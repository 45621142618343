import TextEditor2 from 'components/v2/TextEditor2/TextEditor2';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectInvoiceForm, setInvoiceForm } from 'redux/invoices/createInvoiceWithTemplateSlice';
import styles from './InvoiceFooterSection.module.scss';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import WidgetTextEditor2 from 'components/WidgetTextEditor2/WidgetTextEditor2';

const IS_DEVELOPMENT = isDevelopmentENV();

const InvoiceFooterSection = () => {
  const dispatch = useAppDispatch();
  const invoiceForm = useAppSelector(selectInvoiceForm);

  const { footer } = invoiceForm;

  if (!footer) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {IS_DEVELOPMENT ? (
        <WidgetTextEditor2
          height={300}
          value={footer}
          onBlurMethod
          hasContainerBorder
          onChange={(value) =>
            dispatch(
              setInvoiceForm({
                footer: value
              })
            )
          }
        />
      ) : (
        <TextEditor2
          height={300}
          value={footer}
          onChange={(value) =>
            dispatch(
              setInvoiceForm({
                footer: value
              })
            )
          }
          onBlurMethod
        />
      )}
    </div>
  );
};

export default InvoiceFooterSection;
