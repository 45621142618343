export const getAppointmentReportTableColumns = (currencySymbol: string, abbreviatedTimezone: string) => [
  {
    id: 'serviceId',
    sortAble: false,
    label: 'Appointment Id'
  },
  {
    id: 'dateOfService',
    sortAble: true
  },
  {
    id: 'startTime',
    sortAble: false,
    label: `Start Time (in ${abbreviatedTimezone})`
  },
  {
    id: 'nameOfService',
    sortAble: false
  },
  {
    id: 'processedOutcome',
    sortAble: true
  },
  {
    id: 'waiveCancellationFee',
    sortAble: false
  },
  {
    id: 'reviewNotes',
    sortAble: false
  },
  {
    id: 'tID',
    sortAble: false,
    label: 'T-ID'
  },
  {
    id: 'clientName',
    sortAble: true
  },
  {
    id: 'collected',
    sortAble: false,
    label: currencySymbol
  },
  {
    id: 'invoiceAmount',
    sortAble: true,
    label: `${currencySymbol} Invoice Total`
  },
  {
    id: 'paymentCompleted',
    sortAble: false
  },
  {
    id: 'notesOnUpdate',
    sortAble: false
  }
];

export const getCSVHeaders = (currencySymbol: string, abbreviatedTimezone: string) => [
  { key: 'serviceId', label: 'Appointment Id' },
  { key: 'dateOfService', label: 'Date Of Service' },
  { key: 'startTime', label: `Start Time (in ${abbreviatedTimezone})` },
  { key: 'nameOfService', label: 'Name Of Service' },
  { key: 'processedOutcome', label: 'Processed Outcome' },
  { key: 'waiveCancellationFee', label: 'Waive Cancellation Fee' },
  { key: 'reviewNotes', label: 'Review Notes' },
  { key: 'tID', label: 'T-ID' },
  { key: 'clientName', label: 'Client Name' },
  { key: 'collected', label: `${currencySymbol}` },
  { key: 'invoiceAmount', label: `${currencySymbol} Invoice Total` },
  { key: 'paymentCompleted', label: 'Payment Completed' },
  { key: 'notesOnUpdate', label: 'Notes On Update' }
];
