import { useEffect, useCallback, forwardRef, useMemo, useRef, useImperativeHandle } from 'react';

import styles from './PatientDetailsInvoicesV2.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  RefundInvoice,
  UpdateBalanceInvoice,
  WriteOffForm,
  resetFilters,
  resetPaging,
  resetSearch,
  selectActiveTab,
  selectFilters,
  selectIsShowManualAdjustTotalModal,
  selectIsShowRefundModal,
  selectIsShowUpdateBalanceModal,
  selectIsShowWriteOffModal,
  selectManualAdjustTotalAmount,
  selectRefundInvoice,
  selectSelectedInvoice,
  selectUpdateBalanceInvoice,
  selectWriteOffInvoice,
  setActiveTab,
  setIsShowManualAdjustTotalModal,
  setIsShowRefundModal,
  setIsShowUpdateBalanceModal,
  setManualAdjustTotalAmount,
  setRefundInvoice,
  setSelectedInvoice,
  setUpdateBalanceInvoice,
  setWriteOffInvoice
} from 'redux/invoices/invoiceSlice';
import { DataRefreshHandle } from 'pages/PatientDetails/components/PatientDetailsContent/PatientDetailsContent';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { InvoiceTab } from 'pages/InvoicesV2/constants';
import InvoiceSummary from 'pages/InvoicesV2/components/InvoiceSummary/InvoiceSummary';
import InvoiceList from 'pages/InvoicesV2/components/InvoiceList/InvoiceList';
import RefundModal from 'pages/InvoicesV2/components/RefundModal/RefundModal';
import UpdateBalanceModal from 'pages/InvoicesV2/components/UpdateBalanceModal/UpdateBalanceModal';
import WriteOffModal from 'pages/InvoicesV2/components/WriteOffModal/WriteOffModal';
import ManualAdjustTotalModal from 'pages/InvoicesV2/components/ManualAdjustTotalModal/ManualAdjustTotalModal';

interface PatientDetailsInvoicesV2Props {
  recordId: string;
  clientRecord: clientRecordsInterface;
}

const PatientDetailsInvoicesV2 = forwardRef<DataRefreshHandle, PatientDetailsInvoicesV2Props>(
  ({ recordId, clientRecord }, ref) => {
    const dispatch = useAppDispatch();
    const listRefreshRef = useRef<DataRefreshHandle>(null);
    const summaryRefreshRef = useRef<DataRefreshHandle>(null);

    const activeTab = useAppSelector(selectActiveTab);
    const filters = useAppSelector(selectFilters);
    const isShowRefundModal = useAppSelector(selectIsShowRefundModal);
    const isShowUpdateBalanceModal = useAppSelector(selectIsShowUpdateBalanceModal);
    const isShowWriteOffModal = useAppSelector(selectIsShowWriteOffModal);
    const selectedInvoice = useAppSelector(selectSelectedInvoice);
    const refund = useAppSelector(selectRefundInvoice);
    const updateBalanceFormValue = useAppSelector(selectUpdateBalanceInvoice);
    const writeOffFormValue = useAppSelector(selectWriteOffInvoice);
    const isShowManualAdjustTotalModal = useAppSelector(selectIsShowManualAdjustTotalModal);
    const manualAdjustTotalAmount = useAppSelector(selectManualAdjustTotalAmount);

    const setFilterByTab = useCallback(() => {
      dispatch(resetPaging());
      dispatch(resetSearch());
      dispatch(
        resetFilters({
          ...filters,
          clients: [
            {
              _id: recordId,
              name: `${clientRecord.clientProfiles[0].firstName} ${clientRecord.clientProfiles[0].lastName}`
            }
          ]
        })
      );
    }, [dispatch, filters, clientRecord.clientProfiles, recordId]);

    const isClientProfileTab = useMemo(() => {
      return activeTab === InvoiceTab.ClientProfile;
    }, [activeTab]);

    const isClientChanged = useMemo(() => {
      return filters.clients.length === 1 && !filters.clients.some((client) => client._id === clientRecord._id);
    }, [filters.clients, clientRecord._id]);

    useEffect(() => {
      if (!isClientProfileTab) {
        dispatch(setActiveTab(InvoiceTab.ClientProfile));
      }

      if (!isClientProfileTab || isClientChanged) {
        setFilterByTab();
      }
    }, [dispatch, isClientProfileTab, setFilterByTab, isClientChanged]);

    const onCloseRefundModalHandle = () => {
      dispatch(setIsShowRefundModal(false));
      dispatch(setSelectedInvoice(undefined));
      dispatch(setRefundInvoice({ amount: '', refId: '' }));
    };

    const onChangeRefundValue = (value: Partial<RefundInvoice>) => {
      dispatch(setRefundInvoice(value));
    };

    const onCloseUpdateBalanceModalHandle = () => {
      dispatch(setUpdateBalanceInvoice({ type: '', reference: '', date: '', amount: '' }));
      dispatch(setIsShowUpdateBalanceModal(false));
      dispatch(setSelectedInvoice(undefined));
    };

    const onChangeUpdateBalanceValue = (value: Partial<UpdateBalanceInvoice>) => {
      dispatch(setUpdateBalanceInvoice(value));
    };

    const onCloseWriteOffModalHandle = () => {
      dispatch(setWriteOffInvoice({ reference: '', date: '' }));
      dispatch(setIsShowUpdateBalanceModal(false));
      dispatch(setSelectedInvoice(undefined));
    };

    const onChangeWriteOffFormValue = (value: Partial<WriteOffForm>) => {
      dispatch(setWriteOffInvoice(value));
    };

    useImperativeHandle(ref, () => ({
      onDataRefresh: () => {
        listRefreshRef.current?.onDataRefresh();
        summaryRefreshRef.current?.onDataRefresh();
      }
    }));

    const onCloseManualAdjustTotalModalHandle = () => {
      dispatch(setManualAdjustTotalAmount(undefined));
      dispatch(setIsShowManualAdjustTotalModal(false));
      dispatch(setSelectedInvoice(undefined));
    };

    const onChangeManualAdjustTotalAmount = (value: string) => {
      dispatch(setManualAdjustTotalAmount(value));
    };

    return (
      <div className={styles.container}>
        <InvoiceSummary clientRecordId={clientRecord._id} ref={summaryRefreshRef} />
        <InvoiceList ref={listRefreshRef} skipFetchInvoices={!isClientProfileTab} />

        {selectedInvoice && (
          <>
            <RefundModal
              showModal={isShowRefundModal}
              invoice={selectedInvoice}
              refund={refund}
              onCloseModal={onCloseRefundModalHandle}
              onChangeValue={onChangeRefundValue}
            />
            <UpdateBalanceModal
              showModal={isShowUpdateBalanceModal}
              invoice={selectedInvoice}
              formValue={updateBalanceFormValue}
              onCloseModal={onCloseUpdateBalanceModalHandle}
              onChangeValue={onChangeUpdateBalanceValue}
            />
            <WriteOffModal
              showModal={isShowWriteOffModal}
              invoice={selectedInvoice}
              formValue={writeOffFormValue}
              onCloseModal={onCloseWriteOffModalHandle}
              onChangeValue={onChangeWriteOffFormValue}
            />
            <ManualAdjustTotalModal
              showModal={isShowManualAdjustTotalModal}
              invoice={selectedInvoice}
              amount={manualAdjustTotalAmount}
              onCloseModal={onCloseManualAdjustTotalModalHandle}
              onChangeValue={onChangeManualAdjustTotalAmount}
            />
          </>
        )}
      </div>
    );
  }
);

export default PatientDetailsInvoicesV2;
