import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import styles from './OtherWorkspaceEvent.module.scss';

interface OtherWorkspaceEventProps {
  appointment: AppointmentSlots;
}

const OtherWorkspaceEvent = ({ appointment }: OtherWorkspaceEventProps) => {
  const descLabel =
    'This appointment is reserved in a different connected workspace. To view or edit, switch to that specific workspace.';

  return (
    <div className={styles.container}>
      <div className={styles.title}>{appointment.title}</div>
      <div className={styles.footerContainer}>{descLabel}</div>
    </div>
  );
};

export default OtherWorkspaceEvent;
