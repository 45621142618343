import { useEffect, useState } from 'react';
import styles from './ReportBuilderPreview.module.scss';
import ReportDraftContent from './components/ReportDraftContent/ReportDraftContent';
import SideBar from './components/SideBar/SideBar';
import Button from '../../../../components/Button/Button';
import { putPublish, putShare } from 'utils/http/DocumentService/Reports/reports';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';
import { useAppDispatch } from 'redux/hooks';

interface ReportBuilderDraftProps {
  data: any;
  reportId: string;
  title: string;
  token: string;
  backBtnLabel: string;
  clinicianDetails: any;
  previewMode?: boolean;
  onBackToEditingMode: () => void;
}

const ReportBuilderPreview = ({
  data,
  reportId,
  title,
  token,
  backBtnLabel,
  clinicianDetails,
  previewMode,
  onBackToEditingMode
}: ReportBuilderDraftProps) => {
  const navigate = useNavigate();
  const { REPORTS } = useRoutesGenerator();
  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [reportData, setReportData] = useState(data);
  const [backButtonLabel, setBackButtonLabel] = useState(backBtnLabel);
  const [publishNShared, setPublishNShared] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setReportData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSendReport = async (body: any) => {
    setSaveButtonStatus('active');
    try {
      const resShare = await putShare(token, reportId, body);
      const updatedSessionType = await resShare.json();
      dispatch(documentServicesApiSlice.util.invalidateTags(['Reports']));
      setReportData(updatedSessionType);
      setBackButtonLabel('Back to report listing');
      setPublishNShared(true);
      setSaveButtonStatus('finished');
      navigate(`${REPORTS.BASE}/${reportId}/preview`);
      setTimeout(() => {
        notification.success({
          message: 'Report has been shared',
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });
        setSaveButtonStatus('');
      }, 2000);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to publish and share your report.' });
      setSaveButtonStatus('');
    }
  };

  const handleBackBtn = () => {
    if (publishNShared) {
      navigate(REPORTS.BASE);
    } else {
      onBackToEditingMode();
    }
  };

  const handleSaveReport = async () => {
    setSaveButtonStatus('active');
    try {
      await putPublish(token, reportId);
      setSaveButtonStatus('finished');
      navigate(`${REPORTS.BASE}/${reportId}/preview`);
      setTimeout(() => {
        notification.success({
          message: 'Report has been published',
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });
        setSaveButtonStatus('');
      }, 2000);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to publish your report.' });
      setSaveButtonStatus('');
    }
  };

  return (
    <div className={styles.container}>
      {!previewMode && (
        <div className={styles.leftSection}>
          <div className={styles.title}>{title}</div>
          <Button className={styles.button} type="button" onClick={() => handleBackBtn()}>
            <i className={`material-icons-outlined ${styles.icon}`}>arrow_back_ios</i>
            <span className={styles.text}>{backButtonLabel}</span>
          </Button>
          <SideBar
            data={reportData}
            token={token}
            sendInvoiceButtonStatus={saveButtonStatus}
            onSendReport={handleSendReport}
            onSaveReport={handleSaveReport}
          />
        </div>
      )}
      <div className={styles.rightSection}>
        {!previewMode && (
          <div className={styles.templateHeader}>
            <div className={styles.reportNameWrapper}>
              <div className={styles.reportLabel}>Name of reports</div>
              <div className={styles.reportName}>{reportData.reportName}</div>
            </div>
          </div>
        )}
        <ReportDraftContent data={reportData} clinicianDetails={clinicianDetails} />
      </div>
    </div>
  );
};

export default ReportBuilderPreview;
