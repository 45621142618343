import classNames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import Input from 'components/v2/Input/Input';
import { config } from 'config/config';
import { useField } from 'formik';
import { AppointmentType } from 'interfaces/Schedule/AppointmentType';
import { FocusEvent } from 'react';
import styles from './Rate.module.scss';

interface RateProps {
  appointmentType?: AppointmentType;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}

const Rate = ({ appointmentType, isEditing, setIsEditing }: RateProps) => {
  const [{ onChange, onBlur, ...rateField }, { error, touched, value }, { setError, setValue }] = useField('rate');

  const rateNumber = Number(value);

  const handleBlurRate = (e: FocusEvent<HTMLInputElement>) => {
    onBlur(e);

    const rateNumber = Number(value);

    if (isNaN(rateNumber)) {
      setError('Please enter a valid number');
    } else {
      const rateValue = rateNumber.toFixed(2);
      setError('');
      setValue(rateValue);
    }
  };

  return (
    <div className={styles.eventInformationContainer}>
      <div className={styles.iconContainer}>
        <span className="material-icons-outlined">{config.moneyIcon}</span>
      </div>
      <div className={styles.description}>
        {isEditing ? (
          <>
            <Input
              inputProps={{
                ...rateField,
                onBlur: handleBlurRate,
                onChange: onChange
              }}
              prefix={config.currencySymbol}
              className={styles.inputContainer}
              inputClassName={classNames(styles.input, touched && error && styles.error)}
            />
            <ErrorMessage error={error} visible={touched && !!error} />
          </>
        ) : (
          <>
            <div className={styles.descriptionText}>{rateNumber.toFixed(2)}</div>
            {appointmentType?.rate.editable && (
              <ButtonAlt size={'medium'} variant={'text'} onClick={() => setIsEditing(true)}>
                Change rate
              </ButtonAlt>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Rate;
