import { AppointmentSlots, ServiceDelivered } from 'interfaces/Schedule/Appointment';
import { SSTagTypes, scheduleServicesApiSlice } from 'redux/services/scheduleServicesApiSlice';
import queryString from 'query-string';
import { TaskStatus } from 'interfaces/Tasks/task';

interface ProcessAppointmentPayload {
  serviceDelivered?: ServiceDelivered;
  appointmentTypeId?: string;
  deliveryType?: string;
  asAdmin: boolean;
  providerId?: string;
  funder?: string;
  mbsCode?: string;
  waiveCancellationFee: boolean;
  cancellationNote?: string;
  cancellationWithRefund?: boolean;
  invoiceAmount?: number;
}
interface ProcessAppointmentRequestPayload {
  appointmentId: string;
  payload: ProcessAppointmentPayload;
}

interface ReviewAppointmentRequestPayload {
  appointmentId: string;
  payload: ProcessAppointmentPayload & { reviewNote?: string };
}

export interface GetReviewAppointmentQuery {
  clientRecordIds?: string;
  status?: TaskStatus;
  sortByCreatedAt?: '1' | '-1';
  page?: number;
  perPage?: number;
}

export type ReviewAppointment = AppointmentSlots & { paid?: number; owed?: number };
export interface AppointmentsReviewListResponse {
  appointments: ReviewAppointment[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export const processAppointmentApiSlice = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    processAppointment: builder.mutation({
      query: ({ appointmentId, payload }: ProcessAppointmentRequestPayload) => ({
        url: `/appointments/${appointmentId}/process`,
        method: 'POST',
        body: payload,
        responseHandler: (response: { text: () => any }) => response.text()
      }),
      invalidatesTags: [SSTagTypes.ListOfReviewAppointments]
    }),

    reviewAppointment: builder.mutation({
      query: ({ appointmentId, payload }: ReviewAppointmentRequestPayload) => ({
        url: `/appointments/${appointmentId}/review`,
        method: 'POST',
        body: payload,
        responseHandler: (response: { text: () => any }) => response.text()
      }),
      invalidatesTags: [SSTagTypes.ListOfReviewAppointments]
    }),

    getReviewAppointments: builder.query<AppointmentsReviewListResponse, GetReviewAppointmentQuery>({
      query: (queryParams) => ({
        url: `/appointments/appointments-review?${queryString.stringify(queryParams)}`
      }),
      providesTags: [SSTagTypes.ListOfReviewAppointments]
    }),

    resolveAppointment: builder.mutation({
      query: ({ appointmentId }: { appointmentId: string }) => ({
        url: `/appointments/${appointmentId}/resolve`,
        method: 'PATCH'
      }),
      invalidatesTags: [SSTagTypes.ListOfReviewAppointments]
    })
  })
});

export const {
  useProcessAppointmentMutation,
  useReviewAppointmentMutation,
  useGetReviewAppointmentsQuery,
  useResolveAppointmentMutation
} = processAppointmentApiSlice;
