export enum FaxStatus {
  Received = 'RECEIVED',
  Scheduled = 'SCHEDULED',
  SendFailed = 'SEND_FAILED',
  SendProcessing = 'SEND_PROCESSING',
  Sent = 'SENT'
}

export enum FaxTag {
  Actioned = 'ACTIONED',
  Pending = 'PENDING',
  Closed = 'CLOSED'
}

export interface Fax {
  _id: string;
  accountId: string;
  clientRecordId?: string;
  clinicianId: string;
  createdAt: string;
  faxDocument?: { path: string };
  fileName: string;
  sendAt: Date;
  sendFrom?: string;
  sendTo: string;
  sentAt?: Date;
  status: FaxStatus;
  tag: FaxTag;
}
