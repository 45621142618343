import styles from './HelpOutLineWithTooltips.module.scss';
import { Tooltip } from 'react-tooltip';
import classnames from 'classnames';

interface HelpOutLineWithTooltipsProps {
  id: string;
  desc: string;
  iconClass?: string;
  tooltipsClass?: string;
}

const HelpOutLineWithTooltips = ({ id, desc, iconClass, tooltipsClass }: HelpOutLineWithTooltipsProps) => {
  return (
    <>
      <i className={`material-icons ${styles.toolTipsIcon} ${iconClass}`} data-tooltip-id={id}>
        help_outline
      </i>
      <Tooltip id={id} className={classnames(styles.tooltip, tooltipsClass)}>
        <div dangerouslySetInnerHTML={{ __html: desc }} />
      </Tooltip>
    </>
  );
};

export default HelpOutLineWithTooltips;
