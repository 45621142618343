import styles from './AddressBookCategoryBadge.module.scss';
import { AddressBookCategory, ADDRESS_BOOK_CATEGORIES } from 'pages/AddressBook/Interfaces/AddressBook';

export interface AddressBookCategoryBadgeProps {
  type: AddressBookCategory;
  isDisable?: boolean;
}

const AddressBookCategoryBadge = ({ type, isDisable }: AddressBookCategoryBadgeProps) => (
  <div className={styles[isDisable ? 'disable' : type]}>
    <div>{ADDRESS_BOOK_CATEGORIES[type]?.toUpperCase()}</div>
  </div>
);

export default AddressBookCategoryBadge;
