import { ClientListFilters } from 'redux/clients/clientListSlice';
import {
  CLIENT_LIST_FILTER_ABLE_COLUMNS,
  CLIENT_LIST_FORMS_COLUMNS,
  ColumnConfigType,
  ColumnSettingsEnum,
  UserViewEnum
} from 'utils/providers/ClientListColumnsProvider/constants';
import { initialClientListColumnsDropdownOptions } from '../constant';

/**
 * If view is Edge Admin:
 * => all options will be returned.
 * If view is Edge User:
 * => will return all options except practitioner option.
 * If view is pioneer:
 * => will return all options except practitioner and group options.
 */
export const getInitialColumnSettingsDropdownOptions = (view: UserViewEnum) => {
  let init = initialClientListColumnsDropdownOptions;

  if ([UserViewEnum.EDGE_ADMIN_VIEW, UserViewEnum.EDGE_RECEPTIONIST_VIEW].includes(view)) {
    return init;
  }

  init = init.filter((option) => option.value !== ColumnSettingsEnum.PRACTITIONER);

  if (view === UserViewEnum.EDGE_USER_VIEW) {
    return init;
  }

  init = init.filter((option) => option.value !== ColumnSettingsEnum.GROUP);
  return init;
};

export const getListOfFilterNeedReset = (
  oldSettingColumns: ColumnConfigType[],
  newSettingColumns: ColumnConfigType[]
): ColumnConfigType[] => {
  const listOfFilterNeedReset: ColumnConfigType[] = [];
  let isExistFormColumnChanged = false;
  for (const old of oldSettingColumns) {
    if (old.active) {
      const mapWithNew = newSettingColumns.find((item) => item.id === old.id && item.active === old.active);
      if (!mapWithNew && CLIENT_LIST_FILTER_ABLE_COLUMNS.includes(old.id)) {
        listOfFilterNeedReset.push(old);
        isExistFormColumnChanged = CLIENT_LIST_FORMS_COLUMNS.includes(old.id);
      }
    }
  }
  if (
    isExistFormColumnChanged &&
    newSettingColumns.filter((item) => item.active && CLIENT_LIST_FORMS_COLUMNS.includes(item.id)).length > 0
  ) {
    return listOfFilterNeedReset.filter((item) => !CLIENT_LIST_FORMS_COLUMNS.includes(item.id));
  }
  return listOfFilterNeedReset;
};

export const getNewFilter = (filters: ClientListFilters, resetColumns: ColumnConfigType[]) => {
  const isResetForms = resetColumns.some((item) => CLIENT_LIST_FORMS_COLUMNS.includes(item.id));
  return {
    ...filters,
    page: 1,
    ...(isResetForms && {
      selectedForms: []
    }),
    ...(resetColumns.some((item) => item.id === ColumnSettingsEnum.PRACTITIONER) && {
      selectedPractitioners: []
    }),
    ...(resetColumns.some((item) => item.id === ColumnSettingsEnum.GROUP) && {
      selectedGroups: []
    }),
    ...(resetColumns.some((item) => item.id === ColumnSettingsEnum.PROFILE_TYPE) && {
      selectedProfileTypes: []
    }),
    ...(resetColumns.some((item) => item.id === ColumnSettingsEnum.REFERRAL_SOURCE_AND_DETAIL) && {
      selectedReferralSources: []
    }),
    ...(resetColumns.some((item) => item.id === ColumnSettingsEnum.REFERRAL_STATUSES) && {
      selectedReferralStatuses: []
    })
  };
};
