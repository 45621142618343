import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './AppointmentTypeCard.module.scss';
import { AppointmentType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import BookablePractitioner from './components/BookablePractitioner/BookablePractitioner';
import SessionMode from './components/SessionMode/SessionMode';
import SessionSettings from './components/SessionSettings/SessionSettings';
import SessionAvailableDate from './components/SessionAvailableDate/SessionAvailableDate';
import SessionParticipantType from './components/SessionParticipantType/SessionParticipantType';

interface AppointmentTypeCardProps {
  appointmentType: AppointmentType;
  ableToUpdate: boolean;
  onSelect: () => void;
}

const AppointmentTypeCard = ({ appointmentType, ableToUpdate, onSelect }: AppointmentTypeCardProps) => {
  const { isEdgeUser } = useGetAccountPackageView();

  const isActivity = appointmentType.participantType === ParticipantType.Activity;

  return (
    <>
      <div
        className={`${ableToUpdate ? styles.appointmentTypeCard : styles.appointmentTypeCardView} ${
          appointmentType.isActive ? styles.active : styles.inactive
        }`}
        onClick={() => {
          ableToUpdate && onSelect();
        }}
      >
        <div className={styles.appointmentInfoCard}>
          <div className={styles.leftContent}>
            <div className={styles.header}>
              <div className={styles.headerContent}>
                <div className={styles.name}>{appointmentType.name}</div>
                <div className={`${styles.activeLabel} ${appointmentType.isActive ? styles.active : styles.inactive}`}>
                  {appointmentType.isActive ? 'OPEN TO BOOK' : 'DRAFT - NOT BOOKABLE'}
                </div>
              </div>
              {appointmentType.description && <div className={styles.description}>{appointmentType.description}</div>}
            </div>
            <SessionSettings appointmentType={appointmentType} isActivity={isActivity} />
            {appointmentType.dayTimeSelections && (
              <SessionAvailableDate dayTimeSelections={appointmentType.dayTimeSelections} />
            )}
          </div>
          <div className={styles.rightContent}>
            {isEdgeUser && <SessionParticipantType participantType={appointmentType.participantType} />}
            {isEdgeUser &&
              [ParticipantType.Activity, ParticipantType.OneToOne].includes(appointmentType.participantType) && (
                <BookablePractitioner assignmentMode={appointmentType.assignmentMode} />
              )}
            {!isActivity && <SessionMode deliveryOptions={appointmentType.deliveryOptions} />}
          </div>
        </div>
        {!isActivity && (
          <div className={styles.footer}>
            {appointmentType?.bookingRules?.new.available && appointmentType?.bookingRules?.existing.available && (
              <>
                <strong>New</strong>&nbsp;and&nbsp;<strong>Existing</strong>
              </>
            )}
            {appointmentType?.bookingRules?.new.available && !appointmentType?.bookingRules?.existing.available && (
              <strong>New</strong>
            )}
            {!appointmentType?.bookingRules?.new.available && appointmentType?.bookingRules?.existing.available && (
              <strong>Existing</strong>
            )}
            &nbsp;clients
          </div>
        )}
      </div>
    </>
  );
};

export default AppointmentTypeCard;
