import styles from './LinkToAccountancy.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

const LinkToAccountancy = () => {
  const { CONTROL_PANEL } = useRoutesGenerator();
  return (
    <div className={styles.container}>
      <div className={styles.title}>Link to accountancy</div>
      <div className={styles.description}>
        Connect your invoice data to accountancy software like Xero and QuickBooks
      </div>
      <ButtonAlt
        className={styles.button}
        fullWidth
        variant={'outlined'}
        icon={'settings'}
        to={CONTROL_PANEL.SYSTEM_CONNECTIONS}
      >
        Explore connections
      </ButtonAlt>
    </div>
  );
};

export default LinkToAccountancy;
