import classnames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import { config } from 'config/config';
import styles from './MaterialPhoneInput.module.scss';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import { componentProps } from 'components/AddPatientModalV2/interfaces/component.interface';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import IconButton from 'components/IconButton/IconButton';
import { useContext, useMemo, useState } from 'react';
import { UserContext } from 'utils/UserContextProvider';

export interface MaterialPhoneInputProps extends componentProps {
  id: string;
  inputClass?: string;
  buttonClass?: string;
  label: string;
  value: string;
  onChange: (value?: string) => void;
  isError: boolean;
  isLoading?: boolean;
  errorMessage?: string;
  handleSave?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleCancel?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hideFlag?: boolean;
  autoFormat?: boolean;
  disableCountryCode?: boolean;
  placeholder?: string;
  disabled?: boolean;
  inputName?: string;
  icon?: string;
  labelClass?: string;
}

const PREFERRED_COUNTRY = config.countryCode;

export const reformatPhoneNumber = (phoneNumber: string, countryCode: string): string => {
  // Remove all non-digit characters and truncate to 15 digits
  let cleaned = phoneNumber?.replace(/[^\d]/g, '').substring(0, 15) || '';

  // Helper function to apply country code and format number
  const applyCountryCode = (prefix: string, localNumber: string): string => {
    return `+${prefix}${localNumber}`;
  };

  // Helper function to validate and format based on country code
  const formatByCountry = (number: string): string => {
    switch (countryCode) {
      case 'au':
        if (number.length === 9 && number.startsWith('4')) {
          return applyCountryCode('61', number);
        } else if (number.length === 10 && number.startsWith('0')) {
          return applyCountryCode('61', number.substring(1));
        } else if (number.length === 11 && number.startsWith('61')) {
          return applyCountryCode('61', number.substring(2));
        }
        break;

      case 'nz':
        if (number.length === 9 && number.startsWith('2')) {
          return applyCountryCode('64', number);
        } else if (number.length === 10 && ['021', '022', '027'].includes(number.substring(0, 3))) {
          return applyCountryCode('64', number.substring(1));
        } else if (number.length === 11 && number.startsWith('64')) {
          return applyCountryCode('64', number.substring(2));
        }
        break;

      case 'uk':
        if (number.length === 10 && number.startsWith('7')) {
          return applyCountryCode('44', number);
        } else if (number.length === 11 && number.startsWith('07')) {
          return applyCountryCode('44', number.substring(1));
        } else if (number.length === 11 && ['01', '02', '03'].some((prefix) => number.startsWith(prefix))) {
          return applyCountryCode('44', number.substring(1));
        } else if (number.length === 12 && number.startsWith('44')) {
          return applyCountryCode('44', number.substring(2));
        }
        break;

      case 'sa':
        if (number.length === 9 && number.startsWith('5')) {
          return applyCountryCode('966', number);
        } else if (number.length === 10 && number.startsWith('05')) {
          return applyCountryCode('966', number.substring(1));
        } else if (number.length === 12 && number.startsWith('966')) {
          return applyCountryCode('966', number.substring(2));
        }
        break;

      default:
        return number;
    }

    // If no valid format is found, return the original number
    return number;
  };

  // Check if phone number already includes country code
  if (phoneNumber?.startsWith('+') && !phoneNumber?.startsWith('+0')) {
    return phoneNumber;
  } else {
    return formatByCountry(cleaned);
  }
};

const MaterialPhoneInput = ({
  id,
  inputClass,
  buttonClass,
  label,
  value,
  onChange,
  isError,
  isLoading,
  errorMessage,
  handleSave,
  handleCancel,
  hideFlag,
  autoFormat = true,
  disableCountryCode,
  placeholder,
  disabled,
  inputName,
  icon,
  labelClass
}: MaterialPhoneInputProps) => {
  const [isFocus, setIsFocus] = useState(false);
  const { clinicianProfile } = useContext(UserContext);

  const getCountry = useMemo(
    () => clinicianProfile?.practice?.country || process.env.REACT_APP_GAE_REGION || 'au',
    [clinicianProfile?.practice?.country]
  );

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.phoneInputWrapper}>
          {hideFlag && icon && <i className={`material-icons-outlined ${styles.icon}`}>{icon}</i>}
          <PhoneInput
            inputClass={classnames(
              styles.mobileNumberInput,
              inputClass,
              isError && styles.redBorderBottom,
              hideFlag && styles.inputHideFlag,
              hideFlag && icon && styles.fieldWithIcon
            )}
            buttonClass={classnames(
              styles.countrySelect,
              buttonClass,
              isError && styles.redBorderBottom,
              hideFlag && styles.buttonHideFlag
            )}
            dropdownClass={classnames(styles.countryDropdown)}
            value={reformatPhoneNumber(value, getCountry)}
            onKeyDown={(event) => event.key === 'Enter' && handleSave?.()}
            onChange={(_value, _data, _event, formattedValue) => {
              // use formattedValue because the value having +
              onChange(reformatPhoneNumber(formattedValue, getCountry));
            }}
            inputProps={{
              id,
              name: inputName
            }}
            {...(PREFERRED_COUNTRY && {
              country: PREFERRED_COUNTRY,
              preferredCountries: [PREFERRED_COUNTRY]
            })}
            autoFormat={autoFormat}
            disableCountryCode={disableCountryCode}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
          />
          {(isLoading || handleSave || handleCancel) && (
            <div className={styles.endAdornmentWrapper}>
              {isLoading && <LoadingCircle className={classnames(styles.endAdornment, styles.loader)} />}
              {!isLoading && handleSave && handleCancel && (
                <div className={classnames(styles.endAdornment, styles.save)}>
                  <IconButton className={classnames(styles.iconButton)} iconName="save" onClick={handleSave} />
                  <IconButton
                    className={classnames(styles.iconButton, styles.cancel)}
                    iconName="close"
                    onClick={handleCancel}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <label
          className={classnames(
            styles.label,
            (value?.length > 0 || !hideFlag || isFocus) && styles.showLabel,
            placeholder && styles.maximizeLabel,
            icon && styles.iconLabel,
            labelClass
          )}
          htmlFor={id}
        >
          {label}
        </label>
      </div>
      <ErrorMessage error={errorMessage} visible={!!errorMessage} />
    </div>
  );
};

export default MaterialPhoneInput;
