import classNames from 'classnames';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useState } from 'react';
import { getName } from 'utils/general';

import AvatarProfile from '../AvatarProfile/AvatarProfile';
import styles from './SharedPerson.module.scss';

interface SharedPersonProps {
  details: {
    _id?: string;
    email?: string;
    isClient?: boolean;
    uniqueLink?: string;
    sentAt?: Date | string;
    faxNumber?: string;
    faxRecipientName?: string;
  };
  clientProfile?: {
    firstName?: string;
    lastName?: string;
    name: string;
    avatar?: string;
    initials?: string;
  };
  onClickRevokeAccess?: (id: string, name?: string) => void;
}

const SharedPerson = ({
  details: { _id, email, isClient, faxNumber, faxRecipientName },
  clientProfile,
  onClickRevokeAccess
}: SharedPersonProps) => {
  const [isRevoking, setIsRevoking] = useState(false);
  const clientName = clientProfile ? getName(clientProfile) : '';
  const handleRevokeAccess = async () => {
    if (_id) {
      setIsRevoking(true);
      await onClickRevokeAccess?.(_id, isClient ? clientName : email);
      setIsRevoking(false);
    }
  };

  return (
    <div className={classNames(styles.container, isRevoking && styles.revoking)}>
      <div className={styles.personInfo}>
        {isClient ? (
          <AvatarProfile name={clientName} avatar={clientProfile?.avatar} initials={clientProfile?.initials} />
        ) : email ? (
          <div className={styles.email}>{email}</div>
        ) : (
          <div className={styles.fax}>
            <span className={classNames('material-icons-outlined', styles.icon)}>fax</span>
            <div>
              <div>{faxRecipientName ?? faxNumber}</div>
              {faxRecipientName && <div className={styles.secondary}>{faxNumber}</div>}
            </div>
          </div>
        )}
      </div>
      {onClickRevokeAccess && (
        <ButtonAlt icon="remove_circle" error size="small" variant="text" onClick={handleRevokeAccess} />
      )}
    </div>
  );
};

export default SharedPerson;
