import { notification } from 'antd';
import Select from 'components/Select/CommonSelect/Select';
import { AppointmentSlots, AppointmentStatusOption } from 'interfaces/Schedule/Appointment';
import { useState } from 'react';
import { updateAppointmentStatus } from 'utils/http/appointment';

import styles from './MarkStatus.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

export const getAppointmentMarkOption = (isChargeAppointmentTagEnabled: boolean) =>
  Object.values(AppointmentStatusOption)
    .filter(
      (value) =>
        value !== AppointmentStatusOption.DidNotAttend &&
        (isChargeAppointmentTagEnabled || value !== AppointmentStatusOption.Charge)
    ) // Option is removed, but we are preserving old data
    .map((obj) => ({
      label: obj,
      value: obj
    }));

type MarkStatusProps = {
  appointment: AppointmentSlots;
  token: string;
  onFinishMarkStatus: () => void;
};

const MarkStatus = ({ appointment, token, onFinishMarkStatus }: MarkStatusProps) => {
  const { isChargeAppointmentTagEnabled } = useGetFeatureToggle();
  const [markedStatus, setMarkedStatus] = useState<string[]>(appointment.markedStatus || []);
  const { isProcessAppointmentEnabled } = useGetFeatureToggle();

  const handleMarkedStatusChange = async (status: string) => {
    if (status) {
      const cache = [...markedStatus];
      try {
        const newMarkedStatus = [...markedStatus, status];
        setMarkedStatus(newMarkedStatus);
        await updateAppointmentStatus(token, appointment._id, newMarkedStatus);
        onFinishMarkStatus();
      } catch (err) {
        setMarkedStatus([...cache]);
        console.error(err);
        notification.error({
          message: `Something went wrong while trying to add tags.`
        });
      }
    }
  };

  const handleRemoveStatus = async (status: string) => {
    if (status) {
      const cache = [...markedStatus];
      try {
        const newMarkedStatus = markedStatus.filter((tag) => tag !== status);
        setMarkedStatus(newMarkedStatus);
        await updateAppointmentStatus(token, appointment._id, newMarkedStatus);
        onFinishMarkStatus();
      } catch (err) {
        setMarkedStatus([...cache]);
        console.error(err);
        notification.error({
          message: `Something went wrong while trying to remove tags.`
        });
      }
    }
  };

  return (
    <div>
      <div className={styles.markedStatusValueContainer}>
        {markedStatus?.map((status, index) => (
          <div className={styles.markedStatus} key={index}>
            {status}
            {!isProcessAppointmentEnabled && (
              <div className={styles.iconContainer} onClick={() => handleRemoveStatus(status)}>
                <i className={`material-icons-outlined ${styles.icon}`}>close</i>
              </div>
            )}
          </div>
        ))}
      </div>
      {!isProcessAppointmentEnabled && (
        <>
          <div className={styles.label}>Mark appointment</div>
          <div className={styles.markAppointmentSelect}>
            <Select
              styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
              options={getAppointmentMarkOption(isChargeAppointmentTagEnabled).filter(
                (mark) => markedStatus?.indexOf(mark.value) === -1
              )}
              placeholder="Type here"
              value={''}
              closeMenuOnSelect={false}
              onChange={(status: any) => handleMarkedStatusChange(status?.value || '')}
              isDisabled={appointment.isProcessed || !!appointment.requestedChanges}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MarkStatus;
