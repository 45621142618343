import { Tooltip } from 'react-tooltip';
import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { config } from 'config/config';
import styles from './PerMonth.module.scss';
import { useFormikContext } from 'formik';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useTranslation } from 'react-i18next';

const { currencySymbol } = config;

const DUE_DATE_OPTIONS = [
  { label: 'Immediate (Today)', value: 0 },
  { label: 'Tomorrow', value: 1 },
  { label: '2 Days', value: 2 },
  { label: '3 Days', value: 3 },
  { label: '7 Days', value: 7 },
  { label: '14 Days', value: 14 },
  { label: '28 Days', value: 28 }
];

const INCLUDE_APPOINTMENTS_OPTIONS = [
  { label: 'All confirmed appointments in current month', value: 'current' },
  { label: 'All confirmed appointments in previous month', value: 'previous' },
  { label: 'All confirmed appointments in next month', value: 'next' }
];

const MONTH_SEND_AT_OPTIONS = [
  { label: '1st day of month', value: '1' },
  { label: '15th day of month', value: '15' },
  { label: 'Last day of month', value: '32' }
];

const PerMonth = () => {
  const { values, setFieldValue } = useFormikContext<Required<clientRecordsInterface['invoiceSummary']>['settings']>();
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.label}>Send invoice</div>
      <FormikSelect
        id={'automatedInvoicing.issueAt'}
        className={styles.select}
        name="automatedInvoicing.issueAt"
        styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
        options={MONTH_SEND_AT_OPTIONS}
      />
      <div className={styles.label}>
        {t('invoice.include_appointments')}
        <i className={`material-icons ${styles.icon}`} data-tooltip-id="include-appointments">
          help_outline
        </i>
        <Tooltip id="include-appointments" className={styles.tooltip}>
          {t('invoice.include_appointments.description')}
        </Tooltip>
      </div>
      <FormikSelect
        id={'automatedInvoicing.includeAppointments'}
        className={styles.select}
        name="automatedInvoicing.includeAppointments"
        styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
        options={INCLUDE_APPOINTMENTS_OPTIONS}
      />
      <div className={styles.checkboxContainer}>
        <input
          className={styles.checkbox}
          id="checkbox-automatedInvoicing-setAsFlatFee-active"
          type="checkbox"
          checked={values.automatedInvoicing.setAsFlatFee?.active}
          onChange={(e) => setFieldValue('automatedInvoicing.setAsFlatFee.active', e.target.checked)}
        />
        <label className={styles.checkboxLabel} htmlFor="checkbox-automatedInvoicing-setAsFlatFee-active">
          Set as flat fee
        </label>
      </div>
      {values.automatedInvoicing.setAsFlatFee?.active && (
        <div className={styles.flatFeeContainer}>
          <span className={styles.moneySymbol}>{currencySymbol}</span>
          <FormikMaterialInput
            id={'flatFee'}
            className={styles.input}
            name="automatedInvoicing.setAsFlatFee.value"
            label="Flat fee"
            required
          />
        </div>
      )}
      <div className={styles.label}>Due date</div>
      <FormikSelect
        id={'automatedInvoicing.dueAt'}
        className={styles.select}
        name="automatedInvoicing.dueAt"
        styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
        options={DUE_DATE_OPTIONS}
      />
    </div>
  );
};

export default PerMonth;
