import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

type Person = {
  id: string;
  name: string;
};

export type CarePlan = {
  dateTo: Date | undefined;
  dateFrom: Date | undefined;
  title: string;
  client: Person | undefined;
  practitioner: Person | undefined;
};

export type CarePlanCreator = {
  carePlan: CarePlan;
  showMenu: number;
  showForm: boolean;
};

const initialState: CarePlanCreator = {
  carePlan: {
    dateTo: undefined,
    dateFrom: undefined,
    title: '',
    client: undefined,
    practitioner: undefined
  },
  showMenu: -1,
  showForm: true
};

export const carePlanCreatorSlice = createSlice({
  name: 'carePlanCreatorSlice',
  initialState,
  reducers: {
    openCreator: (state) => {
      state.showMenu = 1;
    },
    hideCreator: (state) => {
      state.showMenu = 0;
    },
    closeCreator: (state) => {
      state.showMenu = -1;
    },
    showCarePlanForm: (state) => {
      state.showForm = true;
    },
    hideCarePlanForm: (state) => {
      state.showForm = false;
    },
    setCarePlan: (state, action) => {
      state.carePlan = action.payload;
    }
  }
});

export const selectShow = (state: RootState) => state.carePlanCreatorSlice.showMenu;

export const selectShowCarePlanForm = (state: RootState) => state.carePlanCreatorSlice.showForm;

export const selectCarePlan = (state: RootState) => state.carePlanCreatorSlice.carePlan;

export const { openCreator, hideCreator, closeCreator, showCarePlanForm, hideCarePlanForm, setCarePlan } =
  carePlanCreatorSlice.actions;

export default carePlanCreatorSlice.reducer;
