import acal from 'assets/images/acal.svg';
import exchange from 'assets/images/exchange.svg';
import gcal from 'assets/images/gcal.svg';
import medicare from 'assets/images/medicare.svg';
import outlook from 'assets/images/outlook.svg';
import quickBooks from 'assets/images/quickbooks.svg';
import stripe from 'assets/images/stripe.svg';
import xero from 'assets/images/xero.svg';
import eFax from 'assets/images/eFax.svg';
import { IntegrationStatus, IntegrationType } from 'pages/ControlPanel/Interfaces/Integration';

export const PROVIDER_ICON = {
  google: gcal,
  live_connect: outlook,
  exchange,
  apple: acal,
  xero,
  quickBooksOnline: quickBooks,
  stripe,
  medicare,
  eFax
};

export const PROVIDER_LABEL = {
  google: 'Google Calendar',
  live_connect: 'Outlook Calendar',
  exchange: 'Exchange Calendar',
  apple: 'Apple iCal',
  xero: 'Xero',
  quickBooksOnline: 'QuickBooks',
  stripe: 'Stripe',
  medicare: 'Medicare',
  eFax: 'eFax'
};

export const CALENDAR_INTEGRATIONS = [
  {
    icon: PROVIDER_ICON.google,
    provider: 'google',
    label: PROVIDER_LABEL.google,
    type: 'calendar',
    description: 'Connect your Google calendar to Tacklit to manage your schedule and availability in one place'
  },
  {
    icon: PROVIDER_ICON.live_connect,
    provider: 'live_connect',
    label: PROVIDER_LABEL.live_connect,
    type: 'calendar',
    description: 'Connect your Outlook calendar to Tacklit to manage your schedule and availability in one place'
  },
  {
    icon: PROVIDER_ICON.exchange,
    provider: 'exchange',
    label: PROVIDER_LABEL.exchange,
    type: 'calendar',
    description: 'Connect your Exchange calendar to Tacklit to manage your schedule and availability in one place'
  },
  {
    icon: PROVIDER_ICON.apple,
    provider: 'apple',
    type: 'calendar',
    label: PROVIDER_LABEL.apple,
    description: 'Connect your Apple iCal to Tacklit to manage your schedule and availability in one place'
  }
];

export const FINANCE_INTEGRATIONS = [
  {
    icon: PROVIDER_ICON.stripe,
    provider: 'stripe',
    label: PROVIDER_LABEL.stripe,
    type: 'finance',
    description: 'Connect Tacklit to a secure checkout to allow clients to pay on booking or via invoice',
    summary:
      'Stripe accepts one-off payments by giving your customers their preferred payment options through Stripe.  Integrate into your Tacklit booking flow and invoicing with ease, making it fast and simple for your clients to pay. Start accepting payments from all major credit, debit and digital wallets in minutes.'
  },
  {
    icon: PROVIDER_ICON.xero,
    provider: 'xero',
    label: PROVIDER_LABEL.xero,
    type: 'account',
    description: 'Connect Xero to Tacklit to automate invoicing, payments and appointment reconciliation',
    summary:
      'Xero is an all-in-one accounting software. Xero enables you to see your invoices, contacts and accounts online in one place. Connect and set up bank feeds from your bank accounts so transactions are imported securely into Xero each business day. Use Xero to collaborate in real time and work with your accountant, bookkeeper or employees anywhere by accessing the same up-to-date information at the same time.'
  },
  {
    icon: PROVIDER_ICON.quickBooksOnline,
    provider: 'quickBooksOnline',
    label: PROVIDER_LABEL.quickBooksOnline,
    type: 'account',
    description: 'Connect QuickBooks to Tacklit to automate all your accounting and finance data',
    summary:
      'QuickBooks is an accounting software that helps to track expenses, manage cash flow, customise invoices, run financial reports and more - all in one place. Data is stored in the cloud that allows you to access anytime and allows you to run your business from any device. It builds better business insights that create reports and collaborate with your advisor to see how your business is doing and get ready for tax time.'
  }
];

export const CLAIM_INTEGRATIONS = [
  {
    icon: PROVIDER_ICON.medicare,
    provider: 'medicare',
    label: PROVIDER_LABEL.medicare,
    type: 'claim',
    description: 'Connect Tacklit to Medicare to allow your client to do the claim from Medicare.',
    summary:
      'The quickest and easiest way to claim for your client after they pay. You need the client to show you their medicare card and make the electronic claim on the spot. This integration will help you to process the claim to medicare as soon as possible and pay your client Medicare benefit to your client account for the EFTPOS card that the client used to pay or the bank account they’ve registered with Medicare.'
  }
];

export const EFAX_INTEGRATIONS = {
  id: PROVIDER_LABEL.eFax,
  icon: PROVIDER_ICON.eFax,
  provider: PROVIDER_LABEL.eFax,
  label: PROVIDER_LABEL.eFax,
  status: IntegrationStatus.Connected,
  type: IntegrationType.Communication,
  description: 'Use our advanced connection to send eFax to share and send your report and letter via eFax.',
  summary:
    'Use our advanced connection eFax integration to receive or send fax to your clients. Each practitioner gets to add their own fax number. Access eFax secure, reliable and feature packed Fax API to make integrations with Tacklit and your workflows a breeze. Powered by GoFax.'
};
