import { Helmet, HelmetProvider } from 'react-helmet-async';

interface HelmetWrapperProps {
  title?: string;
  children?: any;
}

const HelmetWrapper = ({ children, title }: HelmetWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default HelmetWrapper;
