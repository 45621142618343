import { ClientStatus } from 'pages/Client/components/ClientListContent/components/ClientList/constants';
import { clientProfilesInterface, ClientRecordPinnedNote, ClientRecordType } from './clientsRecord';

export enum FormStatusDaysBefore {
  Today = 'today',
  Last7 = 'last7',
  Last28 = 'last28',
  All = 'all'
}

export enum SearchFilterEnum {
  FirstName = 'firstName',
  LastName = 'lastName',
  ParentName = 'parentName',
  ClientId = 'clientId',
  FirstOrLastName = 'firstOrLastName',
  MobileNumber = 'mobileNumber',
  Email = 'email',
  TacklitId = 'tacklitId'
}

export interface ClientRecordListRequestPayload {
  accountId: string;
  payload: GetClientRecordListingQuery;
}

export interface GetClientRecordListingQuery {
  page: number;
  perPage: number;
  recordStatus: ClientStatus;

  asUser?: string; // '1' if fetching from user view, but logged in as admin

  searchValue?: string;
  searchBy?: SearchFilterEnum;

  clientRecordIds?: string; // comma-separated string ids
  clinicianAuth0Ids?: string; // comma-separated string ids
  groupIds?: string; // comma-separated string ids

  referralSourceTags?: string; // comma-separated string ids
  referralStatuses?: string; // comma-separated string ids

  profileRecordTypeSignupQuery?: string; // comma-separated ProfileRecordTypeSignupQuery
  formStatusQuery?: string; // comma-separated FormStatusQueryKeys
  formStatusDaysBefore?: FormStatusDaysBefore; // if not passed will be no limit

  sortByClientName?: 1 | -1;
  sortByClientSince?: 1 | -1;
  sortByPractitionerName?: 1 | -1;

  includeConsentColumn?: string; // '1' if include
  includePsychometricColumn?: string; // '1' if include
  includeSurveyColumn?: string; // '1' if include

  includeReferralDateColumn?: string; // '1' if include
  includeReferralSourceAndDetailColumn?: string; // '1' if include
  includeReferralStatusesColumn?: string; // '1' if include
}

export interface ClientRecordListResponse {
  clientRecords: {
    _id: string;
    tacklitId: string;
    accountId: string;
    caseId?: string;
    recordType: ClientRecordType;
    recordStatus: 'active' | 'closed' | 'waitlist';
    createdAt: string;
    clientProfiles: clientProfilesInterface[];
    clinicians: {
      _id: string;
      avatar?: string;
      name: string;
      title: string;
    }[];
    groups: {
      _id: string;
      name: string;
    }[];
    canBeFullProfile: boolean;
    lastConsentForm?: {
      _id: string;
      name: string;
      createdAt: string;
    } | null;
    lastPsychometric?: {
      _id: string;
      id: string;
      createdAt: string;
    } | null;
    lastSurvey?: {
      _id: string;
      name: string;
      createdAt: string;
    } | null;
    referrals?: {
      date: string;
      source: string;
      detail: string;
      status: string;
    }[];
    activePinnedNotes?: ClientRecordPinnedNote[];
  }[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}
