import { useState } from 'react';
import { Modal, notification } from 'antd';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { putClientInvoiceSettings } from 'utils/http/ClinicianProfileService/Invoices/invoice';

import styles from './PatientInvoiceRulesModal.module.scss';
import { combineName } from 'utils/general';
import ModalHeader from 'components/v2/ModalHeader/ModalHeader';
import AutomatedInvoicing from './components/AutomatedInvoicing/AutomatedInvoicing';
import IncludeDiscount from './components/IncludeDiscount/IncludeDiscount';
import { useGetInvoiceSettingsQuery } from 'redux/endpoints/billingServices/invoiceSetting';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { security } from 'utils/security';

const clientInvoiceSettingsSchema = yup.object().shape({
  discount: yup.object().shape({
    active: yup.boolean(),
    type: yup.string().oneOf(['percent', 'amount']),
    value: yup.string().when('active', {
      is: true,
      then: yup.string().test('discountValueNumber', 'Please enter numbers only', (value) => !isNaN(Number(value))),
      otherwise: yup.string()
    })
  }),
  automatedInvoicing: yup.object().shape({
    active: yup.boolean(),
    type: yup.string().oneOf(['appointment', 'month']),
    dueAt: yup.number().required('Please select a due date'),
    issueAt: yup.string().required('Please select an issue date'),
    note: yup.string(),
    sendToClient: yup.boolean(),
    sendToOther: yup.object().shape({
      active: yup.boolean(),
      emails: yup
        .array()
        .ensure()
        .when('active', {
          is: true,
          then: yup
            .array()
            .of(yup.string().email('Please enter emails in the correct format'))
            .min(1, 'Please enter a list of semi-colon-separated emails'),
          otherwise: yup.array()
        })
    }),
    includeAppointments: yup.string().when('type', {
      is: 'month',
      then: yup.string().required('Please select a range of appointments to include'),
      otherwise: yup.string()
    }),
    setAsFlatFee: yup.object().shape({
      active: yup.boolean(),
      value: yup.string().when('active', {
        is: true,
        then: yup.string().test('flatFeeValueNumber', 'Please enter numbers only', (value) => !isNaN(Number(value))),
        otherwise: yup.string()
      })
    })
  })
});

const getInitialValues = (allowCommunicationWithEmail?: boolean) =>
  ({
    discount: {
      active: false,
      type: 'percent',
      value: 0
    },
    automatedInvoicing: {
      active: false,
      type: 'appointment',
      includeMedicareDetails: false,
      dueAt: 0,
      issueAt: 'onConfirmed',
      note: '',
      sendToClient: !!allowCommunicationWithEmail,
      sendToOther: {
        active: false,
        emails: []
      },
      includeAppointments: 'current',
      setAsFlatFee: {
        active: false,
        value: 0
      }
    }
  } as Required<clientRecordsInterface['invoiceSummary']>['settings']);

interface PatientInvoiceRulesModalProps {
  clientRecordData: clientRecordsInterface;
  invoiceSettingsPaymentMethodsReady: boolean;
  visible: boolean;
  onClose: (newInvoiceSettings?: clientRecordsInterface['invoiceSummary']['settings']) => void;
}

const PatientInvoiceRulesModal = ({
  clientRecordData,
  invoiceSettingsPaymentMethodsReady,
  visible,
  onClose
}: PatientInvoiceRulesModalProps) => {
  const { auth0ClinicianId } = useGetClinicianId();

  const { data: invoiceSettings } = useGetInvoiceSettingsQuery({ clinicianId: auth0ClinicianId });

  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const handleSubmit = async (values: Required<clientRecordsInterface['invoiceSummary']>['settings']) => {
    setSaveButtonStatus('active');

    try {
      const token = await security.getAccessTokenSilently();

      await putClientInvoiceSettings(token, clientRecordData._id, { invoiceSettings: values });

      setSaveButtonStatus('finished');
      notification.success({
        message: 'Invoice settings updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>,
        onClose: () => setSaveButtonStatus('')
      });

      onClose(values);
    } catch (ex) {
      setSaveButtonStatus('');
      notification.error({
        message: 'Something went wrong while trying to update your invoice settings',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const clientMedicareStatus = clientRecordData.clientProfiles[0].medicare?.status;
  const isClientMedicareValid = !!(
    (clientMedicareStatus?.medicare && [0, 9626].includes(clientMedicareStatus.medicare.code)) ||
    (clientMedicareStatus?.dva && clientMedicareStatus.dva.code === 0)
  );
  const isPracticeConnectedMedicare = invoiceSettings?.medicare?.status === 'connected';

  const clientProfiles =
    clientRecordData.recordType === 'child'
      ? clientRecordData.clientProfiles.filter((profileType) => profileType.role === 'child')
      : clientRecordData.clientProfiles;

  return (
    <Modal
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
      width={800}
      footer={null}
      closable={false}
      open={visible}
      destroyOnClose
    >
      <ModalHeader
        title={`${combineName(clientProfiles, clientRecordData.recordType === 'couple')} Invoice Settings`}
        onCancel={() => onClose()}
      />
      <div className={styles.content}>
        <div className={styles.heading}>
          Control the default settings for all invoices you create for{' '}
          <strong>{combineName(clientProfiles, clientRecordData.recordType === 'couple')}</strong>
          <br />
          You can always change them in each individual invoice.
        </div>
        <Formik
          initialValues={
            clientRecordData.invoiceSummary.settings?.discount
              ? clientRecordData.invoiceSummary.settings
              : getInitialValues(clientRecordData.allowCommunicationWithEmail)
          }
          validationSchema={clientInvoiceSettingsSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <Form noValidate>
            <AutomatedInvoicing
              clientRecordData={clientRecordData}
              invoiceSettingsPaymentMethodsReady={invoiceSettingsPaymentMethodsReady}
              isClientMedicareValid={isClientMedicareValid}
              isPracticeConnectedMedicare={isPracticeConnectedMedicare}
            />
            <IncludeDiscount />
            <div className={styles.buttonContainer}>
              <ButtonAlt status={saveButtonStatus}>Save</ButtonAlt>
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};

export default PatientInvoiceRulesModal;
