import { CreateRoomType, RoomInterface, RoomType } from 'interfaces/Schedule/Room';
import { SSTagTypes, scheduleServicesApiSlice } from 'redux/services/scheduleServicesApiSlice';

export const roomApiSlice = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRoomList: builder.query<RoomType[], {}>({
      query: () => ({
        url: `/rooms`
      }),
      providesTags: [SSTagTypes.RoomList]
    }),
    getAvailableRoom: builder.query<RoomInterface[], { queryParam: string }>({
      query: ({ queryParam }) => ({
        url: `/rooms:getAvailableRooms?${queryParam}`
      }),
      providesTags: [SSTagTypes.AvailableRoom]
    }),
    createRoom: builder.mutation({
      query: ({ payload }: { payload: CreateRoomType }) => ({
        url: `/rooms`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [SSTagTypes.RoomList]
    })
  })
});

export const { useGetRoomListQuery, useGetAvailableRoomQuery, useCreateRoomMutation } = roomApiSlice;
