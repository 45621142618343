import SubTab, { SubTabOption } from 'components/v2/SubTab/SubTab';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from '../SubTabLayout.module.scss';
import { PractitionerTabId } from 'pages/PractitionersListing/PractitionersListing';
import { PractitionersListingCount } from 'interfaces/Practitioners/practitionersListing';
import { useState, useEffect, useContext } from 'react';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useLocation } from 'react-router-dom';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ModalVisibilityContext } from 'components/SideDashboardT23/ModalVisibilityContext';
import queryString from 'query-string';
import PractitionerControlPanelSubTab from './PractitionerControlPanelSubTab/PractitionerControlPanelSubTab';
import { useGetRecentlyViewed } from 'utils/hooks/SubTab/recentlyViewed';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { UserContext } from 'utils/UserContextProvider';

const PractitionerSubTab = ({ practitionersCount }: { practitionersCount: PractitionersListingCount }) => {
  const location = useLocation();
  const { clinicianProfile } = useContext(UserContext);
  const { isEdgeAdminView } = useGetAccountPackageView();
  const queryParam: { status?: PractitionerTabId } = queryString.parse(location.search);
  const { isEdgePlanUser } = useGetAccountPackage();
  const isPractitionerPage = isEdgePlanUser
    ? location.pathname.split('/')[2] === 'practitioners'
    : location.pathname.split('/')[1] === 'practitioners';
  const isPractitionerManagePage = isEdgePlanUser
    ? location.pathname.split('/')[3] === 'manage'
    : location.pathname.split('/')[2] === 'manage';
  const getFilterType: PractitionerTabId | string =
    isPractitionerPage && !isPractitionerManagePage ? queryParam.status ?? 'active' : '';

  const params = decodeURI(location.pathname).match(/.*\/(auth0\|.+)\/(.*)(\/.*)?/) || [];
  const practitionerId = params[1];

  const [filterType, setFilterType] = useState<PractitionerTabId | string>(getFilterType);
  const { PRACTITIONER } = useRoutesGenerator();

  const clinicianId = clinicianProfile ? clinicianProfile._id : '';
  const recentlyPractitionerViewed = useGetRecentlyViewed({
    storageName: 'team_viewed',
    clinicianProfileId: clinicianId,
    isEdgeAdminView,
    max: 8
  });

  useEffect(() => {
    setFilterType(getFilterType);
  }, [getFilterType]);

  const modalVisibility = useContext(ModalVisibilityContext);

  const showPractitionerSubTab = !practitionerId;

  const baseHref = `${PRACTITIONER.BASE}/?page=1&status=`;

  return (
    <div className={styles.container}>
      {showPractitionerSubTab && (
        <>
          {isEdgeAdminView && (
            <div className={styles.section}>
              <ButtonAlt
                icon="add_circle_outline"
                color="white"
                variant={'contained'}
                fullWidth
                onClick={() => modalVisibility?.setAddPractitionerModalVisible(true)}
              >
                Add New Teammate
              </ButtonAlt>
            </div>
          )}
          <div className={styles.section}>
            <SubTab
              title="VIEW BY STATUS"
              options={[
                {
                  label: 'Active',
                  number: practitionersCount.active,
                  value: PractitionerTabId.Active,
                  href: `${baseHref}${PractitionerTabId.Active}`
                },
                {
                  label: 'Closed',
                  number: practitionersCount.inactive,
                  value: PractitionerTabId.Inactive,
                  href: `${baseHref}${PractitionerTabId.Inactive}`
                }
              ]}
              activeOption={filterType}
            />
          </div>
        </>
      )}
      {practitionerId && <PractitionerControlPanelSubTab practitionerId={practitionerId} />}
      {showPractitionerSubTab && (
        <div className={styles.section}>
          <SubTab
            title="RECENTLY VIEWED"
            options={recentlyPractitionerViewed.map(
              (item) => ({ ...item, href: `${PRACTITIONER.BASE}/${item.value}/profile` } as SubTabOption)
            )}
            activeOption={filterType}
          />
        </div>
      )}
      {/*<div className={styles.section}>*/}
      {/*  <SubTab*/}
      {/*    title="SAVED VIEWS"*/}
      {/*    onChange={() => {}}*/}
      {/*    noOptionText="Control your team. Add or deactivate staff and control their role permissions."*/}
      {/*    noOptionLinkText="More on Team Setup"*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};

export default PractitionerSubTab;
