import styles from './UpdateBalanceModal.module.scss';
import ModalV2 from 'components/ModalV2/ModalV2';
import { InvoiceWithType, ManualBalanceType } from '../InvoiceList/constants';
import InvoiceDetailsForModal from '../InvoiceDetailsForModal/InvoiceDetailsForModal';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { ChangeEvent, useMemo, useState } from 'react';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { UpdateBalanceInvoice } from 'redux/invoices/invoiceSlice';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import AppointmentDatePicker from 'pages/Calendar/components/CalendarWithHighlightsT23/components/CalendarView/components/InlineBookingModal/components/AppointmentDatePicker/AppointmentDatePicker';
import classNames from 'classnames';
import { config } from 'config/config';
import { convertCurrencyValue, sanitizedCurrencyValue } from 'utils/currencyFormatConverter';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { useManualUpdateBalanceMutation } from 'redux/endpoints/billingServices/invoices';
import { notification } from 'antd';
import moment from 'moment';

interface UpdateBalanceModalProps {
  showModal: boolean;
  invoice: InvoiceWithType;
  formValue: UpdateBalanceInvoice | undefined;
  onCloseModal: () => void;
  onChangeValue: (value: Partial<UpdateBalanceInvoice>) => void;
  onSubmitSuccess?: () => void;
}

type FormError = Omit<UpdateBalanceInvoice, 'reference'>;
const FormErrorInitialValue: FormError = {
  type: '',
  date: '',
  amount: ''
};

const TYPE_OPTIONS: IOptionItem[] = [
  { value: ManualBalanceType.Cash, label: 'Cash Payment' },
  { value: ManualBalanceType.OnlineTransfer, label: 'Online Transfer' },
  { value: ManualBalanceType.CreditNote, label: 'Credit Note' },
  { value: ManualBalanceType.ManualRefund, label: 'Manual Refund' },
  { value: ManualBalanceType.Others, label: 'Others' }
];

const UpdateBalanceModal = ({
  showModal,
  invoice,
  formValue,
  onCloseModal,
  onChangeValue
}: UpdateBalanceModalProps) => {
  const { currencySymbol } = config;

  const [formError, setFormError] = useState<FormError>(FormErrorInitialValue);
  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');

  const [postManualUpdateBalance] = useManualUpdateBalanceMutation();

  const { owed = 0, accountId, _id, paid, invoiceAmount } = invoice;
  const { type, reference, date, amount } = formValue || {};

  const handleCloseModal = () => {
    setFormError(FormErrorInitialValue);
    onCloseModal();
  };

  const onChangeType = (value: string) => {
    onChangeValue({ type: value });
    setFormError({
      ...formError,
      type: ''
    });
  };

  const onChangeReference = (value: string) => {
    onChangeValue({ reference: value });
  };

  const onChangeDate = (value: Date) => {
    onChangeValue({ date: moment(value).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT) });
    setFormError({
      ...formError,
      date: ''
    });
  };

  const onChangeAmount = (value: string) => {
    onChangeValue({ amount: sanitizedCurrencyValue(value) });
    setFormError({
      ...formError,
      amount: ''
    });
  };

  const handleSubmit = async () => {
    const amountInNumber = Number(amount || '');
    const isValidAmount =
      !Number.isNaN(amountInNumber) &&
      amountInNumber > 0 &&
      (type === ManualBalanceType.ManualRefund ? paid?.total && amountInNumber <= paid.total : amountInNumber <= owed);
    if (type && date && isValidAmount) {
      setSubmitButtonStatus('active');
      try {
        await postManualUpdateBalance({
          accountId,
          invoiceId: _id,
          body: {
            type: type.toString(),
            ...(reference && {
              reference
            }),
            date,
            amount: Number(amount)
          }
        }).unwrap();

        notification.success({
          message: 'Balance updated',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitButtonStatus('finished');
        handleCloseModal();
      } catch (ex) {
        notification.error({
          message: 'Update failed. Please try again, if failures persist please contact us to investigate.'
        });
        setSubmitButtonStatus('');
      }
    } else {
      setFormError({
        type: !type ? 'Required' : '',
        date: !date ? 'Required' : '',
        amount: !isValidAmount ? 'Error' : ''
      });
    }
  };

  const TypeOptions = useMemo(() => {
    if (paid) {
      if (paid.total === invoiceAmount) {
        return TYPE_OPTIONS.filter((option) => option.value === ManualBalanceType.ManualRefund);
      }

      if (paid.total === 0) {
        return TYPE_OPTIONS.filter((option) => option.value !== ManualBalanceType.ManualRefund);
      }

      return TYPE_OPTIONS;
    }

    return TYPE_OPTIONS.filter((option) => option.value !== ManualBalanceType.ManualRefund);
  }, [invoiceAmount, paid]);

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={showModal}
      title="Update Balance"
      onModalClose={handleCloseModal}
      bodyStyle={{ padding: '0' }}
      headerContainerClassName={styles.headerContainerClassName}
      titleClassName={styles.titleClassName}
    >
      <div className={styles.container}>
        {/* Invoice details */}
        <InvoiceDetailsForModal invoice={invoice} />

        <div className={styles.form}>
          <div className={styles.rowContainer}>
            <DropdownSearchable
              className={styles.typeDropdown}
              controllerClassName={styles.typeController}
              labelClassName={styles.typeDropdownLabel}
              placeholder="Select type"
              options={TypeOptions}
              selected={type}
              showBorderBottom
              borderBottomClassName={formError.type ? styles.borderBottomError : styles.borderBottom}
              optionWrapperClassName={styles.optionWrapper}
              onSelect={onChangeType}
              error={formError.type}
              hideErrorDesc
            />

            <TitleInput
              inputContainer={styles.referenceInput}
              inputProps={{
                maxLength: 18,
                placeholder: 'Enter reference',
                value: reference || '',
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  onChangeReference(e.target.value);
                }
              }}
              hideErrorDesc
              defaultLine
            />
          </div>

          <div className={styles.dateContainer}>
            <div className={styles.dateInputHeader}>Date</div>
            <AppointmentDatePicker
              error={formError.date}
              placeholder="Select date"
              labelFormat={MOMENTJS_DATE_FORMAT}
              selectedDate={date ? new Date(date) : undefined}
              hideErrorDesc
              showBorderBottom
              icon="calendar_today"
              iconClassName={classNames('material-icons-outlined', styles.calendarIcon)}
              controllerClassName={styles.dateController}
              labelClassName={styles.dateLabel}
              borderBottomClassName={formError.date ? styles.calendarBorderBottomError : styles.calendarBorderBottom}
              onSelect={onChangeDate}
            />
          </div>

          <div className={styles.amountInputContainer}>
            <TitleInput
              inputContainerClassName={formError.amount ? styles.inputErrorClassName : styles.inputClassName}
              prefix={currencySymbol}
              inputContainer={styles.amountInput}
              inputProps={{
                maxLength: 18,
                placeholder: 'Enter amount',
                value: convertCurrencyValue(amount || ''),
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  onChangeAmount(e.target.value);
                }
              }}
              error={formError.amount}
              hideErrorDesc
            />
            <div className={classNames(styles.amountNote, formError.amount ? styles.amountError : '')}>
              Payment amount must be equal or less than {currencySymbol}{' '}
              {type === ManualBalanceType.ManualRefund ? 'paid ' : 'owed '} value
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <ButtonAlt
            status={submitButtonStatus}
            loadingWord="Processing..."
            completedWord="Done"
            onClick={handleSubmit}
          >
            Confirm and save
          </ButtonAlt>
        </div>
      </div>
    </ModalV2>
  );
};

export default UpdateBalanceModal;
