import { FunderNameMapping, PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';
import styles from './CreateClaimPeeking.module.scss';
import momentTz from 'moment-timezone';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useTimeZone } from 'utils/hooks/useTimeZone';

interface CreateClaimPeekingProps {
  isClaimReady: boolean;
  appointmentStartDateTime: string;
  funder?: PendingClaimsFunder;
  mbsCode?: string;
  clinicianName?: string;
  providerNumber?: string;
  onSubmit: () => void;
  onEdit: () => void;
}

const CreateClaimPeeking = ({
  isClaimReady,
  appointmentStartDateTime,
  funder,
  mbsCode,
  clinicianName,
  providerNumber,
  onSubmit,
  onEdit
}: CreateClaimPeekingProps) => {
  const { timeZoneByView } = useTimeZone();

  return (
    <div className={styles.container}>
      <div className={styles.createClaimItem}>
        <div className={styles.label}>Type:</div>
        <div className={styles.value}>{funder && FunderNameMapping[funder]}</div>
      </div>
      <div className={styles.createClaimItem}>
        <div className={styles.label}>MBS Code:</div>
        <div className={styles.value}>{mbsCode}</div>
      </div>
      <div className={styles.createClaimItem}>
        <div className={styles.value}>
          {clinicianName} {providerNumber}
        </div>
      </div>
      <div className={`${styles.createClaimItem} ${styles.column}`}>
        <div className={styles.label}>Delivery Date:</div>
        <div className={styles.value}>
          {momentTz.tz(appointmentStartDateTime, timeZoneByView).format('HH:mm [on] DD/MM/YYYY')}
        </div>
      </div>
      <div className={styles.createClaimItem}>
        <ButtonAlt disabled={!isClaimReady} onClick={onSubmit} size="small">
          Submit
        </ButtonAlt>
        <ButtonAlt onClick={onEdit} size="small" variant="text" icon="mode_edit">
          Edit
        </ButtonAlt>
      </div>
    </div>
  );
};

export default CreateClaimPeeking;
