import MedicareImage from 'assets/images/medicare.svg';
import { useMemo, useState } from 'react';
import classnames from 'classnames';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import { handleCopyContent } from 'utils/CopyFeature';

import styles from './MedicareInformation.module.scss';
import EditMedicareDetailsModal from './components/EditMedicareDetailsModal/EditMedicareDetailsModal';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import MedicareCheck from './components/MedicareCheck/MedicareCheck';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { getMedicareProfilesFromClientRecord } from 'utils/helpers/getMedicareProfilesFromClientRecord';

interface MedicareInformationProps {
  clientRecord: clientRecordsInterface;
  isMedicareClient: boolean;
  isDvaClient: boolean;
  isGeneralPractitionerLoading: boolean;
  isGPReferralProviderNumberSet: boolean;
  isReferralDateSet: boolean;
  fetchGeneralPractitioner: () => Promise<void>;
}

export const MedicareInformation = ({
  clientRecord,
  isMedicareClient,
  isDvaClient,
  isGeneralPractitionerLoading,
  isGPReferralProviderNumberSet,
  isReferralDateSet,
  fetchGeneralPractitioner
}: MedicareInformationProps) => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const { clientProfile, parentMedicareProfile: parentProfile } = getMedicareProfilesFromClientRecord(clientRecord);

  const {
    firstName,
    lastName,
    dateOfBirth,
    medicareNumber,
    irn,
    expiryDate,
    dva,
    status,
    updatedAt,
    isMedicareSet,
    isDvaSet,
    parent,
    dvaCardDetailsType
  } = useMemo(() => {
    const { firstName, lastName, dateOfBirth, medicare } = clientProfile;

    return {
      firstName: medicare?.firstName || firstName,
      lastName: medicare?.lastName || lastName,
      dateOfBirth: medicare?.dateOfBirth
        ? moment(medicare.dateOfBirth, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format(MOMENTJS_DATE_FORMAT)
        : dateOfBirth || '-',
      medicareNumber: medicare?.number || '-',
      irn: medicare?.irn || '-',
      dva: medicare?.dva || '-',
      expiryDate: medicare?.expiryDate || '-',
      status: medicare?.status,
      updatedAt: medicare?.updatedAt,
      isMedicareSet: !!(medicare?.number && medicare?.irn),
      isDvaSet: !!medicare?.dva,
      ...(parentProfile?.medicare && {
        parent: {
          firstName: parentProfile.medicare?.firstName || parentProfile.firstName,
          lastName: parentProfile.medicare?.lastName || parentProfile.lastName,
          dateOfBirth: parentProfile.medicare?.dateOfBirth
            ? moment(parentProfile.medicare.dateOfBirth, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format(MOMENTJS_DATE_FORMAT)
            : parentProfile.dateOfBirth || '-',
          medicareNumber: parentProfile.medicare?.number || '-',
          irn: parentProfile.medicare?.irn || '-',
          expiryDate: medicare?.expiryDate || '-',
          status: parentProfile.medicare?.status,
          isMedicareSet: !!(parentProfile.medicare?.number && parentProfile.medicare?.irn)
        }
      }),
      dvaCardDetailsType: medicare?.dvaCardDetails?.type
    };
  }, [clientProfile, parentProfile]);

  const handleCloseEditModal = () => {
    setIsEditModalVisible(false);
  };

  return (
    <>
      <EditMedicareDetailsModal
        clientRecord={clientRecord}
        visible={isEditModalVisible}
        onCancel={handleCloseEditModal}
      />
      <div className={styles.container}>
        <div className={styles.header}>Medicare Information</div>
        <div className={styles.card}>
          <div className={styles.title}>
            MEDICARE INFORMATION
            <ButtonAlt
              className={styles.button}
              onClick={() => setIsEditModalVisible(true)}
              size={'medium'}
              variant={'text'}
              icon={isMedicareSet || isDvaSet ? 'create' : 'add_circle_outline'}
            >
              {isMedicareSet || isDvaSet ? 'Edit' : 'Add Details'}
            </ButtonAlt>
          </div>
          <img className={styles.medicareLogo} src={MedicareImage} alt="Medicare logo" />
          <div className={styles.details}>
            <div className={styles.column}>
              {parent && (
                <div className={styles.item}>
                  <div className={styles.label}> </div>
                  <div className={styles.title}>CHILD</div>
                </div>
              )}

              <div className={styles.item}>
                <div className={styles.label}>First Name</div>
                <div className={styles.value}>{firstName}</div>
              </div>
              <div className={styles.item}>
                <div className={styles.label}>Last Name</div>
                <div className={styles.value}>{lastName}</div>
              </div>
              <div className={styles.item}>
                <div className={styles.label}>Date of Birth</div>
                <div className={styles.value}>{dateOfBirth}</div>
              </div>
              <div className={styles.item}>
                <div className={styles.label}>Medicare #</div>
                <div
                  className={classnames(`${styles.value} ${styles.medicareNumber}`, isMedicareSet && styles.clickable)}
                  onClick={() => isMedicareSet && handleCopyContent(`${medicareNumber}`)}
                >
                  {medicareNumber}
                  {isMedicareSet && <span className={`material-icons ${styles.icon}`}>content_copy</span>}
                </div>
              </div>
              {isMedicareSet && (
                <div className={styles.item}>
                  <div className={styles.label}>IRN</div>
                  <div className={styles.value}>{irn}</div>
                </div>
              )}
              <div className={styles.item}>
                <div className={styles.label}>DVA #</div>
                <div
                  className={classnames(`${styles.value} ${styles.dva}`, isDvaSet && styles.clickable)}
                  onClick={() => isDvaSet && handleCopyContent(`${dva}`)}
                >
                  {dva}
                  {isDvaSet && <span className={`material-icons ${styles.icon}`}>content_copy</span>}
                </div>
              </div>
              {(isMedicareSet || isDvaSet) && (
                <div className={styles.item}>
                  <div className={styles.label}>Expiry Date</div>
                  <div className={styles.value}>{expiryDate}</div>
                </div>
              )}
              <div className={styles.item}>
                <div className={styles.label}>Medicare Client?</div>
                <div className={classnames(styles.value, (isMedicareSet || isDvaSet) && styles.active)}>
                  {isMedicareClient || isDvaClient
                    ? `YES - ${isMedicareClient ? 'Bulkbill/Rebate' : ''}${
                        isMedicareClient && isDvaClient ? ', ' : ''
                      }${isDvaClient ? 'DVA' : ''}`
                    : 'No'}
                </div>
              </div>
              {isDvaSet && dvaCardDetailsType && (
                <div className={styles.item}>
                  <div className={styles.label}>Card Colour</div>
                  <div className={classnames(styles.value, styles.cardColour)}>{dvaCardDetailsType}</div>
                </div>
              )}
            </div>
            {parent && (
              <div className={styles.column2}>
                <div className={styles.title}>PARENT</div>
                <div className={styles.value}>{parent.firstName}</div>
                <div className={styles.value}>{parent.lastName}</div>
                <div className={styles.value}>{parent.dateOfBirth}</div>

                <div
                  className={classnames(
                    `${styles.value} ${styles.medicareNumber}`,
                    parent.isMedicareSet && styles.clickable
                  )}
                  onClick={() => parent.isMedicareSet && handleCopyContent(`${parent.medicareNumber}`)}
                >
                  {parent.medicareNumber}
                  {parent.isMedicareSet && parent.medicareNumber && (
                    <span className={`material-icons ${styles.icon}`}>content_copy</span>
                  )}
                </div>
                {parent.isMedicareSet && <div className={styles.value}>{parent.irn}</div>}
                <div className={classnames(`${styles.value} ${styles.dva}`)}>-</div>
                {parent.isMedicareSet && <div className={styles.value}>{parent.expiryDate}</div>}
                <div className={classnames(styles.value, parent.isMedicareSet && styles.active)}>
                  {isMedicareClient ? 'YES - Bulkbill/Rebate' : 'No'}
                </div>
              </div>
            )}
          </div>
          {(isMedicareSet || isDvaSet) && (
            <MedicareCheck
              clientRecordId={clientRecord._id}
              clientProfileId={clientProfile._id}
              status={status}
              parentStatus={parent?.status}
              updatedAt={updatedAt}
              isGeneralPractitionerLoading={isGeneralPractitionerLoading}
              isGPReferralProviderNumberSet={isGPReferralProviderNumberSet}
              isReferralDateSet={isReferralDateSet}
              fetchGeneralPractitioner={fetchGeneralPractitioner}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MedicareInformation;
