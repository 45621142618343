import { config } from 'config/config';

const { currencySymbol, currencyCode } = config;

/**
 * We're deprecating this hook, use config.ts instead
 */
export const useCurrency = () => {
  return {
    CURRENCY_SYMBOL: currencySymbol,
    CURRENCY_DECIMAL: 2, // TODO: Add more logic
    CURRENCY_TYPE: currencyCode,
    formatCurrency: (value: number) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencySymbol === '$' ? 'USD' : currencyCode
      }).format(value)
  };
};
