import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetMinifiedPackagesQuery } from 'redux/endpoints/scheduleServices/package';

export const useGetMinifiedPackages = (
  params?: { page?: number; perPage?: number; filterByIds?: string[]; searchValue?: string; searchBy?: string },
  options?: { skip?: boolean }
) => {
  const { accountId } = useGetAccountId();
  const { isPackageEnabled } = useGetFeatureToggle();

  const { data, isLoading, isFetching, isError, refetch } = useGetMinifiedPackagesQuery(
    {
      accountId,
      params
    },
    { skip: !accountId || !isPackageEnabled || options?.skip }
  );

  return {
    isLoading,
    isFetching,
    isError,
    packages: data?.packages || [],
    paging: data?.paging || { totalItems: 0, page: 1, perPage: params?.perPage },
    totalItems: data?.paging.totalItems || 0,
    refetch
  };
};
