import { combineReducers } from 'redux';
import { checkInServiceApiSlice } from './services/checkInServiceApiSlice';
import { billingServicesApiSlice } from './services/billingServicesApiSlice';
import { scheduleServicesApiSlice } from './services/scheduleServicesApiSlice';
import { documentServicesApiSlice } from './services/documentServicesApiSlice';
import { notificationServicesApiSlice } from './services/notificationServicesApiSlice';
import { clinicianProfileServicesApiSlice } from './services/clinicianProfileServicesApiSlice';
import { activityFeedServicesApiSlice } from './services/activityFeedServicesApiSlice';
import clientListSlice from './clients/clientListSlice';
import clientListColumnsSettingsSlice from './clients/clientListColumnsSettingsSlice';
import pendingClaimInvoiceSlice from './features/pendingClaimInvoices/pendingClaimInvoiceSlice';
import submittedClaimSlice from './features/submittedClaims/submittedClaimSlice';
import appointmentTypeSlice from './appointmentTypes/appointmentTypesSlice';
import reportSlice from './reports/reportSlice';
import groupSlice from './groups/groupSlice';
import carePlanListSlice from './carePlan/carePlanListSlice';
import carePlanCreatorSlice from './carePlan/carePlanCreatorSlice';
import carePlanEditorSlice from './carePlan/carePlanEditorSlice';
import broadcastMessageSlice from './features/messages/broadcastMessageSlice';
import letterSlice from './letters/letterSlice';
import invoiceTemplateCreateFormSlice from './invoiceTemplate/invoiceTemplateCreateFormSlice';
import appointmentCreationSlice from './features/appointmentCreation/appointmentCreationSlice';
import createInvoiceWithTemplateSlice from './invoices/createInvoiceWithTemplateSlice';
import episodeSlice from './episodes/episodeSlice';
import processAppointmentSlice from './processAppointment/processAppointmentSlice';
import packageListSlice from './packages/packageListSlice';
import addressBookSlice from './addressBook/addressBookSlice';
import invoiceReviewSlice from './invoices/invoiceReviewSlice';
import invoiceSlice from './invoices/invoiceSlice';
import activeTasksSlice from './features/tasks/activeTasksSlice';
import closedTasksSlice from './features/tasks/closedTasksSlice';
import clientDetailsSlice from './clients/clientDetailsSlice';
import referralSlice from './referrals/referralSlice';
import invoicePaymentsSlice from './features/invoicePayments/invoicePaymentsSlice';
import packageAssignmentListSlice from './packages/packageAssigmentListSlice';
import writeOffInvoiceSlice from './invoices/writeOffInvoiceSlice';
import practitionerReportSlice from './features/practitionerReport/practitionerReportSlice';
import practiceDataSlice from './slice/practiceDataSlice';
import calendarAppointmentListDataSlice from './calendarAppointmentList/calendarAppointmentListDataSlice';

const rootReducer = combineReducers({
  clientListColumnsSettingsSlice: clientListColumnsSettingsSlice,
  clientListSlice: clientListSlice,
  pendingClaimInvoiceSlice: pendingClaimInvoiceSlice,
  submittedClaimSlice: submittedClaimSlice,
  appointmentTypeSlice: appointmentTypeSlice,
  reportSlice: reportSlice,
  letterSlice: letterSlice,
  groupSlice: groupSlice,
  carePlanListSlice: carePlanListSlice,
  carePlanCreatorSlice: carePlanCreatorSlice,
  carePlanEditorSlice: carePlanEditorSlice,
  broadcastMessageSlice: broadcastMessageSlice,
  invoiceTemplateCreateFormSlice: invoiceTemplateCreateFormSlice,
  appointmentCreationSlice: appointmentCreationSlice,
  createInvoiceWithTemplateSlice: createInvoiceWithTemplateSlice,
  episodeSlice: episodeSlice,
  processAppointmentSlice: processAppointmentSlice,
  packageListSlice: packageListSlice,
  packageAssignmentListSlice: packageAssignmentListSlice,
  addressBookSlice: addressBookSlice,
  invoiceReviewSlice: invoiceReviewSlice,
  invoiceSlice: invoiceSlice,
  activeTasksSlice: activeTasksSlice,
  closedTasksSlice: closedTasksSlice,
  clientDetailsSlice: clientDetailsSlice,
  referralSlice: referralSlice,
  invoicePaymentsSlice: invoicePaymentsSlice,
  writeOffInvoiceSlice: writeOffInvoiceSlice,
  practitionerReportSlice: practitionerReportSlice,
  practiceDataSlice: practiceDataSlice,
  calendarAppointmentListDataSlice: calendarAppointmentListDataSlice,
  [clinicianProfileServicesApiSlice.reducerPath]: clinicianProfileServicesApiSlice.reducer,
  [scheduleServicesApiSlice.reducerPath]: scheduleServicesApiSlice.reducer,
  [billingServicesApiSlice.reducerPath]: billingServicesApiSlice.reducer,
  [documentServicesApiSlice.reducerPath]: documentServicesApiSlice.reducer,
  [notificationServicesApiSlice.reducerPath]: notificationServicesApiSlice.reducer,
  [checkInServiceApiSlice.reducerPath]: checkInServiceApiSlice.reducer,
  [activityFeedServicesApiSlice.reducerPath]: activityFeedServicesApiSlice.reducer
});

export default rootReducer;
