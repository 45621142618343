import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import ButtonSelectAll from 'components/v2/ButtonSelectAll/ButtonSelectAll';
import SearchBar from 'components/v2/SearchBar/SearchBar';
import { createRef, useMemo, useState } from 'react';
import CalendarFilterCheckItem from '../CalendarFilterCheckItem/CalendarFilterCheckItem';
import HighLightFilterCheckList from '../HighLightFilterCheckList/HighLightFilterCheckList';
import styles from './FilterCheckList.module.scss';

const SCROLL_ROWS = 3;

export interface FilterCheckListItem {
  _id: string;
  name: string;
  color: string;
}

export interface FilterCheckListProps {
  items: FilterCheckListItem[];
  selectedFilterList: FilterCheckListItem[];
  onChangeItem: (value: {
    item?: FilterCheckListItem;
    toggleAllValue?: boolean;
    color?: string;
    searchValue?: string;
  }) => void;
  searchable?: boolean;
  loading?: boolean;
  notFoundText?: string;
  showToggleAllButtons?: boolean;
  title?: string;
}

// this having same name but only using on filter
const FilterCheckList = ({
  items,
  selectedFilterList,
  onChangeItem,
  searchable,
  loading,
  notFoundText,
  showToggleAllButtons,
  title
}: FilterCheckListProps) => {
  const [searchValue, setSearchValue] = useState('');
  const searchItems = items
    .map((item) => selectedFilterList.find((i) => i._id === item._id) || item)
    .filter((item) => item.name?.toLowerCase().includes(searchValue.toLowerCase()));
  const ref = createRef<HTMLDivElement>();
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const onScrollClick = () => {
    ref?.current?.scrollBy({
      top: SCROLL_ROWS * (ref.current.scrollHeight / searchItems.length),
      behavior: 'smooth'
    });
  };

  const sortedList = useMemo(() => {
    return searchItems.slice().sort((a, b) => {
      if (a._id === '') {
        return -1;
      } else if (b._id === '') {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
  }, [searchItems]);

  const ableSelectAll = useMemo(() => {
    return (
      searchItems.length !==
      selectedFilterList.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase())).length
    );
  }, [searchItems, selectedFilterList, searchValue]);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <>
          {searchable && (
            <div className={styles.searchBoxWrapper}>
              <SearchBar
                containerClassName={styles.searchBox}
                clearTextIcon
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          )}
          {showToggleAllButtons && (
            <div className={styles.actions}>
              <ButtonSelectAll
                className={styles.actionsButton}
                onClick={() => onChangeItem({ toggleAllValue: ableSelectAll, searchValue })}
                ableSelectAll={ableSelectAll}
              />
            </div>
          )}
          {title === 'HIGHLIGHT' ? (
            <HighLightFilterCheckList
              items={sortedList}
              onChangeItem={onChangeItem}
              searchValue={searchValue}
              selectedFilterList={selectedFilterList}
            />
          ) : (
            <>
              <div
                className={styles.items}
                ref={ref}
                onScroll={(e) => {
                  if (ref.current) {
                    if (ref.current.scrollHeight - (ref.current.scrollTop + ref.current.clientHeight) < 10) {
                      setIsScrolledToBottom(true);
                    } else {
                      setIsScrolledToBottom(false);
                    }
                  }
                }}
              >
                {sortedList.length > 0 ? (
                  sortedList.map((item, index) => {
                    const isChecked = !!selectedFilterList.find((i) => i._id === item._id);
                    return (
                      /* List of staff */
                      <CalendarFilterCheckItem
                        key={index}
                        id={`${item._id}${index}`}
                        item={item}
                        onChangeItem={onChangeItem}
                        searchValue={searchValue}
                        isChecked={isChecked}
                      />
                    );
                  })
                ) : searchValue.length > 0 && sortedList.length === 0 ? (
                  <div className={styles.noItems}>
                    <i className="material-icons-outlined">search_off</i>
                    {notFoundText || 'No matching items found'}
                  </div>
                ) : (
                  <div className={styles.noItems}>No items found</div>
                )}
              </div>
              {sortedList.length > 10 && !isScrolledToBottom ? (
                <div
                  className={classNames(styles.scrollDownText, isScrolledToBottom && styles.scrolledToBottom)}
                  onClick={onScrollClick}
                >
                  <div className={styles.scroll}>
                    <i className="material-icons-outlined">arrow_downward</i>
                  </div>
                </div>
              ) : (
                <div className={styles.emptyScroll} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FilterCheckList;
