import {
  CreateGroupRequestPayload,
  CreateGroupValidateField
} from 'components/Groups/CreateGroupModal/CreateGroupModalInterfaces';
import { putCheckGroupIdAvailability } from 'utils/http/ClinicianProfileService/Groups/Groups';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import * as yup from 'yup';

const groupFormSchema = yup.object().shape({
  name: yup.string().required('Please enter the group name')
});

export const validationGroupNameForm = (groupVal: CreateGroupRequestPayload) => {
  const validationErrors: CreateGroupValidateField = {
    name: ''
  };
  try {
    groupFormSchema.validateSync(groupVal, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof CreateGroupValidateField] = error.message;
        }
      });
      return validationErrors;
    }
  }
};

export const validateGroupId = async (token: string, accountId: string, groupId: string) => {
  try {
    const body = {
      groupId
    };
    await putCheckGroupIdAvailability(token, accountId, body);
    return {
      groupId,
      statusCode: 200
    };
  } catch (ex) {
    if (isErrorBentStatusError(ex)) {
      return {
        groupId,
        statusCode: ex.statusCode
      };
    }
    throw ex;
  }
};
