import { DatePicker, DatePickerProps, Skeleton } from 'antd';
import styles from './InvoicePaymentsFilter.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import { useGetMinifiedClientRecordQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useCallback, useMemo, useState } from 'react';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  InvoicePaymentsTab,
  resetFilters,
  resetPaging,
  selectActiveTab,
  selectFilters,
  selectSearch,
  setFilters,
  setSearch
} from 'redux/features/invoicePayments/invoicePaymentsSlice';
import { debounce } from 'lodash';
import { useGetAppointmentTypesQuery } from 'redux/endpoints/scheduleServices/appointmentType';
import classNames from 'classnames';
import customStyles from 'pages/InvoiceSubmittedClaims/components/SubmittedClaimFilter/DateRangePickerCustom.module.scss';
import moment, { Moment } from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import SearchBarT23 from 'components/SearchBarT23/SearchBarT23';
import { ScheduledPaymentSearchByItems, SearchByItems } from './utils';

const PER_PAGE = 50;
const DATE_RANGE_LIMIT = 30;

type RangeValue = [Moment | null, Moment | null] | null;
const { RangePicker } = DatePicker;

interface InvoicePaymentsFilterProps {
  isLoading: boolean;
  totalItems: number;
}

const InvoicePaymentsFilter = ({ isLoading, totalItems }: InvoicePaymentsFilterProps) => {
  const dispatch = useAppDispatch();
  const { accountId } = useGetAccountId();
  const filters = useAppSelector(selectFilters);
  const search = useAppSelector(selectSearch);
  const activeTab = useAppSelector(selectActiveTab);

  const { searchValue, searchBy } = search;

  const [clientListCurrentPage, setClientListCurrentPage] = useState<number>(1);
  const [appointmentTypeCurrentPage, setAppointmentTypeCurrentPage] = useState<number>(1);
  const [clientSearchText, setClientSearchText] = useState<string>('');
  const [appointmentTypeSearchText, setAppointmentTypeSearchText] = useState<string>('');
  const [dates, setDates] = useState<RangeValue>(null);

  const {
    data: clientListData,
    isLoading: isClientListDataLoading,
    isFetching: isClientListDataFetching
  } = useGetMinifiedClientRecordQuery({
    accountId,
    params: {
      page: clientListCurrentPage,
      perPage: PER_PAGE,
      recordStatus: 'active',
      ...(clientSearchText && {
        searchValue: clientSearchText,
        searchBy: SearchFilterEnum.FirstOrLastName
      })
    }
  });

  const {
    data: appointmentTypesData,
    isLoading: isAppointmentTypesLoading,
    isFetching: isAppointmentTypesFetching
  } = useGetAppointmentTypesQuery({
    participantType: 'oneToOne',
    page: appointmentTypeCurrentPage,
    perPage: PER_PAGE,
    isActive: '1',
    name: appointmentTypeSearchText,
    infiniteLoad: true
  });

  const onClearFilter = () => {
    dispatch(resetPaging());
    dispatch(resetFilters());
    setDates(null);
  };

  const onChangeClientFilter = (clients: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, clients }));
  };

  const onChangeAppointmentTypeFilter = (appointmentTypes: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, appointmentTypes }));
  };

  const onSearchHandle = (value: string) => {
    dispatch(resetPaging());
    dispatch(setSearch({ ...search, searchValue: value }));
  };

  const handleChangeSearchBy = (searchBy: string) => {
    dispatch(setSearch({ ...search, searchBy }));
  };

  const onChangeSubmittedDateFilter = (dateString: [string, string] | string) => {
    dispatch(resetPaging());
    dispatch(
      setFilters({
        ...filters,
        ...(filters.createdAt && {
          createdAt: {
            ...(dateString[0] && {
              from: dateString[0]
            }),
            ...(dateString[1] && {
              to: dateString[1]
            })
          }
        })
      })
    );
  };

  const disabledDate = useCallback(
    (current: Moment) => {
      const tomorrowDate = moment().add(1, 'day').startOf('day');
      let tooLate = false;
      let tooEarly = false;

      if (dates) {
        tooLate = !!(dates[0] && current.diff(dates[0], 'days') > DATE_RANGE_LIMIT);
        tooEarly = !!(dates[1] && dates[1].diff(current, 'days') > DATE_RANGE_LIMIT);
      }

      return current.startOf('day') >= tomorrowDate || tooEarly || tooLate;
    },
    [dates]
  );

  const scheduledPaymentDisabledDate = useCallback(
    (current: Moment) => {
      const today = moment().startOf('day');
      let tooLate = false;
      let tooEarly = false;

      if (dates) {
        tooLate = !!(dates[0] && current.diff(dates[0], 'days') > DATE_RANGE_LIMIT);
        tooEarly = !!(dates[1] && dates[1].diff(current, 'days') > DATE_RANGE_LIMIT);
      }

      return current.startOf('day') < today || tooEarly || tooLate;
    },
    [dates]
  );

  const debounceSetClientSearchText = useMemo(
    () =>
      debounce((value) => {
        setClientSearchText(value);
        setClientListCurrentPage(1);
      }, 1000),
    []
  );

  const debounceSetAppointmentTypeSearchText = useMemo(
    () =>
      debounce((value) => {
        setAppointmentTypeSearchText(value);
        setAppointmentTypeCurrentPage(1);
      }, 1000),
    []
  );

  const clientListFilter: FilterCheckListItem[] = clientListData
    ? clientListData.clientRecords.map((item) => ({
        name: `${item.clientProfiles[0].firstName} ${item.clientProfiles[0].lastName}`,
        _id: item._id
      }))
    : [];

  const appointmentTypeListFilter: FilterCheckListItem[] = appointmentTypesData
    ? appointmentTypesData.data.map((item) => ({
        name: item.name,
        _id: item._id || ''
      }))
    : [];

  const { clients, appointmentTypes, createdAt } = filters;

  const showClearFilter = clients.length > 0 || appointmentTypes.length > 0 || (createdAt.from && createdAt.to);
  const totalClientListPage = clientListData?.paging ? Math.ceil(clientListData.paging.totalItems / PER_PAGE) : 1;
  const totalAppointmentTypeListPage = appointmentTypesData?.paging
    ? Math.ceil(appointmentTypesData.paging.totalItems / PER_PAGE)
    : 1;

  const searchBarOptions = activeTab === InvoicePaymentsTab.Scheduled ? ScheduledPaymentSearchByItems : SearchByItems;

  return (
    <div className={styles.container}>
      <div className={styles.filterWrapper}>
        {/* Filter result label */}
        <div className={styles.label}>
          <div className={styles.numberOfMatchedPayments}>
            {isLoading ? (
              <div className={styles.loadingProfile}>
                <Skeleton.Input className={styles.loading} active />
              </div>
            ) : (
              <div>
                {showClearFilter ? 'FILTER matches' : 'Showing'} <span className={styles.highLight}>{totalItems}</span>
                {` ${activeTab} payments`}
              </div>
            )}
          </div>
          <ButtonAlt
            error
            disabled={!showClearFilter}
            size="small"
            variant="text"
            className={styles.clearFilters}
            onClick={onClearFilter}
          >
            Clear filters
          </ButtonAlt>
        </div>

        {/* Filer buttons */}
        <div className={styles.buttonsContainer}>
          {/* Date Range */}
          <div className={classNames(customStyles.dateRangeContainer, customStyles.submittedDateContainerAdmin)}>
            <RangePicker
              placeholder={['From', 'To']}
              value={[
                createdAt.from ? moment(createdAt.from, 'YYYY-MM-DD') : null,
                createdAt.to ? moment(createdAt.to, 'YYYY-MM-DD') : null
              ]}
              dropdownClassName={classNames(customStyles.dateRangeDropdown, customStyles.dropdownClassNameAdmin)}
              onChange={(
                _value: DatePickerProps['value'] | RangePickerProps['value'],
                dateString: [string, string] | string
              ) => {
                onChangeSubmittedDateFilter(dateString);
              }}
              className={createdAt.from || createdAt.to ? 'hasValue' : ''}
              disabledDate={activeTab === InvoicePaymentsTab.Scheduled ? scheduledPaymentDisabledDate : disabledDate}
              onCalendarChange={setDates}
            />
          </div>

          {/* Client */}
          <FilterDropdown
            id="clients"
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            menuItems={clientListFilter}
            onChangeItem={onToggleFilter(clientListFilter, clients, onChangeClientFilter)}
            searchable
            selectedFilterList={clients}
            showSearchIcon
            loading={isClientListDataLoading}
            hasMoreData={totalClientListPage > clientListCurrentPage || isClientListDataFetching}
            isFetchingMore={isClientListDataFetching}
            loadMore={() => {
              if (!isClientListDataFetching && clientListCurrentPage <= totalClientListPage) {
                setClientListCurrentPage(clientListCurrentPage + 1);
              }
            }}
            enableSelectedBackground
            onClearFilter={() => {
              dispatch(setFilters({ ...filters, clients: [] }));
            }}
            setSearchText={debounceSetClientSearchText}
          >
            Client
          </FilterDropdown>

          {/* Appointment type */}
          <FilterDropdown
            id="appointmentTypes"
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            menuItems={appointmentTypeListFilter}
            onChangeItem={onToggleFilter(appointmentTypeListFilter, appointmentTypes, onChangeAppointmentTypeFilter)}
            searchable
            selectedFilterList={appointmentTypes}
            showSearchIcon
            loading={isAppointmentTypesLoading}
            hasMoreData={totalAppointmentTypeListPage > appointmentTypeCurrentPage || isAppointmentTypesFetching}
            isFetchingMore={isAppointmentTypesFetching}
            loadMore={() => {
              if (!isAppointmentTypesFetching && appointmentTypeCurrentPage < totalAppointmentTypeListPage) {
                setAppointmentTypeCurrentPage(appointmentTypeCurrentPage + 1);
              }
            }}
            enableSelectedBackground
            onClearFilter={() => {
              dispatch(setFilters({ ...filters, appointmentTypes: [] }));
            }}
            setSearchText={debounceSetAppointmentTypeSearchText}
          >
            Service
          </FilterDropdown>
        </div>
      </div>

      {/* Search bar */}
      <div className={styles.searchBarWrapper}>
        <SearchBarT23
          placeholder={`Search by ${searchBarOptions.find((item) => searchBy === item._id)?.name?.toLowerCase()}`}
          value={searchValue}
          withSearchButton
          onSearch={onSearchHandle}
          withSearchBy={{
            onChange: handleChangeSearchBy,
            searchByOptions: searchBarOptions,
            selectedSearchByOption: searchBarOptions.find((item) => search.searchBy === item._id) || searchBarOptions[0]
          }}
        />
      </div>
    </div>
  );
};

export default InvoicePaymentsFilter;
