import { useAppSelector } from 'redux/hooks';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import {
  SearchFilterEnum,
  WriteOffInvoiceSliceState,
  WriteOffInvoiceSorting,
  selectFilters,
  selectPaging,
  selectSearch,
  selectSorting
} from './writeOffInvoiceSlice';
import { useGetWriteOffInvoicesQuery } from 'redux/endpoints/billingServices/invoices';
import { SortType } from 'redux/features/utils';
import { ParticipantType as ScheduleParticipantType } from 'interfaces/Schedule/AppointmentType';
import { ParticipantType } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

export interface GetWriteOffInvoiceListQuery {
  page: number;
  perPage: number;
  searchValue?: string;
  searchBy?: SearchFilterEnum;
  clientIds?: string;
  practitionerIds?: string;
  invoiceTypes?: string;
  type: ParticipantType;
  sortByInvoiceId?: SortType;
  sortByInvoiceAmount?: SortType;
  sortByCreatedAt?: SortType;
  includeWriteOffInvoice: 1;
}

export interface WriteOffInvoiceListRequestPayload {
  accountId: string;
  params?: GetWriteOffInvoiceListQuery;
}

export const getSortByQuery = (sortBy: WriteOffInvoiceSorting['sortBy']) => {
  switch (sortBy) {
    case 'invoiceId':
      return 'sortByInvoiceId';
    case 'total':
      return 'sortByInvoiceAmount';
    case 'createdAt':
    default:
      return 'sortByCreatedAt';
  }
};

export const getWriteOffInvoiceRequestPayload = (
  params: Pick<WriteOffInvoiceSliceState, 'paging' | 'sorting' | 'filters' | 'search'>
): GetWriteOffInvoiceListQuery => {
  const { paging, sorting, filters, search } = params;
  const { page, perPage } = paging;
  const { sortBy, sortType } = sorting;
  const { searchValue, searchBy } = search;
  const { clients, practitioners, invoiceTypes, participantType, groups } = filters;
  const sortByKey = sortBy && getSortByQuery(sortBy);

  return {
    includeWriteOffInvoice: 1,
    page,
    perPage,

    ...(sortByKey && {
      [sortByKey]: sortType === SortType.ASC ? 1 : -1
    }),

    ...(search.searchValue && {
      searchValue,
      searchBy
    }),

    ...(participantType === ScheduleParticipantType.OneToOne && clients.length > 0
      ? {
          clientIds: clients.map(({ _id }) => _id).join(',')
        }
      : participantType === ScheduleParticipantType.Group &&
        groups.length > 0 && {
          groupIds: groups.map(({ _id }) => _id).join(',')
        }),

    ...(practitioners.length > 0 && {
      practitionerIds: practitioners.map(({ _id }) => _id).join(',')
    }),

    ...(invoiceTypes.length > 0 && {
      invoiceTypes: invoiceTypes.map(({ _id }) => _id).join(',')
    }),

    ...{
      type: participantType === ScheduleParticipantType.OneToOne ? ParticipantType.Individual : ParticipantType.Group
    }
  };
};

export const useGetWriteOffInvoicesList = () => {
  const { accountId } = useGetAccountId();
  const { isInvoiceListV2Enabled } = useGetFeatureToggle();

  const filters = useAppSelector(selectFilters);
  const paging = useAppSelector(selectPaging);
  const search = useAppSelector(selectSearch);
  const sorting = useAppSelector(selectSorting);

  const { data, isLoading, isFetching, isError, refetch } = useGetWriteOffInvoicesQuery(
    {
      accountId,
      params: {
        ...getWriteOffInvoiceRequestPayload({
          paging,
          sorting,
          filters,
          search
        })
      }
    },
    { skip: !isInvoiceListV2Enabled || !accountId }
  );

  return {
    isWriteOffInvoicesLoading: isLoading || isFetching,
    isError,
    writeOffInvoices: data?.invoices || [],
    writeOffInvoiceCounts: data?.count || {
      recent: 0,
      total: 0
    },
    writeOffInvoiceTotalItems: data?.paging.totalItems || 0,
    refetch
  };
};
