/* eslint-disable complexity */
import { notification } from 'antd';
import { Collaborator, Report, ReportAccessor } from 'interfaces/Reports/report';
import moment from 'moment';
import { ChangeEvent, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccessToken } from 'utils/hooks/token';
import { putRevokeAccessor } from 'utils/http/DocumentService/Reports/reports';

import AvatarProfile from '../../../../../../../components/AvatarProfile/AvatarProfile';
import ClientSection from './components/ClientSection/ClientSection';
import Label from '../../../../../../../components/Label/Label';
import NextActionButton from './components/NextActionButton/NextActionButton';
import RequestApprovalSection from './components/RequestApprovalSection/RequestApprovalSection';
import ReviewSection from './components/ReviewSection/ReviewSection';
import SelectReviewer from './components/SelectReviewer/SelectReviewer';
import SharedPerson from '../../../../../../../components/SharedPerson/SharedPerson';
import Status from '../../../../../../../components/Status/Status';
import TitleSection from './components/TitleSection/TitleSection';
import styles from './ReportInfoSection.module.scss';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import EpisodeTaggingSection from 'components/EpisodeTaggingSection/EpisodeTaggingSection';

interface ReportInfoSectionProps {
  readOnly: boolean;
  statusEditable: boolean;
  nameOfReport: string;
  data: Report;
  selectedClient: clientRecordsInterface;
  onSelectClient: (selectedClient: clientRecordsInterface, b?: boolean) => void;
  changeReportName: (value: ChangeEvent<HTMLInputElement>) => void;
  handleChangeReportName: () => void;
  clientValidation?: boolean;
  reportNameValidation?: boolean;
  reviewersValidation?: boolean;
  actionBtnStatus: '' | 'active' | 'finished';
  previewMode: boolean;
  sharedList: ReportAccessor[];
  onChangeSharedList: (sharedList: ReportAccessor[]) => void;
  isAuthor: boolean;
  approvalRequired: boolean;
  onChangeApprovalRequired: (bool: boolean) => void;
  onPublish: () => void;
  onRequestReview: (reviewers: Collaborator[]) => void;
  onUnpublish: () => void;
  onDelete: () => void;
  onSubmitReview: ({ reviewType, message }: { reviewType: string; message?: string }) => void;
  onSelectEpisode: (episodeId: string) => void;
}

const getReviewerLabel = (status: string, isAuthor: boolean, isReviewerSelected: boolean) => {
  if (status === 'inReview' && isAuthor) {
    return 'BEING REVIEWED BY:';
  }

  return isReviewerSelected ? 'REVIEWERS' : 'SELECT REVIEWERS';
};

const getApprover = (
  histories: {
    status: string;
    statusChangedAt: Date | string;
    statusChangedBy: Collaborator;
    reviewed?: string;
    feedback?: string;
  }[]
) => {
  const approvedHistories = histories.filter((history) => history.status === 'approved');
  return approvedHistories[approvedHistories.length - 1];
};

const getShowActionButton = (status: string, isAuthor: boolean) => {
  switch (status) {
    case 'draft':
      return isAuthor;
    case 'published':
    case 'approved':
    case 'unpublished':
      return true;
    default:
      return false;
  }
};

const ReportInfoSection = ({
  readOnly,
  statusEditable,
  nameOfReport,
  data,
  selectedClient,
  onSelectClient,
  changeReportName,
  handleChangeReportName,
  clientValidation,
  reportNameValidation,
  reviewersValidation,
  actionBtnStatus,
  previewMode,
  sharedList,
  onChangeSharedList,
  isAuthor,
  approvalRequired,
  onChangeApprovalRequired,
  onPublish,
  onRequestReview,
  onUnpublish,
  onDelete,
  onSubmitReview,
  onSelectEpisode
}: ReportInfoSectionProps) => {
  const { token } = useGetAccessToken();
  const { isEdgeUser } = useGetAccountPackageView();
  const [selectedReviewers, setSelectedReviewers] = useState<Collaborator[]>(data.reviewers ?? []);
  const { isEoCEnabled } = useGetFeatureToggle();

  const clientPrimaryProfile = data.clientRecord.clientProfiles[0];
  const reviewerLabel = getReviewerLabel(data.status, isAuthor, selectedReviewers.length > 0);
  const showActionButton = getShowActionButton(data.status, isAuthor);
  const showReviewers =
    approvalRequired &&
    !(data.status === 'inReview' && !isAuthor) &&
    !(['published', 'unpublish'].includes(data.status) && selectedReviewers.length === 0);
  const showAuthor = ['approved', 'published', 'unpublish'].includes(data.status);
  const showStatusBadge = !(data.status === 'inReview' && !isAuthor);
  const showApprovalRequiredToggle = isEdgeUser && data.status === 'draft' && data.review === undefined && isAuthor;
  const isTitleReadOnly = previewMode || !isAuthor;
  const isClientReadOnly = isTitleReadOnly || !!data._id;

  const onClickRevokeAccess = async (id: string, name?: string) => {
    try {
      await putRevokeAccessor(token, data._id, id);
      notification.success({
        message: name ? `${name}'s access has been revoke` : 'Access has been revoke',
        duration: 5,
        closeIcon: <span className="success">OK</span>
      });
      const newSharedList = sharedList.filter((x: any) => x._id !== id);
      onChangeSharedList(newSharedList);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to revoke accessor.' });
    }
  };

  const handleChangeReviewer = (reviewer: Collaborator[]) => {
    setSelectedReviewers(reviewer);
  };

  const handleChangeApprovalRequired = (bool: boolean) => {
    if (!bool) {
      setSelectedReviewers([]);
    }
    onChangeApprovalRequired(bool);
  };

  const handleRequestReview = () => {
    onRequestReview(selectedReviewers);
  };

  const approver =
    ['approved', 'published', 'unpublished'].includes(data.status) && data.statusHistory
      ? getApprover(data.statusHistory)
      : undefined;

  return (
    <div className={styles.container} id={'reportInfoSectionId'}>
      <div className={styles.firstSection}>
        <TitleSection
          readOnly={isTitleReadOnly}
          nameOfReport={nameOfReport}
          reportNameValidation={reportNameValidation}
          handleChangeReportName={handleChangeReportName}
          changeReportName={changeReportName}
        />
        <ClientSection
          clientProfiles={data.clientRecord.clientProfiles}
          recordType={data.clientRecord.recordType}
          readOnly={isClientReadOnly}
          selectedClient={selectedClient}
          clientValidation={clientValidation}
          onSelectClient={onSelectClient}
        />
        {isEoCEnabled && (
          <EpisodeTaggingSection
            readOnly={previewMode || readOnly}
            selectedClientRecordId={selectedClient._id}
            selectedEpisode={data.episodeId}
            onSelectEpisode={(value) => onSelectEpisode(value)}
          />
        )}
      </div>
      {showStatusBadge && (
        <div className={styles.statusContainer}>
          <Label label={'STATUS'} />
          <div className={styles.statusList}>
            <Status status={data.status} />
            {sharedList.length > 0 && <Status status={'shared'} />}
            {data.review && data.review.reviewType !== 'approved' && <Status status={data.review.reviewType} />}
            {(data.status === 'draft' || data.status === 'approved') && data.editedByReviewer && (
              <Status status={'reviewersEdits'} />
            )}
          </div>
        </div>
      )}
      {showApprovalRequiredToggle && (
        <RequestApprovalSection
          approvalRequired={approvalRequired}
          onChangeApprovalRequired={handleChangeApprovalRequired}
        />
      )}
      {approvalRequired && (
        <>
          {showAuthor && data.authorDetail && (
            <div className={styles.detailsContainer}>
              <Label label={'AUTHOR BY'} />
              <AvatarProfile name={data.authorDetail.name} avatar={data.authorDetail.avatar} />
            </div>
          )}
          {data.status === 'inReview' &&
            data.statusHistory &&
            (isAuthor ? (
              <div className={styles.sentForReviewContainer}>
                <Label label={'SENT FOR REVIEW AT'} />
                <div className={styles.sentForReviewTime}>
                  {`${moment(data.statusHistory[data.statusHistory.length - 1].statusChangedAt).format(
                    'dddd Do MMM YYYY'
                  )} (${moment(data.statusHistory[data.statusHistory.length - 1].statusChangedAt).fromNow()})`}
                </div>
              </div>
            ) : (
              <ReviewSection onSubmitReview={onSubmitReview} btnStatus={actionBtnStatus} />
            ))}
          {showReviewers && (
            <div className={styles.detailsContainer}>
              <Label label={reviewerLabel} />
              {selectedReviewers.length > 0 && (
                <div className={styles.detailsContainer}>
                  {selectedReviewers.map(({ clinicianAuth0Id, name, avatar }) => (
                    <AvatarProfile key={clinicianAuth0Id} name={name} avatar={avatar} />
                  ))}
                </div>
              )}
              {!previewMode && (
                <SelectReviewer
                  onChange={handleChangeReviewer}
                  selectedReviewers={selectedReviewers}
                  validationErrors={reviewersValidation}
                  excludeId={data.clinician._id}
                />
              )}
            </div>
          )}
          {approver && (
            <div className={styles.detailsContainer}>
              <Label label={'APPROVED BY'} />
              <AvatarProfile name={approver.statusChangedBy.name} avatar={approver.statusChangedBy.avatar} />
            </div>
          )}
        </>
      )}
      {!!(sharedList.length > 0 || data.shareDetails.faxes?.length) && (
        <div className={styles.detailsContainer}>
          <Label label={'SHARED TO'} />
          <div className={styles.detailsContainer}>
            {sharedList.map((shared) => (
              <SharedPerson
                key={shared._id}
                details={shared}
                clientProfile={clientPrimaryProfile}
                onClickRevokeAccess={onClickRevokeAccess}
              />
            ))}
            {data.shareDetails.faxes?.map(({ _id, sendAt, sendTo, recipientName }) => (
              <SharedPerson
                key={_id}
                details={{ _id, sentAt: sendAt, faxNumber: sendTo, faxRecipientName: recipientName }}
                clientProfile={clientPrimaryProfile}
              />
            ))}
          </div>
        </div>
      )}
      {showActionButton && (
        <NextActionButton
          reportId={data._id}
          status={data.status}
          statusEditable={statusEditable}
          readOnly={readOnly}
          isAuthor={isAuthor}
          approvalRequired={approvalRequired}
          btnStatus={actionBtnStatus}
          onPublish={onPublish}
          onRequestReview={handleRequestReview}
          onUnpublish={onUnpublish}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default ReportInfoSection;
