import { notification } from 'antd';
import { FormListingInterface } from 'interfaces/Form/FormInterface';
import { CaseNotesTemplatesList } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import { useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { getCaseNotesListing } from 'utils/http/DocumentService/CaseNotes/caseNotesTemplate';

import { FirstAssessmentResponse } from '../../../interfaces/checkInService/firstAssessment';
import { ConsentFormInterface } from '../../../interfaces/ConsentForm/ConsentFormInterface';
import { getMicroCheckInAssessments } from '../../../store/CheckIn/GetMCIAssessment/action';
import { getSurveyFormListing } from '../../../utils/http/CheckInService/Assessment/surveyAssessment';
import { getConsentFormListing } from '../../../utils/http/DocumentService/ConsentForm/consentForm';
import { IInvoiceTemplate } from 'pages/Invoices/interface';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import { getInvoiceTemplates } from 'utils/http/BillingService/Invoice/invoice';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { getLetterTemplateList } from 'utils/http/DocumentService/Letters/lettersTemplate';
import { LetterTemplate, LetterTemplateStatus } from 'interfaces/Letters/letterTemplate';
import { getReportTemplateList } from 'utils/http/DocumentService/Reports/reportsTemplate';
import { ReportTemplate, ReportTemplateStatus } from 'interfaces/Reports/reportTemplate';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { getCarePathwayTemplateList } from 'utils/http/ClinicianProfileService/CarePathwayTemplate/carePathwayTemplate';
import { CarePathwayTemplate } from 'redux/endpoints/clinicianProfileServices/carePathwayTemplate';

export interface IGetInvoiceTemplateResponse {
  invoiceTemplates: IInvoiceTemplate[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export const useFetchFormList = (token: string) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isInvoiceTemplateEnabled, isCarePlanEnabled } = useGetFeatureToggle();
  const [formListData, setFormListData] = useState<FormListingInterface[]>([]);
  const [isFormListDataLoading, setIsFormListDataLoading] = useState(true);
  const { isInvoiceReadDenied } = useGetPermissionToggle();
  const isAdminView = isEdgeAdminView || isEdgeReceptionistView;

  const fetchConsentList = async (token: string) => {
    try {
      const [
        GetSurveyPromise,
        GetMCIPromise,
        GetConsentPromise,
        GetCaseNotesTemplatesPromise,
        GetInvoiceTemplatesPromise,
        GetReportTemplatesPromise,
        GetLetterTemplatesPromise,
        GetCarePathwayTemplatesPromise
      ] = await Promise.all([
        !isEdgeReceptionistView ? getSurveyFormListing(token).catch((e) => console.error(e)) : null,
        !isAdminView ? getMicroCheckInAssessments(token).catch((e) => console.error(e)) : [],
        !isEdgeReceptionistView ? getConsentFormListing(token).catch((e) => console.error(e)) : null,
        !isEdgeReceptionistView ? getCaseNotesListing(token).catch((e) => console.error(e)) : null,
        isInvoiceTemplateEnabled && isAdminView && !isInvoiceReadDenied
          ? getInvoiceTemplates(token, accountId).catch((e) => console.error(e))
          : null,
        getReportTemplateList(token, isAdminView).catch((e) => console.error(e)),
        getLetterTemplateList(token, accountId, isAdminView).catch((e) => console.error(e)),
        isCarePlanEnabled ? getCarePathwayTemplateList(token, accountId).catch((e) => console.error(e)) : null
      ]);

      const [
        GetSurveyData,
        GetMCIData,
        GetConsentData,
        GetCaseNotesTemplatesData,
        GetInvoiceTemplatesData,
        GetReportTemplatesData,
        GetLetterTemplatesData,
        GetCarePathwayTemplatesData
      ] = await Promise.all([
        GetSurveyPromise?.json(),
        GetMCIPromise ?? [],
        GetConsentPromise?.json() || [],
        GetCaseNotesTemplatesPromise?.json() || [],
        GetInvoiceTemplatesPromise ? GetInvoiceTemplatesPromise.json() : null,
        GetReportTemplatesPromise ?? [],
        GetLetterTemplatesPromise ? GetLetterTemplatesPromise.json() : [],
        GetCarePathwayTemplatesPromise ? GetCarePathwayTemplatesPromise.json() : []
      ]);

      const massageSurveyData =
        GetSurveyData?.firstAssessments.map((surveyObj: FirstAssessmentResponse) => ({
          _id: surveyObj.id,
          formType: 'survey',
          name: surveyObj.name,
          isPublished: surveyObj.isPublished,
          isDefault: surveyObj.isDefault,
          createdAt: surveyObj.createdAt,
          updatedAt: surveyObj.updatedAt,
          tags: surveyObj.tags ?? []
        })) || [];

      const massageMCIData = GetMCIData.map((mciObj) => ({
        _id: mciObj.id,
        formType: mciObj.assessmentType,
        name: mciObj.name,
        isPublished: mciObj.isPublished,
        isDefault: mciObj.isDefault,
        createdAt: mciObj.createdAt,
        updatedAt: mciObj.updatedAt,
        clients: mciObj.clients
      }));

      const massageConsentData = GetConsentData.map((consentObj: ConsentFormInterface) => ({
        _id: consentObj._id,
        formType: 'consent',
        name: consentObj.name,
        isPublished: consentObj.isPublished,
        isDefault: consentObj.isDefault,
        createdAt: consentObj.createdAt,
        updatedAt: consentObj.updatedAt
      }));

      const massageCaseNotesTemplateData = GetCaseNotesTemplatesData.map((template: CaseNotesTemplatesList) => ({
        _id: template._id,
        formType: 'caseNotes',
        name: template.name,
        isPublished: template.isPublished,
        isDefault: template.isDefault,
        createdAt: template.createdAt,
        participantType: template.participantType
      }));

      const massagedLetterTemplatesData = GetLetterTemplatesData.map((template: LetterTemplate) => ({
        _id: template._id,
        formType: 'letters',
        name: template.title,
        isPublished: template.status === LetterTemplateStatus.Published,
        isEditable: (isAdminView && template.shareable) || (!isAdminView && !template.shareable),
        createdAt: template.createdAt
      }));

      const massagedReportTemplatesData = GetReportTemplatesData.map((template: ReportTemplate) => ({
        _id: template._id,
        formType: 'reports',
        name: template.title,
        isPublished: template.status === ReportTemplateStatus.Published,
        isEditable: isEdgeAdminView ? true : template.createdBy?._id === auth0ClinicianId,
        createdAt: template.createdAt
      }));

      const massageInvoiceTemplateData =
        GetInvoiceTemplatesData?.invoiceTemplates?.map((template: IInvoiceTemplate) => ({
          _id: template._id,
          formType: 'invoices',
          name: template.name,
          isEditable: isAdminView,
          createdAt: template.createdAt
        })) || [];

      const messageCarePathwayTemplateData =
        GetCarePathwayTemplatesData?.map((template: CarePathwayTemplate) => ({
          _id: template._id,
          formType: 'carePathway',
          name: template.name,
          isPublished: template.status === 'published',
          isEditable: isAdminView,
          createdAt: template.createdAt,
          attachmentIds: template.attachments?.map((attachment) => attachment.id)
        })) || [];

      const massageData = [
        ...massageConsentData,
        ...massageSurveyData,
        ...massageMCIData,
        ...massageCaseNotesTemplateData,
        ...massagedLetterTemplatesData,
        ...massagedReportTemplatesData,
        ...massageInvoiceTemplateData,
        ...messageCarePathwayTemplateData
      ];

      setFormListData(massageData as FormListingInterface[]);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get form record' });
    }

    setIsFormListDataLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchConsentList(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { formListData, isFormListDataLoading };
};
