import classNames from 'classnames';
import styles from './Loading.module.scss';

const Loading = ({ className }: { className?: string }) => (
  <div className={classNames(styles.loading, className)}>
    <div className={styles.circle} />
    <div className={styles.circle} />
    <div className={styles.circle} />
    <div className={styles.circle} />
    <div className={styles.circle} />
  </div>
);

export default Loading;
