import { REFERRAL_SOURCE_TAGS } from 'components/AddPatientModalV2/constants';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { REFERRAL_STATUS_LABELS } from 'interfaces/Referral/Referral';

export const profileTypeMenuItems = [
  { name: 'ADULT PROFILE', _id: 'adultProfile', backgroundColor: '#2e4672', fontColor: '#F9F9FA' },
  { name: 'ADULT RECORD', _id: 'adultRecord', backgroundColor: '#74b4dc', fontColor: '#F9F9FA' },
  { name: 'CHILD RECORD', _id: 'childRecord', backgroundColor: '#dca4cc', fontColor: '#F9F9FA' },
  { name: 'COUPLE PROFILE', _id: 'coupleProfile', backgroundColor: '#3765c5', fontColor: '#F9F9FA' },
  { name: 'COUPLE RECORD', _id: 'coupleRecord', backgroundColor: '#bda54d', fontColor: '#F9F9FA' },
  {
    name: 'YOUNG PROFILE',
    _id: 'youngPersonProfile',
    color: '',
    backgroundColor: '#e4c062',
    fontColor: '#F9F9FA'
  },
  { name: 'YOUNG RECORD', _id: 'youngPersonRecord', backgroundColor: '#f5b38e', fontColor: '#F9F9FA' }
];

export enum FormQueryType {
  Status,
  Time
}

export const formMenuFilterByStatus = [
  { name: 'Consent Signed', _id: 'consentCompleted' },
  { name: 'Consent Not Signed', _id: 'consentNotCompleted' },
  { name: 'Psychometric Completed', _id: 'psychometricCompleted' },
  { name: 'Psychometric Not Completed', _id: 'psychometricNotCompleted' },
  { name: 'Survey Completed', _id: 'surveyCompleted' },
  { name: 'Survey Not Completed', _id: 'surveyNotCompleted' }
];

export const formMenuFilterByTime = [
  { name: 'All', _id: 'all' },
  { name: 'Today', _id: 'today' },
  { name: 'Last 7 days', _id: 'last7' },
  { name: 'Last 28 days', _id: 'last28' }
];

export const referralMenuItems = () => {
  return REFERRAL_SOURCE_TAGS.map((item) => ({
    _id: item,
    name: item
  }));
};

export const referralStatusesItems = () => {
  return Object.entries(REFERRAL_STATUS_LABELS).map(([key, value]) => ({
    _id: key,
    name: value.label
  }));
};

export const onToggleFilter = <T extends FilterCheckListItem>(
  list: T[],
  selectedFilters: T[],
  setSelectedFilters: (v: T[], itemId?: string) => void
) => {
  return (value: { item?: FilterCheckListItem; toggleAllValue?: boolean; color?: string; searchValue?: string }) => {
    if (value.toggleAllValue !== undefined) {
      setSelectedFilters(
        value.toggleAllValue
          ? [
              ...selectedFilters,
              ...list
                .filter((j) => !selectedFilters.find((i) => i._id === j._id))
                .filter((j) => j.name.toLowerCase().includes((value.searchValue || '').toLowerCase()))
            ]
          : [
              ...selectedFilters.filter(
                (item) => !item.name.toLowerCase().includes((value.searchValue || '').toLowerCase())
              )
            ]
      );
    } else if (value.item) {
      if (!selectedFilters.find((i) => i._id === value.item!._id)) {
        const selectedItem = list.find((i) => i._id === value.item!._id);
        const massageItem: any = { ...selectedItem };
        if (selectedItem) {
          setSelectedFilters([...selectedFilters, massageItem], value.item?._id);
        }
      } else {
        setSelectedFilters(
          selectedFilters.filter((item) => item._id !== value.item!._id),
          value.item?._id
        );
      }
    }
  };
};
