import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import { clinicianProfileServicesApiSlice } from './services/clinicianProfileServicesApiSlice';
import { scheduleServicesApiSlice } from './services/scheduleServicesApiSlice';
import { billingServicesApiSlice } from './services/billingServicesApiSlice';
import { documentServicesApiSlice } from './services/documentServicesApiSlice';
import { notificationServicesApiSlice } from './services/notificationServicesApiSlice';
import { checkInServiceApiSlice } from './services/checkInServiceApiSlice';
import { activityFeedServicesApiSlice } from './services/activityFeedServicesApiSlice';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(
      clinicianProfileServicesApiSlice.middleware,
      scheduleServicesApiSlice.middleware,
      billingServicesApiSlice.middleware,
      documentServicesApiSlice.middleware,
      notificationServicesApiSlice.middleware,
      checkInServiceApiSlice.middleware,
      activityFeedServicesApiSlice.middleware
    ),
  devTools: process.env.NODE_ENV !== 'production'
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
