import { Skeleton } from 'antd';
import medicareNoPractitioner from 'assets/images/medicare-nopractitioner.svg';
import { PopulatedProvider } from 'pages/ControlPanel/Interfaces/Claimingcom';
import ProviderTable from './components/ProviderTable/ProviderTable';
import styles from './MedicareProviderListing.module.scss';

interface MedicareProviderListingProps {
  providers: PopulatedProvider[];
  isLoading: boolean;
}

const MedicareProviderListing = ({ providers, isLoading }: MedicareProviderListingProps) => (
  <div className={styles.container}>
    <div className={styles.titleContainer}>
      <div className={styles.title}>Practitioners Submitting Medicare Claims</div>
    </div>
    <div className={styles.contentContainer}>
      {isLoading ? (
        <Skeleton.Input active className={styles.loading} />
      ) : providers.length > 0 ? (
        <ProviderTable providers={providers} />
      ) : (
        <div className={styles.noProviderContainer}>
          <img src={medicareNoPractitioner} alt={`medicare-no-provider-icon`} className={styles.noProviderIcon} />
          <div className={styles.noProviderMessage}>There are no practitioners submitting claims for your service.</div>
        </div>
      )}
    </div>
  </div>
);

export default MedicareProviderListing;
