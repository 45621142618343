import { useState } from 'react';
import styles from './AppointmentTypes.module.scss';
import CreateAppointmentTypeModal from './components/CreateAppointmentTypeModal/CreateAppointmentTypeModal';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { AppointmentType, AssignmentMode, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import AppointmentTypeFilters from './components/AppointmentTypeFilters/AppointmentTypeFilters';
import { useGetAppointmentTypesQuery } from 'redux/endpoints/scheduleServices/appointmentType';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectFilters, selectPaging, selectSearch, setPaging } from 'redux/appointmentTypes/appointmentTypesSlice';
import PaginationListV2 from 'components/v2/PaginationListV2/PaginationListV2';
import { Skeleton } from 'antd';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import AppointmentTypeCard from './components/AppointmentTypeCard/AppointmentTypeCard';
import UpdateAppointmentScheduleModal from './components/UpdateAppointmentScheduleModal/UpdateAppointmentScheduleModal';
import { scrollToView } from 'utils/scrollToView';

const AppointmentTypes = () => {
  const dispatch = useAppDispatch();
  const { auth0ClinicianId } = useGetClinicianId();

  const { isEdgeAdminView, isEdgeUserView, isNormalUserView } = useGetAccountPackageView();
  const [showUpdateAppointmentScheduleModal, setShowUpdateAppointmentScheduleModal] = useState(false);
  const [showCreateAppointmentTypeModal, setShowCreateAppointmentTypeModal] = useState(false);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<AppointmentType | undefined>(undefined);

  const filters = useAppSelector(selectFilters);
  const search = useAppSelector(selectSearch);
  const paging = useAppSelector(selectPaging);

  const {
    data,
    isLoading,
    isFetching,
    refetch: fetchAppointmentTypes
  } = useGetAppointmentTypesQuery({
    page: paging.page,
    perPage: paging.perPage,
    ...(filters.selectedDeliveryMode.length > 0 && {
      deliveryModes: filters.selectedDeliveryMode.map(({ _id }) => _id).join(',')
    }),
    ...(filters.selectedPractitioners.length > 0 && {
      assignedClinicianId: filters.selectedPractitioners.map(({ _id }) => _id).join(',')
    }),
    isActive: filters.selectedStatus?._id,
    participantType: filters.selectedType?._id,
    ...(search.searchValue && {
      name: search.searchValue
    })
  });

  const onSelectAppointmentType = (value: AppointmentType) => {
    setSelectedAppointmentType(value);
    if (isEdgeAdminView || isNormalUserView) {
      setShowCreateAppointmentTypeModal(true);
    } else {
      setShowUpdateAppointmentScheduleModal(true);
    }
  };

  const generateAppointmentTypes = () => {
    const appointmentTypes = data?.data || [];
    return appointmentTypes.length ? (
      appointmentTypes.map((data, index) => {
        const ableToUpdate =
          isEdgeAdminView ||
          isNormalUserView ||
          (isEdgeUserView &&
            data.participantType !== ParticipantType.Activity &&
            data.assignmentMode === AssignmentMode.Single &&
            !!data.assignedClinicians &&
            data.assignedClinicians.includes(auth0ClinicianId));
        return (
          <div key={index} className={styles.appointmentTypeCard}>
            <AppointmentTypeCard
              appointmentType={data as AppointmentType}
              onSelect={() => onSelectAppointmentType(data)}
              ableToUpdate={ableToUpdate}
            />
          </div>
        );
      })
    ) : (
      <div>There is no data for appointment types</div>
    );
  };

  const refetchAppointmentTypes = () => {
    fetchAppointmentTypes();
  };

  const onCloseUpdateAppointmentScheduleModal = () => {
    setShowUpdateAppointmentScheduleModal(false);
    setSelectedAppointmentType(undefined);
  };

  const onCloseCreateAppointmentTypeModal = () => {
    setShowCreateAppointmentTypeModal(false);
    setSelectedAppointmentType(undefined);
  };

  const onChangePage = (page: number) => {
    dispatch(setPaging({ ...paging, page }));
    scrollToView('appointmentTypeHeader');
  };

  const showPagination =
    !isFetching && !isLoading && data && data.paging && data.paging.totalItems > data.paging.perPage;

  return (
    <div className={styles.panel}>
      <div id={'appointmentTypeHeader'} className={styles.header}>
        <div className={styles.title}>
          {isEdgeUserView ? 'Appointment types' : 'Click on appointment type to view and edit'}
        </div>
        {(isEdgeAdminView || isNormalUserView) && (
          <ButtonAlt
            className={styles.addButton}
            onClick={() => setShowCreateAppointmentTypeModal(true)}
            variant="outlined"
            icon="add_circle_outline"
          >
            New appointment type
          </ButtonAlt>
        )}
      </div>
      <AppointmentTypeFilters />
      {isLoading || isFetching ? (
        <div className={styles.loadingWrapper}>
          {[...Array(10)].map((_, i) => (
            <div key={i} className={styles.loadingItem}>
              <Skeleton.Input active className={styles.loadingContent} />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.appointmentTypeList}>{generateAppointmentTypes()}</div>
      )}

      {/* Pagination */}
      {showPagination && (
        <div className={styles.paginationWrapper}>
          <PaginationListV2 paging={data.paging} onPageChange={onChangePage} />
        </div>
      )}
      <CreateAppointmentTypeModal
        visible={showCreateAppointmentTypeModal}
        data={selectedAppointmentType}
        onPost={refetchAppointmentTypes}
        onClose={onCloseCreateAppointmentTypeModal}
      />
      {selectedAppointmentType && (
        <UpdateAppointmentScheduleModal
          data={selectedAppointmentType}
          visible={showUpdateAppointmentScheduleModal}
          onSuccess={refetchAppointmentTypes}
          onClose={onCloseUpdateAppointmentScheduleModal}
        />
      )}
    </div>
  );
};

export default AppointmentTypes;
