import classNames from 'classnames';
import { FirstAssessment } from 'interfaces/checkInService/AssessmentInterfaces';
import styles from './LifeBalanceWheelChart.module.scss';

const LifeBalanceWheelChart = ({ survey }: { survey: FirstAssessment }) => {
  const responseIdLabels: { [key: string]: string } = {
    health: 'Health',
    career: 'Career',
    loveLife: 'Love Life',
    friendships: 'Friendships',
    finances: 'Money & Finances',
    hobbies: 'Hobbies & Leisure'
  };

  const surveyResponse = survey.assessment.onboardingQuestions?.assessmentField[0].sections[0].questions;

  const lifeBalanceSectors =
    surveyResponse &&
    surveyResponse.filter(
      ({ id, response }) => !!responseIdLabels[id] && Number.isInteger(Number.parseInt(response?.value, 10))
    );

  if (
    !lifeBalanceSectors ||
    lifeBalanceSectors.length !== 6 ||
    !Object.keys(responseIdLabels).every(
      (expectedId) => lifeBalanceSectors.findIndex(({ id }) => id === expectedId) !== -1
    )
  ) {
    console.error(
      `Unable to display life wheel balance chart with given survey response: ${JSON.stringify(surveyResponse)}`
    );
    return <></>;
  }

  const dataToSectors = lifeBalanceSectors.map((data, index) => {
    const percentage = Number(data.response?.value) / 10;
    const sectorContainerDimensions = percentage * 400;
    const sectorDimensions = percentage * 200;

    return (
      <div key={`sector_${index}`} className={styles.sectorWrapper}>
        <div
          className={styles.sectorContainer}
          style={{ width: sectorContainerDimensions, height: sectorContainerDimensions }}
        >
          <div
            className={styles.sector}
            style={{
              left: sectorDimensions,
              width: sectorDimensions,
              height: sectorDimensions,
              transform: `rotate(${index * 60}deg) skewY(-30deg)`
            }}
          />
        </div>
        <div
          className={styles.labelWrapper}
          style={{
            transform: `rotate(${index * 60 + 30}deg)`
          }}
        >
          <div
            className={styles.labelStraightener}
            style={{
              transform: `rotate(${360 - (30 + index * 60)}deg)`
            }}
          >
            <div className={styles.label}>
              <p>
                {responseIdLabels[data.id] && `${responseIdLabels[data.id]} `}
                <span
                  className={classNames(styles.labelNumber, Number(data.response?.value) === 10 ? styles.label10 : '')}
                >
                  {data.response?.value}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={styles.chartWrapper}>
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
      <hr className={styles.line} />
      <hr className={styles.line} />
      <hr className={styles.line} />
      {dataToSectors}
    </div>
  );
};

export default LifeBalanceWheelChart;
