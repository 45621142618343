import { useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { ReportClientTab } from 'interfaces/Reports/report';

import styles from './PatientDetailsReportsHeader.module.scss';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface PatientDetailsReportsHeaderProps {
  recordId: string;
  currentTab: string;
  reportId?: string;
  reportList?: ReportClientTab[];
}

const PatientDetailsReportsHeader = ({
  recordId,
  currentTab,
  reportId,
  reportList
}: PatientDetailsReportsHeaderProps) => {
  const navigate = useNavigate();
  const { CLIENTS, REPORTS } = useRoutesGenerator();

  const { currentReport, previousReport, nextReport } = useMemo(() => {
    const reportHeaderItems: {
      currentReport?: ReportClientTab;
      previousReport?: ReportClientTab;
      nextReport?: ReportClientTab;
    } = { currentReport: undefined, previousReport: undefined, nextReport: undefined };

    if (reportList) {
      const currentReport = reportList.find((reportItem) => reportItem._id === reportId);

      if (currentReport) {
        reportHeaderItems.currentReport = currentReport;

        const currentIndex = reportList.indexOf(currentReport);

        if (currentIndex > -1) {
          reportHeaderItems.previousReport = reportList[currentIndex - 1];
          reportHeaderItems.nextReport = reportList[currentIndex + 1];
        }
      }
    }

    return reportHeaderItems;
  }, [reportId, reportList]);

  const handleReportClick = (id: string) => {
    navigate(`${CLIENTS.BASE}/${recordId}/${currentTab}/${id}`);

    const element = document.getElementById(id);
    element && element.scrollIntoView({ block: 'nearest' });
  };

  return (
    <div className={styles.container}>
      {previousReport && (
        <ButtonAlt
          className={`${styles.btnBox} ${styles.left}`}
          contentClassName={styles.btnContent}
          variant={'text'}
          onClick={() => handleReportClick(previousReport._id)}
          icon={'chevron_left'}
        >
          <div className={styles.date}>
            <div className={styles.label}>{previousReport.reportName}</div>&nbsp;
            {moment(previousReport.createdAt).format(MOMENTJS_DATE_FORMAT)}
          </div>
        </ButtonAlt>
      )}
      {currentReport && (
        <div className={styles.titleWrapper} onClick={() => navigate(`${REPORTS.BASE}/${reportId}`)}>
          <div className={styles.title}>{currentReport.reportName}</div>
          <div className={styles.date}>{moment(currentReport.createdAt).format(MOMENTJS_DATE_FORMAT)}</div>
        </div>
      )}
      {nextReport && (
        <ButtonAlt
          className={`${styles.btnBox} ${styles.right}`}
          contentClassName={styles.btnContent}
          variant={'text'}
          onClick={() => handleReportClick(nextReport._id)}
          icon={'chevron_right'}
          iconPostFix
        >
          <div className={styles.date}>
            <div className={styles.label}>{nextReport.reportName}</div>&nbsp;
            {moment(nextReport.createdAt).format(MOMENTJS_DATE_FORMAT)}
          </div>
        </ButtonAlt>
      )}
    </div>
  );
};

export default PatientDetailsReportsHeader;
