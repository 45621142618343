import { Link } from 'react-router-dom';
import styles from './ClosedGroupListItem.module.scss';
import columnStyles from 'pages/Groups/GroupsList/components/GroupListContent/components/ClosedGroupListCard/ClosedGroupColumn.module.scss';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import DateDescBox from 'pages/Client/components/ClientListContent/components/Components/DateDescBox/DateDescBox';
import moment from 'moment';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { getDayDiff } from 'utils/helpers/DateDiff';

interface ClosedGroupItemProps {
  groupListingItem: Groups;
  searchValue: string;
}

const ClosedGroupItem = ({ groupListingItem, searchValue }: ClosedGroupItemProps) => {
  const { CARECOORDINATION } = useRoutesGenerator();

  return (
    <Link
      to={`${CARECOORDINATION.GROUPS}/${groupListingItem._id}/details${searchValue ? `?q=${searchValue}` : ''}`}
      className={styles.container}
    >
      <div className={columnStyles.GroupNameSection}>
        <div className={styles.groupName}>{groupListingItem.name}</div>
      </div>
      <div className={columnStyles.GroupSinceSection}>
        <div className={styles.groupSince}>
          <DateDescBox
            title={moment(groupListingItem.createdAt).format('D MMM YYYY')}
            desc={getDayDiff(moment(), moment(groupListingItem.createdAt))}
          />
        </div>
      </div>
      <div className={columnStyles.GroupClosedSection}>
        <div className={styles.groupClosed}>
          <DateDescBox title={moment(groupListingItem.updatedAt).format('D MMM YYYY')} />
        </div>
      </div>
      <div className={columnStyles.GroupLeadSection}>
        <div className={styles.groupLead}>{groupListingItem.leadClinicianProfile.name}</div>
      </div>
      <div className={columnStyles.buttonSection}>
        <i className={`material-icons ${styles.icon}`}>arrow_forward</i>
      </div>
    </Link>
  );
};
export default ClosedGroupItem;
