import { v4 as uuid } from 'uuid';
import { newClientForm } from '../AddPatientModalV2Interface';
import { defaultBaseDetails } from './defaultBaseDetails';

const clientProfiles = [
  {
    _id: uuid(),
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    dateOfBirth: '',
    onboarding: {
      onboardingSurveyIds: [],
      isCheckOnBoarding: true,
      onboardingMethod: 'sendToClient'
    },
    consentForm: {
      isCheckOnConsentForm: true,
      consentFormIds: []
    },
    assessmentForm: {
      isCheckOnAssessmentForm: true,
      assessmentFormIds: []
    },
    role: 'youngPerson',
    isPrimaryContact: true,
    communicationPreference: 'noPreference'
  },
  {
    _id: uuid(),
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    onboarding: {
      onboardingSurveyIds: [],
      isCheckOnBoarding: true,
      onboardingMethod: 'facilitated'
    },
    consentForm: {
      isCheckOnConsentForm: true,
      consentFormIds: []
    },
    assessmentForm: {
      isCheckOnAssessmentForm: true,
      assessmentFormIds: []
    },
    role: '',
    isPrimaryContact: true,
    communicationPreference: 'noPreference'
  }
];

export const defaultYoungDetails = {
  ...defaultBaseDetails,
  recordType: 'youngPerson',
  ...{ clientProfiles },
  agreementField: {
    agreementType: 'Standard',
    agreement: false
  }
} as newClientForm;
