import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';
import { useGetAccessToken } from 'utils/hooks/token';
import { getBazaarAssessment } from 'utils/http/CheckInService/Assessment/clinicalAssessment';
import { getProfileTags } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import {
  deleteClientRecordTag,
  putClientRecordTag
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { useFetchClientDetailByClientId } from '../../hooks/getClientDetailByClientId';
import styles from './Bazaar.module.scss';
import Chapter from './Chapter/Chapter';
import { AccountTag, Tag } from './types';

const useAccountTags = (token: string, accountId: string) => {
  const [accountTags, setAccountTags] = useState<Map<string, AccountTag>>(new Map());

  const getAccountTags = useCallback(async () => {
    const response = await getProfileTags(token, accountId);
    const tags = (await response.json()) as AccountTag[];
    setAccountTags(new Map(tags.map((tag) => [tag._id, tag])));
  }, [token, accountId]);

  useEffect(() => {
    if (token && accountId) {
      getAccountTags();
    }
  }, [token, accountId, getAccountTags]);

  return { accountTags };
};

const Bazaar = () => {
  const [, setCookie] = useCookies(['bazaar-tokens']);
  const [bazaarButtonStatus, setBazaarButtonStatus] = useState<ButtonStatusType>('');
  const [updating, setUpdating] = useState(false);

  const path = useParams<{ clientId: string }>();
  const clientRecordId = path.clientId ?? '';

  const { accountSettings } = useGetAccountSettings();
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const { clientDetails, isClientDetailLoading, setClientDetails } = useFetchClientDetailByClientId(
    token,
    clientRecordId
  );
  const { accountTags } = useAccountTags(token, accountId);

  const clientRecordTags = useMemo(() => clientDetails?.tags || [], [clientDetails]);
  const bazaarTags = useMemo(() => accountSettings?.bazaarChapterTagIds || [], [accountSettings]);

  const loaded = !isClientDetailLoading && (!bazaarTags.length || accountTags.size);

  const chapterDone = useMemo(() => {
    return (
      loaded &&
      bazaarTags
        .map((tag, index) => clientRecordTags.includes(tag) && index + 1)
        .filter((i) => i)
        .join(',')
    );
  }, [loaded, clientRecordTags, bazaarTags]);

  const nextChapterIndex = useMemo(() => {
    return loaded
      ? bazaarTags.reduce<number>((nextChapter: number, tag: string, index: number) => {
          if (nextChapter > -1) return nextChapter;
          if (!clientRecordTags.includes(tag)) return index;
          return -1;
        }, -1)
      : -1;
  }, [loaded, bazaarTags, clientRecordTags]);

  const tags: Tag[] = useMemo(() => {
    return loaded
      ? bazaarTags.map((id, index) => {
          const tag = accountTags.get(id);
          return {
            _id: tag?._id || '',
            label: `${index + 1}. ${tag?.label}`,
            isCompleted: clientRecordTags.includes(id),
            isNextChapter: index === nextChapterIndex
          };
        })
      : [];
  }, [loaded, bazaarTags, accountTags, clientRecordTags, nextChapterIndex]);

  const enterBazaar = async () => {
    setBazaarButtonStatus('active');
    try {
      const response = await (
        await getBazaarAssessment(token, clientRecordId, clientDetails?.clientProfiles[0]._id!)
      ).json();

      setCookie('bazaar-tokens', response, { domain: 'tacklit.com', path: '/', secure: true, sameSite: 'none' });
      setBazaarButtonStatus('finished');
      window.location.href =
        `${accountSettings?.bazaarUrl}?${chapterDone ? `cpt_done=${chapterDone}&` : ''}${
          nextChapterIndex > -1 ? `cpt_next=${nextChapterIndex + 1}&` : ''
        }tacklit_auth=${response.authToken}&tacklit_feedback_url=${encodeURIComponent(
          window.location.href
        )}&tacklit_client_name=${clientDetails?.clientProfiles[0].name || ''}` || '';
    } catch (e) {
      console.error(e);
      setBazaarButtonStatus('');
    }
  };

  const onDoneChapter = async (tagToAdd: string) => {
    if (!updating && clientDetails) {
      setUpdating(true);
      const response = await putClientRecordTag(token, clientRecordId, tagToAdd);
      if (response.statusCode === 204) {
        const tags = [...(clientDetails.tags || []), tagToAdd];
        setClientDetails({
          ...clientDetails,
          tags
        });
      }
      setUpdating(false);
    }
  };

  const onUndoChapter = async (tagToRemove: string) => {
    if (!updating && clientDetails) {
      setUpdating(true);
      const response = await deleteClientRecordTag(token, clientRecordId, tagToRemove);
      if (response.statusCode === 204) {
        const tags = clientDetails.tags?.filter((tag) => tag !== tagToRemove);
        setClientDetails({
          ...clientDetails,
          tags
        });
      }
      setUpdating(false);
    }
  };

  return (
    <div className={styles.programSelectedWrapper}>
      <div className={styles.programSelected}>
        <div className={styles.programSelectedText}>PROGRAM SELECTED</div>
        <div className={styles.bazaarStatus}>Bazaar</div>
        <div>
          {tags.map((tag) => (
            <Chapter
              key={tag._id}
              tag={tag}
              token={token}
              clientRecordId={clientRecordId}
              onDoneChapter={onDoneChapter}
              onUndoChapter={onUndoChapter}
              updating={updating}
              setUpdating={setUpdating}
            />
          ))}
        </div>
        {loaded ? (
          <Button onClick={enterBazaar} status={bazaarButtonStatus}>
            ENTER BAZAAR
          </Button>
        ) : (
          <LoadingCircle />
        )}
      </div>
    </div>
  );
};

export default Bazaar;
