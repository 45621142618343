import { notification } from 'antd';
import CheckBox from 'components/CheckBox/CheckBox';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import MaterialToggleSwitch from 'components/MaterialFieldComponent/MaterialToggleSwitch/MaterialToggleSwitch';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import OptionLabel from 'components/OptionLabel/OptionLabel';
import { FirstAssessmentStruct, QuestionSetStruct } from 'interfaces/firstAssessment';
import moment from 'moment';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createFirstAssessments, updateFirstAssessments } from 'store/CheckIn/UpdateFirstAssessment/action';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { timeDiff } from 'utils/timeDifferent';
import { v4 as uuid } from 'uuid';

import AssessmentsHeader from '../../../components/AssessmentsHeader/AssessmentsHeader';
import IntroNoteEditor2 from 'components/IntroNoteEditor2/IntroNoteEditor2';
import styles from './AssessmentsDetailsContent.module.scss';
import AssessmentGuideline from './components/AssessmentGuideLine/AssessmentGuideLine';
import AssessmentQuestionSection from './components/AssessmentQuestionSection/AssessmentQuestionSection';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { security } from 'utils/security';

interface AssessmentsDetailsContentProps {
  data: FirstAssessmentStruct;
  isEdit: boolean;
  qTemplateData: QuestionSetStruct[];
  readOnly: boolean;
}

// eslint-disable-next-line complexity
const AssessmentsDetailsContent = ({ data, isEdit, qTemplateData, readOnly }: AssessmentsDetailsContentProps) => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();
  const [assessment, setAssessment] = useState(data);
  const [isEditMode, setIsEditMode] = useState(isEdit);
  const [isClickSaveBtn, setClickSaveBtn] = useState(false);
  const [isValidationComplete, setIsValidationComplete] = useState({
    name: true,
    questionSet: true
  });
  const [isSaveAndPublish, setIsSaveAndPublish] = useState(false);
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');
  const [isDiffName, setIsDiffName] = useState<boolean>(!!data.diffName);
  const tagField = data.tags
    ? data.tags.map((obj) => ({
        tag: obj,
        isAdd: true
      }))
    : [];
  const [tags, setTags] = useState<{ tag: string; isAdd: boolean }[]>(tagField);
  const path = useParams() as { assessmentId: string };
  const [lastUpdated, setLastUpdated] = useState(
    isEditMode ? `Last edit was ${timeDiff(moment(), data.updatedAt || data.createdAt)}` : ''
  );
  const [t] = useTranslation();

  const isHaveSurveyQuestions = () => {
    const isQuestionsSetsValid =
      // Must have at least 1 question set
      assessment.questionSets.length !== 0 &&
      // Every question set to be saved in DB must have section name
      assessment.questionSets.every(
        (questionSet) =>
          (questionSet.sectionName?.length && questionSet.questions?.every((question) => !question.isDraftQ)) ||
          questionSet.categoryId ||
          questionSet.defaultCategoryId
      ) &&
      // At least one of the question is checked
      assessment.questionSets.some(
        (questionSet) =>
          questionSet.questions?.some((question) => question.isChecked) ||
          questionSet.categoryId ||
          questionSet.defaultCategoryId
      );

    const isHaveBgQuestion = assessment.backgroundQuestions.some((bgQuestion) => bgQuestion.isChecked);

    return isQuestionsSetsValid || (isHaveBgQuestion && assessment.questionSets.length === 0);
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setAssessment({ ...assessment, name: e.target.value });
  };

  const handleCheckName = (val: boolean) => {
    setIsDiffName(val);
  };

  const onChangeDiffName = (e: ChangeEvent<HTMLInputElement>) => {
    setAssessment({ ...assessment, diffName: e.target.value });
  };

  const onChangeDesc = (e: ChangeEvent<HTMLInputElement>) => {
    setAssessment({ ...assessment, description: e.target.value });
  };

  const onChangeNote = (val: string) => {
    const getValueOnly = val.replace(/<(?!img)(.|\n)*?>/g, '');
    const noteVal = getValueOnly ? val : '';

    setAssessment({ ...assessment, note: noteVal });
  };

  const onClickAddTag = () => {
    tags.push({ tag: '', isAdd: false });
    setTags([...tags]);
  };

  const onChangeTags = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    tags[index] = { tag: e.target.value, isAdd: false };
    setTags([...tags]);
  };

  const onKeyPressAddTag = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && tags[index].tag) {
      tags[index].isAdd = true;
      setTags([...tags]);
    }
  };

  const onRemoveTag = (index: number) => {
    tags.splice(index, 1);
    setTags([...tags]);
  };

  const onChangePublish = (publishVal: boolean) => {
    setAssessment({ ...assessment, isPublished: publishVal });
  };

  const onChangeBgQuestion = (e: any) => {
    setAssessment({ ...assessment, backgroundQuestions: e });
  };

  const buildNewQuestionList = (items: any) => {
    setAssessment({ ...assessment, questionSets: items });
  };

  const checkValidation = (data: any) => {
    setIsValidationComplete({
      name: true,
      questionSet: true
    });
    if (data.name.length > 0) {
      setIsValidationComplete({
        name: true,
        questionSet: true
      });

      return true;
    } else {
      if (data.name.length <= 0) {
        setIsValidationComplete({
          ...isValidationComplete,
          name: false
        });
      }

      setIsValidationComplete({
        name: false,
        questionSet: false
      });

      return false;
    }
  };

  const saveFunction = async (data: any) => {
    setSaveStatus('active');
    const validation = checkValidation(data);

    const token = await security.getAccessTokenSilently();
    setClickSaveBtn(true);

    if (validation) {
      if (isEditMode) {
        updateFirstAssessments(data, token).then((r) => {
          // @ts-ignore
          if (r.id) {
            notification.success({
              message: 'Assessment updated',
              duration: 5,
              closeIcon: <span className="success">OK</span>
            });
            assessment.updatedAt = moment().toDate();
            setLastUpdated(`Last edit was ${timeDiff(moment(), assessment.updatedAt || assessment.createdAt)}`);
            setSaveStatus('finished');
            setTimeout(() => {
              setSaveStatus('');
            }, 2000);
          } else {
            setSaveStatus('');
          }
        });
        setClickSaveBtn(false);
      } else {
        createFirstAssessments(data, token).then((r: any) => {
          if (r.id) {
            setIsEditMode(true);
            notification.success({
              message: 'Assessment created',
              duration: 5,
              closeIcon: <span className="success">OK</span>
            });
            setSaveStatus('finished');
            setTimeout(() => {
              setSaveStatus('');
              navigate(`${FORMS.BASE}/survey/${r.id}`);
            }, 2000);
          } else {
            setSaveStatus('');
          }
        });
      }
      setClickSaveBtn(false);
    } else {
      notification.error({ message: 'Assessment failed to update' });
      setSaveStatus('');
      setClickSaveBtn(false);
    }
    setAssessment({ ...assessment, questionSets: data.questionSets });
  };

  const onChangeQList = (questionSets: any) => {
    setAssessment({ ...assessment, questionSets });
  };

  const massageQuestionList = (questionListVal: any) => {
    let newQList = [];
    for (let obj of questionListVal) {
      newQList.push({
        ...obj,
        id: obj.isDraftQ ? `newId${uuid()}` : obj.id,
        isDraftQ: false
      });
    }
    return newQList;
  };

  const onSaveQuestion = () => {
    const filteredQuestionSet = assessment.questionSets.reduce((acc: any, questionSetObj: any) => {
      const isEligibleQuestion = questionSetObj.questions.filter(
        (value: any) => !((value.isDraftQ && !value.isChecked) || value.stem.length <= 2)
      );
      acc.push({
        ...questionSetObj,
        questions: massageQuestionList(isEligibleQuestion)
      });
      return acc;
    }, []);

    const saveData = {
      id: path.assessmentId,
      name: assessment.name,
      diffName: isDiffName ? assessment.diffName : '',
      note: assessment.note,
      tags,
      description: assessment.description,
      isPublished: assessment.isPublished,
      isDefault: data.isDefault,
      backgroundQuestions: assessment.backgroundQuestions,
      questionSets: filteredQuestionSet
    };

    saveFunction(saveData);
  };

  const onClickSaveNPublish = () => {
    const filteredQuestionSets = assessment.questionSets.reduce((acc: any, questionSetObj: any) => {
      const isEligibleQuestion = questionSetObj.questions.filter(
        (value: any) => !((value.isDraftQ && !value.isChecked) || value.stem.length <= 2)
      );
      acc.push({ ...questionSetObj, questions: isEligibleQuestion });
      return acc;
    }, []);

    const saveData = {
      id: path.assessmentId,
      name: assessment.name,
      diffName: isDiffName ? assessment.diffName : '',
      note: assessment.note,
      tags,
      description: assessment.description,
      isPublished: true,
      isDefault: data.isDefault,
      backgroundQuestions: assessment.backgroundQuestions,
      questionSets: filteredQuestionSets
    };

    assessment.isPublished = true;
    setAssessment({ ...assessment });

    saveFunction(saveData);
  };

  const addNewSection = (validate: boolean) => {
    if (validate) {
      let newQuestionSet = [];
      for (let data of assessment.questionSets) {
        newQuestionSet.push({
          ...data
        });
      }

      newQuestionSet.push({
        id: `newSet_${uuid()}`,
        sectionName: '',
        questions: []
      });
      setAssessment({ ...assessment, questionSets: newQuestionSet });
    }
  };

  const checkNewCardExist = assessment.questionSets?.find(
    (obj) => obj.sectionName === '' || (!(obj.categoryId || obj.defaultCategoryId) && obj.questions.length <= 0)
  );

  return (
    <div>
      <AssessmentsHeader
        title={'Survey Editor'}
        lastUpdateLabel={lastUpdated}
        saveBtn={
          <ButtonAlt
            status={isSaveAndPublish ? saveStatus : ''}
            disabled={!(assessment.name.length > 0) || (isDiffName && !assessment.diffName) || !isHaveSurveyQuestions()}
            onClick={() => {
              setIsSaveAndPublish(true);
              onClickSaveNPublish();
            }}
          >
            Save and Publish
          </ButtonAlt>
        }
      />
      <div className={styles.container}>
        <div className={styles.headerTitle}>
          <div className={styles.leftWrapper}>
            {t('assessment_detail.setup_context')}
            <HelpOutLineWithTooltips id={'setupContext'} desc={t('assessment_detail.setup_context.description')} />
          </div>
          <div className={styles.rightWrapper}>
            {t('assessment_detail.setup_question')}
            <HelpOutLineWithTooltips id={'setupQuestion'} desc={t('assessment_detail.setup_question.description')} />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.leftWrapper}>
            <div className={styles.titleBox}>
              <MaterialInput
                id={'surveyName'}
                label={'Name of survey'}
                className={styles.titleInput}
                value={assessment.name}
                onChange={onChangeName}
                readOnly={readOnly}
                required
              />
              {!readOnly && (
                <MaterialToggleSwitch
                  name={'publish'}
                  label={'Published'}
                  value={assessment.isPublished}
                  customContainerClass={styles.toggleSwitchContainer}
                  onChange={onChangePublish}
                />
              )}
            </div>
            <div className={styles.diffNameBox}>
              <CheckBox
                className={styles.checkBox}
                id={'differentName'}
                value={isDiffName}
                label={<OptionLabel title={t('assessment_detail.show_client_different_name')} />}
                onChange={(e) => handleCheckName(e.target.checked)}
              />
              {isDiffName && (
                <MaterialInput
                  id={'surveyDiffName'}
                  label={t('assessment_detail.client_survey_name')}
                  className={styles.titleInput}
                  value={assessment.diffName}
                  onChange={onChangeDiffName}
                  readOnly={readOnly}
                  required
                />
              )}
            </div>
            <div className={styles.descBox}>
              <MaterialInput
                id={'desc'}
                label={'Short description of this assessment'}
                className={styles.descInput}
                value={assessment.description}
                onChange={onChangeDesc}
                readOnly={readOnly}
                required
              />
            </div>
            <div className={styles.tagBox}>
              {tags.map((obj, index) =>
                !obj.isAdd ? (
                  <div className={styles.tagInput} key={index}>
                    <MaterialInput
                      id={`inputTag${index}`}
                      label={''}
                      placeholder={'Press enter to add tag'}
                      className={styles.titleInput}
                      value={obj.tag}
                      onChange={(e) => onChangeTags(index, e)}
                      onKeyPress={(e) => onKeyPressAddTag(index, e)}
                      readOnly={readOnly}
                      required
                    />
                  </div>
                ) : (
                  <div className={styles.tagItem} key={index}>
                    {obj.tag}
                    <i onClick={() => onRemoveTag(index)} className={`material-icons-outlined ${styles.icon}`}>
                      close
                    </i>
                  </div>
                )
              )}
              <ButtonAlt size={'medium'} variant={'text'} onClick={onClickAddTag} icon={'add'} iconPostFix>
                Add Tag
              </ButtonAlt>
            </div>
            <div className={styles.noteBox}>
              <div className={styles.noteTitle}>
                Include a intro note for recipient
                <HelpOutLineWithTooltips
                  id={'introNoteTooltip'}
                  desc={
                    'Your welcome note will be the cover page for the survey, and will be read prior to seeing any questions. Here you can set expectations, give instructions and thank participants for providing the information.'
                  }
                />
              </div>
              {/* <IntroNoteEditor
                id={`introNote`}
                value={assessment.note || ''}
                onChange={(e) => onChangeNote(e)}
                enableMention
              /> */}
              <IntroNoteEditor2 value={assessment.note || ''} height={220} onChange={(e) => onChangeNote(e)} />
            </div>
          </div>
          <div className={styles.rightWrapper}>
            <AssessmentGuideline bgQuestions={assessment.backgroundQuestions} onChange={onChangeBgQuestion} />
            <AssessmentQuestionSection
              questionList={assessment.questionSets}
              onChange={buildNewQuestionList}
              onChangeQuestionList={onChangeQList}
              onTriggerSave={isClickSaveBtn}
              qTemplateData={qTemplateData}
              validation={isValidationComplete.questionSet}
              disabled={readOnly}
            />
            <div className={styles.buttonWrapper}>
              {!readOnly && (
                <ButtonAlt
                  size={'medium'}
                  variant={'text'}
                  onClick={() => addNewSection(!checkNewCardExist)}
                  disabled={checkNewCardExist !== undefined}
                  icon={'add_circle_outline'}
                >
                  Add Question Theme
                </ButtonAlt>
              )}
              {!readOnly && (
                <ButtonAlt
                  disabled={
                    !(assessment.name.length > 0) || (isDiffName && !assessment.diffName) || !isHaveSurveyQuestions()
                  }
                  status={isSaveAndPublish ? '' : saveStatus}
                  onClick={() => {
                    setIsSaveAndPublish(false);
                    onSaveQuestion();
                  }}
                  className={styles.saveBtnFixed}
                >
                  Save
                </ButtonAlt>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentsDetailsContent;
