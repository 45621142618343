import { notification } from 'antd';
import SkeletonButton from 'antd/lib/skeleton/Button';
import ModalV2 from 'components/ModalV2/ModalV2';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { AssignPackagesInterface, funderType } from 'interfaces/Packages/packages';
import { Referral, ReferralEntrySource, ReferralStatus } from 'interfaces/Referral/Referral';
import moment from 'moment';
import {
  packageSchema,
  SelectedPackage
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsProfile/components/PackageAssignment/components/PackageAssignmentModal/PackageAssignmentModal';
import ReferralCustomFieldsTable from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsV2/components/ReferrralCustomFieldsTable/ReferralCustomFieldsTable';
import { ReferrerType } from 'pages/Referrals/interface';
import ReferralNewClientForm from 'pages/Referrals/ReferralNewClientForm/ReferralNewClientForm';
import { HIGHEST_PAGING_COUNT } from 'pages/Referrals/ReferralsList/ReferralsList';
import { isLargeClientMatchCount } from 'pages/Referrals/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  useCreateReferralMutation,
  useUpdateClientRecordIdMutation,
  useUpdateReferralMutation,
  useUploadDocumentsToReferralMutation
} from 'redux/endpoints/clinicianProfileServices/referral';
import { useAssignPackageMutation, useGetPackageListQuery } from 'redux/endpoints/scheduleServices/package';
import { useGetMatchedClients } from 'redux/referrals/useGetMatchedClients';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { sleep } from 'utils/helpers/sleep';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { scrollToView } from 'utils/scrollToView';
import * as Yup from 'yup';
import { ReferralFormMode } from '../ReferralForm/components/ReferrerForm/ReferrerForm';
import ReferralForm, {
  PACKAGE_LIMIT,
  ReferralFormRef,
  referralFormValidationInitialMessage
} from '../ReferralForm/ReferralForm';
import ReferralMatchItem from '../ReferralsItem/components/ReferralMatchItem/ReferralMatchItem';
import styles from './AddReferralModal.module.scss';
import { ReferralFieldsPayload } from './AddReferralModalInterfaces';

interface AddReferralModalProps {
  visible: boolean;
  onCloseModal: () => void;
  prefillClientRecordId?: string;
  isEditModal?: boolean;
  referral?: Referral;
}

const defaultNewReferral: { _id: string; referralId: string } = {
  _id: '',
  referralId: ''
};

const formatReferralDates = (referralFields: ReferralFieldsPayload, fromFormat: string, toFormat: string) => {
  return {
    date: referralFields.date ? moment(referralFields.date, fromFormat).format(toFormat) : '',
    expiryDate: referralFields.expiryDate ? moment(referralFields.expiryDate, fromFormat).format(toFormat) : '',
    subject: {
      ...referralFields.subject,
      dateOfBirth: referralFields.subject?.dateOfBirth
        ? moment(referralFields.subject.dateOfBirth, fromFormat).format(toFormat)
        : ''
    } as Referral['subject']
  };
};

const AddReferralModal = ({
  visible,
  onCloseModal,
  prefillClientRecordId,
  isEditModal,
  referral
}: AddReferralModalProps) => {
  const defaultFormValue: ReferralFieldsPayload = {
    ...referralFormValidationInitialMessage,
    status: ReferralStatus.New,
    entrySource: ReferralEntrySource.Manual,
    packages: [],
    ...referral,
    referrer: {
      ...referralFormValidationInitialMessage.referrer,
      referrerType: ReferrerType.Self,
      ...referral?.referrer
    },
    ...(referral && formatReferralDates(referral, MOMENTJS_YEAR_MONTH_DAY_FORMAT, MOMENTJS_DATE_FORMAT))
  };
  const [referralFields, setReferralFields] = useState<ReferralFieldsPayload>(defaultFormValue);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [showClientModal, setShowClientModal] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(!!isEditModal);
  const [newReferral, setNewReferral] = useState<{ _id: string; referralId: string }>({ ...defaultNewReferral });
  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const selectedPackageSchema = Yup.array().of(packageSchema);

  const formRef = useRef<ReferralFormRef>(null);

  const memoizedSubjectFields = useMemo(() => {
    const { subject } = referralFields;
    const dateOfBirth = subject?.dateOfBirth
      ? moment(subject.dateOfBirth, MOMENTJS_DATE_FORMAT, true).isValid()
        ? moment(subject.dateOfBirth, MOMENTJS_DATE_FORMAT).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT)
        : undefined
      : undefined;

    return {
      ...subject,
      firstName: subject?.firstName || '',
      lastName: subject?.lastName || '',
      dateOfBirth
    };
  }, [referralFields]);

  const { accountId } = useGetAccountId();
  const {
    matchedClients,
    count: matchCount,
    isLoading: isLoadingMatchedClients
  } = useGetMatchedClients(memoizedSubjectFields, !!referralFields.clientRecordId, referral);
  const { isPackageEnabled } = useGetFeatureToggle();
  const [uploadDocumentsToReferral] = useUploadDocumentsToReferralMutation();
  const [updateClientRecordId] = useUpdateClientRecordIdMutation();
  const [createReferral] = useCreateReferralMutation();
  const [editReferral] = useUpdateReferralMutation();
  const [assignPackage] = useAssignPackageMutation();

  const packageIds = useMemo(() => {
    const allPackageIds = referralFields.packages?.map((p) => p.packageId);
    return Array.from(new Set(allPackageIds));
  }, [referralFields.packages]);

  const packageQueryParam = useMemo(
    () => ({
      page: 1,
      perPage: HIGHEST_PAGING_COUNT * PACKAGE_LIMIT,
      ...(packageIds.length > 0 && { filterByIds: packageIds.join(',') })
    }),
    [packageIds]
  );

  const { data: packageFunderData } = useGetPackageListQuery(
    {
      accountId: accountId,
      params: packageQueryParam
    },
    { skip: !accountId || !isPackageEnabled || !packageIds.length }
  );

  const packageById = useMemo(() => {
    return new Map((packageFunderData?.packages || []).map((opt) => [opt._id, opt]));
  }, [packageFunderData]);

  const handleSubmit = async (reset: boolean = true, close: boolean = true) => {
    setNewReferral(defaultNewReferral);
    setPageSubmitStatus('active');

    const valid = await formRef.current?.validateFields(referralFields);
    if (valid) {
      try {
        const payload = {
          ...referralFields,
          clientRecordId: referralFields.clientRecordId,
          ...{
            org: referralFields.referrer.referrerType === ReferrerType.Organisation ? referralFields.org : undefined
          },
          ...formatReferralDates(referralFields, MOMENTJS_DATE_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT)
        };

        const createdReferral: Referral =
          isEditMode && '_id' in referralFields
            ? await editReferral({ accountId, payload, referralId: referralFields._id }).unwrap()
            : await createReferral({ accountId, payload }).unwrap();

        setNewReferral({
          _id: createdReferral._id,
          referralId: createdReferral.referralId || ''
        });

        if (uploadFiles.length) {
          const successUpload = await handleUploadDocuments({
            ...referralFields,
            _id: createdReferral._id,
            accountId: createdReferral.accountId,
            createdAt: new Date()
          });

          if (!successUpload) {
            setPageSubmitStatus('');
            return;
          }
        }

        setPageSubmitStatus('finished');

        await sleep(2000);

        scrollToView('modalHeader', true);
        notification.success({
          message: isEditMode ? 'Referral saved successfully' : 'New referral created',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        if (close) {
          handleCloseModal();
        } else {
          setIsEditMode(true);
          setReferralFields({
            ...createdReferral,
            ...formatReferralDates(createdReferral, MOMENTJS_YEAR_MONTH_DAY_FORMAT, MOMENTJS_DATE_FORMAT)
          });
        }

        if (reset) {
          setReferralFields(defaultFormValue);
        }
        setPageSubmitStatus('');
        return { success: true, referralId: createdReferral._id };
      } catch (ex) {
        console.error(ex);
        setPageSubmitStatus('');
        notification.error({
          message: isEditMode ? 'Failed to save referral' : 'Failed to create new referral'
        });
      }
    } else {
      setPageSubmitStatus('');
    }
  };

  const handleUploadDocuments = async (referral: Referral) => {
    if (!referral || !referral._id || !referral.accountId) {
      notification.error({ message: 'Failed to upload documents.' });
      return;
    }

    try {
      const formData = new FormData();
      uploadFiles.forEach((file) => formData.append('files', file));

      await uploadDocumentsToReferral({
        accountId: referral.accountId,
        referralId: referral._id,
        files: formData
      }).unwrap();

      setUploadFiles([]);
      formRef.current?.resetFiles();
      return true;
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Failed to upload documents.' });

      return;
    }
  };

  const handleCreateClientRecord = async () => {
    const response = await handleSubmit(false);
    if (response?.success) {
      setShowClientModal(true);
    }
  };

  const handleCreateAndLinkClientRecord = async (clientRecordId: string, isLinkPackage: boolean) => {
    const isCloseAndReset = false;
    const response = await handleSubmit(isCloseAndReset, isCloseAndReset);

    if (response?.success) {
      handleLinkClientRecord(clientRecordId, response.referralId, isCloseAndReset, isLinkPackage);
    }
  };

  const handleLinkClientRecord = async (
    clientRecordId: string,
    referralId: string,
    reset: boolean = true,
    isLinkPackage: boolean = false
  ) => {
    notification.warning({
      message: 'Linking client record...'
    });

    try {
      await updateClientRecordId({ accountId, referralId, clientRecordId }).unwrap();

      if (reset) {
        setReferralFields(defaultFormValue);
      } else {
        setReferralFields((fields) => ({
          ...fields,
          clientRecordId
        }));
      }

      notification.destroy();
      notification.success({
        message: 'Successfully link client record',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      if (isLinkPackage) {
        await handleLinkPackageToClient(referralId, clientRecordId);
      }
    } catch (ex) {
      console.error(ex);

      notification.destroy();
      notification.error({
        message: 'Failed to link client record'
      });
    }
  };

  const validateSelectedPackages = async (selectedPackage: SelectedPackage[]): Promise<boolean> => {
    try {
      await selectedPackageSchema.validate(selectedPackage, { abortEarly: false });
      return true;
    } catch (error) {
      console.error('Validation failed:', error);
      return false;
    }
  };

  const handleLinkPackageToClient = async (referralId: string, clientRecordId: string) => {
    const selectedPackages = referralFields.packages?.map((item) => ({ ...item, referralId }));
    if (selectedPackages && packageById) {
      const isValid = await validateSelectedPackages(selectedPackages);

      if (isValid) {
        notification.warning({
          message: 'Assigning packages to client...'
        });
        try {
          const massagePayload: AssignPackagesInterface[] = selectedPackages?.reduce((acc, assignObj) => {
            const currentPackage = packageById.get(assignObj.packageId);
            const currentFunder = currentPackage?.funders.find((funder) => funder.funderId === assignObj.funderId);

            if (currentPackage && currentFunder) {
              const payload = {
                packageId: assignObj.packageId,
                funder: {
                  type: currentFunder.type,
                  ...(currentFunder.type === funderType.AddressBook && {
                    funderId: currentFunder.funderId
                  }),
                  ...(currentFunder.type === funderType.Default && {
                    name: currentFunder.name
                  })
                },
                ...(assignObj.referralId && {
                  referralId: assignObj.referralId
                })
              };

              acc.push(payload);
            }

            return acc;
          }, [] as AssignPackagesInterface[]);

          const assignRes = await assignPackage({
            accountId,
            clientRecordId: clientRecordId,
            payload: massagePayload
          }).unwrap();

          const failedAssignments = assignRes.filter(({ successfullyAssigned }) => !successfullyAssigned);

          notification.destroy();
          notification.success({
            message: 'Successfully assign packages to client',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });

          if (failedAssignments.length > 0) {
            notification.error({
              message: 'Some packages failed to be assigned',
              description: (
                <ol className={styles.failDescWrapper}>
                  {failedAssignments.map((assignObj, idx) => {
                    const currentPackage = packageById.get(assignObj.packageId);
                    const currentFunder = currentPackage?.funders?.find(
                      (funderObj) => funderObj.funderId === assignObj.funder.funderId
                    );
                    return (
                      <li key={idx}>
                        <div className={styles.failTitle}>
                          {currentFunder?.name} - {currentFunder?.name}
                        </div>
                        <div className={styles.failDesc}>Reason: {assignObj.failedToAssignReason}</div>
                      </li>
                    );
                  })}
                </ol>
              )
            });
          } else {
            notification.success({
              message: 'Packages successfully assigned'
            });
          }
        } catch (ex) {
          console.error(ex);
          notification.error({
            message: 'Something went wrong while assigning to package.'
          });
        }
      } else {
        notification.error({
          message: 'Failed to assign packages to client'
        });
      }
    }
  };

  const handleCloseModal = () => {
    if (isEditMode) {
      setIsEditMode(isEditModal ?? false);
      setReferralFields(defaultFormValue);
      setUploadFiles([]);
      setNewReferral({ ...defaultNewReferral });
    }
    onCloseModal();
  };

  useEffect(() => {
    if (prefillClientRecordId) {
      setReferralFields((fields) => ({
        ...fields,
        clientRecordId: prefillClientRecordId
      }));
    }
  }, [prefillClientRecordId]);

  return (
    <>
      <ModalV2
        containerClassName={styles.modalContainer}
        isModalOpen={visible}
        title={isEditMode ? 'Edit Referral' : 'Create New Referral'}
        onModalClose={handleCloseModal}
        zIndex={1050}
      >
        <div className={styles.contentContainer}>
          <ReferralForm
            referralFields={referralFields}
            setReferralFields={setReferralFields}
            ref={formRef}
            onUploadFiles={(files) => setUploadFiles(files)}
            mode={isEditMode ? ReferralFormMode.Edit : ReferralFormMode.Add}
          />

          {referralFields.rdf && (
            <div className={styles.sectionContainer}>
              <ReferralCustomFieldsTable rdf={referralFields.rdf} />
            </div>
          )}

          <div className={styles.sectionContainer}>
            {referralFields.clientRecordId ? (
              <div className={styles.singleButton}>
                <ButtonAlt status={pageSubmitStatus} disabled={pageSubmitStatus !== ''} onClick={() => handleSubmit()}>
                  Save Referral
                </ButtonAlt>
              </div>
            ) : (
              <>
                <div className={styles.header}>CLIENT LINK</div>
                <div className={styles.content}>
                  {(matchedClients.length > 0 || isLoadingMatchedClients) && (
                    <>
                      {isLoadingMatchedClients ? (
                        <SkeletonButton active className={styles.matchList} />
                      ) : (
                        <div className={styles.matchList}>
                          {isLargeClientMatchCount(matchCount) && (
                            <div className={styles.largeMatches}>
                              <i className={`material-icons-outlined ${styles.icon}`}>info</i> Large number of potential
                              matches to this referral. Showing top 10 matches.
                            </div>
                          )}

                          {matchedClients.map((client, index) => {
                            return (
                              <ReferralMatchItem
                                key={index}
                                client={client}
                                hasPackage={referralFields.packages && referralFields.packages.length > 0}
                                disabled={pageSubmitStatus !== ''}
                                onLinkClient={(isLinkPackage) =>
                                  handleCreateAndLinkClientRecord(client.clientRecordId, isLinkPackage)
                                }
                                beforeNavigate={handleCloseModal}
                              />
                            );
                          })}
                        </div>
                      )}

                      <span className={styles.sectionDivider}>OR</span>
                    </>
                  )}

                  <ButtonAlt
                    icon="group_add"
                    variant="outlined"
                    onClick={() => handleCreateClientRecord()}
                    disabled={pageSubmitStatus !== ''}
                  >
                    Save Referral and Create New Profile
                  </ButtonAlt>

                  <span className={styles.sectionDivider}>OR</span>

                  <ButtonAlt
                    status={pageSubmitStatus}
                    disabled={pageSubmitStatus !== ''}
                    onClick={() => handleSubmit()}
                  >
                    Save Referral
                  </ButtonAlt>
                </div>
              </>
            )}
          </div>
        </div>
      </ModalV2>

      {showClientModal && (
        <ReferralNewClientForm
          referral={referralFields}
          setCloseModal={setShowClientModal}
          onComplete={(clientRecordId) => handleLinkClientRecord(clientRecordId, newReferral._id)}
        />
      )}
    </>
  );
};

export default AddReferralModal;
