import { useEffect, useRef, useState } from 'react';
import { notification } from 'antd';
import LETTER_BUILDER_IMG from 'assets/images/reportBuilderImg.png';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import PatientDetailsLettersHeader from './components/PatientDetailsReportsHeader/PatientDetailsLettersHeader';
import styles from './PatientDetailsLetters.module.scss';
import { useGetAccessToken } from 'utils/hooks/token';
import { downloadLetterPdf, getLetter, getLetterByAdmin } from 'utils/http/DocumentService/Letters/letters';
import { Letter } from 'interfaces/Letters/letter';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import Select from 'components/v2/Select/Select';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import LetterPreview from 'pages/Report/components/LetterPreview/LetterPreview';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useFetchLetterList } from '../../hooks/actions';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { useAppSelector } from 'redux/hooks';
import { selectIsShowAllData } from 'redux/episodes/episodeSlice';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import ReportContentAsPDF from 'pages/Report/components/ReportContentAsPDF/ReportContentAsPDF';
import { useGetAccountInfo } from 'utils/hooks/GetAccountInfo/getAccountInfo';
import { generatePrintableReportPayload, triggerDownload } from 'pages/Report/utils';
import { useTranslation } from 'react-i18next';

interface PatientDetailsLettersProps {
  recordId: string;
  currentTab: string;
  letterId?: string;
  clientRecordData: clientRecordsInterface;
}

const PatientDetailsLetters = ({ recordId, currentTab, letterId, clientRecordData }: PatientDetailsLettersProps) => {
  const [t] = useTranslation();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { auth0ClinicianId } = useGetClinicianId();
  const { CLIENTS } = useRoutesGenerator();
  const { isEdgePlanUser } = useGetAccountPackage();
  const [isLoadingLetterData, setIsLoadingLetterData] = useState(true);
  const [letterData, setLetterData] = useState<Letter>();
  const [selectedLetterId, setSelectedLetterId] = useState('');
  const [selectOptions, setSelectOptions] = useState<IOptionItem[]>([]);
  const [selectValue, setSelectValue] = useState<IOptionItem | undefined>();
  const navigate = useNavigate();
  const location = useLocation();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const { isEoCEnabled } = useGetFeatureToggle();
  const { clinicianProfile } = useGetAccountInfo();
  const { LETTERS } = useRoutesGenerator();
  const [downloadPDFButtonStatus, setDownloadPDFButtonStatus] = useState<ButtonStatusType>('');
  const letterRef = useRef(null);

  const {
    letterList,
    isLetterListLoading: isLoadingLetterList,
    isLetterListFetching
  } = useFetchLetterList(accountId, auth0ClinicianId, recordId);

  useEffect(() => {
    //Prevent showing old letter detail when changing episode
    if (isEoCEnabled && !isShowAllData && letterList[0]?._id) {
      navigate(`${CLIENTS.BASE}/${recordId}/letters/${letterList[0]._id}`);
      return;
    }
    if (!letterId && !isLoadingLetterList && letterList && letterList[0]?._id) {
      navigate(`${CLIENTS.BASE}/${recordId}/letters/${letterList[0]._id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letterList, isLoadingLetterList, isLetterListFetching]);

  const noLetterId = !isLoadingLetterList && letterList.length !== 0 ? letterList[0]._id : '';

  const getLetterId: string = isEdgePlanUser
    ? location.pathname.split('/')[5] ?? noLetterId
    : location.pathname.split('/')[4] ?? noLetterId;

  const noLetters = !isLoadingLetterList && !isLetterListFetching && letterList?.length === 0;

  const fetchLetterDetails = async (patientLetterId: string) => {
    if (patientLetterId) {
      setIsLoadingLetterData(true);

      try {
        const getLetterRes =
          isEdgeAdminView || isEdgeReceptionist
            ? await getLetterByAdmin(token, accountId, patientLetterId)
            : await getLetter(token, accountId, auth0ClinicianId, patientLetterId);
        const letter = await getLetterRes.json();
        setLetterData({
          ...letter,
          clientRecord: clientRecordData
        });
        setIsLoadingLetterData(false);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get this letters detail' });
      }
    } else {
      setIsLoadingLetterData(false);
    }
  };

  useEffect(() => {
    const id = letterId || '';
    if (token) {
      fetchLetterDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letterId, token, recordId]);

  useEffect(() => {
    if (!letterList?.length || isLetterListFetching) {
      return;
    }

    const itemLabel = letterList.find((i) => i._id === getLetterId);
    if (itemLabel) {
      setSelectedLetterId(itemLabel.letterName);
    }

    setSelectOptions(letterList.map((i) => ({ value: i._id, label: i.letterName })));

    setSelectValue(
      letterList.length
        ? selectedLetterId
          ? {
              value: letterList.find((i) => i.letterName === selectedLetterId)?._id || '',
              label: selectedLetterId
            }
          : letterList[0]
          ? { value: letterList[0]._id, label: letterList[0].letterName }
          : undefined
        : undefined
    );
  }, [letterList, getLetterId, setSelectedLetterId, isLetterListFetching, selectedLetterId]);

  const handleDownloadPdf = async () => {
    if (letterId && letterData) {
      setDownloadPDFButtonStatus('active');
      try {
        const retrievedPDF = await downloadLetterPdf(
          token,
          accountId,
          letterId,
          !letterData?.attachment
            ? {
                printPayload: generatePrintableReportPayload(letterRef.current) || ''
              }
            : undefined
        );
        if (retrievedPDF) {
          triggerDownload(retrievedPDF, `Letter_${letterId}`);
        }
        setDownloadPDFButtonStatus('finished');
        notification.success({
          closeIcon: <span className="success">OK</span>,
          duration: 2,
          message: 'Download letter as PDF successful.'
        });
      } catch (ex) {
        notification.error({
          closeIcon: <span className="success">OK</span>,
          duration: 2,
          message: 'Fail to download letter as PDF.'
        });
      } finally {
        setTimeout(() => {
          setDownloadPDFButtonStatus('');
        }, 1000);
      }
    }
  };

  return (
    <>
      <div className={styles.headerContainer}>
        {!noLetters && (
          <>
            <Select
              filledArrow
              label="LETTERS"
              options={selectOptions}
              onChange={(val: any) => {
                setSelectedLetterId(val?.label);
                navigate(`${CLIENTS.BASE}/${recordId}/letters/${val?.value}`);
              }}
              isSearchable={false}
              value={selectValue}
              isDisabled={isLoadingLetterList && isLetterListFetching}
              isLoading={isLoadingLetterList || isLetterListFetching}
              containerClass={styles.selectLetterId}
              controlStyle={{ boxShadow: 'none', minWidth: '300px' }}
            />
            <ButtonAlt
              variant="outlined"
              icon="file_download"
              onClick={handleDownloadPdf}
              status={downloadPDFButtonStatus}
              disabled={!letterData || isLoadingLetterData}
              loadingWord="Preparing PDF"
            >
              Download as PDF
            </ButtonAlt>
          </>
        )}
      </div>
      <div className={styles.container}>
        {isLoadingLetterList || isLetterListFetching ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : (
          <>
            {letterId && letterList.length > 0 ? (
              <>
                <PatientDetailsLettersHeader
                  recordId={recordId}
                  currentTab={currentTab}
                  letterId={letterId}
                  letterList={letterList}
                />
                {clinicianProfile && letterData && (
                  <ReportContentAsPDF
                    type="letter"
                    letter={letterData}
                    practice={clinicianProfile.practice}
                    ref={letterRef}
                  />
                )}
                {isLoadingLetterData || isLetterListFetching ? (
                  <div className={styles.loading}>
                    <LoadingCircle />
                  </div>
                ) : (
                  letterData && (
                    <HelmetWrapper title={'Tacklit - Letter Details'}>
                      <LetterPreview data={letterData} clientRecord={clientRecordData} />
                    </HelmetWrapper>
                  )
                )}
              </>
            ) : (
              <div className={styles.noDataWrapper} id={'noLetterListingId'}>
                <img className={styles.image} alt={'no letter img'} src={LETTER_BUILDER_IMG} />
                <div className={styles.infoWrapper}>
                  <div className={styles.text}>{t('label.no_letter_for_any_client')}</div>
                  <ButtonAlt
                    size={'medium'}
                    variant="outlined"
                    onClick={() => navigate(LETTERS.NEW)}
                    icon="add_circle_outline"
                  >
                    Create New Letter
                  </ButtonAlt>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PatientDetailsLetters;
