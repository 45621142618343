import { useEffect, useState } from 'react';
import styles from './ConsentForms.module.scss';
import {
  ConsentFormListFilterParameter,
  ConsentFormResponseList,
  GroupDetailsTab
} from 'pages/Groups/Interfaces/Groups';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useGetAccessToken } from 'utils/hooks/token';
import queryString from 'query-string';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { SortType } from 'components/TableList/SortBtn/SortBtn';
import { useFetchConsentFormList } from './hooks/getConsentFormList';
import ConsentFormHeader from './components/ConsentFormsHeader/ConsentFormsHeader';
import ConsentFormList from './components/ConsentFormList/ConsentFormList';
import Select from 'components/v2/Select/Select';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';

interface ConsentFormsProps {
  consentId?: string;
  consentFormMenuList: ConsentFormResponseList[];
  consentFormMenuListLoading: boolean;
}

interface SelectedConsentProps {
  value: string;
  label: string;
}

const ConsentForms = ({ consentId, consentFormMenuList, consentFormMenuListLoading }: ConsentFormsProps) => {
  const { token } = useGetAccessToken();
  const { isEdgePlanUser } = useGetAccountPackage();
  const location = useLocation();
  const queryParam: ConsentFormListFilterParameter = queryString.parse(location.search);
  const { groupId = '' } = useParams<{ groupId: string }>();
  const navigate = useNavigate();
  const { CARECOORDINATION } = useRoutesGenerator();
  const [selectedConsentId, setConsentSurveyId] = useState<SelectedConsentProps>();
  const noConsents = !consentFormMenuListLoading && consentFormMenuList?.length === 0;
  const noConsentId = !consentFormMenuListLoading && consentFormMenuList.length !== 0 ? consentFormMenuList[0]._id : '';
  const getConsentId: string = isEdgePlanUser
    ? location.pathname.split('/')[5] ?? noConsentId
    : location.pathname.split('/')[4] ?? noConsentId;

  const [searchQueryParam, setSearchQueryParam] = useState<ConsentFormListFilterParameter>({
    q: queryParam.q || '',
    page: queryParam.page && !isNaN(parseInt(queryParam.page, 10)) ? queryParam.page : '1',
    perPage: queryParam.perPage && !isNaN(parseInt(queryParam.perPage, 10)) ? queryParam.perPage : '20',
    sortType: queryParam.sortType && Object.values(SortType).includes(queryParam.sortType) ? queryParam.sortType : 1,
    sortColumn: queryParam.sortColumn ? queryParam.sortColumn : ''
  });

  const { consentFormList, isConsentFormListLoading } = useFetchConsentFormList(token, groupId, searchQueryParam);

  const setNewParam = (newParamValue: ConsentFormListFilterParameter) => {
    setSearchQueryParam(newParamValue);
    const paramStringify = queryString.stringify(newParamValue);
    navigate(`${CARECOORDINATION.GROUPS}/${groupId}/${GroupDetailsTab.Consent}/${consentId}?${paramStringify}`);
  };

  const handleChangePerPage = (perPageValue: number) => {
    if (parseInt(searchQueryParam.perPage as string, 10) !== perPageValue) {
      const newParam = {
        ...searchQueryParam,
        page: '1',
        perPage: perPageValue.toString()
      };
      setNewParam(newParam);
    }
  };

  const handlePageChange = (pageValue: number) => {
    const newParam = {
      ...searchQueryParam,
      page: pageValue.toString()
    };
    setNewParam(newParam);
  };

  const handleChangeSortType = (sortType: SortType, sortColumn?: string) => {
    const newParam = {
      ...searchQueryParam,
      sortType: sortType,
      sortColumn: sortColumn
    };
    setNewParam(newParam);
  };

  useEffect(() => {
    const consent = consentFormMenuList.find((i) => i._id === getConsentId);
    if (consent) {
      setConsentSurveyId({ value: consent._id, label: consent.name! });
    }
  }, [consentFormMenuList, getConsentId, setConsentSurveyId]);

  return (
    <div className={styles.columnContainer}>
      {!noConsents && (
        <div className={styles.headerContainer}>
          <Select
            filledArrow
            label="CONSENT FORMS"
            options={consentFormMenuList?.map((i) => ({ value: i._id, label: i.name }))}
            onChange={(val: any) => {
              if (val?.value !== selectedConsentId?.value) {
                setConsentSurveyId(val);
                navigate(`${CARECOORDINATION.GROUPS}/${groupId}/${GroupDetailsTab.Consent}/${val.value}`);
              }
            }}
            isSearchable={false}
            //@ts-ignore
            value={
              consentFormMenuList
                ? selectedConsentId
                  ? {
                      value: consentFormMenuList.find((i) => i._id === selectedConsentId.value)!._id,
                      label: selectedConsentId.label
                    }
                  : consentFormMenuList[0]
                  ? { value: consentFormMenuList[0]._id, label: consentFormMenuList[0].name }
                  : undefined
                : undefined
            }
            isLoading={consentFormMenuListLoading}
            containerClass={styles.selectConsentId}
            controlStyle={{ boxShadow: 'none' }}
          />
        </div>
      )}
      <div className={styles.container}>
        <ConsentFormHeader
          groupId={groupId}
          consentFormId={consentId || consentFormMenuList[0]._id} // TODO
          consentFormMenuList={consentFormMenuList}
        />
        <ConsentFormList
          consentFormList={consentFormList}
          isConsentFormListLoading={isConsentFormListLoading}
          sortColumn={searchQueryParam.sortColumn || ''}
          selectedPerPage={parseInt(searchQueryParam.perPage as string, 10)}
          selectedPage={parseInt(searchQueryParam.page as string, 10)}
          sortType={searchQueryParam.sortType as SortType}
          onChangeSortType={handleChangeSortType}
          onChangePerPage={handleChangePerPage}
          onChangePage={handlePageChange}
        />
      </div>
    </div>
  );
};

export default ConsentForms;
