import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { DELIVERY_TYPE_ICONS } from 'interfaces/Schedule/AppointmentType';
import styles from './BespokeItem.module.scss';
import moment from 'moment';
import { getDeliveryTypeLabel } from 'utils/appointment';

export interface BespokeItemProps {
  appointment: Omit<AppointmentSlots, 'clientRecord' | 'deliveryType'> &
    Required<Pick<AppointmentSlots, 'clientRecord' | 'deliveryType'>>;
}

const BespokeItem = ({ appointment }: BespokeItemProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.eventTitleSection}>{appointment.title}</div>
      <div className={styles.detailsContainer}>
        <div className={styles.mainInfo}>
          <div className={styles.timeSection}>
            <i className={`material-icons ${styles.timeIcon}`}>schedule</i>
            <div className={styles.time}>
              {moment(appointment.startTime, 'HH:mm').format('hh:mmA')} -{' '}
              {moment(appointment.endTime, 'HH:mm').format('hh:mmA')}
            </div>
          </div>
          <div className={styles.deliveryModeSection}>
            <div className={styles.deliveryModeWrapper}>
              <i className={`material-icons ${styles.deliveryIcon}`}>{DELIVERY_TYPE_ICONS[appointment.deliveryType]}</i>
              <div>{getDeliveryTypeLabel(appointment.deliveryType, appointment.otherInstructions)}</div>
            </div>
          </div>
          <div className={styles.roomSection}>
            {appointment.room && (
              <>
                <i className={`material-icons-outlined ${styles.meetingIcon}`}>meeting_room</i>
                <div>{appointment.room.roomName}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BespokeItem;
