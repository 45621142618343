/* eslint-disable react/no-multi-comp */
import { useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from 'components/Loading/Loading';
import { useLogout } from 'utils/hooks/logout';

const Logout = () => {
  const { logout } = useLogout();

  useEffect(() => {
    logout();
  }, [logout]);

  return <Loading />;
};

export default withAuthenticationRequired(Logout, {
  onRedirecting: () => <Loading />
});
