import { Skeleton } from 'antd';
import classnames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Select from 'components/Select/CommonSelect/Select';
import { getProviderLabel } from 'pages/ControlPanel/IntegrationDetails/utils';
import { MappingField, Option } from 'pages/ControlPanel/Interfaces/MappingField';

import { EditType } from '../../constants';
import styles from './Mappings.module.scss';

interface MappingsProps {
  itemList: MappingField[];
  editType: EditType;
  provider: string;
  dropdownOption: Option[];
  taxRateOptions: Option[];
  trackingCategories: MappingField[];
  trackingCategoriesOptions: Option[];
  validationErrors: { id: string }[];
  trackingCategoriesValidationError: { id: string }[];
  isTaxRateLoading: boolean;
  isLoading: boolean;
  handleEnabledChanged: (index: number) => void;
  handleSelectedChanged: (index: number, id: string) => void;
  handleTrackingCategoryEnabledChanged: (index: number) => void;
  handleTrackingCategorySelectedChanged: (index: number, id: string) => void;
}

const getLabelField = (editType: string, provider: string) => {
  switch (editType) {
    case EditType.Fields:
      return `${provider.toUpperCase()} DATA FIELD`;
    case EditType.Accounts:
      return 'PRACTITIONER LIST';
    case EditType.Items:
      return 'APPOINTMENT LIST';
    case EditType.Payments:
      return 'PAYMENT TYPE';
    default:
      return 'LIST';
  }
};

const getIntegrationField = (editType: string, provider: string) => {
  switch (editType) {
    case EditType.Fields:
      return `TACKLIT DATA`;
    case EditType.Accounts:
      return `${provider.toUpperCase()} ACCOUNT`;
    case EditType.Items:
      return `${provider.toUpperCase()} INVOICE ITEM`;
    case EditType.Payments:
      return 'PAYMENT ACCOUNT';
    default:
      return 'ITEM';
  }
};

const getDropdownOptions = (parentId: string, dropdownOption: Option[]) =>
  dropdownOption.filter((option) => option.parentId === parentId);

const isXeroNotesField = ({ provider, editType, id }: { provider: string; editType: EditType; id: string }) =>
  provider === 'xero' && editType === EditType.Fields && id === 'notes';

const Mappings = ({
  itemList,
  editType,
  provider,
  dropdownOption,
  taxRateOptions,
  trackingCategories,
  trackingCategoriesOptions,
  trackingCategoriesValidationError,
  validationErrors,
  isTaxRateLoading,
  isLoading,
  handleEnabledChanged,
  handleSelectedChanged,
  handleTrackingCategoryEnabledChanged,
  handleTrackingCategorySelectedChanged
}: MappingsProps) => {
  const providerLabel = getProviderLabel(provider);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.checkboxField} />
        <div className={styles.labelField}>{getLabelField(editType, providerLabel)}</div>
        <div className={styles.mapsTo} />
        <div className={styles.integrationField}>{getIntegrationField(editType, providerLabel)}</div>
      </div>
      {itemList.map(({ id, required, active, selectedId, label }, index) => (
        <div className={styles.rowContainer}>
          <div className={styles.checkboxContainer}>
            <input
              className={classnames(
                provider === 'xero' && editType === EditType.Items ? styles.hidden : styles.checkbox
              )}
              id={id}
              type="checkbox"
              checked={required ? true : active}
              disabled={isXeroNotesField({ provider, editType, id }) || required ? true : false}
              onChange={() => handleEnabledChanged(index)}
            />
          </div>
          <div className={styles.label}>{label}</div>
          <div className={classnames(styles.mapsTo, !active && styles.hidden)}>{`${
            id === 'taxRates' ? 'sets' : 'maps'
          } to`}</div>
          <div className={classnames(styles.selectionContainer, !active && styles.hidden)}>
            {(id === 'taxRates' && isTaxRateLoading) || (editType !== EditType.Fields && isLoading) ? (
              <Skeleton.Input active />
            ) : (
              <>
                <Select
                  className={styles.dropdown}
                  label="Select data field"
                  labelClass={styles.label}
                  options={id === 'taxRates' ? taxRateOptions : dropdownOption}
                  placeholder="Select from list"
                  onChange={(val: any) => handleSelectedChanged(index, val?.value ?? '')}
                  value={selectedId ?? ''}
                />
                <ErrorMessage
                  className={styles.error}
                  visible={validationErrors.some((error) => error.id === id)}
                  error={'Please select one'}
                />
              </>
            )}
          </div>
        </div>
      ))}
      {editType === EditType.Fields && trackingCategories.length > 0 && (
        <>
          <div className={styles.subcategoryTitle}>Tracking Categories</div>
          {trackingCategories.map(({ id, required, active, selectedId, label }, index) => (
            <div className={styles.rowContainer}>
              <div className={styles.checkboxContainer}>
                <input
                  className={styles.checkbox}
                  id={id}
                  type="checkbox"
                  checked={active}
                  disabled={required ? true : false}
                  onChange={() => handleTrackingCategoryEnabledChanged(index)}
                />
              </div>
              <div className={styles.label}>{label}</div>
              <div className={styles.mapsTo}>maps to</div>
              <div className={styles.selectionContainer}>
                <Select
                  className={styles.dropdown}
                  label="Select data field"
                  labelClass={styles.label}
                  options={getDropdownOptions(id, trackingCategoriesOptions)}
                  placeholder="Select from list"
                  onChange={(val: any) => handleTrackingCategorySelectedChanged(index, val?.value ?? '')}
                  value={selectedId ?? ''}
                />
                <ErrorMessage
                  className={styles.error}
                  visible={trackingCategoriesValidationError.some((error) => error.id === id)}
                  error={'Please select one'}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Mappings;
