import { useEffect, useMemo, useState } from 'react';
import { Modal, notification } from 'antd';
import Loading from 'components/Loading/Loading';
import InfiniteScroll from 'react-infinite-scroller';
import { differenceWith, isEqual } from 'lodash';
import { useMCIClientsList } from 'utils/hooks/useMCIClientsList';
import { RefetchMciDetails } from 'utils/hooks/useMCIDetails';
import { useMCIList } from 'utils/hooks/useMCIList';
import { canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import { putAssignedMCIList } from 'utils/http/CheckInService/MicroCheckIn/mci';

import AddPatientModalV2 from 'components/AddPatientModalV2/AddPatientModalV2';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import SubmitButton from 'components/v2/Button/Button';

import styles from './ClientAssignList.module.scss';
import { ClientAssignInterface, MCIClientSaveStruct, MCIClientStruct } from './components/interfaces';
import ClientAssignItem from './components/ClientAssignItem/ClientAssignItem';
import { useTranslation } from 'react-i18next';
import SearchBar from 'components/v2/SearchBar/SearchBar';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchNotificationSettings } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { security } from 'utils/security';

export type OnSelectProgram = (data: { profileId: string; questionId?: string }) => void;

export interface ClientAssignListProps {
  isEditMode: boolean;
  isShowModal: boolean;
  closeModal: () => void;
  refetchMciDetails: RefetchMciDetails;
}

const ClientAssignList = ({ isEditMode, isShowModal, closeModal, refetchMciDetails }: ClientAssignListProps) => {
  const [inputClientsList, setInputClientsList] = useState<ClientAssignInterface[]>([]);
  const { mCIList: mCIAssessmentTemplate, mCIListInitializing } = useMCIList({ isEditMode });
  const {
    clientsList: fetchedClientsList,
    clientsListInitializing,
    hasMoreClients,
    loadMoreClients,
    searching,
    searchTerm,
    setSearchTerm
  } = useMCIClientsList();
  const [showAddClient, setShowAddClient] = useState(false);
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');

  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();

  const { notificationSettings, isNotificationSettingsLoading } = useFetchNotificationSettings(token, accountId);
  const [t] = useTranslation();

  useEffect(() => {
    /**
     * Retain unsaved assignments when more clients are loaded.
     * Map latest fetched clients with unsaved assignments.
     */
    const clientsList = fetchedClientsList.map(
      (fetchedClient) =>
        changedClientsList.find((changedClient) => changedClient._id === fetchedClient._id) ?? fetchedClient
    );
    setInputClientsList(clientsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedClientsList]);

  const changedClientsList = useMemo(
    () => differenceWith(inputClientsList, fetchedClientsList, isEqual),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputClientsList]
  );

  const massageProfileData = (clientProfiles: MCIClientStruct[], profileId: string, questionId?: string) => {
    const newClientProfile: MCIClientStruct[] = [];
    clientProfiles.forEach((clientProfileObj) =>
      newClientProfile.push({
        ...clientProfileObj,
        microCheckInId: clientProfileObj._id === profileId ? questionId ?? null : clientProfileObj.microCheckInId
      })
    );

    return newClientProfile;
  };

  const onSelectProgram: OnSelectProgram = ({ profileId, questionId }) =>
    setInputClientsList(
      inputClientsList.map((clientRecordObj) => ({
        ...clientRecordObj,
        clientProfiles: massageProfileData(clientRecordObj.clientProfiles, profileId, questionId)
      }))
    );

  const massageRecordIdToProfile = (profileData: MCIClientStruct[], recordId: string) => {
    let newClientProfileWithRecordId = [] as MCIClientSaveStruct[];
    profileData
      .filter(
        ({ clientAuth0Id, communicationPreference }) =>
          !!clientAuth0Id &&
          canClientReceiveSms({
            notificationSettings,
            notificationSettingsCategory: 'forms',
            communicationPreference
          })
      )
      .map((clientProfileObj) =>
        newClientProfileWithRecordId.push({
          clientRecordId: recordId,
          clientAuth0Id: clientProfileObj.clientAuth0Id ?? '',
          clientProfileId: clientProfileObj._id,
          microCheckInId: clientProfileObj.microCheckInId ?? null
        })
      );

    return newClientProfileWithRecordId;
  };

  const massageSaveData = (profileData: ClientAssignInterface[]) => {
    const newClientRecord: MCIClientSaveStruct[] = [];
    profileData.forEach((clientRecord) =>
      newClientRecord.push(...massageRecordIdToProfile(clientRecord.clientProfiles, clientRecord._id))
    );

    return newClientRecord;
  };

  const saveClientData = async () => {
    setSaveStatus('active');
    const massageClientProfile = massageSaveData(changedClientsList);

    try {
      const token = await security.getAccessTokenSilently();

      await putAssignedMCIList(token, massageClientProfile);

      /**
       * No need to await, so that can proceed with UI changes
       * while refetching latest data in the background to improve UX
       */
      refetchMciDetails();

      notification.success({
        message: t('form.success.update_mci_clients'),
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      setSaveStatus('finished');

      setTimeout(() => {
        setSaveStatus('');
        closeModal();
      }, 1000);
    } catch (ex) {
      console.error(ex);
      setSaveStatus('');
      notification.error({ message: t('form.error.update_mci_clients'), duration: 5 });
    }
  };

  const handleCancel = () => {
    closeModal();
    setInputClientsList(fetchedClientsList); // Reset unsaved assignment changes
    setSearchTerm(''); // Reset search term
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={<div className={styles.title}>{t('title.assign_client_to_checkin')}</div>}
      open={isShowModal}
      onCancel={handleCancel}
      footer={null}
    >
      <div className={styles.container}>
        {clientsListInitializing || mCIListInitializing || isNotificationSettingsLoading ? (
          <div className={styles.loadingWrapper}>
            <LoadingCircle />
          </div>
        ) : (
          <>
            {inputClientsList.length > 0 && (
              <div className={styles.headerWrapper}>
                <div className={styles.label}>{t('label.select_client')}</div>
                <SubmitButton
                  disabled={saveStatus !== '' || !changedClientsList.length}
                  status={saveStatus}
                  onClick={saveClientData}
                  className={styles.saveBtn}
                >
                  Save
                </SubmitButton>
              </div>
            )}
            <SearchBar searchValue={searchTerm} setSearchValue={setSearchTerm} />
            {searching ? (
              <div className={styles.loadingWrapper}>
                <LoadingCircle />
              </div>
            ) : inputClientsList.length > 0 ? (
              <div className={styles.clientList}>
                <InfiniteScroll
                  hasMore={hasMoreClients}
                  initialLoad={false}
                  loader={<Loading key="infinite-scroll-loading-spinner" className={styles.infiniteScrollLoader} />}
                  loadMore={loadMoreClients}
                  useWindow={false}
                >
                  {inputClientsList.map((clientAssign, index) => (
                    <div key={index}>
                      <ClientAssignItem
                        clientAssign={clientAssign}
                        mCIAssessmentTemplate={mCIAssessmentTemplate}
                        onSelectProgram={onSelectProgram}
                        notificationSettings={notificationSettings}
                      />
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            ) : searchTerm.length > 0 && inputClientsList.length === 0 ? (
              <div className={styles.noItems}>
                <i className="material-icons-outlined">search_off</i>
                No matching records found
              </div>
            ) : (
              <div className={styles.noClientContainer}>
                <div>{t('label.no_client_record_found_create_one')}</div>
                <SubmitButton
                  variant={'link'}
                  icon={'add'}
                  onClick={() => {
                    setShowAddClient(true);
                  }}
                >
                  {t('button.add_new_client')}
                </SubmitButton>
              </div>
            )}
          </>
        )}
        <AddPatientModalV2 visible={showAddClient} onCancel={() => setShowAddClient(false)} />
      </div>
    </Modal>
  );
};

export default ClientAssignList;
