import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { DeliveryType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useGetClientRecordMinifiedForInlineAppointmentQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetAppointmentTypesForInlineAppointmentQuery } from 'redux/endpoints/scheduleServices/appointmentType';
import {
  AppointmentHumanFactorStep,
  DEFAULT_ROOM_OPTION,
  selectAppointmentData,
  selectCurrentStep,
  selectPractitionerMainOptions,
  setCurrentStep,
  setSelectedAppointmentType,
  setSelectedClient,
  setSelectedDeliveryMode,
  setSelectedPractitioner,
  setSelectedRoom,
  setSelectedTime,
  setIsPackageAppointment,
  setRecurring,
  selectedAppointmentView,
  AppointmentViewType,
  startInlineCalendarValidation,
  setAppointmentViewFilter,
  appointmentViewFilter,
  setInstructionNote
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import styles from './HumanFactors.module.scss';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import moment from 'moment';
import { getDeliveryTypeInstruction, getDeliveryTypeLabel } from 'utils/appointment';
import { config } from 'config/config';
import { useGetPractitionerDetailsListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useGetClinicianProfileQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import { useGetPackageBookableAppointmentQuery } from 'redux/endpoints/scheduleServices/package';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetGeneralSettingsQuery } from 'redux/endpoints/clinicianProfileServices/generalSettings';
import classnames from 'classnames';
import { oneOnOneAppointmentValidation } from '../../../../../../Validation/InlineBookingValidationHelper';
import { CalendarFilterInterface } from 'components/v2/CalendarFilter/interfaces';
import { getRandomColor } from '../../../../../../../../../CalendarFilterSection/components/FilterCheckList/components/FilterColorBox/FilterColorBox';
import { calendarFilters, setSelectedClinicians } from 'redux/calendarAppointmentList/calendarAppointmentListDataSlice';
import { setSelectedFiltersToLocalStorage } from '../../../../../../../../../../selectedFilters/selectedFilters';

const PER_PAGE = 50;
const ITEM_FLAG = 'packageItem';

interface HumanFactorsProps {
  practitionerMainOption?: boolean;
  rememberSelectedPractitioner?: boolean;
  showSelectedItemOnFirstOption?: boolean;
}

const HumanFactors = ({
  practitionerMainOption,
  rememberSelectedPractitioner,
  showSelectedItemOnFirstOption
}: HumanFactorsProps) => {
  const dispatch = useAppDispatch();
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeAdminUser, isNormalUserView, isEdgeUserView, isEdgeReceptionist } =
    useGetAccountPackageView();
  const { isAppointmentRateReadDenied } = useGetPermissionToggle();
  const { isPackageEnabled } = useGetFeatureToggle();

  const [clientListCurrentPage, setClientListCurrentPage] = useState<number>(1);
  const [clientSearchText, setClientSearchText] = useState<string>('');

  const currentStep = useAppSelector(selectCurrentStep);
  const practitionerMainOptions = useAppSelector(selectPractitionerMainOptions);
  const { selectedPractitioner, selectedClient, selectedAppointmentType, selectedDeliveryMode, selectedTime } =
    useAppSelector(selectAppointmentData);
  const selectedAppointmentTypeView = useAppSelector(selectedAppointmentView);
  const inlineCalendarValidation = useAppSelector(startInlineCalendarValidation);
  const selectedAppointmentViewFilter = useAppSelector(appointmentViewFilter);
  const { selectedClinicians, selectedRooms, selectedHighLights } = useAppSelector(calendarFilters);

  const { data: generalSettings, isLoading: generalSettingsLoading } = useGetGeneralSettingsQuery(
    {
      accountId: accountId
    },
    { skip: !accountId }
  );

  const packageOnlyAppointment = useMemo(
    () =>
      isPackageEnabled &&
      (!isEdgeAdminView && !isEdgeReceptionist
        ? generalSettings?.packageOnlyBookings.isActive
        : generalSettings?.packageOnlyBookings.isActive && !generalSettings?.packageOnlyBookings.onlyRestrictUserRole),
    [generalSettings, isEdgeAdminView, isEdgeReceptionist, isPackageEnabled]
  );

  const {
    data: clientMinifiedListData,
    isLoading: isClientMinifiedListDataLoading,
    isFetching: isClientMinifiedListDataFetching
  } = useGetClientRecordMinifiedForInlineAppointmentQuery(
    {
      accountId,
      params: {
        ...(selectedPractitioner?._id && { clinicianId: selectedPractitioner._id }),
        recordStatus: isNormalUserView ? 'active' : 'active,waitlist',
        ...(clientSearchText && {
          searchValue: clientSearchText,
          searchBy: SearchFilterEnum.FirstOrLastName
        }),
        page: clientListCurrentPage,
        perPage: PER_PAGE
      }
    },
    { skip: (isEdgeAdminView || isEdgeReceptionist) && selectedPractitioner === undefined }
  );

  const {
    data: practitionerOptions,
    isFetching: practitionerOptionsFetching,
    isLoading: practitionerOptionsLoading
  } = useGetPractitionerDetailsListQuery({
    accountId,
    params: {
      status: 'active',
      withAccessRights: [AccessRight.Admin, AccessRight.User, AccessRight.Mentor]
    }
  });

  const { data: clinicianProfile } = useGetClinicianProfileQuery();

  const {
    data: appointmentTypesData,
    isLoading: isAppointmentTypesDataLoading,
    isFetching: isAppointmentTypesDataFetching
  } = useGetAppointmentTypesForInlineAppointmentQuery(
    {
      isAdmin: isEdgeAdminUser || isEdgeReceptionist || isNormalUserView,
      clinicianId: selectedPractitioner?._id || '',
      participantType: ParticipantType.OneToOne // 1:1 for now, extend for Group later
    },
    { skip: (isEdgeAdminUser || isEdgeReceptionist) && selectedPractitioner === undefined }
  );

  const {
    data: assignmentPackageList,
    isLoading: isAssignmentPackageListLoading,
    isFetching: isAssignmentPackageListFetching
  } = useGetPackageBookableAppointmentQuery(
    {
      accountId: accountId,
      clientRecordId: selectedClient?._id || '',
      params: {
        eventOwnerId: selectedPractitioner?._id || ''
      },
      filterByParticipantType: ParticipantType.OneToOne
    },
    { skip: !isPackageEnabled || !selectedClient }
  );

  const clientOptions: IOptionItem[] = useMemo(() => {
    return clientMinifiedListData
      ? clientMinifiedListData.clientRecords.map((item) => ({
          value: item._id,
          label: `${item.clientProfiles[0].firstName} ${item.clientProfiles[0].lastName}`
        }))
      : [];
  }, [clientMinifiedListData]);

  const packageAppointmentTypeOptions: IOptionItem[] = useMemo(() => {
    return assignmentPackageList
      ? [
          ...assignmentPackageList.map((packageObj) => ({
            value: packageObj.assignee._id || '',
            label: `${packageObj.name} - ${packageObj.assignee.funder.name}`,
            itemFlag: ITEM_FLAG,
            subMenu: packageObj.appointmentTypes.map((appTypeObj) => ({
              value: appTypeObj.packageAppointmentId || '',
              label: appTypeObj.name,
              disabled: appTypeObj.isDone,
              ...(appTypeObj.isDone && {
                subLabel: <div className={styles.doneBadge}>Done</div>
              })
            }))
          }))
        ]
      : [];
  }, [assignmentPackageList]);

  const appointmentTypeOptions: IOptionItem[] = useMemo(() => {
    return appointmentTypesData
      ? appointmentTypesData.data.map((item) => ({
          value: item._id || '',
          label: item.name,
          ...(!isAppointmentRateReadDenied && {
            subLabel: `${config.currencySymbol}${item.rate.amount}`
          })
        }))
      : [];
  }, [appointmentTypesData, isAppointmentRateReadDenied]);

  const deliveryModeOptions: IOptionItem[] = useMemo(() => {
    const foundAppointmentType =
      selectedAppointmentType && appointmentTypesData?.data.find((item) => item._id === selectedAppointmentType._id);

    return (
      foundAppointmentType?.deliveryOptions.map((deliveryType) => ({
        value: deliveryType,
        label: getDeliveryTypeLabel(deliveryType, foundAppointmentType.otherInstructions)
      })) || []
    );
  }, [selectedAppointmentType, appointmentTypesData]);

  const massagePractitionerList: CalendarFilterInterface[] = useMemo(() => {
    let practiceDataMassage;
    if (isEdgeUserView || isNormalUserView) {
      practiceDataMassage = {
        _id: clinicianProfile?._id || '',
        name: clinicianProfile?.name || '',
        avatar: clinicianProfile?.avatar || '',
        mobileNumber: clinicianProfile?.mobileNumber || '',
        workingSchedule: clinicianProfile?.workingSchedule,
        workTimeZone: clinicianProfile?.workTimeZone
      };
    } else {
      practiceDataMassage = {
        _id: '',
        name: clinicianProfile?.practice?.name || '',
        avatar: clinicianProfile?.practice?.logo || '',
        mobileNumber: clinicianProfile?.practice?.mobileNumber || ''
      };
    }
    return [practiceDataMassage, ...(practitionerOptions?.practitionerList || [])];
  }, [practitionerOptions, clinicianProfile, isEdgeUserView, isNormalUserView]);

  const formattedPractitionerOptions = useMemo(() => {
    return massagePractitionerList
      ? massagePractitionerList.map((practitioner) => ({
          value: practitioner._id,
          label: practitioner.name
        }))
      : [];
  }, [massagePractitionerList]);

  const debounceSetClientSearchText = useMemo(
    () =>
      debounce((value) => {
        setClientSearchText(value);
        setClientListCurrentPage(1);
      }, 1000),
    []
  );

  const onSelectPractitioner = (id: string) => {
    const selectedPractitioner = massagePractitionerList?.find((item) => item._id === id);
    setClientListCurrentPage(1);
    clientSearchText && setClientSearchText('');
    dispatch(setSelectedPractitioner(selectedPractitioner));
    dispatch(setSelectedClient(undefined));
    dispatch(setSelectedAppointmentType(undefined));
    dispatch(setSelectedDeliveryMode(undefined));
    dispatch(setCurrentStep(AppointmentHumanFactorStep.Client));

    if (selectedPractitioner && !!rememberSelectedPractitioner) {
      const newPractitionerFilter: CalendarFilterInterface = {
        _id: selectedPractitioner._id,
        name: selectedPractitioner.name,
        avatar: selectedPractitioner.avatar,
        isClinician: true,
        workingSchedule: selectedPractitioner.workingSchedule,
        color: getRandomColor()
      };
      const isPractitionerExistInFilters = selectedClinicians.some(
        (selectedClinicianFilter) => selectedClinicianFilter._id === newPractitionerFilter._id
      );
      if (!isPractitionerExistInFilters) {
        const newSelectedFilters = [...selectedClinicians, newPractitionerFilter];
        dispatch(setSelectedClinicians(newSelectedFilters));
        setSelectedFiltersToLocalStorage({
          id: clinicianProfile ? clinicianProfile._id : '',
          isEdgeAdminView,
          selectedFilters: newSelectedFilters,
          selectedRooms,
          selectedHighLights
        });
      }
    }
  };

  const onSelectClient = (id: string) => {
    const selectedClient = clientMinifiedListData?.clientRecords.find((item) => item._id === id);
    dispatch(setSelectedClient(selectedClient));
    if (!selectedAppointmentType) {
      dispatch(setCurrentStep(AppointmentHumanFactorStep.AppointmentType));
    }
  };

  const onSelectAppointmentType = (id: string, isPackageOption?: string, packageAssigneeId?: string) => {
    const getPackageAppointment = assignmentPackageList?.map((item) => item.appointmentTypes).flat();
    const getPackageAppointmentDetails = getPackageAppointment?.find(
      (appointment) => appointment.packageAppointmentId === id
    );
    const appointmentTypeDetails = appointmentTypesData?.data.find((item) => item._id === id);
    const isPackageItem = isPackageOption === ITEM_FLAG;

    const selectedAppointmentType =
      isPackageEnabled && isPackageItem && assignmentPackageList && assignmentPackageList.length > 0
        ? getPackageAppointmentDetails
        : appointmentTypeDetails;

    dispatch(
      setAppointmentViewFilter({
        ...selectedAppointmentViewFilter,
        times: 'all'
      })
    );
    dispatch(setSelectedAppointmentType(selectedAppointmentType));
    const getDeliveryInstruction =
      selectedAppointmentType && getDeliveryTypeInstruction(selectedDeliveryMode, selectedAppointmentType);
    dispatch(setInstructionNote(getDeliveryInstruction));

    if (isPackageItem) {
      dispatch(
        setRecurring({
          frequency: 0,
          count: 0
        })
      );
    }

    dispatch(
      setIsPackageAppointment({
        isPackageOption: isPackageItem,
        packageId:
          assignmentPackageList?.find((packageObj) => packageObj.assignee._id === packageAssigneeId)?._id || '',
        packageAssigneeId: packageAssigneeId
      })
    );

    if (selectedDeliveryMode) {
      if (!selectedAppointmentType?.deliveryOptions?.includes(selectedDeliveryMode)) {
        dispatch(setSelectedDeliveryMode(undefined));
        dispatch(setCurrentStep(AppointmentHumanFactorStep.DeliveryMode));
      }
    } else {
      dispatch(setCurrentStep(AppointmentHumanFactorStep.DeliveryMode));
    }
    dispatch(setSelectedRoom(DEFAULT_ROOM_OPTION));
    if (selectedAppointmentType) {
      if (selectedAppointmentTypeView !== AppointmentViewType.Customise) {
        dispatch(setSelectedTime({ startTime: '', endTime: '' }));
      } else {
        const newEndTime = selectedTime.startTime
          ? moment(selectedTime.startTime, 'HH:mm')
              .add(selectedAppointmentType?.duration?.minutes, 'minutes')
              .format('HH:mm')
          : selectedTime.endTime;
        dispatch(setSelectedTime({ ...selectedTime, endTime: newEndTime }));
      }
    }
  };

  const onSelectDeliveryMode = (value: DeliveryType) => {
    dispatch(setSelectedDeliveryMode(value));
    dispatch(setCurrentStep(undefined));
    const getDeliveryInstruction =
      selectedAppointmentType && getDeliveryTypeInstruction(value, selectedAppointmentType);
    dispatch(setInstructionNote(getDeliveryInstruction));
  };

  const onUpdateCurrentStep = (step: AppointmentHumanFactorStep) => {
    dispatch(setCurrentStep(step));
  };

  const totalClientListPage = clientMinifiedListData?.paging
    ? Math.ceil(clientMinifiedListData.paging.totalItems / PER_PAGE)
    : 1;

  const loadMoreClientRecord = useCallback(() => {
    const page = clientMinifiedListData?.paging.page || 1;
    if (!isClientMinifiedListDataFetching && page < totalClientListPage) {
      setClientListCurrentPage(page + 1);
    }
  }, [totalClientListPage, isClientMinifiedListDataFetching, clientMinifiedListData]);

  const validateFieldError = useMemo(() => {
    const checkAppointment = oneOnOneAppointmentValidation({
      selectPractitioner: Boolean(selectedPractitioner?._id || selectedPractitioner?.name), // check this 2 because of practice only ''
      selectClient: Boolean(selectedClient?._id),
      selectAppointmentType: Boolean(selectedAppointmentType?.packageAppointmentId || selectedAppointmentType?._id),
      selectDeliveryMode: Boolean(selectedDeliveryMode)
    });

    return checkAppointment.errorMessage;
  }, [selectedPractitioner, selectedClient, selectedAppointmentType, selectedDeliveryMode]);

  return (
    <>
      <div
        className={classnames(styles.container, inlineCalendarValidation && validateFieldError && styles.errorBorder)}
      >
        {(isEdgeAdminView || isEdgeReceptionist) && (
          <div className={styles.dropdownWrapper}>
            <DropdownSearchable
              isActive={currentStep === AppointmentHumanFactorStep.Practitioner}
              onActive={() => onUpdateCurrentStep(AppointmentHumanFactorStep.Practitioner)}
              placeholder="Practitioner Name"
              otherOptionsHeader="Other Calendar"
              searchable
              isSplittedList
              selected={selectedPractitioner?._id}
              options={formattedPractitionerOptions}
              mainOptions={practitionerMainOption ? practitionerMainOptions : undefined}
              isLoading={practitionerOptionsLoading || practitionerOptionsFetching}
              onSelect={onSelectPractitioner}
              error={
                inlineCalendarValidation && !selectedPractitioner?._id && !selectedPractitioner?.name
                  ? 'Please select practitioner'
                  : ''
              }
              hideErrorDesc
            />
          </div>
        )}
        <div className={styles.dropdownWrapper}>
          <DropdownSearchable
            isActive={currentStep === AppointmentHumanFactorStep.Client}
            onActive={() => onUpdateCurrentStep(AppointmentHumanFactorStep.Client)}
            placeholder="Client Name"
            otherOptionsHeader="Other Calendar"
            searchable
            searchText={clientSearchText}
            isSplittedList
            selected={selectedClient?._id}
            options={
              showSelectedItemOnFirstOption && selectedClient
                ? [
                    {
                      value: selectedClient._id,
                      label: `${selectedClient.clientProfiles[0].firstName} ${selectedClient.clientProfiles[0].lastName}`
                    },
                    ...clientOptions.filter((clientOptionObj) => clientOptionObj.value !== selectedClient._id)
                  ]
                : clientOptions || []
            }
            disabled={selectedPractitioner === undefined}
            isLoading={
              isClientMinifiedListDataLoading ||
              (isClientMinifiedListDataFetching && clientListCurrentPage === 1 && !clientSearchText)
            }
            onSelect={onSelectClient}
            setSearchText={debounceSetClientSearchText}
            hasMoreData={totalClientListPage > clientListCurrentPage || isClientMinifiedListDataLoading}
            isFetchingMore={isClientMinifiedListDataFetching}
            loadMore={loadMoreClientRecord}
            error={inlineCalendarValidation && !selectedClient?._id ? 'Please select client' : ''}
            hideErrorDesc
          />
        </div>

        <div className={styles.dropdownWrapper}>
          <DropdownSearchable
            isActive={currentStep === AppointmentHumanFactorStep.AppointmentType}
            onActive={() => onUpdateCurrentStep(AppointmentHumanFactorStep.AppointmentType)}
            placeholder="Appointment Type"
            searchable
            otherOptionsHeader="Other Appointment Type"
            selected={
              isPackageEnabled
                ? selectedAppointmentType?.packageAppointmentId || selectedAppointmentType?._id
                : selectedAppointmentType?._id
            }
            mainOptions={packageOnlyAppointment ? undefined : packageAppointmentTypeOptions}
            options={packageOnlyAppointment ? packageAppointmentTypeOptions : appointmentTypeOptions}
            isLoading={
              isPackageEnabled
                ? isAssignmentPackageListLoading ||
                  isAssignmentPackageListFetching ||
                  isAppointmentTypesDataLoading ||
                  isAppointmentTypesDataFetching ||
                  generalSettingsLoading
                : isAppointmentTypesDataLoading || isAppointmentTypesDataFetching
            }
            isSplittedList={isPackageEnabled ? !packageOnlyAppointment : false}
            onSelect={onSelectAppointmentType}
            disabled={!selectedClient}
            error={
              inlineCalendarValidation &&
              !(selectedAppointmentType?.packageAppointmentId || selectedAppointmentType?._id)
                ? 'Please select appointment type'
                : ''
            }
            hideErrorDesc
          />
        </div>
        <div className={styles.dropdownWrapper}>
          <DropdownSearchable
            isActive={currentStep === AppointmentHumanFactorStep.DeliveryMode}
            onActive={() => onUpdateCurrentStep(AppointmentHumanFactorStep.DeliveryMode)}
            placeholder="Delivery Mode"
            selected={selectedDeliveryMode}
            options={deliveryModeOptions}
            disabled={!selectedAppointmentType}
            onSelect={(value) => onSelectDeliveryMode(value as DeliveryType)}
            error={inlineCalendarValidation && !selectedDeliveryMode ? 'Please select delivery mode' : ''}
            hideErrorDesc
          />
        </div>
      </div>
      {inlineCalendarValidation && <div className={styles.errorMessage}>{validateFieldError}</div>}
    </>
  );
};

export default HumanFactors;
