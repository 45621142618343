import { Modal, notification } from 'antd';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { Collaborator, Report } from 'interfaces/Reports/report';
import { debounce } from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  backToDraft,
  checkInEditSession,
  checkOutEditSession,
  deleteReport,
  getReport,
  getReportByAdmin,
  postReport,
  putPublish,
  putReport,
  putUnpublish,
  requestReview,
  submitReportReview
} from 'utils/http/DocumentService/Reports/reports';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { scrollToView } from 'utils/scrollToView';

import LoadingCircle from '../../../components/LoadingCircle/LoadingCircle';
import ReportBuilderPreview from '../components/ReportBuilderPreview/ReportBuilderPreview';
import ReportBuilder from './components/ReportBuilder/ReportBuilder';
import styles from './ReportDetails.module.scss';
import { initReportDataFunc } from './constants';
import { Session } from './interface';
import { ReportTemplate } from 'interfaces/Reports/reportTemplate';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { clientRecordsInterface } from '../../../interfaces/Clients/clientsRecord';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useAppDispatch } from 'redux/hooks';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';
import { useGetSignatureQuery } from 'redux/endpoints/documentServices/report';
import { useGetClientEncryptedDataByClientRecordIdQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { formatContactDetails, getClinicianDetails, mapSignature } from '../helper';
import { useGetAccountInfo } from 'utils/hooks/GetAccountInfo/getAccountInfo';

const initValidation = {
  clientRecord: false,
  reportName: false,
  items: false,
  clinicianSignature: false,
  reviewers: false
};

const ReportDetails = () => {
  const navigate = useNavigate();
  const { REPORTS } = useRoutesGenerator();
  const { token } = useGetAccessToken();
  const location = useLocation();
  const { auth0ClinicianId } = useGetClinicianId();
  const path = useParams() as { reportId: string };
  const { isEdgeAdminView, isEdgeReceptionist, isEdgeUser } = useGetAccountPackageView();
  const isNewReport = location.pathname.includes('newReport');

  const { clinicianProfile } = useGetAccountInfo();
  const clinicianDetails = getClinicianDetails(clinicianProfile);
  const { data: signature, isLoading: isSignatureLoading } = useGetSignatureQuery();
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const queryParam: { reportName?: string; clientRecordId?: string; refetch?: string } = queryString.parse(
    location.search
  );
  const { isRequestApprovalByDefault } = useGetFeatureToggle();

  const [reportData, setReportData] = useState<any>(
    isNewReport
      ? initReportDataFunc({
          reportName: queryParam && queryParam.reportName ? decodeURIComponent(queryParam.reportName) : '',
          isEdgeAdminView,
          approvalRequired: isRequestApprovalByDefault
        })
      : {}
  );
  const [validation, setValidation] = useState(initValidation as any);
  const [saveDraftStatus, setSaveDraftStatus] = useState<'' | 'saving' | 'saved'>('');
  const [draftBtnStatus, setDraftBtnStatus] = useState<'' | 'active' | 'finished'>('');
  const [actionBtnStatus, setActionDraftBtnStatus] = useState<'' | 'active' | 'finished'>('');
  const [reportId, setReportId] = useState(isNewReport ? 'newReport' : path.reportId || '');
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isWaitingForSavingNewReport, setIsWaitingForSavingNewReport] = useState(false);
  const [session, setSession] = useState<Session>();
  const [applyReportTemplateId, setApplyReportTemplateId] = useState<string>('');
  const [publishing, setPublishing] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [statusEditable, setStatusEditable] = useState(true);
  const dispatch = useAppDispatch();

  const { data: clientEncryptDetails, isLoading: isClientEncryptDetailsLoading } =
    useGetClientEncryptedDataByClientRecordIdQuery(
      { clientRecordId: queryParam?.clientRecordId! || reportData?.clientRecord?._id || '' },
      { skip: !queryParam?.clientRecordId && !reportData?.clientRecord?._id }
    );

  const [t] = useTranslation();

  const fetchReportData = useCallback(async () => {
    setLoading(true);
    try {
      if (token) {
        const getReportResponse =
          isEdgeAdminView || isEdgeReceptionist
            ? await getReportByAdmin(token, path.reportId)
            : await getReport(token, path.reportId);
        const reportData = await getReportResponse.json();
        const massageReportData = {
          ...reportData,
          isEditing: true
        };

        if (clientEncryptDetails && path.reportId === reportId) {
          massageReportData.clientRecord = clientEncryptDetails;
        }
        setReportData(massageReportData);
      }
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 404) {
        notification.error({ message: 'Report not found.' });
        navigate(REPORTS.BASE);
      } else {
        notification.error({ message: 'Something went wrong while trying to get this report details data.' });
      }
    } finally {
      setLoading(false);
    }
    setReportId(path.reportId);
  }, [
    REPORTS.BASE,
    clientEncryptDetails,
    isEdgeAdminView,
    isEdgeReceptionist,
    navigate,
    path.reportId,
    token,
    reportId
  ]);

  const refetchReportItems = async () => {
    try {
      if (token) {
        const getReportResponse =
          isEdgeAdminView || isEdgeReceptionist
            ? await getReportByAdmin(token, path.reportId)
            : await getReport(token, path.reportId);
        const newReportData = await getReportResponse.json();
        setReportData({ ...reportData, items: newReportData.items });
      }
      notification.info({ message: 'Report content updated.' });
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get updated report content' });
    }
  };

  const handleSaveReport = async (draftData: any, updateBtnStatus?: boolean) => {
    setSubmitting(true);
    if (updateBtnStatus) {
      setDraftBtnStatus('active');
    }
    if (reportId === 'newReport') {
      try {
        const createdReport = await (await postReport(token, draftData)).json();
        navigate(`${REPORTS.BASE}/${createdReport._id}?refetch=false`);
        setReportId(createdReport._id);
        setReportData(createdReport);
        if (updateBtnStatus) {
          setDraftBtnStatus('finished');
        }
        setDraftBtnStatus('');
        setSubmitting(false);
        setIsWaitingForSavingNewReport(false);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to save your report.' });
        setDraftBtnStatus('');
        setSubmitting(false);
        setIsWaitingForSavingNewReport(false);
      }
    } else {
      try {
        // remove these properties to avoid overwriting
        const massagedDraftData = {
          ...draftData,
          status: undefined,
          reviewers: !draftData.approvalRequired ? [] : undefined,
          review: undefined,
          editedByReviewer: undefined,
          comments: undefined,
          statusHistory: undefined,
          sessionLock: undefined
        };
        const updatedReport = await (await putReport(token, reportId, massagedDraftData)).json();
        if (updateBtnStatus) {
          setDraftBtnStatus('finished');
        }
        setSession({ ...updatedReport.sessionLock.by, isOwner: true });
        setDraftBtnStatus('');
        setSubmitting(false);
      } catch (ex) {
        notification.error({
          message:
            isErrorBentStatusError(ex) && ex.statusCode === 409
              ? 'This report has been published. To continue editing please revert the status back to edit mode'
              : 'Something went wrong while trying to update your report.'
        });
        console.error(ex);
        setDraftBtnStatus('');
        setSubmitting(false);
      }
    }
    setSaveDraftStatus(Object.keys(draftData).length !== 0 ? 'saved' : '');
  };

  const onClickSaveDraft = async () => {
    setReadOnly(true);
    const data = {
      ...reportData,
      lastUpdatedTime: new Date(),
      isEditing: true
    };
    if (reportId === 'newReport') {
      if (!isSubmitting) {
        setReportData(data);
        if (validateField()) {
          await handleSaveReport(reportData, true);
        }
      }
    } else {
      setReportData(data);
      if (validateField()) {
        await handleSaveReport(reportData, true);
      }
    }
    dispatch(documentServicesApiSlice.util.invalidateTags(['Reports']));
    setReadOnly(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(
      async (draftData: Report) => {
        if (
          draftData.reportName.length > 1 &&
          draftData.clientRecord?._id &&
          draftData.isEditing &&
          draftData.items.length > 0
        ) {
          await handleSaveReport(draftData);
        }
        setStatusEditable(true);
      },
      reportId === 'newReport' ? 100 : 3000
    ),
    [reportId, token]
  );

  useEffect(() => {
    if (isNewReport) {
      setLoading(false);
    } else if (queryParam.refetch !== 'false') {
      fetchReportData();
    }
  }, [fetchReportData, isNewReport, queryParam.refetch]);

  useEffect(() => {
    if (isNewReport && reportData.clientRecord.clientProfiles.length < 1) {
      setLoading(true);
      const clinicianSignature = mapSignature(signature);

      const preLoadData = {
        ...reportData,
        ...(clientEncryptDetails && { clientRecord: clientEncryptDetails }),
        ...(clinicianDetails && {
          contactDetails: formatContactDetails(clinicianDetails)
        }),
        ...(clinicianProfile && {
          clinician: clinicianProfile,
          practice: clinicianProfile.practice ? clinicianProfile.practice : {}
        }),
        ...(clinicianSignature && { clinicianSignature }),
        ...(signature?.extraDetails && { clinicianSignatureExtraDetails: signature.extraDetails })
      };

      setReportData(preLoadData);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature, clientEncryptDetails, clinicianProfile, clinicianDetails, isNewReport]);

  const debouncedCheckOutSession = useMemo(() => debounce(() => setSession(undefined), 300000), []);

  useEffect(() => {
    if (session) {
      debouncedCheckOutSession();
    }
  }, [session, debouncedCheckOutSession]);

  const draftData = (itemObj: any) => {
    const data = {
      ...reportData,
      ...itemObj,
      lastUpdatedTime: new Date(),
      isEditing: true
    };

    setReportData(data);

    if (!isSubmitting && saveDraftStatus !== 'saving' && isNewReport) {
      setSaveDraftStatus(Object.keys(reportData).length !== 0 ? 'saving' : '');
      debouncedSave(data);
    } else if (!isNewReport) {
      setSaveDraftStatus(Object.keys(reportData).length !== 0 ? 'saving' : '');
      debouncedSave(data);
    }
    setTimeout(() => {
      setSaveDraftStatus('');
    }, 2000);
  };

  const onChangeReportName = (nameVal: string) => {
    const data = {
      reportName: nameVal
    };
    draftData(data);
    setValidation({
      ...validation,
      reportName: !(nameVal.length > 0)
    });
  };

  const onChangeApprovalRequired = (bool: boolean) => {
    if (isEdgeUser) {
      const data = {
        approvalRequired: bool
      };
      draftData(data);
    }
  };

  const onSelectClient = (clientVal: clientRecordsInterface, cleanData?: boolean) => {
    const data = {
      clientRecord: clientVal,
      items: cleanData ? [] : reportData.items
    };
    draftData(data);
    setValidation({
      ...validation,
      clientRecord: !(clientVal._id.length > 0)
    });
  };

  const onSelectEpisode = (episodeId: string) => {
    const data = {
      episodeId
    };
    draftData(data);
  };

  const onChangeSignature = (signVal: any, extraDetailsVal: any) => {
    const data = {
      clinicianSignature: signVal,
      clinicianSignatureExtraDetails: extraDetailsVal
    };
    draftData(data);
    setValidation({
      ...validation,
      clinicianSignature: !Object.keys(signVal).length
    });
  };

  const onChangeContactDetails = (contactDetailsVal: any) => {
    const data = {
      contactDetails: contactDetailsVal
    };
    draftData(data);
  };

  const onChangeDndHeight = ({ height, width }: any) => {
    // this condition is for preventing trigger PUT report, once report is open
    if (!(reportData.template.dimensions.height === height && reportData.template.dimensions.width === width)) {
      draftData({
        template: {
          dimensions: {
            height: height,
            width: width
          }
        }
      });
    }
  };

  const onChangeItems = (itemsVal: any) => {
    setStatusEditable(false);
    const data = {
      items: itemsVal
    };
    draftData(data);
    setValidation({
      ...validation,
      items: !(itemsVal.length > 0)
    });
  };

  const onHandleReportTemplate = (newTemplateItems: ReportTemplate['items'], applyTemplateId: string) => {
    const newGroupItems = [...reportData.items, ...newTemplateItems];

    const data = {
      items: newGroupItems
    };

    draftData(data);
    setApplyReportTemplateId(applyTemplateId);
    if (reportId === 'newReport') {
      setIsWaitingForSavingNewReport(true);
    }
    setValidation({
      ...validation,
      items: !(newGroupItems.length > 0)
    });
  };

  const validateField = () => {
    if (!reportData.reportName) {
      notification.error({
        message: 'Please enter name of report'
      });
      setValidation({
        ...validation,
        reportName: true
      });
      scrollToView('nameOfReport');
      return false;
    }

    if (!Object.keys(reportData.clientRecord || {}).length) {
      notification.error({
        message: t('label.please_select_client')
      });
      setValidation({
        ...validation,
        clientRecord: true
      });
      scrollToView('selectClientModal');
      return false;
    }

    if (!reportData.clinicianSignature.type) {
      notification.error({
        message: 'Please sign the signature'
      });
      setValidation({
        ...validation,
        clinicianSignature: true
      });
      scrollToView('signatureSection');
      return false;
    }

    if (reportData.items.length < 1) {
      notification.error({
        message: 'Please add item to report'
      });
      setValidation({
        ...validation,
        items: true
      });
      scrollToView('dndContentSection');
      return false;
    }

    scrollToView('reportView', true);
    return true;
  };

  const onChangeSession = async (bool: boolean) => {
    try {
      if (bool) {
        const [newSession] = await Promise.all([
          (await checkInEditSession(token, reportId)).json(),
          refetchReportItems()
        ]);
        setSession({ ...newSession.by, isOwner: true });
      } else {
        await checkOutEditSession(token, reportId);
        setSession(undefined);
      }
      dispatch(documentServicesApiSlice.util.invalidateTags(['Reports']));
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 423) {
        const newSession = (await ex.json()) as { by: Collaborator };
        setSession({ ...newSession.by, isOwner: false });
        notification.warning({
          message: `Someone else has checked-in the report.`,
          duration: 2,
          closeIcon: <span className="notify">OK</span>
        });
      } else {
        notification.error({
          message: `Something went wrong while trying to check-${bool ? 'in' : 'out'} the report.`
        });
        console.error(ex);
        fetchReportData();
      }
    }
  };

  const onPublish = async () => {
    if (validateField()) {
      setPublishing(true);
      setActionDraftBtnStatus('active');
      if (!reportData.approvalRequired) {
        await handleSaveReport(reportData);
      }
      try {
        await putPublish(token, reportId);
        dispatch(documentServicesApiSlice.util.invalidateTags(['Reports']));
        setActionDraftBtnStatus('finished');
        notification.success({
          message: 'Report has been published.',
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });
      } catch (ex) {
        if (isErrorBentStatusError(ex) && ex.statusCode === 409) {
          notification.warning({
            message: 'Report status out of sync, refreshing page.',
            duration: 2,
            closeIcon: <span className="notify">OK</span>
          });
        } else {
          notification.error({ message: 'Something went wrong while trying to publish your report.' });
          console.error(ex);
        }
      }
      setActionDraftBtnStatus('');
      fetchReportData();
      setPublishing(false);
    }
  };

  const onRequestReview = async (reviewers: Collaborator[]) => {
    if (reviewers.length > 0 && validateField()) {
      setActionDraftBtnStatus('active');
      await handleSaveReport(reportData);
      try {
        await requestReview(token, reportId, reviewers);
        dispatch(documentServicesApiSlice.util.invalidateTags(['Reports']));
        setActionDraftBtnStatus('finished');
        notification.success({
          message: 'Report has been shared for review.',
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to share for review.' });
        console.error(ex);
      }
      setActionDraftBtnStatus('');
      fetchReportData();
    } else {
      setValidation({
        ...validation,
        reviewers: true
      });
    }
  };

  const onUnpublish = async () => {
    try {
      await putUnpublish(token, reportId, isEdgeAdminView);
      dispatch(documentServicesApiSlice.util.invalidateTags(['Reports']));
      notification.success({
        message: 'Report has been unpublished.',
        duration: 5,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 409) {
        notification.warning({
          message: 'Report status out of sync, refreshing page.',
          duration: 2,
          closeIcon: <span className="notify">OK</span>
        });
      } else {
        notification.error({ message: 'Something went wrong while trying to unpublish the report.' });
        console.error(ex);
      }
    }
    if (reportData.clinician._id === auth0ClinicianId) {
      fetchReportData();
    } else {
      navigate(REPORTS.BASE);
    }
  };

  const onDelete = async () => {
    try {
      await deleteReport(token, reportId);
      dispatch(documentServicesApiSlice.util.invalidateTags(['Reports']));
      notification.success({
        message: 'Report has been deleted.',
        duration: 5,
        closeIcon: <span className="success">OK</span>
      });
      navigate(REPORTS.BASE);
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 400) {
        notification.warning({
          message: 'Unable to delete published report.',
          duration: 2,
          closeIcon: <span className="notify">OK</span>
        });
      } else {
        notification.error({ message: 'Something went wrong while trying to delete the report.' });
        console.error(ex);
      }
      fetchReportData();
    }
  };

  const onBackToEditMode = async () => {
    try {
      await backToDraft(token, reportId);
      dispatch(documentServicesApiSlice.util.invalidateTags(['Reports']));
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to back to editing mode. Please try again.'
      });
      console.error(ex);
    }
    fetchReportData();
  };

  const onSubmitReview = async ({ reviewType, message }: { reviewType: string; message?: string }) => {
    setActionDraftBtnStatus('active');
    try {
      await submitReportReview(token, reportId, { reviewType, message });
      dispatch(documentServicesApiSlice.util.invalidateTags(['Reports']));
      setActionDraftBtnStatus('finished');
      notification.success({
        message: 'Review submitted.',
        duration: 5,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 409) {
        notification.warning({
          message: 'Report status out of sync, refreshing page.',
          duration: 2,
          closeIcon: <span className="notify">OK</span>
        });
      } else {
        notification.error({ message: 'Something went wrong while trying to submit your review.' });
        console.error(ex);
      }
    }
    setActionDraftBtnStatus('');
    fetchReportData();
  };

  return (
    <HelmetWrapper title={'Tacklit - Report & Letter Builder'}>
      <ContentLayout withMaxWidth className={styles.container}>
        <div id={'reportView'} />
        {isLoading || isSignatureLoading ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : !isPreviewMode ? (
          <>
            <DndProvider backend={HTML5Backend}>
              <ReportBuilder
                statusEditable={statusEditable}
                readOnly={readOnly}
                onSelectedClient={onSelectClient}
                onSelectEpisode={onSelectEpisode}
                isClientsLoading={isClientEncryptDetailsLoading}
                isSignatureLoading={isSignatureLoading}
                clinicianDetails={clinicianDetails}
                onChangeReportName={onChangeReportName}
                data={reportData}
                onChangeSignature={onChangeSignature}
                onChangeContactDetails={onChangeContactDetails}
                onChangeItems={onChangeItems}
                onChangeDndHeight={onChangeDndHeight}
                saveDraftStatus={saveDraftStatus}
                draftBtnStatus={draftBtnStatus}
                actionBtnStatus={actionBtnStatus}
                onClickSaveDraft={onClickSaveDraft}
                validation={validation}
                session={session}
                preloadTemplate={isNewReport && reportData?.items?.length === 0}
                onChangeSession={onChangeSession}
                onChangeApprovalRequired={onChangeApprovalRequired}
                onPublish={onPublish}
                onRequestReview={onRequestReview}
                onUnpublish={onUnpublish}
                onDelete={onDelete}
                onBackToEditMode={onBackToEditMode}
                onSubmitReview={onSubmitReview}
                onSelectReportTemplate={onHandleReportTemplate}
                autoAlignTemplateId={isWaitingForSavingNewReport ? '' : applyReportTemplateId}
                generalPractitionerId={clientEncryptDetails?.referral?.generalPractitionerId}
                onRefetchReportData={fetchReportData}
              />
            </DndProvider>
            <Modal
              centered
              className={styles.publishing}
              closable={false}
              footer={null}
              keyboard={false}
              maskClosable={false}
              open={publishing}
            >
              Publishing report...
            </Modal>
          </>
        ) : (
          <ReportBuilderPreview
            data={reportData}
            reportId={reportId}
            title={'Report & Letter Builder'}
            token={token}
            backBtnLabel={'Back to editing mode'}
            clinicianDetails={clinicianDetails}
            onBackToEditingMode={() => setIsPreviewMode(false)}
          />
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ReportDetails;
