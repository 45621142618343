import { useState, useEffect, useRef } from 'react';
import styles from './HighLightTypeSelect.module.scss';
import classNames from 'classnames';

export interface OptionInterface {
  value: string;
  label: string;
}

export const FILTER_OPTIONS: OptionInterface[] = [
  { label: 'All', value: 'all' },
  { label: 'Appointment', value: 'appointment' },
  { label: 'Appointment Type', value: 'appointmentType' },
  { label: 'Delivery Mode', value: 'deliveryMode' },
  { label: 'Status', value: 'status' }
];

export interface HighLightTypeSelectProps {
  selectedOption: OptionInterface;
  className?: string;
  onSelect: (selected: OptionInterface) => void;
}

const HighLightTypeSelect = ({ selectedOption, className, onSelect }: HighLightTypeSelectProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const controlNode = useRef<HTMLDivElement>(null);
  const optionsNode = useRef<HTMLDivElement>(null);

  const handleClickOutSide = (event: any) => {
    if (controlNode.current?.contains(event.target)) {
      setShowOptions(!showOptions);
    } else if (!optionsNode.current?.contains(event.target) && showOptions) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const onSelectOption = (selected: OptionInterface) => {
    onSelect(selected);
    setShowOptions(false);
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div ref={controlNode} className={styles.controller}>
        <div className={styles.selectedValue}>{selectedOption.label}</div>
        <div className={styles.controlIcon}>
          <span className={'material-icons-outlined'}>arrow_drop_down</span>
        </div>
      </div>
      {showOptions && (
        <div className={styles.options} ref={optionsNode}>
          {FILTER_OPTIONS.map((optionItem, index) => {
            return (
              <div
                key={`optionItem_${index}`}
                className={classNames(
                  styles.optionItem,
                  selectedOption.value === optionItem.value ? styles.selected : ''
                )}
                onClick={() => onSelectOption(optionItem)}
              >
                <div className={styles.itemName}>{optionItem.label}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default HighLightTypeSelect;
