import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { GeneralPractitionerInterface } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/GPDetails/interface';
import { getMedicareProfilesFromClientRecord } from 'utils/helpers/getMedicareProfilesFromClientRecord';

export const getClaimInvoiceDetailsInfo = (
  clientRecord?: clientRecordsInterface,
  generalPractitioner?: GeneralPractitionerInterface
) => {
  const { clientProfile, parentMedicareProfile: parentProfile } = clientRecord
    ? getMedicareProfilesFromClientRecord(clientRecord)
    : { clientProfile: undefined, parentMedicareProfile: undefined };
  const isClientMedicareSet = !!(
    clientProfile &&
    clientProfile?.medicare &&
    ((clientProfile.medicare.number && clientProfile.medicare.irn) || clientProfile.medicare.dva)
  );
  const isMedicareClient =
    isClientMedicareSet &&
    !!clientProfile.medicare?.status?.medicare &&
    [0, 9626].includes(clientProfile.medicare.status.medicare.code);
  const isDvaClient = isClientMedicareSet && clientProfile.medicare?.status?.dva?.code === 0;

  const isGPReferralProviderNumberSet = !!generalPractitioner?.medicareProviderNumber;
  const isReferralDateSet = !!clientRecord?.referral?.date;

  return {
    clientProfile,
    parentProfile,
    isClientMedicareSet,
    isMedicareClient,
    isDvaClient,
    isGPReferralProviderNumberSet,
    isReferralDateSet
  };
};
