import { useContext, useEffect, useRef } from 'react';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { combineName, filterByMainProfileName, getName } from 'utils/general';
import { faIdBadge } from '@fortawesome/free-solid-svg-icons';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';

import styles from './ClientRecordList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from 'utils/UserContextProvider';

interface ClientRecordListProps {
  clientRecords: clientRecordsInterface[];
  handleSelectClientRecord: (selectedRecord?: clientRecordsInterface) => void;
  selectedClientRecordId?: string;
  onClientListLoading?: (isLoading: boolean) => void;
  isLoading: boolean;
  searchValue: string;
  className?: string;
  disabled?: boolean;
  hideAvatar?: boolean;
}

const ClientRecordList = ({
  clientRecords,
  handleSelectClientRecord,
  selectedClientRecordId,
  onClientListLoading,
  isLoading,
  searchValue,
  className,
  disabled,
  hideAvatar
}: ClientRecordListProps) => {
  const availabilityListRef = useRef<HTMLDivElement>(null);
  const { clinicianProfile } = useContext(UserContext);
  useEffect(() => {
    onClientListLoading && onClientListLoading(isLoading);

    if (
      selectedClientRecordId &&
      !isLoading &&
      clientRecords &&
      clientRecords.length > 0 &&
      clientRecords.find((item) => item._id === selectedClientRecordId)
    ) {
      // Scroll to selected item
      const selectedClientElement = document.getElementById('selectedClient');
      selectedClientElement?.scrollIntoView({ block: 'nearest' });
    }
  }, [clientRecords, isLoading, selectedClientRecordId, onClientListLoading]);

  const searchClientList = clientRecords.filter(filterByMainProfileName(searchValue));

  return (
    <>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          {[...Array(6)].map((obj, i) => (
            <div key={i} className={styles.loadingBox}>
              {!hideAvatar && <Skeleton.Avatar active />}
              <Skeleton.Input active className={!hideAvatar ? styles.loadingName : styles.fullLoadingName} />
            </div>
          ))}
        </div>
      ) : (
        <div className={classNames(styles.container, className)}>
          {searchClientList.length > 0 ? (
            <div ref={availabilityListRef}>
              {searchClientList.map((clientObj, index) => {
                const primaryProfile = clientObj.clientProfiles[0];
                const recordId = clientObj._id;

                const clientProfileType = clientObj.recordType;

                if (!primaryProfile) {
                  return null;
                }

                return (
                  <div
                    id={selectedClientRecordId === recordId ? 'selectedClient' : ''}
                    onClick={() => !disabled && handleSelectClientRecord(clientObj)}
                    className={classNames(styles.clientBox, {
                      [styles.active]: selectedClientRecordId === recordId,
                      [styles.disabled]: disabled
                    })}
                    key={index}
                  >
                    <div className={styles.clientDetailWrapper}>
                      {!hideAvatar && (
                        <ClientAvatar
                          name={getName(primaryProfile)}
                          clientData={clientObj.clientProfiles}
                          initialsName={primaryProfile?.initials || ''}
                          avatarUrl={primaryProfile?.avatar}
                          avatarSize={36}
                          initialsClassName={styles.initialName}
                          nameClassName={styles.clientName}
                          hideName
                          horizontal
                          displayFirstNameOnly={clientProfileType === 'couple'}
                          displayLimit={clientProfileType === 'couple' ? 2 : 1}
                        />
                      )}
                      <div className={classNames(styles.clientName, styles.clientInfo)}>
                        {combineName(clientObj.clientProfiles, clientProfileType === 'couple')}
                        {clientObj.clinicians && clientObj.clinicians?.length > 0 ? (
                          <div className={styles.practitionerInfo}>
                            <FontAwesomeIcon icon={faIdBadge} className={styles.practitionerIcon} />
                            <div className={styles.practitionerName}>{clientObj.clinicians[0].name}</div>
                          </div>
                        ) : (
                          <div className={styles.practitionerInfo}>
                            <div className={styles.practitionerName}>{clinicianProfile?.practice?.name}</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.tid}>{clientObj.tacklitId}</div>
                  </div>
                );
              })}
            </div>
          ) : searchValue.length > 0 && searchClientList.length === 0 ? (
            <div className={styles.noItems}>
              <i className="material-icons-outlined">search_off</i>
              No matching records found
            </div>
          ) : (
            <div className={styles.noFoundLabel}>No client records found</div>
          )}
        </div>
      )}
    </>
  );
};

export default ClientRecordList;
