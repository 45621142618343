import { useEffect, useRef, useState } from 'react';
import { notification } from 'antd';
import {
  filterParameter,
  PractitionersListing,
  PractitionersListingCount
} from 'interfaces/Practitioners/practitionersListing';
import {
  getPractitionerListing,
  getPractitionerListingCount
} from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { PractitionerTabId } from '../PractitionersListing';
import { useTranslation } from 'react-i18next';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

export const useFetchPractitioner = (token: string, filterValue: filterParameter, disabled?: boolean) => {
  const { accountId } = useGetAccountId();
  const [t] = useTranslation();

  const [practitionersList, setPractitionersList] = useState<PractitionersListing>({
    practitionerList: [],
    paging: {
      perPage: 0,
      page: 0,
      totalPage: 0,
      totalItem: 0
    }
  });
  const [isPractitionersListLoading, setIsPractitionersListLoading] = useState(false);

  const qParamRef = useRef('');

  const fetchPractitionersList = async (token: string, filterValue: filterParameter) => {
    const newQParam = queryString.stringify(filterValue);

    if (newQParam !== qParamRef.current) {
      try {
        const practitionersData = await getPractitionerListing(token, accountId, newQParam);
        const practitionerListData = await practitionersData.json();

        const massageData = {
          ...practitionerListData,
          paging: {
            ...practitionerListData.paging,
            page: filterValue.page,
            perPage: filterValue.perPage,
            totalPage: Math.ceil(practitionerListData.paging.totalItem / filterValue.perPage)
          }
        };

        setPractitionersList(massageData as PractitionersListing);
      } catch (ex) {
        notification.error({ message: t('form.error.failed_to_retrieve_practitioner_record') });
      }
    }

    qParamRef.current = newQParam;
    setIsPractitionersListLoading(false);
  };

  useEffect(() => {
    if (token && !disabled) {
      fetchPractitionersList(token, filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, disabled]);

  return { practitionersList, isPractitionersListLoading, fetchPractitionersList, setPractitionersList };
};

export const useFetchPractitionerListCount = (token: string, filterValue: filterParameter) => {
  const { accountId } = useGetAccountId();
  const [t] = useTranslation();
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();

  const [practitionersCount, setPractitionersCount] = useState<PractitionersListingCount>(
    {} as PractitionersListingCount
  );
  const [isPractitionersCountLoading, setIsPractitionersCountLoading] = useState(true);

  const fetchPractitionersCount = async (token: string, filterValue: filterParameter) => {
    setIsPractitionersCountLoading(true);
    try {
      const qParam = `q=${filterValue.q}`;

      const practitionersCountData = await getPractitionerListingCount(token, accountId, qParam);
      const practitionerListCountData = await practitionersCountData.json();

      setPractitionersCount(practitionerListCountData as PractitionersListingCount);
      setIsPractitionersCountLoading(false);
    } catch (ex) {
      notification.error({ message: t('form.error.failed_to_retrieve_practitioner_count') });
      setIsPractitionersCountLoading(false);
    }
  };

  useEffect(() => {
    if (token && (isEdgeAdminView || isEdgeReceptionistView)) {
      fetchPractitionersCount(token, filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isEdgeAdminView]);

  return { practitionersCount, isPractitionersCountLoading, fetchPractitionersCount, setPractitionersCount };
};

export const useGetPractitionerFilterValue = () => {
  const location = useLocation();
  const queryParam: { q?: string; page?: string; status?: PractitionerTabId } = queryString.parse(location.search);
  const tabId: PractitionerTabId = queryParam.status ?? PractitionerTabId.Active;
  const [filterType, setFilterType] = useState<PractitionerTabId>(tabId);
  const filterValue = {
    status: filterType,
    perPage: 10,
    page: queryParam.page || 1,
    q: queryParam.q || ''
  };

  return { filterValue, filterType, setFilterType, tabId, queryParam };
};
