import { ColumnSettingsEnum } from 'utils/providers/ClientListColumnsProvider/constants';

export type ClientListColumnsDropdownOption = {
  section?: string;
  label?: string;
  value?: ColumnSettingsEnum;
  disabled?: boolean;
};

export const initialClientListColumnsDropdownOptions: ClientListColumnsDropdownOption[] = [
  {
    section: 'PROFILE INFO'
  },
  {
    label: 'Client Since',
    value: ColumnSettingsEnum.CLIENT_SINCE,
    disabled: false
  },
  {
    label: 'Profile Type',
    value: ColumnSettingsEnum.PROFILE_TYPE,
    disabled: false
  },
  {
    label: 'Practitioner Assigned',
    value: ColumnSettingsEnum.PRACTITIONER,
    disabled: false
  },
  {
    label: 'Group Membership',
    value: ColumnSettingsEnum.GROUP,
    disabled: false
  },
  {
    label: 'Last Activity',
    value: ColumnSettingsEnum.LAST_ACTIVITY,
    disabled: false
  },
  {
    section: 'APPOINTMENT INFO'
  },
  {
    label: 'Next Appointment',
    value: ColumnSettingsEnum.NEXT_APPOINTMENT,
    disabled: false
  },
  {
    label: 'Count of Appointments Attended',
    value: ColumnSettingsEnum.APPOINTMENT_ATTENDED,
    disabled: false
  },
  {
    label: 'Count of Future Appointments scheduled',
    value: ColumnSettingsEnum.FUTURE_APPOINTMENTS,
    disabled: false
  },
  {
    section: 'FORMS DATA'
  },
  {
    label: 'Last Consent Signed',
    value: ColumnSettingsEnum.LAST_CONSENT_SIGNED,
    disabled: false
  },
  {
    label: 'Last Psychometric Completed',
    value: ColumnSettingsEnum.LAST_PSYCHOMETRIC_COMPLETED,
    disabled: false
  },
  {
    label: 'Last Survey Completed',
    value: ColumnSettingsEnum.LAST_SURVEY_COMPLETED,
    disabled: false
  },
  {
    section: 'REFERRAL DETAIL'
  },
  {
    label: 'Referral Date',
    value: ColumnSettingsEnum.REFERRAL_DATE,
    disabled: false
  },
  {
    label: 'Referral Sources and Details',
    value: ColumnSettingsEnum.REFERRAL_SOURCE_AND_DETAIL,
    disabled: false
  },
  {
    label: 'Referral Statuses',
    value: ColumnSettingsEnum.REFERRAL_STATUSES,
    disabled: false
  }
];
