import { activityFeedServicesApiSlice, AFSTagTypes } from 'redux/services/activityFeedServicesApiSlice';
import queryString from 'query-string';
import { PatientActivity } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsActivity/components/ActivityFeed/ActivityFeed';

interface GetPatientsActivityQuery {
  clientRecordId: string;
  lastCreatedAt?: string;
  pageSize?: number;
  filter?: string;
  action?: string;
}

export const patientActivityApiSlice = activityFeedServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientActivities: builder.query<PatientActivity[], GetPatientsActivityQuery>({
      query: (params) => {
        const stringifyQuery = params ? queryString.stringify(params) : '';
        return {
          url: `/activities${stringifyQuery ? `?${stringifyQuery}` : ''}`
        };
      },
      providesTags: [AFSTagTypes.PatientsActivities]
    })
  })
});

export const { useGetPatientActivitiesQuery } = patientActivityApiSlice;
