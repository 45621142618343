import { InvoiceSettings, PaymentMethod } from '../../interface';

import InvoiceSettingsModal from './components/InvoiceSettingsModal/InvoiceSettingsModal';

import styles from './InvoiceSettings.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface InvoiceSettingsProps {
  invoiceSettings?: InvoiceSettings;
  paymentMethods: PaymentMethod[];
  isInvoiceSettingsLoading: boolean;
  isInvoiceSettingsModalVisible: boolean;
  isPaymentMethodsLoading: boolean;
  onAddPaymentMethodClick: () => void;
  setIsInvoiceSettingsModalVisible: (isInvoiceSettingsModalVisible: boolean) => void;
}

const InvoiceSettingsComponent = ({
  invoiceSettings,
  paymentMethods,
  isInvoiceSettingsLoading,
  isInvoiceSettingsModalVisible,
  isPaymentMethodsLoading,
  onAddPaymentMethodClick,
  setIsInvoiceSettingsModalVisible
}: InvoiceSettingsProps) => {
  const handleManageInvoiceSettingsClick = () => {
    setIsInvoiceSettingsModalVisible(true);
  };

  const handleInvoiceSettingsModalClose = () => {
    setIsInvoiceSettingsModalVisible(false);
  };

  return (
    <>
      <InvoiceSettingsModal
        invoiceSettings={invoiceSettings}
        paymentMethods={paymentMethods}
        isInvoiceSettingsLoading={isInvoiceSettingsLoading}
        isPaymentMethodsLoading={isPaymentMethodsLoading}
        visible={isInvoiceSettingsModalVisible}
        onAddPaymentMethodClick={onAddPaymentMethodClick}
        onClose={handleInvoiceSettingsModalClose}
      />
      <div className={styles.container}>
        <div className={styles.title}>Invoice settings</div>
        <div className={styles.description}>Control all the default controls</div>
        <ButtonAlt
          className={styles.button}
          fullWidth
          variant={'outlined'}
          icon={'settings'}
          onClick={handleManageInvoiceSettingsClick}
        >
          Manage account level defaults
        </ButtonAlt>
      </div>
    </>
  );
};

export default InvoiceSettingsComponent;
