import { Option, Question, QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';

export enum ScaleType {
  AgreeDisagree = 'agreeDisagree',
  Satisfaction = 'satisfaction',
  Likelihood = 'likelihood',
  GoodBadRating = 'goodBadRating',
  Frequency = 'frequency',
  Priority = 'priority',
  ImpactOfChange = 'impactOfChange',
  Recency = 'recency',
  _1_5 = '_1_5',
  _1_10 = '_1_10',
  EmojiRating = 'emojiRating',
  EmojiReaction = 'emojiReaction'
}

export const ScaleTypeLabel = {
  [ScaleType.AgreeDisagree]: 'Agree - Disagree',
  [ScaleType.Satisfaction]: 'Satisfaction',
  [ScaleType.Likelihood]: 'Likelihood',
  [ScaleType.GoodBadRating]: 'Good - Bad Rating',
  [ScaleType.Frequency]: 'Frequency',
  [ScaleType.Priority]: 'Priority',
  [ScaleType.ImpactOfChange]: 'Impact of Change',
  [ScaleType.Recency]: 'Recency',
  [ScaleType._1_5]: '1-5 Rating scale',
  [ScaleType._1_10]: '1-10 Rating scale',
  [ScaleType.EmojiRating]: 'Emoji Rating',
  [ScaleType.EmojiReaction]: 'Emoji Reaction'
};

export enum ClassificationTypes {
  ProfileData = 'profileData',
  HealthData = 'healthData'
}

export const CLASSIFICATION_OPTIONS = [
  { label: 'Profile Data', value: ClassificationTypes.ProfileData },
  { label: 'Health Data', value: ClassificationTypes.HealthData }
];

export interface OpenDataFieldVariable extends Question {
  variableName: string;
  variableId: string;
  stem: string;
  options?: Option[];
  scaleType?: ScaleType;
  isCommonVariable?: boolean;
  type: QuestionType;
}

export interface OpenDataCategory {
  name: string;
  _id?: string;
  classification?: ClassificationTypes;
  questions: Partial<OpenDataFieldVariable>[];
  defaultCategoryId?: string;
  createdAt?: string;
  updatedAt?: string;
  order: number;
}

// variable IDs that sync to client record when CDF data is updated
export const CLIENT_RECORD_SYNC_VARIABLE_IDS = [
  'PI001',
  'PI002',
  'PI003',
  'PI004',
  'PI005',
  'PI006',
  'PI007',
  'RI001',
  'RI002',
  'RI003',
  'GPI001',
  'GPI002',
  'GPI003',
  'GPI004',
  'GPI005',
  'GPI006',
  'GPI007',
  'ID2',
  'ID3',
  'ADI001',
  'ADI002',
  'ADI003',
  'ADI004',
  'ADI005',
  'ADI006'
];
