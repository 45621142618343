import moment from 'moment';
import ReportDraftContent from 'pages/Report/components/ReportBuilderPreview/components/ReportDraftContent/ReportDraftContent';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { Collaborator, Report, ReportAccessor } from 'interfaces/Reports/report';
import { timeDiff } from 'utils/timeDifferent';
import { CommentWidgetInterface, Session } from '../../interface';
import ReportContent from './components/ReportContent/ReportContent';
import WidgetSideBar from './components/WidgetSideBar/WidgetSideBar';
import styles from './ReportBuilder.module.scss';
import { ReportTemplate } from 'interfaces/Reports/reportTemplate';
import ModalV2 from 'components/ModalV2/ModalV2';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import ShareReportModal from 'pages/Report/components/ShareReportModal/ShareReportModal';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetClinicianProfileQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import ReportContentAsPDF from 'pages/Report/components/ReportContentAsPDF/ReportContentAsPDF';
import { generatePrintableReportPayload, triggerDownload } from 'pages/Report/utils';
import { downloadReportPdf } from 'utils/http/DocumentService/Reports/reports';
import { useGetAccessToken } from 'utils/hooks/token';
import { notification } from 'antd';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useTime } from 'pages/Report/hooks/useTime';
import { SubTabContext } from 'layouts/AuthLayoutT23/context/SubTabContextProvider';
import classnames from 'classnames';
import { useAppDispatch } from '../../../../../redux/hooks';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';

export interface ReportBuilderProps {
  readOnly: boolean;
  statusEditable: boolean;
  isClientsLoading: boolean;
  isSignatureLoading: boolean;
  clinicianDetails: any;
  onChangeSignature: (
    signatureVal: { drawVal: string; textVal: string; type: string },
    extraDetailsVal: {
      email: string;
      mobileNumber: string;
      name: string;
      other: string;
    }
  ) => void;
  onChangeContactDetails: any;
  onChangeItems: (items: any[]) => void;
  onChangeDndHeight: any;
  onSelectedClient: (findClient: Report['clientRecord'], cleanData: undefined | boolean) => void;
  onChangeReportName: (value: string) => void;
  saveDraftStatus: string;
  draftBtnStatus: '' | 'active' | 'finished';
  actionBtnStatus: '' | 'active' | 'finished';
  onClickSaveDraft: any;
  data: Report;
  validation: any;
  session?: Session;
  preloadTemplate: boolean;
  onChangeSession: (bool: boolean) => void;
  onChangeApprovalRequired: (bool: boolean) => void;
  onPublish: () => void;
  onRequestReview: (reviewers: Collaborator[]) => void;
  onUnpublish: () => void;
  onDelete: () => void;
  onBackToEditMode: () => void;
  onSubmitReview: ({ reviewType, message }: { reviewType: string; message?: string }) => void;
  onSelectReportTemplate: (val: ReportTemplate['items'], applyTemplateId: string) => void;
  autoAlignTemplateId: string;
  onRefetchReportData: () => void;
  generalPractitionerId?: string;
  onSelectEpisode: (episodeId: string) => void;
}

const ReportBuilder = ({
  readOnly,
  statusEditable,
  isClientsLoading,
  isSignatureLoading,
  clinicianDetails,
  onChangeSignature,
  onChangeContactDetails,
  onChangeItems,
  onChangeDndHeight,
  onSelectedClient,
  onChangeReportName,
  saveDraftStatus,
  draftBtnStatus,
  actionBtnStatus,
  onClickSaveDraft,
  data,
  validation,
  session,
  preloadTemplate,
  onChangeSession,
  onChangeApprovalRequired,
  onPublish,
  onRequestReview,
  onUnpublish,
  onDelete,
  onBackToEditMode,
  onSubmitReview,
  onSelectReportTemplate,
  autoAlignTemplateId,
  generalPractitionerId,
  onRefetchReportData,
  onSelectEpisode
}: ReportBuilderProps) => {
  const dispatch = useAppDispatch();
  const { showSubTab } = useContext(SubTabContext);
  const navigate = useNavigate();
  const { token } = useGetAccessToken();
  const { REPORTS } = useRoutesGenerator();
  const { auth0ClinicianId } = useGetClinicianId();
  const { faxEnable } = useGetFeatureToggle();
  const { reportId } = useParams() as { reportId: string };
  const { accountId } = useGetAccountId();
  const isAuthor = (data.clinician && data.clinician._id === auth0ClinicianId) ?? false;

  const [isSelectedClient, setIsSelectedClient] = useState<Report['clientRecord']>({} as Report['clientRecord']);
  const [nameOfReport, setNameOfReport] = useState(data.reportName);
  const [commentMode, setCommentMode] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [downloadPDFButtonStatus, setDownloadPDFButtonStatus] = useState<ButtonStatusType>('');
  const reportRef = useRef(null);

  const { data: clinicianProfile } = useGetClinicianProfileQuery();

  const massageCommentData =
    (data.comments
      ?.map((comment) => ({
        ...comment,
        rightAligned: comment.position.left > 840 - 350,
        expand: false
      }))
      .sort((a, b) =>
        moment(b.comments[0].commentedAt).diff(moment(a.comments[0].commentedAt))
      ) as CommentWidgetInterface[]) ?? [];

  const [comments, setComments] = useState<CommentWidgetInterface[]>(massageCommentData);

  const [sharedList, setSharedList] = useState<ReportAccessor[]>(
    data.shareDetails?.accessors?.sort((_, b) => (b.isClient ? 1 : -1)) ?? []
  );

  useEffect(() => {
    setIsSelectedClient(data.clientRecord || {});
    setNameOfReport(data.reportName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.clientRecord]);

  useEffect(() => {
    if (isAuthor) {
      setPreviewMode(data.status !== 'draft');
    } else {
      setPreviewMode(data.status !== 'inReview' ? true : !session?.isOwner ?? true);
    }
  }, [session, isAuthor, data.status]);

  const now = useTime(saveDraftStatus === 'saving' ? 1000 : 10000, data.lastUpdatedTime);
  const currentTime = moment();
  const formattedDuration = timeDiff(currentTime, now);

  const changeReportName = (e: ChangeEvent<HTMLInputElement>) => {
    setNameOfReport(e.target.value);
  };

  const handleChangeReportName = () => {
    onChangeReportName(nameOfReport);
  };

  const onSelectClient = (val: clientRecordsInterface, cleanData?: boolean) => {
    setIsSelectedClient(val!);
    if (val) {
      onSelectedClient(val, cleanData);
      dispatch(
        clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.EpisodeList, CPSTagTypes.EpisodeDetails])
      );
    }
  };

  const handleChangeCommentMode = (bool: boolean) => setCommentMode(bool);

  const onChangeComments = (comments: CommentWidgetInterface[]) => {
    setComments(
      comments.sort((a, b) =>
        moment(b.comments[0]?.commentedAt ?? moment()).diff(moment(a.comments[0]?.commentedAt) ?? moment())
      )
    );
  };

  const onChangeSharedList = (newSharedList: ReportAccessor[]) => {
    setSharedList(newSharedList);
  };

  const handleExpandFromSide = (id: string) => {
    const newExpandComment = comments.map((commentObj) => ({
      ...commentObj,
      from: commentObj._id === id && commentObj.from !== 'side' ? 'side' : 'widget',
      expand: commentObj._id === id && commentObj.from !== 'side' ? !commentObj.expand : false
    }));
    setComments(newExpandComment as CommentWidgetInterface[]);
  };

  const handleBackToReportListing = () => {
    navigate(REPORTS.BASE);
  };

  const downloadPdf = async () => {
    setDownloadPDFButtonStatus('active');
    try {
      const retrievedPDF = await downloadReportPdf(
        accountId,
        token,
        reportId,
        !data.attachment
          ? {
              printPayload: generatePrintableReportPayload(reportRef.current) || ''
            }
          : undefined
      );
      if (retrievedPDF) {
        triggerDownload(retrievedPDF, `Report_${reportId}`);
      }
      setDownloadPDFButtonStatus('finished');
      // Refetch report details for attachment checking
      !data.attachment && onRefetchReportData();
      notification.success({
        closeIcon: <span className="success">OK</span>,
        duration: 2,
        message: 'Download report as PDF successful.'
      });
    } catch (ex) {
      notification.error({
        closeIcon: <span className="success">OK</span>,
        duration: 2,
        message: 'Fail to download report as PDF.'
      });
    } finally {
      setTimeout(() => {
        setDownloadPDFButtonStatus('');
      }, 1000);
    }
  };

  return (
    <div className={styles.container}>
      {isClientsLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <>
          <div className={classnames(styles.leftSection, showSubTab && styles.leftSectionWithSubTabOpened)}>
            <div className={styles.titleSection}>
              <div className={styles.title}>Report & Letter Builder</div>
              <ButtonAlt variant={'text'} size={'medium'} icon={'arrow_back_ios'} onClick={handleBackToReportListing}>
                Back to report listing
              </ButtonAlt>
            </div>
            <WidgetSideBar
              statusEditable={statusEditable}
              readOnly={readOnly}
              nameOfReport={nameOfReport}
              data={data}
              selectedClient={isSelectedClient}
              onSelectClient={onSelectClient}
              changeReportName={changeReportName}
              handleChangeReportName={handleChangeReportName}
              clientValidation={validation.client}
              reportNameValidation={validation.reportName}
              reviewersValidation={validation.reviewers}
              actionBtnStatus={actionBtnStatus}
              previewMode={previewMode}
              preloadTemplate={preloadTemplate}
              handleChangeCommentMode={handleChangeCommentMode}
              comments={comments}
              handleExpandComment={handleExpandFromSide}
              sharedList={sharedList}
              onChangeSharedList={onChangeSharedList}
              isAuthor={isAuthor}
              session={session}
              onChangeApprovalRequired={onChangeApprovalRequired}
              onChangeSession={onChangeSession}
              onPublish={onPublish}
              onRequestReview={onRequestReview}
              onUnpublish={onUnpublish}
              onDelete={onDelete}
              onBackToEditMode={onBackToEditMode}
              onSubmitReview={onSubmitReview}
              onSelectReportTemplate={onSelectReportTemplate}
              onSelectEpisode={onSelectEpisode}
            />
          </div>
          <div className={classnames(styles.rightSection, showSubTab && styles.rightSectionWithSubTabOpened)}>
            <div className={styles.templateHeader}>
              {!previewMode ? (
                <div className={styles.rightHeadingWrapper}>
                  <div className={styles.buttonsWrapper}>
                    <ButtonAlt
                      variant={'outlined'}
                      onClick={onClickSaveDraft}
                      status={draftBtnStatus}
                      disabled={readOnly}
                    >
                      Save Changes
                    </ButtonAlt>
                    <ButtonAlt onClick={() => setShowPreviewModal(true)}>View Preview</ButtonAlt>
                  </div>
                  {data.isEditing && (
                    <div className={styles.lastEditMessage}>{`Last edit was ${formattedDuration}`}</div>
                  )}
                </div>
              ) : (
                <>
                  <ButtonAlt
                    variant="outlined"
                    icon="file_download"
                    onClick={downloadPdf}
                    className={styles.downloadButton}
                    status={downloadPDFButtonStatus}
                    loadingWord="Preparing PDF"
                  >
                    Download as PDF
                  </ButtonAlt>
                  {clinicianProfile && data && (
                    <ReportContentAsPDF
                      type="report"
                      reportDetails={data}
                      practice={clinicianProfile?.practice}
                      ref={reportRef}
                    />
                  )}
                  {faxEnable && (
                    <>
                      <ButtonAlt variant="outlined" icon="ios_share" onClick={() => setIsShareModalOpen(true)}>
                        Send eFax
                      </ButtonAlt>
                      <ShareReportModal
                        type="report"
                        generalPractitionerId={generalPractitionerId}
                        isModalOpen={isShareModalOpen}
                        reportId={reportId}
                        reportName={data.reportName}
                        closeModal={() => setIsShareModalOpen(false)}
                        reportData={data as Report}
                        onRefetchReportData={onRefetchReportData}
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <ReportContent
              readOnly={readOnly}
              data={data}
              reportName={nameOfReport}
              isSignatureLoading={isSignatureLoading}
              clinicianDetails={clinicianDetails}
              onChangeSignature={onChangeSignature}
              onChangeContactDetails={onChangeContactDetails}
              onChangeItems={onChangeItems}
              onChangeDndHeight={onChangeDndHeight}
              signatureValidation={validation.clinicianSignature}
              itemValidation={validation.items}
              previewMode={previewMode}
              commentMode={commentMode}
              comments={comments}
              onChangeComments={onChangeComments}
              autoAlignTemplateId={autoAlignTemplateId}
            />
          </div>
          <ModalV2
            containerClassName={styles.previewContainer}
            isModalOpen={showPreviewModal}
            title={'Report Preview'}
            onModalClose={() => setShowPreviewModal(false)}
            bodyStyle={{ padding: '0' }}
          >
            <ReportDraftContent data={data} clinicianDetails={clinicianDetails} />
          </ModalV2>
        </>
      )}
    </div>
  );
};

export default ReportBuilder;
