import { notification } from 'antd';
import { config } from 'config/config';
import { CommunicationPreference, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { debounce } from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useSearch } from 'utils/hooks/useSearch';
import {
  getClientsRecord,
  getClientsRecordByAccountId
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { security } from 'utils/security';

// TODO: Refactor this hook
export const useFetchBookablePatients = ({
  communicationPreferences,
  isSortAsc,
  signUpOnly
}: {
  communicationPreferences?: CommunicationPreference[];
  isSortAsc?: boolean;
  signUpOnly?: boolean;
}) => {
  const [t] = useTranslation();
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isNormalUserView } = useGetAccountPackageView();

  const [bookablePatients, setBookablePatients] = useState<clientRecordsInterface[]>([]);
  const [hasMoreBookablePatients, setHasMoreBookablePatients] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const [isBookablePatientsLoading, setIsBookablePatientsLoading] = useState(false);
  const [loadingMoreBookablePatients, setLoadingMoreBookablePatients] = useState(false);
  const [sorting, setSorting] = useState(false);

  const [page, setPage] = useState(1);
  const { searchTerm, setSearchTerm, debouncedSearchTerm, searching, setSearching, searchActivated } = useSearch({
    page,
    setPage
  });

  const fetchBookablePatients = useCallback(async () => {
    const recordStatusByType = isNormalUserView ? 'active' : 'active,waitlist';
    try {
      const token = await security.getAccessTokenSilently();

      const filterParam = {
        communicationPreference: communicationPreferences?.join(','),
        hasSignedUp: signUpOnly,
        page,
        perPage: config.perPage,
        q: debouncedSearchTerm,
        recordStatus: recordStatusByType,
        sortByClientName: isSortAsc ? 1 : -1
      };
      const qParam = queryString.stringify(filterParam);
      const qString = qParam ? `?${qParam}` : '';

      const {
        clientRecords,
        paging
      }: { clientRecords: clientRecordsInterface[]; paging: { totalItem: number; perPage: number; page: number } } =
        isEdgeAdminView && accountId
          ? await (await getClientsRecordByAccountId(token, accountId, qString)).json()
          : await (await getClientsRecord(token, qString)).json();

      setHasMoreBookablePatients(paging.totalItem / paging.perPage > paging.page);
      setPage(page + 1);

      return clientRecords.filter((clientRecord) =>
        clientRecord.clientProfiles.some(
          (clientProfile) =>
            (communicationPreferences?.includes(CommunicationPreference.Email) && clientProfile.hasEmail) ||
            (communicationPreferences?.includes(CommunicationPreference.SMS) && clientProfile.hasMobileNumber)
        )
      );
    } catch (ex) {
      console.error(ex);
      notification.error({ message: t('form.error.failed_to_retrieve_client_record') });
      return [];
    }
  }, [
    accountId,
    communicationPreferences,
    debouncedSearchTerm,
    isEdgeAdminView,
    isNormalUserView,
    isSortAsc,
    page,
    signUpOnly,
    t
  ]);

  const fetchAndSetBookablePatients = useCallback(async () => {
    setIsBookablePatientsLoading(true);
    const bookablePatients = await fetchBookablePatients();
    setBookablePatients(bookablePatients);
    setSorting(false);
    setIsBookablePatientsLoading(false);
  }, [fetchBookablePatients]);

  /** Initial fetch */
  useEffect(() => {
    if (!initialised && !isBookablePatientsLoading) {
      setInitialised(true);
      fetchAndSetBookablePatients();
    }
  }, [fetchAndSetBookablePatients, initialised, isBookablePatientsLoading]);

  const loadMoreBookablePatients = async () => {
    if (!loadingMoreBookablePatients && hasMoreBookablePatients) {
      setLoadingMoreBookablePatients(true);
      const bookablePatients = await fetchBookablePatients();
      setBookablePatients((existing) => [...existing, ...bookablePatients]);
      setLoadingMoreBookablePatients(false);
    }
  };

  const searchClients = useMemo(
    () =>
      debounce(async () => {
        setSearching(true);
        const records = await fetchBookablePatients();
        if (records) {
          setBookablePatients(records);
        }
        setSearching(false);
      }, config.debounceDelay),
    [fetchBookablePatients, setSearching]
  );

  useEffect(() => {
    if (page === 1 && !searching && (debouncedSearchTerm || searchActivated)) {
      searchClients();
    }
  }, [page, searchActivated, searchClients, debouncedSearchTerm, searching]);

  const sort = async () => {
    setPage(1);
    setSorting(true);
  };

  useEffect(() => {
    if (!loadingMoreBookablePatients && page === 1 && initialised && sorting) {
      fetchAndSetBookablePatients();
    }
  }, [fetchAndSetBookablePatients, initialised, loadingMoreBookablePatients, page, sorting]);

  return {
    bookablePatients,
    hasMoreBookablePatients,
    isBookablePatientsLoading,
    loadMoreBookablePatients,
    searching,
    searchTerm,
    setBookablePatients,
    setSearchTerm,
    sort
  };
};
