import { useState, useRef } from 'react';
import styles from './DropdownButton.module.scss';
import Dropdown from './Dropdown';
import { MenuItemProps } from './DropdownButton';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

type Event = MouseEvent | TouchEvent;

const MenuItems = ({ items, depthLevel }: { items: MenuItemProps; depthLevel: number }) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef<HTMLLIElement>(null);

  const clickOutsideHandler = (event: Event | undefined) => {
    if (dropdown && ref.current && !ref.current.contains((event?.target as Node) || null)) {
      setDropdown(false);
    }
    return true;
  };

  useOnClickOutside(ref, clickOutsideHandler);

  return (
    <li className={styles.menuItems} ref={ref}>
      {items.submenu ? (
        <>
          <button
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            className={styles.button}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}
            {depthLevel > 0 ? <i className="material-icons">navigate_next</i> : <span className={styles.arrow} />}
          </button>
          <Dropdown depthLevel={depthLevel} submenus={items.submenu} dropdown={dropdown} />
        </>
      ) : (
        <button
          className={styles.label}
          onClick={() => {
            items.onClick && items.onClick();
          }}
        >
          {items.title}
        </button>
      )}
    </li>
  );
};

export default MenuItems;
