import classnames from 'classnames';
import { useState } from 'react';
import { QuestionTypeDisplay } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/components/OpenDataVariable/OpenDataVariable';

import styles from './QuestionListWithCheckBox.module.scss';
import { QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';

export interface QuestionListWithCheckBoxProps {
  id: string;
  label: string;
  groupId: string;
  questionId: string;
  isDraftQ: boolean;
  isChecked: boolean;
  onCheckChange: any;
  disabled?: boolean;
  isDataCollection?: boolean;
  onDeleteQuestion: (groupId: string, questionId: string) => void;
  question: any;
}

const QuestionListWithCheckBox = ({
  id,
  label,
  groupId,
  questionId,
  isDraftQ,
  isChecked,
  onCheckChange,
  disabled,
  isDataCollection,
  onDeleteQuestion,
  question
}: QuestionListWithCheckBoxProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const onChangeCheckValue = (checkBoxVal: boolean) => {
    const value = {
      id: questionId,
      stem: label,
      isChecked: checkBoxVal,
      isDraftQ: isDraftQ
    };

    onCheckChange(groupId, value);
  };

  const onDeleteQuestionHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onDeleteQuestion(groupId, questionId);
  };

  const toggleShowOptions = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setShowOptions(!showOptions);
  };

  return (
    <label className={classnames(styles.container, isChecked ? styles.checked : '')} htmlFor={questionId}>
      <div className={styles.questionBox}>
        <input
          className={styles.checkbox}
          id={questionId}
          type={'checkbox'}
          name={groupId}
          checked={isChecked}
          onChange={(e) => onChangeCheckValue(e.target.checked)}
          disabled={disabled}
        />
        <div>
          <div className={styles.labelWrapper}>
            <div className={styles.label}>{label}</div>
            {!isDataCollection &&
              ['multipleChoice', 'multipleChoiceFreeText', 'selectOther'].includes(question?.type) && (
                <div className={styles.multipleChoiceLabel} onClick={toggleShowOptions}>
                  {question?.type === 'multipleChoice' && 'Multiple Choice'}
                  {question?.type === 'multipleChoiceFreeText' && 'Multiple Choice Free Text'}
                  {question?.type === 'selectOther' && 'Select Other'}
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              )}
          </div>
          {!isDataCollection && showOptions && question?.options && (
            <div className={styles.optionsContainer}>
              {question?.options?.map((option: any) => (
                <div className={styles.option}>{option.description}</div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.actionsContainer}>
        {!isDataCollection ? (
          <>
            <div role="button" onClick={onDeleteQuestionHandler}>
              <i className="material-icons">clear</i>
            </div>
            <i className={`material-icons ${styles.arrowIcon}`}>reorder</i>
          </>
        ) : (
          <div className={styles.answerFormat}>
            {question?.type === QuestionType.multipleChoice ? (
              <div>
                <div className={styles.multipleChoiceLabel} onClick={toggleShowOptions}>
                  {'Answer format: Multiple Choice'}
                  <i className="material-icons">arrow_drop_down</i>
                </div>
                {showOptions && question?.options && (
                  <div className={styles.optionsContainer}>
                    {question?.options?.map((option: any) => (
                      <div className={styles.option}>{option.description}</div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              `Answer format: ${question?.type ? QuestionTypeDisplay[question.type as QuestionType] : ''}`
            )}
          </div>
        )}
      </div>
    </label>
  );
};

export default QuestionListWithCheckBox;
