import { GroupListFilters, GroupListPaging, GroupListSearch, GroupListSorting } from 'redux/groups/groupSlice';

export enum ColumnSettingsEnum {
  GROUP_NAME = 'groupName',
  CLIENT_SINCE = 'clientSince',
  GROUP_MEMBER = 'groupMember'
}

export enum SearchFilterEnum {
  GroupName = 'groupName'
}

export interface SortBy {
  groupName?: number;
  clientSince?: number;
  groupMember?: number;
}

export interface GetGroupRecordListingQuery {
  page: number;
  perPage: number;

  searchValue?: string;
  searchBy?: SearchFilterEnum;

  sortByGroupName?: 1 | -1;
  sortByClientSince?: 1 | -1;
  sortByGroupMember?: 1 | -1;
}

export interface GroupRecordListRequestPayload {
  accountId: string;
  isAdmin: boolean;
  params?: GetGroupRecordListingQuery;
}

export enum SortType {
  ASC = 1,
  DESC = -1
}

interface GetPayloadParam {
  paging: GroupListPaging;
  sorting: GroupListSorting;
  filters: GroupListFilters;
  search: GroupListSearch;
}

export const GROUP_LIST_SORT_INFO = 'group-list-sort-info';

const getSortByQuery = (sortBy: GroupListSorting['sortBy']) => {
  switch (sortBy) {
    case ColumnSettingsEnum.GROUP_NAME:
      return 'sortByGroupName';
    case ColumnSettingsEnum.CLIENT_SINCE:
      return 'sortByClientSince';
    case ColumnSettingsEnum.GROUP_MEMBER:
      return 'sortByGroupMember';
    default:
      return '';
  }
};

export const getGroupRecordRequestPayload = (params: GetPayloadParam): GetGroupRecordListingQuery => {
  const { paging, sorting, filters, search } = params;
  const { page, perPage } = paging;
  const { sortBy, sortType } = sorting;
  const { searchValue, searchBy } = search;
  const { selectedGroupLeads, status } = filters;
  const sortByKey = sortBy && getSortByQuery(sortBy);

  return {
    page,
    perPage,
    ...(sortByKey && {
      [sortByKey]: sortType === SortType.ASC ? 1 : -1
    }),

    ...(selectedGroupLeads.length > 0 && {
      groupLeadIds: selectedGroupLeads.map(({ _id }) => _id).join(',')
    }),

    ...(search.searchValue && {
      searchValue,
      searchBy
    }),

    ...(status && {
      status
    })
  };
};
