import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './SubTabWrapper.module.scss';
import MenuTooltip from '../MenuTooltip/MenuTooltip';
import ShareableLinkButton from 'components/ShareableLinkButton/ShareableLinkButton';

const SubTabWrapper = ({
  showSubTab = true,
  setShowSubTab,
  className,
  title,
  showExpandIcon = true,
  children,
  shareableLink
}: {
  children: ReactNode;
  showSubTab?: boolean;
  setShowSubTab?: (v: boolean) => void;
  className?: string;
  title?: string | JSX.Element;
  showExpandIcon?: boolean;
  shareableLink?: {
    componentId: string;
    url?: string;
    tooltip: string;
  };
}) => (
  <div
    className={classNames(styles.subTab, className, !showSubTab && styles.closed)}
    onClick={() => (!showSubTab ? setShowSubTab?.(true) : null)}
  >
    <div className={showSubTab ? styles.displayMenu : styles.hideMenu}>
      <div className={classNames(styles.header, !showExpandIcon && styles.noIcon)}>
        <div className={styles.headerTitle}>
          {title}{' '}
          {shareableLink && (
            <ShareableLinkButton
              id={shareableLink.componentId}
              url={shareableLink.url}
              tooltip={shareableLink.tooltip}
            />
          )}
        </div>
        <MenuTooltip title="CLOSE" pillShape>
          <div className={styles.icon} onClick={() => setShowSubTab?.(false)}>
            <i className="material-icons-outlined">keyboard_double_arrow_left</i>
          </div>
        </MenuTooltip>
      </div>
      <div className={styles.body}>
        <div className={styles.bodyContent}>{children}</div>
      </div>
    </div>
    <div className={!showSubTab ? styles.displayMenu : styles.hideMenu}>
      <div className={styles.iconExpand}>
        <MenuTooltip title="OPEN" pillShape>
          <i className={'material-icons-outlined'} onClick={() => setShowSubTab?.(true)}>
            keyboard_double_arrow_right
          </i>
        </MenuTooltip>
      </div>
    </div>
  </div>
);

export default SubTabWrapper;
