import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { PER_PAGE_DEFAULT_10, Paging, SortType, Sorting, TasksFilters } from '../utils';

interface ClosedTasksSliceState {
  filters: TasksFilters;
  sorting: Sorting;
  paging: Paging;
}

const initialState: ClosedTasksSliceState = {
  filters: {
    clients: []
  },
  sorting: {
    sortBy: 'created',
    sortType: SortType.DESC
  },
  paging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT_10
  }
};

export const closedTasksSlice = createSlice({
  name: 'closedTasksSlice',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    },

    setPaging: (state, action) => {
      state.paging = action.payload;
    }
  }
});

export const selectFilters = (state: RootState) => state.closedTasksSlice.filters;
export const selectSorting = (state: RootState) => state.closedTasksSlice.sorting;
export const selectPaging = (state: RootState) => state.closedTasksSlice.paging;

export const { setFilters, resetFilters, setSorting, setPaging, resetPaging } = closedTasksSlice.actions;

export default closedTasksSlice.reducer;
