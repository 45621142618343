import { useMemo } from 'react';
import { IOptionItem, SubMenuItem } from '../../components/v2/DropdownSearchable/OptionItem';

interface SelectedMenu {
  menu: IOptionItem;
  subMenu?: SubMenuItem;
  menuList: IOptionItem[];
}

export const useGetSelectedOption = (
  options: IOptionItem[],
  selected: string | string[] | undefined,
  mainOptions?: IOptionItem[]
) =>
  useMemo<SelectedMenu>(() => {
    let menu: IOptionItem = { label: '', value: '' };
    let subMenu: SubMenuItem = { label: '', value: '' };

    if (mainOptions && mainOptions?.length > 0) {
      mainOptions?.forEach((item) => {
        if (item.value === selected || selected?.includes(item.value)) {
          menu = item;
        } else if (item?.subMenu) {
          item.subMenu.forEach((subItem) => {
            if (subItem.value === selected || selected?.includes(subItem.value)) {
              menu = item;
              subMenu = subItem;
            }
          });
        }
      });

      options.forEach((item) => {
        if (item.value === selected || selected?.includes(item.value)) {
          menu = item;
        } else if (item?.subMenu) {
          item.subMenu.forEach((subItem) => {
            if (subItem.value === selected || selected?.includes(subItem.value)) {
              menu = item;
              subMenu = subItem;
            }
          });
        }
      });
    } else {
      options.forEach((item) => {
        if (item.value === selected || selected?.includes(item.value)) {
          menu = item;
        } else if (item?.subMenu) {
          item.subMenu.forEach((subItem) => {
            if (subItem.value === selected || selected?.includes(subItem.value)) {
              menu = item;
              subMenu = subItem;
            }
          });
        }
      });
    }

    const getMainOption = mainOptions?.filter(
      (optionObj) =>
        selected?.includes(optionObj.value) || optionObj.subMenu?.some((menuObj) => selected?.includes(menuObj.value))
    );

    const getOption = options.filter(
      (optionObj) =>
        selected?.includes(optionObj.value) || optionObj.subMenu?.some((menuObj) => selected?.includes(menuObj.value))
    );

    const menuList =
      getMainOption && getMainOption.length > 0
        ? getMainOption.filter(
            (optionObj) =>
              selected?.includes(optionObj.value) ||
              optionObj.subMenu?.some((menuObj) => selected?.includes(menuObj.value))
          )
        : getOption;

    return { menu, subMenu, menuList };
  }, [options, selected, mainOptions]);
