import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { Invoice } from 'pages/Invoices/interface';
import {
  Claim,
  ClaimStatus,
  ClaimType
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { GeneralPractitionerInterface } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/GPDetails/interface';

export const ClaimStatusFilter = {
  Completed: ClaimStatus.Complete,
  Success: 'Success',
  Pended: ClaimStatus.MedicarePended,
  Pendable: ClaimStatus.MedicarePendable,
  Rejected: 'Rejected',
  Expired: ClaimStatus.Expired,
  Processed: ClaimStatus.Processed,
  WriteOff: ClaimStatus.WriteOff
};

export const ClaimTypeNameMapping = {
  DVA: 'DVA',
  BulkBill: 'Medicare BB',
  Medicare: 'OOP Rebate'
};

export const ClaimTypeFilterNameMapping = {
  DVA: 'DVA',
  BulkBill: 'Medicare - BulkBill',
  Medicare: 'Out Of Pocket - Rebate'
};

export const ClaimTypeFilters: FilterCheckListItem[] = Object.values(ClaimType).map((item) => ({
  _id: item,
  name: ClaimTypeFilterNameMapping[item]
}));

export const SubmittedClaimStatusFilters: FilterCheckListItem[] = Object.keys(ClaimStatusFilter).map((item) => ({
  _id: item,
  name: item
}));

export const SubmittedClaimStatuses = [
  ClaimStatus.MedicarePendable,
  ClaimStatus.MedicarePended,
  ClaimStatus.Expired,
  ClaimStatus.Processed
];
export const SuccessClaimStatuses = [ClaimStatus.MedicareAssessed, ClaimStatus.WithMedicare, ClaimStatus.Complete];
export const RejectedClaimStatuses = [ClaimStatus.MedicareRejected, ClaimStatus.Rejected];
export const WriteOffClaimStatuses = [ClaimStatus.WriteOff];

export const SubmittedStatusFilters: FilterCheckListItem[] = Object.keys({
  Expired: ClaimStatus.Expired,
  Pended: ClaimStatus.MedicarePended,
  Pendable: ClaimStatus.MedicarePendable,
  Processed: ClaimStatus.Processed
}).map((item) => ({
  _id: item,
  name: item
}));

export const SuccessStatusFilters: FilterCheckListItem[] = Object.keys({
  Completed: ClaimStatus.Complete,
  Success: 'Success'
}).map((item) => ({
  _id: item,
  name: item
}));

export interface GetSubmittedClaimListQuery {
  invoiceIds?: string;
  clientRecordIds?: string;
  clinicianAuth0Ids?: string;
  groupIds?: string;
  claimType?: ClaimType;
  mbsCode?: string;
  status?: string; // comma separated ClaimStatus
  claimedAfter?: string; // YYYY-MM-DD
  claimedBefore?: string; // YYYY-MM-DD
  asUser?: string;
  sortByClaimDate?: '1' | '-1';
  sortByClaimStatus?: '1' | '-1';
  page?: number;
  perPage?: number;
  abandoned?: 1 | 0;
}

export interface GetSubmittedClaimListRequestPayload {
  accountId: string;
  params: GetSubmittedClaimListQuery;
}

export interface SubmittedClaim extends Claim {
  foundClientRecord?: clientRecordsInterface;
  foundClinician?: ProfileInterface;
  foundGeneralPractitioner?: GeneralPractitionerInterface;
  foundInvoice?: Invoice;
  groups: { _id: string; name: string }[];
  allowResubmitClaim: boolean;
}

export interface SubmittedClaimListResponse {
  claims: SubmittedClaim[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export const FilterStatusAndClaimStatusMapping = {
  Expired: ClaimStatus.Expired,
  Pended: ClaimStatus.MedicarePended,
  Pendable: ClaimStatus.MedicarePendable,
  Processed: ClaimStatus.Processed,
  Completed: ClaimStatus.Complete
};
