import { createSlice } from '@reduxjs/toolkit';
import { LetterStatus } from 'interfaces/Letters/letter';
import { RootState } from 'redux/store';

export interface LetterPaging {
  page: number;
  perPage: number;
}

interface LetterState {
  paging: LetterPaging;
  filters: {
    status?: LetterStatus | 'allStatus';
  };
  search: string;
}

const initialState: LetterState = {
  paging: {
    page: 1,
    perPage: 10
  },
  filters: {
    status: 'allStatus'
  },
  search: ''
};

export const letterSlice = createSlice({
  name: 'letterSlice',
  initialState,
  reducers: {
    setPaging: (state, action) => {
      state.paging = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    setSearch: (state, action) => {
      state.search = action.payload;
    },

    resetAll: (state) => {
      state.paging = initialState.paging;
      state.filters = initialState.filters;
      state.search = initialState.search;
    }
  }
});

export const selectPaging = (state: RootState) => state.letterSlice.paging;
export const selectFilters = (state: RootState) => state.letterSlice.filters;
export const selectSearch = (state: RootState) => state.letterSlice.search;

export const { setPaging, resetPaging, setFilters, resetFilters, setSearch, resetAll } = letterSlice.actions;

export default letterSlice.reducer;
