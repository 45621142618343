import { useEffect, useState } from 'react';
import { RoomType } from 'interfaces/Schedule/Room';
import { getRoom } from 'utils/http/ScheduleService/Rooms/rooms';
import { security } from 'utils/security';

export const useFetchRoom = (roomId: string) => {
  const [room, setRoom] = useState<RoomType>();
  const [isRoomLoading, setIsRoomLoading] = useState(true);

  const fetchRoom = async () => {
    setIsRoomLoading(true);
    if (roomId) {
      const token = await security.getAccessTokenSilently();

      const response = await getRoom(token, roomId);
      const data = await response.json();

      setRoom(data);
    } else {
      setRoom(undefined);
    }

    setIsRoomLoading(false);
  };

  useEffect(() => {
    fetchRoom();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  return { room, isRoomLoading };
};
