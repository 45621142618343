import styles from './InvoiceListHeader.module.scss';
import columnStyles from './InvoiceListColumn.module.scss';
import ListHeaderItem from 'components/T23/ListHeaderItem/ListHeaderItem';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getColumnNameFromId } from 'components/T23/ListHeaderItem/helper';
import {
  SortType,
  selectFilters,
  selectPaging,
  selectSorting,
  setPaging,
  setSorting
} from 'redux/invoices/invoiceSlice';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useMemo } from 'react';
import { config } from 'config/config';

interface InvoiceListHeaderProps {
  showInvoiceType?: boolean;
}

export enum InvoiceColumnSortable {
  INVOICE_ID = 'invoiceId',
  DUE_DATE = 'dueDate',
  CREATED_AT = 'createdAt',
  TOTAL = 'total',
  PAID = 'paid',
  OWED = 'owed'
}

const InvoiceListHeader = ({ showInvoiceType }: InvoiceListHeaderProps) => {
  const dispatch = useAppDispatch();
  const sorting = useAppSelector(selectSorting);
  const filters = useAppSelector(selectFilters);
  const paging = useAppSelector(selectPaging);
  const { participantType, createdAt } = filters;
  const { sortBy, sortType } = sorting;
  const isFilteredByDateRange = !!(createdAt.from && createdAt.to);

  const onClickSort = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      dispatch(setSorting({ ...sorting, sortType: sortType === SortType.ASC ? SortType.DESC : SortType.ASC }));
    } else {
      dispatch(setSorting({ ...sorting, sortBy: newSortBy, sortType: SortType.DESC }));
    }
    dispatch(setPaging({ ...paging, page: 1 }));
  };

  const invoiceHeaders = useMemo(() => {
    return [
      {
        id: 'invoiceId',
        sortAble: true
      },
      // {
      //   id: 'issue',
      //   label: 'Issue?',
      //   sortAble: false
      // },
      ...(showInvoiceType
        ? [
            {
              id: 'type',
              sortAble: false,
              label: 'Payer'
            }
          ]
        : []),
      {
        id: 'client',
        label: participantType === ParticipantType.Group ? 'Name' : 'Client',
        sortAble: false
      },
      {
        id: 'createdAt',
        label: 'Generated',
        sortAble: true
      },
      {
        id: 'dueDate',
        sortAble: true
      },
      {
        id: 'status',
        sortAble: false
      },
      {
        id: 'total',
        label: `${config.currencySymbol} Total`,
        sortAble: true
      },
      {
        id: 'paid',
        label: `${config.currencySymbol} Paid`,
        sortAble: isFilteredByDateRange
      },
      {
        id: 'owed',
        label: `${config.currencySymbol} Owed`,
        sortAble: isFilteredByDateRange
      }
    ];
  }, [participantType, showInvoiceType, isFilteredByDateRange]);

  return (
    <div className={styles.container}>
      {invoiceHeaders.map((header, index) => (
        <ListHeaderItem
          key={index}
          columnId={header.id}
          className={columnStyles[header.id]}
          sortBy={sortBy}
          sortType={sortType}
          columnName={header.label || getColumnNameFromId(header.id)}
          sortAble={header.sortAble}
          onSort={onClickSort}
        />
      ))}
      <div className={columnStyles.actions} />
    </div>
  );
};

export default InvoiceListHeader;
