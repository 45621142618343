import { notification } from 'antd';
import { config } from 'config/config';
import { Formik, FormikHelpers } from 'formik';
import { PracticeInterface } from 'interfaces/Profile/Profile';
import { useState } from 'react';
import * as yup from 'yup';
import { omitObject } from 'utils/objects/omitObject';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import Button from 'components/Button/Button';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import PracticeItem from './components/PracticeItem/PracticeItem';

import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './ProfilePractice.module.scss';
import { useAppSelector } from 'redux/hooks';
import { practiceInfo, defaultPracticeData } from 'redux/slice/practiceDataSlice';
import {
  useDeletePracticeInfoMutation,
  useUpdatePracticeInfoMutation
} from 'redux/endpoints/clinicianProfileServices/clinicianProfile';

const { mobileNumberMinLength } = config;

const practiceSchema = yup
  .object()
  .shape({
    locations: yup.array().of(yup.string()),
    logo: yup.string(),
    mobileNumber: yup
      .string()
      .required('Please provide a phone number for this practice')
      .min(mobileNumberMinLength, `Mobile number has to be at least ${mobileNumberMinLength} characters`),
    name: yup.string().required('Please provide a name for this practice'),
    registrationNumber: yup.string(),
    financeEmail: yup.string().email('Please enter a valid email address'),
    formalName: yup.string(),
    faxNumber: yup.string()
  })
  .nullable()
  .default(null);

const ProfilePractice = () => {
  const { accountId } = useGetAccountId();
  const { data: practiceData, isLoading: isPracticeDataLoading } = useAppSelector(practiceInfo);
  const [updatePractice] = useUpdatePracticeInfoMutation();
  const [deletePractice] = useDeletePracticeInfoMutation();

  const [activePracticeShowError, setActivePracticeShowError] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(!!practiceData?.name);
  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const handleAddPractice = (setValues: FormikHelpers<PracticeInterface>['setValues']) => {
    setValues(defaultPracticeData);
    setShowForm(true);
  };

  const handleRemovePractice = (setValues: FormikHelpers<PracticeInterface>['setValues']) => {
    setValues(defaultPracticeData);
    setShowForm(false);
  };

  const submitData = async (values: PracticeInterface) => {
    setSaveButtonStatus('active');

    try {
      if (showForm) {
        await updatePractice({ accountId, payload: omitObject(values) }).unwrap();
      } else {
        await deletePractice({ accountId }).unwrap();
      }

      setSaveButtonStatus('finished');

      notification.success({
        message: showForm ? 'Practice details updated' : 'Remove practice details complete',
        closeIcon: <span className="success">OK</span>
      });

      setTimeout(() => setSaveButtonStatus(''), 2000);
    } catch (ex) {
      console.error(ex);

      setSaveButtonStatus('');

      notification.error({ message: 'Something went wrong while trying to update your practices' });
    }
  };

  const handleSubmit = async (values: PracticeInterface) => {
    setActivePracticeShowError(true);
    await submitData(values);
  };

  return isPracticeDataLoading ? (
    <div className={styles.container}>
      <div className={styles.loading}>
        <LoadingDot />
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <Formik
        initialValues={practiceData || defaultPracticeData}
        validationSchema={showForm ? practiceSchema : undefined}
        onSubmit={handleSubmit}
      >
        {({ setValues, submitForm }) => {
          return (
            <>
              <div className={styles.header}>
                <div>
                  <div className={styles.title}>Practice Information</div>
                  <div className={styles.description}>
                    Update your practice information which will be shown in your reports and invoices.
                  </div>
                </div>
                <ButtonAlt onClick={submitForm} id={'savePracticeInformationId'} status={saveButtonStatus}>
                  Save
                </ButtonAlt>
              </div>
              <div className={styles.content}>
                {showForm ? (
                  <PracticeItem
                    originalAvatar={practiceData?.logo}
                    showError={activePracticeShowError}
                    onHideError={() => setActivePracticeShowError(false)}
                    onRemovePractice={() => handleRemovePractice(setValues)}
                  />
                ) : (
                  <Button className={styles.addButton} variant="secondary" onClick={() => handleAddPractice(setValues)}>
                    <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                    Add practice
                  </Button>
                )}
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProfilePractice;
