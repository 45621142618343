import ContentLayout from '../../../../components/ContentLayout/ContentLayout';

import logo from 'assets/images/tacklit-logo-blue.svg';

import styles from './Header.module.scss';

const Header = () => {
  return (
    <div className={styles.container}>
      <ContentLayout>
        <img alt={'tacklit logo'} className={styles.logo} src={logo} />
      </ContentLayout>
    </div>
  );
};

export default Header;
