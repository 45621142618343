import classNames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  deleteOpenDataCategory,
  postOpenDataCategory,
  putOpenDataCategory
} from 'utils/http/CheckInService/OpenDataField/openDataField';
import { ClassificationTypes, OpenDataCategory, OpenDataFieldVariable } from '../../CollectData.interface';
import OpenDataVariable from '../OpenDataVariable/OpenDataVariable';
import VariableForm from '../VariableForm/VariableForm';
import DataCategoryHeader from './components/DataCategoryHeader/DataCategoryHeader';
import styles from './DataCategory.module.scss';
import columnStyles from './VariableListColumn.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

const reorder = (list: Partial<OpenDataFieldVariable>[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DataCategory = ({
  category,
  isAddNew,
  onSubmit,
  onDelete,
  editingCategory,
  setEditingCategory,
  commonVariables,
  scaleTypes,
  isCommonVariablesLoading,
  canReorder,
  dragHandleProps
}: {
  category?: OpenDataCategory;
  isAddNew?: boolean;
  onSubmit: () => void;
  onDelete: (value: OpenDataCategory) => void;
  editingCategory: string;
  setEditingCategory: (id: string) => void;
  commonVariables: OpenDataFieldVariable[];
  scaleTypes: OpenDataFieldVariable[];
  isCommonVariablesLoading: boolean;
  canReorder?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}) => {
  const editing = isAddNew && editingCategory === 'new' ? true : editingCategory === category?._id;
  const [value, setValue] = useState<OpenDataCategory>(
    category ? { ...category, questions: [...(category?.questions || [])] } : { name: '', questions: [], order: 0 }
  );
  const { token } = useGetAccessToken();
  const [editingVariable, setEditingVariable] = useState<string>(isAddNew ? 'new' : '');
  const [deletingVariable, setDeletingVariable] = useState<string>('');
  const [saveBtnStatus, setSaveBtnStatus] = useState<ButtonStatusType>('');
  const [deleteStatus, setDeleteBtnStatus] = useState<ButtonStatusType>('');

  const [showError, setShowError] = useState(false);
  const { isHealthDataEnabled } = useGetFeatureToggle();
  const { accountId } = useGetAccountId();

  const massagePayload = (value: OpenDataCategory) => ({
    ...value,
    classification:
      !value.classification && !isHealthDataEnabled ? ClassificationTypes.ProfileData : value.classification,
    questions: value.questions.map((item) => ({
      ...item,
      isChecked: true,
      id: undefined
    }))
  });

  const handleSubmit = async () => {
    if (!value.name || !(value?.questions?.length > 0) || (isHealthDataEnabled && !value.classification)) {
      setShowError(true);
      return;
    }
    try {
      setSaveBtnStatus('active');
      if (isAddNew) {
        await postOpenDataCategory(token, accountId, massagePayload(value));
      } else {
        if (category?._id) {
          await putOpenDataCategory(token, accountId, category._id, massagePayload(value));
        }
      }
      setSaveBtnStatus('finished');
      setTimeout(() => {
        setSaveBtnStatus('');
        setEditingCategory('');
      }, 1000);
      onSubmit();
      setShowError(false);
    } catch (er) {
      setShowError(false);
      console.error(er);
      setSaveBtnStatus('');
    }
  };

  const handleDelete = async () => {
    try {
      setDeleteBtnStatus('active');
      if (category?._id) {
        await deleteOpenDataCategory(token, accountId, category._id);
        setDeleteBtnStatus('finished');
      } else {
        setDeleteBtnStatus('finished');
      }
      setTimeout(() => {
        setDeleteBtnStatus('');
        setEditingCategory('');
        onDelete(value);
      }, 1000);
    } catch (er) {
      console.error(er);
      setDeleteBtnStatus('');
    }
  };

  const handleDiscardChange = () => {
    if (category) {
      setValue({ ...category, questions: [...(category?.questions || [])] });
    }
    setEditingVariable('');
    setEditingCategory('');
  };

  const handleAddNewVariable = async (variable: Partial<OpenDataFieldVariable>) => {
    setValue({
      ...value,
      questions: [...value.questions, { ...variable, variableId: variable.variableId || '' }]
    });
    setEditingVariable('');
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const questions = reorder(value.questions, result.source.index, result.destination.index);
    setValue({ ...value, questions: questions });
  };

  return (
    <div
      className={classNames(
        styles.card,
        editing && styles.editing,
        (deleteStatus !== '' || saveBtnStatus !== '' || (!editing && editingCategory !== '')) && styles.disabled
      )}
    >
      <DataCategoryHeader
        value={value}
        editing={editing}
        category={category}
        isAddNew={isAddNew}
        showError={showError}
        canReorder={canReorder}
        deleteStatus={deleteStatus}
        editingCategory={editingCategory}
        dragHandleProps={dragHandleProps}
        setValue={setValue}
        handleDelete={handleDelete}
        setEditingCategory={setEditingCategory}
        handleDiscardChange={handleDiscardChange}
      />
      <div className={styles.variables}>
        {!(
          (editingVariable === 'new' && value.questions.length === 0) ||
          (editingVariable !== 'new' && editingVariable !== '' && value.questions.length === 1)
        ) && (
          <div className={classNames(columnStyles.listHeader, editing && columnStyles.editable)}>
            <div className={columnStyles.question}>Question</div>
            <div className={columnStyles.name}>VARIABLE NAME</div>
            <div className={columnStyles.id}>VARIABLE ID</div>
            <div className={columnStyles.format}>ANSWER FORMAT</div>
            <div className={columnStyles.button} />
          </div>
        )}
        <ErrorMessage
          visible={showError && !(value?.questions?.length > 0) && editingVariable !== 'new'}
          error={'Please input at least 1 field'}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId={`droppable${value._id || 'new'}`}
            type={`${value._id || 'new'}`}
            isDropDisabled={!editing}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef}>
                {value.questions.map((item, index) => (
                  <Draggable
                    key={`${item.id || 'new'}${index}`}
                    draggableId={`${item.id || 'new'}${index}`}
                    index={index}
                    isDragDisabled={!editing || editingVariable !== ''}
                  >
                    {(provided) => (
                      <div className={styles.listItemWrapper} ref={provided.innerRef} {...provided.draggableProps}>
                        <OpenDataVariable
                          key={item.id}
                          variable={item}
                          editable={editing}
                          editingVariable={editingVariable}
                          deletingVariable={deletingVariable}
                          setEditingVariable={setEditingVariable}
                          setDeletingVariable={setDeletingVariable}
                          onDelete={() =>
                            setValue({ ...value, questions: value.questions.filter((i) => i.id !== item.id) })
                          }
                          onChange={(v) =>
                            setValue({ ...value, questions: value.questions.map((i) => (i.id !== item.id ? i : v)) })
                          }
                          commonVariables={commonVariables.filter(
                            (commonVariable) =>
                              commonVariable.variableId === item.variableId ||
                              !value.questions.map((i) => i.variableId).includes(commonVariable.variableId)
                          )}
                          scaleTypes={scaleTypes}
                          isCommonVariablesLoading={isCommonVariablesLoading}
                          dragHandleProps={provided.dragHandleProps}
                          dragSnapshot={snapshot}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {editing && (
          <>
            {editingVariable === 'new' && (
              <VariableForm
                onSubmit={(v) => {
                  handleAddNewVariable(v);
                }}
                onCancel={() => setEditingVariable('')}
                commonVariables={commonVariables.filter(
                  (item) => !value.questions.map((i) => i.variableId).includes(item.variableId)
                )}
                scaleTypes={scaleTypes}
                isCommonVariablesLoading={isCommonVariablesLoading}
              />
            )}
            <div className={classNames(styles.footer, editingVariable === 'new' && styles.alignRight)}>
              {editingVariable !== 'new' && (
                <ButtonAlt
                  variant={'outlined'}
                  onClick={() => setEditingVariable('new')}
                  disabled={editingVariable !== ''}
                >
                  Add new question
                </ButtonAlt>
              )}
              <ButtonAlt onClick={handleSubmit} disabled={editingVariable !== ''} status={saveBtnStatus}>
                Save
              </ButtonAlt>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DataCategory;
