import styles from './Packages.module.scss';
import HelmetWrapper from '../../components/HelmetWrapper/HelmetWrapper';
import PackageCard from './components/PackageCard/PackageCard';
import { useGetPackageListQuery } from 'redux/endpoints/scheduleServices/package';
import { useGetAccountId } from '../../utils/hooks/GetAccountInfo/getAccountId';
import PaginationListV2 from '../../components/v2/PaginationListV2/PaginationListV2';
import { selectPaging, setPaging } from 'redux/packages/packageListSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Skeleton } from 'antd';
import noPackageImg from 'assets/images/noPackageResults.png';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useState } from 'react';
import { PackageModal } from './components/PackageModal/PackageModal';

const Packages = () => {
  const { accountId } = useGetAccountId();
  const dispatch = useAppDispatch();
  const [isPackageModalVisible, setIsPackageModalVisible] = useState(false);

  const paging = useAppSelector(selectPaging);

  const {
    data: packageFunderData,
    isLoading: packageFunderLoading,
    isFetching: packageFunderFetching
  } = useGetPackageListQuery(
    {
      accountId: accountId,
      params: {
        page: paging.page,
        perPage: paging.perPage
      }
    },
    { skip: !accountId }
  );

  const onChangePage = (page: number) => {
    dispatch(setPaging({ ...paging, page }));
  };

  return (
    <HelmetWrapper title="Tacklit - Packages">
      <div className={styles.container}>
        {/*<PackageFilters />*/}
        {packageFunderLoading || packageFunderFetching ? (
          <div className={styles.loadingWrapper}>
            {[...Array(10)].map((_, i) => (
              <div key={i} className={styles.loadingItem}>
                <Skeleton.Input active className={styles.loadingContent} />
              </div>
            ))}
          </div>
        ) : packageFunderData?.packages && packageFunderData?.packages.length <= 0 ? (
          <div className={styles.noContentContainer}>
            <img className={styles.noContentImg} src={noPackageImg} alt={'No package found'} />
            <div>
              <div className={styles.noContentTitle}>No package created yet.</div>
              <div>
                <ButtonAlt
                  icon={'add_circle_outline'}
                  variant={'outlined'}
                  size={'medium'}
                  onClick={() => setIsPackageModalVisible(!isPackageModalVisible)}
                >
                  New Package
                </ButtonAlt>
                <PackageModal visible={isPackageModalVisible} onClose={() => setIsPackageModalVisible(false)} />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.contentContainer}>
            {packageFunderData?.packages.map((packageObj, index) => (
              <PackageCard key={index} packagesData={packageObj} />
            ))}
            {packageFunderData && packageFunderData.paging.totalItems > packageFunderData.paging.perPage && (
              <div className={styles.paginationWrapper}>
                <PaginationListV2 paging={packageFunderData.paging} onPageChange={onChangePage} />
              </div>
            )}
          </div>
        )}
      </div>
    </HelmetWrapper>
  );
};

export default Packages;
