import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';

const URL = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const getClinicalAssessmentByAssessmentId = (token: string, assessmentId: string, queryParam: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/clinical-assessment-responses/${assessmentId}${`?${queryParam}` ?? ''}`
  ) as Promise<BentResponse>;

export const getClinicalAssessmentList = (token: string, recordId: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/clinical-assessment-responses`
  ) as Promise<BentResponse>;

export const getClinicalAssessmentDetailsResponse = (token: string, recordId: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${recordId}/clinical-assessment-detailed-responses`
  ) as Promise<BentResponse>;

export const postAdHocAssessment = (token: string, payload: Record<string, any>) =>
  httpClient('POST', URL!, 201, { Authorization: `Bearer ${token}` })(
    '/ad-hoc-assessment',
    payload
  ) as Promise<BentResponse>;

export const getBazaarAssessment = (token: string, clientRecordId: string, clientProfileId: string) =>
  httpClient('POST', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-profiles/${clientProfileId}:generateBazaarSessionToken`
  ) as Promise<BentResponse>;

export const getGroupClinicalAssessmentList = (token: string, recordId: string) =>
  httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${recordId}/clinical-assessment-responses`
  ) as Promise<BentResponse>;
