import classnames from 'classnames';
import { ChangeEvent, InputHTMLAttributes } from 'react';
import styles from './ToggleSwitch.module.scss';

export interface ToggleSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  checkLabel: string;
  unCheckLabel: string;
  buttonWidth?: number;
  isCheckedProps: boolean;
  onChangeProps: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  toggleClassName?: string;
  labelClassName?: string;
}

const ToggleSwitch = ({
  id,
  checkLabel,
  unCheckLabel,
  buttonWidth,
  isCheckedProps,
  onChangeProps,
  disabled,
  toggleClassName,
  labelClassName
}: ToggleSwitchProps) => {
  return (
    <div className={classnames(styles.toggleSwitch, toggleClassName, disabled && styles.disabled)}>
      <input
        type="checkbox"
        id={`toggleSwitch-${id}`}
        checked={isCheckedProps}
        onChange={onChangeProps}
        disabled={disabled}
      />
      <label
        htmlFor={`toggleSwitch-${id}`}
        style={{ width: buttonWidth }}
        className={classnames(styles.wrapper, labelClassName)}
        data-checked={checkLabel}
        data-unchecked={unCheckLabel}
      >
        <div className={styles.switch} />
      </label>
    </div>
  );
};

export default ToggleSwitch;
