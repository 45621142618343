import { Modal, notification } from 'antd';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { ButtonStatusType } from 'components/v2/Button/Button';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useFetchPractitionerList } from 'components/v2/ClinicianSelect/hooks/GetPractitionersList';
import { AccessRight } from 'interfaces/Clients/clinician';
import { Integration } from 'pages/ControlPanel/Interfaces/Integration';
import { useState } from 'react';
import { validateMedicareProviderNumber } from 'utils/helpers/validateMedicareProviderNumber';
import { useGetAccessToken } from 'utils/hooks/token';
import { patchMedicareIntegration } from 'utils/http/BillingService/Invoice/claimingcom';

import { useFetchMedicareLocations, useFetchMedicareProviders } from '../../hooks/getMedicareData';
import MedicareLocation from './components/MedicareLocation/MedicareLocation';
import MedicareProviderListing from './components/MedicareProviderListing/MedicareProviderListing';
import styles from './MedicareDetails.module.scss';

interface MedicareDetailsProps {
  integration: Integration;
  refetchSystemIntegration: () => void;
}

const MedicareDetails = ({ integration, refetchSystemIntegration }: MedicareDetailsProps) => {
  const { token } = useGetAccessToken();
  const { locations, isLocationsLoading, refetchMedicareLocations, refetchMedicareLocationsSilently } =
    useFetchMedicareLocations(token);
  const { providers, isProvidersLoading } = useFetchMedicareProviders(token);
  const { practitionersList } = useFetchPractitionerList(token, false, [AccessRight.Admin, AccessRight.User]);

  const [providerNumber, setProviderNumber] = useState(integration.providerNumber || '');
  const [isProviderNumberDisabled, setIsProviderNumberDisabled] = useState(true);
  const [showProviderNumberError, setShowProviderNumberError] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');
  const providerNumberError = validateMedicareProviderNumber(providerNumber);

  const massagedProviders = providers.map((provider) => {
    const foundLocation = locations.find(({ minorId }) => minorId === provider.locationMinorId);
    const foundPractitioner = practitionersList.find(({ _id }) => _id === provider.clinicianId);
    return {
      ...provider,
      locationActive: foundLocation?.active ?? false,
      clinicianName: foundPractitioner?.name,
      clinicianRole: foundPractitioner?.medicare?.role
    };
  });

  const submitProviderNumber = async () => {
    setSubmitStatus('active');

    try {
      await patchMedicareIntegration(token, { providerNumber });
      refetchSystemIntegration();
      setSubmitStatus('finished');

      setTimeout(() => {
        setSubmitStatus('');
        setIsProviderNumberDisabled(true);
      }, 1000);
    } catch (ex) {
      console.error('Error while updating practice provider number', ex);
      notification.error({
        message: 'Failed to update practice provider number',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setSubmitStatus('');
    }
  };

  const handleSaveProviderNumber = () => {
    if (providerNumberError) {
      setShowProviderNumberError(true);
      return;
    }

    Modal.confirm({
      title: 'Are you sure you want to save this provider number?',
      content: 'This provider number can be used as the payee in all future claims.',
      cancelText: 'Cancel',
      okText: 'Confirm',
      onOk: submitProviderNumber
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.providerNumberContainer}>
        {isProviderNumberDisabled ? (
          <div className={styles.providerNumberValue}>
            <div className={styles.label}>
              Service Provider Number
              <HelpOutLineWithTooltips
                id="providerNumber"
                iconClass={styles.tooltipIcon}
                desc={'This provider number may be used as a payee for Bulk Bill/DVA claims'}
              />
            </div>
            <div className={styles.value}>{integration.providerNumber || '-'}</div>
          </div>
        ) : (
          <div className={styles.inputContainer}>
            <MaterialInput
              id="serviceProviderNumber"
              name="serviceProviderNumber"
              label={
                <>
                  Service Provider Number
                  <HelpOutLineWithTooltips
                    id="providerNumber"
                    desc={'This provider number may be used as a payee for Bulk Bill/DVA claims'}
                  />
                </>
              }
              value={providerNumber}
              disabled={isProviderNumberDisabled || !!submitStatus}
              onChange={(e) => setProviderNumber(e.target.value?.toUpperCase())}
              required
            />
            {showProviderNumberError && <div className={styles.error}>{providerNumberError}</div>}
          </div>
        )}
        {isProviderNumberDisabled ? (
          <ButtonAlt
            size={'medium'}
            className={styles.unlockButton}
            variant="text"
            icon={'lock'}
            onClick={() => setIsProviderNumberDisabled(false)}
          >
            Unlock to edit
          </ButtonAlt>
        ) : (
          <ButtonAlt
            size={'medium'}
            className={styles.unlockButton}
            variant="contained"
            status={submitStatus}
            onClick={handleSaveProviderNumber}
          >
            Save Provider Number
          </ButtonAlt>
        )}
      </div>
      <MedicareLocation
        locations={locations}
        isLoading={isLocationsLoading}
        refetchMedicareLocations={refetchMedicareLocations}
        refetchMedicareLocationsSilently={refetchMedicareLocationsSilently}
      />
      <MedicareProviderListing providers={massagedProviders} isLoading={isProvidersLoading} />
    </div>
  );
};

export default MedicareDetails;
