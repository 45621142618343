import Button from 'components/v2/Button/Button';
import moment from 'moment';
import { Invoice, InvoiceStatus } from 'pages/Invoices/interface';
import {
  Claim,
  ClaimStatus,
  ClaimType
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import ClaimStatusComponent from '../../../../../ClaimItem/components/ClaimStatus/ClaimStatus';
import styles from './ClientInvoiceItem.module.scss';
import { STATUS_LABELS } from 'pages/Invoices/components/InvoiceListing/components/ClientInvoiceItem/ClientInvoiceItem';
import { config } from 'config/config';

const MONEY_SYMBOL = config.currencySymbol;

interface InvoiceItemProps {
  isMedicareClient: boolean;
  isDvaClient: boolean;
  invoice: Invoice;
  claim?: Claim;
  onInvoiceClick: (claimType: ClaimType) => void;
}

const ClientInvoiceItem = ({
  isMedicareClient,
  isDvaClient,
  invoice: { _id, description, issueDate, invoiceAmount, invoiceId, status, items },
  claim,
  onInvoiceClick
}: InvoiceItemProps) => {
  const { INVOICES } = useRoutesGenerator();

  const [isExpanded, setIsExpanded] = useState(false);

  const menuNode = useRef<HTMLDivElement>(null);

  const handleClick = (e: any) => {
    if (!menuNode.current?.contains(e.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleSelectClaimType = (claimType: ClaimType) => () => {
    onInvoiceClick(claimType);
  };

  const hasNoItems = items.length === 0;
  const hasInvalidItem = items.length === 1 && !items[0].appointmentId;
  const hasTooManyItems = items.length > 1;
  const hasSuccessClaim =
    claim?.status &&
    [ClaimStatus.Complete, ClaimStatus.MedicareAssessed, ClaimStatus.WithMedicare].includes(claim.status);
  const isFutureAppointment = moment(items[0].appointmentDate, 'DD-MM-YYYY').isAfter(moment());
  const disallowedCreateClaimInvoiceStatuses = [
    InvoiceStatus.Voided,
    InvoiceStatus.Closed,
    InvoiceStatus.WriteOff
  ].includes(status);

  const isDisabled =
    hasNoItems ||
    hasInvalidItem ||
    hasTooManyItems ||
    hasSuccessClaim ||
    isFutureAppointment ||
    disallowedCreateClaimInvoiceStatuses;

  const message = useMemo(
    () =>
      hasNoItems
        ? 'Not enough items in invoice'
        : hasInvalidItem
        ? 'Invoiced item is not an appointment'
        : hasTooManyItems
        ? 'Too many items in invoice'
        : hasSuccessClaim
        ? 'Invoice has been successfully claimed.'
        : isFutureAppointment
        ? 'Unable to create claim for future appointment.'
        : disallowedCreateClaimInvoiceStatuses
        ? 'Unable to create claim for a closed, voided or written-off invoice.'
        : '',
    [
      hasNoItems,
      hasInvalidItem,
      hasTooManyItems,
      hasSuccessClaim,
      isFutureAppointment,
      disallowedCreateClaimInvoiceStatuses
    ]
  );

  return (
    <div className={styles.container}>
      <Link className={styles.invoiceId} to={`${INVOICES.BASE}/${_id}`} target="_blank">
        {invoiceId}
      </Link>
      <span className={styles.issueDate}>
        {moment(issueDate, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format(MOMENTJS_DATE_FORMAT)}
      </span>
      <span className={styles.description}>
        {description ? (description.length > 70 ? `${description.slice(0, 70)}...` : description) : '-'}
      </span>
      <span className={styles.invoiceAmount}>
        {MONEY_SYMBOL}
        {invoiceAmount.toFixed(2)}
      </span>
      <div className={styles.statusWrapper}>
        <span className={`${styles.status} ${styles[status]}`}>{STATUS_LABELS[status]}</span>
      </div>
      <div className={styles.claimedWrapper}>
        {claim?.status ? <ClaimStatusComponent _id={claim._id} status={claim.status} iconOnly /> : '-'}
      </div>
      <div ref={menuNode} className={styles.actionButtonContainer}>
        {isDisabled && message && (
          <Tooltip id={`select-invoice-button-${_id}`} className={styles.tooltip}>
            {message}
          </Tooltip>
        )}
        <Button
          className={styles.button}
          variant="secondary"
          data-tooltip-id={`select-invoice-button-${_id}`}
          disabled={isDisabled}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <i className={`material-icons ${styles.icon}`}>more_vert</i>
        </Button>
        <div className={styles.menuWrapper}>
          <div className={isExpanded ? styles.menuShow : styles.menuHide}>
            {isMedicareClient && (
              <>
                <div className={styles.menuItem} onClick={handleSelectClaimType(ClaimType.BulkBill)}>
                  <div className={styles.label}>Bulk-bill Claim</div>
                </div>
                <div className={styles.menuItem} onClick={handleSelectClaimType(ClaimType.Medicare)}>
                  <div className={styles.label}>Rebate Claim</div>
                </div>
              </>
            )}
            {isDvaClient && (
              <div className={styles.menuItem} onClick={handleSelectClaimType(ClaimType.DVA)}>
                <div className={styles.label}>DVA Claim</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientInvoiceItem;
