import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { TabIds } from 'pages/Client/Client';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { selectSetting } from 'redux/clients/clientListColumnsSettingsSlice';
import {
  resetFilters,
  resetPaging,
  selectFilters,
  selectPaging,
  selectSearch,
  selectSorting,
  setFilters
} from 'redux/clients/clientListSlice';
import { useGetClientRecordsListQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetMinifiedGroupListQuery } from 'redux/endpoints/clinicianProfileServices/group';
import { useGetPractitionerListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { ColumnConfigType, ColumnSettingsEnum } from 'utils/providers/ClientListColumnsProvider/constants';
import { getClientRecordRequestPayload } from '../ClientListContent/components/ClientList/constants';
import styles from './ClientListFilters.module.scss';
import {
  formMenuFilterByStatus,
  formMenuFilterByTime,
  onToggleFilter,
  profileTypeMenuItems,
  referralMenuItems,
  referralStatusesItems
} from './constants';
import { Skeleton } from 'antd';
import { debounce } from 'lodash';

const PER_PAGE = 50;

const ClientListFilters = () => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist, isEdgeUserView, isEdgeUser } = useGetAccountPackageView();
  const dispatch = useAppDispatch();
  const { tabId } = useParams<{ [key in keyof TabIds]: string }>();

  const columnSetting = useAppSelector(selectSetting);
  const search = useAppSelector(selectSearch);
  const paging = useAppSelector(selectPaging);
  const sorting = useAppSelector(selectSorting);
  const filters = useAppSelector(selectFilters);

  const [practitionerCurrentPage, setPractitionerCurrentPage] = useState<number>(1);
  const [practitionerSearchText, setPractitionerSearchText] = useState('');

  const {
    data: practitionerData,
    isLoading: isPractitionerDataLoading,
    isFetching: isPractitionerDataFetching
  } = useGetPractitionerListQuery(
    {
      accountId,
      params: {
        page: practitionerCurrentPage,
        perPage: PER_PAGE,
        status: 'active',
        searchValue: practitionerSearchText
      }
    },
    {
      skip:
        !columnSetting?.columns ||
        !columnSetting.columns.find((setting) => setting.id === ColumnSettingsEnum.PRACTITIONER)
    }
  );

  const { data: groupData } = useGetMinifiedGroupListQuery(
    { accountId, isEdgeUserView },
    {
      skip: !columnSetting?.columns || !columnSetting.columns.find((setting) => setting.id === ColumnSettingsEnum.GROUP)
    }
  );

  const { data: clientRecordListResponse, isLoading: isClientRecordListLoading } = useGetClientRecordsListQuery(
    {
      accountId,
      payload: {
        ...getClientRecordRequestPayload({ tabId, paging, sorting, filters, isEdgeUserView, columnSetting, search })
      }
    },
    { skip: !columnSetting }
  );

  const practitionerList: FilterCheckListItem[] = practitionerData
    ? practitionerData.clinicians.map((item) => ({ name: item.name, _id: item._id }))
    : [];
  const groupList: FilterCheckListItem[] = groupData
    ? groupData.groups.map((item) => ({ name: item.name, _id: item._id }))
    : [];

  const isInactive = (columnId: string) =>
    !columnSetting?.columns?.find(({ id, active }: ColumnConfigType) => active && id === columnId);

  const onChangePractitionerFilter = (selectedPractitioners: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedPractitioners }));
  };

  const onChangeGroupFilter = (selectedGroups: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedGroups }));
  };

  const onChangeReferralSource = (selectedReferralSources: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedReferralSources }));
  };

  const onChangeReferralStatuses = (selectedReferralStatuses: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedReferralStatuses }));
  };

  const onChangeProfileType = (selectedProfileTypes: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedProfileTypes }));
  };

  const onChangeForm = (selectedFormsStatus: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedFormsStatus }));
  };

  const onClearFilter = () => {
    dispatch(resetPaging());
    dispatch(resetFilters());
  };

  const onFilterFormsTimeBound = (selected: string) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedFormsTimeBound: selected }));
  };

  const totalPractitionerPage = practitionerData?.paging ? Math.ceil(practitionerData.paging.totalItems / PER_PAGE) : 1;
  const {
    selectedPractitioners,
    selectedGroups,
    selectedReferralSources,
    selectedReferralStatuses,
    selectedProfileTypes,
    selectedFormsStatus,
    selectedFormsTimeBound
  } = filters;
  const showClearFilter = Object.keys(filters).some(
    (key) =>
      key !== 'recordStatus' && key !== 'selectedFormsTimeBound' && filters[key as keyof typeof filters].length > 0
  );

  const debouncedSetPractitionersSearchText = useMemo(
    () =>
      debounce((value) => {
        setPractitionerSearchText(value);
        setPractitionerCurrentPage(1);
      }, 1000),
    []
  );

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <div className={styles.numberOfMatchedProfiles}>
          {!isClientRecordListLoading && clientRecordListResponse ? (
            <div>
              {showClearFilter ? 'FILTER matches' : 'Showing'}{' '}
              <span className={styles.highLight}>{clientRecordListResponse?.paging?.totalItems}</span> profiles
            </div>
          ) : (
            <div className={styles.loadingProfile}>
              <Skeleton.Input className={styles.loading} active />
            </div>
          )}
        </div>
        <ButtonAlt
          error
          disabled={!showClearFilter}
          size={'small'}
          variant={'text'}
          className={styles.clearFilters}
          onClick={onClearFilter}
        >
          Clear filters
        </ButtonAlt>
      </div>
      <div className={styles.buttonsContainer}>
        {(isEdgeAdminView || isEdgeReceptionist) && (
          <FilterDropdown
            id={'practitioner'}
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            isInactive={isInactive(ColumnSettingsEnum.PRACTITIONER)}
            menuItems={practitionerList}
            onChangeItem={onToggleFilter(practitionerList, selectedPractitioners, onChangePractitionerFilter)}
            searchable
            selectedFilterList={selectedPractitioners}
            showSearchIcon
            showToggleAllButtons
            loading={isPractitionerDataLoading}
            hasMoreData={totalPractitionerPage > practitionerCurrentPage || isPractitionerDataFetching}
            isFetchingMore={isPractitionerDataFetching}
            loadMore={() => {
              if (!isPractitionerDataFetching && practitionerCurrentPage <= totalPractitionerPage) {
                setPractitionerCurrentPage(practitionerCurrentPage + 1);
              }
            }}
            setSearchText={debouncedSetPractitionersSearchText}
          >
            Practitioner
          </FilterDropdown>
        )}
        <FilterDropdown
          id={'profileType'}
          enableButtonLabelItemsColor
          icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
          isInactive={isInactive(ColumnSettingsEnum.PROFILE_TYPE)}
          menuItems={profileTypeMenuItems}
          onChangeItem={onToggleFilter(profileTypeMenuItems, selectedProfileTypes, onChangeProfileType)}
          selectedFilterList={selectedProfileTypes}
        >
          Profile type
        </FilterDropdown>
        {isEdgeUser && (
          <FilterDropdown
            id={'group'}
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            isInactive={isInactive(ColumnSettingsEnum.GROUP)}
            menuItems={groupList}
            onChangeItem={onToggleFilter(groupList, selectedGroups, onChangeGroupFilter)}
            searchable
            selectedFilterList={selectedGroups}
            showSearchIcon
            showToggleAllButtons
          >
            Groups
          </FilterDropdown>
        )}
        <FilterDropdown
          id={'formFilter'}
          isInactive={
            isInactive(ColumnSettingsEnum.LAST_CONSENT_SIGNED) &&
            isInactive(ColumnSettingsEnum.LAST_PSYCHOMETRIC_COMPLETED) &&
            isInactive(ColumnSettingsEnum.LAST_SURVEY_COMPLETED)
          }
          menuItems={formMenuFilterByStatus}
          onChangeItem={onToggleFilter(formMenuFilterByStatus, selectedFormsStatus, onChangeForm)}
          selectedFilterList={selectedFormsStatus}
          filterRadioListItems={formMenuFilterByTime}
          onChangeRadioItem={onFilterFormsTimeBound}
          selectedRadioItem={selectedFormsTimeBound}
          radioSectionTitle={'Time bound'}
        >
          Forms
        </FilterDropdown>
        <FilterDropdown
          id={'referralFilter'}
          isInactive={isInactive(ColumnSettingsEnum.REFERRAL_SOURCE_AND_DETAIL)}
          menuItems={referralMenuItems()}
          onChangeItem={onToggleFilter(referralMenuItems(), selectedReferralSources, onChangeReferralSource)}
          selectedFilterList={selectedReferralSources}
        >
          Referral Source
        </FilterDropdown>
        <FilterDropdown
          id={'referralFilter'}
          isInactive={isInactive(ColumnSettingsEnum.REFERRAL_STATUSES)}
          menuItems={referralStatusesItems()}
          onChangeItem={onToggleFilter(referralStatusesItems(), selectedReferralStatuses, onChangeReferralStatuses)}
          selectedFilterList={selectedReferralStatuses}
        >
          Referral Statuses
        </FilterDropdown>
      </div>
    </div>
  );
};

export default ClientListFilters;
