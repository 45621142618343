import { httpClient } from '../../../httpClient';
import { BentResponse } from 'bent';
import _ from 'lodash';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const postPractitionerInvite = (token: string, accountId: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians:sendInvites`,
    payload
  ) as Promise<BentResponse>;

export const getPractitionerListing = (token: string, accountId: string, qParam?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians?${qParam ?? ''}`
  ) as Promise<BentResponse>;

export const getPractitionerListingCount = (token: string, accountId: string, qParam: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians:count?${qParam}`
  ) as Promise<BentResponse>;

export const deactivatePractitioner = (token: string, accountId: string, clinicianId: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians/${clinicianId}:deactivate`
  ) as Promise<BentResponse>;

export const activatePractitioner = (token: string, accountId: string, clinicianId: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians/${clinicianId}:activate`
  ) as Promise<BentResponse>;

export const getProfileTags = (token: string, accountId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/client-record-tags`
  ) as Promise<BentResponse>;

export const patchReassignClient = (
  token: string,
  accountId: string,
  clientRecordId: string,
  payload: Record<string, any>
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/client-records/${clientRecordId}:reassign`,
    payload
  ) as Promise<BentResponse>;

export const patchReassignMentorToClient = (
  token: string,
  accountId: string,
  clientRecordId: string,
  payload: Record<string, any>
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/client-records/${clientRecordId}:reassignMentor`,
    payload
  ) as Promise<BentResponse>;

export const assignRole = (token: string, accountId: string, clinicianId: string, role: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians/${clinicianId}:assign${_.startCase(role)}Role`
  ) as Promise<BentResponse>;

export const getGeneralPractitionerList = (token: string, accountId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/general-practitioners`
  ) as Promise<BentResponse>;

export const getGeneralPractitionerById = (token: string, accountId: string, generalPractitionerId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/general-practitioners/${generalPractitionerId}`
  ) as Promise<BentResponse>;

export const postGeneralPractitioner = (token: string, accountId: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/general-practitioners`,
    payload
  ) as Promise<BentResponse>;

export const putGeneralPractitioner = (
  token: string,
  accountId: string,
  generalPractitionerId: string,
  payload: Record<string, any>
) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/general-practitioners/${generalPractitionerId}`,
    payload
  ) as Promise<BentResponse>;

export const getNotificationSettings = (token: string, accountId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/notification-settings`
  ) as Promise<BentResponse>;

export const postNotificationSettings = (token: string, accountId: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/notification-settings`,
    payload
  ) as Promise<BentResponse>;

export const postGetSetPasswordLink = (token: string, accountId: string, payload: { shortCode: string }) =>
  httpClient('POST', url!, [200, 400, 403, 500], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians/me/reset-password`,
    payload
  ) as Promise<BentResponse>;

export const patchRequestResetClinicianPassword = (token: string, accountId: string, clinicianId: string) =>
  httpClient('PATCH', url!, [204, 400, 403], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians/${clinicianId}:requestResetPassword`
  ) as Promise<BentResponse>;

export const patchClinicianPassword = (token: string, accountId: string, clinicianId: string) =>
  httpClient('PATCH', url!, [200, 400, 403], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians/${clinicianId}:changePassword`
  ) as Promise<BentResponse>;
