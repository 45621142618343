import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { ADDRESS_BOOK_CONTACT_ROLE_OPTIONS, AddressBookContact } from 'pages/AddressBook/Interfaces/AddressBookContact';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { CreateAddressBookContactValidateField } from '../../CreateAddressBookModalInterfaces';
import styles from './AddressBookContactForm.module.scss';
import { validateContactForm } from './validation/AddressBookContactFormValidation';

export interface AddressBookContactFormHandler {
  validate: (isReset?: boolean) => Promise<boolean>;
}

const checkFieldHaveError = (validate: CreateAddressBookContactValidateField) =>
  validate ? Object.values(validate).some((value) => value !== '') : false;

export interface CreateAddressBookContactRequestPayload extends AddressBookContact {}

interface AddressBookContactFormProps {
  contactField: CreateAddressBookContactRequestPayload;
  onChangeField: (fields: CreateAddressBookContactRequestPayload) => void;
}

const AddressBookContactForm = forwardRef<AddressBookContactFormHandler, AddressBookContactFormProps>(
  ({ contactField, onChangeField }, ref) => {
    const [errorMessage, setErrorMessage] = useState<CreateAddressBookContactValidateField>({
      firstName: ''
    });

    const handleChangeFieldValue = async (key: string, val: string) => {
      const newContact = {
        ...contactField,
        [key]: val
      };
      await validateContactFields(newContact);
      onChangeField(newContact);
    };

    const validateContactFields = async (contact: CreateAddressBookContactRequestPayload) => {
      const validate = validateContactForm(contact);
      setErrorMessage(validate as CreateAddressBookContactValidateField);

      return !checkFieldHaveError(validate as CreateAddressBookContactValidateField);
    };

    useImperativeHandle(ref, () => ({
      validate: async () => await validateContactFields(contactField)
    }));

    return (
      <div className={styles.container}>
        <div className={styles.rowContainer}>
          <div className={classNames(styles.fieldContainer)}>
            <MaterialInput
              id={`firstName-contact`}
              label={`First Name`}
              maxLength={20}
              onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
              value={contactField.firstName}
              error={!!errorMessage.firstName}
              required
            />
            {errorMessage.firstName && <div className={styles.fieldError}>{errorMessage.firstName}</div>}
          </div>

          <div className={styles.fieldContainer}>
            <MaterialInput
              id={`lastName-contact`}
              label={`Last Name`}
              maxLength={20}
              onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
              value={contactField.lastName}
              required
            />
          </div>
        </div>

        <div className={styles.rowContainer}>
          <div className={classNames(styles.fieldContainer, styles.roleField)}>
            <MaterialSelect
              id={`role-contact`}
              label={'Role'}
              isSearchable={false}
              options={ADDRESS_BOOK_CONTACT_ROLE_OPTIONS}
              value={contactField.role}
              onChange={(value) => handleChangeFieldValue('role', value)}
            />
          </div>

          <div className={styles.fieldContainer}>
            <MaterialInput
              id={`email-contact`}
              label={`Email`}
              maxLength={200}
              onChange={(e) => handleChangeFieldValue('email', e.target.value)}
              value={contactField.email}
              required
              icon={'email'}
            />
          </div>
        </div>

        <div className={styles.rowContainer}>
          <div className={styles.fieldContainer}>
            <MaterialPhoneInput
              id={'mobileNumber-contact'}
              autoFormat={false}
              disableCountryCode
              hideFlag
              inputName="mobileNumber"
              label="Mobile Number"
              onChange={(value = '') => {
                handleChangeFieldValue('mobileNumber', value);
              }}
              placeholder=""
              isError={false}
              value={contactField.mobileNumber || ''}
              inputClass={styles.transparentInput}
              icon="phone"
            />
          </div>

          <div className={styles.fieldContainer}>
            <MaterialPhoneInput
              id={'faxNumber-contact'}
              autoFormat={false}
              disableCountryCode
              hideFlag
              inputName="faxNumber"
              label="eFax Number"
              onChange={(value = '') => {
                handleChangeFieldValue('faxNumber', value);
              }}
              placeholder=""
              isError={false}
              value={contactField.faxNumber || ''}
              inputClass={styles.transparentInput}
              icon="fax"
            />
          </div>
        </div>
      </div>
    );
  }
);

export default AddressBookContactForm;
