import { DatePicker } from 'antd';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Radio from 'components/Radio/Radio';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import moment, { Moment } from 'moment';
import { getAddressBookName } from 'pages/AddressBook/helpers';
import { FullInvoice } from 'pages/Invoices/components/InvoiceDetail/hooks/getInvoice';
import { InvoiceStatus, RecipientTypes } from 'pages/Invoices/interface';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAddressBookQuery } from 'redux/endpoints/clinicianProfileServices/addressBook';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import * as yup from 'yup';
import SelectKeyContactsBox from './components/SelectKeyContactsBox';
import styles from './ShareInfoBox.module.scss';
import './ShareInfoBox.scss';

const emailSchema = yup.array().of(yup.string().email());

interface ShareInvoiceProps {
  invoice: FullInvoice;
  label: string;
  defaultNote?: string;
  sendInvoiceButtonStatus: '' | 'active' | 'finished';
  participationType?: ParticipantType;
  onSendInvoice: (shareDetails: { sendAt: Date; sendClient: boolean; sendTo?: string[]; note?: string }) => void;
}

const ShareInfoBox = ({
  invoice,
  label,
  defaultNote,
  sendInvoiceButtonStatus,
  participationType,
  onSendInvoice
}: ShareInvoiceProps) => {
  const { accountId } = useGetAccountId();
  const [t] = useTranslation();

  const keyContacts = invoice.group?.keyContacts || invoice.clientRecord?.keyClientContacts;

  const [customSendInvoiceTime, setCustomSendInvoiceTime] = useState('');
  const [emails, setEmails] = useState('');
  const [sendKeyContactIds, setSendKeyContactIds] = useState<string[]>([]);
  const [note, setNote] = useState(defaultNote || '');
  const [sendInvoice, setSendInvoice] = useState('now');
  const [isSendToClientChecked, setIsSendToClientChecked] = useState(
    !!invoice.clientRecord?.allowCommunicationWithEmail
  );
  const [isSendToOtherChecked, setIsSendToOtherChecked] = useState(false);
  const [isSendToFunderChecked, setIsSendToFunderChecked] = useState(false);
  const [isSendToKeyContactsChecked, setIsSendToKeyContactsChecked] = useState<boolean>(!!keyContacts?.length);

  const [errors, setErrors] = useState<{
    customSendInvoiceTime?: string;
    emails?: string;
    sendTo?: string;
    keyContacts?: string;
  }>({});

  const {
    data: funderData,
    isLoading: isFunderDataLoading,
    isFetching: isFunderDataFetching
  } = useGetAddressBookQuery(
    {
      accountId,
      addressBookId: invoice.addressBook?._id || ''
    },
    { skip: !invoice.addressBook?._id }
  );

  const handleCustomSendInvoiceTimeChange = (value: Moment | null) => {
    if (value) {
      setCustomSendInvoiceTime(value.format('YYYY-MM-DD HH:mm'));

      if (errors.customSendInvoiceTime) {
        setErrors({ ...errors, customSendInvoiceTime: undefined });
      }
    } else {
      setCustomSendInvoiceTime('');
    }
  };

  const handleSendToClientChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsSendToClientChecked(e.target.checked);

    if (errors.sendTo) {
      setErrors({ ...errors, sendTo: undefined });
    }
  };

  const handleSendToKeyContactsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsSendToKeyContactsChecked(e.target.checked);
    if (errors.sendTo) {
      setErrors({ ...errors, sendTo: undefined });
    }
  };

  const handleSendToFunderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsSendToFunderChecked(e.target.checked);

    if (errors.sendTo) {
      setErrors({ ...errors, sendTo: undefined });
    }
  };

  const handleSendToOtherChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsSendToOtherChecked(e.target.checked);

    if (errors.sendTo) {
      setErrors({ ...errors, sendTo: undefined });
    }
  };

  const handleEmailsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmails(e.target.value);

    if (errors.emails) {
      const emailsArray = e.target.value.split(';').filter((email) => !!email);

      if (emailsArray.length === 0) {
        setErrors({ ...errors, emails: 'Please enter a list of semi-colon-separated emails' });
      } else {
        try {
          emailSchema.validateSync(emailsArray);
          setErrors({ ...errors, emails: undefined });
        } catch (ex) {
          setErrors({ ...errors, emails: 'Please enter emails in the correct format' });
        }
      }
    }
  };

  const handleKeyContactsChange = (keyContacts: string[]) => {
    setSendKeyContactIds(keyContacts);

    if (keyContacts.length === 0) {
      setErrors({ ...errors, keyContacts: 'Please select a contact' });
    } else {
      setErrors({ ...errors, keyContacts: undefined });
    }
  };

  const handleSendInvoiceClick = () => {
    const newErrors: typeof errors = {};

    if (sendInvoice === 'custom' && !customSendInvoiceTime) {
      newErrors.customSendInvoiceTime = 'Please select a date and time to send this invoice';
    }

    if (!isSendToClientChecked && !isSendToKeyContactsChecked && !isSendToOtherChecked && !isSendToFunderChecked) {
      newErrors.sendTo = 'Please select a recipient';
    } else if (isSendToOtherChecked) {
      const emailsArray = emails.split(';').filter((email) => !!email);

      if (emailsArray.length === 0) {
        newErrors.emails = 'Please enter a list of semi-colon-separated emails';
      } else {
        try {
          emailSchema.validateSync(emailsArray);
        } catch (ex) {
          newErrors.emails = 'Please enter emails in the correct format';
        }
      }
    }

    if (isSendToKeyContactsChecked) {
      if (sendKeyContactIds.length === 0) {
        newErrors.keyContacts = 'Please select a contact';
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    } else {
      const shareDetails: {
        sendAt: Date;
        sendClient: boolean;
        sendTo?: string[];
        note?: string;
        sendKeyContactIds?: string[];
      } = {
        sendAt: new Date(),
        sendClient: isSendToClientChecked,
        note,
        ...(isSendToKeyContactsChecked && { sendKeyContactIds })
      };

      if (sendInvoice === 'now') {
        shareDetails.sendAt = moment().subtract(5, 'minute').toDate();
      } else {
        shareDetails.sendAt = moment(customSendInvoiceTime, 'YYYY-MM-DD HH:mm').toDate();
      }

      if (isSendToOtherChecked) {
        shareDetails.sendTo = emails.split(';').filter((email) => !!email);
      }

      if (isSendToFunderChecked && funderEmail) {
        shareDetails.sendTo ? shareDetails.sendTo.push(funderEmail) : (shareDetails.sendTo = [funderEmail]);
      }

      onSendInvoice(shareDetails);
    }
  };

  const allowToSendToFunder = useMemo(() => {
    if (invoice.template?.sendTo) {
      return invoice.template.sendTo.some((item) => item.type === RecipientTypes.Funder);
    }
    return false;
  }, [invoice]);

  const funderEmail = useMemo(() => {
    if (allowToSendToFunder && !isFunderDataFetching && !isFunderDataLoading) {
      return funderData?.contacts?.find((item) => item.isPrimary && item.email)?.email;
    }
    return undefined;
  }, [allowToSendToFunder, funderData?.contacts, isFunderDataFetching, isFunderDataLoading]);

  return (
    <div className={styles.container}>
      <div className={styles.sendInvoiceContainer}>
        <div className={styles.title}>Send {label}</div>
        <Radio
          className={styles.radioContainer}
          options={[
            { value: 'now', label: 'Now' },
            { value: 'custom', label: 'Custom' }
          ]}
          value={sendInvoice}
          vertical
          onChange={(e) => setSendInvoice(e.target.value)}
        />
        {sendInvoice === 'custom' && (
          <>
            <DatePicker
              className="send-invoice-date-picker"
              disabledDate={(current) => current && current < moment().startOf('day')}
              suffixIcon={<i className={`material-icons icon`}>arrow_drop_down</i>}
              showTime={{ format: 'HH:mm', minuteStep: 15 }}
              value={customSendInvoiceTime ? moment(customSendInvoiceTime, 'YYYY-MM-DD HH:mm') : undefined}
              onChange={(e) => handleCustomSendInvoiceTimeChange(e)}
            />
            <ErrorMessage
              className={styles.customSendInvoiceTimeError}
              error={errors.customSendInvoiceTime}
              visible={!!errors.customSendInvoiceTime}
            />
          </>
        )}
      </div>
      <div className={styles.sendToContainer}>
        <div className={styles.title}>Send To</div>
        {!invoice.group && (
          // Clients
          <label className={styles.checkboxContainer} htmlFor="checkbox-sendTo-client">
            <input
              className={styles.checkbox}
              id="checkbox-sendTo-client"
              type="checkbox"
              checked={isSendToClientChecked}
              disabled={!invoice.clientRecord?.allowCommunicationWithEmail}
              onChange={(e) => handleSendToClientChange(e)}
            />
            <div className={styles.text}>
              {t('label.clients.capitalize')}
              {!invoice.clientRecord?.allowCommunicationWithEmail && (
                <HelpOutLineWithTooltips
                  id={'sendToClient'}
                  desc={t('form.disallowed_email_communication.description')}
                />
              )}
            </div>
          </label>
        )}

        {/* Key contacts */}
        <label className={styles.checkboxContainer} htmlFor="checkbox-sendTo-keyContacts">
          <input
            className={styles.checkbox}
            id="checkbox-sendTo-keyContacts"
            type="checkbox"
            checked={isSendToKeyContactsChecked}
            disabled={!keyContacts || keyContacts?.length === 0}
            onChange={(e) => handleSendToKeyContactsChange(e)}
          />
          <div className={styles.text}>
            {participationType === ParticipantType.Group ? 'Select Group Contact' : 'Select Key Contact'}
            {(!keyContacts || keyContacts?.length === 0) && (
              <HelpOutLineWithTooltips
                id={'sendToKeyContact'}
                desc={invoice.group ? t('form.error.missing_group_contact') : t('form.error.missing_key_contact')}
              />
            )}
          </div>
        </label>
        {isSendToKeyContactsChecked && (
          <div className={styles.keyContactWrapper}>
            <SelectKeyContactsBox keyContacts={keyContacts || []} onChangeKeyContacts={handleKeyContactsChange} />
            <ErrorMessage error={errors.keyContacts} visible={!!errors.keyContacts} />
          </div>
        )}

        {/* Funder */}
        {allowToSendToFunder && (
          <>
            <label className={styles.checkboxContainer} htmlFor="checkbox-sendTo-funder">
              <input
                className={styles.checkbox}
                id="checkbox-sendTo-funder"
                type="checkbox"
                checked={isSendToFunderChecked}
                disabled={!funderEmail}
                onChange={(e) => handleSendToFunderChange(e)}
              />
              <div className={styles.text}>Funder</div>
            </label>
            {isSendToFunderChecked && (
              <div className={styles.funderContactWrapper}>
                <div>{funderData && getAddressBookName(funderData)}</div>
                <div className={styles.email}>{funderEmail}</div>
              </div>
            )}
          </>
        )}

        {/* Other */}
        <label className={styles.checkboxContainer} htmlFor="checkbox-sendTo-other">
          <input
            className={styles.checkbox}
            id="checkbox-sendTo-other"
            type="checkbox"
            checked={isSendToOtherChecked}
            onChange={(e) => handleSendToOtherChange(e)}
          />
          <div className={styles.text}>Other</div>
        </label>
        {isSendToOtherChecked && (
          <div className={styles.inputContainer}>
            <MaterialInput id="sendToOther" label="Email address" value={emails} onChange={handleEmailsChange} />
            <ErrorMessage error={errors.emails} visible={!!errors.emails} />
          </div>
        )}
        <ErrorMessage className={styles.sendToError} error={errors.sendTo} visible={!!errors.sendTo} />
      </div>
      <div className={styles.includeNoteContainer}>
        <div className={styles.title}>Include a note</div>
        <div className={styles.noteContent}>
          <textarea
            className={styles.textarea}
            rows={4}
            maxLength={160}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <div className={styles.wordCount}>{note.length} / 160</div>
        </div>
      </div>
      <ButtonAlt
        type="button"
        disabled={invoice.status === InvoiceStatus.WriteOff}
        className={styles.button}
        status={sendInvoiceButtonStatus}
        onClick={handleSendInvoiceClick}
      >
        Send {label}
      </ButtonAlt>
    </div>
  );
};

export default ShareInfoBox;
