import { ClientAssignInterface } from 'pages/AssessmentDetails/MicroCheckInDetails/components/AssessmentsDetailsContent/components/ClientsList/components/ClientAssignList/components/interfaces';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { getMCIClientList } from 'utils/http/CheckInService/MicroCheckIn/mci';
import { useGetAccessToken } from './token';
import { useSearch } from './useSearch';

export const useMCIClientsList = () => {
  const { token } = useGetAccessToken();

  const [clientsList, setClientsList] = useState<ClientAssignInterface[]>([]);
  const [clientsListInitializing, setClientsListInitializing] = useState(true);
  const [hasMoreClients, setHasMoreClients] = useState(false);

  const [page, setPage] = useState(1);
  const { searchTerm, setSearchTerm, debouncedSearchTerm, searching, setSearching, searchActivated } = useSearch({
    page,
    setPage
  });

  const fetchClientsList = async () => {
    const stringifiedQuery = queryString.stringify({
      page,
      perPage: 10,
      q: debouncedSearchTerm
    });
    const query = `?${stringifiedQuery}`;
    const callClientList = await getMCIClientList({ token, query });
    const { clientsList, paging } = (await callClientList.json()) as {
      clientsList: ClientAssignInterface[];
      paging: { page: number; perPage: number; totalItem: number };
    };
    setHasMoreClients(paging.totalItem / paging.perPage > paging.page);
    setPage(page + 1);
    return clientsList;
  };

  const initializeClientsList = async () => {
    setClientsListInitializing(true);
    const clientsList = await fetchClientsList();
    setClientsList(clientsList);
    setClientsListInitializing(false);
  };

  useEffect(() => {
    if (token) {
      initializeClientsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const loadMoreClients = async () => {
    if (hasMoreClients) {
      const moreClients = await fetchClientsList();
      setClientsList([...clientsList, ...moreClients]);
    }
  };

  const searchClients = async () => {
    setSearching(true);
    const clientsList = await fetchClientsList();
    setClientsList(clientsList);
    setSearching(false);
  };

  useEffect(() => {
    if (page === 1 && !searching && (debouncedSearchTerm || searchActivated) && token) {
      searchClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, page, token]);

  return {
    clientsList,
    clientsListInitializing,
    hasMoreClients,
    loadMoreClients,
    searching,
    searchTerm,
    setSearchTerm
  };
};
