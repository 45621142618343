import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Radio from 'components/Radio/Radio';
import { DatePicker, notification } from 'antd';
import moment, { Moment } from 'moment';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import BroadcastClientList from './components/BroadcastClientList/BroadcastClientList';
import Select from 'components/Select/CommonSelect/Select';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { useGetAccessToken } from 'utils/hooks/token';
import { postBroadcast } from 'utils/http/NotifService/Broadcast/broadcast';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import styles from './BroadcastContent.module.scss';
import BroadcastGroupList from './components/BroadcastGroupList/BroadcastGroupList';
import { MinifiedGroup } from 'pages/Groups/Interfaces/Groups';
import { useTranslation } from 'react-i18next';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import { LoadMoreClients } from 'utils/hooks/GetClient/clientList';
import { useAppSelector } from 'redux/hooks';
import { selectSelectedClients } from 'redux/features/messages/broadcastMessageSlice';
import {
  BROADCAST_OPTIONS,
  BROADCAST_SMS_CHARACTER_LIMIT,
  IFormatOption,
  SEND_TIME_OPTIONS,
  SendTime,
  SendToOption
} from './utils';

export type MinifiedGroupWithSelected = MinifiedGroup & {
  isSelected?: boolean;
};

interface BroadcastContentProps {
  clientList: clientRecordsInterface[];
  groupList?: MinifiedGroupWithSelected[];
  hasMoreClients: boolean;
  isLoading: boolean;
  loadingMoreClients?: boolean;
  loadingSearch?: boolean;
  loadMoreClients: LoadMoreClients;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const BroadcastContent = ({
  clientList,
  groupList,
  hasMoreClients,
  isLoading,
  loadingMoreClients,
  loadingSearch,
  loadMoreClients,
  searchTerm,
  setSearchTerm
}: BroadcastContentProps) => {
  const { token } = useGetAccessToken();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const { isBroadcastSmsOptionToAllReadDenied } = useGetPermissionToggle();

  const [t] = useTranslation();
  const selectedClients = useAppSelector(selectSelectedClients);

  const [broadcastFormat, setBroadcastFormat] = useState<IFormatOption>(BROADCAST_OPTIONS[0]);
  const [messageContent, setMessageContent] = useState('');
  const [sendMessageTime, setSendMessageTime] = useState(SendTime.Now);
  const [sendToOption, setSendToOption] = useState<SendToOption>(SendToOption.CustomClient);
  const [customSendMessageTime, setCustomSendMessageTime] = useState('');
  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');
  const [selectedGroup, setSelectedGroup] = useState<MinifiedGroupWithSelected[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<{ customSendTime?: boolean; message?: boolean; sendToList?: boolean }>({});

  const handleSelectFormat = (val: IFormatOption) => {
    setBroadcastFormat(val);
  };

  const onChangeMessage = (val: string) => {
    setMessageContent(val);

    if (isSubmitted) {
      setErrors({
        ...errors,
        message: val.length <= 0
      });
    }
  };

  const handleSelectedGroup = (selectedGroupList: MinifiedGroupWithSelected[]) => {
    setSelectedGroup(selectedGroupList);
    if (isSubmitted) {
      setErrors({ ...errors, sendToList: selectedGroupList.length <= 0 });
    }
  };

  const handleCustomSendTime = (value: Moment | null) => {
    if (value) {
      const customTime = value.format('YYYY-MM-DD HH:mm');
      setCustomSendMessageTime(customTime);

      if (isSubmitted) {
        setErrors({ ...errors, customSendTime: customTime.length <= 0 });
      }
    } else {
      setCustomSendMessageTime('');
    }
  };

  const validation = () => {
    if (
      (sendMessageTime !== SendTime.Now && customSendMessageTime.length <= 0) ||
      messageContent.length <= 0 ||
      (sendToOption === SendToOption.CustomClient && selectedClients.length <= 0) ||
      (sendToOption === SendToOption.CustomGroup && selectedGroup.length <= 0)
    ) {
      setErrors({
        customSendTime: sendMessageTime !== SendTime.Now && customSendMessageTime.length <= 0,
        message: messageContent.length <= 0,
        sendToList:
          (sendToOption === SendToOption.CustomClient && selectedClients.length <= 0) ||
          (sendToOption === SendToOption.CustomGroup && selectedGroup.length <= 0)
      });
      setButtonStatus('');
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (sendToOption === SendToOption.AllClient && hasMoreClients && !loadingMoreClients) {
      loadMoreClients();
    }
  }, [hasMoreClients, loadMoreClients, loadingMoreClients, sendToOption]);

  useEffect(() => {
    if (sendToOption === SendToOption.AllClient && hasMoreClients) {
      setButtonStatus('active');
    } else {
      setButtonStatus('');
    }
  }, [hasMoreClients, sendToOption]);

  const onHandleSubmitMessage = async () => {
    setButtonStatus('active');
    setIsSubmitted(true);
    const clientRecordIds =
      sendToOption === SendToOption.CustomClient
        ? selectedClients.map((obj) => obj._id)
        : sendToOption === SendToOption.AllClient &&
          clientList.filter((clientObj) => clientObj.allowCommunicationWithSms).map((obj) => obj._id);
    const groupIds = sendToOption === SendToOption.CustomGroup && selectedGroup.map((obj) => obj._id);
    const nowTime = moment().format('YYYY-MM-DD HH:mm');
    const sendAtTime = sendMessageTime === SendTime.Now ? nowTime : customSendMessageTime;

    if (validation()) {
      const broadcastPayload = {
        type: broadcastFormat.value,
        message: messageContent,
        ...(clientRecordIds && { clientRecordIds }),
        ...(groupIds && { groupIds }),
        sendAt: moment(sendAtTime).toISOString(),
        sendAsPractice: isEdgeAdminView || isEdgeReceptionist
      };
      try {
        await postBroadcast(token, broadcastPayload);
        setButtonStatus('finished');
        setTimeout(() => {
          setButtonStatus('');
          notification.success({
            message: `Broadcast message ${sendAtTime === nowTime ? 'sent' : 'scheduled'} successfully`,
            duration: 5,
            closeIcon: <span className="success">OK</span>
          });
        }, 1000);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to send/schedule your broadcast message.' });
        setButtonStatus('');
      }
    }
  };

  const getClientAllowCommSmsOnly = clientList.filter((clientObj) => clientObj.allowCommunicationWithSms);

  return isLoading ? (
    <div className={styles.loadingWrapper}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.header}>Create Broadcast Message</div>
      <div>
        <div className={styles.formatContainer}>
          <div className={styles.formatTitle}>
            Please select format
            <HelpOutLineWithTooltips
              id={'selectFormat'}
              desc={
                'Currently we support broadcast messages via SMS but will be adding Email and other channels in the future'
              }
            />
          </div>
          <Select
            className={styles.multipleChoice}
            isSearchable={false}
            options={BROADCAST_OPTIONS}
            placeholder="Please select..."
            defaultValue={broadcastFormat}
            onChange={(val: any) => handleSelectFormat(val)}
            smallCaretDown
            styles={{
              container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
              valueContainer: (base: any) => ({
                ...base,
                minHeight: '30px',
                padding: '0 8px 0 0',
                cursor: 'pointer'
              }),
              control: (controlBase: any) => ({
                ...controlBase,
                minHeight: '30px',
                backgroundColor: 'transparent',
                border: 'none',
                borderBottom: `1px solid ${styles.greyColor}`,
                borderRadius: 0,
                boxShadow: 'none'
              })
            }}
          />
        </div>
        <div className={styles.textAreaContainer}>
          <div className={styles.textAreaLabel}>Message content</div>
          <div className={styles.textAreaWrap}>
            <textarea
              className={styles.textarea}
              rows={4}
              maxLength={BROADCAST_SMS_CHARACTER_LIMIT}
              value={messageContent}
              onChange={(e) => onChangeMessage(e.target.value)}
            />
            <div className={styles.wordCount}>
              <span className={styles.count}>{BROADCAST_SMS_CHARACTER_LIMIT - messageContent.length}</span> characters
              remaining
            </div>
          </div>
          <ErrorMessage visible={!!errors.message} error={'Message content cannot be blank'} />
        </div>
        <div className={styles.sendToContainer}>
          <div className={styles.title}>Send to</div>
          <Radio
            className={styles.radioContainer}
            options={[
              {
                value: SendToOption.AllClient,
                label: t('options.broadcast_send_to.all_client'),
                subLabel:
                  sendToOption === SendToOption.AllClient && hasMoreClients
                    ? `(loading clients data, please wait...)`
                    : `(${t('options.broadcast_send_to.all_client.description', {
                        count: getClientAllowCommSmsOnly.length
                      })})`,
                disabled: isBroadcastSmsOptionToAllReadDenied
              },
              { value: SendToOption.CustomClient, label: t('options.broadcast_send_to.custom_client') },
              ...(groupList
                ? [
                    {
                      value: SendToOption.CustomGroup,
                      label: t('options.broadcast_send_to.custom_group'),
                      disabled: groupList?.length === 0
                    }
                  ]
                : [])
            ]}
            value={sendToOption}
            vertical
            onChange={(e) => setSendToOption(e.target.value as SendToOption)}
          />
          {sendToOption === SendToOption.CustomClient && (
            <>
              <BroadcastClientList
                clients={clientList}
                hasMoreClients={hasMoreClients}
                loadingSearch={loadingSearch}
                loadMoreClients={loadMoreClients}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              <ErrorMessage
                className={styles.errorMargin}
                visible={isSubmitted && selectedClients.length < 1}
                error={t('form.error.broadcast_client')}
              />
            </>
          )}
          {sendToOption === SendToOption.CustomGroup && groupList && (
            <>
              <BroadcastGroupList groups={groupList} onSelectedGroup={handleSelectedGroup} />
              <ErrorMessage
                className={styles.errorMargin}
                visible={!!errors.sendToList}
                error={'Please select at least one group to send broadcast'}
              />
            </>
          )}
        </div>
        <div className={styles.sendDateContainer}>
          <div className={styles.title}>
            When to send
            <HelpOutLineWithTooltips
              id={'whenToSendMessage'}
              desc={"Decide when you'd like this broadcast message to be sent"}
            />
          </div>
          <Radio
            className={styles.radioContainer}
            options={SEND_TIME_OPTIONS}
            value={sendMessageTime}
            vertical
            onChange={(e) => setSendMessageTime(e.target.value as SendTime)}
          />
          {sendMessageTime === SendTime.CustomDate && (
            <>
              <DatePicker
                className="sendMessageDatePicker"
                disabledDate={(current) => current && current < moment().startOf('day')}
                suffixIcon={<i className={`material-icons icon`}>arrow_drop_down</i>}
                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                value={customSendMessageTime ? moment(customSendMessageTime, 'YYYY-MM-DD HH:mm') : undefined}
                onChange={(e) => handleCustomSendTime(e)}
              />
              <ErrorMessage
                className={styles.errorMargin}
                visible={!!errors.customSendTime}
                error={'Please select a date and time to send this broadcast'}
              />
            </>
          )}
        </div>
        <div className={styles.buttonWrapper}>
          <ButtonAlt
            disabled={buttonStatus !== ''}
            className={styles.sendBtnFixed}
            status={buttonStatus}
            onClick={onHandleSubmitMessage}
          >
            Send Broadcast
          </ButtonAlt>
        </div>
      </div>
    </div>
  );
};

export default BroadcastContent;
