import styles from './BookablePractitioner.module.scss';
import { AppointmentType, AssignmentMode } from 'interfaces/Schedule/AppointmentType';

interface BookablePractitionerProps {
  assignmentMode: AppointmentType['assignmentMode'];
}

const BookablePractitioner = ({ assignmentMode }: BookablePractitionerProps) => {
  const getLabel = () => {
    switch (assignmentMode) {
      case AssignmentMode.All:
        return 'All Practitioners';
      case AssignmentMode.Selected:
        return 'Selected Practitioners';
      case AssignmentMode.Practice:
        return 'Practice Only';
      case AssignmentMode.AllMentors:
        return 'All Mentors';
      case AssignmentMode.AutoAssign:
        return 'Auto Assign';
      case AssignmentMode.Single:
        return 'Single Practitioner';
      default:
        return 'All Practitioners';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>BOOKABLE WITH</div>
      <div className={styles.bookablePractitioners}>{getLabel()}</div>
    </div>
  );
};

export default BookablePractitioner;
