import { useAuth0 } from '@auth0/auth0-react';
import { UserViewEnum } from 'utils/providers/ClientListColumnsProvider/constants';

export const getPermissionFromPath = () => {
  let firstPath = window.location.pathname.split('/')[1];
  if (firstPath === 't23') {
    firstPath = window.location.pathname.split('/')[2];
  }
  const edgeAdminPath = firstPath === 'admin';
  const edgeUserPath = firstPath === 'user';
  const edgeReceptionistPath = firstPath === 'receptionist';
  const edgeMentorPath = firstPath === 'mentor';

  // for client-lists columns settings usage
  const currentView = edgeAdminPath
    ? UserViewEnum.EDGE_ADMIN_VIEW
    : edgeReceptionistPath
    ? UserViewEnum.EDGE_RECEPTIONIST_VIEW
    : edgeUserPath
    ? UserViewEnum.EDGE_USER_VIEW
    : UserViewEnum.PIONEER_VIEW;

  return { edgeAdminPath, edgeUserPath, edgeReceptionistPath, edgeMentorPath, firstPath, currentView };
};

export const useGetAccountPackageView = () => {
  const { user } = useAuth0();
  const userAccessConfig = user?.['https://tacklit.com/accessConfig'];
  const isEdgeAdminUser = userAccessConfig?.plan === 'edge' && userAccessConfig?.accessRight === 'admin';
  const isEdgeUser = userAccessConfig?.plan === 'edge';
  const isDefaultUser = userAccessConfig?.plan !== 'edge';
  const isEdgeReceptionist = userAccessConfig?.plan === 'edge' && userAccessConfig?.accessRight === 'receptionist';
  const isMentorUser = userAccessConfig?.plan === 'edge' && userAccessConfig?.accessRight === 'mentor';
  const isEdgePractitioner = userAccessConfig?.plan === 'edge' && userAccessConfig?.accessRight === 'user';

  const { edgeAdminPath, edgeUserPath, edgeReceptionistPath, edgeMentorPath, firstPath, currentView } =
    getPermissionFromPath();

  const isEdgeAdminView = isEdgeAdminUser && edgeAdminPath;
  const isEdgeUserView = isEdgeUser && edgeUserPath;
  const isNormalUserView = isDefaultUser; // pioneer view
  const isEdgeReceptionistView = isEdgeReceptionist && edgeReceptionistPath;
  const isEdgeUserMentorView = isMentorUser && edgeMentorPath;

  const isEdgeAdminOrReceptionistView = isEdgeAdminView || isEdgeReceptionist;

  return {
    isEdgeAdminView,
    isEdgeAdminOrReceptionistView,
    isEdgeUserView,
    isEdgeUser,
    isEdgeReceptionist,
    isEdgeAdminUser,
    isNormalUserView,
    isEdgeReceptionistView,
    isMentorUser,
    isEdgeUserMentorView,
    isEdgePractitioner,
    firstPath,
    currentView
  };
};
