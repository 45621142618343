import { EpisodeInterface } from '../Episodes/episodes';
import { AppointmentType } from '../Schedule/AppointmentType';

export enum PackagesStatus {
  Active = 'active',
  Draft = 'draft',
  OverQuota = 'overQuota',
  QuotaFull = 'quotaFull'
}

export const PACKAGES_STATUS_LABELS: Record<PackagesStatus, string> = {
  active: 'Active',
  draft: 'Draft',
  overQuota: 'Over Quota',
  quotaFull: 'Quota Full'
};

export enum PackagesType {
  Core = 'core',
  Extension = 'extension',
  EAP = 'eap',
  Program = 'program',
  Virtual = 'virtual',
  InPatient = 'inPatient',
  OutPatient = 'outPatient',
  Community = 'community',
  Other = 'other'
}

export const PACKAGES_TYPE_LABELS: Record<PackagesType, string> = {
  core: 'Core',
  extension: 'Extension',
  eap: 'EAP',
  program: 'Program',
  virtual: 'Virtual',
  inPatient: 'In Patient',
  outPatient: 'Out Patient',
  community: 'Community',
  other: 'Other'
};

export const generatePackageType = () =>
  Object.values(PackagesType).map((statusObj) => ({
    label: PACKAGES_TYPE_LABELS[statusObj].toUpperCase(),
    value: statusObj
  }));

export enum PackagesServiceSequence {
  Any = 'any',
  Sequence = 'sequence'
}

export const PACKAGES_SEQUENCE_LABELS: Record<PackagesServiceSequence, string> = {
  any: 'Any order',
  sequence: 'Defined sequence'
};

export enum PackagesBenefit {
  Client = 'client',
  Funder = 'funder'
}

export enum FunderLimitType {
  Uniform = 'uniform',
  PerFunder = 'perFunder'
}

export enum PackagesBenefitLimit {
  None = 'none',
  TotalServices = 'totalServices',
  DollarSpent = 'dollarSpent',
  NumberOfClients = 'numberOfClients'
}

export enum BillPayer {
  EachClient = 'eachClient',
  GroupByFunder = 'groupByFunder'
}

export interface benefitControlLimitList extends Omit<PackageFunderInterface, '_id' | 'name'> {
  limit: string | number;
  name?: string;
}

export enum funderType {
  Default = 'default',
  AddressBook = 'addressBook'
}

export enum PackageFunderQuotaStatus {
  Available = 'available',
  Full = 'full',
  Over = 'over'
}

export interface PackageFunderInterface {
  type: funderType;
  funderId?: string;
  name: string;
  quotaStatus?: PackageFunderQuotaStatus;
}

export interface PackagesInterface {
  _id?: string;
  status: PackagesStatus;
  type: PackagesType | undefined;
  name: string;
  description: string;
  billingCode: string;
  funders: PackageFunderInterface[];
  serviceAccessOrder: PackagesServiceSequence;
  appointmentTypeIds: string[];
  appointmentTypes?: PackageAppointmentType[];
  benefitControl: {
    by: PackagesBenefit;
    limitMode: PackagesBenefitLimit;
    funderLimitType?: FunderLimitType;
    limit?: string | number;
    perFunderLimit?: benefitControlLimitList[];
  };
  fee?: string;
  feeChecked?: boolean;
}

export interface AssignPackagesResponseInterface {
  assignmentId: string;
  failedToAssignReason?: string;
  funder: AssignPackageFunder;
  packageId: string;
  successfullyAssigned: boolean;
}

export interface CreatePackageFunderInterface extends Omit<PackageFunderInterface, 'name'> {
  name?: string;
}

export interface CreateUpdatePackagesInterface extends Omit<PackagesInterface, 'funders'> {
  funders: CreatePackageFunderInterface[];
}

export interface AssignPackageFunder extends Partial<PackageFunderInterface> {}

export interface AssignPackagesInterface {
  packageId: string;
  funder: AssignPackageFunder;
  episodeId?: string;
  referralId?: string;
}

interface PackageAppointmentType extends AppointmentType {
  bookedAppointment?: {
    _id: string;
    markedStatus?: string[];
    serviceDelivered?: string;
    date?: string;
  };
  isUtilised?: boolean;
  reason?: string;
  isDone?: boolean;
}

export interface GetPackageAssignmentList extends Omit<PackagesInterface, 'appointmentTypes'> {
  assignee: {
    _id: string;
    clientRecordId: string;
    createdAt: string;
    funder: PackageFunderInterface;
    updatedAt: string;
    utilisations: UtilisationPayloadInterface[];
    referralId?: string;
  };
  appointmentTypes: PackageAppointmentType[];
  episode?: EpisodeInterface;
}

export interface UtilisationPayloadInterface {
  appointmentTypeId: string;
  appointmentId?: string;
}
