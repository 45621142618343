import { useEffect, useState } from 'react';
import classnames from 'classnames';

import { CaseNote, SelectInterface } from 'interfaces/caseNote';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Select from 'components/Select/CommonSelect/Select';
import MaterialLabel from 'components/MaterialLabel/MaterialLabel';

import styles from './MultipleChoiceWrapper.module.scss';
import { toCamelCase } from 'utils/generateCamelCase';

interface MultipleChoiceWrapperProps {
  error?: boolean;
  field: CaseNote['fields'][number];
  isValidated: boolean;
  className?: string;
  isDisabled?: boolean;
  placeholder?: string;
  menuPortalTarget?: string;
  onChangeFieldInput: (id: string, value: any) => void;
}

const MultipleChoiceWrapper = ({
  error,
  field,
  isValidated,
  className,
  isDisabled,
  placeholder,
  menuPortalTarget,
  onChangeFieldInput
}: MultipleChoiceWrapperProps) => {
  const minimisedLabel = field.value?.length > 0 || !!field.value || !!placeholder;
  const [fieldData, setFieldData] = useState({} as CaseNote['fields'][number]);
  const [isLabelMinimised, setIsLabelMinimised] = useState(minimisedLabel);

  useEffect(() => {
    setFieldData(field);
    setIsLabelMinimised(minimisedLabel);
  }, [field, minimisedLabel]);

  const handleChange = (val: any) => {
    onChangeFieldInput(fieldData._id, val);
    setIsLabelMinimised(val?.length > 0 || !!val);
  };

  const handleFocus = () => {
    setIsLabelMinimised(true);
  };

  const handleBlur = () => {
    setIsLabelMinimised(
      !placeholder ? (fieldData.multiple && fieldData.value ? fieldData.value.length > 0 : fieldData.value) : true
    );
  };

  const formatOptionLabel = ({ value, label }: SelectInterface) => (
    <div style={{ display: 'flex' }}>
      <div id={toCamelCase(value)}>{label}</div>
    </div>
  );

  return (
    <div className={classnames(styles.headingField, fieldData.multiple && styles.multipleSelectField, className)}>
      <MaterialLabel
        error={error}
        label={`${fieldData.name} ${fieldData.mandatory ? '*' : ''}`}
        isLabelMinimised={isLabelMinimised}
      />
      <Select
        id={toCamelCase(fieldData.name)}
        inputId={fieldData.name}
        isMulti={fieldData.multiple}
        closeMenuOnSelect={!fieldData.multiple}
        className={styles.multipleChoice}
        isSearchable={false}
        options={fieldData.options}
        formatOptionLabel={formatOptionLabel}
        placeholder={placeholder || ''}
        labelClass={styles.label}
        value={fieldData.value ? fieldData.value : fieldData.multiple ? [] : ''}
        onChange={handleChange}
        onFocus={() => handleFocus()}
        onBlur={() => handleBlur()}
        menuPortalTarget={menuPortalTarget && document.querySelector(menuPortalTarget)}
        menuPlacement={'auto'}
        smallCaretDown
        styles={{
          container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
          valueContainer: (base: any) => ({
            ...base,
            minHeight: '30px',
            padding: '0 8px 0 0'
          }),
          control: (controlBase: any) => ({
            ...controlBase,
            minHeight: '30px',
            backgroundColor: 'transparent',
            border: 'none',
            borderBottom: `1px solid ${styles.greyColor}`,
            borderRadius: 0,
            boxShadow: 'none'
          }),
          placeholder: (placeholderBase: any) => ({
            ...placeholderBase,
            color: '#00000040'
          })
        }}
        isDisabled={isDisabled}
      />
      <ErrorMessage
        className={styles.error}
        error={'Please select at least one item'}
        visible={isValidated && fieldData.mandatory && !fieldData.value}
      />
    </div>
  );
};

export default MultipleChoiceWrapper;
