import styles from './LetterSideBar.module.scss';
import { useMemo } from 'react';
import SelectClientOrGroup from 'components/SelectClientOrGroup/SelectClientOrGroup';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import Select from 'components/v2/Select/Select';
import Label from 'pages/Report/components/Label/Label';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Status from 'pages/Report/components/Status/Status';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { Letter, LetterAccessor, PutLetterRequest } from 'interfaces/Letters/letter';
import { LetterTemplate, LetterTemplateStatus } from 'interfaces/Letters/letterTemplate';
import { ClientRecordType, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ShareTab from 'pages/Report/components/ShareTab/ShareTab';
import SharedPerson from 'pages/Report/components/SharedPerson/SharedPerson';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import EpisodeTaggingSection from 'components/EpisodeTaggingSection/EpisodeTaggingSection';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import classNames from 'classnames';

interface LetterSideBarProps {
  selectedClientRecordId?: string;
  letterName: string;
  letterTemplateList?: LetterTemplate[];
  publishBtnStatus: ButtonStatusType;
  isNewLetter: boolean;
  clientProfiles?: Letter['clientRecord']['clientProfiles'];
  clientAllowCommunicationWithEmail?: boolean;
  recordType?: string;
  letterId?: string;
  status: string;
  sharedList: LetterAccessor[];
  letterTemplateId?: string;
  isLoadingLetterTemplateList: boolean;
  isClientRecordInvalid: boolean;
  isLetterNameInvalid: boolean;
  isReadOnly: boolean;
  isLoadingRenderContent: boolean;
  isAuthor: boolean;
  episodeId?: string;
  handleChangeTemplate: (id?: string) => void;
  handlePublishBtn: () => void;
  handleUnpublishBtn: () => void;
  handleDeleteBtn: () => void;
  handleRevokeAccess: (accessorId: string, name?: string) => void;
  setSharedList: (sharedList: LetterAccessor[]) => void;
  selectClient: (record?: clientRecordsInterface) => void;
  updateData: (data: Partial<PutLetterRequest>, validate?: boolean) => void;
  isChangingClientOrTemplateDisabled: boolean;
}

const LetterSideBar = ({
  selectedClientRecordId,
  letterName,
  letterTemplateList,
  publishBtnStatus,
  isNewLetter,
  clientProfiles,
  clientAllowCommunicationWithEmail,
  recordType,
  letterId,
  status,
  sharedList,
  letterTemplateId,
  isLoadingLetterTemplateList,
  isClientRecordInvalid,
  isLetterNameInvalid,
  isReadOnly,
  isLoadingRenderContent,
  isAuthor,
  episodeId,
  handleChangeTemplate,
  handlePublishBtn,
  handleUnpublishBtn,
  handleDeleteBtn,
  handleRevokeAccess,
  setSharedList,
  selectClient,
  updateData,
  isChangingClientOrTemplateDisabled
}: LetterSideBarProps) => {
  const navigate = useNavigate();
  const { isEoCEnabled } = useGetFeatureToggle();
  const { CLIENTS } = useRoutesGenerator();

  const letterTemplateListOptions = useMemo(
    () =>
      letterTemplateList
        ?.filter((letterTemplate) => letterTemplate.status === LetterTemplateStatus.Published)
        .map((letterTemplate) => ({
          value: letterTemplate._id,
          label: letterTemplate.title
        })),
    [letterTemplateList]
  );

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <Label label="LETTER SUBJECT" />
        {clientProfiles && (
          <div
            className={classNames(isReadOnly && styles.clientAvatarContainer)}
            onClick={isReadOnly ? () => navigate(`${CLIENTS.BASE}/${selectedClientRecordId}/profile`) : undefined}
          >
            <ClientAvatar
              clientData={clientProfiles}
              avatarSize={40}
              initialsClassName={styles.initialName}
              nameClassName={styles.clientName}
              horizontal
              displayFirstNameOnly={recordType === ClientRecordType.Couple}
              displayLimit={recordType === ClientRecordType.Couple ? 2 : 1}
              fullAvatarBg
            />
          </div>
        )}
        {!isReadOnly && isNewLetter && (
          <div>
            <SelectClientOrGroup
              defaultParticipationType={ParticipantType.OneToOne}
              hideAddNewClientButton
              handleSelectClientRecord={selectClient}
              defaultRecordId={selectedClientRecordId}
              scrollContainerClassName={styles.selectClientScrollContainer}
              disabled={isChangingClientOrTemplateDisabled}
              hideAvatar
            />
            {isClientRecordInvalid && <div className={styles.error}>Please select a client record</div>}
          </div>
        )}
        {isEoCEnabled && (
          <EpisodeTaggingSection
            readOnly={isReadOnly}
            selectedClientRecordId={selectedClientRecordId || ''}
            selectedEpisode={episodeId}
            onSelectEpisode={(value) => updateData({ episodeId: value })}
          />
        )}
        {!isReadOnly && (
          <Select
            label="Template"
            options={letterTemplateListOptions}
            isLoading={isLoadingLetterTemplateList}
            value={letterTemplateListOptions?.find((option) => option.value === letterTemplateId)}
            onChange={(option) => handleChangeTemplate(option?.value)}
            isDisabled={isChangingClientOrTemplateDisabled}
            styles={{
              control: (controlBase: any) => ({
                ...controlBase,
                minHeight: '24px',
                backgroundColor: 'transparent',
                border: 'none',
                borderBottom: `1px solid ${styles.greyColor} `,
                borderRadius: 0,
                boxShadow: 'none',
                cursor: 'pointer'
              }),
              valueContainer: (base) => ({ ...base, paddingLeft: 0 }),
              indicatorSeparator: (base) => ({ ...base, display: 'none' })
            }}
          />
        )}
        <div className={styles.inputContainer}>
          {isReadOnly ? (
            <div className={styles.titleContainer}>
              <Label label={'TITLE'} />
              <div>{letterName}</div>
            </div>
          ) : (
            <>
              <MaterialInput
                id={'title'}
                label={'Title'}
                value={letterName}
                onChange={(e) => updateData({ letterName: e.target.value })}
                maxLength={100}
                required
              />
              {isLetterNameInvalid && <div className={styles.error}>Title cannot be blank.</div>}
            </>
          )}
        </div>
        <div className={styles.statusContainer}>
          <Label label={'STATUS'} />
          <Status status={status} />
          {sharedList.length > 0 && <Status status={'shared'} />}
        </div>
        {sharedList.length > 0 && (
          <div>
            <Label label={'SHARED TO'} />
            <div className={styles.shareDetailsContainer}>
              {sharedList.map((shared) => (
                <SharedPerson
                  key={shared._id}
                  details={shared}
                  clientProfile={clientProfiles?.[0]}
                  onClickRevokeAccess={handleRevokeAccess}
                />
              ))}
            </div>
          </div>
        )}
        {!isNewLetter && isReadOnly && isAuthor && (
          <ButtonAlt variant="text" error fullWidth onClick={handleUnpublishBtn}>
            Unpublish this letter
          </ButtonAlt>
        )}
        {!isReadOnly && (
          <ButtonAlt fullWidth onClick={handlePublishBtn} status={publishBtnStatus}>
            Publish Letter
          </ButtonAlt>
        )}
        {!isNewLetter && !isReadOnly && (
          <ButtonAlt variant="text" error fullWidth onClick={handleDeleteBtn}>
            Delete this letter
          </ButtonAlt>
        )}
      </div>
      {!isNewLetter && (
        <div className={styles.section}>
          <ShareTab
            visible
            type="letter"
            isClientShared={false}
            data={{
              _id: letterId || '',
              status,
              clientRecord: { allowCommunicationWithEmail: clientAllowCommunicationWithEmail }
            }}
            onChangeSharedList={setSharedList}
          />
        </div>
      )}
    </div>
  );
};

export default LetterSideBar;
