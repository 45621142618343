import * as yup from 'yup';

export const PRONOUN_OPTIONS = [
  { label: 'He / Him', value: 'heHim' },
  { label: 'She / Her', value: 'sheHer' },
  { label: 'They / Them', value: 'theyThem' }
];

export const profileSchema = yup.object().shape({
  avatar: yup.string().nullable(),
  email: yup.string().required('Please enter your email address'),
  mobileNumber: yup
    .string()
    .min(11, 'Mobile number must be 10 digits or longer')
    .required('Please enter your mobile phone number'),
  name: yup.string().required('Please enter your preferred name'),
  titleValue: yup.string().when('title', {
    is: (title: string) => title === 'Other',
    then: yup.string().required('Please tell us how we should address you'),
    otherwise: yup.string()
  }),
  pronouns: yup.string().nullable()
});
