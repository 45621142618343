import classNames from 'classnames';
import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';
import {
  Claim,
  ClaimStatus
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';
import styles from './SubmittedClaimItem.module.scss';
import columnStyles from '../SubmittedClaimListHeader/SubmittedClaimColumn.module.scss';
import SubmittedClaimItemMenu from '../SubmittedClaimItemMenu/SubmittedClaimItemMenu';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import moment from 'moment';
import { useAppDispatch } from 'redux/hooks';
import {
  setIsClaimStatementModalVisible,
  setIsCreateClaimModalVisible,
  setIsShowWriteOffModal,
  setSelectedClaim
} from 'redux/features/submittedClaims/submittedClaimSlice';
import { combineName } from 'utils/general';
import { ClaimTypeNameMapping, SubmittedClaim } from 'interfaces/invoices/submittedClaim';
import GroupsSection from 'pages/Client/components/ClientListContent/components/ClientList/components/ClientItem/components/GroupsSection/GroupsSection';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import { useState } from 'react';
import SubmittedClaimHistory from '../SubmittedClaimHistory/SubmittedClaimHistory';
import { useFetchClaimHistory } from './hooks/useFetchClaimHistory';

interface SubmittedClaimItemProps {
  claim: SubmittedClaim;
  token: string;
  onAbandon?: () => void;
}

interface ClaimStatusInfo {
  statusIcon: string;
  statusText: string;
  statusClassName: string;
}

export const getStatusInfo = (status: ClaimStatus): ClaimStatusInfo => {
  switch (status) {
    case ClaimStatus.Complete:
      return {
        statusIcon: 'check_circle',
        statusText: 'Completed',
        statusClassName: styles.statusSuccess
      };
    case ClaimStatus.MedicareAssessed:
    case ClaimStatus.WithMedicare:
      return {
        statusIcon: 'check_circle',
        statusText: 'Success',
        statusClassName: styles.statusSuccess
      };
    case ClaimStatus.MedicareRejected:
    case ClaimStatus.Rejected:
      return {
        statusIcon: 'cancel',
        statusText: 'Rejected',
        statusClassName: styles.statusRejected
      };
    case ClaimStatus.Expired:
      return {
        statusIcon: 'warning',
        statusText: 'Expired',
        statusClassName: styles.statusExpired
      };
    case ClaimStatus.MedicarePendable:
      return {
        statusIcon: 'hourglass_top',
        statusText: 'Pendable',
        statusClassName: styles.statusPendable
      };
    case ClaimStatus.MedicarePended:
      return {
        statusIcon: 'hourglass_top',
        statusText: 'Pended',
        statusClassName: styles.statusPended
      };
    case ClaimStatus.Processed:
      return {
        statusIcon: 'done',
        statusText: 'Processed',
        statusClassName: styles.statusPended
      };
    case ClaimStatus.WriteOff:
      return {
        statusIcon: 'money_off',
        statusText: 'Write Off',
        statusClassName: styles.statusWriteOff
      };

    default:
      console.error(`Failed to get claim status icon for status [${status}]`);
      return {
        statusIcon: '',
        statusText: '',
        statusClassName: styles.statusRejected
      };
  }
};

const SubmittedClaimItem = ({ claim, token, onAbandon }: SubmittedClaimItemProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();

  const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);

  const { claimHistory, isClaimHistoryLoading, refetchClaimHistory } = useFetchClaimHistory(token, claim._id);

  const {
    claimId,
    claimant,
    status,
    createdAt,
    mbsCode,
    benefit,
    medicareClaimId,
    foundClientRecord,
    foundClinician,
    groups
  } = claim;

  const onResubmit = () => {
    dispatch(setSelectedClaim(claim));
    dispatch(setIsCreateClaimModalVisible(true));
  };

  const onSendDB4Form = () => {
    console.log('onSendDB4Form');
  };

  const handleClickShowHistory = () => {
    const newIsHistoryExpanded = !isHistoryExpanded;

    setIsHistoryExpanded(newIsHistoryExpanded);

    if (newIsHistoryExpanded && !claimHistory) {
      refetchClaimHistory();
    }
  };

  const onSelectClaim = (selectedClaim: Claim) => () => {
    // this function is also used for claim history, which is based on the same invoice
    dispatch(setSelectedClaim({ ...selectedClaim, foundInvoice: claim.foundInvoice }));
    dispatch(setIsClaimStatementModalVisible(true));
  };

  const onViewClientProfile = () => {
    if (claimant.clientRecordId) {
      navigate(`${CLIENTS.BASE}/${claimant.clientRecordId}/profile`);
    }
  };

  const onWriteOff = () => {
    dispatch(setSelectedClaim(claim));
    dispatch(setIsShowWriteOffModal(true));
  };

  const { statusIcon, statusText, statusClassName } = getStatusInfo(status);
  const isSuccessClaim = [ClaimStatus.Complete, ClaimStatus.MedicareAssessed, ClaimStatus.WithMedicare].includes(
    status
  );

  return (
    <div className={styles.container}>
      <div className={styles.claimItem}>
        <div className={columnStyles.client}>
          <div>{foundClientRecord && combineName(foundClientRecord.clientProfiles)}</div>
          <div className={styles.caseId}>{foundClientRecord?.caseId}</div>
        </div>
        <div className={columnStyles.practitioner}>
          {foundClinician ? (
            <ClinicianAvatar name={foundClinician.name} avatarUrl={foundClinician.avatar} avatarSize={50} />
          ) : (
            '-'
          )}
        </div>
        <div className={columnStyles.groups}>
          <GroupsSection groups={groups || []} />
        </div>
        <div className={columnStyles.claimId}>{claimId}</div>
        <div className={columnStyles.date}>{moment(createdAt).format('DD/MM/YYYY')}</div>
        <div className={columnStyles.claimType}>
          <div className={styles.pill}>{ClaimTypeNameMapping[claim.type]}</div>
        </div>
        <div className={columnStyles.claimCode}>{mbsCode}</div>
        <div className={classNames(columnStyles.status, styles.status)}>
          <div className={classNames(styles.statusWrapper, statusClassName)}>
            <i className={'material-icons'}>{statusIcon}</i>
            {statusText}
          </div>
        </div>
        <div className={classNames(columnStyles.reference, styles.reference)}>{medicareClaimId || ''}</div>
        <div className={classNames(columnStyles.claimAmount, styles.claimAmount)}>{`$${benefit.toFixed(2)}`}</div>
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          className={columnStyles.actions}
        >
          {isSuccessClaim && (
            <ButtonAlt
              variant={'text'}
              icon={'link'}
              iconVariant={IconVariant.Filled}
              size={'small'}
              fab
              disabled={isHistoryExpanded && !claimHistory && isClaimHistoryLoading}
              onClick={handleClickShowHistory}
            />
          )}
          <SubmittedClaimItemMenu
            claim={claim}
            onResubmit={onResubmit}
            onSendDB4Form={onSendDB4Form}
            onViewClientProfile={onViewClientProfile}
            onWriteOff={onWriteOff}
            onAbandon={onAbandon}
          />
          <ButtonAlt variant={'outlined'} icon={'open_in_browser'} size={'medium'} fab onClick={onSelectClaim(claim)} />
        </div>
      </div>
      {isSuccessClaim && (
        <SubmittedClaimHistory
          claimHistory={claimHistory}
          isExpanded={isHistoryExpanded}
          isLoading={isClaimHistoryLoading}
          onSelectClaim={onSelectClaim}
        />
      )}
    </div>
  );
};

export default SubmittedClaimItem;
