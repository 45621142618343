import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';

export interface GetPractitionerListRequestParams {
  page: number;
  perPage: number;
  status: string;
  searchValue?: string;
  accessRights?: AccessRight[];
}

export interface PractitionersListMinified {
  _id: string;
  avatar: string;
  name: string;
  title: string;
}

export interface PractitionersListMinifiedResponse {
  clinicians: PractitionersListMinified[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export interface GetPractitionerDetailsListRequestParams {
  status: string;
  withAccessRights: AccessRight[];
}

export interface PractitionerDetailsListResponse {
  practitionerList: PractitionersDetailsInterface[];
  paging: {
    totalItems: number;
  };
}

export const practitionerApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPractitionerList: builder.query<
      PractitionersListMinifiedResponse,
      { accountId: string; params: GetPractitionerListRequestParams; id?: string }
    >({
      query: ({ accountId, params }) => ({
        url: `/accounts/${accountId}/clinicians/minified`,
        params
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => `${endpointName}${queryArgs?.id ? `_${queryArgs?.id}` : ''}`,
      merge: (currentCache, newItems, meta) => ({
        clinicians:
          meta.arg.params.page === 1
            ? newItems.clinicians
            : [
                ...currentCache.clinicians,
                ...newItems.clinicians.filter(
                  (item) => !currentCache.clinicians.map(({ _id }) => _id).includes(item._id)
                )
              ].sort((a, b) => {
                const nameA = a.name;
                const nameB = b.name;
                return nameA.localeCompare(nameB);
              }),
        paging: newItems.paging
      }),
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
      providesTags: (_result, _error, arg) => [
        { type: CPSTagTypes.PractitionerListMinified, searchValue: arg.params.searchValue }
      ]
    }),
    getPractitionerDetailsList: builder.query<
      PractitionerDetailsListResponse,
      { accountId: string; params: GetPractitionerDetailsListRequestParams }
    >({
      query: ({ accountId, params }) => ({
        url: `/accounts/${accountId}/clinicians`,
        params
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName
    })
  })
});

export const { useGetPractitionerListQuery, useGetPractitionerDetailsListQuery } = practitionerApiSlice;
