import { useState, useEffect, useRef } from 'react';
import { notification, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';

import { useGetAccessToken } from 'utils/hooks/token';
import { getInvoiceSettingsPaymentMethodsReady } from 'utils/http/BillingService/Invoice/invoice';

import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import PatientDetailsContent, { DataRefreshHandle } from './components/PatientDetailsContent/PatientDetailsContent';
import PatientDetailsHeader from './components/PatientDetailsHeader/PatientDetailsHeader';
import SimpleNote from './components/SimpleNote/SimpleNote';

import styles from './PatientDetails.module.scss';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { RecordStatus } from 'interfaces/Clients/clientsRecord';
import {
  clientRecordsApiSlice,
  useGetClientEncryptedDataByClientRecordIdQuery,
  useUnpinNoteMutation
} from 'redux/endpoints/clinicianProfileServices/client';
import { useGetNotificationSettingQuery } from 'redux/endpoints/clinicianProfileServices/notificationSetting';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';
import PatientDetailsPinnedNotes from './components/PatientDetailsPinnedNotes/PatientDetailsPinnedNotes';
import { useDispatch } from 'react-redux';
import { CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';

const useFetchInvoiceSettingsPaymentMethodsReady = (token: string) => {
  const [invoiceSettingsPaymentMethodsReady, setInvoiceSettingsPaymentMethodsReady] = useState<boolean>(false);
  const [isInvoiceSettingsPaymentMethodsReadyLoading, setIsInvoiceSettingsPaymentMethodsReadyLoading] = useState(true);
  const { isEdgeAdminView, isNormalUserView, isEdgeReceptionist } = useGetAccountPackageView();
  const fetchInvoiceSettingsPaymentMethodsReady = async (token: string) => {
    try {
      if (isEdgeAdminView || isNormalUserView || isEdgeReceptionist) {
        const callInvoiceSettingsPaymentMethodsReady = await getInvoiceSettingsPaymentMethodsReady(token);

        const { ready } = await callInvoiceSettingsPaymentMethodsReady.json();

        setInvoiceSettingsPaymentMethodsReady(ready);
      }
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get your payment methods status.' });
    }

    setIsInvoiceSettingsPaymentMethodsReadyLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchInvoiceSettingsPaymentMethodsReady(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { invoiceSettingsPaymentMethodsReady, isInvoiceSettingsPaymentMethodsReadyLoading };
};

const PatientDetails = () => {
  const path = useParams<{ recordId: string; patientTab: string; assessmentOrReportId?: string }>();

  const dispatch = useDispatch();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const [unpinNote] = useUnpinNoteMutation();
  const dataRefreshRef = useRef<DataRefreshHandle>(null);

  const { data: notificationSettingsResponses, isLoading: isNotificationSettingLoading } =
    useGetNotificationSettingQuery({
      accountId
    });

  const [isOnboardingModalVisible, setIsOnboardingModalVisible] = useState(false);

  const {
    data: clientRecordData,
    isLoading: isClientRecordLoading,
    isFetching: isFetchClientRecord
  } = useGetClientEncryptedDataByClientRecordIdQuery({
    clientRecordId: path.recordId ?? '',
    includePinnedContent: true
  });
  const { isEdgeAdminView, isNormalUserView, isEdgeReceptionist } = useGetAccountPackageView();

  const { invoiceSettingsPaymentMethodsReady, isInvoiceSettingsPaymentMethodsReadyLoading } =
    useFetchInvoiceSettingsPaymentMethodsReady(token);

  const [refreshActivity, setRefreshActivity] = useState(0);

  const handleUnpinNote = async (noteId: string) => {
    if (!clientRecordData) {
      notification.error({ message: 'Something went wrong while trying to unpin note.' });
      return;
    }
    try {
      notification.warning({
        message: 'Unpinning note...',
        closeIcon: <span className={'notify'}>OK</span>
      });

      await unpinNote({ clientRecordId: clientRecordData._id, noteId }).unwrap();
      dispatch(clientRecordsApiSlice.util.invalidateTags([CPSTagTypes.ClientEncrypted, CPSTagTypes.ClientList]));

      notification.destroy();
      notification.success({
        message: 'Successfully unpinned note.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to unpin note.' });
    }
  };

  const activePinnedNotes = clientRecordData?.activePinnedNotes
    ? [...clientRecordData.activePinnedNotes].reverse()
    : [];

  return (
    <HelmetWrapper title={'Tacklit - Client Details'}>
      <ContentLayout className={styles.container}>
        {clientRecordData && (
          <div className={styles.container}>
            {!isClientRecordLoading && (
              <div className={styles.pinnedNotes}>
                {activePinnedNotes.map((note) => (
                  <PatientDetailsPinnedNotes key={note.noteId} note={note} onUnpin={handleUnpinNote} />
                ))}
              </div>
            )}
            <PatientDetailsHeader
              currentTab={path.patientTab ?? ''}
              clientRecordData={clientRecordData}
              invoiceSettingsPaymentMethodsReady={invoiceSettingsPaymentMethodsReady}
              isInvoiceSettingsPaymentMethodsReadyLoading={isInvoiceSettingsPaymentMethodsReadyLoading}
              isClientRecordLoading={isClientRecordLoading}
              allowCreateNewInvoice={isEdgeAdminView || isNormalUserView || isEdgeReceptionist}
              notificationSettings={notificationSettingsResponses ?? ({} as NotificationSettingsInterface)}
              isOnboardingModalVisible={isOnboardingModalVisible}
              setIsOnboardingModalVisible={setIsOnboardingModalVisible}
              onDataRefresh={() => {
                dataRefreshRef.current?.onDataRefresh();
              }}
            />
            {isClientRecordLoading || isFetchClientRecord || isNotificationSettingLoading ? (
              <div className={styles.loadingWrapper}>
                <div className={styles.contentLoading}>
                  <Skeleton active />
                </div>
              </div>
            ) : (
              <PatientDetailsContent
                recordId={path.recordId ?? ''}
                profileId={clientRecordData.clientProfiles[0]._id}
                isClientLoading={isClientRecordLoading}
                clientRecordData={clientRecordData}
                currentTab={path.patientTab ?? ''}
                assessmentOrReportId={path.assessmentOrReportId}
                refreshActivity={refreshActivity}
                allowCreateNewInvoice={isEdgeAdminView || isNormalUserView}
                notificationSettings={notificationSettingsResponses ?? ({} as NotificationSettingsInterface)}
                dataRefreshRef={dataRefreshRef}
                setIsOnboardingModalVisible={setIsOnboardingModalVisible}
              />
            )}
            {path.patientTab !== 'notes' && (
              <SimpleNote
                recordId={path.recordId ?? ''}
                onCreated={() => setRefreshActivity(refreshActivity + 1)}
                disabled={clientRecordData.recordStatus === RecordStatus.Closed}
              />
            )}
          </div>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default PatientDetails;
