import classnames from 'classnames';
import StepProgressBar from '../StepProgressBar/StepProgressBar';
import styles from './ModalHeader.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ReactNode } from 'react';

interface ModalHeaderProps {
  title: string | ReactNode;
  icon?: string;
  iconClassNames?: string;
  totalStep?: number;
  currentStep?: number;
  isCompleted?: boolean;
  onCancel: () => void;
  onClickBackBtn?: () => void;
  showBackStepBtn?: boolean;
  headerContainerClassName?: string;
  titleClassName?: string;
}

const ModalHeader = ({
  title,
  totalStep,
  currentStep,
  isCompleted,
  onCancel,
  icon,
  iconClassNames,
  onClickBackBtn,
  showBackStepBtn,
  headerContainerClassName,
  titleClassName
}: ModalHeaderProps) => {
  return (
    <div className={classnames(styles.container, headerContainerClassName)}>
      <div className={styles.titleContainer}>
        {icon && <i className={classnames('material-icons-outlined', iconClassNames && iconClassNames)}>{icon}</i>}
        <div className={classnames(styles.title, titleClassName)}>{title}</div>
      </div>
      {showBackStepBtn && (
        <div className={styles.backBtnContainer}>
          <ButtonAlt variant={'text'} id="backBtnId" size={'medium'} onClick={onClickBackBtn} icon={'arrow_back_ios'}>
            Back
          </ButtonAlt>
        </div>
      )}
      {totalStep && (
        <StepProgressBar
          className={styles.stepProgressBar}
          totalStep={totalStep}
          currentStep={currentStep || 0}
          isCompleted={isCompleted || false}
        />
      )}
      <div className={styles.closeButton} onClick={onCancel}>
        <span className="material-icons-outlined">close</span>
      </div>
    </div>
  );
};

export default ModalHeader;
