import styles from './ListItemCheckbox.module.scss';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { Col, Row } from 'antd';
import CheckBox from 'components/CheckBox/CheckBox';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface ListItemCheckboxProps {
  checkboxId: string;
  checkboxLabel: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  content?: ReactNode;
  disabled?: boolean;
  labelTooltip?: ReactNode;
}

const ListItemCheckbox = ({
  checkboxId,
  checkboxLabel,
  checked,
  setChecked,
  content,
  disabled,
  labelTooltip
}: ListItemCheckboxProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  return (
    <Row
      className={classNames(
        styles.container,
        isEdgeAdminView ? styles.adminTheme : styles.userTheme,
        checked && styles.checked
      )}
    >
      <Col span={7}>
        <CheckBox
          disabled={disabled}
          id={checkboxId}
          label={checkboxLabel}
          onChange={(e) => setChecked(e.target.checked)}
          value={checked}
          labelTooltip={labelTooltip}
          labelClassName={styles.labelClassName}
          inputClassName={styles.checkBoxInput}
        />
      </Col>
      <Col span={17}>{content}</Col>
    </Row>
  );
};

export default ListItemCheckbox;
