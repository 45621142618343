import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { security } from 'utils/security';

export enum DSTagTypes {
  reports = 'Reports',
  reportDetails = 'Report Details',
  letters = 'Letters',
  letterDetails = 'Letter Details',
  signature = 'Signature',
  caseNote = 'CaseNote',
  caseNoteList = 'CaseNote List',
  reportTemplates = 'Report Templates',
  letterTemplates = 'Letter Templates',
  consentFormDetails = 'ConsentFormDetails'
}

export const documentServicesApiSlice = createApi({
  reducerPath: 'documentServiceApis',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_DOCUMENT_SERVICE_URL,
    prepareHeaders: async (headers) => {
      const token = await security.getAccessTokenSilently();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    // something need to enhance by using object key, not doing on this PR as will be too big
    'Reports',
    'Report Details',
    'Letters',
    'Letter Details',
    'Signature',
    'CaseNote',
    'CaseNoteList',
    'Report Templates',
    'Letter Templates',
    'ConsentFormDetails'
  ],
  endpoints: () => ({})
});
