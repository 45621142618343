import styles from './InvoicePaymentsItem.module.scss';
import columnStyles from '../InvoicePaymentsListHeader/InvoicePaymentColumn.module.scss';
import { InvoicePayment } from 'pages/InvoicePayments/constants';
import { combineName } from 'utils/general';
import momentTz from 'moment-timezone';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { PaymentRequestEventType } from 'pages/Invoices/interface';
import { useCurrency } from 'utils/hooks/useCurrency';
import { Link } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import classNames from 'classnames';
import InvoicePaymentsActionMenu from '../InvoicePaymentsActionMenu/InvoicePaymentsActionMenu';
import { useAppSelector } from 'redux/hooks';
import { InvoicePaymentsTab, selectActiveTab } from 'redux/features/invoicePayments/invoicePaymentsSlice';
import {
  MOMENTJS_DATE_MONTH_YEAR_FORMAT,
  MOMENTJS_TIME_FORMAT,
  MOMENTJS_TIME_FORMAT_DAY_PARTS
} from 'utils/dateChecker';
import { ScheduledPayment } from 'redux/endpoints/scheduleServices/scheduledPayment';

const AUTO_TRIGGER_TIME_FOR_PAYMENTS = '09:00';

interface InvoicePaymentsItemProps {
  payment?: InvoicePayment;
  scheduledPayment?: ScheduledPayment;
  onViewClient: () => void;
  onPartialRefund?: () => void;
  onFullRefund?: () => void;
}

const getReceivedTimestamp = (activeTab: InvoicePaymentsTab, payment: InvoicePayment): string | undefined => {
  const { eventLogs, refunds, updatedAt } = payment;
  switch (activeTab) {
    case InvoicePaymentsTab.Collected: {
      return eventLogs.find((log) => log.event === PaymentRequestEventType.Completed)?.timestamp;
    }

    case InvoicePaymentsTab.Refunded: {
      return refunds?.[0].updatedAt;
    }

    case InvoicePaymentsTab.Failed:
    case InvoicePaymentsTab.Cancelled:
    default: {
      return updatedAt;
    }
  }
};

const InvoicePaymentsItem = ({
  payment,
  scheduledPayment,
  onViewClient,
  onPartialRefund,
  onFullRefund
}: InvoicePaymentsItemProps) => {
  const { timeZoneByView } = useTimeZone();
  const { formatCurrency } = useCurrency();
  const { INVOICES } = useRoutesGenerator();
  const activeTab = useAppSelector(selectActiveTab);

  const {
    stripeChargeId,
    appointment: paymentAppointment,
    amount = 0,
    payments,
    clientRecord: paymentClientRecord
  } = payment || {};
  const { sessionTypeName, startDateTime, endDateTime, invoices } = paymentAppointment || scheduledPayment || {};
  const { clientProfiles, caseId, tacklitId } = paymentClientRecord || scheduledPayment?.clientRecord || {};

  const serviceDateStartTimeTz = momentTz(startDateTime).tz(timeZoneByView);
  const serviceDateFormatted = serviceDateStartTimeTz.format(MOMENTJS_DATE_MONTH_YEAR_FORMAT);
  const serviceTimeFormatted = `${serviceDateStartTimeTz.format(MOMENTJS_TIME_FORMAT_DAY_PARTS)} - ${momentTz(
    endDateTime
  )
    .tz(timeZoneByView)
    .format(MOMENTJS_TIME_FORMAT_DAY_PARTS)}`;

  const receivedTimestamp = payment ? getReceivedTimestamp(activeTab, payment) : undefined;
  const receivedTimeWithTimeZone = receivedTimestamp ? momentTz(receivedTimestamp).tz(timeZoneByView) : undefined;

  return (
    <div className={styles.container}>
      {/* Charge ID */}
      <div className={columnStyles.chargeId}>{stripeChargeId || scheduledPayment?._id}</div>

      {/* Client */}
      <div className={columnStyles.client}>
        {clientProfiles && <div className={styles.clientName}>{combineName(clientProfiles)}</div>}
        {caseId && <div className={styles.caseId}>{caseId}</div>}
        {tacklitId && <div className={styles.tacklitId}>{tacklitId}</div>}
      </div>

      {/* Appointment type name */}
      <div className={columnStyles.service}>{sessionTypeName}</div>

      {/* Service date | Appointment date */}
      <div className={columnStyles.serviceDate}>
        <div>{serviceDateFormatted}</div>
        <div>{serviceTimeFormatted}</div>
      </div>

      {/* Received */}
      <div className={columnStyles.received}>
        <div>
          {activeTab === InvoicePaymentsTab.Scheduled
            ? AUTO_TRIGGER_TIME_FOR_PAYMENTS
            : receivedTimeWithTimeZone?.format(MOMENTJS_TIME_FORMAT) || ''}
        </div>
        <div>
          {activeTab === InvoicePaymentsTab.Scheduled
            ? serviceDateStartTimeTz.add(-2, 'days').format(MOMENTJS_DATE_MONTH_YEAR_FORMAT)
            : receivedTimeWithTimeZone?.format(MOMENTJS_DATE_MONTH_YEAR_FORMAT) || ''}
        </div>
      </div>

      {/* Amount */}
      <div className={classNames(columnStyles.amount, styles.amount)}>
        {activeTab === InvoicePaymentsTab.Refunded
          ? formatCurrency(payments?.refunded || 0)
          : activeTab === InvoicePaymentsTab.Scheduled
          ? formatCurrency(scheduledPayment?.rate || 0)
          : formatCurrency(amount)}
      </div>

      {/* Invoice */}
      <div className={columnStyles.invoice}>
        {invoices && invoices.length > 0 && (
          <div className={styles.invoiceWrapper}>
            <span className={classNames('material-icons-outlined', styles.receiptIcon)}>receipt</span>
            <div className={styles.invoiceIds}>
              {invoices.map(({ _id, invoiceId }) => (
                <Link className={styles.invoiceId} key={_id} to={`${INVOICES.BASE}/${_id}`}>
                  {invoiceId}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className={columnStyles.actions}>
        <InvoicePaymentsActionMenu
          payment={payment}
          onViewClient={onViewClient}
          onFullRefund={onFullRefund}
          onPartialRefund={onPartialRefund}
        />
      </div>
    </div>
  );
};

export default InvoicePaymentsItem;
