import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { GetPackageAssignmentList } from 'interfaces/Packages/packages';
import { Referral } from 'interfaces/Referral/Referral';
import { DeliveryType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { AddressBookFromAPI } from 'pages/AddressBook/Interfaces/AddressBook';
import { InvoiceStatus, PaymentMethod } from 'pages/Invoices/interface';

export interface InvoiceGenerationFormValue {
  invoiceId: string;
  practice?: {
    address?: string;
    logo?: string;
    mobileNumber?: string;
    name: string;
    fax?: string;
    formalName?: string;
    registrationNumber?: string;
    financeEmail?: string;
  };
  group?: { _id: string };
  clientRecord?: { _id: string };
  issueDate: string;
  dueDate: string;
  description: string;
  taxRate: number;
  items: {
    appointmentId: string;
    overview?: string;
    description?: string;
    quantity: number;
    cost: number;
    tags?: string[];
    appointmentDate?: string;
    tax: number;
    total: number;
    clinicianId?: string;
    deliveryType?: DeliveryType;
    startTime?: string;
    endTime?: string;
  }[];
  discount?: {
    type: string;
    value: number;
  };
  paymentMethods: PaymentMethod[];
  note: string;
  status: InvoiceStatus;
  medicare?: {
    claimant?: {
      name?: string;
      dateOfBirth?: string;
      medicareNumber?: string;
      irn?: string;
      expiryDate?: string;
      dva?: string;
      phoneNumber?: string;
      address?: string;
    };
    referral?: {
      name?: string;
      date?: string;
      providerNumber?: string;
    };
    serviceProvider?: {
      name?: string;
      providerNumber?: string;
    };
    claim?: {
      dateOfService?: string;
      mbsCode?: string;
    };
  };
  title?: string;
  recipients: {
    name: string;
    email?: string;
    address?: string;
    mobileNumber?: string;
    reference?: string;
    registrationNumber?: string;
  }[];
  footer?: string;
  invoiceTemplateId?: string;
  addressBook:
    | {
        _id: string;
      }
    | null
    | undefined;
}

export type InvoiceTemplateCreator = {
  selectedClient: clientRecordsInterface | undefined;
  selectedGroup: any;
  selectedFunder: AddressBookFromAPI | undefined;
  selectedPackage: GetPackageAssignmentList | undefined;
  selectedReferral: Referral | undefined;
  participantType: ParticipantType;
  invoiceForm: InvoiceGenerationFormValue;
};

const initialState: InvoiceTemplateCreator = {
  selectedClient: undefined,
  selectedGroup: undefined,
  selectedFunder: undefined,
  selectedPackage: undefined,
  selectedReferral: undefined,
  participantType: ParticipantType.OneToOne,
  invoiceForm: {
    invoiceId: '',
    issueDate: '',
    dueDate: '',
    description: '',
    taxRate: 0,
    items: [],
    paymentMethods: [],
    note: '',
    status: InvoiceStatus.Draft,
    recipients: [],
    footer: '',
    addressBook: undefined
  }
};

export const createInvoiceWithTemplateSlice = createSlice({
  name: 'createInvoiceWithTemplateSlice',
  initialState,
  reducers: {
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
    setParticipantType: (state, action) => {
      state.participantType = action.payload;
    },
    setInvoiceForm: (state, action) => {
      state.invoiceForm = {
        ...state.invoiceForm,
        ...action.payload
      };
    },
    resetAll: () => initialState,
    setMedicareClaim: (state, action) => {
      const medicareClaimCurrentState = state.invoiceForm.medicare?.claim;
      state.invoiceForm = {
        ...state.invoiceForm,
        medicare: {
          ...state.invoiceForm.medicare,
          claim: {
            ...medicareClaimCurrentState,
            ...action.payload
          }
        }
      };
    },

    setMedicareServiceProvider: (state, action) => {
      state.invoiceForm = {
        ...state.invoiceForm,
        medicare: {
          ...state.invoiceForm.medicare,
          serviceProvider: action.payload
        }
      };
    },

    setMedicareReferralDetails: (state, action) => {
      state.invoiceForm = {
        ...state.invoiceForm,
        medicare: {
          ...state.invoiceForm.medicare,
          referral: action.payload
        }
      };
    },

    setSelectedFunder: (state, action) => {
      state.selectedFunder = action.payload;
    },

    setSelectedPackage: (state, action) => {
      state.selectedPackage = action.payload;
    },

    setSelectedReferral: (state, action) => {
      state.selectedReferral = action.payload;
    },

    resetClientInvoiceData: (state) => {
      state.selectedFunder = initialState.selectedFunder;
      state.selectedPackage = initialState.selectedPackage;
      state.selectedReferral = initialState.selectedReferral;
    }
  }
});

export const selectSelectedClient = (state: RootState) => state.createInvoiceWithTemplateSlice.selectedClient;
export const selectSelectedGroup = (state: RootState) => state.createInvoiceWithTemplateSlice.selectedGroup;
export const selectParticipantType = (state: RootState) => state.createInvoiceWithTemplateSlice.participantType;
export const selectInvoiceForm = (state: RootState) => state.createInvoiceWithTemplateSlice.invoiceForm;
export const selectSelectedFunder = (state: RootState) => state.createInvoiceWithTemplateSlice.selectedFunder;
export const selectSelectedPackage = (state: RootState) => state.createInvoiceWithTemplateSlice.selectedPackage;
export const selectSelectedReferral = (state: RootState) => state.createInvoiceWithTemplateSlice.selectedReferral;

export const {
  setSelectedClient,
  setSelectedGroup,
  setParticipantType,
  setInvoiceForm,
  resetAll,
  setMedicareClaim,
  setMedicareServiceProvider,
  setSelectedFunder,
  setSelectedPackage,
  setSelectedReferral,
  resetClientInvoiceData,
  setMedicareReferralDetails
} = createInvoiceWithTemplateSlice.actions;

export default createInvoiceWithTemplateSlice.reducer;
