import styles from './CalendarEvent.module.scss';
import { Dropdown } from 'antd';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectCarePlanEditorSlice, updateEvent } from 'redux/carePlan/carePlanEditorSlice';
import { useMemo, useState } from 'react';
import {
  AppointmentType,
  DELIVERY_TYPE_ICONS,
  DELIVERY_TYPE_LABELS,
  DeliveryType
} from 'interfaces/Schedule/AppointmentType';
import { MbscCalendarEventData, Toast } from '@mobiscroll/react';
import momentTz from 'moment-timezone';
import { useGetAvailableRoomQuery } from 'redux/endpoints/scheduleServices/room';
import querystring from 'query-string';
import classNames from 'classnames';
import { useUpdateCarePathwayAppointmentMutation } from 'redux/endpoints/scheduleServices/reservedAppointment';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { RoomInterface } from 'interfaces/Schedule/Room';
import { carePlanAppointments } from 'interfaces/CarePathway/CarePathway';

interface CalendarEventProps {
  event: MbscCalendarEventData;
  timezone: string;
  onRemoveEvent: (event: MbscCalendarEventData) => void;
}

// docs -> https://docs.mobiscroll.com/react/eventcalendar#opt-renderScheduleEvent
const CalendarEvent = ({ event, timezone, onRemoveEvent }: CalendarEventProps) => {
  const dispatch = useAppDispatch();
  const { accountId } = useGetAccountId();
  const [updateCarePathwayAppointment] = useUpdateCarePathwayAppointmentMutation();
  const [toastMessage, setToastMessage] = useState('');
  const [isToastOpen, setToastOpen] = useState(false);

  const carePlanData = useAppSelector(selectCarePlanEditorSlice);

  const massageEventData = useMemo(
    () => ({
      ...event,
      _id: event.original?._id
    }),
    [event]
  );

  const appointmentType: AppointmentType = useMemo(() => {
    return massageEventData.original?.appointmentType;
  }, [massageEventData.original]);

  const onChangeDeliveryType = useMemo(
    () => async (deliveryType: string) => {
      const payloadAppointmentUpdate: carePlanAppointments = {
        startDateTime: massageEventData?.original?.startDateTime,
        endDateTime: massageEventData?.original?.endDateTime,
        clinicianId: (massageEventData.original?.resource as string) || '',
        carePathwayItemId: massageEventData?.original?.carePathwayItemId,
        deliveryType: deliveryType,
        ...(massageEventData?.original?.roomId && {
          roomId: massageEventData?.original?.roomId
        })
      };
      await updateCarePathwayAppointment({
        accountId,
        carePathwayId: carePlanData._id || '',
        appointmentId: massageEventData._id,
        payload: payloadAppointmentUpdate
      });

      const massageData = {
        ...massageEventData.original,
        deliveryType: deliveryType
      };

      dispatch(updateEvent(massageData));
      setToastMessage(massageEventData.original?.title + ' delivery type updated');
      setToastOpen(true);
    },
    [massageEventData, accountId, carePlanData, dispatch, updateCarePathwayAppointment]
  );

  const onChangeRoom = useMemo(
    () => async (room: RoomInterface) => {
      const payloadAppointmentUpdate: carePlanAppointments = {
        startDateTime: massageEventData?.original?.startDateTime,
        endDateTime: massageEventData?.original?.endDateTime,
        clinicianId: (massageEventData.original?.resource as string) || '',
        carePathwayItemId: massageEventData?.original?.carePathwayItemId,
        deliveryType: massageEventData?.original?.deliveryType,
        roomId: room._id
      };
      await updateCarePathwayAppointment({
        accountId,
        carePathwayId: carePlanData._id || '',
        appointmentId: massageEventData._id,
        payload: payloadAppointmentUpdate
      });

      const massageData = {
        ...massageEventData.original,
        room: {
          roomId: room._id,
          roomName: room.name
        }
      };

      dispatch(updateEvent(massageData));
      setToastMessage(massageEventData.original?.title + ' room updated');
      setToastOpen(true);
    },
    [massageEventData, accountId, carePlanData, dispatch, updateCarePathwayAppointment]
  );

  const deliveryTypeOptions = useMemo(() => {
    return (
      [...(appointmentType.deliveryOptions || [])]
        .sort((a, b) => a.localeCompare(b))
        .map((deliveryType, index) => ({
          key: index,
          label: (
            <div className={styles.dropdownLabel} onClick={() => onChangeDeliveryType(deliveryType)}>
              <div>
                <span className={`material-icons ${styles.dropdownIcon}`}>{DELIVERY_TYPE_ICONS[deliveryType]}</span>
                <span>
                  {deliveryType === DeliveryType.Other
                    ? appointmentType.otherInstructions?.title || 'Other'
                    : DELIVERY_TYPE_LABELS[deliveryType]}
                </span>
              </div>
              {deliveryType === massageEventData.original?.deliveryType && (
                <span className={`material-icons ${styles.checkCircleIcon}`}>check_circle</span>
              )}
            </div>
          ),
          value: deliveryType,
          className: classNames(
            styles.menuItem,
            deliveryType === massageEventData.original?.deliveryType ? styles.selectedItem : ''
          )
        })) || []
    );
  }, [appointmentType, massageEventData.original?.deliveryType, onChangeDeliveryType]);

  //TODO: Call api to get room's availablity when delivery type is face to face recurring, dispatch]);
  const queryParam = useMemo(() => {
    const startDate = momentTz(massageEventData.startDate.toISOString()).tz(timezone);
    const endDate = momentTz(massageEventData.endDate.toISOString()).tz(timezone);
    return querystring.stringify({
      dates: startDate.format('YYYY-MM-DD'),
      startTime: startDate.format('HH:mm'),
      endTime: endDate.format('HH:mm'),
      allowConflict: false, // false will not return booked room
      timezone
    });
  }, [massageEventData, timezone]);

  const { data: roomList } = useGetAvailableRoomQuery({ queryParam }, { skip: massageEventData.allDay === undefined });

  const roomOptions = useMemo(() => {
    if (roomList?.length === 0) {
      return [
        {
          key: 0,
          label: 'No rooms available',
          disabled: true
        }
      ];
    }
    return (
      roomList?.map((room, index) => ({
        key: index,
        label: (
          <div className={styles.dropdownLabel} onClick={() => onChangeRoom(room)}>
            <span>{room.name}</span>
            {room._id === massageEventData.original?.room?.roomId && (
              <span className={`material-icons ${styles.checkCircleIcon}`}>check_circle</span>
            )}
          </div>
        ),
        className: classNames(
          styles.menuItem,
          room._id === massageEventData.original?.room?.roomId ? styles.selectedItem : ''
        )
      })) || []
    );
  }, [roomList, massageEventData.original?.room, onChangeRoom]);

  const duration = momentTz(massageEventData.end, 'HH:mm A').diff(
    momentTz(massageEventData.start, 'HH:mm A'),
    'minutes'
  );
  const longerThen60mins = duration >= 60;

  const MIN_IN_HOUR = 60;
  const OFFSET_PIXEL = 6;
  const TIME_CELL_STEP = 60;
  const gapHeight =
    (event?.original?.appointmentType?.gap * MIN_IN_HOUR) / TIME_CELL_STEP -
    (MIN_IN_HOUR / TIME_CELL_STEP) * OFFSET_PIXEL;

  const isProcessing = useMemo(() => {
    return carePlanData.items.some(
      (itemObj) =>
        itemObj._id === (event.original?.carePathwayItemId || event.original?._id) &&
        (itemObj.isDropped || itemObj.isProcessing)
    );
  }, [carePlanData.items, event.original]);

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{ height: '100%' }}
        className={classNames(
          styles.eventContainer,
          isProcessing && styles.processing,
          appointmentType?.roomSettings?.required && !massageEventData.original?.room?.roomId && styles.roomRequired
        )}
      >
        <div className={styles.eventHeader}>
          <div className={styles.eventTitle}>
            <div>
              <div className={styles.text}>{massageEventData.title}</div>
            </div>
          </div>
          {!longerThen60mins && (
            <div className={styles.dropdowns}>
              {deliveryTypeOptions.length === 1 ? (
                <div className={`material-icons ${styles.dropdownIcon}`}>
                  {DELIVERY_TYPE_ICONS[deliveryTypeOptions[0].value]}
                </div>
              ) : (
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: deliveryTypeOptions
                  }}
                  disabled={isProcessing}
                >
                  <div
                    onClick={(e) => !isProcessing && e.preventDefault()}
                    className={`material-icons ${styles.dropdownIcon}`}
                  >
                    {
                      DELIVERY_TYPE_ICONS[
                        (massageEventData.original?.deliveryType as DeliveryType) || DeliveryType.FaceToFace
                      ]
                    }
                  </div>
                </Dropdown>
              )}
              <Dropdown
                trigger={['click']}
                menu={{
                  items: roomOptions
                }}
                disabled={isProcessing}
              >
                <div
                  onClick={(e) => !isProcessing && e.preventDefault()}
                  className={`material-icons ${styles.dropdownIcon}`}
                >
                  room_preferences
                </div>
              </Dropdown>
            </div>
          )}
          <div
            onClick={() => !isProcessing && onRemoveEvent(massageEventData)}
            className={`material-icons ${styles.eventRemoveBtn}`}
          >
            close
          </div>
        </div>
        {longerThen60mins && (
          <div className={classNames(styles.eventInfo, longerThen60mins && styles.longerThen60mins)}>
            <div>
              <div className={styles.flex}>
                {deliveryTypeOptions.length === 1 ? (
                  <div className={styles.dropdownLabel}>
                    <span className={`material-icons ${styles.dropdownIcon}`}>
                      {DELIVERY_TYPE_ICONS[deliveryTypeOptions[0].value]}
                    </span>
                    <span>{DELIVERY_TYPE_LABELS[deliveryTypeOptions[0].value]}</span>
                  </div>
                ) : (
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: deliveryTypeOptions
                    }}
                    disabled={isProcessing}
                  >
                    <div onClick={(e) => !isProcessing && e.preventDefault()} className={styles.dropdownLabel}>
                      <span className={`material-icons ${styles.dropdownIcon}`}>
                        {
                          DELIVERY_TYPE_ICONS[
                            (massageEventData.original?.deliveryType as DeliveryType) || DeliveryType.FaceToFace
                          ]
                        }
                      </span>
                      <span>{DELIVERY_TYPE_LABELS[deliveryTypeOptions[0]?.value || DeliveryType.FaceToFace]}</span>
                    </div>
                  </Dropdown>
                )}
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: roomOptions
                  }}
                  disabled={isProcessing}
                >
                  <div className={styles.dropdownLabel}>
                    <span
                      onClick={(e) => !isProcessing && e.preventDefault()}
                      className={`material-icons ${styles.dropdownIcon}`}
                    >
                      room_preferences
                    </span>
                    <span>{appointmentType?.roomSettings?.required ? 'Yes' : 'No'}</span>
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{ height: gapHeight }} className={styles.gapContainer}>
        <div className={styles.gapMakeup} />
        <div className={styles.gapText}>GAP</div>
      </div>
      <Toast isOpen={isToastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
    </div>
  );
};

export default CalendarEvent;
