import styles from './ManualAdjustTotalModal.module.scss';
import ModalV2 from 'components/ModalV2/ModalV2';
import { InvoiceWithType } from '../InvoiceList/constants';
import InvoiceDetailsForModal from '../InvoiceDetailsForModal/InvoiceDetailsForModal';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { ChangeEvent, useState } from 'react';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import { config } from 'config/config';
import { useAdjustTotalAmountMutation } from 'redux/endpoints/billingServices/invoices';
import { notification } from 'antd';
import { convertCurrencyValue, sanitizedCurrencyValue } from 'utils/currencyFormatConverter';

interface ManualAdjustTotalModalProps {
  showModal: boolean;
  amount: string | undefined;
  invoice: InvoiceWithType;
  onCloseModal: () => void;
  onChangeValue: (value: string) => void;
}

const ManualAdjustTotalModal = ({
  showModal,
  invoice,
  amount,
  onCloseModal,
  onChangeValue
}: ManualAdjustTotalModalProps) => {
  const { currencySymbol } = config;

  const [amountError, setAmountError] = useState<string>('');
  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');

  const [patchTotalAmount] = useAdjustTotalAmountMutation();

  const onChangeAmount = (value: string) => {
    onChangeValue(sanitizedCurrencyValue(value));
    setAmountError('');
  };

  const handleCloseModal = () => {
    setAmountError('');
    onCloseModal();
  };

  const handleSubmit = async () => {
    const amountInNumber = Number(amount || '');
    const isValidAmount = !Number.isNaN(amountInNumber) && amountInNumber > 0;
    if (isValidAmount) {
      setSubmitButtonStatus('active');
      try {
        await patchTotalAmount({
          invoiceId: invoice._id,
          invoiceAmount: amountInNumber
        }).unwrap();

        notification.success({
          message: "The invoice's total amount has been successfully adjusted.",
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitButtonStatus('finished');
        handleCloseModal();
      } catch (ex) {
        notification.error({
          message:
            "Adjusting the invoice's total amount failed. Please try again, if failures persist please contact us to investigate."
        });
        setSubmitButtonStatus('');
      }
    } else {
      setAmountError(!amount ? 'Required field' : 'Invalid amount');
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={showModal}
      title={`Adjust ${currencySymbol} Total`}
      onModalClose={handleCloseModal}
      bodyStyle={{ padding: '0' }}
      headerContainerClassName={styles.headerContainerClassName}
      titleClassName={styles.titleClassName}
    >
      <div className={styles.container}>
        {/* Invoice details */}
        <InvoiceDetailsForModal invoice={invoice} />

        <div className={styles.amountInputContainer}>
          <TitleInput
            inputContainerClassName={amountError ? styles.inputErrorClassName : styles.inputClassName}
            prefix={currencySymbol}
            inputContainer={styles.amountInput}
            inputProps={{
              maxLength: 18,
              placeholder: 'Enter amount',
              value: convertCurrencyValue(amount || ''),
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChangeAmount(e.target.value);
              }
            }}
            error={amountError}
          />
          <div className={styles.amountNote}>This updated total will reflect on the live invoice URL</div>
        </div>

        <div className={styles.buttonContainer}>
          <ButtonAlt status={submitButtonStatus} loadingWord="Processing..." onClick={handleSubmit}>
            {`Adjust ${currencySymbol} Total`}
          </ButtonAlt>
        </div>
      </div>
    </ModalV2>
  );
};

export default ManualAdjustTotalModal;
