import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import styles from './FormSection.module.scss';
import { useMemo } from 'react';
import { v4 } from 'uuid';

interface FormSectionProps {
  title: string;
  subtitle?: string;
  help?: string;
  children: any;
}

const FormSection = ({ title, subtitle, help, children }: FormSectionProps) => {
  const helpId = useMemo(() => v4(), []);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        {help && (
          <HelpOutLineWithTooltips
            id={`${helpId}-${title}-form-section-help`}
            desc={help}
            iconClass={styles.helpIcon}
          />
        )}
      </div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      <div>{children}</div>
    </div>
  );
};

export default FormSection;
