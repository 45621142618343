import { REFERRAL_SOURCE_TAGS } from 'components/AddPatientModalV2/constants';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { DELIVERY_TYPE_LABELS, DeliveryType, ParticipantType } from 'interfaces/Schedule/AppointmentType';

export enum FormQueryType {
  Status,
  Time
}

export const formMenuFilterByStatus = [
  { name: 'Open to book', _id: '1' },
  { name: 'Draft - Not bookable', _id: '0' }
];

export const getFormMenuFilterByDeliveryMode = (isTwilioTelehealthEnabled: boolean) => [
  { name: DELIVERY_TYPE_LABELS[DeliveryType.FaceToFace], _id: DeliveryType.FaceToFace },
  ...(isTwilioTelehealthEnabled
    ? [{ name: DELIVERY_TYPE_LABELS[DeliveryType.PhoneCall], _id: DeliveryType.PhoneCall }]
    : []),
  { name: DELIVERY_TYPE_LABELS[DeliveryType.PhoneCallDialClient], _id: DeliveryType.PhoneCallDialClient },
  { name: DELIVERY_TYPE_LABELS[DeliveryType.VideoCall], _id: DeliveryType.VideoCall },
  { name: DELIVERY_TYPE_LABELS[DeliveryType.Other], _id: DeliveryType.Other }
];

export const getFormMenuFilterByParticipantType = [
  { name: 'One to one Appointment', _id: ParticipantType.OneToOne },
  { name: 'Group Appointment', _id: ParticipantType.Group },
  { name: 'Activity', _id: ParticipantType.Activity }
];

export const referralMenuItems = () => {
  return REFERRAL_SOURCE_TAGS.map((item) => ({
    _id: item,
    name: item
  }));
};

export const onToggleFilter = <T extends FilterCheckListItem>(
  list: T[],
  selectedFilters: T[],
  setSelectedFilters: (v: T[]) => void
) => {
  return (value: { item?: FilterCheckListItem; toggleAllValue?: boolean; color?: string; searchValue?: string }) => {
    if (value.toggleAllValue !== undefined) {
      setSelectedFilters(
        value.toggleAllValue
          ? [
              ...selectedFilters,
              ...list
                .filter((j) => !selectedFilters.find((i) => i._id === j._id))
                .filter((j) => j.name.toLowerCase().includes((value.searchValue || '').toLowerCase()))
            ]
          : [
              ...selectedFilters.filter(
                (item) => !item.name.toLowerCase().includes((value.searchValue || '').toLowerCase())
              )
            ]
      );
    } else if (value.item) {
      if (!selectedFilters.find((i) => i._id === value.item!._id)) {
        const selectedItem = list.find((i) => i._id === value.item!._id);
        const massageItem: any = { ...selectedItem };
        if (selectedItem) {
          setSelectedFilters([...selectedFilters, massageItem]);
        }
      } else {
        setSelectedFilters(selectedFilters.filter((item) => item._id !== value.item!._id));
      }
    }
  };
};
