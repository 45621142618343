import { useState } from 'react';
import { Skeleton, message as antdMessage, notification } from 'antd';
import { AppointmentRequest } from '../../../../Notification';

import { deleteAppointmentRequest, postAcceptAppointmentRequest } from 'utils/http/appointment';

import LoadingButton from 'components/v2/Button/Button';
import ChangeNote from '../ChangeNote/ChangeNote';

import styles from './AppointmentRequest.module.scss';
import { getName } from 'utils/general';
import AppointmentTypeSelect, { AppointmentTypeId } from 'components/v2/AppointmentTypeSelect/AppointmentTypeSelect';
import AvailableTimeSelect from 'components/v2/AvailableTimeSelect/AvailableTimeSelect';
import { Formik } from 'formik';
import DeliveryModeSelect from 'components/v2/DeliveryModeSelect/DeliveryModeSelect';
import FormSection from 'components/v2/FormSection/FormSection';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import RoomOption from 'pages/Calendar/components/Modals/EventCreationModal/components/EventForm/components/ScheduleForm/components/RoomOption/RoomOption';
import {
  CreatedAppointmentType,
  DeliveryType,
  OtherInstructions,
  ParticipantType
} from 'interfaces/Schedule/AppointmentType';
import { SelectedAppointmentSlot } from 'components/v2/AvailableTimeSelect/components/DayCard/DayCard';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';

interface AppointmentRequestProps {
  appointmentRequest: AppointmentRequest;
  token: string;
  onRefreshNotifications: () => void;
  assignedClinician?: PractitionersDetailsInterface;
}

export interface AppointmentValues {
  deliveryType: string;
  faceToFaceLocation: string;
  videoCallInstructions: string;
  phoneCallInstructions: string;
  phoneCallDialClientInstructions: string;
  otherInstructions: OtherInstructions;
  roomInfo: string;
}

const AppointmentRequestComponent = ({
  appointmentRequest: { _id, clientRecord, message, clinicianId },
  token,
  onRefreshNotifications,
  assignedClinician
}: AppointmentRequestProps) => {
  const [note, setNote] = useState('');
  const { isEdgeUser, isEdgeReceptionistView, isEdgeAdminView } = useGetAccountPackageView();
  const asAdmin = isEdgeReceptionistView || isEdgeAdminView;

  const [appointmentType, setAppointmentType] = useState<CreatedAppointmentType>();
  const [selectedSlot, setSelectedSlot] = useState<SelectedAppointmentSlot>();
  const [declineButtonState, setDeclineButtonState] = useState<'' | 'active' | 'finished'>('');
  const [submitButtonState, setSubmitButtonState] = useState<'' | 'active' | 'finished'>('');
  const [showChangeNote, setShowChangeNote] = useState(false);
  const [showScheduleAppointment, setShowScheduleAppointment] = useState(false);

  const handleSubmitAppointment = async (values: AppointmentValues) => {
    setSubmitButtonState('active');
    const {
      deliveryType,
      faceToFaceLocation,
      videoCallInstructions,
      phoneCallInstructions,
      phoneCallDialClientInstructions,
      otherInstructions,
      roomInfo
    } = values;

    try {
      const payload = {
        slot: {
          ...selectedSlot,
          faceToFaceLocation: deliveryType === DeliveryType.FaceToFace ? faceToFaceLocation : undefined,
          videoCallInstructions: deliveryType === DeliveryType.VideoCall ? videoCallInstructions : undefined,
          phoneCallInstructions: deliveryType === DeliveryType.PhoneCall ? phoneCallInstructions : undefined,
          phoneCallDialClientInstructions:
            deliveryType === DeliveryType.PhoneCallDialClient ? phoneCallDialClientInstructions : undefined,
          otherInstructions: deliveryType === DeliveryType.Other ? otherInstructions : undefined,
          sessionTypeId: appointmentType?._id || ''
        },
        deliveryType,
        room: roomInfo ? JSON.parse(roomInfo) : ''
      };

      await postAcceptAppointmentRequest({ appointmentRequestId: _id, payload, token, asAdmin });
      setSubmitButtonState('finished');
      onRefreshNotifications();
      notification.success({
        message: 'Succesfully suggested the new time for the appointment'
      });
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to update this appointment request. Please try again.'
      });
      setSubmitButtonState('');
    }
  };

  const handleDeclineAppointmentRequest = async () => {
    setDeclineButtonState('active');

    try {
      await deleteAppointmentRequest({ appointmentRequestId: _id, payload: { note }, token, asAdmin });
      setDeclineButtonState('finished');
      onRefreshNotifications();
      notification.success({
        message: 'Succesfully declined the appointment request'
      });
    } catch (ex) {
      antdMessage.error('Something went wrong while trying to decline this appointment request');
      setDeclineButtonState('');
    }
  };

  const handleChangeSlot = (slot?: SelectedAppointmentSlot) => {
    setSelectedSlot(slot);
  };

  const handleChangeAppointmentType = (appointmentTypeId: AppointmentTypeId | undefined) => {
    setAppointmentType(appointmentTypeId);
    setSelectedSlot(undefined);
  };

  return (
    <Formik
      initialValues={{
        deliveryType: '',
        faceToFaceLocation: '',
        videoCallInstructions: '',
        phoneCallInstructions: '',
        phoneCallDialClientInstructions: '',
        otherInstructions: { title: '', instructions: '' },
        roomRequired: '',
        roomInfo: ''
      }}
      onSubmit={() => {}}
    >
      {({ values }) => (
        <>
          <div className={styles.container}>
            <div className={styles.avatarAndName}>
              <ClientAvatar
                avatarUrl={clientRecord.clientProfiles[0].avatar}
                initialsName={clientRecord.clientProfiles[0].initials}
                avatarSize={40}
                isEllipsisName={false}
                displayLimit={2}
                nameClassName={styles.name}
                initialsClassName={styles.name}
                containerClassName={styles.containerClassName}
                name={getName(clientRecord.clientProfiles[0])}
              />
            </div>

            {asAdmin && clinicianId && (
              <div className={styles.avatarAndName}>
                {!assignedClinician ? (
                  <Skeleton.Button active />
                ) : (
                  <ClinicianAvatar
                    avatarSize={40}
                    isEllipsisName={false}
                    avatarUrl={assignedClinician.avatar}
                    customNameClass={styles.name}
                    customContainerClass={styles.containerClassName}
                    name={assignedClinician.name}
                  />
                )}
              </div>
            )}

            <div className={styles.message}>
              <div className={styles.title}>MESSAGE</div>
              <div className={styles.text}>{message}</div>
            </div>
            <ButtonAlt
              className={styles.scheduleAppointmentButton}
              onClick={() => {
                showChangeNote && setShowChangeNote(false);
                setShowScheduleAppointment(!showScheduleAppointment);
              }}
              icon={'insert_invitation'}
            >
              Schedule Appointment
            </ButtonAlt>
            <ButtonAlt
              variant="outlined"
              iconPostFix
              className={styles.declineButton}
              onClick={() => {
                showScheduleAppointment && setShowScheduleAppointment(false);
                setShowChangeNote(!showChangeNote);
              }}
              icon={'arrow_drop_down'}
            >
              Decline
            </ButtonAlt>
          </div>
          {showChangeNote && (
            <>
              <ChangeNote note={note} onNoteChange={setNote} />
              <LoadingButton
                className={styles.submitButton}
                status={declineButtonState}
                onClick={handleDeclineAppointmentRequest}
              >
                Decline Appointment
              </LoadingButton>
            </>
          )}
          {showScheduleAppointment && (
            <div className={styles.scheduleContainer}>
              <AppointmentTypeSelect
                clinicianId={clinicianId || ''}
                hideTitle
                horizontal
                onSelect={handleChangeAppointmentType}
                selectedParticipantType={appointmentType?.participantType || ParticipantType.OneToOne}
              />
              {appointmentType && (
                <AvailableTimeSelect
                  appointmentTypeId={appointmentType._id}
                  appointmentTypeName={appointmentType.name}
                  onSelectSlot={handleChangeSlot}
                  selectedClinicianId={clinicianId || ''}
                  selectedSlot={selectedSlot}
                />
              )}
              {appointmentType?.deliveryOptions && (
                <div className={styles.deliveryModeContainer}>
                  <FormSection title="Delivery Mode">
                    <div className={styles.deliveryModeContainer}>
                      <DeliveryModeSelect appointmentType={appointmentType} showAdditionalInput />
                    </div>
                  </FormSection>
                </div>
              )}
              {isEdgeUser && appointmentType?.faceToFaceLocation && (
                <div className={styles.roomOptionContainer}>
                  <RoomOption appointmentType={appointmentType} />
                </div>
              )}
              <LoadingButton
                disabled={!selectedSlot || !values.deliveryType || (values.roomRequired === 'yes' && !values.roomInfo)}
                className={styles.submitButton}
                status={submitButtonState}
                onClick={() => handleSubmitAppointment(values)}
              >
                Confirm Appointment
              </LoadingButton>
            </div>
          )}
        </>
      )}
    </Formik>
  );
};

export default AppointmentRequestComponent;
