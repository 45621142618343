import { AppointmentStatusOverviewConfiguration } from 'interfaces/Clients/clientsRecord';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';

const CLIENT_APPOINTMENT_STATUS_OVERVIEW_CONFIG_LOCAL_STORAGE_KEY = 'clientAppointmentStatusOverviewConfig';

interface AppointmentStatusOverviewConfigLocalStorageObject {
  clinicianAuth0Id: string;
  config: Pick<AppointmentStatusOverviewConfiguration, 'displayTagCount'>;
}

const appointmentStatusOverviewConfigLocalStorageObjectSchema: SchemaOf<AppointmentStatusOverviewConfigLocalStorageObject> =
  Yup.object({
    clinicianAuth0Id: Yup.string().required(),
    config: Yup.object({
      displayTagCount: Yup.array(Yup.string().required()).required()
    }).required()
  });

const getValidatedConfigsFromLocalStorage = () => {
  try {
    const item = localStorage.getItem(CLIENT_APPOINTMENT_STATUS_OVERVIEW_CONFIG_LOCAL_STORAGE_KEY);
    const parsedJson = JSON.parse(item || '');

    const validatedConfigs =
      Array.isArray(parsedJson) &&
      parsedJson.filter((item): item is AppointmentStatusOverviewConfigLocalStorageObject =>
        appointmentStatusOverviewConfigLocalStorageObjectSchema.isValidSync(item)
      );

    return validatedConfigs || undefined;
  } catch (ex) {
    localStorage.removeItem(CLIENT_APPOINTMENT_STATUS_OVERVIEW_CONFIG_LOCAL_STORAGE_KEY);
  }
};

export const setClinicianAppointmentStatusOverviewConfigToLocalStorage = (
  newConfig: AppointmentStatusOverviewConfigLocalStorageObject
) => {
  const existingConfigs = getValidatedConfigsFromLocalStorage() || [];

  const currentItemIndex = existingConfigs.findIndex(
    (config) => config.clinicianAuth0Id === newConfig.clinicianAuth0Id
  );

  if (currentItemIndex !== -1) {
    existingConfigs.splice(currentItemIndex, 1, newConfig);
  } else {
    existingConfigs.push(newConfig);
  }

  localStorage.setItem(CLIENT_APPOINTMENT_STATUS_OVERVIEW_CONFIG_LOCAL_STORAGE_KEY, JSON.stringify(existingConfigs));
};

export const getClinicianAppointmentStatusOverviewConfigFromLocalStorage = (
  clinicianAuth0Id: string
): AppointmentStatusOverviewConfigLocalStorageObject['config'] | undefined =>
  getValidatedConfigsFromLocalStorage()?.find((item) => item.clinicianAuth0Id === clinicianAuth0Id)?.config;
