import { FC } from 'react';
import { EpisodeInterface, EpisodeStatus } from 'interfaces/Episodes/episodes';
import EpisodeCard from '../EpisodeCard/EpisodeCard';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import styles from './EpisodeListing.module.scss';
import moment from 'moment';
import { MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';

interface EpisodeListingProps {
  episodes: EpisodeInterface[];
  clientRecord: clientRecordsInterface;
  activeEpisode?: EpisodeInterface;
}

export const EpisodeListing: FC<EpisodeListingProps> = ({ episodes, activeEpisode, clientRecord }) => {
  const isNotShowActive = episodes.some(({ status, endDate }) => {
    if ([EpisodeStatus.Finished, EpisodeStatus.Cancelled].includes(status) && endDate) {
      const parsedEndDate = moment(endDate).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT);
      const duration = moment.duration(moment().diff(moment(parsedEndDate)));
      return duration.days() <= 0 && duration.months() <= 0 && duration.years() <= 0;
    }

    return false;
  });

  return (
    <div className={styles.container}>
      {activeEpisode?._id && (
        <div className={styles.container}>
          <EpisodeCard
            episodeData={activeEpisode}
            clientRecord={clientRecord}
            currentActiveEpisode={activeEpisode}
            overlapActiveDate={isNotShowActive}
          />
        </div>
      )}
      {activeEpisode?._id && episodes.length > 0 && <div className={styles.breakLine} />}
      {episodes.length > 0 && (
        <div className={styles.container}>
          {episodes.map((item, index) => (
            <EpisodeCard
              key={index}
              episodeData={item}
              clientRecord={clientRecord}
              currentActiveEpisode={activeEpisode}
              overlapActiveDate={isNotShowActive}
            />
          ))}
        </div>
      )}
    </div>
  );
};
