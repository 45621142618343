import { useEffect, useState } from 'react';
import { security } from 'utils/security';

export const useGetAccessToken = () => {
  const [token, setToken] = useState('');

  const fetchAccessToken = async () => {
    const token = await security.getAccessTokenSilently();

    setToken(token);
  };

  useEffect(() => {
    fetchAccessToken();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { token, refetchToken: fetchAccessToken };
};
