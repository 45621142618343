import { useCurrency } from 'utils/hooks/useCurrency';
import { InvoiceViewProps } from '../../InvoiceView';

import styles from './ItemsList.module.scss';
import { config } from 'config/config';
import { formatInvoiceNumber } from '../InvoiceTotal/calculateInvoiceTotals';

const TAX_LABEL = config.taxLabel;

const calculateDiscount = (
  items: InvoiceViewProps['items'],
  discountType?: 'percent' | 'amount',
  discountValue?: number
) => {
  if (!discountType || discountValue === undefined || isNaN(Number(discountValue))) {
    return undefined;
  }

  const totalCost = items
    .map((item) => Number(item.cost))
    .reduce((finalTotal, currentCost) => finalTotal + currentCost, 0);

  if (discountType === 'percent' && discountValue <= 100) {
    return (totalCost * Math.round(discountValue)) / 100;
  } else if (discountType === 'amount' && discountValue <= totalCost) {
    return discountValue;
  }
};

interface ItemsListProps {
  discount: InvoiceViewProps['discount'];
  items: InvoiceViewProps['items'];
  amountChanged?: boolean;
}

const ItemsList = ({ discount, items, amountChanged }: ItemsListProps) => {
  const { CURRENCY_SYMBOL } = useCurrency();
  const discountValue = calculateDiscount(items, discount?.type, discount?.value);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Items</div>
      <div className={styles.headingContainer}>
        <span className={styles.heading}>#</span>
        <span className={styles.heading}>OVERVIEW</span>
        {!amountChanged && (
          <>
            <span className={styles.heading}>COST</span>
            <span className={styles.heading}>{TAX_LABEL}</span>
            <span className={styles.heading}>TOTAL</span>
          </>
        )}
      </div>
      {items.map(({ cost, tax, total, overview, tags }, index) => (
        <div className={styles.itemContainer} key={index}>
          <span className={styles.index}>{index + 1}</span>
          <span className={styles.label}>
            {overview}
            {!!tags?.length && (
              <div className={styles.tags}>
                {tags.map((status, index) => (
                  <div className={styles.tag} key={index}>
                    {status}
                  </div>
                ))}
              </div>
            )}
          </span>
          {!amountChanged && (
            <>
              <span className={styles.cost}>{formatInvoiceNumber(CURRENCY_SYMBOL, cost)}</span>
              <span className={styles.tax}>
                {CURRENCY_SYMBOL}
                {tax.toFixed(2)}
              </span>
              <span className={styles.total}>{formatInvoiceNumber(CURRENCY_SYMBOL, total)}</span>
            </>
          )}
        </div>
      ))}
      {!amountChanged && discount && (
        <div className={styles.discountContainer}>
          <span className={styles.label}>Discount</span>
          <span className={styles.discount}>
            - {CURRENCY_SYMBOL}
            {discountValue?.toFixed(2)}
          </span>
        </div>
      )}
    </div>
  );
};

export default ItemsList;
