import { CreateReferral, Referral, ReferralMatchedClient, ReferralStatus } from 'interfaces/Referral/Referral';
import { GetReferralListQuery } from 'pages/Referrals/utils';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import { parseQueryParams } from 'utils/helpers/parseQueryParams';

interface PostReferralPayload {
  accountId: string;
  payload: CreateReferral;
}

export interface UpdateReferralPayload extends PostReferralPayload {
  referralId: string;
}

interface ReferralListResponse {
  referrals: Referral[];
  paging: { totalItems: number; page: number; perPage: number };
  count: {
    [key: string]: number;
  };
}

export interface ReferralListRequestPayload {
  accountId: string;
  params?: GetReferralListQuery;
  infiniteLoad?: boolean;
}

export const referralApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReferral: builder.query<
      Referral,
      {
        accountId: string;
        referralId: string;
      }
    >({
      query: ({ accountId, referralId }) => ({
        url: `accounts/${accountId}/referrals/${referralId}`
      }),
      providesTags: [CPSTagTypes.Referral]
    }),
    getReferralList: builder.query<ReferralListResponse, ReferralListRequestPayload>({
      query: ({ accountId, params }) => ({
        url: `accounts/${accountId}/referrals`,
        params
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        queryArgs?.infiniteLoad
          ? `${endpointName}_${queryArgs?.params?.searchValue || ''}`
          : `${endpointName}_${parseQueryParams({ ...queryArgs.params })}`,
      merge: (currentCache, newItems, meta) => {
        if (meta.arg.infiniteLoad) {
          if (meta.arg.params?.page === 1) {
            return {
              referrals: newItems.referrals,
              paging: newItems.paging,
              count: newItems.count
            };
          }

          return {
            referrals: [...currentCache.referrals, ...newItems.referrals],
            paging: newItems.paging,
            count: newItems.count
          };
        } else {
          return {
            referrals: newItems.referrals,
            paging: newItems.paging,
            count: newItems.count
          };
        }
      },
      providesTags: [CPSTagTypes.ReferralList],
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.params?.page !== previousArg?.params?.page && currentArg?.params?.page !== 1
    }),
    createReferral: builder.mutation<Referral, PostReferralPayload>({
      query: ({ accountId, payload }) => ({
        url: `/accounts/${accountId}/referrals`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [CPSTagTypes.Referral, CPSTagTypes.ReferralList]
    }),
    updateReferral: builder.mutation({
      query: ({ accountId, referralId, payload }: UpdateReferralPayload) => ({
        url: `/accounts/${accountId}/referrals/${referralId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [CPSTagTypes.Referral, CPSTagTypes.ReferralList]
    }),
    uploadDocumentToReferral: builder.mutation({
      query: ({ accountId, referralId, file }: { accountId: string; referralId: string; file: FormData }) => ({
        url: `accounts/${accountId}/referrals/${referralId}/file`,
        method: 'POST',
        body: file
      }),
      invalidatesTags: [CPSTagTypes.Referral, CPSTagTypes.ReferralList]
    }),
    uploadDocumentsToReferral: builder.mutation({
      query: ({ accountId, referralId, files }: { accountId: string; referralId: string; files: FormData }) => ({
        url: `accounts/${accountId}/referrals/${referralId}/files`,
        method: 'POST',
        body: files
      }),
      invalidatesTags: [CPSTagTypes.Referral, CPSTagTypes.ReferralList]
    }),
    getMatchingClients: builder.query<
      { count: number; clients: ReferralMatchedClient[] },
      {
        accountId: string;
        query: {
          firstName: string;
          lastName: string;
          dateOfBirth?: string;
          providerNumber?: string;
        };
      }
    >({
      query: ({ accountId, query }) => ({
        url: `accounts/${accountId}/referrals/client-matches`,
        params: query
      }),
      providesTags: [CPSTagTypes.ReferralMatchedClients]
    }),
    refreshClientMatches: builder.mutation({
      query: ({ accountId, referralId }: { accountId: string; referralId: string }) => ({
        url: `accounts/${accountId}/referrals/${referralId}/client-matches/refresh`,
        method: 'POST'
      }),
      invalidatesTags: [CPSTagTypes.Referral, CPSTagTypes.ReferralList]
    }),
    addPackage: builder.mutation({
      query: ({
        accountId,
        referralId,
        packageId,
        packageName
      }: {
        accountId: string;
        referralId: string;
        packageId: string;
        packageName: string;
      }) => ({
        url: `accounts/${accountId}/referrals/${referralId}/package`,
        method: 'POST',
        body: {
          packageId,
          packageName
        }
      }),
      invalidatesTags: [CPSTagTypes.Referral, CPSTagTypes.ReferralList]
    }),
    updateReferralStatus: builder.mutation({
      query: ({
        accountId,
        referralId,
        status
      }: {
        accountId: string;
        referralId: string;
        status: ReferralStatus;
      }) => ({
        url: `accounts/${accountId}/referrals/${referralId}/status`,
        method: 'PATCH',
        body: { status }
      }),
      invalidatesTags: [CPSTagTypes.Referral, CPSTagTypes.ReferralList]
    }),
    updateClientRecordId: builder.mutation({
      query: ({
        accountId,
        referralId,
        clientRecordId,
        unlink,
        syncRdf
      }: {
        accountId: string;
        referralId: string;
        clientRecordId: string;
        unlink?: 1 | 0;
        syncRdf?: 1 | 0;
      }) => ({
        url: `accounts/${accountId}/referrals/${referralId}/client-record`,
        method: 'PATCH',
        body: { clientRecordId, unlink, syncRdf }
      }),
      invalidatesTags: [CPSTagTypes.Referral, CPSTagTypes.ReferralList]
    }),
    addReferralNote: builder.mutation({
      query: ({
        accountId,
        referralId,
        payload
      }: {
        accountId: string;
        referralId: string;
        payload: { note: string };
      }) => ({
        url: `/accounts/${accountId}/referrals/${referralId}/note`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [CPSTagTypes.Referral, CPSTagTypes.ReferralList]
    })
  })
});

export const {
  useGetReferralQuery,
  useGetReferralListQuery,
  useCreateReferralMutation,
  useUpdateReferralMutation,
  useUploadDocumentToReferralMutation,
  useUploadDocumentsToReferralMutation,
  useGetMatchingClientsQuery,
  useRefreshClientMatchesMutation,
  useAddPackageMutation,
  useUpdateReferralStatusMutation,
  useUpdateClientRecordIdMutation,
  useAddReferralNoteMutation
} = referralApiSlice;
