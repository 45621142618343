import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import PreviewItemInfo from '../PreviewItemInfo/PreviewItemInfo';
import { MedicareInfoProps } from '../interface';
import styles from './MedicareInfo.module.scss';

const MedicareInfo = ({
  medicareClaimant,
  medicareClaim,
  medicareServiceProvider,
  medicareReferral
}: MedicareInfoProps) => {
  const { isRegionAU } = useGetFeatureToggle();
  return (
    <>
      {medicareClaimant && (
        <div className={styles.medicareBlock}>
          <div className={styles.titleDetail}>Client Identity Details</div>
          <div className={styles.contentRow}>
            <div className={styles.itemInfoBlock}>
              <PreviewItemInfo className={styles.customInfo} label="Name:" value="{{Client Name}}" />
              <PreviewItemInfo className={styles.customInfo} label="Date of birth:" value="{{Date of Birth}}" />
              {isRegionAU && (
                <PreviewItemInfo
                  className={styles.customInfo}
                  label="Medicare Card No:"
                  value="{{Medicare Card Number}}"
                />
              )}
            </div>
            <div className={styles.itemInfoBlock}>
              {isRegionAU && <PreviewItemInfo className={styles.customInfo} label="IRN:" value="{{IRN}}" />}
              <PreviewItemInfo className={styles.customInfo} label="Address:" value="{{Client Address}}" />
              <PreviewItemInfo className={styles.customInfo} label="Phone Number:" value="{{Client Phone Number}}" />
            </div>
          </div>
        </div>
      )}
      {(medicareClaim || medicareServiceProvider || medicareReferral) && (
        <div className={styles.medicareBlock}>
          <div className={styles.contentRow}>
            {isRegionAU && medicareClaim && (
              <div className={styles.itemInfoBlock}>
                <div className={styles.titleDetail}>MBS Claim Details</div>
                <PreviewItemInfo className={styles.customInfo} label="Date of Service:" value="{{Provider Name}}" />
                <PreviewItemInfo className={styles.customInfo} label="MBS Code:" value="{{MBS Code}}" />
              </div>
            )}
            {isRegionAU && medicareServiceProvider && (
              <div className={styles.itemInfoBlock}>
                <div className={styles.titleDetail}>Service Provider Details</div>
                <PreviewItemInfo className={styles.customInfo} label="Provider Name:" value="{{IRN}}" />
                <PreviewItemInfo className={styles.customInfo} label="Provider Number:" value="{{Provider Number}}" />
              </div>
            )}
            {medicareReferral && (
              <div className={styles.itemInfoBlock}>
                <div className={styles.titleDetail}>Referral Details</div>
                <PreviewItemInfo className={styles.customInfo} label="Referral Name:" value="{{Referral Name}}" />
                <PreviewItemInfo className={styles.customInfo} label="Date of referral:" value="{{Referral Date}}" />
                {isRegionAU && (
                  <PreviewItemInfo className={styles.customInfo} label="Provider Number:" value="{{Provider Number}}" />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MedicareInfo;
