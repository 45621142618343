import * as Yup from 'yup';
import moment from 'moment';
import {
  AppointmentType,
  ParticipantType,
  ScheduleOption,
  SessionStartOption
} from 'interfaces/Schedule/AppointmentType';

const initialDayTimeSelection = {
  isActive: false,
  startTime: '08:00',
  endTime: '17:00'
};
const initialFixedTimeSession = {
  isActive: false,
  startTimes: []
};

export const initialValues: Partial<AppointmentType> = {
  gap: 30,
  sessionStartOption: SessionStartOption.OnTheHour,
  dayTimeSelections: {
    kind: ScheduleOption.AutoFit,
    autoFit: {
      Monday: initialDayTimeSelection,
      Tuesday: initialDayTimeSelection,
      Wednesday: initialDayTimeSelection,
      Thursday: initialDayTimeSelection,
      Friday: initialDayTimeSelection,
      Saturday: initialDayTimeSelection,
      Sunday: initialDayTimeSelection
    },
    fixedTime: {
      Monday: initialFixedTimeSession,
      Tuesday: initialFixedTimeSession,
      Wednesday: initialFixedTimeSession,
      Thursday: initialFixedTimeSession,
      Friday: initialFixedTimeSession,
      Saturday: initialFixedTimeSession,
      Sunday: initialFixedTimeSession
    }
  }
};

export const generateInitValue = (participantType?: ParticipantType) => ({
  ...initialValues,
  dayTimeSelections: {
    ...initialValues.dayTimeSelections,
    kind: participantType === ParticipantType.Group ? ScheduleOption.FixedTime : initialValues.dayTimeSelections?.kind
  }
});

export const initialValuesUpdate = (data: AppointmentType) => ({
  ...initialValues,
  gap: data.gap,
  sessionStartOption: data.sessionStartOption,
  ...(data.dayTimeSelections && {
    dayTimeSelections: {
      kind: data.dayTimeSelections.kind,
      autoFit: data.dayTimeSelections.autoFit || initialValues.dayTimeSelections?.autoFit,
      fixedTime: data.dayTimeSelections.fixedTime || initialValues.dayTimeSelections?.fixedTime
    }
  })
});

const timeFormat = 'HH:mm';

export const dayTimeSelectionSchema = Yup.object()
  .shape({
    isActive: Yup.boolean(),
    startTime: Yup.string(),
    endTime: Yup.string()
  })
  .test('autoFitTimeValidate', 'End time must later than start time', (value) => {
    if (!value?.isActive) {
      return true;
    }
    return !moment(value.endTime, timeFormat).isSameOrBefore(moment(value.startTime, timeFormat));
  });

export const fixedTimeSelectionSchema = (duration: number) =>
  Yup.object()
    .shape({
      isActive: Yup.boolean(),
      startTimes: Yup.array(Yup.string()).test(
        'fixedTimeScheduleGapTest',
        'Gap between schedule too short',
        (value) => {
          const TIME_FORMAT = 'h:mm A';
          const inputValue = value as string[];
          return inputValue.length < 2
            ? true
            : inputValue
                .sort((a: string, b: string) => moment(a, TIME_FORMAT).diff(moment(b, TIME_FORMAT)))
                .filter((secondSlot, index) => {
                  if (index > 0) {
                    const gap = moment(secondSlot, TIME_FORMAT).diff(
                      moment(inputValue[index - 1], TIME_FORMAT),
                      'minutes'
                    );
                    if (gap < duration) {
                      return true;
                    }
                  }
                  return false;
                }).length === 0;
        }
      )
    })
    .test('emptyStartTime', 'Please select at least one timeslot', (value) => {
      return !value?.isActive || (value.startTimes?.length || 0) > 0;
    });

export const appointmentScheduleSchema = (duration: number) =>
  Yup.object().shape({
    duration: Yup.number(),
    gap: Yup.string().test({
      name: 'gapTest',
      test: (value: any) => isNaN(value) || value !== 'custom',
      message: 'Please specify the gap between this session and the next session'
    }),
    sessionStartOption: Yup.string()
      .oneOf(['onTheHour', 'onTheHalfHour', 'mixed'])
      .required('Please select when this session should be allowed to start on'),
    dayTimeSelections: Yup.object().shape({
      kind: Yup.string(),
      autoFit: Yup.object().when('kind', {
        is: ScheduleOption.AutoFit,
        then: Yup.object()
          .shape({
            Monday: dayTimeSelectionSchema,
            Tuesday: dayTimeSelectionSchema,
            Wednesday: dayTimeSelectionSchema,
            Thursday: dayTimeSelectionSchema,
            Friday: dayTimeSelectionSchema,
            Saturday: dayTimeSelectionSchema,
            Sunday: dayTimeSelectionSchema
          })
          .test('daySelectionValidate', 'Please select at least one available day', (days: any) => {
            return (
              days?.Monday?.isActive ||
              days?.Tuesday?.isActive ||
              days?.Wednesday?.isActive ||
              days?.Thursday?.isActive ||
              days?.Friday?.isActive ||
              days?.Saturday?.isActive ||
              days?.Sunday?.isActive
            );
          }),
        otherwise: Yup.object()
      }),
      fixedTime: Yup.object().when('kind', {
        is: ScheduleOption.FixedTime,
        then: Yup.object()
          .shape({
            Monday: fixedTimeSelectionSchema(duration),
            Tuesday: fixedTimeSelectionSchema(duration),
            Wednesday: fixedTimeSelectionSchema(duration),
            Thursday: fixedTimeSelectionSchema(duration),
            Friday: fixedTimeSelectionSchema(duration),
            Saturday: fixedTimeSelectionSchema(duration),
            Sunday: fixedTimeSelectionSchema(duration)
          })
          .test('daySelectionValidate', 'Please select at least one available day', (days: any) => {
            return (
              days?.Monday?.isActive ||
              days?.Tuesday?.isActive ||
              days?.Wednesday?.isActive ||
              days?.Thursday?.isActive ||
              days?.Friday?.isActive ||
              days?.Saturday?.isActive ||
              days?.Sunday?.isActive
            );
          }),
        otherwise: Yup.object()
      })
    })
  });
