import { useField } from 'formik';

import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Select, { SelectProps } from './Select';
import { Props } from 'react-select';

import styles from './FormikSelect.module.scss';

interface FormikSelectProps extends Omit<SelectProps, 'hasError'> {
  name: string;
  styles?: Props['styles'];
  errorClassName?: string;
  ignoreTouched?: boolean;
}

const FormikSelect = ({
  className,
  name,
  styles: propStyles,
  errorClassName,
  ignoreTouched,
  ...props
}: FormikSelectProps) => {
  const [field, { error, touched }, { setValue }] = useField(name);

  const innerStyles: Props['styles'] = {
    container: (base) => ({ ...base, margin: 0 }),
    control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: touched && !!error ? `1px solid ${styles.redColor}` : `1px solid ${styles.greyColor}`,
      borderRadius: 0,
      boxShadow: 'none',
      minHeight: 0,
      minWidth: '150px',
      padding: !props.label ? '8px 0 0' : ''
    }),
    valueContainer: (base) => ({ ...base, padding: 0 }),
    dropdownIndicator: (base) => ({ ...base, padding: 0 })
  };

  const handleChange = (
    selected: { label: string; value: any } | readonly { label: string; value: any }[] | null | undefined
  ) => {
    if (Array.isArray(selected)) {
      setValue(selected);
    } else {
      setValue((selected as { label: string; value: any })?.value);
    }
  };

  return (
    <>
      <Select
        {...props}
        {...field}
        defaultValue={field.value}
        styles={{ ...innerStyles, ...propStyles }}
        hasError={(ignoreTouched || touched) && !!error}
        className={className}
        onChange={(event: any) => handleChange(event)}
      />
      <ErrorMessage error={error} visible={ignoreTouched || touched} className={errorClassName} />
    </>
  );
};

export default FormikSelect;
