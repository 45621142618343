import { ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import { useEffect, useState } from 'react';
import { getClinicalAssessmentTemplate } from 'store/CheckIn/GetClinicalAssessmentTemplate/action';

export const useFetchAssessmentList = (token: string) => {
  const [assessmentList, setAssessmentList] = useState<ClinicalAssessmentStruct[]>([]);
  const [isAssessmentLoading, setIsAssessmentLoading] = useState<boolean>(true);

  const fetchAssessmentList = async () => {
    const assessmentList = await getClinicalAssessmentTemplate(token);

    if (Array.isArray(assessmentList)) {
      setAssessmentList(assessmentList);
    }
    setIsAssessmentLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAssessmentList();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { assessmentList, isAssessmentLoading };
};
