import ModalV2 from 'components/ModalV2/ModalV2';
import styles from './NoteModal.module.scss';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { useState } from 'react';
import { Modal, notification } from 'antd';
import { Referral } from 'interfaces/Referral/Referral';
import TextEditor2 from 'components/v2/TextEditor2/TextEditor2';
import classNames from 'classnames';
import { useAddReferralNoteMutation } from 'redux/endpoints/clinicianProfileServices/referral';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

interface NoteModalProps {
  referral: Referral;
  onCloseModal: () => void;
  onSubmitSuccess?: () => void;
}

const NoteModal = ({ referral, onCloseModal, onSubmitSuccess }: NoteModalProps) => {
  const { accountId, _id } = referral;

  const [addNote] = useAddReferralNoteMutation();

  const [note, setNote] = useState<string>('');
  const [visibleModal, setVisibleModal] = useState<boolean>(true);
  const [formError, setFormError] = useState<{ note: string }>({ note: '' });
  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');

  const onChangeNote = (value: string) => {
    setNote(value);
    setFormError({
      ...formError,
      note: ''
    });
  };

  const handleSubmit = async () => {
    const isValidNote = !!note && hasContent(note);
    if (isValidNote && accountId) {
      try {
        setSubmitButtonStatus('active');
        await addNote({
          accountId,
          referralId: _id,
          payload: { note }
        }).unwrap();

        notification.success({
          message: 'Successfully save note',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitButtonStatus('finished');
        onSubmitSuccess && onSubmitSuccess();

        setTimeout(() => {
          setSubmitButtonStatus('');
          handleCloseModal();
        }, 2000);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to save note' });
        setSubmitButtonStatus('');
      }
    } else {
      if (!isValidNote) {
        setFormError({
          note: 'Please enter a note'
        });
      } else {
        notification.error({ message: formError.note });
      }
    }
  };

  const handleCloseModal = () => {
    setVisibleModal(false);
    setNote('');
    setFormError({
      note: ''
    });

    setTimeout(() => {
      onCloseModal();
    }, 500);
  };

  const handleConfirmCose = () => {
    if (note && hasContent(note)) {
      Modal.confirm({
        title: 'Are you sure you want to discard this note?',
        okText: 'Confirm',
        onOk: handleCloseModal
      });
    } else {
      handleCloseModal();
    }
  };

  const hasContent = (note: string) => {
    // Remove HTML tags
    const textOnly = note.replace(/<\/?[^>]+(>|$)/g, '');

    const trimmedText = textOnly.trim();
    return trimmedText.length > 0;
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={visibleModal}
      title="Add Quick Note"
      onModalClose={handleConfirmCose}
      bodyStyle={{ padding: '0' }}
      headerContainerClassName={styles.headerContainerClassName}
      titleClassName={styles.titleClassName}
    >
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={classNames(styles.field, styles.noteField)}>
            <TextEditor2
              height={300}
              value={note}
              onChange={(content) => onChangeNote(content)}
              readOnly={submitButtonStatus === 'active'}
            />
            <ErrorMessage error={formError.note} visible={!!formError.note} />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <ButtonAlt
            status={submitButtonStatus}
            loadingWord="Processing..."
            completedWord="Done"
            onClick={handleSubmit}
          >
            Save
          </ButtonAlt>
        </div>
      </div>
    </ModalV2>
  );
};

export default NoteModal;
