import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import styles from './ClientAttendeesList.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface ClientAttendeesListProps {
  clientAttendees: clientRecordsInterface[];
  isLoading?: boolean;
  onAddClientRecord: (clientRecord: clientRecordsInterface) => void;
  onClickShowContacts: (clientRecordId: string) => void;
}

const ClientAttendeesList = ({
  clientAttendees,
  isLoading,
  onAddClientRecord,
  onClickShowContacts
}: ClientAttendeesListProps) => {
  return isLoading ? (
    <div className={styles.loadingContainer}>
      <LoadingCircle />
    </div>
  ) : (
    <div className={styles.clientAttendeesContainer}>
      {clientAttendees.map((clientAttendee, index) => {
        const { _id, clientProfiles, recordType } = clientAttendee;

        const clientProfileList =
          recordType === 'child'
            ? clientProfiles.filter((profileType) => profileType.role === 'child')
            : clientProfiles;

        return (
          <div className={styles.client} key={index}>
            <div className={styles.clientName}>
              <ClientAvatar
                key={index}
                clientData={clientProfileList}
                avatarSize={30}
                initialsClassName={styles.initialName}
                nameClassName={styles.clientName}
                horizontal
                displayLimit={recordType === 'couple' ? 2 : 1}
                displayFirstNameOnly={recordType === 'couple'}
              />
              <ButtonAlt
                className={styles.addButton}
                contentClassName={styles.btnContent}
                size={'small'}
                variant={'text'}
                icon={'add'}
                onClick={() => onAddClientRecord(clientAttendee)}
              >
                Add
              </ButtonAlt>
            </div>
            {clientProfiles.length > 1 && (
              <ButtonAlt
                contentClassName={styles.btnContent}
                size={'small'}
                variant={'text'}
                icon={'chevron_right'}
                iconPostFix
                onClick={() => onClickShowContacts(_id)}
              >
                Contacts
              </ButtonAlt>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ClientAttendeesList;
