import { Modal, notification } from 'antd';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import PillButtonGroup from 'components/v2/PIllButtonGroup/PIllButtonGroup';
import {
  useFetchCodatAccounts,
  useFetchCodatInvoiceItems,
  useFetchCodatMappings,
  useFetchCodatTaxRates,
  useFetchCodatTrackingCategories
} from 'pages/ControlPanel/ControlPanel/hooks/getCodatData';
import { Mapping, MappingField } from 'pages/ControlPanel/Interfaces/MappingField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import { putCodatMappings } from 'utils/http/BillingService/Invoice/codat';

import MandatoryFieldsInfo from './components/MandatoryFieldsInfo/MandatoryFieldsInfo';
import Mappings from './components/Mappings/Mappings';
import { EditType, getMappingOptions } from './constants';
import styles from './DataMappingModal.module.scss';

const getErrorIds = (input: MappingField[]): { id: string }[] =>
  input.filter((item) => item.active && !item.selectedId).map(({ id }) => ({ id }));

interface DataMappingModalProps {
  integrationId: string;
  provider: string;
  visible: boolean;
  setShowDataMappingModal: (bool: boolean) => void;
  refetchSystemIntegration: () => void;
}

const DataMappingModal = ({
  integrationId,
  provider,
  visible,
  setShowDataMappingModal,
  refetchSystemIntegration
}: DataMappingModalProps) => {
  const [t] = useTranslation();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const isXero = provider === 'xero';
  const { isHelmFeatureToggle, isCaWFeatureToggle } = useGetFeatureToggle();

  const [editType, setEditType] = useState<EditType>(EditType.Fields);
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');
  const { taxRateOptions, isTaxRateLoading } = useFetchCodatTaxRates(token, integrationId);
  const { accountOptions, paymentAccountOptions, isAccountLoading } = useFetchCodatAccounts(token, integrationId);
  const { trackingCategoryList, trackingCategoriesOptions } = useFetchCodatTrackingCategories(token, integrationId);
  const { invoiceItemOptions, isInvoiceItemLoading } = useFetchCodatInvoiceItems(token, integrationId);
  const [trackingCategories, setTrackingCategories] = useState<MappingField[]>([]);
  const {
    fields,
    setFields,
    accounts,
    setAccounts,
    items,
    setItems,
    payments,
    setPayments,
    trackingCategoriesMapping,
    isMappingsLoading
  } = useFetchCodatMappings(token, accountId, integrationId, isXero);

  const [fieldValidationError, setFieldValidationError] = useState<{ id: string }[]>([]);
  const [billableAccountValidationError, setBillableAccountValidationError] = useState<{ id: string }[]>([]);
  const [paymentValidationError, setPaymentValidationError] = useState<{ id: string }[]>([]);
  const [itemValidationError, setItemValidationError] = useState<{ id: string }[]>([]);
  const [trackingCategoriesValidationError, setTrackingCategoriesValidationError] = useState<{ id: string }[]>([]);

  useEffect(() => {
    if (trackingCategoriesMapping) {
      const newList = trackingCategoryList.map((item) => {
        const foundMapping = trackingCategoriesMapping.find((mapping) => mapping.id === item.id);
        if (foundMapping) {
          return { ...item, active: foundMapping.active, selectedId: foundMapping.to };
        }
        return item;
      });
      setTrackingCategories(newList);
    } else {
      setTrackingCategories(trackingCategoryList);
    }
  }, [trackingCategoryList, trackingCategoriesMapping]);

  const getOptions = () => {
    switch (editType) {
      case EditType.Fields:
        return {
          itemList: fields,
          dropdownOptions: getMappingOptions(t, isHelmFeatureToggle || isCaWFeatureToggle),
          validationErrors: fieldValidationError,
          isLoading: false
        };
      case EditType.Accounts:
        return {
          itemList: accounts,
          dropdownOptions: accountOptions,
          validationErrors: billableAccountValidationError,
          isLoading: isAccountLoading
        };
      case EditType.Items:
        return {
          itemList: items,
          dropdownOptions: invoiceItemOptions,
          validationErrors: itemValidationError,
          isLoading: isInvoiceItemLoading
        };
      case EditType.Payments:
        return {
          itemList: payments,
          dropdownOptions: paymentAccountOptions,
          validationErrors: paymentValidationError,
          isLoading: isAccountLoading
        };
    }
  };
  const options = getOptions();

  const EDIT_OPTIONS: { value: string; label: string }[] = isXero
    ? [
        { value: EditType.Fields, label: 'Fields' },
        { value: EditType.Accounts, label: 'Accounts' },
        { value: EditType.Items, label: 'Invoice Items' },
        { value: EditType.Payments, label: 'Payments' }
      ]
    : [
        { value: EditType.Fields, label: 'Fields' },
        { value: EditType.Accounts, label: 'Accounts' },
        { value: EditType.Payments, label: 'Payments' }
      ];

  useEffect(() => {
    const errorIds = getErrorIds(fields);
    setFieldValidationError(errorIds);
  }, [fields]);

  useEffect(() => {
    const errorIds = getErrorIds(accounts);
    setBillableAccountValidationError(errorIds);
  }, [accounts]);

  useEffect(() => {
    const errorIds = getErrorIds(payments);
    setPaymentValidationError(errorIds);
  }, [payments]);

  useEffect(() => {
    if (isXero) {
      const errorIds = getErrorIds(items);
      setItemValidationError(errorIds);
    }
  }, [items, isXero]);

  useEffect(() => {
    const errorIds = getErrorIds(trackingCategories);
    setTrackingCategoriesValidationError(errorIds);
  }, [trackingCategories]);

  const handleTypeChange = (newType: string) => {
    setEditType(newType as EditType);
  };

  const handleEnabledChanged = (index: number) => {
    if (editType === EditType.Fields) {
      const newFields = fields.map((field, fieldIndex) =>
        fieldIndex === index ? { ...field, active: !field.active } : field
      );
      setFields(newFields);
    } else if (editType === EditType.Accounts) {
      const newAccounts = accounts.map((account, accIndex) =>
        accIndex === index ? { ...account, active: !account.active } : account
      );
      setAccounts(newAccounts);
    } else if (editType === EditType.Items) {
      const newItems = items.map((item, itemIndex) => (itemIndex === index ? { ...item, active: !item.active } : item));
      setItems(newItems);
    } else if (editType === EditType.Payments) {
      const newPayments = payments.map((account, accIndex) =>
        accIndex === index ? { ...account, active: !account.active } : account
      );
      setPayments(newPayments);
    }
  };

  const handleTrackingCategoriesEnabledChanged = (index: number) => {
    const newTrackingCategories = trackingCategories.map((category, categoryIndex) =>
      categoryIndex === index ? { ...category, active: !category.active } : category
    );
    setTrackingCategories(newTrackingCategories);
  };

  const handleSelectedChanged = (index: number, id: string) => {
    if (editType === EditType.Fields) {
      const newFields = fields.map((field, fieldIndex) =>
        fieldIndex === index ? { ...field, selectedId: id } : field
      );
      setFields(newFields);
    } else if (editType === EditType.Accounts) {
      const newAccounts = accounts.map((account, accIndex) =>
        accIndex === index ? { ...account, selectedId: id } : account
      );
      setAccounts(newAccounts);
    } else if (editType === EditType.Items) {
      const newItems = items.map((item, itemIndex) => (itemIndex === index ? { ...item, selectedId: id } : item));
      setItems(newItems);
    } else if (editType === EditType.Payments) {
      const newPayments = payments.map((account, accIndex) =>
        accIndex === index ? { ...account, selectedId: id } : account
      );
      setPayments(newPayments);
    }
  };

  const handleTrackingCategoriesSelectedChanged = (index: number, id: string) => {
    const newTrackingCategories = trackingCategories.map((category, categoryIndex) =>
      categoryIndex === index ? { ...category, selectedId: id } : category
    );
    setTrackingCategories(newTrackingCategories);
  };

  const handleSubmit = async () => {
    if (
      fieldValidationError.length > 0 ||
      billableAccountValidationError.length > 0 ||
      itemValidationError.length > 0 ||
      paymentValidationError.length > 0 ||
      trackingCategoriesValidationError.length > 0
    ) {
      if (
        billableAccountValidationError.length > 0 &&
        fieldValidationError.length === 0 &&
        paymentValidationError.length === 0 &&
        itemValidationError.length === 0 &&
        trackingCategoriesValidationError.length === 0
      ) {
        setEditType(EditType.Accounts);
      } else if (
        (fieldValidationError.length > 0 || trackingCategoriesValidationError.length > 0) &&
        paymentValidationError.length === 0 &&
        billableAccountValidationError.length === 0 &&
        itemValidationError.length === 0
      ) {
        setEditType(EditType.Fields);
      } else if (
        itemValidationError.length > 0 &&
        paymentValidationError.length === 0 &&
        billableAccountValidationError.length === 0 &&
        fieldValidationError.length === 0 &&
        trackingCategoriesValidationError.length === 0
      ) {
        setEditType(EditType.Items);
      } else if (
        paymentValidationError.length > 0 &&
        fieldValidationError.length === 0 &&
        billableAccountValidationError.length === 0 &&
        itemValidationError.length === 0 &&
        trackingCategoriesValidationError.length === 0
      ) {
        setEditType(EditType.Payments);
      }
    } else {
      try {
        setSubmitStatus('active');
        const fieldsPayload = fields
          .map(({ id, selectedId, active }) => ({ field: id, to: selectedId, active }))
          .reduce(
            (acc, curr) => ({ [curr.field]: { to: curr.to, active: curr.active }, ...acc }),
            {}
          ) as Mapping['fields'];
        fieldsPayload.trackingCategories = trackingCategories.map(({ id, selectedId, active }) => ({
          id,
          to: selectedId,
          active
        }));
        const paymentPayload = payments
          .map(({ id, selectedId, active }) => ({ field: id, to: selectedId, active }))
          .reduce((acc, curr) => ({ [curr.field]: { to: curr.to, active: curr.active }, ...acc }), {});
        const accountsPayload = accounts.map(({ id, selectedId, active }) => ({
          clinicianId: id,
          to: selectedId,
          active
        }));
        const itemsPayload = items.map(({ id, selectedId, active }) => ({
          id,
          to: selectedId,
          active
        }));

        await putCodatMappings(token, integrationId, {
          fields: fieldsPayload,
          accounts: accountsPayload,
          items: isXero ? itemsPayload : [],
          payments: paymentPayload
        });

        notification.success({
          message: 'Data mapping updated',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitStatus('finished');
        setShowDataMappingModal(false);
        refetchSystemIntegration();
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to update data mapping' });
      }
      setSubmitStatus('');
    }
  };

  return (
    <Modal
      className={styles.container}
      open={visible}
      closable
      title={
        <div className={styles.header}>
          <div className={styles.title}>Edit Mapping</div>
        </div>
      }
      onCancel={() => setShowDataMappingModal(false)}
      destroyOnClose
      footer={null}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      {isMappingsLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <div className={styles.body}>
            <div className={styles.typeToggleContainer}>
              <PillButtonGroup options={EDIT_OPTIONS} value={editType} onChange={handleTypeChange} />
            </div>
            <Mappings
              itemList={options.itemList}
              editType={editType}
              provider={provider}
              dropdownOption={options.dropdownOptions}
              taxRateOptions={taxRateOptions}
              trackingCategories={trackingCategories}
              trackingCategoriesOptions={trackingCategoriesOptions}
              validationErrors={options.validationErrors}
              trackingCategoriesValidationError={trackingCategoriesValidationError}
              isTaxRateLoading={isTaxRateLoading}
              isLoading={options.isLoading}
              handleEnabledChanged={handleEnabledChanged}
              handleSelectedChanged={handleSelectedChanged}
              handleTrackingCategoryEnabledChanged={handleTrackingCategoriesEnabledChanged}
              handleTrackingCategorySelectedChanged={handleTrackingCategoriesSelectedChanged}
            />
            {editType === EditType.Fields && <MandatoryFieldsInfo provider={provider} />}
          </div>
          <div className={styles.footer}>
            <Button className={styles.saveButton} onClick={handleSubmit} status={submitStatus}>
              Save
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default DataMappingModal;
