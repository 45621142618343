import { useState } from 'react';

import styles from './ReferralDocuments.module.scss';

import { referralFilesInterface } from 'interfaces/Clients/clientsRecord';
import { notification } from 'antd';
import classNames from 'classnames';
import { getDecryptedDocument } from 'utils/http/ClinicianProfileService/DecryptedDocument/download';
import mime from 'mime';
import { useGetAccessToken } from 'utils/hooks/token';
import moment from 'moment';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { Referral } from 'interfaces/Referral/Referral';
import { useUploadDocumentToReferralMutation } from 'redux/endpoints/clinicianProfileServices/referral';
import DocumentViewerModal from 'components/DocumentViewerModal/DocumentViewerModal';

interface ReferralDocumentsProps {
  referral: Referral;
}

const ReferralDocuments = ({ referral }: ReferralDocumentsProps) => {
  const { token } = useGetAccessToken();

  const [uploadStatus, setUploadStatus] = useState<'active' | 'finished' | ''>('');
  const [viewAttachment, setViewAttachment] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<{ fileName: string; bucketName: string }>({
    fileName: '',
    bucketName: ''
  });

  const [uploadDocToReferral] = useUploadDocumentToReferralMutation();

  const handleDownloadAttachment = async (attachment: referralFilesInterface) => {
    try {
      const callGetDecryptedDocument = await getDecryptedDocument(token, attachment.bucketName, attachment.fileName);

      const decryptedDocument = await callGetDecryptedDocument.arrayBuffer();

      const documentBlob = new Blob([decryptedDocument], {
        type: mime.getType(attachment.fileName) || 'application/octet-stream'
      });

      window.open(URL.createObjectURL(documentBlob), '_blank');
    } catch (ex) {
      console.error(ex);

      notification.error({ message: 'Something went wrong while trying to download this attachment.' });
    }
  };

  const onAddRefAttachment = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = async (event) => {
      const files = (event.target as HTMLInputElement).files;
      if (!files || files.length === 0) {
        return;
      }

      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);

      if (!referral || !referral._id || !referral.accountId) {
        return;
      }

      try {
        setUploadStatus('active');

        await uploadDocToReferral({
          accountId: referral.accountId,
          referralId: referral._id,
          file: formData
        });

        setUploadStatus('finished');

        notification.success({ message: 'Document uploaded successfully.' });
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Failed to upload document.' });

        setUploadStatus('');
      }
    };
    input.click();
  };

  const referralFiles = [...(referral?.files ?? []), ...(referral?.treatmentPlanFiles ?? [])];

  return (
    <div className={styles.container}>
      <div className={styles.title}>Referral Documents</div>
      <div className={styles.referralContent}>
        {referralFiles.map((fileObj, index) => {
          const formattedFileName = fileObj.fileName.substring(fileObj.fileName.indexOf('-') + 1);
          const formattedCreatedTime = moment(fileObj.createdAt).format(MOMENTJS_DATE_FORMAT);

          return (
            <div key={index} className={styles.refDocRecord}>
              <div className={styles.refDocName}>
                <i className={classNames('material-icons', styles.listIcon)}>done</i>
                {formattedFileName}
              </div>

              <div className={styles.refDocDate}>
                {fileObj.treatmentPlanDate && <>Date of Plan: {fileObj.treatmentPlanDate}</>}
              </div>

              <div className={styles.refDocAddedDetails}>
                <div>
                  Added <b>{formattedCreatedTime}</b>
                  {fileObj.uploaderName ? ` by ${fileObj.uploaderName}` : ' by the referee'}
                </div>
              </div>

              <div className={styles.viewAction}>
                <ButtonAlt
                  contentClassName={styles.buttonContent}
                  variant={'text'}
                  size={'small'}
                  onClick={() => {
                    setViewAttachment(true);
                    setSelectedAttachment(fileObj);
                  }}
                  icon={'attach_file'}
                >
                  View
                </ButtonAlt>
                <ButtonAlt
                  contentClassName={styles.buttonContent}
                  variant={'text'}
                  size={'small'}
                  onClick={() => handleDownloadAttachment(fileObj)}
                  icon={'attach_file'}
                >
                  Download
                </ButtonAlt>
              </div>
            </div>
          );
        })}
      </div>
      <DocumentViewerModal
        title={selectedAttachment?.fileName}
        bucketName={selectedAttachment.bucketName}
        visible={viewAttachment}
        onCancel={() => {
          setViewAttachment(false);
          setSelectedAttachment({
            bucketName: '',
            fileName: ''
          });
        }}
      />
      <ButtonAlt
        variant={'outlined'}
        onClick={async () => {
          onAddRefAttachment();
        }}
        icon={'add_circle_outline'}
        status={uploadStatus}
      >
        Upload Document
      </ButtonAlt>
    </div>
  );
};

export default ReferralDocuments;
