import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { MedicareItem, MedicareItemFormat, MedicareItemMode, MedicareItemDuration } from '../interfaces';

export const NURSE_PRACTITIONER_ITEMS: MedicareItem[] = [
  {
    mbsCode: '82200',
    description:
      'Professional attendance by a participating nurse practitioner for an obvious problem characterised by the straightforward nature of the task that requires a short patient history and, if required, limited examination and management.',
    benefit: 12.1,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '82205',
    description:
      'Professional attendance by a participating nurse practitioner, lasting less than 20 minutes and including any of the following: taking a history, undertaking clinical examination, arranging any necessary investigation, implementing a management plan, or providing appropriate preventive health care, for 1 or more health related issues, with appropriate documentation.',
    benefit: 26.4,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan20
  },
  {
    mbsCode: '82210',
    description:
      'Professional attendance by a participating nurse practitioner, lasting at least 20 minutes and including any of the following: taking a history, undertaking clinical examination, arranging any necessary investigation, implementing a management plan, or providing appropriate preventive health care, for 1 or more health related issues, with appropriate documentation.',
    benefit: 50.05,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan20
  },
  {
    mbsCode: '82215',
    description:
      'Professional attendance by a participating nurse practitioner, lasting at least 40 minutes and including any of the following: taking a history, undertaking clinical examination, arranging any necessary investigation, implementing a management plan, or providing appropriate preventive health care, for 1 or more health related issues, with appropriate documentation.',
    benefit: 73.8,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan40
  },
  {
    mbsCode: '91192',
    description:
      'Telehealth attendance by a participating nurse practitioner for an obvious problem characterised by the straightforward nature of the task that requires a short patient history and, if required, limited management.',
    benefit: 12.1,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '91178',
    description:
      'Telehealth attendance by a participating nurse practitioner, lasting less than 20 minutes if the attendance includes any of the following that are clinically relevant: taking a short history, arranging any necessary investigation, implementing a management plan, or providing appropriate preventive health care.',
    benefit: 26.4,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan20
  },
  {
    mbsCode: '91179',
    description:
      'Telehealth attendance by a participating nurse practitioner, lasting at least 20 minutes if the attendance includes any of the following that are clinically relevant: taking a detailed history, arranging any necessary investigation, implementing a management plan, or providing appropriate preventive health care.',
    benefit: 50.05,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan20
  },
  {
    mbsCode: '91180',
    description:
      'Telehealth attendance by a participating nurse practitioner, lasting at least 40 minutes if the attendance includes any of the following that are clinically relevant: taking a extensive history, arranging any necessary investigation, implementing a management plan, or providing appropriate preventive health care.',
    benefit: 73.8,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan40
  },
  {
    mbsCode: '91193',
    description:
      'Phone attendance by a participating nurse practitioner for an obvious problem characterised by the straightforward nature of the task that requires a short patient history and, if required, limited management.',
    benefit: 12.1,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '91189',
    description:
      'Phone attendance by a participating nurse practitioner, lasting less than 20 minutes if the attendance includes any of the following that are clinically relevant: taking a short history, arranging any necessary investigation, implementing a management plan, or providing appropriate preventive health care.',
    benefit: 26.4,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan20
  },
  {
    mbsCode: '91190',
    description:
      'Phone attendance by a participating nurse practitioner, lasting at least 20 minutes if the attendance includes any of the following that are clinically relevant: taking a detailed history, arranging any necessary investigation, implementing a management plan, or providing appropriate preventive health care.',
    benefit: 50.05,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan20
  },
  {
    mbsCode: '91191',
    description:
      'Phone attendance by a participating nurse practitioner, lasting at least 40 minutes if the attendance includes any of the following that are clinically relevant: taking a extensive history, arranging any necessary investigation, implementing a management plan, or providing appropriate preventive health care.',
    benefit: 73.8,
    role: ClinicianMedicareRole.NursePractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan40
  }
];
