import { useState } from 'react';

import styles from './BasicDetailHeaderEdit.module.scss';
import Modal from 'components/Modal/Modal';
import { useUpdateInvoiceContactDetailsMutation } from 'redux/endpoints/billingServices/invoiceSetting';
import { notification } from 'antd';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { Formik } from 'formik';
import { InvoiceContactDetails } from '../../../../../../../Invoices/interface';
import { config } from 'config/config';
import FormikPlaceMaterialInput from 'components/FormikPlaceMaterialInput/FormikPlaceMaterialInput';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import * as yup from 'yup';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

const { mobileNumberMinLength } = config;

const BasicDetailHeaderSchema = yup.object().shape({
  contactDetails: yup.object().shape({
    practice: yup.object().shape({
      mobileNumber: yup.object().shape({
        value: yup
          .string()
          .min(mobileNumberMinLength, `Mobile number has to be at least ${mobileNumberMinLength} characters`)
          .nullable()
      })
    }),
    clinician: yup.object().shape({
      mobileNumber: yup.object().shape({
        value: yup
          .string()
          .min(mobileNumberMinLength, `Mobile number has to be at least ${mobileNumberMinLength} characters`)
          .nullable()
      }),
      email: yup.object().shape({
        value: yup.string().email('Please enter a valid email address').nullable()
      })
    })
  })
});

interface BasicDetailHeadingEditProps {
  showClinicianHeader: boolean;
  visible: boolean;
  onCloseModal: () => void;
  clinicianDetails: any;
  clinicianContactDetails: any;
}

const BasicDetailHeaderEdit = ({
  showClinicianHeader,
  visible,
  onCloseModal,
  clinicianDetails,
  clinicianContactDetails
}: BasicDetailHeadingEditProps) => {
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');
  const [t] = useTranslation();
  const [updateInvoiceContactDetails] = useUpdateInvoiceContactDetailsMutation();

  const handleSubmit = async (values: InvoiceContactDetails) => {
    setSaveStatus('active');
    try {
      await updateInvoiceContactDetails({ payload: values.contactDetails }).unwrap();

      setSaveStatus('finished');
      notification.success({
        message: 'Invoice contact detail updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>,
        onClose: () => setSaveStatus('')
      });
      setTimeout(() => {
        onCloseModal();
      }, 1000);
    } catch (ex) {
      setSaveStatus('');
      notification.error({
        message: 'Something went wrong while trying to update your invoice contact details',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const onSubmit = (e: any, submitForm: () => void) => {
    e.preventDefault();
    submitForm();
  };

  return (
    <Modal isModalOpen={visible}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            Invoice header contact details
            <i className={`material-icons ${styles.tooltipIcon}`} data-tooltip-id="invoiceHeaderSetting">
              help_outline
            </i>
            <Tooltip id="invoiceHeaderSetting" className={styles.tooltip}>
              Select and edit what contact details show in your invoice. This will be saved for any future invoices as
              well. To update your profile picture or practice logo please go to your profile page and update them
              there.
            </Tooltip>
          </div>
          <i onClick={onCloseModal} className={`material-icons ${styles.icon}`}>
            close
          </i>
        </div>
        <Formik
          initialValues={clinicianContactDetails}
          onSubmit={handleSubmit}
          validationSchema={BasicDetailHeaderSchema}
          enableReinitialize
        >
          {({ errors, values, setFieldValue, submitForm }) => (
            <>
              <div className={styles.content}>
                {clinicianDetails.practice && (
                  <div className={styles.profileInfo}>
                    {clinicianDetails.practice?.logo && (
                      <img className={styles.image} src={clinicianDetails.practice.logo} alt="Practice logo" />
                    )}
                    <div className={styles.detailsWrapper}>
                      <span className={styles.detailsTitle}>{clinicianDetails.practice?.name}</span>
                      <div className={styles.detailInfoWrapper}>
                        <div className={styles.inputWrapper}>
                          <label className={styles.checkboxContainer} htmlFor={'practiceAddress'}>
                            <input
                              className={styles.checkbox}
                              id="practiceAddress"
                              type="checkbox"
                              checked={values.contactDetails.practice.address.isVisible}
                              onChange={(e) =>
                                setFieldValue('contactDetails.practice.address.isVisible', e.target.checked)
                              }
                            />
                          </label>
                          <FormikPlaceMaterialInput
                            name={'contactDetails.practice.address.value'}
                            placeholder={'Practice Address'}
                            label={'Practice Address'}
                            className={styles.detailInput}
                          />
                        </div>
                        <div className={styles.inputWrapper}>
                          <label className={styles.checkboxContainer} htmlFor={'practiceMobile'}>
                            <input
                              className={styles.checkbox}
                              id="practiceMobile"
                              type="checkbox"
                              checked={values.contactDetails.practice.mobileNumber.isVisible}
                              onChange={(e) =>
                                setFieldValue('contactDetails.practice.mobileNumber.isVisible', e.target.checked)
                              }
                            />
                          </label>
                          <div className={styles.questionWrapper}>
                            <MaterialInput
                              id={'practiceMobileNumber'}
                              label={'Practice Mobile number'}
                              placeholder={'Practice Mobile number'}
                              value={values.contactDetails.practice.mobileNumber.value}
                              className={styles.detailInput}
                              onChange={(e) =>
                                setFieldValue('contactDetails.practice.mobileNumber.value', e.target.value)
                              }
                            />
                            <ErrorMessage
                              error={errors.contactDetails?.practice?.mobileNumber?.value}
                              visible={!!errors.contactDetails?.practice?.mobileNumber?.value}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {clinicianDetails.clinician && showClinicianHeader && (
                  <div className={styles.profileInfo}>
                    {clinicianDetails.clinician.avatar && (
                      <img
                        className={styles.clinicianImage}
                        src={clinicianDetails.clinician.avatar}
                        alt="Clinician avatar"
                      />
                    )}
                    <div className={styles.detailsWrapper}>
                      <span className={styles.detailsTitle}>{clinicianDetails.clinician.name}</span>
                      <div className={styles.detailInfoWrapper}>
                        <div className={styles.inputWrapper}>
                          <label className={styles.checkboxContainer} htmlFor={'clinicianMobile'}>
                            <input
                              className={styles.checkbox}
                              id="clinicianMobile"
                              type="checkbox"
                              checked={values.contactDetails.clinician.mobileNumber.isVisible}
                              onChange={(e) =>
                                setFieldValue('contactDetails.clinician.mobileNumber.isVisible', e.target.checked)
                              }
                            />
                          </label>
                          <div className={styles.questionWrapper}>
                            <MaterialInput
                              id={'practiceMobile'}
                              label={t('form.practitioner_mobile')}
                              placeholder={t('form.practitioner_mobile')}
                              value={values.contactDetails.clinician.mobileNumber.value}
                              className={styles.detailInput}
                              onChange={(e) =>
                                setFieldValue('contactDetails.clinician.mobileNumber.value', e.target.value)
                              }
                            />
                            <ErrorMessage
                              error={errors.contactDetails?.clinician?.mobileNumber?.value}
                              visible={!!errors.contactDetails?.clinician?.mobileNumber?.value}
                            />
                          </div>
                        </div>
                        <div className={styles.inputWrapper}>
                          <label className={styles.checkboxContainer} htmlFor={'clinicianEmail'}>
                            <input
                              className={styles.checkbox}
                              id="clinicianEmail"
                              type="checkbox"
                              checked={values.contactDetails.clinician.email.isVisible}
                              onChange={(e) =>
                                setFieldValue('contactDetails.clinician.email.isVisible', e.target.checked)
                              }
                            />
                          </label>
                          <div className={styles.questionWrapper}>
                            <MaterialInput
                              id={'practiceEmail'}
                              label={t('form.practitioner_email')}
                              placeholder={t('form.practitioner_email')}
                              value={values.contactDetails.clinician.email.value}
                              className={styles.detailInput}
                              onChange={(e) => setFieldValue('contactDetails.clinician.email.value', e.target.value)}
                            />
                            <ErrorMessage
                              error={errors.contactDetails?.clinician?.email?.value}
                              visible={!!errors.contactDetails?.clinician?.email?.value}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.buttonWrapper}>
                <ButtonAlt status={saveStatus} onClick={(e) => onSubmit(e, submitForm)}>
                  Save
                </ButtonAlt>
              </div>
            </>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default BasicDetailHeaderEdit;
