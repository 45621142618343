import styles from './CarePlanNewAppointment.module.scss';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import {
  DEFAULT_APPOINTMENT_TYPES_PER_PAGES,
  useGetAppointmentTypesQuery
} from 'redux/endpoints/scheduleServices/appointmentType';
import { useCallback, useMemo, useState } from 'react';
import { AppointmentType, AssignmentMode, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import {
  selectAppointmentTypeItems,
  selectCarePlanEditorSlice,
  setAppointmentTypeItems
} from 'redux/carePlan/carePlanEditorSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import classnames from 'classnames';
import { useAddCarePathwayItemMutation } from 'redux/endpoints/clinicianProfileServices/carePathway';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { CarePathwayAppointmentItem } from 'interfaces/CarePathway/CarePathway';
import { debounce } from 'lodash';

const CarePlanNewAppointment = () => {
  const dispatch = useAppDispatch();
  const { accountId } = useGetAccountId();
  const carePlanData = useAppSelector(selectCarePlanEditorSlice);
  const appointmentTypeList = useAppSelector(selectAppointmentTypeItems);

  const [addCarePathwayItem] = useAddCarePathwayItemMutation();

  const [addNewItemStatus, setAddNewItemStatus] = useState<ButtonStatusType>('');
  const [searchAppointmentType, setSearchAppointmentType] = useState('');
  const [apptListCurrentPage, setApptTypeListCurrentPage] = useState<number>(1);

  const {
    data: appointmentTypeData,
    isLoading: appointmentTypeLoading,
    isFetching: appointmentTypeFetching
  } = useGetAppointmentTypesQuery({
    name: searchAppointmentType,
    page: apptListCurrentPage,
    perPage: DEFAULT_APPOINTMENT_TYPES_PER_PAGES,
    participantType: [ParticipantType.Activity, ParticipantType.OneToOne].join(','),
    isActive: '1',
    infiniteLoad: true
  });

  const totalApptListPage = useMemo(
    () =>
      appointmentTypeData?.paging
        ? Math.ceil(appointmentTypeData.paging.totalItems / DEFAULT_APPOINTMENT_TYPES_PER_PAGES)
        : 1,
    [appointmentTypeData]
  );

  const loadMoreApptType = useCallback(() => {
    const page = appointmentTypeData?.paging.page || 1;
    if (!appointmentTypeFetching && page < totalApptListPage) {
      setApptTypeListCurrentPage(page + 1);
    }
  }, [totalApptListPage, appointmentTypeFetching, appointmentTypeData]);

  const hasMoreData = useMemo(() => totalApptListPage > apptListCurrentPage, [totalApptListPage, apptListCurrentPage]);

  const debounceSetSearchText = useMemo(
    () =>
      debounce((value) => {
        setSearchAppointmentType(value);
        setApptTypeListCurrentPage(1);
      }, 1000),
    []
  );

  const appointmentTypeOptions = appointmentTypeData
    ? appointmentTypeData.data
        .filter((appointmentType) => {
          return (
            appointmentType.assignmentMode &&
            [AssignmentMode.All, AssignmentMode.Selected, AssignmentMode.Single].includes(
              appointmentType.assignmentMode
            )
          );
        })
        .map((item) => ({
          value: item._id || '',
          label: item.name
        }))
    : [];

  const handleSelectAppointmentType = async (appointmentData?: AppointmentType) => {
    if (appointmentData) {
      const newItemData: CarePathwayAppointmentItem = {
        appointmentType: appointmentData,
        appointmentTypeId: appointmentData._id || ''
      };

      try {
        setAddNewItemStatus('active');
        const result = await addCarePathwayItem({
          accountId,
          carePathwayId: carePlanData?._id || '',
          payload: newItemData
        });
        setAddNewItemStatus('finished');

        if ('data' in result) {
          const massageResultWithId = {
            ...newItemData,
            _id: result.data._id
          };
          dispatch(setAppointmentTypeItems([...appointmentTypeList, massageResultWithId]));
          setAddNewItemStatus('');
        }
      } catch (error) {
        setAddNewItemStatus('');
        console.error('Error updating care pathway item:', error);
      }
    }
  };

  return (
    <div className={styles.container}>
      <DropdownSearchable
        className={styles.dropdownContainer}
        controllerClassName={styles.dropdownBtn}
        labelClassName={styles.dropdownLabel}
        optionLabelClassName={styles.optionLabel}
        optionWrapperClassName={classnames(
          styles.optionWrapperClassName,
          appointmentTypeList.length > 1 && styles.optionWrapperTop
        )}
        placeholder={'Add Appointment'}
        searchable
        hideActiveLine
        selected={''}
        disabled={!carePlanData.clientRecord || !carePlanData.leadClinician || addNewItemStatus !== ''}
        options={appointmentTypeOptions}
        isLoading={appointmentTypeLoading}
        hasMoreData={hasMoreData}
        loadMore={loadMoreApptType}
        onSelect={(value) =>
          handleSelectAppointmentType(appointmentTypeData?.data?.find((optionObj) => optionObj._id === value))
        }
        disabledColor
        searchText={searchAppointmentType}
        setSearchText={debounceSetSearchText}
        isFetchingMore={appointmentTypeFetching}
      />
    </div>
  );
};

export default CarePlanNewAppointment;
