import styles from './GPDetailTooltip.module.scss';
import { GeneralPractitionerInterface } from '../../../../interface';
import { Tooltip } from 'react-tooltip';
import { checkingGPField } from '../../AddEditClientGPModal';

interface GPDetailTooltipProps {
  id: string;
  gp: GeneralPractitionerInterface;
  showMedicareNumber?: boolean;
}

const GPDetailTooltip = ({ id, gp, showMedicareNumber }: GPDetailTooltipProps) => {
  return checkingGPField(gp) ? (
    <div className={styles.container}>{showMedicareNumber ? `${gp.medicareProviderNumber} (${gp.name})` : gp.name}</div>
  ) : (
    <div className={styles.container} data-tooltip-id={id}>
      {showMedicareNumber ? `${gp.medicareProviderNumber} (${gp.name})` : gp.name}
      <Tooltip id={id} className={styles.tooltip} delayShow={300}>
        <div className={styles.detailsWrapper}>
          {gp.practiceName && <div className={styles.value}>Practice: {gp.name}</div>}
          {gp.address && <div className={styles.value}>Address: {gp.address}</div>}
          {gp.landline && <div className={styles.value}>Landline: {gp.landline}</div>}
          {gp.mobileNumber && <div className={styles.value}>Mobile number: {gp.mobileNumber}</div>}
          {gp.email && <div className={styles.value}>Email: {gp.email}</div>}
          {gp.fax && <div className={styles.value}>Fax: {gp.fax}</div>}
        </div>
      </Tooltip>
    </div>
  );
};

export default GPDetailTooltip;
