export const InvoicePaymentsListColumn = [
  {
    id: 'chargeId',
    sortAble: false
  },
  {
    id: 'client',
    sortAble: false
  },
  {
    id: 'service',
    sortAble: false
  },
  {
    id: 'serviceDate',
    sortAble: false
  },
  {
    id: 'received',
    sortAble: false //TODO: Revert to true after fix performance issue
  },
  {
    id: 'amount',
    sortAble: false
  },
  {
    id: 'invoice',
    sortAble: false
  }
];
