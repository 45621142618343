import styles from './ReferralsHeader.module.scss';
import columnStyles from './ReferralsColumn.module.scss';
import ListHeaderItem from 'components/T23/ListHeaderItem/ListHeaderItem';
import { getReferralListColumns } from './columns';
import { getColumnNameFromId } from 'components/T23/ListHeaderItem/helper';
import { Sorting } from 'redux/features/utils';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { ReferralTab } from 'pages/Referrals/interface';

interface ReferralsHeaderProps {
  activeTab: ReferralTab;
  sorting: Sorting;
  onClickSort: (newSortBy: string) => void;
}

const ReferralsHeader = ({ activeTab, sorting, onClickSort }: ReferralsHeaderProps) => {
  const { isPackageEnabled } = useGetFeatureToggle();

  const { sortBy, sortType } = sorting;

  return (
    <div className={styles.container}>
      {getReferralListColumns({ isPackageEnabled, activeTab }).map((column, index) => (
        <ListHeaderItem
          key={index}
          columnId={column.id}
          className={columnStyles[column.id]}
          sortBy={sortBy}
          sortType={sortType}
          columnName={column.label || getColumnNameFromId(column.id)}
          sortAble={column.sortAble}
          onSort={onClickSort}
        />
      ))}
      <div className={columnStyles.actions} />
    </div>
  );
};

export default ReferralsHeader;
