import { EpisodeInterface } from 'interfaces/Episodes/episodes';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import { Pagination } from 'interfaces';
import moment from 'moment';
import {
  ChangeEpisodeStatusState,
  CreateEpisodeProps
} from 'pages/PatientDetails/components/PatientDetailsContent/components/ClientDetailsEoC/interface/constants';
import { MOMENTJS_FORMAT_DATE } from '../../../utils/appointment';

interface GetEpisodesParams extends Pick<Pagination, 'page' | 'perPage'> {
  status?: string;
}

interface CreateEpisodePayload {
  clientRecordId: string;
  payload: Partial<EpisodeInterface | CreateEpisodeProps>;
}

interface UpdateEpisodePayload extends CreateEpisodePayload {
  episodeId: string;
}

export interface Paging {
  page: number;
  perPage: number;
  totalItems: number;
}

export interface EpisodeListResponse {
  episodes: EpisodeInterface[];
  paging: Paging;
}

export interface EpisodeAvailabilitiesListResponse {
  startDate: string;
  endDate: string;
}

export interface ChangeEpisodeStatusPayload {
  clientRecordId: string;
  episodeId: string;
  payload: {
    status?: string;
    episode?: Partial<Pick<EpisodeInterface, 'startDate' | 'reason' | 'type' | 'createdBy' | 'episodeId'>>;
    activeEpisodeId?: string;
    state?: ChangeEpisodeStatusState;
  };
}

export const episodeSlide = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEpisodeList: builder.query<
      EpisodeListResponse,
      { clientRecordId: string; timezone: string; params?: GetEpisodesParams }
    >({
      query: ({ clientRecordId, params }) => ({
        url: `/client-records/${clientRecordId}/episodes`,
        params
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        `${endpointName}_${queryArgs.clientRecordId}_${queryArgs.params?.status}`,
      merge: (currentCache, newItems, meta) => {
        if (meta.arg.params?.page === 1) {
          return {
            episodes: newItems.episodes,
            paging: newItems.paging
          };
        }
        return {
          episodes: [
            ...currentCache.episodes,
            ...newItems.episodes.filter((item) => !currentCache.episodes.map(({ _id }) => _id).includes(item._id))
          ].sort((a, b) => b.order - a.order),
          paging: newItems.paging
        };
      },
      transformResponse: (response: EpisodeListResponse, _meta, arg) => ({
        ...response,
        episodes: response.episodes.map((epObj) => ({
          ...epObj,
          startDate: moment(moment.tz(epObj.startDate, arg.timezone).format('YYYY-MM-DD HH:mm')).toDate(),
          ...(epObj.endDate && {
            endDate: moment(moment.tz(epObj.endDate, arg.timezone).format('YYYY-MM-DD HH:mm')).toDate()
          })
        }))
      }),
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.params?.page !== previousArg?.params?.page && currentArg?.params?.page !== 1,
      providesTags: [CPSTagTypes.EpisodeList]
    }),
    getEpisodeTimeList: builder.query<
      EpisodeAvailabilitiesListResponse[],
      { clientRecordId: string; timezone: string }
    >({
      query: ({ clientRecordId }) => ({
        url: `/client-records/${clientRecordId}/episodes:episodeTimeList`
      }),
      transformResponse: (response: EpisodeAvailabilitiesListResponse[], _meta, arg) =>
        response.map((epObj) => ({
          ...epObj,
          startDate: moment(moment.tz(epObj.startDate, arg.timezone)).format(MOMENTJS_FORMAT_DATE),
          endDate: moment(moment.tz(epObj.endDate, arg.timezone)).format(MOMENTJS_FORMAT_DATE)
        })),
      providesTags: [CPSTagTypes.EpisodeList, CPSTagTypes.EpisodeTimeList]
    }),
    getCurrentActiveEpisode: builder.query<EpisodeInterface, { clientRecordId: string; timezone: string }>({
      query: ({ clientRecordId }) => ({
        url: `/client-records/${clientRecordId}/episodes:currentActiveEpisode`
      }),
      transformResponse: (response: EpisodeInterface, _meta, arg) => ({
        ...response,
        ...(response.startDate && {
          startDate: moment(moment.tz(response.startDate, arg.timezone)).format(MOMENTJS_FORMAT_DATE)
        }),
        ...(response.endDate && {
          endDate: moment(moment.tz(response.endDate, arg.timezone)).format(MOMENTJS_FORMAT_DATE)
        })
      }),
      providesTags: [CPSTagTypes.EpisodeCurrentActive]
    }),
    getEpisodeDetails: builder.query<EpisodeInterface, { clientRecordId: string; episodeId: string; timezone: string }>(
      {
        query: ({ clientRecordId, episodeId }) => ({
          url: `/client-records/${clientRecordId}/episodes/${episodeId}`
        }),
        transformResponse: (response: EpisodeInterface, _meta, arg) => ({
          ...response,
          ...(response.startDate && {
            startDate: moment(moment.tz(response.startDate, arg.timezone)).format(MOMENTJS_FORMAT_DATE)
          }),
          ...(response.endDate && {
            endDate: moment(moment.tz(response.endDate, arg.timezone)).format(MOMENTJS_FORMAT_DATE)
          })
        }),
        providesTags: [CPSTagTypes.EpisodeDetails]
      }
    ),
    changeEpisodeStatus: builder.mutation({
      query: ({ clientRecordId, episodeId, payload }: ChangeEpisodeStatusPayload) => ({
        url: `/client-records/${clientRecordId}/episodes/${episodeId}:changeStatus`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: (_result, _error) =>
        _error?.status !== 409
          ? [CPSTagTypes.EpisodeList, CPSTagTypes.ClientEncrypted, CPSTagTypes.EpisodeCurrentActive]
          : []
    }),
    createEpisode: builder.mutation<EpisodeInterface, CreateEpisodePayload>({
      query: ({ clientRecordId, payload }: CreateEpisodePayload) => ({
        url: `/client-records/${clientRecordId}/episodes`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [CPSTagTypes.EpisodeList, CPSTagTypes.ClientEncrypted, CPSTagTypes.EpisodeCurrentActive]
    }),
    updateEpisode: builder.mutation<EpisodeInterface, UpdateEpisodePayload>({
      query: ({ clientRecordId, episodeId, payload }: UpdateEpisodePayload) => ({
        url: `/client-records/${clientRecordId}/episodes/${episodeId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [CPSTagTypes.EpisodeList, CPSTagTypes.ClientEncrypted, CPSTagTypes.EpisodeCurrentActive]
    })
  })
});

export const {
  useGetEpisodeListQuery,
  useGetEpisodeTimeListQuery,
  useGetCurrentActiveEpisodeQuery,
  useChangeEpisodeStatusMutation,
  useCreateEpisodeMutation,
  useUpdateEpisodeMutation,
  useGetEpisodeDetailsQuery
} = episodeSlide;
