import { SurveyClientTab } from 'interfaces/Reports/report';
import { CISTagTypes, checkInServiceApiSlice } from 'redux/services/checkInServiceApiSlice';

interface GetSurveyResponsesParams {
  episodeId?: string;
  showOutsideOfEpisode?: boolean;
}

export const surveyResponsesApiSlice = checkInServiceApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSurveyResponses: builder.query<
      SurveyClientTab[],
      { recordId: string; needToSort?: boolean; params?: GetSurveyResponsesParams }
    >({
      query: ({ recordId, params }) => ({
        url: `/client-records/${recordId}/survey-responses`,
        params
      }),
      transformResponse: (response: SurveyClientTab[], _meta, arg) => {
        return arg.needToSort
          ? response.sort(({ createdAt: surveyACreatedAt }, { createdAt: surveyBCreatedAt }) =>
              surveyBCreatedAt.localeCompare(surveyACreatedAt)
            )
          : response;
      },
      providesTags: [CISTagTypes.SurveyResponses],
      keepUnusedDataFor: 10
    })
  })
});

export const { useGetSurveyResponsesQuery } = surveyResponsesApiSlice;
