import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { SearchFilterEnum, SortType } from 'pages/Groups/GroupsList/components/GroupListContent/components/constants';
import { GroupStatus } from 'pages/Groups/Interfaces/Groups';

export interface GroupListSorting {
  sortBy: string;
  sortType: SortType;
}
export interface GroupListPaging {
  page: number;
  perPage: number;
}
export interface GroupListFilters {
  status?: GroupStatus;
  selectedGroupLeads: FilterCheckListItem[];
}

export interface GroupListSearch {
  searchValue: string;
  searchBy: SearchFilterEnum;
}

interface GroupState {
  createGroupSuccess: number;
  search: GroupListSearch;
  sorting: GroupListSorting;
  paging: GroupListPaging;
  filters: GroupListFilters;
}

const initialState: GroupState = {
  createGroupSuccess: 0,
  search: {
    searchValue: '',
    searchBy: SearchFilterEnum.GroupName
  },
  sorting: {
    sortBy: SearchFilterEnum.GroupName,
    sortType: SortType.ASC
  },
  paging: {
    page: 1,
    perPage: 10
  },
  filters: {
    status: undefined,
    selectedGroupLeads: []
  }
};

export const groupSlice = createSlice({
  name: 'groupSlice',
  initialState,
  reducers: {
    setCreateGroupSuccess: (state, action) => {
      state.createGroupSuccess = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },

    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    setPaging: (state, action) => {
      state.paging = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    resetSorting: (state) => {
      state.sorting = initialState.sorting;
    },

    resetSearch: (state) => {
      state.search = initialState.search;
    },

    resetAll: (state) => {
      state.search = initialState.search;
      state.paging = initialState.paging;
      state.sorting = initialState.sorting;
      state.filters = initialState.filters;
    },

    resetPagingAndSorting: (state) => {
      state.paging = initialState.paging;
      state.sorting = initialState.sorting;
    }
  }
});

export const selectCreateGroupSuccess = (state: RootState) => state.groupSlice.createGroupSuccess;
export const selectSearch = (state: RootState) => state.groupSlice.search;
export const selectSorting = (state: RootState) => state.groupSlice.sorting;
export const selectPaging = (state: RootState) => state.groupSlice.paging;
export const selectFilters = (state: RootState) => state.groupSlice.filters;

export default groupSlice.reducer;

export const {
  setCreateGroupSuccess,
  setSearch,
  setSorting,
  setPaging,
  setFilters,
  resetPaging,
  resetFilters,
  resetSearch,
  resetSorting,
  resetAll,
  resetPagingAndSorting
} = groupSlice.actions;
