import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { useParams } from 'react-router-dom';
import { selectFilters } from 'redux/groups/groupSlice';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetGroupLeadListQuery } from './group';
import { GroupListData, GroupStatus, GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';

export const useGetGroupLeadsList = () => {
  const { accountId } = useGetAccountId();
  const { isEdgeUserView } = useGetAccountPackageView();
  const filters = useAppSelector(selectFilters);
  const path = useParams<{ tabId: GroupStatus }>();
  const tabId = (path.tabId as GroupStatus) ?? GroupStatus.Active;
  const groupStatus = filters.status || tabId;

  const [searchValue, setSearchValue] = useState('');
  const [groupLeadList, setGroupLeadsList] = useState<GroupsFromAPI[]>([]);
  const [paging, setPaging] = useState<GroupListData['paging']>({
    page: 1,
    perPage: 10,
    totalItems: 0
  });

  const {
    data: groups,
    isLoading: isGroupLeadsLoading,
    isFetching: isGroupLeadsFetching,
    refetch
  } = useGetGroupLeadListQuery(
    {
      accountId: accountId,
      params: {
        ...(groupStatus && { status: groupStatus }),
        asUser: isEdgeUserView,
        name: searchValue,
        ...paging
      }
    },
    { skip: !accountId }
  );

  const isGroupLeadsListLoading = isGroupLeadsLoading || isGroupLeadsFetching;

  useEffect(() => {
    if (!isGroupLeadsLoading && !isGroupLeadsFetching && groups) {
      setGroupLeadsList(groups.groups);
      setPaging(groups.paging);
    }
  }, [groups, isGroupLeadsLoading, isGroupLeadsFetching]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchValue]);

  return {
    groupLeadList: groupLeadList || [],
    paging,
    isGroupLeadsListLoading,
    setGroupSearch: setSearchValue,
    setGroupLeadsPaging: setPaging,
    isGroupLeadsFetching
  };
};
