import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import { Collaborator, Report, ReportAccessor } from 'interfaces/Reports/report';
import { CommentWidgetInterface, Session } from 'pages/Report/ReportDetails/interface';
import { ChangeEvent, useEffect, useState } from 'react';

import CommentTab from './components/CommentTab/CommentTab';
import EditTab from './components/EditTab/EditTab';
import ReportInfoSection from './components/ReportInfoSection/ReportInfoSection';
import ShareTab from '../../../../../components/ShareTab/ShareTab';
import styles from './WidgetSideBar.module.scss';
import { ReportTemplate } from 'interfaces/Reports/reportTemplate';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

interface WidgetSideBarProps {
  statusEditable: boolean;
  readOnly: boolean;
  nameOfReport: string;
  data: Report;
  selectedClient: clientRecordsInterface;
  onSelectClient: (selectedClient: clientRecordsInterface, b?: boolean) => void;
  changeReportName: (value: ChangeEvent<HTMLInputElement>) => void;
  handleChangeReportName: () => void;
  clientValidation?: boolean;
  reportNameValidation?: boolean;
  reviewersValidation?: boolean;
  actionBtnStatus: '' | 'active' | 'finished';
  previewMode: boolean;
  preloadTemplate: boolean;
  handleChangeCommentMode: (boolean: boolean) => void;
  comments: CommentWidgetInterface[];
  handleExpandComment: (id: string) => void;
  sharedList: ReportAccessor[];
  onChangeSharedList: (sharedList: ReportAccessor[]) => void;
  isAuthor: boolean;
  session?: Session;
  onChangeSession: (bool: boolean) => void;
  onChangeApprovalRequired: (bool: boolean) => void;
  onPublish: () => void;
  onRequestReview: (reviewers: Collaborator[]) => void;
  onUnpublish: () => void;
  onDelete: () => void;
  onBackToEditMode: () => void;
  onSubmitReview: ({ reviewType, message }: { reviewType: string; message?: string }) => void;
  onSelectReportTemplate: (val: ReportTemplate['items'], applyTemplateId: string) => void;
  onSelectEpisode: (episodeId: string) => void;
}

const getDefaultTab = (isAuthor: boolean, status: string) => {
  switch (status) {
    case 'published':
      return 'share';
    case 'draft':
      return isAuthor ? 'edit' : 'comment';
    default:
      return 'comment';
  }
};

const WidgetSideBar = ({
  statusEditable,
  readOnly,
  nameOfReport,
  data,
  selectedClient,
  onSelectClient,
  changeReportName,
  handleChangeReportName,
  clientValidation,
  reportNameValidation,
  reviewersValidation,
  actionBtnStatus,
  previewMode,
  preloadTemplate,
  handleChangeCommentMode,
  comments,
  handleExpandComment,
  sharedList,
  onChangeSharedList,
  isAuthor,
  session,
  onChangeSession,
  onChangeApprovalRequired,
  onPublish,
  onRequestReview,
  onUnpublish,
  onDelete,
  onBackToEditMode,
  onSubmitReview,
  onSelectReportTemplate,
  onSelectEpisode
}: WidgetSideBarProps) => {
  const [actionTab, setActionTab] = useState<'edit' | 'comment' | 'share'>(getDefaultTab(isAuthor, data.status));
  const isClientShared = sharedList.filter(({ isClient }) => isClient).length > 0;

  const EDIT_TAB = {
    id: 'edit',
    label: 'Edit',
    isActive: actionTab === 'edit'
  };
  const COMMENT_TAB = {
    id: 'comment',
    label: 'Comments',
    isActive: actionTab === 'comment'
  };
  const SHARE_TAB = {
    id: 'share',
    label: 'Share',
    isActive: actionTab === 'share'
  };

  const approvalRequired = data.approvalRequired || false;
  const toggleList = approvalRequired ? [EDIT_TAB, COMMENT_TAB, SHARE_TAB] : [EDIT_TAB, SHARE_TAB];

  useEffect(() => {
    handleChangeCommentMode(actionTab === 'comment');
  }, [actionTab, handleChangeCommentMode]);

  const handleChangeApprovalRequired = (bool: boolean) => {
    onChangeApprovalRequired(bool);
    if (!bool && actionTab === 'comment') {
      setActionTab('edit');
    }
  };

  return (
    <div className={styles.container}>
      <ReportInfoSection
        statusEditable={statusEditable}
        readOnly={readOnly}
        nameOfReport={nameOfReport}
        data={data}
        selectedClient={selectedClient}
        onSelectClient={onSelectClient}
        changeReportName={changeReportName}
        handleChangeReportName={handleChangeReportName}
        clientValidation={clientValidation}
        reportNameValidation={reportNameValidation}
        reviewersValidation={reviewersValidation}
        actionBtnStatus={actionBtnStatus}
        previewMode={previewMode}
        sharedList={sharedList}
        onChangeSharedList={onChangeSharedList}
        isAuthor={isAuthor}
        approvalRequired={approvalRequired}
        onChangeApprovalRequired={handleChangeApprovalRequired}
        onPublish={onPublish}
        onRequestReview={onRequestReview}
        onUnpublish={onUnpublish}
        onDelete={onDelete}
        onSubmitReview={onSubmitReview}
        onSelectEpisode={onSelectEpisode}
      />
      <div className={styles.widgetCard}>
        <div className={styles.actionTab}>
          <ToggleSwitchV2
            id={'actionTab'}
            toggleList={toggleList}
            onChangeStatus={(val) => setActionTab(val.id as 'edit' | 'comment' | 'share')}
            size={'medium'}
          />
        </div>
        <EditTab
          visible={actionTab === 'edit'}
          selectedClient={selectedClient}
          onSelectReportTemplate={onSelectReportTemplate}
          isClientSelected={!!selectedClient._id}
          status={data.status}
          session={session}
          previewMode={previewMode}
          isAuthor={isAuthor}
          preloadTemplate={preloadTemplate}
          onChangeSession={onChangeSession}
          onBackToEditMode={onBackToEditMode}
          isChangingTemplateDisabled={!statusEditable}
        />
        {approvalRequired && (
          <CommentTab
            visible={actionTab === 'comment'}
            comments={comments.filter(({ comments }) => comments.length > 0)}
            isAuthor={isAuthor}
            handleExpandComment={handleExpandComment}
          />
        )}
        <ShareTab
          type="report"
          visible={actionTab === 'share'}
          data={data}
          isClientShared={isClientShared}
          onChangeSharedList={onChangeSharedList}
        />
      </div>
    </div>
  );
};

export default WidgetSideBar;
