import { BentResponse } from 'bent';
import { CarePathwayTemplateSchemaType } from 'pages/CarePathwayTemplate/CarePathwayTemplateSchema';
import { httpClient } from 'utils/httpClient';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getCarePathwayTemplateList = (token: string, accountId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/templates/care-pathways`
  ) as Promise<BentResponse>;

export const deleteCarePathwayTemplate = (token: string, accountId: string, templateId: string) =>
  httpClient('DELETE', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/templates/care-pathways/${templateId}`
  ) as Promise<BentResponse>;

export const patchToggleCarePathwayTemplate = (
  token: string,
  accountId: string,
  templateId: string,
  status: CarePathwayTemplateSchemaType['status']
) =>
  httpClient('PATCH', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/templates/care-pathways/${templateId}/publish-status`,
    { status: status }
  ) as Promise<BentResponse>;
