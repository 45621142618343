import { DatePicker, notification } from 'antd';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import FileInput from 'components/FileInput/FileInput';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import Radio from 'components/Radio/Radio';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { convertFileToBase64 } from 'utils/file';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import { PostFaxPayload, postFax } from 'utils/http/NotifService/fax';
import styles from './NewFax.module.scss';
import { config } from 'config/config';

interface SendFaxFormError {
  selectedFile?: string | null;
  sendFrom?: string | null;
  sendTo?: string | null;
}

enum SendAtChoices {
  Now = 'now',
  TomorrowMorning = 'tomorrowMorning',
  TomorrowAfternoon = 'tomorrowAfternoon',
  CustomSchedule = 'customSchedule'
}

const faxFileConfig = {
  mimeType: 'application/pdf',
  inputAccept: '.pdf'
};

const NewFax = () => {
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();

  const [customSendAt, setCustomSendAt] = useState('');
  const [errors, setErrors] = useState<SendFaxFormError>({});
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [sendAtSelected, setSendAtSelected] = useState<SendAtChoices>(SendAtChoices.Now);
  const [sendFrom, setSendFrom] = useState('');
  const [sendTo, setSendTo] = useState('');
  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>();

  const handleSelectedFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors((prev) => ({ ...prev, selectedFile: null }));
    setSelectedFile(e.target.files?.[0]);
  };

  const handleSendAtSelection = (val: SendAtChoices) => setSendAtSelected(val);

  const calculateSendAt = (sendAtSelected: SendAtChoices) => {
    let sendAt = '';
    switch (sendAtSelected) {
      case SendAtChoices.Now:
        sendAt = moment().toISOString();
        break;

      case SendAtChoices.TomorrowMorning:
        sendAt = moment().add(1, 'days').set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).toISOString();
        break;

      case SendAtChoices.TomorrowAfternoon:
        sendAt = moment().add(1, 'days').set({ hour: 13, minute: 0, second: 0, millisecond: 0 }).toISOString();
        break;

      case SendAtChoices.CustomSchedule:
        sendAt = customSendAt;
        break;

      default:
        break;
    }
    return sendAt;
  };

  const validateForm = () => {
    setErrors({});
    let isValid = true;

    if (!selectedFile) {
      setErrors((prev) => ({ ...prev, selectedFile: 'Please attach a file' }));
      isValid = false;
    }

    if (
      sendFrom.length &&
      sendFrom.length < config.mobileNumberMinLength &&
      sendFrom.length > config.mobileNumberMaxLength
    ) {
      setErrors((prev) => ({ ...prev, sendFrom: 'Please enter a valid fax number' }));
      isValid = false;
    }

    if (sendTo.length < config.mobileNumberMinLength && sendTo.length > config.mobileNumberMaxLength) {
      setErrors((prev) => ({ ...prev, sendTo: 'Please enter a valid fax number' }));
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return notification.error({ message: 'Input error, please update the form' });
    } else if (selectedFile) {
      setSubmitButtonStatus('active');

      const encodedFile = await convertFileToBase64(selectedFile);
      const sendAt = calculateSendAt(sendAtSelected);

      const payload: PostFaxPayload = {
        document: { data: encodedFile, fileName: selectedFile.name, mimeType: selectedFile.type },
        sendAt,
        sendFrom: sendFrom || undefined,
        sendTo
      };

      try {
        await postFax({ accountId, payload, token });
        setSubmitButtonStatus('finished');

        setTimeout(() => {
          setSubmitButtonStatus('');

          notification.success({
            closeIcon: <span className="success">OK</span>,
            duration: 5,
            message: 'Your fax request has been added to the processing queue successfully.'
          });
        }, 1000);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to send your fax.' });
        setSubmitButtonStatus('');
      }
    }
  };

  const faxNumberChangeHandlerGenerator =
    (fieldName: string, onChange: Dispatch<SetStateAction<string>>) => (newValue?: string) => {
      setErrors((prev) => ({ ...prev, [fieldName]: null }));
      onChange(newValue || '');
    };

  return (
    <HelmetWrapper title={'Tacklit - Send Fax'}>
      <ContentLayout>
        <div className={styles.headerContainer}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>Send Fax</div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.sendFaxSection}>
            <h2 className={styles.sectionTitle}>Send Fax</h2>
            <MaterialPhoneInput
              id={'sendingFrom'}
              autoFormat={false}
              disableCountryCode
              errorMessage={errors.sendFrom ?? undefined}
              hideFlag
              isError={false}
              label="Sending From"
              onChange={faxNumberChangeHandlerGenerator('sendFrom', setSendFrom)}
              placeholder=""
              value={sendFrom}
            />
            <div className={styles.sendingTo}>
              <MaterialPhoneInput
                id={'sendingTo'}
                autoFormat={false}
                disableCountryCode
                errorMessage={errors.sendTo ?? undefined}
                hideFlag
                isError={!!errors.sendTo}
                label="Sending To*"
                onChange={faxNumberChangeHandlerGenerator('sendTo', setSendTo)}
                placeholder=""
                value={sendTo}
              />
            </div>
            <FileInput
              accept={faxFileConfig.inputAccept}
              buttonClassName={styles.uploadButton}
              buttonLabel="Upload file to fax"
              buttonSize="small"
              errorMessage={errors.selectedFile ?? undefined}
              onChange={handleSelectedFileChange}
              selectedFiles={selectedFile ? [selectedFile] : undefined}
            />
          </div>
          <div className={styles.whenToSendSection}>
            <h2 className={styles.sectionTitle}>When to send</h2>
            <Radio
              useT23Styles
              checked={sendAtSelected === SendAtChoices.Now}
              name="deliveryType"
              value={SendAtChoices.Now}
              onChange={(e) => handleSendAtSelection(e.target.value as SendAtChoices)}
              options={[
                {
                  value: SendAtChoices.Now,
                  label: 'Now'
                }
              ]}
            />
            <Radio
              useT23Styles
              checked={sendAtSelected === SendAtChoices.TomorrowMorning}
              name="deliveryType"
              value={SendAtChoices.TomorrowMorning}
              onChange={(e) => handleSendAtSelection(e.target.value as SendAtChoices)}
              options={[
                {
                  value: SendAtChoices.TomorrowMorning,
                  label: 'Tomorrow morning at 9AM'
                }
              ]}
            />
            <Radio
              useT23Styles
              checked={sendAtSelected === SendAtChoices.TomorrowAfternoon}
              name="deliveryType"
              value={SendAtChoices.TomorrowAfternoon}
              onChange={(e) => handleSendAtSelection(e.target.value as SendAtChoices)}
              options={[
                {
                  value: SendAtChoices.TomorrowAfternoon,
                  label: 'Tomorrow afternoon at 1PM'
                }
              ]}
            />
            <div className={styles.customSchedule}>
              <div className={styles.inputContainer}>
                <Radio
                  useT23Styles
                  checked={sendAtSelected === SendAtChoices.CustomSchedule}
                  name="deliveryType"
                  value={SendAtChoices.CustomSchedule}
                  onChange={(e) => handleSendAtSelection(e.target.value as SendAtChoices)}
                  options={[
                    {
                      value: SendAtChoices.CustomSchedule,
                      label: 'Custom schedule'
                    }
                  ]}
                />
              </div>
              <DatePicker
                className="sendMessageDatePicker"
                disabledDate={(current) => current && current < moment().startOf('day')}
                onChange={(e) => setCustomSendAt(e?.toISOString() ?? '')}
                onClick={() => setSendAtSelected(SendAtChoices.CustomSchedule)}
                showTime={{ format: 'HH:mm', minuteStep: 15 }}
                suffixIcon={<i className={`material-icons icon`}>arrow_drop_down</i>}
                value={customSendAt ? moment(customSendAt) : undefined}
              />
            </div>
          </div>
          <ButtonAlt className={styles.sendFaxButton} onClick={handleSubmit} status={submitButtonStatus}>
            Send Fax
          </ButtonAlt>
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default NewFax;
