import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { security } from 'utils/security';

export enum CPSTagTypes {
  ClientEncrypted = 'Client Encrypted Data By ClientRecordId',
  ClientList = 'ClientRecords',
  ClientListMinified = 'Client Records Minified',
  ClientRecordsByClinicianId = 'Client Records By ClinicianId',
  ClinicianProfile = 'Clinician Profile',
  ClinicianProfileById = 'Clinician Profile By Id',
  GeneralPractitioner = 'General Practitioner',
  GeneralPractitionerList = 'General Practitioner List',
  GroupList = 'Group List',
  GroupListMinified = 'Groups Minified',
  GroupTagList = 'Group Tags',
  PractitionerList = 'Practitioners',
  PractitionerListMinified = 'Practitioners Minified',
  ClientListMinifiedForInlineAppointment = 'Client List Minified For Inline Appointment',
  EpisodeList = 'Episode List',
  EpisodeCurrentActive = 'Episode Current Active',
  TestClients = 'Test Clients',
  EpisodeTimeList = 'Episode Time List',
  EpisodeDetails = 'Episode Details',
  CarePathwayTemplate = 'Care Pathway Template',
  PracticeInfo = 'Practice Info',
  CarePathway = 'Care Pathway',
  CarePathwayCount = 'Care Pathway Count',
  Referral = 'Referral',
  ReferralList = 'Referral List',
  ProfileTag = 'Profile Tag',
  ReferralMatchedClients = 'Referral Matched Clients',
  GeneralSettings = 'General Settings',
  AddressBookList = 'Address Book List',
  AddressBook = 'Address Book'
}

export const clinicianProfileServicesApiSlice = createApi({
  reducerPath: 'clinicianProfileServices',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL,
    prepareHeaders: async (headers) => {
      const token = await security.getAccessTokenSilently();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    }
  }),
  tagTypes: Object.values(CPSTagTypes),
  endpoints: () => ({})
});
