import { AvailableTime } from 'interfaces/Practitioners/practitionersListing';
import { Tooltip } from 'react-tooltip';
import styles from './PractitionerSchedule.module.scss';
import moment from 'moment';

interface DayComponentProp {
  id: string;
  className: string;
  day: string;
  schedule: AvailableTime;
}

const DayComponent = ({ id, className, day, schedule }: DayComponentProp) => {
  const dateStringConverter = (date: string) => {
    const minutes = Number(date.slice(3, 5));
    if (minutes === 0) {
      return moment(date, 'H').format('h A');
    }
    return moment(date, 'H:mm').format('h:mm A');
  };

  return (
    <>
      <div data-tooltip-id={`${day}-sched-${id}`} className={className}>
        {day}
      </div>
      {schedule?.isActive && schedule.timeSlots && schedule.timeSlots.length >= 1 && (
        <Tooltip id={`${day}-sched-${id}`} place="top" className={styles.tooltip}>
          Working:{' '}
          {schedule.timeSlots.length > 1 ? (
            schedule.timeSlots.map((slot, index) => (
              <div key={`schedule-${day}-${index}-${id}`}>
                {dateStringConverter(slot.startTime)} - {dateStringConverter(slot.endTime)}
                {index + 1 !== schedule.timeSlots!.length && ', '}
              </div>
            ))
          ) : (
            <>
              {dateStringConverter(schedule.timeSlots[0].startTime)} -{' '}
              {dateStringConverter(schedule.timeSlots[0].endTime)}
            </>
          )}
        </Tooltip>
      )}
    </>
  );
};

export default DayComponent;
