import { createSlice } from '@reduxjs/toolkit';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { FilterRadioItem } from 'components/T23/FilterRadioDropdown/FilterRadioDropdown';
import { RootState } from 'redux/store';

export enum CarePlanStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  DELETED = 'deleted'
}

export interface CarePlanListFilters {
  selectedStatus?: FilterRadioItem;
  selectedStaffOwners: FilterCheckListItem[];
  selectedClients: FilterCheckListItem[];
}

export interface CarePlanListPaging {
  page: number;
  perPage: number;
}

interface CarePlanListState {
  search: { searchValue: string };
  filters: CarePlanListFilters;
  paging: CarePlanListPaging;
}

const initialState: CarePlanListState = {
  search: {
    searchValue: ''
  },
  filters: {
    selectedStatus: undefined,
    selectedStaffOwners: [],
    selectedClients: []
  },
  paging: {
    page: 1,
    perPage: 10
  }
};

export const carePlanListSlice = createSlice({
  name: 'carePlanListSlice',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    resetSearch: (state) => {
      state.search = initialState.search;
    },

    resetAll: (state) => {
      state.search = initialState.search;
      state.filters = initialState.filters;
      state.paging = initialState.paging;
    },

    setPaging: (state, action) => {
      state.paging = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    }
  }
});

export const selectSearch = (state: RootState) => state.carePlanListSlice.search;
export const selectFilters = (state: RootState) => state.carePlanListSlice.filters;
export const selectPaging = (state: RootState) => state.carePlanListSlice.paging;

export const { setSearch, setFilters, resetFilters, resetSearch, resetAll, setPaging, resetPaging } =
  carePlanListSlice.actions;

export default carePlanListSlice.reducer;
