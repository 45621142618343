import styles from './Sidebar.module.scss';
import AppointmentSection from './components/AppointmentSection/AppointmentSection';
import InfoField from './components/InfoField/InfoField';

const Sidebar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Care Plan Creator</div>
      <InfoField />
      <div className={styles.appointmentWrapper}>
        <AppointmentSection />
      </div>
    </div>
  );
};

export default Sidebar;
