import { useEffect, useState } from 'react';

import classnames from 'classnames';
import { COMMUNICATION_OPTIONS, ROLE_TYPE_OPTIONS } from 'components/AddPatientModalV2/constants';
import { ChildGuardianErrorInterface } from 'components/AddPatientModalV2/formType/ChildForm/components/ChildGuardianForm/ChildGuardianInterface';
import { YoungGuardianErrorInterface } from 'components/AddPatientModalV2/interfaces/formProps.interface';
import CheckBox from 'components/CheckBox/CheckBox';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { useTranslation } from 'react-i18next';
import { ClientDetails, newClientForm } from '../../../../AddPatientModalV2Interface';
import styles from './GuardianForm.module.scss';
import { validationGuardianField } from './validation/GuardianFormValidation';

interface GuardianFormProps {
  id: string | number;
  label?: string;
  clientDetail: ClientDetails;
  onChangeClientField: any;
  checkValidation: boolean;
  shadowBox?: boolean;
  isFirstGuardian: boolean;
  updateDetail: number;
  clientRecordType: newClientForm['clientCapability']['profileType'];
}

const GuardianForm = ({
  id,
  label,
  clientDetail,
  onChangeClientField,
  checkValidation,
  shadowBox,
  isFirstGuardian,
  clientRecordType
}: GuardianFormProps) => {
  const [errorMessage, setErrorMessage] = useState<ChildGuardianErrorInterface>({
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    mobileNumber: ''
  });

  const notifyPerson = 'Send parent client notifications inc messages, appointment reminders and form requests';

  useEffect(() => {
    if (checkValidation) {
      validatePart1Field(clientDetail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const validatePart1Field = (clientVal: ClientDetails) => {
    const validate = validationGuardianField({
      ...clientVal,
      isFirstGuardian: isFirstGuardian,
      profileType: clientRecordType
    });

    setErrorMessage(validate as YoungGuardianErrorInterface);
    return validate;
  };

  const handleChangeFieldValue = async (key: string, value: string | boolean) => {
    const newClientField = {
      ...clientDetail,
      [key]: value
    };

    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const handleSelectRole = (value: ClientDetails['role']) => {
    const newClientField = {
      ...clientDetail,
      role: value
    };

    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const onCommunicationPreferenceChange = (value: ClientDetails['communicationPreference']) => {
    const newClientField = {
      ...clientDetail,
      communicationPreference: value
    };

    onChangeClientField(newClientField);
  };

  const [t] = useTranslation();

  return (
    <div className={shadowBox ? styles.containerWithBox : styles.container}>
      {shadowBox && <div className={styles.labelContainer}>{label && <div className={styles.label}>{label}</div>}</div>}
      <div
        className={classnames(styles.fieldContainer, checkValidation && errorMessage.firstName && styles.fieldError)}
      >
        <MaterialInput
          id={`clientFirstName${id}`}
          label={'Parent/Guardian First Name*'}
          onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
          value={clientDetail.firstName}
          required
        />
        {checkValidation && errorMessage.firstName && <div className={styles.fieldError}>{errorMessage.firstName}</div>}
      </div>
      <div className={styles.twoQuestionWrapper}>
        <div
          className={classnames(styles.fieldContainer, checkValidation && errorMessage.lastName && styles.fieldError)}
        >
          <MaterialInput
            id={`clientLastName${id}`}
            label={'Parent/Guardian Last Name*'}
            onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
            value={clientDetail.lastName}
            required
          />
          {checkValidation && errorMessage.lastName && <div className={styles.fieldError}>{errorMessage.lastName}</div>}
        </div>
        <div className={styles.roleFieldContainer}>
          <MaterialSelect
            id={`relationshipType${id}`}
            label={`${t('form.relationship')}*`}
            isSearchable={false}
            options={ROLE_TYPE_OPTIONS}
            value={clientDetail.role as string}
            onChange={handleSelectRole}
            isError={checkValidation && !!errorMessage.role}
          />
          {checkValidation && errorMessage.role && <div className={styles.fieldError}>{errorMessage.role}</div>}
        </div>
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && errorMessage.email && styles.fieldError)}>
        <MaterialInput
          id={`clientEmail${id}`}
          label={'Parent/Guardian Email'}
          onChange={(e) => handleChangeFieldValue('email', e.target.value)}
          value={clientDetail.email}
          required
        />
        {checkValidation && errorMessage.email && <div className={styles.fieldError}>{errorMessage.email}</div>}
      </div>
      <div className={styles.phoneContainer}>
        <MaterialPhoneInput
          id={`clientMobileNumber${id}`}
          label={'Mobile Number'}
          onChange={(e?: string) => handleChangeFieldValue('mobileNumber', e || '')}
          value={clientDetail.mobileNumber || ''}
          autoFormat={false}
          isError={checkValidation && !!errorMessage.mobileNumber}
        />
        {checkValidation && errorMessage.mobileNumber && (
          <div className={styles.fieldError}>{errorMessage.mobileNumber}</div>
        )}
      </div>
      <div className={styles.primaryFieldContainer}>
        {/* Note for this checkbox: will only be disabled for the first guardian if the main client is young person (recordOnly) or child */}
        <CheckBox
          id={`isPrimaryContact${id}`}
          disabled={isFirstGuardian}
          value={clientDetail.isPrimaryContact || false}
          label={notifyPerson}
          onChange={(e) => handleChangeFieldValue('isPrimaryContact', e.target.checked)}
        />
      </div>
      <div className={classnames(styles.preferenceContainer, styles.fieldContainer)}>
        <div className={styles.inputWithTooltip}>
          <div className={styles.label}>Communication Preference</div>
          <HelpOutLineWithTooltips
            id={'communicationPreference'}
            desc={'Notifications on Invoice and Report & Letter will only be available via Email.'}
            iconClass={styles.helpIcon}
          />
        </div>
        <MaterialSelect
          id={'clientCommunicationPreference'}
          label={''}
          isSearchable={false}
          options={COMMUNICATION_OPTIONS}
          value={clientDetail.communicationPreference || CommunicationPreference.NoPreference}
          onChange={onCommunicationPreferenceChange}
        />
        {clientDetail.communicationPreference === 'none' && (
          <div className={styles.noneComms}>{t('form.error.client_no_digital_message')}</div>
        )}
      </div>
    </div>
  );
};

export default GuardianForm;
