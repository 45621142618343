import classnames from 'classnames';

import styles from './PrivacyPolicy.module.scss';
import { config } from 'config/config';

const PrivacyPolicy = ({ showPlainEnglish }: { showPlainEnglish: boolean }) => {
  const containerClasses = classnames(styles.container, showPlainEnglish && styles.showPlainEnglish);

  return (
    <div className={containerClasses}>
      <div className={styles.content}>
        <div className={styles.scrollContainer}>
          {/* {terms.map(({ text, plainText }, index) => (
            <Fragment key={index}>
              <div className={styles.row}>
                <div className={styles.text}>{text}</div>
                <div className={styles.plainText}>{plainText}</div>
              </div>
              {index !== terms.length - 1 && <br />}
            </Fragment>
          ))} */}

          {config.terms.privacy}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
