import { Skeleton } from 'antd';
import classNames from 'classnames';
import { Report } from 'interfaces/Reports/report';
import DndPreviewSection from 'pages/Report/components/ReportBuilderPreview/components/ReportDraftContent/components/DndPreviewSection/DndPreviewSection';
import { CommentWidgetInterface } from 'pages/Report/ReportDetails/interface';

import ReportContentHeader from '../../../../../components/ReportContentComponents/ReportContentHeader/ReportContentHeader';
import SignatureSection from '../../../../../components/ReportContentComponents/SignatureSection/SignatureSection';
import DragAndDropSection from './components/DragAndDropSection/DragAndDropSection';
import DropCommentSection from './components/DropCommentSection/DropCommentSection';
import styles from './ReportContent.module.scss';
import { ForwardedRef, forwardRef } from 'react';

export interface ReportContentProps {
  readOnly: boolean;
  data: Report;
  reportName: string;
  clinicianDetails: any;
  isSignatureLoading: boolean;
  onChangeSignature: (
    signatureVal: { drawVal: string; textVal: string; type: string },
    extraDetailsVal: {
      email: string;
      mobileNumber: string;
      name: string;
      other: string;
    }
  ) => void;
  onChangeContactDetails: any;
  onChangeItems: (items: any[]) => void;
  onChangeDndHeight: any;
  signatureValidation?: boolean;
  itemValidation?: boolean;
  previewMode: boolean;
  commentMode: boolean;
  comments: CommentWidgetInterface[];
  onChangeComments: (comments: any[]) => void;
  autoAlignTemplateId: string;
}

const ReportContent = forwardRef(
  (
    {
      readOnly,
      data,
      reportName,
      clinicianDetails,
      isSignatureLoading,
      onChangeSignature,
      onChangeContactDetails,
      onChangeItems,
      onChangeDndHeight,
      signatureValidation,
      itemValidation,
      previewMode,
      commentMode,
      comments,
      onChangeComments,
      autoAlignTemplateId
    }: ReportContentProps,
    ref: ForwardedRef<null>
  ) => {
    return (
      <div className={classNames(styles.container, readOnly && styles.readOnly)} ref={ref}>
        <div className={classNames(styles.contentWrapper, previewMode && styles.previewBorder)}>
          <ReportContentHeader
            type="Report"
            reportName={reportName}
            clientRecord={data.clientRecord}
            clinicianContactData={data.contactDetails}
            clinicianDetails={clinicianDetails}
            onChangeContactDetails={onChangeContactDetails}
            isPreview={commentMode || previewMode}
            issueDate={data.issueDate}
          />
          {commentMode ? (
            <div className={previewMode ? styles.previewModeContainer : styles.commentModeContainer}>
              <DropCommentSection
                reportId={data._id}
                dndDimension={data.template.dimensions}
                onChangeComments={onChangeComments}
                items={comments}
                clinicianDetails={clinicianDetails}
                allowedAddComment={data.status !== 'published'}
              />
              <DndPreviewSection dndDimension={data.template.dimensions} reportItems={data.items} />
            </div>
          ) : !previewMode ? (
            <DragAndDropSection
              onChangeDndContainerSize={onChangeDndHeight}
              dndDimension={data.template.dimensions}
              onChangeItems={onChangeItems}
              items={data.items}
              autoExpandContainerHeight={90}
              autoAlignTemplateId={autoAlignTemplateId}
              refetch={data.status !== 'published'}
            />
          ) : (
            <DndPreviewSection dndDimension={data.template.dimensions} reportItems={data.items} />
          )}
          {itemValidation && (
            <div className={styles.error}>
              Drag & Drop area cannot be blank, Please insert at least one item into report
            </div>
          )}
          <div className={styles.signatureWrapper}>
            {isSignatureLoading ? (
              <Skeleton title={false} className={styles.loading} active />
            ) : (
              <SignatureSection
                clinician={clinicianDetails.clinician}
                signVal={data.clinicianSignature}
                extraDetailsVal={data.clinicianSignatureExtraDetails}
                onChangeSignature={onChangeSignature}
                showError={signatureValidation}
                previewMode={commentMode || previewMode}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default ReportContent;
