import { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import classnames from 'classnames';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Input from 'components/Input/Input';
import { checkVerification } from 'utils/http/verification';

import styles from './TwoFAInput.module.scss';

const TwoFAInput = ({ onSuccess }: { onSuccess: () => void }) => {
  const [field, { error, touched }, { setError, setTouched, setValue }] = useField('2fa');
  const { values }: any = useFormikContext();
  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  const callCheckVerification = async (body: any) => {
    setIsVerifying(true);
    setIsErrored(false);
    setIsVerified(false);

    try {
      const checkVerificationStatus = (await checkVerification(body)) as {
        [key: string]: any;
        [key: number]: any;
      };
      if ((await checkVerificationStatus.text()) === 'approved') {
        setIsVerified(true);
        setIsErrored(false);
        onSuccess();
        console.log('Verification checked successfully');
      } else {
        setError('Incorrect code');
        setIsVerified(false);
        setIsErrored(true);
        console.log('Verification check errored.');
      }
    } catch (err) {
      setError('Incorrect code');
      setIsVerified(false);
      setIsErrored(true);
      console.error(err);
    } finally {
      setIsVerifying(false);
    }
  };

  const onChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue && isNaN(Number(inputValue))) {
      return;
    }

    if (inputValue.length === 6) {
      setValue(inputValue.split(''));

      document.getElementById('2fa-5')?.focus();

      setTouched(true, false);
      const body = {
        numberToVerify: `+${values.mobileNumber}`,
        code: inputValue
      };

      callCheckVerification(body);
    } else {
      const actualInputValue = inputValue.charAt(inputValue.length - 1);

      const newValue = [...field.value];
      newValue.splice(index, 1, actualInputValue);
      setValue(newValue);

      if (actualInputValue) {
        document.getElementById(`2fa-${index + 1}`)?.focus();
      }

      const mfaCodeValue = newValue.join('');
      if (mfaCodeValue.length === 6) {
        setTouched(true, false);
        const body = {
          numberToVerify: `+${values.mobileNumber}`,
          code: mfaCodeValue
        };

        callCheckVerification(body);
      }
    }
  };

  const onKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && field.value[index] === '') {
      e.preventDefault();

      document.getElementById(`2fa-${index - 1}`)?.focus();
    }
  };

  return (
    <>
      <span className={`label fullWidth ${styles.label}`}>
        Enter the 6-digit code sent to{' '}
        <span className={styles.formattedMobileNumber}>{values.formattedMobileNumber}</span>
      </span>
      <div
        className={classnames(
          styles.container,
          isVerifying && styles.verifying,
          isVerified && styles.verified,
          isErrored && styles.errored
        )}
      >
        <Input
          type="tel"
          id="2fa-0"
          value={field.value[0]}
          onKeyDown={onKeyDown(0)}
          onChange={onChange(0)}
          hasError={touched && !!error}
          disabled={isVerifying}
          autoComplete={'off'}
          noSpacing
        />
        <Input
          type="tel"
          id="2fa-1"
          value={field.value[1]}
          onKeyDown={onKeyDown(1)}
          onChange={onChange(1)}
          hasError={touched && !!error}
          disabled={isVerifying}
          autoComplete={'off'}
          noSpacing
        />
        <Input
          type="tel"
          id="2fa-2"
          value={field.value[2]}
          onKeyDown={onKeyDown(2)}
          onChange={onChange(2)}
          hasError={touched && !!error}
          disabled={isVerifying}
          autoComplete={'off'}
          noSpacing
        />
        <Input
          type="tel"
          id="2fa-3"
          value={field.value[3]}
          onKeyDown={onKeyDown(3)}
          onChange={onChange(3)}
          hasError={touched && !!error}
          disabled={isVerifying}
          autoComplete={'off'}
          noSpacing
        />
        <Input
          type="tel"
          id="2fa-4"
          value={field.value[4]}
          onKeyDown={onKeyDown(4)}
          onChange={onChange(4)}
          hasError={touched && !!error}
          disabled={isVerifying}
          autoComplete={'off'}
          noSpacing
        />
        <Input
          type="tel"
          id="2fa-5"
          value={field.value[5]}
          onKeyDown={onKeyDown(5)}
          onChange={onChange(5)}
          hasError={touched && !!error}
          disabled={isVerifying}
          autoComplete={'off'}
          noSpacing
        />
      </div>
      <ErrorMessage error={error} visible={touched} />
    </>
  );
};

export default TwoFAInput;
