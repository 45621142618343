import { AppointmentClaimType, AppointmentSlots } from 'interfaces/Schedule/Appointment';
import moment from 'moment';
import { useMemo } from 'react';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface ProcessAppointmentProps {
  appointment: AppointmentSlots;
}

export const useProcessAppointment = ({ appointment }: ProcessAppointmentProps) => {
  const { isInvoiceListV2Enabled } = useGetFeatureToggle();

  const isSpecialAppointment = useMemo<boolean>(() => {
    return (
      !appointment.claimType ||
      ![AppointmentClaimType.BulkBill, AppointmentClaimType.Rebate, AppointmentClaimType.OutOfPocket].includes(
        appointment.claimType
      )
    );
  }, [appointment.claimType]);

  const isProcessVisible = useMemo<boolean>(() => {
    if (appointment.isProcessed) {
      return false;
    }

    if (appointment.isActivity) {
      return false;
    }

    if (appointment.groupId) {
      return false;
    }

    if (moment(appointment.startDateTime).isAfter(moment())) {
      return false;
    }

    if (isSpecialAppointment) {
      return isInvoiceListV2Enabled;
    }

    return true;
  }, [
    appointment.isProcessed,
    appointment.startDateTime,
    appointment.isActivity,
    appointment.groupId,
    isSpecialAppointment,
    isInvoiceListV2Enabled
  ]);

  return {
    isProcessVisible
  };
};
