import { notification } from 'antd';
import { BentResponse } from 'bent';
import { FormListingInterface } from 'interfaces/Form/FormInterface';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';

interface UseSetDefault {
  listState: FormListingInterface[];
  setListState: React.Dispatch<React.SetStateAction<FormListingInterface[]>>;
  patchSetDefault: (token: string, consentFormId: string) => Promise<BentResponse>;
  onSuccess?: string;
  onFail?: string;
}

const useSetDefault = ({
  listState,
  setListState,
  patchSetDefault,
  onSuccess = 'Updated.',
  onFail = 'Fail.'
}: UseSetDefault) => {
  const { token } = useGetAccessToken();
  const [loading, setLoading] = useState('');

  const onSetDefault = async (id: string) => {
    setLoading(id);
    let newADataList = [];
    for (let data of listState) {
      newADataList.push({
        ...data,
        isDefault: data._id === id
      });
    }

    try {
      await patchSetDefault(token, id);
      setListState(newADataList);
      setLoading('');
      notification.success({
        message: onSuccess,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      console.error(ex);
      setLoading('');
      notification.error({
        message: onFail,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return { loading, onSetDefault };
};

export default useSetDefault;
