import { useCurrency } from 'utils/hooks/useCurrency';
import styles from './InvoiceDetailsForModal.module.scss';
import { InvoiceWithType } from '../InvoiceList/constants';
import { combineName } from 'utils/general';
import classNames from 'classnames';
import { Skeleton } from 'antd';
import { FunderNameMapping } from 'interfaces/invoices/pendingClaimInvoices';

interface InvoiceDetailsForModalProps {
  invoice?: InvoiceWithType;
  isLoading?: boolean;
}

const InvoiceDetailsForModal = ({ invoice, isLoading }: InvoiceDetailsForModalProps) => {
  const { CURRENCY_SYMBOL, formatCurrency } = useCurrency();

  const { invoiceId, invoiceType, clientRecord, group, invoiceAmount, paid, owed = 0 } = invoice || {};
  const paidAmount = paid?.total || 0;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.invoiceID}>INVOICE ID</div>
        <div className={styles.type}>PAYER</div>
        <div className={styles.client}>{group ? 'NAME' : 'CLIENT'}</div>
        <div className={styles.total}>{`${CURRENCY_SYMBOL} TOTAL`}</div>
        <div className={styles.paid}>{`${CURRENCY_SYMBOL} PAID`}</div>
        <div className={styles.owed}>{`${CURRENCY_SYMBOL} OWED`}</div>
      </div>
      {isLoading || !invoice ? (
        <div className={styles.details}>
          <div className={styles.invoiceID}>
            <Skeleton.Input className={styles.loadingElm} active />
          </div>
          <div className={styles.type}>
            <Skeleton.Input className={styles.loadingElm} active />
          </div>
          <div className={styles.client}>
            <Skeleton.Input className={styles.loadingElm} active />
          </div>

          <div className={classNames(styles.total, styles.money)}>
            <Skeleton.Input className={styles.loadingElm} active />
          </div>
          <div className={classNames(styles.paid, styles.money)}>
            <Skeleton.Input className={styles.loadingElm} active />
          </div>
          <div className={classNames(styles.owed, styles.money)}>
            <Skeleton.Input className={styles.loadingElm} active />
          </div>
        </div>
      ) : (
        <div className={styles.details}>
          <div className={styles.invoiceID}>{invoiceId}</div>
          <div className={styles.type}>
            {invoiceType && FunderNameMapping[invoiceType] && (
              <div className={styles.typePill}>{FunderNameMapping[invoiceType]}</div>
            )}
          </div>
          <div className={styles.client}>
            {group ? (
              <div className={styles.groupName}>{group.name}</div>
            ) : (
              clientRecord && (
                <>
                  <div className={styles.clientName}>{combineName(clientRecord.clientProfiles)}</div>
                  <div className={styles.clientId}>{clientRecord?.tacklitId}</div>
                  <div className={styles.clientId}>{clientRecord?.caseId}</div>
                </>
              )
            )}
          </div>

          <div className={classNames(styles.total, styles.money)}>{formatCurrency(invoiceAmount || 0)}</div>
          <div className={classNames(styles.paid, styles.money)}>{formatCurrency(paidAmount)}</div>
          <div className={classNames(styles.owed, styles.money)}>{formatCurrency(owed)}</div>
        </div>
      )}
    </div>
  );
};

export default InvoiceDetailsForModal;
