import { DELIVERY_TYPE_ICONS } from 'interfaces/Schedule/AppointmentType';
import { NextAppointmentInterface } from 'pages/Mentor/components/Members/constants';
import styles from './AppointmentDetail.module.scss';
import moment from 'moment';
import { getDeliveryTypeLabel } from 'utils/appointment';

interface AppointmentDetailProps {
  appointment: NextAppointmentInterface;
}

const AppointmentDetail = ({ appointment }: AppointmentDetailProps) => (
  <div className={styles.container}>
    <div className={styles.timeWrapper}>
      <i className={`material-icons ${styles.timeIcon}`}>schedule</i>
      <div className={styles.timeSlotText}>
        {moment(appointment.startTime, 'HH:mm').format('hh:mmA')} -{' '}
        {moment(appointment.endTime, 'HH:mm').format('hh:mmA')}
      </div>
    </div>
    <div className={styles.modeWrapper}>
      <i className={`material-icons ${styles.deliveryIcon}`}>{DELIVERY_TYPE_ICONS[appointment.deliveryType]}</i>
      <div className={styles.deliveryModeText}>
        {getDeliveryTypeLabel(appointment.deliveryType, appointment.otherInstructions)}
      </div>
    </div>
    {appointment.room && (
      <div className={styles.roomWrapper}>
        <i className={`material-icons-outlined ${styles.roomIcon}`}>meeting_room</i>
        <div className={styles.location}>{appointment.room.roomName}</div>
      </div>
    )}
  </div>
);

export default AppointmentDetail;
