import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { AppointmentDocument } from 'interfaces/Clients/Appointment';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { Provider } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { Invoice, InvoiceStatus } from 'pages/Invoices/interface';
import { MBS_CODE_FULL_LIST } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/mbsItems';
import { GeneralPractitionerInterface } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/GPDetails/interface';
import { toWord } from 'utils/generateCamelCase';

export enum PendingClaimsFunder {
  DVA = 'dva',
  BULK_BILL = 'bulkBill',
  OUT_OF_POCKET = 'outOfPocket',
  REBATE = 'rebate'
}

export const FunderNameMapping = {
  dva: 'DVA',
  bulkBill: 'Medicare BB',
  rebate: 'OOP Rebate',
  outOfPocket: 'OOP'
};

export const FunderFilterNameMapping = {
  dva: 'DVA',
  bulkBill: 'Medicare - BulkBill',
  rebate: 'Out Of Pocket - Rebate',
  outOfPocket: 'Out Of Pocket'
};

export enum PendingClaimsStatus {
  ZERO_BALANCE = 'zeroBalance',
  CONFIRM_PAID = 'confirmPaid'
}

export const FunderFilters: FilterCheckListItem[] = Object.values(PendingClaimsFunder)
  .filter((funder) => funder !== PendingClaimsFunder.OUT_OF_POCKET)
  .map((item) => ({
    _id: item,
    name: FunderNameMapping[item]
  }));

export const ClaimInvoiceStatusFilters: FilterCheckListItem[] = [
  InvoiceStatus.ConfirmPaid,
  InvoiceStatus.Issued,
  InvoiceStatus.Overdue,
  InvoiceStatus.PendingClaim
].map((item) => ({
  _id: item,
  name: toWord(item)
}));

const getClaimCodeFilters = () => {
  const filters: FilterCheckListItem[] = [];

  new Set(MBS_CODE_FULL_LIST.filter(({ disabled }) => !disabled).map(({ mbsCode }) => mbsCode)).forEach((mbsCode) => {
    filters.push({
      _id: mbsCode,
      name: mbsCode
    });
  });

  return filters.sort((a, b) => a._id.localeCompare(b._id));
};

export const ClaimCodeFilters: FilterCheckListItem[] = getClaimCodeFilters();

// TODO adjust response
export interface PendingClaimInvoiceListResponse {
  invoices: InvoiceWithProjectedMbsCode[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

// TODO adjust query
export interface PendingClaimInvoiceListRequestPayload {
  accountId: string;
  params: PendingClaimInvoiceListQuery;
}

export interface PendingClaimInvoiceListQuery {
  ids?: string;
  clientRecordIds?: string;
  clinicianAuth0Ids?: string;
  groupIds?: string;
  funder?: PendingClaimsFunder;
  mbsCode?: string;
  status?: string;
  asUser?: string;
  sortByAppointmentDate?: '1' | '-1';
  sortByClaimReady?: '1' | '-1'; // 1 for true first, -1 for false first
  page?: number;
  perPage?: number;
  isClaimReady?: 1 | 0;
  abandoned?: 1 | 0;
}

export interface InvoiceWithProjectedMbsCode extends Invoice {
  foundAppointment: AppointmentDocument; // entire appointment attached to items[0]
  foundClientRecord: clientRecordsInterface; // entire client record, with their assigned GP as foundGeneralPractitioner
  foundGeneralPractitioner?: GeneralPractitionerInterface;
  foundClinician?: ProfileInterface; // entire clinician, from foundAppointment.clinicianId
  foundProvider?: Provider; // default provider for foundClinician, or the first one found
  groups: { _id: string; name: string }[];
  isClaimReady: boolean; // checks a few things, described below
  mbsCode?: string; // autogenerated MBS code, only exists if foundClinician.medicare.role exists
  funder?: PendingClaimsFunder;
}
