import classNames from 'classnames';
import styles from './AppointmentReportFilters.module.scss';
import customStyles from 'pages/InvoiceSubmittedClaims/components/SubmittedClaimFilter/DateRangePickerCustom.module.scss';
import { DatePicker, DatePickerProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectFilters, setFilters } from 'redux/features/practitionerReport/practitionerReportSlice';
import moment from 'moment';
import { MOMENTJS_MONTH_YEAR_FORMAT } from 'utils/dateChecker';

const AppointmentReportFilters = () => {
  const dispatch = useAppDispatch();
  const { isEdgeAdminView } = useGetAccountPackageView();

  const filters = useAppSelector(selectFilters);

  const { dateString } = filters;

  const onChangeDateFilter = (dateString: [string, string] | string) => {
    dispatch(setFilters({ dateString }));
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(
          customStyles.dateRangeContainer,
          isEdgeAdminView ? customStyles.submittedDateContainerAdmin : customStyles.submittedDateContainer
        )}
      >
        <DatePicker
          picker="month"
          value={moment(dateString, MOMENTJS_MONTH_YEAR_FORMAT)}
          dropdownClassName={classNames(
            customStyles.dateRangeDropdown,
            isEdgeAdminView ? customStyles.dropdownClassNameAdmin : customStyles.dropdownClassName
          )}
          onChange={(
            _value: DatePickerProps['value'] | RangePickerProps['value'],
            dateString: [string, string] | string
          ) => {
            onChangeDateFilter(dateString);
          }}
          clearIcon={false}
          format={MOMENTJS_MONTH_YEAR_FORMAT}
        />
      </div>
    </div>
  );
};

export default AppointmentReportFilters;
