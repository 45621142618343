import { notification } from 'antd';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { AddressBookFormValidationInitialMessage } from 'pages/AddressBook/AddressBookList/components/AddressBookListContent/components/constants';
import { AddressBookCategory, AddressBookRole, AddressBookType } from 'pages/AddressBook/Interfaces/AddressBook';
import { AddressBookContactRole } from 'pages/AddressBook/Interfaces/AddressBookContact';
import { useRef, useState } from 'react';
import { useCreateAddressBookMutation } from 'redux/endpoints/clinicianProfileServices/addressBook';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { scrollToView } from 'utils/scrollToView';
import ModalV2 from '../../ModalV2/ModalV2';
import styles from './CreateAddressBookModal.module.scss';
import { CreateAddressBookRequestPayload, CreateAddressBookValidateField } from './CreateAddressBookModalInterfaces';
import { CreateAddressBookContactRequestPayload } from './components/AddressBookContactForm/AddressBookContactForm';
import AddressBookForm, { AddressBookFormHandler } from './components/AddressBookForm/AddressBookForm';
import { validateAddressBookForm } from './components/AddressBookForm/validation/AddressBookValidation';

interface CreateAddressBookModalProps {
  visible: boolean;
  onCloseModal: () => void;
  prefill?: Partial<CreateAddressBookRequestPayload>;
}

const CreateAddressBookModal = ({ visible, onCloseModal, prefill }: CreateAddressBookModalProps) => {
  const { accountId } = useGetAccountId();
  const addressBookFormRef = useRef<AddressBookFormHandler>(null);

  const [createAddressBook] = useCreateAddressBookMutation();

  const defaultFormValue: CreateAddressBookRequestPayload = {
    type: AddressBookType.Individual,
    role: AddressBookRole.Referrer,
    category: AddressBookCategory.GP,
    code: '',
    address: {
      line1: '',
      line2: '',
      state: '',
      suburb: '',
      postcode: '',
      country: ''
    },
    contacts: [],
    organisation: {
      name: '',
      phone: '',
      faxNumber: '',
      description: ''
    },
    ...prefill
  };

  const defaultContactFormValue: CreateAddressBookContactRequestPayload = {
    firstName: '',
    lastName: '',
    role: AddressBookContactRole.GP,
    mobileNumber: '',
    faxNumber: '',
    email: '',
    isPrimary: false
  };

  const [addressBookField, setAddressBookField] = useState(defaultFormValue);
  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [contactField, setContactField] = useState(
    defaultFormValue.type === AddressBookType.Individual && defaultFormValue.contacts?.length
      ? defaultFormValue.contacts[0]
      : defaultContactFormValue
  );

  const [errorMessage, setErrorMessage] = useState<CreateAddressBookValidateField>(
    AddressBookFormValidationInitialMessage
  );

  const validateAddressBookField = async (payload: CreateAddressBookRequestPayload) => {
    const { isError, messages } = validateAddressBookForm(payload) || {};

    setErrorMessage(messages as CreateAddressBookValidateField);

    if (isError) {
      setPageSubmitStatus('');
    }

    return !isError;
  };

  const handleChangeAddressBookField = async (val: CreateAddressBookRequestPayload) => {
    await validateAddressBookField(val);
    setAddressBookField(val);
  };

  const submitContactForm = async () => {
    if (addressBookFormRef.current) {
      return await addressBookFormRef.current.submitContact();
    }
  };

  const handleSubmit = async () => {
    setPageSubmitStatus('active');

    const formData: CreateAddressBookRequestPayload = {
      ...addressBookField,
      ...(addressBookField.type === AddressBookType.Organisation
        ? {
            organisation: {
              name: addressBookField.organisation?.name || '',
              phone: addressBookField.organisation?.phone,
              faxNumber: addressBookField.organisation?.faxNumber,
              description: addressBookField.organisation?.description
            }
          }
        : { organisation: undefined })
    };

    if (addressBookField.type === AddressBookType.Individual) {
      const contacts = await submitContactForm();
      if (contacts && contacts.length > 0) {
        formData.contacts = contacts;
      } else {
        setPageSubmitStatus('');
        return;
      }
    }

    const isValid = await validateAddressBookField(formData);
    if (isValid) {
      try {
        await createAddressBook({ accountId, payload: formData }).unwrap();

        setPageSubmitStatus('finished');
        setTimeout(() => {
          scrollToView('modalHeader', true);
          notification.success({
            message: 'New entry created',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          onCloseModal();
          setAddressBookField(defaultFormValue);
          setContactField(defaultContactFormValue);
          setPageSubmitStatus('');
        }, 2000);
      } catch (ex) {
        console.error(ex);
        setPageSubmitStatus('');
        notification.error({
          message: 'Failed to create new entry',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    } else {
      setPageSubmitStatus('');
      notification.error({
        message: 'Invalid form details',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={visible}
      title={'Address Book Entry'}
      onModalClose={onCloseModal}
    >
      <AddressBookForm
        mode="add"
        addressBookFormField={addressBookField}
        errorMessage={errorMessage}
        submitStatus={pageSubmitStatus}
        onChangeField={handleChangeAddressBookField}
        ref={addressBookFormRef}
        contactField={contactField}
        setContactField={(contact?: CreateAddressBookContactRequestPayload) =>
          setContactField(contact ?? defaultContactFormValue)
        }
      />
      <div className={styles.submitButtonContainer}>
        <ButtonAlt status={pageSubmitStatus} disabled={pageSubmitStatus !== ''} onClick={handleSubmit}>
          Save Addressee
        </ButtonAlt>
      </div>
    </ModalV2>
  );
};

export default CreateAddressBookModal;
