import { Skeleton } from 'antd';
import styles from './EventDetailLoading.module.scss';

const EventDetailLoading = () => {
  return (
    <div className={styles.container}>
      <div className={styles.clientDetails}>
        <Skeleton active />
      </div>
      <div className={styles.appointmentDetails}>
        <Skeleton active />
      </div>
    </div>
  );
};

export default EventDetailLoading;
