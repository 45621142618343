import AssessmentWidget from 'pages/Report/components/WidgetComponents/AssessmentWidget/AssessmentWidget';
import BarLineChartWidget from 'pages/Report/components/WidgetComponents/BarLineChartWidget/BarLineChartWidget';
import BodyTextWidget from 'pages/Report/components/WidgetComponents/BodyTextWidget/BodyTextWidget';
import ClientProfileWidget from 'pages/Report/components/WidgetComponents/ClientProfileWidget/ClientProfileWidget';
import FootNotesTextWidget from 'pages/Report/components/WidgetComponents/FootNotesTextWidget/FootNotesTextWidget';
import HeadingWidget from 'pages/Report/components/WidgetComponents/HeadingWidget/HeadingWidget';
import InsightsChartWidget from 'pages/Report/components/WidgetComponents/InsightsChartWidget/InsightsChartWidget';
import JournalCommentWidget from 'pages/Report/components/WidgetComponents/JournalCommentWidget/JournalCommentWidget';
import NotesWidget from 'pages/Report/components/WidgetComponents/NotesWidget/NotesWidget';
import QuestionWidget from 'pages/Report/components/WidgetComponents/QuestionWidget/QuestionWidget';
import { createRef, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { v4 as uuid } from 'uuid';
import { ReportDimension, ReportWidgetType } from 'interfaces/Reports/report';
import { WidgetWrapper } from './components/WidgetWrapper/WidgetWrapper';

import styles from './DragAndDropSection.module.scss';
import { CaseNoteField } from 'interfaces/caseNote';
import { ReportTemplateCaseNoteField } from '../../../../../../../../../interfaces/Reports/reportTemplate';

interface RefItem {
  id: string;
  itemHeight: number;
  itemWidth: number;
}

export interface DragAndDropSectionProps {
  onChangeDndContainerSize: (dimension: ReportDimension) => void;
  onChangeItems: (items: any[]) => void;
  dndDimension: ReportDimension;
  items: any;
  gridPositioning?: boolean;
  autoExpandContainerHeight?: number;
  autoAlignTemplateId?: string;
  templateBuilder?: boolean;
  refetch?: boolean;
}

export const snapToGridCalc = (x: number) => Math.round(x / 10) * 10;

const DragAndDropSection = ({
  onChangeDndContainerSize,
  dndDimension,
  onChangeItems,
  items,
  gridPositioning,
  autoExpandContainerHeight,
  autoAlignTemplateId,
  templateBuilder,
  refetch
}: DragAndDropSectionProps) => {
  const getRef = useRef<HTMLDivElement>(null);
  const arrayOfRefs = useRef([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (arrayOfRefs.current.length > 0) {
        adjustPageHeight();
      }
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, arrayOfRefs.current]);

  useEffect(() => {
    if (autoAlignTemplateId && refetch) {
      handleAutoAlignItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoAlignTemplateId, refetch]);

  const findLastItemHeight = (item: any) => {
    const extraMarginBottom = autoExpandContainerHeight ? autoExpandContainerHeight : 0;
    if (item.length > 0) {
      let bottommost = item.reduce((max: any, shot: any) => {
        return max.dimensions.itemHeight !== 'auto' &&
          shot.dimensions.itemHeight !== 'auto' &&
          shot.position.top + shot.dimensions.itemHeight >= max.position.top + max.dimensions.itemHeight
          ? shot
          : max;
      });
      return bottommost.position.top + bottommost.dimensions.itemHeight + extraMarginBottom;
    } else {
      return 420;
    }
  };

  const getRefItemHeight = () => {
    const listWithItemHeight: RefItem[] = [];
    arrayOfRefs.current.map((ref: any) => {
      if (ref.current !== null) {
        const id = ref.current.id;
        const { height, width } = ref.current.getBoundingClientRect();
        listWithItemHeight.push({
          id: id,
          itemHeight: height,
          itemWidth: width
        });
      }
      return true;
    });
    return listWithItemHeight;
  };

  const adjustPageHeight = () => {
    if (arrayOfRefs.current.length > 0) {
      const refItemHeight = getRefItemHeight();
      const newListWithHeight = refItemHeight.map((listItemObj) => {
        const temp = items.find((widgetListObj: any) => widgetListObj.id === listItemObj.id);
        return {
          ...temp,
          dimensions: {
            ...temp.dimensions,
            itemHeight: listItemObj.itemHeight,
            itemWidth: listItemObj.itemWidth
          }
        };
      });

      const getBottomMost = findLastItemHeight(newListWithHeight);
      onChangeDndContainerSize({
        height: getBottomMost,
        width: 840
      });
    }
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: 'insightsWidget',
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
    drop: (item: any, monitor: any) => {
      if (item.fromList) {
        const offset = monitor.getSourceClientOffset();
        const dropTargetXy = getRef.current?.getBoundingClientRect() || { top: 0, left: 0 };
        addNewData(item, offset, dropTargetXy);
      } else {
        const initialOffset = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.position.left + initialOffset.x);
        const top = Math.round(item.position.top + initialOffset.y);

        const leftPosition = gridPositioning ? snapToGridCalc(left) : left;
        const topPosition = gridPositioning ? snapToGridCalc(top) : top;

        moveBox(item.id, leftPosition, topPosition);
        return undefined;
      }
    }
  });

  const addNewData = (item: any, offset: any, dropTargetXy: any) => {
    const left = Math.round(offset.x - dropTargetXy.left);
    const top = Math.round(offset.y - dropTargetXy.top);
    const leftPosition = gridPositioning ? snapToGridCalc(left) : left;
    const topPosition = gridPositioning ? snapToGridCalc(top) : top;

    const newGroup = [
      ...items,
      {
        ...item,
        position: {
          top: topPosition,
          left: leftPosition
        },
        id: uuid()
      }
    ];
    onChangeItems(newGroup);
  };

  const moveBox = (id: any, left: number, top: number) => {
    const newMoveWidgetList = [];
    for (let obj of items) {
      newMoveWidgetList.push({
        ...obj,
        position: {
          left: obj.id === id ? left : obj.position.left,
          top: obj.id === id ? top : obj.position.top
        }
      });
    }
    onChangeItems(newMoveWidgetList);
  };

  const removeWidgetItem = (id: string) => {
    const newList = items.filter((obj: any) => obj.id !== id);
    const newRemoveWidgetList = [];
    for (let obj of newList) {
      newRemoveWidgetList.push({
        ...obj
      });
    }
    onChangeItems(newRemoveWidgetList);
  };

  const resizeWidgetItem = (id: string, width: number, height: number | string) => {
    const widthPosition = gridPositioning ? snapToGridCalc(width) : width;

    const newResizeWidgetList = [];
    for (let obj of items) {
      newResizeWidgetList.push({
        ...obj,
        dimensions: {
          ...obj.dimensions,
          height: obj.id === id ? height : obj.dimensions.height,
          width: obj.id === id ? widthPosition : obj.dimensions.width
        }
      });
    }
    onChangeItems(newResizeWidgetList);
  };

  const onChangeTextVal = (id: string, val: string, editModeVal: boolean) => {
    const newList = [];
    for (let obj of items) {
      newList.push({
        ...obj,
        details: {
          ...obj.details,
          title: obj.id === id ? val : obj.details.title
        },
        editMode: obj.id === id ? editModeVal : obj.editMode
      });
    }
    onChangeItems(newList);
  };

  const [expandedWidgets, setExpandedWidgets] = useState<string[]>([]);

  const [autoExpandItemsHeight, setAutoExpandItemsHeight] = useState<{ [id: string]: number }>({});

  useEffect(() => {
    setAutoExpandItemsHeight(getRefItemHeight().reduce((obj, i) => ({ ...obj, [i.id]: i.itemHeight }), {}));
  }, []);

  const onChangeTextValWrapperForAutoExpandWidget = (id: string, val: string, editModeVal: boolean) => {
    let changeItem = items.find((i: any) => i.id === id);
    if (changeItem.details.title !== val || changeItem.editMode !== editModeVal) {
      const prevHeight = autoExpandItemsHeight[id] || 0;
      if (!editModeVal) {
        handleExpandWidget(id, prevHeight);
      }
      onChangeTextVal(id, val, editModeVal);
    }
  };

  const onChangeQuestionSettingsVal = (id: string, val: ReportTemplateCaseNoteField, editModeVal: boolean) => {
    const newList = items.map((item: any) => ({
      ...item,
      details: {
        ...item.details,
        questionDetails: item.id === id ? val : item.details.questionDetails
      },
      editMode: item.id === id ? editModeVal : item.editMode
    }));
    onChangeItems(newList);
  };

  const onChangeQuestionVal = (id: string, val: CaseNoteField) => {
    const newList = [];
    for (let obj of items) {
      newList.push({
        ...obj,
        details: {
          ...obj.details,
          questionDetails: obj.id === id ? val : obj.details.questionDetails
        }
      });
    }
    onChangeItems(newList);
  };

  const onChangeDateRange = (id: string, graphFilter: number) => {
    const newList = [];
    for (let obj of items) {
      newList.push({
        ...obj,
        details: {
          ...obj.details,
          graphFilter: obj.id === id ? graphFilter : obj.details.graphFilter
        }
      });
    }
    onChangeItems(newList);
  };

  const getDimensionPosition = (itemsFromTop: any, refItems: RefItem[]) => {
    const getItemDimension = itemsFromTop.map((itemsFromTopObj: any) => {
      const getRefInfo = refItems.find((obj) => obj.id === itemsFromTopObj.id);
      return {
        ...itemsFromTopObj,
        dimensionPosition: {
          top: itemsFromTopObj.position.top,
          right: itemsFromTopObj.position.left + itemsFromTopObj.dimensions.width,
          left: itemsFromTopObj.position.left,
          bottom: itemsFromTopObj.position.top + getRefInfo?.itemHeight
        }
      };
    });

    return getItemDimension.sort((a: any, b: any) =>
      a.position.top > b.position.top ? 1 : b.position.top > a.position.top ? -1 : 0
    );
  };

  const expandItem = (widgetItemObj: any, widgetList: any[], heightDiff: number) => {
    let newWidgetList = widgetList;
    const excludeSelf = newWidgetList.filter((obj: any) => obj.id !== widgetItemObj.id);
    const widgetsBelow = excludeSelf.filter(
      (obj: any) =>
        obj.dimensionPosition.top > widgetItemObj.dimensionPosition.bottom ||
        (obj.dimensionPosition.top > widgetItemObj.dimensionPosition.top &&
          obj.dimensionPosition.left < widgetItemObj.dimensionPosition.right &&
          obj.dimensionPosition.right > widgetItemObj.dimensionPosition.left)
    );
    const nearestItemBelow = widgetsBelow.sort((a, b) => a.position.top - b.position.top)[0];

    if (heightDiff > 0) {
      const overlapped =
        nearestItemBelow?.dimensionPosition.top < widgetItemObj.dimensionPosition.bottom &&
        nearestItemBelow?.dimensionPosition.left < widgetItemObj.dimensionPosition.right &&
        nearestItemBelow?.dimensionPosition.bottom > widgetItemObj.dimensionPosition.top &&
        nearestItemBelow?.dimensionPosition.right > widgetItemObj.dimensionPosition.left;
      if (!overlapped) {
        return [...newWidgetList];
      }
    } else {
      if (!expandedWidgets.includes(widgetItemObj.id)) {
        return [...newWidgetList];
      }
    }

    heightDiff = widgetItemObj?.dimensionPosition.bottom - nearestItemBelow?.dimensionPosition.top + 10;
    //heightDiff = heightDiff || widgetItemObj.dimensionPosition.bottom - nearestBelow.dimensionPosition.top + 10;//2
    widgetsBelow.forEach((obj) => {
      const newTopValue = obj.dimensionPosition.top + heightDiff;
      const newBottomValue = obj.dimensionPosition.bottom + heightDiff;
      obj.position.top = obj.dimensionPosition.top = newTopValue;
      obj.position.bottom = obj.dimensionPosition.bottom = newBottomValue;
    });

    if (heightDiff > 0 && !expandedWidgets.includes(widgetItemObj.id)) {
      setExpandedWidgets([...expandedWidgets, widgetItemObj.id]);
    }

    return [...newWidgetList];
  };
  const itemAlignment = (widgetList: any[]) => {
    let continueCheck = false;
    let newWidgetList = widgetList.sort((a, b) => a.position.top - b.position.top);

    do {
      for (let i = 1; i < widgetList.length; i++) {
        const widgetItemObj = newWidgetList[i];
        const excludeSelf = newWidgetList.filter((obj: any) => obj.id !== widgetItemObj.id);
        const isOverlapItems = excludeSelf.filter(
          (obj: any) =>
            obj.dimensionPosition.top < widgetItemObj.dimensionPosition.bottom &&
            obj.dimensionPosition.left < widgetItemObj.dimensionPosition.right &&
            obj.dimensionPosition.bottom > widgetItemObj.dimensionPosition.top &&
            obj.dimensionPosition.right > widgetItemObj.dimensionPosition.left
        );
        if (isOverlapItems.length > 0) {
          const isOverlapItemOnTop =
            widgetItemObj.dimensionPosition.top <= isOverlapItems[0].dimensionPosition.top
              ? widgetItemObj
              : isOverlapItems[0];
          const overlapItem =
            widgetItemObj.dimensionPosition.top <= isOverlapItems[0].dimensionPosition.top
              ? isOverlapItems[0]
              : widgetItemObj;
          const newTopValue = isOverlapItemOnTop.dimensionPosition.bottom + 10;
          const newBottomValue = overlapItem.dimensionPosition.bottom - overlapItem.dimensionPosition.top + newTopValue;

          newWidgetList = newWidgetList.map((obj: any) => {
            return {
              ...obj,
              position: {
                ...obj.position,
                top: obj.id === overlapItem.id ? newTopValue : obj.position.top,
                bottom: obj.id === overlapItem.id ? newBottomValue : obj.position.bottom
              },
              dimensionPosition: {
                ...obj.dimensionPosition,
                top: obj.id === overlapItem.id ? newTopValue : obj.dimensionPosition.top,
                bottom: obj.id === overlapItem.id ? newBottomValue : obj.dimensionPosition.bottom
              }
            };
          });
          continueCheck = true;
          break;
        } else {
          continueCheck = false;
        }
      }
    } while (continueCheck);

    return newWidgetList;
  };

  const handleAutoAlignItems = () => {
    const getByTemplateId = items.filter((itemObj: any) => itemObj.details.applyTemplateId === autoAlignTemplateId);
    const getBesideTemplateId = items.filter((itemObj: any) => itemObj.details.applyTemplateId !== autoAlignTemplateId);

    const refItem = getRefItemHeight();
    const massageItem = getDimensionPosition(getByTemplateId, refItem);
    const newTemplateItems = itemAlignment(massageItem);
    const massageNewItemArrangement = [...getBesideTemplateId, ...newTemplateItems];
    onChangeItems(massageNewItemArrangement);
  };

  const handleExpandWidget = (id: string, prevHeight: number) => {
    const getById = items.find((itemObj: any) => itemObj.id === id);
    if (getById) {
      const refItem = getRefItemHeight();
      const massageItem = getDimensionPosition([getById], refItem)[0];
      const currentHeight = massageItem.dimensionPosition.bottom - massageItem.dimensionPosition.top;
      const massageItems = getDimensionPosition(items, refItem);
      const newTemplateItems = expandItem(massageItem, massageItems, currentHeight - prevHeight);
      const massageNewItemArrangement = [...newTemplateItems];
      onChangeItems(massageNewItemArrangement);
      setAutoExpandItemsHeight({ ...autoExpandItemsHeight, [id]: currentHeight });
    }
  };

  // @ts-ignore
  items.forEach(() => arrayOfRefs.current.push(createRef()));

  return (
    <div ref={getRef} id={'dndContentSection'}>
      <div className={isOver ? styles.canDropResizeWrapper : styles.resizeWrapper}>
        <div
          ref={dropRef}
          className={styles.container}
          style={{
            height: dndDimension.height,
            width: dndDimension.width
          }}
        >
          {items.map((itemObj: any, index: any) => {
            const widgetType = itemObj.details.type;
            const leftPos = itemObj.position.left;
            const topPos = itemObj.position.top;

            switch (widgetType) {
              case ReportWidgetType.HeadingWidget:
                return (
                  <WidgetWrapper
                    key={itemObj.id}
                    id={itemObj.id}
                    position={{
                      top: topPos,
                      left: leftPos
                    }}
                    dimensions={{
                      height: itemObj.dimensions.height,
                      width: itemObj.dimensions.width,
                      minWidth: 200,
                      maxWidth: 835
                    }}
                    onClickRemoveBtn={removeWidgetItem}
                    onResizeWidget={resizeWidgetItem}
                    allowDrag={!itemObj.editMode}
                    data={itemObj}
                  >
                    <div className={styles.widgetWrapper} id={itemObj.id} ref={arrayOfRefs.current[index]}>
                      <HeadingWidget
                        id={itemObj.id}
                        data={itemObj.details}
                        changeValue={onChangeTextVal}
                        editMode={itemObj.editMode}
                      />
                    </div>
                  </WidgetWrapper>
                );
              case ReportWidgetType.FootNotesTextWidget:
                return (
                  <WidgetWrapper
                    key={itemObj.id}
                    id={itemObj.id}
                    position={{
                      top: topPos,
                      left: leftPos
                    }}
                    dimensions={{
                      height: itemObj.dimensions.height,
                      width: itemObj.dimensions.width,
                      minWidth: 200,
                      maxWidth: 835
                    }}
                    onClickRemoveBtn={removeWidgetItem}
                    onResizeWidget={resizeWidgetItem}
                    allowDrag={!itemObj.editMode}
                    data={itemObj}
                  >
                    <div className={styles.widgetWrapper} id={itemObj.id} ref={arrayOfRefs.current[index]}>
                      <FootNotesTextWidget
                        id={itemObj.id}
                        data={itemObj.details}
                        changeValue={onChangeTextVal}
                        editMode={itemObj.editMode}
                      />
                    </div>
                  </WidgetWrapper>
                );
              case ReportWidgetType.JournalCommentWidget:
                return (
                  <WidgetWrapper
                    key={itemObj.id}
                    id={itemObj.id}
                    position={{
                      top: topPos,
                      left: leftPos
                    }}
                    dimensions={{
                      height: itemObj.dimensions.height,
                      width: itemObj.dimensions.width,
                      minWidth: 200,
                      maxWidth: 835
                    }}
                    onClickRemoveBtn={removeWidgetItem}
                    onResizeWidget={resizeWidgetItem}
                    allowDrag
                    data={itemObj}
                  >
                    <div id={itemObj.id} ref={arrayOfRefs.current[index]}>
                      <JournalCommentWidget data={itemObj.details} />
                    </div>
                  </WidgetWrapper>
                );
              case ReportWidgetType.InsightsChartWidget:
                return (
                  <WidgetWrapper
                    key={itemObj.id}
                    id={itemObj.id}
                    position={{
                      top: topPos,
                      left: leftPos
                    }}
                    dimensions={{
                      height: itemObj.dimensions.height,
                      width: itemObj.dimensions.width,
                      minWidth: 200,
                      maxWidth: 835
                    }}
                    onClickRemoveBtn={removeWidgetItem}
                    onResizeWidget={resizeWidgetItem}
                    allowDrag
                    data={itemObj}
                  >
                    <div id={itemObj.id} ref={arrayOfRefs.current[index]}>
                      <InsightsChartWidget
                        data={itemObj.details}
                        onDateRangeChange={(dateRange) => onChangeDateRange(itemObj.id, dateRange)}
                      />
                    </div>
                  </WidgetWrapper>
                );
              case ReportWidgetType.BarLineChartWidget:
                return (
                  <WidgetWrapper
                    key={itemObj.id}
                    id={itemObj.id}
                    position={{
                      top: topPos,
                      left: leftPos
                    }}
                    dimensions={{
                      height: itemObj.dimensions.height,
                      width: itemObj.dimensions.width,
                      minWidth: 200,
                      maxWidth: 835
                    }}
                    onClickRemoveBtn={removeWidgetItem}
                    onResizeWidget={resizeWidgetItem}
                    allowDrag
                    data={itemObj}
                  >
                    <div id={itemObj.id} ref={arrayOfRefs.current[index]}>
                      <BarLineChartWidget
                        data={itemObj.details}
                        onDateRangeChange={(dateRange) => onChangeDateRange(itemObj.id, dateRange)}
                      />
                    </div>
                  </WidgetWrapper>
                );
              case ReportWidgetType.AssessmentWidget:
              case ReportWidgetType.AssessmentTemplateWidget:
                return (
                  <WidgetWrapper
                    key={itemObj.id}
                    id={itemObj.id}
                    position={{
                      top: topPos,
                      left: leftPos
                    }}
                    dimensions={{
                      height: itemObj.dimensions.height,
                      width: itemObj.dimensions.width,
                      minWidth: 200,
                      maxWidth: 835
                    }}
                    onClickRemoveBtn={removeWidgetItem}
                    onResizeWidget={resizeWidgetItem}
                    allowDrag
                    data={itemObj}
                  >
                    <div id={itemObj.id} ref={arrayOfRefs.current[index]}>
                      <AssessmentWidget assessmentData={itemObj.details} />
                    </div>
                  </WidgetWrapper>
                );
              case ReportWidgetType.BodyTextWidget:
              case ReportWidgetType.BodyTextTemplateWidget:
                return (
                  <WidgetWrapper
                    key={itemObj.id}
                    id={itemObj.id}
                    position={{
                      top: topPos,
                      left: leftPos
                    }}
                    dimensions={{
                      height: itemObj.dimensions.height,
                      width: itemObj.dimensions.width,
                      minWidth: 200,
                      maxWidth: 835
                    }}
                    onClickRemoveBtn={removeWidgetItem}
                    onResizeWidget={resizeWidgetItem}
                    allowDrag={!itemObj.editMode}
                    data={itemObj}
                  >
                    <div className={styles.widgetWrapper} id={itemObj.id} ref={arrayOfRefs.current[index]}>
                      <BodyTextWidget
                        id={itemObj.id}
                        data={itemObj.details}
                        changeValue={onChangeTextValWrapperForAutoExpandWidget}
                        editMode={itemObj.editMode}
                        enableMention={widgetType === ReportWidgetType.BodyTextTemplateWidget}
                        topPosition={topPos}
                      />
                    </div>
                  </WidgetWrapper>
                );
              case ReportWidgetType.ClientProfileWidget:
                return (
                  <WidgetWrapper
                    key={itemObj.id}
                    id={itemObj.id}
                    position={{
                      top: topPos,
                      left: leftPos
                    }}
                    dimensions={{
                      height: itemObj.dimensions.height,
                      width: itemObj.dimensions.width,
                      minWidth: 200,
                      maxWidth: 835
                    }}
                    onClickRemoveBtn={removeWidgetItem}
                    onResizeWidget={resizeWidgetItem}
                    allowDrag={!itemObj.editMode}
                    data={itemObj}
                  >
                    <div className={styles.widgetWrapper} id={itemObj.id} ref={arrayOfRefs.current[index]}>
                      <ClientProfileWidget id={itemObj.id} data={itemObj.details} />
                    </div>
                  </WidgetWrapper>
                );
              case ReportWidgetType.CaseNoteQuestionWidget:
                return (
                  <WidgetWrapper
                    key={itemObj.id}
                    id={itemObj.id}
                    position={{
                      top: topPos,
                      left: leftPos
                    }}
                    dimensions={{
                      height: itemObj.dimensions.height,
                      width: itemObj.dimensions.width,
                      minWidth: 200,
                      maxWidth: 835
                    }}
                    onClickRemoveBtn={removeWidgetItem}
                    onResizeWidget={resizeWidgetItem}
                    allowDrag={!itemObj.editMode}
                    data={itemObj}
                  >
                    <div className={styles.widgetWrapper} id={itemObj.id} ref={arrayOfRefs.current[index]}>
                      <QuestionWidget
                        id={itemObj.id}
                        data={itemObj.details}
                        onChangeQuestionSettings={onChangeQuestionSettingsVal}
                        onChangeQuestionValue={onChangeQuestionVal}
                        editMode={itemObj.editMode}
                        showMoveCursor
                        viewMode={templateBuilder ? 'edit' : 'template'}
                      />
                    </div>
                  </WidgetWrapper>
                );
              default:
                return (
                  <WidgetWrapper
                    key={itemObj.id}
                    id={itemObj.id}
                    position={{
                      top: topPos,
                      left: leftPos
                    }}
                    dimensions={{
                      height: itemObj.dimensions.height,
                      width: itemObj.dimensions.width,
                      minWidth: 200,
                      maxWidth: 835
                    }}
                    onClickRemoveBtn={removeWidgetItem}
                    onResizeWidget={resizeWidgetItem}
                    allowDrag
                    data={itemObj}
                  >
                    <div id={itemObj.id} ref={arrayOfRefs.current[index]}>
                      <NotesWidget data={itemObj.details} />
                    </div>
                  </WidgetWrapper>
                );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default DragAndDropSection;
