import { Modal, notification, Skeleton } from 'antd';
import noFoundResult from 'assets/images/NoFoundResult.png';
import classnames from 'classnames';
import CreateAddressBookModal from 'components/AddressBook/CreateAddressBookModal/CreateAddressBookModal';
import EditAddressBookModal from 'components/AddressBook/EditAddressBookModal/EditAddressBookModal';
import IconButton from 'components/IconButton/IconButton';
import ListHeaderItem from 'components/T23/ListHeaderItem/ListHeaderItem';
import ThreeDotMenu, { ThreeDotMenuHandle } from 'components/T23/ThreeDotMenu/ThreeDotMenu';
import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';
import {
  ADDRESS_BOOK_ROLE_OPTIONS,
  ADDRESS_BOOK_TYPE_OPTIONS,
  AddressBookFromAPI,
  AddressBookRole,
  AddressBookType
} from 'pages/AddressBook/Interfaces/AddressBook';
import { RefObject, useState } from 'react';
import { AddressBookListSorting, AddressBookTab, selectActiveTab } from 'redux/addressBook/addressBookSlice';
import { useDeleteAddressBookMutation } from 'redux/endpoints/clinicianProfileServices/addressBook';
import { useAppSelector } from 'redux/hooks';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import AddressBookCategoryBadge from '../../../AddressBookCategoryBadge/AddressBookCategoryBadge';
import { ColumnSettingsEnum } from '../constants';
import styles from './AddressBookTable.module.scss';
import AddressBookItem from './components/AddressBookItem/AddressBookItem';
import columnStyles from './components/AddressBookItem/AddressBookItem.module.scss';

interface AddressBookTableProps {
  addressBooks: AddressBookFromAPI[];
  sorting: AddressBookListSorting;
  setSortType: (sortType: ColumnSettingsEnum) => void;
  isLoading: boolean;
  clearAllFilter: () => void;
}

const AddressBookTable = ({ addressBooks, sorting, setSortType, isLoading, clearAllFilter }: AddressBookTableProps) => {
  const { sortBy, sortType } = sorting;
  const activeTab = useAppSelector(selectActiveTab);
  const { accountId } = useGetAccountId();
  const [editAddressBookModalVisible, setEditAddressBookModalVisible] = useState(false);
  const [showCreateAddressBookModal, setShowCreateAddressBookModal] = useState(false);
  const [selectedAddressBook, setSelectedAddressBook] = useState<AddressBookFromAPI>();
  const [deleteAddressBook] = useDeleteAddressBookMutation();

  const COLUMNS = [
    {
      label: 'ID',
      field: 'addressBookId',
      className: 'addressBookIdSection',
      sort: () => setSortType(ColumnSettingsEnum.AddressBookId)
    },
    {
      label: 'Name',
      field: 'name',
      className: 'nameSection',
      sort: () => setSortType(ColumnSettingsEnum.Name),
      cell: (row: AddressBookFromAPI) => {
        return (
          <div>
            {row.type === AddressBookType.Organisation
              ? row.organisation.name
              : row.contacts.length > 0
              ? (row.contacts[0].firstName + ' ' + (row.contacts[0].lastName || '')).trim()
              : ''}
          </div>
        );
      }
    },
    { label: 'Code', field: 'code', className: 'codeSection' },
    {
      label: 'Category',
      field: 'category',
      className: 'categorySection',
      sort: () => setSortType(ColumnSettingsEnum.Category),
      cell: (row: AddressBookFromAPI) => {
        return <AddressBookCategoryBadge type={row.category} />;
      }
    },
    ...(activeTab && [AddressBookTab.Organisation, AddressBookTab.Individual].includes(activeTab)
      ? [
          {
            label: 'Role',
            field: 'role',
            className: 'roleSection',
            cell: (row: AddressBookFromAPI) => {
              return (
                <div className={styles.badgeContainer}>
                  {ADDRESS_BOOK_ROLE_OPTIONS.find((opt) => opt._id === row.role)?.name.toUpperCase()}
                </div>
              );
            }
          }
        ]
      : []),
    ...(activeTab && [AddressBookTab.Funder, AddressBookTab.Referrer].includes(activeTab)
      ? [
          {
            label: 'Type',
            field: 'type',
            className: 'typeSection',
            cell: (row: AddressBookFromAPI) => {
              return (
                <div className={styles.badgeContainer}>
                  {ADDRESS_BOOK_TYPE_OPTIONS.find((opt) => opt._id === row.type)
                    ?.name.slice(0, 3)
                    .toUpperCase()}
                </div>
              );
            }
          }
        ]
      : []),
    {
      label: 'Contact Number',
      field: 'contactNumber',
      className: 'contactSection',
      cell: (row: AddressBookFromAPI) => {
        const phone =
          row.type === AddressBookType.Organisation
            ? row.organisation.phone
            : row.contacts.length > 0 && row.contacts[0].mobileNumber;
        const fax =
          row.type === AddressBookType.Organisation
            ? row.organisation.faxNumber
            : row.contacts.length > 0 && row.contacts[0].faxNumber;

        return (
          <>
            {phone && (
              <div className={columnStyles.contactNumber}>
                <i className={`material-icons ${columnStyles.contactIcon}`}>phone</i>
                {phone}
              </div>
            )}
            {fax && (
              <div className={columnStyles.contactNumber}>
                <i className={`material-icons ${columnStyles.contactIcon}`}>fax</i>
                {fax}
              </div>
            )}
          </>
        );
      }
    },
    {
      label: 'Address',
      field: 'address',
      className: 'addressSection',
      cell: ({ address }: AddressBookFromAPI) => (
        <>
          {address?.line1 && `${address.line1} `}
          {address?.line2 && `${address.line2} `}
          <br />
          {address?.suburb && `${address.suburb} `}
          {address?.state && `${address.state} `}
          {address?.postcode && `${address.postcode} `}
          <br />
          {address?.country && `${address.country} `}
        </>
      )
    },
    ...(activeTab && activeTab !== AddressBookTab.Individual
      ? [
          {
            label: 'Description',
            field: 'description',
            className: 'descriptionSection',
            cell: ({ organisation }: AddressBookFromAPI) =>
              organisation?.description?.trim() ? (
                <div className={styles.overflowDescription}>{organisation.description}</div>
              ) : (
                '-'
              )
          }
        ]
      : []),
    {
      label: '',
      field: '',
      className: 'actionButtons',
      cell: (
        row: AddressBookFromAPI,
        {
          expand,
          setExpand,
          menuRef
        }: { expand: boolean; setExpand: (val: boolean) => void; menuRef: RefObject<ThreeDotMenuHandle> }
      ) => (
        <>
          {activeTab !== AddressBookTab.Individual && (
            <IconButton
              size={'medium'}
              paddingSize={'medium'}
              active={expand}
              iconName={expand ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              onClick={() => setExpand(!expand)}
            />
          )}
          <ThreeDotMenu size={'medium'} paddingSize={'medium'} ref={menuRef}>
            <ThreeDotMenuItem
              label="Edit Contact"
              onClick={() => {
                setSelectedAddressBook(row);
                setEditAddressBookModalVisible(true);
              }}
            />
            <ThreeDotMenuItem
              label="Delete Contact"
              onClick={() => {
                onDeleteAddressBook(row._id);
              }}
            />
            <ThreeDotMenuItem
              label={`Copy to ${row.role === AddressBookRole.Funder ? 'Referrer' : 'Funder'}`}
              postIcon="copy_all"
              onClick={() => {
                setSelectedAddressBook({
                  ...row,
                  role: row.role === AddressBookRole.Funder ? AddressBookRole.Referrer : AddressBookRole.Funder
                });
                setShowCreateAddressBookModal(true);
              }}
            />
          </ThreeDotMenu>
        </>
      )
    }
  ];

  const onDeleteAddressBook = (_id: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this address book?',
      okText: 'Confirm',
      onOk: async () => {
        try {
          await deleteAddressBook({ accountId, addressBookId: _id }).unwrap();
          notification.success({
            message: 'Address book deleted',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } catch (ex) {
          console.error(ex);
          notification.error({ message: 'Something went wrong while trying to delete address book' });
        }
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        {COLUMNS.map((header) => (
          <div key={header.field} className={classnames(styles.header, styles[header.className])}>
            <ListHeaderItem
              columnId={header.field}
              columnName={header.label}
              sortAble={!!header.sort}
              sortBy={header.sort && sortBy}
              sortType={header.sort && sortType}
              onSort={header.sort}
            />
          </div>
        ))}
      </div>
      <div>
        {isLoading ? (
          <>
            {[...Array(5)].map((obj, i) => (
              <div key={i} className={styles.loadingContainer}>
                <Skeleton.Input active className={styles.idLoading} />
                <Skeleton.Input active className={styles.nameLoading} />
                <Skeleton.Input active className={styles.codeLoading} />
                <Skeleton.Input active className={styles.categoryLoading} />
                <Skeleton.Input active className={styles.typeLoading} />
                <Skeleton.Input active className={styles.contactLoading} />
                <Skeleton.Input active className={styles.addressLoading} />
                {activeTab && activeTab !== AddressBookTab.Individual && (
                  <Skeleton.Input active className={styles.descriptionLoading} />
                )}
                <div className={styles.actionLoading} />
              </div>
            ))}
          </>
        ) : !isLoading && addressBooks.length > 0 ? (
          <>
            {addressBooks.map((addressBook, index) => (
              <AddressBookItem
                key={index}
                addressBook={addressBook}
                columns={COLUMNS}
                onAddContact={(addressBook: AddressBookFromAPI) => {
                  setSelectedAddressBook(addressBook);
                  setEditAddressBookModalVisible(true);
                }}
              />
            ))}
          </>
        ) : (
          !isLoading && (
            <div className={styles.noResult}>
              <img className={styles.noResultImg} src={noFoundResult} alt={'noFoundResult'} />
              <div>
                <div className={styles.noTitle}>No contact founds.</div>
                <div className={styles.noDesc}>
                  Please update your filters or{' '}
                  <span className={styles.clearBtn} onClick={clearAllFilter}>
                    clear all filters.
                  </span>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      {selectedAddressBook && editAddressBookModalVisible && (
        <EditAddressBookModal
          initialFields={selectedAddressBook}
          onCloseModal={() => setEditAddressBookModalVisible(false)}
        />
      )}

      {showCreateAddressBookModal && (
        <CreateAddressBookModal
          prefill={selectedAddressBook}
          visible={showCreateAddressBookModal}
          onCloseModal={() => setShowCreateAddressBookModal(false)}
        />
      )}
    </div>
  );
};

export default AddressBookTable;
