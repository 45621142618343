import { Button, Dropdown, DropdownProps } from 'antd';
import classNames from 'classnames';
import FilterCheckList, {
  FilterCheckListChangeValue,
  FilterCheckListItem,
  FilterCheckListProps,
  FilterRadioListItem
} from 'components/FilterCheckList/FilterCheckList';
import { ReactNode, RefObject, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './FilterDropdown.module.scss';
import { Tooltip } from 'react-tooltip';

interface FilterDropdownProps {
  id: string;
  children: ReactNode;
  enableButtonLabelItemsColor?: boolean;
  hasMoreData?: boolean;
  icon?: ReactNode;
  isInactive?: boolean;
  loading?: boolean;
  loadMore?: () => void;
  menuItems: FilterCheckListItem[];
  onChangeItem: FilterCheckListProps['onChangeItem'];
  searchable?: boolean;
  selectedFilterList: FilterCheckListItem[];
  showSearchIcon?: boolean;
  showToggleAllButtons?: boolean;
  filterRadioListItems?: FilterRadioListItem[];
  selectedRadioItem?: string;
  onChangeRadioItem?: (selected: string) => void;
  /**
   * `isFetchingMore` is the loading state for infinity scroll when they are fetching more
   */
  isFetchingMore?: boolean;
  enableSelectedBackground?: boolean;
  onClearFilter?: () => void;
  setSearchText?: (value: string) => void;
  baseDropdownClass?: string;
  multiple?: boolean;
  fullWidth?: boolean;
  variant?: 'default' | 'circle' | 'round';
  size?: 'small' | 'middle' | 'large';
  persistLabel?: boolean;
  tooltipContent?: string;
  onDropdownOpenChange?: (open: boolean) => void;
  checkBoxSectionTitle?: string;
  radioSectionTitle?: string;
  reverseSection?: boolean;
  hideFilterList?: boolean;
  customLabel?: ReactNode;
  placements?: DropdownProps['placement'];
  dropdownRef?: RefObject<HTMLDivElement>;
}

const FilterDropdown = ({
  id,
  children,
  enableButtonLabelItemsColor,
  hasMoreData,
  icon,
  isInactive,
  loading,
  loadMore,
  menuItems,
  onChangeItem,
  searchable,
  selectedFilterList,
  showSearchIcon,
  showToggleAllButtons,
  filterRadioListItems,
  selectedRadioItem,
  onChangeRadioItem,
  isFetchingMore,
  enableSelectedBackground,
  onClearFilter,
  setSearchText,
  baseDropdownClass,
  multiple = true,
  fullWidth = false,
  variant = 'round',
  size = 'middle',
  persistLabel = false,
  tooltipContent,
  onDropdownOpenChange,
  checkBoxSectionTitle,
  radioSectionTitle,
  reverseSection,
  hideFilterList = false,
  customLabel,
  placements,
  dropdownRef
}: FilterDropdownProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleOnchange = (e: FilterCheckListChangeValue) => {
    onChangeItem(e);
    if (!multiple) {
      setIsDropdownOpen(false);
    }
  };
  const onOpenChange = (open: boolean) => {
    setIsDropdownOpen(open);
    onDropdownOpenChange?.(open);
  };

  const dropdownRender = () => (
    <div ref={dropdownRef}>
      <FilterCheckList
        id={id}
        hasMoreData={hasMoreData}
        items={menuItems}
        loading={loading}
        loadMore={loadMore}
        onChangeItem={handleOnchange}
        searchable={searchable}
        selectedFilterList={selectedFilterList}
        showToggleAllButtons={showToggleAllButtons}
        filterRadioListItems={filterRadioListItems}
        selectedRadioItem={selectedRadioItem}
        onChangeRadioItem={onChangeRadioItem}
        isFetchingMore={isFetchingMore}
        setSearchText={setSearchText}
        multiple={multiple}
        checkBoxSectionTitle={checkBoxSectionTitle}
        radioSectionTitle={radioSectionTitle}
        reverseSection={reverseSection}
        hideFilterList={hideFilterList}
      />
    </div>
  );

  const selectedRadioItemObject = filterRadioListItems?.find((i) => i._id === selectedRadioItem);

  let selectedItemsName = enableButtonLabelItemsColor
    ? selectedFilterList.map((i, index) => (
        <div
          key={index}
          className={classNames(styles.buttonLabelItemsColor)}
          style={{ backgroundColor: i.backgroundColor, color: i.fontColor }}
        >
          {i.name}
        </div>
      ))
    : [...selectedFilterList, ...(selectedRadioItemObject ? [selectedRadioItemObject] : [])]
        .map((i) => `${i?.name} ${i?.selectedSubMenu?._id ? ` - ${i?.selectedSubMenu?.name}` : ''}`)
        .join(' | ');

  const passedInButtonLabelWithCount = (
    <>
      {children}
      <div
        className={classNames(
          styles.selectedItemsCount,
          isEdgeAdminView
            ? classNames(styles.adminColor, (isDropdownOpen || selectedFilterList.length > 0) && styles.adminOpen)
            : classNames(styles.nonAdminColor, isDropdownOpen && styles.nonAdminOpen)
        )}
      >
        {selectedFilterList.length}
      </div>
    </>
  );

  const selectedItems = selectedFilterList.length < 4 ? selectedItemsName : passedInButtonLabelWithCount;

  const baseDropdown = (
    <>
      <Dropdown
        disabled={isInactive}
        overlayClassName={baseDropdownClass ?? styles.container}
        dropdownRender={dropdownRender}
        onOpenChange={onOpenChange}
        open={isDropdownOpen}
        trigger={['click']}
        placement={placements}
      >
        {customLabel ? (
          customLabel
        ) : (
          <Button
            size={size}
            shape={variant}
            icon={showSearchIcon && !selectedFilterList.length && icon}
            className={classNames(
              styles.button,
              fullWidth && styles.block,
              isEdgeAdminView
                ? classNames(
                    styles.adminButton,
                    isDropdownOpen && styles.adminButtonOpen,
                    enableSelectedBackground && selectedFilterList.length > 0 && styles.adminSelected
                  )
                : classNames(
                    styles.nonAdminButton,
                    isDropdownOpen && styles.nonAdminButtonOpen,
                    enableSelectedBackground && selectedFilterList.length > 0 && styles.nonAdminSelected
                  ),
              isInactive && styles.disabledButton
            )}
          >
            {persistLabel ? (
              <>
                <span className={styles.label}>{children}</span> {selectedItems}
              </>
            ) : selectedFilterList.length > 0 ? (
              selectedItems
            ) : (
              children
            )}
            <div className={classNames(styles.arrowWrapper, fullWidth && styles.alignRight)}>
              {isDropdownOpen ? (
                <span className={classNames('material-icons-outlined', styles.expandIcon)}>expand_less</span>
              ) : !onClearFilter || selectedFilterList.length < 1 ? (
                <span className={classNames('material-icons-outlined', styles.expandIcon)}>expand_more</span>
              ) : (
                <div
                  className={styles.clearButton}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClearFilter && onClearFilter();
                  }}
                >
                  <i className={`material-icons-outlined ${styles.clearIcon}`}>close</i>
                </div>
              )}
            </div>
          </Button>
        )}
      </Dropdown>
    </>
  );

  if (isInactive) {
    return (
      <>
        <Tooltip id={id} className={styles.tooltips}>
          {tooltipContent ? tooltipContent : 'You can only filter on activated columns'}
        </Tooltip>
        <div data-tooltip-id={id} className={styles.disabledButtonWrapper}>
          {baseDropdown}
        </div>
      </>
    );
  }

  return baseDropdown;
};

export default FilterDropdown;
