import { useRef, useState } from 'react';
import JoditEditor, { Jodit } from 'jodit-pro-react';
import { sanitizeHTMLString } from 'utils/sanitizeHTMLString';
import CaseNoteHeaderImageBtn from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteHeaderImageBtn/CaseNoteHeaderImageBtn';

interface RichEditorProps {
  value: string;
  config: any;
  onChange: (e: any) => void;
  recordId?: string;
  profileId?: string;
  withCaseNoteHeaderButton?: boolean;
  onBlurMethod?: boolean;
}

const RichEditor = ({
  value,
  config,
  onChange,
  recordId,
  profileId,
  withCaseNoteHeaderButton,
  onBlurMethod
}: RichEditorProps) => {
  const editor = useRef<Jodit>(null);
  const [editorValue, setEditorValue] = useState(value);

  // sanitizing on onChange directly will reset value with sanitized input,
  // this will cause cursor jump to the start of textarea on every character typed
  const onInputChange = (e: string) => {
    onChange(sanitizeHTMLString(e));
  };

  const onImageChange = (e: string) => {
    setEditorValue(e);
    onChange(sanitizeHTMLString(e));
  };

  return (
    <>
      {onBlurMethod ? (
        <JoditEditor
          ref={editor}
          value={value}
          config={config}
          onBlur={(newContent) => {
            onInputChange(newContent);
          }}
        />
      ) : (
        <JoditEditor
          ref={editor}
          value={editorValue}
          config={config}
          onChange={(newContent) => {
            onInputChange(newContent);
          }}
        />
      )}
      {withCaseNoteHeaderButton && recordId && profileId && (
        <CaseNoteHeaderImageBtn
          recordId={recordId}
          profileId={profileId}
          caseNoteBodyVal={value}
          onChangeBody={onImageChange}
        />
      )}
    </>
  );
};

export default RichEditor;
