import { Skeleton, notification } from 'antd';
import styles from './AssessmentSummary.module.scss';
import AssessmentItem from './components/AssessmentItem/AssessmentItem';
import { ClientAssessment, useGetAllClientAssessments } from './hooks/useGetAllClientAssessments';
import {
  postCancelAssessments,
  postSendAssessmentReminders
} from 'utils/http/CheckInService/Assessment/adhocAssessment';
import { useGetAccessToken } from 'utils/hooks/token';
import PaginationListV2 from 'components/v2/PaginationListV2/PaginationListV2';

interface AssessmentSummaryProps {
  accountId: string;
  clientRecordId: string;
}

const AssessmentSummary = ({ accountId, clientRecordId }: AssessmentSummaryProps) => {
  const { token } = useGetAccessToken();
  const { isLoading, assessments, navigatePage, currentPage, totalPage, totalItems, refetchAssessments } =
    useGetAllClientAssessments({ accountId, clientRecordId });

  const handleCancelAssessment = (assessment: ClientAssessment) => async () => {
    if (assessment.type) {
      try {
        await postCancelAssessments(token, accountId, {
          assessments: [{ _id: assessment._id, assessmentType: assessment.type }]
        });

        refetchAssessments();
        notification.success({
          message: `Successfully cancelled form`,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      } catch (ex) {
        notification.error({
          message: `Something went wrong while trying to cancel the selected form.`
        });
      }
    }
  };

  const handleSendAssessmentReminder = (assessment: ClientAssessment) => async () => {
    if (assessment.type) {
      try {
        await postSendAssessmentReminders(token, accountId, {
          assessments: [{ _id: assessment._id, assessmentType: assessment.type }]
        });

        notification.success({
          message: 'Successfully sent reminder for form',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      } catch (ex) {
        notification.error({
          message: `Something went wrong while trying to send a reminder for the selected form.`
        });
      }
    }
  };

  return (
    <>
      <div className={styles.title}>OUTSTANDING FORMS SUMMARY</div>
      <div className={styles.header}>
        <div className={styles.assessmentName}>Form(s)</div>
        <div className={styles.requestDate}>Request Date</div>
        <div className={styles.button} />
      </div>
      <div className={styles.content}>
        {isLoading ? (
          [...Array(10)].map((_, i) => <Skeleton.Input key={i} className={styles.loading} active />)
        ) : assessments.length ? (
          <>
            {assessments.map((assessment, i) => (
              <AssessmentItem
                key={i}
                assessment={assessment}
                clientRecordId={clientRecordId}
                onCancelAssessment={handleCancelAssessment(assessment)}
                onSendReminder={handleSendAssessmentReminder(assessment)}
              />
            ))}
            {totalPage > 1 && (
              <PaginationListV2
                paging={{
                  page: currentPage,
                  perPage: 10,
                  totalItems
                }}
                onPageChange={(paging) => navigatePage(Number(paging))}
              />
            )}
          </>
        ) : (
          'No outstanding forms'
        )}
      </div>
    </>
  );
};

export default AssessmentSummary;
