import { Modal, notification } from 'antd';
import location from 'assets/images/location.svg';
import classnames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import MaterialDropdown from 'components/MaterialFieldComponent/MaterialDropdown/MaterialDropdown';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { ButtonStatusType } from 'components/v2/Button/Button';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { LocationType } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useState } from 'react';
import { validateMedicareProviderNumber } from 'utils/helpers/validateMedicareProviderNumber';
import { useGetAccessToken } from 'utils/hooks/token';
import { postLocation } from 'utils/http/BillingService/Invoice/claimingcom';

import styles from './AddLocationModal.module.scss';

const locationTypeOptions = [
  { label: 'Community Health Centre', value: LocationType.CommunityHealthCentre },
  { label: 'Home Visit', value: LocationType.HomeVisit },
  { label: 'Hospital', value: LocationType.Hospital },
  { label: 'Residential Care Facility', value: LocationType.ResidentialCareFacility },
  { label: 'Rooms', value: LocationType.Rooms }
];

interface AddLocationModalProps {
  visible: boolean;
  setShowAddLocationModal: (bool: boolean) => void;
  refetchMedicareLocations: () => void;
}

const AddLocationModal = ({ visible, setShowAddLocationModal, refetchMedicareLocations }: AddLocationModalProps) => {
  const { token } = useGetAccessToken();
  const [locationName, setLocationName] = useState('');
  const [address, setAddress] = useState('');
  const [type, setType] = useState<LocationType>();
  const [providerNumber, setProviderNumber] = useState('');
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    locationName: false,
    agreement: false,
    providerNumber: false
  });
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');
  const providerNumberError = validateMedicareProviderNumber(providerNumber);

  const initForm = () => {
    setLocationName('');
    setAddress('');
    setType(undefined);
    setProviderNumber('');
    setAgreementChecked(false);
    setValidationErrors({ locationName: false, agreement: false, providerNumber: false });
  };

  const handleCloseModal = () => {
    setShowAddLocationModal(false);
    initForm();
  };

  const handleChangeLocationName = (value: string) => {
    if (validationErrors.locationName && value.length > 2 && value.length < 501) {
      setValidationErrors({ ...validationErrors, locationName: false });
    }
    setLocationName(value);
  };

  const handleChangeAgreementChecked = () => {
    if (validationErrors.agreement) {
      setValidationErrors({ ...validationErrors, agreement: false });
    }
    setAgreementChecked(!agreementChecked);
  };

  const handleChangeProviderNumber = (value: string) => {
    if (validationErrors.providerNumber) {
      setValidationErrors({ ...validationErrors, providerNumber: false });
    }
    setProviderNumber(value.toUpperCase());
  };

  const handleSubmit = async () => {
    setSubmitStatus('active');
    const locationNameError = locationName === '' || locationName.length < 3 || locationName.length > 500;
    const providerNumberRequiredError = type === LocationType.Hospital && providerNumberError;
    if (locationNameError || !agreementChecked || providerNumberRequiredError) {
      setValidationErrors({
        locationName: locationNameError,
        agreement: !agreementChecked,
        providerNumber: !!providerNumberRequiredError
      });

      setSubmitStatus('');
      return;
    }

    try {
      await postLocation(token, {
        name: locationName,
        address: address === '' ? undefined : address,
        type,
        ...(type === LocationType.Hospital && { providerNumber })
      });

      notification.success({
        message: 'Location added',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      handleCloseModal();
      refetchMedicareLocations();

      setSubmitStatus('finished');
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to add location' });
    }
    setSubmitStatus('');
  };

  return (
    <Modal
      className={styles.modalContainer}
      open={visible}
      closable
      title={<div className={styles.title}>Add Location</div>}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.descriptionsContainer}>
          <img src={location} alt={'location'} className={styles.icon} />
          <div className={styles.instructionsContainer}>
            <div className={styles.title}>To Generate Location ID</div>
            <div className={styles.instructions}>
              <div>1. Name your delivery location.</div>
              <div>2. Include address of the location.</div>
              <div>3. Select location type (optional).</div>
            </div>
          </div>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.halfWidth}>
            <MaterialInput
              id={'locationName'}
              label={'Location Name*'}
              value={locationName}
              onChange={(e) => handleChangeLocationName(e.target.value)}
              required
            />
            {validationErrors.locationName && (
              <div className={styles.error}>Please enter the location name (3 - 500 characters)</div>
            )}
          </div>
          <MaterialInput
            id={'locationAddress'}
            label={'Address'}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          <div className={styles.typeContainer}>
            <div className={styles.halfWidth}>
              <MaterialDropdown
                labelClassName={styles.dropdownLabel}
                id={'type'}
                label={'Location Type'}
                labelHelpWithTooltips="Required for DVA claims"
                dropDownList={locationTypeOptions}
                selectedValue={type}
                isClearable
                onChangeValue={(value) => setType(value as typeof type)}
              />
            </div>
            {type === LocationType.Hospital && (
              <div className={styles.halfWidth}>
                <MaterialInput
                  id={'providerNumber'}
                  label={
                    <>
                      Provider Number{type === LocationType.Hospital && '*'}
                      <HelpOutLineWithTooltips
                        id="providerNumber"
                        desc={
                          'Commonwealth Hospital Facility Provider Number. Required only if the location\'s type is "Hospital".'
                        }
                      />
                    </>
                  }
                  value={providerNumber}
                  onChange={(e) => handleChangeProviderNumber(e.target.value)}
                  required
                />
                {validationErrors.providerNumber && (
                  <div className={classnames(styles.error, styles.providerNumberError)}>{providerNumberError}</div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.agreementContainer}>
          <CheckBox
            className={styles.checkbox}
            id={'agreement'}
            value={agreementChecked}
            onChange={handleChangeAgreementChecked}
          />
          <div>
            <div
              className={classnames(styles.statement, agreementChecked ? styles.checked : styles.unchecked)}
              onClick={handleChangeAgreementChecked}
            >
              I confirm I wish to generate a Location ID connected to Tacklit for this location, the location
              information is accurate, and I have the authority to create this new Location ID.
            </div>
            {validationErrors.agreement && (
              <div className={styles.error}>Please check this box if you wish to generate a Location ID</div>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <ButtonAlt className={styles.button} onClick={handleSubmit} status={submitStatus}>
            Generate Location ID
          </ButtonAlt>
        </div>
      </div>
    </Modal>
  );
};

export default AddLocationModal;
