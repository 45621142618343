import styles from './ReferralInformation.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import moment from 'moment';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { REFERRAL_STATUS_LABELS, Referral } from 'interfaces/Referral/Referral';
import { Mode } from '../../ReferralsMVP';

interface ReferralInformationProps {
  referral: Referral;
  setMode: (mode: Mode) => void;
}

const ReferralInformation = ({
  referral: { referralId, status, detail, date, source },
  setMode
}: ReferralInformationProps) => {
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();

  const reformatDate = (referralDate: string) => moment(referralDate, 'YYYY-MM-DD').format('D MMM YYYY');

  return (
    <div className={styles.container}>
      <div className={styles.title}>{referralId} - Referral Information</div>
      <div className={styles.referralInformationDetail}>
        {detail.length > 0 || date.length > 0 ? (
          <>
            {source?.trim() && (
              <div className={styles.referralSource}>
                Source
                <div className={styles.source}>
                  <div className={styles.sourceBadge}>{source}</div>
                </div>
              </div>
            )}
            {status && (
              <div className={styles.referralStatus}>
                Status
                <div className={styles.status}>
                  <div className={styles.statusBadge} style={{ backgroundColor: REFERRAL_STATUS_LABELS[status].color }}>
                    {REFERRAL_STATUS_LABELS[status].label}
                  </div>
                </div>
              </div>
            )}
            {date.length > 0 && (
              <div className={styles.referralDate}>
                <div className={styles.dateHeader}>
                  Date
                  {medicareRebateFeatureToggle && (
                    <HelpOutLineWithTooltips
                      id="referral-date"
                      iconClass={styles.icon}
                      desc="This referral date will be automatically used in future Medicare claims"
                    />
                  )}
                </div>
                <div className={styles.date}>{reformatDate(date)}</div>
              </div>
            )}
            {detail.length > 0 && (
              <div className={styles.referralDetails}>
                Detail/number
                <div className={styles.detail}>{detail}</div>
              </div>
            )}
          </>
        ) : (
          <div className={styles.noReferral}>No referral information added</div>
        )}
        <ButtonAlt
          className={detail.length ? styles.referralEdit : styles.noReferralEdit}
          contentClassName={styles.buttonContent}
          size={'medium'}
          variant={'text'}
          onClick={() => setMode(Mode.Edit)}
          icon={'edit'}
        >
          Details
        </ButtonAlt>
      </div>
    </div>
  );
};

export default ReferralInformation;
