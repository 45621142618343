import { useEffect, useState } from 'react';
import { notification } from 'antd';

import { ConsentFormInterface } from 'interfaces/ConsentForm/ConsentFormInterface';
import { CheckBoxConsentListing, SendConsentForm } from '../SendConsentModal';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';

import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import { getConsentFormListing } from 'utils/http/DocumentService/ConsentForm/consentForm';

import { CheckBoxListing } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

const getConsentList = async (token: string) => {
  const GetConsentFormList = (await (await getConsentFormListing(token)).json()) as ConsentFormInterface[];

  return GetConsentFormList.filter((consentObj) => consentObj.isPublished).map(
    ({ _id, name, isDefault, signatureRequired }) => ({
      label: name,
      value: _id,
      isOneSignature: !(signatureRequired.client && signatureRequired.other),
      isDefault
    })
  ) as CheckBoxConsentListing[];
};

export const usePrefillProfileConsentForm = (
  token: string,
  clientProfileList: clientProfilesInterface[],
  notificationSettings: NotificationSettingsInterface
) => {
  const [sendConsentForm, setSendConsentForm] = useState({} as SendConsentForm);
  const [prefillLoading, setPrefillLoading] = useState(true);
  const [consentList, setConsentList] = useState<CheckBoxConsentListing[]>([]);
  const [profileOption, setProfileOption] = useState<CheckBoxListing[]>([]);

  const fetchPrefillProfilesData = async (clientProfileList: clientProfilesInterface[]) => {
    setPrefillLoading(true);

    try {
      const consentOptionList = await getConsentList(token);

      if (!consentOptionList) {
        return;
      }

      setConsentList(consentOptionList);

      const defaultConsentForm = consentOptionList.find((consent) => consent.isDefault);
      const primaryProfile = clientProfileList.find(
        (profile) =>
          profile.isPrimaryContact &&
          ((profile.hasEmail &&
            canClientReceiveEmail({
              communicationPreference: profile.communicationPreference,
              notificationSettings,
              notificationSettingsCategory: 'forms'
            })) ||
            (profile.hasMobileNumber &&
              canClientReceiveSms({
                communicationPreference: profile.communicationPreference,
                notificationSettings,
                notificationSettingsCategory: 'forms'
              })))
      );

      const newProfileList = clientProfileList.map(
        ({
          _id,
          name,
          avatar,
          initials,
          initialsBackgroundColor,
          role,
          firstName,
          lastName,
          communicationPreference,
          hasEmail,
          hasMobileNumber
        }) => {
          const isCommunicationsNotAllowed =
            role !== 'child' &&
            !(
              (canClientReceiveEmail({
                communicationPreference,
                notificationSettings,
                notificationSettingsCategory: 'forms'
              }) &&
                hasEmail) ||
              (canClientReceiveSms({
                communicationPreference,
                notificationSettings,
                notificationSettingsCategory: 'forms'
              }) &&
                hasMobileNumber)
            );

          return {
            profileId: _id,
            name,
            firstName,
            lastName,
            avatar,
            initials,
            initialsBackgroundColor,
            role,
            communicationPreference,
            hasEmail,
            hasMobileNumber,
            consentId: defaultConsentForm ? defaultConsentForm.value : '',
            consentName: defaultConsentForm ? defaultConsentForm.label : '',
            isChecked: clientProfileList.length === 1 ? !isCommunicationsNotAllowed : false,
            isCommunicationsNotAllowed,
            isValid: true,
            receiver:
              role === 'child' && primaryProfile
                ? {
                    _id: primaryProfile._id,
                    name: primaryProfile.name,
                    isValid: true
                  }
                : undefined
          };
        }
      );

      const newSendConsentForm = {
        profileList: newProfileList,
        inviteTime: { sendAt: 'now' },
        note: ''
      };

      setSendConsentForm(newSendConsentForm);

      setProfileOption(
        newProfileList
          .filter((profile) => profile.role !== 'child' && !profile.isCommunicationsNotAllowed)
          .map(({ profileId, firstName, lastName }, index) => ({
            label: `${firstName} ${lastName}`,
            value: profileId,
            isDefault: index === 0
          })) as CheckBoxListing[]
      );
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get your consent list.' });
    }

    setPrefillLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchPrefillProfilesData(clientProfileList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { sendConsentForm, setSendConsentForm, prefillLoading, consentList, profileOption, fetchPrefillProfilesData };
};
