import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { AppointmentSlots } from '../../interfaces/Schedule/Appointment';
import {
  CalendarFilterInterface,
  CalendarHighLightInterface,
  CalendarRoomFilterInterface
} from 'components/v2/CalendarFilter/interfaces';

interface AppointmentsState {
  [key: string]: { [key: string]: AppointmentSlots[] };
}

interface calendarAppointmentListState {
  calendarAppointmentList: AppointmentsState;
  calendarFilter: {
    selectedClinicians: CalendarFilterInterface[];
    selectedRooms: CalendarRoomFilterInterface[];
    selectedHighLights: CalendarHighLightInterface[];
  };
  isLoading: boolean;
}

const initialState: calendarAppointmentListState = {
  calendarAppointmentList: {},
  calendarFilter: {
    selectedClinicians: [],
    selectedRooms: [],
    selectedHighLights: []
  },
  isLoading: false
};

const calendarAppointmentListDataSlice = createSlice({
  name: 'calendarAppointmentList',
  initialState,
  reducers: {
    updateAppointments: (
      state,
      action: PayloadAction<{
        appts: AppointmentSlots[];
        from: string;
        to: string;
        roomIds: string[];
        clinicianIds: string[];
        auth0ClinicianId: string;
      }>
    ) => {
      const { appts, from, to, roomIds, clinicianIds, auth0ClinicianId } = action.payload;

      // Handle Room IDs
      roomIds.forEach((roomId) => {
        const filteredAppts = appts.filter((item) => item.isRoomFilter && item.room?.roomId === roomId);
        state.calendarAppointmentList[roomId] = {
          ...(state.calendarAppointmentList[roomId] || {}),
          [`${from}-${to}`]: filteredAppts
        };
      });

      // Handle Clinician IDs
      let otherClinicianAppts: AppointmentSlots[] = [];
      clinicianIds
        .filter((id) => id !== auth0ClinicianId && id !== '')
        .forEach((clinicianId) => {
          const filteredAppts = appts.filter(
            ({ isRoomFilter, clinicianId: apptClinicianId, groupId }) =>
              !isRoomFilter && (apptClinicianId === clinicianId || (groupId && !apptClinicianId))
          );
          state.calendarAppointmentList[clinicianId] = {
            ...(state.calendarAppointmentList[clinicianId] || {}),
            [`${from}-${to}`]: filteredAppts
          };
          otherClinicianAppts = [...otherClinicianAppts, ...filteredAppts];
        });

      // Handle Auth0 Clinician ID
      if (clinicianIds.includes(auth0ClinicianId) || clinicianIds.includes('')) {
        state.calendarAppointmentList[auth0ClinicianId] = {
          ...(state.calendarAppointmentList[auth0ClinicianId] || {}),
          [`${from}-${to}`]: appts.filter((item) => !item.isRoomFilter && !otherClinicianAppts.includes(item))
        };
      }
    },

    setSelectedClinicians: (state, action: PayloadAction<CalendarFilterInterface[]>) => {
      state.calendarFilter.selectedClinicians = action.payload;
    },

    setSelectedRooms: (state, action: PayloadAction<CalendarRoomFilterInterface[]>) => {
      state.calendarFilter.selectedRooms = action.payload;
    },

    setSelectedHighLights: (state, action: PayloadAction<CalendarHighLightInterface[]>) => {
      state.calendarFilter.selectedHighLights = action.payload;
    }
  }
});

export const calendarAppointmentList = (state: RootState) =>
  state.calendarAppointmentListDataSlice.calendarAppointmentList;
export const calendarFilters = (state: RootState) => state.calendarAppointmentListDataSlice.calendarFilter;

export const { updateAppointments, setSelectedClinicians, setSelectedRooms, setSelectedHighLights } =
  calendarAppointmentListDataSlice.actions;

export default calendarAppointmentListDataSlice.reducer;
