import { QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';
import {
  OpenDataFieldVariable,
  ScaleType
} from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';

export enum OdfEventType {
  ProfileCreated = 'profileCreated',
  ProfileUpdated = 'profileUpdated',
  Referral = 'referral',
  GeneralPractitioner = 'generalPractitioner',
  Medicare = 'medicare',
  SurveyResponded = 'surveyResponded',
  DataUpdated = 'dataUpdated', // update from client data page
  FundingStream = 'fundingStream', // helm partner event,
  CaseNote = 'caseNote', // case note create/update
  ReferralV2 = 'referralV2'
}

export enum ResponseHistoryCreatedByType {
  Client = 'client',
  Clinician = 'clinician',
  System = 'system'
}

export interface ClientDataHistory {
  categoryId: string;
  categoryName: string;
  value: string | string[];
  otherValue?: string;
  source?: {
    type: OdfEventType;
    questionsResponsesId?: string;
  };
  createdAt: Date;
  createdBy: {
    type: ResponseHistoryCreatedByType;
    id: string;
    fallbackName?: string;
    name: string;
  };
  decryptedValue?: string;
  decryptedOtherValue?: string;
}

export interface VariableData {
  variableId: string;
  variableName: string;
  variableType: QuestionType;
  categoryId: string;
  clientProfileId?: string;
  maxSelection?: number;
  options?: OpenDataFieldVariable['options'];
  scaleType?: ScaleType;
  histories: ClientDataHistory[];
}

export interface CategoriesVariables {
  categoryId: string;
  categoryName: string;
  classification: string;
  variables: VariableData[];
}

export interface ClientData {
  clientProfileId: string;
  categories: CategoriesVariables[];
}

export interface SimpleClientData {
  clientProfileId: string;
  variables: Array<{
    variableId: string;
    value: string;
    otherValue?: string;
  }>;
}
