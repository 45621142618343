import styles from './InvoiceTotal.module.scss';
import { config } from 'config/config';

const MONEY_SYMBOL = config.currencySymbol;

interface InvoiceTotalProps {
  totalPayment: string;
  totalCost: string;
  totalTax: string;
}

const InvoiceTotal = ({ totalCost, totalPayment, totalTax }: InvoiceTotalProps) => {
  return (
    <div className={styles.container}>
      <span className={styles.totalLabel}>TOTAL</span>
      <span className={styles.costTotal}>
        {MONEY_SYMBOL}
        {totalCost}
      </span>
      <span className={styles.taxTotal}>
        {MONEY_SYMBOL}
        {totalTax}
      </span>
      <span className={styles.finalTotal}>
        <span className={styles.label}>TO PAY</span>
        {MONEY_SYMBOL}
        {totalPayment}
      </span>
    </div>
  );
};

export default InvoiceTotal;
