import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { useGetPublicPracticeInfoQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import { handleCountryRedirection } from 'utils/handleCountryRedirection';

interface DecodedToken {
  sub: string;
  scope: string;
  exp: number;
  'https://tacklit.com/jwt-claims/clinician-email': string;
}

interface ParsedAccountDetails {
  accountId: string;
  email: string;
  token: string;
}

export const useGetAccountDetailsFromToken = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { practiceSlugUrl = '' } = useParams<{ practiceSlugUrl: string }>();
  const PRACTICE_SLUG_URLS = ['frontline', practiceSlugUrl];

  const [accountDetails, setAccountDetails] = useState<ParsedAccountDetails>({
    accountId: '',
    email: '',
    token: ''
  });

  const [expiredTime, setExpiredTime] = useState<number>(moment().add(2, 'weeks').unix());

  const { data: practiceInfo } = useGetPublicPracticeInfoQuery(
    { accountIdOrSlugUrl: practiceSlugUrl },
    { skip: !PRACTICE_SLUG_URLS.includes(practiceSlugUrl) }
  );

  const redirectToDefaultSignup = () => {
    navigate('/signup');
  };

  const getAccountDetailsFromToken = async () => {
    if (!PRACTICE_SLUG_URLS.includes(practiceSlugUrl)) {
      redirectToDefaultSignup();
    }

    if (practiceInfo) {
      try {
        const { token } = queryString.parse(search);

        if (!token || typeof token !== 'string') {
          redirectToDefaultSignup();
          return;
        }

        handleCountryRedirection(practiceInfo.country);

        const decodedToken = jwt_decode<DecodedToken>(token);
        setExpiredTime(decodedToken.exp);

        if (
          !decodedToken.sub ||
          !decodedToken['https://tacklit.com/jwt-claims/clinician-email'] ||
          decodedToken.scope !== 'write:clinicians'
        ) {
          redirectToDefaultSignup();
          return;
        }

        setAccountDetails({
          accountId: decodedToken.sub,
          email: decodedToken['https://tacklit.com/jwt-claims/clinician-email'],
          token
        });
      } catch (ex) {
        console.error(ex);
        redirectToDefaultSignup();
      }
    }
  };

  useEffect(() => {
    getAccountDetailsFromToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceInfo]);

  return { accountDetails, expiredTime };
};
