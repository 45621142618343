import { createSlice } from '@reduxjs/toolkit';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import {
  ADDRESS_BOOK_LIST_SORT_INFO,
  ColumnSettingsEnum,
  defaultAddressBookFilters,
  SortType
} from 'pages/AddressBook/AddressBookList/components/AddressBookListContent/components/constants';
import { AddressBookRole, AddressBookType } from 'pages/AddressBook/Interfaces/AddressBook';
import { RootState } from 'redux/store';

export enum AddressBookTab {
  Funder = AddressBookRole.Funder,
  Referrer = AddressBookRole.Referrer,
  Organisation = AddressBookType.Organisation,
  Individual = AddressBookType.Individual
}

export interface AddressBookListSorting {
  sortBy: string;
  sortType: SortType;
}
export interface AddressBookListPaging {
  page: number;
  perPage: number;
}
export interface AddressBookListFilters {
  selectedRoles: FilterCheckListItem[];
  selectedCategories: FilterCheckListItem[];
  selectedTypes: FilterCheckListItem[];
}

export interface AddressBookListSearch {
  searchValue: string;
}

interface AddressBookState {
  search: AddressBookListSearch;
  sorting: AddressBookListSorting;
  paging: AddressBookListPaging;
  filters: AddressBookListFilters;
  activeTab?: AddressBookTab;
}

const defaultSorting = {
  sortBy: ColumnSettingsEnum.Name,
  sortType: SortType.ASC
};

const initialState: AddressBookState = {
  search: {
    searchValue: ''
  },
  sorting: localStorage.getItem(ADDRESS_BOOK_LIST_SORT_INFO)
    ? JSON.parse(localStorage.getItem(ADDRESS_BOOK_LIST_SORT_INFO) || JSON.stringify(defaultSorting))
    : defaultSorting,
  paging: {
    page: 1,
    perPage: 20
  },
  filters: {
    selectedRoles: [{ _id: AddressBookRole.Funder, name: 'Funder' }],
    selectedCategories: [],
    selectedTypes: []
  },
  activeTab: AddressBookTab.Funder
};

export const addressBookSlice = createSlice({
  name: 'addressBookSlice',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },

    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    setPaging: (state, action) => {
      state.paging = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    },

    resetFilters: (state) => {
      state.filters = {
        ...initialState.filters,
        ...defaultAddressBookFilters[state.activeTab || AddressBookTab.Funder]
      };
    },

    resetSorting: (state) => {
      state.sorting = initialState.sorting;
    },

    resetSearch: (state) => {
      state.search = initialState.search;
    },

    resetAll: (state) => {
      state.search = initialState.search;
      state.paging = initialState.paging;
      state.sorting = initialState.sorting;
      state.filters = initialState.filters;
    },

    resetPagingAndSorting: (state) => {
      state.paging = initialState.paging;
      state.sorting = initialState.sorting;
    }
  }
});

export const selectSearch = (state: RootState) => state.addressBookSlice.search;
export const selectSorting = (state: RootState) => state.addressBookSlice.sorting;
export const selectPaging = (state: RootState) => state.addressBookSlice.paging;
export const selectFilters = (state: RootState) => state.addressBookSlice.filters;
export const selectActiveTab = (state: RootState) => state.addressBookSlice.activeTab;

export default addressBookSlice.reducer;

export const {
  setSearch,
  setSorting,
  setPaging,
  setFilters,
  setActiveTab,
  resetPaging,
  resetFilters,
  resetSearch,
  resetSorting,
  resetAll,
  resetPagingAndSorting
} = addressBookSlice.actions;
