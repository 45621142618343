import styles from './TasksHeader.module.scss';
import columnStyles from './TasksColumn.module.scss';
import ListHeaderItem from 'components/T23/ListHeaderItem/ListHeaderItem';
import { TasksListColumns } from './columns';
import { getColumnNameFromId } from 'components/T23/ListHeaderItem/helper';
import { Sorting } from 'redux/features/utils';

interface TaskHeaderProps {
  sorting: Sorting;
  onClickSort: (newSortBy: string) => void;
}

const TasksHeader = ({ sorting, onClickSort }: TaskHeaderProps) => {
  const { sortBy, sortType } = sorting;

  return (
    <div className={styles.container}>
      {TasksListColumns.map((column, index) => (
        <ListHeaderItem
          key={index}
          columnId={column.id}
          className={columnStyles[column.id]}
          sortBy={sortBy}
          sortType={sortType}
          columnName={getColumnNameFromId(column.id)}
          sortAble={column.sortAble}
          onSort={onClickSort}
        />
      ))}
      <div className={columnStyles.actions} />
    </div>
  );
};

export default TasksHeader;
