/* eslint-disable react/no-multi-comp */
import { useEffect } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useInitPathGenerator } from 'utils/hooks/Path/pathGenerator';
import Loading from 'components/Loading/Loading';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';

const Login = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const initPath = useInitPathGenerator();
  const { isMentorUser } = useGetAccountPackage();

  const isFirstLogin = user?.['https://tacklit.com/loginsCount'];
  const stonlyParameter = isFirstLogin <= 1 ? '?welcome=1' : '';

  useEffect(() => {
    if (user?.['https://tacklit.com/roles'].includes('patient') && process.env.REACT_APP_CLIENT_DOMAIN) {
      window.location.href = process.env.REACT_APP_CLIENT_DOMAIN;
    } else if (isMentorUser) {
      navigate(`${initPath}/clients${stonlyParameter}`);
    } else {
      navigate(`${initPath}/client${stonlyParameter}`);
    }
  }, [user, navigate, initPath, isMentorUser, stonlyParameter]);

  return <Loading />;
};

export default withAuthenticationRequired(Login, {
  onRedirecting: () => <Loading />
});
