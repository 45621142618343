import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL!;

// Group Case note
export const getGroupCaseNotesByGroupId = (token: string, groupId: string, withContents?: boolean) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes${withContents ? '?withContents=1' : ''}`
  ) as Promise<BentResponse>;

export const getGroupCaseNoteByCaseNoteId = (token: string, groupId: string, caseNoteId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes/${caseNoteId}`
  ) as Promise<BentResponse>;

export const postGroupCaseNote = (token: string, groupId: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes`,
    payload
  ) as Promise<BentResponse>;

export const putGroupCaseNote = (token: string, groupId: string, caseNoteId: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes/${caseNoteId}`,
    payload
  ) as Promise<BentResponse>;

export const deleteGroupCaseNote = (token: string, groupId: string, caseNoteId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/groups/${groupId}/case-notes/${caseNoteId}`
  ) as Promise<BentResponse>;
