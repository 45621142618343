import { useMemo, useState } from 'react';
import styles from './CollectData.module.scss';
import DataCategory from './components/DataCategory/DataCategory';
import { useFetchOpenDataCategories } from './hooks/useFetchOpenDataCategories';
import { useGetAccessToken } from 'utils/hooks/token';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { OpenDataCategory } from './CollectData.interface';
import { useFetchCommonODFVariable } from './hooks/useFetchCommonODFVariable';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { reorderCategories } from 'utils/http/CheckInService/OpenDataField/openDataField';
import { notification } from 'antd';
import DataCategoryVirtual from './components/DataCategory/components/DataCategoryVirtual/DataCategoryVirtual';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

const CollectData = () => {
  const [editingCategory, setEditingCategory] = useState<string>('');
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { commonVariables, scaleTypes, isCommonVariablesLoading } = useFetchCommonODFVariable();
  const { openDataCategories, isOpenDataCategoriesLoading, fetchOpenDataCategories, setOpenDataCategories } =
    useFetchOpenDataCategories(token, accountId);

  const onAddNewCategory = () => {
    fetchOpenDataCategories();
    setEditingCategory('');
  };
  const onUpdate = () => {
    fetchOpenDataCategories(); //TODO, update categories directly
    setEditingCategory('');
  };
  const onDelete = (v: OpenDataCategory) => {
    setOpenDataCategories([...openDataCategories.filter((i) => i._id !== v._id)]);
    setEditingCategory('');
  };

  const reorder = (list: OpenDataCategory[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const debouncedReorderCategories = useMemo(
    () =>
      debounce(async (itemIds: string[]) => {
        try {
          const result = await reorderCategories(token, accountId, { categoriesOrder: itemIds });
          if (result) {
            notification.success({
              message: 'Category order is successfully updated.',
              duration: 2,
              closeIcon: <span className="success">OK</span>
            });
          }
        } catch (ex) {
          notification.warn({
            message: 'Something went wrong. Order is not updated. Please try again.',
            duration: 2,
            closeIcon: <span className="notify">OK</span>
          });
        }
      }, 2000),
    [token, accountId]
  );

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.source.index !== result.destination.index) {
      const ordered = reorder(openDataCategories, result.source.index, result.destination.index).map(
        (category, index) => ({ ...category, order: index })
      );
      setOpenDataCategories([...ordered]);
      await debouncedReorderCategories(ordered.map((category) => category._id!));
    }
  };

  return isOpenDataCategoriesLoading ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>Set and edit your Client Data Fields</div>
          <div className={styles.description}>
            Define each variable here, to collect information from clients directly or by updating their client profile.
            <br />
            Define the question and answer structure for these variables, for your service.
          </div>
        </div>
        <div className={styles.right}>
          <ButtonAlt
            onClick={() => setEditingCategory('new')}
            id={'addNewCategory'}
            disabled={editingCategory !== ''}
            icon={'add_circle_outline'}
          >
            Add New Category
          </ButtonAlt>
        </div>
      </div>

      <div className={styles.categories}>
        {editingCategory === 'new' && (
          <div className={styles.addNewCategory}>
            <DataCategory
              isAddNew
              onDelete={() => setEditingCategory('')}
              onSubmit={onAddNewCategory}
              editingCategory={editingCategory}
              setEditingCategory={setEditingCategory}
              commonVariables={commonVariables}
              scaleTypes={scaleTypes}
              isCommonVariablesLoading={isCommonVariablesLoading}
            />
          </div>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            mode={'virtual'}
            renderClone={(provided, snapshot, rubric) => (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                style={provided.draggableProps.style}
              >
                <DataCategoryVirtual openDataCategories={openDataCategories} rubric={rubric} />
              </div>
            )}
            droppableId={'droppable'}
            type={'CATEGORY'}
            isDropDisabled={editingCategory !== ''}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className={classNames(snapshot.isDraggingOver && styles.draggingList)}>
                {openDataCategories
                  .sort((a, b) => (a.order || 0) - (b.order || 0))
                  .map((category, index) => (
                    <Draggable
                      key={`${category._id || 'new'}${index}`}
                      draggableId={`${category._id || 'new'}${index}`}
                      index={index}
                      isDragDisabled={editingCategory !== ''}
                    >
                      {(provided) => (
                        <div
                          className={styles.categoryItemWrapper}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <DataCategory
                            key={category._id}
                            category={category}
                            onSubmit={onUpdate}
                            onDelete={onDelete}
                            editingCategory={editingCategory}
                            setEditingCategory={setEditingCategory}
                            commonVariables={commonVariables}
                            scaleTypes={scaleTypes}
                            isCommonVariablesLoading={isCommonVariablesLoading}
                            canReorder={openDataCategories.length > 1}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default CollectData;
