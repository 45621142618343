import { notification } from 'antd';
import { Pagination, SortOrder } from 'interfaces';
import { ParticipantType as ScheduleParticipantType } from 'interfaces/Schedule/AppointmentType';
import _ from 'lodash';
import { ParticipantType } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import { Invoice } from 'pages/Invoices/interface';
import { useEffect, useState } from 'react';
import { getInvoices } from 'utils/http/BillingService/Invoice/invoice';
import { useGetAccountInfo } from './GetAccountInfo/getAccountInfo';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentEpisodeId, selectIsOutsideAllEpisodes, selectIsShowAllData } from 'redux/episodes/episodeSlice';
import { security } from 'utils/security';

const perPage = 10;

export enum StatusFilterType {
  All = 'all',
  Closed = 'closed',
  ConfirmPaid = 'confirmPaid',
  Draft = 'draft',
  Issued = 'issued',
  MarkedPaid = 'markedPaid',
  Overdue = 'overdue',
  Voided = 'voided',
  WriteOff = 'writeOff',
  PendingClaim = 'pendingClaim',
  ClaimComplete = 'claimComplete',
  ClaimSuccess = 'claimSuccess',
  ClaimRejected = 'claimRejected',
  FullyRefunded = 'fullyRefunded'
}

interface FetchInvoicesOptions {
  clientRecordId?: string;
  ignorePagination?: boolean;
}

export const useFetchInvoices = ({ clientRecordId, ignorePagination }: FetchInvoicesOptions = {}) => {
  const { clinicianProfile } = useGetAccountInfo();

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isInvoicesLoading, setIsInvoicesLoading] = useState(true);

  const [dateSort, setDateSort] = useState<SortOrder>(SortOrder.Descending);
  const [participationType, setParticipationType] = useState<ScheduleParticipantType>(ScheduleParticipantType.OneToOne);
  const [statusFilter, setStatusFilter] = useState<StatusFilterType>(StatusFilterType.All);

  const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);

  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    perPage,
    totalItem: 0,
    totalPage: 0
  });
  const [hasMoreInvoices, setHasMoreInvoices] = useState(false);

  const fetchInvoices = async ({ page }: { page: number }) => {
    try {
      const token = await security.getAccessTokenSilently();

      if (!clinicianProfile) {
        throw new Error('No clinician profile found.');
      }

      const { invoices, paging } = await getInvoices({
        accountId: clinicianProfile.accountId,
        token,
        query: {
          ...(!ignorePagination && { page, perPage }),
          ...(statusFilter !== StatusFilterType.All && { status: statusFilter }),
          clientRecordId,
          sortByIssueDate: dateSort === SortOrder.Descending ? -1 : 1,
          type:
            participationType === ScheduleParticipantType.OneToOne ? ParticipantType.Individual : ParticipantType.Group,
          ...(!isShowAllData && {
            ...(currentEpisodeId && !isOutsideAllEpisodes && { episodeId: currentEpisodeId }),
            ...(isOutsideAllEpisodes && { showOutsideOfEpisode: true })
          })
        }
      });

      if (!invoices || !Array.isArray(invoices)) {
        throw new Error('Returned invoices are missing or are not an array.');
      }

      setHasMoreInvoices(paging.totalItems / perPage > page);
      setPagination({ ...pagination, page: paging.page + 1, totalItem: paging.totalItems });

      return invoices;
    } catch (ex) {
      console.log(ex);
      notification.error({ message: 'Something went wrong while trying to get your invoices.' });
      return [];
    }
  };

  const fetchAndReplaceInvoices = async () => {
    setIsInvoicesLoading(true);

    const invoices = await fetchInvoices({ page: 1 });
    setInvoices(invoices);

    setIsInvoicesLoading(false);
  };

  useEffect(() => {
    setPagination((pagination) => ({ ...pagination, page: 1 }));
    fetchAndReplaceInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSort, statusFilter, participationType, currentEpisodeId, isOutsideAllEpisodes, isShowAllData]);

  const fetchMoreInvoices = _.debounce(async () => {
    if (!isInvoicesLoading) {
      const invoices = await fetchInvoices({ page: pagination.page });
      setInvoices((prev) => [...prev, ...invoices]);
    }
  }, 1000);

  return {
    dateSort,
    fetchMoreInvoices,
    hasMoreInvoices,
    invoices,
    isInvoicesLoading,
    participationType,
    statusFilter,
    setDateSort,
    setInvoices,
    setParticipationType,
    setStatusFilter,
    refetch: fetchAndReplaceInvoices
  };
};
