import { useMemo, useState } from 'react';
import styles from './ReportTemplateWrapper.module.scss';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import ReportTemplateCard from './components/ReportTemplateCard/ReportTemplateCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { Modal, notification } from 'antd';
import { deleteReportTemplate } from 'utils/http/DocumentService/Reports/reportsTemplate';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import noTemplateImg from 'assets/images/noTemplate.png';
import { GenerateTemplateLoadingInterface } from '../../EditTab';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ReportTemplate, ReportTemplateStatus } from 'interfaces/Reports/reportTemplate';
import { useAppDispatch } from 'redux/hooks';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';

interface ReportTemplateWrapperProps {
  isLoading: boolean;
  reportTemplateData: ReportTemplate[];
  generateTemplateLoading: GenerateTemplateLoadingInterface;
  onSelectReportTemplate: (selectedReportTemplate: ReportTemplate) => void;
  refetchReportTemplate: () => void;
  isChangingTemplateDisabled: boolean;
}

const ReportTemplateWrapper = ({
  isLoading,
  reportTemplateData,
  generateTemplateLoading,
  onSelectReportTemplate,
  refetchReportTemplate,
  isChangingTemplateDisabled
}: ReportTemplateWrapperProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const path = useParams() as { reportId: string };
  const { token } = useGetAccessToken();
  const { isEdgeAdminUser, isEdgeReceptionist } = useGetAccountPackage();
  const { FORMS } = useRoutesGenerator();
  const { auth0ClinicianId } = useGetClinicianId();
  const [showManageSettings, setShowManageSettings] = useState(false);

  const onClickManageBtn = () => {
    setShowManageSettings(!showManageSettings);
  };

  const onHandleDeleteTemplate = (templateId: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this template?',
      icon: <span className={`material-icons-outlined ${styles.deleteReportIcon}`}>report</span>,
      okText: 'Confirm',
      onOk: () => onDeleteTemplate(templateId)
    });
  };

  const onHandleEditTemplate = (templateId: string) => {
    Modal.confirm({
      title: 'Are you sure you want to edit this template?',
      icon: '',
      content: (
        <div>
          <br />
          <div>
            Please note that the previous report that used this template will not update to the latest version of the
            template after you edit it. Editing template will only apply for the future report that uses this template.
          </div>
        </div>
      ),
      okText: 'Confirm',
      onOk: () => onEditTemplate(templateId)
    });
  };

  const handleNewTemplate = () => {
    Modal.confirm({
      title: 'We will navigate you to Template Builder page.',
      icon: '',
      content: (
        <div>
          <br />
          <div>Please save your report before you create a new template.</div>
        </div>
      ),
      okText: 'Confirm',
      onOk: () => onClickNewTemplate()
    });
  };

  const onDeleteTemplate = async (templateId: string) => {
    try {
      await deleteReportTemplate(token, templateId);
      dispatch(documentServicesApiSlice.util.invalidateTags(['Report Templates']));
      notification.success({
        message: 'Report template has been deleted.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      refetchReportTemplate();
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to delete this template' });
    }
  };

  const onEditTemplate = (templateId: string) => {
    localStorage.setItem('reportPrevPath', path.reportId);
    navigate(`${FORMS.REPORT_TEMPLATE}/${templateId}?backToReport=true`);
  };

  const onClickNewTemplate = () => {
    localStorage.setItem('reportPrevPath', path.reportId);
    navigate(`${FORMS.NEW_REPORT_TEMPLATE}?backToReport=true`);
  };

  const onHandleSelectTemplate = async (templateData: ReportTemplate) => {
    onSelectReportTemplate(templateData);
  };

  const reportTemplateListData = useMemo(() => {
    if (showManageSettings) {
      if (isEdgeAdminUser) {
        return reportTemplateData;
      } else if (isEdgeReceptionist) {
        return reportTemplateData.filter((template) => template.isAuthoredByReceptionist);
      }
      return reportTemplateData.filter((template) => template.createdBy?._id === auth0ClinicianId);
    } else if (isEdgeReceptionist) {
      return reportTemplateData.filter(
        (template) => !(!template.isAuthoredByReceptionist && template.status === ReportTemplateStatus.Draft)
      );
    }
    return reportTemplateData;
  }, [auth0ClinicianId, isEdgeAdminUser, isEdgeReceptionist, reportTemplateData, showManageSettings]);

  return isLoading ? (
    <div className={styles.loadingWrapper}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>{showManageSettings ? 'Manage Template' : 'Insert a template'}</div>
        {reportTemplateData.length > 0 && (
          <>
            {showManageSettings ? (
              <ButtonAlt variant={'text'} size={'medium'} onClick={() => onClickManageBtn()} icon={'exit_to_app'}>
                Exit Manage
              </ButtonAlt>
            ) : (
              <ButtonAlt variant={'text'} size={'medium'} onClick={() => onClickManageBtn()} icon={'settings'}>
                Manage
              </ButtonAlt>
            )}
          </>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {reportTemplateListData.length > 0 ? (
          reportTemplateListData.map((reportTemplateItem, index) => (
            <ReportTemplateCard
              key={index}
              reportTemplateData={reportTemplateItem}
              showSettings={showManageSettings}
              onClickSelectTemplate={onHandleSelectTemplate}
              onClickEditTemplate={onHandleEditTemplate}
              onClickDeleteTemplate={onHandleDeleteTemplate}
              generateTemplateLoading={generateTemplateLoading}
              isChangingTemplateDisabled={isChangingTemplateDisabled}
            />
          ))
        ) : (
          <div className={styles.noTemplateContainer}>
            <img src={noTemplateImg} className={styles.noTemplateImg} alt={'no template'} />
            <div className={styles.noTemplateDetails}>
              <div className={styles.label}>No template created</div>
              <ButtonAlt variant={'text'} size={'medium'} onClick={handleNewTemplate} icon={'add'}>
                Add new template
              </ButtonAlt>
            </div>
          </div>
        )}
        {reportTemplateListData.length > 0 && showManageSettings && (
          <ButtonAlt
            className={styles.newTemplateBtn}
            variant={'text'}
            size={'large'}
            onClick={handleNewTemplate}
            icon={'add'}
          >
            Add new template
          </ButtonAlt>
        )}
      </div>
    </div>
  );
};

export default ReportTemplateWrapper;
