import styles from './InvoiceSubmittedClaims.module.scss';
import ContentLayout from 'components/ContentLayoutT23/ContentLayoutT23';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import SubmittedClaimList from './components/SubmittedClaimList/SubmittedClaimList';
import { useLocation } from 'react-router-dom';
import { selectActiveTab, setActiveTab, SubmittedClaimTabs } from 'redux/features/submittedClaims/submittedClaimSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useCallback, useEffect } from 'react';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

const InvoiceSubmittedClaims = () => {
  const location = useLocation();
  const activeTab = useAppSelector(selectActiveTab);
  const dispatch = useAppDispatch();
  const { INVOICES } = useRoutesGenerator();

  const mapURLToClaimTab = useCallback(
    (path: string) => {
      switch (path) {
        case INVOICES.SUCCESS_CLAIMS: {
          return SubmittedClaimTabs.Success;
        }
        case INVOICES.REJECTED_CLAIMS: {
          return SubmittedClaimTabs.Rejected;
        }
        case INVOICES.WRITE_OFF_CLAIMS: {
          return SubmittedClaimTabs.WriteOff;
        }
        case INVOICES.ABANDONED_CLAIMS: {
          return SubmittedClaimTabs.Abandoned;
        }
        case INVOICES.SUBMITTED_CLAIMS:
        default: {
          return SubmittedClaimTabs.Submitted;
        }
      }
    },
    [INVOICES]
  );

  useEffect(() => {
    const tabByURL = mapURLToClaimTab(location.pathname);
    if (tabByURL !== activeTab) {
      dispatch(setActiveTab(tabByURL));
    }
  }, [location.pathname, activeTab, mapURLToClaimTab, dispatch]);

  return (
    <HelmetWrapper title={'Tacklit - Submitted claims'}>
      <ContentLayout className={styles.container}>
        <SubmittedClaimList />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InvoiceSubmittedClaims;
