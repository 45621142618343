import { MouseEvent, MouseEventHandler, ReactNode, useState } from 'react';
import styles from './ListWithShowMore.module.scss';

interface ListWithShowMoreProps {
  list: { _id: string; name: string; onClick?: MouseEventHandler }[];
  initialLength?: number;
  afterTextDisplay?: (itemId: string) => ReactNode;
  itemClassName?: string;
}

const ListWithShowMore = ({ list, initialLength = 3, afterTextDisplay, itemClassName }: ListWithShowMoreProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const totalItem = list.length;

  const handleShowMore = (event: MouseEvent, showValue: boolean) => {
    event.stopPropagation();
    setShowAll(showValue);
  };

  return (
    <div className={styles.container}>
      {totalItem > initialLength && !showAll ? (
        <>
          {list.slice(0, initialLength).map((item) => (
            <div className={styles.contentContainer} key={item._id}>
              <div
                onClick={item.onClick}
                title={item.name}
                className={itemClassName ? styles[itemClassName] : styles.itemLink}
              >
                {item.name}
              </div>
              {afterTextDisplay?.(item._id)}
            </div>
          ))}
          <button onClick={(event) => handleShowMore(event, true)}>{`+ ${totalItem - initialLength} more`}</button>
        </>
      ) : (
        <>
          {list.length > 0
            ? list.map((item) => (
                <div className={styles.contentContainer} key={item._id}>
                  <div
                    onClick={item.onClick}
                    title={item.name}
                    className={itemClassName ? styles[itemClassName] : styles.itemLink}
                  >
                    {item.name}
                  </div>
                  {afterTextDisplay?.(item._id)}
                </div>
              ))
            : '-'}
          {totalItem > initialLength && <button onClick={(event) => handleShowMore(event, false)}>Show less</button>}
        </>
      )}
    </div>
  );
};

export default ListWithShowMore;
