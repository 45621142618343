import styles from './ScaleTypes.module.scss';
import { OpenDataFieldVariable, ScaleType, ScaleTypeLabel } from '../../../../CollectData.interface';
import Select from 'components/v2/Select/Select';
import { useEffect, useState } from 'react';
import Button from 'components/v2/Button/Button';
import HexSlider from 'components/QuestionTypes/HexSlider/HexSlider';
import hexSliderData from 'components/QuestionTypes/HexSlider/HexSliderData.json';
import { QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';
import Radio from 'components/Radio/Radio';
import EmojiScalePreview from '../../../../../../../../../../../../../components/QuestionTypes/EmojiScalePreview/EmojiScalePreview';
import classNames from 'classnames';

interface ScaleTypesProps {
  variable: Partial<OpenDataFieldVariable>;
  scaleQuestions: Partial<OpenDataFieldVariable>[];
  allowEdit: boolean;
  setVariable: (v: Partial<OpenDataFieldVariable>) => void;
}

const ScaleTypes = ({ variable, scaleQuestions, allowEdit, setVariable }: ScaleTypesProps) => {
  useEffect(() => {
    if (!variable.scaleType) {
      setVariable({ ...variable, scaleType: ScaleType._1_5 });
    }
  }, [variable, setVariable]);

  const [showPreview, setShowPreview] = useState(false);

  const questions = scaleQuestions.map((item) => ({
    label: item.scaleType ? ScaleTypeLabel[item.scaleType] : '',
    value: item.scaleType || '',
    option: item.options
  }));

  const scaleOptions = scaleQuestions.find((item) => item.scaleType === variable.scaleType)?.options;

  return (
    <div className={styles.container}>
      <div className={styles.options}>
        <div className={styles.select}>
          {allowEdit ? (
            <Select
              options={questions}
              value={questions.find((i) => variable.scaleType === i.value)}
              valueStyle={{ padding: '4px 0' }}
              onChange={(v: any) =>
                v &&
                setVariable({
                  ...variable,
                  scaleType: v.value as ScaleType
                })
              }
            />
          ) : (
            <div>
              <div className={styles.label}>Scale format</div>
              {questions.find((i) => variable.scaleType === i.value)?.label}
            </div>
          )}
        </div>
        <Button
          icon="visibility"
          className={styles.previewButton}
          variant="link"
          onClick={() => setShowPreview(!showPreview)}
        >
          Format Preview
        </Button>
      </div>
      {showPreview && variable.scaleType && (
        <div className={styles.formatPreview}>
          <div className={styles.label}>FORMAT PREVIEW</div>
          <div className={styles.content}>
            <div
              className={classNames(
                styles.component,
                variable.scaleType === ScaleType.EmojiReaction && styles.emojiReaction,
                variable.scaleType === ScaleType.EmojiRating && styles.emojiRating
              )}
            >
              {variable.scaleType === ScaleType.EmojiReaction && (
                <div className={styles.previewDescription}>One tap for "a little", two taps for "a lot"</div>
              )}
              {(variable.scaleType === ScaleType._1_5 || variable.scaleType === ScaleType._1_10) && (
                <HexSlider
                  question={{
                    id: 'new',
                    scales: hexSliderData.scales.filter(
                      (i) => i.value < (variable.scaleType === ScaleType._1_5 ? 6 : 11)
                    ),
                    stem: '',
                    questionType: QuestionType.scale
                  }}
                  value={variable.scaleType === ScaleType._1_5 ? 3 : 5}
                  onChange={() => {}}
                />
              )}
              {(variable.scaleType === ScaleType.AgreeDisagree ||
                variable.scaleType === ScaleType.Frequency ||
                variable.scaleType === ScaleType.GoodBadRating ||
                variable.scaleType === ScaleType.ImpactOfChange ||
                variable.scaleType === ScaleType.Likelihood ||
                variable.scaleType === ScaleType.Priority ||
                variable.scaleType === ScaleType.Recency ||
                variable.scaleType === ScaleType.Satisfaction) && (
                <Radio
                  options={
                    scaleOptions?.map((option: any) => ({ value: option.value, label: option.description })) || []
                  }
                  variant="button"
                  name={variable.scaleType}
                  value={scaleOptions?.[2]?.value}
                  labelClassName={styles.radio}
                  vertical
                  disabled
                />
              )}
              {(variable.scaleType === ScaleType.EmojiRating || variable.scaleType === ScaleType.EmojiReaction) && (
                <EmojiScalePreview
                  options={scaleQuestions.find((item) => item.scaleType === variable.scaleType)?.options || []}
                  scaleType={variable.scaleType}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScaleTypes;
