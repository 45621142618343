import { useState, useEffect, useCallback } from 'react';
import { notification } from 'antd';
import { getReportTemplateList } from '../../http/DocumentService/Reports/reportsTemplate';
import { useGetAccountPackageView } from '../GetAccountInfo/accountPackageView';
import { ReportTemplate } from 'interfaces/Reports/reportTemplate';

export const useFetchReportTemplateList = (token: string) => {
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const [reportTemplateList, setReportTemplateList] = useState<ReportTemplate[]>([]);
  const [isTemplateListLoading, setIsTemplateListLoading] = useState(true);

  const fetchReportTemplateList = useCallback(async () => {
    setIsTemplateListLoading(true);
    const isAdmin = isEdgeAdminView || isEdgeReceptionistView;
    try {
      const getReportTemplateListResponses = await getReportTemplateList(token, isAdmin);
      setReportTemplateList(getReportTemplateListResponses);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get report template list' });
    }

    setIsTemplateListLoading(false);
  }, [token, isEdgeAdminView, isEdgeReceptionistView]);

  useEffect(() => {
    if (token) {
      fetchReportTemplateList();
    }
  }, [token, fetchReportTemplateList]);

  return { reportTemplateList, isTemplateListLoading, fetchReportTemplateList };
};
