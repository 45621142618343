import { ProfileInterface } from 'interfaces/Profile/Profile';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import ControlPanelContentDisplay from './components/ControlPanelContentDisplay/ControlPanelContentDisplay';
import styles from './ControlPanelContent.module.scss';

interface ControlPanelContentProps {
  profile?: ProfileInterface;
  controlPanelTab?: string;
  onUpdateProfile: (newProfile: ProfileInterface) => void;
  fetchProfile?: () => void;
  isMobileOptional?: boolean;
}

const ControlPanelContent = ({
  profile,
  controlPanelTab,
  onUpdateProfile,
  fetchProfile,
  isMobileOptional
}: ControlPanelContentProps) => {
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView, isEdgeReceptionist } = useGetAccountPackageView();
  const {
    isHelmFeatureToggle,
    isSomeoneHealthFeatureToggle,
    medicareRebateFeatureToggle,
    isCaWFeatureToggle,
    isEngagePublicProfileEnabled,
    isRechargeWellnessFeatureToggle,
    isSelectPsychologyFeatureToggle,
    isPractitionerReportEnabled
  } = useGetFeatureToggle();

  return (
    <div className={styles.container}>
      <ControlPanelContentDisplay
        profile={profile}
        controlPanelTab={controlPanelTab}
        onUpdateProfile={onUpdateProfile}
        aboutYouPage={isEdgeAdminView || isEdgeUserView || isNormalUserView || isEdgeReceptionist}
        helmControlPage={
          (isEdgeAdminView || isEdgeReceptionist || isEdgeUserView) &&
          (isHelmFeatureToggle ||
            isSomeoneHealthFeatureToggle ||
            isCaWFeatureToggle ||
            isEngagePublicProfileEnabled ||
            isRechargeWellnessFeatureToggle ||
            isSelectPsychologyFeatureToggle)
        }
        collectDataPage={!isEdgeUserView}
        medicarePage={
          medicareRebateFeatureToggle && (isEdgeAdminView || isEdgeReceptionist || isEdgeUserView || isNormalUserView)
        }
        workingSchedulePage={isEdgeAdminView || isEdgeReceptionist || isEdgeUserView}
        practiceProfilePage={isEdgeAdminView || isNormalUserView}
        systemConnectionPage={isEdgeAdminView || isEdgeUserView || isNormalUserView}
        notificationSettingsPage={isEdgeAdminView || isNormalUserView}
        generalSettingsPage={isEdgeAdminView}
        practitionerReportPage={isPractitionerReportEnabled}
        fetchProfile={fetchProfile}
        isMobileOptional={isMobileOptional}
      />
    </div>
  );
};

export default ControlPanelContent;
