import classNames from 'classnames';
import {
  PAYMENT_TYPE_LABELS,
  getPaymentMethodDescription
} from 'pages/Invoices/components/PaymentMethods/PaymentMethods';
import { PaymentMethod } from 'pages/Invoices/interface';
import { ChangeEvent, useMemo } from 'react';

import styles from './PaymentMethodItem.module.scss';

interface PaymentMethodItemProps {
  checked: boolean;
  index: number;
  paymentMethod: PaymentMethod;
  onAddPaymentMethod: () => void;
  onRemovePaymentMethod: () => void;
}

const PaymentMethodItem = ({
  checked,
  index,
  paymentMethod,
  onAddPaymentMethod,
  onRemovePaymentMethod
}: PaymentMethodItemProps) => {
  const { label, description, isDisabled } = useMemo(() => {
    const { accountName, email, instructions, paymentType, stripeConnectedBank, stripeStatus } = paymentMethod;

    const label = PAYMENT_TYPE_LABELS[paymentType];

    const description = getPaymentMethodDescription({
      accountName,
      email,
      instructions,
      paymentType,
      stripeConnectedBank
    });

    const isDisabled = paymentType === 'stripe' && stripeStatus !== 'completed';

    return { label, description, isDisabled };
  }, [paymentMethod]);

  const handlePaymentMethodSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onAddPaymentMethod();
    } else {
      onRemovePaymentMethod();
    }
  };

  return (
    <div className={styles.container}>
      <label
        className={classNames(styles.checkboxContainer, isDisabled && styles.disabledCheckboxContainer)}
        htmlFor={`checkbox-paymentMethods-${index}`}
      >
        <input
          className={styles.checkbox}
          id={`checkbox-paymentMethods-${index}`}
          type="checkbox"
          value={index}
          checked={checked}
          disabled={isDisabled}
          onChange={handlePaymentMethodSelect}
        />
        <div className={styles.text}>
          <div className={styles.label}>{label}</div>
          {description && <div className={styles.description}>{description}</div>}
        </div>
      </label>
    </div>
  );
};

export default PaymentMethodItem;
