import moment from 'moment';
import styles from './PractitionerSchedule.module.scss';
import classnames from 'classnames';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import DayComponent from './DayComponent';

interface PractitionerScheduleProps {
  id: number;
  workingSchedule: PractitionersDetailsInterface['workingSchedule'];
}

const daysOfTheWeek: ('monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday')[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

const PractitionerSchedule = ({ id, workingSchedule }: PractitionerScheduleProps) => {
  const getPillClass = (isActive: boolean, weekday: number) => {
    const activeStyles = isActive ? styles.pill : styles.pillDisable;
    const todayDate = moment().weekday();
    const todayStyles = todayDate === weekday ? styles.today : null;
    return classnames(activeStyles, todayStyles);
  };

  return (
    <div className={styles.container}>
      {daysOfTheWeek.map((day, index) => (
        <DayComponent
          key={`${day}-${id}`}
          id={`${id}-${index}`}
          className={getPillClass(workingSchedule[day]?.isActive, index + 1)}
          day={day.slice(0, 3)}
          schedule={workingSchedule[day]}
        />
      ))}
    </div>
  );
};

export default PractitionerSchedule;
