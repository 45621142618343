import { useEffect, useState } from 'react';
import { getSignature } from 'utils/http/DocumentService/Reports/reports';
import { notification } from 'antd';
import { Signature } from 'interfaces/documentService/Signature';

export const useFetchSignature = (token: string) => {
  const [signature, setSignature] = useState<Signature>();
  const [isSignatureLoading, setIsSignatureLoading] = useState(true);

  const fetchSignature = async (token: string) => {
    try {
      const signature = await (await getSignature(token)).json();

      setSignature(signature);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to fetch your signature' });
    }

    setIsSignatureLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchSignature(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { signature, isSignatureLoading };
};
