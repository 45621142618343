import { ClientDetails, newClientForm } from '../AddPatientModalV2Interface';
import moment from 'moment';
import { postClientsRecord } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import i18n from 'i18n';

const massageClientProfiles = (clientProfileData: newClientForm['clientProfiles']) => {
  let newCPList = [];
  for (let cpObj of clientProfileData) {
    const { _id, ...otherCPObj } = cpObj;
    newCPList.push({
      firstName: otherCPObj.firstName,
      lastName: otherCPObj.lastName,
      ...(otherCPObj.dateOfBirth && {
        dateOfBirth: otherCPObj.dateOfBirth
      }),
      ...(otherCPObj.isPrimaryContact && {
        isPrimaryContact: otherCPObj.isPrimaryContact
      }),
      ...(otherCPObj.role && {
        role: otherCPObj.role
      }),
      ...(otherCPObj.email && {
        email: otherCPObj.email
      }),
      ...(otherCPObj.isEmailThirdParty && {
        isEmailThirdParty: otherCPObj.isEmailThirdParty
      }),
      ...(otherCPObj.mobileNumber && {
        mobileNumber: otherCPObj.mobileNumber?.replace(/[^0-9+]+/g, '')
      }),
      ...(otherCPObj.isMobileNumberThirdParty && {
        isMobileNumberThirdParty: otherCPObj.isMobileNumberThirdParty
      }),
      ...(cpObj.onboarding.isCheckOnBoarding &&
        Array.isArray(otherCPObj.onboarding.onboardingSurveyIds) &&
        otherCPObj.onboarding.onboardingSurveyIds.length > 0 && {
          firstAssessmentIds: otherCPObj.onboarding.onboardingSurveyIds,
          onboardingMethod: cpObj.onboarding.onboardingMethod
        }),
      ...(cpObj.consentForm?.isCheckOnConsentForm &&
        Array.isArray(otherCPObj.consentForm?.consentFormIds) &&
        otherCPObj.consentForm?.consentFormIds.length > 0 && {
          consentFormIds: otherCPObj.consentForm?.consentFormIds,
          onboardingMethod: cpObj.onboarding.onboardingMethod
        }),
      ...(otherCPObj.communicationPreference && {
        communicationPreference: otherCPObj.communicationPreference
      }),
      ...(cpObj.assessmentForm?.isCheckOnAssessmentForm &&
        Array.isArray(otherCPObj.assessmentForm?.assessmentFormIds) &&
        otherCPObj.assessmentForm?.assessmentFormIds.length > 0 && {
          assessmentFormIds: otherCPObj.assessmentForm?.assessmentFormIds,
          onboardingMethod: cpObj.onboarding.onboardingMethod
        })
    } as ClientDetails);
  }

  return newCPList;
};

const massageSendTime = (sendTimeValue: string, customTime?: string) => {
  const nowTime = moment().format('HH:mm');

  let sendAtDateTime = '';

  if (sendTimeValue === '9') {
    if (nowTime > '09:00') {
      sendAtDateTime = moment().add('1', 'day').hour(9).minute(0).second(0).format('YYYY-MM-DD hh:mmA Z');
    } else {
      sendAtDateTime = moment().hour(9).minute(0).second(0).format('YYYY-MM-DD hh:mmA Z');
    }
  } else if (sendTimeValue === '13') {
    if (nowTime > '13:00') {
      sendAtDateTime = moment().add('1', 'day').hour(13).minute(0).second(0).format('YYYY-MM-DD hh:mmA Z');
    } else {
      sendAtDateTime = moment().hour(13).minute(0).second(0).format('YYYY-MM-DD hh:mmA Z');
    }
  } else if (sendTimeValue === 'custom') {
    sendAtDateTime = moment(customTime).format('YYYY-MM-DD hh:mmA Z') || nowTime;
  } else {
    sendAtDateTime = moment().format('YYYY-MM-DD hh:mmA Z');
  }

  return sendAtDateTime;
};

export const postNewClientData = async ({
  token,
  clientVal,
  timeZone,
  selectedClinicianId
}: {
  token: string;
  clientVal: newClientForm;
  timeZone?: string;
  selectedClinicianId?: string;
}) => {
  const massageData = {
    clinicianAuth0Ids: selectedClinicianId ? [selectedClinicianId] : clientVal.clinicianAuth0Ids,
    recordType: clientVal.recordType,
    referralSourceTags: clientVal.referralSourceTags,
    referralDetail: clientVal.referralDetail,
    ...(clientVal.caseId && {
      caseId: clientVal.caseId
    }),
    clientProfiles: massageClientProfiles(clientVal.clientProfiles),
    clientCapability: {
      profileType: clientVal.clientCapability.profileType,
      ...(clientVal.clientCapability.profileType === 'full' && {
        signupInvitationSettings: {
          sendAt: massageSendTime(
            clientVal.signupInvitationSettings.sendAt,
            clientVal.signupInvitationSettings.customSendTime
          )
        }
      })
    },
    ...(clientVal.quickNote.includeQuickNote && {
      quickNote: {
        title: i18n.t('label.invite_client_to_signup'),
        body: clientVal.quickNote.note
      }
    }),
    registeredAs: clientVal.registeredAs,
    ...(timeZone && { timeZone }),
    ...(clientVal.address && { address: clientVal.address })
  };

  try {
    const callPostClientsRecord = (await postClientsRecord(token, massageData)) as any;
    const result = await callPostClientsRecord.json();
    return result;
  } catch (e) {
    console.error(e);
    throw new Error('Client set up failed!');
  }
};
