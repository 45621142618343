////////////////
// ENUMS ///////
////////////////

export enum DeliveryType {
  Bespoke = 'bespoke',
  FaceToFace = 'faceToFace',
  VideoCall = 'videoCall',

  // telehealth and regular phone call use this
  PhoneCall = 'phoneCall',
  // only used for telehealth accounts to not generate telehealth voice link
  PhoneCallDialClient = 'phoneCallDialClient',

  // requires otherInstructions as well
  Other = 'other'
}

export const DELIVERY_TYPE_LABELS: Record<DeliveryType, string> = {
  [DeliveryType.Bespoke]: 'Bespoke',
  [DeliveryType.FaceToFace]: 'Face to face',
  [DeliveryType.PhoneCall]: 'Telehealth phone call',
  [DeliveryType.PhoneCallDialClient]: 'Outbound phone call',
  [DeliveryType.VideoCall]: 'Telehealth video call',
  [DeliveryType.Other]: 'Other'
};

export const DELIVERY_TYPE_ICONS: Record<DeliveryType, string> = {
  [DeliveryType.Bespoke]: 'people',
  [DeliveryType.FaceToFace]: 'people',
  [DeliveryType.PhoneCall]: 'phone',
  [DeliveryType.PhoneCallDialClient]: 'phone',
  [DeliveryType.VideoCall]: 'videocam',
  [DeliveryType.Other]: 'connect_without_contact'
};

export enum SessionStartOption {
  OnTheHour = 'onTheHour',
  OnTheHalfHour = 'onTheHalfHour',
  Mixed = 'mixed'
}

export enum Rule {
  Instant = 'instant',
  Request = 'request'
}

export enum ScheduleOption {
  AutoFit = 'auto fit',
  FixedTime = 'fixed time'
}

export enum AssignmentMode {
  All = 'all',
  Practice = 'practice',
  Selected = 'selected',
  AllMentors = 'allMentors',
  AutoAssign = 'autoAssign',
  Single = 'single'
}

export enum ParticipantType {
  OneToOne = 'oneToOne',
  Group = 'group',
  Activity = 'activity'
}

export enum RateType {
  PerSession = 'perSession',
  PerAttendee = 'perAttendee'
}

//////////////////////////
// APPOINTMENT TYPE /////
//////////////////////////

interface AppointmentTypeDuration {
  minutes: number;
  editable: boolean;
}

export interface AppointmentTypeRate {
  amount: number;
  rateType: RateType;
  editable: boolean;
  hidePublicRate?: boolean; // Option to hide the price
}

export interface AutoFitSelection {
  isActive: boolean;
  startTime: string;
  endTime: string;
}

export type FixedTimeSelection = {
  isActive: boolean;
  startTimes: string[];
};

export interface AutoFitSelections {
  Monday: AutoFitSelection;
  Tuesday: AutoFitSelection;
  Wednesday: AutoFitSelection;
  Thursday: AutoFitSelection;
  Friday: AutoFitSelection;
  Saturday: AutoFitSelection;
  Sunday: AutoFitSelection;
}

export interface FixedTimeSelections {
  Monday: FixedTimeSelection;
  Tuesday: FixedTimeSelection;
  Wednesday: FixedTimeSelection;
  Thursday: FixedTimeSelection;
  Friday: FixedTimeSelection;
  Saturday: FixedTimeSelection;
  Sunday: FixedTimeSelection;
}

export interface OtherInstructions {
  title: string;
  instructions?: string;
}

export interface BookingRulesChildProps {
  available: boolean;
  rule: Rule;
}

export interface DayTimeSelection {
  kind: ScheduleOption; // auto fit || fixed time
  autoFit?: AutoFitSelections;
  fixedTime?: FixedTimeSelections;
}

type PreferredRoomAvailability = AutoFitSelections;
export interface PreferredRoom {
  roomId: string;
  name: string;
  availability?: PreferredRoomAvailability;
}

export enum ClaimType {
  DVA = 'dva',
  BULK_BILL = 'bulkBill',
  REBATE = 'rebate',
  OUT_OF_POCKET = 'outOfPocket'
}

export interface AppointmentType {
  id?: string; // This is due to a weird hardcode: src/pages/Calendar/components/CalendarSettings/components/AppointmentTypes/components/AppointmentDeliveryForm/constants.tsx
  _id?: string;
  clinicianId?: string;
  name: string;
  isActive: boolean;
  description?: string;
  duration: AppointmentTypeDuration;
  gap?: number;
  rate: AppointmentTypeRate;
  deliveryOptions: DeliveryType[];
  faceToFaceLocation?: string;
  videoCallInstructions?: string;
  defaultVideoCallInstructions?: string; // set in account level rules, not saved in appointment type
  phoneCallInstructions?: string;
  phoneCallDialClientInstructions?: string;
  otherInstructions?: OtherInstructions;
  sessionStartOption?: SessionStartOption;
  bookingRules?: {
    new: BookingRulesChildProps;
    existing: BookingRulesChildProps;
  };
  accountId?: string; // Edge account
  roomSettings?: {
    required: boolean;
    preferredRoom: PreferredRoom; // Room Info
  };
  roomAny: boolean;
  assignedClinicians?: string[]; // Can be list of clinician
  dayTimeSelections?: DayTimeSelection;
  assignmentMode?: AssignmentMode;
  paymentRequired?: boolean;
  firestoreId?: string;
  participantType: ParticipantType;
  claimType?: ClaimType;
  packageAppointmentId?: string;
}

export interface CreatedAppointmentType extends AppointmentType {
  _id: string;
}
