import { Tooltip } from 'react-tooltip';
import styles from './FieldTitle.module.scss';

interface FieldTitleProps {
  title: string;
  desc?: string | React.ReactNode;
  tooltipId?: string;
  tooltipMessage?: string;
}

const FieldTitle = ({ title, desc, tooltipId, tooltipMessage }: FieldTitleProps) => {
  return (
    <div>
      <div className={styles.titleWrapper}>
        {title}
        {tooltipMessage && (
          <>
            <i className={`material-icons ${styles.icon}`} data-tooltip-id={tooltipId}>
              help_outline
            </i>
            <Tooltip id={tooltipId} className={styles.tooltip}>
              {tooltipMessage}
            </Tooltip>
          </>
        )}
      </div>
      {desc && <div className={styles.descWrapper}>{desc}</div>}
    </div>
  );
};

export default FieldTitle;
