import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { resetPaging, selectActiveTab, selectFilters, setActiveTab, setFilters } from 'redux/referrals/referralSlice';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ReferralsList from './ReferralsList/ReferralsList';
import { getReferralDefaultFilters } from './utils';
import { ReferralTab } from './interface';

const Referals = () => {
  const { tabId = ReferralTab.New } = useParams<{ tabId: string }>();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectFilters);
  const activeTab = useAppSelector(selectActiveTab);

  const setFilterByTab = useCallback(
    (currentTab: ReferralTab) => {
      dispatch(resetPaging());
      const filtersByTab = getReferralDefaultFilters(currentTab);
      dispatch(setFilters({ ...filters, ...filtersByTab }));
    },
    [dispatch, filters]
  );

  const onChangeTabFilter = useCallback(
    (tabValue: ReferralTab) => {
      dispatch(setActiveTab(tabValue));
      setFilterByTab(tabValue);
    },
    [dispatch, setFilterByTab]
  );

  useEffect(() => {
    if (tabId !== activeTab) {
      onChangeTabFilter(tabId as ReferralTab);
    }
  }, [onChangeTabFilter, tabId, activeTab]);

  return (
    <HelmetWrapper title="Tacklit - Referrals">
      <ContentLayout>
        <ReferralsList />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Referals;
