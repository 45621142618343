import { useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import classnames from 'classnames';
import styles from './AddEditClientGPModal.module.scss';
import { GeneralPractitionerErrorInterface, GeneralPractitionerInterface } from '../../interface';
import { CLIENT_GP_INITIAL, validationClientGP } from './constants';
import PhoneInput from 'react-phone-input-2';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import GPSelect from './components/GPSelect/GPSelect';
import { postGeneralPractitioner, putGeneralPractitioner } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useFetchGeneralPractitionerList } from './hooks/getGeneralPractitionerList';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import GPDetailTooltip from './components/GPDetailTooltip/GPDetailTooltip';
import { useTranslation } from 'react-i18next';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import { useUpdateReferralMutation } from 'redux/endpoints/clinicianProfileServices/referral';
import { Referral } from 'interfaces/Referral/Referral';
import { config } from 'config/config';

const CHECK_INDEX_REGEX = /\([\d]+\)/;

interface ClientGPModalProps {
  visible: boolean;
  initialGP: GeneralPractitionerInterface;
  showMedicareField: boolean;
  onCancel: () => void;
  onRefreshGP: (generalPractitionerId: string) => void;
  referral: Referral;
}

export const checkingGPField = (gp: GeneralPractitionerInterface) =>
  gp.fax === '' &&
  gp.email === '' &&
  gp.address === '' &&
  gp.landline === '' &&
  gp.practiceName === '' &&
  gp.mobileNumber === '';

const AddEditClientGPModal = ({
  visible,
  initialGP,
  showMedicareField,
  onCancel,
  onRefreshGP,
  referral
}: ClientGPModalProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();

  const [updateReferral] = useUpdateReferralMutation();

  const [selectedGP, setSelectedGP] = useState<GeneralPractitionerInterface>(initialGP);
  const [updatedGP, setUpdatedGP] = useState<GeneralPractitionerInterface>(initialGP);
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [refreshGPList, setRefreshGPList] = useState(0);
  const { gpList, isGPListLoading } = useFetchGeneralPractitionerList(token, refreshGPList);
  const [existedGP, setExistedGP] = useState<GeneralPractitionerInterface[]>([]);
  const [existedMedicare, setExistedMedicare] = useState<GeneralPractitionerInterface[]>([]);
  const [checkValidation, setCheckValidation] = useState(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    name: '',
    landline: '',
    mobileNumber: '',
    email: '',
    fax: ''
  } as GeneralPractitionerErrorInterface);
  const [t] = useTranslation();

  useEffect(() => {
    setSelectedGP(initialGP);
    setUpdatedGP(initialGP);
  }, [initialGP, visible]);

  useEffect(() => {
    setExistedGP([]);
    setExistedMedicare([]);
  }, [gpList, selectedGP]);

  const onGPChange = (selected: GeneralPractitionerInterface) => {
    setSelectedGP(selected);
    setUpdatedGP(selected);
  };

  const onFieldsChange = (value: string, name: string) => {
    const newGeneralPractitioner = {
      ...updatedGP,
      [name]: value
    };
    setUpdatedGP(newGeneralPractitioner);
    if (checkValidation) {
      validateField(newGeneralPractitioner);
    }
  };

  const generateName = (name: string) => {
    // Search existed name
    const existedGPs = gpList
      .filter((gp) => {
        return name.trim() === gp.name || name.trim() === gp.name.replace(CHECK_INDEX_REGEX, '').trim();
      })
      .sort((a, b) => b.name.localeCompare(a.name));

    if (existedGPs.length > 0) {
      const existedIndexStr = existedGPs[0].name.match(CHECK_INDEX_REGEX);
      if (existedIndexStr && existedIndexStr[0]?.length > 0) {
        const index = existedIndexStr[0].replace('(', '').replace(')', '');
        return existedGPs[0].name.replace(existedIndexStr[0], `(${Number(index) + 1})`);
      }
      return `${name} (1)`;
    }
    return name;
  };

  const isGPUpdated = () => {
    return (
      selectedGP.name !== updatedGP.name ||
      selectedGP.practiceName !== updatedGP.practiceName ||
      selectedGP.address !== updatedGP.address ||
      selectedGP.mobileNumber !== updatedGP.mobileNumber ||
      selectedGP.landline !== updatedGP.landline ||
      selectedGP.fax !== updatedGP.fax ||
      selectedGP.email !== updatedGP.email ||
      (showMedicareField && selectedGP.medicareProviderNumber !== updatedGP.medicareProviderNumber)
    );
  };

  const onUpdate = async (gpData: GeneralPractitionerInterface) => {
    setIsShowConfirmModal(false);
    setSubmitStatus('active');
    try {
      const callUpdateGP = await putGeneralPractitioner(token, accountId, gpData._id, gpData);
      const updatedGP = await callUpdateGP.json();

      if (gpData._id !== initialGP._id) {
        // Change to other GP
        await updateReferral({
          accountId,
          referralId: referral._id,
          payload: {
            ...referral,
            generalPractitionerId: updatedGP._id
          }
        });
      }
      setSubmitStatus('finished');
      setTimeout(() => {
        notification.success({
          message: `${
            gpData._id === initialGP._id
              ? t('form.success.update_gp_detail')
              : t('form.success.update_client_gp_detail')
          }`,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitStatus('');
        onClose();
        onRefreshGP(gpData._id);
      }, 1000);
    } catch (ex) {
      setSubmitStatus('');
      notification.error({
        message: `${
          gpData._id === initialGP._id ? t('form.error.update_gp_detail') : t('form.error.update_client_gp_detail')
        }`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    setCheckValidation(false);
  };

  const onCreate = async (data: GeneralPractitionerInterface) => {
    setIsShowConfirmModal(false);
    setSubmitStatus('active');
    try {
      const payload = {
        name: data.name,
        practiceName: data.practiceName,
        address: data.address,
        landline: data.landline,
        mobileNumber: data.mobileNumber,
        email: data.email,
        fax: data.fax,
        ...(showMedicareField && { medicareProviderNumber: data.medicareProviderNumber })
      };

      const callCreateGP = await postGeneralPractitioner(token, accountId, payload);
      const gpData = await callCreateGP.json();
      if (gpData && gpData._id) {
        await updateReferral({
          accountId,
          referralId: referral._id,
          payload: {
            ...referral,
            generalPractitionerId: gpData._id
          }
        });
        setSubmitStatus('finished');
        setTimeout(() => {
          notification.success({
            message: t('form.success.create_gp_detail'),
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setSubmitStatus('');
          onClose();
          onRefreshGP(gpData._id);
          setRefreshGPList(refreshGPList + 1);
        }, 1000);
      }
    } catch (ex) {
      setSubmitStatus('');
      notification.error({
        message: t('form.error.create_gp_detail'),
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    setCheckValidation(false);
  };

  const validateField = (gpData: GeneralPractitionerInterface) => {
    const validate = validationClientGP(gpData);
    setErrorMessage(validate as GeneralPractitionerErrorInterface);
    return validate;
  };

  const callUnassigned = async () => {
    setSubmitStatus('active');
    try {
      await updateReferral({
        accountId,
        referralId: referral._id,
        payload: {
          ...referral,
          generalPractitionerId: ''
        }
      });
      setSubmitStatus('finished');
      setTimeout(() => {
        notification.success({
          message: t('form.success.unassign_client_gp_detail'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitStatus('');
        onRefreshGP('');
        onClose();
      }, 1000);
    } catch {
      setSubmitStatus('');
      notification.error({
        message: t('form.error.unassign_client_gp_detail'),
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // Unassigned GP
    if (initialGP._id?.length > 0 && checkingGPField(updatedGP) && updatedGP.name?.length === 0) {
      await callUnassigned();
    } else {
      setCheckValidation(true);
      const validate = validateField(updatedGP);
      if (Object.values(validate).some((value) => value !== '')) {
        return;
      }
      if (updatedGP._id?.length > 0) {
        if (isGPUpdated()) {
          setIsShowConfirmModal(true);
        } else if (!isGPUpdated()) {
          if (updatedGP._id !== initialGP?._id) {
            // Change to other GP and don't update any field
            setSubmitStatus('active');
            await updateReferral({
              accountId,
              referralId: referral._id,
              payload: {
                ...referral,
                generalPractitionerId: updatedGP._id
              }
            });
            setSubmitStatus('finished');
            setTimeout(() => {
              notification.success({
                message: t('form.success.update_client_gp_detail'),
                duration: 2,
                closeIcon: <span className="success">OK</span>
              });
              setSubmitStatus('');
              onRefreshGP(updatedGP._id);
              onClose();
            }, 1000);
          } else {
            onClose();
          }
          setCheckValidation(false);
        }
      } else {
        // Create new GP
        onCreate(updatedGP);
        setCheckValidation(false);
      }
    }
  };

  const onClose = () => {
    setCheckValidation(false);
    onCancel();
  };

  const onGPNameChange = (gpName: string) => {
    onFieldsChange(gpName, 'name');
    let exited = [] as GeneralPractitionerInterface[];
    if (gpName.length > 3) {
      exited = gpList
        .filter((gp) => gp.name.toLowerCase().includes(gpName.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    setExistedGP(exited);
  };

  const onMedicareNumberChange = (value: string) => {
    onFieldsChange(value, 'medicareProviderNumber');
    let existed = [] as GeneralPractitionerInterface[];

    if (value.length > 3) {
      existed = gpList
        .filter((gp) => gp.medicareProviderNumber?.toLowerCase().includes(value.toLowerCase()))
        .sort((a, b) => a.medicareProviderNumber?.localeCompare(b.name));
    }
    setExistedMedicare(existed);
  };

  const onUnassigned = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedGP(CLIENT_GP_INITIAL);
    setUpdatedGP(CLIENT_GP_INITIAL);
  };

  return (
    <Modal
      bodyStyle={{
        padding: 0,
        borderRadius: '16px'
      }}
      width={736}
      footer={null}
      open={visible}
      destroyOnClose
      closable={false}
      className={styles.modal}
    >
      {isGPListLoading ? (
        <div className={styles.loadingWrapper}>
          <LoadingDot />
        </div>
      ) : (
        <div className={styles.addEditClientGPModalContainer}>
          <div className={styles.modalHeader}>
            <div className={styles.header}>
              <div className={styles.title}>{`${
                updatedGP && updatedGP._id?.length > 0 ? 'EDIT' : 'ADD'
              } Referrer / GP Details`}</div>
              <button onClick={onClose} className={styles.closeButton}>
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className={styles.selectGP}>
              <div className={styles.selectGPTitle}>
                {`${
                  gpList.length > 0
                    ? 'Enter details below or select from an existing referring Referrer / GP'
                    : 'Enter details below'
                }`}
              </div>
              {gpList.length > 0 && <GPSelect gpList={gpList} preSelectedGP={selectedGP} onChange={onGPChange} />}
              {selectedGP._id?.length > 0 && (
                <div className={styles.unassigned} onClick={onUnassigned}>
                  Unassign this Referrer / GP
                </div>
              )}
            </div>
          </div>
          <div className={styles.modalContent}>
            <form onSubmit={handleSubmit}>
              <div className={styles.fieldContainer}>
                <MaterialInput
                  id={'name'}
                  className={classnames(styles.firstName, styles.input)}
                  name="name"
                  label="Referrer / GP Name*"
                  disabled={submitStatus !== ''}
                  onChange={(event) => {
                    onGPNameChange(event.target.value);
                  }}
                  value={updatedGP.name}
                />
                {updatedGP?.name?.length > 3 && existedGP.length > 0 && (
                  <div className={styles.existedGPs}>
                    {existedGP.length > 5 && <>Please type a few more characters to narrow down the results.</>}
                    <div>Found matching details:</div>
                    {existedGP.slice(0, 5).map((gp, index) => (
                      <div
                        className={styles.suggestionContainer}
                        onClick={() => {
                          onGPChange(gp);
                        }}
                        key={index}
                      >
                        <GPDetailTooltip id={`${index}_tooltip`} gp={gp} />
                      </div>
                    ))}
                    {existedGP.length > 5 && `and ${existedGP.length - 5} more...`}
                  </div>
                )}
                <ErrorMessage error={errorMessage.name} visible={checkValidation && !!errorMessage.name} />
              </div>
              {showMedicareField && (
                <div className={styles.fieldContainer}>
                  <div className={styles.medicareProviderNumber}>
                    <MaterialInput
                      id={'medicare'}
                      className={styles.input}
                      name="medicareProviderNumber"
                      label="Medicare Provider Number"
                      disabled={submitStatus !== ''}
                      onChange={(event) => {
                        onMedicareNumberChange(event.target.value);
                      }}
                      value={updatedGP.medicareProviderNumber || ''}
                    />
                    <div className={styles.tooltip}>
                      <HelpOutLineWithTooltips
                        id="gp-details-medicare-provider-number"
                        desc="This Medicare provider number will be automatically used in future claims for assigned clients"
                      />
                    </div>
                  </div>
                  {updatedGP?.medicareProviderNumber?.length > 2 && existedMedicare.length > 0 && (
                    <div className={styles.existedGPs}>
                      {existedMedicare.length > 5 && <>Please type a few more characters to narrow down the results.</>}
                      <div>Found matching details:</div>
                      {existedMedicare.slice(0, 5).map((gp, index) => (
                        <div
                          className={styles.suggestionContainer}
                          onClick={() => {
                            onGPChange(gp);
                          }}
                          key={index}
                        >
                          <GPDetailTooltip id={`${index}_tooltip`} gp={gp} showMedicareNumber />
                        </div>
                      ))}
                      {existedMedicare.length > 5 && `and ${existedMedicare.length - 5} more...`}
                    </div>
                  )}
                  <ErrorMessage
                    error={errorMessage.medicareProviderNumber}
                    visible={checkValidation && !!errorMessage.medicareProviderNumber}
                  />
                </div>
              )}
              <div className={classnames(styles.fieldContainer)}>
                <MaterialInput
                  id={'practiceName'}
                  className={classnames(styles.nameOfPractice, styles.input)}
                  name="practiceName"
                  label="Name Of Organisation / Practice"
                  disabled={submitStatus !== ''}
                  onChange={(event) => {
                    onFieldsChange(event.target.value, 'practiceName');
                  }}
                  value={updatedGP.practiceName}
                />
              </div>
              <div className={styles.fieldContainer}>
                <MaterialInput
                  id={'address'}
                  className={classnames(styles.address, styles.input)}
                  name="address"
                  label="Address"
                  disabled={submitStatus !== ''}
                  onChange={(event) => {
                    onFieldsChange(event.target.value, 'address');
                  }}
                  value={updatedGP.address}
                />
              </div>
              <div className={classnames(styles.fieldContainer, styles.mobileNumber, styles.mobileNumberContainer)}>
                <span className={styles.label}>Mobile number</span>
                <PhoneInput
                  inputClass={styles.mobileNumberInput}
                  buttonClass={styles.countrySelect}
                  dropdownClass={styles.countryDropdown}
                  value={updatedGP.mobileNumber}
                  onChange={(value) => {
                    onFieldsChange(value, 'mobileNumber');
                  }}
                  {...(config.countryCode && {
                    country: config.countryCode,
                    preferredCountries: [config.countryCode]
                  })}
                  disabled={submitStatus !== ''}
                />
                <ErrorMessage
                  error={errorMessage.mobileNumber}
                  visible={checkValidation && !!errorMessage.mobileNumber}
                />
              </div>
              <div className={classnames(styles.fieldContainer)}>
                <MaterialInput
                  id={'landline'}
                  className={classnames(styles.landline, styles.input)}
                  name="landline"
                  label="Landline Number"
                  disabled={submitStatus !== ''}
                  onChange={(event) => {
                    onFieldsChange(event.target.value, 'landline');
                  }}
                  value={updatedGP.landline}
                />
                <ErrorMessage error={errorMessage.landline} visible={checkValidation && !!errorMessage.landline} />
              </div>
              <div className={classnames(styles.fieldContainer)}>
                <MaterialPhoneInput
                  id={'faxNumber'}
                  autoFormat={false}
                  disableCountryCode
                  disabled={submitStatus !== ''}
                  errorMessage={errorMessage.fax}
                  hideFlag
                  inputName="fax"
                  isError={checkValidation && !!errorMessage.fax}
                  label="Fax Number"
                  onChange={(value = '') => {
                    onFieldsChange(value, 'fax');
                  }}
                  placeholder=""
                  value={updatedGP.fax}
                />
              </div>
              <div className={classnames(styles.fieldContainer)}>
                <MaterialInput
                  id={'email'}
                  className={classnames(styles.email, styles.input)}
                  name="email"
                  label="Email Address"
                  disabled={submitStatus !== ''}
                  onChange={(event) => {
                    onFieldsChange(event.target.value, 'email');
                  }}
                  value={updatedGP.email}
                />
                <ErrorMessage error={errorMessage.email} visible={checkValidation && !!errorMessage.email} />
              </div>
              <ButtonAlt className={styles.saveButton} status={submitStatus} disabled={submitStatus !== ''}>
                {initialGP._id?.length === 0 && selectedGP._id?.length === 0
                  ? 'Add Referrer / GP and Save'
                  : selectedGP._id?.length === 0
                  ? checkingGPField(updatedGP) && updatedGP.name.length === 0
                    ? 'Unassign Referrer / GP and Save'
                    : 'Add Referrer / GP and Save'
                  : isGPUpdated()
                  ? 'Update Referrer / GP details and Save'
                  : 'Save'}
              </ButtonAlt>
            </form>
          </div>
        </div>
      )}
      <Modal
        bodyStyle={{
          padding: 0,
          borderRadius: '16px'
        }}
        width={416}
        footer={null}
        open={isShowConfirmModal}
        destroyOnClose
        closable={false}
        className={styles.confirmModal}
      >
        <div className={styles.confirmHeader}>
          <button
            onClick={() => {
              setIsShowConfirmModal(false);
            }}
            className={styles.closeButton}
          >
            <span className="material-icons-outlined">close</span>
          </button>
        </div>
        <div className={styles.confirmBody}>
          <div className={styles.confirmMessage}>
            <span className={classnames('material-icons-outlined', styles.infoIcon)}>info</span>
            <span>Are you sure you want to update everywhere?</span>
          </div>
          <div className={styles.actionsContainer}>
            {/* Create new GP with another name */}
            <button
              className={styles.confirmNo}
              onClick={() => {
                onCreate({ ...updatedGP, _id: '', name: generateName(updatedGP.name) });
              }}
            >
              No, Create a new Referrer / GP
            </button>
            {/* Update GP */}
            <button
              className={styles.confirmYes}
              onClick={() => {
                onUpdate(updatedGP);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};

export default AddEditClientGPModal;
