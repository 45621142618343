import { AppointmentReport } from 'redux/endpoints/scheduleServices/appointmentReport';
import momentTz from 'moment-timezone';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_TIME_FORMAT } from 'utils/dateChecker';
import { ServiceDeliveredNameMapping } from 'interfaces/Schedule/Appointment';
import { combineName } from 'utils/general';
import { defaultTimezone } from 'utils/hooks/GetTimezones/getTimezones';

interface ReportData {
  serviceId: string;
  dateOfService: string;
  startTime: string;
  nameOfService: string;
  processedOutcome: string;
  tID: string;
  clientName: string;
  collected: string;
  invoiceAmount: string;
  paymentCompleted: string;
  notesOnUpdates: string[];
  waiveCancellationFee: string;
  reviewNotes: string;
}

type ReportCSVData = Omit<ReportData, 'notesOnUpdates'> & { notesOnUpdate: string };

export const getReportData = (reportData: AppointmentReport, currencySymbol: string): ReportData => {
  const {
    _id,
    startDateTime,
    sessionTypeName,
    serviceDelivered,
    clientRecord,
    collected,
    paymentCompleted,
    notesOnUpdate,
    foundGroup,
    invoiceAmount,
    requestedChanges,
    isProcessed
  } = reportData;
  return {
    serviceId: _id,
    dateOfService: startDateTime ? momentTz.tz(startDateTime, defaultTimezone).format(MOMENTJS_DATE_FORMAT) : '-',
    startTime: startDateTime ? momentTz.tz(startDateTime, defaultTimezone).format(MOMENTJS_TIME_FORMAT) : '-',
    nameOfService: sessionTypeName || '-',
    processedOutcome: serviceDelivered ? ServiceDeliveredNameMapping[serviceDelivered] : '-',
    tID: clientRecord?.tacklitId || '-',
    clientName: foundGroup
      ? foundGroup.name
      : clientRecord?.clientProfiles
      ? combineName(clientRecord.clientProfiles, clientRecord.recordType === 'couple', true)
      : '-',
    waiveCancellationFee: isProcessed && requestedChanges?.waiveCancellationFee ? 'Yes' : '-',
    reviewNotes: isProcessed && requestedChanges?.reviewNote ? requestedChanges.reviewNote : '-',
    collected: collected ? `${currencySymbol}${collected.toFixed(2)}` : '-',
    invoiceAmount: invoiceAmount ? `${currencySymbol}${invoiceAmount.toFixed(2)}` : '-',
    paymentCompleted: paymentCompleted
      ? `${momentTz.tz(paymentCompleted, defaultTimezone).format(MOMENTJS_TIME_FORMAT)} ${momentTz
          .tz(paymentCompleted, defaultTimezone)
          .format(MOMENTJS_DATE_FORMAT)}`
      : '-',
    notesOnUpdates: notesOnUpdate || []
  };
};

export const getCSVData = (reportData: AppointmentReport[], currencySymbol: string): ReportCSVData[] => {
  return reportData.map((data) => {
    const notesOnUpdateStr = data.notesOnUpdate?.length ? data.notesOnUpdate.join('\n') : '-';
    return {
      ...getReportData(data, currencySymbol),
      notesOnUpdate: notesOnUpdateStr
    };
  });
};
