import { useFetchOpenDataCategories } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/hooks/useFetchOpenDataCategories';
import { useMemo } from 'react';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';

export const useFetchCDFList = (fetch?: boolean) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { openDataCategories } = useFetchOpenDataCategories(fetch ? token : '', accountId);

  const cdfList = useMemo(() => {
    const questions = openDataCategories.flatMap((category) =>
      category.questions.map((question) => ({
        id: question.variableId || '',
        value: `{{${question.variableId}-${question.variableName}}}`
      }))
    );
    return questions.filter((question, index) => index === questions.findIndex((q) => q.value === question.value));
  }, [openDataCategories]);

  return {
    cdfList
  };
};
