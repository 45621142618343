import { useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { LetterClientTab } from 'interfaces/Letters/letter';

import styles from './PatientDetailsLettersHeader.module.scss';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface PatientDetailsLettersHeaderProps {
  recordId: string;
  currentTab: string;
  letterId?: string;
  letterList?: LetterClientTab[];
}

const PatientDetailsLettersHeader = ({
  recordId,
  currentTab,
  letterId,
  letterList
}: PatientDetailsLettersHeaderProps) => {
  const navigate = useNavigate();
  const { CLIENTS, LETTERS } = useRoutesGenerator();

  const { currentLetter, previousLetter, nextLetter } = useMemo(() => {
    const letterHeaderItems: {
      currentLetter?: LetterClientTab;
      previousLetter?: LetterClientTab;
      nextLetter?: LetterClientTab;
    } = { currentLetter: undefined, previousLetter: undefined, nextLetter: undefined };

    if (letterList) {
      const currentLetter = letterList.find((letterItem) => letterItem._id === letterId);

      if (currentLetter) {
        letterHeaderItems.currentLetter = currentLetter;

        const currentIndex = letterList.indexOf(currentLetter);

        if (currentIndex > -1) {
          letterHeaderItems.previousLetter = letterList[currentIndex - 1];
          letterHeaderItems.nextLetter = letterList[currentIndex + 1];
        }
      }
    }

    return letterHeaderItems;
  }, [letterId, letterList]);

  const handleLetterClick = (id: string) => {
    navigate(`${CLIENTS.BASE}/${recordId}/${currentTab}/${id}`);

    const element = document.getElementById(id);
    element && element.scrollIntoView({ block: 'nearest' });
  };

  return (
    <div className={styles.container}>
      {previousLetter && (
        <ButtonAlt
          className={`${styles.btnBox} ${styles.left}`}
          contentClassName={styles.btnContent}
          variant={'text'}
          onClick={() => handleLetterClick(previousLetter._id)}
          icon={'chevron_left'}
        >
          <div className={styles.date}>
            <div className={styles.label}>{previousLetter.letterName}</div>&nbsp;
            {moment(previousLetter.createdAt).format(MOMENTJS_DATE_FORMAT)}
          </div>
        </ButtonAlt>
      )}
      {currentLetter && (
        <div className={styles.titleWrapper} onClick={() => navigate(`${LETTERS.BASE}/${letterId}`)}>
          <div className={styles.title}>{currentLetter.letterName}</div>
          <div className={styles.date}>{moment(currentLetter.createdAt).format(MOMENTJS_DATE_FORMAT)}</div>
        </div>
      )}
      {nextLetter && (
        <ButtonAlt
          className={`${styles.btnBox} ${styles.right}`}
          contentClassName={styles.btnContent}
          variant={'text'}
          onClick={() => handleLetterClick(nextLetter._id)}
          icon={'chevron_right'}
          iconPostFix
        >
          <div className={styles.date}>
            <div className={styles.label}>{nextLetter.letterName}</div>&nbsp;
            {moment(nextLetter.createdAt).format(MOMENTJS_DATE_FORMAT)}
          </div>
        </ButtonAlt>
      )}
    </div>
  );
};

export default PatientDetailsLettersHeader;
