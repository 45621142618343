import columnStyles from '../../AssessmentSummary.module.scss';
import styles from './AssessmentItem.module.scss';
import { useEffect, useRef, useState } from 'react';
import { ClientAssessment } from '../../hooks/useGetAllClientAssessments';
import moment from 'moment';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface AssessmentItemProps {
  assessment: ClientAssessment;
  clientRecordId: string;
  onCancelAssessment: () => Promise<void>;
  onSendReminder: () => Promise<void>;
}

const AssessmentItem = ({
  assessment: { clinicalAssessmentIds, formName, createdAt },
  onCancelAssessment,
  onSendReminder
}: AssessmentItemProps) => {
  const menuNode = useRef<HTMLDivElement>(null);
  const [menuExpand, setMenuExpand] = useState(false);
  const [isCancellingAssessment, setIsCancellingAssessment] = useState(false);
  const [isSendingReminder, setIsSendingReminder] = useState(false);

  const isLoading = isCancellingAssessment || isSendingReminder;

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target)) {
      return;
    }
    setMenuExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleSendReminder = async () => {
    setIsSendingReminder(true);

    await onSendReminder();

    setIsSendingReminder(false);
    setMenuExpand(false);
  };

  const handleCancelAssessment = async () => {
    setIsCancellingAssessment(true);

    await onCancelAssessment();

    setIsCancellingAssessment(false);
    setMenuExpand(false);
  };

  const getFormDisplayName = () => {
    return (
      formName ||
      `${clinicalAssessmentIds
        .slice(0, 2)
        .map((id) => `${id}`)
        .join(', ')}${clinicalAssessmentIds.length > 2 ? ` +${clinicalAssessmentIds.length - 2} more` : ''}`
    );
  };

  return (
    <div className={styles.container}>
      <div className={columnStyles.assessmentName}>
        <div>{getFormDisplayName()}</div>
      </div>
      <div className={columnStyles.requestDate}>{moment(createdAt).format('DD/MM/YYYY')}</div>
      <div className={columnStyles.button} ref={menuNode}>
        <ButtonAlt
          variant={'text'}
          size={'medium'}
          onClick={() => !isLoading && setMenuExpand(!menuExpand)}
          icon={'more_vert'}
          fab
        />
        <div className={styles.menuWrapper}>
          <div className={menuExpand ? styles.menuShow : styles.menuHide}>
            <div className={styles.itemCard} onClick={() => !isLoading && handleSendReminder()}>
              Send Reminder
              {isSendingReminder && (
                <div className={styles.loading}>
                  <LoadingCircle />
                </div>
              )}
            </div>
            <div className={styles.itemCard} onClick={() => !isLoading && handleCancelAssessment()}>
              Cancel Form
              {isCancellingAssessment && (
                <div className={styles.loading}>
                  <LoadingCircle />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentItem;
