import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { security } from 'utils/security';

export enum AFSTagTypes {
  PatientsActivities = 'Patients Activities'
}

export const activityFeedServicesApiSlice = createApi({
  reducerPath: 'activityFeedServices',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ACTIVITY_FEED_SERVICE_URL,
    prepareHeaders: async (headers) => {
      const token = await security.getAccessTokenSilently().catch(() => '');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: Object.values(AFSTagTypes),
  endpoints: () => ({})
});
