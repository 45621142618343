import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { clientRecordsInterface } from '../../interfaces/Clients/clientsRecord';

export interface AppointmentsStatus {
  all: {
    invoiced: {
      sessions: number;
      total: number;
    };
    notInvoiced: {
      sessions: number;
      total: number;
    };
  };
  completed: {
    invoiced: {
      sessions: number;
      total: number;
    };
    notInvoiced: {
      sessions: number;
      total: number;
    };
  };
  upcoming: {
    invoiced: {
      sessions: number;
      total: number;
    };
    notInvoiced: {
      sessions: number;
      total: number;
    };
  };
}

export enum InvoiceStatus {
  Closed = 'closed',
  ConfirmPaid = 'confirmPaid',
  Draft = 'draft',
  Issued = 'issued',
  MarkedPaid = 'markedPaid',
  Overdue = 'overdue',
  Voided = 'voided',
  WriteOff = 'writeOff',
  PendingClaim = 'pendingClaim',
  ClaimComplete = 'claimComplete',
  ClaimSuccess = 'claimSuccess',
  ClaimRejected = 'claimRejected',
  FullyRefunded = 'fullyRefunded'
}

export enum InvoiceCreator {
  System = 'system',
  Clinician = 'clinician'
}

export enum InvoiceHistoryObject {
  WriteOff = 'writeOff',
  Cash = 'cash',
  OnlineTransfer = 'onlineTransfer',
  CreditNote = 'creditNote',
  Others = 'others',
  StripePayment = 'stripePayment',
  Refund = 'refund',
  Claim = 'claim',
  Status = 'status',
  Sent = 'sent',
  Generated = 'generated',
  ManualRefund = 'manualRefund',
  WriteOffClaim = 'writeOffClaim',
  Amount = 'amount',
  Abandon = 'abandon'
}

export enum InvoiceHistoryType {
  Payment = 'payment',
  Invoice = 'invoice'
}

export enum InvoiceHistoryStatus {
  Success = 'success',
  Failed = 'failed'
}

export enum InvoiceHistoryActor {
  Client = 'client',
  Clinician = 'clinician',
  System = 'system'
}

export interface InvoiceHistory {
  type: InvoiceHistoryType;
  object: InvoiceHistoryObject;
  status: InvoiceHistoryStatus;
  actor: { type: InvoiceHistoryActor; id?: string; name?: string };
  description: string;
  createdAt: string;
  updatedAt: string;
  date?: string;
  amount?: number;
  reference?: string;
  stripeChargeId?: string;
  stripeRefundId?: string;
  paymentRequestId?: string;
  claimId?: string;
}

export interface Invoice {
  _id: string;
  invoiceId: string;
  practice: {
    address: string;
    logo?: string;
    mobileNumber: string;
    name: string;
    fax?: string;
    financeEmail?: string;
    formalName?: string;
    registrationNumber?: string;
  };
  clinician: {
    _id: string;
    avatar: string;
    email: string;
    mobileNumber: string;
    name: string;
  };
  client: {
    _id: string;
    name: string;
    avatar: string;
  };
  clientRecord: clientRecordsInterface;
  issueDate: string;
  dueDate: string;
  description: string;
  taxRate: number;
  items: {
    appointmentId?: string;
    overview: string;
    cost: number;
    tax: number;
    total: number;
    appointmentDate: string;
    description?: string;
    quantity?: string;
    tags?: string[];
  }[];
  discount?: {
    type: 'percent' | 'amount';
    value?: number;
  };
  invoiceAmount: number;
  paymentMethods: PaymentMethod[];
  sendAt?: Date;
  sendClient?: boolean;
  sendTo?: string[];
  note?: string;
  status: InvoiceStatus;
  group?: GroupsFromAPI;
  medicare?: {
    claimant?: {
      name?: string;
      dateOfBirth?: string;
      medicareNumber?: string;
      irn?: string;
      dva?: string;
      phoneNumber?: string;
      address?: string;
      expiryDate?: string;
    };
    referral?: {
      name?: string;
      date?: string;
      providerNumber?: string;
    };
    serviceProvider?: {
      name?: string;
      providerNumber?: string;
    };
    claim?: {
      dateOfService?: string;
      mbsCode?: string;
    };
  };
  resendRecipients?: {
    sendTo: string[];
    sendKeyContactIds: string[];
    sendClient?: boolean;
  };
  template?: IInvoiceTemplate;
  title?: string;
  recipients?: {
    address?: string;
    email?: string;
    mobileNumber?: string;
    name?: string;
    reference?: string;
    registrationNumber?: string;
  }[];
  footer?: string;
  episodeId?: string;
  createdAt: string;
  accountId: string;
  createdBy?: InvoiceCreator;
  histories?: InvoiceHistory[];
  isWriteOff?: boolean;
  amountChanged?: boolean;
  claimAbandoned?: boolean;
  addressBook:
    | {
        _id: string;
      }
    | null
    | undefined;
}

interface Medicare {
  id: string;
  status: string;
  providerNumber?: string;
  connectedBy: string;
  connectedAt: string;
}

export interface ContactDetailsFields {
  practice: {
    address: {
      value: string;
      isVisible: boolean;
    };
    mobileNumber: {
      value: string;
      isVisible: boolean;
    };
  };
  clinician: {
    mobileNumber: {
      value: string;
      isVisible: boolean;
    };
    email: {
      value: string;
      isVisible: boolean;
    };
  };
}

export enum AccountingPlatform {
  QuickBooksOnline = 'quickBooksOnline',
  QuickBooksOnlineSandbox = 'quickBooksOnlineSandbox',
  Xero = 'xero'
}

export enum DataConnectionStatus {
  PendingAuth = 'pendingAuth',
  Linked = 'linked',
  Deauthorized = 'deauthorized',
  Unlinked = 'unlinked'
}

export enum IntegrationStatus {
  Pending = 'pending',
  PendingAuth = 'pendingAuth',
  PendingMapping = 'pendingMapping',
  Connected = 'connected',
  Disconnected = 'disconnected'
}

export interface RequiredMapping {
  to: string;
  active: boolean;
}
export interface OptionalMapping {
  to?: string;
  active: boolean;
}
export interface Mapping {
  clinicianId: string;
  active: boolean;
  to?: string;
}
export interface ItemMapping {
  id: string;
  active: boolean;
  to?: string;
}

export interface MappingFields {
  fields: {
    customerName: RequiredMapping;
    taxRates: RequiredMapping;
    emailAddress: OptionalMapping;
    contactNumber: OptionalMapping;
    invoiceNumber: OptionalMapping;
    notes: OptionalMapping;
    invoiceItemDescription: OptionalMapping;
    trackingCategories: ItemMapping[];
  };
  accounts: Mapping[];
  items: ItemMapping[];
  payments: {
    default: RequiredMapping;
    stripe: OptionalMapping;
  };
}
export interface Integration {
  companyId: string;
  connections: {
    id: string;
    platform: AccountingPlatform;
    status: DataConnectionStatus;
  }[];
  status: IntegrationStatus;
  connectedBy?: string;
  connectedAt?: string;
  mapping?: MappingFields;
  ruleId: {
    connection: string;
    pushStatus: string;
  };
  defaultInvoiceItem?: string;
}

export interface InvoiceSettings {
  dueDate?: number;
  taxRate?: number;
  note?: string;
  paymentMethods?: string[];
  contactDetails?: InvoiceContactDetails['contactDetails'];
  medicare?: Medicare;
  integration?: Integration;
}

export interface PaymentMethod {
  _id: string;
  paymentType: 'bankTransfer' | 'stripe' | 'paypal' | 'cash' | 'other';
  bankName?: string;
  accountName?: string;
  accountNumber?: string;
  code?: string;
  instructions?: string;
  email?: string;
  paypalMeLink?: string;
  stripeAccountId?: string;
  stripeConnectedBy?: string;
  stripe?: string;
  stripeFeeMethod?: string;
  stripeStatus?: 'completed' | 'incomplete';
  stripeConnectedBank?: {
    bankName?: string;
    last4?: string;
    routingNumber?: string;
  };
  stripeConnectedAt?: string;
  stripeUpdatedAt?: string;
}

export interface InvoiceContactDetails {
  contactDetails: {
    practice: {
      address: {
        value: string;
        isVisible: boolean;
      };
      mobileNumber: {
        value: string;
        isVisible: boolean;
      };
    };
    clinician: {
      mobileNumber: {
        value: string;
        isVisible: boolean;
      };
      email: {
        value: string;
        isVisible: boolean;
      };
    };
  };
}
export enum ItemOverview {
  Id = 'id',
  Name = 'name',
  Date = 'date',
  Duration = 'duration',
  Status = 'status',
  Owner = 'owner'
}

export enum ItemColumns {
  Quantity = 'quantity',
  UnitPrice = 'unitPrice',
  TaxRate = 'taxRate',
  Total = 'total'
}

export enum RecipientTypes {
  Client = 'client',
  KeyClientContact = 'keyClientContact',
  Funder = 'funder'
}

interface IRecipient {
  type: RecipientTypes;
  tags?: string[]; // for key client contacts
}

export interface IInvoiceTemplate {
  _id: string;
  accountId: string;
  name: string;
  description?: string;
  shortCode?: string;

  paymentMethods: string[];
  sendTo: IRecipient[];
  recipient: IRecipient;

  invoiceTitle?: string;
  invoiceNotes?: string;
  itemOverviews: ItemOverview[];
  itemColumns: ItemColumns[];
  dueAfter: number; // in days
  invoiceFooter?: string;

  allowDiscount: boolean;
  allowMultipleItems: boolean;

  // required fields
  invoiceId: boolean;
  issueDate: boolean;
  dueDate: boolean;

  practiceLogo: boolean;
  practiceName: boolean;
  practiceAddress: boolean;
  practicePhoneNumber: boolean;
  practiceFax: boolean;
  practiceFormalName: boolean;
  practiceRegistrationNumber: boolean;
  practiceFinanceEmail: boolean;

  recipientAddress: boolean;
  recipientEmail: boolean;
  recipientMobileNumber: boolean;
  recipientReference: boolean;
  recipientRegistrationNumber: boolean;

  medicareClaimant: boolean;
  medicareClaim: boolean;
  medicareServiceProvider: boolean;
  medicareReferral: boolean;
  createdAt: Date;
}

export interface PaymentMethodsInterface {
  accountId: string;
  createdAt: string;
  paymentMethods: PaymentMethod[];
  updatedAt: string;
  __v: number;
  _id: string;
}

export type InvoiceStatusToBeChange =
  | InvoiceStatus.Closed
  | InvoiceStatus.ConfirmPaid
  | InvoiceStatus.Voided
  | InvoiceStatus.WriteOff;

export type OnChangeInvoiceStatus = (
  _id: string,
  status: InvoiceStatusToBeChange,
  groupDetail?: Invoice['group'],
  isRecreate?: boolean
) => void;

export enum RefundStatus {
  Pending = 'pending',
  RequiresAction = 'requires_action',
  Succeeded = 'succeeded',
  Failed = 'failed',
  Canceled = 'canceled'
}

interface Refund {
  amount: number;
  status: RefundStatus;
  updatedAt: string;
}

export enum PaymentRequestStatus {
  Sent = 'sent',
  Paid = 'paid',
  Failed = 'failed',
  Void = 'void',
  PartialRefunded = 'partiallyRefunded',
  Refunded = 'refunded'
}

export enum PaymentType {
  Deposit = 'deposit',
  Remaining = 'remaining',
  Cancellation = 'cancellation',
  Full = 'full',
  Custom = 'custom'
}

export enum PaymentRequestMethod {
  Immediate = 'immediate',
  Request = 'request'
}

export enum PaymentRequestEventType {
  Created = 'paymentRequest.created',
  Attempted = 'paymentRequest.attempted',
  Completed = 'paymentRequest.completed',
  Failed = 'paymentRequest.failed',
  PaymentMethodUpdated = 'paymentRequest.paymentMethod.updated',
  Retried = 'paymentRequest.retried',
  RefundCreated = 'paymentRequest.refund.created',
  RefundCompleted = 'paymentRequest.refund.completed',
  RefundFailed = 'paymentRequest.refund.failed'
}

export interface EventLog {
  event: PaymentRequestEventType;
  idempotencyKey: string;
  timestamp: string;
  source: EventSource;
}

export interface PaymentRequest {
  _id: string;
  accountId: string;
  clientRecordId: string;
  status: PaymentRequestStatus;
  type: PaymentType;
  method: PaymentRequestMethod;
  appointmentId?: string;
  invoiceId?: string;
  amount: number;
  description?: string;
  stripePaymentIntentId?: string;
  stripeChargeId?: string;
  stripeFee?: number;
  lastPaymentError?: string;
  systemRetryCount: number;
  eventLogs: EventLog[];
  refunds?: Refund[];
}

export const InvoiceHistoryActionNameMapping: Record<InvoiceHistoryObject, string> = {
  writeOff: 'Write Off',
  cash: 'Cash',
  onlineTransfer: 'Online Transfer',
  creditNote: 'Credit Note',
  others: 'Others',
  stripePayment: 'Payment',
  refund: 'Refund',
  claim: 'Claim',
  status: 'Status',
  sent: 'Sent',
  generated: 'Generated',
  manualRefund: 'Manual Refund',
  writeOffClaim: 'Write Off Claim',
  amount: 'Amount',
  abandon: 'Abandon'
};
