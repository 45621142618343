import classNames from 'classnames';
import { ScaleType } from '../../../pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';
import { EMOJI_MAP } from './EmojiMap';
import styles from './EmojiScalePreview.module.scss';

interface EmojiScalePreviewProps {
  options: {
    key: string;
    description: string;
    value: string | number;
  }[];
  scaleType: ScaleType;
}

const EmojiScalePreview = ({ options, scaleType }: EmojiScalePreviewProps) => {
  return (
    <div className={classNames(styles.container, scaleType === ScaleType.EmojiRating && styles.ratingContainer)}>
      {options.map(({ key, description }, index) => (
        <div className={styles.item} key={key}>
          <div
            className={classNames(
              styles.emojiButton,
              scaleType === ScaleType.EmojiReaction && styles.reaction,
              scaleType === ScaleType.EmojiRating && index === 2 && styles.active,
              scaleType === ScaleType.EmojiReaction && index === 0 && styles.active,
              scaleType === ScaleType.EmojiReaction && index === 1 && styles.activeDoubleTaps
            )}
          >
            <div className={styles.emoji}>{EMOJI_MAP[key]}</div>
            {scaleType === ScaleType.EmojiReaction && <div className={styles.text}>{description}</div>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EmojiScalePreview;
