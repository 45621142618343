import { notification } from 'antd';
import { LetterTemplate } from 'interfaces/Letters/letterTemplate';
import { useCallback, useEffect, useState } from 'react';
import { getLetterTemplate } from 'utils/http/DocumentService/Letters/lettersTemplate';

export const useFetchLetterTemplate = (token?: string, accountId?: string, letterTemplateId?: string) => {
  const [letterTemplate, setLetterTemplate] = useState<LetterTemplate>();
  const [loading, setLoading] = useState(true);

  const fetchLetterTemplate = useCallback(async () => {
    setLoading(true);
    try {
      if (token && accountId && letterTemplateId && letterTemplateId !== 'new') {
        const response = await getLetterTemplate(token, accountId, letterTemplateId);
        const data = await response.json();
        setLetterTemplate(data.letterTemplate);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      notification.error({ message: 'Failed to load letter template' });
    }
  }, [token, accountId, letterTemplateId]);

  useEffect(() => {
    fetchLetterTemplate();
  }, [fetchLetterTemplate]);

  return { letterTemplate, isLoadingLetterTemplate: loading, fetchLetterTemplate };
};
