import { notification, Skeleton } from 'antd';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { ToggleList } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import {
  NotificationPreference,
  NotificationSettingsInterface,
  useFetchNotificationSettings
} from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';
import { useEffect, useState } from 'react';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import { postNotificationSettings } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import styles from './NotificationSettings.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import NotificationSettingItem from './components/NotificationSettingItem/NotificationSettingItem';

const NotificationSettings = () => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { notificationSettings, isNotificationSettingsLoading } = useFetchNotificationSettings(token, accountId);

  const [notificationSettingsForm, setNotificationSettingsForm] = useState<NotificationSettingsInterface>({
    invoice: NotificationPreference.None,
    reportsAndLetters: NotificationPreference.Email,
    accountInvite: NotificationPreference.All,
    forms: NotificationPreference.All,
    appointmentConfirmation: NotificationPreference.Email,
    appointmentReminder: NotificationPreference.Email,

    practitionerAppointmentConfirmation: NotificationPreference.Email,
    practitionerAppointmentCancellation: NotificationPreference.None,
    practitionerGroupAppointment: NotificationPreference.AllStaff
  });
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');

  useEffect(() => {
    setNotificationSettingsForm(notificationSettings);
  }, [notificationSettings]);

  const handleNotificationSettingsChange = (toggle: ToggleList) => {
    const { id } = toggle;
    const [category, preference] = id!.split(' ');

    setNotificationSettingsForm({
      ...notificationSettingsForm,
      [category]: preference as NotificationPreference
    });
  };

  const handleSubmit = async () => {
    if (token && accountId) {
      try {
        setSubmitStatus('active');

        await postNotificationSettings(token, accountId, notificationSettingsForm);

        setSubmitStatus('finished');
        notification.success({
          message: 'Notification settings updated successfully'
        });
      } catch (error) {
        notification.error({
          message: 'Error updating notification settings'
        });
      } finally {
        setTimeout(() => {
          setSubmitStatus('');
        }, 2000);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerCopy}>
          <div className={styles.title}>Notification Settings</div>
          <div className={styles.description}>
            Control your service's communication settings for clients and practitioners
          </div>
        </div>
        <div className={styles.headerCTA}>
          <ButtonAlt
            id={'saveNotificationSettings'}
            onClick={handleSubmit}
            status={submitStatus}
            disabled={isNotificationSettingsLoading}
          >
            Save and Apply
          </ButtonAlt>
        </div>
      </div>
      <div className={styles.subHeader}>
        Client Communication Settings{' '}
        <HelpOutLineWithTooltips
          id={'client-communication-preference-settings'}
          desc={
            "Set how you'd like key documents and activities delivered. These settings are the default for your account. Any individual client profile that has either email, sms or both turned off will have those rules respected"
          }
        />
      </div>
      <div className={styles.form}>
        {isNotificationSettingsLoading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          Object.entries(notificationSettingsForm)
            .filter(
              ([key]) =>
                ![
                  'practitionerAppointmentConfirmation',
                  'practitionerAppointmentCancellation',
                  'practitionerGroupAppointment'
                ].includes(key)
            )
            .map(([notificationSettingKey, preference], i) => {
              return (
                <NotificationSettingItem
                  key={`${notificationSettingKey} ${i}`}
                  notificationSettingKey={notificationSettingKey}
                  preference={preference}
                  onChangePreference={handleNotificationSettingsChange}
                />
              );
            })
        )}
      </div>
      <div className={styles.subHeader}>
        Practitioner Communication Settings{' '}
        <HelpOutLineWithTooltips
          id={'practitioner-communication-preference-settings'}
          desc="These controls are specifically for notifications for your team in relation to services being scheduled. They are set at the workspace level and will be applied to all staff. Turning off all notifications is not recommended unless you have connected calendars set up."
        />
      </div>
      <div className={styles.form}>
        {isNotificationSettingsLoading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          [
            'practitionerAppointmentConfirmation',
            'practitionerAppointmentCancellation',
            'practitionerGroupAppointment'
          ].map((notificationSettingKey, i) => {
            return (
              <NotificationSettingItem
                key={`${notificationSettingKey} ${i}`}
                notificationSettingKey={notificationSettingKey}
                preference={notificationSettingsForm[notificationSettingKey]}
                onChangePreference={handleNotificationSettingsChange}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default NotificationSettings;
