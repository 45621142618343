import { AddressBookListRequestPayload } from 'pages/AddressBook/AddressBookList/components/AddressBookListContent/components/constants';
import { AddressBookFromAPI, AddressBookListData } from 'pages/AddressBook/Interfaces/AddressBook';
import queryString from 'query-string';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';

export const defaultAddressBookList = {
  addressBooks: [],
  count: {
    funder: 0,
    referrer: 0,
    organisation: 0,
    individual: 0
  },
  paging: {
    page: 1,
    perPage: 20,
    totalFilteredItems: 0,
    totalItems: 0
  }
};

export const addressBookApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAddressBooks: builder.query<AddressBookListData, AddressBookListRequestPayload>({
      query: ({ accountId, params }) => {
        const stringifiedQueryString = params ? `?${queryString.stringify(params)}` : '';
        return {
          url: `/accounts/${accountId}/address-books/${stringifiedQueryString}`
        };
      },
      transformResponse: (response: AddressBookListData, _meta) => response || defaultAddressBookList,
      providesTags: [CPSTagTypes.AddressBookList]
    }),
    getAddressBook: builder.query<AddressBookFromAPI, { accountId: string; addressBookId: string }>({
      query: ({ accountId, addressBookId }) => ({
        url: `/accounts/${accountId}/address-books/${addressBookId}`
      }),
      providesTags: [CPSTagTypes.AddressBook]
    }),
    createAddressBook: builder.mutation({
      query: ({ accountId, payload }) => ({
        url: `/accounts/${accountId}/address-books`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [CPSTagTypes.AddressBookList]
    }),
    updateAddressBook: builder.mutation({
      query: ({ accountId, addressBookId, payload }) => ({
        url: `/accounts/${accountId}/address-books/${addressBookId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [CPSTagTypes.AddressBookList]
    }),
    deleteAddressBook: builder.mutation({
      query: ({ accountId, addressBookId }) => ({
        url: `/accounts/${accountId}/address-books/${addressBookId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [CPSTagTypes.AddressBookList]
    })
  })
});

export const {
  useGetAddressBooksQuery,
  useGetAddressBookQuery,
  useCreateAddressBookMutation,
  useUpdateAddressBookMutation,
  useDeleteAddressBookMutation
} = addressBookApiSlice;
