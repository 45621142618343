import styles from './Checkbox.module.scss';
import { ChangeEvent } from 'react';

interface CheckboxProps {
  label: string;
  subLabel?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const Checkbox = ({ label, subLabel, checked, onChange }: CheckboxProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };
  return (
    <label className={styles.checkboxContainer}>
      <div className={styles.inputContainer}>
        <input className={styles.checkbox} type="checkbox" checked={checked} onChange={handleChange} />
      </div>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{label}</div>
        {subLabel && <div className={styles.subLabel}>{subLabel}</div>}
      </div>
    </label>
  );
};

export default Checkbox;
