import { useState, useEffect } from 'react';

export interface RecentlyViewItem {
  value: string;
  label: string;
  timestamp?: number;
}

interface ViewedItemProps {
  idToken: string;
  isEdgeAdminView: boolean;
  recentlyViewed: RecentlyViewItem[];
}

export const useGetRecentlyViewed = ({
  storageName,
  clinicianProfileId,
  isEdgeAdminView,
  max
}: {
  storageName: string;
  clinicianProfileId: string;
  isEdgeAdminView: boolean;
  max: number;
}) => {
  const [recentlyViewed, setRecentlyViewed] = useState<RecentlyViewItem[]>([]);

  useEffect(() => {
    const checkData = () => {
      const viewed = localStorage.getItem(storageName);

      const createNewRecentlyViewed = (viewedArray: ViewedItemProps[]) => {
        localStorage.setItem(storageName, JSON.stringify(viewedArray));
        setRecentlyViewed([]);
      };

      const newRecentlyViewed = { idToken: clinicianProfileId, isEdgeAdminView: isEdgeAdminView, recentlyViewed: [] };
      if (viewed) {
        const viewedArray: ViewedItemProps[] = JSON.parse(viewed);
        const filteredArray = viewedArray.find(
          (obj) => obj.idToken === clinicianProfileId && obj.isEdgeAdminView === isEdgeAdminView
        );
        if (filteredArray) {
          const recentlyViewedArray = filteredArray.recentlyViewed.map((item) => {
            return { value: item.value, label: item.label ?? '', timestamp: item.timestamp ?? undefined };
          });
          setRecentlyViewed(recentlyViewedArray);
        } else {
          viewedArray.push(newRecentlyViewed);
          createNewRecentlyViewed(viewedArray);
        }
      } else {
        const viewedArray = [newRecentlyViewed];
        createNewRecentlyViewed(viewedArray);
      }
    };

    checkData();
    window.addEventListener('storage', checkData);
    return () => {
      window.removeEventListener('storage', checkData);
    };
  }, [clinicianProfileId, isEdgeAdminView, storageName]);

  return recentlyViewed.slice(0, max);
};

export const useSetRecentlyViewed = ({
  isLoading,
  storageName,
  id,
  clinicianProfileId,
  isEdgeAdminView,
  name,
  recordId
}: {
  isLoading: boolean;
  storageName: string;
  id?: string;
  clinicianProfileId: string;
  isEdgeAdminView: boolean;
  name: string;
  recordId: string;
}) => {
  useEffect(() => {
    if (!isLoading) {
      const viewed = localStorage.getItem(storageName);
      const itemId = id ?? '';
      const newSubTabItem = { value: itemId, label: name, timestamp: Date.now() };
      const newViewRegistered = {
        idToken: clinicianProfileId,
        isEdgeAdminView: isEdgeAdminView,
        recentlyViewed: [newSubTabItem]
      };
      if (viewed) {
        const viewedArray: ViewedItemProps[] = JSON.parse(viewed);
        const isFilteredItem = (item: ViewedItemProps) =>
          item.idToken === clinicianProfileId && item.isEdgeAdminView === isEdgeAdminView;
        const filteredItem = viewedArray.find((item) => isFilteredItem(item));

        if (filteredItem) {
          const index = viewedArray.findIndex((item) => isFilteredItem(item));
          const isIdIncluded = filteredItem.recentlyViewed.find((item) => item.value === recordId);

          if (!isIdIncluded) {
            if (filteredItem.recentlyViewed.length === 10) {
              filteredItem.recentlyViewed.pop();
            }
            filteredItem.recentlyViewed.unshift(newSubTabItem);
            viewedArray[index] = filteredItem;
            localStorage.setItem(storageName, JSON.stringify(viewedArray));
          } else {
            const updatedArrayItem = filteredItem.recentlyViewed.filter((item) => item.value !== recordId);
            updatedArrayItem.unshift(isIdIncluded);
            viewedArray[index].recentlyViewed = updatedArrayItem;
            localStorage.setItem(storageName, JSON.stringify(viewedArray));
          }
        } else {
          viewedArray.push(newViewRegistered);
          localStorage.setItem(storageName, JSON.stringify(viewedArray));
        }
      } else {
        const newViewArray = [newViewRegistered];
        localStorage.setItem(storageName, JSON.stringify(newViewArray));
      }
      window.dispatchEvent(new Event('storage'));
    }
  }, [isLoading, name, recordId, isEdgeAdminView, clinicianProfileId, storageName, id]);
};
