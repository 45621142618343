import moment from 'moment';
import { useMemo, useRef } from 'react';
import {
  selectAppointmentData,
  setSelectedTime,
  startInlineCalendarValidation
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './AppointmentTimePicker.module.scss';
import TimePicker, { TimePickerHandle } from './TimePicker';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

interface AppointmentTimePickerProps {
  step?: number; // Time step in the option list - in minute
  minTime?: number; // The minimum time of an appointment
  duration?: number; // Default duration of an appointment
}

const AppointmentTimePicker = ({ step = 15, minTime = 15, duration = 50 }: AppointmentTimePickerProps) => {
  const dispatch = useAppDispatch();

  const endTimeRef = useRef<TimePickerHandle>(null);
  const inlineCalendarValidation = useAppSelector(startInlineCalendarValidation);

  const { selectedAppointmentType, selectedTime } = useAppSelector(selectAppointmentData);

  const startTimeOptions = useMemo(() => {
    const result: { id: string; label: string }[] = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute = minute + step) {
        const time = moment(`${hour}:${minute}`, 'HH:mm');
        result.push({
          id: time.format('HH:mm'),
          label: time.format('hh:mm A')
        });
      }
    }
    return result;
  }, [step]);

  const onSelectStartTime = (value: string) => {
    const newStartTime = moment(value, 'HH:mm');
    dispatch(
      setSelectedTime({
        ...selectedTime,
        startTime: value,
        ...(moment(selectedTime.endTime, 'HH:mm').diff(newStartTime, 'minutes') !== duration && {
          endTime: newStartTime.add(duration, 'minutes').format('HH:mm')
        })
      })
    );
  };

  const onSelectEndTime = (value: string) => {
    const newEndTime = moment(value, 'HH:mm');
    if (newEndTime.diff(moment(selectedTime.startTime, 'HH:mm'), 'minutes') >= minTime) {
      dispatch(
        setSelectedTime({
          ...selectedTime,
          endTime: value
        })
      );
    } else {
      endTimeRef?.current?.resetInput(selectedTime.endTime);
    }
  };

  return (
    <div className={styles.container}>
      <TimePicker
        options={startTimeOptions}
        selectedTime={selectedTime.startTime}
        onSelect={onSelectStartTime}
        customPlaceHolder={'Start Time'}
        error={inlineCalendarValidation && !selectedTime.startTime ? 'Please select start time' : ''}
      />
      <div className={styles.separator}>-</div>
      {selectedAppointmentType?.participantType !== ParticipantType.Activity ||
      selectedAppointmentType?.duration.editable ? (
        <TimePicker
          ref={endTimeRef}
          options={startTimeOptions}
          selectedTime={selectedTime.endTime}
          onSelect={onSelectEndTime}
          customPlaceHolder={'End Time'}
          error={inlineCalendarValidation && !selectedTime.endTime ? 'Please select end time' : ''}
        />
      ) : (
        moment(selectedTime.endTime, 'HH:mm').format('hh:mm A')
      )}
    </div>
  );
};

export default AppointmentTimePicker;
