import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import TextEditorWidget from './components/TextEditorWidget/TextEditorWidget';
import styles from './TextEditorWrapper.module.scss';

interface TextEditorWrapperProps {
  onClickWidgetMenu: () => void;
}

const TextEditorWrapper = ({ onClickWidgetMenu }: TextEditorWrapperProps) => {
  return (
    <>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>
          <i className={`material-icons ${styles.titleIcon}`}>title</i>
          Click to add text
        </div>
        <ButtonAlt
          className={styles.insertTemplateBtn}
          variant={'text'}
          size={'small'}
          icon={'add'}
          onClick={onClickWidgetMenu}
          iconPostFix
        >
          Insert Template
        </ButtonAlt>
      </div>
      <TextEditorWidget />
    </>
  );
};

export default TextEditorWrapper;
