import styles from './PerAppointment.module.scss';
import MaterialToggleSwitch from 'components/MaterialFieldComponent/MaterialToggleSwitch/MaterialToggleSwitch';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { useFormikContext } from 'formik';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { Tooltip } from 'react-tooltip';

interface PerAppointmentProps {
  isClientMedicareValid: boolean;
  isPracticeConnectedMedicare: boolean;
}

const APPOINTMENT_SEND_AT_OPTIONS = [
  { label: 'On booking confirmed', value: 'onConfirmed' },
  { label: '3 days before', value: '-3' },
  { label: '2 days before', value: '-2' },
  { label: 'Day before', value: '-1' },
  { label: 'Same day', value: '0' },
  { label: 'Day after', value: '1' }
];

const DUE_DATE_OPTIONS = [
  { label: 'Immediate (Today)', value: 0 },
  { label: 'Tomorrow', value: 1 },
  { label: '2 Days', value: 2 },
  { label: '3 Days', value: 3 },
  { label: '7 Days', value: 7 },
  { label: '14 Days', value: 14 },
  { label: '28 Days', value: 28 }
];

const PerAppointment = ({ isClientMedicareValid, isPracticeConnectedMedicare }: PerAppointmentProps) => {
  const disableIncludeMedicareDetails = !isClientMedicareValid || !isPracticeConnectedMedicare;
  const { medicareRebateFeatureToggle, isHelmFeatureToggle, isCaWFeatureToggle } = useGetFeatureToggle();

  const appointmentSendAtOptions =
    isHelmFeatureToggle || isCaWFeatureToggle
      ? [
          {
            label: 'On appointment delivered (marked as attended, not attended by client, or cancelled short notice)',
            value: 'onAttended'
          },
          ...APPOINTMENT_SEND_AT_OPTIONS
        ]
      : APPOINTMENT_SEND_AT_OPTIONS;

  const { values, setFieldValue } = useFormikContext<Required<clientRecordsInterface['invoiceSummary']>['settings']>();

  return (
    <div className={styles.container}>
      {medicareRebateFeatureToggle && (
        <div className={styles.includeMedicareDetails} data-tooltip-id="disable-medicare-details">
          Include Medicare Details
          <MaterialToggleSwitch
            customContainerClass={styles.switchContainer}
            name="automatedInvoicing.includeMedicareDetails"
            value={!!values.automatedInvoicing.includeMedicareDetails}
            onChange={(value) => setFieldValue('automatedInvoicing.includeMedicareDetails', value)}
            disabled={disableIncludeMedicareDetails}
          />
          {disableIncludeMedicareDetails && (
            <Tooltip id="disable-medicare-details" className={styles.tooltips}>
              {!isPracticeConnectedMedicare
                ? 'Medicare not connected, visit Control Panel > System Connections to enable.'
                : 'This medicare setting for this client profile is OFF. Please turn ON and add the required Medicare details to include on their invoices.'}
            </Tooltip>
          )}
        </div>
      )}
      <div className={styles.label}>Send invoice</div>
      <FormikSelect
        id={'automatedInvoicing.issueAt'}
        className={styles.select}
        name="automatedInvoicing.issueAt"
        styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
        options={appointmentSendAtOptions}
      />
      <div className={styles.label}>Due date</div>
      <FormikSelect
        id={'automatedInvoicing.dueAt'}
        className={styles.select}
        name="automatedInvoicing.dueAt"
        styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
        options={DUE_DATE_OPTIONS}
      />
    </div>
  );
};

export default PerAppointment;
