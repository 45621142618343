import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import LoadingDot from 'components/LoadingDot/LoadingDot';
import { HelmControlInterface, ProfileInterface } from 'interfaces/Profile/Profile';
import HelmControl from './components/HelmControl/HelmControl';
import CollectData from './components/CollectData/CollectData';
import NotificationSettings from './components/NotificationSettings/NotificationSettings';
import ProfileAboutYou from './components/ProfileAboutYou/ProfileAboutYou';
import ProfileMedicare from './components/ProfileMedicare/ProfileMedicare';
import ProfilePractice from './components/ProfilePractice/ProfilePractice';
import ProfileWorkingSchedule from './components/ProfileWorkingSchedule/ProfileWorkingSchedule';
import SystemConnection from './components/SystemConnection/SystemConnection';
import GeneralSettings from './components/GeneralSettings/GeneralSettings';

import styles from './ControlPanelContentDisplay.module.scss';
import Packages from '../../../../../../Packages/Packages';
import PractitionerReport from 'pages/PractitionerReport/PractitionerReport';

interface ControlPanelContentDisplayProps {
  profile?: ProfileInterface;
  controlPanelTab?: string;
  onUpdateProfile: (newProfile: ProfileInterface) => void;
  aboutYouPage: boolean;
  helmControlPage: boolean;
  collectDataPage?: boolean;
  medicarePage: boolean;
  workingSchedulePage: boolean;
  practiceProfilePage: boolean;
  systemConnectionPage: boolean;
  notificationSettingsPage: boolean;
  practitionerReportPage: boolean;
  generalSettingsPage?: boolean;
  fetchProfile?: () => void;
  isMobileOptional?: boolean;
}

const ControlPanelContentDisplay = ({
  profile,
  controlPanelTab,
  onUpdateProfile,
  aboutYouPage,
  helmControlPage,
  collectDataPage,
  medicarePage,
  workingSchedulePage,
  practiceProfilePage,
  systemConnectionPage,
  notificationSettingsPage,
  generalSettingsPage,
  practitionerReportPage,
  fetchProfile,
  isMobileOptional
}: ControlPanelContentDisplayProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();

  const getContentComponents = (profile: ProfileInterface, controlPanelTab?: string) => {
    switch (controlPanelTab) {
      case 'practice':
        return practiceProfilePage && <ProfilePractice />;

      case 'schedule':
        return (
          workingSchedulePage && (
            <ProfileWorkingSchedule profile={profile} onUpdateProfile={onUpdateProfile} fetchProfile={fetchProfile} />
          )
        );

      case 'system-connections':
        return systemConnectionPage && <SystemConnection />;

      case 'medicare':
        return medicarePage && <ProfileMedicare />;

      case 'engage-profile':
        return (
          helmControlPage && (
            <HelmControl
              profile={{ ...(profile.helmControl || {}), isProfileListed: profile.isProfileListed }}
              id={profile._id}
              fetchProfile={fetchProfile}
              onUpdateProfile={(value: HelmControlInterface) => onUpdateProfile({ ...profile, helmControl: value })}
            />
          )
        );
      case 'collect-data':
        return collectDataPage && <CollectData />;

      case 'notification-settings':
        return notificationSettingsPage && <NotificationSettings />;

      case 'packages':
        return practiceProfilePage && <Packages />;

      case 'about-you':
      case 'profile':
        return (
          aboutYouPage && (
            <ProfileAboutYou
              profile={profile}
              onUpdateProfile={onUpdateProfile}
              fetchProfile={fetchProfile}
              isMobileOptional
            />
          )
        );

      case 'general-settings':
        return generalSettingsPage && <GeneralSettings />;

      case 'services-report':
        return practitionerReportPage && <PractitionerReport />;

      default:
        return isEdgeAdminView
          ? practiceProfilePage && <ProfilePractice />
          : aboutYouPage && (
              <ProfileAboutYou
                profile={profile}
                onUpdateProfile={onUpdateProfile}
                fetchProfile={fetchProfile}
                isMobileOptional
              />
            );
    }
  };

  return !profile ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>{getContentComponents(profile, controlPanelTab)}</div>
  );
};

export default ControlPanelContentDisplay;
