import SubTab from 'components/v2/SubTab/SubTab';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from '../SubTabLayout.module.scss';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGetInvoiceTemplatesListQuery } from 'redux/endpoints/billingServices/invoiceTemplates';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import ActionDropdown, { ActionDropdownType } from '../ActionDropdown/ActionDropdown';
import { useGetInvoicesList } from 'redux/invoices/useGetInvoices';
import { InvoiceTab } from 'pages/InvoicesV2/constants';
import FinanceSettingsDropdown from './components/FinanceSettingsDropdown';
import { useGetInvoicePayments, useGetScheduledPayments } from 'redux/features/invoicePayments/useGetInvoicePayments';
import { InvoicePaymentsTab } from 'redux/features/invoicePayments/invoicePaymentsSlice';
import { useGetWriteOffInvoicesList } from 'redux/invoices/useGetWriteOffInvoices';
import { useAppDispatch } from 'redux/hooks';
import {
  PendingClaimTabs,
  setActiveTab as setPendingClaimActiveTab
} from 'redux/features/pendingClaimInvoices/pendingClaimInvoiceSlice';
import { useGetPendingClaims } from 'redux/features/pendingClaimInvoices/useGetPendingClaims';
import { setActiveTab as setSubmittedClaimActiveTab } from 'redux/features/submittedClaims/submittedClaimSlice';
import { SubmittedClaimTabs } from 'redux/features/submittedClaims/submittedClaimSlice';
import { useGetSubmittedClaims } from 'redux/features/submittedClaims/useGetSubmittedClaims';
import { resetAll as resetInvoiceGenerationWithTemplateForm } from 'redux/invoices/createInvoiceWithTemplateSlice';

const InvoicesSubTab = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isEdgePlanUser } = useGetAccountPackage();
  const { isEdgeUserView, isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { medicareRebateFeatureToggle, isInvoiceTemplateEnabled, isInvoiceListV2Enabled, isPaymentListEnabled } =
    useGetFeatureToggle();
  const { FORMS, INVOICES } = useRoutesGenerator();
  const { invoiceCounts, totalItems, isInvoicesLoading } = useGetInvoicesList();
  const { isWriteOffInvoicesLoading, writeOffInvoiceTotalItems, writeOffInvoiceCounts } = useGetWriteOffInvoicesList();
  const { totalItems: totalPendingClaimsDataReadyCount } = useGetPendingClaims(PendingClaimTabs.DataReady);
  const { totalItems: totalPendingClaimsDataNotReadyCount } = useGetPendingClaims(PendingClaimTabs.DataNotReady);
  const { totalItems: totalPendingClaimsAbandonedCount } = useGetPendingClaims(PendingClaimTabs.Abandoned);
  const { totalItems: totalSubmittedClaimsCount } = useGetSubmittedClaims(SubmittedClaimTabs.Submitted);
  const { totalItems: totalSuccessClaimsCount } = useGetSubmittedClaims(SubmittedClaimTabs.Success);
  const { totalItems: totalRejectedClaimsCount } = useGetSubmittedClaims(SubmittedClaimTabs.Rejected);
  const { totalItems: totalWriteOffClaimsCount } = useGetSubmittedClaims(SubmittedClaimTabs.WriteOff);
  const { totalItems: totalAbandonedClaimsCount } = useGetSubmittedClaims(SubmittedClaimTabs.Abandoned);

  const totalInvoiceCount = isInvoicesLoading ? 0 : totalItems;
  const totalWriteOffInvoiceCount = isWriteOffInvoicesLoading ? 0 : writeOffInvoiceTotalItems;

  const isInvoicesPage = isEdgePlanUser
    ? location.pathname.split('/')[2] === 'invoices'
    : location.pathname.split('/')[1] === 'invoices';

  const getFilterType: string | undefined = isInvoicesPage
    ? isEdgePlanUser
      ? (location.pathname.split('/')[3] as string) ?? ''
      : (location.pathname.split('/')[2] as string) ?? ''
    : undefined;
  const [filterType, setFilterType] = useState<string | undefined>(getFilterType);

  const allowAddNewInvoiceTemplate = isInvoiceTemplateEnabled && (isEdgeAdminView || isEdgeReceptionistView);

  const { totalItems: totalCollectedPaymentItems, isInvoicePaymentsLoading: isCollectedPaymentsLoading } =
    useGetInvoicePayments(InvoicePaymentsTab.Collected);
  const { totalScheduledPaymentItems, isScheduledPaymentsLoading } = useGetScheduledPayments(
    InvoicePaymentsTab.Scheduled
  );
  const { totalItems: totalFailedPaymentItems, isInvoicePaymentsLoading: isFailedPaymentsLoading } =
    useGetInvoicePayments(InvoicePaymentsTab.Failed);
  const { totalItems: totalCancelledPaymentItems, isInvoicePaymentsLoading: isCancelledPaymentsLoading } =
    useGetInvoicePayments(InvoicePaymentsTab.Cancelled);
  const { totalItems: totalFundedPaymentItems, isInvoicePaymentsLoading: isFundedPaymentsLoading } =
    useGetInvoicePayments(InvoicePaymentsTab.Refunded);

  useEffect(() => {
    setFilterType(getFilterType);
  }, [getFilterType]);

  const createNewInvoiceHandler = () => {
    navigate(`${INVOICES.BASE}/new`);
  };

  const createNewTemplateHandler = () => {
    navigate(FORMS.NEW_INVOICE_TEMPLATE_EDITOR);
  };

  const baseHref = INVOICES.BASE;

  const navigateToCreateInvoiceWithTemplate = (invoiceTemplateId: string) => {
    dispatch(resetInvoiceGenerationWithTemplateForm());
    navigate(`${INVOICES.BASE}/new`, { state: { invoice: { template: { _id: invoiceTemplateId } } } });
  };

  const { accountId } = useGetAccountId();
  const { data, isLoading, isFetching } = useGetInvoiceTemplatesListQuery({
    accountId
  });

  const onChangeClaimSubTab = (value: string) => {
    switch (value) {
      case 'pending-claims-data-ready': {
        dispatch(setPendingClaimActiveTab(PendingClaimTabs.DataReady));
        break;
      }
      case 'pending-claims-abandoned': {
        dispatch(setPendingClaimActiveTab(PendingClaimTabs.Abandoned));
        break;
      }
      case 'pending-claims-data-not-ready': {
        dispatch(setPendingClaimActiveTab(PendingClaimTabs.DataNotReady));
        break;
      }
      case 'submitted-claims': {
        dispatch(setSubmittedClaimActiveTab(SubmittedClaimTabs.Submitted));
        break;
      }
      case 'success-claims': {
        dispatch(setSubmittedClaimActiveTab(SubmittedClaimTabs.Success));
        break;
      }
      case 'rejected-claims': {
        dispatch(setSubmittedClaimActiveTab(SubmittedClaimTabs.Rejected));
        break;
      }
      case 'write-off-claims': {
        dispatch(setSubmittedClaimActiveTab(SubmittedClaimTabs.WriteOff));
        break;
      }
      case 'abandoned-claims': {
        dispatch(setSubmittedClaimActiveTab(SubmittedClaimTabs.Abandoned));
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.flexbox}>
          <div className={styles.title}>CREATE NEW</div>
          <ActionDropdown
            onAddNew={createNewInvoiceHandler}
            onAddNewTemplate={allowAddNewInvoiceTemplate ? createNewTemplateHandler : undefined}
            onNavigateToCreateWithTemplate={navigateToCreateInvoiceWithTemplate}
            templateList={data?.invoiceTemplates}
            type={ActionDropdownType.Invoice}
            isTemplateLoading={isLoading || isFetching}
          />
        </div>
      </div>

      <div className={styles.section}>
        <SubTab
          title="VIEW INVOICES"
          options={[
            ...(isInvoiceListV2Enabled
              ? [
                  {
                    label: 'All',
                    value: '',
                    href: baseHref,
                    number: invoiceCounts.total,
                    matchingRecordCount: totalInvoiceCount,
                    hideMatchingRecordCount:
                      !(filterType === InvoiceTab.All || !filterType) || invoiceCounts.total === totalInvoiceCount
                  },
                  {
                    label: 'Recent',
                    value: 'recent',
                    href: `${baseHref}/recent/tab`,
                    number: invoiceCounts.recent,
                    matchingRecordCount: totalInvoiceCount,
                    hideMatchingRecordCount:
                      filterType !== InvoiceTab.Recent || invoiceCounts.recent === totalInvoiceCount
                  }
                ]
              : [{ label: 'Summary', value: '', href: baseHref }])
          ]}
          activeOption={filterType}
        />
      </div>
      {isInvoiceListV2Enabled && (
        <div className={styles.section}>
          <SubTab
            title="VIEW WRITE OFFS"
            options={[
              {
                label: 'Write offs',
                value: 'write-offs',
                href: `${baseHref}/write-offs`,
                number: writeOffInvoiceCounts.total,
                matchingRecordCount: totalWriteOffInvoiceCount
              }
            ]}
            activeOption={filterType}
          />
        </div>
      )}
      {isPaymentListEnabled && (
        <div className={styles.section}>
          <SubTab
            title="PAYMENTS"
            options={[
              {
                label: 'Collected',
                value: 'collected-payments',
                href: `${baseHref}/collected-payments`,
                number: isCollectedPaymentsLoading ? 0 : totalCollectedPaymentItems
              },
              {
                label: 'Scheduled',
                value: 'scheduled-payments',
                href: `${baseHref}/scheduled-payments`,
                number: isScheduledPaymentsLoading ? 0 : totalScheduledPaymentItems
              },
              {
                label: 'Failed',
                value: 'failed-payments',
                href: `${baseHref}/failed-payments`,
                number: isFailedPaymentsLoading ? 0 : totalFailedPaymentItems
              },
              {
                label: 'Cancelled',
                value: 'cancelled-payments',
                href: `${baseHref}/cancelled-payments`,
                number: isCancelledPaymentsLoading ? 0 : totalCancelledPaymentItems
              },
              {
                label: 'Refunded',
                value: 'refunded-payments',
                href: `${baseHref}/refunded-payments`,
                number: isFundedPaymentsLoading ? 0 : totalFundedPaymentItems
              }
            ]}
            activeOption={filterType}
          />
        </div>
      )}

      {medicareRebateFeatureToggle && !isEdgeUserView && (
        <>
          <div className={styles.section}>
            <SubTab
              title="VIEW PENDING CLAIMS"
              options={[
                {
                  label: 'Ready',
                  value: 'pending-claims-data-ready',
                  href: `${baseHref}/pending-claims-data-ready`,
                  number: totalPendingClaimsDataReadyCount
                },
                {
                  label: 'Not Ready',
                  value: 'pending-claims-data-not-ready',
                  href: `${baseHref}/pending-claims-data-not-ready`,
                  number: totalPendingClaimsDataNotReadyCount
                },
                {
                  label: 'Abandoned',
                  value: 'pending-claims-abandoned',
                  href: `${baseHref}/pending-claims-abandoned`,
                  number: totalPendingClaimsAbandonedCount
                }
              ]}
              activeOption={filterType}
              onChange={onChangeClaimSubTab}
            />
          </div>

          <div className={styles.section}>
            <SubTab
              title="VIEW SUBMITTED CLAIMS"
              options={[
                {
                  label: 'Submitted',
                  value: 'submitted-claims',
                  href: `${baseHref}/submitted-claims`,
                  number: totalSubmittedClaimsCount
                },

                {
                  label: 'Success',
                  value: 'success-claims',
                  href: `${baseHref}/success-claims`,
                  number: totalSuccessClaimsCount
                },
                {
                  label: 'Rejected',
                  value: 'rejected-claims',
                  href: `${baseHref}/rejected-claims`,
                  number: totalRejectedClaimsCount
                },
                {
                  label: 'Write Off',
                  value: 'write-off-claims',
                  href: `${baseHref}/write-off-claims`,
                  number: totalWriteOffClaimsCount
                },
                {
                  label: 'Abandoned',
                  value: 'abandoned-claims',
                  href: `${baseHref}/abandoned-claims`,
                  number: totalAbandonedClaimsCount
                }
              ]}
              activeOption={filterType}
              onChange={onChangeClaimSubTab}
            />
          </div>
        </>
      )}

      {isInvoiceListV2Enabled && (
        <div className={styles.section}>
          <FinanceSettingsDropdown />
        </div>
      )}
    </div>
  );
};

export default InvoicesSubTab;
