import * as yup from 'yup';

export const CarePathwayTemplateSchema = yup.object({
  name: yup.string().required('Template name is required'),
  description: yup.mixed(),
  shortCode: yup.mixed(),
  status: yup.mixed().oneOf(['draft', 'published'], 'Invalid status').required('Status is required'),
  eligibility: yup.mixed().oneOf(['individual', 'group'], 'Invalid eligibility').required('Eligibilty is required'),
  sequence: yup.mixed().oneOf(['defined', 'any'], 'Invalid sequence').required('Sequence is required'),
  summary: yup.mixed(),
  purpose: yup.object({
    category: yup.string().required('Category is required'),
    objective: yup.string().required('Objective is required'),
    description: yup.mixed()
  }),
  appointments: yup
    .array(
      yup.object({
        appointmentTypeId: yup.string().required('Appointment type is required')
      })
    )
    .required(),
  attachments: yup
    .array(
      yup.object({
        id: yup.string().required('Attachment ID is required'),
        name: yup.string().required('Attachment name is required')
      })
    )
    .required()
});

export type CarePathwayTemplateSchemaType = yup.InferType<typeof CarePathwayTemplateSchema>;
