import { useEffect } from 'react';

import BarLineChart from './components/BarLineChart/BarLineChart';
import CardHeader from './components/CardHeader/CardHeader';
import InsightsLineChart from './components/InsightsLineChart/InsightsLineChart';
import MicroJournalEntries from './components/MicroJournalEntries/MicroJournalEntries';
import {
  selectCurrentEpisodeId,
  selectIsFetchingEpisodes,
  selectIsOutsideAllEpisodes
} from 'redux/episodes/episodeSlice';
import { useAppSelector } from 'redux/hooks';
import styles from './PatientDetailsInsights.module.scss';
import {
  useFetchAppetiteCheckInGraph,
  useFetchEnergyCheckInGraph,
  useFetchOverallCheckInGraph,
  useFetchSleepCheckInGraph
} from 'utils/hooks/graph';
import { useAuth0 } from '@auth0/auth0-react';
import ClientDetailsInsightsHeader from './components/ClientDetailsInsightsHeader/ClientDetailsInsightsHeader';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

export interface MicroJournalEntry {
  date: string;
  title: string;
  response: string;
  tags?: Record<string, string>[];
}

interface PatientDetailsInsightsProps {
  clientRecordData?: clientRecordsInterface;
  currentTab: string;
  profileId?: string;
  patientId?: string;
  token: string;
}

const redBullSleepTooltipFormatter = (value: number) => {
  return `${Math.floor(value * 2)} hours`;
};

const sleepTooltipFormatter = (value: number) => {
  return `${Math.round((value + 8) * 10) / 10} hours`;
};

const sleepTickFormatter = (value: number) => {
  const sleepValue = value + 8;

  if (sleepValue === 4) {
    return `<${sleepValue}`;
  } else if (sleepValue === 12) {
    return `${sleepValue}+`;
  } else {
    return `${sleepValue}`;
  }
};

const appetiteTickFormatter = (value: number) => {
  if (value === -18) {
    return 'Under';
  } else if (value === 18) {
    return 'Over';
  } else {
    return 'OK';
  }
};

const redBullSleepTickFormatter = (value: number) => {
  const sleepValue = value;

  if (sleepValue === 1) {
    return '< 4';
  } else if (sleepValue === 5) {
    return '10+';
  } else {
    return '6 - 7';
  }
};

const redBullAppetiteTickFormatter = (value: number) => {
  if (value === 1) {
    return 'Terrible';
  } else if (value === 5) {
    return 'Awesome';
  } else {
    return 'OK';
  }
};

const PatientDetailsInsights = ({
  clientRecordData,
  profileId,
  patientId,
  token,
  currentTab
}: PatientDetailsInsightsProps) => {
  const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isFetchingEpisodes = useAppSelector(selectIsFetchingEpisodes);
  const clientId = clientRecordData?._id || patientId || '';
  // TODO: workspaces - check if isRedBull is still valid and remove auth0 user
  const { user } = useAuth0();
  const isRedBull = user?.['https://tacklit.com/TEMPIsRedBull'];

  const {
    overallCheckInGraph,
    isOverallCheckInGraphLoading,
    fetchOverallCheckInGraph,
    selectedDateRange: overallDateRange
  } = useFetchOverallCheckInGraph({
    token,
    clientId,
    profileId
  });

  const {
    energyCheckInGraph,
    isEnergyCheckInGraphLoading,
    selectedDateRange: energyDateRange,
    fetchEnergyCheckInGraph
  } = useFetchEnergyCheckInGraph({
    token,
    clientId,
    profileId
  });

  const {
    sleepCheckInGraph,
    isSleepCheckInGraphLoading,
    selectedDateRange: sleepDateRange,
    fetchSleepCheckInGraph
  } = useFetchSleepCheckInGraph({
    token,
    clientId,
    profileId
  });

  const {
    appetiteCheckInGraph,
    isAppetiteCheckInGraphLoading,
    selectedDateRange: appetiteDateRange,
    fetchAppetiteCheckInGraph
  } = useFetchAppetiteCheckInGraph({
    token,
    clientId,
    profileId
  });

  useEffect(() => {
    if (token && !isFetchingEpisodes) {
      fetchOverallCheckInGraph(30);
      fetchEnergyCheckInGraph(30);
      fetchSleepCheckInGraph(30);
      fetchAppetiteCheckInGraph(30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, token, clientRecordData, currentEpisodeId, isOutsideAllEpisodes, isFetchingEpisodes]);

  return (
    <div className={styles.container}>
      {clientRecordData && (
        <ClientDetailsInsightsHeader
          recordId={clientRecordData?._id}
          currentTab={currentTab}
          pathProfileId={profileId}
          clientRecordData={clientRecordData}
        />
      )}
      <div className={styles.insightsContainer}>
        <div className={styles.graphWrapper}>
          <div className={styles.graphContainer}>
            <CardHeader
              defaultDateRange={overallDateRange}
              title="Overall, how are you feeling?"
              onSelectDateRange={fetchOverallCheckInGraph}
            />
            <InsightsLineChart
              data={overallCheckInGraph}
              yDomain={[1, 11]}
              yTicks={[1, 6, 11]}
              isLoading={isOverallCheckInGraphLoading}
            />
          </div>
          <div className={styles.graphContainer}>
            <CardHeader
              defaultDateRange={energyDateRange}
              title="How are your energy levels?"
              onSelectDateRange={fetchEnergyCheckInGraph}
            />
            <InsightsLineChart
              data={energyCheckInGraph}
              yDomain={[1, 11]}
              yTicks={[1, 6, 11]}
              isLoading={isEnergyCheckInGraphLoading}
            />
          </div>
        </div>
        {isRedBull ? (
          <div className={styles.graphWrapper}>
            <div className={styles.graphContainer}>
              <CardHeader
                defaultDateRange={sleepDateRange}
                title="How many hours sleep per night have you been getting?"
                onSelectDateRange={fetchSleepCheckInGraph}
              />
              <BarLineChart
                data={sleepCheckInGraph}
                tooltipFormatter={redBullSleepTooltipFormatter}
                yDomain={[1, 5]}
                yLabel=""
                yTicks={[1, 3, 5]}
                yTickFormatter={redBullSleepTickFormatter}
                isLoading={isSleepCheckInGraphLoading}
              />
            </div>
            <div className={styles.graphContainer}>
              <CardHeader
                defaultDateRange={appetiteDateRange}
                title="How have your nutrition and eating habits been?"
                onSelectDateRange={fetchAppetiteCheckInGraph}
              />
              <BarLineChart
                data={appetiteCheckInGraph}
                yDomain={[1, 5]}
                yLabel=""
                yTicks={[1, 3, 5]}
                yTickFormatter={redBullAppetiteTickFormatter}
                isLoading={isAppetiteCheckInGraphLoading}
              />
            </div>
          </div>
        ) : (
          <div className={styles.graphWrapper}>
            <div className={styles.graphContainer}>
              <CardHeader
                defaultDateRange={sleepDateRange}
                title="How have you slept the last few nights?"
                onSelectDateRange={fetchSleepCheckInGraph}
              />
              <BarLineChart
                data={sleepCheckInGraph}
                tooltipFormatter={sleepTooltipFormatter}
                yDomain={[-4, 4]}
                yLabel="Hours per night"
                yTicks={[-4, 0, 4]}
                yTickFormatter={sleepTickFormatter}
                isLoading={isSleepCheckInGraphLoading}
              />
            </div>
            <div className={styles.graphContainer}>
              <CardHeader
                defaultDateRange={appetiteDateRange}
                title="How are your appetite and eating habits?"
                onSelectDateRange={fetchAppetiteCheckInGraph}
              />
              <BarLineChart
                data={appetiteCheckInGraph}
                yDomain={[-18, 18]}
                yLabel="Eating patterns"
                yTicks={[-18, 0, 18]}
                yTickFormatter={appetiteTickFormatter}
                isLoading={isAppetiteCheckInGraphLoading}
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.dividerLine} />
      <MicroJournalEntries token={token} recordId={clientRecordData?._id} profileId={profileId} patientId={patientId} />
    </div>
  );
};

export default PatientDetailsInsights;
