import { Formik } from 'formik';
import { memo } from 'react';
import { Props } from 'react-select';
import {
  appointmentDeliverySchema,
  initialValues,
  initialValuesUpdate,
  getPhoneConnectOptions,
  ROOM_REQUIRED_OPTIONS,
  VIDEO_CONNECT_OPTIONS,
  AppointmentDeliveryFormType
} from './constants';
import FormSection from 'components/v2/FormSection/FormSection';
import Radio from 'components/Radio/Radio';
import styles from './AppointmentDeliveryForm.module.scss';
import classnames from 'classnames';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import FaceToFaceLocation from './components/FaceToFaceLocation/FaceToFaceLocation';
import { useState } from 'react';
import { useFetchRoomList } from 'utils/hooks/GetRoomList/useFetchRoomList';
import { RoomType } from 'interfaces/Schedule/Room';
import { useEffect } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { AppointmentType, DeliveryType, DELIVERY_TYPE_LABELS } from 'interfaces/Schedule/AppointmentType';
import VideoInstructions from './components/VideoInstructions/VideoInstructions';
import PhoneCallInstructions from './components/PhoneCallInstructions/PhoneCallInstructions';
import { useTranslation } from 'react-i18next';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import OtherInstructions from './components/OtherInstructions/OtherInstructions';
import PhoneCallDialClientInstructions from './components/PhoneCallDialClientInstructions/PhoneCallDialClientInstructions';
import SubmitFooter from '../SubmitFooter/SubmitFooter';

interface AppointmentDeliveryFormProps {
  data?: AppointmentType;
  errMess?: string;
  onSubmit: (values: Partial<AppointmentType>) => void;
}

const AppointmentDeliveryForm = ({ data, errMess, onSubmit }: AppointmentDeliveryFormProps) => {
  const [isActive, setIsActive] = useState(false);
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { isTwilioTelehealthEnabled } = useGetFeatureToggle();
  const [openToBookStatus, setOpenToBookStatus] = useState<'' | 'active' | 'finished'>('');
  const [draftStatus, setDraftStatus] = useState<'' | 'active' | 'finished'>('');
  const { roomList, isRoomListLoading } = useFetchRoomList();
  const initialUpdate = data && initialValuesUpdate(data);
  const [isDeliveryOptionsEmpty, setIsDeliveryOptionsEmpty] = useState(
    data
      ? data.deliveryOptions.length === 0
      : !initialValues.faceToFaceSelected &&
          !initialValues.telehealthPhoneCallSelected &&
          !initialValues.phoneCallDialClientSelected &&
          !initialValues.telehealthVideoCallSelected &&
          !initialValues.otherSelected
  );
  const [t] = useTranslation();

  useEffect(() => {
    if (errMess) {
      setOpenToBookStatus('');
      setDraftStatus('');
    }
  }, [errMess]);

  const handleSubmit = (values: typeof initialValues) => {
    const deliveryOptions: DeliveryType[] = [];

    if (values.faceToFaceSelected) {
      deliveryOptions.push(DeliveryType.FaceToFace);
    }
    if (values.telehealthPhoneCallSelected) {
      deliveryOptions.push(DeliveryType.PhoneCall);
    }
    if (values.phoneCallDialClientSelected) {
      deliveryOptions.push(DeliveryType.PhoneCallDialClient);
    }
    if (values.telehealthVideoCallSelected) {
      deliveryOptions.push(DeliveryType.VideoCall);
    }
    if (values.otherSelected) {
      deliveryOptions.push(DeliveryType.Other);
    }

    if (deliveryOptions.length === 0) {
      setIsDeliveryOptionsEmpty(true);
      setOpenToBookStatus('');
      setDraftStatus('');
      return;
    }

    const faceToFaceLocation = values.faceToFaceSelected ? values.faceToFaceLocation : '';
    const videoCallInstructions =
      values.telehealthVideoCallSelected && values.videoCallInstructionType === 'custom'
        ? values.videoCallInstructions
        : '';
    const phoneCallInstructions =
      values.telehealthPhoneCallSelected && values.phoneCallInstructionType === 'custom'
        ? values.phoneCallInstructions
        : '';
    const phoneCallDialClientInstructions = values.phoneCallDialClientSelected
      ? values.phoneCallDialClientInstructions
      : '';
    const otherInstructions = values.otherSelected
      ? { title: values.otherTitle, instructions: values.otherInstructions }
      : undefined;

    onSubmit({
      isActive,
      deliveryOptions,
      faceToFaceLocation,
      videoCallInstructions,
      phoneCallInstructions,
      phoneCallDialClientInstructions,
      otherInstructions,
      roomSettings: {
        required: values.roomRequired === 'yes',
        preferredRoom: {
          name: roomList.find((room) => room._id === values.roomId)?.name || '',
          roomId: values.roomId
        }
      },
      roomAny: values.roomRequired === 'any'
    });
  };

  const propStyles: Props['styles'] = {
    container: (base) => ({ ...base, margin: 0 }),
    control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: `1px solid ${styles.greyColor}`,
      borderRadius: 0,
      boxShadow: 'none'
    }),
    valueContainer: (base) => ({ ...base, padding: 0 }),
    dropdownIndicator: (base) => ({ ...base, padding: 0 })
  };

  const mapRoomList = (roomList: RoomType[]) => {
    return roomList.map((room) => ({ label: room.name, value: room._id }));
  };

  const handleChangeDeliveryModeOptions = (
    oldValues: AppointmentDeliveryFormType,
    changedValues: {
      faceToFaceSelected?: boolean;
      telehealthVideoCallSelected?: boolean;
      telehealthPhoneCallSelected?: boolean;
      phoneCallDialClientSelected?: boolean;
      otherSelected?: boolean;
    },
    setValues: (values: AppointmentDeliveryFormType) => void
  ) => {
    const newValues = { ...oldValues, ...changedValues };

    setValues(newValues);
    setIsDeliveryOptionsEmpty(
      !(
        newValues.faceToFaceSelected ||
        newValues.telehealthVideoCallSelected ||
        newValues.telehealthPhoneCallSelected ||
        newValues.phoneCallDialClientSelected ||
        newValues.otherSelected
      )
    );
  };

  return (
    <Formik
      initialValues={initialUpdate || initialValues}
      validationSchema={appointmentDeliverySchema}
      onSubmit={handleSubmit}
    >
      {({ values, setValues, submitForm, isValid, touched }) => (
        <div className={styles.container}>
          <FormSection
            title={t('appointment.delivery_mode_options')}
            help={t('appointment.delivery_mode_options.description')}
          >
            <div className={styles.telehealthForm}>
              <div className={classnames(styles.optionRow, { [styles.active]: values.faceToFaceSelected })}>
                <label className={styles.checkboxContainer}>
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={values.faceToFaceSelected}
                    onChange={() =>
                      handleChangeDeliveryModeOptions(
                        values,
                        { faceToFaceSelected: !values.faceToFaceSelected },
                        setValues
                      )
                    }
                  />
                  <span className={styles.label}>{DELIVERY_TYPE_LABELS[DeliveryType.FaceToFace]}</span>
                </label>
                <div className={styles.faceToFaceLocation}>{values.faceToFaceSelected && <FaceToFaceLocation />}</div>
              </div>

              <div className={classnames(styles.optionRow, { [styles.active]: values.telehealthVideoCallSelected })}>
                <label className={classnames(styles.checkboxContainer, styles.telehealthCheckbox)}>
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={values.telehealthVideoCallSelected}
                    onChange={() =>
                      handleChangeDeliveryModeOptions(
                        values,
                        { telehealthVideoCallSelected: !values.telehealthVideoCallSelected },
                        setValues
                      )
                    }
                  />
                  <span className={styles.label}>Telehealth Video</span>
                </label>
                {values.telehealthVideoCallSelected && (
                  <>
                    <div className={styles.telehealth2ndColumn}>
                      <label className={styles.label}>How to connect</label>
                      <div className={styles.telehealthItem}>
                        <Radio
                          name={'videoRadio'}
                          options={VIDEO_CONNECT_OPTIONS}
                          value={values.videoCallInstructionType}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              videoCallInstructionType: e.target.value
                            })
                          }
                          vertical
                          className={styles.radioClass}
                          labelClassName={styles.radioLabel}
                        />
                        {values.videoCallInstructionType === 'custom' && <VideoInstructions />}
                      </div>
                    </div>
                  </>
                )}
              </div>

              {isTwilioTelehealthEnabled && (
                <div className={classnames(styles.optionRow, { [styles.active]: values.telehealthPhoneCallSelected })}>
                  <label className={classnames(styles.checkboxContainer, styles.telehealthCheckbox)}>
                    <input
                      className={styles.checkbox}
                      type="checkbox"
                      checked={values.telehealthPhoneCallSelected}
                      onChange={() =>
                        handleChangeDeliveryModeOptions(
                          values,
                          { telehealthPhoneCallSelected: !values.telehealthPhoneCallSelected },
                          setValues
                        )
                      }
                    />
                    <div>
                      <div className={styles.label}>Telehealth Phone</div>
                      <div className={styles.description}>Join a Telehealth room for voice only</div>
                    </div>
                  </label>
                  {values.telehealthPhoneCallSelected && (
                    <>
                      <div className={styles.telehealth2ndColumn}>
                        <label className={styles.label}>How to connect</label>
                        <div className={styles.telehealthItem}>
                          <Radio
                            name={'phoneRadio'}
                            options={getPhoneConnectOptions(t)}
                            value={values.phoneCallInstructionType}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                phoneCallInstructionType: e.target.value
                              })
                            }
                            vertical
                            className={styles.radioClass}
                            labelClassName={styles.radioLabel}
                          />
                          {values.phoneCallInstructionType === 'custom' && <PhoneCallInstructions />}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              <div className={classnames(styles.optionRow, { [styles.active]: values.phoneCallDialClientSelected })}>
                <label className={styles.checkboxContainer}>
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={values.phoneCallDialClientSelected}
                    onChange={() =>
                      handleChangeDeliveryModeOptions(
                        values,
                        { phoneCallDialClientSelected: !values.phoneCallDialClientSelected },
                        setValues
                      )
                    }
                  />
                  <div>
                    <div className={styles.label}>Outbound Phone Call</div>
                    <div>Dial client phone number on record</div>
                  </div>
                </label>
                {values.phoneCallDialClientSelected && (
                  <>
                    <div className={styles.telehealth2ndColumn}>
                      <label className={styles.label}>Instructions</label>
                      <div className={styles.telehealthItem}>
                        <PhoneCallDialClientInstructions />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className={classnames(styles.optionRow, { [styles.active]: values.otherSelected })}>
                <label className={styles.checkboxContainer}>
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={values.otherSelected}
                    onChange={() =>
                      handleChangeDeliveryModeOptions(values, { otherSelected: !values.otherSelected }, setValues)
                    }
                  />
                  <div className={styles.label}>Other</div>
                </label>
                {values.otherSelected && (
                  <>
                    <div className={styles.telehealth2ndColumn}>
                      <div className={styles.telehealthItem}>
                        <OtherInstructions />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {touched && isDeliveryOptionsEmpty && (
              <p className={styles.fieldError}>
                Please select at least one
                <strong> Delivery Mode Option</strong>
              </p>
            )}
          </FormSection>
          {isEdgeAdminView ? (
            <FormSection
              title="Require a room?"
              help="Decide if a room is required for your appointment type. A room can be set up in Room Management under Calendar settings."
            >
              <div className={styles.roomRequiredForm}>
                <Radio
                  options={ROOM_REQUIRED_OPTIONS}
                  value={values.roomRequired}
                  onChange={(e) => setValues({ ...values, roomRequired: e.target.value })}
                  vertical
                  className={styles.radioClass}
                  labelClassName={styles.radioLabel}
                />
                {values.roomRequired === 'yes' &&
                  (isRoomListLoading ? (
                    <div className={styles.loadingBox}>Loading...</div>
                  ) : (
                    <div>
                      <FormikSelect
                        name="roomId"
                        label="Room selection?"
                        options={mapRoomList(roomList)}
                        styles={propStyles}
                        labelClass={styles.labelClass}
                      />
                    </div>
                  ))}
              </div>
            </FormSection>
          ) : (
            <></>
          )}
          <SubmitFooter
            draftStatus={draftStatus}
            setDraftStatus={setDraftStatus}
            openToBookStatus={openToBookStatus}
            setOpenToBookStatus={setOpenToBookStatus}
            isDeliveryOptionsEmpty={isDeliveryOptionsEmpty}
            isValid={isValid}
            setIsActive={setIsActive}
            submitForm={submitForm}
          />
        </div>
      )}
    </Formik>
  );
};

export default memo(AppointmentDeliveryForm);
