import { createSlice } from '@reduxjs/toolkit';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { ServiceDelivered } from 'interfaces/Schedule/Appointment';
import { AppointmentType, DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';
import { RootState } from 'redux/store';

type processAppointmentClientRecord = clientRecordsInterface & {
  firstLastName: string;
};

export enum Step {
  Client,
  AppointmentType,
  DeliveryMode
}

interface IProcessAppointment {
  isPrepareClaimOpen: boolean;
  currentStep: Step | undefined;
  processAppointment: {
    selectedClient: processAppointmentClientRecord | undefined;
    selectedAppointmentType: AppointmentType | undefined;
    selectedDeliveryMode: DeliveryType | undefined;
    selectedServiceDelivered: ServiceDelivered | undefined;
    selectedPractitioner: PractitionersDetailsInterface | undefined;
    selectedProviderNumber: string | undefined;
    selectedInvoiceTemplate: string | undefined;
    selectedInvoiceStatus: string | undefined;
    selectedClaimType: PendingClaimsFunder | undefined;
    selectedMbsCode: string | undefined;
    reviewNote: string | undefined;
    waiveCancellationFee: boolean;
    cancellationNote: string | undefined;
    useServiceFee: boolean;
  };
  formError: {
    selectedClient: boolean;
    selectedAppointmentType: boolean;
    selectedDeliveryMode: boolean;
    selectedServiceDelivered: boolean;
    selectedPractitioner: boolean;
    selectedInvoiceTemplate: boolean;
    selectedInvoiceStatus: boolean;
    selectedClaimType: boolean;
    selectedMbsCode: boolean;
    reviewNote: boolean;
    cancellationNote: boolean;
  };
  isCancel: boolean;
}

const initialState: IProcessAppointment = {
  isPrepareClaimOpen: false,
  currentStep: undefined,
  processAppointment: {
    selectedClient: undefined,
    selectedAppointmentType: undefined,
    selectedDeliveryMode: undefined,
    selectedServiceDelivered: undefined,
    selectedPractitioner: undefined,
    selectedProviderNumber: undefined,
    selectedInvoiceTemplate: undefined,
    selectedInvoiceStatus: undefined,
    selectedClaimType: undefined,
    selectedMbsCode: undefined,
    reviewNote: undefined,
    waiveCancellationFee: false,
    cancellationNote: undefined,
    useServiceFee: false
  },
  formError: {
    selectedClient: false,
    selectedAppointmentType: false,
    selectedDeliveryMode: false,
    selectedServiceDelivered: false,
    selectedPractitioner: false,
    selectedInvoiceTemplate: false,
    selectedInvoiceStatus: false,
    selectedClaimType: false,
    selectedMbsCode: false,
    reviewNote: false,
    cancellationNote: false
  },
  isCancel: false
};

export const processAppointmentSlice = createSlice({
  name: 'processAppointmentSlice',
  initialState,
  reducers: {
    setIsPrepareClaimOpen: (state, action) => {
      state.isPrepareClaimOpen = action.payload;
    },

    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },

    setProcessAppointment: (state, action) => {
      state.processAppointment = {
        ...state.processAppointment,
        ...action.payload
      };
    },

    setFormError: (state, action) => {
      state.formError = {
        ...state.formError,
        ...action.payload
      };
    },

    resetAll: (state) => {
      state.currentStep = initialState.currentStep;
      state.formError = initialState.formError;
      state.processAppointment = initialState.processAppointment;
      state.isPrepareClaimOpen = initialState.isPrepareClaimOpen;
      state.isCancel = false;
    },

    setIsCancel: (state, action) => {
      state.isCancel = action.payload;
    }
  }
});

export const selectIsPrepareClaimOpen = (state: RootState) => state.processAppointmentSlice.isPrepareClaimOpen;
export const selectCurrentStep = (state: RootState) => state.processAppointmentSlice.currentStep;
export const selectProcessAppointment = (state: RootState) => state.processAppointmentSlice.processAppointment;
export const selectFormError = (state: RootState) => state.processAppointmentSlice.formError;
export const selectIsCancel = (state: RootState) => state.processAppointmentSlice.isCancel;

export const {
  setIsPrepareClaimOpen,
  setCurrentStep,
  setProcessAppointment,
  resetAll: resetProcessAppointment,
  setFormError,
  setIsCancel
} = processAppointmentSlice.actions;

export default processAppointmentSlice.reducer;
