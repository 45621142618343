import styles from './ClientPsychologistChart.module.scss';
import { useMemo } from 'react';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { GAE_REGION } from 'config/config';

interface ClientPsychologistChartProps {
  id: string;
  clientRecordId: string;
  updatedAt: string;
  episodeId?: string;
}

const ClientPsychologistChart = ({ id, clientRecordId, updatedAt, episodeId }: ClientPsychologistChartProps) => {
  const chartLink = useMemo(() => {
    return isDevelopmentENV()
      ? 'https://datastudio.google.com/embed/reporting/41b6f0df-9f41-423a-85b1-b5151b5e28a9/page/zTGqC'
      : `https://customer.tacklit.com/app/psychometrics-chart/chart-6556ecf4e42a8b0f6e30e991?embed=true&environment=production&clientRecord=${clientRecordId}&updatedAt=${updatedAt}&id=${id}&region=${GAE_REGION}${
          episodeId && `&episodeId=${episodeId}`
        }`;
  }, [id, clientRecordId, updatedAt, episodeId]);

  return id && clientRecordId && updatedAt ? (
    <div className={styles.container}>
      <div className={styles.contentBox}>
        <iframe title={'clientChart'} width={'100%'} height={'100%'} src={chartLink} frameBorder={0} />
      </div>
    </div>
  ) : null;
};

export default ClientPsychologistChart;
