import { MicroCheckInAssessmentStruct } from 'pages/AssessmentDetails/MicroCheckInDetails/Interface';
import { AssessmentType } from 'pages/Assessments/Interface';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getMCIAssessmentDetails } from 'store/CheckIn/GetMCIAssessmentDetails/action';
import { getMCIAssessmentQuestionTemplate } from 'store/CheckIn/GetMCIAssessmentQuestionTemplate/action';
import { useGetAccessToken } from './token';

export type RefetchMciDetails = () => Promise<void>;

export const useMCIDetails = () => {
  const { token } = useGetAccessToken();
  const path = useParams() as { assessmentId: string };

  const [mciDetails, setMciDetails] = useState<MicroCheckInAssessmentStruct>();
  const [mciDetailsInitializing, setMciDetailsInitializing] = useState(true);
  const [mciDetailsRefetching, setMciDetailsRefetching] = useState(false);

  const [questionTemplates, setQuestionTemplates] = useState<MicroCheckInAssessmentStruct['questionSets']>([]);
  const [questionTemplatesInitializing, setQuestionTemplatesInitializing] = useState(true);

  const fetchMciDetails = async () => {
    let mciDetails: MicroCheckInAssessmentStruct;

    if (path.assessmentId === 'newMCI') {
      mciDetails = {
        id: 'newMCI',
        name: '',
        description: '',
        assessmentType: AssessmentType.MicroCheckIn,
        isDefault: false,
        isPublished: false,
        isExisting: false,
        questionSets: [],
        clients: []
      };
    } else {
      mciDetails = await getMCIAssessmentDetails(token, path.assessmentId);
    }

    setMciDetails(mciDetails);
  };

  const initializeMciDetails = async () => {
    setMciDetailsInitializing(true);
    await fetchMciDetails();
    setMciDetailsInitializing(false);
  };

  /** Separating refetch and initial fetch
   * so that UI can show different (page/component) loading indicator.
   */
  const refetchMciDetails = async () => {
    setMciDetailsRefetching(true);
    await fetchMciDetails();
    setMciDetailsRefetching(false);
  };

  const initializeQuestionTemplates = async () => {
    const response = await getMCIAssessmentQuestionTemplate(token);
    setQuestionTemplates(response?.questionSets ?? []);
    setQuestionTemplatesInitializing(false);
  };

  useEffect(() => {
    if (token) {
      initializeMciDetails();
      initializeQuestionTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    mciDetails,
    mciDetailsInitializing,
    mciDetailsRefetching,
    questionTemplates,
    questionTemplatesInitializing,
    refetchMciDetails
  };
};
