import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import Select from 'components/v2/Select/Select';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import SendAdhocAssessmentModalV2 from 'pages/Assessments/components/SendAdhocAssessmentModalV2/SendAdhocAssessmentModalV2';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import PatientDetailsAssessments from '../PatientDetailsAssessments/PatientDetailsAssessments';
import styles from './AssessmentResult.module.scss';
import AssessmentLabel from './components/AssessmentLabel/AssessmentLabel';
import AssessmentResultDataRow from './components/AssessmentResultDataRow/AssessmentResultDataRow';
import AssessmentResultHeader from './components/AssessmentResultHeader/AssessmentResultHeader';
import noAssessment from 'assets/images/noAssessments.svg';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import RecoverySTAR from './components/RecoverySTAR/RecoverySTAR';
import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';
import {
  useGetAssessmentByIdQuery,
  useGetClinicalAssessmentResponsesQuery
} from 'redux/endpoints/checkInServices/clinicalAssessment';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectCurrentEpisodeId,
  selectIsFetchingEpisodes,
  selectIsOutsideAllEpisodes,
  selectIsShowAllData
} from 'redux/episodes/episodeSlice';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { DataRefreshHandle } from '../../PatientDetailsContent';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { setIsRefreshDataDisabled } from 'redux/clients/clientDetailsSlice';
import ClientPsychologistChart from 'pages/ClientPsychologistChart/ClientPsychologistChart';
import ToggleSwitchV2, { ToggleList } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

interface AssessmentResultProps {
  recordId: string;
  clientRecord: clientRecordsInterface;
  assessmentId?: string;
  notificationSettings: NotificationSettingsInterface;
  isEdgeReceptionist: boolean;
}

const AssessmentResult = forwardRef<DataRefreshHandle, AssessmentResultProps>(
  ({ recordId, clientRecord, assessmentId, notificationSettings, isEdgeReceptionist }, ref) => {
    const { token } = useGetAccessToken();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [isSendAssessmentModalVisible, setIsSendAssessmentModalVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState<IOptionItem | null>(null);
    const [selectedAssessmentId, setSelectedAssessmentId] = useState<string>();
    const [selectedAssessment_Id, setSelectedAssessment_Id] = useState<string>();
    const [tab, setTab] = useState<'breakdown' | 'trendOverTime'>('breakdown');
    const [isExpandAll, setIsExpandAll] = useState(false);
    const [assessmentTab, setAssessmentTab] = useState<'assessmentResult' | 'assessmentChart'>('assessmentResult');

    const navigate = useNavigate();
    const { CLIENTS } = useRoutesGenerator();
    const { isEoCEnabled } = useGetFeatureToggle();

    const setSelectedAssessment_IdAndUpdateParam = useCallback(
      (id?: string) => {
        if (id) {
          setSelectedAssessment_Id(id);
          navigate(`${CLIENTS.BASE}/${recordId}/assessments/${id}`);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [CLIENTS, recordId]
    );

    const onChangeChartView = (value: ToggleList) => {
      setAssessmentTab(value.id as 'assessmentResult' | 'assessmentChart');
    };

    const ASSESSMENT_TAB_LIST = [
      {
        id: 'assessmentResult',
        label: 'Breakdown',
        isActive: assessmentTab === 'assessmentResult'
      },
      {
        id: 'assessmentChart',
        label: 'Chart',
        isActive: assessmentTab === 'assessmentChart'
      }
    ];

    const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
    const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
    const isShowAllData = useAppSelector(selectIsShowAllData);
    const isFetchingEpisodes = useAppSelector(selectIsFetchingEpisodes);

    const {
      data: assessmentList,
      isLoading: isAssessmentListLoading,
      isFetching: isAssessmentListFetching,
      refetch: refetchAssessments
    } = useGetClinicalAssessmentResponsesQuery(
      {
        recordId,
        params: {
          ...(currentEpisodeId && { episodeId: currentEpisodeId }),
          ...(isOutsideAllEpisodes && { showOutsideOfEpisode: isOutsideAllEpisodes })
        }
      },
      {
        skip:
          isEoCEnabled &&
          (isEdgeReceptionist ||
            isFetchingEpisodes ||
            !recordId ||
            (!isOutsideAllEpisodes && !isShowAllData && !currentEpisodeId))
      }
    );

    const {
      data: assessmentByIdData,
      isLoading: isAssessmentByIdDataLoading,
      isFetching: isAssessmentByIdDataFetching
    } = useGetAssessmentByIdQuery(
      { clientRecordId: recordId, assessmentId: selectedAssessment_Id || '' },
      {
        skip: !recordId || !selectedAssessment_Id
      }
    );

    useImperativeHandle(ref, () => ({
      onDataRefresh: () => {
        navigate(`${CLIENTS.BASE}/${recordId}/assessments`);
        setSelectedAssessmentId(undefined);
        setSelectedAssessment_Id('');
        setSelectedValue(null);
        refetchAssessments();
      }
    }));

    useEffect(() => {
      if (assessmentId) {
        setSelectedAssessment_Id(assessmentId);
      }
    }, [assessmentId]);

    useEffect(() => {
      setSelectedAssessmentId(undefined);
      setSelectedAssessment_Id('');
      setSelectedValue(null);
    }, [currentEpisodeId]);

    useEffect(() => {
      dispatch(
        setIsRefreshDataDisabled(
          isAssessmentListLoading ||
            isAssessmentListFetching ||
            isAssessmentByIdDataLoading ||
            isAssessmentByIdDataFetching
        )
      );
    }, [
      isAssessmentListLoading,
      isAssessmentListFetching,
      isAssessmentByIdDataLoading,
      isAssessmentByIdDataFetching,
      dispatch
    ]);

    useEffect(() => {
      if (!isAssessmentListLoading && !isAssessmentListFetching && assessmentList?.length) {
        const selectedAssessment = assessmentList.find((i) => i._id === selectedAssessment_Id) || assessmentList[0];
        !selectedAssessmentId && setSelectedAssessmentId(selectedAssessment?.id);
        !selectedAssessment_Id && setSelectedAssessment_IdAndUpdateParam(selectedAssessment?._id);
      }
    }, [
      isAssessmentListLoading,
      isAssessmentListFetching,
      assessmentList,
      assessmentId,
      selectedAssessmentId,
      selectedAssessment_Id,
      setSelectedAssessment_IdAndUpdateParam
    ]);

    useEffect(() => {
      setSelectedValue(
        !isAssessmentListLoading && !isAssessmentListFetching && assessmentList?.length
          ? selectedAssessmentId
            ? {
                value: assessmentList.find((i) => i.id === selectedAssessmentId)?._id || '',
                label: selectedAssessmentId
              }
            : assessmentList[0]
            ? { value: assessmentList[0]._id, label: assessmentList[0].id }
            : null
          : null
      );
    }, [isAssessmentListLoading, isAssessmentListFetching, assessmentList, selectedAssessmentId]);

    const isSDQ = assessmentByIdData?.id.toLowerCase().includes('sdq');
    const isSTAR = assessmentByIdData?.metadata?.group?.toLowerCase().includes('star');

    const noAssessments = !isAssessmentListFetching && assessmentList?.length === 0;

    const canReceiveNotificationProfiles = clientRecord.clientProfiles.some((client) => {
      const config = {
        communicationPreference: client.communicationPreference,
        notificationSettings,
        notificationSettingsCategory: 'forms'
      };
      return (
        (canClientReceiveSms(config) && client.hasMobileNumber) || (canClientReceiveEmail(config) && client.hasEmail)
      );
    });

    return (
      <div className={classNames(styles.container, noAssessments && styles.noItemsContainer)}>
        <SendAdhocAssessmentModalV2
          token={token}
          visible={isSendAssessmentModalVisible}
          onCancel={() => setIsSendAssessmentModalVisible(false)}
          selectedClientRecord={clientRecord}
        />
        <div className={styles.headerContainer}>
          {!noAssessments && (
            <div className={styles.headerBox}>
              <Select
                filledArrow
                label="ASSESSMENT"
                options={assessmentList
                  ?.filter((item, idx, arr) => arr.findIndex((i) => i.id === item.id) === idx)
                  ?.map((i) => ({ value: i._id, label: i.id }))}
                onChange={(val: any) => {
                  if (val?.label !== selectedAssessmentId) {
                    setSelectedAssessmentId(val?.label);
                    setSelectedAssessment_IdAndUpdateParam(val?.value);
                  }
                }}
                isSearchable={false}
                value={selectedValue}
                isDisabled={isAssessmentListFetching}
                isLoading={isAssessmentListFetching}
                containerClass={styles.selectAssessmentId}
                controlStyle={{ boxShadow: 'none' }}
              />
              {!isAssessmentListFetching && assessmentList && assessmentList?.length > 0 && (
                <div className={styles.clientChartContainer}>
                  <ToggleSwitchV2
                    id={'assessmentTab'}
                    toggleList={ASSESSMENT_TAB_LIST}
                    onChangeStatus={onChangeChartView}
                    isLoading={false}
                    className={styles.toggleClass}
                    indicatorClassName={styles.indicatorClass}
                    activeLabelClassName={styles.activeClass}
                    size={'medium'}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <>
          {noAssessments ? (
            <div className={styles.noItems}>
              <img src={noAssessment} className={styles.noItemsImage} alt="No Assessments" />
              <div className={styles.noItemsText}>
                <div className={styles.noItemsTitle}>No assessments have been completed by this client yet.</div>
                <div className={styles.noItemsSendAssessment}>
                  <Tooltip visible={!canReceiveNotificationProfiles} title={t('form.disallowed_sms.description')}>
                    <ButtonAlt
                      icon={'send'}
                      iconVariant={IconVariant.Filled}
                      onClick={() => setIsSendAssessmentModalVisible(true)}
                      variant={'outlined'}
                      disabled={!canReceiveNotificationProfiles}
                    >
                      Send assessment
                    </ButtonAlt>
                  </Tooltip>
                </div>
              </div>
            </div>
          ) : assessmentTab === 'assessmentChart' ? (
            <div className={styles.clientChartView}>
              <ClientPsychologistChart
                id={selectedValue?.label || ''}
                clientRecordId={clientRecord._id}
                updatedAt={clientRecord.updatedAt}
                episodeId={currentEpisodeId}
              />
            </div>
          ) : (!assessmentByIdData && (isAssessmentByIdDataLoading || isAssessmentByIdDataFetching)) ||
            isAssessmentListLoading ||
            isAssessmentListFetching ? (
            <div className={styles.loading}>
              <LoadingDot />
            </div>
          ) : (
            assessmentByIdData &&
            !isAssessmentListFetching && (
              <div className={styles.content}>
                {isSDQ && (
                  <div className={styles.totalScore}>
                    {assessmentByIdData.charts[0]?.total}
                    <AssessmentLabel label={assessmentByIdData.charts[0]?.label} className={styles.label} />
                  </div>
                )}
                <AssessmentResultHeader
                  assessmentList={assessmentList}
                  isAssessmentListLoading={isAssessmentListFetching || isAssessmentListLoading}
                  setSelectedAssessment_Id={setSelectedAssessment_IdAndUpdateParam}
                  selectedAssessmentId={selectedAssessmentId}
                  clientRecord={clientRecord}
                  selectedAssessment_Id={selectedAssessment_Id}
                />
                {isAssessmentByIdDataFetching || isAssessmentByIdDataLoading ? (
                  <div className={styles.loadingBody}>
                    <LoadingCircle />
                  </div>
                ) : isSDQ ? (
                  <div className={styles.body}>
                    <div className={styles.actions}>
                      <div className={styles.tabs}>
                        <div
                          className={classNames(styles.tab, tab === 'breakdown' && styles.active)}
                          onClick={() => setTab('breakdown')}
                        >
                          Breakdown
                        </div>
                        <div
                          className={classNames(styles.tab, tab === 'trendOverTime' && styles.active)}
                          onClick={() => setTab('trendOverTime')}
                        >
                          Trend over time
                        </div>
                      </div>
                      <div className={styles.buttons}>
                        <ButtonAlt
                          variant={'text'}
                          className={styles.expandAll}
                          onClick={() => {
                            setIsExpandAll(!isExpandAll);
                          }}
                          icon={`${!isExpandAll ? 'add_circle_outline' : 'remove_circle_outline'}`}
                        >
                          {!isExpandAll ? 'Expand all' : 'Hide all'}
                        </ButtonAlt>
                      </div>
                    </div>
                    <div className={styles.data}>
                      {assessmentByIdData.charts.map((item) => (
                        <div className={styles.dataRow} key={item.key}>
                          <AssessmentResultDataRow
                            chart={item}
                            isExpanded={isExpandAll}
                            assessment={assessmentByIdData}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : isSTAR ? (
                  <RecoverySTAR assessment={assessmentByIdData} />
                ) : (
                  <div className={styles.body}>
                    <PatientDetailsAssessments
                      recordId={recordId}
                      assessmentId={assessmentId}
                      assessmentList={assessmentList}
                      isLoadingAssessmentList={isAssessmentListFetching || isAssessmentListLoading}
                      currentTab="assessments"
                      showHeader={false}
                      assessment={assessmentByIdData}
                      isLoadingAssessment={isAssessmentByIdDataFetching || isAssessmentByIdDataLoading}
                    />
                  </div>
                )}
              </div>
            )
          )}
        </>
      </div>
    );
  }
);

export default AssessmentResult;
