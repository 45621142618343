import styles from './ClientPersonalDetails.module.scss';
import ProfileTypeBadge from 'components/ProfileTypeBadge/ProfileTypeBadge';

import moment from 'moment';
import CheckInProgram from './components/CheckInProgram/CheckInProgram';
import { clientProfilesEncrypted, clientRecordsInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import ClientStatusBadge from 'components/ClientStatusBadge/ClientStatusBadge';
import { useTranslation } from 'react-i18next';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { getTimezoneLabel } from 'utils/hooks/GetTimezones/getTimezones';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface ClientPersonalDetailsProps {
  clientRecordData: clientRecordsInterface;
  clientProfileData: clientProfilesEncrypted;
  onResendInvitation: () => void;
  onCancelInvitation: () => void;
  isInvitationProcessing: boolean;
}

const ClientPersonalDetails = ({
  clientRecordData,
  clientProfileData,
  onResendInvitation,
  onCancelInvitation,
  isInvitationProcessing
}: ClientPersonalDetailsProps) => {
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const { isClientTimeZoneEnabled } = useGetFeatureToggle();
  const { auth0ClinicianId } = useGetClinicianId();
  const { accountTimeZone } = useTimeZone();

  const isNotSignUpDateExists = clientRecordData.clientProfiles.some(
    ({ profileType, clientAuth0Id }) => profileType === 'full' && !clientAuth0Id
  );

  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.profileBox}>
        <div className={styles.statusWrapper}>
          <div className={styles.subTitle}>{t('title.client_status').toUpperCase()}</div>
          <ClientStatusBadge recordStatus={clientRecordData.recordStatus} />
        </div>
        <div className={styles.statusWrapper}>
          <div className={styles.subTitle}>PROFILE TYPE</div>
          <ProfileTypeBadge
            recordType={clientRecordData.recordType}
            onResendInvitation={() => onResendInvitation()}
            onCancelInvitation={() => onCancelInvitation()}
            isInvitationProcessing={isInvitationProcessing}
            isShowInvitationAction={clientRecordData.recordStatus !== RecordStatus.Closed}
            isNotFullSignUp={isNotSignUpDateExists}
            clientProfilesData={clientRecordData.clientProfiles}
            canBeFullProfile={clientRecordData.canBeFullProfile}
          />
        </div>
        {isClientTimeZoneEnabled && (
          <div className={styles.statusWrapper}>
            <div className={styles.subTitle}>CLIENT TIMEZONE</div>
            <div className={styles.timeZone}>{getTimezoneLabel(clientRecordData.timeZone || accountTimeZone)}</div>
          </div>
        )}
      </div>
      <div className={styles.detailsBox}>
        <div className={styles.detailWrap}>
          <div className={styles.label}>{t('label.client_since')}:</div>
          <div className={styles.info}>{moment(clientRecordData.createdAt).format('DD MMM YYYY')}</div>
        </div>
        {clientRecordData.isCompleteRecords && !isEdgeReceptionist && !isEdgeAdminView && (
          <CheckInProgram
            clientRecordId={clientRecordData._id}
            clientProfileId={clientProfileData._id}
            disabled={!clientRecordData.clinicianAuth0Ids.includes(auth0ClinicianId)}
          />
        )}
      </div>
    </div>
  );
};

export default ClientPersonalDetails;
