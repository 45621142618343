import classNames from 'classnames';
import { FC } from 'react';
import styles from './CarePathwayAppointmentCard.module.scss';
import Badge from 'components/Badge/Badge';
import { CarePlanAppointmentTypeList } from 'interfaces/CarePathway/CarePathway';
import momentTz from 'moment-timezone';
import { useTimeZone } from 'utils/hooks/useTimeZone';

interface CarePathwayAppointmentCardProps {
  carePathwayAppointment: CarePlanAppointmentTypeList[];
}

const CarePathwayAppointmentCard: FC<CarePathwayAppointmentCardProps> = ({ carePathwayAppointment }) => {
  const { accountTimeZone } = useTimeZone();
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.tableHeader}>
          <div className={classNames(styles.name, styles.tableHeaderItem)}>APPOINTMENT</div>
          <div className={classNames(styles.duration, styles.tableHeaderItem)}>DURATION</div>
          <div className={classNames(styles.date, styles.tableHeaderItem)}>DATE</div>
          <div className={classNames(styles.startDate, styles.tableHeaderItem)}>START</div>
          <div className={classNames(styles.endDate, styles.tableHeaderItem)}>END</div>
          <div className={classNames(styles.staff, styles.tableHeaderItem)}>STAFF</div>
          <div className={classNames(styles.status, styles.tableHeaderItem)}>STATUS</div>
        </div>
        {carePathwayAppointment.map(
          (appointmentObj) =>
            appointmentObj.appointment && (
              <div key={appointmentObj._id} className={styles.tableContent}>
                <div className={classNames(styles.name, styles.tableContentItem)}>
                  {appointmentObj.appointmentType.name}
                </div>
                <div className={classNames(styles.duration, styles.tableContentItem)}>
                  {appointmentObj.appointmentType.duration.minutes}
                </div>
                <div className={classNames(styles.date, styles.tableContentItem)}>
                  {momentTz.tz(appointmentObj.appointment?.startDateTime, accountTimeZone).format('DD/MM/YYYY')}
                </div>
                <div className={classNames(styles.startDate, styles.tableContentItem)}>
                  {momentTz.tz(appointmentObj.appointment?.startDateTime, accountTimeZone).format('hh:mmA')}
                </div>
                <div className={classNames(styles.endDate, styles.tableContentItem)}>
                  {momentTz.tz(appointmentObj.appointment?.endDateTime, accountTimeZone).format('hh:mmA')}
                </div>
                <div className={classNames(styles.staff, styles.tableContentItem)}>
                  <div className={styles.tableBadge}>
                    <Badge
                      className={classNames(styles.staffBadge)}
                      label={appointmentObj.appointment?.clinician?.name || ''}
                    />
                  </div>
                </div>
                <div className={classNames(styles.status, styles.tableContentItem)}>
                  <div className={styles.tableBadge}>
                    {appointmentObj.appointment?.markedStatus?.map((markStatusObj) => (
                      <Badge className={styles.badge} label={markStatusObj} />
                    ))}
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default CarePathwayAppointmentCard;
