import { Skeleton } from 'antd';
import { CheckBoxListing } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import OptionList from 'pages/Groups/GroupDetails/components/GroupContent/components/Common/OptionList/OptionList';
import React from 'react';
import styles from './SurveyListSelection.module.scss';

interface SurveyListSelectionProp {
  id: string;
  value: CheckBoxListing[];
  checkValidation: boolean;
  surveyList: CheckBoxListing[];
  isListLoading: boolean;
  onSelectSurvey: (value: string) => void;
  onRemoveSurvey: (value: string) => void;
}

const SurveyListSelection = ({
  id,
  value,
  checkValidation,
  surveyList,
  isListLoading,
  onSelectSurvey,
  onRemoveSurvey
}: SurveyListSelectionProp) => {
  const handleChange = (isChecked: boolean, id: string) => {
    if (isChecked) {
      onSelectSurvey(id);
    } else {
      onRemoveSurvey(id);
    }
  };

  if (isListLoading) {
    return (
      <div className={styles.loadingContainer}>
        {[...Array(6)].map((obj, i) => (
          <div key={i} className={styles.loadingBox}>
            <Skeleton.Input active className={styles.loadingName} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      <div className={styles.selectAssessmentWrapper}>
        <ErrorMessage error={'Please select at least one survey'} visible={checkValidation && !value.length} />
        <OptionList
          clientId={id}
          title={'Select Survey'}
          subTitle={'You can add a maximum of 3'}
          listOption={surveyList.map(({ value, label }) => ({
            id: value,
            title: label
          }))}
          selectedOptions={value.map((val) => ({
            id: val.value,
            title: val.label
          }))}
          onSelect={handleChange}
        />
      </div>
    </>
  );
};

export default SurveyListSelection;
