import { Question } from 'interfaces/checkInService/AssessmentInterfaces';

import styles from './FreeText.module.scss';

interface FreeTextProps {
  question: Question;
}

const FreeText = ({ question }: FreeTextProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.questionWrapper}>
        <div>{question.stem}</div>
        <div className={styles.answerWrapper}>{`${question.response?.value} ${
          question.response?.otherValue ? ` - ${question.response?.otherValue}` : ''
        }`}</div>
      </div>
    </div>
  );
};

export default FreeText;
