import classNames from 'classnames';
import NestedSelect, { DropdownType } from 'components/Select/NestedSelect/NestedSelect';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { EpisodeInterface, EpisodeStatus } from 'interfaces/Episodes/episodes';
import moment from 'moment';
import { FC, useState } from 'react';
import { useChangeEpisodeStatusMutation } from 'redux/endpoints/clinicianProfileServices/episode';
import { EpisodeCardAction } from '../../interface/constants';
import { useGetEpisodeStatusActionOptions } from '../../hooks/useGetEpisodeStatusOptions';
import { ChangeEpisodeStatusModal } from '../ChangeEpisodeStatusModal/ChangeEpisodeStatusModal';
import EpisodeCardLoading from '../EpisodeCardLoading/EpisodeCardLoading';
import { EpisodeModal } from '../EpisodeModal/EpisodeModal';
import EpisodeStatusBadge from '../EpisodeStatusBadge/EpisodeStatusBadge';
import styles from './EpisodeCard.module.scss';
import { notification } from 'antd';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';

interface EpisodeCardProps {
  episodeData: EpisodeInterface;
  clientRecord: clientRecordsInterface;
  currentActiveEpisode?: EpisodeInterface;
  overlapActiveDate: boolean;
}

const EpisodeCard: FC<EpisodeCardProps> = ({ episodeData, clientRecord, currentActiveEpisode, overlapActiveDate }) => {
  const { accountSettings } = useGetAccountSettings();
  const [isEpisodeModalVisible, setIsEpisodeModalVisible] = useState(false);
  const [isChangeEpisodeStatusModalVisible, setIsChangeEpisodeStatusModalVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<EpisodeStatus>();
  const [isShowConfirmArchived, setIsShowConfirmArchived] = useState<boolean>(false);
  const [episodeStatus, setEpisodeStatus] = useState<EpisodeStatus>();
  const [changeEpisodeStatus, { isLoading: isUpdating }] = useChangeEpisodeStatusMutation();

  const { actionOptions } = useGetEpisodeStatusActionOptions({
    className: styles.warningOption,
    isShowConfirmArchived,
    currentEpisodeData: episodeData,
    currentActiveEpisodeData: currentActiveEpisode,
    overlapActiveDate
  });

  const handleClickDropdownMenu = async ({
    firstSelectedOption,
    secondSelectedOption
  }: {
    firstSelectedOption?: string;
    secondSelectedOption?: EpisodeStatus | string;
  }) => {
    switch (firstSelectedOption) {
      case EpisodeCardAction.Edit: {
        setIsEpisodeModalVisible(true);
        break;
      }
      case EpisodeCardAction.Archived: {
        setIsShowConfirmArchived(true);
        break;
      }
      case EpisodeCardAction.ConfirmArchived: {
        try {
          await changeEpisodeStatus({
            episodeId: episodeData._id,
            clientRecordId: clientRecord._id,
            payload: { status: EpisodeStatus.Archived }
          }).unwrap();
        } catch (ex) {
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to archive the episode.',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } finally {
          setIsShowConfirmArchived(false);
        }
        break;
      }
      case EpisodeCardAction.ChangeStatus: {
        if (!secondSelectedOption) return;
        // Active=>OnHold and OnHold=>Active: don't open modal
        const isAvoidOpenModal =
          (episodeData.status === EpisodeStatus.Active && secondSelectedOption === EpisodeStatus.OnHold) ||
          (episodeData.status === EpisodeStatus.OnHold && secondSelectedOption === EpisodeStatus.Active) ||
          (episodeData.status === EpisodeStatus.Draft && secondSelectedOption === EpisodeStatus.Planned) ||
          (episodeData.status === EpisodeStatus.Planned && secondSelectedOption === EpisodeStatus.Draft);

        if (isAvoidOpenModal) {
          try {
            const payload = { status: secondSelectedOption };
            await changeEpisodeStatus({
              episodeId: episodeData._id,
              clientRecordId: clientRecord._id,
              payload
            }).unwrap();
          } catch (ex) {
            console.error(ex);
            notification.error({
              message: 'Something went wrong while trying to change episode status.'
            });
          } finally {
            setIsShowConfirmArchived(false);
          }
        } else {
          const isShowEditModal =
            secondSelectedOption === EpisodeStatus.Active &&
            (episodeData.status === EpisodeStatus.Draft || episodeData.status === EpisodeStatus.Planned);
          if (isShowEditModal) {
            setEpisodeStatus(EpisodeStatus.Active);
            setIsEpisodeModalVisible(true);
          } else {
            setIsChangeEpisodeStatusModalVisible(true);
            setSelectedStatus(secondSelectedOption as EpisodeStatus);
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleClickOutSide = () => {
    setIsShowConfirmArchived(false);
  };

  return isUpdating ? (
    <EpisodeCardLoading />
  ) : (
    <div className={classNames(styles.container, isShowConfirmArchived && styles.warningEpisode)}>
      <div className={styles.headerContainer}>
        <div className={styles.infoHeader}>
          <div className={styles.episodeIdBox}>
            <div className={styles.episodeNo}>Episode {episodeData.order}</div>
            <div className={styles.episodeId}>{episodeData.episodeId}</div>
          </div>
          <div>
            <EpisodeStatusBadge status={episodeData.status} />
          </div>
          {!!episodeData.permissions?.length && (
            <div className={styles.permissionWrapper}>
              <div className={styles.permissionTitle}>STAFF ASSIGNED</div>
              <div className={styles.permissionListCard}>
                {episodeData.permissions.map((permissionObj, index) => (
                  <div className={styles.permissionCard} key={index}>
                    <img className={styles.permissionAvatar} alt={'permission avatar'} src={permissionObj.avatar} />
                    <div className={styles.permissionName}>
                      {permissionObj.firstName} {permissionObj.lastName}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {episodeData.status === EpisodeStatus.Archived && (
            <div className={styles.archivedLabel}>
              by {episodeData.creatorName} on {moment(episodeData.createdAt).format('DD/MM/YYYY')}
            </div>
          )}
        </div>
        {episodeData.status !== EpisodeStatus.Archived && (
          <div className={styles.menuContainer}>
            <NestedSelect
              type={DropdownType.Menu}
              options={actionOptions}
              value={{}}
              onSelected={handleClickDropdownMenu}
              onClickOutSide={handleClickOutSide}
              positionSubMenu={'left'}
              isSmallSubMenu
            />
          </div>
        )}
      </div>
      {episodeData.status !== EpisodeStatus.Archived && (
        <>
          <div className={styles.contentContainer}>
            <div className={styles.tableHeader}>
              <div className={classNames(styles.openBy, styles.tableHeaderItem)}>OPENED BY</div>
              <div className={classNames(styles.startDate, styles.tableHeaderItem)}>START DATE</div>
              <div className={classNames(styles.endDate, styles.tableHeaderItem)}>END DATE</div>
              <div className={classNames(styles.episodeType, styles.tableHeaderItem)}>
                {accountSettings?.episodeSettings?.typeLabel || 'EPISODE TYPE'}
              </div>
              <div className={classNames(styles.medicareReason, styles.tableHeaderItem)}>
                {accountSettings?.episodeSettings?.reasonLabel || 'MEDICAL REASON'}
              </div>
              {!accountSettings?.episodeSettings?.reasonUseOptionsDisabled && (
                <div className={classNames(styles.reasonUse, styles.tableHeaderItem)}>
                  {accountSettings?.episodeSettings?.reasonUseLabel || 'REASON USE'}
                </div>
              )}
              <div className={classNames(styles.detail, styles.tableHeaderItem)}>
                {accountSettings?.episodeSettings?.reasonDetailsLabel || 'DETAIL'}
              </div>
            </div>
            <div className={styles.tableContent}>
              <div className={classNames(styles.openBy, styles.tableContentItem)}>{episodeData.creatorName}</div>
              <div className={classNames(styles.startDate, styles.tableContentItem)}>
                {moment(episodeData.startDate).format('DD/MM/YYYY')}
              </div>
              <div className={classNames(styles.endDate, styles.tableContentItem)}>
                {episodeData.endDate
                  ? moment(episodeData.endDate).format('DD/MM/YYYY')
                  : episodeData.status === EpisodeStatus.Active
                  ? 'ON GOING'
                  : '-'}
              </div>
              <div className={classNames(styles.episodeType, styles.tableContentItem)}>{episodeData.type}</div>
              <div className={classNames(styles.medicareReason, styles.tableContentItem)}>
                {episodeData.reason?.medicalReason || '-'}
              </div>
              {!accountSettings?.episodeSettings?.reasonUseOptionsDisabled && (
                <div className={classNames(styles.reasonUse, styles.tableContentItem)}>
                  {episodeData.reason?.reasonUse || '-'}
                </div>
              )}
              <div className={classNames(styles.detail, styles.tableContentItem)}>
                {episodeData.reason?.details || '-'}
              </div>
            </div>
          </div>
        </>
      )}
      {isEpisodeModalVisible && (
        <EpisodeModal
          mode={'edit'}
          visible={isEpisodeModalVisible}
          clientRecord={clientRecord}
          draftOrPlanned={
            episodeData.status === EpisodeStatus.Draft || episodeData.status === EpisodeStatus.Planned
              ? episodeData.status
              : undefined
          }
          episode={{ ...episodeData, status: episodeStatus || episodeData.status }}
          onClose={() => {
            setIsEpisodeModalVisible(false);
            setEpisodeStatus(undefined);
          }}
        />
      )}
      {isChangeEpisodeStatusModalVisible && (
        <ChangeEpisodeStatusModal
          episode={episodeData}
          selectedStatus={selectedStatus}
          visible={isChangeEpisodeStatusModalVisible}
          onClose={() => setIsChangeEpisodeStatusModalVisible(false)}
          clientRecord={clientRecord}
          currentActiveEpisode={currentActiveEpisode}
        />
      )}
    </div>
  );
};

export default EpisodeCard;
