import { MicroJournalEntry } from '../../../../PatientDetailsInsights';

import styles from './JournalEntryCard.module.scss';

const JournalEntryCard = ({ date, title, response, tags }: MicroJournalEntry) => (
  <div className={styles.container}>
    <div className={styles.date}>{date}</div>
    <div className={styles.title}>{title}</div>
    <div className={styles.subtitle}>{response}</div>
    <div className={styles.tags}>
      {tags?.map((t, idx) => {
        const tagColor = t.polarity === 'positive' ? '#4abd035e' : '#0085bd30';
        return (
          <div className={styles.polarity} key={idx} style={{ background: tagColor }}>
            {t.title}
          </div>
        );
      })}
    </div>
  </div>
);

export default JournalEntryCard;
