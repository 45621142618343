import { notification } from 'antd';
import { FormListingInterface } from 'interfaces/Form/FormInterface';
import useSetDefault from 'pages/Assessments/hooks/useSetDefault';
import { useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccessToken } from 'utils/hooks/token';
import { patchFirstAssessmentSetDefault } from 'utils/http/CheckInService/Assessment/firstAssessment';
import {
  deleteCaseNoteTemplate,
  patchCaseNoteTemplateSetDefault,
  patchTogglePublishCaseNoteTemplate
} from 'utils/http/DocumentService/CaseNotes/caseNotesTemplate';
import { patchConsentFormSetDefault } from 'utils/http/DocumentService/ConsentForm/consentForm';

import styles from './AssessmentContent.module.scss';
import ADraftCard from './components/ADraftCard/ADraftCard';
import AssessmentColumnBase from './components/AssessmentColumnBase/AssessmentColumnBase';
import CardList from './components/ListCard/CardList';
import {
  deleteLetterTemplate,
  patchTogglePublishLetterTemplate
} from 'utils/http/DocumentService/Letters/lettersTemplate';
import { capitalize } from 'lodash';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';
import { useAppDispatch } from 'redux/hooks';
import {
  deleteCarePathwayTemplate,
  patchToggleCarePathwayTemplate
} from 'utils/http/ClinicianProfileService/CarePathwayTemplate/carePathwayTemplate';
import { deleteCarePathwayTemplateAttachment } from 'utils/http/DocumentService/Attachments/carePathwayTemplateAttachment';

interface AssessmentContentProps {
  formListData: FormListingInterface[];
}

const displayNameMap = {
  caseNotes: 'case note',
  letters: 'letter',
  reports: 'report',
  carePathway: 'carePathway'
};

const AssessmentContent = ({ formListData }: AssessmentContentProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { isInvoiceTemplateEnabled, isCarePlanEnabled } = useGetFeatureToggle();
  const { isInvoiceReadDenied } = useGetPermissionToggle();
  const dispatch = useAppDispatch();

  // unpublish/delete loading states
  const [cardLoading, setCardLoading] = useState({
    caseNotes: '',
    letters: '',
    reports: '',
    carePathway: ''
  });

  // filtered formListData based on columns ----------------------
  const [surveyDataList, setSurveyDataList] = useState(
    formListData.filter((obj) => obj.isPublished && obj.formType === 'survey')
  );
  const [consentList, setConsentList] = useState(
    formListData.filter((obj) => obj.isPublished && obj.formType === 'consent')
  );
  const [caseNotesTemplatesList, setCaseNotesTemplatesList] = useState(
    formListData.filter((obj) => obj.isPublished && obj.formType === 'caseNotes')
  );
  const [letterTemplatesList, setLetterTemplatesList] = useState(
    formListData.filter((obj) => obj.isPublished && obj.formType === 'letters')
  );
  const [reportTemplatesList] = useState(formListData.filter((obj) => obj.isPublished && obj.formType === 'reports'));

  const microCheckInDataList = formListData.filter((obj) => obj.isPublished && obj.formType === 'microCheckIn');

  const [invoiceTemplatesList] = useState(formListData.filter((obj) => obj.formType === 'invoices'));

  const [carePathwayTemplatesList, setCarePathwayTemplatesList] = useState(
    formListData.filter((obj) => obj.isPublished && obj.formType === 'carePathway')
  );

  const [nonActiveData, setNonActiveData] = useState(() =>
    formListData.filter((obj) => {
      if (isEdgeAdminView) {
        return (
          !obj.isPublished &&
          ['survey', 'consent', 'caseNotes', 'letters', 'reports', 'carePathway'].includes(obj.formType)
        );
      }
      if (isEdgeUserView) {
        return !obj.isPublished && ['microCheckIn', 'letters', 'reports'].includes(obj.formType);
      }
      if (isEdgeReceptionistView) {
        return !obj.isPublished && obj.formType === 'reports' && obj.isEditable;
      }
      return !obj.isPublished;
    })
  );

  // setDefault hooks --------------------------
  const { loading: oBDefaultLoading, onSetDefault: onBoardingSetDefault } = useSetDefault({
    listState: surveyDataList,
    setListState: setSurveyDataList,
    patchSetDefault: patchFirstAssessmentSetDefault,
    onSuccess: 'Survey updated.',
    onFail: 'Survey update fail.'
  });

  const { loading: consentDefaultLoading, onSetDefault: consentFormSetDefault } = useSetDefault({
    listState: consentList,
    setListState: setConsentList,
    patchSetDefault: patchConsentFormSetDefault,
    onSuccess: 'Consent form updated.',
    onFail: 'Consent form update fail.'
  });

  const { loading: caseNotesTemplateDefaultLoading, onSetDefault: caseNotesTemplateSetDefault } = useSetDefault({
    listState: caseNotesTemplatesList,
    setListState: setCaseNotesTemplatesList,
    patchSetDefault: patchCaseNoteTemplateSetDefault,
    onSuccess: 'Case note template updated.',
    onFail: 'Case note template update fail.'
  });

  // onDelete handlers ----------------------
  const handleDelete = (formType: 'caseNotes' | 'letters' | 'reports' | 'carePathway') => async (id: string) => {
    setCardLoading((loadingId) => ({
      ...loadingId,
      [formType]: id
    }));
    try {
      switch (formType) {
        case 'caseNotes':
          await deleteCaseNoteTemplate(token, id);
          setCaseNotesTemplatesList((list) => list.filter((obj) => obj._id !== id));
          break;
        case 'letters':
          await deleteLetterTemplate(token, accountId, id);
          setLetterTemplatesList((list) => list.filter((obj) => obj._id !== id));
          dispatch(documentServicesApiSlice.util.invalidateTags(['Letter Templates']));
          break;
        case 'carePathway': {
          const template = carePathwayTemplatesList.filter((obj) => obj._id === id)[0];
          await deleteCarePathwayTemplate(token, accountId, id);
          if (template.attachmentIds?.length) {
            await Promise.all([
              ...template.attachmentIds.map((attachmentId) =>
                deleteCarePathwayTemplateAttachment(token, accountId, attachmentId).catch((e) => console.error(e))
              )
            ]);
          }
          setCarePathwayTemplatesList((list) => list.filter((obj) => obj._id !== id));
          break;
        }
        default:
      }
      notification.success({
        message: `${capitalize(displayNameMap[formType])} template deleted.`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      notification.error({
        message: `Fail to delete the ${displayNameMap[formType]} template.`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    setCardLoading((loadingId) => ({
      ...loadingId,
      [formType]: ''
    }));
  };

  // onUnpublish handlers -------------------
  const handleUnpublish = (formType: 'caseNotes' | 'letters' | 'reports' | 'carePathway') => async (id: string) => {
    setCardLoading((loadingId) => ({
      ...loadingId,
      [formType]: id
    }));
    const unpublishForm = formListData.find((obj) => obj._id === id)!;
    try {
      switch (formType) {
        case 'caseNotes':
          await patchTogglePublishCaseNoteTemplate(token, id);
          setCaseNotesTemplatesList((list) => list.filter((obj) => obj._id !== id));
          break;
        case 'letters':
          await patchTogglePublishLetterTemplate(token, accountId, id);
          setLetterTemplatesList((list) => list.filter((obj) => obj._id !== id));
          dispatch(documentServicesApiSlice.util.invalidateTags(['Letter Templates']));
          break;
        case 'carePathway':
          await patchToggleCarePathwayTemplate(token, accountId, id, 'draft');
          setCarePathwayTemplatesList((list) => list.filter((obj) => obj._id !== id));
          break;
        default:
      }
      setNonActiveData((list) => [...list, unpublishForm]);
      notification.success({
        message: `${capitalize(displayNameMap[formType])} template updated.`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      notification.error({
        message: `Fail to unpublish the ${displayNameMap[formType]} template`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    setCardLoading((loadingId) => ({
      ...loadingId,
      [formType]: ''
    }));
  };

  const showSurvey = !isEdgeReceptionistView;
  const showMCI = isEdgeUserView || isNormalUserView;
  const showCaseNote = isEdgeUserView || isNormalUserView || isEdgeAdminView;

  return (
    <div className={styles.container}>
      {showSurvey && (
        <>
          <AssessmentColumnBase
            name={'Survey'}
            columnType={'active'}
            data={surveyDataList}
            viewOnly={isEdgeUserView}
            id={'columnSurveyId'}
          >
            <CardList
              columnId="survey"
              data={surveyDataList}
              onSetDefault={onBoardingSetDefault}
              loadingId={oBDefaultLoading}
              allowSelect
              allowEdit={!isEdgeUserView}
            />
          </AssessmentColumnBase>

          <AssessmentColumnBase
            name={'Consent Forms'}
            columnType={'active'}
            data={consentList}
            viewOnly={isEdgeUserView}
            id={'columnConsentFormId'}
          >
            <CardList
              columnId="consent"
              data={consentList}
              onSetDefault={consentFormSetDefault}
              loadingId={consentDefaultLoading}
              allowSelect={!isEdgeUserView}
              allowEdit={!isEdgeUserView}
            />
          </AssessmentColumnBase>
        </>
      )}
      {showMCI && (
        <AssessmentColumnBase
          name={'Micro Check-ins'}
          columnType={'active'}
          data={microCheckInDataList}
          id={'columnMCIId'}
        >
          <CardList columnId="microCheckIn" data={microCheckInDataList} allowSelect />
        </AssessmentColumnBase>
      )}
      {showCaseNote && (
        <AssessmentColumnBase
          name={'Case Notes'}
          columnType={'active'}
          data={caseNotesTemplatesList}
          viewOnly={isEdgeUserView}
          id={'columnCaseNoteTemplateId'}
        >
          <CardList
            columnId="caseNotes"
            data={caseNotesTemplatesList}
            onSetDefault={caseNotesTemplateSetDefault}
            loadingId={caseNotesTemplateDefaultLoading}
            allowSelect={!isEdgeUserView}
            allowEdit={!isEdgeUserView}
            onUnpublish={handleUnpublish('caseNotes')}
            onDelete={handleDelete('caseNotes')}
            circleLoadingId={cardLoading.caseNotes}
          />
        </AssessmentColumnBase>
      )}
      {isInvoiceTemplateEnabled && (isEdgeAdminView || isEdgeReceptionistView) && !isInvoiceReadDenied && (
        <AssessmentColumnBase
          name={'Invoice Templates'}
          columnType={'active'}
          data={invoiceTemplatesList}
          viewOnly={isEdgeUserView}
          id={'columnInvoiceTemplateId'}
        >
          <CardList columnId="invoices" data={invoiceTemplatesList} allowSelect />
        </AssessmentColumnBase>
      )}
      <AssessmentColumnBase
        name={'Letter Templates'}
        columnType={'active'}
        data={letterTemplatesList}
        viewOnly={isEdgeUserView}
        id={'columnLetterTemplateId'}
      >
        <CardList
          columnId="letters"
          data={letterTemplatesList}
          allowSelect
          allowEdit
          onUnpublish={handleUnpublish('letters')}
          onDelete={handleDelete('letters')}
          circleLoadingId={cardLoading.letters}
        />
      </AssessmentColumnBase>
      <AssessmentColumnBase
        name={'Report Templates'}
        columnType={'active'}
        data={reportTemplatesList}
        id={'columnLetterTemplateId'}
      >
        <CardList columnId="reports" data={reportTemplatesList} allowSelect circleLoadingId={cardLoading.reports} />
      </AssessmentColumnBase>
      {isCarePlanEnabled && (
        <AssessmentColumnBase
          name={'Care Pathway Templates'}
          columnType={'active'}
          data={carePathwayTemplatesList}
          id={'carePathwayTemplateId'}
        >
          <CardList
            columnId="carePathway"
            data={carePathwayTemplatesList}
            allowSelect
            allowEdit
            onUnpublish={handleUnpublish('carePathway')}
            onDelete={handleDelete('carePathway')}
            circleLoadingId={cardLoading.carePathway}
          />
        </AssessmentColumnBase>
      )}
      <AssessmentColumnBase
        name={'Draft & Inactive'}
        columnType={'draft'}
        data={nonActiveData}
        showCreateCard
        id={'columnDAndIId'}
      >
        <ADraftCard data={nonActiveData} />
      </AssessmentColumnBase>
    </div>
  );
};

export default AssessmentContent;
