import { clientRecordsEncryptedInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { GetPackageAssignmentList } from 'interfaces/Packages/packages';
import { PracticeInterface } from 'interfaces/Profile/Profile';
import moment from 'moment';
import { getAddressBookName } from 'pages/AddressBook/helpers';
import { AddressBookListData } from 'pages/AddressBook/Interfaces/AddressBook';
import {
  AccountingPlatform,
  IInvoiceTemplate,
  IntegrationStatus,
  InvoiceSettings,
  InvoiceStatus,
  PaymentMethod
} from 'pages/Invoices/interface';
import { GeneralPractitionerInterface } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/GPDetails/interface';
import { InvoiceGenerationFormValue } from 'redux/invoices/createInvoiceWithTemplateSlice';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';

export const calculateTaxValue = (taxRate: number, cost: number, quantity: number) => (taxRate / 100) * cost * quantity;

export const calculateTotalValue = (taxRate: number, cost: number, quantity: number) => {
  if (isNaN(cost) || isNaN(quantity)) {
    return 0;
  }
  const taxValue = calculateTaxValue(taxRate, cost, quantity);
  const totalValue = cost * quantity + taxValue;

  return totalValue;
};

export const getRecipientsValue = ({
  invoiceTemplate,
  clientEncryptDetails
}: {
  invoiceTemplate: IInvoiceTemplate;
  clientEncryptDetails: clientRecordsEncryptedInterface;
}) => {
  const { recipient } = invoiceTemplate;
  const { clientProfiles, keyClientContacts } = clientEncryptDetails;

  switch (recipient.type) {
    case 'client':
      return clientProfiles?.map((item) => ({
        name: item.name,
        address: '',
        email: item.email,
        mobileNumber: item.mobileNumber,
        reference: '',
        registrationNumber: ''
      }));
    case 'keyClientContact':
      return keyClientContacts
        ?.filter((item) => item.tags?.some((item) => recipient.tags?.includes(item)))
        ?.map((item) => ({
          name: `${item.firstName} ${item.lastName}`,
          address: '',
          email: item.email,
          mobileNumber: item.mobileNumber,
          reference: '',
          registrationNumber: ''
        }));
    case 'funder':
      return null;
    default:
      return null;
  }
};

export const getInvoiceId = ({
  clientEncryptDetails,
  invoiceSettings
}: {
  clientEncryptDetails?: clientRecordsEncryptedInterface;
  invoiceSettings?: InvoiceSettings;
}) => {
  const invoiceCount = clientEncryptDetails?.invoiceSummary?.count || 0;
  const invoiceOrder = clientEncryptDetails?.invoiceSummary?.order || 0;

  const isQuickBooksConnected =
    invoiceSettings?.integration?.connections.find((connection) =>
      [AccountingPlatform.QuickBooksOnline, AccountingPlatform.QuickBooksOnlineSandbox].includes(connection.platform)
    ) && invoiceSettings?.integration.status === IntegrationStatus.Connected;

  if (!isNaN(invoiceCount)) {
    const date = moment().format('YYYYMM');
    return `${date}-${invoiceOrder}-${invoiceCount + 1}${
      isQuickBooksConnected ? `-${Math.random().toString(36).substring(2, 7).toUpperCase()}` : ''
    }`;
  }
};

export const getInitialServiceDeliveryData = (
  clientEncryptDetails: clientRecordsEncryptedInterface,
  generalPractitioner: GeneralPractitionerInterface
) => {
  const initialServiceDeliveryData = clientEncryptDetails.clientProfiles?.map(({ medicare, mobileNumber }) => {
    const generalPractitionerExists = clientEncryptDetails?.referral?.generalPractitionerId && generalPractitioner;
    return {
      claimant: {
        name: `${medicare?.firstName || clientEncryptDetails.clientProfiles[0].firstName} ${
          medicare?.lastName || clientEncryptDetails.clientProfiles[0].lastName
        }`,
        dateOfBirth: medicare?.dateOfBirth ? moment(medicare.dateOfBirth).format(MOMENTJS_DATE_FORMAT) : '',
        medicareNumber: medicare?.number?.toString(),
        irn: medicare?.irn?.toString(),
        expiryDate: '',
        dva: '',
        phoneNumber: mobileNumber,
        address: ''
      },
      referral: {
        name: generalPractitionerExists ? generalPractitioner.name : '',
        date: clientEncryptDetails?.referral?.date || '',
        providerNumber: generalPractitionerExists ? generalPractitioner.medicareProviderNumber : ''
      }
    };
  });

  return initialServiceDeliveryData.length ? initialServiceDeliveryData[0] : null;
};

export const getInvoiceReqPayload = (
  invoiceStatus: InvoiceStatus,
  invoiceForm: InvoiceGenerationFormValue,
  funderId?: string
) => {
  return {
    ...invoiceForm,
    dueDate: moment(invoiceForm.dueDate, MOMENTJS_DATE_FORMAT).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT),
    issueDate: moment(invoiceForm.issueDate, MOMENTJS_DATE_FORMAT).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT),
    status: invoiceStatus,
    items: invoiceForm.items.map((item) => ({
      ...item,
      cost: Number(item.cost),
      quantity: Number(item.quantity)
    })),
    addressBook: funderId ? { _id: funderId } : null
  };
};

export const initNewInvoiceWithTemplateValues = ({
  practice,
  invoiceTemplate,
  invoiceTemplateId,
  paymentMethods,
  invoiceSettings
}: {
  practice?: PracticeInterface;
  invoiceTemplateId: string;
  paymentMethods: PaymentMethod[];
  invoiceSettings: InvoiceSettings;
  invoiceTemplate?: IInvoiceTemplate;
}) => {
  const currentTimeAsString = moment().format(MOMENTJS_DATE_FORMAT);
  return {
    practice: {
      ...practice,
      address: practice?.locations?.[0]
    },
    paymentMethods,
    issueDate: currentTimeAsString,
    ...(invoiceSettings.taxRate && { taxRate: invoiceSettings.taxRate }),
    note: invoiceTemplate?.invoiceNotes,
    invoiceTemplateId: invoiceTemplateId,
    items: [],
    dueDate: currentTimeAsString,
    title: invoiceTemplate?.invoiceTitle
  };
};

export const getRecipientValuesFromPayer = ({
  selectedPackageData,
  contactData,
  selectedClient
}: {
  selectedPackageData: GetPackageAssignmentList | undefined;
  contactData: AddressBookListData | undefined;
  selectedClient: clientRecordsInterface | undefined;
}) => {
  let recipientData = {
    name: '',
    address: '',
    email: '',
    mobileNumber: '',
    reference: '',
    registrationNumber: ''
  };

  if (selectedPackageData?.assignee.funder.funderId) {
    const foundFunderData = contactData?.addressBooks.find(
      (item) => item._id === selectedPackageData?.assignee.funder.funderId
    );
    if (foundFunderData) {
      const { line1, line2, suburb, state, postcode, country } = foundFunderData.address || {};
      const funderKeyContact = foundFunderData.contacts.find((contact) => contact.isPrimary);

      recipientData.name = getAddressBookName(foundFunderData);
      recipientData.address = `${line1 ? `${line1} ` : ''}${line2 ? `${line2} ` : ''}${suburb ? `${suburb} ` : ''}${
        postcode ? `${postcode} ` : ''
      }${state ? `${state} ` : ''}${country ? `${country} ` : ''}`;
      recipientData.mobileNumber = funderKeyContact?.mobileNumber || foundFunderData?.organisation?.phone || '';
      recipientData.email = funderKeyContact?.email || '';
      recipientData.registrationNumber = foundFunderData.code || '';
    }
  } else if (
    selectedPackageData?.assignee.funder.type === 'default' &&
    selectedPackageData?.assignee.funder.name === 'Out of Pocket' &&
    selectedClient?.clientProfiles?.length
  ) {
    return selectedClient.clientProfiles.map((item) => ({
      name: item.name,
      address: '',
      email: item.email,
      mobileNumber: item.mobileNumber,
      reference: '',
      registrationNumber: ''
    }));
  }

  return [recipientData];
};
