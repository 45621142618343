import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ReportBuilderPreview from '../components/ReportBuilderPreview/ReportBuilderPreview';
import { useNavigate } from 'react-router-dom';
import { useGetAccessToken } from 'utils/hooks/token';
import { Report } from '../../../interfaces/Reports/report';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

interface ReportClientTabPreviewProps {
  reportData: Report;
}

const ReportClientTabPreview = ({ reportData }: ReportClientTabPreviewProps) => {
  const navigate = useNavigate();
  const { REPORTS } = useRoutesGenerator();
  const { token } = useGetAccessToken();

  const backToListing = () => {
    navigate(REPORTS.BASE);
  };

  const clinicianDetails = {
    clinician: reportData.clinician,
    practice: reportData.practice
  };

  return (
    <HelmetWrapper title={'Tacklit - Report Details'}>
      <ReportBuilderPreview
        data={reportData}
        title={'Report & Letter'}
        reportId={reportData._id}
        token={token}
        backBtnLabel={'Back to report listing'}
        clinicianDetails={clinicianDetails}
        previewMode
        onBackToEditingMode={() => backToListing()}
      />
    </HelmetWrapper>
  );
};

export default ReportClientTabPreview;
