import { Modal } from 'antd';
import styles from './EditRoomModal.module.scss';
import ModalHeader from 'components/v2/ModalHeader/ModalHeader';
import RoomForm from '../RoomForm/RoomForm';
import { useGetAccessToken } from 'utils/hooks/token';
import { CreateRoomType, TimeSlot } from 'interfaces/Schedule/Room';
import {
  updateRoom,
  updateRoomAndCancelFutureEvent,
  updateRoomAndKeepFutureEvent
} from 'utils/http/ScheduleService/Rooms/rooms';
import { useState } from 'react';
import { useFetchRoom } from 'utils/hooks/GetRoomList/useFetchRoom';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { capitalize } from 'lodash';
import moment from 'moment';

const TIME_FORMAT_WITHOUT_ASIDE = 'HH:mm',
  TIME_FORMAT_WITH_ASIDE = 'hh:mm a';
interface EditRoomModalProps {
  roomId: string;
  visible: boolean;
  onClose: (reload: boolean) => void;
}

const getUpdateRoomConnector = (roomStatus: string) => {
  if (roomStatus === 'pauseBooking') {
    return updateRoomAndKeepFutureEvent;
  }
  if (roomStatus === 'pauseAndCancelBooking') {
    return updateRoomAndCancelFutureEvent;
  }
  return updateRoom;
};

const EditRoomModal = ({ roomId, visible, onClose }: EditRoomModalProps) => {
  const { token } = useGetAccessToken();
  const [isLoading, setIsLoading] = useState(false);
  const { room, isRoomLoading } = useFetchRoom(roomId);
  const [dataError, setDataError] = useState<string[]>([]);

  const onCancel = () => onClose(false);

  const reFormatTimes = (timeSlots: TimeSlot[]) => {
    if (timeSlots && timeSlots.length > 0) {
      return timeSlots.map((timeSlot) => {
        return {
          startTime: moment(timeSlot.startTime, TIME_FORMAT_WITH_ASIDE).format(TIME_FORMAT_WITHOUT_ASIDE),
          endTime: moment(timeSlot.endTime, TIME_FORMAT_WITH_ASIDE).format(TIME_FORMAT_WITHOUT_ASIDE)
        };
      });
    }
    return [] as TimeSlot[];
  };

  const onSubmit = async (value: CreateRoomType, roomStatus: string) => {
    // Re-format startTime, endTime
    const massageData = {
      ...value,
      availability: {
        monday: {
          isAvailable: value.availability.monday.isAvailable,
          timeSlots: reFormatTimes(value.availability.monday.timeSlots || [])
        },
        tuesday: {
          isAvailable: value.availability.tuesday.isAvailable,
          timeSlots: reFormatTimes(value.availability.tuesday.timeSlots || [])
        },
        wednesday: {
          isAvailable: value.availability.wednesday.isAvailable,
          timeSlots: reFormatTimes(value.availability.wednesday.timeSlots || [])
        },
        thursday: {
          isAvailable: value.availability.thursday.isAvailable,
          timeSlots: reFormatTimes(value.availability.thursday.timeSlots || [])
        },
        friday: {
          isAvailable: value.availability.friday.isAvailable,
          timeSlots: reFormatTimes(value.availability.friday.timeSlots || [])
        },
        saturday: {
          isAvailable: value.availability.saturday.isAvailable,
          timeSlots: reFormatTimes(value.availability.saturday.timeSlots || [])
        },
        sunday: {
          isAvailable: value.availability.sunday.isAvailable,
          timeSlots: reFormatTimes(value.availability.sunday.timeSlots || [])
        }
      }
    } as CreateRoomType;

    setIsLoading(true);
    const response = await getUpdateRoomConnector(roomStatus)(token, roomId, massageData);
    if (response?.statusCode === 204) {
      onClose(true);
    } else if (response.statusCode === 409) {
      const error = await response.json();
      let errorList = [];
      const conflictedAppointmentsCount = error?.errorObject?.conflictedAppointmentsCount || {};
      for (const day in conflictedAppointmentsCount) {
        errorList.push(
          `There are ${conflictedAppointmentsCount[day]} appointment${
            conflictedAppointmentsCount[day] > 1 ? 's' : ''
          } affected by the new time slot changes on ${capitalize(day)}`
        );
      }
      setDataError(errorList);
    }
    setIsLoading(false);
  };
  return (
    <Modal
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
      width={720}
      open={visible}
      footer={null}
      destroyOnClose
      closable={false}
    >
      <ModalHeader title="Edit Room" onCancel={onCancel} />
      <div className={styles.container}>
        {isRoomLoading ? (
          <LoadingDot />
        ) : (
          <RoomForm onSubmit={onSubmit} isLoading={isLoading} room={room} isEdit dataError={dataError} />
        )}
      </div>
    </Modal>
  );
};

export default EditRoomModal;
