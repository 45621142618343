import {
  CalendarFilterInterface,
  CalendarHighLightInterface,
  CalendarRoomFilterInterface
} from 'components/v2/CalendarFilter/interfaces';

const CALENDAR_FILTERS = 'data:calendar_filters_v2';

interface SelectedFilter {
  id: string;
  isEdgeAdminView: boolean;
  selectedFilters?: CalendarFilterInterface[];
  selectedRooms?: CalendarRoomFilterInterface[];
  selectedHighLights?: CalendarHighLightInterface[];
}

export const setSelectedFiltersToLocalStorage = (selectedFilter: SelectedFilter) => {
  const oldFilterString = localStorage.getItem(CALENDAR_FILTERS);
  let oldFilterArray: any[] = [];
  if (oldFilterString) {
    oldFilterArray = JSON.parse(oldFilterString);
    const currentItem = oldFilterArray.find(
      (oldItem) => oldItem.id === selectedFilter.id && oldItem.isEdgeAdminView === selectedFilter.isEdgeAdminView
    );
    if (currentItem) {
      oldFilterArray = oldFilterArray.map((item) =>
        item.id === selectedFilter.id && item.isEdgeAdminView === selectedFilter.isEdgeAdminView ? selectedFilter : item
      );
    } else {
      oldFilterArray.push(selectedFilter);
    }
  } else {
    oldFilterArray.push(selectedFilter);
  }
  localStorage.setItem(CALENDAR_FILTERS, JSON.stringify(oldFilterArray));
};

export const getSelectedFiltersFromLocalStorage = (id: string, isEdgeAdminView: boolean): SelectedFilter | null => {
  const filterString = localStorage.getItem(CALENDAR_FILTERS);
  if (filterString) {
    const filterArray: any[] = JSON.parse(filterString);
    return filterArray.find((item) => item.id === id && item.isEdgeAdminView === isEdgeAdminView);
  }
  return null;
};
