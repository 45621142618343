import { MicroCheckInAssessmentStruct } from 'pages/Assessments/Interface';
import { useEffect, useState } from 'react';
import { getMicroCheckInAssessments } from 'store/CheckIn/GetMCIAssessment/action';
import { useGetAccessToken } from './token';

export const useMCIList = ({ isEditMode }: { isEditMode?: boolean }) => {
  const { token } = useGetAccessToken();
  const [mCIList, setMCIList] = useState<MicroCheckInAssessmentStruct[]>([]);
  const [mCIListInitializing, setMCIListInitializing] = useState(isEditMode);

  const fetchAssessment = async () => {
    setMCIListInitializing(true);
    const callGetMCIAssessments = await getMicroCheckInAssessments(token);
    const mCIList = callGetMCIAssessments.filter((mci: { isPublished: boolean }) => mci.isPublished);
    setMCIList(mCIList);
    setMCIListInitializing(false);
  };

  useEffect(() => {
    if (isEditMode && token) {
      fetchAssessment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, token]);

  return { mCIList, mCIListInitializing };
};
