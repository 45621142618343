import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';
import moment from 'moment';
import Status from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/SystemConnection/components/ConnectedIntegration/components/components/Status/Status';
import { CLAIM_INTEGRATIONS, FINANCE_INTEGRATIONS } from 'pages/ControlPanel/ControlPanel/constants';
import { Integration as IntegrationInterface, IntegrationType } from 'pages/ControlPanel/Interfaces/Integration';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import IntegrationHistory from './components/IntegrationHistory/IntegrationHistory';
import MedicareDetails from './components/MedicareDetails/MedicareDetails';
import styles from './Integration.module.scss';

interface IntegrationComponentInterface {
  integration: IntegrationInterface;
  refetchSystemIntegration: () => void;
}

const Integration = ({ integration, refetchSystemIntegration }: IntegrationComponentInterface) => {
  const navigate = useNavigate();
  const { CONTROL_PANEL } = useRoutesGenerator();
  const { label, connectedAt, connectedBy, provider, status, type } = integration;

  const handleBackButtonClick = () => {
    navigate(`${CONTROL_PANEL.SYSTEM_CONNECTIONS}`);
  };

  const summary = [...FINANCE_INTEGRATIONS, ...CLAIM_INTEGRATIONS].find(
    (integration) => integration.provider === provider
  )?.summary;

  return (
    <div className={styles.container}>
      <div className={styles.backBtnContainer}>
        <ButtonAlt
          size={'medium'}
          variant={'text'}
          icon={'arrow_back_ios_new'}
          iconVariant={IconVariant.Filled}
          onClick={() => handleBackButtonClick()}
        >
          View All Integrations
        </ButtonAlt>
      </div>
      <div className={styles.contentsContainer}>
        <div className={styles.statusContainer}>
          <div>Status</div>
          <div>
            <Status status={status} />
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.nameContainer}>{`Name: ${label}`}</div>
          <div>{`Connected since: ${connectedAt ? moment(connectedAt).format('DD MMM YYYY') : '-'}`}</div>
          <div>{`Set up by: ${connectedBy || '-'}`}</div>
          {summary && (
            <div className={styles.summaryContainer}>
              <div>{`Summary of ${label}:`}</div>
              <div className={styles.summary}>{summary}</div>
            </div>
          )}
        </div>
        {type === IntegrationType.Account && <IntegrationHistory integration={integration} />}
        {type === IntegrationType.Claim && (
          <MedicareDetails integration={integration} refetchSystemIntegration={refetchSystemIntegration} />
        )}
      </div>
    </div>
  );
};

export default Integration;
