import styles from './OptionList.module.scss';
import classnames from 'classnames';
import { Col, Row } from 'antd';
import CheckBox from 'components/CheckBox/CheckBox';
import ReadMore from 'components/ReadMore/ReadMore';
import SearchBar from 'components/v2/SearchBar/SearchBar';

import { useState } from 'react';

interface Option {
  id: string;
  title?: string;
  description?: string;
}

interface OptionListProps {
  clientId?: string;
  title?: string;
  subTitle?: string;
  popularTxt?: string;
  listOption: Option[];
  selectedOptions: Option[];
  onSelect: (isChecked: boolean, id: string) => void;
}

const OptionList = ({
  clientId,
  title,
  subTitle,
  popularTxt,
  listOption,
  selectedOptions,
  onSelect
}: OptionListProps) => {
  const [searchValue, setSearchValue] = useState('');

  const filteredList = listOption.filter((listObj: Option) => {
    const filter = listObj.title?.toLowerCase().includes(searchValue.toLowerCase().trim());
    const acroFilter = listObj.id?.toLowerCase().includes(searchValue.toLowerCase().trim());

    return filter || acroFilter ? true : false;
  });

  return (
    <div className={styles.container}>
      {title && <div className={styles.title}>{title}</div>}
      {subTitle && <div className={styles.subtitle}>{subTitle}</div>}
      {popularTxt && <div className={styles.popular}>{popularTxt}</div>}
      <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
      <div className={styles.optionListWrapper}>
        {filteredList.length > 0 ? (
          filteredList.map((option, index) => {
            const isSelected = !!selectedOptions.find((selected) => option.id === selected.id);
            return (
              <Row key={index} gutter={12} align="top" className={styles.option}>
                <Col span={2}>
                  <CheckBox
                    id={`${clientId}_${option.id}`}
                    value={isSelected}
                    onChange={(e) => onSelect(e.target.checked, option.id)}
                  />
                </Col>
                <Col span={22}>
                  <label htmlFor={`${clientId}_${option.id}`}>
                    <div className={classnames(styles.optionTitle, isSelected ? styles.isSelected : '')}>
                      {option.title}
                    </div>
                    {option.description && <ReadMore maxHeight={60} text={option?.description || ''} />}
                  </label>
                </Col>
              </Row>
            );
          })
        ) : (
          <div className={styles.noItems}>
            <i className="material-icons-outlined">search_off</i>
            No matching assessments
          </div>
        )}
      </div>
    </div>
  );
};

export default OptionList;
