import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import styles from './CarePathwayListFilter.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useContext, useMemo, useState } from 'react';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetPractitionerListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import { debounce } from 'lodash';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import { useGetMinifiedClientRecordQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { CarePathwayListFilterType } from '../../types';
import { ModalVisibilityContext } from 'components/SideDashboardT23/ModalVisibilityContext';
import { AccessRight } from 'interfaces/Clients/clinician';

export const CARE_PATHWAY_STATUS = {
  active: 'Active',
  closed: 'Closed',
  completed: 'Completed',
  draft: 'Draft'
};

interface CarePathwayListFilterProps {
  setFilter: (filter: Partial<CarePathwayListFilterType>) => void;
  filter: CarePathwayListFilterType;
}

const PER_PAGE = 50;

export const CarePathwayListFilter = ({ setFilter, filter }: CarePathwayListFilterProps) => {
  const { accountId } = useGetAccountId();
  const [practitionerCurrentPage, setPractitionerCurrentPage] = useState(1);
  const [practitionerSearchText, setPractitionerSearchText] = useState('');
  const modalVisibility = useContext(ModalVisibilityContext);

  const {
    data: practitionerData,
    isLoading: isPractitionerDataLoading,
    isFetching: isPractitionerDataFetching
  } = useGetPractitionerListQuery({
    accountId,
    params: {
      page: practitionerCurrentPage,
      perPage: PER_PAGE,
      status: 'active',
      searchValue: practitionerSearchText,
      accessRights: [AccessRight.Admin, AccessRight.User]
    }
  });

  const practitionerList: FilterCheckListItem[] = practitionerData
    ? practitionerData.clinicians.map((item) => ({ name: item.name, _id: item._id }))
    : [];
  const totalPractitionerPage = practitionerData?.paging ? Math.ceil(practitionerData.paging.totalItems / PER_PAGE) : 1;

  const [clientCurrentPage, setClientCurrentPage] = useState(1);
  const [clientSearchText, setClientSearchText] = useState('');
  const {
    data: clientData,
    isLoading: isClientDataLoading,
    isFetching: isClientDataFetching
  } = useGetMinifiedClientRecordQuery({
    accountId,
    params: {
      page: clientCurrentPage,
      perPage: PER_PAGE,
      searchValue: clientSearchText,
      recordStatus: 'active',
      searchBy: SearchFilterEnum.FirstOrLastName
    }
  });

  const clientList: FilterCheckListItem[] = clientData
    ? clientData.clientRecords.map((item) => ({
        name: `${item.clientProfiles[0].firstName} ${item.clientProfiles[0].lastName}`,
        _id: item._id
      }))
    : [];
  const totalClientPage = clientData?.paging ? Math.ceil(clientData.paging.totalItems / PER_PAGE) : 1;

  const { staff: selectedPractitioners, client } = filter;

  const debouncedSetPractitionersSearchText = useMemo(
    () =>
      debounce((value) => {
        setPractitionerSearchText(value);
        setPractitionerCurrentPage(1);
      }, 1000),
    []
  );

  const debouncedSetClientSearchText = useMemo(
    () =>
      debounce((value) => {
        setClientSearchText(value);
        setClientCurrentPage(1);
      }, 1000),
    []
  );

  const onChangePractitionerFilter = (selectedPractitioners: FilterCheckListItem[]) => {
    setFilter({
      staff: selectedPractitioners
    });
  };

  const onChangeClientFilter = (client: FilterCheckListItem[]) => {
    setFilter({
      client
    });
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.label}>FILTER BY:</div>
        <div className={styles.dropdownContainer}>
          <FilterDropdown
            id={'practitioner'}
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            menuItems={practitionerList}
            onChangeItem={onToggleFilter(practitionerList, selectedPractitioners, onChangePractitionerFilter)}
            searchable
            selectedFilterList={selectedPractitioners}
            showSearchIcon
            showToggleAllButtons
            loading={isPractitionerDataLoading}
            hasMoreData={totalPractitionerPage > practitionerCurrentPage || isPractitionerDataFetching}
            isFetchingMore={isPractitionerDataFetching}
            loadMore={() => {
              if (!isPractitionerDataFetching && practitionerCurrentPage <= totalPractitionerPage) {
                setPractitionerCurrentPage(practitionerCurrentPage + 1);
              }
            }}
            setSearchText={debouncedSetPractitionersSearchText}
          >
            Staff Lead
          </FilterDropdown>
          <FilterDropdown
            id={'practitioner'}
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            menuItems={clientList}
            onChangeItem={onToggleFilter(clientList, client, onChangeClientFilter)}
            searchable
            selectedFilterList={client}
            showSearchIcon
            showToggleAllButtons
            loading={isClientDataLoading}
            hasMoreData={totalClientPage > clientCurrentPage || isClientDataFetching}
            isFetchingMore={isClientDataFetching}
            loadMore={() => {
              if (!isClientDataFetching && clientCurrentPage <= totalClientPage) {
                setPractitionerCurrentPage(clientCurrentPage + 1);
              }
            }}
            setSearchText={debouncedSetClientSearchText}
          >
            Client
          </FilterDropdown>
        </div>
      </div>
      <div>
        <ButtonAlt
          icon="add_circle_outline"
          variant={'contained'}
          onClick={() => modalVisibility?.setAddNewPathwayModalVisible(true)}
          fullWidth
        >
          New Pathway
        </ButtonAlt>
      </div>
    </div>
  );
};
