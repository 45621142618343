import { Draggable } from '@mobiscroll/react';
import styles from './Appointment.module.scss';
import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useDeleteCarePathwayItemMutation } from 'redux/endpoints/clinicianProfileServices/carePathway';
import { useDispatch } from 'react-redux';
import { deleteAppointmentTypeItem, selectCarePlanEditorSlice } from 'redux/carePlan/carePlanEditorSlice';
import { DELIVERY_TYPE_ICONS, DELIVERY_TYPE_LABELS } from 'interfaces/Schedule/AppointmentType';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useAppSelector } from 'redux/hooks';
import { CarePlanAppointmentType } from 'interfaces/CarePathway/CarePathway';

interface AppointmentProps {
  carePlanAppointment: CarePlanAppointmentType;
  eligibleAddNewAppointment: boolean;
}

const Appointment = ({ carePlanAppointment, eligibleAddNewAppointment }: AppointmentProps) => {
  const dispatch = useDispatch();
  const { accountId } = useGetAccountId();
  const [draggable, setDraggable] = useState<HTMLDivElement>();
  const [deleteCarePathwayItem] = useDeleteCarePathwayItemMutation();
  const carePlanData = useAppSelector(selectCarePlanEditorSlice);

  const onDeleteAppointmentItem = async () => {
    try {
      await deleteCarePathwayItem({
        accountId,
        carePathwayId: carePlanData?._id || '',
        carePathwayItemId: carePlanAppointment._id
      });

      dispatch(deleteAppointmentTypeItem({ _id: carePlanAppointment._id }));
    } catch (error) {
      console.error('Error delete care pathway item:', error);
    }
  };

  const setDragElm = useCallback((elm: HTMLDivElement) => {
    setDraggable(elm);
  }, []);

  const roomNeeded = carePlanAppointment.appointmentType?.roomSettings?.required;
  const appointmentType = useMemo(
    () => ({
      id: carePlanAppointment.appointmentType._id,
      _id: carePlanAppointment._id,
      title: carePlanAppointment.appointmentType.name,
      duration: carePlanAppointment.appointmentType.duration.minutes,
      start: moment().toDate(),
      end: moment().add(carePlanAppointment.appointmentType.duration.minutes, 'minutes').toDate(),
      appointmentType: carePlanAppointment.appointmentType
    }),
    [carePlanAppointment]
  );

  const deliveryTypeOptions = useMemo(() => {
    return carePlanAppointment.appointmentType.deliveryOptions.map((option) => ({
      label: DELIVERY_TYPE_LABELS[option],
      value: option,
      icon: DELIVERY_TYPE_ICONS[option]
    }));
  }, [carePlanAppointment]);

  const getAppointmentDetails = carePlanData.appointments.find(
    (appointmentObj) => appointmentObj.carePathwayItemId === carePlanAppointment._id
  );

  return (
    <div>
      <div
        ref={setDragElm}
        className={classNames(
          styles.container,
          carePlanAppointment.active && eligibleAddNewAppointment ? styles.active : styles.deactivate,
          (carePlanAppointment?.isProcessing || carePlanAppointment?.isDropped) && styles.processing,
          roomNeeded && getAppointmentDetails && !getAppointmentDetails?.room?.roomId && styles.roomRequired
        )}
      >
        <div className={styles.row}>
          <div className={styles.left}>
            <div>{carePlanAppointment.appointmentType.name}</div>
            <div>{carePlanAppointment.appointmentType.duration.minutes} minutes</div>
          </div>
          <div>
            {carePlanAppointment.active && !carePlanData.package && (
              <div
                className={classNames('material-icons-outlined', styles.closeIcon)}
                onClick={onDeleteAppointmentItem}
              >
                close
              </div>
            )}
          </div>
        </div>
        <div className={classNames(styles.row, styles.center)}>
          <div className={classNames(styles.left, styles.deliveryType)}>
            {deliveryTypeOptions.map((deliveryObj, index) => (
              <div key={index} className={`material-icons ${styles.deliveryIcon}`}>
                {deliveryObj.icon}
              </div>
            ))}
          </div>
          <div className={styles.flex}>
            <i className="material-icons-outlined">room_preferences</i>
            <div>{roomNeeded ? 'Yes' : 'No'}</div>
          </div>
        </div>
        {carePlanAppointment.active && !carePlanAppointment.isDropped && (
          <Draggable dragData={appointmentType} element={draggable} />
        )}
      </div>
    </div>
  );
};

export default Appointment;
