import { GroupListData, Groups } from 'pages/Groups/Interfaces/Groups';
import { useEffect, useState } from 'react';
import { useGetAllGroupsQuery, useGetGroupTagsQuery } from './group';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { selectFilters, selectPaging, selectSearch, selectSorting } from 'redux/groups/groupSlice';
import { useAppSelector } from 'redux/hooks';
import { getGroupRecordRequestPayload } from 'pages/Groups/GroupsList/components/GroupListContent/components/constants';

export const useGetGroupList = () => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const filters = useAppSelector(selectFilters);
  const pagination = useAppSelector(selectPaging);
  const search = useAppSelector(selectSearch);
  const sorting = useAppSelector(selectSorting);

  const [groupList, setGroupList] = useState<Groups[]>([]);
  const [groupCountByStatus, setGroupCountByStatus] = useState<GroupListData['count']>({
    active: 0,
    closed: 0
  });
  const [paging, setPaging] = useState<GroupListData['paging']>({
    page: 1,
    perPage: 10,
    totalItems: 0
  });

  const {
    data: groupTags,
    isLoading: isGroupTagsLoading,
    isFetching: isGroupTagsFetching
  } = useGetGroupTagsQuery({ accountId }, { skip: !accountId });

  const {
    data: groups,
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching
  } = useGetAllGroupsQuery(
    {
      accountId: accountId,
      isAdmin: isEdgeAdminView || isEdgeReceptionist,
      params: {
        ...getGroupRecordRequestPayload({
          paging: pagination,
          sorting,
          filters,
          search
        })
      }
    },
    { skip: !accountId }
  );

  const isGroupListLoading = isGroupTagsLoading || isGroupTagsFetching || isGroupsLoading || isGroupsFetching;

  useEffect(() => {
    if (!isGroupTagsLoading && !isGroupTagsFetching && !isGroupsLoading && !isGroupsFetching && groups && groupTags) {
      const groupListsWithGroupTags = groups.groups.map((group) => ({
        ...group,
        tags: group.tags
          .map((tag) => {
            return groupTags.find(({ _id }) => _id === tag);
          })
          .filter((val) => val !== undefined)
      })) as Groups[];

      setGroupList(groupListsWithGroupTags);
      setGroupCountByStatus(groups.count);
      setPaging(groups.paging);
    }
  }, [groupTags, groups, isGroupTagsLoading, isGroupTagsFetching, isGroupsLoading, isGroupsFetching]);

  return {
    groupList,
    groupCountByStatus,
    paging,
    isGroupListLoading
  };
};
