import { referralFilesInterface } from 'interfaces/Clients/clientsRecord';
import { ReferrerType } from 'pages/Referrals/interface';

export enum ReferralStatus {
  New = 'new',
  Acknowledged = 'acknowledged',
  InformationRequest = 'informationRequest',
  Accepted = 'accepted',
  Assigned = 'assigned',
  Triage = 'triage',
  Decline = 'decline',
  Active = 'active',
  Discharged = 'discharged',
  Closed = 'closed'
}

export const REFERRAL_STATUS_LABELS: Record<
  ReferralStatus,
  {
    label: string;
    color: string;
  }
> = {
  [ReferralStatus.New]: {
    label: 'New',
    color: '#AFE386'
  },
  [ReferralStatus.Acknowledged]: {
    label: 'Acknowledged',
    color: '#FFFAD1'
  },
  [ReferralStatus.InformationRequest]: {
    label: 'Info Request',
    color: '#FFBE9A'
  },
  [ReferralStatus.Accepted]: {
    label: 'Accepted',
    color: '#FFD1D1'
  },
  [ReferralStatus.Assigned]: {
    label: 'Assigned',
    color: '#B4C1EF'
  },
  [ReferralStatus.Triage]: {
    label: 'Triage',
    color: '#FFDD9A'
  },
  [ReferralStatus.Decline]: {
    label: 'Decline',
    color: '#FAD1D1'
  },
  [ReferralStatus.Active]: {
    label: 'Active',
    color: '#D2F0DF'
  },
  [ReferralStatus.Discharged]: {
    label: 'Discharged',
    color: '#86E3C7'
  },
  [ReferralStatus.Closed]: {
    label: 'Closed',
    color: '#F38F8F'
  }
};

export enum ReferralEntrySource {
  Manual = 'manual',
  Form = 'form',
  API = 'api'
}

export enum ReferralHistorySource {
  System = 'system',
  Clinician = 'clinician',
  Client = 'client'
}

export interface ReferralSubject {
  firstName: string;
  lastName: string;
  providerNumber?: string;
  email?: string;
  mobile?: string;
  dateOfBirth?: string;
}

export interface ReferralOrganisation {
  type: string;
  name: string;
  phone: string;
  fax?: string;
  email?: string;
  billerCode?: string;
}

export interface ReferralMatchedClient {
  clientRecordId: string;
  tacklitId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  providerNumber: string;
  medicareDateOfBirth: string;
  score: number;
  matchedFields: string[];
}

export interface Referrer {
  name?: string;
  role?: string;
  providerNumber?: string;
  email?: string;
  mobile?: string;
  fax?: string;

  // New fields
  referrerType?: ReferrerType;
  firstName?: string;
  lastName?: string;
  relationship?: string;
}

export interface ReferralDataField {
  categoryId: string;
  categoryName: string;
  variableId: string;
  variableName: string;
  value?: unknown;
}

export interface Referral {
  _id: string;
  referralId?: string;
  accountId?: string;
  clientRecordId?: string;
  source?: string;
  entrySource?: ReferralEntrySource;
  detail: string;
  date: string;
  presentingIssue?: string;
  reviewDate?: string;
  expiryDate?: string;
  status: ReferralStatus;
  referrer: Referrer;
  subject?: ReferralSubject;
  org?: ReferralOrganisation;
  generalPractitionerId?: string;
  files?: referralFilesInterface[];
  linkedEpisodes?: string[];
  changeHistory?: {
    field: string;
    data: Record<string, unknown>;
    date: string;
    user: {
      name: string;
      id: string;
    };
    source: string;
    _id: string;
  }[];
  createdBy?: string;
  createdAt: Date;
  sourceTags?: string[];
  notes?: {
    createdAt: Date;
    content: string;
    user: string;
    source: ReferralHistorySource;
    _id: string;
  }[];
  packages?: {
    packageId: string;
    funderId: string;
  }[];
  clientMatch?: { count: number; clients: ReferralMatchedClient[] };
  rdf?: ReferralDataField[];

  // TODO: Revisit these fields when doing Referral 1.x+ #RefMVP
  // historical, backward compatibility
  treatmentPlanDate?: string;
  isReferredByGP?: boolean;
  isHaveTreatmentPlan?: boolean;
  treatmentPlanFiles?: (referralFilesInterface & { treatmentPlanDate?: string })[];
  isForMyself?: boolean;
  referrerInfo?: {
    firstName: string;
    lastName: string;
    email?: string;
    mobileNumber?: string;
    dateOfBirth?: string;
    relationship?: string;
  };
  sourceName?: string;
  contactMethod?: string;
}

export interface CreateReferral extends Omit<Referral, '_id' | 'files' | 'createdAt'> {
  version?: string; // #REFERRALV1
}
