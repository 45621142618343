import styles from './Badge.module.scss';
import classnames from 'classnames';

interface BadgeProps {
  label: string;
  className?: any;
}

const Badge = ({ label, className }: BadgeProps) => {
  return <div className={classnames(styles.container, className)}>{label}</div>;
};

export default Badge;
