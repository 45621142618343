import { config } from 'config/config';
import { cloneDeep } from 'lodash';
import { AddressBookFormValidationInitialMessage } from 'pages/AddressBook/AddressBookList/components/AddressBookListContent/components/constants';
import { AddressBookCategory, AddressBookRole, AddressBookType } from 'pages/AddressBook/Interfaces/AddressBook';
import * as yup from 'yup';
import { CreateOrUpdateAddressBookPayload } from '../AddressBookForm';

const { mobileNumberMinLength } = config;

const addressBookFormSchema = yup.object().shape({
  type: yup.string().required('Please select type').oneOf(Object.values(AddressBookType), 'Invalid type'),
  role: yup.string().required('Please select role').oneOf(Object.values(AddressBookRole), 'Invalid role'),
  category: yup
    .string()
    .required('Please select category')
    .oneOf(Object.values(AddressBookCategory), 'Invalid category'),
  organisation: yup
    .object()
    .shape({
      name: yup.string().when('$isOrganisation', {
        is: true,
        then: yup.string().required('Organisation name is required'),
        otherwise: yup.string().notRequired().nullable()
      }),
      phone: yup
        .string()
        .min(mobileNumberMinLength, `Phone number has to be at least ${mobileNumberMinLength} characters`)
        .transform((originalValue) => (originalValue === '' ? null : originalValue))
        .nullable(),
      faxNumber: yup
        .string()
        .min(mobileNumberMinLength, `Fax number has to be at least ${mobileNumberMinLength} characters`)
        .transform((originalValue) => (originalValue === '' ? null : originalValue))
        .nullable()
    })
    .nullable() // Allow organisation to be null
    .when('type', {
      is: AddressBookType.Organisation,
      then: yup.object().required('Organisation details are required').nullable(false),
      otherwise: yup.object().notRequired().nullable()
    })
});

export const validateAddressBookForm = (payload: CreateOrUpdateAddressBookPayload) => {
  const validationErrors = cloneDeep(AddressBookFormValidationInitialMessage);
  try {
    addressBookFormSchema.validateSync(payload, {
      context: { isOrganisation: payload.type === AddressBookType.Organisation },
      abortEarly: false
    });
    return { isError: false, messages: validationErrors };
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: yup.ValidationError) => {
        const path = error.path;
        if (path && typeof path === 'string') {
          const pathParts = path.split('.');
          let currentLevel = validationErrors as any;

          pathParts.forEach((part, index) => {
            if (index === pathParts.length - 1) {
              currentLevel[part] = error.message;
            } else {
              if (!currentLevel[part]) {
                currentLevel[part] = {};
              }
              currentLevel = currentLevel[part];
            }
          });
        }
      });
      return { isError: true, messages: validationErrors };
    } else {
      return { isError: true, messages: validationErrors };
    }
  }
};
