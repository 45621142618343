import ShareableLink from 'components/ShareableLink/ShareableLink';
import { Tooltip } from 'react-tooltip';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './ShareableLinkButton.module.scss';

interface ShareableLinkButton {
  id: string;
  url?: string;
  tooltip?: string;
}

export default ({ id: testId, url, tooltip }: ShareableLinkButton) => {
  const { isEdgeAdminView } = useGetAccountPackageView();

  return (
    <div data-tooltip-id={testId} className={isEdgeAdminView ? styles.adminContainer : styles.container}>
      <ShareableLink iconOnly url={url} iconClassName={styles.shareableLink} isLoading={!url} />
      {url && tooltip ? (
        <Tooltip id={testId} className={styles.tooltip}>
          {tooltip}
        </Tooltip>
      ) : null}
    </div>
  );
};
