import { CSSProperties, RefObject } from 'react';
import styles from './InlineBookingModal.module.scss';
import Draggable from 'react-draggable';
import InlineBookingFooter from './components/InlineBookingFooter/InlineBookingFooter';
import InlineBookingForm from './components/InlineBookingForm/InlineBookingForm';
import { Datepicker } from '@mobiscroll/react';

interface InlineBookingModalProps {
  style: CSSProperties;
  onCloseModal: () => void;
  onSubmitSuccess: () => void;
  onSelectOtherType: () => void;
  practitionerMainOption?: boolean;
  rememberSelectedPractitioner?: boolean;
  showSelectedItemOnFirstOption?: boolean;
  onInlineRef?: (ref: Datepicker | null) => void;
  dropdownRef?: RefObject<HTMLDivElement>;
}

const InlineBookingModal = ({
  style,
  onCloseModal,
  onSubmitSuccess,
  onSelectOtherType,
  practitionerMainOption,
  rememberSelectedPractitioner,
  showSelectedItemOnFirstOption,
  onInlineRef,
  dropdownRef
}: InlineBookingModalProps) => {
  return (
    <Draggable handle={'#handle'}>
      <div style={style} className={styles.container}>
        <div className={styles.closeButtonWrapper}>
          <button className={styles.closeButton} onClick={onCloseModal}>
            <i className="material-icons">close</i>
          </button>
        </div>
        <InlineBookingForm
          onSelectOtherType={onSelectOtherType}
          practitionerMainOption={practitionerMainOption}
          rememberSelectedPractitioner={rememberSelectedPractitioner}
          showSelectedItemOnFirstOption={showSelectedItemOnFirstOption}
          onInlineRef={onInlineRef}
          dropdownRef={dropdownRef}
        />
        <InlineBookingFooter onSubmitSuccess={onSubmitSuccess} />
      </div>
    </Draggable>
  );
};

export default InlineBookingModal;
