import classNames from 'classnames';
import styles from './SummaryCard.module.scss';
import moment from 'moment';
import Select from 'components/v2/Select/Select';
import { ReactNode, useState } from 'react';
import { Skeleton } from 'antd';
import { SummaryType } from '../../InvoiceSummary';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface FilterItem {
  description: string | ReactNode;
  colorCode: number;
  type: SummaryType;
}

interface SummaryCardProps {
  title: string;
  items: FilterItem[];
  isLoading?: boolean;
  dateRangeChange: (dateRange?: string) => void;
  filterClick: (type: SummaryType) => void;
}

const dateOptions = [
  {
    value: 'today',
    label: `TODAY (${moment().startOf('day').format(MOMENTJS_DATE_FORMAT)})`
  },
  {
    value: 'yesterday',
    label: `YESTERDAY (${moment().startOf('day').subtract(1, 'days').format(MOMENTJS_DATE_FORMAT)})`
  },
  {
    value: '7days',
    label: `LAST 7 DAYS`
  },
  {
    value: '30days',
    label: `LAST 30 DAYS`
  }
];

const SummaryCard = ({ title, items, isLoading, dateRangeChange, filterClick }: SummaryCardProps) => {
  const [selectedDate, setSelectedDate] = useState('today');

  const handleChangeDateRange = (value?: string) => {
    setSelectedDate(value || '');
    dateRangeChange(value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.summary}>
        <span className={styles.title}>{title}</span>
        <div className={styles.dateFilterContainer}>
          <span className={styles.dateFilterTitle}>Inclusions based on</span>
          <Select
            options={dateOptions}
            value={dateOptions.find((option) => option.value === selectedDate.toString())}
            onChange={(option) => handleChangeDateRange(option?.value)}
            isDisabled={dateOptions.length <= 1}
            containerClass={styles.dateFilter}
            filledArrow
            styles={{
              control: (controlBase: any) => ({
                ...controlBase,
                minHeight: '24px',
                backgroundColor: 'transparent',
                border: 'none',
                borderRadius: 0,
                boxShadow: 'none',
                cursor: 'pointer'
              }),
              valueContainer: (base) => ({ ...base, paddingLeft: 0 })
            }}
          />
        </div>
      </div>
      <div className={styles.filters}>
        {items.map((item, index) => {
          return (
            <div key={index} className={classNames(styles.filter, styles[`bgColor${item.colorCode}`])}>
              {isLoading ? <Skeleton.Input className={styles.loading} active /> : <span>{item.description}</span>}
              <ButtonAlt
                fab
                size="small"
                variant="text"
                icon="filter_alt"
                className={styles.filterButton}
                onClick={() => {
                  filterClick(item.type);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SummaryCard;
