import { Integration } from 'pages/ControlPanel/Interfaces/Integration';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import ControlPanelSidebar from '../../../ControlPanel/components/ControlPanelContent/components/ControlPanelSidebar/ControlPanelSidebar';
import IntegrationDetailsContentDisplay from './components/IntegrationDetailsContentDisplay/IntegrationDetailsContentDisplay';
import styles from './IntegrationDetailsContent.module.scss';

interface IntegrationDetailsContentProps {
  integration: Integration;
  isLoading: boolean;
  refetchSystemIntegration: () => void;
}

const IntegrationDetailsContent = ({
  integration,
  isLoading,
  refetchSystemIntegration
}: IntegrationDetailsContentProps) => {
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView, isEdgeReceptionist } = useGetAccountPackageView();

  return (
    <div className={styles.container}>
      <ControlPanelSidebar
        controlPanelTab={'system-connections'}
        aboutYouPage={isEdgeUserView || isNormalUserView || isEdgeReceptionist}
        medicarePage={isEdgeUserView || isNormalUserView}
        workingSchedulePage={isEdgeUserView}
        practiceProfilePage={isEdgeAdminView || isNormalUserView}
        systemConnectionPage={isEdgeAdminView || isNormalUserView}
        notificationSettingsPage={isEdgeAdminView || isNormalUserView}
        collectDataPage={!isEdgeUserView}
      />
      <IntegrationDetailsContentDisplay
        integration={integration}
        isLoading={isLoading}
        refetchSystemIntegration={refetchSystemIntegration}
      />
    </div>
  );
};

export default IntegrationDetailsContent;
