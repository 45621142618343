import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './Invoices.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  RefundInvoice,
  UpdateBalanceInvoice,
  WriteOffForm,
  resetFilters,
  resetPaging,
  selectActiveTab,
  selectFilters,
  selectIsShowManualAdjustTotalModal,
  selectIsShowRefundModal,
  selectIsShowUpdateBalanceModal,
  selectIsShowWriteOffModal,
  selectManualAdjustTotalAmount,
  selectRefundInvoice,
  selectSelectedInvoice,
  selectUpdateBalanceInvoice,
  selectWriteOffInvoice,
  setActiveTab,
  setFilters,
  setIsShowManualAdjustTotalModal,
  setIsShowRefundModal,
  setIsShowUpdateBalanceModal,
  setIsShowWriteOffModal,
  setManualAdjustTotalAmount,
  setRefundInvoice,
  setSelectedInvoice,
  setUpdateBalanceInvoice,
  setWriteOffInvoice
} from 'redux/invoices/invoiceSlice';
import InvoiceList from './components/InvoiceList/InvoiceList';
import { InvoiceTab, RECENT_INVOICE_RANGE_IN_DAYS, getDefaultFilters } from './constants';
import InvoiceSummary from './components/InvoiceSummary/InvoiceSummary';
import RefundModal from './components/RefundModal/RefundModal';
import UpdateBalanceModal from './components/UpdateBalanceModal/UpdateBalanceModal';
import WriteOffModal from './components/WriteOffModal/WriteOffModal';
import ManualAdjustTotalModal from './components/ManualAdjustTotalModal/ManualAdjustTotalModal';

const Invoices = () => {
  const dispatch = useAppDispatch();
  const path = useParams<{ tabId: InvoiceTab }>();
  const filters = useAppSelector(selectFilters);
  const activeTab = useAppSelector(selectActiveTab);
  const isShowRefundModal = useAppSelector(selectIsShowRefundModal);
  const isShowUpdateBalanceModal = useAppSelector(selectIsShowUpdateBalanceModal);
  const isShowWriteOffModal = useAppSelector(selectIsShowWriteOffModal);
  const isShowManualAdjustTotalModal = useAppSelector(selectIsShowManualAdjustTotalModal);
  const selectedInvoice = useAppSelector(selectSelectedInvoice);
  const refund = useAppSelector(selectRefundInvoice);
  const updateBalanceFormValue = useAppSelector(selectUpdateBalanceInvoice);
  const writeOffFormValue = useAppSelector(selectWriteOffInvoice);
  const manualAdjustTotalAmount = useAppSelector(selectManualAdjustTotalAmount);

  const tabId = path.tabId ?? InvoiceTab.All;

  const setFilterByTab = useCallback(
    (isReset: boolean, currentTab: InvoiceTab) => {
      dispatch(resetPaging());

      if (isReset) {
        dispatch(resetFilters(filters));
      } else {
        const filtersByTab = getDefaultFilters()[currentTab];
        dispatch(setFilters({ ...filters, ...filtersByTab }));
      }
    },
    [dispatch, filters]
  );

  const onChangeTabFilter = useCallback(
    async (tabValue: InvoiceTab, isReset: boolean) => {
      dispatch(setActiveTab(tabValue));
      setFilterByTab(isReset, tabValue);
    },
    [dispatch, setFilterByTab]
  );

  useEffect(() => {
    if (tabId !== activeTab) {
      onChangeTabFilter(tabId, ![InvoiceTab.All, InvoiceTab.Recent].includes(activeTab as InvoiceTab));
    }
  }, [onChangeTabFilter, tabId, activeTab]);

  const onCloseRefundModalHandle = () => {
    dispatch(setIsShowRefundModal(false));
    dispatch(setSelectedInvoice(undefined));
    dispatch(setRefundInvoice({ amount: '', refId: '' }));
  };

  const onChangeRefundValue = (value: Partial<RefundInvoice>) => {
    dispatch(setRefundInvoice(value));
  };

  const onCloseUpdateBalanceModalHandle = () => {
    dispatch(setUpdateBalanceInvoice({ type: '', reference: '', date: '', amount: '' }));
    dispatch(setIsShowUpdateBalanceModal(false));
    dispatch(setSelectedInvoice(undefined));
  };

  const onChangeUpdateBalanceValue = (value: Partial<UpdateBalanceInvoice>) => {
    dispatch(setUpdateBalanceInvoice(value));
  };

  const onCloseWriteOffModalHandle = () => {
    dispatch(setWriteOffInvoice({ reference: '', date: '' }));
    dispatch(setIsShowWriteOffModal(false));
    dispatch(setSelectedInvoice(undefined));
  };

  const onChangeWriteOffFormValue = (value: Partial<WriteOffForm>) => {
    dispatch(setWriteOffInvoice(value));
  };

  const onCloseManualAdjustTotalModalHandle = () => {
    dispatch(setManualAdjustTotalAmount(undefined));
    dispatch(setIsShowManualAdjustTotalModal(false));
    dispatch(setSelectedInvoice(undefined));
  };

  const onChangeManualAdjustTotalAmount = (value: string) => {
    dispatch(setManualAdjustTotalAmount(value));
  };

  return (
    <HelmetWrapper title="Tacklit - Invoices">
      <ContentLayout className={styles.container}>
        <div className={styles.layoutContent}>
          {activeTab !== InvoiceTab.Recent && <InvoiceSummary />}

          <div className={styles.headerContainer}>
            {activeTab === InvoiceTab.Recent ? (
              <div className={styles.header}>
                <span className={styles.title}>RECENT INVOICES</span>
                <span className={styles.titleDesc}>Generated in last {RECENT_INVOICE_RANGE_IN_DAYS} days</span>
              </div>
            ) : (
              <span />
            )}
          </div>

          <InvoiceList />
          {selectedInvoice && (
            <>
              <RefundModal
                showModal={isShowRefundModal}
                invoice={selectedInvoice}
                refund={refund}
                onCloseModal={onCloseRefundModalHandle}
                onChangeValue={onChangeRefundValue}
              />
              <UpdateBalanceModal
                showModal={isShowUpdateBalanceModal}
                invoice={selectedInvoice}
                formValue={updateBalanceFormValue}
                onCloseModal={onCloseUpdateBalanceModalHandle}
                onChangeValue={onChangeUpdateBalanceValue}
              />

              <WriteOffModal
                showModal={isShowWriteOffModal}
                invoice={selectedInvoice}
                formValue={writeOffFormValue}
                onCloseModal={onCloseWriteOffModalHandle}
                onChangeValue={onChangeWriteOffFormValue}
              />

              <ManualAdjustTotalModal
                showModal={isShowManualAdjustTotalModal}
                invoice={selectedInvoice}
                amount={manualAdjustTotalAmount}
                onCloseModal={onCloseManualAdjustTotalModalHandle}
                onChangeValue={onChangeManualAdjustTotalAmount}
              />
            </>
          )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Invoices;
