import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import ProfileBadge from 'components/v2/ProfileBadge/ProfileBadge';

import styles from './PractitionerAttendeesList.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface PractitionerAttendeesListProps {
  practitionerAttendees: PractitionersDetailsInterface[];
  isLoading: boolean;
  onAddPractitioner: (practitioner: PractitionersDetailsInterface) => void;
}

const PractitionerAttendeesList = ({
  practitionerAttendees,
  isLoading,
  onAddPractitioner
}: PractitionerAttendeesListProps) => {
  return isLoading ? (
    <div className={styles.loadingContainer}>
      <LoadingCircle />
    </div>
  ) : (
    <div className={styles.practitionerAttendeesContainer}>
      {practitionerAttendees.map((practitioner, index) => {
        return (
          <div className={styles.practitioner} key={index}>
            <div className={styles.practitionerName}>
              <ProfileBadge name={practitioner.name} avatar={practitioner.avatar} />
              <ButtonAlt
                className={styles.addButton}
                contentClassName={styles.btnContent}
                size={'small'}
                variant={'text'}
                icon={'add'}
                onClick={() => onAddPractitioner(practitioner)}
              >
                Add
              </ButtonAlt>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PractitionerAttendeesList;
