import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { ClientData } from '../ClientData.interface';
import { getClientData } from 'utils/http/CheckInService/OpenDataField/openDataField';
import { ClassificationTypes } from '../../../../../../ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentEpisodeId, selectIsOutsideAllEpisodes, selectIsShowAllData } from 'redux/episodes/episodeSlice';

export const useFetchClientData = (token: string, clientRecordId: string, classification: ClassificationTypes) => {
  const [clientData, setClientData] = useState<ClientData[]>([]);
  const [isClientDataLoading, setIsClientDataLoading] = useState(true);
  const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);

  const fetchClientData = async (token: string) => {
    setIsClientDataLoading(true);
    try {
      const res = await getClientData(token, clientRecordId, {
        classification,
        ...(!isShowAllData &&
          classification === ClassificationTypes.HealthData && {
            episodeId: currentEpisodeId,
            ...(isOutsideAllEpisodes && { showOutsideOfEpisode: isOutsideAllEpisodes })
          })
      });
      setClientData((await res.json()).data as ClientData[]);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get your client data' });
    }

    setIsClientDataLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchClientData(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, classification]);

  //Only fetch health data base on episode
  useEffect(() => {
    if (token && classification === ClassificationTypes.HealthData) {
      fetchClientData(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, currentEpisodeId, isOutsideAllEpisodes, isShowAllData]);

  return { clientData, isClientDataLoading, fetchClientData };
};
