import { useEffect, useState } from 'react';
import styles from './Members.module.scss';
import MemberFilter from './components/MemberFilter/MemberFilter';
import { ToggleList } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import MemberList from './components/MemberList/MemberList';
import { useFetchGroupMemberList } from './hooks/getGroupMemberList';
import { useGetAccessToken } from 'utils/hooks/token';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { AttachedClientRecordStatus, GroupMemberListFilterParameter, Groups } from 'pages/Groups/Interfaces/Groups';
import queryString from 'query-string';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { SortType } from 'components/TableList/SortBtn/SortBtn';
import { FilterCheckListChangeValue } from 'components/FilterCheckList/FilterCheckList';
import { useFetchStageList } from '../PathwayManagement/hooks/getStageList';

interface MembersProps {
  groupDetails: Groups;
  refreshUnassignedMember: () => void;
}

const Members = ({ groupDetails, refreshUnassignedMember }: MembersProps) => {
  const navigate = useNavigate();
  const { CARECOORDINATION } = useRoutesGenerator();
  const { token } = useGetAccessToken();
  const { groupId = '' } = useParams<{ groupId: string }>();
  const location = useLocation();
  const queryParam: GroupMemberListFilterParameter = queryString.parse(location.search);

  const [searchQueryParam, setSearchQueryParam] = useState<GroupMemberListFilterParameter>({
    q: queryParam.q || '',
    page: queryParam.page && !isNaN(parseInt(queryParam.page, 10)) ? queryParam.page : '1',
    perPage: queryParam.perPage && !isNaN(parseInt(queryParam.perPage, 10)) ? queryParam.perPage : '20',
    sortByClientName:
      queryParam.sortByClientName && Object.values(SortType).includes(queryParam.sortByClientName)
        ? queryParam.sortByClientName
        : undefined,
    sortByDateJoined:
      queryParam.sortByDateJoined && Object.values(SortType).includes(queryParam.sortByDateJoined)
        ? queryParam.sortByDateJoined
        : queryParam.sortByClientName && Object.values(SortType).includes(queryParam.sortByClientName)
        ? undefined
        : -1,
    status:
      queryParam.status &&
      Object.values(AttachedClientRecordStatus).includes(queryParam.status as AttachedClientRecordStatus)
        ? queryParam.status
        : AttachedClientRecordStatus.Active
  });

  const [refreshList, setRefreshList] = useState(0);
  const [selectedStages, setSelectedStages] = useState<string[]>([]);
  const { stageListing, isStageListingLoading } = useFetchStageList(token, groupId);
  const { groupMembers, isGroupMembersLoading } = useFetchGroupMemberList(
    token,
    groupId,
    searchQueryParam,
    refreshList,
    selectedStages
  );

  const allStages = stageListing
    ? [
        ...stageListing.anytime.map((stage) => ({ _id: stage._id, name: stage.name })),
        ...stageListing.sequential.map((stage) => ({ _id: stage._id, name: stage.name }))
      ]
    : [];

  const setNewParam = (newParamValue: GroupMemberListFilterParameter) => {
    setSearchQueryParam(newParamValue);
    const paramStringify = queryString.stringify(newParamValue);
    navigate(`${CARECOORDINATION.GROUPS}/${groupId}/members?${paramStringify}`);
  };

  useEffect(() => {
    if (token && location.search === '?refetch') {
      setNewParam({
        ...searchQueryParam,
        page: '1'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleChangeStatus = (statusList: ToggleList) => {
    const newParam = {
      ...searchQueryParam,
      page: '1',
      status: statusList.id
    };
    setNewParam(newParam);
  };

  const handleChangeStage = (value: FilterCheckListChangeValue) => {
    if (value.toggleAllValue !== undefined) {
      setSelectedStages(
        value.toggleAllValue
          ? [
              ...selectedStages,
              ...allStages
                .filter((j) => !selectedStages.find((i) => i === j._id))
                .filter((j) => j.name.toLowerCase().includes((value.searchValue || '').toLowerCase()))
                .map((stage) => stage._id)
            ]
          : [
              ...selectedStages.filter((item) => {
                const selectedItem = allStages.find((i) => i._id === item) || { name: '' };
                return !selectedItem.name.toLowerCase().includes((value.searchValue || '').toLowerCase());
              })
            ]
      );
    } else if (value.item) {
      if (!selectedStages.find((i) => i === value.item!._id)) {
        const selectedItem = allStages.find((i) => i._id === value.item!._id);
        if (selectedItem) {
          setSelectedStages([...selectedStages, selectedItem._id]);
        }
      } else {
        setSelectedStages(selectedStages.filter((item) => item !== value.item!._id));
      }
    }
  };

  const handleSearch = (searchValue: string) => {
    if (searchQueryParam.q !== searchValue) {
      const newParam = {
        ...searchQueryParam,
        page: '1',
        q: searchValue
      };
      setNewParam(newParam);
    }
  };

  const handleChangeDateJoinedSort = (sortName: SortType) => {
    const newParam = {
      ...searchQueryParam,
      sortByDateJoined: sortName,
      sortByClientName: undefined
    };
    setNewParam(newParam);
  };

  const handleChangeNameSort = (sortName: SortType) => {
    const newParam = {
      ...searchQueryParam,
      sortByClientName: sortName,
      sortByDateJoined: undefined
    };
    setNewParam(newParam);
  };

  const handleChangePerPage = (perPageValue: number) => {
    if (parseInt(searchQueryParam.perPage as string, 10) !== perPageValue) {
      const newParam = {
        ...searchQueryParam,
        page: '1',
        perPage: perPageValue.toString()
      };
      setNewParam(newParam);
    }
  };

  const handlePageChange = (pageValue: number) => {
    const newParam = {
      ...searchQueryParam,
      page: pageValue.toString()
    };
    setNewParam(newParam);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Members</div>
      <div className={styles.content}>
        <MemberFilter
          groupMembers={groupMembers}
          isGroupMembersLoading={isGroupMembersLoading}
          stageList={allStages}
          isStageListingLoading={isStageListingLoading}
          searchValue={searchQueryParam.q || ''}
          selectedStatus={searchQueryParam.status as AttachedClientRecordStatus}
          selectedStages={selectedStages}
          handleChangeStatus={handleChangeStatus}
          handleChangeStage={handleChangeStage}
          handleSearch={handleSearch}
          handleClearStageFilter={() => setSelectedStages([])}
        />
        <MemberList
          groupId={groupId}
          groupDetails={groupDetails}
          groupMembers={groupMembers}
          isGroupMembersLoading={isGroupMembersLoading}
          selectedPerPage={parseInt(searchQueryParam.perPage as string, 10)}
          selectedSortDateJoined={searchQueryParam.sortByDateJoined}
          selectedSortName={searchQueryParam.sortByClientName}
          selectedPage={parseInt(searchQueryParam.page as string, 10)}
          onChangeDateJoinedSort={handleChangeDateJoinedSort}
          onChangeNameSort={handleChangeNameSort}
          onChangePerPage={handleChangePerPage}
          onChangePage={handlePageChange}
          onRefreshList={() => {
            setRefreshList(refreshList + 1);
          }}
          refreshUnassignedMember={refreshUnassignedMember}
        />
      </div>
    </div>
  );
};

export default Members;
