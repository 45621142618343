import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styles from './DropdownNote.module.scss';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { sanitizeHTMLString } from 'utils/sanitizeHTMLString';
import { Tooltip } from 'react-tooltip';

interface DropdownNoteProps {
  menuPlacement?: 'top' | 'bottom';
  icon?: string;
  classNameConfig?: {
    containerClassName?: string;
    optionClassName?: string;
    borderBottomClassName?: string;
  };
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  hideErrorDesc?: boolean;
  hideActiveLine?: boolean;
  label?: string;
  value?: string;
  onChangeValue: (value: string) => void;
  maxLength?: number;
  showWordCount?: boolean;
  showTooltips?: boolean;
  tooltipNote?: string;
}

const DropdownNote = ({
  menuPlacement = 'bottom',
  icon,
  classNameConfig,
  placeholder,
  disabled,
  error,
  hideErrorDesc,
  hideActiveLine,
  label,
  value,
  onChangeValue,
  maxLength,
  showWordCount,
  showTooltips,
  tooltipNote
}: DropdownNoteProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();

  const [isActive, setIsActive] = useState<boolean>(false);

  const noteRef = useRef<HTMLDivElement>(null);

  const handleClickOutSide = (event: any) => {
    if (noteRef.current?.contains(event.target)) {
      return;
    }
    setIsActive(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  return (
    <>
      <div
        ref={noteRef}
        data-tooltip-id="noAllowNote"
        className={classNames(
          styles.container,
          classNameConfig?.containerClassName,
          isEdgeAdminView && 't23-admin-theme'
        )}
      >
        <i className={classNames('material-symbols-outlined', styles.fieldIcon, value && styles.activeIcon)}>{icon}</i>
        <button
          type="button"
          disabled={disabled}
          className={classNames(styles.dropdownController, disabled && styles.disabled, error && styles.fieldError)}
          onClick={() => setIsActive(!isActive)}
        >
          <div className={classNames(styles.optionLabel)}>{value || label}</div>
          {isActive && !hideActiveLine && !error && (
            <div
              className={classNames(
                styles.borderBottom,
                isActive && styles.active,
                classNameConfig?.borderBottomClassName
              )}
            />
          )}
        </button>
        {isActive && (
          <div
            id="renderOptionLists"
            className={classNames(styles.optionsWrapper, styles[menuPlacement], classNameConfig?.optionClassName)}
          >
            <textarea
              className={styles.textArea}
              placeholder={placeholder}
              value={value}
              maxLength={maxLength}
              onChange={(event) => onChangeValue(sanitizeHTMLString(event.target.value))}
            />
            {showWordCount && maxLength && (
              <div className={styles.wordCount}>
                {value ? maxLength - value.length : maxLength}&nbsp;characters remaining
              </div>
            )}
          </div>
        )}
        <>
          {error && !hideErrorDesc && <div className={classNames(styles.fieldError, styles.errorLabel)}>{error}</div>}
        </>
      </div>
      {tooltipNote && (
        <Tooltip id="noAllowNote" className={styles.tooltip} hidden={!showTooltips}>
          {tooltipNote}
        </Tooltip>
      )}
    </>
  );
};

export default DropdownNote;
