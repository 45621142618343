import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './SubmitFooter.module.scss';

interface SubmitFooterProps {
  draftStatus: ButtonStatusType;
  setDraftStatus: (status: ButtonStatusType) => void;
  openToBookStatus: ButtonStatusType;
  setOpenToBookStatus: (status: ButtonStatusType) => void;
  isDeliveryOptionsEmpty: boolean;
  isValid: boolean;
  setIsActive: (active: boolean) => void;
  submitForm: () => void;
}

const SubmitFooter = ({
  draftStatus,
  setDraftStatus,
  openToBookStatus,
  setOpenToBookStatus,
  isDeliveryOptionsEmpty,
  isValid,
  setIsActive,
  submitForm
}: SubmitFooterProps) => {
  const handleSubmit = (type: 'draft' | 'openToBook') => () => {
    const setStatus = type === 'draft' ? setDraftStatus : setOpenToBookStatus;

    submitForm();
    if (isValid && (type === 'draft' || !isDeliveryOptionsEmpty)) {
      setIsActive(type !== 'draft');
      setStatus('active');
    } else {
      setStatus('');
    }
  };

  return (
    <div className={styles.footer}>
      <ButtonAlt status={draftStatus} className={styles.draftButton} variant="outlined" onClick={handleSubmit('draft')}>
        Save in draft
      </ButtonAlt>
      <ButtonAlt status={openToBookStatus} onClick={handleSubmit('openToBook')}>
        Save and open for use
      </ButtonAlt>
    </div>
  );
};

export default SubmitFooter;
