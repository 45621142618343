import { createSlice } from '@reduxjs/toolkit';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { FilterRadioItem } from 'components/T23/FilterRadioDropdown/FilterRadioDropdown';
import { SubmittedClaim } from 'interfaces/invoices/submittedClaim';
import { WriteOffForm } from 'redux/invoices/invoiceSlice';
import { RootState } from 'redux/store';
import { Paging, PER_PAGE_DEFAULT_10, Sorting, SortType } from '../utils';

export enum SubmittedClaimTabs {
  Submitted = 'submitted',
  Success = 'success',
  Rejected = 'rejected',
  WriteOff = 'writeOff',
  Abandoned = 'abandoned'
}

export interface SubmittedClaimFilters {
  clients: FilterCheckListItem[];
  practitioners: FilterCheckListItem[];
  groups: FilterCheckListItem[];
  claimType: FilterRadioItem | undefined;
  code: FilterRadioItem | undefined;
  status: FilterRadioItem | undefined;
  submittedStatus: FilterRadioItem | undefined;
  successStatus: FilterRadioItem | undefined;
  claimedAfter: string;
  claimedBefore: string;
  claimId: string; // also searches claim reference
}

interface SubmittedClaimSliceState {
  sorting: Sorting;
  rejectedWriteOffSorting: Sorting;
  submittedPaging: Paging;
  successPaging: Paging;
  rejectedPaging: Paging;
  writeOffPaging: Paging;
  abandonedPaging: Paging;
  filters: SubmittedClaimFilters;
  isClaimStatementModalVisible: boolean;
  isCreateClaimModalVisible: boolean;
  selectedClaim: SubmittedClaim | undefined;
  activeTab: SubmittedClaimTabs;
  isShowWriteOffModal: boolean;
  writeOffFormValue: WriteOffForm | undefined;
}

const initialState: SubmittedClaimSliceState = {
  sorting: {
    sortBy: 'date',
    sortType: SortType.DESC
  },
  rejectedWriteOffSorting: {
    sortBy: 'date',
    sortType: SortType.DESC
  },
  submittedPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT_10
  },
  successPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT_10
  },
  rejectedPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT_10
  },
  writeOffPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT_10
  },
  abandonedPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT_10
  },
  filters: {
    clients: [],
    practitioners: [],
    groups: [],
    claimType: undefined,
    code: undefined,
    status: undefined,
    submittedStatus: undefined,
    successStatus: undefined,
    claimedAfter: '',
    claimedBefore: '',
    claimId: ''
  },
  isClaimStatementModalVisible: false,
  isCreateClaimModalVisible: false,
  selectedClaim: undefined,
  activeTab: SubmittedClaimTabs.Submitted,
  isShowWriteOffModal: false,
  writeOffFormValue: undefined
};

export const submittedClaimSlice = createSlice({
  name: 'submittedClaimSlice',
  initialState,
  reducers: {
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    setRejectedWriteOffSorting: (state, action) => {
      state.rejectedWriteOffSorting = action.payload;
    },

    setSubmittedPaging: (state, action) => {
      state.submittedPaging = action.payload;
    },

    setSuccessPaging: (state, action) => {
      state.successPaging = action.payload;
    },

    setRejectedPaging: (state, action) => {
      state.rejectedPaging = action.payload;
    },

    setWriteOffPaging: (state, action) => {
      state.writeOffPaging = action.payload;
    },

    setAbandonedPaging: (state, action) => {
      state.abandonedPaging = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetSubmittedPaging: (state) => {
      state.submittedPaging = initialState.submittedPaging;
    },

    resetSuccessPaging: (state) => {
      state.successPaging = initialState.successPaging;
    },

    resetRejectedPaging: (state) => {
      state.rejectedPaging = initialState.rejectedPaging;
    },

    resetWriteOffPaging: (state) => {
      state.writeOffPaging = initialState.writeOffPaging;
    },

    resetAbandonedPaging: (state, action) => {
      state.abandonedPaging = action.payload;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    setIsClaimStatementModalVisible: (state, action) => {
      state.isClaimStatementModalVisible = action.payload;
    },

    setIsCreateClaimModalVisible: (state, action) => {
      state.isCreateClaimModalVisible = action.payload;
    },

    setSelectedClaim: (state, action) => {
      state.selectedClaim = action.payload;
    },

    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setIsShowWriteOffModal: (state, action) => {
      state.isShowWriteOffModal = action.payload;
    },

    setWriteOffFormValue: (state, action) => {
      state.writeOffFormValue = {
        ...state.writeOffFormValue,
        ...action.payload
      };
    }
  }
});

export const selectSorting = (state: RootState) => state.submittedClaimSlice.sorting;
export const selectRejectedWriteOffSorting = (state: RootState) => state.submittedClaimSlice.rejectedWriteOffSorting;
export const selectSubmittedPaging = (state: RootState) => state.submittedClaimSlice.submittedPaging;
export const selectSuccessPaging = (state: RootState) => state.submittedClaimSlice.successPaging;
export const selectRejectedPaging = (state: RootState) => state.submittedClaimSlice.rejectedPaging;
export const selectWriteOffPaging = (state: RootState) => state.submittedClaimSlice.writeOffPaging;
export const selectAbandonedPaging = (state: RootState) => state.submittedClaimSlice.abandonedPaging;
export const selectFilters = (state: RootState) => state.submittedClaimSlice.filters;
export const selectedSelectedClaim = (state: RootState) => state.submittedClaimSlice.selectedClaim;
export const selectIsClaimStatementModalVisible = (state: RootState) =>
  state.submittedClaimSlice.isClaimStatementModalVisible;
export const selectIsCreateClaimModalVisible = (state: RootState) =>
  state.submittedClaimSlice.isCreateClaimModalVisible;
export const selectActiveTab = (state: RootState) => state.submittedClaimSlice.activeTab;
export const selectIsShowWriteOffModal = (state: RootState) => state.submittedClaimSlice.isShowWriteOffModal;
export const selectWriteOffFormValue = (state: RootState) => state.submittedClaimSlice.writeOffFormValue;

export const {
  setSorting,
  setRejectedWriteOffSorting,
  setSubmittedPaging,
  setSuccessPaging,
  setRejectedPaging,
  setWriteOffPaging,
  setAbandonedPaging,
  setFilters,
  resetSubmittedPaging,
  resetSuccessPaging,
  resetRejectedPaging,
  resetWriteOffPaging,
  resetAbandonedPaging,
  resetFilters,
  setIsClaimStatementModalVisible,
  setIsCreateClaimModalVisible,
  setSelectedClaim,
  setActiveTab,
  setIsShowWriteOffModal,
  setWriteOffFormValue
} = submittedClaimSlice.actions;

export default submittedClaimSlice.reducer;
