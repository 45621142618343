/* eslint-disable complexity */
import { useState, useMemo } from 'react';
import { notification } from 'antd';
import { config } from 'config/config';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { postClinician, trackNewSignup } from 'utils/http/clinician';
import { getEmailUsed, resendVerificationEmail } from 'utils/http/verification';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import SignUpProgressBar from '../components/SignUpProgressBar/SignUpProgressBar';
import AboutYou from '../components/AboutYou/AboutYou';
import Security from '../components/Security/Security';
import Data from '../components/Data/Data';
import GetStarted from '../components/GetStarted/GetStarted';

import styles from './ClinicianSignUp.module.scss';
import './ClinicianSignUp.scss';

const FORM_STAGES = ['Create account', 'Security', 'Data', 'Get started'];

const initialValues = {
  title: '',
  'title-others': '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  mobileNumber: '',
  formattedMobileNumber: '',
  '2fa': ['', '', '', '', '', ''],
  isEmailCopyRequired: true,
  resendEmail: ''
};

const AboutYouSchema = Yup.object().shape({
  'title-others': Yup.string().when('title', {
    is: (title: string) => title === 'other',
    then: Yup.string().required('Please tell us how we should address you'),
    otherwise: Yup.string()
  }),
  firstName: Yup.string().required('Please enter your first name'),
  lastName: Yup.string().required('Please enter your last name'),
  email: Yup.string().email('Please provide a valid email').required('Please enter your email')
});

const { mobileNumberMinLength, countryCode } = config;

const SecuritySchema = Yup.object().shape({
  password: Yup.string().required('Please choose a password').min(8, 'Password must be at least 8 characters'),
  mobileNumber: Yup.string()
    .required('Please enter your mobile number for two-factor authentication')
    .min(mobileNumberMinLength, `Mobile number has to be at least ${mobileNumberMinLength} characters`),
  '2fa': Yup.array()
    .of(Yup.string())
    .test({
      name: '2fa',
      message: 'Please enter the 6-digit code sent to your mobile number',
      test: (value) => (value as string[]).filter((value) => !!value).length === 6
    })
});

const useSignUpStage = () => {
  const [stage, setStage] = useState(0);

  const nextStage = () => {
    if (stage < 4) {
      setStage(stage + 1);
    }
  };

  return [stage, nextStage] as const;
};

const memberPackage = 'pioneer';

const ClinicianSignUp = () => {
  const [stage, nextStage] = useSignUpStage();
  const [isLoading, setIsLoading] = useState(false);
  const [leadId, setLeadId] = useState('' as any);
  const [hideProgressBar, setHideProgressBar] = useState(false);

  const validationSchema = useMemo(() => {
    switch (stage) {
      case 0:
        return AboutYouSchema;
      case 1:
        return SecuritySchema;
      case 2:
      case 3:
      default:
        return;
    }
  }, [stage]);

  const renderForm = (stage: number) => {
    switch (stage) {
      case 0:
        return <AboutYou />;
      case 1:
        return <Security />;
      case 2:
        return <Data />;
      case 3:
        return <GetStarted setHideProgressBar={setHideProgressBar} allowResendEmail />;
      default:
        return;
    }
  };

  const handleSubmit = async (values: typeof initialValues, formikBag: FormikHelpers<typeof initialValues>) => {
    formikBag.setSubmitting(false);

    if (stage === 0) {
      setIsLoading(true);
      const { email } = values;

      try {
        const callGetClinicianEmailUsed = await getEmailUsed(email);

        const { used } = await callGetClinicianEmailUsed.json();

        if (used) {
          notification.error({ message: 'Email already in use' });
          formikBag.setFieldError('email', 'Email already in use');
        } else {
          formikBag.setFieldTouched('mobileNumber', false, true);
          formikBag.setFieldTouched('2fa', false, true);
          formikBag.setFieldTouched('password', false, true);

          const title = values.title === 'other' ? values['title-others'] : values.title;

          try {
            const res = await trackNewSignup({
              name: `${title} ${values.firstName} ${values.lastName}`,
              email: values.email,
              geo: countryCode,
              signUpCompleted: 'no',
              step: 1
            });

            const id = res ? await res.text() : '';
            setLeadId(id);
          } catch (innerError) {
            // Log the error but do not take further action
            console.error('Error tracking on Step 1:', innerError);
          }

          nextStage();
        }
      } catch (ex) {
        notification.error({ message: 'Invalid email format' });
      }

      setIsLoading(false);
      return;
    }

    if (stage === 1) {
      try {
        await trackNewSignup({
          phone_number: values.mobileNumber,
          lead_id: leadId,
          step: 2
        });
      } catch (innerError) {
        // Log the error but do not take further action
        console.error('Error tracking on Step 2:', innerError);
      }
      nextStage();
    }

    if (stage === 2) {
      setIsLoading(true);

      try {
        const title = values.title === 'other' ? values['title-others'] : values.title;
        await postClinician({
          email: values.email,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.mobileNumber,
          plan: memberPackage,
          title,
          isEmailCopyRequired: values.isEmailCopyRequired
        }).catch(async (ex) => {
          console.error(ex);

          if (ex?.statusCode === 409) {
            const errorObject = await ex.json().catch(() => undefined);

            if (errorObject?.emailUsed) {
              throw new Error(
                'It seems that an account has already been created using this email. Please proceed to log-in or contact our administrators if you believe an account has been mistakenly created using your email.'
              );
            }
          }

          throw new Error('Something went wrong while creating your account. Please try again.');
        });

        try {
          await trackNewSignup({
            lead_id: leadId,
            memberships: memberPackage,
            signUpCompleted: 'yes',
            step: 3
          });
        } catch (innerError) {
          // Log the error but do not take further action
          console.error('Error tracking on Step 3:', innerError);
        }

        formikBag.setFieldValue('resendEmail', values.email);

        nextStage();
      } catch (error) {
        notification.error({ message: error instanceof Error ? error.message : 'Something went wrong.' });
      } finally {
        setIsLoading(false);
      }
    }

    if (stage === 3) {
      if (values.email !== values.resendEmail) {
        setIsLoading(true);
        try {
          const title = values.title === 'other' ? values['title-others'] : values.title;
          await postClinician({
            email: values.resendEmail,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            mobileNumber: values.mobileNumber,
            plan: memberPackage,
            title,
            isEmailCopyRequired: values.isEmailCopyRequired
          }).catch(async (ex) => {
            console.error(ex);

            if (ex?.statusCode === 409) {
              const errorObject = await ex.json().catch(() => undefined);

              if (errorObject?.emailUsed) {
                throw new Error(
                  'It seems that an account has already been created using this email. Please proceed to log-in or contact our administrators if you believe an account has been mistakenly created using your email.'
                );
              }
            }
            throw new Error('Something went wrong while creating your account. Please try again.');
          });

          try {
            await trackNewSignup({
              lead_id: leadId,
              memberships: memberPackage,
              signUpCompleted: 'yes',
              step: 4
            });
          } catch (innerError) {
            // Log the error but do not take further action
            console.error('Error tracking on Step 4:', innerError);
          }

          notification.success({ message: 'Confirmation email resent!' });
        } catch (error) {
          notification.error({
            message: error instanceof Error ? error.message : 'Something went wrong.'
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          await resendVerificationEmail({
            email: values.resendEmail,
            clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''
          });

          notification.success({ message: 'Confirmation email resent!' });
        } catch (e) {
          notification.error({
            message:
              e instanceof Error
                ? e.message
                : 'Something went wrong with resending confirmation email. Please try again.'
          });
        }
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      )}
      <div className={styles.container}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {() => (
            <Form>
              {!hideProgressBar && <SignUpProgressBar stage={stage} stages={FORM_STAGES} />}
              <ContentLayout className={styles.signUpContainer}>
                <div className={styles.content}>{renderForm(stage)}</div>
              </ContentLayout>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ClinicianSignUp;
