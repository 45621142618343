export const toCamelCase = (val: string) => {
  if (val) {
    return val.replace(/\s+(.)/g, function (match, group) {
      return group.toUpperCase();
    });
  }
};

export const toWord = (val: string) => {
  if (val) {
    const temp = val.replace(/([A-Z])/g, ' $1');
    return temp.charAt(0).toUpperCase() + temp.slice(1);
  }
  return '';
};
