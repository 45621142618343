import { Modal, notification, Skeleton } from 'antd';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { CaseNote, CreateUpdateCaseNote } from 'interfaces/caseNote';
import moment from 'moment';
import { CaseNoteTemplate } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from 'utils/UserContextProvider';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { downloadClientCaseNoteWithAttachments } from 'utils/http/UtilityService/downloadCaseNote';
import { DEFAULT_SHARED_FOLDER_ID } from '../../folderConfigs';
import styles from './PatientDetailsNoteDetails.module.scss';
import PatientDetailsNoteDetailsDisplay from './components/PatientDetailsNoteDetailsDisplay/PatientDetailsNoteDetailsDisplay';
import PatientDetailsNoteDetailsForm from './components/PatientDetailsNoteDetailsForm/PatientDetailsNoteDetailsForm';
import { useGetEpisodeDetailsQuery } from 'redux/endpoints/clinicianProfileServices/episode';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useCreateCaseNoteMutation, useUpdateCaseNoteMutation } from 'redux/endpoints/documentServices/caseNote';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';
import { useAppDispatch } from 'redux/hooks';
import { security } from 'utils/security';

const IS_DEVELOPMENT = isDevelopmentENV();

interface PatientDetailsNoteDetailsProps {
  caseNoteDataLoading?: boolean;
  recordId: string;
  profileId: string;
  clientRole?: string;
  caseNoteData: CaseNote;
  currentTemplate?: CaseNoteTemplate;
  templateList?: CaseNoteTemplate[];
  onSelectTemplate: (id: string) => Promise<CaseNote | undefined>;
  token: string;
  isEditing: boolean;
  isReadOnly: boolean;
  isClosedClient?: boolean;
  onValueChange: (b: boolean) => void;
  onUpdateNoteDetails: (newNote: CaseNote, isDraftSave: boolean) => void;
  handleClickDelete: (arg?: { noteId?: string; noteTitle?: string }) => void;
  isDeleting: boolean;
  isPrivateFolderSelected: boolean;
  onEditNoteClick: (noteId?: string) => void;
  refetchCaseNote: () => void;
  setSelectedFolderId: Dispatch<SetStateAction<string>>;
}

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

const reconstructCaseNote = (caseNoteNode: Node): Document => {
  const newDocument = new Document();
  const newHTML = newDocument.createElement('html');
  const newHead = document.head.cloneNode(true);
  const newBody = newDocument.createElement('body');
  newBody.setAttribute('style', 'padding: 20px');
  newBody.appendChild(caseNoteNode.cloneNode(true));

  newHTML.appendChild(newHead);
  newHTML.appendChild(newBody);
  newDocument.appendChild(newHTML);
  const styleSheets = newDocument.querySelectorAll("link[rel='stylesheet' i]");

  styleSheets.forEach((styleSheet) => {
    const extractedHref = styleSheet.getAttribute('href');
    if (extractedHref && !isValidUrl(extractedHref)) {
      styleSheet.setAttribute('href', `${document.location.origin}${extractedHref}`);
    }
  });

  return newDocument;
};

const downloadCaseNote = (file: Blob, fileName: string): void => {
  const hiddenLink = document.createElement('a'); // ToDo: Is this really hidden??
  hiddenLink.href = window.URL.createObjectURL(file);
  hiddenLink.download = `${fileName}.zip`;
  hiddenLink.click();
  hiddenLink.remove();
};

const PatientDetailsNoteDetails = ({
  caseNoteDataLoading,
  recordId,
  clientRole,
  profileId,
  caseNoteData,
  currentTemplate,
  templateList,
  onSelectTemplate,
  token,
  isEditing,
  isReadOnly,
  isClosedClient,
  onValueChange,
  onUpdateNoteDetails,
  handleClickDelete,
  isDeleting,
  isPrivateFolderSelected,
  onEditNoteClick,
  refetchCaseNote,
  setSelectedFolderId
}: PatientDetailsNoteDetailsProps) => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();
  const { templateNameEnable, isEoCEnabled } = useGetFeatureToggle();
  const { auth0ClinicianId: loggedInClinicianId } = useGetClinicianId();
  const { clinicianProfile: loggedInClinicianProfile } = useContext(UserContext);
  const { accountTimeZone } = useTimeZone();
  const dispatch = useAppDispatch();
  const path = useParams() as { assessmentOrReportId: string };
  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [caseNoteNode, setCaseNoteNode] = useState<Node | null>(null);
  const [isCaseNoteDownloading, setIsCaseNoteDownloading] = useState<boolean>(false);
  const [caseNoteDetails, setCaseNoteDetails] = useState(caseNoteData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [caseNoteId, setCaseNoteId] = useState(path.assessmentOrReportId);

  const [isChangingTemplate, setIsChangingTemplate] = useState(false);
  const [isTemplateListActive, setIsTemplateListActive] = useState(false);
  const [isStartEditing, setIsStartEditing] = useState(false);

  const caseNoteRef = useCallback((renderedNode: any) => {
    if (renderedNode) {
      setCaseNoteNode(renderedNode);
    }
  }, []);

  const {
    data: episodeDetailsResponse,
    isLoading: episodeDetailsLoading,
    isFetching: episodeDetailsFetching
  } = useGetEpisodeDetailsQuery(
    {
      clientRecordId: recordId,
      episodeId: caseNoteData.episodeId || '',
      timezone: accountTimeZone
    },
    { skip: !recordId || !caseNoteData.episodeId }
  );

  // to handle the template list menu (to be active only it's a new template even after auto save)
  useEffect(() => {
    if (isEditing && caseNoteId === 'new' && !isTemplateListActive) {
      setIsTemplateListActive(true);
    }
    if (!isEditing && isTemplateListActive) {
      setIsTemplateListActive(false);
    }
    // eslint-disable-next-line
  }, [isEditing]);

  const handleSelectTemplate = async (id: string, confirm?: true) => {
    if (isStartEditing && !confirm) return handleConfirmChangeTemplate(id);

    setIsChangingTemplate(true);
    if (id === currentTemplate?._id) return setIsChangingTemplate(false);

    const newTemplate = await onSelectTemplate(id);
    if (!newTemplate) return setIsChangingTemplate(false);

    setCaseNoteDetails(newTemplate);
    setIsChangingTemplate(false);
  };

  const handleConfirmChangeTemplate = (id: string) => {
    Modal.confirm({
      title: 'Are you sure you want to change the template? Changing the template will reset the fields and headings.',
      onOk: () => handleSelectTemplate(id, true)
    });
  };

  const safeguardingAlertExistInFields = !!caseNoteDetails.fields.find(
    (fieldObj) => fieldObj.name === 'Safeguarding Alert'
  );

  const [createCaseNote] = useCreateCaseNoteMutation();
  const [updateCaseNote] = useUpdateCaseNoteMutation();

  useEffect(() => {
    setCaseNoteDetails(caseNoteData);
    setCaseNoteId(caseNoteData._id);
  }, [caseNoteData]);

  let isRunning = false;
  const handleSaveFunc = async (values: CaseNote, triggerBtnSave: boolean) => {
    if (!isRunning) {
      isRunning = true;
      setIsSubmitting(true);
      triggerBtnSave && setSaveButtonStatus('active');

      const bodyPayload: CreateUpdateCaseNote = {
        caseNoteSaved: triggerBtnSave,
        title: values.title,
        fields: values.fields,
        body: values.body,
        attachments: values.attachments,
        safeguardingAlert: values.safeguardingAlert,
        safeguardingReason: values.safeguardingReason,
        isPrivate: values.isPrivate,
        ...(templateNameEnable && {
          templateName: values.templateName || currentTemplate?.name
        }),
        ...(values.episodeId && {
          episodeId: values.episodeId
        })
      };

      if (caseNoteId === 'new') {
        try {
          const responseCreateNote = await createCaseNote({ clientRecordId: recordId, payload: bodyPayload }).unwrap();
          const createdCaseNote = {
            ...responseCreateNote,
            clinicianName: loggedInClinicianProfile?.name
          };

          setCaseNoteId(createdCaseNote._id);
          setCaseNoteDetails(createdCaseNote);
          onUpdateNoteDetails(createdCaseNote, !triggerBtnSave);
          setIsSubmitting(false);
          onValueChange(false);
          if (triggerBtnSave) {
            setSaveButtonStatus('finished');
            setTimeout(() => setSaveButtonStatus(''), 2000);
          } else {
            navigate(`${CLIENTS.BASE}/${recordId}/notes/${createdCaseNote._id}`);
          }
        } catch (ex) {
          triggerBtnSave && setSaveButtonStatus('');
          notification.error({ message: 'Something went wrong while trying to save your note.' });
        }
      } else {
        try {
          await updateCaseNote({ clientRecordId: recordId, caseNoteId, payload: bodyPayload }).unwrap();
          const massageValueWithUpdatedTime = {
            ...values,
            _id: caseNoteId,
            updatedAt: moment().format(),
            clinicianName: loggedInClinicianProfile?.name
          };
          onUpdateNoteDetails(massageValueWithUpdatedTime, !triggerBtnSave);
          setIsSubmitting(false);
          onValueChange(false);
          if (triggerBtnSave) {
            setSaveButtonStatus('finished');
            notification.success({
              message: `Your note has been successfully saved ${
                isEoCEnabled && episodeDetailsResponse?.order
                  ? ` and assigned to Episode ${episodeDetailsResponse?.order}`
                  : ''
              }.`
            });
            dispatch(documentServicesApiSlice.util.invalidateTags(['CaseNote']));
            setTimeout(() => setSaveButtonStatus(''), 2000);
          }
        } catch (ex) {
          console.error(ex);
          triggerBtnSave && setSaveButtonStatus('');
          notification.error({ message: 'Something went wrong while trying to update your note.' });
        } finally {
          setIsStartEditing(false);
        }

        if (caseNoteDetails.isPrivate !== values.isPrivate) {
          refetchCaseNote();
        }
      }

      setSelectedFolderId(bodyPayload.isPrivate ? loggedInClinicianId : DEFAULT_SHARED_FOLDER_ID);
      isRunning = false;
    }
  };

  const handleSubmit = async (values: CaseNote, draftSave: boolean) => {
    if (!isSubmitting) {
      await handleSaveFunc(values, !draftSave);
    }
  };

  const onDownloadNoteClick = async (fileName: string, caseNoteNode: Node | null) => {
    if (caseNoteNode) {
      setIsCaseNoteDownloading(true);

      try {
        const token = await security.getAccessTokenSilently();
        const htmlNode = reconstructCaseNote(caseNoteNode);
        const retrievedPDF = await downloadClientCaseNoteWithAttachments(token, recordId, caseNoteId, htmlNode);
        downloadCaseNote(retrievedPDF, fileName);
      } catch (ex) {
        console.error(ex);

        notification.error({ message: 'Something went wrong while trying to download your note' });
      }

      setIsCaseNoteDownloading(false);
    }
  };

  if (isChangingTemplate) {
    return (
      <div className={styles.loading}>
        <LoadingDot />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {(isDeleting || caseNoteDataLoading) && (
        <div className={styles.deleting}>
          <LoadingDot />
        </div>
      )}
      <div className={styles.header}>
        <div className={styles.createdAt}>
          <div className={styles.label}>CREATED:</div>
          <div className={styles.value}>{moment(caseNoteDetails.createdAt).format('DD/MM/YYYY hh:mm A')}</div>
        </div>
        {caseNoteDetails.updatedAt !== caseNoteDetails.createdAt && caseNoteId !== 'new' && (
          <div className={styles.updatedAt}>
            <div className={styles.label}>LAST EDIT:</div>
            <div className={styles.value}>{moment(caseNoteDetails.updatedAt).format('DD/MM/YYYY hh:mm A')}</div>
          </div>
        )}
        {isEoCEnabled && (episodeDetailsLoading || episodeDetailsFetching) ? (
          <div className={styles.updatedAt}>
            <Skeleton.Input className={styles.episodeOrderLoading} />
          </div>
        ) : (
          isEoCEnabled &&
          episodeDetailsResponse &&
          !isEditing && (
            <div className={styles.updatedAt}>
              <div className={styles.label}>EPISODE:</div>
              <div className={styles.value}>Episode {episodeDetailsResponse?.order}</div>
            </div>
          )
        )}
        {isEditing && (
          <ButtonAlt
            error
            className={styles.deleteNoteButton}
            variant={'text'}
            size={'small'}
            onClick={() => handleClickDelete()}
          >
            DELETE NOTE
          </ButtonAlt>
        )}
      </div>
      {isEditing ? (
        <PatientDetailsNoteDetailsForm
          recordId={recordId}
          clientRole={clientRole}
          profileId={profileId}
          noteId={caseNoteId}
          isStartEditing={isStartEditing}
          setIsStartEditing={setIsStartEditing}
          isSubmitting={isSubmitting}
          formData={caseNoteDetails}
          saveButtonStatus={saveButtonStatus}
          onSubmit={handleSubmit}
          onValueChange={onValueChange}
          token={token}
          safeguardingAlertExistInFields={safeguardingAlertExistInFields}
          currentTemplate={currentTemplate}
          handleSelectTemplate={handleSelectTemplate}
          isPrivateFolderSelected={isPrivateFolderSelected}
          isTemplateListActive={isTemplateListActive}
          templateList={templateList}
        />
      ) : (
        <div className={styles.noteDetails}>
          <div ref={caseNoteRef}>
            <PatientDetailsNoteDetailsDisplay
              recordId={recordId}
              data={caseNoteDetails}
              showSafeguardingAlert={Boolean(
                (safeguardingAlertExistInFields &&
                  caseNoteDetails.fields.find((field) => field.name === 'Safeguarding Alert')) ||
                  !safeguardingAlertExistInFields
              )}
            />
          </div>
          <div className={styles.buttonContainer}>
            {!isReadOnly && (
              <div className={styles.leftButtons}>
                <ButtonAlt variant="outlined" onClick={() => onEditNoteClick()} disabled={isClosedClient} icon="create">
                  Edit this note
                </ButtonAlt>
              </div>
            )}
            <div className={styles.rightButtons}>
              <ButtonAlt
                className={styles.downloadButton}
                variant={'outlined'}
                disabled={isCaseNoteDownloading}
                onClick={() => {
                  onDownloadNoteClick(caseNoteDetails.title, caseNoteNode);
                }}
                icon={'download'}
              >
                {isCaseNoteDownloading ? 'Downloading...' : 'Download Note'}
              </ButtonAlt>
              {IS_DEVELOPMENT && <ButtonAlt disabled={isClosedClient}>Share Case Note</ButtonAlt>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientDetailsNoteDetails;
