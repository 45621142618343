import styles from './FilterSelect.module.scss';
import { useEffect, useRef, useState } from 'react';

interface FilterSelectProps {
  options: { label: string; value: string }[];
  value: string;
  onSelected: (key: string) => void;
}

const FilterSelect = ({ options, value, onSelected }: FilterSelectProps) => {
  const menuNode = useRef<HTMLDivElement>(null);
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target)) {
      return;
    }
    setIsMenuExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  return (
    <div ref={menuNode}>
      <div className={styles.container} onClick={() => setIsMenuExpanded(!isMenuExpanded)}>
        <span className={styles.title}>Filter:</span>
        <div className={styles.placeholderWrapper}>
          <span className={styles.placeholder}>{value}</span>
          <i className={`material-icons ${styles.icon}`}>keyboard_arrow_down</i>
        </div>
      </div>
      <div className={styles.menuWrapper}>
        <div className={isMenuExpanded ? styles.menuShow : styles.menuHide}>
          {options.map((item, index) => {
            return (
              <div
                key={index}
                className={styles.optionWrapper}
                onClick={() => {
                  setIsMenuExpanded(false);
                  onSelected(item.value);
                }}
              >
                <span>{item.value}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FilterSelect;
