import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { scheduleServicesApiSlice, SSTagTypes } from 'redux/services/scheduleServicesApiSlice';
import queryString from 'query-string';
import { SortType } from 'redux/features/utils';

export interface AppointmentReportQuery {
  from?: string;
  to?: string;
  timezone?: string;
  sortByDate?: SortType;
  sortByName?: SortType;
  sortByProcessedOutcome?: SortType;
  sortByInvoiceAmount?: SortType;
}

export type AppointmentReport = Omit<AppointmentSlots, 'invoices'> & {
  collected: number;
  paymentCompleted: string | undefined;
  notesOnUpdate?: string[];
  foundGroup?: {
    _id: string;
    name: string;
  };
  invoiceAmount?: number;
};

export type AppointmentReportResponse = {
  appointments: AppointmentReport[];
  paging: { page: number; perPage: number; totalItems: number };
};

export const appointmentReportApiSlice = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppointmentReportByClinicianId: builder.query<
      AppointmentReportResponse,
      { clinicianId: string; params: AppointmentReportQuery }
    >({
      query: ({ clinicianId, params }) => {
        const stringifiedQueryString = params ? `?${queryString.stringify(params)}` : '';
        return {
          url: `/clinicians/${clinicianId}/reports/appointments${stringifiedQueryString}`
        };
      },
      providesTags: [SSTagTypes.AppointmentReport]
    })
  })
});

export const { useGetAppointmentReportByClinicianIdQuery } = appointmentReportApiSlice;
