import { notification } from 'antd';
import { Location, Provider } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useCallback, useEffect, useState } from 'react';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { getClinicianProviders, getLocations, getProviders } from 'utils/http/BillingService/Invoice/claimingcom';

export const useFetchMedicareLocations = (token: string, activeOnly?: boolean) => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [isLocationsLoading, setIsLocationsLoading] = useState(true);

  const fetchMedicareLocations = useCallback(
    async (token: string, silently?: boolean) => {
      if (!silently) {
        setIsLocationsLoading(true);
      }
      try {
        const query = activeOnly ? 'active=true' : undefined;
        const getLocationsResponse = await getLocations(token, query);
        const { locations } = (await getLocationsResponse.json()) as { locations: Location[] };

        setLocations(locations);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get locations' });
      }
      if (!silently) {
        setIsLocationsLoading(false);
      }
    },
    [activeOnly]
  );

  const refetchMedicareLocations = () => {
    fetchMedicareLocations(token);
  };

  const refetchMedicareLocationsSilently = async () => {
    await fetchMedicareLocations(token, true);
  };

  useEffect(() => {
    if (token) {
      fetchMedicareLocations(token);
    }
  }, [token, fetchMedicareLocations]);

  return { locations, isLocationsLoading, refetchMedicareLocations, refetchMedicareLocationsSilently };
};

export const useFetchMedicareProviders = (token: string, activeOnly?: boolean, clinicianId?: string) => {
  const [providers, setProviders] = useState<Provider[]>([]);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();

  const [isProvidersLoading, setIsProvidersLoading] = useState(true);

  const fetchMedicareProviders = useCallback(
    async (token: string, silently?: boolean) => {
      if (!silently) {
        setIsProvidersLoading(true);
      }

      try {
        if (!clinicianId && (isEdgeAdminView || isEdgeReceptionist)) {
          const query = activeOnly ? 'active=true' : undefined;
          const getProvidersResponse = await getProviders(token, query);
          const { providers } = (await getProvidersResponse.json()) as { providers: Provider[] };

          setProviders(providers);
        } else {
          const getClinicianProvidersResponse = await getClinicianProviders(token, clinicianId);
          const { providers } = (await getClinicianProvidersResponse.json()) as { providers: Provider[] };

          setProviders(activeOnly ? providers.filter((provider) => provider.active) : providers);
        }
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get providers' });
      }

      if (!silently) {
        setIsProvidersLoading(false);
      }
    },
    [activeOnly, clinicianId, isEdgeAdminView, isEdgeReceptionist]
  );

  const refetchClinicianMedicareProviders = () => {
    fetchMedicareProviders(token);
  };

  const refetchClinicianMedicareProvidersSilently = async () => {
    await fetchMedicareProviders(token, true);
  };

  useEffect(() => {
    if (token && medicareRebateFeatureToggle) {
      fetchMedicareProviders(token);
    }
  }, [token, medicareRebateFeatureToggle, fetchMedicareProviders]);

  return {
    providers,
    isProvidersLoading,
    refetchClinicianMedicareProviders,
    refetchClinicianMedicareProvidersSilently
  };
};

export const useFetchMyMedicareProviders = (token: string, activeOnly?: boolean) => {
  const [providers, setProviders] = useState<Provider[]>([]);

  const { isEdgeReceptionist } = useGetAccountPackageView();
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();

  const fetchMyMedicareProviders = useCallback(
    async (token: string) => {
      try {
        const getClinicianProvidersResponse = await getClinicianProviders(token);
        const { providers } = (await getClinicianProvidersResponse.json()) as { providers: Provider[] };

        setProviders(activeOnly ? providers.filter((provider) => provider.active) : providers);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get providers' });
      }
    },
    [activeOnly]
  );

  useEffect(() => {
    if (token && medicareRebateFeatureToggle && !isEdgeReceptionist) {
      fetchMyMedicareProviders(token);
    }
  }, [token, medicareRebateFeatureToggle, fetchMyMedicareProviders, isEdgeReceptionist]);

  return {
    providers
  };
};
