import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import UpComingCardItem from './UpComingCardItem/UpComingCardItem';

import styles from './UpComingCard.module.scss';
import { Skeleton } from 'antd';

interface UpComingCardProps {
  total: number;
  data: AppointmentSlots[];
  practiceName: string;
  isLoading: boolean;
  refetchAppointment: () => void;
}

const UpComingCard = ({ total, data, practiceName, isLoading, refetchAppointment }: UpComingCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.title}>Booked Appointments</div>
        {isLoading ? (
          <div>
            <Skeleton.Avatar active />
          </div>
        ) : (
          <div className={styles.total}>{total}</div>
        )}
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          {[...Array(3)].map((obj, i) => (
            <div key={i} className={styles.loadingWrapper}>
              <div>
                <Skeleton.Avatar active />
              </div>
              <div className={styles.loadingContent}>
                {i === 0 && <Skeleton.Input active className={styles.title} />}
                <Skeleton.Input active className={styles.desc1} />
                <Skeleton.Input active className={styles.desc2} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        data.map((obj, index: number) => (
          <UpComingCardItem
            key={obj._id}
            appointment={obj}
            isNextEvent={index === 0}
            practiceName={practiceName}
            refetchAppointment={refetchAppointment}
          />
        ))
      )}
    </div>
  );
};

export default UpComingCard;
