import { createContext, Dispatch, SetStateAction } from 'react';

interface ModalVisibilityContextInterface {
  addClientModalVisible: boolean;
  setAddClientModalVisible: Dispatch<SetStateAction<boolean>>;
  addGroupModalVisible: boolean;
  setAddGroupModalVisible: Dispatch<SetStateAction<boolean>>;
  addPractitionerModalVisible: boolean;
  setAddPractitionerModalVisible: Dispatch<SetStateAction<boolean>>;
  addAddressBookModalVisible: boolean;
  setAddAddressBookModalVisible: Dispatch<SetStateAction<boolean>>;
  addNewPathwayModalVisible: boolean;
  setAddNewPathwayModalVisible: Dispatch<SetStateAction<boolean>>;
  addReferralModalVisible: boolean;
  setAddReferralModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const ModalVisibilityContext = createContext<ModalVisibilityContextInterface | null>(null);
