import SearchableSelect, { OptionInterface } from 'components/Select/SearchableSelect/SearchableSelect';
import styles from './YoungForm.module.scss';
import { maxGuardianField, REFERRAL_SOURCE_TAGS } from 'components/AddPatientModalV2/constants';
import { useEffect, useMemo, useState } from 'react';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { validateCaseId } from '../AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import { useGetAccessToken } from 'utils/hooks/token';
import { useTranslation } from 'react-i18next';
import { YoungFormProps } from 'components/AddPatientModalV2/interfaces/formProps.interface';
import ClientForm from './components/ClientForm/ClientForm';
import { ClientDetails } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import { Popconfirm } from 'antd';
import GuardianForm from './components/GuardianForm/GuardianForm';
import { v4 as uuid } from 'uuid';

export const YoungForm = ({
  clientFormData,
  onChangeCaseId,
  onChangeClientField,
  onChangeReferral,
  onChangeReferralDetail,
  checkValidation,
  isOnboardingListExist,
  isContentListExist,
  defaultOnBoardingId,
  defaultConsentId,
  defaultAssessmentId,
  clientAddress,
  onChangeClientAddress,
  onPlaceSelected
}: YoungFormProps) => {
  const { referralSourceTags, clientCapability, clientProfiles, referralDetail, caseId } = clientFormData;
  const [t] = useTranslation();
  const { token } = useGetAccessToken();
  const [duplicateCaseId, setDuplicateCaseId] = useState(false);
  const [isCheckingCaseIdDuplicate, setIsCheckingCaseIdDuplicate] = useState(false);
  const selected = REFERRAL_SOURCE_TAGS.filter((option) => referralSourceTags?.includes(option)).map((item) => ({
    _id: item,
    label: item
  }));
  const [selectedReferralSourceTags, setSelectedReferralSourceTags] = useState<OptionInterface[]>(selected || []);

  useEffect(() => {
    if (checkValidation && caseId && caseId.length > 0) {
      validateDuplicateCaseId(caseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const validateDuplicateCaseId = async (caseIdValue: string) => {
    if (token) {
      setIsCheckingCaseIdDuplicate(true);
      await debouncedCheckCaseIdDuplicate(caseIdValue);
    }
  };

  const debouncedCheckCaseIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const resDupCaseId = await validateCaseId(token, value);
        setDuplicateCaseId(resDupCaseId.statusCode !== 200);
        setIsCheckingCaseIdDuplicate(false);
      }, 1000),
    [token]
  );

  const onSelectReferralSourceTags = (selectedOption: OptionInterface) => {
    let newReferralSourceTags = [];
    if (selectedReferralSourceTags.some((item) => item._id === selectedOption._id)) {
      newReferralSourceTags = selectedReferralSourceTags.filter((item) => item._id !== selectedOption._id);
    } else {
      newReferralSourceTags = [...selectedReferralSourceTags, selectedOption];
    }
    setSelectedReferralSourceTags(newReferralSourceTags);
    onChangeReferral(newReferralSourceTags.map((item) => item._id));
  };

  const handleCaseIdChange = async (caseIdValue: string) => {
    onChangeCaseId(caseIdValue);
    if (checkValidation && caseIdValue.length > 0) {
      await validateDuplicateCaseId(caseIdValue);
    } else {
      setDuplicateCaseId(false);
    }
  };

  const handleChangeClientField = (val: ClientDetails) => {
    const newClientField: ClientDetails[] = [];

    clientFormData.clientProfiles.map((clientProfile) => {
      newClientField.push({
        ...clientProfile,
        firstName: clientProfile._id === val._id ? val.firstName : clientProfile.firstName,
        lastName: clientProfile._id === val._id ? val.lastName : clientProfile.lastName,
        email: clientProfile._id === val._id ? val.email : clientProfile.email,
        mobileNumber: clientProfile._id === val._id ? val.mobileNumber : clientProfile.mobileNumber,
        ...(clientProfile.role === 'youngPerson' && {
          dateOfBirth: clientProfile._id === val._id ? val.dateOfBirth : clientProfile.dateOfBirth,
          ...(clientCapability.profileType === 'full' && { isPrimaryContact: true })
        }),
        ...(clientProfile.role !== 'child' && {
          role: clientProfile._id === val._id ? val.role : clientProfile.role
        }),
        ...(clientProfile.role !== 'youngPerson' &&
          typeof clientProfile.isPrimaryContact === 'boolean' && {
            isPrimaryContact: clientProfile._id === val._id ? val.isPrimaryContact : clientProfile.isPrimaryContact
          }),
        communicationPreference:
          clientProfile._id === val._id ? val.communicationPreference : clientProfile.communicationPreference
      });

      return newClientField;
    });
    onChangeClientField(newClientField);
  };

  const totalGuardian = clientProfiles.filter((clientProfile) => clientProfile.role !== 'youngPerson').length;

  const handleRemoveGuardian = (removeId: string) => {
    if (clientProfiles.length >= 2) {
      const removeByIdList = clientProfiles.filter((q) => q._id !== removeId);
      const childProfile = removeByIdList.filter((cp) => cp.role === 'child');
      const guardianProfile = removeByIdList.filter((cp) => cp.role !== 'child');

      const newGuardianField = [];
      for (let obj of guardianProfile) {
        newGuardianField.push({
          ...obj,
          isPrimaryContact: guardianProfile.length < 2 ? true : obj.isPrimaryContact
        });
      }
      const massageField = [...childProfile, ...newGuardianField];
      onChangeClientField(massageField);
    }
  };

  const handleAddNewGuardian = () => {
    if (clientProfiles.length <= maxGuardianField) {
      const newClientProfile = [
        ...clientProfiles,
        {
          _id: uuid(),
          firstName: '',
          lastName: '',
          email: '',
          mobileNumber: '',
          onboardingSurvey: '',
          consentFormId: '',
          isPrimaryContact: false,
          role: '',
          onboarding: {
            onboardingSurveyIds: [defaultOnBoardingId] ?? [],
            isCheckOnBoarding: isOnboardingListExist ?? true,
            onboardingMethod: 'facilitated'
          },
          consentForm: {
            isCheckOnConsentForm: isContentListExist ?? true,
            consentFormIds: [defaultConsentId] ?? []
          },
          assessmentForm: {
            isCheckOnAssessmentForm: true,
            assessmentFormIds: [defaultAssessmentId] ?? []
          },
          communicationPreference: 'noPreference'
        }
      ];
      onChangeClientField(newClientProfile);
    }
  };

  /**
   * Primary Contact Checkbox button for guardian:
   * For Young Person Full Profile, button is  enabled,
   * For Young Person Record Only and Child, button is disabled for the first guardian,
   */
  const disablePrimaryContactBtn = (index: number) =>
    clientCapability.profileType === 'full' &&
    clientProfiles.find((clientProfile) => clientProfile.role === 'youngPerson')
      ? false
      : index === 0;

  return (
    <>
      {/* Selected Referral Source */}
      {selectedReferralSourceTags.length > 0 && (
        <div className={styles.selectedReferralTypessContainer}>
          {selectedReferralSourceTags.map((referralType, index) => (
            <div key={`referralSource${index}`} className={styles.pill}>
              {referralType.label}
            </div>
          ))}
        </div>
      )}
      {/* Referral Source dropdown */}
      <div className={styles.dropdownContainer}>
        <SearchableSelect
          className={styles.rsDropdown}
          label={'Select Referral Source'}
          options={REFERRAL_SOURCE_TAGS.map((item) => ({ _id: item, label: item }))}
          searchBoxPlaceholder={'Select Source'}
          isMultipleChoice
          selectedOptionsProps={selectedReferralSourceTags}
          onSelecteOptionProps={onSelectReferralSourceTags}
          loadingEnable
        />
      </div>
      <div className={styles.fieldContainer}>
        <MaterialInput
          id={`referralDetail`}
          label={'Referral Detail'}
          value={referralDetail}
          onChange={(event) => {
            onChangeReferralDetail(event.target.value);
          }}
          required
        />
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && duplicateCaseId && styles.fieldError)}>
        <MaterialInput
          id={`clientCaseId`}
          label={t('form.client_reference')}
          onChange={(e) => handleCaseIdChange(e.target.value)}
          isLoading={isCheckingCaseIdDuplicate}
          value={caseId}
          maxLength={20}
          required
        />
        {checkValidation && duplicateCaseId && (
          <div className={styles.fieldError}>{t('form.error.duplicate_client_reference')}</div>
        )}
      </div>
      <ClientForm
        id={'youngField'}
        clientDetail={clientProfiles.find((clientProfile) => clientProfile.role === 'youngPerson') || clientProfiles[0]}
        capabilityData={clientCapability}
        onChangeClientField={handleChangeClientField}
        checkValidation={checkValidation}
        clientAddress={clientAddress}
        onChangeClientAddress={onChangeClientAddress}
        onPlaceSelected={onPlaceSelected}
      />
      {clientProfiles
        .filter((clientProfile) => clientProfile.role !== 'youngPerson')
        .map((clientProfile, index) => (
          <div className={styles.guardianContainer} key={index}>
            <div className={styles.guardianHeader}>
              <div className={styles.guardianTitle}>PARENT OR GUARDIAN {totalGuardian > 1 && index + 1}</div>
              {index > 0 && (
                <Popconfirm
                  title="Are you sure to remove this Parent or Guardian?"
                  onConfirm={() => handleRemoveGuardian(clientProfile._id)}
                  okText="Yes"
                  cancelText="No"
                  placement={'bottomRight'}
                >
                  <div className={`material-icons-outlined ${styles.removeBtn}`}>close</div>
                </Popconfirm>
              )}
            </div>
            <GuardianForm
              id={`Guardian${index}`}
              key={index}
              clientDetail={clientProfile}
              onChangeClientField={handleChangeClientField}
              checkValidation={checkValidation}
              isFirstGuardian={disablePrimaryContactBtn(index)}
              updateDetail={clientProfiles.length}
              clientRecordType={clientFormData.clientCapability.profileType}
            />
          </div>
        ))}
      {clientProfiles.length <= maxGuardianField && (
        <div className={styles.addGuardianBtn} onClick={() => handleAddNewGuardian()}>
          <i className={`material-icons-outlined ${styles.icon}`}>add</i>
          Add another parent or guardian
        </div>
      )}
    </>
  );
};
