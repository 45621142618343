import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const initialState = {
  disableAssignmentUILoading: false
};

export const packageAssignmentListSlice = createSlice({
  name: 'packageAssignmentListSlice',
  initialState,
  reducers: {
    setAssignmentUILoading: (state, action) => {
      state.disableAssignmentUILoading = action.payload;
    }
  }
});

export const selectAssignmentLoading = (state: RootState) =>
  state.packageAssignmentListSlice.disableAssignmentUILoading;

export const { setAssignmentUILoading } = packageAssignmentListSlice.actions;

export default packageAssignmentListSlice.reducer;
