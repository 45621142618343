import AppointmentReportFilters from '../AppointmentReportFilters/AppointmentReportFilters';
import AppointmentReportHeader from '../AppointmentReportHeader/AppointmentReportHeader';
import styles from './AppointmentReportList.module.scss';
import { useAppSelector } from 'redux/hooks';
import { selectFilters, selectSorting } from 'redux/features/practitionerReport/practitionerReportSlice';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAppointmentReport } from 'redux/features/practitionerReport/useGetAppointmentReport';
import { useLocation } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useContext, useMemo } from 'react';
import { Skeleton } from 'antd';
import AppointmentReportItem from '../AppointmentReportItem/AppointmentReportItem';
import columnStyles from '../AppointmentReportHeader/AppointmentReportHeaderColumn.module.scss';
import { CSVLink } from 'react-csv';
import { useCurrency } from 'utils/hooks/useCurrency';
import { getCSVHeaders } from '../AppointmentReportHeader/column';
import { getCSVData } from 'pages/PractitionerReport/helper';
import { useGetClinicianProfileByIdQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import moment from 'moment';
import { defaultTimezone } from 'utils/hooks/GetTimezones/getTimezones';
import { SubTabContext } from 'layouts/AuthLayoutT23/context/SubTabContextProvider';

const AppointmentReportList = () => {
  const { CURRENCY_SYMBOL } = useCurrency();
  const location = useLocation();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { showSubTab } = useContext(SubTabContext);

  const filter = useAppSelector(selectFilters);
  const sorting = useAppSelector(selectSorting);

  const clinicianId = useMemo(() => {
    if (isEdgeAdminView || isEdgeReceptionistView) {
      return location.pathname.split('/')[3];
    }
    return auth0ClinicianId;
  }, [location.pathname, auth0ClinicianId, isEdgeAdminView, isEdgeReceptionistView]);

  const { data, isLoading: isFetchingAppointmentReport } = useGetAppointmentReport({
    clinicianId,
    dateString: filter.dateString,
    sortBy: sorting.sortBy,
    sortType: sorting.sortType
  });

  const {
    data: clinicianProfile,
    isLoading: isClinicianProfileLoading,
    isFetching: isClinicianProfileFetching
  } = useGetClinicianProfileByIdQuery({ clinicianId: clinicianId || '' }, { skip: !clinicianId });

  const ableToExport =
    !isFetchingAppointmentReport &&
    !isClinicianProfileLoading &&
    !isClinicianProfileFetching &&
    data &&
    clinicianProfile;

  const abbreviatedTimezone = moment().tz(defaultTimezone).format('z');

  return (
    <div className={styles.container}>
      <div className={styles.filterWrapper}>
        <AppointmentReportFilters />
        <CSVLink
          headers={getCSVHeaders(CURRENCY_SYMBOL, abbreviatedTimezone)}
          data={data?.appointments ? getCSVData(data.appointments, CURRENCY_SYMBOL) : []}
          filename={`${clinicianProfile?.name || ''}_Service_Report_${filter.dateString}`.replaceAll(' ', '_')}
          className={
            ableToExport
              ? isEdgeAdminView
                ? styles.exportButtonAdmin
                : styles.exportButtonUser
              : styles.exportButtonDisabled
          }
        >
          Export CSV
        </CSVLink>
      </div>

      <div
        style={{ maxWidth: !showSubTab ? 'calc(100vw - 127px)' : 'calc(100vw - 367px)' }}
        className={styles.listWrapper}
      >
        <div className={styles.contentWrapper}>
          <AppointmentReportHeader />
          {isFetchingAppointmentReport ? (
            <>
              {[...Array(5)].map((_obj, i) => (
                <div key={i} className={styles.loadingContainer}>
                  <Skeleton.Input active className={columnStyles.serviceId} />
                  <Skeleton.Input active className={columnStyles.dateOfService} />
                  <Skeleton.Input active className={columnStyles.startTime} />
                  <Skeleton.Input active className={columnStyles.nameOfService} />
                  <Skeleton.Input active className={columnStyles.processedOutcome} />
                  <Skeleton.Input active className={columnStyles.waiveCancellationFee} />
                  <Skeleton.Input active className={columnStyles.reviewNotes} />
                  <Skeleton.Input active className={columnStyles.tID} />
                  <Skeleton.Input active className={columnStyles.clientName} />
                  <Skeleton.Input active className={columnStyles.collected} />
                  <Skeleton.Input active className={columnStyles.invoiceAmount} />
                  <Skeleton.Input active className={columnStyles.paymentCompleted} />
                  <Skeleton.Input active className={columnStyles.notesOnUpdate} />
                </div>
              ))}
            </>
          ) : !isFetchingAppointmentReport && (!data || !data.appointments || data.appointments.length < 1) ? (
            <div className={styles.noDataFound}>No data found</div>
          ) : (
            data?.appointments.map((appointment, index) => (
              <AppointmentReportItem key={index} appointmentReport={appointment} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentReportList;
