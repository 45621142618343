import classNames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { FilterCheckListItem, FilterSubMenuItem } from '../FilterCheckList/FilterCheckList';
import styles from './FilterCheckItem.module.scss';
import { useState } from 'react';

interface FilterCheckItemProps {
  id: string;
  item: FilterCheckListItem;
  searchValue: string;
  selectedFilterList: FilterCheckListItem[];
  onChangeItem: (value: {
    item?: FilterCheckListItem;
    toggleAllValue?: boolean;
    subMenuItem?: FilterSubMenuItem;
  }) => void;
  multiple?: boolean;
}

const FilterCheckItem = ({
  id,
  item,
  searchValue,
  selectedFilterList,
  onChangeItem,
  multiple = true
}: FilterCheckItemProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const isActive = selectedFilterList.find((i) => i._id === item._id);
  const subMenuIsActive = isActive?.subMenu?.find((i) => i._id === item.selectedSubMenu?._id);
  const matchedIndex = item.name.toLowerCase().indexOf(searchValue.toLowerCase());

  const [isHovered, setIsHovered] = useState(false);
  const [top, setTop] = useState(0);

  const handleMouseEnter = () => {
    setIsHovered(true);
    const parentComponent = document.getElementById(`renderFilterOptionLists-${id}`);
    const childComponent = document.getElementById(item._id);

    if (parentComponent && childComponent) {
      setTop(childComponent.getBoundingClientRect().top - parentComponent.getBoundingClientRect().top);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      id={item._id}
      className={classNames(
        styles.item,
        isActive && styles.active,
        isActive && !multiple && styles.single,
        isEdgeAdminView && styles.admin
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={styles.text}
        onClick={(e) => {
          e.stopPropagation();
          e.bubbles && !item.subMenu && onChangeItem({ item });
        }}
      >
        {multiple && (
          <CheckBox
            id={item._id}
            className={styles.checkBox}
            inputClassName={styles.checkBoxInput}
            value={!!isActive}
            onChange={() => onChangeItem({ item })}
          />
        )}
        <div
          className={classNames(styles.label, item.backgroundColor && styles.coloredLabel)}
          style={{ backgroundColor: item.backgroundColor, color: item.fontColor }}
        >
          {item.name.substring(0, matchedIndex)}
          <span className={styles.matchedValue}>
            {item.name.substring(matchedIndex, matchedIndex + searchValue.length)}
          </span>
          {item.name.substring(matchedIndex + searchValue.length)}
        </div>
      </div>
      {item.subMenu && (
        <div className={styles.subMenuContainer}>
          <i className={`material-icons-outlined ${styles.navIcon}`}>navigate_next</i>
          <div hidden={!isHovered} className={styles.subMenu} style={{ top }}>
            {item.subMenu.map((subMenuItem, index) => (
              <div
                key={index}
                className={classNames(
                  styles.item,
                  isEdgeAdminView && styles.admin,
                  subMenuIsActive?._id === subMenuItem._id && !multiple && styles.single,
                  subMenuItem.disabled && styles.disabled
                )}
                onClick={() => !subMenuItem.disabled && subMenuItem._id && onChangeItem({ item, subMenuItem })}
              >
                {subMenuItem.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterCheckItem;
