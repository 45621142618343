import classNames from 'classnames';
import { useField } from 'formik';
import styles from './PhoneCallDialClientInstructions.module.scss';

const PhoneCallDialClientInstructions = () => {
  const [{ value }, telehealthInstructionsMeta, { setValue }] = useField('phoneCallDialClientInstructions');
  return (
    <div className={classNames(styles.fieldContainer)}>
      <textarea
        id={'phoneCallDialClientInstructions'}
        className={styles.textarea}
        rows={4}
        maxLength={320}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {telehealthInstructionsMeta.touched && (
        <div className={styles.fieldError}>{telehealthInstructionsMeta.error}</div>
      )}
    </div>
  );
};

export default PhoneCallDialClientInstructions;
