import { createSlice } from '@reduxjs/toolkit';
import { ReportStatus } from 'components/layout/Header/components/MyProfile/components/Notification/components/NotificationsModal/components/ReportNotification/interfaces';
import { RootState } from 'redux/store';

export interface ReportPaging {
  page: number;
  perPage: number;
}

interface ReportState {
  paging: ReportPaging;
  filters: {
    status?: ReportStatus | 'allStatus';
  };
  search: string;
}

const initialState: ReportState = {
  paging: {
    page: 1,
    perPage: 10
  },
  filters: {
    status: 'allStatus'
  },
  search: ''
};

export const reportSlice = createSlice({
  name: 'reportSlice',
  initialState,
  reducers: {
    setPaging: (state, action) => {
      state.paging = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    setSearch: (state, action) => {
      state.search = action.payload;
    },

    resetAll: (state) => {
      state.paging = initialState.paging;
      state.filters = initialState.filters;
      state.search = initialState.search;
    }
  }
});

export const selectPaging = (state: RootState) => state.reportSlice.paging;
export const selectFilters = (state: RootState) => state.reportSlice.filters;
export const selectSearch = (state: RootState) => state.reportSlice.search;

export const { setPaging, resetPaging, setFilters, resetFilters, setSearch, resetAll } = reportSlice.actions;

export default reportSlice.reducer;
