import classNames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { HTMLProps, ReactNode } from 'react';
import styles from './TitleInput.module.scss';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface TitleInputProps {
  error?: string;
  label?: string;
  help?: string;
  prefix?: string;
  inputProps?: HTMLProps<HTMLInputElement>;
  className?: string;
  inputContainerClassName?: string;
  inputContainer?: string;
  defaultLine?: boolean;
  icon?: string;
  hideErrorDesc?: boolean;
  postFix?: ReactNode;
  errorClass?: string;
}

const TitleInput = ({
  error,
  label,
  help,
  prefix,
  inputProps,
  className,
  inputContainerClassName,
  inputContainer,
  defaultLine,
  icon,
  hideErrorDesc,
  postFix,
  errorClass
}: TitleInputProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  return (
    <div className={classNames(className, isEdgeAdminView && 't23-admin-theme')}>
      <div className={styles.labelContainer}>
        {label && <div className={styles.label}>{label}</div>}
        {help && <HelpOutLineWithTooltips id={`${inputProps?.id}-help`} desc={help} iconClass={styles.helpIcon} />}
      </div>
      <div
        className={classNames(
          styles.inputContainer,
          {
            [styles.error]: error
          },
          inputContainerClassName
        )}
      >
        {prefix && <div className={styles.prefixContainer}>{prefix}</div>}
        {icon && <i className={classNames('material-icons', styles.icon)}>{icon}</i>}
        <input
          className={classNames(
            styles.inputField,
            inputContainer,
            (icon || prefix) && styles.iconInput,
            defaultLine && styles.defaultLine
          )}
          {...inputProps}
        />
        {postFix}
      </div>
      {error && !hideErrorDesc && <ErrorMessage className={errorClass} error={error} visible />}
    </div>
  );
};

export default TitleInput;
