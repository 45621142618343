import ModalV2 from 'components/ModalV2/ModalV2';
import { useState } from 'react';
import { EditStageForm, StageValidateField } from '../../../interface';
import StageFormField, { validateStageField } from '../components/StageFormField/StageFormField';
import styles from './EditStageModal.module.scss';
import { notification } from 'antd';
import { patchStage } from 'utils/http/ClinicianProfileService/Groups/GroupPathway';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface EditStageModalProps {
  groupId: string;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onSuccessEdit: () => void;
  stageFormFieldData: EditStageForm;
}

const EditStageModal = ({
  groupId,
  showModal,
  setShowModal,
  onSuccessEdit,
  stageFormFieldData
}: EditStageModalProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const [checkValidation, setCheckValidation] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [errorMessage, setErrorMessage] = useState<StageValidateField>({ name: '' });
  const [stageFormField, setStageFormField] = useState(stageFormFieldData);

  const validationCheck = (value: EditStageForm) => {
    const validation = validateStageField(value);
    setErrorMessage(validation.errorMessages);
    if (!validation.isValidationPass) {
      setSubmitStatus('');
    }
    return validation;
  };

  const handleChangeFieldValue = async (key: string, value: string) => {
    const newStageField = {
      ...stageFormField,
      [key]: value
    };
    if (key === 'name' && checkValidation) {
      validationCheck(newStageField);
    }
    setStageFormField(newStageField);
  };

  const handleChangeGroupStage = (val: EditStageForm['stageType']) => {
    setStageFormField({
      ...stageFormField,
      stageType: val
    });
  };

  const handleEditSubmit = async () => {
    setSubmitStatus('active');
    setCheckValidation(true);

    const validation = validationCheck(stageFormField);

    if (validation.isValidationPass) {
      try {
        const editPayload = {
          name: stageFormField.name,
          description: stageFormField.description,
          stageType: stageFormField.stageType
        };
        await patchStage(token, accountId, groupId, stageFormField._id, editPayload);
        setSubmitStatus('finished');
        notification.success({
          message: 'Stage Updated',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setTimeout(() => {
          setSubmitStatus('');
          setCheckValidation(false);
          onSuccessEdit();
          setShowModal(false);
        }, 1000);
      } catch (ex) {
        console.error(ex);
        setSubmitStatus('');
        notification.error({
          message: 'Edit Stage fail to update',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={showModal}
      title={'Edit Stage'}
      onModalClose={() => setShowModal(!showModal)}
    >
      <StageFormField
        id={stageFormField?._id || 'edit'}
        stageFormFieldData={stageFormField}
        onChangeStageType={handleChangeGroupStage}
        checkValidation={checkValidation}
        errorMessage={errorMessage}
        onChangeFieldValue={handleChangeFieldValue}
      />
      <div className={styles.submitButtonContainer}>
        <ButtonAlt
          disabled={submitStatus !== ''}
          className={styles.button}
          variant="outlined"
          type={'button'}
          onClick={() => setShowModal(showModal)}
        >
          Cancel
        </ButtonAlt>
        <ButtonAlt
          className={styles.button}
          status={submitStatus}
          disabled={submitStatus !== ''}
          onClick={handleEditSubmit}
          id="AddStageBtnId"
        >
          Edit
        </ButtonAlt>
      </div>
    </ModalV2>
  );
};

export default EditStageModal;
