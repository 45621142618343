import styles from './EpisodeCardLoading.module.scss';
import { Skeleton } from 'antd';

const EpisodeCardLoading = () => (
  <div className={styles.container}>
    <div className={styles.header}>
      <div className={styles.invoiceWrapper}>
        <Skeleton.Input active className={styles.invoiceOrder} />
        <Skeleton.Input active className={styles.invoiceId} />
      </div>
      <Skeleton.Input active className={styles.status} />
    </div>
    <div className={styles.content}>
      <div className={styles.tableTitle}>
        <div className={styles.pillWrapper}>
          <Skeleton.Input active style={{ maxWidth: '80px' }} className={styles.tableTitlePill} />
        </div>
        <div className={styles.pillWrapper}>
          <Skeleton.Input active style={{ maxWidth: '80px' }} className={styles.tableTitlePill} />
        </div>
        <div className={styles.pillWrapper}>
          <Skeleton.Input active style={{ maxWidth: '80px' }} className={styles.tableTitlePill} />
        </div>
        <div className={styles.pillWrapper}>
          <Skeleton.Input active style={{ maxWidth: '80px' }} className={styles.tableTitlePill} />
        </div>
        <div className={styles.pillWrapper} style={{ maxWidth: '150px' }}>
          <Skeleton.Input active style={{ maxWidth: '130px' }} className={styles.tableTitlePill} />
        </div>
        <div className={styles.pillWrapper} style={{ maxWidth: '130px' }}>
          <Skeleton.Input active style={{ maxWidth: '100px' }} className={styles.tableTitlePill} />
        </div>
        <div className={styles.pillWrapper} style={{ maxWidth: '230px' }}>
          <Skeleton.Input active style={{ maxWidth: '50px' }} className={styles.tableTitlePill} />
        </div>
      </div>
      <div className={styles.tableContent}>
        <div className={styles.pillWrapper}>
          <Skeleton.Input active style={{ maxWidth: '80px' }} className={styles.tableContentPill} />
        </div>
        <div className={styles.pillWrapper}>
          <Skeleton.Input active style={{ maxWidth: '60px' }} className={styles.tableContentPill} />
        </div>
        <div className={styles.pillWrapper}>
          <Skeleton.Input active style={{ maxWidth: '80px' }} className={styles.tableContentPill} />
        </div>
        <div className={styles.pillWrapper}>
          <Skeleton.Input active style={{ maxWidth: '80px' }} className={styles.tableContentPill} />
          <Skeleton.Input active style={{ maxWidth: '70px' }} className={styles.tableContentPill} />
        </div>
        <div className={styles.pillWrapper} style={{ maxWidth: '150px' }}>
          <Skeleton.Input active style={{ maxWidth: '70px' }} className={styles.tableContentPill} />
          <Skeleton.Input active style={{ maxWidth: '80px' }} className={styles.tableContentPill} />
        </div>
        <div className={styles.pillWrapper} style={{ maxWidth: '130px' }}>
          <Skeleton.Input active style={{ maxWidth: '50px' }} className={styles.tableContentPill} />
          <Skeleton.Input active style={{ maxWidth: '100px' }} className={styles.tableContentPill} />
        </div>
        <div className={styles.pillWrapper} style={{ maxWidth: '230px' }}>
          <Skeleton.Input active style={{ maxWidth: '230px' }} className={styles.tableContentPill} />
        </div>
      </div>
    </div>
  </div>
);

export default EpisodeCardLoading;
