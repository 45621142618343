import { Integration, IntegrationStatus, InvoiceSettings } from 'pages/Invoices/interface';
import { billingServicesApiSlice, BSTagTypes } from 'redux/services/billingServicesApiSlice';
import { getClinician } from 'utils/http/clinician';

interface InvoiceSettingsResponse {
  clinicianId?: string;
  accountId: string;
  invoiceSettings?: InvoiceSettings;
  integration?: Integration;
  medicare?: {
    id: string;
    status: IntegrationStatus;
    providerNumber?: string;
    connectedBy: string;
    connectedAt: string;
  };
}

const defaultInvoiceSettings = {
  invoiceSettings: {
    dueDate: 0,
    taxRate: 0,
    note: '',
    paymentMethods: []
  }
};

export const getInvoiceSettingsApiSlice = billingServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceSettings: builder.query<InvoiceSettings, { clinicianId: string }>({
      query: () => {
        return {
          url: '/invoice-settings'
        };
      },
      transformResponse: async (res: InvoiceSettingsResponse, _meta, { clinicianId }) => {
        const { invoiceSettings, medicare, integration } = res || defaultInvoiceSettings;
        const callGetClinician = await getClinician(clinicianId);
        const data = await callGetClinician.json();
        const { practice, email, mobileNumber } = data;
        const isInvoiceSettingCDetailsExist = !!(invoiceSettings && invoiceSettings.contactDetails);

        const firstPractice = practice ? practice : undefined;

        return {
          dueDate: invoiceSettings?.dueDate,
          note: invoiceSettings?.note,
          paymentMethods: invoiceSettings?.paymentMethods,
          taxRate: invoiceSettings?.taxRate,
          contactDetails: {
            practice: {
              address: {
                value: isInvoiceSettingCDetailsExist
                  ? invoiceSettings.contactDetails?.practice.address.value
                  : firstPractice?.locations?.[0],
                isVisible: isInvoiceSettingCDetailsExist
                  ? !!invoiceSettings?.contactDetails?.practice.address.isVisible
                  : true
              },
              mobileNumber: {
                value: isInvoiceSettingCDetailsExist
                  ? invoiceSettings.contactDetails?.practice.mobileNumber.value
                  : firstPractice?.mobileNumber,
                isVisible: isInvoiceSettingCDetailsExist
                  ? !!invoiceSettings.contactDetails?.practice.mobileNumber.isVisible
                  : true
              }
            },
            clinician: {
              mobileNumber: {
                value: isInvoiceSettingCDetailsExist
                  ? invoiceSettings.contactDetails?.clinician.mobileNumber.value
                  : mobileNumber,
                isVisible: isInvoiceSettingCDetailsExist
                  ? !!invoiceSettings.contactDetails?.clinician.mobileNumber.isVisible
                  : true
              },
              email: {
                value: isInvoiceSettingCDetailsExist ? invoiceSettings.contactDetails?.clinician.email.value : email,
                isVisible: isInvoiceSettingCDetailsExist
                  ? !!invoiceSettings.contactDetails?.clinician.email.isVisible
                  : true
              }
            }
          },
          medicare,
          integration
        };
      },
      providesTags: (_result, _error, arg) => [{ type: BSTagTypes.InvoiceSetting, id: arg.clinicianId }]
    }),
    updateInvoiceSettings: builder.mutation({
      query: ({ payload }: { payload: Record<string, any> }) => ({
        url: `/invoice-settings`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: (_result, error) => (!error ? [{ type: BSTagTypes.InvoiceSetting }] : [])
    }),
    updateInvoiceContactDetails: builder.mutation({
      query: ({ payload }: { payload: Record<string, any> }) => ({
        url: `/invoice-settings/contact-details`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: (_result, error) => (!error ? [{ type: BSTagTypes.InvoiceSetting }] : [])
    })
  })
});

export const { useGetInvoiceSettingsQuery, useUpdateInvoiceSettingsMutation, useUpdateInvoiceContactDetailsMutation } =
  getInvoiceSettingsApiSlice;
