import { CalendarNav, CalendarNext, CalendarPrev, Datepicker } from '@mobiscroll/react';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './AppointmentDatePicker.module.scss';

export type DatePickerValidDate = string | { start: string; end: string };

interface AppointmentDatePickerProps {
  selectedDate?: Date;
  placeholder?: string;
  error?: string;
  hideErrorDesc?: boolean;
  onSelect: (date: Date) => void;
  disabled?: boolean;
  min?: string;
  max?: string;
  invalid?: DatePickerValidDate[];
  valid?: DatePickerValidDate[];
  labelFormat?: string;
  showBorderBottom?: boolean;
  icon?: string;
  controllerClassName?: string;
  labelClassName?: string;
  iconClassName?: string;
  borderBottomClassName?: string;
  placeholderClassName?: string;
  onInlineRef?: (ref: Datepicker | null) => void;
}

const AppointmentDatePicker = ({
  selectedDate,
  onSelect,
  placeholder,
  error,
  hideErrorDesc,
  disabled,
  min,
  max,
  invalid,
  valid,
  labelFormat = 'DD MMM YYYY',
  icon,
  showBorderBottom,
  controllerClassName,
  labelClassName,
  iconClassName,
  borderBottomClassName,
  placeholderClassName,
  onInlineRef
}: AppointmentDatePickerProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const datePickerControllerRef = useRef<HTMLDivElement>(null);
  const datePickerRef = useRef<Datepicker>(null);

  const handleClickOutSide = (event: any) => {
    if (
      datePickerControllerRef.current?.contains(event.target) ||
      datePickerRef.current?._wrapper?.contains(event.target)
    ) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  useEffect(() => {
    onInlineRef?.(datePickerRef.current || null);
  }, [onInlineRef]);

  return (
    <div className={classNames(isEdgeAdminView && 't23-admin-theme')}>
      <div
        ref={datePickerControllerRef}
        className={classNames(
          styles.datePickerController,
          error && styles.labelError,
          disabled && styles.disabled,
          controllerClassName
        )}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <div
          className={classNames(
            styles.label,
            error && styles.error,
            labelClassName,
            !selectedDate && placeholderClassName
          )}
        >
          {selectedDate ? moment(selectedDate).format(labelFormat) : placeholder}
        </div>
        <i className={classNames('material-icons', styles.icon, error && styles.error, iconClassName)}>
          {icon ? icon : 'arrow_drop_down'}
        </i>
        {(showBorderBottom || (isOpen && !error)) && (
          <div className={classNames(styles.borderBottom, borderBottomClassName)} />
        )}
      </div>
      <Datepicker
        ref={datePickerRef}
        display="anchored"
        anchor={datePickerControllerRef.current || undefined}
        controls={['calendar']}
        firstDay={1}
        showToday
        touchUi={false}
        showInput={false}
        isOpen={isOpen}
        defaultSelection={selectedDate && new Date(selectedDate.toISOString().split('T')[0])}
        onChange={(_, inst) => {
          onSelect(inst.getVal());
          setIsOpen(false);
        }}
        disabled={disabled}
        renderCalendarHeader={() => (
          <div className={styles.customCalendarHeader}>
            <CalendarPrev />
            <CalendarNav />
            <CalendarNext />
          </div>
        )}
        renderDay={(day: any) => {
          const isDefault = selectedDate ? day.date.toDateString() === selectedDate.toDateString() : false;
          return (
            <div
              className={classNames(
                isEdgeAdminView ? styles.adminCustomDay : styles.customDay,
                isDefault && (isEdgeAdminView ? styles.adminCustomDaySelected : styles.customDaySelected),
                day.date.toDateString() === new Date().toDateString() &&
                  (isEdgeAdminView ? styles.adminTodayDay : styles.todayDay),
                'customDays'
              )}
            >
              {day.date.getDate()}
            </div>
          );
        }}
        width={330}
        showOuterDays={false}
        datePickerProps
        min={min}
        max={max}
        invalid={invalid}
        valid={valid}
      />
      {error && !hideErrorDesc && <div className={styles.fieldError}>{error}</div>}
    </div>
  );
};

export default AppointmentDatePicker;
