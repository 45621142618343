import { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import { getClientLatestActivity } from 'utils/http/ActivityFeedService/activities';
import { getAppointmentsByClientRecordIds, getPatientNextAppointments } from 'utils/http/appointment';
import { getMicroCheckIns } from 'utils/http/checkIn';
import { LatestClientsActivityInterface } from 'interfaces/Clients/latestClientsActivity';
import { NextAppointment } from 'interfaces/Clients/nextAppointment';
import {
  clientListFilterParameter,
  ClientListingCount,
  GetClientProfileInterface,
  ProspectStatus,
  ClientStatus
} from 'interfaces/Clients/clientsRecord';
import { PractitionersStatus } from 'interfaces/Practitioners/practitionersListing';
import {
  getClientsRecord,
  getClientsRecordByAccountId
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { appointmentByClientRecordId, AppointmentDocument } from 'interfaces/Clients/Appointment';
import moment from 'moment';
import { sortBy } from 'lodash';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { TabIds, SortBy, CLIENT_LIST_SORT_INFO } from 'pages/Client/Client';
import queryString from 'query-string';

interface MicroCheckInsInterface {
  microCheckIns: {
    name: string;
    isPublished: boolean;
    isDeleted: boolean;
    clients: {
      clientRecordId: string;
      _id: string;
    }[];
  }[];
}

export const useFetchPrograms = (token: string) => {
  const [programs, setPrograms] = useState<MicroCheckInsInterface['microCheckIns'] | undefined>(undefined);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);
  const { isEdgeReceptionist } = useGetAccountPackageView();

  const fetchProgram = async () => {
    if (isEdgeReceptionist) {
      return;
    }
    const callGetMicroCheckIns = await getMicroCheckIns(token);

    const { microCheckIns } = (await callGetMicroCheckIns.json()) as MicroCheckInsInterface;

    setPrograms(microCheckIns.filter((mci) => !mci.isDeleted && mci.isPublished));
    setIsProgramsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchProgram();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { programs, isProgramsLoading };
};

export const useFetchLatestActivity = (token: string) => {
  const [latestClientsActivity, setLatestClientsActivity] = useState<LatestClientsActivityInterface[] | undefined>(
    undefined
  );
  const [isLatestClientsActivityLoading, setIsLatestClientsActivityLoading] = useState(true);

  const fetchLatestActivity = async () => {
    const getClientListLatestActivity = await getClientLatestActivity(token);

    const patientsLatestActivity = (await getClientListLatestActivity.json()) as LatestClientsActivityInterface[];

    setLatestClientsActivity(patientsLatestActivity);
    setIsLatestClientsActivityLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchLatestActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { latestClientsActivity, isLatestClientsActivityLoading };
};

export const useFetchNextAppointments = (token: string, isAdmin: boolean, clientRecordIds: string) => {
  const [nextAppointments, setNextAppointments] = useState<NextAppointment[]>([]);
  const [isNextAppointmentsLoading, setIsNextAppointmentsLoading] = useState(true);

  const fetchNextAppointments = useCallback(
    async (token: string) => {
      setIsNextAppointmentsLoading(true);

      try {
        const callGetPatientNextAppointments = await getPatientNextAppointments(token, isAdmin, clientRecordIds);
        const { patientNextAppointments } = await callGetPatientNextAppointments.json();
        setNextAppointments(patientNextAppointments);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get next appointments' });
      }

      setIsNextAppointmentsLoading(false);
    },
    [clientRecordIds, isAdmin]
  );

  useEffect(() => {
    if (token && clientRecordIds.length > 0) {
      fetchNextAppointments(token);
    }
  }, [token, clientRecordIds, fetchNextAppointments]);

  return { nextAppointments, isNextAppointmentsLoading, fetchNextAppointments };
};

export const useFetchClientsRecord = (token: string, option: clientListFilterParameter) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [clientRecordList, setClientRecordList] = useState<GetClientProfileInterface>({
    clientRecords: [],
    recordStatusCounts: {
      active: 0,
      closed: 0,
      waitlist: 0
    },
    paging: {
      page: 1,
      perPage: 10,
      totalItem: 10,
      totalPage: 1
    }
  } as GetClientProfileInterface);
  const [isClientRecordListLoading, setIsClientRecordListLoading] = useState(true);
  const [hasMoreData, setHasMoreData] = useState(false);

  useEffect(() => {
    clientRecordList.paging.totalItem / clientRecordList.paging.perPage > clientRecordList.paging.page
      ? setHasMoreData(true)
      : setHasMoreData(false);
  }, [clientRecordList.paging.page, clientRecordList.paging.perPage, clientRecordList.paging.totalItem]);

  const fetchClientsRecord = async (token: string, option: clientListFilterParameter) => {
    try {
      let queryString;
      if (option.status === 'waitlist') {
        queryString = `?perPage=${option.perPage}&page=${option.page}&recordStatus=${
          option.prospectStatus ?? 'waitlist'
        }&q=${option.q}&profileType=recordOnly&${
          option.sortByClientName ? `sortByClientName=${option.sortByClientName}` : ''
        }&${option.sortByClientSince ? `sortByClientSince=${option.sortByClientSince}` : ''}`;
      } else {
        queryString = `?perPage=${option.perPage}&page=${option.page}&recordStatus=${option.status}&q=${
          option.q
        }&profileType=full,recordOnly&${option.sortByClientName ? `sortByClientName=${option.sortByClientName}` : ''}&${
          option.sortByClientSince ? `sortByClientSince=${option.sortByClientSince}` : ''
        }`;
      }

      if ((isEdgeAdminView || isEdgeReceptionist) && accountId) {
        const clientsData = await getClientsRecordByAccountId(token, accountId, queryString);
        const clientsRecordData: GetClientProfileInterface = await clientsData.json();

        const clientRecordIds = clientsRecordData.clientRecords.map((clientListObj) => clientListObj._id).join(',');

        const appointmentsData = await getAppointmentsByClientRecordIds(
          accountId,
          '2020-01-01',
          moment(new Date()).add(3, 'months').format('YYYY-MM-DD'),
          token,
          clientRecordIds
        );

        const appointmentsByClientRecordId: appointmentByClientRecordId[] = await appointmentsData.json();

        for (const key in clientsRecordData.clientRecords) {
          const matchedData = appointmentsByClientRecordId.find(
            (appointment) => appointment._id === clientsRecordData.clientRecords[key]._id
          );

          if (matchedData) {
            clientsRecordData.clientRecords[key].appointmentStatistics = massagePatientAppointments(
              matchedData.totalAppointments
            );
          } else {
            clientsRecordData.clientRecords[key].appointmentStatistics = {
              completedAppointmentsTotal: 0,
              upcomingAppointmentsTotal: 0
            };
          }
        }

        setClientRecordList(clientsRecordData);
      } else {
        const clientsData = await getClientsRecord(token, queryString);
        const clientsRecordData = await clientsData.json();

        setClientRecordList(clientsRecordData);
      }
    } catch (ex) {
      console.log(ex);
      notification.error({ message: 'Something went wrong while trying to get your clients record' });
    }

    setIsClientRecordListLoading(false);
  };

  useEffect(() => {
    if (token) {
      setIsClientRecordListLoading(true);
      fetchClientsRecord(token, option);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isEdgeAdminView]);

  return { clientRecordList, isClientRecordListLoading, fetchClientsRecord, hasMoreData };
};

export const massagePatientAppointments = (
  patientAppointments: AppointmentDocument[]
): {
  upcomingAppointmentsTotal: number;
  upcomingAppointments: AppointmentDocument[];
  completedAppointmentsTotal: number;
  completedAppointments: {
    month: string;
    appointments: (AppointmentDocument & { appointmentNo: number; month: string })[];
  }[];
} => {
  const upcomingAppointments = sortBy(
    patientAppointments.filter(
      (patientAppt) => moment(new Date()) <= moment(patientAppt.date + ' ' + patientAppt.startTime)
    ),
    (it) => `${it.date} ${it.startTime}`
  );

  const completedAppointments = patientAppointments
    .filter((patientAppt) => moment(new Date()) >= moment(patientAppt.date + ' ' + patientAppt.startTime))
    .sort((apptA, apptB) => moment(apptB.date + ' ' + apptB.startTime).diff(moment(apptA.date + ' ' + apptA.startTime)))
    .map((completedAppt, index, array) => {
      return {
        ...completedAppt,
        appointmentNo: array.length - index,
        month: moment(completedAppt.date).format('MMMM YYYY')
      };
    });

  const months = [...Array.from(new Set(completedAppointments.map((compAppt) => compAppt.month).values()))];

  const completedAppointmentsGroupedByMonth = months.map((month) => ({
    month,
    appointments: completedAppointments.filter((compAppt) => compAppt.month === month)
  }));

  return {
    upcomingAppointmentsTotal: upcomingAppointments.length,
    upcomingAppointments,
    completedAppointmentsTotal: completedAppointments.length,
    completedAppointments: completedAppointmentsGroupedByMonth
  };
};

export const useFetchClientsRecordCount = (token: string, filterValue: clientListFilterParameter) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist, isMentorUser } = useGetAccountPackageView();
  const [clientsCount, setClientsCount] = useState<ClientListingCount>({} as ClientListingCount);
  const [isClientsCountLoading, setIsClientsCountLoading] = useState(true);

  const fetchClientsCount = async (token: string, filterValue: clientListFilterParameter) => {
    if (!isMentorUser) {
      setIsClientsCountLoading(true);
      try {
        const qParam = `?perPage=${filterValue.perPage}&q=${filterValue.q}`;

        const clientsData =
          (isEdgeAdminView || isEdgeReceptionist) && accountId
            ? await getClientsRecordByAccountId(token, accountId, qParam)
            : await getClientsRecord(token, qParam);
        const clientsRecordData = await clientsData.json();

        const massageCountData = {
          active: clientsRecordData.recordStatusCounts.active || 0,
          closed: clientsRecordData.recordStatusCounts.closed || 0,
          waitlist: clientsRecordData.recordStatusCounts.waitlist || 0
        } as ClientListingCount;

        setClientsCount(massageCountData);
        setIsClientsCountLoading(false);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get your Clients count' });
        setIsClientsCountLoading(false);
      }
    }
  };

  useEffect(() => {
    if (token) {
      setIsClientsCountLoading(true);
      fetchClientsCount(token, filterValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isEdgeAdminView]);

  return { clientsCount, isClientsCountLoading, fetchClientsCount };
};

export const useGetClientFilterValue = () => {
  const location = useLocation();
  const queryParam: {
    q?: string;
    page?: string;
    status?: PractitionersStatus['status'];
    prospectStatus?: ProspectStatus;
  } = queryString.parse(location.search);
  const path = useParams<{ [key in keyof TabIds]: string }>();

  const savedSort = localStorage.getItem(CLIENT_LIST_SORT_INFO);
  const sortBy = savedSort ? (JSON.parse(savedSort) as SortBy) : {};
  const [filterType, setFilterType] = useState<TabIds['tabId']>((path.tabId as ClientStatus['status']) ?? 'active');
  const filterValue = {
    status: filterType,
    perPage: 10,
    page: queryParam.page || 1,
    q: queryParam.q || '',
    prospectStatus: queryParam.prospectStatus || ProspectStatus.Waitlist,
    sortByClientName: sortBy.clientName,
    sortByClientSince: sortBy.clientName || sortBy.clientSince ? sortBy.clientSince : 1
  };

  return { filterValue, filterType, setFilterType, queryParam, path };
};
