import { useEffect, useRef, useState } from 'react';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { QuestionSetStruct } from 'interfaces/firstAssessment';

import styles from './QuestionCategoryMenu.module.scss';

export interface QuestionListWithCheckBoxProps {
  selectedQuestionSet: QuestionSetStruct;
  data: QuestionSetStruct[];
  id: string;
  onAddTopic?: any;
  onSelectQuestionSet: (id: string, selectedQuestionSet: QuestionSetStruct) => void;
  selectedData: QuestionSetStruct[];
  disabled?: boolean;
  isOpenDataCategoriesLoading?: boolean;
}

const QuestionCategoryMenu = ({
  selectedQuestionSet,
  data,
  id,
  onAddTopic,
  onSelectQuestionSet,
  selectedData,
  disabled,
  isOpenDataCategoriesLoading
}: QuestionListWithCheckBoxProps) => {
  const node = useRef<HTMLDivElement>(null);
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [questionSet, setQuestionSet] = useState(selectedQuestionSet);
  const [templateList, setTemplateList] = useState(data);
  const [customInput, setCustomInput] = useState('');
  const [customAddBtn, setCustomAddBtn] = useState(false);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    openList(false);
  };

  useEffect(() => {
    const filteredTemplateList = data.filter(
      (template) =>
        !selectedData.some(
          (questionSet) =>
            (!!questionSet.id && questionSet.id === template.id) ||
            (!!questionSet.categoryId && questionSet.categoryId === template.id)
        )
    );

    setTemplateList(filteredTemplateList);
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedData]);

  const openList = (val: boolean) => {
    if (!disabled) {
      setShowCategoryList(val);
    }
  };

  const selectQuestionSet = (id: string, questionSet: QuestionSetStruct) => {
    setShowCategoryList(false);
    setQuestionSet(questionSet);
    onSelectQuestionSet(id, questionSet);
  };

  const onChangeCustomInput = (value: string) => {
    setCustomAddBtn(value.length > 2);
    setCustomInput(value);
  };

  const addNewTopic = () => {
    onAddTopic(customInput);
    setCustomInput('');
  };

  const commonQuestion = templateList.filter((i) => !i.questionSetType || i.questionSetType === 'commonQuestion');
  const dataCollection = templateList.filter((i) => i.questionSetType === 'dataCollection');

  return (
    <div ref={node} className={styles.materialSelect}>
      <div className={styles.label}>Topic</div>
      <div className={styles.valueWrapper} onClick={() => openList(!showCategoryList)}>
        {questionSet.sectionName.length === 0 ? (
          <div>Please select topic</div>
        ) : (
          <div className={styles.valueBox}>{questionSet.sectionName}</div>
        )}
        {!disabled && <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>}
      </div>

      <div className={showCategoryList ? styles.listWrapperShow : styles.listWrapperHide}>
        {isOpenDataCategoriesLoading ? (
          <div className={styles.listBox}>
            <LoadingCircle />
          </div>
        ) : (
          <>
            <div className={styles.templateLabel}>COMMON QUESTION</div>
            {commonQuestion.map((commonQuestionSet, index: number) => {
              return (
                questionSet.id !== commonQuestionSet.id && (
                  <div className={styles.listBox} key={index} onClick={() => selectQuestionSet(id, commonQuestionSet)}>
                    {commonQuestionSet.sectionName}
                  </div>
                )
              );
            })}
            {dataCollection.length > 0 && (
              <>
                <div className={styles.templateLabel}>DATA COLLECTION</div>
                {dataCollection.map((dataQuestionSet, index: number) => {
                  return (
                    questionSet.categoryId !== dataQuestionSet.categoryId && (
                      <div
                        className={styles.listBox}
                        key={index}
                        onClick={() => selectQuestionSet(id, dataQuestionSet)}
                      >
                        {dataQuestionSet.sectionName}
                      </div>
                    )
                  );
                })}
              </>
            )}
            <div className={styles.templateLabel}>CUSTOM TOPIC</div>
            {templateList
              .filter((i) => i.questionSetType === 'customTopic')
              .map((customQuestionSet, index: number) => {
                return (
                  questionSet.id !== customQuestionSet.id && (
                    <div
                      className={styles.listBox}
                      key={index}
                      onClick={() => selectQuestionSet(id, customQuestionSet)}
                    >
                      {customQuestionSet.sectionName}
                    </div>
                  )
                );
              })}
            <div className={styles.customListBox}>
              <input
                value={customInput}
                className={styles.searchInput}
                placeholder={'Add custom topic'}
                onChange={(e) => onChangeCustomInput(e.target.value)}
              />
              {customAddBtn && (
                <div className={styles.addButton} onClick={addNewTopic}>
                  + Add
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default QuestionCategoryMenu;
