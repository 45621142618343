import { useMemo } from 'react';
import styles from './WriteOffListHeader.module.scss';
import columnStyles from './WriteOffListColumn.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectFilters, selectPaging, selectSorting, setPaging, setSorting } from 'redux/invoices/writeOffInvoiceSlice';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useCurrency } from 'utils/hooks/useCurrency';
import ListHeaderItem from 'components/T23/ListHeaderItem/ListHeaderItem';
import { getColumnNameFromId } from 'components/T23/ListHeaderItem/helper';
import { SortType } from 'redux/features/utils';

interface WriteOffListHeaderProps {
  showInvoiceType?: boolean;
}

const WriteOffListHeader = ({ showInvoiceType }: WriteOffListHeaderProps) => {
  const dispatch = useAppDispatch();
  const { CURRENCY_SYMBOL } = useCurrency();

  const sorting = useAppSelector(selectSorting);
  const filters = useAppSelector(selectFilters);
  const paging = useAppSelector(selectPaging);
  const { participantType } = filters;
  const { sortBy, sortType } = sorting;

  const onClickSort = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      dispatch(setSorting({ ...sorting, sortType: sortType === SortType.ASC ? SortType.DESC : SortType.ASC }));
    } else {
      dispatch(setSorting({ ...sorting, sortBy: newSortBy, sortType: SortType.DESC }));
    }
    dispatch(setPaging({ ...paging, page: 1 }));
  };

  const writeOffInvoiceHeaders = useMemo(() => {
    return [
      {
        id: 'invoiceId',
        sortAble: true
      },
      ...(showInvoiceType
        ? [
            {
              id: 'type',
              sortAble: false,
              label: 'Payer'
            }
          ]
        : []),
      {
        id: 'client',
        label: participantType === ParticipantType.Group ? 'Name' : 'Client',
        sortAble: false
      },
      {
        id: 'createdAt',
        label: 'Generated',
        sortAble: true
      },
      {
        id: 'status',
        sortAble: false
      },
      {
        id: 'total',
        label: `${CURRENCY_SYMBOL} Total`,
        sortAble: true
      }
    ];
  }, [participantType, showInvoiceType, CURRENCY_SYMBOL]);

  return (
    <div className={styles.container}>
      {writeOffInvoiceHeaders.map((header, index) => (
        <ListHeaderItem
          key={index}
          columnId={header.id}
          className={columnStyles[header.id]}
          sortBy={sortBy}
          sortType={sortType}
          columnName={header.label || getColumnNameFromId(header.id)}
          sortAble={header.sortAble}
          onSort={onClickSort}
        />
      ))}
      <div className={columnStyles.actions} />
    </div>
  );
};

export default WriteOffListHeader;
