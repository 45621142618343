import SubTab, { SubTabOption } from 'components/v2/SubTab/SubTab';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import PatientDetailsClientDataProfiles from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsClientDataProfiles/PatientDetailsClientDataProfiles';
import { SubTabContext } from 'layouts/AuthLayoutT23/context/SubTabContextProvider';

import { useFetchClientRecordById } from 'pages/PatientDetails/hooks/GetClientRecord';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetClientDetailsTabInfo } from './constants';
import { ClientProfileEpisodeDropdown } from './components/ClientProfileEpisodeDropdown/ClientProfileEpisodeDropdown';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import styles from './ClientProfileSubTab.module.scss';

const ClientProfileSubTab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { CLIENTS } = useRoutesGenerator();
  const { isEdgePlanUser } = useGetAccountPackage();
  const isProfilePage = isEdgePlanUser ? location.pathname.split('/')[4] : location.pathname.split('/')[3];
  const { setSelectedClientProfileId } = useContext(SubTabContext);

  const clientRecordId = isProfilePage
    ? isEdgePlanUser
      ? location.pathname.split('/')[3]
      : location.pathname.split('/')[2]
    : '';

  const { clientRecordData, isClientRecordLoading } = useFetchClientRecordById(token, clientRecordId, accountId);

  const isLoading = isClientRecordLoading;

  const getFilterType: string = isProfilePage
    ? isEdgePlanUser
      ? location.pathname.split('/')[4] ?? 'profile'
      : location.pathname.split('/')[3] ?? 'profile'
    : '';

  const [filterType, setFilterType] = useState<string>(getFilterType);

  const profileClickHandler = (value: string) => {
    navigate(`${CLIENTS.BASE}/${clientRecordId}/${value}`);
  };

  useEffect(() => {
    setFilterType(getFilterType);
  }, [getFilterType]);

  useEffect(() => {
    if (!isLoading && clientRecordData?.clientProfiles && clientRecordData.clientProfiles.length > 0) {
      setSelectedClientProfileId(clientRecordData.clientProfiles[0]._id);
    }
  }, [isLoading, clientRecordData, setSelectedClientProfileId]);

  const handleChangeEpisode = () => {
    if (getFilterType === 'notes') {
      navigate(`${CLIENTS.BASE}/${clientRecordId}/notes`);
    }
  };

  const {
    profile,
    'client-data': clientData,
    'health-data': healthData,
    activity,
    episodes,
    notes,
    attachments,
    survey,
    assessments,
    checkIn,
    report,
    letters,
    appointments,
    invoices,
    assignment,
    referrers,
    medicare
  } = useGetClientDetailsTabInfo(clientRecordData);

  const { isEoCEnabled, isHealthDataEnabled } = useGetFeatureToggle();

  const getSubTabs = (): SubTabOption[] => {
    const clientProfileSubTabList: Array<SubTabOption & { isDisplay?: boolean }> = [
      {
        label: 'Profile Overview',
        value: profile.tabId,
        isDisplay: profile.isDisplay,
        href: generatePath(CLIENTS.PROFILE, {
          clientRecordId
        })
      },
      {
        label: 'Profile Information',
        value: clientData.tabId,
        isDisplay: clientData.isDisplay,
        desc: (
          <PatientDetailsClientDataProfiles
            loading={isClientRecordLoading}
            clientProfiles={clientRecordData?.clientProfiles || []}
          />
        ),
        href: generatePath(CLIENTS.CLIENT_DATA, {
          clientRecordId
        })
      },
      {
        label: 'Activity',
        value: activity.tabId,
        isDisplay: activity.isDisplay,
        href: generatePath(CLIENTS.ACTIVITY, {
          clientRecordId
        })
      },
      {
        label: 'Care Episodes',
        value: episodes.tabId,
        isDisplay: episodes.isDisplay,
        href: generatePath(CLIENTS.EPISODE, {
          clientRecordId
        })
      },
      {
        label: 'Notes',
        value: notes.tabId,
        isDisplay: notes.isDisplay,
        href: generatePath(CLIENTS.NOTES, {
          clientRecordId
        })
      },
      {
        label: 'Attachments',
        value: attachments.tabId,
        isDisplay: attachments.isDisplay,
        href: generatePath(CLIENTS.ATTACHMENTS, {
          clientRecordId
        })
      },
      {
        label: 'Survey Responses',
        value: survey.tabId,
        isDisplay: survey.isDisplay,
        href: generatePath(CLIENTS.SURVEY, {
          clientRecordId
        })
      },
      {
        label: 'Assessments',
        value: assessments.tabId,
        isDisplay: assessments.isDisplay,
        href: generatePath(CLIENTS.ASSESSMENTS, {
          clientRecordId
        })
      },
      {
        label: 'Check-In Insights',
        value: checkIn.tabId,
        isDisplay: checkIn.isDisplay,
        href: generatePath(CLIENTS.CHECK_IN, {
          clientRecordId
        })
      },
      {
        label: 'Reports',
        value: report.tabId,
        isDisplay: report.isDisplay,
        href: generatePath(CLIENTS.REPORT, {
          clientRecordId
        })
      },
      {
        label: 'Letters',
        value: letters.tabId,
        isDisplay: letters.isDisplay,
        href: generatePath(CLIENTS.LETTERS, {
          clientRecordId
        })
      },
      {
        label: 'Appointments',
        value: appointments.tabId,
        isDisplay: appointments.isDisplay,
        href: generatePath(CLIENTS.APPOINTMENTS, {
          clientRecordId
        })
      },
      {
        label: 'Invoices',
        value: invoices.tabId,
        isDisplay: invoices.isDisplay,
        href: generatePath(CLIENTS.INVOICES, {
          clientRecordId
        })
      },
      {
        label: 'Practitioner Assignments',
        value: assignment.tabId,
        isDisplay: assignment.isDisplay,
        href: generatePath(CLIENTS.ASSIGNMENT, {
          clientRecordId
        })
      },
      {
        label: 'Referrals',
        value: referrers.tabId,
        isDisplay: referrers.isDisplay,
        href: generatePath(CLIENTS.REFERRERS, {
          clientRecordId
        })
      },
      {
        label: 'Medicare',
        value: medicare.tabId,
        isDisplay: medicare.isDisplay,
        href: generatePath(CLIENTS.MEDICARE, {
          clientRecordId
        })
      },
      {
        label: 'Health Information',
        value: healthData.tabId,
        isDisplay: healthData.isDisplay && isHealthDataEnabled,
        desc: (
          <PatientDetailsClientDataProfiles
            loading={isClientRecordLoading}
            clientProfiles={clientRecordData?.clientProfiles || []}
          />
        ),
        href: generatePath(CLIENTS.HEALTH_DATA, {
          clientRecordId
        })
      }
    ];
    return clientProfileSubTabList
      .filter((item) => item.isDisplay)
      .map((item) => {
        return { label: item.label, value: item.value, ...(item.desc && { desc: item.desc }), href: item?.href };
      });
  };

  const profileDataTabs = (): SubTabOption[] => {
    const profileDataSubTabList: Array<SubTabOption & { isDisplay?: boolean }> = [
      {
        label: 'Profile Overview',
        value: profile.tabId,
        isDisplay: profile.isDisplay,
        href: generatePath(CLIENTS.PROFILE, {
          clientRecordId
        })
      },
      {
        label: 'Profile Information',
        value: clientData.tabId,
        isDisplay: clientData.isDisplay,
        desc: (
          <PatientDetailsClientDataProfiles
            loading={isClientRecordLoading}
            clientProfiles={clientRecordData?.clientProfiles || []}
          />
        ),
        href: generatePath(CLIENTS.CLIENT_DATA, {
          clientRecordId
        })
      },
      {
        label: 'Activity',
        value: activity.tabId,
        isDisplay: activity.isDisplay,
        href: generatePath(CLIENTS.ACTIVITY, {
          clientRecordId
        })
      },
      {
        label: 'Care Episodes',
        value: episodes.tabId,
        isDisplay: episodes.isDisplay,
        href: generatePath(CLIENTS.EPISODE, {
          clientRecordId
        })
      },
      {
        label: 'Attachments',
        value: attachments.tabId,
        isDisplay: attachments.isDisplay,
        href: generatePath(CLIENTS.ATTACHMENTS, {
          clientRecordId
        })
      },
      {
        label: 'Practitioner Assignments',
        value: assignment.tabId,
        isDisplay: assignment.isDisplay,
        href: generatePath(CLIENTS.ASSIGNMENT, {
          clientRecordId
        })
      },
      {
        label: 'Referrals',
        value: referrers.tabId,
        isDisplay: referrers.isDisplay,
        href: generatePath(CLIENTS.REFERRERS, {
          clientRecordId
        })
      },
      {
        label: 'Medicare',
        value: medicare.tabId,
        isDisplay: medicare.isDisplay,
        href: generatePath(CLIENTS.MEDICARE, {
          clientRecordId
        })
      }
    ];

    return profileDataSubTabList
      .filter((item) => item.isDisplay)
      .map((item) => {
        return { label: item.label, value: item.value, ...(item.desc && { desc: item.desc }), href: item?.href };
      });
  };

  const careEpisodeDataTabs = (): SubTabOption[] => {
    const careEpisodeSubTabList: Array<SubTabOption & { isDisplay?: boolean }> = [
      {
        label: 'Appointments',
        value: appointments.tabId,
        isDisplay: appointments.isDisplay,
        href: generatePath(CLIENTS.APPOINTMENTS, {
          clientRecordId
        })
      },
      {
        label: 'Assessments',
        value: assessments.tabId,
        isDisplay: assessments.isDisplay,
        href: generatePath(CLIENTS.ASSESSMENTS, {
          clientRecordId
        })
      },
      {
        label: 'Check-In Insights',
        value: checkIn.tabId,
        isDisplay: checkIn.isDisplay,
        href: generatePath(CLIENTS.CHECK_IN, {
          clientRecordId
        })
      },
      {
        label: 'Health Information',
        value: healthData.tabId,
        isDisplay: healthData.isDisplay && isHealthDataEnabled,
        desc: (
          <PatientDetailsClientDataProfiles
            loading={isClientRecordLoading}
            clientProfiles={clientRecordData?.clientProfiles || []}
          />
        ),
        href: generatePath(CLIENTS.HEALTH_DATA, {
          clientRecordId
        })
      },
      {
        label: 'Invoices',
        value: invoices.tabId,
        isDisplay: invoices.isDisplay,
        href: generatePath(CLIENTS.INVOICES, {
          clientRecordId
        })
      },
      {
        label: 'Letters',
        value: letters.tabId,
        isDisplay: letters.isDisplay,
        href: generatePath(CLIENTS.LETTERS, {
          clientRecordId
        })
      },
      {
        label: 'Notes',
        value: notes.tabId,
        isDisplay: notes.isDisplay,
        href: generatePath(CLIENTS.NOTES, {
          clientRecordId
        })
      },
      {
        label: 'Reports',
        value: report.tabId,
        isDisplay: report.isDisplay,
        href: generatePath(CLIENTS.REPORT, {
          clientRecordId
        })
      },
      {
        label: 'Survey Responses',
        value: survey.tabId,
        isDisplay: survey.isDisplay,
        href: generatePath(CLIENTS.SURVEY, {
          clientRecordId
        })
      }
    ];

    return careEpisodeSubTabList
      .filter((item) => item.isDisplay)
      .map((item) => {
        return { label: item.label, value: item.value, ...(item.desc && { desc: item.desc }), href: item?.href };
      });
  };

  return (
    <>
      {isEoCEnabled ? (
        <>
          <div className={styles.profileDataWrapper}>
            <div className={styles.titleLabel}>Profile Data</div>
            <SubTab options={profileDataTabs()} onChange={profileClickHandler} activeOption={filterType} />
          </div>
          <div className={styles.careEpisodeDataWrapper}>
            <div className={styles.titleLabel}>Care Episode Data</div>
            <ClientProfileEpisodeDropdown
              isClientRecordLoading={isLoading}
              clientRecordId={clientRecordId}
              onChangeEpisode={handleChangeEpisode}
            />
            <SubTab options={careEpisodeDataTabs()} onChange={profileClickHandler} activeOption={filterType} />
          </div>
        </>
      ) : (
        <SubTab options={getSubTabs()} onChange={profileClickHandler} activeOption={filterType} />
      )}
    </>
  );
};

export default ClientProfileSubTab;
