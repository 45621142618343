import { useState } from 'react';

import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

import { useFetchGeneralPractitionerById } from '../PatientDetailsReferrers/components/ReferralsMVP/components/GPDetails/components/AddEditClientGPModal/hooks/getGeneralPractitionerList';
import { useGetAccessToken } from 'utils/hooks/token';

import ClaimHistory from './components/ClaimHistory/ClaimHistory';
import ClaimSummary from './components/ClaimSummary/ClaimSummary';
import MedicareInformation from './components/MedicareInformation/MedicareInformation';

import styles from './PatientDetailsMedicare.module.scss';
import { useGetClaimSummary } from './hooks/getClaimSummary';
import moment from 'moment';
import { getMedicareProfilesFromClientRecord } from 'utils/helpers/getMedicareProfilesFromClientRecord';

interface PatientDetailsMedicareProps {
  clientRecordData: clientRecordsInterface;
}

const PatientDetailsMedicare = ({ clientRecordData }: PatientDetailsMedicareProps) => {
  const { token } = useGetAccessToken();

  const { generalPractitioner, isGeneralPractitionerLoading, fetchGeneralPractitionerById } =
    useFetchGeneralPractitionerById(token, clientRecordData.referral?.generalPractitionerId || '');

  const { clientProfile, parentMedicareProfile: parentProfile } = getMedicareProfilesFromClientRecord(clientRecordData);

  const isClientMedicareSet = !!(
    clientProfile?.medicare &&
    ((clientProfile.medicare.number && clientProfile.medicare.irn) || clientProfile.medicare.dva)
  );
  const isMedicareClient =
    isClientMedicareSet &&
    !!clientProfile.medicare?.status?.medicare &&
    [0, 9626].includes(clientProfile.medicare.status.medicare.code);
  const isDvaClient = isClientMedicareSet && clientProfile.medicare?.status?.dva?.code === 0;

  const isGPReferralProviderNumberSet = !isGeneralPractitionerLoading && !!generalPractitioner?.medicareProviderNumber;
  const isReferralDateSet = !!clientRecordData.referral?.date;

  const [summaryQuery, setSummaryQuery] = useState({
    year: moment().format('YYYY'),
    individualCodes: (clientProfile && clientProfile.medicare?.summary.mbsCodes.individual) || [],
    groupCodes: (clientProfile && clientProfile.medicare?.summary.mbsCodes.group) || []
  });
  const { individualClaimCounts, groupClaimCounts, isClaimSummaryLoading, refetchClaimSummary } = useGetClaimSummary({
    token,
    clientRecordId: clientRecordData._id,
    isClientMedicareSet,
    query: summaryQuery
  });

  const refetchGeneralPractitioner = async () => {
    if (clientRecordData.referral?.generalPractitionerId) {
      await fetchGeneralPractitionerById(clientRecordData.referral.generalPractitionerId);
    }
  };

  const handleUpdateSummarySettings = ({
    year,
    individualCodes,
    groupCodes
  }: {
    year: string;
    individualCodes: string[];
    groupCodes: string[];
  }) => {
    setSummaryQuery({ year, individualCodes, groupCodes });
  };

  return (
    <div className={styles.container}>
      {clientProfile ? (
        <>
          <div className={styles.topRow}>
            <MedicareInformation
              clientRecord={clientRecordData}
              isMedicareClient={isMedicareClient}
              isDvaClient={isDvaClient}
              isGeneralPractitionerLoading={
                !!clientRecordData.referral?.generalPractitionerId && isGeneralPractitionerLoading
              }
              isGPReferralProviderNumberSet={isGPReferralProviderNumberSet}
              isReferralDateSet={isReferralDateSet}
              fetchGeneralPractitioner={refetchGeneralPractitioner}
            />
            {isClientMedicareSet && clientProfile.medicare && (
              <ClaimSummary
                clientRecordId={clientRecordData._id}
                clientProfileId={clientProfile._id}
                summary={clientProfile.medicare.summary}
                individualClaimCounts={individualClaimCounts}
                groupClaimCounts={groupClaimCounts}
                summaryYear={summaryQuery.year}
                isClaimSummaryLoading={isClaimSummaryLoading}
                handleUpdateSummarySettings={handleUpdateSummarySettings}
              />
            )}
          </div>
          {isClientMedicareSet && (
            <ClaimHistory
              parentProfile={parentProfile}
              clientRecordId={clientRecordData._id}
              clientProfile={clientProfile}
              referral={{
                name: generalPractitioner?.name || '',
                date: clientRecordData.referral?.date || '',
                providerNumber: generalPractitioner?.medicareProviderNumber || ''
              }}
              isMedicareClient={isMedicareClient}
              isDvaClient={isDvaClient}
              isClientReferralValid={isGPReferralProviderNumberSet && isReferralDateSet}
              refetchClaimSummary={refetchClaimSummary}
            />
          )}
        </>
      ) : (
        'Selected client cannot be connected with Medicare.'
      )}
    </div>
  );
};

export default PatientDetailsMedicare;
