import bent from 'bent';
import { Fax } from 'interfaces/fax';
import { httpClient } from '../../httpClient';

const url = process.env.REACT_APP_NOTIF_SERVICE_URL as string;

export interface PostFaxPayload {
  document: { data: string; fileName: string; mimeType: string };
  sendAt: string;
  sendFrom?: string;
  sendTo: string;
}

export const postFax = ({ accountId, payload, token }: { accountId: string; payload: PostFaxPayload; token: string }) =>
  httpClient('POST', url, 202, { Authorization: `Bearer ${token}` })(`/accounts/${accountId}/faxes`, payload);

export interface ISentTo {
  faxNumber: string;
  recipientName?: string;
}

export interface IPostFaxReportPayload {
  sendAt: string;
  sendFrom: string;
  sendTo: ISentTo[];
  printPayload?: string;
}

export const postFaxReport = (accountId: string, reportId: string, payload: IPostFaxReportPayload, token: string) =>
  httpClient('POST', url!, 202, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/reports/${reportId}/faxes`,
    payload
  );

export const postFaxLetter = (accountId: string, letterId: string, payload: IPostFaxReportPayload, token: string) =>
  httpClient('POST', url!, 202, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letters/${letterId}/faxes`,
    payload
  );

export type FaxDetails = Fax & { file: string };

export const getFaxDetails = ({ accountId, faxId, token }: { accountId: string; faxId: string; token: string }) =>
  bent<FaxDetails>('GET', 'json', url, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/faxes/${faxId}`
  );
