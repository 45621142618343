import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';
import { clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';

export const notificationSettingApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationSetting: builder.query<NotificationSettingsInterface, { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/accounts/${accountId}/notification-settings`
      })
    })
  })
});

export const { useGetNotificationSettingQuery } = notificationSettingApiSlice;
