import { AppointmentType } from './AppointmentType';
import { Translation } from 'i18n/types';

export interface TimeSlot {
  startTime: string;
  endTime: string;
  startDateTime: Date;
  endDateTime: Date;
  date?: string;
}

export enum NoAvailabilityReason {
  CannotFitGap = 'cannotFitGap',
  ClinicianBusy = 'clinicianBusy',
  GroupBusy = 'groupBusy',
  IsPublicHoliday = 'isPublicHoliday',
  MaxForwardAvailability = 'maxForwardAvailability',
  MaxSessionReached = 'maxSessionReached',
  MinTimeBufferBeforeBooking = 'minTimeBufferBeforeBooking',
  NotInAppointmentSchedule = 'notInAppointmentTypeSchedule',
  NotInClinicianSchedule = 'notInClinicianSchedule',
  NotInRoomSchedule = 'notInRoomSchedule',
  RoomBusy = 'roomBusy',
  TimeHasPassed = 'timeHasPassed'
}

export type AvailabilityTimeSlot =
  | ({ isAvailable: true } & TimeSlot)
  | ({ isAvailable: false; noAvailabilityReason: NoAvailabilityReason } & TimeSlot);

export type AvailabilityTimeSlotsByDate = Record<
  string,
  | { isAvailable: true; timeSlots: AvailabilityTimeSlot[] }
  | { isAvailable: false; noAvailabilityReason: NoAvailabilityReason }
>;

export interface AppointmentTypeAvailabilities {
  appointmentType: AppointmentType;
  timeSlots: AvailabilityTimeSlotsByDate;
}

export interface SelectedAppointmentSlotProps {
  date: string;
  endDate?: string;
  startTime: string;
  endTime: string;
  startDateTime?: Date;
  endDateTime?: Date;
}

export const noAvailabilityReasonTexts: Record<NoAvailabilityReason, Translation> = {
  [NoAvailabilityReason.CannotFitGap]: 'calendar.no_availability_reason.cannot_fit_gap',
  [NoAvailabilityReason.ClinicianBusy]: 'calendar.no_availability_reason.clinicion_busy',
  [NoAvailabilityReason.GroupBusy]: 'calendar.no_availability_reason.group_busy',
  [NoAvailabilityReason.IsPublicHoliday]: 'calendar.no_availability_reason.is_public_holiday',
  [NoAvailabilityReason.MaxForwardAvailability]: 'calendar.no_availability_reason.max_forward_availability',
  [NoAvailabilityReason.MaxSessionReached]: 'calendar.no_availability_reason.max_session_reached',
  [NoAvailabilityReason.MinTimeBufferBeforeBooking]: 'calendar.no_availability_reason.min_time_buffer_before_booking',
  [NoAvailabilityReason.NotInAppointmentSchedule]: 'calendar.no_availability_reason.not_in_appointment_schedule',
  [NoAvailabilityReason.NotInClinicianSchedule]: 'calendar.no_availability_reason.not_in_clinician_schedule',
  [NoAvailabilityReason.NotInRoomSchedule]: 'calendar.no_availability_reason.not_in_room_schedule',
  [NoAvailabilityReason.RoomBusy]: 'calendar.no_availability_reason.room_busy',
  [NoAvailabilityReason.TimeHasPassed]: 'calendar.no_availability_reason.time_has_passed'
};
