export interface ClaimFormData {
  clinicianId: string;
  minorId: string;
  providerNumber: string;
  duration: string;
  format: string;
  mode: string;
  mbsCode: string;
  isPracticePayee: boolean;
}

export type ClaimFormErrors = Record<keyof ClaimFormData, string>;

export const defaultClaimFormData = {
  clinicianId: '',
  minorId: '',
  providerNumber: '',
  duration: '',
  format: '',
  mode: '',
  mbsCode: '',
  isPracticePayee: true
};

export const defaultClaimFormErrors = {
  clinicianId: '',
  minorId: '',
  providerNumber: '',
  duration: '',
  format: '',
  mode: '',
  mbsCode: '',
  isPracticePayee: ''
};
