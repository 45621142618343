import Checkbox from 'components/v2/Checkbox/Checkbox';
import Select from 'components/Select/CommonSelect/Select';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import styles from './BonaFides.module.scss';
import { HelmControlInterface } from 'interfaces/Profile/Profile';
import { SelectInterface } from 'interfaces/caseNote';
import { FIT_LANGUAGE_LIST_LABELS, SHLanguageList } from '../../interfaces/fitFilter';
import { Languages } from '../../Languages';

const standardLanguages: SelectInterface[] = Object.keys(Languages).map((key) => ({
  value: key,
  label: Languages[key as keyof typeof Languages]
}));

const shLanguages: SelectInterface[] = Object.keys(SHLanguageList)
  .map((key) => {
    if (key !== SHLanguageList.zh) {
      return {
        value: key,
        label: FIT_LANGUAGE_LIST_LABELS[key as keyof typeof SHLanguageList]
      };
    }
    return { value: key, label: 'Chinese (Mandarin)' };
  })
  .sort((a, b) => a.label.localeCompare(b.label));

interface BonaFidesProps {
  bonaFides: HelmControlInterface['bonaFides'];
  toggleBonafides: (
    key: 'languages' | 'style' | 'yearsOfExperience' | 'qualifications' | 'medicare',
    active: boolean
  ) => void;
  setBonafides: (key: 'languages' | 'style' | 'yearsOfExperience' | 'qualifications', value?: unknown) => void;
}

const BonaFides = ({ bonaFides, toggleBonafides, setBonafides }: BonaFidesProps) => {
  const {
    medicareRebateFeatureToggle,
    isRechargeWellnessFeatureToggle,
    isSelectPsychologyFeatureToggle,
    isSomeoneHealthFeatureToggle
  } = useGetFeatureToggle();

  const languages = isSomeoneHealthFeatureToggle ? shLanguages : standardLanguages;

  return (
    <div>
      <div className={styles.title}>Set up your credentials</div>
      <div className={styles.worksDetail}>
        {!isSomeoneHealthFeatureToggle && !isRechargeWellnessFeatureToggle && !isSelectPsychologyFeatureToggle && (
          <div className={styles.row}>
            <Checkbox
              label=""
              checked={bonaFides?.style?.active || false}
              onChange={(e) => toggleBonafides('style', e)}
            />
            <div className={styles.checkboxDetail}>
              <div className={styles.col1}>Style</div>
              <div className={styles.col2}>
                <MaterialInput
                  id={'information'}
                  containerClassName={styles.inputContainer}
                  label="Information"
                  labelClassName={styles.label}
                  name="bonaFides.style.value"
                  required
                  value={bonaFides?.style?.value}
                  className={styles.helmInput}
                  onChange={(e) => setBonafides('style', e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.checkBoxRow}>
          <Checkbox
            label=""
            checked={bonaFides?.languages?.active || false}
            onChange={(e) => toggleBonafides('languages', e)}
          />
          <div className={styles.checkboxDetail}>
            <div className={styles.col1}>Languages</div>
            <div className={styles.col2}>
              <div className={styles.label}>Languages</div>
              <Select
                styles={{
                  container: (base) => ({ ...base, width: '100%' }),
                  control: (controlBase) => ({
                    ...controlBase,
                    minHeight: '30px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderBottom: `1px solid ${styles.greyColor}`,
                    borderRadius: 0,
                    boxShadow: 'none'
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: styles.blueColor,
                    borderRadius: 20,
                    padding: 2,
                    color: 'white',
                    fontSize: '12px'
                  }),
                  multiValueRemove: (base) => ({
                    ...base,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: styles.removeColor
                    }
                  })
                }}
                options={languages?.filter((language) => !bonaFides?.languages?.value?.includes(language.value))}
                placeholder="Search here"
                closeMenuOnSelect={false}
                value={languages?.filter((language) => bonaFides?.languages?.value?.includes(language.value)) as any}
                isMulti
                onChange={(languages: any) =>
                  setBonafides(
                    'languages',
                    (languages || []).map((language: any) => language.value)
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <Checkbox
            label=""
            checked={bonaFides?.yearsOfExperience?.active || false}
            onChange={(e) => toggleBonafides('yearsOfExperience', e)}
          />
          <div className={styles.checkboxDetail}>
            <div className={styles.col1}>Year of</div>
            <div className={styles.col2}>
              <MaterialInput
                id={'workingExperience'}
                containerClassName={styles.inputContainer}
                label="Working Experience"
                labelClassName={styles.label}
                name="bonaFides.yearsOfExperience.value"
                required
                value={bonaFides?.yearsOfExperience?.value}
                className={styles.helmInput}
                onChange={(e) => setBonafides('yearsOfExperience', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.checkBoxRow}>
          <Checkbox
            label=""
            checked={bonaFides?.qualifications?.active || false}
            onChange={(e) => toggleBonafides('qualifications', e)}
          />
          <div className={styles.checkboxDetail}>
            <div className={styles.col1}>Qualifications</div>
            <div className={styles.col2}>
              <div className={styles.label}>Information (Press enter to create)</div>
              <Select
                styles={{
                  container: (base) => ({ ...base, width: '100%' }),
                  control: (controlBase) => ({
                    ...controlBase,
                    minHeight: '30px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderBottom: `1px solid ${styles.greyColor}`,
                    borderRadius: 0,
                    boxShadow: 'none'
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: styles.blueColor,
                    borderRadius: 20,
                    padding: 2,
                    color: 'white',
                    fontSize: '12px'
                  }),
                  multiValueRemove: (base) => ({
                    ...base,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: styles.removeColor
                    }
                  }),
                  menu: () => ({
                    display: 'none !important'
                  }),
                  indicatorsContainer: () => ({
                    display: 'none !important'
                  })
                }}
                placeholder="Type in your qualifications"
                closeMenuOnSelect={false}
                value={
                  bonaFides?.qualifications?.value?.map((item) => ({
                    value: item,
                    label: item
                  })) as any
                }
                isMulti
                onChange={(items: any) =>
                  setBonafides(
                    'qualifications',
                    (items || []).map((i: any) => i.value)
                  )
                }
              />
            </div>
          </div>
        </div>
        {medicareRebateFeatureToggle && (
          <div className={styles.row}>
            <Checkbox
              label=""
              checked={bonaFides?.medicare?.active || false}
              onChange={(e) => toggleBonafides('medicare', e)}
            />
            <div className={styles.checkboxDetail}>
              <div className={styles.col1}>Medicare</div>
              <div className={styles.col2} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BonaFides;
