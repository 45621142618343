import { notification } from 'antd';
import classNames from 'classnames';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { Location, LocationType } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useEffect, useRef, useState } from 'react';
import { handleCopyContent } from 'utils/CopyFeature';
import { useGetAccessToken } from 'utils/hooks/token';
import { setDefaultLocation } from 'utils/http/BillingService/Invoice/claimingcom';

import ConfirmActivationModal from './components/ConfirmActivationModal/ConfirmActivationModal';
import EditLocationModal from './components/EditLocationModal/EditLocationModal';
import styles from './LocationCard.module.scss';

const locationTypeLabels = {
  [LocationType.CommunityHealthCentre]: 'Community Health Centre',
  [LocationType.HomeVisit]: 'Home Visit',
  [LocationType.Hospital]: 'Hospital',
  [LocationType.ResidentialCareFacility]: 'Residential Care Facility',
  [LocationType.Rooms]: 'Rooms'
};

interface LocationCardProps {
  location: Location;
  refetchMedicareLocations: () => void;
}

const LocationCard = ({ location, refetchMedicareLocations }: LocationCardProps) => {
  const { token } = useGetAccessToken();
  const menuNode = useRef<HTMLDivElement>(null);
  const [expandMenu, setExpandMenu] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showEditLocationModal, setShowEditLocationModal] = useState(false);
  const [showConfirmActivationModal, setShowConfirmActivationModal] = useState(false);

  const { minorId, default: isDefault, name: locationName, type, providerNumber, address, active } = location;

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target)) {
      return;
    }
    setExpandMenu(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleSetDefault = async () => {
    if (isDefault || isUpdating || !active) {
      return;
    }
    setIsUpdating(true);
    try {
      await setDefaultLocation(token, minorId, true);
      await refetchMedicareLocations();

      notification.success({
        message: 'Default location updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to set this location as default' });
    }
    setIsUpdating(false);
  };

  const handleClickEdit = () => {
    setShowEditLocationModal(true);
    setExpandMenu(false);
  };

  const handleClickChangeActive = () => {
    setShowConfirmActivationModal(true);
    setExpandMenu(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{`${locationName}${!active ? ' (Deactivated)' : ''}`}</div>
        <div className={styles.actionContainer}>
          <div className={styles.iconsContainer}>
            {active && (
              <i
                className={classNames(
                  'material-icons',
                  styles.icon,
                  isDefault ? styles.activeDefault : styles.inactiveDefault,
                  isUpdating && styles.spin
                )}
                onClick={handleSetDefault}
              >
                star
              </i>
            )}
            <ButtonAlt variant={'text'} size={'small'} icon={'more_vert'} onClick={() => setExpandMenu(true)} fab />
          </div>
          <div className={styles.menuContainer} ref={menuNode}>
            <div className={expandMenu ? styles.showMenu : styles.hideMenu}>
              {active && (
                <div className={styles.item} onClick={handleClickEdit}>
                  <i className={`material-icons ${styles.itemIcon}`}>edit</i>
                  <div className={styles.label}>Edit</div>
                </div>
              )}
              <div className={styles.item} onClick={handleClickChangeActive}>
                <i className={`material-icons ${styles.itemIcon}`}>{active ? 'location_off' : 'location_on'}</i>
                <div className={styles.label}>{active ? 'Deactivate' : 'Reactivate'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.labelContainer}>
          <div className={styles.label}>
            <div>Location ID </div>
            <div>:</div>
          </div>
          <div className={styles.label}>
            <div>Address </div>
            <div>:</div>
          </div>
          {type && (
            <div className={styles.label}>
              <div>Type </div>
              <div>:</div>
            </div>
          )}
          {type === LocationType.Hospital && (
            <div className={styles.label}>
              <div>Provider Number </div>
              <div>:</div>
            </div>
          )}
        </div>
        <div className={styles.valueContainer}>
          <div className={styles.valueRowContainer}>
            <div className={styles.locationIdContainer}>
              <div className={styles.locationId} onClick={() => handleCopyContent(minorId)}>
                {minorId}
              </div>
              <ButtonAlt
                variant={'text'}
                size={'small'}
                icon={'content_copy'}
                onClick={() => handleCopyContent(minorId)}
                fab
              />
            </div>
          </div>
          <div className={styles.address}>{address || '-'}</div>
          {type && <div className={styles.type}>{locationTypeLabels[type] || '-'}</div>}
          {type === LocationType.Hospital && <div className={styles.providerNumber}>{providerNumber || '-'}</div>}
        </div>
      </div>
      <EditLocationModal
        visible={showEditLocationModal}
        location={location}
        setShowEditLocationModal={setShowEditLocationModal}
        refetchMedicareLocations={refetchMedicareLocations}
      />
      <ConfirmActivationModal
        visible={showConfirmActivationModal}
        location={location}
        setShowConfirmActivationModal={setShowConfirmActivationModal}
        refetchMedicareLocations={refetchMedicareLocations}
      />
    </div>
  );
};

export default LocationCard;
