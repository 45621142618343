import { Popover } from 'antd';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import ProfileBadge from 'components/v2/ProfileBadge/ProfileBadge';
import { indexOf } from 'lodash';
import moment from 'moment';
import { AppointmentSlots, Attendee } from 'interfaces/Schedule/Appointment';
import { useFetchProfileById } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import styles from './BespokeDetails.module.scss';
import CancelPopover from '../CancelPopover/CancelPopover';
import MarkStatus from '../MarkStatus/MarkStatus';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useAppSelector } from 'redux/hooks';
import { practiceInfo } from 'redux/slice/practiceDataSlice';

type BespokeDetailsProps = {
  appointment: AppointmentSlots;
  onFinishMarkStatus(): void;
  onCancelAppointmentComplete: () => void;
  token: string;
};

const BespokeDetails = ({
  appointment,
  onFinishMarkStatus,
  onCancelAppointmentComplete,
  token
}: BespokeDetailsProps) => {
  const { profile, isProfileLoading } = useFetchProfileById(appointment.clinicianId!);
  const { data: practiceData } = useAppSelector(practiceInfo);

  const attendees = appointment.attendees || [
    {
      ...appointment?.clientRecord?.clientProfiles[0],
      clientRecordId: appointment.clientRecord?._id,
      clientProfileId: appointment?.clientRecord?.clientProfiles[0]._id
    } as Attendee
  ];

  return appointment.clinicianId && isProfileLoading ? (
    <div className={styles.loading}>
      <LoadingCircle />
    </div>
  ) : (
    <div className={styles.container}>
      <div>
        {appointment.clinicianId
          ? profile && profile?.name && <ProfileBadge avatar={profile?.avatar} name={profile.name} />
          : practiceData?.name && <ProfileBadge avatar={practiceData?.logo} name={practiceData.name} />}
      </div>
      <div style={{ display: 'flex' }}>
        <div
          className={styles.row}
          style={{ width: '50%', alignItems: appointment.recurrings ? 'baseline' : 'center' }}
        >
          <div className={styles.iconContainer} style={{}}>
            <span className="material-icons-outlined">edit_calendar</span>
          </div>
          <div>
            <div className={styles.content}>{moment(appointment.date, 'YYYY-MM-DD').format('dddd, Do MMMM')}</div>
            {appointment.recurrings && (
              <div className={styles.content}>
                <span className="material-icons-outlined">repeat</span>
                <span>
                  {indexOf(
                    appointment.recurrings.map(({ date }) => date),
                    appointment.date
                  ) + 1}{' '}
                  of {appointment.recurrings.length}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.row} style={{ width: '50%' }}>
          <div className={styles.iconContainer}>
            <span className="material-icons-outlined">schedule</span>
          </div>
          <div className={styles.content}>
            {moment(appointment.startTime, 'HH:mm').format('hh:mm')}
            {' - '}
            {moment(appointment.endTime, 'HH:mm').format('hh:mm A')}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.iconContainer}>
          <span className="material-icons-outlined">edit_location</span>
        </div>
        <div className={styles.content}>{appointment.faceToFaceLocation}</div>
      </div>
      {appointment.room && (
        <div className={styles.row}>
          <div className={styles.iconContainer}>
            <span className="material-icons-outlined">meeting_room</span>
          </div>
          <div className={styles.content}>{appointment.room.roomName}</div>
        </div>
      )}
      <div className={styles.row} style={{ alignItems: 'baseline' }}>
        <div className={styles.iconContainer}>
          <span className="material-icons-outlined">people</span>
        </div>
        <div className={styles.attendeesContainer}>
          {attendees.map((attendee) => {
            if (attendee.clinicianId || attendee.clientProfileId) {
              return <ProfileBadge {...attendee} />;
            }
            return (
              <div className={styles.content}>
                <div className={styles.emailIconContainer}>
                  <span className="material-icons-outlined">mail</span>
                </div>
                <div>{attendee.email}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.iconContainer}>
          <span className="material-icons-outlined">segment</span>
        </div>
        <div className={styles.content}>{appointment.notes}</div>
      </div>
      <div>
        <div className={styles.content}>
          <MarkStatus appointment={appointment} token={token} onFinishMarkStatus={onFinishMarkStatus} />
        </div>
      </div>
      <div className={styles.row} style={{ justifyContent: 'flex-end' }}>
        <Popover
          title="Are you sure to cancel this appointment?"
          content={() => (
            <CancelPopover appointment={appointment} onFinishMarkStatus={onCancelAppointmentComplete} token={token} />
          )}
          trigger="click"
        >
          <ButtonAlt variant={'outlined'}>Cancel</ButtonAlt>
        </Popover>
      </div>
    </div>
  );
};

export default BespokeDetails;
