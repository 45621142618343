import styles from './SessionSettings.module.scss';
import { AppointmentType } from 'interfaces/Schedule/AppointmentType';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import { config } from 'config/config';

type SessionSettingsProps = {
  appointmentType: AppointmentType;
  isActivity: boolean;
};

const SessionSettings = ({ appointmentType, isActivity }: SessionSettingsProps) => {
  const { isEdgeUser } = useGetAccountPackageView();
  const { isAppointmentRateReadDenied } = useGetPermissionToggle();

  const showRate = () => {
    const { rate } = appointmentType;
    return rate?.hidePublicRate || isAppointmentRateReadDenied ? 'Private' : rate?.amount.toFixed(2);
  };

  return (
    <div className={styles.container}>
      <div className={styles.symbolInfo}>
        <span className="material-icons-outlined">{config.moneyIcon}</span>
        <div className={styles.value}>{showRate()}</div>
      </div>
      <div className={styles.symbolInfo}>
        <i className="material-icons-outlined">schedule</i>
        <div className={styles.value}>
          {appointmentType.duration.minutes === 1440 ? 'Full day' : `${appointmentType.duration.minutes} minutes`}
        </div>
      </div>
      {isEdgeUser && !isActivity && (
        <div className={styles.symbolInfo}>
          <i className="material-icons-outlined">meeting_room</i>
          <div className={styles.value}>
            {appointmentType.roomSettings?.required || appointmentType.roomAny ? 'Yes' : 'No'}
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionSettings;
