import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row, Col, Menu, Dropdown, Skeleton, notification } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { map, groupBy } from 'lodash';
import moment from 'moment';
import InfiniteScroller from 'react-infinite-scroller';

import { getPatientActivityFeed } from 'utils/http/CheckInService/ClientActivity/activityFeed';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import MonthActivityFeed from './components/MonthActivityFeed/MonthActivityFeed';

import styles from './ActivityFeed.module.scss';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { security } from 'utils/security';

const FILTER_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Appointments', value: 'appointment' },
  { label: 'Broadcast', value: 'broadcast message' },
  { label: 'Check-ins', value: 'check-in' },
  { label: 'Clinical Assessments', value: 'assessment' },
  { label: 'Consent', value: 'consent form' },
  { label: 'Invoices', value: 'invoice' },
  { label: 'Notes', value: 'case note' },
  { label: 'Client Updates', value: 'patient' },
  { label: 'Reports', value: 'report' },
  { label: 'Survey', value: 'onboarding' }
];

export type PatientActivityActor = 'patient' | 'clinician' | 'system';

export interface PatientActivity {
  _id: string;
  clinicianId?: string;
  patientId?: string;
  clientRecordId?: string;
  clientProfileId?: string;
  originId?: string;
  clinicianName: string;
  accountId: string;
  object:
    | 'activity'
    | 'appointment'
    | 'appointment delivery mode'
    | 'appointment change request'
    | 'user'
    | 'check-in'
    | 'profile'
    | 'patient'
    | 'assessment'
    | 'onboarding'
    | 'invoice'
    | 'report'
    | 'client profile'
    | 'note'
    | 'communication preference'
    | 'survey'
    | 'mentor'
    | 'group'
    | 'clinician'
    | 'case note'
    | 'invoice setting'
    | 'consent form'
    | 'broadcast message'
    | 'smp'
    | 'payment request'
    | 'payment card'
    | 'refund'
    | 'payment record'
    | 'claim';
  action:
    | 'attended'
    | 'shared'
    | 'booked'
    | 'signed up'
    | 'onboarded'
    | 'updated'
    | 'rescheduled'
    | 'cancelled'
    | 'initiated'
    | 'sent'
    | 'issued'
    | 'resent'
    | 'claim paid'
    | 'confirm paid'
    | 'published'
    | 'unpublished'
    | 'revoked'
    | 'closed'
    | 'activated'
    | 'generated'
    | 'changed'
    | 'approved'
    | 'rejected'
    | 'completed'
    | 'failed'
    | 'retried'
    | 'void'
    | 'submitted';
  actor: PatientActivityActor;
  description: string;
  body?: string;
  createdAt: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  deletedAt?: string;
  deletedBy?: string;
}

interface clientActivityInterface {
  data: PatientActivity[];
  count: number;
  nextCreatedAt?: string;
  nextNoteCreatedAt?: string;
}

const useListActivityFeed = (patientId: string, selectedFilter: string, refreshActivity: number) => {
  const [fetching, setFetching] = useState(false);
  const [activityFeed, setActivityFeed] = useState<PatientActivity[]>([]);
  const [hasMoreActivity, setHasMoreActivity] = useState(true);
  const [lastCreatedAt, setLastCreatedAt] = useState('');
  const [lastNoteCreatedAt, setLastNoteCreatedAt] = useState('');
  const [lastCount, setLastCount] = useState(0);

  const pageSize = 20;

  const initialFetch = async () => {
    setFetching(true);

    await fetchActivityFeed(true);

    setFetching(false);
  };

  // clumping back-to-back activities together (that happen in the same day)
  const filterDuplicatedActivity = useMemo(() => {
    return activityFeed.filter((item, index, array) => {
      return !array.some((otherItem, otherIndex) => {
        return (
          otherIndex < index &&
          otherItem.description === item.description &&
          otherItem.originId === item.originId &&
          moment(otherItem.createdAt).isSame(item.createdAt, 'day')
        );
      });
    });
  }, [activityFeed]);

  const fetchActivityFeed = useCallback(
    async (fetchNew: boolean) => {
      try {
        const token = await security.getAccessTokenSilently();

        const callGetPatientActivityByPatientId = await getPatientActivityFeed(
          token,
          patientId,
          selectedFilter,
          pageSize,
          fetchNew ? '' : lastCreatedAt,
          fetchNew ? '' : lastNoteCreatedAt
        );
        const { data, count, nextCreatedAt, nextNoteCreatedAt } =
          (await callGetPatientActivityByPatientId.json()) as clientActivityInterface;

        if (fetchNew) {
          setActivityFeed(data);
        } else {
          setActivityFeed([...activityFeed, ...data]);
        }
        setHasMoreActivity(count === pageSize);
        setLastCreatedAt(nextCreatedAt || '');
        setLastNoteCreatedAt(nextNoteCreatedAt || '');
        setLastCount(count);
      } catch (e) {
        console.error(e);
        notification.error({ message: "Some thing went wrong while trying to fetch this patient's activity" });
      }
    },
    [activityFeed, patientId, selectedFilter, lastCreatedAt, lastNoteCreatedAt]
  );

  // try to load more item so the UI can show scrollbar, and prevent forever loading
  useEffect(() => {
    const fetchAndFilterItems = async () => {
      if (filterDuplicatedActivity.length < 10 && lastCount !== 0) {
        await fetchActivityFeed(false);
      }
    };

    fetchAndFilterItems();
  }, [filterDuplicatedActivity, fetchActivityFeed, lastCount]);

  useEffect(() => {
    initialFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshActivity, selectedFilter]);

  return { fetching, activityFeed, filterDuplicatedActivity, hasMoreActivity, fetchActivityFeed };
};

interface ActivityFeedProps {
  clientRecordData: clientRecordsInterface;
  refreshActivity: number;
  profile: ProfileInterface;
}

const ActivityFeed = ({ clientRecordData, refreshActivity, profile }: ActivityFeedProps) => {
  const [selectedFilter, setSelectedFilter] = useState<string>('all');

  const { fetching, filterDuplicatedActivity, hasMoreActivity, fetchActivityFeed } = useListActivityFeed(
    clientRecordData._id,
    selectedFilter,
    refreshActivity
  );

  const infiniteScrollContainerRef = useRef<HTMLDivElement>(null);

  const handleFilterClick = (filter: string) => {
    if (filter === selectedFilter) {
      setSelectedFilter('all');
    } else {
      setSelectedFilter(filter);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>Activity Feed</div>
        <div className={styles.headerFilter}>
          <Row gutter={16}>
            <Col>Filter</Col>
            <Col>
              <Dropdown
                overlay={
                  <Menu selectedKeys={[selectedFilter]} onClick={({ key }) => handleFilterClick(key as string)}>
                    {FILTER_OPTIONS.map((option) => (
                      <Menu.Item key={option.value}>
                        <div>{option.label}</div>
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={['click']}
              >
                <div className={styles.headerFilterDropdown} onClick={(e) => e.preventDefault()}>
                  {FILTER_OPTIONS.find((option) => option.value === selectedFilter)?.label} <DownOutlined />
                </div>
              </Dropdown>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.content}>
        {fetching ? (
          <Skeleton active />
        ) : !filterDuplicatedActivity.length ? (
          <div className={styles.noActivity}>No activity recorded.</div>
        ) : (
          <div className={styles.monthGroupContainer} ref={infiniteScrollContainerRef}>
            <InfiniteScroller
              getScrollParent={() => infiniteScrollContainerRef.current}
              initialLoad={false}
              loader={
                <div className={styles.activityFeedLoading} key={0}>
                  <LoadingCircle />
                </div>
              }
              loadMore={() => fetchActivityFeed(false)}
              useWindow={false}
              hasMore={hasMoreActivity}
            >
              {map(
                groupBy(filterDuplicatedActivity, (activity) => moment(activity.createdAt).format('MMMM YYYY')),
                (items, monthString) => {
                  return (
                    <MonthActivityFeed
                      key={monthString}
                      items={items}
                      monthString={monthString}
                      clientRecordData={clientRecordData}
                      profile={profile}
                    />
                  );
                }
              )}
            </InfiniteScroller>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityFeed;
