import { Groups } from 'pages/Groups/Interfaces/Groups';
import styles from './ActiveGroupListingItem.module.scss';
import columnStyles from 'pages/Groups/GroupsList/components/GroupListContent/components/ActiveGroupListCard/GroupRowSection.module.scss';
import moment from 'moment';
import DateDescBox from 'pages/Client/components/ClientListContent/components/Components/DateDescBox/DateDescBox';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { Link } from 'react-router-dom';
import { getDayDiff } from 'utils/helpers/DateDiff';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface ActiveGroupListingItemProps {
  groupListingItem: Groups;
  searchValue: string;
}

const ActiveGroupListingItem = ({ groupListingItem, searchValue }: ActiveGroupListingItemProps) => {
  const { CARECOORDINATION } = useRoutesGenerator();
  const { isGroupsTagsToggle } = useGetFeatureToggle();

  return (
    <Link
      to={`${CARECOORDINATION.GROUPS}/${groupListingItem._id}/details${searchValue ? `?q=${searchValue}` : ''}`}
      className={styles.container}
    >
      <div className={columnStyles.groupNameSection}>
        <div className={styles.groupName}>{groupListingItem.name}</div>
      </div>
      <div className={columnStyles.createdSection}>
        <div className={styles.createdDate}>
          <DateDescBox
            title={moment(groupListingItem.createdAt).format('D MMM YYYY')}
            desc={getDayDiff(moment(), moment(groupListingItem.createdAt))}
          />
        </div>
      </div>
      {isGroupsTagsToggle && (
        <div className={columnStyles.groupTagSection}>
          <>
            {groupListingItem.tags.length > 0 ? (
              <>
                {groupListingItem.tags.map((tagObj, index) => (
                  <div className={styles.tag} key={index}>
                    {tagObj.label}
                  </div>
                ))}
              </>
            ) : (
              <div className={styles.noneText}>none</div>
            )}
          </>
        </div>
      )}
      <div className={columnStyles.groupMemberSection}>
        <div className={styles.member}>
          {groupListingItem.clientCount} member
          {groupListingItem.clientCount !== 1 && 's'}
        </div>
      </div>
      <div className={columnStyles.groupLeadSection}>
        <div className={styles.lead}>{groupListingItem.leadClinicianProfile.name}</div>
      </div>
      <div className={columnStyles.descriptionSection}>
        <div className={styles.description}> {groupListingItem.description ? groupListingItem.description : '-'}</div>
      </div>
      <div className={columnStyles.buttonSection}>
        <i className={`material-icons ${styles.icon}`}>arrow_forward</i>
      </div>
    </Link>
  );
};

export default ActiveGroupListingItem;
