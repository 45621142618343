import * as Yup from 'yup';

export const formSchema = Yup.object().shape({
  roomId: Yup.string(),
  roomName: Yup.string(),
  deliveryType: Yup.string(),
  faceToFaceLocation: Yup.string(),
  videoCallInstructions: Yup.string(),
  phoneCallInstructions: Yup.string(),
  phoneCallDialClientInstructions: Yup.string(),
  rate: Yup.string().test({
    name: 'rate',
    message: 'Please enter a valid number',
    test: (value) => {
      return !value || !isNaN(Number(value));
    }
  })
});
