import FlexBox from 'components/FlexBox/FlexBox';
import styles from './IconTag.module.scss';
import classnames from 'classnames';

interface IconTagProps {
  iconName: string;
  text: string;
  tagContainerClass?: string;
  iconClass?: string;
}

const IconTag = ({ iconName, text, tagContainerClass, iconClass }: IconTagProps) => {
  return (
    <FlexBox
      direction="row"
      alignItems="center"
      spacing={8}
      className={classnames(styles.container, tagContainerClass)}
    >
      <span>{text}</span>
      <i className={classnames(`material-icons ${styles.icon}`, iconClass)}>{iconName}</i>
    </FlexBox>
  );
};

export default IconTag;
