import { useState } from 'react';
import { useFormikContext } from 'formik';

import FormikInput from 'components/Input/FormikInput';
import SubmitButton from '../SubmitButton/SubmitButton';

import EMAIL_SENT_IMG from 'assets/images/emailSent.png';

import styles from './GetStarted.module.scss';

interface GetStartedProps {
  allowResendEmail?: boolean;
  setHideProgressBar: React.Dispatch<React.SetStateAction<boolean>>;
}

const GetStarted = ({ allowResendEmail, setHideProgressBar }: GetStartedProps) => {
  const { values, submitForm } = useFormikContext<{ email: string }>();

  const [notReceivedLink, setNotReceivedLink] = useState(false);

  const onClickNotReceivedLink = () => {
    setHideProgressBar(true);
    setNotReceivedLink(true);

    if (!allowResendEmail) {
      submitForm();
    }
  };

  const goToLogin = () => {
    window.location.href = window.location.origin + '/login';
  };

  return (
    <div className={styles.container}>
      {!notReceivedLink ? (
        <div className={styles.box}>
          <div className={styles.imgWrapper}>
            <img src={EMAIL_SENT_IMG} className={styles.img} alt={'emailSent'} />
            <div className={styles.title}>Confirmation email sent</div>
          </div>
          <div className={styles.desc}>
            <div className={styles.description}>
              <p>
                <b>You are now all set up and ready to go.</b>
              </p>
              <p>
                The final piece of securing your account is to confirm your email address. We have sent you a one-time
                verifcation link at <b>{values.email}</b>.
              </p>
              <p>Please click the verification link to activate your Tacklit account.</p>
              <p>
                If you have already verified your email,{' '}
                <span className={styles.link} onClick={() => goToLogin()}>
                  click here to continue
                </span>
                .
              </p>
            </div>
            <div className={styles.linkWrapper}>
              <span onClick={onClickNotReceivedLink}>I haven't received an email </span>{' '}
              <span className="material-icons-outlined">chevron_right</span>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.box}>
          {allowResendEmail && (
            <>
              <p>Please check and upate your email address if incorrect.</p>
              <FormikInput className="fullWidth" label="Email" name="resendEmail" noSpacing required />
              <SubmitButton style={{ display: 'flex', alignItems: 'center' }} variant="primary" type="submit">
                <span className="material-icons-outlined">send</span>&nbsp;&nbsp;&nbsp;Resend email
              </SubmitButton>
            </>
          )}
          <p>Still not landed in your inbox?</p>
          <p>Please check your ‘Junk’ or ‘Spam’ folder to ensure it didn’t end up there.</p>
          <p>
            Else please contact us to{' '}
            <a style={{ fontSize: 14, textDecoration: 'underline' }} href="mailto:contact@tacklit.com">
              report this issue
            </a>
            (contact@tacklit.com).
          </p>
        </div>
      )}
    </div>
  );
};

export default GetStarted;
