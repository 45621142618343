import { HelmControlInterface } from 'interfaces/Profile/Profile';
import styles from './FitPsychologistFilter.module.scss';
import Select from 'components/Select/CommonSelect/Select';
import { SelectInterface } from 'interfaces/caseNote';
import {
  FIT_DELIVERY_LIST_LABELS,
  FIT_LOCATION_LIST_LABELS,
  FitDeliveryList,
  FitLocationList,
  FUNDING_METHODS,
  SELECT_FIT_DELIVERY_LIST_LABELS,
  SelectFitDeliveryList,
  SERVICE_AVAILABLE_LIST,
  STYLE_LIST
} from '../../interfaces/fitFilter';
import {
  S1hSpecialisationsKeyLabelMapping,
  S1hSpecialisationOptionList,
  SelectSpecialisationOptionList,
  SelectSpecialisationsKeyLabelMapping,
  SelectSpecialisations,
  S1hSpecialisations,
  SpecialisationOptionList
} from './specialisations';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

const MAX_SPECIALISATIONS = 5;

const getDeliveryModeOptions = (isSelectPsychologyFeatureToggle: boolean) =>
  isSelectPsychologyFeatureToggle
    ? Object.values(SelectFitDeliveryList).map(
        (value) =>
          ({
            value,
            label: SELECT_FIT_DELIVERY_LIST_LABELS[value]
          } as SelectInterface)
      )
    : Object.keys(FitDeliveryList).map(
        (key) =>
          ({
            value: key,
            label: FIT_DELIVERY_LIST_LABELS[key as keyof typeof FitDeliveryList]
          } as SelectInterface)
      );

const locationOptions = Object.values(FitLocationList).map(
  (value) =>
    ({
      value,
      label: FIT_LOCATION_LIST_LABELS[value]
    } as SelectInterface)
);

const selectStyles = {
  container: (base: any) => ({ ...base, width: '100%' }),
  valueContainer: (base: any) => ({ ...base, paddingLeft: 4 }),
  control: (controlBase: any) => ({
    ...controlBase,
    minHeight: '30px',
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${styles.greyColor}`,
    borderRadius: 0,
    boxShadow: 'none'
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: styles.blueColor,
    borderRadius: 20,
    padding: 2,
    color: 'white',
    fontSize: '12px'
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
      color: styles.removeColor
    }
  })
};

interface FitPsychologistFilterProps {
  profileValue: HelmControlInterface;
  setValues: (value: HelmControlInterface) => void;
}

const FitPsychologistFilter = ({ profileValue, setValues }: FitPsychologistFilterProps) => {
  const { isRechargeWellnessFeatureToggle, isSelectPsychologyFeatureToggle, isSomeoneHealthFeatureToggle } =
    useGetFeatureToggle();

  const setField = (
    key:
      | 'deliveryModes'
      | 'primarySpecialisations'
      | 'secondarySpecialisations'
      | 'servicesAvailable'
      | 'styles'
      | 'fundingMethods'
      | 'locations',
    value?: FitDeliveryList[] & string[]
  ) => {
    setValues({
      ...profileValue,
      [key]: value
    });
  };

  return (
    <div className={styles.section}>
      <div className={styles.checkBoxRow}>
        <div className={styles.checkboxDetail}>
          <div className={styles.col2}>
            <div className={styles.label}>Delivery Mode</div>
            <Select
              styles={selectStyles}
              options={getDeliveryModeOptions(isSelectPsychologyFeatureToggle).filter(
                (value) => !profileValue.deliveryModes?.includes(value.value as FitDeliveryList)
              )}
              placeholder="Search here"
              closeMenuOnSelect={false}
              noCreateNewOption
              value={
                getDeliveryModeOptions(isSelectPsychologyFeatureToggle).filter((value) =>
                  profileValue.deliveryModes?.includes(value.value as FitDeliveryList)
                ) as any
              }
              isMulti
              onChange={(values: any) =>
                setField(
                  'deliveryModes',
                  (values || []).map((value: any) => value.value)
                )
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.checkBoxRow}>
        <div className={styles.checkboxDetail}>
          <div className={styles.col2}>
            <div className={styles.label}>Select primary specialisations</div>
            <Select
              styles={selectStyles}
              options={(isSelectPsychologyFeatureToggle
                ? SelectSpecialisationOptionList
                : S1hSpecialisationOptionList
              ).filter(
                (value) =>
                  ![
                    ...(profileValue.primarySpecialisations || []),
                    ...(profileValue.secondarySpecialisations || [])
                  ].includes(value.value)
              )}
              placeholder="Search here"
              closeMenuOnSelect={false}
              value={
                profileValue.primarySpecialisations?.map((obj) => ({
                  label: isSelectPsychologyFeatureToggle
                    ? SelectSpecialisationsKeyLabelMapping[obj as SelectSpecialisations]
                    : S1hSpecialisationsKeyLabelMapping[obj as S1hSpecialisations],
                  value: obj
                })) as any
              }
              isMulti
              onChange={(values: any) => {
                setField(
                  'primarySpecialisations',
                  (values || []).map((value: any) => value.value)
                );
              }}
              maxOption={
                isRechargeWellnessFeatureToggle || isSelectPsychologyFeatureToggle ? undefined : MAX_SPECIALISATIONS
              }
              noCreateNewOption
            />
          </div>
        </div>
      </div>
      <div className={styles.checkBoxRow}>
        <div className={styles.checkboxDetail}>
          <div className={styles.col2}>
            <div className={styles.label}>Select secondary specialisations</div>
            <Select
              styles={selectStyles}
              options={(isSelectPsychologyFeatureToggle
                ? SelectSpecialisationOptionList
                : isSomeoneHealthFeatureToggle
                ? S1hSpecialisationOptionList
                : SpecialisationOptionList
              ).filter(
                (value) =>
                  ![
                    ...(profileValue.primarySpecialisations || []),
                    ...(profileValue.secondarySpecialisations || [])
                  ].includes(value.value)
              )}
              placeholder="Search here"
              closeMenuOnSelect={false}
              value={
                profileValue.secondarySpecialisations?.map((obj) => ({
                  label: isSelectPsychologyFeatureToggle
                    ? SelectSpecialisationsKeyLabelMapping[obj as SelectSpecialisations]
                    : S1hSpecialisationsKeyLabelMapping[obj as S1hSpecialisations],
                  value: obj
                })) as any
              }
              isMulti
              onChange={(values: any) => {
                setField(
                  'secondarySpecialisations',
                  (values || []).map((value: any) => value.value)
                );
              }}
              maxOption={
                isRechargeWellnessFeatureToggle || isSelectPsychologyFeatureToggle ? undefined : MAX_SPECIALISATIONS
              }
              noCreateNewOption
            />
          </div>
        </div>
      </div>
      {isSomeoneHealthFeatureToggle && (
        <div className={styles.checkBoxRow}>
          <div className={styles.checkboxDetail}>
            <div className={styles.col2}>
              <div className={styles.label}>Available Service</div>
              <Select
                styles={selectStyles}
                options={SERVICE_AVAILABLE_LIST?.filter(
                  (value) => !profileValue.servicesAvailable?.includes(value.value)
                )}
                placeholder="Search here"
                closeMenuOnSelect={false}
                value={
                  profileValue.servicesAvailable?.map((obj) => ({
                    label: obj,
                    value: obj
                  })) as any
                }
                isMulti
                onChange={(values: any) => {
                  setField(
                    'servicesAvailable',
                    (values || []).map((value: any) => value.value)
                  );
                }}
              />
            </div>
          </div>
        </div>
      )}
      {isSelectPsychologyFeatureToggle && (
        <div className={styles.checkBoxRow}>
          <div className={styles.checkboxDetail}>
            <div className={styles.col2}>
              <div className={styles.label}>Location</div>
              <Select
                styles={selectStyles}
                options={locationOptions?.filter(
                  (value) => !profileValue.locations?.includes(value.value as FitLocationList)
                )}
                placeholder="Search here"
                closeMenuOnSelect={false}
                value={
                  profileValue.locations?.map((obj) => ({
                    label: FIT_LOCATION_LIST_LABELS[obj],
                    value: obj
                  })) as any
                }
                isMulti
                onChange={(values: any) => {
                  setField(
                    'locations',
                    (values || []).map((value: any) => value.value)
                  );
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!isSelectPsychologyFeatureToggle && (
        <div className={styles.checkBoxRow}>
          <div className={styles.checkboxDetail}>
            <div className={styles.col2}>
              <div className={styles.label}>Psychologist Style</div>
              <Select
                styles={selectStyles}
                options={STYLE_LIST?.filter((value) => !profileValue.styles?.includes(value.value))}
                placeholder="Search here"
                closeMenuOnSelect={false}
                value={
                  profileValue.styles?.map((obj) => ({
                    label: obj,
                    value: obj
                  })) as any
                }
                isMulti
                onChange={(values: any) => {
                  setField(
                    'styles',
                    (values || []).map((value: any) => value.value)
                  );
                }}
              />
            </div>
          </div>
        </div>
      )}
      {isSomeoneHealthFeatureToggle && (
        <div className={styles.checkBoxRow}>
          <div className={styles.checkboxDetail}>
            <div className={styles.col2}>
              <div className={styles.label}>Funding Methods</div>
              <Select
                styles={selectStyles}
                options={FUNDING_METHODS?.filter((value) => !profileValue.fundingMethods?.includes(value.value))}
                placeholder="Search here"
                closeMenuOnSelect={false}
                value={
                  profileValue.fundingMethods?.map((obj) => ({
                    label: obj,
                    value: obj
                  })) as any
                }
                isMulti
                onChange={(values: any) =>
                  setField(
                    'fundingMethods',
                    (values || []).map((value: any) => value.value)
                  )
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FitPsychologistFilter;
