import { TimePicker as AntdTimePicker } from 'antd';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';
import ButtonAlt from '../ButtonAlt/ButtonAlt';
import styles from './TimePicker.module.scss';
import './TimePicker.scss';

interface TimePickerProps {
  id?: string;
  time: string;
  minuteStep?: number;
  timeFormat?: string;
  error?: boolean;
  removable?: boolean;
  onRemove?: () => void;
  onChange: (value: string) => void;
}

const TIME_FORMAT = 'hh:mm A';

const TimePicker = ({
  id,
  time,
  minuteStep = 15,
  timeFormat = TIME_FORMAT,
  error = false,
  removable = false,
  onRemove = () => {},
  onChange
}: TimePickerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<Moment>(moment(time, timeFormat));
  const onOpenChange = (open: boolean) => setOpen(open);

  const onRemoveClick = () => {
    setOpen(false);
    onRemove();
  };

  const onSelect = (newValue: Moment | null) => {
    if (newValue) {
      setValue(newValue);
      onChange(newValue.format(timeFormat));
    }
  };

  useEffect(() => {
    setValue(moment(time, timeFormat));
  }, [time, timeFormat]);
  return (
    <AntdTimePicker
      id={id}
      className={classNames('day-time-selection-time-picker', {
        [styles.error]: error
      })}
      use12Hours
      bordered={false}
      allowClear={false}
      format={timeFormat}
      minuteStep={minuteStep}
      showNow={false}
      popupClassName="day-time-selection-time-picker-popup"
      suffixIcon={<ButtonAlt size={'small'} variant={'text'} icon={'arrow_drop_down'} fab />}
      value={value}
      onOpenChange={onOpenChange}
      onSelect={onSelect}
      open={open}
      renderExtraFooter={
        removable
          ? () => (
              <button className={styles.removeButton} onClick={onRemoveClick}>
                Remove
              </button>
            )
          : undefined
      }
    />
  );
};

export default TimePicker;
