export const DEFAULT_SHARED_FOLDER_ID = 'defaultSharedFolder';

export const FOLDER_NAME = {
  SHARED: 'Shared Folder',
  PRIVATE: 'Private Folder'
} as const;

export const FOLDER_SORT_ORDER = {
  SHARED: 0,
  PRIVATE_OWN: 1,
  OTHERS: 2
} as const;

export const SHARED_FOLDER_INITIAL_CONFIG = {
  _id: DEFAULT_SHARED_FOLDER_ID,
  folderName: FOLDER_NAME.SHARED,
  isPrivate: false,
  noteCount: 0,
  sortOrder: FOLDER_SORT_ORDER.SHARED
};

export const getPrivateFolderConfig = (clinicianId: string) => ({
  _id: clinicianId,
  folderName: FOLDER_NAME.PRIVATE,
  isPrivate: true,
  noteCount: 0,
  sortOrder: FOLDER_SORT_ORDER.PRIVATE_OWN
});
