import mammoth from 'mammoth';
import { useEffect, useRef, useState } from 'react';

import styles from './DocxViewer.module.scss';
import { sanitizeHTMLString } from 'utils/sanitizeHTMLString';

interface DocxViewerProps {
  docArrayBuffer: ArrayBuffer;
}

const DocxViewer = ({ docArrayBuffer }: DocxViewerProps) => {
  const docxRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const renderDocx = async () => {
      try {
        const docxHtml = await mammoth.convertToHtml({ arrayBuffer: docArrayBuffer });

        if (docxRef.current) {
          docxRef.current.innerHTML = sanitizeHTMLString(docxHtml.value);
        }
        setErrorMessage(null);
      } catch (error) {
        console.error('Error rendering DOCX:', error);

        setErrorMessage('Failed to load the document. Please ensure it is a valid .docx file.');
      }
    };

    renderDocx();
  }, [docArrayBuffer]);

  return (
    <div>
      {errorMessage ? (
        <div className={styles.error}>{errorMessage}</div>
      ) : (
        <div ref={docxRef} className={styles.preview} />
      )}
    </div>
  );
};

export default DocxViewer;
