import { createSlice } from '@reduxjs/toolkit';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { ReferralTab, SearchFilterEnum } from 'pages/Referrals/interface';
import { getReferralDefaultFilters, REFERRAL_PER_PAGE } from 'pages/Referrals/utils';
import { Paging, SortType, Sorting } from 'redux/features/utils';
import { RootState } from 'redux/store';

export interface DateFilter {
  from: string | null;
  to: string | null;
}

export interface ReferralFilters {
  dateRange: DateFilter;
  statuses: FilterCheckListItem[];
  referrerTypes: FilterCheckListItem[];
  linkages: FilterCheckListItem[];
  packages: FilterCheckListItem[];
}

export interface ReferralSearch {
  searchValue: string;
  searchBy: SearchFilterEnum.SubjectName;
}

interface ReferralSliceState {
  search: ReferralSearch;
  filters: ReferralFilters;
  sorting: Sorting;
  paging: Paging;
  activeTab?: ReferralTab;
}

export const initialState: ReferralSliceState = {
  search: {
    searchValue: '',
    searchBy: SearchFilterEnum.SubjectName
  },
  filters: {
    dateRange: { from: null, to: null },
    statuses: [],
    referrerTypes: [],
    linkages: [],
    packages: []
  },
  sorting: {
    sortBy: 'createdAt',
    sortType: SortType.DESC
  },
  paging: {
    page: 1,
    perPage: REFERRAL_PER_PAGE
  },
  activeTab: undefined
};

export const referralSlice = createSlice({
  name: 'referralSlice',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetFilters: (state) => {
      state.filters = {
        ...initialState.filters,
        ...getReferralDefaultFilters(state.activeTab)
      };
    },

    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    setPaging: (state, action) => {
      state.paging = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    },

    resetSorting: (state) => {
      state.sorting = initialState.sorting;
    },

    resetSearch: (state) => {
      state.search = initialState.search;
    },

    resetAll: (state) => {
      state.search = initialState.search;
      state.paging = initialState.paging;
      state.sorting = initialState.sorting;

      state.filters = {
        ...initialState.filters,
        ...getReferralDefaultFilters(state.activeTab)
      };
    },

    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    }
  }
});

export const selectSearch = (state: RootState) => state.referralSlice.search;
export const selectFilters = (state: RootState) => state.referralSlice.filters;
export const selectSorting = (state: RootState) => state.referralSlice.sorting;
export const selectPaging = (state: RootState) => state.referralSlice.paging;
export const selectActiveTab = (state: RootState) => state.referralSlice.activeTab;

export const {
  setSearch,
  setFilters,
  resetFilters,
  setSorting,
  setPaging,
  resetPaging,
  resetSorting,
  resetSearch,
  resetAll,
  setActiveTab
} = referralSlice.actions;

export default referralSlice.reducer;
