import { useState } from 'react';
import styles from './ClientContactDetails.module.scss';

import {
  ClientAddress,
  clientProfilesEncrypted,
  clientRecordsEncryptedInterface
} from 'interfaces/Clients/clientsRecord';
import { handleCopyContent } from 'utils/CopyFeature';
import { ROLE_TYPE_OPTIONS } from '../../../../../../../../components/AddPatientModalV2/formType/ChildForm/components/ChildGuardianForm/ChildGuardianForm';
import QrCodeModal from '../QrCodeModal/QrCodeModal';
import { getName } from 'utils/general';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

import moment from 'moment';

interface ClientPersonalDetailsProps {
  clientProfileData: clientProfilesEncrypted;
  caseId?: clientRecordsEncryptedInterface['caseId'];
  title: string;
  clientAddress?: ClientAddress;
}

const ClientContactDetails = ({ clientProfileData, title, caseId, clientAddress }: ClientPersonalDetailsProps) => {
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const contactDetailsExist = clientProfileData.mobileNumber || clientProfileData.email || clientAddress;
  const showPreferredName = clientProfileData.profileType === 'full' && clientProfileData.name;

  const findRoleLabel = (roleVal: string) => {
    const roleLabel = ROLE_TYPE_OPTIONS.find((obj) => obj.value === roleVal);
    return roleLabel ? roleLabel.label : 'Other';
  };

  const facilitatedLink = clientProfileData.onboardingSettings
    ? clientProfileData.onboardingSettings?.facilitationUrl
    : '';

  const [t] = useTranslation();

  const getAgeBasedOnDOB = (dob: string) => {
    const parsedDob = moment(dob, ['YYYY-MM-DD', 'DD/MM/YYYY']);

    if (!parsedDob.isValid()) {
      return '';
    }

    return `(Age: ${moment().diff(parsedDob, 'years')})`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <div className={styles.pITitleWrapper}>
          <div className={styles.subTitle}>{title}</div>
        </div>
        <div className={styles.details}>
          <div className={styles.fieldLabel}>
            <div>First Name</div>
            <div>Last Name</div>
            {showPreferredName && <div>Preferred Name</div>}
            {clientProfileData.dateOfBirth && <div>Date of Birth</div>}
            {clientProfileData.role && clientProfileData.role !== 'child' && <div>Relationship</div>}
            {caseId && <div>{t('form.client_reference')} #</div>}
          </div>
          <div className={styles.verticalLine} />
          <div className={styles.fieldBox}>
            <div>{clientProfileData.firstName}</div>
            <div>{clientProfileData.lastName}</div>
            {showPreferredName && <div>{clientProfileData.name}</div>}
            {clientProfileData.dateOfBirth && (
              <div>
                {clientProfileData.dateOfBirth} {getAgeBasedOnDOB(clientProfileData.dateOfBirth)}
              </div>
            )}
            {clientProfileData.role && clientProfileData.role !== 'child' && (
              <div>{findRoleLabel(clientProfileData.role)}</div>
            )}
            {caseId && <div>{caseId}</div>}
          </div>
        </div>
      </div>
      {contactDetailsExist && (
        <div className={styles.infoWrapper}>
          <div className={styles.subTitle}>CONTACT DETAILS</div>
          <div className={styles.details}>
            <div className={styles.fieldLabel}>
              {clientProfileData.mobileNumber && <div>Mobile</div>}
              {clientProfileData.email && <div>Email</div>}
              {clientProfileData?.postcode && <div>Postcode</div>}
              {clientAddress && <div>Address</div>}
            </div>
            <div className={styles.verticalLine} />
            <div className={styles.fieldBox}>
              {clientProfileData.mobileNumber && (
                <div className={styles.fieldValue}>
                  {clientProfileData.mobileNumber}
                  <ButtonAlt
                    variant={'text'}
                    icon={'content_copy'}
                    size={'small'}
                    className={styles.icon}
                    onClick={() => handleCopyContent(clientProfileData.mobileNumber)}
                    fab
                  />
                </div>
              )}
              {clientProfileData.email && (
                <div className={styles.fieldValue}>
                  <div className={styles.widthValue}>{clientProfileData.email}</div>
                  <ButtonAlt
                    variant={'text'}
                    icon={'content_copy'}
                    size={'small'}
                    className={styles.icon}
                    onClick={() => handleCopyContent(clientProfileData.email)}
                    fab
                  />
                </div>
              )}
              {clientProfileData?.postcode && <div className={styles.fieldValue}>{clientProfileData?.postcode}</div>}
              {clientAddress && (
                <div className={styles.fieldValue}>
                  {clientAddress?.line1 && `${clientAddress.line1} `}
                  {clientAddress?.line2 && `${clientAddress.line2} `}
                  {clientAddress?.suburb && `${clientAddress.suburb} `}
                  {clientAddress?.state && `${clientAddress.state} `}
                  {clientAddress?.postcode && `${clientAddress.postcode} `}
                  {clientAddress?.country && `${clientAddress.country} `}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {facilitatedLink && (
        <div className={styles.infoWrapper}>
          <div className={styles.subTitle}>ONBOARDING</div>
          <div className={styles.details}>
            <div className={styles.fieldLabel}>{facilitatedLink && <div>Link</div>}</div>
            <div className={styles.verticalLine} />
            <div className={styles.fieldBox}>
              {facilitatedLink && (
                <div className={styles.fieldValue}>
                  <div className={styles.facUContainer} onClick={() => setQrCodeModal(true)}>
                    <i className={`material-icons ${styles.qrCodeBtn}`}>qr_code_2</i>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <QrCodeModal
        name={getName(clientProfileData)}
        facilitatedUrl={facilitatedLink}
        visibleModal={qrCodeModal}
        onCancel={() => setQrCodeModal(false)}
      />
    </div>
  );
};

export default ClientContactDetails;
