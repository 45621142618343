import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { useParams } from 'react-router-dom';

import ControlPanelContent from './components/ControlPanelContent/ControlPanelContent';
import ControlPanelHeader from './components/ControlPanelHeader/ControlPanelHeader';
import styles from './ControlPanel.module.scss';
import { useContext } from 'react';
import { UserContext } from 'utils/UserContextProvider';

const ControlPanel = () => {
  const { controlPanelTab = '' } = useParams<{ controlPanelTab?: string }>();
  const { clinicianProfile, setProfile } = useContext(UserContext);

  return (
    <HelmetWrapper title="Tacklit - Control Panel">
      <ContentLayout className={styles.container}>
        <div className={styles.container}>
          <ControlPanelHeader tab={controlPanelTab} practitionerName={clinicianProfile?.name} />
          <ControlPanelContent
            profile={clinicianProfile}
            controlPanelTab={controlPanelTab}
            onUpdateProfile={setProfile}
          />
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ControlPanel;
