import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from '../ActiveTasks/ActiveTasks.module.scss';
import {
  resetFilters,
  resetPaging,
  selectFilters,
  selectPaging,
  selectSorting,
  setFilters,
  setPaging,
  setSorting
} from 'redux/features/tasks/closedTasksSlice';
import { useGetReviewAppointmentsQuery } from 'redux/endpoints/scheduleServices/processAppointment';
import { getReviewAppointmentsRequestPayload } from '../utils';
import { SortType } from 'redux/features/utils';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import TasksFilters from '../components/TasksFilters/TasksFilters';
import TasksHeader from '../components/TasksHeader/TasksHeader';
import { Skeleton } from 'antd';
import AppointmentItem from '../components/TasksItem/Appointment/AppointmentItem';
import PaginationListV2 from 'components/v2/PaginationListV2/PaginationListV2';
import { TaskStatus } from 'interfaces/Tasks/task';

const ClosedTasks = () => {
  const dispatch = useAppDispatch();
  const paging = useAppSelector(selectPaging);
  const sorting = useAppSelector(selectSorting);
  const filters = useAppSelector(selectFilters);
  const { clients } = filters;
  const { sortBy, sortType } = sorting;

  const { data, isLoading, isFetching, isError } = useGetReviewAppointmentsQuery(
    getReviewAppointmentsRequestPayload(paging, sorting, filters, TaskStatus.Closed)
  );

  const onChangePage = (page: number) => {
    dispatch(setPaging({ ...paging, page }));
  };

  const onClickSort = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      dispatch(setSorting({ ...sorting, sortType: sortType === SortType.ASC ? SortType.DESC : SortType.ASC }));
    } else {
      dispatch(setSorting({ ...sorting, sortBy: newSortBy, sortType: SortType.DESC }));
    }
  };

  const onChangeClientFilter = (clients: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, clients }));
  };

  const onResetFilters = () => {
    dispatch(resetPaging());
    dispatch(resetFilters());
  };

  return (
    <div className={styles.container}>
      {/* Filters */}
      <div className={styles.filterWrapper}>
        <TasksFilters
          isLoading={isLoading || isFetching}
          totalItems={!isError ? data?.paging?.totalItems || 0 : 0}
          filterText="closed tasks"
          selectedClients={clients}
          onChangeClientFilter={onChangeClientFilter}
          onResetFilters={onResetFilters}
        />
      </div>

      <div className={styles.listWrapper}>
        <TasksHeader sorting={sorting} onClickSort={onClickSort} />
        {isLoading || isFetching ? (
          <div className={styles.loadingWrapper}>
            {[...Array(10)].map((_, i) => (
              <div key={i} className={styles.loadingItem}>
                <Skeleton.Input active className={styles.loadingContent} />
              </div>
            ))}
          </div>
        ) : isError || (data?.appointments && data.appointments.length < 1) ? (
          <div>No review item</div>
        ) : (
          <div className={styles.listOfTasks}>
            {data?.appointments.map((appointment) => (
              <AppointmentItem key={appointment._id} appointment={appointment} />
            ))}
          </div>
        )}
      </div>

      {/* Pagination */}
      {!isError && !isLoading && !isFetching && data && data.paging.totalItems > data.paging.perPage && (
        <div className={styles.paginationWrapper}>
          <PaginationListV2 paging={data.paging} onPageChange={onChangePage} />
        </div>
      )}
    </div>
  );
};

export default ClosedTasks;
