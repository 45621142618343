import AddPatientModalV2 from 'components/AddPatientModalV2/AddPatientModalV2';
import styles from './ReferralNewClientForm.module.scss';
import { useCallback, useMemo } from 'react';
import { newClientForm } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import { ReferralFromAPI } from '../interface';
import { defaultAdultDetails } from 'components/AddPatientModalV2/InitValue/defaultAdultDetails';
import { defaultYoungDetails } from 'components/AddPatientModalV2/InitValue/defaultYoungDetails';
import { defaultChildDetails } from 'components/AddPatientModalV2/InitValue/defaultChildDetails';
import { defaultCoupleDetails } from 'components/AddPatientModalV2/InitValue/defaultCoupleDetails';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useAppDispatch } from 'redux/hooks';
import { ReferralFieldsPayload } from '../components/AddReferralModal/AddReferralModalInterfaces';
import moment from 'moment';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';

interface ReferralNewClientFormProps {
  referral: ReferralFromAPI | ReferralFieldsPayload;
  setCloseModal: (val: boolean) => void;
  onComplete: (clientRecordId: string) => void;
}

const ReferralNewClientForm = ({ referral, setCloseModal, onComplete }: ReferralNewClientFormProps) => {
  const dispatch = useAppDispatch();

  const { referralId, detail, subject } = referral;

  const prefillProfile: Partial<newClientForm['clientProfiles'][0]> = useMemo(() => {
    return {
      firstName: subject?.firstName || '',
      lastName: subject?.lastName || '',
      email: subject?.email,
      mobileNumber: subject?.mobile,
      dateOfBirth: subject?.dateOfBirth ? moment(subject.dateOfBirth).format(MOMENTJS_DATE_FORMAT) : ''
    };
  }, [subject]);

  const prefill = useCallback(
    (recordType: newClientForm['recordType']) => {
      if (recordType === 'child') {
        delete prefillProfile.mobileNumber;
      }
      return {
        ...(detail.trim() && {
          referralDetail: detail.trim() || referralId
        }),
        ...(recordType === 'adult'
          ? {
              clientProfiles: [
                {
                  ...defaultAdultDetails.clientProfiles[0],
                  ...prefillProfile
                }
              ]
            }
          : recordType === 'youngPerson'
          ? {
              clientProfiles: [
                {
                  ...defaultYoungDetails.clientProfiles[0],
                  ...prefillProfile
                },
                defaultYoungDetails.clientProfiles[1]
              ]
            }
          : recordType === 'child'
          ? {
              clientProfiles: [
                {
                  ...defaultChildDetails.clientProfiles[0],
                  ...prefillProfile
                },
                defaultChildDetails.clientProfiles[1]
              ]
            }
          : recordType === 'couple'
          ? {
              clientProfiles: [
                {
                  ...defaultCoupleDetails.clientProfiles[0],
                  ...prefillProfile
                },
                defaultCoupleDetails.clientProfiles[1]
              ]
            }
          : {})
      };
    },
    [detail, prefillProfile, referralId]
  );

  return (
    <AddPatientModalV2
      visible
      onCancel={(isUpdated: boolean) => {
        if (isUpdated) {
          dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.ClientList]));
        }
        setCloseModal(false);
      }}
      onComplete={(clientRecord: clientRecordsInterface) => onComplete(clientRecord._id)}
      information={
        <div className={styles.infoBox}>
          <i className={`material-icons ${styles.icon}`}>info</i>
          <span>
            Profile data pre-populated from referral <b>{referralId}</b>. Please edit or add missing information.
          </span>
        </div>
      }
      initialPrefill={prefill('adult')}
      getClientPrefill={(recordType) => prefill(recordType)}
    />
  );
};

export default ReferralNewClientForm;
