import { QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';
import { isArray } from 'lodash';
import { ClientDataHistory, OdfEventType } from './ClientData.interface';
import moment from 'moment';
import {
  OpenDataFieldVariable,
  ScaleType
} from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';
import { EMOJI_MAP } from 'components/QuestionTypes/EmojiScalePreview/EmojiMap';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_WITH_DASH = 'YYYY-MM-DD';

export const CLIENT_ID_VARIABLE = 'PI001';
export const CLIENT_EMAIL_VARIABLE = 'PI005';
export const UNEDITABLE_VARIABLES = [CLIENT_EMAIL_VARIABLE];
export const IS_FIRST_OR_LAST_NAME = ['PI002', 'PI003'];
export const HIDE_FOR_CHILD = ['PI005', 'PI006', 'PI007'];
export const PHONE_NUMBER_VARIABLE = ['PI006'];

export const getClientDataSourceName = (source: OdfEventType) => {
  switch (source) {
    case OdfEventType.DataUpdated:
      return 'Direct Update';
    case OdfEventType.GeneralPractitioner:
      return 'GP Details Update';
    case OdfEventType.Medicare:
      return 'Medicare Update';
    case OdfEventType.ProfileCreated:
    case OdfEventType.FundingStream:
      return 'Profile Creation';
    case OdfEventType.ProfileUpdated:
      return 'Profile Update';
    case OdfEventType.Referral:
      return 'Referral Update';
    case OdfEventType.ReferralV2:
      return 'Referral Creation';
    case OdfEventType.SurveyResponded:
      return 'Survey Response';
    case OdfEventType.CaseNote:
      return 'Case Note';
  }
};

export const INIT_EMOJI_VALUE = {
  happy: 0,
  grateful: 0,
  loved: 0,
  respected: 0,
  thoughtful: 0,
  hopeful: 0,
  nervous: 0,
  anxious: 0,
  worried: 0,
  angry: 0,
  tired: 0,
  frustrated: 0,
  bored: 0,
  lonely: 0,
  mischievous: 0,
  strong: 0,
  flat: 0,
  upset: 0,
  unwell: 0,
  frowning: 0,
  slightly_frowning: 0,
  neutral: 0,
  slightly_smile: 0,
  smiley: 0
} as Record<string, number>;

export const getHistoryValue = (
  value: ClientDataHistory['value'],
  variableType: QuestionType,
  variableOptions: {
    label: string;
    value: string;
  }[]
) => {
  if ([QuestionType.scale, QuestionType.multipleChoice, QuestionType.multipleChoiceFreeText].includes(variableType)) {
    if (isArray(value)) {
      if (
        variableType === QuestionType.multipleChoice &&
        value.some((val) => !variableOptions.map((i) => i.value).includes(val))
      ) {
        return value.join(', ');
      } else {
        return variableOptions
          .filter((option) => value.includes(option.value))
          .map(({ label }) => label)
          .join(', ');
      }
    } else {
      return variableOptions.find((i) => i.value === String(value))?.label || value || '-';
    }
  } else if (variableType === QuestionType.date && value && moment(value).isValid()) {
    return moment(value, [DATE_FORMAT, DATE_FORMAT_WITH_DASH]).format(DATE_FORMAT);
  } else {
    return value || '-';
  }
};

export const getVariableOptions = (variable: Pick<OpenDataFieldVariable, 'type' | 'scaleType' | 'options'>) => {
  const defaultOptions =
    variable.options?.map((i) => ({
      value: i.value.toString(),
      label: i.description.toString()
    })) || [];

  if (variable.type === QuestionType.scale) {
    switch (variable.scaleType) {
      case ScaleType._1_5:
        return Array.from(Array(5).keys()).map((i) => ({
          value: (i + 1).toString(),
          label: (i + 1).toString()
        }));
      case ScaleType._1_10:
        return Array.from(Array(10).keys()).map((i) => ({
          value: (i + 1).toString(),
          label: (i + 1).toString()
        }));
      case ScaleType.EmojiReaction:
        return (
          variable.options?.flatMap((i) => [
            {
              value: `${i.key}_1`,
              label: `A little ${i.description.toString()} ${EMOJI_MAP[i.key]}`
            },
            {
              value: `${i.key}_2`,
              label: `Very ${i.description.toString()} ${EMOJI_MAP[i.key]}`
            }
          ]) || []
        );
      case ScaleType.EmojiRating:
        return (
          variable.options?.map((i) => ({
            value: i.value.toString(),
            label: `${EMOJI_MAP[i.value]}`
          })) || []
        );
      default:
        return defaultOptions;
    }
  }

  return defaultOptions;
};
