import { configInterface } from './config';
import * as constants from '../pages/Calendar/components/CalendarSettings/components/AccountLevelSettings/constants';
import UKTerms from 'pages/SignUp/components/Data/components/UKTerms';
import UKPrivacy from 'pages/SignUp/components/Data/components/UKPrivacy';

export const configUK: configInterface = {
  countryCode: 'gb',
  mobileNumberMinLength: 10,
  mobileNumberMaxLength: 20,
  moneyIcon: 'currency_pound',
  currencySymbol: '£',
  currencyCode: 'gbp',
  taxLabel: 'VAT',
  taxRateLabel: 'VAT',
  mobileCountryCode: '44',
  codeLabel: 'Sort Code',
  defaultTimezone: 'Europe/London',
  practiceSettings: {
    registrationNumberLabel: 'Company Number',
    publicHolidayOption: constants.UK_PUBLIC_HOLIDAY_CALENDAR_OPTIONS
  },
  terms: {
    tnc: <UKTerms />,
    privacy: <UKPrivacy />
  }
};
