import { useState, useEffect } from 'react';
import { CaseNote } from 'interfaces/caseNote';
import CheckBox from 'components/CheckBox/CheckBox';

interface CheckboxWrapperProps {
  field: CaseNote['fields'][number];
  labelClassName?: string;
  isDisabled?: boolean;
  onChangeFieldInput: (id: string, value: any) => void;
}

const CheckboxWrapper = ({ field, labelClassName, isDisabled, onChangeFieldInput }: CheckboxWrapperProps) => {
  const [fieldData, setFieldData] = useState({} as CaseNote['fields'][number]);

  useEffect(() => {
    setFieldData(field);
  }, [field]);

  const handleChange = (val: any) => {
    onChangeFieldInput(fieldData._id, val);
  };

  return (
    <CheckBox
      id={fieldData._id}
      value={fieldData.value === 'Yes'}
      label={fieldData.name}
      labelClassName={labelClassName}
      disabled={isDisabled}
      onChange={(event) => handleChange(event.target.checked ? 'Yes' : 'No')}
    />
  );
};

export default CheckboxWrapper;
