import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Dropdown, Menu, Skeleton } from 'antd';

import { AppointmentsStatus } from '../../interface';

import styles from './AppointmentStatus.module.scss';
import HelpOutLineWithTooltips from '../../../../components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { config } from 'config/config';

const MONEY_SYMBOL = config.currencySymbol;

export enum InvoiceAppointmentStatusFilterEnum {
  ALL = 'all',
  COMPLETED = 'completed',
  UPCOMING = 'upcoming'
}

const STATUS_FILTER_OPTIONS = [
  { label: 'All', value: InvoiceAppointmentStatusFilterEnum.ALL },
  { label: 'Completed', value: InvoiceAppointmentStatusFilterEnum.COMPLETED },
  { label: 'Upcoming', value: InvoiceAppointmentStatusFilterEnum.UPCOMING }
];

export interface AppointmentStatusFilterHandle {
  onFilterChange: (filter: InvoiceAppointmentStatusFilterEnum) => void;
}

interface AppointmentStatusProps {
  appointmentsStatus?: AppointmentsStatus;
  isAppointmentsStatusLoading: boolean;
}

const AppointmentStatus = forwardRef<AppointmentStatusFilterHandle, AppointmentStatusProps>(
  ({ appointmentsStatus, isAppointmentsStatusLoading }, ref) => {
    const [filter, setFilter] = useState<InvoiceAppointmentStatusFilterEnum>(InvoiceAppointmentStatusFilterEnum.ALL);

    const selectedStatus = useMemo(() => {
      if (appointmentsStatus) {
        return appointmentsStatus[filter];
      }
    }, [appointmentsStatus, filter]);

    useImperativeHandle(ref, () => ({
      onFilterChange: (filter: InvoiceAppointmentStatusFilterEnum) => setFilter(filter)
    }));

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>Appointments invoiced vs. outstanding</div>
            <HelpOutLineWithTooltips
              id={'appointmentHeadingInfo'}
              desc={
                'This summary shows how many confirmed appointments you have included in at least one invoice (we count each appointment uniquely).  The value is an approximate value based on the cost associated with each appointment type.'
              }
            />
          </div>
          <div className={styles.filter}>
            Show:
            <Dropdown
              overlay={
                <Menu onClick={(e) => setFilter(e.key as InvoiceAppointmentStatusFilterEnum)}>
                  {STATUS_FILTER_OPTIONS.map((option) => (
                    <Menu.Item key={option.value}>{option.label}</Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <div className={styles.dropdownText}>
                {STATUS_FILTER_OPTIONS.find((option) => option.value === filter)?.label}
                <i className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</i>
              </div>
            </Dropdown>
          </div>
        </div>
        {isAppointmentsStatusLoading ? (
          <Skeleton active />
        ) : (
          <>
            <div className={styles.statusRow}>
              <div className={styles.label}>INVOICED</div>
              <div className={styles.details}>
                <div className={styles.sessionWrapper}>
                  <span className={styles.sessionCount}>{selectedStatus ? selectedStatus.invoiced.sessions : 0}</span>{' '}
                  appointments
                </div>
                <div className={styles.costWrapper}>
                  <span className={styles.cost}>
                    {MONEY_SYMBOL}
                    {selectedStatus ? selectedStatus.invoiced.total.toFixed(2) : 0}
                  </span>{' '}
                  in value
                </div>
              </div>
            </div>
            <div className={styles.statusRow}>
              <div className={styles.label}>NOT INVOICED</div>
              <div className={styles.details}>
                <div className={styles.sessionWrapper}>
                  <span className={styles.sessionCount}>
                    {selectedStatus ? selectedStatus.notInvoiced.sessions : 0}
                  </span>{' '}
                  appointments
                </div>
                <div className={styles.costWrapper}>
                  <span className={styles.cost}>
                    {MONEY_SYMBOL}
                    {selectedStatus ? selectedStatus.notInvoiced.total.toFixed(2) : 0}
                  </span>{' '}
                  in value
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default AppointmentStatus;
