import { BentResponse } from 'bent';
import moment from 'moment';
import queryString from 'query-string';

import { httpClient } from '../httpClient';
import { OtherInstructions } from 'interfaces/Schedule/AppointmentType';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getAppointmentsByClientRecordIds = (
  accountId: string,
  from: string,
  to: string,
  token: string,
  clientRecordIds: string
) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(
    `/accounts/${accountId}/appointments?from=${from}&to=${to}&clientRecordIds=${clientRecordIds}`
  ) as Promise<BentResponse>;

export const getAppointmentsInvoicedStatus = (token: string) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })('/appointments/invoiced-status') as Promise<BentResponse>;

export const getAppointmentsInvoicedStatusByPatientId = (
  token: string,
  clientRecordId: string,
  queryParams?: { episodeId?: string; showOutsideOfEpisode?: boolean }
) => {
  const stringifiedQuery = queryParams ? `?${queryString.stringify(queryParams)}` : '';
  return httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/appointments/client-records/${clientRecordId}/invoiced-status${stringifiedQuery}`) as Promise<BentResponse>;
};

export const getPatientNextAppointments = (token: string, asAdmin: boolean, clientRecordIds: string) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/appointments/patients/next?asAdmin=${asAdmin}&clientRecordIds=${clientRecordIds}`) as Promise<BentResponse>;

export const getAppointmentRequests = (token: string, asAdmin: boolean) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/appointments/requests${asAdmin ? '?asAdmin=true' : ''}`) as Promise<BentResponse>;

export const getAppointmentAlerts = (token: string, asAdmin: boolean) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/appointments/alerts?asAdmin=${asAdmin}`) as Promise<BentResponse>;

export const deleteAppointmentAlert = (token: string, alertId: string, asAdmin: boolean) =>
  httpClient('DELETE', url!, 204, {
    Authorization: `Bearer ${token}`
  })(`/appointments/alerts/${alertId}`, { asAdmin }) as Promise<BentResponse>;

export interface RescheduleChangeTimePayload {
  appointmentTypeId: string;
  appointmentId: string;
  deliveryType?: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  startDateTime?: string;
  endDateTime?: string;
  note?: string;
  includeRecurringAppointments?: boolean;
  room?: {
    roomId: string;
    roomName: string;
  };
  faceToFaceLocation?: string;
  videoCallInstructions?: string;
  phoneCallInstructions?: string;
  phoneCallDialClientInstructions?: string;
  otherInstructions?: OtherInstructions;
}

export const submitRescheduleRequest = (payload: RescheduleChangeTimePayload, token: string) => {
  return httpClient('PUT', url!, [204, 409], { Authorization: `Bearer ${token}` })('/appointments', payload);
};

export const postAppointments = (payload: any, token: string) =>
  httpClient('POST', url!, [201, 409], { Authorization: `Bearer ${token}` })(
    '/appointments',
    payload
  ) as Promise<BentResponse>;

export const postBespokeAppointment = (payload: any, token: string) =>
  httpClient('POST', url!, [201, 409], { Authorization: `Bearer ${token}` })(
    '/appointments/bespoke',
    payload
  ) as Promise<BentResponse>;

export const postAcceptAppointmentRequest = ({
  appointmentRequestId,
  payload,
  token,
  asAdmin
}: {
  appointmentRequestId: string;
  payload: {
    slot: any;
    deliveryType: string;
    faceToFaceLocation?: string;
    videoCallInstructions?: string;
    phoneCallInstructions?: string;
    otherInstructions?: OtherInstructions;
    roomInfo?: {
      roomId: string;
      roomName: string;
    };
  };
  token: string;
  asAdmin?: boolean;
}) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/appointments/requests/${appointmentRequestId}/accept${asAdmin ? '?asAdmin=1' : ''}`,
    payload
  );

export const postAcceptAppointmentBookingRequest = (
  appointmentBookingRequestId: string,
  token: string,
  asAdmin?: boolean
) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(
    `/appointments/booking-request/${appointmentBookingRequestId}/accept${asAdmin ? '?asAdmin=1' : ''}`
  ) as Promise<BentResponse>;

export const postDeclineAppointmentBookingRequest = (
  appointmentBookingRequestId: string,
  payload: { note?: string },
  token: string,
  asAdmin?: boolean
) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(
    `/appointments/booking-request/${appointmentBookingRequestId}/decline${asAdmin ? '?asAdmin=1' : ''}`,
    payload
  ) as Promise<BentResponse>;

export const postSuggestAppointmentBookingRequest = (
  appointmentBookingRequestId: string,
  payload: {
    slot: any;
    sessionTypeId: string;
    deliveryType: string;
    faceToFaceLocation?: string;
    videoCallInstructions?: string;
    phoneCallInstructions?: string;
    phoneCallDialClientInstructions?: string;
    otherInstructions?: OtherInstructions;
    roomInfo?: {
      roomId: string;
      roomName: string;
    };
    note?: string;
  },
  token: string,
  asAdmin?: boolean
) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(
    `/appointments/booking-request/${appointmentBookingRequestId}/suggest${asAdmin ? '?asAdmin=1' : ''}`,
    payload
  ) as Promise<BentResponse>;

export const deleteAppointment = (id: string, payload: { notes?: string }, token: string) =>
  httpClient('DELETE', url!, 204, {
    Authorization: `Bearer ${token}`
  })(`/appointments/cancel/${id}`, payload);

export const postBlock = (payload: any, token: string) => {
  return httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })('/appointments/block', payload);
};

export const postBlockWholeDay = (date: string, token: string) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(`/appointments/block/day?date=${date}`);

export const deleteBlockWholeDay = (date: string, token: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(`/appointments/block/day?date=${date}`);

export const deleteAppointmentRequest = ({
  appointmentRequestId,
  payload,
  token,
  asAdmin
}: {
  appointmentRequestId: string;
  payload: { note: string };
  token: string;
  asAdmin?: boolean;
}) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/appointments/requests/${appointmentRequestId}${asAdmin ? '?asAdmin=1' : ''}`,
    payload
  );

export const updateAppointmentStatus = (token: string, appointmentId: string, status: string[]) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(`/appointments/${appointmentId}/status`, {
    status
  });

export const updateAppointmentFreeBusyToggle = (token: string, appointmentId: string, payload: { asAdmin?: boolean }) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/appointments/${appointmentId}/free-busy/toggle`,
    payload
  );

export const getClientNextAppointments = (token: string, clientRecordId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/appointments/next?today=${moment().format('YYYY-MM-DD')}`
  ) as Promise<BentResponse>;
