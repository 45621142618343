import { Skeleton } from 'antd';
import OnboardingTriageModal from 'components/OnboardingTriageModal/OnboardingTriageModal';
import { clientRecordsInterface, ClientRecordType, RecordStatus } from 'interfaces/Clients/clientsRecord';
import EventCreationModal from 'pages/Calendar/components/Modals/EventCreationModal/EventCreationModal';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import PatientInvoiceRulesModal from '../PatientDetailsContent/components/PatientDetailsInvoices/components/PatientInvoiceRulesModal/PatientInvoiceRulesModal';

import { Datepicker } from '@mobiscroll/react';
import ClientProfileAvatar, { ClientProfileAvatarProps } from 'components/ClientProfileAvatar/ClientProfileAvatar';
import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';
import { AccessRight } from 'interfaces/Clients/clinician';
import moment from 'moment/moment';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';
import AddReferralModal from 'pages/Referrals/components/AddReferralModal/AddReferralModal';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { selectIsRefreshDataDisabled } from 'redux/clients/clientDetailsSlice';
import { useGetPractitionerDetailsListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import {
  appointmentViewFilter,
  AppointmentViewType,
  resetAppointmentData,
  setAppointmentView,
  setAppointmentViewFilter,
  setOriginalDateTime,
  setSelectedClient,
  setSelectedDate,
  setSelectedPractitioner,
  setSelectedTime,
  setStartValidation
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { combineName } from 'utils/general';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useSetRecentlyViewed } from 'utils/hooks/SubTab/recentlyViewed';
import { useGetAccessToken } from 'utils/hooks/token';
import { UserContext } from 'utils/UserContextProvider';
import SendAdhocAssessmentModalV2 from '../../../Assessments/components/SendAdhocAssessmentModalV2/SendAdhocAssessmentModalV2';
import InlineBookingModal from '../../../Calendar/components/CalendarWithHighlightsT23/components/CalendarView/components/InlineBookingModal/InlineBookingModal';
import { EpisodeModal } from '../PatientDetailsContent/components/ClientDetailsEoC/components/EpisodeModal/EpisodeModal';
import SendSurveyModal from '../PatientDetailsContent/components/PatientDetailsSurveysList/components/SendSurveyModal/SendSurveyModal';
import styles from './PatientDetailsHeader.module.scss';

export interface PatientDetailsHeaderProps {
  currentTab: string;
  clientRecordData: clientRecordsInterface;
  invoiceSettingsPaymentMethodsReady: boolean;
  isInvoiceSettingsPaymentMethodsReadyLoading: boolean;
  isClientRecordLoading: boolean;
  allowCreateNewInvoice: boolean;
  notificationSettings: NotificationSettingsInterface;
  isOnboardingModalVisible: boolean;
  setIsOnboardingModalVisible: (isOnboardingModalVisible: boolean) => void;
  onDataRefresh: () => void;
}

const PatientDetailsHeader = ({
  currentTab,
  clientRecordData,
  invoiceSettingsPaymentMethodsReady,
  isInvoiceSettingsPaymentMethodsReadyLoading,
  isClientRecordLoading,
  allowCreateNewInvoice,
  notificationSettings,
  isOnboardingModalVisible,
  setIsOnboardingModalVisible,
  onDataRefresh
}: PatientDetailsHeaderProps) => {
  const navigate = useNavigate();
  const { token } = useGetAccessToken();
  const location = useLocation();
  const { accountId } = useGetAccountId();
  const { INVOICES, CLIENTS } = useRoutesGenerator();
  const { isReferralV2Enabled } = useGetFeatureToggle();
  const [isCreateApptModalVisible, setIsCreateApptModalVisible] = useState(false);
  const [isOldCreateApptModalVisible, setIsOldCreateApptModalVisible] = useState(false);
  const [isSendAssessmentModalVisible, setIsSendAssessmentModalVisible] = useState(false);
  const [isPatientInvoiceRulesModalVisible, setIsPatientInvoiceRulesModalVisible] = useState(false);
  const [isSendSurveyModalVisible, setIsSendSurveyModalVisible] = useState(false);
  const [isEpisodeModalVisible, setIsEpisodeModalVisible] = useState(false);
  const [isReferralModalVisible, setIsReferralModalVisible] = useState(false);
  const queryParam: { page?: string } = queryString.parse(location.search);
  const { clinicianProfile } = useContext(UserContext);
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView } = useGetAccountPackageView();
  const pathByParams = useParams<{ recordId: string; patientTab: string; assessmentOrReportId?: string }>();
  const clinicianProfileId = clinicianProfile ? clinicianProfile?._id : '';
  const recordId = pathByParams.recordId ?? '';
  const dispatch = useAppDispatch();

  const isRefreshDataDisabled = useAppSelector(selectIsRefreshDataDisabled);
  const selectedAppointmentViewFilter = useAppSelector(appointmentViewFilter);

  const handlePatientInvoiceRulesClose = (newInvoiceSettings: clientRecordsInterface['invoiceSummary']['settings']) => {
    clientRecordData &&
      newInvoiceSettings &&
      dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.ClientEncrypted]));
    setIsPatientInvoiceRulesModalVisible(false);
  };

  const getPath = (recordStatus: clientRecordsInterface['recordStatus']) => {
    let path = '/';
    switch (recordStatus) {
      case 'active':
        path = '';
        break;
      case 'closed':
        path = '/closed';
        break;
      case 'waitlist':
        path = '/waitlist';
        break;
      default:
        path = '';
        break;
    }

    return path;
  };

  const getRecordTypeName = (recordType: ClientRecordType) =>
    recordType === ClientRecordType.YoungPerson ? 'young person' : recordType;

  const handleBackBtn = () => {
    const qParam = queryString.stringify(queryParam);
    const path = getPath(clientRecordData.recordStatus);
    navigate(`${CLIENTS.BASE}${path}${qParam ? `?${qParam}` : ''}`);
  };

  const [t] = useTranslation();

  const canReceiveNotificationProfiles = clientRecordData.clientProfiles?.some((client) => {
    const config = {
      communicationPreference: client.communicationPreference,
      notificationSettings,
      notificationSettingsCategory: 'forms'
    };
    return (
      (canClientReceiveSms(config) && client.hasMobileNumber) || (canClientReceiveEmail(config) && client.hasEmail)
    );
  });

  const getAvatarsAndInitials = () => {
    let avatarList: ClientProfileAvatarProps[] = [];
    let nameList: string[] = [];
    const { clientProfiles } = clientRecordData;
    switch (clientRecordData.recordType) {
      case 'adult': {
        avatarList.push({
          avatarUrl: clientProfiles[0].avatar,
          initialsName: clientProfiles[0].initials
        });
        nameList.push(combineName(clientProfiles));
        break;
      }

      case 'child': {
        clientProfiles
          .filter((clientP) => clientP.role === 'child')
          .forEach((clientPr) => {
            avatarList.push({
              avatarUrl: clientPr.avatar,
              initialsName: clientPr.initials
            });
            nameList.push(combineName(clientProfiles.filter((clientPName) => clientPName.role === 'child')));
          });
        break;
      }

      case 'couple': {
        clientProfiles.forEach((clientP) => {
          avatarList.push({
            avatarUrl: clientP.avatar,
            initialsName: clientP.initials
          });
          nameList.push(clientP.firstName);
        });
        break;
      }

      case 'youngPerson': {
        avatarList.push({
          avatarUrl: clientProfiles[0].avatar,
          initialsName: clientProfiles[0].initials
        });
        nameList.push(combineName([clientProfiles[0]]));
        break;
      }

      default:
        break;
    }
    return { avatarList, nameList };
  };

  const openOldAppointmentModal = () => {
    setIsCreateApptModalVisible(false);
    setIsOldCreateApptModalVisible(true);
  };

  const { nameList } = getAvatarsAndInitials();
  const name: string = nameList.map((name) => `${name}`).join(' & ');

  const renderAvatarAndName = () => {
    const { avatarList } = getAvatarsAndInitials();
    return (
      <div className={styles.avatarAndNameContainer}>
        <div className={styles.avatarSection}>
          {avatarList.map((item, index) => (
            <ClientProfileAvatar
              key={`avatar_${index}`}
              avatarUrl={item.avatarUrl}
              initialsName={item.initialsName}
              avatarClassName={styles.avatar}
              initialClassName={styles.initials}
            />
          ))}
        </div>
        <div className={styles.nameSection}>
          <span className={styles.profileName}>{name}</span>
          {` ${getRecordTypeName(clientRecordData.recordType)} profile`}
          {clientRecordData.tacklitId && <div className={styles.tacklitId}>{clientRecordData.tacklitId}</div>}
        </div>
      </div>
    );
  };

  useSetRecentlyViewed({
    isLoading: isClientRecordLoading,
    storageName: 'client_viewed',
    id: recordId,
    clinicianProfileId,
    isEdgeAdminView,
    name,
    recordId
  });

  const needRefreshData = ['appointments', 'assessments', 'invoices', 'survey'].includes(currentTab);

  const { data: practitionerOptions } = useGetPractitionerDetailsListQuery({
    accountId,
    params: {
      status: 'active',
      withAccessRights: [AccessRight.Admin, AccessRight.User, AccessRight.Mentor]
    }
  });

  const massagePractitionerList = useMemo(() => {
    let practiceDataMassage;
    if (isEdgeUserView || isNormalUserView) {
      practiceDataMassage = {
        _id: clinicianProfile?._id || '',
        name: clinicianProfile?.name || '',
        avatar: clinicianProfile?.avatar || '',
        mobileNumber: clinicianProfile?.mobileNumber || '',
        workingSchedule: clinicianProfile?.workingSchedule,
        workTimeZone: clinicianProfile?.workTimeZone
      };
    } else {
      practiceDataMassage = {
        _id: '',
        name: clinicianProfile?.practice?.name || '',
        avatar: clinicianProfile?.practice?.logo || '',
        mobileNumber: clinicianProfile?.practice?.mobileNumber || ''
      };
    }
    return [practiceDataMassage, ...(practitionerOptions?.practitionerList || [])];
  }, [practitionerOptions, clinicianProfile, isEdgeUserView, isNormalUserView]);

  const handleNewAppointment = () => {
    // Reset all selected data
    dispatch(resetAppointmentData());
    // Set date and time data to inline form
    const selectedDate = moment().format('YYYY-MM-DD');
    const startTime = moment().format('HH:mm');
    const endTime = moment().add(50, 'minutes').format('HH:mm');

    dispatch(setSelectedDate(selectedDate));
    dispatch(setSelectedTime({ startTime, endTime }));
    dispatch(setOriginalDateTime({ date: selectedDate, startTime, endTime }));

    const getPractitionerInfo = massagePractitionerList.find((practitionerObj) =>
      clientRecordData.clinicianAuth0Ids.length > 0
        ? practitionerObj._id === clientRecordData.clinicianAuth0Ids[0]
        : practitionerObj._id === ''
    );
    dispatch(
      setSelectedPractitioner({
        _id: getPractitionerInfo?._id || '',
        name: getPractitionerInfo?.name || '',
        avatar: getPractitionerInfo?.avatar || ''
      })
    );
    dispatch(setAppointmentView(AppointmentViewType.Calendar));
    dispatch(
      setAppointmentViewFilter({
        ...selectedAppointmentViewFilter,
        times: 'all'
      })
    );
    dispatch(setStartValidation(false));
    dispatch(setSelectedClient(clientRecordData));

    setIsCreateApptModalVisible((prev) => !prev);
  };

  const menuNode = useRef<HTMLDivElement | null>(null);
  const filterDropdownNode = useRef<HTMLDivElement | null>(null);
  const inlineChildRef = useRef<Datepicker | null>(null);
  const handleClick = (e: any) => {
    if (
      menuNode.current?.contains(e.target) ||
      filterDropdownNode.current?.contains(e.target) ||
      inlineChildRef.current?._wrapper?.contains(e.target)
    ) {
      return;
    }
    setIsCreateApptModalVisible(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return (
    <>
      {!isClientRecordLoading && (
        <>
          <OnboardingTriageModal
            visible={isOnboardingModalVisible}
            onClose={() => setIsOnboardingModalVisible(false)}
            clientRecordData={clientRecordData}
          />
          <EventCreationModal
            visible={isOldCreateApptModalVisible}
            defaultClient={clientRecordData}
            onClose={() => setIsOldCreateApptModalVisible(false)}
            onCreateEditSuccess={() => {
              navigate(`?refetch`);
              setIsOldCreateApptModalVisible(false);
            }}
          />
          <div ref={menuNode}>
            {isCreateApptModalVisible && (
              <InlineBookingModal
                style={{
                  top: '106px',
                  right: '248px',
                  boxShadow: '1px 1px 21px #0000001a',
                  maxWidth: '800px',
                  width: '100%'
                }}
                onCloseModal={() => setIsCreateApptModalVisible(false)}
                onSubmitSuccess={() => {
                  navigate(`?refetch`);
                  setIsCreateApptModalVisible(false);
                }}
                onSelectOtherType={openOldAppointmentModal}
                onInlineRef={(ref) => (inlineChildRef.current = ref)}
                dropdownRef={filterDropdownNode}
                showSelectedItemOnFirstOption
              />
            )}
          </div>
          {allowCreateNewInvoice && (
            <PatientInvoiceRulesModal
              clientRecordData={clientRecordData}
              invoiceSettingsPaymentMethodsReady={invoiceSettingsPaymentMethodsReady}
              visible={isPatientInvoiceRulesModalVisible}
              onClose={handlePatientInvoiceRulesClose}
            />
          )}
        </>
      )}
      <div className={styles.container}>
        <div className={styles.leftSection}>
          <div className={styles.titleWrapper}>
            {isClientRecordLoading ? (
              <div className={styles.title}>
                <Skeleton.Button active />
              </div>
            ) : (
              <div className={styles.nameAndFreshDataWrapper}>
                {renderAvatarAndName()}
                {needRefreshData && (
                  <ButtonAlt
                    className={styles.refreshDataBtn}
                    size={'medium'}
                    variant={'text'}
                    onClick={() => onDataRefresh()}
                    icon={'refresh'}
                    disabled={isRefreshDataDisabled}
                  >
                    Refresh data
                  </ButtonAlt>
                )}
              </div>
            )}
            <div className={styles.backBtnWrapper}>
              <ButtonAlt size={'medium'} variant={'text'} onClick={() => handleBackBtn()} icon={'arrow_back_ios'}>
                {t('header.view_all_clients')}
              </ButtonAlt>
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
          {![
            'invoices',
            'notes',
            'attachments',
            'medicare',
            'assessments',
            'survey',
            'episodes',
            'referrers',
            'letters',
            'report'
          ].includes(currentTab) && (
            <div className={styles.ctaContainer}>
              <ButtonAlt
                id={'onboardingOrTriage'}
                variant={'outlined'}
                disabled={clientRecordData.recordStatus === RecordStatus.Closed}
                onClick={() =>
                  clientRecordData.recordStatus === RecordStatus.Closed || setIsOnboardingModalVisible(true)
                }
                icon={'content_paste_go'}
              >
                Onboard or Triage
              </ButtonAlt>
              <ButtonAlt
                id={'addNewAppointmentId'}
                disabled={clientRecordData.recordStatus === RecordStatus.Closed}
                onClick={handleNewAppointment}
                icon={'insert_invitation'}
              >
                Add New Appointment
              </ButtonAlt>
            </div>
          )}
          {currentTab === 'survey' && !isClientRecordLoading && (
            <>
              <SendSurveyModal
                clientRecordId={recordId}
                clientProfileList={clientRecordData.clientProfiles}
                visible={isSendSurveyModalVisible}
                onCancel={() => setIsSendSurveyModalVisible(false)}
                notificationSettings={notificationSettings}
              />
              <ButtonAlt
                id="addNewAppointmentId"
                icon={'send'}
                iconVariant={IconVariant.Filled}
                size={'large'}
                variant={'contained'}
                onClick={() =>
                  clientRecordData.recordStatus === RecordStatus.Closed || setIsSendSurveyModalVisible(true)
                }
                disabled={clientRecordData.recordStatus === RecordStatus.Closed}
              >
                Generate Survey
              </ButtonAlt>
            </>
          )}
          {currentTab === 'assessments' && canReceiveNotificationProfiles && (
            <>
              <SendAdhocAssessmentModalV2
                token={token}
                visible={isSendAssessmentModalVisible}
                onCancel={() => setIsSendAssessmentModalVisible(false)}
                selectedClientRecord={clientRecordData}
              />
              <ButtonAlt onClick={() => setIsSendAssessmentModalVisible(true)} icon={'send'}>
                Send assessment
              </ButtonAlt>
            </>
          )}
          {currentTab === 'invoices' && allowCreateNewInvoice && (
            <>
              <ButtonAlt
                className={styles.invoiceSettingsButton}
                variant={'outlined'}
                disabled={
                  isInvoiceSettingsPaymentMethodsReadyLoading || clientRecordData.recordStatus === RecordStatus.Closed
                }
                onClick={() => setIsPatientInvoiceRulesModalVisible(true)}
                icon={'settings'}
              >
                {t('button.manage_client_invoice_rules')}
              </ButtonAlt>
              <Link
                to={clientRecordData.recordStatus !== RecordStatus.Closed ? { pathname: INVOICES.NEW } : {}}
                state={
                  clientRecordData.recordStatus !== RecordStatus.Closed
                    ? { invoice: { clientRecord: { _id: clientRecordData?._id } } }
                    : {}
                }
              >
                <ButtonAlt disabled={clientRecordData.recordStatus === RecordStatus.Closed} icon={'receipt'}>
                  Create New Invoice
                </ButtonAlt>
              </Link>
            </>
          )}
          {currentTab === 'episodes' && (
            <>
              {isEpisodeModalVisible && (
                <EpisodeModal
                  visible={isEpisodeModalVisible}
                  onClose={() => setIsEpisodeModalVisible(false)}
                  clientRecord={clientRecordData}
                  mode={'create'}
                />
              )}
              <ButtonAlt icon={'add_circle_outline'} onClick={() => setIsEpisodeModalVisible(true)}>
                New Episode
              </ButtonAlt>
            </>
          )}

          {isReferralV2Enabled && currentTab === 'referrers' && (
            <>
              {isReferralModalVisible && (
                <AddReferralModal
                  visible={isReferralModalVisible}
                  onCloseModal={() => setIsReferralModalVisible(false)}
                  prefillClientRecordId={clientRecordData._id}
                />
              )}
              <ButtonAlt icon={'add_circle_outline'} onClick={() => setIsReferralModalVisible(true)}>
                Add New Referral
              </ButtonAlt>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientDetailsHeader;
