import { ReferralDataField } from 'interfaces/Referral/Referral';
import styles from './ReferralCustomFieldsTable.module.scss';

interface ReferralCustomFieldsTableProps {
  rdf: ReferralDataField[];
}

interface GroupedRDF {
  categoryId: string;
  categoryName: string;
  variables: { variableId: string; variableName: string; value?: unknown }[];
}

const ReferralCustomFieldsTable = ({ rdf }: ReferralCustomFieldsTableProps) => {
  const groupedCustomFields: GroupedRDF[] = Object.values(
    rdf.reduce((acc, curr) => {
      if (!acc[curr.categoryId]) {
        acc[curr.categoryId] = {
          categoryId: curr.categoryId,
          categoryName: curr.categoryName,
          variables: []
        };
      }
      acc[curr.categoryId].variables.push({
        variableId: curr.variableId,
        variableName: curr.variableName,
        value: curr.value
      });
      return acc;
    }, {} as { [key: string]: GroupedRDF })
  );

  return (
    <>
      {groupedCustomFields.length > 0 && (
        <div className={styles.container}>
          <div className={styles.title}>CUSTOM REFERRAL FIELDS</div>

          <div className={styles.table}>
            <div className={styles.header}>
              <div className={styles.category}>CATEGORY</div>
              <div className={styles.name}>VARIABLE NAME</div>
              <div className={styles.detail}>DETAIL</div>
            </div>
            <div className={styles.content}>
              {groupedCustomFields.map((field, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.category}>{field.categoryName}</div>
                  <div className={styles.variables}>
                    {field.variables.map(({ variableId, variableName, value }, index) => (
                      <div key={`${variableId}-${index}`} className={styles.variableRow}>
                        <div className={styles.variableName}>{variableName}</div>
                        <div className={styles.variableValue}>
                          {value && Array.isArray(value) ? value.join(', ') : String(value)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReferralCustomFieldsTable;
