import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';
import { PutLetterTemplateRequest } from 'interfaces/Letters/letterTemplate';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL!;

export const getLetterTemplate = (token: string, accountId: string, templateId: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letter-templates/${templateId}`
  ) as Promise<BentResponse>;

export const getLetterTemplateList = (token: string, accountId: string, asAdmin?: boolean) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letter-templates${asAdmin ? `?asAdmin=true` : ''}`
  ) as Promise<BentResponse>;

export const postLetterTemplate = (
  token: string,
  accountId: string,
  asAdmin: boolean,
  body: PutLetterTemplateRequest
) =>
  httpClient('POST', url, 201, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letter-templates${asAdmin ? `?asAdmin=true` : ''}`,
    body
  ) as Promise<BentResponse>;

export const putLetterTemplate = (
  token: string,
  accountId: string,
  letterTemplateId: string,
  body: PutLetterTemplateRequest
) =>
  httpClient('PUT', url, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letter-templates/${letterTemplateId}`,
    body
  ) as Promise<BentResponse>;

export const patchTogglePublishLetterTemplate = (token: string, accountId: string, letterTemplateId: string) =>
  httpClient('PATCH', url, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letter-templates/${letterTemplateId}/publish-status`
  ) as Promise<BentResponse>;

export const deleteLetterTemplate = (token: string, accountId: string, letterTemplateId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letter-templates/${letterTemplateId}`
  ) as Promise<BentResponse>;
