const AUPrivacy = () => {
  return (
    <div>
      <h1>
        <strong>Tacklit Privacy Policy - Australia</strong>
      </h1>
      <p>
        <span style={{ fontWeight: 400 }}>
          The purpose of this Privacy Policy is to provide you with a clear explanation of when, why and how we collect
          and use your personal and health information, as well as an explanation of your statutory rights. We strongly
          urge you to read this policy and make sure you fully understand it, before you access or use any of our
          services.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>Tacklit Australia Pty Ltd (</span>
        <strong>Tacklit</strong>
        <span style={{ fontWeight: 400 }}>, </span>
        <strong>we</strong>
        <span style={{ fontWeight: 400 }}>, </span>
        <strong>us</strong>
        <span style={{ fontWeight: 400 }}> or </span>
        <strong>our</strong>
        <span style={{ fontWeight: 400 }}>
          ) are committed to protecting the privacy of personal information provided to us and to complying with the
          Privacy Act 1988 (Cth) (the{' '}
        </span>
        <strong>Privacy Act</strong>
        <span style={{ fontWeight: 400 }}>).&nbsp; This Privacy Policy outlines how we</span>{' '}
        <span style={{ fontWeight: 400 }}>
          collect, use and disclose your information when you access or use our website located at tacklit.com or our
          other websites (the{' '}
        </span>
        <strong>Site</strong>
        <span style={{ fontWeight: 400 }}>, </span>
        <strong>Sites</strong>
        <span style={{ fontWeight: 400 }}>), our mobile applications (the </span>
        <strong>App</strong>
        <span style={{ fontWeight: 400 }}>, </span>
        <strong>Apps</strong>
        <span style={{ fontWeight: 400 }}>
          ) and other online or offline services, in each case that we own and/or operate and that link to this Privacy
          Policy (collectively, the{' '}
        </span>
        <strong>Services</strong>
        <span style={{ fontWeight: 400 }}>
          ) and when you otherwise interact with us, and the rights and choices available to our visitors and users
          regarding such information.&nbsp; By using our Services, you agree that we may treat your information in the
          ways we describe in this Privacy Policy. If you do not agree with any term of this Privacy Policy, you may not
          use our Services.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>1. About Tacklit</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          Tacklit&rsquo;s purpose is to leverage the power of technology and data to help deliver better mental health
          outcomes. We provide tools and services to practitioners and their clients to engage and support through
          recovery.&nbsp; We collect, store, and use your data on our servers to provide you with the ability to better
          maintain and improve your Services. We may also use data in an aggregated and de-identified form for our own
          purposes.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>2. What do we collect?</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          We collect such information regarding our users and their interactions with our Services.&nbsp; We will
          collect relevant and necessary information depending on the nature of your relationship with our Services.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          For parts of our Service, through connecting Practitioners and Clients, we act in the role of the Data
          Processor while you are the Data Controller.&nbsp;&nbsp;
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Personal information is information or an opinion about an individual who is identified or reasonably
          identifiable.&nbsp; The types of personal information we may collect and hold includes;
        </span>
      </p>
      <ol>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>your name;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>images of you, for example for a practitioner profile page</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              your contact details, including email address, mailing address, street address and/or telephone number;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>your medicare number and associated details;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>your age and/or date of birth;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              your credit card details, for example for subscription fee processing
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>your professional registrations</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>your preferences and/or opinions;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>information you provide to us through customer surveys;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              details of products and services we have provided to you and/or that you have enquired about, and our
              response to you;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              your browser session and geo-location data, device and network information, statistics on page views and
              sessions, acquisition sources, search queries and/or browsing behaviour;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              information about your access and use of our Site, including through the use of Internet cookies, your
              communications with our Site, the type of browser you are using, the type of operating system you are
              using and the domain name of your Internet service provider;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              information about individuals who apply for a job with us, such as their name, contact details,
              experience, qualifications and training, academic results and current salary; and
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              information about individuals who are suppliers to us, or are employed or engaged by a supplier to us.
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              additional personal information that you provide to us, directly or indirectly, through your use of our
              Site, associated applications, associated social media platforms and/or accounts from which you permit us
              to collect information; and
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              any other personal information requested by us and/or provided by you or a third party.
            </span>
          </li>
        </ul>
      </ol>
      <p>
        <span style={{ fontWeight: 400 }}>
          There are some elements of your Personal information that are deemed sensitive in line with the Privacy
          Act.&nbsp; Sensitive information is a subset of personal information that is given a higher level of
          protection under the Australian Privacy Principles.&nbsp; This includes information relating to recording a
          person&rsquo;s physical or psychological health for the purposes of assessing, maintaining, improving or
          managing the person&rsquo;s health.&nbsp; The type of necessary health information we may collect and hold due
          to the nature of our Service includes;
        </span>
      </p>
      <ol>
        <ul>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Details of presenting symptoms as reported by a practitioner and/or a client;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Details of any referral from another healthcare provider;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Details of recovery progress as reported by a practitioner and/or a client;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Details of prior or current prescription medications;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Outcome measurement data through completion of clinical assessments;
            </span>
          </li>
        </ul>
      </ol>
      <p>&nbsp;</p>
      <h2>
        <strong>3. How do we collect information?</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>There are two primary ways we collect information;</span>
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span style={{ fontWeight: 400 }}>W</span>
            <span style={{ fontWeight: 400 }}>
              e collect information through your use of our Services. When you visit or use our Services, including when
              you browse the Sites or Apps, register a User Account, and use the Platform, we will usually gather and
              collect such uses, sessions and related information, either independently or with the help of third-party
              services (as detailed below), including through the use of &ldquo;cookies&rdquo; and other tracking
              technologies.&nbsp;
            </span>
          </strong>
        </li>
      </ul>
      <ul>
        <li>
          <span style={{ fontWeight: 400 }}>
            We collect information provided by you voluntarily. For example, we collect the Personal Information you
            provide us when you register to our Services; when you submit or upload such Personal Information as you use
            any of our Services; and/or when you contact us directly by any communication channel, for example reaching
            our Customer Support team to help you resolve a query.
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may also obtain information about you from other sources and combine that information with information we
          collect from you directly, for example information from your practice manager or your business website. In
          addition, we may collect information about you when you post content to our pages and/or feeds on third party
          social media platforms.
        </span>
      </p>
      <h1>
        <strong>Cookies and Web Beacons</strong>
      </h1>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may use cookies on our Site from time to time. Cookies are text files placed in your computer's browser to
          store your preferences. Cookies, by themselves, do not tell us your email address or other personally
          identifiable information. However, they do allow third parties, such as Google and Facebook, to cause our
          advertisements to appear on your social media and online media feeds as part of our retargeting campaigns. If
          and when you choose to provide our Site with personal information, this information may be linked to the data
          stored in the cookie.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may use web beacons on our Site from time to time. Web beacons (also known as Clear GIFs) are small pieces
          of code placed on a web page to monitor the visitor&rsquo;s behaviour and collect data about the
          visitor&rsquo;s viewing of a web page. For example, web beacons can be used to count the users who visit a web
          page or to deliver a cookie to the browser of a visitor viewing that page.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We use Oribi Analytics to help us understand how users engage with our Services. Oribi Analytics uses cookies,
          web beacons and other technologies to track your interactions with our Services, then collects that
          information and reports it to us, without identifying individual users. This information helps us improve our
          Services so that we can better serve users like you.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>4. How we use your information</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>We may use your information in the following ways:</span>
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span style={{ fontWeight: 400 }}>
              Provide you the Services and fulfill your requests: We may use your information to register you,
              administer your account, and provide you the information, products and services that you request. For
              example, we respond to your questions when you contact us, assist with any problems you report about our
              Services.
            </span>
          </strong>
        </li>
      </ul>
      <ul>
        <li>
          <span style={{ fontWeight: 400 }}>
            Enhance your experience: We use your information to personalize and enhance your experience when you use the
            Services, for example remembering preferences or showing you applicable recommendations.
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span style={{ fontWeight: 400 }}>
            Communicate with you: We may contact you via email, phone, SMS, push notification or other applicable
            messaging services as part of our overall product experience in sharing relevant information with you.&nbsp;
            We may also share information and materials that we think might be of interest to you, including information
            about products and services that promote health and wellness. You may unsubscribe from receiving
            communications about these products and services by using the unsubscribe and notifications settings in your
            profile, through the unsubscribe link in an email, or contacting us at{' '}
          </span>
          <a href="mailto:unsubscribe@tacklit.com">
            <span style={{ fontWeight: 400 }}>unsubscribe@tacklit.com</span>
          </a>
          <span style={{ fontWeight: 400 }}>&nbsp;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span style={{ fontWeight: 400 }}>
            Improve our Services: Your information helps us improve the content and functionality of our Services. For
            example, we may conduct measurement activities and analyze trends, usage and activities in connection with
            the Services to create new features and content to aid our Users.
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span style={{ fontWeight: 400 }}>
            Protect Tacklit and our Users: We may use information about you to detect, investigate and prevent
            fraudulent transactions and other illegal activities and protect the rights and property of Tacklit and
            others.
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span style={{ fontWeight: 400 }}>
            Creation of De-Identified Information: We may use your Personal Information to create data that is
            de-identified in accordance with Australian Privacy Principles. This de-identified information is not
            Personal Information, because it cannot be used to identify you, and may be used by us for any lawful
            purpose.
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          In addition to those purposes listed above, we may use your information for any other purpose disclosed to you
          at the time of collection.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>5. Disclosure of personal information&nbsp;</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>We may disclose personal information to;</span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Practitioners (if you are a Client): Including medical groups or healthcare professionals who provide
            physician or other clinical services to you through our Services in line with our Client End User Agreement.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Clients (if you are a Practitioner):&nbsp; Including prospective clients, existing clients or previous
            clients who are seeking to connect with you through our Services in line with our Australia Practitioner
            Terms Of Use.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Other Service Providers: Including payment system operators; and third-party hosting and information
            security providers that provide computer, storage and information security resources to Tacklit.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection
            with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal
            rights.
          </span>
        </li>
      </ul>
      <p>
        <span style={{ fontWeight: 400 }}>
          In addition, where it relates to sensitive health information, in line with the permitted health situations
          detailed in the Privacy Act we reserve the right to use or disclose such information where we reasonably
          believe it is necessary to prevent a serious threat to life, health or safety.&nbsp; Any such disclosure will
          be done in accordance with the Australian Privacy Principles.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          By providing us with personal information, you consent to the disclosure of your personal information to third
          parties who reside outside Australia and acknowledge that we are not required to ensure that those third
          parties comply with Australian privacy laws.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We never sell personal data and we carry out all processing operations in compliance with the EU General Data
          Protection Regulation (&ldquo;GDPR&rdquo;)
        </span>
        <span style={{ fontWeight: 400 }}>.</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may share de-identified information and other de-identified non-personal Information in all legally
          permissible ways.
        </span>
      </p>
      <h1>
        <strong>Links to other websites</strong>
      </h1>
      <p>
        <span style={{ fontWeight: 400 }}>
          Note that while our Services may contain links to other websites or services, we are not responsible for such
          websites&rsquo; or services&rsquo; privacy practices. We encourage you to be aware when you leave our Services
          and read the privacy statements of each and every website and service you visit before providing your Personal
          Information. This Privacy Policy does not apply to such linked third -party websites and services.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>6. Storage and Security</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          We are committed to ensuring that the personal information we collect is secure. In order to prevent
          unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial
          procedures to safeguard and secure the personal information and protect it from misuse, interference, loss and
          unauthorised access, modification and disclosure.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          We cannot guarantee the security of any information that is transmitted to or by us over the Internet. The
          transmission and exchange of information is carried out at your own risk. Although we take measures to
          safeguard against unauthorised disclosures of information, we cannot assure you that the personal information
          we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>7. Your rights and controlling your personal information</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          Choice and consent: Please read this Privacy Policy carefully. By providing personal information to us, you
          consent to us collecting, holding, using and disclosing your personal information in accordance with this
          Privacy Policy. You do not have to provide personal information to us, however, if you do not, it may affect
          your use of this Site or the products and/or services offered on or through it.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Information from third parties: If we receive personal information about you from a third party, we will
          protect it as set out in this Privacy Policy. If you are a third party providing personal information about
          somebody else, you represent and warrant that you have such person&rsquo;s consent to provide the personal
          information to us.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Restrict: You may choose to restrict the collection or use of your personal information.&nbsp; If you have
          previously agreed to us using your personal information for direct marketing purposes, you may change your
          mind at any time by contacting us using the details below.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Access: You may request details of the personal information that we hold about you.&nbsp; An administrative
          fee may be payable for the provision of such information.&nbsp; In certain circumstances, as set out in the{' '}
        </span>
        <em>
          <span style={{ fontWeight: 400 }}>Privacy Act 1988</span>
        </em>
        <span style={{ fontWeight: 400 }}>
          {' '}
          (Cth), we may refuse to provide you with personal information that we hold about you.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Correction: If you believe that any information we hold about you is inaccurate, out of date, incomplete,
          irrelevant or misleading, please contact us using the details below. We will take reasonable steps to correct
          any information found to be inaccurate, incomplete, misleading or out of date.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Complaints: If you believe that we have breached the Australian Privacy Principles and wish to make a
          complaint, please contact us using the details below and provide us with full details of the alleged breach.
          We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our
          investigation and the steps we will take to deal with your complaint.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Unsubscribe: To unsubscribe from our email database or opt-out of communications (including marketing
          communications), please contact us using the details below or opt-out using the opt-out facilities provided in
          the communication or via your user profile.
        </span>
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>8. Amendments</strong>
      </h2>
      <p>
        <span style={{ fontWeight: 400 }}>
          We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended Privacy Policy
          on our Site. We recommend you check our Site regularly to ensure you are aware of our current Privacy Policy.
        </span>
      </p>
      <p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
      <p>
        <strong>For any questions or notices, please contact our Privacy Officer at:</strong>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>Tacklit Australia Pty Ltd (ABN 55 642 101 139)&nbsp;</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>Email: privacy@tacklit.com</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>Last update: 23 November 2020</span>
      </p>
    </div>
  );
};

export default AUPrivacy;
