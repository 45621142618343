import { CreatedAppointmentType, DeliveryType } from 'interfaces/Schedule/AppointmentType';
import moment from 'moment';
import * as Yup from 'yup';

export const initialValues = {
  date: '',
  endDate: '',
  startTime: '',
  endTime: '',
  startDateTime: '',
  endDateTime: '',
  timeZone: '',
  deliveryType: DeliveryType.FaceToFace,
  deliveryOptions: [''],
  isRecurring: false,
  frequency: 0,
  occurrences: 0,
  roomRequired: 'no',
  roomInfo: '',
  faceToFaceLocation: '',
  videoCallInstructions: '',
  phoneCallInstructions: '',
  phoneCallDialClientInstructions: '',
  otherInstructions: { title: '' },
  isCustomising: false
};

export const generateInitialValues = (appointmentType: CreatedAppointmentType) => ({
  ...initialValues,
  deliveryType: appointmentType.deliveryOptions[0],
  faceToFaceLocation: appointmentType.faceToFaceLocation || initialValues.faceToFaceLocation,
  videoCallInstructions:
    appointmentType.videoCallInstructions ||
    appointmentType.defaultVideoCallInstructions ||
    initialValues.videoCallInstructions,
  phoneCallInstructions: appointmentType.phoneCallInstructions || initialValues.phoneCallInstructions,
  phoneCallDialClientInstructions:
    appointmentType.phoneCallDialClientInstructions || initialValues.phoneCallDialClientInstructions,
  otherInstructions: appointmentType.otherInstructions || initialValues.otherInstructions
});

export const appointmentSchema = Yup.object().shape({
  date: Yup.string(),
  startTime: Yup.string().required('Please select time'),
  endTime: Yup.string()
    .required('Please select time')
    .test('invalidTimeSlot', 'Invalid time slot', function (value) {
      const { startTime } = this.parent;
      const beginningTime = moment(startTime, 'hh:mm');
      const endTime = moment(value, 'hh:mm');
      return beginningTime.isBefore(endTime);
    }),
  isRecurring: Yup.boolean(),
  frequency: Yup.number().when('isRecurring', {
    is: true,
    then: Yup.number().moreThan(0, 'Please select frequency'),
    otherwise: Yup.number()
  }),
  occurrences: Yup.number().when('isRecurring', {
    is: true,
    then: Yup.number().moreThan(0, 'Please select occurrences'),
    otherwise: Yup.number()
  }),
  roomInfo: Yup.string().when('roomRequired', {
    is: 'yes',
    then: Yup.string().required('Please choose the room'),
    otherwise: Yup.string()
  }),
  faceToFaceLocation: Yup.string(),
  videoCallInstructions: Yup.string(),
  phoneCallInstructions: Yup.string(),
  phoneCallDialClientInstructions: Yup.string(),
  otherInstructions: Yup.object().shape({
    title: Yup.string(),
    instructions: Yup.string()
  })
});
