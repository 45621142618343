import { createSlice } from '@reduxjs/toolkit';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { SubmittedClaim } from 'interfaces/invoices/submittedClaim';
import { InvoiceWithType } from 'pages/InvoicesV2/components/InvoiceList/constants';
import { getDefaultFilters, InvoiceTab } from 'pages/InvoicesV2/constants';
import { PER_PAGE_DEFAULT } from 'redux/features/utils';
import { RootState } from 'redux/store';

export enum SearchFilterEnum {
  InvoiceId = 'invoiceId'
}

export enum SortType {
  ASC = 1,
  DESC = -1
}

export interface InvoiceSorting {
  sortBy: string;
  sortType: SortType;
}

export interface DateFilter {
  from: string | null;
  to: string | null;
}

export interface InvoiceFilters {
  clients: FilterCheckListItem[];
  groups: FilterCheckListItem[];
  practitioners: FilterCheckListItem[];
  invoiceTypes: FilterCheckListItem[];
  statuses: FilterCheckListItem[];
  paymentStatuses: FilterCheckListItem[];
  participantType: ParticipantType;
  createdAt: DateFilter;
}

export interface Paging {
  page: number;
  perPage: number;
}

export interface InvoiceSearch {
  searchValue: string;
  searchBy: SearchFilterEnum.InvoiceId;
}

export interface RefundInvoice {
  amount: string;
  refId: string;
}

export interface UpdateBalanceInvoice {
  type: string;
  reference: string;
  date: string;
  amount: string;
}

export interface WriteOffForm {
  reference: string;
  date: string;
}

export interface InvoiceSliceState {
  search: InvoiceSearch;
  filters: InvoiceFilters;
  sorting: InvoiceSorting;
  paging: Paging;
  isShowRefundModal: boolean;
  isShowUpdateBalanceModal: boolean;
  selectedInvoice: InvoiceWithType | undefined;
  activeTab?: InvoiceTab;
  refundInvoice: RefundInvoice | undefined;
  updateBalanceInvoice: UpdateBalanceInvoice | undefined;
  isShowWriteOffModal: boolean;
  writeOffInvoice: WriteOffForm | undefined;
  isShowCreateClaimModal: boolean;
  isShowViewClaimModal: boolean;
  selectedClaim: SubmittedClaim | undefined;
  isShowManualAdjustTotalModal: boolean;
  manualAdjustTotalAmount: string | undefined;
}

export const DEFAULT_SORTING = {
  sortBy: 'createdAt',
  sortType: SortType.DESC
};

const initialState: InvoiceSliceState = {
  search: {
    searchValue: '',
    searchBy: SearchFilterEnum.InvoiceId
  },
  filters: {
    clients: [],
    groups: [],
    practitioners: [],
    invoiceTypes: [],
    statuses: [],
    paymentStatuses: [],
    participantType: ParticipantType.OneToOne,
    createdAt: { from: null, to: null }
  },
  sorting: DEFAULT_SORTING,
  paging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT
  },
  activeTab: InvoiceTab.All,
  isShowRefundModal: false,
  selectedInvoice: undefined,
  refundInvoice: undefined,
  isShowUpdateBalanceModal: false,
  updateBalanceInvoice: undefined,
  isShowWriteOffModal: false,
  writeOffInvoice: undefined,
  isShowCreateClaimModal: false,
  isShowViewClaimModal: false,
  selectedClaim: undefined,
  isShowManualAdjustTotalModal: false,
  manualAdjustTotalAmount: undefined
};

export const invoiceSlice = createSlice({
  name: 'invoiceSlice',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetFilters: (state, action) => {
      const defaultFilters = getDefaultFilters(action.payload as InvoiceFilters);
      state.filters = {
        ...initialState.filters,
        participantType: state.filters.participantType, // Don't reset
        ...defaultFilters[state.activeTab || InvoiceTab.All]
      };
    },

    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    },

    setPaging: (state, action) => {
      state.paging = action.payload;
    },

    resetSorting: (state) => {
      state.sorting = initialState.sorting;
    },

    resetSearch: (state) => {
      state.search = initialState.search;
    },

    resetAll: (state) => {
      state.search = initialState.search;
      state.paging = initialState.paging;
      state.sorting = initialState.sorting;
      state.filters = initialState.filters;
    },

    resetPagingAndSorting: (state) => {
      state.paging = initialState.paging;
      state.sorting = initialState.sorting;
    },

    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },

    setIsShowRefundModal: (state, action) => {
      state.isShowRefundModal = action.payload;
    },

    setSelectedInvoice: (state, action) => {
      state.selectedInvoice = action.payload;
    },

    setRefundInvoice: (state, action) => {
      state.refundInvoice = {
        ...state.refundInvoice,
        ...action.payload
      };
    },

    setIsShowUpdateBalanceModal: (state, action) => {
      state.isShowUpdateBalanceModal = action.payload;
    },

    setUpdateBalanceInvoice: (state, action) => {
      state.updateBalanceInvoice = {
        ...state.updateBalanceInvoice,
        ...action.payload
      };
    },

    setIsShowWriteOffModal: (state, action) => {
      state.isShowWriteOffModal = action.payload;
    },

    setWriteOffInvoice: (state, action) => {
      state.writeOffInvoice = {
        ...state.writeOffInvoice,
        ...action.payload
      };
    },

    setIsShowCreateClaimModal: (state, action) => {
      state.isShowCreateClaimModal = action.payload;
    },

    setIsShowViewClaimModal: (state, action) => {
      state.isShowViewClaimModal = action.payload;
    },

    setSelectedClaim: (state, action) => {
      state.selectedClaim = action.payload;
    },

    setIsShowManualAdjustTotalModal: (state, action) => {
      state.isShowManualAdjustTotalModal = action.payload;
    },

    setManualAdjustTotalAmount: (state, action) => {
      state.manualAdjustTotalAmount = action.payload;
    }
  }
});

export const selectFilters = (state: RootState) => state.invoiceSlice.filters;
export const selectSearch = (state: RootState) => state.invoiceSlice.search;
export const selectSorting = (state: RootState) => state.invoiceSlice.sorting;
export const selectPaging = (state: RootState) => state.invoiceSlice.paging;
export const selectActiveTab = (state: RootState) => state.invoiceSlice.activeTab;
export const selectIsShowRefundModal = (state: RootState) => state.invoiceSlice.isShowRefundModal;
export const selectSelectedInvoice = (state: RootState) => state.invoiceSlice.selectedInvoice;
export const selectRefundInvoice = (state: RootState) => state.invoiceSlice.refundInvoice;
export const selectIsShowUpdateBalanceModal = (state: RootState) => state.invoiceSlice.isShowUpdateBalanceModal;
export const selectUpdateBalanceInvoice = (state: RootState) => state.invoiceSlice.updateBalanceInvoice;
export const selectIsShowWriteOffModal = (state: RootState) => state.invoiceSlice.isShowWriteOffModal;
export const selectWriteOffInvoice = (state: RootState) => state.invoiceSlice.writeOffInvoice;
export const selectIsShowCreateClaimModal = (state: RootState) => state.invoiceSlice.isShowCreateClaimModal;
export const selectIsShowViewClaimModal = (state: RootState) => state.invoiceSlice.isShowViewClaimModal;
export const selectSelectedClaim = (state: RootState) => state.invoiceSlice.selectedClaim;
export const selectIsShowManualAdjustTotalModal = (state: RootState) => state.invoiceSlice.isShowManualAdjustTotalModal;
export const selectManualAdjustTotalAmount = (state: RootState) => state.invoiceSlice.manualAdjustTotalAmount;

export const {
  setSearch,
  setFilters,
  resetFilters,
  setSorting,
  setPaging,
  resetSearch,
  resetPaging,
  setActiveTab,
  setIsShowRefundModal,
  setSelectedInvoice,
  setRefundInvoice,
  setIsShowUpdateBalanceModal,
  setUpdateBalanceInvoice,
  setIsShowWriteOffModal,
  setWriteOffInvoice,
  setIsShowCreateClaimModal,
  setIsShowViewClaimModal,
  setSelectedClaim,
  setIsShowManualAdjustTotalModal,
  setManualAdjustTotalAmount
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
