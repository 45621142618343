export enum MentalHealthList {
  anxiousOrPanicky = 'anxiousOrPanicky',
  difficultyInMyRelationship = 'difficultyInMyRelationship',
  traumaticExperience = 'traumaticExperience',
  troubleSleeping = 'troubleSleeping',
  addictionOrDifficultyWithSubstanceAbuse = 'addictionOrDifficultyWithSubstanceAbuse',
  downOrDepressed = 'downOrDepressed',
  stressAtWorkOrSchool = 'stressAtWorkOrSchool',
  justExploring = 'justExploring',
  angerManagement = 'angerManagement',
  attentionDeficitHyperactivityDisorder = 'attentionDeficitHyperactivityDisorder',
  autism = 'autism',
  bipolarAffectiveDisorder = 'bipolarAffectiveDisorder',
  bodyDysmorphia = 'bodyDysmorphia',
  borderlinePersonalityDisorder = 'borderlinePersonalityDisorder',
  chronicPain = 'chronicPain',
  confidence = 'confidence',
  dissociativeDisorders = 'dissociativeDisorders',
  dyslexia = 'dyslexia',
  eatingDisorders = 'eatingDisorders',
  family = 'family',
  fertilityAPerinatal = 'fertilityAPerinatal',
  financialStress = 'financialStress',
  genderAndSexualIdentity = 'genderAndSexualIdentity',
  griefAndBereavement = 'griefAndBereavement',
  lifeTransition = 'lifeTransition',
  loneliness = 'loneliness',
  meaningValue = 'meaningValue',
  narcissism = 'narcissism',
  newParent = 'newParent',
  ocd = 'ocd',
  painManagement = 'painManagement',
  parentTherapy = 'parentTherapy',
  perfectionism = 'perfectionism',
  personalityDisorder = 'personalityDisorder',
  phobias = 'phobias',
  pornographySexualDifficulties = 'pornographySexualDifficulties',
  ptsd = 'ptsd',
  riskyBehaviours = 'riskyBehaviours',
  selfDevelopment = 'selfDevelopment',
  sexualHealth = 'sexualHealth',
  tinnitus = 'tinnitus',
  weightManagement = 'weightManagement'
}

export const MENTAL_HEALTH_LIST_LABELS: Record<MentalHealthList, string> = {
  anxiousOrPanicky: 'I’m feeling anxious or panicky',
  difficultyInMyRelationship: 'I’m having difficulty in my relationship',
  traumaticExperience: 'A traumatic experience [past or present]',
  troubleSleeping: 'I’ve been having trouble sleeping',
  addictionOrDifficultyWithSubstanceAbuse: 'I’m navigating addiction or difficulty with substance abuse',
  downOrDepressed: 'I’m feeling down or depressed',
  stressAtWorkOrSchool: 'I’m dealing with stress at work or school',
  justExploring: 'Just exploring',
  angerManagement: 'Anger Management',
  attentionDeficitHyperactivityDisorder: 'Attention Deficit Hyperactivity Disorder (ADHD)',
  autism: 'Autism',
  bipolarAffectiveDisorder: 'Bipolar affective disorder',
  bodyDysmorphia: 'Body Dysmorphia',
  borderlinePersonalityDisorder: 'Borderline Personality Disorder',
  chronicPain: 'Chronic Pain',
  confidence: 'Confidence',
  dissociativeDisorders: 'Dissociative disorders',
  dyslexia: 'Dyslexia',
  eatingDisorders: 'Eating Disorders',
  family: 'Family',
  fertilityAPerinatal: 'Fertility & Perinatal',
  financialStress: 'Financial stress',
  genderAndSexualIdentity: 'Gender & Sexual Identity',
  griefAndBereavement: 'Grief & Bereavement',
  lifeTransition: 'Life Transition',
  loneliness: 'Loneliness',
  meaningValue: 'Meaning & value',
  narcissism: 'Narcissism',
  newParent: 'New Parent',
  ocd: 'OCD',
  painManagement: 'Pain Management',
  parentTherapy: 'Parent therapy',
  perfectionism: 'Perfectionism',
  personalityDisorder: 'Personality disorder',
  phobias: 'Phobias',
  pornographySexualDifficulties: 'Pornography/ sexual difficulties',
  ptsd: 'PTSD',
  riskyBehaviours: 'Risky behaviours',
  selfDevelopment: 'Self development',
  sexualHealth: 'Sexual health',
  tinnitus: 'Tinnitus',
  weightManagement: 'Weight Management'
};

export enum AgeRangeList {
  adults = 'adults',
  olderAdults = 'olderAdults',
  children = 'children',
  teenagers = 'teenagers',
  youth = 'youth'
}

export const AGE_RANGE_LIST_LABELS: Record<AgeRangeList, string> = {
  adults: 'Adults',
  olderAdults: 'Older Adults (65+)',
  children: 'Children (<12)',
  teenagers: 'Teenagers (12-16)',
  youth: 'Youth (17-25)'
};

export const NUMBER_AGE_RANGE_LIST_LABELS: Record<AgeRangeList, string> = {
  children: '0-12',
  teenagers: '13-17',
  youth: '18-24',
  adults: '25-64',
  olderAdults: '65+'
};

export const SELECT_AGE_RANGE_LIST_LABELS = {
  [AgeRangeList.children]: 'Under 11',
  [AgeRangeList.teenagers]: '11-16',
  [AgeRangeList.youth]: '16-18',
  [AgeRangeList.adults]: '18 and over'
};

export enum GenderList {
  woman = 'woman',
  man = 'man',
  transgenderWoman = 'transgenderWoman',
  transgenderMan = 'transgenderMan',
  nonBinary = 'nonBinary',
  agender = 'agender',
  genderNotListed = 'genderNotListed',
  preferNotToState = 'preferNotToState'
}

export const GENDER_LIST_LABELS: Record<GenderList, string> = {
  woman: 'Woman',
  man: 'Man',
  transgenderWoman: 'Transgender Woman',
  transgenderMan: 'Transgender Man',
  nonBinary: 'Non-Binary',
  agender: 'Agender (I don’t identify with any gender)',
  genderNotListed: 'Gender not listed',
  preferNotToState: 'Prefer not to state'
};
