import styles from './PageHeader.module.scss';

interface PageHeaderProps {
  title: string;
  children?: React.ReactNode;
}

const PageHeader = ({ title, children }: PageHeaderProps) => (
  <div className={styles.container}>
    <span className={styles.title}>{title}</span>
    {children}
  </div>
);

export default PageHeader;
