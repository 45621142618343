import { EpisodeStatus } from 'interfaces/Episodes/episodes';
import { ChangeEvent } from 'react';
import styles from './CreateNewEPForm.module.scss';
import formStyles from '../../EpisodeModal/EpisodeModal.module.scss';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import EpisodeStatusBadge from '../../EpisodeStatusBadge/EpisodeStatusBadge';
import AppointmentDatePicker from 'pages/Calendar/components/CalendarWithHighlightsT23/components/CalendarView/components/InlineBookingModal/components/AppointmentDatePicker/AppointmentDatePicker';
import { CreateEpisodeProps, episodeTypes, medicalReasons, reasonUses } from '../../../interface/constants';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import { EpisodeError, EpisodeFieldNames } from '../../../interface';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';
import moment from 'moment/moment';
import { MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { useGenerateAvailableDate } from '../../../hooks/useGenerateAvailableDate';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

interface CreateNewEPFormProps {
  episodeFields: CreateEpisodeProps;
  onChangeNewFormField: (val: CreateEpisodeProps) => void;
  errors: Omit<EpisodeError, 'status'>;
  setErrors: (val: Omit<EpisodeError, 'status'>) => void;
  clientRecord: clientRecordsInterface;
}

export const CreateNewEPForm = ({
  episodeFields,
  onChangeNewFormField,
  errors,
  setErrors,
  clientRecord
}: CreateNewEPFormProps) => {
  const { accountSettings } = useGetAccountSettings();

  const { minStartDate, newActiveAndOnHoldDateRange } = useGenerateAvailableDate(
    clientRecord,
    moment(episodeFields?.startDate).format(MOMENTJS_FORMAT_DATE),
    episodeFields?.currentEpisodeEndDate && moment(episodeFields?.currentEpisodeEndDate).format(MOMENTJS_FORMAT_DATE)
  );

  const handleFieldChange = (fieldName: keyof EpisodeFieldNames, value: any) => {
    if (fieldName.includes('.')) {
      const subFieldName = fieldName.split('.')[1];

      setErrors({ ...errors, 'reason.medicalReason': '' });
      onChangeNewFormField({
        ...episodeFields,
        reason: {
          ...episodeFields.reason,
          [subFieldName]: value
        }
      });
    } else {
      setErrors({ ...errors, [fieldName]: '' });
      onChangeNewFormField({ ...episodeFields, [fieldName]: value });
    }
  };

  const episodeSettings = accountSettings?.episodeSettings;

  return (
    <div className={styles.container}>
      <div className={formStyles.container}>
        <div className={formStyles.label}>Status & Type</div>
        <div className={formStyles.dropdownWrapper}>
          <EpisodeStatusBadge status={EpisodeStatus.Active} />
        </div>
        <div className={formStyles.dropdownWrapper}>
          <DropdownSearchable
            error={errors.type}
            placeholder={episodeSettings?.typeLabel || 'Episode Type'}
            options={episodeSettings?.typeOptions || episodeTypes}
            selected={episodeFields.type}
            onSelect={(value) => handleFieldChange('type', value)}
            hideErrorDesc
          />
        </div>
      </div>
      <div className={formStyles.container}>
        <div className={formStyles.label}>Reason</div>
        <div className={formStyles.dropdownWrapper}>
          <DropdownSearchable
            error={errors['reason.medicalReason']}
            placeholder={episodeSettings?.reasonLabel || 'Medical reason'}
            options={episodeSettings?.reasonOptions || medicalReasons}
            selected={episodeFields.reason?.medicalReason}
            onSelect={(value) => handleFieldChange('reason.medicalReason', value)}
            hideErrorDesc
          />
        </div>
        {!episodeSettings?.reasonUseOptionsDisabled && (
          <div className={formStyles.dropdownWrapper}>
            <DropdownSearchable
              placeholder={episodeSettings?.reasonUseLabel || 'Reason use'}
              options={episodeSettings?.reasonUseOptions || reasonUses}
              selected={episodeFields.reason?.reasonUse}
              onSelect={(value) => handleFieldChange('reason.reasonUse', value)}
            />
          </div>
        )}
        <div className={formStyles.dropdownWrapper}>
          <TitleInput
            inputContainerClassName={formStyles.input}
            inputProps={{
              placeholder: episodeSettings?.reasonDetailsLabel || 'Reason details',
              value: episodeFields.reason?.details,
              onChange: (e: ChangeEvent<HTMLInputElement>) => handleFieldChange('reason.details', e.target.value)
            }}
          />
        </div>
      </div>
      <div className={formStyles.container}>
        <div className={formStyles.label}>Period</div>
        <div className={formStyles.dropdownWrapper}>
          <div className={formStyles.dateTimeWrapper}>
            <div className={formStyles.dateTimeItem}>
              <AppointmentDatePicker
                error={errors.startDate}
                placeholder="Start date"
                selectedDate={episodeFields?.startDate ? new Date(episodeFields.startDate) : undefined}
                onSelect={(value) => handleFieldChange('startDate', value)}
                hideErrorDesc
                min={minStartDate}
                max={moment().format(MOMENTJS_YEAR_MONTH_DAY_FORMAT)}
                invalid={newActiveAndOnHoldDateRange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
