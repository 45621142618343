import Input from 'components/v2/Input/Input';
import { useField } from 'formik';
import styles from './Rate.module.scss';
import { ParticipantType, RateType } from 'interfaces/Schedule/AppointmentType';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { config } from 'config/config';

interface RateProps {
  inputContainerClass?: string;
  vertical?: boolean;
  showEditableCheckbox?: boolean;
  showRateTypeSelect?: boolean;
}

const RATE_OPTION = [
  {
    value: RateType.PerSession,
    label: 'per session'
  },
  {
    value: RateType.PerAttendee,
    label: 'per attendee'
  }
];

const Rate = ({ inputContainerClass, vertical, showEditableCheckbox, showRateTypeSelect }: RateProps) => {
  const [, { value: participantType }] = useField('participantType');
  const [, { value: hidePublicRate }, { setValue: setHidePublicRate }] = useField<boolean>('hidePublicRate');
  const [, { value: rateEditable }, { setValue: setRateEditable }] = useField<boolean>('rateEditable');
  const [rateInputProps, rateMeta] = useField('rate');
  const [rateType] = useField('rateType');

  const [t] = useTranslation();

  return (
    <div className={vertical ? styles.containerVertical : styles.container}>
      <div className={classNames(styles.costInputWrapper, inputContainerClass)}>
        <Input
          label={`Cost of ${participantType === ParticipantType.Activity ? 'activity' : 'appointment'}`}
          help="This is the fee associated with this appointment type. It can automatically filter into an invoice when you link it to an appointment (you can then still change or add discount per customer). You can decide whether to show or hide the price point on the public view of your calendar"
          error={rateMeta.touched ? rateMeta.error : ''}
          inputProps={rateInputProps}
          prefix={config.currencySymbol}
          className={styles.verticalInput}
          inputClassName={styles.costInput}
        />
        {showRateTypeSelect && (
          <div className={styles.rateTypeContainer}>
            <FormikSelect
              name={'rateType'}
              className={styles.rateTypeSession}
              hasError
              isSearchable={false}
              options={RATE_OPTION}
              placeholder="Please select..."
              defaultValue={rateType}
              smallCaretDown
              styles={{
                container: (containerBase: any) => ({
                  ...containerBase,
                  width: '100%'
                }),
                valueContainer: (base: any) => ({
                  ...base,
                  minHeight: '30px',
                  padding: '0 8px 0 0',
                  cursor: 'pointer'
                }),
                singleValue: (svBase: any) => ({
                  ...svBase,
                  padding: '0 8px'
                }),
                control: (controlBase: any) => ({
                  ...controlBase,
                  minHeight: '30px',
                  minWidth: '150px',
                  backgroundColor: 'transparent',
                  border: 'none',
                  borderBottom:
                    rateMeta.touched && rateMeta.error ? '1px solid #ff1900' : `1px solid ${styles.greyColor}`,
                  borderRadius: 0,
                  boxShadow: 'none'
                })
              }}
            />
          </div>
        )}
      </div>
      {showRateTypeSelect && (
        <div className={styles.checkboxContainer}>
          <input
            className={styles.checkbox}
            type="checkbox"
            id={`checkbox-hidePriceFromClientView`}
            checked={hidePublicRate}
            onChange={() => setHidePublicRate(!hidePublicRate)}
            value="hidePriceFromClientView"
          />
          <label className={styles.label} htmlFor={`checkbox-hidePriceFromClientView`}>
            {t('appointment.hide_price_from_client')}
          </label>
        </div>
      )}
      {showEditableCheckbox && (
        <div className={styles.checkboxContainer}>
          <input
            className={styles.checkbox}
            type="checkbox"
            id={`checkbox-rateEditable`}
            checked={rateEditable}
            onChange={() => setRateEditable(!rateEditable)}
            value="rateEditable"
          />
          <label className={styles.label} htmlFor={`checkbox-rateEditable`}>
            Allow fee to be customised
          </label>
        </div>
      )}
    </div>
  );
};

export default Rate;
