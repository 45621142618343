import { CaseNote, CreateUpdateCaseNote } from 'interfaces/caseNote';
import queryString from 'query-string';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';

interface TogglePrivacyParams {
  accountId: string;
  caseNoteId: string;
  clientRecordId: string;
}

interface GetCaseNoteListPayload {
  clientRecordId: string;
  payload: {
    withClinicianName?: number;
    episodeId?: string;
    showOutsideOfEpisode?: boolean;
  };
}

interface UpdateCaseNotePayload {
  clientRecordId: string;
  caseNoteId: string;
  payload: CreateUpdateCaseNote;
}

interface createCaseNotePayload {
  clientRecordId: string;
  payload: CreateUpdateCaseNote;
}

export const caseNoteApiSlice = documentServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCaseNote: builder.query<CaseNote, { caseNoteId: string; clientRecordId: string }>({
      query: ({ caseNoteId, clientRecordId }) => {
        return {
          url: `/client-records/${clientRecordId}/case-notes/${caseNoteId}`
        };
      },
      providesTags: ['CaseNote']
    }),
    getCaseNoteList: builder.query<CaseNote[], GetCaseNoteListPayload>({
      query: ({ clientRecordId, payload }) => {
        const qString = payload ? `?${queryString.stringify(payload)}` : '';
        return {
          url: `/client-records/${clientRecordId}/case-notes${qString}`
        };
      },
      providesTags: (result) =>
        result?.length
          ? [...result.map(({ _id }) => ({ type: 'CaseNoteList' as const, id: _id })), 'CaseNoteList']
          : ['CaseNoteList'],
      keepUnusedDataFor: 5
    }),
    togglePrivacy: builder.mutation({
      query: ({ accountId, caseNoteId, clientRecordId }: TogglePrivacyParams) => ({
        url: `/accounts/${accountId}/client-records/${clientRecordId}/case-notes/${caseNoteId}:togglePrivacy`,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'CaseNote', id: arg.caseNoteId }]
    }),
    createCaseNote: builder.mutation({
      query: ({ clientRecordId, payload }: createCaseNotePayload) => ({
        url: `/client-records/${clientRecordId}/case-notes`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['CaseNote']
    }),
    updateCaseNote: builder.mutation({
      query: ({ clientRecordId, caseNoteId, payload }: UpdateCaseNotePayload) => ({
        url: `/client-records/${clientRecordId}/case-notes/${caseNoteId}`,
        method: 'PUT',
        body: payload
      })
    })
  })
});

export const {
  useGetCaseNoteQuery,
  useGetCaseNoteListQuery,
  useTogglePrivacyMutation,
  useUpdateCaseNoteMutation,
  useCreateCaseNoteMutation
} = caseNoteApiSlice;
