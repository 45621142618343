import share from 'assets/images/share.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ShareSideBox from './ShareSideBox/ShareSideBox';
import styles from './ShareTab.module.scss';

interface ShareTabProps {
  type: 'letter' | 'report';
  visible: boolean;
  data: {
    _id: string;
    status: string;
    clientRecord: {
      allowCommunicationWithEmail?: boolean;
    };
  };
  isClientShared: boolean;
  onChangeSharedList: (
    sharedList: {
      _id?: string;
      email?: string;
      isClient?: boolean;
      uniqueLink: string;
      note?: string;
      sendAt?: string;
      sentAt?: string;
    }[]
  ) => void;
}
const ShareTab = ({ visible, type, data, isClientShared, onChangeSharedList }: ShareTabProps) => {
  const { _id, status, clientRecord } = data;
  const [t] = useTranslation();

  return (
    <div className={classNames(styles.container, !visible && styles.hidden, type === 'report' && styles.topBorder)}>
      {status === 'published' ? (
        <ShareSideBox
          type={type}
          reportId={_id}
          isClientShared={isClientShared}
          allowCommunicationWithEmail={clientRecord.allowCommunicationWithEmail}
          onChangeSharedList={onChangeSharedList}
        />
      ) : (
        <div className={styles.notShareableContainer}>
          <div className={styles.iconContainer}>
            <img className={styles.icon} src={share} alt={'shareIcon'} />
          </div>
          <div className={styles.message}>{t(`form.error.share_${type}_to_client_before_publish`)}</div>
        </div>
      )}
    </div>
  );
};

export default ShareTab;
