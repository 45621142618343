import { notification } from 'antd';
import classnames from 'classnames';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetInvoiceSettingsQuery } from 'redux/endpoints/billingServices/invoiceSetting';
import { useFetchInvoices } from 'utils/hooks/useFetchInvoices';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { getAppointmentsInvoicedStatus } from 'utils/http/appointment';
import { putInvoiceStatus, putResendInvoice } from 'utils/http/BillingService/Invoice/invoice';
import { postConnectStripe } from 'utils/http/BillingService/Invoice/stripe';
import AppointmentStatus from './components/AppointmentStatus/AppointmentStatus';
import InvoiceListing from './components/InvoiceListing/InvoiceListing';
import InvoiceSettingsComponent from './components/InvoiceSettings/InvoiceSettings';
import LinkToAccountancy from './components/LinkToAccountancy/LinkToAccountancy';
import PaymentMethods from './components/PaymentMethods/PaymentMethods';
import PaymentStatus from './components/PaymentStatus/PaymentStatus';
import { AppointmentsStatus, InvoiceStatus, InvoiceStatusToBeChange } from './interface';
import styles from './Invoices.module.scss';
import ButtonAlt from '../../components/v2/ButtonAlt/ButtonAlt';
import InfiniteScroller from 'react-infinite-scroller';
import Loading from 'components/Loading/Loading';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentEpisodeId, selectIsOutsideAllEpisodes, selectIsShowAllData } from 'redux/episodes/episodeSlice';
import { InvoiceType, useTotalInvoicesAmounts } from 'utils/hooks/useTotalInvoicesAmounts';
import { useGetPaymentMethodsQuery } from 'redux/endpoints/billingServices/paymentMethods';
import { security } from 'utils/security';

const useFetchAppointmentsStatus = (token: string) => {
  const [appointmentsStatus, setAppointmentsStatus] = useState<AppointmentsStatus>();
  const [isAppointmentsStatusLoading, setIsAppointmentsStatusLoading] = useState(true);
  const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);

  const fetchAppointmentsStatus = async (token: string) => {
    try {
      const callAppointmentsStatus = await getAppointmentsInvoicedStatus(token);

      const appointmentsStatus = await callAppointmentsStatus.json();

      if (appointmentsStatus) {
        setAppointmentsStatus(appointmentsStatus);
      }
    } catch (ex) {
      notification.error({ message: "Something went wrong while trying to get your appointments' invoiced status." });
    }

    setIsAppointmentsStatusLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAppointmentsStatus(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowAllData, currentEpisodeId, isOutsideAllEpisodes, token]);

  return { appointmentsStatus, isAppointmentsStatusLoading };
};

export const useFetchPaymentMethods = () => {
  const { data, isLoading, isFetching, refetch: refetchPaymentMethods } = useGetPaymentMethodsQuery();

  const paymentMethods = useMemo(() => data?.paymentMethods || [], [data?.paymentMethods]);

  return {
    paymentMethods,
    isPaymentMethodsLoading: isLoading || isFetching,
    refetchPaymentMethods
  };
};

const Invoices = () => {
  const { token } = useGetAccessToken();
  const { INVOICES } = useRoutesGenerator();
  const { auth0ClinicianId } = useGetClinicianId();
  const { invoiceId = '' } = useParams<{ invoiceId?: string }>();
  const [invoicesAmountsFilter, setInvoicesAmountsFilter] = useState<InvoiceType>(InvoiceType.All);

  const { totalInvoicesAmounts, loading: isTotalInvoicesAmountsLoading } =
    useTotalInvoicesAmounts(invoicesAmountsFilter);
  const { appointmentsStatus, isAppointmentsStatusLoading } = useFetchAppointmentsStatus(token);
  const {
    dateSort,
    fetchMoreInvoices,
    hasMoreInvoices,
    invoices,
    isInvoicesLoading,
    participationType,
    setDateSort,
    setInvoices,
    setParticipationType,
    setStatusFilter,
    statusFilter
  } = useFetchInvoices();
  const {
    data: invoiceSettings,
    isLoading: isInvoiceSettingLoading,
    isFetching: isInvoiceSettingFetching
  } = useGetInvoiceSettingsQuery({
    clinicianId: auth0ClinicianId
  });
  const isInvoiceSettingsLoading = isInvoiceSettingLoading || isInvoiceSettingFetching;

  const { paymentMethods, isPaymentMethodsLoading, refetchPaymentMethods } = useFetchPaymentMethods();
  const [invoiceIdProcessing, setInvoiceIdProcessing] = useState<string>('');
  const { search } = useLocation();
  const { stripeRefresh } = queryString.parse(search);
  const { isEdgeAdminView, isEdgeReceptionist, isNormalUserView } = useGetAccountPackageView();

  const [isStripeRefreshLoading, setIsStripeRefreshLoading] = useState(false);
  const [isInvoiceSettingsModalVisible, setIsInvoiceSettingsModalVisible] = useState(false);
  const [isPaymentMethodsModalVisible, setIsPaymentMethodsModalVisible] = useState(false);

  const { isGroupsFeatureToggle } = useGetFeatureToggle();

  const callPostConnectStripe = async () => {
    const stripeUrl = await (await postConnectStripe(token)).text();
    window.location.href = stripeUrl;
  };

  useEffect(() => {
    if (stripeRefresh) {
      setIsStripeRefreshLoading(true);
      if (token) {
        callPostConnectStripe();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeRefresh, token]);

  useEffect(() => {
    if (invoiceId === 'invoice-settings') {
      setIsInvoiceSettingsModalVisible(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  const handleAddPaymentMethodClick = () => {
    setIsPaymentMethodsModalVisible(true);
  };

  const onChangeStatus = async (_id: string, status: InvoiceStatusToBeChange) => {
    const token = await security.getAccessTokenSilently();

    if (token) {
      try {
        setInvoiceIdProcessing(_id);
        await putInvoiceStatus(token, _id, status);
        const newInvoices = invoices;
        const updatedInvoice = newInvoices.find((invoice) => invoice._id === _id);
        updatedInvoice && (updatedInvoice.status = status as InvoiceStatus);
        setInvoices(newInvoices);

        notification.success({
          message: 'Invoice status updated.',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setInvoiceIdProcessing('');
      } catch (ex) {
        setInvoiceIdProcessing('');
        notification.error({
          message: 'Status update failed. Please try again, if failures persist please contact us to investigate.'
        });
      }
    }
  };

  const onResendInvoice = async (_id: string) => {
    const token = await security.getAccessTokenSilently();

    if (token) {
      try {
        setInvoiceIdProcessing(_id);
        await putResendInvoice(token, _id);

        notification.success({
          message: 'Successfully resent this invoice!',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setInvoiceIdProcessing('');
      } catch (ex) {
        setInvoiceIdProcessing('');
        notification.error({ message: "Something went wrong while trying to update this invoice's status" });
      }
    }
  };

  return (
    <HelmetWrapper title={'Tacklit - Invoices'}>
      <ContentLayout className={classnames(isStripeRefreshLoading && styles.loading)}>
        <div className={styles.header}>
          <span className={styles.title}>Invoice Summary</span>
          <ButtonAlt to={INVOICES.NEW} id={'createNewInvoiceId'} icon={'receipt'}>
            Create New Invoice
          </ButtonAlt>
        </div>
        <div className={styles.content}>
          <div className={styles.leftCol}>
            {(isEdgeAdminView || isEdgeReceptionist || isNormalUserView) && (
              <InvoiceSettingsComponent
                invoiceSettings={invoiceSettings}
                paymentMethods={paymentMethods}
                isInvoiceSettingsLoading={isInvoiceSettingsLoading}
                isInvoiceSettingsModalVisible={isInvoiceSettingsModalVisible}
                isPaymentMethodsLoading={isPaymentMethodsLoading}
                onAddPaymentMethodClick={handleAddPaymentMethodClick}
                setIsInvoiceSettingsModalVisible={setIsInvoiceSettingsModalVisible}
              />
            )}
            <PaymentMethods
              paymentMethods={paymentMethods}
              isPaymentMethodsLoading={isPaymentMethodsLoading}
              isPaymentMethodsModalVisible={isPaymentMethodsModalVisible}
              setIsPaymentMethodsModalVisible={setIsPaymentMethodsModalVisible}
              onSavePaymentMethods={refetchPaymentMethods}
              isEditingAllowed={isEdgeAdminView || isEdgeReceptionist || isNormalUserView}
            />
            {(isEdgeAdminView || isEdgeReceptionist || isNormalUserView) && <LinkToAccountancy />}
          </div>
          <div className={styles.rightCol}>
            <div className={styles.statuses}>
              <div className={styles.appointmentStatus} id={'appointmentStatusContainerId'}>
                <AppointmentStatus
                  appointmentsStatus={appointmentsStatus}
                  isAppointmentsStatusLoading={isAppointmentsStatusLoading}
                />
              </div>

              <div className={styles.paymentStatus} id={'paymentStatusContainerId'}>
                <PaymentStatus
                  isLoading={isTotalInvoicesAmountsLoading}
                  filter={invoicesAmountsFilter}
                  totalInvoicesAmounts={totalInvoicesAmounts}
                  enableGroupSelection={isGroupsFeatureToggle}
                  setFilter={setInvoicesAmountsFilter}
                />
              </div>
            </div>
            <div className={styles.listing} id="invoiceListingId">
              <InfiniteScroller
                initialLoad={false}
                loadMore={() => fetchMoreInvoices()}
                useWindow
                hasMore={hasMoreInvoices}
                loader={
                  <div className={styles.listLoading} key={0}>
                    <Loading />
                  </div>
                }
              >
                <InvoiceListing
                  invoices={invoices}
                  isInvoicesLoading={isInvoicesLoading}
                  allowCreateNewInvoice
                  onChangeStatus={onChangeStatus}
                  onResendInvoice={onResendInvoice}
                  invoiceIdProcessing={invoiceIdProcessing}
                  enableGroupSelection={isGroupsFeatureToggle}
                  dateSort={dateSort}
                  setDateSort={setDateSort}
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                  participationType={participationType}
                  setParticipationType={setParticipationType}
                />
              </InfiniteScroller>
            </div>
          </div>
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Invoices;
