import styles from './QuestionSectionView.module.scss';
import { FirstAssessmentStruct } from 'interfaces/firstAssessment';
import { Skeleton } from 'antd';

interface QuestionSectionViewProps {
  questionList: FirstAssessmentStruct['questionSets'];
  isOpenDataCategoriesLoading: boolean;
}

const QuestionSectionView = ({ questionList, isOpenDataCategoriesLoading }: QuestionSectionViewProps) => {
  return (
    <div>
      {questionList?.map((quesSectionObj, index: number) => (
        <div key={index}>
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.topicLabel}>Topic</div>
              <div className={styles.topic}>{quesSectionObj.sectionName}</div>
            </div>
            <div className={styles.content}>
              {quesSectionObj.categoryId && isOpenDataCategoriesLoading ? (
                <>
                  {[...Array(3)].map((obj, i) => (
                    <div key={i} className={styles.loadingWrapper}>
                      <Skeleton.Input active className={styles.loading} />
                    </div>
                  ))}
                </>
              ) : (
                quesSectionObj.questions
                  .filter((obj) => obj.isChecked)
                  .map((quesObj, index: number) => (
                    <label className={styles.questionWrapper} htmlFor={quesObj.id} key={index}>
                      <div className={styles.questionBox}>
                        <div>{index + 1}. </div>
                        <div className={styles.label}>{quesObj.stem}</div>
                      </div>
                    </label>
                  ))
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestionSectionView;
