import ModalV2 from 'components/ModalV2/ModalV2';
import Header from './Header/Header';
import InvoiceInfo from './InvoiceInfo/InvoiceInfo';
import InvoiceTemplatesOverView from './InvoiceTemplatesOverView/InvoiceTemplatesOverView';
import styles from './InvoiceTemplatesPreview.module.scss';
import MedicareInfo from './MedicareInfo/MedicareInfo';
import PaymentDetail from './PaymentDetail/PaymentDetail';
import { InvoiceTemplatesPreviewProps } from './interface';

const InvoiceTemplatesPreview = ({
  isModalOpen,
  onModalClose,
  data: {
    name,
    practiceLogo,
    practiceName,
    practiceAddress,
    practicePhoneNumber,
    practiceFax,
    practiceFormalName,
    practiceRegistrationNumber,
    practiceFinanceEmail,
    invoiceTitle,
    invoiceId,
    issueDate,
    recipientAddress,
    recipientRegistrationNumber,
    invoiceNotes,
    medicareClaimant,
    medicareClaim,
    medicareReferral,
    medicareServiceProvider,
    itemOverviews,
    itemColumns,
    invoiceFooter,
    shortCode,
    recipientReference,
    recipientMobileNumber,
    recipientEmail,
    recipient,
    allowMultipleItems
  },
  practiceInfo,
  paymentMethods
}: InvoiceTemplatesPreviewProps) => {
  return (
    <ModalV2
      containerClassName={styles.previewContainer}
      isModalOpen={isModalOpen}
      title={name}
      onModalClose={onModalClose}
      bodyStyle={{ padding: '0' }}
    >
      <div className={styles.invoiceTemplatesContainer}>
        {practiceInfo && (
          <>
            <Header
              practiceLogo={practiceLogo}
              practiceName={practiceName}
              practiceAddress={practiceAddress}
              practicePhoneNumber={practicePhoneNumber}
              practiceFax={practiceFax}
              practiceFormalName={practiceFormalName}
              practiceRegistrationNumber={practiceRegistrationNumber}
              practiceFinanceEmail={practiceFinanceEmail}
              practiceInfo={practiceInfo}
            />
            <div className={styles.divider} />
          </>
        )}
        <InvoiceInfo
          invoiceTitle={invoiceTitle}
          invoiceId={invoiceId}
          issueDate={issueDate}
          recipientAddress={recipientAddress}
          recipientRegistrationNumber={recipientRegistrationNumber}
          shortCode={shortCode}
          recipientReference={recipientReference}
          recipientMobileNumber={recipientMobileNumber}
          recipientEmail={recipientEmail}
          recipient={recipient}
        />
        {invoiceNotes && <div className={styles.additionalNote} dangerouslySetInnerHTML={{ __html: invoiceNotes }} />}
        <div className={styles.divider} />
        <MedicareInfo
          medicareClaimant={medicareClaimant}
          medicareClaim={medicareClaim}
          medicareServiceProvider={medicareServiceProvider}
          medicareReferral={medicareReferral}
        />
        <InvoiceTemplatesOverView
          itemOverviews={itemOverviews}
          itemColumns={itemColumns}
          allowMultipleItems={allowMultipleItems}
        />
        <div className={styles.divider} />
        {paymentMethods.length > 0 && <PaymentDetail paymentMethods={paymentMethods} />}
        {invoiceFooter && <div className={styles.invoiceFooter} dangerouslySetInnerHTML={{ __html: invoiceFooter }} />}
      </div>
    </ModalV2>
  );
};

export default InvoiceTemplatesPreview;
