import { useState } from 'react';
import { Skeleton } from 'antd';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import moment from 'moment';
import noListImg from 'assets/images/voluteer_schedule.png';

import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import { DayFilter, FilterStatus, MentorAppointmentFilter } from '../../constants';
import MemberFilter from './components/MemberFilter/MemberFilter';
import MemberItem, { MemberItemProps } from './components/MemberItem/MemberItem';
import styles from './MentorAppointmentList.module.scss';
import { useFetchCountEachTimeSelections, useFetchMemberListByFilter } from './hooks/getMentorAppointmentList';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import BespokeItem from './components/BespokeItem/BespokeItem';
import queryString from 'query-string';
import MentorHeader from '../../../MentorHeader/MentorHeader';

interface MentorAppointmentListProps {
  profile: ProfileInterface;
}

const MentorAppointmentList = ({ profile }: MentorAppointmentListProps) => {
  const { accountId } = useGetAccountId();
  const location = useLocation();
  const queryParam: {
    filterStatus?: FilterStatus;
    selectedDay?: DayFilter;
  } = queryString.parse(location.search);

  const massageParams: MentorAppointmentFilter = {
    filterStatus:
      queryParam.filterStatus && !!Object.values(FilterStatus).find((filterObj) => filterObj)
        ? queryParam.filterStatus
        : FilterStatus.UPCOMING,
    selectedDay: {
      key:
        queryParam.selectedDay && !!Object.values(DayFilter).find((obj) => obj === queryParam.selectedDay)
          ? queryParam.selectedDay
          : DayFilter.Today,
      label: 'Today',
      count: 0
    }
  };

  const [filter, setFilter] = useState<MentorAppointmentFilter>(massageParams);
  const { token } = useGetAccessToken();
  const { timeSelections, isTimeSelectionsLoading } = useFetchCountEachTimeSelections(token);
  const { listOfMembers, isListOfMembersLoading } = useFetchMemberListByFilter(token, accountId, filter);
  const navigate = useNavigate();
  const { MENTOR } = useRoutesGenerator();

  const onChangeFilter = (filter: MentorAppointmentFilter) => {
    setFilter(filter);
    const routerPath = {
      filterStatus: filter.filterStatus,
      ...(filter.filterStatus === 'upcoming' && {
        selectedDay: filter.selectedDay.key
      })
    };

    const param = queryString.stringify(routerPath);
    navigate(`${MENTOR.CLIENTS}?${param}`);
  };

  const handleSelectMember = (clientRecordId: string) => {
    navigate(`${MENTOR.CLIENTS}/${clientRecordId}`);
  };

  return (
    <div className={styles.container}>
      <MentorHeader profile={profile}>
        <div
          className={styles.title}
        >{`Welcome back ${profile.name}, here is your ${profile.practice?.name} case load`}</div>
      </MentorHeader>
      {isTimeSelectionsLoading ? (
        <>
          <div className={styles.toggleLoadingWrapper}>
            <Skeleton.Button active className={styles.toggleLoading} />
          </div>
          <div className={styles.countLoading}>
            <Skeleton.Button active className={styles.filterLoading} />
            <Skeleton.Button active className={styles.filterLoading} />
            <Skeleton.Button active className={styles.filterLoading} />
            <Skeleton.Button active className={styles.filterLoading} />
            <Skeleton.Button active className={styles.filterLoading} />
          </div>
        </>
      ) : (
        <MemberFilter
          filterStatus={filter.filterStatus}
          selectedDay={filter.selectedDay}
          onChangeFilter={onChangeFilter}
          dayList={timeSelections}
          isLoading={isListOfMembersLoading}
          totalAppointment={listOfMembers.length}
        />
      )}

      <div className={styles.content}>
        {isListOfMembersLoading ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : listOfMembers.length > 0 ? (
          listOfMembers.map((member, index) => (
            <div key={index}>
              {(index === 0 || member.date !== listOfMembers[index - 1].date) && member.date && (
                <div className={styles.selectedTimeLabel}>{moment(member.date).format('dddd, MMMM D')}</div>
              )}
              {member.clientRecord && member.deliveryType !== 'bespoke' ? (
                <div
                  onClick={() => {
                    handleSelectMember(member.clientRecord?._id || '');
                  }}
                >
                  <MemberItem appointment={member as MemberItemProps['appointment']} />
                </div>
              ) : (
                <BespokeItem appointment={member as MemberItemProps['appointment']} />
              )}
            </div>
          ))
        ) : (
          <div className={styles.noListingContainer}>
            <img alt={'noListImg'} className={styles.noListImg} src={noListImg} />
            <div>No appointments scheduled</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MentorAppointmentList;
