import { Modal, notification } from 'antd';

import styles from './QrCodeModal.module.scss';
import QRCode from 'qrcode.react';
import logo from 'assets/images/tacklit-icon.png';

export interface PersonalDetailsProps {
  facilitatedUrl: string;
  visibleModal: boolean;
  name?: string;
  onCancel?: () => void;
}

const QrCodeModal = ({
  facilitatedUrl,
  visibleModal,
  name,
  ...props
}: PersonalDetailsProps & React.HTMLProps<HTMLDivElement>) => {
  const handleCopyContent = async (onBoardingLink: string) => {
    try {
      await navigator.clipboard.writeText(onBoardingLink);
      notification.success({
        message: 'Copied!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    } catch (err) {
      notification.error({
        message: 'Failed to copy!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <Modal title="Facilitated URL" open={visibleModal} {...props} footer={null}>
      <div className={styles.container}>
        {name && <div className={styles.nameContainer}>{name}</div>}
        <div className={styles.linkContainer}>
          <div className={styles.linkWrapper}>
            <a className={styles.link} href={facilitatedUrl} target={'_blank'} rel={'noopener noreferrer'}>
              {facilitatedUrl}
            </a>
            <div className={styles.copyBtn} onClick={() => handleCopyContent(facilitatedUrl)}>
              <i className={`material-icons`}>content_copy</i>
            </div>
          </div>
        </div>
        <div className={styles.qrCodeWrapper}>
          <QRCode
            value={facilitatedUrl}
            size={180}
            bgColor={styles.whiteColor}
            fgColor={styles.blackColor}
            level={'L'}
            includeMargin={false}
            renderAs={'svg'}
            imageSettings={{
              src: logo,
              height: 24,
              width: 24,
              excavate: true
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default QrCodeModal;
