import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import {
  InvoiceSliceState,
  SearchFilterEnum,
  SortType,
  selectActiveTab,
  selectFilters,
  selectPaging,
  selectSearch,
  selectSorting
} from './invoiceSlice';
import { useAppSelector } from 'redux/hooks';
import { useGetInvoicesQuery } from 'redux/endpoints/billingServices/invoices';
import { getSortByQuery } from 'pages/InvoicesV2/components/InvoiceList/constants';
import { ParticipantType as ScheduleParticipantType } from 'interfaces/Schedule/AppointmentType';
import { ParticipantType } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import moment from 'moment';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { InvoiceTab } from 'pages/InvoicesV2/constants';

interface GetInvoiceListQuery {
  page: number;
  perPage: number;

  searchValue?: string;
  searchBy?: SearchFilterEnum;

  sortByIssueDate?: 1 | -1;

  clientIds?: string;
  practitionerIds?: string;
  invoiceTypes?: string;
  statuses?: string;
  paymentStatuses?: string;
  type: ParticipantType;
}

export const getInvoiceRequestPayload = (
  params: Pick<InvoiceSliceState, 'paging' | 'sorting' | 'filters' | 'search' | 'activeTab'>
): GetInvoiceListQuery => {
  const { paging, sorting, filters, search, activeTab } = params;
  const { page, perPage } = paging;
  const { sortBy, sortType } = sorting;
  const { searchValue, searchBy } = search;
  const { clients, practitioners, invoiceTypes, statuses, paymentStatuses, participantType, createdAt, groups } =
    filters;
  const sortByKey = sortBy && getSortByQuery(sortBy);

  return {
    page,
    perPage,

    ...(sortByKey && {
      [sortByKey]: sortType === SortType.ASC ? 1 : -1
    }),

    ...(search.searchValue && {
      searchValue,
      searchBy
    }),

    ...(participantType === ScheduleParticipantType.OneToOne && clients.length > 0
      ? activeTab === InvoiceTab.ClientProfile
        ? {
            clientRecordId: clients[0]._id
          }
        : {
            clientIds: clients.map(({ _id }) => _id).join(',')
          }
      : participantType === ScheduleParticipantType.Group &&
        groups.length > 0 && {
          groupIds: groups.map(({ _id }) => _id).join(',')
        }),

    ...(practitioners.length > 0 && {
      practitionerIds: practitioners.map(({ _id }) => _id).join(',')
    }),

    ...(invoiceTypes.length > 0 && {
      invoiceTypes: invoiceTypes.map(({ _id }) => _id).join(',')
    }),

    ...(statuses.length > 0 && {
      statuses: statuses.map(({ _id }) => _id).join(',')
    }),

    ...(paymentStatuses.length > 0 && {
      paymentStatuses: paymentStatuses.map(({ _id }) => _id).join(',')
    }),

    ...{
      type: participantType === ScheduleParticipantType.OneToOne ? ParticipantType.Individual : ParticipantType.Group
    },

    ...(createdAt.from &&
      createdAt.to && {
        createdAtFrom: createdAt.from,
        createdAtTo: createdAt.to && moment(createdAt.to).endOf('day').toISOString()
      })
  };
};

export const useGetInvoicesList = (skipFetch?: boolean) => {
  const { accountId } = useGetAccountId();
  const filters = useAppSelector(selectFilters);
  const paging = useAppSelector(selectPaging);
  const search = useAppSelector(selectSearch);
  const sorting = useAppSelector(selectSorting);
  const activeTab = useAppSelector(selectActiveTab);

  const { isInvoiceListV2Enabled } = useGetFeatureToggle();

  const { data, isLoading, isFetching, isError, refetch } = useGetInvoicesQuery(
    {
      accountId,
      params: {
        ...getInvoiceRequestPayload({
          paging,
          sorting,
          filters,
          search,
          activeTab
        })
      }
    },
    { skip: !accountId || !isInvoiceListV2Enabled || !!skipFetch } // skipFetch: skip until reset tab
  );

  return {
    isInvoicesLoading: isLoading || isFetching,
    isError,
    invoices: data?.invoices || [],
    invoiceCounts: data?.count || {
      recent: 0,
      total: 0
    },
    totalItems: data?.paging.totalItems || 0,
    refetch
  };
};
