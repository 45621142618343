import styles from './ProCarePendingSetup.module.scss';

const ProCarePendingSetup = () => {
  const goToLogin = () => {
    window.location.href = window.location.origin + '/login';
  };

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Further steps required</div>
        </div>
        <div className={styles.desc}>
          <div className={styles.description}>
            <p>
              <b>You have successfully registered.</b>
            </p>
            <p>
              Your administrator needs to complete some steps before you can access your Tacklit account. Please contact
              them for further information.
            </p>
            <p>
              If your administrator has completed those steps, please{' '}
              <span className={styles.link} onClick={() => goToLogin()}>
                click here to continue
              </span>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProCarePendingSetup;
