import { useState } from 'react';

import MaterialSelect from '../../../Select/MaterialSelect/MaterialSelect';

import styles from './ProfileTypeForm.module.scss';
import { newClientForm } from '../../AddPatientModalV2Interface';
import { PROFILE_TYPE_OPTIONS } from 'components/AddPatientModalV2/constants';
import { ProfileTypeFormProps } from 'components/AddPatientModalV2/interfaces/profileType.interface';

const ProfileTypeForm = ({ profileTypeData, onChangeProfileType }: ProfileTypeFormProps) => {
  const [profileType, setProfileType] = useState(profileTypeData);
  const handleSelectType = (value: newClientForm['recordType']) => {
    setProfileType(value);
    onChangeProfileType(value);
  };

  return (
    <>
      <div className={styles.fieldContainer}>
        <MaterialSelect
          id={'clientProfileType'}
          label={'Profile Type'}
          isSearchable={false}
          options={PROFILE_TYPE_OPTIONS}
          value={profileType}
          onChange={handleSelectType}
        />
      </div>
    </>
  );
};

export default ProfileTypeForm;
