import { notification } from 'antd';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { AddressBookFormValidationInitialMessage } from 'pages/AddressBook/AddressBookList/components/AddressBookListContent/components/constants';
import { AddressBookType } from 'pages/AddressBook/Interfaces/AddressBook';
import { AddressBookContactRole } from 'pages/AddressBook/Interfaces/AddressBookContact';
import { useRef, useState } from 'react';
import { useUpdateAddressBookMutation } from 'redux/endpoints/clinicianProfileServices/addressBook';
import { sleep } from 'utils/helpers/sleep';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { scrollToView } from 'utils/scrollToView';
import ModalV2 from '../../ModalV2/ModalV2';
import { CreateAddressBookContactRequestPayload } from '../CreateAddressBookModal/components/AddressBookContactForm/AddressBookContactForm';
import AddressBookForm, {
  AddressBookFormHandler,
  CreateOrUpdateAddressBookPayload
} from '../CreateAddressBookModal/components/AddressBookForm/AddressBookForm';
import { validateAddressBookForm } from '../CreateAddressBookModal/components/AddressBookForm/validation/AddressBookValidation';
import styles from './EditAddressBookModal.module.scss';
import { EditAddressBookValidateField } from './EditAddressBookModalInterfaces';

interface EditAddressBookModalProps {
  onCloseModal: () => void;
  initialFields: CreateOrUpdateAddressBookPayload;
}

const defaultContactFields = {
  firstName: '',
  lastName: '',
  role: AddressBookContactRole.GP,
  mobileNumber: '',
  faxNumber: '',
  email: '',
  isPrimary: false
};

const EditAddressBookModal = ({ onCloseModal, initialFields }: EditAddressBookModalProps) => {
  const { accountId } = useGetAccountId();
  const [addressBookField, setAddressBookField] = useState(initialFields);
  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [visible, setVisible] = useState<boolean>(true);
  const addressBookFormRef = useRef<AddressBookFormHandler>(null);
  const [updateAddressBook] = useUpdateAddressBookMutation();

  const [contactField, setContactField] = useState(
    addressBookField.type === AddressBookType.Individual && addressBookField.contacts
      ? addressBookField.contacts[0]
      : defaultContactFields
  );
  const [errorMessage, setErrorMessage] = useState<EditAddressBookValidateField>(
    AddressBookFormValidationInitialMessage
  );

  const handleChangeAddressBookField = async (val: CreateOrUpdateAddressBookPayload) => {
    await validateAddressBookField(val);
    setAddressBookField(val);
  };

  const validateAddressBookField = async (payload: CreateOrUpdateAddressBookPayload) => {
    const { isError, messages } = validateAddressBookForm(payload) || {};

    setErrorMessage(messages as EditAddressBookValidateField);

    if (isError) {
      setPageSubmitStatus('');
    }

    return !isError;
  };

  const handleSubmit = async () => {
    setPageSubmitStatus('active');

    const formData: CreateOrUpdateAddressBookPayload = {
      ...addressBookField,
      ...(addressBookField.type === AddressBookType.Organisation
        ? {
            type: AddressBookType.Organisation,
            organisation: addressBookField.organisation
          }
        : { type: AddressBookType.Individual, organisation: undefined })
    };

    if (addressBookField.type === AddressBookType.Individual) {
      const contacts = await submitContactForm();
      if (contacts && contacts.length > 0) {
        formData.contacts = contacts;
      } else {
        setPageSubmitStatus('');
        return;
      }
    }

    const validate = await validateAddressBookField(formData);
    if (validate && '_id' in formData) {
      try {
        await updateAddressBook({ accountId, addressBookId: formData._id, payload: formData }).unwrap();

        setPageSubmitStatus('finished');
        setTimeout(() => {
          scrollToView('modalHeader', true);
          notification.success({
            message: 'Entry updated',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setPageSubmitStatus('');
          closeModal();
        }, 2000);
      } catch (ex) {
        console.error(ex);
        setPageSubmitStatus('');
        notification.error({
          message: 'Failed to edit entry',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    } else {
      setPageSubmitStatus('');
      notification.error({
        message: 'Invalid form details',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const submitContactForm = async () => {
    if (addressBookFormRef.current) {
      return await addressBookFormRef.current.submitContact();
    }
  };

  const closeModal = async () => {
    setVisible(false);
    await sleep(850);
    onCloseModal();
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={visible}
      title={'Edit Address Book Entry'}
      onModalClose={closeModal}
    >
      <div className={styles.container}>
        <AddressBookForm
          mode="edit"
          addressBookFormField={addressBookField}
          errorMessage={errorMessage}
          submitStatus={pageSubmitStatus}
          onChangeField={handleChangeAddressBookField}
          ref={addressBookFormRef}
          contactField={contactField}
          setContactField={(contact?: CreateAddressBookContactRequestPayload) =>
            setContactField(contact ?? defaultContactFields)
          }
        />
      </div>
      <div className={styles.submitButtonContainer}>
        <ButtonAlt status={pageSubmitStatus} disabled={pageSubmitStatus !== ''} onClick={handleSubmit}>
          Save Addressee
        </ButtonAlt>
      </div>
    </ModalV2>
  );
};

export default EditAddressBookModal;
