import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import Loading from 'components/Loading/Loading';
import SearchBar from 'components/v2/SearchBar/SearchBar';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { LoadMoreClients } from 'utils/hooks/GetClient/clientList';
import styles from './BroadcastClientList.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  resetPreSelectedClientRecordId,
  selectPreSelectedClientRecordId,
  selectSelectedClients,
  setSelectedClients
} from 'redux/features/messages/broadcastMessageSlice';
import { useGetClientEncryptedDataByClientRecordIdQuery } from 'redux/endpoints/clinicianProfileServices/client';

interface BroadcastClientListProps {
  clients: clientRecordsInterface[];
  hasMoreClients: boolean;
  loadingSearch?: boolean;
  loadMoreClients: LoadMoreClients;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const BroadcastClientList = ({
  clients,
  hasMoreClients,
  loadingSearch,
  loadMoreClients,
  searchTerm,
  setSearchTerm
}: BroadcastClientListProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const preSelectClientRecordId = useAppSelector(selectPreSelectedClientRecordId);
  const selectedClients = useAppSelector(selectSelectedClients);

  // Fetch client record data to show in selected client section
  const { data: clientRecordData, isLoading: isClientRecordLoading } = useGetClientEncryptedDataByClientRecordIdQuery(
    {
      clientRecordId: preSelectClientRecordId
    },
    { skip: !preSelectClientRecordId }
  );

  useEffect(() => {
    if (!isClientRecordLoading && clientRecordData) {
      dispatch(setSelectedClients([clientRecordData]));
      // Reset preselect client id after add client record data to global store
      dispatch(resetPreSelectedClientRecordId());
    }
  }, [clientRecordData, isClientRecordLoading, dispatch]);

  // Reset selected client list after component unmount
  useEffect(() => {
    return () => {
      dispatch(setSelectedClients([]));
    };
  }, [dispatch]);

  const handleAddClient = (clientId: string) => {
    const foundClient = clients.find((client) => client._id === clientId);
    if (!foundClient) {
      return;
    }
    dispatch(setSelectedClients([...selectedClients, foundClient]));
  };

  const handleRemoveClient = (clientId: string) => {
    const newSelectedClients = [...selectedClients].filter((item) => item._id !== clientId);
    dispatch(setSelectedClients([...newSelectedClients]));
  };

  return (
    <div className={styles.container}>
      <div className={styles.clientListContainer}>
        <div className={styles.cardTitle}>Select who to add to broadcast</div>
        <SearchBar searchValue={searchTerm} setSearchValue={setSearchTerm} />
        <div className={styles.clientListWrapper}>
          {loadingSearch || isClientRecordLoading ? (
            <Loading />
          ) : clients.length > 0 ? (
            <InfiniteScroll
              hasMore={hasMoreClients}
              initialLoad={false}
              loader={<Loading key="infinite-scroll-loading-spinner" />}
              loadMore={loadMoreClients}
              useWindow={false}
            >
              {clients.map(({ _id, clientProfiles, recordType, allowCommunicationWithSms }, index: number) => {
                return (
                  !selectedClients.some((client) => client._id === _id) && (
                    <div
                      className={allowCommunicationWithSms ? styles.clientListCard : styles.clientListCardDisable}
                      key={index}
                    >
                      <div className={styles.clientRecordWrapper}>
                        <ClientAvatar
                          clientData={clientProfiles}
                          avatarSize={24}
                          isEllipsisName
                          displayFirstNameOnly={recordType === 'couple'}
                          displayLimit={recordType === 'couple' ? 2 : 1}
                          initialsClassName={styles.initialName}
                          nameClassName={styles.name}
                          avatarClassName={styles.avatar}
                          horizontal
                        />
                      </div>
                      {allowCommunicationWithSms ? (
                        <span className={styles.addBtn} onClick={() => handleAddClient(_id)}>
                          + Add
                        </span>
                      ) : (
                        <div className={styles.noAllowTooltips}>
                          <HelpOutLineWithTooltips
                            id={`noAllowAdd${index}`}
                            desc={t('form.error.broadcast_client_limited_communication')}
                          />
                        </div>
                      )}
                    </div>
                  )
                );
              })}
            </InfiniteScroll>
          ) : searchTerm.length > 0 && clients.length === 0 ? (
            <div className={styles.noItems}>
              <i className="material-icons-outlined">search_off</i>
              No matching records found
            </div>
          ) : (
            <div className={styles.noItems}>No client records found</div>
          )}
        </div>
      </div>
      <div className={styles.clientCartContainer}>
        <div className={styles.cardTitle}>{selectedClients.length} recipients added</div>
        <div className={styles.clientListWrapper}>
          {selectedClients.map(({ _id, clientProfiles, recordType }, index: number) => (
            <div className={styles.clientListCard} key={index}>
              <div className={styles.clientRecordWrapper}>
                <ClientAvatar
                  clientData={clientProfiles}
                  avatarSize={24}
                  isEllipsisName
                  displayFirstNameOnly={recordType === 'couple'}
                  displayLimit={recordType === 'couple' ? 2 : 1}
                  initialsClassName={styles.initialName}
                  nameClassName={styles.name}
                  avatarClassName={styles.avatar}
                  horizontal
                />
              </div>
              <span className={styles.removeBtn} onClick={() => handleRemoveClient(_id)}>
                Remove
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BroadcastClientList;
