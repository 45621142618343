import moment from 'moment';
import { useEffect, useState } from 'react';

export const useTime = (refreshCycle: number, updatedTime: Date) => {
  const updateTime = moment(updatedTime);
  const [now, setNow] = useState(updateTime);

  useEffect(() => {
    const intervalId = setInterval(() => setNow(updateTime), refreshCycle);
    return () => clearInterval(intervalId);
  }, [refreshCycle, setNow, updateTime]);

  return now;
};
