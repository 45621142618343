import { LetterStatus, PutLetterRequest } from 'interfaces/Letters/letter';
import moment from 'moment';

export const initLetterData = ({
  clinician,
  clinicianSignature,
  clinicianSignatureExtraDetails,
  contactDetails,
  isForPractice,
  practice,
  clientRecord
}: Partial<PutLetterRequest>): PutLetterRequest => ({
  status: LetterStatus.Draft,
  isForPractice: !!isForPractice,
  letterName: `Letter - ${moment().format('DD/MM/YYYY')}`,
  body: '',
  issueDate: moment().format('YYYY-MM-DD'),
  clientRecord: {
    _id: clientRecord?._id || ''
  },
  clinician: clinician || {
    _id: ''
  },
  contactDetails: contactDetails || {
    practice: {
      address: {
        value: '',
        isVisible: false
      },
      mobileNumber: {
        value: '',
        isVisible: false
      }
    },
    clinician: {
      email: {
        value: '',
        isVisible: false
      },
      mobileNumber: {
        value: '',
        isVisible: false
      }
    }
  },
  clinicianSignature: clinicianSignature || {
    type: '',
    textVal: '',
    drawVal: ''
  },
  clinicianSignatureExtraDetails: clinicianSignatureExtraDetails || {
    valediction: 'Kind Regards',
    email: '',
    mobileNumber: '',
    name: '',
    other: ''
  },
  ...(practice && { practice })
});
