import { Modal, notification } from 'antd';
import { ClientProfileEpisodeDropdownItem } from 'components/SideDashboardT23/components/ClientSubTab/ClientProfileSubTab/components/ClientProfileEpisodeDropdownItem/ClientProfileEpisodeDropdownItem';
import { generateClientProfileDropdownOptions } from 'components/SideDashboardT23/components/ClientSubTab/ClientProfileSubTab/utils/generateClientProfileDropdownOptions';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { EpisodeStatus } from 'interfaces/Episodes/episodes';
import {
  AssignPackagesInterface,
  funderType,
  PackageFunderQuotaStatus,
  PackagesStatus
} from 'interfaces/Packages/packages';
import { Referral } from 'interfaces/Referral/Referral';
import { ReferrerType } from 'pages/Referrals/interface';
import { REFERRAL_PER_PAGE } from 'pages/Referrals/utils';
import { useCallback, useMemo, useState } from 'react';
import {
  useGetCurrentActiveEpisodeQuery,
  useGetEpisodeListQuery
} from 'redux/endpoints/clinicianProfileServices/episode';
import { useGetReferralListQuery } from 'redux/endpoints/clinicianProfileServices/referral';
import { useAssignPackageMutation, useGetPackageListQuery } from 'redux/endpoints/scheduleServices/package';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import * as Yup from 'yup';
import { EPISODE_PER_PAGE } from '../../../../../ClientDetailsEoC/interface/constants';
import styles from './PackageAssignmentModal.module.scss';

interface PackageAssignmentModalProps {
  clientRecordId: string;
  isVisible?: boolean;
  onClose: () => void;
}

export interface SelectedPackage {
  packageId: string;
  funderId: string;
  episodeId?: string;
  referralId?: string;
}

export const packageSchema = Yup.object().shape({
  packageId: Yup.string().required('Package ID is required'),
  funderId: Yup.string().required('Funder ID is required')
});

export const getReferralName = (referralInfo: Referral) => {
  const { referrerType } = referralInfo.referrer;
  const fullName = `${referralInfo.referrer.firstName} ${referralInfo.referrer.lastName}`;
  const trimmedName = referralInfo.referrer.name?.trim();
  const orgName = referralInfo.org?.name;

  switch (referrerType) {
    case ReferrerType.Self:
      return `${referralInfo.subject?.firstName} ${referralInfo.subject?.lastName}`;
    case ReferrerType.Organisation:
      return orgName || fullName;
    case ReferrerType.Professional:
    case ReferrerType.FriendFamily:
      return trimmedName || fullName;
    default:
      return trimmedName || fullName;
  }
};

const PACKAGE_PER_PAGE = 20;

const PackageAssignmentModal = ({ clientRecordId, isVisible, onClose }: PackageAssignmentModalProps) => {
  const { accountId } = useGetAccountId();
  const { accountTimeZone } = useTimeZone();
  const selectedPackageSchema = Yup.array().of(packageSchema);
  const [selectedPackage, setSelectedPackage] = useState<SelectedPackage[]>([
    {
      packageId: '',
      funderId: ''
    }
  ]);
  const [checkValidation, setCheckValidation] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');
  const [packageListCurrentPage, setPackageListCurrentPage] = useState<number>(1);
  const [episodeListCurrentPage, setEpisodeListCurrentPage] = useState<number>(1);
  const [referralListCurrentPage, setReferralListCurrentPage] = useState<number>(1);

  const [assignPackage] = useAssignPackageMutation();

  const {
    data: packageFunderData,
    isLoading: packageFunderDataLoading,
    isFetching: packageFunderDataFetching,
    refetch: packageFunderDataRefetch
  } = useGetPackageListQuery(
    {
      accountId: accountId,
      params: {
        page: packageListCurrentPage,
        perPage: PACKAGE_PER_PAGE,
        status: PackagesStatus.Active
      },
      infiniteLoad: true
    },
    { skip: !accountId }
  );

  const {
    data: episodeListResponse,
    isLoading: isLoadingEpisodes,
    isFetching: isFetchingEpisodes
  } = useGetEpisodeListQuery(
    {
      clientRecordId,
      timezone: accountTimeZone,
      params: {
        page: episodeListCurrentPage,
        perPage: EPISODE_PER_PAGE,
        status: [EpisodeStatus.Finished, EpisodeStatus.Cancelled].join(',')
      }
    },
    { skip: !clientRecordId }
  );

  const {
    data: referralListResponse,
    isLoading: isLoadingReferral,
    isFetching: isFetchingReferral
  } = useGetReferralListQuery(
    {
      accountId,
      params: {
        page: referralListCurrentPage,
        perPage: REFERRAL_PER_PAGE,
        clientRecordIds: clientRecordId
      },
      infiniteLoad: true
    },
    { skip: !accountId || !clientRecordId }
  );

  const { data: activeEpisode } = useGetCurrentActiveEpisodeQuery(
    {
      clientRecordId,
      timezone: accountTimeZone
    },
    { skip: !clientRecordId }
  );

  const addAnotherPackage = () => {
    setSelectedPackage((prevState) => [
      ...prevState,
      {
        packageId: '',
        funderId: ''
      }
    ]);
  };

  const updatePackage = (index: number, key: string, value: string) => {
    setSelectedPackage((prevState) => {
      const updatedPackages = [...prevState];
      updatedPackages[index] = {
        ...updatedPackages[index],
        [key]: value
      };
      return updatedPackages;
    });
  };

  const handleRemovePackage = (index: number) => {
    setSelectedPackage((prevState) => {
      return prevState.filter((_, idx) => idx !== index);
    });
  };

  const validateSelectedPackage = async (selectedPackage: SelectedPackage[]): Promise<boolean> => {
    try {
      await selectedPackageSchema.validate(selectedPackage, { abortEarly: false });
      return true;
    } catch (error) {
      console.error('Validation failed:', error);
      return false;
    }
  };

  const totalPackageListPage = packageFunderData?.paging
    ? Math.ceil(packageFunderData.paging.totalItems / PACKAGE_PER_PAGE)
    : 1;

  const loadMorePackageItem = useCallback(() => {
    const page = packageFunderData?.paging.page || 1;
    if (!packageFunderDataFetching && page < totalPackageListPage) {
      setPackageListCurrentPage(page + 1);
    }
  }, [totalPackageListPage, packageFunderDataFetching, packageFunderData]);

  const totalEpisodeListPage = episodeListResponse?.paging
    ? Math.ceil(episodeListResponse.paging.totalItems / EPISODE_PER_PAGE)
    : 1;

  const loadMoreEpisodeItem = useCallback(() => {
    const page = episodeListResponse?.paging.page || 1;
    if (!isFetchingEpisodes && page < totalEpisodeListPage) {
      setEpisodeListCurrentPage(page + 1);
    }
  }, [totalEpisodeListPage, isFetchingEpisodes, episodeListResponse]);

  const episodeOptions = useMemo(
    () =>
      generateClientProfileDropdownOptions({
        activeEpisode,
        episodes: episodeListResponse?.episodes,
        noDefaultValue: true
      }),
    [activeEpisode, episodeListResponse]
  );

  const totalReferralListPage = referralListResponse?.paging
    ? Math.ceil(referralListResponse.paging.totalItems / REFERRAL_PER_PAGE)
    : 1;

  const loadMoreReferralItem = useCallback(() => {
    const page = referralListResponse?.paging.page || 1;
    if (!isFetchingReferral && page < totalReferralListPage) {
      setReferralListCurrentPage(page + 1);
    }
  }, [totalReferralListPage, isFetchingReferral, referralListResponse]);

  const handleAssignPackage = async () => {
    setCheckValidation(true);
    const keysAreNotEmpty = await validateSelectedPackage(selectedPackage);

    if (keysAreNotEmpty) {
      setSubmitStatus('active');
      try {
        const massagePayload: AssignPackagesInterface[] = selectedPackage?.map((assignObj) => {
          const getFunderDetails = packageFunderData?.packages
            .find((packageObj) => packageObj._id === assignObj.packageId)
            ?.funders.find((funderObj) => funderObj.funderId === assignObj.funderId);
          return {
            packageId: assignObj.packageId,
            funder: {
              type: getFunderDetails?.type,
              ...(getFunderDetails?.type === funderType.AddressBook && {
                funderId: getFunderDetails.funderId
              }),
              ...(getFunderDetails?.type === funderType.Default && {
                name: getFunderDetails.name
              })
            },
            ...(assignObj.episodeId && {
              episodeId: assignObj.episodeId
            }),
            ...(assignObj.referralId && {
              referralId: assignObj.referralId
            })
          };
        });

        const assignRes = await assignPackage({
          accountId,
          clientRecordId: clientRecordId,
          payload: massagePayload
        }).unwrap();

        const failedAssignments = assignRes.filter(({ successfullyAssigned }) => !successfullyAssigned);

        setSubmitStatus('finished');

        if (failedAssignments.length > 0) {
          notification.error({
            message: 'Some packages failed to be assigned',
            description: (
              <ol className={styles.failDescWrapper}>
                {failedAssignments.map((assignObj, idx) => {
                  const getPackageDetails = packageFunderData?.packages.find(
                    (pckObj) => pckObj._id === assignObj.packageId
                  );
                  const getFunderDetails = getPackageDetails?.funders?.find(
                    (funderObj) => funderObj.funderId === assignObj.funder.funderId
                  );
                  return (
                    <li key={idx}>
                      <div className={styles.failTitle}>
                        {getPackageDetails?.name} - {getFunderDetails?.name}
                      </div>
                      <div className={styles.failDesc}>Reason: {assignObj.failedToAssignReason}</div>
                    </li>
                  );
                })}
              </ol>
            )
          });
        } else {
          setPackageListCurrentPage(1);
          notification.success({
            message: 'Packages successfully assigned'
          });
        }

        setTimeout(() => {
          setSubmitStatus('');
          setSelectedPackage([
            {
              packageId: '',
              funderId: ''
            }
          ]);
          packageFunderDataRefetch();
          setCheckValidation(false);
          onClose();
        }, 1000);
      } catch (ex) {
        console.error(ex);
        setSubmitStatus('');
        notification.error({
          message: 'Something went wrong while assigning to package.'
        });
      }
    }
  };

  return (
    <Modal
      key={'availablePackageModal'}
      className={styles.modalContainer}
      open={isVisible}
      onCancel={onClose}
      bodyStyle={{ padding: '16px 24px' }}
      title="Add New Packages"
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[
        <div className={styles.btnWrapper}>
          <ButtonAlt status={submitStatus} size={'medium'} onClick={handleAssignPackage}>
            Save Package Assignment
          </ButtonAlt>
        </div>
      ]}
    >
      <div className={styles.content}>
        {selectedPackage.map((assignPackageObj, idx) => (
          <div key={idx} className={styles.assignWrapper}>
            <div className={styles.fieldWrapper}>
              <DropdownSearchable
                className={styles.input}
                placeholder="Select Package"
                options={
                  packageFunderData?.packages.map((item) => ({
                    value: item._id || '',
                    label: item.name
                  })) || []
                }
                loadMore={loadMorePackageItem}
                hasMoreData={
                  (packageFunderData?.packages.length || 0) < (packageFunderData?.paging.totalItems || 0) ||
                  packageFunderDataFetching ||
                  packageFunderDataLoading
                }
                selected={assignPackageObj.packageId}
                onSelect={(value) => updatePackage(idx, 'packageId', value)}
                error={!assignPackageObj.packageId && checkValidation ? 'Please select Package' : ''}
              />
              <div className={styles.divider} />
              <DropdownSearchable
                className={styles.input}
                placeholder="Select Funder"
                options={
                  packageFunderData?.packages
                    .find((packageObj) => packageObj._id === assignPackageObj.packageId)
                    ?.funders.map((item) => ({
                      value: item.funderId || '',
                      label: item.name,
                      ...(item.quotaStatus && {
                        tooltip:
                          item.quotaStatus !== PackageFunderQuotaStatus.Available ? 'Selection quota exceeded' : '',
                        disabled: item.quotaStatus !== PackageFunderQuotaStatus.Available
                      })
                    })) || []
                }
                selected={assignPackageObj?.funderId}
                onSelect={(value) => updatePackage(idx, 'funderId', value)}
                error={
                  !assignPackageObj.packageId && checkValidation
                    ? 'Please select a package first'
                    : !assignPackageObj?.funderId && checkValidation
                    ? 'Please select Funder'
                    : ''
                }
              />
              <div className={styles.divider} />
              <DropdownSearchable
                className={styles.input}
                placeholder="Episode link"
                options={episodeOptions}
                loadMore={loadMoreEpisodeItem}
                hasMoreData={
                  (episodeListResponse?.episodes.length || 0) < (episodeListResponse?.paging.totalItems || 0) ||
                  isFetchingEpisodes ||
                  isLoadingEpisodes
                }
                selected={assignPackageObj?.episodeId}
                onSelect={(value) => updatePackage(idx, 'episodeId', value)}
                DropdownItemChildren={(itemProps) => (
                  <ClientProfileEpisodeDropdownItem
                    className={styles.episodeDropdownItem}
                    episode={itemProps.props}
                    value={itemProps.props.value}
                  />
                )}
                DisplayLabelChildren={(itemProps) => <div>Episode (#{itemProps.props.order})</div>}
              />
              <div className={styles.divider} />
              <DropdownSearchable
                className={styles.input}
                placeholder="Referral link"
                options={
                  referralListResponse?.referrals.map((referralObj) => ({
                    referralInfo: referralObj,
                    label: `${referralObj.referrer.name} ${referralObj.referralId}`,
                    value: referralObj._id || ''
                  })) || []
                }
                loadMore={loadMoreReferralItem}
                hasMoreData={
                  (referralListResponse?.referrals.length || 0) < (referralListResponse?.paging.totalItems || 0) ||
                  isFetchingReferral ||
                  isLoadingReferral
                }
                selected={assignPackageObj?.referralId}
                onSelect={(value) => updatePackage(idx, 'referralId', value)}
                itemClassName={styles.referralDropdown}
                DropdownItemChildren={(itemProps, index) => (
                  <div key={index}>
                    <div className={styles.referralName}>{getReferralName(itemProps.props.referralInfo)}</div>
                    <div className={styles.referralId}>{itemProps.props.referralInfo.referralId}</div>
                  </div>
                )}
                DisplayLabelChildren={(itemProps) => <div>{getReferralName(itemProps.props.referralInfo)}</div>}
              />
            </div>
            <div className={styles.removeWrapper}>
              <i onClick={() => handleRemovePackage(idx)} className={`material-icons-outlined ${styles.binIcon}`}>
                delete
              </i>
            </div>
          </div>
        ))}
        <div className={styles.addPackageBtnWrapper}>
          <ButtonAlt
            disabled={submitStatus !== ''}
            size={'medium'}
            variant={'outlined'}
            icon={'add_circle_outline'}
            onClick={addAnotherPackage}
          >
            Add another package
          </ButtonAlt>
        </div>
      </div>
    </Modal>
  );
};

export default PackageAssignmentModal;
