import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  AddressBookTab,
  resetPaging,
  selectActiveTab,
  selectFilters,
  setActiveTab,
  setFilters
} from 'redux/addressBook/addressBookSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './AddressBookList.module.scss';
import AddressBookListContent from './components/AddressBookListContent/AddressBookListContent';
import { defaultAddressBookFilters } from './components/AddressBookListContent/components/constants';

const AddressBookList = () => {
  const dispatch = useAppDispatch();
  const path = useParams<{ tabId: AddressBookTab }>();
  const filters = useAppSelector(selectFilters);
  const activeTab = useAppSelector(selectActiveTab);

  const tabId = path.tabId ?? AddressBookTab.Funder;

  const setFilterByTab = useCallback(() => {
    dispatch(resetPaging());

    const filtersByTab = defaultAddressBookFilters[tabId];
    dispatch(setFilters({ ...filters, ...filtersByTab }));
  }, [dispatch, filters, tabId]);

  const onChangeTabFilter = useCallback(
    async (tabValue: AddressBookTab) => {
      dispatch(setActiveTab(tabValue));
      setFilterByTab();
    },
    [dispatch, setFilterByTab]
  );

  useEffect(() => {
    if (tabId !== activeTab) {
      onChangeTabFilter(tabId);
    }
  }, [onChangeTabFilter, tabId, activeTab]);

  return (
    <HelmetWrapper title={'Tacklit - Address Book'}>
      <ContentLayout>
        <div className={styles.container}>
          <AddressBookListContent />
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default AddressBookList;
