import { Table } from 'antd';
import classNames from 'classnames';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import moment from 'moment';
import { ReactNode, useMemo } from 'react';
import { PatientActivityActor } from '../../../PatientDetailsActivity/components/ActivityFeed/ActivityFeed';
import { isAppointmentStatusCancelled } from '../../utils';
import styles from './AppointmentsListItem.module.scss';

const activityFeedsColumns = [
  {
    title: 'VARIATION',
    dataIndex: 'variation',
    key: 'variation'
  },
  {
    title: 'DETAIL',
    dataIndex: 'detail',
    key: 'detail',
    render: (detail: string) => <div dangerouslySetInnerHTML={{ __html: detail }} />
  },
  {
    title: 'CHANGE MADE',
    dataIndex: 'changeMade',
    key: 'changeMade',
    render: (changeMade: string) => <time dateTime={changeMade}>{moment(changeMade).format('DD/MM/YYYY')}</time>
  },
  {
    title: 'MADE BY',
    dataIndex: 'madeBy',
    key: 'madeBy'
  }
];

export const getActorName = ({
  actor,
  clientName = 'Unknown Client Name',
  clinicianName,
  practiceName
}: {
  actor: PatientActivityActor;
  clientName?: string;
  clinicianName: string;
  practiceName: string;
}) => {
  if (actor === 'clinician') {
    return clinicianName ?? practiceName;
  } else if (actor === 'patient') {
    return clientName;
  } else if (actor === 'system') {
    return 'System';
  }
  return 'Unknown Actor';
};

interface AppointmentsListItemProps {
  appointment: AppointmentSlots;
  children: ReactNode;
  /** If true, left and right padding are removed. */
  disableGutters?: boolean;
  isNextEvent?: boolean;
  practiceName: string;
  showExpandedView: boolean;
}

const AppointmentsListItem = ({
  appointment,
  children,
  disableGutters,
  isNextEvent,
  practiceName,
  showExpandedView
}: AppointmentsListItemProps) => {
  const getTime = (time: string) => moment(appointment.date + ' ' + time).format('h:mmA');
  const timeRange = `${getTime(appointment.startTime)} - ${getTime(appointment.endTime)}`;
  const date = moment(appointment.date).format('dddd, Do MMMM YYYY');
  const appointmentCancelled = isAppointmentStatusCancelled(appointment.status);
  const statusColor = appointmentCancelled ? styles.cancelledColor : null;
  const statusIcon = appointmentCancelled ? 'event_busy' : appointment.isActivity ? 'pending_actions' : 'event';

  const dataSource = useMemo(
    () =>
      appointment.activityFeeds?.map((activity, index) => ({
        key: activity._id,
        variation: index + 1,
        detail: activity.description,
        changeMade: activity.createdAt,
        madeBy: getActorName({
          actor: activity.actor,
          clientName: appointment.clientRecord?.clientProfiles[0].name,
          clinicianName: activity.clinicianName,
          practiceName
        })
      })),
    [appointment.activityFeeds, appointment.clientRecord?.clientProfiles, practiceName]
  );

  return (
    <div className={classNames(styles.container, { [styles.disableGutters]: disableGutters })}>
      <div className={styles.eventIconWrapper}>
        <i className={classNames('material-icons-outlined', styles.eventIcon, statusColor)}>{statusIcon}</i>
      </div>
      <div className={styles.contentWrapper}>
        {isNextEvent && <div className={styles.nextEventLabel}>NEXT</div>}
        <div className={classNames(styles.content, { [styles.nextEvent]: isNextEvent })}>
          <div className={styles.simplifiedView}>
            <div className={styles.appointmentTime}>
              <time>{timeRange}</time> | <time dateTime={appointment.date}>{date}</time>
            </div>
            {children}
          </div>
          <div className={classNames(styles.expandedView, { [styles.visible]: showExpandedView })}>
            <Table
              className={styles.activityFeedsTable}
              columns={activityFeedsColumns}
              dataSource={dataSource}
              pagination={false}
            />
            <div className={styles.appointmentId}>Appt ID: {appointment._id}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsListItem;
