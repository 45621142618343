import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import styles from './CarePathwayModal.module.scss';
import FilterWrapper from './components/FilterWrapper/FilterWrapper';
import CarePlanCalendar from './components/CarePlanCalendar/CarePlanCalendar';
import Sidebar from './components/Sidebar/Sidebar';
import Footer from './components/Footer/Footer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  initialCarePathwayState,
  selectCarePlanEditorSlice,
  setCarePlanData
} from 'redux/carePlan/carePlanEditorSlice';
import { useGetCarePathwayQuery } from 'redux/endpoints/clinicianProfileServices/carePathway';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useEffect, useState } from 'react';
import { Modal, Skeleton } from 'antd';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { clientRecordsApiSlice } from 'redux/endpoints/clinicianProfileServices/client';
import { CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';

interface CarePathwayModalProps {
  visible: boolean;
  onCloseModal: () => void;
  carePathWayId?: string;
}

const CarePathwayModal = ({ visible, onCloseModal, carePathWayId }: CarePathwayModalProps) => {
  const { accountId } = useGetAccountId();
  const dispatch = useAppDispatch();
  const carePlanData = useAppSelector(selectCarePlanEditorSlice);
  const [pathwayId, setPathwayId] = useState('');
  const [confirmExitModal, setConfirmExitModal] = useState(false);

  useEffect(() => {
    if (visible && carePathWayId) {
      setPathwayId(carePathWayId);
    }
  }, [visible, carePathWayId]);

  const {
    data: pathwayData,
    isLoading: pathwayDataLoading,
    isFetching
  } = useGetCarePathwayQuery(
    {
      accountId: accountId,
      carePathwayId: pathwayId || ''
    },
    { skip: !pathwayId }
  );

  useEffect(() => {
    if (visible) {
      if (pathwayId) {
        if (!pathwayDataLoading && !isFetching) {
          dispatch(setCarePlanData(pathwayData));
        }
      } else {
        dispatch(setCarePlanData(initialCarePathwayState));
      }
    }
  }, [pathwayData, pathwayDataLoading, isFetching, dispatch, pathwayId, visible]);

  const handleExitModal = () => {
    if (carePlanData._id) {
      setConfirmExitModal(true);
    } else {
      dispatch(clientRecordsApiSlice.util.invalidateTags([CPSTagTypes.CarePathway]));
      onCloseModal();
    }
  };

  return (
    <FullScreenModal
      open={visible}
      onClose={handleExitModal}
      title="Care Pathway Creator"
      subtitle={carePlanData?.shortCode && `Plan ID: ${carePlanData.shortCode}`}
    >
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.filterWrapper}>
            <FilterWrapper />
          </div>
          {pathwayDataLoading ? (
            <div className={styles.calendarLoading}>
              <Skeleton active />
            </div>
          ) : (
            <>{carePlanData._id && <CarePlanCalendar />}</>
          )}
        </div>
        <div className={styles.sideBarContainer}>{pathwayDataLoading ? <></> : carePlanData._id && <Sidebar />}</div>
      </div>
      <Footer onCloseModal={onCloseModal} />
      <Modal
        title={
          <div className={styles.confirmationContainer}>
            <div className={styles.messageContainer}>
              <i className={`material-symbols-outlined ${styles.messageIcon}`}>error</i>
              <div>
                <div>Are you sure you want to close this?</div>
                <div>Kindly ensure that you save the data before exiting.</div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <ButtonAlt onClick={() => setConfirmExitModal(false)} size={'small'} variant={'outlined'}>
                Cancel
              </ButtonAlt>
              <ButtonAlt
                onClick={() => {
                  setConfirmExitModal(false);
                  setTimeout(() => {
                    dispatch(clientRecordsApiSlice.util.invalidateTags([CPSTagTypes.CarePathway]));
                    onCloseModal();
                  }, 300);
                }}
                size={'small'}
              >
                OK
              </ButtonAlt>
            </div>
          </div>
        }
        open={confirmExitModal}
        footer={null}
        className={styles.confirmationModal}
        bodyStyle={{ display: 'none' }}
        closable={false}
      />
    </FullScreenModal>
  );
};

export default CarePathwayModal;
