import styles from './GroupCaseNoteAttachmentDisplay.module.scss';
import { CaseNote } from 'interfaces/caseNote';
import { notification } from 'antd';
import mime from 'mime';
import { downloadAttachmentByGroupId } from 'utils/http/DocumentService/Attachments/groupAttachment';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface GroupCaseNoteAttachmentDisplayProps {
  token: string;
  groupId: string;
  caseNoteAttachmentData: CaseNote['attachments'];
}

const GroupCaseNoteAttachmentDisplay = ({
  token,
  groupId,
  caseNoteAttachmentData
}: GroupCaseNoteAttachmentDisplayProps) => {
  const handleDownloadAttachment = async (attachment: CaseNote['attachments'][number]) => {
    try {
      const callGetAttachmentById = await downloadAttachmentByGroupId(token, groupId, attachment._id);

      const downloadedAttachment = await callGetAttachmentById.arrayBuffer();

      const documentBlob = new Blob([downloadedAttachment], {
        type: mime.getType(attachment.title) || 'application/octet-stream'
      });

      const anchor = document.createElement('a');

      anchor.href = URL.createObjectURL(documentBlob);
      anchor.download = attachment.title;
      anchor.click();
      anchor.remove();
    } catch (ex) {
      console.error(ex);

      notification.error({ message: 'Something went wrong while trying to download this attachment.' });
    }
  };

  return caseNoteAttachmentData.length > 0 ? (
    <div className={styles.attachmentsContainer}>
      <>
        <div className={styles.title}>ATTACHMENTS</div>
        {caseNoteAttachmentData.map((attachment) => (
          <div className={styles.attachmentContainer} key={attachment._id}>
            <i className={`material-icons-outlined ${styles.icon}`}>attach_file</i>
            {attachment.title}
            <ButtonAlt
              className={styles.viewButton}
              size={'small'}
              variant="text"
              onClick={() => handleDownloadAttachment(attachment)}
            >
              view
            </ButtonAlt>
          </div>
        ))}
      </>
    </div>
  ) : null;
};

export default GroupCaseNoteAttachmentDisplay;
