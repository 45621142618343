import styles from './GroupCard.module.scss';
import { useFetchGroupDetails } from 'pages/Groups/GroupDetails/hooks/getGroupDetails';
import { Link } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { Skeleton } from 'antd';
import moment from 'moment';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface GroupCardProps {
  groupId: string;
  sessionId: string;
  label?: string;
  hideEditGroupBtn?: boolean;
}

const GroupCard = ({ groupId, sessionId, label, hideEditGroupBtn }: GroupCardProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { CARECOORDINATION } = useRoutesGenerator();
  const { groupDetails, isGroupDetailsLoading } = useFetchGroupDetails(token, groupId, accountId);

  return isGroupDetailsLoading || !groupDetails ? (
    <div>
      <Skeleton active />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.groupLabelWrapper}>
        {label ? (
          <div className={styles.groupLabel}>{label}</div>
        ) : (
          <div className={styles.noGroupLabel}>No label added</div>
        )}
        {!hideEditGroupBtn && (
          <Link
            className={styles.link}
            to={`${CARECOORDINATION.GROUPS}/${groupDetails._id}/sessionDetail/${sessionId}`}
          >
            View session details
            <i className={`material-icons ${styles.arrowIcon}`}>arrow_forward_ios</i>
          </Link>
        )}
      </div>
      <div className={styles.groupDetailWrapper}>
        <div className={styles.leftContent}>
          <div className={styles.groupTitleWrapper}>
            <div className={styles.groupTitle}>{groupDetails.name}</div>
            <Link className={styles.link} to={`${CARECOORDINATION.GROUPS}/${groupDetails._id}/details`}>
              <ButtonAlt size={'medium'} variant={'text'} icon={'arrow_forward_ios'} iconPostFix>
                View group details
              </ButtonAlt>
            </Link>
          </div>
          <div className={styles.groupValue}>Created by: {moment(groupDetails.createdAt).format('DD MMM YYYY')}</div>
          <div className={styles.groupValue}>Total Active Member: {groupDetails.clientCount}</div>
        </div>
        <div className={styles.rightContent}>
          <div className={styles.assignedLabel}>SESSION FACILITATOR</div>
          {groupDetails.clinicianProfiles.length > 0 ? (
            groupDetails.clinicianProfiles.map((cPObj, index) => (
              <div key={index} className={styles.facilitatorCard}>
                <img alt={`clinicianAvatar${index}`} src={cPObj.avatar} className={styles.facilitatorAvatar} />
                <div className={styles.facilitatorName}>{cPObj.name}</div>
              </div>
            ))
          ) : (
            <div>No specific facilitator designated</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupCard;
