import { CaseNote } from 'interfaces/caseNote';
import mentorDefaultFormatSettingsFields from './mentorDefaultFormatSettingsFields.json';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { CaseNoteFormatSettingsFields } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';

export const SESSION_FEEDBACK_TOOLBAR = [
  ['bold', 'italic', 'underline'],
  [{ color: [] }, { list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
  ['image']
];

export const SAFEGUARDING_OBJ: CaseNote['fields'][number] = {
  _id: 'safeGuardingObj',
  name: 'Safeguarding concern',
  type: 'multipleChoice',
  options: [
    {
      label: '',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' }
      ]
    }
  ],
  mandatory: true,
  value: ''
};

export const initSessionFeedbackField = ({ hasBazaar }: { hasBazaar: boolean }) =>
  (mentorDefaultFormatSettingsFields.fields as CaseNoteFormatSettingsFields[])
    .filter(({ _id }) => _id !== 'bazaarChapters' || hasBazaar)
    .map(
      ({
        _id,
        name,
        type,
        linkType,
        choiceType,
        commonChoices,
        customChoices,
        customCommonChoices,
        active,
        mandatory,
        multiple
      }) => {
        if (!active) {
          return undefined;
        }

        const field: Record<string, any> = {
          _id: _id && _id.length > 0 ? _id : uuidv4(),
          name,
          type,
          mandatory
        };

        if (_id === 'dateOfSession') {
          field['value'] = moment();
        }
        if (type === 'link') {
          field.linkType = linkType;
        } else if (type === 'multipleChoice' || type === 'multipleChoiceCount') {
          let options: Record<string, any>[] = [];

          if (choiceType === 'common') {
            options = commonChoices?.concat(customCommonChoices || []) || [];
          } else {
            options = customChoices || [];
          }

          field.multiple = multiple;
          field.options = options;
        }

        return field;
      }
    )
    .filter((field) => !!field);
