import { configInterface } from './config';
import * as constants from '../pages/Calendar/components/CalendarSettings/components/AccountLevelSettings/constants';
import AUTerms from 'pages/SignUp/components/Data/components/AUTerms';
import AUPrivacy from 'pages/SignUp/components/Data/components/AUPrivacy';

export const configNZ: configInterface = {
  countryCode: 'nz',
  mobileNumberMinLength: 10,
  mobileNumberMaxLength: 20,
  moneyIcon: 'monetization_on',
  currencySymbol: '$',
  currencyCode: 'nzd',
  taxLabel: 'TAX',
  taxRateLabel: 'GST',
  mobileCountryCode: '64',
  codeLabel: '', //NZ need to hide this
  defaultTimezone: 'Pacific/Auckland',
  practiceSettings: {
    registrationNumberLabel: 'Company Number',
    publicHolidayOption: constants.NZ_PUBLIC_HOLIDAY_CALENDAR_OPTIONS
  },
  terms: {
    tnc: <AUTerms />,
    privacy: <AUPrivacy />
  }
};
