import { Report } from 'interfaces/Reports/report';

import styles from './SelectGroupOrClientModalBox.module.scss';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { t } from 'i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface SelectGroupOrClientModalBoxProps {
  clientRecord?: Report['clientRecord'];
  selectedGroup?: GroupsFromAPI;
  participationType?: ParticipantType;
  onClickSelectUser?: () => void;
}

const SelectGroupOrClientModalBox = ({
  clientRecord,
  selectedGroup,
  participationType,
  onClickSelectUser
}: SelectGroupOrClientModalBoxProps) => {
  return (
    <div className={styles.container}>
      {selectedGroup ? (
        <div className={styles.selectedGroupContainer}>
          <div className={styles.groupName}>{selectedGroup?.name}</div>
          <div className={styles.groupCount}>{selectedGroup?.clientCount} Active Members</div>
        </div>
      ) : clientRecord ? (
        <ClientAvatar
          clientData={clientRecord?.clientProfiles}
          avatarSize={50}
          nameClassName={styles.name}
          displayFirstNameOnly={clientRecord.recordType === 'couple'}
          displayLimit={clientRecord.recordType === 'couple' ? 2 : 1}
          fullAvatarBg
          horizontal
        />
      ) : (
        <>
          <div className={styles.noImage} />
          <span className={styles.noName}>Not selected</span>
        </>
      )}
      {onClickSelectUser && (
        <div className={styles.alignEnd}>
          <div onClick={onClickSelectUser}>
            <ButtonAlt useDivAsBtn className={styles.button} variant={'text'}>
              <>{participationType === ParticipantType.Group ? t('button.change_group') : t('button.change_client')}</>
            </ButtonAlt>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectGroupOrClientModalBox;
