import styles from './InviteComplete.module.scss';
import { PractitionerInviteInterface } from '../../AddPractitionerModalInterface';
import { PRACTITIONER_ROLES } from '../PractitionerForm/constants';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useTranslation } from 'react-i18next';

interface ClientCreationProps {
  selectedRole: AccessRight;
  practitionerDetail: PractitionerInviteInterface;
  onResetForm: () => void;
}

const InviteComplete = ({ practitionerDetail, selectedRole, onResetForm }: ClientCreationProps) => {
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      {practitionerDetail.emailsSent.length > 0 && (
        <div>
          <div className={styles.label}>{practitionerDetail.emailsSent.length} email(s) successfully sent.</div>
          <div className={styles.emailList}>
            {practitionerDetail.emailsSent.map((obj, idx) => (
              <div className={styles.emailSuccessItem} key={idx}>
                {`${t(PRACTITIONER_ROLES[selectedRole].label)} - ${obj}`}
              </div>
            ))}
          </div>
        </div>
      )}
      {practitionerDetail.emailsRegistered.length > 0 && (
        <div>
          <div className={styles.label}>{practitionerDetail.emailsRegistered.length} registered email(s) found.</div>
          <div className={styles.emailList}>
            {practitionerDetail.emailsRegistered.map((obj, idx) => (
              <div className={styles.emailRegisteredItem} key={idx}>
                {obj}
              </div>
            ))}
          </div>
        </div>
      )}
      {practitionerDetail.emailsFailed.length > 0 && (
        <div>
          <div className={styles.label}>{practitionerDetail.emailsFailed.length} email(s) invite failed.</div>
          <div className={styles.emailList}>
            {practitionerDetail.emailsFailed.map((obj, idx) => (
              <div className={styles.emailFailedItem} key={idx}>
                {obj}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={styles.resetBtnWrapper}>
        <div className={styles.createNewPractitionerBtn} onClick={onResetForm}>
          <i className={`material-icons`}>add_circle_outline</i>
          <div className={styles.newClientLabel}>{t('title.invite_another_practitioner')}</div>
        </div>
      </div>
    </div>
  );
};

export default InviteComplete;
