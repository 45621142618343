import { PaymentMethodsInterface } from 'pages/Invoices/interface';
import { BSTagTypes, billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';

export const paymentMethodsApiSlice = billingServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentMethods: builder.query<PaymentMethodsInterface, void>({
      query: () => ({
        url: '/payment-methods'
      }),
      providesTags: [BSTagTypes.PaymentMethods]
    })
  })
});

export const { useGetPaymentMethodsQuery } = paymentMethodsApiSlice;
