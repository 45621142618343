import { SSTagTypes, scheduleServicesApiSlice } from '../../services/scheduleServicesApiSlice';
import { AppointmentType, AssignmentMode, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { AppointmentTypeAvailabilities } from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import queryString from 'query-string';
import { mapUtcToClinicianTimeInTimeSlots } from 'utils/helpers/timezone';

export interface AppointmentTypesPaging {
  page: number;
  perPage: number;
  totalItems: number;
}

export interface GetAppointmentTypesResponse {
  data: AppointmentType[];
  paging: AppointmentTypesPaging;
}

export interface GetAppointmentTypesPayload {
  name?: string;
  participantType?: string;
  deliveryModes?: string;
  isActive?: string;
  assignedClinicianId?: string;
  page?: number;
  perPage?: number;
  infiniteLoad?: boolean;
}

export interface GetAppointmentTypeForInlineAppointmentRequest {
  clinicianId: string;
  participantType: ParticipantType;
  isAdmin: boolean;
}

export interface GetAppointmentTypeAvailableSlotsRequest {
  id?: string;
  from: string;
  to: string;
  type: 'booking' | 'reschedule';
  appointmentTypeId: string;
  clinicianId: string;
  selectedRoomId?: string;
  groupId?: string;
  timeZone?: string;
}

export const DEFAULT_APPOINTMENT_TYPES_PER_PAGES = 100;

export const appointmentApiSlice = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppointmentTypes: builder.query<GetAppointmentTypesResponse, GetAppointmentTypesPayload>({
      query: (payload) => ({
        url: `/session-types?${payload ? queryString.stringify(payload) : ''}`
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        queryArgs?.infiniteLoad
          ? `${endpointName}_${queryArgs?.name || ''}`
          : `${endpointName}_${queryString.stringify(queryArgs || {})}`,
      merge: (currentCache, newItems, meta) => {
        if (meta.arg.infiniteLoad) {
          if (meta.arg.page === 1) {
            return {
              data: newItems.data,
              paging: newItems.paging
            };
          }

          return {
            data: [...currentCache.data, ...newItems.data],
            paging: newItems.paging
          };
        } else {
          return {
            data: newItems.data,
            paging: newItems.paging
          };
        }
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg?.page !== previousArg?.page && currentArg?.page !== 1,
      providesTags: [SSTagTypes.AppointmentTypes]
    }),

    getAppointmentTypesForInlineAppointment: builder.query<
      { data: AppointmentType[] },
      GetAppointmentTypeForInlineAppointmentRequest
    >({
      query: ({ clinicianId, participantType, isAdmin }) => {
        const queryParamsStr = queryString.stringify({
          participantType,
          ...((!isAdmin || !clinicianId) && {
            assignmentMode: participantType === ParticipantType.Group ? AssignmentMode.All : AssignmentMode.Practice
          })
        });

        return {
          url: `${isAdmin && clinicianId ? `/clinician/${clinicianId}` : ''}/session-types${
            queryParamsStr ? `?${queryParamsStr}` : ''
          }`
        };
      },
      transformResponse: (response: { data: AppointmentType[] }) => {
        return {
          data: response.data
            .filter((appointmentType) => appointmentType.isActive)
            .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))
        };
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.clinicianId !== previousArg?.clinicianId ||
        currentArg?.isAdmin !== previousArg?.isAdmin ||
        currentArg?.participantType !== previousArg?.participantType,
      providesTags: [SSTagTypes.AppointmentTypesForInlineAppointment]
    }),

    getAppointmentTypeAvailableSlots: builder.query<
      AppointmentTypeAvailabilities,
      GetAppointmentTypeAvailableSlotsRequest
    >({
      query: ({ from, to, type, appointmentTypeId, clinicianId, selectedRoomId, groupId }) => {
        const query = queryString.stringify({
          clinicianId,
          date: from,
          to,
          type,
          selectedRoomId,
          groupId
        });
        return {
          url: `/appointment-types/${appointmentTypeId}/availabilities?${query ?? ''}`
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        `${endpointName}_${queryArgs?.clinicianId || ''}_${queryArgs?.appointmentTypeId || ''}_${
          queryArgs?.type || ''
        }_${queryArgs?.selectedRoomId || ''}_${queryArgs?.groupId || ''}_${queryArgs?.id || ''}`,
      merge: (currentCache, newItems) => ({
        appointmentType: currentCache.appointmentType,
        timeSlots: {
          ...currentCache.timeSlots,
          ...newItems.timeSlots
        }
      }),
      transformResponse: (response: AppointmentTypeAvailabilities, _meta, arg) => ({
        ...response,
        timeSlots: {
          ...response.timeSlots,
          ...(arg.timeZone ? mapUtcToClinicianTimeInTimeSlots(response.timeSlots, arg.timeZone) : response.timeSlots)
        }
      }),
      forceRefetch: ({ currentArg, previousArg }) => currentArg?.from !== previousArg?.from
    }),

    getAppointmentTypeById: builder.query<AppointmentType, { id: string }>({
      query: ({ id }) => ({
        url: `/session-types/${id}`
      }),
      providesTags: [SSTagTypes.AppointmentTypeById]
    })
  })
});

export const {
  useGetAppointmentTypesQuery,
  useGetAppointmentTypesForInlineAppointmentQuery,
  useGetAppointmentTypeAvailableSlotsQuery,
  useGetAppointmentTypeByIdQuery
} = appointmentApiSlice;
