import { Skeleton } from 'antd';
import { useFetchSignedUpProfile } from 'components/EditClientModal/hooks/CheckSignUpProfile';
import { QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';
import { clientRecordsInterface, ClientRecordType } from 'interfaces/Clients/clientsRecord';
import { SubTabContext } from 'layouts/AuthLayoutT23/context/SubTabContextProvider';
import { useFetchCommonODFVariable } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/hooks/useFetchCommonODFVariable';
import { useContext, useMemo, useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import ClientDataListItem from './components/ClientDataListItem/ClientDataListItem';
import { HIDE_FOR_CHILD, PHONE_NUMBER_VARIABLE } from './constants';
import { useFetchClientData } from './hooks/getClientData';
import styles from './PatientDetailsClientData.module.scss';
import { useFetchOpenDataCategories } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/hooks/useFetchOpenDataCategories';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import {
  CLASSIFICATION_OPTIONS,
  ClassificationTypes
} from '../../../../../ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface PatientDetailsClientDataProps {
  clientRecord: clientRecordsInterface;
  classification: ClassificationTypes;
}

const PatientDetailsClientData = ({ clientRecord, classification }: PatientDetailsClientDataProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { scaleTypes, isCommonVariablesLoading } = useFetchCommonODFVariable();
  const { isHealthDataEnabled } = useGetFeatureToggle();
  const { clientData, isClientDataLoading, fetchClientData } = useFetchClientData(
    token,
    clientRecord._id,
    classification
  );
  const { openDataCategories, isOpenDataCategoriesLoading } = useFetchOpenDataCategories(
    token,
    accountId,
    classification
  );
  const { signedUpProfiles, isSUPLoading } = useFetchSignedUpProfile(token, clientRecord._id);
  const [editingVariable, setEditingVariable] = useState<string>('');
  const { selectedClientProfileId } = useContext(SubTabContext);

  const data = useMemo(() => {
    if (isClientDataLoading || isCommonVariablesLoading || isOpenDataCategoriesLoading) {
      return [];
    }
    const clientProfile = clientRecord.clientProfiles.find((profile) => profile._id === selectedClientProfileId);
    const clientProfileCategoriesVariables = clientData.find(
      (datum) => datum.clientProfileId === selectedClientProfileId
    );

    if (!clientProfile || !clientProfileCategoriesVariables) {
      return [];
    }
    const isChildProfile = clientRecord.recordType === ClientRecordType.Child && clientProfile.role === 'child';

    return (
      clientProfileCategoriesVariables.categories.map((category) => ({
        categoryId: category.categoryId,
        categoryName: category.categoryName,
        classification: category.classification,
        variables:
          category.variables
            .filter((variable) => !isChildProfile || !HIDE_FOR_CHILD.includes(variable.variableId))
            .map((variable) => {
              const foundCategoryQuestion = openDataCategories
                .find(
                  (category) =>
                    category._id === variable.histories[0]?.categoryId || category._id === variable.categoryId
                )
                ?.questions.find(({ variableId }) => variableId === variable.variableId);

              return {
                ...variable,
                ...([QuestionType.multipleChoice, QuestionType.multipleChoiceFreeText].includes(
                  variable.variableType
                ) && {
                  maxSelection: foundCategoryQuestion?.maxSelection,
                  options: foundCategoryQuestion?.options
                }),
                ...(variable.variableType === QuestionType.scale && {
                  options: scaleTypes.find((scale) => scale.scaleType === variable.scaleType)?.options
                })
              };
            }) || []
      })) || []
    );
  }, [
    clientData,
    scaleTypes,
    openDataCategories,
    selectedClientProfileId,
    isClientDataLoading,
    isCommonVariablesLoading,
    isOpenDataCategoriesLoading,
    clientRecord.clientProfiles,
    clientRecord.recordType
  ]);

  return isClientDataLoading || isCommonVariablesLoading || isSUPLoading ? (
    <div className={styles.clientDataContainer}>
      {[...Array(3)].map((obj, i) => (
        <div key={i} className={styles.loadingWrapper}>
          {[...Array(10)].map((obj, j) => (
            <Skeleton.Input key={j} active className={styles.loading} />
          ))}
        </div>
      ))}
    </div>
  ) : (
    <div className={styles.clientDataContainer}>
      {data.map((category) => (
        <div key={`${selectedClientProfileId}_${category.categoryId}`} className={styles.categoryContainer}>
          <div className={styles.categoryHeaderWrapper}>
            <div className={styles.categoryHeaderBox}>
              <div className={styles.categoryHeaderLabel}>Category Name</div>
              <div className={styles.categoryName}>{category.categoryName}</div>
            </div>
            {category.classification && isHealthDataEnabled && (
              <div className={styles.classificationBadge}>
                {
                  CLASSIFICATION_OPTIONS.find(
                    (classificationObj) => classificationObj.value === category.classification
                  )?.label
                }
              </div>
            )}
          </div>
          <div className={styles.clientData}>
            <div className={styles.listHeader}>
              <div className={styles.variableName}>VARIABLE NAME</div>
              <div className={styles.value}>DETAIL</div>
            </div>
            {category.variables.map((item) => (
              <ClientDataListItem
                clientData={item}
                key={`${selectedClientProfileId}_${item.categoryId}_${item.variableId}`}
                clientRecordId={clientRecord._id}
                clientProfileId={selectedClientProfileId}
                disableEmailField={signedUpProfiles.includes(selectedClientProfileId)}
                shouldValidateEmailField={clientRecord.recordType !== ClientRecordType.Child}
                couplePartnerPhoneNumber={
                  PHONE_NUMBER_VARIABLE.includes(item.variableId) && clientRecord.recordType === ClientRecordType.Couple
                    ? clientRecord.clientProfiles.find((profile) => profile._id !== selectedClientProfileId)
                        ?.mobileNumber
                    : ''
                }
                editingVariable={editingVariable}
                fetchClientData={fetchClientData}
                setEditingVariable={setEditingVariable}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PatientDetailsClientData;
