export const getShortOrdinalNumber = (value: number | string) => {
  const numberValue = Number(value);

  if (numberValue === 1 || (numberValue > 20 && numberValue % 10 === 1)) {
    return `${value}st`;
  } else if (numberValue === 2 || (numberValue > 20 && numberValue % 10 === 2)) {
    return `${value}nd`;
  } else if (numberValue === 3 || (numberValue > 20 && numberValue % 10 === 3)) {
    return `${value}rd`;
  } else {
    return `${value}th`;
  }
};

export const getName = (
  clientData: { firstName?: string; lastName?: string; name?: string },
  firstNameOnly?: boolean
): string => {
  const { firstName, lastName, name } = clientData;
  if (firstName && lastName) {
    return firstNameOnly ? firstName : `${firstName} ${lastName}`;
  }
  return name || '';
};

export const filterByProfilesNames =
  (searchValue: string) =>
  ({
    clientProfiles
  }: {
    clientProfiles: { firstName?: string; lastName?: string; name: string }[];
    [key: string]: any;
  }) => {
    const findClientName = clientProfiles.reduce(
      (res, clientProfileObj) =>
        res || getName(clientProfileObj).toLowerCase().includes(searchValue.toLowerCase().trim()),
      false
    );
    return findClientName;
  };

export const filterByMainProfileName =
  (searchValue: string) =>
  ({
    clientProfiles
  }: {
    clientProfiles: { firstName?: string; lastName?: string; name: string }[];
    [key: string]: any;
  }) => {
    return getName(clientProfiles[0]).toLowerCase().includes(searchValue.toLowerCase().trim());
  };

export const combineName = (
  clientProfiles: { firstName?: string; lastName?: string; name?: string }[],
  firstNameOnly?: boolean,
  useSymbol?: boolean
) => {
  return clientProfiles
    .map((val) => getName(val, firstNameOnly))
    .join(', ')
    .replace(/,(?!.*,)/gim, ` ${useSymbol ? '&' : ' and'}`);
};

export const convertStringToTitleCase = (sentence: string) =>
  sentence.replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.substring(1));

export const toWord = (val: string) => {
  if (val) {
    const temp = val.replace(/([A-Z])/g, ' $1');
    return temp.charAt(0).toUpperCase() + temp.slice(1);
  }
  return '';
};
