import { PracticeInterface } from 'interfaces/Profile/Profile';
import PreviewItemInfo from '../PreviewItemInfo/PreviewItemInfo';
import { HeaderProps } from '../interface';
import styles from './Header.module.scss';

const Header = ({
  practiceLogo,
  practiceName,
  practiceAddress,
  practicePhoneNumber,
  practiceFax,
  practiceFormalName,
  practiceRegistrationNumber,
  practiceFinanceEmail,
  practiceInfo
}: HeaderProps & { practiceInfo: PracticeInterface & { address?: string } }) => {
  return (
    <div className={styles.header}>
      {practiceLogo && <img className={styles.logo} src={practiceInfo.logo} alt="logo" />}
      <div>
        {practiceName && <h2>{practiceInfo.name}</h2>}
        <div className={styles.headerContent}>
          <div className={styles.itemInfoBlock}>
            {practiceAddress && practiceInfo?.address && <PreviewItemInfo value={practiceInfo.address} />}
            {practicePhoneNumber && <PreviewItemInfo value={practiceInfo.mobileNumber} />}
            {practiceFax && practiceInfo.faxNumber && <PreviewItemInfo value={practiceInfo.faxNumber} />}
          </div>
          <div className={styles.itemInfoBlock}>
            {practiceFormalName && practiceInfo.formalName && <PreviewItemInfo value={practiceInfo.formalName} />}
            {practiceRegistrationNumber && practiceInfo.registrationNumber && (
              <PreviewItemInfo value={practiceInfo.registrationNumber} />
            )}
            {practiceFinanceEmail && practiceInfo.financeEmail && <PreviewItemInfo value={practiceInfo.financeEmail} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
