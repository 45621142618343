import SubTab, { SubTabOption } from 'components/v2/SubTab/SubTab';
import styles from '../SubTabLayout.module.scss';

import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

const ControlsSubTab = () => {
  const location = useLocation();
  const { isEdgePlanUser } = useGetAccountPackage();
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView, isEdgeReceptionist } = useGetAccountPackageView();
  const isControlsPage = isEdgePlanUser
    ? location.pathname.split('/')[2] === 'control-panel'
    : location.pathname.split('/')[1] === 'control-panel';
  const getFilterType: string = isControlsPage
    ? isEdgePlanUser
      ? (location.pathname.split('/')[3] as string) ?? (isEdgeAdminView ? 'practice' : 'about-you')
      : (location.pathname.split('/')[2] as string) ?? (isEdgeAdminView ? 'practice' : 'about-you')
    : '';
  const { CONTROL_PANEL } = useRoutesGenerator();
  const [filterType, setFilterType] = useState<string>(getFilterType);
  const {
    medicareRebateFeatureToggle,
    isHelmFeatureToggle,
    isSomeoneHealthFeatureToggle,
    isCaWFeatureToggle,
    isEngagePublicProfileEnabled,
    isRechargeWellnessFeatureToggle,
    isPackageEnabled,
    isSelectPsychologyFeatureToggle,
    isPractitionerReportEnabled
  } = useGetFeatureToggle();

  useEffect(() => {
    setFilterType(getFilterType);
  }, [getFilterType]);

  const baseHref = CONTROL_PANEL.BASE;

  const controlsSubTabList: Array<SubTabOption & { isDisplay: boolean }> = [
    {
      value: 'about-you',
      label: 'About You',
      isDisplay: isEdgeUserView || isNormalUserView || isEdgeReceptionist,
      href: `${baseHref}/about-you`
    },
    {
      value: 'engage-profile',
      label: 'Engage Public Profile',
      isDisplay:
        isEdgeUserView &&
        (isHelmFeatureToggle ||
          isSomeoneHealthFeatureToggle ||
          isCaWFeatureToggle ||
          isEngagePublicProfileEnabled ||
          isRechargeWellnessFeatureToggle ||
          isSelectPsychologyFeatureToggle),
      href: `${baseHref}/engage-profile`
    },
    {
      value: 'practice',
      label: 'Practice Information',
      isDisplay: isEdgeAdminView || isNormalUserView,
      href: `${baseHref}/practice`
    },
    {
      value: 'medicare',
      label: 'Medicare',
      isDisplay: medicareRebateFeatureToggle && (isEdgeUserView || isNormalUserView),
      href: `${baseHref}/medicare`
    },
    {
      value: 'schedule',
      label: 'Working Schedule',
      isDisplay: isEdgeUserView,
      href: `${baseHref}/schedule`
    },
    {
      value: 'system-connections',
      label: 'System Connections',
      isDisplay: isEdgeAdminView || isEdgeUserView || isNormalUserView,
      href: `${baseHref}/system-connections`
    },
    {
      value: 'notification-settings',
      label: 'Notification Settings',
      isDisplay: isEdgeAdminView || isNormalUserView,
      href: `${baseHref}/notification-settings`
    },
    {
      value: 'collect-data',
      label: 'Collect Data ',
      isDisplay: !isEdgeUserView,
      href: `${baseHref}/collect-data`
    },
    {
      value: 'general-settings',
      label: 'General Settings',
      isDisplay: isEdgeAdminView,
      href: `${baseHref}/general-settings`
    },
    {
      value: 'packages',
      label: 'Packages',
      isDisplay: isEdgeAdminView && isPackageEnabled,
      href: `${baseHref}/packages`
    },
    {
      value: 'services-report',
      label: 'Services Report',
      isDisplay: isEdgeUserView && isPractitionerReportEnabled,
      href: `${baseHref}/services-report`
    }
  ];

  const generateSubTab: SubTabOption[] = controlsSubTabList
    .filter((item) => item.isDisplay)
    .map((item) => ({ label: item.label, value: item.value, href: item.href } as SubTabOption));

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <SubTab options={generateSubTab} activeOption={filterType} />
      </div>
    </div>
  );
};

export default ControlsSubTab;
