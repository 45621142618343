import { ProfileRole, ClientRecordType } from 'interfaces/Clients/clientsRecord';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { DELIVERY_TYPE_ICONS } from 'interfaces/Schedule/AppointmentType';
import styles from './MemberItem.module.scss';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { combineName } from 'utils/general';
import moment from 'moment';
import { getDeliveryTypeLabel } from 'utils/appointment';

export interface MemberItemProps {
  appointment: Omit<AppointmentSlots, 'clientRecord' | 'deliveryType'> &
    Required<Pick<AppointmentSlots, 'clientRecord' | 'deliveryType'>>;
}

const MemberItem = ({ appointment }: MemberItemProps) => {
  const memberProfileList =
    appointment.clientRecord.recordType === ClientRecordType.Child
      ? appointment.clientRecord.clientProfiles.filter((memberObj) => memberObj.role === ProfileRole.Child)
      : appointment.clientRecord.clientProfiles;

  return (
    <div className={styles.container}>
      <div className={styles.avatarSection}>
        <ClientAvatar
          clientData={memberProfileList}
          displayFirstNameOnly={appointment.clientRecord.recordType === 'couple'}
          displayLimit={appointment.clientRecord.recordType === 'couple' ? 2 : 1}
          avatarSize={64}
          hideName
        />
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.mainInfo}>
          <div className={styles.nameSection}>
            {combineName(memberProfileList, appointment.clientRecord.recordType === 'couple', true)}
          </div>
          <div className={styles.timeSection}>
            <i className={`material-icons ${styles.timeIcon}`}>schedule</i>
            <div>
              {moment(appointment.startTime, 'HH:mm').format('hh:mmA')} -{' '}
              {moment(appointment.endTime, 'HH:mm').format('hh:mmA')}
            </div>
          </div>
          <div className={styles.deliveryModeSection}>
            <div className={styles.deliveryModeWrapper}>
              <i className={`material-icons ${styles.deliveryIcon}`}>{DELIVERY_TYPE_ICONS[appointment.deliveryType]}</i>
              <div>{getDeliveryTypeLabel(appointment.deliveryType, appointment.otherInstructions)}</div>
            </div>
          </div>
          <div className={styles.roomSection}>
            {appointment.room && (
              <>
                <i className={`material-icons-outlined ${styles.meetingIcon}`}>meeting_room</i>
                <div>{appointment.room.roomName}</div>
              </>
            )}
          </div>
          <div className={styles.taggingSection}>
            {appointment.clientRecord.tags?.map((tag, index) => (
              <div className={styles.tagBadge} key={index}>
                {tag}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.actionSection}>
          <div className={styles.arrowWrapper}>
            <i className={`material-icons ${styles.arrowIcon}`}>arrow_forward</i>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MemberItem;
