import classNames from 'classnames';
import IconButton from 'components/IconButton/IconButton';
import ListHeaderItem from 'components/T23/ListHeaderItem/ListHeaderItem';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { AddressBookType } from 'pages/AddressBook/Interfaces/AddressBook';
import { ADDRESS_BOOK_CONTACT_ROLE_OPTIONS, AddressBookContact } from 'pages/AddressBook/Interfaces/AddressBookContact';
import { renderCellValue } from '../../../constants';
import styles from './AddressBookContactTable.module.scss';

interface AddressBookContactTableProps {
  type: AddressBookType;
  contacts: AddressBookContact[];
  isEditMode?: boolean;
  onDeleteContact?: (index: number) => void;
  onChangePrimaryContact?: (index: number) => void;
  onAddContact?: () => void;
}

const AddressBookContactTable = ({
  type,
  contacts,
  isEditMode = false,
  onChangePrimaryContact,
  onDeleteContact,
  onAddContact
}: AddressBookContactTableProps) => {
  const CONTACT_COLUMNS = [
    {
      label: '',
      field: 'isPrimary',
      className: 'isPrimarySection',
      cell: (row: AddressBookContact, index: number) =>
        isEditMode ? (
          <IconButton
            active={false}
            size={'medium'}
            paddingSize={'small'}
            className={classNames(styles.primaryContactIcon, row.isPrimary && styles.isPrimary)}
            iconName={row.isPrimary ? 'star' : 'star_outline'}
            onClick={() => {
              onChangePrimaryContact?.(index);
            }}
          />
        ) : (
          <i
            className={classNames(
              'material-icons-outlined',
              styles.primaryContactIcon,
              row.isPrimary && styles.isPrimary
            )}
          >
            {row.isPrimary ? 'star' : 'star_outline'}
          </i>
        )
    },
    { label: 'First Name', field: 'firstName', className: 'firstNameSection' },
    { label: 'Last Name', field: 'lastName', className: 'lastNameSection' },
    {
      label: 'Role',
      field: 'role',
      className: 'roleSection',
      cell: (row: AddressBookContact) => (
        <span>{ADDRESS_BOOK_CONTACT_ROLE_OPTIONS.find((opt) => opt.value === row.role)?.label || '-'}</span>
      )
    },
    { label: 'Phone', field: 'mobileNumber', className: 'mobileNumberSection' },
    { label: 'Email', field: 'email', className: 'emailSection' },
    { label: 'Fax', field: 'faxNumber', className: 'faxSection' },
    ...(isEditMode
      ? [
          {
            label: '',
            field: '',
            className: 'actionSection',
            cell: (row: AddressBookContact, index: number) => (
              <IconButton
                className={styles.deleteIcon}
                iconName={'delete'}
                size="medium"
                paddingSize={'small'}
                onClick={() => onDeleteContact?.(index)}
              />
            )
          }
        ]
      : [])
  ];

  return contacts.length > 0 ? (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        {CONTACT_COLUMNS.map((contact) => (
          <div key={contact.field} className={classNames(styles.header, styles[contact.className])}>
            <ListHeaderItem columnId={contact.field} columnName={contact.label} />
          </div>
        ))}
      </div>
      <div className={styles.contentWrapper}>
        {contacts.map((contact, index) => (
          <div className={styles.contentList} key={index}>
            {CONTACT_COLUMNS.map((row, colIndex) => (
              <div key={colIndex} className={classNames(styles.content, styles[row.className])}>
                {row.cell ? row.cell(contact, index) : renderCellValue(contact[row.field as keyof AddressBookContact])}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className={styles.noContactContainer}>
      <i className={`material-icons-outlined ${styles.icon}`}>info</i>
      <div>
        <div>Add an individual contact to this {type}</div>
        <ButtonAlt
          className={styles.addContactBtn}
          size={'small'}
          variant="text"
          icon={'open_in_new'}
          iconPostFix
          fab
          onClick={onAddContact}
        >
          Open to edit / add contacts
        </ButtonAlt>
      </div>
    </div>
  );
};

export default AddressBookContactTable;
