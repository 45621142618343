export enum AddressBookContactRole {
  GP = 'generalPractitioner',
  Nurse = 'nurse',
  PracticeManager = 'practiceManager',
  Psychiatrist = 'psychiatrist',
  Psychologist = 'psychologist',
  Executive = 'executive',
  CaseManager = 'caseManager',
  Teacher = 'teacher',
  Professor = 'professor',
  Doctor = 'doctor',
  OtherHealthProfessional = 'otherHealthProfessional',
  OtherProfessional = 'otherProfessional'
}

export interface AddressBookContact {
  firstName: string;
  lastName?: string;
  role?: AddressBookContactRole;
  email?: string;
  mobileNumber?: string;
  faxNumber?: string;
  isPrimary: boolean;
}

export const ADDRESS_BOOK_CONTACT_ROLE_OPTIONS = [
  { value: AddressBookContactRole.GP, label: 'General Practitioner' },
  { value: AddressBookContactRole.Nurse, label: 'Nurse' },
  { value: AddressBookContactRole.PracticeManager, label: 'Practice Manager' },
  { value: AddressBookContactRole.Psychiatrist, label: 'Psychiatrist' },
  { value: AddressBookContactRole.Psychologist, label: 'Psychologist' },
  { value: AddressBookContactRole.Executive, label: 'Executive' },
  { value: AddressBookContactRole.CaseManager, label: 'Case Manager' },
  { value: AddressBookContactRole.Teacher, label: 'Teacher' },
  { value: AddressBookContactRole.Professor, label: 'Professor' },
  { value: AddressBookContactRole.Doctor, label: 'Doctor' },
  { value: AddressBookContactRole.OtherHealthProfessional, label: 'Other Health Professional' },
  { value: AddressBookContactRole.OtherProfessional, label: 'Other Professional' }
];
