import FormSection from 'components/v2/FormSection/FormSection';
import Radio from 'components/Radio/Radio';
import styles from './AppointmentParticipantType.module.scss';
import { useField } from 'formik';
import { AssignmentMode, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { ChangeEvent } from 'react';

interface AppointmentParticipantTypeProps {
  disabled?: boolean;
  onChangeParticipantType: (participantType: ParticipantType) => void;
}

const AppointmentParticipantType = ({ disabled, onChangeParticipantType }: AppointmentParticipantTypeProps) => {
  const { isGroupsFeatureToggle } = useGetFeatureToggle();

  const APPOINTMENT_PARTICIPANT = [
    { label: 'One to one appointment', value: ParticipantType.OneToOne },
    ...(isGroupsFeatureToggle ? [{ label: 'Group Appointment', value: ParticipantType.Group }] : []),
    { label: 'Activity', value: ParticipantType.Activity }
  ];

  const [{ value, onChange }] = useField('participantType');
  const [, , { setValue: setAssignmentModeValue }] = useField('assignmentMode');

  const handleChangeParticipantType = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    onChangeParticipantType(e.target.value as ParticipantType);
    setAssignmentModeValue(AssignmentMode.All);
  };

  return (
    <FormSection
      title="Type"
      help="Define the type of service this is. One to One is best for practitioner - client appointments. Group is best for one practitioner with multiple specified clients. Activity is best for any other billable event, that does not require specific invites to clients e.g. writing a report"
    >
      <div className={styles.assignmentForm}>
        <Radio
          disabled={disabled}
          name="participantType"
          options={APPOINTMENT_PARTICIPANT}
          value={value}
          onChange={handleChangeParticipantType}
          vertical
        />
      </div>
    </FormSection>
  );
};

export default AppointmentParticipantType;
