import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { ClientEncryptDetailInterface } from 'interfaces/Clients/ClientEncryptDetail';
import {
  getAllClientEncryptedDataByClientRecordId,
  getClientEncryptedDataByRecordProfileId
} from '../../http/ClinicianProfileService/ClientRecords/clientRecords';
import { clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import {
  PathwayStageItem,
  PathwayStageList,
  StageType
} from '../../../pages/Groups/GroupDetails/components/GroupContent/components/PathwayManagement/interface';
import { useTranslation } from 'react-i18next';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';

const massageAnytimeStage = (stageData: PathwayStageItem[]) => {
  return stageData.map((stageObj) => ({
    ...stageObj,
    stageType: StageType.Anytime
  }));
};

const massageSequentialStage = (stageData: PathwayStageItem[]) => {
  return stageData.map((stageObj, index) => ({
    ...stageObj,
    sequence: index + 1,
    stageType: StageType.Sequential
  }));
};

export const useFetchAllClientProfileEncryptDetails = (token: string, recordId: string, fetchOnMount = true) => {
  const [clientEncryptDetails, setClientEncryptDetails] = useState({} as clientRecordsEncryptedInterface);
  const [clientEncryptDetailsLoading, setClientEncryptDetailsLoading] = useState(false);
  const [t] = useTranslation();

  const fetchClientEncryptDetails = async () => {
    setClientEncryptDetailsLoading(true);

    try {
      const getClientEncryptResponses = await getAllClientEncryptedDataByClientRecordId(token, recordId);
      const clientDetailsRes = (await getClientEncryptResponses.json()) as clientRecordsEncryptedInterface;

      const massageStageConfiguration = (stageData: PathwayStageList) => ({
        anytime: stageData?.anytime ? massageAnytimeStage(stageData.anytime) : [],
        sequential: stageData?.sequential ? massageSequentialStage(stageData.sequential) : []
      });

      const massageAttachedGroup = (attachedGroupsData: clientRecordsEncryptedInterface['attachedGroups']) => {
        return attachedGroupsData?.map((attachedGroupsObj) => ({
          ...attachedGroupsObj,
          stagesConfiguration: massageStageConfiguration(attachedGroupsObj.stagesConfiguration)
        }));
      };

      const massageClientDetails = {
        ...clientDetailsRes,
        ...(clientDetailsRes.attachedGroups && {
          attachedGroups: massageAttachedGroup(clientDetailsRes.attachedGroups)
        }),
        allowCommunicationWithEmail: clientDetailsRes.clientProfiles.some(
          (profileObj) =>
            profileObj.role !== 'child' &&
            profileObj.isPrimaryContact &&
            canClientReceiveEmail({
              communicationPreference: profileObj.communicationPreference
            }) &&
            profileObj.hasEmail
        ),
        allowCommunicationWithSms: clientDetailsRes.clientProfiles.some(
          (profileObj) =>
            profileObj.role !== 'child' &&
            profileObj.isPrimaryContact &&
            canClientReceiveSms({
              communicationPreference: profileObj.communicationPreference
            }) &&
            profileObj.hasMobileNumber
        ),
        isCompleteRecords:
          clientDetailsRes.clientProfiles[0].profileType === 'full' &&
          !!clientDetailsRes.clientProfiles[0].clientAuth0Id
      };

      setClientEncryptDetails(massageClientDetails);
    } catch (ex) {
      notification.error({ message: t('form.error.failed_to_retrieve_this_client_detail') });
    }

    setClientEncryptDetailsLoading(false);
  };

  useEffect(() => {
    if (fetchOnMount && token && recordId) {
      fetchClientEncryptDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, recordId]);

  return { clientEncryptDetails, clientEncryptDetailsLoading, fetchClientEncryptDetails };
};

export const useFetchClientEncryptDetails = (token: string, recordId: string, profileId: string) => {
  const [clientEncryptDetails, setClientEncryptDetails] = useState({} as ClientEncryptDetailInterface);
  const [clientEncryptDetailsLoading, setClientEncryptDetailsLoading] = useState(!!recordId);
  const [t] = useTranslation();

  const fetchClientEncryptDetails = async () => {
    setClientEncryptDetailsLoading(true);

    try {
      const getClientEncryptResponses = await getClientEncryptedDataByRecordProfileId(token, recordId, profileId);

      const clientDetailsRes = await getClientEncryptResponses.json();
      setClientEncryptDetails(clientDetailsRes);
    } catch (ex) {
      notification.error({ message: t('form.error.failed_to_retrieve_this_client_detail') });
    }

    setClientEncryptDetailsLoading(false);
  };

  useEffect(() => {
    if (token && recordId) {
      fetchClientEncryptDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { clientEncryptDetails, clientEncryptDetailsLoading, fetchClientEncryptDetails };
};
