import { MenuItemProps } from './DropdownButton';
import styles from './DropdownButton.module.scss';
import MenuItems from './MenuItems';
import classnames from 'classnames';

const Dropdown = ({
  submenus,
  dropdown,
  depthLevel
}: {
  submenus: MenuItemProps[];
  dropdown: boolean;
  depthLevel: number;
}) => {
  const dropdownClass = depthLevel >= 1 ? styles.submenu : '';
  return (
    <ul className={classnames(styles.dropdown, dropdownClass, dropdown ? styles.show : '')}>
      {submenus.map((submenu: MenuItemProps, index: number) => (
        <MenuItems items={submenu} key={index} depthLevel={depthLevel + 1} />
      ))}
    </ul>
  );
};

export default Dropdown;
