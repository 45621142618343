import IconButton from 'components/IconButton/IconButton';
import styles from './InfoField.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectCarePlanEditorSlice, setCarePlanData } from 'redux/carePlan/carePlanEditorSlice';
import { useEffect, useState } from 'react';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useDebounce } from 'utils/hooks/useDebounce';
import { massageCarePathwayPayload } from 'redux/carePlan/useCarePathwaySave';
import { notification } from 'antd';
import { useUpdateCarePathwayMutation } from 'redux/endpoints/clinicianProfileServices/carePathway';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

const InfoField = () => {
  const dispatch = useAppDispatch();
  const carePlanData = useAppSelector(selectCarePlanEditorSlice);
  const { accountId } = useGetAccountId();
  const [updateCarePathway] = useUpdateCarePathwayMutation();
  const [startEdit, setStartEdit] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const [planInfoData, setPlanInfoData] = useState<{
    name: string;
    desc: string;
    note: string;
    noteVisible: boolean;
  }>({
    name: carePlanData.name,
    desc: carePlanData.description,
    note: carePlanData.note,
    noteVisible: carePlanData.isNoteVisibleToClient
  });

  const debouncedPlanData = useDebounce(planInfoData, 2000);

  const handleSaveData = async () => {
    const updatedData = {
      ...carePlanData,
      name: debouncedPlanData.name,
      description: debouncedPlanData.desc,
      note: debouncedPlanData.note,
      isNoteVisibleToClient: debouncedPlanData.noteVisible
    };
    dispatch(setCarePlanData(updatedData));
    const massagePutPayload = massageCarePathwayPayload(updatedData);
    try {
      const res: any = await updateCarePathway({
        accountId,
        carePathwayId: carePlanData?._id || '',
        payload: massagePutPayload
      });
      if (res?.error && res?.error?.originalStatus !== 200) {
        console.error('Error publish care pathway:', res.error.data.message);
        throw new Error(res.error.data.message);
      }
      notification.success({
        message: 'Plan is successfully updated.',
        duration: 2,
        closeIcon: <span className="success">OK</span>,
        placement: 'topRight'
      });
    } catch (error) {
      console.error('Error updating care pathway:', error);
      notification.error({
        message: 'Something went wrong while trying to updating your care pathway.'
      });
    }
  };

  useEffect(() => {
    if (startEdit) {
      handleSaveData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPlanData]);

  const handleChangeInfo = (key: string, value: string | boolean) => {
    setPlanInfoData({
      ...planInfoData,
      [key]: value
    });
    setStartEdit(true);
  };

  const VISIBLE_LIST = [
    {
      id: 'visible',
      label: 'VISIBLE TO CLIENT',
      isActive: planInfoData.noteVisible
    },
    {
      id: 'hidden',
      label: 'NOT VISIBLE',
      isActive: !planInfoData.noteVisible
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.titleField}>
        <MaterialInput
          id={'title'}
          label={'Plan title'}
          value={planInfoData.name}
          onChange={(e) => handleChangeInfo('name', e.target.value)}
          required
        />
      </div>
      <div className={styles.descField}>
        <MaterialInput
          id={'desc'}
          label={'Add short description'}
          value={planInfoData.desc}
          onChange={(e) => handleChangeInfo('desc', e.target.value)}
          required
        />
      </div>
      <div className={styles.noteContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <i className="material-icons-outlined">edit_note</i>
            <div>Note</div>
          </div>
          {!editing && <IconButton size={'small'} iconName="edit" onClick={() => setEditing(!editing)} />}
        </div>
        {editing ? (
          <div className={styles.textFieldContainer}>
            <textarea
              placeholder={'Add a note to this care plan'}
              className={styles.textarea}
              onChange={(e) => handleChangeInfo('note', e.target.value.trim())}
              defaultValue={planInfoData.note}
            />
            <div className={styles.buttonWrapper}>
              <ButtonAlt size={'small'} onClick={() => setEditing(false)}>
                Save
              </ButtonAlt>
            </div>
          </div>
        ) : planInfoData.note.length > 0 ? (
          <div onDoubleClick={() => setEditing(true)} className={styles.noteBody}>
            <div className={styles.noteContent}>{planInfoData.note}</div>
          </div>
        ) : (
          <div onDoubleClick={() => setEditing(true)} className={styles.addNoteLabel}>
            <div className={styles.noteContent}>Add Note</div>
          </div>
        )}
      </div>
      <div className={styles.toggleContainer}>
        <ToggleSwitchV2
          id={'visibleToClient'}
          toggleList={VISIBLE_LIST}
          onChangeStatus={(value) => handleChangeInfo('noteVisible', value.id === 'visible')}
          isLoading={false}
          className={styles.toggleClass}
          indicatorClassName={styles.indicatorClass}
          activeLabelClassName={styles.activeClass}
          size={'small'}
        />
      </div>
    </div>
  );
};

export default InfoField;
