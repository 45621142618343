import styles from './ClientAssignItem.module.scss';
import { ClientAssignInterface } from '../interfaces';
import { canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import classnames from 'classnames';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { getName } from 'utils/general';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import ProgramMenu from '../ProgramMenu/ProgramMenu';
import { MicroCheckInAssessmentStruct } from 'pages/Assessments/Interface';
import { useTranslation } from 'react-i18next';
import { OnSelectProgram } from '../../ClientAssignList';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

export interface ClientAssignItemProps {
  clientAssign: ClientAssignInterface;
  mCIAssessmentTemplate: MicroCheckInAssessmentStruct[];
  onSelectProgram: OnSelectProgram;
  notificationSettings: NotificationSettingsInterface;
}

const ClientAssignItem = ({
  clientAssign,
  mCIAssessmentTemplate,
  onSelectProgram,
  notificationSettings
}: ClientAssignItemProps) => {
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      {clientAssign.recordType === 'couple' && (
        <div className={styles.coupleLabel}>
          <span>COUPLE PROFILE</span>
        </div>
      )}
      {clientAssign.clientProfiles.map((profile) => {
        const isSmsCommunicationsNotAllowed = !canClientReceiveSms({
          communicationPreference: profile.communicationPreference,
          notificationSettings,
          notificationSettingsCategory: 'forms'
        });
        const isSignedUp = !!profile.clientAuth0Id;
        return (
          <div
            className={classnames(
              isSmsCommunicationsNotAllowed || !isSignedUp ? styles.clientCardDisable : styles.clientCard
            )}
            key={profile._id}
          >
            <div className={styles.detail}>
              <div className={styles.profile}>
                <ClientProfileAvatar avatarUrl={profile.avatar} initialsName={profile.initials} />
              </div>
              <div className={styles.name}>{getName(profile)}</div>
            </div>
            {isSmsCommunicationsNotAllowed || !isSignedUp ? (
              <div className={styles.list}>
                <span className={styles.notAvailable}>Not available</span>
                <HelpOutLineWithTooltips
                  id={`noAllowSelect${profile._id}`}
                  desc={
                    isSmsCommunicationsNotAllowed
                      ? t('micro_checkin.disallowed_sms_communication')
                      : t('micro_checkin.not_signup')
                  }
                />
              </div>
            ) : (
              <div className={styles.list}>
                <ProgramMenu
                  value={profile.microCheckInId}
                  data={mCIAssessmentTemplate}
                  clientProfileId={profile._id}
                  onSelectQuestionSet={onSelectProgram}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ClientAssignItem;
