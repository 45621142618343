import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { Assessment } from '../../../pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';
import { getClinicalAssessmentDetailsResponse } from '../../http/CheckInService/Assessment/clinicalAssessment';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { getPatientsActivity } from 'utils/http/ActivityFeedService/activities';
import { PatientActivity } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsActivity/components/ActivityFeed/ActivityFeed';

const massageNameInId = (records: Assessment[]) => {
  const addApostrophe = (name: string): string => (name.slice(-1) === 's' ? `${name}'` : `${name}'s`);
  return records.map((recordObj) => ({
    ...recordObj,
    id: recordObj.assessedBy ? `${addApostrophe(recordObj.assessedBy)} ${recordObj.id}` : recordObj.id
  }));
};

export const useFetchAssessmentByRecordProfileId = (token: string, recordId: string, nameInId?: boolean) => {
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [assessmentList, setAssessmentList] = useState<Assessment[]>([]);
  const [isLoadingAssessmentList, setIsLoadingAssessmentList] = useState(true);

  const fetchAssessment = async () => {
    setIsLoadingAssessmentList(true);

    if (recordId && !isEdgeReceptionist) {
      try {
        const getCAssessmentResponse = await getClinicalAssessmentDetailsResponse(token, recordId);

        const assessment = await getCAssessmentResponse.json();
        setAssessmentList(!nameInId ? assessment : massageNameInId(assessment));
      } catch (ex) {
        console.error(ex);
        notification.error({ message: "Something went wrong while trying to get this record's assessments details" });
      }
    }

    setIsLoadingAssessmentList(false);
  };

  useEffect(() => {
    if (token) {
      fetchAssessment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { assessmentList, isLoadingAssessmentList, fetchAssessment };
};

export const useFetchCancelledAppointments = (token: string, clientRecordId: string) => {
  const [cancelledAppointments, setCancelledAppointments] = useState<PatientActivity[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCancelledAppointments = async () => {
    setIsLoading(true);

    try {
      const appointmentsResponse = await getPatientsActivity(token, {
        clientRecordId: clientRecordId,
        pageSize: 0,
        filter: 'appointment',
        action: 'cancelled'
      });

      const appointmentsJson = (await appointmentsResponse.json()) as PatientActivity[];

      setCancelledAppointments(appointmentsJson);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get appointments details' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (token && clientRecordId) {
      fetchCancelledAppointments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clientRecordId]);

  return { cancelledAppointments, isLoading };
};
