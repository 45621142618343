export const TasksListColumns = [
  {
    id: 'type',
    sortAble: false
  },
  {
    id: 'assignedTo',
    sortAble: false
  },
  {
    id: 'created',
    sortAble: true
  },
  {
    id: 'linkedTo',
    sortAble: false
  },
  {
    id: 'reason',
    sortAble: false
  },
  {
    id: 'invoice',
    sortAble: false
  }
];
