import classnames from 'classnames';
import { toCamelCase } from 'utils/generateCamelCase';
import { CaseNote } from 'interfaces/caseNote';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import styles from './NumberWrapper.module.scss';
import { useMemo } from 'react';

interface NumberWrapperProps {
  field: CaseNote['fields'][number];
  isValidated: boolean;
  className?: string;
  inputClassName?: string;
  isDisabled?: boolean;
  placeholder?: string;
  onChangeFieldInput: (id: string, value: any) => void;
}

const NumberWrapper = ({
  field,
  isValidated,
  className,
  inputClassName,
  isDisabled,
  placeholder,
  onChangeFieldInput
}: NumberWrapperProps) => {
  const errorString = useMemo(() => {
    switch (field.type) {
      case 'number':
        return !field.value?.match('^[0-9]*$') && `${field.name.toLocaleLowerCase()} is not a number`;
      case 'text':
        return field.value?.toString().length === 0 && `Please fill in ${field.name.toLocaleLowerCase()}`;
      case 'phone':
        return (
          !Yup.string()
            .matches(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g)
            .isValidSync(field.value) && 'Please enter a valid phone number'
        );
      case 'email':
        return !Yup.string().email().isValidSync(field.value) && 'Please enter a valid email';
    }
  }, [field.name, field.type, field.value]);

  return (
    <div className={classnames(styles.inputContainer, styles.headingField, className)}>
      <MaterialInput
        className={classnames(styles.inputField, inputClassName)}
        type={field.type}
        id={toCamelCase(field.name) || 'fieldName'}
        name={field.name}
        label={`${field.name} ${field.mandatory ? '*' : ''}`}
        value={field.value || ''}
        onChange={(e) => onChangeFieldInput(field._id, e.target.value)}
        placeholder={placeholder}
        disabled={isDisabled}
        required
      />
      <ErrorMessage
        className={styles.error}
        error={errorString || undefined}
        visible={
          isValidated &&
          ((field.mandatory && !field.value) || (!isEmpty(field.value) && !field.value?.match('^[0-9]*$')))
        }
      />
    </div>
  );
};

export default NumberWrapper;
