import { CarePathwayListAction, CarePathwayListState } from './types';

export const carePathwayReducer = (state: CarePathwayListState, action: CarePathwayListAction) => {
  switch (action.type) {
    case 'SET_FILTER':
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload
        },
        currentPage: 1
      };
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload
      };
    default:
      return state;
  }
};
