import { Address, useAddressSuggestion } from 'utils/hooks/useAddressSuggestion';
import styles from './AddressSuggestion.module.scss';
import classnames from 'classnames';

interface AddressSuggestionProps {
  keyword: string;
  onSelect?: (address: Address['details']) => void;
}

export const AddressSuggestion = ({ keyword, onSelect }: AddressSuggestionProps) => {
  const { addresses, isLoading } = useAddressSuggestion(keyword);

  const renderState = () => {
    if (isLoading) {
      return <div className={styles.loading}>Loading...</div>;
    }

    if (addresses.length === 0 && keyword.length > 0) {
      return <div className={styles.loading}>No address found. Please try other keywords.</div>;
    }

    if (addresses.length === 0 && keyword.length === 0) {
      return <div className={styles.loading}>Type something for suggestion</div>;
    }

    return (
      <>
        {addresses.map((address) => (
          <div
            className={styles.address}
            key={address.description}
            onClick={() => {
              onSelect?.(address.details);
            }}
          >
            {address.description}
          </div>
        ))}
      </>
    );
  };

  return (
    <div
      className={classnames(styles.container, {
        [styles.hiddenInMobile]: addresses.length === 0 || keyword.length === 0
      })}
    >
      <h4 className={styles.title}>Address Suggestion</h4>
      {renderState()}
    </div>
  );
};
