export const PendingClaimInvoiceListColumn = [
  {
    id: 'client',
    sortAble: false
  },
  {
    id: 'practitioner',
    sortAble: false
  },
  {
    id: 'groups',
    sortAble: false
  },
  {
    id: 'invoiceId',
    sortAble: false
  },
  {
    id: 'date',
    sortAble: true
  },
  {
    id: 'type',
    sortAble: false,
    label: 'Payer'
  },
  {
    id: 'claimCode',
    sortAble: false
  },
  {
    id: 'invoiceStatus',
    sortAble: false
  },
  {
    id: 'amount',
    sortAble: false
  }
];
