import styles from './CarePathwayBadge.module.scss';
import { CARE_PATHWAY_STATUS_LABELS, CarePathwayStatus } from 'interfaces/CarePathway/CarePathway';

export interface CarePathwayBadgeProps {
  status: CarePathwayStatus;
  isDisable?: boolean;
}

const CarePathwayBadge = ({ status, isDisable }: CarePathwayBadgeProps) => (
  <div className={styles[isDisable ? 'disable' : status]}>
    <div>{CARE_PATHWAY_STATUS_LABELS[status]?.toUpperCase()}</div>
  </div>
);

export default CarePathwayBadge;
