import {
  AppointmentType,
  DELIVERY_TYPE_LABELS,
  DeliveryType,
  OtherInstructions
} from 'interfaces/Schedule/AppointmentType';
import moment, { Moment } from 'moment';

export const MOMENTJS_FORMAT_DATE = 'YYYY-MM-DD';
export const MOMENTJS_FORMAT_TIME = 'HH:mm';
export const MOMENTJS_FORMAT_START_TIME_FORMAT = 'hh:mm';
export const MOMENTJS_FORMAT_END_TIME_FORMAT = 'hh:mm a';

export const getLastRecurringAppointmentDate = (startDate: string, frequency: string, occurrences: string) => {
  return moment(startDate, 'YYYY-MM-DD')
    .add(Number(frequency) * Number(occurrences), 'days')
    .format('DD MMMM YYYY');
};

export const getAllDatesInWeek = (date: Moment) => [
  date.clone().day(0).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(1).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(2).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(3).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(4).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(5).format(MOMENTJS_FORMAT_DATE),
  date.clone().day(6).format(MOMENTJS_FORMAT_DATE)
];

export const getDeliveryTypeLabel = (deliveryType?: string, otherInstructions?: OtherInstructions) => {
  switch (deliveryType) {
    // use title if exists, fallback to delivery type label if somehow fails
    case DeliveryType.Other:
      return otherInstructions?.title || DELIVERY_TYPE_LABELS[deliveryType];
    case DeliveryType.Bespoke:
    case DeliveryType.FaceToFace:
    case DeliveryType.PhoneCall:
    case DeliveryType.PhoneCallDialClient:
    case DeliveryType.VideoCall:
      return DELIVERY_TYPE_LABELS[deliveryType];

    default:
      return '';
  }
};

export const getDeliveryTypeInstruction = (deliveryType?: DeliveryType, appointmentType?: AppointmentType): string => {
  switch (deliveryType) {
    case DeliveryType.Other:
      return appointmentType?.otherInstructions?.instructions ?? '';
    case DeliveryType.FaceToFace:
      return appointmentType?.faceToFaceLocation ?? '';
    case DeliveryType.PhoneCall:
      return appointmentType?.phoneCallInstructions ?? '';
    case DeliveryType.PhoneCallDialClient:
      return appointmentType?.phoneCallDialClientInstructions ?? '';
    case DeliveryType.VideoCall:
      return appointmentType?.videoCallInstructions ?? '';
    case DeliveryType.Bespoke:
      return '';

    default:
      return '';
  }
};
