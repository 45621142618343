import momentTz from 'moment-timezone';

export const TOP_LEFT_OF_SCHEDULE_CALENDAR_SELECTOR = '.mbsc-schedule-time-col.mbsc-schedule-time-col-empty'; // where is appended the anchor time
export const SCHEDULE_ITEM_CLASS = '.mbsc-schedule-item.mbsc-flex-1-0.mbsc-material';
export const SCHEDULE_CALENDAR_VIEW_WRAPPER_CLASS = '.mbsc-schedule-grid-wrapper';
export const FIRST_HALF_HOUR_CLASS = 'first-half';
export const HALF_PAST_HOUR_CLASS = 'half-past';
export const MARK_TO_APPT_CREATION_CLASS = 'mark-to-create-appt';
export const DEFAULT_APPT_RANGE = 30;
export const AUTO_START_VALUE = 'auto';

export interface HoverState {
  start: Date;
  end: Date;
  type: string;
}

/**
 * Inject tz to date as string
 * @param date
 * @param timezone
 * @returns
 */
export const convertDateWithTimezone = (date: Date, timezone: string): Date => {
  const offsetTz = momentTz(date).tz(timezone).format('Z');

  return new Date(
    `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${
      date.toTimeString().split(' ')[0]
    }${offsetTz}`
  );
};

/**
 * Calculate dynamic position
 * @param domEvent
 * @param cellWidth
 * @param inlineBookingFormWidth
 * @param isHorizontalCalendarView
 * @returns
 */
export const calculateInlineModalPosition = (
  domEvent: any,
  cellWidth: number,
  inlineBookingFormWidth: number,
  isHorizontalCalendarView?: boolean
) => {
  const {
    clientX,
    clientY,
    nativeEvent: { offsetX, offsetY }
  } = domEvent;
  const { innerWidth: windowX, innerHeight: windowY } = window;
  const calendarViewWidth = document.querySelector(SCHEDULE_CALENDAR_VIEW_WRAPPER_CLASS)?.clientWidth || 0;
  // need to use a constant since the inner form is not rendered yet
  const inlineBookingFormHeight = 375;
  const sideBarOffset = windowX - calendarViewWidth;
  let top = clientY - offsetY - 200;
  let left = clientX - offsetX - sideBarOffset + cellWidth + 10;
  const bottomSpace = windowY - clientY;
  const topSpace = clientY;
  const pos = { top, left, width: inlineBookingFormWidth };
  const rightSpace = windowX - clientX - cellWidth;
  const leftSpace = parseInt('' + clientX / cellWidth, 10) * cellWidth;

  if (!isHorizontalCalendarView) {
    if (topSpace > bottomSpace) {
      // not enough space to render the form, re-calculating
      pos.top = top + (inlineBookingFormHeight - top) - inlineBookingFormHeight / 2 + 120;
    }

    if (leftSpace > rightSpace) {
      // show on the left of the hover state
      pos.left = left - inlineBookingFormWidth - cellWidth - 10;
    }

    // if the area of the left and right of the hover state not enough for
    // inline booking form to be rendered. So looking for top and bottom area instead
    if (leftSpace < inlineBookingFormWidth && rightSpace < inlineBookingFormWidth) {
      // try to render on top or bottom position
      if (topSpace > bottomSpace) {
        pos.top = clientY - inlineBookingFormHeight - 100;
        pos.left = cellWidth / 2;
      } else {
        // increase top space by auto scroll the calendar view down
        pos.top = pos.top + 60;
        pos.left = cellWidth / 2;
      }
    }
    return pos;
  } else {
    pos.top = topSpace > bottomSpace ? clientY - inlineBookingFormHeight - 100 : topSpace;
    pos.left = 120;
    return pos;
  }
};
