import { configInterface } from './config';
import * as constants from '../pages/Calendar/components/CalendarSettings/components/AccountLevelSettings/constants';
import UKTerms from 'pages/SignUp/components/Data/components/UKTerms';
import UKPrivacy from 'pages/SignUp/components/Data/components/UKPrivacy';

export const configSA: configInterface = {
  countryCode: 'sa',
  mobileNumberMinLength: 9,
  mobileNumberMaxLength: 20,
  moneyIcon: 'monetization_on',
  currencySymbol: 'ر.س',
  currencyCode: 'sar',
  taxLabel: 'TAX',
  taxRateLabel: 'VAT',
  mobileCountryCode: '966',
  codeLabel: '',
  defaultTimezone: 'Asia/Riyadh',
  practiceSettings: {
    registrationNumberLabel: 'CR Number',
    publicHolidayOption: constants.SA_PUBLIC_HOLIDAY_CALENDAR_OPTIONS
  },
  terms: {
    tnc: <UKTerms />,
    privacy: <UKPrivacy />
  }
};
