import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { AddressBookAddress } from './AddressBookAddress';
import { AddressBookContact } from './AddressBookContact';
import { AddressBookOrganisation } from './AddressBookOrganisation';

export enum AddressBookType {
  Individual = 'individual',
  Organisation = 'organisation'
}

export enum AddressBookRole {
  Funder = 'funder',
  Referrer = 'referrer'
}

export enum AddressBookCategory {
  GP = 'generalPractitioner',
  CaseManager = 'caseManager',
  Charity = 'charity',
  CommunityService = 'communityService',
  PHI = 'phi',
  EAP = 'eap',
  Hospital = 'hospital',
  WorkCover = 'workCover',
  ThirdPartyFunder = 'thirdPartyFunder',
  NDIS = 'ndis',
  Other = 'other'
}

interface BaseAddressBook {
  accountId: string;
  addressBookId: string;
  type: AddressBookType;
  role: AddressBookRole;
  category: AddressBookCategory;
  code?: string;
  address?: AddressBookAddress;
  contacts: AddressBookContact[];
}

// Interface for Organisation type AddressBook
interface OrganisationAddressBook extends BaseAddressBook {
  type: AddressBookType.Organisation;
  organisation: AddressBookOrganisation;
}

// Interface for Individual type AddressBook
interface IndividualAddressBook extends BaseAddressBook {
  type: AddressBookType.Individual;
  organisation?: never;
}

export type AddressBook = OrganisationAddressBook | IndividualAddressBook;

export type AddressBookFromAPI = AddressBook & {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
};

export interface AddressBookListData {
  addressBooks: AddressBookFromAPI[];
  count: {
    funder: number;
    referrer: number;
    organisation: number;
    individual: number;
  };
  paging: {
    page: number;
    perPage: number;
    totalFilteredItems: number;
    totalItems: number;
  };
}

export const ADDRESS_BOOK_CATEGORIES: Record<AddressBookCategory, string> = {
  generalPractitioner: 'GP',
  caseManager: 'Case Manager',
  charity: 'Charity',
  communityService: 'Community',
  phi: 'PHI',
  eap: 'EAP',
  hospital: 'Hospital',
  workCover: 'Work Cover',
  thirdPartyFunder: '3RD Party',
  ndis: 'NDIS',
  other: 'Other'
};

export const ADDRESS_BOOK_CATEGORY_OPTIONS: FilterCheckListItem[] = Object.values(AddressBookCategory).map(
  (category) => ({
    _id: category,
    name: ADDRESS_BOOK_CATEGORIES[category].toUpperCase()
  })
);

export const ADDRESS_BOOK_TYPE_OPTIONS: FilterCheckListItem[] = [
  {
    _id: AddressBookType.Individual,
    name: 'Individual'
  },
  {
    _id: AddressBookType.Organisation,
    name: 'Organisation'
  }
];

export const ADDRESS_BOOK_ROLE_OPTIONS: FilterCheckListItem[] = [
  { _id: AddressBookRole.Funder, name: 'Funder' },
  { _id: AddressBookRole.Referrer, name: 'Referrer' }
];
