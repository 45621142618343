import FormSection from 'components/v2/FormSection/FormSection';
import { Formik } from 'formik';
import * as constants from './constants';
import styles from './AccountLevelSettings.module.scss';
import RuleField from './components/RuleField/RuleField';
import { useFetchSchedulingRules } from 'utils/hooks/GetRoomList/useFetchSchedulingRules';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { SchedulingRulesType } from 'interfaces/Schedule/SchedulingRules';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import RescheduleRules from './components/RescheduleRules/RescheduleRules';
import FormikTextarea from './components/FormikTextarea/FormikTextarea';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { config } from 'config/config';

// (requestChanges.value.from, selfManaged.value.to) &
// these fields isn't control in formik thus populate in below function
const formatClientReschedulePayload = (
  clientReschedule: SchedulingRulesType['clientReschedule']
): SchedulingRulesType['clientReschedule'] => {
  const processedClientReschedule = Object.assign({}, clientReschedule);
  processedClientReschedule.requestChanges.value.from = clientReschedule.selfManage.value.to;

  return processedClientReschedule;
};

const AccountLevelSettings = () => {
  const { schedulingRules, isSchedulingRulesLoading, updateSchedulingRules, isSchedulingRulesUpdating } =
    useFetchSchedulingRules();
  const [t] = useTranslation();

  const handleSubmit = async (values: SchedulingRulesType) => {
    const clientReschedulePayload = formatClientReschedulePayload(values.clientReschedule);
    const response = await updateSchedulingRules({ ...values, clientReschedule: clientReschedulePayload });
    if (response.statusCode === 204) {
      notification.success({
        message: 'Account level rules updated.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return isSchedulingRulesLoading ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <Formik
      initialValues={{ ...constants.initialValues, ...schedulingRules }}
      validationSchema={constants.accountLevelSettingsSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <div className={styles.container}>
          <FormSection
            title="Appointment Availability Rules"
            subtitle="Set rules that govern across your service, these control all appointment types and practitioners."
          >
            <div className={styles.rulesContainer}>
              <RuleField
                name="preventBookingsOnPublicHolidays"
                desc={t('account_level.rules.prevent_bookings_on_public_holidays')}
                help="This option prevents clients booking appointments on a public holiday. Services and / or Practitioners can still book sessions on public holidays."
                optionLabel="Select holiday calendar"
                options={config.practiceSettings.publicHolidayOption}
              />
              <RuleField
                name="maxSessionsPerDay"
                desc={t('account_level.rules.max_sessions_per_day')}
                optionLabel="Maximum session"
                options={constants.MAXIMUM_APPOINTMENTS_PER_DAY_OPTIONS}
              />
              <RuleField
                name="maxForwardAvailability"
                desc={t('account_level.rules.max_forward_availability')}
                optionLabel="Maximum forward to"
                options={constants.MAX_FORWARD_AVAILABILITY_OPTIONS}
              />
              <RuleField
                name="minTimeBeforeAppointmentWhereBookingsAreAllowed"
                desc={t('account_level.rules.min_time_before_appointment')}
                optionLabel="Minimum gap of"
                options={constants.MINIMUM_TIME_BETWEEN_OPTIONS}
              />
            </div>
          </FormSection>
          <FormSection
            title="Reschedule Rules"
            subtitle="Set rules that enable clients to either self manage or request appointment changes.  No limitations are placed on your team making changes."
          >
            <RescheduleRules />
          </FormSection>
          <FormSection
            title="Cancellation Policy Explainer"
            subtitle="Add an overview of your cancellation policy here."
          >
            <FormikTextarea
              name={'cancellationPolicyDescription.value'}
              placeholder={'This is a cancellation policy'}
            />
          </FormSection>
          <FormSection
            title="Appointment Guidance Note"
            subtitle="Add detail to accompany your appointment invitations E.g. directions to the practice, how to connect to telehealth."
            help="This information will be included with each appointment invite that goes out. It will be visible in the body
        of the calendar message, alongside the detail of that specific appointment e.g. date, time."
          >
            <FormikTextarea name={'phoneAndVideoCallSettings.value'} />
          </FormSection>
          <ButtonAlt className={styles.button} status={isSchedulingRulesUpdating ? 'active' : ''} onClick={submitForm}>
            Save and apply
          </ButtonAlt>
        </div>
      )}
    </Formik>
  );
};

export default AccountLevelSettings;
