import classNames from 'classnames';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ReferralMatchedClient } from 'interfaces/Referral/Referral';
import moment from 'moment';
import { isStrongMatch } from 'pages/Referrals/utils';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { getName } from 'utils/general';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from './ReferralMatchItem.module.scss';

interface ReferralMatchItemProps {
  client: ReferralMatchedClient;
  disabled?: boolean;
  onLinkClient: (isLinkPackage: boolean) => void;
  beforeNavigate?: () => void;
  hasPackage?: boolean;
}

const ReferralMatchItem = ({ client, disabled, onLinkClient, beforeNavigate, hasPackage }: ReferralMatchItemProps) => {
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownContainer = useRef<HTMLDivElement>(null);

  const dob = client.matchedFields.includes('medicareDateOfBirth') ? client.medicareDateOfBirth : client.dateOfBirth;

  const handleClientClick = (clientRecordId: string) => {
    beforeNavigate?.();
    navigate(`${CLIENTS.BASE}/${clientRecordId}/profile`);
  };

  const handleClickOutSide = (event: any) => {
    if (dropdownContainer.current?.contains(event.target)) {
      return;
    }
    setShowDropdown(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.subject}>
        <span
          className={classNames(
            styles.indicator,
            isStrongMatch(client.score, medicareRebateFeatureToggle) ? styles.high : styles.medium
          )}
        />
        <div className={styles.info}>
          <span className={styles.name}>{getName(client)}</span>
          <div className={styles.moreInfo}>
            <span className={styles.tacklitId}>{client.tacklitId}</span>
            <span>{dob && moment(dob, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.navigate} onClick={() => handleClientClick(client.clientRecordId)}>
          View <i className={`material-icons-outlined ${styles.accessibilityIcon}`}>open_in_new</i>
        </div>

        {hasPackage ? (
          <>
            <ButtonAlt
              className={styles.linkButton}
              icon="link"
              disabled={disabled}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              Link
            </ButtonAlt>
            <div ref={dropdownContainer} className={showDropdown ? styles.menuShow : styles.menuHide}>
              <div
                className={styles.menuItem}
                onClick={() => {
                  setShowDropdown(false);
                  onLinkClient(true);
                }}
              >
                Attach package/s
                <i className={`material-icons-outlined ${styles.menuIcon}`}>inventory</i>
              </div>
              <div
                className={styles.menuItem}
                onClick={() => {
                  setShowDropdown(false);
                  onLinkClient(false);
                }}
              >
                Don't attach
              </div>
            </div>
          </>
        ) : (
          <ButtonAlt className={styles.linkButton} icon="link" disabled={disabled} onClick={() => onLinkClient(false)}>
            Link
          </ButtonAlt>
        )}
      </div>
    </div>
  );
};

export default ReferralMatchItem;
