import { Modal } from 'antd';
import Button from 'components/v2/Button/Button';
import { Integration } from 'pages/ControlPanel/Interfaces/Integration';

import styles from './DisconnectWarningModal.module.scss';

interface DisconnectWarningModalProps {
  visible: boolean;
  currentIntegration: Integration;
  newIntegrationLabel: Integration['label'];
  setShowDisconnectWarningModal: (bool: boolean) => void;
  setShowDisconnectModal: (bool: boolean) => void;
}

const DisconnectWarningModal = ({
  visible,
  currentIntegration: { label },
  newIntegrationLabel,
  setShowDisconnectWarningModal,
  setShowDisconnectModal
}: DisconnectWarningModalProps) => {
  const handleCloseModal = () => setShowDisconnectWarningModal(false);

  const handleConfirmDisconnect = () => {
    handleCloseModal();
    setShowDisconnectModal(true);
  };

  return (
    <Modal
      className={styles.container}
      open={visible}
      closable
      title={null}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.title}>{`Are you sure you want to connect ${newIntegrationLabel}?`}</div>
          <div className={styles.message}>
            You already have an accounting package connected. Selecting a different accounting package will terminate
            your existing accounting package connection, and could result in invalid data being synced.
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDisconnect}>{`Disconnect ${label}`}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default DisconnectWarningModal;
