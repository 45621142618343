import { ClientDetails, newClientForm } from '../AddPatientModalV2Interface';
import {
  validateCaseId,
  validateEmail,
  validatePhoneNumber,
  validationClientForm
} from '../formType/AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import { validationChildAndYoungPersonField } from '../formType/ChildForm/components/ChildClientForm/validation/ChildClientFormValidation';
import { validationChildGuardianField } from '../formType/ChildForm/components/ChildGuardianForm/validation/ChildGuardianFormValidation';
import { validationClientFormField } from '../formType/CoupleForm/validation/ClientFormValidation';
import _ from 'lodash';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import {
  validationYoungField,
  validationYoungGuardianField
} from '../formType/YoungForm/components/ClientForm/validation/YoungClientFormValidation';

export const checkIsDuplicateCaseId = async (token: string, caseIdValue?: string, excludeCaseIdValue?: string) => {
  const isSameCaseIdValue = excludeCaseIdValue === caseIdValue;
  if (caseIdValue && !isSameCaseIdValue) {
    const postValidateCaseId = await validateCaseId(token, caseIdValue);
    return postValidateCaseId.statusCode !== 200;
  } else {
    return false;
  }
};

export const validateAdultForm = async (
  token: string,
  clientVal: newClientForm['clientProfiles'],
  profileType?: clientProfilesInterface['profileType'],
  excludeEmailChecking?: string[]
) => {
  const checkValidation = clientVal.filter((clientField) => {
    const clientForm = validationClientForm({ ...clientField, profileType: profileType });
    return Object.values(clientForm as ClientDetails).some((value) => value !== '');
  });

  let checkDuplicateEmail = false;
  let checkInvalidPhone = false;
  if (checkValidation.length <= 0) {
    let emailCheck = [];
    let phoneCheck = [];
    for (const clientObj of clientVal) {
      if (clientObj.email && !excludeEmailChecking?.includes(clientObj.email)) {
        const checkEmail = await validateEmail(token, clientObj.email);
        if (checkEmail.statusCode !== 200) {
          emailCheck.push(checkEmail);
        }
      }
      if (clientObj.mobileNumber) {
        const checkPhoneNumber = await validatePhoneNumber(clientObj.mobileNumber);
        if (!checkPhoneNumber.valid) {
          phoneCheck.push(checkPhoneNumber);
        }
      }
    }
    checkDuplicateEmail = emailCheck.filter((emailObj) => emailObj.statusCode !== 200).length > 0;
    checkInvalidPhone = phoneCheck.filter((phoneObj) => !phoneObj.valid).length > 0;
  }
  const isAdultFormErrorExist = checkValidation.length > 0 || checkDuplicateEmail || checkInvalidPhone;
  return { isAdultFormErrorExist };
};

export const validateChildForm = async (clientVal: newClientForm['clientProfiles']) => {
  const childData = clientVal.filter((childObj) => childObj.role === 'child');
  const guardianData = clientVal.filter((childObj) => childObj.role !== 'child');

  const checkChildValidation = childData.filter((clientField) => {
    const clientForm = validationChildAndYoungPersonField(clientField);
    return Object.values(clientForm as ClientDetails).some((value) => value !== '');
  });

  const checkGuardianValidation = guardianData.filter((clientField) => {
    const clientGuardianForm = validationChildGuardianField({ ...clientField });
    return Object.values(clientGuardianForm as ClientDetails).some((value) => value !== '');
  });

  const isChildFieldFormErrorExist = checkChildValidation.length > 0;
  const isGuardianFormErrorExist = checkGuardianValidation.length > 0;
  const isChildFormErrorExist = isChildFieldFormErrorExist || isGuardianFormErrorExist;

  return { isChildFormErrorExist };
};

export const validateYoungForm = async (
  token: string,
  clientVal: newClientForm['clientProfiles'],
  profileType?: clientProfilesInterface['profileType'],
  excludeEmailChecking?: string[]
) => {
  const youngData = clientVal.filter((youngObj) => youngObj.role === 'youngPerson');
  const guardianData = clientVal.filter((youngObj) => youngObj.role !== 'youngPerson');

  const checkYoungValidation = youngData.filter((clientField) => {
    const clientForm = validationYoungField({ ...clientField, profileType });
    return Object.values(clientForm as ClientDetails).some((value) => value !== '');
  });

  let isEmailDuplicated = false;
  let isInvalidPhone = false;

  if (checkYoungValidation.length <= 0) {
    let emailCheck = [];
    let phoneCheck = [];
    if (youngData[0].email && !excludeEmailChecking?.includes(youngData[0].email)) {
      const checkEmail = await validateEmail(token, youngData[0].email);
      if (checkEmail.statusCode !== 200) {
        emailCheck.push(checkEmail);
      }
    }

    if (youngData[0].mobileNumber) {
      const checkPhoneNumber = await validatePhoneNumber(youngData[0].mobileNumber);
      if (!checkPhoneNumber.valid) {
        phoneCheck.push(checkPhoneNumber);
      }
    }
    isEmailDuplicated = emailCheck.filter((emailObj) => emailObj.statusCode !== 200).length > 0;
    isInvalidPhone = phoneCheck.filter((phoneObj) => !phoneObj.valid).length > 0;
  }

  const checkGuardianValidation = guardianData.filter((clientField, index) => {
    const clientGuardianForm = validationYoungGuardianField({
      ...clientField,
      profileType,
      isFirstGuardian: index === 0
    });
    return Object.values(clientGuardianForm as ClientDetails).some((value) => value !== '');
  });

  const isYoungFieldFormErrorExist = checkYoungValidation.length > 0;
  const isGuardianFormErrorExist = checkGuardianValidation.length > 0;
  const isYoungFormErrorExist =
    isYoungFieldFormErrorExist || isGuardianFormErrorExist || isEmailDuplicated || isInvalidPhone;

  return { isYoungFormErrorExist };
};

export const validateCoupleForm = async (
  token: string,
  clientVal: newClientForm['clientProfiles'],
  oldClientVal?: newClientForm['clientProfiles'],
  profileType?: clientProfilesInterface['profileType']
) => {
  const checkClientValidation = clientVal.filter((clientField, index) => {
    const clientForm = validationClientFormField({
      ...clientField,
      isFirstClient: index === 0,
      profileType: profileType
    });
    return Object.values(clientForm as ClientDetails).some((value) => value !== '');
  });
  let isEmailInUse = false;
  let checkInvalidPhone = false;
  const mailList = clientVal.map((client) => client.email);
  const isDuplicateEmail =
    mailList.filter((mail, index) => mail && mail.length > 0 && mailList.indexOf(mail) !== index).length > 0;
  const phoneList = clientVal.map((client) => client.mobileNumber);
  const isDuplicatePhone =
    phoneList.filter((phone, index) => phone && phone.length > 3 && phoneList.indexOf(phone) !== index).length > 0;

  const needUniquenessEmailChecking = (email: string, clientId: string) => {
    if (_.isEmpty(oldClientVal)) {
      return true;
    } else {
      const oldClient = oldClientVal?.find((client) => client.email === email);
      return _.isEmpty(oldClient) || clientId !== oldClient?._id;
    }
  };

  const isNoContact = clientVal.filter((obj) => obj.isPrimaryContact).length < 1;

  if (checkClientValidation.length <= 0 && !isNoContact) {
    let emailCheck = [];
    let phoneCheck = [];
    for (const clientObj of clientVal) {
      if (
        clientObj.email &&
        !_.isEmpty(clientObj.email) &&
        needUniquenessEmailChecking(clientObj.email, clientObj._id)
      ) {
        const checkEmail = await validateEmail(token, clientObj.email);
        if (checkEmail.statusCode !== 200) {
          emailCheck.push(checkEmail);
        }
      }
      if (clientObj.mobileNumber && clientObj.mobileNumber.length > 3) {
        const checkPhoneNumber = await validatePhoneNumber(clientObj.mobileNumber);
        if (!checkPhoneNumber.valid) {
          phoneCheck.push(checkPhoneNumber);
        }
      }
    }
    isEmailInUse = emailCheck.filter((emailObj) => emailObj.statusCode !== 200).length > 0;
    checkInvalidPhone = phoneCheck.filter((phoneObj) => !phoneObj.valid).length > 0;
  }
  const isClientFormErrorExist = checkClientValidation.length > 0 || isEmailInUse || checkInvalidPhone || isNoContact;
  return { isClientFormErrorExist, isDuplicateEmail, isDuplicatePhone };
};
