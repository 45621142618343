import { SetStateAction, useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { MedicareItemDuration, MedicareItemFormat, MedicareItemMode } from '../../../../../interfaces';
import { ClaimFormData, ClaimFormErrors } from '../initValue';

import { TIME_FORMAT } from 'pages/Calendar/components/Modals/FinderWizardModal/constants';

import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { getAppointmentById } from 'utils/http/ScheduleService/Appointments/Appointments';
import { validateClaimForm } from '../validation';

export const usePrepopulateFormFromAppointment = ({
  token,
  appointmentId,
  setClaimForm,
  setErrors
}: {
  token: string;
  appointmentId?: string;
  setClaimForm: (claimForm: SetStateAction<ClaimFormData>) => void;
  setErrors: (errors: SetStateAction<ClaimFormErrors>) => void;
}) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

  const [isPrepopulateLoading, setIsPrepopulateLoading] = useState(true);

  const prepopulateFormFromAppointment = useCallback(
    async (appointmentId: string) => {
      setIsPrepopulateLoading(true);

      try {
        const appointmentResponse = await getAppointmentById(token, accountId, appointmentId);
        const appointment = (await appointmentResponse.json()) as AppointmentSlots;

        const prepopulatedForm: Partial<ClaimFormData> = {};

        if ((isEdgeAdminView || isEdgeReceptionist) && appointment.clinicianId) {
          prepopulatedForm.clinicianId = appointment.clinicianId;
        }

        if (appointment.clientRecord) {
          prepopulatedForm.format = MedicareItemFormat.OneToOne;

          const duration = moment(appointment.endTime, TIME_FORMAT).diff(
            moment(appointment.startTime, TIME_FORMAT),
            'minutes'
          );

          prepopulatedForm.duration =
            duration < 50 ? MedicareItemDuration.OneToOneLessThan50 : MedicareItemDuration.OneToOneMoreThan50;

          prepopulatedForm.mode =
            appointment.deliveryType === DeliveryType.FaceToFace
              ? MedicareItemMode.FaceToFace
              : appointment.deliveryType === DeliveryType.VideoCall
              ? MedicareItemMode.VideoCall
              : [DeliveryType.PhoneCall, DeliveryType.PhoneCallDialClient].includes(
                  appointment.deliveryType as DeliveryType
                )
              ? MedicareItemMode.PhoneCall
              : MedicareItemMode.Telehealth;
        } else if (appointment.group) {
          prepopulatedForm.duration = MedicareItemDuration.GroupMoreThan60;
          prepopulatedForm.format = MedicareItemFormat.Group;
          prepopulatedForm.mode =
            appointment.deliveryType === DeliveryType.FaceToFace
              ? MedicareItemMode.FaceToFace
              : MedicareItemMode.Telehealth;
        }

        setClaimForm((claimForm) => {
          const newForm = { ...claimForm, ...prepopulatedForm };

          const errors = validateClaimForm(newForm);
          setErrors(errors);

          return newForm;
        });
      } catch (ex) {
        console.info('Unable to prepopulate form from appointment');
      }

      setIsPrepopulateLoading(false);
    },
    [accountId, token, isEdgeAdminView, isEdgeReceptionist, setClaimForm, setErrors]
  );

  useEffect(() => {
    if (appointmentId) {
      prepopulateFormFromAppointment(appointmentId);
    }
  }, [appointmentId, prepopulateFormFromAppointment]);

  return { isPrepopulateLoading };
};
