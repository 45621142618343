import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { TFunction } from 'react-i18next';

export interface PractitionersListing {
  practitionerList: PractitionersDetailsInterface[];
  paging: {
    page: number;
    perPage: number;
    totalItem: number;
    totalPage: number;
  };
}

export interface WorkingScheduleInterface {
  monday: AvailableTime;
  tuesday: AvailableTime;
  wednesday: AvailableTime;
  thursday: AvailableTime;
  friday: AvailableTime;
  saturday: AvailableTime;
  sunday: AvailableTime;
}

export interface PractitionersDetailsInterface {
  _id: string;
  avatar: string;
  name: string;
  mobileNumber: string;
  email: string;
  status: PractitionersStatus['status'];
  accessRight: string;
  caseLoad: {
    availableSlot: number;
    activeClient: number;
    allocations?: Allocation[];
  };
  workTimeZone?: string;
  workingSchedule: WorkingScheduleInterface;
  medicare?: {
    role: ClinicianMedicareRole;
  };
  title?: string;
}

export interface PractitionersDetailsWithSelected extends PractitionersDetailsInterface {
  isSelected?: boolean;
}

export interface CalendarPractitionerList
  extends Omit<PractitionersDetailsInterface, 'email' | 'status' | 'accessRight' | 'workingSchedule' | 'caseLoad'> {
  workingSchedule?: WorkingScheduleInterface;
}

export interface AvailableTime {
  isActive: boolean;
  timeSlots?: TimeSlot[];
}

interface TimeSlot {
  startTime: string;
  endTime: string;
}

export interface PractitionersStatus {
  status?: 'active' | 'inactive';
}

export interface PractitionersListingCount {
  active: number;
  inactive: number;
}

export interface filterParameter {
  status?: PractitionersStatus['status'];
  clinicianIds?: string;
  perPage: number;
  page: number | string;
  q?: string;
}

export enum AllocationCategory {
  Rebate = 'rebate',
  BulkBill = 'bulkBill',
  NDIS = 'ndis',
  WorkCover = 'workCover',
  OutOfPocket = 'outOfPocket'
}

export enum AllocationType {
  Percentage = 'percentage',
  Fixed = 'fixed'
}

export interface Allocation {
  category: AllocationCategory;
  type: AllocationType;
  value: string;
}

export const getUserRoles: (t: TFunction) => { [key: string]: string } = (t) => ({
  admin: t('options.roles.super_user'),
  user: t('options.roles.practitioner'),
  receptionist: t('options.roles.administrator'),
  mentor: t('options.roles.mentor')
});
