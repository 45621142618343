import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import styles from './ClientStatusBadge.module.scss';

interface ClientStatusBadgeProps {
  recordStatus: clientRecordsInterface['recordStatus'];
}

const ClientStatusBadge = ({ recordStatus }: ClientStatusBadgeProps) => {
  const generateType = (profileType: clientRecordsInterface['recordStatus']) => {
    switch (profileType) {
      case 'active':
        return {
          label: 'ACTIVE',
          badgeStyles: styles.activeBadge
        };
      case 'closed':
        return {
          label: 'CLOSED',
          badgeStyles: styles.closedBadge
        };
      case 'waitlist':
        return {
          label: 'WAITLIST',
          badgeStyles: styles.waitlistBadge
        };
      default:
        console.error('Something went wrong while trying to generate your record status badge');
        return {
          label: '',
          badgeStyles: ''
        };
    }
  };

  return (
    <div className={generateType(recordStatus).badgeStyles}>
      <div>{generateType(recordStatus).label}</div>
    </div>
  );
};

export default ClientStatusBadge;
