import { useEffect, useState } from 'react';
import { Field, useField } from 'formik';

import { InvoiceSettings } from 'pages/Invoices/interface';
import { InvoicedItem } from '../../../../interface';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';
import TextArea from 'components/TextArea/TextArea';
import TaxRate from './components/TaxRate/TaxRate';

import styles from './ItemsList.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { config } from 'config/config';

const MONEY_SYMBOL = config.currencySymbol;
const TAX_LABEL = config.taxLabel;

interface ItemsListProps {
  invoiceSettings?: InvoiceSettings;
  onRemoveItem: (appointmentId?: string) => void;
}

const calculateTaxValue = (tax: string, cost: string) => {
  const taxValue = (Number(tax) / 100) * Number(cost);

  if (!isNaN(taxValue)) {
    return taxValue.toFixed(2);
  }
};

const calculateTotalValue = (tax: string, cost: string) => {
  const totalValue = (Number(tax) / 100) * Number(cost) + Number(cost);

  if (!isNaN(totalValue)) {
    return totalValue.toFixed(2);
  }
};

const ItemsList = ({ invoiceSettings, onRemoveItem }: ItemsListProps) => {
  const [{ value: items }, { error: itemsError, touched: itemsTouched }, { setValue: setItemsValue }] =
    useField<InvoicedItem[]>('items');
  const [{ value: tax }, , { setValue: setTaxValue }] = useField<string>('taxRate');

  const [isTaxFieldVisible, setisTaxFieldVisible] = useState(false);

  useEffect(() => {
    if (invoiceSettings?.taxRate !== undefined && !isNaN(invoiceSettings.taxRate) && tax === '0') {
      handleSetTaxRate(invoiceSettings.taxRate.toFixed(2));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceSettings]);

  const handleTaxButtonClick = () => {
    setisTaxFieldVisible(!isTaxFieldVisible);
  };

  const handleSetTaxRate = (taxRate: string) => {
    setTaxValue(taxRate);
    setisTaxFieldVisible(false);
  };

  const handleAddItem = () => {
    setItemsValue([...items, { cost: '', overview: '', appointmentDate: '' }]);
  };

  const handleRemoveItem = (index: number) => () => {
    const foundItem = items[index];

    const newItems = [...items];
    newItems.splice(index, 1);

    setItemsValue(newItems);
    onRemoveItem(foundItem.appointmentId);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Items</div>
      <div className={styles.headingContainer}>
        <span className={styles.heading}>#</span>
        <span className={styles.heading}>OVERVIEW</span>
        <span className={styles.heading}>COST ({MONEY_SYMBOL})</span>
        <span className={styles.heading}>
          {isTaxFieldVisible && <TaxRate defaultTaxRate={tax} onSetTaxRate={handleSetTaxRate} />}
          {TAX_LABEL} (
          <div className={styles.taxButtonWrapper}>
            <ButtonAlt
              variant={'text'}
              size={'small'}
              className={styles.taxButton}
              type="button"
              onClick={handleTaxButtonClick}
            >
              {tax}%
            </ButtonAlt>
          </div>
          )
        </span>
        <span className={styles.heading}>TOTAL ({MONEY_SYMBOL})</span>
      </div>
      {items.map(({ cost, tags }, index) => (
        <div className={styles.itemContainer} key={index}>
          <ButtonAlt variant={'text'} size={'small'} error type="button" onClick={handleRemoveItem(index)}>
            Remove
          </ButtonAlt>
          <span className={styles.index}>{index + 1}</span>
          <div className={styles.overviewContainer}>
            <Field name={`items.${index}.overview`}>
              {({ field, meta }: any) => (
                <>
                  <TextArea containerClass={styles.overviewInput} {...field} />
                  <ErrorMessage error={meta.error} visible={!!meta.touched} />
                </>
              )}
            </Field>
            {!!tags?.length && (
              <div className={styles.tags}>
                {tags.map((status, index) => (
                  <div className={styles.tag} key={index}>
                    {status}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles.costInput}>
            <FormikMaterialInput id={`items.${index}.cost`} name={`items.${index}.cost`} label="" />
          </div>
          <span className={styles.tax}>{calculateTaxValue(tax, cost)}</span>
          <span className={styles.total}>{calculateTotalValue(tax, cost)}</span>
        </div>
      ))}
      <ButtonAlt
        variant={'text'}
        className={styles.addButton}
        type="button"
        onClick={handleAddItem}
        icon={'add_circle'}
      >
        Add New item
      </ButtonAlt>
      <ErrorMessage
        className={styles.error}
        error={typeof itemsError === 'string' ? itemsError : ''}
        visible={itemsTouched}
      />
    </div>
  );
};

export default ItemsList;
