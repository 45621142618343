import { useEffect, useMemo, useState } from 'react';
import styles from './EditCoupleForm.module.scss';
import { ClientDetails, newClientForm } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import ClientForm from 'components/AddPatientModalV2/formType/CoupleForm/components/ClientForm/ClientForm';
import { editClientForm } from 'components/EditClientModal/EditClientModalInterface';
import { useFetchSignedUpProfile } from 'components/EditClientModal/hooks/CheckSignUpProfile';
import { useGetAccessToken } from 'utils/hooks/token';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { ClientAddress, clientProfilesInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';
import classnames from 'classnames';
import MaterialInput from '../../../MaterialInput/MaterialInput';
import { debounce } from 'lodash';
import { validateCaseId } from '../../../AddPatientModalV2/formType/AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import ProfileStatusButtons from '../../components/profileStatusButtons';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import InfoCTABox from 'components/InfoCTABox/InfoCTABox';
import { TIMEZONE_OPTIONS } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/ProfileWorkingSchedule/ProfileWorkingSchedule';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface EditCoupleFormProps {
  clientRecordId: string;
  clientDetail: editClientForm['clientProfiles'];
  onChangeClientField: any;
  checkValidation: boolean;
  submitStatus: '' | 'active' | 'finished';
  onClickSubmit: (options?: { withTriage?: boolean }) => void;
  profileType: clientProfilesInterface['profileType'];
  onChangeProfileType: (val: clientProfilesInterface['profileType']) => void;
  caseIdValue: editClientForm['caseId'];
  onChangeCaseId: (value: editClientForm['caseId']) => void;
  excludeCaseIdChecking?: string;
  recordStatus: editClientForm['recordStatus'];
  onStatusProfileChange: (val: 'active' | 'waitlist' | 'closed') => void;
  onClickSaveClosedClient: () => void;
  showOnboardingCTA: boolean;
  noUnsavedChanges?: boolean;
  timeZone: string;
  onChangeTimeZone: (value: editClientForm['timeZone']) => void;
  clientAddress: ClientAddress;
  onChangeClientAddress: (address: ClientAddress) => void;
  onPlaceSelected: (address: ClientAddress) => void;
}

const EditCoupleForm = (props: EditCoupleFormProps) => {
  const {
    clientDetail,
    checkValidation,
    submitStatus,
    onChangeClientField,
    clientRecordId,
    profileType,
    caseIdValue,
    onChangeCaseId,
    excludeCaseIdChecking,
    onClickSubmit,
    recordStatus,
    onStatusProfileChange,
    onClickSaveClosedClient,
    showOnboardingCTA,
    noUnsavedChanges,
    timeZone,
    onChangeTimeZone,
    clientAddress,
    onChangeClientAddress,
    onPlaceSelected
    // onChangeProfileType,
  } = props;
  const { token } = useGetAccessToken();
  const { isClientTimeZoneEnabled } = useGetFeatureToggle();
  const { signedUpProfiles, isSUPLoading } = useFetchSignedUpProfile(token, clientRecordId);

  const [duplicateCaseId, setDuplicateCaseId] = useState(false);
  const [isCheckingCaseIdDuplicate, setIsCheckingCaseIdDuplicate] = useState(false);

  useEffect(() => {
    const isSameCaseIdValue = excludeCaseIdChecking === caseIdValue;
    if (checkValidation && caseIdValue && caseIdValue.length > 0 && !isSameCaseIdValue) {
      validateDuplicateCaseId(caseIdValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const handleChangeClientField = (val: ClientDetails) => {
    const newClientField = [];
    for (let obj of clientDetail) {
      newClientField.push({
        ...obj,
        firstName: obj._id === val._id ? val.firstName : obj.firstName,
        lastName: obj._id === val._id ? val.lastName : obj.lastName,
        email: obj._id === val._id ? val.email : obj.email,
        mobileNumber: obj._id === val._id ? val.mobileNumber : obj.mobileNumber,
        isPrimaryContact: obj._id === val._id ? val.isPrimaryContact : obj.isPrimaryContact,
        communicationPreference: obj._id === val._id ? val.communicationPreference : obj.communicationPreference
      });
    }
    onChangeClientField(newClientField);
  };

  const debouncedCheckCaseIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const resDupCaseId = await validateCaseId(token, value);
        setDuplicateCaseId(resDupCaseId.statusCode !== 200);
        setIsCheckingCaseIdDuplicate(false);
      }, 1000),
    [token]
  );

  const validateDuplicateCaseId = async (newCaseIdValue: string) => {
    if (token) {
      setIsCheckingCaseIdDuplicate(true);
      await debouncedCheckCaseIdDuplicate(newCaseIdValue);
    }
  };

  const handleCaseIdChange = async (newCaseIdValue: string) => {
    onChangeCaseId(newCaseIdValue);
    const isSameCaseIdValue = excludeCaseIdChecking === newCaseIdValue;
    if (checkValidation && newCaseIdValue.length > 0 && !isSameCaseIdValue) {
      await validateDuplicateCaseId(newCaseIdValue);
    } else {
      setDuplicateCaseId(false);
    }
  };

  const [t] = useTranslation();

  return isSUPLoading ? (
    <div className={styles.loadingWrapper}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>
      {/* TODO
      <EditProfileType recordOnly profileTypeProps={profileType} onChangeProfileTypeProps={onChangeProfileType} /> */}
      <div className={styles.clientDetailTitle}>COUPLE PROFILE DETAILS</div>
      <div>
        <div className={styles.subTitle}>PROFILE STATUS</div>
        <ProfileStatusButtons
          value={recordStatus}
          onChange={onStatusProfileChange}
          disableWaitlistButton={profileType === 'full'}
          noUnsavedChanges={noUnsavedChanges}
        />
      </div>
      {showOnboardingCTA && (
        <InfoCTABox ctaOnClick={() => onClickSubmit({ withTriage: true })} ctaDisabled={submitStatus !== ''} />
      )}
      {isClientTimeZoneEnabled && (
        <MaterialSelect
          className={styles.clientTimeZoneSelect}
          id={'timeZone'}
          label={'CLIENT TIMEZONE'}
          isSearchable={false}
          options={TIMEZONE_OPTIONS}
          value={timeZone}
          onChange={(value: string) => onChangeTimeZone(value)}
        />
      )}
      {recordStatus !== RecordStatus.Closed && (
        <>
          <div className={classnames(styles.fieldContainer, checkValidation && duplicateCaseId && styles.fieldError)}>
            <MaterialInput
              id={`clientCaseId`}
              label={t('form.client_reference')}
              onChange={(e) => handleCaseIdChange(e.target.value)}
              isLoading={isCheckingCaseIdDuplicate}
              value={caseIdValue}
              maxLength={20}
              required
            />
            {checkValidation && duplicateCaseId && (
              <div className={styles.fieldError}>{t('form.error.duplicate_client_reference')}</div>
            )}
          </div>
          <div className={styles.clientsContainer}>
            <div className={styles.clientTitle}>{t('label.client.capitalize').toUpperCase()} 1</div>
            <ClientForm
              id={`Client0`}
              clientDetail={clientDetail[0]}
              onChangeClientField={handleChangeClientField}
              checkValidation={checkValidation}
              other={clientDetail[1]}
              disableEmailField={signedUpProfiles.includes(clientDetail[0]._id)}
              isFirstClient
              capabilityData={{ profileType: profileType } as newClientForm['clientCapability']}
              showAddress
              clientAddress={clientAddress}
              onChangeClientAddress={onChangeClientAddress}
              onPlaceSelected={onPlaceSelected}
            />
          </div>
          <div className={styles.clientsContainer}>
            <div className={styles.clientTitle}>{t('label.client.capitalize').toUpperCase()} 2</div>
            <ClientForm
              id={`Client1`}
              clientDetail={clientDetail[1]}
              onChangeClientField={handleChangeClientField}
              checkValidation={checkValidation}
              other={clientDetail[0]}
              disableEmailField={signedUpProfiles.includes(clientDetail[1]._id)}
              isFirstClient={false}
              capabilityData={{ profileType: profileType } as newClientForm['clientCapability']}
              showAddress={false}
              clientAddress={clientAddress}
              onChangeClientAddress={onChangeClientAddress}
              onPlaceSelected={onPlaceSelected}
            />
          </div>
        </>
      )}
      <div className={styles.buttonContainer}>
        <ButtonAlt
          status={submitStatus}
          disabled={submitStatus !== ''}
          onClick={recordStatus !== RecordStatus.Closed ? () => onClickSubmit() : onClickSaveClosedClient}
        >
          Save
        </ButtonAlt>
      </div>
    </div>
  );
};

export default EditCoupleForm;
