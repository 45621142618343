import { useEffect, useState, useRef } from 'react';
import { notification } from 'antd';
import moment from 'moment';
import querystring from 'query-string';

import { RoomType } from 'interfaces/Schedule/Room';

import { getRoomList, getAvailableRoomList } from 'utils/http/ScheduleService/Rooms/rooms';
import { useGetAccountPackageView } from '../GetAccountInfo/accountPackageView';
import { security } from 'utils/security';

const ROOM_DATE_FORMAT = 'YYYY-MM-DD';

export const useFetchRoomList = () => {
  const { isEdgeUser } = useGetAccountPackageView();

  const [roomList, setRoomList] = useState<RoomType[]>([]);
  const [isRoomListLoading, setIsRoomListLoading] = useState(true);

  const fetchRoomList = async () => {
    if (isEdgeUser) {
      setIsRoomListLoading(true);
      const token = await security.getAccessTokenSilently();

      const response = await getRoomList(token);
      const data = await response.json();

      setRoomList(data);

      setIsRoomListLoading(false);
    }
  };

  const reloadRoomList = () => fetchRoomList();

  useEffect(() => {
    fetchRoomList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { roomList, isRoomListLoading, reloadRoomList };
};

const usePrevious = (value: any) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useFetchAvailableRoomList = (values?: any, timezone?: string, bookedAppointmentId?: string) => {
  const { isEdgeUser } = useGetAccountPackageView();

  const [availableRoomList, setAvailableRoomList] = useState<RoomType[]>([]);
  const [isAvailableRoomListLoading, setIsAvailableRoomListLoading] = useState(false);

  const { date, isRecurring, occurrences, frequency, startTime, endTime } = values;
  const previousValues: any = usePrevious(values) || {};

  const fetchAvailableRoomList = async () => {
    if (isEdgeUser) {
      setIsAvailableRoomListLoading(true);

      // Calculate dates with occurrences and frequency
      let dates = date;
      if (isRecurring && occurrences && occurrences > 0 && frequency && frequency > 0) {
        for (let i = 1; i <= occurrences; i++) {
          const nextDay = moment(date, ROOM_DATE_FORMAT);
          nextDay.add(frequency * i, 'day');
          dates = dates.concat(`;${nextDay.format(ROOM_DATE_FORMAT)}`);
        }
      }

      const qParam = querystring.stringify({
        bookedAppointmentId,
        dates,
        startTime,
        endTime,
        timezone
      });

      try {
        const token = await security.getAccessTokenSilently();

        const response = await getAvailableRoomList(token, qParam);
        const data = await response.json();

        setAvailableRoomList(data || []);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get available rooms' });
      }

      setIsAvailableRoomListLoading(false);
    }
  };

  useEffect(() => {
    if (
      date &&
      startTime &&
      endTime &&
      (date !== previousValues.date ||
        startTime !== previousValues.startTime ||
        endTime !== previousValues.endTime ||
        (isRecurring && occurrences > 0 && frequency > 0) ||
        (!isRecurring && previousValues.isRecurring && previousValues.occurrences > 0 && previousValues.frequency > 0))
    ) {
      fetchAvailableRoomList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return { availableRoomList, isAvailableRoomListLoading, fetchAvailableRoomList };
};
