import { useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './SortBtn.module.scss';

export enum SortType {
  asc = 1,
  desc = -1,
  noSort = 0
}

interface SortBtnProps {
  id?: string;
  selectedSortName?: SortType;
  onChangeSort: (val: SortType, columnName?: string) => void;
  disabled?: boolean;
  selectedColumn?: string;
  columnName?: string;
}

const SortBtn = ({ id, onChangeSort, selectedSortName = 0, disabled, selectedColumn, columnName }: SortBtnProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [isAscSort, setIsAscSort] = useState<SortType>(selectedSortName);

  useEffect(() => {
    setIsAscSort(selectedSortName);
  }, [selectedSortName]);

  const handleSortBtn = (sortVal: SortType) => {
    setIsAscSort(sortVal);
    onChangeSort(sortVal, columnName);
  };

  return (
    <div className={isEdgeAdminView ? styles.adminTheme : styles.userTheme}>
      <div
        id={id}
        className={styles.container}
        onClick={() => (!disabled ? handleSortBtn(isAscSort === SortType.asc ? SortType.desc : SortType.asc) : false)}
      >
        <i
          className={`material-icons ${
            columnName === selectedColumn && isAscSort === SortType.desc ? styles.sortIconActive : styles.sortIcon
          }`}
        >
          keyboard_arrow_up
        </i>
        <i
          className={`material-icons ${
            columnName === selectedColumn && isAscSort === SortType.asc ? styles.sortIconActive : styles.sortIcon
          }`}
        >
          keyboard_arrow_down
        </i>
      </div>
    </div>
  );
};

export default SortBtn;
