import moment from 'moment';
import styles from './AppointmentHistory.module.scss';
import { PatientActivity } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsActivity/components/ActivityFeed/ActivityFeed';
import { getActorName } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAppointments/components/AppointmentsListItem/AppointmentsListItem';
import { useAppSelector } from 'redux/hooks';
import { practiceInfo } from 'redux/slice/practiceDataSlice';

interface AppointmentHistoryProps {
  activityFeeds: PatientActivity[];
  clientName: string;
}

const AppointmentHistory = ({ activityFeeds, clientName }: AppointmentHistoryProps) => {
  const { data: practiceData } = useAppSelector(practiceInfo);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.varian}>VARIATION</div>
        <div className={styles.detail}>DETAIL</div>
        <div className={styles.time}>TIME</div>
        <div className={styles.actionBy}>ACTION BY</div>
      </div>
      <div className={styles.content}>
        {activityFeeds.map((activity, index) => (
          <div className={styles.historyItem} key={index}>
            <div className={styles.varian}>{activityFeeds.length - index}</div>
            <div className={styles.detail}>
              <span dangerouslySetInnerHTML={{ __html: activity.description }} />
            </div>
            <div className={styles.time}>{moment(activity.createdAt).format('DD / MM / YYYY')}</div>
            <div className={styles.actionBy}>
              {getActorName({
                actor: activity.actor,
                clientName,
                clinicianName: activity.clinicianName,
                practiceName: practiceData?.name || ''
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppointmentHistory;
