export const RECURRING_APPOINTMENT_FREQUENCY_OPTIONS = [
  { label: 'Day', value: '1' },
  { label: 'Week', value: '7' },
  { label: 'Fortnight', value: '14' },
  { label: 'Month', value: '28' }
];

export const RECURRING_APPOINTMENT_AMOUNT_OPTIONS = [
  { label: 'This and the next', value: '1' },
  { label: '2 appointments', value: '2' },
  { label: '3 appointments', value: '3' },
  { label: '4 appointments', value: '4' },
  { label: '5 appointments', value: '5' },
  { label: '6 appointments', value: '6' },
  { label: '7 appointments', value: '7' },
  { label: '8 appointments', value: '8' },
  { label: '9 appointments', value: '9' },
  { label: '10 appointments', value: '10' },
  { label: '11 appointments', value: '11' },
  { label: '12 appointments', value: '12' },
  { label: '13 appointments', value: '13' },
  { label: '14 appointments', value: '14' },
  { label: '15 appointments', value: '15' },
  { label: '16 appointments', value: '16' },
  { label: '17 appointments', value: '17' },
  { label: '18 appointments', value: '18' },
  { label: '19 appointments', value: '19' },
  { label: '20 appointments', value: '20' },
  { label: '21 appointments', value: '21' },
  { label: '22 appointments', value: '22' },
  { label: '23 appointments', value: '23' },
  { label: '24 appointments', value: '24' },
  { label: '25 appointments', value: '25' },
  { label: '26 appointments', value: '26' },
  { label: '27 appointments', value: '27' },
  { label: '28 appointments', value: '28' }
];
