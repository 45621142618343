import { ContactDetails } from 'interfaces/documentService/ContactDetails';
import { ClientRecordType } from '../Clients/clientsRecord';

export enum LetterStatus {
  Draft = 'draft',
  Published = 'published'
}

export enum FaxJobStatus {
  Received = 'RECEIVED',
  Scheduled = 'SCHEDULED',
  SendFailed = 'SEND_FAILED',
  SendProcessing = 'SEND_PROCESSING',
  Sent = 'SENT'
}

export interface ClientProfile {
  _id: string;
  name: string;
  initials?: string;
  initialsBackgroundColor?: string;
  avatar?: string;
}

export interface ClientRecord {
  _id: string;
  recordType: ClientRecordType;
  clientProfiles: ClientProfile[];
}

export interface LetterAccessor {
  _id?: string;
  email?: string;
  isClient?: boolean;
  uniqueLink: string;
  note?: string;
  sendAt?: string;
  sentAt?: string;
}

export interface FaxLetter {
  _id: string;
  faxJobId: string;
  recipientName?: string;
  sendAt: string;
  sendFrom: string;
  sendTo: string;
  sentAt?: string;
  status: FaxJobStatus;
}

export interface ShareDetails {
  sendAt?: Date | string;
  sendClient?: boolean;
  sendTo: string[];
  note?: string;
  accessors?: LetterAccessor[];
  faxes?: FaxLetter[];
}

interface Clinician {
  _id: string;
  avatar?: string;
  email?: string;
  mobileNumber?: string;
  name?: string;
}

interface Practice {
  address?: string;
  logo?: string;
  mobileNumber?: string;
  name: string;
}

export interface ClinicianDetails {
  clinician: Clinician;
  practice?: Practice;
}

export interface Letter {
  _id: string;
  accountId: string;
  isForPractice: boolean;
  isAuthoredByReceptionist: boolean;
  status: LetterStatus;
  statusHistory?: {
    status: LetterStatus;
    statusChangedAt: Date | string;
    statusChangedBy: {
      clinicianAuth0Id: string;
      name: string;
    };
  }[];
  letterName: string;
  body: string;
  issueDate: string;
  clientRecord: ClientRecord;
  clinician: Clinician;
  practice?: Practice;
  contactDetails: ContactDetails['contactDetails'];
  clinicianSignature: {
    type: string;
    textVal: string;
    drawVal: string;
  };
  clinicianSignatureExtraDetails: {
    valediction: string;
    email: string;
    mobileNumber: string;
    name: string;
    other: string;
  };
  shareDetails: ShareDetails;
  attachment?: {
    _id: string;
    title: string;
  };
  createdAt: string;
  updatedAt: string;
  episodeId?: string;
}

export interface PutLetterRequest
  extends Omit<
    Letter,
    | '_id'
    | 'clientRecord'
    | 'accountId'
    | 'isAuthoredByReceptionist'
    | 'statusHistory'
    | 'attachment'
    | 'shareDetails'
    | 'createdAt'
    | 'updatedAt'
  > {
  clientRecord: {
    _id: string;
  };
}

export interface LetterClientTab {
  _id: string;
  status?: string;
  letterName: string;
  lastUpdatedTime?: Date;
  createdAt: string;
}
