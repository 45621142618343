export const JODIT_DISABLE_PLUGINS = 'about,add-new-line,backspace,bold,tune-block';
export const JODIT_TOOLBAR_BUTTONS =
  'paragraph,fontsize,bold,italic,underline,strikethrough,pasteCode,brush,ol,ul,outdent,indent,align,link,image,table';
export const JODIT_INLINE_TOOLBAR_BUTTONS = [
  'paragraph',
  'fontsize',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'pasteCode',
  'brush',
  'ol',
  'ul',
  'outdent',
  'indent',
  'align',
  'link',
  'image',
  'table'
];

export const fullInfo = (medicareEnabled?: boolean) => [
  { id: 1, value: '{{CLIENT_NAME}}' },
  { id: 2, value: '{{CLIENT_DOB}}' },
  { id: 3, value: '{{FIRST_APPOINTMENT_DATE}}' },
  { id: 4, value: '{{RECENT_APPOINTMENT_DATE}}' },
  { id: 5, value: '{{APPOINTMENT_COUNT}}' },
  { id: 6, value: '{{FIRST_ASSESSMENT}}' },
  { id: 7, value: '{{FIRST_ASSESSMENT_DATE}}' },
  { id: 8, value: '{{RECENT_ASSESSMENT}}' },
  { id: 9, value: '{{RECENT_ASSESSMENT_DATE}}' },
  { id: 10, value: '{{REFERRER_NAME}}' },
  { id: 11, value: '{{NUMBER_OF_AUTHORIZED_SESSION}}' },
  { id: 12, value: '{{CLIENT_FIRST_NAME}}' },
  { id: 13, value: '{{NUMBER_OF_SESSIONS_CANCELLED}}' },
  { id: 14, value: '{{NUMBER_OF_SESSIONS_NOT_ATTEND}}' },
  { id: 15, value: '{{GP_NAME}}' },
  ...(medicareEnabled ? [{ id: 16, value: '{{GP_MEDICARE_PROVIDER_NUMBER}}' }] : []),
  { id: 17, value: '{{GP_NAME_OF_PRACTICE}}' },
  { id: 18, value: '{{GP_ADDRESS}}' },
  { id: 19, value: '{{GP_FAX}}' },
  { id: 20, value: '{{GP_LANDLINE}}' },
  { id: 21, value: '{{GP_EMAIL}}' },
  { id: 22, value: '{{CLIENT_POSTCODE}}' },
  { id: 23, value: '{{CLIENT_MOBILE}}' },
  { id: 24, value: '{{CLIENT_EMAIL}}' },
  ...(medicareEnabled ? [{ id: 25, value: '{{MY_PROVIDER_NAME}}' }] : []),
  ...(medicareEnabled ? [{ id: 26, value: '{{MY_PROVIDER_NUMBER}}' }] : []),
  ...(medicareEnabled ? [{ id: 27, value: '{{CLIENT_MEDICARE_NUMBER}}' }] : []),
  ...(medicareEnabled ? [{ id: 28, value: '{{CLIENT_MEDICARE_IRN}}' }] : [])
];

export const basicInfo = [
  { id: 1, value: '{{FIRST_NAME}}' },
  { id: 2, value: '{{LAST_NAME}}' }
];
