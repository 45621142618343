import bent from 'bent';
import { v4 as uuid } from 'uuid';
import { FirstAssessmentResponse, Question, QuestionSet } from 'interfaces/checkInService/firstAssessment';
import { FirstAssessmentStruct, QuestionSetStruct } from 'interfaces/firstAssessment';

const getData = (authKey: string, id: string): Promise<FirstAssessmentResponse> => {
  const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;
  const getFirstAssessmentRequest = bent<FirstAssessmentResponse>(`${url}`, 'GET', 'json', {
    Authorization: `Bearer ${authKey}`
  });

  return getFirstAssessmentRequest(`/first-assessments/${id}`);
};

const massageQuestions = (questions: Question[]) => {
  const massagedQuestions = questions.map((question) => ({
    id: question.structuredQuestionId || uuid(),
    questionId: uuid(),
    ...question
  }));

  return massagedQuestions;
};

const massageQuestionSets = (
  questionSets: QuestionSet[] | undefined,
  firstAssessmentQuestionTemplate: QuestionSetStruct[]
) => {
  const massagedQuestionSets = questionSets?.map(({ sectionName, questions, categoryId }, index) => ({
    id:
      (!categoryId &&
        firstAssessmentQuestionTemplate.find((questionTemplate) => questionTemplate.sectionName === sectionName)?.id) ||
      `oldSet_${index + 1}`,
    sectionName,
    categoryId,
    questions: massageQuestions(questions)
  }));

  return massagedQuestionSets ?? [];
};

const massageData = (
  data: FirstAssessmentResponse,
  firstAssessmentQuestionTemplate: QuestionSetStruct[]
): FirstAssessmentStruct => ({
  id: data.id,
  name: data.name,
  diffName: data.diffName || '',
  tags: data.tags || [],
  note: data.note || '',
  description: data.description || '',
  isPublished: data.isPublished,
  isDefault: data.isDefault,
  backgroundQuestions: data.backgroundQuestions,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
  questionSets: massageQuestionSets(data.questionSets, firstAssessmentQuestionTemplate)
});

export const getFirstAssessmentDetails = async (
  token: string,
  id: string,
  firstAssessmentQuestionTemplate: QuestionSetStruct[]
): Promise<FirstAssessmentStruct> => {
  const firstAssessment = await getData(token, id);
  return massageData(firstAssessment, firstAssessmentQuestionTemplate);
};
