import { HelmControlInterface } from 'interfaces/Profile/Profile';
import styles from './FitPsychologistFilter.module.scss';
import Select from 'components/Select/CommonSelect/Select';
import { SelectInterface } from 'interfaces/caseNote';
import { FIT_DELIVERY_LIST_LABELS, FitDeliveryList, HELM_FUNDING_METHODS } from '../../interfaces/fitFilter';

const DeliveryModeOption: SelectInterface[] = Object.keys(FitDeliveryList).map((key) => ({
  value: key,
  label: FIT_DELIVERY_LIST_LABELS[key as keyof typeof FitDeliveryList]
}));

interface FitPsychologistFilterProps {
  profileValue: HelmControlInterface;
  setValues: (value: HelmControlInterface) => void;
}

const HelmFitPsychologistFilter = ({ profileValue, setValues }: FitPsychologistFilterProps) => {
  const selectStyles = {
    container: (base: any) => ({ ...base, width: '100%' }),
    valueContainer: (base: any) => ({ ...base, paddingLeft: 4 }),
    control: (controlBase: any) => ({
      ...controlBase,
      minHeight: '30px',
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: `1px solid ${styles.greyColor}`,
      borderRadius: 0,
      boxShadow: 'none'
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: styles.blueColor,
      borderRadius: 20,
      padding: 2,
      color: 'white',
      fontSize: '12px'
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'transparent',
        color: styles.removeColor
      }
    })
  };

  const setField = (
    key: 'deliveryModes' | 'servicesAvailable' | 'fundingMethods',
    value?: FitDeliveryList[] & string[]
  ) => {
    setValues({
      ...profileValue,
      [key]: value
    });
  };

  return (
    <div className={styles.section}>
      <div className={styles.title}>Client cover area</div>
      <div className={styles.description}>This will help how client find you in listing view</div>
      <div className={styles.checkBoxRow}>
        <div className={styles.checkboxDetail}>
          <div className={styles.col2}>
            <div className={styles.label}>Delivery Mode</div>
            <Select
              styles={selectStyles}
              options={DeliveryModeOption?.filter(
                (value) => !profileValue.deliveryModes?.includes(value.value as FitDeliveryList)
              )}
              placeholder="Search here"
              closeMenuOnSelect={false}
              noCreateNewOption
              value={
                DeliveryModeOption?.filter((value) =>
                  profileValue.deliveryModes?.includes(value.value as FitDeliveryList)
                ) as any
              }
              isMulti
              onChange={(values: any) =>
                setField(
                  'deliveryModes',
                  (values || []).map((value: any) => value.value)
                )
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.checkBoxRow}>
        <div className={styles.checkboxDetail}>
          <div className={styles.col2}>
            <div className={styles.label}>Funding Methods</div>
            <Select
              styles={selectStyles}
              options={HELM_FUNDING_METHODS?.filter((value) => !profileValue.fundingMethods?.includes(value.value))}
              placeholder="Search here"
              closeMenuOnSelect={false}
              value={
                profileValue.fundingMethods?.map((obj) => ({
                  label: HELM_FUNDING_METHODS.find((listObj) => listObj.value === obj)?.label || obj,
                  value: obj
                })) as any
              }
              isMulti
              onChange={(values: any) =>
                setField(
                  'fundingMethods',
                  (values || []).map((value: any) => value.value)
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelmFitPsychologistFilter;
