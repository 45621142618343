import { resetFilters, resetPaging, selectFilters, setFilters } from 'redux/groups/groupSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './GroupListFilters.module.scss';
import { Skeleton } from 'antd';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetGroupList } from 'redux/endpoints/clinicianProfileServices/getGroupList';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetGroupLeadsList } from 'redux/endpoints/clinicianProfileServices/getGroupLeadList';
import { useMemo } from 'react';
import { debounce } from 'lodash';

const GroupListFilters = () => {
  const dispatch = useAppDispatch();
  const { isEdgeUser } = useGetAccountPackageView();
  const filters = useAppSelector(selectFilters);
  const { isGroupListLoading, paging } = useGetGroupList();
  const {
    groupLeadList,
    setGroupSearch,
    paging: groupLeadPaging,
    isGroupLeadsListLoading,
    isGroupLeadsFetching,
    setGroupLeadsPaging
  } = useGetGroupLeadsList();

  const { selectedGroupLeads } = filters;
  const showClearFilter = selectedGroupLeads.length;
  const totalGroupLeadPage = groupLeadPaging ? Math.ceil(groupLeadPaging.totalItems / groupLeadPaging.perPage) : 1;

  const onChangeGroupLeadFilter = (selectedGroupLeads: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedGroupLeads }));
  };

  const onClearFilter = () => {
    dispatch(resetFilters());
  };

  const debouncedSetSearchText = useMemo(
    () =>
      debounce((value) => {
        setGroupSearch(value);
        setGroupLeadsPaging({ ...groupLeadPaging, page: 1 });
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <div className={styles.numberOfMatchedProfiles}>
          {!isGroupListLoading ? (
            <div>
              {showClearFilter ? 'FILTER matches' : 'Showing'}{' '}
              <span className={styles.highLight}>{paging.totalItems}</span> groups
            </div>
          ) : (
            <div className={styles.loadingProfile}>
              <Skeleton.Input className={styles.loading} active />
            </div>
          )}
        </div>
        <ButtonAlt
          error
          disabled={!showClearFilter}
          size={'small'}
          variant={'text'}
          className={styles.clearFilters}
          onClick={onClearFilter}
        >
          Clear filters
        </ButtonAlt>
      </div>
      <div className={styles.buttonsContainer}>
        {isEdgeUser && (
          <FilterDropdown
            id={'groupLeads'}
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            menuItems={groupLeadList}
            onChangeItem={onToggleFilter(groupLeadList, selectedGroupLeads, onChangeGroupLeadFilter)}
            searchable
            selectedFilterList={selectedGroupLeads}
            showSearchIcon
            showToggleAllButtons
            setSearchText={debouncedSetSearchText}
            loading={isGroupLeadsListLoading}
            hasMoreData={totalGroupLeadPage > groupLeadPaging.page || isGroupLeadsFetching}
            isFetchingMore={isGroupLeadsFetching}
            loadMore={() => {
              if (!isGroupLeadsFetching && groupLeadPaging.page <= totalGroupLeadPage) {
                setGroupLeadsPaging({ ...groupLeadPaging, page: groupLeadPaging.page + 1 });
              }
            }}
          >
            Group Lead
          </FilterDropdown>
        )}
      </div>
    </div>
  );
};

export default GroupListFilters;
