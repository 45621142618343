import { Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';

import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ShareableLinkButton from 'components/ShareableLinkButton/ShareableLinkButton';
import Status from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/SystemConnection/components/ConnectedIntegration/components/components/Status/Status';
import { EFAX_INTEGRATIONS } from 'pages/ControlPanel/ControlPanel/constants';
import { useFetchProfile } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from './EFaxDetails.module.scss';

const EFaxDetails = () => {
  const navigate = useNavigate();
  const { CONTROL_PANEL } = useRoutesGenerator();

  const { profile, isProfileLoading } = useFetchProfile();

  const handleBackButtonClick = () => {
    navigate(CONTROL_PANEL.SYSTEM_CONNECTIONS);
  };

  const { label, status, summary } = EFAX_INTEGRATIONS;

  const isFaxNumbersExist = profile.practice?.inboundFaxNumbers && profile.practice?.inboundFaxNumbers.length > 0;

  return (
    <HelmetWrapper title="Tacklit - System Connections">
      <ContentLayout>
        <div className={styles.container}>
          {isProfileLoading ? (
            <Skeleton active />
          ) : (
            <div className={styles.wrapper}>
              <div className={styles.backBtnContainer}>
                <ButtonAlt
                  size={'medium'}
                  variant={'text'}
                  icon={'arrow_back_ios_new'}
                  iconVariant={IconVariant.Filled}
                  onClick={() => handleBackButtonClick()}
                >
                  View All Integrations
                </ButtonAlt>
              </div>
              <div className={styles.contentsContainer}>
                <div className={styles.statusContainer}>
                  <div>STATUS</div>
                  <div>
                    <Status status={status} />
                  </div>
                </div>
                <div className={styles.detailsContainer}>
                  <div className={styles.nameContainer}>{`Name: ${label}`}</div>
                  {summary && (
                    <div className={styles.summaryContainer}>
                      <div>{`Summary:`}</div>
                      <div>{summary}</div>
                    </div>
                  )}
                  <div className={styles.nameContainer}>Fax Number list</div>
                  {isFaxNumbersExist && (
                    <div className={styles.tableContainer}>
                      <table>
                        <thead>
                          <tr>
                            <th />
                            <th>FAX NUMBER</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {profile.practice?.inboundFaxNumbers.map(({ faxNumber, isPrimary }, index) => (
                            <tr key={index}>
                              <td className={isPrimary ? styles.iconPrimary : styles.icon}>
                                <i className={'material-icons-outlined'}>{isPrimary ? 'star' : 'star_rate'}</i>
                              </td>
                              <td>
                                <div className={styles.flexbox}>
                                  {faxNumber}
                                  <ShareableLinkButton id="copy-fax-number" url={faxNumber} />
                                </div>
                              </td>
                              <td>
                                {isPrimary ? (
                                  <span>
                                    This is a Tacklit default fax number, which you can use to send fax to recipient
                                    only. Please note that you will not be able to access a fax reply by using this
                                    number.
                                  </span>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <div className={styles.contactUsContainer}>
                    {isFaxNumbersExist
                      ? 'Would you like to make change to fax number?'
                      : 'Would you like to add new fax number?'}
                    <a href="mailto:support@tacklit.com">
                      <div className={styles.mailTo}>
                        <span>Contact us</span>
                        <i className={'material-icons'} data-tooltip-id="contact-us">
                          mail_outline
                        </i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default EFaxDetails;
