import { Dropdown, Menu, Skeleton } from 'antd';
import { useField } from 'formik';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import moment from 'moment';
import { Appointment, InvoicedItem } from 'pages/InvoiceGenerator/interface';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterInvoiceAppointmentTags } from 'utils/filterInvoiceAppointmentTags';
import AppointmentItem from './components/AppointmentItem/AppointmentItem';
import GroupAppointmentItem from './components/GroupAppointmentItem/GroupAppointmentItem';
import styles from './IncludeAppointments.module.scss';

interface IncludeAppointmentsProps {
  appointments: Appointment[];
  selectedAppointmentIds: string[];
  isAppointmentsLoading: boolean;
  participationType: ParticipantType;
  isGroupSelected: boolean;
  isClientRecordSelected: boolean;
  onAddAppointment: (appointmentId: string) => void;
  onRemoveAppointment: (appointmentId: string) => void;
}

const IncludeAppointments = ({
  appointments,
  selectedAppointmentIds,
  isAppointmentsLoading,
  participationType,
  isGroupSelected,
  isClientRecordSelected,
  onAddAppointment,
  onRemoveAppointment
}: IncludeAppointmentsProps) => {
  const [{ value: items }, , { setValue }] = useField<InvoicedItem[]>('items');
  const [filter, setFilter] = useState('Unattached');
  const [t] = useTranslation();

  const { addedAppointments, unaddedAppointments } = useMemo(() => {
    const addedAppointments = appointments.filter((appointment) => selectedAppointmentIds.includes(appointment._id));
    let unaddedAppointments = appointments.filter((appointment) => !selectedAppointmentIds.includes(appointment._id));

    if (filter === 'Unattached') {
      unaddedAppointments = unaddedAppointments.filter(
        (appointment) => (!appointment.invoices || appointment.invoices.length === 0) && appointment.type !== 'reserved'
      );
    }

    return { addedAppointments, unaddedAppointments };
  }, [appointments, filter, selectedAppointmentIds]);

  const handleAddAppointment = (appointment: Appointment) => {
    const { _id, date, rate, startTime, endTime, markedStatus = [], clientRecord, sessionTypeName } = appointment;

    const dateText = moment(date, 'YYYY-MM-DD').format('DD MMM YYYY');
    const duration = moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'minute');

    const overview = `${duration} minute appointment${sessionTypeName ? `, ${sessionTypeName}` : ''} on ${dateText}`;
    const appointmentDate = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');

    setValue([
      ...items,
      {
        appointmentId: _id,
        cost: rate?.toFixed(2) || '0.00',
        overview,
        ...(clientRecord && {
          tags: filterInvoiceAppointmentTags(markedStatus)
        }),
        appointmentDate
      }
    ]);

    onAddAppointment(_id);
  };

  const handleRemoveAppointment = (appointment: Appointment) => {
    setValue(items.filter((item) => item.appointmentId !== appointment._id));

    onRemoveAppointment(appointment._id);
  };

  const recipientLabel =
    participationType === ParticipantType.Group ? t('label.group.capitalize') : t('label.client.capitalize');

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <i className={`material-icons-outlined ${styles.icon}`}>event</i>
        <span className={styles.text}>Include appointments</span>
      </div>
      <div>
        {addedAppointments.length > 0 && isAppointmentsLoading ? (
          <Skeleton className={styles.loading} active />
        ) : (
          <>
            {addedAppointments.map((appointment, index) =>
              (participationType === ParticipantType.Group && isGroupSelected) || !isClientRecordSelected ? (
                <GroupAppointmentItem
                  key={index}
                  appointment={appointment}
                  included
                  onAddAppointment={handleAddAppointment}
                  onRemoveAppointment={handleRemoveAppointment}
                />
              ) : (
                <AppointmentItem
                  key={index}
                  appointment={appointment}
                  included
                  onAddAppointment={handleAddAppointment}
                  onRemoveAppointment={handleRemoveAppointment}
                />
              )
            )}
          </>
        )}
      </div>
      <div>
        <div className={styles.filter}>
          Filter:
          <Dropdown
            overlay={
              <Menu onClick={(e) => setFilter(String(e.key))}>
                <Menu.Item key="Unattached">
                  <div>Unattached</div>
                </Menu.Item>
                <Menu.Item key="All">
                  <div>All</div>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <div className={styles.dropdownText}>
              {filter}
              <i className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</i>
            </div>
          </Dropdown>
        </div>

        {!isGroupSelected && !isClientRecordSelected ? (
          <div className={styles.noClient}>{recipientLabel} not selected</div>
        ) : isAppointmentsLoading ? (
          <Skeleton className={styles.loading} active />
        ) : (
          <>
            {unaddedAppointments.length > 0 ? (
              <div className={styles.unaddedAppointments}>
                {unaddedAppointments.map((appointment, index) => {
                  return (participationType === ParticipantType.Group && isGroupSelected) || !isClientRecordSelected ? (
                    <GroupAppointmentItem
                      key={index}
                      appointment={appointment}
                      onAddAppointment={handleAddAppointment}
                      onRemoveAppointment={handleRemoveAppointment}
                    />
                  ) : (
                    <AppointmentItem
                      key={index}
                      appointment={appointment}
                      onAddAppointment={handleAddAppointment}
                      onRemoveAppointment={handleRemoveAppointment}
                    />
                  );
                })}
              </div>
            ) : (
              <div className={styles.noClient}>
                This {recipientLabel.toLowerCase()} has no {filter === 'Unattached' ? 'unattached ' : ''}appointments
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default IncludeAppointments;
