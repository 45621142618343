import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';

import styles from './PractitionerCaseLoad.module.scss';

interface PractitionerCaseLoadProps {
  caseLoadData: PractitionersDetailsInterface['caseLoad'];
}

const PractitionerCaseLoad = ({ caseLoadData }: PractitionerCaseLoadProps) => {
  const openSlotBalance =
    caseLoadData && caseLoadData.availableSlot - caseLoadData.activeClient > 0
      ? caseLoadData.availableSlot - caseLoadData.activeClient
      : 0;
  return (
    <div className={styles.container}>
      <div className={styles.activeWrapper}>
        <div className={styles.value}>{caseLoadData ? caseLoadData.activeClient : 0}</div>
        <div className={styles.label}>Active</div>
      </div>
      <div className={styles.openSlotWrapper}>
        <div className={styles.value}>{openSlotBalance}</div>
        <div className={styles.label}>Open slots</div>
      </div>
    </div>
  );
};

export default PractitionerCaseLoad;
