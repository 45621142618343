import * as yup from 'yup';
import { config } from 'config/config';
import { GeneralPractitionerErrorInterface, GeneralPractitionerInterface } from '../../interface';
import { validateMedicareProviderNumber } from 'utils/helpers/validateMedicareProviderNumber';

const NUMBER_REGEX = /^[0-9]*$/;

export const GPClientValidationSchema = yup.object().shape({
  name: yup.string().required("Please enter General Practitioner's name"),
  mobileNumber: yup
    .string()
    .test('mobileNumber', `Enter at least ${config.mobileNumberMinLength} characters`, (value) => {
      return !value || value.length >= config.mobileNumberMinLength;
    }),
  landline: yup.string().test('landlineValidation', 'Landline number must be number only', (value) => {
    return !value || NUMBER_REGEX.test(value);
  }),
  fax: yup
    .string()
    .test(
      'fax',
      `Fax number must be ${config.mobileNumberMinLength}-${config.mobileNumberMaxLength} digits long.`,
      (value) => {
        return !value || (value.length >= config.mobileNumberMinLength && value.length <= config.mobileNumberMaxLength);
      }
    ),
  email: yup.string().email('Please enter a valid email').nullable(true)
});

export const CLIENT_GP_INITIAL: GeneralPractitionerInterface = {
  _id: '',
  accountId: '',
  name: '',
  practiceName: '',
  address: '',
  landline: '',
  mobileNumber: '',
  email: '',
  fax: '',
  medicareProviderNumber: ''
};

export const validationClientGP = (value: GeneralPractitionerInterface) => {
  const validationErrors: GeneralPractitionerErrorInterface = {
    name: '',
    landline: '',
    mobileNumber: '',
    email: '',
    fax: '',
    medicareProviderNumber: ''
  };

  try {
    GPClientValidationSchema.validateSync(value, { abortEarly: false });

    if (value.medicareProviderNumber) {
      validationErrors.medicareProviderNumber = validateMedicareProviderNumber(value.medicareProviderNumber);
    }

    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error) => {
        if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
          validationErrors[error.path as keyof GeneralPractitionerErrorInterface] = error.message;
        }
      });
    }
    return validationErrors;
  }
};
