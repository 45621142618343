import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { getSessionTypes, getSessionTypesByClinicianId } from 'utils/http/sessionType';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { AppointmentType, ParticipantType, AssignmentMode } from 'interfaces/Schedule/AppointmentType';

export const useFetchAppointmentTypes = (token: string) => {
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>([]);
  const [isAppointmentTypesLoading, setIsAppointmentTypesLoading] = useState(true);

  const fetchAppointmentTypes = async (token: string) => {
    try {
      const appointmentTypesRaw = await getSessionTypes(token);
      const appointmentTypes = await appointmentTypesRaw.json();

      setAppointmentTypes(appointmentTypes.data as AppointmentType[]);
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to get your appointment type records'
      });
    } finally {
      setIsAppointmentTypesLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchAppointmentTypes(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { appointmentTypes, isAppointmentTypesLoading, fetchAppointmentTypes, setAppointmentTypes };
};

export const useFetchAppointmentTypesByClinicianId = (
  token: string,
  clinicianId: string | undefined,
  selectedParticipantType: ParticipantType
) => {
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>([]);
  const [isAppointmentTypesLoading, setIsAppointmentTypesLoading] = useState(true);
  const { isEdgeAdminUser, isEdgeReceptionist, isNormalUserView } = useGetAccountPackageView();

  const fetchAppointmentTypesByClinicianId = async (
    token: string,
    clinicianId: string,
    selectedParticipantType: ParticipantType
  ) => {
    try {
      const appointmentTypesRaw = await getSessionTypesByClinicianId(token, clinicianId, selectedParticipantType);
      const appointmentTypes = await appointmentTypesRaw.json();

      setAppointmentTypes(appointmentTypes.data as AppointmentType[]);
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to get your appointment type records'
      });
    } finally {
      setIsAppointmentTypesLoading(false);
    }
  };

  const fetchAppointmentTypesOfPractice = async (token: string, selectedParticipantType: ParticipantType) => {
    try {
      const assignmentMode =
        selectedParticipantType === ParticipantType.Group ? AssignmentMode.All : AssignmentMode.Practice;
      const appointmentTypesRaw = await getSessionTypes(token, selectedParticipantType, assignmentMode);
      const appointmentTypes = await appointmentTypesRaw.json();

      setAppointmentTypes(appointmentTypes.data as AppointmentType[]);
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to get your appointment type records'
      });
    } finally {
      setIsAppointmentTypesLoading(false);
    }
  };

  useEffect(() => {
    setIsAppointmentTypesLoading(true);
    if (token) {
      if (
        clinicianId &&
        [ParticipantType.OneToOne, ParticipantType.Activity].includes(selectedParticipantType) &&
        (isEdgeAdminUser || isEdgeReceptionist || isNormalUserView)
      ) {
        fetchAppointmentTypesByClinicianId(token, clinicianId, selectedParticipantType);
      } else {
        fetchAppointmentTypesOfPractice(token, selectedParticipantType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clinicianId, selectedParticipantType]);

  return {
    appointmentTypes,
    isAppointmentTypesLoading,
    fetchAppointmentTypesByClinicianId,
    fetchAppointmentTypesOfPractice,
    setAppointmentTypes
  };
};
