import { AttachedClientRecordStatus, GroupStage, GroupTag, Groups } from 'pages/Groups/Interfaces/Groups';

import { appointmentStatistics } from './Appointment';
import { ClientEncryptDetailInterface } from './ClientEncryptDetail';
import { clinicianInterface } from './clinician';

export type ProfileType = 'full' | 'recordOnly' | 'prospect';

export enum ClientRecordType {
  Adult = 'adult',
  Child = 'child',
  Couple = 'couple',
  YoungPerson = 'youngPerson'
}

export enum ProfileRole {
  Child = 'child',
  Mother = 'mother',
  Father = 'father',
  YoungPerson = 'youngPerson',
  StepMother = 'stepMother',
  StepFather = 'stepFather',
  Grandparent = 'grandparent',
  Guardian = 'guardian',
  Sibling = 'sibling',
  Teacher = 'teacher',
  CaseWorker = 'caseWorker',
  Other = 'other'
}

export interface ClientStatus {
  status?: 'active' | 'closed' | 'waitlist';
}

export enum CommunicationPreference {
  NoPreference = 'noPreference',
  Email = 'email',
  SMS = 'sms',
  None = 'none'
}

export interface ClientAddress {
  line1?: string;
  line2?: string;
  state?: string;
  suburb?: string;
  postcode?: string;
  country?: string;
}

export interface GetClientProfileInterface {
  clientRecords: clientRecordsInterface[];
  recordStatusCounts: {
    active: number;
    closed: number;
    waitlist: number;
  };
  paging: {
    page: number;
    perPage: number;
    totalItem: number;
    totalPage: number;
  };
}
export enum RecordStatus {
  Active = 'active',
  Closed = 'closed',
  Prospect = 'prospect'
}

export interface referralFilesInterface {
  bucketName: string;
  fileName: string;
  fileUrl: string;
  clientProfileId?: string;
  questionsResponsesId?: string;
  createdAt: Date;
  updatedAt?: Date;
  treatmentPlanDate?: Date;
  createdBy?: string;
  uploaderName?: string; // to record non-client user who uploaded the file
}

export interface KeyClientContact {
  _id: string;
  firstName: string;
  lastName: string;
  mobileNumber?: string;
  email?: string;
  tags?: string[];
}

export interface AppointmentStatusOverviewConfiguration {
  target: number;
  limit: number;
  displayTagCount: string[];
}

export interface MedicareStatus {
  code: number;
  message?: string;
}

export enum DvaCardType {
  Gold = 'gold',
  White = 'white',
  Orange = 'orange'
}

export interface MedicareDetails {
  number?: number;
  irn?: number;
  dva?: string;
  expiryDate?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  status?: {
    medicare?: MedicareStatus;
    dva?: MedicareStatus;
  };
  summary: {
    mbsCodes: {
      individual: string[];
      group: string[];
    };
  };
  createdAt: string;
  updatedAt: string;
  dvaCardDetails?: {
    type?: DvaCardType;
    expiryDate?: string;
  };
}

export interface referralInfoProps {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  relationship?: string;
}

interface EpisodeSummary {
  // Order is the number of all episode been created for the client record. Order cannot be changed.
  order: number;
  // Count is the temporal number of order of the client record. Count can be changed.
  count: number;
}

interface StripeCard {
  brand: string;
  country: string | null;
  expiryMonth: number;
  expiryYear: number;
  last4: string;
}

export interface clientRecordsInterface {
  accountId: string;
  clientProfiles: clientProfilesInterface[];
  createdAt: string;
  recordStatus: ClientStatus['status'];
  recordType: ClientRecordType;
  referral?: {
    sourceTags?: string[];
    detail: string;
    isReferredByGP?: boolean;
    files?: referralFilesInterface[];
    date?: string;
    generalPractitionerId?: string;
    treatmentPlanFiles?: referralFilesInterface[];
    source?: string;
    sourceName?: string;
    isForMySelf?: boolean;
    referrerInfo?: referralInfoProps;
  };
  invoiceSummary: {
    count: number;
    order: number;
    settings?: {
      automatedInvoicing: {
        active: boolean;
        dueAt: number;
        includeAppointments: string;
        issueAt: string;
        note: string;
        sendToClient: boolean;
        type: 'appointment' | 'month';
        includeMedicareDetails?: boolean;
        sendToOther: {
          active: boolean;
          emails?: string[];
        };
        setAsFlatFee: {
          active: boolean;
          value: number;
        };
      };
      discount: {
        active: boolean;
        type: 'percent' | 'amount';
        value: number;
      };
    };
  };
  episodeSummary?: EpisodeSummary;
  updatedAt: string;
  _id: string;
  tacklitId: string;
  isCompleteRecords: boolean;
  clinicians?: clinicianInterface[];
  appointmentStatistics: appointmentStatistics;
  clinicianAuth0Ids: string[];
  mentorIds?: string[];
  caseId?: string;
  tags?: string[];
  allowCommunicationWithEmail?: boolean;
  allowCommunicationWithSms?: boolean;
  canBeFullProfile: boolean;
  keyClientContacts?: KeyClientContact[];
  attachedGroups?: AttachedGroup[];
  appointmentStatusOverviewConfiguration: AppointmentStatusOverviewConfiguration;
  timeZone?: string;
  address?: ClientAddress;
  integrations?: {
    codatId?: string;
    wondeId?: string;
    stripeCustomerId?: string;
    stripePaymentMethod?: {
      id: string;
      lastPaymentError?: string;
      card?: StripeCard;
    };
    egristId?: string;
  };
  activePinnedNotes?: ClientRecordPinnedNote[];
}

export interface clientProfilesInterface {
  clientAuth0Id?: string;
  _id: string;
  createdAt: string;
  hashedMobileNumber: string;
  avatar?: string;
  initials: string;
  initialsBackgroundColor: string;
  isEmailThirdParty: boolean;
  isMobileNumberThirdParty: boolean;
  mobileNumber?: string;
  hasMobileNumber: boolean;
  email?: string;
  hasEmail: boolean;
  name: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  postcode?: string;
  onboardingSettings?: {
    facilitationUrl: string;
    firstAssessmentIds: string[];
    onboardingMethod: string;
  };
  profileType: ProfileType;
  signupInvitation?: {
    isSent: boolean;
    createdAt: string;
    inviteDate: string;
    sendAt: string;
    updatedAt: string;
    signupDate: string;
  };
  updatedAt: string;
  isPrimaryContact?: boolean;
  communicationPreference?: CommunicationPreference;
  role?: ProfileRole;
  medicare?: MedicareDetails;
}

export enum PinType {
  Risk = 'risk',
  Important = 'important'
}

export interface ClientRecordPinnedNote {
  title: string;
  body: string;
  pinType: PinType;
  clinicianAuth0Id?: string;
  noteId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface clientRecordsEncryptedInterface extends clientRecordsInterface {
  address?: ClientAddress;
  caseId?: string;
  clientProfiles: clientProfilesEncrypted[];
}

export interface clientProfilesEncrypted extends clientProfilesInterface {
  mobileNumber: string;
  email: string;
  background: ClientEncryptDetailInterface['background'];
}

export enum ProspectStatus {
  Prospect = 'prospect',
  Closed = 'closed',
  Waitlist = 'waitlist'
}

export interface clientListFilterParameter {
  status?: ClientStatus['status'];
  perPage: number;
  page: number | string;
  q: string;
  prospectStatus?: ProspectStatus;
  sortByClientName?: number;
  sortByClientSince?: number;
}

export interface ClientListingCount {
  active: number;
  closed: number;
  waitlist: number;
}

export interface AttachedGroup {
  groupId: string;
  status: AttachedClientRecordStatus;
  dateJoined: Date;
  updatedAt: Date;
  createdAt: Date;
  groupName: string;
  groupTags: GroupTag[];
  groupLeadClinicianAuth0Id: string;
  groupClinicianAuth0Ids: string[];
  stage: GroupStage;
  stageId: string;
  stageUpdatedAt?: Date;
  groupLeadName: string;
  stagesConfiguration: Groups['stagesConfiguration'];
}
