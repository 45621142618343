import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccessToken } from 'utils/hooks/token';

import { useFetchSystemIntegration } from '../ControlPanel/hooks/getSystemIntegrations';
import { IntegrationStatus, IntegrationType } from '../Interfaces/Integration';
import DataMappingModal from './components/DataMappingModal/DataMappingModal';
import DisconnectIntegrationModal from './components/DisconnectIntegrationModal/DisconnectIntegrationModal';
import IntegrationDetailsContent from './components/IntegrationDetailsContent/IntegrationDetailsContent';
import IntegrationDetailsHeader from './components/IntegrationDetailsHeader/IntegrationDetailsHeader';

const IntegrationDetails = () => {
  const { token } = useGetAccessToken();
  const { isEdgeAdminView, isNormalUserView } = useGetAccountPackageView();
  const isAdmin = isEdgeAdminView || isNormalUserView;

  const { integrationId = '' } = useParams<{ integrationId: string }>();
  const { integration, isIntegrationLoading, refetchSystemIntegration } = useFetchSystemIntegration(
    token,
    isAdmin,
    integrationId
  );
  const [showDataMappingModal, setShowDataMappingModal] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);

  return (
    <HelmetWrapper title="Tacklit - System Connections">
      <ContentLayout>
        <div>
          <IntegrationDetailsHeader
            integration={integration}
            isLoading={isIntegrationLoading}
            setShowDataMappingModal={setShowDataMappingModal}
            setShowDisconnectModal={setShowDisconnectModal}
          />
          <IntegrationDetailsContent
            integration={integration}
            isLoading={isIntegrationLoading}
            refetchSystemIntegration={refetchSystemIntegration}
          />
          {[IntegrationStatus.Connected, IntegrationStatus.PendingAuth, IntegrationStatus.PendingMapping].includes(
            integration.status
          ) &&
            integration.type === IntegrationType.Account && (
              <>
                <DataMappingModal
                  integrationId={integrationId}
                  provider={integration.provider}
                  visible={showDataMappingModal}
                  setShowDataMappingModal={setShowDataMappingModal}
                  refetchSystemIntegration={refetchSystemIntegration}
                />
                <DisconnectIntegrationModal
                  integration={integration}
                  visible={showDisconnectModal}
                  setShowDisconnectModal={setShowDisconnectModal}
                  refetchSystemIntegration={refetchSystemIntegration}
                />
              </>
            )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default IntegrationDetails;
