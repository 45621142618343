import { BentResponse } from 'bent';
import { httpClient } from '../../httpClient';
import queryString from 'query-string';

const ACTIVITY_FEED_SERVICE_URL = process.env.REACT_APP_ACTIVITY_FEED_SERVICE_URL;

export const getPatientsLatestActivity = (token: string) =>
  httpClient('GET', ACTIVITY_FEED_SERVICE_URL!, 200, { Authorization: `Bearer ${token}` })(
    `/patients-activity/latest`
  ) as Promise<BentResponse>;

export const getPatientsActivity = (
  token: string,
  query: {
    clientRecordId: string;
    lastCreatedAt?: string;
    pageSize?: number;
    filter?: string;
    action?: string;
  }
) => {
  const queryParams = queryString.stringify(query);

  return httpClient('GET', ACTIVITY_FEED_SERVICE_URL!, 200, { Authorization: `Bearer ${token}` })(
    `/activities${queryParams ? `?${queryParams}` : ''}`
  ) as Promise<BentResponse>;
};

export const getClientLatestActivity = (token: string) =>
  httpClient('GET', ACTIVITY_FEED_SERVICE_URL!, 200, { Authorization: `Bearer ${token}` })(
    `/activities:latestByClientRecord`
  ) as Promise<BentResponse>;

export const getGroupActivityFeed = (
  token: string,
  accountId: string,
  groupId: string,
  filter: string,
  pageSize: number,
  lastActivityId: string
) =>
  httpClient('GET', ACTIVITY_FEED_SERVICE_URL!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/activities?groupId=${groupId}&filter=${filter}&pageSize=${pageSize}${
      lastActivityId ? `&lastActivityId=${lastActivityId}` : ''
    }`
  ) as Promise<BentResponse>;
