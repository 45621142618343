import { Modal } from 'antd';
import styles from './CreateRoomModal.module.scss';
import ModalHeader from 'components/v2/ModalHeader/ModalHeader';
import RoomForm from '../RoomForm/RoomForm';
import { CreateRoomType, TimeSlot } from 'interfaces/Schedule/Room';
import { useState } from 'react';
import moment from 'moment';
import { useCreateRoomMutation } from 'redux/endpoints/scheduleServices/room';

const TIME_FORMAT_WITHOUT_ASIDE = 'HH:mm',
  TIME_FORMAT_WITH_ASIDE = 'hh:mm a';

interface CreateRoomModalProps {
  visible: boolean;
  onClose: (reload: boolean) => void;
}

const CreateRoomModal = ({ visible, onClose }: CreateRoomModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [createRoom] = useCreateRoomMutation();

  const onCancel = () => onClose(false);

  const reFormatTimes = (timeSlots: TimeSlot[]) => {
    if (timeSlots && timeSlots.length > 0) {
      return timeSlots.map((timeSlot) => {
        return {
          startTime: moment(timeSlot.startTime, TIME_FORMAT_WITH_ASIDE).format(TIME_FORMAT_WITHOUT_ASIDE),
          endTime: moment(timeSlot.endTime, TIME_FORMAT_WITH_ASIDE).format(TIME_FORMAT_WITHOUT_ASIDE)
        };
      });
    }
    return [] as TimeSlot[];
  };

  const onSubmit = async (value: CreateRoomType) => {
    // Re-format startTime, endTime
    const massageData = {
      ...value,
      availability: {
        monday: {
          isAvailable: value.availability.monday.isAvailable,
          timeSlots: reFormatTimes(value.availability.monday.timeSlots || [])
        },
        tuesday: {
          isAvailable: value.availability.tuesday.isAvailable,
          timeSlots: reFormatTimes(value.availability.tuesday.timeSlots || [])
        },
        wednesday: {
          isAvailable: value.availability.wednesday.isAvailable,
          timeSlots: reFormatTimes(value.availability.wednesday.timeSlots || [])
        },
        thursday: {
          isAvailable: value.availability.thursday.isAvailable,
          timeSlots: reFormatTimes(value.availability.thursday.timeSlots || [])
        },
        friday: {
          isAvailable: value.availability.friday.isAvailable,
          timeSlots: reFormatTimes(value.availability.friday.timeSlots || [])
        },
        saturday: {
          isAvailable: value.availability.saturday.isAvailable,
          timeSlots: reFormatTimes(value.availability.saturday.timeSlots || [])
        },
        sunday: {
          isAvailable: value.availability.sunday.isAvailable,
          timeSlots: reFormatTimes(value.availability.sunday.timeSlots || [])
        }
      }
    } as CreateRoomType;
    setIsLoading(true);
    const response = await createRoom({ payload: massageData }).unwrap();
    if (response._id) {
      onClose(true);
    }
    setIsLoading(false);
  };
  return (
    <Modal
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
      width={720}
      open={visible}
      footer={null}
      destroyOnClose
      closable={false}
    >
      <ModalHeader title="Create New Room" onCancel={onCancel} />
      <div className={styles.container}>
        <RoomForm onSubmit={onSubmit} isLoading={isLoading} />
      </div>
    </Modal>
  );
};

export default CreateRoomModal;
