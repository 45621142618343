import { createSlice } from '@reduxjs/toolkit';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { FilterRadioItem } from 'components/T23/FilterRadioDropdown/FilterRadioDropdown';
import { RootState } from 'redux/store';

export interface AppointmentTypeFilters {
  selectedStatus?: FilterRadioItem;
  selectedPractitioners: FilterCheckListItem[];
  selectedDeliveryMode: FilterCheckListItem[];
  selectedType?: FilterRadioItem;
}

export interface AppointmentTypePaging {
  page: number;
  perPage: number;
}

interface AppointmentTypeState {
  search: { searchValue: string };
  filters: AppointmentTypeFilters;
  paging: AppointmentTypePaging;
}

const initialState: AppointmentTypeState = {
  search: {
    searchValue: ''
  },
  filters: {
    selectedStatus: undefined,
    selectedPractitioners: [],
    selectedDeliveryMode: [],
    selectedType: undefined
  },
  paging: {
    page: 1,
    perPage: 10
  }
};

export const appointmentTypeSlice = createSlice({
  name: 'appointmentTypeSlice',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    resetSearch: (state) => {
      state.search = initialState.search;
    },

    resetAll: (state) => {
      state.search = initialState.search;
      state.filters = initialState.filters;
      state.paging = initialState.paging;
    },

    setPaging: (state, action) => {
      state.paging = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    }
  }
});

export const selectSearch = (state: RootState) => state.appointmentTypeSlice.search;
export const selectFilters = (state: RootState) => state.appointmentTypeSlice.filters;
export const selectPaging = (state: RootState) => state.appointmentTypeSlice.paging;

export const { setSearch, setFilters, resetFilters, resetSearch, resetAll, setPaging, resetPaging } =
  appointmentTypeSlice.actions;

export default appointmentTypeSlice.reducer;
