import { SSTagTypes, scheduleServicesApiSlice } from '../../services/scheduleServicesApiSlice';
import { carePlanAppointments } from 'interfaces/CarePathway/CarePathway';

interface PostCarePathwayAppointmentPayload {
  accountId: string;
  carePathwayId: string;
  payload: carePlanAppointments;
}

export interface UpdateCarePathwayAppointmentPayload extends PostCarePathwayAppointmentPayload {
  appointmentId: string;
}

export interface DeleteCarePathwayAppointmentPayload extends Omit<PostCarePathwayAppointmentPayload, 'payload'> {
  appointmentId?: string;
}

export const appointmentReservedApiSlice = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    CreateCarePathwayAppointment: builder.mutation({
      query: ({ accountId, carePathwayId, payload }: PostCarePathwayAppointmentPayload) => ({
        url: `/accounts/${accountId}/care-pathways/${carePathwayId}/reserved-appointments`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [SSTagTypes.CarePathwayAppointment]
    }),
    UpdateCarePathwayAppointment: builder.mutation({
      query: ({ accountId, carePathwayId, appointmentId, payload }: UpdateCarePathwayAppointmentPayload) => ({
        url: `/accounts/${accountId}/care-pathways/${carePathwayId}/reserved-appointments/${appointmentId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [SSTagTypes.CarePathwayAppointment]
    }),
    DeleteCarePathwayAppointment: builder.mutation({
      query: ({ accountId, carePathwayId, appointmentId }: DeleteCarePathwayAppointmentPayload) => ({
        url: `/accounts/${accountId}/care-pathways/${carePathwayId}/reserved-appointments/${appointmentId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [SSTagTypes.CarePathwayAppointment]
    }),
    ResetAllCarePathwayAppointment: builder.mutation({
      query: ({ accountId, carePathwayId }: DeleteCarePathwayAppointmentPayload) => ({
        url: `/accounts/${accountId}/care-pathways/${carePathwayId}/reserved-appointments`,
        method: 'DELETE'
      }),
      invalidatesTags: [SSTagTypes.CarePathwayAppointment]
    })
  })
});

export const {
  useCreateCarePathwayAppointmentMutation,
  useUpdateCarePathwayAppointmentMutation,
  useDeleteCarePathwayAppointmentMutation,
  useResetAllCarePathwayAppointmentMutation
} = appointmentReservedApiSlice;
