import classNames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ButtonAlt, { ButtonAltSize } from 'components/v2/ButtonAlt/ButtonAlt';
import { useRef } from 'react';
import styles from './FileInput.module.scss';

interface FileInputProps {
  accept?: string;
  buttonClassName?: string;
  buttonLabel?: string;
  buttonSize?: ButtonAltSize;
  errorMessage?: string;
  multiple?: boolean;
  onChange: (e: any) => void;
  selectedFiles?: File[];
}

const FileInput = ({
  accept,
  buttonClassName,
  buttonLabel,
  buttonSize,
  errorMessage,
  multiple,
  onChange,
  selectedFiles
}: FileInputProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  return (
    <div>
      <input
        accept={accept}
        className={styles.fileInput}
        multiple={multiple}
        onChange={onChange}
        ref={fileInputRef}
        type="file"
      />
      <div className={styles.container}>
        <ButtonAlt
          className={classNames(styles.button, buttonClassName)}
          icon="upload"
          onClick={() => fileInputRef.current && fileInputRef.current.click()}
          size={buttonSize}
        >
          {buttonLabel ?? 'Choose file'}
        </ButtonAlt>
        {selectedFiles?.map((file) => file.name)}
      </div>
      <ErrorMessage error={errorMessage} visible={!!errorMessage} />
    </div>
  );
};

export default FileInput;
