import classNames from 'classnames';
import ExpandableContent from 'components/ExpandableContent/ExpandableContent';
import { Section } from 'interfaces/checkInService/AssessmentInterfaces';
import { useEffect, useState } from 'react';
import PatientAnswer from '../../../PatientDetailsAssessments/components/PatientAnswer/PatientAnswer';
import { Assessment, AssessmentChart } from '../../../PatientDetailsAssessments/PatientDetailsAssessments';
import AssessmentLabel from '../AssessmentLabel/AssessmentLabel';

import styles from './AssessmentResultDataRow.module.scss';

const AssessmentResultDataRow = ({
  assessment,
  chart,
  isExpanded
}: {
  assessment: Assessment;
  chart: AssessmentChart;
  isExpanded: boolean;
}) => {
  const [expand, setExpand] = useState(isExpanded || false);
  useEffect(() => {
    setExpand(isExpanded);
  }, [isExpanded]);

  const filterQuestion = (questions: Section['questions']) => {
    return questions.filter((item) => (assessment.referenceScore[chart.key || ''] ?? []).includes(item.id));
  };

  return (
    <div className={classNames(styles.card, expand && styles.expanded)}>
      <div className={styles.header} onClick={() => setExpand(!expand)}>
        <div className={styles.title}>{chart.key}</div>
        <div className={styles.labelCol}>
          <AssessmentLabel label={chart.label} />
        </div>
        <div className={styles.number}>{chart.total}</div>
        <div className={styles.barScore}>
          <div className={styles.bar}>
            <div className={styles.barThumb} style={{ width: `${((chart.total * 100) / chart.max).toFixed(2)}%` }} />
            <div className={styles.barNumberStart}>0</div>
            <div className={styles.barNumberEnd}>{chart.max}</div>
          </div>
        </div>
        <div className={styles.icon}>
          <i className="material-icons-outlined">{!expand ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</i>
        </div>
      </div>
      <ExpandableContent expanded={expand} className={styles.extendable}>
        <div className={classNames(styles.body, 'cords-assessment-question')}>
          <PatientAnswer
            sections={assessment.sections.map((i) => ({ ...i, questions: filterQuestion(i.questions) }))}
            showName={false}
            hideNoItemsSection
          />
        </div>
      </ExpandableContent>
    </div>
  );
};

export default AssessmentResultDataRow;
