import { BentResponse } from 'bent';
import { httpClient } from '../httpClient';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;
const firebase_url = process.env.REACT_APP_FIREBASE_URL;

export const getClinician = (slugUrlOrId: string) =>
  httpClient('GET', url!, 200)(`/clinician/${slugUrlOrId}`) as Promise<BentResponse>;

export const postClinician = (body: Record<string, any>) => httpClient('POST', url!, 204)('/clinician', body);

export const postPracticeClinician = (token: string, accountId: string, body: Record<string, any>) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(`/accounts/${accountId}/clinicians`, body);

export const updateClinicianContactDetails = (token: string, body: Record<string, any>, clinicianId?: string) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `${clinicianId ? `/clinicians/${clinicianId}` : ''}/contact-details`,
    body
  );

export const uploadAvatar = (formData: FormData) => {
  return new Promise((resolve, reject) => {
    fetch(`${url}/uploadAvatar`, { method: 'POST', body: formData })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const putClinicianPatientBackground = (
  token: string,
  clinicianId: string,
  body: { background: Record<string, any> }
) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinician/${clinicianId}/patient/background`,
    body
  );

export const postResendInvite = (token: string, _id: string) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(`/clinician/patient/${_id}/resend-invite`);

export const postCancelInvite = (token: string, _id: string) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(`/clinician/patient/${_id}/cancel-invite`);

export const postReopenInvite = (token: string, _id: string) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(`/clinician/patient/${_id}/reopen-invite`);

export const trackNewSignup = (body: Record<string, any>) => {
  const token = process.env.REACT_APP_FIREBASE_TOKEN;

  if (token && firebase_url) {
    return httpClient('POST', firebase_url, 200, { access_token: token })(
      '/functions/newClinician',
      body
    ) as Promise<BentResponse>;
  }
};
