import { useEffect, useState } from 'react';
import { notification } from 'antd';

import { ProfileRole, clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import { SendSurveyForm } from '../interfaces';

import { getFirstAssessments } from 'store/CheckIn/GetFirstAssessment/action';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';

import { CheckBoxListing } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

const getSurveyList = async (token: string) => {
  const getSurveys = await getFirstAssessments(token);
  const surveyOptionList = getSurveys
    .filter((oBObj) => oBObj.isPublished)
    .map(({ id, name, isDefault }) => ({
      label: name,
      value: id,
      isDefault
    }));
  return surveyOptionList;
};

export const usePrefillProfileSurveyForm = (
  token: string,
  clientProfileList: clientProfilesInterface[],
  notificationSettings: NotificationSettingsInterface
) => {
  const [sendSurveyForm, setSendSurveyForm] = useState({} as SendSurveyForm);
  const [prefillLoading, setPrefillLoading] = useState(true);
  const [surveyList, setSurveyList] = useState<CheckBoxListing[]>([]);
  const [profileOption, setProfileOption] = useState<CheckBoxListing[]>([]);

  const fetchPrefillProfilesData = async (clientProfileList: clientProfilesInterface[]) => {
    setPrefillLoading(true);

    try {
      const surveyOptionList = await getSurveyList(token);

      if (!surveyOptionList) {
        return;
      }

      setSurveyList(surveyOptionList.sort(({ isDefault }) => (isDefault ? -1 : 1)));

      const defaultSurveyForm = surveyOptionList.find((survey) => survey.isDefault);
      const primaryProfile = clientProfileList.find((profile) => {
        const notificationConfig = {
          communicationPreference: profile.communicationPreference,
          notificationSettings,
          notificationSettingsCategory: 'forms'
        };
        return (
          profile.isPrimaryContact &&
          ((profile.hasEmail && canClientReceiveEmail(notificationConfig)) ||
            (profile.hasMobileNumber && canClientReceiveSms(notificationConfig)))
        );
      });

      const notificationConfig = {
        notificationSettings,
        notificationSettingsCategory: 'forms'
      };

      const newProfileList = clientProfileList.map(
        ({
          _id,
          name,
          avatar,
          initials,
          initialsBackgroundColor,
          role,
          firstName,
          lastName,
          communicationPreference,
          hasEmail,
          hasMobileNumber,
          profileType
        }) => {
          const isCommunicationsNotAllowed =
            role === ProfileRole.YoungPerson && profileType === 'recordOnly'
              ? // if young record, check if all guardian profiles cannot receive notifications
                clientProfileList.every(
                  (clientProfile) =>
                    !(
                      (canClientReceiveEmail({
                        ...notificationConfig,
                        communicationPreference: clientProfile.communicationPreference
                      }) &&
                        clientProfile.hasEmail &&
                        clientProfile.isPrimaryContact) ||
                      (canClientReceiveSms({
                        ...notificationConfig,
                        communicationPreference: clientProfile.communicationPreference
                      }) &&
                        clientProfile.hasMobileNumber &&
                        clientProfile.isPrimaryContact)
                    )
                )
              : role === ProfileRole.Child
              ? // if child, check if all profiles have no communication (so that survey link can still be generated on the facilitated flow)
                clientProfileList.every(
                  (clientProfile) =>
                    !(
                      (canClientReceiveEmail({
                        ...notificationConfig,
                        communicationPreference: clientProfile.communicationPreference
                      }) &&
                        clientProfile.hasEmail) ||
                      (canClientReceiveSms({
                        ...notificationConfig,
                        communicationPreference: clientProfile.communicationPreference
                      }) &&
                        clientProfile.hasMobileNumber)
                    )
                )
              : !(
                  (canClientReceiveEmail({ ...notificationConfig, communicationPreference }) && hasEmail) ||
                  (canClientReceiveSms({ ...notificationConfig, communicationPreference }) && hasMobileNumber)
                );

          return {
            profileId: _id,
            name,
            firstName,
            lastName,
            avatar,
            initials,
            initialsBackgroundColor,
            role,
            communicationPreference,
            hasEmail,
            hasMobileNumber,
            surveys: defaultSurveyForm ? [defaultSurveyForm] : [],
            isChecked: clientProfileList.length === 1 ? !isCommunicationsNotAllowed : false,
            isCommunicationsNotAllowed,
            isValid: true,
            receiver:
              role === 'child' && primaryProfile
                ? {
                    _id: primaryProfile._id,
                    name: primaryProfile.name,
                    isValid: true
                  }
                : undefined
          };
        }
      );

      const newSendSurveyForm = {
        profileList: newProfileList,
        inviteTime: { sendAt: 'now' },
        note: ''
      };

      setSendSurveyForm(newSendSurveyForm);

      setProfileOption(
        newProfileList
          .filter((profile) => profile.role !== 'child' && !profile.isCommunicationsNotAllowed)
          .map(({ profileId, firstName, lastName }, index) => ({
            label: `${firstName} ${lastName}`,
            value: profileId,
            isDefault: index === 0
          })) as CheckBoxListing[]
      );
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get your survey list.' });
    }

    setPrefillLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchPrefillProfilesData(clientProfileList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clientProfileList]);

  return { sendSurveyForm, setSendSurveyForm, prefillLoading, surveyList, profileOption, fetchPrefillProfilesData };
};
