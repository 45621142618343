import { AppointmentStatusOption } from 'interfaces/Schedule/Appointment';

export const APPOINTMENT_TAG_IN_INVOICE: string[] = [
  AppointmentStatusOption.Attended,
  AppointmentStatusOption.DidNotAttend,
  AppointmentStatusOption.ClientDidNotAttend,
  AppointmentStatusOption.ClinicianDidNotAttend,
  AppointmentStatusOption.CancelledShortNotice,
  AppointmentStatusOption.CancelledWithNotice,
  AppointmentStatusOption.CancelledByClient,
  AppointmentStatusOption.CancelledByClinician
];

export const filterInvoiceAppointmentTags = (tags: string[]): string[] =>
  tags.filter((tag) => APPOINTMENT_TAG_IN_INVOICE.includes(tag));
