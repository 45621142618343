import { useContext } from 'react';

import { CustomFeature, UserContext } from '../UserContextProvider';
import { useGetAccountPackageView } from '../hooks/GetAccountInfo/accountPackageView';
import { isDevelopmentENV } from './DevelopmentToggle';

export const useGetFeatureToggle = () => {
  const { isNormalUserView, isEdgeUserView, isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const { customFeatures } = useContext(UserContext);
  const isMentorFeatureToggle = customFeatures.includes(CustomFeature.Mentor);
  const isGroupsFeatureToggle = isEdgeUserView || isEdgeAdminView || isEdgeReceptionist;
  const isEnableStonlyGuide = isNormalUserView;

  const isRegionAU = process.env.REACT_APP_GAE_REGION === 'au';
  const isRegionUK = process.env.REACT_APP_GAE_REGION === 'gb';

  const helmContentFeatureToggle = customFeatures.includes(CustomFeature.HelmContent);
  const medicareRebateFeatureToggle = isRegionAU && customFeatures.includes(CustomFeature.MedicareRebate);
  const isHelmFeatureToggle = customFeatures.includes(CustomFeature.Helm);
  const isSomeoneHealthFeatureToggle = customFeatures.includes(CustomFeature.SomeoneHealth);
  const isCaWFeatureToggle = customFeatures.includes(CustomFeature.CaW);
  const isEaseWellbeingFeatureToggle = customFeatures.includes(CustomFeature.EaseWellbeing);
  const isRechargeWellnessFeatureToggle = customFeatures.includes(CustomFeature.RechargeWellness);
  const isSelectPsychologyFeatureToggle = customFeatures.includes(CustomFeature.SelectPsychology);
  const isGroupsTagsToggle = false;
  const isVadcFeatureToggle = customFeatures.includes(CustomFeature.Vadc);
  const isRequestApprovalByDefault = customFeatures.includes(CustomFeature.RequestApprovalByDefault);
  const templateNameEnable = customFeatures.includes(CustomFeature.TemplateName);
  const faxEnable = customFeatures.includes(CustomFeature.Fax);
  const isCarePlanEnabled = customFeatures.includes(CustomFeature.CarePlan) || isDevelopmentENV();
  const isEoCEnabled = customFeatures.includes(CustomFeature.EoC);
  const isMultiTimeZoneEnabled = isRegionAU && customFeatures.includes(CustomFeature.MultiTimeZone);
  const isEngagePublicProfileEnabled = customFeatures.includes(CustomFeature.EngagePublicProfile);
  const isInvoiceTemplateEnabled = isDevelopmentENV() || customFeatures.includes(CustomFeature.InvoiceTemplate);
  const isClientTimeZoneEnabled = isRegionAU;
  const isEgristEnabled = customFeatures.includes(CustomFeature.Egrist);
  const isHealthDataEnabled = customFeatures.includes(CustomFeature.HealthData);
  const isHidePrivateCaseNotes = customFeatures.includes(CustomFeature.HidePrivateCaseNotes);
  const isPackageEnabled = customFeatures.includes(CustomFeature.Packages);
  const isProcessAppointmentEnabled = customFeatures.includes(CustomFeature.ProcessAppointment);
  const isAddressBookFeatureToggle = isEdgeAdminView && customFeatures.includes(CustomFeature.AddressBook);
  const isInvoiceListV2Enabled = customFeatures.includes(CustomFeature.InvoiceListV2);
  const ableToAccessTaskList = isProcessAppointmentEnabled && isEdgeAdminView;
  const isChargeAppointmentTagEnabled =
    isEaseWellbeingFeatureToggle || isRechargeWellnessFeatureToggle || isSelectPsychologyFeatureToggle;
  const isTwilioTelehealthEnabled = customFeatures.includes(CustomFeature.TwilioTelehealth);
  const isReferralV2Enabled = customFeatures.includes(CustomFeature.ReferralV2);
  const isReferralV2ListEnabled = (isEdgeAdminView || isEdgeReceptionist) && isReferralV2Enabled;
  const isPaymentListEnabled = isEdgeAdminView && customFeatures.includes(CustomFeature.SomeoneHealth);
  const isPractitionerReportEnabled = isEdgeAdminView || isEdgeUserView;

  return {
    isEnableStonlyGuide,
    isGroupsFeatureToggle,
    isGroupsTagsToggle,
    isHelmFeatureToggle,
    isMentorFeatureToggle,
    isRegionAU,
    isRegionUK,
    isVadcFeatureToggle,
    helmContentFeatureToggle,
    medicareRebateFeatureToggle,
    isRequestApprovalByDefault,
    templateNameEnable,
    faxEnable,
    isSomeoneHealthFeatureToggle,
    isCaWFeatureToggle,
    isCarePlanEnabled,
    isEaseWellbeingFeatureToggle,
    isEoCEnabled,
    isMultiTimeZoneEnabled,
    isEngagePublicProfileEnabled,
    isInvoiceTemplateEnabled,
    isRechargeWellnessFeatureToggle,
    isClientTimeZoneEnabled,
    isEgristEnabled,
    isHealthDataEnabled,
    isHidePrivateCaseNotes,
    isPackageEnabled,
    isSelectPsychologyFeatureToggle,
    isProcessAppointmentEnabled,
    isAddressBookFeatureToggle,
    isInvoiceListV2Enabled,
    ableToAccessTaskList,
    isChargeAppointmentTagEnabled,
    isTwilioTelehealthEnabled,
    isReferralV2ListEnabled,
    isReferralV2Enabled,
    isPaymentListEnabled,
    isPractitionerReportEnabled
  };
};
