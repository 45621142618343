import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';

export enum MedicareItemDuration {
  OneToOneLessThan20 = '<20',
  OneToOneMoreThan20 = '>20',
  OneToOneMoreThan30 = '>30',
  OneToOneLessThan40 = '<40',
  OneToOneMoreThan40 = '>40',
  OneToOneMoreThan45 = '>45',
  OneToOneLessThan50 = '<50',
  OneToOneMoreThan50 = '>50',
  GroupMoreThan60 = '>60',
  Any = 'any'
}

export enum MedicareItemFormat {
  OneToOne = 'oneToOne',
  Group = 'group'
}

export enum MedicareItemMode {
  FaceToFace = 'faceToFace',
  VideoCall = 'videoCall',
  PhoneCall = 'phoneCall',
  Telehealth = 'telehealth',
  Any = 'any'
}

export interface MedicareItem {
  mbsCode: string;
  description: string;
  benefit: number;
  role: ClinicianMedicareRole;
  duration: MedicareItemDuration;
  format: MedicareItemFormat;
  mode: MedicareItemMode;
  disabled?: boolean;
}
