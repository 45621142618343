import FlexBox from 'components/FlexBox/FlexBox';
import { Invoice } from 'pages/Invoices/interface';
import InvoiceField from '../InvoiceField/InvoiceField';
import styles from '../../InvoiceViewWithTemplate.module.scss';

const PracticeInfoSection = ({ invoice }: { invoice: Invoice }) => {
  const {
    practice: { logo, name, formalName, address, registrationNumber, mobileNumber, fax, financeEmail }
  } = invoice;
  return (
    <FlexBox direction="row" spacing={20} className={styles.padding20}>
      <img src={logo} alt="Practice logo" width={100} height={100} />
      <FlexBox direction="column" flex={1}>
        <span className={styles.practiceHeading}>{name}</span>
        <FlexBox direction="row" spacing={20}>
          <FlexBox direction="column" className={styles.width280} spacing={4}>
            {[formalName, address, registrationNumber].map((item, index) => (
              <InvoiceField key={index} value={item} />
            ))}
          </FlexBox>
          <FlexBox direction="column" className={styles.width360} spacing={4}>
            {[mobileNumber, fax, financeEmail].map((item, index) => (
              <InvoiceField key={index} value={item} />
            ))}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default PracticeInfoSection;
