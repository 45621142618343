import { useEffect, useState } from 'react';
import { Divider, Skeleton, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import Card from 'components/Card/Card';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import PageHeader from 'components/PageHeader/PageHeader';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectInvoiceTemplate,
  setInvoiceTemplate,
  resetInvoiceTemplate
} from 'redux/invoiceTemplate/invoiceTemplateCreateFormSlice';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import BasicDetailsForm from './BasicDetailsForm';
import MoreDetailsForm from './MoreDetailsForm';
import { mapClinicianDetails } from './helpers/helpers';
import styles from './InvoiceTemplate.module.scss';
import InvoiceTemplatesPreview from 'pages/InvoiceTemplatesPreview/InvoiceTemplatesPreview';
import { useFetchPaymentMethods } from 'pages/Invoices/Invoices';
import {
  useCreateInvoiceTemplateMutation,
  useGetInvoiceTemplateQuery,
  useUpdateInvoiceTemplateMutation
} from 'redux/endpoints/billingServices/invoiceTemplates';
import { useGetAccountInfo } from 'utils/hooks/GetAccountInfo/getAccountInfo';

const InvoiceTemplate = () => {
  const { accountId } = useGetAccountId();
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();
  const invoiceTemplate = useAppSelector(selectInvoiceTemplate);
  const [showPreview, setShowPreview] = useState(false);
  const { invoiceTemplateId = '' } = useParams<{ invoiceTemplateId?: string }>();
  const dispatch = useAppDispatch();

  const { clinicianProfile } = useGetAccountInfo();
  const clinicianDetails = clinicianProfile ? mapClinicianDetails(clinicianProfile) : undefined;
  const { name, recipient, dueAt, itemOverviews, itemColumns, paymentMethods } = invoiceTemplate;
  const {
    paymentMethods: availablePaymentMethods,
    isPaymentMethodsLoading,
    refetchPaymentMethods
  } = useFetchPaymentMethods();
  const { data, isLoading, isFetching } = useGetInvoiceTemplateQuery(
    { accountId, invoiceTemplateId },
    { skip: !invoiceTemplateId }
  );
  const [createInvoiceTemplate, { isLoading: isCreating }] = useCreateInvoiceTemplateMutation();
  const [updateInvoiceTemplate, { isLoading: isUpdating }] = useUpdateInvoiceTemplateMutation();

  const selectedPaymentMethods = availablePaymentMethods.filter(({ _id }) => paymentMethods.includes(_id));

  const isDisabled =
    name === '' || recipient === undefined || dueAt === undefined || itemOverviews.length < 1 || itemColumns.length < 1;

  const handlePreview = () => {
    setShowPreview(!showPreview);
  };

  useEffect(() => {
    if (!(isLoading || isFetching) && data) {
      dispatch(setInvoiceTemplate(data));
    }
  }, [data, dispatch, isFetching, isLoading]);

  useEffect(
    () => () => {
      dispatch(resetInvoiceTemplate());
    },
    [dispatch]
  );

  const handleSave = async () => {
    try {
      if (invoiceTemplateId) {
        await updateInvoiceTemplate({ accountId, invoiceTemplateId, payload: invoiceTemplate }).unwrap();
        notification.success({
          message: 'Invoice template updated!',
          duration: 2
        });
      } else {
        const response = await createInvoiceTemplate({ accountId, payload: invoiceTemplate }).unwrap();
        notification.success({
          message: 'Invoice template created!',
          duration: 2
        });
        navigate(`${FORMS.INVOICE_TEMPLATE}/${response._id}`);
      }
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to create this invoice template',
        duration: 2
      });
    }
  };

  return (
    <HelmetWrapper title="Invoice Template">
      <ContentLayout className={styles.container}>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            <PageHeader title="Invoice Template">
              <div className={styles.pageHeaderRight}>
                <ButtonAlt disabled={isDisabled} variant="outlined" onClick={() => handlePreview()}>
                  Preview
                </ButtonAlt>
                <ButtonAlt
                  disabled={isDisabled}
                  onClick={() => handleSave()}
                  status={isCreating || isUpdating ? 'active' : ''}
                >
                  Save
                </ButtonAlt>
              </div>
            </PageHeader>
            <ButtonAlt
              variant={'text'}
              size={'medium'}
              onClick={() => navigate(FORMS.BASE)}
              icon={'arrow_back_ios'}
              className={styles.backBtn}
            >
              To Template List
            </ButtonAlt>
            <Card className={styles.cardContainer}>
              <BasicDetailsForm
                availablePaymentMethods={availablePaymentMethods}
                isPaymentMethodsLoading={isPaymentMethodsLoading}
                refetchPaymentMethods={refetchPaymentMethods}
              />
              <Divider type="vertical" className={styles.divider} />
              <MoreDetailsForm clinicianDetails={clinicianDetails} />
            </Card>
            <InvoiceTemplatesPreview
              isModalOpen={showPreview}
              onModalClose={() => handlePreview()}
              data={{
                ...invoiceTemplate,
                practiceLogo: true,
                practiceName: true,
                practiceAddress: true,
                practicePhoneNumber: true,
                practiceFax: true,
                invoiceId: true,
                issueDate: true,
                dueDate: true
              }}
              practiceInfo={clinicianDetails?.practice}
              paymentMethods={selectedPaymentMethods}
            />
          </>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InvoiceTemplate;
