import classNames from 'classnames';
import styles from './PreviewItemInfo.module.scss';
import { PreviewItemInfoProps } from '../interface';

const PreviewItemInfo = ({ label, value, className }: PreviewItemInfoProps) => {
  return (
    <div className={classNames(styles.infoWrapper, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.infoText}>{value}</div>
    </div>
  );
};

export default PreviewItemInfo;
