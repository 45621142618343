import styles from './CarePathwayGoal.module.scss';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import { ChangeEvent } from 'react';
import { useCarePathwayTemplateFormContext } from 'pages/CarePathwayTemplate/context';

const categories = [
  { label: 'Preventive Care', value: '1', className: styles.selectItem },
  { label: 'Primary Care', value: '2', className: styles.selectItem },
  { label: 'Psychotherapy/Counselling', value: '3', className: styles.selectItem },
  { label: 'Psychiatric Medication Management', value: '4', className: styles.selectItem },
  { label: 'Specialised Therapies', value: '5', className: styles.selectItem },
  { label: 'Intensive Outpatient Programs (IOP)', value: '6', className: styles.selectItem },
  { label: 'Inpatient Hospitalisation', value: '7', className: styles.selectItem },
  { label: 'Partial Hospitalisation Programs (PHP)', value: '8', className: styles.selectItem },
  { label: 'Community Mental Health Services', value: '9', className: styles.selectItem },
  { label: 'Telehealth/Online Therapy', value: '10', className: styles.selectItem },
  { label: 'Peer Support Services', value: '11', className: styles.selectItem },
  { label: 'Rehabilitation Services', value: '12', className: styles.selectItem },
  { label: 'Holistic Approaches', value: '13', className: styles.selectItem },
  { label: 'Trauma-Informed Care', value: '14', className: styles.selectItem },
  { label: 'Dual Diagnosis Programs', value: '15', className: styles.selectItem },
  { label: 'Child and Adolescent Mental Health Services', value: '16', className: styles.selectItem },
  { label: 'Elderly Mental Health Care', value: '17', className: styles.selectItem },
  { label: 'Crisis Intervention Services', value: '18', className: styles.selectItem },
  { label: 'Cultural and Identity-Specific Services', value: '19', className: styles.selectItem },
  { label: 'Transitional and Aftercare Services', value: '20', className: styles.selectItem },
  { label: 'Other', value: '21', className: styles.selectItem }
];

const objectives = [
  { label: 'Early Identification and Intervention', value: '1', className: styles.selectItem },
  { label: 'Symptom Reduction and Management', value: '2', className: styles.selectItem },
  { label: 'Improved Functioning and Quality of Life', value: '3', className: styles.selectItem },
  { label: 'Education and Empowerment', value: '4', className: styles.selectItem },
  { label: 'Crisis Prevention and Management', value: '5', className: styles.selectItem },
  { label: 'Collaborative Care Coordination', value: '6', className: styles.selectItem },
  { label: 'Holistic Well-being', value: '7', className: styles.selectItem },
  { label: 'Enhanced Coping Skills', value: '8', className: styles.selectItem },
  { label: 'Family and Social Support', value: '9', className: styles.selectItem },
  { label: 'Long-Term Stability', value: '10', className: styles.selectItem },
  { label: 'Functional Recovery', value: '11', className: styles.selectItem },
  { label: 'Relapse Prevention', value: '12', className: styles.selectItem },
  { label: 'Quality of Sleep Improvement', value: '13', className: styles.selectItem },
  { label: 'Enhanced Self-Esteem and Confidence', value: '14', className: styles.selectItem },
  { label: 'Continuous Monitoring and Adjustment', value: '15', className: styles.selectItem },
  { label: 'Prevention of Secondary Complications', value: '16', className: styles.selectItem },
  { label: 'Other', value: '17', className: styles.selectItem }
];

export const CarePathwayGoal = () => {
  const { values, setValues } = useCarePathwayTemplateFormContext();
  return (
    <div className={styles.container}>
      <div className={styles.title}>Purpose</div>
      <div className={styles.rightContainer}>
        <DropdownSearchable
          className={styles.input}
          placeholder="Category"
          options={categories}
          selected={values.purpose.category}
          onSelect={(value) =>
            setValues({
              purpose: {
                ...values.purpose,
                category: value
              }
            })
          }
          hideErrorDesc
        />
        <DropdownSearchable
          className={styles.input}
          placeholder="Objective"
          options={objectives}
          selected={values.purpose.objective}
          onSelect={(value) =>
            setValues({
              purpose: {
                ...values.purpose,
                objective: value
              }
            })
          }
          hideErrorDesc
        />
        <TitleInput
          className={styles.input}
          inputProps={{
            placeholder: 'Describe your purpose',
            value: values.purpose.description,
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              setValues({
                purpose: {
                  ...values.purpose,
                  description: e.target.value
                }
              })
          }}
        />
      </div>
    </div>
  );
};
