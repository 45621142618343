import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';

export interface IGeneralSettings {
  enforceUserMFA: boolean;
  packageOnlyBookings: {
    isActive: boolean;
    onlyRestrictUserRole: boolean;
  };
}

export interface IUpdateGeneralSettingsPayload {
  accountId: string;
  payload: IGeneralSettings;
}

export const defaultGeneralSettings = {
  enforceUserMFA: false,
  packageOnlyBookings: {
    isActive: false,
    onlyRestrictUserRole: false
  }
};

export const generalSettingsApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGeneralSettings: builder.query<IGeneralSettings, { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/accounts/${accountId}/general-settings`
      }),
      transformResponse: (response: IGeneralSettings) => {
        return {
          ...response,
          enforceUserMFA: response.enforceUserMFA || false,
          packageOnlyBookings: {
            isActive: response.packageOnlyBookings.isActive || false,
            onlyRestrictUserRole: response.packageOnlyBookings.onlyRestrictUserRole || false
          }
        };
      },
      providesTags: [CPSTagTypes.GeneralSettings]
    }),
    patchGeneralSettings: builder.mutation<IGeneralSettings, IUpdateGeneralSettingsPayload>({
      query: ({ accountId, payload }) => ({
        url: `/accounts/${accountId}/general-settings`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: [CPSTagTypes.GeneralSettings]
    })
  })
});

export const { useGetGeneralSettingsQuery, usePatchGeneralSettingsMutation } = generalSettingsApiSlice;
