import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import {
  resetDataNotReadyPaging,
  resetDataReadyPaging,
  resetFilters,
  selectFilters,
  setFilters
} from 'redux/features/pendingClaimInvoices/pendingClaimInvoiceSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './PendingClaimInvoiceFilter.module.scss';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { ClaimCodeFilters, ClaimInvoiceStatusFilters, FunderFilters } from 'interfaces/invoices/pendingClaimInvoices';
import FilterRadioDropdown, { FilterRadioItem } from 'components/T23/FilterRadioDropdown/FilterRadioDropdown';
import { useGetMinifiedClientRecordQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useMemo, useState } from 'react';
import { Skeleton } from 'antd';
import { debounce } from 'lodash';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetMinifiedGroupListQuery } from 'redux/endpoints/clinicianProfileServices/group';
import { useGetPractitionerListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';

const PER_PAGE = 50;

interface PendingClaimInvoiceFilterProps {
  isLoading: boolean;
  totalItems: number;
}

const PendingClaimInvoiceFilter = ({ isLoading, totalItems }: PendingClaimInvoiceFilterProps) => {
  const dispatch = useAppDispatch();
  const { accountId } = useGetAccountId();
  const { isEdgeUser, isEdgeUserView } = useGetAccountPackageView();

  const filters = useAppSelector(selectFilters);

  const [clientListCurrentPage, setClientListCurrentPage] = useState<number>(1);
  const [clientSearchText, setClientSearchText] = useState<string>('');
  const [practitionerCurrentPage, setPractitionerCurrentPage] = useState<number>(1);
  const [practitionerSearchText, setPractitionerSearchText] = useState('');

  const onResetPaging = () => {
    dispatch(resetDataReadyPaging());
    dispatch(resetDataNotReadyPaging());
  };

  const onClearFilter = () => {
    onResetPaging();
    dispatch(resetFilters());
  };

  const onChangeClientFilter = (clients: FilterCheckListItem[]) => {
    onResetPaging();
    dispatch(setFilters({ ...filters, clients }));
  };

  const onChangePractitionerFilter = (practitioners: FilterCheckListItem[]) => {
    onResetPaging();
    dispatch(setFilters({ ...filters, practitioners }));
  };

  const onChangeGroupFilter = (groups: FilterCheckListItem[]) => {
    onResetPaging();
    dispatch(setFilters({ ...filters, groups }));
  };

  const onChangeFunderFilter = (funder: FilterRadioItem | undefined) => {
    onResetPaging();
    dispatch(setFilters({ ...filters, funder }));
  };

  const onChangeClaimCodeFilter = (code: FilterRadioItem | undefined) => {
    onResetPaging();
    dispatch(setFilters({ ...filters, code }));
  };

  const onChangeStatusFilter = (statuses: FilterCheckListItem[]) => {
    onResetPaging();
    dispatch(setFilters({ ...filters, statuses }));
  };

  const {
    data: clientListData,
    isLoading: isClientListDataLoading,
    isFetching: isClientListDataFetching
  } = useGetMinifiedClientRecordQuery(
    {
      accountId,
      params: {
        page: clientListCurrentPage,
        perPage: PER_PAGE,
        recordStatus: 'active',
        ...(clientSearchText && {
          searchValue: clientSearchText,
          searchBy: SearchFilterEnum.FirstOrLastName
        })
      }
    },
    {}
  );

  const {
    data: practitionerData,
    isLoading: isPractitionerDataLoading,
    isFetching: isPractitionerDataFetching
  } = useGetPractitionerListQuery({
    accountId,
    params: { page: practitionerCurrentPage, perPage: PER_PAGE, status: 'active', searchValue: practitionerSearchText }
  });

  const { data: groupData } = useGetMinifiedGroupListQuery({ accountId, isEdgeUserView });

  const debounceSetClientSearchText = useMemo(
    () =>
      debounce((value) => {
        setClientSearchText(value);
        setClientListCurrentPage(1);
      }, 1000),
    []
  );

  const debouncedSetPractitionersSearchText = useMemo(
    () =>
      debounce((value) => {
        setPractitionerSearchText(value);
        setPractitionerCurrentPage(1);
      }, 1000),
    []
  );

  const { clients, practitioners, groups, funder, code, statuses } = filters;
  const showClearFilter =
    clients.length > 0 || practitioners.length > 0 || groups.length > 0 || funder || code || statuses.length > 0;

  const clientListFilter: FilterCheckListItem[] = clientListData
    ? clientListData.clientRecords.map((item) => ({
        name: `${item.clientProfiles[0].firstName} ${item.clientProfiles[0].lastName}`,
        _id: item._id
      }))
    : [];
  const practitionerList: FilterCheckListItem[] = practitionerData
    ? practitionerData.clinicians.map((item) => ({ name: item.name, _id: item._id }))
    : [];
  const groupList: FilterCheckListItem[] = groupData
    ? groupData.groups.map((item) => ({ name: item.name, _id: item._id }))
    : [];

  const totalClientListPage = clientListData?.paging ? Math.ceil(clientListData.paging.totalItems / PER_PAGE) : 1;
  const totalPractitionerPage = practitionerData?.paging ? Math.ceil(practitionerData.paging.totalItems / PER_PAGE) : 1;

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <div className={styles.numberOfMatchedInvoices}>
          {isLoading ? (
            <div className={styles.loadingProfile}>
              <Skeleton.Input className={styles.loading} active />
            </div>
          ) : (
            <div>
              {showClearFilter ? 'FILTER matches' : 'Showing'} <span className={styles.highLight}>{totalItems}</span>{' '}
              claimable invoices
            </div>
          )}
        </div>
        <ButtonAlt
          error
          disabled={!showClearFilter}
          size={'small'}
          variant={'text'}
          className={styles.clearFilters}
          onClick={onClearFilter}
        >
          Clear filters
        </ButtonAlt>
      </div>

      {/* Filer buttons */}
      <div className={styles.buttonsContainer}>
        {/* Client */}
        <FilterDropdown
          id={'clients'}
          icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
          menuItems={clientListFilter}
          onChangeItem={onToggleFilter(clientListFilter, clients, onChangeClientFilter)}
          searchable
          selectedFilterList={clients}
          showSearchIcon
          loading={isClientListDataLoading}
          hasMoreData={totalClientListPage > clientListCurrentPage || isClientListDataFetching}
          isFetchingMore={isClientListDataFetching}
          loadMore={() => {
            if (!isClientListDataFetching && clientListCurrentPage <= totalClientListPage) {
              setClientListCurrentPage(clientListCurrentPage + 1);
            }
          }}
          enableSelectedBackground
          onClearFilter={() => {
            dispatch(setFilters({ ...filters, clients: [] }));
          }}
          setSearchText={debounceSetClientSearchText}
        >
          Client
        </FilterDropdown>

        {isEdgeUser && (
          <FilterDropdown
            id={'practitioner'}
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            menuItems={practitionerList}
            onChangeItem={onToggleFilter(practitionerList, practitioners, onChangePractitionerFilter)}
            searchable
            selectedFilterList={practitioners}
            showSearchIcon
            loading={isPractitionerDataLoading}
            hasMoreData={totalPractitionerPage > practitionerCurrentPage || isPractitionerDataFetching}
            isFetchingMore={isPractitionerDataFetching}
            enableSelectedBackground
            loadMore={() => {
              if (!isPractitionerDataFetching && practitionerCurrentPage <= totalPractitionerPage) {
                setPractitionerCurrentPage(practitionerCurrentPage + 1);
              }
            }}
            setSearchText={debouncedSetPractitionersSearchText}
          >
            Practitioner
          </FilterDropdown>
        )}

        {isEdgeUser && (
          <FilterDropdown
            id={'group'}
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            menuItems={groupList}
            onChangeItem={onToggleFilter(groupList, groups, onChangeGroupFilter)}
            searchable
            selectedFilterList={groups}
            showSearchIcon
            enableSelectedBackground
          >
            Group
          </FilterDropdown>
        )}

        {/* Funder */}
        <FilterRadioDropdown
          id="funder"
          menuItems={FunderFilters}
          onChangeItem={onChangeFunderFilter}
          selectedItem={funder}
        >
          Payer
        </FilterRadioDropdown>

        {/* Claim code */}
        <FilterRadioDropdown
          id="claimCode"
          menuItems={ClaimCodeFilters}
          onChangeItem={onChangeClaimCodeFilter}
          selectedItem={code}
        >
          Code
        </FilterRadioDropdown>

        {/* Claim invoice status */}
        <FilterDropdown
          id={'status'}
          menuItems={ClaimInvoiceStatusFilters}
          onChangeItem={onToggleFilter(ClaimInvoiceStatusFilters, statuses, onChangeStatusFilter)}
          selectedFilterList={statuses}
          enableSelectedBackground
          showSearchIcon
        >
          Status
        </FilterDropdown>
      </div>
    </div>
  );
};

export default PendingClaimInvoiceFilter;
