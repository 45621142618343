import classnames from 'classnames';
import { get } from 'lodash';
import { SyncOutlined, CheckOutlined } from '@ant-design/icons';

import styles from './Button.module.scss';
import classNames from 'classnames';

export type ButtonStatusType = '' | 'active' | 'finished';
export interface ButtonProps {
  status?: ButtonStatusType;
  activeClassName?: any;
  finishedClassName?: any;
  variant?: 'secondary' | 'primary' | 'pink' | 'link' | 'pill' | 'pill-active';
  icon?: string;
  iconPostFix?: boolean;
  id?: string;
}

const Button = ({
  status = '',
  activeClassName,
  finishedClassName,
  variant,
  icon,
  iconPostFix = false,
  id,
  ...props
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const statusClass = get(
    {
      active: classnames(styles.active, activeClassName),
      finished: classnames(styles.finished, finishedClassName)
    },
    status,
    ''
  );
  const { className } = props;

  return (
    <button
      {...props}
      id={id}
      disabled={status !== '' || props.disabled}
      className={classnames(styles.container, className, statusClass, variant && styles[variant])}
    >
      <span
        className={classNames(styles.submit, {
          [styles.reverse]: iconPostFix
        })}
      >
        {icon && <span className={`material-icons-outlined ${styles.icon}`}>{icon}</span>}
        {props.children}
      </span>
      <span className={styles.loading}>
        <SyncOutlined />
      </span>
      <span className={styles.check}>
        <CheckOutlined />
      </span>
    </button>
  );
};

export default Button;
