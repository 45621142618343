import { ProfileInterface } from 'interfaces/Profile/Profile';
import {
  AttachedGroup,
  clientProfilesInterface,
  clientRecordsInterface
} from '../../../interfaces/Clients/clientsRecord';
import { SortType } from 'components/TableList/SortBtn/SortBtn';
import { PathwayStageList } from '../GroupDetails/components/GroupContent/components/PathwayManagement/interface';

export enum GroupStatus {
  Active = 'active',
  Closed = 'closed'
}

export enum AttachedClientRecordStatus {
  All = 'all',
  Active = 'active',
  Archived = 'archived',
  Completed = 'completed'
}

export enum GroupStage {
  Referral = 'Referral',
  Induction = 'Induction',
  BridgingSupport = 'Bridging Support',
  BeforeProgram = 'Before Program',
  Session = 'Session 1 - 8',
  AfterPrograme = 'After Program',
  Withdrawn = 'Withdrawn'
}

export interface GroupsStatusId {
  tabId?: GroupStatus;
}

export enum GroupDetailsTab {
  Details = 'details',
  Members = 'members',
  Sessions = 'sessions',
  Psychometrics = 'psychometrics',
  Survey = 'survey',
  Consent = 'consent',
  CaseNotes = 'notes',
  Reports = 'reports',
  Attachments = 'attachments',
  Contacts = 'contacts',
  PractitionerAssigned = 'assignment',
  PathwayManagement = 'pathway'
}

export interface GroupDetailsId {
  contentId?: string;
  groupId: string;
  groupTab: GroupDetailsTab;
  childContentId?: string;
}

export interface GroupPsychometricDetailsId extends GroupDetailsId {
  clientRecordId: string;
  responseId: string;
}

export interface KeyContact {
  _id: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  email?: string;
  tags?: string[];
}

export interface LeadClinicianProfile {
  avatar?: string;
  title?: string;
  name: string;
  mobileNumber?: string;
  email?: string;
}

export interface Groups {
  _id: string;
  status: GroupStatus;
  groupId?: string;
  name: string;
  leadClinicianAuth0Id: string;
  leadClinicianProfile: LeadClinicianProfile;
  clinicianAuth0Ids: string[];
  description?: string;
  tags: GroupTag[];
  clinicianProfiles: ProfileInterface[];
  keyContacts: KeyContact[];
  clientCount: number;
  createdAt: Date;
  updatedAt: Date;
  stagesConfiguration: PathwayStageList;
}

export interface GroupsWithSelected extends Groups {
  isSelected?: boolean;
}

export interface GroupsLeadFromAPI extends LeadClinicianProfile {
  logo?: string;
}

export interface GroupsFromAPI extends Omit<Groups, 'tags' | 'leadClinicianProfile'> {
  tags: string[];
  leadClinicianProfile: GroupsLeadFromAPI;
  leadClinicianAuth0Id: string;
}

export interface GroupListData {
  groups: GroupsFromAPI[];
  count: {
    active: number;
    closed: number;
  };
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export type MinifiedGroup = Pick<Groups, '_id' | 'name'>;

export interface GroupsMinifiedData {
  groups: MinifiedGroup[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export interface GroupTag {
  _id: string;
  label: string;
  accountId: string;
}

export interface ClientRecordWithGroupStatus extends clientRecordsInterface {
  groupStatus: Exclude<AttachedClientRecordStatus, AttachedClientRecordStatus.All>;
  groupStage: string;
  attachedGroups: AttachedGroup[];
}

export interface GroupMemberList {
  clientRecords: ClientRecordWithGroupStatus[];
  count: {
    active: number;
    completed: number;
    archived: number;
    all: number;
  };
  paging: {
    page: number;
    perPage: number;
    totalItem: number;
  };
}

export interface GroupMemberListFilterParameter {
  status?: string;
  q?: string;
  page?: string;
  perPage?: string;
  sortByClientName?: SortType;
  sortByDateJoined?: SortType;
}

export interface GroupAssessmentClient {
  clientRecordId: string;
  clientProfiles: AssessmentClientProfile[];
}

export interface UnassignedGroupMemberList {
  clientRecords: ClientRecordWithGroupStatus[];
  paging: {
    page: number;
    perPage: number;
    totalItem: number;
  };
}

export interface UnassignedGroupMemberListFilter {
  q: string;
  page: number;
  perPage: number;
  sortByClientName: SortType;
}

type FormSendStatus =
  | {
      isSent: true;
    }
  | {
      isSent: false;
      sendAt: string; // YYYY-MM-DD hh:mmA Z
    };

export type GroupPsychometricMenu = FormSendStatus & {
  _id: string;
  clinicalAssessmentId: string;
  createdAt: string;
};

export interface ConsentFormInterface {
  _id: string;
  clientProfiles: {
    avatar: string;
    firstName: string;
    lastName: string;
    initials: string;
    initialsBackgroundColor: string;
    name: string;
  }[];
  recordType: 'adult' | 'couple' | 'minor' | 'group' | 'child';
  isFormSent: boolean;
  isFormSigned: boolean;
  dateSigned: string;
}

export interface ConsentFormListInterface {
  consentFormList: ConsentFormInterface[];
  count: {
    active: number;
    completed: number;
    archived: number;
    all: number;
  };
  paging: {
    page: number;
    perPage: number;
    totalItem: number;
  };
}

export interface ConsentFormResponseList {
  _id: string;
  name: string;
  createdAt: string;
}

export interface ConsentFormListFilterParameter {
  status?: string;
  q?: string;
  page?: string;
  perPage?: string;
  sortColumn?: string;
  sortType?: SortType;
}

export interface GroupConsentForm {
  id: string;
  name: string;
}

export interface AssessmentClientProfile extends clientProfilesInterface {
  clientProfileId: string;
  assessmentSent: boolean;
  assessmentComplete: boolean;
  processedScore?: any;
}
