import { EpisodeInterface } from 'interfaces/Episodes/episodes';
import EpisodeStatusBadge from '../EpisodeStatusBadge';
import styles from './DetailEpisodeStatusBadge.module.scss';

interface DetailEpisodeStatusBadgeProps {
  episode: EpisodeInterface;
}

export const DetailEpisodeStatusBadge = ({ episode }: DetailEpisodeStatusBadgeProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSideContainer}>
        <div className={styles.order}>Episode {episode.order}</div>
      </div>
      <EpisodeStatusBadge status={episode.status} />
    </div>
  );
};
