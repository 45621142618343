import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface ClientDetailsState {
  isRefreshDataDisabled: boolean;
}

const initialState: ClientDetailsState = {
  isRefreshDataDisabled: true
};

export const clientDetailsSlice = createSlice({
  name: 'clientDetailsSlice',
  initialState,
  reducers: {
    setIsRefreshDataDisabled: (state, action) => {
      state.isRefreshDataDisabled = action.payload;
    }
  }
});

export const selectIsRefreshDataDisabled = (state: RootState) => state.clientDetailsSlice.isRefreshDataDisabled;

export default clientDetailsSlice.reducer;

export const { setIsRefreshDataDisabled } = clientDetailsSlice.actions;
