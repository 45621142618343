import { useEffect, useMemo, useState } from 'react';
import styles from './PaginationDescV2.module.scss';
import { Skeleton } from 'antd';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import Select from 'components/v2/Select/Select';

interface PaginationDescV2Props {
  currentPage: number;
  perPage: number;
  totalItem: number;
  onPageChange: (pageValue: number) => void;
  isLoading?: boolean;
}

const PaginationDescV2 = ({ currentPage, perPage, totalItem, onPageChange, isLoading }: PaginationDescV2Props) => {
  const [page, setPage] = useState({
    havePrev: false,
    haveNext: false
  });

  const totalAvailablePage = Math.ceil(totalItem / perPage);

  const pageOptions = useMemo(() => {
    return Array.from({ length: totalAvailablePage }, (_, index) => ({
      value: `${index + 1}`,
      label: `Page ${index + 1}`
    }));
  }, [totalAvailablePage]);

  useEffect(() => {
    setPage({
      havePrev: currentPage > 1,
      haveNext: currentPage < totalAvailablePage
    });
  }, [perPage, currentPage, totalAvailablePage]);

  const handleClickPrev = () => {
    if (currentPage > 1) {
      const minusPage = currentPage - 1;
      setPage({
        havePrev: minusPage > 1,
        haveNext: totalAvailablePage > minusPage
      });
      onPageChange(minusPage);
    }
  };

  const handleClickNext = () => {
    if (currentPage < totalAvailablePage) {
      const addPage = currentPage + 1;
      setPage({
        havePrev: addPage > 1,
        haveNext: totalAvailablePage > addPage
      });
      onPageChange(addPage);
    }
  };

  const handleChangePage = (page?: number) => {
    if (!page) {
      return;
    }
    setPage({
      havePrev: page > 1,
      haveNext: totalAvailablePage > page
    });
    onPageChange(page);
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loadingWrapper}>
          <Skeleton.Input style={{ width: '160px', height: '25px', borderRadius: '4px' }} active />
        </div>
      ) : (
        <>
          <div className={styles.buttonWrapper}>
            <ButtonAlt
              size="medium"
              variant="outlined"
              disabled={isLoading || !page.havePrev}
              onClick={handleClickPrev}
              icon="arrow_back"
              className={styles.leftIconOnly}
            />
            <Select
              options={pageOptions}
              value={pageOptions?.find((option) => option.value === currentPage.toString())}
              onChange={(option) => handleChangePage(Number(option?.value))}
              isDisabled={pageOptions.length <= 1}
              containerClass={styles.selectPageContainer}
              menuPlacement="top"
              filledArrow
              styles={{
                control: (controlBase: any) => ({
                  ...controlBase,
                  minHeight: '24px',
                  backgroundColor: 'transparent',
                  border: 'none',
                  borderBottom: '1px solid #D3D8DD',
                  borderRadius: 0,
                  boxShadow: 'none',
                  cursor: 'pointer'
                }),
                valueContainer: (base) => ({ ...base, paddingLeft: 0 })
              }}
            />
            <ButtonAlt
              size="medium"
              variant="outlined"
              disabled={isLoading || !page.haveNext}
              onClick={handleClickNext}
              icon="arrow_forward"
              contentClassName={styles.navigateBtn}
              className={styles.leftIconOnly}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PaginationDescV2;
