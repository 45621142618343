import { notification } from 'antd';
import Button from 'components/Button/Button';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import InvoiceView from 'components/InvoiceView/InvoiceView';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import SelectGroupOrClientModalBox from 'components/SelectClient/SelectGroupOrClientModalBox/SelectGroupOrClientModalBox';
import ShareInfoBox from 'components/ShareInfoBox/ShareInfoBox';
import InvoiceStatusBox from 'components/StatusHistoryBox/InvoiceStatusBox';
import { useFetchNotificationSettings } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';
import { InvoiceStatus } from 'pages/Invoices/interface';
import InvoiceViewWithTemplate from 'pages/InvoiceViewWithTemplate/InvoiceViewWithTemplate';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetInvoiceSettingsQuery } from 'redux/endpoints/billingServices/invoiceSetting';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { putInvoiceStatus, putResendInvoice, sendInvoice } from 'utils/http/BillingService/Invoice/invoice';
import { ParticipantType } from '../../../../interfaces/Schedule/AppointmentType';
import { useFetchInvoiceDetailWithFullClientRecordOrGroup } from './hooks/getInvoice';
import { useFetchInvoiceHistory } from './hooks/getInvoiceHistory';
import styles from './InvoiceDetail.module.scss';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { useGetAccountInfo } from 'utils/hooks/GetAccountInfo/getAccountInfo';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import EpisodeTaggingSection from 'components/EpisodeTaggingSection/EpisodeTaggingSection';
import { useAppDispatch } from 'redux/hooks';
import { BSTagTypes, billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { security } from 'utils/security';

interface InvoicedItem {
  overview: string;
  cost: number;
  appointmentId?: string;
  appointmentDate: string;
}

const getClinicianDetails = (clinician?: ProfileInterface) => {
  if (clinician) {
    const { avatar, name, practice, title, email, mobileNumber } = clinician;

    const firstPractice = practice ? practice : undefined;

    return {
      clinician: {
        avatar,
        email,
        mobileNumber,
        name: `${title || ''}${name ? ` ${name}` : ''}`
      },
      practice: firstPractice
        ? {
            address: firstPractice?.locations?.[0],
            logo: firstPractice?.logo,
            mobileNumber: firstPractice?.mobileNumber,
            name: firstPractice?.name
          }
        : undefined
    };
  } else {
    return {};
  }
};

const getFormattedItems = (items: InvoicedItem[], taxRate: string) => {
  const taxAmount = Number(taxRate) / 100;

  return items.map(({ cost, ...rest }) => {
    const costAmount = Number(cost);

    const tax = taxAmount * costAmount;
    const total = costAmount + tax;

    return { ...rest, cost: costAmount, tax, total };
  });
};

const InvoiceDetail = () => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { clinicianProfile } = useGetAccountInfo();
  const { isEdgeUserView } = useGetAccountPackageView();
  const { invoiceId = '' } = useParams<{ invoiceId: string }>();
  const { isNotificationSettingsLoading, notificationSettings } = useFetchNotificationSettings(token, accountId);
  const { invoice, isInvoiceLoading, fetchInvoiceDetail } = useFetchInvoiceDetailWithFullClientRecordOrGroup(
    token,
    invoiceId,
    notificationSettings
  );
  const { invoiceHistory, isInvoiceHistoryLoading } = useFetchInvoiceHistory(token, invoiceId);
  const {
    data: invoiceSettings,
    isLoading: isInvoiceSettingLoading,
    isFetching: isInvoiceSettingFetching
  } = useGetInvoiceSettingsQuery({ clinicianId: clinicianProfile?._id || '' }, { skip: !clinicianProfile?._id });
  const isInvoiceSettingsLoading = isInvoiceSettingLoading || isInvoiceSettingFetching;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { INVOICES } = useRoutesGenerator();
  const clinicianDetails = getClinicianDetails(clinicianProfile);
  const [sendInvoiceButtonStatus, setSendInvoiceButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [resendInvoiceButtonStatus, setResendInvoiceButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [isInvoiceSent, setIsInvoiceSent] = useState<boolean>(
    !!invoice?.resendRecipients?.sendTo?.length ||
      !!invoice?.resendRecipients?.sendKeyContactIds?.length ||
      !!invoice?.resendRecipients?.sendClient
  );
  const { isInvoiceTemplateEnabled } = useGetFeatureToggle();

  useEffect(() => {
    setIsInvoiceSent(
      !!invoice?.resendRecipients?.sendTo?.length ||
        !!invoice?.resendRecipients?.sendKeyContactIds?.length ||
        !!invoice?.resendRecipients?.sendClient
    );
  }, [invoice]);

  const participationType = invoice?.clientRecord ? ParticipantType.OneToOne : ParticipantType.Group;

  const practiceCInfo = invoiceSettings?.contactDetails?.practice;
  const clinicianCInfo = invoiceSettings?.contactDetails?.clinician;

  const getHeaderDetails = {
    practice: {
      ...clinicianDetails?.practice,
      address: practiceCInfo?.address.isVisible ? practiceCInfo?.address.value : '',
      mobileNumber: practiceCInfo?.mobileNumber.isVisible ? practiceCInfo?.mobileNumber.value : ''
    },
    clinician: {
      ...clinicianDetails?.clinician,
      email: clinicianCInfo?.email.isVisible ? clinicianCInfo?.email.value : '',
      mobileNumber: clinicianCInfo?.mobileNumber.isVisible ? clinicianCInfo?.mobileNumber.value : ''
    }
  };

  const formattedItems = useMemo(
    () =>
      invoice
        ? invoice.isWriteOff
          ? invoice.items
          : getFormattedItems(invoice.items, invoice.taxRate.toString())
        : [],
    [invoice]
  );

  const backToListing = () => {
    navigate(INVOICES.BASE);
  };

  const onChangeStatus = async (status: InvoiceStatus) => {
    const token = await security.getAccessTokenSilently();

    if (token) {
      try {
        setIsUpdatingStatus(true);

        notification.warning({
          message: 'Updating invoice status...',
          duration: 2,
          closeIcon: <span className={'notify'}>OK</span>
        });

        await putInvoiceStatus(token, invoiceId, status);
        notification.success({
          message: 'Invoice status updated.',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        dispatch(billingServicesApiSlice.util.invalidateTags([BSTagTypes.Invoices, BSTagTypes.InvoiceSummary]));
        setIsUpdatingStatus(false);
        fetchInvoiceDetail(token);
      } catch (ex) {
        notification.error({
          message: 'Status update failed. Please try again, if failures persist please contact us to investigate.',
          duration: 2
        });
        setIsUpdatingStatus(false);
      }
    }
  };

  const onResendInvoice = async () => {
    const token = await security.getAccessTokenSilently();

    if (token) {
      try {
        setResendInvoiceButtonStatus('active');
        await putResendInvoice(token, invoiceId);
        notification.success({
          message: 'Successfully resent this invoice!',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setResendInvoiceButtonStatus('');
      } catch (ex) {
        setResendInvoiceButtonStatus('');
        notification.error({ message: "Something went wrong while trying to update this invoice's status" });
      }
    }
  };

  const onSendInvoice = async (shareDetails: {
    sendAt: Date;
    sendClient: boolean;
    sendTo?: string[];
    note?: string;
  }) => {
    if (token) {
      try {
        setSendInvoiceButtonStatus('active');
        await sendInvoice(token, invoiceId, shareDetails);
        notification.success({
          message: 'Invoice sent',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setIsInvoiceSent(true);
        setSendInvoiceButtonStatus('finished');
        setSendInvoiceButtonStatus('');
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to send invoice' });
        setSendInvoiceButtonStatus('');
      }
    }
  };

  const canEditInvoice = !(isEdgeUserView && clinicianProfile?._id !== invoice?.clinician?._id);
  const paidAmount = invoice?.paid?.total || 0;

  if (isInvoiceTemplateEnabled && invoice?.template?._id) {
    return <InvoiceViewWithTemplate />;
  }

  return (
    <HelmetWrapper title={'Tacklit - Invoices'}>
      <ContentLayout>
        <div className={styles.container}>
          {isInvoiceLoading ||
          isInvoiceSettingsLoading ||
          isInvoiceHistoryLoading ||
          isNotificationSettingsLoading ||
          !invoice ? (
            <div className={styles.loading}>
              <LoadingCircle />
            </div>
          ) : (
            <>
              <div className={styles.header}>
                <div className={styles.title}>
                  Invoice Created{' '}
                  <Button className={styles.button} type="button" onClick={backToListing}>
                    <i className={`material-icons-outlined ${styles.icon}`}>arrow_back_ios</i>
                    <span className={styles.text}>Back to Invoice List</span>
                  </Button>
                </div>
                <div className={styles.invoiceIdContainer}>
                  <div className={styles.label}>Invoice ID</div>
                  <div className={styles.invoiceId}>{invoice.invoiceId}</div>
                </div>
              </div>
              <div className={styles.content}>
                <div className={styles.leftCol}>
                  <SelectGroupOrClientModalBox
                    clientRecord={invoice.clientRecord}
                    selectedGroup={invoice.group}
                    participationType={participationType}
                  />
                  {invoice.episodeId && (
                    <div className={styles.card}>
                      <EpisodeTaggingSection
                        containerClassName={styles.episodeContainer}
                        selectedClientRecordId={invoice.clientRecord._id || ''}
                        selectedEpisode={invoice.episodeId}
                        readOnly
                        onSelectEpisode={() => {}}
                      />
                    </div>
                  )}
                  <InvoiceStatusBox
                    isUpdatingStatus={isUpdatingStatus}
                    resendInvoiceButtonStatus={resendInvoiceButtonStatus}
                    onChangeStatus={onChangeStatus}
                    onResendInvoice={onResendInvoice}
                    histories={invoiceHistory || []}
                    invoiceStatus={invoice.status || InvoiceStatus.Closed}
                    isNotAllowResend={!isInvoiceSent}
                    allowVoid={paidAmount === 0}
                    allowEdit={canEditInvoice}
                  />
                  <ShareInfoBox
                    invoice={invoice}
                    label="Invoice"
                    defaultNote={invoiceSettings?.note}
                    sendInvoiceButtonStatus={sendInvoiceButtonStatus}
                    onSendInvoice={onSendInvoice}
                    participationType={participationType}
                  />
                </div>
                <div className={styles.rightCol}>
                  <div className={styles.invoiceContainer}>
                    <InvoiceView
                      clientRecord={invoice.clientRecord}
                      clinician={getHeaderDetails.clinician as any}
                      practice={getHeaderDetails.practice as any}
                      description={invoice.description}
                      discount={
                        invoice.discount ? { ...invoice.discount, value: Number(invoice.discount.value) } : undefined
                      }
                      dueDate={invoice.dueDate}
                      invoiceId={invoice.invoiceId}
                      issueDate={invoice.issueDate}
                      items={formattedItems}
                      paymentMethods={invoice.paymentMethods}
                      taxRate={Number(invoice.taxRate)}
                      selectedGroup={invoice.group}
                      medicare={invoice.medicare}
                      status={invoice.status}
                      invoiceAmount={invoice.invoiceAmount}
                      amountChanged={invoice.amountChanged}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InvoiceDetail;
