import { BentResponse } from 'bent';
import { httpClient } from 'utils/httpClient';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getGroupListingByAccountId = (token: string, accountId: string, status?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups${status ?? ''}`
  ) as Promise<BentResponse>;

export const getGroupListByGroupId = (token: string, status?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/groups${status ?? ''}`
  ) as Promise<BentResponse>;

export const getGroupDetailsByGroupId = (token: string, groupId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/groups/${groupId}`
  ) as Promise<BentResponse>;

export const getGroupTags = (token: string, accountId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/group-tags`
  ) as Promise<BentResponse>;

export const patchReassignGroup = (token: string, accountId: string, groupId: string, payload: Record<string, any>) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}:reassign`,
    payload
  ) as Promise<BentResponse>;

export const postKeyContact = (token: string, accountId: string, groupId: string, body: Record<string, any>) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/key-contacts`,
    body
  ) as Promise<BentResponse>;

export const deleteKeyContact = (token: string, accountId: string, groupId: string, keyContactId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/key-contacts/${keyContactId}`
  ) as Promise<BentResponse>;

export const postNewGroup = (token: string, accountId: string, body: Record<string, any>) => {
  return httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(`/accounts/${accountId}/groups`, body);
};

export const putGroup = (token: string, accountId: string, groupId: string, body: Record<string, any>) => {
  return httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}`,
    body
  );
};

export const putCheckGroupIdAvailability = (token: string, accountId: string, body: Record<string, any>) => {
  return httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups:checkGroupIdAvailability`,
    body
  );
};

export const patchStageGroup = (
  token: string,
  accountId: string,
  groupId: string,
  clientRecordId: string,
  payload: Record<string, any>
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/client-records/${clientRecordId}:updateStage`,
    payload
  ) as Promise<BentResponse>;

export const patchRemoveClient = (token: string, accountId: string, groupId: string, clientRecordId: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/client-records/${clientRecordId}:removeClient`
  ) as Promise<BentResponse>;

export const patchUpdateStatus = (
  token: string,
  accountId: string,
  groupId: string,
  clientRecordId: string,
  payload: Record<string, any>
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/groups/${groupId}/client-records/${clientRecordId}:updateStatus`,
    payload
  ) as Promise<BentResponse>;
