import { useEffect, useState } from 'react';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import styles from './AssignmentClinicians.module.scss';
import {
  PractitionersDetailsInterface,
  PractitionersDetailsWithSelected
} from 'interfaces/Practitioners/practitionersListing';
import { useField } from 'formik';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

const sortClinician = (list: PractitionersDetailsWithSelected[], sortAsc: boolean) => {
  return list?.sort((a, b) => {
    return sortAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
  });
};

interface CliniciansProps {
  clinicians: PractitionersDetailsInterface[];
}

const AssignmentClinicians = ({ clinicians }: CliniciansProps) => {
  const [{ value: selectedClinicians }, metaAssignedClinicians, { setValue: setSelectedClinicians }] =
    useField('assignedClinicians');
  const [clinicianList, setClinicianList] = useState<PractitionersDetailsWithSelected[]>([]);

  useEffect(() => {
    const sortedClinician = sortClinician(clinicians, true).map((clin) => {
      return {
        ...clin,
        isSelected: selectedClinicians.indexOf(clin._id) !== -1
      };
    });
    setClinicianList(sortClinician(sortedClinician, true));
  }, [clinicians, selectedClinicians]);

  const handleAddClient = (clinicianId: string) => {
    let newClinicianList = [];
    for (let data of clinicianList) {
      newClinicianList.push({
        ...data,
        isSelected: data.isSelected ? data.isSelected : data._id === clinicianId
      });
    }
    setClinicianList(newClinicianList);

    const selectedList = newClinicianList.filter((obj) => obj.isSelected);
    setSelectedClinicians(selectedList.map((obj) => obj._id));
  };

  const handleRemoveClient = (clientId: string) => {
    let newClinicianList = [];
    for (let data of clinicianList) {
      newClinicianList.push({
        ...data,
        isSelected: data._id === clientId ? false : data.isSelected
      });
    }
    setClinicianList(newClinicianList);

    const selectedList = newClinicianList.filter((obj) => obj.isSelected);
    setSelectedClinicians(selectedList.map((obj) => obj._id));
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.clientListContainer}>
          <div className={styles.cardTitle}>Select who to assign</div>
          <div className={styles.clientListWrapper}>
            {clinicianList?.map(
              ({ _id, avatar, name, isSelected }, index: number) =>
                !isSelected && (
                  <div className={styles.clientListCard} key={index}>
                    <div className={styles.avatarWrapper}>
                      <ClientProfileAvatar avatarUrl={avatar} initialClassName={styles.initialName} />
                    </div>
                    <span className={styles.name}>{name}</span>
                    <span className={styles.addBtn} onClick={() => handleAddClient(_id)}>
                      + Add
                    </span>
                  </div>
                )
            )}
          </div>
        </div>
        <div className={styles.clientCartContainer}>
          <div className={styles.cardTitle}>
            {selectedClinicians.length} clinician{selectedClinicians.length > 1 ? 's' : ''} added
          </div>
          <div className={styles.clientListWrapper}>
            {clinicianList?.map(
              ({ _id, avatar, name, isSelected }, index: number) =>
                isSelected && (
                  <div className={styles.clientListCard} key={index}>
                    <div className={styles.avatarWrapper}>
                      <ClientProfileAvatar avatarUrl={avatar} initialClassName={styles.initialName} />
                    </div>
                    <span className={styles.name}>{name}</span>
                    <span className={styles.removeBtn} onClick={() => handleRemoveClient(_id)}>
                      Remove
                    </span>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
      <ErrorMessage
        className={styles.errorMargin}
        visible
        error={metaAssignedClinicians.touched ? metaAssignedClinicians.error : ''}
      />
    </>
  );
};

export default AssignmentClinicians;
