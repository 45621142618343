import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import MessagesContent from './components/MessagesContent/MessagesContent';

import styles from './Messages.module.scss';

const Messages = () => {
  return (
    <HelmetWrapper title={'Tacklit - Messages'}>
      <ContentLayout>
        <div className={styles.headerContainer}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>Messages</div>
          </div>
        </div>
        <MessagesContent />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Messages;
