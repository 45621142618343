import { ChangeEvent, useState } from 'react';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { ButtonStatusType } from 'components/v2/Button/Button';
import { deleteGroupAppointment } from 'utils/http/ScheduleService/Groups/groups';
import { deleteAppointment } from 'utils/http/appointment';
import { Modal, notification } from 'antd';
import { useAppDispatch } from 'redux/hooks';
import { SSTagTypes, scheduleServicesApiSlice } from 'redux/services/scheduleServicesApiSlice';

interface CancelAppointmentProps {
  appointment: AppointmentSlots;
  token: string;
  onFinishMarkStatus: () => void;
  recurringAppointmentId?: string;
}

export const useCancelAppointment = ({
  appointment,
  token,
  onFinishMarkStatus,
  recurringAppointmentId
}: CancelAppointmentProps) => {
  const dispatch = useAppDispatch();

  const [isRecurring, setIsRecurring] = useState(false);
  const [isConfirmingCancel, setIsConfirmingCancel] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [cancelRecurringStatus, setCancelRecurringStatus] = useState<ButtonStatusType>('');

  let cancelButtonLabel = 'Cancel appointment';
  if (isConfirmingCancel) {
    cancelButtonLabel = 'Confirm cancellation?  YES';
  }
  if (isCancelling) {
    cancelButtonLabel = 'Processing...';
  }

  const handleCloseModal = () => {
    setIsRecurring(false);
  };

  const handleChangeReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
    setIsErrorVisible(isErrorVisible && reason.trim() === '');
  };

  const handleCancelRecurringAppointment = () => {
    setIsRecurring(false);
  };

  const handleAbortCancelRecurringAppointments = () => {
    setIsRecurring(false);
    setIsConfirmingCancel(false);
    setReason('');
  };

  const cancelAppointment = async (includeRecurringAppointments: boolean) => {
    setCancelRecurringStatus('active');
    setIsCancelling(true);

    try {
      const { _id, groupId, clientRecord } = appointment;

      const payload = { includeRecurringAppointments, notes: reason };
      if (groupId) {
        await deleteGroupAppointment(token, groupId, _id, payload);
      } else {
        await deleteAppointment(_id, payload, token);
      }

      Modal.destroyAll();

      setCancelRecurringStatus('finished');

      !groupId &&
        dispatch(
          scheduleServicesApiSlice.util.invalidateTags([
            { type: SSTagTypes.NextAppointmentByCurrent, id: clientRecord?._id }
          ])
        );

      notification.success({
        message: 'The appointment has been cancelled',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      onFinishMarkStatus();
    } catch (error) {
      notification.error({ message: `Something went wrong while trying to cancel your appointment` });
    } finally {
      setCancelRecurringStatus('');
      setIsCancelling(false);
    }
  };

  const handleCancel = async () => {
    if (!isConfirmingCancel) {
      setIsConfirmingCancel(true);
    } else {
      setIsErrorVisible(reason.trim() === '');
    }

    if ((reason !== undefined && reason.trim() !== '') || appointment.isActivity) {
      if (recurringAppointmentId) {
        setIsRecurring(true);
      } else {
        await cancelAppointment(false);
      }
    } else {
      setReason('');
    }
  };

  const resetCancelValues = () => {
    setIsConfirmingCancel(false);
    setReason('');
    setIsErrorVisible(false);
  };

  return {
    isCancelling,
    isRecurring,
    isConfirmingCancel,
    reason,
    isErrorVisible,
    cancelRecurringStatus,
    cancelButtonLabel,
    handleChangeReason,
    handleCancelRecurringAppointment,
    handleCloseModal,
    handleAbortCancelRecurringAppointments,
    cancelAppointment,
    handleCancel,
    resetCancelValues
  };
};
