import moment from 'moment';
import { Paging, SortType, Sorting } from 'redux/features/utils';
import { ReferralFilters, ReferralSearch } from 'redux/referrals/referralSlice';
import { ReferralHistorySource, ReferralStatus } from 'interfaces/Referral/Referral';
import { OrganisationType, ReferralLinkage, ReferralTab, ReferrerType, SearchFilterEnum } from './interface';
import { ROLE_TYPE_OPTIONS } from 'components/AddPatientModalV2/constants';

export const REFERRAL_PER_PAGE = 20;

export interface GetReferralListQuery {
  page?: number;
  perPage?: number;
  statuses?: string;
  referrerIds?: string;
  linkages?: string;
  packageIds?: string;
  sortBy?: string;
  sortType?: SortType;
  searchBy?: SearchFilterEnum;
  searchValue?: string;
  clientRecordIds?: string;
}

export const getReferralsRequestPayload = (
  search: ReferralSearch,
  paging: Paging,
  sorting: Sorting,
  filters: ReferralFilters
): GetReferralListQuery => {
  const { page, perPage } = paging;
  const { sortBy, sortType } = sorting;
  const { dateRange, statuses, referrerTypes, linkages, packages } = filters;
  const { searchBy, searchValue } = search;

  return {
    page,
    perPage,
    ...(dateRange.from &&
      dateRange.to && {
        createdAtFrom: dateRange.from,
        createdAtTo: dateRange.to && moment(dateRange.to).endOf('day').toISOString()
      }),
    ...(statuses.length > 0 && {
      statuses: statuses.map(({ _id }: any) => _id).join(',')
    }),
    ...(referrerTypes.length > 0 && {
      referrerTypes: referrerTypes.map(({ _id }: any) => _id).join(',')
    }),
    ...(linkages.length > 0 && {
      linkages: linkages.map(({ _id }: any) => _id).join(',')
    }),
    ...(packages.length > 0 && {
      packageIds: packages.map(({ _id }: any) => _id).join(',')
    }),
    sortBy,
    sortType,
    searchBy,
    searchValue
  };
};

export const getReferralDefaultFilters = (activeTab: ReferralTab = ReferralTab.New) => {
  switch (activeTab) {
    case ReferralTab.New:
      return {
        statuses: [{ _id: ReferralStatus.New, name: 'New' }],
        linkages: []
      };
    case ReferralTab.Triage:
      return {
        statuses: [{ _id: ReferralStatus.Triage, name: 'Triage' }],
        linkages: []
      };
    case ReferralTab.Decline:
      return {
        statuses: [{ _id: ReferralStatus.Decline, name: 'Declined' }],
        linkages: []
      };
    case ReferralTab.Accepted:
      return {
        statuses: [{ _id: ReferralStatus.Accepted, name: 'Accepted' }],
        linkages: []
      };
    case ReferralTab.Unlinked:
      return {
        statuses: [],
        linkages: [{ _id: ReferralLinkage.Unlinked, name: 'Unlinked' }]
      };
    default:
      return {
        statuses: [],
        linkages: []
      };
  }
};

export const searchFilterItems = [
  { name: 'Referral ID', _id: SearchFilterEnum.ReferralId },
  { name: 'Referrer Name', _id: SearchFilterEnum.ReferrerName },
  { name: 'Subject Name', _id: SearchFilterEnum.SubjectName }
];

export const ORGANISATION_TYPE_OPTIONS = [
  { label: 'GP Surgery', value: OrganisationType.GpSurgery },
  { label: 'Charity', value: OrganisationType.Charity },
  { label: 'Community Org', value: OrganisationType.Community },
  { label: 'School or College', value: OrganisationType.SchoolOrCollege },
  { label: 'Hospital', value: OrganisationType.Hospital },
  { label: 'Employer', value: OrganisationType.Employer },
  { label: 'Other Health Org', value: OrganisationType.OtherHealth },
  { label: 'Other', value: OrganisationType.Other }
];

export const isStrongMatch = (score: number, isMedicare: boolean) =>
  (isMedicare && score === 4) || (!isMedicare && score >= 3);

export const isLargeClientMatchCount = (count: number) => count > 10;

export const getRoleLabel = (roleVal: string) => {
  const roleLabel = ROLE_TYPE_OPTIONS.find((obj) => obj.value === roleVal);
  return roleLabel ? roleLabel.label : 'Other';
};

export const getReferrerTypeOptions = (referrerType: ReferrerType) => [
  {
    id: ReferrerType.Self,
    label: 'Self',
    isActive: referrerType === ReferrerType.Self
  },
  {
    id: ReferrerType.FriendFamily,
    label: 'Friend/Family',
    isActive: referrerType === ReferrerType.FriendFamily
  },
  {
    id: ReferrerType.Organisation,
    label: 'Organisation',
    isActive: referrerType === ReferrerType.Organisation
  },
  {
    id: ReferrerType.Professional,
    label: 'Professional',
    isActive: referrerType === ReferrerType.Professional
  }
];

export const getActorName = ({
  actor,
  clientName = 'Unknown Client Name',
  clinicianName,
  practiceName
}: {
  actor: ReferralHistorySource;
  clientName?: string;
  clinicianName: string;
  practiceName: string;
}) => {
  if (actor === ReferralHistorySource.Clinician) {
    return clinicianName ?? practiceName;
  } else if (actor === ReferralHistorySource.Client) {
    return clientName;
  } else if (actor === ReferralHistorySource.System) {
    return 'System';
  }
  return 'Unknown Actor';
};
