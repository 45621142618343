import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { InferType } from 'yup';
import { TypedSchema } from 'yup/lib/util/types';

interface FormContextType<T extends TypedSchema, P extends any> {
  values: InferType<T>;
  setValues: (values: Partial<InferType<T>>) => void;
  submitForm: (context?: P) => Promise<void> | void;
  isSubmitting: boolean;
  isValid: boolean;
  isWaitingFormValidation: boolean;
  setIsWaitingFormValidation: (values: boolean) => void;
}

interface FormProps<T extends TypedSchema, P extends any> {
  validationSchema: T;
  initialValues: InferType<T>;
  children?: (props: FormContextType<T, P>) => React.ReactNode | React.ReactNode;
  onSubmit?: (values: InferType<T>, context?: P) => void;
}

const FormContext = createContext<FormContextType<TypedSchema, any>>({
  values: {},
  setValues: () => {},
  isSubmitting: false,
  isValid: false,
  submitForm: () => {},
  isWaitingFormValidation: false,
  setIsWaitingFormValidation: () => {}
});

export const useFormContext = <T extends TypedSchema, P extends any>() => {
  const context = useContext(FormContext) as FormContextType<T, P>;
  return context;
};

export const Form = <T extends TypedSchema, P extends any>({
  validationSchema,
  initialValues,
  children,
  onSubmit
}: FormProps<T, P>) => {
  const [values, setValues] = useState(initialValues);
  const schema = useRef(validationSchema).current;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isWaitingFormValidation, setIsWaitingFormValidation] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const submitForm = async (context?: P) => {
    if (!isValid || isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    await onSubmit?.(values, context);
    setIsSubmitting(false);
  };

  type Values = typeof initialValues;

  const setFormValues = useCallback(
    (values: Partial<Values>) => {
      setValues((prev) => {
        const newValues = { ...prev, ...values };

        try {
          (schema as unknown as InferType<T>).validateSync(newValues);
          setIsValid(true);
        } catch (e) {
          setIsValid(false);
        }

        return newValues;
      });
    },
    [schema]
  );

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  return (
    <FormContext.Provider
      value={{
        values,
        setValues: setFormValues,
        isSubmitting,
        isValid,
        submitForm,
        setIsWaitingFormValidation,
        isWaitingFormValidation
      }}
    >
      {children?.({
        values,
        setValues: setFormValues,
        isSubmitting,
        isValid,
        submitForm,
        setIsWaitingFormValidation,
        isWaitingFormValidation
      })}
    </FormContext.Provider>
  );
};
