import styles from './LetterContent.module.scss';
import ReportContentHeader from 'pages/Report/components/ReportContentComponents/ReportContentHeader/ReportContentHeader';
import { PutLetterRequest } from 'interfaces/Letters/letter';
import SignatureSection from 'pages/Report/components/ReportContentComponents/SignatureSection/SignatureSection';
import WidgetTextEditor2 from 'components/WidgetTextEditor2/WidgetTextEditor2';
import { Skeleton } from 'antd';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

type LetterContentProps = {
  letterName: PutLetterRequest['letterName'];
  body: PutLetterRequest['body'];
  issueDate: PutLetterRequest['issueDate'];
  clientRecordId: PutLetterRequest['clientRecord']['_id'];
  clinician: PutLetterRequest['clinician'];
  practice?: PutLetterRequest['practice'];
  contactDetails: PutLetterRequest['contactDetails'];
  clinicianSignature: PutLetterRequest['clinicianSignature'];
  clinicianSignatureExtraDetails: PutLetterRequest['clinicianSignatureExtraDetails'];
  clientRecordData?: clientRecordsInterface;
  isLoadingTemplate: boolean;
  isLoadingClientRecordData: boolean;
  isSignatureLoading: boolean;
  isBodyInvalid: boolean;
  isSignatureInvalid: boolean;
  updateData: (data: Partial<PutLetterRequest>, validate?: boolean) => void;
};

const LetterContent = ({
  letterName,
  body,
  issueDate,
  clientRecordId,
  clinician,
  practice,
  contactDetails,
  clinicianSignature,
  clinicianSignatureExtraDetails,
  clientRecordData,
  isLoadingTemplate,
  isLoadingClientRecordData,
  isSignatureLoading,
  isBodyInvalid,
  isSignatureInvalid,
  updateData
}: LetterContentProps) => {
  const handleChangeSignature = (
    signatureVal: PutLetterRequest['clinicianSignature'],
    extraDetailsVal: PutLetterRequest['clinicianSignatureExtraDetails']
  ) => {
    updateData({
      clinicianSignature: signatureVal,
      clinicianSignatureExtraDetails: extraDetailsVal
    });
  };

  return (
    <div className={styles.container}>
      <div>
        <ReportContentHeader
          type="Letter"
          clinicianDetails={{
            clinician,
            practice
          }}
          clinicianContactData={contactDetails}
          reportName={letterName}
          issueDate={issueDate}
          clientRecord={clientRecordData}
          onChangeContactDetails={(contactDetails) => updateData({ contactDetails: contactDetails })}
          clientDetailsLoading={isLoadingClientRecordData}
        />
      </div>
      <div className={styles.content}>
        {isLoadingTemplate ? (
          <Skeleton active />
        ) : (
          <>
            <WidgetTextEditor2
              minHeight={350}
              height={'auto'}
              className="letter-text-editor"
              value={body}
              onChange={(value) => value !== body && updateData({ body: value }, isBodyInvalid)}
              recordId={clientRecordId}
              profileId={clientRecordData?.clientProfiles[0]._id || ''}
              readOnly={false}
              withCaseNoteHeaderButton
            />
            {isBodyInvalid && <div className={styles.error}>Letter cannot be blank.</div>}
          </>
        )}
      </div>
      <div>
        {isSignatureLoading ? (
          <Skeleton active />
        ) : (
          <SignatureSection
            clinician={clinician}
            signVal={clinicianSignature}
            extraDetailsVal={clinicianSignatureExtraDetails}
            showError={isSignatureInvalid}
            onChangeSignature={handleChangeSignature}
          />
        )}
      </div>
    </div>
  );
};

export default LetterContent;
