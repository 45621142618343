import { HelmControlInterface } from 'interfaces/Profile/Profile';
import styles from './ClientCoverFilter.module.scss';
import Select from 'components/Select/CommonSelect/Select';
import { AgeRangeList, GenderList, MentalHealthList } from '../../interfaces/mentalHealth';
import {
  genderOptions,
  selectAgeRangeOptions,
  someoneHealthAgeRangeOptions,
  standardAgeRangeOptions
} from '../../utils/generateOptions';
import { FC } from 'react';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface ClientCoverFilterProps {
  profileValue: HelmControlInterface;
  setValues: (value: HelmControlInterface) => void;
}

const ClientCoverFilter: FC<ClientCoverFilterProps> = ({ profileValue, setValues }) => {
  const { isSelectPsychologyFeatureToggle, isSomeoneHealthFeatureToggle } = useGetFeatureToggle();

  const ageRangeOptions = isSomeoneHealthFeatureToggle
    ? someoneHealthAgeRangeOptions
    : isSelectPsychologyFeatureToggle
    ? selectAgeRangeOptions
    : standardAgeRangeOptions;

  const selectStyles = {
    container: (base: any) => ({ ...base, width: '100%' }),
    valueContainer: (base: any) => ({ ...base, paddingLeft: 4 }),
    control: (controlBase: any) => ({
      ...controlBase,
      minHeight: '30px',
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: `1px solid ${styles.greyColor}`,
      borderRadius: 0,
      boxShadow: 'none'
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: styles.blueColor,
      borderRadius: 20,
      padding: 2,
      color: 'white',
      fontSize: '12px'
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'transparent',
        color: styles.removeColor
      }
    })
  };

  const setExpertiseList = (
    key: 'clientAgeRange' | 'clientGender',
    value?: MentalHealthList[] & AgeRangeList[] & GenderList[]
  ) => {
    setValues({
      ...profileValue,
      [key]: value
    });
  };

  return (
    <div className={styles.section}>
      {isSomeoneHealthFeatureToggle && (
        <>
          <div className={styles.title}>Client cover area</div>
          <div className={styles.description}>This will help how client find you in listing view</div>
        </>
      )}
      <div className={styles.checkBoxRow}>
        <div className={styles.checkboxDetail}>
          <div className={styles.col2}>
            <div className={styles.label}>Age Range</div>
            <Select
              styles={selectStyles}
              options={ageRangeOptions?.filter(
                (item) => !profileValue.clientAgeRange?.includes(item.value as AgeRangeList)
              )}
              placeholder="Search here"
              closeMenuOnSelect={false}
              noCreateNewOption
              value={
                ageRangeOptions?.filter((item) =>
                  profileValue.clientAgeRange?.includes(item.value as AgeRangeList)
                ) as any
              }
              isMulti
              onChange={(value: any) =>
                setExpertiseList(
                  'clientAgeRange',
                  (value || []).map((obj: any) => obj.value)
                )
              }
            />
          </div>
        </div>
      </div>
      {isSomeoneHealthFeatureToggle && (
        <div className={styles.checkBoxRow}>
          <div className={styles.checkboxDetail}>
            <div className={styles.col2}>
              <div className={styles.label}>Client Gender</div>
              <Select
                styles={selectStyles}
                options={genderOptions?.filter(
                  (value) => !profileValue.clientGender?.includes(value.value as GenderList)
                )}
                placeholder="Search here"
                closeMenuOnSelect={false}
                noCreateNewOption
                value={
                  genderOptions?.filter((value) =>
                    profileValue.clientGender?.includes(value.value as GenderList)
                  ) as any
                }
                isMulti
                onChange={(value: any) =>
                  setExpertiseList(
                    'clientGender',
                    (value || []).map((obj: any) => obj.value)
                  )
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientCoverFilter;
