import moment from 'moment/moment';
import { MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import {
  useGetCurrentActiveEpisodeQuery,
  useGetEpisodeTimeListQuery
} from 'redux/endpoints/clinicianProfileServices/episode';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { DatePickerValidDate } from 'pages/Calendar/components/CalendarWithHighlightsT23/components/CalendarView/components/InlineBookingModal/components/AppointmentDatePicker/AppointmentDatePicker';

export const useGenerateAvailableDate = (
  clientRecord: clientRecordsInterface,
  selectedStartDate?: string,
  currentEpisodeEndDate?: string
) => {
  const { accountTimeZone } = useTimeZone();
  const { data: episodeTimeListData } = useGetEpisodeTimeListQuery({
    clientRecordId: clientRecord._id,
    timezone: accountTimeZone
  });

  const { data: currentActiveData } = useGetCurrentActiveEpisodeQuery({
    clientRecordId: clientRecord._id,
    timezone: accountTimeZone
  });

  const minStartDate = currentEpisodeEndDate
    ? moment(currentEpisodeEndDate).add(1, 'day').format(MOMENTJS_YEAR_MONTH_DAY_FORMAT)
    : moment(clientRecord.createdAt).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT);
  const maxDate = moment().add(1, 'year').endOf('month').format(MOMENTJS_YEAR_MONTH_DAY_FORMAT);

  const generateStartDateRange = () => {
    let dateList: DatePickerValidDate[] = [];
    episodeTimeListData?.map((timeListObj) => {
      if (timeListObj.startDate === timeListObj.endDate) {
        dateList.push(timeListObj.startDate);
      } else {
        dateList.push({
          start: timeListObj.startDate,
          end: timeListObj.endDate
        });
      }
      return dateList;
    });
    return dateList;
  };

  const newActiveAndOnHoldDateRange: DatePickerValidDate[] = [
    {
      start: minStartDate,
      end: currentActiveData?.startDate
        ? moment(currentActiveData?.startDate).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT)
        : episodeTimeListData?.slice(-1).pop()?.endDate || ''
    }
  ];

  const editActiveAndOnHoldDateRange: DatePickerValidDate[] = [
    {
      start: minStartDate,
      end: episodeTimeListData?.slice(-1).pop()?.endDate || ''
    }
  ];

  const generateEndDateRange = () => {
    const massageTakenEPDate = generateStartDateRange().map((epObj) => ({
      start: typeof epObj !== 'string' ? epObj.start : epObj,
      end: typeof epObj !== 'string' ? epObj.end : epObj
    }));

    const getAllDate: string[] = [];
    let currentDate = moment(minStartDate);
    const endDate = moment(maxDate);

    while (currentDate <= endDate) {
      const currentDateFormat = currentDate.format(MOMENTJS_YEAR_MONTH_DAY_FORMAT);
      getAllDate.push(currentDateFormat);
      currentDate = moment(currentDate).add(1, 'days');
    }

    let resultsRange = [];
    for (let i = 0; i < massageTakenEPDate.length; i++) {
      const noTakenDateRange =
        i === 0
          ? getAllDate.slice(
              0,
              getAllDate.findIndex((date) => date === massageTakenEPDate[i].start)
            )
          : getAllDate.slice(
              getAllDate.findIndex(
                (date) =>
                  date ===
                  moment(massageTakenEPDate[i - 1].end)
                    .add(1, 'days')
                    .format(MOMENTJS_YEAR_MONTH_DAY_FORMAT)
              ),
              getAllDate.findIndex((date) => massageTakenEPDate[i].start === date)
            );
      if (noTakenDateRange.length) {
        resultsRange.push(noTakenDateRange);
      }
      if (i === massageTakenEPDate.length - 1) {
        const finalRange = getAllDate.slice(
          getAllDate.findIndex(
            (date) => date === moment(massageTakenEPDate[i].end).add(1, 'days').format(MOMENTJS_YEAR_MONTH_DAY_FORMAT)
          ),
          getAllDate.length
        );
        if (finalRange.length) {
          resultsRange.push(finalRange);
        }
      }
    }

    const startDate = selectedStartDate ? moment(selectedStartDate).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT) : '';
    const targetRange = resultsRange.find((rangeObj) => rangeObj.includes(startDate)) || [];
    const suggestedEndDate = targetRange[targetRange.length - 1] || moment().format(MOMENTJS_YEAR_MONTH_DAY_FORMAT);

    return [
      {
        start: startDate,
        end: suggestedEndDate
      }
    ];
  };

  return {
    minStartDate,
    maxDate,
    generateStartDateRange,
    generateEndDateRange,
    newActiveAndOnHoldDateRange,
    editActiveAndOnHoldDateRange
  };
};
