import { SimpleClientData } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsClientData/ClientData.interface';
import { checkInServiceApiSlice, CISTagTypes } from 'redux/services/checkInServiceApiSlice';

export const clientDataApiSlice = checkInServiceApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMinifiedClientData: builder.query<{ data: SimpleClientData[] }, { clientRecordId: string }>({
      query: ({ clientRecordId }) => ({
        url: `/client-records/${clientRecordId}/client-data/minified`
      }),
      providesTags: (_result, _error, arg) => [{ type: CISTagTypes.MinifiedClientData, id: arg.clientRecordId }]
    })
  })
});

export const { useGetMinifiedClientDataQuery } = clientDataApiSlice;
