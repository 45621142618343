import classNames from 'classnames';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccessToken } from 'utils/hooks/token';
import { getAppointmentAlerts, getAppointmentRequests } from 'utils/http/appointment';
import { getReportsNotifications } from 'utils/http/DocumentService/Reports/reports';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { AppointmentAlertInterface } from './components/NotificationsModal/components/AppointmentAlert/interfaces';
import { ReportNotification } from './components/NotificationsModal/components/ReportNotification/interfaces';
import NotificationsModal from './components/NotificationsModal/NotificationsModal';
import styles from './Notification.module.scss';

export interface AppointmentBookingRequest {
  date: string;
  appointmentBookingRequests: Required<
    AppointmentSlots & {
      isExpired: boolean;
      isClashedWithOtherAppointment: boolean;
      clinicianProfile?: { avatar?: string; name?: string };
    }
  >[];
}

export interface AppointmentRequest {
  _id: string;
  clinicianId: string;
  clientRecord: {
    _id: string;
    clientProfiles: {
      _id: string;
      clientAuth0Id?: string;
      avatar?: string;
      initials?: string;
      initialsBackgroundColor?: string;
      name: string;
    }[];
  };
  message: string;
  createdAt: Date;
}

const useFetchNotifications = (
  token: string,
  isNotificationsModalVisible: boolean,
  refetchToken: () => Promise<void>
) => {
  const [appointmentBookingRequests, setAppointmentBookingRequests] = useState<AppointmentBookingRequest[]>([]);
  const [appointmentRequests, setAppointmentRequests] = useState<AppointmentRequest[]>([]);
  const [appointmentAlerts, setAppointmentAlerts] = useState<AppointmentAlertInterface[]>([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [reportsNotifications, setReportsNotifications] = useState<ReportNotification[]>([]);
  const [isNotificationsLoading, setisNotificationsLoading] = useState(true);

  const { isEdgeAdminView, isEdgeUserView, isEdgeReceptionistView } = useGetAccountPackageView();
  const shouldFetchAlerts = isEdgeAdminView || isEdgeUserView || isEdgeReceptionistView;
  const { pathname } = useLocation();
  const firstPath = pathname.split('/')[1];
  const [isViewChanged, setIsViewChanged] = useState(false);

  const REFRESH_INTERVAL = 300000;

  const [refreshIntervalId, setRefreshIntervalId] = useState<NodeJS.Timeout>();

  const refetchNotifications = async () => {
    setisNotificationsLoading(true);
    try {
      const [callGetAppointmentRequests, callGetAppointmentAlerts, callGetReportsNotifications] = await Promise.all([
        getAppointmentRequests(token, isEdgeAdminView || isEdgeReceptionistView),
        shouldFetchAlerts ? getAppointmentAlerts(token, isEdgeAdminView) : null,
        shouldFetchAlerts ? getReportsNotifications(token) : null
      ]);

      const [
        { appointmentBookingRequests, appointmentRequests, appointmentRequestsCount },
        appointmentAlerts,
        reportsNotifications
      ] = await Promise.all([
        callGetAppointmentRequests.json(),
        (callGetAppointmentAlerts && callGetAppointmentAlerts.json()) ?? [],
        (callGetReportsNotifications && callGetReportsNotifications.json()) ?? []
      ]);

      setAppointmentBookingRequests(appointmentBookingRequests);
      setAppointmentRequests(appointmentRequests);
      setAppointmentAlerts(appointmentAlerts);
      setNotificationsCount(appointmentRequestsCount + appointmentAlerts.length + reportsNotifications.length);
      setReportsNotifications(reportsNotifications);
      setisNotificationsLoading(false);
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 401) {
        await refetchToken();
      } else {
        console.error('Something went wrong while trying to fetch your appointment requests', ex);
        setisNotificationsLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsViewChanged(true);
  }, [firstPath]);

  useEffect(() => {
    if (token) {
      if (!isNotificationsModalVisible) {
        if (!refreshIntervalId) {
          setRefreshIntervalId(setInterval(refetchNotifications, REFRESH_INTERVAL));
        } else {
          if (isViewChanged) {
            clearInterval(refreshIntervalId);
            setRefreshIntervalId(setInterval(refetchNotifications, REFRESH_INTERVAL));
          } else {
            clearInterval(refreshIntervalId);
            setRefreshIntervalId(setInterval(refetchNotifications, REFRESH_INTERVAL));
          }
        }
      } else if (refreshIntervalId) {
        clearInterval(refreshIntervalId);
      }
    }
    setIsViewChanged(false);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isNotificationsModalVisible, isViewChanged]);

  return {
    appointmentBookingRequests,
    appointmentRequests,
    appointmentAlerts,
    reportsNotifications,
    notificationsCount,
    isNotificationsLoading,
    refetchNotifications
  };
};

const Notification = ({ containerClass, className }: { containerClass?: string; className?: string }) => {
  const { token, refetchToken } = useGetAccessToken();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [isNotificationsModalVisible, setIsNotificationsModalVisible] = useState(false);

  const {
    appointmentBookingRequests,
    appointmentRequests,
    appointmentAlerts,
    reportsNotifications,
    notificationsCount,
    isNotificationsLoading,
    refetchNotifications
  } = useFetchNotifications(token, isNotificationsModalVisible, refetchToken);

  const handleNotificationsModalOpen = () => {
    setIsNotificationsModalVisible(true);
  };

  const handleNotificationsModalClose = () => {
    setIsNotificationsModalVisible(false);
  };

  return (
    <>
      <NotificationsModal
        appointmentBookingRequests={appointmentBookingRequests}
        appointmentRequests={appointmentRequests}
        appointmentAlerts={appointmentAlerts}
        reportsNotifications={reportsNotifications}
        token={token}
        visible={isNotificationsModalVisible}
        isNotificationsLoading={isNotificationsLoading}
        onCancel={handleNotificationsModalClose}
        onRefreshNotifications={refetchNotifications}
      />
      <div className={styles.container} onClick={handleNotificationsModalOpen}>
        <div className={classNames(isEdgeAdminView ? styles.notificationBtnGold : styles.notificationBtn, className)}>
          <i className={`material-icons-outlined ${styles.icon}`}>
            {notificationsCount === 0 ? 'notifications' : 'notifications_active'}
          </i>
          {notificationsCount > 0 && (
            <div className={styles.bubbleWrapper}>
              <div className={styles.bubble}>{notificationsCount}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notification;
