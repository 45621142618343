import { ShareDetails } from 'interfaces/Reports/report';
import moment from 'moment';

export const EDGE_USER_REPORT_FILTERS = [
  { label: 'All Status', value: 'allStatus' },
  { label: 'Published', value: 'published' },
  { label: 'Unpublished', value: 'unpublished' },
  { label: 'Approved', value: 'approved' },
  { label: 'In Review', value: 'inReview' },
  { label: 'Draft', value: 'draft' }
];

export const REPORT_FILTERS = [
  { label: 'All Status', value: 'allStatus' },
  { label: 'Published', value: 'published' },
  { label: 'Unpublished', value: 'unpublished' },
  { label: 'Draft', value: 'draft' }
];

export enum ShareMethod {
  EMAIL = 'email',
  eFAX = 'eFax'
}

interface IShareDetailDisplay {
  shareMethod: ShareMethod;
  label: string;
  sentAt: string;
  className: string;
  icon: string;
}

export const getSharedDetails = (data: ShareDetails, primaryName: string) => {
  const combineSharedData: IShareDetailDisplay[] = [];
  const { accessors, faxes } = data;
  if (accessors && accessors.length) {
    accessors.forEach((item) => {
      combineSharedData.push({
        shareMethod: ShareMethod.EMAIL,
        label: item.isClient ? primaryName : item.email || '',
        sentAt: item.sentAt || item.sendAt || '',
        className: 'emailIcon',
        icon: 'email'
      });
    });
  }

  if (faxes && faxes.length) {
    faxes.forEach((item) => {
      combineSharedData.push({
        shareMethod: ShareMethod.eFAX,
        label: item.recipientName || item.sendTo,
        sentAt: item.sentAt || item.sendAt || '',
        className: 'eFaxIcon',
        icon: 'fax'
      });
    });
  }
  return combineSharedData.sort((a, b) => moment(b.sentAt).diff(moment(a.sentAt)));
};
