import classnames from 'classnames';
import styles from './SubmittedClaimHistory.module.scss';
import { getStatusInfo } from '../SubmittedClaimItem/SubmittedClaimItem';
import moment from 'moment';
import { DATE_FORMAT } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsClientData/constants';
import { ClaimTypeNameMapping } from 'interfaces/invoices/submittedClaim';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ClaimHistoryItem } from '../SubmittedClaimItem/hooks/useFetchClaimHistory';
import { Skeleton } from 'antd';
import { Claim } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';

interface SubmittedClaimHistoryProps {
  claimHistory?: ClaimHistoryItem[];
  isExpanded: boolean;
  isLoading: boolean;
  onSelectClaim: (selectedClaim: Claim) => () => void;
}

const SubmittedClaimHistory = ({ claimHistory, isExpanded, isLoading, onSelectClaim }: SubmittedClaimHistoryProps) => {
  return (
    <div className={classnames(styles.container, isExpanded && styles.expanded)}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.submissionTime}>SUBMISSION TIME</div>
          <div className={styles.submissionDate}>SUBMISSION DATE</div>
          <div className={styles.status}>STATUS</div>
          <div className={styles.claimId}>CLAIM ID</div>
          <div className={styles.reference}>REFERENCE</div>
          <div className={styles.claimType}>CLAIM TYPE</div>
          <div className={styles.claimCode}>CLAIM CODE</div>
          <div className={styles.submittedBy}>SUBMITTED BY</div>
          <div className={styles.button} />
        </div>
        {isLoading ? (
          <div className={styles.loadingItem}>
            <Skeleton.Input active className={styles.loadingContent} />
          </div>
        ) : !claimHistory || claimHistory.length === 0 ? (
          <div className={styles.noHistory}>No other related claims found</div>
        ) : (
          claimHistory.map((claim) => {
            const { type, mbsCode, status, claimId, createdAt, createdByClinician, medicareClaimId } = claim;
            const { statusIcon, statusText, statusClassName } = getStatusInfo(status);
            const createdAtMoment = moment(createdAt);

            return (
              <div className={styles.historyItem}>
                <div className={styles.submissionTime}>{createdAtMoment.format('HH:mm:ss')}</div>
                <div className={styles.submissionDate}>{createdAtMoment.format(DATE_FORMAT)}</div>
                <div className={styles.status}>
                  <div className={classnames(styles.statusWrapper, statusClassName)}>
                    <i className={'material-icons'}>{statusIcon}</i>
                    {statusText}
                  </div>
                </div>
                <div className={styles.claimId}>{claimId}</div>
                <div className={styles.reference}>{medicareClaimId || '-'}</div>
                <div className={styles.claimType}>{ClaimTypeNameMapping[type]}</div>
                <div className={styles.claimCode}>{mbsCode}</div>
                <div className={styles.submittedBy}>{createdByClinician?.name || '-'}</div>
                <div className={styles.button}>
                  <ButtonAlt
                    variant={'text'}
                    icon={'open_in_browser'}
                    size={'small'}
                    fab
                    onClick={onSelectClaim(claim)}
                  />
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SubmittedClaimHistory;
