import styles from './ClientListContent.module.scss';
import ClientList from './components/ClientList/ClientList';

const ClientRecordList = () => (
  <div className={styles.container}>
    <div id={'clientListContentsId'}>
      <ClientList />
    </div>
  </div>
);

export default ClientRecordList;
