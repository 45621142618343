import './ant.custom.scss';

import { Popover } from 'antd';
import { ButtonStatusType } from 'components/v2/Button/Button';
import { Formik } from 'formik';
import { PracticeInterface } from 'interfaces/Profile/Profile';
import { AppointmentSlots, AppointmentStatusType } from 'interfaces/Schedule/Appointment';
import { AppointmentType, DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { useMemo, useState } from 'react';

import CancelPopover from '../CancelPopover/CancelPopover';
import MarkStatus from '../MarkStatus/MarkStatus';
import DeliveryMode from './components/DeliveryMode/DeliveryMode';
import Room from './components/Room/Room';
import { formSchema } from './constants';
import styles from './EventDetails.module.scss';
import GroupCard from './components/GroupCard/GroupCard';
import OneToOneCard from './components/OneToOneCard/OneToOneCard';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import AppointmentActionsDropdown from './components/AppointmentActionsDropdown/AppointmentActionsDropdown';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useFetchProfileById } from 'utils/hooks/GetProfileDetails/getProfileInfo';

import { InvoiceIds } from './components/InvoiceIds/InvoiceIds';
import { useFetchAppointmentClientDetails } from './components/OneToOneCard/hooks/GetAppointmentClientDetails';
import EventDetailLoading from './components/EventDetailLoading/EventDetailLoading';
import FreeBusyEvent from '../FreeBusyEvent/FreeBusyEvent';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import Rate from './components/Rate/Rate';
import { useGetPackageBookableAppointmentQuery } from 'redux/endpoints/scheduleServices/package';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

interface EventDetailsProps {
  groupSessionLabel?: string;
  appointment: AppointmentSlots;
  appointmentType?: AppointmentType;
  practiceData?: PracticeInterface;
  token: string;
  isLoading: boolean;
  hideEditGroupBtn?: boolean;
  onEditComplete: () => void;
  handleUpdateAppointment: (payload: any, setSubmitStatus: Function) => Promise<void>;
  onCancelAppointmentComplete: () => void;
  handleReschedule: () => void;
  handleShowProcessAppointment: () => void;
}

const EventDetails = ({
  groupSessionLabel,
  appointment,
  appointmentType,
  practiceData,
  token,
  isLoading,
  hideEditGroupBtn,
  onEditComplete,
  handleUpdateAppointment,
  onCancelAppointmentComplete,
  handleReschedule,
  handleShowProcessAppointment
}: EventDetailsProps) => {
  const { accountId } = useGetAccountId();
  const [isEditingRoom, setIsEditingRoom] = useState(false);
  const [isEditingDeliveryMode, setIsEditingDeliveryMode] = useState(false);
  const [isEditingRate, setIsEditingRate] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');
  const isReservedAppointment = appointment.type === AppointmentStatusType.Reserved;

  const { isProcessAppointmentEnabled } = useGetFeatureToggle();
  const { isAppointmentRateReadDenied } = useGetPermissionToggle();

  const recurrIdx = appointment.recurrings?.findIndex((recurr) => appointment.date === recurr.date);

  const formInitialValues = {
    roomId: appointment.room?.roomId,
    roomName: appointment.room?.roomName,
    deliveryType: appointment.deliveryType,
    faceToFaceLocation: appointment.faceToFaceLocation,
    videoCallInstructions: appointment.videoCallInstructions,
    phoneCallInstructions: appointment.phoneCallInstructions,
    phoneCallDialClientInstructions: appointment.phoneCallDialClientInstructions,
    otherInstructions: appointment.otherInstructions,
    rate: typeof appointment.rate === 'number' && Number(appointment.rate).toFixed(2)
  };

  const { clientEncryptDetails, clientEncryptDetailsLoading } = useFetchAppointmentClientDetails(
    token,
    appointment.clientRecord?._id || ''
  );
  const { profile, isProfileLoading } = useFetchProfileById(appointment.clinicianId!);

  const { data: assignmentPackageList } = useGetPackageBookableAppointmentQuery(
    {
      accountId: accountId,
      clientRecordId: appointment.clientRecord?._id || ''
    },
    { skip: !appointment.clientRecord?._id }
  );

  const getPackageDetails = useMemo(
    () => assignmentPackageList?.find((aPackageObj) => aPackageObj.assignee._id === appointment.packageAssigneeId),
    [assignmentPackageList, appointment.packageAssigneeId]
  );

  const handleUpdate = async (values: typeof formInitialValues) => {
    await handleUpdateAppointment(
      {
        room: values.roomId ? { roomId: values.roomId, roomName: values.roomName } : undefined,
        deliveryType: values.deliveryType,
        faceToFaceLocation: values.faceToFaceLocation,
        videoCallInstructions: values.videoCallInstructions,
        phoneCallInstructions: values.phoneCallInstructions,
        phoneCallDialClientInstructions: values.phoneCallDialClientInstructions,
        otherInstructions: values.otherInstructions,
        rate: values.rate
      },
      setSubmitStatus
    );
    setIsEditingRoom(false);
    setIsEditingDeliveryMode(false);
    setIsEditingRate(false);
  };

  return (
    <div className={styles.container}>
      {isLoading || clientEncryptDetailsLoading || (appointment.clinicianId && isProfileLoading) ? (
        <EventDetailLoading />
      ) : (
        <Formik initialValues={formInitialValues} validationSchema={formSchema} onSubmit={handleUpdate}>
          {({ values, submitForm }) => (
            <>
              {appointment.groupId ? (
                <GroupCard
                  groupId={appointment.groupId}
                  label={groupSessionLabel || appointment.groupDetails.label}
                  sessionId={appointment._id}
                  hideEditGroupBtn={hideEditGroupBtn}
                />
              ) : (
                <OneToOneCard
                  clinicianId={appointment.clinicianId}
                  clientRecordId={appointment.clientRecord?._id || ''}
                  practice={practiceData}
                  profile={profile}
                  clientEncryptDetails={clientEncryptDetails}
                />
              )}
              {getPackageDetails && (
                <div className={styles.eventInformationContainer}>
                  <div className={styles.iconContainer}>
                    <span className="material-symbols-outlined">contract</span>
                  </div>
                  <div className={styles.description}>
                    <div className={styles.descriptionText}>{getPackageDetails.name}</div>
                  </div>
                  <div />
                  {getPackageDetails?.description && (
                    <div className={styles.subInfoContainer}>{getPackageDetails.description}</div>
                  )}
                </div>
              )}
              {appointment.deliveryType !== DeliveryType.Bespoke && (
                <div className={styles.eventInformationContainer}>
                  <div className={styles.iconContainer}>
                    <span className="material-icons-outlined">event</span>
                  </div>
                  <div className={styles.description}>
                    <div className={styles.descriptionText}>{appointmentType?.name || appointment.sessionTypeName}</div>
                  </div>
                  <div />
                  {appointmentType?.description && (
                    <div className={styles.subInfoContainer}>{appointmentType.description}</div>
                  )}
                </div>
              )}
              <Room appointment={appointment} isEditing={isEditingRoom} setIsEditing={setIsEditingRoom} />
              {appointmentType?.deliveryOptions && (
                <DeliveryMode
                  appointmentType={appointmentType}
                  isEditing={isEditingDeliveryMode}
                  setIsEditing={setIsEditingDeliveryMode}
                  isReservedAppointment={isReservedAppointment}
                  appointmentId={appointment._id}
                  telehealthRoomId={appointment.telehealthRoomId}
                  isAppointmentProcessed={appointment.isProcessed || !!appointment.requestedChanges}
                />
              )}
              {!isAppointmentRateReadDenied && appointment.isActivity && (
                <Rate appointmentType={appointmentType} isEditing={isEditingRate} setIsEditing={setIsEditingRate} />
              )}
              {!!appointment?.invoices?.length && <InvoiceIds invoices={appointment.invoices} />}
              {appointment.recurrings && (
                <div className={styles.eventInformationContainer}>
                  <div className={styles.iconContainer}>
                    <span className="material-icons-outlined">repeat</span>
                  </div>
                  <div className={styles.description}>
                    <div className={styles.descriptionText}>
                      {recurrIdx + 1} of {appointment.recurrings.length}
                    </div>
                  </div>
                </div>
              )}
              {appointment.isActivity && (
                <FreeBusyEvent
                  appointment={appointment}
                  className={styles.freeBusyContainer}
                  onToggleFreeBusy={onEditComplete}
                />
              )}
              {!isReservedAppointment && (
                <div className={styles.markAppointmentSelectContainer}>
                  <MarkStatus appointment={appointment} token={token} onFinishMarkStatus={onEditComplete} />
                </div>
              )}
              <div className={styles.footer}>
                {(appointment.room?.roomId !== values.roomId && isEditingRoom) ||
                isEditingDeliveryMode ||
                isEditingRate ? (
                  <ButtonAlt className={styles.button} onClick={submitForm} status={submitStatus}>
                    Update Appointment
                  </ButtonAlt>
                ) : (
                  <Popover
                    title="Are you sure to cancel this appointment?"
                    content={() => (
                      <CancelPopover
                        appointment={appointment}
                        onFinishMarkStatus={onCancelAppointmentComplete}
                        token={token}
                      />
                    )}
                    trigger="click"
                  >
                    {!isReservedAppointment && !appointment.isProcessed && !appointment.requestedChanges ? (
                      isProcessAppointmentEnabled ? (
                        <AppointmentActionsDropdown
                          isFromCalendarView
                          handleReschedule={handleReschedule}
                          appointment={appointment}
                          onFinishMarkStatus={onCancelAppointmentComplete}
                          token={token}
                          handleShowProcessAppointment={handleShowProcessAppointment}
                        />
                      ) : (
                        <ButtonAlt variant={'outlined'}>Cancel this appointment</ButtonAlt>
                      )
                    ) : null}
                  </Popover>
                )}
              </div>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EventDetails;
