import { useGetClientAppointmentsByDateRangeQuery } from 'redux/endpoints/scheduleServices/appointment';
import { cleanupTemplate } from './makeTemplateContentRenderer';
import moment from 'moment';
import { MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useGetClinicalAssessmentDetailsResponseQuery } from 'redux/endpoints/checkInServices/clinicalAssessment';
import { useGetPatientActivitiesQuery } from 'redux/endpoints/activityFeedServices/patientActivities';
import { useGetGeneralPractitionerByIdQuery } from 'redux/endpoints/clinicianProfileServices/generalPractitioner';
import { generateAssessment } from 'utils/hooks/headingTemplate';
import { AppointmentStatusOption } from 'interfaces/Schedule/Appointment';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useCallback, useEffect, useState } from 'react';
import Handlebars from 'handlebars';
import { useGetMinifiedClientDataQuery } from 'redux/endpoints/checkInServices/minifiedClientData';
import { clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import { useGetClinicianProfileByIdQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';

export const useCreateTemplateContent = ({
  clientEncryptDetails,
  shouldNotRun
}: {
  clientEncryptDetails?: clientRecordsEncryptedInterface;
  shouldNotRun?: boolean;
}) => {
  const { accountId } = useGetAccountId();
  const { timeZoneByView } = useTimeZone();

  const [renderer, setRenderer] = useState(() => {
    return (_template: string) => {};
  });

  const clientRecordId = clientEncryptDetails?._id;
  const profileId = clientEncryptDetails?.clientProfiles[0]._id;
  const practitionerId =
    clientEncryptDetails && clientEncryptDetails.clinicianAuth0Ids.length > 0
      ? clientEncryptDetails.clinicianAuth0Ids[0]
      : '';

  const {
    data: appointments,
    isFetching: isAppointmentsFetching,
    isLoading: isAppointmentsLoading
  } = useGetClientAppointmentsByDateRangeQuery(
    {
      clientRecordId,
      from: '2020-01-01',
      to: moment(new Date()).add(1, 'years').format(MOMENTJS_YEAR_MONTH_DAY_FORMAT),
      timeZone: timeZoneByView
    },
    { skip: !clientRecordId || shouldNotRun }
  );

  const {
    data: clinicianProfile,
    isLoading: isGetClinicianProfileLoading,
    isFetching: isGetClinicianProfileFetching
  } = useGetClinicianProfileByIdQuery({ clinicianId: practitionerId }, { skip: !practitionerId });

  const {
    data: assessmentDetailsList,
    isLoading: isAssessmentDetailsListLoading,
    isFetching: isAssessmentDetailsListFetching
  } = useGetClinicalAssessmentDetailsResponseQuery(
    {
      clientRecordId: clientRecordId || ''
    },
    { skip: !clientRecordId || shouldNotRun }
  );

  const {
    data: patientActivities,
    isLoading: isPatientActivitiesLoading,
    isFetching: isPatientActivitiesFetching
  } = useGetPatientActivitiesQuery(
    {
      clientRecordId: clientRecordId || '',
      pageSize: 0,
      filter: 'appointment',
      action: 'cancelled'
    },
    { skip: !clientRecordId || shouldNotRun }
  );

  const {
    data: clientMinifiedData,
    isLoading: isClientMinifiedDataLoading,
    isFetching: isClientMinifiedDataFetching
  } = useGetMinifiedClientDataQuery(
    {
      clientRecordId: clientRecordId || ''
    },
    { skip: !clientRecordId || shouldNotRun }
  );

  const {
    data: generalPractitioner,
    isLoading: isGeneralPractitionerLoading,
    isFetching: isGeneralPractitionerFetching
  } = useGetGeneralPractitionerByIdQuery(
    { generalPractitionerId: clientEncryptDetails?.referral?.generalPractitionerId || '', accountId },
    { skip: !clientEncryptDetails?.referral?.generalPractitionerId || !accountId || shouldNotRun }
  );

  const isLoading =
    isAppointmentsLoading ||
    isAppointmentsFetching ||
    isGetClinicianProfileLoading ||
    isGetClinicianProfileFetching ||
    isAssessmentDetailsListLoading ||
    isAssessmentDetailsListFetching ||
    isPatientActivitiesLoading ||
    isPatientActivitiesFetching ||
    isClientMinifiedDataLoading ||
    isClientMinifiedDataFetching ||
    isGeneralPractitionerLoading ||
    isGeneralPractitionerFetching;

  const createTemplate = useCallback(async () => {
    const firstAssessment = await generateAssessment('firstAssessment');
    const mostRecentAssessment = await generateAssessment('mostRecentAssessment');

    if (!isLoading && appointments) {
      const firstAppointDate =
        appointments.length > 0
          ? moment(`${appointments[0].date} ${appointments[0].startTime}`).format('dddd DD MMMM YYYY \\at hh:mmA')
          : '';
      const lastAppointDate =
        appointments.length > 0
          ? moment(
              `${appointments[appointments.length - 1].date} ${appointments[appointments.length - 1].startTime}`
            ).format('dddd DD MMMM YYYY \\at hh:mmA')
          : '';
      const firstAssessmentDate =
        assessmentDetailsList && assessmentDetailsList.length > 0
          ? moment(assessmentDetailsList[0].createdAt).format('dddd DD MMMM YYYY \\at hh:mmA')
          : '';
      const lastAssessmentDate =
        assessmentDetailsList && assessmentDetailsList.length > 0
          ? moment(assessmentDetailsList[assessmentDetailsList.length - 1].createdAt).format(
              'dddd DD MMMM YYYY \\at hh:mmA'
            )
          : '';

      const clientProfileData = clientEncryptDetails?.clientProfiles.filter((cpObj) => cpObj._id === profileId)[0];

      const sessionsNotAttend = appointments.filter(
        (appointmentObj) =>
          appointmentObj.markedStatus?.includes(AppointmentStatusOption.DidNotAttend) ||
          appointmentObj.markedStatus?.includes(AppointmentStatusOption.ClientDidNotAttend) ||
          appointmentObj.markedStatus?.includes(AppointmentStatusOption.ClinicianDidNotAttend)
      ).length;

      const referrerName = clientEncryptDetails?.keyClientContacts?.filter((keyContactObj) =>
        keyContactObj.tags?.some((tagObj) => tagObj.toLowerCase() === 'referrer')
      )[0];

      const authorizedSessionNumber = clientEncryptDetails?.appointmentStatusOverviewConfiguration.limit;

      const matchedClientData = clientMinifiedData?.data.find((item) => item.clientProfileId === profileId);
      const cdfList: { [key: string]: string } = matchedClientData
        ? matchedClientData?.variables.reduce((obj, { variableId, value }) => {
            return {
              ...obj,
              [variableId]: value
            };
          }, {})
        : {};

      const variableList: { [key: string]: string | number | undefined } = {
        CLIENT_NAME: clientProfileData ? `${clientProfileData.firstName} ${clientProfileData.lastName}` : '',
        CLIENT_DOB: clientProfileData?.dateOfBirth
          ? moment(clientProfileData.dateOfBirth, 'DD/MM/yyyy').format('D MMMM YYYY')
          : 'DOB Unknown',
        CLIENT_EMAIL: clientProfileData?.email || '',
        CLIENT_MOBILE: clientProfileData?.mobileNumber || '',
        CLIENT_POSTCODE: clientProfileData?.postcode || '',
        FIRST_APPOINTMENT_DATE: firstAppointDate,
        RECENT_APPOINTMENT_DATE: lastAppointDate,
        APPOINTMENT_COUNT: appointments.length,
        FIRST_ASSESSMENT: firstAssessment || '',
        FIRST_ASSESSMENT_DATE: firstAssessmentDate,
        RECENT_ASSESSMENT: mostRecentAssessment || '',
        RECENT_ASSESSMENT_DATE: lastAssessmentDate,
        PRACTITIONER_NAME: clinicianProfile?.name || '',
        REFERRER_NAME: referrerName ? `${referrerName.firstName} ${referrerName.lastName}` : '',
        NUMBER_OF_AUTHORIZED_SESSION: authorizedSessionNumber,
        CLIENT_FIRST_NAME: clientProfileData?.firstName || '',
        NUMBER_OF_SESSIONS_CANCELLED: patientActivities?.length || 0,
        NUMBER_OF_SESSIONS_NOT_ATTEND: sessionsNotAttend,
        GP_NAME: generalPractitioner?.name || '',
        GP_MEDICARE_PROVIDER_NUMBER: generalPractitioner?.medicareProviderNumber || '',
        GP_NAME_OF_PRACTICE: generalPractitioner?.practiceName || '',
        GP_ADDRESS: generalPractitioner?.address || '',
        GP_FAX: generalPractitioner?.fax || '',
        GP_LANDLINE: generalPractitioner?.landline || '',
        GP_EMAIL: generalPractitioner?.email || '',
        MY_PROVIDER_NAME: clientProfileData?.medicare
          ? `${clientProfileData.medicare.firstName} ${clientProfileData.medicare.lastName}`
          : '',
        MY_PROVIDER_NUMBER: clientProfileData?.medicare?.number || ''
      };
      const variables = { ...variableList, ...cdfList };

      // CREATE TEMPLATE COMPILER
      setRenderer(() => {
        return (template: string) => {
          const cleanedTemplate = cleanupTemplate(template, variables);
          const compiledTemplate = Handlebars.compile(cleanedTemplate);
          return compiledTemplate(variables);
        };
      });
    }
  }, [
    isLoading,
    appointments,
    assessmentDetailsList,
    clientEncryptDetails,
    clientMinifiedData?.data,
    generalPractitioner,
    patientActivities,
    profileId,
    clinicianProfile
  ]);

  useEffect(() => {
    if (!isLoading && !shouldNotRun) {
      createTemplate();
    }
  }, [createTemplate, isLoading, shouldNotRun]);

  return {
    renderer,
    isRendererBinding: isLoading,
    assessmentList: assessmentDetailsList || []
  };
};
