import { CalendarHighLightInterface } from 'components/v2/CalendarFilter/interfaces';
import { createRef, useState } from 'react';
import CalendarFilterCheckItem from '../CalendarFilterCheckItem/CalendarFilterCheckItem';
import { FilterCheckListItem } from '../FilterCheckList/FilterCheckList';
import styles from './HighLightFilterCheckList.module.scss';
import HighLightTypeSelect, { FILTER_OPTIONS } from './HighLightTypeSelect/HighLightTypeSelect';

const SCROLL_ROWS = 3;

export interface HighLightFilterCheckListProps {
  items: CalendarHighLightInterface[];
  searchValue: string;
  selectedFilterList: FilterCheckListItem[];
  onChangeItem: (value: { item?: FilterCheckListItem; toggleAllValue?: boolean; color?: string }) => void;
}

const HighLightFilterCheckList = ({
  items,
  searchValue,
  selectedFilterList,
  onChangeItem
}: HighLightFilterCheckListProps) => {
  const [selectedFilter, setSelectedFilter] = useState<{ label: string; value: string }>(FILTER_OPTIONS[0]);
  const ref = createRef<HTMLDivElement>();
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const searchItems = items
    .map((item) => selectedFilterList.find((i) => i._id === item._id) || item)
    .filter((item) => item.name?.toLowerCase().includes(searchValue.toLowerCase()));

  const onScrollClick = () => {
    ref?.current?.scrollBy({
      top: SCROLL_ROWS * (ref.current.scrollHeight / searchItems.length),
      behavior: 'smooth'
    });
  };

  const appointment =
    selectedFilter?.value === 'all' || selectedFilter?.value === 'appointment'
      ? items
          .filter((i) => i.sessionType)
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          })
      : [];
  const appointmentType =
    selectedFilter?.value === 'all' || selectedFilter?.value === 'appointmentType'
      ? items.filter((i) => i.appointmentType)
      : [];
  const deliveryMode =
    selectedFilter?.value === 'all' || selectedFilter?.value === 'deliveryMode'
      ? items.filter((i) => i.deliveryMode)
      : [];
  const status =
    selectedFilter?.value === 'all' || selectedFilter?.value === 'status'
      ? items
          .filter((i) => i.status)
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          })
      : [];
  return (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <HighLightTypeSelect className={styles.filter} onSelect={setSelectedFilter} selectedOption={selectedFilter} />
      </div>
      <div
        className={styles.items}
        ref={ref}
        onScroll={(e) => {
          if (ref.current) {
            if (ref.current.scrollHeight - (ref.current.scrollTop + ref.current.clientHeight) < 10) {
              setIsScrolledToBottom(true);
            } else {
              setIsScrolledToBottom(false);
            }
          }
        }}
      >
        {appointment.length > 0 && (
          <>
            <div className={styles.subSection}>APPOINTMENT</div>
            {appointment.map((item, index) => {
              const isChecked = !!selectedFilterList.find((i) => i._id === item._id);
              return (
                <CalendarFilterCheckItem
                  key={index}
                  id={`${item._id}${index}`}
                  item={item}
                  onChangeItem={onChangeItem}
                  searchValue={searchValue}
                  isChecked={isChecked}
                />
              );
            })}
          </>
        )}
        {appointmentType.length > 0 && (
          <>
            <div className={styles.subSection}>APPOINTMENT TYPE</div>
            {appointmentType.map((item, index) => {
              const isChecked = !!selectedFilterList.find((i) => i._id === item._id);
              return (
                <CalendarFilterCheckItem
                  key={index}
                  id={`${item._id}${index}`}
                  item={item}
                  onChangeItem={onChangeItem}
                  searchValue={searchValue}
                  isChecked={isChecked}
                />
              );
            })}
          </>
        )}
        {deliveryMode.length > 0 && (
          <>
            <div className={styles.subSection}>DELIVERY MODE</div>
            {deliveryMode.map((item, index) => {
              const isChecked = !!selectedFilterList.find((i) => i._id === item._id);
              return (
                <CalendarFilterCheckItem
                  key={index}
                  id={`${item._id}${index}`}
                  item={item}
                  onChangeItem={onChangeItem}
                  searchValue={searchValue}
                  isChecked={isChecked}
                />
              );
            })}
          </>
        )}
        {status.length > 0 && (
          <>
            <div className={styles.subSection}>STATUS</div>
            {status.map((item, index) => {
              const isChecked = !!selectedFilterList.find((i) => i._id === item._id);
              return (
                <CalendarFilterCheckItem
                  key={index}
                  id={`${item._id}${index}`}
                  item={item}
                  onChangeItem={onChangeItem}
                  searchValue={searchValue}
                  isChecked={isChecked}
                />
              );
            })}
          </>
        )}
      </div>
      {items.length > 5 && !isScrolledToBottom ? (
        <div className={styles.scrollDownText} onClick={onScrollClick}>
          <div className={styles.scroll}>
            <i className="material-icons-outlined">arrow_downward</i>
          </div>
        </div>
      ) : (
        <div className={styles.emptyScroll} />
      )}
    </div>
  );
};

export default HighLightFilterCheckList;
