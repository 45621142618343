import { BentResponse } from 'bent';
import queryString from 'query-string';
import { Invoice } from 'pages/Invoices/interface';
import { httpClient } from '../../../httpClient';
import { InvoiceType, TotalInvoicesAmounts } from 'utils/hooks/useTotalInvoicesAmounts';
import { StatusFilterType } from 'utils/hooks/useFetchInvoices';
import { ParticipantType } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';

const url = process.env.REACT_APP_BILLING_SERVICE_URL;

export const getInvoiceIdUsed = (token: string, invoiceId: string, invoiceDocumentId?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/invoices:isInvoiceIdUsed?invoiceId=${invoiceId}${`&invoiceDocumentId=${invoiceDocumentId}` ?? ''}`
  ) as Promise<BentResponse>;

interface GetInvoicesOptions {
  accountId: string;
  query?: {
    clientRecordId?: string;
    page?: number;
    perPage?: number;
    sortByIssueDate?: 1 | -1;
    status?: StatusFilterType;
    type?: Omit<ParticipantType, 'all'>;
  };
  token: string;
}

export const getInvoices = async ({ accountId, query, token }: GetInvoicesOptions) => {
  const stringifiedQuery = query ? queryString.stringify(query) : '';

  const response = (await httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/invoices?${stringifiedQuery}`
  )) as BentResponse;

  return (await response.json()) as Promise<{
    invoices: Invoice[];
    paging: { page: number; perPage: number; totalItems: number };
  }>;
};

export const getInvoice = (token: string, invoiceId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(`/invoices/${invoiceId}`) as Promise<BentResponse>;

export const getInvoicesAmounts = async ({
  accountId,
  queryParams,
  token
}: {
  accountId: string;
  queryParams?: { type?: InvoiceType; clientRecordId?: string; episodeId?: string; showOutsideOfEpisode?: boolean };
  token: string;
}) => {
  const stringifiedQuery = queryParams ? `?${queryString.stringify(queryParams)}` : '';

  const response = (await httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/invoices/summary${stringifiedQuery}`
  )) as BentResponse;

  return (await response.json()) as Promise<TotalInvoicesAmounts>;
};

export const getInvoiceSettingsPaymentMethodsReady = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    '/invoice-settings/payment-methods-ready'
  ) as Promise<BentResponse>;

export const postInvoice = (token: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })('/invoices', payload) as Promise<BentResponse>;

export const putInvoice = (token: string, invoiceId: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/invoices/${invoiceId}`,
    payload
  ) as Promise<BentResponse>;

export const putInvoiceStatus = (token: string, _id: string, status: string) =>
  httpClient('PATCH', url!, 200, { Authorization: `Bearer ${token}` })(`/invoices/${_id}/status`, {
    status
  }) as Promise<BentResponse>;

export const putPaymentMethods = (token: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    '/payment-methods',
    payload
  ) as Promise<BentResponse>;

export const putResendInvoice = (token: string, _id: string) =>
  httpClient('PATCH', url!, 202, { Authorization: `Bearer ${token}` })(
    `/invoices/${_id}:resend`
  ) as Promise<BentResponse>;

export const sendInvoice = (token: string, id: string, payload: Record<string, any>) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/invoices/${id}:send`,
    payload
  ) as Promise<BentResponse>;

export const getInvoiceTemplates = (token: string, accountId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/invoice-templates`
  ) as Promise<BentResponse>;
