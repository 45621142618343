import { GetClientRecordListingQuery, SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import {
  ClientListFilters,
  ClientListPaging,
  ClientListSearch,
  ClientListSorting
} from 'redux/clients/clientListSlice';
import { ClientListColumnsSettings, ColumnSettingsEnum } from 'utils/providers/ClientListColumnsProvider/constants';
import { mobileNumberConvert } from 'utils/helpers/mobileNumber';

export enum SortType {
  ASC = 1,
  DESC = -1
}

export enum ClientStatus {
  ACTIVE = 'active',
  CLOSED = 'closed',
  WAITLIST = 'waitlist'
}

export const searchFilterItems = [
  { name: 'Client Name', _id: SearchFilterEnum.FirstOrLastName },
  { name: 'Client First Name', _id: SearchFilterEnum.FirstName },
  { name: 'Client Last Name', _id: SearchFilterEnum.LastName },
  { name: 'Parent Name', _id: SearchFilterEnum.ParentName },
  { name: 'Client Reference', _id: SearchFilterEnum.ClientId },
  { name: 'T-ID', _id: SearchFilterEnum.TacklitId },
  { name: 'Mobile Number', _id: SearchFilterEnum.MobileNumber },
  { name: 'Email Address', _id: SearchFilterEnum.Email }
];

interface GetPayloadParam {
  tabId: string | undefined;
  paging: ClientListPaging;
  sorting: ClientListSorting;
  filters: ClientListFilters;
  isEdgeUserView: boolean;
  columnSetting: ClientListColumnsSettings;
  search: ClientListSearch;
}

export const getClientRecordRequestPayload = (params: GetPayloadParam): GetClientRecordListingQuery => {
  const { tabId, paging, sorting, filters, isEdgeUserView, columnSetting, search } = params;
  const { page, perPage } = paging;
  const { sortBy, sortType } = sorting;
  const { searchValue, searchBy } = search;

  const {
    selectedPractitioners,
    selectedGroups,
    selectedReferralSources,
    selectedReferralStatuses,
    selectedProfileTypes,
    selectedFormsStatus,
    selectedFormsTimeBound
  } = filters;

  return {
    page,
    perPage,
    recordStatus: tabId !== undefined ? (tabId as ClientStatus) : ClientStatus.ACTIVE,
    ...(selectedGroups.length > 0 && {
      groupIds: selectedGroups.map(({ _id }) => _id).join(',')
    }),
    ...(selectedPractitioners.length > 0 && {
      clinicianAuth0Ids: selectedPractitioners.map(({ _id }) => _id).join(',')
    }),
    ...(selectedReferralSources.length > 0 && {
      referralSourceTags: selectedReferralSources.map(({ _id }) => _id).join(',')
    }),
    ...(selectedReferralStatuses.length > 0 && {
      referralStatuses: selectedReferralStatuses.map(({ _id }) => _id).join(',')
    }),
    ...(selectedProfileTypes.length > 0 && {
      profileRecordTypeQuery: selectedProfileTypes.map(({ _id }) => _id).join(',')
    }),
    ...(selectedFormsStatus.length > 0 && {
      formStatusQuery: selectedFormsStatus.map(({ _id }) => _id).join(','),
      // Only refetch if there is 1 or more form status filter is selected
      formStatusDaysBefore: selectedFormsTimeBound
    }),
    // TODO enhance sort
    ...{
      [sortBy === 'clientName'
        ? 'sortByClientName'
        : sortBy === ColumnSettingsEnum.CLIENT_SINCE
        ? 'sortByClientSince'
        : 'sortByPractitionerName']: [sortType === SortType.ASC ? 1 : -1]
    },
    ...(isEdgeUserView && {
      asUser: '1'
    }),
    ...(columnSetting &&
      columnSetting.columns?.find((column) => column.id === ColumnSettingsEnum.LAST_CONSENT_SIGNED) && {
        includeConsentColumn: '1'
      }),
    ...(columnSetting &&
      columnSetting.columns?.find((column) => column.id === ColumnSettingsEnum.LAST_SURVEY_COMPLETED) && {
        includeSurveyColumn: '1'
      }),
    ...(columnSetting &&
      columnSetting.columns?.find((column) => column.id === ColumnSettingsEnum.LAST_PSYCHOMETRIC_COMPLETED) && {
        includePsychometricColumn: '1'
      }),
    ...(columnSetting &&
      columnSetting.columns?.find((column) => column.id === ColumnSettingsEnum.REFERRAL_DATE) && {
        includeReferralDateColumn: '1'
      }),
    ...(columnSetting &&
      columnSetting.columns?.find((column) => column.id === ColumnSettingsEnum.REFERRAL_SOURCE_AND_DETAIL) && {
        includeReferralSourceAndDetailColumn: '1'
      }),
    ...(columnSetting &&
      columnSetting.columns?.find((column) => column.id === ColumnSettingsEnum.REFERRAL_STATUSES) && {
        includeReferralStatusesColumn: '1'
      }),
    ...(search.searchValue && {
      searchValue: searchBy === SearchFilterEnum.MobileNumber ? mobileNumberConvert(searchValue) : searchValue,
      searchBy
    })
  };
};
