import { Divider, Skeleton } from 'antd';
import classnames from 'classnames';
import { useEffect, useState, useRef } from 'react';

import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import FlexBox from 'components/FlexBox/FlexBox';
import SearchBar from 'components/v2/SearchBar/SearchBar';
import styles from './ActionDropdown.module.scss';

export enum ActionDropdownType {
  Invoice = 'Invoice',
  Report = 'Report',
  Letter = 'Letter'
}

interface TemplateItem {
  _id: string;
  name: string;
}

interface ActionDropdownProps {
  type: ActionDropdownType;
  onAddNew: () => void;
  onNavigateToCreateWithTemplate: (templateId: string) => void;
  isTemplateLoading: boolean;
  templateList?: TemplateItem[];
  onAddNewTemplate?: () => void;
}

const ActionDropdown = ({
  type,
  onAddNew,
  onAddNewTemplate,
  isTemplateLoading,
  templateList,
  onNavigateToCreateWithTemplate
}: ActionDropdownProps) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [filteredTemplateList, setFilteredTemplateList] = useState<TemplateItem[]>([]);

  const menuNode = useRef<HTMLDivElement>(null);

  const showTemplates = !!templateList?.length;

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target)) {
      return;
    }
    setIsMenuExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  useEffect(() => {
    const newFilteredList = searchValue
      ? templateList?.filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase().trim()))
      : templateList;
    setFilteredTemplateList(newFilteredList || []);
  }, [searchValue, templateList]);

  return isTemplateLoading ? (
    <Skeleton.Button active block />
  ) : (
    <div ref={menuNode}>
      <ButtonAlt
        variant="outlined"
        iconPostFix
        onClick={showTemplates ? () => setIsMenuExpanded(!isMenuExpanded) : () => onAddNew()}
        icon={showTemplates ? 'arrow_drop_down' : undefined}
        fullWidth
      >
        {type === 'Invoice' ? 'Service Invoice' : type}
      </ButtonAlt>
      <div className={styles.menuWrapper}>
        <div className={isMenuExpanded ? styles.menuShow : styles.menuHide}>
          <div className={styles.searchWrapper}>
            <SearchBar
              containerClassName={styles.searchBox}
              clearTextIcon
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </div>
          <Divider style={{ margin: 0 }} />
          <div
            className={styles.optionWrapper}
            onClick={() => {
              setIsMenuExpanded(false);
              onAddNew();
            }}
          >
            <span>Empty {type}</span>
          </div>
          <Divider style={{ margin: 0 }} />
          <div className={styles.groupLabel}>TEMPLATE LIST</div>
          {filteredTemplateList.map((item) => (
            <div
              className={styles.optionWrapper}
              key={item._id}
              onClick={() => {
                onNavigateToCreateWithTemplate(item._id);
                setIsMenuExpanded(false);
              }}
            >
              <span>{item.name}</span>
            </div>
          ))}
          {onAddNewTemplate && (
            <div
              className={styles.optionWrapper}
              onClick={() => {
                setIsMenuExpanded(false);
                onAddNewTemplate();
              }}
            >
              <FlexBox direction="row" alignItems="center" spacing={8} className={styles.addButton}>
                <i className={classnames('material-icons')}>add_circle_outline</i>
                <span>New template</span>
              </FlexBox>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActionDropdown;
