import styles from './MemberList.module.scss';
import columnStyles from './MemberListColumn.module.scss';
import classnames from 'classnames';
import SortBtn, { SortType } from 'components/TableList/SortBtn/SortBtn';
import PerRecordDropdown from 'components/TableList/PerRecordDropdown/PerRecordDropdown';
import PaginationDesc from 'components/TableList/PaginationDesc/PaginationDesc';
import { GroupMemberList, Groups } from 'pages/Groups/Interfaces/Groups';
import { combineName } from 'utils/general';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ProfileTypeBadge from 'components/ProfileTypeBadge/ProfileTypeBadge';
import { Link } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { Skeleton } from 'antd';
import GroupStatusBadge from 'components/GroupStatusBadge/GroupStatusBadge';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import GroupConfigurationActionMenu from 'pages/Groups/GroupDetails/components/GroupContent/components/Common/GroupConfigurationActionMenu/GroupConfigurationActionMenu';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import moment from 'moment';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface MemberListProps {
  groupId: string;
  groupDetails: Groups;
  groupMembers: GroupMemberList;
  isGroupMembersLoading: boolean;
  selectedPerPage: number;
  selectedSortDateJoined?: SortType;
  selectedSortName?: SortType;
  selectedPage: number;
  onChangeDateJoinedSort: (val: SortType) => void;
  onChangeNameSort: (val: SortType) => void;
  onChangePerPage: (val: number) => void;
  onChangePage: (val: number) => void;
  onRefreshList: () => void;
  refreshUnassignedMember: () => void;
}

const MemberList = ({
  groupId,
  groupDetails,
  groupMembers,
  isGroupMembersLoading,
  selectedPerPage,
  selectedSortDateJoined,
  selectedSortName,
  selectedPage,
  onChangeDateJoinedSort,
  onChangeNameSort,
  onChangePerPage,
  onChangePage,
  onRefreshList,
  refreshUnassignedMember
}: MemberListProps) => {
  const { CLIENTS } = useRoutesGenerator();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const TOTAL_ITEM = groupMembers.paging.totalItem || 0;

  const handleRecordPerPageValue = (perPageVal: number) => {
    onChangePerPage(perPageVal);
  };

  const handlePageChange = (pageVal: number) => {
    onChangePage(pageVal);
  };

  const isGroupPractitioner =
    auth0ClinicianId === groupDetails?.leadClinicianAuth0Id ||
    groupDetails?.clinicianAuth0Ids.includes(auth0ClinicianId);

  return (
    <div className={styles.container}>
      <div className={styles.listContainer}>
        <div className={classnames(columnStyles.columnWrapperHeader, styles.header)}>
          <div className={classnames(columnStyles.dateAdded, columnStyles.sortActive)}>
            DATE ADDED
            <SortBtn
              selectedSortName={selectedSortDateJoined}
              onChangeSort={onChangeDateJoinedSort}
              disabled={isGroupMembersLoading}
            />
          </div>
          <div className={columnStyles.avatar}>AVATAR</div>
          <div className={classnames(columnStyles.fullName, columnStyles.sortActive)}>
            FULL NAME
            <SortBtn
              selectedSortName={selectedSortName}
              onChangeSort={onChangeNameSort}
              disabled={isGroupMembersLoading}
            />
          </div>
          <div className={columnStyles.profileType}>PROFILE TYPE</div>
          <div className={columnStyles.status}>STATUS</div>
          <div className={columnStyles.stage}>STAGE</div>
          <div className={styles.actionHeader}>
            <div className={columnStyles.dropdown} />
            <div className={columnStyles.action} />
          </div>
        </div>
        {isGroupMembersLoading ? (
          <>
            {[...Array(10)].map((obj, i) => (
              <div key={i} className={classnames(styles.loadingWrapper, styles.listItem)}>
                <Skeleton.Input active className={styles.loading} />
              </div>
            ))}
          </>
        ) : groupMembers.clientRecords.length > 0 ? (
          <>
            {groupMembers.clientRecords.map((clientRecordObj, index) => {
              const attachedGroup = clientRecordObj.attachedGroups.filter((group) => group.groupId === groupId)[0];
              const stageInfo =
                groupDetails.stagesConfiguration.anytime.find((stageObj) => stageObj._id === attachedGroup.stageId) ||
                groupDetails.stagesConfiguration.sequential.find((stageObj) => stageObj._id === attachedGroup.stageId);

              return (
                <div
                  key={index}
                  style={{ zIndex: 100 - index }}
                  className={classnames(columnStyles.columnWrapper, styles.listItem)}
                >
                  <div className={classnames(columnStyles.dateAdded, styles.dateJoined)}>
                    {moment(attachedGroup.dateJoined).format('DD MMM YYYY')}
                  </div>
                  <div className={columnStyles.avatar}>
                    <ClientAvatar
                      clientData={clientRecordObj.clientProfiles}
                      displayLimit={clientRecordObj.recordType === 'couple' ? 2 : 1}
                      hideName
                    />
                  </div>
                  <div className={classnames(columnStyles.fullName, styles.name)}>
                    {combineName(clientRecordObj.clientProfiles)}
                  </div>
                  <div className={columnStyles.profileType}>
                    <ProfileTypeBadge
                      clientProfilesData={clientRecordObj.clientProfiles}
                      recordType={clientRecordObj.recordType}
                      isShowInvitationAction={false}
                      isInvitationProcessing={false}
                      canBeFullProfile={false}
                    />
                  </div>
                  <div className={columnStyles.status}>
                    <GroupStatusBadge groupStatus={attachedGroup.status} />
                  </div>
                  <div className={classnames(columnStyles.stage, styles.stage)}>
                    {stageInfo ? (
                      <>
                        <div className={styles.stagePill}>{stageInfo.name}</div>
                        {attachedGroup.stageUpdatedAt && (
                          <div className={styles.stageDatetime}>
                            <span>{moment(attachedGroup.stageUpdatedAt).format('HH:mm')}</span>
                            <span>{moment(attachedGroup.stageUpdatedAt).format('DD/MM/YYYY')}</span>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className={styles.noValue}>-</div>
                    )}
                  </div>
                  <div className={styles.arrowWrapper}>
                    <div className={columnStyles.dropdown}>
                      {(isEdgeAdminView || isGroupPractitioner || isEdgeReceptionistView) && (
                        <GroupConfigurationActionMenu
                          groupId={groupId}
                          activeStageId={attachedGroup.stageId}
                          groupStatus={attachedGroup.status}
                          groupStagesConfiguration={groupDetails.stagesConfiguration}
                          clientRecordId={clientRecordObj._id}
                          clientRecordStatus={clientRecordObj.recordStatus}
                          onRefreshList={onRefreshList}
                          refreshUnassignedMember={refreshUnassignedMember}
                        />
                      )}
                    </div>
                    <div className={columnStyles.action}>
                      <Link to={`${CLIENTS.BASE}/${clientRecordObj._id}/profile`}>
                        <ButtonAlt size={'medium'} variant={'outlined'} icon={'arrow_forward'} fab />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className={styles.noMemberFoundContainer}>No members found</div>
        )}
      </div>
      <div className={styles.paginationWrapper}>
        <div className={styles.recordPerPageWrapper}>
          <PerRecordDropdown
            totalItem={TOTAL_ITEM}
            selectedPerPage={selectedPerPage}
            selectDropdownValue={handleRecordPerPageValue}
            disabled={isGroupMembersLoading}
          />
          <div className={styles.label}>records per page</div>
        </div>
        <PaginationDesc
          currentPage={selectedPage}
          totalItem={TOTAL_ITEM}
          perPage={selectedPerPage}
          onPageChange={handlePageChange}
          isLoading={isGroupMembersLoading}
          label={'members'}
        />
      </div>
    </div>
  );
};

export default MemberList;
