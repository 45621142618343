import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getClaims } from 'utils/http/BillingService/Invoice/claimingcom';

import { MedicareItemDuration, MedicareItemFormat, MedicareItemMode } from '../interfaces';

export enum ClaimType {
  DVA = 'DVA',
  BulkBill = 'BulkBill',
  Medicare = 'Medicare'
}

export enum ClaimStatus {
  MedicareAssessed = 'MEDICARE_ASSESSED', // accepted
  MedicareRejected = 'MEDICARE_REJECTED', // failed
  MedicarePendable = 'MEDICARE_PENDABLE', // errors present, to be reviewed
  MedicarePended = 'MEDICARE_PENDED', // errors present, reviewed and confirmed
  Complete = 'COMPLETE',
  WithMedicare = 'WITH MEDICARE',
  Expired = 'EXPIRED', // errors present, but not confirmed after 1 hour
  Processed = 'PROCESSED', // bulk-bill processed
  Rejected = 'REJECTED', // bulk-bill rejected
  WriteOff = 'WRITEOFF'
}

export interface Claim {
  _id: string;
  accountId: string;
  type: ClaimType;
  provider: {
    clinicianId: string;
    providerNumber: string;
    providerName: string;
    payee?: string;
  };
  locationMinorId: string;
  claimant: {
    clientRecordId: string;
    medicareNumber?: string;
    medicareIrn?: string;
    medicareDva?: string;
  };
  _claimant?: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    medicareNumber: string;
    medicareIrn: string;
  };
  invoice: {
    id: string;
    invoiceId: string;
    amount: number;
  };
  service: {
    duration: MedicareItemDuration;
    format: MedicareItemFormat;
    mode: MedicareItemMode;
  };
  referral?: {
    date: string;
    name: string;
    providerNumber: string;
    generalPractitionerId: string;
  };
  benefit: number;
  mbsCode: string;
  status: ClaimStatus;
  statusMessages: string[];
  claimId: string;
  medicareClaimId?: string;
  payment?: {
    paymentId: number;
    date: string;
    amount: number;
    bank: {
      bsb: string;
      accountNumber: string;
      accountName: string;
    };
    payerName: string;
    eraTransactionId: string;
  };
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  abandoned?: boolean;
}

export const useGetClaims = (token: string, clientRecordId: string) => {
  const [claims, setClaims] = useState<Claim[]>([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState(false);

  const fetchClaims = useCallback(async (token: string, clientRecordId: string) => {
    setIsClaimsLoading(true);

    try {
      const getClaimsResponse = await getClaims(token, clientRecordId);
      const { claims } = (await getClaimsResponse.json()) as { claims: Claim[] };

      setClaims(claims);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: "Something went wrong while trying to fetch your client's claims"
      });
    }
    setIsClaimsLoading(false);
  }, []);

  const refetchClaims = () => {
    fetchClaims(token, clientRecordId);
  };

  useEffect(() => {
    if (token && clientRecordId) {
      fetchClaims(token, clientRecordId);
    }
  }, [token, clientRecordId, fetchClaims]);

  return { claims, isClaimsLoading, refetchClaims };
};
