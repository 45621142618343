import { useCallback, useEffect, useState } from 'react';
import styles from './ReferrerSearchField.module.scss';
import classNames from 'classnames';

import MaterialInput from 'components/MaterialInput/MaterialInput';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface Contact {
  name: string;
  code: string;
}

export interface ReferrerSearchFieldProps {
  fetchData: (search: string) => Promise<Contact[]>;
  label?: string;
  buttonLabel?: string;
  icon?: string;
  onClickAdd: (search: string) => void;
}

const ReferrerSearchField = ({
  fetchData,
  label = 'Search by individual name',
  buttonLabel = 'Add new individual',
  icon = 'person_add_alt',
  onClickAdd
}: ReferrerSearchFieldProps) => {
  const [search, setSearch] = useState<string>('');
  const [contacts, setContacts] = useState<Contact[]>([]);

  const fetchContacts = useCallback(async () => {
    try {
      const data = await fetchData(search);
      setContacts(data);
    } catch (e) {
      setContacts([]);
    }
  }, [search, fetchData]);

  useEffect(() => {
    if (search) {
      fetchContacts();
    } else {
      setContacts([]);
    }
  }, [search, fetchContacts]);

  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <div className={styles.fieldContainer}>
          <MaterialInput
            id="referrer-search"
            label={label}
            maxLength={20}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            postFix={<i className={`material-icons-outlined ${styles.searchIcon}`}>search</i>}
          />
        </div>

        {!search && (
          <ButtonAlt icon={icon} variant="outlined" onClick={() => onClickAdd('')}>
            {buttonLabel}
          </ButtonAlt>
        )}
      </div>

      {search && (
        <div className={styles.contactsContainer}>
          {contacts.length ? (
            contacts.map((contact, index) => (
              <div key={index} className={styles.contact}>
                <span className={classNames(styles.matchIndicator, styles.high)} />
                <span>{contact.name}</span>
                <span>{contact.code}</span>
              </div>
            ))
          ) : (
            <>
              <div className={classNames(styles.contact, styles.noMatch)}>
                <span className={classNames(styles.matchIndicator, styles.low)} />
                <span>No matches. Create new entry?</span>
                <ButtonAlt icon={icon} variant="outlined" onClick={() => onClickAdd(search)}>
                  {buttonLabel}
                </ButtonAlt>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ReferrerSearchField;
