import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export enum RecipientTypes {
  Client = 'client',
  KeyClientContact = 'keyClientContact',
  Funder = 'funder'
}

export interface Recipient {
  type: RecipientTypes;
  tags?: string[]; // for key client contacts
}

export enum ItemOverview {
  Id = 'id',
  Name = 'name',
  Date = 'date',
  Duration = 'duration',
  Status = 'status',
  Owner = 'owner'
}

export enum ItemColumns {
  Quantity = 'quantity',
  UnitPrice = 'unitPrice',
  TaxRate = 'taxRate',
  Total = 'total'
}

export interface InvoiceTemplateCreateFormProps {
  invoiceTemplate: {
    name: string;
    description?: string;
    shortCode?: string;

    paymentMethods: string[];
    sendTo: Recipient[];
    recipient: Recipient;

    invoiceTitle?: string;
    invoiceNotes: string;
    itemOverviews: ItemOverview[];
    itemColumns: ItemColumns[];
    dueAt: number;
    invoiceFooter: string;

    allowMultipleItems?: boolean;

    practiceFormalName: boolean;
    practiceRegistrationNumber: boolean;
    practiceFinanceEmail: boolean;

    recipientAddress: boolean;
    recipientEmail: boolean;
    recipientMobileNumber: boolean;
    recipientReference: boolean;
    recipientRegistrationNumber: boolean;

    medicareClaimant: boolean;
    medicareClaim: boolean;
    medicareServiceProvider: boolean;
    medicareReferral: boolean;
  };
}

const initialState: InvoiceTemplateCreateFormProps = {
  invoiceTemplate: {
    name: '',
    description: undefined,
    shortCode: undefined,

    paymentMethods: [],
    sendTo: [],
    recipient: {
      type: RecipientTypes.Client
    },

    invoiceTitle: undefined,
    invoiceNotes: '',
    itemOverviews: [],
    itemColumns: [ItemColumns.Total, ItemColumns.Quantity, ItemColumns.UnitPrice, ItemColumns.TaxRate],
    dueAt: 0,
    invoiceFooter: '',

    allowMultipleItems: true,

    practiceFormalName: false,
    practiceRegistrationNumber: false,
    practiceFinanceEmail: false,

    recipientAddress: false,
    recipientEmail: false,
    recipientMobileNumber: false,
    recipientReference: false,
    recipientRegistrationNumber: false,

    medicareClaimant: false,
    medicareClaim: false,
    medicareServiceProvider: false,
    medicareReferral: false
  }
};

export const invoiceTemplateCreateFormSlice = createSlice({
  name: 'invoiceTemplateCreateFormSlice',
  initialState,
  reducers: {
    setInvoiceTemplate: (state, action) => {
      state.invoiceTemplate = {
        ...state.invoiceTemplate,
        ...action.payload
      };
    },
    resetAll: (state) => {
      state.invoiceTemplate = initialState.invoiceTemplate;
    }
  }
});

export const selectInvoiceTemplate = (state: RootState) => state.invoiceTemplateCreateFormSlice.invoiceTemplate;

export const { setInvoiceTemplate, resetAll: resetInvoiceTemplate } = invoiceTemplateCreateFormSlice.actions;

export default invoiceTemplateCreateFormSlice.reducer;
