import { useMemo } from 'react';
import moment from 'moment';

import { FirstAssessment, Option, QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';

import styles from './PatientDetailsSurveysContent.module.scss';
import { useGetAccessToken } from 'utils/hooks/token';
import { referralFilesInterface } from 'interfaces/Clients/clientsRecord';
import { getDecryptedDocument } from 'utils/http/ClinicianProfileService/DecryptedDocument/download';
import mime from 'mime';
import { notification } from 'antd';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import classnames from 'classnames';
import { ScaleType } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';
import { EMOJI_MAP } from 'components/QuestionTypes/EmojiScalePreview/EmojiMap';
import LifeBalanceWheelChart from './LifeBalanceWheelChart/LifeBalanceWheelChart';

interface PatientDetailsSurveysContentProps {
  survey: FirstAssessment;
  referralFile?: referralFilesInterface;
}
const BACKGROUND_COPY: Record<string, any> = {
  yes: 'Yes',
  no: 'No',
  therapist: {
    no: 'No',
    currently: 'Yes and still am',
    previously: 'Yes previously'
  },
  similarExperience: {
    no: 'No different',
    yes: 'Yes similar'
  },
  medication: {
    no: 'No',
    currently: 'Yes and am currently',
    previously: 'No, but have in last 6 months'
  }
};

const PatientDetailsSurveysContent = ({ survey, referralFile }: PatientDetailsSurveysContentProps) => {
  const { token } = useGetAccessToken();

  let index = 1;
  const calIndexBg = (i: number) => {
    index++;
    return i;
  };

  const bgResponse = survey.assessment.onboardingQuestions.backgroundResponse;

  const count = bgResponse ? Object.values(bgResponse).filter((bg) => bg.isShow).length : 0;

  const onboardingQuestions = useMemo(() => {
    const { onboardingQuestions } = survey.assessment;

    const defaultData: {
      completed: number;
      questions: {
        stem: string;
        response: string;
        responseOptions: string[];
        questionType: string;
        scaleType?: ScaleType;
      }[];
      title: string;
      id: string;
    }[] = [];

    if (onboardingQuestions) {
      return defaultData.concat(
        onboardingQuestions.assessmentField.map(({ sections, title, id }) => {
          const questions = sections
            .flatMap((section) => section.questions)
            .map(({ options, response, stem, questionType, scaleType }) => {
              let responseValue = '';
              let responseOptions: string[] = [];

              if (
                !response ||
                (Array.isArray(response.value) && response.value.length === 0) ||
                (!response.value && Number.isNaN(response.value))
              ) {
                return { stem, response: responseValue, responseOptions, questionType, scaleType };
              }

              if (Array.isArray(response.value)) {
                if (options) {
                  const foundOptions = (options as Option[])
                    .filter((option) => response.value.includes(option.key))
                    .map((option) => option.description);

                  responseOptions = responseOptions.concat(foundOptions);
                } else {
                  responseOptions = responseOptions.concat(response.value);
                }
              } else if (Array.isArray(options) && options.length > 0) {
                if (typeof response.value === 'object') {
                  for (let item of Object.entries(response.value)) {
                    if (Array.isArray(item) && item[1] && typeof item[1] === 'number' && item[1] > 0) {
                      responseOptions.push(`${item[0]}|${item[1]}`);
                    }
                  }
                } else {
                  const foundValue = (options as Option[]).find((option) => option.key === response.value);
                  foundValue && responseOptions.push(foundValue?.description || foundValue?.value?.toString());
                }
              } else {
                responseValue += response.value;
              }

              if (response.otherValue) {
                responseValue += ' ' + response.otherValue;
              }

              return { stem, response: responseValue, responseOptions, questionType, scaleType };
            });

          const completed = questions.filter(
            (question) => !!question.response || question.responseOptions.length > 0
          ).length;

          return { completed, questions, title, id };
        })
      );
    } else {
      return defaultData;
    }
  }, [survey.assessment]);

  const handleDownloadAttachment = async (attachment: referralFilesInterface) => {
    try {
      const callGetDecryptedDocument = await getDecryptedDocument(token, attachment.bucketName, attachment.fileName);

      const decryptedDocument = await callGetDecryptedDocument.arrayBuffer();

      const documentBlob = new Blob([decryptedDocument], {
        type: mime.getType(attachment.fileName) || 'application/octet-stream'
      });

      window.open(URL.createObjectURL(documentBlob), '_blank');
    } catch (ex) {
      console.error(ex);

      notification.error({ message: 'Something went wrong while trying to download this attachment.' });
    }
  };

  const checkBgQuestionExist =
    !!bgResponse &&
    (bgResponse.summary.isShow ||
      bgResponse.medication.isShow ||
      bgResponse.referral.isShow ||
      bgResponse.therapist.isShow);

  const renderEmojiReactionResponse = (response: string, index: number, length: number) => {
    const result = response.split('|');
    return (
      <div className={styles.emojiReaction} key={index}>
        {parseInt(result[1], 10) > 1 ? `${index === 0 ? 'V' : 'v'}ery ` : `${index === 0 ? 'A' : 'a'} little `}
        {`${result[0]} `}
        {EMOJI_MAP[result[0]]}
        {index < length - 1 ? ',' : ''}
      </div>
    );
  };

  const isIP6LifeBalance =
    survey.assessment.onboardingQuestions?.assessmentField.length > 0
      ? ['ip6-life-dev', 'ip6-life'].includes(survey.assessment.onboardingQuestions?.assessmentField[0].id)
      : false;

  return (
    <div className={styles.container}>
      {isIP6LifeBalance && <LifeBalanceWheelChart survey={survey} />}
      {checkBgQuestionExist && (
        <div className={styles.card}>
          <div className={styles.groupWrapper}>
            <div className={styles.groupTitle}>
              General Background
              <i className={`material-icons ${styles.icon}`}>arrow_drop_up</i>
            </div>
            <div className={styles.questionAnswered}>
              <strong>{`${count} / ${count}`}</strong> questions answered
            </div>
          </div>
          <div className={styles.answerWrapper}>
            {bgResponse.summary.isShow && (
              <div className={styles.answerCard}>
                <div className={styles.leftPanel}>
                  <div className={styles.number}>{calIndexBg(index)}</div>
                </div>
                <div className={styles.rightPanel}>
                  <div className={styles.questionTitle}>
                    Please provide a brief summary of what you'd like to work on
                  </div>
                  <div className={styles.answer}>{bgResponse.summary.content}</div>
                </div>
              </div>
            )}
            {bgResponse.referral.isShow && (
              <div className={styles.answerCardWhite}>
                <div className={styles.leftPanel}>
                  <div className={styles.number}>{calIndexBg(index)}</div>
                </div>
                <div className={styles.rightPanel}>
                  <div className={styles.questionTitle}>Have you been referred by a GP or other provider?</div>
                  <div className={styles.responseOptions}>
                    <div className={styles.pill}>{BACKGROUND_COPY[bgResponse.referral.code]}</div>
                  </div>
                  {bgResponse.referral.code === 'yes' && (
                    <div className={styles.answer}>{`From ${bgResponse.referral.name}. ${moment(
                      bgResponse.referral.date,
                      'YYYY-MM-DD'
                    ).format(MOMENTJS_DATE_FORMAT)}`}</div>
                  )}
                  {referralFile && (
                    <div className={styles.download}>
                      <div className={styles.viewReferralBtn} onClick={() => handleDownloadAttachment(referralFile)}>
                        View attached referral
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {bgResponse.therapist.isShow && (
              <div className={styles.answerCard}>
                <div className={styles.leftPanel}>
                  <div className={styles.number}>{calIndexBg(index)}</div>
                </div>
                <div className={styles.rightPanel}>
                  <div className={styles.questionTitle}>Have you worked with a psychologist or therapist before?</div>
                  <div className={styles.responseOptions}>
                    <div className={styles.pill}>{BACKGROUND_COPY.therapist[bgResponse.therapist.code]}</div>
                  </div>
                  {bgResponse.therapist.code !== 'no' && (
                    <>
                      <div className={styles.questionTitle}>
                        Was that experience working on a similar theme as you outlined above?
                      </div>
                      <div className={styles.responseOptions}>
                        <div className={styles.pill}>
                          {BACKGROUND_COPY.similarExperience[bgResponse.therapist.similarExperience || '']}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {bgResponse.medication.isShow && (
              <div className={styles.answerCardWhite}>
                <div className={styles.leftPanel}>
                  <div className={styles.number}>{calIndexBg(index)}</div>
                </div>
                <div className={styles.rightPanel}>
                  <div className={styles.questionTitle}>Are you taking any prescription medication?</div>
                  <div className={styles.responseOptions}>
                    <div className={styles.pill}>{BACKGROUND_COPY.medication[bgResponse.medication.code]}</div>
                  </div>
                  {bgResponse.medication.code !== 'no' &&
                    Array.isArray(bgResponse.medication.medications) &&
                    bgResponse.medication.medications.length > 0 && (
                      <div className={styles.responseOptions}>
                        {bgResponse.medication.medications.map((medication: any, index: number) => (
                          <div className={styles.pill} key={index}>
                            {medication.label}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {onboardingQuestions.map(
        ({ completed, questions, id, title }, index) =>
          questions.length > 0 && (
            <div className={styles.card} key={index}>
              <div className={styles.groupWrapper}>
                <div className={styles.groupTitle}>
                  {title || id}
                  <i className={`material-icons ${styles.icon}`}>arrow_drop_up</i>
                </div>
                <div className={styles.questionAnswered}>
                  <strong>
                    {completed} / {questions.length}
                  </strong>{' '}
                  questions answered
                </div>
              </div>
              <div className={styles.answerWrapper}>
                {questions.map((question, index) => {
                  return (
                    <div className={index % 2 === 0 ? styles.answerCard : styles.answerCardWhite} key={index}>
                      <div className={styles.leftPanel}>
                        <div className={styles.number}>{index + 1}</div>
                      </div>
                      <div className={styles.rightPanel}>
                        <div className={styles.questionTitle}>{question.stem}</div>
                        {question.responseOptions.length > 0 && (
                          <div className={styles.responseOptions}>
                            {question.responseOptions.map((response, index) => {
                              return question.questionType !== QuestionType.multipleChoiceColourFreeText ? (
                                question.scaleType === ScaleType.EmojiRating ? (
                                  <div className={styles.emojiRating} key={index}>
                                    {EMOJI_MAP[response.toLowerCase().replaceAll(' ', '_')]}
                                  </div>
                                ) : question.scaleType === ScaleType.EmojiReaction ? (
                                  renderEmojiReactionResponse(response, index, question.responseOptions.length)
                                ) : (
                                  <div className={styles.pill} key={index}>
                                    {response}
                                  </div>
                                )
                              ) : (
                                <div
                                  className={classnames(styles.pill, styles.colorbox)}
                                  key={index}
                                  style={{ backgroundColor: response }}
                                />
                              );
                            })}
                          </div>
                        )}
                        {question.response ? (
                          <div className={styles.answer}>{question.response}</div>
                        ) : (
                          question.responseOptions.length === 0 && <div className={styles.skipped}>Skipped</div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default PatientDetailsSurveysContent;
