import classnames from 'classnames';

import styles from './MaterialLabel.module.scss';
import HelpOutLineWithTooltips from '../HelpOutLineWithTooltips/HelpOutLineWithTooltips';

export interface MaterialLabelProps {
  error?: boolean;
  id?: string;
  label: string;
  isLabelMinimised: boolean;
  labelClassName?: string;
  labelHelpWithTooltips?: string;
}

const MaterialLabel = ({
  error,
  id,
  label,
  isLabelMinimised,
  labelClassName,
  labelHelpWithTooltips
}: MaterialLabelProps) => {
  return (
    <div
      className={classnames(
        styles.label,
        labelClassName,
        isLabelMinimised ? styles.onFocus : styles.onBlur,
        error && styles.error
      )}
    >
      {label}
      {labelHelpWithTooltips && (
        <HelpOutLineWithTooltips
          id={`${id ? `${id}-` : ''}${label}`}
          desc={labelHelpWithTooltips}
          iconClass={styles.icon}
        />
      )}
    </div>
  );
};

export default MaterialLabel;
