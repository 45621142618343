import { useState } from 'react';
import { Modal, notification } from 'antd';
import styles from './AssignMentorModal.module.scss';
import { patchReassignMentorToClient } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccessToken } from 'utils/hooks/token';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import MentorSelect from '../../../MentorSelect/MentorSelect';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

export interface AssignMentorModalProps {
  clientRecord: clientRecordsInterface;
  visible: boolean;
  onCancel: (isSave: boolean) => void;
}

const AssignMentorModal = ({ visible, clientRecord, onCancel, ...props }: AssignMentorModalProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const [selectedMentorId, setSelectedMentorId] = useState(clientRecord.mentorIds ? clientRecord.mentorIds[0] : '');
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [t] = useTranslation();

  const handleEditSaveClientData = async () => {
    setSubmitStatus('active');

    try {
      const payload = {
        mentorIds: selectedMentorId ? [selectedMentorId] : []
      };
      await patchReassignMentorToClient(token, accountId, clientRecord._id, payload);
      setSubmitStatus('finished');
      setTimeout(() => {
        notification.success({
          message: t('form.success.update_client'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitStatus('');
        onCancel(true);
      }, 1000);
    } catch (ex) {
      console.error(ex);
      setSubmitStatus('');
      notification.error({
        message: t('form.error.update_client'),
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={<div className={styles.title}>{t('client_assignment.mentor.edit')}</div>}
      open={visible}
      {...props}
      footer={null}
      onCancel={() => onCancel(false)}
    >
      <div>
        <div className={styles.subTitle}>{t('client_assignment.mentor.description')}</div>
        <div className={styles.listWrapper}>
          <MentorSelect
            onSelect={(value) => {
              setSelectedMentorId(value?._id || '');
            }}
            selectedId={selectedMentorId}
          />
        </div>
        <div className={styles.buttonContainer}>
          <ButtonAlt status={submitStatus} disabled={submitStatus !== ''} onClick={handleEditSaveClientData}>
            Save Assignment Changes
          </ButtonAlt>
        </div>
      </div>
    </Modal>
  );
};

export default AssignMentorModal;
