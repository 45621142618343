import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CarePathwayTemplateAttachment.module.scss';
import { faUpload, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useState } from 'react';
import { Spin, notification } from 'antd';
import {
  deleteCarePathwayTemplateAttachment,
  uploadCarePathwayTemplateAttachment
} from 'utils/http/DocumentService/Attachments/carePathwayTemplateAttachment';
import { useGetAccessToken } from 'utils/hooks/token';
import { useCarePathwayTemplateFormContext } from 'pages/CarePathwayTemplate/context';
import DocumentViewerModal from 'components/DocumentViewerModal/DocumentViewerModal';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

const MAX_FILE_SIZE = 19 * 1024 * 1024;

export const CarePathwayTemplateAttachment = () => {
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const { values, setValues, setIsWaitingFormValidation, isWaitingFormValidation } =
    useCarePathwayTemplateFormContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePreview = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsModalOpen(true);
  };

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const uploadedAttachmentDetails = [];

    if (files) {
      setIsWaitingFormValidation(true);
      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];

        if (currentFile.size > MAX_FILE_SIZE) {
          notification.error({ message: `File ${currentFile.name} is too big` });
          return;
        }

        try {
          const uploadedAttachmentDetail = await uploadCarePathwayTemplateAttachment(token, accountId, currentFile);

          uploadedAttachmentDetails.push({
            id: uploadedAttachmentDetail._id,
            name: uploadedAttachmentDetail.fileName
          });
        } catch (ex) {
          notification.error({ message: `Failed to upload file ${currentFile.name}` });
        }
      }
      setValues({
        attachments: [...values.attachments, ...uploadedAttachmentDetails]
      });
      notification.success({
        message: 'Attachment(s) upload successfully!',
        duration: 2
      });
      setIsWaitingFormValidation(false);
    }
  };

  const handleUploadAttachmentClick = () => {
    document.getElementById('care-pathway-template-upload-attachment')?.click();
  };

  const handleDeleteAttachment = (attachmentId: string) => {
    setValues({
      attachments: values.attachments.filter((attachment) => attachment.id !== attachmentId)
    });
    deleteCarePathwayTemplateAttachment(token, accountId, attachmentId);
  };

  const renderContent = () => {
    if (isWaitingFormValidation) {
      return <Spin />;
    }

    return (
      <div>
        <div className={styles.fileSelector} onClick={handleUploadAttachmentClick}>
          <FontAwesomeIcon icon={faUpload} />
          <span>Upload file</span>
        </div>
        {values.attachments.length > 0 && (
          <div className={styles.attachments}>
            {values.attachments.map((attachment) => (
              <div key={attachment.id} className={styles.uploadFileContainer}>
                <div className={styles.selectedFileName} onClick={handlePreview}>
                  {attachment.name}
                </div>
                <FontAwesomeIcon
                  className={styles.trashIcon}
                  icon={faTrashCan}
                  onClick={() => handleDeleteAttachment(attachment.id)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <p className={styles.title}>Attachment</p>
        <div className={styles.fileSelectorContainer}>
          <input
            onChange={handleUpload}
            multiple
            type="file"
            id="care-pathway-template-upload-attachment"
            accept="image/png,image/jpeg,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            className={styles.input}
          />
          {renderContent()}
        </div>
      </div>
      <DocumentViewerModal
        title={values.attachments[0]?.name}
        attachmentId={values.attachments[0]?.id}
        visible={isModalOpen}
        carePathwayTemplateId="1" // Just a dummy value to make the modal work
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};
