import { notification } from 'antd';
import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersListing } from 'interfaces/Practitioners/practitionersListing';
import { AppointmentType } from 'interfaces/Schedule/AppointmentType';
import {
  MAPPING_FIELDS,
  PAYMENT_TYPES
} from 'pages/ControlPanel/IntegrationDetails/components/DataMappingModal/constants';
import {
  AccountsResponse,
  InvoiceItemsResponse,
  TaxRatesResponse,
  TrackingCategoriesResponse,
  TransactionsResponse
} from 'pages/ControlPanel/Interfaces/CodatResponses';
import { ItemMapping, Mapping, MappingField, Option } from 'pages/ControlPanel/Interfaces/MappingField';
import { useCallback, useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import {
  getCodatAccounts,
  getCodatInvoiceItems,
  getCodatMappings,
  getCodatTaxRates,
  getCodatTrackingCategories,
  getCodatTransactions
} from 'utils/http/BillingService/Invoice/codat';
import { getPractitionerListing } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { getClinicianProfile } from 'utils/http/ClinicianProfileService/Profile/profile';
import { getSessionTypes } from 'utils/http/sessionType';

export const useFetchCodatTaxRates = (token: string, companyId: string) => {
  const [taxRateOptions, setTaxRateOptions] = useState<Option[]>([]);
  const [isTaxRateLoading, setIsTaxRateLoading] = useState(true);

  const fetchCodatTaxRates = useCallback(
    async (token: string) => {
      setIsTaxRateLoading(true);
      try {
        const getCodatTaxRatesResponses = await getCodatTaxRates(token, companyId);
        const codatTaxRates = (await getCodatTaxRatesResponses.json()) as TaxRatesResponse;

        const taxRateOptions = codatTaxRates.taxRates.map(({ id, name }) => ({ value: id, label: name }));

        setTaxRateOptions(taxRateOptions);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: "Something went wrong while trying to get integration's tax rates" });
      }
      setIsTaxRateLoading(false);
    },
    [companyId]
  );

  useEffect(() => {
    if (token) {
      fetchCodatTaxRates(token);
    }
  }, [token, fetchCodatTaxRates]);

  return { taxRateOptions, isTaxRateLoading };
};

export const useFetchCodatAccounts = (token: string, companyId: string) => {
  const [accountOptions, setAccountsOptions] = useState<Option[]>([]);
  const [paymentAccountOptions, setPaymentAccountsOptions] = useState<Option[]>([]);
  const [isAccountLoading, setIsAccountLoading] = useState(true);

  const fetchCodatAccounts = useCallback(
    async (token: string) => {
      setIsAccountLoading(true);
      try {
        const getCodatAccountsResponses = await getCodatAccounts(token, companyId);
        const codatAccounts = (await getCodatAccountsResponses.json()) as AccountsResponse;

        const accountOptions = codatAccounts.accounts.map(({ id, name, nominalCode }) => ({
          value: id,
          label: `${nominalCode ? `${nominalCode} - ` : ''}${name}`
        }));
        const paymentAccountOptions = codatAccounts.paymentAccounts.map(({ id, name, nominalCode }) => ({
          value: id,
          label: `${nominalCode ? `${nominalCode} - ` : ''}${name}`
        }));

        setAccountsOptions(accountOptions);
        setPaymentAccountsOptions(paymentAccountOptions);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: "Something went wrong while trying to get integration's accounts" });
      }
      setIsAccountLoading(false);
    },
    [companyId]
  );

  useEffect(() => {
    if (token) {
      fetchCodatAccounts(token);
    }
  }, [token, fetchCodatAccounts]);

  return { accountOptions, paymentAccountOptions, isAccountLoading };
};

export const useFetchCodatInvoiceItems = (token: string, companyId: string) => {
  const [invoiceItemOptions, setInvoiceItemOptions] = useState<Option[]>([]);
  const [isInvoiceItemLoading, setIsInvoiceItemLoading] = useState(true);

  const fetchCodatInvoiceItems = useCallback(
    async (token: string) => {
      setIsInvoiceItemLoading(true);
      try {
        const getCodatInvoiceItemsResponses = await getCodatInvoiceItems(token, companyId);
        const codatInvoiceItems = (await getCodatInvoiceItemsResponses.json()) as InvoiceItemsResponse;

        const invoiceItemOptions = codatInvoiceItems.items.map(({ id, name, code }) => ({
          value: id,
          label: `${code ? `${code} - ` : ''}${name}`
        }));

        setInvoiceItemOptions(invoiceItemOptions);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get invoice items' });
      }
      setIsInvoiceItemLoading(false);
    },
    [companyId]
  );

  useEffect(() => {
    if (token) {
      fetchCodatInvoiceItems(token);
    }
  }, [token, fetchCodatInvoiceItems]);

  return { invoiceItemOptions, isInvoiceItemLoading };
};

export const useFetchCodatTrackingCategories = (token: string, companyId: string) => {
  const [trackingCategoryList, setTrackingCategoryList] = useState<MappingField[]>([]);
  const [trackingCategoriesOptions, setTrackingCategoriesOptions] = useState<Option[]>([]);
  const [isTrackingCategoriesLoading, setIsTrackingCategoriesLoading] = useState(true);

  const fetchCodatTrackingCategories = useCallback(
    async (token: string) => {
      setIsTrackingCategoriesLoading(true);
      try {
        const getCodatTrackingCategoriesResponses = await getCodatTrackingCategories(token, companyId);
        const { trackingCategories } = (await getCodatTrackingCategoriesResponses.json()) as TrackingCategoriesResponse;

        let trackingCategoryList: MappingField[] = [];
        let trackingCategoriesOptions: Option[] = [];
        trackingCategories.forEach(({ id, name, hasChildren, parentId }) => {
          if (hasChildren && trackingCategories.filter((item) => item.parentId === id).length > 0) {
            trackingCategoryList.push({
              id,
              label: name,
              active: false
            });
          } else {
            trackingCategoriesOptions.push({
              value: id,
              parentId,
              label: name
            });
          }
        });

        setTrackingCategoryList(trackingCategoryList);
        setTrackingCategoriesOptions(trackingCategoriesOptions);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: "Something went wrong while trying to get integration's trackingCategories" });
      }
      setIsTrackingCategoriesLoading(false);
    },
    [companyId]
  );

  useEffect(() => {
    if (token) {
      fetchCodatTrackingCategories(token);
    }
  }, [token, fetchCodatTrackingCategories]);

  return {
    trackingCategoryList,
    trackingCategoriesOptions,
    isTrackingCategoriesLoading
  };
};

export const useFetchCodatMappings = (token: string, accountId: string, companyId: string, isXero: boolean) => {
  const [fields, setFields] = useState<MappingField[]>(MAPPING_FIELDS);
  const [accounts, setAccounts] = useState<MappingField[]>([]);
  const [payments, setPayments] = useState<MappingField[]>(PAYMENT_TYPES);
  const [items, setItems] = useState<MappingField[]>([]);
  const [trackingCategoriesMapping, setTrackingCategoriesMapping] = useState<ItemMapping[]>([]);
  const [isMappingsLoading, setIsMappingsLoading] = useState(true);
  const { isEdgeUser } = useGetAccountPackageView();

  const fetchCodatMappings = useCallback(
    async (token: string) => {
      setIsMappingsLoading(true);
      try {
        const getCodatMappingsResponses = await getCodatMappings(token, companyId);
        const codatMappings = (await getCodatMappingsResponses.json()) as Mapping;

        const practitioners: MappingField[] = [];
        const appointmentTypes: MappingField[] = [];

        if (isEdgeUser) {
          const accessRights = [AccessRight.Admin, AccessRight.User];
          const getPractitionerListingResponses = await getPractitionerListing(
            token,
            accountId,
            `status=active&withAccessRights=${accessRights.join(',')}`
          );
          const { practitionerList } = (await getPractitionerListingResponses.json()) as PractitionersListing;
          practitioners.push({ id: 'default', label: 'Practice Account', required: !isXero, active: !isXero });
          practitionerList.forEach(({ _id, name }) => practitioners.push({ id: _id, label: name, active: false }));
        } else {
          const getClinicianProfileResponses = await getClinicianProfile(token);
          const { _id, name } = await getClinicianProfileResponses.json();
          practitioners.push({ id: _id, label: name, required: true, active: true });
        }

        // set default
        setAccounts(practitioners);

        if (isXero) {
          const { data } = (await (await getSessionTypes(token)).json()) as { data: AppointmentType[] };
          data.forEach((appointmentType) =>
            appointmentTypes.push({
              id: appointmentType._id!,
              label: appointmentType.name,
              required: true,
              active: true
            })
          );
          setItems(appointmentTypes);
        }

        if (Object.keys(codatMappings).length > 0) {
          setTrackingCategoriesMapping(codatMappings.fields.trackingCategories ?? []);

          const populatedFields = MAPPING_FIELDS.map((field) => ({
            ...field,
            selectedId: codatMappings.fields[field.id as keyof Omit<Mapping['fields'], 'trackingCategories'>].to,
            active: codatMappings.fields[field.id as keyof Omit<Mapping['fields'], 'trackingCategories'>].active
          }));
          setFields(populatedFields);

          const populatedPayments = PAYMENT_TYPES.map((payment) => ({
            ...payment,
            selectedId: codatMappings.payments[payment.id as keyof Mapping['payments']].to,
            active: codatMappings.payments[payment.id as keyof Mapping['payments']].active
          }));
          setPayments(populatedPayments);

          if (codatMappings.accounts.length > 0) {
            const populatedAccounts = practitioners.map((practitioner) => {
              const matchedPractitioner = codatMappings.accounts.find(
                ({ clinicianId }) => clinicianId === practitioner.id
              );
              return {
                ...practitioner,
                ...(matchedPractitioner && { active: matchedPractitioner.active, selectedId: matchedPractitioner.to })
              };
            });

            setAccounts(populatedAccounts);
          }
          if (isXero && codatMappings.items.length > 0) {
            const populatedItems = appointmentTypes.map((item) => {
              const matchedItem = codatMappings.items.find(({ id }) => id === item.id);
              return {
                ...item,
                ...(matchedItem && { active: matchedItem.active, selectedId: matchedItem.to })
              };
            });

            setItems(populatedItems);
          }
        }
      } catch (ex) {
        console.error(ex);
        notification.error({ message: "Something went wrong while trying to get integration's mapping" });
      }
      setIsMappingsLoading(false);
    },
    [companyId, isEdgeUser, isXero, accountId]
  );

  useEffect(() => {
    if (token) {
      fetchCodatMappings(token);
    }
  }, [token, fetchCodatMappings]);

  return {
    fields,
    setFields,
    accounts,
    setAccounts,
    items,
    setItems,
    payments,
    setPayments,
    trackingCategoriesMapping,
    isMappingsLoading
  };
};

export const useFetchCodatTransactions = (
  token: string,
  companyId: string,
  filter?: 'success' | 'failed' | 'retried'
) => {
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);
  const [codatTransactions, setCodatTransactions] = useState<TransactionsResponse[]>([]);

  const fetchCodatTransactions = useCallback(
    async (token: string, filter?: string) => {
      setIsTransactionsLoading(true);
      try {
        const getCodatTransactionsResponses = await getCodatTransactions(token, companyId, filter);
        const codatTransactionsResponse = (await getCodatTransactionsResponses.json()) as TransactionsResponse[];

        setCodatTransactions(codatTransactionsResponse);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: "Something went wrong while trying to get integration's history" });
      }
      setIsTransactionsLoading(false);
    },
    [companyId]
  );

  const refetchCodatTransactions = (query?: string) => {
    fetchCodatTransactions(token, query);
  };

  useEffect(() => {
    if (token) {
      fetchCodatTransactions(token, filter);
    }
  }, [token, filter, fetchCodatTransactions]);

  return { isTransactionsLoading, codatTransactions, refetchCodatTransactions };
};
