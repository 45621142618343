import { useEffect, useState } from 'react';

import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import { useGetAccessToken } from 'utils/hooks/token';

import styles from './GroupAssignment.module.scss';
import { notification, Modal } from 'antd';
import { AccessRight } from 'interfaces/Clients/clinician';
import { Groups, GroupStatus } from 'pages/Groups/Interfaces/Groups';
import { patchReassignGroup } from 'utils/http/ClinicianProfileService/Groups/Groups';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface GroupAssignmentProps {
  groupDetail: Groups;
  fetchGroupDetails: (token: string) => void;
  isGroupDetailsLoading: boolean;
}

const GroupAssignment = ({ groupDetail, fetchGroupDetails, isGroupDetailsLoading }: GroupAssignmentProps) => {
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const [selectedClinicianIds, setSelectedClinicianIds] = useState<string[]>(
    groupDetail.clinicianAuth0Ids.length > 0 ? groupDetail.clinicianAuth0Ids : ['']
  );
  const [leadId, setLeadId] = useState(groupDetail.leadClinicianAuth0Id || '');
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');
  const [t] = useTranslation();

  useEffect(() => {
    if (!isGroupDetailsLoading) {
      setSelectedClinicianIds(groupDetail.clinicianAuth0Ids.length > 0 ? groupDetail.clinicianAuth0Ids : ['']);
      setLeadId(groupDetail.leadClinicianAuth0Id || '');
    }
  }, [isGroupDetailsLoading, groupDetail.clinicianAuth0Ids, groupDetail.leadClinicianAuth0Id]);

  const handleSelectPractitioner = (index: number, value?: PractitionersDetailsInterface) => {
    const newClinicianIds = [...selectedClinicianIds];
    newClinicianIds[index] = value?._id || '';
    setSelectedClinicianIds(newClinicianIds);
  };

  const handleAddPractitionerSelector = () => {
    const newClinicianIds = [...selectedClinicianIds];
    newClinicianIds.filter((id) => !id).length === 0 && newClinicianIds.push('');
    setSelectedClinicianIds(newClinicianIds);
  };

  const handleRemovePractitioner = (index: number) => {
    const newClinicianIds = [...selectedClinicianIds];
    newClinicianIds.length > 1 ? newClinicianIds.splice(index, 1) : (newClinicianIds[0] = '');
    setSelectedClinicianIds(newClinicianIds);
  };

  const handleWarningModal = () => {
    if (groupDetail.status === GroupStatus.Closed) {
      return;
    }
    Modal.confirm({
      title: 'Confirm save message',
      content: 'Are you sure that you want to save the group assignment changes?',

      onOk: async () => {
        setSaveStatus('active');
        try {
          const payload = {
            clinicianAuth0Ids: selectedClinicianIds.filter((clinicianId) => clinicianId),
            leadClinicianAuth0Id: leadId
          };
          await patchReassignGroup(token, accountId, groupDetail._id, payload);
          fetchGroupDetails(token);
          setSaveStatus('finished');

          notification.success({
            message: 'Group successfully assigned!',
            duration: 3,
            closeIcon: <span className="success">OK</span>
          });
          setTimeout(() => {
            setSaveStatus('');
          }, 500);
        } catch (ex) {
          console.error(ex);
          notification.error({
            message: 'Group fail to assign',
            duration: 3,
            closeIcon: <span className="success">OK</span>
          });
          setSaveStatus('');
        }
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentBox}>
        <div className={styles.title}>{t('group_assignment.group_lead')}</div>
        <div className={styles.desc}>{t('group_assignment.group_lead.description')}</div>
        <div className={styles.leadListWrapper}>
          <ClinicianSelect
            onSelect={(value) => {
              setLeadId(value?._id || '');
            }}
            selectedId={leadId}
            disabled={groupDetail.status === GroupStatus.Closed}
            filterRoles={[AccessRight.Admin, AccessRight.User]}
            placeholder={t('label.select_practitioner')}
            excludedClinicianIds={selectedClinicianIds.filter((clinicianId) => clinicianId !== '')}
            includePractice
          />
        </div>
        <div className={styles.title}>{t('group_assignment.practitioner')}</div>
        <div className={styles.desc}>{t('group_assignment.practitioner.description')}</div>
        <div className={styles.listWrapper}>
          {selectedClinicianIds.map((clinicianId, index) => (
            <div className={styles.practitionerItem} key={index}>
              <div className={styles.selectorWrapper}>
                <ClinicianSelect
                  onSelect={(value) => handleSelectPractitioner(index, value)}
                  selectedId={clinicianId}
                  excludedClinicianIds={[...selectedClinicianIds, ...[leadId]]}
                  disabled={groupDetail.status === GroupStatus.Closed}
                  filterRoles={[AccessRight.Admin, AccessRight.User]}
                  placeholder={t('label.select_practitioner')}
                  onRemove={() => handleRemovePractitioner(index)}
                  allowEmpty
                  noInitChange
                />
              </div>
              {index === selectedClinicianIds.length - 1 && (
                <ButtonAlt
                  fab
                  size={'medium'}
                  className={styles.addBtn}
                  variant={'text'}
                  icon={'add_circle'}
                  onClick={handleAddPractitionerSelector}
                  disabled={selectedClinicianIds.filter((id) => !id).length > 0}
                />
              )}
            </div>
          ))}
        </div>

        <div className={styles.buttonWrapper}>
          <ButtonAlt
            type={'submit'}
            status={saveStatus}
            onClick={handleWarningModal}
            disabled={groupDetail.status === GroupStatus.Closed}
          >
            Save Assignment Changes
          </ButtonAlt>
        </div>
      </div>
    </div>
  );
};

export default GroupAssignment;
