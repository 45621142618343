import { useState } from 'react';
import { notification } from 'antd';
import classNames from 'classnames';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { Formik } from 'formik';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { TimeSlot } from 'interfaces/Schedule/Room';
import moment from 'moment';
import TimeAvailable from 'pages/Calendar/components/CalendarSettings/components/RoomManagement/components/RoomForm/components/TimeAvailable/TimeAvailable';
import { useGetAccessToken } from 'utils/hooks/token';
import { updateClinicianProfile } from 'utils/http/ClinicianProfileService/Profile/profile';
import { initFormValue, TIME_FORMAT_WITHOUT_ASIDE, TIME_FORMAT_WITH_ASIDE, workingScheduleSchema } from './constants';
import styles from './MentorWorkingSchedule.module.scss';
import { WorkingScheduleInterface } from './interfaces';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import MentorHeader from '../MentorHeader/MentorHeader';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface MentorWorkingScheduleProps {
  profile: ProfileInterface;
  onUpdateProfile: (newProfile: ProfileInterface) => void;
}

const MentorWorkingSchedule = ({ profile, onUpdateProfile }: MentorWorkingScheduleProps) => {
  const { token } = useGetAccessToken();
  const [numberOfMembers, setNumberOfMembers] = useState<PractitionersDetailsInterface['caseLoad']['availableSlot']>(
    profile?.caseLoad?.availableSlot
  );
  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [numberOfMembersError, setNumberOfMembersError] = useState(false);

  const reFormatTimes = (timeSlots: TimeSlot[]) => {
    if (timeSlots && timeSlots.length > 0) {
      return timeSlots.map((timeSlot) => {
        return {
          startTime: moment(timeSlot.startTime, TIME_FORMAT_WITH_ASIDE).format(TIME_FORMAT_WITHOUT_ASIDE),
          endTime: moment(timeSlot.endTime, TIME_FORMAT_WITH_ASIDE).format(TIME_FORMAT_WITHOUT_ASIDE)
        };
      });
    }
    return [] as TimeSlot[];
  };

  const validateField = () => {
    if (numberOfMembers < 0) {
      setNumberOfMembersError(true);
      setPageSubmitStatus('');
      return false;
    }
    return true;
  };

  const handleChangeNumberOfMembers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regexNumberOnly = /^[0-9\b]+$/;
    if (event.target.value === '' || regexNumberOnly.test(event.target.value)) {
      setNumberOfMembersError(false);
      setNumberOfMembers(parseInt(event.target.value, 10));
    }
  };

  const handleSubmit = async (value: WorkingScheduleInterface) => {
    setPageSubmitStatus('active');

    if (validateField()) {
      try {
        const reqBody = {
          ...profile,
          caseLoad: {
            availableSlot: numberOfMembers
          },
          workingSchedule: {
            monday: {
              isActive: value.availability.monday.isAvailable,
              timeSlots: reFormatTimes(value.availability.monday.timeSlots || [])
            },
            tuesday: {
              isActive: value.availability.tuesday.isAvailable,
              timeSlots: reFormatTimes(value.availability.tuesday.timeSlots || [])
            },
            wednesday: {
              isActive: value.availability.wednesday.isAvailable,
              timeSlots: reFormatTimes(value.availability.wednesday.timeSlots || [])
            },
            thursday: {
              isActive: value.availability.thursday.isAvailable,
              timeSlots: reFormatTimes(value.availability.thursday.timeSlots || [])
            },
            friday: {
              isActive: value.availability.friday.isAvailable,
              timeSlots: reFormatTimes(value.availability.friday.timeSlots || [])
            },
            saturday: {
              isActive: value.availability.saturday.isAvailable,
              timeSlots: reFormatTimes(value.availability.saturday.timeSlots || [])
            },
            sunday: {
              isActive: value.availability.sunday.isAvailable,
              timeSlots: reFormatTimes(value.availability.sunday.timeSlots || [])
            }
          }
        } as ProfileInterface;

        await updateClinicianProfile(token, reqBody);

        setPageSubmitStatus('finished');

        onUpdateProfile(reqBody);

        notification.success({
          message: 'Working Schedule updated',
          closeIcon: <span className="success">OK</span>
        });

        setTimeout(() => setPageSubmitStatus(''), 2000);
      } catch (ex) {
        setPageSubmitStatus('');
        notification.error({ message: 'Something went wrong while trying to update your working schedule info' });
      }
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <MentorHeader profile={profile} />
        <div>
          <div className={styles.title}>Working Schedule</div>
          <div className={styles.description}>Set your availability to support members</div>
        </div>
      </div>

      {/* Content */}
      <Formik initialValues={initFormValue(profile)} validationSchema={workingScheduleSchema} onSubmit={handleSubmit}>
        {({ submitForm }) => (
          <div className={styles.content}>
            <div className={styles.fieldBoxWrapper}>
              <div className={styles.labelWrapper}>
                <div className={styles.title}>Number of Members</div>
              </div>
              <div className={classNames(styles.fieldContainer, numberOfMembersError && styles.fieldError)}>
                <div className={styles.fieldLabelWrapper}>
                  <div className={styles.fieldLabel}>Your total number of members to work with</div>
                  <HelpOutLineWithTooltips id={'numberOfMembers'} desc={'Availability for target active '} />
                </div>
                <input
                  type={'tel'}
                  maxLength={4}
                  className={styles.numberOfMembersField}
                  value={numberOfMembers || 0}
                  onChange={handleChangeNumberOfMembers}
                />
                {numberOfMembersError && (
                  <div className={styles.fieldError}>Number of Members field cannot be blank</div>
                )}
              </div>
            </div>
            <div className={styles.fieldBoxWrapper}>
              <div className={styles.labelWrapper}>
                <div className={styles.title}>Working Schedule</div>
                <div className={styles.desc}>Set up your regular schedule</div>
              </div>
              <TimeAvailable
                startTimeHeader={'Set earliest appointment start times'}
                endTimeHeader={'Set latest appointment finish times'}
              />
            </div>
            <ButtonAlt className={styles.submitButtonContainer} fab status={pageSubmitStatus} onClick={submitForm}>
              Save
            </ButtonAlt>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default MentorWorkingSchedule;
