import { Skeleton } from 'antd';
import { FC } from 'react';
import styles from './ClientProfileEpisodeSkeleton.module.scss';

export const ClientProfileEpisodeSkeleton: FC = () => {
  return (
    <div className={styles.container}>
      <Skeleton.Input active className={styles.skeleton} />
    </div>
  );
};
