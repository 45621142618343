import moment from 'moment';

import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { FormListingInterface } from 'interfaces/Form/FormInterface';
import Badge from 'components/Badge/Badge';

import styles from './CardList.module.scss';
import ListCardMenu from '../ListCardMenu/ListCardMenu';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { useTranslation } from 'react-i18next';

interface CardListProp {
  columnId: 'survey' | 'consent' | 'microCheckIn' | 'caseNotes' | 'letters' | 'reports' | 'invoices' | 'carePathway'; // route ==> /forms/:columnId/:id
  data: FormListingInterface[];
  onSetDefault?: (id: string) => Promise<void>;
  loadingId?: string;
  allowEdit?: boolean;
  allowSelect: boolean;
  onDelete?: (id: string) => void;
  onUnpublish?: (id: string) => void;
  circleLoadingId?: string;
}

const participantTypeLabels: { [key: string]: string } = {
  individual: 'Individual client',
  group: 'Group',
  all: 'Both client and group'
};

const CardList = ({
  columnId,
  data,
  onSetDefault,
  loadingId,
  allowEdit,
  allowSelect,
  onDelete,
  onUnpublish,
  circleLoadingId
}: CardListProp) => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();
  const [t] = useTranslation();

  const clickDefault = (e: React.MouseEvent, id: string) => {
    if (!onSetDefault) return;
    e.preventDefault();
    e.stopPropagation();
    onSetDefault(id);
  };

  const onSelectForm = (id: string) => {
    navigate(`${FORMS.BASE}/${columnId}/${id}`);
  };

  return (
    <div className={styles.cardContainer}>
      {data.map((obj, index) => {
        if (obj._id === circleLoadingId) {
          return (
            <div className={styles.cardWrapper} key={index}>
              <div className={styles.loaderContainer}>
                <LoadingCircle />
              </div>
            </div>
          );
        }

        const itemEditable = obj.isEditable === undefined || obj.isEditable;

        return (
          <div className={styles.cardWrapper} key={index}>
            {allowEdit && itemEditable && (onDelete || onUnpublish) && (
              <div className={styles.listCardMenu}>
                <ListCardMenu id={obj._id} onDelete={onDelete} onTogglePublish={onUnpublish} />
              </div>
            )}
            {!itemEditable && (
              <div className={styles.listCardMenu}>
                <i className={`material-icons ${styles.editOffIcon}`}>edit_off</i>
              </div>
            )}

            <div
              className={classnames(styles.container, styles.active, allowSelect && itemEditable && styles.editable)}
              onClick={() => allowSelect && itemEditable && onSelectForm(obj._id)}
            >
              <div className={styles.titleWrapper}>
                <div className={styles.title}>{obj.name}</div>
              </div>
              <div className={styles.dateWrapper}>
                <span className={styles.dateLabel}>Created: </span>
                <span className={styles.date}>{moment(obj.createdAt).format('MMMM DD, yyyy')}</span>
              </div>
              {columnId === 'caseNotes' && obj.participantType && (
                <div className={styles.subContextWrapper}>
                  <span className={styles.subContextLabel}>Assign To: </span>
                  <span className={styles.subContext}>{participantTypeLabels[obj.participantType]}</span>
                </div>
              )}

              <div className={styles.pd2px} />

              {columnId === 'survey' && (
                <div className={styles.badgeWrapper}>
                  {obj.tags?.map((obj, index) => (
                    <Badge className={styles.badge} label={obj} key={index} />
                  ))}
                </div>
              )}
              {allowEdit && onSetDefault && (
                <div className={styles.defaultBtnWrapper}>
                  <i
                    className={`material-icons ${
                      obj.isDefault
                        ? styles.defaultBtnActive
                        : classnames(styles.defaultBtn, loadingId === obj._id && styles.spinBtn)
                    }`}
                    onClick={(e) => clickDefault(e, obj._id)}
                  >
                    grade
                  </i>
                </div>
              )}
              {columnId === 'microCheckIn' && (
                <div className={styles.subscribeWrapper}>
                  <div className={styles.clientWrapper}>
                    {obj.clients.slice(0, 3).map((clientItem: any, index: number) => {
                      return (
                        <div className={styles.avatarWrapper} key={index}>
                          <ClientProfileAvatar avatarUrl={clientItem.avatar} initialsName={clientItem.initials} />
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.clientLabelWrapper}>
                    <span className={styles.clientLabel}>
                      {t('label.active_client', { count: obj.clients.length })}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardList;
