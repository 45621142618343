import { createSlice } from '@reduxjs/toolkit';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { RootState } from 'redux/store';

interface IBroadcastMessage {
  preSelectedClientRecordId: string;
  selectedClients: clientRecordsInterface[];
}

const initialState: IBroadcastMessage = {
  preSelectedClientRecordId: '',
  selectedClients: []
};

export const broadcastMessageSlice = createSlice({
  name: 'broadcastMessageSlice',
  initialState,
  reducers: {
    setPreSelectedClientRecordId: (state, action) => {
      state.preSelectedClientRecordId = action.payload;
    },

    resetPreSelectedClientRecordId: (state) => {
      state.preSelectedClientRecordId = initialState.preSelectedClientRecordId;
    },

    setSelectedClients: (state, action) => {
      state.selectedClients = action.payload;
    }
  }
});

export const selectPreSelectedClientRecordId = (state: RootState) =>
  state.broadcastMessageSlice.preSelectedClientRecordId;
export const selectSelectedClients = (state: RootState) => state.broadcastMessageSlice.selectedClients;

export default broadcastMessageSlice.reducer;

export const { setPreSelectedClientRecordId, resetPreSelectedClientRecordId, setSelectedClients } =
  broadcastMessageSlice.actions;
