import classNames from 'classnames';
import SubTabWrapper from 'components/SideDashboardT23/components/SubTabWrapper/SubTabWrapper';
import { SubTabContext } from 'layouts/AuthLayoutT23/context/SubTabContextProvider';
import { useContext } from 'react';

import styles from './ContentLayoutT23.module.scss';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface ContentLayoutProps {
  children?: any;
  className?: string;
  subTab?: JSX.Element;
  subTabTitle?: string | JSX.Element;
}

const ContentLayout = ({ children, className, subTab, subTabTitle }: ContentLayoutProps) => {
  const { showSubTab, setShowSubTab } = useContext(SubTabContext);
  const { isEdgeAdminView } = useGetAccountPackageView();

  return (
    <>
      {subTab && (
        <div className={classNames('t23-calendar', isEdgeAdminView && 'cal-admin-theme')}>
          <SubTabWrapper
            showSubTab={showSubTab}
            title={subTabTitle}
            setShowSubTab={setShowSubTab}
            className={classNames(styles.subTab)}
          >
            {subTab}
          </SubTabWrapper>
        </div>
      )}
      <div className={classNames(styles.container, className)}>
        <div className={styles.main}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default ContentLayout;
