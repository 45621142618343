import { Divider, Modal, Skeleton, notification } from 'antd';
import classNames from 'classnames';
import ErrorBanner from 'components/ErrorBanner/ErrorBanner';
import ListItemCheckbox from 'components/ListItemCheckbox/ListItemCheckbox';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import Radio from 'components/Radio/Radio';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import Footer from './components/Footer/Footer';
import styles from './ShareReportModal.module.scss';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import {
  useGetAllGeneralPractitionerQuery,
  useGetGeneralPractitionerByIdQuery,
  useUpdateGeneralPractitionerMutation
} from 'redux/endpoints/clinicianProfileServices/generalPractitioner';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import {
  ISendEFaxFormError,
  ISendEFaxFormValue,
  getFaxNumberSendTo,
  getNumberOfRecipients,
  initialError,
  initialValue,
  validate
} from './utils';
import { useGetClinicianProfileQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import ReportContentAsPDF from '../ReportContentAsPDF/ReportContentAsPDF';
import { Report } from 'interfaces/Reports/report';
import { generatePrintableReportPayload } from 'pages/Report/utils';
import { useAppDispatch } from 'redux/hooks';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';
import { postFaxLetter, postFaxReport } from 'utils/http/NotifService/fax';
import { useGetAccessToken } from 'utils/hooks/token';
import { config } from 'config/config';
import { Letter } from 'interfaces/Letters/letter';

interface ShareReportModalProps {
  type: 'letter' | 'report';
  generalPractitionerId?: string;
  isModalOpen: boolean;
  reportId: string;
  reportName: string;
  reportData?: Report;
  letterData?: Letter;
  closeModal: () => void;
  isLoading?: boolean;
  onRefetchReportData?: () => void;
}

const ShareReportModal = ({
  type,
  generalPractitionerId,
  isModalOpen,
  reportId,
  reportName,
  reportData,
  letterData,
  closeModal,
  isLoading,
  onRefetchReportData
}: ShareReportModalProps) => {
  const dispatch = useAppDispatch();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');

  const { data: generalPractitioner, isLoading: isGeneralPractitionerLoading } = useGetGeneralPractitionerByIdQuery(
    { generalPractitionerId: generalPractitionerId || '', accountId },
    { skip: !generalPractitionerId || !accountId }
  );

  const {
    data: generalPractitionerList,
    isLoading: isGeneralPractitionerListLoading,
    isFetching: isGeneralPractitionerListFetching
  } = useGetAllGeneralPractitionerQuery({ accountId }, { skip: !accountId });

  const { data: clinicianProfile, isLoading: isClinicianProfileLoading } = useGetClinicianProfileQuery();

  const [updateGP, { isSuccess: isUpdateGPFaxNumberSuccess, isError: isUpdateGPFaxNumberError }] =
    useUpdateGeneralPractitionerMutation();

  const [isSavingFaxNumber, setIsSavingFaxNumber] = useState(false);
  const [isEditingFaxNumber, setIsEditingFaxNumber] = useState(false);

  const [sendFrom, setSendFrom] = useState<string>('');
  const [formValue, setFormValue] = useState<ISendEFaxFormValue>(initialValue);
  const [referringGPFaxNumber, setReferringGPFaxNumber] = useState<string>(generalPractitioner?.fax || '');
  const [formError, setFormError] = useState<ISendEFaxFormError>(initialError);

  const reportRef = useRef(null);

  useEffect(() => {
    if (generalPractitioner && generalPractitioner.fax) setReferringGPFaxNumber(generalPractitioner.fax);
  }, [generalPractitioner]);

  useEffect(() => {
    if (!isClinicianProfileLoading) {
      const { inboundFaxNumbers } = clinicianProfile?.practice || {};
      if (inboundFaxNumbers && inboundFaxNumbers.length) {
        setSendFrom(inboundFaxNumbers.filter((fax) => fax.isPrimary)?.[0].faxNumber);
      }
    }
  }, [isClinicianProfileLoading, clinicianProfile]);

  useEffect(() => {
    if (isUpdateGPFaxNumberSuccess) {
      notification.success({
        closeIcon: <span className="success">OK</span>,
        duration: 2,
        message: 'GP fax number is updated'
      });
    } else if (isUpdateGPFaxNumberError) {
      notification.error({
        closeIcon: <span className="success">OK</span>,
        duration: 2,
        message: 'Fail to update GP fax number'
      });
    }
  }, [isUpdateGPFaxNumberSuccess, isUpdateGPFaxNumberError]);

  const selectGPDropdownOptions = useMemo(
    () =>
      generalPractitionerList
        ? generalPractitionerList
            .filter((gp) => gp.fax)
            .map((gp) => ({ value: `${gp.name}|${gp.fax}`, label: `${gp.name} — ${gp.fax}` }))
        : [],
    [generalPractitionerList]
  );

  const onChangeFormValue = (fieldName: string, value: string | boolean) => {
    setFormValue({
      ...formValue,
      [fieldName]: value
    });
    if (typeof value === 'boolean' && value && formError.isFaxRecipientError) {
      setFormError({
        ...formError,
        isFaxRecipientError: false
      });
    }
  };

  const onChangeSelectGPFaxNumber = (value: string) => {
    onChangeFormValue('selectGPFaxNumber', value);
    formError.selectGPErrorMessage &&
      setFormError({
        ...formError,
        selectGPErrorMessage: ''
      });
  };

  const onChangeOtherFaxNumbers = (value: string) => {
    const sanitizedValue = value.replace(/[^\d;]+/g, '');
    onChangeFormValue('otherFaxNumbers', sanitizedValue);
    formError.otherFaxNumbersErrorMessage &&
      setFormError({
        ...formError,
        otherFaxNumbersErrorMessage: ''
      });
  };

  const onSubmit = async () => {
    const validationResults = validate(sendFrom, formValue, isEditingFaxNumber);
    setFormError(validationResults);
    if (Object.values(validationResults).some((value) => !!value)) {
      return;
    }
    setSubmitButtonStatus('active');
    const requestPayload = {
      sendFrom,
      sendTo: getFaxNumberSendTo(formValue, referringGPFaxNumber, generalPractitioner?.name || ''),
      sendAt: moment().toISOString(),
      ...(!reportData?.attachment && {
        printPayload: generatePrintableReportPayload(reportRef.current) || ''
      })
    };
    try {
      if (type === 'report') {
        await postFaxReport(accountId, reportId, requestPayload, token);
      } else {
        await postFaxLetter(accountId, reportId, requestPayload, token);
      }
      setSubmitButtonStatus('finished');
      notification.success({
        closeIcon: <span className="success">OK</span>,
        duration: 2,
        message: 'Sent eFax successful.'
      });
      dispatch(documentServicesApiSlice.util.invalidateTags(['Report Details', 'Reports']));
      // Refetch report details for attachment checking
      onRefetchReportData && onRefetchReportData();
      handleCloseModal();
    } catch (ex) {
      notification.error({
        closeIcon: <span className="success">OK</span>,
        duration: 2,
        message: 'Fail to send eFax.'
      });
      setSubmitButtonStatus('');
    }
  };

  const handleCloseModal = () => {
    setFormValue(initialValue);
    setFormError(initialError);
    setIsEditingFaxNumber(false);
    setSubmitButtonStatus('');
    closeModal();
  };

  const handleCancelSaveFaxNumber = () => {
    setIsEditingFaxNumber(false);
    setReferringGPFaxNumber(generalPractitioner?.fax || '');
    setFormError({
      ...initialError,
      referringGPErrorMessage: ''
    });
  };

  const handleSaveFaxNumber = async () => {
    if (!referringGPFaxNumber) {
      setFormError({
        ...initialError,
        referringGPErrorMessage: 'Please fill in the fax number to send fax'
      });
      return;
    }

    const sanitizedValue = referringGPFaxNumber.trim().replace(/\D/g, '').length;

    if (sanitizedValue < config.mobileNumberMinLength || sanitizedValue > config.mobileNumberMaxLength) {
      setFormError({
        ...initialError,
        referringGPErrorMessage: 'Please enter a valid fax number'
      });
      return;
    }
    setIsSavingFaxNumber(true);

    generalPractitioner &&
      updateGP({
        accountId: accountId,
        payload: {
          ...generalPractitioner,
          fax: referringGPFaxNumber
        }
      });

    setIsSavingFaxNumber(false);
    setIsEditingFaxNumber(false);
    setFormError({
      ...initialError,
      referringGPErrorMessage: ''
    });
  };

  const sendFromOptions = useMemo(
    () =>
      clinicianProfile?.practice?.inboundFaxNumbers &&
      clinicianProfile.practice.inboundFaxNumbers.map((fax) => ({
        value: fax.faxNumber || '',
        label: (
          <div>
            {fax.isPrimary && <div className={styles.primary}>PRIMARY NUMBER</div>}
            <div>{fax.faxNumber}</div>
          </div>
        )
      })),
    [clinicianProfile]
  );

  const isDataLoading =
    isLoading || isGeneralPractitionerLoading || isGeneralPractitionerListLoading || isClinicianProfileLoading;

  return (
    <Modal
      className={styles.container}
      footer={
        <Footer
          onSubmit={onSubmit}
          disabled={isDataLoading}
          buttonStatus={submitButtonStatus}
          numberOfRecipients={getNumberOfRecipients(formValue)}
          reportName={reportName}
        />
      }
      onCancel={handleCloseModal}
      open={isModalOpen}
      title={
        <div className={styles.modalHeader}>
          <div className={styles.title}>Send via eFax</div>
          <button className={styles.closeButton} onClick={handleCloseModal}>
            <i className={classNames('material-icons-outlined', styles.icon)}>close</i>
          </button>
        </div>
      }
      width={800}
      closable={false}
    >
      <div className={styles.section}>
        <div className={styles.title}>
          Send eFax to{' '}
          <HelpOutLineWithTooltips
            id={'sendEFaxToTitle'}
            desc={'Please select at least 1 eFax recipient. You can select multiple options below.'}
          />
        </div>
        {formError.isFaxRecipientError && (
          <ErrorBanner errorMessage={<div>At least one fax recipient is required.</div>} />
        )}
        <ListItemCheckbox
          checkboxId="referringGP"
          checkboxLabel="Referring GP Listed"
          checked={formValue.isReferringGPSelected}
          labelTooltip={
            !generalPractitionerId && (
              <HelpOutLineWithTooltips id={'noReferringGPListed'} desc={'This client does not have a listed GP.'} />
            )
          }
          content={
            generalPractitionerId && (
              <div className={styles.referringGP}>
                {isLoading || isGeneralPractitionerLoading ? (
                  <Skeleton.Input active block />
                ) : (
                  <>
                    {generalPractitioner?.name} <Divider type="vertical" className={styles.verticalDivider} />
                    {generalPractitioner?.practiceName} <Divider type="vertical" className={styles.verticalDivider} />
                    <div
                      className={classNames(styles.faxNumber, isEditingFaxNumber && styles.active)}
                      onClick={() => (isEditingFaxNumber ? undefined : setIsEditingFaxNumber(true))}
                    >
                      <div className={classNames('material-icons-outlined', styles.icon)}>print</div>
                      {(!isEditingFaxNumber && referringGPFaxNumber) || (
                        <div className={styles.faxNumberInput}>
                          <MaterialPhoneInput
                            id={'faxNumber'}
                            errorMessage={formError.referringGPErrorMessage}
                            handleCancel={handleCancelSaveFaxNumber}
                            handleSave={handleSaveFaxNumber}
                            isError={!!formError.referringGPErrorMessage}
                            isLoading={isSavingFaxNumber}
                            label="Fax Number"
                            onChange={(value) => setReferringGPFaxNumber(value || '')}
                            value={referringGPFaxNumber}
                            inputClass={
                              formValue.isReferringGPSelected
                                ? isEdgeAdminView
                                  ? styles.phoneInputClassNameAdmin
                                  : styles.phoneInputClassName
                                : ''
                            }
                            hideFlag
                            autoFormat={false}
                            disableCountryCode
                            placeholder=""
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            )
          }
          disabled={!generalPractitionerId || isDataLoading}
          setChecked={(value) => onChangeFormValue('isReferringGPSelected', value)}
        />
        <ListItemCheckbox
          checkboxId="selectGP"
          checkboxLabel="Select GP"
          checked={formValue.isSelectGPSelected}
          disabled={isDataLoading}
          content={
            isGeneralPractitionerListLoading || isGeneralPractitionerListFetching ? (
              <Skeleton.Input active block />
            ) : (
              <MaterialSelect
                id={'selectGP'}
                isSearchable={false}
                label={'Select GP'}
                onChange={(value) => {
                  onChangeSelectGPFaxNumber(value);
                }}
                options={selectGPDropdownOptions}
                value={formValue.selectGPFaxNumber}
                isError={!!formError.selectGPErrorMessage}
                errorMessage={formError.selectGPErrorMessage}
              />
            )
          }
          setChecked={(value) => onChangeFormValue('isSelectGPSelected', value)}
        />
        <ListItemCheckbox
          checkboxId="otherFaxNumbers"
          checkboxLabel="Other Fax Number"
          checked={formValue.isOtherFaxNumbersSelected}
          disabled={isDataLoading}
          content={
            <MaterialInput
              id={'otherFaxNumber'}
              containerClassName={styles.otherFaxNumbersInput}
              className={
                formValue.isOtherFaxNumbersSelected
                  ? isEdgeAdminView
                    ? styles.inputClassNameAdmin
                    : styles.inputClassName
                  : ''
              }
              error={!!formError.otherFaxNumbersErrorMessage}
              errorMessage={formError.otherFaxNumbersErrorMessage}
              helperText="Split multiple numbers with ;"
              label="Enter numbers"
              onChange={(event) => onChangeOtherFaxNumbers(event.target.value)}
              value={formValue.otherFaxNumbers}
            />
          }
          setChecked={(value) => onChangeFormValue('isOtherFaxNumbersSelected', value)}
        />
      </div>
      <div className={classNames(styles.section, isEdgeAdminView && 't23-admin-theme')}>
        <div className={styles.title}>
          Send from{' '}
          <HelpOutLineWithTooltips id={'sendForm'} desc={'If there are multiple eFax numbers, please select one.'} />
        </div>
        {isClinicianProfileLoading ? (
          <Skeleton active />
        ) : sendFromOptions && sendFromOptions.length ? (
          <Radio
            onChange={(e) => setSendFrom(e.target.value)}
            options={sendFromOptions || []}
            radioClassName={styles.sendFromOptions}
            value={sendFrom}
            disabled={isDataLoading}
          />
        ) : (
          <ErrorBanner
            errorMessage={
              <div className={styles.noEFaxSetup}>
                You don’t have eFax set up at the moment. Please contact us to set up eFax.
                <a
                  className={classNames(styles.contactUsLink, isEdgeAdminView && styles.contactUsLinkAdmin)}
                  href="mailto:contact@tacklit.com"
                >
                  Contact us to setup eFax
                  <i className={classNames('material-icons-outlined', styles.icon)}>open_in_new</i>
                </a>
              </div>
            }
            className={classNames(!formError.isUnsupportedBannerHighlighted && styles.unsupported)}
          />
        )}
      </div>
      {clinicianProfile && (reportData || letterData) && (
        <ReportContentAsPDF
          type={type}
          reportDetails={reportData}
          letter={letterData}
          practice={clinicianProfile?.practice}
          ref={reportRef}
        />
      )}
    </Modal>
  );
};

export default ShareReportModal;
