import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './PackageUtilisationActionMenu.module.scss';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import Checkbox from 'components/CheckBox/CheckBox';
import classNames from 'classnames';
import { GetPackageAssignmentList, UtilisationPayloadInterface } from 'interfaces/Packages/packages';
import { ServiceDelivered } from 'interfaces/Schedule/Appointment';
import { useUpdatePackageUtilisationMutation } from 'redux/endpoints/scheduleServices/package';
import { notification } from 'antd';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { setAssignmentUILoading } from 'redux/packages/packageAssigmentListSlice';
import { useDispatch } from 'react-redux';

interface PackageUtilisationActionMenuProps {
  appointmentList: GetPackageAssignmentList['appointmentTypes'];
  clientRecordId: string;
  packageId: string;
  assigneeId: string;
}

const PackageUtilisationActionMenu = ({
  appointmentList,
  clientRecordId,
  packageId,
  assigneeId
}: PackageUtilisationActionMenuProps) => {
  const { accountId } = useGetAccountId();
  const dispatch = useDispatch();
  const menuNode = useRef<HTMLDivElement>(null);
  const [utilisationData, setUtilisationData] = useState(appointmentList);
  const [menuExpand, setMenuExpand] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');

  const [updateUtilisation] = useUpdatePackageUtilisationMutation();

  const handleExpandMenu = () => {
    setMenuExpand(!menuExpand);
  };

  const handleClick = (e: MouseEvent) => {
    if (menuNode.current?.contains(e.target as HTMLElement)) {
      return;
    }
    setMenuExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  useEffect(() => {
    setUtilisationData(appointmentList);
  }, [appointmentList]);

  const handleCheck = useCallback(
    (idx: number) => {
      const updatedData = utilisationData.map((item, index) => {
        if (index === idx) {
          return {
            ...item,
            isUtilised: !item.isUtilised
          };
        }
        return item;
      });
      setUtilisationData(updatedData);
    },
    [utilisationData]
  );

  const handleSave = useCallback(async () => {
    setSubmitStatus('active');
    const massagePayload: UtilisationPayloadInterface[] = utilisationData
      .filter((utilisationObj) => utilisationObj.isUtilised)
      .map((utilObj) => ({
        appointmentTypeId: utilObj._id || '',
        ...(utilObj.bookedAppointment?._id && {
          appointmentId: utilObj.bookedAppointment._id
        })
      }));

    try {
      await updateUtilisation({
        accountId,
        clientRecordId,
        packageId,
        assigneeId,
        payload: massagePayload
      }).unwrap();
      dispatch(setAssignmentUILoading(true));
      setSubmitStatus('finished');

      setTimeout(() => {
        setMenuExpand(false);
        setSubmitStatus('');
      }, 1000);
    } catch (ex) {
      console.error(ex);
      setSubmitStatus('');
      notification.error({
        message: 'Something went wrong while updating utilisation.'
      });
    }
  }, [utilisationData, accountId, clientRecordId, packageId, assigneeId, updateUtilisation, dispatch]);

  return (
    <>
      <div ref={menuNode} className={styles.container}>
        <div className={styles.buttonWrapper}>
          <ButtonAlt
            className={styles.editBtn}
            variant={'text'}
            size={'small'}
            onClick={handleExpandMenu}
            icon={'edit'}
            fab
          />
        </div>
        <div className={styles.menuWrapper}>
          <div className={menuExpand ? styles.menuShow : styles.menuHide}>
            <div className={styles.content}>
              <div className={styles.contentLabel}>USED?</div>
              <div className={styles.checkBoxContainer}>
                {utilisationData.map((appointmentObj, idx) => (
                  <div key={idx} onClick={() => handleCheck(idx)} className={styles.checkBoxWrapper}>
                    <div className={styles.checkBoxInfo}>
                      <Checkbox
                        id={`${appointmentObj._id}-${idx}`}
                        value={appointmentObj.isUtilised || false}
                        onChange={() => handleCheck(idx)}
                        useT23style
                      />
                      <div className={classNames(styles.label, appointmentObj.isUtilised && styles.strike)}>
                        {appointmentObj.name}
                      </div>
                    </div>
                    {appointmentObj?.bookedAppointment?.serviceDelivered === ServiceDelivered.Attended ||
                      (appointmentObj.bookedAppointment?.markedStatus?.some(
                        (statusObj) => statusObj === 'Attended'
                      ) && (
                        <div className={styles.attendedBadge}>
                          <div>Attended</div>
                          <i className={`material-icons-outlined ${styles.attendIcon}`}>playlist_add_check</i>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
              <div className={styles.saveListBtnWrapper}>
                <ButtonAlt status={submitStatus} onClick={handleSave} className={styles.saveListBtn} size={'small'}>
                  Save
                </ButtonAlt>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageUtilisationActionMenu;
