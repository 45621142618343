import DropdownSearchable, { DropdownVariant } from 'components/v2/DropdownSearchable/DropdownSearchable';
import { EpisodeStatus } from 'interfaces/Episodes/episodes';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetCurrentActiveEpisodeQuery,
  useGetEpisodeListQuery
} from 'redux/endpoints/clinicianProfileServices/episode';
import {
  selectSelectedEpisodeDropdownValue,
  setCurrentEpisodeId,
  setIsFetchingEpisodes,
  setIsOutsideAllEpisodes,
  setIsShowAllData,
  setSelectedEpisodeDropdownValue,
  setCurrentEpisode
} from 'redux/episodes/episodeSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { generateClientProfileDropdownOptions } from '../../utils/generateClientProfileDropdownOptions';
import {
  ClientProfileEpisodeDropdownItem,
  EpisodeDropdownType
} from '../ClientProfileEpisodeDropdownItem/ClientProfileEpisodeDropdownItem';
import { ClientProfileEpisodeSkeleton } from '../ClientProfileEpisodeSkeleton/ClientProfileEpisodeSkeleton';
import styles from './ClientProfileEpisodeDropdown.module.scss';
import { EPISODE_PER_PAGE } from 'pages/PatientDetails/components/PatientDetailsContent/components/ClientDetailsEoC/interface/constants';

interface ClientProfileEpisodeProps {
  clientRecordId: string;
  isClientRecordLoading: boolean;
  onChangeEpisode: () => void;
}

export const ClientProfileEpisodeDropdown: FC<ClientProfileEpisodeProps> = ({
  clientRecordId,
  isClientRecordLoading,
  onChangeEpisode
}) => {
  const dispatch = useAppDispatch();
  const { accountTimeZone } = useTimeZone();
  const selectedEpisodeDropdownValue = useAppSelector(selectSelectedEpisodeDropdownValue);

  const [episodeListCurrentPage, setEpisodeListCurrentPage] = useState<number>(1);
  const [clientRecordIdCache, setClientRecordIdCache] = useState(clientRecordId);

  useEffect(() => {
    // reason to have this because of the client sub sidebar have no page change during navigation between different client
    setEpisodeListCurrentPage(1);
    setClientRecordIdCache(clientRecordId);
  }, [clientRecordId]);

  const { data: activeEpisode, isFetching: isFetchingActiveEpisode } = useGetCurrentActiveEpisodeQuery(
    {
      clientRecordId,
      timezone: accountTimeZone
    },
    { skip: !clientRecordId }
  );

  const {
    data: episodeListResponse,
    isLoading: isLoadingEpisodes,
    isFetching: isFetchingEpisodes
  } = useGetEpisodeListQuery(
    {
      clientRecordId,
      timezone: accountTimeZone,
      params: {
        page: episodeListCurrentPage,
        perPage: EPISODE_PER_PAGE,
        status: [EpisodeStatus.Finished, EpisodeStatus.Cancelled].join(',')
      }
    },
    { skip: !clientRecordId || clientRecordIdCache !== clientRecordId }
  );

  const totalEpisodeListPage = episodeListResponse?.paging
    ? Math.ceil(episodeListResponse.paging.totalItems / EPISODE_PER_PAGE)
    : 1;

  const loadMoreEpisodeItem = useCallback(() => {
    const page = episodeListResponse?.paging.page || 1;
    if (!isFetchingEpisodes && page < totalEpisodeListPage) {
      setEpisodeListCurrentPage(page + 1);
    }
  }, [totalEpisodeListPage, isFetchingEpisodes, episodeListResponse]);

  const handleSelectOption = useCallback(
    (value: string) => {
      if (value === EpisodeDropdownType.OUTSIDE_ALL_EPISODES) {
        dispatch(setIsOutsideAllEpisodes(true));
        dispatch(setSelectedEpisodeDropdownValue(EpisodeDropdownType.OUTSIDE_ALL_EPISODES));
        return;
      }

      if (value === EpisodeDropdownType.SHOW_ALL_DATA) {
        dispatch(setIsShowAllData(true));
        dispatch(setSelectedEpisodeDropdownValue(EpisodeDropdownType.SHOW_ALL_DATA));
        return;
      }

      const foundEpisode = [activeEpisode, ...(episodeListResponse?.episodes || [])].find((ep) => ep?._id === value);
      dispatch(setCurrentEpisodeId(value));
      dispatch(setCurrentEpisode(foundEpisode));
      dispatch(setSelectedEpisodeDropdownValue(value));
      onChangeEpisode();
    },
    [activeEpisode, dispatch, episodeListResponse, onChangeEpisode]
  );

  const options = useMemo(
    () => generateClientProfileDropdownOptions({ activeEpisode, episodes: episodeListResponse?.episodes }),
    [activeEpisode, episodeListResponse]
  );

  useEffect(() => {
    if (isFetchingActiveEpisode || isFetchingEpisodes) {
      dispatch(setIsFetchingEpisodes(true));
    } else {
      dispatch(setIsFetchingEpisodes(false));
    }
  }, [dispatch, isFetchingActiveEpisode, isFetchingEpisodes]);

  useEffect(() => {
    handleSelectOption(options.length > 0 ? options[0].value : EpisodeDropdownType.SHOW_ALL_DATA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return isClientRecordLoading ? (
    <ClientProfileEpisodeSkeleton />
  ) : (
    <DropdownSearchable
      className={styles.container}
      placeholder="Select episode"
      onSelect={handleSelectOption}
      selected={selectedEpisodeDropdownValue}
      options={options}
      hasMoreData={
        (episodeListResponse?.episodes.length || 0) < (episodeListResponse?.paging.totalItems || 0) ||
        isFetchingEpisodes ||
        isLoadingEpisodes
      }
      loadMore={loadMoreEpisodeItem}
      variant={DropdownVariant.box}
      hideErrorDesc
      hideActiveLine
      DropdownItemChildren={(itemProps) => (
        <ClientProfileEpisodeDropdownItem episode={itemProps.props} value={itemProps.props.value} />
      )}
      DisplayLabelChildren={(itemProps) => (
        <ClientProfileEpisodeDropdownItem episode={itemProps.props} supportCurrentLabel value={itemProps.props.value} />
      )}
    />
  );
};
