import styles from './PinnedNoteFlags.module.scss';
import { Tooltip } from 'react-tooltip';
import { ClientRecordPinnedNote, PinType } from 'interfaces/Clients/clientsRecord';
import classNames from 'classnames';
import { Fragment } from 'react';

interface PinnedNoteFlagsProps {
  clientRecordId?: string;
  pinnedNotes: ClientRecordPinnedNote[];
  hideTooltip?: boolean;
}

const PinnedNoteFlags = ({ clientRecordId, pinnedNotes, hideTooltip }: PinnedNoteFlagsProps) => {
  const noteTypes = [
    { type: PinType.Important, icon: 'notification_important', style: styles.importantIcon },
    { type: PinType.Risk, icon: 'flag_circle', style: styles.riskIcon }
  ];

  const getPinnedTooltip = (pinType: PinType, notes: ClientRecordPinnedNote[]) => (
    <Tooltip id={`${clientRecordId}${pinType}-flag`} className={styles.tooltip}>
      <div className={styles.tooltipContent}>
        {notes
          .filter((note) => note.pinType === pinType)
          .reverse()
          .map((note, index) => (
            <div
              key={index}
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: `${note.title}${note.title.endsWith('.') ? '' : '.'} ${note.body}`
              }}
            />
          ))}
      </div>
    </Tooltip>
  );

  return (
    <div className={styles.container}>
      {noteTypes.map(
        ({ type, icon, style }) =>
          pinnedNotes.some((note) => note.pinType === type) && (
            <Fragment key={`${clientRecordId}-${type}`}>
              <i
                data-tooltip-id={`${clientRecordId}${type}-flag`}
                className={`material-icons-outlined ${classNames(
                  style,
                  styles.flagIcon,
                  !hideTooltip && styles.showOnHover
                )}`}
              >
                {icon}
              </i>
              {!hideTooltip && getPinnedTooltip(type, pinnedNotes)}
            </Fragment>
          )
      )}
    </div>
  );
};

export default PinnedNoteFlags;
