import { useEffect, useState } from 'react';
import styles from './AttendanceLogList.module.scss';
import columnStyles from './AttendanceLogListColumn.module.scss';
import classnames from 'classnames';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { notification, Skeleton } from 'antd';
import SortBtn, { SortType } from 'components/TableList/SortBtn/SortBtn';
import { combineName, getName } from 'utils/general';
import ProfileTypeBadge from 'components/ProfileTypeBadge/ProfileTypeBadge';
import AttendanceCheckbox from './components/AttendanceCheckbox/AttendanceCheckbox';
import { GroupSessionAttendee } from 'pages/Groups/Interfaces/GroupsSession';
import { useGetAccessToken } from 'utils/hooks/token';
import { putGroupSessionDetails } from 'utils/http/ScheduleService/Groups/groups';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface AttendanceLogListProps {
  groupId: string;
  sessionId: string;
  sessionClientRecord: GroupSessionAttendee[];
  isAttendanceListLoading: boolean;
}

interface AttendanceList {
  clientRecords: GroupSessionAttendee[];
  activeSort: 'clientNameAsc' | 'clientNameDesc' | 'profileTypeAsc' | 'profileTypeDesc';
}

const sortClient = (list: GroupSessionAttendee[], sortAsc: boolean) => {
  return list.sort((a, b) => {
    const clientA = getName(a.clientRecord.clientProfiles[0]);
    const clientB = getName(b.clientRecord.clientProfiles[0]);
    return sortAsc ? clientA.localeCompare(clientB) : clientB.localeCompare(clientA);
  });
};

const sortRecordType = (list: GroupSessionAttendee[], sortAsc: boolean) => {
  return list.sort((a, b) => {
    const clientA = a.clientRecord.recordType;
    const clientB = b.clientRecord.recordType;
    return sortAsc ? clientA.localeCompare(clientB) : clientB.localeCompare(clientA);
  });
};

const AttendanceLogList = ({
  groupId,
  sessionId,
  sessionClientRecord,
  isAttendanceListLoading
}: AttendanceLogListProps) => {
  const { token } = useGetAccessToken();
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const [attendanceList, setAttendanceList] = useState<AttendanceList>({
    clientRecords: sessionClientRecord,
    activeSort: 'clientNameAsc'
  });

  useEffect(() => {
    setAttendanceList({
      clientRecords: sortClient(sessionClientRecord, true),
      activeSort: 'clientNameAsc'
    });
  }, [sessionClientRecord]);

  const handleChangeNameSort = (sortAsc: SortType) => {
    const massageList: AttendanceList = {
      clientRecords: sortClient(attendanceList.clientRecords, sortAsc === SortType.asc),
      activeSort: sortAsc === SortType.asc ? 'clientNameAsc' : 'clientNameDesc'
    };
    setAttendanceList(massageList);
  };

  const handleChangeProfileSort = (sortAsc: SortType) => {
    const massageList: AttendanceList = {
      clientRecords: sortRecordType(attendanceList.clientRecords, sortAsc === SortType.asc),
      activeSort: sortAsc === SortType.asc ? 'profileTypeAsc' : 'profileTypeDesc'
    };
    setAttendanceList(massageList);
  };

  const handleChangeAttendance = (recordId: string, isAttend: boolean | undefined, reason?: string) => {
    let newClientRecordWithAttendedList = [];
    for (let data of attendanceList.clientRecords) {
      newClientRecordWithAttendedList.push({
        ...data,
        attended: data.clientRecordId === recordId ? isAttend : data.attended,
        reasonForNonAttendance: data.clientRecordId === recordId ? reason : data.reasonForNonAttendance
      });
    }
    const massageList: AttendanceList = {
      clientRecords: newClientRecordWithAttendedList,
      activeSort: attendanceList.activeSort
    };
    setAttendanceList(massageList);
  };

  const onSaveAttendance = async () => {
    setSubmitStatus('active');
    try {
      const payload = {
        attendees: attendanceList.clientRecords
      };
      await putGroupSessionDetails(token, groupId, sessionId, payload);
      notification.success({
        message: 'Attendance log saved',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setSubmitStatus('finished');
      setTimeout(() => {
        setSubmitStatus('');
      }, 1000);
    } catch (ex) {
      console.error(ex);
      setSubmitStatus('');
      notification.error({
        message: 'Attendance log fail to save',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>ATTENDANCE LOG</div>
        {attendanceList.clientRecords.length > 0 && (
          <ButtonAlt status={submitStatus} onClick={() => onSaveAttendance()}>
            Save Attendance
          </ButtonAlt>
        )}
      </div>
      <div className={classnames(columnStyles.columnWrapper, styles.listHeader)}>
        <div className={columnStyles.avatar}>AVATAR</div>
        <div
          className={classnames(
            columnStyles.fullName,
            (attendanceList.activeSort === 'clientNameAsc' || attendanceList.activeSort === 'clientNameDesc') &&
              columnStyles.sortActive
          )}
        >
          FULL NAME
          <SortBtn
            id={'clientNameSort'}
            selectedSortName={
              attendanceList.activeSort === 'clientNameAsc'
                ? SortType.asc
                : attendanceList.activeSort === 'clientNameDesc'
                ? SortType.desc
                : SortType.noSort
            }
            onChangeSort={handleChangeNameSort}
            disabled={isAttendanceListLoading}
          />
        </div>
        <div
          className={classnames(
            columnStyles.profileType,
            (attendanceList.activeSort === 'profileTypeAsc' || attendanceList.activeSort === 'profileTypeDesc') &&
              columnStyles.sortActive
          )}
        >
          PROFILE TYPE
          <SortBtn
            id={'recordTypeSort'}
            selectedSortName={
              attendanceList.activeSort === 'profileTypeAsc'
                ? SortType.asc
                : attendanceList.activeSort === 'profileTypeDesc'
                ? SortType.desc
                : SortType.noSort
            }
            onChangeSort={handleChangeProfileSort}
            disabled={isAttendanceListLoading}
          />
        </div>
        <div className={columnStyles.invited}>INVITED?</div>
        <div className={columnStyles.attended}>ATTENDED?</div>
      </div>
      {isAttendanceListLoading ? (
        <>
          {[...Array(10)].map((obj, i) => (
            <div key={i} className={classnames(styles.loadingWrapper, styles.listItem)}>
              <Skeleton.Input active className={styles.loading} />
            </div>
          ))}
        </>
      ) : attendanceList.clientRecords.length > 0 ? (
        <>
          {attendanceList.clientRecords.map((clientRecordObj, index) => {
            return (
              <div key={index} className={classnames(columnStyles.columnWrapper, styles.listItem)}>
                <div className={columnStyles.avatar}>
                  <ClientAvatar
                    clientData={clientRecordObj.clientRecord.clientProfiles}
                    displayLimit={clientRecordObj.clientRecord.recordType === 'couple' ? 2 : 1}
                    fullAvatarBg
                    hideName
                  />
                </div>
                <div className={classnames(columnStyles.fullName, styles.name)}>
                  {combineName(clientRecordObj.clientRecord.clientProfiles)}
                </div>
                <div className={columnStyles.profileType}>
                  <ProfileTypeBadge
                    clientProfilesData={clientRecordObj.clientRecord.clientProfiles}
                    recordType={clientRecordObj.clientRecord.recordType}
                    isShowInvitationAction={false}
                    isInvitationProcessing={false}
                    canBeFullProfile={false}
                  />
                </div>
                <div className={columnStyles.invited}>
                  {clientRecordObj.invited !== undefined &&
                    (clientRecordObj.invited ? (
                      <div className={styles.invitedCard}>
                        <i className={`material-icons ${styles.checkIcon}`}>done</i>
                        YES
                      </div>
                    ) : (
                      <div className={styles.invitedCard}>NO</div>
                    ))}
                </div>
                <div className={columnStyles.attended}>
                  <AttendanceCheckbox
                    clientRecordId={clientRecordObj.clientRecord._id}
                    attended={clientRecordObj.attended}
                    reasonForNonAttendance={clientRecordObj.reasonForNonAttendance}
                    onChangeAttendance={handleChangeAttendance}
                    disabled={clientRecordObj.invited === undefined}
                  />
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className={styles.noMemberFoundContainer}>No attendance found</div>
      )}
    </div>
  );
};

export default AttendanceLogList;
