import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { CronofyCalendar } from 'interfaces/Cronofy/cronofyProfile';
import { useEffect, useState } from 'react';

import styles from './ConnectedCalendar.module.scss';

interface ConnectedCalendarProps {
  cronofyCalendar: CronofyCalendar;
  handleCalendarChange: (calendar: CronofyCalendar) => void;
}

const ConnectedCalendar = ({ cronofyCalendar, handleCalendarChange }: ConnectedCalendarProps) => {
  const [calendarPayload, setCalendarPayload] = useState(cronofyCalendar);

  useEffect(() => {
    setCalendarPayload(cronofyCalendar);
  }, [cronofyCalendar]);

  const handleCalendarConfigurationChange = (type: string) => {
    const payload = {
      ...calendarPayload,
      configuration: {
        ...calendarPayload.configuration,
        [type]: !calendarPayload.configuration[type]
      }
    };
    setCalendarPayload(payload);
    handleCalendarChange(payload);
  };

  return (
    <div className={styles.container}>
      <div className={styles.connectSetting}>
        <div className={styles.connectSettingCalendarName}>
          <label className={styles.connectSettingCheckbox}>
            <input
              checked={!calendarPayload.configuration.disable}
              className={styles.checkbox}
              type="checkbox"
              onChange={() => handleCalendarConfigurationChange('disable')}
            />
            <span className={styles.connectSettingLabel}>{calendarPayload.name}</span>
          </label>
        </div>
        <div
          className={
            calendarPayload.configuration.disable ? styles.disabledConnectSettingItem : styles.connectSettingItem
          }
        >
          <label className={styles.connectSettingCheckbox}>
            <input
              disabled={calendarPayload.configuration.disable}
              checked={calendarPayload.configuration.push}
              className={styles.checkbox}
              type="checkbox"
              onChange={() => handleCalendarConfigurationChange('push')}
            />
            <span className={styles.connectSettingLabel}>{'Push appointment details'}</span>
          </label>
        </div>
        <div
          className={
            calendarPayload.configuration.disable ? styles.disabledConnectSettingItem : styles.connectSettingItem
          }
        >
          <label className={styles.connectSettingCheckbox}>
            <input
              disabled={calendarPayload.configuration.disable}
              checked={calendarPayload.configuration.pull}
              className={styles.checkbox}
              type="checkbox"
              onChange={() => handleCalendarConfigurationChange('pull')}
            />
            <span className={styles.connectSettingLabel}>{'Read free / busy status'}</span>
          </label>
        </div>
      </div>
      <div className={styles.connectStatus}>
        <ToggleSwitch
          id={calendarPayload.id}
          checkLabel={'ACTIVE'}
          unCheckLabel={'PAUSED'}
          buttonWidth={188}
          isCheckedProps={calendarPayload.configuration.active}
          onChangeProps={() => handleCalendarConfigurationChange('active')}
          disabled={calendarPayload.configuration.disable}
        />
      </div>
    </div>
  );
};

export default ConnectedCalendar;
