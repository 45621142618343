import TimeZoneLabel from 'components/TimeZoneLabel/TimeZoneLabel';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  selectCurrentEpisodeId,
  selectIsFetchingEpisodes,
  selectIsOutsideAllEpisodes,
  selectIsShowAllData
} from 'redux/episodes/episodeSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import AppointmentSummary from './components/AppointmentSummary/AppointmentSummary';
import CompletedCard from './components/CompletedCard/CompletedCard';
import UpComingCard from './components/UpComingCard/UpComingCard';
import styles from './PatientDetailsAppointments.module.scss';
import { massageClientAppointments } from './utils';
import { getClinicianAppointmentStatusOverviewConfigFromLocalStorage } from './utils/appointmentStatusOverviewConfigLocalStorage';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { DataRefreshHandle } from '../../PatientDetailsContent';
import { setIsRefreshDataDisabled } from 'redux/clients/clientDetailsSlice';
import { useGetClientAppointmentsByDateRangeQuery } from 'redux/endpoints/scheduleServices/appointment';
import moment from 'moment';
import { MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';

interface PatientDetailsAppointmentsProps {
  recordId: string;
  profile: ProfileInterface;
  clientRecordData: clientRecordsInterface;
  isProfileLoading: boolean;
}

const PatientDetailsAppointments = forwardRef<DataRefreshHandle, PatientDetailsAppointmentsProps>(
  ({ recordId, profile, clientRecordData, isProfileLoading }, ref) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const node = useRef<HTMLDivElement>(null);
    const [showFilter, setShowFilter] = useState(false);
    const { isEoCEnabled } = useGetFeatureToggle();
    const [filter, setFilter] = useState(['upcoming', 'past']);

    const { auth0ClinicianId } = useGetClinicianId();

    const appointmentStatusOverviewConfigFromLocalStorage = useMemo(
      () => getClinicianAppointmentStatusOverviewConfigFromLocalStorage(auth0ClinicianId),
      [auth0ClinicianId]
    );

    const handleClick = (e: any) => {
      if (node.current?.contains(e.target)) {
        return;
      }
      openFilterList(false);
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClick);

      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    });

    const openFilterList = (val: boolean) => {
      setShowFilter(val);
    };

    const { timeZoneByView } = useTimeZone();

    const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
    const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
    const isShowAllData = useAppSelector(selectIsShowAllData);
    const isFetchingEpisodes = useAppSelector(selectIsFetchingEpisodes);

    const {
      data: appointments,
      isFetching: isAppointmentsFetching,
      isLoading: isAppointmentsLoading,
      isError,
      refetch: fetchAppointments
    } = useGetClientAppointmentsByDateRangeQuery(
      {
        clientRecordId: recordId,
        showCreatedBy: true,
        showHistories: true,
        showDeletedAppointments: true,
        ...((!isEoCEnabled || isShowAllData) && {
          from: '2020-01-01',
          to: moment(new Date()).add(1, 'years').format(MOMENTJS_YEAR_MONTH_DAY_FORMAT)
        }),
        ...(isEoCEnabled && {
          ...(currentEpisodeId && { episodeId: currentEpisodeId }),
          ...(isOutsideAllEpisodes && { showOutsideOfEpisode: isOutsideAllEpisodes })
        }),
        timeZone: timeZoneByView
      },
      {
        skip: isEoCEnabled && (isFetchingEpisodes || (!isOutsideAllEpisodes && !isShowAllData && !currentEpisodeId))
      }
    );

    useImperativeHandle(ref, () => ({
      onDataRefresh: () => fetchAppointments()
    }));

    useEffect(() => {
      dispatch(setIsRefreshDataDisabled(isAppointmentsFetching || isAppointmentsLoading));
    }, [isAppointmentsFetching, isAppointmentsLoading, dispatch]);

    useEffect(() => {
      if (location.search === '?refetch') {
        fetchAppointments();
        navigate(location.pathname);
      }
    }, [location.search, location.pathname, fetchAppointments, navigate]);

    const massagedPatientAppointments = useMemo(
      () => massageClientAppointments((!isError && appointments) || []),
      [appointments, isError]
    );

    const practiceName = profile.practice?.name || '';
    const isLoading = isAppointmentsFetching || isAppointmentsLoading || isProfileLoading;

    return (
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <AppointmentSummary
            clientRecordId={clientRecordData._id}
            clientAppointment={(!isError && appointments) || []}
            appointmentStatusOverviewConfiguration={{
              ...clientRecordData.appointmentStatusOverviewConfiguration,
              displayTagCount:
                appointmentStatusOverviewConfigFromLocalStorage?.displayTagCount ||
                clientRecordData.appointmentStatusOverviewConfiguration.displayTagCount
            }}
            upcomingAppointmentsTotal={massagedPatientAppointments.upcomingAppointmentsTotal}
            pastAppointmentsTotal={massagedPatientAppointments.pastAppointmentsTotal}
            isLoading={isLoading}
          />
          <div className={styles.titleContainer}>
            <div>
              {/* Temporarily hide this filter until we properly implement it. */}
              {/* <div className={styles.title}>Appointment Overview</div>
            <div className={styles.filterWrapper}>
              <div>Filter</div>
              <div className={styles.filter} onClick={() => openFilterList(!showFilter)}>
                All
                <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
              </div>
            </div> */}
            </div>
            <TimeZoneLabel />
          </div>
          <div ref={node}>
            <div className={styles.dropDown}>
              <div className={showFilter ? styles.dropDownMenuShow : styles.dropDownMenuHide}>
                <div
                  className={styles.listBox}
                  onClick={() => {
                    setFilter(['upcoming', 'past']);
                    setShowFilter(false);
                  }}
                >
                  All
                </div>
                <div
                  className={styles.listBox}
                  onClick={() => {
                    setFilter(['upcoming']);
                    setShowFilter(false);
                  }}
                >
                  Upcoming only
                </div>
                <div
                  className={styles.listBox}
                  onClick={() => {
                    setFilter(['past']);
                    setShowFilter(false);
                  }}
                >
                  Past only
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contentContainer}>
          {filter.includes('upcoming') && (
            <UpComingCard
              total={massagedPatientAppointments.upcomingAppointmentsTotal}
              data={massagedPatientAppointments.upcomingAppointments}
              practiceName={practiceName}
              isLoading={isLoading}
              refetchAppointment={fetchAppointments}
            />
          )}
          {filter.includes('upcoming') && filter.includes('past') && <div className={styles.divider} />}
          {filter.includes('past') && (
            <CompletedCard
              total={massagedPatientAppointments.pastAppointmentsTotal}
              data={massagedPatientAppointments.completedAppointments}
              practiceName={practiceName}
              isLoading={isLoading}
              refetchAppointment={fetchAppointments}
            />
          )}
        </div>
      </div>
    );
  }
);
export default PatientDetailsAppointments;
