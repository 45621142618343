import styles from './PackageStatusBadge.module.scss';
import { PACKAGES_STATUS_LABELS, PackagesStatus } from 'interfaces/Packages/packages';

export interface PackageStatusBadgeProps {
  status: PackagesStatus;
  isDisable?: boolean;
}

const PackageStatusBadge = ({ status, isDisable }: PackageStatusBadgeProps) => (
  <div className={styles[isDisable ? 'disable' : status]}>
    <div>{PACKAGES_STATUS_LABELS[status]?.toUpperCase()}</div>
  </div>
);

export default PackageStatusBadge;
