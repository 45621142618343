import { useState } from 'react';
import { clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import styles from './ProfileTypeBadge.module.scss';
import moment, { Moment } from 'moment';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

export const SIGNUP_INVITATION_SEND_AT_FORMAT = 'YYYY-MM-DD hh:mmA Z';

interface ProfileTypeBadgeProps {
  recordType: clientRecordsInterface['recordType'];
  isShowInvitationAction?: boolean;
  isInvitationProcessing: boolean;
  onResendInvitation?: () => void;
  onCancelInvitation?: () => void;
  clientProfilesData: clientProfilesInterface[];
  isNotFullSignUp?: boolean;
  canBeFullProfile: boolean;
}

const ProfileTypeBadge = ({
  recordType,
  isShowInvitationAction = true,
  isInvitationProcessing,
  onResendInvitation,
  onCancelInvitation,
  clientProfilesData,
  isNotFullSignUp,
  canBeFullProfile
}: ProfileTypeBadgeProps) => {
  const [showMenu, setShowMenu] = useState(false);

  const getAgoTime = (diffDateTime: Moment) => {
    const currentDateTime = moment();
    let dateTime = '';
    if (currentDateTime.diff(diffDateTime, 'minutes') <= 60) {
      dateTime = `${currentDateTime.diff(diffDateTime, 'minute')} min`;
    } else if (currentDateTime.diff(diffDateTime, 'hours') <= 24) {
      dateTime = `${currentDateTime.diff(diffDateTime, 'hour')} hr`;
    } else {
      dateTime = `${currentDateTime.diff(diffDateTime, 'day')} days`;
    }
    return dateTime;
  };

  const getSendInviteTime = (diffDateTime: Moment) => {
    const currentDateTime = moment();
    let dateTime = '';
    if (diffDateTime.diff(currentDateTime, 'minutes') >= 0 && diffDateTime.diff(currentDateTime, 'minutes') <= 60) {
      dateTime = `Invite send in ${diffDateTime.diff(currentDateTime, 'minute')} min`;
    } else if (diffDateTime.diff(currentDateTime, 'hours') >= 0 && diffDateTime.diff(currentDateTime, 'hours') <= 24) {
      dateTime = `Invite send in ${diffDateTime.diff(currentDateTime, 'hour')} hr`;
    } else if (diffDateTime.diff(currentDateTime, 'day') >= 0 && diffDateTime.diff(currentDateTime, 'day') <= 2) {
      dateTime = `Invite send in ${diffDateTime.diff(currentDateTime, 'day')} days`;
    } else {
      dateTime = `Invite send at ${moment(diffDateTime).format('DD MMM YYYY hh:mmA')}`;
    }

    return dateTime;
  };

  const profileType = clientProfilesData ? clientProfilesData[0].profileType : 'full';
  const signUpInvitation = clientProfilesData ? clientProfilesData[0].signupInvitation?.signupDate : [];

  const isSignUpInvitationSentAt = clientProfilesData ? clientProfilesData[0].signupInvitation?.sendAt : [];

  const isSignUpInvitationSent = clientProfilesData ? clientProfilesData[0].signupInvitation?.isSent : [];

  const handleCloseMenu = () => {
    setShowMenu(false);
    document.removeEventListener('click', handleCloseMenu);
  };

  const handleSelectButtonClick = (e: any) => {
    e.stopPropagation();
    if (showMenu) {
      handleCloseMenu();
    } else {
      document.addEventListener('click', handleCloseMenu);
    }

    setShowMenu(!showMenu);
  };

  const handleResend = (e: any) => {
    e.stopPropagation();
    if (isInvitationProcessing) {
      return;
    }
    if (onResendInvitation !== undefined) onResendInvitation();
  };

  const handleCancel = (e: any) => {
    e.stopPropagation();
    if (isInvitationProcessing) {
      return;
    }
    if (onCancelInvitation !== undefined) onCancelInvitation();
  };

  const isRecordOnlyActionExists =
    profileType === 'recordOnly' && recordType !== 'child' && !signUpInvitation && isShowInvitationAction;

  const generateType = (profileType: clientProfilesInterface['profileType']) => {
    switch (profileType) {
      case 'full':
        switch (recordType) {
          case 'couple':
            return {
              label: 'COUPLE PROFILE',
              badgeStyles: isNotFullSignUp ? styles.fullInviteContainer : styles.fullCoupleContainer
            };
          case 'adult':
            return {
              label: 'ADULT PROFILE',
              badgeStyles: isNotFullSignUp ? styles.fullInviteContainer : styles.fullContainer
            };
          case 'youngPerson':
            return {
              label: 'YOUNG PROFILE',
              badgeStyles: isNotFullSignUp ? styles.fullYoungInviteContainer : styles.fullYoungContainer
            };
          default:
            console.error('Something went wrong while trying to generate profile type badge');
            return {
              label: '',
              badgeStyles: ''
            };
        }
      case 'recordOnly':
      case 'prospect':
        switch (recordType) {
          case 'couple':
            return {
              label: 'COUPLE RECORD',
              badgeStyles: styles.recordCoupleContainer
            };
          case 'child':
            return {
              label: 'CHILD RECORD',
              badgeStyles: styles.recordChildContainer
            };
          case 'adult':
            return {
              label: 'ADULT RECORD',
              badgeStyles: styles.recordContainer
            };
          case 'youngPerson':
            return {
              label: 'YOUNG RECORD',
              badgeStyles: styles.recordYoungContainer
            };
          default:
            console.error('Something went wrong while trying to generate profile type badge');
            return {
              label: '',
              badgeStyles: ''
            };
        }
      /**
       * @deprecated profile type only `full` and `recordOnly`.
       *
       * Temperary keep this for safety.
       * TAG: #prospect
       * TODO: Need to remove `prospect` after done migration
       * from `prospect` to `recordOnly` in `profileType`. OR after fully tested
       * without error.
       */
      default:
        console.error('Something went wrong while trying to fetch your appointment requests');
        return {
          label: '',
          badgeStyles: ''
        };
    }
  };

  return (
    <div>
      <div className={generateType(profileType).badgeStyles}>
        <div>{generateType(profileType).label}</div>
      </div>
      {isNotFullSignUp && isSignUpInvitationSentAt && (
        <>
          {isSignUpInvitationSent ? (
            <div className={styles.inviteLabel}>
              Invited {getAgoTime(moment(isSignUpInvitationSentAt, SIGNUP_INVITATION_SEND_AT_FORMAT))} ago
            </div>
          ) : (
            <div className={styles.inviteLabel}>
              {getSendInviteTime(moment(isSignUpInvitationSentAt, SIGNUP_INVITATION_SEND_AT_FORMAT))}
            </div>
          )}
        </>
      )}
      <div className={styles.inviteAction}>
        {isNotFullSignUp && isShowInvitationAction && (
          <>
            {isInvitationProcessing ? (
              <div className={styles.loading}>
                <LoadingCircle />
              </div>
            ) : (
              <>
                <div className={styles.label} onClick={(e) => handleSelectButtonClick(e)}>
                  Invitation actions
                </div>
                <div className={styles.menuWrapper}>
                  <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
                    <div className={styles.listBox} onClick={handleResend}>
                      <div className={styles.listTitle}>Resend Signup Invitation</div>
                    </div>
                    <div className={styles.listBox} onClick={handleCancel}>
                      <div className={styles.listTitle}>Cancel Signup Invitation</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {canBeFullProfile && isRecordOnlyActionExists && (
          <>
            {isInvitationProcessing ? (
              <div className={styles.loading}>
                <LoadingCircle />
              </div>
            ) : (
              <>
                <div className={styles.label} onClick={(e) => handleSelectButtonClick(e)}>
                  Record actions
                </div>

                <div className={styles.menuWrapper}>
                  <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
                    <div className={styles.listBox} onClick={handleResend}>
                      <div className={styles.listTitle}>Convert to full profile client</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileTypeBadge;
