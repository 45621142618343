import styles from './WriteOffListItem.module.scss';
import columnStyles from '../WriteOffListHeader/WriteOffListColumn.module.scss';
import moment from 'moment';
import classNames from 'classnames';
import { STATUS_LABELS } from 'pages/Invoices/components/InvoiceListing/components/ClientInvoiceItem/ClientInvoiceItem';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { selectFilters } from 'redux/invoices/invoiceSlice';
import { useAppSelector } from 'redux/hooks';
import { ClientRecordType } from 'interfaces/Clients/clientsRecord';
import { MOMENTJS_DATE_MONTH_YEAR_FORMAT, MOMENTJS_TIME_FORMAT } from 'utils/dateChecker';
import { InvoiceCreator } from 'pages/Invoices/interface';
import { useState } from 'react';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useCurrency } from 'utils/hooks/useCurrency';
import { InvoiceWithType } from 'pages/InvoicesV2/components/InvoiceList/constants';
import { InvoiceTypeMapping } from 'pages/InvoicesV2/components/InvoiceList/components/InvoiceListItem/InvoiceListItem';
import WriteOffActionMenu from '../WriteOffActionMenu/WriteOffActionMenu';
import InvoiceHistoryItem from 'pages/InvoicesV2/components/InvoiceList/components/InvoiceHistoryItem/InvoiceHistoryItem';

interface WriteOffListItemProps {
  invoice: InvoiceWithType;
  practiceName: string;
  showInvoiceType?: boolean;
}

const WriteOffListItem = ({ invoice, showInvoiceType, practiceName }: WriteOffListItemProps) => {
  const { CURRENCY_SYMBOL } = useCurrency();
  const filters = useAppSelector(selectFilters);
  const { participantType } = filters;

  const [isShowHistory, setIsShowHistory] = useState<boolean>(false);

  const onClickHistory = () => {
    setIsShowHistory(!isShowHistory);
  };

  const { clientRecord, invoiceId, status, invoiceAmount, group, invoiceType, createdAt, createdBy, histories } =
    invoice;

  const hasHistory = histories && histories.length > 0;

  return (
    <div className={styles.container}>
      <div className={styles.invoiceContainer}>
        <div className={columnStyles.invoiceId}>{invoiceId}</div>
        {showInvoiceType && (
          <div className={columnStyles.type}>
            {invoiceType && InvoiceTypeMapping[invoiceType] && (
              <div className={classNames(styles.typePill, styles[status])}>{InvoiceTypeMapping[invoiceType]}</div>
            )}
          </div>
        )}
        <div className={classNames(styles.client, columnStyles.client)}>
          {participantType === ParticipantType.Group ? (
            <div className={styles.groupName}>{group?.name}</div>
          ) : (
            clientRecord && (
              <>
                <div className={styles.clientName}>
                  <ClientAvatar
                    clientData={clientRecord.clientProfiles}
                    avatarSize={60}
                    displayFirstNameOnly={clientRecord.recordType === ClientRecordType.Couple}
                    displayLimit={clientRecord.recordType === ClientRecordType.Couple ? 2 : 1}
                    nameClassName={styles.clientName}
                    hideAvatar
                  />
                </div>
                <div className={styles.clientId}>{clientRecord?.tacklitId}</div>
                <div className={styles.clientId}>{clientRecord?.caseId}</div>
              </>
            )
          )}
        </div>
        <div className={classNames(columnStyles.createdAt, styles.createdAt)}>
          <div className={styles.automated}>
            {createdBy === InvoiceCreator.System && (
              <i className={`material-icons-outlined ${styles.automatedIcon}`}>bolt</i>
            )}
          </div>
          <div className={styles.generatedValue}>
            <span className={styles.generatedValueTime}>{moment(createdAt).format(MOMENTJS_TIME_FORMAT)}</span>
            <span className={styles.generatedValueDate}>
              {moment(createdAt).format(MOMENTJS_DATE_MONTH_YEAR_FORMAT)}
            </span>
          </div>
        </div>

        <div className={columnStyles.status}>
          {status && <div className={classNames(styles.statusPill, styles[status])}>{STATUS_LABELS[status]}</div>}
        </div>
        <div className={classNames(columnStyles.total, styles.total)}>{`(${CURRENCY_SYMBOL}${(
          -1 * invoiceAmount
        ).toFixed(2)})`}</div>
        <div className={columnStyles.actions}>
          {hasHistory && (
            <ButtonAlt
              variant="text"
              size="medium"
              icon={isShowHistory ? 'expand_less' : 'history'}
              fab
              onClick={onClickHistory}
            />
          )}
          <WriteOffActionMenu invoice={invoice} />
        </div>
      </div>

      {/* History */}
      {isShowHistory && (
        <div className={styles.historyWrapper}>
          <InvoiceHistoryItem
            histories={histories || []}
            clientName={clientRecord?.clientProfiles[0].name || ''}
            practiceName={practiceName}
          />
        </div>
      )}
    </div>
  );
};

export default WriteOffListItem;
