import { useState } from 'react';
import classNames from 'classnames';
import styles from './KeyContactList.module.scss';
import columnStyles from '../../KeyContactsColumn.module.scss';
import { Modal, notification } from 'antd';
import { KeyContact } from 'pages/Groups/Interfaces/Groups';
import { deleteKeyContact } from 'utils/http/ClinicianProfileService/Groups/Groups';
import ContactTag from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/KeyClientContacts/components/ContactTag/ContactTag';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

export interface KeyContactListProps {
  token: string;
  groupId: string;
  keyContacts: KeyContact[];
  enableDelete: boolean;
}

const KeyContactList = ({ keyContacts, token, groupId, enableDelete }: KeyContactListProps) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { accountId } = useGetAccountId();

  const onDeleteContact = async (value: KeyContact) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this key contact?',
      cancelText: 'Cancel',
      okText: 'Yes',
      onOk: () => handleDeleteTag(value)
    });
  };

  const handleDeleteTag = async (value: KeyContact) => {
    if (isDeleting) {
      return;
    }
    setIsDeleting(true);
    try {
      await deleteKeyContact(token, accountId, groupId, value._id);
      keyContacts.splice(
        keyContacts.findIndex((o) => o._id === value._id),
        1
      );
      setTimeout(() => {
        notification.success({
          message: 'Key contact deleted.',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }, 500);
    } catch (e) {
      notification.error({ message: 'Something went wrong while trying to delete key contact' });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className={styles.container}>
      {keyContacts.map((contact, index) => (
        <div key={index} className={classNames(columnStyles.listHeader, styles.contactItem)}>
          <div className={columnStyles.firstName}>{contact.firstName}</div>
          <div className={columnStyles.lastName}>{contact.lastName}</div>
          <div className={columnStyles.mobileNumber}>{contact.mobileNumber}</div>
          <div className={columnStyles.email}>{contact.email}</div>
          <div className={columnStyles.contactTags}>
            <ContactTag tags={contact.tags || []} />
          </div>
          <div className={columnStyles.actions}>
            {enableDelete && (
              <ButtonAlt
                error
                variant={'text'}
                size={'medium'}
                icon={'cancel'}
                fab
                onClick={() => onDeleteContact(contact)}
                disabled={isDeleting}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default KeyContactList;
