import styles from './InvoiceCheckoutForm.module.scss';
import { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { notification } from 'antd';
import { FormikValues, useFormikContext } from 'formik';

import CheckBox from 'components/CheckBox/CheckBox';
import OptionLabel from 'components/OptionLabel/OptionLabel';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import {
  CreateCardPaymentPayload,
  CreateCardPaymentResponse,
  useCreateCardPaymentMutation
} from 'redux/endpoints/billingServices/invoices';

export const InvoiceCheckoutForm = ({
  invoiceId,
  onPaymentComplete
}: {
  invoiceId: string;
  onPaymentComplete: (isSuccess: boolean) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { accountId } = useGetAccountId();
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [createCardPayment] = useCreateCardPaymentMutation();

  const [message, setMessage] = useState<string>('');
  const [submitCheckoutStatus, setSubmitCheckoutStatus] = useState<ButtonStatusType>('');

  const submitCheckout = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setSubmitCheckoutStatus('active');
    setMessage('');

    try {
      const isError = await validateForm();
      if (isError) {
        setSubmitCheckoutStatus('');
        return;
      }

      const { paymentMethod } = await stripe.createPaymentMethod({ elements });

      if (!paymentMethod || !paymentMethod.id) {
        setSubmitCheckoutStatus('');
        setMessage('Payment failed. Please try again, if failures persist please contact us to investigate.');
        onPaymentComplete(false);
        return;
      }

      const payload = {
        amount: parseFloat(values.amount),
        paymentMethodId: paymentMethod.id,
        isSaveCard: values.isSaveCard
      } as CreateCardPaymentPayload;

      await createCardPayment({ accountId, invoiceId, payload }).unwrap();

      notification.success({
        message: 'Payment success',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setSubmitCheckoutStatus('finished');
      onPaymentComplete(true);
    } catch (ex) {
      console.error(ex);
      setSubmitCheckoutStatus('');

      const error = ex as { data: CreateCardPaymentResponse };
      const message = `Payment failed. ${
        error?.data?.message || 'Please try again, if failures persist please contact us to investigate.'
      }`;

      if (error?.data?.success === false) {
        setMessage(message);
      } else {
        notification.error({
          message: message,
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });
      }
      onPaymentComplete(false);
    }
  };

  const validateForm = async () => {
    if (!elements) return;

    const { error } = await elements.submit();
    if (error) {
      // Skip validation_error as error shown on inputs
      if (error.type !== 'validation_error') {
        setMessage(
          `Payment failed. ${
            error.message || 'Please try again, if failures persist please contact us to investigate.'
          }`
        );
      }
      return true;
    }
  };

  return (
    <>
      {stripe && elements && (
        <>
          <PaymentElement
            id="payment-element"
            options={{ layout: 'tabs' }}
            onLoaderStart={() => setSubmitCheckoutStatus('active')}
            onReady={() => setSubmitCheckoutStatus('')}
          />

          {message && <div className={styles.paymentMessage}>{message}</div>}

          <CheckBox
            id="clientOnboardingCheck"
            value={values.isSaveCard}
            label={<OptionLabel title="Update and save card for future payments." />}
            onChange={(e) => {
              setFieldValue('isSaveCard', e.target.checked);
            }}
            disabled={submitCheckoutStatus === 'active'}
          />

          <ButtonAlt
            type="submit"
            status={submitCheckoutStatus}
            onClick={submitCheckout}
            contentClassName={styles.submitBtn}
            fullWidth
            loadingWord="Processing card payment..."
          >
            Pay
          </ButtonAlt>
        </>
      )}
    </>
  );
};
