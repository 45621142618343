import PreviewItemInfo from '../PreviewItemInfo/PreviewItemInfo';
import { ADDRESS_TO_VALUE, InvoiceInfoProps } from '../interface';
import styles from './InvoiceInfo.module.scss';
import { config } from 'config/config';

const InvoiceInfo = ({
  invoiceTitle,
  invoiceId,
  issueDate,
  recipientAddress,
  recipientRegistrationNumber,
  shortCode,
  recipientReference,
  recipientMobileNumber,
  recipientEmail,
  recipient
}: InvoiceInfoProps) => {
  const addressToValue = ADDRESS_TO_VALUE[recipient.type];
  const registrationNumberLabel = config.practiceSettings.registrationNumberLabel;

  return (
    <div className={styles.titleInfo}>
      {invoiceTitle && <h2>{invoiceTitle}</h2>}
      <div className={styles.contentRow}>
        <div className={styles.itemInfoBlock}>
          {invoiceId && (
            <PreviewItemInfo
              className={styles.customInfo}
              label="Invoice ID:"
              value={`${shortCode ? shortCode + '-' : ''}{{Invoice ID}}}`}
            />
          )}
          {issueDate && (
            <PreviewItemInfo className={styles.customInfo} label="Date of issue:" value="{{Issued Date}}" />
          )}
          <PreviewItemInfo className={styles.customInfo} label="Due date:" value="{{Due Date}}" />
        </div>
        <div className={styles.itemInfoBlock}>
          <PreviewItemInfo className={styles.customInfo} label="Address to" value={`{{${addressToValue}}}`} />
          {recipientAddress && <PreviewItemInfo className={styles.customInfo} label="Address:" value="{{Address}}" />}
          {recipientEmail && <PreviewItemInfo className={styles.customInfo} label="Email:" value="{{Email}}" />}
          {recipientMobileNumber && (
            <PreviewItemInfo className={styles.customInfo} label="Mobile Number:" value="{{Mobile Number}}" />
          )}
          {recipientReference && (
            <PreviewItemInfo className={styles.customInfo} label="Reference:" value="{{Reference}}" />
          )}
          {recipientRegistrationNumber && (
            <PreviewItemInfo
              className={styles.customInfo}
              label={`${registrationNumberLabel}:`}
              value={`{{${registrationNumberLabel}}}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceInfo;
