import SubTab from 'components/v2/SubTab/SubTab';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import tabStyles from '../SubTabLayout.module.scss';
import { useCallback, useContext, useMemo } from 'react';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { SubTabContext } from 'layouts/AuthLayoutT23/context/SubTabContextProvider';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './ReportsAndLettersSubTab.module.scss';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetReportTemplatesQuery } from 'redux/endpoints/documentServices/report';
import { useGetLetterTemplatesQuery } from 'redux/endpoints/documentServices/letter';
import ActionDropdown, { ActionDropdownType } from '../ActionDropdown/ActionDropdown';
import { ReportTemplateStatus } from 'interfaces/Reports/reportTemplate';
import { LetterTemplateStatus } from 'interfaces/Letters/letterTemplate';

export const RESET_FORM_TIME = 'resetFormTime' as const;

const ReportsAndLetterSubTab = () => {
  const { setShowCreateNewReportModal } = useContext(SubTabContext);
  const { isEdgeAdminOrReceptionistView } = useGetAccountPackageView();
  const { accountId } = useGetAccountId();
  const location = useLocation();
  const { isEdgePlanUser } = useGetAccountPackage();
  const navigate = useNavigate();
  const { FORMS, REPORTS, LETTERS } = useRoutesGenerator();
  const asAdmin = isEdgeAdminOrReceptionistView;
  const {
    data: reportTemplateList,
    isLoading: isReportTemplatesLoading,
    isFetching: isReportTemplatesFetching
  } = useGetReportTemplatesQuery({ asAdmin });
  const {
    data: letterTemplateList,
    isLoading: isLetterTemplatesLoading,
    isFetching: isLetterTemplatesFetching
  } = useGetLetterTemplatesQuery({ accountId, asAdmin });

  const selectedType = useMemo(() => {
    return location.pathname.split('/')[isEdgePlanUser ? 2 : 1];
  }, [location, isEdgePlanUser]);

  const publishedLetterTemplateList = useMemo(
    () =>
      letterTemplateList
        ?.filter(({ status }) => status === LetterTemplateStatus.Published)
        .map(({ _id, title }) => ({ _id, name: title })),
    [letterTemplateList]
  );

  const publishedReportTemplateList = useMemo(
    () =>
      reportTemplateList
        ?.filter(({ status }) => status === ReportTemplateStatus.Published)
        .map(({ _id, title }) => ({ _id, name: title })),
    [reportTemplateList]
  );

  const navigateToNewLetterPage = useCallback(
    (params?: { templateId?: string }) => {
      const templateId = params?.templateId;

      navigate({
        pathname: LETTERS.NEW,
        search: createSearchParams({
          [RESET_FORM_TIME]: Date.now().toString(),
          ...(templateId && { templateId })
        }).toString()
      });
    },
    [LETTERS.NEW, navigate]
  );

  const onSelectLetterTemplate = useCallback(
    (letterTemplateId: string) => {
      navigateToNewLetterPage({ templateId: letterTemplateId });
    },
    [navigateToNewLetterPage]
  );

  const onCreateReport = useCallback(
    (reportTemplateId?: string) => {
      setShowCreateNewReportModal(true);
      navigate(`${REPORTS.BASE}${reportTemplateId ? `?reportTemplateId=${reportTemplateId}` : ''}`);
    },
    [REPORTS.BASE, navigate, setShowCreateNewReportModal]
  );

  const documentTypeOptions = [
    { label: 'Letters', value: 'letters', href: LETTERS.BASE },
    { label: 'Reports', value: 'reports', href: REPORTS.BASE }
  ];

  return (
    <div className={tabStyles.container}>
      <div className={tabStyles.section}>
        <div className={tabStyles.title}>CREATE NEW</div>
        <div className={styles.letterButtonContainer}>
          <ActionDropdown
            onAddNew={() => navigate(LETTERS.NEW)}
            onAddNewTemplate={() => navigate(FORMS.NEW_LETTER_TEMPLATE)}
            onNavigateToCreateWithTemplate={onSelectLetterTemplate}
            type={ActionDropdownType.Letter}
            isTemplateLoading={isLetterTemplatesFetching || isLetterTemplatesLoading}
            templateList={publishedLetterTemplateList}
          />
        </div>
      </div>
      <div className={tabStyles.section}>
        <ActionDropdown
          onAddNew={onCreateReport}
          onAddNewTemplate={() => navigate(FORMS.NEW_REPORT_TEMPLATE)}
          onNavigateToCreateWithTemplate={onCreateReport}
          type={ActionDropdownType.Report}
          isTemplateLoading={isReportTemplatesFetching || isReportTemplatesLoading}
          templateList={publishedReportTemplateList}
        />
      </div>
      <div className={tabStyles.section}>
        <SubTab title="VIEW DOCUMENTS BY TYPE" options={documentTypeOptions} activeOption={selectedType} />
      </div>
    </div>
  );
};

export default ReportsAndLetterSubTab;
