import { useState, useMemo } from 'react';
import { Modal, notification } from 'antd';
import styles from './ProfileMfaBadge.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { updateClinicianEnforceMfa } from 'utils/http/ClinicianProfileService/Profile/profile';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';
import { useGetGeneralSettingsQuery } from 'redux/endpoints/clinicianProfileServices/generalSettings';

interface ProfileMfaBadgeProps {
  profile: ProfileInterface;
  onUpdateEnforceMfa: () => void;
}

enum TfaStatus {
  On = 'on',
  Off = 'off',
  Disabled = 'disabled'
}

const ProfileMfaBadge = ({ profile, onUpdateEnforceMfa }: ProfileMfaBadgeProps) => {
  const { token } = useGetAccessToken();
  const { CONTROL_PANEL } = useRoutesGenerator();
  const [isLoading, setIsLoading] = useState(false);

  const { data: generalSettings, isLoading: generalSettingsLoading } = useGetGeneralSettingsQuery(
    {
      accountId: profile.accountId
    },
    { skip: !profile.accountId }
  );

  const tfaStatus = useMemo(() => {
    if (!generalSettings?.enforceUserMFA) {
      return TfaStatus.Disabled;
    } else if (profile.profileSettings?.enforceMfa === false) {
      return TfaStatus.Off;
    } else {
      return TfaStatus.On;
    }
  }, [generalSettings?.enforceUserMFA, profile.profileSettings]);

  const handleSubmitMfa = async (enforceMfa: boolean) => {
    if (isLoading) {
      return;
    }

    const enableDisableMfa = async ({ enforceMfa, profile }: { enforceMfa: boolean; profile: ProfileInterface }) => {
      try {
        setIsLoading(true);

        await updateClinicianEnforceMfa(token, profile._id, { enforceMfa });

        onUpdateEnforceMfa();
        setIsLoading(false);
        notification.success({
          message: 'Successfully updated 2FA settings',
          closeIcon: <span className="success">OK</span>
        });
      } catch (error) {
        notification.error({ message: 'Something went wrong while trying to update 2FA settings' });
        setIsLoading(false);
      }
    };

    const data = { enforceMfa, profile };
    if (enforceMfa) {
      await enableDisableMfa(data);
      return;
    }
    Modal.confirm({
      title: 'Are you sure you want to turn off Two Factor Authentication?',
      onOk: async () => await enableDisableMfa(data)
    });
  };

  return (
    <div className={styles.container}>
      {!generalSettingsLoading && (
        <>
          {tfaStatus === TfaStatus.Disabled && (
            <>
              <div className={classnames(styles.tfaBadge, styles.disabled)}>2FA Not Enabled</div>
              <Link
                to={`${CONTROL_PANEL.BASE}/general-settings`}
                className={classnames(styles.action, styles.disabled)}
              >
                Turn on Two Factor Authentication
                <i className={classnames('material-icons-outlined', styles.icon)}>open_in_new</i>
              </Link>
            </>
          )}
          {tfaStatus === TfaStatus.Off && (
            <>
              <div className={classnames(styles.tfaBadge, styles.off)}>
                2FA Off<i className={classnames('material-icons-outlined', styles.icon)}>block</i>
              </div>
              <Button
                className={classnames(styles.action, styles.off)}
                onClick={() => handleSubmitMfa(true)}
                disabled={isLoading}
              >
                <i className={classnames('material-icons-outlined', styles.icon)}>add</i>Trigger enrolment
              </Button>
            </>
          )}
          {tfaStatus === TfaStatus.On && (
            <>
              <div className={classnames(styles.tfaBadge, styles.on)}>
                2FA On<i className={classnames('material-icons-outlined', styles.icon)}>check_circle</i>
              </div>
              <Button className={classnames(styles.action, styles.on)} onClick={() => handleSubmitMfa(false)}>
                Turn off 2FA
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProfileMfaBadge;
