import classNames from 'classnames';
import IconButton, { IconPaddingSize } from 'components/IconButton/IconButton';
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from './ThreeDotMenu.module.scss';
import { ButtonAltSize } from 'components/v2/ButtonAlt/ButtonAlt';

export interface ThreeDotMenuHandle {
  closeMenu: () => void;
  openMenu: () => void;
}

interface ThreeDotMenuProps {
  children?: ReactNode;
  className?: string;
  iconButtonClassName?: string;
  isMenuDisabled?: boolean;
  size?: ButtonAltSize;
  paddingSize?: IconPaddingSize;
  manualClose?: boolean;
  menuContentClass?: string;
}

const ThreeDotMenu = forwardRef<ThreeDotMenuHandle, ThreeDotMenuProps>(
  (
    {
      children,
      className,
      iconButtonClassName,
      isMenuDisabled,
      size = 'large',
      paddingSize,
      manualClose,
      menuContentClass
    },
    ref
  ) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuNode = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleMouseDown = (e: MouseEvent) => {
        if (isMenuOpen && !manualClose && !menuNode.current?.contains(e.target as Node)) {
          setIsMenuOpen(false);
        }
      };

      document.addEventListener('mousedown', handleMouseDown);
      return () => window.removeEventListener('mousedown', handleMouseDown);
    }, [isMenuOpen, manualClose]);

    useImperativeHandle(ref, () => ({
      closeMenu: () => setIsMenuOpen(false),
      openMenu: () => setIsMenuOpen(true)
    }));

    const handleMoreIconClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      setIsMenuOpen(!isMenuOpen);
    };

    return (
      <div className={className}>
        <div ref={menuNode}>
          <IconButton
            size={size}
            className={iconButtonClassName}
            paddingSize={paddingSize}
            iconName="more_vert"
            isDisabled={isMenuDisabled}
            onClick={handleMoreIconClick}
          />
          <div className={styles.menuWrapper}>
            <div
              className={classNames(
                styles.menuContent,
                menuContentClass,
                isMenuOpen && styles.show,
                isMenuDisabled && styles.disabled
              )}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ThreeDotMenu;
