import { CarePathway, CarePathwayStatus } from 'interfaces/CarePathway/CarePathway';
import { funderType } from '../../interfaces/Packages/packages';

export const massageCarePathwayPayload = (payload: CarePathway, resetItem?: boolean) => ({
  ...(payload._id && {
    _id: payload._id
  }),
  name: payload.name,
  description: payload.description,
  note: payload.note,
  isNoteVisibleToClient: payload.isNoteVisibleToClient,
  status: CarePathwayStatus.Draft,
  clientRecordId: payload.clientRecord?._id || '',
  leadClinicianAuth0Id: payload.leadClinician?._id || '',
  clinicianAuth0Ids: payload.clinicians.map((clinicianObj) => clinicianObj._id),
  ...(!resetItem &&
    payload.items.length > 0 && {
      items: payload.items.map((itemObj) => ({
        appointmentTypeId: itemObj.appointmentTypeId || itemObj.appointmentType._id || '',
        _id: itemObj._id,
        preferredDeliveryType: itemObj.preferredDeliveryType,
        appointmentType: itemObj.appointmentType
      }))
    }),
  appointments: payload.appointments.map(
    (appointmentObj) =>
      ({
        startDateTime: appointmentObj.startDateTime,
        endDateTime: appointmentObj.endDateTime,
        clinicianId: appointmentObj.clinicianId,
        carePathwayItemId: appointmentObj.carePathwayItemId,
        deliveryType: appointmentObj.deliveryType,
        roomId: appointmentObj.roomId
      } || [])
  ),
  ...(payload.package?.id && {
    package: {
      id: payload.package.id,
      funder: {
        type: payload.package.funder.type,
        ...(payload.package.funder.type === funderType.AddressBook && {
          funderId: payload.package.funder.funderId
        }),
        ...(payload.package.funder.type === funderType.Default && {
          name: payload.package.funder.name
        })
      }
    }
  })
});
