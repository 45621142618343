import classNames from 'classnames';
import { EpisodeInterface, EpisodeStatus } from 'interfaces/Episodes/episodes';
import moment from 'moment';
import EpisodeStatusBadge from 'pages/PatientDetails/components/PatientDetailsContent/components/ClientDetailsEoC/components/EpisodeStatusBadge/EpisodeStatusBadge';
import { FC } from 'react';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_SHORT_YEAR_FORMAT } from 'utils/dateChecker';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './ClientProfileEpisodeDropdownItem.module.scss';

export enum EpisodeDropdownType {
  OUTSIDE_ALL_EPISODES = 'Outside all episodes',
  SHOW_ALL_DATA = 'Show all data'
}

interface ClientProfileEpisodeDropdownItemProps {
  episode: EpisodeInterface;
  value?: EpisodeDropdownType;
  supportCurrentLabel?: boolean;
  selectedClass?: boolean;
  className?: string;
  showStatus?: boolean;
}

export const ClientProfileEpisodeDropdownItem: FC<ClientProfileEpisodeDropdownItemProps> = ({
  episode,
  value,
  supportCurrentLabel,
  selectedClass,
  className,
  showStatus = true
}) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const startDate = moment(episode?.startDate).format(MOMENTJS_SHORT_YEAR_FORMAT);
  const endDate = episode?.endDate ? moment(episode?.endDate).format(MOMENTJS_SHORT_YEAR_FORMAT) : 'Ongoing';

  if (!episode._id) {
    return <div>{value}</div>;
  }

  if ([EpisodeStatus.Active, EpisodeStatus.OnHold].includes(episode.status) && supportCurrentLabel) {
    return (
      <div className={styles.activeContainer}>
        <div className={styles.order}>Current Episode (#{episode.order})</div>
        <div className={styles.date}>
          {startDate} - {endDate}
        </div>
        <EpisodeStatusBadge status={episode.status} />
      </div>
    );
  }

  const endDateLongFormat = episode?.endDate ? moment(episode?.endDate).format(MOMENTJS_DATE_FORMAT) : 'Ongoing';

  return (
    <div
      className={classNames(
        isEdgeAdminView && 't23-admin-theme',
        styles.container,
        className,
        selectedClass && styles.selectedClassContainer
      )}
    >
      <div className={styles.left}>
        <div className={styles.order}>Episode {episode.order}</div>
        <div className={styles.date}>
          {startDate} - {endDateLongFormat}
        </div>
      </div>
      {showStatus && <EpisodeStatusBadge status={episode.status} />}
    </div>
  );
};
