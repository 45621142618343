import moment from 'moment';

import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

import { MOMENTJS_DATE_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import {
  checkIsShouldHaveParentMedicare,
  getMedicareProfilesFromClientRecord
} from 'utils/helpers/getMedicareProfilesFromClientRecord';

export interface MedicareFormData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  number: string;
  irn: string;
  dva: string;
  expiryDate: string;
  parentFirstName?: string;
  parentLastName?: string;
  parentDateOfBirth?: string;
  parentIrn?: string;
  //For validate purpose
  shouldHaveParentMedicare?: boolean;
}

export const getMedicareFormData = (clientRecord: clientRecordsInterface): MedicareFormData => {
  const { clientProfile, parentMedicareProfile } = getMedicareProfilesFromClientRecord(clientRecord);
  const { firstName, lastName, dateOfBirth, medicare } = clientProfile;
  const shouldHaveParentMedicare = checkIsShouldHaveParentMedicare(clientRecord);
  const {
    firstName: parentFirstName,
    lastName: parentLastName,
    dateOfBirth: parentDateOfBirth,
    medicare: parentMedicare
  } = shouldHaveParentMedicare
    ? parentMedicareProfile || { firstName: '', lastName: '', dateOfBirth: '', medicare: {} }
    : { firstName: '', lastName: '', dateOfBirth: '', medicare: {} };

  return {
    firstName: medicare?.firstName || firstName,
    lastName: medicare?.lastName || lastName,
    dateOfBirth:
      (medicare?.dateOfBirth &&
        moment(medicare.dateOfBirth, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format(MOMENTJS_DATE_FORMAT)) ||
      dateOfBirth ||
      '',
    number: isNaN(Number(medicare?.number)) ? '' : `${medicare?.number}`,
    irn: isNaN(Number(medicare?.irn)) ? '' : `${medicare?.irn}`,
    dva: medicare?.dva || '',
    expiryDate: medicare?.expiryDate || '',
    ...(shouldHaveParentMedicare && {
      parentFirstName: parentMedicare?.firstName || parentFirstName,
      parentDateOfBirth:
        (parentMedicare?.dateOfBirth &&
          moment(parentMedicare.dateOfBirth, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format(MOMENTJS_DATE_FORMAT)) ||
        parentDateOfBirth ||
        '',
      parentLastName: parentMedicare?.lastName || parentLastName,
      parentIrn: isNaN(Number(parentMedicare?.irn)) ? '' : `${parentMedicare?.irn}`
    }),
    shouldHaveParentMedicare
  };
};
