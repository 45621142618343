import InvoicePaymentsFilter from '../InvoicePaymentsFilter/InvoicePaymentsFilter';
import InvoicePaymentsItem from '../InvoicePaymentsItem/InvoicePaymentsItem';
import InvoicePaymentsListHeader from '../InvoicePaymentsListHeader/InvoicePaymentsListHeader';
import styles from './InvoicePaymentsList.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  InvoicePaymentsTab,
  selectActiveTab,
  selectCancelledPaging,
  selectCollectedPaging,
  selectFailedPaging,
  selectRefundedPaging,
  selectScheduledPaging,
  setIsShowPartialRefundModal,
  setPaging,
  setSelectedPayment
} from 'redux/features/invoicePayments/invoicePaymentsSlice';
import { Skeleton } from 'antd';
import PerRecordDropdown from 'components/TableList/PerRecordDropdown/PerRecordDropdown';
import PaginationDescV2 from 'components/TableList/PaginationDescV2/PaginationDescV2';
import { useGetInvoicePayments, useGetScheduledPayments } from 'redux/features/invoicePayments/useGetInvoicePayments';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { usePaymentFullRefund } from 'redux/features/invoicePayments/usePaymentFullRefund';
import { InvoicePayment } from 'pages/InvoicePayments/constants';
import { useMemo } from 'react';
import { PER_PAGE_DEFAULT } from 'redux/features/utils';

const InvoicePaymentsList = () => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector(selectActiveTab);
  const collectedPaging = useAppSelector(selectCollectedPaging);
  const scheduledPaging = useAppSelector(selectScheduledPaging);
  const refundedPaging = useAppSelector(selectRefundedPaging);
  const failedPaging = useAppSelector(selectFailedPaging);
  const cancelledPaging = useAppSelector(selectCancelledPaging);
  const { CLIENTS } = useRoutesGenerator();

  const { onFullRefund } = usePaymentFullRefund();

  const {
    isError: isGetInvoicePaymentsError,
    invoicePayments,
    totalItems: totalInvoicePaymentItems,
    isInvoicePaymentsLoading
  } = useGetInvoicePayments();
  const { isGetScheduledPaymentsError, scheduledPayments, totalScheduledPaymentItems, isScheduledPaymentsLoading } =
    useGetScheduledPayments();

  const paging = useMemo(() => {
    switch (activeTab) {
      case InvoicePaymentsTab.Collected:
        return collectedPaging;
      case InvoicePaymentsTab.Scheduled:
        return scheduledPaging;
      case InvoicePaymentsTab.Refunded:
        return refundedPaging;
      case InvoicePaymentsTab.Failed:
        return failedPaging;
      case InvoicePaymentsTab.Cancelled:
        return cancelledPaging;
    }
  }, [activeTab, collectedPaging, scheduledPaging, refundedPaging, failedPaging, cancelledPaging]);

  const onChangePage = (page: number) => {
    dispatch(setPaging({ ...paging, page }));
  };

  const onChangePerPage = (perPage: number) => {
    dispatch(setPaging({ perPage, page: 1 }));
  };

  const onViewClient = (clientRecordId: string) => {
    window.open(`${CLIENTS.BASE}/${clientRecordId}/profile`, '_blank');
  };

  const handleFullRefund = (payment: InvoicePayment) => {
    onFullRefund({ accountId: payment.accountId, paymentRequestId: payment._id });
  };

  const handlePartialRefund = (payment: InvoicePayment) => {
    dispatch(setSelectedPayment(payment));
    dispatch(setIsShowPartialRefundModal(true));
  };

  const isLoading = activeTab === InvoicePaymentsTab.Scheduled ? isScheduledPaymentsLoading : isInvoicePaymentsLoading;
  const isError = activeTab === InvoicePaymentsTab.Scheduled ? isGetScheduledPaymentsError : isGetInvoicePaymentsError;
  const isNoResult =
    activeTab === InvoicePaymentsTab.Scheduled
      ? scheduledPayments && scheduledPayments.length < 1
      : invoicePayments && invoicePayments.length < 1;
  const totalItems = activeTab === InvoicePaymentsTab.Scheduled ? totalScheduledPaymentItems : totalInvoicePaymentItems;

  return (
    <div className={styles.container}>
      {/* Filters */}
      <div className={styles.filter}>
        <InvoicePaymentsFilter totalItems={!isError ? totalItems : 0} isLoading={isLoading} />
      </div>
      <div className={styles.listWrapper}>
        <InvoicePaymentsListHeader />

        {isLoading ? (
          <div className={styles.loadingWrapper}>
            {[...Array(10)].map((_, i) => (
              <div key={i} className={styles.loadingItem}>
                <Skeleton.Input active className={styles.loadingContent} />
              </div>
            ))}
          </div>
        ) : isError || isNoResult ? (
          <div>No payments</div>
        ) : (
          <div className={styles.listOfInvoicePayments}>
            {activeTab === InvoicePaymentsTab.Scheduled
              ? scheduledPayments.map((scheduledPayment, index) => (
                  <InvoicePaymentsItem
                    key={index}
                    scheduledPayment={scheduledPayment}
                    onViewClient={() =>
                      scheduledPayment.clientRecord?._id && onViewClient(scheduledPayment.clientRecord._id)
                    }
                  />
                ))
              : invoicePayments.map((payment, index) => (
                  <InvoicePaymentsItem
                    key={index}
                    payment={payment}
                    onViewClient={() => onViewClient(payment.clientRecordId)}
                    onPartialRefund={() => handlePartialRefund(payment)}
                    onFullRefund={() => handleFullRefund(payment)}
                  />
                ))}
          </div>
        )}
      </div>

      {/* Pagination */}
      {!isInvoicePaymentsLoading && totalItems > PER_PAGE_DEFAULT && (
        <div className={styles.paginationWrapper}>
          <div className={styles.recordPerPageWrapper}>
            <PerRecordDropdown
              totalItem={totalItems}
              selectedPerPage={paging.perPage}
              selectDropdownValue={onChangePerPage}
            />
            <div className={styles.label}>payments per page</div>
          </div>
          <PaginationDescV2
            currentPage={paging.page}
            totalItem={totalItems}
            perPage={paging.perPage}
            onPageChange={onChangePage}
          />
        </div>
      )}
    </div>
  );
};

export default InvoicePaymentsList;
