import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import moment from 'moment';
import styles from './HeaderControl.module.scss';

interface HeaderControlProps {
  previous: any;
  current: any;
  next: any;
  isShowDate?: boolean;
  onGoToOtherClick: (id: string, childId?: string) => void;
  isDetailPage?: boolean;
}

const HeaderControl = ({ previous, current, next, isShowDate, onGoToOtherClick }: HeaderControlProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        {previous && (
          <div className={styles.btnBox}>
            <ButtonAlt
              contentClassName={styles.btnBoxContent}
              variant={'text'}
              size={'medium'}
              onClick={() => onGoToOtherClick(previous._id, previous.clinicalAssessmentId)}
              icon={'chevron_left'}
            >
              <div className={styles.date}>
                <div className={styles.label}>{previous.id || previous.name || previous.clinicalAssessmentId}</div>
                {isShowDate && moment(previous.createdAt).format('DD/MM/YY')}
              </div>
            </ButtonAlt>
          </div>
        )}
      </div>
      <div className={styles.middleContent}>
        {current && (
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{current.id || current.name || current.clinicalAssessmentId}</div>
            {isShowDate && (
              <div className={styles.date}>{`SENT ON ${moment(current.createdAt).format('DD/MM/YY')}`}</div>
            )}
          </div>
        )}
      </div>
      <div className={styles.rightContent}>
        {next && (
          <div className={styles.btnBox}>
            <ButtonAlt
              contentClassName={styles.btnBoxContent}
              variant={'text'}
              size={'medium'}
              onClick={() => onGoToOtherClick(next._id, next.clinicalAssessmentId)}
              icon={'chevron_right'}
              iconPostFix
            >
              <div className={styles.date}>
                <div className={styles.label}>{next.id || next.name || next.clinicalAssessmentId}</div>
                {isShowDate && moment(next.createdAt).format('DD/MM/YY')}
              </div>
            </ButtonAlt>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderControl;
