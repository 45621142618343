import SubTab, { SubTabOption } from 'components/v2/SubTab/SubTab';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from '../SubTabLayout.module.scss';
import { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { GroupListData, GroupStatus } from 'pages/Groups/Interfaces/Groups';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ModalVisibilityContext } from 'components/SideDashboardT23/ModalVisibilityContext';
import { RecentlyViewItem, useGetRecentlyViewed } from 'utils/hooks/SubTab/recentlyViewed';
import { UserContext } from 'utils/UserContextProvider';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import ClientRecentlyViewed from '../ClientSubTab/ClientRecentlyViewed/ClientRecentlyViewed';
import GroupsDetailsSubTab from './GroupsDetailsSubTab.tsx/GroupsDetailsSubTab';
import { CarePathwayStatus } from 'interfaces/CarePathway/CarePathway';
import { useGetCarePathwayCountQuery } from 'redux/endpoints/clinicianProfileServices/carePathway';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

const GroupsSubTab = ({
  groupCountByStatus,
  pinned,
  setIsSecondTab
}: {
  groupCountByStatus: GroupListData['count'];
  pinned?: boolean;
  setIsSecondTab: Function;
}) => {
  const { accountId } = useGetAccountId();
  const location = useLocation();
  const { isEdgePlanUser } = useGetAccountPackage();
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { isCarePlanEnabled } = useGetFeatureToggle();
  const { data: carePathwayCountByStatus } = useGetCarePathwayCountQuery(
    { accountId },
    { skip: !isCarePlanEnabled || !isEdgeAdminView }
  );
  const { clinicianProfile } = useContext(UserContext);
  const isGroupsPage = isEdgePlanUser
    ? location.pathname.split('/')[2] === 'groups'
    : location.pathname.split('/')[1] === 'groups';
  const isCarePathwayPage = isEdgePlanUser
    ? location.pathname.split('/')[2] === 'care-pathways'
    : location.pathname.split('/')[1] === 'care-pathways';
  const getFilterType: GroupStatus | string = isGroupsPage
    ? isEdgePlanUser
      ? (location.pathname.split('/')[3] as GroupStatus) ?? GroupStatus.Active
      : (location.pathname.split('/')[2] as GroupStatus) ?? GroupStatus.Active
    : isCarePathwayPage
    ? isEdgePlanUser
      ? (location.pathname.split('/')[3] as CarePathwayStatus) ?? CarePathwayStatus.Active
      : (location.pathname.split('/')[2] as CarePathwayStatus) ?? CarePathwayStatus.Active
    : '';
  const { CARECOORDINATION } = useRoutesGenerator();
  const isDetailsPage = isEdgePlanUser ? location.pathname.split('/')[4] : location.pathname.split('/')[3];
  const dropdownnContainer = useRef<HTMLDivElement>(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [filterType, setFilterType] = useState<GroupStatus | CarePathwayStatus | string>(getFilterType);

  const clinicianId = clinicianProfile ? clinicianProfile._id : '';
  const groupRecentlyViewed = useGetRecentlyViewed({
    storageName: 'group_viewed',
    clinicianProfileId: clinicianId,
    isEdgeAdminView,
    max: 8
  });

  const carePathwayRecentlyViewed = useGetRecentlyViewed({
    storageName: 'carepathway_viewed',
    clinicianProfileId: clinicianId,
    isEdgeAdminView,
    max: 8
  });

  const groupBaseHref = CARECOORDINATION.GROUPS;
  const carePathwayBaseHref = CARECOORDINATION.CAREPATHWAYS;

  const recentlyViewed: (RecentlyViewItem & { basePath: string })[] = useMemo(
    () =>
      [
        ...groupRecentlyViewed.map((item) => ({
          ...item,
          basePath: groupBaseHref
        })),
        ...carePathwayRecentlyViewed.map((item) => ({
          ...item,
          basePath: carePathwayBaseHref
        }))
      ]
        .sort((a, b) => (b.timestamp ?? 0) - (a.timestamp ?? 0))
        .slice(0, 4),
    [carePathwayBaseHref, carePathwayRecentlyViewed, groupBaseHref, groupRecentlyViewed]
  );

  const handleClickOutSide = (event: any) => {
    if (dropdownnContainer.current?.contains(event.target)) {
      return;
    }
    setShowDropdown(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  useEffect(() => {
    setIsSecondTab(!pinned && isDetailsPage);
  }, [pinned, isDetailsPage, setIsSecondTab]);

  useEffect(() => {
    setFilterType(getFilterType);
  }, [getFilterType]);

  const modalVisibility = useContext(ModalVisibilityContext);

  return (
    <div className={styles.container}>
      <div
        ref={dropdownnContainer}
        className={pinned || (!pinned && !isDetailsPage) ? styles.displaySideMenu : styles.hideSideMenu}
      >
        {!isEdgeReceptionistView && (
          <>
            {isCarePlanEnabled && isEdgeAdminView ? (
              <div className={styles.section}>
                <ButtonAlt
                  icon="arrow_drop_down"
                  color="white"
                  variant="outlined"
                  iconPostFix
                  fullWidth
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  Add New
                </ButtonAlt>
                <div className={styles.menuWrapper}>
                  <div className={showDropdown ? styles.menuShow : styles.menuHide}>
                    <div
                      className={styles.menuItem}
                      onClick={() => {
                        modalVisibility?.setAddGroupModalVisible(true);
                        setShowDropdown(false);
                      }}
                    >
                      GROUP
                    </div>
                    <div
                      className={styles.menuItem}
                      onClick={() => {
                        modalVisibility?.setAddNewPathwayModalVisible(true);
                        setShowDropdown(false);
                      }}
                    >
                      CARE PATHWAY
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.section}>
                <ButtonAlt
                  icon="add_circle_outline"
                  color="white"
                  variant={'contained'}
                  fullWidth
                  onClick={() => modalVisibility?.setAddGroupModalVisible(true)}
                >
                  Add New Group
                </ButtonAlt>
              </div>
            )}
          </>
        )}
        <div className={styles.section}>
          <SubTab
            title={isCarePlanEnabled ? 'VIEW GROUP PROGRAMS' : 'VIEW BY STATUS'}
            options={[
              {
                label: 'Active',
                number: groupCountByStatus.active,
                value: GroupStatus.Active,
                href: `${groupBaseHref}/${GroupStatus.Active}`
              },
              {
                label: 'Closed',
                number: groupCountByStatus.closed,
                value: GroupStatus.Closed,
                href: `${groupBaseHref}/${GroupStatus.Closed}`
              }
            ]}
            activeOption={isGroupsPage ? filterType : ''}
          />
        </div>
        {isCarePlanEnabled && isEdgeAdminView && (
          <div className={styles.section}>
            <SubTab
              title="VIEW CARE PATHWAYS"
              options={[
                {
                  label: 'Active',
                  number: carePathwayCountByStatus?.active ?? 0,
                  value: CarePathwayStatus.Active,
                  href: `${carePathwayBaseHref}/${CarePathwayStatus.Active}`
                },
                {
                  label: 'Completed',
                  number: carePathwayCountByStatus?.completed ?? 0,
                  value: CarePathwayStatus.Completed,
                  href: `${carePathwayBaseHref}/${CarePathwayStatus.Completed}`
                },
                {
                  label: 'Draft',
                  number: carePathwayCountByStatus?.draft ?? 0,
                  value: CarePathwayStatus.Draft,
                  href: `${carePathwayBaseHref}/${CarePathwayStatus.Draft}`
                },
                {
                  label: 'Closed',
                  number: carePathwayCountByStatus?.closed ?? 0,
                  value: CarePathwayStatus.Closed,
                  href: `${carePathwayBaseHref}/${CarePathwayStatus.Closed}`
                }
              ]}
              activeOption={isCarePathwayPage ? filterType : ''}
            />
          </div>
        )}
        <div className={styles.section}>
          <ClientRecentlyViewed
            title="RECENTLY VIEWED"
            recentlyViewed={recentlyViewed.map((item) => {
              return { ...item, href: `${item.basePath}/${item.value}/details` } as SubTabOption;
            })}
            activeOption={filterType}
          />
        </div>
      </div>
      {isGroupsPage && !pinned && isDetailsPage && <GroupsDetailsSubTab />}
    </div>
  );
};

export default GroupsSubTab;
