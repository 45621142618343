import styles from './InvoiceListItem.module.scss';
import columnStyles from '../InvoiceListHeader/InvoiceListColumn.module.scss';
import moment from 'moment';
import classNames from 'classnames';
import { STATUS_LABELS } from 'pages/Invoices/components/InvoiceListing/components/ClientInvoiceItem/ClientInvoiceItem';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { selectFilters } from 'redux/invoices/invoiceSlice';
import { useAppSelector } from 'redux/hooks';
import { InvoiceWithType } from '../../constants';
import { ClientRecordType } from 'interfaces/Clients/clientsRecord';
import {
  MOMENTJS_DATE_MONTH_YEAR_FORMAT,
  MOMENTJS_TIME_FORMAT,
  MOMENTJS_YEAR_MONTH_DAY_FORMAT
} from 'utils/dateChecker';
import InvoiceActionMenuV2 from 'pages/Invoices/components/InvoiceListing/components/InvoiceActionMenuV2/InvoiceActionMenuV2';
import { InvoiceCreator, OnChangeInvoiceStatus } from 'pages/Invoices/interface';
import { useState } from 'react';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useCurrency } from 'utils/hooks/useCurrency';
import InvoiceHistoryItem from '../InvoiceHistoryItem/InvoiceHistoryItem';

interface InvoiceListItemProps {
  invoice: InvoiceWithType;
  isExistLocationWithType?: boolean;
  stripeAccountId: string | undefined;
  practiceName: string;
  showInvoiceType?: boolean;
  isPendingClaimDataLoading?: boolean;
  isSubmittedClaimDataLoading?: boolean;
  onChangeStatus: OnChangeInvoiceStatus;
  onPartialRefund: () => void;
  onFullRefund: () => void;
  onUpdateBalance: () => void;
  onWriteOff: () => void;
  onCreateClaim: () => void;
  onManualAdjustTotal: () => void;
  onMatchMBSBenefit: () => void;
}

export const InvoiceTypeMapping = {
  dva: 'DVA',
  bulkBill: 'Medicare BB',
  rebate: 'OOP Rebate',
  outOfPocket: 'OOP'
};

const InvoiceListItem = ({
  invoice,
  isExistLocationWithType,
  stripeAccountId,
  showInvoiceType,
  practiceName,
  isPendingClaimDataLoading,
  isSubmittedClaimDataLoading,
  onChangeStatus,
  onPartialRefund,
  onFullRefund,
  onUpdateBalance,
  onWriteOff,
  onCreateClaim,
  onManualAdjustTotal,
  onMatchMBSBenefit
}: InvoiceListItemProps) => {
  const { formatCurrency } = useCurrency();
  const filters = useAppSelector(selectFilters);
  const { participantType } = filters;

  const [isShowHistory, setIsShowHistory] = useState<boolean>(false);

  const onClickHistory = () => {
    setIsShowHistory(!isShowHistory);
  };

  const {
    clientRecord,
    invoiceId,
    status,
    dueDate,
    invoiceAmount,
    paid,
    owed = 0,
    group,
    invoiceType,
    createdAt,
    createdBy,
    histories
  } = invoice;
  const paidAmount = paid?.total || 0;
  const hasHistory = histories && histories.length > 0;

  return (
    <div className={styles.container}>
      <div className={styles.invoiceContainer}>
        <div className={columnStyles.invoiceId}>{invoiceId}</div>

        {/* <div className={columnStyles.issue}>
          <i className={`material-icons ${styles.flagIcon}`}>flag</i>
          <i className={`material-icons-outlined ${styles.errorIcon}`}>error_outline</i>
        </div> */}
        {showInvoiceType && (
          <div className={columnStyles.type}>
            {invoiceType && InvoiceTypeMapping[invoiceType] && (
              <div className={classNames(styles.typePill, styles[status])}>{InvoiceTypeMapping[invoiceType]}</div>
            )}
          </div>
        )}
        <div className={classNames(styles.client, columnStyles.client)}>
          {participantType === ParticipantType.Group ? (
            <div className={styles.groupName}>{group?.name}</div>
          ) : (
            clientRecord && (
              <>
                <div className={styles.clientName}>
                  <ClientAvatar
                    clientData={clientRecord.clientProfiles}
                    avatarSize={60}
                    displayFirstNameOnly={clientRecord.recordType === ClientRecordType.Couple}
                    displayLimit={clientRecord.recordType === ClientRecordType.Couple ? 2 : 1}
                    nameClassName={styles.clientName}
                    hideAvatar
                  />
                </div>
                <div className={styles.clientId}>{clientRecord?.tacklitId}</div>
                <div className={styles.clientId}>{clientRecord?.caseId}</div>
              </>
            )
          )}
        </div>
        <div className={classNames(columnStyles.createdAt, styles.createdAt)}>
          <div className={styles.automated}>
            {createdBy === InvoiceCreator.System && (
              <i className={`material-icons-outlined ${styles.automatedIcon}`}>bolt</i>
            )}
          </div>
          <div className={styles.generatedValue}>
            <span className={styles.generatedValueTime}>{moment(createdAt).format(MOMENTJS_TIME_FORMAT)}</span>
            <span className={styles.generatedValueDate}>
              {moment(createdAt).format(MOMENTJS_DATE_MONTH_YEAR_FORMAT)}
            </span>
          </div>
        </div>
        <div className={classNames(columnStyles.dueDate, styles.dueDate)}>
          {moment(dueDate, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format(MOMENTJS_DATE_MONTH_YEAR_FORMAT)}
        </div>
        <div className={columnStyles.status}>
          {status && <div className={classNames(styles.statusPill, styles[status])}>{STATUS_LABELS[status]}</div>}
        </div>
        <div className={classNames(columnStyles.total, styles.total)}>{formatCurrency(invoiceAmount)}</div>
        <div className={classNames(columnStyles.paid, styles.paid)}>{formatCurrency(paidAmount)}</div>
        <div className={classNames(columnStyles.owed, styles.owed)}>{formatCurrency(owed)}</div>
        <div className={columnStyles.actions}>
          {hasHistory && (
            <ButtonAlt
              variant="text"
              size="medium"
              icon={isShowHistory ? 'expand_less' : 'history'}
              fab
              onClick={onClickHistory}
            />
          )}
          <InvoiceActionMenuV2
            invoice={invoice}
            stripeAccountId={stripeAccountId}
            minifiedButton
            isExistLocationWithType={isExistLocationWithType}
            isPendingClaimDataLoading={isPendingClaimDataLoading}
            isSubmittedClaimDataLoading={isSubmittedClaimDataLoading}
            onChangeStatus={onChangeStatus}
            onPartialRefund={onPartialRefund}
            onFullRefund={onFullRefund}
            onUpdateBalance={onUpdateBalance}
            onWriteOff={onWriteOff}
            onCreateClaim={onCreateClaim}
            onManualAdjustTotal={onManualAdjustTotal}
            onMatchMBSBenefit={onMatchMBSBenefit}
          />
        </div>
      </div>

      {/* History */}
      {isShowHistory && (
        <div className={styles.historyWrapper}>
          <InvoiceHistoryItem
            histories={histories || []}
            clientName={clientRecord?.clientProfiles[0].name || ''}
            practiceName={practiceName}
          />
        </div>
      )}
    </div>
  );
};

export default InvoiceListItem;
