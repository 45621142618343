import { useEffect, useRef, useState } from 'react';
import WidgetTextEditor2 from 'components/WidgetTextEditor2/WidgetTextEditor2';

import styles from './BodyTextWidget.module.scss';
import classNames from 'classnames';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface BodyTextWidgetProps {
  data: any;
  id?: any;
  changeValue?: any;
  previewMode?: boolean;
  editMode?: boolean;
  enableMention?: boolean;
  topPosition?: number;
}

const BodyTextWidget = ({
  data,
  id,
  changeValue,
  previewMode,
  editMode,
  enableMention,
  topPosition
}: BodyTextWidgetProps) => {
  const bodyTextRef = useRef<HTMLDivElement>(null);
  const [isShowInput, setShowInput] = useState(false);
  const { isEdgeAdminView } = useGetAccountPackageView();

  const handleClick = (e: any) => {
    if (bodyTextRef.current?.contains(e.target)) {
      return;
    }
    if (changeValue && editMode) {
      changeValue(id, data.title, false);
    }
    const fromDisplayMode = document.getElementById(id)?.contains(e.target) || false;
    const onHandleResize = e.target?.outerHTML.includes('col-resize') || e.target?.outerHTML.includes('ResizeHandle');
    const notDNDSection = e.target?.outerHTML.includes('DragAndDropSection');
    const selectedId = document.getElementById(id)?.id;
    const isJoditToolbarContent = e.target?.parentNode?.parentNode?.outerHTML.includes('jodit-');
    if (onHandleResize || fromDisplayMode) {
      setShowInput(false);
    } else if (!isJoditToolbarContent) {
      const condition = selectedId === id && notDNDSection ? fromDisplayMode : false;
      setShowInput(condition);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const onClickEditText = () => {
    setShowInput(true);
    changeValue(id, data.title, true);
  };

  const onChangeValue = (val: any) => {
    changeValue(id, val, true);
  };

  if (previewMode) {
    return (
      <div className={`ql-snow ${styles.previewContainer}`}>
        <div className={`ql-editor ${styles.wrapper}`} dangerouslySetInnerHTML={{ __html: data.title }} />
      </div>
    );
  }

  return !isShowInput ? (
    <div className={`ql-snow ${styles.containerWrapper}`} id={id} onClick={onClickEditText}>
      <div className={`ql-editor ${styles.container}`} dangerouslySetInnerHTML={{ __html: data.title }} />
    </div>
  ) : (
    <div className={classNames(styles.containerWrapper, isEdgeAdminView && 't23-admin-theme')} ref={bodyTextRef}>
      <WidgetTextEditor2
        height={'auto'}
        minHeight={'auto'}
        hasBackground
        floatToolbar
        floatToolbarPosition={topPosition && topPosition <= 86 ? 'top' : 'bottom'}
        value={data.title}
        hasContainerBorder
        enableMention={enableMention}
        enableMentionCDF={enableMention}
        className={styles.editContainer}
        onChange={onChangeValue}
      />
    </div>
  );
};

export default BodyTextWidget;
