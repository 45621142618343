import { useState, useEffect, useRef } from 'react';
import styles from './ViewAction.module.scss';
import classnames from 'classnames';
import { combineName } from 'utils/general';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { SurveyFormInterface } from 'pages/Groups/Interfaces/GroupsSurvey';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface ViewActionProps {
  surveyFormData: SurveyFormInterface;
}

const ViewAction = ({ surveyFormData }: ViewActionProps) => {
  const navigate = useNavigate();
  const { CARECOORDINATION } = useRoutesGenerator();

  const menuNode = useRef<HTMLDivElement>(null);
  const menuWrapperNode = useRef<HTMLDivElement>(null);
  const [menuExpand, setMenuExpand] = useState(false);

  const disabledViewIcon =
    surveyFormData.clientProfiles.filter((clientP) => clientP._id?.length > 0 && clientP.surveyComplete).length < 1;

  const handleClick = (e: any) => {
    if (menuWrapperNode.current?.contains(e.target)) {
      return;
    }
    if (menuNode.current?.contains(e.target)) {
      return;
    }
    setMenuExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleDetailAction = (responseId: string) => {
    navigate(`${CARECOORDINATION.SURVEY.DETAIL}/${responseId}/clientRecordId/${surveyFormData.clientRecordId}`);
  };

  const handleExpandMenu = () => {
    if (!disabledViewIcon && surveyFormData.clientProfiles.length > 1) {
      setMenuExpand(!menuExpand);
    } else if (!disabledViewIcon) {
      handleDetailAction(surveyFormData.clientProfiles[0]._id);
    }
  };

  return (
    <div ref={menuNode}>
      <ButtonAlt
        size={'medium'}
        variant={'outlined'}
        fab
        onClick={handleExpandMenu}
        icon={'arrow_forward'}
        disabled={disabledViewIcon}
      />
      <div ref={menuWrapperNode} className={styles.menuWrapper}>
        <div className={menuExpand ? styles.menuShow : styles.menuHide}>
          {surveyFormData.clientProfiles.length > 1 &&
            surveyFormData.clientProfiles.map((client, index) => (
              <div
                id={client._id}
                key={index}
                className={classnames(
                  styles.itemCard,
                  !(client._id || client.surveyComplete) ? styles.disabledSubMenu : ''
                )}
                onClick={() => {
                  client._id && client.surveyComplete && handleDetailAction(client._id);
                }}
              >
                <div className={styles.label}>{combineName([client])}</div>
                <i className={`material-icons ${styles.itemIcon}`}>navigate_next</i>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ViewAction;
