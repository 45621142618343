import { KeyboardEvent, useState } from 'react';
import styles from './MultipleChoiceOptions.module.scss';
import Switch from 'components/Switch/Switch';
import { OpenDataFieldVariable } from '../../../../CollectData.interface';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import classNames from 'classnames';
import Button from 'components/v2/Button/Button';
import MaterialInput from 'components/MaterialInput/MaterialInput';

const MultipleChoiceOptions = ({
  showError,
  variable,
  allowEditMoreThanOne,
  setVariable
}: {
  showError: boolean;
  variable: Partial<OpenDataFieldVariable>;
  allowEditMoreThanOne: boolean;
  setVariable: (v: Partial<OpenDataFieldVariable>) => void;
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleAddOption = () => {
    if (inputValue && !variable.options?.map((i) => i.value).includes(inputValue)) {
      setVariable({
        ...variable,
        options: [
          ...(variable.options || []),
          {
            value: inputValue,
            description: inputValue,
            key: inputValue
          }
        ]
      });
      setInputValue('');
    }
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddOption();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.options}>
        <div className={styles.createOption}>
          <div className={styles.optionValues}>
            {variable.options?.map((item, index) => (
              <span key={index} className={styles.optionValue}>
                {item.value}{' '}
                <i
                  className={`material-icons-outlined ${styles.optionDeleteIcon}`}
                  onClick={() =>
                    setVariable({
                      ...variable,
                      options: variable.options?.filter((i) => i !== item)
                    })
                  }
                >
                  close
                </i>
              </span>
            ))}
          </div>
          <div className={styles.addOption}>
            <div className={styles.inputBox}>
              <MaterialInput
                id={'otherOption'}
                label=""
                className={styles.input}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleInputKeyDown}
                placeholder="Type in your options"
              />
              <i className={`material-icons-outlined ${styles.clearTextIcon}`} onClick={() => setInputValue('')}>
                close
              </i>
            </div>
            <Button className={styles.addOptionBtn} variant="link" icon="add" onClick={handleAddOption}>
              Add option
            </Button>
          </div>
        </div>
        <ErrorMessage
          visible={showError && !((variable.options?.length || 0) > 0)}
          error={'Please enter at least 1 option'}
        />
      </div>
      <div className={styles.acceptMultiple}>
        <Switch
          className={classNames(styles.switch, variable.maxSelection === 0 ? styles.checked : '')}
          labelClass={!allowEditMoreThanOne ? styles.disabledMoreThanOne : ''}
          name="moreThanOne"
          checked={variable.maxSelection === 0}
          onChange={(e) => {
            setVariable({ ...variable, maxSelection: e.target.checked ? 0 : 1 });
          }}
          disabled={!allowEditMoreThanOne}
        />
        <div>Accept more than one answer?</div>
      </div>
    </div>
  );
};

export default MultipleChoiceOptions;
