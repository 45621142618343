import { memo, useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import AppointmentTypeForm from '../AppointmentTypeForm/AppointmentTypeForm';
import styles from './CreateAppointmentTypeModal.module.scss';
import AppointmentScheduleForm from '../AppointmentScheduleForm/AppointmentScheduleForm';
import AppointmentDeliveryForm from '../AppointmentDeliveryForm/AppointmentDeliveryForm';
import classNames from 'classnames';
import ModalHeader from 'components/v2/ModalHeader/ModalHeader';
import { useGetAccessToken } from 'utils/hooks/token';
import { AppointmentType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { postSessionType, putSessionType } from 'utils/http/sessionType';

interface CreateAppointmentProps {
  visible: boolean;
  data?: AppointmentType;
  onPost: (onSubmitAppointmentType: Partial<AppointmentType>) => void;
  onClose: (createdSessionType?: AppointmentType) => void;
}

const CreateAppointmentTypeModal = ({ visible, data, onPost, onClose }: CreateAppointmentProps) => {
  const { token } = useGetAccessToken();
  const [error, setError] = useState('');

  const title = [
    <div>
      {data
        ? `Update ${data.participantType === ParticipantType.Activity ? 'activity' : 'appointment'} type`
        : 'Create a new appointment or activity type'}
    </div>,
    <div>
      Set up <span className={styles.highlighted}>General</span> schedule and format
    </div>,
    <div>
      Set up <span className={styles.highlighted}>General</span> delivery mode
    </div>
  ];

  const [step, setStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(data?.participantType === ParticipantType.Activity ? 1 : 3);
  const [values, setValues] = useState<Partial<AppointmentType>>();
  const [duration, setDuration] = useState(data?.duration.minutes || 0);

  useEffect(() => {
    setTotalSteps(data?.participantType === ParticipantType.Activity ? 1 : 3);
  }, [data]);

  const onCancel = () => {
    setStep(1);
    onClose();
  };

  const handleBackButton = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleChangeParticipantType = (participantType: ParticipantType) => {
    if (participantType === ParticipantType.Activity) {
      setTotalSteps(1);
    } else {
      setTotalSteps(3);
    }
  };

  const onSubmit = (nextStep: number) => async (newValues: Partial<AppointmentType>) => {
    if (nextStep === 1 || newValues.participantType === ParticipantType.Activity) {
      setError('');
      const payload: Partial<AppointmentType> = {
        ...values,
        ...newValues,
        ...(newValues.participantType === ParticipantType.Group && { assignmentMode: undefined }),
        ...(newValues.participantType === ParticipantType.Activity && {
          deliveryOptions: [],
          gap: 0
        })
      };

      try {
        let message = '';
        if (data && data._id) {
          await putSessionType(data._id, payload, token);
          message = 'Appointment type updated.';
        } else {
          await postSessionType(payload, token);
          message = 'Appointment type created.';
        }
        notification.success({
          message,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        onClose();
        setStep(1);
        onPost(payload);
      } catch (err) {
        console.error(err);
        const errMess = data ? 'update' : 'create';
        notification.error({
          message: `Something went wrong while trying to ${errMess}.`
        });
        setError(errMess);
      }
    } else {
      setValues({
        ...values,
        ...newValues
      });
      if (newValues?.duration) {
        setDuration(newValues.duration.minutes);
      }
      setStep(nextStep);
    }
  };

  return (
    <Modal bodyStyle={{ padding: 0 }} width={720} visible={visible} footer={null} destroyOnClose closable={false}>
      <ModalHeader
        title={title[step - 1]}
        currentStep={step}
        totalSteps={totalSteps}
        onBack={handleBackButton}
        onCancel={onCancel}
      />
      {step === 1 && (
        <div className={classNames(styles.formContainer)}>
          <AppointmentTypeForm
            data={data}
            onChangeParticipantType={handleChangeParticipantType}
            onSubmit={onSubmit(2)}
          />
        </div>
      )}
      {step === 2 && (
        <div className={classNames(styles.formContainer)}>
          <AppointmentScheduleForm data={data} onSubmit={onSubmit(3)} duration={duration} formValues={values} />
        </div>
      )}
      {step === 3 && (
        <div className={classNames(styles.formContainer)}>
          <AppointmentDeliveryForm data={data} errMess={error} onSubmit={onSubmit(1)} />
        </div>
      )}
    </Modal>
  );
};

export default memo(CreateAppointmentTypeModal);
