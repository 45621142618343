import Card from 'components/Card/Card';
import styles from './CarePathwayCard.module.scss';
import Badge from 'components/Badge/Badge';
import classNames from 'classnames';
import { useState } from 'react';
import moment from 'moment';
import CarePathwayCardMenu from '../CarePathwayCardMenu/CarePathwayCardMenu';
import CarePathwayAppointmentCard from './components/CarePathwayAppointmentCard/CarePathwayAppointmentCard';
import { CarePathwayList } from 'interfaces/CarePathway/CarePathway';
import CarePathwayBadge from '../CarePathwayBadge/CarePathwayBadge';

interface CarePathwayCardProps {
  carePathway: CarePathwayList;
}

export const CarePathwayCard = ({ carePathway }: CarePathwayCardProps) => {
  const [showDetail, setShowDetail] = useState(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);

  return (
    <Card className={classNames(styles.container, isShowConfirmDelete && styles.warningEpisode)}>
      <div className={styles.topContainer}>
        <div className={styles.details}>
          <div className={styles.headerRow}>
            {carePathway.name ? (
              <div className={styles.title}>{carePathway.name}</div>
            ) : (
              <div className={styles.noTitle}>untitled</div>
            )}
            <CarePathwayBadge status={carePathway.status} />
            <div className={styles.date}>
              <span>CREATED:</span>
              <span>{moment(carePathway.createdAt).format('DD/MM/YYYY')}</span>
            </div>
            <div className={styles.date}>
              <span>LAST EDIT:</span>
              <span>{moment(carePathway.updatedAt).format('DD/MM/YYYY')}</span>
            </div>
          </div>
          <div className={styles.participantRow}>
            <div className={styles.participant}>
              <Badge className={classNames(styles.badge, styles.participantBadge)} label="Client" />
              <div>
                {carePathway.clientRecord?.clientProfiles
                  ?.map((clientObj) => `${clientObj.firstName} ${clientObj.lastName}`)
                  .join(',')}
              </div>
            </div>
            <div className={styles.participant}>
              <Badge className={classNames(styles.badge, styles.participantBadge)} label="Lead" />
              <div>{carePathway.leadClinician?.name}</div>
            </div>
          </div>
        </div>
        <div className={styles.listCardMenu}>
          <CarePathwayCardMenu
            carePathwayId={carePathway?._id || ''}
            status={carePathway.status}
            isShowConfirmDelete={isShowConfirmDelete}
            setIsShowConfirmDelete={setIsShowConfirmDelete}
          />
        </div>
      </div>
      {carePathway.items.some((itemObj) => itemObj.appointment) && (
        <div className={styles.accordion}>
          <div className={styles.accordionTitle} onClick={() => setShowDetail((show) => !show)}>
            <span>{showDetail ? 'Hide ' : 'Show '} appointment details</span>
            <i className={`material-icons-outlined ${styles.expandIcon}`}>
              {showDetail ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </i>
          </div>
          {showDetail && <CarePathwayAppointmentCard carePathwayAppointment={carePathway.items} />}
        </div>
      )}
    </Card>
  );
};
