import FormSection from 'components/v2/FormSection/FormSection';
import Radio from 'components/Radio/Radio';
import styles from './Assignments.module.scss';
import { useField } from 'formik';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchClinicians } from '../../../../hooks/GetClinicians';
import AssignmentClinicians from './components/AssignmentClinicians/AssignmentClinicians';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { AssignmentMode } from 'interfaces/Schedule/AppointmentType';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

import { TFunction, useTranslation } from 'react-i18next';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { Skeleton } from 'antd';
import { useMemo } from 'react';

const getAssignmentOptions = ({
  t,
  includeAutoAssignOption,
  includeMentorOption
}: {
  t: TFunction;
  includeAutoAssignOption?: boolean;
  includeMentorOption: boolean;
}) => [
  { label: t('calendar.assignment_mode.practice'), value: AssignmentMode.Practice },
  ...(includeAutoAssignOption
    ? [
        {
          label: t('calendar.assignment_mode.auto_assign'),
          value: AssignmentMode.AutoAssign,
          tooltip: t('calendar.assignment_mode.auto_assign.tooltip')
        }
      ]
    : []),
  { label: t('calendar.assignment_mode.all'), value: AssignmentMode.All },
  ...(includeMentorOption
    ? [{ label: t('calendar.assignment_mode.all_mentors'), value: AssignmentMode.AllMentors }]
    : []),
  { label: t('calendar.assignment_mode.selected'), value: AssignmentMode.Selected },
  { label: t('calendar.assignment_mode.singlePractitioner'), value: AssignmentMode.Single }
];

interface AssignmentsProps {
  includeAutoAssignOption?: boolean;
}

const Assignments = ({ includeAutoAssignOption }: AssignmentsProps) => {
  const { token } = useGetAccessToken();
  const [{ value, onChange }] = useField('assignmentMode');
  const { clinicians, isCliniciansLoading } = useFetchClinicians(token);
  const { isMentorFeatureToggle } = useGetFeatureToggle();
  const [t] = useTranslation();

  const generateClinicianSelections = () => {
    return isCliniciansLoading ? (
      <div className={styles.loading} style={{ display: 'flex', justifyContent: 'center' }}>
        <LoadingCircle />
      </div>
    ) : clinicians.practitionerList.length > 0 ? (
      <>
        <AssignmentClinicians clinicians={clinicians.practitionerList} />
      </>
    ) : (
      <div>There is no clinicians</div>
    );
  };

  const singlePractitionerList = useMemo(
    () =>
      clinicians.practitionerList
        .map((practitioner) => ({
          value: practitioner._id,
          label: practitioner.name
        }))
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
    [clinicians.practitionerList]
  );

  return (
    <FormSection
      title={t('calendar.form.assignments.title')}
      help={t('calendar.form.assignments.help')}
      subtitle={t('calendar.form.assignments.subtitle')}
    >
      <div className={styles.assignmentForm}>
        <Radio
          name="assignmentMode"
          options={getAssignmentOptions({ t, includeAutoAssignOption, includeMentorOption: isMentorFeatureToggle })}
          value={value}
          onChange={onChange}
          vertical
        />
        {[AssignmentMode.Selected, AssignmentMode.AutoAssign].includes(value) && generateClinicianSelections()}
        {AssignmentMode.Single === value &&
          (isCliniciansLoading ? (
            <div className={styles.singlePractitionerLoading}>
              <Skeleton.Input active />
            </div>
          ) : (
            <FormikSelect
              placeholder="Select practitioner ..."
              name="singlePractitioner"
              className={styles.singlePractitioner}
              options={singlePractitionerList}
              errorClassName={styles.singlePractitionerError}
              noCreateNewOption
            />
          ))}
      </div>
    </FormSection>
  );
};

export default Assignments;
