import { ReactNode } from 'react';
import { configUK } from './configUK';
import { configAU } from './configAU';
import { configNZ } from './configNZ';
import { configSA } from './configSA';

export interface configInterface {
  countryCode: string;
  mobileNumberMinLength: number;
  mobileNumberMaxLength: number;
  moneyIcon: string;
  currencySymbol: string;
  currencyCode: string;
  mobileCountryCode: string;
  taxLabel: string;
  taxRateLabel: string;
  codeLabel: string;
  defaultTimezone: string;
  practiceSettings: {
    registrationNumberLabel: string;
    publicHolidayOption: {
      label: string;
      value: string;
    }[];
  };
  terms: {
    tnc: ReactNode;
    privacy: ReactNode;
  };
}

interface countryObject {
  [key: string]: configInterface;
}

export const GAE_REGION = process.env.REACT_APP_GAE_REGION || 'au';

const configMapping: countryObject = {
  gb: configUK,
  au: configAU,
  nz: configNZ,
  sa: configSA
};

export const config = {
  ...configMapping[GAE_REGION],
  /** pagination use */
  perPage: 150,
  /** in milliseconds */
  debounceDelay: 500
};
