import {
  ClientRecordListRequestPayload,
  ClientRecordListResponse,
  SearchFilterEnum
} from 'interfaces/Clients/clientRecordNew';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from '../../services/clinicianProfileServicesApiSlice';
import queryString from 'query-string';
import {
  ClientRecordType,
  GetClientProfileInterface,
  ProfileRole,
  clientRecordsEncryptedInterface
} from 'interfaces/Clients/clientsRecord';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';

export interface GetMinifiedClientRecordRequestParams {
  page: number;
  perPage: number;
  recordStatus: string;
  searchValue?: string;
  searchBy?: SearchFilterEnum;
  clinicianId?: string;
}

export interface MinifiedClientProfile {
  firstName: string;
  initials: string;
  initialsBackgroundColor: string;
  lastName: string;
  role: ProfileRole;
  _id: string;
}

export interface MinifiedClientRecord {
  _id: string;
  recordStatus: string;
  recordType: ClientRecordType;
  clientProfiles: MinifiedClientProfile[];
}

export interface MinifiedClientRecordResponse {
  clientRecords: MinifiedClientRecord[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export interface IClientRecordForInLineAppointmentRequest {
  accountId: string;
  asAdmin: boolean;
  params: {
    page: number;
    perPage: number;
    recordStatus: string;
    sortByClientName?: number;
    query?: string;
    clinicianId?: string;
  };
}

export const clientRecordsApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClientRecordsList: builder.query<ClientRecordListResponse, ClientRecordListRequestPayload>({
      query: ({ accountId, payload }) => ({
        url: `/accounts/${accountId}/client-records/listing?${queryString.stringify(payload)}`
      }),
      providesTags: [CPSTagTypes.ClientList]
    }),

    getMinifiedClientRecord: builder.query<
      MinifiedClientRecordResponse,
      { accountId: string; params: GetMinifiedClientRecordRequestParams }
    >({
      query: ({ accountId, params }) => ({
        url: `/accounts/${accountId}/client-records/minified?${queryString.stringify(params)}`
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, meta) => {
        if (meta.arg.params.page === 1) {
          return {
            clientRecords: newItems.clientRecords,
            paging: newItems.paging
          };
        }

        return {
          clientRecords: [
            ...currentCache.clientRecords,
            ...newItems.clientRecords.filter(
              (item) => !currentCache.clientRecords.map(({ _id }) => _id).includes(item._id)
            )
          ].sort((a, b) => {
            const nameA = `${a.clientProfiles[0].firstName} ${a.clientProfiles[0].lastName}`;
            const nameB = `${b.clientProfiles[0].firstName} ${b.clientProfiles[0].lastName}`;
            return nameA.localeCompare(nameB);
          }),
          paging: newItems.paging
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
      providesTags: (_result, _error, arg) => [
        { type: CPSTagTypes.ClientListMinified, searchValue: arg.params.searchValue }
      ],
      // typing expects never for this, which is wrong
      // https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#invalidating-tags
      // @ts-ignore
      invalidatesTags: (_result, _error, arg) => [
        { type: CPSTagTypes.ClientListMinified, searchValue: arg.params.searchValue }
      ]
    }),

    // Get client encrypted data by clientRecordId
    getClientEncryptedDataByClientRecordId: builder.query<
      clientRecordsEncryptedInterface,
      { clientRecordId: string; includePinnedContent?: boolean }
    >({
      query: ({ clientRecordId, includePinnedContent }) => ({
        url: `/clinicians/me/client-records/${clientRecordId}:getDecrypted${
          includePinnedContent ? '?includePinnedContent=true' : ''
        }`
      }),
      transformResponse: (response: clientRecordsEncryptedInterface) => {
        return {
          ...response,
          isCompleteRecords:
            response.clientProfiles[0].profileType === 'full' && !!response.clientProfiles[0].clientAuth0Id,
          allowCommunicationWithEmail: response.clientProfiles.some(
            (profileObj) =>
              profileObj.role !== 'child' &&
              profileObj.isPrimaryContact &&
              canClientReceiveEmail({
                communicationPreference: profileObj.communicationPreference
              }) &&
              profileObj.hasEmail
          ),
          allowCommunicationWithSms: response.clientProfiles.some(
            (profileObj) =>
              profileObj.role !== 'child' &&
              profileObj.isPrimaryContact &&
              canClientReceiveSms({
                communicationPreference: profileObj.communicationPreference
              }) &&
              profileObj.hasMobileNumber
          )
        };
      },
      providesTags: [CPSTagTypes.ClientEncrypted]
    }),

    // TODO: Need new minified endpoint for this
    getClientRecordForInlineAppointment: builder.query<
      GetClientProfileInterface,
      IClientRecordForInLineAppointmentRequest
    >({
      providesTags: [CPSTagTypes.ClientList],
      query: ({ accountId, asAdmin, params }) => ({
        url: `${asAdmin ? `/accounts/${accountId}` : '/clinicians/me'}/client-records?${
          queryString.stringify(params) ?? ''
        }`
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => `${endpointName}_${queryArgs?.params?.clinicianId || ''}`,
      merge: (currentCache, newItems, meta) => {
        if (meta.arg.params.page === 1) {
          return {
            clientRecords: newItems.clientRecords,
            paging: newItems.paging,
            recordStatusCounts: newItems.recordStatusCounts
          };
        }

        return {
          clientRecords: [
            ...currentCache.clientRecords,
            ...newItems.clientRecords.filter(
              (item) => !currentCache.clientRecords.map(({ _id }) => _id).includes(item._id)
            )
          ],
          paging: newItems.paging,
          recordStatusCounts: newItems.recordStatusCounts
        };
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.asAdmin !== previousArg?.asAdmin ||
        (currentArg?.params.page !== previousArg?.params.page && currentArg?.params.page !== 1) ||
        (currentArg?.params.query !== previousArg?.params.query && !!currentArg?.params.query)
    }),

    getClientRecordMinifiedForInlineAppointment: builder.query<
      MinifiedClientRecordResponse,
      { accountId: string; params: GetMinifiedClientRecordRequestParams }
    >({
      query: ({ accountId, params }) => ({
        url: `/accounts/${accountId}/client-records/minified?${queryString.stringify(params)}`
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        `${endpointName}_${queryArgs?.params?.clinicianId || ''}_${queryArgs?.params?.searchValue || ''}`,
      merge: (currentCache, newItems, meta) => {
        if (meta.arg.params.page === 1) {
          return {
            clientRecords: newItems.clientRecords,
            paging: newItems.paging
          };
        }

        return {
          clientRecords: [
            ...currentCache.clientRecords,
            ...newItems.clientRecords.filter(
              (item) => !currentCache.clientRecords.map(({ _id }) => _id).includes(item._id)
            )
          ].sort((a, b) => {
            const nameA = `${a.clientProfiles[0].firstName} ${a.clientProfiles[0].lastName}`;
            const nameB = `${b.clientProfiles[0].firstName} ${b.clientProfiles[0].lastName}`;
            return nameA.localeCompare(nameB);
          }),
          paging: newItems.paging
        };
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.params.page !== previousArg?.params.page && currentArg?.params.page !== 1
    }),
    unpinNote: builder.mutation({
      query: ({ clientRecordId, noteId }: { clientRecordId: string; noteId: string }) => ({
        url: `/clinicians/me/client-records/${clientRecordId}/pinned-notes/${noteId}`,
        method: 'PATCH'
      }),
      invalidatesTags: [CPSTagTypes.ClientEncrypted]
    })
  })
});

export const {
  useGetClientRecordsListQuery,
  useGetMinifiedClientRecordQuery,
  useGetClientEncryptedDataByClientRecordIdQuery,
  useGetClientRecordForInlineAppointmentQuery,
  useGetClientRecordMinifiedForInlineAppointmentQuery,
  useUnpinNoteMutation
} = clientRecordsApiSlice;
