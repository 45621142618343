import styles from './PackageAppointmentCard.module.scss';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import {
  DEFAULT_APPOINTMENT_TYPES_PER_PAGES,
  useGetAppointmentTypesQuery
} from 'redux/endpoints/scheduleServices/appointmentType';
import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { debounce } from 'lodash';

interface PackageAppointmentProps {
  index: number;
  appointmentData?: string;
  onSelectAppointmentType: (appointmentId: string) => void;
  onRemoveAppointment: () => void;
  onDuplicateAppointment: () => void;
  showPackageFee: boolean;
  showMaxLimit: boolean;
  checkValidation: boolean;
}

export const PackageAppointmentCard = ({
  index,
  appointmentData,
  onSelectAppointmentType,
  onRemoveAppointment,
  onDuplicateAppointment,
  checkValidation
}: PackageAppointmentProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();

  const [searchAppointmentType, setSearchAppointmentType] = useState('');
  const [apptListCurrentPage, setApptTypeListCurrentPage] = useState<number>(1);

  const {
    data: appointmentTypeData,
    isLoading: appointmentTypeLoading,
    isFetching: appointmentTypeFetching
  } = useGetAppointmentTypesQuery({
    name: searchAppointmentType,
    page: apptListCurrentPage,
    perPage: DEFAULT_APPOINTMENT_TYPES_PER_PAGES,
    participantType: [ParticipantType.Activity, ParticipantType.OneToOne].join(','),
    isActive: '1',
    infiniteLoad: true
  });

  const totalApptListPage = useMemo(
    () =>
      appointmentTypeData?.paging
        ? Math.ceil(appointmentTypeData.paging.totalItems / DEFAULT_APPOINTMENT_TYPES_PER_PAGES)
        : 1,
    [appointmentTypeData]
  );

  const loadMoreApptType = useCallback(() => {
    const page = appointmentTypeData?.paging.page || 1;
    if (!appointmentTypeFetching && page < totalApptListPage) {
      setApptTypeListCurrentPage(page + 1);
    }
  }, [totalApptListPage, appointmentTypeFetching, appointmentTypeData]);

  const hasMoreData = useMemo(() => totalApptListPage > apptListCurrentPage, [totalApptListPage, apptListCurrentPage]);

  const appointmentTypeOptions = appointmentTypeData
    ? appointmentTypeData.data.map((item) => ({
        value: item._id || '',
        label: item.name
      }))
    : [];

  const debounceSetSearchText = useMemo(
    () =>
      debounce((value) => {
        setSearchAppointmentType(value);
        setApptTypeListCurrentPage(1);
      }, 1000),
    []
  );

  return (
    <div className={classNames(styles.container, isEdgeAdminView && 't23-admin-theme')}>
      <div className={styles.firstSection}>
        <div className={`material-icons ${styles.icon}`}>drag_handle</div>
        <div className={styles.number}>{index + 1}</div>
        <div className={styles.appointmentType}>
          <DropdownSearchable
            labelClassName={styles.appointmentLabel}
            placeholder={'Appointment Type'}
            searchable
            selected={appointmentData}
            options={appointmentTypeOptions}
            isLoading={appointmentTypeLoading}
            hasMoreData={hasMoreData}
            loadMore={loadMoreApptType}
            onSelect={(value) => onSelectAppointmentType(value)}
            error={checkValidation && !appointmentData ? 'Please select appointment type' : ''}
            searchText={searchAppointmentType}
            setSearchText={debounceSetSearchText}
            isFetchingMore={appointmentTypeFetching}
          />
        </div>
      </div>
      {/*<div className={classNames(styles.divider, showPackageFee && styles.hideDivider)} />*/}
      {/*<div className={styles.secondSection}>*/}
      {/*  {!showPackageFee && (*/}
      {/*    <TitleInput*/}
      {/*      icon={'attach_money'}*/}
      {/*      inputContainer={styles.packageFeeInput}*/}
      {/*      defaultLine*/}
      {/*      inputProps={{*/}
      {/*        maxLength: 18,*/}
      {/*        placeholder: '',*/}
      {/*        value: convertCurrencyValue(appointmentData?.appointmentPrice || ''),*/}
      {/*        onChange: (e: ChangeEvent<HTMLInputElement>) =>*/}
      {/*          onChangeAppointmentCost(appointmentData?._id || '', e.target.value)*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</div>*/}
      {/*<div className={classNames(styles.divider, !showMaxLimit && styles.hideDivider)} />*/}
      <div className={styles.thirdSection}>
        <div className={styles.actionWrapper}>
          <ButtonAlt variant={'text'} size={'medium'} fab onClick={onDuplicateAppointment}>
            <div className={styles.duplicateBtn}>
              <div className={`material-icons ${styles.duplicateIcon}`}>content_copy</div>
              <div className={`material-icons ${styles.duplicateIcon}`}>arrow_downward</div>
            </div>
          </ButtonAlt>
          <ButtonAlt error variant={'text'} size={'medium'} icon={'delete_forever'} fab onClick={onRemoveAppointment} />
        </div>
      </div>
    </div>
  );
};
