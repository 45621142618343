import styles from './TiffViewer.module.scss';
import { TIFFViewer } from 'react-tiff';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useEffect, useRef, useState } from 'react';

interface TiffViewerProps {
  documentUrl: string;
}

const TiffViewer = ({ documentUrl }: TiffViewerProps) => {
  const tiffRef = useRef<HTMLDivElement>(null);
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkCanvas = () => {
      const container = tiffRef.current;
      if (container) {
        const canvas = container.querySelector('canvas');
        if (!canvas) {
          setError('Unable to load the TIFF document. The file appears to be corrupted or unsupported.');
        } else {
          setError(null);
        }
      }
    };

    // Delay to allow TIFFViewer to render the canvas
    const timeoutId = setTimeout(checkCanvas, 1000);

    // Cleanup the timeout on component unmount or documentUrl change
    return () => clearTimeout(timeoutId);
  }, [documentUrl]);

  return (
    <div ref={tiffRef} className={isEdgeAdminView ? styles.tiffPreviewAdminContainer : styles.tiffPreviewContainer}>
      {error ? (
        <div className={styles.error}>{error}</div>
      ) : (
        <TIFFViewer tiff={documentUrl} lang="en" paginate="bottom" />
      )}
    </div>
  );
};

export default TiffViewer;
