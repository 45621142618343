import { Modal } from 'antd';
import InvoiceView from 'components/InvoiceView/InvoiceView';
import moment from 'moment';
import { useRef } from 'react';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { Invoice, OnChangeInvoiceStatus } from '../../../../interface';
import InvoiceActionMenu from '../InvoiceActionMenu/InvoiceActionMenu';
import styles from './InvoiceModal.module.scss';
import { useFetchInvoiceById } from 'pages/InvoiceSubmittedClaims/hooks/fetchInvoiceById';
import { useGetAccessToken } from 'utils/hooks/token';
import Loading from 'components/Loading/Loading';
import { STATUS_LABELS } from '../ClientInvoiceItem/ClientInvoiceItem';
import { config } from 'config/config';

const MONEY_SYMBOL = config.currencySymbol;

/**
 * Either invoice or invoiceId is required in props.
 * If invoiceId is provided, it will fetch the invoice from backend.
 */
interface InvoiceModalProps {
  invoice?: Invoice;
  invoiceId?: string;
  visible: boolean;
  onChangeStatus?: OnChangeInvoiceStatus;
  onClose: () => void;
  onResendInvoice?: (_id: string, groupDetail?: Invoice['group']) => void;
  isInvoiceActionProcessing?: boolean;
}

const InvoiceModal = ({
  invoice: passedInvoice,
  invoiceId,
  visible,
  onChangeStatus,
  onClose,
  onResendInvoice,
  isInvoiceActionProcessing
}: InvoiceModalProps) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const { token } = useGetAccessToken();
  const { invoice: fetchedInvoice, isInvoiceLoading } = useFetchInvoiceById(token, invoiceId);

  const invoice = fetchedInvoice || passedInvoice;

  return (
    <Modal bodyStyle={{ padding: 0 }} width={768} footer={null} open={visible} destroyOnClose onCancel={onClose}>
      <div className={styles.title}>Invoice Overview</div>
      {isInvoiceLoading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        invoice && (
          <>
            {onChangeStatus && onResendInvoice && (
              <div className={styles.invoiceSummary}>
                <div className={styles.titles}>
                  <span className={styles.item}>Invoice ID</span>
                  <span className={styles.item}>Date sent</span>
                  <span className={styles.item}>Description</span>
                  <span className={styles.item}>Amount</span>
                  <span className={styles.item}>Status</span>
                  <span className={styles.item}>Action</span>
                </div>
                <div className={styles.values}>
                  <div className={styles.invoiceId}>{invoice.invoiceId}</div>
                  <span className={styles.issueDate}>
                    {moment(invoice.issueDate, 'YYYY-MM-DD').format(MOMENTJS_DATE_FORMAT)}
                  </span>
                  <span className={styles.description}>
                    {invoice.description
                      ? invoice.description.length > 70
                        ? `${invoice.description.slice(0, 70)}...`
                        : invoice.description
                      : '-'}
                  </span>
                  <span className={styles.invoiceAmount}>
                    {MONEY_SYMBOL}
                    {invoice.invoiceAmount.toFixed(2)}
                  </span>
                  <span className={`${styles.status} ${styles[invoice.status]}`}>{STATUS_LABELS[invoice.status]}</span>
                  <div ref={menuRef} className={styles.actionButtonContainer}>
                    <InvoiceActionMenu
                      status={invoice.status}
                      invoiceId={invoice._id}
                      isInvoiceActionProcessing={!!isInvoiceActionProcessing}
                      onChangeStatus={onChangeStatus}
                      onResendInvoice={onResendInvoice}
                      groupInvoiceDetail={invoice.group}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={styles.invoiceView}>
              <InvoiceView
                clientRecord={invoice.clientRecord}
                clinician={invoice.clinician}
                description={invoice.description}
                discount={invoice.discount}
                dueDate={invoice.dueDate}
                invoiceId={invoice.invoiceId}
                issueDate={invoice.issueDate}
                items={invoice.items}
                paymentMethods={invoice.paymentMethods}
                practice={invoice.practice}
                taxRate={invoice.taxRate}
                selectedGroup={invoice.group}
                medicare={invoice.medicare}
                amountChanged={invoice.amountChanged}
                invoiceAmount={invoice.invoiceAmount}
              />
            </div>
          </>
        )
      )}
    </Modal>
  );
};

export default InvoiceModal;
