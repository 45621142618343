export const validateTitle = (title: string) => {
  if (title.length <= 0) {
    return 'Please enter template title';
  }
  return undefined;
};

export const validateBody = (body: string) => {
  if (body.length <= 0) {
    return 'Content cannot be empty';
  }
  return undefined;
};

export const validate = (key: string, value: string) => {
  switch (key) {
    case 'title':
      return validateTitle(value);
    case 'body':
      return validateBody(value);
    default:
      return undefined;
  }
};

export const validateAll = (form: { title: string; body: string }) => {
  const errors = {
    title: validateTitle(form.title),
    body: validateBody(form.body)
  };

  return {
    errors,
    isValid: Object.values(errors).every((error) => error === undefined)
  };
};
