import styles from './AppointmentWithCalendar.module.scss';
import { CalendarNav, CalendarNext, CalendarPrev, Datepicker, MbscPageLoadedEvent } from '@mobiscroll/react';
import moment from 'moment/moment';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import './inlineCalendarMobiscrollCustom.scss';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import classNames from 'classnames';
import { getTimezoneLabel } from 'utils/hooks/GetTimezones/getTimezones';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  appointmentViewFilter,
  FilterTimes,
  selectAppointmentData,
  setSelectedDate,
  setSelectedTime,
  startInlineCalendarValidation
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { convertAppointmentTimeBetweenTimezones } from 'utils/helpers/timezone';
import TimeSlotCard from '../TimeSlotCard/TimeSlotCard';
import { AvailabilityTimeSlot, SelectedAppointmentSlotProps } from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';
import momentTz from 'moment-timezone';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { Skeleton } from 'antd';
import { DEFAULT_FETCHING_DATES, FetchingDates, getTimeRange } from '../AppointmentContent/AppointmentContent';
import { useGetAppointmentTypeAvailableSlotsQuery } from 'redux/endpoints/scheduleServices/appointmentType';
import { useDebounce } from 'utils/hooks/useDebounce';

const today = momentTz.utc().startOf('hour').add(59, 'minutes');
const yesterday = momentTz.utc().subtract(1, 'days').startOf('day');

export const filterSlotByFilterTime = ({
  availabilityTimeSlot,
  selectedAppointmentViewFilter
}: {
  availabilityTimeSlot: AvailabilityTimeSlot[];
  selectedAppointmentViewFilter?: FilterTimes;
}): AvailabilityTimeSlot[] => {
  const { start, end } = getTimeRange(selectedAppointmentViewFilter || FilterTimes.All);

  return availabilityTimeSlot.filter((slot) => {
    const slotStartTime = slot.startTime;
    return slotStartTime >= start && slotStartTime <= end;
  });
};

const AppointmentWithCalendar = () => {
  const dispatch = useAppDispatch();
  const calendarDatePickerRef = createRef<Datepicker>();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { timeZoneByView } = useTimeZone();
  const { selectedDate, selectedPractitioner, selectedTime, selectedAppointmentType, selectedDeliveryMode } =
    useAppSelector(selectAppointmentData);
  const selectedAppointmentViewFilter = useAppSelector(appointmentViewFilter);

  const [fetchedMonth, setFetchedMonth] = useState<string[]>([]);

  const inlineCalendarValidation = useAppSelector(startInlineCalendarValidation);

  const [selectedDay, setSelectedDay] = useState<string>(selectedDate);
  const [currentViewDate, setCurrentViewDate] = useState<FetchingDates>(DEFAULT_FETCHING_DATES);

  useEffect(() => {
    const weekDays = document.querySelectorAll('.inlineCalendar .mbsc-calendar-week-day');
    weekDays.forEach((day) => {
      const dayText = day?.textContent?.trim() || '';
      const parentDiv = day.closest('div');
      if (parentDiv) {
        parentDiv.setAttribute('aria-label', dayText);
      }
    });
  }, []);

  const [previousApptType, setPreviousApptType] = useState(selectedAppointmentType?._id);

  const dateRange = useDebounce(currentViewDate, 500);

  const {
    data: availabilityData,
    isLoading: isAvailabilityLoading,
    isFetching: isAvailabilityFetching
  } = useGetAppointmentTypeAvailableSlotsQuery(
    {
      from: selectedAppointmentType?._id !== previousApptType ? DEFAULT_FETCHING_DATES.from : dateRange.from,
      to: selectedAppointmentType?._id !== previousApptType ? DEFAULT_FETCHING_DATES.to : dateRange.to,
      type: 'booking',
      appointmentTypeId: selectedAppointmentType?._id || '',
      clinicianId: selectedPractitioner?._id || '',
      timeZone: timeZoneByView // we use view timezone because we want to show view timezone, after select only convert to selected practitioner timezone
    },
    { skip: !selectedAppointmentType }
  );

  // Reset currentViewDate when selectedAppointmentType changes
  useEffect(() => {
    if (selectedAppointmentType?._id !== previousApptType) {
      setCurrentViewDate(DEFAULT_FETCHING_DATES);
      setPreviousApptType(selectedAppointmentType?._id || '');
      const newDate = moment().format(MOMENTJS_FORMAT_DATE); // Change this to your logic
      const newDateObject = moment(newDate, MOMENTJS_FORMAT_DATE).toDate();
      setSelectedDay(newDate);
      setFetchedMonth([]);

      // Optional: Force update the Datepicker view
      if (calendarDatePickerRef.current) {
        calendarDatePickerRef.current.navigate(newDateObject);
      }
    }
  }, [selectedAppointmentType?._id, previousApptType, calendarDatePickerRef]);

  const { startDateTime: selectedPractitionerStartDateTime, endDateTime: selectedPractitionerEndDateTime } =
    selectedPractitioner?.workTimeZone && selectedPractitioner.workTimeZone !== timeZoneByView
      ? convertAppointmentTimeBetweenTimezones({
          date: selectedDate,
          startTime: selectedTime.startTime,
          endTime: selectedTime.endTime,
          originalTimezone: timeZoneByView,
          targetTimezone: selectedPractitioner?.workTimeZone || ''
        })
      : { startDateTime: undefined, endDateTime: undefined };

  const handleSelectDate = (value: Date) => {
    setSelectedDay(moment(value).format(MOMENTJS_FORMAT_DATE));
  };

  const handleSelectSlot = (slot: SelectedAppointmentSlotProps) => {
    dispatch(setSelectedDate(moment(slot.date).format(MOMENTJS_FORMAT_DATE)));
    dispatch(setSelectedTime({ startTime: slot.startTime, endTime: slot.endTime }));
  };

  const dateTimeSlot = useMemo(() => {
    return availabilityData?.timeSlots[selectedDay];
  }, [availabilityData, selectedDay]);

  const filterByTimeAvailabilityData = useMemo(
    () =>
      filterSlotByFilterTime({
        availabilityTimeSlot: dateTimeSlot?.isAvailable ? dateTimeSlot.timeSlots : [],
        selectedAppointmentViewFilter: selectedAppointmentViewFilter?.times
      }),
    [dateTimeSlot, selectedAppointmentViewFilter]
  );

  const handlePageChange = useCallback(
    (value: MbscPageLoadedEvent) => {
      const currentMonth = moment().startOf('month');
      const selectedMonth = moment(value.month).startOf('month');

      const isBeforeMonth = selectedMonth.isBefore(currentMonth);
      const formattedMonth = moment(value.month).format('YYYY-MM');

      if (!fetchedMonth.includes(formattedMonth)) {
        const startOfMonth = isBeforeMonth
          ? moment().toDate()
          : moment(value.month).subtract(1, 'month').startOf('month');
        const endOfMonth = isBeforeMonth
          ? moment().add(2, 'month').endOf('month')
          : moment(value.month).add(1, 'month').endOf('month');
        setCurrentViewDate({
          from: moment(startOfMonth).format(MOMENTJS_FORMAT_DATE),
          to: moment(endOfMonth).format(MOMENTJS_FORMAT_DATE)
        });
      }
    },
    [fetchedMonth]
  );

  const massageNotAvailableAppointmentSlot = useMemo(() => {
    const convertKeyToArray = availabilityData ? Object.keys(availabilityData?.timeSlots) : [];
    const uniqueYearMonth = [...Array.from(new Set(convertKeyToArray.map((date) => date.substring(0, 7))))];
    setFetchedMonth(uniqueYearMonth);

    return availabilityData
      ? Object.keys(availabilityData?.timeSlots).filter((date) => {
          const dateData = availabilityData?.timeSlots[date];
          if (!dateData.isAvailable) {
            return true;
          }
          if ('timeSlots' in dateData) {
            return dateData.timeSlots.every((slot) => !slot.isAvailable);
          }
          return false;
        })
      : [];
  }, [availabilityData]);

  const invalidTimes = useMemo(
    () => [
      {
        recurring: {
          repeat: 'daily',
          until: yesterday
        }
      },
      {
        start: yesterday,
        end: today
      },
      ...massageNotAvailableAppointmentSlot
    ],
    [massageNotAvailableAppointmentSlot]
  );

  const checkSelectedDayAreFetching = useMemo(() => {
    const formattedMonth = moment(selectedDay).format('YYYY-MM');

    return !fetchedMonth.includes(formattedMonth) && isAvailabilityFetching;
  }, [selectedDay, fetchedMonth, isAvailabilityFetching]);

  const isSlotNotSelected = useMemo(
    () => !selectedDate || !selectedTime.startTime || !selectedTime.endTime,
    [selectedDate, selectedTime]
  );

  return selectedAppointmentType && selectedDeliveryMode ? (
    <div className={styles.container}>
      {isAvailabilityLoading ? (
        <div className={classNames(styles.calendarContainer, styles.loadingContainer)}>
          <Skeleton.Input active />
          <br />
          <Skeleton active />
        </div>
      ) : (
        <div className={classNames('inlineCalendar', styles.calendarContainer, isEdgeAdminView && 'adminTheme')}>
          <Datepicker
            firstDay={1}
            pages={1}
            onChange={(e) => handleSelectDate(moment(e.value).toDate())}
            onPageChange={handlePageChange}
            calendarType={'month'}
            controls={['calendar']}
            showInput={false}
            isOpen
            theme={'ios'}
            themeVariant={'light'}
            display="inline"
            className={styles.datePicker}
            ref={calendarDatePickerRef}
            value={selectedDay}
            invalid={invalidTimes}
            renderCalendarHeader={() => (
              <div className={styles.customCalendarHeader}>
                <CalendarPrev className={styles.calendarArrow} />
                <CalendarNav className={styles.calendarToday} />
                <CalendarNext className={styles.calendarArrow} />
              </div>
            )}
          />
          <div className={styles.updatingMessageWrapper}>
            <div
              className={classNames(
                styles.updatingMessage,
                isAvailabilityFetching && styles.showUpdatingMessage,
                isEdgeAdminView && styles.updatingMessageAdmin
              )}
            >
              Fetching latest availability... <LoadingCircle className={styles.circleLoading} />
            </div>
          </div>
        </div>
      )}
      <div className={styles.slotWrapper}>
        <div
          className={classNames(
            styles.slotContainer,
            inlineCalendarValidation && isSlotNotSelected && styles.slotContainerError
          )}
        >
          <div className={styles.timezoneWrapper}>
            <i className={classNames('material-icons', styles.icon)}>schedule</i>
            <div className={styles.timezone}>
              <div>
                The timezone of the booking will be made in:
                <strong> {getTimezoneLabel(timeZoneByView)}</strong>
              </div>
              {/* There is a case that clinician doesn't have workTimeZone */}
              {selectedPractitionerStartDateTime && selectedPractitionerEndDateTime && (
                <div>
                  For <strong>{selectedPractitioner?.name}</strong> the time will be{' '}
                  <strong>
                    {selectedPractitionerStartDateTime.isSame(selectedPractitionerEndDateTime, 'date')
                      ? `${selectedPractitionerStartDateTime.format(
                          'dddd, Do MMMM HH:mm'
                        )} - ${selectedPractitionerEndDateTime.format('HH:mm')}`
                      : `${selectedPractitionerStartDateTime.format('dddd, Do MMMM HH:mm')} -
                  ${selectedPractitionerEndDateTime.format('dddd, Do MMMM HH:mm')}`}
                  </strong>
                </div>
              )}
            </div>
          </div>
          <div className={styles.timeSlotContainer}>
            <div className={styles.sessionInfoWrapper}>
              <div className={styles.sessionTitle}>
                {moment(selectedDay, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY')}
              </div>
              <div className={styles.sessionDesc}>
                Session duration: {selectedAppointmentType?.duration.minutes} min + {selectedAppointmentType?.gap} mins
                gap
              </div>
            </div>
            <TimeSlotCard
              isLoading={isAvailabilityLoading}
              isFetching={checkSelectedDayAreFetching} // need to check this month is loaded? if not need show fetching true
              isAvailabilitiesViewTypeSimple={false}
              selectedAppointmentSlot={{
                date: selectedDate,
                startTime: selectedTime.startTime,
                endTime: selectedTime.endTime
              }}
              showStartTimeOnly
              onSelectSlot={handleSelectSlot}
              data={{
                date: selectedDay,
                isAvailable: dateTimeSlot?.isAvailable || false,
                timeSlots: dateTimeSlot?.isAvailable ? filterByTimeAvailabilityData : []
              }}
            />
          </div>
        </div>
        {inlineCalendarValidation && isSlotNotSelected && (
          <div className={styles.errorMessage}>Please select timeslot</div>
        )}
      </div>
    </div>
  ) : (
    <div className={styles.noSelectWrapper}>Please select an appointment type to view available calendar slots.</div>
  );
};

export default AppointmentWithCalendar;
