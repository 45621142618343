import QRCode from 'qrcode.react';
import logo from 'assets/images/tacklit-icon.png';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import styles from './ClientCreation.module.scss';
import { getName } from 'utils/general';
import { useTranslation } from 'react-i18next';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';
import ShareableLinkButton from 'components/ShareableLinkButton/ShareableLinkButton';

interface ClientCreationProps {
  clientRecords: clientRecordsInterface;
  onResetForm: () => void;
  isTriageOnly?: boolean;
}

const ClientCreation = ({ clientRecords, onResetForm, isTriageOnly }: ClientCreationProps) => {
  const { CALENDAR, CLIENTS } = useRoutesGenerator();
  const [t] = useTranslation();

  const isOnboardingExist =
    clientRecords.clientProfiles && !!clientRecords.clientProfiles.filter((obj) => obj.onboardingSettings);
  const isFacilitatedExist =
    clientRecords.clientProfiles &&
    clientRecords.clientProfiles.filter(
      (obj) => isOnboardingExist && obj.onboardingSettings?.onboardingMethod === 'facilitated'
    ).length > 0;
  const singleInvitation = clientRecords.clientProfiles && clientRecords.clientProfiles.length <= 1;
  const isRecordOnly = clientRecords.clientProfiles && clientRecords.clientProfiles[0].profileType === 'recordOnly';

  let lang = {
    title: '',
    desc: ''
  };

  if (isFacilitatedExist) {
    lang.title = t('client.creation.complete.facilitated_exist');
    lang.desc = t('client.creation.complete.facilitated_exist.description');
  } else if (isOnboardingExist && !isRecordOnly) {
    lang.title = t('client.creation.complete.onboarding_exist');
    lang.desc = t('client.creation.complete.onboarding_exist.description');
  } else if (isOnboardingExist && isRecordOnly) {
    lang.title = t('client.creation.complete.onboarding_exist_n_record_only');
    lang.desc = t('client.creation.complete.onboarding_exist_n_record_only.description');
  } else {
    lang.title = t('client.creation.complete.other');
    lang.desc = t('client.creation.complete.other.description');
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>{lang.title}</div>
      <div className={styles.desc}>
        {isOnboardingExist && isRecordOnly && !isFacilitatedExist && (
          <a
            className={styles.link}
            href={`${CLIENTS.BASE}/${clientRecords._id}/profile`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Link to profile
          </a>
        )}
        {lang.desc}
      </div>
      {isFacilitatedExist ? (
        <div className={styles.facilitatedContainer}>
          {clientRecords.clientProfiles
            .filter((obj) => obj.onboardingSettings?.onboardingMethod === 'facilitated')
            .map((clientObj, index) => {
              const facilitatedLink = clientObj.onboardingSettings?.facilitationUrl || '';
              return (
                <div className={styles.facilitatedWrapper} key={index}>
                  <div className={styles.clientName}>{clientObj.name}</div>
                  <div className={styles.linkContainer}>
                    <div className={styles.linkWrapper}>
                      <a className={styles.link} href={facilitatedLink} target="_blank" rel="noopener noreferrer">
                        {facilitatedLink}
                      </a>
                      {
                        <ShareableLinkButton
                          id="copy-faciliated-url"
                          url={facilitatedLink}
                          tooltip="Copy facilitated link"
                        />
                      }
                    </div>
                    {!singleInvitation && (
                      <a href={facilitatedLink} target="_blank" rel="noopener noreferrer">
                        <ButtonAlt
                          size="medium"
                          variant="outlined"
                          icon="add_circle_outline"
                          iconVariant={IconVariant.Filled}
                        >
                          Open link in new tab
                        </ButtonAlt>
                      </a>
                    )}
                  </div>
                  <div className={styles.qrCodeWrapper}>
                    {facilitatedLink && (
                      <QRCode
                        value={facilitatedLink}
                        size={180}
                        bgColor={styles.whiteColor}
                        fgColor={styles.blackColor}
                        level="L"
                        includeMargin={false}
                        renderAs="svg"
                        imageSettings={{
                          src: logo,
                          height: 24,
                          width: 24,
                          excavate: true
                        }}
                      />
                    )}
                  </div>
                  {singleInvitation && (
                    <div className={styles.footerButtonWrapper}>
                      {!isTriageOnly && (
                        <ButtonAlt variant="outlined" onClick={() => onResetForm()} icon="add_circle_outline">
                          {t('label.create_another_new_client')}
                        </ButtonAlt>
                      )}
                      <ButtonAlt
                        variant="outlined"
                        onClick={() => window.open(facilitatedLink, '_blank')}
                        icon="add_circle_outline"
                      >
                        Open link in new tab
                      </ButtonAlt>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      ) : (
        !isTriageOnly && (
          <>
            <div className={styles.appointmentBtnWrapper}>
              <ButtonAlt variant="outlined" to={CALENDAR.BASE} icon="add_circle_outline">
                Schedule an appointment for {getName(clientRecords.clientProfiles[0])}
              </ButtonAlt>
            </div>
            <div className={styles.newClientBtnWrapper}>
              <ButtonAlt variant="text" onClick={() => onResetForm()} icon="add_circle_outline">
                {t('label.create_another_new_client')}
              </ButtonAlt>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default ClientCreation;
