import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';
import SubTab, { SubTabOption } from 'components/v2/SubTab/SubTab';
import { SubTabContext } from 'layouts/AuthLayoutT23/context/SubTabContextProvider';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from '../SubTabLayout.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

const FormsSubTab = () => {
  const location = useLocation();
  const { isEdgePlanUser } = useGetAccountPackage();
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { isInvoiceTemplateEnabled, isCarePlanEnabled } = useGetFeatureToggle();
  const isFormsPage = isEdgePlanUser
    ? location.pathname.split('/')[2] === 'forms'
    : location.pathname.split('/')[1] === 'forms';
  const isOtherPage = isEdgePlanUser ? location.pathname.split('/')[4] : location.pathname.split('/')[3];

  const getFilterType: string | undefined = isFormsPage
    ? isOtherPage
      ? isEdgePlanUser
        ? `${location.pathname.split('/')[3]}/${location.pathname.split('/')[4]}`
        : `${location.pathname.split('/')[2]}/${location.pathname.split('/')[3]}`
      : isEdgePlanUser
      ? (location.pathname.split('/')[3] as string) ?? ''
      : (location.pathname.split('/')[2] as string) ?? ''
    : undefined;
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();
  const [filterType, setFilterType] = useState<string | undefined>(getFilterType);
  const { setSendAdhocAssessmentVisible } = useContext(SubTabContext);

  useEffect(() => {
    setFilterType(getFilterType);
  }, [getFilterType]);

  const sendClickHandler = () => {
    if (location.pathname !== (FORMS.BASE || `${FORMS.BASE}/`)) {
      navigate(`${FORMS.BASE}/`);
    }
    setSendAdhocAssessmentVisible(true);
  };

  const baseHref = FORMS.BASE;

  const showSendFormBtn = !isEdgeReceptionistView;
  const showOAc = isEdgeAdminView || isNormalUserView;
  const showMCIc = isEdgeUserView || isNormalUserView;
  const showConsent = isEdgeAdminView || isNormalUserView;
  const showCaseNote = isEdgeAdminView || isNormalUserView;
  const showCarePlan = isCarePlanEnabled && (isEdgeAdminView || isNormalUserView);

  const formsSubTabList: Array<SubTabOption & { isDisplay: boolean }> = [
    { label: 'Survey Form', value: 'survey/newFa', isDisplay: showOAc, href: `${baseHref}/survey/newFa` },
    {
      label: 'Micro Check-in',
      value: 'microCheckIn/newMCI',
      isDisplay: showMCIc,
      href: `${baseHref}/microCheckIn/newMCI`
    },
    { label: 'Consent Form', value: 'consent/new', isDisplay: showConsent, href: `${baseHref}/consent/new` },
    { label: 'Case Note', value: 'caseNotes/new', isDisplay: showCaseNote, href: `${baseHref}/caseNotes/new` },
    { label: 'Letter', value: 'letters/new', isDisplay: true, href: `${baseHref}/letters/new` },
    { label: 'Report', value: 'reports/newTemplate', isDisplay: true, href: `${baseHref}/reports/newTemplate` },
    {
      label: 'Invoice',
      value: 'invoices/new',
      isDisplay: isInvoiceTemplateEnabled && (isEdgeAdminView || isEdgeReceptionistView),
      href: `${baseHref}/invoices/new`
    },
    {
      label: 'Care Pathway',
      value: 'carepathway/new',
      isDisplay: showCarePlan,
      href: FORMS.NEW_CARE_PATHWAY_TEMPLATE
    }
  ];

  const generateSubTab: SubTabOption[] = formsSubTabList
    .filter((item) => item.isDisplay)
    .map((item) => ({ label: item.label, value: item.value, href: item.href } as SubTabOption));

  return (
    <div className={styles.container}>
      {showSendFormBtn && (
        <div className={styles.section}>
          <ButtonAlt onClick={sendClickHandler} icon={'send'} fullWidth iconVariant={IconVariant.Filled}>
            Send Assessment
          </ButtonAlt>
        </div>
      )}
      <div className={styles.section}>
        <SubTab title="CREATE NEW TEMPLATE" options={generateSubTab} activeOption={filterType} />
      </div>
      <div className={styles.section}>
        <SubTab
          title="VIEW TEMPLATES"
          options={[{ label: 'All Templates', value: '', href: baseHref }]}
          activeOption={filterType}
        />
      </div>
    </div>
  );
};

export default FormsSubTab;
