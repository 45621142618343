import { notification } from 'antd';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getClientRecordByRecordId } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';

export const useFetchClientDetailByClientId = (token: string, clientId: string) => {
  const [clientDetails, setClientDetails] = useState<clientRecordsInterface>();
  const [isClientDetailLoading, setIsClientDetailLoading] = useState(true);
  const [t] = useTranslation();

  const fetchClientDetail = useCallback(
    async (token: string) => {
      try {
        const clientData = await (await getClientRecordByRecordId(token, clientId)).json();
        setClientDetails(clientData);
      } catch (ex) {
        console.error(ex);
        if (isErrorBentStatusError(ex) && (ex.statusCode === 401 || ex.statusCode === 403)) {
          notification.error({
            message: t('form.error.access_to_client')
          });
        } else {
          notification.error({
            message: t('form.error.failed_to_retrieve_this_client_detail')
          });
        }
      }

      setIsClientDetailLoading(false);
    },
    [clientId, t]
  );

  useEffect(() => {
    if (token) {
      fetchClientDetail(token);
    }
  }, [token, fetchClientDetail]);

  return { clientDetails, isClientDetailLoading, setClientDetails };
};
