import { DeliveryType, OtherInstructions } from 'interfaces/Schedule/AppointmentType';

export const DAY_SELECTION = [
  { key: 'today', label: 'Today', count: 0 },
  { key: 'tomorrow', label: 'Tomorrow', count: 0 },
  { key: 'thisWeek', label: 'This Week', count: 0 },
  { key: 'nextWeek', label: 'Next Week', count: 0 },
  { key: 'allUpcoming', label: 'All', count: 0 }
] as DaySelection[];

export enum FilterStatus {
  UPCOMING = 'upcoming',
  PAST = 'past'
}

export interface NextAppointmentInterface {
  date: string;
  deliveryType: DeliveryType;
  endTime: string;
  faceToFaceLocation: string;
  otherInstructions?: OtherInstructions;
  startTime: string;
  room?: {
    roomId: string;
    roomName: string;
  };
}

export enum DayFilter {
  Today = 'today',
  Tomorrow = 'tomorrow',
  ThisWeek = 'thisWeek',
  NextWeek = 'nextWeek',
  AllUpcoming = 'allUpcoming'
}

export interface DaySelection {
  key: DayFilter;
  label: string;
  count: number;
}

export const DEFAULT_MEMBER_FILTER = {
  filterStatus: FilterStatus.UPCOMING,
  selectedDay: DAY_SELECTION[0]
};

export interface MentorAppointmentFilter {
  filterStatus: FilterStatus;
  selectedDay: DaySelection;
}
