import classNames from 'classnames';
import { Translation } from 'i18n/types';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import {
  AvailabilityTimeSlot,
  NoAvailabilityReason,
  SelectedAppointmentSlotProps
} from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import {
  MOMENTJS_FORMAT_DATE,
  MOMENTJS_FORMAT_END_TIME_FORMAT,
  MOMENTJS_FORMAT_START_TIME_FORMAT,
  MOMENTJS_FORMAT_TIME
} from 'utils/appointment';

import { formatEndDate } from '../../utils/dateChecker';
import styles from './DayCard.module.scss';
import { Skeleton } from 'antd';

type DayCardProps = {
  isAvailabilitiesViewTypeSimple: boolean;
  selectedAppointmentSlot?: SelectedAppointmentSlotProps;
  onSelectSlot: (slot: SelectedAppointmentSlotProps) => void;
  data:
    | { date: string }
    | {
        date: string;
        isAvailable: boolean;
        timeSlots: AvailabilityTimeSlot[];
      };
  numberOfTimeSlotsShown?: number;
  isLoading?: boolean;
};

const noAvailabilityReasonTexts: Record<NoAvailabilityReason, Translation> = {
  [NoAvailabilityReason.CannotFitGap]: 'calendar.no_availability_reason.cannot_fit_gap',
  [NoAvailabilityReason.ClinicianBusy]: 'calendar.no_availability_reason.clinicion_busy',
  [NoAvailabilityReason.GroupBusy]: 'calendar.no_availability_reason.group_busy',
  [NoAvailabilityReason.IsPublicHoliday]: 'calendar.no_availability_reason.is_public_holiday',
  [NoAvailabilityReason.MaxForwardAvailability]: 'calendar.no_availability_reason.max_forward_availability',
  [NoAvailabilityReason.MaxSessionReached]: 'calendar.no_availability_reason.max_session_reached',
  [NoAvailabilityReason.MinTimeBufferBeforeBooking]: 'calendar.no_availability_reason.min_time_buffer_before_booking',
  [NoAvailabilityReason.NotInAppointmentSchedule]: 'calendar.no_availability_reason.not_in_appointment_schedule',
  [NoAvailabilityReason.NotInClinicianSchedule]: 'calendar.no_availability_reason.not_in_clinician_schedule',
  [NoAvailabilityReason.NotInRoomSchedule]: 'calendar.no_availability_reason.not_in_room_schedule',
  [NoAvailabilityReason.RoomBusy]: 'calendar.no_availability_reason.room_busy',
  [NoAvailabilityReason.TimeHasPassed]: 'calendar.no_availability_reason.time_has_passed'
};

const DayCard = ({
  isAvailabilitiesViewTypeSimple,
  selectedAppointmentSlot,
  onSelectSlot,
  data,
  numberOfTimeSlotsShown,
  isLoading
}: DayCardProps) => {
  const { date } = data;
  const isAvailable = 'isAvailable' in data ? data.isAvailable : false;
  const timeSlots = 'isAvailable' in data && data.isAvailable ? data.timeSlots : [];
  const [t] = useTranslation();
  const momentDate = moment(date, MOMENTJS_FORMAT_DATE);
  const formattedDate = momentDate.format(MOMENTJS_FORMAT_DATE);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(0);
  }, [data]);

  const handleLaterButtonClick = () => {
    const targetIndex = currentIndex + 1;
    setCurrentIndex(targetIndex);
  };

  const handleEarlierButtonClick = () => {
    const targetIndex = currentIndex - 1;
    setCurrentIndex(targetIndex);
  };

  const mapTimes = (timeSlots: AvailabilityTimeSlot[]) => {
    const filteredTimeSlots = isAvailabilitiesViewTypeSimple
      ? timeSlots.filter((x: { isAvailable: boolean }) => x.isAvailable)
      : timeSlots;

    const numberOfDisplay = numberOfTimeSlotsShown ? numberOfTimeSlotsShown : filteredTimeSlots.length;
    const groupedTimeSlots: AvailabilityTimeSlot[][] = [];
    for (let i = 0; i < filteredTimeSlots.length; i += numberOfDisplay) {
      const group = filteredTimeSlots.slice(i, i + numberOfDisplay);
      groupedTimeSlots.push(group);
    }

    const targetTimeSlots = groupedTimeSlots && groupedTimeSlots.length > 0 ? groupedTimeSlots[currentIndex] : [];

    const isAppointmentEqual = ({
      selectedAppointment,
      currentAppointment
    }: {
      selectedAppointment?: SelectedAppointmentSlotProps;
      currentAppointment: SelectedAppointmentSlotProps;
    }) => JSON.stringify(selectedAppointment) === JSON.stringify(currentAppointment);

    const mappedTimeSlots =
      targetTimeSlots && targetTimeSlots.length > 0
        ? groupedTimeSlots[currentIndex]
            .map((timeSlot, index) => {
              if (isAvailabilitiesViewTypeSimple && !timeSlot.isAvailable) {
                return undefined;
              }

              const formattedStartTime = moment(timeSlot.startTime, MOMENTJS_FORMAT_TIME).format(
                MOMENTJS_FORMAT_START_TIME_FORMAT
              );
              const formattedEndTime = moment(timeSlot.endTime, MOMENTJS_FORMAT_TIME).format(
                MOMENTJS_FORMAT_END_TIME_FORMAT
              );

              return (
                <div
                  key={index}
                  {...(!timeSlot.isAvailable && {
                    'data-tooltip-content': noAvailabilityReasonTexts[timeSlot.noAvailabilityReason]
                      ? t(noAvailabilityReasonTexts[timeSlot.noAvailabilityReason])
                      : timeSlot.noAvailabilityReason,
                    'data-tooltip-id': `unavailable-reason-${formattedDate}`
                  })}
                  className={classNames(
                    !timeSlot.isAvailable ? styles.timeButtonDisabled : styles.timeButton,
                    isAppointmentEqual({
                      selectedAppointment: selectedAppointmentSlot,
                      currentAppointment: {
                        date: formattedDate,
                        endDate: formatEndDate(timeSlot.endDateTime),
                        startTime: moment(timeSlot.startTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME),
                        endTime: moment(timeSlot.endTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME),
                        startDateTime: timeSlot.startDateTime,
                        endDateTime: timeSlot.endDateTime
                      }
                    }) && styles.active
                  )}
                  onClick={() => {
                    timeSlot.isAvailable &&
                      onSelectSlot({
                        date: formattedDate,
                        endDate: formatEndDate(timeSlot.endDateTime),
                        startTime: moment(timeSlot.startTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME),
                        endTime: moment(timeSlot.endTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME),
                        startDateTime: timeSlot.startDateTime,
                        endDateTime: timeSlot.endDateTime
                      });
                  }}
                >
                  {`${formattedStartTime} to ${formattedEndTime}`}
                </div>
              );
            })
            .filter((timeComponent) => !!timeComponent)
        : [];

    if (mappedTimeSlots.length === 0 && isAvailabilitiesViewTypeSimple) {
      return <div className={styles.noAvailability}>No availability</div>;
    }

    return (
      <div className={styles.timeSlotContainer}>
        {currentIndex !== 0 && (
          <ButtonAlt
            size={'small'}
            variant={'outlined'}
            icon={'arrow_upward'}
            iconPostFix
            onClick={handleEarlierButtonClick}
            className={styles.timeSlotButton}
          >
            EARLIER
          </ButtonAlt>
        )}
        {mappedTimeSlots}
        {currentIndex !== groupedTimeSlots.length - 1 && (
          <ButtonAlt
            size={'small'}
            variant={'outlined'}
            icon={'arrow_downward'}
            iconPostFix
            onClick={handleLaterButtonClick}
            className={styles.timeSlotButton}
          >
            LATER
          </ButtonAlt>
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.header, {
          [styles.active]: selectedAppointmentSlot?.date === formattedDate
        })}
      >
        <div className={styles.date}>{momentDate.format('dddd,')}</div>
        <div className={styles.date}>{momentDate.format('Do MMMM')}</div>
      </div>
      <div className={styles.body}>
        {isLoading ? (
          [...Array(6)].map((_obj, i) => (
            <div key={i} className={classNames(styles.loadingWrapper, styles.listItem)}>
              <Skeleton.Input active className={styles.loading} />
            </div>
          ))
        ) : isAvailable && timeSlots.length > 0 ? (
          mapTimes(timeSlots)
        ) : (
          <div className={styles.noAvailability}>No availability</div>
        )}
      </div>
      <Tooltip hidden={isAvailabilitiesViewTypeSimple} id={`unavailable-reason-${formattedDate}`} />
    </div>
  );
};

export default DayCard;
