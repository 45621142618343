import { Letter, LetterClientTab, LetterStatus } from 'interfaces/Letters/letter';
import { LetterTemplate } from 'interfaces/Letters/letterTemplate';
import queryString from 'query-string';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';

export interface GetLetterResponse {
  data: Letter[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export interface GetLetterRequestPayload {
  accountId: string;
  clinicianId?: string;
  payload: {
    page?: number;
    perPage?: number;
    status?: LetterStatus;
    search?: string;
    clientRecordId?: string;
  };
  isAdmin?: boolean;
}

export const letterApiSlice = documentServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLetters: builder.query<GetLetterResponse | LetterClientTab[], GetLetterRequestPayload>({
      query: ({ accountId, clinicianId, isAdmin, payload }) => ({
        url: `/accounts/${accountId}/${isAdmin ? 'letters' : `clinicians/${clinicianId}/letters`}?${
          payload ? queryString.stringify(payload) : ''
        }`
      }),
      providesTags: ['Letters']
    }),
    getLetterDetails: builder.query<
      Letter,
      { accountId: string; clinicianId?: string; isAdmin: boolean; letterId: string }
    >({
      query: ({ accountId, clinicianId, isAdmin, letterId }) => ({
        url: `/accounts/${accountId}/${
          isAdmin ? `letters/${letterId}` : `clinicians/${clinicianId}/letters/${letterId}`
        }`
      }),
      providesTags: ['Letter Details']
    }),
    getLetterTemplates: builder.query<LetterTemplate[], { accountId: string; asAdmin: boolean }>({
      query: ({ accountId, asAdmin }) => ({
        url: `/accounts/${accountId}/letter-templates${asAdmin ? `?asAdmin=true` : ''}`
      }),
      providesTags: ['Letter Templates']
    })
  })
});

export const { useGetLettersQuery, useGetLetterDetailsQuery, useGetLetterTemplatesQuery } = letterApiSlice;
