import { useState } from 'react';
import SessionAssigned from '../SessionAssigned/SessionAssigned';
import EventInformationModal from 'pages/Calendar/components/Modals/EventInformationModal/EventInformationModal';
import { GroupSessionDetails } from 'pages/Groups/Interfaces/GroupsSession';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import moment from 'moment';
import { DELIVERY_TYPE_ICONS, DeliveryType } from 'interfaces/Schedule/AppointmentType';

import styles from './SessionInfoBox.module.scss';
import { putGroupSessionDetails } from 'utils/http/ScheduleService/Groups/groups';
import { notification } from 'antd';
import { useGetAccessToken } from 'utils/hooks/token';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { getDeliveryTypeLabel } from 'utils/appointment';

interface SessionInfoBoxProps {
  sessionLabel: string;
  groupDetails: Groups;
  groupSessionDetails: GroupSessionDetails;
  groupId: string;
  sessionId: string;
  onEditAppointmentComplete: () => void;
  onCancelAppointmentComplete: () => void;
}

const SessionInfoBox = ({
  sessionLabel,
  groupDetails,
  groupSessionDetails,
  groupId,
  sessionId,
  onEditAppointmentComplete,
  onCancelAppointmentComplete
}: SessionInfoBoxProps) => {
  const { token } = useGetAccessToken();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeReceptionist, isEdgeAdminView, isEdgeUserView } = useGetAccountPackageView();
  const [showEditAppointmentModal, setShowEditAppointmentModal] = useState<boolean>(false);

  const isEventOwner = groupSessionDetails.clinicianId ? groupSessionDetails.clinicianId === auth0ClinicianId : true;
  const isEditable = isEdgeReceptionist || isEdgeAdminView || (isEdgeUserView && isEventOwner);

  const handleChangeFacilitator = async (
    facilitatorList: GroupSessionDetails['groupDetails']['deliveringPractitionerIds']
  ) => {
    try {
      const payload = {
        deliveringPractitionerIds: facilitatorList?.filter((clinicianId) => clinicianId)
      };
      await putGroupSessionDetails(token, groupId, sessionId, payload);
      notification.success({
        message: 'Facilitator updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Facilitator fail to updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const generateDeliveryDesc = (deliveryType: DeliveryType) => {
    switch (deliveryType) {
      case DeliveryType.PhoneCall:
        return <div className={styles.detailDesc}>{groupSessionDetails.phoneCallInstructions}</div>;
      case DeliveryType.PhoneCallDialClient:
        return <div className={styles.detailDesc}>{groupSessionDetails.phoneCallDialClientInstructions}</div>;
      case DeliveryType.VideoCall:
        return <div className={styles.detailDesc}>{groupSessionDetails.videoCallInstructions}</div>;
      case DeliveryType.FaceToFace:
        return <div className={styles.detailDesc}>{groupSessionDetails.faceToFaceLocation}</div>;
      case DeliveryType.Other:
        return <div className={styles.detailDesc}>{groupSessionDetails.otherInstructions?.instructions}</div>;
      default:
        return <div />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.dateInfo}>
        <div className={styles.time}>
          {moment(groupSessionDetails.startTime, 'HH:mm').format('hh:mm A')}
          {' - '}
          {moment(groupSessionDetails.endTime, 'HH:mm').format('hh:mm A')}
        </div>
        <div className={styles.appointmentDate}>
          {moment(groupSessionDetails.date, 'YYYY-MM-DD').format('dddd, Do MMMM')}
        </div>
        {isEditable && (
          <>
            <ButtonAlt
              contentClassName={styles.btnContent}
              size={'medium'}
              variant={'text'}
              onClick={() => {
                setShowEditAppointmentModal(true);
              }}
              icon={'edit'}
            >
              Change appointment
            </ButtonAlt>
            <EventInformationModal
              groupSessionLabel={sessionLabel}
              visible={showEditAppointmentModal}
              onClose={() => {
                setShowEditAppointmentModal(false);
              }}
              group={0}
              appointmentId={groupSessionDetails._id}
              appointment={groupSessionDetails}
              hideEditGroupBtn
              onEditComplete={() => {
                onEditAppointmentComplete();
              }}
              onCancelAppointmentComplete={onCancelAppointmentComplete}
            />
          </>
        )}
      </div>
      <div className={styles.detailInfo}>
        <div className={styles.detailCard}>
          <div className={`material-icons ${styles.detailIcon}`}>event</div>
          <div className={styles.descWrapper}>
            <div className={styles.detailTitle}>{groupSessionDetails?.name || groupSessionDetails.sessionTypeName}</div>
            {groupSessionDetails.sessionTypeDescription && (
              <div className={styles.detailDesc}>{groupSessionDetails.sessionTypeDescription}</div>
            )}
          </div>
        </div>
        {groupSessionDetails.room && (
          <div className={styles.detailCard}>
            <div className={`material-icons ${styles.detailIcon}`}>meeting_room</div>
            <div className={styles.descWrapper}>
              <div className={styles.detailTitle}>{groupSessionDetails.room.roomName}</div>
            </div>
          </div>
        )}
        <div className={styles.detailCard}>
          <div className={`material-icons ${styles.detailIcon}`}>
            {DELIVERY_TYPE_ICONS[groupSessionDetails.deliveryType!]}
          </div>
          <div className={styles.descWrapper}>
            <div className={styles.detailTitle}>
              {getDeliveryTypeLabel(groupSessionDetails.deliveryType, groupSessionDetails.otherInstructions)}
            </div>
            {generateDeliveryDesc(groupSessionDetails.deliveryType!)}
          </div>
        </div>
      </div>
      <SessionAssigned
        groupDetails={groupDetails}
        selectedPractitioner={groupSessionDetails.groupDetails.deliveringPractitionerIds}
        selectedPractitionerProfile={groupSessionDetails.groupDetails.deliveringPractitionerProfiles}
        onChangeFacilitator={handleChangeFacilitator}
      />
    </div>
  );
};

export default SessionInfoBox;
