import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { FC, useEffect, useState } from 'react';
import InfiniteScroller from 'react-infinite-scroller';
import {
  useGetCurrentActiveEpisodeQuery,
  useGetEpisodeListQuery
} from 'redux/endpoints/clinicianProfileServices/episode';
import { useFetchEpisodes } from './hooks/useFetchEpisodes';
import styles from './ClientDetailsEoC.module.scss';
import EpisodeCardLoading from './components/EpisodeCardLoading/EpisodeCardLoading';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { EpisodeListing } from './components/EpisodeListing/EpisodeListing';
import { EpisodeStatus } from 'interfaces/Episodes/episodes';
import NO_RESULT_IMG from 'assets/images/reportBuilderImg.png';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { EpisodeModal } from './components/EpisodeModal/EpisodeModal';

interface ClientDetailsEoCProps {
  clientRecord: clientRecordsInterface;
}

const ClientDetailsEoC: FC<ClientDetailsEoCProps> = ({ clientRecord }) => {
  const [isNewEpisodeModalVisible, setIsNewEpisodeModalVisible] = useState(false);
  const { pagination, fetchMoreEpisodes, episodes, handleUpdateEpisodes, handleUpdatePaging, hasMore } =
    useFetchEpisodes();

  const { accountTimeZone } = useTimeZone();

  const { data: episodeListResponse, isLoading } = useGetEpisodeListQuery(
    {
      clientRecordId: clientRecord._id,
      timezone: accountTimeZone,
      params: {
        page: pagination.page,
        perPage: pagination.perPage,
        status: Object.values(EpisodeStatus)
          .filter((item) => item !== EpisodeStatus.Active && item !== EpisodeStatus.OnHold)
          .join(',')
      }
    },
    { skip: !hasMore }
  );

  const { data: currentActiveEpisode } = useGetCurrentActiveEpisodeQuery({
    clientRecordId: clientRecord._id,
    timezone: accountTimeZone
  });

  useEffect(() => {
    if (episodeListResponse?.episodes && episodeListResponse?.paging) {
      handleUpdateEpisodes(episodeListResponse.episodes, currentActiveEpisode?._id || '');
      handleUpdatePaging(episodeListResponse.paging);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeListResponse, currentActiveEpisode]);

  return isLoading ? (
    <div className={styles.container}>
      {[...Array(3)].map((_, i) => (
        <EpisodeCardLoading key={i} />
      ))}
    </div>
  ) : episodes && episodes.length === 0 && !currentActiveEpisode?._id ? (
    <div className={styles.noDataWrapper} id={'noEOCId'}>
      <img className={styles.image} alt={'no eoc img'} src={NO_RESULT_IMG} />
      <div className={styles.infoWrapper}>
        <div className={styles.text}>No episode created for this client yet</div>
        <ButtonAlt
          size={'medium'}
          variant="outlined"
          onClick={() => setIsNewEpisodeModalVisible(true)}
          icon="add_circle_outline"
        >
          Create New Episode
        </ButtonAlt>
      </div>
      {isNewEpisodeModalVisible && (
        <EpisodeModal
          visible={isNewEpisodeModalVisible}
          onClose={() => setIsNewEpisodeModalVisible(false)}
          clientRecord={clientRecord}
          mode={'create'}
        />
      )}
    </div>
  ) : (
    <div className={styles.container}>
      <InfiniteScroller
        initialLoad={false}
        loadMore={fetchMoreEpisodes}
        useWindow
        hasMore={hasMore}
        loader={
          <div className={styles.loading} key={0}>
            <EpisodeCardLoading />
          </div>
        }
      >
        <EpisodeListing clientRecord={clientRecord} episodes={episodes} activeEpisode={currentActiveEpisode} />
      </InfiniteScroller>
    </div>
  );
};

export default ClientDetailsEoC;
