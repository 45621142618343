import styles from './PackageCard.module.scss';
import PackageStatusBadge from '../PackageStatusBadge/PackageStatusBadge';
import { PackagesInterface, PackagesStatus } from 'interfaces/Packages/packages';
import classNames from 'classnames';
import PackageTypeBadge from '../PackageTypeBadge/PackageTypeBadge';
import FunderBadge from '../FunderBadge/FunderBadge';
import PackageIcon from '../PackageIcon/PackageIcon';
import { PackageModal } from '../PackageModal/PackageModal';
import { useState } from 'react';
import NestedSelect, { DropdownType } from 'components/Select/NestedSelect/NestedSelect';
import { notification } from 'antd';
import { usePatchPackageStatusMutation } from 'redux/endpoints/scheduleServices/package';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

export enum PackageCardAction {
  ChangeStatus = 'changeStatus',
  Edit = 'edit'
}

interface PackageCardProps {
  packagesData: PackagesInterface;
}

const PackageCard = ({ packagesData }: PackageCardProps) => {
  const { accountId } = useGetAccountId();
  const [isPackageModalVisible, setIsPackageModalVisible] = useState(false);

  const [changePackageStatus] = usePatchPackageStatusMutation();
  const ACTION_OPTION = [
    {
      value: PackageCardAction.Edit,
      label: 'Edit Package'
    },
    {
      value: PackageCardAction.ChangeStatus,
      label: 'Change status',
      subMenu: [{ value: PackagesStatus.Active, label: <PackageStatusBadge status={PackagesStatus.Active} /> }]
    }
  ];

  const handleClickDropdownMenu = async ({
    firstSelectedOption,
    secondSelectedOption
  }: {
    firstSelectedOption?: string;
    secondSelectedOption?: PackagesStatus | string;
  }) => {
    switch (firstSelectedOption) {
      case PackageCardAction.Edit: {
        setIsPackageModalVisible(true);
        break;
      }
      case PackageCardAction.ChangeStatus: {
        if (!secondSelectedOption) return;

        try {
          const payload = { status: secondSelectedOption };
          await changePackageStatus({
            accountId: accountId,
            packageId: packagesData._id || '',
            payload
          });
        } catch (ex) {
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to change package status.'
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      <div className={classNames(styles.container, styles[`${packagesData.status}Line`])}>
        <div className={styles.headerContainer}>
          <div className={styles.headerInfo}>
            <div className={styles.title}>{packagesData.name}</div>
            <div className={styles.billerCode}>{packagesData.billingCode}</div>
          </div>
          <div className={styles.actionWrapper}>
            <PackageStatusBadge status={packagesData.status} />
            {packagesData.status === PackagesStatus.Draft && (
              <div className={styles.menuContainer}>
                <NestedSelect
                  type={DropdownType.Menu}
                  options={ACTION_OPTION}
                  value={{}}
                  onSelected={handleClickDropdownMenu}
                  positionSubMenu={'left'}
                  isSmallSubMenu
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.description}>{packagesData.description}</div>
        <div className={styles.controlContainer}>
          <PackageIcon settingsType={packagesData.serviceAccessOrder} />
          <PackageIcon settingsType={packagesData.benefitControl?.limitMode} />
          <PackageIcon settingsType={packagesData.benefitControl?.by} />
        </div>
        <div className={styles.otherInfoContainer}>
          <div className={styles.otherInfoBox}>
            <div className={styles.otherTitle}>TYPE</div>
            <div className={styles.badgeList}>
              <PackageTypeBadge type={packagesData.type!} />
            </div>
          </div>
          <div className={styles.otherInfoBox}>
            <div className={styles.otherTitle}>FUNDERS</div>
            <div className={styles.badgeList}>
              {packagesData.funders.map((funderObj, index) => (
                <FunderBadge key={index} funder={funderObj.name} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <PackageModal
        visible={isPackageModalVisible}
        onClose={() => setIsPackageModalVisible(false)}
        packagesDetail={packagesData}
      />
    </>
  );
};

export default PackageCard;
