import styles from './InfoCTABox.module.scss';

interface InfoCTABoxProps {
  ctaOnClick: () => void;
  ctaDisabled: boolean;
}

const InfoCTABox = ({ ctaOnClick, ctaDisabled }: InfoCTABoxProps) => {
  return (
    <div className={styles.infoBox}>
      <div className={styles.infoBoxIcon}>
        <i className={'material-icons-outlined'}>info</i>
      </div>
      <div className={styles.infoBoxTextAndCTA}>
        <div className={styles.infoBoxText}>
          When changing this profile's status do you want to trigger an onboarding or triage? If yes, please check the
          contact details below and continue.
        </div>
        <div
          className={ctaDisabled ? styles.infoBoxCTADisabled : styles.infoBoxCTA}
          onClick={ctaDisabled ? undefined : ctaOnClick}
        >
          Update status and proceed to onboarding options
          <i className={'material-icons-outlined'}>content_paste_go</i>
        </div>
      </div>
    </div>
  );
};

export default InfoCTABox;
