import classNames from 'classnames';
import EpisodeTaggingSection from 'components/EpisodeTaggingSection/EpisodeTaggingSection';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { PackageFunderQuotaStatus, PackagesStatus } from 'interfaces/Packages/packages';
import { REFERRAL_STATUS_LABELS, ReferralStatus } from 'interfaces/Referral/Referral';
import { debounce } from 'lodash';
import { PRESENTING_ISSUES } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/AddOrEditReferralForm/AddOrEditReferralForm';
import { ReferrerType } from 'pages/Referrals/interface';
import { getReferrerTypeOptions } from 'pages/Referrals/utils';
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { useGetPackageListQuery } from 'redux/endpoints/scheduleServices/package';
import { convertDateFormat, MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { ReferralFieldsPayload, ReferralValidateField } from '../AddReferralModal/AddReferralModalInterfaces';
import styles from './ReferralForm.module.scss';
import ReferralDocumentForm, { ReferralDocumentFormRef } from './components/ReferralDocumentForm/ReferralDocumentForm';
import ReferralSubjectForm from './components/ReferralSubjectForm/ReferralSubjectForm';
import ReferrerForm, { ReferralFormMode } from './components/ReferrerForm/ReferrerForm';
import { validateReferralForm } from './validation/ReferralFormValidation';

interface ReferralFormProps {
  mode: ReferralFormMode;
  referralFields: ReferralFieldsPayload;
  setReferralFields: Dispatch<SetStateAction<ReferralFieldsPayload>>;
  onUploadFiles: (files: File[]) => void;
}

export const PACKAGE_LIMIT = 3;
const PACKAGE_PER_PAGE = 20;

const STATUS_OPTIONS = Object.values(ReferralStatus).map((status) => ({
  name: REFERRAL_STATUS_LABELS[status].label,
  _id: status
}));

export const referralFormValidationInitialMessage = {
  date: '',
  presentingIssue: '',
  detail: '',
  expiryDate: '',
  status: '',
  referrer: {
    referrerType: '',
    firstName: '',
    lastName: '',
    relationship: '',
    role: '',
    name: '',
    mobile: ''
  },
  subject: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    providerNumber: '',
    email: '',
    mobile: ''
  },
  packages: [],
  org: {
    name: '',
    type: '',
    phone: '',
    fax: '',
    email: '',
    billerCode: ''
  }
};

export interface ReferralFormRef {
  validateFields: (fields: ReferralFieldsPayload) => Promise<boolean>;
  resetFiles: () => void;
}

interface SelectedPackage {
  packageId: string;
  funderId: string;
}

const ReferralForm = forwardRef<ReferralFormRef, ReferralFormProps>(
  ({ referralFields, setReferralFields, mode = ReferralFormMode.Add, onUploadFiles }, ref) => {
    const { accountId } = useGetAccountId();
    const { isPackageEnabled, isEoCEnabled } = useGetFeatureToggle();
    const documentFormRef = useRef<ReferralDocumentFormRef>(null);

    const [packageListCurrentPage, setPackageListCurrentPage] = useState<number>(1);
    const [packageSearch, setPackageSearch] = useState<string>('');
    const [isIndividual, setIsIndividual] = useState<boolean>(
      mode === ReferralFormMode.Edit && referralFields.referrer.referrerType === ReferrerType.Organisation
        ? !!referralFields.referrer.firstName
        : false
    );
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<ReferralValidateField>(referralFormValidationInitialMessage);
    const [selectedPackages, setSelectedPackages] = useState<SelectedPackage[]>(referralFields.packages || []);

    const referrerType = useMemo(
      () => referralFields.referrer.referrerType || ReferrerType.Self,
      [referralFields.referrer.referrerType]
    );

    const {
      data: packageFunderData,
      isLoading: packageFunderDataLoading,
      isFetching: packageFunderDataFetching
    } = useGetPackageListQuery(
      {
        accountId: accountId,
        params: {
          page: packageListCurrentPage,
          perPage: PACKAGE_PER_PAGE,
          status: PackagesStatus.Active,
          searchValue: packageSearch,
          searchBy: 'name'
        },
        infiniteLoad: true
      },
      { skip: !accountId || !isPackageEnabled }
    );

    const validateReferralFields = async (referralFieldsVal: ReferralFieldsPayload) => {
      const { isError: isErrorResponse, messages } =
        validateReferralForm(
          referralFieldsVal,
          referrerType,
          referralFields.referrer.referrerType === ReferrerType.Organisation && isIndividual
        ) || {};
      setIsError(isErrorResponse);
      setErrorMessage(messages as ReferralValidateField);

      return !isErrorResponse;
    };

    useImperativeHandle(ref, () => ({
      validateFields: async (fields: ReferralFieldsPayload) => await validateReferralFields(fields),
      resetFiles: () => documentFormRef.current?.resetFiles()
    }));

    const onChangeReferralForm = async (val: ReferralFieldsPayload) => {
      if (isError) await validateReferralFields(val);
      setReferralFields(val);
    };

    const handleChangeFieldValue = (key: keyof ReferralFieldsPayload, val: string) => {
      const newReferalFields = {
        ...referralFields,
        [key]: val
      };
      onChangeReferralForm(newReferalFields);
    };

    const handleChangeSubjectFieldValue = (key: string, val: string) => {
      const newReferalFields = {
        ...referralFields,
        subject: {
          ...(referralFields.subject || referralFormValidationInitialMessage.subject),
          [key]: val
        }
      };
      onChangeReferralForm(newReferalFields);
    };

    const handleChangeReferrerFieldValue = (key: string, val: string, keyType?: string) => {
      const newReferalFields = {
        ...referralFields,
        ...(keyType === ReferrerType.Organisation
          ? {
              org: {
                ...referralFormValidationInitialMessage.org,
                ...referralFields.org,
                [key]: val
              }
            }
          : {}),

        referrer: {
          ...referralFields.referrer,
          [key]: val
        }
      };
      onChangeReferralForm(newReferalFields);
    };

    const handleChangeLinkedEpisodeValue = (val: string) => {
      const newReferalFields = {
        ...referralFields,
        linkedEpisodes: [val]
      };
      onChangeReferralForm(newReferalFields);
    };

    const handleResetReferrer = (referrerType: ReferrerType) => {
      const newReferalFields = {
        ...referralFields,
        referrer: {
          referrerType
        }
      };
      onChangeReferralForm(newReferalFields);
    };

    const debounceSetPackageSearch = useMemo(
      () =>
        debounce((value) => {
          setPackageSearch(value);
          setPackageListCurrentPage(1);
        }, 1000),
      []
    );

    const addAnotherPackage = () => {
      const newPackage = {
        packageId: '',
        funderId: ''
      };
      setSelectedPackages((prevState) => [...prevState, newPackage]);

      const newReferalFields = {
        ...referralFields,
        packages: [...(selectedPackages || []), newPackage]
      };
      onChangeReferralForm(newReferalFields);

      setErrorMessage({
        ...errorMessage,
        packages: [...((typeof errorMessage.packages !== 'string' && errorMessage.packages) || []), newPackage]
      });
    };

    const updatePackage = (index: number, key: string, value: string) => {
      setSelectedPackages((prevState) => {
        const updatedPackages = [...prevState];
        updatedPackages[index] = {
          ...updatedPackages[index],
          [key]: value
        };
        return updatedPackages;
      });

      const newPackages = [...(referralFields.packages || [])];
      newPackages[index] = {
        ...newPackages[index],
        [key]: value
      };

      onChangeReferralForm({
        ...referralFields,
        packages: newPackages
      });
    };

    const handleRemovePackage = (index: number) => {
      setSelectedPackages((prevState) => {
        return prevState.filter((_, idx) => idx !== index);
      });

      onChangeReferralForm({
        ...referralFields,
        packages: selectedPackages.filter((_, idx) => idx !== index)
      });

      setErrorMessage({
        ...errorMessage,
        packages: ((typeof errorMessage.packages !== 'string' && errorMessage.packages) || []).filter(
          (_, idx) => idx !== index
        )
      });
    };

    const totalPackageListPage = packageFunderData?.paging
      ? Math.ceil(packageFunderData.paging.totalItems / PACKAGE_PER_PAGE)
      : 1;

    const loadMorePackageItem = useCallback(() => {
      const page = packageFunderData?.paging.page || 1;
      if (!packageFunderDataFetching && page < totalPackageListPage) {
        setPackageListCurrentPage(page + 1);
      }
    }, [totalPackageListPage, packageFunderDataFetching, packageFunderData]);

    const handleKeyDownDateChange = (
      event: React.KeyboardEvent<HTMLInputElement>,
      field: keyof ReferralFieldsPayload,
      dateValue: string
    ) => {
      if (event.keyCode === 8 || event.keyCode === 46) {
        event.preventDefault();
        if (dateValue) {
          const newValue = dateValue.slice(0, dateValue.length - 1);
          handleChangeFieldValue(field, newValue);
        }
      }
    };

    const handleDateChange = (dateValue: string, field: keyof ReferralFieldsPayload) => {
      const newValue = convertDateFormat(dateValue);
      handleChangeFieldValue(field, newValue);
    };

    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.header}>REFERRAL</div>

          <div className={styles.rowContainer}>
            <div className={classNames(styles.fieldContainer, styles.dateContainer)}>
              <div className={styles.dateInputLabel}>Referral date</div>
              <MaterialInput
                id="referralDate"
                placeholder={MOMENTJS_DATE_FORMAT}
                label={''}
                onKeyDown={(e) => handleKeyDownDateChange(e, 'date', referralFields.date)}
                onChange={(e) => handleDateChange(e.target.value, 'date')}
                value={referralFields.date}
                type={'tel'}
                pattern={'[0-9\\/]*'}
                maxLength={10}
                autoComplete={'off'}
                errorMessage={errorMessage.date}
              />
            </div>

            <div className={classNames(styles.fieldContainer, styles.dateContainer)}>
              <div className={styles.dateInputLabel}>Referral expires</div>
              <MaterialInput
                id="referralExpiryDate"
                placeholder={MOMENTJS_DATE_FORMAT}
                label={''}
                onKeyDown={(e) => handleKeyDownDateChange(e, 'expiryDate', referralFields.expiryDate || '')}
                onChange={(e) => handleDateChange(e.target.value, 'expiryDate')}
                value={referralFields.expiryDate}
                type={'tel'}
                pattern={'[0-9\\/]*'}
                maxLength={10}
                autoComplete={'off'}
                errorMessage={errorMessage.expiryDate}
              />
            </div>

            <div className={styles.fieldContainer}>
              <MaterialSelect
                className={styles.selectOffset}
                label="Presenting issue"
                isSearchable
                options={PRESENTING_ISSUES.map((issue) => ({
                  value: issue,
                  label: issue
                }))}
                value={referralFields.presentingIssue}
                onChange={(value) => handleChangeFieldValue('presentingIssue', value)}
                noCreateNewOption
                menuStyle={{ zIndex: 2 }}
              />
            </div>

            <div className={classNames(styles.fieldContainer, styles.detailContainer)}>
              <MaterialInput
                id="referral-detail"
                label="Referral detail/number"
                maxLength={20}
                minLength={5}
                onChange={(e) => handleChangeFieldValue('detail', e.target.value)}
                value={referralFields.detail}
                error={!!errorMessage.detail}
                errorMessage={errorMessage.detail}
                containerClassName={styles.detailInput}
                required
              />
            </div>

            <div className={styles.fieldContainer}>
              <MaterialSelect
                label="Status"
                isSearchable
                options={STATUS_OPTIONS.map((status) => ({
                  value: status._id,
                  label: status.name
                }))}
                value={referralFields.status}
                onChange={(value) => handleChangeFieldValue('status', value)}
                noCreateNewOption
                menuStyle={{ zIndex: 2 }}
              />
            </div>
          </div>
        </div>

        {isPackageEnabled && (
          <div className={styles.rowContainer}>
            <div className={classNames(styles.sectionContainer, styles.packageSectionContainer)}>
              {selectedPackages.length > 0 ? (
                <div className={styles.content}>
                  {selectedPackages.map((assignPackageObj, idx) => (
                    <div key={idx} className={styles.packageWrapper}>
                      <div className={styles.header}>Package{idx > 0 && ` ${idx + 1}`}</div>
                      <div className={styles.assignWrapper}>
                        <div className={styles.fieldWrapper}>
                          <DropdownSearchable
                            className={styles.input}
                            placeholder="Assign Package"
                            options={
                              packageFunderData?.packages.map((item) => ({
                                value: item._id || '',
                                label: item.name
                              })) || []
                            }
                            disabled={packageFunderDataLoading || packageFunderDataFetching}
                            isLoading={
                              packageFunderDataLoading ||
                              (packageFunderDataFetching && packageListCurrentPage === 1 && !packageSearch)
                            }
                            loadMore={loadMorePackageItem}
                            hasMoreData={
                              (packageFunderData?.packages.length || 0) < (packageFunderData?.paging.totalItems || 0) ||
                              packageFunderDataFetching ||
                              packageFunderDataLoading
                            }
                            selected={assignPackageObj.packageId}
                            searchText={packageSearch}
                            setSearchText={debounceSetPackageSearch}
                            searchable
                            onSelect={(value) => updatePackage(idx, 'packageId', value)}
                            error={errorMessage.packages?.[idx]?.packageId ? 'Please select Package' : ''}
                          />
                          <div className={styles.divider} />
                          <DropdownSearchable
                            className={styles.input}
                            placeholder="Select Funder"
                            options={
                              packageFunderData?.packages
                                .find((packageObj) => packageObj._id === assignPackageObj.packageId)
                                ?.funders.map((item) => ({
                                  value: item.funderId || '',
                                  label: item.name,
                                  ...(item.quotaStatus && {
                                    tooltip:
                                      item.quotaStatus !== PackageFunderQuotaStatus.Available
                                        ? 'Selection quota exceeded'
                                        : '',
                                    disabled: item.quotaStatus !== PackageFunderQuotaStatus.Available
                                  })
                                })) || []
                            }
                            selected={assignPackageObj?.funderId}
                            onSelect={(value) => updatePackage(idx, 'funderId', value)}
                            error={
                              errorMessage.packages?.[idx]?.packageId
                                ? 'Please select a package first'
                                : errorMessage.packages?.[idx]?.funderId
                                ? 'Please select Funder'
                                : ''
                            }
                          />
                        </div>
                        <div className={styles.removeWrapper}>
                          <i
                            onClick={() => handleRemovePackage(idx)}
                            className={`material-icons-outlined ${styles.binIcon}`}
                          >
                            delete
                          </i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.header}>Package</div>
              )}

              {selectedPackages.length < PACKAGE_LIMIT && (
                <ButtonAlt size="medium" variant="outlined" onClick={addAnotherPackage} icon="add">
                  {selectedPackages.length > 0 ? 'Add another package' : 'Package'}
                </ButtonAlt>
              )}

              {typeof errorMessage.packages === 'string' && errorMessage.packages && (
                <div className={styles.error}>{errorMessage.packages}</div>
              )}
            </div>
          </div>
        )}

        <div className={styles.rowContainer}>
          <div className={classNames(styles.sectionContainer, styles.subjectFormContainer)}>
            <div className={styles.header}>SUBJECT</div>
            <ReferralSubjectForm
              subjectFields={referralFields.subject}
              errorMessage={errorMessage.subject}
              onChange={handleChangeSubjectFieldValue}
            />

            {isEoCEnabled && (
              <div className={styles.episodeContainer}>
                <EpisodeTaggingSection
                  selectedClientRecordId={referralFields.clientRecordId || ''}
                  selectedEpisode={referralFields.linkedEpisodes?.[0] || ''}
                  onSelectEpisode={(value) => handleChangeLinkedEpisodeValue(value)}
                  disabled={!referralFields.clientRecordId}
                  readOnly={false}
                  showSelected={false}
                />
              </div>
            )}

            <div className={styles.header}>REFERRAL DOCUMENTS</div>
            <ReferralDocumentForm referral={referralFields} onChange={onUploadFiles} ref={documentFormRef} />
          </div>

          <div className={classNames(styles.sectionContainer, styles.referrerDetailContainer)}>
            <div className={styles.header}>REFERRER DETAIL</div>
            <ToggleSwitchV2
              id="referral-referrerType"
              toggleList={getReferrerTypeOptions(referrerType)}
              className={styles.toggleContainer}
              activeLabelClassName={styles.activeLabel}
              indicatorClassName={styles.indicator}
              onChangeStatus={(val) => {
                handleResetReferrer(val.id as ReferrerType);
              }}
            />

            <ReferrerForm
              referralFields={referralFields}
              errorMessage={errorMessage}
              referrerType={referrerType}
              onChange={handleChangeReferrerFieldValue}
              mode={mode}
              isIndividual={isIndividual}
              setIsIndividual={setIsIndividual}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default ReferralForm;
