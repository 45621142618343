import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';

import styles from './HelmContent.module.scss';

const HelmContent = () => {
  return (
    <HelmetWrapper title="Tacklit - Helm Content">
      <ContentLayout className={styles.container}>
        <iframe
          title={'Helm Content'}
          width={'100%'}
          height={'100%'}
          src={
            'https://airtable.com/embed/shrPNDdfq5rUVe1OF/tblkbxLJuX9jo0W3k/viwZ3zfyKUK8O0tbD?backgroundColor=transparent'
          }
          frameBorder={0}
          allowFullScreen
        />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default HelmContent;
