import { PAYMENT_TYPE_LABELS } from 'pages/Invoices/components/PaymentMethods/PaymentMethods';
import { InvoiceViewProps } from '../../InvoiceView';

import styles from './PaymentMethods.module.scss';
import { config } from 'config/config';

interface PaymentMethodsProps {
  paymentMethods: Required<InvoiceViewProps>['paymentMethods'];
}

const PaymentMethods = ({ paymentMethods }: PaymentMethodsProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Payment via</div>
      {paymentMethods.map(
        ({ accountNumber, code, email, instructions, paymentType, paypalMeLink, stripeConnectedBank }, index) => (
          <div className={styles.paymentMethodContainer} key={index}>
            <div className={styles.label}>{PAYMENT_TYPE_LABELS[paymentType]}</div>
            {paymentType === 'bankTransfer' && (
              <>
                <div className={styles.description}>
                  Account Number: <span className={styles.value}>{accountNumber}</span>
                </div>
                {config.codeLabel && (
                  <div className={styles.description}>
                    {config.codeLabel}: <span className={styles.value}>{code}</span>
                  </div>
                )}
                {instructions && (
                  <div className={styles.description}>
                    Instructions: <span className={styles.value}>{instructions}</span>
                  </div>
                )}
              </>
            )}
            {paymentType === 'paypal' && (
              <>
                <div className={styles.description}>
                  ID: <span className={styles.value}>{email}</span>
                </div>
                {paypalMeLink && (
                  <div className={styles.description}>
                    Link:{' '}
                    <a className={styles.value} href={paypalMeLink}>
                      {paypalMeLink}
                    </a>
                  </div>
                )}
                {instructions && (
                  <div className={styles.description}>
                    Instructions: <span className={styles.value}>{instructions}</span>
                  </div>
                )}
              </>
            )}
            {paymentType === 'cash' && (
              <div className={styles.description}>
                Instructions: <span className={styles.value}>{instructions}</span>
              </div>
            )}
            {paymentType === 'other' && (
              <div className={styles.description}>
                Instructions: <span className={styles.value}>{instructions}</span>
              </div>
            )}
            {paymentType === 'stripe' && stripeConnectedBank?.bankName && (
              <div className={styles.description}>{`Stripe paying into ${stripeConnectedBank.bankName}`}</div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default PaymentMethods;
