import styles from './MemberFilter.module.scss';
import ToggleSwitchV2, { ToggleList } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import { AttachedClientRecordStatus, GroupMemberList } from 'pages/Groups/Interfaces/Groups';
import SearchBar from 'components/SearchBar/SearchBar';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import { FilterCheckListChangeValue } from 'components/FilterCheckList/FilterCheckList';

interface MemberFilterProps {
  searchValue: string;
  groupMembers: GroupMemberList;
  isGroupMembersLoading: boolean;
  stageList: { _id: string; name: string }[];
  isStageListingLoading: boolean;
  selectedStatus: AttachedClientRecordStatus;
  selectedStages: string[];
  handleChangeStatus: (statusValue: ToggleList) => void;
  handleSearch: (searchValue: string) => void;
  handleChangeStage: (stageValue: FilterCheckListChangeValue) => void;
  handleClearStageFilter: () => void;
}

const MemberFilter = ({
  searchValue,
  isGroupMembersLoading,
  groupMembers,
  stageList,
  isStageListingLoading,
  selectedStatus,
  selectedStages,
  handleChangeStatus,
  handleSearch,
  handleChangeStage,
  handleClearStageFilter
}: MemberFilterProps) => {
  const TOGGLE_LIST = [
    {
      id: AttachedClientRecordStatus.Active,
      label: 'Active',
      count: groupMembers.count.active,
      isActive: AttachedClientRecordStatus.Active === selectedStatus
    },
    {
      id: AttachedClientRecordStatus.Completed,
      label: 'Complete',
      count: groupMembers.count.completed,
      isActive: AttachedClientRecordStatus.Completed === selectedStatus
    },
    {
      id: AttachedClientRecordStatus.Archived,
      label: 'Archive',
      count: groupMembers.count.archived,
      isActive: AttachedClientRecordStatus.Archived === selectedStatus
    },
    {
      id: AttachedClientRecordStatus.All,
      label: 'All',
      count: groupMembers.count.all,
      isActive: AttachedClientRecordStatus.All === selectedStatus
    }
  ];

  return (
    <div className={styles.container}>
      <ToggleSwitchV2
        id={'memberStatus'}
        toggleList={TOGGLE_LIST}
        onChangeStatus={handleChangeStatus}
        isLoading={isGroupMembersLoading}
        className={styles.toggleSwitchContainer}
      />

      <div className={styles.filters}>
        <FilterDropdown
          id={'stage'}
          enableButtonLabelItemsColor
          icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
          menuItems={stageList}
          loading={isStageListingLoading}
          onChangeItem={handleChangeStage}
          onClearFilter={handleClearStageFilter}
          searchable
          showSearchIcon
          selectedFilterList={stageList.filter((stage) => selectedStages.includes(stage._id))}
          showToggleAllButtons
        >
          Stage
        </FilterDropdown>

        <SearchBar
          containerClassName={styles.search}
          placeholder={'Search by name or ID'}
          value={searchValue}
          withSearchButton
          onSearch={handleSearch}
          label={'SEARCH FOR:'}
        />
      </div>
    </div>
  );
};

export default MemberFilter;
