import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { MedicareItem, MedicareItemFormat, MedicareItemMode, MedicareItemDuration } from '../interfaces';

// used for all roles in this list 01/08/2023
// https://www.servicesaustralia.gov.au/specific-criteria-for-allied-health-professionals?context=20
export const ALLIED_HEALTH_PROFESSIONAL_ITEMS: MedicareItem[] = [
  {
    mbsCode: '80176',
    description:
      'Attendance by an eligible allied health practitioner, as a member of a multidisciplinary case conference team, to participate in a mental health case conference lasting at least 15 minutes but less than 20 minutes',
    benefit: 47.35,
    role: ClinicianMedicareRole.AlliedHealthProfessional,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneLessThan20
  },
  {
    mbsCode: '80177',
    description:
      'Attendance by an eligible allied health practitioner, as a member of a multidisciplinary case conference team, to participate in a mental health case conference lasting at least 20 minutes but less than 40 minutes',
    benefit: 81.15,
    role: ClinicianMedicareRole.AlliedHealthProfessional,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneLessThan40
  },
  {
    mbsCode: '80178',
    description:
      'Attendance by an eligible allied health practitioner, as a member of a multidisciplinary case conference team, to participate in a mental health case conference lasting at least 40 minutes',
    benefit: 135,
    role: ClinicianMedicareRole.AlliedHealthProfessional,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneMoreThan40
  }
];
