import { useContext } from 'react';
import { useAppSelector } from 'redux/hooks';
import { initialState, selectActiveTab, selectFilters, selectSearch } from 'redux/referrals/referralSlice';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from '../SubTabLayout.module.scss';
import { ReferralTab } from 'pages/Referrals/interface';
import SubTab from 'components/v2/SubTab/SubTab';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ModalVisibilityContext } from 'components/SideDashboardT23/ModalVisibilityContext';
import { useGetReferralList } from 'redux/referrals/useGetReferralList';
import { isEqual } from 'lodash';
import { getReferralDefaultFilters } from 'pages/Referrals/utils';

const ReferralsSubTab = () => {
  const { REFERRALS } = useRoutesGenerator();
  const search = useAppSelector(selectSearch);
  const filters = useAppSelector(selectFilters);
  const activeTab = useAppSelector(selectActiveTab);
  const modalVisibility = useContext(ModalVisibilityContext);

  const { counts, isLoading, isFetching, paging: pagingData } = useGetReferralList();
  const isReferralLoading = isLoading || isFetching;
  const defaultFilters = {
    ...initialState.filters,
    ...getReferralDefaultFilters(activeTab)
  };
  const isSameFilters = isEqual(search, initialState.search) && isEqual(filters, defaultFilters);

  return (
    <div className={styles.container}>
      <ButtonAlt
        icon="add_circle_outline"
        color="white"
        variant="contained"
        fullWidth
        onClick={() => modalVisibility?.setAddReferralModalVisible(true)}
      >
        Create new referral
      </ButtonAlt>

      <div className={styles.section}>
        <SubTab
          title="VIEW BY STATUS"
          options={[
            {
              label: 'New',
              value: ReferralTab.New,
              href: `${REFERRALS.BASE}/${ReferralTab.New}`,
              ...(activeTab === ReferralTab.New || !activeTab
                ? {
                    number: isReferralLoading ? 0 : counts.new,
                    matchingRecordCount: isReferralLoading ? 0 : pagingData.totalItems,
                    hideMatchingRecordCount: counts.new === pagingData.totalItems || isSameFilters
                  }
                : {
                    number: counts.new
                  })
            },
            {
              label: 'Triage',
              value: ReferralTab.Triage,
              href: `${REFERRALS.BASE}/${ReferralTab.Triage}`,
              ...(activeTab === ReferralTab.Triage
                ? {
                    number: isReferralLoading ? 0 : counts.triage,
                    matchingRecordCount: isReferralLoading ? 0 : pagingData.totalItems,
                    hideMatchingRecordCount: counts.triage === pagingData.totalItems || isSameFilters
                  }
                : { number: counts.triage })
            },
            {
              label: 'Declined',
              value: ReferralTab.Decline,
              href: `${REFERRALS.BASE}/${ReferralTab.Decline}`,
              ...(activeTab === ReferralTab.Decline
                ? {
                    number: isReferralLoading ? 0 : counts.decline,
                    matchingRecordCount: isReferralLoading ? 0 : pagingData.totalItems,
                    hideMatchingRecordCount: counts.decline === pagingData.totalItems || isSameFilters
                  }
                : { number: counts.decline })
            },
            {
              label: 'Accepted',
              value: ReferralTab.Accepted,
              href: `${REFERRALS.BASE}/${ReferralTab.Accepted}`,
              ...(activeTab === ReferralTab.Accepted
                ? {
                    number: isReferralLoading ? 0 : counts.accepted,
                    matchingRecordCount: isReferralLoading ? 0 : pagingData.totalItems,
                    hideMatchingRecordCount: counts.accepted === pagingData.totalItems || isSameFilters
                  }
                : { number: counts.accepted })
            },
            {
              label: 'Unlinked Only',
              value: ReferralTab.Unlinked,
              href: `${REFERRALS.BASE}/${ReferralTab.Unlinked}`,
              ...(activeTab === ReferralTab.Unlinked
                ? {
                    number: isReferralLoading ? 0 : counts.unlinked,
                    matchingRecordCount: isReferralLoading ? 0 : pagingData.totalItems,
                    hideMatchingRecordCount: counts.unlinked === pagingData.totalItems || isSameFilters
                  }
                : { number: counts.unlinked })
            },
            {
              label: 'All Referrals',
              value: 'all',
              href: `${REFERRALS.BASE}/${ReferralTab.All}`,
              ...(activeTab === ReferralTab.All
                ? {
                    number: isReferralLoading ? 0 : counts.total,
                    matchingRecordCount: isReferralLoading ? 0 : pagingData.totalItems,
                    hideMatchingRecordCount: counts.total === pagingData.totalItems || isSameFilters
                  }
                : { number: counts.total })
            }
          ]}
          activeOption={activeTab || ReferralTab.New}
        />
      </div>
    </div>
  );
};

export default ReferralsSubTab;
