import classNames from 'classnames';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import moment from 'moment';
import querystring from 'query-string';
import { useEffect, useMemo } from 'react';
import { useGetAvailableRoomQuery } from 'redux/endpoints/scheduleServices/room';
import {
  DEFAULT_ROOM_OPTION,
  selectAppointmentData,
  setIgnoreRoomConflict,
  setSelectedRoom
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './AppointmentRoom.module.scss';
import { useTimeZone } from 'utils/hooks/useTimeZone';

const AppointmentRoom = () => {
  const { timeZoneByView } = useTimeZone();
  const dispatch = useAppDispatch();

  const { selectedDate, selectedTime, recurring, selectedRoom, selectedAppointmentType } =
    useAppSelector(selectAppointmentData);
  const { frequency, count } = recurring;

  useEffect(() => {
    dispatch(setSelectedRoom(DEFAULT_ROOM_OPTION));
  }, [selectedDate, selectedTime, recurring, dispatch]);

  const queryParam = useMemo(() => {
    let dateString = selectedDate;
    if (count && frequency) {
      for (let i = 1; i <= count; i++) {
        const nextDay = moment(selectedDate, 'YYYY-MM-DD');
        nextDay.add(frequency * i, 'day');
        dateString = dateString.concat(`;${nextDay.format('YYYY-MM-DD')}`);
      }
    }
    return querystring.stringify({
      dates: dateString,
      startTime: selectedTime.startTime,
      endTime: selectedTime.endTime,
      allowConflict: true,
      timezone: timeZoneByView
    });
  }, [selectedDate, selectedTime, frequency, count, timeZoneByView]);

  const {
    data: roomList,
    isLoading: isRoomListLoading,
    isFetching: isRoomListFetching
  } = useGetAvailableRoomQuery({ queryParam }, { skip: !selectedTime.startTime || !selectedTime.endTime });

  useEffect(() => {
    if (
      roomList &&
      selectedAppointmentType?.roomSettings?.required &&
      selectedAppointmentType.roomSettings.preferredRoom.roomId
    ) {
      const preferredRoom = roomList.find(
        (room) => room._id === selectedAppointmentType.roomSettings?.preferredRoom.roomId
      );
      preferredRoom && dispatch(setSelectedRoom(preferredRoom));
    }
  }, [selectedAppointmentType, roomList, dispatch]);

  const onSelectRoom = (value: string) => {
    const selected = roomList?.find((item) => item._id === value);
    dispatch(setSelectedRoom(selected || DEFAULT_ROOM_OPTION));
    dispatch(setIgnoreRoomConflict(false));
  };

  const roomOptions: IOptionItem[] = useMemo(() => {
    const options = roomList?.length
      ? roomList.map((item) => ({
          value: item._id,
          label: item.name,
          ...(item.isConflicted && {
            icon: 'warning_amber'
          })
        }))
      : [];

    /**
     * Appointment type - Room setting - /calendar/settings/appointmentTypes - Step 3: Require a room
     */
    const { required, preferredRoom } = selectedAppointmentType?.roomSettings || {};
    if (required) {
      return preferredRoom?.roomId ? options.filter((option) => preferredRoom.roomId === option.value) : options;
    }
    return [{ value: DEFAULT_ROOM_OPTION._id, label: DEFAULT_ROOM_OPTION.name }, ...options];
  }, [roomList, selectedAppointmentType]);

  return (
    <div className={styles.container}>
      <i className={classNames('material-icons-outlined', styles.icon)}>room_preferences</i>
      <DropdownSearchable
        menuPlacement="top"
        selected={selectedRoom?._id}
        options={roomOptions}
        onSelect={onSelectRoom}
        isLoading={isRoomListLoading || isRoomListFetching}
        itemIconClassName={styles.conflictWarning}
        labelClassName={styles.roomLabel}
      />
    </div>
  );
};

export default AppointmentRoom;
