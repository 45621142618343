import styles from './SessionFilter.module.scss';
import ToggleSwitchV2, { ToggleList } from 'components/ToggleSwitchV2/ToggleSwitchV2';
// import SearchBar from 'components/SearchBar/SearchBar';
import { GroupSessionList, SessionStatus } from 'pages/Groups/Interfaces/GroupsSession';

interface SessionFilterProps {
  searchValue: string;
  groupSessions: GroupSessionList;
  isGroupSessionsLoading: boolean;
  selectedStatus: SessionStatus;
  handleChangeStatus: (statusValue: ToggleList) => void;
  handleSearch: (searchValue: string) => void;
}

const SessionFilter = ({
  // searchValue,
  isGroupSessionsLoading,
  groupSessions,
  selectedStatus,
  handleChangeStatus
}: // handleSearch
SessionFilterProps) => {
  const TOGGLE_LIST = [
    {
      id: SessionStatus.Upcoming,
      label: 'UPCOMING',
      count: groupSessions.count.upcoming,
      isActive: SessionStatus.Upcoming === selectedStatus
    },
    {
      id: SessionStatus.Completed,
      label: 'COMPLETED',
      count: groupSessions.count.completed,
      isActive: SessionStatus.Completed === selectedStatus
    }
  ];

  return (
    <div className={styles.container}>
      <ToggleSwitchV2
        id={'sessionStatus'}
        toggleList={TOGGLE_LIST}
        onChangeStatus={handleChangeStatus}
        isLoading={isGroupSessionsLoading}
      />
      {/*<SearchBar*/}
      {/*  containerClassName={styles.search}*/}
      {/*  placeholder={'Search by Label or Date'}*/}
      {/*  value={searchValue}*/}
      {/*  withSearchButton*/}
      {/*  onSearch={handleSearch}*/}
      {/*  label={'SEARCH FOR A SESSION:'}*/}
      {/*/>*/}
    </div>
  );
};

export default SessionFilter;
