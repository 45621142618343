import { useEffect, useReducer } from 'react';
import { CarePathwayList } from '../CarePathwayList/CarePathwayList';
import { CARE_PATHWAY_STATUS, CarePathwayListFilter } from '../CarePathwayListFilter/CarePathwayListFilter';
import styles from './CarePathwayListContent.module.scss';
import { useParams } from 'react-router-dom';
import { carePathwayReducer } from '../../reducer';
import { CarePathwayListFilterType } from '../../types';

export const CarePathwayListContent = () => {
  const { status } = useParams<{ status: string }>();
  const [state, dispatch] = useReducer(carePathwayReducer, {
    filter: {
      status: status
        ? [
            {
              _id: status,
              name: CARE_PATHWAY_STATUS[status as keyof typeof CARE_PATHWAY_STATUS]
            }
          ]
        : [],
      client: [],
      staff: []
    },
    currentPage: 1
  });

  useEffect(() => {
    dispatch({
      type: 'SET_FILTER',
      payload: {
        status: status
          ? [
              {
                _id: status,
                name: CARE_PATHWAY_STATUS[status as keyof typeof CARE_PATHWAY_STATUS]
              }
            ]
          : []
      }
    });
  }, [status]);

  const setFilter = (filter: Partial<CarePathwayListFilterType>) => {
    dispatch({
      type: 'SET_FILTER',
      payload: filter
    });
  };

  const setCurrentPage = (page: number) => {
    dispatch({
      type: 'SET_CURRENT_PAGE',
      payload: page
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <CarePathwayListFilter setFilter={setFilter} filter={state.filter} />
      </div>
      <CarePathwayList filter={state.filter} currentPage={state.currentPage} setCurrentPage={setCurrentPage} />
    </div>
  );
};
