import Checkbox from 'components/v2/Checkbox/Checkbox';
import styles from './RecurringAppointment.module.scss';
import OccurrencesField from '../../../OccurrencesField/OccurrencesField';
import FrequencyField from '../../../FrequencyField/FrequencyField';
import { useField } from 'formik';

interface RecurringAppointmentProps {
  date: string;
  occurrences: number;
  frequency: number;
}

const RecurringAppointment = ({ date, occurrences, frequency }: RecurringAppointmentProps) => {
  const [{ value: valueRecurring }, , recurringHelper] = useField('isRecurring');

  return (
    <div className={styles.container}>
      <div className={styles.settingsContainer}>
        <Checkbox
          label="Set as recurring appointment"
          subLabel="Same day and time for more than once"
          checked={valueRecurring}
          onChange={recurringHelper.setValue}
        />
        {valueRecurring && (
          <div className={styles.recurringDetails}>
            <div>
              <FrequencyField />
            </div>
            <div className={styles.recurringTimesContainer}>
              <OccurrencesField />
            </div>
          </div>
        )}
      </div>
      {valueRecurring && (
        <div className={styles.warningMessage}>
          <i className={`material-icons-outlined ${styles.icon}`}>info</i>
          <span>
            To make it easier to set recurring appointments we allow you to book across existing events. <br /> Please
            check before you confirm this set up if you want to avoid going over already scheduled events
          </span>
        </div>
      )}
    </div>
  );
};

export default RecurringAppointment;
