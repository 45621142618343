import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import {
  CarePathway,
  CarePathwayAppointmentItem,
  CarePathwayStatus,
  CreateCarePathway,
  GetCarePathwayCountResponse,
  GetCarePathwayResponse
} from 'interfaces/CarePathway/CarePathway';
import { funderType } from '../../../interfaces/Packages/packages';

interface PostCarePathwayPayload {
  accountId: string;
  payload: CreateCarePathway;
}

export interface UpdateCarePathwayPayload extends PostCarePathwayPayload {
  carePathwayId: string;
}

export interface PublishCarePathwayPayload extends Omit<PostCarePathwayPayload, 'payload'> {
  carePathwayId: string;
}

interface CarePathwayItemPayload {
  accountId: string;
  carePathwayId: string;
  payload: CarePathwayAppointmentItem;
}

interface CarePathwayItemUpdatePayload {
  accountId: string;
  carePathwayId: string;
  payload: CarePathwayAppointmentItem[];
}

interface DeleteCarePathwayItemPayload {
  accountId: string;
  carePathwayId: string;
  carePathwayItemId?: string;
}

export const carePathwayApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarePathway: builder.query<
      CarePathway,
      {
        accountId: string;
        carePathwayId: string;
      }
    >({
      query: ({ accountId, carePathwayId }) => ({
        url: `accounts/${accountId}/care-pathways/${carePathwayId}`
      }),
      transformResponse: (response: CarePathway) => ({
        ...response,
        clientRecord: response.clientRecord?._id
          ? {
              _id: response.clientRecord?._id || '',
              name:
                response.clientRecord?.clientProfiles
                  ?.map((profileObj) => `${profileObj.firstName} ${profileObj.lastName}`)
                  .join(', ') || ''
            }
          : undefined,
        items: response.items?.filter((itemObj) => itemObj._id) || [],
        appointments: response.appointments.map((appointmentObj) => ({
          ...appointmentObj,
          appointmentType: response.items.find((itemObj) => itemObj._id === appointmentObj.carePathwayItemId)
            ?.appointmentType
        })),
        ...(response.package && {
          template: {
            _id: response.package.id || '',
            name: response.package.name || '',
            selectedSubMenu: {
              _id:
                response.package.funder.type === funderType.Default
                  ? response.package.funder.name || ''
                  : response.package.funder.funderId || '',
              name: response.package.funder.name || ''
            }
          }
        })
      }),
      providesTags: [CPSTagTypes.CarePathway]
    }),
    getCarePathwayList: builder.query<
      GetCarePathwayResponse,
      {
        accountId: string;
        query?: {
          leadClinicianIds?: string[];
          status: string[];
          clientRecordIds?: string[];
          page?: number;
          perPage?: number;
        };
      }
    >({
      query: ({ accountId, query }) => ({
        url: `accounts/${accountId}/care-pathways`,
        params: query
      }),
      providesTags: [CPSTagTypes.CarePathway]
    }),
    getCarePathwayCount: builder.query<
      GetCarePathwayCountResponse,
      {
        accountId: string;
      }
    >({
      query: ({ accountId }) => ({
        url: `accounts/${accountId}/care-pathways/counts`
      }),
      providesTags: [CPSTagTypes.CarePathwayCount]
    }),
    updateCarePathwayStatus: builder.mutation<
      null,
      {
        accountId: string;
        carePathwayId: string;
        status: CarePathwayStatus;
      }
    >({
      query: ({ accountId, carePathwayId, status }) => ({
        url: `accounts/${accountId}/care-pathways/${carePathwayId}/status`,
        method: 'PATCH',
        body: {
          status
        }
      }),
      invalidatesTags: [CPSTagTypes.CarePathway, CPSTagTypes.CarePathwayCount]
    }),
    deleteCarePathway: builder.mutation<
      null,
      {
        accountId: string;
        carePathwayId: string;
      }
    >({
      query: ({ accountId, carePathwayId }) => ({
        url: `accounts/${accountId}/care-pathways/${carePathwayId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [CPSTagTypes.CarePathway, CPSTagTypes.CarePathwayCount]
    }),
    CreateCarePathway: builder.mutation({
      query: ({ accountId, payload }: PostCarePathwayPayload) => ({
        url: `/accounts/${accountId}/care-pathways`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: []
    }),
    UpdateCarePathway: builder.mutation({
      query: ({ accountId, carePathwayId, payload }: UpdateCarePathwayPayload) => ({
        url: `/accounts/${accountId}/care-pathways/${carePathwayId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: []
    }),
    PublishCarePathway: builder.mutation({
      query: ({ accountId, carePathwayId }: PublishCarePathwayPayload) => ({
        url: `/accounts/${accountId}/care-pathways/${carePathwayId}:publish`,
        method: 'PUT'
      }),
      invalidatesTags: []
    }),
    AddCarePathwayItem: builder.mutation({
      query: ({ accountId, carePathwayId, payload }: CarePathwayItemPayload) => ({
        url: `/accounts/${accountId}/care-pathways/${carePathwayId}/items`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: []
    }),
    UpdateCarePathwayItems: builder.mutation({
      query: ({ accountId, carePathwayId, payload }: CarePathwayItemUpdatePayload) => ({
        url: `/accounts/${accountId}/care-pathways/${carePathwayId}/items`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: []
    }),
    DeleteCarePathwayItem: builder.mutation({
      query: ({ accountId, carePathwayId, carePathwayItemId }: DeleteCarePathwayItemPayload) => ({
        url: `/accounts/${accountId}/care-pathways/${carePathwayId}/items/${carePathwayItemId}`,
        method: 'DELETE'
      }),
      invalidatesTags: []
    })
  })
});

export const {
  useGetCarePathwayQuery,
  useGetCarePathwayListQuery,
  useGetCarePathwayCountQuery,
  useUpdateCarePathwayStatusMutation,
  useDeleteCarePathwayMutation,
  useCreateCarePathwayMutation,
  useUpdateCarePathwayMutation,
  usePublishCarePathwayMutation,
  useAddCarePathwayItemMutation,
  useUpdateCarePathwayItemsMutation,
  useDeleteCarePathwayItemMutation
} = carePathwayApiSlice;
