import { useContext, useState } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  useFetchPractitioner,
  useFetchPractitionerListCount,
  useGetPractitionerFilterValue
} from './hooks/GetPractitionersList';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import PractitionersListingHeader from './components/PractitionersListingHeader/PractitionersListingHeader';
import PractitionerListContent from './components/PractitionerListContent/PractitionerListContent';
import { ModalVisibilityContext } from 'components/SideDashboardT23/ModalVisibilityContext';
import styles from './PractitionersListing.module.scss';
import { useTranslation } from 'react-i18next';

export enum PractitionerTabId {
  Active = 'active',
  Inactive = 'inactive'
}

const PractitionersListing = () => {
  const { token } = useGetAccessToken();
  const { PRACTITIONER } = useRoutesGenerator();
  const navigate = useNavigate();
  const { filterValue, filterType, setFilterType, queryParam } = useGetPractitionerFilterValue();

  const [searchFilterValue, setSearchFilterValue] = useState(queryParam.q ?? '');
  const [pageLoading, setPageLoading] = useState(false);
  const [t] = useTranslation();

  const { practitionersList, isPractitionersListLoading, fetchPractitionersList } = useFetchPractitioner(
    token,
    filterValue
  );
  const { practitionersCount, isPractitionersCountLoading, fetchPractitionersCount } = useFetchPractitionerListCount(
    token,
    filterValue
  );

  const onChangeTabStatus = async (tabValue: PractitionerTabId) => {
    if (tabValue !== filterType) {
      setPageLoading(true);
      const newQParam = {
        ...(filterValue.page && {
          page: 1
        }),
        ...(filterValue.q && {
          q: filterValue.q
        }),
        status: tabValue
      };
      const qParam = queryString.stringify(newQParam);
      navigate(`${PRACTITIONER.BASE}/${qParam ? `?${qParam}` : ''}`);
      setFilterType(tabValue);
      const newFilterValue = {
        status: tabValue,
        perPage: filterValue.perPage,
        page: 1,
        q: searchFilterValue
      };
      await fetchPractitionersList(token, newFilterValue);
      setPageLoading(false);
    }
  };

  const onHandleSearch = async (value: string) => {
    setPageLoading(true);
    const newQParam = {
      ...(filterValue.status && {
        status: filterValue.status
      }),
      page: 1,
      q: value
    };

    const qParam = queryString.stringify(newQParam);
    navigate(`${PRACTITIONER.BASE}/${qParam ? `?${qParam}` : ''}`);
    setSearchFilterValue(value);
    const newFilterValue = {
      status: filterValue.status,
      perPage: filterValue.perPage,
      page: 1,
      q: value
    };
    await fetchPractitionersList(token, newFilterValue);
    await fetchPractitionersCount(token, newFilterValue);
    setPageLoading(false);
  };

  const onHandlePageChange = async (pageValue: string) => {
    setPageLoading(true);
    const newQParam = {
      ...(filterValue.q && {
        q: filterValue.q
      }),
      ...(filterValue.status && {
        status: filterValue.status
      }),
      page: pageValue
    };
    const qParam = queryString.stringify(newQParam);
    navigate(`${PRACTITIONER.BASE}/${qParam ? `?${qParam}` : ''}`);
    const newFilterValue = {
      status: filterValue.status,
      perPage: filterValue.perPage,
      page: pageValue,
      q: searchFilterValue
    };
    await fetchPractitionersList(token, newFilterValue);
    await fetchPractitionersCount(token, newFilterValue);
    setPageLoading(false);
  };

  const modalVisibility = useContext(ModalVisibilityContext);

  return (
    <HelmetWrapper title={t('label.tacklit_practitioners')}>
      <ContentLayout>
        <div className={styles.container}>
          <PractitionersListingHeader
            total={practitionersList.paging.totalItem || 0}
            isLoading={isPractitionersListLoading || isPractitionersCountLoading || pageLoading}
            currentTabFilter={filterType}
            onAddPractitionerClick={() => modalVisibility?.setAddPractitionerModalVisible(true)}
          />
          <PractitionerListContent
            practitionersList={practitionersList}
            isCountLoading={isPractitionersCountLoading}
            isPractitionersListLoading={isPractitionersListLoading}
            isPageLoading={pageLoading}
            onChangeTabStatus={onChangeTabStatus}
            filterValue={filterValue}
            practitionersCount={practitionersCount}
            onHandleSearch={onHandleSearch}
            onHandlePageChange={onHandlePageChange}
          />
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default PractitionersListing;
