import { FC, useEffect, useRef, useState } from 'react';
import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './FinanceSettingsDropdown.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { Link } from 'react-router-dom';
import InvoiceSettingsModal from 'pages/Invoices/components/InvoiceSettings/components/InvoiceSettingsModal/InvoiceSettingsModal';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { PaymentMethod } from 'pages/Invoices/interface';
import { useFetchPaymentMethods } from 'pages/Invoices/Invoices';
import PaymentMethodsModal from 'pages/Invoices/components/PaymentMethods/components/PaymentMethodsModal/PaymentMethodsModal';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetInvoiceSettingsQuery } from 'redux/endpoints/billingServices/invoiceSetting';

const FinanceSettingsDropdown: FC = () => {
  const { auth0ClinicianId } = useGetClinicianId();
  const menuNode = useRef<HTMLDivElement>(null);
  const modalNode = useRef<HTMLDivElement>(null);
  const { CONTROL_PANEL } = useRoutesGenerator();
  const { isEdgeAdminView, isEdgeReceptionist, isNormalUserView } = useGetAccountPackageView();
  const canViewMainSettings = isEdgeAdminView || isEdgeReceptionist || isNormalUserView;

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [isInvoiceSettingsModalVisible, setIsInvoiceSettingsModalVisible] = useState(false);
  const [isPaymentMethodsModalVisible, setIsPaymentMethodsModalVisible] = useState(false);

  const {
    data: invoiceSettings,
    isLoading: isInvoiceSettingLoading,
    isFetching: isInvoiceSettingFetching
  } = useGetInvoiceSettingsQuery({
    clinicianId: auth0ClinicianId
  });
  const isInvoiceSettingsLoading = isInvoiceSettingLoading || isInvoiceSettingFetching;

  const { paymentMethods, isPaymentMethodsLoading, refetchPaymentMethods } = useFetchPaymentMethods();

  const handleToggleMenu = () => {
    setIsMenuExpanded(!isMenuExpanded);
  };

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target) || modalNode.current?.contains(event.target)) {
      return;
    }
    setIsMenuExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => document.removeEventListener('mousedown', handleClickOutSide);
  });

  const handleManageInvoiceSettingsClick = () => {
    setIsInvoiceSettingsModalVisible(true);
  };

  const handleInvoiceSettingsModalClose = () => {
    setIsInvoiceSettingsModalVisible(false);
  };

  const handleAddPaymentMethodClick = () => {
    setIsPaymentMethodsModalVisible(true);
  };

  const handleChangePaymentDetailsClick = () => {
    setIsPaymentMethodsModalVisible(true);
  };

  const handlePaymentMethodsModalClose = (paymentMethods?: PaymentMethod[]) => {
    setIsPaymentMethodsModalVisible(false);

    refetchPaymentMethods();
  };

  return (
    <div ref={menuNode} className={styles.container}>
      <ButtonAlt
        variant="outlined"
        iconVariant={IconVariant.Filled}
        onClick={handleToggleMenu}
        icon="settings"
        size="medium"
        fullWidth
      >
        <div className={styles.buttonTitle}>
          Finance Settings <i className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</i>
        </div>
      </ButtonAlt>
      <div className={styles.menuWrapper}>
        <div className={isMenuExpanded ? styles.menuShow : styles.menuHide}>
          {canViewMainSettings && (
            <div className={styles.listBox} onClick={handleManageInvoiceSettingsClick}>
              <div className={styles.listTitle}>Manage account level defaults</div>
            </div>
          )}
          <div className={styles.listBox} onClick={handleChangePaymentDetailsClick}>
            <div className={styles.listTitle}>Add / change payment details</div>
          </div>
          {canViewMainSettings && (
            <Link className={styles.listBox} to={CONTROL_PANEL.SYSTEM_CONNECTIONS}>
              <div className={styles.listTitle}>Explore accountancy connections</div>
            </Link>
          )}
        </div>
      </div>

      {canViewMainSettings && (
        <InvoiceSettingsModal
          invoiceSettings={invoiceSettings}
          paymentMethods={paymentMethods}
          isInvoiceSettingsLoading={isInvoiceSettingsLoading}
          isPaymentMethodsLoading={isPaymentMethodsLoading}
          visible={isInvoiceSettingsModalVisible}
          onAddPaymentMethodClick={handleAddPaymentMethodClick}
          onClose={handleInvoiceSettingsModalClose}
        />
      )}

      <PaymentMethodsModal
        paymentMethods={paymentMethods}
        isPaymentMethodsLoading={isPaymentMethodsLoading}
        visible={isPaymentMethodsModalVisible}
        onClose={handlePaymentMethodsModalClose}
      />
    </div>
  );
};

export default FinanceSettingsDropdown;
