import Checkbox from 'components/v2/Checkbox/Checkbox';
import DatePicker from 'components/v2/DatePicker/DatePicker';
import TimePicker from 'components/v2/TimePicker/TimePicker';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { useField } from 'formik';
import FrequencyField from '../../../FrequencyField/FrequencyField';
import OccurrencesField from '../../../OccurrencesField/OccurrencesField';
import styles from './Schedule.module.scss';
import moment, { Moment } from 'moment';
import { useEffect } from 'react';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { getTimezoneLabel } from 'utils/hooks/GetTimezones/getTimezones';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

const IS_DEVELOPMENT = isDevelopmentENV();

interface ScheduleProps {
  isEdit: boolean;
  date?: Date;
  timeZone?: string;
}

const Schedule = ({ isEdit, date, timeZone }: ScheduleProps) => {
  const { isSomeoneHealthFeatureToggle } = useGetFeatureToggle();
  const { isEdgeUserView } = useGetAccountPackageView();
  const [{ value: valueDate }, metaDate, dateHelper] = useField('date');
  const [{ value: valueRecurring }, , recurringHelper] = useField('isRecurring');
  const [{ value: valueStartTime }, , startTimeHelper] = useField('startTime');
  const [{ value: valueEndTime }, metaEndTime, endTimeHelper] = useField('endTime');

  useEffect(() => {
    if (isEdit) {
      const startTime = moment(valueStartTime, 'HH:mm').format('HH:mm');
      const endTime = moment(valueEndTime, 'HH:mm').format('HH:mm');
      startTimeHelper.setValue(startTime);
      endTimeHelper.setValue(endTime);
    } else {
      const momentDate = moment(date);
      dateHelper.setValue(momentDate.format('YYYY-MM-DD'));
      const startTime = momentDate.format('HH:mm');
      const endTime = momentDate.add(30, 'minutes').format('HH:mm');
      startTimeHelper.setValue(startTime);
      endTimeHelper.setValue(endTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleChangeDate = (value: Moment | null) => {
    if (value) {
      dateHelper.setValue(value.format('YYYY-MM-DD'));
    } else {
      dateHelper.setValue('');
    }
  };

  const handleChangeStartTime = (val: any) => {
    startTimeHelper.setValue(moment(val, 'hh:mm a').format('HH:mm'));
  };

  const handleChangeEndTime = (val: any) => {
    endTimeHelper.setValue(moment(val, 'hh:mm a').format('HH:mm'));
  };

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <span className="material-icons-outlined">edit_calendar</span>
      </div>
      <div className={styles.fieldsContainer}>
        <div className={styles.field}>
          <div>
            <DatePicker
              date={valueDate}
              error={metaDate?.touched && !!metaDate.error}
              onChangeValue={handleChangeDate}
            />
          </div>
          <div>
            <div className={styles.timeField}>
              <span className="material-icons-outlined">schedule</span>
              <TimePicker
                id={'bespokeStartTime'}
                error={metaEndTime?.touched && !!metaEndTime.error}
                time={valueStartTime}
                minuteStep={5}
                onChange={handleChangeStartTime}
              />
              <span>-</span>
              <TimePicker
                id={'bespokeEndTime'}
                error={metaEndTime?.touched && !!metaEndTime.error}
                time={valueEndTime}
                minuteStep={5}
                onChange={handleChangeEndTime}
              />
              <ErrorMessage
                className={styles.error}
                error={metaEndTime.error}
                visible={metaEndTime?.touched && !!metaEndTime.error}
              />
            </div>
            {(isSomeoneHealthFeatureToggle || IS_DEVELOPMENT) && (
              <div className={styles.timezoneInfo}>
                Time in {isEdgeUserView ? 'your' : "owner's"} time zone: <b>{getTimezoneLabel(timeZone)}</b>
              </div>
            )}
          </div>
        </div>
        {!isEdit && (
          <div className={styles.recurringField}>
            <Checkbox label="Recurring?" checked={valueRecurring} onChange={recurringHelper.setValue} />
            {valueRecurring && (
              <>
                <FrequencyField />
                <OccurrencesField />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Schedule;
