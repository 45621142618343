import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { getInvoice } from 'utils/http/BillingService/Invoice/invoice';
import { getAllClientEncryptedDataByClientRecordId } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { InvoiceStatus } from 'pages/Invoices/interface';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { getGroupDetailsByGroupId } from 'utils/http/ClinicianProfileService/Groups/Groups';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import { InvoiceWithType } from 'pages/InvoicesV2/components/InvoiceList/constants';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

interface InvoiceById extends Omit<InvoiceWithType, 'invoiceType' | 'paymentRequests' | 'activityFeeds'> {}

export interface FullInvoice extends InvoiceById {
  fullClientRecord?: clientRecordsInterface;
  group?: GroupsFromAPI;
}
export const useFetchInvoiceDetailWithFullClientRecordOrGroup = (
  token: string,
  invoiceId: string,
  notificationSettings?: NotificationSettingsInterface
) => {
  const [invoice, setInvoice] = useState<FullInvoice>();
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);

  const fetchInvoiceDetail = async (token: string) => {
    try {
      const invoice = (await (await getInvoice(token, invoiceId)).json()) as InvoiceById;

      if (invoice.clientRecord?._id) {
        const clientRecord = (await (
          await getAllClientEncryptedDataByClientRecordId(token, invoice.clientRecord._id)
        ).json()) as clientRecordsInterface;
        const invoiceWithClientRecord = {
          ...invoice,
          status:
            new Date(invoice.dueDate) < new Date() && invoice.status === 'issued'
              ? InvoiceStatus.Overdue
              : invoice.status,
          clientRecord: {
            ...clientRecord,
            allowCommunicationWithEmail: clientRecord.clientProfiles.some(
              (profileObj) =>
                profileObj.role !== 'child' &&
                profileObj.isPrimaryContact &&
                canClientReceiveEmail({
                  communicationPreference: profileObj.communicationPreference,
                  notificationSettings,
                  notificationSettingsCategory: 'invoice'
                }) &&
                profileObj.hasEmail
            ),
            allowCommunicationWithSms: clientRecord.clientProfiles.some(
              (profileObj) =>
                profileObj.role !== 'child' &&
                profileObj.isPrimaryContact &&
                canClientReceiveSms({
                  communicationPreference: profileObj.communicationPreference,
                  notificationSettings,
                  notificationSettingsCategory: 'invoice'
                }) &&
                profileObj.hasMobileNumber
            )
          }
        };

        setInvoice(invoiceWithClientRecord);
      } else {
        const group = invoice.group
          ? ((await (await getGroupDetailsByGroupId(token, invoice.group?._id)).json()) as GroupsFromAPI)
          : null;
        const invoiceWithGroup = {
          ...invoice,
          ...(group && { group })
        };

        setInvoice(invoiceWithGroup);
      }
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to get your invoice detail.',
        duration: 2
      });
    }
    setIsInvoiceLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchInvoiceDetail(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, notificationSettings]);

  return { invoice, isInvoiceLoading, fetchInvoiceDetail };
};
