import classNames from 'classnames';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { useState } from 'react';
import {
  selectAppointmentData,
  selectPackageAppointmentDetails,
  setRecurring
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './AppointmentRecurring.module.scss';
import OccurrenceInput from './OccurrenceInput/OccurrenceInput';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { Tooltip } from 'react-tooltip';

export const FrequencyNameMapping = {
  0: "Doesn't repeat",
  1: 'Repeat daily',
  7: 'Repeat weekly',
  14: 'Repeat fortnightly',
  28: 'Repeat monthly'
};

const RECURRING_OPTIONS: IOptionItem[] = [
  { value: '0', label: "Doesn't repeat" },
  { value: '1', label: 'Repeat daily' },
  { value: '7', label: 'Repeat weekly' },
  { value: '14', label: 'Repeat fortnightly' },
  { value: '28', label: 'Repeat monthly' }
];

const AppointmentRecurring = () => {
  const dispatch = useAppDispatch();
  const { isPackageEnabled } = useGetFeatureToggle();
  const { recurring } = useAppSelector(selectAppointmentData);
  const packageAppointmentDetails = useAppSelector(selectPackageAppointmentDetails);

  const [isFrequencyActive, setIsFrequencyActive] = useState<boolean>(false);

  const onSelectFrequency = (value: string) => {
    const valueAsNumber = Number(value);
    dispatch(
      setRecurring({
        ...recurring,
        frequency: valueAsNumber,
        ...(valueAsNumber === 0 && recurring.count !== 0
          ? {
              count: 0
            }
          : valueAsNumber !== 0 &&
            recurring.count === 0 && {
              count: 1
            })
      })
    );
    setIsFrequencyActive(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.childWrapper} data-tooltip-id="noAllowRepeat">
        <i className={classNames('material-icons', styles.icon)}>repeat</i>
        <DropdownSearchable
          menuPlacement="top"
          isActive={isFrequencyActive}
          selected={recurring.frequency.toString()}
          options={RECURRING_OPTIONS}
          onSelect={onSelectFrequency}
          disabled={isPackageEnabled && packageAppointmentDetails?.isPackageOption}
        />
      </div>
      {recurring.frequency !== 0 && (
        <div className={styles.childWrapper}>
          <OccurrenceInput
            value={(recurring.count + 1).toString()}
            setValue={(valueValue) =>
              dispatch(
                setRecurring({
                  ...recurring,
                  count: Number(valueValue) - 1
                })
              )
            }
            disabled={isPackageEnabled && packageAppointmentDetails?.isPackageOption}
          />
        </div>
      )}
      <Tooltip
        id="noAllowRepeat"
        className={styles.tooltip}
        hidden={!(isPackageEnabled && packageAppointmentDetails?.isPackageOption)}
      >
        Recurring appointments are not allowed for packages.
      </Tooltip>
    </div>
  );
};

export default AppointmentRecurring;
