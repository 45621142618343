import { Skeleton } from 'antd';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { MedicareDetails } from 'interfaces/Clients/clientsRecord';
import { useState } from 'react';
import { ClaimCount } from '../../hooks/getClaimSummary';
import styles from './ClaimSummary.module.scss';
import CustomiseSummaryModal from './components/CustomiseSummaryModal/CustomiseSummaryModal';

interface ClaimSummaryProps {
  clientRecordId: string;
  clientProfileId: string;
  summary: MedicareDetails['summary'];
  individualClaimCounts: ClaimCount[];
  groupClaimCounts: ClaimCount[];
  summaryYear: string;
  isClaimSummaryLoading: boolean;
  handleUpdateSummarySettings: ({
    year,
    individualCodes,
    groupCodes
  }: {
    year: string;
    individualCodes: string[];
    groupCodes: string[];
  }) => void;
}

export const ClaimSummary = ({
  clientRecordId,
  clientProfileId,
  summary,
  individualClaimCounts,
  groupClaimCounts,
  summaryYear,
  isClaimSummaryLoading,
  handleUpdateSummarySettings
}: ClaimSummaryProps) => {
  const [isCustomiseModalVisible, setIsCustomiseModalVisible] = useState(false);
  const totalIndividual = individualClaimCounts.reduce((prev, curr) => prev + curr.count, 0);
  const totalGroup = groupClaimCounts.reduce((prev, curr) => prev + curr.count, 0);

  const handleCloseCustomiseModal = () => {
    setIsCustomiseModalVisible(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.headerContainer}>
            <div className={styles.header}>
              Medicare Claim Summary <span className={styles.divider}>|</span> {summaryYear}
            </div>

            <ButtonAlt
              className={styles.button}
              onClick={() => setIsCustomiseModalVisible(true)}
              size={'medium'}
              variant={'text'}
              icon={'create'}
            >
              Edit
            </ButtonAlt>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>INDIVIDUAL</div>
            <div className={styles.description}>
              Medicare rebates are available for up to <span className={styles.strong}>10 individual</span> allied
              mental health services this calendar year.
            </div>
            <div className={styles.heading}>
              <div className={styles.leftColumn}>MBS Code</div>
              <div className={styles.middleColumn}># Claims</div>
            </div>
            {isClaimSummaryLoading ? (
              <div className={styles.loadingContainer}>
                {[...Array(3)].map((obj, index) => (
                  <Skeleton.Input active className={styles.loading} key={index} />
                ))}
              </div>
            ) : (
              <>
                {individualClaimCounts.map(({ code, count }, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.mbsCode}>{code}</div>
                    <div className={styles.middleColumn}>
                      <div className={styles.count}>{count}</div>
                    </div>
                  </div>
                ))}
                <div className={styles.total}>
                  <div className={styles.label}>TOTAL</div>
                  <div className={styles.middleColumn}>
                    <div className={styles.totalCount}>{totalIndividual}</div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.section}>
            <div className={styles.title}>GROUP</div>
            <div className={styles.description}>
              Medicare rebates are available for up to <span className={styles.strong}>10 group</span> allied mental
              health services this calendar year.
            </div>
            <div className={styles.heading}>
              <div className={styles.leftColumn}>MBS Code</div>
              <div className={styles.middleColumn}># Claims</div>
            </div>
            {isClaimSummaryLoading ? (
              <div className={styles.loadingContainer}>
                {[...Array(3)].map((obj, index) => (
                  <Skeleton.Input active className={styles.loading} key={index} />
                ))}
              </div>
            ) : (
              <>
                {groupClaimCounts.map(({ code, count }, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.mbsCode}>{code}</div>
                    <div className={styles.middleColumn}>
                      <div className={styles.count}>{count}</div>
                    </div>
                  </div>
                ))}
                <div className={styles.total}>
                  <div className={styles.label}>TOTAL</div>
                  <div className={styles.middleColumn}>
                    <div className={styles.totalCount}>{totalGroup}</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <CustomiseSummaryModal
        visible={isCustomiseModalVisible}
        clientRecordId={clientRecordId}
        clientProfileId={clientProfileId}
        summary={summary}
        selectedYear={summaryYear}
        handleUpdateSummarySettings={handleUpdateSummarySettings}
        handleCancel={handleCloseCustomiseModal}
      />
    </>
  );
};

export default ClaimSummary;
