import { createSlice } from '@reduxjs/toolkit';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { PER_PAGE_DEFAULT, Paging, SortType } from 'redux/features/utils';
import { RootState } from 'redux/store';

export enum SearchFilterEnum {
  InvoiceId = 'invoiceId'
}
export interface WriteOffInvoiceSorting {
  sortBy: string;
  sortType: SortType;
}

export interface WriteOffInvoiceFilters {
  clients: FilterCheckListItem[];
  groups: FilterCheckListItem[];
  practitioners: FilterCheckListItem[];
  invoiceTypes: FilterCheckListItem[];
  participantType: ParticipantType;
}

export interface WriteOffInvoiceSearch {
  searchValue: string;
  searchBy: SearchFilterEnum.InvoiceId;
}

export interface WriteOffInvoiceSliceState {
  search: WriteOffInvoiceSearch;
  filters: WriteOffInvoiceFilters;
  sorting: WriteOffInvoiceSorting;
  paging: Paging;
}

export const DEFAULT_SORTING = {
  sortBy: 'createdAt',
  sortType: SortType.DESC
};

const initialState: WriteOffInvoiceSliceState = {
  search: {
    searchValue: '',
    searchBy: SearchFilterEnum.InvoiceId
  },
  filters: {
    clients: [],
    groups: [],
    practitioners: [],
    invoiceTypes: [],
    participantType: ParticipantType.OneToOne
  },
  sorting: DEFAULT_SORTING,
  paging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT
  }
};

export const writeOffInvoiceSlice = createSlice({
  name: 'writeOffInvoiceSlice',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetFilters: (state) => {
      state.filters = {
        ...initialState.filters,
        participantType: state.filters.participantType // Don't reset
      };
    },

    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    },

    setPaging: (state, action) => {
      state.paging = action.payload;
    },

    resetSorting: (state) => {
      state.sorting = initialState.sorting;
    },

    resetSearch: (state) => {
      state.search = initialState.search;
    },

    resetAll: (state) => {
      state.search = initialState.search;
      state.paging = initialState.paging;
      state.sorting = initialState.sorting;
      state.filters = initialState.filters;
    },

    resetPagingAndSorting: (state) => {
      state.paging = initialState.paging;
      state.sorting = initialState.sorting;
    }
  }
});

export const selectFilters = (state: RootState) => state.writeOffInvoiceSlice.filters;
export const selectSearch = (state: RootState) => state.writeOffInvoiceSlice.search;
export const selectSorting = (state: RootState) => state.writeOffInvoiceSlice.sorting;
export const selectPaging = (state: RootState) => state.writeOffInvoiceSlice.paging;

export const { setSearch, setFilters, resetFilters, setSorting, setPaging, resetSearch, resetPaging } =
  writeOffInvoiceSlice.actions;

export default writeOffInvoiceSlice.reducer;
