import { PracticeInterface } from 'interfaces/Profile/Profile';
import { PaymentMethod } from 'pages/Invoices/interface';
import { ItemColumns, ItemOverview, RecipientTypes } from 'redux/invoiceTemplate/invoiceTemplateCreateFormSlice';

export const ACTION_ORDER = {
  [ItemColumns.Quantity]: 1,
  [ItemColumns.UnitPrice]: 2,
  [ItemColumns.TaxRate]: 3,
  [ItemColumns.Total]: 4
} as const;

export const ITEM_COLUMNS = {
  quantity: {
    name: 'QUANTITY',
    value: '{{Quantity}}',
    valueTotal: '{{Quantity}}'
  },
  unitPrice: {
    name: 'UNIT PRICE',
    value: '{{Price}}',
    valueTotal: '{{Total Price}}'
  },
  taxRate: {
    name: 'TAX RATE',
    value: '{{Tax Price}}',
    valueTotal: '{{Total Tax}}'
  },
  total: {
    name: 'TOTAL ($)',
    value: '{{PRICE}}',
    valueTotal: `\${{TOTAL}}`
  }
};

export const ADDRESS_TO_VALUE = {
  [RecipientTypes.Client]: 'Client Name',
  [RecipientTypes.KeyClientContact]: 'Key Client Contact Name',
  [RecipientTypes.Funder]: 'Funder Name'
};

export interface Recipient {
  type: RecipientTypes;
  tags?: string[]; // for key client contacts
}

export interface InvoiceTemplatesOverViewProps {
  itemOverviews: ItemOverview[];
  itemColumns: ItemColumns[];
  allowMultipleItems?: boolean;
}

export interface InvoiceTemplatesPreviewProps {
  isModalOpen: boolean;
  onModalClose: () => void;
  data: InvoiceTemplatesData;
  practiceInfo?: PracticeInterface & { address?: string };
  paymentMethods: PaymentMethod[];
}

interface InvoiceTemplatesData extends HeaderProps, InvoiceInfoProps, MedicareInfoProps, InvoiceTemplatesOverViewProps {
  _id?: string;
  paymentMethods: string[];
  dueDate?: boolean;
  name: string;
  dueAt: number;
  invoiceNotes?: string;
  invoiceFooter?: string;
  sendTo: Recipient[];
  accountId?: string;
}

export interface HeaderProps {
  practiceLogo?: boolean;
  practiceName?: boolean;
  practiceAddress?: boolean;
  practicePhoneNumber?: boolean;
  practiceFax?: boolean;
  practiceFormalName?: boolean;
  practiceRegistrationNumber?: boolean;
  practiceFinanceEmail?: boolean;
}

export interface InvoiceInfoProps {
  invoiceTitle?: string;
  invoiceId?: boolean;
  issueDate?: boolean;
  recipientAddress?: boolean;
  recipientRegistrationNumber?: boolean;
  shortCode?: string;
  recipientReference?: boolean;
  recipientMobileNumber?: boolean;
  recipientEmail?: boolean;
  recipient: Recipient;
}

export interface MedicareInfoProps {
  medicareClaimant?: boolean;
  medicareClaim?: boolean;
  medicareServiceProvider?: boolean;
  medicareReferral?: boolean;
}

export interface PreviewItemInfoProps {
  label?: string;
  value: string;
  className?: string;
}
