import classnames from 'classnames';
import ReactSelect, { components } from 'react-select';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import FlexBox from 'components/FlexBox/FlexBox';
import styles from './GroupedMultiSelect.module.scss';

interface GroupedMultiSelectProps {
  groupedOptions: {
    label: string;
    options: {
      label: string;
      value: string;
      group?: string;
      isDisabled?: boolean;
    }[];
  }[];
  value: {
    label: string;
    value: string;
    group?: string;
    isDisabled?: boolean;
  }[];
  onSelect: (target: { label: string; value: string; group?: string; isDisabled?: boolean }) => void;
  errorMessage?: string;
  placeholder?: string;
}

const customDropdownIndicator = () => (
  <i className={classnames('material-icons', styles.dropdownIndicatorIcon)}>arrow_drop_down</i>
);

const customOption = (props: any) => {
  const {
    data: { isDisabled }
  } = props;
  return (
    <div>
      <components.Option {...props} className={isDisabled && styles.disabledOption}>
        <FlexBox direction="row" alignItems="center" spacing={8}>
          <input type="checkbox" disabled={isDisabled} checked={props.isSelected} onChange={() => null} />
          <label>{props.label}</label>
        </FlexBox>
      </components.Option>
    </div>
  );
};

const customGroup = (props: any) => {
  return (
    <div className={styles.grouped}>
      <components.Group {...props} />
    </div>
  );
};

const GroupedMultiSelect = ({
  groupedOptions,
  value,
  onSelect,
  errorMessage,
  placeholder
}: GroupedMultiSelectProps) => {
  return (
    <div className={styles.container}>
      <ReactSelect
        isMulti
        options={groupedOptions}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={(e: any) => {
          onSelect(e);
        }}
        value={value}
        components={{
          Option: customOption,
          Group: customGroup,
          DropdownIndicator: customDropdownIndicator
        }}
        styles={{
          control: (controlBase) => ({
            ...controlBase,
            minHeight: '40px',
            border: 'none',
            borderBottom: `1px solid ${errorMessage ? '#ff1900' : '#b5b8bd'}`,
            borderRadius: 0,
            boxShadow: 'none',
            cursor: 'pointer'
          }),
          valueContainer: (base) => ({
            ...base,
            minHeight: '40px',
            padding: '0 8px 0 0'
          }),
          indicatorSeparator: (base) => ({ ...base, display: 'none' }),
          placeholder: (base) => ({ ...base, color: styles.newGreyColor, fontSize: '14px', lineHeight: '21px' }),
          multiValue: (base) => ({
            ...base,
            backgroundColor: styles.newBlueColor,
            borderRadius: 25,
            color: styles.newGreyColor,
            padding: 4,
            fontSize: 12
          }),
          multiValueLabel: (base, props) => ({
            ...base,
            color: styles.newGreyColor,
            fontSize: 12,
            ...(props.data.isDisabled && { paddingRight: 6 })
          }),
          multiValueRemove: (base, props) => (props.data.isDisabled ? { ...base, display: 'none' } : base)
        }}
      />
      {!!errorMessage && <ErrorMessage error={errorMessage} visible={!!errorMessage} />}
    </div>
  );
};

export default GroupedMultiSelect;
