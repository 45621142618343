import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import {
  AvailabilityTimeSlot,
  noAvailabilityReasonTexts,
  SelectedAppointmentSlotProps
} from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import { MOMENTJS_FORMAT_TIME } from 'utils/appointment';

import styles from './TimeSlotCard.module.scss';
import { Skeleton } from 'antd';
import clientRecordEmpty from 'assets/images/clientRecordEmpty2.svg';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import {
  appointmentViewFilter,
  FILTER_TIME_LABELS,
  FilterTimes,
  setAppointmentViewFilter
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

export interface timeSlotDataInterface {
  date: string;
  isAvailable: boolean;
  timeSlots: AvailabilityTimeSlot[];
}

type TimeSlotCardProps = {
  isAvailabilitiesViewTypeSimple: boolean;
  selectedAppointmentSlot?: SelectedAppointmentSlotProps;
  onSelectSlot: (slot: SelectedAppointmentSlotProps) => void;
  data: timeSlotDataInterface;
  isLoading?: boolean;
  isFetching?: boolean;
  showStartTimeOnly?: boolean;
};

const TimeSlotCard = ({
  isAvailabilitiesViewTypeSimple,
  selectedAppointmentSlot,
  onSelectSlot,
  data,
  isLoading,
  isFetching,
  showStartTimeOnly
}: TimeSlotCardProps) => {
  const dispatch = useAppDispatch();
  const timeSlotRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation();
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const selectedAppointmentViewFilter = useAppSelector(appointmentViewFilter);

  useEffect(() => {
    if (timeSlotRef.current) {
      if (timeSlotRef.current.scrollHeight - (timeSlotRef.current.scrollTop + timeSlotRef.current.clientHeight) < 10) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    }
    // eslint-disable-next-line
  }, [timeSlotRef.current]);

  const checkWholeDayAvailability = useMemo(
    () => data.timeSlots.length > 0 && data.timeSlots.some((slotObj) => slotObj.isAvailable),
    [data.timeSlots]
  );

  const mapTimes = (timeSlots: AvailabilityTimeSlot[]) => {
    const filteredTimeSlots = isAvailabilitiesViewTypeSimple
      ? timeSlots.filter((x: { isAvailable: boolean }) => x.isAvailable)
      : timeSlots;

    const isAppointmentEqual = ({
      selectedAppointment,
      currentAppointment
    }: {
      selectedAppointment?: SelectedAppointmentSlotProps;
      currentAppointment: SelectedAppointmentSlotProps;
    }) => JSON.stringify(selectedAppointment) === JSON.stringify(currentAppointment);

    const mappedTimeSlots =
      filteredTimeSlots && filteredTimeSlots.length > 0
        ? filteredTimeSlots
            .map((timeSlot, index) => {
              if (isAvailabilitiesViewTypeSimple && !timeSlot.isAvailable) {
                return undefined;
              }

              const formattedStartTime = moment(timeSlot.startTime, MOMENTJS_FORMAT_TIME).format(
                showStartTimeOnly ? 'hh:mmA' : 'hh:mm'
              );

              const formattedEndTime = moment(timeSlot.endTime, MOMENTJS_FORMAT_TIME).format('hh:mmA');

              return (
                <div
                  key={index}
                  {...(!timeSlot.isAvailable && {
                    'data-tooltip-content': noAvailabilityReasonTexts[timeSlot.noAvailabilityReason]
                      ? t(noAvailabilityReasonTexts[timeSlot.noAvailabilityReason])
                      : timeSlot.noAvailabilityReason,
                    'data-tooltip-id': `unavailable-reason`
                  })}
                  className={classNames(
                    !timeSlot.isAvailable ? styles.timeButtonDisabled : styles.timeButton,
                    showStartTimeOnly && styles.startTimeOnly,
                    isAppointmentEqual({
                      selectedAppointment: selectedAppointmentSlot,
                      currentAppointment: {
                        date: timeSlot.date || '',
                        startTime: moment(timeSlot.startTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME),
                        endTime: moment(timeSlot.endTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME)
                      }
                    }) && styles.active
                  )}
                  onClick={() => {
                    timeSlot.isAvailable &&
                      onSelectSlot({
                        date: timeSlot.date || '',
                        startTime: moment(timeSlot.startTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME),
                        endTime: moment(timeSlot.endTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME)
                      });
                  }}
                >
                  {showStartTimeOnly ? `${formattedStartTime}` : `${formattedStartTime} to ${formattedEndTime}`}
                </div>
              );
            })
            .filter((timeComponent) => !!timeComponent)
        : [];

    if (mappedTimeSlots.length === 0 && isAvailabilitiesViewTypeSimple) {
      return <div className={styles.noAvailability}>No availability</div>;
    }

    return (
      <div className={showStartTimeOnly ? styles.startTimeOnlyContainer : styles.timeSlotContainer}>
        {mappedTimeSlots}
      </div>
    );
  };

  const onScrollClick = () => {
    timeSlotRef.current?.scrollBy({
      top: 3 * (timeSlotRef.current.scrollHeight / data.timeSlots.length),
      behavior: 'smooth'
    });
  };

  return (
    <>
      <div
        ref={timeSlotRef}
        className={styles.container}
        onScroll={(e) => {
          setIsScrolledToBottom(
            e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight) < 10
          );
        }}
      >
        <div className={styles.listWrapper}>
          {isLoading ? (
            <div className={showStartTimeOnly ? styles.startTimeOnlyContainer : styles.timeSlotContainer}>
              {[...Array(12)].map((_obj, i) => (
                <div key={i} className={styles.loadingWrapper}>
                  <Skeleton.Input active className={styles.loading} />
                </div>
              ))}
            </div>
          ) : isFetching ? (
            <div className={showStartTimeOnly ? styles.startTimeOnlyContainer : styles.timeSlotContainer}>
              {[...Array(12)].map((_obj, i) => (
                <div key={i} className={styles.loadingWrapper}>
                  <Skeleton.Input active className={styles.loading} />
                </div>
              ))}
            </div>
          ) : !checkWholeDayAvailability ? (
            <div className={styles.noAvailableSlot}>
              <div className={styles.slotFilterEmptyContainer}>
                <img className={styles.emptyImg} src={clientRecordEmpty} alt={'No filter result found'} />
                <div className={styles.emptyDesc}>
                  <div className={styles.emptyTitle}>
                    No{' '}
                    {selectedAppointmentViewFilter?.times &&
                      selectedAppointmentViewFilter?.times !== FilterTimes.All &&
                      FILTER_TIME_LABELS[selectedAppointmentViewFilter?.times].toLowerCase()}{' '}
                    slot available on {moment(data.date).format('DD MMM YYYY')}.
                  </div>
                  <div className={styles.suggestion}>
                    Please update your filters or&nbsp;
                    <ButtonAlt
                      className={styles.clearAll}
                      variant={'text'}
                      onClick={() => {
                        dispatch(
                          setAppointmentViewFilter({
                            ...selectedAppointmentViewFilter,
                            times: FilterTimes.All
                          })
                        );
                      }}
                    >
                      clear all
                    </ButtonAlt>
                  </div>
                </div>
              </div>
            </div>
          ) : data.timeSlots.length > 0 ? (
            mapTimes(data.timeSlots)
          ) : (
            <div className={styles.noAvailability}>No availability</div>
          )}
        </div>
        <Tooltip hidden={isAvailabilitiesViewTypeSimple} id={`unavailable-reason`} />
      </div>
      {!isScrolledToBottom && (
        <div className={styles.scrollDownWrapper}>
          <div
            className={classNames(styles.scrollDownText, isScrolledToBottom && styles.scrolledToBottom)}
            onClick={onScrollClick}
          >
            <div className={styles.scroll}>
              Scroll
              <i className="material-icons-outlined">keyboard_double_arrow_down</i>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TimeSlotCard;
