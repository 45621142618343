import ContentLayout from 'components/ContentLayoutT23/ContentLayoutT23';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import PendingClaimInvoiceList from './components/PendingClaimInvoiceList/PendingClaimInvoiceList';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  PendingClaimTabs,
  selectActiveTab,
  setActiveTab
} from 'redux/features/pendingClaimInvoices/pendingClaimInvoiceSlice';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useCallback, useEffect } from 'react';

const InvoicePendingClaims = () => {
  const location = useLocation();
  const activeTab = useAppSelector(selectActiveTab);
  const dispatch = useAppDispatch();
  const { INVOICES } = useRoutesGenerator();

  const mapURLToClaimTab = useCallback(
    (path: string) => {
      switch (path) {
        case INVOICES.PENDING_CLAIMS_ABANDONED: {
          return PendingClaimTabs.Abandoned;
        }
        case INVOICES.PENDING_CLAIMS_DATA_NOT_READY: {
          return PendingClaimTabs.DataNotReady;
        }
        case INVOICES.PENDING_CLAIMS_DATA_READY:
        default: {
          return PendingClaimTabs.DataReady;
        }
      }
    },
    [INVOICES]
  );

  useEffect(() => {
    const tabByURL = mapURLToClaimTab(location.pathname);
    if (tabByURL !== activeTab) {
      dispatch(setActiveTab(tabByURL));
    }
  }, [location.pathname, activeTab, mapURLToClaimTab, dispatch]);

  return (
    <HelmetWrapper title={'Tacklit - Pending claim invoice'}>
      <ContentLayout>
        <PendingClaimInvoiceList />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InvoicePendingClaims;
