import { notification } from 'antd';
import { getProviderLabel } from 'pages/ControlPanel/IntegrationDetails/utils';
import { Integration, IntegrationStatus, IntegrationType } from 'pages/ControlPanel/Interfaces/Integration';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import {
  getSystemIntegration,
  getSystemIntegrations
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';

export const useFetchSystemIntegrations = (token: string, asAdmin: boolean) => {
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [isIntegrationsLoading, setIsIntegrationsLoading] = useState(true);
  const [isCodatConnected, setIsCodatConnected] = useState(false);

  const fetchSystemIntegrations = useCallback(
    async (token: string) => {
      setIsIntegrationsLoading(true);
      try {
        const getSystemIntegrationsResponses = await getSystemIntegrations(token, asAdmin);
        const systemIntegrations = (await getSystemIntegrationsResponses.json()) as Integration[];

        const populatedSystemIntegrations = systemIntegrations.map((integration) => ({
          ...integration,
          label: getProviderLabel(integration.provider)
        }));

        setIntegrations(populatedSystemIntegrations);
        setIsCodatConnected(
          populatedSystemIntegrations.some(
            ({ type, status }) => type === IntegrationType.Account && status === IntegrationStatus.Connected
          )
        );
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get system integrations' });
      }
      setIsIntegrationsLoading(false);
    },
    [asAdmin]
  );

  const refetchSystemIntegrations = () => {
    fetchSystemIntegrations(token);
  };

  useEffect(() => {
    if (token) {
      fetchSystemIntegrations(token);
    }
  }, [token, fetchSystemIntegrations]);

  return { integrations, isIntegrationsLoading, isCodatConnected, refetchSystemIntegrations };
};

export const useFetchSystemIntegration = (token: string, asAdmin: boolean, integrationId: string) => {
  const [integration, setIntegration] = useState<Integration>({} as Integration);
  const [isIntegrationLoading, setIsIntegrationLoading] = useState(true);
  const navigate = useNavigate();
  const { CONTROL_PANEL } = useRoutesGenerator();

  const fetchSystemIntegration = useCallback(
    async (token: string, asAdmin: boolean, integrationId: string) => {
      setIsIntegrationLoading(true);
      try {
        const getSystemIntegrationResponses = await getSystemIntegration(token, integrationId, asAdmin);
        const systemIntegration = (await getSystemIntegrationResponses.json()) as Integration;

        const populatedSystemIntegration = {
          ...systemIntegration,
          label: getProviderLabel(systemIntegration.provider)
        };

        setIntegration(populatedSystemIntegration);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get system integrations' });

        if (isErrorBentStatusError(ex) && ex.statusCode === 404) {
          navigate(CONTROL_PANEL.SYSTEM_CONNECTIONS);
        }
      }
      setIsIntegrationLoading(false);
    },
    [CONTROL_PANEL.SYSTEM_CONNECTIONS, navigate]
  );

  const refetchSystemIntegration = () => {
    fetchSystemIntegration(token, asAdmin, integrationId);
  };

  useEffect(() => {
    if (token) {
      fetchSystemIntegration(token, asAdmin, integrationId);
    }
  }, [token, asAdmin, integrationId, fetchSystemIntegration]);

  return { integration, isIntegrationLoading, refetchSystemIntegration };
};
