import { DatePicker } from 'antd';
import moment from 'moment';
import FlexBox from 'components/FlexBox/FlexBox';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import styles from './InlineEditField.module.scss';

interface InlineEditFieldProps {
  placeholder?: string;
  label?: string;
  value?: string | number;
  name?: string;
  editable?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDate?: (e: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
  readOnly?: boolean;
}

const InlineEditField = ({
  placeholder,
  label,
  value,
  name,
  editable,
  onChange,
  onChangeDate,
  readOnly
}: InlineEditFieldProps) => {
  const isTableFieldInput = name
    ? ['overview', 'description', 'quantity', 'cost', 'tax', 'total'].includes(name)
    : false;

  const getInput = () => {
    const isDateInput = name ? ['dueDate', 'dateOfBirth', 'dateOfService', 'date'].includes(name) : false;
    if (isDateInput && onChangeDate) {
      return (
        <DatePicker
          className={styles.inlineDatePicker}
          disabledDate={(current) => name === 'dateOfBirth' && current && current > moment().startOf('day')}
          suffixIcon={null}
          bordered={false}
          value={isDateInput && value !== '' ? moment(value, MOMENTJS_DATE_FORMAT) : null}
          allowClear={false}
          onChange={(_value, dateString) =>
            onChangeDate({
              target: {
                name: name || '',
                value: dateString
              }
            })
          }
          format={MOMENTJS_DATE_FORMAT}
          placeholder=""
        />
      );
    }

    return (
      <MaterialInput
        className={isTableFieldInput ? styles.tableFieldInput : styles.input}
        id={name || 'inlineName'}
        disabled={!editable}
        name={name}
        value={value}
        label=""
        onChange={onChange}
        placeholder={placeholder}
        required
        readOnly={readOnly}
      />
    );
  };

  return (
    <FlexBox direction="row" spacing={4} alignItems="center" className={styles.inputWrapper}>
      {label && <span className={styles.label}>{label}:</span>}
      {editable ? (
        getInput()
      ) : (
        <span className={isTableFieldInput ? styles.tableFieldValue : styles.value}>{value}</span>
      )}
    </FlexBox>
  );
};

export default InlineEditField;
