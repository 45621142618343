import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode';

interface DecodedToken {
  sub: string;
  scope: string;
  exp: number;
  'https://tacklit.com/jwt-claims/clinician-id': string;
  'https://tacklit.com/jwt-claims/practice-name': string;
}

interface ParsedAccountDetails {
  accountId: string;
  practiceName: string;
  token: string;
}

const useGetAccountDetailsFromSetPasswordToken = () => {
  const { search } = useLocation();

  const [accountDetails, setAccountDetails] = useState<ParsedAccountDetails>({
    accountId: '',
    practiceName: '',
    token: ''
  });

  const [expiredTime, setExpiredTime] = useState<number>(moment().unix());

  const getAccountDetailsFromToken = useCallback(() => {
    try {
      const { token } = queryString.parse(search);

      if (!token || typeof token !== 'string') {
        setExpiredTime(0);
        return;
      }

      const decodedToken = jwt_decode<DecodedToken>(token);

      if (
        !decodedToken.sub ||
        !decodedToken['https://tacklit.com/jwt-claims/clinician-id'] ||
        !decodedToken['https://tacklit.com/jwt-claims/practice-name'] ||
        decodedToken.scope !== 'write:clinicians'
      ) {
        setExpiredTime(0);
        return;
      }

      setExpiredTime(decodedToken.exp);

      setAccountDetails({
        accountId: decodedToken.sub,
        practiceName: decodedToken['https://tacklit.com/jwt-claims/practice-name'],
        token
      });
    } catch (ex) {
      console.error(ex);
      setExpiredTime(0);
    }
  }, [search]);

  useEffect(() => {
    getAccountDetailsFromToken();
  }, [getAccountDetailsFromToken]);

  return { accountDetails, expiredTime };
};

export default useGetAccountDetailsFromSetPasswordToken;
