import classNames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import {
  appointmentViewFilter,
  AppointmentViewType,
  FILTER_TIME_LABELS,
  FilterTimes,
  selectAppointmentData,
  selectedAppointmentView,
  selectIgnoreRoomConflict,
  setAppointmentView,
  setAppointmentViewFilter,
  setIgnoreRoomConflict
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import AppointmentDateAndTime from '../AppointmentDateAndTime/AppointmentDateAndTime';
import AppointmentRecurring from '../AppointmentRecurring/AppointmentRecurring';
import AppointmentRoom from '../AppointmentRoom/AppointmentRoom';
import HumanFactors from './components/HumanFactors/HumanFactors';
import styles from './AppointmentContent.module.scss';
import { Datepicker } from '@mobiscroll/react';
import ToggleSwitchV2, { ToggleList } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import { RefObject, useMemo } from 'react';
import AppointmentWithCalendar from '../AppointmentWithCalendar/AppointmentWithCalendar';
import OpenSlotAppointment from '../OpenSlotAppointment/OpenSlotAppointment';
import moment from 'moment';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import AppointmentInstructionNote from '../AppointmentInstructionNote/AppointmentInstructionNote';

const appointmentViewMenuFilterByTime = [
  { name: `${FILTER_TIME_LABELS.all} times`, _id: FilterTimes.All },
  { name: `${FILTER_TIME_LABELS.morning} only`, _id: FilterTimes.Morning },
  { name: `${FILTER_TIME_LABELS.afternoon} only`, _id: FilterTimes.Afternoon },
  { name: `${FILTER_TIME_LABELS.evening} only`, _id: FilterTimes.Evening }
];

export interface FetchingDates {
  from: string;
  to: string;
}

export const DEFAULT_FETCHING_DATES: FetchingDates = {
  from: moment().format(MOMENTJS_FORMAT_DATE),
  to: moment().add(2, 'month').endOf('month').format(MOMENTJS_FORMAT_DATE)
};

// Extracted function
export const getTimeRange = (period: FilterTimes) => {
  switch (period) {
    case FilterTimes.Morning:
      return { start: '00:00', end: '11:59' };
    case FilterTimes.Afternoon:
      return { start: '12:00', end: '17:59' };
    case FilterTimes.Evening:
      return { start: '18:00', end: '23:59' };
    case FilterTimes.All:
    default:
      return { start: '00:00', end: '23:59' };
  }
};

interface AppointmentContentProps {
  practitionerMainOption?: boolean;
  rememberSelectedPractitioner?: boolean;
  showSelectedItemOnFirstOption?: boolean;
  onInlineRef?: (ref: Datepicker | null) => void;
  dropdownRef?: RefObject<HTMLDivElement>;
}

const AppointmentContent = ({
  practitionerMainOption,
  rememberSelectedPractitioner,
  showSelectedItemOnFirstOption,
  onInlineRef,
  dropdownRef
}: AppointmentContentProps) => {
  const dispatch = useAppDispatch();
  const ignoreRoomConflict = useAppSelector(selectIgnoreRoomConflict);
  const { selectedRoom, selectedAppointmentType, selectedDeliveryMode } = useAppSelector(selectAppointmentData);
  const selectedAppointmentTypeView = useAppSelector(selectedAppointmentView);
  const selectedAppointmentViewFilter = useAppSelector(appointmentViewFilter);

  const APPOINTMENTS_VIEW_TYPE: ToggleList[] = useMemo(
    () => [
      {
        id: AppointmentViewType.Calendar,
        label: 'Calendar',
        isActive: selectedAppointmentTypeView === AppointmentViewType.Calendar
      },
      {
        id: AppointmentViewType.OpenSlot,
        label: 'Open Slots',
        isActive: selectedAppointmentTypeView === AppointmentViewType.OpenSlot
      },
      {
        id: AppointmentViewType.Customise,
        label: 'Customise',
        isActive: selectedAppointmentTypeView === AppointmentViewType.Customise
      }
    ],
    [selectedAppointmentTypeView]
  );

  const onFilterRoom = (selectedRoom: FilterCheckListItem[]) => {
    console.log(selectedRoom, '>>selectedFormsStatus');
  };

  const onFilterTime = (selected: string) => {
    dispatch(
      setAppointmentViewFilter({
        ...selectedAppointmentViewFilter,
        times: selected
      })
    );
  };

  return (
    <>
      <div className={styles.humanFactorsWrapper}>
        <HumanFactors
          practitionerMainOption={practitionerMainOption}
          rememberSelectedPractitioner={rememberSelectedPractitioner}
          showSelectedItemOnFirstOption={showSelectedItemOnFirstOption}
        />
      </div>
      <div className={styles.bottomContent}>
        <div className={styles.viewToggleWrapper}>
          <ToggleSwitchV2
            id={'typeOfAppointment'}
            toggleList={APPOINTMENTS_VIEW_TYPE}
            className={styles.typeOfAppointmentClassName}
            onChangeStatus={(value) => dispatch(setAppointmentView(value.id))}
            t23Theme
            size={'small'}
          />
          {selectedAppointmentType &&
            selectedDeliveryMode &&
            selectedAppointmentTypeView !== AppointmentViewType.Customise && (
              <div>
                <FilterDropdown
                  id={'appointmentFilter'}
                  baseDropdownClass={styles.appointmentFilter}
                  menuItems={[]}
                  onChangeItem={onToggleFilter([], selectedAppointmentViewFilter?.room || [], onFilterRoom)}
                  selectedFilterList={selectedAppointmentViewFilter?.room || []}
                  filterRadioListItems={appointmentViewMenuFilterByTime}
                  onChangeRadioItem={onFilterTime}
                  selectedRadioItem={selectedAppointmentViewFilter?.times}
                  checkBoxSectionTitle={'Available Room'}
                  radioSectionTitle={'Times'}
                  reverseSection
                  hideFilterList
                  customLabel={
                    <div className={styles.customLabel}>
                      {selectedAppointmentViewFilter?.times && selectedAppointmentViewFilter?.times !== FilterTimes.All
                        ? `${FILTER_TIME_LABELS[selectedAppointmentViewFilter?.times]} only`
                        : 'Filter by'}
                      <span className={classNames('material-icons-outlined', styles.expandIcon)}>arrow_drop_down</span>
                    </div>
                  }
                  placements={'bottomRight'}
                  dropdownRef={dropdownRef}
                >
                  Filter
                </FilterDropdown>
              </div>
            )}
        </div>
        <div>
          {selectedAppointmentTypeView === AppointmentViewType.Calendar && <AppointmentWithCalendar />}
          {selectedAppointmentTypeView === AppointmentViewType.OpenSlot && <OpenSlotAppointment />}
          {selectedAppointmentTypeView === AppointmentViewType.Customise && (
            <AppointmentDateAndTime onInlineRef={onInlineRef} />
          )}
        </div>
        <div className={styles.recurringAndRoom}>
          <div className={styles.recurring}>
            <AppointmentRecurring />
          </div>
          <div className={styles.instructionNote}>
            <AppointmentInstructionNote />
          </div>
          <div className={styles.room}>
            <AppointmentRoom />
          </div>
        </div>
        {selectedRoom?.isConflicted && (
          <div className={styles.roomConflict}>
            <i className={classNames(styles.icon, 'material-icons-outlined')}>warning_amber</i>
            <div className={styles.message}>
              <div>
                Sorry, the room is not available, please select other date and time or room to create this appointment.
              </div>
              <CheckBox
                useT23style
                className={styles.checkboxClassName}
                labelClassName={styles.checkboxLabelClassName}
                inputClassName={styles.inputClassName}
                id="ignoreRoomConflict"
                value={ignoreRoomConflict}
                onChange={(event) => {
                  dispatch(setIgnoreRoomConflict(event.target.checked));
                }}
                label="Ignore and continue book"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AppointmentContent;
