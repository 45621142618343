import { AppointmentReport } from 'redux/endpoints/scheduleServices/appointmentReport';
import columnStyles from '../AppointmentReportHeader/AppointmentReportHeaderColumn.module.scss';
import styles from './AppointmentReportItem.module.scss';
import classNames from 'classnames';
import { ServiceDeliveredNameMapping } from 'interfaces/Schedule/Appointment';
import { useCurrency } from 'utils/hooks/useCurrency';
import { getReportData } from 'pages/PractitionerReport/helper';

const AppointmentReportItem = ({ appointmentReport }: { appointmentReport: AppointmentReport }) => {
  const { CURRENCY_SYMBOL } = useCurrency();

  const {
    serviceId,
    dateOfService,
    startTime,
    nameOfService,
    tID,
    clientName,
    collected,
    invoiceAmount,
    paymentCompleted,
    notesOnUpdates,
    waiveCancellationFee,
    reviewNotes
  } = getReportData(appointmentReport, CURRENCY_SYMBOL);

  return (
    <div className={styles.container}>
      <div className={columnStyles.serviceId}>{serviceId}</div>
      <div className={columnStyles.dateOfService}>{dateOfService}</div>
      <div className={columnStyles.startTime}>{startTime}</div>
      <div className={columnStyles.nameOfService}>{nameOfService}</div>
      <div
        className={classNames(
          columnStyles.processedOutcome,
          appointmentReport.serviceDelivered ? styles[appointmentReport.serviceDelivered] : ''
        )}
      >
        {appointmentReport.serviceDelivered ? (
          <div className={classNames(styles.serviceDeliveredPill, styles[appointmentReport.serviceDelivered])}>
            {ServiceDeliveredNameMapping[appointmentReport.serviceDelivered]}
          </div>
        ) : (
          '-'
        )}
      </div>
      <div className={columnStyles.waiveCancellationFee}>{waiveCancellationFee}</div>
      <div className={columnStyles.reviewNotes}>{reviewNotes}</div>
      <div className={columnStyles.tID}>{tID}</div>
      <div className={columnStyles.clientName}>{clientName}</div>
      <div className={columnStyles.collected}>{collected}</div>
      <div className={columnStyles.invoiceAmount}>{invoiceAmount}</div>
      <div className={columnStyles.paymentCompleted}>{paymentCompleted}</div>
      <div className={columnStyles.notesOnUpdate}>
        {notesOnUpdates.length ? notesOnUpdates.map((note, index) => <div key={index}>{note}</div>) : '-'}
      </div>
    </div>
  );
};

export default AppointmentReportItem;
