import { useState } from 'react';
import styles from './SessionAssigned.module.scss';
import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import { AccessRight } from 'interfaces/Clients/clinician';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { GroupSessionDetails } from 'pages/Groups/Interfaces/GroupsSession';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface SessionAssignedProps {
  groupDetails: Groups;
  selectedPractitioner: GroupSessionDetails['groupDetails']['deliveringPractitionerIds'];
  selectedPractitionerProfile: GroupSessionDetails['groupDetails']['deliveringPractitionerProfiles'];
  allowEdit?: boolean;
  onChangeFacilitator: (val: GroupSessionDetails['groupDetails']['deliveringPractitionerIds']) => void;
}

const SessionAssigned = ({
  groupDetails,
  selectedPractitioner,
  selectedPractitionerProfile,
  allowEdit,
  onChangeFacilitator
}: SessionAssignedProps) => {
  const sessionClinicianList = [groupDetails.leadClinicianAuth0Id, ...groupDetails.clinicianAuth0Ids];
  const massageSessionClinicianList = sessionClinicianList.filter((cId) => cId);
  const [selectedClinicianIds, setSelectedClinicianIds] = useState<string[]>(
    selectedPractitioner && selectedPractitioner.length > 0 ? selectedPractitioner : ['']
  );
  const availableClinicianList = massageSessionClinicianList.filter((cId) => !selectedClinicianIds.includes(cId));

  const [t] = useTranslation();

  const handleSelectClinician = (id: number, selectedClinician?: PractitionersDetailsInterface) => {
    if (selectedClinician) {
      const newClinicianIds = [...selectedClinicianIds];
      newClinicianIds[id] = selectedClinician._id;
      setSelectedClinicianIds(newClinicianIds);
      onChangeFacilitator(newClinicianIds);
    }
  };

  const handleAddPractitionerSelector = () => {
    const newClinicianIds = [...selectedClinicianIds];
    newClinicianIds.filter((id) => !id).length === 0 && newClinicianIds.push('');
    setSelectedClinicianIds(newClinicianIds);
  };

  const handleRemovePractitioner = (index: number) => {
    const newClinicianIds = [...selectedClinicianIds];
    newClinicianIds.splice(index, 1);
    setSelectedClinicianIds(newClinicianIds.length > 0 ? newClinicianIds : ['']);
    onChangeFacilitator(newClinicianIds);
  };

  const selectedFacilitatorProfileList = selectedPractitionerProfile
    ? selectedPractitionerProfile.filter((cpObj) =>
        selectedClinicianIds.find((clinicianId) => clinicianId === cpObj._id)
      )
    : [];

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        {(allowEdit ? t('label.set_session_facilitator') : t('label.practitioner_assigned')).toUpperCase()}
      </div>
      {allowEdit ? (
        selectedClinicianIds.map((clinicianId, index) => (
          <div className={styles.listWrapper} key={index}>
            <ClinicianSelect
              className={styles.selectList}
              onSelect={(value) => handleSelectClinician(index, value)}
              selectedId={clinicianId}
              customClinicianIds={availableClinicianList}
              filterRoles={[AccessRight.Admin, AccessRight.User]}
              placeholder={t('label.select_practitioner')}
              onRemove={() => handleRemovePractitioner(index)}
              noInitChange
              allowEmpty
            />
            {selectedClinicianIds.length < massageSessionClinicianList.length && (
              <ButtonAlt
                variant={'text'}
                onClick={handleAddPractitionerSelector}
                disabled={selectedClinicianIds.filter((id) => !id).length > 0}
                icon={'add_circle_outline'}
              >
                Add another
              </ButtonAlt>
            )}
          </div>
        ))
      ) : selectedFacilitatorProfileList.length > 0 ? (
        selectedFacilitatorProfileList.map((cPObj, index) => (
          <div key={index} className={styles.facilitatorCard}>
            <img alt={`clinicianAvatar${index}`} src={cPObj.avatar} className={styles.facilitatorAvatar} />
            <div className={styles.facilitatorName}>{cPObj.name}</div>
          </div>
        ))
      ) : (
        <div className={styles.noFacilitator}>No specific facilitator designated</div>
      )}
    </div>
  );
};

export default SessionAssigned;
