import { config } from 'config/config';
import { IInvoiceTemplate } from 'pages/Invoices/interface';
import { InvoiceGenerationFormValue } from 'redux/invoices/createInvoiceWithTemplateSlice';

export const getRecipientsField = ({
  invoiceTemplate,
  recipients
}: {
  invoiceTemplate: IInvoiceTemplate;
  recipients: InvoiceGenerationFormValue['recipients'];
}) => {
  const { recipientAddress, recipientEmail, recipientMobileNumber, recipientReference, recipientRegistrationNumber } =
    invoiceTemplate;
  return recipients.map((item) => ({
    name: {
      label: 'Addressed To',
      name: 'name',
      show: true,
      value: item.name,
      editable: true
    },
    address: {
      label: 'Address',
      name: 'address',
      show: recipientAddress,
      value: item.address || '',
      editable: true
    },
    email: {
      label: 'Email',
      name: 'email',
      show: recipientEmail,
      value: item.email || '',
      editable: true
    },
    mobileNumber: {
      label: 'Phone',
      name: 'mobileNumber',
      show: recipientMobileNumber,
      value: item.mobileNumber || '',
      editable: true
    },
    reference: {
      label: 'Reference',
      name: 'reference',
      show: recipientReference,
      value: item.reference || '',
      editable: true
    },
    registrationNumber: {
      label: config.practiceSettings.registrationNumberLabel,
      name: 'registrationNumber',
      show: recipientRegistrationNumber,
      value: item.registrationNumber || '',
      editable: true
    }
  }));
};

export const getInvoiceFields = (invoiceForm: InvoiceGenerationFormValue) => {
  return {
    invoiceTitle: invoiceForm.title,
    invoiceId: {
      label: 'Invoice ID',
      name: 'invoiceId',
      show: true,
      value: invoiceForm.invoiceId,
      editable: false
    },
    issueDate: {
      label: 'Date of issue',
      name: 'issueDate',
      show: true,
      value: invoiceForm.issueDate,
      editable: false
    },
    dueDate: {
      label: 'Due date',
      name: 'dueDate',
      show: true,
      value: invoiceForm.dueDate,
      editable: true
    },
    recipients: invoiceForm.recipients,
    description: {
      name: 'description',
      show: true,
      value: invoiceForm.description,
      editable: true
    }
  };
};
