import classNames from 'classnames';
import ButtonAlt, { ButtonAltSize } from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './IconButton.module.scss';

export enum MaterialSet {
  Icons = 'Icons',
  Symbols = 'Symbols'
}

export type IconPaddingSize = 'small' | 'medium' | 'large';

interface IconButtonProps {
  active?: boolean;
  className?: string;
  /** Material Icons / Symbols from https://fonts.google.com/icons */
  iconName: string;
  isDisabled?: boolean;
  paddingSize?: IconPaddingSize;
  size?: ButtonAltSize;
  materialSet?: MaterialSet;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const materialClassName = {
  [MaterialSet.Icons]: 'material-icons-outlined',
  [MaterialSet.Symbols]: 'material-symbols-outlined'
};

const IconButton = ({
  active,
  className,
  iconName,
  isDisabled,
  paddingSize = 'large',
  size = 'large',
  materialSet = MaterialSet.Icons,
  onClick
}: IconButtonProps) => {
  return (
    <ButtonAlt
      className={classNames(styles.iconButton, styles[`${paddingSize}Padding`], className)}
      disabled={isDisabled}
      onClick={onClick}
      size={paddingSize}
      variant={active ? 'contained' : 'text'}
    >
      <div className={styles.iconContainer}>
        <div className={classNames(materialClassName[materialSet], styles[size])}>{iconName}</div>
      </div>
    </ButtonAlt>
  );
};

export default IconButton;
