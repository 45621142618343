import { useFetchAppointmentsByPatient } from './appointment';
import moment from 'moment';
import Handlebars from 'handlebars';
import { toPng } from 'html-to-image';
import { useFetchAssessmentByRecordProfileId, useFetchCancelledAppointments } from './GetAssessmentList/assessment';
import { useFetchPractitionerList } from '../../components/v2/ClinicianSelect/hooks/GetPractitionersList';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useFetchClientRecordById } from '../../pages/PatientDetails/hooks/GetClientRecord';
import { useFetchGeneralPractitionerById } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/GPDetails/components/AddEditClientGPModal/hooks/getGeneralPractitionerList';
import { AppointmentStatusOption } from 'interfaces/Schedule/Appointment';
import { useFetchSimpleClientData } from './useFetchSimpleClientData';
import { useFetchMyMedicareProviders } from 'pages/ControlPanel/IntegrationDetails/components/IntegrationDetailsContent/components/IntegrationDetailsContentDisplay/components/Integration/hooks/getMedicareData';
import { useGetAccountPackageView } from './GetAccountInfo/accountPackageView';
import { formatHeadingTemplateBodyString } from 'utils/helpers/formatHeadingTemplateBodyString';

export const generateAssessment = async (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    try {
      const dataUrl = await toPng(element);
      return `<img src="${dataUrl}" width="300" alt="assessment" />`;
    } catch (ex) {
      console.error(ex);
    }
  }
};

export const useGenerateHeadingTemplateDetails = (token: string, recordId: string, profileId: string) => {
  const { appointmentsData, fetchAppointments } = useFetchAppointmentsByPatient({
    token,
    clientRecordId: recordId
  });
  const { practitionersList, fetchPractitionersList } = useFetchPractitionerList(token, true, [
    AccessRight.Admin,
    AccessRight.User
  ]);
  const { clientRecordData, fetchClientRecord, isClientRecordLoading } = useFetchClientRecordById(token, recordId);
  const { generalPractitioner, isGeneralPractitionerLoading } = useFetchGeneralPractitionerById(
    token,
    clientRecordData?.referral?.generalPractitionerId
  );

  const { assessmentList, isLoadingAssessmentList, fetchAssessment } = useFetchAssessmentByRecordProfileId(
    token,
    recordId,
    true
  );

  const { cancelledAppointments } = useFetchCancelledAppointments(token, recordId);

  const { clientData, isClientDataLoading } = useFetchSimpleClientData(token, recordId);

  const isHeadingTemplateDetailsLoading =
    isLoadingAssessmentList ||
    isClientRecordLoading ||
    isClientDataLoading ||
    (!!clientRecordData?.referral?.generalPractitionerId && isGeneralPractitionerLoading);

  const { isEdgeAdminOrReceptionistView } = useGetAccountPackageView();
  const { providers } = useFetchMyMedicareProviders(token, true);
  const authorPreferredProvider = providers?.find((provider) => provider.default) || providers?.[0];
  const authorPreferredProviderName = isEdgeAdminOrReceptionistView ? '' : authorPreferredProvider?.name || '';
  const authorPreferredProviderNumber = isEdgeAdminOrReceptionistView
    ? ''
    : authorPreferredProvider?.providerNumber || '';

  const generateHeadingTemplate = async (body: string) => {
    const matchedClientData = clientData.find((item) => item.clientProfileId === profileId);
    const cdfList: { [key: string]: string } = matchedClientData
      ? matchedClientData?.variables.reduce((obj, { variableId, value, otherValue }) => {
          return {
            ...obj,
            [variableId]: `${otherValue ? `${value} - ${otherValue}` : value}`
          };
        }, {})
      : {};

    const firstAssessment = await generateAssessment('firstAssessment');
    const mostRecentAssessment = await generateAssessment('mostRecentAssessment');

    const firstAppointDate =
      appointmentsData.length > 0
        ? moment(`${appointmentsData[0].date} ${appointmentsData[0].startTime}`).format('dddd DD MMMM YYYY \\at hh:mmA')
        : '';
    const lastAppointDate =
      appointmentsData.length > 0
        ? moment(
            `${appointmentsData[appointmentsData.length - 1].date} ${
              appointmentsData[appointmentsData.length - 1].startTime
            }`
          ).format('dddd DD MMMM YYYY \\at hh:mmA')
        : '';
    const firstAssessmentDate =
      assessmentList.length > 0 ? moment(assessmentList[0].createdAt).format('dddd DD MMMM YYYY \\at hh:mmA') : '';
    const lastAssessmentDate =
      assessmentList.length > 0
        ? moment(assessmentList[assessmentList.length - 1].createdAt).format('dddd DD MMMM YYYY \\at hh:mmA')
        : '';

    if (!clientRecordData) {
      return '';
    }

    const clientProfileData = clientRecordData.clientProfiles.filter((cpObj) => cpObj._id === profileId)[0];

    const getPractitionerId =
      clientRecordData.clinicianAuth0Ids.length > 0 ? clientRecordData.clinicianAuth0Ids[0] : '';
    const getPractitionerDetails = practitionersList.find(
      (practitionerObj) => practitionerObj._id === getPractitionerId
    );
    const getPractitionerName = getPractitionerDetails?.name || '';

    const sessionsNotAttend = appointmentsData.filter(
      (appointmentObj) =>
        appointmentObj.markedStatus?.includes(AppointmentStatusOption.DidNotAttend) ||
        appointmentObj.markedStatus?.includes(AppointmentStatusOption.ClientDidNotAttend) ||
        appointmentObj.markedStatus?.includes(AppointmentStatusOption.ClinicianDidNotAttend)
    ).length;

    const referrerName = clientRecordData.keyClientContacts?.filter((keyContactObj) =>
      keyContactObj.tags?.some((tagObj) => tagObj.toLowerCase() === 'referrer')
    )[0];

    const authorizedSessionNumber = clientRecordData.appointmentStatusOverviewConfiguration.limit;

    const variableList: { [key: string]: string | number | undefined } = {
      CLIENT_NAME: `${clientProfileData.firstName} ${clientProfileData.lastName}`,
      CLIENT_DOB: clientProfileData.dateOfBirth
        ? moment(clientProfileData.dateOfBirth, 'DD/MM/yyyy').format('D MMMM YYYY')
        : 'DOB Unknown',
      CLIENT_EMAIL: clientProfileData.email,
      CLIENT_MOBILE: clientProfileData.mobileNumber,
      CLIENT_POSTCODE: clientProfileData.postcode || '',
      FIRST_APPOINTMENT_DATE: firstAppointDate,
      RECENT_APPOINTMENT_DATE: lastAppointDate,
      APPOINTMENT_COUNT: appointmentsData.length,
      FIRST_ASSESSMENT: firstAssessment || '',
      FIRST_ASSESSMENT_DATE: firstAssessmentDate,
      RECENT_ASSESSMENT: mostRecentAssessment || '',
      RECENT_ASSESSMENT_DATE: lastAssessmentDate,
      PRACTITIONER_NAME: getPractitionerName,
      REFERRER_NAME: referrerName ? `${referrerName.firstName} ${referrerName.lastName}` : '',
      NUMBER_OF_AUTHORIZED_SESSION: authorizedSessionNumber,
      CLIENT_FIRST_NAME: clientProfileData.firstName,
      NUMBER_OF_SESSIONS_CANCELLED: cancelledAppointments.length,
      NUMBER_OF_SESSIONS_NOT_ATTEND: sessionsNotAttend,
      GP_NAME: generalPractitioner.name || '',
      GP_MEDICARE_PROVIDER_NUMBER: generalPractitioner.medicareProviderNumber || '',
      GP_NAME_OF_PRACTICE: generalPractitioner.practiceName || '',
      GP_ADDRESS: generalPractitioner.address || '',
      GP_FAX: generalPractitioner.fax || '',
      GP_LANDLINE: generalPractitioner.landline || '',
      GP_EMAIL: generalPractitioner.email || '',
      MY_PROVIDER_NAME: authorPreferredProviderName,
      MY_PROVIDER_NUMBER: authorPreferredProviderNumber,
      CLIENT_MEDICARE_NUMBER: clientProfileData?.medicare?.number || '',
      CLIENT_MEDICARE_IRN: clientProfileData?.medicare?.irn || ''
    };

    const variables = { ...variableList, ...cdfList };

    const fixedBody = formatHeadingTemplateBodyString(body);
    const regex1 = /<span[^<>]*class="mention"[^<>]*>(.*?)<\/span>{{|{{/gm;
    const regex2 = /}}<\/span>\ufeff<\/span>|}}/gm;
    const generateBodyPart1 = fixedBody.replace(regex1, '{{{[');
    const generateBodyPart2 = generateBodyPart1.replace(regex2, ']}}}');
    // remove sentence in [] if first value is empty
    const regexToFindAllSquareBrackets = /\[\[([^[\]]*{{{\[[^[\]]*\]}}}[^[\]]*)*\]\]/;
    const regexToFindVariable = /{{{\[([^[\]]*)\]}}}/;

    const template = generateBodyPart2.replaceAll(new RegExp(regexToFindAllSquareBrackets, 'g'), (str) => {
      const content = str.match(regexToFindAllSquareBrackets)?.[1] || '';
      const firstVariable = str.match(regexToFindVariable)?.[1];
      return firstVariable && variables[firstVariable] ? content : '';
    });

    const headingTemplate = Handlebars.compile(template);

    return headingTemplate(variables);
  };

  return {
    assessmentList,
    fetchAppointments,
    isHeadingTemplateDetailsLoading,
    fetchAssessment,
    fetchPractitionersList,
    fetchClientRecord,
    generateHeadingTemplate
  };
};
