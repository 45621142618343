import { useCurrency } from 'utils/hooks/useCurrency';
import styles from './InvoiceHistoryItem.module.scss';
import moment from 'moment';
import classNames from 'classnames';
import {
  InvoiceHistory,
  InvoiceHistoryActionNameMapping,
  InvoiceHistoryActor,
  InvoiceHistoryObject
} from 'pages/Invoices/interface';
import { useMemo } from 'react';

interface InvoiceHistoryItemProps {
  histories: InvoiceHistory[];
  clientName: string;
  practiceName: string;
}

export const getHistoryActionName = ({
  actor,
  clientName = 'Client',
  practiceName
}: {
  actor: InvoiceHistory['actor'];
  clientName?: string;
  practiceName: string;
}) => {
  if (actor.type === InvoiceHistoryActor.Clinician) {
    return actor.name ?? practiceName;
  } else if (actor.type === InvoiceHistoryActor.Client) {
    return actor.name ?? clientName;
  } else if (actor.type === InvoiceHistoryActor.System) {
    return 'Automation Rule';
  }
  return '-';
};

const InvoiceHistoryItem = ({ histories, clientName, practiceName }: InvoiceHistoryItemProps) => {
  const { CURRENCY_SYMBOL } = useCurrency();

  const sortedHistory = useMemo(
    () => [...histories].sort((x, y) => (moment(x.createdAt).isBefore(y.createdAt) ? 1 : -1)),
    [histories]
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.action}>ACTION</div>
        <div className={styles.detail}>DETAIL</div>
        <div className={styles.value}>{`${CURRENCY_SYMBOL} VALUE`}</div>
        <div className={styles.refId}>REF ID</div>
        <div className={styles.time}>TIME</div>
        <div className={styles.actionBy}>ACTION BY</div>
      </div>
      <div className={styles.content}>
        {sortedHistory.map((history, index) => (
          <div className={styles.historyItem} key={index}>
            <div className={styles.action}>
              <div
                className={classNames(
                  styles.actionPill,
                  history.object === InvoiceHistoryObject.StripePayment && history.status && styles[history.status]
                )}
              >
                {InvoiceHistoryActionNameMapping[history.object]}
              </div>
            </div>
            <div className={styles.detail}>{history.description}</div>
            <div className={classNames(styles.value, history.status === 'failed' && styles.failed)}>
              {history.amount !== undefined
                ? history.amount < 0
                  ? `(${CURRENCY_SYMBOL}${Math.abs(history.amount).toFixed(2)})`
                  : `${CURRENCY_SYMBOL}${history.amount.toFixed(2)}`
                : '-'}
            </div>
            <div className={styles.refId}>
              {history.reference || history.stripeRefundId || history.stripeChargeId || history.claimId || '-'}
            </div>
            <div className={styles.time}>
              <div>{moment(history.createdAt).format('hh:mm')}</div>
              <div>{moment(history.createdAt).format('DD / MM / YYYY')}</div>
            </div>
            <div className={styles.actionBy}>
              {getHistoryActionName({
                actor: history.actor,
                practiceName,
                clientName
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InvoiceHistoryItem;
