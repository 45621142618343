import { useEffect, useMemo, useState } from 'react';

import styles from './ChildClientForm.module.scss';
import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { validationChildAndYoungPersonField } from './validation/ChildClientFormValidation';
import { ClientDetails } from '../../../../AddPatientModalV2Interface';
import { ChildAndYoungClientErrorInterface } from './ChildClientInterface';
import { convertDateFormat } from 'utils/dateChecker';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/CheckBox/CheckBox';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { COMMUNICATION_OPTIONS } from 'components/AddPatientModalV2/constants';
import { ClientAddress, CommunicationPreference, ProfileType } from 'interfaces/Clients/clientsRecord';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import { useGetAccessToken } from 'utils/hooks/token';
import { debounce } from 'lodash';
import { validateEmail } from 'components/AddPatientModalV2/formType/AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import { checkPhoneNumber } from 'utils/http/NotifService/phoneValidator';
import AddressForm from 'components/AddPatientModalV2/components/AddressForm/AddressForm';

interface ChildOrYoungClientFormProps {
  id: string | number;
  recordType: 'child' | 'youngPerson';
  profileType: ProfileType;
  label?: string;
  clientDetail: ClientDetails;
  onChangeClientField: any;
  checkValidation: boolean;
  shadowBox?: boolean;
  excludeEmailCheckingGroup?: string[];
  clientAddress: ClientAddress;
  onChangeClientAddress: (address: ClientAddress) => void;
  onPlaceSelected: (address: ClientAddress) => void;
}

/**
 * This component is for child and youngPerson
 * youngPerson full profile will need to fill in contacts
 */
const ChildOrYoungClientForm = ({
  recordType,
  profileType,
  label,
  clientDetail,
  onChangeClientField,
  checkValidation,
  shadowBox,
  excludeEmailCheckingGroup,
  clientAddress,
  onChangeClientAddress,
  onPlaceSelected
}: ChildOrYoungClientFormProps) => {
  const requiredContactFields = recordType === 'youngPerson' && profileType === 'full';

  const { token } = useGetAccessToken();
  const [clientField, setClientField] = useState(clientDetail);
  const [errorMessage, setErrorMessage] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    ...(requiredContactFields && {
      email: '',
      mobileNumber: ''
    })
  } as ChildAndYoungClientErrorInterface);

  useEffect(() => {
    if (checkValidation) {
      const validate = validatePart1Field(clientField);
      const isNonEditEmailValue = excludeEmailCheckingGroup?.includes(clientField.email as string) || false;
      if (validate?.email === '' && clientField.email && !isNonEditEmailValue) {
        validateDuplicateEmail(clientField.email);
      }
      if (validate?.mobileNumber === '' && clientField.mobileNumber) {
        validatePhoneNumber(clientField.mobileNumber);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  // for youngPerson's email and mobile number checking ==============================
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [isCheckingDuplicate, setIsCheckingDuplicate] = useState(false);

  const [invalidPhone, setInvalidPhone] = useState(false);
  const [isCheckingPhone, setIsCheckingPhone] = useState(false);

  const debouncedCheckDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const duplicate = await validateEmail(token, value);
        setDuplicateEmail(duplicate.statusCode !== 200);
        setIsCheckingDuplicate(false);
      }, 1000),
    [token]
  );

  const debouncedValidatePhoneNumber = useMemo(
    () =>
      debounce(async (value) => {
        try {
          const duplicate = await (await checkPhoneNumber(value)).json();
          setInvalidPhone(!duplicate.valid);
          setIsCheckingPhone(false);
        } catch (e) {
          setInvalidPhone(true);
          setIsCheckingPhone(false);
        }
      }, 1000),
    []
  );

  const validatePhoneNumber = async (value: string) => {
    if (value && value.length >= 10) {
      setIsCheckingPhone(true);
      await debouncedValidatePhoneNumber(value);
    } else {
      setInvalidPhone(false);
    }
  };

  const handleChangePhoneNumber = async (value: string) => {
    const newClientField = {
      ...clientField,
      mobileNumber: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      const validate = validatePart1Field(newClientField);
      if (validate?.mobileNumber === '') {
        await validatePhoneNumber(value);
      }
    }
  };

  const handleChangeEmail = async (value: string) => {
    const newClientField = {
      ...clientField,
      email: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (value && checkValidation) {
      const validate = validatePart1Field(newClientField);
      const isNonEditEmailValue = excludeEmailCheckingGroup?.includes(value) || false;
      if (validate?.email === '' && !isNonEditEmailValue) {
        await validateDuplicateEmail(value);
      } else {
        setDuplicateEmail(false);
      }
    } else {
      setDuplicateEmail(false);
    }
  };

  const validateDuplicateEmail = async (emailValue: string) => {
    if (token) {
      setIsCheckingDuplicate(true);
      await debouncedCheckDuplicate(emailValue);
    }
  };

  const onCommunicationPreferenceChange = (value: ClientDetails['communicationPreference']) => {
    const newClientField = {
      ...clientField,
      communicationPreference: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
  };
  // END Ypp contact's checking =================================================================================

  const validatePart1Field = (clientVal: ChildAndYoungClientErrorInterface) => {
    const validate = validationChildAndYoungPersonField(clientVal);
    setErrorMessage(validate as ChildAndYoungClientErrorInterface);
    return validate;
  };

  const handleChangeFieldValue = async (key: string, value: string | boolean) => {
    const newClientField = {
      ...clientField,
      [key]: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const handleKeyDownDateChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      const newValue = clientField.dateOfBirth?.slice(0, clientField.dateOfBirth.length - 1);
      const newClientField = {
        ...clientField,
        dateOfBirth: newValue
      };
      setClientField(newClientField);
      onChangeClientField(newClientField);
      if (checkValidation) {
        validatePart1Field(newClientField);
      }
    }
  };

  const handleDateChange = (dateValue: string) => {
    const newDateFormat = convertDateFormat(dateValue);
    const newClientField = {
      ...clientField,
      dateOfBirth: newDateFormat
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const [t] = useTranslation();

  return (
    <div className={shadowBox ? styles.containerWithBox : styles.container}>
      {shadowBox && <div className={styles.labelContainer}>{label && <div className={styles.label}>{label}</div>}</div>}
      <div
        className={classnames(styles.fieldContainer, checkValidation && errorMessage.firstName && styles.fieldError)}
      >
        <MaterialInput
          id={'childClientFirstName'}
          label={`${t('form.client_first_name')}*`}
          onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
          value={clientField.firstName}
          required
        />
        {checkValidation && errorMessage.firstName && <div className={styles.fieldError}>{errorMessage.firstName}</div>}
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && errorMessage.lastName && styles.fieldError)}>
        <MaterialInput
          id={'childClientLastName'}
          label={`${t('form.client_last_name')}*`}
          onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
          value={clientField.lastName}
          required
        />
        {checkValidation && errorMessage.lastName && <div className={styles.fieldError}>{errorMessage.lastName}</div>}
      </div>
      <div
        className={classnames(styles.fieldContainer, checkValidation && errorMessage.dateOfBirth && styles.fieldError)}
      >
        <MaterialInput
          placeholder={'DD/MM/YYYY'}
          id={'childClientDOB'}
          label={'Date of Birth'}
          onKeyDown={(e) => handleKeyDownDateChange(e)}
          onChange={(e) => handleDateChange(e.target.value)}
          value={clientField.dateOfBirth}
          type={'tel'}
          pattern={'[0-9\\/]*'}
          maxLength={10}
          autoComplete={'off'}
        />
        {checkValidation && errorMessage.dateOfBirth && (
          <div className={styles.fieldError}>{errorMessage.dateOfBirth}</div>
        )}
      </div>

      <AddressForm
        clientAddress={clientAddress}
        onChangeClientAddress={onChangeClientAddress}
        onPlaceSelected={onPlaceSelected}
      />

      {recordType === 'youngPerson' && (
        <>
          <div className={styles.withCheckBoxContainer}>
            <div
              className={classnames(
                styles.fieldContainer,
                checkValidation && (errorMessage.email || duplicateEmail) && styles.fieldError
              )}
            >
              <MaterialInput
                id={'clientEmail'}
                label={`${t('form.client_email')}${requiredContactFields ? '*' : ''}`}
                onChange={(e) => handleChangeEmail(e.target.value)}
                value={clientField.email}
                isLoading={isCheckingDuplicate}
                required
              />
              {checkValidation && errorMessage.email && <div className={styles.fieldError}>{errorMessage.email}</div>}
              {checkValidation && duplicateEmail && (
                <div className={styles.fieldError}>This email address is already in use</div>
              )}
              <div className={styles.checkBoxField}>
                <CheckBox
                  id={'clientEmailIsThirdParty'}
                  value={clientField.isEmailThirdParty || false}
                  label={'Email belongs to third party e.g. friend or family'}
                  onChange={(e) => handleChangeFieldValue('isEmailThirdParty', e.target.checked)}
                />
              </div>
            </div>
          </div>

          <div className={styles.withCheckBoxContainer}>
            <div className={classnames(styles.phoneContainer)}>
              <MaterialPhoneInput
                id={'clientMobileNumber'}
                label={`Mobile Number${requiredContactFields ? '*' : ''}`}
                onChange={(e?: string) => handleChangePhoneNumber(e || '')}
                value={clientField.mobileNumber || ''}
                isLoading={isCheckingPhone}
                autoFormat={false}
                isError={!!(checkValidation && errorMessage.mobileNumber && invalidPhone)}
              />
              {checkValidation && errorMessage.mobileNumber && (
                <div className={styles.fieldError}>{errorMessage.mobileNumber}</div>
              )}
              {checkValidation && invalidPhone && (
                <div className={styles.fieldError}>Please enter a valid mobile number</div>
              )}
              <div className={styles.checkBoxField}>
                <CheckBox
                  id={`clientMobileIsThirdParty`}
                  value={clientField.isMobileNumberThirdParty || false}
                  label={'Mobile phone belongs to third party e.g. friend or family'}
                  onChange={(e) => handleChangeFieldValue('isMobileNumberThirdParty', e.target.checked)}
                />
              </div>
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <MaterialSelect
              id={'clientCommunicationPreference'}
              label={''}
              isSearchable={false}
              options={COMMUNICATION_OPTIONS}
              value={clientDetail.communicationPreference || CommunicationPreference.NoPreference}
              onChange={onCommunicationPreferenceChange}
            />
            {clientDetail.communicationPreference === 'none' && (
              <div className={styles.noneComms}>{t('form.error.client_no_digital_message')}</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ChildOrYoungClientForm;
