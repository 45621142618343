import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { MedicareItem, MedicareItemFormat, MedicareItemMode, MedicareItemDuration } from '../interfaces';

export const PRACTICE_NURSE_ITEMS: MedicareItem[] = [
  {
    mbsCode: '10983',
    description:
      'Attendance by a practice nurse, on behalf of and under the supervision of a medical practitioner, to provide clinical support to a patient.',
    benefit: 36.95,
    role: ClinicianMedicareRole.PracticeNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '10987',
    description:
      'Follow up service provided by a practice nurse, on behalf of a medical practitioner, for an Indigenous person who has received a health assessment.',
    benefit: 27.3,
    role: ClinicianMedicareRole.PracticeNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '10997',
    description:
      'Service provided by a practice nurse to a person with a chronic disease who has a GP Management Plan, Team Care Arrangements or Multidisciplinary Care Plan, on behalf of and under the supervision of a medical practitioner',
    benefit: 13.65,
    role: ClinicianMedicareRole.PracticeNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '16400',
    description:
      'Antenatal service provided by a practice nurse, on behalf of and under the supervision of a medical practitioner',
    benefit: 26.4,
    role: ClinicianMedicareRole.PracticeNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '91850',
    description:
      'Antenatal telehealth service provided by a practice nurse, on behalf of and under the supervision of a medical practitioner',
    benefit: 26.4,
    role: ClinicianMedicareRole.PracticeNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '91855',
    description:
      'Antenatal phone service provided by a practice nurse, on behalf of and under the supervision of a medical practitioner',
    benefit: 26.4,
    role: ClinicianMedicareRole.PracticeNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '93200',
    description:
      'Follow-up telehealth attendance provided by a practice nurse for an Indigenous person who has received a health check, on behalf of and under the supervision of a medical practitioner',
    benefit: 27.3,
    role: ClinicianMedicareRole.PracticeNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '93201',
    description:
      'Telehealth service provided by a practice nurse to a person with a chronic disease who has a GP Management Plan, Team Care Arrangements or Multidisciplinary Care Plan, on behalf of and under the supervision of a medical practitioner',
    benefit: 13.75,
    role: ClinicianMedicareRole.PracticeNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '93202',
    description:
      'Follow-up phone attendance provided by a practice nurse for an Indigenous person who has received a health check, on behalf of and under the supervision of a medical practitioner',
    benefit: 27.3,
    role: ClinicianMedicareRole.PracticeNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: '93203',
    description:
      'Phone service provided by a practice nurse to a person with a chronic disease who has a GP Management Plan, Team Care Arrangements or Multidisciplinary Care Plan, on behalf of and under the supervision of a medical practitioner',
    benefit: 13.75,
    role: ClinicianMedicareRole.PracticeNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.Any
  }
];
