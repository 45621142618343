import { ReferralTab } from 'pages/Referrals/interface';

export const getReferralListColumns = ({
  isPackageEnabled,
  activeTab
}: {
  isPackageEnabled: boolean;
  activeTab: ReferralTab;
}) => [
  {
    id: 'referral',
    sortAble: false,
    label: 'Referral ID'
  },
  {
    id: 'source',
    sortAble: false
  },
  {
    id: 'referrer',
    sortAble: false
  },
  {
    id: 'subject',
    sortAble: false
  },
  {
    id: 'profile',
    sortAble: false,
    label: 'Profile?'
  },
  {
    id: 'createdAt',
    sortAble: true,
    label: 'Received'
  },
  {
    id: 'date',
    sortAble: true,
    label: 'Dated'
  },
  {
    id: 'status',
    sortAble: [ReferralTab.All, ReferralTab.Unlinked].includes(activeTab)
  },
  ...(isPackageEnabled
    ? [
        {
          id: 'package',
          sortAble: false,
          label: 'Package?'
        }
      ]
    : [])
];
