import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useEffect, useRef, useState } from 'react';
import styles from './ActionsMenu.module.scss';
import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';

interface ActionsMenuProps {
  isSendEFaxDisabled: boolean;
  onClickSendViaEFax: () => void;
}

const ActionsMenu = ({ isSendEFaxDisabled, onClickSendViaEFax }: ActionsMenuProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const menuNode = useRef<HTMLDivElement>(null);

  const handleExpandMenu = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target)) {
      return;
    }
    setIsExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const onSendViaEFaxHandle = () => {
    setIsExpanded(false);
    onClickSendViaEFax();
  };

  return (
    <div
      ref={menuNode}
      className={styles.container}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
      }}
    >
      <ButtonAlt className={styles.button} variant={'outlined'} onClick={handleExpandMenu}>
        <i className={`material-icons ${styles.icon}`}>more_vert</i>
      </ButtonAlt>
      <div className={styles.menuWrapper}>
        <div className={isExpanded ? styles.menuShow : styles.menuHide}>
          {/* Send Via eFax */}
          <ThreeDotMenuItem
            disabled={isSendEFaxDisabled}
            labelClassName={styles.menuItemLabel}
            label={'Send Via eFax'}
            onClick={onSendViaEFaxHandle}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionsMenu;
