import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { FilterRadioItem } from 'components/T23/FilterRadioDropdown/FilterRadioDropdown';
import { InvoiceWithProjectedMbsCode } from 'interfaces/invoices/pendingClaimInvoices';
import { WriteOffForm } from 'redux/invoices/invoiceSlice';
import { RootState } from 'redux/store';
import { Paging, PER_PAGE_DEFAULT_10, Sorting, SortType } from '../utils';

export enum PendingClaimTabs {
  DataReady = 'dataReady',
  DataNotReady = 'dataNotReady',
  Abandoned = 'abandoned'
}

export interface PendingClaimFilters {
  clients: FilterCheckListItem[];
  practitioners: FilterCheckListItem[];
  groups: FilterCheckListItem[];
  funder?: FilterRadioItem;
  code?: FilterRadioItem;
  statuses: FilterCheckListItem[];
}
interface PendingClaimSliceState {
  activeTab: PendingClaimTabs;
  sorting: Sorting;
  dataReadyPaging: Paging;
  dataNotReadyPaging: Paging;
  abandonedPaging: Paging;
  filters: PendingClaimFilters;
  selectedClaimInvoiceId: string;
  createClaimModalVisible: boolean;
  batchOfClaims: InvoiceWithProjectedMbsCode[];
  isShowWriteOffModal: boolean;
  writeOffFormValue: WriteOffForm | undefined;
}

const initialState: PendingClaimSliceState = {
  activeTab: PendingClaimTabs.DataReady,
  sorting: {
    sortBy: 'date',
    sortType: SortType.DESC
  },
  dataReadyPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT_10
  },
  dataNotReadyPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT_10
  },
  abandonedPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT_10
  },
  filters: {
    clients: [],
    practitioners: [],
    groups: [],
    funder: undefined,
    code: undefined,
    statuses: []
  },
  selectedClaimInvoiceId: '',
  createClaimModalVisible: false,
  batchOfClaims: [],
  isShowWriteOffModal: false,
  writeOffFormValue: undefined
};

export const pendingClaimInvoiceSlice = createSlice({
  name: 'pendingClaimInvoiceSlice',
  initialState,
  reducers: {
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    setDataReadyPaging: (state, action) => {
      state.dataReadyPaging = action.payload;
    },

    setDataNotReadyPaging: (state, action) => {
      state.dataNotReadyPaging = action.payload;
    },

    setAbandonedPaging: (state, action) => {
      state.abandonedPaging = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetDataReadyPaging: (state) => {
      state.dataReadyPaging = initialState.dataReadyPaging;
    },

    resetDataNotReadyPaging: (state) => {
      state.dataNotReadyPaging = initialState.dataNotReadyPaging;
    },

    resetAbandonedPaging: (state) => {
      state.abandonedPaging = initialState.abandonedPaging;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    setSelectedClaimInvoiceId: (state, action) => {
      state.selectedClaimInvoiceId = action.payload;
    },

    setCreateClaimModalVisible: (state, action) => {
      state.createClaimModalVisible = action.payload;
    },

    setBatchOfClaims: (state, action: PayloadAction<InvoiceWithProjectedMbsCode[]>) => {
      state.batchOfClaims = action.payload;
    },

    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },

    setIsShowWriteOffModal: (state, action) => {
      state.isShowWriteOffModal = action.payload;
    },

    setWriteOffFormValue: (state, action) => {
      state.writeOffFormValue = {
        ...state.writeOffFormValue,
        ...action.payload
      };
    }
  }
});

export const selectSorting = (state: RootState) => state.pendingClaimInvoiceSlice.sorting;
export const selectDataReadyPaging = (state: RootState) => state.pendingClaimInvoiceSlice.dataReadyPaging;
export const selectDataNotReadyPaging = (state: RootState) => state.pendingClaimInvoiceSlice.dataNotReadyPaging;
export const selectAbandonedPaging = (state: RootState) => state.pendingClaimInvoiceSlice.abandonedPaging;
export const selectFilters = (state: RootState) => state.pendingClaimInvoiceSlice.filters;
export const selectSelectedClaimInvoiceId = (state: RootState) => state.pendingClaimInvoiceSlice.selectedClaimInvoiceId;
export const selectCreateClaimModalVisible = (state: RootState) =>
  state.pendingClaimInvoiceSlice.createClaimModalVisible;
export const selectBatchOfClaims = (state: RootState) => state.pendingClaimInvoiceSlice.batchOfClaims;
export const selectActiveTab = (state: RootState) => state.pendingClaimInvoiceSlice.activeTab;
export const selectIsShowWriteOffModal = (state: RootState) => state.pendingClaimInvoiceSlice.isShowWriteOffModal;
export const selectWriteOffFormValue = (state: RootState) => state.pendingClaimInvoiceSlice.writeOffFormValue;

export const {
  setSorting,
  setDataReadyPaging,
  setDataNotReadyPaging,
  setAbandonedPaging,
  setFilters,
  resetDataReadyPaging,
  resetDataNotReadyPaging,
  resetAbandonedPaging,
  resetFilters,
  setSelectedClaimInvoiceId,
  setCreateClaimModalVisible,
  setBatchOfClaims,
  setActiveTab,
  setIsShowWriteOffModal,
  setWriteOffFormValue
} = pendingClaimInvoiceSlice.actions;

export default pendingClaimInvoiceSlice.reducer;
