import moment from 'moment';

import { InvoiceViewProps } from '../../InvoiceView';

import styles from './InvoiceViewBasicDetails.module.scss';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { combineName } from 'utils/general';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { useTranslation } from 'react-i18next';

interface InvoiceViewBasicDetailsProps {
  clientRecord?: InvoiceViewProps['clientRecord'];
  clinician: InvoiceViewProps['clinician'];
  dueDate: InvoiceViewProps['dueDate'];
  issueDate: InvoiceViewProps['issueDate'];
  invoiceId: InvoiceViewProps['invoiceId'];
  practice: InvoiceViewProps['practice'];
  selectedGroup?: InvoiceViewProps['selectedGroup'];
  serviceDeliveredDates: string[];
}

const InvoiceViewBasicDetails = ({
  clientRecord,
  clinician,
  dueDate,
  invoiceId,
  issueDate,
  practice,
  selectedGroup,
  serviceDeliveredDates
}: InvoiceViewBasicDetailsProps) => {
  const { isEdgeUserView, isNormalUserView } = useGetAccountPackageView();
  const [t] = useTranslation();

  const clientName =
    clientRecord &&
    combineName(
      ['child', 'youngPerson'].includes(clientRecord.recordType)
        ? clientRecord.clientProfiles.slice(0, 1)
        : clientRecord.clientProfiles,
      clientRecord.recordType === 'couple'
    );
  const selectedRecipientName = selectedGroup ? selectedGroup?.name : clientName;

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        {practice && (
          <div className={styles.practice}>
            {practice.logo && <img className={styles.image} src={practice.logo} alt="Practice logo" />}
            <div className={styles.practiceDetails}>
              <span className={styles.title}>{practice.name}</span>
              <span className={styles.description}>{practice.address}</span>
              <span className={styles.description}>{practice.mobileNumber}</span>
            </div>
          </div>
        )}
        {(isEdgeUserView || isNormalUserView) && clinician && (
          <div className={styles.clinician}>
            {clinician.avatar && <img className={styles.image} src={clinician.avatar} alt="Clinician avatar" />}
            <div className={styles.clinicianDetails}>
              <span className={styles.title}>{clinician.name}</span>
              <span className={styles.description}>{clinician.mobileNumber}</span>
              <span className={styles.description}>{clinician.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.bottomRow}>
        <div className={styles.childRow}>
          <div className={styles.item}>
            <span className={styles.label}>{selectedGroup ? t('form.group_name') : t('form.client_name')}:</span>
            <span className={styles.description}>{selectedRecipientName || '-'}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.label}>Invoice ID:</span>
            <span className={styles.description}>{invoiceId || '-'}</span>
          </div>
        </div>
        <div className={styles.childRow}>
          {serviceDeliveredDates.length > 0 && (
            <div className={styles.item}>
              <span className={styles.label}>Service delivered date:</span>
              <div className={styles.description}>
                {serviceDeliveredDates.map((date) => moment(date, 'DD-MM-YYYY').format('DD/MM/YYYY')).join(', ')}
              </div>
            </div>
          )}
          <div className={styles.item}>
            <span className={styles.label}>Date of issue:</span>
            <span className={styles.description}>{moment(issueDate, 'YYYY-MM-DD').format(MOMENTJS_DATE_FORMAT)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.label}>Due date:</span>
            <span className={styles.description}>
              {dueDate ? moment(dueDate, 'YYYY-MM-DD').format(MOMENTJS_DATE_FORMAT) : 'N/A'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceViewBasicDetails;
