import { useGetCarePathwayListQuery } from 'redux/endpoints/clinicianProfileServices/carePathway';
import { CarePathwayCard } from '../CarePathwayCard/CarePathwayCard';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { Card, Skeleton } from 'antd';
import styles from './CarePathwayList.module.scss';
import { CarePathwayListFilterType } from '../../types';
import clientRecordEmpty from 'assets/images/clientRecordEmpty2.svg';
import PaginationListV2 from 'components/v2/PaginationListV2/PaginationListV2';

interface CarePathwayListProps {
  filter: CarePathwayListFilterType;
  setCurrentPage: (page: number) => void;
  currentPage: number;
}

export const CarePathwayList = ({ filter, currentPage, setCurrentPage }: CarePathwayListProps) => {
  const { accountId } = useGetAccountId();
  const { data, isLoading, isFetching } = useGetCarePathwayListQuery({
    accountId,
    query: {
      status: filter.status.map((item) => item._id),
      ...(filter.staff.length > 0 && {
        leadClinicianIds: filter.staff.map((item) => item._id)
      }),
      ...(filter.client.length > 0 && {
        clientRecordIds: filter.client.map((item) => item._id)
      }),
      page: currentPage,
      perPage: 10
    }
  });
  const { carePathways = [], paging } = data ?? {};

  if (isLoading || isFetching) {
    return (
      <div>
        <Card>
          <Skeleton />
        </Card>
      </div>
    );
  }

  if (!carePathways.length) {
    return (
      <div>
        <Card className={styles.empty}>
          <div className={styles.emptyContent}>
            <img src={clientRecordEmpty} alt={'No care pathway found'} />
            <p className={styles.emptyTitle}>No care pathway found</p>
            <p>Please refine your filters and try again.</p>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {carePathways.map((carePathway) => (
        <CarePathwayCard key={carePathway._id} carePathway={carePathway} />
      ))}
      {paging && <PaginationListV2 paging={paging} onPageChange={setCurrentPage} />}
    </div>
  );
};
