import { useState } from 'react';
import { DatePicker, Skeleton } from 'antd';
import moment, { Moment } from 'moment';

import './BasicDetails.scss';
import styles from './BasicDetails.module.scss';
import BasicDetailHeaderEdit from './components/BasicDetailHeaderEdit/BasicDetailHeaderEdit';
import { InvoiceContactDetails, InvoiceSettings } from '../../../../../Invoices/interface';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import classnames from 'classnames';
import { ProfileInterface } from 'interfaces/Profile/Profile';

const DEFAULT_CONTACT_DETAILS: InvoiceContactDetails = {
  contactDetails: {
    practice: {
      address: {
        value: '',
        isVisible: false
      },
      mobileNumber: {
        value: '',
        isVisible: false
      }
    },
    clinician: {
      mobileNumber: {
        value: '',
        isVisible: false
      },
      email: {
        value: '',
        isVisible: false
      }
    }
  }
};

const getClinicianDetails = (clinician?: ProfileInterface) => {
  if (clinician) {
    const { avatar, name, practice, title, email, mobileNumber } = clinician;

    const firstPractice = practice ? practice : undefined;

    return {
      clinician: {
        avatar,
        email,
        mobileNumber,
        name: `${title || ''}${name ? ` ${name}` : ''}`
      },
      practice: firstPractice
        ? {
            address: firstPractice?.locations?.[0],
            logo: firstPractice?.logo,
            mobileNumber: firstPractice?.mobileNumber,
            name: firstPractice?.name
          }
        : undefined
    };
  } else {
    return {};
  }
};

interface BasicDetailsProps {
  clinician?: ProfileInterface;
  invoiceId: string;
  selectedClientName?: string;
  selectedGroup?: GroupsFromAPI;
  selectedDueDate: string;
  serviceDeliveredDates: string[];
  onSelectDueDate: (newDueDate: string) => void;
  invoiceSettings?: InvoiceSettings;
  isInvoiceLoading: boolean;
}

const BasicDetails = ({
  clinician,
  invoiceId,
  selectedClientName,
  selectedGroup,
  selectedDueDate,
  serviceDeliveredDates,
  onSelectDueDate,
  invoiceSettings,
  isInvoiceLoading
}: BasicDetailsProps) => {
  const { isEdgeUserView, isNormalUserView } = useGetAccountPackageView();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const [isEditHeadingOpen, setIsEditHeadingOpen] = useState(false);

  const clinicianContactData = { contactDetails: invoiceSettings?.contactDetails };

  const clinicianDetails = getClinicianDetails(clinician);

  const [t] = useTranslation();

  const formattedDueDate = moment(selectedDueDate, 'YYYY-MM-DD').format(MOMENTJS_DATE_FORMAT);
  const differenceInDays = moment(selectedDueDate, 'YYYY-MM-DD').diff(moment().startOf('day'), 'day');

  const handleDueDateClick = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleSelectDueDate = (value: Moment | null, dateString?: string) => {
    if (dateString) {
      onSelectDueDate(dateString);
      setIsDatePickerOpen(false);
    }
  };

  const practiceContactDetail = clinicianContactData?.contactDetails
    ? clinicianContactData.contactDetails
    : DEFAULT_CONTACT_DETAILS.contactDetails;

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        {isInvoiceLoading ? (
          <Skeleton active />
        ) : (
          <>
            <div className={styles.practice}>
              {clinicianDetails?.practice && (
                <>
                  {clinicianDetails.practice.logo && (
                    <img className={styles.image} src={clinicianDetails.practice.logo} alt="Practice logo" />
                  )}
                  <div className={styles.detailsWrapper} onClick={() => setIsEditHeadingOpen(true)}>
                    <span className={styles.detailsTitle}>{clinicianDetails.practice.name}</span>
                    <div className={styles.detailInfoWrapper}>
                      <div className={styles.editBadge}>
                        <div className={styles.editIconWrapper}>
                          <i className={`material-icons ${styles.icon}`}>mode</i>
                        </div>
                      </div>
                      {practiceContactDetail.practice.address.isVisible && (
                        <div className={styles.detailInfo}>{practiceContactDetail.practice.address.value}</div>
                      )}
                      {practiceContactDetail.practice.mobileNumber.isVisible && (
                        <div className={styles.detailInfo}>{practiceContactDetail.practice.mobileNumber.value}</div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {clinicianDetails?.clinician && (isEdgeUserView || isNormalUserView) && (
              <div className={styles.clinician}>
                {clinicianDetails.clinician.avatar && (
                  <img className={styles.image} src={clinicianDetails.clinician.avatar} alt="Clinician avatar" />
                )}
                <div className={styles.detailsWrapper} onClick={() => setIsEditHeadingOpen(true)}>
                  <span className={styles.detailsTitle}>{clinicianDetails.clinician.name}</span>
                  <div className={styles.detailInfoWrapper}>
                    <div className={styles.editBadge}>
                      <div className={styles.editIconWrapper}>
                        <i className={`material-icons ${styles.icon}`}>mode</i>
                      </div>
                    </div>
                    {practiceContactDetail.clinician.mobileNumber.isVisible && (
                      <div className={styles.detailInfo}>{practiceContactDetail.clinician.mobileNumber.value}</div>
                    )}
                    {practiceContactDetail.clinician.email.isVisible && (
                      <div className={styles.detailInfo}>{practiceContactDetail.clinician.email.value}</div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {clinicianDetails.clinician && (
        <BasicDetailHeaderEdit
          showClinicianHeader={isEdgeUserView || isNormalUserView}
          visible={isEditHeadingOpen}
          onCloseModal={() => setIsEditHeadingOpen(false)}
          clinicianDetails={clinicianDetails}
          clinicianContactDetails={clinicianContactData}
        />
      )}
      <div className={styles.bottomRow}>
        <div className={styles.childRow}>
          <div className={styles.item}>
            {selectedGroup ? (
              <>
                <span className={styles.label}>Group Name:</span>
                <span className={styles.description}>{selectedGroup.name || '-'}</span>
              </>
            ) : (
              <>
                <span className={styles.label}>{t('form.client_name')}:</span>
                <span className={styles.description}>{selectedClientName || '-'}</span>
              </>
            )}
          </div>
          <div className={styles.item}>
            <span className={styles.label}>Invoice ID:</span>
            <span className={styles.description}>{invoiceId || '-'}</span>
          </div>
        </div>
        <div className={styles.childRow}>
          {serviceDeliveredDates.length > 0 && (
            <div className={styles.item}>
              <span className={styles.label}>Service delivered date:</span>
              <div className={styles.description}>
                {serviceDeliveredDates.map((date) => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')).join(', ')}
              </div>
            </div>
          )}
          <div className={styles.item}>
            <span className={styles.label}>Date of issue:</span>
            <span className={styles.description}>{moment().format(MOMENTJS_DATE_FORMAT)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.label}>Due date:</span>
            <span className={styles.description}>
              {moment(selectedDueDate, 'YYYY-MM-DD').isValid() ? (
                <div className={styles.buttonWrapper}>
                  <ButtonAlt
                    variant={'text'}
                    size={'small'}
                    className={styles.button}
                    type="button"
                    onClick={handleDueDateClick}
                  >
                    <i className={`material-icons ${styles.icon}`}>calendar_today</i>({formattedDueDate})
                  </ButtonAlt>{' '}
                  (
                  {differenceInDays === 0 ? (
                    <ButtonAlt
                      variant={'text'}
                      size={'small'}
                      className={classnames(styles.button, styles.space)}
                      type="button"
                      onClick={handleDueDateClick}
                    >
                      {' '}
                      TODAY
                    </ButtonAlt>
                  ) : differenceInDays === 1 ? (
                    <ButtonAlt
                      variant={'text'}
                      size={'small'}
                      className={classnames(styles.button, styles.space)}
                      type="button"
                      onClick={handleDueDateClick}
                    >
                      TOMORROW
                    </ButtonAlt>
                  ) : (
                    <>
                      IN{' '}
                      <ButtonAlt
                        variant={'text'}
                        size={'small'}
                        className={classnames(styles.button, styles.space)}
                        type="button"
                        onClick={handleDueDateClick}
                      >
                        {differenceInDays} DAYS
                      </ButtonAlt>
                    </>
                  )}
                  )
                </div>
              ) : (
                <ButtonAlt
                  variant={'text'}
                  size={'small'}
                  className={styles.button}
                  type="button"
                  onClick={handleDueDateClick}
                  icon={'calendar_today'}
                >
                  Select date
                </ButtonAlt>
              )}
            </span>
            <DatePicker
              className="due-date-picker"
              disabledDate={(current) => current && current < moment().startOf('day')}
              open={isDatePickerOpen}
              showToday={false}
              onChange={handleSelectDueDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
