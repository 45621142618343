import moment from 'moment';

import { convertStringToTitleCase } from 'utils/general';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';

import { ProfileInterface } from 'interfaces/Profile/Profile';
import { PatientActivity } from '../../../../ActivityFeed';

import styles from './ActivityItem.module.scss';

interface ActivityItemProps {
  item: PatientActivity;
  patientName?: string;
  profile: ProfileInterface;
}

const getIcon = (object: PatientActivity['object']) => {
  switch (object) {
    case 'note':
      return 'speaker_notes';

    case 'case note':
      return 'speaker_notes';

    case 'activity':
      return 'pending_actions';

    case 'appointment':
    case 'appointment delivery mode':
    case 'appointment change request':
      return 'event';

    case 'assessment':
    case 'check-in':
    case 'survey':
    case 'onboarding':
      return 'fact_check';

    case 'patient':
    case 'profile':
    case 'user':
      return 'account_circle';

    case 'invoice':
    case 'invoice setting':
    case 'claim':
      return 'receipt';

    case 'report':
      return 'assignment';

    case 'client profile':
    case 'mentor':
    case 'clinician':
      return 'person';

    case 'communication preference':
      return 'settings';

    case 'group':
      return 'groups';

    case 'consent form':
      return 'feed';

    case 'broadcast message':
      return 'podcasts';

    case 'smp':
      return 'description';

    case 'payment request':
    case 'payment record':
    case 'refund':
      return 'payments';

    case 'payment card':
      return 'credit_card';

    default:
      console.warn('Failed to get icon for patient activity');
      return '';
  }
};

const getTitle = (object: PatientActivity['object'], patientName?: string) => {
  if (object === 'note') {
    return 'Profile Note';
  } else if (object === 'communication preference' && patientName) {
    return `${convertStringToTitleCase(object)} of ${patientName}`;
  } else if (object === 'patient') {
    return 'Client';
  } else {
    return convertStringToTitleCase(object);
  }
};

const getPreposition = ({ object, action }: PatientActivity) =>
  object === 'appointment' && action === 'booked' ? 'with' : 'by';

const ActivityItem = ({ item, patientName, profile }: ActivityItemProps) => {
  const icon = getIcon(item.object);
  const { isEdgeAdminView, isNormalUserView } = useGetAccountPackageView();
  const { auth0ClinicianId } = useGetClinicianId();
  const practiceName = profile.practice?.name;

  return (
    <>
      <div>{icon && <span className={`${styles.activityIcon} material-icons-outlined`}>{icon}</span>}</div>
      <div>
        <div className={styles.activityTitle}>
          <span>{getTitle(item.object, patientName)} </span>
          <b>{item.action.toUpperCase()}</b>
          {` ${getPreposition(item)} ${
            item.actor === 'clinician'
              ? isEdgeAdminView
                ? item.clinicianId
                  ? item.clinicianName
                  : practiceName
                : isNormalUserView
                ? 'You'
                : item.clinicianId === auth0ClinicianId
                ? 'You'
                : item.clinicianId
                ? item.clinicianName
                : practiceName
              : item.actor === 'patient'
              ? patientName
              : 'Tacklit'
          }`}
        </div>
        <div className={styles.date}>
          {moment(item.createdAt).calendar(null, { sameElse: 'DD/MM/YYYY' })} -{' '}
          <span dangerouslySetInnerHTML={{ __html: item.description }} />
        </div>
        {item.body && (
          <div className={`ql-snow`}>
            <div className={`ql-editor ${styles.activityContent}`} dangerouslySetInnerHTML={{ __html: item.body }} />
          </div>
        )}
      </div>
    </>
  );
};

export default ActivityItem;
