import RedirectTo404 from 'components/RedirectTo404/RedirectTo404';
import AddressBookList from 'pages/AddressBook/AddressBookList/AddressBookList';
import Analytics from 'pages/Analytics/Analytics';
import CaseNoteTemplate from 'pages/AssessmentDetails/CaseNoteTemplate/CaseNoteTemplate';
import OnBoardingDetails from 'pages/AssessmentDetails/OnBoardingDetails/OnBoardingDetails';
import Assessments from 'pages/Assessments/Assessments';
import CalendarWithHighlightsT23 from 'pages/Calendar/components/CalendarWithHighlightsT23/CalendarWithHighlightsT23';
import { CarePathwayTemplate } from 'pages/CarePathwayTemplate/CarePathwayTemplate';
import ClientPage from 'pages/Client/Client';
import ControlPanel from 'pages/ControlPanel/ControlPanel/ControlPanel';
import IntegrationDetails from 'pages/ControlPanel/IntegrationDetails/IntegrationDetails';
import EFaxDetails from 'pages/ControlPanel/IntegrationDetails/components/EFaxDetails/EFaxDetails';
import Faxes from 'pages/Fax/Faxes/Faxes';
import NewFax from 'pages/Fax/NewFax/NewFax';
import { CarePathwayList } from 'pages/Groups/CarePathwayList/CarePathwayList';
import GroupDetailRoutes from 'pages/Groups/GroupDetails/GroupDetailsRoutes';
import InvoiceGenerator from 'pages/InvoiceGenerator/InvoiceGenerator';
import InvoicePayments from 'pages/InvoicePayments/InvoicePayments';
import InvoicePendingClaims from 'pages/InvoicePendingClaims/InvoicePendingClaims';
import InvoiceSubmittedClaims from 'pages/InvoiceSubmittedClaims/InvoiceSubmittedClaims';
import InvoiceTemplate from 'pages/InvoiceTemplate/InvoiceTemplate';
import Invoices from 'pages/Invoices/Invoices';
import InvoiceDetail from 'pages/Invoices/components/InvoiceDetail/InvoiceDetail';
import InvoicesList from 'pages/InvoicesV2/Invoices';
import Messages from 'pages/Messages/Messages';
import PatientDetails from 'pages/PatientDetails/PatientDetails';
import PractitionerControlPanel from 'pages/PractitionerControlPanel/PractitionerControlPanel';
import PractitionersListing from 'pages/PractitionersListing/PractitionersListing';
import Referrals from 'pages/Referrals/Referrals';
import LetterDetails from 'pages/Report/LetterDetails/LetterDetails';
import LetterTemplateEditor from 'pages/Report/LetterTemplateEditor/LetterTemplateEditor';
import LettersList from 'pages/Report/LettersList/LettersList';
import ReportDetails from 'pages/Report/ReportDetails/ReportDetails';
import ReportTemplateEditor from 'pages/Report/ReportTemplateEditor/ReportTemplateEditor';
import ReportsList from 'pages/Report/ReportsList/ReportsList';
import Tasks from 'pages/Tasks/Tasks';
import { Route, Routes } from 'react-router-dom';
import { InvoicePaymentsTab } from 'redux/features/invoicePayments/invoicePaymentsSlice';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import CalendarSettings from '../pages/Calendar/components/CalendarSettings/CalendarSettings';
import ConsentForm from '../pages/ConsentForm/ConsentForm';
import SessionDetails from '../pages/Groups/GroupDetails/components/GroupContent/components/Sessions/SessionDetails/SessionDetails';
import GroupsList from '../pages/Groups/GroupsList/GroupsList';
import HelmContent from '../pages/HelmContent/HelmContent';
import InvoiceWriteOff from 'pages/InvoiceWriteOff/InvoiceWriteOff';

const EdgeAdminRoutes = () => {
  const {
    isGroupsFeatureToggle,
    isAddressBookFeatureToggle,
    helmContentFeatureToggle,
    medicareRebateFeatureToggle,
    isInvoiceListV2Enabled,
    ableToAccessTaskList,
    isCarePlanEnabled,
    isReferralV2ListEnabled,
    isPaymentListEnabled
  } = useGetFeatureToggle();
  const { isInvoiceReadDenied } = useGetPermissionToggle();

  return (
    <Routes>
      {/* Patients - Clients */}
      <Route path={'client'} element={<ClientPage />} />
      <Route path={'client/:tabId'} element={<ClientPage />} />
      <Route path={'client/:recordId/:patientTab'} element={<PatientDetails />} />
      <Route path={'client/:recordId/:patientTab/:assessmentOrReportId'} element={<PatientDetails />} />
      {/* Groups */}
      {isGroupsFeatureToggle && (
        <>
          <Route path={'groups'} element={<GroupsList />} />
          <Route path={'groups/:tabId'} element={<GroupsList />} />
          <Route path={'groups/:groupId/*'} element={<GroupDetailRoutes />} />
          <Route path={'groups/:groupId/sessionDetail/:sessionId'} element={<SessionDetails />} />
        </>
      )}
      {isCarePlanEnabled && (
        <>
          <Route path={'care-pathways/:status'} element={<CarePathwayList />} />
        </>
      )}
      <Route path={'forms'} element={<Assessments />} />
      <Route path={'forms/survey/:assessmentId'} element={<OnBoardingDetails />} />
      <Route path={'forms/consent/:consentId'} element={<ConsentForm />} />
      <Route path={'forms/caseNotes/:caseNoteTemplateId'} element={<CaseNoteTemplate />} />
      <Route path={'forms/letters/:letterTemplateId'} element={<LetterTemplateEditor />} />
      <Route path={'forms/reports/:templateId'} element={<ReportTemplateEditor />} />
      {!isInvoiceReadDenied && (
        <>
          <Route path={'forms/invoices/new'} element={<InvoiceTemplate />} />
          <Route path={'forms/invoices/:invoiceTemplateId'} element={<InvoiceTemplate />} />
        </>
      )}
      {/* Calendar */}
      <Route path={'calendar'} element={<CalendarWithHighlightsT23 />} />
      <Route path={'calendar/settings/:tab'} element={<CalendarSettings />} />
      {/* Care Plan */}
      <Route path={'careplan'} element={<CalendarWithHighlightsT23 />} />
      {isCarePlanEnabled && (
        <>
          <Route path={'forms/carepathway/:carePathwayTemplateId'} element={<CarePathwayTemplate />} />
          <Route path={'forms/carepathway/new'} element={<CarePathwayTemplate />} />
        </>
      )}
      {/* Control panel */}
      <Route path={'control-panel/*'} element={<ControlPanel />}>
        <Route path={':controlPanelTab'} element={<ControlPanel />} />
      </Route>
      <Route path={'control-panel/system-connections/eFax'} element={<EFaxDetails />} />
      <Route path={'control-panel/system-connections/:integrationId'} element={<IntegrationDetails />} />
      {/* Reports */}
      <Route path={'reports'} element={<ReportsList />} />
      <Route path={'reports/:reportId'} element={<ReportDetails />} />
      {/* Letter */}
      <Route path={'letters'} element={<LettersList />} />
      <Route path={'letters/:letterId'} element={<LetterDetails />} />
      {/* Practitioners */}
      <Route path={'practitioners'} element={<PractitionersListing />} />
      <Route path={'practitioners/:practitionerId/*'} element={<PractitionerControlPanel />}>
        <Route path={':controlPanelTab'} element={<ControlPanel />} />
      </Route>
      {/* Messages */}
      <Route path={'messages'} element={<Messages />} />
      <Route path={'messages/send-fax'} element={<NewFax />} />
      <Route path={'messages/faxes'} element={<Faxes />} />
      <Route path={'messages/:messageTabId'} element={<Messages />} />
      {/* Analytics */}
      <Route path={'analytics'} element={<Analytics />} />
      {/* HelmContent */}
      {helmContentFeatureToggle && <Route path={'helm-content'} element={<HelmContent />} />}
      {/* Invoices */}
      {!isInvoiceReadDenied && (
        <>
          <Route path={'invoices/new'} element={<InvoiceGenerator />} />
          {isInvoiceListV2Enabled ? (
            <>
              <Route path={'invoices'} element={<InvoicesList />} />
              <Route path={'invoices/:tabId/tab'} element={<InvoicesList />} />
              <Route path={'invoices/write-offs'} element={<InvoiceWriteOff />} />
            </>
          ) : (
            <Route path={'invoices'} element={<Invoices />} />
          )}

          <Route path={'invoices/invoice-settings'} element={<Invoices />} />
          <Route path={'invoices/:invoiceId'} element={<InvoiceDetail />} />
          <Route path={'invoices/:invoiceId/edit'} element={<InvoiceGenerator />} />
          {medicareRebateFeatureToggle && (
            <>
              <Route path={'invoices/pending-claims-data-ready'} element={<InvoicePendingClaims />} />
              <Route path={'invoices/pending-claims-data-not-ready'} element={<InvoicePendingClaims />} />
              <Route path={'invoices/pending-claims-abandoned'} element={<InvoicePendingClaims />} />
              <Route path={'invoices/submitted-claims'} element={<InvoiceSubmittedClaims />} />
              <Route path={'invoices/success-claims'} element={<InvoiceSubmittedClaims />} />
              <Route path={'invoices/rejected-claims'} element={<InvoiceSubmittedClaims />} />
              <Route path={'invoices/write-off-claims'} element={<InvoiceSubmittedClaims />} />
              <Route path={'invoices/abandoned-claims'} element={<InvoiceSubmittedClaims />} />
            </>
          )}

          {isPaymentListEnabled && (
            <>
              <Route
                path={'invoices/collected-payments'}
                element={<InvoicePayments tab={InvoicePaymentsTab.Collected} />}
              />
              <Route
                path={'invoices/scheduled-payments'}
                element={<InvoicePayments tab={InvoicePaymentsTab.Scheduled} />}
              />
              <Route path={'invoices/failed-payments'} element={<InvoicePayments tab={InvoicePaymentsTab.Failed} />} />
              <Route
                path={'invoices/cancelled-payments'}
                element={<InvoicePayments tab={InvoicePaymentsTab.Cancelled} />}
              />
              <Route
                path={'invoices/refunded-payments'}
                element={<InvoicePayments tab={InvoicePaymentsTab.Refunded} />}
              />
            </>
          )}
        </>
      )}
      {/* Address Book */}
      {isAddressBookFeatureToggle && (
        <>
          <Route path={'address-book'} element={<AddressBookList />} />
          <Route path={'address-book/:tabId'} element={<AddressBookList />} />
        </>
      )}
      {/* Tasks */}
      {ableToAccessTaskList && <Route path={'tasks'} element={<Tasks />} />}
      {/* Referrals */}
      {isReferralV2ListEnabled && (
        <>
          <Route path={'referrals'} element={<Referrals />} />
          <Route path={'referrals/:tabId'} element={<Referrals />} />
        </>
      )}
      {/* 404 */}
      <Route path={'*'} element={<RedirectTo404 />} />
    </Routes>
  );
};

export default EdgeAdminRoutes;
