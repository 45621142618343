import styles from './PackageAppointment.module.scss';
import { FunderLimitType, PackagesBenefitLimit, PackagesInterface, PackagesStatus } from 'interfaces/Packages/packages';
import PackageIcon from '../../../PackageIcon/PackageIcon';
import { ChangeEvent, useCallback, useState } from 'react';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { PackageAppointmentList } from './components/PackageAppointmentList/PackageAppointmentList';
import { PackageBenefitControl } from '../PackageBenefitControl/PackageBenefitControl';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import CheckBox from 'components/CheckBox/CheckBox';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import { convertCurrencyValue, sanitizedCurrencyValue } from 'utils/currencyFormatConverter';
import { config } from 'config/config';

interface PackageAppointmentProps {
  packageData: PackagesInterface;
  onChangePackageData: (value: PackagesInterface) => void;
  onSubmit: (status: PackagesStatus) => void;
  submitStatus: { btnType: PackagesStatus | undefined; status: ButtonStatusType };
}

export const PackageAppointment = ({
  packageData,
  onChangePackageData,
  onSubmit,
  submitStatus
}: PackageAppointmentProps) => {
  const { currencySymbol } = config;
  const [checkValidation, setCheckValidation] = useState<boolean>(false);

  const handleAddNewAppointment = () => {
    const newData = {
      ...packageData,
      appointmentTypeIds: [...packageData.appointmentTypeIds, '']
    };
    onChangePackageData(newData);
  };

  const onHandleSubmit = useCallback(
    (status: PackagesStatus) => {
      setCheckValidation(true);
      const checkBudgetValueIsEmpty =
        packageData.benefitControl.limitMode !== PackagesBenefitLimit.None
          ? packageData.benefitControl.funderLimitType === FunderLimitType.PerFunder
            ? packageData.benefitControl.perFunderLimit?.some(
                (limitObj) => limitObj.limit === '0' || limitObj.limit === 0
              )
            : packageData.benefitControl.limit === '' ||
              packageData.benefitControl.limit === '0' ||
              packageData.benefitControl.limit === 0
          : false;

      const checkPackageFeeIsEmpty =
        (packageData.benefitControl.limitMode === PackagesBenefitLimit.DollarSpent || packageData.feeChecked) &&
        (!packageData.fee || ['', '0'].includes(packageData.fee?.toString()));

      const checkFieldHaveError =
        packageData.appointmentTypeIds.length <= 0 ||
        packageData.appointmentTypeIds.some((obj) => obj === '') ||
        checkBudgetValueIsEmpty ||
        checkPackageFeeIsEmpty;

      if (!checkFieldHaveError) {
        onSubmit(status);
      }
    },
    [packageData, onSubmit]
  );

  const handlePackageFeeChecked = (value: boolean) => {
    onChangePackageData({
      ...packageData,
      feeChecked: value
    });
  };

  const handlePackageFee = (e: ChangeEvent<HTMLInputElement>) => {
    onChangePackageData({
      ...packageData,
      fee: sanitizedCurrencyValue(e.target.value)
    });
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.firstSection}>
          <PackageIcon settingsType={packageData.serviceAccessOrder} />
        </div>
        <div className={styles.secondSection}>
          <CheckBox
            id={`packageFee`}
            label={'Package Fee?'}
            className={styles.checkBox}
            value={
              packageData.benefitControl.limitMode === PackagesBenefitLimit.DollarSpent
                ? true
                : packageData.feeChecked || false
            }
            onChange={(e) => handlePackageFeeChecked(e.target.checked)}
            disabled={packageData.benefitControl.limitMode === PackagesBenefitLimit.DollarSpent}
            useT23style
          />
          {packageData.feeChecked && (
            <TitleInput
              prefix={currencySymbol}
              inputContainer={styles.packageFeeInput}
              defaultLine
              inputProps={{
                maxLength: 18,
                placeholder: '',
                value: convertCurrencyValue(packageData.fee || ''),
                onChange: handlePackageFee
              }}
              error={
                checkValidation &&
                (packageData.benefitControl.limitMode === PackagesBenefitLimit.DollarSpent || packageData.feeChecked) &&
                (!packageData.fee || ['', '0'].includes(packageData.fee?.toString()))
                  ? packageData.benefitControl.limitMode === PackagesBenefitLimit.DollarSpent
                    ? 'Package fee is required when limit mode is dollar spent'
                    : 'Package fee is required'
                  : ''
              }
            />
          )}
        </div>
      </div>
      <div className={styles.appointmentTypeContainer}>
        <PackageAppointmentList
          packageData={packageData}
          onChangePackageData={onChangePackageData}
          checkValidation={checkValidation}
        />
        <div>
          <ButtonAlt
            variant={'outlined'}
            id={'addNewAppointment'}
            icon={'add_circle_outline'}
            onClick={handleAddNewAppointment}
          >
            Service
          </ButtonAlt>
          <div>
            <ErrorMessage
              error={
                checkValidation && packageData.appointmentTypeIds.length <= 0
                  ? 'Please add at least one service'
                  : undefined
              }
              visible
            />
          </div>
        </div>
        {packageData.appointmentTypeIds.length > 0 && (
          <div className={styles.appointmentListSummary}>
            {packageData.appointmentTypeIds.length} appointments added to {packageData.name}
          </div>
        )}
        <div className={styles.warningMessage}>
          <div className={`material-icons-outlined ${styles.infoIcon}`}>info</div>
          <div>
            Services are considered utilised for package purposes on point of booking. Should a service be cancelled it
            will return to allocation unless processed as a short notice cancellation. You can update utilisation on any
            clients specific profile.
          </div>
        </div>
      </div>
      {packageData.benefitControl?.limitMode !== PackagesBenefitLimit.None && (
        <PackageBenefitControl
          packageData={packageData}
          onChangePackageData={onChangePackageData}
          checkValidation={checkValidation}
        />
      )}
      <div className={styles.buttonContainer}>
        <ButtonAlt
          status={submitStatus.btnType === PackagesStatus.Draft ? submitStatus.status : ''}
          disabled={submitStatus.status !== ''}
          variant={'outlined'}
          onClick={() => onHandleSubmit(PackagesStatus.Draft)}
        >
          Save in Draft
        </ButtonAlt>
        <ButtonAlt
          status={submitStatus.btnType === PackagesStatus.Active ? submitStatus.status : ''}
          disabled={submitStatus.status !== ''}
          onClick={() => onHandleSubmit(PackagesStatus.Active)}
        >
          Save and Publish Package
        </ButtonAlt>
      </div>
    </>
  );
};
