export const sanitizedCurrencyValue = (value: string) => {
  return value
    .replace(/[^\d.]/g, '')
    .replace(/(\.\d{2})\d*/, '$1')
    .replace(/^\./, '0.')
    .replace(/^(\d+)$/, '$1')
    .replace(/\.(?=.*\.)/g, '');
};

export const convertCurrencyValue = (value: string) => {
  return value
    .replace(/^0+(\d)/, '$1')
    .replace(/[^\d.]/g, '')
    .replace(/(\.\d\d)\d*/, '$1')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertOnlyNumberValue = (value: string) => {
  return value
    .replace(/^0+(\d)/, '$1')
    .replace(/[^\d.]/g, '')
    .replace(/(\.\d\d)\d*/, '$1');
};
