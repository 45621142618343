import { Modal, Skeleton, notification } from 'antd';
import styles from './OnboardingTriageModal.module.scss';
import { clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useGetAccessToken } from 'utils/hooks/token';

// renamed the following imports to avoid confusion - original usage is for new client form
import { usePrefillNewClientForm as usePrefillClientForm } from 'components/AddPatientModalV2/hooks/PrefillValue';
import { newClientForm as clientForm } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import { useMemo, useState } from 'react';
import OnboardingTriageStep from 'components/AddPatientModalV2/components/OnboardingTriageStep/OnboardingTriageStep';
import { onboardTriageClient } from './hooks/PutOnboardTriageClient';

interface OnboardingTriageModalProps {
  visible: boolean;
  onClose: () => void;
  clientRecordData: clientRecordsInterface;
}

const OnboardingTriageModal = ({ visible, onClose, clientRecordData }: OnboardingTriageModalProps) => {
  const { token } = useGetAccessToken();

  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [formCompleted, setFormCompleted] = useState(false);
  const [updatedClientRecord, setUpdatedClientRecord] = useState<clientRecordsInterface>();

  const initOnboardingTriageForm = useMemo(
    () =>
      ({
        clinicianAuth0Ids: clientRecordData.clinicianAuth0Ids,
        recordType: clientRecordData.recordType,
        referralSourceTags: clientRecordData.referral?.sourceTags || [],
        referralDetail: clientRecordData.referral?.detail || '',
        caseId: clientRecordData.caseId,
        clientProfiles: clientRecordData.clientProfiles.map((clientProfile: clientProfilesInterface) => {
          return {
            _id: clientProfile._id,
            firstName: clientProfile.firstName,
            lastName: clientProfile.lastName,
            email: clientProfile.email,
            isEmailThirdParty: clientProfile.isEmailThirdParty,
            isMobileNumberThirdParty: clientProfile.isMobileNumberThirdParty,
            mobileNumber: clientProfile.mobileNumber,
            onboarding: {
              isCheckOnBoarding: false,
              onboardingSurveyIds: [],
              onboardingMethod: clientProfile.onboardingSettings?.onboardingMethod as 'sendToClient' | 'facilitated'
            },
            consentForm: {
              isCheckOnConsentForm: false,
              consentFormIds: []
            },
            assessmentForm: {
              isCheckOnAssessmentForm: false,
              assessmentFormIds: []
            },
            dateOfBirth: clientProfile.dateOfBirth,
            isPrimaryContact: clientProfile.isPrimaryContact,
            role: clientProfile.role,
            communicationPreference: clientProfile.communicationPreference
          };
        }),
        clientCapability: {
          profileType: clientRecordData.clientProfiles[0].profileType
        },
        signupInvitationSettings: {
          sendAt: 'now',
          customSendTime: ''
        },
        quickNote: {
          note: '',
          includeQuickNote: false
        },
        registeredAs: clientRecordData.recordStatus,
        address: clientRecordData.address
      } as clientForm),
    [clientRecordData]
  );

  const {
    clientFormData,
    setClientFormData,
    onBoardingList,
    consentList,
    prefillLoading,
    fetchPrefillClientData,
    assessmentList
  } = usePrefillClientForm(token, initOnboardingTriageForm);

  const handleSubmit = async () => {
    setSubmitStatus('active');

    try {
      const { clientProfiles, quickNote } = clientFormData;
      const result = await onboardTriageClient(token, clientRecordData._id, clientProfiles, quickNote);
      setUpdatedClientRecord(result as clientRecordsInterface);
      setTimeout(() => {
        setSubmitStatus('finished');
      }, 500);
      setTimeout(() => {
        setFormCompleted(true);
        notification.success({
          message: 'Onboarding or triage tasked successfully',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitStatus('');
      }, 1000);
    } catch (ex) {
      console.error(ex);
      setSubmitStatus('');
      notification.error({
        message: 'Failed to create onboarding or triage task',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleResetForm = async () => {
    setSubmitStatus('');
    setFormCompleted(false);
    await fetchPrefillClientData(clientFormData);
  };

  return (
    <Modal
      title={
        <div className={styles.modalTitle}>
          Create a set of activities for {clientRecordData.clientProfiles[0].firstName}{' '}
          {clientRecordData.clientProfiles[0].lastName}{' '}
          {clientRecordData.clientProfiles.length > 1 && `and ${clientRecordData.clientProfiles.length - 1} other(s)`}
        </div>
      }
      open={visible}
      className={styles.modalContainer}
      footer={null}
      onCancel={onClose}
      destroyOnClose
    >
      {prefillLoading ? (
        <div className={styles.loadingContainer}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : (
        <OnboardingTriageStep
          formCompleted={formCompleted}
          clientFormData={clientFormData}
          onboardingList={onBoardingList}
          consentList={consentList}
          assessmentList={assessmentList}
          clientRecords={updatedClientRecord}
          setClientFormData={setClientFormData}
          handleSubmit={handleSubmit}
          handleResetForm={handleResetForm}
          submitStatus={submitStatus}
          isTriageOnly
        />
      )}
    </Modal>
  );
};

export default OnboardingTriageModal;
