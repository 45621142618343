import { useState } from 'react';
import { Modal, notification } from 'antd';
import classnames from 'classnames';
import { Field, FieldProps, Formik, Form } from 'formik';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import TextEditor2 from 'components/v2/TextEditor2/TextEditor2';

import styles from './SimpleNote.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useCreateNoteMutation } from 'redux/endpoints/checkInServices/notes';
import { sanitizeHTMLString } from 'utils/sanitizeHTMLString';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { useDispatch } from 'react-redux';
import { clientRecordsApiSlice } from 'redux/endpoints/clinicianProfileServices/client';
import { CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { PinType } from 'interfaces/Clients/clientsRecord';

const useCreateNote = () => {
  const dispatch = useDispatch();
  const [createNote, { isLoading: isSubmitting }] = useCreateNoteMutation();

  const submit = async (payload: any, onSuccess = () => {}) => {
    try {
      await createNote({
        ...payload
      });
      onSuccess();
      dispatch(clientRecordsApiSlice.util.invalidateTags([CPSTagTypes.ClientEncrypted, CPSTagTypes.ClientList]));

      notification.success({
        message: 'Note created',
        duration: 5,
        closeIcon: <span className="success">OK</span>
      });
    } catch (e) {
      console.error(e);
      notification.error({ message: `Something went wrong` });
    }
  };

  return { submitting: isSubmitting, submit };
};

interface SimpleNoteProps {
  recordId: string;
  disabled?: boolean;
  onCreated?: () => void;
}

const SimpleNote = ({ recordId, disabled, onCreated }: SimpleNoteProps) => {
  const [visible, setVisible] = useState(false);
  const [pinType, setPinType] = useState<PinType | ''>('');
  const { submitting, submit } = useCreateNote();
  const [status, setStatus] = useState<'' | 'active' | 'finished'>('');

  const validateTitle = (value: string) => {
    let error;
    if (!value || value.length < 1) {
      error = 'Title is required!';
    }

    return error;
  };

  return (
    <div className={styles.container}>
      <div className={styles.createBtn}>
        <ButtonAlt
          onClick={() => disabled || setVisible(true)}
          disabled={disabled}
          id={'createQuickNoteId'}
          icon={'add_circle_outline'}
          fab
        >
          Profile Note
        </ButtonAlt>
      </div>
      <Formik
        initialValues={{
          title: '',
          note: ''
        }}
        onSubmit={({ title, note }, { resetForm }) => {
          setStatus('active');

          submit(
            {
              clientRecordId: recordId,
              title: sanitizeHTMLString(title, 'escape'),
              body: note,
              pinType
            },
            () => {
              setStatus('finished');
              setTimeout(() => {
                setVisible(false);
                onCreated && onCreated();
                resetForm({});
                setStatus('');
                setPinType('');
              }, 2000);
            }
          );
        }}
      >
        {({ submitForm, errors, touched }) => (
          <Form>
            <Modal
              open={visible}
              onCancel={() => setVisible(false)}
              title="Create Note"
              footer={[
                <div className={styles.buttonWrapper}>
                  <div className={styles.pinTypeDesc}>
                    {pinType && (
                      <>
                        You're pinning {pinType === PinType.Important ? 'an ' : 'a '}
                        <strong>{pinType} flag</strong> - this note will be visible to all admin and clinical staff with
                        access to this profile (it is not shared with the client).
                      </>
                    )}
                  </div>

                  <ButtonAlt status={status} onClick={() => submitForm()} disabled={submitting}>
                    {submitting ? 'Saving...' : 'Save Note'}
                  </ButtonAlt>
                </div>
              ]}
              destroyOnClose
            >
              <div>
                <div className={styles.rowContainer}>
                  <Field name="title" validate={validateTitle}>
                    {({ field }: FieldProps) => (
                      <div
                        className={classnames(
                          styles.fieldContainer,
                          styles.titleInput,
                          errors.title && touched.title && styles.fieldError
                        )}
                      >
                        <MaterialInput id="SimpleNote.title" label="Note title" {...field} required />
                        {errors.title && touched.title ? <div className={styles.fieldError}>{errors.title}</div> : null}
                      </div>
                    )}
                  </Field>

                  <div className={classnames(styles.fieldContainer, styles.pinTypeField)}>
                    <DropdownSearchable
                      className={styles.dropdown}
                      controllerClassName={styles.controller}
                      labelWrapperClassName={styles.labelWrapper}
                      itemIconClassName={styles.icon}
                      itemClassName={styles.item}
                      placeholder=""
                      options={[
                        { value: '', label: 'No flag' },
                        {
                          value: PinType.Important,
                          label: 'Important',
                          icon: 'notification_important',
                          iconClass: styles.important,
                          iconVariant: 'material-icons-outlined'
                        },
                        {
                          value: PinType.Risk,
                          label: 'Risk',
                          icon: 'flag_circle',
                          iconClass: styles.risk,
                          iconVariant: 'material-icons-outlined'
                        }
                      ]}
                      selected={pinType}
                      onSelect={(value) => setPinType(value as PinType)}
                      hideErrorDesc
                      hideActiveLine
                    />
                  </div>
                </div>

                <Field name="note">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <div className={`${styles.field} ${styles.noteField}`}>
                      {/* <TextEditor
                        id={'clientQuickNote'}
                        value={field.value}
                        onChange={(content) => setFieldValue('note', content)}
                        readOnly={submitting}
                      /> */}
                      <TextEditor2
                        height={300}
                        value={field.value}
                        onChange={(content) => setFieldValue('note', content)}
                        readOnly={submitting}
                      />
                    </div>
                  )}
                </Field>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SimpleNote;
