export enum FitPsychologistGenderList {
  woman = 'woman',
  man = 'man',
  transgenderWoman = 'transgenderWoman',
  transgenderMan = 'transgenderMan',
  nonBinary = 'nonBinary',
  agender = 'agender'
}

export const FIT_CLIENT_GENDER_LIST_LABELS: Record<FitPsychologistGenderList, string> = {
  woman: 'Woman',
  man: 'Man',
  transgenderWoman: 'Transgender Woman',
  transgenderMan: 'Transgender Man',
  nonBinary: 'Non-Binary',
  agender: 'Agender (I don’t identify with any gender)'
};

export enum FitDeliveryList {
  phone = 'phone',
  video = 'video'
}

export const FIT_DELIVERY_LIST_LABELS: Record<FitDeliveryList, string> = {
  phone: 'Phone Consult',
  video: 'Video Consult'
};

export enum SelectFitDeliveryList {
  FaceToFace = 'faceToFace',
  Phone = 'phone',
  Video = 'video'
}

export const SELECT_FIT_DELIVERY_LIST_LABELS: Record<SelectFitDeliveryList, string> = {
  [SelectFitDeliveryList.FaceToFace]: 'Face to Face',
  [SelectFitDeliveryList.Phone]: 'Phone Call',
  [SelectFitDeliveryList.Video]: 'Video Call'
};

export enum FitLocationList {
  // Select
  Tynemouth = 'tynemouth',
  Gosforth = 'gosforth',
  Durham = 'durham',
  Hexham = 'hexham',
  Darlington = 'darlington'
}

export const FIT_LOCATION_LIST_LABELS: Record<FitLocationList, string> = {
  [FitLocationList.Tynemouth]: 'Tynemouth',
  [FitLocationList.Gosforth]: 'Gosforth',
  [FitLocationList.Durham]: 'Durham',
  [FitLocationList.Hexham]: 'Hexham',
  [FitLocationList.Darlington]: 'Darlington'
};

export enum SHLanguageList {
  en = 'en',
  es = 'es',
  vi = 'vi',
  fr = 'fr',
  it = 'it',
  sr = 'sr',
  bs = 'bs',
  hr = 'hr',
  fa = 'fa',
  af = 'af',
  pt = 'pt',
  ta = 'ta',
  de = 'de',
  ko = 'ko',
  bn = 'bn',
  zh = 'zh'
}

export const FIT_LANGUAGE_LIST_LABELS: Record<SHLanguageList, string> = {
  en: 'English',
  es: 'Spanish',
  vi: 'Vietnamese',
  fr: 'French (Standard)',
  it: 'Italian (Standard)',
  sr: 'Serbian',
  bs: 'Bosnian',
  hr: 'Croatian',
  fa: 'Persian/Farsi',
  af: 'Afrikaans',
  pt: 'Portuguese',
  ta: 'Tamil',
  de: 'German (Standard)',
  ko: 'Korean',
  bn: 'Bengali',
  zh: 'Mandarin'
};

export const SERVICE_AVAILABLE_LIST = [
  { label: 'Report Creation', value: 'Report Creation' },
  { label: 'Accept Workcover', value: 'Accept Workcover' }
];

export const STYLE_LIST = [
  { label: 'Emotion Focused Therapy', value: 'Emotion Focused Therapy' },
  { label: 'Interpersonal Psychotherapy', value: 'Interpersonal Psychotherapy' },
  { label: 'Mindfulness', value: 'Mindfulness' },
  { label: 'Cognitive Behavioral Therapy (CBT)', value: 'Cognitive Behavioral Therapy (CBT)' },
  { label: 'Narrative Therapy', value: 'Narrative Therapy' },
  { label: 'Positive Psychology', value: 'Positive Psychology' },
  { label: 'Psychodynamic Therapy', value: 'Psychodynamic Therapy' },
  { label: 'Solution-Focused Brief Therapy', value: 'Solution-Focused Brief Therapy' },
  { label: 'Acceptance and Commitment Therapy (ACT)', value: 'Acceptance and Commitment Therapy (ACT)' },
  { label: 'Dialectical Behavior Therapy', value: 'Dialectical Behavior Therapy' },
  { label: 'Attachment-Based Therapy', value: 'Attachment-Based Therapy' },
  { label: 'Coaching', value: 'Coaching' },
  { label: 'Motivational Interviewing', value: 'Motivational Interviewing' },
  { label: 'Person-Centered Therapy', value: 'Person-Centered Therapy' },
  { label: 'Schema Therapy', value: 'Schema Therapy' }
];

export const FUNDING_METHODS = [
  { label: 'Rebate', value: 'Rebate' },
  { label: 'Bulk bill', value: 'Bulk bill' },
  { label: 'Self fund', value: 'Self fund' },
  { label: 'NDIS', value: 'NDIS' },
  { label: 'WorkCover', value: 'WorkCover' },
  { label: 'DVA', value: 'DVA' },
  { label: 'Other', value: 'Other' }
];

export const HELM_FUNDING_METHODS = [
  { label: 'Medicare', value: 'medicare' },
  {
    label: 'Agency',
    value: 'agency'
  },
  { label: 'NDIS', value: 'ndis' }
];
