import { Divider, Skeleton } from 'antd';
import Card from 'components/Card/Card';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { IInvoiceTemplate, InvoiceStatus } from 'pages/Invoices/interface';

import { selectInvoiceForm } from 'redux/invoices/createInvoiceWithTemplateSlice';
import { useAppSelector } from 'redux/hooks';

import PracticeInfoSection from './components/PracticeInfoSection/PracticeInfoSection';
import InvoiceInfoSection from './components/InvoiceInfoSection/InvoiceInfoSection';
import ServiceDeliverySection from './components/ServiceDeliverySection/ServiceDeliverySection';
import PaymentInfoSection from './components/PaymentInfoSection/PaymentInfoSection';
import AppointmentInfoSection from './components/AppointmentInfoSection/AppointmentInfoSection';
import InvoiceFooterSection from './components/InvoiceFooterSection/InvoiceFooterSection';
import styles from './RightSection.module.scss';
import { config } from 'config/config';
import { clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';

interface RightSectionProps {
  invoiceTemplate: IInvoiceTemplate;
  isSubmitting: boolean;
  onSave: (invoiceStatus: InvoiceStatus) => void;
  isClientDetailsLoading?: boolean;
  isRendererBinding?: boolean;
  clientEncryptDetails: clientRecordsEncryptedInterface | undefined;
}

const RightSection = ({
  invoiceTemplate,
  onSave,
  isSubmitting,
  clientEncryptDetails,
  isClientDetailsLoading,
  isRendererBinding
}: RightSectionProps) => {
  const invoiceForm = useAppSelector(selectInvoiceForm);
  const MONEY_SYMBOL = config.currencySymbol;

  const getTotalPaymentAfterDiscount = () => {
    const { discount, items } = invoiceForm;
    const totalPrice = items.reduce((finalTotal, item) => finalTotal + Number(item.total), 0);
    const isDiscounted = discount && (discount.type === 'percent' || discount.type === 'amount');

    if (!isDiscounted) {
      return totalPrice.toFixed(2);
    }

    const discountValue = discount.value ? Number(discount.value) : 0;

    if (discount.type === 'percent') {
      const afterPercentageDiscount = Math.max(totalPrice - (totalPrice * Math.round(discountValue)) / 100, 0).toFixed(
        2
      );
      return afterPercentageDiscount;
    }

    const afterDiscount = Math.max(totalPrice - discountValue, 0).toFixed(2);
    return afterDiscount;
  };

  return (
    <Card>
      {isClientDetailsLoading || isRendererBinding ? (
        <Skeleton active className={styles.wrapper} />
      ) : (
        <>
          <PracticeInfoSection invoiceTemplate={invoiceTemplate} />
          <Divider style={{ margin: 0 }} />
          <InvoiceInfoSection invoiceTemplate={invoiceTemplate} />
          <Divider style={{ margin: 0 }} />
          <ServiceDeliverySection invoiceTemplate={invoiceTemplate} clientEncryptDetails={clientEncryptDetails} />
          <AppointmentInfoSection invoiceTemplate={invoiceTemplate} totalPayment={getTotalPaymentAfterDiscount()} />
          <Divider style={{ margin: 0 }} />
          <PaymentInfoSection />
          <InvoiceFooterSection />
          <div className={styles.wrapper}>
            <ButtonAlt
              type="button"
              variant={'outlined'}
              disabled={isSubmitting}
              onClick={() => onSave(InvoiceStatus.Draft)}
            >
              Save in Draft
            </ButtonAlt>
          </div>
          <ButtonAlt
            className={styles.previewAndSend}
            type="button"
            disabled={isSubmitting}
            fab
            onClick={() => onSave(InvoiceStatus.Issued)}
          >
            GENERATE INVOICE FOR {MONEY_SYMBOL}
            {getTotalPaymentAfterDiscount()}
          </ButtonAlt>
        </>
      )}
    </Card>
  );
};

export default RightSection;
