import { BentResponse } from 'bent';
import { httpClient } from 'utils/httpClient';

const URL = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const getOutstandingAssessments = (token: string, accountId: string, args?: { clientRecordIds?: string[] }) => {
  const query =
    args &&
    new URLSearchParams({
      ...(args.clientRecordIds && { clientRecordIds: args.clientRecordIds.join(',') }),
      allForms: 'true'
    }).toString();

  return httpClient('GET', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/assessments:getAllOutstandingAssessments${query ? `?${query}` : ''}`
  ) as Promise<BentResponse>;
};

export const postCancelAssessments = (token: string, accountId: string, payload: Record<string, unknown>) =>
  httpClient('POST', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/assessments:cancelAssessments`,
    payload
  ) as Promise<BentResponse>;

export const postSendAssessmentReminders = (token: string, accountId: string, payload: Record<string, unknown>) =>
  httpClient('POST', URL!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/assessments:sendReminders`,
    payload
  ) as Promise<BentResponse>;
