import classNames from 'classnames';
import { useField } from 'formik';
import styles from './OtherInstructions.module.scss';
import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';

const OtherInstructions = () => {
  const [{ value: otherInstructions }, otherInstructionsMeta, { setValue: setOtherInstructions }] =
    useField('otherInstructions');

  return (
    <div className={classNames(styles.fieldContainer)}>
      <FormikMaterialInput inputClass={styles.input} id="otherTitle" name="otherTitle" label="Mode Title" required />

      <div>
        <label className={styles.label} htmlFor="otherInstructions">
          Connection Instructions
        </label>
        <textarea
          id={'otherInstructions'}
          className={styles.textarea}
          rows={4}
          maxLength={320}
          value={otherInstructions}
          onChange={(e) => setOtherInstructions(e.target.value)}
        />
        {otherInstructionsMeta.touched && <div className={styles.fieldError}>{otherInstructionsMeta.error}</div>}
      </div>
    </div>
  );
};

export default OtherInstructions;
