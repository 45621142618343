import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './GroupsList.module.scss';
import GroupListingContent from './components/GroupListContent/GroupListingContent';
import { GroupStatus } from '../Interfaces/Groups';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import queryString from 'query-string';
import { useGetGroupList } from 'redux/endpoints/clinicianProfileServices/getGroupList';
import {
  resetPaging,
  selectFilters,
  selectSearch,
  selectSorting,
  setFilters,
  setPaging,
  setSorting
} from 'redux/groups/groupSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ColumnSettingsEnum, SortBy } from './components/GroupListContent/components/constants';

const GroupsList = () => {
  const { CARECOORDINATION } = useRoutesGenerator();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const path = useParams<{ tabId: GroupStatus }>();
  const location = useLocation();
  const { groupList, paging, isGroupListLoading } = useGetGroupList();

  const queryParam: {
    q?: string;
    page?: string;
  } = queryString.parse(location.search);
  const filters = useAppSelector(selectFilters);
  const search = useAppSelector(selectSearch);
  const sorting = useAppSelector(selectSorting);
  const { searchValue } = search;

  const totalPage = paging ? Math.ceil(paging.totalItems / paging.perPage) : 1;

  const tabId = (path.tabId as GroupStatus) ?? GroupStatus.Active;
  const filterStatus = filters.status || GroupStatus.Active;

  const [pageLoading, setPageLoading] = useState(false);

  const setFilterStatus = useCallback(
    (status: string) => {
      dispatch(resetPaging());
      dispatch(setFilters({ ...filters, status, selectedGroupLeads: [] }));
    },
    [dispatch, filters]
  );

  const onChangeTabFilter = useCallback(
    async (tabValue: GroupStatus) => {
      if (tabValue !== filters.status) {
        setPageLoading(true);
        setFilterStatus(tabValue);
        setPageLoading(false);
      }
    },
    [filters.status, setFilterStatus]
  );

  useEffect(() => {
    onChangeTabFilter(tabId);
  }, [onChangeTabFilter, tabId, queryParam]);

  useEffect(() => {
    setPageLoading(isGroupListLoading);
  }, [isGroupListLoading]);

  const onHandleSearch = (q: string) => {
    navigate(`${CARECOORDINATION.GROUPS}/${filterStatus}${q ? `?q=${q}` : ''}`);
  };

  const handlePageChange = async (page: string, sortBy?: SortBy) => {
    setPageLoading(true);
    dispatch(setPaging({ ...paging, page }));

    if (sortBy) {
      dispatch(
        setSorting(
          sortBy.clientSince
            ? { sortBy: ColumnSettingsEnum.CLIENT_SINCE, sortType: sortBy.clientSince }
            : sortBy.groupMember
            ? { sortBy: ColumnSettingsEnum.GROUP_MEMBER, sortType: sortBy.groupMember }
            : { sortBy: ColumnSettingsEnum.GROUP_NAME, sortType: sortBy.groupName }
        )
      );
    }
    setPageLoading(false);
  };

  return (
    <HelmetWrapper title={'Tacklit - Care Coordination'}>
      <ContentLayout>
        <div className={styles.container}>
          <GroupListingContent
            groupListing={groupList}
            isGroupListLoading={isGroupListLoading}
            onHandleSearch={onHandleSearch}
            isPageLoading={pageLoading}
            groupStatus={filterStatus}
            searchValue={searchValue}
            paging={paging}
            filters={filters}
            totalPage={totalPage}
            sorting={sorting}
            onPageChange={handlePageChange}
          />
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default GroupsList;
