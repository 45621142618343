import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

import { getName } from 'utils/general';

import ClientAvatar from 'components/ClientAvatar/ClientAvatar';

import styles from './ClientProfileAttendeesList.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface ClientProfileAttendeesListProps {
  clientRecord: clientRecordsInterface;
  onAddClientProfile: (clientProfile: clientRecordsInterface['clientProfiles'][number]) => void;
  onBack: () => void;
}

const ClientProfileAttendeesList = ({
  clientRecord: { clientProfiles, recordType },
  onAddClientProfile,
  onBack
}: ClientProfileAttendeesListProps) => {
  return (
    <div className={styles.contactsContainer}>
      <ButtonAlt
        className={styles.backBtn}
        contentClassName={styles.btnContent}
        variant={'text'}
        size={'medium'}
        icon={'chevron_left'}
        onClick={onBack}
      >
        BACK TO ALL
      </ButtonAlt>
      {clientProfiles.length ? (
        clientProfiles.map((clientProfile, index) => (
          <div className={styles.contactContainer} key={index}>
            <div className={styles.nameAndTags}>
              {recordType === 'child' && clientProfile.role !== 'child' ? (
                <>
                  <div>{getName(clientProfile)}</div>
                  <div className={styles.pill}>{clientProfile.role}</div>
                </>
              ) : (
                <ClientAvatar
                  key={index}
                  name={getName(clientProfile)}
                  initialsName={clientProfile.initials}
                  avatarUrl={clientProfile.avatar}
                  avatarSize={36}
                  initialsClassName={styles.initialName}
                  nameClassName={styles.clientName}
                  horizontal
                />
              )}
            </div>
            <div className={styles.nameAndTags}>
              <div>{clientProfile.email}</div>
              <ButtonAlt
                className={styles.addButton}
                contentClassName={styles.btnContent}
                size={'small'}
                variant={'text'}
                icon={'add'}
                onClick={() => onAddClientProfile(clientProfile)}
              >
                Add
              </ButtonAlt>
            </div>
          </div>
        ))
      ) : (
        <div>All contacts have been added</div>
      )}
    </div>
  );
};

export default ClientProfileAttendeesList;
