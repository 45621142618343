import * as yup from 'yup';
import { EpisodeError, EpisodeFields, UpdateEpisodeField, UpdateEpisodeStatusError } from '../interface';
import { validateEpisodeSchema, validateUpdateEpisodeStatusSchema } from './validateEpisodeSchema';

export const validateForm = (episodeFields: EpisodeFields, episodeStatusCheck?: boolean) => {
  const validationErrors: EpisodeError = {
    status: '',
    type: '',
    startDate: '',
    endDate: '',
    'reason.medicalReason': '',
    currentEpisodeStatus: '',
    currentEpisodeEndDate: ''
  };

  try {
    validateEpisodeSchema(episodeStatusCheck || false).validateSync(episodeFields, { abortEarly: false });

    return validationErrors;
  } catch (error: any) {
    if (error instanceof yup.ValidationError && error.inner && error.inner.length !== 0) {
      error.inner.forEach((subError: any) => {
        if (Object.keys(validationErrors).includes(subError.path)) {
          validationErrors[subError.path as keyof typeof validationErrors] = subError.message;
        }
      });
      return validationErrors;
    }
  }
};

export const validateUpdateStatusForm = (updateStatusFields: UpdateEpisodeField) => {
  const validationErrors: UpdateEpisodeStatusError = {
    currentEpisodeStatus: '',
    currentEpisodeEndDate: ''
  };

  try {
    validateUpdateEpisodeStatusSchema().validateSync(updateStatusFields, { abortEarly: false });

    return validationErrors;
  } catch (error: any) {
    if (error instanceof yup.ValidationError && error.inner && error.inner.length !== 0) {
      error.inner.forEach((subError: any) => {
        if (Object.keys(validationErrors).includes(subError.path)) {
          validationErrors[subError.path as keyof typeof validationErrors] = subError.message;
        }
      });
      return validationErrors;
    }
  }
};

export const isFormValid = (errors: EpisodeError): boolean => {
  const errorCount = Object.keys(errors).filter((item) => errors[item as keyof EpisodeError] && item);

  return !errorCount.length;
};
