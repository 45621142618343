import { Modal, notification } from 'antd';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { trim } from 'lodash';
import moment from 'moment';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { useState } from 'react';
import { deleteAppointment } from 'utils/http/appointment';
import { deleteGroupAppointment } from 'utils/http/ScheduleService/Groups/groups';
import styles from './CancelPopover.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useAppDispatch } from 'redux/hooks';
import { SSTagTypes, scheduleServicesApiSlice } from 'redux/services/scheduleServicesApiSlice';
import {
  MOMENTJS_FORMAT_DATE,
  MOMENTJS_FORMAT_TIME,
  MOMENTJS_FORMAT_START_TIME_FORMAT,
  MOMENTJS_FORMAT_END_TIME_FORMAT
} from 'utils/appointment';

type CancelPopoverProps = {
  appointment: AppointmentSlots;
  token: string;
  onFinishMarkStatus(): void;
};

const CancelPopover = ({ appointment, token, onFinishMarkStatus }: CancelPopoverProps) => {
  const { date, endTime, recurringAppointmentId, startTime, isActivity } = appointment;

  const dispatch = useAppDispatch();
  const [cancelNote, setCancelNote] = useState<string>();
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringCancelButtonStatus, setRecurringCancelButtonStatus] = useState<ButtonStatusType>('');

  const [cancelButtonStatus, setCancelButtonStatus] = useState<ButtonStatusType>('');

  const callCancelAppointment = async (includeRecurringAppointments: boolean) => {
    setRecurringCancelButtonStatus('active');
    try {
      const { _id, groupId, clientRecord } = appointment;
      const payload = { includeRecurringAppointments, notes: cancelNote };

      if (groupId) {
        await deleteGroupAppointment(token, groupId, _id, payload);
      } else {
        await deleteAppointment(_id, payload, token);
      }

      Modal.destroyAll();

      setCancelButtonStatus('finished');
      setRecurringCancelButtonStatus('finished');

      setTimeout(() => {
        setCancelButtonStatus('');
      }, 2000);

      // Clear Next Appointment cached data after cancel appointment success
      !groupId &&
        dispatch(
          scheduleServicesApiSlice.util.invalidateTags([
            { type: SSTagTypes.NextAppointmentByCurrent, id: clientRecord?._id }
          ])
        );

      notification.success({
        message: 'Your appointment has been cancelled',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      onFinishMarkStatus();
    } catch (e) {
      setRecurringCancelButtonStatus('');

      setCancelButtonStatus('');
      notification.error({ message: `Something went wrong while trying to cancel your appointment` });
    }
  };

  const handleCancelAppointment = async () => {
    if ((cancelNote !== undefined && trim(cancelNote) !== '') || isActivity) {
      if (recurringAppointmentId) {
        setIsRecurring(true);
      } else {
        setCancelButtonStatus('active');
        await callCancelAppointment(false);
      }
    } else {
      setCancelNote('');
    }
  };

  const handleCancelCancelRecurringAppointment = () => {
    setIsRecurring(false);
    setCancelButtonStatus('');
    setRecurringCancelButtonStatus('');
  };

  return (
    <div className={styles.cancelPopoverContainer}>
      <Modal
        open={isRecurring}
        okType={'primary'}
        title={'This appointment is a recurring appointment.'}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancelCancelRecurringAppointment}
        zIndex={1200}
      >
        <>
          <div className={styles.confirmModalAppointmentDescription}>
            <div>
              <strong>Date:</strong> {moment(date, MOMENTJS_FORMAT_DATE).format('dddd DD MMMM YYYY')}
            </div>
            <div>
              <strong>Time:</strong>{' '}
              {`${moment(startTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_START_TIME_FORMAT)} - ${moment(
                endTime,
                MOMENTJS_FORMAT_TIME
              ).format(MOMENTJS_FORMAT_END_TIME_FORMAT)}`}
            </div>
          </div>
          <div className={styles.confirmModalConfirmCancel}>
            Would you like to cancel all related recurring appointments?
          </div>
          <div className={styles.confirmModalButtonContainer}>
            <Button
              className={`${styles.modalButton} ${recurringCancelButtonStatus !== '' ? styles.modalButtonBusy : ''}`}
              onClick={() => callCancelAppointment(true)}
              disabled={recurringCancelButtonStatus !== ''}
            >
              Cancel all recurring appointments
            </Button>
            <Button
              className={`${styles.modalButton} ${recurringCancelButtonStatus !== '' ? styles.modalButtonBusy : ''}`}
              onClick={() => callCancelAppointment(false)}
              disabled={recurringCancelButtonStatus !== ''}
            >
              Cancel this appointment
            </Button>
            <Button
              className={styles.modalCancelButton}
              onClick={handleCancelCancelRecurringAppointment}
              disabled={recurringCancelButtonStatus !== ''}
            >
              Don't cancel this appointment
            </Button>
          </div>
        </>
      </Modal>
      {!isActivity && (
        <>
          <textarea
            onChange={(e) => setCancelNote(e.target.value)}
            value={cancelNote}
            placeholder="Reason for cancellation"
          />
          <ErrorMessage
            error="Please insert reason to cancel"
            visible={cancelNote !== undefined && trim(cancelNote) === ''}
          />
        </>
      )}
      <ButtonAlt onClick={handleCancelAppointment} status={cancelButtonStatus}>
        Confirm
      </ButtonAlt>
    </div>
  );
};

export default CancelPopover;
