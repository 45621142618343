import { Modal, notification, Skeleton } from 'antd';
import classNames from 'classnames';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import ModalHeader from 'components/v2/ModalHeader/ModalHeader';
import { AppointmentSlots, AppointmentStatusType } from 'interfaces/Schedule/Appointment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useGetAppointmentTypeById } from 'utils/hooks/AppointmentTypes/useGetAppointmentTypeById';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useFetchProfileById } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { useGetAccessToken } from 'utils/hooks/token';
import { putGroupSession } from 'utils/http/ScheduleService/Groups/groups';
import { RescheduleChangeTimePayload, submitRescheduleRequest } from 'utils/http/appointment';

import BespokeDetails from './components/BespokeDetails/BespokeDetails';
import EditBespokeForm from './components/EditBespokeForm/EditBespokeForm';
import EditEvent from './components/EditEvent/EditEvent';
import EventDetails from './components/EventDetails/EventDetails';
import FreeBusyEvent from './components/FreeBusyEvent/FreeBusyEvent';
import styles from './EventInformationModal.module.scss';
import { AppointmentType, DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { BentResponse } from 'bent';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { SSTagTypes, scheduleServicesApiSlice } from 'redux/services/scheduleServicesApiSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { defaultTimezone as systemTimezone } from 'utils/hooks/GetTimezones/getTimezones';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';
import {
  MOMENTJS_FORMAT_DATE,
  MOMENTJS_FORMAT_END_TIME_FORMAT,
  MOMENTJS_FORMAT_START_TIME_FORMAT,
  MOMENTJS_FORMAT_TIME
} from 'utils/appointment';
import ProcessAppointment from './components/ProcessAppointment/ProcessAppointment';
import OtherWorkspaceEvent from './components/OtherWorkspaceEvent/OtherWorkspaceEvent';
import { FREE_BUSY_APPOINTMENT_TYPES } from '../../CalendarWithHighlightsT23/components/CalendarView/CalendarView';
import { practiceInfo } from 'redux/slice/practiceDataSlice';

interface EventInformationModalProps {
  groupSessionLabel?: string;
  visible: boolean;
  isLoading?: boolean;
  appointment?: AppointmentSlots;
  appointmentId?: string;
  group: number;
  hideEditGroupBtn?: boolean;
  onClose: () => void;
  onEditComplete: () => void;
  onCancelAppointmentComplete: () => void;
  updateSelectedAppointment?: (values: AppointmentSlots) => void;
  isProcessAppointmentModalShow?: boolean;
  onCloseProcessAppointmentModal?: () => void;
  handleShowProcessAppointment?: () => void;
  isEditing?: boolean;
  onSubmitProcessSuccessful?: (appointmentId: string, withoutRefetch?: boolean) => void;
  isRefetchingAppointmentAfterProcess?: boolean;
}
const EventInformationModal = ({
  groupSessionLabel,
  appointment,
  isLoading,
  visible,
  group = 0,
  hideEditGroupBtn,
  onClose,
  onEditComplete,
  onCancelAppointmentComplete,
  updateSelectedAppointment,
  isProcessAppointmentModalShow,
  onCloseProcessAppointmentModal,
  handleShowProcessAppointment,
  isEditing,
  onSubmitProcessSuccessful,
  isRefetchingAppointmentAfterProcess
}: EventInformationModalProps) => {
  const { token } = useGetAccessToken();
  const { accountSettings } = useGetAccountSettings();
  const { profile } = useFetchProfileById(appointment?.clinicianId!);
  const { appointmentType, isAppointmentTypeLoading } = useGetAppointmentTypeById(token, appointment?.sessionTypeId!);
  const { data: practiceData, isLoading: isPracticeDataLoading } = useAppSelector(practiceInfo);
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringRescheduleButtonStatus, setRecurringRescheduleButtonStatus] = useState<ButtonStatusType>('');
  const [recurringAllRescheduleButtonStatus, setRecurringAllRescheduleButtonStatus] = useState<ButtonStatusType>('');
  const [updatePayload, setUpdatePayload] = useState<any>();

  const [isEditMode, setIsEditMode] = useState(!!isEditing);
  const [isRescheduleLoading, setRescheduleLoading] = useState(false);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

  const { timeZoneByView } = useTimeZone();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsEditMode(!!isEditing);
  }, [isEditing]);

  const onCancel = () => {
    onClose();
    setIsEditMode(false);
    setRescheduleLoading(false);
  };

  const handleEditComplete = () => {
    setIsEditMode(false);
    setRescheduleLoading(true);
    setTimeout(() => {
      setRescheduleLoading(false);
    }, 8000);
    onEditComplete();
  };

  const handleCancelComplete = () => {
    onCancelAppointmentComplete();
    onClose();
  };

  const isBespoke = appointment?.deliveryType === DeliveryType.Bespoke;

  let date, endTime, recurringAppointmentId: string | undefined, startTime;

  if (appointment) {
    date = appointment.date;
    endTime = appointment.endTime;
    recurringAppointmentId = appointment.recurringAppointmentId;
    startTime = appointment.startTime;
  }

  const title = !appointment ? (
    'No appointment selected'
  ) : !isBespoke ? (
    <div className={isEditMode ? styles.editTitleContainer : styles.titleContainer}>
      {isEditMode && <div className={styles.title}>EDITING</div>}
      {isLoading && isRescheduleLoading ? (
        <Skeleton.Input className={styles.timeChangeLoading} />
      ) : (
        <>
          <div>
            {moment(appointment.startTime, 'HH:mm').format('hh:mm')}
            {' - '}
            {moment(appointment.endTime, 'HH:mm').format('hh:mm A')}
          </div>
          <div className={styles.divider}>&nbsp;</div>
          <div>{moment(appointment.date, 'YYYY-MM-DD').format('dddd, Do MMMM')}</div>
          {appointment.type === 'reserved' && <div className={styles.reservedText}>(Reserved)</div>}
        </>
      )}
      {(!FREE_BUSY_APPOINTMENT_TYPES.includes(appointment.type) || appointment.isActivity) &&
        appointment.type !== AppointmentStatusType.OtherWorkspace &&
        appointment.type !== 'reserved' &&
        appointment.deliveryType !== DeliveryType.Bespoke &&
        !isEditMode &&
        !appointment.isProcessed &&
        !appointment.requestedChanges && (
          <ButtonAlt
            size={'medium'}
            className={styles.changeDayOrTime}
            contentClassName={styles.btnContent}
            variant={'text'}
            icon={'edit'}
            onClick={() => setIsEditMode(true)}
          >
            Change day or time
          </ButtonAlt>
        )}
      {!isEditing && isEditMode && (
        <ButtonAlt
          size={'medium'}
          contentClassName={styles.btnContent}
          variant={'text'}
          icon={'chevron_left'}
          onClick={() => setIsEditMode(false)}
        >
          Back
        </ButtonAlt>
      )}
    </div>
  ) : isEditMode ? (
    'EDIT EVENT'
  ) : (
    <div className={styles.bespokeTitle}>
      <div className={styles.title}>
        <span>{appointment.title}</span>
        {appointment.recurringAppointmentId && <span className="material-icons-outlined">repeat</span>}
      </div>
      <ButtonAlt
        size={'medium'}
        contentClassName={styles.btnContent}
        variant={'text'}
        icon={'edit'}
        onClick={() => setIsEditMode(true)}
      >
        Edit this event
      </ButtonAlt>
    </div>
  );

  const callRescheduleAppointment = async (
    includeRecurringAppointments: boolean,
    updatePayload: any,
    setSubmitStatus: Function
  ) => {
    try {
      setSubmitStatus('active');
      if (appointment) {
        const { sessionTypeId, _id, groupId } = appointment;

        const payload: RescheduleChangeTimePayload = {
          asAdmin: isEdgeAdminView || isEdgeReceptionist,
          includeRecurringAppointments,
          appointmentTypeId: sessionTypeId!,
          appointmentId: _id,
          note: '',
          ...updatePayload
        };

        let response;

        if (groupId) {
          response = (await putGroupSession(token, groupId, _id, payload)) as BentResponse;
        } else {
          response = (await submitRescheduleRequest(payload, token)) as BentResponse;
        }

        if (response.statusCode === 204) {
          updateSelectedAppointment?.(updatePayload);
          Modal.destroyAll();

          setSubmitStatus('finished');

          // Clear Next Appointment cached data after update appointment success
          !groupId &&
            dispatch(
              scheduleServicesApiSlice.util.invalidateTags([
                { type: SSTagTypes.NextAppointmentByCurrent, id: appointment.clientRecord?._id }
              ])
            );
          setTimeout(() => {
            setSubmitStatus('');
            handleEditComplete();
            setIsRecurring(false);
          }, 2000);

          notification.success({
            message: 'Your appointment has been updated',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } else if (response.statusCode === 409) {
          notification.error({ message: (await response.json()).message ?? 'Something went wrong.' });
          setSubmitStatus('');
        } else {
          notification.error({ message: 'Something went wrong.' });
          setSubmitStatus('');
        }
      }
    } catch (e) {
      notification.error({ message: 'Something went wrong.' });
      setSubmitStatus('');
      console.error({ e });
    }
  };

  const handleUpdateAppointment = async (payload: any, setSubmitStatus: Function) => {
    if (recurringAppointmentId) {
      setIsRecurring(true);
      setUpdatePayload(payload);
    } else {
      await callRescheduleAppointment(false, payload, setSubmitStatus);
    }
  };

  const handleProcessAppointmentComplete = (withoutRefetch: boolean) => {
    onEditComplete();
    onSubmitProcessSuccessful && onSubmitProcessSuccessful(appointment?._id || '', withoutRefetch);
  };

  const generateContent = (appointment: AppointmentSlots, appointmentType?: AppointmentType) => {
    const getTimeZone = appointment?.group?._id
      ? accountSettings?.timezone || systemTimezone
      : appointment?.clinicianId && profile?.workTimeZone
      ? profile.workTimeZone
      : timeZoneByView;

    if (isProcessAppointmentModalShow) {
      return (
        <ProcessAppointment
          appointment={appointment}
          appointmentType={appointmentType}
          onClose={onCloseProcessAppointmentModal}
          profile={profile}
          onProcessAppointmentComplete={handleProcessAppointmentComplete}
        />
      );
    }

    if (isEditMode && !isBespoke) {
      return (
        <EditEvent
          appointment={appointment}
          appointmentType={appointmentType}
          handleUpdateAppointment={handleUpdateAppointment}
          timeZone={getTimeZone}
        />
      );
    }
    if (isEditMode && isBespoke) {
      return (
        <EditBespokeForm
          appointment={appointment}
          handleUpdateAppointment={handleUpdateAppointment}
          onEditComplete={onEditComplete}
          timeZone={getTimeZone}
        />
      );
    }
    if (isBespoke) {
      return (
        <BespokeDetails
          appointment={appointment}
          token={token}
          onFinishMarkStatus={onEditComplete}
          onCancelAppointmentComplete={handleCancelComplete}
        />
      );
    } else {
      return (
        <EventDetails
          groupSessionLabel={groupSessionLabel}
          appointment={appointment}
          appointmentType={appointmentType}
          practiceData={practiceData}
          token={token}
          isLoading={
            isPracticeDataLoading ||
            (!!appointment.sessionTypeId && isAppointmentTypeLoading) ||
            !!isRefetchingAppointmentAfterProcess
          }
          onEditComplete={onEditComplete}
          handleUpdateAppointment={handleUpdateAppointment}
          hideEditGroupBtn={hideEditGroupBtn}
          onCancelAppointmentComplete={handleCancelComplete}
          handleReschedule={() => setIsEditMode(true)}
          handleShowProcessAppointment={handleShowProcessAppointment ? handleShowProcessAppointment : () => {}}
        />
      );
    }
  };

  return (
    <Modal
      bodyStyle={{
        padding: 0,
        borderRadius: '16px'
      }}
      width={820}
      open={visible}
      footer={null}
      destroyOnClose
      closable={false}
      className={styles.modal}
    >
      <>
        <Modal
          open={isRecurring}
          okType={'primary'}
          title={'This appointment is a recurring appointment.'}
          closable
          onCancel={() => setIsRecurring(false)}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div className={styles.confirmModalAppointmentDescription}>
            <div>
              <strong>Date:</strong> {moment(date, MOMENTJS_FORMAT_DATE).format('dddd DD MMMM YYYY')}
            </div>
            <div>
              <strong>Time:</strong>{' '}
              {`${moment(startTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_START_TIME_FORMAT)} - ${moment(
                endTime,
                MOMENTJS_FORMAT_TIME
              ).format(MOMENTJS_FORMAT_END_TIME_FORMAT)}`}
            </div>
          </div>
          <div className={styles.confirmModalConfirmCancel}>
            Would you like to update all related recurring appointments?
          </div>
          <div className={styles.confirmModalButtonContainer}>
            <ButtonAlt
              className={styles.modalButton}
              onClick={() => callRescheduleAppointment(true, updatePayload, setRecurringAllRescheduleButtonStatus)}
              disabled={recurringAllRescheduleButtonStatus !== '' || recurringRescheduleButtonStatus !== ''}
              status={recurringAllRescheduleButtonStatus}
            >
              Update all recurring appointments
            </ButtonAlt>
            <ButtonAlt
              className={styles.modalButton}
              onClick={() => callRescheduleAppointment(false, updatePayload, setRecurringRescheduleButtonStatus)}
              disabled={recurringAllRescheduleButtonStatus !== '' || recurringRescheduleButtonStatus !== ''}
              status={recurringRescheduleButtonStatus}
            >
              Update this appointment only
            </ButtonAlt>
            <Button
              className={styles.modalCancelButton}
              onClick={() => {
                Modal.destroyAll();
                setIsRecurring(false);
              }}
            >
              Don't update this appointment
            </Button>
          </div>
        </Modal>
        <div
          className={classNames(
            styles.container,
            !isBespoke && styles[`group${group}`],
            isBespoke && !isEditMode && styles.bespoke,
            isBespoke && isEditMode && styles.bespokeEdit,
            appointment?.type && appointment.type === 'reserved' && styles.reserved
          )}
        >
          {!isProcessAppointmentModalShow && <ModalHeader title={title} onCancel={onCancel} />}
          {appointment ? (
            !appointment.isActivity && FREE_BUSY_APPOINTMENT_TYPES.includes(appointment.type) ? (
              <FreeBusyEvent appointment={appointment} onToggleFreeBusy={onEditComplete} />
            ) : appointment.type === AppointmentStatusType.OtherWorkspace ? (
              <OtherWorkspaceEvent appointment={appointment} />
            ) : (
              generateContent(appointment, appointmentType)
            )
          ) : (
            <div className={styles.noAppointment}>No appointment details</div>
          )}
        </div>
      </>
    </Modal>
  );
};

export default EventInformationModal;
