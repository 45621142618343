import classnames from 'classnames';
import FlexBox from 'components/FlexBox/FlexBox';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { Provider } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectProcessAppointment,
  setProcessAppointment,
  selectFormError,
  setIsPrepareClaimOpen,
  selectIsCancel
} from 'redux/processAppointment/processAppointmentSlice';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './AppointmentMoreDetails.module.scss';
import CheckBox from 'components/CheckBox/CheckBox';
import { ServiceDelivered } from 'interfaces/Schedule/Appointment';
import { PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';

const SERVICE_DELIVERED_OPTIONS = [
  { label: 'Yes - Attended', value: ServiceDelivered.Attended },
  { label: 'No - Client DNA', value: ServiceDelivered.ClientDidNotAttend },
  { label: 'No - Clinician DNA', value: ServiceDelivered.ClinicianDidNotAttend }
];

const CANCEL_SERVICE_DELIVERED_OPTIONS = [
  { label: 'Client - Cancelled Short Notice', value: ServiceDelivered.CancelShortNotice },
  { label: 'Client - Cancelled With Notice', value: ServiceDelivered.CancelWithNotice },
  { label: 'Clinician - Cancelled Short Notice', value: ServiceDelivered.ClinicianCancelShortNotice },
  { label: 'Clinician - Cancelled With Notice', value: ServiceDelivered.ClinicianCancelWithNotice }
];

const AppointmentMoreDetails = ({ providers }: { providers: Provider[] }) => {
  const dispatch = useAppDispatch();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const processAppointment = useAppSelector(selectProcessAppointment);
  const {
    selectedServiceDelivered,
    selectedPractitioner,
    selectedProviderNumber,
    selectedClaimType,
    selectedMbsCode,
    waiveCancellationFee
  } = processAppointment;
  const formError = useAppSelector(selectFormError);
  const isCancel = useAppSelector(selectIsCancel);
  const providerNumberOptions = providers.map(({ providerNumber }) => ({
    label: providerNumber,
    value: providerNumber
  }));

  const onSelectServiceDelivered = (value: string) => {
    dispatch(
      setProcessAppointment({
        selectedServiceDelivered: value,
        waiveCancellationFee: false // reset waiveCancellationFee when change service delivered
      })
    );
    dispatch(
      setIsPrepareClaimOpen(
        value === ServiceDelivered.Attended &&
          selectedClaimType &&
          [PendingClaimsFunder.REBATE, PendingClaimsFunder.BULK_BILL, PendingClaimsFunder.DVA].includes(
            selectedClaimType
          )
      )
    );
    if (value !== ServiceDelivered.Attended && (selectedClaimType || selectedMbsCode)) {
      dispatch(
        setProcessAppointment({
          ...(selectedClaimType && {
            selectedClaimType: undefined
          }),
          ...(selectedMbsCode && {
            selectedMbsCode: undefined,
            useServiceFee: false
          })
        })
      );
    }
  };

  const isServiceDelivered = selectedServiceDelivered === ServiceDelivered.Attended;

  return (
    <FlexBox direction="row" spacing={40} className={styles.container}>
      <FlexBox direction="row" spacing={8}>
        <MaterialSelect
          borderless={formError.selectedServiceDelivered}
          className={classnames(styles.minWidth200, formError.selectedServiceDelivered && styles.errorInput)}
          id="serviceDelivered"
          label="Service Delivered?"
          labelClass={styles.customLabel}
          isSearchable={false}
          options={
            isCancel
              ? CANCEL_SERVICE_DELIVERED_OPTIONS
              : [...SERVICE_DELIVERED_OPTIONS, ...CANCEL_SERVICE_DELIVERED_OPTIONS]
          }
          value={selectedServiceDelivered}
          whiteSpace="nowrap"
          onChange={onSelectServiceDelivered}
        />
        {isServiceDelivered ? (
          <div className={styles.selectedYesBadge}>
            <span className="material-icons">check_circle</span>
          </div>
        ) : (
          <div className={styles.selectedNoBadge}>
            <span className="material-icons">cancel</span>
          </div>
        )}
      </FlexBox>
      <div className={styles.dropdownSearchableWrapper}>
        <div className={styles.disabledInputWrapper}>
          <span className={styles.customLabel}>{isServiceDelivered ? 'Delivered by' : 'With'}</span>
          <div className={styles.textWrapper}>{selectedPractitioner?.name}</div>
        </div>
      </div>

      {isServiceDelivered ? (
        <>
          {providerNumberOptions.length > 0 &&
            (providerNumberOptions.length < 2 ? (
              <div className={styles.disabledInputWrapper}>
                <span className={styles.customLabel}>Provider #</span>
                <div className={styles.textWrapper}>{selectedProviderNumber}</div>
              </div>
            ) : (
              <MaterialSelect
                className={styles.minWidth160}
                id="providerNo"
                label="Provider #"
                labelClass={styles.customLabel}
                isSearchable={false}
                options={providerNumberOptions}
                value={selectedProviderNumber}
                whiteSpace="nowrap"
                onChange={(value: string) => dispatch(setProcessAppointment({ selectedProviderNumber: value }))}
                isDisabled={!isEdgeAdminView}
              />
            ))}
        </>
      ) : (
        (selectedServiceDelivered === ServiceDelivered.ClientDidNotAttend ||
          selectedServiceDelivered === ServiceDelivered.CancelShortNotice) && (
          <CheckBox
            id="waiveCancellationFee"
            value={waiveCancellationFee}
            label="Waive cancellation fee"
            onChange={(e) => dispatch(setProcessAppointment({ waiveCancellationFee: e.target.checked }))}
          />
        )
      )}
    </FlexBox>
  );
};

export default AppointmentMoreDetails;
