import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { AppointmentType, ClaimType, DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  Step,
  selectCurrentStep,
  setCurrentStep,
  selectProcessAppointment,
  setProcessAppointment,
  selectFormError,
  setFormError,
  setIsPrepareClaimOpen,
  selectIsPrepareClaimOpen
} from 'redux/processAppointment/processAppointmentSlice';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import styles from './AppointmentBasicDetails.module.scss';
import { mbsItemLookup } from 'pages/InvoiceTemplate/helpers/helpers';
import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { useMemo } from 'react';
import { getDeliveryTypeLabel } from 'utils/appointment';
import { ServiceDelivered } from 'interfaces/Schedule/Appointment';
import { config } from 'config/config';

interface AppointmentBasicDetailsProps {
  appointmentTypes: AppointmentType[];
  medicareRole: ClinicianMedicareRole | undefined;
}

const needResetPrepareClaim = ({
  selectedAppointmentType
}: {
  selectedAppointmentType: AppointmentType | undefined;
}) => {
  return (
    !selectedAppointmentType?.claimType ||
    (selectedAppointmentType.claimType &&
      selectedAppointmentType.claimType !== ClaimType.BULK_BILL &&
      selectedAppointmentType.claimType !== ClaimType.REBATE)
  );
};

const AppointmentBasicDetails = ({ appointmentTypes, medicareRole }: AppointmentBasicDetailsProps) => {
  const dispatch = useAppDispatch();
  const { isAppointmentRateReadDenied } = useGetPermissionToggle();
  const processAppointment = useAppSelector(selectProcessAppointment);
  const { selectedClient, selectedAppointmentType, selectedDeliveryMode, selectedServiceDelivered } =
    processAppointment;
  const currentStep = useAppSelector(selectCurrentStep);
  const formError = useAppSelector(selectFormError);
  const isPrepareClaimOpen = useAppSelector(selectIsPrepareClaimOpen);

  const appointmentTypeOptions = appointmentTypes
    ? appointmentTypes.map((item) => ({
        value: item._id || '',
        label: item.name,
        ...(!isAppointmentRateReadDenied && {
          subLabel: `${config.currencySymbol}${item.rate.amount}`
        })
      }))
    : [];

  const deliveryModeOptions = useMemo(() => {
    const foundAppointmentType =
      selectedAppointmentType && appointmentTypes?.find((item) => item._id === selectedAppointmentType._id);

    return (
      foundAppointmentType?.deliveryOptions.map((deliveryType) => ({
        value: deliveryType,
        label: getDeliveryTypeLabel(deliveryType, foundAppointmentType.otherInstructions)
      })) || []
    );
  }, [appointmentTypes, selectedAppointmentType]);

  const onSelectAppointmentType = (id: string) => {
    const selectedAppointmentType = appointmentTypes.find((item) => item._id === id);
    const isNeedResetPrepareClaim = needResetPrepareClaim({
      selectedAppointmentType
    });

    const mbsCode =
      !isNeedResetPrepareClaim &&
      selectedAppointmentType &&
      selectedDeliveryMode &&
      selectedAppointmentType?.deliveryOptions.includes(selectedDeliveryMode)
        ? mbsItemLookup({
            deliveryType: selectedDeliveryMode,
            duration: selectedAppointmentType.duration.minutes,
            clinicianRole: medicareRole
          })?.mbsCode
        : undefined;
    dispatch(
      setProcessAppointment({
        selectedAppointmentType: selectedAppointmentType,
        selectedClaimType: !isNeedResetPrepareClaim ? selectedAppointmentType?.claimType : undefined,
        selectedMbsCode: mbsCode,
        useServiceFee: isNeedResetPrepareClaim
          ? false
          : selectedAppointmentType?.claimType === ClaimType.BULK_BILL && !!mbsCode
      })
    );

    if (isNeedResetPrepareClaim || (!isPrepareClaimOpen && selectedServiceDelivered === ServiceDelivered.Attended)) {
      dispatch(setIsPrepareClaimOpen(!isNeedResetPrepareClaim));
    }

    // Reset error
    if (formError.selectedAppointmentType) {
      dispatch(
        setFormError({
          selectedAppointmentType: false
        })
      );
    }
    if (selectedDeliveryMode) {
      if (!selectedAppointmentType?.deliveryOptions.includes(selectedDeliveryMode)) {
        dispatch(
          setProcessAppointment({
            selectedDeliveryMode: undefined
          })
        );
        dispatch(setCurrentStep(Step.DeliveryMode));
      }
    }
  };

  const onSelectDeliveryMode = (value: string) => {
    const isNeedResetPrepareClaim = needResetPrepareClaim({
      selectedAppointmentType
    });

    dispatch(
      setProcessAppointment({
        selectedDeliveryMode: value,
        ...(selectedAppointmentType && {
          selectedMbsCode: !isNeedResetPrepareClaim
            ? mbsItemLookup({
                deliveryType: value as DeliveryType,
                duration: selectedAppointmentType.duration.minutes,
                clinicianRole: medicareRole
              })?.mbsCode
            : undefined
        })
      })
    );
    // Reset error
    if (formError.selectedDeliveryMode) {
      dispatch(
        setFormError({
          selectedDeliveryMode: false
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.dropdownWrapper}>
        <div className={styles.textWrapper}>{selectedClient?.firstLastName}</div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.dropdownWrapper}>
        <DropdownSearchable
          className={formError.selectedAppointmentType ? styles.errorInput : undefined}
          isActive={currentStep === Step.AppointmentType}
          onActive={() => dispatch(setCurrentStep(Step.AppointmentType))}
          dropdownIconClassName={styles.dropdownSearchableDropdownIcon}
          placeholder="Appointment Type"
          searchable
          selected={selectedAppointmentType?._id}
          options={appointmentTypeOptions}
          isLoading={appointmentTypes.length === 0}
          onSelect={onSelectAppointmentType}
          disabled={!selectedClient}
          labelClassName={styles.dropdownLabel}
        />
      </div>
      <hr className={styles.divider} />
      <div className={styles.dropdownWrapper}>
        <DropdownSearchable
          className={formError.selectedDeliveryMode ? styles.errorInput : undefined}
          isActive={currentStep === Step.DeliveryMode}
          onActive={() => dispatch(setCurrentStep(Step.DeliveryMode))}
          dropdownIconClassName={styles.dropdownSearchableDropdownIcon}
          placeholder="Delivery Mode"
          selected={selectedDeliveryMode}
          options={deliveryModeOptions}
          disabled={!selectedAppointmentType}
          isLoading={appointmentTypes.length === 0}
          onSelect={onSelectDeliveryMode}
        />
      </div>
    </div>
  );
};

export default AppointmentBasicDetails;
