import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';
import queryString from 'query-string';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getAppointmentById = (token: string, accountId: string, appointmentId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/appointments/${appointmentId}`
  ) as Promise<BentResponse>;

export const getAppointmentsCount = (token: string, today: string) => {
  return httpClient('GET', url!, [200], { Authorization: `Bearer ${token}` })(
    `/clinicians/me/appointments/count?today=${today}`
  ) as Promise<BentResponse>;
};

export const getClientAppointmentsByDateRange = ({
  token,
  from,
  to,
  clientRecordId,
  showCreatedBy,
  showHistories,
  showDeletedAppointments,
  episodeId,
  showOutsideOfEpisode
}: {
  token: string;
  from?: string;
  to?: string;
  clientRecordId?: string;
  showCreatedBy?: boolean;
  showHistories?: boolean;
  showDeletedAppointments?: boolean;
  episodeId?: string;
  showOutsideOfEpisode?: boolean;
}) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(
    `/appointments?${queryString.stringify({
      from,
      to,
      clientRecordId,
      showCreatedBy,
      showHistories,
      showDeletedAppointments,
      episodeId,
      showOutsideOfEpisode
    })}`
  ) as Promise<BentResponse>;

export const getGroupAppointmentsByDateRange = (token: string, groupId: string, from?: string, to?: string) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/groups/${groupId}/client-records/appointments?${queryString.stringify({ from, to })}`) as Promise<BentResponse>;

export const postApproveRescheduleRequest = (token: string, appointmentId: string) =>
  httpClient('POST', url!, [204, 403, 404, 409], {
    Authorization: `Bearer ${token}`
  })(`/appointments/${appointmentId}/reschedule/request:approve`) as Promise<BentResponse>;

export const postRejectRescheduleRequest = (token: string, appointmentId: string, payload: { note?: string }) =>
  httpClient('POST', url!, [204, 404], {
    Authorization: `Bearer ${token}`
  })(`/appointments/${appointmentId}/reschedule/request:reject`, payload) as Promise<BentResponse>;

export const getRecentAppointmentsByClientRecordId = ({
  token,
  clientRecordId,
  asAdmin = false,
  limit
}: {
  token: string;
  clientRecordId: string;
  asAdmin?: boolean;
  limit?: number;
}) =>
  httpClient('GET', url!, [200], {
    Authorization: `Bearer ${token}`
  })(
    `/appointments/client-records/${clientRecordId}/recent?asAdmin=${asAdmin ? 1 : 0}${limit ? `&amount=${limit}` : ''}`
  ) as Promise<BentResponse>;
