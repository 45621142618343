import classNames from 'classnames';
import moment from 'moment';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { scrollToView } from 'utils/scrollToView';
import styles from './TimePicker.module.scss';
import { TIME_REGEX } from 'utils/regex';

interface ITimeOption {
  id: string;
  label: string;
}

interface TimePickerProps {
  options: ITimeOption[]; // Format: id: HH:mm (ex 13:45), label: hh:mm A (ex 01:45 PM)
  selectedTime: string;
  onSelect: (value: string) => void;
  customPlaceHolder?: string;
  error?: string;
}

export type TimePickerHandle = {
  resetInput: (originalTime: string) => void;
};

const getTimeLabel = (time: string) => (time ? moment(time, 'HH:mm').format('hh:mm A') : '');

const TimePicker = forwardRef<TimePickerHandle, TimePickerProps>(
  ({ options, selectedTime, onSelect, customPlaceHolder, error }, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(getTimeLabel(selectedTime));

    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        resetInput: () => {
          setInputValue(getTimeLabel(selectedTime));
        }
      }),
      [selectedTime]
    );

    useEffect(() => {
      setInputValue(getTimeLabel(selectedTime));
    }, [selectedTime]);

    useEffect(() => {
      if (isOpen) {
        scrollToView('closestTimeId', true, false, 'auto');
      }
    }, [isOpen]);

    const handleClickOutSide = (event: any) => {
      if (containerRef.current?.contains(event.target)) {
        return;
      }
      setIsOpen(false);
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutSide);
      return () => {
        document.removeEventListener('mousedown', handleClickOutSide);
      };
    });

    const onSelectTimeOption = (value: string) => {
      onSelect(value);
      setIsOpen(false);
    };

    const onInputChange = (value: string) => {
      setInputValue(value);
    };

    const onBlurHandle = () => {
      if (TIME_REGEX.test(inputValue)) {
        onSelect(moment(inputValue, 'hh:mm A').format('HH:mm'));
        return;
      }
      setInputValue(getTimeLabel(selectedTime));
    };

    const closestToSelectedTime = options.reduce((prev, curr) =>
      Math.abs(moment(curr.id, 'HH:mm').diff(moment(selectedTime, 'HH:mm'))) <
      Math.abs(moment(prev.id, 'HH:mm').diff(moment(selectedTime, 'HH:mm')))
        ? curr
        : prev
    );

    return (
      <div ref={containerRef}>
        <div className={classNames(styles.controller, error && styles.fieldError)} onClick={() => setIsOpen(true)}>
          <input
            className={styles.timeInput}
            type={'text'}
            value={inputValue}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onInputChange(event.target.value);
            }}
            onBlur={onBlurHandle}
            placeholder={customPlaceHolder || 'Select Time'}
          />
          <i className={classNames('material-icons', styles.icon, error && styles.iconError)}>arrow_drop_down</i>
          {error && !isOpen && <div className={styles.errorBorderBottom} />}
          {isOpen && <div className={styles.borderBottom} />}
        </div>
        {isOpen && (
          <div className={styles.options}>
            {options.map((item, index) => (
              <div
                id={closestToSelectedTime.id === item.id ? `closestTimeId` : ''}
                key={index}
                className={classNames(styles.item, selectedTime === item.id && styles.selected)}
                onClick={() => onSelectTimeOption(item.id)}
                onMouseDown={(event) => event.preventDefault()} // Prevent onBlur from input
              >
                {item.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
);

export default TimePicker;
