import { notification, Skeleton } from 'antd';
import { MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import moment from 'moment';

import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import ProfileTypeBadge from 'components/ProfileTypeBadge/ProfileTypeBadge';
import { appointmentStatistics } from 'interfaces/Clients/Appointment';
import { ClientRecordListResponse } from 'interfaces/Clients/clientRecordNew';
import { ClientRecordType } from 'interfaces/Clients/clientsRecord';
import { LatestClientsActivityInterface } from 'interfaces/Clients/latestClientsActivity';
import { NextAppointment } from 'interfaces/Clients/nextAppointment';
import { PracticeInterface } from 'interfaces/Profile/Profile';
import { isNotSignUpDateExists } from 'pages/Client/helpers/clientItem';
import { getDayDiff } from 'utils/helpers/DateDiff';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  putCancelInvitation,
  putResendInvitation
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { ClientListColumnsSettings, ColumnSettingsEnum } from 'utils/providers/ClientListColumnsProvider/constants';
import DateDescBox from '../../../Components/DateDescBox/DateDescBox';
import styles from './ClientItem.module.scss';
import columnStyles from '../../ClientListColumn.module.scss';
import GroupsSection from './components/GroupsSection/GroupsSection';
import ClientItemMenu from './components/ClientItemMenu/ClientItemMenu';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import TagsSection from './components/TagsSection/TagsSection';
import { REFERRAL_STATUS_LABELS, ReferralStatus } from 'interfaces/Referral/Referral';
import PinnedNoteFlags from './components/PinnedNoteFlags/PinnedNoteFlags';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
const DATE_FORMAT = 'YYYY-MM-DD';
const CLIENT_SINCE_FORMAT = 'D MMM YYYY';

interface ClientItemProps {
  clientRecord: ClientRecordListResponse['clientRecords'][number];
  columnSetting: ClientListColumnsSettings;
  selectClientNo: number;
  appointmentStatistics: appointmentStatistics;
  onSelectClient: () => void;
  refetchClientRecordList: () => void;
  practiceData?: PracticeInterface;
  nextAppointment?: NextAppointment;
  latestActivity?: LatestClientsActivityInterface['latestActivity'];
  nextAppointmentLoading: boolean;
  appointmentStatisticsLoading: boolean;
}

const ClientItem = ({
  clientRecord,
  columnSetting,
  appointmentStatistics,
  onSelectClient,
  refetchClientRecordList,
  practiceData,
  nextAppointment,
  latestActivity,
  nextAppointmentLoading,
  appointmentStatisticsLoading
}: ClientItemProps) => {
  const { token } = useGetAccessToken();
  const [t] = useTranslation();

  const [isInvitationProcessing, setIsInvitationProcessing] = useState<boolean>(false);

  const {
    _id,
    caseId,
    clientProfiles,
    recordType,
    createdAt,
    canBeFullProfile,
    clinicians,
    groups,
    lastConsentForm,
    lastSurvey,
    lastPsychometric,
    recordStatus,
    referrals
  } = clientRecord;
  const { remainingDate, remainingTime } = useMemo(() => {
    if (nextAppointment) {
      const {
        appointment: { date, startTime }
      } = nextAppointment;

      const dateTimeMoment = moment(`${date} ${startTime}`, DATE_TIME_FORMAT);
      const todayMoment = moment();

      let dateString = '';
      if (todayMoment.format(DATE_FORMAT) === date) {
        dateString = 'Today';
      } else if (todayMoment.clone().add(1, 'day').format(DATE_FORMAT) === date) {
        dateString = 'Tomorrow';
      } else {
        dateString = dateTimeMoment.format('dddd');
      }
      const dateTimeString = `${dateString} ${dateTimeMoment.format('h:mm A')}`;

      return {
        remainingDate: dateTimeString,
        remainingTime: getDayDiff(dateTimeMoment, todayMoment)
      };
    } else {
      return {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextAppointment]);

  const latestActivityLabel = `${
    latestActivity?.object
      ? latestActivity?.object[0]?.toUpperCase() + latestActivity?.object?.substr(1)
      : 'No activity'
  } ${latestActivity?.action ? latestActivity?.action : ''}`;

  const onResendInvitation = async () => {
    if (token) {
      try {
        setIsInvitationProcessing(true);
        await putResendInvitation(token, _id);
        refetchClientRecordList();
        notification.success({
          message: t('form.success.signup_invitation'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setIsInvitationProcessing(false);
      } catch (ex) {
        if (isErrorBentStatusError(ex) && ex.statusCode === 409) {
          notification.destroy();
          notification.error({
            message: t('form.error.signup_invitation_limited_communication'),
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } else {
          console.error(ex);
          notification.error({ message: t('form.error.signup_invitation') });
        }
        setIsInvitationProcessing(false);
      }
    }
  };

  const onCancelInvitation = async () => {
    if (token) {
      try {
        setIsInvitationProcessing(true);
        await putCancelInvitation(token, _id);
        refetchClientRecordList();
        notification.success({
          message: 'Signup invitation is cancelled',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setIsInvitationProcessing(false);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to cancel this sign up invitation' });
        setIsInvitationProcessing(false);
      }
    }
  };

  const getClientColumn = (columnId: string) => {
    const columnStyle = columnStyles[columnId];
    switch (columnId) {
      case ColumnSettingsEnum.CLIENT_SINCE:
        return (
          <div key={columnId} className={classnames(styles.clientSinceSection, columnStyle)}>
            <DateDescBox
              title={moment(createdAt).format(CLIENT_SINCE_FORMAT)}
              desc={`(${getDayDiff(moment(), moment(createdAt))})`}
            />
          </div>
        );
      case ColumnSettingsEnum.PROFILE_TYPE:
        return (
          <div key={columnId} className={classnames(styles.pTypeSection, columnStyle)}>
            <ProfileTypeBadge
              clientProfilesData={clientProfiles}
              recordType={recordType}
              isInvitationProcessing={isInvitationProcessing}
              onResendInvitation={() => onResendInvitation()}
              onCancelInvitation={() => onCancelInvitation()}
              isNotFullSignUp={isNotSignUpDateExists(clientProfiles)}
              canBeFullProfile={canBeFullProfile}
            />
          </div>
        );
      case ColumnSettingsEnum.PRACTITIONER:
        return (
          <div key={columnId} className={classnames(styles.practitionerSection, columnStyle)}>
            {clinicians && (
              <ClinicianAvatar
                name={clinicians.length > 0 ? clinicians[0].name : practiceData?.name}
                avatarUrl={clinicians.length > 0 ? clinicians[0].avatar : practiceData?.logo}
                avatarSize={50}
                showUnassigned={clinicians.length <= 0 && !practiceData?.logo}
                customNameClass={styles.practitionerName}
              />
            )}
          </div>
        );
      case ColumnSettingsEnum.GROUP:
        return (
          <div key={columnId} className={classnames(styles.groupsSection, columnStyle)}>
            <GroupsSection groups={groups} />
          </div>
        );
      case ColumnSettingsEnum.LAST_ACTIVITY:
        return (
          <div key={columnId} className={classnames(styles.activitySection, columnStyle)}>
            <DateDescBox
              title={latestActivityLabel}
              desc={latestActivity?.createdAt ? moment(latestActivity?.createdAt).format(CLIENT_SINCE_FORMAT) : ''}
            />
          </div>
        );
      case ColumnSettingsEnum.NEXT_APPOINTMENT:
        return nextAppointmentLoading ? (
          <div key={columnId} className={styles.appointmentLoadingWrapper}>
            <Skeleton.Input active className={styles.appointmentLoading} />
          </div>
        ) : (
          <div key={columnId} className={classnames(styles.appointmentSection, columnStyle)}>
            <DateDescBox
              title={remainingDate && remainingTime ? remainingDate : 'Not booked'}
              desc={remainingTime ? `(${remainingTime})` : ''}
            />
          </div>
        );
      case ColumnSettingsEnum.APPOINTMENT_ATTENDED:
        return appointmentStatisticsLoading ? (
          <div key={columnId} className={styles.appointmentLoadingWrapper}>
            <Skeleton.Input active className={styles.appointmentLoading} />
          </div>
        ) : (
          <div key={columnId} className={classnames(styles.countAppointmentAttendedSection, columnStyle)}>
            {appointmentStatistics.completedAppointmentsTotal}
          </div>
        );
      case ColumnSettingsEnum.FUTURE_APPOINTMENTS:
        return appointmentStatisticsLoading ? (
          <div key={columnId} className={styles.appointmentLoadingWrapper}>
            <Skeleton.Input active className={styles.appointmentLoading} />
          </div>
        ) : (
          <div key={columnId} className={classnames(styles.countFutureAppointmentSection, columnStyle)}>
            {appointmentStatistics.upcomingAppointmentsTotal}
          </div>
        );
      case ColumnSettingsEnum.LAST_CONSENT_SIGNED:
        return (
          <div key={columnId} className={classnames(styles.lastConsentSignedSection, columnStyle)}>
            {lastConsentForm ? (
              <DateDescBox
                title={lastConsentForm.name}
                desc={moment(lastConsentForm.createdAt).format(CLIENT_SINCE_FORMAT)}
              />
            ) : (
              '-'
            )}
          </div>
        );
      case ColumnSettingsEnum.LAST_PSYCHOMETRIC_COMPLETED:
        return (
          <div key={columnId} className={classnames(styles.lastPsychometricCompletedSection, columnStyle)}>
            {lastPsychometric ? (
              <DateDescBox
                title={lastPsychometric.id}
                desc={moment(lastPsychometric.createdAt).format(CLIENT_SINCE_FORMAT)}
              />
            ) : (
              '-'
            )}
          </div>
        );
      case ColumnSettingsEnum.LAST_SURVEY_COMPLETED:
        return (
          <div key={columnId} className={classnames(styles.surveyCompletedSection, columnStyle)}>
            {lastSurvey ? (
              <DateDescBox title={lastSurvey.name} desc={moment(lastSurvey.createdAt).format(CLIENT_SINCE_FORMAT)} />
            ) : (
              '-'
            )}
          </div>
        );
      case ColumnSettingsEnum.REFERRAL_DATE:
        return (
          <div key={columnId} className={classnames(styles.referralDateSection, columnStyle)}>
            {referrals && referrals?.length > 0 ? (
              <TagsSection tags={referrals.map((referral) => moment(referral.date).format('D MMM YYYY'))} />
            ) : (
              '-'
            )}
          </div>
        );
      case ColumnSettingsEnum.REFERRAL_SOURCE_AND_DETAIL:
        return (
          <div key={columnId} className={classnames(styles.referralSourceSection, columnStyle)}>
            {referrals && referrals?.length > 0
              ? referrals.map((referral, index) => (
                  <TagsSection key={index} tags={[referral.source]} details={referral.detail} />
                ))
              : '-'}
          </div>
        );
      case ColumnSettingsEnum.REFERRAL_STATUSES:
        return (
          <div key={columnId} className={classnames(styles.referralStatusSection, columnStyle)}>
            {referrals && referrals?.length > 0 ? (
              <TagsSection
                tags={referrals.map((referral) => REFERRAL_STATUS_LABELS[referral.status as ReferralStatus].label)}
              />
            ) : (
              '-'
            )}
          </div>
        );
      default:
        break;
    }
  };

  const handleNotClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.container} onClick={onSelectClient}>
      <div className={classnames(styles.clientSection, columnStyles.client)}>
        <ClientAvatar
          clientData={clientProfiles}
          displayFirstNameOnly={recordType === ClientRecordType.Couple}
          displayLimit={recordType === ClientRecordType.Couple ? 2 : 1}
          hideAvatar={!columnSetting?.clientName?.avatar}
          nameClassName={styles.clientName}
          fullAvatarBg
        />
        {clientRecord.tacklitId && <span className={styles.tacklitId}>{clientRecord.tacklitId}</span>}
        {columnSetting?.clientName?.refId && caseId && <div className={styles.caseId}>{caseId}</div>}
        {clientRecord.activePinnedNotes && (
          <PinnedNoteFlags pinnedNotes={clientRecord.activePinnedNotes} clientRecordId={clientRecord._id} />
        )}
      </div>
      {columnSetting?.columns.filter((column) => column.active).map((column) => getClientColumn(column.id))}
      <div
        onClick={(event) => handleNotClick(event)}
        className={classnames(styles.actionsSection, columnStyles.action)}
      >
        <ClientItemMenu
          clientRecord={clientRecord}
          recordStatus={recordStatus}
          profileType={clientProfiles[0].profileType}
        />
        <ButtonAlt variant={'outlined'} icon={'arrow_forward'} size={'medium'} fab onClick={onSelectClient} />
      </div>
    </div>
  );
};

export default ClientItem;
