import classNames from 'classnames';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { useField } from 'formik';
import moment, { Moment } from 'moment';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import ButtonAlt from '../ButtonAlt/ButtonAlt';
import styles from './AvailableTimeSelect.module.scss';
import AvailabilitiesViewTypeToggle, {
  AvailabilitiesViewType
} from './components/AvailabilitiesViewTypeToggle/AvailabilitiesViewTypeToggle';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { getTimezoneLabel } from 'utils/hooks/GetTimezones/getTimezones';
import AppointmentSlotSelect from 'components/AppointmentSlotSelect/AppointmentSlotSelect';
import { SelectedAppointmentSlotProps } from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import { useGetAppointmentTypeAvailableSlotsQuery } from 'redux/endpoints/scheduleServices/appointmentType';

const IS_DEVELOPMENT = isDevelopmentENV();
const MAX_DAYS = 90;

export interface FetchingDates {
  from: string;
  to: string;
}

const DEFAULT_FETCHING_DATES: FetchingDates = {
  from: moment().format(MOMENTJS_FORMAT_DATE),
  to: moment().add(MAX_DAYS, 'd').endOf('isoWeek').format(MOMENTJS_FORMAT_DATE)
};
interface AvailableTimeSelectProps {
  appointmentTypeId: string;
  appointmentTypeName: string;
  showAppointmentTypeName?: boolean;
  selectedSlot?: SelectedAppointmentSlotProps;
  onSelectSlot?: (slot: SelectedAppointmentSlotProps) => void;
  selectedClinicianId?: string;
  setIsCustomising?: Dispatch<SetStateAction<boolean>>;
  containerClassName?: string;
  selectedRoomId?: string;
  selectedGroup?: GroupsFromAPI;
  timeZone?: string;
}

const AvailableTimeSelect = ({
  appointmentTypeId,
  appointmentTypeName,
  selectedClinicianId,
  selectedSlot,
  onSelectSlot,
  setIsCustomising,
  containerClassName,
  selectedRoomId,
  selectedGroup,
  timeZone
}: AvailableTimeSelectProps) => {
  const { isSomeoneHealthFeatureToggle } = useGetFeatureToggle();
  const { isEdgeUserView } = useGetAccountPackageView();
  const [, , dateHelper] = useField('date');
  const [, , startTimeHelper] = useField('startTime');
  const [, , endTimeHelper] = useField('endTime');
  const [, , startDateTimeHelper] = useField('startDateTime');
  const [, , endDateTimeHelper] = useField('endDateTime');

  const [availabilitiesViewType, setAvailabilitiesViewType] = useState<AvailabilitiesViewType>(
    AvailabilitiesViewType.Simple
  );

  const [selectAppointmentTypeId, setSelectedAppointmentTypeId] = useState<string>(appointmentTypeId);
  const [fetchingDates, setFetchingDates] = useState<FetchingDates>(DEFAULT_FETCHING_DATES);
  const [firstDay, setFirstDay] = useState<Moment>(moment());

  /**
   * Reset fetching date if user change appointment type
   */
  useEffect(() => {
    setSelectedAppointmentTypeId(appointmentTypeId);
    setFetchingDates(DEFAULT_FETCHING_DATES);
    setFirstDay(moment());
  }, [appointmentTypeId]);

  const { data, isLoading, isFetching } = useGetAppointmentTypeAvailableSlotsQuery({
    from: fetchingDates.from,
    to: fetchingDates.to,
    type: 'booking',
    appointmentTypeId: selectAppointmentTypeId,
    clinicianId: selectedClinicianId || '',
    selectedRoomId,
    groupId: selectedGroup ? selectedGroup._id : undefined,
    timeZone
  });

  const handleSelectSlot = (slot: SelectedAppointmentSlotProps) => {
    if (onSelectSlot) {
      dateHelper.setValue(slot.date);
      startTimeHelper.setValue(slot.startTime);
      endTimeHelper.setValue(slot.endTime);
      startDateTimeHelper.setValue(slot.startDateTime);
      endDateTimeHelper.setValue(slot.endDateTime);
      onSelectSlot(slot);
    }
  };

  return (
    <div className={classNames(styles.container, containerClassName)}>
      <div className={styles.header}>
        <div className={styles.appointmentNameContainer}>
          <div className={styles.label}>SHOWING OPEN TIMES FOR</div>
          <div className={styles.text}>{appointmentTypeName}</div>
        </div>
        {!!setIsCustomising && (
          <ButtonAlt size={'medium'} variant={'text'} onClick={() => setIsCustomising(true)} icon={'design_services'}>
            Customise
          </ButtonAlt>
        )}
        <div className={styles.toggleContainer}>
          <div className={styles.toggle}>
            <AvailabilitiesViewTypeToggle
              currentViewType={availabilitiesViewType}
              onChange={setAvailabilitiesViewType}
            />
          </div>
        </div>
      </div>
      {(isSomeoneHealthFeatureToggle || IS_DEVELOPMENT) && (
        <div className={styles.timezoneInfo}>
          Time in <b>{getTimezoneLabel(timeZone)}</b>
          <HelpOutLineWithTooltips
            id={'timzone-tooltip'}
            desc={`Availabilities are converted to ${
              isEdgeUserView
                ? 'your'
                : selectedGroup?._id || !selectedClinicianId
                ? `Practitioner's`
                : `Appointment owner's`
            } time zone`}
          />
        </div>
      )}
      <AppointmentSlotSelect
        availabilitiesViewType={availabilitiesViewType}
        appointmentTypeAvailabilities={data}
        selectedSlot={selectedSlot}
        onSelectSlot={handleSelectSlot}
        dayRange={MAX_DAYS}
        isLoading={isLoading || isFetching}
        fetchingDates={fetchingDates}
        setFetchingDates={setFetchingDates}
        firstDay={firstDay}
        setFirstDay={setFirstDay}
      />
    </div>
  );
};

export default AvailableTimeSelect;
