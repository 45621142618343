import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import styles from './PackageSetUp.module.scss';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import {
  FunderLimitType,
  generatePackageType,
  PACKAGES_SEQUENCE_LABELS,
  PackagesBenefit,
  PackagesBenefitLimit,
  PackagesInterface,
  PackagesServiceSequence,
  PackagesType
} from 'interfaces/Packages/packages';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import FunderBadge from 'pages/Packages/components/FunderBadge/FunderBadge';
import Radio from 'components/Radio/Radio';
import PackageTypeBadge from 'pages/Packages/components/PackageTypeBadge/PackageTypeBadge';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import * as yup from 'yup';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetPackageFundersQuery } from 'redux/endpoints/clinicianProfileServices/package';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { config } from 'config/config';

interface PackageSetUpProps {
  packageData: PackagesInterface;
  onChangePackageData: (value: PackagesInterface) => void;
  onSubmit: () => void;
}

export interface PackageModalError {
  name: string;
  type?: string;
  funders?: string;
}

const errorInitState = {
  name: '',
  type: '',
  funders: ''
};

const FUNDER_LIST_PER_PAGE = 50;

const setUpPackageSchema = () =>
  yup.object().shape({
    name: yup.string().required('Please enter the package name'),
    type: yup.string().oneOf(Object.values(PackagesType)).required('Please select package type'),
    funders: yup.array().of(yup.string()).min(1, 'Please select at least one funder').ensure()
  });

export const validationFirstStepForm = (value: PackagesInterface) => {
  const validationErrors = {
    name: '',
    type: '',
    funders: ''
  };

  try {
    setUpPackageSchema().validateSync(value, { abortEarly: false });
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof typeof validationErrors] = error.message;
        }
      });
    }
  }

  return validationErrors;
};

export const PackageSetUp = ({ packageData, onChangePackageData, onSubmit }: PackageSetUpProps) => {
  const { currencySymbol } = config;
  const { accountId } = useGetAccountId();
  const [errors, setErrors] = useState<PackageModalError>(errorInitState);
  const [checkValidation, setCheckValidation] = useState<boolean>(false);
  const [funderListCurrentPage, setFunderListCurrentPage] = useState<number>(1);

  const {
    data: packageFunderData,
    isLoading: packageFunderLoading,
    isFetching: packageFunderFetching
  } = useGetPackageFundersQuery(
    {
      accountId: accountId,
      params: {
        page: funderListCurrentPage,
        perPage: FUNDER_LIST_PER_PAGE
      }
    },
    { skip: !accountId }
  );

  const funderOptions: IOptionItem[] = useMemo(() => {
    return packageFunderData
      ? packageFunderData.funders.map((funderObj) => ({
          label: funderObj.name,
          value: funderObj?.funderId || ''
        }))
      : [];
  }, [packageFunderData]);

  const totalFunderListPage = packageFunderData?.paging
    ? Math.ceil(packageFunderData.paging.total / FUNDER_LIST_PER_PAGE)
    : 1;

  const loadMoreFunder = useCallback(() => {
    const page = packageFunderData?.paging.page || 1;
    if (!packageFunderFetching && page < totalFunderListPage) {
      setFunderListCurrentPage(page + 1);
    }
  }, [totalFunderListPage, packageFunderFetching, packageFunderData]);

  const validateField = useCallback((newVal: PackagesInterface) => {
    const validate = validationFirstStepForm(newVal);
    setErrors(validate);
    return validate;
  }, []);

  const handleFieldChange = useCallback(
    (fieldName: keyof PackagesInterface, value: string) => {
      const updatedField = {
        ...packageData,
        [fieldName]: value
      };
      validateField(updatedField);
      onChangePackageData(updatedField);
    },
    [onChangePackageData, packageData, validateField]
  );

  const onHandleSubmit = useCallback(() => {
    setCheckValidation(true);
    const validate = validateField(packageData);
    const checkFieldHaveError = Object.values(validate).some((value) => value !== '');
    if (!checkFieldHaveError) {
      onSubmit();
    }
  }, [packageData, validateField, onSubmit]);

  const handleBenefitLimit = (value: PackagesBenefitLimit) => {
    onChangePackageData({
      ...packageData,
      benefitControl: {
        ...packageData.benefitControl,
        limitMode: value,
        ...(packageData.benefitControl.by === PackagesBenefit.Funder && value !== PackagesBenefitLimit.None
          ? {
              perFunderLimit: packageData.funders.map((funderObj) => ({
                name: funderObj.name,
                funderId: funderObj.funderId,
                type: funderObj.type,
                limit:
                  (
                    packageData.benefitControl?.perFunderLimit?.find(
                      (limitObj) => limitObj.funderId === funderObj.funderId
                    ) || {}
                  ).limit || 0
              }))
            }
          : {
              limit: 0
            })
      },
      feeChecked: value === PackagesBenefitLimit.DollarSpent ? true : packageData.feeChecked || false,
      fee: packageData.fee || '0'
    });
  };

  const handleSequenceChange = (value: PackagesServiceSequence) => {
    onChangePackageData({
      ...packageData,
      serviceAccessOrder: value
    });
  };

  const handleBenefitControl = (value: PackagesBenefit) => {
    onChangePackageData({
      ...packageData,
      benefitControl: {
        ...packageData.benefitControl,
        by: value,
        limitMode: PackagesBenefitLimit.None,
        funderLimitType: value === PackagesBenefit.Funder ? FunderLimitType.Uniform : undefined
      }
    });
  };

  const handleFunderChange = (value: string) => {
    const isFunderInList = packageData.funders.some((funderObj) => funderObj.funderId === value);
    const getValueDetails = packageFunderData?.funders.find((funderObj) => funderObj.funderId === value);

    let newFunderList = [...packageData.funders];

    if (!isFunderInList && getValueDetails) {
      newFunderList.push({
        name: getValueDetails.name,
        type: getValueDetails.type,
        funderId: getValueDetails.funderId
      });
    } else {
      newFunderList = newFunderList.filter((funderList) => funderList.funderId !== value);
    }

    const updatedField = {
      ...packageData,
      funders: newFunderList,
      benefitControl: {
        ...packageData.benefitControl,
        ...(packageData.benefitControl.by === PackagesBenefit.Funder
          ? {
              perFunderLimit: newFunderList.map((funderObj) => ({
                ...funderObj,
                funderId: funderObj.funderId,
                limit:
                  (
                    packageData.benefitControl?.perFunderLimit?.find(
                      (limitObj) => limitObj.funderId === funderObj.funderId
                    ) || {}
                  ).limit || 0
              }))
            }
          : {
              limit: packageData.benefitControl.limit || 0
            })
      }
    };

    validateField(updatedField);
    onChangePackageData(updatedField);
  };

  const benefitLimitOption = useMemo(
    () =>
      packageData.benefitControl.by === PackagesBenefit.Client
        ? [
            {
              value: PackagesBenefitLimit.None,
              label: 'None'
            },
            {
              value: PackagesBenefitLimit.TotalServices,
              label: 'Services #'
            }
          ]
        : [
            {
              value: PackagesBenefitLimit.None,
              label: 'None'
            },
            {
              value: PackagesBenefitLimit.TotalServices,
              label: 'Total Services #'
            },
            {
              value: PackagesBenefitLimit.NumberOfClients,
              label: 'Client #'
            },
            {
              value: PackagesBenefitLimit.DollarSpent,
              label: `${currencySymbol} Spend`
            }
          ],
    [packageData.benefitControl.by, currencySymbol]
  );

  return (
    <>
      <div className={styles.fieldContainer}>
        <div className={styles.label}>Name</div>
        <div className={styles.dropdownWrapper}>
          <TitleInput
            inputProps={{
              placeholder: 'Name package*',
              value: packageData.name,
              onChange: (e: ChangeEvent<HTMLInputElement>) => handleFieldChange('name', e.target.value)
            }}
            error={checkValidation ? errors.name : ''}
            errorClass={styles.fieldError}
          />
        </div>
        <div className={styles.dropdownWrapper}>
          <TitleInput
            inputProps={{
              placeholder: 'Description',
              value: packageData.description,
              onChange: (e: ChangeEvent<HTMLInputElement>) => handleFieldChange('description', e.target.value)
            }}
          />
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.label}>Configuration</div>
        <div className={styles.dropdownWrapper}>
          <TitleInput
            inputProps={{
              placeholder: 'Billing Code',
              value: packageData.billingCode,
              onChange: (e: ChangeEvent<HTMLInputElement>) => handleFieldChange('billingCode', e.target.value)
            }}
          />
        </div>
        <div className={styles.dropdownWrapper}>
          <DropdownSearchable
            error={checkValidation ? errors.type : ''}
            placeholder={'Select Type*'}
            options={generatePackageType()}
            selected={packageData.type}
            onSelect={(value) => handleFieldChange('type', value)}
            hideActiveLine
            DropdownItemChildren={(itemProps) => <PackageTypeBadge type={itemProps.props.value} />}
            DisplayLabelChildren={(displayProps) => <PackageTypeBadge type={displayProps.props.value} />}
          />
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.label}>Funders</div>
        <div className={styles.dropdownWrapper}>
          <DropdownSearchable
            error={checkValidation ? errors.funders : ''}
            placeholder={'Select Funder*'}
            options={funderOptions}
            selected={packageData.funders.map((obj) => (obj.funderId ? obj.funderId : obj.name))}
            onSelect={(value) => handleFunderChange(value)}
            DisplayLabelChildren={(displayProps) => (
              <FunderBadge className={styles.funderBadge} funder={displayProps.props.label} />
            )}
            isLoading={packageFunderLoading}
            hasMoreData={totalFunderListPage > funderListCurrentPage}
            loadMore={loadMoreFunder}
            isFetchingMore={packageFunderFetching}
            isMultiSelect
            showCheckBox
            hideActiveLine
            searchable
          />
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.label}>Benefit Controls</div>
        <div className={styles.dropdownWrapper}>
          <Radio
            useT23Styles
            name="benefitControl"
            radioClassName={styles.radioClass}
            labelClassName={styles.radioLabelClass}
            value={packageData.benefitControl?.by}
            onChange={(e) => handleBenefitControl(e.target.value as PackagesBenefit)}
            options={[
              {
                value: PackagesBenefit.Client,
                label: 'By Client'
              },
              {
                value: PackagesBenefit.Funder,
                label: 'By Funder'
              }
            ]}
          />
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.label}>Benefit Limits</div>
        <div className={styles.dropdownWrapper}>
          <Radio
            useT23Styles
            name="benefitLimit"
            radioClassName={styles.radioClass}
            labelClassName={styles.radioLabelClass}
            value={packageData.benefitControl?.limitMode}
            onChange={(e) => handleBenefitLimit(e.target.value as PackagesBenefitLimit)}
            options={benefitLimitOption}
          />
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.label}>Service Access</div>
        <div className={styles.dropdownWrapper}>
          <Radio
            useT23Styles
            name="serviceSequence"
            radioClassName={styles.radioClass}
            labelClassName={styles.radioLabelClass}
            value={packageData.serviceAccessOrder}
            onChange={(e) => handleSequenceChange(e.target.value as PackagesServiceSequence)}
            options={[
              {
                value: PackagesServiceSequence.Any,
                label: PACKAGES_SEQUENCE_LABELS[PackagesServiceSequence.Any]
              }
            ]}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonAlt onClick={onHandleSubmit}>Next to set up Appointments</ButtonAlt>
      </div>
    </>
  );
};
