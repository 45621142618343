import { useEffect, useState } from 'react';
import CheckBox from 'components/CheckBox/CheckBox';
import FlexBox from 'components/FlexBox/FlexBox';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import styles from './IncludeDiscount.module.scss';
import MaterialToggleSwitch from 'components/MaterialFieldComponent/MaterialToggleSwitch/MaterialToggleSwitch';
import { config } from 'config/config';

interface IncludeDiscountProps {
  type?: string;
  value?: number;
  onChange: ({ type, value }: { type: string; value: number }) => void;
}

const IncludeDiscount = ({ type, value, onChange }: IncludeDiscountProps) => {
  const MONEY_SYMBOL = config.currencySymbol;
  const [showDiscount, setShowDiscount] = useState(false);
  const [isDiscountInAmount, setIsDiscountInAmount] = useState(false);

  useEffect(() => {
    if (type) {
      setShowDiscount(true);
      setIsDiscountInAmount(type === 'amount');
    }
  }, [type]);

  return (
    <FlexBox direction="row" spacing={20}>
      <CheckBox
        id="showDiscount"
        value={showDiscount}
        label="Include Discount"
        onChange={(e) => {
          setShowDiscount(e.target.checked);
          !e.target.checked && onChange({ type: '', value: 0 });
        }}
      />
      {showDiscount && (
        <FlexBox alignItems="center" direction="row" spacing={16}>
          <FlexBox alignItems="center" direction="row" spacing={8}>
            <span className={styles.label}>%</span>
            <MaterialToggleSwitch
              name={'type'}
              value={type ? type === 'amount' : false}
              customContainerClass={styles.toggleSwitchContainer}
              onChange={() => {
                const currentIsDiscountInAmount = !isDiscountInAmount;
                setIsDiscountInAmount(currentIsDiscountInAmount);
                onChange({ type: currentIsDiscountInAmount ? 'amount' : 'percent', value: value || 0 });
              }}
            />
            <span className={styles.label}>{MONEY_SYMBOL}</span>
          </FlexBox>
          <MaterialInput
            className={styles.input}
            id="value"
            name="value"
            value={value || 0}
            label=""
            onChange={(e) => {
              onChange({ type: isDiscountInAmount ? 'amount' : 'percent', value: Number(e.target.value) });
            }}
            type="number"
            required
          />
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default IncludeDiscount;
