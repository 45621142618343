import { EpisodeStatus } from 'interfaces/Episodes/episodes';

interface CheckIsShouldShowStatusPayload {
  currentEpisodeStatus: EpisodeStatus;
  status: EpisodeStatus;
}

export const checkIsShouldShowStatus = ({ currentEpisodeStatus, status }: CheckIsShouldShowStatusPayload): boolean => {
  switch (currentEpisodeStatus) {
    case EpisodeStatus.Draft: {
      return [EpisodeStatus.Planned, EpisodeStatus.Active].includes(status);
    }
    case EpisodeStatus.Planned: {
      return [EpisodeStatus.Draft, EpisodeStatus.Active].includes(status);
    }
    case EpisodeStatus.Active: {
      return [EpisodeStatus.OnHold, EpisodeStatus.Finished, EpisodeStatus.Cancelled, EpisodeStatus.Error].includes(
        status
      );
    }
    case EpisodeStatus.OnHold: {
      return [EpisodeStatus.Active, EpisodeStatus.Finished, EpisodeStatus.Cancelled, EpisodeStatus.Error].includes(
        status
      );
    }
    case EpisodeStatus.Finished:
    case EpisodeStatus.Cancelled:
    case EpisodeStatus.Archived: {
      return false;
    }
    default: {
      return (
        [
          EpisodeStatus.Draft,
          EpisodeStatus.Planned,
          EpisodeStatus.Active,
          EpisodeStatus.OnHold,
          EpisodeStatus.Finished,
          EpisodeStatus.Cancelled
        ].filter((value) => value !== status).length > 0
      );
    }
  }
};
