/* eslint-disable complexity */
import { notification } from 'antd';
import ModalV2 from 'components/ModalV2/ModalV2';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { EPISODE_STATUS_LABELS, EpisodeInterface, EpisodeStatus } from 'interfaces/Episodes/episodes';
import moment from 'moment';
import { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useCreateEpisodeMutation,
  useGetCurrentActiveEpisodeQuery,
  useGetEpisodeTimeListQuery,
  useUpdateEpisodeMutation
} from 'redux/endpoints/clinicianProfileServices/episode';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import AppointmentDatePicker from 'pages/Calendar/components/CalendarWithHighlightsT23/components/CalendarView/components/InlineBookingModal/components/AppointmentDatePicker/AppointmentDatePicker';
import { EpisodeError, EpisodeFieldNames } from '../../interface';
import { getEpisodeOrder } from '../../utils/episodeOrder';
import { generateEpisodeId } from '../../utils/generateId';
import { isFormValid, validateForm } from '../../utils/validateErrors';
import styles from './EpisodeModal.module.scss';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import EpisodeStatusBadge from '../EpisodeStatusBadge/EpisodeStatusBadge';
import {
  CreateEpisodeProps,
  createEpisodeStatus,
  DEFAULT_EPISODE_ORDER,
  defaultEpisode,
  editEpisodeList,
  episodeTypes,
  generateStatusList,
  medicalReasons,
  reasonUses
} from '../../interface/constants';
import { useGenerateAvailableDate } from '../../hooks/useGenerateAvailableDate';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';
import { MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';

interface EpisodeModalProps {
  mode: 'create' | 'edit';
  visible: boolean;
  onClose: () => void;
  episode?: EpisodeInterface;
  clientRecord: clientRecordsInterface;
  draftOrPlanned?: EpisodeStatus.Draft | EpisodeStatus.Planned;
}

const errorInitState = {
  status: '',
  type: '',
  startDate: '',
  endDate: '',
  currentEpisodeStatus: '',
  currentEpisodeEndDate: '',
  'reason.medicalReason': ''
};

export const EpisodeModal: FC<EpisodeModalProps> = ({
  visible,
  onClose,
  mode,
  episode,
  clientRecord,
  draftOrPlanned
}) => {
  const generateStartDate = (): Date | undefined => {
    if (draftOrPlanned && episode?.status === EpisodeStatus.Active) {
      return new Date();
    }
    if (episode?.startDate) {
      return new Date(episode.startDate);
    }
    return undefined;
  };

  const { accountSettings } = useGetAccountSettings();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeReceptionist, isEdgeAdminView } = useGetAccountPackageView();
  const [episodeFields, setEpisodeFields] = useState<Partial<EpisodeInterface | CreateEpisodeProps>>({
    ...(episode ? episode : defaultEpisode),
    startDate: generateStartDate()
  });

  const { accountTimeZone } = useTimeZone();
  const { data: episodeTimeListData } = useGetEpisodeTimeListQuery({
    clientRecordId: clientRecord._id,
    timezone: accountTimeZone
  });

  const initEPStatus = draftOrPlanned ? draftOrPlanned : episode?.status || EpisodeStatus.Draft;

  const [errors, setErrors] = useState<EpisodeError>(errorInitState);

  const [isShowConfirmButton, setIsShowConfirmButton] = useState<boolean>(false);

  const [processing, setProcessing] = useState<ButtonStatusType>('');
  const [archiveBtnStatus, setArchiveBtnStatus] = useState<ButtonStatusType>('');

  const path = useParams<{ recordId: string; patientTab: string; assessmentOrReportId?: string }>();
  const clientRecordId = path.recordId!;

  const { data: currentActiveData } = useGetCurrentActiveEpisodeQuery({
    clientRecordId: clientRecord._id,
    timezone: accountTimeZone
  });

  const isNotShowActive =
    episodeTimeListData?.some(({ endDate }) => {
      const duration = moment.duration(moment().diff(moment(endDate)));
      return duration.days() <= 0 && duration.months() <= 0 && duration.years() <= 0;
    }) ||
    (!!currentActiveData?.startDate &&
      moment(currentActiveData.startDate).isSame(moment(), 'day') &&
      currentActiveData._id !== episode?._id);

  const [createEpisode] = useCreateEpisodeMutation();
  const [updateEpisode] = useUpdateEpisodeMutation();

  const order = getEpisodeOrder(mode, clientRecord, episode);
  const modalTitle = mode === 'create' ? `Create New Episode #${order}` : `Edit Episode #${order}`;
  const primaryButtonTitle = mode === 'create' ? 'Create Episode' : 'Save Episode Detail';

  const updateCurrentActiveStatus: boolean = (currentActiveData?._id && currentActiveData?.order !== order) || false;

  const handleCreate = async () => {
    try {
      setProcessing('active');
      const validation = validateForm(episodeFields, updateCurrentActiveStatus);

      if (validation && !isFormValid(validation)) {
        setErrors(validation);
        return;
      }

      await createEpisode({
        clientRecordId,
        payload: {
          ...episodeFields,
          startDate: moment(episodeFields.startDate).format(MOMENTJS_FORMAT_DATE),
          ...(episodeFields.endDate && { endDate: moment(episodeFields.endDate).format(MOMENTJS_FORMAT_DATE) }),
          createdBy: isEdgeReceptionist || isEdgeAdminView ? '' : auth0ClinicianId,
          currentEpisodeEndDate: moment(episodeFields.currentEpisodeEndDate).format(MOMENTJS_FORMAT_DATE),
          episodeId: generateEpisodeId(
            clientRecord.episodeSummary?.order ? clientRecord.episodeSummary?.order + 1 : DEFAULT_EPISODE_ORDER
          )
        }
      }).unwrap();
      notification.success({
        message: 'Episode created.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setEpisodeFields(defaultEpisode);
      setProcessing('finished');
      onClose();
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to create the episode.'
      });
    } finally {
      setProcessing('');
    }
  };

  const handleEdit = async () => {
    try {
      setProcessing('active');
      const validation = validateForm(episodeFields, updateCurrentActiveStatus);
      if (validation && !isFormValid(validation)) {
        setErrors(validation);
        return;
      }

      const editPayload = {
        ...episodeFields,
        startDate: moment(episodeFields.startDate).format(MOMENTJS_FORMAT_DATE),
        ...(episodeFields.endDate && { endDate: moment(episodeFields.endDate).format(MOMENTJS_FORMAT_DATE) }),
        ...(episodeFields.currentEpisodeEndDate && {
          currentEpisodeEndDate: moment(episodeFields.currentEpisodeEndDate).format(MOMENTJS_FORMAT_DATE)
        })
      };

      await updateEpisode({
        clientRecordId,
        episodeId: episode?._id || '',
        payload: editPayload
      }).unwrap();

      notification.success({
        message: 'Episode updated.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      setProcessing('finished');
      onClose();
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to edit the episode.'
      });
    } finally {
      setProcessing('');
    }
  };

  const handleArchive = async () => {
    try {
      setArchiveBtnStatus('active');
      const validation = validateForm(episodeFields, updateCurrentActiveStatus);

      if (validation && !isFormValid(validation)) {
        setErrors(validation);
        return;
      }

      const updateArchiveStatusPayload = {
        ...episodeFields,
        startDate: moment(episodeFields.startDate).format(MOMENTJS_FORMAT_DATE),
        status: EpisodeStatus.Archived
      };

      await updateEpisode({
        clientRecordId,
        episodeId: episode?._id || '',
        payload: updateArchiveStatusPayload
      }).unwrap();
      setIsShowConfirmButton(false);
      setProcessing('finished');

      notification.success({
        message: 'Episode status updated.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      onClose();
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to update the episode status.'
      });
    } finally {
      setArchiveBtnStatus('');
    }
  };

  const handleStatusChange = (value: EpisodeStatus) => {
    setErrors(() => errorInitState);
    if (value === EpisodeStatus.Active) {
      if (isNotShowActive) {
        return;
      }
      setEpisodeFields((prev) => ({ ...prev, status: value, startDate: new Date() }));
    } else {
      setEpisodeFields((prev) => ({ ...prev, status: value }));
    }
  };

  const handleFieldChange = (fieldName: keyof EpisodeFieldNames, value: any) => {
    if (fieldName.includes('.')) {
      const subFieldName = fieldName.split('.')[1];

      setErrors((prev) => ({ ...prev, 'reason.medicalReason': '' }));
      setEpisodeFields((prev) => ({
        ...prev,
        reason: {
          ...prev.reason,
          [subFieldName]: value
        }
      }));
    } else {
      setErrors((prev) => ({ ...prev, [fieldName]: '' }));
      setEpisodeFields((prev) => ({ ...prev, [fieldName]: value as EpisodeStatus }));
    }
  };

  const statusOption = (mode === 'create' ? createEpisodeStatus : editEpisodeList(initEPStatus)).map((item) =>
    item.value === EpisodeStatus.Active && isNotShowActive
      ? { ...item, tooltip: 'There is already an episode covering this date, episodes can not overlap.' }
      : item
  );
  const noEditStatus =
    episode?.status && [EpisodeStatus.Error, EpisodeStatus.Finished, EpisodeStatus.Cancelled].includes(episode.status);

  const isPrimaryButtonVisible =
    mode === 'create'
      ? true
      : episode?.status &&
        [EpisodeStatus.Draft, EpisodeStatus.Planned, EpisodeStatus.Active, EpisodeStatus.OnHold].includes(
          episode?.status
        );

  const {
    generateEndDateRange,
    generateStartDateRange,
    minStartDate,
    maxDate,
    newActiveAndOnHoldDateRange,
    editActiveAndOnHoldDateRange
  } = useGenerateAvailableDate(
    clientRecord,
    moment(episodeFields?.startDate).format(MOMENTJS_FORMAT_DATE),
    episodeFields?.currentEpisodeEndDate && moment(episodeFields?.currentEpisodeEndDate).format(MOMENTJS_FORMAT_DATE)
  );

  const { generateEndDateRange: currentEndDateRange } = useGenerateAvailableDate(
    clientRecord,
    moment(currentActiveData?.startDate).format(MOMENTJS_FORMAT_DATE)
  );

  const generateUpdateEPEndDate = episodeFields?.status
    ? [EpisodeStatus.Active].includes(episodeFields?.status)
      ? moment().subtract(1, 'day').format(MOMENTJS_FORMAT_DATE)
      : moment().format(MOMENTJS_FORMAT_DATE)
    : moment().format(MOMENTJS_FORMAT_DATE);

  return (
    <ModalV2 containerClassName={styles.modalContainer} isModalOpen={visible} title={modalTitle} onModalClose={onClose}>
      <div className={styles.container}>
        <div className={styles.label}>Status & Type</div>
        <div className={styles.dropdownWrapper}>
          <DropdownSearchable
            error={errors.status}
            placeholder={'Episode Status'}
            options={statusOption}
            selected={episodeFields.status}
            onSelect={(value) => handleStatusChange(value as EpisodeStatus)}
            disabled={statusOption.length <= 1}
            DisplayLabelChildren={(displayProps) => <EpisodeStatusBadge status={displayProps.props.value} />}
            DropdownItemChildren={(itemProps) => (
              <EpisodeStatusBadge
                isDisable={itemProps.props.value === EpisodeStatus.Active && isNotShowActive}
                status={itemProps.props.value}
              />
            )}
          />
        </div>
        <div className={styles.dropdownWrapper}>
          <DropdownSearchable
            error={errors.type}
            placeholder={accountSettings?.episodeSettings?.typeLabel || 'Episode Type'}
            options={accountSettings?.episodeSettings?.typeOptions || episodeTypes}
            selected={episodeFields.type}
            onSelect={(value) => handleFieldChange('type', value)}
            disabled={noEditStatus}
            hideErrorDesc
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.label}>Reason</div>
        <div className={styles.dropdownWrapper}>
          <DropdownSearchable
            error={errors['reason.medicalReason']}
            placeholder={accountSettings?.episodeSettings?.reasonLabel || 'Medical reason'}
            options={accountSettings?.episodeSettings?.reasonOptions || medicalReasons}
            selected={episodeFields.reason?.medicalReason}
            onSelect={(value) => handleFieldChange('reason.medicalReason', value)}
            disabled={noEditStatus}
            hideErrorDesc
          />
        </div>
        {!accountSettings?.episodeSettings?.reasonUseOptionsDisabled && (
          <div className={styles.dropdownWrapper}>
            <DropdownSearchable
              placeholder={accountSettings?.episodeSettings?.reasonUseLabel || 'Reason use'}
              options={accountSettings?.episodeSettings?.reasonUseOptions || reasonUses}
              selected={episodeFields.reason?.reasonUse}
              onSelect={(value) => handleFieldChange('reason.reasonUse', value)}
              disabled={noEditStatus}
            />
          </div>
        )}
        <div className={styles.dropdownWrapper}>
          <TitleInput
            inputProps={{
              placeholder: accountSettings?.episodeSettings?.reasonDetailsLabel || 'Reason details',
              value: episodeFields.reason?.details,
              onChange: (e: ChangeEvent<HTMLInputElement>) => handleFieldChange('reason.details', e.target.value),
              disabled: noEditStatus
            }}
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.label}>Period</div>
        <div className={styles.dropdownWrapper}>
          <div className={styles.dateTimeWrapper}>
            <AppointmentDatePicker
              error={errors.startDate}
              placeholder="Start date"
              selectedDate={episodeFields?.startDate ? new Date(episodeFields.startDate) : undefined}
              onSelect={(value) => handleFieldChange('startDate', value)}
              hideErrorDesc
              disabled={
                episodeFields.status &&
                (mode === 'edit'
                  ? ![EpisodeStatus.Draft, EpisodeStatus.Planned, EpisodeStatus.OnHold, EpisodeStatus.Active].includes(
                      episodeFields.status
                    )
                  : noEditStatus)
              }
              min={minStartDate}
              max={
                episodeFields.status &&
                [EpisodeStatus.Finished, EpisodeStatus.Cancelled, EpisodeStatus.OnHold, EpisodeStatus.Active].includes(
                  episodeFields?.status
                )
                  ? moment().format(MOMENTJS_YEAR_MONTH_DAY_FORMAT)
                  : maxDate
              }
              invalid={
                episodeFields.status && [EpisodeStatus.OnHold, EpisodeStatus.Active].includes(episodeFields.status)
                  ? mode === 'edit'
                    ? editActiveAndOnHoldDateRange
                    : newActiveAndOnHoldDateRange
                  : generateStartDateRange()
              }
            />
            {episodeFields.status &&
              [EpisodeStatus.Finished, EpisodeStatus.Cancelled, EpisodeStatus.Error].includes(episodeFields.status) && (
                <>
                  <div className={styles.divider}>-</div>
                  <AppointmentDatePicker
                    error={errors.endDate}
                    placeholder="End date"
                    selectedDate={episodeFields?.endDate ? new Date(episodeFields?.endDate) : undefined}
                    onSelect={(value) => handleFieldChange('endDate', value)}
                    hideErrorDesc
                    disabled={noEditStatus}
                    min={generateEndDateRange()[0].start}
                    max={
                      episodeFields.status &&
                      [EpisodeStatus.Finished, EpisodeStatus.Cancelled, EpisodeStatus.Error].includes(
                        episodeFields?.status
                      )
                        ? moment().format(MOMENTJS_YEAR_MONTH_DAY_FORMAT)
                        : generateEndDateRange()[0].end
                    }
                    valid={generateEndDateRange()}
                  />
                </>
              )}
          </div>
        </div>
      </div>
      {(episodeFields.status === EpisodeStatus.Active || episodeFields.status === EpisodeStatus.OnHold) &&
        currentActiveData?._id &&
        updateCurrentActiveStatus && (
          <div className={styles.updateCurrentStatus}>
            <div className={`material-icons-outlined ${styles.icon}`}>info</div>
            <div className={styles.updateContent}>
              <div className={styles.updateDesc}>
                A single client profile can only support one ACTIVE or ON HOLD care episode. You currently have an{' '}
                {EPISODE_STATUS_LABELS[currentActiveData.status].toUpperCase()} episode, please update the status
                accordingly:
              </div>
              <div className={styles.updateActionWrapper}>
                <div className={styles.updateActionContent}>
                  <div className={styles.updateActionTitle}>
                    Episode #{currentActiveData.order} | {currentActiveData.creatorName} started on{' '}
                    {moment(currentActiveData.startDate).format('DD MMM YYYY')}
                  </div>
                  <div className={styles.updateActionType}>Episode Type: {currentActiveData.type}</div>
                </div>
                <div className={styles.updateActionContent}>
                  <div className={styles.updateActionStatus}>UPDATE EPISODE #{currentActiveData.order} STATUS</div>
                  <div className={styles.container}>
                    <div className={styles.dropdownWrapper}>
                      <DropdownSearchable
                        error={errors.currentEpisodeStatus}
                        options={generateStatusList([EpisodeStatus.Finished, EpisodeStatus.Cancelled])}
                        selected={episodeFields?.currentEpisodeStatus}
                        onSelect={(value) => handleFieldChange('currentEpisodeStatus', value)}
                        placeholder={'Change status to'}
                        hideErrorDesc
                        DisplayLabelChildren={(displayProps) => (
                          <EpisodeStatusBadge status={displayProps.props.value} />
                        )}
                        DropdownItemChildren={(itemProps) => <EpisodeStatusBadge status={itemProps.props.value} />}
                      />
                    </div>
                    <div className={styles.dropdownWrapper}>
                      <div className={styles.endDateWrapper}>
                        <div className={`material-icons-outlined ${styles.endDateIcon}`}>info</div>
                        <AppointmentDatePicker
                          error={errors.currentEpisodeEndDate}
                          placeholder="End date"
                          selectedDate={
                            episodeFields?.currentEpisodeEndDate
                              ? new Date(episodeFields.currentEpisodeEndDate)
                              : undefined
                          }
                          onSelect={(value) => {
                            handleFieldChange(
                              'startDate',
                              moment(value).add(1, 'day').format(MOMENTJS_YEAR_MONTH_DAY_FORMAT)
                            );
                            handleFieldChange('currentEpisodeEndDate', value);
                          }}
                          hideErrorDesc
                          disabled={false}
                          min={currentEndDateRange()[0].start}
                          max={generateUpdateEPEndDate}
                          valid={currentEndDateRange()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      <div className={styles.buttonContainer}>
        <div>
          {mode === 'edit' && (
            <ButtonAlt
              className={isShowConfirmButton ? styles.warningButton : ''}
              variant="outlined"
              error
              status={archiveBtnStatus}
              onClick={isShowConfirmButton ? handleArchive : () => setIsShowConfirmButton(true)}
            >
              {!isShowConfirmButton ? 'Archive Episode' : 'Confirm Archive Episode?'}
            </ButtonAlt>
          )}
        </div>
        <div>
          {isPrimaryButtonVisible && (
            <ButtonAlt onClick={() => (mode === 'create' ? handleCreate() : handleEdit())} status={processing}>
              {primaryButtonTitle}
            </ButtonAlt>
          )}
        </div>
      </div>
    </ModalV2>
  );
};
