import { SelectInterface } from 'interfaces/caseNote';
import {
  AgeRangeList,
  AGE_RANGE_LIST_LABELS,
  GenderList,
  GENDER_LIST_LABELS,
  NUMBER_AGE_RANGE_LIST_LABELS,
  SELECT_AGE_RANGE_LIST_LABELS
} from '../interfaces/mentalHealth';

export const standardAgeRangeOptions: SelectInterface[] = Object.keys(AgeRangeList).map((key) => ({
  value: key,
  label: AGE_RANGE_LIST_LABELS[key as keyof typeof AgeRangeList]
}));

export const selectAgeRangeOptions: SelectInterface[] = Object.keys(SELECT_AGE_RANGE_LIST_LABELS).map((item) => {
  return {
    value: item,
    label: SELECT_AGE_RANGE_LIST_LABELS[item as keyof typeof SELECT_AGE_RANGE_LIST_LABELS]
  };
});

export const someoneHealthAgeRangeOptions: SelectInterface[] = Object.keys(NUMBER_AGE_RANGE_LIST_LABELS).map((item) => {
  return {
    value: item,
    label: NUMBER_AGE_RANGE_LIST_LABELS[item as keyof typeof AgeRangeList]
  };
});

export const genderOptions: SelectInterface[] = Object.keys(GenderList).map((key) => ({
  value: key,
  label: GENDER_LIST_LABELS[key as keyof typeof GenderList]
}));
