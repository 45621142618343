import { MedicareDetails, MedicareStatus } from 'interfaces/Clients/clientsRecord';

import styles from './VerificationStatus.module.scss';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

interface VerificationStatusProps {
  medicareOwner?: string;
  status: MedicareDetails['status'];
  isLoading: boolean;
}

const getMedicareStatus = (status: MedicareStatus, medicareOwner?: string) => {
  switch (status.code) {
    // verified
    case 0:
      return (
        <div className={styles.container}>
          <div className={styles.status}>
            <i className={`material-icons ${styles.icon}`}>check_circle</i>
            Medicare {medicareOwner || ''} details valid
          </div>
        </div>
      );
    case 9626:
      return (
        <div className={styles.container}>
          <div className={styles.status}>
            <i className={`material-icons ${styles.icon}`}>check_circle</i>
            Medicare {medicareOwner || ''} details valid
          </div>
          {status.message && <div className={styles.description}>{status.message}</div>}
        </div>
      );

    // partially verified, with some differences that have been saved in the client record
    case 8015:
    case 8016:
    case 8017:
    case 8018:
    case 8019:
    case 8020:
    case 8021:
      return (
        <div className={styles.container}>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconWarning}`}>warning</i>
            Medicare {medicareOwner || ''} details partially valid
          </div>
          {status.message && <div className={styles.description}>{status.message}</div>}
        </div>
      );

    // verification error
    case 9633:
    case 9649:
    case 9650:
    case 9675:
    default:
      return (
        <div className={styles.container}>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Medicare {medicareOwner || ''} details invalid
          </div>
          {status.message && <div className={styles.description}>{status.message}</div>}
        </div>
      );
  }
};

const getDvaStatus = (status: MedicareStatus) => {
  switch (status.code) {
    // verified
    case 0:
      return (
        <div className={styles.container}>
          <div className={styles.status}>
            <i className={`material-icons ${styles.icon}`}>check_circle</i>
            DVA details valid
          </div>
        </div>
      );

    // verification error
    case 9650:
    default:
      return (
        <div className={styles.container}>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            DVA details invalid
          </div>
          {status.message && <div className={styles.description}>{status.message}</div>}
        </div>
      );
  }
};

const VerificationStatus = ({ status, isLoading, medicareOwner }: VerificationStatusProps) => {
  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.status}>
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
          Checking Medicare {medicareOwner || ''} details...
        </div>
      </div>
    );
  }

  if (status) {
    return (
      <>
        {status.medicare && getMedicareStatus(status.medicare, medicareOwner)}
        {status.dva && getDvaStatus(status.dva)}
      </>
    );
  }

  // if no status somehow
  return (
    <div className={styles.container}>
      <div className={styles.status}>
        <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
        Medicare {medicareOwner || ''} details invalid
      </div>
    </div>
  );
};

export default VerificationStatus;
