import { Skeleton, notification } from 'antd';
import MaterialDropdown from 'components/MaterialFieldComponent/MaterialDropdown/MaterialDropdown';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useFetchClinicianMedicareRole } from 'pages/ControlPanel/IntegrationDetails/components/IntegrationDetailsContent/components/IntegrationDetailsContentDisplay/components/Integration/hooks/getClinicianMedicareRole';
import {
  useFetchMedicareLocations,
  useFetchMedicareProviders
} from 'pages/ControlPanel/IntegrationDetails/components/IntegrationDetailsContent/components/IntegrationDetailsContentDisplay/components/Integration/hooks/getMedicareData';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { patchClinicianMedicareRole } from 'utils/http/ClinicianProfileService/Profile/profile';

import AddProviderModal from './components/AddProviderModal/AddProviderModal';
import ProviderCard from './components/ProviderCard/ProviderCard';
import { MEDICARE_ROLES_OPTIONS } from './constants';
import styles from './ProfileMedicare.module.scss';
import { Tooltip } from 'react-tooltip';
import { useParams } from 'react-router-dom';

const ProfileMedicare = () => {
  const { practitionerId: clinicianId } = useParams();

  const { token } = useGetAccessToken();
  const { locations, isLocationsLoading } = useFetchMedicareLocations(token);
  const { role, isRoleLoading, refetchClinicianMedicareRole } = useFetchClinicianMedicareRole(token, clinicianId);
  const { providers, isProvidersLoading, refetchClinicianMedicareProvidersSilently } = useFetchMedicareProviders(
    token,
    false,
    clinicianId
  );
  const [showAddProviderModal, setShowAddProviderModal] = useState(false);

  const activeLocations = locations.filter((location) => location.active);

  const submitRole = async (role: string) => {
    try {
      await patchClinicianMedicareRole(token, role, clinicianId);
      refetchClinicianMedicareRole();
      notification.success({
        message: 'Role updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to update your role' });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Medicare Details</div>
        <div className={styles.description}>
          Add your provider name and ID to enable your client to make a claim through Medicare.
        </div>
      </div>
      <div className={styles.contentContainer}>
        {isRoleLoading ? (
          <Skeleton.Input active className={styles.dropdownLoading} />
        ) : (
          <MaterialDropdown
            id={'role'}
            label={'Your Role Type'}
            dropDownList={MEDICARE_ROLES_OPTIONS}
            selectedValue={role ?? ''}
            onChangeValue={(value) => submitRole(value)}
            placeholder={'Select One'}
          />
        )}
        {isProvidersLoading ? (
          <Skeleton.Input active className={styles.cardLoading} />
        ) : (
          providers.length > 0 && (
            <div className={styles.providersContainer}>
              {providers.map((provider, index) => {
                const foundLocation = locations.find((location) => location.minorId === provider.locationMinorId);
                const populatedProvider = {
                  ...provider,
                  locationName: foundLocation?.name || '-',
                  locationsActive: foundLocation?.active || false
                };
                return (
                  <ProviderCard
                    key={index}
                    provider={populatedProvider}
                    clinicianId={clinicianId}
                    refetchClinicianMedicareProviders={refetchClinicianMedicareProvidersSilently}
                  />
                );
              })}
            </div>
          )
        )}
        <ButtonAlt
          id={'addProviderNumber'}
          onClick={() => setShowAddProviderModal(true)}
          icon={'add_circle_outline'}
          data-tooltip-id="create-provider-button"
          disabled={!role}
          variant={providers.length > 0 ? 'outlined' : 'contained'}
        >
          Add Provider Number
        </ButtonAlt>
        {!role && (
          <Tooltip id="create-provider-button" className={styles.tooltip}>
            Please set your role
          </Tooltip>
        )}
      </div>
      <AddProviderModal
        visible={showAddProviderModal}
        locations={activeLocations}
        isLocationsLoading={isLocationsLoading}
        setShowAddProviderModal={setShowAddProviderModal}
        refetchClinicianMedicareProviders={refetchClinicianMedicareProvidersSilently}
        clinicianId={clinicianId}
      />
    </div>
  );
};

export default ProfileMedicare;
