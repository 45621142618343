import { useState, useEffect } from 'react';
import { notification } from 'antd';

export enum InvoiceAction {
  Draft = 'draft',
  Issued = 'issued',
  Overdue = 'overdue',
  MarkedPaid = 'markedPaid',
  ConfirmPaid = 'confirmPaid',
  Closed = 'closed',
  Resend = 'resend'
}

const MOCK_INVOICE_HISTORY = [
  { date: '2022-04-29', actorName: 'Mr Carl Dean', action: 'issued' },
  { date: '2022-04-30', actorName: 'Mr Carl Dean', action: 'resend' },
  { date: '2022-05-01', actorName: 'Mr Carl Dean', action: 'markedPaid' },
  { date: '2022-05-02', actorName: 'Mr Carl Dean', action: 'closed' }
];

export interface InvoiceHistory {
  date: string;
  action: InvoiceAction;
  actorName: string;
}

export const useFetchInvoiceHistory = (token: string, invoiceId: string) => {
  const [invoiceHistory, setInvoiceHistory] = useState<InvoiceHistory[]>();
  const [isInvoiceHistoryLoading, setIsInvoiceHistoryLoading] = useState(true);

  const fetchInvoiceHistory = async (token: string) => {
    try {
      //Bind later
      setInvoiceHistory(MOCK_INVOICE_HISTORY as InvoiceHistory[]);
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to get your invoice history.',
        duration: 2
      });
    }
    setIsInvoiceHistoryLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchInvoiceHistory(token);
    }
  }, [token]);

  return { invoiceHistory, isInvoiceHistoryLoading, setInvoiceHistory };
};
