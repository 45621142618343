export const convertFileToBase64 = (file: Blob | File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result!.toString().replace('data:application/pdf;base64,', ''));
  });

export const getFilenameAndExtension = (
  fileNameWithExtension: string
): {
  filename: string;
  extension: string;
} => {
  const cleanedFileName = fileNameWithExtension.replace(/^.*[\\/]/, '');

  return {
    filename: cleanedFileName.substring(0, cleanedFileName.lastIndexOf('.')),
    extension: cleanedFileName.split('.').pop() || ''
  };
};
