import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './ReferralSelection.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectSelectedPackage,
  selectSelectedReferral,
  setMedicareReferralDetails,
  setSelectedReferral
} from 'redux/invoices/createInvoiceWithTemplateSlice';
import classNames from 'classnames';
import { useGetReferralListQuery } from 'redux/endpoints/clinicianProfileServices/referral';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { Skeleton } from 'antd';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import moment from 'moment';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { getReferralName } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsProfile/components/PackageAssignment/components/PackageAssignmentModal/PackageAssignmentModal';

const ReferralSelection = ({ clientRecordId }: { clientRecordId: string }) => {
  const { accountId } = useGetAccountId();
  const dispatch = useAppDispatch();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const menuNode = useRef<HTMLDivElement>(null);

  useOnClickOutside(menuNode, () => {
    setIsExpanded(false);
  });

  const selectedReferral = useAppSelector(selectSelectedReferral);
  const selectedPackage = useAppSelector(selectSelectedPackage);

  const {
    data: referralData,
    isLoading: isReferralDataLoading,
    isFetching: isReferralDataFetching
  } = useGetReferralListQuery(
    {
      accountId,
      params: {
        clientRecordIds: clientRecordId
      }
    },
    { skip: !accountId || !clientRecordId }
  );

  useEffect(() => {
    if (!isReferralDataLoading && !isReferralDataFetching && referralData) {
      if (selectedPackage) {
        const foundReferral = referralData.referrals.find(
          (referral) => referral._id === selectedPackage.assignee.referralId
        );
        dispatch(setSelectedReferral(foundReferral));
        dispatch(
          setMedicareReferralDetails({
            name: foundReferral ? getReferralName(foundReferral) : '',
            date: foundReferral?.date
              ? moment(foundReferral.date, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format(MOMENTJS_DATE_FORMAT)
              : '',
            providerNumber: foundReferral?.referrer.providerNumber || ''
          })
        );
      } else {
        dispatch(setSelectedReferral(undefined));
      }
    }
  }, [isReferralDataLoading, isReferralDataFetching, referralData, selectedPackage, dispatch]);

  const onSelectReferral = (id: string) => {
    setIsExpanded(false);
    const selectedReferral = referralData?.referrals.find((item) => item._id === id);
    if (selectedReferral) {
      dispatch(setSelectedReferral(selectedReferral));
      dispatch(
        setMedicareReferralDetails({
          name: getReferralName(selectedReferral),
          date: selectedReferral.date
            ? moment(selectedReferral.date, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format(MOMENTJS_DATE_FORMAT)
            : '',
          providerNumber: selectedReferral?.referrer.providerNumber || ''
        })
      );
    }
  };

  const referralOptions: IOptionItem[] = useMemo(() => {
    if (!isReferralDataLoading && !isReferralDataFetching && referralData?.referrals) {
      return referralData.referrals.map((item, index) => ({
        value: item._id,
        label: `#${index + 1} | ${getReferralName(item)}${
          item.referrer.role && item.referrer.role.trim() ? ` - ${item.referrer.role.trim()}` : ''
        }${
          item.referrer.providerNumber && item.referrer.providerNumber.trim()
            ? ` - GP ${item.referrer.providerNumber.trim()} `
            : ''
        }`,
        subLabel: item.status
      }));
    }
    return [];
  }, [isReferralDataLoading, isReferralDataFetching, referralData?.referrals]);

  return (
    <div ref={menuNode} className={styles.container}>
      <div className={styles.controller}>
        <div>
          {selectedReferral
            ? `${getReferralName(selectedReferral)}${
                selectedReferral.referrer.role && selectedReferral.referrer.role.trim()
                  ? ` - ${selectedReferral.referrer.role.trim()}`
                  : ''
              }${
                selectedReferral.referrer.providerNumber && selectedReferral.referrer.providerNumber.trim()
                  ? ` - GP ${selectedReferral.referrer.providerNumber.trim()} `
                  : ''
              }`
            : 'Select Referral'}
        </div>
        <button
          className={classNames(styles.customButton, styles.changeReferralButton)}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          Change Referral
        </button>
      </div>

      <div className={styles.referralListWrapper}>
        <div className={isExpanded ? styles.referralListShow : styles.referralList}>
          {isReferralDataLoading || isReferralDataFetching ? (
            [...Array(3)].map((_obj, i) => (
              <div key={i} className={styles.loadingBox}>
                <Skeleton.Input active className={styles.loading} />
              </div>
            ))
          ) : referralOptions.length > 0 ? (
            <div className={styles.referrals}>
              {referralOptions.map((item, index) => (
                <button
                  className={classNames(styles.customButton, styles.referralOption)}
                  key={index}
                  onClick={() => onSelectReferral(item.value)}
                >
                  <div className={styles.nameWrapper}>
                    <div>{item.label}</div>
                    <div className={styles.statusPill}>{item.subLabel}</div>
                  </div>
                </button>
              ))}
            </div>
          ) : (
            <div className={styles.noReferralFound}>No referral found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferralSelection;
