import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { FunderNameMapping, InvoiceWithProjectedMbsCode } from 'interfaces/invoices/pendingClaimInvoices';
import moment from 'moment';
import GroupsSection from 'pages/Client/components/ClientListContent/components/ClientList/components/ClientItem/components/GroupsSection/GroupsSection';
import { getClaimInvoiceDetailsInfo } from 'pages/InvoicePendingClaims/utils';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import {
  setCreateClaimModalVisible,
  setIsShowWriteOffModal,
  setSelectedClaimInvoiceId
} from 'redux/features/pendingClaimInvoices/pendingClaimInvoiceSlice';
import { combineName } from 'utils/general';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import PendingClaimInvoiceItemMenu from '../PendingClaimInvoiceItemMenu/PendingClaimInvoiceItemMenu';
import columnStyles from '../PendingClaimInvoiceListHeader/./PendingClaimInvoiceColumn.module.scss';
import styles from './PendingClaimInvoiceItem.module.scss';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import CheckBox from 'components/CheckBox/CheckBox';
import { STATUS_LABELS } from 'pages/Invoices/components/InvoiceListing/components/ClientInvoiceItem/ClientInvoiceItem';
import classNames from 'classnames';

interface PendingClaimInvoiceItemProps {
  invoice: InvoiceWithProjectedMbsCode;
  isExistLocationWithType: boolean;
  onSelect: () => void;
  onSubmitClaim: () => void;
  onResendInvoice: () => void;
  isChecked?: boolean;
  onCheck?: (isChecked: boolean) => void;
  onAbandon: () => void;
}

const PendingClaimInvoiceItem = ({
  invoice,
  isExistLocationWithType,
  onSelect,
  onSubmitClaim,
  onResendInvoice,
  isChecked,
  onCheck,
  onAbandon
}: PendingClaimInvoiceItemProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { CLIENTS } = useRoutesGenerator();

  const { isDvaClient, isMedicareClient } = getClaimInvoiceDetailsInfo(
    invoice.foundClientRecord,
    invoice.foundGeneralPractitioner
  );

  const onSelectInvoice = () => {
    onSelect();
  };

  const onEdit = () => {
    dispatch(setSelectedClaimInvoiceId(invoice._id));
    dispatch(setCreateClaimModalVisible(true));
  };

  const onViewClientProfile = () => {
    if (invoice.clientRecord._id) {
      navigate(`${CLIENTS.BASE}/${invoice.clientRecord._id}/profile`);
    }
  };

  const onWriteOff = () => {
    dispatch(setSelectedClaimInvoiceId(invoice._id));
    dispatch(setIsShowWriteOffModal(true));
  };

  const { foundClientRecord, foundClinician, status, invoiceId, invoiceAmount, mbsCode, funder, foundAppointment } =
    invoice;

  const ableToCreate =
    (isMedicareClient || isDvaClient) &&
    (!isDvaClient || isExistLocationWithType) &&
    moment(foundAppointment.date).isSameOrBefore(moment());

  return (
    <div className={styles.container}>
      <div className={styles.checkboxWrapper}>
        {invoice.isClaimReady && (
          <CheckBox
            useT23style
            id={invoice._id}
            value={!!isChecked}
            onChange={(event) => onCheck && onCheck(event.target.checked)}
          />
        )}
      </div>
      <div className={columnStyles.client}>
        <div className={styles.clientName}>{combineName(foundClientRecord.clientProfiles)}</div>
        <div className={styles.caseId}>{foundClientRecord.caseId}</div>
      </div>
      <div className={columnStyles.practitioner}>
        {foundClinician ? (
          <ClinicianAvatar
            name={foundClinician.name}
            avatarUrl={foundClinician.avatar}
            avatarSize={50}
            customNameClass={styles.practitionerName}
          />
        ) : (
          '-'
        )}
      </div>
      <div className={columnStyles.groups}>
        <GroupsSection groups={invoice.groups} />
      </div>
      <div className={columnStyles.invoiceId}>{invoiceId}</div>
      <div className={columnStyles.date}>{foundAppointment.date}</div>
      <div className={columnStyles.type}>
        <div className={styles.funderPill}>{funder ? FunderNameMapping[funder] : '-'}</div>
      </div>
      <div className={columnStyles.claimCode}>{mbsCode || '-'}</div>
      <div className={columnStyles.invoiceStatus}>
        {status && <div className={classNames(styles.statusPill, styles[status])}>{STATUS_LABELS[status]}</div>}
      </div>
      <div className={columnStyles.amount}>{`$${invoiceAmount.toFixed(2)}`}</div>
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={columnStyles.actions}
      >
        <PendingClaimInvoiceItemMenu
          invoice={invoice}
          ableToCreate={ableToCreate}
          onSubmit={onSubmitClaim}
          onViewClientProfile={onViewClientProfile}
          onResend={onResendInvoice}
          onEdit={onEdit}
          onWriteOff={onWriteOff}
          onAbandon={onAbandon}
        />
        <ButtonAlt variant={'outlined'} icon={'arrow_forward'} size={'medium'} fab onClick={onSelectInvoice} />
      </div>
    </div>
  );
};

export default PendingClaimInvoiceItem;
