import styles from './FunderBadge.module.scss';
import classNames from 'classnames';

export interface FunderBadgeProps {
  funder: string;
  selected?: boolean;
  className?: string;
}

const FunderBadge = ({ funder, selected, className }: FunderBadgeProps) => (
  <div className={classNames(selected ? styles.selected : styles.badge, className)}>{funder}</div>
);

export default FunderBadge;
