import { useEffect, useRef, useState } from 'react';
import styles from './PerRecordDropdown.module.scss';
import { PER_PAGE_DEFAULT } from 'redux/features/utils';

export interface dropdownItem {
  label: string;
  id: number;
}

const DEFAULT_LIST = [
  {
    label: '20',
    id: PER_PAGE_DEFAULT
  },
  {
    label: '50',
    id: 50
  },
  {
    label: '100',
    id: 100
  }
] as dropdownItem[];

interface PerRecordDropdownProps {
  selectedPerPage: number;
  totalItem: number;
  selectDropdownValue: (value: number) => void;
  disabled?: boolean;
}

const PerRecordDropdown = ({ selectedPerPage, totalItem, selectDropdownValue, disabled }: PerRecordDropdownProps) => {
  const dropDownNode = useRef<HTMLDivElement>(null);
  const [listExpand, setListExpand] = useState(false);
  const [perPageList, setPerPageList] = useState(DEFAULT_LIST);
  const [selectedValue, setSelectedValue] = useState<dropdownItem>(DEFAULT_LIST[0]);

  useEffect(() => {
    const foundOptionIndex = DEFAULT_LIST.findIndex(({ id }) => id >= totalItem);
    const pageList = foundOptionIndex < 0 ? DEFAULT_LIST : DEFAULT_LIST.slice(0, foundOptionIndex + 1);
    setPerPageList(pageList);
    setSelectedValue(pageList.find((listObj) => listObj.id === selectedPerPage) || pageList[0]);
  }, [totalItem, selectedPerPage]);

  const handleClick = (e: any) => {
    if (dropDownNode.current?.contains(e.target)) {
      return;
    }
    setListExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleExpandList = () => {
    setListExpand(!listExpand);
  };

  const handleSelectValue = (selectedObj: dropdownItem) => {
    setSelectedValue(selectedObj);
    selectDropdownValue(selectedObj.id);
    setListExpand(false);
  };

  return (
    <div ref={dropDownNode} className={styles.container}>
      <div className={styles.selectedBox} onClick={() => (!disabled ? handleExpandList() : false)}>
        {selectedValue.label}
        <i className={`material-icons ${styles.downIcon}`}>arrow_drop_down</i>
      </div>
      <div className={styles.listWrapper}>
        <div className={listExpand ? styles.listShow : styles.listHide}>
          {perPageList.map((listObj, index) => (
            <div
              key={index}
              className={listObj.id === selectedValue.id ? styles.listItemSelected : styles.listItem}
              onClick={() => handleSelectValue(listObj)}
            >
              {listObj.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PerRecordDropdown;
