import styles from './PatientDetailsPinnedNotes.module.scss';

import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ClientRecordPinnedNote, PinType } from 'interfaces/Clients/clientsRecord';
import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';

interface PatientDetailsPinnedNoteProps {
  note: ClientRecordPinnedNote;
  onUnpin: (noteId: string) => void;
}

const PatientDetailsPinnedNotes = ({ note, onUnpin }: PatientDetailsPinnedNoteProps) => {
  const menuNode = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const { isEdgeAdminUser } = useGetAccountPackageView();
  const { auth0ClinicianId } = useGetClinicianId();

  const [isClamped, setIsClamped] = useState(false);
  const [showConfirmUnpin, setShowConfirmUnpin] = useState(false);
  const [expandContent, setExpandContent] = useState(false);
  const isAssignedPractitioner = note.clinicianAuth0Id === auth0ClinicianId;
  const canUnpinNote = isEdgeAdminUser || isAssignedPractitioner;

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target)) {
      return;
    }
    setShowConfirmUnpin(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  useEffect(() => {
    const checkClamping = () => {
      if (contentRef.current) {
        const element = contentRef.current;
        setIsClamped(element.scrollHeight > element.clientHeight + 2);
      }
    };

    checkClamping();

    window.addEventListener('resize', checkClamping);
    return () => window.removeEventListener('resize', checkClamping);
  }, []);

  return (
    <div className={classNames(styles.container, styles[note.pinType])} ref={menuNode}>
      <div className={styles.note}>
        <i className={`material-icons-outlined ${classNames(styles.pinTypeIcon, styles[note.pinType])}`}>
          {note.pinType === PinType.Risk ? 'flag_circle' : 'notification_important'}
        </i>
        <div ref={contentRef} className={classNames(styles.content, { [styles.ellipsis]: !expandContent })}>
          <strong>
            {note.title}
            {note.title.endsWith('.') ? '' : '.'}
          </strong>
          {'  '}
          <div
            className={styles.body}
            dangerouslySetInnerHTML={{
              __html: note.body
            }}
          />
        </div>
      </div>

      <div className={styles.actions}>
        {!expandContent && isClamped && (
          <i
            onClick={() => setExpandContent(!expandContent)}
            className={`material-icons-outlined ${styles.actionIcon}`}
          >
            close_fullscreen
          </i>
        )}
        {canUnpinNote && (
          <i
            onClick={() => setShowConfirmUnpin(!showConfirmUnpin)}
            className={`material-icons-outlined ${classNames(
              styles.actionIcon,
              !showConfirmUnpin && styles.showOnHover,
              showConfirmUnpin && styles.active
            )}`}
          >
            highlight_off
          </i>
        )}
      </div>

      <div className={styles.menuWrapper}>
        <div className={showConfirmUnpin ? styles.menuShow : styles.menuHide}>
          <div className={styles.menuItem}>
            <ThreeDotMenuItem
              className={styles.confirmMenuItem}
              labelClassName={styles.confirmLabel}
              label="Unpin this note?"
              onClick={() => {
                setShowConfirmUnpin(false);
                onUnpin(note.noteId);
              }}
              postLabelComponent={<strong>YES</strong>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetailsPinnedNotes;
