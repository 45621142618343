import { useState } from 'react';
import styles from './GroupListingContent.module.scss';
import ActiveGroupListCard from './components/ActiveGroupListCard/ActiveGroupListCard';
import ClosedGroupListCard from './components/ClosedGroupListCard/ClosedGroupListCard';
import { GroupListData, Groups, GroupStatus } from 'pages/Groups/Interfaces/Groups';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import SearchBarT23 from 'components/SearchBarT23/SearchBarT23';
import { GroupListFilters, GroupListSorting, resetPaging, setSearch } from 'redux/groups/groupSlice';
import GroupListFilter from '../GroupListFilters/GroupListFilters';
import PaginationListV2 from 'components/v2/PaginationListV2/PaginationListV2';
import { useAppDispatch } from 'redux/hooks';
import { ColumnSettingsEnum, GROUP_LIST_SORT_INFO, SortBy } from './components/constants';

interface GroupListingContentProps {
  groupListing: Groups[];
  isGroupListLoading: boolean;
  onHandleSearch: (q: string) => void;
  isPageLoading: boolean;
  groupStatus: GroupStatus;
  searchValue: string;
  paging: GroupListData['paging'];
  filters: GroupListFilters;
  totalPage: number;
  sorting: GroupListSorting;
  onPageChange: (paging: string, sortBy?: SortBy) => void;
}

const GroupListingContent = ({
  groupListing,
  isGroupListLoading,
  onHandleSearch,
  isPageLoading,
  groupStatus,
  searchValue,
  paging,
  sorting,
  onPageChange
}: GroupListingContentProps) => {
  const listLoading = isGroupListLoading || isPageLoading;
  const dispatch = useAppDispatch();

  const savedSort = localStorage.getItem(GROUP_LIST_SORT_INFO);
  const sort = savedSort ? (JSON.parse(savedSort) as SortBy) : {};
  const [sortBy, setSortBy] = useState({
    groupName: sort.groupName,
    clientSince: sort.clientSince || sort.groupName ? sort.clientSince : 1,
    groupMember: sort.groupMember
  });

  const onSearchHandle = (val: string) => {
    dispatch(resetPaging());
    dispatch(setSearch({ searchValue: val }));
    onHandleSearch(val);
  };

  const groupsContentComponents = () => {
    switch (groupStatus) {
      case GroupStatus.Active:
        return (
          <ActiveGroupListCard
            groupListing={groupListing}
            searchValue={searchValue}
            sorting={sorting}
            onSortGroupListByName={onSortGroupListByName}
            onSortGroupListByClientSince={onSortGroupListByClientSince}
            onSortGroupListByGroupMember={onSortGroupListByGroupMember}
          />
        );
      case GroupStatus.Closed:
        return (
          <ClosedGroupListCard
            groupListing={groupListing}
            searchValue={searchValue}
            sorting={sorting}
            onSortGroupListByName={onSortGroupListByName}
            onSortGroupListByClientSince={onSortGroupListByClientSince}
          />
        );
    }
  };

  const onSortGroupListByName = () => {
    const newSort = {
      groupName: -1 * (sortBy.groupName || 1),
      clientSince: undefined,
      groupMember: undefined
    };
    handleSorting(newSort);
  };

  const onSortGroupListByClientSince = () => {
    const newSort = {
      groupName: undefined,
      clientSince: -1 * (sortBy.clientSince || 1),
      groupMember: undefined
    };
    handleSorting(newSort);
  };

  const onSortGroupListByGroupMember = () => {
    const newSort = {
      groupName: undefined,
      clientSince: undefined,
      groupMember: -1 * (sortBy.groupMember || 1)
    };
    handleSorting(newSort);
  };

  const handleSorting = (newSort: { [key in ColumnSettingsEnum]: number | undefined }) => {
    localStorage.setItem(GROUP_LIST_SORT_INFO, JSON.stringify(newSort));
    setSortBy(newSort);
    onPageChange('1', newSort);
  };

  const setCurrentPage = (page: number) => {
    onPageChange(page.toString());
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <GroupListFilter />

        <div className={styles.searchBarWrapper}>
          <div className={styles.searchBarCard}>
            <SearchBarT23
              placeholder={'Search by group name'}
              value={searchValue}
              onSearch={onSearchHandle}
              withSearchButton
            />
          </div>
        </div>
      </div>
      <div className={styles.cardContainer}>
        {listLoading ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : (
          groupsContentComponents()
        )}
      </div>

      {paging && <PaginationListV2 paging={paging} onPageChange={(page: number) => setCurrentPage(page)} />}
    </div>
  );
};

export default GroupListingContent;
