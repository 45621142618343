import { Link } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { PatientActivity } from '../../ActivityFeed';

import ActivityItem from './components/ActivityItem/ActivityItem';

import styles from './MonthActivityFeed.module.scss';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { getName } from 'utils/general';

interface MonthActivityFeedProps {
  items: PatientActivity[];
  monthString: string;
  clientRecordData: clientRecordsInterface;
  profile: ProfileInterface;
}

const MonthActivityFeed = ({ items, monthString, clientRecordData, profile }: MonthActivityFeedProps) => {
  const { CLIENTS } = useRoutesGenerator();

  return (
    <div className={styles.monthGroup}>
      <div className={styles.monthGroupTitle}>{monthString}</div>
      <div className={styles.monthGroupActivityItems}>
        {items.map((item, index) => {
          const patient = clientRecordData.clientProfiles.find(({ _id }) => _id === item.clientProfileId);
          const patientName = patient ? getName(patient) : 'client';
          return (
            <div key={index} className={styles.activityContainer}>
              {item.action === 'onboarded' ? (
                <Link
                  className={styles.activityLinkContainer}
                  to={`${CLIENTS.BASE}/${clientRecordData._id}/survey/${item.originId}`}
                >
                  <ActivityItem item={item} patientName={patientName} profile={profile} />
                </Link>
              ) : (
                <ActivityItem item={item} patientName={patientName} profile={profile} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonthActivityFeed;
