import { ModalVisibilityContext } from 'components/SideDashboardT23/ModalVisibilityContext';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import SubTab from 'components/v2/SubTab/SubTab';
import { getAddressBookRecordRequestPayload } from 'pages/AddressBook/AddressBookList/components/AddressBookListContent/components/constants';
import { getAddressBookLabelByTab } from 'pages/AddressBook/helpers';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AddressBookTab,
  selectFilters,
  selectPaging,
  selectSearch,
  selectSorting
} from 'redux/addressBook/addressBookSlice';
import { defaultAddressBookList, useGetAddressBooksQuery } from 'redux/endpoints/clinicianProfileServices/addressBook';
import { useAppSelector } from 'redux/hooks';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from '../SubTabLayout.module.scss';

const AddressBookSubTab = () => {
  const location = useLocation();
  const isAddressBooksPage = location.pathname.split('/')[2] === 'address-book';
  const getFilterType: AddressBookTab | string = isAddressBooksPage
    ? (location.pathname.split('/')[3] as AddressBookTab) ?? AddressBookTab.Funder
    : '';
  const { ADDRESS_BOOK } = useRoutesGenerator();
  const { isAddressBookFeatureToggle } = useGetFeatureToggle();
  const { accountId } = useGetAccountId();
  const filters = useAppSelector(selectFilters);
  const search = useAppSelector(selectSearch);
  const sorting = useAppSelector(selectSorting);
  const pagination = useAppSelector(selectPaging);

  const { data: addressBookData = defaultAddressBookList } = useGetAddressBooksQuery(
    {
      accountId,
      params: getAddressBookRecordRequestPayload({
        paging: pagination,
        sorting,
        filters,
        search
      })
    },
    { skip: !accountId || !isAddressBookFeatureToggle }
  );

  const {
    count,
    paging: { totalFilteredItems }
  } = addressBookData;

  const [filterType, setFilterType] = useState<AddressBookTab | string>(getFilterType);

  useEffect(() => {
    setFilterType(getFilterType);
  }, [getFilterType]);

  const modalVisibility = useContext(ModalVisibilityContext);

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <ButtonAlt
          icon="add_circle_outline"
          color="white"
          variant={'contained'}
          fullWidth
          onClick={() => modalVisibility?.setAddAddressBookModalVisible(true)}
        >
          New Entry
        </ButtonAlt>
      </div>
      <div className={styles.section}>
        <SubTab
          title="VIEW BY ROLE"
          options={[
            {
              label: getAddressBookLabelByTab(AddressBookTab.Funder, true),
              number: count.funder,
              value: AddressBookTab.Funder,
              href: `${ADDRESS_BOOK.BASE}/${AddressBookTab.Funder}`,
              matchingRecordCount: totalFilteredItems,
              hideMatchingRecordCount: filterType !== AddressBookTab.Funder || count.funder === totalFilteredItems
            },
            {
              label: getAddressBookLabelByTab(AddressBookTab.Referrer, true),
              number: count.referrer,
              value: AddressBookTab.Referrer,
              href: `${ADDRESS_BOOK.BASE}/${AddressBookTab.Referrer}`,
              matchingRecordCount: totalFilteredItems,
              hideMatchingRecordCount: filterType !== AddressBookTab.Referrer || count.referrer === totalFilteredItems
            }
          ]}
          activeOption={filterType}
        />
      </div>
      <div className={styles.section}>
        <SubTab
          title="VIEW BY TYPE"
          options={[
            {
              label: getAddressBookLabelByTab(AddressBookTab.Organisation, true),
              number: count.organisation,
              value: AddressBookTab.Organisation,
              href: `${ADDRESS_BOOK.BASE}/${AddressBookTab.Organisation}`,
              matchingRecordCount: totalFilteredItems,
              hideMatchingRecordCount:
                filterType !== AddressBookTab.Organisation || count.organisation === totalFilteredItems
            },
            {
              label: getAddressBookLabelByTab(AddressBookTab.Individual, true),
              number: count.individual,
              value: AddressBookTab.Individual,
              href: `${ADDRESS_BOOK.BASE}/${AddressBookTab.Individual}`,
              matchingRecordCount: totalFilteredItems,
              hideMatchingRecordCount:
                filterType !== AddressBookTab.Individual || count.individual === totalFilteredItems
            }
          ]}
          activeOption={filterType}
        />
      </div>
    </div>
  );
};

export default AddressBookSubTab;
