import { BentResponse } from 'bent';
import { LocationType } from 'pages/ControlPanel/Interfaces/Claimingcom';

import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_BILLING_SERVICE_URL;

export const postMedicareIntegration = (token: string) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    '/accounts/me/medicare/integrations'
  ) as Promise<BentResponse>;

export const patchMedicareIntegration = (token: string, payload: { providerNumber: string }) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    '/accounts/me/medicare/integrations',
    payload
  ) as Promise<BentResponse>;

export const postLocation = (
  token: string,
  payload: { name: string; address?: string; type?: LocationType; providerNumber?: string }
) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    '/accounts/me/claimingcom/locations',
    payload
  ) as Promise<BentResponse>;

export const updateLocation = (
  token: string,
  minorId: string,
  payload: { name?: string; address?: string; active?: boolean; type?: LocationType; providerNumber?: string }
) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/me/claimingcom/locations/${minorId}`,
    payload
  ) as Promise<BentResponse>;

export const getLocations = (token: string, qParam?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/claimingcom/locations?${qParam || ''}`
  ) as Promise<BentResponse>;

export const setDefaultLocation = (token: string, minorId: string, value: boolean) =>
  httpClient('POST', url!, 204, {
    Authorization: `Bearer ${token}`
  })(`/accounts/me/claimingcom/locations/${minorId}:setDefault`, { default: value }) as Promise<BentResponse>;

export const postProvider = (
  token: string,
  minorId: string,
  payload: { name: string; providerNumber: string },
  clinicianId?: string
) =>
  httpClient('POST', url!, [201, 400], { Authorization: `Bearer ${token}` })(
    `/clinicians/${clinicianId || 'me'}/claimingcom/locations/${minorId}/providers`,
    payload
  ) as Promise<BentResponse>;

export const getProviders = (token: string, qParam?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/me/claimingcom/providers?${qParam || ''}`
  ) as Promise<BentResponse>;

export const getClinicianProviders = (token: string, clinicianId?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/${clinicianId || 'me'}/claimingcom/providers`
  ) as Promise<BentResponse>;

export const setDefaultProvider = (
  token: string,
  minorId: string,
  providerNumber: string,
  value: boolean,
  clinicianId?: string
) =>
  httpClient('POST', url!, 204, {
    Authorization: `Bearer ${token}`
  })(`/clinicians/${clinicianId || 'me'}/claimingcom/locations/${minorId}/providers/${providerNumber}:setDefault`, {
    default: value
  }) as Promise<BentResponse>;

export const updateProvider = (
  token: string,
  minorId: string,
  providerNumber: string,
  payload: { name?: string; address?: string; active?: boolean },
  clinicianId?: string
) =>
  httpClient('PATCH', url!, [204, 400], { Authorization: `Bearer ${token}` })(
    `/clinicians/${clinicianId || 'me'}/claimingcom/locations/${minorId}/providers/${providerNumber}`,
    payload
  ) as Promise<BentResponse>;

export const deleteProvider = (token: string, minorId: string, providerNumber: string, clinicianId?: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/${clinicianId || 'me'}/claimingcom/locations/${minorId}/providers/${providerNumber}`
  ) as Promise<BentResponse>;

export const getClaims = (token: string, clientRecordId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/claims`
  ) as Promise<BentResponse>;

export const confirmClaimSubmission = (token: string, minorId: string, providerNumber: string, claimId: string) =>
  httpClient('POST', url!, [200, 400], { Authorization: `Bearer ${token}` })(
    `/clinicians/me/claimingcom/locations/${minorId}/providers/${providerNumber}/claims/${claimId}:confirmClaimSubmission`
  ) as Promise<BentResponse>;

export const getClaimSummary = (token: string, clientRecordId: string, qParam: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/claims/summary?${qParam}`
  ) as Promise<BentResponse>;

export const createClaim = (token: string, minorId: string, providerId: string, payload: Record<string, unknown>) =>
  httpClient('POST', url!, [201, 400, 404, 500, 503], { Authorization: `Bearer ${token}` })(
    `/clinicians/me/claimingcom/locations/${minorId}/providers/${providerId}/claims`,
    payload
  ) as Promise<BentResponse>;

export const getClaimHistory = (token: string, claimId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/:accountId/claims/${claimId}/history`
  ) as Promise<BentResponse>;
