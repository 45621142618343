import styles from './SessionMode.module.scss';
import { AppointmentType, DeliveryType } from 'interfaces/Schedule/AppointmentType';

type SessionModeProps = {
  deliveryOptions: AppointmentType['deliveryOptions'];
};

const SessionMode = ({ deliveryOptions }: SessionModeProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>MODE</div>
      <div className={styles.deliveryTypeOptions}>
        <i
          className={`material-icons ${
            deliveryOptions.includes(DeliveryType.FaceToFace) ? styles.active : styles.inactive
          }`}
        >
          supervisor_account
        </i>
        <i
          className={`material-icons ${
            [DeliveryType.PhoneCall, DeliveryType.PhoneCallDialClient].some((deliveryType) =>
              deliveryOptions.includes(deliveryType)
            )
              ? styles.active
              : styles.inactive
          }`}
        >
          call
        </i>
        <i
          className={`material-icons-outlined ${
            deliveryOptions.includes(DeliveryType.VideoCall) ? styles.active : styles.inactive
          }`}
        >
          videocam
        </i>
        <i
          className={`material-icons-outlined ${
            deliveryOptions.includes(DeliveryType.Other) ? styles.active : styles.inactive
          }`}
        >
          connect_without_contact
        </i>
      </div>
    </div>
  );
};

export default SessionMode;
