import { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import * as yup from 'yup';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import Radio from 'components/Radio/Radio';

import './ShareSideBox.scss';
import styles from './ShareSideBox.module.scss';
import { useTranslation } from 'react-i18next';

const emailSchema = yup.array().of(yup.string().email());

interface ShareSideBoxProps {
  data: any;
  isClientShared: boolean;
  allowCommunicationWithEmail: boolean | undefined;
  errorsData?: any;
  onChangeReportDetails: any;
}

const ShareSideBox = ({
  data,
  isClientShared,
  allowCommunicationWithEmail,
  onChangeReportDetails,
  errorsData
}: ShareSideBoxProps) => {
  const [errors, setErrors] = useState(errorsData);
  const [noteValue, setNoteValue] = useState(data.note);
  const [otherEmailValue, setOtherEmailValue] = useState(data.sendToInfo.otherEmail);
  const [formData, setFormData] = useState(data);
  const [t] = useTranslation();

  useEffect(() => {
    setFormData(data);
    setErrors(errorsData);
    setNoteValue(data.note);
    setOtherEmailValue(data.sendToInfo.otherEmail);
  }, [data, errorsData]);

  const onChangeSendToDetails = (key: string, val: any) => {
    onChangeReportDetails({
      sendToInfo: {
        ...data.sendToInfo,
        [key]: val
      }
    });
  };

  const onChangeSendTime = (key: string, val: any) => {
    onChangeReportDetails({
      sendTimeInfo: {
        ...data.sendTimeInfo,
        [key]: val
      }
    });
  };

  const onChangeNote = (val: any) => {
    setNoteValue(val);
    onChangeReportDetails({
      note: val
    });
  };

  const handleCustomSendInvoiceTimeChange = (value: Moment | null) => {
    onChangeSendTime('customSendTime', value ? value.format('YYYY-MM-DD HH:mm') : '');

    if (value) {
      if (errors.customSendInvoiceTime) {
        setErrors({ ...errors, customSendInvoiceTime: undefined });
      }
    }
  };

  const handleEmailsChange = (val: string) => {
    setOtherEmailValue(val);
    onChangeReportDetails({
      sendToInfo: {
        ...data.sendToInfo,
        otherEmail: val
      }
    });

    if (errors.emails) {
      const emailsArray = val.split(';').filter((email) => !!email);

      if (emailsArray.length === 0) {
        setErrors({ ...errors, emails: 'Please enter a list of semi-colon-separated emails' });
      } else {
        try {
          emailSchema.validateSync(emailsArray);
          setErrors({ ...errors, emails: undefined });
        } catch (ex) {
          setErrors({ ...errors, emails: 'Please enter emails in the correct format' });
        }
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.questionWrapper}>
        <div className={styles.title}>
          Send
          <HelpOutLineWithTooltips
            id={'sendSection'}
            desc={'Decide whether to send the report or letter now or set a time for it to be sent.'}
          />
        </div>
        <Radio
          className={styles.radioContainer}
          options={[
            { value: 'now', label: 'Now' },
            { value: 'custom', label: 'Custom' }
          ]}
          value={formData.sendTimeInfo.sendInvoiceType}
          vertical
          onChange={(e) => onChangeSendTime('sendInvoiceType', e.target.value)}
        />
        {formData.sendTimeInfo.sendInvoiceType === 'custom' && (
          <>
            <DatePicker
              className="send-invoice-date-picker"
              disabledDate={(current) => current && current < moment().startOf('day')}
              suffixIcon={<i className={`material-icons icon`}>arrow_drop_down</i>}
              showTime={{ format: 'HH:mm', minuteStep: 15 }}
              value={
                formData.sendTimeInfo.customSendTime
                  ? moment(formData.sendTimeInfo.customSendTime, 'YYYY-MM-DD HH:mm')
                  : undefined
              }
              onChange={(e) => handleCustomSendInvoiceTimeChange(e)}
            />
            <ErrorMessage
              className={styles.sendError}
              error={errors.customSendInvoiceTime}
              visible={!!errors.customSendInvoiceTime}
            />
          </>
        )}
      </div>
      <div className={styles.questionWrapper}>
        <div className={styles.title}>
          Send To
          <HelpOutLineWithTooltips
            id={'sendToSection'}
            desc={
              'Add any recipients here and they will receive an email with access to the report. You can remove access at any time.'
            }
          />
        </div>
        <label className={styles.checkboxContainer} htmlFor="checkbox-sendTo-client">
          <input
            className={styles.checkbox}
            id="checkbox-sendTo-client"
            type="checkbox"
            checked={!allowCommunicationWithEmail && !isClientShared ? false : formData.sendToInfo.isClientCheck}
            disabled={isClientShared || !allowCommunicationWithEmail}
            onChange={(e) => onChangeSendToDetails('isClientCheck', e.target.checked)}
          />
          <div className={styles.text}>
            {t('label.client.capitalize')}
            {!allowCommunicationWithEmail && !isClientShared && (
              <HelpOutLineWithTooltips id={'sendToClient'} desc={t('form.error.share_report_limited_communication')} />
            )}
          </div>
        </label>
        <label className={styles.checkboxContainer} htmlFor="checkbox-sendTo-other">
          <input
            className={styles.checkbox}
            id="checkbox-sendTo-other"
            type="checkbox"
            checked={formData.sendToInfo.isOtherCheck}
            onChange={(e) => onChangeSendToDetails('isOtherCheck', e.target.checked)}
          />
          <div className={styles.text}>Other</div>
        </label>
        {formData.sendToInfo.isOtherCheck && (
          <div className={styles.inputContainer}>
            <MaterialInput
              id={'emailAddress'}
              label="Email address"
              value={otherEmailValue}
              onChange={(e) => handleEmailsChange(e.target.value)}
            />
            <ErrorMessage error={errors.emails} visible={!!errors.emails} />
          </div>
        )}
        <ErrorMessage className={styles.sendToError} error={errors.sendTo} visible={!!errors.sendTo} />
      </div>
      <div className={styles.includeNoteContainer}>
        <div className={styles.noteTitle}>
          Include a intro note for recipients
          <HelpOutLineWithTooltips
            id={'noteSection'}
            desc={
              'This note will go into the email providing access to the report or letter. This gives you a chance to quickly provide context to the subject of the report and why they are receiving it'
            }
          />
        </div>
        <textarea
          className={styles.textarea}
          rows={4}
          maxLength={160}
          value={noteValue}
          onChange={(e) => onChangeNote(e.target.value)}
        />
        <span className={styles.wordCount}>{noteValue.length} / 160</span>
      </div>
    </div>
  );
};

export default ShareSideBox;
