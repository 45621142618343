import { Modal } from 'antd';
import classNames from 'classnames';
import ModalHeader from 'components/v2/ModalHeader/ModalHeader';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EventForm from './components/EventForm/EventForm';
import styles from './EventCreationModal.module.scss';
import { ClientRecordListResponse } from 'interfaces/Clients/clientRecordNew';
import { useTimeZone } from 'utils/hooks/useTimeZone';

interface EventCreationModalProps {
  visible: boolean;
  date?: Date;
  defaultClient?: clientRecordsInterface | ClientRecordListResponse['clientRecords'][number];
  defaultGroupId?: string;
  onClose: () => void;
  onCreateEditSuccess?: () => void;
}
const EventCreationModal = ({
  visible,
  date,
  defaultClient,
  defaultGroupId,
  onClose,
  onCreateEditSuccess
}: EventCreationModalProps) => {
  const [t] = useTranslation();
  const [step, setStep] = useState<number>(1);
  const { timeZoneByView } = useTimeZone();

  return (
    <Modal
      bodyStyle={{
        padding: 0,
        borderRadius: '16px'
      }}
      width={820}
      open={visible}
      footer={null}
      destroyOnClose
      closable={false}
      className={styles.modal}
    >
      <div className={classNames(styles.container, styles[`group${0}`])}>
        <ModalHeader
          title={step === 1 ? 'New' : 'Select Date and Time'}
          onCancel={onClose}
          currentStep={step}
          onBack={() => setStep(1)}
          backLabel={t('label.change_client_or_appointment')}
        />
        <div>
          <EventForm
            step={step}
            date={date}
            defaultClient={defaultClient}
            defaultGroupId={defaultGroupId}
            setStep={setStep}
            onClose={onClose}
            onCreateEditSuccess={onCreateEditSuccess}
            defaultTimeZone={timeZoneByView}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EventCreationModal;
