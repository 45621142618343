import LoadingDot from 'components/LoadingDot/LoadingDot';
import styles from './PatientDetailsReferrers.module.scss';

import { useGetReferralListQuery } from 'redux/endpoints/clinicianProfileServices/referral';
import { SortType } from 'redux/features/utils';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import ReferralsMVP from './components/ReferralsMVP/ReferralsMVP';
import ReferralsV2 from './components/ReferralsV2/ReferralsV2';

interface PatientDetailsReferrersProps {
  recordId: string;
}

const PatientDetailsReferrers = (props: PatientDetailsReferrersProps) => {
  const { recordId } = props;
  const { accountId } = useGetAccountId();
  const { isReferralV2Enabled } = useGetFeatureToggle();

  const { data, isLoading, isFetching } = useGetReferralListQuery({
    accountId,
    params: {
      clientRecordIds: recordId,
      ...(isReferralV2Enabled ? { sortBy: 'createdAt', sortType: SortType.DESC } : {})
    }
  });

  return isLoading || isFetching ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>
      {isReferralV2Enabled ? (
        <ReferralsV2 recordId={recordId} referrals={data?.referrals || []} />
      ) : (
        <>
          <ReferralsMVP recordId={recordId} referrals={data?.referrals || []} />
        </>
      )}
    </div>
  );
};

export default PatientDetailsReferrers;
