import { notification } from 'antd';
import { DEFAULT_TEMPLATE } from 'pages/AssessmentDetails/CaseNoteTemplate/hooks/useGetCaseNoteTemplate';
import { CaseNoteTemplate, ParticipantType } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import { useEffect, useState } from 'react';
import { getAssignedCaseNotesListing } from 'utils/http/DocumentService/CaseNotes/caseNotesTemplate';

const useGetCaseNoteTemplatesList = (token: string, participantType: ParticipantType, adminView: boolean) => {
  const [isLoading, setIsLoading] = useState(false);
  const [templatesList, setTemplatesList] = useState<CaseNoteTemplate[]>([]);
  const [currentTemplate, setCurrentTemplate] = useState<CaseNoteTemplate>(DEFAULT_TEMPLATE);

  const fetchTemplatesList = async () => {
    setIsLoading(true);
    try {
      const list = (await (
        await getAssignedCaseNotesListing(token, participantType, adminView)
      ).json()) as CaseNoteTemplate[];
      setTemplatesList(list);

      const defaultTemplate = list.find((obj) => obj.isDefault);
      if (defaultTemplate) setCurrentTemplate(defaultTemplate);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to get your case note templates'
      });
    }
    setIsLoading(false);
  };

  const selectTemplate = (id: string) => {
    setIsLoading(true);
    const selected = templatesList?.find((obj) => obj._id === id);
    if (!selected) return setIsLoading(false);

    setCurrentTemplate(selected);
    setIsLoading(false);
    return selected;
  };

  useEffect(() => {
    if (token) {
      fetchTemplatesList();
    }
    // eslint-disable-next-line
  }, [token]);

  return { templatesList, currentTemplate, selectTemplate, isLoading };
};

export default useGetCaseNoteTemplatesList;
