import { PracticeInterface } from 'interfaces/Profile/Profile';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClinician } from 'utils/http/clinician';

export interface Clinician {
  _id: string;
  avatar: string;
  bio: string;
  name: string;
  title: string;
  specialisations: [string];
  partOfPractice: boolean;
  practice?: PracticeInterface;
  practices: {
    name: string;
    mobileNumber: string;
    streetAddress: string;
    postcode: string;
    logo: string;
    locations: string[];
  }[];
}

export const useFetchClinician = (clinicianId?: string) => {
  const [clinician, setClinician] = useState<Clinician | undefined>();
  const [isClinicianLoading, setIsClinicianLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClinician = async () => {
      setIsClinicianLoading(true);
      setClinician(undefined);
      try {
        if (!clinicianId) {
          setIsClinicianLoading(false);
          return;
        }

        const callGetClinician = await getClinician(clinicianId);
        const data = await callGetClinician.json();

        setClinician(data);
        setIsClinicianLoading(false);
      } catch (ex) {
        console.error(`User redirected from ${window.location.pathname} to /404`);
        navigate('/404');
      }
    };

    fetchClinician();
  }, [clinicianId, navigate]);

  return { clinician, isClinicianLoading };
};
