/* eslint-disable complexity */
import { Skeleton, notification } from 'antd';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import AppointmentTypeSelect, { AppointmentTypeId } from 'components/v2/AppointmentTypeSelect/AppointmentTypeSelect';
import AvailableTimeSelect from 'components/v2/AvailableTimeSelect/AvailableTimeSelect';
import { SelectedAppointmentSlot } from 'components/v2/AvailableTimeSelect/components/DayCard/DayCard';
import LoadingButton from 'components/v2/Button/Button';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import DeliveryModeSelect from 'components/v2/DeliveryModeSelect/DeliveryModeSelect';
import FormSection from 'components/v2/FormSection/FormSection';
import { Formik } from 'formik';
import { CreatedAppointmentType, DeliveryType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import moment from 'moment';
import RoomOption from 'pages/Calendar/components/Modals/EventCreationModal/components/EventForm/components/ScheduleForm/components/RoomOption/RoomOption';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import {
  postAcceptAppointmentBookingRequest,
  postDeclineAppointmentBookingRequest,
  postSuggestAppointmentBookingRequest
} from 'utils/http/appointment';
import { AppointmentBookingRequest } from '../../../../Notification';

import { AppointmentValues } from '../AppointmentRequest/AppointmentRequest';
import ChangeNote from '../ChangeNote/ChangeNote';
import styles from './AppointmentBookingRequestV2.module.scss';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { getDeliveryTypeLabel } from 'utils/appointment';

interface AppointmentBookingRequestV2Props {
  appointmentBookingRequestData: AppointmentBookingRequest['appointmentBookingRequests'][number];
  token: string;
  onRefreshNotifications: () => void;
  assignedClinician?: PractitionersDetailsInterface;
}

const AppointmentBookingRequestV2 = ({
  appointmentBookingRequestData: {
    _id,
    clientRecord,
    date,
    startTime,
    endTime,
    clinicianId,
    isExpired,
    isClashedWithOtherAppointment
  },
  token,
  onRefreshNotifications,
  assignedClinician
}: AppointmentBookingRequestV2Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { isEdgeUser, isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const asAdmin = isEdgeAdminView || isEdgeReceptionistView;
  const { timeZoneByView } = useTimeZone();

  const [declineState, setDeclineState] = useState<'' | 'decline' | 'suggest'>('');
  const [showMenu, setShowMenu] = useState(false);

  const [appointmentType, setAppointmentType] = useState<CreatedAppointmentType>();
  const [selectedSlot, setSelectedSlot] = useState<SelectedAppointmentSlot>();
  const [note, setNote] = useState('');

  const [acceptButtonState, setAcceptButtonState] = useState<'' | 'active' | 'finished'>('');
  const [declineButtonState, setDeclineButtonState] = useState<'' | 'active' | 'finished'>('');

  const handleClickOutSide = (event: any) => {
    if (menuRef.current?.contains(event.target)) {
      return;
    }
    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const handleDeclineButtonClick = () => {
    setShowMenu(!showMenu);
  };

  const handleDeclineWithNote = () => {
    setDeclineState('decline');
    setShowMenu(false);
  };

  const handleSuggestNewTime = () => {
    setDeclineState('suggest');
    setShowMenu(false);
  };

  const handleChangeSlot = (slot?: SelectedAppointmentSlot) => {
    setSelectedSlot(slot);
  };

  const handleChangeAppointmentType = (appointmentTypeId: AppointmentTypeId | undefined) => {
    setAppointmentType(appointmentTypeId);
    setSelectedSlot(undefined);
  };

  const handleAcceptRequest = async () => {
    setAcceptButtonState('active');

    try {
      await postAcceptAppointmentBookingRequest(_id, token, asAdmin);
      setAcceptButtonState('finished');
      notification.success({
        message: 'Succesfully accepted the appointment booking request'
      });
      onRefreshNotifications();
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to accept this appointment request. Please try again'
      });
      setAcceptButtonState('');
    }
  };

  const handleSubmitDecline = async (values: AppointmentValues) => {
    setDeclineButtonState('active');
    const {
      deliveryType,
      faceToFaceLocation,
      videoCallInstructions,
      phoneCallInstructions,
      phoneCallDialClientInstructions,
      otherInstructions,
      roomInfo
    } = values;

    if (declineState === 'suggest') {
      try {
        await postSuggestAppointmentBookingRequest(
          _id,
          {
            slot: { ...selectedSlot, timeZone: assignedClinician?.workTimeZone || timeZoneByView },
            sessionTypeId: appointmentType?._id || '',
            note,
            deliveryType,
            faceToFaceLocation: deliveryType === DeliveryType.FaceToFace ? faceToFaceLocation : undefined,
            videoCallInstructions: deliveryType === DeliveryType.VideoCall ? videoCallInstructions : undefined,
            phoneCallInstructions: deliveryType === DeliveryType.PhoneCall ? phoneCallInstructions : undefined,
            phoneCallDialClientInstructions:
              deliveryType === DeliveryType.PhoneCallDialClient ? phoneCallDialClientInstructions : undefined,
            otherInstructions: deliveryType === DeliveryType.Other ? otherInstructions : undefined,
            roomInfo: roomInfo ? JSON.parse(roomInfo) : undefined
          },
          token,
          asAdmin
        );
        setDeclineButtonState('finished');
        notification.success({
          message: 'Succesfully suggested the new time for the appointment'
        });
        onRefreshNotifications();
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to suggest a new time. Please try again' });
        setDeclineButtonState('');
      }
    }

    if (declineState === 'decline') {
      try {
        await postDeclineAppointmentBookingRequest(_id, { note }, token, asAdmin);
        setDeclineButtonState('finished');
        notification.success({
          message: 'Succesfully declined the appointment'
        });
        onRefreshNotifications();
      } catch (ex) {
        notification.error({
          message: 'Something went wrong while trying to decline this appointment request.'
        });
        setDeclineButtonState('');
      }
    }
  };

  const [t] = useTranslation();

  return (
    <Formik
      initialValues={{
        deliveryType: '',
        faceToFaceLocation: '',
        videoCallInstructions: '',
        phoneCallInstructions: '',
        phoneCallDialClientInstructions: '',
        otherInstructions: { title: '', instructions: '' },
        roomRequired: '',
        roomInfo: ''
      }}
      onSubmit={() => {}}
    >
      {({ values }) => (
        <>
          <div className={styles.container}>
            <div className={styles.title}>
              Request:{' '}
              {clientRecord.recordStatus === 'waitlist' ? t('label.prospect_client') : t('label.existing_client')}{' '}
              {isExpired ? '(Expired)' : isClashedWithOtherAppointment && '(Clashing with another appointment)'}
            </div>
            <div className={styles.content}>
              <div className={styles.clientInfoWrapper}>
                <div className={styles.avatarAndName}>
                  <ClientAvatar
                    clientData={clientRecord.clientProfiles}
                    avatarSize={40}
                    isEllipsisName={false}
                    displayFirstNameOnly={clientRecord.recordType === 'couple'}
                    displayLimit={2}
                    nameClassName={styles.name}
                    initialsClassName={styles.name}
                    containerClassName={styles.containerClassName}
                  />
                </div>

                {asAdmin && clinicianId && (
                  <div className={styles.avatarAndName}>
                    {!assignedClinician ? (
                      <Skeleton.Button active />
                    ) : (
                      <ClinicianAvatar
                        avatarSize={40}
                        isEllipsisName={false}
                        avatarUrl={assignedClinician.avatar}
                        customNameClass={styles.name}
                        customContainerClass={styles.containerClassName}
                        name={assignedClinician.name}
                      />
                    )}
                  </div>
                )}

                <div className={styles.dateTimeWrapper}>
                  <span className={styles.date}>{moment(date).format('dddd DD MMMM')}</span>
                  <span className={styles.time}>
                    {startTime} - {moment(endTime, 'HH:mm').format('HH:mmA')}
                  </span>
                </div>
                {values.deliveryType && (
                  <div className={styles.deliveryType}>
                    <span>{getDeliveryTypeLabel(values.deliveryType, values.otherInstructions)}</span>
                  </div>
                )}
              </div>
              <div className={styles.actionWrapper}>
                {acceptButtonState === 'finished' && declineButtonState === 'finished' && (
                  <div className={styles.statusWrapper}>
                    {acceptButtonState === 'finished' && (
                      <div className={styles.booked}>
                        <i className={`material-icons-outlined ${styles.statusIcon}`}>check_circle_outline</i>
                        Booked
                      </div>
                    )}
                    {declineButtonState === 'finished' &&
                      (declineState === 'decline' ? (
                        <div className={styles.declined}>
                          <i className={`material-icons-outlined ${styles.statusIcon}`}>highlight_off</i>
                          Appointment Declined
                        </div>
                      ) : (
                        <div className={styles.suggested}>
                          <i className={`material-icons-outlined ${styles.statusIcon}`}>check_circle_outline</i>
                          New Time Suggested
                        </div>
                      ))}
                  </div>
                )}
                {acceptButtonState !== 'finished' && declineButtonState !== 'finished' && (
                  <>
                    <ButtonAlt
                      size={'medium'}
                      className={styles.acceptButton}
                      status={acceptButtonState}
                      onClick={handleAcceptRequest}
                      disabled={isExpired || isClashedWithOtherAppointment}
                    >
                      Accept
                    </ButtonAlt>
                    <div ref={menuRef} className={styles.declineButtonContainer}>
                      <ButtonAlt
                        className={styles.declineButton}
                        size={'medium'}
                        variant={'outlined'}
                        onClick={handleDeclineButtonClick}
                      >
                        {declineState === '' && (
                          <>
                            Decline
                            <i className={`material-icons ${styles.declineIcon}`}>arrow_drop_down</i>
                          </>
                        )}
                        {declineState === 'decline' && 'Decline appointment'}
                        {declineState === 'suggest' && 'Suggesting new time'}
                      </ButtonAlt>
                      <div className={styles.menuWrapper}>
                        <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
                          <div className={styles.listBox} onClick={handleDeclineWithNote}>
                            <div className={styles.listTitle}>Decline with note</div>
                          </div>
                          <div className={styles.listBox} onClick={handleSuggestNewTime}>
                            <div className={styles.listTitle}>Reschedule</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {declineState === 'suggest' && (
              <>
                <AppointmentTypeSelect
                  clinicianId={clinicianId || ''}
                  hideTitle
                  horizontal
                  onSelect={handleChangeAppointmentType}
                  selectedParticipantType={appointmentType?.participantType || ParticipantType.OneToOne}
                />
                {appointmentType && (
                  <AvailableTimeSelect
                    appointmentTypeId={appointmentType._id}
                    appointmentTypeName={appointmentType.name}
                    onSelectSlot={handleChangeSlot}
                    selectedSlot={selectedSlot}
                    selectedClinicianId={clinicianId || ''}
                    timeZone={assignedClinician?.workTimeZone || timeZoneByView}
                  />
                )}
                {appointmentType?.deliveryOptions && (
                  <div className={styles.deliveryModeContainer}>
                    <FormSection title="Delivery Mode">
                      <div className={styles.deliveryModeContainer}>
                        <DeliveryModeSelect appointmentType={appointmentType} showAdditionalInput />
                      </div>
                    </FormSection>
                  </div>
                )}
                {isEdgeUser && values.deliveryType === DeliveryType.FaceToFace && appointmentType && (
                  <div className={styles.roomOptionContainer}>
                    <RoomOption appointmentType={appointmentType} />
                  </div>
                )}
              </>
            )}
            {(declineState === 'decline' || declineState === 'suggest') && (
              <>
                <ChangeNote note={note} onNoteChange={setNote} />
                <LoadingButton
                  disabled={
                    declineState === 'suggest' &&
                    (!selectedSlot || !values.deliveryType || (values.roomRequired === 'yes' && !values.roomInfo))
                  }
                  className={styles.submitButton}
                  status={declineButtonState}
                  onClick={() => handleSubmitDecline({ ...values, deliveryType: values.deliveryType || '' })}
                >
                  {declineState === 'decline' ? 'Decline Appointment' : 'Suggest New Appointment Time'}
                </LoadingButton>
              </>
            )}
          </div>
        </>
      )}
    </Formik>
  );
};

export default AppointmentBookingRequestV2;
