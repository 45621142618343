import { useState } from 'react';
import styles from './ConsentDetails.module.scss';
import moment from 'moment';
import { ConsentFormListInterface } from 'interfaces/ConsentForm/ConsentFormListInterface';
import ConsentDetailsModal from './components/ConsentDetailsModal/ConsentDetailsModal';
import { clientRecordsEncryptedInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';
import SendConsentModal from './components/SendConsentModal/SendConsentModal';
import { getName } from 'utils/general';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { Tooltip } from 'react-tooltip';
import { t } from 'i18next';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

interface ConsentDetailsProps {
  clientRecordData: clientRecordsEncryptedInterface;
  consentListData?: ConsentFormListInterface[];
  notificationSettings: NotificationSettingsInterface;
}

const ConsentDetails = ({ clientRecordData, consentListData, notificationSettings }: ConsentDetailsProps) => {
  const [showConsentForm, setShowConsentForm] = useState('');
  const [showSendConsent, setShowSendConsent] = useState(false);
  const onSendAdhocConsent = () => {
    setShowSendConsent(true);
  };
  const isSendingDisabled =
    clientRecordData.clientProfiles.every(
      (profile) =>
        profile.role === 'child' ||
        !(
          (profile.hasEmail &&
            canClientReceiveEmail({
              communicationPreference: profile.communicationPreference,
              notificationSettings,
              notificationSettingsCategory: 'forms'
            })) ||
          (profile.hasMobileNumber &&
            canClientReceiveSms({
              communicationPreference: profile.communicationPreference,
              notificationSettings,
              notificationSettingsCategory: 'forms'
            }))
        )
    ) || clientRecordData.recordStatus === RecordStatus.Closed;
  return (
    <div className={styles.container}>
      <div className={styles.title}>CONSENT FORM</div>
      <div className={styles.consentContent}>
        {consentListData?.map((consentObj, index) => {
          const clientData = clientRecordData.clientProfiles.find(
            (clientObj) => clientObj._id === consentObj.clientProfileId
          );
          const clientName = clientData ? getName(clientData) : '';
          return (
            <div key={index}>
              <div className={styles.consentList}>
                <div className={styles.consentName}>
                  <i className={`material-icons ${styles.gpCheckIcon}`}>done</i>
                  {consentObj.name}
                </div>
                <div className={styles.consentDetails}>
                  <div className={styles.consentDate}>
                    Signed {moment(consentObj.createdAt).format(MOMENTJS_DATE_FORMAT)}
                  </div>
                  <ButtonAlt
                    size={'small'}
                    variant={'text'}
                    onClick={() => setShowConsentForm(consentObj._id)}
                    icon={'visibility'}
                    iconPostFix
                  >
                    View
                  </ButtonAlt>
                </div>
              </div>
              <ConsentDetailsModal
                visible={consentObj._id === showConsentForm}
                onCancel={() => setShowConsentForm('')}
                consentFormDetail={consentObj}
                clientName={clientName}
              />
            </div>
          );
        })}

        <div data-tooltip-id="sendAssessmentBtn">
          <ButtonAlt
            className={styles.sendAdhocBtn}
            size={'medium'}
            variant={'outlined'}
            onClick={onSendAdhocConsent}
            disabled={isSendingDisabled}
            icon={'send'}
            iconVariant={IconVariant.Filled}
          >
            Send consent form
          </ButtonAlt>
          {isSendingDisabled && (
            <Tooltip id="sendAssessmentBtn" className={styles.tooltip}>
              <>{t('form.error.send_consent_limited_communication')}</>
            </Tooltip>
          )}
        </div>

        <SendConsentModal
          visible={showSendConsent}
          clientProfileList={clientRecordData.clientProfiles}
          clientRecordId={clientRecordData._id}
          onCancel={() => setShowSendConsent(false)}
          notificationSettings={notificationSettings}
        />
      </div>
    </div>
  );
};

export default ConsentDetails;
