import Pill from 'components/Pill/Pill';
import styles from './AppointmentSection.module.scss';
import Button from 'components/v2/Button/Button';
import classNames from 'classnames';
import Appointment from './components/Appointment/Appointment';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectAppointments,
  selectAppointmentTypeItems,
  selectCarePlanEditorSlice,
  setEvents
} from 'redux/carePlan/carePlanEditorSlice';
import { useMemo, useState } from 'react';
import CarePlanNewAppointment from '../CarePlanNewAppointment/CarePlanNewAppointment';
import { useResetAllCarePathwayAppointmentMutation } from 'redux/endpoints/scheduleServices/reservedAppointment';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { Toast } from '@mobiscroll/react';
import { scheduleServicesApiSlice, SSTagTypes } from 'redux/services/scheduleServicesApiSlice';
import { useGetPackageQuery } from 'redux/endpoints/scheduleServices/package';
import { PackagesBenefit, PackagesBenefitLimit } from 'interfaces/Packages/packages';

const AppointmentSection = () => {
  const { accountId } = useGetAccountId();
  const dispatch = useAppDispatch();
  const carePlanData = useAppSelector(selectCarePlanEditorSlice);
  const appointmentTypeList = useAppSelector(selectAppointmentTypeItems);
  const appointmentEvent = useAppSelector(selectAppointments);

  const [resetCarePathwayAppointment] = useResetAllCarePathwayAppointmentMutation();
  const [toastMessage, setToastMessage] = useState('');
  const [isToastOpen, setToastOpen] = useState(false);

  const mappedAppointmentTypeList = useMemo(() => {
    const appointedEventIds = appointmentEvent.map((event) => event.carePathwayItemId);
    return appointmentTypeList.map((appointmentType) => {
      return {
        ...appointmentType,
        ...(appointmentType._id && {
          active: !appointedEventIds.includes(appointmentType._id)
        })
      };
    });
  }, [appointmentTypeList, appointmentEvent]);

  const onReset = async () => {
    try {
      const result = await resetCarePathwayAppointment({
        accountId,
        carePathwayId: carePlanData._id || ''
      });

      if ('data' in result) {
        dispatch(setEvents([]));
        dispatch(scheduleServicesApiSlice.util.invalidateTags([SSTagTypes.AppointmentList]));
        setToastMessage('All appointment are unscheduled');
        setToastOpen(true);
      }
    } catch (error: any) {
      console.error('Fail to remove appointment from calendar:', error.message);
    }
  };

  const { data: packageData } = useGetPackageQuery(
    {
      accountId: accountId,
      packageId: carePlanData.package?.id || '',
      params: {
        expandAppointmentTypes: '1'
      }
    },
    { skip: !carePlanData.package?.id }
  );

  const eligibleBalance = useMemo(() => {
    const appointmentUsedBalance = appointmentTypeList.length - appointmentEvent.length;

    const countBalance =
      packageData?.benefitControl.by === PackagesBenefit.Client &&
      packageData?.benefitControl.limitMode === PackagesBenefitLimit.TotalServices
        ? packageData?.benefitControl.limit
        : appointmentUsedBalance;

    return typeof countBalance === 'string' ? parseInt(countBalance, 10) : countBalance || 0;
  }, [packageData?.benefitControl, appointmentTypeList, appointmentEvent]);

  const eligibleAddNewAppointment = useMemo(() => {
    return appointmentEvent.length < eligibleBalance;
  }, [appointmentEvent, eligibleBalance]);

  return (
    <div className={styles.container}>
      {carePlanData.package && (
        <div className={styles.packageRule}>
          <i className={classNames('material-icons-outlined', styles.packageRuleIcon)}>info</i>
          <div>
            This care pathway uses a Package. Please check the appointment allocation carefully to align with package
            rules
          </div>
        </div>
      )}
      <div className={styles.title}>
        <div className={styles.left}>
          <div>APPOINTMENTS</div>
          <Pill className={styles.activePill}>{appointmentEvent.length} added</Pill>
          <Pill>{eligibleBalance} to add</Pill>
        </div>
        <div>
          <Button variant="link" className={styles.resetBtn} onClick={onReset}>
            Reset
          </Button>
        </div>
      </div>
      <div className={styles.subtitle}>
        <i className={classNames('material-icons-outlined', styles.icon)}>info</i>
        <div>Drag & Drop appointments</div>
      </div>
      <div className={styles.list}>
        {mappedAppointmentTypeList.map((appointmentTypeObj, i) => (
          <div key={i} className={styles.item}>
            <Appointment
              carePlanAppointment={appointmentTypeObj}
              eligibleAddNewAppointment={carePlanData.package ? eligibleAddNewAppointment : true}
            />
          </div>
        ))}
      </div>
      {!carePlanData?.package?.id && <CarePlanNewAppointment />}
      <Toast isOpen={isToastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
    </div>
  );
};

export default AppointmentSection;
