import moment from 'moment';
import Handlebars from 'handlebars';
import { formatHeadingTemplateBodyString } from 'utils/helpers/formatHeadingTemplateBodyString';

export const useGenerateHeadingTemplateDetails = (token: string, groupId: string) => {
  //TODO: get Appointments, Assessment of Group

  const generateHeadingTemplate = async (body: string) => {
    const fixedBody = formatHeadingTemplateBodyString(body);
    const regex1 = /<span[^<>]*class="mention"[^<>]*>(.*?)<\/span>{{|{{/gm;
    const regex2 = /}}<\/span>\ufeff<\/span>|}}/gm;
    const generateBodyPart1 = fixedBody.replace(regex1, '{{{[');
    const generateBodyPart2 = generateBodyPart1.replace(regex2, ']}}}');
    const headingTemplate = Handlebars.compile(generateBodyPart2);

    const variableList = {
      GROUP_NAME: `TEST`, // TODO: Group name from Group detail
      FIRST_APPOINTMENT_DATE: moment().format('dddd DD MMMM YYYY \\at hh:mmA'), // TODO: Group's first appointment date
      RECENT_APPOINTMENT_DATE: moment().format('dddd DD MMMM YYYY \\at hh:mmA'), // TODO: Group's recent appointment date
      APPOINTMENT_COUNT: 0, // TODO: Group's total appointments
      FIRST_ASSESSMENT: {}, // TODO: Group's first assessment
      FIRST_ASSESSMENT_DATE: {}, // TODO: Group's first assessment date
      RECENT_ASSESSMENT: {}, // TODO: Group's recent assessment
      RECENT_ASSESSMENT_DATE: {} // TODO: Group's recent assessment date
    };

    return headingTemplate(variableList);
  };

  return { generateHeadingTemplate };
};
