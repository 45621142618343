import { useGetAccountPackage } from '../GetAccountInfo/accountPackage';

export const useInitPathGenerator = () => {
  const { isEdgePlanUser, isEdgeAdminUser, isEdgeUser, isEdgeReceptionist, isMentorUser } = useGetAccountPackage();

  if (isEdgePlanUser) {
    if (isEdgeAdminUser) {
      return '/admin';
    }
    if (isEdgeUser) {
      return '/user';
    }
    if (isEdgeReceptionist) {
      return '/receptionist';
    }
    if (isMentorUser) {
      return '/mentor';
    }
  } else {
    return '';
  }
};
