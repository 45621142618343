import { useEffect, useMemo, useState } from 'react';
import { Report, ReportContactDetails } from 'interfaces/Reports/report';
import moment from 'moment';

import styles from './ReportContentHeader.module.scss';
import ReportHeaderEdit from './components/ReportHeaderEdit/ReportHeaderEdit';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { combineName } from 'utils/general';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { useTranslation } from 'react-i18next';
import {
  ClientRecordType,
  ProfileRole,
  clientProfilesInterface,
  clientRecordsInterface
} from 'interfaces/Clients/clientsRecord';
import { ClinicianDetails } from 'interfaces/Letters/letter';
import { Skeleton } from 'antd';

interface ReportContentHeaderProps {
  clinicianDetails: ClinicianDetails;
  clinicianContactData: ReportContactDetails;
  onChangeContactDetails?: (info: ReportContactDetails) => void;
  isPreview?: boolean;
  reportName: string;
  clientRecord?: Pick<clientRecordsInterface, 'recordType'> & {
    clientProfiles: Pick<clientProfilesInterface, 'firstName' | 'lastName' | 'name' | 'role'>[];
  };
  issueDate: Report['issueDate'];
  type: 'Report' | 'Letter';
  clientDetailsLoading?: boolean;
}

const ReportContentHeader = ({
  reportName,
  clientRecord,
  clinicianDetails,
  clinicianContactData,
  onChangeContactDetails,
  isPreview,
  issueDate,
  type,
  clientDetailsLoading
}: ReportContentHeaderProps) => {
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [isEditHeadingOpen, setIsEditHeadingOpen] = useState(false);
  const [t] = useTranslation();
  const [contactDetail, setContactDetail] = useState(clinicianContactData);

  const handleChangeContactDetails = (cDInfoVal: ReportContactDetails) => {
    onChangeContactDetails?.(cDInfoVal);
    setContactDetail(cDInfoVal);
  };

  useEffect(() => {
    if (clinicianContactData.clinician.email.value) {
      // refresh if previously is empty
      setContactDetail((data) => (data.clinician.email.value === '' ? clinicianContactData : data));
    }
  }, [clinicianContactData]);

  const clientPrimaryName = useMemo(() => {
    if (clientRecord) {
      const primaryClientProfileName = [ClientRecordType.Child, ClientRecordType.YoungPerson].includes(
        clientRecord.recordType
      )
        ? clientRecord.clientProfiles.filter(
            ({ role }) => role && [ProfileRole.Child, ProfileRole.YoungPerson].includes(role)
          )
        : clientRecord.clientProfiles;

      return clientRecord?.clientProfiles
        ? combineName(primaryClientProfileName, clientRecord.recordType === 'couple')
        : '-';
    }
  }, [clientRecord]);

  return (
    <div className={styles.container} id={'reportHeaderId'}>
      <div className={styles.practiceInfoWrapper}>
        <div className={styles.practiceCard}>
          {clinicianDetails.practice && (
            <>
              {clinicianDetails.practice.logo && (
                <div className={styles.practiceImgWrapper}>
                  <img src={clinicianDetails.practice.logo} alt={'practice logo'} className={styles.practiceImg} />
                </div>
              )}
              {isPreview ? (
                <div className={styles.contactDetailInfoPreview}>
                  <span className={styles.name}>{clinicianDetails.practice.name}</span>
                  <div className={styles.infoPreviewWrapper}>
                    {contactDetail.practice.address.isVisible && (
                      <div className={styles.detailInfo}>{contactDetail.practice.address.value}</div>
                    )}
                    {contactDetail.practice.mobileNumber.isVisible && (
                      <div className={styles.detailInfo}>{contactDetail.practice.mobileNumber.value}</div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.contactDetailInfo} onClick={() => setIsEditHeadingOpen(true)}>
                  <span className={styles.name}>{clinicianDetails.practice.name}</span>
                  <div className={!isEdgeReceptionist ? styles.infoWrapper : styles.infoWrapperNoHover}>
                    <div className={styles.editBadge}>
                      <div className={styles.editIconWrapper}>
                        <i className={`material-icons ${styles.icon}`}>mode</i>
                      </div>
                    </div>
                    {contactDetail.practice.address.isVisible && (
                      <div className={styles.detailInfo}>{contactDetail.practice.address.value}</div>
                    )}
                    {contactDetail.practice.mobileNumber.isVisible && (
                      <div className={styles.detailInfo}>{contactDetail.practice.mobileNumber.value}</div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.patientInfoWrapper}>
        <div className={styles.infoWrap}>
          {clientDetailsLoading ? (
            <Skeleton.Input active className={styles.loadingBar} />
          ) : (
            <>
              <span className={styles.infoTitle}>{t('form.client_name')}:</span> {clientPrimaryName}
            </>
          )}
        </div>
        <div className={styles.infoWrap}>
          <span className={styles.infoTitle}>{type === 'Report' ? 'Report Name' : 'Letter Title'}:</span>{' '}
          {reportName || '-'}
        </div>
        <div className={styles.infoWrap}>
          <span className={styles.infoTitle}>{type === 'Report' ? 'Date of report' : 'Letter Date'}:</span>{' '}
          {moment(issueDate, 'YYYY-MM-DD').format(MOMENTJS_DATE_FORMAT)}
        </div>
      </div>
      {!isEdgeReceptionist && (
        <ReportHeaderEdit
          visible={isEditHeadingOpen}
          onCloseModal={() => setIsEditHeadingOpen(false)}
          clinicianDetails={clinicianDetails}
          clinicianContactDetails={contactDetail}
          onChangeContactDetails={handleChangeContactDetails}
        />
      )}
    </div>
  );
};

export default ReportContentHeader;
