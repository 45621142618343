import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getClinicianProfile = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })('/profile') as Promise<BentResponse>;

export const getClinicianProfileById = (token: string, clinicianId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/${clinicianId}/profile`
  ) as Promise<BentResponse>;

export const updateClinicianProfile = (token: string, body: Record<string, any>, clinicianId?: string) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `${clinicianId ? `/clinicians/${clinicianId}` : ''}/profile`,
    body
  );

export const patchClinicianMedicareRole = (token: string, role: string, clinicianId?: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(`/clinicians/${clinicianId || 'me'}/medicare`, {
    role
  });

export const uploadHelmControlPhoto = (token: string, formData: FormData, clinicianId?: string) =>
  new Promise((resolve, reject) => {
    fetch(`${url}/clinicians/${clinicianId || 'me'}/helmControl:uploadImage`, {
      method: 'POST',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((res) => {
        if (res.status === 201) {
          resolve(res);
        } else {
          reject(res.statusText);
        }
      })
      .catch((err) => reject(err));
  }) as Promise<{ [key: string]: any }>;

export const updateClinicianHelmControl = (token: string, body: Record<string, any>, clinicianId?: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/${clinicianId || 'me'}/helmControl`,
    body
  ) as Promise<BentResponse>;

export const updateClinicianEnforceMfa = (token: string, clinicianId: string, body: { enforceMfa: boolean }) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/${clinicianId}:enforceMfa`,
    body
  ) as Promise<BentResponse>;

export const getUnclaimedAdSignUp = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    '/clinicians/me:getUnclaimedAdSignup'
  ) as Promise<BentResponse>;

export const claimAdSignUp = (token: string, payload: Record<string, any>) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    '/clinicians/me:claimAdSignup',
    payload
  ) as Promise<BentResponse>;
