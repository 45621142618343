import { useEffect, useMemo, useState } from 'react';

import { EditClientDetails, editClientForm } from '../../EditClientModalInterface';
import AdultClientForm from 'components/AddPatientModalV2/formType/AdultForm/components/AdultClientForm/AdultClientForm';
import { useFetchSignedUpProfile } from '../../hooks/CheckSignUpProfile';
import { useGetAccessToken } from 'utils/hooks/token';
import styles from './EditAdultForm.module.scss';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { debounce } from 'lodash';
import { validateCaseId } from '../../../AddPatientModalV2/formType/AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import { newClientForm } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import { ClientAddress, clientProfilesInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';
import ProfileStatusButtons from '../../components/profileStatusButtons';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import InfoCTABox from 'components/InfoCTABox/InfoCTABox';
import { TIMEZONE_OPTIONS } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/ProfileWorkingSchedule/ProfileWorkingSchedule';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface EditAdultFormProps {
  clientRecordId: string;
  clientDetail: editClientForm['clientProfiles'];
  onChangeClientField: (val: editClientForm['clientProfiles']) => void;
  checkValidation: boolean;
  submitStatus: '' | 'active' | 'finished';
  caseIdValue: editClientForm['caseId'];
  onChangeCaseId: (value: editClientForm['caseId']) => void;
  onClickSubmit: (options?: { withTriage?: boolean }) => void;
  excludeEmailCheckingGroup?: string[];
  excludeCaseIdChecking?: string;
  profileType: clientProfilesInterface['profileType'];
  onChangeProfileType: (val: clientProfilesInterface['profileType']) => void;
  recordStatus: editClientForm['recordStatus'];
  onStatusProfileChange: (val: 'active' | 'waitlist' | 'closed') => void;
  onClickSaveClosedClient: () => void;
  showOnboardingCTA: boolean;
  noUnsavedChanges?: boolean;
  timeZone: string;
  onChangeTimeZone: (value: editClientForm['timeZone']) => void;
  clientAddress: ClientAddress;
  onChangeClientAddress: (address: ClientAddress) => void;
  onPlaceSelected: (address: ClientAddress) => void;
}

const EditAdultForm = ({
  clientRecordId,
  clientDetail,
  onChangeClientField,
  checkValidation,
  submitStatus,
  caseIdValue,
  onChangeCaseId,
  onClickSubmit,
  excludeEmailCheckingGroup,
  excludeCaseIdChecking,
  profileType,
  recordStatus,
  onStatusProfileChange,
  onClickSaveClosedClient,
  showOnboardingCTA,
  noUnsavedChanges,
  timeZone,
  onChangeTimeZone,
  clientAddress,
  onChangeClientAddress,
  onPlaceSelected
}: // onChangeProfileType
EditAdultFormProps) => {
  const { token } = useGetAccessToken();
  const { signedUpProfiles, isSUPLoading } = useFetchSignedUpProfile(token, clientRecordId);
  const [duplicateCaseId, setDuplicateCaseId] = useState(false);
  const [isCheckingCaseIdDuplicate, setIsCheckingCaseIdDuplicate] = useState(false);
  const { isClientTimeZoneEnabled } = useGetFeatureToggle();

  useEffect(() => {
    const isSameCaseIdValue = excludeCaseIdChecking === caseIdValue;
    if (checkValidation && caseIdValue && caseIdValue.length > 0 && !isSameCaseIdValue) {
      validateDuplicateCaseId(caseIdValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const handleChangeClientField = (val: EditClientDetails) => {
    const newClientField = [];
    for (let obj of clientDetail) {
      newClientField.push({
        ...obj,
        firstName: val.firstName,
        lastName: val.lastName,
        dateOfBirth: val.dateOfBirth,
        email: val.email,
        isEmailThirdParty: val.isEmailThirdParty || false,
        mobileNumber: val.mobileNumber,
        isMobileNumberThirdParty: val.isMobileNumberThirdParty || false,
        communicationPreference: val.communicationPreference
      });
    }
    onChangeClientField(newClientField);
  };

  const debouncedCheckCaseIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const resDupCaseId = await validateCaseId(token, value);
        setDuplicateCaseId(resDupCaseId.statusCode !== 200);
        setIsCheckingCaseIdDuplicate(false);
      }, 1000),
    [token]
  );

  const validateDuplicateCaseId = async (newCaseIdValue: string) => {
    if (token) {
      setIsCheckingCaseIdDuplicate(true);
      await debouncedCheckCaseIdDuplicate(newCaseIdValue);
    }
  };

  const handleCaseIdChange = async (newCaseIdValue: string) => {
    onChangeCaseId(newCaseIdValue);
    const isSameCaseIdValue = excludeCaseIdChecking === newCaseIdValue;
    if (checkValidation && newCaseIdValue.length > 0 && !isSameCaseIdValue) {
      await validateDuplicateCaseId(newCaseIdValue);
    } else {
      setDuplicateCaseId(false);
    }
  };

  const [t] = useTranslation();

  return isSUPLoading ? (
    <div className={styles.loadingWrapper}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>
      <div>
        <div className={styles.subTitle}>PROFILE STATUS</div>
        <ProfileStatusButtons
          value={recordStatus}
          onChange={onStatusProfileChange}
          disableWaitlistButton={profileType === 'full'}
          noUnsavedChanges={noUnsavedChanges}
        />
      </div>
      {showOnboardingCTA && (
        <InfoCTABox ctaOnClick={() => onClickSubmit({ withTriage: true })} ctaDisabled={submitStatus !== ''} />
      )}
      {isClientTimeZoneEnabled && (
        <MaterialSelect
          className={styles.clientTimeZoneSelect}
          id={'timeZone'}
          label={'CLIENT TIMEZONE'}
          isSearchable={false}
          options={TIMEZONE_OPTIONS}
          value={timeZone}
          onChange={(value: string) => onChangeTimeZone(value)}
        />
      )}
      {recordStatus !== RecordStatus.Closed && (
        <>
          <div className={classnames(styles.fieldContainer, checkValidation && duplicateCaseId && styles.fieldError)}>
            <MaterialInput
              id={`clientCaseId`}
              label={t('form.client_reference')}
              onChange={(e) => handleCaseIdChange(e.target.value)}
              isLoading={isCheckingCaseIdDuplicate}
              value={caseIdValue}
              maxLength={20}
              required
            />
            {checkValidation && duplicateCaseId && (
              <div className={styles.fieldError}>{t('form.error.duplicate_client_reference')}</div>
            )}
          </div>
          {/* TODO
      <EditProfileType recordOnly profileTypeProps={profileType} onChangeProfileTypeProps={onChangeProfileType} /> */}
          <div className={styles.clientDetailTitle}>ADULT PROFILE DETAILS</div>
          {clientDetail.map((obj, index) => (
            <AdultClientForm
              id={obj._id}
              key={index}
              clientDetail={obj}
              onChangeClientField={handleChangeClientField}
              checkValidation={checkValidation}
              dateOfBirthField
              capabilityData={{ profileType: profileType } as newClientForm['clientCapability']}
              disableEmailField={signedUpProfiles.includes(obj._id)}
              excludeEmailCheckingGroup={excludeEmailCheckingGroup}
              showAddress={index === 0}
              clientAddress={clientAddress}
              onChangeClientAddress={onChangeClientAddress}
              onPlaceSelected={onPlaceSelected}
            />
          ))}
        </>
      )}
      <div className={styles.buttonContainer}>
        <ButtonAlt
          status={submitStatus}
          disabled={submitStatus !== ''}
          onClick={recordStatus !== RecordStatus.Closed ? () => onClickSubmit() : onClickSaveClosedClient}
        >
          Save
        </ButtonAlt>
      </div>
    </div>
  );
};

export default EditAdultForm;
