import { clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import { ConsentFormListInterface } from 'interfaces/ConsentForm/ConsentFormListInterface';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

import ClientInfo from '../components/ClientInfo/ClientInfo';
import ConsentDetails from '../components/ConsentDetails/ConsentDetails';
import { ProfileTagsOption } from '../components/DropdownSearchable/interface';
import GroupInformation from '../components/GroupInformation/GroupInformation';
import MentorInfo from '../components/MentorInfo/MentorInfo';
import ProfileTags from '../components/ProfileTags/ProfileTags';
import styles from './AdultDetails.module.scss';
import PackageAssignment from '../components/PackageAssignment/PackageAssignment';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

interface AdultDetailsProps {
  clientRecordData: clientRecordsEncryptedInterface;
  profileTags: ProfileTagsOption[];
  onResendInvitation: () => void;
  onCancelInvitation: () => void;
  onSelectedTags: (tag: ProfileTagsOption) => void;
  isInvitationProcessing: boolean;
  consentListData: ConsentFormListInterface[];
  mentorId?: string;
  refetchClientDetail?: () => void;
  notificationSettings: NotificationSettingsInterface;
  showEditModal: boolean;
  setShowEditModal: (value: boolean) => void;
  setIsOnboardingModalVisible: (value: boolean) => void;
}

const AdultDetails = ({
  clientRecordData,
  profileTags,
  onResendInvitation,
  onCancelInvitation,
  onSelectedTags,
  isInvitationProcessing,
  consentListData,
  mentorId,
  refetchClientDetail,
  notificationSettings,
  showEditModal,
  setShowEditModal,
  setIsOnboardingModalVisible
}: AdultDetailsProps) => {
  const { isMentorFeatureToggle, isGroupsFeatureToggle, isPackageEnabled } = useGetFeatureToggle();

  return (
    <div className={styles.container}>
      <div className={styles.clientInfoSection}>
        <ClientInfo
          clientRecordData={clientRecordData}
          onResendInvitation={onResendInvitation}
          onCancelInvitation={onCancelInvitation}
          isInvitationProcessing={isInvitationProcessing}
          refetchClientDetail={refetchClientDetail}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          setIsOnboardingModalVisible={setIsOnboardingModalVisible}
        />
      </div>
      {isPackageEnabled && <PackageAssignment clientRecordId={clientRecordData._id} />}
      {isGroupsFeatureToggle && (
        <GroupInformation
          clientRecordId={clientRecordData._id}
          clientRecordStatus={clientRecordData.recordStatus}
          attachedGroups={clientRecordData.attachedGroups || []}
          onRefreshData={refetchClientDetail}
        />
      )}
      {isMentorFeatureToggle && (
        <MentorInfo
          clientRecordData={clientRecordData}
          mentorId={mentorId}
          isReadOnly={clientRecordData.recordStatus === 'closed'}
          onCloseModal={(isSave: boolean) => {
            isSave && refetchClientDetail && refetchClientDetail();
          }}
        />
      )}
      <div className={styles.profileTags}>
        <ProfileTags
          profileTags={profileTags}
          onSelectedTags={onSelectedTags}
          isReadOnly={clientRecordData.recordStatus === 'closed'}
        />
      </div>
      <div className={styles.docSection} id={'docSectionId'}>
        <ConsentDetails
          consentListData={consentListData}
          clientRecordData={clientRecordData}
          notificationSettings={notificationSettings}
        />
      </div>
    </div>
  );
};

export default AdultDetails;
