import classNames from 'classnames';
import moment from 'moment';
import { TransactionStatus } from 'pages/ControlPanel/Interfaces/CodatResponses';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { convertStringToTitleCase } from 'utils/general';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import styles from './HistoryRow.module.scss';

interface HistoryRowProps {
  data: {
    _id: string;
    invoiceId?: string;
    invoiceRefId?: string;
    providerId?: string;
    clientName?: string;
    amount?: number;
    date: Date;
    status: TransactionStatus;
    error?: string;
    codatId?: string;
  };
  callPostRetryTransactions: (id: string) => void;
  isRetryingTransaction: boolean;
  provider: string;
}

const HistoryRow = ({
  data: { _id, invoiceId, invoiceRefId, providerId, clientName, amount, date, status, error, codatId },
  callPostRetryTransactions,
  isRetryingTransaction,
  provider
}: HistoryRowProps) => {
  const navigate = useNavigate();
  const { INVOICES } = useRoutesGenerator();

  const handleOnClickInvoiceId = () => {
    navigate(`${INVOICES.BASE}/${invoiceId}`);
  };
  const handleOnClickActionButton = () => {
    switch (provider) {
      case 'xero':
        window.open(`https://invoicing.xero.com/view/${codatId}`);
        break;
      case 'quickBooksOnline':
        window.open(`https://app.qbo.intuit.com/app/invoice?txnId=${codatId}`);
        break;
    }
  };

  const success = [TransactionStatus.Success, TransactionStatus.Retried].includes(status);
  const failed = status === TransactionStatus.Failed;

  return (
    <div className={styles.container}>
      <div className={styles.id} onClick={handleOnClickInvoiceId}>
        {invoiceRefId}
      </div>
      <div className={styles.providerId}>{providerId}</div>
      <div className={styles.field}>{clientName}</div>
      <div className={styles.field}>{amount}</div>
      <div className={styles.field}>{moment(date).format('DD MMM YYYY')}</div>
      <div className={styles.status}>
        <div className={styles.statusIconContainer}>
          <i className={classNames('material-icons', styles[status])} data-tooltip-id={_id}>
            {success ? 'check_circle' : 'warning'}
          </i>
          {failed && error && (
            <Tooltip id={_id} className={styles.tooltip}>
              {error}
            </Tooltip>
          )}
        </div>
        <div className={styles.statusMessageContainer}>
          <div className={styles.statusMessage}>{convertStringToTitleCase(status)}</div>
          {failed && error && (
            <div
              className={`${styles.retry} ${isRetryingTransaction ? styles.disabled : ''}`}
              onClick={() => callPostRetryTransactions(_id)}
            >
              RETRY
            </div>
          )}
        </div>
      </div>
      <div className={styles.action}>
        {status === TransactionStatus.Success && (
          <i className={`material-icons ${styles.icon}`} onClick={handleOnClickActionButton}>
            arrow_forward
          </i>
        )}
      </div>
    </div>
  );
};

export default HistoryRow;
