import { MouseEvent, useEffect, useState } from 'react';
import styles from './SendAdhocAssessmentModalV2.module.scss';
import { Modal, notification } from 'antd';
import { ModalFuncProps, ModalProps } from 'antd/lib/modal/Modal';
import classnames from 'classnames';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import PatientSelect from 'components/v2/PatientSelect/PatientSelect';
import { ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import moment from 'moment';
import { canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { postAdHocAssessment } from 'utils/http/CheckInService/Assessment/clinicalAssessment';
import { ProfileRole, clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { PatientAssessment } from '../SendAdhocAssessmentModal/interfaces';
import ClientPickAssessment from './ClientPickAssessment/ClientPickAssessment';
import AddNote from 'components/AddNote/AddNote';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { CommunicationTransport } from 'pages/Assessments/helper/getCommunication';
import StepProgressBar from 'components/ModalV2/components/StepProgressBar/StepProgressBar';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

export interface SendAdhocAssessmentModalPropsV2 {
  token: string;
  selectedClientRecord?: clientRecordsInterface;
  notificationSettings?: NotificationSettingsInterface;
}

const SendAdhocAssessmentModalV2 = ({
  token,
  onCancel,
  selectedClientRecord,
  notificationSettings,
  ...props
}: SendAdhocAssessmentModalPropsV2 & ModalProps & ModalFuncProps) => {
  const [step, setStep] = useState<'pickClient' | 'pickAssessment' | 'addNote'>(
    selectedClientRecord ? 'pickAssessment' : 'pickClient'
  );
  const [clients, setClients] = useState<clientProfilesInterface[]>(
    selectedClientRecord?.clientProfiles as clientProfilesInterface[]
  );

  const [recordId, setRecordId] = useState(selectedClientRecord?._id);
  const [patientAssessments, setPatientAssessments] = useState<PatientAssessment[]>([]);
  const [sendAt, setSendAt] = useState('now');
  const [customSendAt, setCustomSendAt] = useState(moment().add(1, 'hour').minute(0).format('YYYY-MM-DD hh:mmA Z'));
  const [note, setNote] = useState('');
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [isChildProfile, setIsChildProfile] = useState(false);
  const [isYoungRecord, setIsYoungRecord] = useState(false);

  const [proxyProfiles, setProxyProfiles] = useState<PatientAssessment[]>();
  const [selectedProxyProfileId, setSelectedProxyProfileId] = useState<string>();

  const { isEdgeAdminView } = useGetAccountPackageView();

  useEffect(() => {
    if (clients && clients.length > 0) {
      const init = clients.map((client) => {
        const signupDate = client.signupInvitation?.signupDate;
        const isSignedUp = !!signupDate && signupDate.length > 0;
        return {
          client: client,
          selectedAssessments: [],
          isSignedUp: isSignedUp,
          isSelected: isSignedUp
        };
      });

      setPatientAssessments(init);
      setIsChildProfile(clients.some(({ role }) => role === ProfileRole.Child));
      setIsYoungRecord(
        clients.some(({ role, profileType }) => role === ProfileRole.YoungPerson && profileType === 'recordOnly')
      );
    }
  }, [clients]);

  useEffect(() => {
    setClients(selectedClientRecord?.clientProfiles || []);
    setRecordId(selectedClientRecord?._id);
  }, [selectedClientRecord]);

  useEffect(() => {
    if (isChildProfile && patientAssessments && patientAssessments.length > 0) {
      const canReceiveSmsProfiles = patientAssessments.filter(
        ({ client }) =>
          client.hasMobileNumber &&
          canClientReceiveSms({
            communicationPreference: client.communicationPreference,
            notificationSettings,
            notificationSettingsCategory: 'forms'
          })
      );
      setProxyProfiles(canReceiveSmsProfiles);
      setSelectedProxyProfileId(canReceiveSmsProfiles[0]?.client._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, isChildProfile]);

  const handleProxyProfileChange = (value: string) => {
    setSelectedProxyProfileId(value);
  };

  const handleCancel = (e?: MouseEvent) => {
    e?.preventDefault();

    setCustomSendAt(moment().add(1, 'hour').minute(0).format('YYYY-MM-DD hh:mmA Z'));
    setNote('');
    setSendAt('now');
    setStep(selectedClientRecord ? 'pickAssessment' : 'pickClient');
    setClients(selectedClientRecord?.clientProfiles || []);
    setSubmitStatus('');
    onClearSelectedAssessment();

    onCancel && onCancel();
  };

  const handleSubmit = async () => {
    setSubmitStatus('active');

    let sendAtDateTime = sendAt;

    const now = moment().format('HH:mm');

    if (sendAt === '9') {
      if (now > '09:00') {
        sendAtDateTime = moment().add('1', 'day').hour(9).minute(0).format('YYYY-MM-DD hh:mmA Z');
      } else {
        sendAtDateTime = moment().hour(9).minute(0).format('YYYY-MM-DD hh:mmA Z');
      }
    } else if (sendAt === '13') {
      if (now > '13:00') {
        sendAtDateTime = moment().add('1', 'day').hour(13).minute(0).format('YYYY-MM-DD hh:mmA Z');
      } else {
        sendAtDateTime = moment().hour(13).minute(0).format('YYYY-MM-DD hh:mmA Z');
      }
    } else if (sendAt === 'custom') {
      sendAtDateTime = customSendAt;
    }

    const postArr = patientAssessments
      .filter((patientAssess) => patientAssess.selectedAssessments.length > 0)
      .map(({ client, selectedAssessments }) => {
        const payload = {
          clientRecordId: recordId,
          clientProfileId: client._id,
          clinicalAssessments: selectedAssessments,
          note,
          ...(client.role === 'child' && {
            proxyProfileId: selectedProxyProfileId
          }),
          sendAsAdmin: isEdgeAdminView,
          sendAt: sendAtDateTime
        };

        return postAdHocAssessment(token, payload);
      });

    try {
      await Promise.all(postArr);
      notification.success({
        message: 'Ad-hoc assessment sent!',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      setSubmitStatus('finished');
      handleCancel();
    } catch (ex) {
      setSubmitStatus('');
      notification.error({ message: 'Something went wrong while trying to send this assessment. Please try again' });
    }
  };

  const handleSelectPatients = (clients: clientProfilesInterface[], recordId: string) => {
    setClients(clients);
    setRecordId(recordId);
    setStep('pickAssessment');
  };

  const onSelectClientProfile = (isSelected: boolean, clientId?: string) => {
    const newPatientAssessment = patientAssessments.map((patientAssessment) => {
      if (patientAssessment.client._id === clientId) {
        patientAssessment.isSelected = isSelected;
      }
      return patientAssessment;
    });
    setPatientAssessments(newPatientAssessment);
  };

  const onAddSelectedAssessment = (assessment: ClinicalAssessmentStruct, clientId?: string) => {
    const newPatientAssessment = patientAssessments.map((patientAssessment) => {
      if (patientAssessment.client._id === clientId) {
        const newSelectAssessment = [...patientAssessment.selectedAssessments, assessment];
        patientAssessment.selectedAssessments = newSelectAssessment;
      }
      return patientAssessment;
    });
    setPatientAssessments(newPatientAssessment);
  };

  const onRemoveSelectedAssessment = (assessment: ClinicalAssessmentStruct, clientId?: string) => {
    const newPatientAssessment = patientAssessments.map((patientAssessment) => {
      if (patientAssessment.client._id === clientId) {
        const newSelectAssessment = patientAssessment.selectedAssessments.filter((item) => item.id !== assessment.id);
        patientAssessment.selectedAssessments = newSelectAssessment;
      }
      return patientAssessment;
    });
    setPatientAssessments(newPatientAssessment);
  };

  const onClearSelectedAssessment = () => {
    const newPatientAssessment = patientAssessments.map((patientAssessment) => ({
      ...patientAssessment,
      selectedAssessments: []
    }));
    setPatientAssessments(newPatientAssessment);
  };

  return (
    <Modal
      title={
        <div className={styles.modalHeader}>
          <div className={styles.title}>
            Send Assessment
            {isChildProfile ? (
              <HelpOutLineWithTooltips
                id={'sendAssessmentHeadingInfo'}
                desc={`The request for a child to complete a clinical assessment will be sent to the child's primary contact.`}
              />
            ) : (
              isYoungRecord && (
                <HelpOutLineWithTooltips
                  id={'sendAssessmentHeadingInfo'}
                  desc={`The request for a Young Person to complete a clinical assessment will be sent to the their primary contact if no contact detail is provided.`}
                />
              )
            )}
          </div>
          <StepProgressBar currentStep={step === 'addNote' ? 2 : 1} totalStep={2} />
        </div>
      }
      bodyStyle={{ padding: 0 }}
      footer={null}
      destroyOnClose
      onCancel={handleCancel}
      {...props}
    >
      <div className={styles.container}>
        <div
          className={classnames(styles.patientSelectContainer, step === 'pickClient' ? styles.active : styles.inactive)}
        >
          <PatientSelect
            handleSelectPatients={handleSelectPatients}
            communicationTransport={CommunicationTransport.Both}
            notificationCategory="forms"
            selectedClientRecordId={selectedClientRecord?._id}
            notificationSettings={notificationSettings}
            searchable
          />
        </div>

        <ClientPickAssessment
          className={step === 'pickAssessment' ? styles.active : styles.inactive}
          patientAssessments={patientAssessments}
          onBackClick={() => setStep('pickClient')}
          onAddSelectedAssessment={onAddSelectedAssessment}
          onRemoveSelectedAssessment={onRemoveSelectedAssessment}
          onNextClick={() => setStep('addNote')}
          onSelectClientProfile={onSelectClientProfile}
          noReselectClient={!!selectedClientRecord}
          handleProxyProfileChange={handleProxyProfileChange}
          proxyProfiles={proxyProfiles}
          selectedProxyProfileId={selectedProxyProfileId}
        />

        <AddNote
          participationType={ParticipantType.OneToOne}
          patientAssessments={patientAssessments}
          onBackClick={() => setStep('pickAssessment')}
          onCustomSendAtChange={setCustomSendAt}
          onNoteChanged={setNote}
          onSendAtChange={setSendAt}
          customSendAt={customSendAt}
          sendAt={sendAt}
          className={step === 'addNote' ? styles.active : styles.inactive}
          onSubmit={handleSubmit}
          submitStatus={submitStatus}
        />
      </div>
    </Modal>
  );
};

export default SendAdhocAssessmentModalV2;
