import { debounce } from 'lodash';
import { ReferralFieldsPayload } from 'pages/Referrals/components/AddReferralModal/AddReferralModalInterfaces';
import { useCallback, useEffect, useState } from 'react';
import { useGetMatchingClientsQuery } from 'redux/endpoints/clinicianProfileServices/referral';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

const MINIMUM_LENGTH = 2;

export const useGetMatchedClients = (
  referralSubject: ReferralFieldsPayload['subject'],
  skip: boolean,
  initialFields?: ReferralFieldsPayload
) => {
  const { accountId } = useGetAccountId();
  const { isReferralV2Enabled } = useGetFeatureToggle();

  const [debouncedReferralSubject, setDebouncedReferralSubject] = useState(referralSubject);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  const isSkip = useCallback(
    (subject: ReferralFieldsPayload['subject']) =>
      !subject ||
      !subject.firstName ||
      subject.firstName.length < MINIMUM_LENGTH ||
      !subject.lastName ||
      subject.lastName.length < MINIMUM_LENGTH,
    []
  );

  const { subject: initialSubject, clientMatch: initialClientMatch } = initialFields || {};
  const isSame = initialSubject
    ? initialSubject.firstName === (debouncedReferralSubject?.firstName || '') &&
      initialSubject.lastName === (debouncedReferralSubject?.lastName || '') &&
      (initialSubject.dateOfBirth || '') === (debouncedReferralSubject?.dateOfBirth || '') &&
      (initialSubject.providerNumber || '') === (debouncedReferralSubject?.providerNumber || '')
    : false;
  const shouldUseInitial = isSame && !!initialClientMatch;

  const { data, isLoading, isFetching, isError, refetch } = useGetMatchingClientsQuery(
    {
      accountId,
      query: {
        firstName: debouncedReferralSubject?.firstName || '',
        lastName: debouncedReferralSubject?.lastName || '',
        dateOfBirth: debouncedReferralSubject?.dateOfBirth,
        providerNumber: debouncedReferralSubject?.providerNumber
      }
    },
    { skip: !accountId || !isReferralV2Enabled || isSkip(debouncedReferralSubject) || shouldUseInitial || skip }
  );

  useEffect(() => {
    const handler = debounce((newReferralSubject) => {
      setDebouncedReferralSubject(newReferralSubject);
      setIsWaiting(false);
    }, 3000);

    handler(referralSubject);
    setIsWaiting(!isSkip(referralSubject));

    return () => {
      handler.cancel();
    };
  }, [referralSubject, isSkip]);

  return {
    isLoading: isLoading || isFetching || isWaiting,
    isFetching,
    isError,
    count: shouldUseInitial ? initialClientMatch?.count ?? 0 : data?.count ?? 0,
    matchedClients: shouldUseInitial ? initialClientMatch?.clients ?? [] : data?.clients ?? [],
    refetch
  };
};
