import { VariableData } from '../../ClientData.interface';
import styles from './ClientDataHistoryList.module.scss';
import HistoryItem from './components/HistoryItem/HistoryItem';

const ClientDataHistoryList = ({
  clientDataVariable,
  clientRecordId,
  variableOptions
}: {
  clientDataVariable: VariableData;
  clientRecordId: string;
  variableOptions: { label: string; value: string }[];
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.variableId}>VARIABLE ID</div>
        <div className={styles.category}>CATEGORY</div>
        <div className={styles.detail}>DETAIL</div>
        <div className={styles.entryDate}>ENTRY DATE</div>
        <div className={styles.updatedBy}>UPDATED BY</div>
        <div className={styles.source}>SOURCE</div>
      </div>
      {clientDataVariable.histories.map((item, index) => (
        <HistoryItem
          key={index}
          clientRecordId={clientRecordId}
          variableId={clientDataVariable.variableId}
          history={item}
          variableOptions={variableOptions}
          variableType={clientDataVariable.variableType}
        />
      ))}
    </div>
  );
};

export default ClientDataHistoryList;
