import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFetchReportTemplate } from 'utils/hooks/reports';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  ReportTemplate,
  ReportTemplateStatus,
  ReportTemplateValidationInterface
} from 'interfaces/Reports/reportTemplate';
import TemplateContent from './components/TemplateContent/TemplateContent';
import TemplateSideBar from './components/TemplateSideBar/TemplateSideBar';
import styles from './ReportTemplateEditor.module.scss';
import { defaultReportTemplateValidation } from './constants';
import moment from 'moment';
import { timeDiff } from 'utils/timeDifferent';
import { debounce } from 'lodash';
import TemplatePreview from './components/TemplatePreview/TemplatePreview';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { postReportTemplate, putReportTemplate } from 'utils/http/DocumentService/Reports/reportsTemplate';
import { notification } from 'antd';
import { ReportDimension } from 'interfaces/Reports/report';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useTime } from '../hooks/useTime';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';
import { useAppDispatch } from 'redux/hooks';

const ReportTemplateEditor = () => {
  const dispatch = useAppDispatch();
  const { token } = useGetAccessToken();
  const navigate = useNavigate();
  const { FORMS, REPORTS } = useRoutesGenerator();
  const { templateId = '' } = useParams<{ templateId: string }>();
  const [searchParams] = useSearchParams();
  const isNewTemplateId = templateId === 'newTemplate';
  const { templateData, isTemplateDataLoading } = useFetchReportTemplate(token, templateId);
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const [savingStatus, setSavingStatus] = useState<'' | 'saving' | 'saved'>('');
  const [saveBtnStatus, setSaveBtnStatus] = useState<'' | 'active' | 'finished'>('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showEditTime, setShowEditTime] = useState(false);
  const [reportTemplateData, setReportTemplateData] = useState<ReportTemplate>(templateData);
  const [reportTemplateValidation, setReportTemplateValidation] = useState<ReportTemplateValidationInterface>(
    defaultReportTemplateValidation
  );

  const now = useTime(savingStatus === 'saving' || isTemplateDataLoading ? 1000 : 10000, reportTemplateData.updatedAt);
  const currentTime = moment();
  const lastUpdateTime = moment(reportTemplateData.updatedAt);
  const formattedDuration = reportTemplateData.isEditing
    ? timeDiff(currentTime, now)
    : timeDiff(currentTime, lastUpdateTime);

  useEffect(() => {
    setShowEditTime(!isTemplateDataLoading);
  }, [isTemplateDataLoading]);

  useEffect(() => {
    if (templateData) {
      setReportTemplateData(templateData);
    }
  }, [templateData]);
  const reportPrevPath = localStorage.getItem('reportPrevPath');
  const backToReport = searchParams.get('backToReport') === 'true' && reportPrevPath;

  const onClickExit = () => {
    navigate(backToReport ? `${REPORTS.BASE}/${reportPrevPath}?widgetMenu=reportTemplateView` : FORMS.BASE);
  };

  const saveTemplateData = (newData: ReportTemplate) => {
    const newTemplateData: ReportTemplate = {
      ...reportTemplateData,
      ...newData,
      isEditing: true,
      updatedAt: new Date()
    };
    setReportTemplateData(newTemplateData);
    if (!isSubmitting) {
      setSavingStatus(Object.keys(newTemplateData).length !== 0 ? 'saving' : '');
      debouncedSave(newTemplateData);
    }
  };

  const onChangeTemplateTitle = (title: string) => {
    saveTemplateData({ ...reportTemplateData, title });
    setReportTemplateValidation({
      ...reportTemplateValidation,
      title: title.length <= 0
    });
  };

  const onChangeTemplateStatus = (status: ReportTemplate['status']) => {
    saveTemplateData({ ...reportTemplateData, status });
  };

  const onChangeTemplateDimensions = (dimensions: ReportDimension) => {
    if (reportTemplateData.isEditing) {
      saveTemplateData({ ...reportTemplateData, template: { dimensions } });
    }
  };

  const onChangeTemplateItems = (items: ReportTemplate['items']) => {
    saveTemplateData({
      ...reportTemplateData,
      items
    });
    setReportTemplateValidation({
      ...reportTemplateValidation,
      items: items.length <= 0
    });
  };

  const handleSaveReportTemplate = useCallback(
    async (newTemplateData: ReportTemplate, withBtnStatus?: boolean) => {
      setSubmitting(true);
      if (withBtnStatus) {
        setSaveBtnStatus('active');
      }
      if (isNewTemplateId) {
        try {
          const { _id, ...newTemplateDataObj } = newTemplateData;
          const massagePOSTPayload = {
            ...newTemplateDataObj,
            asAdmin: isEdgeAdminView || isEdgeReceptionistView
          };
          const createdNewReportTemplateId = await (await postReportTemplate(token, massagePOSTPayload)).text();
          dispatch(documentServicesApiSlice.util.invalidateTags(['Report Templates']));
          navigate(`${FORMS.REPORT_TEMPLATE}/${createdNewReportTemplateId}`);
          if (withBtnStatus) {
            setSaveBtnStatus('finished');
            notification.success({
              message: 'Report Template has been saved.',
              duration: 3,
              closeIcon: <span className="success">OK</span>
            });
            setTimeout(() => {
              setSaveBtnStatus('');
            }, 1000);
          }
        } catch (ex) {
          setSaveBtnStatus('');
          console.error(ex);
          notification.error({ message: 'Something went wrong while trying to create report template' });
        }
      } else {
        try {
          const massageUpdatePayload = {
            ...newTemplateData,
            _id: templateId
          };
          await putReportTemplate(token, templateId, massageUpdatePayload);
          dispatch(documentServicesApiSlice.util.invalidateTags(['Report Templates']));
          if (withBtnStatus) {
            setSaveBtnStatus('finished');
            notification.success({
              message: 'Report Template has been updated.',
              duration: 3,
              closeIcon: <span className="success">OK</span>
            });
            setTimeout(() => {
              setSaveBtnStatus('');
            }, 1000);
          }
        } catch (ex) {
          setSaveBtnStatus('');
          console.error(ex);
          notification.error({ message: 'Something went wrong while trying to update the report template' });
        }
      }

      setSavingStatus(Object.keys(newTemplateData).length !== 0 ? 'saved' : '');
      setSubmitting(false);
    },
    [
      FORMS.REPORT_TEMPLATE,
      isEdgeAdminView,
      isEdgeReceptionistView,
      isNewTemplateId,
      templateId,
      token,
      navigate,
      dispatch
    ]
  );

  const debouncedSave = useMemo(
    () =>
      debounce(async (templateDraftData: ReportTemplate) => {
        if (templateDraftData.title.length > 0 && templateDraftData.items.length > 0) {
          await handleSaveReportTemplate(templateDraftData);
        }
      }, 3000),
    [handleSaveReportTemplate]
  );

  const onClickSaveTemplate = async () => {
    setReportTemplateValidation({
      title: reportTemplateData.title.length <= 0,
      items: reportTemplateData.items.length <= 0
    });
    if (reportTemplateData.title.length > 0 && reportTemplateData.items.length > 0) {
      setSavingStatus(Object.keys(reportTemplateData).length !== 0 ? 'saving' : '');
      await handleSaveReportTemplate(reportTemplateData, true);
    }
  };

  return (
    <HelmetWrapper title={'Tacklit - Report Template Editor'}>
      <ContentLayout withMaxWidth>
        <div id={'reportTemplateEditor'} />
        <DndProvider backend={HTML5Backend}>
          <div className={styles.container}>
            <div className={styles.leftSection}>
              <div className={styles.titleSection}>
                <div className={styles.title}>Template editor</div>
                <ButtonAlt size={'medium'} variant={'text'} onClick={onClickExit} icon={'arrow_back_ios'}>
                  {backToReport ? 'Back to report' : 'To Template List'}
                </ButtonAlt>
              </div>
              <TemplateSideBar
                title={reportTemplateData.title}
                titleValidation={reportTemplateValidation.title}
                status={reportTemplateData.status}
                templateContent={reportTemplateData.items}
                onChangeTemplateTitle={onChangeTemplateTitle}
                onChangeTemplateStatus={(e) => onChangeTemplateStatus(e as ReportTemplateStatus)}
              />
            </div>
            <div className={styles.rightSection}>
              <div className={styles.templateHeader}>
                <div className={styles.rightHeadingWrapper}>
                  {!isNewTemplateId && (
                    <div className={!showEditTime ? styles.lastEditMessageHide : styles.lastEditMessage}>
                      Last edit was {formattedDuration}
                    </div>
                  )}
                  <ButtonAlt
                    variant={'outlined'}
                    onClick={onClickSaveTemplate}
                    activeClassName={styles.draftActive}
                    status={saveBtnStatus}
                    disabled={
                      reportTemplateData.title.length <= 0 ||
                      reportTemplateData.items.length <= 0 ||
                      savingStatus === 'saving'
                    }
                  >
                    Save Changes
                  </ButtonAlt>
                  <ButtonAlt disabled={reportTemplateData.items.length <= 0} onClick={() => setShowPreviewModal(true)}>
                    View Template Preview
                  </ButtonAlt>
                </div>
              </div>
              <TemplateContent
                templateData={reportTemplateData}
                isLoading={isTemplateDataLoading}
                validation={reportTemplateValidation.items}
                onChangeTemplateDimensions={onChangeTemplateDimensions}
                onChangeTemplateItems={onChangeTemplateItems}
              />
            </div>
          </div>
          <TemplatePreview
            showPreviewModal={showPreviewModal}
            setShowPreviewModal={setShowPreviewModal}
            templateData={reportTemplateData}
          />
        </DndProvider>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ReportTemplateEditor;
