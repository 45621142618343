import { BentResponse } from 'bent';
import { CaseNoteTemplate, ParticipantType } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import queryString from 'query-string';
import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL!;

// GET ---------------------
export const getCaseNotesListing = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })('/case-note-templates') as Promise<BentResponse>;

export const getAssignedCaseNotesListing = (token: string, participantType?: ParticipantType, asAdmin?: boolean) => {
  const filterParam = {
    participantType: participantType,
    asAdmin: asAdmin
  };
  const qParam = queryString.stringify(filterParam);
  const qString = qParam ? `?${qParam}` : '';

  return httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/case-note-templates/assigned${qString}`
  ) as Promise<BentResponse>;
};

export const getCaseNoteTemplateById = (token: string, id: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/case-note-templates/${id}`
  ) as Promise<BentResponse>;

// POST ----------------------
export const postCaseNoteTemplate = (token: string, payload: CaseNoteTemplate) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    '/case-note-templates',
    payload
  ) as Promise<BentResponse>;

// PUT -----------------------
export const updateCaseNoteTemplate = (token: string, id: string, payload: CaseNoteTemplate) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/case-note-templates/${id}`,
    payload
  ) as Promise<BentResponse>;

// PATCH ---------------------
export const patchCaseNoteTemplateSetDefault = (token: string, id: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/case-note-templates/${id}:setDefault`
  ) as Promise<BentResponse>;

export const patchTogglePublishCaseNoteTemplate = (token: string, id: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/case-note-templates/${id}:togglePublish`
  ) as Promise<BentResponse>;

// DELETE -----------------------
export const deleteCaseNoteTemplate = (token: string, id: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/case-note-templates/${id}`
  ) as Promise<BentResponse>;
