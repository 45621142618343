import { Dropdown, Menu } from 'antd';
import HelpOutLineWithTooltips from '../../../../components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import styles from './PaymentStatus.module.scss';
import { InvoiceType, TotalInvoicesAmounts } from 'utils/hooks/useTotalInvoicesAmounts';
import classNames from 'classnames';
import { config } from 'config/config';

const PARTICIPANT_FILTER_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Client', value: 'individual' },
  { label: 'Group', value: 'group' }
];

interface PaymentStatusProps {
  filter: InvoiceType;
  isLoading: boolean;
  totalInvoicesAmounts: TotalInvoicesAmounts;
  setFilter: (value: InvoiceType) => void;
  enableGroupSelection?: boolean;
}

const PaymentStatus = ({
  filter,
  isLoading,
  enableGroupSelection,
  totalInvoicesAmounts,
  setFilter
}: PaymentStatusProps) => {
  const { closed, confirmPaid, issued, markedPaid, overdue } = totalInvoicesAmounts;

  const formatAmount = (amount: number) =>
    isLoading ? '...' : typeof amount === 'number' ? `${config.currencySymbol}${amount.toFixed(2)}` : `-`;

  const generatePill = (label: string, amount: number, labelClassName: string) => (
    <div className={styles.statusContainer}>
      <div className={classNames(styles.pill, labelClassName)}>{label}</div>
      <div className={styles.value}>{formatAmount(amount)}</div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Total invoice value and status overview</div>
          <HelpOutLineWithTooltips
            id="totalInvoiceHeaderInfo"
            desc="This is a summary of the total actual amounts invoiced grouped by their status (including any taxes or discounts you have added).  Each invoice can only live in one status at a time."
          />
        </div>
        {enableGroupSelection && (
          <div className={styles.filter}>
            Show:
            <Dropdown
              overlay={
                <Menu onClick={(e) => setFilter(String(e.key) as InvoiceType)}>
                  {PARTICIPANT_FILTER_OPTIONS.map((option) => (
                    <Menu.Item key={option.value}>{option.label}</Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <div className={styles.dropdownText}>
                {PARTICIPANT_FILTER_OPTIONS.find((option) => option.value === filter)?.label}
                <i className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</i>
              </div>
            </Dropdown>
          </div>
        )}
      </div>
      <div className={styles.statusesContainer}>
        {generatePill('ISSUED', issued, styles.issuedPill)}
        {generatePill('OVERDUE', overdue, styles.overduePill)}
        {generatePill('MARKED PAID', markedPaid, styles.markedPaidPill)}
        {generatePill('CONFIRM PAID', confirmPaid, styles.confirmPaidPill)}
        {generatePill('CLOSED', closed, styles.closedPill)}
      </div>
    </div>
  );
};

export default PaymentStatus;
