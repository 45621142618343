import { useGetAccessToken } from 'utils/hooks/token';

import styles from './BroadcastMessageGroup.module.scss';
import BroadcastContent from '../BroadcastContent/BroadcastContent';
import { useFetchClientRecordList } from 'utils/hooks/GetClient/clientList';
import { useGetMinifiedGroupListQuery } from 'redux/endpoints/clinicianProfileServices/group';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { GroupStatus } from 'pages/Groups/Interfaces/Groups';

const BroadcastMessageGroup = () => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { isEdgeUserView } = useGetAccountPackageView();

  const {
    clientList,
    hasMoreClients,
    isClientListLoading,
    loadingMoreClients,
    loadingSearch,
    loadMoreClients,
    searchTerm,
    setSearchTerm
  } = useFetchClientRecordList({
    token,
    usePagination: true
  });
  const { data: groupData, isLoading: isGroupListLoading } = useGetMinifiedGroupListQuery({
    accountId,
    isEdgeUserView,
    status: GroupStatus.Active
  });

  return (
    <div className={styles.container}>
      <BroadcastContent
        clientList={clientList}
        groupList={groupData?.groups}
        hasMoreClients={hasMoreClients}
        isLoading={isClientListLoading || isGroupListLoading}
        loadingMoreClients={loadingMoreClients}
        loadingSearch={loadingSearch}
        loadMoreClients={loadMoreClients}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </div>
  );
};

export default BroadcastMessageGroup;
