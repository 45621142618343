import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useMemo, useState } from 'react';
import styles from './GroupForm.module.scss';
import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import {
  CreateGroupRequestPayload,
  CreateGroupValidateField
} from 'components/Groups/CreateGroupModal/CreateGroupModalInterfaces';
import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import { useGetAccessToken } from 'utils/hooks/token';
import { debounce } from 'lodash';
import { validateGroupId } from './validation/GroupFormValidation';

interface GroupFromProps {
  selectedClinicianId: string;
  checkValidation: boolean;
  onChangeClinician: (b: string) => void;
  errorMessage: CreateGroupValidateField;
  groupFormField: CreateGroupRequestPayload;
  onChangeGroupField: any;
  accountId: string;
}

const GroupForm = ({
  selectedClinicianId,
  checkValidation,
  onChangeClinician,
  errorMessage,
  groupFormField,
  onChangeGroupField,
  accountId
}: GroupFromProps) => {
  const { token } = useGetAccessToken();
  const [duplicateGroupId, setDuplicateGroupId] = useState(false);
  const [isCheckingGroupIdDuplicate, setIsCheckingGroupIdDuplicate] = useState(false);

  const debouncedCheckGroupIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const duplicate = await validateGroupId(token, accountId, value);
        setDuplicateGroupId(duplicate.statusCode !== 200);
        setIsCheckingGroupIdDuplicate(false);
      }, 1000),
    [token, accountId]
  );

  const validateDuplicateGroupId = async (groupIdValue: string) => {
    setIsCheckingGroupIdDuplicate(true);
    await debouncedCheckGroupIdDuplicate(groupIdValue);
  };

  const onSelectClinician = (selectedClinician?: PractitionersDetailsInterface) => {
    if (selectedClinician) {
      const newGroupClinicianField = {
        ...groupFormField,
        leadClinicianAuth0Id: selectedClinician._id
      };
      onChangeClinician(selectedClinician._id);
      onChangeGroupField(newGroupClinicianField);
    }
  };

  const handleChangeFieldValue = async (key: string, val: string) => {
    const newGroupField = {
      ...groupFormField,
      [key]: val
    };
    onChangeGroupField(newGroupField);
    if (key === 'groupId') {
      if (checkValidation && val) {
        await validateDuplicateGroupId(val);
      } else {
        setIsCheckingGroupIdDuplicate(false);
        setDuplicateGroupId(false);
      }
    }
  };

  return (
    <>
      <div className={classNames(styles.fieldContainer, checkValidation && duplicateGroupId && styles.fieldError)}>
        <MaterialInput
          id={`groupId-${groupFormField.groupId}`}
          label={`Group ID`}
          maxLength={20}
          isLoading={isCheckingGroupIdDuplicate}
          onChange={(e) => handleChangeFieldValue('groupId', e.target.value)}
          value={groupFormField.groupId}
          required
        />
        {checkValidation && duplicateGroupId && (
          <div className={styles.fieldError}>This group id is already in use</div>
        )}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.groupLeadLabel}>Group Lead</div>
        <ClinicianSelect
          includePractice
          selectedId={selectedClinicianId}
          filterRoles={[AccessRight.Admin, AccessRight.User]}
          onSelect={(clinician) => onSelectClinician(clinician)}
          materialStyle
          hideAvatar
        />
      </div>
      <div className={classNames(styles.fieldContainer, checkValidation && errorMessage.name && styles.fieldError)}>
        <MaterialInput
          id={`groupName`}
          label={`Group Name*`}
          required
          value={groupFormField.name}
          onChange={(e) => handleChangeFieldValue('name', e.target.value)}
        />
        {checkValidation && errorMessage.name && <div className={styles.fieldError}>{errorMessage.name}</div>}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.label}>Group Short Description</div>
        <textarea
          className={styles.textarea}
          value={groupFormField.description}
          onChange={(e) => handleChangeFieldValue('description', e.target.value)}
        />
      </div>
    </>
  );
};

export default GroupForm;
