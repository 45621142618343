import { Modal, Skeleton, notification } from 'antd';
import InvoiceView from 'components/InvoiceView/InvoiceView';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import moment from 'moment';
import { Invoice } from 'pages/Invoices/interface';
import { MBS_CODE_FULL_LIST } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/mbsItems';
import {
  Claim,
  ClaimStatus,
  ClaimType
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';
import {
  MedicareItemDuration,
  MedicareItemFormat,
  MedicareItemMode
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/interfaces';
import { useState } from 'react';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { getName } from 'utils/general';
import { useGetAccessToken } from 'utils/hooks/token';
import { confirmClaimSubmission } from 'utils/http/BillingService/Invoice/claimingcom';
import { CLAIM_TYPE_LABELS } from '../../../CreateClaimModal/CreateClaimModal';

import styles from './ClaimStatementModal.module.scss';

const getClaimStatusProps = (status: ClaimStatus): { status: string; icon: string } => {
  switch (status) {
    case ClaimStatus.Complete:
      return { status: 'Completed', icon: 'check_circle' };
    case ClaimStatus.Expired:
      return { status: 'Expired', icon: 'warning' };
    case ClaimStatus.MedicareAssessed:
    case ClaimStatus.WithMedicare:
      return { status: 'Success', icon: 'check_circle' };
    case ClaimStatus.MedicarePendable:
      return { status: 'Pendable', icon: 'hourglass_top' };
    case ClaimStatus.MedicarePended:
      return { status: 'Pended', icon: 'hourglass_top' };
    case ClaimStatus.MedicareRejected:
    case ClaimStatus.Rejected:
      return { status: 'Rejected', icon: 'warning' };
    case ClaimStatus.Processed:
      return { status: 'Processed', icon: 'done' };
    case ClaimStatus.WriteOff:
      return { status: 'Written Off', icon: 'money_off' };

    default:
      console.error(`Failed to get claim status icon for status [${status}]`);
      return { status: '', icon: '' };
  }
};

const getMBSDescription = (code: string) =>
  MBS_CODE_FULL_LIST.find((item) => item.mbsCode === code)?.description ?? '-';

const getServiceDuration = (duration: MedicareItemDuration) => {
  switch (duration) {
    case MedicareItemDuration.OneToOneLessThan20:
      return 'Less than 20 minutes';
    case MedicareItemDuration.OneToOneMoreThan20:
      return 'More than 20 minutes';
    case MedicareItemDuration.OneToOneMoreThan30:
      return 'More than 30 minutes';
    case MedicareItemDuration.OneToOneLessThan40:
      return 'Less than 40 minutes';
    case MedicareItemDuration.OneToOneMoreThan40:
      return 'More than 40 minutes';
    case MedicareItemDuration.OneToOneMoreThan45:
      return 'More than 45 minutes';
    case MedicareItemDuration.OneToOneLessThan50:
      return 'Less than 50 minutes';
    case MedicareItemDuration.OneToOneMoreThan50:
      return 'More than 50 minutes';
    case MedicareItemDuration.GroupMoreThan60:
      return 'More than 60 minutes';
  }
};

const getServiceFormat = (format: MedicareItemFormat) => {
  switch (format) {
    case MedicareItemFormat.OneToOne:
      return 'One to one';
    case MedicareItemFormat.Group:
      return 'Group';
  }
};

const getServiceMode = (mode: MedicareItemMode) => {
  switch (mode) {
    case MedicareItemMode.FaceToFace:
      return 'Face to face';
    case MedicareItemMode.PhoneCall:
      return 'Telehealth - Phone Call';
    case MedicareItemMode.VideoCall:
      return 'Telehealth - Video Call';
    case MedicareItemMode.Telehealth:
      return 'Telehealth';
  }
};

interface ClaimStatementModalProps {
  visible: boolean;
  claim: Claim;
  invoice?: Invoice;
  handleCancel: () => void;
  refetchClaims: () => void;
}

const ClaimStatementModal = ({
  visible,
  claim: {
    type,
    mbsCode,
    benefit,
    status,
    claimant,
    referral,
    provider,
    locationMinorId,
    service,
    statusMessages,
    payment,
    medicareClaimId,
    claimId,
    _claimant: parentMedicare,
    createdAt
  },
  invoice,
  handleCancel,
  refetchClaims
}: ClaimStatementModalProps) => {
  const { token } = useGetAccessToken();
  const [confirmButtonStatus, setConfirmButtonStatus] = useState<ButtonStatusType>('');
  const statusProps = getClaimStatusProps(status);

  const submitConfirmClaimSubmission = async () => {
    setConfirmButtonStatus('active');
    try {
      const response = await confirmClaimSubmission(token, locationMinorId, provider.providerNumber, claimId);

      if (response.statusCode === 400) {
        const error = await response.json();
        notification.error({
          message: error.message,
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });
      } else if (response.statusCode === 200) {
        notification.success({
          message: 'Claim confirmation submitted',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });

        refetchClaims();
        setConfirmButtonStatus('finished');
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to confirm claim submission' });
    }
    setConfirmButtonStatus('');
  };

  return (
    <Modal
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
      width={800}
      title={
        <div className={styles.titleContainer}>
          <div className={styles.title}>Statement of Claim - {CLAIM_TYPE_LABELS[type || ClaimType.Medicare]}</div>
          {status === ClaimStatus.MedicarePendable && (
            <Button onClick={submitConfirmClaimSubmission} variant="secondary" status={confirmButtonStatus}>
              Confirm Claim Submission
            </Button>
          )}
        </div>
      }
      footer={null}
      open={visible}
      destroyOnClose
      onCancel={handleCancel}
    >
      <div className={styles.container}>
        <div className={styles.detailsContainer}>
          <div className={styles.title}>Medicare item and status</div>
          <div className={styles.row}>
            <div className={styles.flex1}>
              <div className={styles.label}>MBS Code</div>
              <div>
                <div className={styles.mbsCode}>{mbsCode}</div>
              </div>
            </div>
            <div className={styles.flex2}>
              <div className={styles.label}>MBS Details</div>
              <div className={styles.value}>{getMBSDescription(mbsCode)}</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.flex1}>
              <div className={styles.label}>Benefit</div>
              <div className={styles.value}>{`$${benefit.toFixed(2)}`}</div>
            </div>
            <div className={styles.flex2}>
              <div className={styles.label}>Date Submitted</div>
              <div className={styles.value}>{createdAt ? moment(createdAt).format(MOMENTJS_DATE_FORMAT) : '-'}</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.flex1}>
              <div className={styles.label}>Claim Status</div>
              <div className={styles.value}>
                <i
                  className={`material-icons ${styles[`icon-${statusProps.status.toLowerCase().replace(/\s+/g, '')}`]}`}
                >
                  {statusProps.icon}
                </i>
                <div>{`Claim ${statusProps.status}`}</div>
              </div>
            </div>
            {[ClaimStatus.Complete, ClaimStatus.MedicareAssessed].includes(status) && medicareClaimId && (
              <div className={styles.flex2}>
                <div className={styles.label}>Claim Reference</div>
                <div className={styles.value}>{medicareClaimId}</div>
              </div>
            )}
          </div>
          {[ClaimStatus.MedicarePendable, ClaimStatus.MedicareRejected, ClaimStatus.Rejected].includes(status) && (
            <div className={styles.row}>
              <div className={styles.flex1}>
                <div className={styles.label}>
                  {status === ClaimStatus.MedicarePendable ? 'Pendable Notes' : 'Rejected Reason'}
                </div>
                <div className={styles.notes}>
                  {statusMessages.map((message, index) => (
                    <div key={index}>{message}</div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {[ClaimStatus.Complete, ClaimStatus.MedicareAssessed, ClaimStatus.WithMedicare].includes(status) && (
            <>
              <div className={styles.row}>
                <div className={styles.flex1}>
                  <div className={styles.label}>Claim Paid?</div>
                  <div className={styles.value}>{payment ? 'Yes' : 'No'}</div>
                </div>
                {payment && (
                  <div className={styles.flex2}>
                    <div className={styles.label}>Benefit paid via</div>
                    <div className={styles.value}>EFT</div>
                  </div>
                )}
              </div>
              {payment && (
                <>
                  <div className={styles.row}>
                    <div className={styles.flex1}>
                      <div className={styles.label}>Payment ID</div>
                      <div className={styles.value}>{payment.paymentId}</div>
                    </div>
                    <div className={styles.flex2}>
                      <div className={styles.label}>ERA Transaction ID</div>
                      <div className={styles.value}>{payment.eraTransactionId || '-'}</div>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.flex1}>
                      <div className={styles.label}>Payer Name</div>
                      <div className={styles.value}>{payment.payerName || '-'}</div>
                    </div>
                    {type !== ClaimType.Medicare && (
                      <div className={styles.flex2}>
                        <div className={styles.label}>Paid To</div>
                        <div className={styles.value}>{payment.bank?.accountName || '-'}</div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.title}>For</div>
          {parentMedicare && <div className={styles.title}>CHILD DETAILS</div>}
          <div className={styles.row}>
            <div className={styles.flex1}>
              <div className={styles.label}>Name</div>
              <div className={styles.value}>{invoice ? getName(invoice.clientRecord.clientProfiles[0]) : '-'}</div>
            </div>
            {claimant.medicareNumber && claimant.medicareIrn && (
              <div className={claimant.medicareDva ? styles.flex1 : styles.flex2}>
                <div className={styles.label}>Medicare #</div>
                <div className={styles.value}>
                  {claimant.medicareNumber}-{claimant.medicareIrn}
                </div>
              </div>
            )}
            {claimant.medicareDva && (
              <div className={claimant.medicareNumber && claimant.medicareIrn ? styles.flex1 : styles.flex2}>
                <div className={styles.label}>DVA #</div>
                <div className={styles.value}>{claimant.medicareDva}</div>
              </div>
            )}
          </div>
          {parentMedicare && (
            <div className={styles.parentSection}>
              <div className={styles.title}>PARENT DETAILS</div>
              <div className={styles.row}>
                <div className={styles.flex1}>
                  <div className={styles.label}>Name</div>
                  <div className={styles.value}>{parentMedicare.firstName}</div>
                </div>
                {parentMedicare.medicareNumber && parentMedicare.medicareIrn && (
                  <div className={styles.flex2}>
                    <div className={styles.label}>Medicare #</div>
                    <div className={styles.value}>
                      {parentMedicare.medicareNumber}-{parentMedicare.medicareIrn}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={styles.row}>
            <div className={styles.flex1}>
              <div className={styles.label}>Invoice</div>
              <div className={styles.value}>{invoice?.invoiceId ?? '-'}</div>
            </div>
            <div className={styles.flex2}>
              <div className={styles.label}>Patient Contribution</div>
              <div className={styles.value}>
                {invoice ? (type !== ClaimType.BulkBill ? `$${invoice.invoiceAmount}` : `$0`) : '-'}
              </div>
            </div>
          </div>
        </div>
        {referral && (
          <div className={styles.detailsContainer}>
            <div className={styles.title}>Referred By</div>
            <div className={styles.row}>
              <div className={styles.flex1}>
                <div className={styles.label}>Name</div>
                <div className={styles.value}>{referral.name}</div>
              </div>
              <div className={styles.flex1}>
                <div className={styles.label}>Date of Referral</div>
                <div className={styles.value}>
                  {moment(referral.date, MOMENTJS_FORMAT_DATE).format(MOMENTJS_DATE_FORMAT)}
                </div>
              </div>
              <div className={styles.flex1}>
                <div className={styles.label}>Provider Number</div>
                <div className={styles.value}>{referral.providerNumber}</div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.detailsContainer}>
          <div className={styles.title}>Service delivered</div>
          <div className={styles.row}>
            <div className={styles.flex1}>
              <div className={styles.label}>Provider Name </div>
              <div className={styles.value}>{provider.providerName}</div>
            </div>
            <div className={styles.flex1}>
              <div className={styles.label}>Location ID</div>
              <div className={styles.value}>{locationMinorId}</div>
            </div>
            <div className={styles.flex1}>
              <div className={styles.label}>Provider Number</div>
              <div className={styles.value}>{provider.providerNumber}</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.flex1}>
              <div className={styles.label}>Service Duration</div>
              <div className={styles.value}>{getServiceDuration(service.duration)}</div>
            </div>
            <div className={styles.flex1}>
              <div className={styles.label}>Service Format</div>
              <div className={styles.value}>{getServiceFormat(service.format)}</div>
            </div>
            <div className={styles.flex1}>
              <div className={styles.label}>Service Mode</div>
              <div className={styles.value}>{getServiceMode(service.mode)}</div>
            </div>
          </div>
        </div>
        {invoice ? (
          <div className={styles.invoiceContainer}>
            <InvoiceView
              clientRecord={invoice.clientRecord}
              clinician={invoice.clinician}
              description={invoice.description}
              discount={invoice.discount}
              dueDate={invoice.dueDate}
              invoiceId={invoice.invoiceId}
              issueDate={invoice.issueDate}
              items={invoice.items}
              paymentMethods={invoice.paymentMethods}
              practice={invoice.practice}
              taxRate={invoice.taxRate}
              selectedGroup={invoice.group}
              medicare={invoice.medicare}
              invoiceAmount={invoice.invoiceAmount}
              amountChanged={invoice.amountChanged}
            />
          </div>
        ) : (
          <Skeleton.Input active className={styles.invoiceLoading} />
        )}
      </div>
    </Modal>
  );
};

export default ClaimStatementModal;
