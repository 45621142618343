import styles from './HowToConnect.module.scss';

interface HowToConnectProps {
  value: string;
  onChange: (value: string) => void;
}

const HowToConnect = ({ value, onChange }: HowToConnectProps) => (
  <div className={styles.container}>
    <label className={styles.label}>How to connect</label>
    <textarea
      id={'howToConnect'}
      className={styles.textarea}
      rows={4}
      maxLength={320}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

export default HowToConnect;
