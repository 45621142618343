import ToggleSwitchV2, { ToggleList } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import styles from './InlineBookingForm.module.scss';
import { RefObject, useMemo } from 'react';
import AppointmentContent from './components/AppointmentContent/AppointmentContent';
import {
  AppointmentClientOrGroupType,
  AppointmentHumanFactorStep,
  TypeOfAppointment,
  selectAppointmentData,
  selectTypeOfAppointment,
  setIgnoreRoomConflict,
  setIsActivity,
  setRate,
  setSelectedAppointmentType,
  setSelectedClient,
  setSelectedClientType,
  setSelectedDate,
  setSelectedDeliveryMode,
  setSelectedStatus,
  setSelectedTime,
  setTypeOfAppointment
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ActivityContent from './components/ActivityContent/ActivityContent';
import { setCurrentStep } from 'redux/processAppointment/processAppointmentSlice';
import { AppointmentStatusType } from 'interfaces/Schedule/Appointment';
import { Datepicker } from '@mobiscroll/react';

interface InlineBookingFormProps {
  onSelectOtherType: () => void;
  practitionerMainOption?: boolean;
  rememberSelectedPractitioner?: boolean;
  showSelectedItemOnFirstOption?: boolean;
  onInlineRef?: (ref: Datepicker | null) => void;
  dropdownRef?: RefObject<HTMLDivElement>;
}

const InlineBookingForm = ({
  onSelectOtherType,
  practitionerMainOption,
  rememberSelectedPractitioner,
  showSelectedItemOnFirstOption,
  onInlineRef,
  dropdownRef
}: InlineBookingFormProps) => {
  const dispatch = useAppDispatch();
  const { originalDateTime, selectedClientType, isActivity } = useAppSelector(selectAppointmentData);
  const typeOfAppointment = useAppSelector(selectTypeOfAppointment);

  const TYPE_OF_APPOINTMENTS: ToggleList[] = useMemo(
    () => [
      {
        id: TypeOfAppointment.Appointment,
        label: '1:1 Appointment',
        isActive: typeOfAppointment === TypeOfAppointment.Appointment
      },
      {
        id: TypeOfAppointment.Activity,
        label: 'Activity',
        isActive: typeOfAppointment === TypeOfAppointment.Activity
      },
      {
        id: TypeOfAppointment.Other,
        label: 'Other',
        isActive: typeOfAppointment === TypeOfAppointment.Other
      }
    ],
    [typeOfAppointment]
  );

  const handleChangeStatus = (status: ToggleList) => {
    const isActivity = status.id === TypeOfAppointment.Activity;

    dispatch(setSelectedAppointmentType(undefined));
    dispatch(setSelectedDeliveryMode(undefined));
    dispatch(setSelectedDate(originalDateTime.date));
    dispatch(setSelectedTime({ startTime: originalDateTime.startTime, endTime: originalDateTime.endTime }));
    dispatch(setRate(''));
    dispatch(setIgnoreRoomConflict(false));
    dispatch(setCurrentStep(AppointmentHumanFactorStep.Practitioner));
    dispatch(setIsActivity(isActivity));
    dispatch(setTypeOfAppointment(status.id));
    dispatch(setSelectedStatus(AppointmentStatusType.Free));

    if (!isActivity && selectedClientType === AppointmentClientOrGroupType.Group) {
      dispatch(setSelectedClient(undefined));
      dispatch(setSelectedClientType(AppointmentClientOrGroupType.Client));
    }

    if (status.id === TypeOfAppointment.Other) {
      onSelectOtherType();
    }
  };

  return (
    <>
      <div id={'handle'} className={styles.typeOfAppointmentWrapper}>
        <ToggleSwitchV2
          id={'typeOfAppointment'}
          toggleList={TYPE_OF_APPOINTMENTS}
          className={styles.typeOfAppointmentClassName}
          wrapperClassName={styles.typeOfAppointmentWrapperClassName}
          labelClassName={styles.typeOfAppointmentLabelClassName}
          activeLabelClassName={styles.typeOfAppointmentActiveLabelClassName}
          indicatorClassName={styles.typeOfAppointmentIndicatorClassName}
          onChangeStatus={handleChangeStatus}
          t23Theme
          size={'small'}
        />
      </div>
      <div>
        {isActivity ? (
          <ActivityContent
            practitionerMainOption={practitionerMainOption}
            rememberSelectedPractitioner={rememberSelectedPractitioner}
            showSelectedItemOnFirstOption={showSelectedItemOnFirstOption}
            onInlineRef={onInlineRef}
          />
        ) : (
          <AppointmentContent
            practitionerMainOption={practitionerMainOption}
            rememberSelectedPractitioner={rememberSelectedPractitioner}
            showSelectedItemOnFirstOption={showSelectedItemOnFirstOption}
            onInlineRef={onInlineRef}
            dropdownRef={dropdownRef}
          />
        )}
      </div>
    </>
  );
};

export default InlineBookingForm;
