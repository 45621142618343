import classnames from 'classnames';
import MaterialLabel from 'components/MaterialLabel/MaterialLabel';
import Select from 'components/Select/CommonSelect/Select';
import { useEffect, useState } from 'react';

import styles from './MaterialDropdown.module.scss';

interface Option {
  value: string;
  label: string;
}

interface MaterialDropdownProps {
  id: string;
  label: string;
  dropDownList: Option[];
  selectedValue?: string;
  className?: string;
  fieldClassName?: string;
  labelClassName?: string;
  labelHelpWithTooltips?: string;
  placeholder?: string;
  isClearable?: boolean;
  onChangeValue: (value: string) => void;
}

const MaterialDropdown = ({
  id,
  label,
  dropDownList,
  selectedValue,
  className,
  fieldClassName,
  labelClassName,
  labelHelpWithTooltips,
  placeholder,
  isClearable,
  onChangeValue
}: MaterialDropdownProps) => {
  const minimisedLabel = !!selectedValue?.length || !!selectedValue || !!placeholder;
  const [isLabelMinimised, setIsLabelMinimised] = useState(minimisedLabel);

  useEffect(() => {
    setIsLabelMinimised(minimisedLabel);
  }, [minimisedLabel]);

  const handleChange = (val: Option | null) => {
    onChangeValue(val?.value || '');
    setIsLabelMinimised((val?.value && val.value.length > 0) || !!val || !!placeholder);
  };

  const handleFocus = () => {
    setIsLabelMinimised(true);
  };

  const handleBlur = () => {
    setIsLabelMinimised(!!selectedValue?.length || !!placeholder);
  };

  return (
    <div className={classnames(styles.container, className)}>
      <MaterialLabel
        id={id}
        label={label}
        isLabelMinimised={isLabelMinimised}
        labelClassName={classnames(styles.label, labelClassName)}
        labelHelpWithTooltips={labelHelpWithTooltips}
      />
      <Select
        id={id}
        className={classnames(fieldClassName, styles.selectField)}
        isSearchable={false}
        options={dropDownList}
        placeholder={placeholder || ''}
        labelClass={styles.selectLabel}
        menuPlacement={'auto'}
        value={selectedValue}
        onChange={(val: any) => handleChange(val)}
        onFocus={() => handleFocus()}
        onBlur={() => handleBlur()}
        smallCaretDown
        isClearable={isClearable}
        styles={{
          container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
          valueContainer: (base: any) => ({
            ...base,
            minHeight: '28px',
            padding: '0 8px',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '22px'
          }),
          singleValue: (singleValueBase: any) => ({
            ...singleValueBase,
            fontSize: '16px',
            lineHeight: '22px',
            cursor: 'pointer'
          }),
          control: (controlBase: any) => ({
            ...controlBase,
            minHeight: '24px',
            backgroundColor: 'transparent',
            border: 'none',
            borderBottom: `1px solid ${styles.greyColor} `,
            borderRadius: 0,
            boxShadow: 'none',
            cursor: 'pointer'
          }),
          indicatorsContainer: (indicatorBase: any) => ({
            ...indicatorBase,
            cursor: 'pointer'
          })
        }}
      />
    </div>
  );
};

export default MaterialDropdown;
