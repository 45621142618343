import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './SignUpProgressBar.module.scss';

interface SignUpProgressBarProps {
  stage: number;
  stages: string[];
}

const SignUpProgressBar = ({ stage, stages }: SignUpProgressBarProps) => {
  return (
    <div className={styles.container}>
      <ContentLayout>
        <div className={styles.progressBar}>
          {stages.map((stageLabel, index) => {
            const isActive = index === stage;
            const isCompleted = index < stage;

            const iconClasses = `${styles.icon} ${isActive ? styles.isActive : ''}`;
            const labelClasses = `${styles.label} ${isActive ? styles.isActive : ''}`;

            return (
              <div className={styles.stage} key={index}>
                <div className={isCompleted ? styles.iconComplete : iconClasses}>
                  {isCompleted ? <FontAwesomeIcon icon={faCheck} className={styles.checkmark} /> : index + 1}
                </div>
                <div className={labelClasses}>{stageLabel}</div>
              </div>
            );
          })}
        </div>
      </ContentLayout>
    </div>
  );
};

export default SignUpProgressBar;
