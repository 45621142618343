import ReferralDocuments from './components/ReferralDocuments/ReferralDocuments';
import GPDetails from './components/GPDetails/GPDetails';
import ReferralInformation from './components/ReferralInformation/ReferralInformation';
import AddOrEditReferralForm from './components/AddOrEditReferralForm/AddOrEditReferralForm';

import styles from './ReferralsMVP.module.scss';
import { Referral, ReferralEntrySource } from 'interfaces/Referral/Referral';
import Card from 'components/Card/Card';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { useState } from 'react';
import {
  useCreateReferralMutation,
  useUpdateReferralMutation
} from 'redux/endpoints/clinicianProfileServices/referral';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { notification } from 'antd';

interface ReferralsMVPProps {
  referrals: Referral[];
  recordId: string;
}

export enum Mode {
  View = 'view',
  Add = 'add',
  Edit = 'edit'
}

const REFERRAL_VERSION = '1';

const ReferralsMVP = ({ referrals, recordId }: ReferralsMVPProps) => {
  const { accountId } = useGetAccountId();

  const [mode, setMode] = useState<Mode>(Mode.View);
  const [selectedReferral, setSelectedReferral] = useState<Referral | undefined>(referrals[referrals.length - 1]);
  const [currentIndex, setCurrentIndex] = useState<number>(referrals.length - 1);

  const [createReferral] = useCreateReferralMutation();
  const [updateReferral] = useUpdateReferralMutation();

  const handleSubmit = async (payload: Omit<Referral, '_id' | 'clientRecordId' | 'createdAt'>) => {
    if (mode === Mode.Add) {
      try {
        await createReferral({
          accountId,
          payload: {
            ...payload,
            clientRecordId: recordId,
            version: REFERRAL_VERSION
          }
        });
        notification.success({
          message: 'Referral Created',
          description: 'Referral has been successfully created'
        });
        setMode(Mode.View);
      } catch (error) {
        notification.error({
          message: 'Referral Creation Failed',
          description: 'Failed to create referral'
        });
      }
    }

    if (mode === Mode.Edit) {
      try {
        await updateReferral({
          accountId,
          referralId: selectedReferral?._id || '',
          payload: {
            ...payload,
            entrySource: ReferralEntrySource.Manual,
            clientRecordId: recordId,
            version: REFERRAL_VERSION
          }
        });
        notification.success({
          message: 'Referral Updated',
          description: 'Referral has been successfully updated'
        });
        setCurrentIndex(referrals.findIndex((referral) => referral._id === selectedReferral?._id));
        setSelectedReferral(referrals.find((referral) => referral._id === selectedReferral?._id));
        setMode(Mode.View);
      } catch (error) {
        notification.error({
          message: 'Referral Update Failed',
          description: 'Failed to update referral'
        });
      }
    }
  };

  if (mode === Mode.View) {
    return (
      <Card className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            Referral
            {referrals.length ? (
              <DropdownSearchable
                className={styles.input}
                placeholder="Select Referral"
                options={referrals.map((referral, idx) => ({
                  label: `#${idx + 1} | ${referral.referrer.name} - ${referral.referrer.role} | ${referral.referralId}`,
                  value: referral._id || ''
                }))}
                selected={selectedReferral?._id || ''}
                onSelect={(value: string) => {
                  if (!value) return;
                  setSelectedReferral(referrals.find((referral) => referral._id === value));
                  setCurrentIndex(referrals.findIndex((referral) => referral._id === value));
                }}
                hideErrorDesc
              />
            ) : null}
          </div>
          <ButtonAlt
            variant="outlined"
            onClick={() => {
              setMode(Mode.Add);
              setCurrentIndex(referrals.length);
              setSelectedReferral(undefined);
            }}
            icon="add_circle_outline"
          >
            Add New Referral
          </ButtonAlt>
        </div>
        {selectedReferral ? (
          <>
            <ReferralInformation referral={selectedReferral} setMode={setMode} />
            <ReferralDocuments referral={selectedReferral} />
            <GPDetails referral={selectedReferral} />
          </>
        ) : (
          'No Referral Added'
        )}
      </Card>
    );
  }

  if (mode === Mode.Add || mode === Mode.Edit) {
    return (
      <Card className={styles.editingContainer}>
        <AddOrEditReferralForm
          clientRecordId={recordId}
          currentIndex={currentIndex}
          selectedReferral={selectedReferral}
          mode={mode}
          setMode={setMode}
          handleSubmit={handleSubmit}
          onCancel={() => setSelectedReferral(referrals[referrals.length - 1])}
        />
      </Card>
    );
  }

  return null;
};

export default ReferralsMVP;
