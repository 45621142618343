import { Skeleton, notification } from 'antd';
import InvoiceListItem from './components/InvoiceListItem/InvoiceListItem';
import styles from './InvoiceList.module.scss';
import InvoiceListFilter from './components/InvoiceListFilter/InvoiceListFilter';
import InvoiceListHeader from './components/InvoiceListHeader/InvoiceListHeader';
import { useGetClinicianProfileQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import PerRecordDropdown from 'components/TableList/PerRecordDropdown/PerRecordDropdown';
import PaginationDescV2 from 'components/TableList/PaginationDescV2/PaginationDescV2';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  setPaging,
  selectPaging,
  setSelectedInvoice,
  setIsShowRefundModal,
  setIsShowUpdateBalanceModal,
  setIsShowWriteOffModal,
  selectSelectedInvoice,
  selectIsShowCreateClaimModal,
  setIsShowCreateClaimModal,
  selectIsShowViewClaimModal,
  selectSelectedClaim,
  setIsShowViewClaimModal,
  setIsShowManualAdjustTotalModal
} from 'redux/invoices/invoiceSlice';
import { useGetInvoicesList } from 'redux/invoices/useGetInvoices';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { Invoice, InvoiceStatus, InvoiceStatusToBeChange } from 'pages/Invoices/interface';
import { useAdjustTotalAmountMutation, useUpdateInvoiceStatusMutation } from 'redux/endpoints/billingServices/invoices';
import { InvoiceWithType, getCreateClaimPayload, massageInvoiceData } from './constants';
import { useFullRefund } from 'redux/invoices/useFullRefund';
import { setIsRefreshDataDisabled } from 'redux/clients/clientDetailsSlice';
import { DataRefreshHandle } from 'pages/PatientDetails/components/PatientDetailsContent/PatientDetailsContent';
import { useFetchPaymentMethods } from 'pages/Invoices/Invoices';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';
import {
  useCreateClaimMutation,
  useGetPendingClaimInvoiceListQuery
} from 'redux/endpoints/billingServices/pendingClaimInvoice';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetLocationsQuery } from 'redux/endpoints/billingServices/location';
import { getClaimInvoiceDetailsInfo } from 'pages/InvoicePendingClaims/utils';
import CreateClaimModal from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/components/ClaimHistory/components/CreateClaimModal/CreateClaimModal';
import { getSelectedClaimType } from 'pages/InvoicePendingClaims/components/PendingClaimInvoiceList/utils';
import Loading from 'components/Loading/Loading';
import { useGetSubmittedClaimListQuery } from 'redux/endpoints/billingServices/submittedClaim';
import ClaimStatementModal from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/components/ClaimHistory/components/ClaimItem/components/ClaimStatementModal/ClaimStatementModal';
import { PER_PAGE_DEFAULT } from 'redux/features/utils';

const InvoiceList = forwardRef<DataRefreshHandle, { skipFetchInvoices?: boolean }>(({ skipFetchInvoices }, ref) => {
  const { accountId } = useGetAccountId();
  const { isEdgeUserView } = useGetAccountPackageView();
  const { isSomeoneHealthFeatureToggle } = useGetFeatureToggle();
  const { data: profileData, isLoading: isProfileLoading } = useGetClinicianProfileQuery();
  const { paymentMethods } = useFetchPaymentMethods();
  const [updateInvoiceStatus] = useUpdateInvoiceStatusMutation();
  const { onFullRefund } = useFullRefund();
  const { INVOICES } = useRoutesGenerator();
  const navigate = useNavigate();
  const [postCreateClaim, { isLoading }] = useCreateClaimMutation();
  const [patchTotalAmount, { isLoading: isPatchTotalAmountProcessing }] = useAdjustTotalAmountMutation();

  const dispatch = useAppDispatch();
  const paging = useAppSelector(selectPaging);
  const selectedInvoice = useAppSelector(selectSelectedInvoice);
  const isCreateClaimModalVisible = useAppSelector(selectIsShowCreateClaimModal);
  const isShowViewClaimModal = useAppSelector(selectIsShowViewClaimModal);
  const selectedClaim = useAppSelector(selectSelectedClaim);

  const {
    isInvoicesLoading,
    isError,
    invoices,
    totalItems,
    refetch: refetchInvoices
  } = useGetInvoicesList(skipFetchInvoices);

  const pendingClaimInvoiceIds: string[] = invoices.flatMap((invoice) =>
    invoice.invoiceType === PendingClaimsFunder.BULK_BILL && invoice.status === InvoiceStatus.PendingClaim
      ? invoice._id
      : []
  );
  const submittedClaimInvoiceIds: string[] = invoices.flatMap((invoice) =>
    invoice.invoiceType === PendingClaimsFunder.BULK_BILL &&
    [InvoiceStatus.ClaimComplete, InvoiceStatus.ClaimSuccess, InvoiceStatus.ClaimRejected].includes(invoice.status)
      ? invoice._id
      : []
  );
  const {
    data: pendingClaimListData,
    isLoading: isPendingClaimListDataLoading,
    isFetching: isPendingClaimListDataFetching,
    isError: isFetchPendingClaimListError
  } = useGetPendingClaimInvoiceListQuery(
    {
      accountId,
      params: {
        ...(isEdgeUserView && {
          asUser: '1'
        }),
        page: 1,
        perPage: paging.perPage,
        ids: pendingClaimInvoiceIds.join(',')
      }
    },
    { skip: pendingClaimInvoiceIds.length < 1 || isInvoicesLoading }
  );

  const {
    data: submittedClaimListData,
    isLoading: isSubmittedClaimListDataLoading,
    isFetching: isSubmittedClaimListDataFetching,
    isError: isFetchSubmittedClaimListError,
    refetch: refetchSubmittedClaimList
  } = useGetSubmittedClaimListQuery(
    {
      accountId,
      params: {
        ...(isEdgeUserView && {
          asUser: '1'
        }),
        page: 1,
        perPage: paging.perPage,
        invoiceIds: submittedClaimInvoiceIds.join(',')
      }
    },
    { skip: submittedClaimInvoiceIds.length < 1 || isInvoicesLoading }
  );

  const {
    data: locationData,
    isLoading: isLocationDataLoading,
    isFetching: isLocationDataFetching,
    isError: isFetchLocationDataError
  } = useGetLocationsQuery({ active: true }, { skip: pendingClaimInvoiceIds.length < 1 || isInvoicesLoading });

  const isExistLocationWithType = useMemo(
    () =>
      !isLocationDataLoading &&
      !isLocationDataFetching &&
      !isFetchLocationDataError &&
      locationData &&
      locationData.locations.filter((location) => location.type).length > 0,
    [locationData, isLocationDataLoading, isLocationDataFetching, isFetchLocationDataError]
  );

  const massagedInvoiceList: InvoiceWithType[] = useMemo(() => {
    if (
      !isInvoicesLoading &&
      !isPendingClaimListDataLoading &&
      !isPendingClaimListDataFetching &&
      !isFetchPendingClaimListError &&
      !isSubmittedClaimListDataLoading &&
      !isSubmittedClaimListDataFetching &&
      !isFetchSubmittedClaimListError
    ) {
      return massageInvoiceData({
        invoiceList: invoices,
        pendingClaimList: pendingClaimListData?.invoices || [],
        submittedClaimList: submittedClaimListData?.claims || []
      });
    }
    return invoices;
  }, [
    invoices,
    isInvoicesLoading,
    isPendingClaimListDataLoading,
    isPendingClaimListDataFetching,
    isFetchPendingClaimListError,
    pendingClaimListData?.invoices,
    isSubmittedClaimListDataLoading,
    isSubmittedClaimListDataFetching,
    isFetchSubmittedClaimListError,
    submittedClaimListData?.claims
  ]);

  const showInvoiceType = isSomeoneHealthFeatureToggle;
  const stripeAccountId =
    paymentMethods.find((payment) => payment.paymentType === 'stripe' && payment.stripeStatus === 'completed')
      ?.stripeAccountId || '';

  const {
    clientProfile,
    parentProfile,
    isDvaClient,
    isMedicareClient,
    isGPReferralProviderNumberSet,
    isReferralDateSet
  } = getClaimInvoiceDetailsInfo(selectedInvoice?.foundClientRecord, selectedInvoice?.foundGeneralPractitioner);

  const locationsFiltered = useMemo(() => {
    if (!isLocationDataLoading && !isLocationDataFetching && !isFetchLocationDataError && locationData) {
      return locationData.locations.filter(({ type }) => !isDvaClient || type);
    }
  }, [locationData, isLocationDataLoading, isLocationDataFetching, isFetchLocationDataError, isDvaClient]);

  const onChangePage = (page: number) => {
    dispatch(setPaging({ ...paging, page }));
  };

  const onChangePerPage = (perPage: number) => {
    dispatch(setPaging({ ...paging, perPage, page: 1 }));
  };

  useEffect(() => {
    if (isError && !isInvoicesLoading) {
      notification.error({ message: 'Something went wrong while trying to fetch invoices.' });
    }
  }, [isInvoicesLoading, isError]);

  useEffect(() => {
    dispatch(setIsRefreshDataDisabled(isInvoicesLoading));
  }, [isInvoicesLoading, dispatch]);

  useImperativeHandle(ref, () => ({
    onDataRefresh: () => {
      refetchInvoices();
    }
  }));

  const refetchListAfterCreateClaim = () => {
    refetchInvoices();
  };

  const onChangeStatus = async (
    _id: string,
    status: InvoiceStatusToBeChange,
    groupDetail?: Invoice['group'],
    isRecreate?: boolean
  ) => {
    try {
      notification.warning({
        message: 'Updating invoice status...',
        duration: 2,
        closeIcon: <span className={'notify'}>OK</span>
      });

      await updateInvoiceStatus({ invoiceId: _id, status: status }).unwrap();

      notification.destroy();
      notification.success({
        message: 'Invoice status updated.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      if (isRecreate) {
        const foundInvoice = invoices.find((invoice) => invoice._id === _id);
        navigate(`${INVOICES.BASE}/new`, { state: { invoice: { ...foundInvoice, isRecreate: true } } });
      }
    } catch (ex) {
      notification.error({
        message: 'Status update failed. Please try again, if failures persist please contact us to investigate.',
        duration: 2
      });
    }
  };

  const onPartialRefund = (invoice: InvoiceWithType) => {
    dispatch(setSelectedInvoice(invoice));
    dispatch(setIsShowRefundModal(true));
  };

  const onUpdateBalance = (invoice: InvoiceWithType) => {
    dispatch(setSelectedInvoice(invoice));
    dispatch(setIsShowUpdateBalanceModal(true));
  };

  const onManualAdjustTotal = (invoice: InvoiceWithType) => {
    dispatch(setSelectedInvoice(invoice));
    dispatch(setIsShowManualAdjustTotalModal(true));
  };

  const onMatchMBSBenefit = async (invoice: InvoiceWithType) => {
    if (invoice.submittedClaim?.benefit) {
      notification.info({
        message: "Adjusting the invoice's total amount...",
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      try {
        await patchTotalAmount({
          invoiceId: invoice._id,
          invoiceAmount: invoice.submittedClaim?.benefit
        }).unwrap();

        notification.success({
          message: "The invoice's total amount has been successfully adjusted.",
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      } catch (ex) {
        notification.error({
          message:
            "Adjusting the invoice's total amount failed. Please try again, if failures persist please contact us to investigate."
        });
      }
    }
  };

  const onCreateClaim = async (invoice: InvoiceWithType) => {
    const payload = getCreateClaimPayload(invoice);

    try {
      await postCreateClaim({
        minorId: invoice.foundProvider?.locationMinorId || '',
        providerId: invoice.foundProvider?.providerNumber || '',
        payload
      }).unwrap();

      notification.success({
        message: 'Claim created'
      });
    } catch (error) {
      notification.error({ message: (error as { message: string }).message });
      console.error(error);
    }
  };

  const onWriteOff = (invoice: InvoiceWithType) => {
    dispatch(setSelectedInvoice(invoice));
    dispatch(setIsShowWriteOffModal(true));
  };

  return (
    <>
      {(isLoading || isPatchTotalAmountProcessing) && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}

      <div className={styles.container}>
        {/* Filters */}
        <InvoiceListFilter showInvoiceType={showInvoiceType} skipFetchInvoices={skipFetchInvoices} />

        <div className={styles.listWrapper}>
          {<InvoiceListHeader showInvoiceType={showInvoiceType} />}

          {isInvoicesLoading || isProfileLoading ? (
            <div className={styles.loadingWrapper}>
              {[...Array(10)].map((_, i) => (
                <div key={i} className={styles.loadingItem}>
                  <Skeleton.Input active className={styles.loadingContent} />
                </div>
              ))}
            </div>
          ) : massagedInvoiceList.length < 1 ? (
            <div>No invoices</div>
          ) : (
            <div className={styles.listItem}>
              {massagedInvoiceList.map((invoice, index) => (
                <InvoiceListItem
                  key={index}
                  invoice={invoice}
                  isExistLocationWithType={isExistLocationWithType}
                  stripeAccountId={stripeAccountId}
                  practiceName={profileData?.practice?.name || ''}
                  showInvoiceType={showInvoiceType}
                  isPendingClaimDataLoading={isPendingClaimListDataFetching || isPendingClaimListDataLoading}
                  isSubmittedClaimDataLoading={isSubmittedClaimListDataFetching || isSubmittedClaimListDataLoading}
                  onChangeStatus={onChangeStatus}
                  onPartialRefund={() => {
                    onPartialRefund(invoice);
                  }}
                  onFullRefund={() => {
                    onFullRefund(invoice);
                  }}
                  onUpdateBalance={() => {
                    onUpdateBalance(invoice);
                  }}
                  onWriteOff={() => {
                    onWriteOff(invoice);
                  }}
                  onCreateClaim={() => {
                    onCreateClaim(invoice);
                  }}
                  onManualAdjustTotal={() => {
                    onManualAdjustTotal(invoice);
                  }}
                  onMatchMBSBenefit={() => {
                    onMatchMBSBenefit(invoice);
                  }}
                />
              ))}
            </div>
          )}
        </div>

        {/* Pagination */}
        {!isInvoicesLoading && totalItems > PER_PAGE_DEFAULT && (
          <div className={styles.paginationWrapper}>
            <div className={styles.recordPerPageWrapper}>
              <PerRecordDropdown
                totalItem={totalItems}
                selectedPerPage={paging.perPage}
                selectDropdownValue={onChangePerPage}
              />
              <div className={styles.label}>invoices per page</div>
            </div>
            <PaginationDescV2
              currentPage={paging.page}
              totalItem={totalItems}
              perPage={paging.perPage}
              onPageChange={onChangePage}
            />
          </div>
        )}
        {selectedInvoice && clientProfile && (
          <CreateClaimModal
            parentProfile={parentProfile}
            visible={isCreateClaimModalVisible}
            clientRecordId={selectedInvoice.foundClientRecord?._id || ''}
            clientProfile={clientProfile}
            isMedicareClient={isMedicareClient}
            isDvaClient={isDvaClient}
            referral={{
              name: selectedInvoice.foundGeneralPractitioner?.name || '',
              date: selectedInvoice.foundClientRecord?.referral?.date || '',
              providerNumber: selectedInvoice.foundGeneralPractitioner?.medicareProviderNumber || ''
            }}
            onCancel={() => {
              dispatch(setIsShowCreateClaimModal(false));
            }}
            preSelectedStep={2}
            hideStepProcessBar
            preSelectedInvoice={selectedInvoice}
            preSelectedClaimType={getSelectedClaimType(selectedInvoice.funder)}
            locations={locationsFiltered || []}
            refetchClaims={refetchListAfterCreateClaim}
            isClientReferralValid={isGPReferralProviderNumberSet && isReferralDateSet}
          />
        )}

        {selectedClaim && (
          <ClaimStatementModal
            visible={isShowViewClaimModal}
            claim={selectedClaim}
            invoice={selectedClaim.foundInvoice}
            handleCancel={() => dispatch(setIsShowViewClaimModal(false))}
            refetchClaims={refetchSubmittedClaimList}
          />
        )}
      </div>
    </>
  );
});

export default InvoiceList;
