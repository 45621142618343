import { useState } from 'react';
import { Input, Modal, notification } from 'antd';
import classnames from 'classnames';
import moment from 'moment';

import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';

import { useGetAccessToken } from 'utils/hooks/token';
import { putSendConsentInvitation } from 'utils/http/DocumentService/ConsentForm/consentForm';
import { usePrefillProfileConsentForm } from './hook/PrefillValue';

import ConsentSelect from 'components/AddPatientModalV2/components/Onboarding/components/ConsentSelect/ConsentSelect';
import CheckBox from 'components/CheckBox/CheckBox';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import InviteTimeForm from 'components/InviteTimeForm/InviteTimeForm';
import { inviteTimeInterface } from 'components/InviteTimeForm/InviteTimeInterface';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import OptionLabel from 'components/OptionLabel/OptionLabel';
import ProfileSelect from './component/ProfileSelect/ProfileSelect';

import { getName } from 'utils/general';

import styles from './SendConsentModal.module.scss';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

const formatSendTime = (sendTimeValue: string, customTime?: string) => {
  const nowTime = moment().format('HH:mm');

  let sendAtDateTime: Date;

  if (sendTimeValue === '9') {
    if (nowTime > '09:00') {
      sendAtDateTime = moment().add('1', 'day').hour(9).minute(0).second(0).toDate();
    } else {
      sendAtDateTime = moment().hour(9).minute(0).second(0).toDate();
    }
  } else if (sendTimeValue === '13') {
    if (nowTime > '13:00') {
      sendAtDateTime = moment().add('1', 'day').hour(13).minute(0).second(0).toDate();
    } else {
      sendAtDateTime = moment().hour(13).minute(0).second(0).toDate();
    }
  } else if (sendTimeValue === 'custom') {
    sendAtDateTime = moment(customTime).toDate() || nowTime;
  } else {
    sendAtDateTime = moment().toDate();
  }

  return sendAtDateTime;
};

const putNewSendConsentForm = async (
  token: string,
  sendConsentForm: SendConsentForm,
  consentList: CheckBoxConsentListing[],
  clientRecordId: string
) => {
  const profiles = sendConsentForm.profileList
    .filter((profile) => profile.isChecked)
    .map(({ profileId, consentId, receiver }) => ({
      clientProfileId: profileId,
      consentId,
      receiverId: receiver?._id
    }));

  const payload = {
    profiles,
    sendAt: formatSendTime(sendConsentForm.inviteTime.sendAt, sendConsentForm.inviteTime.customSendTime),
    note: sendConsentForm.note
  };

  await putSendConsentInvitation(token, clientRecordId, payload);
};
export interface CheckBoxConsentListing {
  label: string;
  value: string;
  isDefault: boolean;
  isOneSignature: boolean;
}
export interface ProfileConsentForm {
  profileId: string;
  name: string;
  firstName: string;
  lastName: string;
  consentId: string;
  consentName: string;
  isChecked: boolean;
  isValid: boolean;
  avatar?: string;
  initials: string;
  initialsBackgroundColor: string;
  role?: string;
  receiver?: {
    _id: string;
    name: string;
    isValid: boolean;
  };
  isCommunicationsNotAllowed: boolean;
}

export interface SendConsentForm {
  profileList: ProfileConsentForm[];
  inviteTime: inviteTimeInterface;
  note: string;
}
interface SendConsentModalProps {
  onCancel?: () => void;
  visible: boolean;
  clientProfileList: clientProfilesInterface[];
  clientRecordId: string;
  notificationSettings: NotificationSettingsInterface;
}

const SendConsentModal = ({
  onCancel,
  visible,
  clientProfileList,
  clientRecordId,
  notificationSettings
}: SendConsentModalProps) => {
  const { token } = useGetAccessToken();
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [step, setStep] = useState<1 | 2>(1);
  const [isReceiverValid, setIsReceiverValid] = useState<boolean>(true);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const isHaveChildProfile = clientProfileList.find((profile) => profile.role === 'child');
  const [t] = useTranslation();

  const { sendConsentForm, setSendConsentForm, prefillLoading, consentList, profileOption, fetchPrefillProfilesData } =
    usePrefillProfileConsentForm(token, clientProfileList, notificationSettings);

  const handleSelectConsent = (index: number, val: string) => {
    sendConsentForm.profileList[index].consentId = val;
    sendConsentForm.profileList[index].consentName = consentList.find((consent) => consent.value === val)?.label || '';
    setSendConsentForm({ ...sendConsentForm, profileList: [...sendConsentForm.profileList] });
  };

  const handleSelectProfile = (index: number, val: string) => {
    if (sendConsentForm.profileList[index].role === 'child') {
      sendConsentForm.profileList[index].receiver = {
        _id: val,
        name: profileOption.find((profile) => profile.value === val)?.label || '',
        isValid: !!val
      };
    }
    setSendConsentForm({ ...sendConsentForm, profileList: [...sendConsentForm.profileList] });
  };

  const handleProfileChange = (index: number, val: boolean) => {
    sendConsentForm.profileList[index].isChecked = val;
    setSendConsentForm({ ...sendConsentForm, profileList: [...sendConsentForm.profileList] });
  };

  const handleChangeInviteTime = (val: inviteTimeInterface) => {
    sendConsentForm.inviteTime = val;
    setSendConsentForm({ ...sendConsentForm });
  };

  const onChangeNote = (val: string) => {
    sendConsentForm.note = val;
    setSendConsentForm({ ...sendConsentForm });
  };

  const isStep1Valid = (): boolean => {
    const checkProfileSelect = !sendConsentForm.profileList.find(
      (profile) => profile.role === 'child' && !profile.receiver?._id
    );
    setIsReceiverValid(checkProfileSelect);

    const checkProfileList = sendConsentForm.profileList.map((profile) => ({
      ...profile,
      isValid: !(!profile.consentId && profile.isChecked)
    }));
    setSendConsentForm({ ...sendConsentForm, profileList: checkProfileList });
    return !checkProfileList.find((profile) => !profile.isValid) && checkProfileSelect;
  };

  const isStep2Valid = (): boolean => {
    const inviteTimeCheck =
      sendConsentForm.inviteTime.sendAt !== 'custom' || !!sendConsentForm.inviteTime.customSendTime;
    return inviteTimeCheck;
  };

  const handleMoveToStep2 = () => {
    if (!isStep1Valid()) return;
    setStep(2);
  };

  const handleSubmitConsent = async () => {
    setIsSubmit(true);
    if (!(isStep2Valid() && isStep1Valid())) return;
    setSubmitStatus('active');

    try {
      await putNewSendConsentForm(token, sendConsentForm, consentList, clientRecordId);

      setTimeout(() => {
        setSubmitStatus('finished');
      }, 500);
      setTimeout(async () => {
        notification.success({
          message: 'Successfully sent consent form',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitStatus('');
        if (onCancel) {
          onCancel();
        }
        await fetchPrefillProfilesData(clientProfileList);
        setStep(1);
        setIsReceiverValid(true);
        setIsSubmit(false);
      }, 1000);
    } catch (e) {
      console.error(e);
      setSubmitStatus('');
      notification.error({
        message: 'Something went wrong while trying to send your consent form. Please try again.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={
        <div className={styles.title}>
          Sending Consent Form
          {isHaveChildProfile && (
            <HelpOutLineWithTooltips
              id={'sendConsentHeadingInfo'}
              desc={`The request for a child to sign consent form will be sent to the child's primary contact.`}
            />
          )}
        </div>
      }
      open={visible}
      footer={null}
      onCancel={onCancel}
    >
      {prefillLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : consentList.length === 0 ? (
        <div className={styles.container}>
          <div className={styles.noListLabel}>No consent forms available.</div>
        </div>
      ) : (
        <>
          {step === 1 && (
            <div className={styles.container}>
              <div className={styles.secondHeaderTitle}>{t('label.select_client')}</div>
              {sendConsentForm.profileList.map((profile, index) => (
                <div
                  key={`profile-${index}`}
                  className={classnames(styles.itemWrapper, profile.isCommunicationsNotAllowed && styles.disabled)}
                >
                  <CheckBox
                    className={styles.checkBox}
                    labelClassName={styles.checkBoxLabel}
                    id={`checkBox-${index}`}
                    value={profile.isChecked}
                    disabled={profile.isCommunicationsNotAllowed}
                    label={
                      <>
                        <ClientProfileAvatar
                          avatarClassName={styles.avatar}
                          initialClassName={styles.avatar}
                          avatarUrl={profile.avatar}
                          initialsName={profile.initials}
                        />
                        <div className={styles.clientLabel}>
                          <OptionLabel
                            containerClassName={styles.nameContainer}
                            titleClassName={styles.clientName}
                            title={getName(profile)}
                            descClassName={styles.note}
                            desc={
                              profile.role === 'child' && profile.isChecked
                                ? profile.receiver && profile.receiver.name
                                  ? `(${profile.receiver.name} will receive the request)`
                                  : 'No contact to receive the request'
                                : ''
                            }
                          />
                          {profile.isCommunicationsNotAllowed && (
                            <HelpOutLineWithTooltips
                              id={`noAllowAdd${index}`}
                              desc={t('form.error.send_consent_limited_communication')}
                            />
                          )}
                        </div>
                      </>
                    }
                    onChange={(e) => handleProfileChange(index, e.target.checked)}
                  />
                  {profile.isChecked && (
                    <div className={styles.selectContainer}>
                      <ConsentSelect
                        key={`consent${index}`}
                        id={`consent${index}`}
                        clientName={sendConsentForm.profileList.length > 1 ? getName(profile) : ''}
                        value={profile.consentId}
                        checkValidation={!profile.isValid}
                        consentList={consentList}
                        isListLoading={prefillLoading}
                        onChangeConsentValue={(val: string) => handleSelectConsent(index, val)}
                      />
                      {profile.consentId &&
                        !consentList.find((consent) => consent.value === profile.consentId)?.isOneSignature && (
                          <div className={styles.note}>({t('validation.client_and_guardian_signature.require')})</div>
                        )}
                      {profile.role === 'child' && (
                        <div className={styles.profileSelect}>
                          <ProfileSelect
                            key={`profile${index}`}
                            id={`profile${index}`}
                            value={profile.receiver?._id || ''}
                            checkValidation={!isReceiverValid}
                            profileList={profileOption}
                            isListLoading={prefillLoading}
                            onChangeConsentValue={(val: string) => handleSelectProfile(index, val)}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
              <div className={styles.submitButtonContainer}>
                <ButtonAlt
                  status={submitStatus}
                  onClick={handleMoveToStep2}
                  disabled={!sendConsentForm.profileList.find((profile) => profile.isChecked)}
                >
                  Next
                </ButtonAlt>
              </div>
            </div>
          )}
          {step === 2 && (
            <>
              <ButtonAlt
                className={styles.backBtn}
                variant={'text'}
                size={'medium'}
                onClick={() => setStep(1)}
                icon={'arrow_back_ios'}
              >
                Back
              </ButtonAlt>
              <div className={styles.step2Container}>
                <div className={styles.profileWrapper}>
                  {sendConsentForm.profileList
                    .filter((profile) => profile.isChecked)
                    .map((profile, index) => (
                      <div className={styles.profileConsent} key={index}>
                        <div className={styles.profile}>
                          <ClientProfileAvatar
                            avatarClassName={styles.avatar}
                            initialClassName={styles.avatar}
                            avatarUrl={profile.avatar}
                            initialsName={profile.initials}
                          />
                          <div className={styles.name}>{getName(profile)}</div>
                        </div>
                        <div className={styles.consentName}>{profile.consentName}</div>
                      </div>
                    ))}
                </div>
                <InviteTimeForm
                  title={t('label.send_invite')}
                  tooltipsMessage={t('label.send_invite_consent.tooltip')}
                  inviteTimeForm={sendConsentForm.inviteTime}
                  checkValidation={isSubmit}
                  onChangeInviteField={handleChangeInviteTime}
                />
                <div className={styles.addNoteTitle}>Add a note</div>

                <div className={styles.addNoteEditor}>
                  <Input.TextArea
                    id={'consentNote'}
                    rows={5}
                    value={sendConsentForm.note}
                    onChange={(e) => onChangeNote(e.target.value)}
                  />
                </div>
                <div className={styles.submitButtonContainer}>
                  <ButtonAlt
                    status={submitStatus}
                    onClick={handleSubmitConsent}
                    disabled={!sendConsentForm.profileList.find((profile) => profile.isChecked)}
                  >
                    Send consent form
                  </ButtonAlt>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default SendConsentModal;
