import WidgetTextEditor2 from 'components/WidgetTextEditor2/WidgetTextEditor2';
import { useFormikContext } from 'formik';
import { CaseNoteTemplate } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import styles from './CaseNoteHeadingsEditor.module.scss';

interface TextEditorProp {
  name: string;
  disabled?: boolean;
}

const CaseNoteHeadingsEditor = ({ name, disabled = false }: TextEditorProp) => {
  const { values, setFieldValue } = useFormikContext<CaseNoteTemplate>();

  const onChangeBody = (body: any) => {
    setFieldValue(name, body.trim());
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.editorWrapper}>
          {/* <WidgetTextEditor
            value={values.headings}
            onChangeValue={(e: any) => onChangeBody(e)}
            enableMention
            readOnly={disabled}
          /> */}
          <WidgetTextEditor2
            height={320}
            enableMention
            hasBackground
            hasContainerBorder
            readOnly={disabled}
            value={values.headings}
            onChange={onChangeBody}
          />
        </div>
      </div>
    </div>
  );
};

export default CaseNoteHeadingsEditor;
