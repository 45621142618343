import { useGetInvoiceSummaryQuery } from 'redux/endpoints/billingServices/invoices';
import styles from './InvoiceSummary.module.scss';
import SummaryCard from './components/SummaryCard/SummaryCard';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { InvoiceFilters, selectFilters, setFilters } from 'redux/invoices/invoiceSlice';
import { StatusFilterType } from 'utils/hooks/useFetchInvoices';
import { InvoicePaymentStatus, STATUS_OPTIONS } from '../InvoiceList/components/InvoiceListFilter/InvoiceListFilter';
import { MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { useCurrency } from 'utils/hooks/useCurrency';
import { DataRefreshHandle } from 'pages/PatientDetails/components/PatientDetailsContent/PatientDetailsContent';

export enum SummaryType {
  generatedInvoice = 'generatedInvoice',
  collectedPayment = 'collectedPayment',
  overdueInvoice = 'overdueInvoice',
  failedPayment = 'failedPayment',
  writtenOffPayment = 'writtenOffPayment',
  creditNotePayment = 'creditNotePayment'
}
interface DateRange {
  from: string;
  to: string;
}

const todayString = moment().format(MOMENTJS_YEAR_MONTH_DAY_FORMAT);

const InvoiceSummary = forwardRef<DataRefreshHandle, { clientRecordId?: string }>(({ clientRecordId }, ref) => {
  const dispatch = useAppDispatch();
  const { accountId } = useGetAccountId();
  const { CURRENCY_SYMBOL, formatCurrency } = useCurrency();
  const filters = useAppSelector(selectFilters);

  const [invoiceDateRange, setInvoiceDateRange] = useState<DateRange | undefined>({
    from: todayString,
    to: todayString
  });
  const {
    data: invoiceData,
    isLoading: invoiceLoading,
    isFetching: invoiceFetching,
    refetch: refetchSummary
  } = useGetInvoiceSummaryQuery({
    accountId,
    params: {
      ...invoiceDateRange,
      clientRecordId
    }
  });

  const getDateRange = (dateRange?: string) => {
    switch (dateRange) {
      case 'yesterday': {
        const yesterday = moment().subtract(1, 'days').format(MOMENTJS_YEAR_MONTH_DAY_FORMAT);
        return {
          from: yesterday,
          to: yesterday
        };
      }
      case '7days':
        return {
          from: moment().subtract(7, 'days').format(MOMENTJS_YEAR_MONTH_DAY_FORMAT),
          to: todayString
        };
      case '30days':
        return {
          from: moment().subtract(30, 'days').format(MOMENTJS_YEAR_MONTH_DAY_FORMAT),
          to: todayString
        };
      default: // Included today
        return {
          from: todayString,
          to: todayString
        };
    }
  };

  const handleChangeInvoiceDateRange = (dateRange?: string) => {
    setInvoiceDateRange(getDateRange(dateRange));
  };

  const filterClick = (value: SummaryType) => {
    const newFilters: InvoiceFilters = {
      ...filters,
      ...{ createdAt: { from: null, to: null }, statuses: [], paymentStatuses: [] }
    };

    switch (value) {
      case SummaryType.generatedInvoice:
        newFilters.statuses = STATUS_OPTIONS.filter(({ _id }) => _id !== StatusFilterType.Draft);
        break;
      case SummaryType.collectedPayment:
        newFilters.statuses = STATUS_OPTIONS.filter(({ _id }) =>
          [StatusFilterType.Issued, StatusFilterType.Overdue].includes(_id)
        );
        newFilters.paymentStatuses = [
          { name: `Paid is > ${CURRENCY_SYMBOL}0`, _id: InvoicePaymentStatus.PaidMoreThanZero }
        ];
        break;
      case SummaryType.overdueInvoice:
        newFilters.statuses = [{ name: 'Overdue', _id: StatusFilterType.Overdue }];
        break;
      case SummaryType.failedPayment:
        newFilters.statuses = STATUS_OPTIONS.filter(({ _id }) =>
          [StatusFilterType.Issued, StatusFilterType.Overdue].includes(_id)
        );
        newFilters.paymentStatuses = [{ name: 'Payment failed', _id: InvoicePaymentStatus.FailedPayments }];
        break;
      case SummaryType.writtenOffPayment:
        newFilters.paymentStatuses = [{ name: 'Payment write off', _id: InvoicePaymentStatus.WriteOff }];
        break;
      case SummaryType.creditNotePayment:
        newFilters.paymentStatuses = [{ name: 'Payment credited', _id: InvoicePaymentStatus.CreditNote }];
        break;
    }

    dispatch(
      setFilters({
        ...newFilters,
        ...(filters.createdAt && {
          createdAt: {
            ...(invoiceDateRange?.from && {
              from: invoiceDateRange.from
            }),
            ...(invoiceDateRange?.to && {
              to: invoiceDateRange.to
            })
          }
        })
      })
    );
  };

  useImperativeHandle(ref, () => ({
    onDataRefresh: () => {
      refetchSummary();
    }
  }));

  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <SummaryCard
          title="INVOICE SUMMARY"
          items={[
            {
              description: (
                <span>
                  <b>{invoiceData?.generatedInvoices.quantity || 0}</b> invoices generated totalling{' '}
                  <b>{formatCurrency(invoiceData?.generatedInvoices.total || 0)}</b>
                </span>
              ),
              colorCode: 1,
              type: SummaryType.generatedInvoice
            },
            {
              description: (
                <span>
                  <b>{invoiceData?.overdueInvoices.quantity || 0}</b> invoices overdue totalling{' '}
                  <b>{formatCurrency(invoiceData?.overdueInvoices.total || 0)}</b>
                </span>
              ),
              colorCode: 3,
              type: SummaryType.overdueInvoice
            },
            {
              description: (
                <span>
                  <b>{invoiceData?.writeOffPayments.quantity || 0}</b> invoices write off totalling{' '}
                  <b>{formatCurrency(invoiceData?.writeOffPayments.total || 0)}</b>
                </span>
              ),
              colorCode: 5,
              type: SummaryType.writtenOffPayment
            },
            {
              description: (
                <span>
                  <b>{invoiceData?.paymentCollected.quantity || 0}</b> payments collected totalling{' '}
                  <b>{formatCurrency(invoiceData?.paymentCollected.total || 0)}</b>
                </span>
              ),
              colorCode: 2,
              type: SummaryType.collectedPayment
            },
            {
              description: (
                <span>
                  <b>{invoiceData?.failedPayment.quantity || 0}</b> payments failed totalling{' '}
                  <b>{formatCurrency(invoiceData?.failedPayment.total || 0)}</b>
                </span>
              ),
              colorCode: 4,
              type: SummaryType.failedPayment
            },
            {
              description: (
                <span>
                  <b>{invoiceData?.creditPayments.quantity || 0}</b> invoices credit totalling{' '}
                  <b>{formatCurrency(invoiceData?.creditPayments.total || 0)}</b>
                </span>
              ),
              colorCode: 6,
              type: SummaryType.creditNotePayment
            }
          ]}
          filterClick={filterClick}
          dateRangeChange={handleChangeInvoiceDateRange}
          isLoading={invoiceLoading || invoiceFetching}
        />
      </div>

      <div className={styles.separator} />
    </div>
  );
});

export default InvoiceSummary;
