import styles from './LoadingSpinner.module.scss';
import classnames from 'classnames';

interface LoadingSpinnerProps {
  className?: string;
}

const LoadingSpinner = ({ className }: LoadingSpinnerProps) => {
  return <div className={classnames(className, styles.loading)} />;
};

export default LoadingSpinner;
