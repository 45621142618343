import classNames from 'classnames';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useState, useRef, useEffect } from 'react';
import styles from './AddClientDataFieldButton.module.scss';
import {
  OpenDataCategory,
  OpenDataFieldVariable
} from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';
import { useFormikContext } from 'formik';
import { CaseNoteTemplate } from '../../interface';

interface AddClientDataFieldButtonProps {
  categories: OpenDataCategory[];
  disabled: boolean;
}

const AddClientDataFieldButton = ({ categories, disabled }: AddClientDataFieldButtonProps) => {
  const { values, setFieldValue } = useFormikContext<CaseNoteTemplate>();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (isMenuOpen && !menuNode.current?.contains(e.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => window.removeEventListener('mousedown', handleMouseDown);
  }, [isMenuOpen]);

  const handleAddButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAddClientDataField = (categoryId: string, variable: Partial<OpenDataFieldVariable>) => {
    const isExistingField = values.fields.some(
      (field) => field.categoryId === categoryId && field.variableId === variable.variableId
    );

    if (isExistingField) {
      setFieldValue(
        'fields',
        values.fields.filter((field) => !(field.categoryId === categoryId && field.variableId === variable.variableId))
      );
    } else {
      setFieldValue(`fields.${values.fields.length}`, {
        name: variable.variableName,
        type: 'cdf',
        active: true,
        editable: false,
        mandatory: false,
        categoryId,
        variableId: variable.variableId
      });
    }
  };

  return (
    <div ref={menuNode}>
      <div className={styles.menuWrapper}>
        <div className={classNames(styles.menuContent, isMenuOpen && styles.show, disabled && styles.disabled)}>
          {categories.map(({ _id, name, questions }, i) => (
            <div key={i} className={styles.categoryWrapper}>
              <div className={styles.categoryName}>{name}</div>
              {questions.map((question, i) => (
                <div
                  key={i}
                  className={classNames(
                    styles.question,
                    values.fields.some(
                      ({ categoryId, variableId }) => _id === categoryId && question.variableId === variableId
                    ) && styles.selected
                  )}
                  onClick={() => handleAddClientDataField(_id!, question)}
                >
                  <span>
                    <span className={styles.variableId}>{question.variableId}</span> - {question.variableName}
                  </span>
                  <i className={`material-icons ${styles.icon}`}>check_circle</i>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <ButtonAlt
        className={styles.createFieldBtn}
        variant={'text'}
        size={'medium'}
        onClick={handleAddButtonClick}
        icon={'add_link'}
        disabled={disabled}
      >
        Add Client Data Field
      </ButtonAlt>
    </div>
  );
};

export default AddClientDataFieldButton;
