import InviteTimeForm from 'components/InviteTimeForm/InviteTimeForm';
import { useTranslation } from 'react-i18next';
import ClientCreation from '../ClientCreation/ClientCreation';
import Onboarding from '../Onboarding/Onboarding';
import QuickNoteForm from '../QuickNoteForm/QuickNoteForm';

import styles from './OnboardingTriageStep.module.scss';
import { CheckBoxListing, ClientDetails, newClientForm } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import { clientRecordsInterface, ClientRecordType } from 'interfaces/Clients/clientsRecord';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useState } from 'react';

interface OnboardingTriageStepInterface {
  formCompleted: boolean;
  clientFormData: newClientForm;
  onboardingList: CheckBoxListing[];
  consentList: CheckBoxListing[];
  assessmentList: CheckBoxListing[];
  clientRecords?: clientRecordsInterface;
  setClientFormData: (val: newClientForm) => void;
  handleSubmit: () => void;
  handleResetForm: () => void;
  submitStatus: '' | 'active' | 'finished';
  isTriageOnly?: boolean;
}

const OnboardingTriageStep = ({
  formCompleted,
  clientFormData,
  onboardingList,
  consentList,
  assessmentList,
  clientRecords,
  setClientFormData,
  handleSubmit,
  handleResetForm,
  submitStatus,
  isTriageOnly
}: OnboardingTriageStepInterface) => {
  const { t } = useTranslation();

  const [isNothingCheckedForTriage, setIsNothingCheckedForTriage] = useState(false);
  const [shouldShowErrorMessages, setShouldShowErrorMessages] = useState(false);

  const handleChangeOnboardDetail = (val: ClientDetails['onboarding'], clientId: string) => {
    const clientProfiles = clientFormData.clientProfiles.map((client) => ({
      ...client,
      onboarding: client._id === clientId ? (client.onboarding = val) : client.onboarding
    }));
    const newClientForm = {
      ...clientFormData,
      clientProfiles: clientProfiles
    };
    setClientFormData(newClientForm);
  };

  const handleChangeConsentDetail = (val: ClientDetails['consentForm'], clientId: string) => {
    const clientProfiles = clientFormData.clientProfiles.map((client) => ({
      ...client,
      consentForm: client._id === clientId ? (client.consentForm = val) : client.consentForm
    }));
    setClientFormData({
      ...clientFormData,
      clientProfiles: clientProfiles
    });
  };

  const handleChangeAssessmentDetail = (val: ClientDetails['assessmentForm'], clientId: string) => {
    const clientProfiles = clientFormData.clientProfiles.map((client) => ({
      ...client,
      assessmentForm: client._id === clientId ? (client.assessmentForm = val) : client.assessmentForm
    }));
    setClientFormData({
      ...clientFormData,
      clientProfiles: clientProfiles
    });
  };

  const handleChangeClientDetail = (val: ClientDetails) => {
    const newClientProfiles = clientFormData.clientProfiles.map((client) => {
      return client._id === val._id ? val : client;
    });
    setClientFormData({
      ...clientFormData,
      clientProfiles: newClientProfiles
    });
  };

  const handleChangeInviteDetail = (val: newClientForm['signupInvitationSettings']) => {
    setClientFormData({
      ...clientFormData,
      signupInvitationSettings: val
    });
  };

  const handleChangeQuickNote = (val: newClientForm['quickNote']) => {
    setClientFormData({
      ...clientFormData,
      quickNote: val
    });
  };

  const handleValidateAndSubmit = () => {
    setShouldShowErrorMessages(true);
    const signupInvitationChecking =
      clientFormData.signupInvitationSettings.sendAt !== 'custom' ||
      !!clientFormData.signupInvitationSettings.customSendTime;

    const quickNoteChecking =
      !clientFormData.quickNote.includeQuickNote ||
      clientFormData.quickNote.note.replace(/<(?!img)(.|\n)*?>/g, '').trim().length > 0;

    const noFormsChecked =
      clientFormData.clientProfiles.filter(
        (clientObj) =>
          clientObj.onboarding.isCheckOnBoarding ||
          clientObj.consentForm?.isCheckOnConsentForm ||
          clientObj.assessmentForm?.isCheckOnAssessmentForm
      ).length === 0;

    const isOnboardingErrorExist =
      clientFormData.clientProfiles.filter(
        (clientObj) =>
          clientObj.onboarding.isCheckOnBoarding &&
          clientObj.onboarding.onboardingSurveyIds &&
          clientObj.onboarding.onboardingSurveyIds.length === 0
      ).length > 0;

    const consentFormErrorExist =
      clientFormData.clientProfiles.filter(
        (clientObj) =>
          clientObj.consentForm?.isCheckOnConsentForm &&
          clientObj.consentForm.consentFormIds &&
          clientObj.consentForm.consentFormIds.length === 0
      ).length > 0;

    const assessmentFormErrorExist =
      clientFormData.clientProfiles.filter(
        (clientObj) =>
          clientObj.assessmentForm?.isCheckOnAssessmentForm &&
          clientObj.assessmentForm.assessmentFormIds &&
          clientObj.assessmentForm.assessmentFormIds.length === 0
      ).length > 0;

    if (isTriageOnly) {
      if (noFormsChecked) {
        setIsNothingCheckedForTriage(true);
        return;
      } else {
        setIsNothingCheckedForTriage(false);
      }
    } else if (!signupInvitationChecking) {
      return;
    }

    if (quickNoteChecking && !consentFormErrorExist && !isOnboardingErrorExist && !assessmentFormErrorExist) {
      handleSubmit();
    } else {
      return;
    }
  };

  return (
    <>
      {!formCompleted && (
        <div>
          {clientFormData.clientProfiles &&
            clientFormData.clientProfiles.length > 0 &&
            clientFormData.clientProfiles.map((client, index) => (
              <Onboarding
                key={`onboarding_${index}`}
                clientProfile={client}
                onChangeConsentField={handleChangeConsentDetail}
                onChangeOnboardField={handleChangeOnboardDetail}
                onChangeAssessmentDetail={handleChangeAssessmentDetail}
                handleChangeClientDetail={handleChangeClientDetail}
                onBoardingList={onboardingList}
                consentList={consentList}
                checkValidation={shouldShowErrorMessages}
                profileType={
                  clientRecords?.clientProfiles?.find((clientProfile) => clientProfile._id.toString() === client._id)
                    ?.profileType || clientFormData.clientCapability.profileType
                }
                assessmentList={assessmentList}
                recordType={clientFormData.recordType as ClientRecordType}
                isTriageOnly={isTriageOnly}
              />
            ))}
          {clientFormData.clientCapability.profileType === 'full' && !isTriageOnly && (
            <div className={styles.inviteFormWrapper}>
              <InviteTimeForm
                title={t('label.send_welcome_documents_to_complete')}
                tooltipsMessage={t('label.send_invite.tooltip')}
                inviteTimeForm={clientFormData.signupInvitationSettings}
                checkValidation={shouldShowErrorMessages}
                onChangeInviteField={handleChangeInviteDetail}
              />
            </div>
          )}
          <QuickNoteForm
            clientQuickNote={clientFormData.quickNote}
            checkValidation={shouldShowErrorMessages}
            onChangeQuickNote={handleChangeQuickNote}
          />
          {isNothingCheckedForTriage && <div className={styles.triageError}>Please select at least one activity.</div>}
          <div id="completeBtnId" className={styles.submitButtonContainer}>
            <ButtonAlt status={submitStatus} disabled={submitStatus !== ''} onClick={() => handleValidateAndSubmit()}>
              {isTriageOnly ? 'Triage Client' : t('button.complete_client_setup')}
            </ButtonAlt>
          </div>
        </div>
      )}
      {formCompleted && clientRecords && (
        <ClientCreation clientRecords={clientRecords} onResetForm={handleResetForm} isTriageOnly={isTriageOnly} />
      )}
    </>
  );
};

export default OnboardingTriageStep;
