import classnames from 'classnames';
import Button from 'components/Button/Button';
import moment from 'moment';
import { Appointment } from 'pages/InvoiceGenerator/interface';
import { filterInvoiceAppointmentTags } from 'utils/filterInvoiceAppointmentTags';
import styles from './GroupAppointmentItem.module.scss';

interface GroupAppointmentItemProps {
  appointment: Appointment;
  included?: boolean;
  onAddAppointment: (appointment: Appointment) => void;
  onRemoveAppointment: (appointment: Appointment) => void;
}

const GroupAppointmentItem = ({
  appointment,
  included,
  onAddAppointment,
  onRemoveAppointment
}: GroupAppointmentItemProps) => {
  const dateText = moment(appointment.date, 'YYYY-MM-DD').format('dddd D MMMM, YYYY');
  const startTimeText = moment(appointment.startTime, 'HH:mm').format('h:mm A');
  const isGroupAppointment = !appointment.clientRecord;
  const tags = filterInvoiceAppointmentTags((!isGroupAppointment && appointment.markedStatus) || []);

  const handleAddOrRemoveAppointment = () => {
    if (included) {
      onRemoveAppointment(appointment);
    } else {
      onAddAppointment(appointment);
    }
  };

  return (
    <div className={classnames(styles.container, included && styles.included)}>
      <div className={styles.iconWrapper}>
        {isGroupAppointment ? (
          <i className={`material-icons ${styles.groupIcon}`}>groups</i>
        ) : (
          <i className={`material-icons ${styles.groupIcon}`}>person</i>
        )}
      </div>
      <div className={styles.appointmentInfoWrapper}>
        <div>
          <div>
            <span className={styles.title}>{appointment.sessionTypeName}</span> {isGroupAppointment ? 'on' : ''}
          </div>
          {isGroupAppointment ? (
            <strong>
              {dateText} at {startTimeText}
            </strong>
          ) : (
            <div>
              booked for{' '}
              <strong>
                {appointment.clientRecord?.clientName} on {dateText} at {startTimeText}
              </strong>
            </div>
          )}
        </div>
        {tags.length > 0 && (
          <div className={styles.tags}>
            {tags.map((status, index) => (
              <div className={styles.tag} key={index}>
                {status}
              </div>
            ))}
          </div>
        )}
      </div>
      <Button className={styles.button} type="button" onClick={handleAddOrRemoveAppointment}>
        <i className={`material-icons-outlined ${included ? styles.remove : styles.add}`}>
          {included ? 'remove_circle' : 'add_circle'}
        </i>
      </Button>
    </div>
  );
};

export default GroupAppointmentItem;
