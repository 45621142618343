import { notification } from 'antd';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersListing } from 'interfaces/Practitioners/practitionersListing';
import { AppointmentSlots, AppointmentStatusOption } from 'interfaces/Schedule/Appointment';
import moment from 'moment';
import Handlebars from 'handlebars';
import { PatientActivity } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsActivity/components/ActivityFeed/ActivityFeed';
import { Assessment } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';
import { SimpleClientData } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsClientData/ClientData.interface';
import { GeneralPractitionerInterface } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/GPDetails/interface';
import { generateAssessment } from 'utils/hooks/headingTemplate';
import { getPatientsActivity } from 'utils/http/ActivityFeedService/activities';
import { getClinicalAssessmentDetailsResponse } from 'utils/http/CheckInService/Assessment/clinicalAssessment';
import { getSimpleClientData } from 'utils/http/CheckInService/OpenDataField/openDataField';
import {
  getGeneralPractitionerById,
  getPractitionerListing
} from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { getAllClientEncryptedDataByClientRecordId } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { getClientAppointmentsByDateRange } from 'utils/http/ScheduleService/Appointments/Appointments';
import { formatHeadingTemplateBodyString } from './formatHeadingTemplateBodyString';

export const cleanupTemplate = (template: string, variables: Record<string, string | number | undefined>) => {
  const fixedBody = formatHeadingTemplateBodyString(template);
  const regex1 = /<span[^<>]*class="mention"[^<>]*>(.*?)<\/span>{{|{{/gm;
  const regex2 = /}}<\/span>\ufeff<\/span>|}}/gm;
  const generateBodyPart1 = fixedBody.replace(regex1, '{{{[');
  const generateBodyPart2 = generateBodyPart1.replace(regex2, ']}}}');
  // remove sentence in [] if first value is empty
  const regexToFindAllSquareBrackets = /\[\[([^[\]]*{{{\[[^[\]]*\]}}}[^[\]]*)*\]\]/;
  const regexToFindVariable = /{{{\[([^[\]]*)\]}}}/;

  return generateBodyPart2.replaceAll(new RegExp(regexToFindAllSquareBrackets, 'g'), (str) => {
    const content = str.match(regexToFindAllSquareBrackets)?.[1] || '';
    const firstVariable = str.match(regexToFindVariable)?.[1];
    return firstVariable && variables[firstVariable] ? content : '';
  });
};

export const makeTemplateContentRenderer = async (
  token: string,
  accountId: string,
  clientRecordId?: string,
  profileId?: string
) => {
  try {
    const [appointments, practitionersList, clientRecord, assessmentList, cancelledAppointments, clientData] =
      (await Promise.all([
        (
          await getClientAppointmentsByDateRange({
            token,
            from: '2020-01-01',
            to: moment(new Date()).add(1, 'y').format('YYYY-MM-DD'),
            clientRecordId
          })
        ).json(),
        (
          await getPractitionerListing(
            token,
            accountId,
            `status=active&withAccessRights=${[AccessRight.Admin, AccessRight.User].join(',')}`
          )
        ).json(),
        clientRecordId ? (await getAllClientEncryptedDataByClientRecordId(token, clientRecordId)).json() : undefined,
        clientRecordId ? (await getClinicalAssessmentDetailsResponse(token, clientRecordId)).json() : undefined,
        clientRecordId
          ? (
              await getPatientsActivity(token, {
                clientRecordId,
                pageSize: 0,
                filter: 'appointment',
                action: 'cancelled'
              })
            ).json()
          : undefined,
        clientRecordId ? (await (await getSimpleClientData(token, clientRecordId)).json()).data : undefined
      ])) as [
        AppointmentSlots[],
        PractitionersListing,
        clientRecordsInterface | undefined,
        Assessment[] | undefined,
        PatientActivity[] | undefined,
        SimpleClientData[] | undefined
      ];

    const generalPractitionerId = clientRecord?.referral?.generalPractitionerId;
    const generalPractitioner = generalPractitionerId
      ? ((await (
          await getGeneralPractitionerById(token, accountId, generalPractitionerId)
        ).json()) as GeneralPractitionerInterface)
      : undefined;

    // START MAPPING DATA TO VARIABLES LIST, copies from headingTemplate
    const firstAssessment = await generateAssessment('firstAssessment');
    const mostRecentAssessment = await generateAssessment('mostRecentAssessment');

    const firstAppointDate =
      appointments.length > 0
        ? moment(`${appointments[0].date} ${appointments[0].startTime}`).format('dddd DD MMMM YYYY \\at hh:mmA')
        : '';
    const lastAppointDate =
      appointments.length > 0
        ? moment(
            `${appointments[appointments.length - 1].date} ${appointments[appointments.length - 1].startTime}`
          ).format('dddd DD MMMM YYYY \\at hh:mmA')
        : '';
    const firstAssessmentDate =
      assessmentList && assessmentList.length > 0
        ? moment(assessmentList[0].createdAt).format('dddd DD MMMM YYYY \\at hh:mmA')
        : '';
    const lastAssessmentDate =
      assessmentList && assessmentList.length > 0
        ? moment(assessmentList[assessmentList.length - 1].createdAt).format('dddd DD MMMM YYYY \\at hh:mmA')
        : '';

    const clientProfileData = clientRecord?.clientProfiles.filter((cpObj) => cpObj._id === profileId)[0];

    const getPractitionerId =
      clientRecord && clientRecord.clinicianAuth0Ids.length > 0 ? clientRecord.clinicianAuth0Ids[0] : '';
    const getPractitionerDetails = practitionersList.practitionerList.find(
      (practitionerObj) => practitionerObj._id === getPractitionerId
    );
    const getPractitionerName = getPractitionerDetails?.name || '';

    const sessionsNotAttend = appointments.filter(
      (appointmentObj) =>
        appointmentObj.markedStatus?.includes(AppointmentStatusOption.DidNotAttend) ||
        appointmentObj.markedStatus?.includes(AppointmentStatusOption.ClientDidNotAttend) ||
        appointmentObj.markedStatus?.includes(AppointmentStatusOption.ClinicianDidNotAttend)
    ).length;

    const referrerName = clientRecord?.keyClientContacts?.filter((keyContactObj) =>
      keyContactObj.tags?.some((tagObj) => tagObj.toLowerCase() === 'referrer')
    )[0];

    const authorizedSessionNumber = clientRecord?.appointmentStatusOverviewConfiguration.limit;

    const matchedClientData = clientData?.find((item) => item.clientProfileId === profileId);
    const cdfList: { [key: string]: string } = matchedClientData
      ? matchedClientData?.variables.reduce((obj, { variableId, value }) => {
          return {
            ...obj,
            [variableId]: value
          };
        }, {})
      : {};

    const variableList: { [key: string]: string | number | undefined } = {
      CLIENT_NAME: clientProfileData ? `${clientProfileData.firstName} ${clientProfileData.lastName}` : '',
      CLIENT_DOB: clientProfileData?.dateOfBirth
        ? moment(clientProfileData.dateOfBirth, 'DD/MM/yyyy').format('D MMMM YYYY')
        : 'DOB Unknown',
      CLIENT_EMAIL: clientProfileData?.email || '',
      CLIENT_MOBILE: clientProfileData?.mobileNumber || '',
      CLIENT_POSTCODE: clientProfileData?.postcode || '',
      FIRST_APPOINTMENT_DATE: firstAppointDate,
      RECENT_APPOINTMENT_DATE: lastAppointDate,
      APPOINTMENT_COUNT: appointments.length,
      FIRST_ASSESSMENT: firstAssessment || '',
      FIRST_ASSESSMENT_DATE: firstAssessmentDate,
      RECENT_ASSESSMENT: mostRecentAssessment || '',
      RECENT_ASSESSMENT_DATE: lastAssessmentDate,
      PRACTITIONER_NAME: getPractitionerName,
      REFERRER_NAME: referrerName ? `${referrerName.firstName} ${referrerName.lastName}` : '',
      NUMBER_OF_AUTHORIZED_SESSION: authorizedSessionNumber,
      CLIENT_FIRST_NAME: clientProfileData?.firstName || '',
      NUMBER_OF_SESSIONS_CANCELLED: cancelledAppointments?.length || 0,
      NUMBER_OF_SESSIONS_NOT_ATTEND: sessionsNotAttend,
      GP_NAME: generalPractitioner?.name || '',
      GP_MEDICARE_PROVIDER_NUMBER: generalPractitioner?.medicareProviderNumber || '',
      GP_NAME_OF_PRACTICE: generalPractitioner?.practiceName || '',
      GP_ADDRESS: generalPractitioner?.address || '',
      GP_FAX: generalPractitioner?.fax || '',
      GP_LANDLINE: generalPractitioner?.landline || '',
      GP_EMAIL: generalPractitioner?.email || '',
      MY_PROVIDER_NAME: clientProfileData?.medicare
        ? `${clientProfileData.medicare.firstName} ${clientProfileData.medicare.lastName}`
        : '',
      MY_PROVIDER_NUMBER: clientProfileData?.medicare?.number || ''
    };
    const variables = { ...variableList, ...cdfList };

    // CREATE TEMPLATE COMPILER
    return (template: string) => {
      const cleanedTemplate = cleanupTemplate(template, variables);
      const compiledTemplate = Handlebars.compile(cleanedTemplate);
      return compiledTemplate(variables);
    };
  } catch (e) {
    console.error(e);
    notification.error({ message: 'Failed to fetch data to render template' });
  }
  return (template: string) => {
    const cleanedTemplate = cleanupTemplate(template, {});
    const compiledTemplate = Handlebars.compile(cleanedTemplate);
    return compiledTemplate({});
  };
};
