import { Route, Routes } from 'react-router-dom';
import { useGetAccountPackage } from '../utils/hooks/GetAccountInfo/accountPackage';
import RedirectTo404 from '../components/RedirectTo404/RedirectTo404';
import MentorRoutes from './MentorRoutes';
import ReceptionRoutes from './ReceptionRoutes';
import PioneerRoutes from './PioneerRoutes';
import EdgeAdminRoutes from './EdgeAdminRoutes';
import EdgeUserRoutes from './EdgeUserRoutes';

const AuthRoutes = () => {
  const { isEdgeAdminUser, isEdgeReceptionist, userAccessConfig, isMentorUser } = useGetAccountPackage();

  switch (userAccessConfig.plan) {
    case 'edge':
      return isEdgeAdminUser ? (
        <Routes>
          <Route path="/admin/*" element={<EdgeAdminRoutes />} />
          <Route path="/user/*" element={<EdgeUserRoutes />} />
          <Route path="*" element={<RedirectTo404 />} />
        </Routes>
      ) : isEdgeReceptionist ? (
        <Routes>
          <Route path="/receptionist/*" element={<ReceptionRoutes />} />
          <Route path="*" element={<RedirectTo404 />} />
        </Routes>
      ) : isMentorUser ? (
        <Routes>
          <Route path="/mentor/*" element={<MentorRoutes />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/user/*" element={<EdgeUserRoutes />} />
          <Route path="*" element={<RedirectTo404 />} />
        </Routes>
      );
    default:
      return (
        <Routes>
          <Route path="/*" element={<PioneerRoutes />} />
        </Routes>
      );
  }
};

export default AuthRoutes;
