import SubTab, { SubTabOption } from 'components/v2/SubTab/SubTab';
import { useFetchGroupDetails } from 'pages/Groups/GroupDetails/hooks/getGroupDetails';
import { GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';

const GroupsDetailsSubTab = () => {
  const { isEdgeReceptionist, isEdgeAdminView } = useGetAccountPackageView();
  const { isEdgePlanUser } = useGetAccountPackage();
  const { isGroupAttachmentReadDenied } = useGetPermissionToggle();
  const { auth0ClinicianId } = useGetClinicianId();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const location = useLocation();
  const groupId = isEdgePlanUser ? location.pathname.split('/')[3] : location.pathname.split('/')[2];
  const { groupDetails } = useFetchGroupDetails(token, groupId, accountId);
  const allowEditAssigned = isEdgeAdminView || auth0ClinicianId === groupDetails?.leadClinicianAuth0Id;
  const { CARECOORDINATION } = useRoutesGenerator();
  const [filterType, setFilterType] = useState('');

  const getSubTabs = (): SubTabOption[] => {
    const baseHref = `${CARECOORDINATION.GROUPS}/${groupId}`;

    const groupDetailsSubTabList: Array<SubTabOption & { isDisplay: boolean }> = [
      {
        label: 'Group Details',
        value: GroupDetailsTab.Details,
        isDisplay: true,
        href: `${baseHref}/${GroupDetailsTab.Details}`
      },
      {
        label: 'Members',
        value: GroupDetailsTab.Members,
        isDisplay: true,
        href: `${baseHref}/${GroupDetailsTab.Members}`
      },
      {
        label: 'Sessions',
        value: GroupDetailsTab.Sessions,
        isDisplay: true,
        href: `${baseHref}/${GroupDetailsTab.Sessions}`
      },
      {
        label: 'Psychometrics',
        value: GroupDetailsTab.Psychometrics,
        isDisplay: true,
        href: `${baseHref}/${GroupDetailsTab.Psychometrics}`
      },
      {
        label: 'Survey Forms',
        value: GroupDetailsTab.Survey,
        isDisplay: true,
        href: `${baseHref}/${GroupDetailsTab.Survey}`
      },
      {
        label: 'Group Case Notes',
        value: GroupDetailsTab.CaseNotes,
        isDisplay: !isEdgeReceptionist,
        href: `${baseHref}/${GroupDetailsTab.CaseNotes}`
      },
      {
        label: 'Attachments',
        value: GroupDetailsTab.Attachments,
        isDisplay: !isGroupAttachmentReadDenied,
        href: `${baseHref}/${GroupDetailsTab.Attachments}`
      },
      {
        label: 'Contacts',
        value: GroupDetailsTab.Contacts,
        isDisplay: true,
        href: `${baseHref}/${GroupDetailsTab.Contacts}`
      },
      {
        label: 'Practitioners Assigned',
        value: GroupDetailsTab.PractitionerAssigned,
        isDisplay: allowEditAssigned,
        href: `${baseHref}/${GroupDetailsTab.PractitionerAssigned}`
      },
      {
        label: 'Pathway Management',
        value: GroupDetailsTab.PathwayManagement,
        isDisplay: !isEdgeReceptionist,
        href: `${baseHref}/${GroupDetailsTab.PathwayManagement}`
      }
    ];

    return groupDetailsSubTabList
      .filter((item) => item.isDisplay)
      .map((item) => ({ label: item.label, value: item.value, href: item.href } as SubTabOption));
  };

  const getFilterType: string = isEdgePlanUser
    ? location.pathname.split('/')[4] ?? 'GroupDetailsTab.Details'
    : location.pathname.split('/')[3] ?? 'GroupDetailsTab.Details';

  useEffect(() => {
    setFilterType(getFilterType);
  }, [getFilterType]);

  return <SubTab options={getSubTabs()} activeOption={filterType} />;
};

export default GroupsDetailsSubTab;
