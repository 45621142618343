import { notification } from 'antd';
import { CaseNote } from 'interfaces/caseNote';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noteList } from 'utils/http/CheckInService/Notes/notes';
import { getCaseNotesByRecordId } from 'utils/http/DocumentService/CaseNotes/caseNotes';
import { QuickNote } from '../interfaces';

export interface MemberNote {
  note: QuickNote | CaseNote;
  isCaseNote: boolean;
}

export const useFetchNotesByClientId = (token: string, recordId: string, refreshList: number) => {
  const [memberNotes, setMemberNotes] = useState<MemberNote[]>([]);
  const [isMemberNotesLoading, setIsMemberNotesLoading] = useState(true);
  const [t] = useTranslation();

  const fetchQuickNotes = useCallback(
    async (token: string) => {
      notification.destroy();
      notification.warning({
        message: t('label.fetching_client_note'),
        closeIcon: <span className={'notify'}>OK</span>
      });
      try {
        const [callGetQuickNotes, callGetCaseNotes] = await Promise.all([
          noteList(token, recordId),
          getCaseNotesByRecordId({ token, recordId, withContents: true })
        ]);
        const [quickNotes, caseNotes] = await Promise.all([callGetQuickNotes.json(), callGetCaseNotes.json() ?? []]);

        const notes = [
          ...quickNotes.data.map((quickNote: QuickNote) => ({ note: quickNote, isCaseNote: false })),
          ...caseNotes.map((caseNote: CaseNote) => ({ note: caseNote, isCaseNote: true }))
        ];
        setMemberNotes(notes.sort((a, b) => moment(b.note.updatedAt).diff(moment(a.note.updatedAt))));
        notification.destroy();
        notification.success({
          message: t('form.success.fetch_client_note'),
          duration: 2,
          closeIcon: <span className={'success'}>OK</span>
        });
      } catch (ex) {
        notification.error({ message: t('form.error.fetch_client_note') });
      }

      setIsMemberNotesLoading(false);
    },
    [recordId, t]
  );

  useEffect(() => {
    if (token) {
      fetchQuickNotes(token);
    }
  }, [token, fetchQuickNotes, refreshList]);

  return { memberNotes, isMemberNotesLoading };
};
