import classnames from 'classnames';
import { InputHTMLAttributes, ReactNode, RefObject } from 'react';
import { Tooltip } from 'react-tooltip';

import LoadingCircle from '../LoadingCircle/LoadingCircle';
import styles from './MaterialInput.module.scss';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

export interface MaterialInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string | ReactNode;
  containerClassName?: string;
  labelClassName?: string;
  placeholder?: string;
  isLoading?: boolean;
  showDisabledLock?: boolean;
  disabledTooltips?: string;
  error?: boolean;
  errorMessage?: string;
  helperText?: string;
  icon?: string;
  placeWidgetRef?: RefObject<HTMLInputElement>;
  wrapperClassname?: string;
  postFix?: ReactNode;
}

const MaterialInput = ({
  id,
  label,
  className,
  containerClassName,
  labelClassName,
  isLoading,
  value,
  placeholder,
  showDisabledLock,
  disabledTooltips,
  error,
  errorMessage,
  helperText,
  icon,
  placeWidgetRef,
  postFix,
  wrapperClassname,
  ...props
}: MaterialInputProps) => {
  return (
    <div className={wrapperClassname}>
      <div className={classnames(styles.container, containerClassName)}>
        {icon && <i className={`material-icons-outlined ${styles.icon}`}>{icon}</i>}
        <input
          {...props}
          id={id}
          className={classnames(
            isLoading ? styles.fieldWithLoading : styles.field,
            error && styles.error,
            icon && styles.fieldWithIcon,
            className
          )}
          type={props.type || 'text'}
          autoComplete={'off'}
          placeholder={placeholder}
          value={value}
          ref={placeWidgetRef}
        />
        <label
          className={classnames(
            styles.label,
            labelClassName,
            placeholder && styles.maximizeLabel,
            error && styles.error,
            icon && styles.labelWithIcon
          )}
          htmlFor={id}
        >
          {label}
        </label>
        {showDisabledLock && (
          <>
            <i className={`material-icons-outlined ${styles.lockIcon}`} data-tooltip-id={id}>
              lock
            </i>
            {disabledTooltips && <Tooltip id={id}>{disabledTooltips}</Tooltip>}
          </>
        )}
        {isLoading && (
          <div className={styles.loadingWrapper}>
            <div className={styles.loadingBox}>
              <LoadingCircle />
            </div>
          </div>
        )}
        {postFix}
      </div>
      {helperText && <div className={styles.helperText}>{helperText}</div>}
      <ErrorMessage error={errorMessage} visible={!!errorMessage} />
    </div>
  );
};

export default MaterialInput;
