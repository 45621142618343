import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './Client.module.scss';
import HelmetWrapper from '../../components/HelmetWrapper/HelmetWrapper';
import { ClientStatus } from '../../interfaces/Clients/clientsRecord';
import { useTranslation } from 'react-i18next';
import ClientRecordList from './components/ClientListContent/ClientListContent';
import ClientListColumnSettingsModal from 'pages/Client/components/ClientListContent/components/ClientListColumnSettingsModal/ClientListColumnSettingsModal';

export interface TabIds {
  tabId?: ClientStatus['status'];
}

export interface SortBy {
  clientName?: number;
  clientSince?: number;
}

export const CLIENT_LIST_SORT_INFO = 'client-list-sort-info';

const ClientPage = () => {
  const [t] = useTranslation();

  return (
    <HelmetWrapper title={t('label.tacklit_clients')}>
      <ContentLayout>
        <div className={styles.container}>
          <ClientRecordList />
          <ClientListColumnSettingsModal />
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ClientPage;
