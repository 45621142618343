import FlexBox from 'components/FlexBox/FlexBox';
import { IInvoiceTemplate } from 'pages/Invoices/interface';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectInvoiceForm, setInvoiceForm } from 'redux/invoices/createInvoiceWithTemplateSlice';

import InlineEditField from '../InlineEditField/InlineEditField';
import styles from './PracticeInfoSection.module.scss';

interface PracticeInfoSectionProps {
  invoiceTemplate: IInvoiceTemplate;
}

const PracticeInfoSection = ({ invoiceTemplate }: PracticeInfoSectionProps) => {
  const dispatch = useAppDispatch();
  const invoiceForm = useAppSelector(selectInvoiceForm);

  const { practice } = invoiceForm;
  const {
    practiceAddress,
    practiceFormalName,
    practicePhoneNumber,
    practiceRegistrationNumber,
    practiceFax,
    practiceFinanceEmail
  } = invoiceTemplate;

  const practiceInfoField = {
    logo: practice?.logo,
    name: practice?.name,
    formalName: {
      name: 'formalName',
      show: practiceFormalName,
      value: practice?.formalName,
      editable: false
    },
    address: {
      name: 'address',
      show: practiceAddress,
      value: practice?.address,
      editable: false
    },
    registrationNumber: {
      name: 'registrationNumber',
      show: practiceRegistrationNumber,
      value: practice?.registrationNumber,
      editable: false
    },
    mobileNumber: {
      name: 'mobileNumber',
      show: practicePhoneNumber,
      value: practice?.mobileNumber,
      editable: false
    },
    fax: {
      name: 'fax',
      show: practiceFax,
      value: practice?.fax,
      editable: false
    },
    financeEmail: {
      name: 'financeEmail',
      show: practiceFinanceEmail,
      value: practice?.financeEmail,
      editable: false
    }
  };

  const { logo, name, formalName, address, registrationNumber, mobileNumber, fax, financeEmail } = practiceInfoField;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setInvoiceForm({
        practice: {
          ...practice,
          [e.target.name]: e.target.value
        }
      })
    );
  };

  return (
    <FlexBox direction="row" spacing={20} className={styles.wrapper}>
      <img src={logo} alt="Practice logo" width={100} height={100} />
      <FlexBox direction="column" flex={1}>
        <span className={styles.practiceName}>{name}</span>
        <FlexBox direction="row" justifyContent="space-between">
          <FlexBox direction="column" className={styles.width280} spacing={4}>
            {[formalName, address, registrationNumber]
              .filter((item) => item.show)
              .map((item, index) => (
                <InlineEditField
                  key={index}
                  value={item.value}
                  name={item.name}
                  editable={item.editable}
                  onChange={onChange}
                />
              ))}
          </FlexBox>
          <FlexBox direction="column" className={styles.width360} spacing={4}>
            {[mobileNumber, fax, financeEmail]
              .filter((item) => item.show)
              .map((item, index) => (
                <InlineEditField
                  key={index}
                  value={item.value}
                  name={item.name}
                  editable={item.editable}
                  onChange={onChange}
                />
              ))}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default PracticeInfoSection;
