import { clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import queryString from 'query-string';
import { funderType, PackageFunderInterface } from 'interfaces/Packages/packages';

export interface PackageFunderRequestPayload {
  accountId: string;
  params?: {
    page: number;
    perPage: number;
    searchName?: string;
  };
}

interface PackageFunderRespond {
  funders: PackageFunderInterface[];
  paging: {
    page: number;
    perPage: number;
    total: number;
  };
}

export const packageApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get package funder list
    getPackageFunders: builder.query<PackageFunderRespond, PackageFunderRequestPayload>({
      query: ({ accountId, params }) => {
        const stringifiedQueryString = params ? `?${queryString.stringify(params)}` : '';
        return {
          url: `/accounts/${accountId}/package-funders${stringifiedQueryString}`
        };
      },
      transformResponse: (response: PackageFunderRespond) => {
        return {
          funders: response.funders.map((funderObj) => ({
            type: funderObj.type,
            name: funderObj.name,
            funderId: funderObj.type === funderType.AddressBook ? funderObj?.funderId : funderObj.name
          })),
          paging: response.paging
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => `${endpointName}_${queryArgs?.params?.searchName || ''}`,
      merge: (currentCache, newItems, meta) => {
        if (meta.arg.params?.page === 1) {
          return {
            funders: newItems.funders,
            paging: newItems.paging
          };
        }

        return {
          funders: [...currentCache.funders, ...newItems.funders],
          paging: newItems.paging
        };
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.params?.page !== previousArg?.params?.page && currentArg?.params?.page !== 1
    })
  })
});

export const { useGetPackageFundersQuery } = packageApiSlice;
