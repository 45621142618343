import styles from './CaseNoteAttachment.module.scss';
import Button from 'components/Button/Button';
import { CaseNote } from 'interfaces/caseNote';
import { notification } from 'antd';
import mime from 'mime';
import { downloadAttachmentByRecordId } from 'utils/http/DocumentService/Attachments/attachments';
import { security } from 'utils/security';

interface CaseNoteAttachmentProps {
  recordId: string;
  caseNoteAttachmentData: CaseNote['attachments'];
}

const CaseNoteAttachment = ({ recordId, caseNoteAttachmentData }: CaseNoteAttachmentProps) => {
  const handleDownloadAttachment = async (attachment: CaseNote['attachments'][number]) => {
    const token = await security.getAccessTokenSilently();
    try {
      const callGetAttachmentById = await downloadAttachmentByRecordId(token, recordId, attachment._id);

      const downloadedAttachment = await callGetAttachmentById.arrayBuffer();

      const documentBlob = new Blob([downloadedAttachment], {
        type: mime.getType(attachment.title) || 'application/octet-stream'
      });

      const anchor = document.createElement('a');

      anchor.href = URL.createObjectURL(documentBlob);
      anchor.download = attachment.title;
      anchor.click();
      anchor.remove();
    } catch (ex) {
      console.error(ex);

      notification.error({ message: 'Something went wrong while trying to download this attachment.' });
    }
  };

  return caseNoteAttachmentData.length > 0 ? (
    <div className={styles.attachmentsContainer}>
      <>
        <div className={styles.title}>ATTACHMENTS</div>
        {caseNoteAttachmentData.map((attachment) => (
          <div className={styles.attachmentContainer} key={attachment._id}>
            <i className={`material-icons-outlined ${styles.icon}`}>attach_file</i>
            {attachment.title}
            <Button
              className={styles.viewButton}
              variant="secondary"
              onClick={() => handleDownloadAttachment(attachment)}
            >
              view
            </Button>
          </div>
        ))}
      </>
    </div>
  ) : null;
};

export default CaseNoteAttachment;
