import { useAppSelector } from 'redux/hooks';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import {
  InvoicePaymentsFilters,
  InvoicePaymentsSearch,
  InvoicePaymentsSorting,
  InvoicePaymentsTab,
  selectActiveTab,
  selectFilters,
  selectCollectedPaging,
  selectRefundedPaging,
  selectSearch,
  selectSorting,
  selectFailedPaging,
  selectCancelledPaging,
  selectScheduledPaging
} from 'redux/features/invoicePayments/invoicePaymentsSlice';
import {
  GetInvoicePaymentQueryParams,
  useGetInvoicePaymentsQuery
} from 'redux/endpoints/billingServices/invoicePayments';
import { Paging } from '../utils';
import momentTz from 'moment-timezone';
import { PaymentRequestStatus } from 'pages/Invoices/interface';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetScheduledPaymentsQuery } from 'redux/endpoints/scheduleServices/scheduledPayment';

const getPaymentRequestStatusFilters = (tab: InvoicePaymentsTab): PaymentRequestStatus[] => {
  switch (tab) {
    case InvoicePaymentsTab.Refunded:
      return [PaymentRequestStatus.Refunded, PaymentRequestStatus.PartialRefunded];
    case InvoicePaymentsTab.Failed:
      return [PaymentRequestStatus.Failed];
    case InvoicePaymentsTab.Cancelled:
      return [PaymentRequestStatus.Void];
    case InvoicePaymentsTab.Collected:
      return [PaymentRequestStatus.Paid, PaymentRequestStatus.Refunded, PaymentRequestStatus.PartialRefunded];
    default:
      return [];
  }
};

const getInvoicePaymentsParams = ({
  activeTab,
  paging,
  sorting,
  search,
  filters,
  timeZone
}: {
  activeTab: InvoicePaymentsTab;
  paging: Paging;
  sorting: InvoicePaymentsSorting;
  search: InvoicePaymentsSearch;
  filters: InvoicePaymentsFilters;
  timeZone: string;
}): GetInvoicePaymentQueryParams => {
  const { page, perPage } = paging;
  // const { sortBy, sortType } = sorting;
  const { searchBy, searchValue } = search;
  const { clients, appointmentTypes, createdAt } = filters;

  return {
    ...(activeTab !== InvoicePaymentsTab.Scheduled && {
      status: getPaymentRequestStatusFilters(activeTab).join(',')
    }),
    page,
    perPage,
    ...(clients.length > 0 && {
      clientRecordIds: clients.map(({ _id }) => _id).join(',')
    }),
    ...(appointmentTypes.length > 0 && {
      sessionTypeIds: appointmentTypes.map(({ _id }) => _id).join(',')
    }),
    ...(search.searchValue && {
      searchValue,
      searchBy
    }),
    ...(createdAt.from &&
      createdAt.to &&
      (activeTab === InvoicePaymentsTab.Scheduled
        ? {
            from: momentTz.tz(createdAt.from, timeZone).add(2, 'days').toISOString(),
            to: momentTz.tz(createdAt.to, timeZone).add(2, 'days').endOf('day').toISOString()
          }
        : {
            from: momentTz.tz(createdAt.from, timeZone).toISOString(),
            to: momentTz.tz(createdAt.to, timeZone).endOf('day').toISOString()
          }))
    // ...(sortBy && sortBy === 'received' && sortType && { sortByReceived: sortType })
    //TODO: Revert after fix performance issue
  };
};

export const useGetInvoicePayments = (tab?: InvoicePaymentsTab) => {
  const { accountId } = useGetAccountId();
  const { timeZoneByView } = useTimeZone();
  const { isPaymentListEnabled } = useGetFeatureToggle();

  const filters = useAppSelector(selectFilters);
  const collectedPaging = useAppSelector(selectCollectedPaging);
  const refundedPaging = useAppSelector(selectRefundedPaging);
  const failedPaging = useAppSelector(selectFailedPaging);
  const cancelledPaging = useAppSelector(selectCancelledPaging);
  const search = useAppSelector(selectSearch);
  const sorting = useAppSelector(selectSorting);
  const activeTab = useAppSelector(selectActiveTab);

  const currentTab = tab || activeTab;

  const paging =
    currentTab === InvoicePaymentsTab.Collected
      ? collectedPaging
      : currentTab === InvoicePaymentsTab.Refunded
      ? refundedPaging
      : currentTab === InvoicePaymentsTab.Failed
      ? failedPaging
      : cancelledPaging;

  const { data, isLoading, isFetching, isError } = useGetInvoicePaymentsQuery(
    {
      accountId,
      params: getInvoicePaymentsParams({
        paging,
        sorting,
        search,
        filters,
        activeTab: currentTab,
        timeZone: timeZoneByView
      })
    },
    {
      skip: !isPaymentListEnabled || InvoicePaymentsTab.Scheduled === currentTab
    }
  );

  return {
    isError,
    totalItems: data?.paging.totalItems || 0,
    invoicePayments: data?.paymentRequest || [],
    isInvoicePaymentsLoading: isLoading || isFetching
  };
};

export const useGetScheduledPayments = (tab?: InvoicePaymentsTab) => {
  const { accountId } = useGetAccountId();
  const { timeZoneByView } = useTimeZone();
  const { isPaymentListEnabled } = useGetFeatureToggle();

  const filters = useAppSelector(selectFilters);
  const scheduledPaging = useAppSelector(selectScheduledPaging);
  const search = useAppSelector(selectSearch);
  const sorting = useAppSelector(selectSorting);
  const activeTab = useAppSelector(selectActiveTab);

  const { data, isLoading, isFetching, isError } = useGetScheduledPaymentsQuery(
    {
      accountId,
      params: getInvoicePaymentsParams({
        paging: scheduledPaging,
        sorting,
        search,
        filters,
        activeTab: InvoicePaymentsTab.Scheduled,
        timeZone: timeZoneByView
      })
    },
    { skip: !isPaymentListEnabled || !(!!tab || activeTab === InvoicePaymentsTab.Scheduled) }
  );

  return {
    isGetScheduledPaymentsError: isError,
    totalScheduledPaymentItems: data?.paging.totalItems || 0,
    scheduledPayments: data?.appointments || [],
    isScheduledPaymentsLoading: isLoading || isFetching
  };
};
