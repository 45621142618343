import { components, SingleValueProps } from 'react-select';

import { MBSCodeOption } from '../../ClaimForm';

import styles from './MBSSingleValue.module.scss';

const MBSSingleValue = ({ children, ...props }: SingleValueProps<MBSCodeOption>) => {
  const { description } = props.data;

  return (
    <components.SingleValue {...props}>
      <div className={styles.singleValueContainer}>
        <div className={styles.mbsCode}>{children}</div>
        <div className={styles.mbsCodeDetails}>{description}</div>
      </div>
    </components.SingleValue>
  );
};

export default MBSSingleValue;
