import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { Referral, ReferralStatus } from 'interfaces/Referral/Referral';

export enum SearchFilterEnum {
  ReferralId = 'referralId',
  ReferrerName = 'referrerName',
  SubjectName = 'subjectName'
}

export interface SearchFilterItemInterface {
  name: string;
  _id: SearchFilterEnum;
}

export enum ReferralLinkage {
  Linked = 'linked',
  Unlinked = 'unlinked'
}

export enum ReferralTab {
  All = 'all',
  New = ReferralStatus.New,
  Triage = ReferralStatus.Triage,
  Decline = ReferralStatus.Decline,
  Accepted = ReferralStatus.Accepted,
  Unlinked = ReferralLinkage.Unlinked
}

export interface ReferralFromAPI extends Referral {
  clientRecord?: clientRecordsInterface;
}

export enum ReferrerType {
  Self = 'self',
  FriendFamily = 'friendFamily',
  Organisation = 'organisation',
  Professional = 'professional'
}

export enum OrganisationType {
  GpSurgery = 'gpSurgery',
  Charity = 'charity',
  Community = 'community',
  SchoolOrCollege = 'schoolOrCollege',
  Hospital = 'hospital',
  Employer = 'employer',
  OtherHealth = 'otherHealth',
  Other = 'other'
}
