import { useEffect, useRef, useState } from 'react';

import styles from './CaseNoteHeaderImageBtn.module.scss';
import AddAssessmentModal from '../AddAssessmentModal/AddAssessmentModal';
import AddJournalDetailModal from './components/AddJournalDetailModal/AddJournalDetailModal';
import AddQuickNoteModal from '../AddQuickNoteModal/AddQuickNoteModal';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface CaseNoteHeaderImageBtnProps {
  recordId: string;
  profileId: string;
  caseNoteBodyVal: string;
  onChangeBody: any;
}

const CaseNoteHeaderImageBtn = ({
  recordId,
  profileId,
  caseNoteBodyVal,
  onChangeBody
}: CaseNoteHeaderImageBtnProps) => {
  const node = useRef<HTMLDivElement>(null);
  const [t] = useTranslation();

  const [isAddAssessmentModalVisible, setIsAddAssessmentModalVisible] = useState(false);
  const [isAddJournalDetailModalVisible, setIsAddJournalDetailModalVisible] = useState(false);
  const [isAddQuickNoteModalVisible, setIsAddQuickNoteModalVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleAddAssessmentModalCancel = (newAssessmentImages?: string) => {
    setIsAddAssessmentModalVisible(false);
    newAssessmentImages && onChangeBody(caseNoteBodyVal + newAssessmentImages);
  };

  const handleAddJournalDetailModalCancel = (newJournalDetailImages?: string) => {
    setIsAddJournalDetailModalVisible(false);
    newJournalDetailImages && onChangeBody(caseNoteBodyVal + newJournalDetailImages);
  };

  const handleAddQuickNoteModalCancel = (newQuickNoteImages?: string) => {
    setIsAddQuickNoteModalVisible(false);
    newQuickNoteImages && onChangeBody(caseNoteBodyVal + newQuickNoteImages);
  };

  const handleDropDownMenu = (typeVal: string) => {
    switch (typeVal) {
      case 'assessmentModal':
        setIsAddAssessmentModalVisible(true);
        break;
      case 'journalDetailModal':
        setIsAddJournalDetailModalVisible(true);
        break;
      case 'quickNoteModal':
        setIsAddQuickNoteModalVisible(true);
        break;
    }
    setShowMenu(false);
  };

  return (
    <>
      <AddAssessmentModal
        recordId={recordId}
        visible={isAddAssessmentModalVisible}
        onCancel={handleAddAssessmentModalCancel}
      />
      <AddJournalDetailModal
        recordId={recordId}
        profileId={profileId}
        visible={isAddJournalDetailModalVisible}
        onCancel={handleAddJournalDetailModalCancel}
      />
      <AddQuickNoteModal
        recordId={recordId}
        visible={isAddQuickNoteModalVisible}
        onCancel={handleAddQuickNoteModalCancel}
      />
      <div ref={node} className={styles.rightSection}>
        <ButtonAlt size={'medium'} variant={'outlined'} onClick={() => setShowMenu(!showMenu)} icon={'perm_media'}>
          Insert data
        </ButtonAlt>
        <div className={styles.menuWrapper}>
          <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
            <div className={styles.listBox} onClick={() => handleDropDownMenu('assessmentModal')}>
              <div className={styles.listTitle}>{t('client_case_notes.assessment_summary')}</div>
              <div className={styles.listDesc}>{t('client_case_notes.assessment_summary.description')}</div>
            </div>
            <div className={styles.listBox} onClick={() => handleDropDownMenu('journalDetailModal')}>
              <div className={styles.listTitle}>{t('client_case_notes.journal_detail')}</div>
              <div className={styles.listDesc}>{t('client_case_notes.journal_detail.description')}</div>
            </div>
            <div className={styles.listBox} onClick={() => handleDropDownMenu('quickNoteModal')}>
              <div className={styles.listTitle}>{t('client_case_notes.quick_note')}</div>
              <div className={styles.listDesc}>{t('client_case_notes.quick_note.description')}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseNoteHeaderImageBtn;
