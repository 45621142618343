import { useState } from 'react';
import classnames from 'classnames';
import FlexBox from 'components/FlexBox/FlexBox';
import IconButton from 'components/IconButton/IconButton';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { IInvoiceTemplate, ItemColumns } from 'pages/Invoices/interface';
import { calculateTaxValue, calculateTotalValue } from 'pages/InvoiceGeneratorWithTemplate/helpers';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectInvoiceForm, setInvoiceForm } from 'redux/invoices/createInvoiceWithTemplateSlice';
import TaxRate from 'pages/InvoiceGenerator/components/InvoiceGeneratorForm/components/ItemsList/components/TaxRate/TaxRate';

import AppointmentTags from '../AppointmentTags/AppointmentTags';
import IncludeDiscount from '../IncludeDiscount/IncludeDiscount';
import InlineEditField from '../InlineEditField/InlineEditField';
import styles from './AppointmentInfoSection.module.scss';
import moment from 'moment';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { config } from 'config/config';

interface AppointmentInfoSectionProps {
  invoiceTemplate: IInvoiceTemplate;
  totalPayment: string;
}

const AppointmentInfoSection = ({ invoiceTemplate, totalPayment }: AppointmentInfoSectionProps) => {
  const dispatch = useAppDispatch();
  const MONEY_SYMBOL = config.currencySymbol;
  const TAX_LABEL = config.taxLabel;
  const [isTaxFieldVisible, setIsTaxFieldVisible] = useState(false);

  const { allowMultipleItems, itemColumns } = invoiceTemplate;
  const invoiceForm = useAppSelector(selectInvoiceForm);
  const selectedAppointments = invoiceForm?.items || [];
  const totalQuantity = selectedAppointments.reduce((finalTotal, item) => finalTotal + Number(item.quantity), 0);
  const totalCost = selectedAppointments.reduce((finalTotal, item) => finalTotal + Number(item.cost), 0).toFixed(2);
  const totalTax = selectedAppointments.reduce((finalTotal, item) => finalTotal + Number(item.tax), 0).toFixed(2);
  const taxRate = invoiceForm.taxRate.toFixed(1);

  const getHeaders = () => {
    const headers = [
      { label: '', key: 'remove' },
      { label: '#', key: 'index' },
      { label: 'OVERVIEW', key: 'overview' }
    ];

    if (itemColumns.includes(ItemColumns.Quantity)) {
      headers.push({
        label: ItemColumns.Quantity.toUpperCase(),
        key: ItemColumns.Quantity
      });
    }

    if (itemColumns.includes(ItemColumns.UnitPrice)) {
      headers.push({
        label: ItemColumns.UnitPrice.split(/(?=[A-Z])/)
          .join(' ')
          .toUpperCase(),
        key: ItemColumns.UnitPrice
      });
    }

    if (itemColumns.includes(ItemColumns.TaxRate)) {
      headers.push({
        label: `${TAX_LABEL} RATE`,
        key: ItemColumns.TaxRate
      });
    }

    headers.push({
      label: `${ItemColumns.Total.toUpperCase()} (${MONEY_SYMBOL})`,
      key: ItemColumns.Total
    });

    return headers;
  };

  const onChangeDiscount = ({ type, value }: { type: string; value: number }) => {
    dispatch(
      setInvoiceForm({
        discount: !type ? undefined : { type, value }
      })
    );
  };

  const onChangeAppointment = (e: React.ChangeEvent<HTMLInputElement>) => (fieldIndex: number) => {
    const clonedRecord = { ...invoiceForm };
    const updatedAppointment = {
      ...clonedRecord.items[fieldIndex],
      [e.target.name]: e.target.value,
      tax: calculateTaxValue(
        invoiceForm.taxRate,
        e.target.name === 'cost' ? Number(e.target.value) : Number(clonedRecord.items[fieldIndex].cost),
        e.target.name === 'quantity' ? Number(e.target.value) : Number(clonedRecord.items[fieldIndex].quantity)
      ),
      total: calculateTotalValue(
        invoiceForm.taxRate,
        e.target.name === 'cost' ? Number(e.target.value) : Number(clonedRecord.items[fieldIndex].cost),
        e.target.name === 'quantity' ? Number(e.target.value) : Number(clonedRecord.items[fieldIndex].quantity)
      )
    };
    const updatedAppointments = [...clonedRecord.items];
    updatedAppointments[fieldIndex] = updatedAppointment;
    dispatch(
      setInvoiceForm({
        items: updatedAppointments
      })
    );
  };

  const onAddNewManualItem = () => {
    const { taxRate } = invoiceForm;
    dispatch(
      setInvoiceForm({
        items: [
          ...invoiceForm.items,
          {
            overview: '',
            description: '',
            quantity: '1',
            cost: '0.00',
            tax: calculateTaxValue(taxRate, 0, 1).toFixed(2),
            total: '0.00'
          }
        ]
      })
    );
  };

  const onRemoveAppointmentItem = (fieldIndex: number) => () => {
    const clonedAppointments = [...selectedAppointments];
    clonedAppointments.splice(fieldIndex, 1);
    dispatch(
      setInvoiceForm({
        items: clonedAppointments,
        medicare: {
          ...invoiceForm.medicare,
          claim: {
            ...invoiceForm.medicare?.claim,
            dateOfService:
              clonedAppointments.length === 1 && clonedAppointments[0].appointmentDate
                ? moment(clonedAppointments[0].appointmentDate, 'DD-MM-YYYY').format(MOMENTJS_DATE_FORMAT)
                : ''
          }
        }
      })
    );
  };

  const onSetTaxRate = (value: string) => {
    const taxRate = Number(value);
    const updatedItems = invoiceForm.items.map((item) => ({
      ...item,
      tax: calculateTaxValue(taxRate, item.cost, item.quantity),
      total: calculateTotalValue(taxRate, item.cost, item.quantity)
    }));

    dispatch(
      setInvoiceForm({
        taxRate,
        items: updatedItems
      })
    );

    setIsTaxFieldVisible(false);
  };

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            {getHeaders().map(({ label, key }) => (
              <th
                key={key}
                className={
                  key === 'remove' || key === 'index'
                    ? styles.noPadding
                    : key === ItemColumns.Quantity ||
                      key === ItemColumns.UnitPrice ||
                      key === ItemColumns.TaxRate ||
                      key === ItemColumns.Total
                    ? styles.width110
                    : undefined
                }
              >
                {key === ItemColumns.TaxRate ? (
                  <>
                    {isTaxFieldVisible && <TaxRate defaultTaxRate={taxRate} onSetTaxRate={onSetTaxRate} />}
                    <div className={styles.taxRateContainer}>
                      TAX (
                      <ButtonAlt
                        variant={'text'}
                        size={'small'}
                        className={styles.taxButton}
                        type="button"
                        onClick={() => setIsTaxFieldVisible(!isTaxFieldVisible)}
                      >
                        {taxRate}
                      </ButtonAlt>
                      %)
                    </div>
                  </>
                ) : (
                  label
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedAppointments.length > 0 &&
            selectedAppointments.map((item, fieldIndex) => {
              const { overview, description, quantity, cost, tags, tax, total } = item;
              const showOneLineOverview = !item.appointmentId || description === '';
              const appointmentInfoField = {
                overview: {
                  name: 'overview',
                  value: overview,
                  editable: true
                },
                description: {
                  name: 'description',
                  value: description,
                  editable: true
                },
                quantity: {
                  name: 'quantity',
                  value: quantity.toString(),
                  editable: true
                },
                cost: {
                  name: 'cost',
                  value: cost,
                  editable: true
                },
                tax: {
                  name: 'tax',
                  value: Number(tax).toFixed(2),
                  editable: false
                },
                total: {
                  name: 'total',
                  value: Number(total).toFixed(2),
                  editable: false
                }
              };
              return (
                <tr key={fieldIndex}>
                  <td className={styles.noPadding}>
                    <IconButton
                      className={styles.removeIconButton}
                      iconName="remove_circle"
                      onClick={onRemoveAppointmentItem(fieldIndex)}
                    />
                  </td>
                  <td className={styles.noPadding}>{fieldIndex + 1}</td>
                  <td>
                    {showOneLineOverview ? (
                      <InlineEditField
                        value={appointmentInfoField.overview.value}
                        name={appointmentInfoField.overview.name}
                        editable={appointmentInfoField.overview.editable}
                        onChange={(e) => onChangeAppointment(e)(fieldIndex)}
                      />
                    ) : (
                      <FlexBox direction="column">
                        {[appointmentInfoField.overview, appointmentInfoField.description].map((item, index) => (
                          <InlineEditField
                            key={index}
                            value={item.value}
                            name={item.name}
                            editable={item.editable}
                            onChange={(e) => onChangeAppointment(e)(fieldIndex)}
                            placeholder="Please add description"
                          />
                        ))}
                        {tags && tags.length > 0 && <AppointmentTags tags={tags} />}
                      </FlexBox>
                    )}
                  </td>
                  {itemColumns.includes(ItemColumns.Quantity) && (
                    <td>
                      <InlineEditField
                        value={appointmentInfoField.quantity.value}
                        name={appointmentInfoField.quantity.name}
                        editable={appointmentInfoField.quantity.editable}
                        onChange={(e) => onChangeAppointment(e)(fieldIndex)}
                      />
                    </td>
                  )}
                  {itemColumns.includes(ItemColumns.UnitPrice) && (
                    <td>
                      <InlineEditField
                        value={appointmentInfoField.cost.value}
                        name={appointmentInfoField.cost.name}
                        editable={appointmentInfoField.cost.editable}
                        onChange={(e) => onChangeAppointment(e)(fieldIndex)}
                      />
                    </td>
                  )}
                  {itemColumns.includes(ItemColumns.TaxRate) && (
                    <td>
                      <InlineEditField
                        value={appointmentInfoField.tax.value}
                        name={appointmentInfoField.tax.name}
                        editable={appointmentInfoField.tax.editable}
                        readOnly
                      />
                    </td>
                  )}
                  <td>
                    <InlineEditField
                      value={appointmentInfoField.total.value}
                      name={appointmentInfoField.total.name}
                      editable={appointmentInfoField.total.editable}
                      readOnly
                    />
                  </td>
                </tr>
              );
            })}
          {!allowMultipleItems && selectedAppointments.length >= 1 ? null : (
            <tr>
              <td colSpan={6}>
                <ButtonAlt variant={'text'} type="button" onClick={onAddNewManualItem} icon={'add_circle'}>
                  Add New item
                </ButtonAlt>
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={7} className={styles.includeDiscountRowPadding}>
              <IncludeDiscount
                type={invoiceForm.discount?.type}
                value={invoiceForm.discount?.value}
                onChange={onChangeDiscount}
              />
            </td>
          </tr>
          <tr className={styles.noBorder}>
            <td colSpan={2} />
            <td align="right" className={styles.totalLabel}>
              TOTAL
            </td>
            {itemColumns.includes(ItemColumns.Quantity) && (
              <td className={styles.dashedBorderTop}>{!isNaN(Number(totalQuantity)) ? totalQuantity : 0}</td>
            )}
            {itemColumns.includes(ItemColumns.UnitPrice) && (
              <td className={styles.dashedBorderTop}>
                {MONEY_SYMBOL}
                {!isNaN(Number(totalCost)) ? totalCost : 0}
              </td>
            )}
            {itemColumns.includes(ItemColumns.TaxRate) && (
              <td className={styles.dashedBorderTop}>
                {MONEY_SYMBOL}
                {!isNaN(Number(totalTax)) ? totalTax : 0}
              </td>
            )}
            <td className={classnames(styles.dashedBorderTop, styles.totalPrice)}>
              {MONEY_SYMBOL}
              {totalPayment}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AppointmentInfoSection;
