import { notification } from 'antd';
import classNames from 'classnames';
import { Provider } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { handleCopyContent } from 'utils/CopyFeature';
import { useGetAccessToken } from 'utils/hooks/token';
import { setDefaultProvider } from 'utils/http/BillingService/Invoice/claimingcom';

import ConfirmActionModal from './components/ConfirmActionModal/ConfirmActionModal';
import EditProviderModal from './components/EditProviderModal/EditProviderModal';
import styles from './ProviderCard.module.scss';

interface ProviderCardProps {
  provider: Provider & { locationName: string; locationsActive: boolean };
  clinicianId?: string;
  refetchClinicianMedicareProviders: () => void;
}

const ProviderCard = ({ provider, clinicianId, refetchClinicianMedicareProviders }: ProviderCardProps) => {
  const { token } = useGetAccessToken();
  const menuNode = useRef<HTMLDivElement>(null);
  const [expandMenu, setExpandMenu] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showEditProviderModal, setShowEditProviderModal] = useState(false);
  const [showConfirmActionModal, setShowConfirmActionModal] = useState(false);
  const [actionType, setActionType] = useState<'delete' | 'deactivate' | 'reactivate' | ''>('');

  const {
    locationName,
    locationMinorId,
    default: isDefault,
    name: providerName,
    providerNumber,
    active,
    locationsActive
  } = provider;
  const isActive = locationsActive && active;

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target)) {
      return;
    }
    setExpandMenu(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleSetDefault = async () => {
    if (isDefault || isUpdating || !active) {
      return;
    }
    setIsUpdating(true);
    try {
      await setDefaultProvider(token, locationMinorId, providerNumber, true, clinicianId);
      await refetchClinicianMedicareProviders();

      notification.success({
        message: 'Default provider number updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to set this provider number as default' });
    }
    setIsUpdating(false);
  };

  const handleClickEdit = () => {
    setShowEditProviderModal(true);
    setExpandMenu(false);
  };

  const handleClickChangeActive = () => {
    if (locationsActive) {
      setActionType(active ? 'deactivate' : 'reactivate');
      setShowConfirmActionModal(true);
      setExpandMenu(false);
    }
  };

  const handleClickDelete = () => {
    setActionType('delete');
    setShowConfirmActionModal(true);
    setExpandMenu(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{`${providerName}${!isActive ? ' (Deactivated)' : ''}`}</div>
        <div className={styles.actionContainer}>
          <div className={styles.iconsContainer}>
            {isActive && (
              <i
                className={classNames(
                  'material-icons',
                  styles.icon,
                  isDefault ? styles.activeDefault : styles.inactiveDefault,
                  isUpdating && styles.spin
                )}
                onClick={handleSetDefault}
              >
                star
              </i>
            )}
            <i className={`material-icons ${styles.icon}`} onClick={() => setExpandMenu(true)}>
              more_vert
            </i>
          </div>
          <div className={styles.menuContainer} ref={menuNode}>
            <div className={expandMenu ? styles.showMenu : styles.hideMenu}>
              {isActive && (
                <div className={styles.item} onClick={handleClickEdit}>
                  <i className={`material-icons ${styles.itemIcon}`}>edit</i>
                  <div className={styles.label}>Edit</div>
                </div>
              )}
              <div
                data-tooltip-id={`locationDeactivated-${providerNumber}`}
                className={locationsActive ? styles.item : styles.disabledItem}
                onClick={handleClickChangeActive}
              >
                <i className={`material-icons ${styles.itemIcon}`}>{isActive ? 'location_off' : 'location_on'}</i>
                <div className={styles.label}>{isActive ? 'Deactivate' : 'Reactivate'}</div>
              </div>
              <div className={styles.item} onClick={handleClickDelete}>
                <i className={`material-icons ${styles.itemIcon}`}>delete</i>
                <div className={styles.label}>Delete</div>
              </div>
            </div>
          </div>
          {!locationsActive && (
            <Tooltip id={`locationDeactivated-${providerNumber}`}>
              {!locationsActive
                ? 'You cannot reactivate this provider number because the location ID deactivated.'
                : null}
            </Tooltip>
          )}
        </div>
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.labelContainer}>
          <div className={styles.label}>
            <div>Location Name</div>
            <div>:</div>
          </div>
          <div className={styles.label}>
            <div>Location ID</div>
            <div>:</div>
          </div>
          <div className={styles.label}>
            <div>Provider Number</div>
            <div>:</div>
          </div>
        </div>
        <div className={styles.valueContainer}>
          <div className={styles.valueRow}>{locationName}</div>
          <div className={styles.valueRow}>{locationMinorId}</div>
          <div className={styles.valueRow}>
            <div className={styles.value} onClick={() => handleCopyContent(providerNumber)}>
              {providerNumber}
            </div>
            <i className={`material-icons ${styles.icon}`} onClick={() => handleCopyContent(providerNumber)}>
              content_copy
            </i>
          </div>
        </div>
      </div>
      <EditProviderModal
        visible={showEditProviderModal}
        provider={provider}
        clinicianId={clinicianId}
        setShowEditProviderModal={setShowEditProviderModal}
        refetchClinicianMedicareProviders={refetchClinicianMedicareProviders}
      />
      <ConfirmActionModal
        visible={showConfirmActionModal}
        provider={provider}
        actionType={actionType}
        clinicianId={clinicianId}
        setShowConfirmActionModal={setShowConfirmActionModal}
        refetchClinicianMedicareProviders={refetchClinicianMedicareProviders}
      />
    </div>
  );
};

export default ProviderCard;
