import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { AppointmentSlot } from 'components/v2/AvailableTimeSelect/Interface';
import DatePicker from 'components/v2/DatePicker/DatePicker';
import TimePicker from 'components/v2/TimePicker/TimePicker';
import { useField } from 'formik';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { AppointmentType } from 'interfaces/Schedule/AppointmentType';
import moment, { Moment } from 'moment';
import { useLayoutEffect } from 'react';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { convertTimeToUtcDateObject } from 'utils/helpers/timezone';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { defaultTimezone, getTimezoneLabel } from 'utils/hooks/GetTimezones/getTimezones';
import { formatEndDate } from 'utils/dateChecker';
import styles from './CustomiseSchedule.module.scss';

const IS_DEVELOPMENT = isDevelopmentENV();

interface CustomiseScheduleProps {
  onChangeSchedule: (slot: AppointmentSlot) => void;
  setIsCustomising: (isCustomising: boolean) => void;
  timeslot?: AppointmentSlot;
  timeZone?: string;
  appointmentType?: AppointmentType;
  bookedAppointment?: AppointmentSlots;
}

const CustomiseSchedule = ({
  setIsCustomising,
  onChangeSchedule,
  timeslot,
  timeZone,
  appointmentType,
  bookedAppointment
}: CustomiseScheduleProps) => {
  const [{ value: valueDate }, metaDate, dateHelper] = useField('date');
  const [{ value: valueStartTime }, , startTimeHelper] = useField('startTime');
  const [{ value: valueEndTime }, metaEndTime, endTimeHelper] = useField('endTime');
  const { isSomeoneHealthFeatureToggle } = useGetFeatureToggle();
  const { isEdgeUserView } = useGetAccountPackageView();

  useLayoutEffect(() => {
    if (!timeslot) {
      const date = bookedAppointment?.date
        ? moment(bookedAppointment.startDateTime)
            .tz(timeZone || defaultTimezone)
            .format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
      const startTime = bookedAppointment?.startTime
        ? moment(bookedAppointment.startDateTime)
            .tz(timeZone || defaultTimezone)
            .format('HH:mm')
        : moment().format('HH:mm');
      const endTime = bookedAppointment?.endTime
        ? moment(bookedAppointment.endDateTime)
            .tz(timeZone || defaultTimezone)
            .format('HH:mm')
        : moment()
            .add(appointmentType?.duration.minutes || 30, 'minutes')
            .format('HH:mm');

      dateHelper.setValue(date);
      startTimeHelper.setValue(startTime);
      endTimeHelper.setValue(endTime);

      const endDateTime = convertTimeToUtcDateObject({
        date,
        time: endTime,
        timeZone
      });

      onChangeSchedule({
        date,
        endDate: formatEndDate(endDateTime),
        startTime,
        endTime,
        startDateTime: convertTimeToUtcDateObject({
          date,
          time: startTime,
          timeZone
        }),
        endDateTime
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeslot]);

  const handleChangeDate = (value: Moment | null) => {
    if (value) {
      dateHelper.setValue(value.format('YYYY-MM-DD'));
      if (timeslot && onChangeSchedule) {
        onChangeSchedule({
          ...timeslot,
          date: value.format('YYYY-MM-DD'),
          endDate: formatEndDate(
            convertTimeToUtcDateObject({
              date: value.format('YYYY-MM-DD'),
              time: timeslot.endTime,
              timeZone
            })
          ),
          startDateTime: convertTimeToUtcDateObject({
            date: value.format('YYYY-MM-DD'),
            time: timeslot.startTime,
            timeZone
          }),
          endDateTime: convertTimeToUtcDateObject({
            date: value.format('YYYY-MM-DD'),
            time: timeslot.endTime,
            timeZone
          })
        });
      }
    } else {
      dateHelper.setValue('');
    }
  };

  const handleChangeStartTime = (val: any) => {
    startTimeHelper.setValue(moment(val, 'hh:mm a').format('HH:mm'));
    if (timeslot && onChangeSchedule) {
      onChangeSchedule({
        ...timeslot,
        startTime: moment(val, 'hh:mm a').format('HH:mm'),
        startDateTime: convertTimeToUtcDateObject({
          date: timeslot.date,
          time: moment(val, 'hh:mm a').format('HH:mm'),
          timeZone
        })
      });
    }
  };

  const handleChangeEndTime = (val: any) => {
    endTimeHelper.setValue(moment(val, 'hh:mm a').format('HH:mm'));
    if (timeslot && onChangeSchedule) {
      onChangeSchedule({
        ...timeslot,
        endTime: moment(val, 'hh:mm a').format('HH:mm'),
        endDateTime: convertTimeToUtcDateObject({
          date: timeslot.date,
          time: moment(val, 'hh:mm a').format('HH:mm'),
          timeZone
        })
      });
    }
  };

  return (
    <>
      <div className={styles.header}>
        {appointmentType?.name ? (
          <div className={styles.appointmentNameContainer}>
            <div className={styles.label}>CUSTOMISE TIMES FOR</div>
            <div className={styles.text}>{appointmentType.name}</div>
          </div>
        ) : (
          'Select day and time'
        )}
        {!bookedAppointment?.isActivity && (
          <div className={styles.customise} onClick={() => setIsCustomising(false)}>
            <span className="material-icons-outlined">arrow_back_ios</span>
            Choose an open slot
          </div>
        )}
      </div>
      <div className={styles.container}>
        <div className={styles.fieldsContainer}>
          <div className={styles.field}>
            <div className={styles.dateField}>
              <span className="material-icons-outlined">edit_calendar</span>
              <DatePicker
                noDisabledDate
                date={valueDate ? valueDate : moment().format('YYYY-MM-DD')}
                error={metaDate?.touched && !!metaDate.error}
                onChangeValue={handleChangeDate}
              />
            </div>
            <div className={styles.timeField}>
              <span className="material-icons-outlined">schedule</span>
              <TimePicker
                id={'customiseStartTime'}
                error={metaEndTime?.touched && !!metaEndTime.error}
                time={valueStartTime ? valueStartTime : moment().format('hh:mm a')}
                minuteStep={5}
                onChange={handleChangeStartTime}
              />
              <span>-</span>
              <TimePicker
                id={'customiseEndTime'}
                error={metaEndTime?.touched && !!metaEndTime.error}
                time={valueEndTime ? valueEndTime : moment().add(1, 'h').format('hh:mm a')}
                minuteStep={5}
                onChange={handleChangeEndTime}
              />
              <ErrorMessage
                className={styles.error}
                error={metaEndTime.error}
                visible={metaEndTime?.touched && !!metaEndTime.error}
              />
            </div>
            {(isSomeoneHealthFeatureToggle || IS_DEVELOPMENT) && (
              <div className={styles.timezoneInfo}>
                Time in {isEdgeUserView ? 'your' : "owner's"} time zone: <b>{getTimezoneLabel(timeZone)}</b>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomiseSchedule;
