import { notification } from 'antd';
import { Invoice } from 'pages/Invoices/interface';
import { useCallback, useEffect, useState } from 'react';
import { getInvoice } from 'utils/http/BillingService/Invoice/invoice';

export const useFetchInvoiceById = (token: string, invoiceId?: string) => {
  const [invoice, setInvoice] = useState<Invoice>();
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);

  const fetchInvoice = useCallback(async () => {
    if (invoiceId) {
      setIsInvoiceLoading(true);
      try {
        const response = await getInvoice(token, invoiceId);
        const data = (await response.json()) as Invoice;
        setInvoice(data);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get your invoice detail.' });
      } finally {
        setIsInvoiceLoading(false);
      }
    }
  }, [invoiceId, token]);

  useEffect(() => {
    if (token) {
      fetchInvoice();
    }
  }, [fetchInvoice, token]);

  return {
    invoice,
    isInvoiceLoading,
    fetchInvoice
  };
};
