/* eslint-disable max-depth */
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { notification } from 'antd';

import LoadingDot from 'components/LoadingDot/LoadingDot';
import PatientDetailsAttachmentCard from './components/PatientDetailsAttachmentCard/PatientDetailsAttachmentCard';

import styles from './PatientDetailsAttachments.module.scss';
import {
  getAttachmentsByRecordId,
  uploadAttachmentByRecordId,
  uploadAttachmentThumbnailByRecordId
} from 'utils/http/DocumentService/Attachments/attachments';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import FolderCard from 'components/FolderCard/FolderCard';
import { useGetFaxesQuery } from 'redux/endpoints/notificationServices/eFax';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import PatientDetailsFaxCard from './components/PatientDetailsFaxCard/PatientDetailsFaxCard';
import FaxDetailsModal from 'pages/Fax/FaxDetailsModal/FaxDetailsModal';
import { Fax } from 'interfaces/fax';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import classNames from 'classnames';
import { ThreeDotMenuHandle } from 'components/T23/ThreeDotMenu/ThreeDotMenu';
import { getFilenameAndExtension } from 'utils/file';

export interface Attachment {
  _id: string;
  clinicianId: string;
  clientId: string;
  title: string;
  caseNote?: {
    _id: string;
    title: string;
  };
  fileName: string;
  mimeType: string;
  fileSize: number;
  thumbnail?: string;
  createdAt: Date | string;
}

const MAX_FILE_SIZE = 19 * 1024 * 1024;

const MAX_HEIGHT = 140;
const MAX_WIDTH = 200;

const resizeAndCropImage = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    try {
      const image = new Image();

      const canvas = document.createElement('canvas');

      canvas.style.display = 'none';

      image.onload = () => {
        let resizeHeight = image.height;
        let resizeWidth = image.width;

        if (resizeHeight > MAX_HEIGHT) {
          resizeWidth = (MAX_HEIGHT / resizeHeight) * resizeWidth;
          resizeHeight = MAX_HEIGHT;
        }

        if (resizeWidth > MAX_WIDTH) {
          resizeHeight = (MAX_WIDTH / resizeWidth) * resizeHeight;
          resizeWidth = MAX_WIDTH;
        }

        canvas.height = resizeHeight;
        canvas.width = resizeWidth;

        canvas.getContext('2d')?.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

        const dataUrl = canvas.toDataURL();
        canvas.remove();
        image.remove();

        resolve(dataUrl);
      };

      image.src = URL.createObjectURL(file);
    } catch (ex) {
      console.error(ex);
      reject(ex);
    }
  });
};

enum Folder {
  Attachments = 'ATTACHMENTS',
  Faxes = 'FAXES'
}

const useFetchAttachments = (token: string, recordId: string) => {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [isAttachmentsLoading, setIsAttachmentsLoading] = useState(true);

  const fetchAttachments = async (token: string) => {
    try {
      const callGetAttachments = await getAttachmentsByRecordId(token, recordId);
      const attachments = (await callGetAttachments.json()) as Attachment[];

      const sortByCreatedAt = attachments.sort((attObj1, attObj2) =>
        moment(attObj2.createdAt).diff(moment(attObj1.createdAt))
      );

      setAttachments(sortByCreatedAt);
    } catch (ex) {
      notification.error({ message: "Something went wrong while trying to get this patient's attachments" });
    }

    setIsAttachmentsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAttachments(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { attachments, isAttachmentsLoading, setAttachments };
};

/** sync with https://github.com/tacklit/document-service/blob/master/src/utils/cloudStorage.ts */
const acceptedFileTypes = [
  '.7z',
  '.bmp',
  '.csv',
  '.eml',
  '.epub',
  '.gif',
  '.gz',
  '.heic',
  '.key',
  '.m4a',
  '.mp3',
  '.mp4',
  '.numbers',
  '.odp',
  '.ods',
  '.odt',
  '.pages',
  '.ppt',
  '.pptx',
  '.pub',
  '.rar',
  '.ref',
  '.rtf',
  '.tar',
  '.txt',
  '.wav',
  '.wma',
  '.wpd',
  '.xls',
  '.xlsx',
  '.xml',
  '.zip',
  'application/msword',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'text/html'
] as const;
const acceptedFileTypesString = acceptedFileTypes.toString();

interface PatientDetailsAttachmentsProps {
  recordId: string;
  token: string;
  isReadOnly?: boolean;
}

const PatientDetailsAttachments = ({ recordId, token, isReadOnly }: PatientDetailsAttachmentsProps) => {
  const [t] = useTranslation();
  const { accountId } = useGetAccountId();
  const { attachments, isAttachmentsLoading, setAttachments } = useFetchAttachments(token, recordId);
  const { faxEnable } = useGetFeatureToggle();
  const { data: faxesData, isFetching: isFaxesFetching } = useGetFaxesQuery(
    {
      accountId,
      params: {
        clientRecordIds: [recordId],
        includeFileMetadata: true,
        page: 1,
        perPage: 9999
      }
    },
    { skip: !faxEnable }
  );

  const [activeFolder, setActiveFolder] = useState(Folder.Attachments);
  const [addAttachmentButtonStatus, setAddAttachmentButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [selectedFax, setSelectedFax] = useState<Fax | null>(null);

  const closeMenuButtonRef = useRef<ThreeDotMenuHandle>(null);
  const viewingAttachmentFolder = activeFolder === Folder.Attachments;
  const viewingFaxFolder = activeFolder === Folder.Faxes;

  const faxes = useMemo(() => faxesData?.faxes ?? [], [faxesData]);
  const totalFiles = useMemo(() => attachments.filter((attachment) => attachment._id).length, [attachments]);

  const handleDeleteAttachment = (attachmentId: string) => {
    setAttachments(attachments.filter((attachment) => attachment._id !== attachmentId));
  };

  const handleUploadAttachmentClick = () => {
    if (!isReadOnly) {
      document.getElementById('patient-upload-attachment')?.click();
    }
  };

  const handleUploadAttachment = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      setAddAttachmentButtonStatus('active');

      const uploadedAttachments: Attachment[] = [];

      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];

        if (currentFile.size > MAX_FILE_SIZE) {
          notification.error({ message: `File ${currentFile.name} is too big` });
          continue;
        }

        const payload: Record<string, any> = {};

        try {
          const uploadedAttachmentDetail = await uploadAttachmentByRecordId(token, recordId, currentFile, payload);

          if (/(image\/)\w+/g.test(currentFile.type) && currentFile.type !== 'image/tiff') {
            const attachmentId = uploadedAttachmentDetail._id || '';
            const dataUrl = await resizeAndCropImage(currentFile);

            if (!dataUrl) {
              notification.error({ message: 'Something went wrong while trying to compress your image.' });
              continue;
            }
            const dataArray = dataUrl.split(',');
            const mimeType = dataArray[0].match(/:(.*?);/)?.[1];
            const dataStrings = atob(dataArray[1]);
            let dataLength = dataStrings.length;
            const actualData = new Uint8Array(dataLength);

            while (dataLength--) {
              actualData[dataLength] = dataStrings.charCodeAt(dataLength);
            }

            const { filename, extension } = getFilenameAndExtension(currentFile.name);
            const thumbnailFile = new File([actualData], `${filename}-thumbnail.${extension}`, { type: mimeType });

            const responseWithThumbnail = await uploadAttachmentThumbnailByRecordId(
              token,
              recordId,
              attachmentId,
              thumbnailFile
            );

            uploadedAttachments.push(responseWithThumbnail);
            setTimeout(() => setAddAttachmentButtonStatus(''), 1000);
          } else {
            uploadedAttachments.push(uploadedAttachmentDetail);
            setTimeout(() => setAddAttachmentButtonStatus(''), 1000);
          }
        } catch (ex) {
          notification.error({ message: `Failed to upload file ${currentFile.name}` });
          continue;
        }
      }

      if (uploadedAttachments.length > 0) {
        closeMenuButtonRef.current?.closeMenu();
        setAddAttachmentButtonStatus('finished');

        notification.success({
          message: `Successfully uploaded ${uploadedAttachments.length} ${
            uploadedAttachments.length === 1 ? 'attachment' : 'attachments'
          }.`,
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });

        setAttachments([...uploadedAttachments, ...attachments]);

        setTimeout(() => setAddAttachmentButtonStatus(''), 1000);
      } else {
        setAddAttachmentButtonStatus('');
      }
    }
  };

  return (
    <div className={styles.container}>
      {isAttachmentsLoading || isFaxesFetching ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.header}>
            <span className={styles.attachmentsCount}>{totalFiles} files attached</span>
            <input
              className={styles.uploadInput}
              id="patient-upload-attachment"
              type="file"
              accept={acceptedFileTypesString}
              multiple
              onChange={handleUploadAttachment}
            />
            {!viewingFaxFolder && (
              <>
                <ButtonAlt
                  status={addAttachmentButtonStatus}
                  disabled={!!addAttachmentButtonStatus || isReadOnly}
                  onClick={handleUploadAttachmentClick}
                  icon={'upload'}
                >
                  Upload new files
                </ButtonAlt>
              </>
            )}
          </div>
          <div className={styles.foldersContainer}>
            <FolderCard
              active={viewingAttachmentFolder}
              containerClassName={styles.folderCard}
              folderIconClassName={styles.folderIcon}
              folderName="Attachments"
              folderNameClassName={styles.folderName}
              closeMenuButtonRef={closeMenuButtonRef}
              moreMenu={
                <ThreeDotMenuItem
                  disabled={!!addAttachmentButtonStatus || isReadOnly}
                  label="Upload files"
                  onClick={handleUploadAttachmentClick}
                  postComponent={addAttachmentButtonStatus && <LoadingCircle />}
                  preIcon="upload"
                />
              }
              onCardClick={() => setActiveFolder(Folder.Attachments)}
              totalFiles={attachments.length}
            />
            {faxEnable && (
              <FolderCard
                active={viewingFaxFolder}
                containerClassName={styles.folderCard}
                folderIconClassName={styles.folderIcon}
                folderName="Faxes"
                folderNameClassName={styles.folderName}
                onCardClick={() => setActiveFolder(Folder.Faxes)}
                totalFiles={faxes.length}
              />
            )}
          </div>
          {(viewingAttachmentFolder && attachments.length === 0) || (viewingFaxFolder && faxes.length === 0) ? (
            <div className={styles.noFiles}>
              <div className={classNames('material-symbols-outlined', styles.icon)}>unknown_document</div>
              <div>
                <div className={styles.text}>{t('label.no_attachment_to_client')}</div>
                {viewingAttachmentFolder && (
                  <ButtonAlt
                    className={styles.button}
                    disabled={!!addAttachmentButtonStatus || isReadOnly}
                    icon="add_circle_outline"
                    onClick={handleUploadAttachmentClick}
                    status={addAttachmentButtonStatus}
                    variant="outlined"
                  >
                    Upload new files
                  </ButtonAlt>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.attachmentsContainer}>
              {viewingAttachmentFolder &&
                attachments.map((attachment) => (
                  <PatientDetailsAttachmentCard
                    attachment={attachment}
                    isReadOnly={isReadOnly}
                    key={attachment._id}
                    onDeleteAttachment={() => handleDeleteAttachment(attachment._id)}
                    recordId={recordId}
                  />
                ))}
              {faxEnable && viewingFaxFolder && (
                <>
                  {selectedFax?._id && (
                    <FaxDetailsModal
                      faxId={selectedFax?._id}
                      onCloseModal={() => setSelectedFax(null)}
                      visible={!!selectedFax?._id}
                    />
                  )}
                  {faxes.map((fax) => (
                    <PatientDetailsFaxCard key={fax._id} fax={fax} onTitleClick={(fax: Fax) => setSelectedFax(fax)} />
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PatientDetailsAttachments;
