import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styles from './MentorTimeSelection.module.scss';
import { DaySelection } from '../../../constants';

interface MentorTimeSelectionProps {
  filterStatusState: string;
  selectedTimeState: any;
  dayList: DaySelection[];
  onChangeTimeSelection: (selectedTime: DaySelection) => void;
  isLoading: boolean;
  totalAppointment: number;
}

const MentorTimeSelection = ({
  filterStatusState,
  dayList,
  selectedTimeState,
  onChangeTimeSelection,
  isLoading,
  totalAppointment
}: MentorTimeSelectionProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [filterArrowBtn, setFilterArrowBtn] = useState({
    left: false,
    right: false
  });

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const getScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', getScreenSize);

    return () => {
      window.removeEventListener('resize', getScreenSize);
    };
  }, [screenSize]);

  useEffect(() => {
    scrollSlider();
  }, [screenSize]);

  const onScrollSlider = (position: number) => {
    if (ref.current) {
      ref.current.scrollLeft += position;
    }
  };

  const scrollSlider = () => {
    if (ref.current) {
      const endOfRightPos = ref.current.scrollLeft + ref.current.clientWidth === ref.current.scrollWidth;

      setFilterArrowBtn({
        left: ref.current.scrollLeft > 0,
        right: !endOfRightPos
      });
    }
  };

  return (
    <>
      {filterStatusState === 'upcoming' ? (
        <div className={styles.timeSelection}>
          <div className={styles.leftBtn}>
            <div
              className={filterArrowBtn.left ? styles.arrowBtn : styles.arrowBtnHide}
              onClick={() => onScrollSlider(-20)}
            >
              <span className={'material-icons-outlined'}>keyboard_arrow_left</span>
              <div className={styles.leftDivider} />
            </div>
          </div>
          <div className={styles.timeSelectionRange} ref={ref} onScroll={() => scrollSlider()}>
            <div className={styles.timeSelectionSlider}>
              {dayList.map((item, index) => {
                return (
                  <div className={styles.timeSelectionItem} key={index}>
                    <button
                      className={classnames(
                        item.key === selectedTimeState.key && styles.active,
                        isLoading && styles.btnDisable
                      )}
                      onClick={() => {
                        onChangeTimeSelection(item);
                      }}
                      disabled={isLoading}
                    >
                      <div className={styles.timeLabel}>{item.label}</div>
                      {item.count > 0 && <div className={styles.numberOfMember}>{item.count}</div>}
                    </button>
                    {index < dayList.length - 1 && <div key={`time_separator_${index}`} className={styles.separator} />}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.rightBtn}>
            <div
              className={filterArrowBtn.right ? styles.arrowBtn : styles.arrowBtnHide}
              onClick={() => onScrollSlider(20)}
            >
              <span className={'material-icons-outlined'}>keyboard_arrow_right</span>
              <div className={styles.rightDivider} />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.passWrapper}>
          <div className={styles.timeSelectionItemPass}>
            {totalAppointment > 0 && !isLoading && (
              <div className={styles.numberOfMember}>{totalAppointment} past clients</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MentorTimeSelection;
