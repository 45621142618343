import { notification } from 'antd';
import { SimpleClientData } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsClientData/ClientData.interface';
import { useCallback, useEffect, useState } from 'react';
import { getSimpleClientData } from 'utils/http/CheckInService/OpenDataField/openDataField';
import { useGetAccountPackageView } from './GetAccountInfo/accountPackageView';

export const useFetchSimpleClientData = (token: string, clientRecordId: string) => {
  const [clientData, setClientData] = useState<Array<SimpleClientData>>([]);
  const [isClientDataLoading, setIsClientDataLoading] = useState(true);
  const { isEdgeReceptionist } = useGetAccountPackageView();

  const fetchSimpleClientData = useCallback(async () => {
    if (token && clientRecordId) {
      try {
        const response = await getSimpleClientData(token, clientRecordId);
        setClientData((await response.json()).data as SimpleClientData[]);
      } catch (e) {
        notification.error({ message: 'Something went wrong while trying to get your client data' });
      }
      setIsClientDataLoading(false);
    }
  }, [token, clientRecordId]);

  useEffect(() => {
    if (!isEdgeReceptionist) {
      fetchSimpleClientData();
    }
  }, [fetchSimpleClientData, isEdgeReceptionist]);

  return {
    clientData,
    isClientDataLoading,
    fetchSimpleClientData
  };
};
