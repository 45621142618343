import { notification } from 'antd';
import comment from 'assets/images/comment.svg';
import classNames from 'classnames';
import moment from 'moment';
import { CommentWidgetInterface } from 'pages/Report/ReportDetails/interface';
import { KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { scrollToView } from 'utils/scrollToView';

import styles from './CommentWidget.module.scss';
import SwitchLoading from 'components/MaterialFieldComponent/MaterialToggleSwitch/SwitchLoading/SwitchLoading';
import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';

interface CommentWidgetProps {
  clinician: {
    avatar: string;
    name: string;
  };
  commentItem: CommentWidgetInterface;
  replyAllowed?: boolean;
  onAddComment: (id: string, comment: string) => void;
  handleFocusOutside: () => void;
  handleExpandItem: (id: string, focusBool: boolean) => void;
}

const CommentWidget = ({
  clinician,
  commentItem,
  replyAllowed,
  onAddComment,
  handleFocusOutside,
  handleExpandItem
}: CommentWidgetProps) => {
  const [isExpanded, setIsExpanded] = useState(commentItem.from === 'newWidget');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { _id, comments, rightAligned, from } = commentItem;

  useEffect(() => {
    if (commentItem.from === 'side') {
      setIsExpanded(commentItem.expand);
    }
  }, [commentItem]);

  const handleOnClickExpand = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded(!isExpanded);
    if (from !== 'newWidget') {
      handleExpandItem(_id, !isExpanded);
      handleFocusOutside();
    }
  };

  const handleInput = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onKeyPressSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmitComment();
    }
  };

  const onSubmitComment = async () => {
    if (message.trim() !== '') {
      setIsSubmitting(true);
      try {
        await onAddComment(_id, message);
        setMessage('');
        scrollToView('lastId', true);
      } catch (ex) {
        notification.error({
          message: 'Something went wrong while trying to add your comment.'
        });
        console.error(ex);
      }
      setIsSubmitting(false);
    }
  };

  const handleClickOutSide = (event: any) => {
    if (divRef.current?.contains(event.target)) {
      return;
    }
    setIsExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  return (
    <div className={styles.container} key={_id} ref={divRef} onClick={(e) => handleInput(e)}>
      <div className={styles.iconContainer} onClick={(e) => handleOnClickExpand(e)}>
        <img src={comment} className={styles.icon} alt={'commentIcon'} />
      </div>
      {isExpanded && (
        <div className={classNames(styles.commentContainer, rightAligned && styles.rightAligned)}>
          {comments && comments.length > 0 && (
            <div className={styles.comments}>
              {comments.map((comment, index) => (
                <div id={index === comments.length - 1 ? 'lastId' : ''} className={styles.commentWrapper} key={index}>
                  <div className={styles.topSection}>
                    <div className={styles.clinicianContainer}>
                      <div className={styles.avatarContainer}>
                        <img
                          className={styles.avatar}
                          alt={`${comment.commentedBy.name}-avatar`}
                          src={comment.commentedBy.avatar}
                        />
                      </div>
                      <div className={styles.name}>{comment.commentedBy.name}</div>
                    </div>
                    <div className={styles.createdDate}>{moment(comment.commentedAt).format('DD/MM/YYYY')}</div>
                  </div>
                  <div className={styles.comment}>{comment.content}</div>
                </div>
              ))}
            </div>
          )}
          {replyAllowed && (
            <div className={styles.newComment}>
              <img className={styles.avatar} src={clinician.avatar} alt={'myAvatar'} />
              <div className={styles.inputField}>
                <input
                  value={message}
                  className={classNames(styles.input, isSubmitting && styles.submitting)}
                  type={'text'}
                  placeholder={'Write a comment'}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) => onKeyPressSubmit(e)}
                  disabled={isSubmitting}
                />
                <div
                  className={classNames(styles.sendButton, isSubmitting && styles.submitting)}
                  onClick={onSubmitComment}
                >
                  {isSubmitting ? (
                    <SwitchLoading />
                  ) : (
                    <ButtonAlt
                      className={styles.sendIcon}
                      variant={'text'}
                      size={'medium'}
                      icon={'send'}
                      iconVariant={IconVariant.Filled}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CommentWidget;
