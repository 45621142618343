import styles from './CaseNoteDetailDisplay.module.scss';
import { GroupCaseNoteInterface } from '../../interfaces';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { Link } from 'react-router-dom';
import moment from 'moment';
import GroupCaseNoteAttachmentDisplay from '../GroupCaseNoteAttachmentDisplay/GroupCaseNoteAttachmentDisplay';
import { useTranslation } from 'react-i18next';

interface CaseNoteDetailDisplayProps {
  token: string;
  groupId: string;
  baseLink: string;
  caseNoteDetail: GroupCaseNoteInterface;
  displaySafeguardingAlert?: boolean;
}

const CaseNoteDetailDisplay = ({
  token,
  groupId,
  baseLink,
  caseNoteDetail,
  displaySafeguardingAlert = true
}: CaseNoteDetailDisplayProps) => {
  const [t] = useTranslation();
  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.label}>Title</div>
          <div className={styles.value}>{caseNoteDetail.title}</div>
        </div>
        {displaySafeguardingAlert && (
          <div className={styles.safeguardingContainer}>
            <div className={styles.safeguarding}>
              <div className={styles.label}>Safeguarding Alert</div>
              <div className={styles.value}>{caseNoteDetail.safeguardingAlert ? 'Yes' : 'No'}</div>
            </div>
            {caseNoteDetail.safeguardingAlert && (
              <div className={styles.safeguardingReason}>
                <div className={styles.label}>{t('client_case_notes.safe_guarding_label')}</div>
                <div className={styles.value}>{caseNoteDetail.safeguardingReason}</div>
              </div>
            )}
          </div>
        )}

        <div className={styles.fieldContainer}>
          {caseNoteDetail.fields?.map((fieldObj, index: number) => {
            switch (fieldObj.type) {
              case 'multipleChoice':
                return (
                  fieldObj.value && (
                    <div className={styles.fieldBox} key={index}>
                      <div className={styles.label}>{fieldObj.name}</div>
                      {fieldObj.multiple ? (
                        <div className={styles.value}>{fieldObj.value.map((val: any) => val.label).join(', ')}</div>
                      ) : (
                        <div className={styles.value}>{fieldObj.value.label}</div>
                      )}
                    </div>
                  )
                );
              case 'link':
                return (
                  fieldObj.value && (
                    <div className={styles.fieldBox} key={index}>
                      <div className={styles.label}>{fieldObj.name}</div>
                      {fieldObj.linkType === 'assessment' ? (
                        <Link className={styles.value} to={`${baseLink}/${groupId}/assessments/${fieldObj.value._id}`}>
                          {moment(fieldObj.value.date).format(MOMENTJS_DATE_FORMAT)} {fieldObj.value.shortCode}
                        </Link>
                      ) : (
                        <div className={styles.value}>
                          {moment(fieldObj.value.date).format(MOMENTJS_DATE_FORMAT)} {fieldObj.value.shortCode}
                        </div>
                      )}
                    </div>
                  )
                );
              case 'multipleChoiceCount': {
                const getOnlyWithValue = fieldObj.options.filter((obj: any) => obj.count > 0);
                return (
                  getOnlyWithValue.length > 0 && (
                    <div className={styles.fieldBox} key={index}>
                      <div className={styles.label}>{fieldObj.name}</div>
                      <div className={styles.value}>
                        {getOnlyWithValue.map((val: any) => `${val.count} ${val.label}`).join(' | ')}
                      </div>
                    </div>
                  )
                );
              }
              case 'date':
                return (
                  fieldObj.value && (
                    <div className={styles.fieldBox} key={index}>
                      <div className={styles.label}>{fieldObj.name}</div>
                      <div className={styles.value}>
                        {moment(fieldObj.value, MOMENTJS_DATE_FORMAT).format('MMMM DD YYYY')}
                      </div>
                    </div>
                  )
                );
              case 'text':
              case 'number':
              case 'time':
              default:
                return (
                  fieldObj.value && (
                    <div className={styles.fieldBox} key={index}>
                      <div className={styles.label}>{fieldObj.name}</div>
                      <div className={styles.value}>{fieldObj.value}</div>
                    </div>
                  )
                );
            }
          })}
        </div>
      </div>
      <div className={`ql-snow ${styles.body}`}>
        <div className={`ql-editor ${styles.text}`} dangerouslySetInnerHTML={{ __html: caseNoteDetail.body }} />
      </div>
      <GroupCaseNoteAttachmentDisplay
        token={token}
        groupId={groupId}
        caseNoteAttachmentData={caseNoteDetail.attachments}
      />
    </>
  );
};

export default CaseNoteDetailDisplay;
