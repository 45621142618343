import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './ClientListEmpty.module.scss';
import clientRecordEmpty from 'assets/images/clientRecordEmpty2.svg';
import { useAppDispatch } from 'redux/hooks';
import { resetFilters, resetPaging, resetSearch } from 'redux/clients/clientListSlice';

const ClientListEmpty = () => {
  const dispatch = useAppDispatch();

  const onClearFilter = () => {
    dispatch(resetFilters());
    dispatch(resetSearch());
    dispatch(resetPaging());
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        <img src={clientRecordEmpty} alt={'No Record found'} />
      </div>
      <div className={styles.rightContent}>
        <p>No profiles match your filters</p>
        <div className={styles.suggestion}>
          Please update your filters or
          <ButtonAlt className={styles.clearAll} variant={'text'} onClick={onClearFilter}>
            clear all
          </ButtonAlt>
        </div>
      </div>
    </div>
  );
};
export default ClientListEmpty;
