import { useState } from 'react';

import styles from './BroadcastGroupList.module.scss';
import { MinifiedGroup } from 'pages/Groups/Interfaces/Groups';
import CheckBox from 'components/CheckBox/CheckBox';
import { MinifiedGroupWithSelected } from '../../BroadcastContent';

const sortGroup = (list: MinifiedGroup[], sortAsc: boolean): MinifiedGroupWithSelected[] => {
  return [...list].sort((a, b) => {
    return sortAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
  });
};

interface BroadcastGroupListProps {
  groups: MinifiedGroup[];
  onSelectedGroup: (selectedList: MinifiedGroupWithSelected[]) => void;
}

const BroadcastGroupList = ({ groups, onSelectedGroup }: BroadcastGroupListProps) => {
  const [groupList, setGroupList] = useState(sortGroup(groups, true));
  const [selectedGroupList, setSelectedGroupList] = useState([] as MinifiedGroupWithSelected[]);

  const handleAddGroup = (groupId: string) => {
    let newGroupList = [];
    for (let data of groupList) {
      newGroupList.push({
        ...data,
        isSelected: data.isSelected ? data.isSelected : data._id === groupId
      });
    }
    setGroupList(newGroupList);

    const selectedList = newGroupList.filter((obj) => obj.isSelected);
    setSelectedGroupList(selectedList);
    onSelectedGroup(selectedList);
  };

  const handleRemoveGroup = (groupId: string) => {
    let newGroupList = [];
    for (let data of groupList) {
      newGroupList.push({
        ...data,
        isSelected: data._id === groupId ? false : data.isSelected
      });
    }
    setGroupList(newGroupList);

    const selectedList = newGroupList.filter((obj) => obj.isSelected);
    setSelectedGroupList(selectedList);
    onSelectedGroup(selectedList);
  };

  const handleSelectAll = () => {
    const newGroupList = groupList.map((group) => ({
      ...group,
      isSelected: true
    }));
    setGroupList(newGroupList);
    setSelectedGroupList(newGroupList);
    onSelectedGroup(newGroupList);
  };

  return (
    <div className={styles.container}>
      <div className={styles.groupListContainer}>
        <div className={styles.cardTitle}>
          Select which group to add to broadcast
          <span className={styles.addBtn} onClick={handleSelectAll}>
            Select All
          </span>
        </div>
        <div className={styles.subTitle}>
          <label>{selectedGroupList.length || 0}</label> Group(s) Selected
        </div>
        <div className={styles.groupListWrapper}>
          {groupList.map((group) => {
            return (
              <div className={styles.groupItem}>
                <CheckBox
                  id={`${group._id}`}
                  label={group.name}
                  value={!!group.isSelected}
                  labelClassName={styles.groupLabel}
                  onChange={() => (!group.isSelected ? handleAddGroup(group._id) : handleRemoveGroup(group._id))}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BroadcastGroupList;
