export const SubmittedClaimListColumn = [
  {
    id: 'client',
    sortAble: false
  },
  {
    id: 'practitioner',
    sortAble: false
  },
  {
    id: 'groups',
    sortAble: false
  },
  {
    id: 'claimId',
    sortAble: false
  },
  {
    id: 'date',
    sortAble: true
  },
  {
    id: 'claimType',
    sortAble: false,
    label: 'Payer'
  },
  {
    id: 'claimCode',
    sortAble: false
  },
  {
    id: 'status',
    sortAble: true
  },
  {
    id: 'reference',
    sortAble: false
  },
  {
    id: 'claimAmount',
    sortAble: false
  }
];
