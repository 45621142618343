import { useRef, useState } from 'react';
import styles from './CarePathwayCardMenu.module.scss';
import {
  useUpdateCarePathwayStatusMutation,
  useDeleteCarePathwayMutation
} from 'redux/endpoints/clinicianProfileServices/carePathway';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { notification } from 'antd';
import CarePathwayModal from '../../../CarePathwayModal/CarePathwayModal';
import NestedSelect, { DropdownType } from 'components/Select/NestedSelect/NestedSelect';
import { CarePathwayCardAction, CarePathwayStatus } from 'interfaces/CarePathway/CarePathway';

interface CarePathwayCardMenuProps {
  carePathwayId: string;
  status: CarePathwayStatus;
  isShowConfirmDelete: boolean;
  setIsShowConfirmDelete: (v: boolean) => void;
}

const CarePathwayCardMenu = ({
  carePathwayId,
  status,
  isShowConfirmDelete,
  setIsShowConfirmDelete
}: CarePathwayCardMenuProps) => {
  const menuNode = useRef<HTMLDivElement>(null);
  const { accountId } = useGetAccountId();
  const [editPathwayModal, setEditPathwayModal] = useState(false);
  const [updateCarePathwayStatus] = useUpdateCarePathwayStatusMutation();
  const [deleteCarePathway] = useDeleteCarePathwayMutation();

  const handleClose = async () => {
    const response = await updateCarePathwayStatus({ accountId, carePathwayId, status: CarePathwayStatus.Closed });
    if (Object.hasOwn(response, 'data')) {
      notification.success({
        message: 'Care pathway has been closed.',
        duration: 2
      });
    } else {
      notification.error({
        message: 'Something went wrong while trying to update this care pathway',
        duration: 2
      });
    }
  };

  const handleDelete = async () => {
    const response = await deleteCarePathway({ accountId, carePathwayId });
    if (Object.hasOwn(response, 'data')) {
      notification.success({
        message: 'Care pathway has been deleted.',
        duration: 2
      });
    } else {
      notification.error({
        message: 'Something went wrong while trying to delete this care pathway',
        duration: 2
      });
    }
  };

  const actionOptions = [
    ...(status === CarePathwayStatus.Draft
      ? [{ value: CarePathwayCardAction.Edit, label: 'Edit Plan', icon: 'edit' }]
      : []),
    {
      value: CarePathwayCardAction.ChangeStatus,
      label: 'Change Status',
      icon: 'task_alt',
      subMenu: [
        ...(status !== CarePathwayStatus.Closed ? [{ value: CarePathwayStatus.Closed, label: 'Close' }] : []),
        ...(!isShowConfirmDelete
          ? [{ value: CarePathwayCardAction.Delete, label: 'Delete', isOpenAfterSelected: true }]
          : [{ value: 'confirmDelete', label: 'Confirm Delete', className: styles.warningOption }])
      ]
    }
  ];

  const handleClickDropdownMenu = async ({
    firstSelectedOption,
    secondSelectedOption
  }: {
    firstSelectedOption?: string;
    secondSelectedOption?: string;
  }) => {
    switch (firstSelectedOption) {
      case CarePathwayCardAction.Edit: {
        setEditPathwayModal(true);
        break;
      }
      case CarePathwayCardAction.ChangeStatus: {
        if (!secondSelectedOption) return;
        if (secondSelectedOption === CarePathwayStatus.Closed) {
          await handleClose();
        } else if (secondSelectedOption === CarePathwayCardAction.Delete) {
          setIsShowConfirmDelete(true);
        } else if (secondSelectedOption === 'confirmDelete') {
          await handleDelete();
          setIsShowConfirmDelete(false);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div ref={menuNode} className={styles.container}>
      <NestedSelect
        type={DropdownType.Menu}
        options={actionOptions}
        value={{}}
        onSelected={handleClickDropdownMenu}
        onClickOutSide={() => setIsShowConfirmDelete(false)}
        positionSubMenu={'left'}
        isSmallSubMenu
        subMenuClassName={styles.subMenu}
      />
      <CarePathwayModal
        visible={editPathwayModal}
        onCloseModal={() => setEditPathwayModal(false)}
        carePathWayId={carePathwayId}
      />
    </div>
  );
};

export default CarePathwayCardMenu;
