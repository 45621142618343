import { useEffect, useState } from 'react';
import styles from './ReportDraftContent.module.scss';
import ReportContentHeader from '../../../ReportContentComponents/ReportContentHeader/ReportContentHeader';
import DndPreviewSection from './components/DndPreviewSection/DndPreviewSection';
import SignatureSection from '../../../ReportContentComponents/SignatureSection/SignatureSection';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { Report } from 'interfaces/Reports/report';

interface ReportDraftContentProps {
  data: Report;
  clinicianDetails: any;
}

const ReportDraftContent = ({ data, clinicianDetails }: ReportDraftContentProps) => {
  const [isShowContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.container}>
      {isShowContent ? (
        <div className={styles.contentWrapper}>
          <ReportContentHeader
            reportName={data.reportName}
            clientRecord={data.clientRecord}
            clinicianDetails={clinicianDetails}
            clinicianContactData={data.contactDetails}
            isPreview
            issueDate={data.issueDate}
            type="Report"
          />
          <DndPreviewSection dndDimension={data.template.dimensions} reportItems={data.items} />
          <SignatureSection
            signVal={data.clinicianSignature}
            extraDetailsVal={data.clinicianSignatureExtraDetails}
            clinician={clinicianDetails.clinician}
            previewMode
          />
        </div>
      ) : (
        <div className={styles.loadingWrapper}>
          <LoadingDot />
        </div>
      )}
    </div>
  );
};

export default ReportDraftContent;
