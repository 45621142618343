import { useEffect, useState } from 'react';
import styles from './SurveyFormList.module.scss';
import { GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';
import { SurveyFormResponseList } from 'pages/Groups/Interfaces/GroupsSurvey';
import { useGetAccessToken } from 'utils/hooks/token';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SurveyFormListContent from './components/SurveyFormListContent/SurveyFormListContent';
import { useFetchAdHocSurveyClientList } from './hooks/getSurveyFormList';
import SurveyFormHeader from './components/SurveyFormListContent/components/SurveyFormHeader/SurveyFormHeader';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import Select from 'components/v2/Select/Select';
import { useFetchGroupSurveyResponseList } from 'pages/Groups/GroupDetails/hooks/getSurveyMenuList';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';

interface SurveyFormListProps {
  surveyId?: string;
  surveyFormMenuList: SurveyFormResponseList[];
  surveyResponseListLoading: boolean;
}

interface SelectedSurveyProps {
  value: string;
  label: string;
}

const SurveyFormList = ({ surveyId, surveyFormMenuList, surveyResponseListLoading }: SurveyFormListProps) => {
  const { token } = useGetAccessToken();
  const { CARECOORDINATION } = useRoutesGenerator();
  const { isEdgePlanUser } = useGetAccountPackage();
  const navigate = useNavigate();
  const location = useLocation();
  const { groupId = '' } = useParams<{ groupId: string }>();
  const { groupSurveyClientList, isSurveyFormListLoading } = useFetchAdHocSurveyClientList(token, groupId, surveyId!);
  const { surveyResponseList, isSurveyResponseListLoading } = useFetchGroupSurveyResponseList(token, groupId);
  const [selectedSurveyId, setSelectedSurveyId] = useState<SelectedSurveyProps>();
  const noSurveys = !isSurveyResponseListLoading && surveyResponseList?.length === 0;
  const noSurveyId = !isSurveyResponseListLoading && surveyResponseList.length !== 0 ? surveyResponseList[0]._id : '';
  const getSurveyId: string = isEdgePlanUser
    ? location.pathname.split('/')[5] ?? noSurveyId
    : location.pathname.split('/')[4] ?? noSurveyId;

  useEffect(() => {
    if (!surveyId && surveyFormMenuList && surveyFormMenuList.length > 0) {
      const isSentSurveys = surveyFormMenuList.filter((obj) => obj.isSent);
      if (isSentSurveys.length > 0) {
        navigate(`${CARECOORDINATION.GROUPS}/${groupId}/${GroupDetailsTab.Survey}/${isSentSurveys[0]._id}`);
      }
    }
  }, [surveyFormMenuList, surveyId, CARECOORDINATION, groupId, navigate]);

  useEffect(() => {
    const survey = surveyResponseList.find((i) => i._id === getSurveyId);
    if (survey) {
      setSelectedSurveyId({ value: survey._id, label: survey.name! });
    }
  }, [surveyResponseList, getSurveyId, setSelectedSurveyId]);

  return (
    <div className={styles.columnContainer}>
      {!noSurveys && (
        <div className={styles.headerContainer}>
          <Select
            filledArrow
            label="SURVEY FORMS"
            options={surveyResponseList?.map((i) => ({ value: i._id, label: i.name! }))}
            onChange={(val: any) => {
              if (val?.value !== selectedSurveyId?.value) {
                setSelectedSurveyId(val);
                navigate(`${CARECOORDINATION.GROUPS}/${groupId}/${GroupDetailsTab.Survey}/${val?.value}`);
              }
            }}
            isSearchable={false}
            //@ts-ignore
            value={
              surveyResponseList
                ? selectedSurveyId
                  ? {
                      value: surveyResponseList.find((i) => i._id === selectedSurveyId.value)!._id,
                      label: selectedSurveyId.label
                    }
                  : surveyResponseList[0]
                  ? { value: surveyResponseList[0]._id, label: surveyResponseList[0].name }
                  : undefined
                : undefined
            }
            isLoading={isSurveyResponseListLoading}
            containerClass={styles.selectSurveyId}
            controlStyle={{ boxShadow: 'none' }}
          />
        </div>
      )}
      <div className={styles.container}>
        {surveyResponseListLoading || (groupSurveyClientList.length === 0 && isSurveyFormListLoading) ? (
          <div className={styles.loadingContainer}>
            <LoadingDot />
          </div>
        ) : groupSurveyClientList.length > 0 ? (
          <>
            <SurveyFormHeader groupId={groupId} surveyFormId={surveyId} surveyFormMenuList={surveyFormMenuList} />
            <SurveyFormListContent
              groupSurveyClientList={groupSurveyClientList}
              isSurveyFormListLoading={isSurveyFormListLoading}
            />
          </>
        ) : surveyId ? (
          'No clients were in the group when this survey was sent, or all clients who have been sent this survey have been removed from the group.'
        ) : (
          'No survey selected.'
        )}
      </div>
    </div>
  );
};

export default SurveyFormList;
