import moment from 'moment';
import { AppointmentSlots, AppointmentStatus } from 'interfaces/Schedule/Appointment';
import { cancelledStatuses } from '../interface/constants';
import { sortBy } from 'lodash';

const isAppointmentHappened = (startDateTime: string | undefined) => moment().isAfter(moment(startDateTime));

export const isAppointmentTagsCancelled = (statuses?: string[]) =>
  statuses?.some((status) => cancelledStatuses.some((cancelStatus) => cancelStatus === status));

export const isAppointmentStatusCancelled = (status?: string) => status === AppointmentStatus.Cancelled;

export const massageClientAppointments = (
  patientAppointments: AppointmentSlots[]
): {
  upcomingAppointmentsTotal: number;
  upcomingAppointments: AppointmentSlots[];
  pastAppointmentsTotal: number;
  completedAppointments: AppointmentSlots[];
} => {
  const upcomingAppointments = sortBy(
    patientAppointments.filter(
      (patientAppt) =>
        !isAppointmentHappened(patientAppt.startDateTime) && !isAppointmentStatusCancelled(patientAppt.status)
    ),
    (it) => `${it.date} ${it.startTime}`
  );

  const completedAppointments = patientAppointments
    .filter(
      (patientAppt) =>
        isAppointmentHappened(patientAppt.startDateTime) || isAppointmentStatusCancelled(patientAppt.status)
    )
    .sort((apptA, apptB) => moment(apptB.date + ' ' + apptB.startTime).diff(moment(apptA.date + ' ' + apptA.startTime)))
    .map((completedAppt, index, array) => {
      return {
        ...completedAppt,
        appointmentNo: array.length - index,
        month: moment(completedAppt.date).format('MMMM YYYY')
      };
    });

  const pastAppointmentsTotal = completedAppointments.filter(
    (appt) => !isAppointmentStatusCancelled(appt.status)
  ).length;

  return {
    upcomingAppointmentsTotal: upcomingAppointments.length,
    upcomingAppointments,
    pastAppointmentsTotal,
    completedAppointments
  };
};
