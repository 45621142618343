import moment from 'moment';
import { InvoiceFilters } from 'redux/invoices/invoiceSlice';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

export const RECENT_INVOICE_RANGE_IN_DAYS = 14;

export const getDefaultFilters = (filters?: InvoiceFilters) => ({
  recent: {
    createdAt: {
      from: moment().subtract(RECENT_INVOICE_RANGE_IN_DAYS, 'days').startOf('day').toISOString(),
      to: moment().toISOString()
    }
  },
  all: {
    createdAt: {
      from: null,
      to: null
    }
  },
  clientProfile: {
    participantType: ParticipantType.OneToOne,
    clients: filters?.clients || []
  }
});

export enum InvoiceTab {
  All = 'all',
  Recent = 'recent',
  ClientProfile = 'clientProfile'
}

export const CLAIM_TYPE_OPTIONS: FilterCheckListItem[] = [
  { name: 'DVA', _id: PendingClaimsFunder.DVA },
  { name: 'Medicare - BulkBill', _id: PendingClaimsFunder.BULK_BILL },
  { name: 'Out Of Pocket', _id: PendingClaimsFunder.OUT_OF_POCKET },
  { name: 'Out Of Pocket - Rebate', _id: PendingClaimsFunder.REBATE }
];
