import { notification } from 'antd';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { ClientRecordType, ProfileRole } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AppointmentClientOrGroupType,
  AppointmentViewType,
  selectAppointmentData,
  selectedAppointmentView,
  selectIgnoreRoomConflict,
  selectPackageAppointmentDetails,
  setIsPackageAppointment,
  setStartValidation,
  startInlineCalendarValidation
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppSelector } from 'redux/hooks';
import { scheduleServicesApiSlice, SSTagTypes } from 'redux/services/scheduleServicesApiSlice';
import { convertTimeToUtcDateObject } from 'utils/helpers/timezone';
import { useGetAccessToken } from 'utils/hooks/token';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { postAppointments } from 'utils/http/appointment';
import styles from './InlineBookingFooter.module.scss';
import { DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { postGroupAppointments } from 'utils/http/ScheduleService/Groups/groups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup, faIdBadge, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import {
  activityAppointmentValidation,
  oneOnOneAppointmentValidation
} from '../../Validation/InlineBookingValidationHelper';
import { getDeliveryTypeLabel } from 'utils/appointment';

interface InlineBookingFooterProps {
  onSubmitSuccess: () => void;
}

const InlineBookingFooter = ({ onSubmitSuccess }: InlineBookingFooterProps) => {
  const { timeZoneByView } = useTimeZone();
  const { token } = useGetAccessToken();
  const dispatch = useDispatch();

  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');

  const ignoreRoomConflict = useAppSelector(selectIgnoreRoomConflict);
  const appointmentData = useAppSelector(selectAppointmentData);
  const selectedAppointmentTypeView = useAppSelector(selectedAppointmentView);
  const {
    rate,
    recurring,
    selectedAppointmentType,
    selectedClient,
    selectedGroup,
    selectedPractitioner,
    selectedDate,
    selectedTime,
    selectedDeliveryMode,
    selectedRoom,
    selectedClientType,
    isActivity,
    selectedStatus,
    instructionNote
  } = appointmentData;
  const packageAppointmentDetails = useAppSelector(selectPackageAppointmentDetails);
  const inlineCalendarValidation = useAppSelector(startInlineCalendarValidation);

  const checkFormError = useMemo(() => {
    if (isActivity) {
      return activityAppointmentValidation({
        selectPractitioner: Boolean(selectedPractitioner?._id || selectedPractitioner?.name), // check this 2 because of practice only ''
        selectTargetedClient:
          selectedClientType === AppointmentClientOrGroupType.Client
            ? Boolean(selectedClient?._id)
            : Boolean(selectedGroup?._id),
        selectAppointmentType: Boolean(selectedAppointmentType?.packageAppointmentId || selectedAppointmentType?._id),
        selectTimeSlot: Boolean(selectedTime.startTime || selectedTime.endTime)
      });
    } else {
      return oneOnOneAppointmentValidation({
        selectPractitioner: Boolean(selectedPractitioner?._id || selectedPractitioner?.name),
        selectClient: Boolean(selectedClient?._id),
        selectAppointmentType: Boolean(selectedAppointmentType?.packageAppointmentId || selectedAppointmentType?._id),
        selectDeliveryMode: Boolean(selectedDeliveryMode),
        selectTimeSlot: Boolean(selectedTime.startTime || selectedTime.endTime)
      });
    }
  }, [
    selectedPractitioner,
    selectedClient,
    selectedAppointmentType,
    isActivity,
    selectedDeliveryMode,
    selectedClientType,
    selectedGroup,
    selectedTime
  ]);

  const onSubmit = async () => {
    dispatch(setStartValidation(true));
    if (!checkFormError.isErrorExist) {
      const payload = {
        customised: selectedAppointmentTypeView === AppointmentViewType.Customise || isActivity,
        eventOwner: selectedPractitioner?._id,
        clientRecordId: selectedClient?._id,
        deliveryOption: selectedDeliveryMode,
        recurringAppointment: {
          amount: recurring.count,
          frequency: recurring.frequency
        },
        slot: {
          /**
           * TODO: Why should it need a appointment type data while only sessionTypeId is good enough?
           * service should be refactored
           */
          sessionTypeId: selectedAppointmentType?._id,
          gap: selectedAppointmentType?.gap,
          name: selectedAppointmentType?.name,
          date: selectedDate,
          startTime: selectedTime.startTime,
          endTime: selectedTime.endTime,
          startDateTime: convertTimeToUtcDateObject({
            date: selectedDate,
            time: selectedTime.startTime,
            timeZone: timeZoneByView
          }),
          endDateTime: convertTimeToUtcDateObject({
            date: selectedDate,
            time: selectedTime.endTime,
            timeZone: timeZoneByView
          }),
          ...(selectedDeliveryMode === DeliveryType.FaceToFace && {
            faceToFaceLocation: instructionNote
          }),
          ...(selectedDeliveryMode === DeliveryType.VideoCall && {
            videoCallInstructions: instructionNote
          }),
          ...(selectedDeliveryMode === DeliveryType.PhoneCall && {
            phoneCallInstructions: instructionNote
          }),
          ...(selectedDeliveryMode === DeliveryType.PhoneCallDialClient && {
            phoneCallDialClientInstructions: instructionNote
          }),
          ...(selectedDeliveryMode === DeliveryType.Other && {
            otherInstructions: {
              title: selectedAppointmentType?.otherInstructions?.title,
              instructions: instructionNote
            }
          })
        },
        ...(selectedRoom?._id && {
          room: {
            roomId: selectedRoom._id,
            roomName: selectedRoom.name
            /**
             * TODO: Why does it need only roomId but the payload required room object?
             * service should be refactored => Replace room object by roomId
             */
          }
        }),
        timeZone: timeZoneByView,
        allowRoomConflict: true,
        ...(isActivity && {
          type: selectedStatus,
          rate
        }),
        ...(packageAppointmentDetails?.isPackageOption && {
          packageId: packageAppointmentDetails.packageId,
          packageAssigneeId: packageAppointmentDetails.packageAssigneeId
        })
      };

      setSubmitButtonStatus('active');

      try {
        const response = await (isActivity && selectedClientType === AppointmentClientOrGroupType.Group
          ? postGroupAppointments(selectedGroup?._id || '', payload, token) // this need to change to redux, then can remove the SSTagTypes.AppointmentList on bottom
          : postAppointments(payload, token));
        if (response.statusCode === 201 || response.statusCode === 204) {
          setSubmitButtonStatus('finished');
          notification.success({
            message: `${isActivity ? 'Activity' : 'One to one appointment'} created.`,
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          // Clear Next Appointment cached data after add new appointment success
          dispatch(
            scheduleServicesApiSlice.util.invalidateTags([
              { type: SSTagTypes.NextAppointmentByCurrent, id: selectedClient?._id },
              SSTagTypes.ListOfReviewAppointments,
              SSTagTypes.PackageAssignmentList,
              SSTagTypes.AppointmentList
            ])
          );
          setTimeout(() => {
            setSubmitButtonStatus('');
            dispatch(
              setIsPackageAppointment({
                isPackageOption: false,
                packageId: '',
                packageAssigneeId: ''
              })
            );
          }, 1000);
          onSubmitSuccess();
        } else if (response.statusCode === 409) {
          notification.error({
            message:
              (await response.json()).message ??
              `Something went wrong when creating this ${isActivity ? 'activity' : 'appointment'}.`
          });
        }
      } catch (ex) {
        console.log(ex);
        notification.error({
          message: `Something went wrong when creating this ${isActivity ? 'activity' : 'appointment'}.`
        });
      } finally {
        setSubmitButtonStatus('');
        dispatch(setStartValidation(false));
      }
    }
  };

  const clientProfiles = useMemo(
    () =>
      selectedClient
        ? selectedClient.recordType === ClientRecordType.Child
          ? selectedClient.clientProfiles.filter((profileType) => profileType.role === ProfileRole.Child)
          : selectedClient.clientProfiles
        : [],
    [selectedClient]
  );

  const oneOnOneAppointmentIconValidation =
    selectedDate && selectedDeliveryMode && selectedTime.startTime && selectedTime.endTime;

  const activityAppointmentIconValidation =
    selectedDate && selectedAppointmentType && selectedTime.startTime && selectedTime.endTime;

  return (
    <div className={styles.container}>
      <div className={styles.bookingSummary}>
        <div className={styles.practitioner}>
          {selectedPractitioner?.name ? (
            <div className={styles.practitionerInfo}>
              {selectedPractitioner?.avatar && (
                <img
                  className={styles.practitionerAvatar}
                  src={selectedPractitioner?.avatar}
                  alt={'clinician profile'}
                />
              )}
              <div className={styles.practitionerName}>{selectedPractitioner?.name}</div>
            </div>
          ) : (
            <div className={styles.noSelectWrapper}>
              <FontAwesomeIcon
                icon={faIdBadge}
                className={classnames(
                  styles.noSelectIcon,
                  inlineCalendarValidation && !selectedPractitioner?._id && styles.error
                )}
              />
              <div
                className={classnames(
                  styles.noSelectName,
                  inlineCalendarValidation && !selectedPractitioner?._id && styles.error
                )}
              >
                Practitioner
              </div>
            </div>
          )}
        </div>
        <div className={classnames(styles.splitterPlus, selectedClient && styles.splitterSuccess)}>+</div>
        <div className={styles.client}>
          {selectedClientType === AppointmentClientOrGroupType.Client ? (
            selectedClient ? (
              <ClientAvatar
                clientData={clientProfiles}
                avatarSize={32}
                displayFirstNameOnly
                displayLimit={selectedClient.recordType === ClientRecordType.Couple ? 2 : 1}
                horizontal
                avatarClassName={styles.avatarClassName}
                nameClassName={styles.nameClassName}
                initialsClassName={styles.initialsClassName}
                fullAvatarBg
              />
            ) : (
              <div className={styles.noSelectWrapper}>
                <FontAwesomeIcon
                  icon={faUserGroup}
                  className={classnames(
                    styles.noSelectIcon,
                    inlineCalendarValidation && !selectedClient && styles.error
                  )}
                />
                <div
                  className={classnames(
                    styles.noSelectName,
                    inlineCalendarValidation && !selectedClient && styles.error
                  )}
                >
                  Client
                </div>
              </div>
            )
          ) : selectedGroup ? (
            selectedGroup?.name
          ) : (
            <div className={styles.noSelectWrapper}>
              <FontAwesomeIcon
                icon={faUserGroup}
                className={classnames(styles.noSelectIcon, inlineCalendarValidation && !selectedGroup && styles.error)}
              />
              <div
                className={classnames(styles.noSelectName, inlineCalendarValidation && !selectedGroup && styles.error)}
              >
                Group
              </div>
            </div>
          )}
        </div>
        <div className={classnames(styles.splitter, selectedDate && selectedDeliveryMode && styles.splitterSuccess)}>
          ON
        </div>
        <div className={styles.appointmentDateTimeWrapper}>
          <FontAwesomeIcon
            icon={faCalendarDays}
            className={classnames(
              styles.appointmentIcon,
              !isActivity
                ? oneOnOneAppointmentIconValidation && styles.appointmentIconSuccess
                : activityAppointmentIconValidation && styles.appointmentIconSuccess,
              !isActivity
                ? inlineCalendarValidation && !oneOnOneAppointmentIconValidation && styles.error
                : inlineCalendarValidation && !activityAppointmentIconValidation && styles.error
            )}
          />
          <div className={styles.appointmentDateTime}>
            {selectedDate ? (
              <div>{moment(selectedDate, 'YYYY-MM-DD').format('dddd, DD/MM/YYYY')}</div>
            ) : (
              <div
                className={classnames(styles.noSelectName, inlineCalendarValidation && !selectedDate && styles.error)}
              >
                Date
              </div>
            )}
            {selectedTime.startTime && selectedTime.endTime ? (
              <div>
                {moment(selectedTime.startTime, 'HH:mm').format('hh:mm A')} -{' '}
                {moment(selectedTime.endTime, 'HH:mm').format('hh:mm A')}
              </div>
            ) : (
              <div
                className={classnames(
                  styles.noSelectName,
                  inlineCalendarValidation && !(selectedTime.startTime && selectedTime.endTime) && styles.error
                )}
              >
                Time
              </div>
            )}
            <div>
              {selectedDeliveryMode ? (
                <div>{getDeliveryTypeLabel(selectedDeliveryMode, selectedAppointmentType?.otherInstructions)}</div>
              ) : (
                !isActivity && (
                  <div
                    className={classnames(
                      styles.noSelectName,
                      inlineCalendarValidation && !selectedDeliveryMode && styles.error
                    )}
                  >
                    Mode
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.submitButtonWrapper}>
        <ButtonAlt
          loadingWord="Processing"
          disabled={selectedRoom?.isConflicted && !ignoreRoomConflict}
          status={submitButtonStatus}
          className={
            inlineCalendarValidation && checkFormError.isErrorExist ? styles.submitButtonError : styles.submitButton
          }
          onClick={onSubmit}
        >
          Confirm
        </ButtonAlt>
      </div>
    </div>
  );
};

export default InlineBookingFooter;
