import { Button, Dropdown } from 'antd';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './FilterRadioDropdown.module.scss';
import Radio from '../../Radio/Radio';
import OptionLabel from '../../OptionLabel/OptionLabel';
import classnames from 'classnames';

export interface FilterRadioItem {
  name: string;
  _id: string;
}

interface FilterRadioDropdownProps {
  id: string;
  children: ReactNode;
  menuItems: FilterRadioItem[];
  selectedItem: FilterRadioItem | undefined;
  onChangeItem: (v: FilterRadioItem | undefined) => void;
}

const FilterRadioDropdown = ({ id, children, menuItems, onChangeItem, selectedItem }: FilterRadioDropdownProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onSelectItem = (selectedId: string) => {
    onChangeItem(menuItems.find((item) => item._id === selectedId) || undefined);
    setIsDropdownOpen(false);
  };

  const dropdownRender = () => (
    <div className={classnames(styles.listWrapper, isEdgeAdminView && 't23-admin-theme')}>
      <Radio
        vertical
        nowrap
        useT23Styles
        name={id}
        className={styles.radioBtnWrapper}
        radioClassName={styles.radioBtn}
        labelClassName={styles.radioLabel}
        selectedClassName={styles.selectedClassName}
        options={menuItems.map((radioItem) => ({
          value: radioItem._id,
          label: (
            <OptionLabel
              titleClassName={classnames(selectedItem?._id === radioItem._id && styles.selectedLabel, styles.label)}
              isSelected={selectedItem?._id === radioItem._id}
              title={radioItem.name}
            />
          )
        }))}
        value={selectedItem?._id}
        onChange={(event) => {
          onSelectItem(event.target.value);
        }}
      />
    </div>
  );

  return (
    <Dropdown
      overlayClassName={styles.container}
      dropdownRender={dropdownRender}
      onOpenChange={(open) => setIsDropdownOpen(open)}
      open={isDropdownOpen}
      trigger={['click']}
    >
      <Button
        shape="round"
        className={classNames(
          styles.button,
          isEdgeAdminView
            ? classNames(
                styles.adminButton,
                isDropdownOpen && styles.adminButtonOpen,
                selectedItem && styles.adminSelected
              )
            : classNames(
                styles.nonAdminButton,
                isDropdownOpen && styles.nonAdminButtonOpen,
                selectedItem && styles.nonAdminSelected
              )
        )}
      >
        {selectedItem?.name || children}
        <div className={styles.arrowWrapper}>
          {isDropdownOpen ? (
            <span className={classNames('material-icons-outlined', styles.expandIcon)}>expand_less</span>
          ) : !selectedItem ? (
            <span className={classNames('material-icons-outlined', styles.expandIcon)}>expand_more</span>
          ) : (
            <div
              className={styles.clearButton}
              onClick={(event) => {
                event.stopPropagation();
                onChangeItem(undefined);
              }}
            >
              <i className={`material-icons-outlined ${styles.clearIcon}`}>close</i>
            </div>
          )}
        </div>
      </Button>
    </Dropdown>
  );
};

export default FilterRadioDropdown;
