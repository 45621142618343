import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { DeliveryType, OtherInstructions } from './AppointmentType';
import { GroupDetails } from '../../pages/Groups/Interfaces/GroupsSession';
import { GroupsFromAPI } from '../../pages/Groups/Interfaces/Groups';
import { PatientActivity } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsActivity/components/ActivityFeed/ActivityFeed';
import { PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';
import { PractitionersListMinified } from '../../redux/endpoints/clinicianProfileServices/practitioner';

export enum TelehealthRoomType {
  Video = 'video',
  Voice = 'voice'
}

export enum AttendeeParticipationStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Pending = 'pending',
  Unknown = 'unknown'
}

export enum ClientRescheduleRequestStatus {
  Pending = 'pending',
  Rejected = 'rejected'
}

export interface Attendee {
  clinicianId?: string;
  clientRecordId?: string;
  clientProfileId?: string;
  email?: string;
  participationStatus?: AttendeeParticipationStatus;
  name?: string;
  initials?: string;
  initialsBackgroundColor?: string;
  avatar?: string;
}

export interface GetAppointmentsByClinicianIdOrRoomIdQueryParams {
  from: string;
  to: string;
  roomIds?: string;
  clinicianIds?: string;
}

// Certain statuses marked triggers alert/invoice in sched-service
// > sched-service/src/handlers/appointment/putAppointmentStatus.tsx
export enum AppointmentStatusOption {
  Attended = 'Attended',
  DidNotAttend = 'Did Not Attend',
  ClientDidNotAttend = 'Client Did Not Attend',
  ClinicianDidNotAttend = 'Clinician Did Not Attend',
  RescheduledOnce = 'Rescheduled Once',
  RescheduledTwice = 'Rescheduled Twice',
  InvoiceSent = 'Invoice Sent',
  InvoicePaid = 'Invoice Paid',
  DepositPaid = 'Deposit Paid',
  DepositRequired = 'Deposit Required',
  CancelledShortNotice = 'Cancelled Short Notice',
  CancelledWithNotice = 'Cancelled With Notice',
  CancelledByClient = 'Cancelled By Client',
  CancelledByClinician = 'Cancelled By Clinician',

  // custom for recharge, select, ease
  Charge = 'Charge'
}

export interface MinifiedInvoice {
  _id: string;
  amount: number;
  invoiceId: string;
  status: string;
}

export enum AppointmentStatusType {
  Block = 'block',
  Booking = 'booking',
  Busy = 'busy',
  Free = 'free',
  Request = 'request',
  Reserved = 'reserved',
  OtherWorkspace = 'otherWorkspace'
}

export enum AppointmentClaimType {
  Rebate = 'rebate',
  BulkBill = 'bulkBill',
  VTPHNA = 'VTPHNA',
  WISE = 'WISE',
  OutOfPocket = 'outOfPocket'
  // not covering DVA for now
}

export enum ServiceDelivered {
  Attended = 'attended',
  ClientDidNotAttend = 'clientDidNotAttend',
  ClinicianDidNotAttend = 'clinicianDidNotAttend',
  CancelShortNotice = 'cancelShortNotice',
  CancelWithNotice = 'cancelWithNotice',
  ClinicianCancelWithNotice = 'clinicianCancelWithNotice',
  ClinicianCancelShortNotice = 'clinicianCancelShortNotice'
}

export const ServiceDeliveredNameMapping = {
  attended: 'Attended',
  clientDidNotAttend: 'Client DNA',
  clinicianDidNotAttend: 'Clinician DNA',
  cancelShortNotice: 'CNX Short Notice',
  cancelWithNotice: 'CNX With Notice',
  clinicianCancelWithNotice: 'CCNX With Notice',
  clinicianCancelShortNotice: 'CCNX Short Notice'
};

export interface RequestedChanges {
  appointmentTypeId?: string;
  deliveryType?: DeliveryType;
  providerId?: string;
  funder?: PendingClaimsFunder;
  mbsCode?: string;
  clinicianId?: string;
  createdAt?: Date;
  waiveCancellationFee?: boolean;
  reviewNote: string;
  cancellationNote?: string;
  cancellationWithRefund?: boolean;
  invoiceAmount?: number;
}

export interface AppointmentSlots {
  _id: string;
  accountId: string;
  clinicianId?: string;
  sessionTypeId?: string;
  sessionTypeName?: string;
  date?: string;
  endDate?: string;
  startTime: string;
  endTime: string;
  startDateTime?: string;
  endDateTime?: string;
  availability?: 'open' | 'blocked' | 'busy' | 'booked' | 'request';
  deliveryType?: DeliveryType;
  clientRecord?: clientRecordsInterface;
  confirmation?: {
    clinician: boolean;
    patient: boolean;
  };
  deliveryOptions?: string[];
  faceToFaceLocation?: string;
  videoCallInstructions?: string;
  phoneCallInstructions?: string;
  phoneCallDialClientInstructions?: string;
  otherInstructions?: OtherInstructions;
  rate?: number;
  gap?: number;
  bookingRules?: {
    new: {
      available: boolean;
      rule: 'instant' | 'request';
    };
    existing: {
      available: boolean;
      rule: 'instant' | 'request';
    };
  };
  bookingRule?: 'instant' | 'request';
  recurringAppointmentId?: string;
  room?: {
    roomId: string;
    roomName: string;
  };
  markedStatus?: AppointmentStatusOption[];
  title: string;
  recurrings: any[];
  notes?: string;
  type: AppointmentStatusType;
  integration?: {
    profileName?: string;
  };
  attendees?: Attendee[];
  groupId?: string;
  groupDetails: GroupDetails;
  group: GroupsFromAPI;
  clientRescheduleDetails?: {
    count: number;
    status?: ClientRescheduleRequestStatus;
    date?: string;
    startTime?: string;
    endTime?: string;
  };
  createdByClinician?: string;
  telehealthRoomId?: string;
  activityFeeds?: PatientActivity[];
  dayOfWeek?: string;
  invoices?: MinifiedInvoice[];
  status?: AppointmentStatus;
  isCancelled?: boolean;
  claimType?: AppointmentClaimType;
  isProcessed?: boolean;
  requestedChanges?: RequestedChanges;
  serviceDelivered?: ServiceDelivered;
  paymentRequestIds?: string[];
  isActivity?: boolean;
  packageAssigneeId?: string;
  packageId?: string;
  isRoomFilter?: boolean;
  isRoomBookedByOthers?: boolean;
  clinicianProfile?: PractitionersListMinified;
}

export enum AppointmentStatus {
  Cancelled = 'cancelled'
}
