import { configInterface } from './config';
import * as constants from '../pages/Calendar/components/CalendarSettings/components/AccountLevelSettings/constants';
import AUTerms from 'pages/SignUp/components/Data/components/AUTerms';
import AUPrivacy from 'pages/SignUp/components/Data/components/AUPrivacy';

export const configAU: configInterface = {
  countryCode: 'au',
  mobileNumberMinLength: 10,
  mobileNumberMaxLength: 20,
  moneyIcon: 'monetization_on',
  currencySymbol: '$',
  currencyCode: 'aud',
  taxLabel: 'TAX',
  taxRateLabel: 'GST',
  mobileCountryCode: '61',
  codeLabel: 'BSB Code',
  defaultTimezone: 'Australia/Melbourne',
  practiceSettings: {
    registrationNumberLabel: 'ABN',
    publicHolidayOption: constants.AU_PUBLIC_HOLIDAY_CALENDAR_OPTIONS
  },
  terms: {
    tnc: <AUTerms />,
    privacy: <AUPrivacy />
  }
};
