import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

import styles from './LinkButton.module.scss';

interface LinkButtonProps {
  children: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const LinkButton = ({ children, onClick }: LinkButtonProps) => {
  return (
    <ButtonAlt size={'small'} variant={'text'} onClick={onClick} className={styles.linkButton}>
      {children}
    </ButtonAlt>
  );
};

export default LinkButton;
