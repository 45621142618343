import { newClientForm } from '../AddPatientModalV2Interface';

export const defaultBaseDetails = {
  clinicianAuth0Ids: [''],
  recordType: 'adult',
  referralSourceTags: [],
  referralDetail: '',
  caseId: '',
  clientProfiles: [],
  clientCapability: {
    profileType: 'full'
  },
  signupInvitationSettings: {
    sendAt: 'now',
    customSendTime: ''
  },
  quickNote: {
    note: '',
    includeQuickNote: false
  },
  registeredAs: 'active',
  address: {}
} as newClientForm;
