import { useGetAccessToken } from 'utils/hooks/token';

import styles from './BroadcastMessageOneToOne.module.scss';
import BroadcastContent from '../BroadcastContent/BroadcastContent';
import { useFetchClientRecordList } from 'utils/hooks/GetClient/clientList';

const BroadcastMessageOneToOne = () => {
  const { token } = useGetAccessToken();
  const { clientList, isClientListLoading, hasMoreClients, loadMoreClients, searchTerm, setSearchTerm } =
    useFetchClientRecordList({
      token,
      usePagination: true
    });

  return (
    <div className={styles.container}>
      <BroadcastContent
        clientList={clientList}
        hasMoreClients={hasMoreClients}
        isLoading={isClientListLoading}
        loadMoreClients={loadMoreClients}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </div>
  );
};

export default BroadcastMessageOneToOne;
