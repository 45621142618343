import { notification } from 'antd';
import { LetterTemplate } from 'interfaces/Letters/letterTemplate';
import { useCallback, useEffect, useState } from 'react';
import { getLetterTemplateList } from 'utils/http/DocumentService/Letters/lettersTemplate';
import { useGetAccountPackageView } from '../GetAccountInfo/accountPackageView';

export const useFetchLetterTemplateList = (token?: string, accountId?: string) => {
  const [letterTemplateList, setLetterTemplateList] = useState<LetterTemplate[]>([]);
  const [loading, setLoading] = useState(true);

  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();

  const asAdmin = isEdgeAdminView || isEdgeReceptionistView;

  const fetchLetterTemplateList = useCallback(async () => {
    setLoading(true);
    try {
      if (token && accountId) {
        const response = await getLetterTemplateList(token, accountId, asAdmin);
        const data = await response.json();
        setLetterTemplateList(data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      notification.error({ message: 'Failed to load letter template' });
    }
  }, [token, accountId, asAdmin]);

  useEffect(() => {
    fetchLetterTemplateList();
  }, [fetchLetterTemplateList]);

  return { letterTemplateList, isLoadingLetterTemplateList: loading, fetchLetterTemplateList };
};
