import { ParticipantType } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import { Invoice, PaymentRequest } from 'pages/Invoices/interface';
import { InvoiceSorting, SearchFilterEnum } from 'redux/invoices/invoiceSlice';
import { InvoiceColumnSortable } from './components/InvoiceListHeader/InvoiceListHeader';
import { InvoiceWithProjectedMbsCode, PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';
import { AppointmentDocument } from 'interfaces/Clients/Appointment';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { GeneralPractitionerInterface } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/GPDetails/interface';
import { ClinicianMedicareRole, ProfileInterface } from 'interfaces/Profile/Profile';
import { Provider } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { getClaimInvoiceDetailsInfo } from 'pages/InvoicePendingClaims/utils';
import { MBS_CODE_ITEMS } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/mbsItems';
import { getSelectedClaimType } from 'pages/InvoicePendingClaims/components/PendingClaimInvoiceList/utils';
import { ClaimType } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';
import { SubmittedClaim } from 'interfaces/invoices/submittedClaim';

export interface GetInvoiceListData {
  invoices: InvoiceWithType[];
  paging: { page: number; perPage: number; totalItems: number };
  count: { total: number; recent: number };
}

interface GetInvoiceRecordListingQuery {
  page: number;
  perPage: number;

  searchValue?: string;
  searchBy?: SearchFilterEnum;

  sortByIssueDate?: 1 | -1;

  clientIds?: string;
  practitionerIds?: string;
  invoiceTypes?: string;
  statuses?: string;
  paymentStatuses?: string;
  type: ParticipantType;
  createdAt?: string;
}

export interface InvoiceListRequestPayload {
  accountId: string;
  params?: GetInvoiceRecordListingQuery;
}

export const getSortByQuery = (sortBy: InvoiceSorting['sortBy']) => {
  switch (sortBy) {
    case InvoiceColumnSortable.INVOICE_ID:
      return 'sortByInvoiceId';
    case InvoiceColumnSortable.CREATED_AT:
      return 'sortByCreatedAt';
    case InvoiceColumnSortable.TOTAL:
      return 'sortByInvoiceAmount';
    case InvoiceColumnSortable.PAID:
      return 'sortByInvoicePaid';
    case InvoiceColumnSortable.OWED:
      return 'sortByInvoiceOwed';
    default:
      return 'sortByIssueDate';
  }
};

export interface InvoiceWithType extends Invoice {
  paid?: {
    payments: number;
    claims: number;
    total: number;
  };
  owed?: number;
  invoiceType?: PendingClaimsFunder;
  paymentRequests?: PaymentRequest[];

  // following field for BulkBill invoice (create claim) - all is optional
  foundAppointment?: AppointmentDocument; // entire appointment attached to items[0]
  foundClientRecord?: clientRecordsInterface; // entire client record, with their assigned GP as foundGeneralPractitioner
  foundGeneralPractitioner?: GeneralPractitionerInterface;
  foundClinician?: ProfileInterface; // entire clinician, from foundAppointment.clinicianId
  foundProvider?: Provider; // default provider for foundClinician, or the first one found
  groups?: { _id: string; name: string }[];
  isClaimReady?: boolean; // checks a few things, described below
  mbsCode?: string; // autogenerated MBS code, only exists if foundClinician.medicare.role exists
  funder?: PendingClaimsFunder;

  submittedClaim?: SubmittedClaim; // To view submit claim in invoice list
}

export enum ManualBalanceType {
  Cash = 'cash',
  OnlineTransfer = 'onlineTransfer',
  CreditNote = 'creditNote',
  WriteOff = 'writeOff',
  ManualRefund = 'manualRefund',
  Others = 'others'
}

/**
 * Get claim data and inject into the invoice
 */
export const massageInvoiceData = ({
  invoiceList,
  pendingClaimList,
  submittedClaimList
}: {
  invoiceList: InvoiceWithType[];
  pendingClaimList: InvoiceWithProjectedMbsCode[];
  submittedClaimList: SubmittedClaim[];
}) => {
  if (invoiceList.length === 0 || (pendingClaimList.length === 0 && submittedClaimList.length === 0)) {
    return invoiceList;
  }

  return invoiceList.map((invoice) => {
    if (!invoice.invoiceType || invoice.invoiceType !== PendingClaimsFunder.BULK_BILL) {
      return invoice;
    }
    const foundPendingClaim = pendingClaimList.find((pendingClaim) => pendingClaim._id === invoice._id);
    const foundSubmittedClaim = submittedClaimList.find((submittedClaim) => submittedClaim.invoice.id === invoice._id);

    return {
      ...invoice,
      ...(foundPendingClaim && {
        foundAppointment: foundPendingClaim.foundAppointment,
        foundClientRecord: foundPendingClaim.foundClientRecord,
        foundGeneralPractitioner: foundPendingClaim.foundGeneralPractitioner,
        foundClinician: foundPendingClaim.foundClinician,
        foundProvider: foundPendingClaim.foundProvider,
        groups: foundPendingClaim.groups,
        isClaimReady: foundPendingClaim.isClaimReady,
        mbsCode: foundPendingClaim.mbsCode,
        funder: foundPendingClaim.funder
      }),
      ...(foundSubmittedClaim && {
        submittedClaim: foundSubmittedClaim
      })
    };
  });
};

export const getCreateClaimPayload = (invoice: InvoiceWithType) => {
  const { clientProfile } = getClaimInvoiceDetailsInfo(invoice.foundClientRecord);

  const clinicianRoleMbsItems =
    MBS_CODE_ITEMS[invoice.foundClinician?.medicare?.role || ClinicianMedicareRole.ClinicalPsychologists];

  const mbsItem = clinicianRoleMbsItems.find(({ mbsCode }) => mbsCode === invoice.mbsCode) || clinicianRoleMbsItems[0];

  return {
    type: getSelectedClaimType(invoice.funder) || ClaimType.BulkBill,
    invoiceId: invoice._id,
    clientRecordId: invoice.foundClientRecord?._id || '',
    clientProfileId: clientProfile?._id || '',
    mbsCode: invoice.mbsCode || '',
    benefit: Math.min(invoice.invoiceAmount, mbsItem.benefit),
    service: {
      duration: mbsItem.duration,
      format: mbsItem.format,
      mode: mbsItem.mode
    }
  };
};
