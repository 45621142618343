import { Skeleton } from 'antd';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  DateFilter,
  resetFilters,
  resetPaging,
  selectActiveTab,
  selectFilters,
  selectPaging,
  selectSearch,
  selectSorting,
  setFilters,
  setPaging,
  setSearch,
  setSorting
} from 'redux/referrals/referralSlice';
import ReferralsFilters from '../components/ReferralsFilters/ReferralsFilters';
import ReferralsHeader from '../components/ReferralsHeader/ReferralsHeader';
import styles from './ReferralsList.module.scss';

import SearchBarT23 from 'components/SearchBarT23/SearchBarT23';
import PaginationDescV2 from 'components/TableList/PaginationDescV2/PaginationDescV2';
import PerRecordDropdown from 'components/TableList/PerRecordDropdown/PerRecordDropdown';
import { useMemo } from 'react';
import { useGetClinicianProfileQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import { useGetPackageListQuery } from 'redux/endpoints/scheduleServices/package';
import { SortType } from 'redux/features/utils';
import { useGetReferralList } from 'redux/referrals/useGetReferralList';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { PACKAGE_LIMIT } from '../components/ReferralForm/ReferralForm';
import ReferralItem from '../components/ReferralsItem/ReferralItem';
import { ReferralTab } from '../interface';
import { searchFilterItems } from '../utils';

export const HIGHEST_PAGING_COUNT = 100;

const ReferralsList = () => {
  const { accountId } = useGetAccountId();
  const { data: profileData, isLoading: isProfileLoading } = useGetClinicianProfileQuery();
  const dispatch = useAppDispatch();
  const paging = useAppSelector(selectPaging);
  const sorting = useAppSelector(selectSorting);
  const filters = useAppSelector(selectFilters);
  const activeTab = useAppSelector(selectActiveTab);
  const search = useAppSelector(selectSearch);
  const { dateRange, statuses, referrerTypes, linkages, packages } = filters;
  const { sortBy, sortType } = sorting;

  const { isPackageEnabled } = useGetFeatureToggle();
  const { referrals, paging: pagingData, isLoading, isFetching, isError } = useGetReferralList();

  const onClickSort = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      dispatch(setSorting({ ...sorting, sortType: sortType === SortType.ASC ? SortType.DESC : SortType.ASC }));
    } else {
      dispatch(setSorting({ ...sorting, sortBy: newSortBy, sortType: SortType.DESC }));
    }
  };

  const onChangePage = (page: number) => {
    dispatch(setPaging({ ...paging, page }));
  };

  const onChangePerPage = (perPage: number) => {
    dispatch(setPaging({ ...paging, perPage, page: 1 }));
  };

  const onChangeDateFilter = (dateRange: DateFilter) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, dateRange }));
  };

  const onChangeStatusFilter = (statuses: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, statuses }));
  };

  const onChangeReferrerTypeFilter = (referrerTypes: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, referrerTypes }));
  };

  const onChangeLinkageFilter = (linkages: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, linkages }));
  };

  const onChangePackageFilter = (packages: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, packages }));
  };

  const onResetFilters = () => {
    dispatch(resetPaging());
    dispatch(resetFilters());
  };

  const onChangeSearchValue = (searchValue: string) => {
    dispatch(resetPaging());
    dispatch(setSearch({ ...search, searchValue }));
  };

  const onChangeSearchBy = (searchBy: string) => {
    dispatch(setSearch({ ...search, searchBy }));
  };

  const getFilterTitle = (tab: ReferralTab | undefined) => {
    switch (tab) {
      case ReferralTab.All:
        return 'referrals';
      case ReferralTab.Decline:
        return 'declined referrals';
      case ReferralTab.Triage:
        return 'referrals in triage';
      default:
        return tab + ' referrals';
    }
  };

  const packageIds = useMemo(() => {
    const allPackageIds = referrals
      .map((referral) => referral.packages?.map((pack) => pack.packageId) || [])
      .flat()
      .filter((p) => p);
    return Array.from(new Set(allPackageIds));
  }, [referrals]);

  const packageQueryParam = useMemo(
    () => ({
      page: 1,
      perPage: HIGHEST_PAGING_COUNT * PACKAGE_LIMIT,
      ...(packageIds.length > 0 && { filterByIds: packageIds.join(',') })
    }),
    [packageIds]
  );

  const { data: packageFunderData } = useGetPackageListQuery(
    {
      accountId: accountId,
      params: packageQueryParam
    },
    { skip: !accountId || !isPackageEnabled || !packageIds.length }
  );

  const packageById = useMemo(() => {
    return new Map((packageFunderData?.packages || []).map((opt) => [opt._id, opt]));
  }, [packageFunderData]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        {/* Filters */}
        <div className={styles.filterWrapper}>
          <ReferralsFilters
            isLoading={isLoading || isFetching}
            totalItems={!isError ? pagingData.totalItems || 0 : 0}
            filterText={getFilterTitle(activeTab)}
            selectedDateRange={dateRange}
            onChangeDateFilter={onChangeDateFilter}
            selectedStatuses={statuses}
            onChangeStatusFilter={onChangeStatusFilter}
            selectedReferrerTypes={referrerTypes}
            onChangeReferrerTypeFilter={onChangeReferrerTypeFilter}
            selectedLinkages={linkages}
            onChangeLinkageFilter={onChangeLinkageFilter}
            selectedPackages={packages}
            onChangePackageFilter={onChangePackageFilter}
            onResetFilters={onResetFilters}
          />
        </div>

        <div className={styles.searchBarWrapper}>
          <SearchBarT23
            placeholder={`Search by ${searchFilterItems
              .find((item) => search.searchBy === item._id)
              ?.name?.toLowerCase()}`}
            value={search.searchValue}
            withSearchButton
            onSearch={onChangeSearchValue}
            withSearchBy={{
              onChange: onChangeSearchBy,
              searchByOptions: searchFilterItems,
              selectedSearchByOption:
                searchFilterItems.find((item) => search.searchBy === item._id) || searchFilterItems[0]
            }}
          />
        </div>
      </div>

      <div className={styles.listWrapper}>
        <ReferralsHeader activeTab={activeTab || ReferralTab.All} sorting={sorting} onClickSort={onClickSort} />

        {isLoading || isFetching || isProfileLoading ? (
          <div className={styles.loadingWrapper}>
            {[...Array(10)].map((_, i) => (
              <div key={i} className={styles.loadingItem}>
                <Skeleton.Input active className={styles.loadingContent} />
              </div>
            ))}
          </div>
        ) : isError || referrals.length < 1 ? (
          <div>No referrals found</div>
        ) : (
          <div className={styles.listItem}>
            {referrals?.map((referral) => (
              <ReferralItem
                key={referral._id}
                referral={referral}
                practiceName={profileData?.practice?.name || ''}
                packageById={packageById}
                allowActions
              />
            ))}
          </div>
        )}
      </div>

      {/* Pagination */}
      {!isError && !isLoading && !isFetching && pagingData.totalItems > pagingData.perPage && (
        <div className={styles.paginationWrapper}>
          <div className={styles.recordPerPageWrapper}>
            <PerRecordDropdown
              totalItem={pagingData.totalItems}
              selectedPerPage={pagingData.perPage}
              selectDropdownValue={onChangePerPage}
            />
            <div className={styles.label}>referrals per page</div>
          </div>
          <PaginationDescV2
            currentPage={paging.page}
            totalItem={pagingData.totalItems}
            perPage={paging.perPage}
            onPageChange={onChangePage}
          />
        </div>
      )}
    </div>
  );
};

export default ReferralsList;
