import { ChangeEvent, memo, useEffect, useRef, useState } from 'react';
import styles from './OccurrenceInput.module.scss';
import classNames from 'classnames';

interface OccurrenceInputProps {
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
}

const OccurrenceInput = ({ value: valueProps, setValue: setValueProps, disabled }: OccurrenceInputProps) => {
  const [isOccurrenceActive, setIsOccurrenceActive] = useState<boolean>(false);
  const [value, setValue] = useState<string>(valueProps);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setValue(valueProps);
  }, [valueProps]);

  const handleClickOutSide = (event: any) => {
    if (!containerRef.current?.contains(event.target)) {
      setIsOccurrenceActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const onOccurrenceChange = (increase: number) => {
    const valueAsNumber = Number(value);
    setIsOccurrenceActive(true);
    if (value === '') {
      return;
    }
    setValueProps((valueAsNumber + increase).toString());
  };

  const onOccurrenceInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (!/^[0-9]*$/.test(newValue)) {
      return;
    }
    if (newValue === '') {
      setValue(newValue);
    }
    const newValueAsNumber = Number(newValue);
    if (newValueAsNumber > 0 && newValueAsNumber <= 28) {
      setValue(newValue);
    }
  };

  const onOccurrenceInputBlur = () => {
    if (value === '') {
      setValue(valueProps);
      return;
    } else if (Number(value) > 2 && value !== valueProps) {
      setValueProps(value);
    } else {
      setValue(valueProps);
    }
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.inputWrapper}>
        <input
          type="text"
          className={styles.input}
          value={value}
          disabled={disabled}
          onChange={onOccurrenceInputChange}
          onFocus={() => setIsOccurrenceActive(true)}
          onBlur={onOccurrenceInputBlur}
        />
        {isOccurrenceActive && <div className={styles.borderBottom} />}
      </div>
      <div className={styles.occurrenceControllerWrapper}>
        <button
          className={styles.button}
          disabled={disabled || Number(value) === 28}
          onClick={() => onOccurrenceChange(1)}
        >
          <i className={classNames('material-icons', styles.icon)}>arrow_drop_up</i>
        </button>
        <button
          className={styles.button}
          disabled={disabled || Number(value) === 2}
          onClick={() => onOccurrenceChange(-1)}
        >
          <i className={classNames('material-icons', styles.icon)}>arrow_drop_down</i>
        </button>
      </div>
      occurrences
    </div>
  );
};

export default memo(OccurrenceInput);
