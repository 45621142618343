import { useState } from 'react';
import styles from './StartTimeSettingControl.module.scss';

// Generate option items constant
const TIME_OPTIONS = [
  { label: 'AUTO', value: 'auto' },
  { label: '7 AM', value: '7' },
  { label: '8 AM', value: '8' },
  { label: '9 AM', value: '9' },
  { label: '10 AM', value: '10' },
  { label: '11 AM', value: '11' },
  { label: '12 PM', value: '12' },
  { label: '1 PM', value: '13' },
  { label: '2 PM', value: '14' },
  { label: '3 PM', value: '15' },
  { label: '4 PM', value: '16' },
  { label: '5 PM', value: '17' },
  { label: '6 PM', value: '18' },
  { label: '7 PM', value: '19' },
  { label: '8 PM', value: '20' },
  { label: '9 PM', value: '21' },
  { label: '10 PM', value: '22' },
  { label: '11 PM', value: '23' }
];

interface StartTimeSettingProps {
  timeValue: string;
  onTimeSelected: (value: string) => void;
}

const StartTimeSettingControl = ({ timeValue, onTimeSelected }: StartTimeSettingProps) => {
  const [isShowTimeSelector, setIsShowTimeSelector] = useState(false);
  const toggleShowingTimeSelector = () => {
    setIsShowTimeSelector(!isShowTimeSelector);
  };

  const handleSelectedTime = (timeValue: string) => {
    setIsShowTimeSelector(false);
    onTimeSelected(timeValue);
  };

  /* render main component ---------------------------------------- */
  return (
    <div className={styles.container}>
      <div className="start-time-label">
        <i className="material-icons">schedule</i>
        <label>FROM</label>
      </div>
      <div onClick={toggleShowingTimeSelector}>
        <label>{TIME_OPTIONS.find((o) => o.value === timeValue)?.label || 'AUTO'}</label>
        <i className="material-icons">arrow_drop_down</i>
      </div>
      {isShowTimeSelector && (
        <div onMouseLeave={() => setIsShowTimeSelector(false)}>
          <ul>
            {TIME_OPTIONS.map((o) => (
              <li
                key={o.value}
                className={o.value === timeValue ? styles.active : ''}
                onClick={() => handleSelectedTime(o.value)}
              >
                {o.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default StartTimeSettingControl;
