import i18n from 'i18n';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import {
  putCheckAvailabilityCaseId,
  putCheckAvailabilityEmail
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { checkPhoneNumber } from 'utils/http/NotifService/phoneValidator';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import * as yup from 'yup';
import { ClientDetails } from '../../../../../AddPatientModalV2Interface';

export interface AdultClientFormValidationInterface extends ClientDetails {
  profileType?: clientProfilesInterface['profileType'];
}

const clientFormSchema = () =>
  yup.object().shape({
    firstName: yup.string().required(i18n.t('validation.client_firstname.require')),
    lastName: yup.string().required(i18n.t('validation.client_lastname.require')),
    email: yup.string().when('profileType', {
      is: 'full',
      then: yup
        .string()
        .email(i18n.t('validation.client_email.invalid'))
        .required(i18n.t('validation.client_email.require')),
      otherwise: yup.string().email(i18n.t('validation.client_email.invalid')).nullable()
    }),
    mobileNumber: yup.string().when('profileType', {
      is: 'full',
      then: yup
        .string()
        .required(i18n.t('validation.client_phonenumber.require'))
        .min(12, i18n.t('validation.client_phonenumber.min', { count: 10 })),
      otherwise: yup.string().when('mobileNumber', {
        is: '',
        then: yup.string().nullable(),
        otherwise: yup.string().min(10, i18n.t('validation.client_phonenumber.min', { count: 10 }))
      })
    }),
    dateOfBirth: yup.string().test('Date of Birth', i18n.t('validation.dob.invalid'), (value) => {
      if (value && value?.length > 0) {
        return moment(value, MOMENTJS_DATE_FORMAT).format(MOMENTJS_DATE_FORMAT) === value;
      } else {
        return true;
      }
    })
  });

export const validationClientForm = (value: AdultClientFormValidationInterface) => {
  const validationErrors = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    mobileNumber: ''
  };

  try {
    clientFormSchema().validateSync(value, { abortEarly: false });
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof typeof validationErrors] = error.message;
        }
      });
    }
  }

  return validationErrors;
};

export const validateEmail = async (token: string, email: string) => {
  try {
    const body = {
      email
    };
    await putCheckAvailabilityEmail(token, body);
    return {
      email,
      statusCode: 200
    };
  } catch (ex) {
    if (isErrorBentStatusError(ex)) {
      return {
        email,
        statusCode: ex.statusCode
      };
    }

    throw ex;
  }
};
export const validatePhoneNumber = async (phoneNumber: string) => {
  try {
    const { valid } = (await (await checkPhoneNumber(phoneNumber)).json()) as { valid: boolean };
    return {
      phoneNumber,
      valid
    };
  } catch (ex) {
    if (isErrorBentStatusError(ex)) {
      return {
        phoneNumber,
        valid: false
      };
    }

    throw ex;
  }
};

export const validateCaseId = async (token: string, caseId: string, clientRecordId?: string) => {
  try {
    const body = {
      caseId,
      ...(clientRecordId && {
        clientRecordId
      })
    };
    await putCheckAvailabilityCaseId(token, body);
    return {
      caseId,
      statusCode: 200
    };
  } catch (ex) {
    if (isErrorBentStatusError(ex)) {
      return {
        caseId,
        statusCode: ex.statusCode
      };
    }

    throw ex;
  }
};
