import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';

const CHECK_IN_SERVICE_URL = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const getPatientActivityFeed = (
  token: string,
  recordId: string,
  filter: string,
  pageSize: number,
  lastCreatedAt: string,
  lastNoteCreatedAt: string
) =>
  httpClient('GET', CHECK_IN_SERVICE_URL!, 200, { Authorization: `Bearer ${token}` })(
    `/activity-feed?clientRecordId=${recordId}&filter=${filter}&pageSize=${pageSize}${
      lastCreatedAt ? `&lastCreatedAt=${lastCreatedAt}` : ''
    }${lastNoteCreatedAt ? `&lastNoteCreatedAt=${lastNoteCreatedAt}` : ''}`
  ) as Promise<BentResponse>;
