import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useEffect, useRef, useState } from 'react';
import styles from './SubmittedClaimItemMenu.module.scss';
import { SubmittedClaim } from 'interfaces/invoices/submittedClaim';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { ClaimStatus } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

const IS_DEVELOPMENT = isDevelopmentENV();

interface SubmittedClaimItemMenuProps {
  claim: SubmittedClaim;
  onResubmit: () => void;
  onSendDB4Form: () => void;
  onViewClientProfile: () => void;
  onWriteOff: () => void;
  onAbandon?: () => void;
}

const SubmittedClaimItemMenu = ({
  claim,
  onResubmit,
  onSendDB4Form,
  onViewClientProfile,
  onWriteOff,
  onAbandon
}: SubmittedClaimItemMenuProps) => {
  const { isInvoiceListV2Enabled } = useGetFeatureToggle();
  const { isEdgeAdminView } = useGetAccountPackageView();

  const [isExpanded, setIsExpanded] = useState(false);
  const menuNode = useRef<HTMLDivElement>(null);

  const handleExpandMenu = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target)) {
      return;
    }
    setIsExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const onSendDB4FormHandle = () => {
    setIsExpanded(false);
    onSendDB4Form();
  };

  const onViewClientProfileHandle = () => {
    setIsExpanded(false);
    onViewClientProfile();
  };

  const onResubmitHandle = () => {
    setIsExpanded(false);
    onResubmit();
  };

  const handleWriteOff = () => {
    isExpanded && setIsExpanded(false);
    onWriteOff();
  };

  const handleAbandon = () => {
    isExpanded && setIsExpanded(false);
    onAbandon && onAbandon();
  };

  return (
    <div ref={menuNode} className={styles.container}>
      <ButtonAlt className={styles.button} variant={'text'} onClick={handleExpandMenu}>
        <i className={`material-icons ${styles.icon}`}>more_vert</i>
      </ButtonAlt>
      <div className={styles.menuWrapper}>
        <div className={isExpanded ? styles.menuShow : styles.menuHide}>
          {/* Send DB4 Form */}
          {IS_DEVELOPMENT && (
            <ThreeDotMenuItem disabled={false} label={'Send DB4 Form'} onClick={onSendDB4FormHandle} preIcon={'send'} />
          )}

          {/* Resubmit claim */}
          <ThreeDotMenuItem
            disabled={!claim.allowResubmitClaim}
            label={'Resubmit claim'}
            onClick={onResubmitHandle}
            preIcon={'cases'}
          />

          {/* View Client Profile */}
          <ThreeDotMenuItem label={'View Client Profile'} onClick={onViewClientProfileHandle} preIcon={'person'} />

          {/* Write Off */}
          {isInvoiceListV2Enabled && isEdgeAdminView && (
            <ThreeDotMenuItem
              label="Write Off"
              onClick={handleWriteOff}
              preIcon="money_off"
              disabled={![ClaimStatus.Rejected].includes(claim.status)}
            />
          )}

          {/* Abandon */}
          {isEdgeAdminView &&
            [ClaimStatus.Rejected, ClaimStatus.MedicareRejected].includes(claim.status) &&
            !claim.abandoned && <ThreeDotMenuItem label="Abandon" onClick={handleAbandon} preIcon="credit_card_off" />}
        </div>
      </div>
    </div>
  );
};

export default SubmittedClaimItemMenu;
