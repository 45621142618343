import moment from 'moment';
import styles from './ReferralHistory.module.scss';
import { Referral, ReferralHistorySource } from 'interfaces/Referral/Referral';
import DocumentViewerModal from 'components/DocumentViewerModal/DocumentViewerModal';
import { useCallback, useState } from 'react';
import { referralFilesInterface } from 'interfaces/Clients/clientsRecord';
import classNames from 'classnames';
import { getActorName } from 'pages/Referrals/utils';

interface ReferralHistoryProps {
  activityFeeds: Referral['changeHistory'];
  practiceName: string;
  files: referralFilesInterface[];
}

const ReferralHistory = ({ activityFeeds = [], practiceName, files }: ReferralHistoryProps) => {
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<{ fileName: string; bucketName: string }>({
    fileName: '',
    bucketName: ''
  });

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setSelectedFile({ fileName: '', bucketName: '' });
  };

  const isFileExist = useCallback(
    (file: referralFilesInterface) => {
      return files.some((item) => item.fileName === file.fileName && item.bucketName === file.bucketName);
    },
    [files]
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.variant}>ACTION</div>
        <div className={styles.detail}>DETAIL</div>
        <div className={styles.time}>TIME</div>
        <div className={styles.actionBy}>ACTION BY</div>
      </div>
      <div className={styles.content}>
        {activityFeeds.map((activity, index) => (
          <div className={styles.historyItem} key={index}>
            <div className={styles.variant}>
              <span className={styles.pill}>{activity.field.toUpperCase()}</span>
            </div>
            <div className={styles.detail}>
              {activity.field === 'file' ? (
                <>
                  {activity.data.message}:
                  {Array.isArray(activity.data.items) &&
                    activity.data.items.map((file, index) => {
                      const nameIndex = file.fileName.indexOf('-');
                      const displayName = file.fileName.substring(nameIndex + 1);
                      const isExist = isFileExist(file);

                      return (
                        <div
                          key={index}
                          className={classNames(styles.file, isExist && styles.fileExist)}
                          onClick={() => {
                            if (!isExist) return;
                            setSelectedFile(file);
                            setIsViewerOpen(true);
                          }}
                        >
                          {displayName} {!isExist && <span className={styles.deleted}>(deleted)</span>}
                        </div>
                      );
                    })}
                </>
              ) : (
                <span dangerouslySetInnerHTML={{ __html: activity.data.message as string }} />
              )}
            </div>
            <div className={styles.time}>
              {!moment(activity.date).isSame(moment().startOf('day')) && (
                <>
                  {moment(activity.date).format('HH:mm')} <br />
                </>
              )}
              {moment(activity.date).format('DD / MM / YYYY')}
            </div>
            <div className={styles.actionBy}>
              {getActorName({
                actor: activity.source as ReferralHistorySource,
                clientName: activity.user.name,
                clinicianName: activity.user.name,
                practiceName
              })}
            </div>
          </div>
        ))}
      </div>

      {selectedFile.bucketName && selectedFile.fileName && (
        <DocumentViewerModal
          title={selectedFile.fileName}
          bucketName={selectedFile.bucketName}
          visible={isViewerOpen}
          onCancel={handleCloseViewer}
        />
      )}
    </div>
  );
};

export default ReferralHistory;
