import { useEffect, useState } from 'react';
import styles from './ParticipationToggle.module.scss';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

type ParticipationToggleProps = {
  selectedParticipantType: ParticipantType;
  onChangeParticipation: (val: ParticipantType) => void;
  showOnlyParticipantType?: {
    oneToOne: boolean;
    group: boolean;
  };
};

const ParticipationToggle = ({
  selectedParticipantType,
  onChangeParticipation,
  showOnlyParticipantType = { oneToOne: true, group: true }
}: ParticipationToggleProps) => {
  const [activeToggle, setActiveToggle] = useState<ParticipantType>(selectedParticipantType);

  const handleChangeParticipation = (PValue: ParticipantType) => {
    if (PValue !== activeToggle) {
      onChangeParticipation(PValue);
      setActiveToggle(PValue);
    }
  };

  useEffect(() => {
    if (selectedParticipantType !== activeToggle) {
      setActiveToggle(selectedParticipantType);
    }
  }, [selectedParticipantType, activeToggle]);

  return (
    <div className={styles.container}>
      {showOnlyParticipantType?.oneToOne && (
        <div
          onClick={() => handleChangeParticipation(ParticipantType.OneToOne)}
          className={activeToggle === ParticipantType.OneToOne ? styles.toggleWrapperActive : styles.toggleWrapper}
        >
          <i className={`material-icons ${styles.icon}`}>person</i>
        </div>
      )}
      {showOnlyParticipantType?.group && (
        <div
          onClick={() => handleChangeParticipation(ParticipantType.Group)}
          className={activeToggle === ParticipantType.Group ? styles.toggleWrapperActive : styles.toggleWrapper}
        >
          <i className={`material-icons ${styles.icon}`}>groups</i>
        </div>
      )}
    </div>
  );
};

export default ParticipationToggle;
