import { useState } from 'react';
import { Modal, notification } from 'antd';
import moment from 'moment';
import path from 'path-browserify';
import { Link } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { Attachment } from '../../PatientDetailsAttachments';

import { deleteAttachmentByRecordId } from 'utils/http/DocumentService/Attachments/attachments';

import Button from 'components/Button/Button';

import styles from './PatientDetailsAttachmentCard.module.scss';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import DocumentViewerModal from 'components/DocumentViewerModal/DocumentViewerModal';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { security } from 'utils/security';

interface PatientDetailsAttachmentCardProps {
  attachment: Attachment;
  recordId: string;
  isReadOnly?: boolean;
  onDeleteAttachment: () => void;
}

const PatientDetailsAttachmentCard = ({
  attachment: { _id, caseNote, createdAt, title, thumbnail, clinicianId },
  recordId,
  isReadOnly,
  onDeleteAttachment
}: PatientDetailsAttachmentCardProps) => {
  const { CLIENTS } = useRoutesGenerator();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeAdminView } = useGetAccountPackageView();

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      const token = await security.getAccessTokenSilently();

      await deleteAttachmentByRecordId(token, recordId, _id);

      onDeleteAttachment();
    } catch (ex) {
      console.error(ex);

      setIsDeleting(false);

      notification.error({ message: 'Something went wrong while trying to delete this attachment.' });
    }
  };

  const handleClickDelete = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this attachment?',
      onOk: handleDelete
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.previewContainer}>
          {thumbnail ? (
            <img src={thumbnail} alt={'thumbnail'} />
          ) : (
            <span className={styles.fileType}>{path.extname(title).toUpperCase()}</span>
          )}
        </div>
        <Button className={styles.titleButton} variant="secondary" onClick={() => setIsModalOpen((bool) => !bool)}>
          {title}
        </Button>
        <div className={styles.date}>Added {moment(createdAt).format(MOMENTJS_DATE_FORMAT)}</div>
        {caseNote?._id && (
          <div className={styles.linked}>
            Linked to{' '}
            <Link className={styles.link} to={`${CLIENTS.BASE}/${recordId}/notes/${caseNote._id}`}>
              {caseNote.title}
            </Link>
          </div>
        )}
        {(auth0ClinicianId === clinicianId || isEdgeAdminView) && !isReadOnly ? (
          <ButtonAlt
            error
            size="small"
            className={styles.deleteButton}
            variant="text"
            disabled={isDeleting}
            onClick={handleClickDelete}
          >
            DELETE
          </ButtonAlt>
        ) : (
          <i className={`material-icons-outlined ${styles.lockIcon}`}>lock</i>
        )}
      </div>
      <DocumentViewerModal
        title={title}
        recordId={recordId}
        attachmentId={_id}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default PatientDetailsAttachmentCard;
