import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import styles from './FrequencyField.module.scss';

const frequencyOptions = [
  { label: 'Day', value: '1' },
  { label: 'Week', value: '7' },
  { label: 'Fortnight', value: '14' },
  { label: 'Month', value: '28' }
];

const FrequencyField = () => {
  return (
    <div className={styles.container} style={{ flex: '1' }}>
      <FormikSelect name="frequency" label="Every" options={frequencyOptions} />
    </div>
  );
};

export default FrequencyField;
