import { useEffect, useRef, useState } from 'react';

import styles from './FootNotesTextWidget.module.scss';
import classnames from 'classnames';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface FootNotesTextWidgetProps {
  data: any;
  id?: any;
  changeValue?: any;
  previewMode?: boolean;
  editMode?: boolean;
}

const FootNotesTextWidget = ({ data, id, changeValue, previewMode, editMode }: FootNotesTextWidgetProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const footNotesRef = useRef<HTMLDivElement>(null);

  const [footNoteValue, setFootNoteValue] = useState(data.title);
  const [isShowInput, setShowInput] = useState(false);

  const handleClick = (e: any) => {
    if (footNotesRef.current?.contains(e.target)) {
      return;
    }
    setShowInput(false);
    if (changeValue && editMode) {
      changeValue(id, footNoteValue, false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const onClickEditFootNotes = () => {
    setShowInput(true);
    changeValue(id, footNoteValue, true);
  };

  const onChangeFooterTextValue = (event: any) => {
    setFootNoteValue(event.target.value);
  };

  const onKeyUp = (keyCode: any) => {
    if (keyCode === 13) {
      changeValue(id, footNoteValue, false);
      setShowInput(false);
    }
  };

  if (previewMode) {
    return (
      <div className={styles.previewContainer}>
        <div className={styles.title}>{footNoteValue}</div>
      </div>
    );
  }

  return !isShowInput ? (
    <div className={styles.container} onClick={onClickEditFootNotes}>
      <div className={styles.title}>{footNoteValue}</div>
    </div>
  ) : (
    <div className={classnames(styles.editWrapper, isEdgeAdminView && 't23-admin-theme')} ref={footNotesRef} id={id}>
      <div className={styles.editContainer}>
        <input
          className={styles.input}
          value={footNoteValue}
          onChange={(e) => onChangeFooterTextValue(e)}
          onKeyUp={(e) => onKeyUp(e.keyCode)}
          autoFocus
        />
      </div>
    </div>
  );
};

export default FootNotesTextWidget;
