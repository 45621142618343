import { useState } from 'react';

import { AppointmentSlots } from 'interfaces/Schedule/Appointment';

import styles from './FreeBusyEvent.module.scss';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { Link } from 'react-router-dom';
import { updateAppointmentFreeBusyToggle } from 'utils/http/appointment';
import { useGetAccessToken } from 'utils/hooks/token';
import { notification } from 'antd';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import classNames from 'classnames';

interface FreeBusyEventProps {
  appointment: AppointmentSlots;
  className?: string;
  onToggleFreeBusy: () => void;
}

const FreeBusyEvent = ({ appointment, className, onToggleFreeBusy }: FreeBusyEventProps) => {
  const [freeBusy, setFreeBusy] = useState(appointment.type === 'free');
  const [isFreeBusyTogglePending, setIsFreeBusyTogglePending] = useState(false);
  const { token } = useGetAccessToken();
  const { CALENDAR } = useRoutesGenerator();
  const { isEdgeAdminOrReceptionistView, isEdgeUserView } = useGetAccountPackageView();
  const { auth0ClinicianId } = useGetClinicianId();

  const handleOnFreeBusyToggle = async () => {
    setIsFreeBusyTogglePending(true);
    try {
      setFreeBusy(!freeBusy);
      await updateAppointmentFreeBusyToggle(token, appointment._id, { asAdmin: isEdgeAdminOrReceptionistView });
      setIsFreeBusyTogglePending(false);
      notification.success({
        message: `Successfully toggled free-busy status.`
      });
      onToggleFreeBusy();
    } catch {
      setIsFreeBusyTogglePending(false);

      notification.error({
        message: `Something went wrong while trying to toggle free-busy.`
      });
    }
  };

  const isAllowedToEditEvent =
    isEdgeUserView || appointment.clinicianId === auth0ClinicianId || appointment.clinicianId === '';

  return (
    <div className={classNames(styles.container, className)}>
      {appointment.title && <div className={styles.title}>{appointment.title}</div>}
      <div className={styles.toggleContainer}>
        <div className={styles.label}>{isAllowedToEditEvent && 'YOUR '}SCHEDULE MARKED AS</div>
        {isAllowedToEditEvent ? (
          <div className={styles.toggle}>
            <ToggleSwitch
              id={appointment._id}
              checkLabel={'FREE'}
              unCheckLabel={'BUSY'}
              isCheckedProps={freeBusy}
              onChangeProps={handleOnFreeBusyToggle}
              disabled={isFreeBusyTogglePending}
            />
          </div>
        ) : (
          <div className={styles.freeBusyStatus}>{freeBusy ? 'FREE' : 'BUSY'}</div>
        )}
      </div>
      {!isAllowedToEditEvent && !appointment.isActivity && (
        <>
          <div className={styles.profileNameContainer}>
            <div className={styles.label}>EVENT FROM</div>
            <div className={styles.profileName}>{appointment.integration?.profileName}</div>
          </div>
          <div className={styles.footerContainer}>
            <Link to={CALENDAR.SETTINGS_CLD_LINKING} className={styles.link}>
              Calendar connection settings <i className={`material-icons`}>arrow_forward_ios</i>
            </Link>
            <div className={styles.freeBusyDisclaimer}>
              This appointment is reserved in a different connected workspace. To view or edit, switch to that specific
              workspace.
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FreeBusyEvent;
