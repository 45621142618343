import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import styles from './OptionItem.module.scss';
import CheckBox from 'components/CheckBox/CheckBox';

export interface SubMenuItem {
  value: string;
  label: string;
  disabled?: boolean;
  subLabel?: ReactNode;
}

export interface IOptionItem {
  value: string;
  label: string;
  subLabel?: string;
  icon?: string;
  tooltip?: string;
  subMenu?: SubMenuItem[];
  selected?: boolean;
  disabled?: boolean;
  itemFlag?: string;
  iconClass?: string;
  iconVariant?: string;
}

interface OptionItemProps {
  id?: string;
  item: IOptionItem;
  onSelect: (value: string) => void;
  isSelected?: boolean;
  iconClassName?: string;
  customItem?: ReactNode;
  className?: string;
  isSelectedItem?: boolean;
  selectedValue?: string | string[];
  showCheckBox?: boolean;
}

const OptionItem = ({
  item,
  onSelect,
  isSelected,
  id,
  iconClassName,
  customItem,
  isSelectedItem,
  className,
  selectedValue,
  showCheckBox
}: OptionItemProps) => {
  const itemId = id ? id : item.value;
  const { value, label, subLabel, icon, subMenu, disabled, iconClass, iconVariant } = item;

  const [isHovered, setIsHover] = useState(false);
  const [top, setTop] = useState(0);

  const handleMouseEnter = () => {
    setIsHover(true);
    const parentComponent = document.getElementById('renderOptionLists');
    const childComponent = document.getElementById(itemId);

    if (parentComponent && childComponent) {
      setTop(childComponent.getBoundingClientRect().top - parentComponent.getBoundingClientRect().top - 8);
    }
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div
      id={itemId}
      className={classNames(
        styles.container,
        isSelected && styles.selected,
        isSelectedItem && styles.selectedItem,
        className,
        disabled && styles.disabled
      )}
      onClick={() => !disabled && !subMenu && onSelect(value)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showCheckBox && (
        <CheckBox
          id={item.value}
          useT23style
          value={!!isSelected}
          onChange={(value) => !disabled && onSelect(value.target.id)}
        />
      )}
      {customItem ? (
        customItem
      ) : (
        <>
          <div className={styles.label}>
            <div>{label}</div>
            {subLabel ? ` ${subLabel}` : ''}
          </div>
          {subMenu && (
            <div className={styles.subMenuContainer}>
              <i className={`material-icons-outlined ${styles.navIcon}`}>navigate_next</i>
              <div hidden={!isHovered} className={styles.subMenu} style={{ top }}>
                {subMenu.map((subMenuItem, index) => (
                  <div
                    key={index}
                    className={classNames(
                      styles.label,
                      (selectedValue === subMenuItem.value || selectedValue?.includes(subMenuItem.value)) &&
                        styles.selected,
                      subMenuItem.disabled && styles.disabled
                    )}
                    onClick={() => !subMenuItem.disabled && subMenuItem.value && onSelect(subMenuItem.value)}
                  >
                    {subMenuItem.label}
                    {subMenuItem.subLabel}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {icon && (
        <i className={classNames(iconVariant ?? 'material-icons', styles.icon, iconClassName, iconClass)}>{icon}</i>
      )}
    </div>
  );
};

export default OptionItem;
