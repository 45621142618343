import { useState } from 'react';
import styles from './ProCareAdSignUpCompletion.module.scss';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { Field, FieldProps, Form, Formik } from 'formik';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import { object, SchemaOf, string } from 'yup';
import { config } from 'config/config';
import { salutationOptions } from 'pages/SignUp/components/AboutYou/AboutYou';
import FormikRadio from 'components/Radio/FormikRadio';
import TermsAndConditions from 'pages/SignUp/components/PracticeData/components/TermsAndConditions';
import Switch from 'components/Switch/Switch';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import SubmitButton from 'pages/SignUp/components/SubmitButton/SubmitButton';
import { notification } from 'antd';
import { useGetUnclaimedAdSignUp } from './useGetUnclaimedAdSignUp';
import { claimAdSignUp } from 'utils/http/ClinicianProfileService/Profile/profile';
import { security } from 'utils/security';

const initialValues = {
  title: '',
  'title-others': '',
  mobileNumber: '',
  isEmailCopyRequired: true
};

const { mobileNumberMinLength, countryCode: PREFERRED_COUNTRY } = config;

const validationSchema = object<Parameters<SchemaOf<typeof initialValues>['shape']>[0]>().shape({
  title: string().required('Please select your title'),
  'title-others': string().when('title', {
    is: (title: string) => title === 'other',
    then: string().required('Please tell us how we should address you'),
    otherwise: string()
  }),
  mobileNumber: string()
    .required('Please enter your mobile number')
    .min(mobileNumberMinLength, `Mobile number has to be at least ${mobileNumberMinLength} characters`)
});

const ProCareAdSignUpCompletion = () => {
  const { isLoading } = useGetUnclaimedAdSignUp();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: typeof initialValues) => {
    setIsSubmitting(true);

    try {
      const title = values.title === 'other' ? values['title-others'] : values.title;

      const token = await security.getAccessTokenSilently();
      await claimAdSignUp(token, {
        mobileNumber: values.mobileNumber,
        title,
        isEmailCopyRequired: values.isEmailCopyRequired
      });

      window.location.assign('/');
      setTimeout(() => window.location.reload(), 100);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while creating your account. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return isLoading ? (
    <div className={styles.loading}>
      <LoadingCircle />
    </div>
  ) : (
    <>
      {isSubmitting && (
        <div className={styles.submitting}>
          <LoadingCircle />
        </div>
      )}
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, handleChange }) => (
          <Form>
            <ContentLayout className={styles.container}>
              <div>
                <div className={styles.title}>Welcome to Tacklit</div>
                <div className={styles.heading}>Please fill in the following details and accept the Terms of Use</div>
              </div>
              <div>
                <FormikRadio
                  label="How should we address you?"
                  radioLabelClassName={styles.radioLabel}
                  variant="button"
                  name="title"
                  options={salutationOptions}
                  hasOthers
                  noSpacing
                />
              </div>
              <Field name="mobileNumber">
                {({ field, form, meta }: FieldProps) => (
                  <div className={styles.mobileNumber}>
                    <span className={styles.label}>Mobile Number</span>
                    <PhoneInput
                      inputClass={styles.mobileNumberInput}
                      buttonClass={styles.countrySelect}
                      dropdownClass={styles.countryDropdown}
                      inputProps={{ onBlur: field.onBlur, name: field.name }}
                      value={values.mobileNumber}
                      onChange={(value) => form.setFieldValue('mobileNumber', value)}
                      {...(PREFERRED_COUNTRY && {
                        country: PREFERRED_COUNTRY,
                        preferredCountries: [PREFERRED_COUNTRY]
                      })}
                    />
                    <ErrorMessage error={meta.error} visible={meta.touched} />
                  </div>
                )}
              </Field>
              <div className="divider" />
              <div className={styles.terms}>
                <span className="title">We treat Data Security and Privacy Compliance as first class concerns</span>
                <span className="heading">
                  We know how critical it is for you to have confidence and peace of mind that all your important
                  information is handled correctly.
                  <br />
                  <br />
                  We use leading security technology, practices and procedures in how we collect, process and manage all
                  data on our platform.
                </span>
                <div className="divider" />
                <div className={styles.plainEnglish}>
                  <span className={`sectionHeader ${styles.fullVersion}`}>Edge Platform Terms of Use</span>
                </div>
                <div className="divider" />
                <TermsAndConditions />
                <div className={styles.emailCopy}>
                  Email me a copy of the policy{' '}
                  <Switch
                    className={styles.switch}
                    checked={values.isEmailCopyRequired}
                    onChange={handleChange('isEmailCopyRequired')}
                    name="isEmailCopyRequired"
                  />
                </div>
              </div>
              <SubmitButton variant="primary" type="submit">
                Accept terms
              </SubmitButton>
            </ContentLayout>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProCareAdSignUpCompletion;
