import { Col, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import { NEW_ASSESSMENT_TEMPORARY_ID } from '../../../AssessmentDetails/OnBoardingDetails/OnBoardingDetails';
import styles from './AssessmentHeader.module.scss';
import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

export interface AssessmentHeaderProps {
  onSendAdhocAssessmentClick?: () => void;
  disableSendAdhocAssessmentButton?: boolean;
}

const AssessmentHeader = ({
  onSendAdhocAssessmentClick = () => {},
  disableSendAdhocAssessmentButton
}: AssessmentHeaderProps) => {
  const navigate = useNavigate();
  const { isInvoiceTemplateEnabled, isCarePlanEnabled } = useGetFeatureToggle();
  const { FORMS } = useRoutesGenerator();
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView, isEdgeUser, isEdgeReceptionistView } =
    useGetAccountPackageView();
  const [t] = useTranslation();

  const node = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const setOpen = (val: boolean) => {
    setShowMenu(val);
  };

  const onSelectNewFa = () => {
    navigate(`${FORMS.BASE}/survey/${NEW_ASSESSMENT_TEMPORARY_ID}`);
  };

  const onSelectNewCa = () => {
    navigate(`${FORMS.BASE}/microCheckIn/newMCI`);
  };

  const onHandleNewConsentForm = () => {
    navigate(`${FORMS.BASE}/consent/new`);
  };

  const onHandleNewCaseNoteTemplate = () => {
    navigate(`${FORMS.BASE}/caseNotes/new`);
  };

  const onHandleNewCarePathway = () => {
    navigate(`${FORMS.BASE}/carePathway/new`);
  };

  const onHandleNewLetterTemplate = () => {
    navigate(FORMS.NEW_LETTER_TEMPLATE);
  };

  const onHandleNewReportTemplate = () => {
    navigate(FORMS.NEW_REPORT_TEMPLATE);
  };

  const onSelectInvoiceTemplate = () => {
    navigate(`${FORMS.NEW_INVOICE_TEMPLATE_EDITOR}`);
  };

  const showOAc = isEdgeAdminView || isNormalUserView;
  const showMCIc = isEdgeUserView || isNormalUserView;
  const showConsent = isEdgeAdminView || isNormalUserView;
  const showCaseNote = isEdgeAdminView || isNormalUserView;
  const showInvoiceTemplate = isInvoiceTemplateEnabled && (isEdgeAdminView || isEdgeReceptionistView);
  const showCarePathway = isCarePlanEnabled && (isEdgeAdminView || isNormalUserView);

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{isEdgeUser ? 'Templates' : 'Templates & Micro Check-Ins'}</div>
        </div>
      </div>
      <Row gutter={16}>
        <Col>
          <div ref={node} className={styles.rightSection}>
            <ButtonAlt onClick={() => setOpen(!showMenu)} id={'createNewTemplateId'} icon={'add_circle_outline'}>
              Create new template
            </ButtonAlt>
            <div className={styles.menuWrapper}>
              <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
                {showOAc && (
                  <div className={styles.listBox} onClick={onSelectNewFa}>
                    <div className={styles.listTitle}>{t('assessment_table.survey')}</div>
                    <div className={styles.listDesc}>{t('assessment_table.survey.description')}</div>
                  </div>
                )}
                {showMCIc && (
                  <div className={styles.listBox} onClick={onSelectNewCa}>
                    <div className={styles.listTitle}>{t('assessment_table.micro_checkin')}</div>
                    <div className={styles.listDesc}>{t('assessment_table.micro_checkin.description')}</div>
                  </div>
                )}
                {showConsent && (
                  <div className={styles.listBox} onClick={onHandleNewConsentForm}>
                    <div className={styles.listTitle}>{t('assessment_table.consent_form')}</div>
                    <div className={styles.listDesc}>{t('assessment_table.consent_form.description')}</div>
                  </div>
                )}
                {showCaseNote && (
                  <div className={styles.listBox} onClick={onHandleNewCaseNoteTemplate}>
                    <div className={styles.listTitle}>Case Note</div>
                    <div className={styles.listDesc}>
                      Capture client notes for initial assessments, follow-up sessions, different conditions or
                      different types of clients; to keep track of client progress.
                    </div>
                  </div>
                )}
                {showCarePathway && (
                  <div className={styles.listBox} onClick={onHandleNewCarePathway}>
                    <div className={styles.listTitle}>Care Pathway</div>
                    <div className={styles.listDesc}>
                      Capture client notes for initial assessments, follow-up sessions, different conditions or
                      different types of clients; to keep track of client progress.
                    </div>
                  </div>
                )}
                <div className={styles.listBox} onClick={onHandleNewLetterTemplate}>
                  <div className={styles.listTitle}>Letter</div>
                  <div className={styles.listDesc}>
                    Best for simpler, text oriented documents. Word processor style controls.
                  </div>
                </div>
                <div className={styles.listBox} onClick={onHandleNewReportTemplate}>
                  <div className={styles.listTitle}>Report</div>
                  <div className={styles.listDesc}>
                    Use our drag-and-drop canvas for complex document layouts. Reports are great to utilise incorporated
                    graphs and measures, and require approval flows / collaboration.
                  </div>
                </div>
                {showInvoiceTemplate && (
                  <div className={styles.listBox} onClick={onSelectInvoiceTemplate}>
                    <div className={styles.listTitle}>Invoice Template</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>
        {!isEdgeReceptionistView && (
          <Col id={'sendAdhocBtnId'}>
            <div data-tooltip-id="send-adhoc-button-tooltip-ref">
              <ButtonAlt
                onClick={() => !disableSendAdhocAssessmentButton && onSendAdhocAssessmentClick()}
                disabled={disableSendAdhocAssessmentButton}
                id={'sendAdhocButton'}
                variant={'outlined'}
                icon={'send'}
                iconVariant={IconVariant.Filled}
              >
                {t('assessment_table.send_client_ad_hoc_assessment')}
              </ButtonAlt>
            </div>
            <Tooltip
              id="send-adhoc-button-tooltip-ref"
              place={'bottom-start'}
              className={styles.tooltip}
              content={'Sending adhoc assessments is disabled by the notification settings.'}
              hidden={!disableSendAdhocAssessmentButton}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AssessmentHeader;
