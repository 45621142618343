import { useState } from 'react';
import styles from './profileStatusButtons.module.scss';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import { RecordStatus } from 'interfaces/Clients/clientsRecord';

interface ProfileStatusButtonsProps {
  value: string;
  onChange: (value: 'active' | 'waitlist' | 'closed') => void;
  disableWaitlistButton?: boolean;
  noUnsavedChanges?: boolean;
}

const ProfileStatusButtons = ({
  value,
  onChange,
  disableWaitlistButton,
  noUnsavedChanges
}: ProfileStatusButtonsProps) => {
  const [clientStatus, setClientStatus] = useState(value);

  const handleConfirm = (id: string) => {
    if (id !== 'active' && id !== 'closed' && id !== 'waitlist') {
      return;
    }
    setClientStatus(id);
    onChange(id);
  };

  const toggleList = [
    {
      id: 'active',
      label: 'Active',
      isActive: clientStatus === 'active',
      color: '#219653'
    },
    ...(!disableWaitlistButton
      ? [
          {
            id: 'waitlist',
            label: 'Waitlist',
            isActive: clientStatus === 'waitlist',
            color: '#ffb946'
          }
        ]
      : []),
    {
      id: 'closed',
      label: 'Closed',
      isActive: clientStatus === 'closed',
      color: '#ef4444'
    }
  ];

  return (
    <div className={styles.statusContainer}>
      <div
        style={{
          width: `${toggleList.length * 20}%`
        }}
      >
        <ToggleSwitchV2
          id={'clientActiveStatus'}
          toggleList={toggleList}
          onChangeStatus={(value) => handleConfirm(value.id)}
          alertOnClick={`Are you sure you want to change the profile type of this client? ${
            value === RecordStatus.Closed || noUnsavedChanges ? '' : 'Any unsaved changes will be discarded.'
          }`}
        />
      </div>
    </div>
  );
};

export default ProfileStatusButtons;
