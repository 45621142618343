import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useState, useEffect } from 'react';
import SubTab, { SubTabOption } from 'components/v2/SubTab/SubTab';
import styles from '../SubTabLayout.module.scss';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

const CalendarSettingsSubTab = () => {
  const location = useLocation();
  const { isEdgePlanUser } = useGetAccountPackage();
  const { isEdgeAdminView, isEdgeUser, isNormalUserView } = useGetAccountPackageView();
  const isCalendarSettingsPage = isEdgePlanUser
    ? `${location.pathname.split('/')[2]}/${location.pathname.split('/')[3]}` === 'calendar/settings'
    : `${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}` === 'calendar/settings';
  const getFilterType: string = isCalendarSettingsPage
    ? isEdgePlanUser
      ? (location.pathname.split('/')[4] as string) ?? 'appointmentTypes'
      : (location.pathname.split('/')[3] as string) ?? 'appointmentTypes'
    : '';
  const navigate = useNavigate();
  const { CALENDAR } = useRoutesGenerator();
  const [filterType, setFilterType] = useState<string>(getFilterType);

  useEffect(() => {
    setFilterType(getFilterType);
  }, [getFilterType]);

  const calendarSettingsClickHandler = (value?: string) => {
    navigate(`${CALENDAR.BASE}/settings/${value}`);
  };

  const baseHref = `${CALENDAR.BASE}/settings`;

  const controlsSubTabList: Array<SubTabOption & { isDisplay: boolean }> = [
    { label: 'Appointment Types', value: 'appointmentTypes', isDisplay: true, href: `${baseHref}/appointmentTypes` },
    {
      label: 'Calendar Linking',
      value: 'calendarLinking',
      isDisplay: isEdgeAdminView || isEdgeUser || isNormalUserView,
      href: `${baseHref}/calendarLinking`
    },
    {
      label: 'Account Level Settings',
      value: 'accountLevelSettings',
      isDisplay: isEdgeAdminView || isNormalUserView,
      href: `${baseHref}/accountLevelSettings`
    },
    { label: 'Room Management', value: 'roomManagement', isDisplay: isEdgeUser, href: `${baseHref}/roomManagement` }
  ];

  const generateSubTab: SubTabOption[] = controlsSubTabList
    .filter((item) => item.isDisplay)
    .map((item) => {
      return { label: item.label, value: item.value, href: item.href };
    });

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <ButtonAlt
          onClick={() => navigate(`${CALENDAR.BASE}`)}
          className={styles.btn}
          variant="outlined"
          icon={'arrow_back_ios'}
          fullWidth
        >
          Back to Calendar
        </ButtonAlt>
      </div>
      <div className={styles.section}>
        <SubTab options={generateSubTab} onChange={calendarSettingsClickHandler} activeOption={filterType} />
      </div>
    </div>
  );
};

export default CalendarSettingsSubTab;
