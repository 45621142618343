import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import {
  AddressBookTab,
  resetPaging,
  selectActiveTab,
  selectFilters,
  setFilters
} from 'redux/addressBook/addressBookSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  ADDRESS_BOOK_CATEGORY_OPTIONS,
  ADDRESS_BOOK_ROLE_OPTIONS,
  ADDRESS_BOOK_TYPE_OPTIONS
} from '../../../Interfaces/AddressBook';
import styles from './AddressBookFilters.module.scss';

const AddressBookFilters = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectFilters);
  const activeTab = useAppSelector(selectActiveTab);

  const { selectedCategories, selectedTypes, selectedRoles } = filters;

  const onChangeCategoryFilter = (selectedCategories: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedCategories }));
  };

  const onChangeTypeFilter = (selectedTypes: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedTypes }));
  };

  const onChangeRoleFilter = (selectedRoles: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedRoles }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <div className={styles.filterHeading}>FILTER BY:</div>
      </div>
      <div className={styles.buttonsContainer}>
        <FilterDropdown
          id={'category'}
          menuItems={ADDRESS_BOOK_CATEGORY_OPTIONS}
          onChangeItem={onToggleFilter(ADDRESS_BOOK_CATEGORY_OPTIONS, selectedCategories, onChangeCategoryFilter)}
          searchable
          selectedFilterList={selectedCategories}
          showToggleAllButtons
          onClearFilter={() => {
            dispatch(resetPaging());
            dispatch(setFilters({ ...filters, selectedCategories: [] }));
          }}
        >
          Category
        </FilterDropdown>

        {activeTab && [AddressBookTab.Funder, AddressBookTab.Referrer].includes(activeTab) && (
          <FilterDropdown
            id={'type'}
            menuItems={ADDRESS_BOOK_TYPE_OPTIONS}
            onChangeItem={onToggleFilter(ADDRESS_BOOK_TYPE_OPTIONS, selectedTypes, onChangeTypeFilter)}
            searchable
            selectedFilterList={selectedTypes}
            showToggleAllButtons
            onClearFilter={() => {
              dispatch(resetPaging());
              dispatch(setFilters({ ...filters, selectedTypes: [] }));
            }}
          >
            Type
          </FilterDropdown>
        )}

        {activeTab && [AddressBookTab.Organisation, AddressBookTab.Individual].includes(activeTab) && (
          <FilterDropdown
            id={'role'}
            menuItems={ADDRESS_BOOK_ROLE_OPTIONS}
            onChangeItem={onToggleFilter(ADDRESS_BOOK_ROLE_OPTIONS, selectedRoles, onChangeRoleFilter)}
            searchable
            selectedFilterList={selectedRoles}
            showToggleAllButtons
            onClearFilter={() => {
              dispatch(resetPaging());
              dispatch(setFilters({ ...filters, selectedRoles: [] }));
            }}
          >
            Role
          </FilterDropdown>
        )}
      </div>
    </div>
  );
};

export default AddressBookFilters;
