import { useCallback } from 'react';
import styles from './ReferrerForm.module.scss';
import { ReferrerType } from 'pages/Referrals/interface';
import ReferrerRelativeForm from '../ReferrerRelativeForm/ReferrerRelativeForm';
import ReferrerOrganisationForm from '../ReferrerOrganisationForm/ReferrerOrganisationForm';
import ReferrerIndividualForm from '../ReferrerIndividualForm/ReferrerIndividualForm';
import {
  ReferralFieldsPayload,
  ReferralValidateField
} from 'pages/Referrals/components/AddReferralModal/AddReferralModalInterfaces';
import { referralFormValidationInitialMessage } from '../../ReferralForm';

export enum ReferralFormMode {
  Edit = 'edit',
  Add = 'add'
}

interface ReferrerFormProps {
  referralFields: ReferralFieldsPayload;
  errorMessage: ReferralValidateField;
  referrerType: ReferrerType;
  mode: ReferralFormMode;
  onChange: (key: string, val: string, keyType?: string) => void;
  isIndividual: boolean;
  setIsIndividual: (val: boolean) => void;
}

const ReferrerForm = ({
  referralFields,
  errorMessage,
  referrerType,
  onChange,
  mode,
  isIndividual,
  setIsIndividual
}: ReferrerFormProps) => {
  const renderFormByReferrerType = useCallback(() => {
    switch (referrerType) {
      case ReferrerType.FriendFamily:
        return (
          <ReferrerRelativeForm
            referrerFields={referralFields.referrer}
            errorMessage={errorMessage.referrer}
            onChange={onChange}
          />
        );
      case ReferrerType.Organisation:
        return (
          <ReferrerOrganisationForm
            organisationFields={referralFields.org || referralFormValidationInitialMessage.org}
            errorMessage={errorMessage.org}
            individualFields={referralFields.referrer}
            individualErrorMessage={errorMessage.referrer}
            onChange={onChange}
            mode={mode}
            isIndividual={isIndividual}
            setIsIndividual={setIsIndividual}
          />
        );
      case ReferrerType.Professional:
        return (
          <ReferrerIndividualForm
            referrerFields={referralFields.referrer}
            errorMessage={errorMessage.referrer}
            onChange={onChange}
            showSearch
            mode={mode}
          />
        );
      default:
        return null;
    }
  }, [referralFields, errorMessage, referrerType, mode, onChange, isIndividual, setIsIndividual]);

  return <div className={styles.container}>{renderFormByReferrerType()}</div>;
};

export default ReferrerForm;
