import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import ConsentContent from './components/ConsentContent/ConsentContent';

import styles from './ConsentForm.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

const ConsentForm = () => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();

  const backBtn = () => {
    navigate(FORMS.BASE);
  };

  return (
    <HelmetWrapper title={'Tacklit - Consent form'}>
      <ContentLayout>
        <div className={styles.headerContainer}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>Consent Form Editor</div>
            <div className={styles.backBtnWrapper}>
              <ButtonAlt size={'medium'} variant={'text'} onClick={backBtn} icon={'arrow_back_ios'}>
                To Template List
              </ButtonAlt>
            </div>
          </div>
        </div>
        <ConsentContent />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ConsentForm;
