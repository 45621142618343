export interface CaseNoteFormatSettingsFields {
  _id?: string;
  name: string;
  key?: string;
  type: 'date' | 'link' | 'multipleChoice' | 'multipleChoiceCount' | 'number' | 'text' | 'time' | 'checkbox' | 'cdf';
  linkType?: string;
  commonChoices?: { label: string; options?: { label: string; value: string }[]; value?: string }[];
  customCommonChoices?: { label: string; options?: { label: string; value: string }[]; value?: string }[];
  customChoices?: { label: string; options?: { label: string; value: string }[]; value?: string }[];
  choiceType?: 'common' | 'custom';
  multiple?: boolean;
  active: boolean;
  editable: boolean;
  mandatory: boolean;

  // only exists if type: 'cdf'
  categoryId?: string;
  variableId?: string;
}

export enum AssignmentMode {
  All = 'all',
  Practice = 'practice',
  Selected = 'selected'
}

export enum ParticipantType {
  Individual = 'individual',
  Group = 'group',
  Both = 'all'
}

export interface CaseNoteTemplate {
  _id?: string;
  name: string;
  description: string;
  isPublished: boolean;
  isDefault?: boolean;
  participantType: ParticipantType;
  assignmentMode: AssignmentMode;
  selectedPractitioners: string[]; // array of clinicianAuth0Ids
  fields: CaseNoteFormatSettingsFields[];
  headings: string;
  createdAt?: string;
  createdBy?: string;
  accountId?: string;
  updatedAt?: string;
}

export interface CaseNotesTemplatesList {
  _id: string;
  name: string;
  isPublished: boolean;
  isDefault: boolean;
  participantType: ParticipantType;
  assignmentMode: AssignmentMode;
  createdAt: string;
}
