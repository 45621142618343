import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ClientSelect from 'components/v2/ClientSelect/ClientSelect';
import { ClientRecordType, clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useTranslation } from 'react-i18next';

import Label from '../../../../../../../../../components/Label/Label';
import styles from './ClientSection.module.scss';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

interface ClientSectionProps {
  clientProfiles: clientProfilesInterface[];
  recordType: ClientRecordType;
  readOnly: boolean;
  selectedClient: clientRecordsInterface;
  clientValidation?: boolean;
  onSelectClient: (selectedClient: clientRecordsInterface, b?: boolean) => void;
}

const ClientSection = ({
  clientProfiles,
  recordType,
  readOnly,
  selectedClient,
  clientValidation,
  onSelectClient
}: ClientSectionProps) => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { CLIENTS } = useRoutesGenerator();

  return (
    <div className={styles.container}>
      <Label label={'ATTACHED TO'} />
      {readOnly ? (
        <div
          className={styles.clientAvatarContainer}
          onClick={() => navigate(`${CLIENTS.BASE}/${selectedClient?._id}/profile`)}
        >
          <ClientAvatar
            clientData={clientProfiles}
            avatarSize={40}
            initialsClassName={styles.initialName}
            nameClassName={styles.clientName}
            horizontal
            displayFirstNameOnly={recordType === ClientRecordType.Couple}
            displayLimit={recordType === ClientRecordType.Couple ? 2 : 1}
            fullAvatarBg
          />
        </div>
      ) : (
        <>
          <ClientSelect
            onSelect={onSelectClient}
            selectedClientRecords={selectedClient}
            confirmChangeMessage={t('form.confirm.switch_client_on_report')}
          />
          {clientValidation && <div className={styles.error}>{t('label.please_select_client')}</div>}
        </>
      )}
    </div>
  );
};

export default ClientSection;
