import { useEffect, useState } from 'react';
import { clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import { getAllClientEncryptedDataByClientRecordId } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';

export const useFetchClientDetails = (token: string, recordId?: string) => {
  const [clientEncryptDetails, setClientEncryptDetails] = useState<clientRecordsEncryptedInterface>();
  const [clientEncryptDetailsLoading, setClientEncryptDetailsLoading] = useState(true);
  const [t] = useTranslation();

  useEffect(() => {
    if (token && recordId) {
      const fetchClientEncryptDetails = async () => {
        setClientEncryptDetailsLoading(true);
        try {
          const getClientEncryptResponses = await getAllClientEncryptedDataByClientRecordId(token, recordId);
          const clientRecordData = (await getClientEncryptResponses.json()) as clientRecordsEncryptedInterface;

          const clientDetailsRes: clientRecordsEncryptedInterface = {
            ...clientRecordData,
            isCompleteRecords:
              clientRecordData.clientProfiles[0].profileType === 'full' &&
              !!clientRecordData.clientProfiles[0].clientAuth0Id,
            allowCommunicationWithEmail: clientRecordData.clientProfiles.some(
              (profileObj) =>
                profileObj.role !== 'child' &&
                profileObj.isPrimaryContact &&
                canClientReceiveEmail({
                  communicationPreference: profileObj.communicationPreference
                }) &&
                profileObj.hasEmail
            ),
            allowCommunicationWithSms: clientRecordData.clientProfiles.some(
              (profileObj) =>
                profileObj.role !== 'child' &&
                profileObj.isPrimaryContact &&
                canClientReceiveSms({
                  communicationPreference: profileObj.communicationPreference
                }) &&
                profileObj.hasMobileNumber
            )
          };

          setClientEncryptDetails(clientDetailsRes);
        } catch (ex) {
          notification.error({ message: t('form.error.failed_to_retrieve_this_client_detail') });
        }
        setClientEncryptDetailsLoading(false);
      };

      fetchClientEncryptDetails();
    }
  }, [recordId, token, t]);

  return { clientEncryptDetails, clientEncryptDetailsLoading };
};
