import classnames from 'classnames';
import Select, { SelectProps } from '../CommonSelect/Select';

import styles from './MaterialSelect.module.scss';
import { CSSProperties } from 'react';

export interface MaterialSelectProps extends SelectProps {
  id?: string;
  label: string;
  isSearchable: boolean;
  value: string | undefined;
  onChange: (value: any) => void;
  loading?: boolean;
  boldValue?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
  borderless?: boolean;
  whiteSpace?: 'normal' | 'nowrap';
  menuStyle?: CSSProperties;
}

const MaterialSelect = ({
  id,
  className,
  label,
  isSearchable,
  value,
  onChange,
  loading,
  boldValue,
  isError,
  isDisabled,
  borderless,
  whiteSpace = 'normal',
  menuStyle = {},
  ...props
}: MaterialSelectProps) => {
  return (
    <Select
      {...props}
      id={id}
      className={className}
      label={label}
      labelClass={classnames(styles.selectLabel, props.labelClass)}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      smallCaretDown
      styles={{
        container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
        valueContainer: (base: any) => ({
          ...base,
          minHeight: '30px',
          padding: '0 8px 0 0',
          cursor: 'pointer',
          fontWeight: boldValue ? 'bold' : 'normal'
        }),
        option: (menuBase: any) => ({
          ...menuBase,
          cursor: 'pointer'
        }),
        control: (controlBase: any) => ({
          ...controlBase,
          minHeight: '30px',
          backgroundColor: 'transparent',
          border: 'none',
          borderBottom: borderless ? 'unset' : isError ? '1px solid #ff4d4f' : `1px solid ${styles.greyColor}`,
          borderRadius: 0,
          boxShadow: 'none'
        }),
        singleValue: (base) => ({
          ...base,
          whiteSpace: whiteSpace,
          position: 'initial',
          transform: 'none'
        }),
        input: (base) => ({
          ...base,
          position: 'absolute'
        }),
        placeholder: (base) => ({
          ...base,
          color: '#414449',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '21px'
        }),
        menu: (base) => ({
          ...base,
          ...menuStyle
        })
      }}
      value={value}
      onChange={(e: any) => onChange(e?.value)}
      loading={loading}
    />
  );
};

export default MaterialSelect;
