import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import {
  NotificationSettingsInterface,
  NotificationPreference
} from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

export const canClientReceiveCommunication = ({
  communicationPreference,
  notificationSettings,
  notificationSettingsCategory
}: {
  communicationPreference?: CommunicationPreference;
  notificationSettings?: NotificationSettingsInterface;
  notificationSettingsCategory?: keyof NotificationSettingsInterface;
}) => {
  const isAllowedByCommunicationPreference =
    !!communicationPreference && communicationPreference !== CommunicationPreference.None;

  if (!notificationSettings || !notificationSettingsCategory) {
    return isAllowedByCommunicationPreference;
  }

  const isAllowedByNotificationSettings =
    notificationSettings[notificationSettingsCategory] !== NotificationPreference.None;

  return isAllowedByNotificationSettings && isAllowedByCommunicationPreference;
};

export const canClientReceiveEmail = ({
  communicationPreference,
  notificationSettings,
  notificationSettingsCategory
}: {
  communicationPreference?: CommunicationPreference;
  notificationSettings?: NotificationSettingsInterface;
  notificationSettingsCategory?: keyof NotificationSettingsInterface;
}) => {
  const isEmailAllowedByCommunicationPreference =
    !!communicationPreference &&
    [CommunicationPreference.NoPreference, CommunicationPreference.Email].includes(communicationPreference);

  if (!notificationSettings || !notificationSettingsCategory) {
    return isEmailAllowedByCommunicationPreference;
  }

  const isEmailAllowedByNotificationSettings = [NotificationPreference.Email, NotificationPreference.All].includes(
    notificationSettings[notificationSettingsCategory]
  );
  return isEmailAllowedByNotificationSettings && isEmailAllowedByCommunicationPreference;
};

export const canClientReceiveSms = ({
  communicationPreference,
  notificationSettings,
  notificationSettingsCategory
}: {
  communicationPreference?: CommunicationPreference;
  notificationSettings?: NotificationSettingsInterface;
  notificationSettingsCategory?: keyof NotificationSettingsInterface;
}) => {
  const isSmsAllowedByCommunicationPreference =
    !!communicationPreference &&
    [CommunicationPreference.NoPreference, CommunicationPreference.SMS].includes(communicationPreference);

  if (!notificationSettings || !notificationSettingsCategory) {
    return isSmsAllowedByCommunicationPreference;
  }

  const isSmsAllowedByNotificationSettings = [NotificationPreference.SMS, NotificationPreference.All].includes(
    notificationSettings[notificationSettingsCategory]
  );
  return isSmsAllowedByNotificationSettings && isSmsAllowedByCommunicationPreference;
};
