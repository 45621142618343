import { ClinicianMedicareRole, PracticeInterface, ProfileInterface } from 'interfaces/Profile/Profile';
import { DeliveryType } from 'interfaces/Schedule/AppointmentType';
import {
  MedicareItemDuration,
  MedicareItemFormat,
  MedicareItemMode
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/interfaces';
import { MBS_CODE_ITEMS } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/mbsItems';
import { Recipient } from 'redux/invoiceTemplate/invoiceTemplateCreateFormSlice';

export interface ClinicianDetails {
  clinician: {
    avatar: string;
    email: string;
    mobileNumber: string;
    name: string;
  };
  practice?: PracticeInterface & { address?: string };
}

export const mapClinicianDetails = (clinician: ProfileInterface): ClinicianDetails => {
  const { avatar, name, practice, title, email, mobileNumber } = clinician;

  return {
    clinician: {
      avatar: avatar || '',
      email,
      mobileNumber: mobileNumber || '',
      name: `${title || ''}${name ? ` ${name}` : ''}`
    },
    practice: practice
      ? {
          ...practice,
          address: practice.locations?.[0]
        }
      : undefined
  };
};

export const addOrRemoveFromList = ({ list, target }: { list: string[]; target: string }) => {
  const isExisted = list.includes(target);
  return isExisted ? list.filter((item) => item !== target) : [...list, target];
};

export const addOrRemoveFromRecipients = ({ list, target }: { list: Recipient[]; target: { type: string } }) => {
  const isExisted = list.some(({ type }) => type === target.type);
  return isExisted ? list.filter(({ type }) => type !== target.type) : [...list, target];
};

export const addOrRemoveSelectedKeyClientContacts = ({
  list,
  selectedTags,
  selectedType
}: {
  list: Recipient[];
  selectedTags: string[];
  selectedType: string;
}) => {
  const updatedList = list.filter((item) => item.type !== selectedType);
  return selectedTags.length
    ? [
        ...updatedList,
        {
          type: selectedType,
          tags: selectedTags
        }
      ]
    : updatedList;
};

export const mbsItemLookup = ({
  deliveryType,
  duration,
  clinicianRole
}: {
  deliveryType: DeliveryType;
  duration: number;
  clinicianRole?: ClinicianMedicareRole;
}) => {
  const mbsItems = MBS_CODE_ITEMS[clinicianRole || ClinicianMedicareRole.ClinicalPsychologists];
  const serviceDurationType = [
    ...(duration > 20 ? [MedicareItemDuration.OneToOneMoreThan20] : [MedicareItemDuration.OneToOneLessThan20]),
    ...(duration > 30 ? [MedicareItemDuration.OneToOneMoreThan30] : []),
    ...(duration < 40 ? [MedicareItemDuration.OneToOneLessThan40] : [MedicareItemDuration.OneToOneMoreThan40]),
    ...(duration > 45 ? [MedicareItemDuration.OneToOneMoreThan45] : []),
    ...(duration < 50 ? [MedicareItemDuration.OneToOneLessThan50] : [MedicareItemDuration.OneToOneMoreThan50])
  ];
  const serviceMode =
    deliveryType === DeliveryType.FaceToFace
      ? [MedicareItemMode.FaceToFace]
      : deliveryType === DeliveryType.VideoCall
      ? [MedicareItemMode.VideoCall, MedicareItemMode.Telehealth]
      : [DeliveryType.PhoneCall, DeliveryType.PhoneCallDialClient].includes(deliveryType)
      ? [MedicareItemMode.PhoneCall, MedicareItemMode.Telehealth]
      : [
          MedicareItemMode.Any,
          MedicareItemMode.FaceToFace,
          MedicareItemMode.PhoneCall,
          MedicareItemMode.VideoCall,
          MedicareItemMode.Telehealth
        ];

  return (
    mbsItems.find(
      ({ duration, format, mode }) =>
        format === MedicareItemFormat.OneToOne && serviceDurationType.includes(duration) && serviceMode.includes(mode)
    ) ||
    mbsItems.find(
      ({ duration, format, mode }) =>
        (format === MedicareItemFormat.OneToOne &&
          serviceDurationType.includes(duration) &&
          mode === MedicareItemMode.Any) ||
        (duration === MedicareItemDuration.Any && serviceMode.includes(mode))
    )
  );
};
