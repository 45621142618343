export interface Pagination {
  page: number;
  perPage: number;
  totalItem: number;
  totalPage: number;
}

export enum SortOrder {
  Ascending = 'ascending',
  Descending = 'descending'
}
