import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import {
  selectAbandonedPaging,
  selectActiveTab,
  selectFilters,
  selectRejectedPaging,
  selectRejectedWriteOffSorting,
  selectSorting,
  selectSubmittedPaging,
  selectSuccessPaging,
  selectWriteOffPaging,
  SubmittedClaimFilters,
  SubmittedClaimTabs
} from './submittedClaimSlice';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useAppSelector } from 'redux/hooks';
import { useGetSubmittedClaimListQuery } from 'redux/endpoints/billingServices/submittedClaim';
import { Paging, Sorting, SortType } from '../utils';
import {
  FilterStatusAndClaimStatusMapping,
  GetSubmittedClaimListQuery,
  RejectedClaimStatuses,
  SubmittedClaimStatuses,
  SuccessClaimStatuses,
  WriteOffClaimStatuses
} from 'interfaces/invoices/submittedClaim';
import {
  ClaimStatus,
  ClaimType
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';
import { FilterRadioItem } from 'components/T23/FilterRadioDropdown/FilterRadioDropdown';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

const getSubmittedClaimStatusQuery = ({
  tab,
  submittedStatus,
  successStatus
}: {
  tab: SubmittedClaimTabs;
  submittedStatus?: FilterRadioItem;
  successStatus?: FilterRadioItem;
}) => {
  switch (tab) {
    case SubmittedClaimTabs.Submitted: {
      if (submittedStatus?._id) {
        return FilterStatusAndClaimStatusMapping[submittedStatus._id as keyof typeof FilterStatusAndClaimStatusMapping];
      }
      return SubmittedClaimStatuses.join(',');
    }

    case SubmittedClaimTabs.Success: {
      if (successStatus?._id) {
        return successStatus._id === 'Success'
          ? [ClaimStatus.MedicareAssessed, ClaimStatus.WithMedicare].join(',')
          : FilterStatusAndClaimStatusMapping[successStatus._id as keyof typeof FilterStatusAndClaimStatusMapping];
      }
      return SuccessClaimStatuses.join(',');
    }

    case SubmittedClaimTabs.Rejected: {
      return RejectedClaimStatuses.join(',');
    }

    case SubmittedClaimTabs.WriteOff: {
      return WriteOffClaimStatuses.join(',');
    }

    default: {
      return undefined;
    }
  }
};

export const getSubmittedClaimListQuery = (
  paging: Paging,
  sorting: Sorting,
  rejectedWriteOffSorting: Sorting,
  filters: SubmittedClaimFilters,
  isEdgeUserView: boolean,
  tab: SubmittedClaimTabs
): GetSubmittedClaimListQuery => {
  const { page, perPage } = paging;
  const { sortBy, sortType } = sorting;
  const { sortBy: rejectedWriteOffSortBy, sortType: rejectedWriteOffSortType } = rejectedWriteOffSorting;
  const {
    clients,
    practitioners,
    groups,
    claimType,
    code,
    submittedStatus,
    successStatus,
    claimedAfter,
    claimedBefore,
    claimId
  } = filters;

  const statusQuery = getSubmittedClaimStatusQuery({ tab, submittedStatus, successStatus });

  return {
    page,
    perPage,
    ...(clients.length > 0 && {
      clientRecordIds: clients.map(({ _id }) => _id).join(',')
    }),
    ...(practitioners.length > 0 && {
      clinicianAuth0Ids: practitioners.map(({ _id }) => _id).join(',')
    }),
    ...(groups.length > 0 && {
      groupIds: groups.map(({ _id }) => _id).join(',')
    }),
    ...(claimType && {
      claimType: claimType._id as ClaimType
    }),
    ...(code && {
      mbsCode: code._id
    }),
    ...(tab === SubmittedClaimTabs.Abandoned
      ? {
          abandoned: 1
        }
      : statusQuery && {
          status: statusQuery
        }),
    ...(isEdgeUserView && {
      asUser: '1'
    }),
    ...(claimedAfter && {
      claimedAfter
    }),
    ...(claimedBefore && {
      claimedBefore
    }),
    ...(claimId && {
      claimId
    }),
    ...([SubmittedClaimTabs.Rejected, SubmittedClaimTabs.WriteOff].includes(tab)
      ? {
          [rejectedWriteOffSortBy === 'date' ? 'sortByClaimDate' : 'sortByClaimStatus']: [
            rejectedWriteOffSortType === SortType.ASC ? 1 : -1
          ]
        }
      : {
          [sortBy === 'date' ? 'sortByClaimDate' : 'sortByClaimStatus']: [sortType === SortType.ASC ? 1 : -1]
        })
  };
};

export const useGetSubmittedClaims = (tab?: SubmittedClaimTabs) => {
  const { accountId } = useGetAccountId();
  const { isEdgeUserView, isEdgeAdminOrReceptionistView } = useGetAccountPackageView();
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();

  const submittedPaging = useAppSelector(selectSubmittedPaging);
  const successPaging = useAppSelector(selectSuccessPaging);
  const rejectedPaging = useAppSelector(selectRejectedPaging);
  const writeOffPaging = useAppSelector(selectWriteOffPaging);
  const abandonedPaging = useAppSelector(selectAbandonedPaging);
  const sorting = useAppSelector(selectSorting);
  const rejectedWriteOffSorting = useAppSelector(selectRejectedWriteOffSorting);
  const filters = useAppSelector(selectFilters);
  const activeTab = useAppSelector(selectActiveTab);

  const currentTab = tab || activeTab;
  const currentPage =
    currentTab === SubmittedClaimTabs.Submitted
      ? submittedPaging
      : currentTab === SubmittedClaimTabs.Success
      ? successPaging
      : currentTab === SubmittedClaimTabs.Rejected
      ? rejectedPaging
      : currentTab === SubmittedClaimTabs.Abandoned
      ? abandonedPaging
      : writeOffPaging;

  const { data, isLoading, isFetching, isError, refetch } = useGetSubmittedClaimListQuery(
    {
      accountId,
      params: {
        ...getSubmittedClaimListQuery(
          currentPage,
          sorting,
          rejectedWriteOffSorting,
          filters,
          isEdgeUserView,
          currentTab
        )
      }
    },
    { skip: !medicareRebateFeatureToggle || !isEdgeAdminOrReceptionistView }
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    data,
    totalItems: !(isLoading || isFetching) ? data?.paging.totalItems || 0 : 0,
    refetch
  };
};
