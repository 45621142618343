import { Modal } from 'antd';
import { CSSProperties, ReactNode } from 'react';

import classNames from 'classnames';
import ModalHeader from './components/ModalHeader/ModalHeader';
import styles from './ModalV2.module.scss';

interface ModalV2Props {
  isModalOpen: boolean;
  title?: string | ReactNode;
  titleIcon?: string;
  iconClassNames?: string;
  totalStep?: number;
  currentStep?: number;
  isCompleted?: boolean;
  children?: any;
  closable?: boolean;
  containerClassName?: string;
  mask?: boolean;
  onModalClose: () => void;
  onClickBackBtn?: () => void;
  showBackStepBtn?: boolean;
  bodyStyle?: CSSProperties;
  style?: CSSProperties;
  headerContainerClassName?: string;
  titleClassName?: string;
  zIndex?: number;
}

const ModalV2 = ({
  isModalOpen,
  title,
  titleIcon,
  iconClassNames,
  totalStep,
  currentStep,
  isCompleted,
  children,
  closable = false,
  containerClassName,
  mask,
  onModalClose,
  onClickBackBtn,
  showBackStepBtn,
  bodyStyle,
  style,
  headerContainerClassName,
  titleClassName,
  zIndex
}: ModalV2Props) => {
  return (
    <Modal
      mask={mask}
      bodyStyle={bodyStyle}
      style={style}
      className={classNames(styles.container, containerClassName)}
      title={
        title && (
          <ModalHeader
            title={title}
            icon={titleIcon}
            iconClassNames={iconClassNames}
            totalStep={totalStep}
            currentStep={currentStep}
            isCompleted={isCompleted}
            onCancel={onModalClose}
            onClickBackBtn={onClickBackBtn}
            showBackStepBtn={showBackStepBtn}
            headerContainerClassName={headerContainerClassName}
            titleClassName={titleClassName}
          />
        )
      }
      open={isModalOpen}
      footer={null}
      closable={closable}
      zIndex={zIndex}
    >
      {children}
    </Modal>
  );
};

export default ModalV2;
