import { CalendarHighLightInterface } from 'components/v2/CalendarFilter/interfaces';
import { DeliveryType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { CustomCalendarEvent } from '../components/CalendarEvent/CalendarEvent';
import { AppointmentStatusOption } from 'interfaces/Schedule/Appointment';
import { InvoiceStatus } from 'pages/Invoices/interface';

const getInvoiceStatusByAppointmentStatus = (appointmentStatus: AppointmentStatusOption) => {
  switch (appointmentStatus) {
    case AppointmentStatusOption.InvoicePaid:
      return InvoiceStatus.ConfirmPaid;
    case AppointmentStatusOption.InvoiceSent:
      return InvoiceStatus.Issued;
    default:
      return '';
  }
};

export const highLightEvents = (highListList: CalendarHighLightInterface[], events: CustomCalendarEvent[]) => {
  return events.map((e) => {
    const highLights = highListList
      .map((highLight) => {
        if (highLight.sessionType?._id) {
          return e.sessionTypeId === highLight.sessionType._id ? highLight : undefined;
        }
        if (highLight.appointmentType) {
          return (e.isRecurring && highLight.appointmentType === 'recurring') ||
            (!e.groupId &&
              highLight.appointmentType === ParticipantType.OneToOne &&
              !e.isActivity &&
              !['free', 'busy'].includes(e.type!)) ||
            (e.groupId && highLight.appointmentType === ParticipantType.Group) ||
            (highLight.appointmentType === ParticipantType.Activity && e.isActivity) ||
            (e.deliveryType === DeliveryType.Bespoke && highLight.appointmentType === DeliveryType.Bespoke)
            ? highLight
            : undefined;
        }
        if (highLight.deliveryMode) {
          return e.deliveryType === highLight.deliveryMode ? highLight : undefined;
        }
        if (highLight.status) {
          const hasMatchInvoices = e.invoices?.some(
            (invoice) => highLight.status && invoice.status === getInvoiceStatusByAppointmentStatus(highLight.status)
          );
          if (hasMatchInvoices) {
            return highLight;
          }
          return e.status?.includes(highLight.status) ? highLight : undefined;
        }
        return undefined;
      })
      .filter((i) => i) as CalendarHighLightInterface[];
    return { ...e, highLights: highLights };
  });
};
