import * as Yup from 'yup';

export const AU_PUBLIC_HOLIDAY_CALENDAR_OPTIONS = [
  { label: 'Australian Capital Territory AU', value: 'Australian Capital Territory AU' },
  { label: 'New South Wales AU', value: 'New South Wales AU' },
  { label: 'Northern Territory AU', value: 'Northern Territory AU' },
  { label: 'South Australia AU', value: 'South Australia AU' },
  { label: 'Tasmania AU', value: 'Tasmania AU' },
  { label: 'Victoria AU', value: 'Victoria AU' },
  { label: 'Queensland AU', value: 'Queensland AU' },
  { label: 'Western Australia AU', value: 'Western Australia AU' }
];

export const UK_PUBLIC_HOLIDAY_CALENDAR_OPTIONS = [
  { label: 'England UK', value: 'England UK' },
  { label: 'Wales UK', value: 'Wales UK' },
  { label: 'Northern Ireland UK', value: 'Northern Ireland UK' },
  { label: 'Scotland UK', value: 'Scotland UK' }
];

export const NZ_PUBLIC_HOLIDAY_CALENDAR_OPTIONS = [
  { label: 'North Island NZ', value: 'North Island NZ' },
  { label: 'South Island NZ', value: 'South Island NZ' }
];

export const SA_PUBLIC_HOLIDAY_CALENDAR_OPTIONS = [
  { label: 'Kingdom of Saudi Arabia', value: 'Kingdom of Saudi Arabia' }
];

export const MAXIMUM_APPOINTMENTS_PER_DAY_OPTIONS = [
  { label: '1 appointment', value: 1 },
  { label: '2 appointments', value: 2 },
  { label: '3 appointments', value: 3 },
  { label: '4 appointments', value: 4 },
  { label: '5 appointments', value: 5 },
  { label: '6 appointments', value: 6 },
  { label: '7 appointments', value: 7 },
  { label: '8 appointments', value: 8 },
  { label: '9 appointments', value: 9 },
  { label: '10 appointments', value: 10 }
];
export const MINIMUM_TIME_BETWEEN_OPTIONS = [
  { label: 'One calendar date before', value: -1 },
  { label: '12 hours', value: 720 },
  { label: '24 hours', value: 1440 },
  { label: '48 hours', value: 2880 },
  { label: '72 hours', value: 4320 }
];
export const MAX_TIME_BEFORE_CHANGES_IS_ALLOW_OPTIONS = [
  { label: '2 hours', value: 120 },
  { label: '6 hours', value: 360 },
  { label: '12 hours', value: 720 },
  { label: '24 hours', value: 1440 },
  { label: '48 hours', value: 2880 },
  { label: '72 hours', value: 4320 }
];
export const SEND_REMINDER_TO_CLIENTS_OPTIONS = MAX_TIME_BEFORE_CHANGES_IS_ALLOW_OPTIONS;
export const MAX_FORWARD_AVAILABILITY_OPTIONS = [
  { label: 'This month plus next month', value: 1 },
  { label: 'This month plus next 2 months', value: 2 },
  { label: 'This month plus next 3 months', value: 3 }
];
export const RESCHEDULE_TOGGLE_OPTIONS = [
  { label: 'Unable to reschedule appointments', value: 'false' },
  { label: 'Allow to reschedule appointments', value: 'true' }
];
export const RESCHEDULE_LIMIT_OPTIONS = [
  { label: '1 times', value: 1 },
  { label: '2 times', value: 2 },
  { label: '3 times', value: 3 },
  { label: 'Unlimited', value: 0 }
];
export const RESCHEDULE_START_TIME_RANGE_OPTIONS = [
  { label: 'booking confirmation', value: -1 },
  { label: '14 days before', value: 20160 },
  { label: '7 days before', value: 10080 }
];
export const RESCHEDULE_TIME_RANGE_OPTIONS = [
  { label: '7 days before', value: 10080 },
  { label: '72 hours before', value: 4320 },
  { label: '48 hours before', value: 2880 },
  { label: '24 hours before', value: 1440 },
  { label: '12 hours before', value: 720 },
  { label: '4 hours before', value: 240 },
  { label: '2 hours before', value: 120 }
];

export const initialValues = {
  preventBookingsOnPublicHolidays: { active: false, value: '' },
  maxSessionsPerDay: { active: false, value: 1 },
  minTimeBeforeAppointmentWhereBookingsAreAllowed: { active: false, value: -1 },
  maxTimeBeforeAppointmentWhereChangesAreAllowed: { active: false, value: 120 },
  sendReminderToClients: { active: false, value: 120 },
  maxForwardAvailability: { active: false, value: 1 },
  phoneAndVideoCallSettings: { value: '' },
  cancellationPolicyDescription: { value: '' },
  clientReschedule: {
    active: false,
    limit: 0,
    selfManage: {
      active: true,
      value: { from: -1, to: 4320 }
    },
    requestChanges: {
      active: true,
      value: { from: 4320, to: 1440 }
    }
  }
};

export const accountLevelSettingsSchema = Yup.object().shape({
  preventBookingsOnPublicHolidays: Yup.object().shape({
    active: Yup.boolean(),
    value: Yup.string().when('active', {
      is: true,
      then: Yup.string().required('Please select an option'),
      otherwise: Yup.string()
    })
  }),
  maxSessionsPerDay: Yup.object().shape({
    active: Yup.boolean(),
    value: Yup.number().when('active', {
      is: true,
      then: Yup.number().required('Please select an option'),
      otherwise: Yup.number()
    })
  }),
  minTimeBeforeAppointmentWhereBookingsAreAllowed: Yup.object().shape({
    active: Yup.boolean(),
    value: Yup.number().when('active', {
      is: true,
      then: Yup.number().required('Please select an option'),
      otherwise: Yup.number()
    })
  }),
  maxTimeBeforeAppointmentWhereChangesAreAllowed: Yup.object().shape({
    active: Yup.boolean(),
    value: Yup.number().when('active', {
      is: true,
      then: Yup.number().required('Please select an option'),
      otherwise: Yup.number()
    })
  }),
  sendReminderToClients: Yup.object().shape({
    active: Yup.boolean(),
    value: Yup.number().when('active', {
      is: true,
      then: Yup.number().required('Please select an option'),
      otherwise: Yup.number()
    })
  }),
  maxForwardAvailability: Yup.object().shape({
    active: Yup.boolean(),
    value: Yup.number().when('active', {
      is: true,
      then: Yup.number().required('Please select an option'),
      otherwise: Yup.number()
    })
  }),
  phoneAndVideoCallSettings: Yup.object().shape({
    value: Yup.string()
  }),
  cancellationPolicyDescription: Yup.object().shape({
    value: Yup.string()
  }),
  clientReschedule: Yup.object().shape({
    active: Yup.boolean(),
    limit: Yup.number().when('active', { is: true, then: Yup.number().required('Please select an option') }),
    selfManage: Yup.object().when('active', {
      is: true,
      then: Yup.object().when('requestChanges.active', {
        is: false,
        then: Yup.object().shape({
          active: Yup.boolean().equals([true]),
          value: Yup.object().shape({
            from: Yup.string().required('Please select an option'),
            to: Yup.string().required('Please select an option')
          })
        }),
        otherwise: Yup.object().shape({
          active: Yup.boolean(),
          value: Yup.object().shape({
            from: Yup.string().required('Please select an option'),
            to: Yup.string().required('Please select an option')
          })
        })
      })
    }),
    requestChanges: Yup.object().when('active', {
      is: true,
      then: Yup.object().shape({
        active: Yup.boolean(),
        value: Yup.object().shape({
          from: Yup.string().required('Please select an option'),
          to: Yup.string().required('Please select an option')
        })
      })
    })
  })
});
