import {
  AppointmentTypeAvailabilities,
  AvailabilityTimeSlotsByDate,
  SelectedAppointmentSlotProps
} from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import styles from './OpenSlotAppointment.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import {
  appointmentViewFilter,
  FilterTimes,
  selectAppointmentData,
  setAppointmentViewFilter,
  setSelectedDate,
  setSelectedTime,
  startInlineCalendarValidation
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import HorizontalTimeSlotCard from '../HorizontalTimeSlotCard/HorizontalTimeSlotCard';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';
import { scrollToView } from 'utils/scrollToView';
import classNames from 'classnames';
import { Skeleton } from 'antd';
import { getTimezoneLabel } from 'utils/hooks/GetTimezones/getTimezones';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { convertAppointmentTimeBetweenTimezones } from 'utils/helpers/timezone';
import clientRecordEmpty from 'assets/images/clientRecordEmpty2.svg';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetAppointmentTypeAvailableSlotsQuery } from 'redux/endpoints/scheduleServices/appointmentType';
import { DEFAULT_FETCHING_DATES, getTimeRange } from '../AppointmentContent/AppointmentContent';

const filterByFilterTime = ({
  availabilityData,
  selectedAppointmentViewFilter
}: {
  availabilityData?: AppointmentTypeAvailabilities;
  selectedAppointmentViewFilter?: FilterTimes;
}): AppointmentTypeAvailabilities | undefined => {
  if (!availabilityData) {
    // If availabilities is undefined, return undefined
    return undefined;
  }

  const { start, end } = getTimeRange(selectedAppointmentViewFilter || FilterTimes.All);

  const filteredTimeSlots: AvailabilityTimeSlotsByDate = {};

  Object.keys(availabilityData.timeSlots).forEach((date) => {
    const daySlots = availabilityData.timeSlots[date];

    if (daySlots.isAvailable) {
      const filteredDaySlots = daySlots.timeSlots.filter((slot) => {
        const slotStartTime = slot.startTime;
        return slotStartTime >= start && slotStartTime <= end;
      });

      if (filteredDaySlots.length > 0) {
        filteredTimeSlots[date] = { isAvailable: true, timeSlots: filteredDaySlots };
      }
    } else if (selectedAppointmentViewFilter === FilterTimes.All) {
      // If 'all' is selected, include dates with no available slots as-is
      filteredTimeSlots[date] = daySlots;
    }
  });

  return {
    appointmentType: availabilityData.appointmentType,
    timeSlots: filteredTimeSlots
  };
};

const OpenSlotAppointment = () => {
  const dispatch = useAppDispatch();
  const { timeZoneByView } = useTimeZone();
  const { selectedAppointmentType, selectedPractitioner, selectedDate, selectedTime, selectedDeliveryMode } =
    useAppSelector(selectAppointmentData);
  const itemsRef = useRef<HTMLDivElement>(null);
  const selectedAppointmentViewFilter = useAppSelector(appointmentViewFilter);
  const inlineCalendarValidation = useAppSelector(startInlineCalendarValidation);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    scrollToView('selectedOpenTimeSlot', true, false, 'auto');
  }, []);

  const {
    data: availabilityData,
    isLoading: isAvailabilityLoading,
    isFetching: isAvailabilityFetching
  } = useGetAppointmentTypeAvailableSlotsQuery(
    {
      id: 'openSlot',
      from: DEFAULT_FETCHING_DATES.from,
      to: DEFAULT_FETCHING_DATES.to,
      type: 'booking',
      appointmentTypeId: selectedAppointmentType?._id || '',
      clinicianId: selectedPractitioner?._id || '',
      timeZone: timeZoneByView // we use view timezone because we want to show view timezone, after select only convert to selected practitioner timezone
    },
    { skip: !selectedAppointmentType }
  );

  const { startDateTime: selectedPractitionerStartDateTime, endDateTime: selectedPractitionerEndDateTime } =
    selectedPractitioner?.workTimeZone && selectedPractitioner.workTimeZone !== timeZoneByView
      ? convertAppointmentTimeBetweenTimezones({
          date: selectedDate,
          startTime: selectedTime.startTime,
          endTime: selectedTime.endTime,
          originalTimezone: timeZoneByView,
          targetTimezone: selectedPractitioner?.workTimeZone || ''
        })
      : { startDateTime: undefined, endDateTime: undefined };

  const filterByTimeAvailabilityData = useMemo(
    () =>
      filterByFilterTime({
        availabilityData: availabilityData,
        selectedAppointmentViewFilter: selectedAppointmentViewFilter?.times
      }),
    [availabilityData, selectedAppointmentViewFilter]
  );

  const daySlots = useMemo(
    () =>
      filterByTimeAvailabilityData &&
      Object.entries(filterByTimeAvailabilityData.timeSlots).map(([date, filterByTimeAvailabilityData]) => {
        if (filterByTimeAvailabilityData.isAvailable) {
          return {
            date,
            isAvailable: true,
            timeSlots: filterByTimeAvailabilityData.timeSlots
          };
        } else {
          return {
            date,
            isAvailable: false,
            timeSlots: []
          };
        }
      }),
    [filterByTimeAvailabilityData]
  );

  const onScrollClick = () => {
    itemsRef.current?.scrollBy({
      top: 3 * (itemsRef.current.scrollHeight / (daySlots?.length || 3)),
      behavior: 'smooth'
    });
  };

  const handleSelectSlot = (slot: SelectedAppointmentSlotProps) => {
    dispatch(setSelectedDate(moment(slot.date).format(MOMENTJS_FORMAT_DATE)));
    dispatch(setSelectedTime({ startTime: slot.startTime, endTime: slot.endTime }));
  };

  const isSlotNotSelected = useMemo(
    () => !selectedDate || !selectedTime.startTime || !selectedTime.endTime,
    [selectedDate, selectedTime]
  );

  return selectedAppointmentType && selectedDeliveryMode ? (
    <div>
      <div className={classNames(styles.slotBox, inlineCalendarValidation && isSlotNotSelected && styles.slotBoxError)}>
        {isAvailabilityLoading ? (
          <div className={styles.loadingContainer}>
            <Skeleton.Input className={styles.loading} active />
            <Skeleton.Input className={styles.loading} active />
            <Skeleton.Input className={styles.loading} active />
            <Skeleton.Input className={styles.loading} active />
            <Skeleton.Input className={styles.loading} active />
          </div>
        ) : (
          <>
            <div
              ref={itemsRef}
              onScroll={(e) => {
                setIsScrolledToBottom(
                  e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight) < 10
                );
              }}
              className={styles.container}
            >
              <div className={styles.slotItems}>
                {daySlots && daySlots?.length > 0 ? (
                  daySlots?.map((timeSlotObj, index) => (
                    <div key={index} className={styles.slotContainer}>
                      <div className={styles.dateBox}>
                        <div>{moment(timeSlotObj.date).format('dddd')}</div>
                        <div>{moment(timeSlotObj.date).format('Do MMMM YYYY')}</div>
                      </div>
                      <div className={styles.timeSlotBox}>
                        <HorizontalTimeSlotCard
                          isLoading={isAvailabilityFetching}
                          isAvailabilitiesViewTypeSimple={false}
                          selectedAppointmentSlot={{
                            date: selectedDate,
                            startTime: selectedTime.startTime,
                            endTime: selectedTime.endTime
                          }}
                          onSelectSlot={handleSelectSlot}
                          data={timeSlotObj}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles.slotFilterEmptyContainer}>
                    <img className={styles.emptyImg} src={clientRecordEmpty} alt={'No filter result found'} />
                    <div className={styles.emptyDesc}>
                      <div className={styles.emptyTitle}>No slot matching your filters.</div>
                      <div className={styles.suggestion}>
                        Please update your filters or
                        <ButtonAlt
                          className={styles.clearAll}
                          variant={'text'}
                          onClick={() => {
                            dispatch(
                              setAppointmentViewFilter({
                                ...selectedAppointmentViewFilter,
                                times: 'all'
                              })
                            );
                          }}
                        >
                          clear all
                        </ButtonAlt>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {daySlots && daySlots?.length > 5 && (
              <div
                className={classNames(styles.scrollDownText, isScrolledToBottom && styles.scrolledToBottom)}
                onClick={onScrollClick}
              >
                <div className={styles.scroll}>
                  Scroll
                  <i className="material-icons-outlined">keyboard_double_arrow_down</i>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={classNames(
          styles.timezoneWrapper,
          inlineCalendarValidation && isSlotNotSelected && styles.timezoneSlotError
        )}
      >
        <i className={classNames('material-icons', styles.icon)}>schedule</i>
        <div className={styles.timezone}>
          <div>
            The timezone of the booking will be made in:
            <strong> {getTimezoneLabel(timeZoneByView)}</strong>
          </div>
          {/* There is a case that clinician doesn't have workTimeZone */}
          {selectedPractitionerStartDateTime && selectedPractitionerEndDateTime && (
            <div>
              For <strong>{selectedPractitioner?.name}</strong> the time will be{' '}
              <strong>
                {selectedPractitionerStartDateTime.isSame(selectedPractitionerEndDateTime, 'date')
                  ? `${selectedPractitionerStartDateTime.format(
                      'dddd, Do MMMM HH:mm'
                    )} - ${selectedPractitionerEndDateTime.format('HH:mm')}`
                  : `${selectedPractitionerStartDateTime.format('dddd, Do MMMM HH:mm')} -
                  ${selectedPractitionerEndDateTime.format('dddd, Do MMMM HH:mm')}`}
              </strong>
            </div>
          )}
        </div>
      </div>
      {inlineCalendarValidation && isSlotNotSelected && (
        <div className={styles.errorMessage}>Please select timeslot</div>
      )}
    </div>
  ) : (
    <div className={styles.noSelectWrapper}>Please select an appointment type to view available calendar slots.</div>
  );
};

export default OpenSlotAppointment;
