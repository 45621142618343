import classNames from 'classnames';

import styles from './HorizontalTimeCard.module.scss';
import {
  AvailabilityTimeSlot,
  noAvailabilityReasonTexts,
  SelectedAppointmentSlotProps
} from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import moment from 'moment/moment';
import { MOMENTJS_FORMAT_TIME } from 'utils/appointment';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

type HorizontalTimeCardProps = {
  isAvailabilitiesViewTypeSimple: boolean;
  showStartTimeOnly?: boolean;
  timeSlots: AvailabilityTimeSlot[];
  selectedAppointmentSlot?: SelectedAppointmentSlotProps;
  onSelectSlot: (slot: SelectedAppointmentSlotProps) => void;
};

const HorizontalTimeCard = ({
  isAvailabilitiesViewTypeSimple,
  showStartTimeOnly,
  timeSlots,
  selectedAppointmentSlot,
  onSelectSlot
}: HorizontalTimeCardProps) => {
  const [t] = useTranslation();

  const filteredTimeSlots = isAvailabilitiesViewTypeSimple
    ? timeSlots.filter((x: { isAvailable: boolean }) => x.isAvailable)
    : timeSlots;

  const isAppointmentEqual = ({
    selectedAppointment,
    currentAppointment
  }: {
    selectedAppointment?: SelectedAppointmentSlotProps;
    currentAppointment: SelectedAppointmentSlotProps;
  }) => JSON.stringify(selectedAppointment) === JSON.stringify(currentAppointment);

  const mappedTimeSlots =
    filteredTimeSlots && filteredTimeSlots.length > 0
      ? filteredTimeSlots
          .map((timeSlot, index) => {
            if (isAvailabilitiesViewTypeSimple && !timeSlot.isAvailable) {
              return undefined;
            }

            const formattedStartTime = moment(timeSlot.startTime, MOMENTJS_FORMAT_TIME).format(
              showStartTimeOnly ? 'hh:mmA' : 'hh:mm'
            );

            const formattedEndTime = moment(timeSlot.endTime, MOMENTJS_FORMAT_TIME).format('hh:mmA');

            const selectedSlot = isAppointmentEqual({
              selectedAppointment: selectedAppointmentSlot,
              currentAppointment: {
                date: timeSlot.date || '',
                startTime: moment(timeSlot.startTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME),
                endTime: moment(timeSlot.endTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME)
              }
            });

            return (
              <div key={index}>
                <div
                  id={selectedSlot ? 'selectedOpenTimeSlot' : ''}
                  {...(!timeSlot.isAvailable && {
                    'data-tooltip-id': `horizontal-unavailable-reason-${timeSlot.date}-${timeSlot.startTime}`
                  })}
                  className={classNames(
                    !timeSlot.isAvailable ? styles.timeButtonDisabled : styles.timeButton,
                    showStartTimeOnly && styles.startTimeOnly,
                    selectedSlot && styles.active
                  )}
                  onClick={() => {
                    timeSlot.isAvailable &&
                      onSelectSlot({
                        date: timeSlot.date || '',
                        startTime: moment(timeSlot.startTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME),
                        endTime: moment(timeSlot.endTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_TIME)
                      });
                  }}
                >
                  {showStartTimeOnly ? `${formattedStartTime}` : `${formattedStartTime} to ${formattedEndTime}`}
                </div>
                {!timeSlot.isAvailable && timeSlot.noAvailabilityReason && (
                  <Tooltip
                    place={'top'}
                    hidden={isAvailabilitiesViewTypeSimple}
                    id={`horizontal-unavailable-reason-${timeSlot.date}-${timeSlot.startTime}`}
                  >
                    {t(noAvailabilityReasonTexts[timeSlot.noAvailabilityReason])}
                  </Tooltip>
                )}
              </div>
            );
          })
          .filter((timeComponent) => !!timeComponent)
      : [];

  if (mappedTimeSlots.length === 0 && isAvailabilitiesViewTypeSimple) {
    return <div className={styles.noAvailability}>No availability</div>;
  }

  return <div className={styles.timeSlotContainer}>{mappedTimeSlots}</div>;
};

export default HorizontalTimeCard;
