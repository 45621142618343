import styles from './FieldTab.module.scss';

interface FieldTabProps {
  label: string;
  value: string;
}

const FieldTab = ({ label, value }: FieldTabProps) => (
  <div className={styles.fieldTab}>
    <div className={styles.fieldName}>{label}</div>
    <div className={styles.fieldValue}>{value}</div>
  </div>
);

export default FieldTab;
