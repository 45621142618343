import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { CaseNote } from 'interfaces/caseNote';
import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import MultipleChoiceWrapper from './components/MultipleChoiceWrapper/MultipleChoiceWrapper';
import CaseNoteFieldType from './components/CaseNoteFieldType/CaseNoteFieldType';
import styles from './CaseNoteHeaderOption.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import FolderDropdown from './components/FolderDropdown/FolderDropdown';
import TemplateDropdown from './components/TemplateDropdown/TemplateDropdown';
import { CaseNoteTemplate } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import EpisodeTaggingSection from 'components/EpisodeTaggingSection/EpisodeTaggingSection';
import { useTranslation } from 'react-i18next';

interface CaseNoteHeaderOptionProps {
  noteId: string;
  recordId: string;
  clientRole?: string;
  fieldsData: CaseNote['fields'];
  title: CaseNote['title'];
  safeguardingAlert?: CaseNote['safeguardingAlert'];
  safeguardingReason?: CaseNote['safeguardingReason'];
  onChangeFieldValue: any;
  onChangeTitle: any;
  onChangeSafeguardingAlert: any;
  onChangeSafeguardingReason: any;
  isValidated: boolean;
  isGroup?: boolean;
  isTemplateListActive: boolean;
  handleSelectTemplate: (id: string, confirm?: true) => Promise<void>;
  templateList?: CaseNoteTemplate[];
  currentTemplate?: CaseNoteTemplate;
  onChangeFolder: (val: boolean) => void;
  isPrivateInput: boolean;
  setIsPrivateInput: Dispatch<SetStateAction<boolean>>;
  episodeTaggingField?: boolean;
  episodeId?: string;
  onChangeEpisodeId?: (val: string) => void;
}

const CaseNoteHeaderOption = ({
  noteId,
  recordId,
  clientRole,
  fieldsData,
  title,
  onChangeFieldValue,
  onChangeTitle,
  safeguardingAlert,
  safeguardingReason,
  onChangeSafeguardingAlert,
  onChangeSafeguardingReason,
  isValidated,
  isGroup,
  isTemplateListActive,
  handleSelectTemplate,
  templateList,
  currentTemplate,
  onChangeFolder,
  isPrivateInput,
  setIsPrivateInput,
  episodeTaggingField,
  episodeId,
  onChangeEpisodeId
}: CaseNoteHeaderOptionProps) => {
  const [t] = useTranslation();
  const { isHelmFeatureToggle, isCaWFeatureToggle, isHidePrivateCaseNotes } = useGetFeatureToggle();
  const [caseNoteFieldData, setCaseNoteFieldData] = useState<CaseNote['fields']>(fieldsData);
  const [caseNoteTitle, setCaseNoteTitle] = useState(title);

  const safeguardingObj: CaseNote['fields'][number] = {
    _id: 'safeGuardingObj',
    name: t('client_case_notes.safe_guarding_alert'),
    type: 'multipleChoice',
    options: [
      {
        label: '',
        options: [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' }
        ]
      }
    ],
    mandatory: true,
    value: ''
  };
  const [safeguardingAlertState, setSafeguardingAlertState] = useState({
    ...safeguardingObj,
    mandatory:
      fieldsData.filter((data) => data.name === 'Safeguarding Alert')[0]?.mandatory ?? safeguardingObj.mandatory,
    value: safeguardingAlert === undefined ? '' : safeguardingAlert ? 'Yes' : 'No'
  });
  const [safeguardingReasonState, setSafeguardingReasonState] = useState(safeguardingReason);

  useEffect(() => {
    if (noteId === 'new') {
      setCaseNoteFieldData(fieldsData);
    }
  }, [fieldsData, noteId, title]);

  const findReplaceOptionData = (id: string, newOption: any) => {
    const newCollection = [];
    for (let fieldObj of caseNoteFieldData) {
      newCollection.push({
        ...fieldObj,
        options: fieldObj._id === id ? newOption : fieldObj.options
      });
    }
    setCaseNoteFieldData(newCollection);
    return newCollection;
  };

  const handleChangeSelectCount = (id: string, val: any) => {
    const newValue = findReplaceOptionData(id, val);
    onChangeFieldValue(newValue);
  };

  const findReplaceFieldValue = (id: string, value: any) => {
    const newCollection = [];
    for (let fieldObj of caseNoteFieldData) {
      newCollection.push({
        ...fieldObj,
        value: fieldObj._id === id ? value : fieldObj.value
      });
    }
    setCaseNoteFieldData(newCollection);
    return newCollection;
  };

  const handleOnChangeFieldInput = (fieldId: string, val: string) => {
    const newValue = findReplaceFieldValue(fieldId, val);
    onChangeFieldValue(newValue);
  };

  const handleOnChangeTitle = (val: string) => {
    setCaseNoteTitle(val);
    onChangeTitle(val);
  };

  const handleOnChangeSafeguardingAlert = (id: string, value: any) => {
    setSafeguardingAlertState({ ...safeguardingAlertState, value: value.value });
    if (value.value === 'Yes') {
      onChangeSafeguardingAlert(true);
    } else if (value.value === 'No') {
      setSafeguardingReasonState('');
      onChangeSafeguardingAlert(false);
    } else {
      setSafeguardingReasonState('');
      onChangeSafeguardingAlert(undefined);
    }
  };

  const handleOnChangeSafeguardingReason = (value: any) => {
    setSafeguardingReasonState(value);
    onChangeSafeguardingReason(value);
  };

  const isTitleError = isValidated && caseNoteTitle.length <= 0;
  const isSafeguardingReasonError =
    isValidated && safeguardingAlertState.value === 'Yes' && _.isEmpty(safeguardingReasonState);

  return (
    <div className={styles.container}>
      <div className={episodeTaggingField ? styles.firstRowWithEp : styles.firstRowContainer}>
        <div className={styles.titleContainer}>
          <MaterialInput
            id="title"
            name="title"
            label="Title for this Note *"
            value={caseNoteTitle}
            onChange={(e) => {
              handleOnChangeTitle(e.target.value);
            }}
            maxLength={100}
            required
            error={isTitleError}
          />
          <ErrorMessage
            className={styles.error}
            error={'Please fill in the title of this note'}
            visible={isTitleError}
          />
        </div>
        <div className={styles.firstRow}>
          {episodeTaggingField && (
            <div className={styles.episodeTaggingContainer}>
              <EpisodeTaggingSection
                readOnly={false}
                selectedClientRecordId={recordId}
                selectedEpisode={episodeId}
                onSelectEpisode={(value) => onChangeEpisodeId && onChangeEpisodeId(value)}
                containerClassName={styles.epTagging}
              />
            </div>
          )}
          {!isHidePrivateCaseNotes && (
            <FolderDropdown
              isPrivateInput={isPrivateInput}
              onChange={onChangeFolder}
              setIsPrivateInput={setIsPrivateInput}
            />
          )}
          {isTemplateListActive && (
            <TemplateDropdown
              currentTemplate={currentTemplate}
              handleSelectTemplate={handleSelectTemplate}
              templateList={templateList}
            />
          )}
        </div>
      </div>
      {!(isHelmFeatureToggle || isCaWFeatureToggle) &&
        caseNoteFieldData.find((data) => data.name === 'Safeguarding Alert') && (
          <div className={styles.safeguardingContainer}>
            <MultipleChoiceWrapper
              error={isValidated}
              field={safeguardingAlertState}
              isValidated={isValidated}
              onChangeFieldInput={handleOnChangeSafeguardingAlert}
            />
            {safeguardingAlertState.value === 'Yes' && (
              <div className={styles.safeguardingReason}>
                <MaterialInput
                  id="safeguardingReason"
                  name="safeguardingReason"
                  label={`${t('client_case_notes.safe_guarding_label')} *`}
                  value={safeguardingReasonState}
                  onChange={(e) => handleOnChangeSafeguardingReason(e.target.value)}
                  maxLength={200}
                  required
                  error={isSafeguardingReasonError}
                />
                <ErrorMessage
                  className={styles.error}
                  error={'Please fill in this field'}
                  visible={isSafeguardingReasonError}
                />
              </div>
            )}
          </div>
        )}
      <div className={styles.fieldContainer}>
        {caseNoteFieldData
          .filter((data) => data.name !== 'Safeguarding Alert')
          .map((obj, index) => (
            <CaseNoteFieldType
              key={index}
              recordId={recordId}
              caseNoteFieldData={obj}
              handleOnChangeFieldInput={handleOnChangeFieldInput}
              handleChangeSelectCount={handleChangeSelectCount}
              isValidated={isValidated}
              clientRole={clientRole}
              isGroup={isGroup}
            />
          ))}
      </div>
    </div>
  );
};

export default CaseNoteHeaderOption;
