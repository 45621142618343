import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import styles from './AssessmentsHeader.module.scss';
import SurveyVideoModal from './components/SurveyVideoModal/SurveyVideoModal';

interface AssessmentsHeaderProps {
  title: string;
  lastUpdateLabel?: string;
  saveBtn?: any;
  backText?: string;
  hideVideo?: boolean;
}

const AssessmentsHeader = ({
  title,
  lastUpdateLabel,
  saveBtn,
  backText = 'To Template List',
  hideVideo = false
}: AssessmentsHeaderProps) => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();
  const { isEdgeUserView } = useGetAccountPackageView();
  const [isShowVideoModal, setShowVideoModal] = useState(false);

  const backBtn = () => {
    navigate(FORMS.BASE);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            {title}
            {!hideVideo && !isEdgeUserView && (
              <>
                <ButtonAlt
                  variant={'text'}
                  className={styles.videoBtn}
                  onClick={() => setShowVideoModal(true)}
                  icon={'ondemand_video'}
                >
                  <div className={styles.videoLabel}>Watch short how-to video for creating surveys</div>
                </ButtonAlt>
                <SurveyVideoModal visible={isShowVideoModal} onCancel={() => setShowVideoModal(false)} />
              </>
            )}
          </div>
          <div className={styles.backBtnWrapper}>
            <ButtonAlt variant={'text'} size={'medium'} onClick={backBtn} icon={'arrow_back_ios'}>
              {backText}
            </ButtonAlt>
          </div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.updateLabel}>{lastUpdateLabel}</div>
        {saveBtn && saveBtn}
      </div>
    </div>
  );
};

export default AssessmentsHeader;
