import RedirectTo404 from 'components/RedirectTo404/RedirectTo404';
import Analytics from 'pages/Analytics/Analytics';
import MicroCheckInDetails from 'pages/AssessmentDetails/MicroCheckInDetails/MicroCheckInDetails';
import OnBoardingDetails from 'pages/AssessmentDetails/OnBoardingDetails/OnBoardingDetails';
import Assessments from 'pages/Assessments/Assessments';
import ControlPanel from 'pages/ControlPanel/ControlPanel/ControlPanel';
import IntegrationDetails from 'pages/ControlPanel/IntegrationDetails/IntegrationDetails';
import InvoiceGenerator from 'pages/InvoiceGenerator/InvoiceGenerator';
import InvoiceDetail from 'pages/Invoices/components/InvoiceDetail/InvoiceDetail';
import Invoices from 'pages/Invoices/Invoices';
import Messages from 'pages/Messages/Messages';
import ClientPage from 'pages/Client/Client';
import PatientDetails from 'pages/PatientDetails/PatientDetails';
import ReportDetails from 'pages/Report/ReportDetails/ReportDetails';
import ReportsList from 'pages/Report/ReportsList/ReportsList';
import ReportTemplateEditor from 'pages/Report/ReportTemplateEditor/ReportTemplateEditor';
import { Route, Routes } from 'react-router-dom';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';

import CalendarSettings from '../pages/Calendar/components/CalendarSettings/CalendarSettings';
import SessionDetails from '../pages/Groups/GroupDetails/components/GroupContent/components/Sessions/SessionDetails/SessionDetails';
import GroupsList from '../pages/Groups/GroupsList/GroupsList';
import HelmContent from '../pages/HelmContent/HelmContent';
import GroupDetailRoutes from 'pages/Groups/GroupDetails/GroupDetailsRoutes';
import CalendarWithHighlightsT23 from 'pages/Calendar/components/CalendarWithHighlightsT23/CalendarWithHighlightsT23';
import LetterTemplateEditor from 'pages/Report/LetterTemplateEditor/LetterTemplateEditor';
import LetterDetails from 'pages/Report/LetterDetails/LetterDetails';
import LettersList from 'pages/Report/LettersList/LettersList';
import InvoicesList from 'pages/InvoicesV2/Invoices';
import InvoiceWriteOff from 'pages/InvoiceWriteOff/InvoiceWriteOff';

const EdgeUserRoutes = () => {
  const { isGroupsFeatureToggle, helmContentFeatureToggle, isInvoiceListV2Enabled } = useGetFeatureToggle();
  const { isInvoiceReadDenied } = useGetPermissionToggle();

  return (
    <Routes>
      {/* Patients - Clients */}
      <Route path={'client'} element={<ClientPage />} />
      <Route path={'client/:tabId'} element={<ClientPage />} />
      <Route path={'client/:recordId/:patientTab'} element={<PatientDetails />} />
      <Route path={'client/:recordId/:patientTab/:assessmentOrReportId'} element={<PatientDetails />} />
      {/* Groups */}
      {isGroupsFeatureToggle && (
        <>
          <Route path={'groups'} element={<GroupsList />} />
          <Route path={'groups/:tabId'} element={<GroupsList />} />
          <Route path={'groups/:groupId/*'} element={<GroupDetailRoutes />} />
          <Route path={'groups/:groupId/sessionDetail/:sessionId'} element={<SessionDetails />} />
        </>
      )}
      {/* Forms */}
      <Route path={'forms'} element={<Assessments />} />
      <Route path={'forms/survey/:assessmentId'} element={<OnBoardingDetails />} />
      <Route path={'forms/microCheckIn/:assessmentId'} element={<MicroCheckInDetails />} />
      <Route path={'forms/letters/:letterTemplateId'} element={<LetterTemplateEditor />} />
      <Route path={'forms/reports/:templateId'} element={<ReportTemplateEditor />} />
      {/* Calendar */}
      <Route path={'calendar'} element={<CalendarWithHighlightsT23 />} />
      <Route path={'calendar/settings/:tab'} element={<CalendarSettings />} />
      {/* Control panel */}
      <Route path={'control-panel/*'} element={<ControlPanel />}>
        <Route path={':controlPanelTab'} element={<ControlPanel />} />
      </Route>
      <Route path={'control-panel/system-connections/:integrationId'} element={<IntegrationDetails />} />
      {/* Reports */}
      <Route path={'reports'} element={<ReportsList />} />
      <Route path={'reports/:reportId'} element={<ReportDetails />} />
      {/* Letter */}
      <Route path={'letters'} element={<LettersList />} />
      <Route path={`letters/:letterId`} element={<LetterDetails />} />
      {/* Messages */}
      <Route path={'messages'} element={<Messages />} />
      <Route path={'messages/:messageTabId'} element={<Messages />} />
      {/* Analytics */}
      <Route path={'analytics'} element={<Analytics />} />
      {/* HelmContent */}
      {helmContentFeatureToggle && <Route path={'helm-content'} element={<HelmContent />} />}
      {/* Invoices */}
      {!isInvoiceReadDenied && (
        <>
          <Route path={'invoices/new'} element={<InvoiceGenerator />} />
          {isInvoiceListV2Enabled ? (
            <>
              <Route path={'invoices'} element={<InvoicesList />} />
              <Route path={'invoices/:tabId/tab'} element={<InvoicesList />} />
              <Route path={'invoices/write-offs'} element={<InvoiceWriteOff />} />
            </>
          ) : (
            <Route path={'invoices'} element={<Invoices />} />
          )}
          <Route path={'invoices/invoice-settings'} element={<Invoices />} />
          <Route path={'invoices/:invoiceId'} element={<InvoiceDetail />} />
          <Route path={'invoices/:invoiceId/edit'} element={<InvoiceGenerator />} />
        </>
      )}
      ){/* 404 */}
      <Route path={'*'} element={<RedirectTo404 />} />
    </Routes>
  );
};

export default EdgeUserRoutes;
