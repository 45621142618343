import { InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './Switch.module.scss';

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  labelClass?: string;
}

const Switch = ({ className, name, hasError, labelClass, ...props }: SwitchProps) => {
  return (
    <div className={classNames(styles.container, hasError && styles.error, className)}>
      <label className={classNames(styles.switchToggle, labelClass)} htmlFor={name}>
        <input type={'checkbox'} id={name} name={name} {...props} />
        <span />
      </label>
    </div>
  );
};

export default Switch;
