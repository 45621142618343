import { useFormikContext, FormikValues } from 'formik';
import { Props } from 'react-select';
import { useFetchAvailableRoomList } from 'utils/hooks/GetRoomList/useFetchRoomList';

import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { useTimeZone } from 'utils/hooks/useTimeZone';

interface RoomSelectProps {
  bookedAppointmentId?: string;
  preferredRoomId?: string;
  menuPlacement?: Props['menuPlacement'];
}

const RoomSelect = ({ bookedAppointmentId, preferredRoomId, menuPlacement }: RoomSelectProps) => {
  const { timeZoneByView } = useTimeZone();
  const { values } = useFormikContext<FormikValues>();
  const { availableRoomList, isAvailableRoomListLoading } = useFetchAvailableRoomList(
    values,
    timeZoneByView,
    bookedAppointmentId
  );

  const mapRoomList = () => {
    let list = [...availableRoomList];
    if (preferredRoomId) {
      list = list.filter((item) => item._id === preferredRoomId);
    }
    return list.map((room) => ({
      label: room.name,
      value: JSON.stringify({
        roomId: room._id,
        roomName: room.name
      })
    }));
  };

  return isAvailableRoomListLoading ? (
    <div>Loading...</div>
  ) : (
    <FormikSelect name="roomInfo" label="Select available room" options={mapRoomList()} menuPlacement={menuPlacement} />
  );
};

export default RoomSelect;
