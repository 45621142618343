import { notification } from 'antd';
import { useEffect, useState } from 'react';

import { getMicroJournalEntries } from '../http/CheckInService/MicroJournal/microJournal';
import { useGetAccountPackageView } from './GetAccountInfo/accountPackageView';
import { calculateDateRange } from './graph';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentEpisode, selectIsOutsideAllEpisodes, selectIsShowAllData } from 'redux/episodes/episodeSlice';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

export interface MicroJournalEntry {
  _id: string;
  date: string;
  title: string;
  response: string;
  tags?: Record<string, string>[];
}

export const useFetchMicroJournalEntries = ({
  token,
  clientId,
  profileId,
  dateRange
}: {
  token: string;
  clientId: string;
  profileId?: string;
  dateRange?: number;
}) => {
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [microJournalEntries, setMicroJournalEntries] = useState<MicroJournalEntry[]>([]);
  const [isMicroJournalEntriesLoading, setIsMicroJournalEntriesLoading] = useState(true);
  const currentEpisode = useAppSelector(selectCurrentEpisode);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const { isEoCEnabled } = useGetFeatureToggle();
  const isSupportEpisode = isEoCEnabled && !isShowAllData;
  const episode = isSupportEpisode ? currentEpisode : undefined;

  const fetchMicroJournalEntries = async (dateRange?: number) => {
    setIsMicroJournalEntriesLoading(true);

    if (clientId && !isEdgeReceptionist) {
      try {
        const { startDate, endDate } = calculateDateRange({ episode, dateRange: dateRange || 365 * 2 });
        const episodeQuery = isOutsideAllEpisodes ? `&showOutsideOfEpisode=${isOutsideAllEpisodes}` : '';
        const queryParam = `${
          profileId ? `clientRecordId=${clientId}&clientProfileId=${profileId}` : `patientId=${clientId}`
        }&startDate=${startDate}&endDate=${endDate}${episodeQuery}`;

        const callGetMicroJournalEntriesByPatientId = await getMicroJournalEntries(token, queryParam);
        const { entries } = await callGetMicroJournalEntriesByPatientId.json();

        if (Array.isArray(entries)) {
          const dateRangeEntries = isOutsideAllEpisodes && dateRange ? entries.slice(-dateRange) : entries;
          setMicroJournalEntries(dateRangeEntries);
        } else {
          throw new Error();
        }
      } catch (ex) {
        notification.error({
          message: "Something went wrong while trying to get this patient's micro journal entries"
        });
      }
    }

    setIsMicroJournalEntriesLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchMicroJournalEntries(dateRange ? dateRange : 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { microJournalEntries, isMicroJournalEntriesLoading, fetchMicroJournalEntries };
};
