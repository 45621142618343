import { useEffect, useRef, useState } from 'react';

import SelectCountBtn from './components/SelectCountBtn/SelectCountBtn';
import { v4 as uuid } from 'uuid';
import MaterialInput from '../MaterialInput/MaterialInput';
import MaterialLabel from '../MaterialLabel/MaterialLabel';
import { toCamelCase } from 'utils/generateCamelCase';

import styles from './SelectWithCount.module.scss';

interface SelectWithCountItemProps {
  _id: string;
  count: number;
  label: string;
  subLabel: string;
  value: string;
}

const sortOtherLast = (list: SelectWithCountItemProps[]) => {
  return list.sort((a, b) => {
    return b.value !== 'Other' ? 1 : -1;
  });
};

export interface SelectWithCountProps {
  id: string;
  label: string;
  options?: any;
  onChangeValue: any;
}

const generateLabel = (list: any) => {
  const getOnlyWithValue = list.filter((obj: any) => obj.count > 0);
  return getOnlyWithValue
    .map((val: any) => {
      return `${val.count} ${val.label}`;
    })
    .join(' | ');
};

const SelectWithCount = ({ id, label, options, onChangeValue }: SelectWithCountProps) => {
  const selectCountRef = useRef<HTMLDivElement>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [optionList, setOptionList] = useState([] as any);
  const [selectedValue, setSelectedValue] = useState(generateLabel(options));
  const [otherValue, setOtherValue] = useState('');

  const handleClick = (e: any) => {
    if (selectCountRef.current?.contains(e.target)) {
      return;
    }
    openFilterList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  useEffect(() => {
    const valueLabel = generateLabel(options);
    setSelectedValue(valueLabel);
    setOptionList(sortOtherLast([...options]));
  }, [options]);

  const openFilterList = (val: boolean) => {
    setShowFilter(val);
  };

  const handleValueChange = (idValue: string, countValue: number) => {
    const newCountValue = [];
    for (let obj of optionList) {
      newCountValue.push({
        ...obj,
        count: obj.value === idValue ? countValue : obj.count
      });
    }
    setOptionList(newCountValue);
    onChangeValue(id, newCountValue);
    const valueLabel = generateLabel(newCountValue);
    setSelectedValue(valueLabel);
  };

  const handleOtherValue = (val: string) => {
    setOtherValue(val);
  };

  const handleAddOtherValue = () => {
    const newOtherValue = {
      count: 0,
      label: otherValue,
      subLabel: '',
      value: `${otherValue}-${uuid()}`
    };
    const newList = [...optionList, newOtherValue];

    const newOptionList = [];
    for (let obj of newList) {
      newOptionList.push({
        ...obj
      });
    }
    setOptionList(sortOtherLast(newOptionList));
    setOtherValue('');
  };

  return (
    <div className={styles.container} ref={selectCountRef}>
      <div onClick={() => openFilterList(!showFilter)}>
        <MaterialLabel label={label} isLabelMinimised={!!selectedValue} />
      </div>
      <div className={styles.filterWrapper}>
        <div className={styles.filter} onClick={() => openFilterList(!showFilter)}>
          <div id={toCamelCase(label)} className={styles.value}>
            {selectedValue}
          </div>
          <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
        </div>
      </div>
      <div className={styles.dropDown}>
        <div className={showFilter ? styles.dropDownMenuShow : styles.dropDownMenuHide}>
          {optionList.map((optionObj: any, index: number) => (
            <div className={optionObj.count > 0 ? styles.listValueSelected : styles.listBox} key={index}>
              {optionObj.value !== 'Other' ? (
                <div className={styles.listValue}>
                  <div>
                    <div className={styles.listLabel}>{optionObj.label}</div>
                    {optionObj.subLabel && <div className={styles.subLabel}>({optionObj.subLabel})</div>}
                  </div>
                  <SelectCountBtn
                    id={optionObj.value}
                    countValue={optionObj.count}
                    maxValue={optionObj.value === 'Myself' ? 1 : 16}
                    onChangeValue={handleValueChange}
                  />
                </div>
              ) : (
                <div className={styles.listValueOther}>
                  <MaterialInput
                    id={'otherField'}
                    label={optionObj.label}
                    labelClassName={styles.label}
                    className={styles.input}
                    name="otherField"
                    value={otherValue}
                    onChange={(e) => handleOtherValue(e.target.value)}
                  />
                  <div className={styles.addBtnWrapper}>
                    {otherValue.length > 0 && (
                      <div className={styles.addBtn} onClick={handleAddOtherValue}>
                        <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
                        Add
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectWithCount;
