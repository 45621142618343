import { Table, TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import { FilterCheckListChangeValue, FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import Pill from 'components/Pill/Pill';
import PaginationListV2 from 'components/v2/PaginationListV2/PaginationListV2';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { Fax, FaxStatus, FaxTag } from 'interfaces/fax';
import { debounce } from 'lodash';
import moment from 'moment';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetMinifiedClientRecordQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetFaxesQuery } from 'redux/endpoints/notificationServices/eFax';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import FaxDetailsModal from '../FaxDetailsModal/FaxDetailsModal';
import styles from './Faxes.module.scss';
import TableRowActionDropdown from './TableRowActionDropdown/TableRowActionDropdown';

type FaxDetailsClientRecord = Pick<clientRecordsInterface, '_id' | 'clientProfiles'> & {
  clientProfiles: [{ firstLastName: string }];
};

enum SearchParams {
  View = 'view'
}

const perPage = 50;

export interface FaxesFilters {
  clients: FilterCheckListItem[];
  statuses: FilterCheckListItem[];
  tags: FilterCheckListItem[];
}

enum SortOrder {
  Ascend = 'ascend',
  Descend = 'descend'
}

const defaultSorter = { columnKey: 'createdAt', field: 'createdAt', order: SortOrder.Descend };

const Faxes = () => {
  const [faxDetailsModalVisible, setFaxDetailsModalVisible] = useState(false);
  const [faxesCurrentPage, setFaxesCurrentPage] = useState<number>(1);
  const [filterClientCurrentPage, setFilterClientCurrentPage] = useState<number>(1);
  const [filterClientSearchText, setFilterClientSearchText] = useState<string>('');
  const [filters, setFilters] = useState<FaxesFilters>({ clients: [], statuses: [], tags: [] });
  const [selectedFax, setSelectedFax] = useState<Fax>();
  const [tableSort, setTableSort] = useState<SorterResult<Fax>>(defaultSorter);

  const [searchParams, setSearchParams] = useSearchParams();
  const { accountId } = useGetAccountId();
  const navigate = useNavigate();

  const debouncedSetFilterClientSearchText = useMemo(
    () =>
      debounce((value) => {
        setFilterClientCurrentPage(1);
        setFilterClientSearchText(value);
      }, 1000),
    []
  );

  const getIdsFromFilter = (key: keyof FaxesFilters) => filters[key].map((filter) => filter._id);
  const sortByCreatedAt =
    tableSort.columnKey === 'createdAt' ? (tableSort.order === SortOrder.Ascend ? 1 : -1) : undefined;
  const { data: faxesData, isFetching: isFaxesFetching } = useGetFaxesQuery({
    accountId,
    params: {
      clientRecordIds: getIdsFromFilter('clients'),
      page: faxesCurrentPage,
      perPage,
      sortByCreatedAt,
      statuses: getIdsFromFilter('statuses'),
      tags: getIdsFromFilter('tags')
    }
  });

  const { data: filterClientListData, isFetching: isFilterClientListDataFetching } = useGetMinifiedClientRecordQuery(
    {
      accountId,
      params: {
        page: filterClientCurrentPage,
        perPage,
        recordStatus: 'active',
        ...(filterClientSearchText && {
          searchValue: filterClientSearchText,
          searchBy: SearchFilterEnum.FirstOrLastName
        })
      }
    },
    {}
  );

  const faxes = useMemo(() => faxesData?.faxes ?? [], [faxesData]);

  const hasMoreFilterClientListData = filterClientListData?.paging
    ? filterClientListData.paging.page * filterClientListData.paging.perPage < filterClientListData.paging.totalItems
    : false;

  const faxesPaging = { page: faxesCurrentPage, perPage, totalItems: faxesData?.paging?.totalItems ?? 0 };

  const filterClientListMenuItems =
    filterClientListData?.clientRecords.map((client) => ({
      _id: client._id,
      name: `${client.clientProfiles[0].firstName} ${client.clientProfiles[0].lastName}`
    })) ?? [];

  useEffect(() => {
    const faxId = searchParams.get(SearchParams.View);
    if (faxId) {
      setSelectedFax(faxes.find((fax) => fax._id === faxId));
      setFaxDetailsModalVisible(true);
    } else {
      setSelectedFax(undefined);
      setFaxDetailsModalVisible(false);
    }
  }, [faxes, searchParams]);

  const handleModalClose = () => {
    searchParams.delete(SearchParams.View);
    setSearchParams(searchParams);
  };

  const handleFileLinkClick = (faxId: string) => {
    searchParams.append(SearchParams.View, faxId);
    setSearchParams(searchParams);
  };

  const handleClientNameClick = (clientRecordId: string) => {
    navigate(`/admin/client/${clientRecordId}/profile`);
  };

  const handleTableChange: TableProps<Fax>['onChange'] = (pagination, filters, sorter, extra) => {
    if (!Array.isArray(sorter)) {
      let controlledSorter = sorter;

      if (sorter.column === undefined) {
        controlledSorter = { columnKey: 'createdAt', field: 'createdAt', order: SortOrder.Ascend };
      }

      setTableSort(controlledSorter);
    }
  };

  const checklistChangeHandlerGenerator =
    ({
      stateVariable,
      updateStateVariable
    }: {
      stateVariable: FilterCheckListItem[];
      updateStateVariable: Dispatch<FilterCheckListItem[]>;
    }) =>
    (changes: FilterCheckListChangeValue) => {
      if (changes.item) {
        if (stateVariable.includes(changes.item)) {
          const index = stateVariable.indexOf(changes.item);
          const copyOfStateVariable = [...stateVariable];
          copyOfStateVariable.splice(index, 1);
          updateStateVariable(copyOfStateVariable);
          return;
        }
        updateStateVariable([...stateVariable, changes.item]);
      }
    };

  const columns = [
    {
      dataIndex: 'createdAt',
      defaultSortOrder: SortOrder.Descend,
      key: 'createdAt',
      render: (createdAt: string) => moment(createdAt).format('DD/MM/YYYY HH:mm'),
      sortDirections: [SortOrder.Ascend, SortOrder.Descend],
      sorter: true,
      sortOrder: tableSort.columnKey === 'createdAt' ? tableSort.order : null,
      title: 'DATE & TIME'
    },
    { key: 'status', dataIndex: 'status', title: 'STATUS', render: (status: string) => status?.replaceAll(/_/gi, ' ') },
    { key: 'sendFrom', dataIndex: 'sendFrom', title: 'FROM NUMBER', width: 160 },
    { key: 'sendTo', dataIndex: 'sendTo', title: 'TO NUMBER', width: 160 },
    {
      key: 'fileName',
      dataIndex: 'fileName',
      title: 'FILE LINK',
      ellipsis: {
        showTitle: false
      },
      render: (fileName: string, { _id }: Fax) => (
        <button
          className={styles.fileLink}
          onClick={() => handleFileLinkClick(_id)}
        >{`${window.location.origin}${window.location.pathname}?view=${_id}`}</button>
      )
    },
    {
      key: 'clientName',
      dataIndex: 'clientRecord',
      title: 'CLIENT LINK',
      render: (clientRecord: FaxDetailsClientRecord, { _id }: Fax) => (
        <button className={styles.fileLink} onClick={() => handleClientNameClick(clientRecord._id)}>
          {clientRecord?.clientProfiles[0]?.firstLastName}
        </button>
      )
    },
    {
      key: 'tag',
      dataIndex: 'tag',
      title: 'TAG',
      render: (tag: string) => <Pill className={styles.tag}>{tag}</Pill>
    },
    {
      key: 'action',
      width: 48,
      render: (_: any, fax: Fax) => <TableRowActionDropdown fax={fax} key={fax._id} />
    }
  ];

  return (
    <HelmetWrapper title={'Tacklit - Received & Sent Faxes'}>
      {selectedFax && (
        <FaxDetailsModal
          visible={selectedFax && faxDetailsModalVisible}
          onCloseModal={handleModalClose}
          faxId={selectedFax._id}
        />
      )}
      <ContentLayout>
        <div className={styles.headerContainer}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>Received & Sent Faxes</div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.filterSection}>
            <div className={styles.filterHeading}>FILTER BY:</div>
            <div className={styles.filterBar}>
              <FilterDropdown
                id="status"
                menuItems={Object.values(FaxStatus).map((status) => ({
                  _id: status,
                  name: status.replaceAll(/_/gi, ' ')
                }))}
                onChangeItem={checklistChangeHandlerGenerator({
                  stateVariable: filters.statuses,
                  updateStateVariable: (statuses) => setFilters({ ...filters, statuses })
                })}
                selectedFilterList={filters.statuses}
              >
                Status
              </FilterDropdown>
              <FilterDropdown
                hasMoreData={hasMoreFilterClientListData}
                id="clientLinked"
                loadMore={() => {
                  if (!isFilterClientListDataFetching && hasMoreFilterClientListData) {
                    setFilterClientCurrentPage(filterClientCurrentPage + 1);
                  }
                }}
                menuItems={filterClientListMenuItems}
                onChangeItem={checklistChangeHandlerGenerator({
                  stateVariable: filters.clients,
                  updateStateVariable: (clients) => setFilters({ ...filters, clients })
                })}
                searchable
                selectedFilterList={filters.clients}
                setSearchText={debouncedSetFilterClientSearchText}
              >
                Client Linked
              </FilterDropdown>
              <FilterDropdown
                id="tag"
                menuItems={Object.values(FaxTag).map((tag) => ({ _id: tag, name: tag }))}
                onChangeItem={checklistChangeHandlerGenerator({
                  stateVariable: filters.tags,
                  updateStateVariable: (tags) => setFilters({ ...filters, tags })
                })}
                selectedFilterList={filters.tags}
              >
                Tag
              </FilterDropdown>
            </div>
          </div>
          <div className={styles.tableSection}>
            <Table
              columns={columns}
              dataSource={faxes}
              loading={isFaxesFetching}
              onChange={handleTableChange}
              pagination={false}
              rowKey="_id"
            />
            <PaginationListV2 paging={faxesPaging} onPageChange={(page: number) => setFaxesCurrentPage(page)} />
          </div>
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Faxes;
