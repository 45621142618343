import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import styles from './SidebarBreadcrumb.module.scss';

interface SidebarBreadcrumbProps {
  baseUrl: string;
  baseLabel: string;
  subPageLabel: string;
}

const SidebarBreadcrumb = ({ baseUrl, baseLabel, subPageLabel }: SidebarBreadcrumbProps) => {
  const location = useLocation();
  const { isEdgePlanUser } = useGetAccountPackage();

  const subPage = isEdgePlanUser ? location.pathname.split('/')[4] : location.pathname.split('/')[3];

  return (
    <div className={styles.container}>
      {subPage ? (
        <>
          <Link className={styles.baseLink} to={baseUrl}>
            {baseLabel}
          </Link>
          <i className={`material-icons ${styles.icon}`}>keyboard_arrow_right</i>
          <span>{subPageLabel}</span>
        </>
      ) : (
        <span>{baseLabel}</span>
      )}
    </div>
  );
};

export default SidebarBreadcrumb;
