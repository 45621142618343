import MaterialInput from 'components/MaterialInput/MaterialInput';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { REFERRAL_STATUS_LABELS, Referral, ReferralStatus } from 'interfaces/Referral/Referral';

import styles from './AddOrEditReferralForm.module.scss';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { useState } from 'react';
import { useGetEpisodeListQuery } from 'redux/endpoints/clinicianProfileServices/episode';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { REFERRAL_SOURCE_TAGS } from 'components/AddPatientModalV2/constants';
import { Mode } from '../../ReferralsMVP';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { ReferralFieldsPayload } from 'pages/Referrals/components/AddReferralModal/AddReferralModalInterfaces';

export const PRESENTING_ISSUES = [
  'Anxiety',
  'Substance issues',
  'Adjustment issues',
  'Behavioural issues',
  'Eating issues',
  'Gender Identity',
  'Grief & Loss',
  'Health issues',
  'Low mood/Depression',
  'Relationship difficulties',
  'Self-harm',
  'Sleep Issues',
  'Stress',
  'Suicidal ideation',
  'Trauma',
  'Other'
];

export const REFERRER_ROLES = [
  'General Practitioner - GP',
  'Medical Practice',
  'Nurse',
  'School Counsellor',
  'Teacher',
  'Case Manager',
  'Charity',
  'Community Service',
  'Private Health Insurer',
  'Employer/EAP',
  'Hospital',
  'Workcover',
  '3rd Party Funder',
  'NDIS',
  'Other'
];

interface AddOrEditReferralFormProps {
  clientRecordId: string;
  currentIndex: number;
  mode: 'add' | 'edit';
  selectedReferral?: Referral;
  setMode: (mode: Mode) => void;
  handleSubmit: (payload: ReferralFieldsPayload) => Promise<void>;
  onCancel?: () => void;
}

const AddOrEditReferralForm = ({
  clientRecordId,
  currentIndex,
  selectedReferral,
  mode,
  setMode,
  handleSubmit,
  onCancel
}: AddOrEditReferralFormProps) => {
  const { accountTimeZone } = useTimeZone();

  const { isEoCEnabled } = useGetFeatureToggle();

  const [referralPayload, setReferralPayload] = useState<ReferralFieldsPayload>({
    referrer: {
      name: selectedReferral?.referrer.name || '',
      role: selectedReferral?.referrer.role || '',
      providerNumber: selectedReferral?.referrer.providerNumber || '',
      fax: selectedReferral?.referrer.fax || '',
      mobile: selectedReferral?.referrer.mobile || '',
      email: selectedReferral?.referrer.email || ''
    },
    date: selectedReferral?.date || '',
    detail: selectedReferral?.detail || '',
    source: selectedReferral?.source || '',
    status: selectedReferral?.status || ReferralStatus.New,
    presentingIssue: selectedReferral?.presentingIssue || '',
    linkedEpisodes: selectedReferral?.linkedEpisodes || []
  });
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const [savingStatus, setSavingStatus] = useState<ButtonStatusType>('');

  const { data: episodeListResponse } = useGetEpisodeListQuery({
    clientRecordId,
    timezone: accountTimeZone
  });

  const handleSaveReferral = async () => {
    setSavingStatus('active');

    // validate required fields
    if (!referralPayload.referrer.name) {
      setSavingStatus('');
      setErrors({ ...errors, name: 'Name is required' });
      return;
    }

    if (!referralPayload.referrer.role) {
      setSavingStatus('');
      setErrors({ ...errors, role: 'Role is required' });
      return;
    }

    if (!referralPayload.date) {
      setSavingStatus('');
      setErrors({ ...errors, date: 'Date is required' });
      return;
    }

    if (!referralPayload.detail) {
      setSavingStatus('');
      setErrors({ ...errors, detail: 'Detail is required' });
      return;
    }

    if (!referralPayload.source) {
      setSavingStatus('');
      setErrors({ ...errors, source: 'Source is required' });
      return;
    }

    if (!referralPayload.status) {
      setSavingStatus('');
      setErrors({ ...errors, status: 'Status is required' });
      return;
    }

    setErrors({});

    // save
    await handleSubmit(referralPayload);

    setSavingStatus('finished');
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.formHeader}>
        <div className={styles.formTitle}>
          {mode === Mode.Add ? 'Add' : 'Edit'} Referral #{currentIndex + 1}
        </div>
        <ButtonAlt
          onClick={() => {
            setMode(Mode.View);
            onCancel?.();
          }}
          icon="close"
          variant="text"
        />
      </div>
      <div className={styles.formContent}>
        <MaterialInput
          id={'referrerName'}
          label="Referrer name*"
          value={referralPayload.referrer.name}
          placeholder="Name"
          onChange={(e) => {
            setReferralPayload({
              ...referralPayload,
              referrer: {
                ...referralPayload.referrer,
                name: e.target.value
              }
            });

            if (errors.name && e.target.value) {
              setErrors({ ...errors, name: undefined });
            }
          }}
          error={!!errors.name}
          errorMessage={errors.name}
        />
        <MaterialSelect
          className={styles.selectOffset}
          label="Referrer role*"
          isSearchable
          options={REFERRER_ROLES.map((role) => ({
            value: role,
            label: role
          }))}
          value={referralPayload.referrer.role}
          onChange={(value) => {
            setReferralPayload({
              ...referralPayload,
              referrer: {
                ...referralPayload.referrer,
                role: value
              }
            });

            if (errors.role && value) {
              setErrors({ ...errors, role: undefined });
            }
          }}
          noCreateNewOption
          error={!!errors.role}
          errorMessage={errors.role}
        />
        <MaterialInput
          id={'providerNumber'}
          label="Provider number"
          value={referralPayload.referrer.providerNumber}
          onChange={(e) =>
            setReferralPayload({
              ...referralPayload,
              referrer: {
                ...referralPayload.referrer,
                providerNumber: e.target.value
              }
            })
          }
          placeholder="Provider number"
        />
        <MaterialInput
          id={'referrerEfax'}
          label="Referrer efax number"
          icon="fax"
          placeholder="efax number"
          value={referralPayload.referrer.fax}
          onChange={(e) =>
            setReferralPayload({
              ...referralPayload,
              referrer: {
                ...referralPayload.referrer,
                fax: e.target.value
              }
            })
          }
          type="tel"
        />
        <MaterialInput
          id={'referralMobileNumber'}
          label="Referrer mobile number"
          icon="phone"
          placeholder="Mobile number"
          value={referralPayload.referrer.mobile}
          onChange={(e) =>
            setReferralPayload({
              ...referralPayload,
              referrer: {
                ...referralPayload.referrer,
                mobile: e.target.value
              }
            })
          }
          type="tel"
        />
        <MaterialInput
          id={'referrerEmail'}
          label="Referrer email"
          icon="email"
          placeholder="Email"
          value={referralPayload.referrer.email}
          onChange={(e) =>
            setReferralPayload({
              ...referralPayload,
              referrer: {
                ...referralPayload.referrer,
                email: e.target.value
              }
            })
          }
          type="email"
        />
        <MaterialInput
          id={'referralDate'}
          label="Referral date*"
          placeholder="Date"
          value={referralPayload.date}
          onChange={(e) => {
            setReferralPayload({
              ...referralPayload,
              date: e.target.value
            });

            if (errors.date && e.target.value) {
              setErrors({ ...errors, date: undefined });
            }
          }}
          type="date"
          error={!!errors.date}
          errorMessage={errors.date}
        />
        <MaterialInput
          id={'referralDetails'}
          label="Referral detail/number*"
          placeholder="Detail/number"
          value={referralPayload.detail}
          onChange={(e) => {
            setReferralPayload({
              ...referralPayload,
              detail: e.target.value
            });

            if (errors.detail && e.target.value) {
              setErrors({ ...errors, detail: undefined });
            }
          }}
          error={!!errors.detail}
          errorMessage={errors.detail}
        />
        <MaterialSelect
          className={styles.selectOffset}
          label="Referral source*"
          isSearchable
          options={REFERRAL_SOURCE_TAGS.map((source) => ({
            value: source,
            label: source
          }))}
          value={referralPayload.source}
          onChange={(value) => {
            setReferralPayload({
              ...referralPayload,
              source: value
            });

            if (errors.source && value) {
              setErrors({ ...errors, source: undefined });
            }
          }}
          noCreateNewOption
          error={!!errors.source}
          errorMessage={errors.source}
        />
        <MaterialSelect
          className={styles.selectOffset}
          label="Status*"
          isSearchable
          options={Object.values(ReferralStatus).map((status) => ({
            value: status,
            label: REFERRAL_STATUS_LABELS[status].label
          }))}
          value={referralPayload.status}
          onChange={(value) => {
            setReferralPayload({
              ...referralPayload,
              status: value
            });

            if (errors.status && value) {
              setErrors({ ...errors, status: undefined });
            }
          }}
          noCreateNewOption
          error={!!errors.status}
          errorMessage={errors.status}
        />
        <MaterialSelect
          className={styles.selectOffset}
          label="Presenting issue"
          isSearchable
          options={PRESENTING_ISSUES.map((issue) => ({
            value: issue,
            label: issue
          }))}
          value={referralPayload.presentingIssue}
          onChange={(value) =>
            setReferralPayload({
              ...referralPayload,
              presentingIssue: value
            })
          }
          noCreateNewOption
        />
        {isEoCEnabled && (
          <MaterialSelect
            className={styles.selectOffset}
            label="Care episode"
            isSearchable
            options={episodeListResponse?.episodes.map((episode) => ({
              value: episode._id,
              label: `Episode #${episode.order} | ${episode.status}`
            }))}
            value={referralPayload.linkedEpisodes?.[0] || ''}
            onChange={(value) =>
              setReferralPayload({
                ...referralPayload,
                linkedEpisodes: [value]
              })
            }
            noCreateNewOption
          />
        )}
      </div>
      <ButtonAlt status={savingStatus} onClick={() => handleSaveReferral()}>
        Save
      </ButtonAlt>
    </div>
  );
};

export default AddOrEditReferralForm;
