import { ProfileInterface } from 'interfaces/Profile/Profile';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import { useRef, useState } from 'react';
import { useListGroupActivityFeed } from './hook/getListGroupActivityFeed';
import styles from './GroupActivity.module.scss';
import { Dropdown, Menu, Skeleton } from 'antd';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import InfiniteScroller from 'react-infinite-scroller';
import { DownOutlined } from '@ant-design/icons';
import { groupBy, map } from 'lodash';
import moment from 'moment';
import GroupMonthActivityFeed from './GroupMonthActivityFeed/GroupMonthActivityFeed';
import { TFunction, useTranslation } from 'react-i18next';

export enum GroupActivityObject {
  Client = 'client',
  Group = 'group',
  Practitioner = 'practitioner',
  CaseNote = 'case note',
  Attachment = 'attachment',
  AdHocAssessment = 'ad hoc assessment',
  AdHocSurvey = 'ad hoc survey',
  Invoice = 'invoice'
}

export enum GroupActivityAction {
  Activated = 'activated',
  Assigned = 'assigned',
  Closed = 'closed',
  Unassigned = 'unassigned',
  Removed = 'removed',
  StatusChanged = 'status changed',
  Created = 'created',
  Deleted = 'deleted',
  Updated = 'updated',
  Uploaded = 'uploaded',
  Sent = 'sent'
}

export enum GroupActivityActor {
  Patient = 'patient',
  Clinician = 'clinician',
  System = 'system'
}

const getFilterOptions = (t: TFunction) => [
  { label: t('label.all.capitalize'), value: 'all' },
  { label: t('label.practitioner.capitalize'), value: GroupActivityObject.Practitioner },
  { label: t('label.client.capitalize'), value: GroupActivityObject.Client },
  { label: t('label.attachment.capitalize'), value: GroupActivityObject.Attachment },
  { label: t('label.case_notes.capitalize'), value: GroupActivityObject.CaseNote },
  { label: t('label.group.capitalize'), value: GroupActivityObject.Group }
];

export interface GroupActivity {
  accountId: string;
  groupId: string;
  clinicianId?: string;
  clinicianName?: string;
  originId?: string;
  object: GroupActivityObject;
  action: GroupActivityAction;
  actor: GroupActivityActor;
  description: string;
  createdAt: Date;
  createdBy?: string;
}

interface GroupActivityFeedProps {
  groupDetails: Groups;
  profile: ProfileInterface;
}

const GroupActivityFeed = ({ groupDetails, profile }: GroupActivityFeedProps) => {
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const [t] = useTranslation();

  const { fetching, activityFeed, hasMoreActivity, fetchActivityFeed } = useListGroupActivityFeed(
    groupDetails._id,
    selectedFilter
  );

  const infiniteScrollContainerRef = useRef<HTMLDivElement>(null);

  const handleFilterClick = (filter: string) => {
    if (filter === selectedFilter) {
      setSelectedFilter('all');
    } else {
      setSelectedFilter(filter);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerFilter}>
        <div className={styles.filterList}>
          <div className={styles.filterTitle}>Filter</div>
          <div>
            <Dropdown
              overlay={
                <Menu selectedKeys={[selectedFilter]} onClick={({ key }) => handleFilterClick(key as string)}>
                  {getFilterOptions(t).map((option) => (
                    <Menu.Item key={option.value}>
                      <div>{option.label}</div>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <div className={styles.headerFilterDropdown} onClick={(e) => e.preventDefault()}>
                {getFilterOptions(t).find((option) => option.value === selectedFilter)?.label} <DownOutlined />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {fetching ? (
          <Skeleton active />
        ) : !activityFeed.length ? (
          <div className={styles.noActivity}>No activity recorded.</div>
        ) : (
          <div className={styles.monthGroupContainer} ref={infiniteScrollContainerRef}>
            <InfiniteScroller
              getScrollParent={() => infiniteScrollContainerRef.current}
              initialLoad={false}
              loader={
                <div className={styles.activityFeedLoading} key={0}>
                  <LoadingCircle />
                </div>
              }
              loadMore={() => fetchActivityFeed(false)}
              useWindow={false}
              hasMore={hasMoreActivity}
            >
              {map(
                groupBy(activityFeed, (activity) => moment(activity.createdAt).format('MMMM YYYY')),
                (items, monthString) => {
                  return (
                    <GroupMonthActivityFeed
                      key={monthString}
                      items={items}
                      monthString={monthString}
                      groupDetails={groupDetails}
                      profile={profile}
                    />
                  );
                }
              )}
            </InfiniteScroller>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupActivityFeed;
