import classnames from 'classnames';
import { useField } from 'formik';
import moment from 'moment';

import styles from './FixedTimeSelection.module.scss';
import TimePicker from 'components/v2/TimePicker/TimePicker';
import { FixedTimeSelection as FixedTimeSelectionType } from 'interfaces/Schedule/AppointmentType';

const TIME_FORMAT = 'hh:mm A';

interface FixedTimeSelectionProps {
  day: string;
  duration: number;
}

const FixedTimeSelection = ({ day, duration }: FixedTimeSelectionProps) => {
  const [, { value, error }, { setValue }] = useField<FixedTimeSelectionType>(`dayTimeSelections.fixedTime.${day}`);
  const [, { error: startTimesError, touched }] = useField(`dayTimeSelections.fixedTime.${day}.startTimes`);

  const isActive = value.isActive;
  const startTimes = value.startTimes;
  const sortedStartTimes = [...startTimes].sort((a: string, b: string) =>
    moment(a, TIME_FORMAT).diff(moment(b, TIME_FORMAT))
  );
  const errorStartTimes = !startTimesError
    ? []
    : sortedStartTimes
        .filter((secondSlot, index) => {
          if (index > 0) {
            const gap = moment(secondSlot, TIME_FORMAT).diff(
              moment(sortedStartTimes[index - 1], TIME_FORMAT),
              'minutes'
            );
            if (gap < duration) {
              return secondSlot;
            }
          }
          return false;
        })
        .filter((item) => item);

  const toggleActive = () => {
    setValue({
      isActive: !isActive,
      startTimes: !isActive ? [] : startTimes
    });
  };

  const addTime = () => {
    const newStartTimes = [...startTimes, '12:00 am'];
    setValue({
      ...value,
      startTimes: newStartTimes
    });
  };

  const onChange = (inputIndex: number) => (selectedTime: string) => {
    const time = moment(selectedTime, TIME_FORMAT);

    if (time) {
      const newStartTimes = startTimes.map((startime, index) => (index === inputIndex ? selectedTime : startime));
      setValue({
        ...value,
        startTimes: newStartTimes
      });
    }
  };

  const onRemove = (indexToRemove: number) => () => {
    const newStartTimes = startTimes.filter((st, index) => index !== indexToRemove);
    setValue({
      ...value,
      startTimes: newStartTimes
    });
  };

  return (
    <>
      <div className={classnames(styles.container, isActive && styles.active)}>
        <div className={styles.checkboxContainer}>
          <input
            className={styles.checkbox}
            id={`checkbox-${day}-isActive`}
            type="checkbox"
            checked={isActive}
            onChange={toggleActive}
          />
          <label className={styles.label} htmlFor={`checkbox-${day}-isActive`}>
            {day}
          </label>
        </div>
      </div>
      <div className={classnames({ [styles.background]: isActive }, styles.row, styles.inputs)}>
        {isActive &&
          startTimes.map((startAt, index) => (
            <div key={index}>
              <div>
                <TimePicker
                  key={index}
                  time={startAt}
                  minuteStep={5}
                  error={errorStartTimes.includes(startAt)}
                  onRemove={onRemove(index)}
                  onChange={onChange(index)}
                />
                {errorStartTimes.includes(startAt) && (
                  <div className={styles.errorText}>Start times for this duration are overlapping.</div>
                )}
              </div>
              <button className={styles.addButton} onClick={onRemove(index)}>
                <i className="material-icons-outlined">remove</i>
              </button>
            </div>
          ))}
        {isActive && (
          <button className={styles.addButton} onClick={addTime}>
            <i className="material-icons-outlined">add</i>
          </button>
        )}
        {typeof error == 'string' && touched && <span className={styles.errorText}>{error}</span>}
      </div>
    </>
  );
};
export default FixedTimeSelection;
