import { Skeleton } from 'antd';
import classNames from 'classnames';
import { handleCopyContent } from 'utils/CopyFeature';
import styles from './ShareableLink.module.scss';

const ShareableLink = ({
  iconClassName,
  iconOnly,
  isLoading,
  label,
  url = ''
}: {
  iconClassName?: string;
  iconOnly?: boolean;
  isLoading?: boolean;
  label?: string;
  url?: string;
}) => {
  if (isLoading) {
    return <Skeleton.Input active className={styles.loading} />;
  }

  const copyIcon = (
    <span className={classNames('material-icons', iconClassName)} onClick={() => handleCopyContent(url)}>
      content_copy
    </span>
  );

  return (
    <>
      {iconOnly ? (
        copyIcon
      ) : (
        <div className={styles.shareLinkWrapper} id={'shareLinkWrapperId'}>
          <div className={styles.shareLinkLabel}>{label}</div>
          <div className={styles.linkAndCopy}>
            <a href={url} className={styles.shareLink}>
              {url}
            </a>
            {copyIcon}
          </div>
        </div>
      )}
    </>
  );
};

export default ShareableLink;
