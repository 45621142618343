import styles from './InvoiceActionMenu.module.scss';
import { useEffect, useRef, useState } from 'react';
import { Invoice, InvoiceStatus, OnChangeInvoiceStatus } from 'pages/Invoices/interface';
import { Tooltip } from 'react-tooltip';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface InvoiceActionMenuProps {
  isInvoiceActionProcessing: boolean;
  status: Invoice['status'];
  onChangeStatus: OnChangeInvoiceStatus;
  onResendInvoice: (_id: string, groupDetail?: Invoice['group']) => void;
  invoiceId: string;
  groupInvoiceDetail?: Invoice['group'];
}

const InvoiceActionMenu = ({
  isInvoiceActionProcessing,
  status,
  onChangeStatus,
  onResendInvoice,
  invoiceId,
  groupInvoiceDetail
}: InvoiceActionMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const isDraft = status === InvoiceStatus.Draft;

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const handleClickOutSide = (event: any) => {
    if (menuRef.current?.contains(event.target)) {
      return;
    }
    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const handleSelectButtonClick = () => {
    if (isDraft) {
      return;
    }
    setShowMenu(!showMenu);
  };

  const handleResendInvoice = () => {
    handleCloseMenu();
    onResendInvoice(invoiceId, groupInvoiceDetail);
  };

  const handleChangeStatus = (status: InvoiceStatus.Closed | InvoiceStatus.ConfirmPaid) => () => {
    handleCloseMenu();
    onChangeStatus(invoiceId, status, groupInvoiceDetail);
  };

  return (
    <div ref={menuRef} className={styles.actionButtonWrapper}>
      <div data-tooltip-id={`action-button-${invoiceId}`}>
        <ButtonAlt
          size="medium"
          fullWidth
          variant="outlined"
          onClick={!isInvoiceActionProcessing ? handleSelectButtonClick : () => {}}
          icon="arrow_drop_down"
          iconPostFix
          disabled={isDraft}
          status={isInvoiceActionProcessing ? 'active' : ''}
        >
          Select...
        </ButtonAlt>
      </div>
      <Tooltip id={`action-button-${invoiceId}`} className={styles.tooltip} hidden={!isDraft}>
        This invoice is still in draft mode.
      </Tooltip>
      <div className={styles.menuWrapper}>
        <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
          <div className={styles.listBox}>
            <div className={styles.listTitle} onClick={handleResendInvoice}>
              Resend Invoice
            </div>
          </div>
          <div className={styles.listBox} onClick={handleChangeStatus(InvoiceStatus.ConfirmPaid)}>
            <div className={styles.listTitle}>Change Status to Paid</div>
          </div>
          <div className={styles.listBox} onClick={handleChangeStatus(InvoiceStatus.Closed)}>
            <div className={styles.listTitle}>Change Status to Closed</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceActionMenu;
