import { Skeleton } from 'antd';
import classnames from 'classnames';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useFetchPractitionerList } from 'components/v2/ClinicianSelect/hooks/GetPractitionersList';
import ProfileBadge from 'components/v2/ProfileBadge/ProfileBadge';
import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { Collaborator } from 'interfaces/Reports/report';
import { useEffect, useRef, useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';

import styles from './SelectReviewer.module.scss';

interface SelectReviewerProps {
  onChange: (reviewer: Collaborator[]) => void;
  selectedReviewers: Collaborator[];
  selectedId?: string;
  backgroundColor?: string;
  materialStyle?: boolean;
  className?: string;
  validationErrors?: boolean;
  excludeId?: string;
}

interface ReviewerDetails extends PractitionersDetailsInterface {
  isSelected: boolean;
}

const SelectReviewer: React.FC<SelectReviewerProps> = ({
  onChange,
  selectedReviewers,
  backgroundColor,
  materialStyle,
  className,
  validationErrors,
  excludeId
}) => {
  const { token } = useGetAccessToken();
  const node = useRef<HTMLDivElement>(null);
  const { practitionersList, isPractitionersListLoading } = useFetchPractitionerList(token, false, [AccessRight.Admin]);
  const [showList, setShowList] = useState(false);
  const [reviewers, setReviewers] = useState<ReviewerDetails[]>([]);

  useEffect(() => {
    const newList = practitionersList
      .filter((practitioner) => practitioner._id !== excludeId)
      .map((practitioner) => ({
        ...practitioner,
        isSelected: selectedReviewers.some((selected) => selected.clinicianAuth0Id === practitioner._id)
      }));
    setReviewers(newList);
  }, [practitionersList, selectedReviewers, excludeId]);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setShowList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const onClickReviewer = (id: string) => {
    const newReviewers = reviewers.map((reviewer) => ({
      ...reviewer,
      isSelected: reviewer._id === id ? !reviewer.isSelected : reviewer.isSelected
    }));
    setReviewers(newReviewers);
    const filteredReviewers = newReviewers
      .filter(({ isSelected }) => isSelected)
      .map(({ _id, name, avatar }) => ({
        clinicianAuth0Id: _id,
        name: name,
        avatar: avatar
      }));

    onChange(filteredReviewers);
  };

  return (
    <div ref={node} id={'selectReviewer'} className={classnames(styles.container, className)}>
      {isPractitionersListLoading ? (
        <div className={classnames(materialStyle ? styles.materialLoadingBox : styles.loadingBox)}>
          <Skeleton.Input active className={styles.skeletonLoading} />
        </div>
      ) : (
        <>
          <div
            className={materialStyle ? styles.selectedMaterialBox : styles.selectedBox}
            onClick={() => setShowList(!showList)}
            style={{ backgroundColor }}
          >
            <ProfileBadge name={'Select Reviewer'} nameClassName={styles.selectLabel} />
            <ButtonAlt variant={'text'} fab size={'medium'} icon={'arrow_drop_down'} />
          </div>
          <div className={styles.listWrapper}>
            <div className={showList ? styles.listShow : styles.listHide}>
              <div className={styles.itemWrapper}>
                {reviewers.map((practitioner) => (
                  <div className={styles.item} key={practitioner._id} onClick={() => onClickReviewer(practitioner._id)}>
                    <ProfileBadge name={practitioner.name} avatar={practitioner.avatar} />
                    <input className={styles.checkbox} type="checkbox" checked={practitioner.isSelected} readOnly />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {validationErrors && selectedReviewers.length < 1 && (
            <div className={styles.error}>Please select at least one reviewer.</div>
          )}
        </>
      )}
    </div>
  );
};

export default SelectReviewer;
