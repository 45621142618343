import { useRef, useState } from 'react';
import styles from './WriteOffActionMenu.module.scss';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';
import { useNavigate } from 'react-router-dom';
import { InvoiceWithType } from 'pages/InvoicesV2/components/InvoiceList/constants';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface WriteOffActionMenuProps {
  invoice: InvoiceWithType;
}

const WriteOffActionMenu = ({ invoice }: WriteOffActionMenuProps) => {
  const navigate = useNavigate();
  const { INVOICES } = useRoutesGenerator();

  const menuNode = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const clickOutsideHandler = () => {
    setIsExpanded(false);
  };

  useOnClickOutside(menuNode, clickOutsideHandler);

  const handleExpandMenu = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div ref={menuNode} className={styles.container}>
      <ButtonAlt size="medium" variant="text" fab icon="more_vert" onClick={handleExpandMenu} />
      <div className={styles.menuWrapper}>
        <div className={isExpanded ? styles.menuShow : styles.menuHide}>
          {/* View Invoice */}
          <div className={styles.menuItem}>
            <ThreeDotMenuItem label="View Invoice" onClick={() => navigate(`${INVOICES.BASE}/${invoice._id}`)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WriteOffActionMenu;
