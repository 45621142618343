import { ProfileTagsInterface } from 'interfaces/Clients/profileTags';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';

export const profileTagApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfileTags: builder.query<ProfileTagsInterface[], { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/accounts/${accountId}/client-record-tags`
      }),
      providesTags: [CPSTagTypes.ProfileTag]
    })
  })
});

export const { useGetProfileTagsQuery } = profileTagApiSlice;
