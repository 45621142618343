import styles from './ExpandableContent.module.scss';
import classNames from 'classnames';

interface ExpandableContentProps {
  expanded: boolean;
  minHeight?: boolean;
  className?: string;
  showContentClassName?: string;
  children: any;
}

const ExpandableContent = ({
  expanded,
  children,
  minHeight,
  className,
  showContentClassName
}: ExpandableContentProps) => {
  const hideAllContent = expanded ? classNames(styles.showContent, showContentClassName) : styles.hideContent;
  const hideWithHeight = expanded ? classNames(styles.showContent, showContentClassName) : styles.hideMinHeightContent;
  return <div className={`${className} ${minHeight ? hideWithHeight : hideAllContent}`}>{children}</div>;
};

export default ExpandableContent;
