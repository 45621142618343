import ListHeaderItem from 'components/T23/ListHeaderItem/ListHeaderItem';
import styles from './InvoicePaymentsListHeader.module.scss';
import columnStyles from './InvoicePaymentColumn.module.scss';
import { InvoicePaymentsListColumn } from './columns';
import { getColumnNameFromId } from 'components/T23/ListHeaderItem/helper';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  InvoicePaymentsSorting,
  InvoicePaymentsTab,
  selectActiveTab,
  selectSorting,
  setSorting
} from 'redux/features/invoicePayments/invoicePaymentsSlice';
import { SortType } from 'redux/features/utils';

const InvoicePaymentsListHeader = () => {
  const dispatch = useAppDispatch();

  const sorting = useAppSelector(selectSorting);
  const activeTab = useAppSelector(selectActiveTab);

  const onClickSort = (newSortBy: string) => {
    let newSorting: InvoicePaymentsSorting = { sortBy: newSortBy, sortType: SortType.DESC };
    if (newSortBy === sortBy) {
      if (sorting.sortType === SortType.DESC) {
        newSorting.sortType = SortType.ASC;
      } else if (sorting.sortType === SortType.ASC) {
        newSorting.sortBy = undefined;
      } else {
        newSorting.sortType = SortType.DESC;
      }
    }
    dispatch(setSorting(newSorting));
  };

  const { sortBy, sortType } = sorting;

  return (
    <div className={styles.container}>
      {InvoicePaymentsListColumn.map((column, index) => (
        <ListHeaderItem
          key={index}
          columnId={column.id}
          className={columnStyles[column.id]}
          sortBy={sortBy}
          sortType={sortType}
          columnName={
            activeTab === InvoicePaymentsTab.Scheduled && column.id === 'chargeId'
              ? 'Appointment Id'
              : activeTab === InvoicePaymentsTab.Scheduled && column.id === 'received'
              ? 'Scheduled'
              : getColumnNameFromId(column.id)
          }
          sortAble={column.sortAble}
          onSort={onClickSort}
        />
      ))}
      <div className={columnStyles.actions} />
    </div>
  );
};

export default InvoicePaymentsListHeader;
