import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './Card.module.scss';

interface CardProps {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}

export const Card = ({ children, className, onClick }: CardProps) => {
  return (
    <div className={classNames(styles.container, className)} onClick={onClick}>
      {children}
    </div>
  );
};

export default Card;
