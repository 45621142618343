import WidgetTextEditor2 from 'components/WidgetTextEditor2/WidgetTextEditor2';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { CaseNote } from 'interfaces/caseNote';
import _, { debounce } from 'lodash';
import { CaseNoteTemplate } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './PatientDetailsNoteDetailsForm.module.scss';
import CaseNoteAttachmentBtn from './components/CaseNoteAttachmentBtn/CaseNoteAttachmentBtn';
import CaseNoteHeaderOption from './components/CaseNoteHeaderOption/CaseNoteHeaderOption';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import * as Yup from 'yup';

interface PatientDetailsNoteDetailsFormProps {
  recordId: string;
  clientRole?: string;
  profileId: string;
  isSubmitting: boolean;
  noteId: string;
  isStartEditing: boolean;
  setIsStartEditing: React.Dispatch<React.SetStateAction<boolean>>;
  token: string;
  formData: CaseNote;
  saveButtonStatus: '' | 'active' | 'finished';
  onSubmit: any;
  onValueChange: (b: boolean) => void;
  safeguardingAlertExistInFields: boolean;
  currentTemplate?: CaseNoteTemplate;
  handleSelectTemplate: (id: string, confirm?: true) => Promise<void>;
  isPrivateFolderSelected: boolean;
  isTemplateListActive: boolean;
  templateList?: CaseNoteTemplate[];
}

const PatientDetailsNoteDetailsForm = ({
  recordId,
  clientRole,
  profileId,
  formData,
  isSubmitting,
  saveButtonStatus,
  onSubmit,
  noteId,
  isStartEditing,
  setIsStartEditing,
  token,
  onValueChange,
  safeguardingAlertExistInFields,
  currentTemplate,
  handleSelectTemplate,
  isPrivateFolderSelected,
  isTemplateListActive,
  templateList
}: PatientDetailsNoteDetailsFormProps) => {
  const { _id, ...caseNoteInitialData } = formData;
  const [caseNoteDetails, setCaseNoteDetails] = useState(caseNoteInitialData);
  const [caseNoteBody, setCaseNoteBody] = useState(caseNoteInitialData.body);
  const [isShowError, setIsShowError] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [isPrivateInput, setIsPrivateInput] = useState(
    noteId === 'new' ? (isPrivateFolderSelected ? true : false) : !!caseNoteDetails.isPrivate
  );
  const { isEoCEnabled } = useGetFeatureToggle();

  useEffect(() => {
    if (noteId === 'new') {
      const { _id, ...caseNoteInitialData } = formData;

      if (isPrivateFolderSelected) {
        caseNoteInitialData.isPrivate = true;
      }

      setCaseNoteDetails(caseNoteInitialData);
      setCaseNoteBody(caseNoteInitialData.body);
    }
    // Do not reassign isPrivate field when isPrivateFolderSelected is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, noteId]);

  const validateField = useCallback(
    (caseNoteValue: Omit<CaseNote, '_id'>) => {
      const mandatoryFields = caseNoteValue.fields.filter((obj) => obj.mandatory && obj.name !== 'Safeguarding Alert');

      const areMandatoryFieldsValid = !mandatoryFields.some((obj) =>
        obj.type !== 'multipleChoiceCount'
          ? !obj.value
          : obj.options.filter((countObj: any) => countObj.count > 0).length <= 0
      );

      const areNumberFieldsValid = !mandatoryFields.some(
        (obj) => obj.type === 'number' && !obj.value?.match('^[0-9]*$')
      );

      const areEmailFieldsValid = !caseNoteValue.fields.some(
        (obj) => obj.type === 'email' && !Yup.string().email().isValidSync(obj.value)
      );

      const arePhoneFieldsValid = !caseNoteValue.fields.some(
        (obj) =>
          obj.type === 'phone' &&
          !Yup.string()
            .matches(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g)
            .isValidSync(obj.value)
      );

      const isSafeguardingMandatory =
        (safeguardingAlertExistInFields &&
          caseNoteValue.fields.find((obj) => obj.name === 'Safeguarding Alert')?.mandatory === true) ||
        false;

      const isSafeguardingValid =
        ((isSafeguardingMandatory && caseNoteValue && typeof caseNoteValue.safeguardingAlert === 'boolean') ||
          !isSafeguardingMandatory) &&
        (!caseNoteValue.safeguardingAlert ||
          (caseNoteValue.safeguardingAlert && !_.isEmpty(caseNoteValue.safeguardingReason)));

      return (
        caseNoteValue.title.length > 0 &&
        isSafeguardingValid &&
        areMandatoryFieldsValid &&
        areNumberFieldsValid &&
        areEmailFieldsValid &&
        arePhoneFieldsValid
      );
    },
    [safeguardingAlertExistInFields]
  );

  const debouncedSave = useMemo(
    () =>
      debounce(
        async (caseNote) => {
          const safeguarding =
            !caseNote.safeguardingAlert || (caseNote.safeguardingAlert && !_.isEmpty(caseNote.safeguardingReason));

          if (validateField(caseNote) && safeguarding && caseNote.title.length > 0 && !isSubmitting && isStartEditing) {
            await onSubmit(caseNote, true);
          }
        },
        noteId === 'new' ? 1000 : 2000
      ),
    [noteId, isSubmitting, onSubmit, isStartEditing, validateField]
  );

  const onChangeCaseNoteBody = (value: string) => {
    setIsStartEditing(true);
    setCaseNoteBody(value);
    const caseNoteObj = {
      ...caseNoteDetails,
      body: value
    };
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj);
    }
  };

  const handleTitleChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      title: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj);
    }
  };

  const handleSafeguardingAlertChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      safeguardingAlert: val,
      safeguardingReason: val ? caseNoteDetails.safeguardingReason : '',
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj);
    }
  };

  const handleFolderChange = (val: boolean) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      isPrivate: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj);
    }
  };

  const handleSafegaurdingReasonChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      safeguardingReason: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj);
    }
  };

  const handleFieldChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      fields: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj);
    }
  };

  const handleAttachmentChange = async (attachmentVal: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      body: caseNoteBody,
      attachments: attachmentVal
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
    }
  };

  const handleAttachmentUploadStatus = (uploadStatus: boolean) => {
    setUploadStatus(uploadStatus);
  };

  const handleEpisodeIdChange = (episodeIdValue: string) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      episodeId: episodeIdValue,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj);
    }
  };

  const handleSubmit = () => {
    const checkMandatoryFill = validateField(caseNoteDetails);
    setIsShowError(!checkMandatoryFill);

    if (!checkMandatoryFill) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (checkMandatoryFill && caseNoteDetails.title) {
      const caseNoteObj = {
        ...caseNoteDetails,
        body: caseNoteBody
      };
      onSubmit(caseNoteObj, false);
    }
  };

  return (
    <>
      <CaseNoteHeaderOption
        noteId={noteId}
        recordId={recordId}
        clientRole={clientRole}
        fieldsData={caseNoteDetails.fields}
        safeguardingAlert={caseNoteDetails.safeguardingAlert}
        safeguardingReason={caseNoteDetails.safeguardingReason}
        title={caseNoteDetails.title}
        onChangeFieldValue={handleFieldChange}
        onChangeTitle={handleTitleChange}
        onChangeSafeguardingAlert={handleSafeguardingAlertChange}
        onChangeSafeguardingReason={handleSafegaurdingReasonChange}
        isValidated={isShowError}
        currentTemplate={currentTemplate}
        handleSelectTemplate={handleSelectTemplate}
        isPrivateInput={isPrivateInput}
        isTemplateListActive={isTemplateListActive}
        onChangeFolder={handleFolderChange}
        setIsPrivateInput={setIsPrivateInput}
        templateList={templateList}
        episodeTaggingField={isEoCEnabled}
        episodeId={caseNoteDetails.episodeId}
        onChangeEpisodeId={handleEpisodeIdChange}
      />
      <div className={styles.editor}>
        {/* <WidgetTextEditor
          className="case-notes-text-editor"
          containerClass={styles.textEditorContainer}
          value={caseNoteBody}
          onChangeValue={(value: string) => onChangeCaseNoteBody(value)}
          fullHeightEditor
        /> */}
        <WidgetTextEditor2
          minHeight={350}
          height={'auto'}
          value={caseNoteBody}
          className="case-notes-text-editor"
          onChange={onChangeCaseNoteBody}
          recordId={recordId}
          profileId={profileId}
          readOnly={false}
          withCaseNoteHeaderButton
        />
        <CaseNoteAttachmentBtn
          caseNoteAttachmentData={caseNoteDetails.attachments}
          token={token}
          recordId={recordId}
          uploadStatus={handleAttachmentUploadStatus}
          onChangeCaseNoteAttachment={handleAttachmentChange}
        />
      </div>
      <div className={styles.saveButtonContainer}>
        <ButtonAlt
          className={styles.saveButton}
          type="submit"
          status={saveButtonStatus}
          disabled={uploadStatus || isSubmitting}
          onClick={!isSubmitting ? handleSubmit : undefined}
        >
          {uploadStatus ? 'Uploading...' : isSubmitting ? 'Saving...' : 'Save Note'}
        </ButtonAlt>
      </div>
    </>
  );
};

export default PatientDetailsNoteDetailsForm;
