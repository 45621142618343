import DropdownSearchable, { DropdownVariant } from 'components/v2/DropdownSearchable/DropdownSearchable';
import { EpisodeInterface, EpisodeStatus } from 'interfaces/Episodes/episodes';
import { useEffect, useState } from 'react';
import { useFetchEpisodes } from 'pages/PatientDetails/components/PatientDetailsContent/components/ClientDetailsEoC/hooks/useFetchEpisodes';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useGetEpisodeListQuery } from 'redux/endpoints/clinicianProfileServices/episode';
import styles from '../components/ChangeEpisodeStatusModal/ChangeEpisodeStatusModal.module.scss';
import { DetailEpisodeStatusBadge } from '../components/EpisodeStatusBadge/DetailEpisodeStatusBadge/DetailEpisodeStatusBadge';

interface UseSelectChangeEpisodeStatusProps {
  episode: EpisodeInterface;
  clientRecordId: string;
  newForm?: boolean;
}

export const useSelectChangeEpisodeStatus = ({ episode, clientRecordId }: UseSelectChangeEpisodeStatusProps) => {
  const [activeEpisodeId, setActiveEpisodeId] = useState<string>();

  const {
    draftAndPlannedEpisodeOptions,
    fetchMoreEpisodes,
    hasMore,
    pagination,
    handleUpdateEpisodes,
    handleUpdatePaging
  } = useFetchEpisodes(episode._id);

  const { accountTimeZone } = useTimeZone();

  const { data: episodeListResponse } = useGetEpisodeListQuery(
    {
      clientRecordId,
      timezone: accountTimeZone,
      params: {
        page: pagination.page,
        perPage: 30,
        status: [EpisodeStatus.Planned, EpisodeStatus.Draft].join(',')
      }
    },
    { skip: !hasMore }
  );

  useEffect(() => {
    if (episodeListResponse?.episodes && episodeListResponse?.paging) {
      handleUpdateEpisodes(episodeListResponse.episodes, activeEpisodeId || '');
      handleUpdatePaging(episodeListResponse.paging);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeListResponse]);

  const changeOtherEpisode = (checkError: boolean) => (
    <div className={styles.activeDropdownWrapper}>
      <DropdownSearchable
        controllerClassName={styles.changeEPDropdown}
        optionWrapperClassName={styles.changeEPDropdownOption}
        placeholder="Select episode"
        onSelect={(value) => setActiveEpisodeId(value)}
        selected={activeEpisodeId}
        options={draftAndPlannedEpisodeOptions}
        hasMoreData={hasMore}
        loadMore={fetchMoreEpisodes}
        variant={DropdownVariant.box}
        error={checkError && !activeEpisodeId ? 'Please select episode' : ''}
        hideErrorDesc
        hideActiveLine
        DropdownItemChildren={(itemProps) => <DetailEpisodeStatusBadge episode={itemProps.props} />}
      />
    </div>
  );

  return {
    changeOtherEpisode,
    activeEpisodeId,
    draftAndPlannedEpisodeOptions
  };
};
