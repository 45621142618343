import { useMemo, useState } from 'react';
import { DatePicker, DatePickerProps, Skeleton } from 'antd';
import { debounce } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import styles from './ReferralsFilters.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import dateFilterStyles from 'pages/InvoiceSubmittedClaims/components/SubmittedClaimFilter/DateRangePickerCustom.module.scss';
import { REFERRAL_STATUS_LABELS, ReferralStatus } from 'interfaces/Referral/Referral';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { RangePickerProps } from 'antd/lib/date-picker';
import { DateFilter, selectActiveTab } from 'redux/referrals/referralSlice';
import { useAppSelector } from 'redux/hooks';
import { ReferralTab, ReferrerType } from 'pages/Referrals/interface';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetMinifiedPackages } from 'redux/packages/useGetMinifiedPackages';

const PER_PAGE = 50;

const STATUS_OPTIONS = Object.values(ReferralStatus).map((status) => ({
  name: REFERRAL_STATUS_LABELS[status].label,
  _id: status
}));

const LINKAGE_OPTIONS = [
  { name: 'Linked', _id: 'linked' },
  { name: 'Unlinked', _id: 'unlinked' }
];

export const REFERRER_TYPE_OPTIONS = [
  { name: 'Self', _id: ReferrerType.Self },
  { name: 'Friends/Family', _id: ReferrerType.FriendFamily },
  { name: 'Organisation', _id: ReferrerType.Organisation },
  { name: 'Professional', _id: ReferrerType.Professional }
];

interface ReferralsFiltersProps {
  isLoading: boolean;
  totalItems: number;
  filterText: string;
  selectedDateRange: DateFilter;
  selectedStatuses: FilterCheckListItem[];
  selectedReferrerTypes: FilterCheckListItem[];
  selectedLinkages: FilterCheckListItem[];
  selectedPackages: FilterCheckListItem[];
  onChangeDateFilter: (date: DateFilter) => void;
  onChangeStatusFilter: (statuses: FilterCheckListItem[]) => void;
  onChangeReferrerTypeFilter: (statuses: FilterCheckListItem[]) => void;
  onChangeLinkageFilter: (linkages: FilterCheckListItem[]) => void;
  onChangePackageFilter: (packages: FilterCheckListItem[]) => void;
  onResetFilters: () => void;
}

const { RangePicker } = DatePicker;

const ReferralsFilters = ({
  isLoading,
  totalItems,
  selectedDateRange,
  selectedStatuses,
  selectedReferrerTypes,
  selectedLinkages,
  selectedPackages,
  filterText,
  onChangeDateFilter,
  onChangeStatusFilter,
  onChangeReferrerTypeFilter,
  onChangeLinkageFilter,
  onChangePackageFilter,
  onResetFilters
}: ReferralsFiltersProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const activeTab = useAppSelector(selectActiveTab);
  const { isPackageEnabled } = useGetFeatureToggle();

  const [packageListCurrentPage, setPackageListCurrentPage] = useState<number>(1);
  const [packageSearchText, setPackageSearchText] = useState<string>('');

  const {
    packages: packageListData,
    isLoading: isPackageListDataLoading,
    isFetching: isPackageListDataFetching,
    paging: packageListPaging
  } = useGetMinifiedPackages({
    page: packageListCurrentPage,
    perPage: PER_PAGE,
    ...(packageSearchText && {
      searchValue: packageSearchText,
      searchBy: 'name'
    })
  });

  const packageListFilter: FilterCheckListItem[] = packageListData || [];

  const debounceSetPackageSearchText = useMemo(
    () =>
      debounce((value) => {
        setPackageSearchText(value);
        setPackageListCurrentPage(1);
      }, 1000),
    []
  );

  const onChangeDateRange = (dateString: [string, string] | string) => {
    onChangeDateFilter({
      from: dateString[0] || null,
      to: dateString[1] || null
    });
  };

  const isDisableStatusFilter = useMemo(
    () =>
      activeTab && [ReferralTab.New, ReferralTab.Triage, ReferralTab.Decline, ReferralTab.Accepted].includes(activeTab),
    [activeTab]
  );

  const isDisableLinkageFilter = useMemo(() => activeTab === ReferralTab.Unlinked, [activeTab]);

  const showClearFilter = useMemo(
    () =>
      selectedDateRange.from ||
      selectedDateRange.to ||
      (!isDisableLinkageFilter && selectedLinkages.length > 0) ||
      (!isDisableStatusFilter && selectedStatuses.length > 0) ||
      selectedReferrerTypes.length > 0 ||
      selectedPackages.length > 0,
    [
      selectedDateRange,
      selectedLinkages,
      selectedStatuses,
      selectedReferrerTypes,
      selectedPackages,
      isDisableLinkageFilter,
      isDisableStatusFilter
    ]
  );

  const totalPackageListPage = packageListPaging ? Math.ceil(packageListPaging.totalItems / PER_PAGE) : 1;

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <div className={styles.numberOfMatched}>
          {isLoading ? (
            <div className={styles.loadingProfile}>
              <Skeleton.Input className={styles.loading} active />
            </div>
          ) : (
            <div>
              {showClearFilter ? 'FILTER matches' : 'Showing'} <span className={styles.highLight}>{totalItems}</span>{' '}
              {filterText}
            </div>
          )}
        </div>
        <ButtonAlt
          error
          disabled={!showClearFilter}
          size="small"
          variant="text"
          className={styles.clearFilters}
          onClick={onResetFilters}
        >
          Clear filters
        </ButtonAlt>
      </div>

      {/* Filter buttons */}
      <div className={styles.buttonsContainer}>
        <div
          className={classNames(
            dateFilterStyles.dateRangeContainer,
            isEdgeAdminView ? dateFilterStyles.submittedDateContainerAdmin : dateFilterStyles.submittedDateContainer
          )}
        >
          <RangePicker
            placeholder={['From', 'To']}
            value={[
              selectedDateRange.from ? moment(selectedDateRange.from, 'YYYY-MM-DD') : null,
              selectedDateRange.to ? moment(selectedDateRange.to, 'YYYY-MM-DD') : null
            ]}
            dropdownClassName={classNames(
              dateFilterStyles.dateRangeDropdown,
              isEdgeAdminView ? dateFilterStyles.dropdownClassNameAdmin : dateFilterStyles.dropdownClassName
            )}
            onChange={(
              _value: DatePickerProps['value'] | RangePickerProps['value'],
              dateString: [string, string] | string
            ) => {
              onChangeDateRange(dateString);
            }}
            className={selectedDateRange.from || selectedDateRange.to ? 'hasValue' : ''}
          />
        </div>

        <FilterDropdown
          id="statuses"
          menuItems={STATUS_OPTIONS}
          onChangeItem={onToggleFilter(STATUS_OPTIONS, selectedStatuses, onChangeStatusFilter)}
          selectedFilterList={selectedStatuses}
          enableSelectedBackground
          onClearFilter={() => {
            onChangeStatusFilter([]);
          }}
          isInactive={isDisableStatusFilter}
        >
          Status
        </FilterDropdown>

        <FilterDropdown
          id="referrers"
          menuItems={REFERRER_TYPE_OPTIONS}
          onChangeItem={onToggleFilter(REFERRER_TYPE_OPTIONS, selectedReferrerTypes, onChangeReferrerTypeFilter)}
          selectedFilterList={selectedReferrerTypes}
          enableSelectedBackground
          onClearFilter={() => {
            onChangeReferrerTypeFilter([]);
          }}
        >
          Referrer
        </FilterDropdown>

        <FilterDropdown
          id="linkages"
          menuItems={LINKAGE_OPTIONS}
          onChangeItem={onToggleFilter(LINKAGE_OPTIONS, selectedLinkages, onChangeLinkageFilter)}
          selectedFilterList={selectedLinkages}
          enableSelectedBackground
          onClearFilter={() => {
            onChangeLinkageFilter([]);
          }}
          isInactive={isDisableLinkageFilter}
        >
          Linked / Unlinked
        </FilterDropdown>

        {isPackageEnabled && (
          <FilterDropdown
            id="packages"
            menuItems={packageListFilter}
            onChangeItem={onToggleFilter(packageListFilter, selectedPackages, onChangePackageFilter)}
            searchable
            selectedFilterList={selectedPackages}
            showSearchIcon
            loading={isPackageListDataLoading}
            hasMoreData={totalPackageListPage > packageListCurrentPage || isPackageListDataFetching}
            isFetchingMore={isPackageListDataFetching}
            loadMore={() => {
              if (!isPackageListDataFetching && packageListCurrentPage <= totalPackageListPage) {
                setPackageListCurrentPage(packageListCurrentPage + 1);
              }
            }}
            enableSelectedBackground
            onClearFilter={() => {
              onChangePackageFilter([]);
            }}
            setSearchText={debounceSetPackageSearchText}
          >
            Package
          </FilterDropdown>
        )}
      </div>
    </div>
  );
};

export default ReferralsFilters;
