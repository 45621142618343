import {
  GuardianFormValidationInterface,
  YoungClientErrorInterface,
  YoungClientFormValidationInterface,
  YoungGuardianErrorInterface
} from 'components/AddPatientModalV2/interfaces/formProps.interface';
import i18n from 'i18n';
import moment from 'moment';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import * as yup from 'yup';
import { youngPersonGuardianFormSchema } from '../../GuardianForm/validation/GuardianFormValidation';

export const youngFormSchema = () =>
  yup.object().shape({
    firstName: yup.string().required(i18n.t('validation.client_firstname.require')),
    lastName: yup.string().required(i18n.t('validation.client_lastname.require')),
    email: yup.string().when('profileType', {
      is: 'full',
      then: yup
        .string()
        .email(i18n.t('validation.client_email.invalid'))
        .required(i18n.t('validation.client_email.require')),
      otherwise: yup.string().email(i18n.t('validation.client_email.invalid')).nullable()
    }),
    mobileNumber: yup.string().when('profileType', {
      is: 'full',
      then: yup
        .string()
        .required(i18n.t('validation.client_phonenumber.require'))
        .min(12, i18n.t('validation.client_phonenumber.min', { count: 10 })),
      otherwise: yup.string().nullable()
    }),
    dateOfBirth: yup.string().test('Date of Birth', i18n.t('validation.dob.invalid'), (value) => {
      if (value && value?.length > 0) {
        return moment(value, MOMENTJS_DATE_FORMAT).format(MOMENTJS_DATE_FORMAT) === value;
      } else {
        return true;
      }
    })
  });

export const validationYoungField = (clientVal: YoungClientFormValidationInterface) => {
  const validationErrors: YoungClientErrorInterface = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    mobileNumber: ''
  };

  try {
    youngFormSchema().validateSync(clientVal, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error) => {
        if (error.path && Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof YoungClientErrorInterface] = error.message;
        }
      });
      return validationErrors;
    }
  }
};

export const validationYoungGuardianField = (value: GuardianFormValidationInterface) => {
  const validationErrors: YoungGuardianErrorInterface = {
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    mobileNumber: ''
  };
  try {
    youngPersonGuardianFormSchema().validateSync(value, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error) => {
        if (error.path) {
          validationErrors[error.path as keyof YoungGuardianErrorInterface] = error.message;
        }
      });
      return validationErrors;
    }
  }
};
