import { Skeleton } from 'antd';
import SearchBarT23 from 'components/SearchBarT23/SearchBarT23';
import PaginationListV2 from 'components/v2/PaginationListV2/PaginationListV2';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import moment from 'moment';
import { TabIds } from 'pages/Client/Client';
import {
  massagePatientAppointments,
  useFetchLatestActivity
} from 'pages/Client/components/ClientListContent/hooks/GetClientListInfo';
import queryString from 'query-string';
import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectSetting } from 'redux/clients/clientListColumnsSettingsSlice';
import {
  resetPaging,
  selectFilters,
  selectPaging,
  selectSearch,
  selectSorting,
  setPaging,
  setSearch
} from 'redux/clients/clientListSlice';
import { useGetClientRecordsListQuery } from 'redux/endpoints/clinicianProfileServices/client';
import {
  useGetAppointmentsByRecordIdsQuery,
  useGetNextAppointmentsByRecordIdsQuery
} from 'redux/endpoints/scheduleServices/appointment';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  CLIENT_LIST_APPOINTMENT_STATISTICS_COLUMNS,
  ColumnSettingsEnum
} from 'utils/providers/ClientListColumnsProvider/constants';
import ClientListFilters from '../../../ClientListFilters/ClientListFilters';
import styles from './ClientList.module.scss';
import ClientItem from './components/ClientItem/ClientItem';
import ClientListEmpty from './components/ClientListEmpty/ClientListEmpty';
import ClientListHeader from './components/ClientListHeader/ClientListHeader';
import { ClientStatus, getClientRecordRequestPayload, searchFilterItems } from './constants';
import { practiceInfo } from 'redux/slice/practiceDataSlice';

const ClientList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { CLIENTS } = useRoutesGenerator();
  const queryParam: { page?: string } = queryString.parse(location.search);
  const { isEdgeUserView, isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { tabId } = useParams<{ [key in keyof TabIds]: string }>();

  // Get data from redux global store
  const columnSetting = useAppSelector(selectSetting);
  const search = useAppSelector(selectSearch);
  const paging = useAppSelector(selectPaging);
  const sorting = useAppSelector(selectSorting);
  const filters = useAppSelector(selectFilters);
  const { data: practiceData, isLoading: practiceInfoLoading } = useAppSelector(practiceInfo);

  const [clientRecordIds, setClientRecordIds] = useState<string>('');

  const { latestClientsActivity } = useFetchLatestActivity(token);

  const {
    data: clientRecordListResponse,
    isLoading: isClientRecordListLoading,
    isFetching: isClientRecordListFetching,
    isError,
    refetch: refetchClientRecordList
  } = useGetClientRecordsListQuery(
    {
      accountId,
      payload: {
        ...getClientRecordRequestPayload({ tabId, paging, sorting, filters, isEdgeUserView, columnSetting, search })
      }
    },
    { skip: !columnSetting }
  );

  const {
    data: nextAppointmentsResponse,
    isLoading: isNextAppointmentsLoading,
    isFetching: isNextAppointmentsFetching
  } = useGetNextAppointmentsByRecordIdsQuery(
    { asAdmin: isEdgeAdminView || isEdgeReceptionistView, clientRecordIds: clientRecordIds || '' },
    {
      skip:
        isClientRecordListFetching ||
        !clientRecordIds ||
        tabId === ClientStatus.CLOSED ||
        !columnSetting ||
        !columnSetting.columns.find((column) => column.id === ColumnSettingsEnum.NEXT_APPOINTMENT)
    }
  );
  const {
    data: appointmentStatistics,
    isLoading: isAppointmentStatisticsLoading,
    isFetching: isAppointmentStatisticsFetching
  } = useGetAppointmentsByRecordIdsQuery(
    {
      accountId,
      from: '2020-01-01',
      to: moment(new Date()).add(3, 'months').format('YYYY-MM-DD'),
      clientRecordIds: clientRecordIds || ''
    },
    {
      skip:
        isClientRecordListFetching ||
        !clientRecordIds ||
        !columnSetting ||
        !columnSetting.columns.some((column) =>
          CLIENT_LIST_APPOINTMENT_STATISTICS_COLUMNS.find((item) => item === column.id)
        )
    }
  );

  const getAppointmentStatistics = (clientRecordId: string) => {
    const matchedData =
      appointmentStatistics && appointmentStatistics.find((appointment) => appointment._id === clientRecordId);
    if (matchedData) {
      return massagePatientAppointments(matchedData.totalAppointments);
    }
    return {
      completedAppointmentsTotal: 0,
      upcomingAppointmentsTotal: 0
    };
  };

  useLayoutEffect(() => {
    if (!isClientRecordListLoading && !isClientRecordListFetching && clientRecordListResponse?.clientRecords) {
      const clientRecordIds = clientRecordListResponse.clientRecords
        .map((clientListObj) => clientListObj._id)
        .join(',');
      setClientRecordIds(clientRecordIds);
    }
  }, [isClientRecordListLoading, isClientRecordListFetching, clientRecordListResponse?.clientRecords]);

  const selectPatient = (recordId: string) => {
    const qParam = queryString.stringify(queryParam);
    navigate(`${CLIENTS.BASE}/${recordId}/profile${qParam ? `?${qParam}` : ''}`);
  };

  const onChangePage = (page: number) => {
    dispatch(setPaging({ ...paging, page }));
  };

  const handleSearch = (searchValue: string) => {
    dispatch(resetPaging());
    dispatch(setSearch({ ...search, searchValue }));
  };

  const isListLoading = isClientRecordListLoading || practiceInfoLoading || isClientRecordListFetching;

  const handleChangeSearchFilter = (searchBy: string) => {
    dispatch(setSearch({ ...search, searchBy }));
  };

  return (
    <>
      <div className={styles.clientListHeader}>
        <div className={styles.filterWrapper}>
          <ClientListFilters />
        </div>
        <div className={styles.searchBarWrapper}>
          <SearchBarT23
            placeholder={`Search by ${searchFilterItems
              .find((item) => search.searchBy === item._id)
              ?.name?.toLowerCase()}`}
            value={search.searchValue}
            withSearchButton
            onSearch={handleSearch}
            withSearchBy={{
              onChange: handleChangeSearchFilter,
              searchByOptions: searchFilterItems,
              selectedSearchByOption:
                searchFilterItems.find((item) => search.searchBy === item._id) || searchFilterItems[0]
            }}
          />
          {[SearchFilterEnum.Email, SearchFilterEnum.MobileNumber].includes(search.searchBy) && (
            <div className={styles.message}>
              Only exact matches for{' '}
              {searchFilterItems.find((item) => search.searchBy === item._id)?.name.toLowerCase()} will return
            </div>
          )}
        </div>
      </div>
      <div className={styles.container}>
        <ClientListHeader />
        {isListLoading || !columnSetting ? (
          <div className={styles.loadingWrapper}>
            {[...Array(10)].map((obj, i) => (
              <div key={i} className={styles.loadingItem}>
                <Skeleton.Input active className={styles.loadingContent} />
              </div>
            ))}
          </div>
        ) : isError ||
          (clientRecordListResponse?.clientRecords && clientRecordListResponse.clientRecords.length < 1) ? (
          <ClientListEmpty />
        ) : (
          <div className={styles.listOfClients}>
            {clientRecordListResponse?.clientRecords.map((clientRecord, index) => {
              const { _id } = clientRecord;
              const activity = latestClientsActivity?.find(
                (activity) => activity.clientRecordId === _id
              )?.latestActivity;
              const nextAppointment = nextAppointmentsResponse?.patientNextAppointments?.find(
                (appointment) => appointment.clientRecord._id === _id
              );
              const appointmentStatistics = getAppointmentStatistics(_id);
              return (
                <ClientItem
                  key={index}
                  selectClientNo={index}
                  clientRecord={clientRecord}
                  latestActivity={activity}
                  nextAppointment={nextAppointment}
                  practiceData={practiceData}
                  columnSetting={columnSetting}
                  appointmentStatistics={appointmentStatistics}
                  onSelectClient={() => selectPatient(_id)}
                  refetchClientRecordList={refetchClientRecordList}
                  nextAppointmentLoading={isNextAppointmentsLoading || isNextAppointmentsFetching}
                  appointmentStatisticsLoading={isAppointmentStatisticsLoading || isAppointmentStatisticsFetching}
                />
              );
            })}
          </div>
        )}
        {clientRecordListResponse &&
          clientRecordListResponse.paging.totalItems > clientRecordListResponse.paging.perPage && (
            <div className={styles.paginationWrapper}>
              <PaginationListV2 paging={clientRecordListResponse.paging} onPageChange={onChangePage} />
            </div>
          )}
      </div>
    </>
  );
};

export default ClientList;
