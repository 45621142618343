import { useFormikContext } from 'formik';
import { useMemo, useState } from 'react';
import styles from './CaseNoteFormatSettings.module.scss';
import classnames from 'classnames';
import Button from 'components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { CaseNoteFormatSettingsFields, CaseNoteTemplate } from '../interface';
import FormatSettingsFieldEditor from './FormatSettingsFieldEditor/FormatSettingsFieldEditor';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useTranslation } from 'react-i18next';
import AddClientDataFieldButton from './AddClientDataFieldButton/AddClientDataFieldButton';
import {
  OpenDataCategory,
  CLIENT_RECORD_SYNC_VARIABLE_IDS
} from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';
import { useFetchOpenDataCategories } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/hooks/useFetchOpenDataCategories';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';

const DEFAULT_FIELD: CaseNoteFormatSettingsFields = {
  name: 'New Field',
  type: 'text',
  active: true,
  editable: true,
  mandatory: false
};

const QUESTION_TYPE_TEXT: Record<string, string | undefined> = {
  date: 'Date',
  multipleChoice: 'Multiple choice',
  multipleChoiceCount: 'Multiple choice with count',
  number: 'Number',
  text: 'Text',
  time: 'Time'
};

interface CaseNoteFormatSettingsProp {
  disabled?: boolean;
}

const CaseNoteFormatSettings = ({ disabled = false }: CaseNoteFormatSettingsProp) => {
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const [t] = useTranslation();
  const { values, setFieldValue } = useFormikContext<CaseNoteTemplate>();
  const { openDataCategories, isOpenDataCategoriesLoading } = useFetchOpenDataCategories(token, accountId);

  const [selectedField, setSelectedField] = useState<number>();
  const [isEditing, setIsEditing] = useState(false);

  const filteredCategories = useMemo<OpenDataCategory[]>(
    () =>
      isOpenDataCategoriesLoading
        ? []
        : openDataCategories
            .map((category) => ({
              ...category,
              questions: category.questions.filter(
                (question) => !CLIENT_RECORD_SYNC_VARIABLE_IDS.includes(question.variableId as string)
              )
            }))
            .filter((category) => !!category.questions.length),
    [openDataCategories, isOpenDataCategoriesLoading]
  );

  const getQuestionTypeText = (field: CaseNoteFormatSettingsFields) => {
    if (field.type === 'cdf') {
      if (isOpenDataCategoriesLoading) {
        return;
      }

      const foundCategoryName =
        filteredCategories.find(({ _id }) => _id === field.categoryId)?.name || 'Deleted Category';

      return `${foundCategoryName} - ${field.variableId}`;
    }

    return QUESTION_TYPE_TEXT[field.type];
  };

  const handleEditClick = (index: number) => {
    setIsEditing(true);
    setSelectedField(index);
  };

  const handleEditFinish = (index: number) => (newField?: CaseNoteFormatSettingsFields) => {
    newField && setFieldValue(`fields.${index}`, newField);

    setIsEditing(false);
    setSelectedField(undefined);
  };

  return (
    <>
      {isEditing && !isNaN(Number(selectedField)) && (
        <FormatSettingsFieldEditor
          selectedField={values.fields[selectedField as number] || DEFAULT_FIELD}
          onEditFinish={handleEditFinish(selectedField as number)}
        />
      )}
      <div className={styles.container}>
        <div className={classnames(styles.fieldContainer, styles.disabled)}>
          <label className={styles.checkboxContainer} htmlFor="checkbox-formatSettingsField-noteTitle">
            <input
              className={styles.checkbox}
              id="checkbox-formatSettingsField-noteTitle"
              type="checkbox"
              checked
              onChange={() => {}}
              disabled
            />
            <div className={classnames(styles.text, styles.active)}>
              <div className={styles.label}>Note Title</div>
              <div className={styles.description}>Text</div>
            </div>
          </label>
          <Button className={classnames(styles.button, styles.active)} disabled>
            <FontAwesomeIcon className={styles.starIcon} icon={faAsterisk} />
          </Button>
        </div>

        {values.fields.map((field, index) => (
          <div className={classnames(styles.fieldContainer, disabled && styles.disabled)} key={index}>
            <label className={styles.checkboxContainer} htmlFor={`checkbox-formatSettingsField-${index}`}>
              <input
                className={styles.checkbox}
                id={`checkbox-formatSettingsField-${index}`}
                type="checkbox"
                value={index}
                checked={field.active}
                onChange={(e) => setFieldValue(`fields.${index}.active`, e.target.checked)}
                disabled={disabled}
              />
              <div className={classnames(styles.text, field.active && styles.active)}>
                <div className={styles.label}>
                  {field.name === 'Safeguarding Alert' ? t('client_case_notes.safe_guarding_alert') : field.name}
                </div>
                {field.active && <div className={styles.description}>{getQuestionTypeText(field)}</div>}
              </div>
            </label>
            {field.active && (
              <>
                {((disabled && field.mandatory) || !disabled) && (
                  <Button
                    className={classnames(styles.button, field.mandatory && styles.active)}
                    onClick={() => setFieldValue(`fields.${index}.mandatory`, !field.mandatory)}
                    disabled={disabled}
                  >
                    <FontAwesomeIcon className={styles.starIcon} icon={faAsterisk} />
                  </Button>
                )}
                {field.editable && !disabled && (
                  <Button className={styles.button} onClick={() => handleEditClick(index)}>
                    <i className={`material-icons-outlined ${styles.editIcon}`}>edit</i>
                  </Button>
                )}
              </>
            )}
          </div>
        ))}
        {!disabled && (
          <div className={styles.createFieldWrapper}>
            <ButtonAlt
              className={styles.createFieldBtn}
              variant={'text'}
              size={'medium'}
              onClick={() => handleEditClick(values.fields.length)}
              icon={'add_circle_outline'}
            >
              Create Field
            </ButtonAlt>
            <AddClientDataFieldButton
              categories={filteredCategories}
              disabled={isOpenDataCategoriesLoading || !filteredCategories.length}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CaseNoteFormatSettings;
