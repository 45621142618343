import { ChangeEvent } from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ScaleBox from './components/ScaleBox/ScaleBox';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';

import hexSliderData from './HexSliderData.json';

import styles from './HexSlider.module.scss';
import { Question, Option } from 'interfaces/checkInService/AssessmentInterfaces';
import { useGetAccountInfo } from 'utils/hooks/GetAccountInfo/getAccountInfo';

interface HexSliderProps {
  question: Question;
  defaultPatientDetails?: { name: string; picture: string };
  value: number;
  onChange: (v: number) => void;
  error?: string;
  showError?: boolean;
}

const HexSlider = ({
  question,
  defaultPatientDetails,
  value: formValue,
  onChange,
  error,
  showError = false
}: HexSliderProps) => {
  const { clinicianProfile } = useGetAccountInfo();

  const scales = question.scales && question.scales.length > 1 ? question.scales : hexSliderData.scales;

  const handleRangeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseInt(e.target.value, 10);

    if (value) {
      onChange(value);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.scaleBarContainer}>
          {scales.map(({ key, colour, value }) => (
            <ScaleBox key={key} colour={colour} formValue={formValue} value={value} onChange={onChange} />
          ))}
        </div>
        <div className={styles.labelContainer}>
          <div className={scales.length > 5 ? styles.labelBox : styles.labelBoxSmaller}>
            {(question.options as Option[])?.map(({ key, description }) => (
              <div key={key}>{description}</div>
            ))}
          </div>
        </div>
        <div className={styles.sliderContainer}>
          <div className={styles.slider}>
            <input
              style={{ width: scales.length * 35 }}
              className={styles.hexSliderRange}
              type="range"
              min={
                question.scales && question.scales.length > 1
                  ? question.scales?.reduce((prev, curr) => (prev.value < curr.value ? prev : curr)).value
                  : 1
              }
              max={
                question.scales && question.scales.length > 1
                  ? question.scales?.reduce((prev, curr) => (prev.value > curr.value ? prev : curr)).value
                  : 11
              }
              value={formValue}
              onChange={handleRangeChange}
            />
          </div>
          <div className={styles.pointerContainer} data-value={formValue}>
            <div className={styles.label}>
              <div className={styles.circleContainer}>
                <div className={styles.triangle} />
                <div className={styles.circle}>
                  <div className={styles.profileWrapper}>
                    <div className={styles.profile}>
                      <ClientProfileAvatar avatarUrl={clinicianProfile?.avatar || defaultPatientDetails?.picture} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage error={error} visible={showError && !!error} />
    </>
  );
};

export default HexSlider;
