import { useState } from 'react';
import { notification } from 'antd';
import moment from 'moment';

import { AppointmentBookingRequest } from '../../../../Notification';

import { MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import {
  postApproveRescheduleRequest,
  postRejectRescheduleRequest
} from 'utils/http/ScheduleService/Appointments/Appointments';

import ChangeNote from '../ChangeNote/ChangeNote';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';

import styles from './AppointmentRescheduleRequest.module.scss';

interface AppointmentRescheduleRequestProps {
  appointmentBookingRequestData: AppointmentBookingRequest['appointmentBookingRequests'][number];
  token: string;
  onRefreshNotifications: () => void;
}

const AppointmentRescheduleRequest = ({
  appointmentBookingRequestData: {
    _id,
    clientRecord,
    clinicianProfile,
    date,
    startTime,
    endTime,
    sessionTypeName,
    clientRescheduleDetails,
    isExpired,
    isClashedWithOtherAppointment
  },
  token,
  onRefreshNotifications
}: AppointmentRescheduleRequestProps) => {
  const { isEdgeAdminUser, isEdgeReceptionistView } = useGetAccountPackageView();

  const [acceptButtonStatus, setAcceptButtonStatus] = useState<ButtonStatusType>('');
  const [rejectButtonStatus, setRejectButtonStatus] = useState<ButtonStatusType>('');
  const [rejectNote, setRejectNote] = useState('');
  const [showRejectNotes, setShowRejectNotes] = useState(false);

  const handleAcceptRequest = async () => {
    setAcceptButtonStatus('active');

    try {
      const response = await postApproveRescheduleRequest(token, _id);

      if (response.statusCode === 204) {
        onRefreshNotifications();
        setAcceptButtonStatus('finished');

        notification.success({
          message: 'Change request approved',
          duration: 2
        });

        setTimeout(() => {
          setAcceptButtonStatus('');
        }, 200);
      } else if (response.statusCode === 404) {
        notification.error({
          message: 'Unable to find this appointment change request. Please reload the page.'
        });
        setAcceptButtonStatus('');
      } else {
        const responseErrorMessage = await response.text();

        notification.error({
          message:
            responseErrorMessage || 'Something went wrong while trying to approve this appointment change request.'
        });
        setAcceptButtonStatus('');
      }
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to approve this appointment change request.'
      });
      setAcceptButtonStatus('');
    }
  };

  const handleRejectRequest = async () => {
    setRejectButtonStatus('active');

    try {
      const response = await postRejectRescheduleRequest(token, _id, { note: rejectNote });

      if (response.statusCode === 204) {
        onRefreshNotifications();
        setRejectButtonStatus('finished');

        notification.success({
          message: 'Change request rejected',
          duration: 2
        });

        setTimeout(() => {
          setRejectButtonStatus('');
        }, 200);
      } else if (response.statusCode === 404) {
        notification.error({
          message: 'Unable to find this appointment change request. Please reload the page.'
        });
        setRejectButtonStatus('');
      }
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying to reject this appointment change request.'
      });
      setRejectButtonStatus('');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Appointment changes requested{' '}
        {isExpired ? '(Expired)' : isClashedWithOtherAppointment && '(Clashing with another appointment)'}
      </div>
      <div className={styles.content}>
        <div className={styles.rescheduleDetails}>
          <div className={styles.avatars}>
            {isEdgeAdminUser || isEdgeReceptionistView ? (
              <>
                <div>
                  <div className={styles.heading}>CLIENT</div>
                  <ClientAvatar
                    clientData={clientRecord.clientProfiles}
                    avatarSize={40}
                    isEllipsisName={false}
                    displayFirstNameOnly={clientRecord.recordType === 'couple'}
                    displayLimit={clientRecord.recordType === 'couple' ? 2 : 1}
                    nameClassName={styles.name}
                    initialsClassName={styles.name}
                    containerClassName={styles.containerClassName}
                  />
                </div>
                {clinicianProfile && (
                  <div>
                    <div className={styles.heading}>CLINICIAN</div>
                    <ClinicianAvatar
                      name={clinicianProfile.name}
                      avatarUrl={clinicianProfile.avatar}
                      avatarSize={40}
                      isEllipsisName={false}
                      showUnassigned={!clinicianProfile.avatar}
                      customNameClass={styles.name}
                      customContainerClass={styles.containerClassName}
                    />
                  </div>
                )}
              </>
            ) : (
              <ClientAvatar
                clientData={clientRecord.clientProfiles}
                avatarSize={40}
                isEllipsisName={false}
                displayFirstNameOnly={clientRecord.recordType === 'couple'}
                displayLimit={clientRecord.recordType === 'couple' ? 2 : 1}
                nameClassName={styles.name}
                initialsClassName={styles.name}
                containerClassName={styles.containerClassName}
              />
            )}
          </div>
          <div className={styles.currentAppointment}>
            <div className={styles.heading}>Current Appointment</div>
            <div className={styles.text}>{sessionTypeName}</div>
            <div className={styles.text}>
              {startTime} - {endTime} | {moment(date, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format('dddd DD MMMM')}
            </div>
          </div>
          <div className={styles.requestedAppointment}>
            <div className={styles.heading}>Requested Appointment</div>
            <div className={styles.text}>{sessionTypeName}</div>
            <div className={styles.text}>
              {clientRescheduleDetails.startTime || '-'} - {clientRescheduleDetails.endTime || '-'} |{' '}
              {clientRescheduleDetails.date
                ? moment(clientRescheduleDetails.date, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format('dddd DD MMMM')
                : '-'}
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.acceptButton}
              variant="primary"
              status={acceptButtonStatus}
              disabled={!!rejectButtonStatus || isExpired || isClashedWithOtherAppointment}
              onClick={handleAcceptRequest}
            >
              Accept
            </Button>
            <Button
              className={styles.rejectButton}
              variant="secondary"
              disabled={!!acceptButtonStatus || !!rejectButtonStatus}
              onClick={() => setShowRejectNotes(true)}
            >
              Reject
            </Button>
          </div>
        </div>
        {showRejectNotes && (
          <>
            <ChangeNote
              title="Include a message for the client to explain (optional)"
              note={rejectNote}
              onNoteChange={setRejectNote}
            />
            <Button
              className={styles.rejectWithNotesButton}
              variant="primary"
              status={rejectButtonStatus}
              disabled={!!acceptButtonStatus}
              onClick={handleRejectRequest}
            >
              Reject Change Request
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AppointmentRescheduleRequest;
