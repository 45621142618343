import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ActiveTasks from './ActiveTasks/ActiveTasks';
import { useLocation } from 'react-router-dom';
import ClosedTasks from './ClosedTasks/ClosedTasks';
import queryString from 'query-string';
import { TaskStatus } from 'interfaces/Tasks/task';

const Tasks = () => {
  const { search } = useLocation();
  const { status }: { status?: string } = queryString.parse(search);

  return (
    <HelmetWrapper title="Tacklit - Tasks">
      <ContentLayout>{status === TaskStatus.Closed ? <ClosedTasks /> : <ActiveTasks />}</ContentLayout>
    </HelmetWrapper>
  );
};

export default Tasks;
