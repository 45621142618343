import { useState } from 'react';
import Button from 'components/v2/Button/Button';
import moment from 'moment';
import classNames from 'classnames';
import { InvoiceAction, InvoiceHistory } from 'pages/Invoices/components/InvoiceDetail/hooks/getInvoiceHistory';
import { STATUS_LABELS } from 'pages/Invoices/components/InvoiceListing/components/ClientInvoiceItem/ClientInvoiceItem';
import { InvoiceStatus } from 'pages/Invoices/interface';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import styles from './InvoiceStatusBox.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
const IS_DEVELOPMENT = isDevelopmentENV();

interface InvoiceStatusProps {
  invoiceStatus: InvoiceStatus;
  histories: InvoiceHistory[];
  isUpdatingStatus?: boolean;
  isNotAllowResend?: boolean;
  resendInvoiceButtonStatus?: '' | 'active' | 'finished';
  allowVoid?: boolean;
  allowEdit?: boolean;
  onResendInvoice: () => void;
  onChangeStatus: (status: InvoiceStatus) => void;
}

const InvoiceStatusBox = ({
  invoiceStatus,
  histories,
  resendInvoiceButtonStatus,
  isUpdatingStatus,
  isNotAllowResend,
  allowVoid,
  allowEdit,
  onChangeStatus,
  onResendInvoice
}: InvoiceStatusProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { isInvoiceListV2Enabled } = useGetFeatureToggle();

  const handleSendInvoiceClick = () => {
    onResendInvoice && onResendInvoice();
  };

  const handleChangeStatus = (status: InvoiceStatus) => {
    onChangeStatus(status);
    setShowMenu(false);
  };

  const isChangeStatusDisabled =
    isInvoiceListV2Enabled &&
    [InvoiceStatus.Draft, InvoiceStatus.Voided, InvoiceStatus.WriteOff, InvoiceStatus.ConfirmPaid].includes(
      invoiceStatus
    );

  const ableChangeToPaid = ![
    InvoiceStatus.PendingClaim,
    InvoiceStatus.ClaimComplete,
    InvoiceStatus.ClaimSuccess,
    InvoiceStatus.ClaimRejected
  ].includes(invoiceStatus);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Status</div>
      <div className={styles.statusSection}>
        <div className={styles.statusWrapper}>
          <span className={`${styles.status} ${styles[invoiceStatus]}`}>{STATUS_LABELS[invoiceStatus]}</span>
        </div>
        {allowEdit && (
          <div className={styles.changeStatusWrapper}>
            <div
              className={classNames(styles.changeStatus, isChangeStatusDisabled && styles.disabled)}
              onClick={() => !isChangeStatusDisabled && !isUpdatingStatus && setShowMenu(!showMenu)}
            >
              Change status <i className={`material-icons-outlined ${styles.icon}`}>chevron_right</i>
            </div>
            <div className={styles.menuWrapper}>
              <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
                <div
                  className={classNames(styles.listBox, !ableChangeToPaid && styles.disabled)}
                  onClick={() => ableChangeToPaid && handleChangeStatus(InvoiceStatus.ConfirmPaid)}
                >
                  <div className={styles.listTitle}>Change Status to Paid</div>
                </div>

                <div className={styles.listBox} onClick={() => handleChangeStatus(InvoiceStatus.Closed)}>
                  <div className={styles.listTitle}>Change Status to Closed</div>
                </div>
                {isInvoiceListV2Enabled && (
                  <div
                    className={classNames(styles.listBox, !allowVoid && styles.disabled)}
                    onClick={() => allowVoid && handleChangeStatus(InvoiceStatus.Voided)}
                  >
                    <div className={styles.listTitle}>Change Status to Voided</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {IS_DEVELOPMENT && (
        <div className={styles.historySection}>
          <div className={styles.label}>HISTORY</div>
          <div className={styles.historyList}>
            {histories.map((history, index) => (
              <div className={styles.historyItem} key={index}>
                <div>
                  <span className={`${styles.activityIcon} material-icons-outlined`}>receipt</span>
                </div>
                <span className={styles.content}>
                  Invoice{' '}
                  <b>
                    {(history.action !== InvoiceAction.Resend ? STATUS_LABELS[history.action] : 'resend').toUpperCase()}
                  </b>{' '}
                  by {history.actorName}
                  <br />
                  {moment(history.date, 'YYYY-MM-DD').format('DD/MM/YYYY')} - Invoice {history.action}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={styles.buttonSection}>
        <Button
          className={styles.button}
          status={resendInvoiceButtonStatus}
          type="button"
          disabled={isNotAllowResend || invoiceStatus === InvoiceStatus.WriteOff}
          onClick={handleSendInvoiceClick}
        >
          Resend
        </Button>
      </div>
    </div>
  );
};

export default InvoiceStatusBox;
