import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { ClientProfileEpisodeDropdownItem } from 'components/SideDashboardT23/components/ClientSubTab/ClientProfileSubTab/components/ClientProfileEpisodeDropdownItem/ClientProfileEpisodeDropdownItem';
import { EpisodeInterface } from 'interfaces/Episodes/episodes';
import { PackagesInterface } from 'interfaces/Packages/packages';
import { Referral, REFERRAL_STATUS_LABELS, ReferralEntrySource } from 'interfaces/Referral/Referral';
import moment from 'moment';
import ActionMenu from 'pages/Referrals/components/ReferralsItem/components/ActionMenu/ActionMenu';
import { ReferrerType } from 'pages/Referrals/interface';
import { getReferrerTypeOptions, getRoleLabel, ORGANISATION_TYPE_OPTIONS } from 'pages/Referrals/utils';
import { useMemo } from 'react';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { getName } from 'utils/general';
import styles from './ReferralInformation.module.scss';

interface ReferralInformationProps {
  referral: Referral;
  index: number;
  packageById: Map<string | undefined, PackagesInterface> | undefined;
  episodeById: Map<string, EpisodeInterface> | undefined;
}

const ReferralInformation = ({ referral, index, packageById, episodeById }: ReferralInformationProps) => {
  const { isPackageEnabled, medicareRebateFeatureToggle, isEoCEnabled } = useGetFeatureToggle();

  const {
    referralId,
    status,
    detail,
    date,
    entrySource,
    expiryDate,
    presentingIssue,
    packages = [],
    referrer,
    org,
    linkedEpisodes
  } = referral;

  const activePackages = useMemo(
    () => (packageById ? packages.filter((p) => packageById.get(p.packageId)?.name) : []),
    [packages, packageById]
  );

  const reformatDate = (referralDate: string) => moment(referralDate, 'YYYY-MM-DD').format('DD/MM/YYYY');

  const renderReferrerDetails = () => {
    const getIndividualDetails = (referrerType: ReferrerType) => (
      <div className={styles.referrer}>
        <div className={styles.madeBy}>Made by</div>
        <div className={styles.referrerName}>
          {getName(referrer)}{' '}
          {(referrerType === ReferrerType.Professional || referrerType === ReferrerType.Organisation) && (
            <span>
              {referrer.role} {referrer.providerNumber && <span>- {referrer.providerNumber}</span>}
            </span>
          )}
        </div>
        <div className={styles.referrerContact}>
          {referrer.email && (
            <div className={styles.contact}>
              <i className={`material-icons-outlined ${styles.contactIcon}`}>email</i>
              <span>{referrer.email}</span>
            </div>
          )}
          {referrer.mobile && (
            <div className={styles.contact}>
              <i className={`material-icons-outlined ${styles.contactIcon}`}>phone</i>
              <span>{referrer.mobile}</span>
            </div>
          )}
        </div>
      </div>
    );

    switch (referrer.referrerType) {
      case ReferrerType.FriendFamily:
      case ReferrerType.Professional:
        return getIndividualDetails(referrer.referrerType);
      case ReferrerType.Organisation:
        return (
          <>
            {org && (
              <div className={styles.organisation}>
                <div className={styles.orgName}>
                  <span>{org.name}</span> {org.billerCode && <span>{org.billerCode}</span>}
                </div>

                <div className={styles.orgContact}>
                  {org.email && (
                    <div className={styles.contact}>
                      <i className={`material-icons-outlined ${styles.contactIcon}`}>email</i>
                      <span>{org.email}</span>
                    </div>
                  )}
                  {org.phone && (
                    <div className={styles.contact}>
                      <i className={`material-icons-outlined ${styles.contactIcon}`}>phone</i>
                      <span>{org.phone}</span>
                    </div>
                  )}
                  {org.fax && (
                    <div className={styles.contact}>
                      <i className={`material-icons-outlined ${styles.contactIcon}`}>fax</i>
                      <span>{org.fax}</span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {referrer.firstName && getIndividualDetails(referrer.referrerType)}
          </>
        );
      default:
        return null;
    }
  };

  const getPackageFunderPill = (packageId: string, funderId: string) => {
    const currentPackage = packageById?.get(packageId);
    return (
      <>
        {currentPackage && (
          <div className={styles.packageItemDetails}>
            <div className={styles.packageTitle}>{currentPackage.name}</div>
            <div className={styles.funderDetails}>
              {currentPackage.funders.find((funder) => funder.funderId === funderId)?.name}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          Referral #{index + 1}: {referralId}
        </div>
        <ActionMenu referral={referral} viewMode="client" />
      </div>

      <div className={styles.referralInformationDetail}>
        {entrySource && Object.values(ReferralEntrySource).some((option) => option === entrySource) && (
          <div className={styles.referralSource}>
            Referral Source
            <div className={styles.source}>
              <div className={styles.pill}>
                {entrySource === ReferralEntrySource.API || entrySource === ReferralEntrySource.Form
                  ? entrySource.toUpperCase()
                  : ReferralEntrySource.Manual === entrySource
                  ? 'MNL'
                  : ''}
              </div>
            </div>
          </div>
        )}
        {status && (
          <div className={styles.referralStatus}>
            Status
            <div className={styles.status}>
              <div className={styles.statusBadge} style={{ backgroundColor: REFERRAL_STATUS_LABELS[status].color }}>
                {REFERRAL_STATUS_LABELS[status].label}
              </div>
            </div>
          </div>
        )}
        {date.length > 0 && (
          <div className={styles.referralDate}>
            <div className={styles.dateHeader}>
              Dated
              {medicareRebateFeatureToggle && (
                <HelpOutLineWithTooltips
                  id="referral-date"
                  iconClass={styles.icon}
                  desc="This referral date will be automatically used in future Medicare claims"
                />
              )}
            </div>
            <div className={styles.date}>{reformatDate(date)}</div>
          </div>
        )}

        <div className={styles.referralDate}>
          <div className={styles.dateHeader}>Expires</div>
          <div className={styles.date}>{expiryDate ? reformatDate(expiryDate) : '-'}</div>
        </div>

        <div className={styles.referralPresentingIssue}>
          Presenting issue
          <div className={styles.detail}>{presentingIssue || '-'}</div>
        </div>

        {isPackageEnabled && (
          <>
            <div className={styles.referralPackages}>
              Packages
              <>
                {!activePackages.length ? (
                  <div className={styles.packages}>-</div>
                ) : (
                  packageById &&
                  activePackages.map((activePackage, idx) => (
                    <div key={activePackage.packageId + idx} className={styles.packages}>
                      {getPackageFunderPill(activePackage.packageId, activePackage.funderId)}
                    </div>
                  ))
                )}
              </>
            </div>
          </>
        )}

        <div className={styles.referralDetails}>
          Details
          <div className={styles.detail}>{detail.trim() || '-'}</div>
        </div>

        {isEoCEnabled && (
          <div className={styles.referralEpisodes}>
            Episode link
            {linkedEpisodes && linkedEpisodes.length > 0 && episodeById ? (
              linkedEpisodes.map((episodeId) => {
                const episode = episodeById.get(episodeId);
                return (
                  <div key={episodeId} className={styles.episode}>
                    {episode ? (
                      <ClientProfileEpisodeDropdownItem
                        showStatus={false}
                        episode={episode}
                        supportCurrentLabel={false}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                );
              })
            ) : (
              <div className={styles.episode}>-</div>
            )}
          </div>
        )}
      </div>

      {referrer.referrerType && referrer.referrerType !== ReferrerType.Self && (
        <>
          <div className={styles.header}>Referrer</div>
          <div className={styles.referrerInformationDetail}>
            <div className={styles.tags}>
              <div className={styles.pill}>
                {getReferrerTypeOptions(referrer.referrerType).find((opt) => opt.id === referrer.referrerType)?.label}
              </div>

              {referrer.referrerType === ReferrerType.Organisation && org && (
                <div className={styles.pill}>
                  {ORGANISATION_TYPE_OPTIONS.find((opt) => opt.value === org.type)?.label}
                </div>
              )}

              {referrer.referrerType === ReferrerType.FriendFamily && referrer.relationship && (
                <div className={styles.pill}>{getRoleLabel(referrer.relationship)}</div>
              )}
            </div>

            {renderReferrerDetails()}
          </div>
        </>
      )}
    </div>
  );
};

export default ReferralInformation;
