import FlexBox from 'components/FlexBox/FlexBox';
import PaymentMethodDetail from 'pages/InvoiceTemplatesPreview/PaymentDetail/components/PaymentMethodDetail';
import { PAYMENT_TYPE_LABELS } from 'pages/Invoices/components/PaymentMethods/PaymentMethods';
import { useAppSelector } from 'redux/hooks';
import { selectInvoiceForm } from 'redux/invoices/createInvoiceWithTemplateSlice';
import styles from './PaymentInfoSection.module.scss';

const PaymentInfoSection = () => {
  const invoiceForm = useAppSelector(selectInvoiceForm);

  if (!invoiceForm.paymentMethods.length) {
    return null;
  }

  return (
    <FlexBox direction="column" spacing={4} className={styles.wrapper}>
      <span className={styles.title}>Payment Details</span>
      <FlexBox direction="row" justifyContent="space-between" spacing={20} className={styles.flexWrap}>
        {invoiceForm.paymentMethods.map((paymentMethod, index) => (
          <div className={styles.width350} key={index}>
            <span className={styles.paymentMethodTitle}>{PAYMENT_TYPE_LABELS[paymentMethod.paymentType]}</span>
            <PaymentMethodDetail paymentMethod={paymentMethod} />
          </div>
        ))}
      </FlexBox>
    </FlexBox>
  );
};

export default PaymentInfoSection;
