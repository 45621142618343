import {
  AssignPackagesInterface,
  AssignPackagesResponseInterface,
  CreateUpdatePackagesInterface,
  FunderLimitType,
  funderType,
  GetPackageAssignmentList,
  PackagesBenefit,
  PackagesInterface,
  PackagesStatus,
  UtilisationPayloadInterface
} from 'interfaces/Packages/packages';
import queryString from 'query-string';
import { v4 as uuid } from 'uuid';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { scheduleServicesApiSlice, SSTagTypes } from '../../services/scheduleServicesApiSlice';

export interface CreatePackagePayload {
  accountId: string;
  payload: CreateUpdatePackagesInterface;
}

export interface UpdatePackagePayload {
  accountId: string;
  packageId: string;
  payload: CreateUpdatePackagesInterface;
}

export interface AssignPackagePayload {
  accountId: string;
  clientRecordId: string;
  payload: AssignPackagesInterface[];
}

export interface UnassignPackagePayload {
  accountId: string;
  clientRecordId: string;
  packageId: string;
  assigneeId: string;
}

export interface PackageListRequestPayload {
  accountId: string;
  params?: {
    page: number;
    perPage: number;
    searchValue?: string;
    searchBy?: string;
    status?: PackagesStatus;
    filterByIds?: string;
    expandAppointmentTypes?: '1';
  };
  infiniteLoad?: boolean;
}

export interface PackageRequestPayload {
  accountId: string;
  packageId: string;
  params?: {
    expandAppointmentTypes?: '1';
  };
}

export interface GetPackageAssignmentRequestPayload {
  accountId: string;
  clientRecordId: string;
  params?: {
    status?: PackagesStatus;
    sortByCreatedAt?: '1' | '-1';
  };
}

export interface GetPackageListBookableRequestPayload {
  accountId: string;
  clientRecordId: string;
  params?: {
    eventOwnerId?: string;
  };
  filterByParticipantType?: ParticipantType;
}

export interface PackageStatusRequestPayload {
  accountId: string;
  packageId: string;
  payload: {
    status: string;
  };
}

interface PackageListRespond {
  packages: PackagesInterface[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export interface MinifiedPackageList {
  packages: { _id: string; name: string }[];
  paging: { totalItems: number; page: number };
}

export interface UpdatePackageUtilisationPayload {
  accountId: string;
  clientRecordId: string;
  packageId: string;
  assigneeId: string;
  payload: UtilisationPayloadInterface[];
}

export const packageApiSlice = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createPackage: builder.mutation<PackagesInterface, CreatePackagePayload>({
      query: ({ accountId, payload }: CreatePackagePayload) => ({
        url: `/accounts/${accountId}/packages`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [SSTagTypes.PackageList]
    }),
    updatePackage: builder.mutation<PackagesInterface, UpdatePackagePayload>({
      query: ({ accountId, packageId, payload }: UpdatePackagePayload) => ({
        url: `/accounts/${accountId}/packages/${packageId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [SSTagTypes.PackageList]
    }),
    getPackageList: builder.query<PackageListRespond, PackageListRequestPayload>({
      query: ({ accountId, params }) => {
        const stringifiedQueryString = params ? `?${queryString.stringify(params)}` : '';
        return {
          url: `/accounts/${accountId}/packages${stringifiedQueryString}`
        };
      },
      providesTags: [SSTagTypes.PackageList],
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        queryArgs?.infiniteLoad
          ? `${endpointName}_${queryArgs?.params?.searchValue || ''}`
          : `${endpointName}_${queryString.stringify(queryArgs?.params || {})}`,
      merge: (currentCache, newItems, meta) => {
        if (meta.arg.infiniteLoad) {
          if (meta.arg.params?.page === 1) {
            return {
              packages: newItems.packages,
              paging: newItems.paging
            };
          }

          return {
            packages: [...currentCache.packages, ...newItems.packages],
            paging: newItems.paging
          };
        } else {
          return {
            packages: newItems.packages,
            paging: newItems.paging
          };
        }
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.params?.page !== previousArg?.params?.page && currentArg?.params?.page !== 1,
      transformResponse: (response: PackageListRespond) => {
        return {
          ...response,
          packages: response.packages.map((packageObj) => ({
            ...packageObj,
            funders: packageObj.funders.map((funderObj) => ({
              type: funderObj.type,
              name: funderObj.name,
              funderId: funderObj.type === funderType.AddressBook ? funderObj?.funderId : funderObj.name,
              ...(funderObj.quotaStatus && {
                quotaStatus: funderObj.quotaStatus
              })
            })),
            feeChecked: !!packageObj.fee,
            benefitControl: {
              ...packageObj.benefitControl,
              ...(packageObj.benefitControl.by === PackagesBenefit.Funder && {
                funderLimitType:
                  packageObj.benefitControl.perFunderLimit && packageObj.benefitControl.perFunderLimit?.length > 0
                    ? FunderLimitType.PerFunder
                    : FunderLimitType.Uniform
              }),
              ...(packageObj.benefitControl.perFunderLimit &&
                packageObj.benefitControl.perFunderLimit?.length > 0 && {
                  perFunderLimit: packageObj.benefitControl.perFunderLimit.map((perFunderLimitObj) => ({
                    ...perFunderLimitObj,
                    type: perFunderLimitObj.type,
                    funderId:
                      perFunderLimitObj.type === funderType.AddressBook
                        ? perFunderLimitObj?.funderId
                        : perFunderLimitObj.name
                  }))
                })
            }
          }))
        };
      }
    }),
    getPackage: builder.query<PackagesInterface, PackageRequestPayload>({
      query: ({ accountId, packageId, params }) => {
        const stringifiedQueryString = params ? `?${queryString.stringify(params)}` : '';
        return {
          url: `/accounts/${accountId}/packages/${packageId}${stringifiedQueryString}`
        };
      },
      providesTags: [SSTagTypes.PackageDetail]
    }),
    patchPackageStatus: builder.mutation({
      query: ({ accountId, packageId, payload }: PackageStatusRequestPayload) => ({
        url: `/accounts/${accountId}/packages/${packageId}/status`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: (_result, _error) => (_error?.status !== 409 ? [SSTagTypes.PackageList] : [])
    }),
    assignPackage: builder.mutation<AssignPackagesResponseInterface[], AssignPackagePayload>({
      query: ({ accountId, clientRecordId, payload }: AssignPackagePayload) => ({
        url: `/accounts/${accountId}/client-records/${clientRecordId}/packages`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [SSTagTypes.PackageAssignmentList]
    }),
    getMinifiedPackages: builder.query<
      MinifiedPackageList,
      {
        accountId: string;
        params?: {
          page?: number;
          perPage?: number;
          searchValue?: string;
          searchBy?: string;
          filterByIds?: string[];
        };
      }
    >({
      query: ({ accountId, params }) => ({
        url: `accounts/${accountId}/packages`,
        params: {
          minimized: 1,
          ...params
        }
      }),
      providesTags: [SSTagTypes.MinifiedPackageList]
    }),
    getAssignmentPackage: builder.query<GetPackageAssignmentList[], GetPackageAssignmentRequestPayload>({
      query: ({ accountId, clientRecordId, params }) => {
        const stringifiedQueryString = params ? `?${queryString.stringify(params)}` : '';
        return {
          url: `/accounts/${accountId}/client-records/${clientRecordId}/packages${stringifiedQueryString}`
        };
      },
      providesTags: [SSTagTypes.PackageAssignmentList]
    }),
    getPackageBookableAppointment: builder.query<GetPackageAssignmentList[], GetPackageListBookableRequestPayload>({
      query: ({ accountId, clientRecordId, params }) => {
        const stringifiedQueryString = params ? `?${queryString.stringify(params)}` : '';
        return {
          url: `/accounts/${accountId}/client-records/${clientRecordId}/packages:listBookable${stringifiedQueryString}`
        };
      },
      providesTags: [SSTagTypes.PackageAssignmentList],
      transformResponse: (response: GetPackageAssignmentList[], _meta, arg) => {
        if (arg.filterByParticipantType) {
          return response
            .filter((obj) =>
              obj.appointmentTypes.some(
                (appObj) => appObj.participantType === arg.filterByParticipantType && !appObj.isDone
              )
            )
            .map((packageListObj) => ({
              ...packageListObj,
              appointmentTypes: packageListObj.appointmentTypes
                .filter((appointmentObj) => appointmentObj.participantType === arg.filterByParticipantType)
                .map((appointmentTypeObj) => ({
                  ...appointmentTypeObj,
                  packageAppointmentId: uuid()
                }))
            }));
        } else {
          return response.map((packageListObj) => ({
            ...packageListObj,
            appointmentTypes: packageListObj.appointmentTypes.map((appointmentTypeObj) => ({
              ...appointmentTypeObj,
              packageAppointmentId: uuid()
            }))
          }));
        }
      }
    }),
    unassignPackage: builder.mutation<undefined, UnassignPackagePayload>({
      query: ({ accountId, clientRecordId, packageId, assigneeId }: UnassignPackagePayload) => ({
        url: `/accounts/${accountId}/client-records/${clientRecordId}/packages/${packageId}/assignees/${assigneeId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [SSTagTypes.PackageAssignmentList, SSTagTypes.PackageList]
    }),
    updatePackageUtilisation: builder.mutation<undefined, UpdatePackageUtilisationPayload>({
      query: ({ accountId, clientRecordId, packageId, assigneeId, payload }: UpdatePackageUtilisationPayload) => ({
        url: `/accounts/${accountId}/client-records/${clientRecordId}/packages/${packageId}/assignees/${assigneeId}/utilisations`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: [SSTagTypes.PackageAssignmentList]
    })
  })
});

export const {
  useCreatePackageMutation,
  useUpdatePackageMutation,
  useGetPackageListQuery,
  useGetPackageQuery,
  usePatchPackageStatusMutation,
  useAssignPackageMutation,
  useGetMinifiedPackagesQuery,
  useGetAssignmentPackageQuery,
  useGetPackageBookableAppointmentQuery,
  useUnassignPackageMutation,
  useUpdatePackageUtilisationMutation
} = packageApiSlice;
