import styles from './OpenDataVariable.module.scss';
import columnStyles from '../DataCategory/VariableListColumn.module.scss';
import classNames from 'classnames';
import { OpenDataFieldVariable } from '../../CollectData.interface';
import VariableForm from '../VariableForm/VariableForm';
import { QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';
import { Popconfirm } from 'antd';
import { DraggableProvidedDragHandleProps, DroppableStateSnapshot } from 'react-beautiful-dnd';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

export const QuestionTypeDisplay = {
  [QuestionType.multipleChoice]: 'Multiple Choice',
  [QuestionType.freeText]: 'Text',
  [QuestionType.numeric]: 'Number',
  [QuestionType.email]: 'Email',
  [QuestionType.phone]: 'Phone Number',
  [QuestionType.date]: 'Date',
  [QuestionType.scale]: 'Scale',
  [QuestionType.multipleAnswers]: 'Multiple Answer',
  [QuestionType.multipleChoiceColourFreeText]: 'Multiple Choice Colour Free Text',
  [QuestionType.multipleChoiceFreeText]: 'Multiple Choice Free Text',
  [QuestionType.barSlider]: 'Bar Slider',
  [QuestionType.hexSlider]: 'Hex Slider',
  [QuestionType.vote]: 'Vote',
  [QuestionType.selectOther]: 'Select Other',
  [QuestionType.simpleSlider]: 'Simple Slider'
};

const OpenDataVariable = ({
  editable,
  variable,
  onDelete,
  onChange,
  editingVariable,
  deletingVariable,
  setEditingVariable,
  setDeletingVariable,
  commonVariables,
  scaleTypes,
  isCommonVariablesLoading,
  dragHandleProps,
  dragSnapshot
}: {
  editable: boolean;
  variable: Partial<OpenDataFieldVariable>;
  onChange: (value: Partial<OpenDataFieldVariable>) => void;
  onDelete: (id: string) => void;
  editingVariable: string;
  deletingVariable: string;
  setEditingVariable: (id: string) => void;
  setDeletingVariable: (id: string) => void;
  commonVariables: OpenDataFieldVariable[];
  scaleTypes: OpenDataFieldVariable[];
  isCommonVariablesLoading: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  dragSnapshot?: DroppableStateSnapshot;
}) => {
  const editing = editingVariable === variable.id;
  const deleting = deletingVariable === variable.id;

  const handlePopConfirmVisible = (visible: boolean) => {
    if (!visible) {
      setDeletingVariable('');
    }
  };

  return !editing ? (
    <div
      className={classNames(
        styles.listItem,
        columnStyles.listItem,
        editable && columnStyles.editable,
        dragSnapshot?.isDraggingOver &&
          variable.id &&
          dragSnapshot.draggingOverWith?.includes(variable.id) &&
          styles.isDragging,
        deleting && styles.deleting,
        !dragSnapshot?.isDraggingOver && styles.showActionButton
      )}
    >
      <div className={columnStyles.question}>{variable.stem}</div>
      <div className={columnStyles.name}>
        {variable.variableName}
        {variable.variableId === 'PI001' && (
          <HelpOutLineWithTooltips
            tooltipsClass={styles.tooltipsClass}
            iconClass={styles.iconClass}
            id={'clientIdTooltip'}
            desc={'This question will not be included in surveys'}
          />
        )}
      </div>
      <div className={columnStyles.id}>{variable.variableId || '-'}</div>
      <div className={columnStyles.format}>{variable.type ? QuestionTypeDisplay[variable.type] : ''}</div>
      <div className={classNames(columnStyles.button, styles.actionButton)}>
        {!variable.isCommonVariable && (
          <ButtonAlt
            size={'small'}
            variant="text"
            onClick={() => setEditingVariable(variable.id!)}
            disabled={editingVariable !== ''}
            icon={'edit'}
            fab
          />
        )}
        <Popconfirm
          onVisibleChange={handlePopConfirmVisible}
          title="Do you want to remove this question?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => {
            onDelete(variable.id!);
            setDeletingVariable('');
          }}
          disabled={editingVariable !== ''}
          placement="topRight"
          onCancel={() => setDeletingVariable('')}
        >
          <ButtonAlt
            size={'small'}
            variant="text"
            onClick={() => setDeletingVariable(variable.id!)}
            disabled={editingVariable !== ''}
            icon={'delete'}
            fab
          />
        </Popconfirm>
        <div {...dragHandleProps}>
          <ButtonAlt
            useDivAsBtn
            disabled={editingVariable !== ''}
            variant={'text'}
            size={'medium'}
            icon={'reorder'}
            fab
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.editCard}>
      <VariableForm
        initValue={variable}
        onCancel={() => setEditingVariable('')}
        onSubmit={(v) => {
          onChange(v);
          setEditingVariable('');
        }}
        commonVariables={commonVariables}
        scaleTypes={scaleTypes}
        isCommonVariablesLoading={isCommonVariablesLoading}
      />
    </div>
  );
};

export default OpenDataVariable;
