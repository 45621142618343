import classnames from 'classnames';
import { useEffect, useState, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './NestedSelect.module.scss';

export enum DropdownType {
  Menu = 'menu',
  Select = 'select'
}
interface NestedSelectProps {
  type?: DropdownType;
  positionSubMenu?: 'left';
  dropdownButtonIcon?: string; //Get from google icon
  isSmallSubMenu?: boolean;
  options: {
    value: string;
    label: string;
    icon?: string; //Get from google icon
    isOpenAfterSelected?: boolean;
    className?: string;
    subMenu?: {
      value: string;
      label: string | JSX.Element;
      isDisable?: boolean;
      tooltip?: string;
      isOpenAfterSelected?: boolean;
      className?: string;
    }[];
  }[];
  placeholder?: string;
  value: {
    firstSelectedOption?: string;
    secondSelectedOption?: string[];
  };
  onSelected: ({
    firstSelectedOption,
    secondSelectedOption
  }: {
    firstSelectedOption: string | undefined;
    secondSelectedOption?: string;
  }) => void;
  onClickOutSide?: () => void;
  subMenuClassName?: string;
}

const NestedSelect = ({
  type = DropdownType.Select,
  dropdownButtonIcon,
  options,
  placeholder,
  positionSubMenu,
  value: { firstSelectedOption, secondSelectedOption },
  isSmallSubMenu,
  onSelected,
  onClickOutSide,
  subMenuClassName
}: NestedSelectProps) => {
  const menuNode = useRef<HTMLDivElement>(null);
  const subMenuNode = useRef<HTMLDivElement>(null);

  const { isEdgeAdminView } = useGetAccountPackageView();
  const [firstValue, setFirstValue] = useState<string>();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [isSubMenuExpanded, setIsSubMenuExpanded] = useState(false);
  const subMenu = options.find((item) => item.subMenu)?.subMenu;

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target) || subMenuNode.current?.contains(event.target)) {
      return;
    }
    setIsMenuExpanded(false);
    setIsSubMenuExpanded(false);

    onClickOutSide && onClickOutSide();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const getDisplayValue = () => {
    const target = options.find((item) => item.value === firstSelectedOption);
    const main = target?.label;

    const sub = secondSelectedOption
      ? target?.subMenu?.find((item) => item.value === secondSelectedOption[0])?.label
      : null;

    return sub ? `${main} - ${sub}` : main;
  };

  return (
    <>
      <div ref={menuNode}>
        <div
          onClick={() => {
            setIsMenuExpanded(!isMenuExpanded);
            setIsSubMenuExpanded(false);
          }}
        >
          {type === DropdownType.Select ? (
            <div className={styles.container}>
              <span className={styles.placeholder}>{firstSelectedOption ? getDisplayValue() : placeholder}</span>
              <i className={classnames('material-icons', styles.dropdownIndicatorIcon)}>arrow_drop_down</i>
            </div>
          ) : (
            <i
              className={classnames(
                'material-icons-outlined',
                styles.buttonDropdown,
                isEdgeAdminView && 't23-admin-theme'
              )}
            >
              {dropdownButtonIcon || 'more_vert'}
            </i>
          )}
        </div>

        <div className={styles.menuWrapper}>
          <div className={isMenuExpanded ? styles.menuShow : styles.menuHide}>
            {options.map((item, index) => (
              <div
                key={index}
                className={classnames(styles.optionWrapper, isEdgeAdminView && styles.adminColor, item.className)}
                onClick={() => {
                  setFirstValue(item.value);
                  if (item.subMenu) {
                    setIsSubMenuExpanded(true);
                  } else {
                    !item.isOpenAfterSelected && setIsMenuExpanded(false);
                    setIsSubMenuExpanded(false);
                    onSelected({
                      firstSelectedOption: item.value
                    });
                  }
                }}
              >
                <div className={styles.labelItem}>
                  {item.icon && <i className={`material-icons ${styles.itemIcon}`}>{item.icon}</i>}
                  <span>{item.label}</span>
                </div>
                {item.subMenu && <i className={`material-icons ${styles.navIcon}`}>navigate_next</i>}
              </div>
            ))}
          </div>
        </div>
      </div>
      {subMenu && (
        <div ref={subMenuNode}>
          <div className={styles.subMenuWrapper}>
            <div
              className={
                isSubMenuExpanded
                  ? classnames(
                      styles.subMenuShow,
                      positionSubMenu === 'left' && styles.leftPosition,
                      isSmallSubMenu && styles.smallSubMenu,
                      subMenuClassName
                    )
                  : styles.subMenuHide
              }
            >
              {subMenu.map((item, index) => (
                <div
                  key={index}
                  className={classnames(styles.optionWrapper, isEdgeAdminView && styles.adminColor, item.className)}
                  onClick={() => {
                    if (item.isDisable) {
                      return;
                    }
                    !item.isOpenAfterSelected && setIsMenuExpanded(false);
                    !item.isOpenAfterSelected && setIsSubMenuExpanded(false);
                    onSelected({
                      firstSelectedOption: firstValue,
                      secondSelectedOption: item.value
                    });
                  }}
                  data-tooltip-id={item.tooltip && `tooltip-${item.value}`}
                >
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
          </div>
          {subMenu
            .filter(({ tooltip }) => tooltip)
            .map((item, index) => (
              <Tooltip id={`tooltip-${item.value}`} className={styles.tooltip} key={index}>
                {item.tooltip}
              </Tooltip>
            ))}
        </div>
      )}
    </>
  );
};

export default NestedSelect;
