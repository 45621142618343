import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';
import moment from 'moment';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { ClientDataHistory } from '../../../../ClientData.interface';
import { DATE_FORMAT, getClientDataSourceName, getHistoryValue } from '../../../../constants';
import styles from '../../ClientDataHistoryList.module.scss';

interface HistoryItemProps {
  clientRecordId: string;
  variableId: string;
  history: ClientDataHistory;
  variableType: QuestionType;
  variableOptions: {
    label: string;
    value: string;
  }[];
}

const HistoryItem = ({ clientRecordId, variableId, history, variableOptions, variableType }: HistoryItemProps) => {
  const { CLIENTS } = useRoutesGenerator();

  const historyValue = useMemo(
    () => getHistoryValue(history.decryptedValue || history.value, variableType, variableOptions),
    [history.value, history.decryptedValue, variableType, variableOptions]
  );

  const otherValue = history.decryptedOtherValue || history.otherValue;

  return (
    <div className={styles.item}>
      <div className={styles.variableId}>{variableId}</div>
      <div className={styles.category}>{history.categoryName}</div>
      <div className={styles.detail}>
        <div>{historyValue}</div>
        {otherValue && (
          <div>
            <span className={styles.otherValueLabel}>NOTE</span>
            {otherValue}
          </div>
        )}
      </div>
      <div className={styles.entryDate}>{moment(history.createdAt).format(DATE_FORMAT)}</div>
      <div className={styles.updatedBy}>{history.createdBy.name}</div>
      <div className={styles.source}>
        {history.source ? (
          history.source.questionsResponsesId ? (
            <Link to={`${CLIENTS.BASE}/${clientRecordId}/survey/${history.source.questionsResponsesId}`}>
              <ButtonAlt className={styles.link} variant={'text'} size={'small'}>
                {getClientDataSourceName(history.source.type)}
              </ButtonAlt>
            </Link>
          ) : (
            getClientDataSourceName(history.source.type)
          )
        ) : (
          '-'
        )}
      </div>
    </div>
  );
};

export default HistoryItem;
