import { useMemo } from 'react';
import classnames from 'classnames';
import styles from './WidgetTextEditor2.module.scss';
import {
  basicInfo,
  JODIT_DISABLE_PLUGINS,
  JODIT_TOOLBAR_BUTTONS,
  fullInfo,
  JODIT_INLINE_TOOLBAR_BUTTONS
} from './constants';
import { JODITLicense } from '../v2/TextEditor2/TextEditor2';
import RichEditor from 'components/RichEditor/RichEditor';
import { useFetchCDFList } from './hooks/useFetchCDFList';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

export interface WidgetTextEditor2Props {
  value: string;
  height?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  className?: string;
  placeholder?: string;
  onChange: (e: any) => void;
  readOnly?: boolean;
  hasBackground?: boolean;
  hasContainerBorder?: boolean;
  enableMention?: boolean;
  enableMentionCDF?: boolean;
  basicMention?: boolean;
  recordId?: string;
  profileId?: string;
  withCaseNoteHeaderButton?: boolean;
  inlineToolbar?: boolean;
  floatToolbar?: boolean;
  floatToolbarPosition?: 'bottom' | 'top';
  onBlurMethod?: boolean;
}

const WidgetTextEditor2 = ({
  value,
  height,
  minHeight,
  maxHeight,
  className,
  placeholder,
  onChange,
  readOnly,
  hasBackground,
  hasContainerBorder,
  enableMention,
  enableMentionCDF,
  basicMention,
  recordId,
  profileId,
  withCaseNoteHeaderButton,
  inlineToolbar,
  floatToolbar,
  floatToolbarPosition,
  onBlurMethod
}: WidgetTextEditor2Props) => {
  const { cdfList } = useFetchCDFList(enableMentionCDF);
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();

  const autocompleteList = useMemo(() => {
    if (basicMention) {
      return basicInfo;
    }
    if (enableMentionCDF) {
      return [...fullInfo(medicareRebateFeatureToggle), ...cdfList];
    }
    return fullInfo(medicareRebateFeatureToggle);
  }, [basicMention, enableMentionCDF, medicareRebateFeatureToggle, cdfList]);

  const config = useMemo(
    () => ({
      license: JODITLicense,
      enter: 'div',
      height: height,
      autofocus: true,
      useSearch: false,
      statusbar: false,
      readonly: readOnly,
      allowResizeY: false,
      minHeight: minHeight,
      maxheight: maxHeight,
      toolbarAdaptive: false,
      fillEmptyParagraph: true,
      placeholder: placeholder || '',
      buttons: JODIT_TOOLBAR_BUTTONS,
      disablePlugins: JODIT_DISABLE_PLUGINS,
      uploader: {
        insertImageAsBase64URI: true
      },
      ...(inlineToolbar && {
        toolbar: false,
        popup: {
          selection: JODIT_INLINE_TOOLBAR_BUTTONS
        },
        toolbarInline: true,
        toolbarInlineForSelection: true
      }),
      ...(enableMention && {
        autocomplete: {
          sources: [autocompleteList],
          insertValueRenderer: (item: any) => `<span class="mention" contenteditable="false">${item.value}</span>`,
          maxItems: autocompleteList.length,
          isMatchedQuery: (q: string, value: string) => {
            if (q === '{') return true;
            if (q.startsWith('{{')) {
              if (q.length <= 3) {
                return value.toLowerCase().startsWith(q.toLowerCase());
              }
              return value.substring(2).toLowerCase().indexOf(q.substring(2).toLowerCase()) >= 0;
            }
            if (q.length >= 3) {
              return value.substring(2).toLowerCase().indexOf(q.toLowerCase()) >= 0;
            }
          }
        }
      }),
      controls: {
        paragraph: {
          list: {
            h1: 'Heading 1',
            h2: 'Heading 2',
            h3: 'Heading 3',
            h4: 'Heading 4',
            h5: 'Heading 5',
            h6: 'Heading 6',
            div: 'Normal',
            p: 'Paragraph',
            blockquote: 'Quote'
          }
        }
      },
      spellcheck: true
    }),
    [placeholder, readOnly, enableMention, autocompleteList, height, minHeight, maxHeight, inlineToolbar]
  );

  return (
    <div
      className={classnames(
        className,
        isEdgeAdminView && 't23-admin-theme',
        styles.container,
        `${hasBackground ? styles.hasBackground : ''} ${hasContainerBorder ? styles.hasContainerBorder : ''}`,
        floatToolbar && (floatToolbarPosition === 'top' ? styles.floatToolbarTop : styles.floatToolbarBottom)
      )}
    >
      <RichEditor
        value={value}
        config={config}
        onChange={onChange}
        recordId={recordId}
        profileId={profileId}
        withCaseNoteHeaderButton={withCaseNoteHeaderButton}
        onBlurMethod={onBlurMethod}
      />
    </div>
  );
};

export default WidgetTextEditor2;
