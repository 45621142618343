import { putOnboardTriageClient } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import i18n from 'i18n';
import { newClientForm } from 'components/AddPatientModalV2/AddPatientModalV2Interface';

export const onboardTriageClient = async (
  token: string,
  clientRecordId: string,
  clientProfiles: newClientForm['clientProfiles'],
  quickNote: newClientForm['quickNote']
) => {
  const massageData = {
    clientProfiles: clientProfiles
      .map((clientProfile) => {
        const { _id, onboarding, consentForm, assessmentForm } = clientProfile;

        if (
          !onboarding.isCheckOnBoarding &&
          !consentForm.isCheckOnConsentForm &&
          !assessmentForm.isCheckOnAssessmentForm
        ) {
          return undefined;
        }

        return {
          _id,
          ...(onboarding.isCheckOnBoarding && {
            onboardingSurveyIds: onboarding.onboardingSurveyIds
          }),
          ...(consentForm.isCheckOnConsentForm && {
            consentFormIds: consentForm.consentFormIds
          }),
          ...(assessmentForm.isCheckOnAssessmentForm && {
            assessmentFormIds: assessmentForm.assessmentFormIds
          })
        };
      })
      .filter(Boolean),
    ...(quickNote.includeQuickNote && {
      quickNote: {
        title: i18n.t('label.invite_client_to_signup'),
        body: quickNote.note
      }
    })
  };

  const callPutOnboardTriageClient = await putOnboardTriageClient(token, clientRecordId, massageData);
  const result = await callPutOnboardTriageClient.json();
  return result;
};
