import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useState, useEffect, useCallback } from 'react';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';

import styles from './GeneralSettings.module.scss';
import { Skeleton, notification } from 'antd';
import ToggleSwitchV2, { ToggleList } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import CheckBox from 'components/CheckBox/CheckBox';
import {
  defaultGeneralSettings,
  IGeneralSettings,
  useGetGeneralSettingsQuery,
  usePatchGeneralSettingsMutation
} from 'redux/endpoints/clinicianProfileServices/generalSettings';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

export const GeneralSettings = () => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { isPackageEnabled } = useGetFeatureToggle();

  const [generalSettingsState, setGeneralSettingsState] = useState<IGeneralSettings>(defaultGeneralSettings);

  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [patchGeneralSettings] = usePatchGeneralSettingsMutation();

  const { data: generalSettings, isLoading: generalSettingsLoading } = useGetGeneralSettingsQuery(
    {
      accountId: accountId
    },
    { skip: !accountId }
  );

  useEffect(() => {
    if (generalSettings) {
      setGeneralSettingsState(generalSettings);
    }
  }, [generalSettings]);

  const handleGeneralSettingsChange = useCallback(
    (toggle: ToggleList) => {
      setGeneralSettingsState({
        ...generalSettingsState,
        enforceUserMFA: toggle.id === 'ON'
      });
    },
    [generalSettingsState]
  );

  const handlePackageSettingsChange = useCallback(
    (toggleValue: ToggleList) => {
      setGeneralSettingsState({
        ...generalSettingsState,
        packageOnlyBookings: {
          isActive: toggleValue?.id === 'yes',
          onlyRestrictUserRole:
            toggleValue?.id === 'no' ? false : generalSettingsState.packageOnlyBookings.onlyRestrictUserRole
        }
      });
    },
    [generalSettingsState]
  );

  const handleSuperUserSettingsChange = useCallback(
    (value: boolean) => {
      setGeneralSettingsState({
        ...generalSettingsState,
        packageOnlyBookings: {
          ...generalSettingsState.packageOnlyBookings,
          onlyRestrictUserRole: value
        }
      });
    },
    [generalSettingsState]
  );

  const handleSubmit = async () => {
    if (token && accountId) {
      try {
        setSubmitStatus('active');
        await patchGeneralSettings({ accountId, payload: generalSettingsState });
        setTimeout(() => {
          setSubmitStatus('finished');
        }, 1000);
        setTimeout(() => {
          notification.success({
            message: 'General settings updated successfully'
          });
          setSubmitStatus('');
        }, 2000);
      } catch (error) {
        notification.error({
          message: 'Error updating general settings'
        });
      } finally {
        setTimeout(() => {
          setSubmitStatus('');
        }, 2000);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerCopy}>
          <div className={styles.title}>General Settings</div>
          <div className={styles.description}>All general settings are set in this tab.</div>
        </div>
        <div className={styles.headerCTA}>
          <ButtonAlt
            id={'saveGeneralSettings'}
            onClick={handleSubmit}
            status={submitStatus}
            disabled={generalSettingsLoading}
          >
            Save and Apply
          </ButtonAlt>
        </div>
      </div>
      <div className={styles.subHeader}>
        Multi-Factor Authentication Settings
        <HelpOutLineWithTooltips
          id={'security-settings'}
          desc={
            'Require team members to enter a code from their mobile device when they sign in. This adds an extra layer of security to your account.'
          }
        />
      </div>
      <form className={styles.form}>
        {!generalSettingsLoading ? (
          <div key={'enforceUserMFA'} className={styles.preference}>
            <div className={styles.categoryLabel}>
              <div className={generalSettingsState.enforceUserMFA ? styles.activeDot : styles.inactiveDot} />
              Enforce MFA for Team
            </div>
            <ToggleSwitchV2
              id={'enforceUserMFA'}
              size={'medium'}
              className={styles.toggle}
              toggleList={[
                {
                  label: 'OFF',
                  isActive: !generalSettingsState.enforceUserMFA,
                  id: 'OFF'
                },
                {
                  label: 'ON',
                  isActive: generalSettingsState.enforceUserMFA,
                  id: 'ON'
                }
              ]}
              onChangeStatus={handleGeneralSettingsChange}
              isLoading={generalSettingsLoading || submitStatus === 'active'}
            />
          </div>
        ) : (
          <div className={styles.loadingWrapper}>
            <Skeleton.Input active className={styles.settingLoading} />
            <Skeleton.Input active className={styles.settingLoading} />
          </div>
        )}
      </form>
      {isPackageEnabled && (
        <>
          <div className={styles.subHeader}>
            Package Booking Settings
            <HelpOutLineWithTooltips
              id={'package-settings'}
              desc={
                'Using packages allows you to control in detail what services are bookable for a particular client. You can increase that control by preventing alternative services being set, and locking it only to assigned packages. We support ability to override this for those with superuser or admin accounts when they are booking in services'
              }
            />
          </div>
          <div className={styles.form}>
            {!generalSettingsLoading ? (
              <div className={styles.preference}>
                <div className={styles.categoryLabel}>
                  <div
                    className={
                      generalSettingsState.packageOnlyBookings.isActive ? styles.activeDot : styles.inactiveDot
                    }
                  />
                  Limit services to ONLY those in assigned package?
                </div>
                <div className={styles.packageToggleWrapper}>
                  <ToggleSwitchV2
                    className={styles.toggle}
                    id={'packageSettings'}
                    size={'medium'}
                    toggleList={[
                      {
                        id: 'yes',
                        isActive: generalSettingsState.packageOnlyBookings.isActive,
                        label: 'YES'
                      },
                      {
                        id: 'no',
                        isActive: !generalSettingsState.packageOnlyBookings.isActive,
                        label: 'NO'
                      }
                    ]}
                    onChangeStatus={handlePackageSettingsChange}
                  />
                  <CheckBox
                    id={`includeSuperuser`}
                    label={'Superuser and admin override'}
                    className={styles.checkBox}
                    value={generalSettingsState.packageOnlyBookings.onlyRestrictUserRole}
                    onChange={(e) => handleSuperUserSettingsChange(e.target.checked)}
                    disabled={!generalSettingsState.packageOnlyBookings.isActive}
                    useT23style
                  />
                </div>
              </div>
            ) : (
              <div className={styles.loadingWrapper}>
                <Skeleton.Input active className={styles.settingLoading} />
                <Skeleton.Input active className={styles.settingLoading} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default GeneralSettings;
