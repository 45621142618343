import { Skeleton } from 'antd';

import { PaymentMethod } from '../../interface';
import PaymentMethodsModal from './components/PaymentMethodsModal/PaymentMethodsModal';
import styles from './PaymentMethods.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

export const getPaymentMethodDescription = ({
  accountName,
  email,
  instructions,
  paymentType,
  stripeConnectedBank
}: Pick<PaymentMethod, 'accountName' | 'email' | 'instructions' | 'paymentType' | 'stripeConnectedBank'>) => {
  switch (paymentType) {
    case 'bankTransfer':
      return accountName;
    case 'paypal':
      return email;
    case 'cash':
    case 'other':
      return instructions;
    case 'stripe':
      return stripeConnectedBank?.bankName && `Stripe paying into ${stripeConnectedBank.bankName}`;
  }
};

export const PAYMENT_TYPE_LABELS = {
  bankTransfer: 'Bank Transfer',
  stripe: 'Online Payment',
  paypal: 'Paypal',
  cash: 'Cash',
  other: 'Other'
};

interface PaymentMethodsProps {
  paymentMethods: PaymentMethod[];
  isPaymentMethodsLoading: boolean;
  isPaymentMethodsModalVisible: boolean;
  setIsPaymentMethodsModalVisible: (isPaymentMethodsModalVisible: boolean) => void;
  onSavePaymentMethods: () => void;
  isEditingAllowed: boolean;
}

const PaymentMethods = ({
  paymentMethods,
  isPaymentMethodsLoading,
  isPaymentMethodsModalVisible,
  setIsPaymentMethodsModalVisible,
  onSavePaymentMethods,
  isEditingAllowed
}: PaymentMethodsProps) => {
  const handleChangePaymentDetailsClick = () => {
    setIsPaymentMethodsModalVisible(true);
  };

  const handlePaymentMethodsModalClose = (paymentMethods?: PaymentMethod[]) => {
    setIsPaymentMethodsModalVisible(false);

    paymentMethods && onSavePaymentMethods();
  };

  return (
    <>
      <PaymentMethodsModal
        paymentMethods={paymentMethods}
        isPaymentMethodsLoading={isPaymentMethodsLoading}
        visible={isPaymentMethodsModalVisible}
        onClose={handlePaymentMethodsModalClose}
      />
      <div className={styles.container} id={'paymentMethodsId'}>
        <div className={styles.title}>Payment methods</div>
        <div className={styles.paymentMethodsContainer}>
          {isPaymentMethodsLoading ? (
            <Skeleton active />
          ) : (
            paymentMethods.map(({ paymentType, accountName, email, instructions, stripeConnectedBank }, index) => (
              <div className={styles.paymentMethod} key={index}>
                <i className={`material-icons-outlined ${styles.icon}`}>account_balance</i>
                <div className={styles.paymentType}>{PAYMENT_TYPE_LABELS[paymentType]}</div>
                <div className={styles.description}>
                  {getPaymentMethodDescription({ paymentType, accountName, email, instructions, stripeConnectedBank })}
                </div>
              </div>
            ))
          )}
        </div>
        <ButtonAlt
          className={styles.button}
          fullWidth
          variant={'outlined'}
          icon={'settings'}
          onClick={handleChangePaymentDetailsClick}
        >
          Add / change payment details
        </ButtonAlt>
      </div>
    </>
  );
};

export default PaymentMethods;
