import { TaskStatus } from 'interfaces/Tasks/task';
import { GetReviewAppointmentQuery } from 'redux/endpoints/scheduleServices/processAppointment';
import { TasksFilters } from 'redux/features/utils';
import { Paging, SortType, Sorting } from 'redux/features/utils';

export const getReviewAppointmentsRequestPayload = (
  paging: Paging,
  sorting: Sorting,
  filters: TasksFilters,
  status: TaskStatus
): GetReviewAppointmentQuery => {
  const { page, perPage } = paging;
  const { sortType } = sorting;
  const { clients: selectedClients } = filters;

  return {
    page,
    perPage,
    ...(selectedClients.length > 0 && {
      clientRecordIds: selectedClients.map(({ _id }) => _id).join(',')
    }),
    sortByCreatedAt: sortType === SortType.ASC ? '1' : '-1',
    status
  };
};
