import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import styles from './TimeZoneLabel.module.scss';
import { getTimezoneLabel } from 'utils/hooks/GetTimezones/getTimezones';
import { useTimeZone } from 'utils/hooks/useTimeZone';

const TimeZoneLabel = () => {
  const { isMultiTimeZoneEnabled } = useGetFeatureToggle();
  const { timeZoneByView } = useTimeZone();

  return isMultiTimeZoneEnabled ? (
    <div>
      <div className={styles.label}>TIMEZONE</div>
      <div>{getTimezoneLabel(timeZoneByView)}</div>
    </div>
  ) : (
    <></>
  );
};

export default TimeZoneLabel;
