import { notification } from 'antd';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import Select from 'components/v2/Select/Select';
import { FirstAssessment } from 'interfaces/checkInService/AssessmentInterfaces';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetSurveyResponsesQuery } from 'redux/endpoints/checkInServices/surveyResponses';
import {
  selectCurrentEpisodeId,
  selectIsFetchingEpisodes,
  selectIsOutsideAllEpisodes,
  selectIsShowAllData
} from 'redux/episodes/episodeSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { getInitialOBAssessmentByRecordId } from 'utils/http/CheckInService/ReportList/reportList';
import { MONGODB_OBJECT_ID } from 'utils/regex';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import PatientDetailsSurveysContent from './components/PatientDetailsSurveysContent/PatientDetailsSurveysContent';
import PatientDetailsSurveysHeader from './components/PatientDetailsSurveysHeader/PatientDetailsSurveysHeader';
import styles from './PatientDetailsSurveys.module.scss';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { DataRefreshHandle } from '../../PatientDetailsContent';
import { setIsRefreshDataDisabled } from 'redux/clients/clientDetailsSlice';

interface PatientDetailsSurveysProps {
  recordId: string;
  currentTab: string;
  surveyId?: string;
  clientRecordData: clientRecordsInterface;
  isEdgeReceptionist: boolean;
}

const PatientDetailsSurveys = forwardRef<DataRefreshHandle, PatientDetailsSurveysProps>(
  ({ recordId, currentTab, surveyId, clientRecordData, isEdgeReceptionist }, ref) => {
    const { token } = useGetAccessToken();
    const { CLIENTS } = useRoutesGenerator();
    const { isEdgePlanUser } = useGetAccountPackage();
    const [t] = useTranslation();
    const dispatch = useAppDispatch();

    const [isLoadingSurveyData, setIsLoadingSurveyData] = useState(true);
    const [selectedSurveyId, setSelectedSurveyId] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [initSurvey, setInitSurvey] = useState<FirstAssessment>({} as FirstAssessment);
    const [isInitSurvey, setIsInitSurvey] = useState(surveyId ? MONGODB_OBJECT_ID.test(surveyId) : false);
    const [selectOptions, setSelectOptions] = useState<IOptionItem[]>([]);
    const [selectValue, setSelectValue] = useState<IOptionItem | null>();
    const { isEoCEnabled } = useGetFeatureToggle();

    const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
    const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
    const isShowAllData = useAppSelector(selectIsShowAllData);
    const isFetchingEpisodes = useAppSelector(selectIsFetchingEpisodes);

    const {
      data: surveyList,
      isLoading: isLoadingSurveys,
      isFetching: isFetchingSurveys,
      isError: isFetchSurveyListError,
      refetch: refetchSurveyList
    } = useGetSurveyResponsesQuery(
      {
        recordId,
        needToSort: true,
        params: {
          ...(isEoCEnabled && currentEpisodeId && { episodeId: currentEpisodeId }),
          ...(isEoCEnabled && isOutsideAllEpisodes && { showOutsideOfEpisode: isOutsideAllEpisodes })
        }
      },
      {
        skip:
          isEdgeReceptionist ||
          (isEoCEnabled && isFetchingEpisodes) ||
          (isEoCEnabled && !isOutsideAllEpisodes && !isShowAllData && !currentEpisodeId)
      }
    );

    useImperativeHandle(ref, () => ({
      onDataRefresh: () => {
        navigate(`${CLIENTS.BASE}/${recordId}/survey`);
        refetchSurveyList();
      }
    }));

    useEffect(() => {
      dispatch(setIsRefreshDataDisabled(isFetchingSurveys || isLoadingSurveys));
    }, [isFetchingSurveys, isLoadingSurveys, dispatch]);

    useEffect(() => {
      if (!surveyId && !isLoadingSurveys && !isFetchingSurveys && surveyList && surveyList[0]?._id) {
        navigate(`${CLIENTS.BASE}/${recordId}/survey/${surveyList[0]._id}`);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surveyList, isLoadingSurveys, isFetchingSurveys]);

    useEffect(() => {
      if (isFetchSurveyListError) {
        notification.error({ message: t('form.error.fetch_client_survey') });
      }
    }, [isFetchSurveyListError, t]);

    const noSurveyId =
      !isLoadingSurveys && !isFetchingSurveys && surveyList && surveyList.length !== 0 ? surveyList[0]._id : '';

    const getSurveyId: string = isEdgePlanUser
      ? location.pathname.split('/')[5] ?? noSurveyId
      : location.pathname.split('/')[4] ?? noSurveyId;

    const noSurveys = !isLoadingSurveys && !isFetchingSurveys && surveyList?.length === 0;

    const fetchSurveyDetails = async (patientSurveyId: string) => {
      if (patientSurveyId) {
        setIsLoadingSurveyData(true);

        const isInitSurvey = MONGODB_OBJECT_ID.test(patientSurveyId);

        if (isInitSurvey) {
          setIsInitSurvey(true);

          try {
            const getInitReportResponseById = await getInitialOBAssessmentByRecordId(token, recordId, patientSurveyId);
            const survey = await getInitReportResponseById.json();
            setInitSurvey(survey);
            setIsLoadingSurveyData(false);
          } catch (ex) {
            notification.error({ message: 'Something went wrong while trying to get init survey' });
          }
        } else {
          setIsInitSurvey(false);
        }
      } else {
        setIsLoadingSurveyData(false);
      }
    };

    useEffect(() => {
      setSelectedSurveyId('');
      setSelectOptions([]);
      setSelectValue(null);
    }, [isFetchingEpisodes, isFetchingSurveys, isLoadingSurveys]);

    useEffect(() => {
      if (token && surveyId) {
        fetchSurveyDetails(surveyId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surveyId, token, recordId]);

    useEffect(() => {
      if (!surveyList?.length || isFetchingSurveys || isLoadingSurveys) {
        return;
      }
      const itemLabel = surveyList.find((i) => i._id === getSurveyId);
      if (itemLabel) {
        setSelectedSurveyId(itemLabel.name);
      }

      setSelectOptions(
        surveyList
          ?.filter((item, idx, arr) => arr.findIndex((i) => i.name === item.name) === idx)
          ?.map((i) => ({ value: i._id, label: i.name }))
      );

      setSelectValue(
        surveyList.length
          ? selectedSurveyId !== ''
            ? {
                value: surveyList.find((i) => i.name === selectedSurveyId)?._id || '',
                label: selectedSurveyId
              }
            : surveyList[0]
            ? { value: surveyList[0]._id, label: surveyList[0].name }
            : null
          : null
      );
    }, [surveyList, getSurveyId, setSelectedSurveyId, selectedSurveyId, isFetchingSurveys, isLoadingSurveys]);

    const getReferralFile = clientRecordData.referral?.files?.find(
      (fileObj) => fileObj.questionsResponsesId === surveyId
    );

    return (
      <>
        <div className={styles.headerContainer}>
          {!noSurveys && (
            <div>
              <Select
                filledArrow
                label="SURVEY RESPONSES"
                options={selectOptions}
                onChange={(val: any) => {
                  if (val?.label !== selectedSurveyId) {
                    setSelectedSurveyId(val?.label);
                    navigate(`${CLIENTS.BASE}/${recordId}/survey/${val?.value}`);
                  }
                }}
                isSearchable={false}
                value={selectValue}
                isDisabled={isEoCEnabled && isFetchingEpisodes}
                isLoading={isLoadingSurveys || isFetchingSurveys}
                containerClass={styles.selectSurveyId}
                controlStyle={{ boxShadow: 'none' }}
              />
            </div>
          )}
        </div>
        <div className={styles.container}>
          {isLoadingSurveys || isFetchingSurveys ? (
            <div className={styles.loading}>
              <LoadingCircle />
            </div>
          ) : (
            <>
              {surveyId && surveyList && surveyList.length > 0 ? (
                <>
                  <PatientDetailsSurveysHeader
                    recordId={recordId}
                    currentTab={currentTab}
                    surveyId={surveyId}
                    surveyList={surveyList}
                  />
                  {isLoadingSurveyData ? (
                    <div className={styles.loading}>
                      <LoadingCircle />
                    </div>
                  ) : isInitSurvey ? (
                    <PatientDetailsSurveysContent survey={initSurvey} referralFile={getReferralFile} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>No survey created yet or there is no survey selected</>
              )}
            </>
          )}
        </div>
      </>
    );
  }
);

export default PatientDetailsSurveys;
