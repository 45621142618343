import { MouseEvent, useState } from 'react';
import styles from './GroupsSection.module.scss';

interface GroupsSectionProps {
  groups: { _id: string; name: string }[];
}

const GroupsSection = ({ groups }: GroupsSectionProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const totalGroup = groups.length;

  const handleShowMore = (event: MouseEvent, showValue: boolean) => {
    event.stopPropagation();
    setShowAll(showValue);
  };

  return (
    <div className={styles.container}>
      {totalGroup > 3 && !showAll ? (
        <>
          {groups.slice(0, 3).map((group) => (
            <div title={group.name} key={group._id} className={styles.groupPill}>
              {group.name}
            </div>
          ))}
          <button onClick={(event) => handleShowMore(event, true)}>{`+ ${totalGroup - 3} more`}</button>
        </>
      ) : (
        <>
          {groups.length > 0
            ? groups.map((group) => (
                <div title={group.name} key={group._id} className={styles.groupPill}>
                  {group.name}
                </div>
              ))
            : '-'}
          {totalGroup > 3 && <button onClick={(event) => handleShowMore(event, false)}>Show less</button>}
        </>
      )}
    </div>
  );
};

export default GroupsSection;
