import FlexBox from 'components/FlexBox/FlexBox';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import TextEditor2 from 'components/v2/TextEditor2/TextEditor2';
import { IInvoiceTemplate } from 'pages/Invoices/interface';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectInvoiceForm, setInvoiceForm } from 'redux/invoices/createInvoiceWithTemplateSlice';

import InlineEditField from '../InlineEditField/InlineEditField';
import styles from './InvoiceInfoSection.module.scss';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import WidgetTextEditor2 from 'components/WidgetTextEditor2/WidgetTextEditor2';
import { getInvoiceFields, getRecipientsField } from './helper';
import { useMemo } from 'react';

const IS_DEVELOPMENT = isDevelopmentENV();

interface InvoiceInfoSectionProps {
  invoiceTemplate: IInvoiceTemplate;
}
const InvoiceInfoSection = ({ invoiceTemplate }: InvoiceInfoSectionProps) => {
  const dispatch = useAppDispatch();
  const invoiceForm = useAppSelector(selectInvoiceForm);

  const { invoiceTitle, description, invoiceId, issueDate, dueDate } = getInvoiceFields(invoiceForm);
  const recipientFields = useMemo(
    () => getRecipientsField({ invoiceTemplate, recipients: invoiceForm.recipients || [] }),
    [invoiceForm.recipients, invoiceTemplate]
  );

  const onChange = (e: { target: { name: string; value: string } }) => {
    dispatch(
      setInvoiceForm({
        [e.target.name]: e.target.value
      })
    );
  };

  const onChangeRecipients = (e: React.ChangeEvent<HTMLInputElement>) => (fieldIndex: number) => {
    const clonedRecord = { ...invoiceForm };
    const updatedRecipient = {
      ...clonedRecord.recipients[fieldIndex],
      [e.target.name]: e.target.value
    };

    const updatedRecipients = [...clonedRecord.recipients];
    updatedRecipients[fieldIndex] = updatedRecipient;

    dispatch(
      setInvoiceForm({
        recipients: updatedRecipients
      })
    );
  };

  return (
    <FlexBox direction="column" spacing={20} className={styles.wrapper}>
      <MaterialInput
        className={styles.titleInput}
        id="title"
        name="title"
        value={invoiceTitle}
        label=""
        onChange={onChange}
        required
      />
      <FlexBox direction="row" justifyContent="space-between">
        <FlexBox direction="column" className={styles.width350} spacing={4}>
          {[invoiceId, issueDate, dueDate]
            .filter((item) => item.show)
            .map((item, index) => (
              <InlineEditField
                key={`${invoiceId}_invoice_${index}`}
                label={item.label}
                value={item.value}
                name={item.name}
                editable={item.editable}
                onChange={onChange}
                onChangeDate={onChange}
              />
            ))}
        </FlexBox>
        {recipientFields.length > 0 && (
          <FlexBox direction="column" className={styles.width350} spacing={4}>
            {recipientFields.map((item, fieldIndex) => {
              return [item.name, item.address, item.email, item.mobileNumber, item.reference, item.registrationNumber]
                .filter((item) => item.show)
                .map((item, index) => (
                  <InlineEditField
                    key={`${invoiceId}_recipients_${index}`}
                    label={item.label}
                    value={item.value}
                    name={item.name}
                    editable={item.editable}
                    onChange={(e) => onChangeRecipients(e)(fieldIndex)}
                  />
                ));
            })}
          </FlexBox>
        )}
      </FlexBox>
      {description.editable &&
        (IS_DEVELOPMENT ? (
          <WidgetTextEditor2
            height={300}
            onBlurMethod
            hasContainerBorder
            value={description.value}
            onChange={(value) =>
              dispatch(
                setInvoiceForm({
                  description: value
                })
              )
            }
          />
        ) : (
          <TextEditor2
            height={300}
            value={description.value}
            onChange={(value) =>
              dispatch(
                setInvoiceForm({
                  description: value
                })
              )
            }
            onBlurMethod
          />
        ))}
    </FlexBox>
  );
};

export default InvoiceInfoSection;
