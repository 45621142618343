import { SSTagTypes, scheduleServicesApiSlice } from '../../services/scheduleServicesApiSlice';
import { appointmentByClientRecordId } from 'interfaces/Clients/Appointment';
import { NextAppointment } from 'interfaces/Clients/nextAppointment';
import { AppointmentSlots, GetAppointmentsByClinicianIdOrRoomIdQueryParams } from 'interfaces/Schedule/Appointment';
import moment from 'moment';
import queryString from 'query-string';
import { convertTimeToUtcDateObject } from 'utils/helpers/timezone';
import momentTz from 'moment-timezone';
import { MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';

interface GetAppointmentsByRecordIdsPayload {
  accountId: string;
  from: string;
  to: string;
  clientRecordIds: string;
}

interface GetNextAppointmentsByRecordIdsPayload {
  asAdmin: boolean;
  clientRecordIds: string;
}

interface GetNextAppointmentsRequest {
  accountId: string;
  clientRecordId: string;
  isAdmin?: boolean;
}

interface getAppointmentsByClinicianIdPayload {
  accountId: string;
  asUser: boolean;
  params: GetAppointmentsByClinicianIdOrRoomIdQueryParams;
  timeZone: string;
}

interface GetClientAppointmentRequest {
  from?: string;
  to?: string;
  clientRecordId?: string;
  showCreatedBy?: boolean;
  showHistories?: boolean;
  showDeletedAppointments?: boolean;
  episodeId?: string;
  showOutsideOfEpisode?: boolean;
  timeZone: string;
}

export const appointmentApiSlice = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppointmentsByClinicianIds: builder.query<AppointmentSlots[], getAppointmentsByClinicianIdPayload>({
      query: ({ accountId, params, asUser }) => {
        const massageParam = {
          from: params.from,
          to: params.to,
          roomIds: params.roomIds,
          clinicianIds: params.clinicianIds,
          asUser: asUser ? 1 : undefined,
          includeCancelledAppointment: '1' // TODO include cancelled by default first
        };

        const qParam = queryString.stringify(massageParam);
        return {
          url: `/accounts/${accountId}/appointments:getByClinicianIdsOrRoomIds?${qParam}`
        };
      },
      transformResponse: (response: AppointmentSlots[], _meta, arg) =>
        response.map((appointmentObj) => ({
          ...appointmentObj,
          ...(appointmentObj?.date &&
            (!appointmentObj.startDateTime || !appointmentObj.endDateTime) && {
              startDateTime:
                appointmentObj.startDateTime ||
                convertTimeToUtcDateObject({
                  date: appointmentObj.date,
                  time: appointmentObj.startTime,
                  timeZone: arg.timeZone
                }).toUTCString(),
              endDateTime:
                appointmentObj.endDateTime ||
                convertTimeToUtcDateObject({
                  date: appointmentObj.date,
                  time: appointmentObj.endTime,
                  timeZone: arg.timeZone
                }).toUTCString()
            })
        })),
      providesTags: [SSTagTypes.AppointmentList]
    }),

    getAppointmentsByRecordIds: builder.query<appointmentByClientRecordId[], GetAppointmentsByRecordIdsPayload>({
      query: ({ accountId, from, to, clientRecordIds }) => ({
        url: `/accounts/${accountId}/appointments?from=${from}&to=${to}&clientRecordIds=${clientRecordIds}`
      })
    }),

    getNextAppointmentsByRecordIds: builder.query<
      { patientNextAppointments: NextAppointment[] },
      GetNextAppointmentsByRecordIdsPayload
    >({
      query: ({ asAdmin, clientRecordIds }) => ({
        url: `/appointments/patients/next?asAdmin=${asAdmin}&clientRecordIds=${clientRecordIds}`
      }),
      providesTags: (_result, _error, arg) =>
        arg.clientRecordIds
          .split(',')
          .map((clientRecordId) => ({ type: SSTagTypes.NextAppointmentByCurrent, id: clientRecordId }))
    }),

    getNextAppointments: builder.query<AppointmentSlots[], GetNextAppointmentsRequest>({
      query: ({ accountId, clientRecordId, isAdmin }) => ({
        url: `${
          isAdmin ? `/accounts/${accountId}` : ''
        }/client-records/${clientRecordId}/appointments/next?today=${moment().format('YYYY-MM-DD')}`
      }),
      providesTags: (_result, _error, arg) => [{ type: SSTagTypes.NextAppointmentByCurrent, id: arg.clientRecordId }]
    }),
    getAppointmentsByGroupId: builder.query({
      query: ({ groupId, from, to }) => ({
        url: `/groups/${groupId}/client-records/appointments?from=${from}&to=${to}`
      })
    }),

    getClientAppointmentsByDateRange: builder.query<AppointmentSlots[], GetClientAppointmentRequest>({
      query: ({
        from,
        to,
        clientRecordId,
        showCreatedBy,
        showHistories,
        showDeletedAppointments,
        episodeId,
        showOutsideOfEpisode
      }) => ({
        url: `/appointments?${queryString.stringify({
          from,
          to,
          clientRecordId,
          showCreatedBy,
          showHistories,
          showDeletedAppointments,
          episodeId,
          showOutsideOfEpisode
        })}`
      }),
      transformResponse: (response: AppointmentSlots[], _meta, arg) =>
        response.map((appointment) => {
          const startDateTime =
            appointment.startDateTime ||
            convertTimeToUtcDateObject({
              date: appointment.date || moment().format('YYYY-MM-DD'),
              time: appointment.startTime,
              timeZone: arg.timeZone
            }).toUTCString();

          const endDateTime =
            appointment.endDateTime ||
            convertTimeToUtcDateObject({
              date: appointment.date || moment().format('YYYY-MM-DD'),
              time: appointment.endTime,
              timeZone: arg.timeZone
            }).toUTCString();

          const startDateTimeDateObj = new Date(startDateTime);
          const endDateTimeDateObj = new Date(endDateTime);

          return {
            ...appointment,
            startDateTime,
            endDateTime,
            date: momentTz.tz(startDateTimeDateObj, arg.timeZone).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT),
            startTime: momentTz.tz(startDateTimeDateObj, arg.timeZone).format('HH:mm'),
            endTime: momentTz.tz(endDateTimeDateObj, arg.timeZone).format('HH:mm')
          };
        }),
      providesTags: (_result, _error, arg) => [
        { type: SSTagTypes.ClientAppointmentsByDateRange, id: arg.clientRecordId }
      ],
      keepUnusedDataFor: 10
    })
  })
});

export const {
  useGetAppointmentsByClinicianIdsQuery,
  useGetAppointmentsByRecordIdsQuery,
  useGetNextAppointmentsByRecordIdsQuery,
  useGetNextAppointmentsQuery,
  useGetAppointmentsByGroupIdQuery,
  useGetClientAppointmentsByDateRangeQuery
} = appointmentApiSlice;
