import styles from './Modal.module.scss';

interface ModalProps {
  isModalOpen: boolean;
  children?: any;
}

const Modal = ({ isModalOpen, children }: ModalProps) => {
  return isModalOpen ? (
    <div className={styles.container}>
      <div className={styles.modalContent}>{children}</div>
    </div>
  ) : null;
};

export default Modal;
