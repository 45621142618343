/* eslint-disable max-depth */
import { ChangeEvent, useState } from 'react';

import styles from './CaseNoteAttachmentBtn.module.scss';
import { CaseNote } from 'interfaces/caseNote';
import { notification } from 'antd';
import { resizeAndCropImage } from './components/resizeCropImage';
import {
  uploadAttachmentByRecordId,
  uploadAttachmentThumbnailByRecordId
} from 'utils/http/DocumentService/Attachments/attachments';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { getFilenameAndExtension } from 'utils/file';

interface CaseNoteAttachmentBtnProps {
  caseNoteAttachmentData: CaseNote['attachments'];
  token: string;
  recordId: string;
  uploadStatus: any;
  onChangeCaseNoteAttachment: any;
}

const CaseNoteAttachmentBtn = ({
  caseNoteAttachmentData,
  token,
  recordId,
  uploadStatus,
  onChangeCaseNoteAttachment
}: CaseNoteAttachmentBtnProps) => {
  const [uploadAttachmentLoading, setUploadAttachmentLoading] = useState(false);

  const MAX_FILE_SIZE = 19 * 1024 * 1024;

  const handleUploadAttachmentClick = () => {
    document.getElementById('patient-upload-attachment')?.click();
  };

  const handleUploadAttachment = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (files.length === 0) {
        return;
      }
      setUploadAttachmentLoading(true);
      uploadStatus(true);

      const uploadedAttachments: CaseNote['attachments'][number][] = [];

      try {
        for (let i = 0; i < files.length; i++) {
          const currentFile = files[i];

          if (currentFile.size > MAX_FILE_SIZE) {
            notification.error({ message: `File ${currentFile.name} is too big` });
            continue;
          }

          const payload: Record<string, any> = {};

          try {
            const uploadedAttachmentDetail = await uploadAttachmentByRecordId(token, recordId, currentFile, payload);

            if (/(image\/)\w+/g.test(currentFile.type)) {
              const attachmentId = uploadedAttachmentDetail._id || '';
              const dataUrl = await resizeAndCropImage(currentFile).catch((ex) => console.error(ex));

              if (!dataUrl) {
                notification.error({ message: 'Something went wrong while trying to compress your image.' });
                continue;
              }

              const dataArray = dataUrl.split(',');
              const mimeType = dataArray[0].match(/:(.*?);/)?.[1];
              const dataStrings = atob(dataArray[1]);
              let dataLength = dataStrings.length;
              const actualData = new Uint8Array(dataLength);

              while (dataLength--) {
                actualData[dataLength] = dataStrings.charCodeAt(dataLength);
              }

              const { filename, extension } = getFilenameAndExtension(currentFile.name);
              const thumbnailFile = new File([actualData], `${filename}-thumbnail.${extension}`, { type: mimeType });

              const responseWithThumbnail = await uploadAttachmentThumbnailByRecordId(
                token,
                recordId,
                attachmentId,
                thumbnailFile
              );

              uploadedAttachments.push({ _id: responseWithThumbnail._id, title: responseWithThumbnail.title });
            } else {
              uploadedAttachments.push({ _id: uploadedAttachmentDetail._id, title: uploadedAttachmentDetail.title });
            }
          } catch (ex) {
            notification.error({ message: `Failed to upload file ${currentFile.name}` });
            continue;
          }
        }

        if (uploadedAttachments.length > 0) {
          notification.success({
            message: `Successfully uploaded ${uploadedAttachments.length} ${
              uploadedAttachments.length === 1 ? 'attachment' : 'attachments'
            }.`,
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          const newAttachment = [...caseNoteAttachmentData, ...uploadedAttachments];
          onChangeCaseNoteAttachment(newAttachment);
          setUploadAttachmentLoading(false);
          uploadStatus(false);
        }
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to upload your attachments' });
        setUploadAttachmentLoading(false);
        uploadStatus(false);
      }
    }
  };

  return (
    <div className={styles.attachmentsContainer}>
      {caseNoteAttachmentData.length > 0 && (
        <>
          <div className={styles.title}>ATTACHMENTS</div>
          {caseNoteAttachmentData.map((attachment: any) => (
            <div className={styles.attachmentContainer} key={attachment._id}>
              <i className={`material-icons-outlined ${styles.icon}`}>attach_file</i>
              {attachment.title}
            </div>
          ))}
        </>
      )}
      <ButtonAlt
        size={'small'}
        variant={'text'}
        onClick={!uploadAttachmentLoading ? handleUploadAttachmentClick : undefined}
        icon={'add'}
        status={uploadAttachmentLoading ? 'active' : ''}
        loadingWord={'Uploading...'}
      >
        add attachment
      </ButtonAlt>
      <input
        className={styles.addAttachmentInput}
        id="patient-upload-attachment"
        type="file"
        accept="image/png,image/jpeg,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        multiple
        onChange={handleUploadAttachment}
      />
    </div>
  );
};

export default CaseNoteAttachmentBtn;
