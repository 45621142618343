import { Dropdown, Menu, Skeleton } from 'antd';
import REPORTBUILDERIMG from 'assets/images/reportBuilderImg.png';
import PaginationListV2 from 'components/v2/PaginationListV2/PaginationListV2';
import { useTranslation } from 'react-i18next';
import {
  GetReportResponse,
  useGetReportDetailsQuery,
  useGetReportsQuery
} from 'redux/endpoints/documentServices/report';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  resetPaging,
  selectFilters,
  selectPaging,
  selectSearch,
  setFilters,
  setPaging,
  setSearch
} from 'redux/reports/reportSlice';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import ReportItem from './components/ReportItem/ReportItem';
import styles from './ReportList.module.scss';
import columnStyles from './ReportListColumn.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetClientEncryptedDataByClientRecordIdQuery } from 'redux/endpoints/clinicianProfileServices/client';
import ShareReportModal from 'pages/Report/components/ShareReportModal/ShareReportModal';
import { useState } from 'react';
import { ReportListInterface } from 'interfaces/Reports/report';
import { EDGE_USER_REPORT_FILTERS, REPORT_FILTERS } from './constants';
import SearchBarT23 from 'components/SearchBarT23/SearchBarT23';
import ButtonAlt from '../../../../../components/v2/ButtonAlt/ButtonAlt';
interface ReportListProps {
  onClickNewReport: () => void;
}

const ReportList = ({ onClickNewReport }: ReportListProps) => {
  const { faxEnable } = useGetFeatureToggle();
  const dispatch = useAppDispatch();
  const { isEdgeAdminView, isEdgeReceptionist, isEdgeUser } = useGetAccountPackageView();
  const [t] = useTranslation();
  const paging = useAppSelector(selectPaging);
  const filters = useAppSelector(selectFilters);
  const search = useAppSelector(selectSearch);

  const [selectedReport, setSelectedReport] = useState<
    { reportId: string; reportName: string; clientRecordId: string } | undefined
  >(undefined);
  const [isShareReportModalOpen, setIsShareReportModalOpen] = useState<boolean>(false);

  const {
    data,
    isLoading: reportDataLoading,
    isFetching
  } = useGetReportsQuery({
    payload: {
      ...paging,
      ...(filters.status !== 'allStatus' && { status: filters.status }),
      ...(search && { search })
    },
    isAdmin: isEdgeAdminView || isEdgeReceptionist
  });

  const reportData = data as GetReportResponse;

  const {
    data: clientEncryptDetails,
    isLoading: isClientEncryptDetailsLoading,
    isFetching: isClientEncryptDetailsFetching
  } = useGetClientEncryptedDataByClientRecordIdQuery(
    { clientRecordId: selectedReport?.clientRecordId || '' },
    { skip: !selectedReport || !selectedReport.clientRecordId }
  );

  const {
    data: reportDetails,
    isLoading: isReportDetailsLoading,
    isFetching: isReportDetailsFetching
  } = useGetReportDetailsQuery(
    { isAdmin: isEdgeAdminView || isEdgeReceptionist, reportId: selectedReport?.reportId || '' },
    { skip: !selectedReport?.reportId }
  );

  const onClickSendViaEFax = (report: ReportListInterface) => {
    setSelectedReport({
      reportId: report._id,
      reportName: report.reportName,
      clientRecordId: report.clientRecord._id
    });
    setIsShareReportModalOpen(true);
  };

  const showPagination =
    !isFetching &&
    !reportDataLoading &&
    reportData &&
    reportData.paging &&
    reportData.paging.totalItems > reportData.paging.perPage;
  const filterType = isEdgeUser ? EDGE_USER_REPORT_FILTERS : REPORT_FILTERS;

  const onChangeStatusFilter = (status: string) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, status }));
  };

  const onChangePage = (page: number) => {
    dispatch(setPaging({ ...paging, page }));
  };

  const handleSearch = (reportName: string) => {
    dispatch(resetPaging());
    dispatch(setSearch(reportName));
  };

  return (
    <>
      <div className={styles.reportListHeader}>
        <div className={styles.filterWrapper} id={'filterReportId'}>
          <div className={styles.filterWrapperTitle}>Filter:</div>
          <Dropdown
            overlay={
              <Menu onClick={({ key }) => onChangeStatusFilter(String(key))}>
                {filterType.map(({ label, value }) => (
                  <Menu.Item key={value}>{label}</Menu.Item>
                ))}
              </Menu>
            }
            trigger={['click']}
          >
            <div className={styles.filterWrapperFilter} onClick={(e) => e.preventDefault()}>
              {filterType.find((option) => option.value === filters.status)?.label}
              <i className={`material-icons ${styles.dropDownIcon}`}>keyboard_arrow_down</i>
            </div>
          </Dropdown>
        </div>
        <div className={styles.searchBarWrapper}>
          <SearchBarT23 placeholder="Report name" value={search} withSearchButton onSearch={handleSearch} />
        </div>
      </div>
      {reportDataLoading || isFetching ? (
        <div className={styles.loadingWrapper}>
          {[...Array(10)].map((obj, i) => (
            <div key={i} className={styles.loadingItem}>
              <Skeleton.Input active className={styles.loadingContent} />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.container}>
          {!reportData?.data?.length ? (
            <div className={styles.noDataWrapper} id={'noReportListingId'}>
              <img className={styles.image} alt={'no report img'} src={REPORTBUILDERIMG} />
              <div className={styles.infoWrapper}>
                <div className={styles.text}>{t('label.no_report_for_any_client')}</div>
                <ButtonAlt size={'medium'} variant="outlined" onClick={onClickNewReport} icon="add_circle_outline">
                  Create New Report
                </ButtonAlt>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.cardContainer} id={'reportListingId'}>
                {reportData?.data.length ? (
                  <>
                    <div className={styles.filterListWrap}>
                      <div className={styles.contentWrapper}>
                        {(isEdgeAdminView || isEdgeReceptionist) && (
                          <div className={columnStyles.authorColumn}>
                            <div className={styles.label}>Created as</div>
                          </div>
                        )}
                        <div className={columnStyles.profileColumn}>
                          <div className={styles.label}>{t('label.client.capitalize')}</div>
                        </div>
                        <div className={columnStyles.reportNameColumn}>
                          <div className={styles.label}>Name</div>
                        </div>
                        <div className={columnStyles.statusColumn}>
                          <div className={styles.label}>Status</div>
                        </div>
                        <div className={columnStyles.createdColumn}>
                          <div className={styles.label}>Created At</div>
                        </div>
                        <div className={columnStyles.lastActivityColumn}>
                          <div className={styles.label}>Last Updated</div>
                        </div>
                        <div className={columnStyles.shareDetailColumn}>
                          <div className={styles.label}>Share</div>
                        </div>
                        <div className={columnStyles.actionsColum} />
                      </div>
                    </div>
                    <ReportItem reportListData={reportData.data} onClickSendViaEFax={onClickSendViaEFax} />
                  </>
                ) : (
                  <div className={styles.noData}>There is currently no reports and letters data</div>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {/* Pagination */}
      {showPagination && (
        <div className={styles.paginationWrapper}>
          <PaginationListV2 paging={reportData.paging} onPageChange={onChangePage} />
        </div>
      )}

      {faxEnable && selectedReport && (
        <ShareReportModal
          type="report"
          reportId={selectedReport.reportId}
          reportName={selectedReport.reportName}
          reportData={reportDetails}
          generalPractitionerId={clientEncryptDetails?.referral?.generalPractitionerId}
          isModalOpen={isShareReportModalOpen}
          closeModal={() => setIsShareReportModalOpen(false)}
          isLoading={
            isClientEncryptDetailsLoading ||
            isClientEncryptDetailsFetching ||
            isReportDetailsLoading ||
            isReportDetailsFetching
          }
        />
      )}
    </>
  );
};

export default ReportList;
