import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { EpisodeInterface } from 'interfaces/Episodes/episodes';

interface EpisodeSliceState {
  currentEpisodeId: string;
  currentEpisode?: EpisodeInterface;
  isOutsideAllEpisodes: boolean;
  isShowAllData: boolean;
  selectedEpisodeDropdownValue: string;
  isFetchingEpisodes: boolean;
}

const initialState: EpisodeSliceState = {
  currentEpisodeId: '',
  currentEpisode: undefined,
  isOutsideAllEpisodes: false,
  isShowAllData: false,
  selectedEpisodeDropdownValue: '',
  isFetchingEpisodes: true
};

export const episodeSlice = createSlice({
  name: 'episodeSlice',
  initialState,
  reducers: {
    setCurrentEpisodeId: (state, action: PayloadAction<string>) => {
      state.currentEpisodeId = action.payload;
      state.isOutsideAllEpisodes = false;
      state.isShowAllData = false;
    },

    setCurrentEpisode: (state, action: PayloadAction<EpisodeInterface | undefined>) => {
      state.currentEpisode = action.payload;
      state.isOutsideAllEpisodes = false;
      state.isShowAllData = false;
    },

    setIsOutsideAllEpisodes: (state, action: PayloadAction<boolean>) => {
      state.isOutsideAllEpisodes = action.payload;
      state.currentEpisodeId = '';
      state.currentEpisode = undefined;
      state.isShowAllData = false;
    },

    setIsShowAllData: (state, action: PayloadAction<boolean>) => {
      state.isShowAllData = action.payload;
      state.isOutsideAllEpisodes = false;
      state.currentEpisodeId = '';
      state.currentEpisode = undefined;
    },

    setSelectedEpisodeDropdownValue: (state, action: PayloadAction<string>) => {
      if (state.currentEpisodeId) {
        state.selectedEpisodeDropdownValue = action.payload;
      }

      if (state.currentEpisode) {
        state.selectedEpisodeDropdownValue = action.payload;
      }

      if (state.isOutsideAllEpisodes) {
        state.selectedEpisodeDropdownValue = action.payload;
      }

      if (state.isShowAllData) {
        state.selectedEpisodeDropdownValue = action.payload;
      }
    },

    setIsFetchingEpisodes: (state, action: PayloadAction<boolean>) => {
      state.isFetchingEpisodes = action.payload;
    }
  }
});

export const selectCurrentEpisodeId = (state: RootState) => state.episodeSlice.currentEpisodeId;
export const selectCurrentEpisode = (state: RootState) => state.episodeSlice.currentEpisode;
export const selectIsOutsideAllEpisodes = (state: RootState) => state.episodeSlice.isOutsideAllEpisodes;
export const selectIsShowAllData = (state: RootState) => state.episodeSlice.isShowAllData;
export const selectSelectedEpisodeDropdownValue = (state: RootState) => state.episodeSlice.selectedEpisodeDropdownValue;
export const selectIsFetchingEpisodes = (state: RootState) => state.episodeSlice.isFetchingEpisodes;

export const {
  setCurrentEpisodeId,
  setCurrentEpisode,
  setIsOutsideAllEpisodes,
  setIsShowAllData,
  setSelectedEpisodeDropdownValue,
  setIsFetchingEpisodes
} = episodeSlice.actions;

export default episodeSlice.reducer;
