import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './AppointmentInstructionNote.module.scss';
import DropdownNote from 'components/v2/DropdownNote/DropdownNote';
import { selectAppointmentData, setInstructionNote } from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { DeliveryType } from 'interfaces/Schedule/AppointmentType';

const AppointmentInstructionNote = () => {
  const dispatch = useAppDispatch();
  const { instructionNote, selectedDeliveryMode, selectedAppointmentType } = useAppSelector(selectAppointmentData);

  const onChangeInstructionNote = (value: string) => {
    dispatch(setInstructionNote(value));
  };

  return (
    <div className={styles.container}>
      <DropdownNote
        classNameConfig={{
          containerClassName: styles.noteWrapper,
          optionClassName: styles.optionClass
        }}
        label={'Add instruction for client'}
        placeholder={'Include detail on location, how to join the session etc...'}
        menuPlacement={'top'}
        icon={'note_alt'}
        value={instructionNote}
        onChangeValue={onChangeInstructionNote}
        disabled={selectedDeliveryMode === DeliveryType.PhoneCall && !selectedAppointmentType?.phoneCallInstructions}
        showTooltips={
          selectedDeliveryMode === DeliveryType.PhoneCall && !selectedAppointmentType?.phoneCallInstructions
        }
        tooltipNote={'Instruction note are not allowed for this delivery mode.'}
        maxLength={320}
        showWordCount
      />
    </div>
  );
};

export default AppointmentInstructionNote;
