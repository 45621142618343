import { Divider } from 'antd';
import { useState } from 'react';

import CheckBox from 'components/CheckBox/CheckBox';
import FlexBox from 'components/FlexBox/FlexBox';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Select from 'components/Select/CommonSelect/Select';
import GroupedMultiSelect from 'components/Select/GroupedMultiSelect/GroupedMultiSelect';
import NestedSelect from 'components/Select/NestedSelect/NestedSelect';
import TextEditor2 from 'components/v2/TextEditor2/TextEditor2';
import {
  ItemColumns,
  selectInvoiceTemplate,
  setInvoiceTemplate
} from 'redux/invoiceTemplate/invoiceTemplateCreateFormSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import styles from './MoreDetailsForm.module.scss';
import { ClinicianDetails } from './helpers/helpers';
import { config } from 'config/config';
import WidgetTextEditor2 from 'components/WidgetTextEditor2/WidgetTextEditor2';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import {
  DUE_DATE_OPTIONS,
  GROUPED_OPTIONS,
  INCLUDE_COLUMN_MENU_OPTIONS,
  INCLUDE_ITEM_MENU_OPTIONS,
  RECIPIENT_OPTIONS
} from './helpers/constants';

const IS_DEVELOPMENT = isDevelopmentENV();

interface MoreDetailsFormProps {
  clinicianDetails?: ClinicianDetails;
}

const MoreDetailsForm = ({ clinicianDetails }: MoreDetailsFormProps) => {
  const { isRegionAU } = useGetFeatureToggle();
  const registrationNumberLabel = config.practiceSettings.registrationNumberLabel;

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const dispatch = useAppDispatch();
  const invoiceTemplate = useAppSelector(selectInvoiceTemplate);
  const {
    practiceFormalName,
    practiceRegistrationNumber,
    practiceFinanceEmail,
    invoiceTitle,
    invoiceNotes,
    medicareClaim,
    medicareClaimant,
    medicareServiceProvider,
    medicareReferral,
    invoiceFooter,
    allowMultipleItems,
    recipient,
    recipientAddress,
    recipientEmail,
    recipientMobileNumber,
    recipientReference,
    recipientRegistrationNumber,
    itemOverviews,
    itemColumns,
    dueAt
  } = invoiceTemplate;

  const getSelectedItemsAndColumns = () => {
    const selectedItemOverviews = INCLUDE_ITEM_MENU_OPTIONS.filter((item) => itemOverviews.includes(item.value));
    const selectedItemColumns = INCLUDE_COLUMN_MENU_OPTIONS.filter((item) => itemColumns.includes(item.value));

    return [...selectedItemOverviews, ...selectedItemColumns];
  };

  const handleSetSelectedItemsAndColumns = (target: any) => {
    if (target.length < 1) {
      setShowErrorMessage(true);
      dispatch(
        setInvoiceTemplate({
          itemOverviews: [],
          itemColumns: [ItemColumns.Total]
        })
      );
      return;
    }
    setShowErrorMessage(new Set(target.map((item: { group: string }) => item.group)).size < 2);
    const groupedData = target.reduce(
      (accumulator: { itemOverviews: any[]; itemColumns: any[] }, currentValue: { group: string; value: any }) => {
        if (currentValue.group === 'itemOverviews') {
          accumulator.itemOverviews.push(currentValue.value);
        } else if (currentValue.group === 'itemColumns') {
          accumulator.itemColumns.push(currentValue.value);
        }

        return accumulator;
      },
      {
        itemOverviews: [],
        itemColumns: []
      }
    );
    dispatch(setInvoiceTemplate(groupedData));
  };

  const practiceDetailsField = [
    {
      name: 'practiceFormalName',
      label: 'Formal Company Name',
      value: practiceFormalName
    },
    {
      name: 'practiceRegistrationNumber',
      label: registrationNumberLabel,
      value: practiceRegistrationNumber
    },
    {
      name: 'practiceFinanceEmail',
      label: 'Finance Email',
      value: practiceFinanceEmail
    }
  ];

  const medicareDetailsField = isRegionAU
    ? [
        {
          name: 'medicareClaimant',
          label: 'Client identity data',
          value: medicareClaimant
        },
        {
          name: 'medicareClaim',
          label: 'MBS claim data',
          value: medicareClaim
        }
      ]
    : [
        {
          name: 'medicareClaimant',
          label: 'Client identity data',
          value: medicareClaimant
        }
      ];

  const medicareMoreDetailsField = isRegionAU
    ? [
        {
          name: 'medicareServiceProvider',
          label: 'Service Provider data',
          value: medicareServiceProvider
        },
        {
          name: 'medicareReferral',
          label: 'Referral data',
          value: medicareReferral
        }
      ]
    : [
        {
          name: 'medicareReferral',
          label: 'Referral data',
          value: medicareReferral
        }
      ];

  return (
    <FlexBox direction="column" spacing={20}>
      <FlexBox direction="row" alignItems="center" spacing={12}>
        {clinicianDetails?.practice?.logo && (
          <img src={clinicianDetails.practice.logo} alt="Practice logo" width={112} height={110} />
        )}
        <FlexBox direction="column">
          <span className={styles.practiceName}>{clinicianDetails?.practice?.name}</span>
          <FlexBox direction="row" justifyContent="space-between" spacing={12}>
            <FlexBox direction="column" spacing={4}>
              <span className={styles.practiceDetails}>{clinicianDetails?.practice?.address}</span>
              <span className={styles.practiceDetails}>{clinicianDetails?.practice?.mobileNumber}</span>
              <span className={styles.practiceDetails}>{clinicianDetails?.practice?.faxNumber}</span>
            </FlexBox>
            <FlexBox direction="column" spacing={4} className={styles.minWidth280}>
              {practiceDetailsField.map((item) => (
                <CheckBox
                  key={`invoice-template-${item.name}`}
                  id={`${item.name}-input`}
                  className={styles.smallCheckBoxLabel}
                  inputClassName={styles.smallCheckBoxInput}
                  label={item.label}
                  value={item.value}
                  onChange={(e) => {
                    dispatch(
                      setInvoiceTemplate({
                        [item.name]: e.target.checked
                      })
                    );
                  }}
                />
              ))}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <Divider className={styles.horizontalDivider} />
      <FlexBox direction="column" spacing={12}>
        <FlexBox direction="row" justifyContent="space-between" spacing={12}>
          <FlexBox direction="column" spacing={4} flex={1}>
            <MaterialInput
              id="invoiceTitle-input"
              name="invoiceTitle"
              label="Invoice title"
              value={invoiceTitle}
              onChange={(e) =>
                dispatch(
                  setInvoiceTemplate({
                    invoiceTitle: e.target.value
                  })
                )
              }
              required
            />
          </FlexBox>
          <FlexBox direction="column" spacing={4} flex={1} />
        </FlexBox>
        <FlexBox direction="row" spacing={40}>
          <FlexBox direction="column" spacing={8} flex={1} className={styles.maxWidth280}>
            <span className={styles.title}>FOR</span>
            <NestedSelect
              options={RECIPIENT_OPTIONS}
              placeholder="Addressed To*"
              value={{
                firstSelectedOption: recipient?.type,
                secondSelectedOption: recipient?.tags
              }}
              onSelected={({ firstSelectedOption, secondSelectedOption }) => {
                if (secondSelectedOption) {
                  dispatch(
                    setInvoiceTemplate({
                      recipient: {
                        type: firstSelectedOption,
                        tags: [secondSelectedOption]
                      }
                    })
                  );
                } else {
                  dispatch(
                    setInvoiceTemplate({
                      recipient: {
                        type: firstSelectedOption
                      }
                    })
                  );
                }
              }}
            />
            <FlexBox direction="row" justifyContent="space-between" spacing={12}>
              <FlexBox direction="column" spacing={4}>
                <CheckBox
                  id="recipientAddress"
                  className={styles.smallCheckBoxLabel}
                  inputClassName={styles.smallCheckBoxInput}
                  value={recipientAddress}
                  label="Address"
                  onChange={(e) => {
                    dispatch(
                      setInvoiceTemplate({
                        recipientAddress: e.target.checked
                      })
                    );
                  }}
                />
                <CheckBox
                  id="recipientEmail"
                  className={styles.smallCheckBoxLabel}
                  inputClassName={styles.smallCheckBoxInput}
                  value={recipientEmail}
                  label="Email"
                  onChange={(e) => {
                    dispatch(
                      setInvoiceTemplate({
                        recipientEmail: e.target.checked
                      })
                    );
                  }}
                />
                <CheckBox
                  id="recipientMobileNumber"
                  className={styles.smallCheckBoxLabel}
                  inputClassName={styles.smallCheckBoxInput}
                  value={recipientMobileNumber}
                  label="Mobile Number"
                  onChange={(e) => {
                    dispatch(
                      setInvoiceTemplate({
                        recipientMobileNumber: e.target.checked
                      })
                    );
                  }}
                />
              </FlexBox>
              <FlexBox direction="column" spacing={4}>
                <CheckBox
                  id="recipientReference"
                  className={styles.smallCheckBoxLabel}
                  inputClassName={styles.smallCheckBoxInput}
                  value={recipientReference}
                  label="Reference"
                  onChange={(e) => {
                    dispatch(
                      setInvoiceTemplate({
                        recipientReference: e.target.checked
                      })
                    );
                  }}
                />
                <CheckBox
                  id="recipientRegistrationNumber"
                  className={styles.smallCheckBoxLabel}
                  inputClassName={styles.smallCheckBoxInput}
                  value={recipientRegistrationNumber}
                  label={registrationNumberLabel}
                  onChange={(e) => {
                    dispatch(
                      setInvoiceTemplate({
                        recipientRegistrationNumber: e.target.checked
                      })
                    );
                  }}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox direction="column" spacing={8} flex={1} className={styles.maxWidth280}>
            <span className={styles.title}>INVOICE DETAILS</span>
            <Select
              isSearchable={false}
              options={DUE_DATE_OPTIONS}
              defaultValue={DUE_DATE_OPTIONS[0]}
              placeholder="Due date*"
              value={dueAt.toString()}
              onChange={(target: any) =>
                dispatch(
                  setInvoiceTemplate({
                    dueAt: Number(target.value)
                  })
                )
              }
              smallCaretDown
              styles={{
                valueContainer: (base: any) => ({
                  ...base,
                  minHeight: '40px',
                  padding: '0 8px 0 0'
                }),
                control: (controlBase: any) => ({
                  ...controlBase,
                  minHeight: '40px',
                  border: 'none',
                  borderBottom: '1px solid #b5b8bd',
                  borderRadius: 0,
                  boxShadow: 'none',
                  cursor: 'pointer'
                }),
                placeholder: (base) => ({ ...base, color: styles.newGreyColor, fontSize: '14px', lineHeight: '21px' })
              }}
            />
            <FlexBox direction="row" justifyContent="space-between" spacing={12}>
              <CheckBox
                id="invoiceId"
                className={styles.smallCheckBoxLabel}
                inputClassName={styles.smallCheckBoxInput}
                disabled
                value
                label="Invoice #"
              />
              <CheckBox
                id="issueDate"
                className={styles.smallCheckBoxLabel}
                inputClassName={styles.smallCheckBoxInput}
                disabled
                value
                label="Issued Date"
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
        {IS_DEVELOPMENT ? (
          <WidgetTextEditor2
            height={300}
            onBlurMethod
            enableMention
            enableMentionCDF
            hasContainerBorder
            value={invoiceNotes}
            onChange={(value) =>
              dispatch(
                setInvoiceTemplate({
                  invoiceNotes: value
                })
              )
            }
          />
        ) : (
          <TextEditor2
            height={300}
            value={invoiceNotes}
            onChange={(value) =>
              dispatch(
                setInvoiceTemplate({
                  invoiceNotes: value
                })
              )
            }
            onBlurMethod
          />
        )}
      </FlexBox>
      <Divider className={styles.horizontalDivider} />
      <FlexBox direction="column" spacing={12}>
        <span className={styles.title}>SERVICE DELIVERY</span>
        <FlexBox direction="row" justifyContent="space-between" spacing={12}>
          <FlexBox direction="column" spacing={4} flex={1}>
            {medicareDetailsField.map((item) => (
              <CheckBox
                key={`invoice-template-${item.name}`}
                id={`${item.name}-input`}
                className={styles.mediumCheckBoxLabel}
                inputClassName={styles.mediumCheckBoxInput}
                label={item.label}
                value={item.value}
                onChange={(e) => {
                  dispatch(
                    setInvoiceTemplate({
                      [item.name]: e.target.checked
                    })
                  );
                }}
              />
            ))}
          </FlexBox>
          <FlexBox direction="column" spacing={4} flex={1}>
            {medicareMoreDetailsField.map((item) => (
              <CheckBox
                key={`invoice-template-${item.name}`}
                id={`${item.name}-input`}
                className={styles.mediumCheckBoxLabel}
                inputClassName={styles.mediumCheckBoxInput}
                label={item.label}
                value={item.value}
                onChange={(e) => {
                  dispatch(
                    setInvoiceTemplate({
                      [item.name]: e.target.checked
                    })
                  );
                }}
              />
            ))}
          </FlexBox>
        </FlexBox>
        <span className={styles.title}>INCLUDE</span>
        <GroupedMultiSelect
          groupedOptions={GROUPED_OPTIONS}
          value={getSelectedItemsAndColumns()}
          onSelect={(target) => {
            handleSetSelectedItemsAndColumns(target);
          }}
          errorMessage={showErrorMessage ? 'Select at least one item' : undefined}
          placeholder="Select items and column to be included in the invoice*"
        />
        <CheckBox
          id="allowMultipleItems"
          className={styles.mediumCheckBoxLabel}
          inputClassName={styles.mediumCheckBoxInput}
          value={!allowMultipleItems}
          label="Support only one service item per invoice."
          onChange={(e) => {
            dispatch(
              setInvoiceTemplate({
                allowMultipleItems: !e.target.checked
              })
            );
          }}
        />
      </FlexBox>
      <Divider className={styles.horizontalDivider} />
      <FlexBox direction="column" spacing={4}>
        <span className={styles.invoiceFooter}>Invoice footer</span>
        {IS_DEVELOPMENT ? (
          <WidgetTextEditor2
            height={300}
            onBlurMethod
            enableMention
            enableMentionCDF
            hasContainerBorder
            value={invoiceFooter}
            onChange={(value) =>
              dispatch(
                setInvoiceTemplate({
                  invoiceFooter: value
                })
              )
            }
          />
        ) : (
          <TextEditor2
            height={300}
            value={invoiceFooter}
            onChange={(value) =>
              dispatch(
                setInvoiceTemplate({
                  invoiceFooter: value
                })
              )
            }
            onBlurMethod
          />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default MoreDetailsForm;
