import SubTab, { SubTabOption } from 'components/v2/SubTab/SubTab';
import { useLocation } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

const PractitionerControlPanelSubTab = ({ practitionerId }: { practitionerId?: string }) => {
  const location = useLocation();
  const { PRACTITIONER } = useRoutesGenerator();
  const {
    medicareRebateFeatureToggle,
    isHelmFeatureToggle,
    isSomeoneHealthFeatureToggle,
    isCaWFeatureToggle,
    isEngagePublicProfileEnabled,
    isRechargeWellnessFeatureToggle,
    isSelectPsychologyFeatureToggle,
    isPractitionerReportEnabled
  } = useGetFeatureToggle();

  const params = decodeURI(location.pathname).match(/.*\/(auth0\|.+)\/(.*)(\/.*)?/) || [];
  const activeOption = params[2];

  const baseHref = `${PRACTITIONER.BASE}/${practitionerId}`;

  const practitionerSubTab: Array<SubTabOption & { isDisplay: boolean }> = [
    { label: 'About', value: 'profile', isDisplay: true, href: `${baseHref}/profile` },
    {
      label: 'Engage Public Profile',
      value: 'engage-profile',
      isDisplay:
        isHelmFeatureToggle ||
        isSomeoneHealthFeatureToggle ||
        isCaWFeatureToggle ||
        isEngagePublicProfileEnabled ||
        isRechargeWellnessFeatureToggle ||
        isSelectPsychologyFeatureToggle,
      href: `${baseHref}/engage-profile`
    },
    { label: 'Working Schedule', value: 'schedule', isDisplay: true, href: `${baseHref}/schedule` },
    {
      value: 'medicare',
      label: 'Medicare',
      isDisplay: medicareRebateFeatureToggle,
      href: `${baseHref}/medicare`
    },
    {
      value: 'services-report',
      label: 'Services Report',
      isDisplay: isPractitionerReportEnabled,
      href: `${baseHref}/services-report`
    }
  ];

  return (
    <SubTab
      options={practitionerSubTab
        .filter((item) => item.isDisplay)
        .map((item) => ({ label: item.label, value: item.value, href: item.href } as SubTabOption))}
      activeOption={activeOption}
    />
  );
};

export default PractitionerControlPanelSubTab;
