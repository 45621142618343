import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import styles from './PatientDetailsClientDataProfiles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as RegularCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as SolidCircle } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { SubTabContext } from 'layouts/AuthLayoutT23/context/SubTabContextProvider';
import { Skeleton } from 'antd';

const PatientDetailsClientDataProfiles = ({
  clientProfiles,
  loading
}: {
  clientProfiles: clientProfilesInterface[];
  loading: boolean;
}) => {
  const { selectedClientProfileId, setSelectedClientProfileId } = useContext(SubTabContext);

  return loading ? (
    <div className={styles.loadingWrapper}>
      <Skeleton.Input active className={styles.loadingName} />
    </div>
  ) : (
    <div className={styles.clientDataProfileContainer}>
      {clientProfiles.map((item) => (
        <div id={item._id} className={styles.link} key={item._id} onClick={() => setSelectedClientProfileId(item._id)}>
          <FontAwesomeIcon
            className={styles.icon}
            icon={selectedClientProfileId === item._id ? SolidCircle : RegularCircle}
          />
          <div className={styles.label}>{item.firstName}</div>
        </div>
      ))}
    </div>
  );
};

export default PatientDetailsClientDataProfiles;
