import { CheckBoxListing, ClientDetails } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import CheckBox from 'components/CheckBox/CheckBox';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import OptionLabel from 'components/OptionLabel/OptionLabel';
import Radio from 'components/Radio/Radio';
import { useEffect, useState } from 'react';
import { getName } from 'utils/general';
import styles from './Onboarding.module.scss';
import CreatableSelect from 'components/Select/CommonSelect/Select';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { ClientRecordType, ProfileRole, ProfileType } from 'interfaces/Clients/clientsRecord';

interface OnboardingProps {
  checkValidation: boolean;
  clientProfile: ClientDetails;
  consentList: CheckBoxListing[];
  onBoardingList: CheckBoxListing[];
  profileType: ProfileType;
  onChangeConsentField: any;
  onChangeOnboardField: any;
  onChangeAssessmentDetail: any;
  handleChangeClientDetail: any;
  assessmentList: CheckBoxListing[];
  recordType: ClientRecordType;
  isTriageOnly?: boolean;
}

const Onboarding = (props: OnboardingProps) => {
  const {
    clientProfile,
    onBoardingList,
    consentList,
    checkValidation,
    profileType,
    assessmentList,
    recordType,
    isTriageOnly
  } = props;
  const { onChangeOnboardField, handleChangeClientDetail, onChangeConsentField, onChangeAssessmentDetail } = props;

  const [clientOnboardField, setClientOnboardField] = useState(clientProfile.onboarding);
  const [clientConsentField, setClientConsentField] = useState(clientProfile.consentForm);
  const [clientAssessmentField, setClientAssessmentField] = useState(clientProfile.assessmentForm);

  const disabledClientOnboardOption = profileType !== 'full';

  useEffect(() => {
    const onboardField = clientProfile.onboarding;
    if (disabledClientOnboardOption) {
      onboardField.onboardingMethod = 'facilitated';
    }
    setClientOnboardField(onboardField);
  }, [clientProfile.onboarding, disabledClientOnboardOption]);

  useEffect(() => {
    setClientConsentField(clientProfile.consentForm);
  }, [clientProfile.consentForm]);

  useEffect(() => {
    setClientAssessmentField(clientProfile.assessmentForm);
  }, [clientProfile.assessmentForm]);

  const handleOnboardingChange = (key: string, val: boolean | string) => {
    const onboardField = {
      ...clientOnboardField,
      [key]: val
    };
    setClientOnboardField(onboardField);
    onChangeOnboardField(onboardField, clientProfile._id);
  };

  const handleSurveyChange = (val: { label: string; value: string }) => {
    const newClientField = {
      ...clientProfile,
      onboarding: {
        ...clientProfile.onboarding,
        onboardingSurveyIds: [...(clientProfile.onboarding.onboardingSurveyIds || []), val.value]
      }
    };

    handleChangeClientDetail(newClientField);
  };

  const handleConsentChange = (key: string, val: boolean | string) => {
    const consentField = {
      ...clientConsentField,
      [key]: val
    };
    setClientConsentField(consentField);
    onChangeConsentField(consentField, clientProfile._id);
  };

  const handleConsentFormChange = (val: { label: string; value: string }) => {
    const newClientField = {
      ...clientProfile,
      consentForm: clientProfile.consentForm && {
        ...clientProfile.consentForm,
        consentFormIds: [...(clientProfile.consentForm.consentFormIds || []), val.value]
      }
    };

    handleChangeClientDetail(newClientField);
  };

  /** Tick or untick the checkbox in onboarding options. */
  const handleAssessmentChange = (key: string, val: boolean | string) => {
    const assessmentField = {
      ...clientAssessmentField,
      [key]: val
    };
    setClientAssessmentField(assessmentField);
    onChangeAssessmentDetail(assessmentField, clientProfile._id);
  };

  /** Binding the selected assessments form id. */
  const onSelectAssessmentForm = (val: { label: string; value: string }) => {
    const newClientField = {
      ...clientProfile,
      assessmentForm: clientProfile.assessmentForm && {
        ...clientProfile.assessmentForm,
        assessmentFormIds: [...(clientProfile.assessmentForm.assessmentFormIds || []), val.value]
      }
    };

    handleChangeClientDetail(newClientField);
  };

  const renderOnBoardingOption = () => {
    return (
      <div
        className={
          clientOnboardField.isCheckOnBoarding ||
          clientConsentField.isCheckOnConsentForm ||
          clientAssessmentField?.isCheckOnAssessmentForm
            ? styles.optionContainer
            : styles.optionContainerHidden
        }
      >
        <div className={styles.subTitle}>
          Do you want to send this request or generate a link?
          <HelpOutLineWithTooltips
            id={'howOnboard'}
            desc={"Decide how you'd like your onboarding survey to be filled"}
          />
        </div>
        <div className={styles.taskOptions}>
          <Radio
            name={`clientOnboardingMethod_${clientProfile._id}`}
            options={[
              {
                value: 'sendToClient',
                disabled: disabledClientOnboardOption,
                label: (
                  <OptionLabel
                    isSelected={clientOnboardField.onboardingMethod === 'sendToClient'}
                    title={`Send to ${clientProfile.firstName}`}
                    highlightTitle
                    disabledTitle={disabledClientOnboardOption}
                  />
                )
              },
              {
                value: 'facilitated',
                label: (
                  <OptionLabel
                    isSelected={clientOnboardField.onboardingMethod === 'facilitated'}
                    title={`Facilitated Onboarding`}
                    highlightTitle
                  />
                )
              }
            ]}
            vertical
            value={disabledClientOnboardOption ? 'facilitated' : clientOnboardField.onboardingMethod}
            onChange={(e) => handleOnboardingChange('onboardingMethod', e.target.value)}
          />
        </div>
      </div>
    );
  };

  const getLabel = (list: CheckBoxListing[], id: string): string => {
    const item = list.find((item: CheckBoxListing) => item.value === id);
    return item?.label ?? '';
  };

  const handleRemoveForm = (Ids: string[], index: number, option: string) => {
    Ids.splice(index, 1);

    const newClientField = {
      ...clientProfile,
      ...(option === 'survey' && {
        onboarding: {
          ...clientProfile.onboarding,
          onboardingSurveyIds: [...Ids]
        }
      }),
      ...(option === 'consent' && {
        consentForm: clientProfile.consentForm && {
          ...clientProfile.consentForm,
          consentFormIds: [...Ids]
        }
      }),
      ...(option === 'assessment' && {
        assessmentForm: clientProfile.assessmentForm && {
          ...clientProfile.assessmentForm,
          assessmentFormIds: [...Ids]
        }
      })
    };

    handleChangeClientDetail(newClientField);
  };

  const showErrorMsg = (isSubmitForm: boolean, formType: string[], isCompulsary: boolean) => {
    if (isSubmitForm && isCompulsary && formType.length === 0) {
      return true;
    }
    return false;
  };

  const shouldShowOnboardingOption = (): boolean => {
    return (
      recordType === ClientRecordType.Adult ||
      recordType === ClientRecordType.Couple ||
      (recordType === ClientRecordType.YoungPerson && clientProfile.role === ProfileRole.YoungPerson)
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Ask&nbsp;<strong>{getName({ ...clientProfile, name: clientProfile.firstName })}</strong>&nbsp;to complete:
      </div>
      <div className={styles.content}>
        <div className={styles.onboarding}>
          {onBoardingList.length > 0 ? (
            <div className={clientOnboardField.isCheckOnBoarding ? styles.wrapper : styles.notSelectWrapper}>
              {/* Onboarding section */}
              <CheckBox
                className={styles.checkBox}
                id={`clientOnboardingCheck_${clientProfile._id}`}
                value={clientOnboardField.isCheckOnBoarding}
                label={<OptionLabel title="Form or Survey" />}
                onChange={(e) => handleOnboardingChange('isCheckOnBoarding', e.target.checked)}
              />
              <div className={styles.selection}>
                {clientOnboardField.isCheckOnBoarding && (
                  <CreatableSelect
                    styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
                    options={onBoardingList.filter(
                      (survey) => !clientProfile.onboarding.onboardingSurveyIds?.includes(survey.value)
                    )}
                    placeholder="Select survey"
                    value={''}
                    closeMenuOnSelect={false}
                    // Value will always be { label: string; value: any } | undefined
                    onChange={(value) => handleSurveyChange(value as { label: string; value: string })}
                    isValidNewOption={() => false}
                  />
                )}
                <ErrorMessage
                  className={styles.error}
                  error={'Please select a survey'}
                  visible={showErrorMsg(
                    checkValidation,
                    clientProfile.onboarding.onboardingSurveyIds || [],
                    clientOnboardField.isCheckOnBoarding
                  )}
                />
              </div>
            </div>
          ) : (
            <div className={styles.noListLabel}>
              No published survey found. Kindly configured in forms tab to enable this feature.
            </div>
          )}
        </div>
        <div
          className={
            clientOnboardField.isCheckOnBoarding &&
            clientProfile.onboarding?.onboardingSurveyIds &&
            clientProfile.onboarding?.onboardingSurveyIds.length > 0
              ? styles.badgeContainer
              : styles.badgeContainerHidden
          }
        >
          <div className={styles.specialisationsContainer} id={'surveyformId'}>
            <div className={styles.specialisationsValueContainer}>
              {clientProfile.onboarding?.onboardingSurveyIds &&
                clientProfile.onboarding?.onboardingSurveyIds.length > 0 &&
                clientProfile.onboarding.onboardingSurveyIds.map((surveyId, index) => (
                  <div className={styles.specialisation} key={surveyId}>
                    {getLabel(onBoardingList, surveyId)}
                    <div
                      className={styles.iconContainer}
                      onClick={() =>
                        handleRemoveForm(clientProfile.onboarding?.onboardingSurveyIds || [], index, 'survey')
                      }
                    >
                      <i className={`material-icons-outlined ${styles.icon}`}>close</i>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* Consent */}
        <div className={styles.consent}>
          {consentList.length > 0 ? (
            <div className={clientConsentField?.isCheckOnConsentForm ? styles.wrapper : styles.notSelectWrapper}>
              <CheckBox
                className={styles.checkBox}
                id={`clientConsentFormCheck_${clientProfile._id}`}
                value={clientConsentField?.isCheckOnConsentForm || false}
                label={<OptionLabel title="Confidentiality agreement or consent form" />}
                onChange={(e) => handleConsentChange('isCheckOnConsentForm', e.target.checked)}
              />
              <div className={styles.selection}>
                {clientConsentField?.isCheckOnConsentForm && (
                  <CreatableSelect
                    styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
                    options={consentList.filter(
                      (consent) => !clientProfile.consentForm.consentFormIds?.includes(consent.value)
                    )}
                    placeholder="Select consent form"
                    value={''}
                    closeMenuOnSelect={false}
                    // Value will always be { label: string; value: any } | undefined
                    onChange={(value) => handleConsentFormChange(value as { label: string; value: string })}
                    isValidNewOption={() => false}
                  />
                )}
                <ErrorMessage
                  className={styles.error}
                  error={'Please select a consent form'}
                  visible={showErrorMsg(
                    checkValidation,
                    clientProfile.consentForm.consentFormIds || [],
                    clientConsentField.isCheckOnConsentForm
                  )}
                />
              </div>
            </div>
          ) : (
            <div className={styles.container}>
              <div className={styles.noListLabel}>
                No published consent form was found. Kindly configure in the forms tab to enable this feature.
              </div>
            </div>
          )}
        </div>
        <div
          className={
            clientConsentField?.isCheckOnConsentForm &&
            clientProfile.consentForm.consentFormIds &&
            clientProfile.consentForm.consentFormIds.length > 0
              ? styles.badgeContainer
              : styles.badgeContainerHidden
          }
        >
          <div className={styles.specialisationsContainer} id={'consentformId'}>
            <div className={styles.specialisationsValueContainer}>
              {clientProfile.consentForm.consentFormIds &&
                clientProfile.consentForm.consentFormIds.length > 0 &&
                clientProfile.consentForm.consentFormIds.map((consentId, index) => (
                  <div className={styles.specialisation} key={consentId}>
                    {getLabel(consentList, consentId)}
                    <div
                      className={styles.iconContainer}
                      onClick={() => handleRemoveForm(clientProfile.consentForm.consentFormIds || [], index, 'consent')}
                    >
                      <i className={`material-icons-outlined ${styles.icon}`}>close</i>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* phychometrics form */}
        <div className={styles.consent}>
          {assessmentList.length > 0 ? (
            <div className={clientAssessmentField?.isCheckOnAssessmentForm ? styles.wrapper : styles.notSelectWrapper}>
              <CheckBox
                className={styles.checkBox}
                id={`clientPhychometricAssessmentCheck_${clientProfile._id}`}
                value={clientAssessmentField?.isCheckOnAssessmentForm || false}
                label={<OptionLabel title="Psychometric assessment" />}
                onChange={(e) => handleAssessmentChange('isCheckOnAssessmentForm', e.target.checked)}
              />
              <div className={styles.selection}>
                {clientAssessmentField?.isCheckOnAssessmentForm && (
                  <CreatableSelect
                    styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
                    options={assessmentList.filter(
                      (assessment) => !clientProfile.assessmentForm.assessmentFormIds?.includes(assessment.value)
                    )}
                    placeholder="Select psychometric"
                    value={''}
                    closeMenuOnSelect={false}
                    // Value will always be { label: string; value: any } | undefined
                    onChange={(value) => onSelectAssessmentForm(value as { label: string; value: string })}
                    isValidNewOption={() => false}
                  />
                )}
                <ErrorMessage
                  className={styles.error}
                  error={'Please select a psychometric'}
                  visible={showErrorMsg(
                    checkValidation,
                    clientProfile.assessmentForm.assessmentFormIds || [],
                    clientAssessmentField.isCheckOnAssessmentForm
                  )}
                />
              </div>
            </div>
          ) : (
            <div className={styles.container}>
              <div className={styles.noListLabel}>No published psychometric assessment was found.</div>
            </div>
          )}
        </div>
        <div
          className={
            clientAssessmentField?.isCheckOnAssessmentForm &&
            clientProfile.assessmentForm.assessmentFormIds &&
            clientProfile.assessmentForm.assessmentFormIds.length > 0
              ? styles.badgeContainer
              : styles.badgeContainerHidden
          }
        >
          <div className={styles.specialisationsContainer} id={'consentformId'}>
            <div className={styles.specialisationsValueContainer}>
              {clientProfile.assessmentForm.assessmentFormIds &&
                clientProfile.assessmentForm.assessmentFormIds.length > 0 &&
                clientProfile.assessmentForm.assessmentFormIds.map((assessmentId, index) => (
                  <div className={styles.specialisation} key={assessmentId}>
                    {getLabel(assessmentList, assessmentId)}
                    <div
                      className={styles.iconContainer}
                      onClick={() =>
                        handleRemoveForm(clientProfile.assessmentForm.assessmentFormIds || [], index, 'assessment')
                      }
                    >
                      <i className={`material-icons-outlined ${styles.icon}`}>close</i>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {!isTriageOnly && shouldShowOnboardingOption() && renderOnBoardingOption()}
      </div>
    </div>
  );
};

export default Onboarding;
