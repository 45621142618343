import { Skeleton } from 'antd';

import { PaymentMethod } from 'pages/Invoices/interface';

import PaymentMethodItem from './components/PaymentMethodItem/PaymentMethodItem';

import styles from './PaymentMethods.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface PaymentMethodsProps {
  paymentMethods: PaymentMethod[];
  selectedPaymentMethods: string[];
  isPaymentMethodsLoading: boolean;
  onAddPaymentMethod: (index: string) => () => void;
  onChangePaymentDetailsClick: () => void;
  onRemovePaymentMethod: (index: string) => () => void;
  isEditingAllowed: boolean;
}

const PaymentMethods = ({
  paymentMethods,
  selectedPaymentMethods,
  isPaymentMethodsLoading,
  onAddPaymentMethod,
  onChangePaymentDetailsClick,
  onRemovePaymentMethod,
  isEditingAllowed
}: PaymentMethodsProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <i className={`material-icons-outlined ${styles.icon}`}>account_balance</i>
        <span className={styles.text}>Payment methods</span>
      </div>
      {isPaymentMethodsLoading ? (
        <Skeleton className={styles.loading} active />
      ) : (
        <>
          {paymentMethods.map((paymentMethod, index) => (
            <PaymentMethodItem
              key={index}
              checked={selectedPaymentMethods.includes(paymentMethod._id)}
              index={index}
              paymentMethod={paymentMethod}
              onAddPaymentMethod={onAddPaymentMethod(paymentMethod._id)}
              onRemovePaymentMethod={onRemovePaymentMethod(paymentMethod._id)}
            />
          ))}
          <div className={styles.btnWrapper}>
            <ButtonAlt
              className={styles.button}
              variant="outlined"
              type="button"
              onClick={onChangePaymentDetailsClick}
              disabled={!isEditingAllowed}
              icon={'settings'}
            >
              <span className={styles.text}>Add / change payment details</span>
            </ButtonAlt>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentMethods;
