import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { CarePathway, CarePathwayStatus } from 'interfaces/CarePathway/CarePathway';

export const initialCarePathwayState: CarePathway = {
  _id: '',
  shortCode: '',
  name: '',
  description: '',
  isNoteVisibleToClient: true,
  status: CarePathwayStatus.Draft,
  note: '',
  clientRecord: undefined,
  leadClinician: undefined,
  clinicians: [],
  template: undefined,
  items: [], // appointment type list on sidebar
  appointments: [] // appointment events
};

export const carePlanEditorSlice = createSlice({
  name: 'carePlanEditorSlice',
  initialState: initialCarePathwayState,
  reducers: {
    setClient: (state, action) => {
      state.clientRecord = action.payload;
    },
    setLeadClinician: (state, action) => {
      state.leadClinician = action.payload;
    },
    setAdditionalClinicians: (state, action) => {
      state.clinicians = action.payload;
    },
    setAppointmentTypeItems: (state, action) => {
      state.items = action.payload;
    },
    deleteAppointmentTypeItem: (state, action) => {
      state.items = state.items.filter((item) => {
        return item._id !== action.payload._id;
      });
    },
    setUpdateAppointmentTypeItem: (state, action) => {
      state.items = state.items.map((item) => ({
        ...item,
        isProcessing: item._id === action.payload._id ? action.payload.isProcessing : false,
        isDropped: item._id === action.payload._id ? action.payload.isDropped : false
      }));
    },
    setAppointmentTypeItemDeliveryType: (state, action) => {
      state.items = state.items.map((item) => ({
        ...item,
        preferredDeliveryType:
          item._id === action.payload._id ? action.payload.deliveryType : item.preferredDeliveryType
      }));
    },
    setEvents: (state, action) => {
      state.appointments = action.payload;
    },
    addEvent: (state, action) => {
      state.appointments = [...state.appointments, action.payload];
    },
    updateEvent: (state, action) => {
      state.appointments = state.appointments.map((event: MbscCalendarEvent) => {
        return event.carePathwayItemId === action.payload.carePathwayItemId ? action.payload : event;
      });
    },
    deleteEventItem: (state, action) => {
      state.appointments = state.appointments.filter((event: MbscCalendarEvent) => {
        return event.carePathwayItemId !== action.payload.original.carePathwayItemId;
      });
    },
    setNewAppointment: (state, action) => {
      state.note = action.payload;
    },
    setTemplate: (state, action) => {
      state.template = action.payload;
    },
    setPackage: (state, action) => {
      if (Object.keys(action.payload).length === 0) {
        const { package: omitted, ...rest } = state;
        return rest;
      }

      return {
        ...state,
        package: action.payload
      };
    },
    setCarePlanData: (state, action) => {
      return { ...state, ...action.payload };
    }
  }
});

export const selectClient = (state: RootState) => state.carePlanEditorSlice.clientRecord;
export const selectLeadClinician = (state: RootState) => state.carePlanEditorSlice.leadClinician;
export const selectAdditionalClinicians = (state: RootState) => state.carePlanEditorSlice.clinicians;
export const selectAppointments = (state: RootState) => state.carePlanEditorSlice.appointments;
export const selectAppointmentTypeItems = (state: RootState) => state.carePlanEditorSlice.items;
export const selectTemplate = (state: RootState) => state.carePlanEditorSlice.template;
export const selectCarePlanEditorSlice = (state: RootState) => state.carePlanEditorSlice;

export const {
  setClient,
  setLeadClinician,
  setAdditionalClinicians,
  setAppointmentTypeItems,
  setUpdateAppointmentTypeItem,
  setTemplate,
  deleteAppointmentTypeItem,
  setAppointmentTypeItemDeliveryType,
  setEvents,
  addEvent,
  updateEvent,
  deleteEventItem,
  setCarePlanData,
  setPackage
} = carePlanEditorSlice.actions;

export default carePlanEditorSlice.reducer;
