import React, { useState } from 'react';
import styles from './SetPassword.module.scss';
import classnames from 'classnames';
import useGetAccountDetailsFromSetPasswordToken from './hooks/useGetAccountDetailsFromSetPasswordToken';
import moment from 'moment';
import { postGetSetPasswordLink } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import Input from 'components/Input/Input';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import shLogo from 'assets/images/someoneHealth/someone-health.png';

const SetPassword = () => {
  const {
    accountDetails: { accountId, token },
    expiredTime
  } = useGetAccountDetailsFromSetPasswordToken();

  const [error, setError] = useState('');
  const [shortCode, setShortCode] = useState(['', '', '', '', '', '']);

  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [showError, setShowError] = useState(false);

  const isTokenExpired = moment(moment.unix(expiredTime)).isBefore(moment());

  const callCheckVerification = async (shortCode: string) => {
    setError('');
    setIsVerifying(true);
    setIsErrored(false);
    setIsVerified(false);

    await new Promise((resolve) => setTimeout(resolve, Math.random() * 1000 + 1000));

    try {
      const response = await postGetSetPasswordLink(token, accountId, { shortCode });

      if (response.statusCode === 200) {
        const { url } = await response.json();

        setIsVerified(true);
        setIsErrored(false);

        window.location.href = url;
      } else {
        setIsVerified(false);
        setIsErrored(true);

        if (response.statusCode === 400) {
          setError('Invalid code.');
        } else if (response.statusCode === 403) {
          setError('This link has broken. Please contact an administrator.');
        } else if (response.statusCode === 500) {
          setError(
            'Something went wrong while verifying your code. Please try again, or contact an administrator if the problem persists.'
          );
        }
      }
    } catch (ex) {
      setIsVerified(false);
      setIsErrored(true);
      console.error(ex);
    } finally {
      setIsVerifying(false);
    }
  };

  const onChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue.length === 6) {
      setShortCode(inputValue.split(''));

      document.getElementById('shortCode-5')?.focus();

      setShowError(true);

      callCheckVerification(inputValue);
    } else {
      const actualInputValue = inputValue.charAt(inputValue.length - 1)?.toUpperCase();

      const newValue = [...shortCode];
      newValue.splice(index, 1, actualInputValue);
      setShortCode(newValue);

      if (actualInputValue) {
        document.getElementById(`shortCode-${index + 1}`)?.focus();
      }

      const shortCodeValue = newValue.join('');

      if (shortCodeValue.length === 6) {
        setShowError(true);

        callCheckVerification(shortCodeValue);
      }
    }
  };

  const onKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && shortCode[index] === '') {
      e.preventDefault();

      document.getElementById(`shortCode-${index - 1}`)?.focus();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleCard}>
        <div>Welcome to Tacklit</div>
        <p>
          You have been invited to join a workspace.
          <br />
          Please begin by entering your access code
        </p>
      </div>
      <div className={styles.card}>
        <img className={styles.logo} src={shLogo} alt="someone health logo" />
        <div className={styles.shText}>someone.health</div>
        {isTokenExpired ? (
          <div className={styles.expired}>This link has expired.</div>
        ) : (
          <>
            <p className={styles.prompt}>Enter the code for this provider workspace</p>
            <div
              className={classnames(
                styles.shortCodeContainer,
                isVerifying && styles.verifying,
                isVerified && styles.verified,
                isErrored && styles.errored
              )}
            >
              {[...Array(6)].map((_, idx) => (
                <Input
                  key={`input-${idx}`}
                  type="tel"
                  id={`shortCode-${idx}`}
                  value={shortCode[idx]}
                  onKeyDown={onKeyDown(idx)}
                  onChange={onChange(idx)}
                  hasError={showError && !!error}
                  disabled={isVerifying}
                  noSpacing
                />
              ))}
            </div>
            <div className={styles.dash}>-</div>
            <ErrorMessage className={styles.error} error={error} visible={showError} />
          </>
        )}
      </div>
    </div>
  );
};

export default SetPassword;
