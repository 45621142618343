import MaterialInput from 'components/MaterialInput/MaterialInput';
import { ClientAddress } from 'interfaces/Clients/clientsRecord';
import styles from './AddressForm.module.scss';
import { AddressSuggestion } from '../AddressSuggestion/AddressSuggestion';
import { useTranslation } from 'react-i18next';

interface AddressFormPros {
  clientAddress: ClientAddress;
  onChangeClientAddress: (address: ClientAddress) => void;
  onPlaceSelected: (address: ClientAddress) => void;
}

const AddressForm = ({ clientAddress, onChangeClientAddress, onPlaceSelected }: AddressFormPros) => {
  const [t] = useTranslation();
  const updateAddress = (key: string, value: string) => {
    onChangeClientAddress({
      ...clientAddress,
      [key]: value
    });
  };

  const handleAddressSelect = (address: ClientAddress) => {
    onChangeClientAddress(address);
    onPlaceSelected(address);
  };

  return (
    <div>
      <h4 className={styles.title}>ADDRESS</h4>
      <div className={styles.form}>
        <div className={styles.row}>
          <div className={styles.addressLine}>
            <div className={styles.addressLineField}>
              <MaterialInput
                placeholder="Address 1"
                id={'line1'}
                label={'Address 1'}
                onChange={(e) => updateAddress('line1', e.target.value)}
                value={clientAddress?.line1}
                autoComplete={'off'}
              />
            </div>
            <div className={styles.addressLineField}>
              <MaterialInput
                placeholder="Address 2"
                id={'line2'}
                label={'Address 2'}
                onChange={(e) => updateAddress('line2', e.target.value)}
                value={clientAddress?.line2}
                autoComplete={'off'}
              />
            </div>
          </div>
          <div className={styles.addressSuggestion}>
            <AddressSuggestion keyword={clientAddress?.line1 || ''} onSelect={handleAddressSelect} />
          </div>
        </div>
        <div className={styles.addressRow}>
          <div className={styles.fieldContainer}>
            <MaterialInput
              placeholder={t('form.client_address_suburb')}
              id={'suburb'}
              label={t('form.client_address_suburb')}
              onChange={(e) => updateAddress('suburb', e.target.value)}
              value={clientAddress?.suburb}
              autoComplete={'off'}
            />
          </div>
          <div className={styles.fieldContainer}>
            <MaterialInput
              placeholder={t('form.client_address_state')}
              id={'state'}
              label={t('form.client_address_state')}
              onChange={(e) => updateAddress('state', e.target.value)}
              value={clientAddress?.state}
              autoComplete={'off'}
            />
          </div>
        </div>
        <div className={styles.addressRow}>
          <div className={styles.fieldContainer}>
            <MaterialInput
              placeholder="Postcode"
              id={'postcode'}
              label={'Postcode'}
              onChange={(e) => updateAddress('postcode', e.target.value)}
              value={clientAddress?.postcode}
              autoComplete={'off'}
            />
          </div>
          <div className={styles.fieldContainer}>
            <MaterialInput
              placeholder="Country"
              id={'country'}
              label={'Country'}
              onChange={(e) => updateAddress('country', e.target.value)}
              value={clientAddress?.country}
              autoComplete={'off'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
