import classNames from 'classnames';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import DeliveryModeSelect from 'components/v2/DeliveryModeSelect/DeliveryModeSelect';
import { useField } from 'formik';
import { AppointmentSlots, TelehealthRoomType } from 'interfaces/Schedule/Appointment';
import { AppointmentType, DELIVERY_TYPE_ICONS, DeliveryType } from 'interfaces/Schedule/AppointmentType';

import styles from './DeliveryMode.module.scss';
import { getDeliveryTypeLabel } from 'utils/appointment';

type DeliveryModeProps = {
  appointmentType?: AppointmentType;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  isReservedAppointment: boolean;
  appointmentId: string;
  telehealthRoomId?: string;
  isAppointmentProcessed?: boolean;
};

const DeliveryMode = ({
  appointmentType,
  isEditing,
  setIsEditing,
  isReservedAppointment,
  appointmentId,
  telehealthRoomId,
  isAppointmentProcessed
}: DeliveryModeProps) => {
  const TELE_UI_URL = process.env.REACT_APP_TELE_UI_URL!;
  const [, { value: deliveryType }] = useField<AppointmentSlots['deliveryType']>('deliveryType');
  const [, { value: faceToFaceLocation }] = useField('faceToFaceLocation');
  const [, { value: videoCallInstructions }] = useField('videoCallInstructions');
  const [, { value: phoneCallInstructions }] = useField('phoneCallInstructions');
  const [, { value: phoneCallDialClientInstructions }] = useField('phoneCallDialClientInstructions');
  const [, { value: otherInstructions }] = useField('otherInstructions');

  if (!deliveryType || deliveryType === DeliveryType.Bespoke) {
    return null;
  }

  const telehealthUrl =
    telehealthRoomId &&
    [DeliveryType.PhoneCall, DeliveryType.VideoCall].includes(deliveryType) &&
    `${TELE_UI_URL}/room/${telehealthRoomId}?type=${
      deliveryType === DeliveryType.PhoneCall
        ? TelehealthRoomType.Voice
        : deliveryType === DeliveryType.VideoCall
        ? TelehealthRoomType.Video
        : ''
    }`;
  const voiceCallUrl = `${TELE_UI_URL}/calls/${appointmentId}`;

  return (
    <div className={classNames(styles.eventInformationContainer, isEditing && styles.flexStart)}>
      <div className={styles.iconContainer}>
        <span className="material-icons-outlined">{DELIVERY_TYPE_ICONS[deliveryType]}</span>
      </div>
      <div className={styles.description}>
        {!isEditing ? (
          <>
            <div className={styles.descriptionText}>{getDeliveryTypeLabel(deliveryType, otherInstructions)}</div>
            {!isReservedAppointment && !isAppointmentProcessed && (
              <ButtonAlt size={'medium'} variant={'text'} onClick={() => setIsEditing(true)}>
                Change delivery mode
              </ButtonAlt>
            )}
          </>
        ) : (
          <DeliveryModeSelect appointmentType={appointmentType} showAdditionalInput />
        )}
      </div>
      <div />
      {!isEditing && (
        <>
          {deliveryType === DeliveryType.FaceToFace && (
            <div className={styles.subInfoContainer}>
              {faceToFaceLocation ||
                (!isAppointmentProcessed && (
                  <ButtonAlt size={'medium'} variant={'text'} onClick={() => setIsEditing(true)}>
                    Add location details
                  </ButtonAlt>
                ))}
            </div>
          )}
          {telehealthUrl && (
            <div className={styles.subInfoContainer}>
              <a href={telehealthUrl} target="_blank" rel="noopener noreferrer">
                <ButtonAlt className={styles.btn} size={'small'} variant={'text'}>
                  {telehealthUrl}
                </ButtonAlt>
              </a>
            </div>
          )}
          {telehealthRoomId && deliveryType === DeliveryType.PhoneCallDialClient && (
            <div className={styles.subInfoContainer}>
              <a href={voiceCallUrl} target="_blank" rel="noopener noreferrer">
                <ButtonAlt className={styles.btn} size={'small'} variant={'text'}>
                  {voiceCallUrl}
                </ButtonAlt>
              </a>
              {phoneCallDialClientInstructions ||
                (!isAppointmentProcessed && (
                  <ButtonAlt size={'medium'} variant={'text'} onClick={() => setIsEditing(true)}>
                    Add phone call details
                  </ButtonAlt>
                ))}
            </div>
          )}
          {!telehealthRoomId &&
            (deliveryType === DeliveryType.VideoCall || deliveryType === DeliveryType.PhoneCall) && (
              <div className={styles.subInfoContainer}>
                {(deliveryType === DeliveryType.VideoCall && videoCallInstructions) ||
                  phoneCallInstructions ||
                  (!isAppointmentProcessed && (
                    <ButtonAlt
                      className={styles.btn}
                      size={'small'}
                      variant={'text'}
                      onClick={() => setIsEditing(true)}
                    >
                      Add telehealth dial in details
                    </ButtonAlt>
                  ))}
              </div>
            )}
          {!telehealthRoomId && deliveryType === DeliveryType.PhoneCallDialClient && (
            <div className={styles.subInfoContainer}>
              {phoneCallDialClientInstructions ||
                (!isAppointmentProcessed && (
                  <ButtonAlt size={'medium'} variant={'text'} onClick={() => setIsEditing(true)}>
                    Add phone call details
                  </ButtonAlt>
                ))}
            </div>
          )}
          {deliveryType === DeliveryType.Other && (
            <div className={styles.subInfoContainer}>
              {otherInstructions?.instructions ||
                (!isAppointmentProcessed && (
                  <ButtonAlt size={'medium'} variant={'text'} onClick={() => setIsEditing(true)}>
                    Add connection details
                  </ButtonAlt>
                ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DeliveryMode;
