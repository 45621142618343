import noData from 'assets/images/noResultFunder.png';
import SearchBar from 'components/SearchBar/SearchBar';
import { ModalVisibilityContext } from 'components/SideDashboardT23/ModalVisibilityContext';
import PaginationDesc from 'components/TableList/PaginationDesc/PaginationDesc';
import PerRecordDropdown from 'components/TableList/PerRecordDropdown/PerRecordDropdown';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { getAddressBookLabelByTab } from 'pages/AddressBook/helpers';
import { useContext } from 'react';
import {
  resetFilters,
  resetPaging,
  resetSearch,
  selectActiveTab,
  selectFilters,
  selectPaging,
  selectSearch,
  selectSorting,
  setPaging,
  setSearch,
  setSorting
} from 'redux/addressBook/addressBookSlice';
import { defaultAddressBookList, useGetAddressBooksQuery } from 'redux/endpoints/clinicianProfileServices/addressBook';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import AddressBookFilters from '../AddressBookFilters/AddressBookFilters';
import styles from './AddressBookListContent.module.scss';
import AddressBookTable from './components/AddressBookTable/AddressBookTable';
import {
  ADDRESS_BOOK_LIST_SORT_INFO,
  ColumnSettingsEnum,
  getAddressBookRecordRequestPayload
} from './components/constants';

const AddressBookListContent = () => {
  const dispatch = useAppDispatch();
  const modalVisibility = useContext(ModalVisibilityContext);
  const { accountId } = useGetAccountId();
  const { isAddressBookFeatureToggle } = useGetFeatureToggle();

  const filters = useAppSelector(selectFilters);
  const search = useAppSelector(selectSearch);
  const sorting = useAppSelector(selectSorting);
  const activeTab = useAppSelector(selectActiveTab);
  const pagination = useAppSelector(selectPaging);
  const { sortBy, sortType } = sorting;

  const {
    data: addressBookData = defaultAddressBookList,
    isLoading: isAddressBookLoading,
    isFetching: isAddressBookFetching
  } = useGetAddressBooksQuery(
    {
      accountId,
      params: getAddressBookRecordRequestPayload({
        paging: pagination,
        sorting,
        filters,
        search
      })
    },
    { skip: !accountId || !isAddressBookFeatureToggle }
  );

  const { addressBooks, paging } = addressBookData;
  const { searchValue } = search;
  const isLoadingOrFetching = isAddressBookLoading || isAddressBookFetching;

  const onSearchHandle = (val: string) => {
    dispatch(resetPaging());
    dispatch(setSearch({ searchValue: val }));
  };

  const handlePageChange = async (page: string, perPage?: string) => {
    dispatch(setPaging({ ...paging, ...(perPage && { perPage }), page }));
  };

  const setSortType = (field: ColumnSettingsEnum) => {
    const newSorting = { sortType: field === sortBy ? -1 * sortType : sortType, sortBy: field };

    localStorage.setItem(ADDRESS_BOOK_LIST_SORT_INFO, JSON.stringify(newSorting));
    dispatch(setSorting(newSorting));
  };

  const handleRecordPerPageValue = (perPageValue: number) => {
    if (paging.perPage !== perPageValue) {
      handlePageChange('1', perPageValue.toString());
    }
  };

  const handleClearAllFilter = () => {
    dispatch(resetFilters());
    dispatch(resetSearch());
    dispatch(resetPaging());
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <AddressBookFilters />
        <div className={styles.searchBarWrapper}>
          <div className={styles.searchBarCard}>
            <SearchBar
              placeholder={`Search ${getAddressBookLabelByTab(activeTab).toLowerCase()} name`}
              value={searchValue}
              withSearchButton
              onSearch={onSearchHandle}
              label={'SEARCH FOR'}
              containerClassName={styles.searchBarContainer}
            />
          </div>
        </div>
      </div>

      <div className={styles.cardContainer}>
        {paging.totalItems > 0 || isLoadingOrFetching ? (
          <AddressBookTable
            isLoading={isLoadingOrFetching}
            addressBooks={addressBooks}
            sorting={sorting}
            setSortType={setSortType}
            clearAllFilter={handleClearAllFilter}
          />
        ) : (
          !isLoadingOrFetching && (
            <div className={styles.noResultContainer}>
              <img className={styles.noResultImg} src={noData} alt={'noData'} />
              <div>
                <div className={styles.noTitle}>No address book found.</div>
                <ButtonAlt
                  icon="add_circle_outline"
                  variant={'outlined'}
                  onClick={() => modalVisibility?.setAddAddressBookModalVisible(true)}
                >
                  New Entry
                </ButtonAlt>
              </div>
            </div>
          )
        )}
      </div>

      {paging && paging.totalItems > 0 && (
        <div className={styles.paginationWrapper}>
          <div className={styles.recordPerPageWrapper}>
            <PerRecordDropdown
              totalItem={paging.totalFilteredItems}
              selectedPerPage={paging.perPage}
              selectDropdownValue={handleRecordPerPageValue}
              disabled={isLoadingOrFetching}
            />
            <div className={styles.label}>records per page</div>
          </div>
          <PaginationDesc
            currentPage={paging.page}
            totalItem={paging.totalFilteredItems}
            perPage={paging.perPage}
            onPageChange={(page) => handlePageChange(page.toString())}
            isLoading={isLoadingOrFetching}
            label={getAddressBookLabelByTab(activeTab, true)}
          />
        </div>
      )}
    </div>
  );
};
export default AddressBookListContent;
