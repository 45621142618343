import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { MedicareItem, MedicareItemFormat, MedicareItemMode, MedicareItemDuration } from '../interfaces';

export const OCCUPATIONAL_THERAPIST_ITEMS: MedicareItem[] = [
  {
    mbsCode: '80125',
    description:
      'Initial individual face to face (consultation rooms) session by an occupational therapist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '80130',
    description:
      'Initial individual face to face (call-out) session by an occupational therapist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 85,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '80126',
    description:
      'Initial individual telehealth (geographic eligibiility applies) session by an occupational therapist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 81.65,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '91172',
    description:
      'Initial individual telehealth (video call) session by an occupational therapist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '91185',
    description:
      'Initial individual telehealth (phone call) session by an occupational therapist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '80135',
    description:
      'Initial individual face to face (consultation rooms) session by an occupational therapist, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '80140',
    description:
      'Initial individual face to face (call-out) session by an occupational therapist, lasting at least 50 minutes.',
    benefit: 109.75,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '80136',
    description:
      'Initial individual telehealth (geographic eligibiility applies) session by an occupational therapist, lasting at least 50 minutes.',
    benefit: 81.9,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '91173',
    description:
      'Initial individual telehealth (video call) session by an occupational therapist, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '91186',
    description:
      'Initial individual telehealth (phone call) session by an occupational therapist, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '93356',
    description:
      'Additional individual face to face (consultation rooms) session by an occupational therapist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 55.1,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '93357',
    description:
      'Additional individual telehealth (video call) session by an occupational therapist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 55.1,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '93358',
    description:
      'Additional individual telehealth (phone call) session by an occupational therapist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 55.1,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '93359',
    description:
      'Additional individual face to face (consultation rooms) session by an occupational therapist, lasting at least 50 minutes.',
    benefit: 77.8,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '93360',
    description:
      'Additional individual telehealth (video call) session by an occupational therapist, lasting at least 50 minutes.',
    benefit: 77.8,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '93361',
    description:
      'Additional individual telehealth (phone call) session by an occupational therapist, lasting at least 50 minutes.',
    benefit: 77.8,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '80145',
    description: 'Group face to face session by an occupational therapist, lasting at least 60 minutes.',
    benefit: 21.6,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: '80146',
    description:
      'Group telehealth (geographic eligibility applies) session by an occupational therapist, lasting at least 60 minutes.',
    benefit: 21.6,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: '10958',
    description:
      "Occupational therapy health service provided by an eligible occupational therapist, lasting at least 20 minutes, to a person who has a chronic condition and complex care needs being managed by a medical practitioner (including a general practitioner, but not a specialist or consultant physician) under a shared care plan or under both a GP Management Plan and Team Care Arrangements or, if the person is a resident of an aged care facility, the person's medical practitioner has contributed to a multidisciplinary care plan.",
    benefit: 60.35,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan20
  },
  {
    mbsCode: '82368',
    description:
      'Eating disorder psychological treatment service provided to an eligible patient in consulting rooms by an eligible occupational therapist, lasting at least 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '82370',
    description:
      'Eating disorder psychological treatment service provided to an eligible patient at a place other than consulting rooms by an eligible occupational therapist, lasting at least 20 minutes but less than 50 minutes.',
    benefit: 85,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '82371',
    description:
      'Eating disorder psychological treatment service provided to an eligible patient in consulting rooms by an eligible occupational therapist, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '82373',
    description:
      'Eating disorder psychological treatment service provided to an eligible patient at a place other than consulting rooms by an eligible occupational therapist, lasting at least 50 minutes.',
    benefit: 109.75,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '82374',
    description:
      'Eating disorder psychological treatment service provided to an eligible patient in person as part of a group of 6 to 10 patients by an eligible occupational therapist, lasting at least 60 minutes.',
    benefit: 21.6,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: '82375',
    description:
      'Eating disorder psychological treatment service provided to an eligible patient by video conference as part of a group of 6 to 10 patients by an eligible occupational therapist, lasting at least 60 minutes.',
    benefit: 21.6,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: '93092',
    description:
      'Eating disorder psychological treatment service provided by telehealth attendance to an eligible patient by an eligible occupational therapist, lasting at least 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '93095',
    description:
      'Eating disorder psychological treatment service provided by telehealth attendance to an eligible patient by an eligible occupational therapist, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '93126',
    description:
      'Eating disorder psychological treatment service provided by phone attendance to an eligible patient by an eligible occupational therapist, lasting at least 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '93129',
    description:
      'Eating disorder psychological treatment service provided by phone attendance to an eligible patient by an eligible occupational therapist, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.OccupationalTherapists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  }
];
