import { useEffect, useState } from 'react';

import SelectClientModal from './components/SelectClientModal/SelectClientModal';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

import SelectGroupOrClientModalBox from '../SelectGroupOrClientModalBox/SelectGroupOrClientModalBox';

interface SelectClientProps {
  title: string;
  selectedClientRecord?: clientRecordsInterface;
  selectedClientRecordId?: string;
  selectedGroup?: GroupsFromAPI;
  onSelectClientRecord: (selectedClientRecord?: clientRecordsInterface) => void;
  showModalIfUnselected?: boolean;
  onSelectGroups: (val?: GroupsFromAPI) => void;
  participationType: ParticipantType;
  onChangeParticipationType: (val: ParticipantType) => void;
}

const SelectClientList = ({
  title,
  selectedClientRecord,
  selectedClientRecordId,
  selectedGroup,
  showModalIfUnselected,
  onSelectClientRecord,
  onSelectGroups,
  participationType,
  onChangeParticipationType
}: SelectClientProps) => {
  const [isSelectClientModalVisible, setIsSelectClientModalVisible] = useState(false);

  useEffect(() => {
    if (showModalIfUnselected) {
      if (!selectedClientRecordId && !selectedGroup) {
        setIsSelectClientModalVisible(true);
      } else {
        setIsSelectClientModalVisible(false);
      }
    }
  }, [selectedClientRecordId, selectedGroup, showModalIfUnselected]);

  const onClickSelectClient = () => {
    setIsSelectClientModalVisible(true);
  };

  const handleSelectClient = (clientRecord: clientRecordsInterface) => {
    setIsSelectClientModalVisible(false);
    onSelectClientRecord(clientRecord);
    onSelectGroups(undefined);
  };

  const handleSelectGroup = (selectedGroup: GroupsFromAPI) => {
    setIsSelectClientModalVisible(false);
    onSelectGroups(selectedGroup);
    onSelectClientRecord(undefined);
  };

  return (
    <div id={'selectClientModal'}>
      <SelectClientModal
        title={title}
        visible={isSelectClientModalVisible}
        onCloseModal={() => {
          setIsSelectClientModalVisible(false);
        }}
        onSelectClientRecord={handleSelectClient}
        onSelectGroup={handleSelectGroup}
        selectedClientId={selectedClientRecord?._id || ''}
        selectedGroupId={selectedGroup?._id}
        participationType={participationType}
        onChangeParticipationType={onChangeParticipationType}
      />
      <SelectGroupOrClientModalBox
        clientRecord={selectedClientRecord}
        selectedGroup={selectedGroup}
        participationType={participationType}
        onClickSelectUser={onClickSelectClient}
      />
    </div>
  );
};

export default SelectClientList;
