import ToggleSwitchV2, { ToggleList } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import styles from './NotificationSettingItem.module.scss';
import {
  NotificationPreference,
  NotificationSettingsInterface
} from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';
import { useMemo } from 'react';

const LABEL_MAPPINGS: Record<keyof NotificationSettingsInterface | string, string> = {
  invoice: 'Invoice',
  reportsAndLetters: 'Report and Letter',
  accountInvite: 'Account Invite',
  forms: 'Forms (Surveys, Psychometrics, Consent Forms)',
  appointmentConfirmation: 'Appointment Confirmation',
  appointmentReminder: 'Appointment Reminder',

  practitionerAppointmentConfirmation: 'Appointment Confirmation',
  practitionerAppointmentCancellation: 'Appointment Cancellation',
  practitionerGroupAppointment: 'Groups/MDT Session Communications'
};

const NOTIFICATION_PREFERENCE_LABELS = {
  [NotificationPreference.Email]: 'EMAIL',
  [NotificationPreference.SMS]: 'SMS',
  [NotificationPreference.All]: 'BOTH',
  [NotificationPreference.None]: 'OFF',

  [NotificationPreference.AppointmentOwner]: 'ONLY APPT OWNER',
  [NotificationPreference.AllStaff]: 'ALL GROUP/MDT STAFF'
};

const NOTIFICATION_SETTINGS_TOGGLE_LISTS: Record<
  keyof NotificationSettingsInterface | 'default',
  { id: NotificationPreference; label: string }[]
> = {
  invoice: [{ id: NotificationPreference.Email, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.Email] }],
  reportsAndLetters: [
    { id: NotificationPreference.Email, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.Email] }
  ],
  accountInvite: [
    { id: NotificationPreference.Email, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.Email] },
    { id: NotificationPreference.SMS, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.SMS] },
    { id: NotificationPreference.All, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.All] }
  ],
  forms: [
    { id: NotificationPreference.Email, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.Email] },
    { id: NotificationPreference.SMS, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.SMS] },
    { id: NotificationPreference.All, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.All] }
  ],
  appointmentConfirmation: [
    { id: NotificationPreference.Email, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.Email] },
    { id: NotificationPreference.SMS, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.SMS] },
    { id: NotificationPreference.All, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.All] },
    { id: NotificationPreference.None, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.None] }
  ],
  appointmentReminder: [
    { id: NotificationPreference.Email, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.Email] },
    { id: NotificationPreference.SMS, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.SMS] },
    { id: NotificationPreference.All, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.All] },
    { id: NotificationPreference.None, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.None] }
  ],

  practitionerAppointmentConfirmation: [
    { id: NotificationPreference.Email, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.Email] },
    { id: NotificationPreference.None, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.None] }
  ],
  practitionerAppointmentCancellation: [
    { id: NotificationPreference.Email, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.Email] },
    { id: NotificationPreference.None, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.None] }
  ],
  practitionerGroupAppointment: [
    {
      id: NotificationPreference.AppointmentOwner,
      label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.AppointmentOwner]
    },
    { id: NotificationPreference.AllStaff, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.AllStaff] }
  ],

  default: [
    { id: NotificationPreference.Email, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.Email] },
    { id: NotificationPreference.SMS, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.SMS] },
    { id: NotificationPreference.All, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.All] },
    { id: NotificationPreference.None, label: NOTIFICATION_PREFERENCE_LABELS[NotificationPreference.None] }
  ]
};

const getToggleList = (
  settingKey: keyof NotificationSettingsInterface,
  currentSetting: NotificationPreference
): ToggleList[] =>
  (NOTIFICATION_SETTINGS_TOGGLE_LISTS[settingKey] || NOTIFICATION_SETTINGS_TOGGLE_LISTS.default)
    .map(({ id, label }) => {
      return {
        id: `${settingKey} ${id}`,
        label: label,
        isActive: currentSetting === id
      };
    })
    .filter((toggle) => toggle !== undefined) as ToggleList[];

interface NotificationSettingItemProps {
  notificationSettingKey: string;
  preference: NotificationPreference;
  onChangePreference: (value: ToggleList) => void;
}

const NotificationSettingItem = ({
  notificationSettingKey,
  preference,
  onChangePreference
}: NotificationSettingItemProps) => {
  const toggleList = useMemo(
    () => getToggleList(notificationSettingKey, preference),
    [notificationSettingKey, preference]
  );

  return (
    <div className={styles.container}>
      <div className={styles.categoryLabel}>
        {preference !== 'none' ? <div className={styles.activeDot} /> : <div className={styles.inactiveDot} />}
        {LABEL_MAPPINGS[notificationSettingKey]}
      </div>
      <ToggleSwitchV2
        id={notificationSettingKey}
        labelClassName={styles.toggleLabel}
        activeLabelClassName={styles.toggleLabel}
        toggleList={toggleList}
        onChangeStatus={onChangePreference}
      />
    </div>
  );
};

export default NotificationSettingItem;
