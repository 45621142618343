import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';

import { AvailabilityTimeSlot, SelectedAppointmentSlotProps } from 'interfaces/Schedule/AppointmentTypeAvailabilities';

import styles from './HorizontalTimeSlotCard.module.scss';
import { Skeleton } from 'antd';
import { useAppSelector } from 'redux/hooks';
import {
  appointmentViewFilter,
  selectedAppointmentView
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import HorizontalTimeCard from './components/HorizontalTimeCard/HorizontalTimeCard';

interface DaySlotsInterface {
  date: string;
  isAvailable: boolean;
  timeSlots: AvailabilityTimeSlot[];
}

type HorizontalTimeSlotCardProps = {
  isAvailabilitiesViewTypeSimple: boolean;
  selectedAppointmentSlot?: SelectedAppointmentSlotProps;
  onSelectSlot: (slot: SelectedAppointmentSlotProps) => void;
  data: DaySlotsInterface;
  isLoading?: boolean;
  showStartTimeOnly?: boolean;
};

const HorizontalTimeSlotCard = ({
  isAvailabilitiesViewTypeSimple,
  selectedAppointmentSlot,
  onSelectSlot,
  data,
  isLoading,
  showStartTimeOnly
}: HorizontalTimeSlotCardProps) => {
  const timeSlotRef = useRef<HTMLDivElement>(null);
  const selectedAppointmentTypeView = useAppSelector(selectedAppointmentView);
  const selectedAppointmentViewFilter = useAppSelector(appointmentViewFilter);

  const [arrowBtn, setArrowBtn] = useState({
    left: false,
    right: false
  });

  useEffect(() => {
    scrollSlider();
  }, [selectedAppointmentViewFilter, selectedAppointmentTypeView]);

  const checkWholeDayAvailability = useMemo(
    () => data.timeSlots.length > 0 && data.timeSlots.some((slotObj) => slotObj.isAvailable),
    [data.timeSlots]
  );

  const onScrollSlider = (position: number) => {
    if (timeSlotRef.current) {
      timeSlotRef.current.scrollLeft += position;
    }
  };

  const scrollSlider = () => {
    if (timeSlotRef.current) {
      const endOfRightPos =
        timeSlotRef.current.scrollLeft + timeSlotRef.current.clientWidth === timeSlotRef.current.scrollWidth;

      setArrowBtn({
        left: timeSlotRef.current.scrollLeft > 0,
        right: !endOfRightPos
      });
    }
  };

  return (
    <div ref={timeSlotRef} onScroll={() => scrollSlider()} className={styles.container}>
      <div>
        {isLoading ? (
          <div className={styles.timeSlotContainer}>
            {[...Array(5)].map((_obj, i) => (
              <div key={i} className={styles.loadingWrapper}>
                <Skeleton.Input active className={styles.loading} />
              </div>
            ))}
          </div>
        ) : !checkWholeDayAvailability ? (
          <div className={styles.noAvailableSlot}>
            No slots available on&nbsp;<strong>{moment(data.date).format('DD MMM YYYY')}</strong>
          </div>
        ) : data.timeSlots.length > 0 ? (
          <div className={styles.timeSlotBox}>
            {arrowBtn.left && (
              <div onClick={() => onScrollSlider(-30)} className={classNames(styles.arrowBtn, styles.leftArrowBtn)}>
                <i className={`material-icons ${styles.arrowIcon}`}>arrow_back</i>
              </div>
            )}
            <HorizontalTimeCard
              isAvailabilitiesViewTypeSimple={isAvailabilitiesViewTypeSimple}
              showStartTimeOnly={showStartTimeOnly}
              selectedAppointmentSlot={selectedAppointmentSlot}
              timeSlots={data.timeSlots}
              onSelectSlot={onSelectSlot}
            />
            {arrowBtn.right && data.timeSlots.length > 5 && (
              <div onClick={() => onScrollSlider(30)} className={classNames(styles.arrowBtn, styles.rightArrowBtn)}>
                <i className={`material-icons ${styles.arrowIcon}`}>arrow_forward</i>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.noAvailability}>No availability</div>
        )}
      </div>
    </div>
  );
};

export default HorizontalTimeSlotCard;
