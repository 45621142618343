import classnames from 'classnames';
import { ItemColumns, ItemOverview } from 'redux/invoiceTemplate/invoiceTemplateCreateFormSlice';
import { ITEM_COLUMNS, InvoiceTemplatesOverViewProps, ACTION_ORDER } from '../interface';
import styles from './InvoiceTemplatesOverView.module.scss';

const InvoiceTemplatesOverView = ({
  itemOverviews,
  itemColumns,
  allowMultipleItems
}: InvoiceTemplatesOverViewProps) => {
  const itemColumnsSort = [...itemColumns].sort((a, b) => ACTION_ORDER[a] - ACTION_ORDER[b]);
  const hasId = itemOverviews.includes(ItemOverview.Id);
  const hasName = itemOverviews.includes(ItemOverview.Name);
  const hasDuration = itemOverviews.includes(ItemOverview.Duration);
  const hasDate = itemOverviews.includes(ItemOverview.Date);
  const hasDelivery = itemOverviews.includes(ItemOverview.Owner);
  const hasStatus = itemOverviews.includes(ItemOverview.Status);
  const overviewList = allowMultipleItems ? [1, 2] : [1];
  return (
    <div className={styles.container}>
      <ul className={styles.overviewList}>
        <li>#</li>
        <li>OVERVIEW</li>
        {itemColumnsSort?.map((item: ItemColumns, i: number) => {
          return <li key={i}>{ITEM_COLUMNS[item].name}</li>;
        })}
      </ul>
      {overviewList.map((item: number) => {
        return (
          <ul key={item} className={classnames(styles.overviewList, styles.overviewListItem)}>
            <li>{item}</li>
            <li>
              {`${hasName ? '{{Appointment Name}}' : ''} ${hasName && hasId ? '-' : ''} ${hasId ? '{{ID}}' : ''}`}
              <p>{`${hasDuration ? '{{Appointment Duration}}' : ''} ${hasDuration && hasDate ? 'on' : ''} ${
                hasDate ? '{{Appointment Date}}' : ''
              } ${hasDelivery ? 'by {{Service Delivered By}}' : ''}`}</p>
              {hasStatus && (
                <p className={styles.tags}>
                  <span>{`{{Appointment Tag}}`}</span>
                </p>
              )}
            </li>
            {itemColumnsSort?.map((item: ItemColumns, i: number) => {
              return <li key={i}>{ITEM_COLUMNS[item].value}</li>;
            })}
          </ul>
        );
      })}
      <div className={styles.discount}>
        <div className={styles.discountText}>Discount</div>
        <div>{`{{Discount}}`}</div>
      </div>
      <div className={styles.dashed} />
      <ul className={styles.totalContainer}>
        <li>TOTAL</li>
        {itemColumnsSort?.map((item: ItemColumns, i: number) => {
          return <li key={i}>{ITEM_COLUMNS[item].valueTotal}</li>;
        })}
      </ul>
    </div>
  );
};

export default InvoiceTemplatesOverView;
