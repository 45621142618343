import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { security } from 'utils/security';

export enum CISTagTypes {
  ClinicalAssessmentResponses = 'Clinical Assessment Responses',
  SurveyResponses = 'Survey Responses',
  Notes = 'Notes',
  GetAssessmentById = 'Get Assessment by id',
  ClinicalAssessmentDetailsResponses = 'Clinical Assessment Details Responses',
  MinifiedClientData = 'Minified Client Data'
}

export const checkInServiceApiSlice = createApi({
  reducerPath: 'checkInServices',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CHECK_IN_SERVICE_URL,
    prepareHeaders: async (headers) => {
      const token = await security.getAccessTokenSilently();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: Object.values(CISTagTypes),
  endpoints: () => ({})
});
