import { useMemo } from 'react';
import styles from './PractitionerCardItem.module.scss';
import columnStyles from '../../PractitionerRowCard.module.scss';
import { getUserRoles, PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import PractitionerSchedule from './components/PractitionerSchedule/PractitionerSchedule';
import PractitionerCaseLoad from './components/PractitionerCaseLoad/PractitionerCaseLoad';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import classNames from 'classnames';

interface PatientProps {
  id: number;
  PractitionerData: PractitionersDetailsInterface;
  onSelectPractitioner: () => void;
}

const PractitionerCardItem = ({ id, PractitionerData, onSelectPractitioner }: PatientProps) => {
  const handleCopyContent = async (e: React.MouseEvent<HTMLButtonElement>, value: string) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(value);
      notification.success({
        message: 'Copied!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    } catch (err) {
      notification.error({
        message: 'Failed to copy!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const [t] = useTranslation();

  const USER_ROLES = useMemo(() => getUserRoles(t), [t]);

  return (
    <div className={classNames(styles.container, styles.clickable)} onClick={onSelectPractitioner}>
      <div className={columnStyles.profileSection}>
        <ClinicianAvatar avatarUrl={PractitionerData.avatar} name={PractitionerData.name} />
      </div>
      <div className={columnStyles.roleSection}>
        <div className={styles.value}>{USER_ROLES[PractitionerData.accessRight]}</div>
      </div>
      <div className={columnStyles.mobileSection}>
        <div className={styles.contentValueWrapper}>
          <div className={styles.value}>{PractitionerData.mobileNumber}</div>
          <ButtonAlt
            className={styles.copyBtn}
            fab
            onClick={(e) => handleCopyContent(e, PractitionerData.mobileNumber)}
            size={'small'}
            variant={'text'}
            icon={'content_copy'}
          />
        </div>
      </div>
      <div className={columnStyles.emailSection}>
        <div className={styles.contentValueWrapper}>
          <div className={styles.value}>{PractitionerData.email}</div>
          <ButtonAlt
            className={styles.copyBtn}
            fab
            onClick={(e) => handleCopyContent(e, PractitionerData.email)}
            size={'small'}
            variant={'text'}
            icon={'content_copy'}
          />
        </div>
      </div>
      <div className={columnStyles.scheduleSection}>
        {PractitionerData.workingSchedule ? (
          <PractitionerSchedule id={id} workingSchedule={PractitionerData.workingSchedule} />
        ) : (
          <div>Working schedule not set.</div>
        )}
      </div>
      <div className={columnStyles.caseLoadSection}>
        <PractitionerCaseLoad caseLoadData={PractitionerData.caseLoad} />
      </div>
    </div>
  );
};

export default PractitionerCardItem;
