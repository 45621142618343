import { notification } from 'antd';
import Button from 'components/Button/Button';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';
import FormikMaterialPhoneInput from 'components/MaterialPhoneInput/FormikMaterialPhoneInput';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { Formik, FormikHelpers } from 'formik';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import AvatarEditor from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/AvatarEditor/AvatarEditor';
import ConfirmUpdateContactDetailsModal from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/ProfileAboutYou/components/ConfirmUpdateContactDetailsModal/ConfirmUpdateContactDetailsModal';
import { useState } from 'react';
import { updateClinicianContactDetails, uploadAvatar } from 'utils/http/clinician';
import { updateClinicianProfile } from 'utils/http/ClinicianProfileService/Profile/profile';
import { getEmailUsed } from 'utils/http/verification';

import MentorHeader from '../MentorHeader/MentorHeader';
import { PRONOUN_OPTIONS, profileSchema } from './constants';
import styles from './MentorAboutYou.module.scss';
import { security } from 'utils/security';

interface MentorAboutYouProps {
  profile: ProfileInterface;
  onUpdateProfile: (newProfile: ProfileInterface) => void;
}

const MentorAboutYou = ({ profile, onUpdateProfile }: MentorAboutYouProps) => {
  const [isAvatarEditorVisible, setIsAvatarEditorVisible] = useState(false);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [isUpdateContactDetailsModalVisible, setIsUpdateContactDetailsModalVisible] = useState(false);

  const [isEmailAddressInputDisabled, setIsEmailAddressInputDisabled] = useState(true);
  const [isMobileNumberInputDisabled, setIsMobileNumberInputDisabled] = useState(true);

  const handleInitialUploadAvatar = async (
    { file, onSuccess }: any,
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue']
  ) => {
    setUploadingAvatar(true);
    const data = new FormData();
    data.append('file', file);
    const res = (await uploadAvatar(data)) as {
      [key: string]: any;
      [key: number]: any;
    };

    const body = await res.json();
    setFieldValue('avatar', body.data.url);
    setUploadingAvatar(false);
    onSuccess(res);
  };

  const beforeInitialUploadAvatar = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: `You can only upload JPG or PNG` });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({ message: `Image cannot be bigger than 2MB!` });
    }
    return isJpgOrPng && isLt2M;
  };

  const handleAvatarEditorOpen = () => {
    setIsAvatarEditorVisible(true);
  };

  const handleAvatarEditorClose = () => {
    setIsAvatarEditorVisible(false);
  };

  const handleVerifyError = (setFieldError: FormikHelpers<ProfileInterface>['setFieldError']) => {
    handleUpdateContactDetailsModalClose();

    notification.error({ message: 'Invalid phone number' });
    setFieldError('mobileNumber', 'Invalid phone number');
    setSaveButtonStatus('');
  };

  const handleAvatarEditorCancel = (
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue'],
    originalAvatar?: string
  ) => {
    setFieldValue('avatar', originalAvatar);
    handleAvatarEditorClose();
  };

  const handleUpdateContactDetailsModalClose = () => {
    setIsUpdateContactDetailsModalVisible(false);
    setSaveButtonStatus('');
  };

  const handleUpdateProfile = async (values: ProfileInterface) => {
    try {
      const token = await security.getAccessTokenSilently();

      await updateClinicianProfile(token, values);

      if (values.email !== profile.email || values.mobileNumber !== profile.mobileNumber) {
        await updateClinicianContactDetails(token, { email: values.email, mobileNumber: values.mobileNumber });
      }

      handleUpdateContactDetailsModalClose();

      onUpdateProfile(values);

      setIsEmailAddressInputDisabled(true);
      setIsMobileNumberInputDisabled(true);
      setSaveButtonStatus('finished');

      notification.success({
        message: 'Profile updated',
        closeIcon: <span className="success">OK</span>
      });

      setTimeout(() => setSaveButtonStatus(''), 2000);
    } catch (ex) {
      console.error(ex);

      setSaveButtonStatus('');

      notification.error({ message: 'Something went wrong while trying to update your practices' });
    }
  };

  const handleSubmit = async (values: ProfileInterface, { setFieldError }: FormikHelpers<ProfileInterface>) => {
    setSaveButtonStatus('active');

    if (values.email !== profile.email || values.mobileNumber !== profile.mobileNumber) {
      try {
        if (values.email !== profile.email) {
          const callGetClinicianEmailUsed = await getEmailUsed(values.email);

          const { used } = await callGetClinicianEmailUsed.json();

          if (used) {
            notification.error({ message: 'Email already in use' });
            setFieldError('email', 'Email already in use');
            setSaveButtonStatus('');
            return;
          }
        }
      } catch (ex) {
        notification.error({ message: 'Invalid email format' });
        setFieldError('email', 'Invalid email format');
        setSaveButtonStatus('');
        return;
      }

      setIsUpdateContactDetailsModalVisible(true);
    } else {
      const checkTitleValueIsNone = values.title !== 'None' ? values.title : '';
      const massageUpdateData = {
        ...values,
        title: values.title === 'Other' ? values.titleValue : checkTitleValueIsNone,
        titleValue: values.title !== 'Other' ? checkTitleValueIsNone : values.titleValue
      };
      await handleUpdateProfile(massageUpdateData);
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <MentorHeader profile={profile} />
        <div>
          <div className={styles.title}>About You</div>
        </div>
      </div>

      {/* Content */}
      <Formik initialValues={profile} validationSchema={profileSchema} onSubmit={handleSubmit}>
        {({ values, setFieldError, setFieldValue, submitForm }) => (
          <>
            <AvatarEditor
              borderRadius={1000}
              filenameSuffix="profile-avatar"
              imgSrc={values.avatar}
              placeholder="Add your profile picture"
              title="Upload profile picture"
              visible={isAvatarEditorVisible}
              isAvatarUploading={uploadingAvatar}
              onBeforeUpload={beforeInitialUploadAvatar}
              onCancel={() => handleAvatarEditorCancel(setFieldValue, values.avatar)}
              onClose={handleAvatarEditorClose}
              onUploadAvatar={(value) => handleInitialUploadAvatar(value, setFieldValue)}
            />
            <ConfirmUpdateContactDetailsModal
              mobileNumber={values.mobileNumber}
              visible={isUpdateContactDetailsModalVisible}
              onCancel={handleUpdateContactDetailsModalClose}
              onSubmit={() => handleUpdateProfile(values)}
              onVerifyError={() => handleVerifyError(setFieldError)}
            />
            <div className={styles.content}>
              <div className={styles.basicDetails}>
                <div className={styles.avatarContainer}>
                  {values.avatar ? (
                    <img className={styles.avatar} src={values.avatar} alt="Avatar" onClick={handleAvatarEditorOpen} />
                  ) : (
                    <div className={styles.uploadAvatarPrompt} onClick={handleAvatarEditorOpen}>
                      <i className={`material-icons-outlined ${styles.icon}`}>add_a_photo</i>
                      ADD PROFILE PIC
                    </div>
                  )}
                </div>
                <div className={styles.detailsInputContainer}>
                  <div className={styles.name}>
                    {profile?.titleValue} {profile.name}
                  </div>
                  <div className={styles.nameDetailsContainer}>
                    <div className={styles.nameContainer}>
                      <div className={styles.label}>
                        Preferred name
                        <HelpOutLineWithTooltips
                          id={'name-input'}
                          desc={
                            'This is the name we will show to clients whenever you are referenced in a message or communication.  You can go informal e.g. just your first name, or more formal e.g. Dr S Howard depending on what best fits your communication style'
                          }
                        />
                      </div>
                      <FormikMaterialInput id={'name'} inputClass={styles.inputClazz} label="" name="name" required />
                    </div>
                    <div className={styles.pronounsContainer}>
                      <div className={styles.label}>
                        Your pronouns
                        <HelpOutLineWithTooltips
                          id={'pronoun-select'}
                          desc={
                            'Please select your preferred pronouns.  This will be used whenever you are referenced in the third person.  E.g. Dr Sarah Howard has requested you send her an updated referral '
                          }
                        />
                      </div>
                      <FormikSelect
                        styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
                        name="pronouns"
                        options={PRONOUN_OPTIONS}
                      />
                    </div>
                  </div>
                  <div className={styles.mobileNumberContainer}>
                    {isEmailAddressInputDisabled ? (
                      <div className={styles.mobileNumberValue}>
                        <div className={styles.label}>Mobile phone number</div>
                        <div className={styles.value}>{values.mobileNumber}</div>
                      </div>
                    ) : (
                      <div className={styles.mobileFieldWrapper}>
                        <FormikMaterialPhoneInput
                          id={'mobileNumber'}
                          inputClass={styles.inputClazz}
                          className={styles.inputContainer}
                          label="Mobile phone number*"
                          name="mobileNumber"
                          onChange={(e: string) => setFieldValue('mobileNumber', e)}
                          required
                        />
                      </div>
                    )}
                    {isEmailAddressInputDisabled && (
                      <Button
                        className={styles.unlockButton}
                        variant="secondary"
                        onClick={() => setIsEmailAddressInputDisabled(false)}
                      >
                        <i className={`material-icons ${styles.icon}`}>lock</i>
                        Unlock to edit
                      </Button>
                    )}
                  </div>
                  <div className={styles.emailContainer}>
                    {isMobileNumberInputDisabled ? (
                      <div className={styles.emailValue}>
                        <div className={styles.label}>Email address</div>
                        <div className={styles.value}>{values.email}</div>
                      </div>
                    ) : (
                      <FormikMaterialInput
                        id={'email'}
                        inputClass={styles.inputClazz}
                        className={styles.inputContainer}
                        label="Email address*"
                        name="email"
                        required
                      />
                    )}
                    {isMobileNumberInputDisabled && (
                      <Button
                        className={styles.unlockButton}
                        variant="secondary"
                        onClick={() => setIsMobileNumberInputDisabled(false)}
                      >
                        <i className={`material-icons ${styles.icon}`}>lock</i>
                        Unlock to edit
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <ButtonAlt status={saveButtonStatus} onClick={submitForm}>
                  Save
                </ButtonAlt>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default MentorAboutYou;
