import * as yup from 'yup';
import i18n from 'i18n';
import {
  GuardianErrorInterface,
  GuardianFormValidationInterface,
  YoungGuardianErrorInterface
} from 'components/AddPatientModalV2/interfaces/formProps.interface';

export const youngPersonGuardianFormSchema = () =>
  yup.object().shape(
    {
      firstName: yup.string().required('Please enter first name'),
      lastName: yup.string().required('Please enter last name'),
      role: yup.string().required(i18n.t('validation.client_role.require')),
      isPrimaryContact: yup.boolean(),
      email: yup.string().email('Please enter a valid email address').nullable(),
      mobileNumber: yup.string().when('mobileNumber', (mobileNumber: string) => {
        if (mobileNumber && mobileNumber.length > 0) {
          return yup.string().min(12, 'Mobile number must be 10 digits or longer');
        } else {
          return yup.string().nullable();
        }
      })
    },
    [['mobileNumber', 'mobileNumber']]
  );

export const validationGuardianField = (value: GuardianFormValidationInterface) => {
  const validationErrors: YoungGuardianErrorInterface = {
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    mobileNumber: ''
  };

  try {
    youngPersonGuardianFormSchema().validateSync(value, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error) => {
        if (error.path) {
          validationErrors[error.path as keyof GuardianErrorInterface] = error.message;
        }
      });
      return validationErrors;
    }
  }
};
