import { Modal } from 'antd';
import classnames from 'classnames';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import moment from 'moment';
import { FC, RefObject } from 'react';
import {
  MOMENTJS_FORMAT_DATE,
  MOMENTJS_FORMAT_END_TIME_FORMAT,
  MOMENTJS_FORMAT_START_TIME_FORMAT,
  MOMENTJS_FORMAT_TIME
} from 'utils/appointment';
import styles from './CancelAppointmentModal.module.scss';

interface CancelAppointmentModalProps {
  isOpen: boolean;
  handleAbortCancelRecurringAppointments: () => void;
  handleCancelAppointment: (includeRecurringAppointments: boolean) => void;
  startTime: string;
  endTime: string;
  date?: string;
  onClose: () => void;
  cancelRecurringStatus?: ButtonStatusType;
  modalNode: RefObject<HTMLDivElement>;
}

export const CancelAppointmentModal: FC<CancelAppointmentModalProps> = ({
  handleAbortCancelRecurringAppointments,
  handleCancelAppointment,
  date,
  endTime,
  startTime,
  isOpen,
  onClose,
  cancelRecurringStatus,
  modalNode
}) => {
  return (
    <Modal
      open={isOpen}
      okType={'primary'}
      title={'This appointment is a recurring appointment.'}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={onClose}
      zIndex={1200}
    >
      <div ref={modalNode}>
        <div className={styles.description}>
          <div>
            <strong>Date:</strong> {moment(date, MOMENTJS_FORMAT_DATE).format('dddd DD MMMM YYYY')}
          </div>
          <div>
            <strong>Time:</strong>{' '}
            {`${moment(startTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_START_TIME_FORMAT)} - ${moment(
              endTime,
              MOMENTJS_FORMAT_TIME
            ).format(MOMENTJS_FORMAT_END_TIME_FORMAT)}`}
          </div>
        </div>
        <div className={styles.message}>Would you like to cancel all related recurring appointments?</div>
        <div className={styles.container}>
          <Button
            className={classnames(styles.button, !!cancelRecurringStatus && styles.busyButton)}
            onClick={() => handleCancelAppointment(true)}
            disabled={!!cancelRecurringStatus}
          >
            Cancel all recurring appointments
          </Button>
          <Button
            className={classnames(styles.button, !!cancelRecurringStatus && styles.busyButton)}
            onClick={() => handleCancelAppointment(false)}
            disabled={!!cancelRecurringStatus}
          >
            Cancel this appointment
          </Button>
          <Button
            className={styles.buttonCancel}
            onClick={handleAbortCancelRecurringAppointments}
            disabled={!!cancelRecurringStatus}
          >
            Don't cancel this appointment
          </Button>
        </div>
      </div>
    </Modal>
  );
};
