import * as yup from 'yup';
import { EpisodeStatus, ReasonUse } from 'interfaces/Episodes/episodes';

export const validateEpisodeSchema = (episodeStatusCheck?: boolean) =>
  yup.object().shape({
    status: yup.string().required('Please select status').oneOf(Object.values(EpisodeStatus)),
    type: yup.string().required('Please select type'),
    startDate: yup.date().required('Please select start date'),
    endDate: yup.string().when('status', {
      is: (epStatus: EpisodeStatus) =>
        [EpisodeStatus.Finished, EpisodeStatus.Cancelled, EpisodeStatus.Error].includes(epStatus),
      then: yup.string().required('Please select end date')
    }),
    reason: yup.object().shape({
      medicalReason: yup.string().required('Please select medical reason'),
      details: yup.string().optional(),
      reasonUse: yup.string().optional().oneOf(Object.values(ReasonUse))
    }),
    ...(episodeStatusCheck && {
      currentEpisodeStatus: yup.string().when('status', {
        is: EpisodeStatus.Active,
        then: yup.string().required('Please select status')
      }),
      currentEpisodeEndDate: yup.string().when('status', {
        is: EpisodeStatus.Active,
        then: yup.string().required('Please select end date')
      })
    })
  });

export const validateUpdateEpisodeStatusSchema = () =>
  yup.object().shape({
    currentEpisodeStatus: yup.string().required('Please select status'),
    currentEpisodeEndDate: yup.string().required('Please select end date')
  });
