import { MouseEvent, useEffect, useState } from 'react';
import styles from './GroupCaseNote.module.scss';
import { GroupDetailsTab, Groups, GroupStatus } from 'pages/Groups/Interfaces/Groups';
import { useGetAccessToken } from 'utils/hooks/token';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import CaseNoteListing from './components/CaseNoteListing/CaseNoteListing';
import { useFetchCaseNote, useFetchGroupCaseNoteList } from './hooks/getCaseNoteInfo';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import NoCaseNoteDetail from './components/NoCaseNoteDetail/NoCaseNoteDetail';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { Modal, notification } from 'antd';
import moment from 'moment';
import { useGenerateHeadingTemplateDetails } from './hooks/headingTemplate';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import defaultGroupFormatSettingsFields from './defaultGroupFormatSettingsFields.json';
import GroupCaseNoteDetail from './components/GroupCaseNoteDetail/GroupCaseNoteDetail';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import HeadingAssessment from 'components/HeadingTemplate/HeadingTemplateList/components/HeadingAssessment/HeadingAssessment';
import { GroupCaseNoteInterface } from './interfaces';
import { useTranslation } from 'react-i18next';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import useGetCaseNoteTemplatesList from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/hooks/useGetCaseNoteTemplatesList';
import { CaseNoteFormatSettingsFields, ParticipantType } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import { formatCaseNoteTemplateFields } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/formatCaseNoteTemplateFields';
import { useFetchOpenDataCategories } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/hooks/useFetchOpenDataCategories';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useFetchCommonODFVariable } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/hooks/useFetchCommonODFVariable';

interface GroupCaseNoteProps {
  groupDetails: Groups;
  caseNoteId?: string;
  activeGroupTab: GroupDetailsTab;
}

const GroupCaseNote = ({ groupDetails, caseNoteId, activeGroupTab }: GroupCaseNoteProps) => {
  const navigate = useNavigate();
  const path = useParams() as { contentId: string };
  const { token } = useGetAccessToken();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isHelmFeatureToggle, isCaWFeatureToggle } = useGetFeatureToggle();
  const { CARECOORDINATION } = useRoutesGenerator();
  const { isNormalUserView, isEdgeAdminView } = useGetAccountPackageView();
  const { accountId } = useGetAccountId();

  const { caseNoteList, isCaseNoteListLoading, setGroupCaseNotes } = useFetchGroupCaseNoteList(token, groupDetails._id);
  const { caseNote, isCaseNoteLoading, fetchCaseNote } = useFetchCaseNote(token, groupDetails._id, caseNoteId);
  const { generateHeadingTemplate } = useGenerateHeadingTemplateDetails(token, groupDetails._id);
  const { openDataCategories, isOpenDataCategoriesLoading } = useFetchOpenDataCategories(token, accountId);
  const { scaleTypes, isCommonVariablesLoading } = useFetchCommonODFVariable();

  const [addNoteButtonStatus, setAddNoteButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [caseNotes, setCaseNotes] = useState<GroupCaseNoteInterface[]>([]);

  const [searchValue, setSearchValue] = useState('');
  const [isDraftSave, setIsDraftSave] = useState<boolean>(false);
  const [caseNoteDetail, setCaseNoteDetail] = useState<GroupCaseNoteInterface>();
  const [isEditing, setIsEditing] = useState(false);
  const [isValueChange, setIsValueChange] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    setCaseNotes(caseNoteList);
  }, [caseNoteList]);

  useEffect(() => {
    setCaseNoteDetail(caseNote);
  }, [caseNote, auth0ClinicianId]);

  const {
    isLoading: isTemplatesLoading,
    templatesList: caseNotesTemplatesList,
    currentTemplate: currentCaseNoteTemplate,
    selectTemplate: selectCaseNoteTemplate
  } = useGetCaseNoteTemplatesList(token, ParticipantType.Group, isEdgeAdminView);

  const handleRedirect = (id: string) => {
    const idPath = id ? `/${id}` : '';
    navigate(`${CARECOORDINATION.GROUPS}/${groupDetails._id}/${activeGroupTab}${idPath}`);
  };

  const changeTemplate = async (id: string) => {
    const selected = selectCaseNoteTemplate(id);
    if (!selected) return;

    const body = await generateHeadingTemplate(selected.headings);
    return {
      ...caseNoteDetail,
      body,
      fields: formatCaseNoteTemplateFields({
        fields: selected.fields,
        categories: openDataCategories,
        scaleTypes
      })
    } as GroupCaseNoteInterface;
  };

  const handleAddCaseNote = async () => {
    setAddNoteButtonStatus('active');
    const headings = await generateHeadingTemplate(currentCaseNoteTemplate.headings);

    try {
      const payload: Record<string, any> = {
        _id: 'new',
        title: '',
        attachments: [],
        body: headings,
        clinicianId: auth0ClinicianId,
        createdAt: moment().toISOString()
      };

      const defaultFieldSettings =
        isHelmFeatureToggle || isCaWFeatureToggle
          ? (defaultGroupFormatSettingsFields.fields.filter(
              (obj) => obj.name !== 'Safeguarding Alert'
            ) as CaseNoteFormatSettingsFields[])
          : (defaultGroupFormatSettingsFields.fields as CaseNoteFormatSettingsFields[]);

      const templateFields =
        isHelmFeatureToggle || isCaWFeatureToggle
          ? currentCaseNoteTemplate?.fields.filter((obj) => obj.name !== 'Safeguarding Alert')
          : currentCaseNoteTemplate?.fields;

      payload.fields = formatCaseNoteTemplateFields({
        fields: templateFields || defaultFieldSettings,
        categories: openDataCategories,
        scaleTypes
      });

      setCaseNoteDetail(payload as GroupCaseNoteInterface);
      setCaseNotes([payload as GroupCaseNoteInterface, ...caseNotes]);

      setIsEditing(true);

      handleRedirect('new');

      setAddNoteButtonStatus('finished');

      setTimeout(() => setAddNoteButtonStatus(''), 500);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to add a note' });
    }
  };

  const onClickNewNote = () => {
    if (groupDetails.status === GroupStatus.Closed) {
      return;
    }
    if (isEditing || isValueChange) {
      Modal.confirm({
        title: 'There are some unsaved changes. Are you sure you want to navigate away from this page?',
        onOk: () => handleAddCaseNote()
      });
    } else {
      handleAddCaseNote();
    }
  };

  const handleNavigateNote = (id: string) => {
    setIsDraftSave(false);
    setIsEditing(false);
    setCaseNotes(caseNotes.filter((caseNoteObj) => caseNoteObj._id !== 'new'));
    handleRedirect(id);
    fetchCaseNote(id);
  };

  const onSelectNote = (id: string) => (e: MouseEvent) => {
    e.stopPropagation();
    const noteId = path.contentId;
    if (noteId !== 'new' ? id !== noteId : id !== 'new') {
      if ((isEditing && isValueChange) || (isEditing && noteId === 'new')) {
        Modal.confirm({
          title: 'There are some unsaved changes. Are you sure you want to navigate away from this page?',
          onOk: () => handleNavigateNote(id)
        });
      } else {
        handleNavigateNote(id);
      }
    }
  };

  const handleSearchKeyUp = (event: any) => {
    const fieldValue = event.target.value.toLowerCase() || '';
    if (event.keyCode === 13) {
      setSearchValue(fieldValue);
      if (fieldValue.length > 0) {
        const newCaseNotes = caseNoteList.filter((caseNoteObj) => {
          return caseNoteObj.title.toLowerCase().includes(fieldValue);
        });
        setCaseNotes(newCaseNotes);
      } else {
        setCaseNotes(caseNoteList);
      }
    }
  };

  const handleDeleteNote = () => {
    setIsEditing(false);
    setCaseNoteDetail(undefined);
    fetchCaseNote(undefined);
    setCaseNotes(caseNotes.filter((caseNote) => caseNote._id !== caseNoteId));
    setGroupCaseNotes(caseNotes.filter((caseNote) => caseNote._id !== caseNoteId));
    handleRedirect('');
  };

  const handleEditNoteClick = () => {
    setIsEditing(true);
  };

  const handleUpdatedNote = (updatedNote: GroupCaseNoteInterface, isDraft: boolean) => {
    setIsDraftSave(isDraft);
    const newNoteList = [
      updatedNote,
      ...caseNotes.filter((caseNoteObj) => caseNoteObj._id !== 'new' && caseNoteObj._id !== updatedNote._id)
    ];
    setCaseNoteDetail(updatedNote);
    setCaseNotes(newNoteList);
    setGroupCaseNotes(newNoteList);

    if (!isDraft) {
      handleRedirect(updatedNote._id);
      setIsEditing(false);
    }
  };

  const contentLoading = isDraftSave
    ? false
    : isCaseNoteLoading ||
      addNoteButtonStatus ||
      isTemplatesLoading ||
      isOpenDataCategoriesLoading ||
      isCommonVariablesLoading;
  const isAddNoteButtonDisabled = !!addNoteButtonStatus || isEditing || groupDetails.status === GroupStatus.Closed;

  return (
    <div className={styles.container}>
      {isCaseNoteListLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <HeadingAssessment isLoading={[].length > 0} assessmentData={[]} />
          <div className={styles.header}>
            <span className={styles.caseNotesCount}>
              {caseNotes.filter((note) => note._id).length} case notes saved
            </span>
            <div className={styles.inputContainer}>
              <MaterialInput id="search-notes" label="Search notes" onKeyUp={(e) => handleSearchKeyUp(e)} required />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.noteListing}>
              <CaseNoteListing
                noteId={caseNoteId}
                caseNotes={caseNotes}
                searchValue={searchValue}
                addNoteButtonStatus={addNoteButtonStatus}
                isAddNoteButtonDisabled={isAddNoteButtonDisabled}
                onClickNewNote={onClickNewNote}
                onSelectNote={onSelectNote}
                isEditing={isEditing}
              />
            </div>
            <div className={styles.noteDetailsContainer}>
              {caseNotes.length === 0 && !isCaseNoteLoading ? (
                <div className={styles.noCaseNotes}>
                  <NoCaseNoteDetail
                    message={t('label.no_client_case_notes')}
                    addNoteButtonStatus={addNoteButtonStatus}
                    isAddNoteButtonDisabled={isAddNoteButtonDisabled}
                    onClickNewNote={onClickNewNote}
                  />
                </div>
              ) : !caseNoteId ? (
                <div className={styles.noCaseNoteSelected}>
                  <NoCaseNoteDetail
                    message={'No case note selected.'}
                    addNoteButtonStatus={addNoteButtonStatus}
                    isAddNoteButtonDisabled={isAddNoteButtonDisabled}
                    onClickNewNote={onClickNewNote}
                  />
                </div>
              ) : contentLoading ? (
                <div className={styles.loading}>
                  <LoadingDot />
                </div>
              ) : caseNoteDetail ? (
                <GroupCaseNoteDetail
                  groupId={groupDetails._id}
                  caseNoteData={caseNoteDetail}
                  currentTemplate={currentCaseNoteTemplate}
                  templateList={caseNotesTemplatesList}
                  onSelectTemplate={changeTemplate}
                  token={token}
                  isEditing={isEditing}
                  onValueChange={(val) => setIsValueChange(val)}
                  onDeleteNote={handleDeleteNote}
                  onEditNoteClick={handleEditNoteClick}
                  onUpdateNoteDetails={handleUpdatedNote}
                  isReadOnly={caseNote && !isNormalUserView ? caseNote?.clinicianId !== auth0ClinicianId : false}
                  isClosedClient={groupDetails.status === GroupStatus.Closed}
                />
              ) : (
                <div className={styles.noCaseNoteFound}>
                  <NoCaseNoteDetail
                    message={'Note not found.'}
                    addNoteButtonStatus={addNoteButtonStatus}
                    isAddNoteButtonDisabled={isAddNoteButtonDisabled}
                    onClickNewNote={onClickNewNote}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupCaseNote;
