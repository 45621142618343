import { ChangeEvent } from 'react';
import classnames from 'classnames';

import FlexBox from 'components/FlexBox/FlexBox';
import styles from './CheckBoxWithDesc.module.scss';

interface CheckBoxWithDescPops {
  id: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  description?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CheckBoxWithDesc = ({ id, label, checked, disabled, description, onChange }: CheckBoxWithDescPops) => {
  return (
    <FlexBox direction="row" spacing={12}>
      <input
        className={classnames(disabled ? styles.disabledCheckbox : styles.checkbox)}
        type="checkbox"
        disabled={disabled}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <FlexBox direction="column">
        <label className={styles.label}>{label}</label>
        {description && <span className={styles.desc}>{description}</span>}
      </FlexBox>
    </FlexBox>
  );
};

export default CheckBoxWithDesc;
