import FlexBox from 'components/FlexBox/FlexBox';
import { Invoice } from 'pages/Invoices/interface';
import PaymentMethodDetail from 'pages/InvoiceTemplatesPreview/PaymentDetail/components/PaymentMethodDetail';
import { PAYMENT_TYPE_LABELS } from 'pages/Invoices/components/PaymentMethods/PaymentMethods';

import styles from '../../InvoiceViewWithTemplate.module.scss';

const PaymentDetailsSection = ({ invoice }: { invoice: Invoice }) => {
  return (
    <FlexBox direction="column" spacing={4} className={styles.wrapper}>
      <span className={styles.heading}>Payment Details</span>
      <FlexBox direction="row" spacing={20} className={styles.flexWrap}>
        {invoice.paymentMethods.map((paymentMethod, index) => (
          <div className={styles.width350} key={index}>
            <span className={styles.paymentMethodTitle}>{PAYMENT_TYPE_LABELS[paymentMethod.paymentType]}</span>
            <PaymentMethodDetail paymentMethod={paymentMethod} />
          </div>
        ))}
      </FlexBox>
    </FlexBox>
  );
};

export default PaymentDetailsSection;
