import momentTz from 'moment-timezone';
import { config } from 'config/config';

export const defaultTimezone = config.defaultTimezone;

export const getTimeZoneDateTime = (dateTime?: string, timeZoneSetting?: string) =>
  momentTz.tz(dateTime, timeZoneSetting || config.defaultTimezone);

const generateTimeZoneLabel = (timezone: string) => {
  const getTimeZoneLabel = AU_TIMEZONE_LABEL[timezone];
  if (!getTimeZoneLabel) {
    return timezone;
  }

  const getTodayTime = momentTz.tz(timezone).isDST();
  const dstCode = getTodayTime ? getTimeZoneLabel.dstCode : getTimeZoneLabel.nonDstCode;
  const dstHr = getTodayTime ? getTimeZoneLabel.dstHr : getTimeZoneLabel.nonDstHr;
  return `${getTimeZoneLabel.desc} (${dstCode}) +${dstHr}`;
};

interface TimeZoneLabelInterface {
  nonDstCode: string;
  dstCode: string;
  nonDstHr: string;
  dstHr: string;
  desc: string;
  shortDesc: string;
}

const AU_TIMEZONE_LABEL: Record<string, TimeZoneLabelInterface> = {
  'Australia/Sydney': {
    nonDstCode: 'AEST',
    dstCode: 'AEDT',
    nonDstHr: '10',
    dstHr: '11',
    desc: 'New South Wales/Sydney',
    shortDesc: 'NSW, VIC & ACT'
  },
  'Australia/Melbourne': {
    nonDstCode: 'AEST',
    dstCode: 'AEDT',
    nonDstHr: '10',
    dstHr: '11',
    desc: 'Victoria/Melbourne',
    shortDesc: 'NSW, VIC & ACT'
  },
  'Australia/Brisbane': {
    nonDstCode: 'AEST',
    dstCode: 'AEST',
    nonDstHr: '10',
    dstHr: '10',
    desc: 'Queensland/Brisbane',
    shortDesc: 'Queensland'
  },
  'Australia/Queensland': {
    nonDstCode: 'AEST',
    dstCode: 'AEST',
    nonDstHr: '10',
    dstHr: '10',
    desc: 'Queensland/Brisbane',
    shortDesc: 'Queensland'
  },
  'Australia/Adelaide': {
    nonDstCode: 'ACST',
    dstCode: 'ACDT',
    nonDstHr: '9.5',
    dstHr: '10.5',
    desc: 'South Australia/Adelaide',
    shortDesc: 'South Australia'
  },
  'Australia/Darwin': {
    nonDstCode: 'ACST',
    dstCode: 'ACST',
    nonDstHr: '9.5',
    dstHr: '9.5',
    desc: 'Northern Territory/Darwin',
    shortDesc: 'Northern Territory'
  },
  'Australia/Perth': {
    nonDstCode: 'AWST',
    dstCode: 'AWST',
    nonDstHr: '8',
    dstHr: '8',
    desc: 'Western Australia/Perth',
    shortDesc: 'Western Australia'
  },
  'Pacific/Norfolk': {
    nonDstCode: 'NFT',
    dstCode: 'NFDT',
    nonDstHr: '11',
    dstHr: '12',
    desc: 'Norfolk Island/Kingston',
    shortDesc: 'Norfolk Island'
  },
  'Indian/Christmas': {
    nonDstCode: 'CXT',
    dstCode: 'CXT',
    nonDstHr: '7',
    dstHr: '7',
    desc: 'Christmas Island',
    shortDesc: 'Christmas Island'
  },
  'Indian/Cocos': {
    nonDstCode: 'CCT',
    dstCode: 'CCT',
    nonDstHr: '6.5',
    dstHr: '6.5',
    desc: 'Cocos Islands',
    shortDesc: 'Christmas Island'
  }
};

export const getTimezoneLabel = (timezoneKey: string = defaultTimezone) => generateTimeZoneLabel(timezoneKey);
