import ModalV2 from 'components/ModalV2/ModalV2';
import styles from './PartialRefundModal.module.scss';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { config } from 'config/config';
import classNames from 'classnames';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import { convertCurrencyValue, sanitizedCurrencyValue } from 'utils/currencyFormatConverter';
import { ChangeEvent, useState } from 'react';
import { PartialRefundResponse, usePartialRefundMutation } from 'redux/endpoints/billingServices/invoices';
import { notification } from 'antd';
import { InvoicePayment } from 'pages/InvoicePayments/constants';
import PaymentDetailsForModal from '../PaymentDetailsForModal/PaymentDetailsForModal';

interface PartialRefundModalProps {
  showModal: boolean;
  payment: InvoicePayment;
  partialRefund: { amount: string };
  onCloseModal: () => void;
  onChangeValue: (value: { amount: string }) => void;
  onSubmitSuccess?: () => void;
}

const PartialRefundModal = ({
  showModal,
  payment,
  partialRefund,
  onCloseModal,
  onChangeValue,
  onSubmitSuccess
}: PartialRefundModalProps) => {
  const { currencySymbol } = config;
  const { accountId, payments, _id: paymentRequestId } = payment;
  const { amount } = partialRefund;

  const [postPartialRefund] = usePartialRefundMutation();

  const [formError, setFormError] = useState<{ amount: string }>({ amount: '' });
  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');

  const onChangeAmount = (value: string) => {
    onChangeValue({ amount: sanitizedCurrencyValue(value) });
    setFormError({
      ...formError,
      amount: ''
    });
  };

  const handleSubmit = async () => {
    const amountInNumber = Number(amount || '');
    const isValidAmount = !Number.isNaN(amountInNumber) && amountInNumber > 0 && amountInNumber <= payments.paid;
    if (paymentRequestId && isValidAmount) {
      try {
        setSubmitButtonStatus('active');
        const result: PartialRefundResponse = await postPartialRefund({
          accountId,
          paymentRequestId,
          amount: amountInNumber
        }).unwrap();
        if (result.error) {
          notification.error({ message: result.error });
          setSubmitButtonStatus('');
        } else {
          notification.success({
            message:
              'Refund processed successfully. Your customer will see the refund as a credit in approximately 5-10 business days depending upon their bank.',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setSubmitButtonStatus('finished');
          onSubmitSuccess && onSubmitSuccess();
          setTimeout(() => {
            setSubmitButtonStatus('');
            handleCloseModal();
          }, 2000);
        }
      } catch (ex) {
        setSubmitButtonStatus('');
        notification.error({
          message: 'Refund attempt failed. Please try again, if failures persist please contact us to investigate.'
        });
      }
    }
    setFormError({
      amount: !isValidAmount ? 'Error' : ''
    });
  };

  const handleCloseModal = () => {
    setFormError({
      amount: ''
    });
    onCloseModal();
  };

  return (
    <ModalV2
      containerClassName={styles.refundModalContainer}
      isModalOpen={showModal}
      title="Refund"
      onModalClose={handleCloseModal}
      bodyStyle={{ padding: '0' }}
      headerContainerClassName={styles.headerContainerClassName}
      titleClassName={styles.titleClassName}
    >
      <div className={styles.container}>
        {/* Payment details */}
        <PaymentDetailsForModal payment={payment} />

        {/* Refund Form */}
        <div className={styles.refundForm}>
          <div className={styles.amountInputContainer}>
            <TitleInput
              inputContainerClassName={formError.amount ? styles.inputErrorClassName : styles.inputClassName}
              prefix={currencySymbol}
              inputContainer={styles.amountInput}
              inputProps={{
                maxLength: 18,
                placeholder: 'Enter amount',
                value: convertCurrencyValue(amount.toString() || ''),
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  onChangeAmount(e.target.value);
                }
              }}
              error={formError.amount}
              hideErrorDesc
            />
            <div className={classNames(styles.amountNote, formError.amount ? styles.amountError : '')}>
              Refund amount must be equal or less than {currencySymbol} eligible for refund value
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <ButtonAlt
            status={submitButtonStatus}
            loadingWord="Processing..."
            completedWord="Done"
            onClick={handleSubmit}
          >
            Refund
          </ButtonAlt>
        </div>
      </div>
    </ModalV2>
  );
};

export default PartialRefundModal;
