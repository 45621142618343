import styles from './StepProgressBar.module.scss';
import classnames from 'classnames';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface StepProgressBarInterface {
  currentStep: number;
  totalStep: number;
  isCompleted?: boolean;
  className?: string;
}

const StepProgressBar = ({ totalStep, currentStep, isCompleted, className }: StepProgressBarInterface) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const percentPerStep = Math.floor(100 / totalStep);
  const progressVal = percentPerStep * currentStep;

  return (
    <div className={classnames(styles.container, className, isEdgeAdminView && 't23-admin-theme')}>
      <div className={isCompleted ? styles.hideContent : styles.showContent}>
        <div className={styles.label}>
          STEP {currentStep} OF {totalStep}
        </div>
        <div className={styles.barContainer}>
          <ProgressBar progressVal={progressVal} barColor={isEdgeAdminView ? styles.goldColor : styles.blueColor} />
        </div>
      </div>
      <div className={isCompleted ? styles.showContent : styles.hideContent}>
        <div className={styles.label}>COMPLETED</div>
      </div>
    </div>
  );
};

export default StepProgressBar;
