import { createContext, ReactNode, useEffect } from 'react';
import { useFetchProfile } from './hooks/GetProfileDetails/getProfileInfo';
import Loading from '../components/Loading/Loading';
import NoAuthLayout from '../layouts/NoAuthLayout/NoAuthLayout';
import { ProfileInterface } from '../interfaces/Profile/Profile';
import { useTranslation } from 'react-i18next';
import { getTerminology } from 'i18n/resources/en/terminology';

export enum CustomFeature {
  Mentor = 'mentor',
  Vadc = 'vadc',
  HelmContent = 'helmContent',
  Helm = 'helm',
  MedicareRebate = 'medicareRebate',
  MentalHealthHubLogo = 'mentalHealthHubLogo',
  RequestApprovalByDefault = 'requestApprovalByDefault',
  TemplateName = 'templateName',
  Fax = 'fax',
  SomeoneHealth = 'someoneHealth',
  CaW = 'caw',
  CarePlan = 'carePlan',
  LetterDisabled = 'letterDisabled',
  EaseWellbeing = 'easeWellbeing',
  EoC = 'eoc',
  MultiTimeZone = 'multiTimeZone',
  EngagePublicProfile = 'engagePublicProfile',
  InvoiceTemplate = 'invoiceTemplate',
  RechargeWellness = 'rechargeWellness',
  ClientProfileEpisode = 'clientProfileEpisode',
  AppointmentActions = 'appointmentActions',
  Egrist = 'egrist',
  HealthData = 'healthData',
  HidePrivateCaseNotes = 'hidePrivateCaseNotes',
  Packages = 'packages',
  SelectPsychology = 'selectPsychology',
  ProcessAppointment = 'processAppointment',
  AddressBook = 'addressBook',
  InvoiceListV2 = 'invoiceListV2',
  TwilioTelehealth = 'twilioTelehealth',
  ReferralV2 = 'referralV2'
}

const initialValues = {
  customFeatures: [],
  accountSettings: {},
  profileSettings: {},
  clinicianProfile: undefined,
  setProfile: () => {}
};

export const UserContext = createContext<{
  customFeatures: CustomFeature[];
  accountSettings: ProfileInterface['accountSettings'];
  profileSettings: ProfileInterface['profileSettings'];
  clinicianProfile?: ProfileInterface;
  setProfile: (p: ProfileInterface) => void;
}>(initialValues);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const { profile, isProfileLoading, setProfile } = useFetchProfile();
  const [, trans] = useTranslation();

  useEffect(() => {
    if (profile && profile.accountSettings) {
      trans.addResourceBundle('en', 'terminology', getTerminology(profile.accountSettings), true, true);
    }
  }, [profile, trans]);

  return isProfileLoading ? (
    <NoAuthLayout>
      <Loading />
    </NoAuthLayout>
  ) : (
    <UserContext.Provider
      value={{
        customFeatures: profile.customFeatures || [],
        accountSettings: profile.accountSettings,
        profileSettings: profile.profileSettings,
        clinicianProfile: profile,
        setProfile
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
