import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import styles from './CarePathwayList.module.scss';
import { CarePathwayListContent } from './components/CarePathwayListContent/CarePathwayListContent';

export const CarePathwayList = () => {
  return (
    <HelmetWrapper title={'Tacklit - Care Coordination'}>
      <ContentLayout>
        <div className={styles.container}>
          <CarePathwayListContent />
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};
