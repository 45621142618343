import { v4 as uuid } from 'uuid';
import { newClientForm } from '../AddPatientModalV2Interface';
import { defaultBaseDetails } from './defaultBaseDetails';

const clientProfiles = [
  {
    _id: uuid(),
    firstName: '',
    lastName: '',
    email: '',
    isEmailThirdParty: false,
    mobileNumber: '',
    isMobileNumberThirdParty: false,
    onboarding: {
      isCheckOnBoarding: true,
      onboardingSurveyIds: [],
      onboardingMethod: 'sendToClient'
    },
    consentForm: {
      isCheckOnConsentForm: true,
      consentFormIds: []
    },
    assessmentForm: {
      isCheckOnAssessmentForm: true,
      assessmentFormIds: []
    },
    isPrimaryContact: true,
    communicationPreference: 'noPreference'
  }
];

export const defaultAdultDetails = {
  ...defaultBaseDetails,
  ...{ clientProfiles }
} as newClientForm;
