import { config } from 'config/config';
import { ISentTo } from 'utils/http/NotifService/fax';

export interface ISendEFaxFormValue {
  isReferringGPSelected: boolean;
  isSelectGPSelected: boolean;
  isOtherFaxNumbersSelected: boolean;
  selectGPFaxNumber: string;
  otherFaxNumbers: string;
}

export const initialValue: ISendEFaxFormValue = {
  isReferringGPSelected: false,
  isSelectGPSelected: false,
  isOtherFaxNumbersSelected: false,
  selectGPFaxNumber: '',
  otherFaxNumbers: ''
};

export interface ISendEFaxFormError {
  isUnsupportedBannerHighlighted: boolean;
  isFaxRecipientError: boolean;
  referringGPErrorMessage: string;
  selectGPErrorMessage: string;
  otherFaxNumbersErrorMessage: string;
}

export const initialError: ISendEFaxFormError = {
  isUnsupportedBannerHighlighted: false,
  isFaxRecipientError: false,
  referringGPErrorMessage: '',
  selectGPErrorMessage: '',
  otherFaxNumbersErrorMessage: ''
};

export const validate = (
  sendFrom: string,
  formValue: ISendEFaxFormValue,
  isEditingFaxNumber: boolean
): ISendEFaxFormError => {
  const { isReferringGPSelected, isSelectGPSelected, isOtherFaxNumbersSelected, selectGPFaxNumber, otherFaxNumbers } =
    formValue;
  let result = initialError;
  if (!sendFrom) {
    return {
      ...initialError,
      isUnsupportedBannerHighlighted: true
    };
  }

  if (!isReferringGPSelected && !isSelectGPSelected && !isOtherFaxNumbersSelected) {
    return {
      ...initialError,
      isFaxRecipientError: true
    };
  }

  if (isReferringGPSelected && isEditingFaxNumber) {
    result = {
      ...initialError,
      referringGPErrorMessage: 'Please save your fax number before sending'
    };
  }

  if (isSelectGPSelected && !selectGPFaxNumber) {
    result = {
      ...initialError,
      selectGPErrorMessage: 'Please select a referring GP'
    };
  }

  if (isOtherFaxNumbersSelected) {
    // Remove all spaces
    const formattedOtherFaxNumberString = otherFaxNumbers.replaceAll(/\s/g, '');

    if (!formattedOtherFaxNumberString) {
      result = {
        ...initialError,
        otherFaxNumbersErrorMessage: 'Please enter at least one fax number'
      };
    } else {
      const faxNumbers = formattedOtherFaxNumberString.split(';');
      const existInvalidFaxNumber = faxNumbers.some(
        (faxNumber) =>
          faxNumber.length < config.mobileNumberMinLength || faxNumber.length > config.mobileNumberMaxLength
      );
      if (existInvalidFaxNumber) {
        result = {
          ...initialError,
          otherFaxNumbersErrorMessage: 'Please enter valid fax numbers'
        };
      }
    }
  }

  return result;
};

export const getNumberOfRecipients = (formValue: ISendEFaxFormValue): number => {
  let result = 0;
  if (formValue.isReferringGPSelected) {
    result++;
  }

  if (formValue.isSelectGPSelected && formValue.selectGPFaxNumber) {
    result++;
  }

  if (formValue.isOtherFaxNumbersSelected) {
    const otherFaxNumbersArray = formValue.otherFaxNumbers.split(';').map((number) => number.trim());
    result += otherFaxNumbersArray.filter((stringNumber) => stringNumber).length;
  }

  return result;
};

const formatFaxNumber = (faxNumber: string) => {
  return faxNumber.replaceAll(/[+]|[-]|[(]|[)]|[\s]/gi, '');
};

export const getFaxNumberSendTo = (
  formValue: ISendEFaxFormValue,
  referringGPFaxNumber: string,
  referringGPName: string
): ISentTo[] => {
  let result = [] as ISentTo[];
  const { isReferringGPSelected, isSelectGPSelected, isOtherFaxNumbersSelected, selectGPFaxNumber, otherFaxNumbers } =
    formValue;

  if (isReferringGPSelected) {
    result.push({
      faxNumber: formatFaxNumber(referringGPFaxNumber),
      ...(referringGPName && {
        recipientName: referringGPName
      })
    });
  }
  if (isSelectGPSelected) {
    const selectGPSplitted = selectGPFaxNumber.split('|');
    if (selectGPSplitted[1]) {
      result.push({
        faxNumber: formatFaxNumber(selectGPSplitted[1]),
        ...(selectGPSplitted[0] && {
          recipientName: selectGPSplitted[0]
        })
      });
    }
  }
  if (isOtherFaxNumbersSelected) {
    // Remove all spaces
    const formattedOtherFaxNumberString = otherFaxNumbers.replaceAll(/\s/g, '');
    const rawFaxNumbers = formattedOtherFaxNumberString.split(';');
    rawFaxNumbers.forEach((faxNumber) => {
      if (faxNumber) {
        result.push({
          faxNumber: formatFaxNumber(faxNumber)
        });
      }
    });
  }
  return result;
};
