import styles from './WriteOffListFilter.module.scss';
import { useMemo, useState } from 'react';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectFilters,
  selectSearch,
  resetPaging,
  resetSearch,
  resetFilters,
  setFilters,
  setSearch
} from 'redux/invoices/writeOffInvoiceSlice';
import { Skeleton } from 'antd';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import ParticipationToggle from 'components/SelectClientOrGroup/components/ParticipationHeader/components/ParticipationToggle/ParticipationToggle';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import { useGetMinifiedClientRecordQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetMinifiedGroupListQuery } from 'redux/endpoints/clinicianProfileServices/group';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import { useGetPractitionerListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import { debounce } from 'lodash';
import { CLAIM_TYPE_OPTIONS } from 'pages/InvoicesV2/constants';
import SearchBar from 'components/SearchBar/SearchBar';
import { useGetWriteOffInvoicesList } from 'redux/invoices/useGetWriteOffInvoices';

interface WriteOffListFilterProps {
  showInvoiceType?: boolean;
}

const PER_PAGE = 50;

const WriteOffListFilter = ({ showInvoiceType }: WriteOffListFilterProps) => {
  const dispatch = useAppDispatch();
  const { accountId } = useGetAccountId();
  const { isGroupsFeatureToggle } = useGetFeatureToggle();
  const { isEdgeAdminView, isEdgeUserView } = useGetAccountPackageView();

  const [clientListCurrentPage, setClientListCurrentPage] = useState<number>(1);
  const [clientSearchText, setClientSearchText] = useState<string>('');
  const [practitionerCurrentPage, setPractitionerCurrentPage] = useState<number>(1);
  const [practitionerSearchText, setPractitionerSearchText] = useState<string>('');

  const filters = useAppSelector(selectFilters);
  const search = useAppSelector(selectSearch);

  const { searchValue } = search;
  const { participantType, clients, invoiceTypes, groups, practitioners } = filters;

  const {
    isWriteOffInvoicesLoading,
    writeOffInvoiceCounts,
    writeOffInvoiceTotalItems: matchedCount
  } = useGetWriteOffInvoicesList();

  const {
    data: clientListData,
    isLoading: isClientListDataLoading,
    isFetching: isClientListDataFetching
  } = useGetMinifiedClientRecordQuery(
    {
      accountId,
      params: {
        page: clientListCurrentPage,
        perPage: PER_PAGE,
        recordStatus: 'active,waitlist',
        ...(clientSearchText && {
          searchValue: clientSearchText,
          searchBy: SearchFilterEnum.FirstOrLastName
        })
      }
    },
    {}
  );

  const {
    data: groupListData,
    isLoading: isGroupListDataLoading,
    isFetching: isGroupListDataFetching
  } = useGetMinifiedGroupListQuery({ accountId, isEdgeUserView }, { skip: participantType !== ParticipantType.Group });

  const {
    data: practitionerData,
    isLoading: isPractitionerDataLoading,
    isFetching: isPractitionerDataFetching
  } = useGetPractitionerListQuery({
    accountId,
    params: { page: practitionerCurrentPage, perPage: PER_PAGE, status: 'active', searchValue: practitionerSearchText }
  });

  const groupListFilter: FilterCheckListItem[] = groupListData ? groupListData.groups : [];
  const clientListFilter: FilterCheckListItem[] = clientListData
    ? clientListData.clientRecords.map((item) => ({
        name: `${item.clientProfiles[0].firstName} ${item.clientProfiles[0].lastName}`,
        _id: item._id
      }))
    : [];
  const practitionerList: FilterCheckListItem[] = practitionerData
    ? practitionerData.clinicians.map((item) => ({ name: item.name, _id: item._id }))
    : [];

  const onClearFilter = () => {
    dispatch(resetPaging());
    dispatch(resetSearch());
    dispatch(resetFilters());
  };

  const onChangeParticipantType = (participantType: ParticipantType) => {
    dispatch(
      setFilters({
        ...filters,
        participantType
      })
    );
  };

  const onChangeClientFilter = (clients: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, clients }));
  };

  const onChangeGroupFilter = (groups: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, groups }));
  };

  const onChangePractitionerFilter = (practitioners: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, practitioners }));
  };

  const onChangeInvoiceTypesFilter = (invoiceTypes: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, invoiceTypes }));
  };

  const onSearchHandle = (val: string) => {
    dispatch(resetPaging());
    dispatch(setSearch({ ...search, searchValue: val }));
  };

  const debounceSetClientSearchText = useMemo(
    () =>
      debounce((value) => {
        setClientSearchText(value);
        setClientListCurrentPage(1);
      }, 1000),
    []
  );

  const debouncedSetPractitionersSearchText = useMemo(
    () =>
      debounce((value) => {
        setPractitionerSearchText(value);
        setPractitionerCurrentPage(1);
      }, 1000),
    []
  );

  const showClearFilter = useMemo(
    () =>
      search.searchValue ||
      (filters.participantType === ParticipantType.OneToOne && filters.clients.length > 0) ||
      (filters.participantType === ParticipantType.Group && filters.groups.length > 0) ||
      filters.invoiceTypes.length > 0 ||
      filters.practitioners.length > 0,
    [filters, search.searchValue]
  );

  const totalClientListPage = clientListData?.paging ? Math.ceil(clientListData.paging.totalItems / PER_PAGE) : 1;
  const totalPractitionerPage = practitionerData?.paging ? Math.ceil(practitionerData.paging.totalItems / PER_PAGE) : 1;

  return (
    <div className={styles.container}>
      <div className={styles.rowContainer}>
        <div className={styles.filterInformation}>
          <div className={styles.numberOfMatchedInvoices}>
            {!isWriteOffInvoicesLoading ? (
              <div>
                {showClearFilter ? 'FILTER matched' : 'Showing'}{' '}
                <span className={styles.highLight}>
                  {matchedCount}/{writeOffInvoiceCounts.total}{' '}
                </span>
                write offs
              </div>
            ) : (
              <div className={styles.loadingInvoice}>
                <Skeleton.Input className={styles.loading} active />
              </div>
            )}
          </div>
          {showClearFilter && (
            <ButtonAlt error size={'small'} variant={'text'} className={styles.clearFilters} onClick={onClearFilter}>
              Clear filters
            </ButtonAlt>
          )}
        </div>
        {/* Participant Type */}
        {isGroupsFeatureToggle && (
          <ParticipationToggle
            selectedParticipantType={participantType}
            onChangeParticipation={(val) => {
              onChangeParticipantType(val);
            }}
          />
        )}
      </div>

      <div className={styles.rowContainer}>
        <div className={styles.filterDropdownsContainer}>
          {/* Client | Group filter*/}
          {participantType === ParticipantType.OneToOne ? (
            <FilterDropdown
              id="clients"
              icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
              menuItems={clientListFilter}
              onChangeItem={onToggleFilter(clientListFilter, clients, onChangeClientFilter)}
              searchable
              selectedFilterList={clients}
              showSearchIcon
              loading={isClientListDataLoading}
              hasMoreData={totalClientListPage > clientListCurrentPage || isClientListDataFetching}
              isFetchingMore={isClientListDataFetching}
              loadMore={() => {
                if (!isClientListDataFetching && clientListCurrentPage <= totalClientListPage) {
                  setClientListCurrentPage(clientListCurrentPage + 1);
                }
              }}
              enableSelectedBackground
              onClearFilter={() => {
                dispatch(resetPaging());
                dispatch(setFilters({ ...filters, clients: [] }));
              }}
              setSearchText={debounceSetClientSearchText}
            >
              Client
            </FilterDropdown>
          ) : (
            <FilterDropdown
              id="groups"
              searchable
              icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
              menuItems={groupListFilter}
              onChangeItem={onToggleFilter(groupListFilter, groups, onChangeGroupFilter)}
              selectedFilterList={groups}
              loading={isGroupListDataLoading || isGroupListDataFetching}
              enableSelectedBackground
              onClearFilter={() => {
                dispatch(resetPaging());
                dispatch(setFilters({ ...filters, groups: [] }));
              }}
            >
              Group
            </FilterDropdown>
          )}

          {/* Practitioner */}
          {isEdgeAdminView && (
            <FilterDropdown
              id={'practitioner'}
              icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
              menuItems={practitionerList}
              onChangeItem={onToggleFilter(practitionerList, practitioners, onChangePractitionerFilter)}
              searchable
              selectedFilterList={practitioners}
              showSearchIcon
              showToggleAllButtons
              loading={isPractitionerDataLoading}
              hasMoreData={totalPractitionerPage > practitionerCurrentPage || isPractitionerDataFetching}
              isFetchingMore={isPractitionerDataFetching}
              loadMore={() => {
                if (!isPractitionerDataFetching && practitionerCurrentPage <= totalPractitionerPage) {
                  setPractitionerCurrentPage(practitionerCurrentPage + 1);
                }
              }}
              onClearFilter={() => {
                dispatch(resetPaging());
                dispatch(setFilters({ ...filters, practitioners: [] }));
              }}
              enableSelectedBackground
              setSearchText={debouncedSetPractitionersSearchText}
            >
              Practitioner
            </FilterDropdown>
          )}

          {/* Invoice Type */}
          {showInvoiceType && (
            <FilterDropdown
              id="invoiceTypes"
              menuItems={CLAIM_TYPE_OPTIONS}
              onChangeItem={onToggleFilter(CLAIM_TYPE_OPTIONS, invoiceTypes, onChangeInvoiceTypesFilter)}
              selectedFilterList={invoiceTypes}
              enableSelectedBackground
              onClearFilter={() => {
                dispatch(resetPaging());
                dispatch(setFilters({ ...filters, invoiceTypes: [] }));
              }}
            >
              Payer
            </FilterDropdown>
          )}
        </div>

        <SearchBar
          placeholder={'Search by Invoice ID'}
          value={searchValue}
          withSearchButton
          onSearch={onSearchHandle}
          containerClassName={styles.searchContainer}
        />
      </div>
    </div>
  );
};

export default WriteOffListFilter;
