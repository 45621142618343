import ContentLayoutT23 from 'components/ContentLayoutT23/ContentLayoutT23';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { useParams, useNavigate } from 'react-router-dom';
import AppointmentTypes from './components/AppointmentTypes/AppointmentTypes';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import styles from './CalendarSettings.module.scss';
import RoomManagement from './components/RoomManagement/RoomManagement';
import CalendarLinking from './components/CalendarLinking/CalendarLinking';
import AccountLevelSettings from './components/AccountLevelSettings/AccountLevelSettings';
import CalendarSettingsSubTab from 'components/SideDashboardT23/components/CalendarSettingsSubTab/CalendarSettingsSubTab';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

const CalendarSettings = () => {
  const navigate = useNavigate();
  const { CALENDAR } = useRoutesGenerator();
  const { isEdgeAdminView, isEdgeUser, isNormalUserView } = useGetAccountPackageView();
  const { tab = '' } = useParams<{ tab: string }>();

  const backBtn = () => {
    navigate(CALENDAR.BASE);
  };

  const renderContent = () => {
    switch (tab) {
      case 'appointmentTypes':
        return <AppointmentTypes />;
      case 'calendarLinking':
        return (isEdgeAdminView || isEdgeUser || isNormalUserView) && <CalendarLinking />;
      case 'accountLevelSettings':
        return <AccountLevelSettings />;
      case 'roomManagement':
        return isEdgeUser && <RoomManagement />;
    }
  };

  return (
    <HelmetWrapper title="Tacklit - Calendar Settings">
      <ContentLayoutT23
        className={styles.container}
        subTab={<CalendarSettingsSubTab />}
        subTabTitle={<div className={styles.copyShareableLinkContainer}>Calendar Settings</div>}
      >
        <div className={styles.header}>
          <ButtonAlt variant={'text'} size={'medium'} onClick={backBtn} icon={'arrow_back_ios'}>
            To Calendar
          </ButtonAlt>
        </div>
        <div className={styles.body}>
          <div className={styles.content}>{renderContent()}</div>
        </div>
      </ContentLayoutT23>
    </HelmetWrapper>
  );
};

export default CalendarSettings;
