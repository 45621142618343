import styles from './ReportTemplateCard.module.scss';
import classnames from 'classnames';
import { ReportTemplate, ReportTemplateStatus } from 'interfaces/Reports/reportTemplate';
import moment from 'moment';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import SwitchLoading from 'components/MaterialFieldComponent/MaterialToggleSwitch/SwitchLoading/SwitchLoading';
import { GenerateTemplateLoadingInterface } from '../../../../EditTab';
import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';

interface ReportTemplateCardProps {
  showSettings: boolean;
  reportTemplateData: ReportTemplate;
  onClickDeleteTemplate: (id: string) => void;
  onClickEditTemplate: (id: string) => void;
  onClickSelectTemplate: (templateData: ReportTemplate) => void;
  generateTemplateLoading: GenerateTemplateLoadingInterface;
  isChangingTemplateDisabled: boolean;
}

const ReportTemplateCard = ({
  showSettings,
  reportTemplateData,
  onClickDeleteTemplate,
  onClickEditTemplate,
  onClickSelectTemplate,
  generateTemplateLoading,
  isChangingTemplateDisabled
}: ReportTemplateCardProps) => {
  const { auth0ClinicianId } = useGetClinicianId();
  const reportTemplateId = reportTemplateData._id;
  const updatedDate = moment(reportTemplateData.updatedAt).format('DD/MM/YYYY');
  const authorName =
    reportTemplateData.createdBy?._id === auth0ClinicianId ? 'you' : reportTemplateData.createdBy?.name || '';

  const templateStatus: Record<ReportTemplateStatus, string> = {
    [ReportTemplateStatus.Draft]: 'Draft',
    [ReportTemplateStatus.Published]: 'Published'
  };

  const allowAction =
    !showSettings &&
    reportTemplateData.status === ReportTemplateStatus.Published &&
    !generateTemplateLoading.loading &&
    !isChangingTemplateDisabled;

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={showSettings ? styles.settingsMenu : styles.settingsMenuHide}>
          <ButtonAlt
            error
            variant={'text'}
            size={'small'}
            className={styles.deleteBtn}
            fab
            icon={'delete'}
            iconVariant={IconVariant.Filled}
            onClick={() => onClickDeleteTemplate(reportTemplateId)}
          />
          <ButtonAlt
            variant={'text'}
            size={'small'}
            className={styles.editBtn}
            fab
            icon={'edit'}
            iconVariant={IconVariant.Filled}
            onClick={() => onClickEditTemplate(reportTemplateId)}
          />
        </div>
        <div
          className={classnames(styles.detailsWrapper, allowAction && styles.cursor)}
          onClick={() => allowAction && onClickSelectTemplate(reportTemplateData)}
        >
          <div className={styles.details}>
            <div className={styles.label}>{reportTemplateData.title}</div>
            <div className={styles.desc}>
              {templateStatus[reportTemplateData.status]} by {authorName} on {updatedDate}
            </div>
          </div>
          {reportTemplateData.status === ReportTemplateStatus.Published && (
            <div className={!showSettings ? styles.selectMenu : styles.selectMenuHide}>
              <ButtonAlt variant={'outlined'} size={'small'} fab icon={'arrow_forward'} disabled={!allowAction} />
            </div>
          )}
        </div>
      </div>
      {generateTemplateLoading.templateId === reportTemplateId && generateTemplateLoading.loading && (
        <div className={styles.overlayContainer}>
          <div className={styles.overlayContent}>
            Applying Template
            <div className={styles.loading}>
              <SwitchLoading circleClass={styles.circle} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportTemplateCard;
