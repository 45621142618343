import { Skeleton } from 'antd';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import ProfileBadge from 'components/v2/ProfileBadge/ProfileBadge';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useState } from 'react';
import { useFetchClinician } from 'utils/hooks/clinician';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import AssignMentorModal from '../AssignMentorModal/AssignMentorModal';
import styles from './MentorInfo.module.scss';

interface MentorInfoProps {
  clientRecordData: clientRecordsInterface;
  mentorId?: string;
  isReadOnly?: boolean;
  onCloseModal: (isSave: boolean) => void;
}

const MentorInfo = ({ clientRecordData, mentorId, isReadOnly, onCloseModal }: MentorInfoProps) => {
  const [showAssignModal, setShowAssignModal] = useState(false);
  const { clinician, isClinicianLoading } = useFetchClinician(mentorId);
  const { isEdgeReceptionistView } = useGetAccountPackageView();
  const isDisabled = isReadOnly || isEdgeReceptionistView;

  return (
    <div className={styles.mentorInfo}>
      <div className={styles.title}>Mentor Assignment</div>
      <div className={styles.info}>
        {isClinicianLoading ? (
          <Skeleton.Input className={styles.loading} active />
        ) : clinician ? (
          <ProfileBadge name={clinician.name} avatar={clinician.avatar} />
        ) : (
          <div>No mentor assigned</div>
        )}
        <ButtonAlt
          className={styles.editBtn}
          contentClassName={styles.buttonContent}
          variant={'text'}
          size={'small'}
          icon={'mode_edit'}
          onClick={() => !isDisabled && setShowAssignModal(true)}
          disabled={isDisabled}
        >
          Edit
        </ButtonAlt>
      </div>
      <AssignMentorModal
        clientRecord={clientRecordData}
        visible={showAssignModal}
        onCancel={(isSave: boolean) => {
          onCloseModal(isSave);
          setShowAssignModal(false);
        }}
      />
    </div>
  );
};

export default MentorInfo;
