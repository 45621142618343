import { notification } from 'antd';
import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { REFERRAL_STATUS_LABELS, Referral, ReferralStatus } from 'interfaces/Referral/Referral';
import AddReferralModal from 'pages/Referrals/components/AddReferralModal/AddReferralModal';
import { FILE_COUNT_LIMIT } from 'pages/Referrals/components/ReferralForm/components/ReferralDocumentForm/ReferralDocumentForm';
import { useEffect, useRef, useState } from 'react';
import {
  useRefreshClientMatchesMutation,
  useUpdateClientRecordIdMutation,
  useUpdateReferralStatusMutation,
  useUploadDocumentsToReferralMutation
} from 'redux/endpoints/clinicianProfileServices/referral';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import NoteModal from '../NoteModal/NoteModal';
import styles from './ActionMenu.module.scss';

interface ActionMenuProps {
  referral: Referral;
  viewMode?: 'client';
}

type ReferralActions = 'changeStatus';

const ActionMenu = ({ referral, viewMode }: ActionMenuProps) => {
  const { accountId } = useGetAccountId();
  const [isExpanded, setIsExpanded] = useState(false);
  const [changeStatusExpand, setChangeStatusExpand] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isShowNoteModal, setIsShowNoteModal] = useState(false);
  const [showConfirmUnlink, setShowConfirmUnlink] = useState(false);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

  const menuNode = useRef<HTMLDivElement>(null);
  const changeStatusSubMenu = useRef<HTMLDivElement>(null);

  const isLinked = !!referral.clientRecordId;
  const isClientView = viewMode === 'client';

  const [uploadDocumentsToReferral] = useUploadDocumentsToReferralMutation();
  const [refreshClientMatches] = useRefreshClientMatchesMutation();
  const [updateStatus] = useUpdateReferralStatusMutation();
  const [updateClientRecordId] = useUpdateClientRecordIdMutation();

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target) || changeStatusSubMenu.current?.contains(event.target)) {
      return;
    }
    handleCloseMenu();
    setIsExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const handleCloseMenu = () => {
    setIsExpanded(false);
    setChangeStatusExpand(false);
    setShowConfirmUnlink(false);
  };

  const handleExpandMenu = () => {
    setIsExpanded(!isExpanded);
  };

  const handleChangeStatus = async (status: ReferralStatus) => {
    handleCloseMenu();

    notification.warning({
      message: 'Updating referral status...',
      closeIcon: <span className={'notify'}>OK</span>
    });

    try {
      await updateStatus({
        accountId,
        referralId: referral._id,
        status
      }).unwrap();

      notification.destroy();
      notification.success({
        message: 'Referral status updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      notification.error({
        message: 'Something went wrong on updating status',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const closeAllExpandedMenu = () => {
    if (changeStatusExpand) {
      setChangeStatusExpand(false);
    }
  };

  const handleExpandSubMenu = (item: ReferralActions) => {
    closeAllExpandedMenu();
    if (item === 'changeStatus') {
      return setChangeStatusExpand(!changeStatusExpand);
    }
  };

  const handleRefreshMatch = async () => {
    try {
      setIsSubmitting(true);
      await refreshClientMatches({ accountId, referralId: referral._id }).unwrap();
      notification.success({
        message: 'Matches refreshed successfully',
        duration: 2
      });
      setTimeout(() => {
        setIsSubmitting(false);
        setIsExpanded(false);
      }, 2000);
    } catch (ex) {
      console.error(ex);
      setIsSubmitting(false);
      notification.error({
        message: 'Failed to refresh matches'
      });
    }
  };

  const handleUploadDocuments = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.onchange = async (event) => {
      const files = (event.target as HTMLInputElement).files;
      if (!files || files.length === 0) {
        return;
      }

      if (files.length > FILE_COUNT_LIMIT) {
        notification.error({
          message: `You can only upload up to ${FILE_COUNT_LIMIT} files.`
        });
        return;
      }

      const formData = new FormData();
      Array.from(files).forEach((file) => formData.append('files', file));

      if (!referral || !referral._id || !referral.accountId) {
        return;
      }

      try {
        setIsSubmitting(true);
        notification.warning({
          message: 'Attaching documents...'
        });

        await uploadDocumentsToReferral({
          accountId: referral.accountId,
          referralId: referral._id,
          files: formData
        }).unwrap();

        setIsSubmitting(false);

        notification.destroy();
        notification.success({
          message: 'Documents uploaded successfully.',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Failed to upload documents.' });

        setIsSubmitting(false);
      }
    };
    input.click();
  };

  const handleUnlinkClient = async () => {
    if (!referral.clientRecordId) {
      notification.error({
        message: 'Failed to unlink client'
      });
      return;
    }

    setShowConfirmUnlink(false);

    try {
      setIsSubmitting(true);

      await updateClientRecordId({
        accountId,
        referralId: referral._id,
        clientRecordId: referral.clientRecordId,
        unlink: 1
      }).unwrap();

      notification.success({
        message: 'Client unlinked successfully',
        duration: 2
      });

      setTimeout(() => {
        setIsSubmitting(false);
        setIsExpanded(false);
      }, 2000);
    } catch (ex) {
      console.error(ex);
      setIsSubmitting(false);
      notification.error({
        message: 'Failed to unlink client'
      });
    }
  };

  return (
    <div ref={menuNode} className={styles.container}>
      <ButtonAlt size="medium" variant="text" fab icon="more_vert" onClick={handleExpandMenu} />
      <div className={styles.menuWrapper}>
        <div className={isExpanded ? styles.menuShow : styles.menuHide}>
          <div className={styles.menuItem}>
            <ThreeDotMenuItem
              labelClassName={styles.menuItemLabel}
              label={isClientView ? 'Edit this referral' : 'View or edit'}
              onClick={() => setShowEditModal(true)}
              disabled={isSubmitting}
              preIcon={isClientView ? 'edit' : 'view_stream'}
            />
          </div>

          <div className={styles.menuItem}>
            <ThreeDotMenuItem
              labelClassName={styles.menuItemLabel}
              label="Change Status"
              disabled={isSubmitting}
              preIcon="task_alt"
              postIcon="navigate_next"
              onClick={() => {
                handleExpandSubMenu('changeStatus');
              }}
            />

            {/* Change status sub menu */}
            <div ref={changeStatusSubMenu} className={changeStatusExpand ? styles.subMenuShow : styles.subMenuHide}>
              {Object.values(ReferralStatus).map(
                (status, index) =>
                  status !== referral.status && (
                    <ThreeDotMenuItem
                      key={index}
                      label={REFERRAL_STATUS_LABELS[status].label}
                      onClick={() => handleChangeStatus(status)}
                    />
                  )
              )}
            </div>
          </div>
          {!isClientView && (
            <div className={styles.menuItem}>
              <ThreeDotMenuItem
                labelClassName={styles.menuItemLabel}
                label="Attach Files"
                onClick={handleUploadDocuments}
                disabled={isSubmitting}
                preIcon="upload"
              />
            </div>
          )}
          {!isClientView && (
            <div className={styles.menuItem}>
              <ThreeDotMenuItem
                labelClassName={styles.menuItemLabel}
                label="Add Quick Note"
                onClick={() => setIsShowNoteModal(true)}
                disabled={isSubmitting}
                preIcon="edit_note"
              />
            </div>
          )}
          {!isLinked && !isClientView && (
            <div className={styles.menuItem}>
              <ThreeDotMenuItem
                labelClassName={styles.menuItemLabel}
                label="Check Matches Again"
                onClick={handleRefreshMatch}
                disabled={isSubmitting}
                preIcon="cached"
              />
            </div>
          )}
          {isLinked && isClientView && (isEdgeAdminView || isEdgeReceptionist) && (
            <div className={styles.menuItem}>
              {!showConfirmUnlink ? (
                <ThreeDotMenuItem
                  labelClassName={styles.menuItemLabel}
                  label="Unlink from client"
                  onClick={() => setShowConfirmUnlink(true)}
                  disabled={isSubmitting}
                  preIcon="link_off"
                />
              ) : (
                <ThreeDotMenuItem
                  className={styles.confirmMenuItem}
                  labelClassName={styles.confirmLabel}
                  label="Confirm unlink?"
                  onClick={handleUnlinkClient}
                  disabled={isSubmitting}
                  postLabelComponent={<strong>YES</strong>}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {showEditModal && (
        <AddReferralModal visible isEditModal referral={referral} onCloseModal={() => setShowEditModal(false)} />
      )}

      {isShowNoteModal && <NoteModal referral={referral} onCloseModal={() => setIsShowNoteModal(false)} />}
    </div>
  );
};

export default ActionMenu;
