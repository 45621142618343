import { Modal } from 'antd';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import SelectClientOrGroup from 'components/SelectClientOrGroup/SelectClientOrGroup';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from './ReportCreateNewModal.module.scss';

interface ReportCreateNewModalProps {
  isShowCreateNewModal: boolean;
  onCloseModal: any;
}

const ReportCreateNewModal = ({ isShowCreateNewModal, onCloseModal }: ReportCreateNewModalProps) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { REPORTS } = useRoutesGenerator();
  const [selectedCId, setSelectedCId] = useState('');
  const [saveTemplateStatus, setSaveTemplateStatus] = useState<'' | 'active' | 'finished'>('');
  const [reportName, setReportName] = useState('');
  const [validation, setValidation] = useState({
    reportName: false,
    selectedClient: false
  });
  const [t] = useTranslation();

  const handleSelectClientRecordId = (clientRecordId: string) => {
    setSelectedCId(clientRecordId);
    setValidation({
      ...validation,
      selectedClient: clientRecordId.length <= 0
    });
  };

  const handleChangeReportName = (val: string) => {
    setReportName(val);
    setValidation({
      ...validation,
      reportName: val.length <= 0
    });
  };

  const onSubmitCreateReport = (e: any) => {
    e.preventDefault();
    setSaveTemplateStatus('active');

    if (reportName.length <= 0 || selectedCId.length <= 0) {
      setValidation({
        reportName: reportName.length <= 0,
        selectedClient: selectedCId.length <= 0
      });
      setSaveTemplateStatus('');
    } else {
      setTimeout(() => {
        setSaveTemplateStatus('finished');
        const reportTemplateId = searchParams.get('reportTemplateId');
        const newSearchParams = new URLSearchParams({
          reportName: encodeURIComponent(reportName),
          clientRecordId: encodeURIComponent(selectedCId),
          ...(reportTemplateId && { reportTemplateId: encodeURIComponent(reportTemplateId) })
        });
        navigate(`${REPORTS.NEW}?${newSearchParams.toString()}`);
      }, 600);
    }
  };

  const onClose = () => {
    searchParams.delete('reportTemplateId');
    setSearchParams(searchParams);
    onCloseModal();
  };

  return (
    <Modal open={isShowCreateNewModal} bodyStyle={{ padding: 0 }} footer={null} onCancel={onClose} forceRender>
      <div className={styles.header}>
        <div className={styles.title}>Create Report</div>
      </div>
      <div className={styles.content} id={'createReportModalId'}>
        <div className={styles.reportNameWrapper}>
          <MaterialInput
            id={'nameOfReport'}
            label={'Name of report'}
            className={styles.reportName}
            value={reportName}
            onChange={(e) => handleChangeReportName(e.target.value)}
            maxLength={30}
          />
          <div className={styles.note}>You can edit this later</div>
          {validation.reportName && <div className={styles.error}>Please enter name of report</div>}
        </div>
        <SelectClientOrGroup
          scrollContainerClassName={styles.scrollContainer}
          defaultParticipationType={ParticipantType.OneToOne}
          hideAddNewClientButton
          handleSelectClientRecord={(selectedClientRecord) =>
            handleSelectClientRecordId(selectedClientRecord?._id || '')
          }
          defaultRecordId={selectedCId}
        />
        {validation.selectedClient && <div className={styles.error}>{t('label.select_client_before_proceed')}</div>}
        <div className={styles.buttonWrapper}>
          <ButtonAlt
            className={styles.createReportBtn}
            status={saveTemplateStatus}
            type="button"
            onClick={(e) => onSubmitCreateReport(e)}
          >
            Start editing this report
          </ButtonAlt>
        </div>
      </div>
    </Modal>
  );
};

export default ReportCreateNewModal;
