import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './ActionMenu.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';
import ProcessAppointment from 'pages/Calendar/components/Modals/EventInformationModal/components/ProcessAppointment/ProcessAppointment';
import { AppointmentClaimType, ServiceDelivered } from 'interfaces/Schedule/Appointment';
import ModalV2 from 'components/ModalV2/ModalV2';
import { resetProcessAppointment } from 'redux/processAppointment/processAppointmentSlice';
import { useAppDispatch } from 'redux/hooks';
import { PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';
import { useGetClinicianProfileByIdQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import { useGetAppointmentTypeByIdQuery } from 'redux/endpoints/scheduleServices/appointmentType';
import {
  ReviewAppointment,
  useProcessAppointmentMutation,
  useResolveAppointmentMutation
} from 'redux/endpoints/scheduleServices/processAppointment';
import { notification } from 'antd';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useNavigate } from 'react-router-dom';

interface ActionMenuProps {
  appointment: ReviewAppointment;
}

export enum PaymentActions {
  FullPayment = 'fullPayment',
  ChargeCancellationFee = 'chargeCancellationFee',
  ChargeRemainingIfRequired = 'chargeRemainingIfRequired',
  FullRefund = 'fullRefund',
  PartialRefund = 'partialRefund',
  None = 'none'
}

const getMenuLabel = (paymentAction: PaymentActions): string => {
  switch (paymentAction) {
    case PaymentActions.FullPayment:
    case PaymentActions.ChargeCancellationFee:
      return 'Accept & charge';
    case PaymentActions.FullRefund:
    case PaymentActions.PartialRefund:
      return 'Accept & refund';
    case PaymentActions.ChargeRemainingIfRequired:
    case PaymentActions.None:
      return 'Accept';
  }
};

export const getPaymentAction = ({
  currentClaimType,
  latestClaimType,
  serviceDelivered,
  waiveCancellationFee,
  isPaid
}: {
  currentClaimType?: AppointmentClaimType;
  latestClaimType?: PendingClaimsFunder;
  serviceDelivered?: ServiceDelivered;
  waiveCancellationFee?: boolean;
  isPaid?: boolean;
}): PaymentActions => {
  if (!serviceDelivered || !currentClaimType) {
    return PaymentActions.None;
  }

  const isChangeBetweenRebateAndOutOfPocket =
    currentClaimType &&
    latestClaimType &&
    [PendingClaimsFunder.REBATE, PendingClaimsFunder.OUT_OF_POCKET].includes(latestClaimType) &&
    [AppointmentClaimType.Rebate, AppointmentClaimType.OutOfPocket].includes(currentClaimType);

  if (
    latestClaimType &&
    latestClaimType.toString() !== currentClaimType.toString() &&
    !isChangeBetweenRebateAndOutOfPocket
  ) {
    if (latestClaimType && [PendingClaimsFunder.REBATE, PendingClaimsFunder.OUT_OF_POCKET].includes(latestClaimType)) {
      switch (serviceDelivered) {
        case ServiceDelivered.Attended:
          return PaymentActions.FullPayment;
        case ServiceDelivered.CancelShortNotice:
        case ServiceDelivered.ClientDidNotAttend:
          return waiveCancellationFee ? PaymentActions.None : PaymentActions.ChargeCancellationFee;
        case ServiceDelivered.ClinicianDidNotAttend:
        case ServiceDelivered.CancelWithNotice:
        case ServiceDelivered.ClinicianCancelWithNotice:
        case ServiceDelivered.ClinicianCancelShortNotice:
          return PaymentActions.None;
      }
    }

    if (
      currentClaimType &&
      [AppointmentClaimType.Rebate, AppointmentClaimType.OutOfPocket].includes(currentClaimType)
    ) {
      switch (serviceDelivered) {
        case ServiceDelivered.Attended:
        case ServiceDelivered.CancelWithNotice:
        case ServiceDelivered.ClinicianCancelShortNotice:
        case ServiceDelivered.ClinicianCancelWithNotice:
        case ServiceDelivered.ClinicianDidNotAttend:
          return PaymentActions.FullRefund;
        case ServiceDelivered.CancelShortNotice:
        case ServiceDelivered.ClientDidNotAttend:
          if (isPaid) {
            return waiveCancellationFee ? PaymentActions.FullRefund : PaymentActions.PartialRefund;
          }
          return PaymentActions.ChargeCancellationFee;
      }
    }
  }

  switch (serviceDelivered) {
    case ServiceDelivered.Attended:
      return PaymentActions.ChargeRemainingIfRequired;
    case ServiceDelivered.CancelShortNotice:
    case ServiceDelivered.ClientDidNotAttend:
      if ([AppointmentClaimType.Rebate, AppointmentClaimType.OutOfPocket].includes(currentClaimType) && isPaid) {
        return waiveCancellationFee ? PaymentActions.FullRefund : PaymentActions.PartialRefund;
      }
      return waiveCancellationFee ? PaymentActions.None : PaymentActions.ChargeCancellationFee;
    case ServiceDelivered.ClinicianDidNotAttend:
    case ServiceDelivered.ClinicianCancelWithNotice:
    case ServiceDelivered.ClinicianCancelShortNotice:
    case ServiceDelivered.CancelWithNotice:
      return [AppointmentClaimType.Rebate, AppointmentClaimType.OutOfPocket].includes(currentClaimType) && isPaid
        ? PaymentActions.FullRefund
        : PaymentActions.None;
  }
};

const ActionMenu = ({ appointment }: ActionMenuProps) => {
  const dispatch = useAppDispatch();
  const menuNode = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { INVOICES } = useRoutesGenerator();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isShowProcessAppointmentModal, setIsShowProcessAppointmentModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSpecialAppointment =
    !appointment.claimType ||
    ![AppointmentClaimType.BulkBill, AppointmentClaimType.Rebate, AppointmentClaimType.OutOfPocket].includes(
      appointment.claimType
    );

  const { data: profile } = useGetClinicianProfileByIdQuery(
    { clinicianId: appointment.clinicianId || '' },
    { skip: !appointment.clinicianId }
  );

  // Prioritize getting the appointment type from requestChanges
  const { data: appointmentType } = useGetAppointmentTypeByIdQuery(
    { id: appointment.requestedChanges?.appointmentTypeId || appointment.sessionTypeId || '' },
    { skip: !(appointment.requestedChanges?.appointmentTypeId || appointment.sessionTypeId) }
  );

  const [processAppointmentAction] = useProcessAppointmentMutation();
  const [resolveAppointmentAction] = useResolveAppointmentMutation();

  const handleCloseMenu = () => {
    setIsExpanded(false);
  };

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target)) {
      return;
    }
    setIsExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const handleExpandMenu = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleProcessAppointmentModal = () => {
    setIsShowProcessAppointmentModal(!isShowProcessAppointmentModal);
  };

  const handleShowProcessAppointment = () => {
    handleCloseMenu();
    toggleProcessAppointmentModal();
  };

  const handleCloseProcessAppointmentModal = () => {
    dispatch(resetProcessAppointment());
    toggleProcessAppointmentModal();
  };

  const paymentAction = useMemo(() => {
    const { requestedChanges, claimType, serviceDelivered, paid, owed } = appointment;
    return getPaymentAction({
      currentClaimType: claimType,
      latestClaimType: requestedChanges?.funder,
      serviceDelivered,
      waiveCancellationFee: requestedChanges?.waiveCancellationFee,
      isPaid: owed === 0 && !!paid && paid > 0
    });
  }, [appointment]);

  const handleAccept = async () => {
    const payload = {
      serviceDelivered: appointment.serviceDelivered,
      appointmentTypeId: appointment.requestedChanges?.appointmentTypeId,
      deliveryType: appointment.requestedChanges?.deliveryType,
      asAdmin: true,
      providerId: appointment.requestedChanges?.providerId,
      funder: appointment.requestedChanges?.funder,
      mbsCode: appointment.requestedChanges?.mbsCode,
      waiveCancellationFee: !!appointment.requestedChanges?.waiveCancellationFee,
      invoiceAmount: appointment.requestedChanges?.invoiceAmount
    };

    try {
      setIsSubmitting(true);
      await processAppointmentAction({ appointmentId: appointment._id, payload }).unwrap();
      notification.success({
        message: 'Appointment processed successfully',
        duration: 2
      });
      setTimeout(() => {
        setIsSubmitting(false);
        setIsExpanded(false);
      }, 2000);
    } catch (ex) {
      console.error(ex);
      setIsSubmitting(false);
    }
  };

  const handleIssueInvoice = () => {
    navigate(`${INVOICES.BASE}/new`, {
      state: { invoice: { clientRecord: { _id: appointment.clientRecord?._id } } }
    });
  };

  const handleMarkAsResolve = async () => {
    //call mark as resolve api
    try {
      setIsSubmitting(true);
      await resolveAppointmentAction({ appointmentId: appointment._id }).unwrap();
      notification.success({
        message: 'Appointment resolved successfully',
        duration: 2
      });
      setTimeout(() => {
        setIsSubmitting(false);
        setIsExpanded(false);
      }, 2000);
    } catch (ex) {
      console.error(ex);
      setIsSubmitting(false);
    }
  };

  return (
    <div ref={menuNode} className={styles.container}>
      <ButtonAlt size="medium" variant="text" fab icon="more_vert" onClick={handleExpandMenu} />
      <div className={styles.menuWrapper}>
        <div className={isExpanded ? styles.menuShow : styles.menuHide}>
          {isSpecialAppointment ? (
            <>
              <div className={styles.menuItem}>
                <ThreeDotMenuItem
                  labelClassName={styles.menuItemLabel}
                  label="Issue invoice"
                  onClick={handleIssueInvoice}
                  disabled={isSubmitting}
                />
              </div>
              <div className={styles.menuItem}>
                <ThreeDotMenuItem
                  labelClassName={styles.menuItemLabel}
                  label="Mark as resolve"
                  onClick={handleMarkAsResolve}
                  disabled={isSubmitting}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.menuItem}>
                <ThreeDotMenuItem
                  labelClassName={styles.menuItemLabel}
                  label={getMenuLabel(paymentAction)}
                  onClick={handleAccept}
                  disabled={isSubmitting}
                />
              </div>
              <div className={styles.menuItem}>
                <ThreeDotMenuItem
                  labelClassName={styles.menuItemLabel}
                  label="Edit"
                  onClick={handleShowProcessAppointment}
                  disabled={isSubmitting}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {isShowProcessAppointmentModal && (
        <ModalV2
          containerClassName={styles.modalContainer}
          isModalOpen={isShowProcessAppointmentModal}
          onModalClose={handleCloseProcessAppointmentModal}
          bodyStyle={{ padding: '0' }}
        >
          <div className={styles.contentContainer}>
            <ProcessAppointment
              mode="review"
              appointment={appointment}
              appointmentType={appointmentType}
              onClose={handleCloseProcessAppointmentModal}
              profile={profile}
              onProcessAppointmentComplete={handleCloseProcessAppointmentModal}
            />
          </div>
        </ModalV2>
      )}
    </div>
  );
};

export default ActionMenu;
