import { IInvoiceTemplate } from 'pages/Invoices/interface';
import { BSTagTypes, billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';

interface GetInvoiceTemplatesRequestPayload {
  accountId: string;
}

interface GetInvoiceTemplatesResponsePayload {
  invoiceTemplates: IInvoiceTemplate[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

interface GetInvoiceTemplateRequestPayload {
  accountId: string;
  invoiceTemplateId: string;
}

interface CreateInvoiceTemplateRequestPayload {
  accountId: string;
  payload: Record<string, unknown>;
}

interface UpdateInvoiceTemplateRequestPayload extends CreateInvoiceTemplateRequestPayload {
  invoiceTemplateId: string;
}

export const getInvoiceTemplatesApiSlice = billingServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceTemplatesList: builder.query<GetInvoiceTemplatesResponsePayload, GetInvoiceTemplatesRequestPayload>({
      query: ({ accountId }) => ({
        url: `/accounts/${accountId}/invoice-templates`
      }),
      providesTags: [BSTagTypes.InvoiceTemplates]
    }),
    getInvoiceTemplate: builder.query<IInvoiceTemplate, GetInvoiceTemplateRequestPayload>({
      query: ({ accountId, invoiceTemplateId }) => ({
        url: `/accounts/${accountId}/invoice-templates/${invoiceTemplateId}`
      }),
      providesTags: (_result, _error, arg) => [{ type: BSTagTypes.InvoiceTemplates, id: arg.invoiceTemplateId }]
    }),
    createInvoiceTemplate: builder.mutation({
      query: ({ accountId, payload }: CreateInvoiceTemplateRequestPayload) => ({
        url: `/accounts/${accountId}/invoice-templates`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [BSTagTypes.InvoiceTemplates]
    }),
    updateInvoiceTemplate: builder.mutation({
      query: ({ accountId, invoiceTemplateId, payload }: UpdateInvoiceTemplateRequestPayload) => ({
        url: `/accounts/${accountId}/invoice-templates/${invoiceTemplateId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [BSTagTypes.InvoiceTemplates],
      async onQueryStarted ({ accountId, invoiceTemplateId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          dispatch(
            getInvoiceTemplatesApiSlice.util.updateQueryData(
              'getInvoiceTemplate',
              { accountId, invoiceTemplateId },
              (draft) => {
                Object.assign(draft, updatedPost);
              }
            )
          );
        } catch (ex) {
          console.error(ex);
        }
      }
    })
  })
});

export const {
  useGetInvoiceTemplatesListQuery,
  useGetInvoiceTemplateQuery,
  useCreateInvoiceTemplateMutation,
  useUpdateInvoiceTemplateMutation
} = getInvoiceTemplatesApiSlice;
