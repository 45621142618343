import Input from 'components/v2/Input/Input';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { useField } from 'formik';
import { Props } from 'react-select';
import styles from './Duration.module.scss';
import { getTimeOptions } from '../../constants';
import classNames from 'classnames';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

const isCustom = (value: string) => value === 'custom';

interface DurationProps {
  inputContainerClass?: string;
  includeFullDayOption?: boolean;
  showEditableCheckbox?: boolean;
}

const Duration = ({ inputContainerClass, includeFullDayOption, showEditableCheckbox }: DurationProps) => {
  const [, { value: participantType }] = useField('participantType');
  const [field] = useField('duration');
  const [customInputProps, customMeta] = useField('customDuration');
  const [, { value: durationEditable }, { setValue: setDurationEditable }] = useField<boolean>('durationEditable');

  const propStyles: Props['styles'] = {
    container: (base) => ({ ...base, margin: 0 }),
    control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: `1px solid ${styles.greyColor}`,
      borderRadius: 0,
      boxShadow: 'none',
      minHeight: 0,
      minWidth: '150px'
    }),
    valueContainer: (base) => ({ ...base, padding: 0 }),
    dropdownIndicator: (base) => ({ ...base, padding: 0 })
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.durationInputContainer, inputContainerClass)}>
        <FormikSelect
          name="duration"
          label={`Duration of ${participantType === ParticipantType.Activity ? 'activity' : 'appointment'}`}
          options={getTimeOptions(includeFullDayOption)}
          styles={propStyles}
          className={styles.selectClass}
          labelClass={styles.labelClass}
          required
        />
        {isCustom(field.value) && (
          <div className={styles.customField}>
            <Input
              error={customMeta.touched ? customMeta.error : ''}
              inputProps={{
                ...customInputProps,
                size: 6
              }}
            />
            <div className={styles.customText}>mins</div>
          </div>
        )}
      </div>
      {showEditableCheckbox && (
        <div className={styles.checkboxContainer}>
          <input
            className={styles.checkbox}
            type="checkbox"
            id={`checkbox-durationEditable`}
            checked={durationEditable}
            onChange={() => setDurationEditable(!durationEditable)}
            value="durationEditable"
          />
          <label className={styles.label} htmlFor={`checkbox-durationEditable`}>
            Allow duration to be customised
          </label>
        </div>
      )}
    </div>
  );
};

export default Duration;
