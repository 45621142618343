import { useCallback, useEffect, useRef, useState } from 'react';
import { notification } from 'antd';
import { clientRecordsInterface, GetClientProfileInterface } from 'interfaces/Clients/clientsRecord';
import {
  getClientsRecord,
  getClientsRecordByAccountId
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import _, { sortBy } from 'lodash';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { getName } from 'utils/general';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { SortType } from 'components/TableList/SortBtn/SortBtn';
import { security } from 'utils/security';

interface FetchClientRecordByClinician {
  clinicianId?: string;
  showAllStatusClient?: boolean;
  perPage: number;
  sortByClientName?: SortType;
  checkClientExist?: (clients: clientRecordsInterface[]) => void;
  searchValue?: string;
}

export const useFetchClientRecordByClinician = ({
  clinicianId,
  showAllStatusClient,
  perPage,
  sortByClientName,
  checkClientExist,
  searchValue
}: FetchClientRecordByClinician) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist, isNormalUserView } = useGetAccountPackageView();

  const [bookableClients, setBookableClients] = useState<clientRecordsInterface[]>([]);
  const [paging, setPaging] = useState<GetClientProfileInterface['paging']>({
    page: 1,
    perPage: perPage,
    totalItem: 0,
    totalPage: 0
  });

  const [isBookableClientsLoading, setIsBookableClientsLoading] = useState(false);
  const [t] = useTranslation();

  const wasCalled = useRef(false);

  const fetchBookablePatients = useCallback(
    async ({
      clinicianId,
      page,
      search,
      sort
    }: {
      clinicianId?: string;
      page?: number;
      search?: string;
      sort?: SortType;
    }) => {
      if (page === 1) {
        setIsBookableClientsLoading(true);
      }
      try {
        const token = await security.getAccessTokenSilently();
        const recordStatusByType = isNormalUserView ? 'active' : 'active,waitlist';
        if ((isEdgeAdminView || isEdgeReceptionist) && accountId) {
          const filterParam = {
            perPage: perPage,
            page: page,
            recordStatus: showAllStatusClient ? undefined : recordStatusByType,
            clinicianId: clinicianId ? clinicianId : undefined,
            sortByClientName: sort || sortByClientName,
            q: search || searchValue
          };
          const qParam = queryString.stringify(filterParam);
          const qString = qParam ? `?${qParam}` : '';
          const clientsData = await getClientsRecordByAccountId(token, accountId, qString);
          const clientsRecordData: GetClientProfileInterface = await clientsData.json();

          const sortingMassage = sortBy(clientsRecordData.clientRecords, (clientObj) =>
            getName(clientObj.clientProfiles[0]).toLowerCase()
          );

          setBookableClients((bookableClients) => {
            const newBookableClients = [...bookableClients, ...sortingMassage];

            if (clinicianId) {
              checkClientExist?.(newBookableClients);
            }

            return newBookableClients;
          });
          setPaging(clientsRecordData.paging);
        } else {
          const queryString = showAllStatusClient ? '' : `?recordStatus=${recordStatusByType}`;
          const callGetActivePatients = await getClientsRecord(token, queryString);
          const data = (await callGetActivePatients.json()) as GetClientProfileInterface;

          setBookableClients(sortBy(data.clientRecords, (patient) => getName(patient.clientProfiles[0]).toLowerCase()));
          setPaging(data.paging);
        }
      } catch (ex) {
        console.error(ex);
        notification.error({ message: t('form.error.failed_to_retrieve_client_record') });
      }

      wasCalled.current = false;
      setIsBookableClientsLoading(false);
    },
    // disabling because checkClientExist will force this to keep rerunning
    // revisit later when not needed any more
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      accountId,
      isEdgeAdminView,
      isEdgeReceptionist,
      showAllStatusClient,
      isNormalUserView,
      t,
      perPage,
      sortByClientName
    ]
  );

  const hasMoreData = paging.totalItem / perPage > paging.page;

  const fetchMoreClientRecords = _.debounce(
    (search?: string) =>
      !isBookableClientsLoading && fetchBookablePatients({ clinicianId, page: paging.page + 1, search: search }),
    500
  );

  const refetchClientRecords = useCallback(
    ({ search }: { search?: string }) => {
      setBookableClients([]);
      fetchBookablePatients({ clinicianId, page: 1, search });
    },
    [clinicianId, fetchBookablePatients]
  );

  const sortClientsByName = (isAsc: boolean) => {
    setBookableClients([]);
    const sort = isAsc ? SortType.asc : SortType.desc;
    fetchBookablePatients({ clinicianId, page: 1, search: searchValue, sort });
  };

  useEffect(() => {
    if (wasCalled.current) {
      return;
    }

    if (!isBookableClientsLoading) {
      wasCalled.current = true;
      refetchClientRecords({});
    }
    // eslint-disable-next-line
  }, [refetchClientRecords]);

  return {
    paging,
    setBookableClients,
    bookableClients,
    isBookableClientsLoading,
    sortClientsByName,
    hasMoreData,
    fetchMoreClientRecords,
    refetchClientRecords
  };
};
