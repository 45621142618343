import React, { useEffect, useRef, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { ColumnConfigType, ColumnSettingsEnum } from 'utils/providers/ClientListColumnsProvider/constants';
import { Modal } from 'antd';
import styles from './ClientListColumnSettingsModal.module.scss';
import CheckBox from 'components/CheckBox/CheckBox';
import { Formik, FormikProps } from 'formik';
import ClientListColumnsDropdown from './components/ClientListColumnsDropdown/ClientListColumnsDropdown';
import * as yup from 'yup';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import {
  getInitialColumnSettingsDropdownOptions,
  getListOfFilterNeedReset,
  getNewFilter
} from './helpers/getInitialColumnSettingsDropdownOptions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectFilters,
  selectIsSettingModalOpen,
  setFilters,
  setIsSettingModalOpen
} from 'redux/clients/clientListSlice';
import { selectSetting, setSetting } from 'redux/clients/clientListColumnsSettingsSlice';
import { storeColumnSettingToLocalStorage } from 'pages/Client/components/ClientListContent/components/ClientList/components/ClientListHeader/helper';

const columnsValidationSchema = yup.object({
  columns: yup
    .array(
      yup.object({
        active: yup.bool(),
        id: yup.string().when('active', {
          is: true,
          then: yup.string().not(Object.values(ColumnSettingsEnum.EMPTY)),
          otherwise: yup.string().oneOf(Object.values(ColumnSettingsEnum))
        })
      })
    )
    .length(7)
});

const ClientListColumnSettingsModal = () => {
  const dispatch = useAppDispatch();
  const { currentView } = useGetAccountPackageView();

  const initialDropdownOptions = getInitialColumnSettingsDropdownOptions(currentView);

  const isOpen = useAppSelector(selectIsSettingModalOpen);
  const initialColumnSettings = useAppSelector(selectSetting);
  const filters = useAppSelector(selectFilters);

  const formikRef = useRef<FormikProps<{ columns: ColumnConfigType[] }>>(null);
  const [avatar, setAvatar] = useState<boolean>(initialColumnSettings.clientName?.avatar);
  const [refId, setRefId] = useState<boolean>(initialColumnSettings.clientName?.refId);
  const [isLengthValid, setIsLengthValid] = useState(true);

  useEffect(() => {
    if (initialColumnSettings.clientName) {
      setAvatar(initialColumnSettings.clientName.avatar);
      setRefId(initialColumnSettings.clientName.refId);
    }
  }, [initialColumnSettings.clientName]);

  const handleChangeClientNameConfig = (e: React.ChangeEvent<HTMLInputElement>, type: 'avatar' | 'refId') => {
    if (type === 'avatar') {
      setAvatar(e.target.checked);
    } else {
      setRefId(e.target.checked);
    }
  };

  const handleChangeCheckbox = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    formikRef.current?.setFieldValue(`columns.${index}.active`, isChecked);
    if (!isChecked) {
      formikRef.current?.setFieldValue(`columns.${index}.id`, ColumnSettingsEnum.EMPTY);
    }
  };

  const onSave = ({ columns }: { columns: ColumnConfigType[] }) => {
    const activeColumnsNum = columns.filter((column) => column.active).length;
    if (activeColumnsNum < 4) {
      return setIsLengthValid(false);
    }
    setIsLengthValid(true);

    // Reset list filters if the column is inactive
    const needToRest = getListOfFilterNeedReset(initialColumnSettings.columns, columns);
    if (needToRest.length > 0) {
      dispatch(setFilters(getNewFilter(filters, needToRest)));
    }

    storeColumnSettingToLocalStorage({
      ...initialColumnSettings,
      clientName: {
        avatar,
        refId
      },
      columns
    });
    dispatch(
      setSetting({
        ...initialColumnSettings,
        clientName: {
          avatar,
          refId
        },
        columns
      })
    );
    dispatch(setIsSettingModalOpen(false));
  };

  return (
    <Modal
      bodyStyle={{
        padding: 0
      }}
      width={800}
      open={isOpen}
      onCancel={() => dispatch(setIsSettingModalOpen(false))}
      destroyOnClose
      closable={false}
      title={
        <div className={styles.headerContainer}>
          <div className={styles.modalHeader}>
            <h2>Customise Columns</h2>
            <p>Choose the data fields to display for each client in the list view.</p>
          </div>
          <div className={styles.closeButton} onClick={() => dispatch(setIsSettingModalOpen(false))}>
            <span className="material-icons-outlined">close</span>
          </div>
        </div>
      }
      footer={null}
      className={styles.modalContainer}
    >
      <div className={styles.modalBody}>
        <table>
          <thead>
            <tr>
              <th className={styles.columnPosition}>COLUMN POSITION</th>
              <th className={styles.setTo} />
              <th className={styles.tacklitData}>TACKLIT DATA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.columnPositionBody}>
                <div>
                  <CheckBox className={styles.checkbox} id={'clientName'} value disabled onChange={(e) => {}} />
                  <span>1</span>
                </div>
              </td>
              <td className={styles.setTo}>set to</td>
              <td className={styles.tacklitData}>
                <div className={styles.clientName}>
                  <p>Client Name | </p>
                  <CheckBox
                    className={styles.checkbox}
                    labelClassName={styles.labelCheckbox}
                    id={'avatar'}
                    value={avatar}
                    onChange={(e) => handleChangeClientNameConfig(e, 'avatar')}
                    label="Avatar"
                    useT23style
                  />
                  <p> | </p>
                  <CheckBox
                    className={styles.checkbox}
                    labelClassName={styles.labelCheckbox}
                    id={'refId'}
                    value={refId}
                    onChange={(e) => handleChangeClientNameConfig(e, 'refId')}
                    label="Ref ID"
                    useT23style
                  />
                </div>
              </td>
            </tr>
            <Formik
              innerRef={formikRef}
              initialValues={{ columns: initialColumnSettings.columns }}
              validationSchema={columnsValidationSchema}
              onSubmit={onSave}
            >
              {({ values }) =>
                values.columns.map((column, idx) => (
                  <tr key={`tr_${idx}`}>
                    <td className={styles.columnPositionBody}>
                      <div>
                        <CheckBox
                          className={styles.checkbox}
                          id={column.id}
                          value={column.active}
                          onChange={(e) => handleChangeCheckbox(idx, e)}
                          useT23style
                        />
                        <span>{idx + 2}</span>
                      </div>
                    </td>
                    <td className={styles.setTo}>set to</td>
                    <td className={styles.tacklitData}>
                      <ClientListColumnsDropdown index={idx} column={column} initialOptions={initialDropdownOptions} />
                    </td>
                  </tr>
                ))
              }
            </Formik>
          </tbody>
        </table>
        {!isLengthValid && <p className={styles.error}>Minimum 5 active columns required</p>}
        <div className={styles.modalFooter}>
          <ButtonAlt type="submit" onClick={() => formikRef.current?.handleSubmit()}>
            Update Client List View
          </ButtonAlt>
        </div>
      </div>
    </Modal>
  );
};

export default ClientListColumnSettingsModal;
