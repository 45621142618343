import classnames from 'classnames';
import ChildOrYoungClientForm from 'components/AddPatientModalV2/formType/ChildForm/components/ChildClientForm/ChildClientForm';
import ChildGuardianForm from 'components/AddPatientModalV2/formType/ChildForm/components/ChildGuardianForm/ChildGuardianForm';
import InfoCTABox from 'components/InfoCTABox/InfoCTABox';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import {
  ClientAddress,
  clientProfilesInterface,
  ClientRecordType,
  RecordStatus
} from 'interfaces/Clients/clientsRecord';
import { debounce } from 'lodash';
import { TIMEZONE_OPTIONS } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/ProfileWorkingSchedule/ProfileWorkingSchedule';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccessToken } from 'utils/hooks/token';
import { v4 as uuid } from 'uuid';
import { validateCaseId } from '../../../AddPatientModalV2/formType/AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import ProfileStatusButtons from '../../components/profileStatusButtons';
import { EditClientDetails, editClientForm } from '../../EditClientModalInterface';
import styles from './EditChildAndYoungPersonForm.module.scss';

interface EditChildOrYoungFormProps {
  clientRecordType: 'child' | 'youngPerson';
  clientDetail: editClientForm['clientProfiles'];
  onChangeClientField: any;
  checkValidation: boolean;
  submitStatus: '' | 'active' | 'finished';
  caseIdValue: editClientForm['caseId'];
  onChangeCaseId: (value: editClientForm['caseId']) => void;
  onClickSubmit: (options?: { withTriage?: boolean }) => void;
  excludeCaseIdChecking?: string;
  excludeEmailCheckingGroup?: string[];
  profileType: clientProfilesInterface['profileType'];
  onChangeProfileType: (val: clientProfilesInterface['profileType']) => void;
  recordStatus: editClientForm['recordStatus'];
  onStatusProfileChange: (val: 'active' | 'waitlist' | 'closed') => void;
  onClickSaveClosedClient: () => void;
  showOnboardingCTA: boolean;
  noUnsavedChanges?: boolean;
  timeZone: string;
  onChangeTimeZone: (value: editClientForm['timeZone']) => void;
  clientAddress: ClientAddress;
  onChangeClientAddress: (address: ClientAddress) => void;
  onPlaceSelected: (address: ClientAddress) => void;
}

const maxGuardianField = 5;

const EditChildAndYoungPersonForm = ({
  clientRecordType,
  clientDetail,
  onChangeClientField,
  checkValidation,
  submitStatus,
  caseIdValue,
  onChangeCaseId,
  onClickSubmit,
  excludeCaseIdChecking,
  excludeEmailCheckingGroup,
  recordStatus,
  onStatusProfileChange,
  onClickSaveClosedClient,
  profileType,
  showOnboardingCTA,
  noUnsavedChanges,
  timeZone,
  onChangeTimeZone,
  clientAddress,
  onChangeClientAddress,
  onPlaceSelected
}: EditChildOrYoungFormProps) => {
  const { token } = useGetAccessToken();
  const { isClientTimeZoneEnabled } = useGetFeatureToggle();

  const [duplicateCaseId, setDuplicateCaseId] = useState(false);
  const [isCheckingCaseIdDuplicate, setIsCheckingCaseIdDuplicate] = useState(false);

  const isYoungPersonRecordType = clientRecordType === ClientRecordType.YoungPerson;

  useEffect(() => {
    const isSameCaseIdValue = excludeCaseIdChecking === caseIdValue;
    if (checkValidation && caseIdValue && caseIdValue.length > 0 && !isSameCaseIdValue) {
      validateDuplicateCaseId(caseIdValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const handleChangeClientField = (val: EditClientDetails) => {
    const newClientField = [];
    for (let obj of clientDetail) {
      newClientField.push({
        ...obj,
        _id: obj._id,
        firstName: obj._id === val._id ? val.firstName : obj.firstName,
        lastName: obj._id === val._id ? val.lastName : obj.lastName,
        role: obj._id === val._id ? val.role : obj.role,
        isPrimaryContact: obj._id === val._id ? val.isPrimaryContact : obj.isPrimaryContact,
        ...(obj.role &&
          ['child', 'youngPerson'].includes(obj.role) && {
            dateOfBirth: obj._id === val._id ? val.dateOfBirth : obj.dateOfBirth
          }),
        ...(obj.role &&
          obj.role !== 'child' && {
            email: obj._id === val._id ? val.email : obj.email,
            mobileNumber: obj._id === val._id ? val.mobileNumber : obj.mobileNumber,
            isEmailThirdParty: obj._id === val._id ? val.isEmailThirdParty : obj.isEmailThirdParty,
            isMobileNumberThirdParty: obj._id === val._id ? val.isMobileNumberThirdParty : obj.isMobileNumberThirdParty
          }),
        communicationPreference: obj._id === val._id ? val.communicationPreference : obj.communicationPreference
      });
    }
    onChangeClientField(newClientField);
  };

  const debouncedCheckCaseIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const resDupCaseId = await validateCaseId(token, value);
        setDuplicateCaseId(resDupCaseId.statusCode !== 200);
        setIsCheckingCaseIdDuplicate(false);
      }, 1000),
    [token]
  );

  const validateDuplicateCaseId = async (newCaseIdValue: string) => {
    if (token) {
      setIsCheckingCaseIdDuplicate(true);
      await debouncedCheckCaseIdDuplicate(newCaseIdValue);
    }
  };

  const handleCaseIdChange = async (newCaseIdValue: string) => {
    onChangeCaseId(newCaseIdValue);
    const isSameCaseIdValue = excludeCaseIdChecking === newCaseIdValue;
    if (checkValidation && newCaseIdValue.length > 0 && !isSameCaseIdValue) {
      await validateDuplicateCaseId(newCaseIdValue);
    } else {
      setDuplicateCaseId(false);
    }
  };

  const handleAddNewGuardian = () => {
    if (clientDetail.length <= maxGuardianField) {
      const newClientProfile = [
        ...clientDetail,
        {
          _id: uuid(),
          isNewContact: true,
          firstName: '',
          lastName: '',
          email: '',
          mobileNumber: '',
          isPrimaryContact: false,
          role: ''
        }
      ];
      onChangeClientField(newClientProfile);
    }
  };

  /**
   * Primary Contact Checkbox button for guardian:
   * For Young Person Full Profile and Young Person Record, button is  enabled,
   * For Child, button is disabled for the first guardian,
   */
  const disablePrimaryContactBtn = (i: number) => (isYoungPersonRecordType ? false : i === 0);

  const nonGuardianRoles = ['child', 'youngPerson'];
  const totalGuardian = clientDetail.filter(({ role }) => role && !nonGuardianRoles.includes(role)).length;
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.subTitle}>PROFILE STATUS</div>
        <ProfileStatusButtons
          value={recordStatus}
          onChange={onStatusProfileChange}
          disableWaitlistButton={profileType === 'full'}
          noUnsavedChanges={noUnsavedChanges}
        />
      </div>
      {showOnboardingCTA && (
        <InfoCTABox ctaOnClick={() => onClickSubmit({ withTriage: true })} ctaDisabled={submitStatus !== ''} />
      )}
      {isClientTimeZoneEnabled && (
        <MaterialSelect
          className={styles.clientTimeZoneSelect}
          id={'timeZone'}
          label={'CLIENT TIMEZONE'}
          isSearchable={false}
          options={TIMEZONE_OPTIONS}
          value={timeZone}
          onChange={(value: string) => onChangeTimeZone(value)}
        />
      )}
      {recordStatus !== RecordStatus.Closed && (
        <>
          <div className={classnames(styles.fieldContainer, checkValidation && duplicateCaseId && styles.fieldError)}>
            <MaterialInput
              id={'clientCaseId'}
              label={t('form.client_reference')}
              onChange={(e) => handleCaseIdChange(e.target.value)}
              isLoading={isCheckingCaseIdDuplicate}
              value={caseIdValue}
              maxLength={20}
              required
            />
            {checkValidation && duplicateCaseId && (
              <div className={styles.fieldError}>{t('form.error.duplicate_client_reference')}</div>
            )}
          </div>
          <ChildOrYoungClientForm
            id={'childField'}
            recordType={clientRecordType}
            profileType={profileType}
            clientDetail={clientDetail.find((obj) => obj.role === 'child') || clientDetail[0]}
            onChangeClientField={handleChangeClientField}
            checkValidation={checkValidation}
            excludeEmailCheckingGroup={excludeEmailCheckingGroup}
            clientAddress={clientAddress}
            onChangeClientAddress={onChangeClientAddress}
            onPlaceSelected={onPlaceSelected}
          />
          {/* TODO
      <EditProfileType recordOnly={false} profileTypeProps={profileType} onChangeProfileTypeProps={onChangeProfileType} /> */}
          {clientDetail
            .filter(({ role }) => !role || !nonGuardianRoles.includes(role))
            .map((obj, index) => (
              <div className={styles.guardianContainer} key={index}>
                <div className={styles.guardianHeader}>
                  <div className={styles.guardianTitle}>PARENT OR GUARDIAN {totalGuardian > 1 && index + 1}</div>
                </div>
                <ChildGuardianForm
                  id={`Guardian${index}`}
                  key={index}
                  checkValidation={checkValidation}
                  clientDetail={obj}
                  disablePrimaryContactBtn={disablePrimaryContactBtn(index)}
                  onChangeClientField={handleChangeClientField}
                  updateDetail={clientDetail.length}
                />
              </div>
            ))}
          {clientDetail.length <= maxGuardianField && (
            <div className={styles.addGuardianBtn} onClick={() => handleAddNewGuardian()}>
              <i className={`material-icons-outlined ${styles.icon}`}>add</i>
              Add another parent or guardian
            </div>
          )}
        </>
      )}
      <div className={styles.buttonContainer}>
        <ButtonAlt
          status={submitStatus}
          disabled={submitStatus !== ''}
          onClick={recordStatus !== RecordStatus.Closed ? () => onClickSubmit() : onClickSaveClosedClient}
        >
          Save
        </ButtonAlt>
      </div>
    </div>
  );
};

export default EditChildAndYoungPersonForm;
