import { useMemo, useState } from 'react';
import styles from './CaseNoteDetailForm.module.scss';
import { GroupCaseNoteInterface } from '../../interfaces';
import CaseNoteHeaderOption from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteHeaderOption/CaseNoteHeaderOption';
import _, { debounce } from 'lodash';
import WidgetTextEditor2 from 'components/WidgetTextEditor2/WidgetTextEditor2';
import GroupCaseNoteAttachment from '../GroupCaseNoteAttachment/GroupCaseNoteAttachment';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { CaseNoteTemplate } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import * as Yup from 'yup';

interface CaseNoteDetailFormProps {
  groupId: string;
  isSubmitting: boolean;
  isTemplateListActive: boolean;
  noteId: string;
  isStartEditing: boolean;
  setIsStartEditing: React.Dispatch<React.SetStateAction<boolean>>;
  token: string;
  formData: GroupCaseNoteInterface;
  saveButtonStatus: '' | 'active' | 'finished';
  onSubmit: any;
  onValueChange: (b: boolean) => void;
  safeguardingAlertExistInFields: boolean;
  handleSelectTemplate: (id: string, confirm?: true) => Promise<void>;
  templateList?: CaseNoteTemplate[];
  currentTemplate?: CaseNoteTemplate;
}

const CaseNoteDetailForm = ({
  groupId,
  formData,
  isSubmitting,
  isTemplateListActive,
  saveButtonStatus,
  onSubmit,
  noteId,
  isStartEditing,
  setIsStartEditing,
  token,
  onValueChange,
  safeguardingAlertExistInFields,
  handleSelectTemplate,
  templateList,
  currentTemplate
}: CaseNoteDetailFormProps) => {
  const { _id, ...groupCaseNoteInitialData } = formData;
  const [caseNoteDetails, setCaseNoteDetails] = useState(groupCaseNoteInitialData);
  const [caseNoteBody, setCaseNoteBody] = useState(groupCaseNoteInitialData.body);
  const [isShowError, setIsShowError] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [isPrivateInput, setIsPrivateInput] = useState(false);

  const debouncedSave = useMemo(
    () =>
      debounce(
        async (caseNote, startEditingVal) => {
          const safeguarding =
            !caseNote.safeguardingAlert ||
            (caseNote.safeguardingAlert && caseNote.safeguardingReason && caseNote.safeguardingReason.length > 1);
          if (
            validateField(caseNote) &&
            safeguarding &&
            caseNote.title.length > 0 &&
            !isSubmitting &&
            startEditingVal
          ) {
            await onSubmit(caseNote, true);
          }
        },
        noteId === 'new' ? 1000 : 2000
      ),
    // eslint-disable-next-line
    [noteId, isSubmitting, onSubmit]
  );

  const validateField = (caseNoteValue: Omit<GroupCaseNoteInterface, '_id'>) => {
    const mandatoryFields = caseNoteValue.fields.filter((obj) => obj.mandatory && obj.name !== 'Safeguarding Alert');

    const areMandatoryFieldsValid = !mandatoryFields.some((obj) =>
      obj.type !== 'multipleChoiceCount'
        ? !obj.value
        : obj.options.filter((countObj: any) => countObj.count > 0).length <= 0
    );

    const areNumberFieldsValid = !mandatoryFields.some((obj) => obj.type === 'number' && !obj.value?.match('^[0-9]*$'));

    const areEmailFieldsValid = !caseNoteValue.fields.some(
      (obj) => obj.type === 'email' && !Yup.string().email().isValidSync(obj.value)
    );

    const arePhoneFieldsValid = !caseNoteValue.fields.some(
      (obj) =>
        obj.type === 'phone' &&
        !Yup.string()
          .matches(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g)
          .isValidSync(obj.value)
    );

    const isSafeguardingMandatory =
      (safeguardingAlertExistInFields &&
        caseNoteValue.fields.find((obj) => obj.name === 'Safeguarding Alert')?.mandatory === true) ||
      false;

    const isSafeguardingValid =
      ((isSafeguardingMandatory && caseNoteValue && typeof caseNoteValue.safeguardingAlert === 'boolean') ||
        !isSafeguardingMandatory) &&
      (!caseNoteValue.safeguardingAlert ||
        (caseNoteValue.safeguardingAlert && !_.isEmpty(caseNoteValue.safeguardingReason)));

    return (
      caseNoteValue.title.length > 0 &&
      isSafeguardingValid &&
      areMandatoryFieldsValid &&
      areNumberFieldsValid &&
      areEmailFieldsValid &&
      arePhoneFieldsValid
    );
  };

  const onChangeCaseNoteBody = (value: string) => {
    setIsStartEditing(true);
    setCaseNoteBody(value);
    const caseNoteObj = {
      ...caseNoteDetails,
      body: value
    };
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, isStartEditing);
    }
  };

  const handleTitleChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      title: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, isStartEditing);
    }
  };

  const handleFolderChange = (val: boolean) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      isPrivate: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, isStartEditing);
    }
  };

  const handleSafeguardingAlertChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      safeguardingAlert: val,
      safeguardingReason: val ? caseNoteDetails.safeguardingReason : '',
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, isStartEditing);
    }
  };

  const handleSafegaurdingReasonChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      safeguardingReason: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, isStartEditing);
    }
  };

  const handleFieldChange = (val: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      fields: val,
      body: caseNoteBody
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
      debouncedSave(caseNoteObj, isStartEditing);
    }
  };

  const handleAttachmentUploadStatus = (uploadStatus: boolean) => {
    setUploadStatus(uploadStatus);
  };

  const handleAttachmentChange = async (attachmentVal: any) => {
    setIsStartEditing(true);
    const caseNoteObj = {
      ...caseNoteDetails,
      body: caseNoteBody,
      attachments: attachmentVal
    };
    setCaseNoteDetails(caseNoteObj);
    if (!isSubmitting) {
      onValueChange(true);
    }
  };

  const handleSubmit = () => {
    const checkMandatoryFill = validateField(caseNoteDetails);
    setIsShowError(!checkMandatoryFill);
    if (checkMandatoryFill && caseNoteDetails.title) {
      const caseNoteObj = {
        ...caseNoteDetails,
        body: caseNoteBody
      };
      onSubmit(caseNoteObj, false);
    }
  };

  return (
    <>
      <CaseNoteHeaderOption
        noteId={noteId}
        recordId={groupId}
        isGroup
        fieldsData={caseNoteDetails.fields}
        safeguardingAlert={caseNoteDetails.safeguardingAlert}
        safeguardingReason={caseNoteDetails.safeguardingReason}
        title={caseNoteDetails.title}
        onChangeFieldValue={handleFieldChange}
        onChangeTitle={handleTitleChange}
        onChangeSafeguardingAlert={handleSafeguardingAlertChange}
        onChangeSafeguardingReason={handleSafegaurdingReasonChange}
        isValidated={isShowError}
        isTemplateListActive={isTemplateListActive}
        handleSelectTemplate={handleSelectTemplate}
        currentTemplate={currentTemplate}
        templateList={templateList}
        onChangeFolder={handleFolderChange}
        isPrivateInput={isPrivateInput}
        setIsPrivateInput={setIsPrivateInput}
      />
      <div className={styles.editor}>
        {/* <WidgetTextEditor
          className="case-notes-editor"
          containerClass={styles.textEditorContainer}
          value={caseNoteBody}
          onChangeValue={(value: string) => onChangeCaseNoteBody(value)}
          fullHeightEditor
        /> */}
        <WidgetTextEditor2
          minHeight={350}
          height={'auto'}
          value={caseNoteBody}
          className="case-notes-editor"
          onChange={onChangeCaseNoteBody}
        />
        <GroupCaseNoteAttachment
          token={token}
          groupId={groupId}
          caseNoteAttachmentData={caseNoteDetails.attachments}
          uploadStatus={handleAttachmentUploadStatus}
          onChangeCaseNoteAttachment={handleAttachmentChange}
        />
        <div className={styles.saveButtonContainer}>
          <ButtonAlt
            className={styles.saveButton}
            type="submit"
            status={saveButtonStatus}
            disabled={uploadStatus || isSubmitting}
            onClick={!isSubmitting ? handleSubmit : undefined}
            loadingWord={uploadStatus ? 'Uploading...' : isSubmitting ? 'Saving...' : ''}
          >
            Save Note
          </ButtonAlt>
        </div>
      </div>
    </>
  );
};

export default CaseNoteDetailForm;
