import { CLIENT_LIST_COLUMNS_SETTINGS } from 'localStorageNames';
import {
  ClientListColumnsSettings,
  defaultColumnSettings,
  UserViewEnum
} from 'utils/providers/ClientListColumnsProvider/constants';

export const initColumnSettings = (clinicianId: string, currentView: UserViewEnum) => {
  const columnSettingsStr = window.localStorage.getItem(CLIENT_LIST_COLUMNS_SETTINGS);
  if (columnSettingsStr) {
    try {
      const columnSettings: ClientListColumnsSettings[] = JSON.parse(columnSettingsStr);
      const foundSetting = columnSettings.find((setting) => setting.id === clinicianId && setting.view === currentView);
      if (foundSetting) {
        return foundSetting;
      } else {
        window.localStorage.setItem(
          CLIENT_LIST_COLUMNS_SETTINGS,
          JSON.stringify([...columnSettings, { ...defaultColumnSettings[currentView], id: clinicianId }])
        );
        return { ...defaultColumnSettings[currentView], id: clinicianId };
      }
    } catch (error) {
      console.error(error);
    }
  }
  window.localStorage.setItem(
    CLIENT_LIST_COLUMNS_SETTINGS,
    JSON.stringify([{ ...defaultColumnSettings[currentView], id: clinicianId }])
  );
  return { ...defaultColumnSettings[currentView], id: clinicianId };
};

export const storeColumnSettingToLocalStorage = (columnSetting: ClientListColumnsSettings) => {
  const columnSettingsStr = window.localStorage.getItem(CLIENT_LIST_COLUMNS_SETTINGS);
  if (columnSettingsStr) {
    try {
      const columnSettings: ClientListColumnsSettings[] = JSON.parse(columnSettingsStr);
      const foundSetting = columnSettings.find(
        (setting) => setting.id === columnSetting.id && setting.view === columnSetting.view
      );
      if (foundSetting) {
        const newSettings = columnSettings.map((setting) => {
          if (setting.id === columnSetting.id && setting.view === columnSetting.view) {
            return { ...setting, clientName: columnSetting.clientName, columns: columnSetting.columns };
          } else return setting;
        });
        window.localStorage.setItem(CLIENT_LIST_COLUMNS_SETTINGS, JSON.stringify(newSettings));
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }
  window.localStorage.setItem(CLIENT_LIST_COLUMNS_SETTINGS, JSON.stringify([columnSetting]));
};

export const getColumnNameFromId = (columnId: string) => {
  const rawName = columnId.replace(/([A-Z])/g, ' $1');
  return `${rawName.charAt(0).toUpperCase()}${rawName.slice(1)}`;
};
