import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { getGroupActivityFeed } from 'utils/http/ActivityFeedService/activities';
import { GroupActivity } from '../GroupActivity';
import { security } from 'utils/security';

export const useListGroupActivityFeed = (groupId: string, selectedFilter: string) => {
  const { accountId } = useGetAccountId();

  const [fetching, setFetching] = useState(false);
  const [activityFeed, setActivityFeed] = useState<GroupActivity[]>([]);
  const [hasMoreActivity, setHasMoreActivity] = useState(true);
  const [lastActivityId, setLastActivityId] = useState('');

  const pageSize = 20;

  const fetchActivityFeed = useCallback(
    async (fetchNew: boolean) => {
      try {
        const token = await security.getAccessTokenSilently();

        const callGetPatientActivityByPatientId = await getGroupActivityFeed(
          token,
          accountId,
          groupId,
          selectedFilter,
          pageSize,
          fetchNew ? '' : lastActivityId
        );
        const { data, count, nextActivityId } = await callGetPatientActivityByPatientId.json();

        if (fetchNew) {
          setActivityFeed(data);
        } else {
          setActivityFeed((activityFeed) => [...activityFeed, ...data]);
        }
        setHasMoreActivity(count === pageSize);
        setLastActivityId(nextActivityId || '');
      } catch (e) {
        console.error(e);
        notification.error({ message: "Some thing went wrong while trying to fetch this patient's activity" });
      }
    },
    [accountId, groupId, lastActivityId, selectedFilter]
  );

  useEffect(() => {
    setFetching(true);
    fetchActivityFeed(true).then(() => {
      setFetching(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  return { fetching, activityFeed, hasMoreActivity, fetchActivityFeed };
};
