import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { security } from 'utils/security';

export enum BSTagTypes {
  PendingClaims = 'Pending Claims',
  SubmittedClaims = 'Submitted Claims',
  InvoiceTemplates = 'Invoice Templates',
  PaymentMethods = 'Payment Methods',
  Invoice = 'Invoice',
  Invoices = 'Invoices',
  MyMedicareProvider = 'My Medicare Provider',
  ClinicianMedicareProvider = 'Clinician Medicare Provider',
  InvoiceSummary = 'Invoice Summary',
  InvoicePayments = 'Invoice Payments',
  WriteOffInvoices = 'Write Off Invoices',
  InvoiceWithType = 'Invoice With Type',
  InvoiceSetting = 'Invoice Setting'
}

export const billingServicesApiSlice = createApi({
  reducerPath: 'billingServices',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BILLING_SERVICE_URL,
    prepareHeaders: async (headers) => {
      const token = await security.getAccessTokenSilently();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: Object.values(BSTagTypes),
  endpoints: () => ({})
});
