import AvailableTimeSelect from 'components/v2/AvailableTimeSelect/AvailableTimeSelect';
import { SelectedAppointmentSlot } from 'components/v2/AvailableTimeSelect/components/DayCard/DayCard';
import { ClinicianListInterface } from 'components/v2/ClinicianSelect/ClinicianSelect';
import CustomiseSchedule from 'components/v2/CustomiseSchedule/CustomiseSchedule';
import DeliveryModeSelect from 'components/v2/DeliveryModeSelect/DeliveryModeSelect';
import FormSection from 'components/v2/FormSection/FormSection';
import { Formik } from 'formik';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { CreatedAppointmentType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import _ from 'lodash';
import { useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import AppointmentFooter from '../AppointmentFooter/AppointmentFooter';
import RecurringAppointment from './components/RecurringAppointment/RecurringAppointment';
import RoomOption from './components/RoomOption/RoomOption';
import { appointmentSchema, generateInitialValues, initialValues } from './constants';
import styles from './ScheduleForm.module.scss';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { ClientRecordListResponse } from 'interfaces/Clients/clientRecordNew';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';
import { defaultTimezone as systemTimezone } from 'utils/hooks/GetTimezones/getTimezones';

type ScheduleFormProps = {
  clinician?: Partial<ClinicianListInterface>;
  appointmentType?: CreatedAppointmentType;
  processing: '' | 'active' | 'finished';
  onSubmit: (values: any) => void;
  selectedClientRecord?: clientRecordsInterface | ClientRecordListResponse['clientRecords'][number];
  selectedParticipationType: ParticipantType;
  selectedGroup: GroupsFromAPI;
  defaultTimeZone?: string;
};

const ScheduleForm = ({
  selectedClientRecord,
  selectedParticipationType,
  selectedGroup,
  clinician,
  appointmentType,
  processing = '',
  defaultTimeZone,
  onSubmit
}: ScheduleFormProps) => {
  const { isEdgeUser } = useGetAccountPackageView();
  const { accountSettings } = useGetAccountSettings();
  const [timeslot, setTimeslot] = useState<SelectedAppointmentSlot>();
  const [isCustomising, setIsCustomising] = useState<boolean>(false);
  const timeZone = selectedGroup._id
    ? accountSettings?.timezone || systemTimezone
    : clinician?.workTimeZone || defaultTimeZone;

  const handleSubmit = (values: typeof initialValues) => {
    onSubmit({ ...values, ...timeslot, isCustomising });
  };

  return !appointmentType ? (
    <></>
  ) : (
    <Formik
      initialValues={generateInitialValues(appointmentType)}
      validationSchema={appointmentSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, submitForm }) => (
        <>
          <div className={styles.container}>
            {isCustomising ? (
              <CustomiseSchedule
                timeslot={timeslot}
                onChangeSchedule={setTimeslot}
                setIsCustomising={setIsCustomising}
                appointmentType={appointmentType}
                timeZone={timeZone}
              />
            ) : (
              <AvailableTimeSelect
                selectedClinicianId={clinician?._id}
                appointmentTypeId={appointmentType._id}
                appointmentTypeName={appointmentType.name}
                selectedSlot={timeslot}
                onSelectSlot={setTimeslot}
                setIsCustomising={setIsCustomising}
                containerClassName={styles.timeSelectWrapper}
                selectedGroup={selectedGroup}
                timeZone={timeZone}
              />
            )}

            {(!_.isEmpty(timeslot) || isCustomising) && (
              <>
                <RecurringAppointment
                  date={values.date}
                  occurrences={values.occurrences}
                  frequency={values.frequency}
                />
                <FormSection title="Delivery Mode">
                  <div className={styles.deliveryModeContainer}>
                    <DeliveryModeSelect appointmentType={appointmentType} showAdditionalInput />
                  </div>
                </FormSection>
                {isEdgeUser && appointmentType && <RoomOption appointmentType={appointmentType} />}
              </>
            )}
          </div>
          <AppointmentFooter
            selectedParticipantType={selectedParticipationType}
            clinician={clinician}
            appointmentType={appointmentType}
            timeslot={timeslot}
            submitForm={submitForm}
            buttonText="Confirm Appointment"
            showsClinician
            processing={processing}
            selectedClientRecord={selectedClientRecord}
            selectedGroup={selectedGroup}
          />
        </>
      )}
    </Formik>
  );
};

export default ScheduleForm;
