import { Modal, notification } from 'antd';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { Provider } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { deleteProvider, updateProvider } from 'utils/http/BillingService/Invoice/claimingcom';

import styles from './ConfirmActionModal.module.scss';

interface ConfirmActionModalProps {
  visible: boolean;
  provider: Provider;
  actionType: 'delete' | 'deactivate' | 'reactivate' | '';
  clinicianId?: string;
  setShowConfirmActionModal: (bool: boolean) => void;
  refetchClinicianMedicareProviders: () => void;
}

const getText = (action: 'delete' | 'deactivate' | 'reactivate' | '') => {
  switch (action) {
    case 'deactivate':
      return {
        descriptions: 'If Yes, you can no longer submit claims with this provider number.',
        confirmButtonLabel: 'Confirm deactivation'
      };
    case 'reactivate':
      return {
        confirmButtonLabel: 'Yes'
      };
    case 'delete':
      return {
        descriptions:
          'If Yes, you can no longer submit claims with this provider number and the provider number will be deleted.',
        confirmButtonLabel: 'Delete'
      };
    default:
      return {
        confirmButtonLabel: 'Confirm'
      };
  }
};

const ConfirmActionModal = ({
  visible,
  provider: { name: providerName, locationMinorId, providerNumber },
  actionType,
  clinicianId,
  setShowConfirmActionModal,
  refetchClinicianMedicareProviders
}: ConfirmActionModalProps) => {
  const { token } = useGetAccessToken();
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');
  const texts = getText(actionType);

  const handleCloseModal = () => {
    setShowConfirmActionModal(false);
  };

  const handleConfirmChangeActive = async () => {
    setSubmitStatus('active');
    try {
      switch (actionType) {
        case 'deactivate':
          await updateProvider(token, locationMinorId, providerNumber, { active: false }, clinicianId);
          break;
        case 'reactivate':
          await updateProvider(token, locationMinorId, providerNumber, { active: true }, clinicianId);
          break;
        case 'delete':
          await deleteProvider(token, locationMinorId, providerNumber, clinicianId);
          break;
        default:
          return;
      }

      refetchClinicianMedicareProviders();
      handleCloseModal();
      setSubmitStatus('finished');

      notification.success({
        message: `Provider number ${actionType}d`,
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      console.error(ex);
      notification.error({ message: `Something went wrong while trying to ${actionType} this provider number` });
    }
    setSubmitStatus('');
  };

  return (
    <Modal
      className={styles.modalContainer}
      open={visible}
      closable
      title={null}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <i className={`material-icons ${styles.icon}`}>report_gmailerrorred</i>
          <div className={styles.messageContainer}>
            <div className={styles.title}>{`Are you sure you want to ${actionType} ${providerName} ?`}</div>
            {texts.descriptions && <div className={styles.description}>{texts.descriptions}</div>}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button className={styles.button} onClick={handleConfirmChangeActive} status={submitStatus}>
            {texts.confirmButtonLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmActionModal;
