import { Skeleton } from 'antd';
import NoInvoices from 'assets/images/NoInvoices.png';
import Button from 'components/Button/Button';
import { Invoice, InvoiceStatus } from 'pages/Invoices/interface';
import {
  Claim,
  ClaimStatus,
  ClaimType
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';
import { useMemo, useState } from 'react';

import ClientInvoiceItem from './components/ClientInvoiceItem/ClientInvoiceItem';
import styles from './InvoiceListing.module.scss';

interface InvoiceListingProps {
  isMedicareClient: boolean;
  isDvaClient: boolean;
  invoices: Invoice[];
  claims: Claim[];
  isLoading: boolean;
  onSelectInvoice: (invoice: Invoice, claimType: ClaimType) => void;
}

const InvoiceListing = ({
  isMedicareClient,
  isDvaClient,
  invoices,
  claims,
  isLoading,
  onSelectInvoice
}: InvoiceListingProps) => {
  const [dateSort, setDateSort] = useState<'ascending' | 'descending'>('descending');

  const invoiceList = useMemo(() => {
    let invoiceList = invoices.filter((invoice) => invoice.status !== InvoiceStatus.Draft);

    if (dateSort === 'ascending') {
      invoiceList = invoiceList.sort((a, b) => {
        if (a.issueDate > b.issueDate) {
          return 1;
        } else if (a.issueDate < b.issueDate) {
          return -1;
        } else {
          return 0;
        }
      });
    } else {
      invoiceList = invoiceList.sort((a, b) => {
        if (a.issueDate > b.issueDate) {
          return -1;
        } else if (a.issueDate < b.issueDate) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    return invoiceList;
  }, [dateSort, invoices]);

  const handleDateSortClick = () => {
    setDateSort(dateSort === 'ascending' ? 'descending' : 'ascending');
  };

  const handleInvoiceItemClick = (invoice: Invoice) => (claimType: ClaimType) => {
    onSelectInvoice(invoice, claimType);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Select invoice to request rebate against</div>
      {isLoading ? (
        <Skeleton active />
      ) : invoices.length === 0 ? (
        <div className={styles.noInvoices}>
          <img className={styles.image} src={NoInvoices} alt="No Invoices" />
          <div className={styles.prompt}>
            <div className={styles.text}>No invoices created</div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.listHeader}>
            <span className={styles.invoiceId}>Invoice ID</span>
            <Button className={`${styles.issueDate} ${styles.button}`} onClick={handleDateSortClick}>
              Dated
              <i className={`material-icons-outlined ${styles.icon}`}>
                {dateSort === 'ascending' ? 'arrow_drop_up' : 'arrow_drop_down'}
              </i>
            </Button>
            <span className={styles.description}>Description</span>
            <span className={styles.amount}>Amount</span>
            <span className={styles.status}>Invoice Status</span>
            <span className={styles.claimed}>Claim Status</span>
            <span className={styles.action} />
          </div>
          {invoiceList?.length === 0 ? (
            <div className={styles.noInvoicesFilter}>
              <div className={styles.text}>No invoices filter</div>
            </div>
          ) : (
            invoiceList.map((invoice, index) => (
              <ClientInvoiceItem
                key={index}
                isMedicareClient={isMedicareClient}
                isDvaClient={isDvaClient}
                invoice={invoice}
                claim={
                  claims.find(
                    (claim) =>
                      claim.invoice.id === invoice._id &&
                      [ClaimStatus.Complete, ClaimStatus.MedicareAssessed, ClaimStatus.WithMedicare].includes(
                        claim.status
                      )
                  ) || claims.find((claim) => claim.invoice.id === invoice._id)
                }
                onInvoiceClick={handleInvoiceItemClick(invoice)}
              />
            ))
          )}
        </>
      )}
    </div>
  );
};

export default InvoiceListing;
