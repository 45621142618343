import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { getPractitionerListing } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import { practiceInfo } from 'redux/slice/practiceDataSlice';

export const useFetchPractitionerList = (
  token: string,
  includePractice?: boolean,
  accessRights: AccessRight[] = [AccessRight.Admin, AccessRight.User, AccessRight.Mentor]
) => {
  const { accountId } = useGetAccountId();

  const [practitionersList, setPractitionersList] = useState<PractitionersDetailsInterface[]>([]);
  const [isPractitionersListLoading, setIsPractitionersListLoading] = useState(true);
  const { isEdgeAdminUser, isEdgeReceptionist, isEdgePractitioner } = useGetAccountPackageView();
  const { data: practiceData } = useAppSelector(practiceInfo);

  const [t] = useTranslation();

  const fetchPractitionersList = async (token: string) => {
    if (isEdgeAdminUser || isEdgeReceptionist || isEdgePractitioner) {
      try {
        const practitionersData = await getPractitionerListing(
          token,
          accountId,
          `status=active&withAccessRights=${accessRights.join(',')}`
        );
        const practitionerListData = await practitionersData.json();

        if (includePractice) {
          const practiceDataMassage = {
            _id: '',
            name: practiceData?.name,
            avatar: practiceData?.logo,
            mobileNumber: practiceData?.mobileNumber
          };
          const massageData = [practiceDataMassage, ...practitionerListData.practitionerList];

          setPractitionersList(massageData);
        } else {
          setPractitionersList(practitionerListData.practitionerList);
        }
      } catch (ex) {
        notification.error({ message: t('form.error.failed_to_retrieve_practitioner_record') });
      }
    }

    setIsPractitionersListLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchPractitionersList(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { practitionersList, isPractitionersListLoading, fetchPractitionersList, setPractitionersList };
};
