import { useState } from 'react';
import { notification } from 'antd';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useNavigate } from 'react-router-dom';
import { StageForm, StageType, StageValidateField } from '../../../interface';
import { GroupDetailsTab } from 'pages/Groups/Interfaces/Groups';
import StageFormField, { validateStageField } from '../components/StageFormField/StageFormField';
import { postStage } from 'utils/http/ClinicianProfileService/Groups/GroupPathway';
import ModalV2 from 'components/ModalV2/ModalV2';

import styles from './AddStageModal.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface AddNewStageModalProps {
  groupId: string;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

const defaultStageFormValue: StageForm = {
  stageType: StageType.Sequential,
  name: '',
  description: ''
};

const AddStageModal = ({ groupId, showModal, setShowModal }: AddNewStageModalProps) => {
  const navigate = useNavigate();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { CARECOORDINATION } = useRoutesGenerator();
  const [checkValidation, setCheckValidation] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [errorMessage, setErrorMessage] = useState<StageValidateField>({ name: '' });
  const [stageFormField, setStageFormField] = useState(defaultStageFormValue);

  const validationCheck = (value: StageForm) => {
    const validation = validateStageField(value);
    setErrorMessage(validation.errorMessages);
    if (!validation.isValidationPass) {
      setSubmitStatus('');
    }
    return validation;
  };

  const handleChangeFieldValue = async (key: string, value: string) => {
    const newStageField = {
      ...stageFormField,
      [key]: value
    };
    if (key === 'name' && checkValidation) {
      validationCheck(newStageField);
    }
    setStageFormField(newStageField);
  };

  const handleChangeGroupStage = (val: StageForm['stageType']) => {
    setStageFormField({
      ...stageFormField,
      stageType: val
    });
  };

  const handleSubmit = async () => {
    setSubmitStatus('active');
    setCheckValidation(true);

    const validation = validationCheck(stageFormField);

    if (validation.isValidationPass) {
      try {
        const stagePayload: StageForm = {
          stageType: stageFormField.stageType,
          name: stageFormField.name,
          description: stageFormField.description
        };
        await postStage(token, accountId, groupId, stagePayload);
        setSubmitStatus('finished');
        notification.success({
          message: 'New Stage Added',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setTimeout(() => {
          setSubmitStatus('');
          setStageFormField(defaultStageFormValue);
          setCheckValidation(false);
          setShowModal(false);
          navigate(`${CARECOORDINATION.GROUPS}/${groupId}/${GroupDetailsTab.PathwayManagement}?refetch`);
        }, 1000);
      } catch (ex) {
        console.error(ex);
        setSubmitStatus('');
        notification.error({
          message: 'New Stage fail to create',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={showModal}
      title={'Add Stage'}
      onModalClose={() => setShowModal(!showModal)}
    >
      <StageFormField
        id={'new'}
        stageFormFieldData={stageFormField}
        onChangeStageType={(value: StageForm['stageType']) => handleChangeGroupStage(value)}
        checkValidation={checkValidation}
        errorMessage={errorMessage}
        onChangeFieldValue={handleChangeFieldValue}
      />
      <div className={styles.submitButtonContainer}>
        <ButtonAlt
          disabled={submitStatus !== ''}
          className={styles.cancelButton}
          variant="outlined"
          type={'button'}
          onClick={() => setShowModal(showModal)}
        >
          Cancel
        </ButtonAlt>
        <ButtonAlt status={submitStatus} disabled={submitStatus !== ''} onClick={handleSubmit} id="AddStageBtnId">
          Add Stage
        </ButtonAlt>
      </div>
    </ModalV2>
  );
};

export default AddStageModal;
