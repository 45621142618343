import { IS_PROCARE_APP } from './brand';

export const handleCountryRedirection = (country?: string) => {
  if (
    !IS_PROCARE_APP &&
    country &&
    (process.env.REACT_APP_GAE_REGION === 'au' || process.env.REACT_APP_GAE_REGION === 'nz')
  ) {
    const clinicianDomain =
      country === 'nz' ? process.env.REACT_APP_CLINICIAN_NZ_DOMAIN : process.env.REACT_APP_CLINICIAN_DOMAIN;

    if (
      clinicianDomain &&
      ((country !== 'nz' && !clinicianDomain.includes(window.location.host)) ||
        (country === 'nz' && !clinicianDomain.includes(window.location.host)))
    ) {
      window.location.href = clinicianDomain + window.location.pathname + window.location.search;
    }
  }
};
