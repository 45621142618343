import { PendingClaimInvoiceListQuery, PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';
import { Paging, Sorting, SortType } from '../utils';
import {
  PendingClaimFilters,
  PendingClaimTabs,
  selectActiveTab,
  selectFilters,
  selectDataReadyPaging,
  selectDataNotReadyPaging,
  selectSorting,
  selectAbandonedPaging
} from './pendingClaimInvoiceSlice';

import { useGetPendingClaimInvoiceListQuery } from 'redux/endpoints/billingServices/pendingClaimInvoice';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useAppSelector } from 'redux/hooks';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

export const getPendingClaimsRequestPayload = (
  paging: Paging,
  sorting: Sorting,
  filters: PendingClaimFilters,
  isEdgeUserView: boolean,
  tab: PendingClaimTabs
): PendingClaimInvoiceListQuery => {
  const { page, perPage } = paging;
  const { sortBy, sortType } = sorting;
  const {
    clients: selectedClients,
    practitioners: selectedPractitioners,
    groups: selectedGroups,
    funder: selectedFunder,
    code: selectedClaimCode,
    statuses: selectedStatuses
  } = filters;

  return {
    page,
    perPage,
    ...(tab === PendingClaimTabs.Abandoned
      ? {
          abandoned: 1
        }
      : {
          isClaimReady: tab === PendingClaimTabs.DataReady ? 1 : 0
        }),
    ...(selectedClients.length > 0 && {
      clientRecordIds: selectedClients.map(({ _id }) => _id).join(',')
    }),
    ...(selectedPractitioners.length > 0 && {
      clinicianAuth0Ids: selectedPractitioners.map(({ _id }) => _id).join(',')
    }),
    ...(selectedGroups.length > 0 && {
      groupIds: selectedGroups.map(({ _id }) => _id).join(',')
    }),
    ...(selectedFunder && {
      funder: selectedFunder._id as PendingClaimsFunder
    }),
    ...(selectedClaimCode && {
      mbsCode: selectedClaimCode._id
    }),
    ...(selectedStatuses.length > 0 && {
      statuses: selectedStatuses.map(({ _id }) => _id).join(',')
    }),
    ...(isEdgeUserView && {
      asUser: '1'
    }),
    ...{
      [sortBy === 'date' ? 'sortByAppointmentDate' : 'sortByClaimReady']: [sortType === SortType.ASC ? 1 : -1]
    }
  };
};

export const useGetPendingClaims = (tab?: PendingClaimTabs) => {
  const { accountId } = useGetAccountId();
  const { isEdgeUserView, isEdgeAdminOrReceptionistView } = useGetAccountPackageView();
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();

  const dataReadyPaging = useAppSelector(selectDataReadyPaging);
  const dataNotReadyPaging = useAppSelector(selectDataNotReadyPaging);
  const abandonedPaging = useAppSelector(selectAbandonedPaging);
  const sorting = useAppSelector(selectSorting);
  const filters = useAppSelector(selectFilters);
  const activeTab = useAppSelector(selectActiveTab);

  const currentTab = tab || activeTab;

  const currentPage =
    currentTab === PendingClaimTabs.Abandoned
      ? abandonedPaging
      : currentTab === PendingClaimTabs.DataReady
      ? dataReadyPaging
      : dataNotReadyPaging;

  const { data, isLoading, isFetching, isError, refetch } = useGetPendingClaimInvoiceListQuery(
    {
      accountId,
      params: { ...getPendingClaimsRequestPayload(currentPage, sorting, filters, isEdgeUserView, currentTab) }
    },
    { skip: !medicareRebateFeatureToggle || !isEdgeAdminOrReceptionistView }
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    data,
    totalItems: !(isLoading || isFetching) ? data?.paging.totalItems || 0 : 0,
    refetch
  };
};
