import { FC, useMemo } from 'react';
import { MinifiedInvoice } from 'interfaces/Schedule/Appointment';
import { useNavigate } from 'react-router-dom';
import styles from './InvoiceIds.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

interface InvoiceIdsProps {
  invoices: MinifiedInvoice[];
}

export const InvoiceIds: FC<InvoiceIdsProps> = ({ invoices }) => {
  const navigate = useNavigate();
  const { INVOICES } = useRoutesGenerator();

  const appointmentInvoices = useMemo(() => {
    return invoices
      .map((invoice) => ({
        name: invoice.invoiceId || invoice._id,
        status: invoice.status,
        onClick: () => navigate(`${INVOICES.BASE}/${invoice._id}`)
      }))
      .reverse();
  }, [INVOICES.BASE, invoices, navigate]);

  return (
    <div className={styles.eventInformationContainer}>
      <div className={styles.iconContainer}>
        <span className="material-icons-outlined">receipt</span>
      </div>
      <div className={styles.invoiceIdContainer}>
        {appointmentInvoices.map((item, index) => (
          <div key={index} className={styles.link} onClick={item.onClick}>
            <span>{item.name}</span>
            {item?.status === 'confirmPaid' && <span className={styles.badge}>PAID</span>}
          </div>
        ))}
      </div>
    </div>
  );
};
