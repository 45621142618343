import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import SelectClientList from 'components/SelectClient/SelectClientList/SelectClientList';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { Form, useField } from 'formik';
import { clientRecordsEncryptedInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { ClinicianMedicareRole, ProfileInterface } from 'interfaces/Profile/Profile';
import { DeliveryType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import moment from 'moment';
import { TIME_FORMAT } from 'pages/Calendar/components/Modals/FinderWizardModal/constants';
import { Provider } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { InvoiceForm } from 'pages/InvoiceGenerator/InvoiceGenerator';
import { Invoice, InvoiceSettings, PaymentMethod } from 'pages/Invoices/interface';
import { MBS_CODE_ITEMS } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/mbsItems';
import {
  MedicareItemDuration,
  MedicareItemFormat,
  MedicareItemMode
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/interfaces';
import { GeneralPractitionerInterface } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/GPDetails/interface';
import { useEffect, useMemo } from 'react';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { getClinicianDefaultProvider } from 'utils/helpers/getClinicianDefaultProvider';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import { Appointment, InvoicedItem } from '../../interface';
import BasicDetails from './components/BasicDetails/BasicDetails';
import DescriptionOfServices from './components/DescriptionOfServices/DescriptionOfServices';
import IncludeAppointments from './components/IncludeAppointments/IncludeAppointments';
import IncludeDiscount from './components/IncludeDiscount/IncludeDiscount';
import IncludeMedicareDetails from './components/IncludeMedicareDetails/IncludeMedicareDetails';
import InvoiceTotal from './components/InvoiceTotal/InvoiceTotal';
import ItemsList from './components/ItemsList/ItemsList';
import MedicareDetails from './components/MedicareDetails/MedicareDetails';
import PaymentMethods from './components/PaymentMethods/PaymentMethods';
import styles from './InvoiceGeneratorForm.module.scss';
import EpisodeTaggingSection from 'components/EpisodeTaggingSection/EpisodeTaggingSection';

const calculateTotal = (
  isDiscounted: boolean,
  discountType: 'percent' | 'amount',
  discountValue: string,
  items: InvoicedItem[],
  tax: string
) => {
  const discountValueNumber = Number(discountValue);
  const totalItemsCost = items.reduce((finalTotal, item) => finalTotal + Number(item.cost), 0);

  let totalCost = totalItemsCost || 0;

  if (isDiscounted && !isNaN(discountValueNumber) && !isNaN(totalCost)) {
    if (discountType === 'percent') {
      if (discountValueNumber <= 100) {
        totalCost = totalItemsCost - (totalItemsCost * Math.round(discountValueNumber)) / 100;
      } else {
        totalCost = 0;
      }
    } else if (discountType === 'amount') {
      if (discountValueNumber <= totalItemsCost) {
        totalCost = totalItemsCost - discountValueNumber;
      } else {
        totalCost = 0;
      }
    }
  }

  let totalTax = 0;

  const taxValue = (Number(tax) / 100) * Number(totalCost);

  if (!isNaN(taxValue)) {
    totalTax = taxValue;
  }

  const totalPayment = totalCost + totalTax;

  return { totalCost: totalCost.toFixed(2), totalTax: totalTax.toFixed(2), totalPayment: totalPayment.toFixed(2) };
};

const mbsItemLookup = (clinicianRole?: ClinicianMedicareRole, appointment?: Appointment) => {
  const mbsItems = MBS_CODE_ITEMS[clinicianRole || ClinicianMedicareRole.ClinicalPsychologists];

  if (appointment) {
    const { startTime, endTime, deliveryType } = appointment;
    const serviceDuration = moment(endTime, TIME_FORMAT).diff(moment(startTime, TIME_FORMAT), 'minutes');
    const serviceDurationType = [
      ...(serviceDuration > 20 ? [MedicareItemDuration.OneToOneMoreThan20] : [MedicareItemDuration.OneToOneLessThan20]),
      ...(serviceDuration > 30 ? [MedicareItemDuration.OneToOneMoreThan30] : []),
      ...(serviceDuration < 40 ? [MedicareItemDuration.OneToOneLessThan40] : [MedicareItemDuration.OneToOneMoreThan40]),
      ...(serviceDuration > 45 ? [MedicareItemDuration.OneToOneMoreThan45] : []),
      ...(serviceDuration < 50 ? [MedicareItemDuration.OneToOneLessThan50] : [MedicareItemDuration.OneToOneMoreThan50])
    ];
    const serviceMode =
      deliveryType === DeliveryType.FaceToFace
        ? [MedicareItemMode.FaceToFace]
        : deliveryType === DeliveryType.VideoCall
        ? [MedicareItemMode.VideoCall, MedicareItemMode.Telehealth]
        : [DeliveryType.PhoneCall, DeliveryType.PhoneCallDialClient].includes(deliveryType)
        ? [MedicareItemMode.PhoneCall, MedicareItemMode.Telehealth]
        : [
            MedicareItemMode.Any,
            MedicareItemMode.FaceToFace,
            MedicareItemMode.PhoneCall,
            MedicareItemMode.VideoCall,
            MedicareItemMode.Telehealth
          ];

    return (
      mbsItems.find(
        ({ duration, format, mode }) =>
          format === MedicareItemFormat.OneToOne && serviceDurationType.includes(duration) && serviceMode.includes(mode)
      ) ||
      mbsItems.find(
        ({ duration, format, mode }) =>
          (format === MedicareItemFormat.OneToOne &&
            serviceDurationType.includes(duration) &&
            mode === MedicareItemMode.Any) ||
          (duration === MedicareItemDuration.Any && serviceMode.includes(mode))
      )
    );
  }
};

interface InvoiceGeneratorFormProps {
  appointments: Appointment[];
  clinician?: ProfileInterface;
  draftInvoice?: Partial<Invoice>;
  invoiceId: string;
  invoiceIdError: string;
  invoiceSettings?: InvoiceSettings;
  paymentMethods: PaymentMethod[];
  previewAndGenerateStatus: '' | 'active' | 'finished';
  saveInDraftButtonStatus: '' | 'active' | 'finished';
  selectedAppointmentIds: string[];
  selectedClientRecord?: clientRecordsInterface;
  selectedClientRecordId?: string;
  selectedClientName?: string;
  selectedDueDate: string;
  selectedPaymentMethods: string[];
  isAppointmentsLoading: boolean;
  isInvoiceLoading: boolean;
  isDiscounted: boolean;
  isPaymentMethodsLoading: boolean;
  selectedEpisodeId?: string;
  onAddAppointment: (appointmentId: string) => void;
  onAddPaymentMethod: (index: string) => () => void;
  onChangePaymentDetailsClick: () => void;
  onInvoiceIdChange: (newInvoiceId: string) => void;
  onIsDiscountedChange: () => void;
  onSelectClientRecord: (selectedClientRecord?: clientRecordsInterface) => void;
  onRemoveAppointment: (appointmentId: string) => void;
  onRemoveItem: (appointmentId?: string) => void;
  onRemovePaymentMethod: (index: string) => () => void;
  onSaveInDraft: () => void;
  onSelectDueDate: (newDueDate: string) => void;
  onSelectedEpisodeId: (episodeId?: string) => void;
  setIsDiscounted: (isDiscounted: boolean) => void;
  selectedGroup?: GroupsFromAPI;
  onSelectGroups: (val?: GroupsFromAPI) => void;
  participationType: ParticipantType;
  onChangeParticipationType: (val: ParticipantType) => void;
  includeMedicareDetails: boolean;
  autofillMedicareDetails: boolean;
  clientEncryptDetails?: clientRecordsEncryptedInterface;
  generalPractitioner?: GeneralPractitionerInterface;
  medicareProviders: Provider[];
  appointmentClinician?: ProfileInterface;
  onChangeIncludeMedicareDetails: (val: boolean) => void;
  onChangeAutofillMedicareDetails: (val: boolean) => void;
}

const InvoiceGeneratorForm = ({
  appointments,
  clinician,
  draftInvoice,
  invoiceId,
  invoiceIdError,
  invoiceSettings,
  paymentMethods,
  previewAndGenerateStatus,
  saveInDraftButtonStatus,
  selectedAppointmentIds,
  selectedClientRecord,
  selectedClientRecordId,
  selectedClientName,
  selectedDueDate,
  selectedPaymentMethods,
  isAppointmentsLoading,
  isInvoiceLoading,
  isDiscounted,
  isPaymentMethodsLoading,
  selectedEpisodeId,
  onAddAppointment,
  onAddPaymentMethod,
  onChangePaymentDetailsClick,
  onInvoiceIdChange,
  onIsDiscountedChange,
  onRemoveAppointment,
  onRemoveItem,
  onRemovePaymentMethod,
  onSaveInDraft,
  onSelectClientRecord,
  onSelectDueDate,
  setIsDiscounted,
  onSelectedEpisodeId,
  selectedGroup,
  onSelectGroups,
  participationType,
  onChangeParticipationType,
  includeMedicareDetails,
  autofillMedicareDetails,
  clientEncryptDetails,
  generalPractitioner,
  medicareProviders,
  appointmentClinician,
  onChangeIncludeMedicareDetails,
  onChangeAutofillMedicareDetails
}: InvoiceGeneratorFormProps) => {
  const { isEdgeAdminView, isEdgeReceptionist, isNormalUserView } = useGetAccountPackageView();
  const { medicareRebateFeatureToggle, isEoCEnabled } = useGetFeatureToggle();

  const [{ value: discountType }] = useField<'percent' | 'amount'>('discount.type');
  const [{ value: discountValue }] = useField('discount.value');
  const [{ value: items }] = useField<InvoicedItem[]>('items');
  const [{ value: tax }] = useField<string>('taxRate');
  const [, , { setValue: setMedicareDetails }] = useField<InvoiceForm['medicare']>('medicare');

  useEffect(() => {
    if (includeMedicareDetails) {
      if (autofillMedicareDetails && selectedClientRecord) {
        const clientProfileMedicare = selectedClientRecord.clientProfiles[0].medicare;
        const foundAppointment =
          selectedAppointmentIds.length > 0
            ? appointments.find((appointment) => appointment._id === selectedAppointmentIds[0])
            : undefined;
        const foundProvider =
          foundAppointment &&
          foundAppointment.clinicianId &&
          getClinicianDefaultProvider(
            medicareProviders.filter(({ clinicianId }) => clinicianId === foundAppointment.clinicianId)
          );
        const referralGPExist = !!selectedClientRecord.referral?.generalPractitionerId;
        const foundMbsItem = mbsItemLookup(appointmentClinician?.medicare?.role, foundAppointment);

        setMedicareDetails({
          claimant: {
            name: clientProfileMedicare ? `${clientProfileMedicare.firstName} ${clientProfileMedicare.lastName}` : '-',
            dateOfBirth: clientProfileMedicare?.dateOfBirth
              ? moment(clientProfileMedicare.dateOfBirth, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format(MOMENTJS_DATE_FORMAT)
              : '-',
            medicareNumber: clientProfileMedicare?.number?.toString() || '-',
            irn: clientProfileMedicare?.irn?.toString() || '-',
            dva: clientProfileMedicare?.dva || '-',
            expiryDate: clientProfileMedicare?.expiryDate || '-',
            phoneNumber: clientEncryptDetails?.clientProfiles[0].mobileNumber || '-',
            address: '-'
          },
          referral: {
            name: (referralGPExist && generalPractitioner?.name) || '-',
            date: selectedClientRecord.referral?.date || '-',
            providerNumber: (referralGPExist && generalPractitioner?.medicareProviderNumber) || '-'
          },
          serviceProvider: {
            name: (foundProvider && foundProvider.name) || '-',
            providerNumber: (foundProvider && foundProvider.providerNumber) || '-'
          },
          claim: {
            dateOfService: foundAppointment
              ? moment(foundAppointment.date, MOMENTJS_YEAR_MONTH_DAY_FORMAT).format(MOMENTJS_DATE_FORMAT)
              : '-',
            mbsCode: foundMbsItem?.mbsCode || '-'
          }
        });
      }
    } else {
      setMedicareDetails(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    includeMedicareDetails,
    autofillMedicareDetails,
    appointments,
    clientEncryptDetails,
    generalPractitioner,
    selectedAppointmentIds,
    selectedClientRecord,
    medicareProviders,
    appointmentClinician
  ]);

  const { totalCost, totalTax, totalPayment } = useMemo(
    () => calculateTotal(isDiscounted, discountType, discountValue, items, tax),
    [isDiscounted, discountType, discountValue, items, tax]
  );

  const isClientRecordSelected = !!selectedClientRecord?._id;

  const clientMedicareStatus = selectedClientRecord?.clientProfiles[0].medicare?.status;
  const isClientMedicareValid = !!(
    (clientMedicareStatus?.medicare && [0, 9626].includes(clientMedicareStatus.medicare.code)) ||
    (clientMedicareStatus?.dva && clientMedicareStatus.dva.code === 0)
  );
  const isPracticeConnectedMedicare = invoiceSettings?.medicare?.status === 'connected';

  const serviceDeliveredDates: string[] =
    appointments.filter((appt) => selectedAppointmentIds.includes(appt._id)).map((item) => item.date) || [];

  return (
    <div className={styles.container}>
      <Form noValidate>
        <div className={styles.header}>
          <div className={styles.title}>Invoice Generator</div>
          <div className={styles.invoiceIdInputContainer}>
            <MaterialInput
              id="invoice-generator-invoice-id-input"
              name="invoiceId"
              label="Invoice ID"
              value={invoiceId}
              onChange={(e) => onInvoiceIdChange(e.target.value)}
            />
            <ErrorMessage error={invoiceIdError} visible={!!invoiceIdError} />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.leftCol}>
            <SelectClientList
              title={'Create Invoice'}
              selectedClientRecord={selectedClientRecord}
              selectedClientRecordId={selectedClientRecordId}
              showModalIfUnselected
              onSelectClientRecord={onSelectClientRecord}
              selectedGroup={selectedGroup}
              onSelectGroups={onSelectGroups}
              participationType={participationType}
              onChangeParticipationType={onChangeParticipationType}
            />
            {isEoCEnabled && (
              <div className={styles.episodeCard}>
                <EpisodeTaggingSection
                  selectedClientRecordId={selectedClientRecordId || ''}
                  onSelectEpisode={onSelectedEpisodeId}
                  selectedEpisode={selectedEpisodeId}
                  readOnly={false}
                  containerClassName={styles.episodeContainer}
                  clearCache
                />
              </div>
            )}

            <div className={styles.card}>
              <IncludeAppointments
                appointments={appointments}
                selectedAppointmentIds={selectedAppointmentIds}
                isAppointmentsLoading={isAppointmentsLoading}
                isGroupSelected={!!selectedGroup?._id}
                isClientRecordSelected={isClientRecordSelected}
                participationType={participationType}
                onAddAppointment={onAddAppointment}
                onRemoveAppointment={onRemoveAppointment}
              />
              {medicareRebateFeatureToggle && !selectedGroup?._id && (
                <IncludeMedicareDetails
                  includeMedicareDetails={includeMedicareDetails}
                  autofillMedicareDetails={autofillMedicareDetails}
                  isClientRecordSelected={isClientRecordSelected}
                  isClientMedicareValid={isClientMedicareValid}
                  isPracticeConnectedMedicare={isPracticeConnectedMedicare}
                  onChangeIncludeMedicareDetails={onChangeIncludeMedicareDetails}
                  onChangeAutofillMedicareDetails={onChangeAutofillMedicareDetails}
                />
              )}
              <PaymentMethods
                paymentMethods={paymentMethods}
                selectedPaymentMethods={selectedPaymentMethods}
                isPaymentMethodsLoading={isPaymentMethodsLoading}
                onAddPaymentMethod={onAddPaymentMethod}
                onChangePaymentDetailsClick={onChangePaymentDetailsClick}
                onRemovePaymentMethod={onRemovePaymentMethod}
                isEditingAllowed={isEdgeAdminView || isEdgeReceptionist || isNormalUserView}
              />
            </div>
          </div>
          <div className={styles.rightCol}>
            <BasicDetails
              clinician={clinician}
              invoiceId={invoiceId}
              selectedClientName={selectedClientName}
              selectedGroup={selectedGroup}
              selectedDueDate={selectedDueDate}
              serviceDeliveredDates={serviceDeliveredDates}
              onSelectDueDate={onSelectDueDate}
              invoiceSettings={invoiceSettings}
              isInvoiceLoading={isInvoiceLoading}
            />
            <DescriptionOfServices placeholder={'You may fill description of services here'} />
            {includeMedicareDetails && <MedicareDetails />}
            <ItemsList invoiceSettings={invoiceSettings} onRemoveItem={onRemoveItem} />
            <IncludeDiscount
              clientRecord={selectedClientRecord}
              draftInvoice={draftInvoice}
              isDiscounted={isDiscounted}
              onIsDiscountedChange={onIsDiscountedChange}
              setIsDiscounted={setIsDiscounted}
            />
            <InvoiceTotal totalPayment={totalPayment} totalCost={totalCost} totalTax={totalTax} />
            <ButtonAlt
              className={styles.saveInDraftButton}
              variant={'outlined'}
              type="button"
              status={saveInDraftButtonStatus}
              onClick={onSaveInDraft}
            >
              Save in Draft
            </ButtonAlt>
            <ButtonAlt className={styles.previewAndSend} type="submit" status={previewAndGenerateStatus} fab>
              GENERATE INVOICE FOR {totalPayment}
            </ButtonAlt>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default InvoiceGeneratorForm;
