const getReportHTML = (node: Node): Document => {
  const newDocument = new Document();
  const newHTML = newDocument.createElement('html');
  const newHead = document.head.cloneNode(true);
  const newBody = newDocument.createElement('body');
  newBody.appendChild(node.cloneNode(true));

  newHTML.appendChild(newHead);
  newHTML.appendChild(newBody);
  newDocument.appendChild(newHTML);

  const styleSheets = newDocument.querySelectorAll("link[rel='stylesheet' i]");
  styleSheets.forEach((styleSheet) => {
    const extractedHref = styleSheet.getAttribute('href');
    if (extractedHref && !isValidUrl(extractedHref)) {
      styleSheet.setAttribute('href', `${document.location.origin}${extractedHref}`);
    }
  });

  return newDocument;
};

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const triggerDownload = (file: Blob, fileName: string): void => {
  const hiddenLink = document.createElement('a');
  hiddenLink.href = window.URL.createObjectURL(file);
  hiddenLink.download = `${fileName}.pdf`;
  hiddenLink.click();
  hiddenLink.remove();
};

export const generatePrintableReportPayload = (node?: Node | null) => {
  if (node) {
    const printPayloadDocument = getReportHTML(node);
    const xmlSerializer = new XMLSerializer();
    return xmlSerializer.serializeToString(printPayloadDocument);
  }
};
