import classNames from 'classnames';
import { selectAppointmentData } from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppSelector } from 'redux/hooks';
import AppointmentDateAndTime from '../AppointmentDateAndTime/AppointmentDateAndTime';
import AppointmentRecurring from '../AppointmentRecurring/AppointmentRecurring';
import AppointmentRoom from '../AppointmentRoom/AppointmentRoom';
import styles from './ActivityContent.module.scss';
import HumanFactors from './components/HumanFactors/HumanFactors';
import LinkClientOrGroup from './components/LinkClientOrGroup/LinkClientOrGroup';
import { Datepicker } from '@mobiscroll/react';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface ActivityContentProps {
  practitionerMainOption?: boolean;
  rememberSelectedPractitioner?: boolean;
  showSelectedItemOnFirstOption?: boolean;
  onInlineRef?: (ref: Datepicker | null) => void;
}

const ActivityContent = ({
  practitionerMainOption,
  rememberSelectedPractitioner,
  showSelectedItemOnFirstOption,
  onInlineRef
}: ActivityContentProps) => {
  const { selectedRoom } = useAppSelector(selectAppointmentData);
  const { isPackageEnabled } = useGetFeatureToggle();

  return (
    <>
      <div className={styles.humanFactorsWrapper}>
        <HumanFactors
          practitionerMainOption={practitionerMainOption}
          rememberSelectedPractitioner={rememberSelectedPractitioner}
          showSelectedItemOnFirstOption={showSelectedItemOnFirstOption}
        />
      </div>
      <div className={styles.bottomContent}>
        <AppointmentDateAndTime onInlineRef={onInlineRef} />
        {!isPackageEnabled && <LinkClientOrGroup />}
        <div className={styles.recurringAndRoom}>
          <AppointmentRecurring />
          <AppointmentRoom />
        </div>
        {selectedRoom?.isConflicted && (
          <div className={styles.roomConflict}>
            <i className={classNames(styles.icon, 'material-icons-outlined')}>warning_amber</i>
            <div className={styles.message}>
              Sorry, the room is not available, please select other date and time or room to create this appointment.
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ActivityContent;
