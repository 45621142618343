import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';

export enum LocationType {
  HomeVisit = 'V',
  Hospital = 'H',
  Rooms = 'R',
  ResidentialCareFacility = 'N',
  CommunityHealthCentre = 'C'
}

export interface Location {
  active: boolean;
  default: boolean;
  minorId: string;
  name: string;
  type?: LocationType;
  providerNumber?: string;
  address?: string;
}

export interface Provider {
  accountId: string;
  clinicianId: string;
  locationMinorId: string;
  name: string;
  providerNumber: string;
  active: boolean;
  default: boolean;
}

export interface PopulatedProvider extends Provider {
  locationActive: boolean;
  clinicianName?: string;
  clinicianRole?: ClinicianMedicareRole;
}
