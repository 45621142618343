import bent from 'bent';

const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

const updateData = (data: any, authKey: string) => {
  const dataServiceReportRequest = bent(
    `${url}`,
    'PUT',
    'json',
    {
      Authorization: `Bearer ${authKey}`
    },
    200
  );
  const assessmentID = data.id;
  return dataServiceReportRequest(`/first-assessments/${assessmentID}`, data);
};

const createData = (data: any, authKey: string) => {
  const dataServiceReportRequest = bent(
    `${url}`,
    'PUT',
    'json',
    {
      Authorization: `Bearer ${authKey}`
    },
    201
  );
  return dataServiceReportRequest('/first-assessments', data);
};

const massageQuestions = (data: any) => {
  const massageQ = [];
  for (const { id, ...obj } of data) {
    massageQ.push({
      ...obj
    });
  }
  return massageQ;
};

const massageCategoryQuestions = (data: any) => {
  const massageQ = [];
  for (const { id, ...obj } of data) {
    massageQ.push({
      ...obj,
      structuredQuestionId: id
    });
  }
  return massageQ;
};

const massageQuestionSet = (data: any) => {
  const massageQS = [];
  for (let obj of data) {
    massageQS.push(
      obj.questionSetType === 'dataCollection'
        ? {
            sectionName: obj.sectionName,
            categoryId: obj.categoryId,
            defaultCategoryId: obj.defaultCategoryId,
            questions: massageCategoryQuestions(obj.questions)
          }
        : {
            sectionName: obj.sectionName,
            questions: massageQuestions(obj.questions)
          }
    );
  }
  return massageQS;
};
const massageData = (data: any) => {
  return {
    id: data.id,
    name: data.name,
    diffName: data.diffName,
    note: data.note,
    tags: data.tags
      .filter((obj: { tag: any; isAdd: any }) => obj.isAdd)
      .map(({ tag }: { tag: any; isAdd: any }) => tag),
    description: data.description,
    isPublished: data.isPublished,
    backgroundQuestions: data.backgroundQuestions,
    questionSets: massageQuestionSet(data.questionSets)
  };
};
export const updateFirstAssessments = async (data: any, token: string) => {
  try {
    const refactorData = massageData(data);
    return await updateData(refactorData, token);
  } catch (error) {
    console.log('error in calling database', error);
  }

  return undefined;
};

export const createFirstAssessments = async (data: any, token: string) => {
  try {
    const refactorData = massageData(data);
    return await createData(refactorData, token);
  } catch (error) {
    console.log('error in calling database', error);
  }

  return undefined;
};
