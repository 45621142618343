import { clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { PracticeInterface } from 'interfaces/Profile/Profile';

export const clientItem = (clientRecordData: clientRecordsInterface) => {
  const getReferralSource = (): string => {
    if (!clientRecordData || !clientRecordData.referral) {
      return '-';
    }

    const { referral } = clientRecordData;
    let referralSourceText = '-';
    referralSourceText = _handleSourceTextFormation(referral);

    return referralSourceText;
  };

  const getAddedBy = (practiceInfo: PracticeInterface) => {
    const { clinicians } = clientRecordData;
    if (!clientRecordData || !clientRecordData.referral) {
      return {
        referral: 'practitioner',
        name: clinicians && clinicians.length > 0 ? clinicians[0].name : practiceInfo.name,
        avatarUrl: clinicians && clinicians.length > 0 ? clinicians[0].avatar : practiceInfo.logo,
        avatarSize: 50,
        showUnassigned: clinicians && clinicians.length === 0
      };
    }

    const { referral } = clientRecordData;

    if (referral.isForMySelf === false) {
      // NOTE: Refer to someone else.
      return {
        referral: '3rd party',
        referrer: _handleReferrerInfo(referral).name,
        relationship: _handleReferrerInfo(referral).relationship
      };
    } else if (referral.isForMySelf === undefined) {
      // NOTE: From Tacklit website.
      return {
        referral: 'practitioner',
        name: clinicians && clinicians.length > 0 ? clinicians[0].name : practiceInfo.name,
        avatarUrl: clinicians && clinicians.length > 0 ? clinicians[0].avatar : practiceInfo.logo,
        avatarSize: 50,
        showUnassigned: clinicians && clinicians.length <= 0 && !practiceInfo.logo
      };
    } else {
      return {
        referral: 'self',
        label: 'Self'
      };
    }
  };

  const _handleSourceTextFormation = (referral: clientRecordsInterface['referral']): string => {
    if (!referral) {
      return '-';
    }

    let text = '';
    if (referral.sourceTags && referral.sourceTags.length > 0) {
      let source = referral.sourceTags.join(', ');
      let answer = referral.detail;

      text += source += answer ? ` - ${answer}` : '';
    } else if (referral.source) {
      let source = referral.source;
      let answer = referral.sourceName ? referral.sourceName : '';
      text += source += answer ? ` - ${answer}` : '';
    } else {
      text += '-';
    }

    return text;
  };

  const _handleReferrerInfo = (referral: clientRecordsInterface['referral']) => {
    if (referral?.referrerInfo) {
      const { firstName, lastName, relationship } = referral.referrerInfo;
      return {
        name: `${firstName} ${lastName}`,
        relationship: relationship
      };
    }

    return {
      name: '-',
      relationship: null
    };
  };

  return {
    getReferralSource,
    getAddedBy
  };
};

export const isNotSignUpDateExists = (clientData: clientProfilesInterface[]) => {
  return clientData?.some(({ profileType, clientAuth0Id }) => profileType === 'full' && !clientAuth0Id);
};
