import ClaimStatementModal from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/components/ClaimHistory/components/ClaimItem/components/ClaimStatementModal/ClaimStatementModal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectedSelectedClaim,
  selectIsClaimStatementModalVisible,
  selectIsCreateClaimModalVisible,
  selectIsShowWriteOffModal,
  selectSubmittedPaging,
  selectSuccessPaging,
  selectRejectedPaging,
  selectWriteOffPaging,
  setIsClaimStatementModalVisible,
  setIsCreateClaimModalVisible,
  setSubmittedPaging,
  setSuccessPaging,
  setRejectedPaging,
  setWriteOffPaging,
  selectActiveTab,
  SubmittedClaimTabs,
  selectWriteOffFormValue,
  setWriteOffFormValue,
  setIsShowWriteOffModal,
  setSelectedClaim,
  setAbandonedPaging,
  selectAbandonedPaging
} from 'redux/features/submittedClaims/submittedClaimSlice';
import SubmittedClaimFilter from '../SubmittedClaimFilter/SubmittedClaimFilter';
import SubmittedClaimItem from '../SubmittedClaimItem/SubmittedClaimItem';
import SubmittedClaimListHeader from '../SubmittedClaimListHeader/SubmittedClaimListHeader';
import styles from './SubmittedClaimList.module.scss';
import { notification, Skeleton } from 'antd';
import { useState } from 'react';
import Loading from 'components/Loading/Loading';
import PaginationListV2 from 'components/v2/PaginationListV2/PaginationListV2';
import CreateClaimModal from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/components/ClaimHistory/components/CreateClaimModal/CreateClaimModal';
import { getClaimInvoiceDetailsInfo } from 'pages/InvoicePendingClaims/utils';
import { useFetchMedicareLocations } from 'pages/ControlPanel/IntegrationDetails/components/IntegrationDetailsContent/components/IntegrationDetailsContentDisplay/components/Integration/hooks/getMedicareData';
import { useGetAccessToken } from 'utils/hooks/token';
import { BSTagTypes, billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';
import { useGetSubmittedClaims } from 'redux/features/submittedClaims/useGetSubmittedClaims';
import WriteOffModal from 'pages/InvoicesV2/components/WriteOffModal/WriteOffModal';
import { WriteOffForm } from 'redux/invoices/invoiceSlice';
import { usePatchAbandonRejectedClaimMutation } from 'redux/endpoints/billingServices/submittedClaim';

const SubmittedClaimList = () => {
  const { token } = useGetAccessToken();
  const dispatch = useAppDispatch();

  const isClaimStatementModalVisible = useAppSelector(selectIsClaimStatementModalVisible);
  const isCreateClaimModalVisible = useAppSelector(selectIsCreateClaimModalVisible);
  const selectedClaim = useAppSelector(selectedSelectedClaim);
  const isShowWriteOffModal = useAppSelector(selectIsShowWriteOffModal);
  const writeOffFormValue = useAppSelector(selectWriteOffFormValue);
  const submittedPaging = useAppSelector(selectSubmittedPaging);
  const successPaging = useAppSelector(selectSuccessPaging);
  const rejectedPaging = useAppSelector(selectRejectedPaging);
  const writeOffPaging = useAppSelector(selectWriteOffPaging);
  const abandonedPaging = useAppSelector(selectAbandonedPaging);
  const activeTab = useAppSelector(selectActiveTab);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { data, isLoading, isError, totalItems, refetch } = useGetSubmittedClaims();
  const [patchAbandonedRejectedClaim] = usePatchAbandonRejectedClaimMutation();

  const { locations } = useFetchMedicareLocations(token, true);

  const {
    clientProfile,
    parentProfile,
    isDvaClient,
    isMedicareClient,
    isGPReferralProviderNumberSet,
    isReferralDateSet
  } = getClaimInvoiceDetailsInfo(selectedClaim?.foundClientRecord, selectedClaim?.foundGeneralPractitioner);

  const onChangePage = (page: number) => {
    switch (activeTab) {
      case SubmittedClaimTabs.Submitted: {
        dispatch(setSubmittedPaging({ ...submittedPaging, page }));
        break;
      }
      case SubmittedClaimTabs.Success: {
        dispatch(setSuccessPaging({ ...successPaging, page }));
        break;
      }
      case SubmittedClaimTabs.Rejected: {
        dispatch(setRejectedPaging({ ...rejectedPaging, page }));
        break;
      }
      case SubmittedClaimTabs.WriteOff: {
        dispatch(setWriteOffPaging({ ...writeOffPaging, page }));
        break;
      }
      case SubmittedClaimTabs.Abandoned: {
        dispatch(setAbandonedPaging({ ...abandonedPaging, page }));
        break;
      }
      default:
        break;
    }
  };

  const refetchListAfterResubmitClaim = () => {
    dispatch(billingServicesApiSlice.util.invalidateTags([BSTagTypes.SubmittedClaims]));
    refetch();
  };

  const onCloseWriteOffModalHandle = () => {
    dispatch(setWriteOffFormValue({ reference: '', date: '' }));
    dispatch(setIsShowWriteOffModal(false));
    dispatch(setSelectedClaim(undefined));
  };

  const onChangeWriteOffFormValue = (value: Partial<WriteOffForm>) => {
    dispatch(setWriteOffFormValue(value));
  };

  const onWriteOffSuccess = () => {
    dispatch(billingServicesApiSlice.util.invalidateTags([BSTagTypes.SubmittedClaims, BSTagTypes.WriteOffInvoices]));
  };

  const onAbandonRejectedClaim = async (accountId: string, claimId: string) => {
    try {
      setIsSubmitting(true);
      await patchAbandonedRejectedClaim({ accountId, claimId }).unwrap();
      notification.success({
        message: 'Successfully abandoned rejected claim!',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to abandoned rejected claim.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const locationsFiltered = locations.filter(({ type }) => !isDvaClient || type);

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div className={styles.container}>
        {/* Filters */}
        <div className={styles.filterWrapper}>
          <SubmittedClaimFilter totalItems={!isError ? totalItems || 0 : 0} isLoading={isLoading} />
        </div>

        <div className={styles.listWrapper}>
          <SubmittedClaimListHeader />

          {isLoading ? (
            <div className={styles.loadingWrapper}>
              {[...Array(10)].map((obj, i) => (
                <div key={i} className={styles.loadingItem}>
                  <Skeleton.Input active className={styles.loadingContent} />
                </div>
              ))}
            </div>
          ) : isError || (data?.claims && data.claims.length < 1) ? (
            <div>No claims submitted</div>
          ) : (
            <div className={styles.listOfClaims}>
              {data?.claims.map((claim) => (
                <SubmittedClaimItem
                  key={claim._id}
                  claim={claim}
                  token={token}
                  onAbandon={() => onAbandonRejectedClaim(claim.accountId, claim._id)}
                />
              ))}
            </div>
          )}
        </div>

        {/* Pagination */}
        {!isError && !isLoading && data && totalItems > data.paging.perPage && (
          <div className={styles.paginationWrapper}>
            <PaginationListV2 paging={data.paging} onPageChange={onChangePage} />
          </div>
        )}
      </div>
      {isClaimStatementModalVisible && selectedClaim && (
        <ClaimStatementModal
          visible={!!(isClaimStatementModalVisible && selectedClaim)}
          claim={selectedClaim}
          invoice={selectedClaim.foundInvoice}
          handleCancel={() => dispatch(setIsClaimStatementModalVisible(false))}
          refetchClaims={refetch}
        />
      )}

      {selectedClaim && clientProfile && (
        <CreateClaimModal
          parentProfile={parentProfile}
          visible={isCreateClaimModalVisible}
          clientRecordId={selectedClaim.foundClientRecord?._id || ''}
          clientProfile={clientProfile}
          isMedicareClient={isMedicareClient}
          isDvaClient={isDvaClient}
          referral={{
            name: selectedClaim.foundGeneralPractitioner?.name || '',
            date: selectedClaim.foundClientRecord?.referral?.date || '',
            providerNumber: selectedClaim.foundGeneralPractitioner?.medicareProviderNumber || ''
          }}
          isClientReferralValid={isGPReferralProviderNumberSet && isReferralDateSet}
          onCancel={() => {
            dispatch(setIsCreateClaimModalVisible(false));
          }}
          preSelectedStep={2}
          hideStepProcessBar
          preSelectedInvoice={selectedClaim.foundInvoice}
          preSelectedClaimType={selectedClaim.type}
          locations={locationsFiltered}
          refetchClaims={refetchListAfterResubmitClaim}
        />
      )}

      {selectedClaim && (
        <WriteOffModal
          invoiceRecordId={selectedClaim.foundInvoice?._id}
          showModal={isShowWriteOffModal}
          formValue={writeOffFormValue}
          successMessage="Claim written off. New write off created."
          onCloseModal={onCloseWriteOffModalHandle}
          onChangeValue={onChangeWriteOffFormValue}
          onSubmitSuccess={onWriteOffSuccess}
        />
      )}
    </>
  );
};

export default SubmittedClaimList;
