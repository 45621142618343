import ContentLayout from 'components/ContentLayoutT23/ContentLayoutT23';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import styles from './LetterTemplateEditor.module.scss';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import moment, { Moment } from 'moment';
import { timeDiff } from 'utils/timeDifferent';
import { useCallback, useEffect, useMemo, useState } from 'react';
import WidgetTextEditor2 from 'components/WidgetTextEditor2/WidgetTextEditor2';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import { LetterTemplateStatus, PutLetterTemplateRequest } from 'interfaces/Letters/letterTemplate';
import { validate, validateAll } from './validator';
import { Skeleton, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { postLetterTemplate, putLetterTemplate } from 'utils/http/DocumentService/Letters/lettersTemplate';
import { useGetAccessToken } from 'utils/hooks/token';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { debounce } from 'lodash';
import { useFetchLetterTemplate } from 'utils/hooks/GetLetters/useFetchLetterTemplate';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';
import { useAppDispatch } from 'redux/hooks';

const LetterTemplateEditor = () => {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [saveBtnStatus, setSaveBtnStatus] = useState<ButtonStatusType>('');
  const [lastEdited, setLastEdited] = useState<Moment>();
  const [formattedDuration, setFormattedDuration] = useState<string>();
  const [data, setData] = useState<PutLetterTemplateRequest>({
    title: '',
    body: '',
    status: LetterTemplateStatus.Draft
  });
  const [errors, setErrors] = useState<{
    title?: string;
    body?: string;
  }>({});

  const { token } = useGetAccessToken();
  const { auth0ClinicianId } = useGetClinicianId();
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { FORMS } = useRoutesGenerator();
  const navigate = useNavigate();
  const { letterTemplateId } = useParams();
  const { letterTemplate } = useFetchLetterTemplate(token, accountId, letterTemplateId);
  const dispatch = useAppDispatch();

  const asAdmin = isEdgeAdminView || isEdgeReceptionistView;
  const isNewLetterTemplate = useMemo(() => !letterTemplateId || letterTemplateId === 'new', [letterTemplateId]);

  const statusToggleList = useMemo(
    () => [
      {
        id: LetterTemplateStatus.Draft.toString(),
        label: 'Draft',
        isActive: data.status === LetterTemplateStatus.Draft
      },
      {
        id: LetterTemplateStatus.Published.toString(),
        label: 'Published',
        isActive: data.status === LetterTemplateStatus.Published
      }
    ],
    [data.status]
  );

  const isEditable = useMemo(() => {
    if (isNewLetterTemplate || letterTemplate === undefined) {
      return true;
    }
    if (letterTemplate?.shareable && asAdmin) {
      return true;
    }
    console.log(letterTemplate, letterTemplate);
    if (!letterTemplate?.shareable && letterTemplate?.createdBy.clinicianAuth0Id === auth0ClinicianId) {
      return true;
    }

    return false;
  }, [isNewLetterTemplate, letterTemplate, asAdmin, auth0ClinicianId]);

  const saveLetterTemplate = useCallback(
    async (payload: PutLetterTemplateRequest, notify: boolean) => {
      if (validateAll(payload).isValid) {
        setSaveBtnStatus('active');
        try {
          if (isNewLetterTemplate) {
            const createdLetterTemplateId = await (await postLetterTemplate(token, accountId, asAdmin, payload)).text();
            navigate(`${FORMS.LETTER_TEMPLATE}/${createdLetterTemplateId}`);
          } else if (letterTemplateId) {
            await putLetterTemplate(token, accountId, letterTemplateId, payload);
          }
          setSaveBtnStatus('finished');
          notify &&
            notification.success({
              message: 'Letter Template has been saved.',
              duration: 3,
              closeIcon: <span className="success">OK</span>
            });
          dispatch(documentServicesApiSlice.util.invalidateTags(['Letter Templates']));
        } catch (e) {
          notify && notification.error({ message: 'Error saving letter template' });
        }
        setTimeout(() => {
          setSaveBtnStatus('');
        }, 1000);
      } else {
        notify && notification.error({ message: 'Please fill in all required fields' });
      }
    },
    [FORMS.LETTER_TEMPLATE, isNewLetterTemplate, asAdmin, letterTemplateId, navigate, token, accountId, dispatch]
  );

  const debouncedSaveLetterTemplate = useMemo(
    () => debounce(async (data: PutLetterTemplateRequest, notify: boolean) => saveLetterTemplate(data, notify), 3000),
    [saveLetterTemplate]
  );

  useEffect(() => {
    const timer = setInterval(() => {
      if (lastEdited) {
        const now = moment();
        const duration = timeDiff(now, lastEdited);
        setFormattedDuration(duration);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [lastEdited]);

  useEffect(() => {
    if (letterTemplate) {
      setData({
        title: letterTemplate.title,
        body: letterTemplate.body,
        status: letterTemplate.status
      });
      setIsLoadingData(false);
    } else if (isNewLetterTemplate) {
      setIsLoadingData(false);
    }
  }, [isNewLetterTemplate, letterTemplate]);

  const handleDataChange = (key: keyof PutLetterTemplateRequest, value: string) => {
    if (value !== data[key]) {
      const newData = {
        ...data,
        [key]: value
      };
      const error = validate(key, value);
      setData(newData);
      setErrors((err) => ({
        ...err,
        [key]: error
      }));
      setLastEdited(moment());
      if (saveBtnStatus === '') {
        debouncedSaveLetterTemplate(newData, false);
      }
    }
  };

  const handleClickSave = () => {
    debouncedSaveLetterTemplate.cancel();
    saveLetterTemplate(data, true);
  };

  const goToFormsHub = () => {
    navigate(FORMS.BASE);
  };

  return (
    <HelmetWrapper title={'Tacklit - Letter Template Editor'}>
      <ContentLayout>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.titleSection}>
              <div className={styles.title}>{isEditable ? 'Letter Template Editor' : data.title}</div>
              <ButtonAlt size={'medium'} variant={'text'} onClick={goToFormsHub} icon={'arrow_back_ios'}>
                To Template List
              </ButtonAlt>
            </div>
          </div>
          {isLoadingData ? (
            <Skeleton active />
          ) : isEditable ? (
            <div className={styles.body}>
              <div className={styles.left}>
                <div className={styles.panel}>
                  <div className={styles.titleContainer}>
                    <MaterialInput
                      id={'title'}
                      label="Title"
                      required
                      onChange={(e) => handleDataChange('title', e.target.value)}
                      value={data.title}
                    />
                    {errors.title && <div className={styles.error}>{errors.title}</div>}
                  </div>
                  <div>
                    <div className={styles.label}>TEMPLATE STATUS</div>
                    <ToggleSwitchV2
                      id="status"
                      toggleList={statusToggleList}
                      onChangeStatus={(value) => handleDataChange('status', value.id as LetterTemplateStatus)}
                    />
                  </div>
                  <div className={styles.actionSection}>
                    <ButtonAlt variant="contained" onClick={handleClickSave} status={saveBtnStatus} fullWidth>
                      Save Changes
                    </ButtonAlt>
                    {lastEdited && <div className={styles.lastEditMessage}>{`Last edit was ${formattedDuration}`}</div>}
                  </div>
                </div>
              </div>
              <div className={styles.content}>
                <WidgetTextEditor2
                  minHeight={350}
                  height="auto"
                  value={data.body}
                  onChange={(value) => handleDataChange('body', value)}
                  enableMention
                  enableMentionCDF
                />
                {errors.body && <div className={styles.error}>{errors.body}</div>}
              </div>
            </div>
          ) : (
            <div className={styles.body}>
              <div className={styles.content} dangerouslySetInnerHTML={{ __html: data.body }} />
            </div>
          )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default LetterTemplateEditor;
