import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PractitionerCardItem from './components/PractitionerCardItem/PractitionerCardItem';

import styles from './ActivePractitionerCard.module.scss';
import columnStyles from './PractitionerRowCard.module.scss';
import { PractitionersDetailsInterface, PractitionersListing } from 'interfaces/Practitioners/practitionersListing';
import classnames from 'classnames';
import PaginationList from 'components/PaginationList/PaginationList';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

interface ActivePractitionerCardProps {
  practitionersList: PractitionersDetailsInterface[];
  isPractitionersListLoading: boolean;
  paging: PractitionersListing['paging'];
  onPageChange: (paging: string) => void;
}

const ActivePractitionerCard = ({
  practitionersList,
  isPractitionersListLoading,
  paging,
  onPageChange
}: ActivePractitionerCardProps) => {
  const [t] = useTranslation();

  const navigate = useNavigate();

  const { PRACTITIONER } = useRoutesGenerator();

  const selectPractitioner = (practitionerId: string) => {
    navigate(`${PRACTITIONER.BASE}/${practitionerId}/profile`);
  };

  return isPractitionersListLoading ? (
    <div className={styles.loading}>
      <LoadingCircle />
    </div>
  ) : practitionersList.length > 0 ? (
    <>
      <div className={styles.filterContainer}>
        <div className={classnames(columnStyles.profileSection, styles.profileLabel)}>
          {t('label.practitioner.capitalize')}
        </div>
        <div className={columnStyles.roleSection}>Role</div>
        <div className={columnStyles.mobileSection}>Mobile</div>
        <div className={columnStyles.emailSection}>Email</div>
        <div className={columnStyles.scheduleSection}>Schedule this week</div>
        <div className={columnStyles.caseLoadSection}>Caseload</div>
        <div className={columnStyles.buttonSection} />
      </div>
      {practitionersList.map((practitionersListObj, index) => {
        return (
          <PractitionerCardItem
            key={index}
            id={index}
            PractitionerData={practitionersListObj}
            onSelectPractitioner={() => selectPractitioner(practitionersListObj._id)}
          />
        );
      })}
      {paging.totalPage > 1 && (
        <div className={styles.paginationWrapper}>
          <PaginationList paging={paging} onPageChange={onPageChange} />
        </div>
      )}
    </>
  ) : (
    <div>{t('label.no_data_for_practitioner')}</div>
  );
};

export default ActivePractitionerCard;
