import { Modal } from 'antd';

import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import styles from './SelectClientModal.module.scss';
import SelectClientOrGroup from 'components/SelectClientOrGroup/SelectClientOrGroup';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';

interface SelectClientModalProps {
  title: string;
  visible: boolean;
  onCloseModal: () => void;
  onSelectClientRecord: (selectedClientRecord: clientRecordsInterface) => void;
  onSelectGroup?: (selectedGroup: GroupsFromAPI) => void;
  selectedClientId: string;
  selectedGroupId?: string;
  participationType: ParticipantType;
  onChangeParticipationType?: (val: ParticipantType) => void;
  disableGroupSelection?: boolean;
  hideAddNewClientButton?: boolean;
}

const SelectClientModal = ({
  title,
  visible,
  onCloseModal,
  onSelectClientRecord,
  onSelectGroup,
  selectedClientId,
  selectedGroupId,
  participationType,
  onChangeParticipationType,
  disableGroupSelection,
  hideAddNewClientButton
}: SelectClientModalProps) => {
  const { isGroupsFeatureToggle } = useGetFeatureToggle();

  const handleSelectClient = (clientRecord?: clientRecordsInterface) => {
    if (clientRecord) {
      onSelectClientRecord(clientRecord);
    }
    onCloseModal();
  };

  const handleSelectGroup = (selectedGroup: GroupsFromAPI, preventCloseModal?: boolean) => {
    onSelectGroup?.(selectedGroup);
    if (!preventCloseModal) {
      onCloseModal();
    }
  };

  return (
    <Modal open={visible} bodyStyle={{ padding: 0 }} footer={null} onCancel={onCloseModal} forceRender>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.content}>
        <SelectClientOrGroup
          enableGroupSelection={isGroupsFeatureToggle && !disableGroupSelection}
          defaultRecordId={selectedClientId}
          defaultGroupId={selectedGroupId}
          defaultParticipationType={participationType}
          onChangeSelectedParticipationType={onChangeParticipationType}
          handleSelectGroup={handleSelectGroup}
          handleSelectClientRecord={handleSelectClient}
          hideAddNewClientButton={hideAddNewClientButton}
        />
      </div>
    </Modal>
  );
};

export default SelectClientModal;
