import { config } from 'config/config';

export const mobileNumberConvert = (mobileNumber: string) => {
  const countryCodeEnv = config.mobileCountryCode;
  if (mobileNumber.startsWith('0')) {
    return `+${countryCodeEnv}${mobileNumber.slice(1)}`;
  } else if (mobileNumber.startsWith(countryCodeEnv)) {
    return `+${mobileNumber}`;
  } else {
    return mobileNumber;
  }
};
