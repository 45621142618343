import { GroupPsychometricMenu } from 'pages/Groups/Interfaces/Groups';
import { Route, Routes } from 'react-router-dom';
import PsychometricList from './components/PsychometricList/PsychometricList';
import PsychometricDetail from './components/PsychometricDetail/PsychometricDetail';

interface PsychometricsProps {
  psychometricId?: string;
  selectedClinicalAssessmentId?: string;
  psychometricMenuList: GroupPsychometricMenu[];
  onCreatePsychometricList: () => void;
}

const Psychometrics = ({
  psychometricId,
  psychometricMenuList,
  selectedClinicalAssessmentId,
  onCreatePsychometricList
}: PsychometricsProps) => {
  return (
    <Routes>
      <Route
        index
        element={
          <PsychometricList
            psychometricId={psychometricId}
            selectedClinicalAssessmentId={selectedClinicalAssessmentId}
            psychometricMenuList={psychometricMenuList}
            onCreateSuccess={onCreatePsychometricList}
          />
        }
      />
      <Route path={`responseId/:responseId/clientRecordId/:clientRecordId`} element={<PsychometricDetail />} />
    </Routes>
  );
};

export default Psychometrics;
