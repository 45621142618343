import { useState } from 'react';
import logo from 'assets/images/tacklit-logo-blue.svg';

import styles from './HeaderT23.module.scss';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import Notification from '../Header/components/MyProfile/components/Notification/Notification';
import { useGetAccountPackageView } from '../../../utils/hooks/GetAccountInfo/accountPackageView';
import ModalV2 from '../../ModalV2/ModalV2';

interface HeaderProps {
  showSubTab?: boolean;
  showWorkspace?: boolean;
  className?: string;
}

const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL;

const Header = ({ showSubTab, showWorkspace, className }: HeaderProps) => {
  const { logout } = useAuth0();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [showAnalyticLogout, setShowAnalyticLogout] = useState(false);

  const handleLogout = async () => {
    setShowAnalyticLogout(true);
    setTimeout(() => {
      setShowAnalyticLogout(false);
      logout();
    }, 2000);
  };

  return (
    <div
      className={classNames(
        styles.container,
        className,
        showSubTab && styles.subTabShowing,
        showWorkspace && styles.showWorkspace
      )}
    >
      <div className={styles.leftContent} />
      <div className={styles.rightContent}>
        <div className={styles.logoWrapper}>
          <a href={'/'} rel="noreferrer" className={styles.logo} target={'_self'}>
            <img src={logo} alt={'logo'} />
          </a>
        </div>
        <Notification className={styles.notificationBtn} />
        <div className={isEdgeAdminView ? styles.buttonGold : styles.button} onClick={() => handleLogout()}>
          <i className="material-icons-outlined">logout</i>
        </div>
        {ANALYTICS_URL && (
          <ModalV2
            containerClassName={styles.analyticLogoutContainer}
            isModalOpen={showAnalyticLogout}
            title={'Logout'}
            onModalClose={() => setShowAnalyticLogout(false)}
            mask={false}
          >
            <iframe title={'analytic'} width={'100%'} height={'100%'} src={`${ANALYTICS_URL}/logout`} frameBorder={0} />
          </ModalV2>
        )}
      </div>
    </div>
  );
};

export default Header;
