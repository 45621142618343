import { clientRecordsInterface, ClientRecordType, ProfileRole } from 'interfaces/Clients/clientsRecord';

export const getMedicareProfilesFromClientRecord = (clientRecord: clientRecordsInterface) => {
  const isChildOrYpp = checkIsShouldHaveParentMedicare(clientRecord);
  const clientProfile =
    (clientRecord.recordType === ClientRecordType.Adult
      ? clientRecord.clientProfiles[0]
      : clientRecord.recordType === ClientRecordType.Child
      ? clientRecord.clientProfiles.find(({ role }) => role === ProfileRole.Child)
      : clientRecord.recordType === ClientRecordType.YoungPerson
      ? clientRecord.clientProfiles.find(({ role }) => role === ProfileRole.YoungPerson)
      : clientRecord.clientProfiles[0]) || clientRecord.clientProfiles[0];
  const parentMedicareProfile = isChildOrYpp
    ? clientRecord.clientProfiles.find(
        (profile) => profile.medicare && profile.role !== ProfileRole.Child && profile.role !== ProfileRole.YoungPerson
      )
    : undefined;

  return { clientProfile, parentMedicareProfile };
};

export const checkIsShouldHaveParentMedicare = (clientRecord: clientRecordsInterface) =>
  [ClientRecordType.Child, ClientRecordType.YoungPerson].includes(clientRecord.recordType);
