import { clientRecordsInterface } from '../Clients/clientsRecord';

export interface ReportDimension {
  width: number | string;
  height: number | string;
}

export interface ReportAccessor {
  _id?: string;
  email?: string;
  isClient?: boolean;
  uniqueLink: string;
  note?: string;
  sendAt?: string;
  sentAt?: string;
}

export interface FaxReport {
  _id: string;
  faxJobId: string;
  recipientName?: string;
  sendAt: string;
  sendFrom: string;
  sendTo: string;
  sentAt?: string;
  status: FaxJobStatus;
}

export interface ShareDetails {
  sendAt?: Date | string;
  sendClient?: boolean;
  sendTo: string[];
  note?: string;
  accessors?: ReportAccessor[];
  faxes?: FaxReport[];
}

export enum FaxJobStatus {
  Received = 'RECEIVED',
  Scheduled = 'SCHEDULED',
  SendFailed = 'SEND_FAILED',
  SendProcessing = 'SEND_PROCESSING',
  Sent = 'SENT'
}

export interface Report {
  _id: string;
  status: 'published' | 'approved' | 'inReview' | 'draft' | 'unpublished';
  reportName: string;
  lastUpdatedTime: Date;
  issueDate: string;
  approvalRequired?: boolean;
  isEditing?: boolean;
  isForPractice?: boolean;
  authorDetail?: {
    avatar?: string;
    email: string;
    name: string;
  };
  shareDetails: ShareDetails;
  contactDetails: ReportContactDetails;
  template: {
    dimensions: ReportDimension;
  };
  clientRecord: clientRecordsInterface;
  clinician: {
    _id: string;
    avatar: string;
    email: string;
    mobileNumber: string;
    name: string;
  };
  practice: {
    address: string;
    logo: string;
    mobileNumber: string;
    name: string;
  };
  clinicianSignature: {
    type: string;
    textVal: string;
    drawVal: string;
  };
  clinicianSignatureExtraDetails: {
    valediction: string;
    email: string;
    mobileNumber: string;
    name: string;
    other: string;
  };
  items: {
    id: string;
    position: {
      top: number;
      left: number;
    };
    dimensions: {
      width: number | string;
      height: number | string;
    };
    details: any;
  }[];
  comments?: {
    _id: string;
    comments: {
      content: string;
      commentedBy: Collaborator;
      commentedAt: string;
    }[];
    position: {
      top: number;
      left: number;
    };
  }[];
  reviewers?: Collaborator[];
  statusHistory?: {
    status: string;
    statusChangedAt: Date | string;
    statusChangedBy: Collaborator;
    review?: {
      reviewType: string;
      message?: string;
    };
  }[];
  review?: {
    reviewType: 'feedback' | 'approved' | 'changesRequested';
    message?: string;
  };
  editedByReviewer?: boolean;
  sessionLock?: {
    by: Collaborator;
    expiredAt: Date | string;
  };
  createdAt: string;
  attachment?: {
    _id: string;
    title: string;
  };
  episodeId?: string;
}

export interface ReportContactDetails {
  practice: {
    address: {
      value: string;
      isVisible: boolean;
    };
    mobileNumber: {
      value: string;
      isVisible: boolean;
    };
  };
  clinician: {
    mobileNumber: {
      value: string;
      isVisible: boolean;
    };
    email: {
      value: string;
      isVisible: boolean;
    };
  };
}

export type ReportListInterface = Pick<
  Report,
  | '_id'
  | 'clientRecord'
  | 'clinician'
  | 'practice'
  | 'isForPractice'
  | 'reportName'
  | 'createdAt'
  | 'lastUpdatedTime'
  | 'status'
  | 'shareDetails'
  | 'editedByReviewer'
  | 'review'
>;

export interface ReportClientTab {
  _id: string;
  status?: string;
  reportName: string;
  lastUpdatedTime?: Date;
  createdAt: string;
}

export interface SurveyClientTab {
  _id: string;
  status?: string;
  name: string;
  lastUpdatedTime?: Date;
  createdAt: string;
  updatedAt: string;
}

export interface Collaborator {
  clinicianAuth0Id: string;
  name: string;
  avatar: string;
  notificationDismissedAt?: string;
}

export enum ReportWidgetType {
  HeadingWidget = 'headingWidget',
  BodyTextWidget = 'bodyTextWidget',
  FootNotesTextWidget = 'footNotesTextWidget',
  JournalCommentWidget = 'journalCommentWidget',
  InsightsChartWidget = 'insightsChartWidget',
  BarLineChartWidget = 'barLineChartWidget',
  AssessmentWidget = 'assessmentWidget',
  BodyTextTemplateWidget = 'bodyTextTemplateWidget',
  AssessmentTemplateWidget = 'assessmentTemplateWidget',
  ClientProfileWidget = 'clientProfileWidget',
  NotesWidget = 'notesWidget',
  CaseNoteQuestionWidget = 'caseNoteQuestionWidget'
}

export enum ReportBuilderWidgetView {
  WidgetMenuView = 'WidgetMenuView',
  NotesWidgetView = 'notesWidgetView',
  PsychometricWidgetView = 'PsychometricWidgetView',
  JournalCommentWidgetView = 'journalCommentWidgetView',
  LiveChartWidgetView = 'liveChartWidgetView',
  ReportTemplateView = 'reportTemplateView'
}
