import { InvoicePayment } from 'pages/InvoicePayments/constants';
import queryString from 'query-string';
import { BSTagTypes, billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';

export interface GetInvoicePaymentQueryParams {
  page: number;
  perPage: number;

  searchValue?: string;
  searchBy?: 'chargeId' | 'appointmentId';

  sortByReceived?: 1 | -1;

  from?: string;
  to?: string;

  clientRecordIds?: string;
  appointmentIds?: string;

  status?: string;
}

export interface GetInvoicePaymentResponseData {
  paymentRequest: InvoicePayment[];
  paging: { page: number; perPage: number; totalItems: number };
}

export const invoicePaymentsApiSlice = billingServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvoicePayments: builder.query<
      GetInvoicePaymentResponseData,
      { accountId: string; params: GetInvoicePaymentQueryParams }
    >({
      query: ({ accountId, params }) => {
        const stringifiedQuery = params ? queryString.stringify(params) : '';
        return {
          url: `accounts/${accountId}/payment-requests?${stringifiedQuery}`
        };
      },
      providesTags: [BSTagTypes.InvoicePayments]
    })
  })
});

export const { useGetInvoicePaymentsQuery } = invoicePaymentsApiSlice;
