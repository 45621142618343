import queryString from 'query-string';
import { InvoiceGenerationFormValue } from 'redux/invoices/createInvoiceWithTemplateSlice';
import { Invoice, InvoiceStatus } from 'pages/Invoices/interface';
import { BSTagTypes, billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';
import {
  GetInvoiceListData,
  InvoiceListRequestPayload,
  InvoiceWithType
} from 'pages/InvoicesV2/components/InvoiceList/constants';
import { WriteOffInvoiceListRequestPayload } from 'redux/invoices/useGetWriteOffInvoices';

interface CreateInvoiceRequestPayload {
  payload: InvoiceGenerationFormValue;
}

interface UpdateInvoiceRequestPayload extends CreateInvoiceRequestPayload {
  invoiceId: string;
}

interface InvoiceData {
  total: number;
  quantity: number;
}

interface InvoiceSummaryData {
  generatedInvoices: InvoiceData;
  paymentCollected: InvoiceData;
  overdueInvoices: InvoiceData;
  failedPayment: InvoiceData;
  writeOffPayments: InvoiceData;
  creditPayments: InvoiceData;
}

interface SendInvoiceRequestPayload {
  payload: {
    sendAt: Date;
    sendClient: boolean;
    sendTo?: string[];
    note?: string;
  };
  invoiceId: string;
}

export interface FullRefundResponse {
  errors: string[];
  failed: number;
  success: number;
  total: number;
}
export interface PartialRefundResponse {
  error: string;
  status: string;
}

export interface CreateCardPaymentPayload {
  amount: number;
  paymentMethodId: string;
  isSaveCard: boolean;
}
interface CardPaymentResponse {
  success?: boolean;
  message?: string;
}

export type CreateCardPaymentResponse = CardPaymentResponse;
export type CreateOwedPaymentResponse = CardPaymentResponse;

interface ManualUpdateBalancePayload {
  amount: number;
  type: string;
  date: string;
  reference?: string;
}

interface WriteOffPayload {
  date: string;
  reference?: string;
}

export const getInvoicesApiSlice = billingServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createInvoice: builder.mutation({
      query: ({ payload }: CreateInvoiceRequestPayload) => ({
        url: `/invoices`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [BSTagTypes.Invoices, BSTagTypes.InvoiceSummary]
    }),
    updateInvoice: builder.mutation({
      query: ({ invoiceId, payload }: UpdateInvoiceRequestPayload) => ({
        url: `/invoices/${invoiceId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: BSTagTypes.Invoice, id: arg.invoiceId },
        { type: BSTagTypes.Invoices }
      ]
    }),
    getInvoices: builder.query<GetInvoiceListData, InvoiceListRequestPayload>({
      query: ({ accountId, params }) => {
        const stringifiedQuery = params ? queryString.stringify(params) : '';
        return {
          url: `accounts/${accountId}/invoices?${stringifiedQuery}`
        };
      },
      providesTags: [BSTagTypes.Invoices]
    }),
    getInvoiceById: builder.query<Invoice, { invoiceId: string }>({
      query: ({ invoiceId }) => ({
        url: `/invoices/${invoiceId}`
      }),
      providesTags: (_result, _error, arg) => [{ type: BSTagTypes.Invoice, id: arg.invoiceId }]
    }),
    sendInvoice: builder.mutation({
      query: ({ invoiceId, payload }: SendInvoiceRequestPayload) => ({
        url: `/invoices/${invoiceId}:send`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: BSTagTypes.Invoice, id: arg.invoiceId },
        { type: BSTagTypes.Invoices }
      ]
    }),
    resendInvoice: builder.mutation({
      query: ({ invoiceId }: { invoiceId: string }) => ({
        url: `/invoices/${invoiceId}:resend`,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: BSTagTypes.Invoice, id: arg.invoiceId },
        { type: BSTagTypes.Invoices }
      ]
    }),
    updateInvoiceStatus: builder.mutation({
      query: ({ invoiceId, status }: { invoiceId: string; status: InvoiceStatus }) => ({
        url: `/invoices/${invoiceId}/status`,
        method: 'PATCH',
        body: { status }
      }),
      invalidatesTags: (_result, error, arg) => {
        if (!error) {
          return [
            { type: BSTagTypes.Invoice, id: arg.invoiceId },
            { type: BSTagTypes.Invoices },
            { type: BSTagTypes.InvoiceSummary }
          ];
        }
        return [];
      }
    }),
    getInvoiceSummary: builder.query<
      InvoiceSummaryData,
      { accountId: string; params?: { from?: string; to?: string; clientRecordId?: string } }
    >({
      query: ({ accountId, params }) => {
        const stringifiedQuery = params ? queryString.stringify(params) : '';
        return {
          url: `/accounts/${accountId}/invoices/invoice-summary?${stringifiedQuery}`
        };
      },
      providesTags: [BSTagTypes.InvoiceSummary]
    }),
    createCardPayment: builder.mutation({
      query: ({
        accountId,
        invoiceId,
        payload
      }: {
        accountId: string;
        invoiceId: string;
        payload: CreateCardPaymentPayload;
      }) => ({
        url: `/accounts/${accountId}/invoices/${invoiceId}/card-payment`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: (_result, error, arg) => {
        const res = error?.data ? (error.data as CreateCardPaymentResponse) : {};
        return res?.success === false
          ? []
          : [
              { type: BSTagTypes.Invoice, id: arg.invoiceId },
              { type: BSTagTypes.Invoices },
              { type: BSTagTypes.InvoiceSummary }
            ];
      }
    }),
    fullRefund: builder.mutation({
      query: ({ accountId, invoiceId }: { accountId: string; invoiceId: string }) => ({
        url: `/accounts/${accountId}/invoices/${invoiceId}/refund`,
        method: 'POST'
      }),
      // Need to check result to set invalidatesTags because the Stripe might return 200 even got error
      invalidatesTags: (result: FullRefundResponse | undefined, _error, arg) =>
        result?.failed === result?.total
          ? []
          : [
              { type: BSTagTypes.Invoice, id: arg.invoiceId },
              { type: BSTagTypes.Invoices },
              { type: BSTagTypes.InvoiceSummary }
            ]
    }),
    partialRefund: builder.mutation({
      query: ({
        accountId,
        paymentRequestId,
        amount
      }: {
        accountId: string;
        paymentRequestId: string;
        amount?: number;
      }) => ({
        url: `/accounts/${accountId}/payment-requests/${paymentRequestId}/refund`,
        method: 'POST',
        body: { ...(amount && { amount }) }
      }),
      // Need to check result to set invalidatesTags because the Stripe might return 200 even got error
      invalidatesTags: (result: PartialRefundResponse | undefined, error, _arg) =>
        result?.error || error ? [] : [BSTagTypes.Invoices, BSTagTypes.InvoiceSummary, BSTagTypes.InvoicePayments]
    }),
    createOwedPayment: builder.mutation({
      query: ({ accountId, invoiceId }: { accountId: string; invoiceId: string }) => ({
        url: `/accounts/${accountId}/invoices/${invoiceId}/owed-payment`,
        method: 'POST'
      }),
      invalidatesTags: (_result, error, arg) => {
        const res = error?.data ? (error.data as CreateOwedPaymentResponse) : {};
        return res?.success === false || error
          ? []
          : [
              { type: BSTagTypes.Invoice, id: arg.invoiceId },
              { type: BSTagTypes.Invoices },
              { type: BSTagTypes.InvoiceSummary }
            ];
      }
    }),
    manualUpdateBalance: builder.mutation({
      query: ({
        accountId,
        invoiceId,
        body
      }: {
        accountId: string;
        invoiceId: string;
        body: ManualUpdateBalancePayload;
      }) => ({
        url: `/accounts/${accountId}/invoices/${invoiceId}/manual-balance`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (_result, error, arg) => {
        return error
          ? []
          : [
              { type: BSTagTypes.Invoice, id: arg.invoiceId },
              { type: BSTagTypes.Invoices },
              { type: BSTagTypes.InvoiceSummary }
            ];
      }
    }),

    writeOff: builder.mutation({
      query: ({ accountId, invoiceId, body }: { accountId: string; invoiceId: string; body: WriteOffPayload }) => ({
        url: `/accounts/${accountId}/invoices/${invoiceId}/write-off`,
        method: 'POST',
        body
      }),
      invalidatesTags: (_result, error, arg) => {
        return error
          ? []
          : [
              { type: BSTagTypes.Invoice, id: arg.invoiceId },
              { type: BSTagTypes.Invoices },
              { type: BSTagTypes.InvoiceSummary },
              { type: BSTagTypes.WriteOffInvoices }
            ];
      }
    }),

    getWriteOffInvoices: builder.query<GetInvoiceListData, WriteOffInvoiceListRequestPayload>({
      query: ({ accountId, params }) => {
        const stringifiedQuery = params ? queryString.stringify(params) : '';
        return {
          url: `accounts/${accountId}/invoices?${stringifiedQuery}`
        };
      },
      providesTags: [BSTagTypes.WriteOffInvoices]
    }),

    getInvoiceWithTypeById: builder.query<InvoiceWithType, { invoiceId: string }>({
      query: ({ invoiceId }) => ({
        url: `/invoices/${invoiceId}?includeInvoiceType=1`
      }),
      providesTags: (_result, _error, arg) => [{ type: BSTagTypes.InvoiceWithType, id: arg.invoiceId }]
    }),

    adjustTotalAmount: builder.mutation({
      query: ({ invoiceId, invoiceAmount }: { invoiceId: string; invoiceAmount: number }) => ({
        url: `/invoices/${invoiceId}/amount`,
        method: 'PATCH',
        body: { invoiceAmount }
      }),
      invalidatesTags: (_result, error, arg) => {
        return error
          ? []
          : [
              { type: BSTagTypes.Invoice, id: arg.invoiceId },
              { type: BSTagTypes.Invoices },
              { type: BSTagTypes.InvoiceSummary }
            ];
      }
    })
  })
});

export const {
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useGetInvoicesQuery,
  useGetInvoiceByIdQuery,
  useSendInvoiceMutation,
  useResendInvoiceMutation,
  useUpdateInvoiceStatusMutation,
  useGetInvoiceSummaryQuery,
  useCreateCardPaymentMutation,
  useFullRefundMutation,
  usePartialRefundMutation,
  useCreateOwedPaymentMutation,
  useManualUpdateBalanceMutation,
  useWriteOffMutation,
  useGetWriteOffInvoicesQuery,
  useGetInvoiceWithTypeByIdQuery,
  useAdjustTotalAmountMutation
} = getInvoicesApiSlice;
