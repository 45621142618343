import { Skeleton } from 'antd';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import QuestionForm from '../QuestionForm/QuestionForm';
import QuestionListWithCheckBox from '../QuestionListWithCheckBox/QuestionListWithCheckBox';
import styles from './QuestionList.module.scss';

export interface QuestionListProps {
  questionSet: any;
  questionNum: number;
  onAddQuestion: any;
  onChangeQuestion: any;
  onChangeCheckBox: any;
  onClickSave: any;
  disabled?: boolean;
  onDeleteQuestion: (groupId: string, questionId: string) => void;
  isOpenDataCategoriesLoading?: boolean;
}

const QuestionList = ({
  questionSet,
  questionNum,
  onAddQuestion,
  onChangeQuestion,
  onChangeCheckBox,
  onClickSave,
  onDeleteQuestion,
  disabled,
  isOpenDataCategoriesLoading
}: QuestionListProps) => {
  const onAddNewQuestion = (newQData: any) => {
    onAddQuestion(questionSet.id, newQData);
  };

  const onAddDraftQuestion = (question: any) => {
    onChangeQuestion(questionSet.id, question);
  };

  const onDeleteDraftQuestion = (questionId: string) => {
    onDeleteQuestion(questionSet.id, questionId);
  };

  const onCheckChange = (groupId: string, value: any) => {
    onChangeCheckBox(groupId, value);
  };

  const onDeleteQuestionHandler = (groupId: string, questionId: string) => {
    onDeleteQuestion(groupId, questionId);
  };

  const isDataCollection = questionSet.questionSetType === 'dataCollection';

  return questionSet.sectionName !== '' ? (
    isOpenDataCategoriesLoading ? (
      <>
        {[...Array(3)].map((obj, i) => (
          <div key={i} className={styles.loadingWrapper}>
            <Skeleton.Input active className={styles.loading} />
          </div>
        ))}
      </>
    ) : (
      <Droppable droppableId={`droppable${questionSet.id}`} type={`${questionNum}`}>
        {(provided) => (
          <div className={styles.container} ref={provided.innerRef}>
            {questionSet.questions.map((question: any, index: any) => {
              return (
                !question.isDraftQ && (
                  <Draggable
                    isDragDisabled={disabled || isDataCollection}
                    key={`${questionNum}${index}`}
                    draggableId={`${questionNum}${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <QuestionListWithCheckBox
                          label={question.stem}
                          id={question.id}
                          questionId={question.id}
                          isDraftQ={question.isDraftQ}
                          isChecked={question.isChecked}
                          groupId={questionSet.id}
                          onCheckChange={onCheckChange}
                          disabled={disabled}
                          onDeleteQuestion={onDeleteQuestionHandler}
                          question={question}
                          isDataCollection={isDataCollection}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              );
            })}
            {provided.placeholder}
            {!disabled && !isDataCollection && (
              <QuestionForm
                onAddQuestion={onAddNewQuestion}
                onAddDraftQuestion={onAddDraftQuestion}
                onRemoveDraftQuestion={onDeleteDraftQuestion}
              />
            )}
          </div>
        )}
      </Droppable>
    )
  ) : (
    <div className={styles.noQuestionContainer}>
      Each topic has a question library to which you can add / remove questions
    </div>
  );
};

export default QuestionList;
