import FlexBox from 'components/FlexBox/FlexBox';
import { clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import { IInvoiceTemplate } from 'pages/Invoices/interface';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectInvoiceForm, setInvoiceForm } from 'redux/invoices/createInvoiceWithTemplateSlice';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import InlineEditField from '../InlineEditField/InlineEditField';
import styles from './ServiceDeliverySection.module.scss';
import { useMemo } from 'react';

interface ServiceDeliverySectionProps {
  invoiceTemplate: IInvoiceTemplate;
  clientEncryptDetails?: clientRecordsEncryptedInterface;
}

const ServiceDeliverySection = ({ invoiceTemplate }: ServiceDeliverySectionProps) => {
  const dispatch = useAppDispatch();

  const { isRegionAU } = useGetFeatureToggle();
  const invoiceForm = useAppSelector(selectInvoiceForm);

  const { medicareClaimant, medicareClaim, medicareServiceProvider, medicareReferral } = invoiceTemplate;
  const { medicare, items } = invoiceForm;

  const serviceDeliveryField = useMemo(
    () => [
      {
        medicareClaimant: {
          show: medicareClaimant,
          data: [
            {
              label: 'Name',
              show: true,
              value: medicare?.claimant?.name || '',
              name: 'name',
              editable: true
            },
            {
              label: 'Date of birth',
              show: true,
              value: medicare?.claimant?.dateOfBirth || '',
              name: 'dateOfBirth',
              editable: true
            },
            {
              label: 'Medicare Card No',
              show: isRegionAU,
              value: medicare?.claimant?.medicareNumber || '',
              name: 'medicareNumber',
              editable: true
            },
            {
              label: 'IRN',
              show: isRegionAU,
              value: medicare?.claimant?.irn || '',
              name: 'irn',
              editable: true
            },
            {
              label: 'Address',
              show: true,
              value: medicare?.claimant?.address || '',
              name: 'address',
              editable: true
            },
            {
              label: 'Phone Number',
              show: true,
              value: medicare?.claimant?.phoneNumber || '',
              name: 'phoneNumber',
              editable: true
            }
          ]
        },
        medicareClaim: {
          show: isRegionAU && medicareClaim,
          data: [
            {
              label: 'Date of Service',
              show: isRegionAU,
              value: medicare?.claim?.dateOfService || '',
              name: 'dateOfService',
              editable: true
            },
            {
              label: 'MBS Code',
              show: isRegionAU,
              value: medicare?.claim?.mbsCode || '',
              name: 'mbsCode',
              editable: true
            }
          ]
        },
        medicareServiceProvider: {
          show: isRegionAU && medicareServiceProvider,
          data: [
            {
              label: 'Provider Name',
              show: isRegionAU,
              value: medicare?.serviceProvider?.name || '',
              name: 'name',
              editable: true
            },
            {
              label: 'Provider No',
              show: isRegionAU,
              value: medicare?.serviceProvider?.providerNumber || '',
              name: 'providerNumber',
              editable: true
            }
          ]
        },
        medicareReferral: {
          show: medicareReferral,
          data: [
            {
              label: 'Referral Name',
              show: true,
              value: medicare?.referral?.name || '',
              name: 'name',
              editable: true
            },
            {
              label: 'Date of referral',
              show: true,
              value: medicare?.referral?.date || '',
              name: 'date',
              editable: true
            },
            {
              label: 'Provider Number',
              show: isRegionAU,
              value: medicare?.referral?.providerNumber || '',
              name: 'providerNumber',
              editable: true
            }
          ]
        }
      }
    ],
    [isRegionAU, medicare, medicareClaim, medicareClaimant, medicareReferral, medicareServiceProvider]
  );

  const onChangeClaimant = (e: { target: { name: string; value: string } }) => {
    dispatch(
      setInvoiceForm({
        medicare: {
          ...medicare,
          claimant: {
            ...medicare?.claimant,
            [e.target.name]: e.target.value
          }
        }
      })
    );
  };

  const onChangeReferral = (e: { target: { name: string; value: string } }) => {
    dispatch(
      setInvoiceForm({
        medicare: {
          ...medicare,
          referral: {
            ...medicare?.referral,
            [e.target.name]: e.target.value
          }
        }
      })
    );
  };

  const onChangeServiceDelivery = (e: { target: { name: string; value: string } }) => {
    dispatch(
      setInvoiceForm({
        medicare: {
          ...medicare,
          serviceProvider: {
            ...medicare?.serviceProvider,
            [e.target.name]: e.target.value
          }
        }
      })
    );
  };

  const onChangeClaim = (e: { target: { name: string; value: string } }) => {
    dispatch(
      setInvoiceForm({
        medicare: {
          ...medicare,
          claim: {
            ...medicare?.claim,
            [e.target.name]: e.target.value
          }
        }
      })
    );
  };

  return (
    <div className={styles.wrapper}>
      {serviceDeliveryField.map((item, index) => (
        <FlexBox direction="column" spacing={20} key={index}>
          {item?.medicareClaimant?.show && (
            <FlexBox direction="column" spacing={4}>
              <span className={styles.title}>Client Identity Details</span>
              <FlexBox direction="row" justifyContent="space-between">
                <FlexBox direction="column" className={styles.width350} spacing={4}>
                  {item.medicareClaimant.data
                    .slice(0, 3)
                    .filter((item) => item.show)
                    .map((item, index) => (
                      <InlineEditField
                        key={index}
                        label={item.label}
                        value={item.value}
                        name={item.name}
                        editable={item.editable}
                        onChange={onChangeClaimant}
                        onChangeDate={onChangeClaimant}
                      />
                    ))}
                </FlexBox>
                <FlexBox direction="column" className={styles.width350} spacing={4}>
                  {item.medicareClaimant.data
                    .slice(3, 6)
                    .filter((item) => item.show)
                    .map((item, index) => (
                      <InlineEditField
                        key={index}
                        label={item.label}
                        value={item.value}
                        name={item.name}
                        editable={item.editable}
                        onChange={onChangeClaimant}
                        onChangeDate={onChangeClaimant}
                      />
                    ))}
                </FlexBox>
              </FlexBox>
            </FlexBox>
          )}
          {items.length < 2 && (item?.medicareClaim?.show || item?.medicareServiceProvider.show) ? (
            <FlexBox direction="row" justifyContent="space-between">
              {item.medicareClaim.show && (
                <FlexBox direction="column" className={styles.width350} spacing={4}>
                  <span className={styles.title}>MBS Claim Details</span>
                  {item.medicareClaim.data
                    .filter((item) => item.show)
                    .map((item, index) => (
                      <InlineEditField
                        key={index}
                        label={item.label}
                        value={item.value}
                        name={item.name}
                        editable={item.editable}
                        onChange={onChangeClaim}
                        onChangeDate={onChangeClaim}
                      />
                    ))}
                </FlexBox>
              )}
              {item.medicareServiceProvider.show && (
                <FlexBox direction="column" className={styles.width350} spacing={4}>
                  <span className={styles.title}>Service Provider Details</span>
                  {item.medicareServiceProvider.data
                    .filter((item) => item.show)
                    .map((item, index) => (
                      <InlineEditField
                        key={index}
                        label={item.label}
                        value={item.value}
                        name={item.name}
                        editable={item.editable}
                        onChange={onChangeServiceDelivery}
                      />
                    ))}
                </FlexBox>
              )}
            </FlexBox>
          ) : null}
          {item?.medicareReferral?.show && (
            <FlexBox direction="column" className={styles.width350} spacing={4}>
              <span className={styles.title}>Referral Details</span>
              {item.medicareReferral.data
                .filter((item) => item.show)
                .map((item, index) => (
                  <InlineEditField
                    key={index}
                    label={item.label}
                    value={item.value}
                    name={item.name}
                    editable={item.editable}
                    onChange={onChangeReferral}
                    onChangeDate={onChangeReferral}
                  />
                ))}
            </FlexBox>
          )}
        </FlexBox>
      ))}
    </div>
  );
};

export default ServiceDeliverySection;
