import { notification } from 'antd';
import { useState, useEffect } from 'react';
import { getNotificationSettings } from 'utils/http/ClinicianProfileService/Accounts/accounts';

export enum NotificationPreference {
  Email = 'email',
  SMS = 'sms',
  All = 'all',
  None = 'none',

  // group appointments only
  AppointmentOwner = 'appointmentOwner',
  AllStaff = 'allStaff'
}

export interface NotificationSettingsInterface {
  invoice: NotificationPreference.None;
  reportsAndLetters: NotificationPreference.Email;
  accountInvite: Exclude<NotificationPreference, NotificationPreference.None>;
  forms: Exclude<NotificationPreference, NotificationPreference.None>;
  appointmentConfirmation: NotificationPreference;
  appointmentReminder: NotificationPreference;

  // practitioner notifications
  practitionerAppointmentConfirmation: NotificationPreference.Email | NotificationPreference.None;
  practitionerAppointmentCancellation: NotificationPreference.Email | NotificationPreference.None;
  practitionerGroupAppointment: NotificationPreference.AppointmentOwner | NotificationPreference.AllStaff;
  [index: string]: NotificationPreference;
}

export const useFetchNotificationSettings = (token: string, accountId: string) => {
  const [notificationSettings, setNotificationSettings] = useState<NotificationSettingsInterface>(
    {} as NotificationSettingsInterface
  );
  const [isNotificationSettingsLoading, setIsNotificationSettingsLoading] = useState(true);

  useEffect(() => {
    const fetchNotificationSettings = async (token: string) => {
      setIsNotificationSettingsLoading(true);
      try {
        const getNotificationSettingsResponses = await getNotificationSettings(token, accountId);
        const notificationSettings = (await getNotificationSettingsResponses.json()) as NotificationSettingsInterface;
        setNotificationSettings(notificationSettings);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get notification settings' });
      }
      setIsNotificationSettingsLoading(false);
    };

    if (token && accountId) {
      fetchNotificationSettings(token);
    }
  }, [accountId, token]);

  return { notificationSettings, isNotificationSettingsLoading };
};
