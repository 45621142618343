import styles from './PackageIcon.module.scss';
import {
  BillPayer,
  PackagesBenefit,
  PackagesBenefitLimit,
  PackagesServiceSequence
} from 'interfaces/Packages/packages';
import classNames from 'classnames';

export interface PackageIconProps {
  settingsType?: PackagesServiceSequence | PackagesBenefit | PackagesBenefitLimit | BillPayer;
  label?: string;
}

const PackageIcon = ({ settingsType, label }: PackageIconProps) => {
  const getSettings = () => {
    switch (settingsType) {
      case PackagesServiceSequence.Any:
        return {
          icon: 'shuffle',
          label: 'Any Order'
        };
      case PackagesServiceSequence.Sequence:
        return {
          icon: 'low_priority',
          label: 'Defined sequence'
        };
      case PackagesBenefit.Client:
        return {
          icon: 'loyalty',
          label: 'Benefit by client'
        };
      case PackagesBenefit.Funder:
        return {
          icon: 'loyalty',
          label: 'Benefit by funder'
        };
      case PackagesBenefitLimit.None:
        return {
          icon: 'pause_circle_outline',
          label: 'No limits set'
        };
      case PackagesBenefitLimit.TotalServices:
        return {
          icon: 'pause_circle_outline',
          label: 'Limit by service'
        };
      case PackagesBenefitLimit.DollarSpent:
        return {
          icon: 'pause_circle_outline',
          label: 'Limit by spend'
        };
      case PackagesBenefitLimit.NumberOfClients:
        return {
          icon: 'pause_circle_outline',
          label: 'Limit by service'
        };
      case BillPayer.EachClient:
        return {
          icon: 'receipt_long',
          label: 'Bill by each client'
        };
      case BillPayer.GroupByFunder:
        return {
          icon: 'receipt_long',
          label: 'Bill by grouped funder'
        };
      default:
        return {
          icon: '',
          label: ''
        };
    }
  };

  return (
    <div className={styles.container}>
      <div className={classNames('material-symbols-outlined', styles.icon)}>{getSettings().icon}</div>
      <div className={styles.label}>{label || getSettings().label}</div>
    </div>
  );
};

export default PackageIcon;
