import { Modal, notification } from 'antd';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import HeadingAssessment from 'components/HeadingTemplate/HeadingTemplateList/components/HeadingAssessment/HeadingAssessment';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { RecordStatus, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { CaseNote, CaseNoteField } from 'interfaces/caseNote';
import { CaseNoteFormatSettingsFields, ParticipantType } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import { useGetCaseNoteListQuery, useGetCaseNoteQuery } from 'redux/endpoints/documentServices/caseNote';
import { getEgristUrlByClientRecordId } from 'utils/http/ClinicianProfileService/Egrist/egrist';
import { UserContext } from 'utils/UserContextProvider';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGenerateHeadingTemplateDetails } from 'utils/hooks/headingTemplate';
import { deleteNote } from 'utils/http/DocumentService/CaseNotes/caseNotes';
import styles from './PatientDetailsNotes.module.scss';
import FolderList, { FolderMetadata } from './components/FolderList/FolderList';
import PatientDetailsNoteDetails from './components/PatientDetailsNoteDetails/PatientDetailsNoteDetails';
import PatientDetailsNotesListing from './components/PatientDetailsNotesListing/PatientDetailsNotesListing';
import {
  DEFAULT_SHARED_FOLDER_ID,
  FOLDER_NAME,
  FOLDER_SORT_ORDER,
  SHARED_FOLDER_INITIAL_CONFIG,
  getPrivateFolderConfig
} from './folderConfigs';
import useGetCaseNoteTemplatesList from './hooks/useGetCaseNoteTemplatesList';
import { useAppSelector } from 'redux/hooks';
import {
  selectCurrentEpisodeId,
  selectIsFetchingEpisodes,
  selectIsOutsideAllEpisodes,
  selectIsShowAllData
} from 'redux/episodes/episodeSlice';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useFetchOpenDataCategories } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/hooks/useFetchOpenDataCategories';
import { formatCaseNoteTemplateFields } from './components/formatCaseNoteTemplateFields';
import { useFetchCommonODFVariable } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/hooks/useFetchCommonODFVariable';

const NEW_NOTE_TEMPORARY_ID = 'new';

interface PatientDetailsNotesProps {
  clientRecordData: clientRecordsInterface;
  currentTab: string;
  noteId?: string;
  profileId: string;
  recordId: string;
  token: string;
}

const PatientDetailsNotes = ({
  clientRecordData,
  currentTab,
  noteId: pathNoteId,
  profileId,
  recordId: clientRecordId,
  token
}: PatientDetailsNotesProps) => {
  const isClosedClient = clientRecordData.recordStatus === RecordStatus.Closed;

  const [t] = useTranslation();
  const { auth0ClinicianId: loggedInClinicianId } = useGetClinicianId();
  const { clinicianProfile: loggedInClinicianProfile } = useContext(UserContext);
  const { CLIENTS } = useRoutesGenerator();
  const {
    isHelmFeatureToggle,
    isCaWFeatureToggle,
    templateNameEnable,
    isEgristEnabled,
    isEoCEnabled,
    isHidePrivateCaseNotes
  } = useGetFeatureToggle();
  const { isNormalUserView, isEdgeAdminView } = useGetAccountPackageView();
  const { accountId } = useGetAccountId();
  const navigate = useNavigate();

  const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const isFetchingEpisodes = useAppSelector(selectIsFetchingEpisodes);

  const [addNoteButtonStatus, setAddNoteButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [caseNoteDetail, setCaseNoteDetail] = useState<CaseNote>();
  const [caseNoteList, setCaseNoteList] = useState<CaseNote[]>([]);
  const [caseNoteSearchVal, setCaseNoteSearchVal] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDraftSaved, setIsDraftSaved] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(DEFAULT_SHARED_FOLDER_ID);
  const [isFetchingEgristUrl, setIsFetchingEgristUrl] = useState(false);

  const isAddNoteButtonDisabled = useMemo(
    () => !!addNoteButtonStatus || isEditing || isClosedClient,
    [addNoteButtonStatus, isClosedClient, isEditing]
  );

  const { assessmentList, isHeadingTemplateDetailsLoading, generateHeadingTemplate } =
    useGenerateHeadingTemplateDetails(token, clientRecordId, profileId);

  const { openDataCategories, isOpenDataCategoriesLoading } = useFetchOpenDataCategories(token, accountId);
  const { scaleTypes, isCommonVariablesLoading } = useFetchCommonODFVariable();

  const {
    data: fetchedCaseNoteList,
    isLoading: isCaseNoteListLoading,
    isFetching: isCaseNoteListFetching
  } = useGetCaseNoteListQuery(
    {
      clientRecordId,
      payload: {
        withClinicianName: 1,
        ...(!isShowAllData && currentEpisodeId && isEoCEnabled && { episodeId: currentEpisodeId }),
        ...(!isShowAllData && isOutsideAllEpisodes && isEoCEnabled && { showOutsideOfEpisode: isOutsideAllEpisodes })
      }
    },
    {
      skip:
        (isEoCEnabled && isFetchingEpisodes) ||
        (isEoCEnabled && !isOutsideAllEpisodes && !isShowAllData && !currentEpisodeId)
    }
  );

  useEffect(() => {
    setCaseNoteList(fetchedCaseNoteList ?? []);
  }, [fetchedCaseNoteList]);

  const isCurrentUserAuthor = useCallback(
    (caseNoteClinicianId: string) => caseNoteClinicianId === loggedInClinicianId,
    [loggedInClinicianId]
  );

  const isNewNote = useMemo(() => pathNoteId === NEW_NOTE_TEMPORARY_ID, [pathNoteId]);

  const selectedCaseNoteSummary = useMemo(
    () => caseNoteList?.find((caseNote) => caseNote._id === pathNoteId),
    [caseNoteList, pathNoteId]
  );

  const isPrivateCaseNoteCreatedByOthers = useMemo(
    () => selectedCaseNoteSummary?.isPrivate && !isCurrentUserAuthor(selectedCaseNoteSummary?.clinicianId),
    [isCurrentUserAuthor, selectedCaseNoteSummary?.clinicianId, selectedCaseNoteSummary?.isPrivate]
  );

  const {
    data: fetchedCaseNote,
    isLoading: isCaseNoteLoading,
    isFetching: isCaseNoteFetching,
    refetch: refetchCaseNote
  } = useGetCaseNoteQuery(
    {
      clientRecordId: clientRecordId,
      caseNoteId: pathNoteId || ''
    },
    { skip: !pathNoteId || isPrivateCaseNoteCreatedByOthers || isNewNote }
  );

  const {
    currentTemplate: currentCaseNoteTemplate,
    isLoading: isTemplateListLoading,
    selectTemplate: selectCaseNoteTemplate,
    templatesList: caseNotesTemplateList
  } = useGetCaseNoteTemplatesList(token, ParticipantType.Individual, isEdgeAdminView || isNormalUserView);

  /**
   * The following variable is for tackling race condition issue and
   * ensure that the form is initialized with the latest fetched selected case note value.
   */
  const isEditDataLoading = useMemo(
    () => !isNewNote && isEditing && caseNoteDetail?._id !== pathNoteId,
    [caseNoteDetail?._id, isEditing, isNewNote, pathNoteId]
  );

  const contentLoading = useMemo(
    () =>
      isDraftSaved
        ? false
        : isCaseNoteLoading ||
          addNoteButtonStatus ||
          isTemplateListLoading ||
          isEditDataLoading ||
          isOpenDataCategoriesLoading ||
          isCommonVariablesLoading,
    [
      addNoteButtonStatus,
      isCaseNoteLoading,
      isDraftSaved,
      isEditDataLoading,
      isTemplateListLoading,
      isOpenDataCategoriesLoading,
      isCommonVariablesLoading
    ]
  );

  /** Group case notes into folders with metadata */
  const caseNotesInFolders = useMemo(() => {
    const foldersByFolderId: Record<string, { caseNotes: CaseNote[]; metadata: FolderMetadata }> = {};

    // Loop through the list of case notes and group them into folders
    caseNoteList?.forEach((caseNote) => {
      const isPrivate = !!caseNote.isPrivate;
      const folderId = isPrivate ? caseNote.clinicianId : DEFAULT_SHARED_FOLDER_ID;

      const folderName =
        folderId === DEFAULT_SHARED_FOLDER_ID
          ? FOLDER_NAME.SHARED
          : caseNote.isPrivate && isCurrentUserAuthor(caseNote.clinicianId)
          ? FOLDER_NAME.PRIVATE
          : `${caseNote.clinicianName}-Private Folder`;

      const sortOrder = isPrivate
        ? isCurrentUserAuthor(caseNote.clinicianId)
          ? FOLDER_SORT_ORDER.PRIVATE_OWN
          : FOLDER_SORT_ORDER.OTHERS
        : FOLDER_SORT_ORDER.SHARED;

      if (!foldersByFolderId[folderId]) {
        foldersByFolderId[folderId] = {
          caseNotes: [],
          metadata: {
            _id: folderId,
            ...(isPrivate && { clinicianName: caseNote.clinicianName }),
            folderName,
            isPrivate,
            noteCount: 0,
            sortOrder
          }
        };
      }

      const folder = foldersByFolderId[folderId];
      folder.caseNotes.push(caseNote);
      folder.metadata.noteCount += 1;
    });

    return foldersByFolderId;
  }, [caseNoteList, isCurrentUserAuthor]);

  const selectedFolder = useMemo(() => {
    const folderMetadata = caseNotesInFolders[selectedFolderId]?.metadata;
    const addable =
      !folderMetadata?.isPrivate || (folderMetadata?.isPrivate && isCurrentUserAuthor(folderMetadata._id));

    return { ...folderMetadata, addable };
  }, [caseNotesInFolders, isCurrentUserAuthor, selectedFolderId]);

  const isPrivateFolderSelected = selectedFolder.isPrivate;
  const isPrivateFolderForOthers = isPrivateFolderSelected && !selectedFolder.addable;

  const ensureDefaultFoldersExist = useCallback(
    (folderMetadataList: FolderMetadata[]) => {
      const hasSharedFolder = folderMetadataList.some((folder) => folder._id === DEFAULT_SHARED_FOLDER_ID);
      const hasPrivateOwnFolder = folderMetadataList.some((folder) => folder._id === loggedInClinicianId);

      if (!hasSharedFolder) {
        folderMetadataList.push(SHARED_FOLDER_INITIAL_CONFIG);
      }

      if (!hasPrivateOwnFolder) {
        folderMetadataList.push(getPrivateFolderConfig(loggedInClinicianId));
      }
    },
    [loggedInClinicianId]
  );

  const sortedFolderMetadataList = useMemo(() => {
    const folderIds = Object.keys(caseNotesInFolders);
    const folderMetadataList = folderIds.map((folderId) => caseNotesInFolders[folderId].metadata);

    ensureDefaultFoldersExist(folderMetadataList);

    folderMetadataList.sort((a, b) => a.sortOrder - b.sortOrder);

    return folderMetadataList;
  }, [caseNotesInFolders, ensureDefaultFoldersExist]);

  /** Update folder selection status */
  const foldersWithUpdatedSelection = useMemo(
    () =>
      sortedFolderMetadataList.map((folder) =>
        folder._id === selectedFolderId ? { ...folder, isSelected: true } : { ...folder, isSelected: false }
      ),
    [sortedFolderMetadataList, selectedFolderId]
  );

  /** Get the list of case notes for the selected folder */
  const selectedCaseNoteList = useMemo(() => {
    const _caseNoteList = caseNotesInFolders[selectedFolderId]?.caseNotes ?? [];

    if (caseNoteSearchVal) {
      return _caseNoteList.filter((caseNote) => caseNote.title.toLowerCase().includes(caseNoteSearchVal));
    }

    return _caseNoteList;
  }, [caseNoteSearchVal, caseNotesInFolders, selectedFolderId]);

  useEffect(() => {
    if (!pathNoteId) {
      setIsEditing(false);
    }
  }, [pathNoteId]);

  useEffect(() => {
    if (fetchedCaseNote) {
      setCaseNoteDetail(fetchedCaseNote);
    }
  }, [fetchedCaseNote]);

  const handleRedirect = useCallback(
    (id: string) => {
      const idPath = id ? `/${id}` : '';
      navigate(`${CLIENTS.BASE}/${clientRecordId}/${currentTab}${idPath}`);
    },
    [CLIENTS.BASE, clientRecordId, currentTab, navigate]
  );

  const changeTemplate = useCallback(
    async (id: string) => {
      const selected = selectCaseNoteTemplate(id);
      if (!selected) return;

      const body = await generateHeadingTemplate(selected.headings);

      const fields = (
        isHelmFeatureToggle || isCaWFeatureToggle
          ? selected.fields.filter((obj) => obj.name !== 'Safeguarding Alert')
          : selected.fields
      ) as CaseNoteFormatSettingsFields[];

      return {
        ...caseNoteDetail,
        body,
        ...(templateNameEnable && {
          templateName: selected.name
        }),
        fields: formatCaseNoteTemplateFields({
          fields,
          categories: openDataCategories,
          scaleTypes
        })
      } as CaseNote;
    },
    [
      caseNoteDetail,
      generateHeadingTemplate,
      isCaWFeatureToggle,
      isHelmFeatureToggle,
      openDataCategories,
      scaleTypes,
      selectCaseNoteTemplate,
      templateNameEnable
    ]
  );

  const handleAddNote = useCallback(async () => {
    setAddNoteButtonStatus('active');
    const headings = await generateHeadingTemplate(currentCaseNoteTemplate.headings);

    try {
      const payload: Record<string, any> = {
        _id: NEW_NOTE_TEMPORARY_ID,
        title: 'Case note',
        attachments: [],
        body: headings,
        clinicianId: loggedInClinicianId,
        clinicianName: loggedInClinicianProfile?.name,
        isPrivate: selectedFolderId !== DEFAULT_SHARED_FOLDER_ID,
        createdAt: moment().toISOString()
      };

      const defaultFieldSettings =
        isHelmFeatureToggle || isCaWFeatureToggle
          ? (caseNoteDetail?.fields.filter((obj) => obj.name !== 'Safeguarding Alert') as CaseNoteField[])
          : caseNoteDetail?.fields;

      const templateFields =
        isHelmFeatureToggle || isCaWFeatureToggle
          ? currentCaseNoteTemplate?.fields.filter((obj) => obj.name !== 'Safeguarding Alert')
          : currentCaseNoteTemplate?.fields;

      payload.fields = formatCaseNoteTemplateFields({
        fields: templateFields || defaultFieldSettings,
        categories: openDataCategories,
        scaleTypes
      });

      setCaseNoteDetail(payload as CaseNote);
      setCaseNoteList([payload as CaseNote, ...caseNoteList]);

      setIsEditing(true);

      handleRedirect(NEW_NOTE_TEMPORARY_ID);

      setAddNoteButtonStatus('finished');

      setTimeout(() => setAddNoteButtonStatus(''), 500);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to add a note' });
    }
  }, [
    caseNoteDetail?.fields,
    caseNoteList,
    currentCaseNoteTemplate?.fields,
    currentCaseNoteTemplate.headings,
    generateHeadingTemplate,
    handleRedirect,
    isCaWFeatureToggle,
    isHelmFeatureToggle,
    loggedInClinicianId,
    loggedInClinicianProfile?.name,
    openDataCategories,
    scaleTypes,
    selectedFolderId
  ]);

  const handleClickNewNote = useCallback(() => {
    if (isClosedClient) {
      return;
    } else if (isEditing || isValueChanged) {
      Modal.confirm({
        title: 'There are some unsaved changes. Are you sure you want to navigate away from this page?',
        onOk: handleAddNote
      });
    } else {
      handleAddNote();
    }
  }, [handleAddNote, isClosedClient, isEditing, isValueChanged]);

  const handleEditNoteClick = useCallback(
    (noteId?: string) => {
      if (!isClosedClient) {
        if (noteId) {
          handleRedirect(noteId);
        }
        setIsEditing(true);
        clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.EpisodeList]);
      }
    },
    [handleRedirect, isClosedClient]
  );

  const handleDeleteNote = useCallback(
    (argNoteId?: string) => {
      const _noteId = argNoteId || pathNoteId;
      setIsEditing(false);
      setCaseNoteDetail(undefined);
      setCaseNoteList(caseNoteList.filter((caseNote) => caseNote._id !== _noteId));
      handleRedirect('');
    },
    [caseNoteList, handleRedirect, pathNoteId]
  );

  const handleUpdatedNote = useCallback(
    (updatedNote: CaseNote, isDraft: boolean) => {
      setIsDraftSaved(isDraft);
      const newNoteList = [
        updatedNote,
        ...caseNoteList.filter(
          (caseNoteObj) => caseNoteObj._id !== NEW_NOTE_TEMPORARY_ID && caseNoteObj._id !== updatedNote._id
        )
      ];
      setCaseNoteDetail(updatedNote);
      setCaseNoteList(newNoteList);

      if (!isDraft) {
        handleRedirect(updatedNote._id);
        setIsEditing(false);
      }
    },
    [caseNoteList, handleRedirect]
  );

  const handleClickFolder = useCallback(
    (folderId: string) => {
      if (!isEditing && selectedFolderId !== folderId) {
        handleRedirect('');
        setSelectedFolderId(folderId);
        setCaseNoteSearchVal('');
      }
    },
    [handleRedirect, isEditing, selectedFolderId]
  );

  const handleDelete = useCallback(
    async (argNoteId?: string) => {
      setIsDeleting(true);
      const _noteId = argNoteId || pathNoteId;

      if (_noteId === NEW_NOTE_TEMPORARY_ID) {
        handleDeleteNote(_noteId);
        setIsValueChanged(false);
      } else if (_noteId) {
        try {
          await deleteNote(token, clientRecordId, _noteId);
          handleDeleteNote(_noteId);
        } catch (ex) {
          notification.error({ message: 'Something went wrong while trying to delete this case note' });
        }
      }

      setIsDeleting(false);
    },
    [clientRecordId, handleDeleteNote, pathNoteId, token]
  );

  const handleClickDelete = useCallback(
    (arg?: { noteId?: string; noteTitle?: string }) => {
      Modal.confirm({
        onOk: () => handleDelete(arg?.noteId),
        title: `Are you sure you want to delete this note ${arg?.noteTitle ? ` (${arg.noteTitle})` : ''}?`
      });
    },
    [handleDelete]
  );

  const handleClickOpenEgrist = async () => {
    try {
      setIsFetchingEgristUrl(true);
      const egristUrl = await (await getEgristUrlByClientRecordId(token, clientRecordId)).text();

      if (!egristUrl) {
        notification.error({ message: 'Something went wrong while trying to open Egrist' });
        return;
      }

      window.open(egristUrl, '_blank');
      setIsFetchingEgristUrl(false);
    } catch (ex) {
      setIsFetchingEgristUrl(false);
      notification.error({ message: 'Something went wrong while trying to open Egrist' });
    }
  };

  return (
    <>
      <div className={styles.topBar}>
        {!isHidePrivateCaseNotes && (
          <FolderList
            disabled={isEditing}
            folders={foldersWithUpdatedSelection}
            loading={isCaseNoteListLoading}
            onClick={handleClickFolder}
          />
        )}
        {isEgristEnabled && (
          <ButtonAlt
            className={styles.button}
            onClick={handleClickOpenEgrist}
            disabled={isCaseNoteListLoading || isFetchingEgristUrl}
            icon="assessment"
            variant="outlined"
          >
            Open Egrist Session
          </ButtonAlt>
        )}
      </div>
      <div className={styles.container}>
        {isCaseNoteListLoading ? (
          <div className={styles.loading}>
            <LoadingDot />
          </div>
        ) : (
          <>
            <HeadingAssessment isLoading={isHeadingTemplateDetailsLoading} assessmentData={assessmentList} />
            <div className={styles.content}>
              <PatientDetailsNotesListing
                addNoteButtonStatus={addNoteButtonStatus}
                handleRedirect={handleRedirect}
                isAddNoteButtonDisabled={isAddNoteButtonDisabled}
                isAddNoteButtonVisible={selectedFolder.addable}
                isDeleting={isDeleting}
                isEditing={isEditing}
                isPrivateFolder={selectedFolder.isPrivate}
                noteId={pathNoteId}
                notes={selectedCaseNoteList}
                onClickAddNote={handleClickNewNote}
                onClickDeleteNote={handleClickDelete}
                onClickEditNote={handleEditNoteClick}
                recordId={clientRecordId}
                searchValue={caseNoteSearchVal}
                setSearchValue={setCaseNoteSearchVal}
                listingLoading={isCaseNoteListFetching}
              />
              <div className={styles.noteDetailsContainer}>
                {selectedCaseNoteList.length === 0 && !isCaseNoteLoading ? (
                  <div className={styles.noCaseNotes}>
                    <div className={styles.text}>{t('label.no_client_case_notes')}</div>
                    <ButtonAlt
                      className={styles.button}
                      icon="add_circle_outline"
                      onClick={handleClickNewNote}
                      status={addNoteButtonStatus}
                      disabled={isAddNoteButtonDisabled}
                    >
                      Add new note
                    </ButtonAlt>
                  </div>
                ) : isPrivateCaseNoteCreatedByOthers || isPrivateFolderForOthers ? (
                  <div className={styles.privateFolderMessage}>
                    <div className={styles.text}>
                      This is the private folder for{' '}
                      {selectedCaseNoteSummary?.clinicianName || selectedFolder.clinicianName}
                    </div>
                    <div className={styles.text}>
                      Please contact {selectedCaseNoteSummary?.clinicianName || selectedFolder.clinicianName} to share
                      the particular note to the shared folder to view them.
                    </div>
                  </div>
                ) : !pathNoteId ? (
                  <div className={styles.noNoteSelected}>
                    <div className={styles.text}>No case note selected.</div>
                    <ButtonAlt
                      className={styles.button}
                      status={addNoteButtonStatus}
                      disabled={isAddNoteButtonDisabled}
                      onClick={handleClickNewNote}
                      icon="add_circle_outline"
                    >
                      Add new note
                    </ButtonAlt>
                  </div>
                ) : contentLoading ? (
                  <div className={styles.loading}>
                    <LoadingDot />
                  </div>
                ) : caseNoteDetail ? (
                  <PatientDetailsNoteDetails
                    caseNoteData={caseNoteDetail}
                    caseNoteDataLoading={isCaseNoteFetching}
                    clientRole={clientRecordData.clientProfiles[0].role}
                    currentTemplate={currentCaseNoteTemplate}
                    handleClickDelete={handleClickDelete}
                    isClosedClient={isClosedClient}
                    isDeleting={isDeleting}
                    isEditing={isEditing}
                    isPrivateFolderSelected={isPrivateFolderSelected}
                    isReadOnly={
                      fetchedCaseNote && !isNormalUserView
                        ? fetchedCaseNote?.clinicianId !== loggedInClinicianId
                        : false
                    }
                    onEditNoteClick={handleEditNoteClick}
                    onSelectTemplate={changeTemplate}
                    onUpdateNoteDetails={handleUpdatedNote}
                    onValueChange={(val) => setIsValueChanged(val)}
                    profileId={profileId}
                    recordId={clientRecordId}
                    refetchCaseNote={refetchCaseNote}
                    templateList={caseNotesTemplateList}
                    token={token}
                    setSelectedFolderId={setSelectedFolderId}
                  />
                ) : (
                  <div className={styles.noNoteFound}>
                    <div className={styles.text}>Note not found.</div>
                    <ButtonAlt
                      className={styles.button}
                      status={addNoteButtonStatus}
                      disabled={isAddNoteButtonDisabled}
                      onClick={handleClickNewNote}
                      icon="add_circle_outline"
                    >
                      Add new note
                    </ButtonAlt>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PatientDetailsNotes;
