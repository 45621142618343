import { useState, useEffect } from 'react';
import rtf2html from 'rtf2html';
import { sanitizeHTMLString } from 'utils/sanitizeHTMLString';

interface RtfViewerProps {
  rtfText: string;
}

const RtfViewer: React.FC<RtfViewerProps> = ({ rtfText }) => {
  const [htmlContent, setHtmlContent] = useState<string>('');

  const convertRtfToHtml = () => {
    try {
      const html = rtf2html(rtfText, '', {});

      const sanitizedHtml = sanitizeHTMLString(html);

      setHtmlContent(sanitizedHtml);
    } catch (error) {
      console.error('Failed to convert RTF to HTML:', error);
      setHtmlContent('<p>Error converting RTF</p>');
    }
  };

  useEffect(() => {
    convertRtfToHtml();
  });

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default RtfViewer;
