import { Skeleton } from 'antd';
import ActivePractitionerCard from './components/ActivePractitionerCard/ActivePractitionerCard';
import {
  filterParameter,
  PractitionersListing,
  PractitionersListingCount
} from 'interfaces/Practitioners/practitionersListing';
import SearchBar from 'components/SearchBar/SearchBar';
import Tag from 'components/Tag/Tag';
import { PractitionerTabId } from 'pages/PractitionersListing/PractitionersListing';
import { useState, useEffect, useCallback } from 'react';
import { useGetPractitionerFilterValue } from 'pages/PractitionersListing/hooks/GetPractitionersList';

import styles from './PractitionerListContent.module.scss';

interface PatientListProps {
  practitionersList: PractitionersListing;
  isCountLoading: boolean;
  isPractitionersListLoading: boolean;
  isPageLoading: boolean;
  onChangeTabStatus: (tabValue: PractitionerTabId) => void;
  filterValue: filterParameter;
  practitionersCount: PractitionersListingCount;
  onHandleSearch: (searchValue: string) => void;
  onHandlePageChange: (pageValue: string) => void;
}

const PractitionerListContent = ({
  practitionersList,
  isCountLoading,
  isPractitionersListLoading,
  isPageLoading,
  onChangeTabStatus,
  filterValue,
  practitionersCount,
  onHandleSearch,
  onHandlePageChange
}: PatientListProps) => {
  const listLoading = isCountLoading || isPractitionersListLoading || isPageLoading;
  const { tabId } = useGetPractitionerFilterValue();
  const [filterType, setFilterType] = useState<PractitionerTabId>(tabId);

  const regenPageContents = useCallback(
    (tabId: PractitionerTabId) => {
      setFilterType(tabId);
      onChangeTabStatus(tabId);
    },
    [onChangeTabStatus]
  );

  useEffect(() => {
    regenPageContents(tabId);
  }, [tabId, regenPageContents]);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.filterWrapper}>
          <div className={styles.filterWrapperTitle}>Show</div>
          <div className={styles.filterWrapperTags}>
            <div className={styles.tagWrapper}>
              {isPractitionersListLoading ? (
                <Skeleton.Button active />
              ) : (
                <Tag
                  label="Active"
                  type={filterType === 'active' ? 'active' : 'normal'}
                  counter={practitionersCount.active}
                  onClick={() => onChangeTabStatus(PractitionerTabId.Active)}
                  isLoading={isCountLoading}
                />
              )}
            </div>
            <div>
              {isPractitionersListLoading ? (
                <Skeleton.Button active />
              ) : (
                <Tag
                  label="Inactive"
                  type={filterType === 'inactive' ? 'active' : 'normal'}
                  counter={practitionersCount.inactive}
                  onClick={() => onChangeTabStatus(PractitionerTabId.Inactive)}
                  isLoading={isCountLoading}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.searchBarWrapper}>
          <div className={styles.searchBarCard}>
            <SearchBar
              placeholder={'Search by name'}
              value={filterValue.q}
              withSearchButton
              onSearch={onHandleSearch}
            />
          </div>
        </div>
      </div>
      <div className={styles.cardContainer}>
        <ActivePractitionerCard
          practitionersList={!isPractitionersListLoading ? practitionersList.practitionerList : []}
          isPractitionersListLoading={listLoading}
          paging={practitionersList.paging}
          onPageChange={onHandlePageChange}
        />
      </div>
    </div>
  );
};

export default PractitionerListContent;
