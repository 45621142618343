import { useState } from 'react';
import classnames from 'classnames';

import Button from 'components/Button/Button';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Select from 'components/Select/CommonSelect/Select';
import { components } from 'react-select';
import styles from './NotesFormatSettingsFieldsEditor.module.scss';
import MaterialToggleSwitch from 'components/MaterialFieldComponent/MaterialToggleSwitch/MaterialToggleSwitch';
import { ReportTemplateCaseNoteField } from 'interfaces/Reports/reportTemplate';
import { v4 as uuid } from 'uuid';
import ButtonAlt, { IconVariant } from 'components/v2/ButtonAlt/ButtonAlt';

const FIELD_TYPE_OPTIONS = [
  { label: 'Date', value: 'date' },
  { label: 'Time', value: 'time' },
  { label: 'Multiple Choice', value: 'multipleChoice' },
  { label: 'Number', value: 'number' },
  { label: 'Text', value: 'text' }
];

interface NotesFormatSettingsFieldsEditorProps {
  selectedField: ReportTemplateCaseNoteField;
  onChangeField: (updatedField: ReportTemplateCaseNoteField) => void;
}

const NotesFormatSettingsFieldsEditor = ({ selectedField, onChangeField }: NotesFormatSettingsFieldsEditorProps) => {
  const [selectedOptionId, setSelectedOptionId] = useState<string>('');

  const [fieldValue, setFieldValue] = useState<ReportTemplateCaseNoteField>(selectedField);

  const handleAddNewOptions = () => () => {
    const newId = uuid();
    const newOptionsList = [
      ...(fieldValue.options || []),
      {
        _id: newId,
        label: 'New Section',
        options: []
      }
    ];
    setSelectedOptionId(newId);
    handleFieldValue('options', newOptionsList);
  };

  const handleClickOptions = (optionId: string) => {
    if (optionId !== selectedOptionId) {
      setSelectedOptionId(optionId);
      onChangeField(fieldValue);
    }
  };

  const handleRemoveOption = (optionId: string) => {
    const newOptions = fieldValue.options.filter((optionObj) => optionObj._id !== optionId);
    handleFieldValue('options', newOptions);
  };

  const handleFieldValue = (key: string, val: string | boolean | any) => {
    const updatedField = {
      ...fieldValue,
      [key]: val
    };
    setFieldValue(updatedField);
    onChangeField(updatedField);
  };

  const handleOptionFieldLabel = (optionId: string, val: string) => {
    let newOptions: ReportTemplateCaseNoteField['options'] = [];
    fieldValue.options.map((optionObj) =>
      newOptions.push({
        ...optionObj,
        label: optionObj._id === optionId ? val : optionObj.label
      })
    );
    handleFieldValue('options', newOptions);
  };

  const handleOptionFieldOptions = (optionId: string, val: string) => {
    const massageOptionStringToObj = val
      .split(';')
      .filter((value) => !!value)
      .map((value) => ({ label: value, value: value }));
    let newOptions: ReportTemplateCaseNoteField['options'] = [];
    fieldValue.options.map((optionObj) =>
      newOptions.push({
        ...optionObj,
        options: optionObj._id === optionId ? massageOptionStringToObj : optionObj.options
      })
    );
    handleFieldValue('options', newOptions);
  };

  const customOption = (props: any) => {
    const optionProps = {
      ...props,
      innerProps: {
        ...props.innerProps,
        id: 'templateFormatField'
      }
    };
    return <components.Option {...optionProps} />;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <MaterialInput
          id={'fieldName'}
          className={styles.titleInput}
          label={'Field name'}
          value={fieldValue?.name}
          onChange={(e) => handleFieldValue('name', e.target.value)}
        />
      </div>
      <div className={styles.content}>
        <Select
          labelClass={styles.selectLabel}
          label={'Selection format'}
          options={FIELD_TYPE_OPTIONS}
          styles={{
            container: (containerBase) => ({ ...containerBase, width: '100%' }),
            valueContainer: (base) => ({ ...base, paddingLeft: 0 }),
            menuPortal: (base) => ({ ...base, zIndex: 101 })
          }}
          value={fieldValue.type}
          onChange={(e) => handleFieldValue('type', e?.value || '')}
          menuPortalTarget={document.querySelector('body')}
          menuPlacement={'auto'}
          components={{ Option: customOption }}
          isSearchable={false}
        />
        {fieldValue.type === 'multipleChoice' && (
          <div className={styles.middleCol}>
            <div className={styles.switchContainer}>
              <div className={styles.label}>Accept more than one answer?</div>
              <div className={styles.multipleCheckBox}>
                <MaterialToggleSwitch
                  name={'acceptMultipleAnswer'}
                  value={fieldValue.multiple ?? false}
                  onChange={(val) => handleFieldValue('multiple', val)}
                  falseLabel={'No'}
                  trueLabel={'Yes'}
                />
              </div>
            </div>
            <div className={styles.label}>Field Settings</div>
            <div className={styles.customOptionsContainer}>
              {fieldValue.type === 'multipleChoice' ? (
                <>
                  {fieldValue.options?.map((optionObj, index) => (
                    <div key={index}>
                      <div className={styles.pillContainer}>
                        <Button className={styles.pill} onClick={() => handleClickOptions(optionObj._id)}>
                          {optionObj.label} {optionObj.label && `|`} {optionObj.options?.length || 0} answers
                        </Button>
                        <ButtonAlt
                          className={styles.removeIcon}
                          error
                          size={'small'}
                          variant={'text'}
                          fab
                          onClick={() => handleRemoveOption(optionObj._id)}
                          icon={'delete'}
                          iconVariant={IconVariant.Filled}
                        />
                      </div>
                      <div
                        className={classnames(
                          styles.customChoiceInputs,
                          optionObj._id === selectedOptionId && styles.visible
                        )}
                      >
                        <MaterialInput
                          className={styles.input}
                          id={`custom-multiple-choice-section-name-${index}`}
                          label={'Group title'}
                          defaultValue={optionObj.label}
                          onChange={(e) => handleOptionFieldLabel(optionObj._id, e.target.value)}
                          required
                        />
                        <MaterialInput
                          className={styles.input}
                          id={`custom-multiple-choice-options-${index}`}
                          label={'Answer options'}
                          defaultValue={optionObj.options?.map((option) => option.label).join(';')}
                          onChange={(e) => handleOptionFieldOptions(optionObj._id, e.target.value)}
                          required
                        />
                        <div className={styles.tip}>Separate options with semi-colon ;</div>
                      </div>
                    </div>
                  ))}
                  <ButtonAlt size={'small'} variant={'text'} onClick={handleAddNewOptions()} icon={'add'}>
                    add options
                  </ButtonAlt>
                </>
              ) : (
                <>
                  <MaterialInput
                    className={styles.customOptionsInput}
                    id="custom-multiple-choice-options"
                    label="Custom Options"
                    defaultValue={fieldValue.options
                      ?.flatMap((choice) => choice.options?.map((option) => option.label) || choice.label)
                      .filter((label) => !!label)
                      .join(';')}
                    autoFocus
                    required
                  />
                  <div className={styles.tip}>Separate options with semi-colon ;</div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotesFormatSettingsFieldsEditor;
