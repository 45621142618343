import ModalV2 from 'components/ModalV2/ModalV2';
import styles from './RefundModal.module.scss';
import { InvoiceWithType } from '../InvoiceList/constants';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { useCurrency } from 'utils/hooks/useCurrency';
import classNames from 'classnames';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import { convertCurrencyValue, sanitizedCurrencyValue } from 'utils/currencyFormatConverter';
import { ChangeEvent, useMemo, useState } from 'react';
import { RefundInvoice } from 'redux/invoices/invoiceSlice';
import { RefundStatus } from 'pages/Invoices/interface';
import { PartialRefundResponse, usePartialRefundMutation } from 'redux/endpoints/billingServices/invoices';
import { notification } from 'antd';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import InvoiceDetailsForModal from '../InvoiceDetailsForModal/InvoiceDetailsForModal';
import { config } from 'config/config';

interface RefundModalProps {
  showModal: boolean;
  invoice: InvoiceWithType;
  refund: RefundInvoice | undefined;
  onCloseModal: () => void;
  onChangeValue: (value: Partial<RefundInvoice>) => void;
  onSubmitSuccess?: () => void;
}

const RefundModal = ({
  showModal,
  invoice,
  refund,
  onCloseModal,
  onChangeValue,
  onSubmitSuccess
}: RefundModalProps) => {
  const { currencySymbol } = config;
  const { CURRENCY_DECIMAL } = useCurrency();
  const { accountId, paid, paymentRequests } = invoice;
  const { amount, refId } = refund || {};
  const paymentAmount = paid?.payments || 0;

  const [postPartialRefund] = usePartialRefundMutation();

  const [formError, setFormError] = useState<{ amount: string; refId: string }>({ amount: '', refId: '' });
  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');

  const onChangeRefId = (value: string) => {
    onChangeValue({ refId: value });
    setFormError({
      ...formError,
      refId: ''
    });
  };

  const onChangeAmount = (value: string) => {
    onChangeValue({ amount: sanitizedCurrencyValue(value) });
    setFormError({
      ...formError,
      amount: ''
    });
  };

  const handleSubmit = async () => {
    const amountInNumber = Number(amount || '');
    const isValidAmount = !Number.isNaN(amountInNumber) && amountInNumber > 0 && amountInNumber <= paymentAmount;
    if (refId && isValidAmount) {
      try {
        setSubmitButtonStatus('active');
        const result: PartialRefundResponse = await postPartialRefund({
          accountId,
          amount: amountInNumber,
          paymentRequestId: refId
        }).unwrap();
        if (result.error) {
          notification.error({ message: result.error });
          setSubmitButtonStatus('');
        } else {
          notification.success({
            message:
              'Refund processed successfully. Your customer will see the refund as a credit in approximately 5-10 business days depending upon their bank.',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setSubmitButtonStatus('finished');
          onSubmitSuccess && onSubmitSuccess();
          setTimeout(() => {
            setSubmitButtonStatus('');
            handleCloseModal();
          }, 2000);
        }
      } catch (ex) {
        setSubmitButtonStatus('');
        notification.error({
          message: 'Refund attempt failed. Please try again, if failures persist please contact us to investigate.'
        });
      }
    }
    setFormError({
      amount: !isValidAmount ? 'Error' : '',
      refId: !refId ? 'Required' : ''
    });
  };

  const handleCloseModal = () => {
    setFormError({
      amount: '',
      refId: ''
    });
    onCloseModal();
  };

  const refOptions: IOptionItem[] = useMemo(() => {
    return !!paymentRequests && paymentRequests.length
      ? paymentRequests.map((paymentRequest) => {
          const totalRefundedAmount: number = paymentRequest.refunds
            ? paymentRequest.refunds
                .filter((refund) => refund.status === RefundStatus.Succeeded)
                .reduce((totalRefundedAmount, refund) => totalRefundedAmount + refund.amount, 0)
            : 0;

          return {
            label: paymentRequest.stripeChargeId || '',
            subLabel: `${currencySymbol}${(paymentRequest.amount - totalRefundedAmount).toFixed(CURRENCY_DECIMAL)}`,
            value: paymentRequest._id || ''
          };
        })
      : [];
  }, [paymentRequests, CURRENCY_DECIMAL, currencySymbol]);

  return (
    <ModalV2
      containerClassName={styles.refundModalContainer}
      isModalOpen={showModal}
      title="Refund"
      onModalClose={handleCloseModal}
      bodyStyle={{ padding: '0' }}
      headerContainerClassName={styles.headerContainerClassName}
      titleClassName={styles.titleClassName}
    >
      <div className={styles.container}>
        {/* Invoice details */}
        <InvoiceDetailsForModal invoice={invoice} />

        {/* Refund Form */}
        <div className={styles.refundForm}>
          <DropdownSearchable
            className={styles.refDropdown}
            controllerClassName={styles.refController}
            labelClassName={styles.refDropdownLabel}
            placeholder="Select Ref ID"
            options={refOptions}
            selected={refId}
            showBorderBottom
            borderBottomClassName={formError.refId ? styles.borderBottomError : styles.borderBottom}
            optionWrapperClassName={styles.optionWrapper}
            searchable
            onSelect={onChangeRefId}
            error={formError.refId}
            hideErrorDesc
          />

          <div className={styles.amountInputContainer}>
            <TitleInput
              inputContainerClassName={formError.amount ? styles.inputErrorClassName : styles.inputClassName}
              prefix={currencySymbol}
              inputContainer={styles.amountInput}
              inputProps={{
                maxLength: 18,
                placeholder: 'Enter amount',
                value: convertCurrencyValue(amount || ''),
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  onChangeAmount(e.target.value);
                }
              }}
              error={formError.amount}
              hideErrorDesc
            />
            <div className={classNames(styles.amountNote, formError.amount ? styles.amountError : '')}>
              Refund amount must be equal or less than {currencySymbol} paid value
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <ButtonAlt status={submitButtonStatus} loadingWord="Processing..." onClick={handleSubmit}>
            Refund
          </ButtonAlt>
        </div>
      </div>
    </ModalV2>
  );
};

export default RefundModal;
