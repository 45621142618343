import styles from './CarePathwayAppointmentItem.module.scss';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLAttributes, forwardRef, useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { useCarePathwayTemplateFormContext } from 'pages/CarePathwayTemplate/context';
import {
  DEFAULT_APPOINTMENT_TYPES_PER_PAGES,
  useGetAppointmentTypesQuery
} from 'redux/endpoints/scheduleServices/appointmentType';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

interface CarePathwayAppointmentItemProps extends HTMLAttributes<HTMLDivElement> {
  index: number;
  appointment: {
    appointmentTypeId: string;
  };
}

export const CarePathwayAppointmentItem = forwardRef<HTMLDivElement, CarePathwayAppointmentItemProps>(
  ({ appointment, className, index, ...props }, ref) => {
    const { values, setValues } = useCarePathwayTemplateFormContext();

    const [apptListCurrentPage, setApptTypeListCurrentPage] = useState<number>(1);

    const {
      data: appointmentTypeData,
      isLoading: appointmentTypeLoading,
      isFetching: appointmentTypeFetching
    } = useGetAppointmentTypesQuery({
      page: apptListCurrentPage,
      perPage: DEFAULT_APPOINTMENT_TYPES_PER_PAGES,
      participantType: [ParticipantType.Activity, ParticipantType.OneToOne].join(','),
      isActive: '1',
      infiniteLoad: true
    });

    const totalApptListPage = useMemo(
      () =>
        appointmentTypeData?.paging
          ? Math.ceil(appointmentTypeData.paging.totalItems / DEFAULT_APPOINTMENT_TYPES_PER_PAGES)
          : 1,
      [appointmentTypeData]
    );

    const loadMoreApptType = useCallback(() => {
      const page = appointmentTypeData?.paging.page || 1;
      if (!appointmentTypeFetching && page < totalApptListPage) {
        setApptTypeListCurrentPage(page + 1);
      }
    }, [totalApptListPage, appointmentTypeFetching, appointmentTypeData]);

    const hasMoreData = useMemo(
      () => totalApptListPage > apptListCurrentPage,
      [totalApptListPage, apptListCurrentPage]
    );

    const appointmentTypes = useMemo(() => {
      return (
        appointmentTypeData?.data.map((item) => {
          return {
            label: item.name,
            value: item._id ?? '',
            className: styles.selectItem
          };
        }) ?? []
      );
    }, [appointmentTypeData]);

    const handleDelete = () => {
      setValues({
        appointments: values.appointments.filter((_, i) => i !== index)
      });
    };

    return (
      <div className={classNames(styles.container, className)} ref={ref} {...props}>
        <div className={styles.title}>
          <span className={styles.equal}>=</span>
          <span>{index + 1}</span>
        </div>
        <div className={styles.rightContainer}>
          <div>Appointment</div>
          <DropdownSearchable
            className={styles.input}
            placeholder="Appointment Type"
            options={appointmentTypes}
            selected={appointment.appointmentTypeId}
            onSelect={(value: string) => {
              const newAppointments = [...values.appointments];
              newAppointments[index] = {
                ...appointment,
                appointmentTypeId: value
              };
              setValues({
                appointments: newAppointments
              });
            }}
            hideErrorDesc
            hasMoreData={hasMoreData}
            loadMore={loadMoreApptType}
            isFetchingMore={appointmentTypeFetching}
            isLoading={appointmentTypeLoading}
            menuPlacement={'top'}
          />
        </div>
        <FontAwesomeIcon width={16} className={styles.trashIcon} icon={faTrashCan} onClick={handleDelete} />
      </div>
    );
  }
);
