import { useField } from 'formik';

import TextArea from 'components/TextArea/TextArea';

import styles from './DescriptionOfServices.module.scss';

interface DescriptionOfServicesProps {
  placeholder?: string;
}

const DescriptionOfServices = ({ placeholder }: DescriptionOfServicesProps) => {
  const [field] = useField<string>('description');

  return (
    <div className={styles.container}>
      <span className={styles.title}>Description of services</span>
      <TextArea className={styles.textarea} {...field} placeholder={placeholder ? placeholder : ''} />
    </div>
  );
};

export default DescriptionOfServices;
