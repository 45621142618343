import SubTab, { SubTabOption } from 'components/v2/SubTab/SubTab';

const ClientRecentlyViewed = ({
  title,
  recentlyViewed,
  activeOption
}: {
  title: string;
  recentlyViewed: SubTabOption[];
  activeOption: string;
}) => {
  return <SubTab title={title} options={recentlyViewed} activeOption={activeOption} />;
};

export default ClientRecentlyViewed;
