import ReferralDocuments from './components/ReferralDocuments/ReferralDocuments';
import ReferralInformation from './components/ReferralInformation/ReferralInformation';

import Card from 'components/Card/Card';
import { Referral } from 'interfaces/Referral/Referral';
import { useMemo } from 'react';
import { useGetEpisodeListQuery } from 'redux/endpoints/clinicianProfileServices/episode';
import { useGetPackageListQuery } from 'redux/endpoints/scheduleServices/package';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import ReferralCustomFieldsTable from './components/ReferrralCustomFieldsTable/ReferralCustomFieldsTable';
import styles from './ReferralsV2.module.scss';

interface ReferralsV2Props {
  referrals: Referral[];
  recordId: string;
}

const ReferralsV2 = ({ referrals, recordId }: ReferralsV2Props) => {
  const { accountId } = useGetAccountId();
  const { isPackageEnabled, isEoCEnabled } = useGetFeatureToggle();
  const { accountTimeZone } = useTimeZone();

  const packageIds = useMemo(() => {
    const allPackageIds = referrals
      .map((referral) => referral.packages?.map((pack) => pack.packageId) || [])
      .flat()
      .filter((p) => p);
    return Array.from(new Set(allPackageIds));
  }, [referrals]);

  const packageQueryParam = useMemo(
    () => ({
      page: 1,
      perPage: 20,
      ...(packageIds.length > 0 && { filterByIds: packageIds.join(',') })
    }),
    [packageIds]
  );

  const { data: packageFunderData } = useGetPackageListQuery(
    {
      accountId: accountId,
      params: packageQueryParam
    },
    { skip: !accountId || !isPackageEnabled || !packageIds.length }
  );

  const packageById = useMemo(() => {
    return new Map((packageFunderData?.packages || []).map((opt) => [opt._id, opt]));
  }, [packageFunderData]);

  const { data: episodeListResponse } = useGetEpisodeListQuery(
    {
      clientRecordId: recordId,
      timezone: accountTimeZone,
      params: {
        page: 1,
        perPage: 50
      }
    },
    { skip: !recordId || !isEoCEnabled }
  );

  const episodeById = useMemo(() => {
    return new Map((episodeListResponse?.episodes || []).map((opt) => [opt._id, opt]));
  }, [episodeListResponse?.episodes]);

  return referrals.length ? (
    <>
      {referrals.map((referral, index) => {
        const referralIndex = referrals.length - (index + 1);

        return (
          <Card key={referral._id} className={styles.container}>
            <ReferralInformation
              referral={referral}
              index={referralIndex}
              packageById={packageById}
              episodeById={episodeById}
            />
            <ReferralDocuments referral={referral} />
            {referral.rdf && <ReferralCustomFieldsTable rdf={referral.rdf} />}
          </Card>
        );
      })}
    </>
  ) : (
    <>No Referral Added</>
  );
};

export default ReferralsV2;
