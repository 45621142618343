import { Modal, notification } from 'antd';
import ModalHeader from 'components/v2/ModalHeader/ModalHeader';
import { AppointmentType } from 'interfaces/Schedule/AppointmentType';
import styles from './UpdateAppointmentScheduleModal.module.scss';
import AppointmentScheduleForm from '../AppointmentScheduleForm/AppointmentScheduleForm';
import { putSessionTypeSchedule } from 'utils/http/sessionType';
import { useGetAccessToken } from 'utils/hooks/token';
import { useState } from 'react';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';

interface UpdateAppointmentScheduleModalProps {
  visible: boolean;
  data: AppointmentType;
  onSuccess: () => void;
  onClose: (createdSessionType?: AppointmentType) => void;
}

const UpdateAppointmentScheduleModal = ({ visible, data, onSuccess, onClose }: UpdateAppointmentScheduleModalProps) => {
  const { token } = useGetAccessToken();
  const { auth0ClinicianId } = useGetClinicianId();

  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const onSubmit = async (newValues: Partial<AppointmentType>) => {
    setSaveButtonStatus('active');

    try {
      await putSessionTypeSchedule(
        data._id || '',
        auth0ClinicianId,
        { dayTimeSelections: newValues.dayTimeSelections },
        token
      );
      notification.success({
        message: 'Appointment type updated.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      onSuccess();
      setSaveButtonStatus('finished');
      onClose();
    } catch (err) {
      console.error(err);
      notification.error({
        message: `Something went wrong while trying to update schedule.`
      });
    } finally {
      setTimeout(() => {
        setSaveButtonStatus('');
      }, 1000);
    }
  };

  return (
    <Modal bodyStyle={{ padding: 0 }} width={720} visible={visible} footer={null} destroyOnClose closable={false}>
      <ModalHeader title="Set up Schedule" onCancel={onClose} />
      <div className={styles.formContainer}>
        <AppointmentScheduleForm
          data={data}
          onSubmit={onSubmit}
          duration={data.duration.minutes || 0}
          formValues={data}
          submitButtonLabel="Save Schedule"
          buttonStatus={saveButtonStatus}
        />
      </div>
    </Modal>
  );
};

export default UpdateAppointmentScheduleModal;
