import { BentResponse } from 'bent';
import { httpClient } from '../httpClient';
import { ParticipantType, AssignmentMode } from 'interfaces/Schedule/AppointmentType';
import queryString from 'query-string';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getSessionTypes = (token: string, ParticipantType?: ParticipantType, AssignmentMode?: AssignmentMode) => {
  const queryParam = {
    participantType: ParticipantType,
    assignmentMode: AssignmentMode
  };

  const stringifiedQueryParam = queryString.stringify(queryParam);

  return httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/session-types${stringifiedQueryParam ? `?${stringifiedQueryParam}` : ''}`
  ) as Promise<BentResponse>;
};

export const getSessionTypesByClinicianId = (token: string, clinicianId: string, participantType?: ParticipantType) => {
  const queryParam = queryString.stringify({
    participantType
  });

  return httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinician/${clinicianId}/session-types${queryParam ? `?${queryParam}` : ''}`
  ) as Promise<BentResponse>;
};

export const getSessionTypeById = (token: string, sessionTypeId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/session-types/${sessionTypeId}`
  ) as Promise<BentResponse>;

export const postSessionType = (payload: any, token: string) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })('/session-types', payload);

export const putSessionType = (id: string, payload: any, token: string) =>
  httpClient('PUT', url!, 201, { Authorization: `Bearer ${token}` })(`/session-types/${id}`, payload);

export const putSessionTypeSchedule = (id: string, clinicianId: string, payload: any, token: string) =>
  httpClient('PUT', url!, 201, { Authorization: `Bearer ${token}` })(
    `/clinicians/${clinicianId}/session-types/${id}`,
    payload
  );
