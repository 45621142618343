import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import styles from './ThreeDotMenuItem.module.scss';
import { Tooltip } from 'react-tooltip';

interface ThreeDotMenuItemProps {
  id?: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  preIcon?: string;
  postComponent?: React.ReactNode;
  postIcon?: string;
  className?: string;
  labelClassName?: string;
  postLabelComponent?: React.ReactNode;
  buttonClass?: string;
  tooltipContent?: string;
}

const ThreeDotMenuItem = ({
  id,
  label,
  isActive,
  onClick,
  isLoading,
  disabled,
  preIcon,
  postComponent,
  postIcon,
  className,
  labelClassName,
  postLabelComponent,
  buttonClass,
  tooltipContent
}: ThreeDotMenuItemProps) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick();
  };

  const baseMenuItem = (
    <button className={classNames(styles.buttonWrapper, buttonClass)} disabled={disabled} onClick={handleClick}>
      <div className={classNames(styles.container, isActive && styles.active, className, disabled && styles.disabled)}>
        {preIcon && <i className={`material-icons-outlined ${styles.itemIcon}`}>{preIcon}</i>}
        <div className={classNames(labelClassName, styles.label)}>
          {label}
          {postLabelComponent}
        </div>
        {postComponent}
        {isLoading ? (
          <LoadingCircle />
        ) : (
          postIcon && <i className={`material-icons ${styles.subMenuControlIcon}`}>{postIcon}</i>
        )}
      </div>
    </button>
  );

  if (disabled && tooltipContent && id) {
    return (
      <>
        <div data-tooltip-id={id}>{baseMenuItem}</div>
        <Tooltip place="top-start" id={id} className={styles.tooltips}>
          {tooltipContent}
        </Tooltip>
      </>
    );
  }
  return baseMenuItem;
};

export default ThreeDotMenuItem;
