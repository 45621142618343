import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './ClientItemMenu.module.scss';
import { useEffect, useRef, useState } from 'react';
import ContactDetails from 'pages/Groups/GroupDetails/components/GroupContent/components/Common/GroupConfigurationActionMenu/components/ContactDetails/ContactDetails';
import { ClientStatus, ProfileType } from 'interfaces/Clients/clientsRecord';
import { useGetAccessToken } from 'utils/hooks/token';
import { patchClientRecordsRecordStatus } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { Skeleton, notification } from 'antd';
import EventCreationModal from 'pages/Calendar/components/Modals/EventCreationModal/EventCreationModal';
import { ClientRecordListResponse } from 'interfaces/Clients/clientRecordNew';
import { useDispatch } from 'react-redux';
import { clientRecordsApiSlice } from 'redux/endpoints/clinicianProfileServices/client';
import { CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';

interface ClientItemMenuProps {
  clientRecord: ClientRecordListResponse['clientRecords'][number];
  recordStatus: ClientStatus['status'];
  profileType: ProfileType;
}

const ClientItemMenu = ({ clientRecord, recordStatus, profileType }: ClientItemMenuProps) => {
  const { token } = useGetAccessToken();

  const [isExpanded, setIsExpanded] = useState(false);
  const [profileStatusExpand, setProfileStatusExpand] = useState(false);
  const [contactInfoExpand, setContactInfoExpand] = useState(false);
  const [isCreateApptModalVisible, setIsCreateApptModalVisible] = useState(false);
  const [isSavingRecordStatus, setIsSavingRecordStatus] = useState(false);
  const dispatch = useDispatch();

  const menuNode = useRef<HTMLDivElement>(null);
  const subMenuNode = useRef<HTMLDivElement>(null);

  const handleExpandMenu = () => {
    setIsExpanded(!isExpanded);
    if (contactInfoExpand) {
      setContactInfoExpand(false);
    }
    if (profileStatusExpand) {
      setProfileStatusExpand(false);
    }
  };

  const handleExpandSubMenu = (item: 'profileStatus' | 'contactInfo') => {
    if (item === 'profileStatus') {
      if (contactInfoExpand) {
        setContactInfoExpand(false);
      }
      return setProfileStatusExpand(!profileStatusExpand);
    } else if (item === 'contactInfo') {
      if (profileStatusExpand) {
        setProfileStatusExpand(false);
      }
      return setContactInfoExpand(!contactInfoExpand);
    }
  };

  const handleSetAppointment = () => {
    return setIsCreateApptModalVisible(true);
  };

  const handleChangeRecordStatus = async (requestedRecordStatus: ClientStatus['status']) => {
    if ((profileType === 'full' && requestedRecordStatus === 'waitlist') || recordStatus === requestedRecordStatus) {
      return;
    }

    setIsSavingRecordStatus(true);
    try {
      await patchClientRecordsRecordStatus(token, clientRecord._id, { recordStatus: requestedRecordStatus });
      setIsExpanded(false);
      setProfileStatusExpand(false);
      setContactInfoExpand(false);
      notification.success({
        message: 'Profile Status successfully changed'
      });
      dispatch(clientRecordsApiSlice.util.invalidateTags([CPSTagTypes.ClientList]));
    } catch (ex) {
      console.error(ex);
      let message: string;
      if (ex instanceof Error) {
        message = ex.message;
      } else {
        message = "There's an error when changing the record status";
      }
      notification.error({
        message
      });
    }
    setIsSavingRecordStatus(false);
  };

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target) || subMenuNode.current?.contains(e.target)) {
      return;
    }
    setIsExpanded(false);
    setProfileStatusExpand(false);
    setContactInfoExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return (
    <>
      <div ref={menuNode} className={styles.container}>
        <ButtonAlt className={styles.button} variant={'text'} onClick={handleExpandMenu}>
          <i className={`material-icons ${styles.icon}`}>more_vert</i>
        </ButtonAlt>
        <div className={styles.menuWrapper}>
          <div className={isExpanded ? styles.menuShow : styles.menuHide}>
            {/* Change Profile Status */}
            <div
              className={profileStatusExpand ? styles.itemCardActive : styles.itemCard}
              onClick={() => handleExpandSubMenu('profileStatus')}
            >
              <i className={`material-icons-outlined ${styles.itemIcon}`}>change_circle</i>
              <div className={styles.label}>Change Profile Status</div>
              <i className={`material-icons ${styles.subMenuControlIcon}`}>navigate_next</i>
            </div>

            {/* Set Appointment */}
            <div className={styles.itemCard} onClick={handleSetAppointment}>
              <i className={`material-icons-outlined ${styles.itemIcon}`}>edit_calendar</i>
              <div className={styles.label}>Set Appointment</div>
              <i className={`material-icons ${styles.subMenuControlIcon}`}>open_in_new</i>
            </div>

            {/* Client Contact Info */}
            <div
              className={contactInfoExpand ? styles.itemCardActive : styles.itemCard}
              onClick={() => handleExpandSubMenu('contactInfo')}
            >
              <i className={`material-icons-outlined ${styles.itemIcon}`}>chat</i>
              <div className={styles.label}>Client Contact Info</div>
              <i className={`material-icons ${styles.subMenuControlIcon}`}>navigate_next</i>
            </div>
          </div>
        </div>
      </div>
      <div ref={subMenuNode}>
        <div className={styles.subMenuWrapper}>
          {/* Change Profile Status Submenu */}
          <div className={profileStatusExpand ? styles.subMenuShow : styles.subMenuHide}>
            {isSavingRecordStatus ? (
              <div className={styles.loading}>
                <Skeleton.Input className={styles.skeleton} active />
              </div>
            ) : (
              <>
                {recordStatus !== 'active' && (
                  <div className={styles.itemCard} onClick={() => handleChangeRecordStatus('active')}>
                    <div className={styles.label}>Change to Active</div>
                  </div>
                )}
                {recordStatus !== 'closed' && (
                  <div className={styles.itemCard} onClick={() => handleChangeRecordStatus('closed')}>
                    <div className={styles.label}>Change to Closed</div>
                  </div>
                )}
                {recordStatus !== 'waitlist' && profileType !== 'full' && (
                  <div className={styles.itemCard} onClick={() => handleChangeRecordStatus('waitlist')}>
                    <div className={styles.label}>Change to Waitlist</div>
                  </div>
                )}
              </>
            )}
          </div>

          {/* Client Contact Info Submenu */}
          <div className={contactInfoExpand ? styles.subMenuShow : styles.subMenuHide}>
            <ContactDetails clientRecordId={clientRecord._id} visible={contactInfoExpand} />
          </div>
        </div>
      </div>
      <EventCreationModal
        visible={isCreateApptModalVisible}
        defaultClient={clientRecord}
        onClose={() => setIsCreateApptModalVisible(false)}
        onCreateEditSuccess={() => {
          setIsCreateApptModalVisible(false);
          setIsExpanded(false);
          setProfileStatusExpand(false);
          setContactInfoExpand(false);
        }}
      />
    </>
  );
};

export default ClientItemMenu;
