import { BSTagTypes, billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';
import queryString from 'query-string';
import { GetSubmittedClaimListRequestPayload, SubmittedClaimListResponse } from 'interfaces/invoices/submittedClaim';

export const submittedClaimsApiSlice = billingServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubmittedClaimList: builder.query<SubmittedClaimListResponse, GetSubmittedClaimListRequestPayload>({
      query: ({ accountId, params }) => ({
        url: `/accounts/${accountId}/claims:submitted?${queryString.stringify(params)}`
      }),
      providesTags: [BSTagTypes.SubmittedClaims]
    }),

    patchAbandonRejectedClaim: builder.mutation({
      query: ({ accountId, claimId }: { accountId: string; claimId: string }) => ({
        url: `/accounts/${accountId}/claims/${claimId}:abandoned`,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, error, arg) => {
        return error ? [] : [{ type: BSTagTypes.SubmittedClaims }];
      }
    })
  })
});

export const { useGetSubmittedClaimListQuery, usePatchAbandonRejectedClaimMutation } = submittedClaimsApiSlice;
