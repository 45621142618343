import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { GroupCaseNoteInterface } from '../interfaces';
import {
  getGroupCaseNoteByCaseNoteId,
  getGroupCaseNotesByGroupId
} from 'utils/http/DocumentService/CaseNotes/groupCaseNotes';

export const useFetchGroupCaseNoteList = (token: string, groupId: string) => {
  const [caseNoteList, setGroupCaseNotes] = useState<GroupCaseNoteInterface[]>([]);
  const [isCaseNoteListLoading, setIsCaseNoteListLoading] = useState(true);

  const fetchGroupCaseNotes = async (token: string) => {
    try {
      const callGetGroupCaseNotes = await getGroupCaseNotesByGroupId(token, groupId);
      const groupCaseNotes = await callGetGroupCaseNotes.json();
      setGroupCaseNotes(groupCaseNotes);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: "Something went wrong while trying to get this group's case notes" });
    }
    setIsCaseNoteListLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchGroupCaseNotes(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { caseNoteList, isCaseNoteListLoading, setGroupCaseNotes };
};

export const useFetchCaseNote = (token: string, groupId: string, noteId?: string) => {
  const [caseNote, setCaseNote] = useState<GroupCaseNoteInterface>();
  const [isCaseNoteLoading, setIsCaseNoteLoading] = useState(true);

  const fetchCaseNote = async (noteId?: string) => {
    setIsCaseNoteLoading(true);

    if (!noteId) {
      setCaseNote(undefined);
    } else if (noteId !== 'new') {
      try {
        const callGetGroupCaseNote = await getGroupCaseNoteByCaseNoteId(token, groupId, noteId);
        const caseNote = await callGetGroupCaseNote.json();
        setIsCaseNoteLoading(false);
        setCaseNote(caseNote);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get this note' });
        setIsCaseNoteLoading(false);
        setCaseNote(undefined);
      }
    }
    setIsCaseNoteLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchCaseNote(noteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { caseNote, isCaseNoteLoading, fetchCaseNote };
};
