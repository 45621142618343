import { Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import styles from './FilterColorBox.module.scss';
import colorBackgroundStyles from './colorBoxBackground.module.scss';

export const CALENDAR_FILTER_COLOURS = Array.from(Array(25).keys()).map((i) => `color${i + 1}`);
const colorValues = [
  '#fdc3c3',
  '#ffbe9a',
  '#ffdd9a',
  '#eeace7',
  '#c884e0',
  '#ff9a9a',
  '#dc995a',
  '#f0c047',
  '#f768c6',
  '#ca77ae',
  '#85cddd',
  '#b4c1ef',
  '#a8c9f8',
  '#afe386',
  '#86e3c7',
  '#42b1ca',
  '#8598dd',
  '#8aa8d3',
  '#70c52d',
  '#33d2a2',
  'url(~assets/images/t23/Checker.png)',
  'url(~assets/images/t23/BlackDots.png)',
  'url(~assets/images/t23/Diamond.png)',
  'url(~assets/images/t23/VerticalBars.png)',
  'url(~assets/images/t23/DiagonalStripes.png)'
];

export const getRandomColor = () => {
  return CALENDAR_FILTER_COLOURS[Math.floor(Math.random() * CALENDAR_FILTER_COLOURS.length)];
};

export const CALENDAR_FILTER_COLOURS_VALUE: { [key: string]: string } = Array.from(Array(25).keys()).reduce(
  (obj, i, index) => ({ ...obj, [CALENDAR_FILTER_COLOURS[index]]: colorValues[index] }),
  {}
);

export const isColorBlindColour = (colorGroup: string) => {
  const colorBlind = ['color21', 'color22', 'color23', 'color24', 'color25'];
  return colorBlind.includes(colorGroup);
};

const FilterColorBox = ({ color, onChange }: { onChange?: (v: string) => void; color?: string }) => {
  const handleChangeColor = (color: string) => {
    if (onChange) {
      onChange(color);
    }
  };

  return (
    <Dropdown
      overlay={
        <Menu className={styles.modal}>
          {CALENDAR_FILTER_COLOURS.map((colorItem, index) => (
            <Menu.Item
              key={index}
              className={classNames(
                styles.colorItem,
                color === colorItem && styles.active,
                colorBackgroundStyles[colorItem]
              )}
              onClick={() => handleChangeColor(colorItem)}
            />
          ))}
        </Menu>
      }
      trigger={onChange ? ['click'] : []}
    >
      <div
        className={classNames(
          styles.colorBox,
          color && colorBackgroundStyles[color],
          !color && styles.noColor,
          onChange && styles.editable
        )}
      >
        {onChange && (
          <i
            className={classNames(
              styles.icon,
              'material-icons-outlined',
              isColorBlindColour(color!) && styles.colorBlindIcon
            )}
          >
            arrow_drop_down
          </i>
        )}
      </div>
    </Dropdown>
  );
};

export default FilterColorBox;
