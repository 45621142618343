import { notification } from 'antd';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import TextEditor2 from 'components/v2/TextEditor2/TextEditor2';
import { CaseNote, CreateUpdateCaseNote } from 'interfaces/caseNote';
import moment from 'moment';
import DateWrapper from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteHeaderOption/components/DateWrapper/DateWrapper';
import LinkWrapper from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteHeaderOption/components/LinkWrapper/LinkWrapper';
import MultipleChoiceCountWrapper from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteHeaderOption/components/MultipleChoiceCountWrapper/MultipleChoiceCountWrapper';
import MultipleChoiceWrapper from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteHeaderOption/components/MultipleChoiceWrapper/MultipleChoiceWrapper';
import NumberWrapper from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteHeaderOption/components/NumberWrapper/NumberWrapper';
import TimeWrapper from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/components/PatientDetailsNoteDetails/components/PatientDetailsNoteDetailsForm/components/CaseNoteHeaderOption/components/TimeWrapper/TimeWrapper';
import { useState } from 'react';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { v4 as uuidv4 } from 'uuid';
import { initSessionFeedbackField, SAFEGUARDING_OBJ } from '../../constants';
import { CaptureSessionFeedbackProps } from '../../interfaces';
import styles from './CaptureSessionFeedback.module.scss';
import CheckboxWrapper from './components/CheckboxWrapper/CheckboxWrapper';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useCreateCaseNoteMutation } from 'redux/endpoints/documentServices/caseNote';

const CaptureSessionFeedback = ({ recordId, profile, onCreated, hasBazaar }: CaptureSessionFeedbackProps) => {
  const [fieldsData, setFieldsData] = useState<CaseNote['fields']>(
    initSessionFeedbackField({ hasBazaar }) as CaseNote['fields']
  );
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [caseNoteBody, setCaseNoteBody] = useState('');
  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [safeguardingConcern, setSafeguardingConcern] = useState(SAFEGUARDING_OBJ);
  const [safeguardingReason, setSafeguardingReason] = useState('');

  const resetCaseNoteForm = () => {
    setFieldsData(initSessionFeedbackField({ hasBazaar }) as CaseNote['fields']);
    setCaseNoteBody('');
  };

  const handleOnChangeFieldInput = (fieldId: string, val: any) => {
    const newCollection = [];
    for (let fieldObj of fieldsData) {
      newCollection.push({
        ...fieldObj,
        value: fieldObj._id === fieldId ? val : fieldObj.value
      });
    }
    setFieldsData(newCollection);
  };

  const handleChangeSelectCount = (fieldId: string, newOption: any) => {
    const newCollection = [];
    for (let fieldObj of fieldsData) {
      newCollection.push({
        ...fieldObj,
        options: fieldObj._id === fieldId ? newOption : fieldObj.options
      });
    }
    setFieldsData(newCollection);
  };

  const onChangeCaseNoteBody = (value: string) => {
    setCaseNoteBody(value);
  };

  const renderFields = (fields: CaseNote['fields']) =>
    fields.map((field, index) => {
      switch (field.type) {
        case 'multipleChoice':
          return (
            <MultipleChoiceWrapper
              isDisabled={saveButtonStatus !== ''}
              className={styles.fieldContainer}
              field={field}
              isValidated={isValidated}
              onChangeFieldInput={handleOnChangeFieldInput}
              key={index}
            />
          );
        case 'multipleChoiceCount':
          return (
            <MultipleChoiceCountWrapper
              className={styles.fieldContainer}
              field={field}
              isValidated={
                isValidated &&
                field.mandatory &&
                field.options.filter((countObj: any) => countObj.count > 0).length <= 0
              }
              onChangeFieldInput={handleChangeSelectCount}
              key={index}
            />
          );
        case 'text':
        case 'number':
          return (
            <NumberWrapper
              isDisabled={saveButtonStatus !== ''}
              inputClassName={styles.inputStyle}
              className={styles.fieldContainer}
              field={field}
              isValidated={isValidated}
              onChangeFieldInput={handleOnChangeFieldInput}
              key={index}
            />
          );
        case 'date':
          return (
            <DateWrapper
              isDisabled={saveButtonStatus !== ''}
              className={styles.fieldContainer}
              field={field}
              isValidated={isValidated}
              onChangeFieldInput={handleOnChangeFieldInput}
              key={index}
            />
          );
        case 'time':
          return (
            <TimeWrapper
              field={field}
              className={styles.fieldContainer}
              isValidated={isValidated}
              onChangeFieldInput={handleOnChangeFieldInput}
              key={index}
            />
          );
        case 'link':
          return (
            <LinkWrapper
              recordId={'recordId'}
              field={field}
              isValidated={isValidated}
              onChangeFieldInput={handleOnChangeFieldInput}
              key={index}
            />
          );
        case 'checkbox':
          return (
            <CheckboxWrapper
              isDisabled={saveButtonStatus !== ''}
              field={field}
              labelClassName={styles.didNotAttendLabel}
              onChangeFieldInput={handleOnChangeFieldInput}
              key={index}
            />
          );
        default:
          return (
            <div key={index}>
              {field.name} {field.type}
            </div>
          );
      }
    });

  const handleOnChangeSafeguardingConcern = (id: string, value: any) => {
    setSafeguardingConcern({ ...SAFEGUARDING_OBJ, value: value.value });
    if (value.value !== 'Yes') {
      setSafeguardingReason('');
    }
  };

  const handleOnChangeSafeguardingReason = (value: string) => {
    setSafeguardingReason(value);
  };

  const validateField = (fields: CaseNote['fields']) => {
    const fieldWithMandatory = fields.filter((obj) => obj.mandatory);

    const checkFieldValue = fieldWithMandatory.filter((obj) =>
      obj.type !== 'multipleChoiceCount'
        ? !obj.value
        : obj.options.filter((countObj: any) => countObj.count > 0).length <= 0
    );

    const invalidFieldNumber = fields.filter((obj) => obj.type === 'number' && !obj.value?.match('^[0-9]*$'));
    const safeGuardingValidate = safeguardingConcern.value !== 'Yes' || safeguardingReason.length > 0;

    return checkFieldValue.length <= 0 && invalidFieldNumber.length === 0 && safeGuardingValidate;
  };

  const [createCaseNote] = useCreateCaseNoteMutation();

  const handleSubmit = async () => {
    const checkMandatoryFill = validateField(fieldsData);
    setIsValidated(!checkMandatoryFill);

    if (checkMandatoryFill) {
      setSaveButtonStatus('active');

      // Update fields id
      const fields = fieldsData.map((field) => ({
        ...field,
        _id: ['dateOfSession', 'didNotAttend'].includes(field._id) ? uuidv4() : field._id
      }));

      const bodyPayload: CreateUpdateCaseNote = {
        title: `${moment().format(MOMENTJS_DATE_FORMAT)} session feedback from mentor ${profile.name}`,
        fields,
        body: caseNoteBody,
        safeguardingAlert: safeguardingConcern.value === 'Yes',
        safeguardingReason
      };

      try {
        await createCaseNote({ clientRecordId: recordId, payload: bodyPayload });
        setSaveButtonStatus('finished');
        setTimeout(() => {
          onCreated();
          notification.success({
            message: 'Save note successful',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setSaveButtonStatus('');
          resetCaseNoteForm();
        }, 1000);
      } catch (ex) {
        setSaveButtonStatus('');
        notification.error({
          message: 'Something went wrong while trying to save your note.',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>CAPTURE SESSION FEEDBACK</div>

      <div className={styles.fieldsContainer}>
        <div className={styles.firstArea}>
          {renderFields(fieldsData.filter((field) => ['dateOfSession', 'didNotAttend'].includes(field._id)))}
        </div>
        <div className={styles.secondArea}>
          {renderFields(
            fieldsData.filter(
              (field) =>
                !['dateOfSession', 'didNotAttend', hasBazaar ? undefined : 'bazaarChapters'].includes(field._id)
            )
          )}
          <div className={styles.safeguardingContainer}>
            <MultipleChoiceWrapper
              isDisabled={saveButtonStatus !== ''}
              field={safeguardingConcern}
              isValidated={isValidated}
              onChangeFieldInput={handleOnChangeSafeguardingConcern}
              className={styles.fieldContainer}
            />
            {safeguardingConcern.value === 'Yes' && (
              <div className={styles.safeguardingReason}>
                <MaterialInput
                  disabled={saveButtonStatus !== ''}
                  className={styles.inputStyle}
                  id="safeguardingReason"
                  name="safeguardingReason"
                  label="Safeguarding Reason *"
                  value={safeguardingReason}
                  onChange={(e) => handleOnChangeSafeguardingReason(e.target.value)}
                  maxLength={200}
                  required
                />
                <ErrorMessage
                  className={styles.error}
                  error={'Please enter a reason for the safeguarding alert'}
                  visible={isValidated && safeguardingConcern.value === 'Yes' && safeguardingReason.length < 1}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.noteContainer}>
          {/* <TextEditor
            id={'clientQuickNote'}
            value={caseNoteBody}
            readOnly={saveButtonStatus !== ''}
            onChange={onChangeCaseNoteBody}
          /> */}
          <TextEditor2
            value={caseNoteBody}
            minHeight={300}
            readOnly={saveButtonStatus !== ''}
            onChange={onChangeCaseNoteBody}
          />
        </div>
        <div className={styles.buttonContainer}>
          <ButtonAlt className={styles.saveButton} status={saveButtonStatus} onClick={handleSubmit}>
            Save Note
          </ButtonAlt>
        </div>
      </div>
    </div>
  );
};

export default CaptureSessionFeedback;
