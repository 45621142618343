import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './Footer.module.scss';

interface FooterProps {
  onSubmit: () => void;
  disabled: boolean;
  numberOfRecipients: number;
  reportName: string;
  buttonStatus: ButtonStatusType;
}

const Footer = ({ onSubmit, disabled, numberOfRecipients, reportName, buttonStatus }: FooterProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        Sending <b className={styles.reportName}>{reportName}</b> to{' '}
        <span className={styles.recipients}>
          {numberOfRecipients} recipient{numberOfRecipients > 1 && 's'}
        </span>
      </div>
      <ButtonAlt status={buttonStatus} onClick={onSubmit} disabled={disabled}>
        Send Fax
      </ButtonAlt>
    </div>
  );
};

export default Footer;
