import { MicroJournalEntry, useFetchMicroJournalEntries } from 'utils/hooks/microJournal';
import { useGetAccessToken } from 'utils/hooks/token';

import LoadingDot from 'components/LoadingDot/LoadingDot';
import JournalDetailCard from './components/JournalDetailCard/JournalDetailCard';

import styles from './JournalDetailList.module.scss';
import { useTranslation } from 'react-i18next';

interface JournalDetailListProps {
  recordId: string;
  profileId: string;
  selectedJournalDetails: MicroJournalEntry[];
  onSelectJournalDetail: (journalDetail: MicroJournalEntry) => void;
}

const JournalDetailList = ({
  recordId,
  profileId,
  selectedJournalDetails,
  onSelectJournalDetail
}: JournalDetailListProps) => {
  const { token } = useGetAccessToken();
  const [t] = useTranslation();

  const { microJournalEntries, isMicroJournalEntriesLoading } = useFetchMicroJournalEntries({
    token,
    clientId: recordId,
    profileId,
    dateRange: 60
  });

  return isMicroJournalEntriesLoading ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <div className={styles.container}>
      {microJournalEntries.length > 0 ? (
        microJournalEntries.map((microJournalEntry, index) => (
          <JournalDetailCard
            key={index}
            journalDetail={microJournalEntry}
            selected={selectedJournalDetails.map((journalDetail) => journalDetail._id).includes(microJournalEntry._id)}
            onClick={() => onSelectJournalDetail(microJournalEntry)}
          />
        ))
      ) : (
        <div>{t('label.no_client_assessment_data')}</div>
      )}
    </div>
  );
};

export default JournalDetailList;
