import { components, OptionProps } from 'react-select';

import { MBSCodeOption } from '../../ClaimForm';

import styles from './MBSOption.module.scss';

const MBSOption = ({ children, ...props }: OptionProps<MBSCodeOption, false>) => {
  const { description } = props.data;

  return (
    <components.Option {...props}>
      <div className={styles.optionContainer}>
        <div className={styles.mbsCode}>{children}</div>
        <div className={styles.mbsCodeDetails}>{description}</div>
      </div>
    </components.Option>
  );
};

export default MBSOption;
