import ContentLayout from 'components/ContentLayoutT23/ContentLayoutT23';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { useTranslation } from 'react-i18next';
import styles from './PractitionerControlPanel.module.scss';
import ControlPanelHeader from 'pages/ControlPanel/ControlPanel/components/ControlPanelHeader/ControlPanelHeader';
import { useContext, useEffect } from 'react';
import { UserContext } from 'utils/UserContextProvider';
import ControlPanelContent from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/ControlPanelContent';
import { useParams } from 'react-router-dom';
import { useFetchProfileById } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { useSetRecentlyViewed } from 'utils/hooks/SubTab/recentlyViewed';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { scrollToView } from 'utils/scrollToView';

const PractitionerControlPanel = () => {
  const [t] = useTranslation();
  const { controlPanelTab, practitionerId } = useParams();
  const { clinicianProfile, setProfile } = useContext(UserContext);
  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { profile, fetchProfile } = useFetchProfileById(practitionerId || '');

  const isAdminSelected = clinicianProfile?._id === practitionerId;

  const onUpdateProfile = isAdminSelected ? setProfile : () => {};
  const selectedProfile = isAdminSelected ? clinicianProfile : profile;

  useEffect(() => {
    scrollToView('practitionerProfile');
  }, [controlPanelTab]);

  useSetRecentlyViewed({
    isLoading: !selectedProfile,
    storageName: 'team_viewed',
    id: selectedProfile?._id,
    clinicianProfileId: auth0ClinicianId,
    isEdgeAdminView,
    name: selectedProfile?.name ?? 'Team',
    recordId: selectedProfile?._id || ''
  });

  return (
    <HelmetWrapper title={t('label.tacklit_practitioner_profile')}>
      <div id={'practitionerProfile'} />
      <ContentLayout>
        <div className={styles.container}>
          <ControlPanelHeader tab={controlPanelTab} practitionerName={profile?.name} />
          <ControlPanelContent
            profile={selectedProfile}
            controlPanelTab={controlPanelTab}
            onUpdateProfile={onUpdateProfile}
            fetchProfile={() => fetchProfile(selectedProfile?._id || '')}
            isMobileOptional
          />
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default PractitionerControlPanel;
