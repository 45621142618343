import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { MedicareItem, MedicareItemFormat, MedicareItemMode, MedicareItemDuration } from '../interfaces';

export const DIETITIAN_ITEMS: MedicareItem[] = [
  {
    mbsCode: '10954',
    description:
      "Dietitics health service provided by an eligible dietitian, lasting at least 20 minutes, to a person who has a chronic condition and complex care needs being managed by a medical practitioner (including a general practitioner, but not a specialist or consultant physician) under a shared care plan or under both a GP Management Plan and Team Care Arrangements or, if the person is a resident of an aged care facility, the person's medical practitioner has contributed to a multidisciplinary care plan.",
    benefit: 60.35,
    role: ClinicianMedicareRole.Dietitian,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan20
  },
  {
    mbsCode: '81120',
    description:
      "Dietetics health service provided to a person by an eligible dietitian, lasting at least 45 minutes, for the purposes of assessing a person's suitability for group services for the management of type 2 diabetes, including taking a comprehensive patient history.",
    benefit: 77.4,
    role: ClinicianMedicareRole.Dietitian,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan45
  },
  {
    mbsCode: '81125',
    description:
      'Dietetics health service provided to a person by an eligible dietitian, lasting at least 60 minutes, as a group service, for the management of type 2 diabetes.',
    benefit: 19.3,
    role: ClinicianMedicareRole.Dietitian,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: '82350',
    description:
      'Dietetics health service provided to an eligible patient by an eligible dietitian, lasting at least 20 minutes, and the service is recommended in the patient’s eating disorder treatment and management plan.',
    benefit: 60.35,
    role: ClinicianMedicareRole.Dietitian,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan20
  },
  {
    mbsCode: '93284',
    description:
      "Telehealth attendance by an eligible dietitian, lasting at least 45 minutes, provided to a person for the purposes of assessing a person's suitability for group services for the management of type 2 diabetes, including taking a comprehensive patient history.",
    benefit: 77.4,
    role: ClinicianMedicareRole.Dietitian,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan45
  },
  {
    mbsCode: '93285',
    description:
      'Telehealth attendance by an eligible dietitian, lasting at least 60 minutes, provided to a person as a group service, for the management of type 2 diabetes.',
    benefit: 19.3,
    role: ClinicianMedicareRole.Dietitian,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: '93286',
    description:
      "Phone attendance by an eligible dietitian, lasting at least 45 minutes, provided to a person for the purposes of assessing a person's suitability for group services for the management of type 2 diabetes, including taking a comprehensive patient history.",
    benefit: 77.4,
    role: ClinicianMedicareRole.Dietitian,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan45
  },
  {
    mbsCode: '93074',
    description:
      'Dietetics health service provided by telehealth attendance to an eligible patient by an eligible dietitian, lasting at least 20 minutes',
    benefit: 60.35,
    role: ClinicianMedicareRole.Dietitian,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan20
  },
  {
    mbsCode: '93108',
    description:
      'Dietetics health service provided by phone attendance to an eligible patient by an eligible dietitian, lasting at least 20 minutes',
    benefit: 60.35,
    role: ClinicianMedicareRole.Dietitian,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan20
  }
];
