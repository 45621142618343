import bent, { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL!;

export const getLetterList = (token: string, accountId: string, clinicianId: string, queryParam?: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians/${clinicianId}/letters${queryParam ?? ''}`
  ) as Promise<BentResponse>;

export const getLetterListByAdmin = (token: string, accountId: string, queryParam?: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letters${queryParam ?? ''}`
  ) as Promise<BentResponse>;

export const getLetterByAdmin = (token: string, accountId: string, letterId: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letters/${letterId}`
  ) as Promise<BentResponse>;

export const getLetter = (token: string, accountId: string, clinicianId: string, letterId: string) =>
  httpClient('GET', url, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/clinicians/${clinicianId}/letters/${letterId}`
  ) as Promise<BentResponse>;

export const postLetter = (token: string, accountId: string, body: Record<string, any>) =>
  httpClient('POST', url, 201, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letters`,
    body
  ) as Promise<BentResponse>;

export const putLetter = (token: string, accountId: string, letterId: string, body: Record<string, any>) =>
  httpClient('PUT', url, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letters/${letterId}`,
    body
  ) as Promise<BentResponse>;

export const putPublishLetter = (token: string, accountId: string, letterId: string) =>
  httpClient('PATCH', url, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letters/${letterId}:publish`
  );

export const downloadLetterPdf = async (
  token: string,
  accountId: string,
  letterId: string,
  body?: { printPayload: string }
) => {
  const response = await bent('POST', 'buffer', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/accounts/${accountId}/letters/${letterId}/printable-pdf`, body);
  return new Blob([response], { type: 'application/pdf' });
};

export const putUnpublishLetter = (token: string, accountId: string, letterId: string, asAdmin?: boolean) =>
  httpClient('PATCH', url, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letters/${letterId}:unpublish${asAdmin ? '?asAdmin=true' : ''}`
  );

export const putShareLetter = (token: string, accountId: string, letterId: string, body: Record<string, any>) =>
  httpClient('PUT', url, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letters/${letterId}/share`,
    body
  ) as Promise<BentResponse>;

export const putRevokeLetterAccessor = (token: string, accountId: string, letterId: string, accessorId: string) =>
  httpClient('PUT', url, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letters/${letterId}/accessor/${accessorId}/revoke`
  );

export const backToDraft = (token: string, accountId: string, letterId: string) =>
  httpClient('PATCH', url, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/letters/${letterId}:draft`
  );

export const deleteLetter = (token: string, accountId: string, letterId: string) =>
  httpClient('DELETE', url, 204, { Authorization: `Bearer ${token}` })(`/accounts/${accountId}/letters/${letterId}`);
