import MaterialToggleSwitch from 'components/MaterialFieldComponent/MaterialToggleSwitch/MaterialToggleSwitch';
import { Tooltip } from 'react-tooltip';

import styles from './IncludeMedicareDetails.module.scss';

interface IncludeMedicareDetailsProps {
  includeMedicareDetails: boolean;
  autofillMedicareDetails: boolean;
  isClientRecordSelected: boolean;
  isClientMedicareValid: boolean;
  isPracticeConnectedMedicare: boolean;
  onChangeIncludeMedicareDetails: (val: boolean) => void;
  onChangeAutofillMedicareDetails: (val: boolean) => void;
}

const IncludeMedicareDetails = ({
  includeMedicareDetails,
  autofillMedicareDetails,
  isClientRecordSelected,
  isClientMedicareValid,
  isPracticeConnectedMedicare,
  onChangeIncludeMedicareDetails,
  onChangeAutofillMedicareDetails
}: IncludeMedicareDetailsProps) => {
  const disableIncludeMedicareDetails =
    !isClientRecordSelected || !isClientMedicareValid || !isPracticeConnectedMedicare;

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.title}>
          <i className={`material-icons-outlined ${styles.icon}`}>cases</i>
          <div className={styles.text}>Include Medicare Details</div>
        </div>
        <div className={styles.toggleContainer} data-tooltip-id="disable-medicare-details">
          <MaterialToggleSwitch
            name={'includeMedicareDetails'}
            value={includeMedicareDetails}
            onChange={onChangeIncludeMedicareDetails}
            disabled={disableIncludeMedicareDetails}
          />
        </div>
        {disableIncludeMedicareDetails && (
          <Tooltip id="disable-medicare-details" className={styles.tooltips}>
            {!isPracticeConnectedMedicare
              ? 'Medicare not connected, visit Control Panel > System Connections to enable.'
              : !isClientRecordSelected
              ? 'Please select a client.'
              : 'This medicare setting for this client profile is OFF. Please turn ON and add the required Medicare details to include on their invoices.'}
          </Tooltip>
        )}
      </div>
      <div className={includeMedicareDetails ? styles.autofillContainer : styles.hiddenAutofillContainer}>
        <label
          className={styles.checkboxContainer}
          htmlFor={'checkbox-medicare-autofill'}
          data-tooltip-id="disable-medicare-autofill"
        >
          <input
            className={styles.checkbox}
            id={'checkbox-medicare-autofill'}
            type="checkbox"
            checked={autofillMedicareDetails}
            onChange={(e) => onChangeAutofillMedicareDetails(e.target.checked)}
          />
          <div className={styles.label}>Autofill information?</div>
        </label>
      </div>
    </div>
  );
};

export default IncludeMedicareDetails;
