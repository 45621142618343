import MaterialInput from 'components/MaterialInput/MaterialInput';
import Radio from 'components/Radio/Radio';
import { useField } from 'formik';
import {
  AppointmentType,
  DeliveryType,
  DELIVERY_TYPE_LABELS,
  OtherInstructions
} from 'interfaces/Schedule/AppointmentType';
import { useState, useEffect } from 'react';
import styles from './DeliveryModeSelect.module.scss';
import classNames from 'classnames';
import HowToConnect from './components/HowToConnect/HowToConnect';

interface DeliveryModeSelectProps {
  appointmentType?: AppointmentType;
  showAdditionalInput?: boolean;
}

const DeliveryModeSelect = ({ appointmentType, showAdditionalInput }: DeliveryModeSelectProps) => {
  const [{ value: deliveryType }, , { setValue: setDeliveryType }] = useField('deliveryType');
  const [locationProps, , locationHelper] = useField('faceToFaceLocation');
  const [callProps, , callHelper] = useField('videoCallInstructions');
  const [phoneProps, , phoneHelper] = useField('phoneCallInstructions');
  const [phoneDialClientProps, , phoneDialClientHelper] = useField('phoneCallDialClientInstructions');
  const [otherInstructionsProps, , otherInstructionsHelper] = useField<OtherInstructions | undefined>(
    'otherInstructions'
  );
  const [selected, setSelected] = useState(deliveryType || DeliveryType.FaceToFace);

  useEffect(() => {
    if (appointmentType) {
      if (!deliveryType || !appointmentType.deliveryOptions.includes(deliveryType)) {
        setDeliveryType(appointmentType.deliveryOptions?.[0]);
        setSelected(appointmentType.deliveryOptions?.[0]);
      }
      locationHelper.setValue(appointmentType.faceToFaceLocation);
      callHelper.setValue(appointmentType.videoCallInstructions || appointmentType.defaultVideoCallInstructions);
      phoneHelper.setValue(appointmentType.phoneCallInstructions);
      phoneDialClientHelper.setValue(appointmentType.phoneCallDialClientInstructions);
      otherInstructionsHelper.setValue(appointmentType.otherInstructions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentType]);

  const onSelected = (value: string) => {
    setSelected(value);
    setDeliveryType(value);
  };

  return appointmentType?.deliveryOptions && appointmentType?.deliveryOptions.length > 0 ? (
    <div className={styles.container}>
      {appointmentType?.deliveryOptions.includes(DeliveryType.FaceToFace) && (
        <div>
          <Radio
            useT23Styles
            name="deliveryType"
            value={DeliveryType.FaceToFace}
            checked={selected === DeliveryType.FaceToFace}
            onChange={(e) => onSelected(e.target.value)}
            options={[
              {
                value: DeliveryType.FaceToFace,
                label: DELIVERY_TYPE_LABELS[DeliveryType.FaceToFace]
              }
            ]}
          />
          {showAdditionalInput && selected === DeliveryType.FaceToFace && (
            <div className={classNames(styles.subField, styles.location)}>
              <MaterialInput
                id={`location`}
                label={'Location'}
                onChange={(e) => locationHelper.setValue(e.target.value)}
                value={locationProps.value}
                required
                style={{
                  background: 'transparent'
                }}
              />
            </div>
          )}
        </div>
      )}
      {appointmentType?.deliveryOptions.includes(DeliveryType.PhoneCall) && (
        <div>
          <Radio
            useT23Styles
            name="deliveryType"
            value={DeliveryType.PhoneCall}
            checked={selected === DeliveryType.PhoneCall}
            onChange={(e) => onSelected(e.target.value)}
            options={[
              {
                value: DeliveryType.PhoneCall,
                label: DELIVERY_TYPE_LABELS[DeliveryType.PhoneCall]
              }
            ]}
          />
          {showAdditionalInput && selected === DeliveryType.PhoneCall && !!appointmentType.phoneCallInstructions && (
            <HowToConnect value={phoneProps.value} onChange={phoneHelper.setValue} />
          )}
        </div>
      )}
      {appointmentType?.deliveryOptions.includes(DeliveryType.PhoneCallDialClient) && (
        <div>
          <Radio
            useT23Styles
            name="deliveryType"
            value={DeliveryType.PhoneCallDialClient}
            checked={selected === DeliveryType.PhoneCallDialClient}
            onChange={(e) => onSelected(e.target.value)}
            options={[
              {
                value: DeliveryType.PhoneCallDialClient,
                label: DELIVERY_TYPE_LABELS[DeliveryType.PhoneCallDialClient]
              }
            ]}
          />
          {showAdditionalInput && selected === DeliveryType.PhoneCallDialClient && (
            <HowToConnect value={phoneDialClientProps.value} onChange={phoneDialClientHelper.setValue} />
          )}
        </div>
      )}
      {appointmentType?.deliveryOptions.includes(DeliveryType.VideoCall) && (
        <div>
          <Radio
            useT23Styles
            name="deliveryType"
            value={DeliveryType.VideoCall}
            checked={selected === DeliveryType.VideoCall}
            onChange={(e) => onSelected(e.target.value)}
            options={[
              {
                value: DeliveryType.VideoCall,
                label: DELIVERY_TYPE_LABELS[DeliveryType.VideoCall]
              }
            ]}
          />
          {showAdditionalInput && selected === DeliveryType.VideoCall && (
            <HowToConnect value={callProps.value} onChange={callHelper.setValue} />
          )}
        </div>
      )}
      {appointmentType?.deliveryOptions.includes(DeliveryType.Other) && appointmentType.otherInstructions && (
        <div>
          <Radio
            useT23Styles
            name="deliveryType"
            value={DeliveryType.Other}
            checked={selected === DeliveryType.Other}
            onChange={(e) => onSelected(e.target.value)}
            options={[
              {
                value: DeliveryType.Other,
                label: appointmentType.otherInstructions.title
              }
            ]}
          />
          {showAdditionalInput && selected === DeliveryType.Other && (
            <div className={classNames(styles.subField, styles.location)}>
              <MaterialInput
                id={`otherInstructions`}
                label={'Connection Instructions'}
                onChange={(e) =>
                  otherInstructionsHelper.setValue({
                    ...(otherInstructionsProps.value as OtherInstructions),
                    instructions: e.target.value
                  })
                }
                value={otherInstructionsProps.value?.instructions}
                required
                style={{
                  background: 'transparent'
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <div className={styles.noDeliveryOption}>No delivery type for this appointment type.</div>
  );
};

export default DeliveryModeSelect;
