import { useEffect, useState } from 'react';
import moment from 'moment';
import { notification } from 'antd';
import { CaseNote } from 'interfaces/caseNote';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Select from 'components/Select/CommonSelect/Select';
import MaterialLabel from 'components/MaterialLabel/MaterialLabel';
import {
  getClinicalAssessmentList,
  getGroupClinicalAssessmentList
} from 'utils/http/CheckInService/Assessment/clinicalAssessment';
import { toCamelCase } from 'utils/generateCamelCase';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { getClientAppointmentsByDateRange } from 'utils/http/ScheduleService/Appointments/Appointments';
import { useGetAccessToken } from 'utils/hooks/token';
import classnames from 'classnames';

import styles from './LinkWrapper.module.scss';
import { massageNameInId } from 'pages/PatientDetails/components/PatientDetailsContent/hooks/actions';
import { getGroupSessionList } from 'utils/http/ScheduleService/Groups/groups';

const useFetchAssessmentList = (token: string, recordId: string, nameInId?: boolean, isGroup?: boolean) => {
  const [assessmentData, setAssessmentData] = useState([] as any);
  const [isAssessmentDataLoading, setIsAssessmentDataLoading] = useState(true);

  const fetchAssessmentList = async () => {
    if (recordId) {
      try {
        const callGetClinicalAssessmentResponsesListByPatientId = isGroup
          ? await getGroupClinicalAssessmentList(token, recordId)
          : await getClinicalAssessmentList(token, recordId);
        let assessmentDataList = await callGetClinicalAssessmentResponsesListByPatientId.json();
        const massageAssessmentData = [];
        if (nameInId) {
          assessmentDataList = massageNameInId(assessmentDataList);
        }
        for (let obj of assessmentDataList) {
          massageAssessmentData.push({
            _id: obj._id,
            shortCode: obj.id,
            date: obj.createdAt,
            label: `${moment(obj.createdAt).format(MOMENTJS_DATE_FORMAT)} ${obj.id}`,
            value: obj._id
          });
        }
        setAssessmentData(massageAssessmentData);
        setIsAssessmentDataLoading(false);
      } catch (ex) {
        notification.error({ message: "Something went wrong while trying to get this patient's assessments" });
      }
    }
  };

  useEffect(() => {
    if (token && recordId) {
      fetchAssessmentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, recordId]);

  return { assessmentData, isAssessmentDataLoading };
};

const useFetchAppointments = (token: string, clientOrGroupId: string, isGroup?: boolean) => {
  const [appointmentsData, setAppointmentsData] = useState([] as any);
  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(false);

  const fetchAppointments = async (token: string, clientOrGroupId: string) => {
    setIsAppointmentsLoading(true);
    if (clientOrGroupId) {
      try {
        const from = moment('2020-01-01').format('YYYY-MM-DD');
        const to = moment().add(3, 'months').format('YYYY-MM-DD');
        const appointments = !isGroup
          ? await (await getClientAppointmentsByDateRange({ token, from, to, clientRecordId: clientOrGroupId })).json()
          : (await (await getGroupSessionList(token, clientOrGroupId)).json()).sessions;
        const massageAppointmentsData = [];
        for (let obj of appointments) {
          massageAppointmentsData.push({
            _id: obj._id,
            shortCode: obj.sessionTypeName || '',
            date: obj.date,
            startTime: obj.startTime,
            endTime: obj.endTime,
            label: `${moment(obj.date).format(MOMENTJS_DATE_FORMAT)} ${obj.sessionTypeName || ''}`,
            value: obj._id
          });
        }
        setAppointmentsData(massageAppointmentsData);
      } catch (ex) {
        notification.error({
          message: "Something went wrong while trying to fetch this patient's appointments",
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }

    setIsAppointmentsLoading(false);
  };

  useEffect(() => {
    if (token && clientOrGroupId) {
      fetchAppointments(token, clientOrGroupId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clientOrGroupId]);

  return { appointmentsData, isAppointmentsLoading };
};

interface LinkWrapperProps {
  recordId: string;
  field: CaseNote['fields'][number];
  isValidated: boolean;
  onChangeFieldInput: (id: string, value: any) => void;
  className?: string;
  isDisabled?: boolean;
  isChildRecord?: boolean;
  isGroup?: boolean;
}

const LinkWrapper = ({
  recordId,
  field,
  isValidated,
  onChangeFieldInput,
  className,
  isDisabled,
  isChildRecord,
  isGroup
}: LinkWrapperProps) => {
  const { token } = useGetAccessToken();
  const { appointmentsData } = useFetchAppointments(token, recordId, isGroup) as any;
  const { assessmentData } = useFetchAssessmentList(token, recordId, isChildRecord, isGroup) as any;
  const minimisedLabel = field.value?.length > 0 || !!field.value;
  const [linkFieldData, setLinkFieldData] = useState({} as CaseNote['fields'][number]);
  const [isLabelMinimised, setIsLabelMinimised] = useState(minimisedLabel);

  useEffect(() => {
    setLinkFieldData(field);
    setIsLabelMinimised(minimisedLabel);
  }, [field, minimisedLabel]);

  const handleChange = (val: any) => {
    onChangeFieldInput(linkFieldData._id, val);
    setIsLabelMinimised(val?.length > 0 || !!val);
  };

  const handleFocus = () => {
    setIsLabelMinimised(true);
  };

  const handleBlur = () => {
    setIsLabelMinimised(linkFieldData.value);
  };

  return (
    <div className={classnames(styles.headingField, className)}>
      <MaterialLabel
        label={`${linkFieldData.name} ${linkFieldData.mandatory ? '*' : ''}`}
        isLabelMinimised={isLabelMinimised}
      />
      <Select
        id={toCamelCase(linkFieldData.name)}
        className={styles.link}
        options={linkFieldData.linkType === 'assessment' ? assessmentData : appointmentsData}
        placeholder=""
        labelClass={styles.label}
        isSearchable={false}
        value={linkFieldData.value ? linkFieldData.value : ''}
        onChange={handleChange}
        onFocus={() => handleFocus()}
        onBlur={() => handleBlur()}
        smallCaretDown
        isDisabled={isDisabled}
        styles={{
          container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
          valueContainer: (base: any) => ({
            ...base,
            minHeight: '30px',
            padding: '0 8px 0 0'
          }),
          control: (controlBase: any) => ({
            ...controlBase,
            minHeight: '30px',
            backgroundColor: 'transparent',
            border: 'none',
            borderBottom: `1px solid ${styles.greyColor}`,
            borderRadius: 0,
            boxShadow: 'none'
          })
        }}
      />
      <ErrorMessage
        className={styles.error}
        error={'Please select at least one item'}
        visible={isValidated && linkFieldData.mandatory && !linkFieldData.value}
      />
    </div>
  );
};

export default LinkWrapper;
