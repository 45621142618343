import moment from 'moment';
import {
  AppointmentReportQuery,
  useGetAppointmentReportByClinicianIdQuery
} from 'redux/endpoints/scheduleServices/appointmentReport';
import { MOMENTJS_MONTH_YEAR_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { SortType } from '../utils';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

export const getAppointmentReportQueryParams = ({
  dateString,
  sortBy,
  sortType
}: {
  dateString: string;
  sortBy?: string;
  sortType?: SortType;
}): AppointmentReportQuery => {
  return {
    from: moment(dateString, MOMENTJS_MONTH_YEAR_FORMAT).startOf('month').format(MOMENTJS_YEAR_MONTH_DAY_FORMAT),
    to: moment(dateString, MOMENTJS_MONTH_YEAR_FORMAT).endOf('month').format(MOMENTJS_YEAR_MONTH_DAY_FORMAT),
    ...(sortType && sortBy && sortBy === 'dateOfService'
      ? { sortByDate: sortType }
      : sortBy === 'clientName'
      ? { sortByName: sortType }
      : sortBy === 'processedOutcome'
      ? { sortByProcessedOutcome: sortType }
      : sortBy === 'invoiceAmount' && { sortByInvoiceAmount: sortType })
  };
};

export const useGetAppointmentReport = ({
  clinicianId,
  dateString,
  sortBy,
  sortType
}: {
  clinicianId: string;
  dateString: string;
  sortBy?: string;
  sortType?: SortType;
}) => {
  const { isPractitionerReportEnabled } = useGetFeatureToggle();
  const { data, isLoading, isFetching } = useGetAppointmentReportByClinicianIdQuery(
    {
      clinicianId,
      params: getAppointmentReportQueryParams({ dateString, sortBy, sortType })
    },
    { skip: !isPractitionerReportEnabled }
  );

  return {
    isLoading: isLoading || isFetching,
    data
  };
};
