import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import WriteOffListFilter from '../WriteOffListFilter/WriteOffListFilter';
import styles from './WriteOffList.module.scss';
import WriteOffListHeader from '../WriteOffListHeader/WriteOffListHeader';
import { Skeleton, notification } from 'antd';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectPaging, setPaging } from 'redux/invoices/writeOffInvoiceSlice';
import PerRecordDropdown from 'components/TableList/PerRecordDropdown/PerRecordDropdown';
import PaginationDescV2 from 'components/TableList/PaginationDescV2/PaginationDescV2';
import { useEffect } from 'react';
import WriteOffListItem from '../WriteOffListItem/WriteOffListItem';
import { useGetClinicianProfileQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';

import { useGetWriteOffInvoicesList } from 'redux/invoices/useGetWriteOffInvoices';
import { PER_PAGE_DEFAULT } from 'redux/features/utils';

const WriteOffList = () => {
  const dispatch = useAppDispatch();
  const { isSomeoneHealthFeatureToggle } = useGetFeatureToggle();

  const paging = useAppSelector(selectPaging);

  const { data: profileData, isLoading: isProfileLoading } = useGetClinicianProfileQuery();
  const { isError, isWriteOffInvoicesLoading, writeOffInvoices, writeOffInvoiceTotalItems } =
    useGetWriteOffInvoicesList();

  const onChangePage = (page: number) => {
    dispatch(setPaging({ ...paging, page }));
  };

  const onChangePerPage = (perPage: number) => {
    dispatch(setPaging({ ...paging, perPage, page: 1 }));
  };

  useEffect(() => {
    if (isError && !isWriteOffInvoicesLoading) {
      notification.error({ message: 'Something went wrong while trying to fetch write off invoices.' });
    }
  }, [isWriteOffInvoicesLoading, isError]);

  return (
    <div className={styles.container}>
      {/* Filters */}
      <WriteOffListFilter showInvoiceType={isSomeoneHealthFeatureToggle} />

      <div className={styles.listWrapper}>
        {/* Header */}
        <WriteOffListHeader showInvoiceType={isSomeoneHealthFeatureToggle} />

        {/* List items */}
        {isWriteOffInvoicesLoading || isProfileLoading ? (
          <div className={styles.loadingWrapper}>
            {[...Array(10)].map((_, i) => (
              <div key={i} className={styles.loadingItem}>
                <Skeleton.Input active className={styles.loadingContent} />
              </div>
            ))}
          </div>
        ) : writeOffInvoices.length < 1 ? (
          <div>No invoices</div>
        ) : (
          <div className={styles.listItem}>
            {writeOffInvoices.map((invoice, index) => (
              <WriteOffListItem
                key={index}
                invoice={invoice}
                practiceName={profileData?.practice?.name || ''}
                showInvoiceType={isSomeoneHealthFeatureToggle}
              />
            ))}
          </div>
        )}

        {/* Pagination */}
        {!isWriteOffInvoicesLoading && writeOffInvoiceTotalItems > PER_PAGE_DEFAULT && (
          <div className={styles.paginationWrapper}>
            <div className={styles.recordPerPageWrapper}>
              <PerRecordDropdown
                totalItem={writeOffInvoiceTotalItems}
                selectedPerPage={paging.perPage}
                selectDropdownValue={onChangePerPage}
              />
              <div className={styles.label}>write offs per page</div>
            </div>
            <PaginationDescV2
              currentPage={paging.page}
              totalItem={writeOffInvoiceTotalItems}
              perPage={paging.perPage}
              onPageChange={onChangePage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WriteOffList;
