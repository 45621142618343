import { useState } from 'react';
import styles from './Chapter.module.scss';
import { Tag } from '../types';
import classNames from 'classnames';
import completedSvg from '../../../../../../../../../assets/images/bazaar/completed.svg';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

type ChapterProps = {
  tag: Tag;
  token: string;
  clientRecordId: string;
  onDoneChapter: (id: string) => void;
  onUndoChapter: (id: string) => void;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
};

const Chapter = ({ tag, onDoneChapter, onUndoChapter }: ChapterProps) => {
  const [loading, setLoading] = useState(false);

  const doneChapter = async () => {
    setLoading(true);
    await onDoneChapter(tag._id);
    setLoading(false);
  };

  const undoChapter = async () => {
    setLoading(true);
    await onUndoChapter(tag._id);
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.label,
          tag.isNextChapter && styles.nextChapter,
          tag.isCompleted && styles.isCompleted
        )}
      >
        {tag.label}
      </div>
      {loading ? (
        <LoadingCircle />
      ) : !tag.isCompleted ? (
        <button className={styles.button} onClick={doneChapter}>
          Done
        </button>
      ) : (
        <>
          <img src={completedSvg} className={styles.icon} alt="Completed" />
          <button className={styles.button} onClick={undoChapter}>
            Undo
          </button>
        </>
      )}
    </div>
  );
};

export default Chapter;
