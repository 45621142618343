import { useMemo } from 'react';

import { Question, Option } from 'interfaces/checkInService/AssessmentInterfaces';

import styles from './MultipleChoice.module.scss';

interface MultipleChoiceProps {
  question: Question;
  hideStem?: boolean;
}

const MultipleChoice = ({ question, hideStem }: MultipleChoiceProps) => {
  const options = useMemo(() => {
    const { options, response } = question;

    if (options) {
      return (options as Option[]).map(({ key, description }) => ({
        key,
        description,
        checked: response && (Array.isArray(response.value) ? response.value.includes(key) : key === response.value)
      }));
    } else {
      return [];
    }
  }, [question]);

  return (
    <div className={styles.container}>
      <div className={styles.questionWrapper}>
        {!hideStem && <div className={styles.stem}>{question.stem}</div>}
        <div className={styles.answerWrapper}>
          {options.map(({ key, description, checked }) => (
            <div className={checked ? styles.answerBtnChecked : styles.answerBtn} key={key}>
              {description}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultipleChoice;
