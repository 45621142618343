import { notification } from 'antd';
import FormikHiddenInput from 'components/HiddenInput/FormikHiddenInput';
import OptionLabel from 'components/OptionLabel/OptionLabel';
import FormikRadio from 'components/Radio/FormikRadio';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import moment from 'moment';
import { PaymentMethod } from 'pages/Invoices/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { postConnectStripe } from 'utils/http/BillingService/Invoice/stripe';

import styles from './OnlinePayment.module.scss';
import { config } from 'config/config';
import { security } from 'utils/security';

interface OnlinePaymentProps {
  paymentMethod?: PaymentMethod;
  index: number;
}

const MONEY_SYMBOL = config.currencySymbol;

export const OnlinePayment = ({ paymentMethod, index }: OnlinePaymentProps) => {
  const [isRegisterWithStripeLoading, setIsRegisterWithStripeLoading] = useState(false);
  const [stripeFeeMethod, setStripeFeeMethod] = useState(paymentMethod?.stripeFeeMethod ?? 'inclusive');
  const [t] = useTranslation();

  const handleConnectStripe = async () => {
    setIsRegisterWithStripeLoading(true);
    try {
      const token = await security.getAccessTokenSilently();
      const stripeUrl = await (await postConnectStripe(token, { feeMethod: stripeFeeMethod })).text();

      window.location.href = stripeUrl;
    } catch (ex) {
      setIsRegisterWithStripeLoading(false);
      notification.error({
        message: 'Something went wrong while trying to register with Stripe',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <>
      <div className={styles.stripeDescription}>
        <p>
          To provide you with integrated invoicing and payment collection, and bank payouts, Tacklit charge 1%
          transaction fee (+ any card processing fees based on the payment method used).{' '}
          <a href="https://www.tacklit.com/how/productivity/finances/payment-fees">Read more about card fees here</a>
        </p>

        <p>Please select how you’d like to handle the collection of the transaction fee.</p>
      </div>
      <div className={styles.stripeFeeMethod}>
        <FormikRadio
          name={`paymentMethods.${index}.stripeFeeMethod`}
          options={[
            {
              value: 'inclusive',
              label: (
                <OptionLabel
                  title={t('invoice.stripe_fee_methods.inclusive')}
                  desc={t('invoice.stripe_fee_methods.inclusive.description', { currency: MONEY_SYMBOL })}
                />
              )
            },
            {
              value: 'exclusive',
              label: (
                <OptionLabel
                  title={t('invoice.stripe_fee_methods.exclusive')}
                  desc={t('invoice.stripe_fee_methods.exclusive.description', { currency: MONEY_SYMBOL })}
                />
              )
            }
          ]}
          value={stripeFeeMethod}
          onChange={({ target }) => {
            setStripeFeeMethod(target.value);
          }}
        />
      </div>
      {paymentMethod?.stripeStatus === 'completed' ? (
        <div className={styles.stripeSetupContainer}>
          <div className={styles.setupTitle}>Your Stripe account is successfully connected</div>
          <div className={styles.stripeInfo}>
            <div className={styles.audit}>
              <div className={styles.setUpEdit}>
                <div className={styles.setUpContainer}>
                  <i className={`material-icons-round ${styles.checkIcon}`}>check</i>
                  <div className={styles.setUp}>
                    Set up by: {paymentMethod.stripeConnectedBy} <br />
                    On: {moment(paymentMethod.stripeConnectedAt).format(MOMENTJS_DATE_FORMAT)}
                  </div>
                </div>
                {paymentMethod.stripeUpdatedAt && (
                  <div className={styles.edit}>
                    <div className={styles.subTitle}>Last edited on</div>
                    <div className={styles.date}>
                      {moment(paymentMethod.stripeUpdatedAt).format(MOMENTJS_DATE_FORMAT)}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.account}>
              <span className={styles.subTitle}>Payout Account Information</span>
              <div className={styles.accountDetails}>
                <div className={styles.keys}>
                  <span>Bank Name</span>
                  {config.codeLabel && paymentMethod.stripeConnectedBank?.routingNumber && (
                    <span>{config.codeLabel}</span>
                  )}
                  <span>Account #</span>
                </div>
                <div className={styles.values}>
                  <span>{paymentMethod.stripeConnectedBank?.bankName}</span>
                  {config.codeLabel && paymentMethod.stripeConnectedBank?.routingNumber && (
                    <span>{paymentMethod.stripeConnectedBank?.routingNumber}</span>
                  )}
                  <span>••••••{paymentMethod.stripeConnectedBank?.last4}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.editCTA}>
            <ButtonAlt
              variant={'text'}
              size={'medium'}
              onClick={handleConnectStripe}
              disabled={isRegisterWithStripeLoading}
              icon={'arrow_forward'}
              iconPostFix
            >
              Edit account details
            </ButtonAlt>
          </div>
        </div>
      ) : (
        <div className={styles.stripeSetupContainer}>
          <div className={styles.setupTitle}>Set up your account to pay money into</div>
          <div className={styles.setupDesc}>
            We utilise the leading capabilities of global leader Stripe to process payments.
            <br />
            As part of the compliance needed to securely process and pay you, you need to register your information with
            Stripe to set up online payment.
          </div>
          <div className={styles.setupCTA}>
            {paymentMethod?.stripeStatus === 'incomplete' ? (
              <>
                <span className={styles.inProgress}>Registration in progress</span>
                <ButtonAlt
                  variant={'text'}
                  size={'medium'}
                  onClick={handleConnectStripe}
                  disabled={isRegisterWithStripeLoading}
                  icon={'arrow_forward'}
                  iconPostFix
                >
                  Go to Stripe to complete
                </ButtonAlt>
              </>
            ) : (
              <>
                <FormikHiddenInput id={`paymentMethods.${index}.stripeAccountId`} />
                <ButtonAlt
                  type="button"
                  size={'medium'}
                  onClick={handleConnectStripe}
                  disabled={isRegisterWithStripeLoading}
                  icon={'arrow_forward'}
                  iconPostFix
                >
                  Register with Stripe
                </ButtonAlt>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OnlinePayment;
