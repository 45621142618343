import classNames from 'classnames';
import Card from 'components/Card/Card';
import ThreeDotMenu, { ThreeDotMenuHandle } from 'components/T23/ThreeDotMenu/ThreeDotMenu';
import { ReactNode, Ref } from 'react';
import styles from './FolderCard.module.scss';

interface FolderCardProps {
  active?: boolean;
  closeMenuButtonRef?: Ref<ThreeDotMenuHandle>;
  containerClassName?: string;
  folderIconClassName?: string;
  folderName?: string;
  folderNameClassName?: string;
  moreMenu?: ReactNode;
  onCardClick?: () => void;
  totalFiles?: number;
}

const FolderCard = ({
  active,
  closeMenuButtonRef,
  containerClassName,
  folderIconClassName,
  folderName,
  folderNameClassName,
  moreMenu,
  onCardClick,
  totalFiles = 0
}: FolderCardProps) => {
  return (
    <Card onClick={onCardClick} className={classNames(containerClassName, active && styles.active)}>
      <div className={styles.iconContainer}>
        <div className={classNames('material-symbols-outlined', folderIconClassName)}>
          {active ? 'folder_open' : 'folder'}
        </div>
        {moreMenu && (
          <ThreeDotMenu ref={closeMenuButtonRef} iconButtonClassName={styles.moreMenuIcon}>
            {moreMenu}
          </ThreeDotMenu>
        )}
      </div>
      <div className={folderNameClassName}>{folderName}</div>
      <div>{totalFiles} files</div>
    </Card>
  );
};

export default FolderCard;
