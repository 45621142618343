import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import Radio from 'components/Radio/Radio';
import ButtonSelectAll from 'components/v2/ButtonSelectAll/ButtonSelectAll';
import SearchBar from 'components/v2/SearchBar/SearchBar';
import { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import FilterCheckItem from '../FilterCheckItem/FilterCheckItem';
import styles from './FilterCheckList.module.scss';
import OptionLabel from '../OptionLabel/OptionLabel';
import classnames from 'classnames';

const SCROLL_ROWS = 3;

export interface FilterSubMenuItem {
  _id: string;
  name: string;
  disabled?: boolean;
}

export interface FilterCheckListItem {
  _id: string;
  name: string;
  backgroundColor?: string;
  fontColor?: string;
  section?: string;
  subMenu?: FilterSubMenuItem[];
  itemFlag?: string;
  selectedSubMenu?: FilterSubMenuItem;
}

export interface FilterRadioListItem {
  _id: string;
  name: string;
  subMenu?: FilterSubMenuItem[];
  selectedSubMenu?: FilterSubMenuItem;
}

export interface FilterCheckListChangeValue {
  item?: FilterCheckListItem;
  toggleAllValue?: boolean;
  color?: string;
  searchValue?: string;
  subMenuItem?: FilterSubMenuItem;
}

export interface FilterCheckListProps {
  id: string;
  /** For infinity scroll. Whether there are more items to be loaded. */
  hasMoreData?: boolean;
  items: FilterCheckListItem[];
  loading?: boolean;
  /** For infinity scroll. A callback when more items are requested by the user.
   * Receives a single parameter specifying the page to load */
  loadMore?: () => void;
  /**
   * `isFetchingMore` is the loading state for infinity scroll when they are fetching more
   */
  isFetchingMore?: boolean;
  needToSort?: boolean;
  notFoundText?: string;
  onChangeItem: (value: FilterCheckListChangeValue) => void;
  searchable?: boolean;
  selectedFilterList: FilterCheckListItem[];
  showToggleAllButtons?: boolean;
  filterRadioListItems?: FilterRadioListItem[];
  selectedRadioItem?: string;
  onChangeRadioItem?: (selected: string) => void;
  setSearchText?: (value: string) => void;
  multiple?: boolean;
  checkBoxSectionTitle?: string;
  radioSectionTitle?: string;
  reverseSection?: boolean;
  hideFilterList?: boolean;
}

const infinityScrollLoader = (
  <div className={styles.listLoading} key={'loading'}>
    <LoadingCircle />
  </div>
);

const FilterCheckList = ({
  id,
  hasMoreData,
  items,
  loading,
  loadMore,
  needToSort,
  notFoundText,
  onChangeItem,
  searchable,
  selectedFilterList,
  showToggleAllButtons,
  filterRadioListItems,
  selectedRadioItem,
  onChangeRadioItem,
  isFetchingMore,
  setSearchText,
  multiple = true,
  checkBoxSectionTitle,
  radioSectionTitle,
  reverseSection,
  hideFilterList
}: FilterCheckListProps) => {
  const [searchValue, setSearchValue] = useState('');
  const searchItems = items
    .map((item) => selectedFilterList.find((i) => i._id === item._id) || item)
    .filter((item) => item.name?.toLowerCase().includes(searchValue.toLowerCase()));
  const ref = useRef<HTMLDivElement>(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const { isEdgeAdminView } = useGetAccountPackageView();

  const onScrollClick = () => {
    ref.current?.scrollBy({
      top: SCROLL_ROWS * (ref.current.scrollHeight / searchItems.length),
      behavior: 'smooth'
    });
  };

  const sortedList = needToSort
    ? searchItems.sort((a, b) => {
        if (a._id === '') {
          return -1;
        } else if (b._id === '') {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      })
    : searchItems;

  const ableSelectAll = useMemo(() => {
    return (
      searchItems.length !==
      selectedFilterList.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase())).length
    );
  }, [searchItems, selectedFilterList, searchValue]);

  const checklist =
    sortedList.length > 0 ? (
      sortedList.map((item, index) =>
        item.section ? (
          <div key={index} className={styles.section}>
            {item.section}
          </div>
        ) : (
          <FilterCheckItem
            id={id}
            key={index}
            item={item}
            onChangeItem={onChangeItem}
            searchValue={searchValue}
            selectedFilterList={selectedFilterList}
            multiple={multiple}
          />
        )
      )
    ) : searchValue.length > 0 && sortedList.length === 0 ? (
      <div className={styles.noItems}>
        <i className="material-icons-outlined">search_off</i>
        {notFoundText || 'No matching items found'}
      </div>
    ) : (
      <div className={styles.noItems}>No items found</div>
    );

  useEffect(() => {
    if (ref.current) {
      if (ref.current.scrollHeight - (ref.current.scrollTop + ref.current.clientHeight) < 10) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    }
    // eslint-disable-next-line
  }, [ref.current]);

  const onSearchChange = (value: string) => {
    setSearchValue(value);
    setSearchText && setSearchText(value);
  };

  return (
    <div
      id={`renderFilterOptionLists-${id}`}
      className={classnames(styles.container, isEdgeAdminView && 't23-admin-theme')}
    >
      {loading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <div className={classnames(styles.listWrapper, reverseSection && styles.reverseListWrapper)}>
          {!hideFilterList && (
            <div>
              {searchable && (
                <div className={styles.searchBoxWrapper}>
                  <SearchBar
                    containerClassName={styles.searchBox}
                    clearTextIcon
                    searchValue={searchValue}
                    setSearchValue={onSearchChange}
                  />
                </div>
              )}
              {showToggleAllButtons && (
                <div className={styles.actions}>
                  <ButtonSelectAll
                    onClick={() => onChangeItem({ toggleAllValue: ableSelectAll, searchValue })}
                    ableSelectAll={ableSelectAll}
                  />
                </div>
              )}
              <div className={styles.sectionTitle}>{checkBoxSectionTitle}</div>
              <div
                className={classNames(styles.items, isEdgeAdminView && styles.admin)}
                ref={ref}
                onScroll={(e) => {
                  setIsScrolledToBottom(
                    e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight) < 10
                  );
                }}
              >
                {loadMore ? (
                  <InfiniteScroll
                    hasMore={hasMoreData}
                    loader={infinityScrollLoader}
                    loadMore={loadMore}
                    pageStart={1}
                    useWindow={false}
                    initialLoad={false}
                  >
                    {checklist}
                  </InfiniteScroll>
                ) : (
                  checklist
                )}
              </div>
              {!isScrolledToBottom && !isFetchingMore && (
                <div
                  className={classNames(
                    styles.scrollDownText,
                    isScrolledToBottom && styles.scrolledToBottom,
                    isEdgeAdminView && styles.admin
                  )}
                  onClick={onScrollClick}
                >
                  <div className={styles.scroll}>
                    <i className="material-icons-outlined">arrow_downward</i>
                  </div>
                </div>
              )}
            </div>
          )}
          {filterRadioListItems && filterRadioListItems.length > 0 && onChangeRadioItem && (
            <div className={styles.timeBound}>
              <div className={styles.sectionTitle}>{radioSectionTitle}</div>
              <Radio
                vertical
                useT23Styles
                name={'timeBound'}
                className={styles.radioBtnWrapper}
                radioClassName={styles.radioBtn}
                labelClassName={styles.radioLabel}
                options={filterRadioListItems.map((radioItem, index) => ({
                  value: radioItem._id,
                  label: (
                    <OptionLabel
                      key={index}
                      titleClassName={classnames(selectedRadioItem === radioItem._id && styles.selectedLabel)}
                      isSelected={selectedRadioItem === radioItem._id}
                      title={radioItem.name}
                    />
                  )
                }))}
                value={selectedRadioItem}
                onChange={(e) => onChangeRadioItem(e.target.value)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterCheckList;
