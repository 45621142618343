import { notification } from 'antd';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { Formik } from 'formik';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import { useFetchMedicareProviders } from 'pages/ControlPanel/IntegrationDetails/components/IntegrationDetailsContent/components/IntegrationDetailsContentDisplay/components/Integration/hooks/getMedicareData';
import { useFetchClientDetails } from 'pages/Groups/GroupDetails/components/GroupContent/components/Psychometrics/hooks/getClientDetails';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import PaymentMethodsModal from 'pages/Invoices/components/PaymentMethods/components/PaymentMethodsModal/PaymentMethodsModal';
import { AccountingPlatform, IntegrationStatus, Invoice, InvoiceStatus, PaymentMethod } from 'pages/Invoices/interface';
import { useFetchGeneralPractitionerById } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/GPDetails/components/AddEditClientGPModal/hooks/getGeneralPractitionerList';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { combineName } from 'utils/general';
import { useFetchProfileById } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { useGetInvoiceSettingsQuery } from 'redux/endpoints/billingServices/invoiceSetting';
import { useInitPathGenerator } from 'utils/hooks/Path/pathGenerator';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { getInvoiceIdUsed, postInvoice, putInvoice } from 'utils/http/BillingService/Invoice/invoice';
import {
  getClientAppointmentsByDateRange,
  getGroupAppointmentsByDateRange
} from 'utils/http/ScheduleService/Appointments/Appointments';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import * as yup from 'yup';

import { ParticipantType } from '../../interfaces/Schedule/AppointmentType';
import InvoiceGeneratorForm from './components/InvoiceGeneratorForm/InvoiceGeneratorForm';
import { Appointment, InvoicedItem } from './interface';
import { useGetAccountInfo } from 'utils/hooks/GetAccountInfo/getAccountInfo';
import InvoiceGeneratorWithTemplate from 'pages/InvoiceGeneratorWithTemplate/InvoiceGeneratorWithTemplate';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useAppDispatch } from 'redux/hooks';
import { clientRecordsApiSlice } from 'redux/endpoints/clinicianProfileServices/client';
import { CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { billingServicesApiSlice, BSTagTypes } from 'redux/services/billingServicesApiSlice';
import { useFetchPaymentMethods } from 'pages/Invoices/Invoices';
import { security } from 'utils/security';

export interface InvoiceForm {
  description: string;
  medicare?: {
    claimant: {
      name: string;
      dateOfBirth: string;
      medicareNumber: string;
      irn: string;
      dva: string;
      expiryDate: string;
      phoneNumber: string;
      address: string;
    };
    referral: {
      name: string;
      date: string;
      providerNumber: string;
    };
    serviceProvider: {
      name: string;
      providerNumber: string;
    };
    claim: {
      dateOfService: string;
      mbsCode: string;
    };
  };
  discount: {
    type: 'percent' | 'amount';
    value: number;
  };
  items: InvoicedItem[];
  taxRate: number;
}

const INITIAL_VALUES = {
  description: '',
  discount: {
    type: 'percent',
    value: 0
  },
  items: [],
  taxRate: 0
} as InvoiceForm;

const useFetchAppointments = (token: string, clientRecordId?: string, selectedGroup?: GroupsFromAPI) => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(false);

  const fetchAppointments = async (token: string, clientRecordId?: string, selectedGroup?: GroupsFromAPI) => {
    setIsAppointmentsLoading(true);
    try {
      const from = moment('2020-10-01').format('YYYY-MM-DD');
      const to = moment().add(1, 'year').format('YYYY-MM-DD');

      if (selectedGroup) {
        const groupId = selectedGroup._id || '';
        const appointments = await (await getGroupAppointmentsByDateRange(token, groupId, from, to)).json();
        setAppointments(appointments);
      } else if (clientRecordId) {
        const appointments = await (await getClientAppointmentsByDateRange({ token, from, to, clientRecordId })).json();
        setAppointments(appointments);
      }
    } catch (ex) {
      notification.error({
        message: "Something went wrong while trying to fetch this patient's appointments",
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }

    setIsAppointmentsLoading(false);
  };

  useEffect(() => {
    if (token && (clientRecordId || selectedGroup)) {
      fetchAppointments(token, clientRecordId, selectedGroup);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clientRecordId, selectedGroup?._id]);

  return { appointments, isAppointmentsLoading };
};

const InvoiceSchema = yup.object().shape({
  items: yup
    .array()
    .of(
      yup.object().shape({
        overview: yup.string().required('Please provide an overview for this item'),
        cost: yup.number().typeError('Please enter a number').required('Please provide a cost for this item'),
        appointmentId: yup.string()
      })
    )
    .min(1, 'Please add an item to this invoice'),
  taxRate: yup.number().typeError('Please enter a number').required('Please provide a tax value')
});

const InvoiceGenerator = () => {
  const navigate = useNavigate();
  const initPath = useInitPathGenerator();

  const { INVOICES } = useRoutesGenerator();
  const location = useLocation() as {
    [key: string]: any;
    state: { invoice?: Partial<Invoice> & { isRecreate?: boolean } } | null;
  };
  const params = useParams<{ invoiceId?: string }>();

  const { clinicianProfile } = useGetAccountInfo();
  const { token } = useGetAccessToken();
  const { isInvoiceTemplateEnabled } = useGetFeatureToggle();

  const [invoiceId, setInvoiceId] = useState('');
  const [invoiceIdError, setInvoiceIdError] = useState('');
  const [selectedAppointmentIds, setSelectedAppointmentIds] = useState<string[]>([]);
  const [selectedClientRecordId, setSelectedClientRecordId] = useState<string | undefined>(
    location.state?.invoice?.clientRecord?._id
  );
  // const [selectedClientRecord, setSelectedClientRecord] = useState<clientRecordsInterface | undefined>(undefined);
  const [selectedGroup, setSelectedGroup] = useState<GroupsFromAPI>();
  const [selectedDueDate, setSelectedDueDate] = useState('');
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<string[]>([]);
  const [previewAndGenerateStatus, setPreviewAndGenerateStatus] = useState<'' | 'active' | 'finished'>('');
  const [saveInDraftButtonStatus, setSaveInDraftButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [isPaymentMethodsModalVisible, setIsPaymentMethodsModalVisible] = useState(false);
  const [includeMedicareDetails, setIncludeMedicareDetails] = useState(false);
  const [autofillMedicareDetails, setAutofillMedicareDetails] = useState(false);
  const [selectedEpisodeId, setSelectedEpisodeId] = useState<string | undefined>(location.state?.invoice?.episodeId);
  const dispatch = useAppDispatch();

  const { appointments, isAppointmentsLoading } = useFetchAppointments(token, selectedClientRecordId, selectedGroup);
  const {
    data: invoiceSettings,
    isLoading: isInvoiceSettingLoading,
    isFetching: isInvoiceSettingFetching
  } = useGetInvoiceSettingsQuery({ clinicianId: clinicianProfile?._id || '' }, { skip: !clinicianProfile?._id });
  const isInvoiceSettingsLoading = isInvoiceSettingLoading || isInvoiceSettingFetching;

  const { paymentMethods, isPaymentMethodsLoading, refetchPaymentMethods } = useFetchPaymentMethods();
  const { clientEncryptDetails } = useFetchClientDetails(token, selectedClientRecordId);

  const { generalPractitioner } = useFetchGeneralPractitionerById(
    token,
    clientEncryptDetails?.referral?.generalPractitionerId
  );
  const { providers: medicareProviders } = useFetchMedicareProviders(token, true);
  const { profile: appointmentClinician } = useFetchProfileById(
    (selectedAppointmentIds.length > 0 &&
      appointments.find((appointment) => appointment._id === selectedAppointmentIds[0])?.clinicianId) ||
      ''
  );

  const [isDiscounted, setIsDiscounted] = useState(false);

  const [t] = useTranslation();

  const [participationType, setParticipationType] = useState<ParticipantType>(
    selectedGroup?._id ? ParticipantType.Group : ParticipantType.OneToOne
  );

  useEffect(() => {
    if (location.pathname !== INVOICES.NEW && (!params.invoiceId || !location.state?.invoice)) {
      navigate(INVOICES.NEW);

      if (invoiceSettings) {
        setSelectedDueDate(moment().add(invoiceSettings.dueDate, 'days').format('YYYY-MM-DD'));
        setSelectedPaymentMethods(invoiceSettings.paymentMethods || []);
      }
    } else if (location.state?.invoice) {
      const { clientRecord, discount, dueDate, invoiceId, items, paymentMethods } = location.state.invoice;
      clientRecord && setSelectedClientRecordId(clientRecord._id);
      discount && setIsDiscounted(true);
      dueDate && setSelectedDueDate(dueDate);
      invoiceId && setInvoiceId(invoiceId);
      items?.length &&
        setSelectedAppointmentIds(
          items.map((item) => item.appointmentId).filter((appointmentId): appointmentId is string => !!appointmentId)
        );
      paymentMethods?.length && setSelectedPaymentMethods(paymentMethods.map((paymentMethod) => paymentMethod._id));
    } else if (invoiceSettings) {
      setSelectedDueDate(moment().add(invoiceSettings.dueDate, 'days').format('YYYY-MM-DD'));
      setSelectedPaymentMethods(invoiceSettings.paymentMethods || []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceSettings]);

  useEffect(() => {
    if ((!location.state?.invoice?.invoiceId || location.state?.invoice?.isRecreate) && clientEncryptDetails) {
      const invoiceCount = clientEncryptDetails?.invoiceSummary?.count || 0;
      const invoiceOrder = clientEncryptDetails?.invoiceSummary?.order || 0;

      const isQuickBooksConnected =
        invoiceSettings?.integration?.connections.find((connection) =>
          [AccountingPlatform.QuickBooksOnline, AccountingPlatform.QuickBooksOnlineSandbox].includes(
            connection.platform
          )
        ) && invoiceSettings?.integration.status === IntegrationStatus.Connected;

      if (!isNaN(invoiceCount)) {
        const date = moment().format('YYYYMM');
        setInvoiceId(
          `${date}-${invoiceOrder}-${invoiceCount + 1}${
            isQuickBooksConnected ? `-${Math.random().toString(36).substring(2, 7).toUpperCase()}` : ''
          }`
        );
      }
    }
  }, [clientEncryptDetails, invoiceSettings, location.state?.invoice]);

  const initialValues: InvoiceForm = useMemo(() => {
    if (
      (params.invoiceId || location.state?.invoice?.isRecreate) &&
      location.state?.invoice &&
      !location.state.invoice.template?._id
    ) {
      const { description, discount, items, taxRate } = location.state.invoice;

      return {
        ...INITIAL_VALUES,
        ...(description && { description }),
        ...(discount && { discount: { ...discount, value: discount.value || 0 } }),
        ...(items && { items: items.map((item) => ({ ...item, cost: item.cost?.toFixed(2) })) }),
        ...(!isNaN(taxRate || 0) && { taxRate })
      };
    }
    return INITIAL_VALUES;
  }, [location, params]);

  const selectClientProfilesList =
    clientEncryptDetails?.recordType === 'child'
      ? clientEncryptDetails.clientProfiles.filter((profileType) => profileType.role === 'child')
      : clientEncryptDetails?.clientProfiles;

  const selectedClientName =
    clientEncryptDetails &&
    selectClientProfilesList &&
    combineName(selectClientProfilesList, clientEncryptDetails.recordType === 'couple');

  const handleInvoiceIdChange = (newInvoiceId: string) => {
    setInvoiceId(newInvoiceId);

    if (newInvoiceId) {
      setInvoiceIdError('');
    }
  };

  const handleSelectedClientChange = (clientRecord?: clientRecordsInterface) => {
    setSelectedClientRecordId(clientRecord?._id);
    dispatch(clientRecordsApiSlice.util.invalidateTags([CPSTagTypes.EpisodeList]));

    if (includeMedicareDetails) {
      setIncludeMedicareDetails(false);
      setAutofillMedicareDetails(false);
    }
  };

  const handleAddAppointment = (appointmentId: string) => {
    setSelectedAppointmentIds([...selectedAppointmentIds, appointmentId]);
  };

  const handleRemoveAppointment = (appointmentId?: string) => {
    appointmentId &&
      setSelectedAppointmentIds(
        selectedAppointmentIds.filter((selectedAppointmentId) => selectedAppointmentId !== appointmentId)
      );
  };

  const handleAddPaymentMethod = (paymentMethodId: string) => () => {
    setSelectedPaymentMethods([...selectedPaymentMethods, paymentMethodId]);
  };

  const handleRemovePaymentMethod = (paymentMethodId: string) => () => {
    setSelectedPaymentMethods(selectedPaymentMethods.filter((paymentMethod) => paymentMethod !== paymentMethodId));
  };

  const handleChangePaymentDetailsClick = () => {
    setIsPaymentMethodsModalVisible(true);
  };

  const handlePaymentMethodsModalClose = (paymentMethods?: PaymentMethod[]) => {
    setIsPaymentMethodsModalVisible(false);

    paymentMethods && refetchPaymentMethods();
  };

  const validateNonFormValues = () => {
    if (!invoiceId) {
      setInvoiceIdError('Please enter an invoice ID');

      document.getElementById('invoice-generator-invoice-id-input')?.scrollIntoView({ block: 'center' });
    } else if (!selectedClientRecordId && !selectedGroup) {
      notification.error({
        message: t('form.error.invoice_missing_client_or_group', {
          participant: participationType === ParticipantType.Group ? t('label.group') : t('label.client')
        }),
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } else if (!selectedDueDate) {
      notification.error({
        message: 'Please select a due date before creating this invoice',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } else if (selectedPaymentMethods.length === 0) {
      notification.error({
        message: 'Please select a payment method before creating this invoice',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } else {
      return true;
    }
  };

  const generateInvoicePayload = ({
    values,
    status
  }: {
    values: InvoiceForm;
    status: InvoiceStatus;
  }): Record<string, unknown> => ({
    ...values,
    ...(participationType === ParticipantType.Group
      ? {
          group: {
            _id: selectedGroup?._id
          }
        }
      : {
          clientRecord: clientEncryptDetails
        }),
    dueDate: selectedDueDate,
    invoiceId,
    issueDate: moment().format('YYYY-MM-DD'),
    paymentMethods: paymentMethods.filter((paymentMethod) => selectedPaymentMethods.includes(paymentMethod._id)),
    status,
    items: values.items.map((item) => ({ ...item, cost: Number(item.cost) })),
    taxRate: Number(values.taxRate),
    discount: { ...values.discount, value: Number(values.discount.value) },
    episodeId: selectedEpisodeId
  });

  const handleSaveInDraft = async (values: InvoiceForm) => {
    if (validateNonFormValues()) {
      setSaveInDraftButtonStatus('active');

      const payload = generateInvoicePayload({ values, status: InvoiceStatus.Draft });

      if (!isDiscounted) {
        payload.discount = undefined;
      }

      try {
        const token = await security.getAccessTokenSilently();

        if (params.invoiceId) {
          await putInvoice(token, params.invoiceId, payload);
        } else {
          await postInvoice(token, payload);
        }

        dispatch(billingServicesApiSlice.util.invalidateTags([BSTagTypes.Invoices]));
        notification.success({
          message: 'Invoice created!',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSaveInDraftButtonStatus('finished');

        navigate(INVOICES.BASE);
      } catch (ex) {
        if (isErrorBentStatusError(ex) && ex.statusCode === 422) {
          notification.error({
            message: 'This invoice has already been issued. Please create a new one.',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } else {
          notification.error({
            message: 'Something went wrong while trying to create this invoice',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        }

        setSaveInDraftButtonStatus('');
      }
    }
  };

  const handleGenerateInvoice = async (values: InvoiceForm) => {
    if (validateNonFormValues()) {
      setPreviewAndGenerateStatus('active');

      const payload = generateInvoicePayload({ values, status: InvoiceStatus.Issued });

      if (!isDiscounted) {
        payload.discount = undefined;
      }

      const token = await security.getAccessTokenSilently();
      const callGetInvoiceIdUsed = await getInvoiceIdUsed(token, invoiceId, params.invoiceId);
      const { used } = await callGetInvoiceIdUsed.json();

      try {
        if (used) {
          notification.error({
            message: 'This invoice ID is already in use. Please provide a different invoice ID',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setInvoiceIdError('This invoice ID is already in use. Please provide a different invoice ID');
          setPreviewAndGenerateStatus('');
          return;
        } else {
          if (params.invoiceId) {
            const generateInvoiceUpdate = await (await putInvoice(token, params.invoiceId, payload)).json();
            navigate(`${initPath}/invoices/${generateInvoiceUpdate._id}`);
          } else {
            const generateInvoice = await (await postInvoice(token, payload)).json();
            navigate(`${initPath}/invoices/${generateInvoice._id}`);
          }
          dispatch(billingServicesApiSlice.util.invalidateTags([BSTagTypes.Invoices, BSTagTypes.InvoiceSummary]));
          notification.success({
            message: 'Invoice created!',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        }
      } catch (ex) {
        if (used) {
          notification.error({
            message: 'Something went wrong while trying to check if the invoice ID is in use. Please try again.',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } else if (isErrorBentStatusError(ex) && ex.statusCode === 422) {
          notification.error({
            message: 'This invoice has already been issued. Please create a new one.',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } else {
          notification.error({
            message: 'Something went wrong while trying to create this invoice',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        }

        setPreviewAndGenerateStatus('');
      }
    }
  };

  if (isInvoiceTemplateEnabled && location.state?.invoice?.template?._id) {
    return <InvoiceGeneratorWithTemplate />;
  }

  return (
    <HelmetWrapper title={'Tacklit - Invoices'}>
      <ContentLayout>
        <PaymentMethodsModal
          paymentMethods={paymentMethods}
          isPaymentMethodsLoading={isPaymentMethodsLoading}
          visible={isPaymentMethodsModalVisible}
          onClose={handlePaymentMethodsModalClose}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={InvoiceSchema}
          onSubmit={handleGenerateInvoice}
          enableReinitialize
        >
          {({ values }) => (
            <InvoiceGeneratorForm
              appointments={appointments}
              clinician={clinicianProfile}
              draftInvoice={location.state?.invoice}
              invoiceId={invoiceId}
              invoiceIdError={invoiceIdError}
              invoiceSettings={invoiceSettings}
              isInvoiceLoading={isInvoiceSettingsLoading}
              paymentMethods={paymentMethods}
              previewAndGenerateStatus={previewAndGenerateStatus}
              saveInDraftButtonStatus={saveInDraftButtonStatus}
              selectedAppointmentIds={selectedAppointmentIds}
              selectedClientRecord={clientEncryptDetails}
              selectedClientRecordId={selectedClientRecordId}
              selectedClientName={selectedClientName}
              selectedDueDate={selectedDueDate}
              selectedEpisodeId={selectedEpisodeId}
              selectedPaymentMethods={selectedPaymentMethods}
              isAppointmentsLoading={isAppointmentsLoading}
              isDiscounted={isDiscounted}
              isPaymentMethodsLoading={isPaymentMethodsLoading}
              onAddAppointment={handleAddAppointment}
              onAddPaymentMethod={handleAddPaymentMethod}
              onChangePaymentDetailsClick={handleChangePaymentDetailsClick}
              onInvoiceIdChange={handleInvoiceIdChange}
              onIsDiscountedChange={() => setIsDiscounted(!isDiscounted)}
              onRemoveAppointment={handleRemoveAppointment}
              onRemoveItem={handleRemoveAppointment}
              onRemovePaymentMethod={handleRemovePaymentMethod}
              onSaveInDraft={() => handleSaveInDraft(values)}
              onSelectClientRecord={handleSelectedClientChange}
              onSelectDueDate={setSelectedDueDate}
              setIsDiscounted={setIsDiscounted}
              selectedGroup={selectedGroup}
              onSelectGroups={(val) => setSelectedGroup(val)}
              participationType={participationType}
              onChangeParticipationType={(val) => setParticipationType(val)}
              includeMedicareDetails={includeMedicareDetails}
              autofillMedicareDetails={autofillMedicareDetails}
              clientEncryptDetails={clientEncryptDetails}
              generalPractitioner={generalPractitioner}
              medicareProviders={medicareProviders}
              appointmentClinician={appointmentClinician}
              onChangeIncludeMedicareDetails={(val) => setIncludeMedicareDetails(val)}
              onChangeAutofillMedicareDetails={(val) => setAutofillMedicareDetails(val)}
              onSelectedEpisodeId={setSelectedEpisodeId}
            />
          )}
        </Formik>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InvoiceGenerator;
