import {
  Eventcalendar,
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscEventCreateEvent,
  MbscEventCreateFailedEvent,
  MbscEventCreatedEvent,
  MbscEventDragEvent,
  MbscEventUpdateEvent,
  MbscEventUpdateFailedEvent,
  MbscEventUpdatedEvent,
  MbscEventcalendarView,
  MbscPageLoadingEvent,
  Toast,
  momentTimezone,
  Popup,
  MbscEventClickEvent
} from '@mobiscroll/react';
import { notification } from 'antd';
import classNames from 'classnames';
import { carePlanAppointments } from 'interfaces/CarePathway/CarePathway';
import { AccessRight } from 'interfaces/Clients/clinician';
import { AssignmentMode, DeliveryType } from 'interfaces/Schedule/AppointmentType';
import momentTz from 'moment-timezone';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  addEvent,
  deleteEventItem,
  selectAdditionalClinicians,
  selectAppointments,
  selectCarePlanEditorSlice,
  selectClient,
  selectLeadClinician,
  setUpdateAppointmentTypeItem,
  updateEvent
} from 'redux/carePlan/carePlanEditorSlice';
import { useGetPractitionerDetailsListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import { useGetAppointmentsByClinicianIdsQuery } from 'redux/endpoints/scheduleServices/appointment';
import {
  useCreateCarePathwayAppointmentMutation,
  useDeleteCarePathwayAppointmentMutation,
  useUpdateCarePathwayAppointmentMutation
} from 'redux/endpoints/scheduleServices/reservedAppointment';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { UserContext } from 'utils/UserContextProvider';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useWindowSize } from 'utils/useWindowSize';
import './CarePlanCalendar.module.scss';
import styles from './CarePlanCalendar.module.scss';
import CalendarEvent from './components/CalendarEvent/CalendarEvent';
import CalendarHeader from './components/CalendarHeader/CalendarHeader';
import { mapWorkingScheduleToInvalidTimeSlots } from './utils/mapWorkingScheduleToInvalidTimeSlots';

const today = momentTz.utc().startOf('hour').add(59, 'minutes');
const yesterday = momentTz.utc().subtract(1, 'days').startOf('day');

const CarePlanCalendar = () => {
  const carePlanCalendarRef = useRef<Eventcalendar>(null);
  const dispatch = useAppDispatch();
  const { accountId } = useGetAccountId();
  const { accountTimeZone } = useTimeZone();
  const { clinicianProfile } = useContext(UserContext);
  const carePlanCalendarContentRef = useRef<HTMLDivElement>(null);
  const { isEdgeAdminView, isEdgeUserView } = useGetAccountPackageView();
  const [calendarViewSize, setCalendarViewSize] = useState({
    width: `${carePlanCalendarContentRef?.current?.offsetWidth}px` || '75vw',
    height: `${carePlanCalendarContentRef?.current?.offsetHeight}px`
  });
  const [viewMode, setViewMode] = useState<'day' | 'week'>('week');
  const [calendarDate, setCalendarDate] = useState<MbscPageLoadingEvent>();
  const [invalidClinicianIds, setInvalidClinicianIds] = useState<string[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [anchor, setAnchor] = useState();

  const carePlanData = useAppSelector(selectCarePlanEditorSlice);
  const clientRecord = useAppSelector(selectClient);
  const leadClinician = useAppSelector(selectLeadClinician);
  const additionalClinicians = useAppSelector(selectAdditionalClinicians);
  const appointmentsItems = useAppSelector(selectAppointments);

  const [createCarePathwayAppointment] = useCreateCarePathwayAppointmentMutation();
  const [updateCarePathwayAppointment] = useUpdateCarePathwayAppointmentMutation();
  const [deleteCarePathwayAppointment] = useDeleteCarePathwayAppointmentMutation();

  const clinicianList = useMemo(
    () =>
      [leadClinician, ...additionalClinicians]
        .filter((staff) => staff !== undefined)
        .map((clinicianObj) => ({
          _id: clinicianObj?._id || '',
          name: clinicianObj?.name || ''
        })),
    [leadClinician, additionalClinicians]
  );

  const onPageLoading = useCallback(async (event: any) => {
    setCalendarDate(event);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const from = momentTz(calendarDate?.firstDay).format('YYYY-MM-DD');
  const to = momentTz(calendarDate?.lastDay).format('YYYY-MM-DD');

  const { data: appointmentData, refetch: refetchAppointment } = useGetAppointmentsByClinicianIdsQuery(
    {
      accountId,
      asUser: isEdgeUserView,
      params: {
        from: from,
        to: to,
        clinicianIds: clinicianList.map((cObj) => cObj._id).join(',')
      },
      timeZone: accountTimeZone
    },
    { skip: clinicianList.length <= 0 }
  );

  const { data: practitionerListData } = useGetPractitionerDetailsListQuery({
    accountId,
    params: {
      status: 'active',
      withAccessRights: [AccessRight.Admin, AccessRight.User, AccessRight.Mentor, AccessRight.Receptionist]
    }
  });

  const practitionerList = useMemo(() => {
    const selectedClinicianIds = clinicianList.map((cObj) => cObj._id);
    return (
      practitionerListData?.practitionerList.filter((practitioner) =>
        selectedClinicianIds.includes(practitioner._id)
      ) || []
    );
  }, [practitionerListData, clinicianList]);

  const view: MbscEventcalendarView = useMemo(() => {
    return {
      schedule: {
        type: viewMode,
        allDay: false
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode, leadClinician, additionalClinicians]);

  const massageTakenAppointmentSlot = useMemo(
    () =>
      appointmentData
        ?.filter((obj) => appointmentsItems.every((appointmentObj) => appointmentObj?._id !== obj._id))
        .map((appointmentObj) => ({
          _id: appointmentObj._id,
          title: appointmentObj.title || `${clinicianProfile?.practice?.name} appointment`,
          start: appointmentObj.startDateTime,
          end: momentTz(appointmentObj.endDateTime).add(appointmentObj.gap, 'minutes'),
          resource: appointmentObj.clinicianId,
          cssClass: styles.takenAppointment
        })) || [],
    [appointmentData, clinicianProfile, appointmentsItems]
  );

  const practitionerNonWorkingSchedule = useMemo(() => {
    const mapFunction = mapWorkingScheduleToInvalidTimeSlots(accountTimeZone);
    return practitionerList.flatMap(mapFunction);
  }, [practitionerList, accountTimeZone]);

  const invalidClinicians = useMemo(() => {
    return invalidClinicianIds.map((id) => ({
      resource: id,
      recurring: {
        repeat: 'daily'
      }
    }));
  }, [invalidClinicianIds]);

  const invalidTimes = useMemo(
    () => [
      {
        recurring: {
          repeat: 'daily',
          until: yesterday
        }
      },
      {
        start: yesterday,
        end: today
      },
      ...massageTakenAppointmentSlot,
      ...practitionerNonWorkingSchedule,
      ...invalidClinicians
    ],
    [massageTakenAppointmentSlot, practitionerNonWorkingSchedule, invalidClinicians]
  );

  const [myColors, setColors] = useState<MbscCalendarColor[]>([]);
  const [toastMessage, setToastMessage] = useState('');
  const [isToastOpen, setToastOpen] = useState(false);

  const checkOverlapEvent = useCallback(
    (eventItem: MbscCalendarEvent, noFilter?: boolean) => {
      const draggedEventStartTime = momentTz(eventItem.start, 'YYYY-MM-DD hh:mm');
      const draggedEventEndTime = momentTz(eventItem.end, 'YYYY-MM-DD hh:mm');

      if (noFilter) {
        return appointmentsItems
          .filter((evtObj) => (evtObj.resource || evtObj.clinicianId) === eventItem.resource)
          .some((eEventObj) => {
            const appointmentStartTime = momentTz(eEventObj.start || eEventObj.startDateTime, 'YYYY-MM-DD hh:mm');
            const appointmentEndTime = momentTz(eEventObj.end || eEventObj.endDateTime, 'YYYY-MM-DD hh:mm').add(
              eEventObj.appointmentType.gap,
              'minutes'
            );
            const validateStartTime = draggedEventStartTime.isBefore(appointmentEndTime);
            const validateEndTime = draggedEventEndTime.isAfter(appointmentStartTime);

            return validateStartTime && validateEndTime;
          });
      } else {
        return appointmentsItems
          .filter(
            (evtObj) =>
              evtObj.carePathwayItemId !== eventItem.carePathwayItemId &&
              (evtObj.resource || evtObj.clinicianId) === eventItem.resource
          )
          .some((eEventObj) => {
            const appointmentStartTime = momentTz(eEventObj.start || eEventObj.startDateTime, 'YYYY-MM-DD hh:mm');
            const appointmentEndTime = momentTz(eEventObj.end || eEventObj.endDateTime, 'YYYY-MM-DD hh:mm').add(
              eEventObj.appointmentType.gap,
              'minutes'
            );
            const validateStartTime = draggedEventStartTime.isBefore(appointmentEndTime);
            const validateEndTime = draggedEventEndTime.isAfter(appointmentStartTime);

            return validateStartTime && validateEndTime;
          });
      }
    },
    [appointmentsItems]
  );

  const onEventCreate = useCallback(
    (args: MbscEventCreateEvent) => {
      const checkOverlap = checkOverlapEvent(args.event, true);
      if (checkOverlap) {
        setToastMessage('Events must not coincide or overlap.');
        setToastOpen(true);
        return false;
      }
    },
    [checkOverlapEvent]
  );

  const onEventUpdate = useCallback(
    (args: MbscEventUpdateEvent) => {
      const checkOverlap = checkOverlapEvent(args.event);
      if (checkOverlap) {
        setToastMessage('Events must not coincide or overlap.');
        setToastOpen(true);
        return false;
      }
    },
    [checkOverlapEvent]
  );

  const onEventCreated = useCallback(
    async (args: MbscEventCreatedEvent) => {
      dispatch(
        setUpdateAppointmentTypeItem({
          ...args.event,
          isDropped: true
        })
      );
      try {
        const reservedAppointment: carePlanAppointments = {
          startDateTime: momentTz.tz(args.event.start, accountTimeZone).utc().toDate(),
          endDateTime: momentTz.tz(args.event.end, accountTimeZone).utc().toDate(),
          clinicianId: (args.event.resource as string) || '',
          carePathwayItemId: args.event._id,
          deliveryType: args.event?.appointmentType.deliveryOptions[0] || DeliveryType.FaceToFace
        };
        const result = await createCarePathwayAppointment({
          accountId,
          carePathwayId: carePlanData._id || '',
          payload: reservedAppointment
        });

        if ('data' in result) {
          const massageData = {
            ...args.event,
            clinicianId: args.event.resource,
            startDateTime: momentTz.tz(args.event.start, accountTimeZone).utc().toDate(),
            endDateTime: momentTz.tz(args.event.end, accountTimeZone).utc().toDate(),
            carePathwayItemId: args.event._id,
            _id: result.data._id,
            deliveryType: args.event?.appointmentType.deliveryOptions[0] || DeliveryType.FaceToFace
          };
          dispatch(addEvent(massageData));
          dispatch(
            setUpdateAppointmentTypeItem({
              ...args.event,
              isDropped: false
            })
          );

          setToastMessage(massageData.title + ' reserved');
          setToastOpen(true);
        } else {
          dispatch(addEvent(carePlanData.appointments));
          if ('error' in result) {
            const errorMessage: any = result.error;
            throw new Error(errorMessage?.data?.message);
          }
        }
      } catch (error: any) {
        console.error('Error adding appointment on calendar:', error.message);
        notification.error({
          message: 'Something went wrong while adding appointment on calendar.',
          duration: 2
        });
      } finally {
        dispatch(
          setUpdateAppointmentTypeItem({
            ...args.event,
            isDropped: false
          })
        );
      }
    },
    [dispatch, accountId, carePlanData._id, createCarePathwayAppointment, accountTimeZone, carePlanData.appointments]
  );

  const onEventUpdated = useCallback(
    async (args: MbscEventUpdatedEvent) => {
      try {
        const payloadAppointmentUpdate: carePlanAppointments = {
          startDateTime: momentTz.tz(args.event.start, accountTimeZone).utc().toDate(),
          endDateTime: momentTz.tz(args.event.end, accountTimeZone).utc().toDate(),
          clinicianId: (args.event.resource as string) || '',
          carePathwayItemId: args.event.carePathwayItemId,
          deliveryType: args.event?.appointmentType.deliveryOptions[0] || DeliveryType.FaceToFace
        };
        await updateCarePathwayAppointment({
          accountId,
          carePathwayId: carePlanData._id || '',
          appointmentId: args.event._id,
          payload: payloadAppointmentUpdate
        });
        const massageData = {
          ...args.event,
          clinicianId: args.event.resource,
          startDateTime: momentTz.tz(args.event.start, accountTimeZone).utc().toDate(),
          endDateTime: momentTz.tz(args.event.end, accountTimeZone).utc().toDate(),
          carePathwayItemId: args.event.carePathwayItemId
        };
        dispatch(updateEvent(massageData));
        refetchAppointment();
        setToastMessage(args.event.title + ' updated');
        setToastOpen(true);
      } catch (error: any) {
        console.error('Error updating appointment on calendar:', error.message);
        notification.error({
          message: 'Something went wrong while updating appointment on calendar.',
          duration: 2
        });
      }
    },
    [dispatch, accountId, carePlanData._id, updateCarePathwayAppointment, accountTimeZone, refetchAppointment]
  );

  const handleFailed = useCallback((event: MbscCalendarEvent) => {
    if (event.start && event.start <= today) {
      setToastMessage("Can't add event in the past");
    } else {
      setToastMessage('Unable to add an event due to the unavailability of slots.');
    }
    setToastOpen(true);
  }, []);

  const onEventCreateFailed = useCallback(
    (args: MbscEventCreateFailedEvent) => {
      handleFailed(args.event);
    },
    [handleFailed]
  );

  const onEventUpdateFailed = useCallback(
    (args: MbscEventUpdateFailedEvent) => {
      handleFailed(args.event);
    },
    [handleFailed]
  );

  const onEventDragStart = useCallback(
    ({ event }: MbscEventDragEvent) => {
      if (event?.appointmentType?.assignmentMode !== AssignmentMode.All) {
        const assignedClinicians: string[] = event.appointmentType?.assignedClinicians || [];
        setInvalidClinicianIds(
          clinicianList
            .filter((clinician) => !assignedClinicians.includes(clinician._id))
            .map((clinician) => clinician._id)
        );
      }
      dispatch(
        setUpdateAppointmentTypeItem({
          ...event,
          isProcessing: true
        })
      );
      setColors([
        {
          start: '00:00',
          end: '23:59',
          recurring: {
            repeat: 'daily'
          }
        }
      ]);
    },
    [clinicianList, dispatch]
  );

  const onEventDragEnd = useCallback(
    ({ event }: MbscEventDragEvent) => {
      setInvalidClinicianIds([]);
      // console.log('onEventDragEnd', event);
      dispatch(
        setUpdateAppointmentTypeItem({
          ...event,
          isProcessing: false
        })
      );
      setColors([]);
    },
    [dispatch]
  );

  const onToastClose = useCallback(() => {
    setToastOpen(false);
  }, []);

  // needed for calendar update event
  const mappedEvents = useMemo(() => {
    return appointmentsItems.map((event) => ({
      ...event,
      start: event.startDateTime,
      end: event.endDateTime,
      resource: event.clinicianId,
      title: event.title || event.sessionTypeName
    }));
  }, [appointmentsItems]);

  const handleRemoveEvent = useCallback(
    async (eventData: any) => {
      try {
        const result = await deleteCarePathwayAppointment({
          accountId,
          carePathwayId: carePlanData._id || '',
          appointmentId: eventData._id
        });

        if ('data' in result) {
          dispatch(deleteEventItem(eventData));
          refetchAppointment();

          setToastMessage(eventData.title + ' unscheduled');
          setToastOpen(true);
        }
      } catch (error: any) {
        console.error('Fail to remove appointment from calendar:', error.message);
        notification.error({
          message: 'Something went wrong while remove appointment on calendar.',
          duration: 2
        });
      }
    },
    [dispatch, accountId, carePlanData, deleteCarePathwayAppointment, refetchAppointment]
  );

  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const handleEventHoverIn = useCallback((args: MbscEventClickEvent) => {
    const event = args.event;
    if (event?.appointmentType?.roomSettings?.required && !event?.room?.roomId) {
      setAnchor(args.domEvent.target);
      setOpen(true);
    }
  }, []);

  const handleEventHoverOut = useCallback(() => {
    timerRef.current = setTimeout(() => {
      setOpen(false);
    }, 200);
  }, []);

  const [width, height] = useWindowSize();
  useEffect(() => {
    setCalendarViewSize({
      width: `${width - 355}px` || '75vw',
      height: `${height - 95 - 80 - 35}px` || '400px'
    });
  }, [width, height]);

  momentTimezone.moment = momentTz;

  return (
    <div ref={carePlanCalendarContentRef} className={classNames('t23-calendar', isEdgeAdminView && 'cal-admin-theme')}>
      {clientRecord && clinicianList.length > 0 && (
        <>
          <Eventcalendar
            ref={carePlanCalendarRef}
            data={mappedEvents}
            firstDay={1}
            view={view}
            groupBy="date"
            resources={clinicianList.map((clinicianObj) => ({
              ...clinicianObj,
              id: clinicianObj._id
            }))}
            invalid={invalidTimes}
            height={calendarViewSize.height}
            width={calendarViewSize.width}
            dragToMove
            dragTimeStep={5}
            min={yesterday}
            externalDrop
            externalDrag
            colors={myColors}
            // cant use utc here because the recurring is using HH:mm, when convert to utc, the startTime might later then endTime, e.g. 09:00 become 23:00
            dataTimezone={'utc'}
            displayTimezone={accountTimeZone}
            timezonePlugin={momentTimezone}
            renderScheduleEvent={(event) => (
              <CalendarEvent event={event} timezone={accountTimeZone} onRemoveEvent={handleRemoveEvent} />
            )}
            onEventCreate={onEventCreate}
            onEventCreated={onEventCreated}
            onEventUpdate={onEventUpdate}
            onEventUpdated={onEventUpdated}
            onEventCreateFailed={onEventCreateFailed}
            onEventUpdateFailed={onEventUpdateFailed}
            onEventDragStart={onEventDragStart}
            onEventDragEnd={onEventDragEnd}
            onPageLoading={onPageLoading}
            onEventHoverIn={handleEventHoverIn}
            onEventHoverOut={handleEventHoverOut}
            renderHeader={() => <CalendarHeader view={viewMode} onViewChange={setViewMode} />}
          />
          <Popup
            display="anchored"
            isOpen={isOpen}
            anchor={anchor}
            touchUi={false}
            showOverlay={false}
            contentPadding={false}
            closeOnOverlayClick={false}
          >
            <div className={styles.popupContainer}>
              <div className="md-tooltip-title">Room is required for this appointment</div>
            </div>
          </Popup>
        </>
      )}
      <Toast isOpen={isToastOpen} message={toastMessage} onClose={onToastClose} />
    </div>
  );
};

export default CarePlanCalendar;
