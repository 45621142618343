import { useState } from 'react';

// TODO: refactor all local storage usage in the code to use this hook instead
/**
 * function to use localStorage like useState
 *
 * Example:
 * const [state, setState] = useLocalStorage<TYPES>(key, initialValue)
 *
 * @param key (string) to be stored as key in the localStorage
 * @param initialValue (any) to be stored as the initialValue if the !localStorage.getItem(key), else will return the stored value
 */
const useLocalStorage = <T,>(key: string, initialValue: T): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (ex) {
      console.error(ex);
      return initialValue;
    }
  });

  const setValue = (value: T | ((prop: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (ex) {
      console.error(ex);
    }
  };
  return [storedValue, setValue];
};

export default useLocalStorage;
