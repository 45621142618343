export enum AssessmentType {
  Survey = 'survey',
  MicroCheckIn = 'microCheckIn'
}

export interface BackgroundQuestion {
  question: string;
  subQuestion?: string;
  isChecked: boolean;
}

export interface QuestionSetStruct {
  id?: string;
  defaultCategoryId?: string;
  categoryId?: string;
  sectionName: string;
  questions: QuestionStruct[];
  questionSetType?: 'commonQuestion' | 'dataCollection' | 'customTopic';
  refKey?: string;
}

export interface QuestionStruct {
  id?: string;
  isChecked: boolean;
  isDraftQ?: boolean;
  stem?: string;
}

export interface ClinicalAssessmentStruct {
  description?: string;
  title?: string;
  id: string;
  shortCode: string;
  isChecked: boolean;
}

export interface FirstAssessmentStruct {
  id?: string;
  name: string;
  diffName?: string;
  tags?: string[];
  description?: string;
  note?: string;
  isPublished: boolean;
  isDefault: boolean;
  questionSets: QuestionSetStruct[];
  backgroundQuestions: BackgroundQuestion[];
  createdAt: Date;
  updatedAt?: Date;
}
