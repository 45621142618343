import styles from './PackageAppointmentList.module.scss';
import { PackagesInterface } from 'interfaces/Packages/packages';

import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { PackageAppointmentCard } from '../../../PackageAppointmentCard/PackageAppointmentCard';
import { StrictModeDroppable } from 'components/StrictModeDroppable/StrictModeDroppable';

const reorderItem = (list: PackagesInterface['appointmentTypeIds'], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface PackageAppointmentProps {
  packageData: PackagesInterface;
  onChangePackageData: (value: PackagesInterface) => void;
  checkValidation: boolean;
}

export const PackageAppointmentList = ({
  packageData,
  onChangePackageData,
  checkValidation
}: PackageAppointmentProps) => {
  const handleUpdateByIndex = (index: number, appointmentTypeId: string) => {
    // Create a copy of the current list
    const updatedList = [...packageData.appointmentTypeIds];

    // Update the service at the specified index
    updatedList[index] = appointmentTypeId;

    // Call onChangePackageData with updated packageData
    onChangePackageData({
      ...packageData,
      appointmentTypeIds: updatedList
    });
  };

  const onRemoveServiceByIndex = (index: number) => {
    // Create a copy of the services array
    const updatedList = [...packageData.appointmentTypeIds];

    // Remove the service at the specified index
    updatedList.splice(index, 1);

    // Call onChangePackageData with the updated packageData
    onChangePackageData({
      ...packageData,
      appointmentTypeIds: updatedList
    });
  };

  const onDuplicateAppointment = (index: number) => {
    if (index < 0 || index >= packageData.appointmentTypeIds.length) {
      console.error('Invalid index');
      return;
    }
    // Get the item to duplicate from the services array
    const itemToDuplicate = packageData.appointmentTypeIds[index];

    // Append the duplicated item to the end of the services array
    const updatedServices = [...packageData.appointmentTypeIds, itemToDuplicate];

    // Update packageData with the new services array
    onChangePackageData({
      ...packageData,
      appointmentTypeIds: updatedServices
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const stageReorder = reorderItem(packageData.appointmentTypeIds, result.source.index, result.destination.index);
    onChangePackageData({
      ...packageData,
      appointmentTypeIds: stageReorder
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId={'droppable'} type={'SEQUENTIAL_STAGE'}>
        {(provided) => (
          <div className={styles.container}>
            <div className={styles.wrapper} {...provided.droppableProps} ref={provided.innerRef}>
              {packageData.appointmentTypeIds.map((appointmentListObj, index) => (
                <Draggable draggableId={`itemSection-${index}`} key={`itemSection-${index}`} index={index}>
                  {(provided) => (
                    <div
                      className={styles.card}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <PackageAppointmentCard
                        index={index}
                        appointmentData={appointmentListObj}
                        onSelectAppointmentType={(value) => handleUpdateByIndex(index, value)}
                        onRemoveAppointment={() => onRemoveServiceByIndex(index)}
                        onDuplicateAppointment={() => onDuplicateAppointment(index)}
                        showPackageFee={false}
                        showMaxLimit={false}
                        checkValidation={checkValidation}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};
