import SubTab from 'components/v2/SubTab/SubTab';
import styles from '../SubTabLayout.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { TaskStatus } from 'interfaces/Tasks/task';

const TasksSubTab = () => {
  const { TASKS } = useRoutesGenerator();
  const { search } = useLocation();
  const { status }: { status?: string } = queryString.parse(search);

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <SubTab
          title="TASKS"
          options={[
            { label: 'Active', value: 'active', href: `${TASKS.BASE}?status=${TaskStatus.Active}` },
            { label: 'Closed', value: 'closed', href: `${TASKS.BASE}?status=${TaskStatus.Closed}` }
          ]}
          activeOption={status || TaskStatus.Active}
        />
      </div>
    </div>
  );
};

export default TasksSubTab;
