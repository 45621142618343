import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import { AppointmentType } from '../../../interfaces/Schedule/AppointmentType';

export interface CarePathwayItem {
  appointmentTypeId: string;
  _id?: string;
  appointmentType?: AppointmentType;
}

export interface CarePathwayTemplatePayload {
  accountId: string;
  name: string;
  description: string;
  shortCode: string;
  status: 'draft' | 'published';
  eligibility: 'individual' | 'group';
  sequence: 'defined' | 'any';
  summary: string;
  purpose: {
    category: string;
    objective: string;
    description: string;
  };
  items: CarePathwayItem[];
  attachments: {
    id: string;
    name: string;
  }[];
}

export interface CarePathwayTemplate extends CarePathwayTemplatePayload {
  _id: string;
  createdAt: string;
  updatedAt: string;
}

type CreateCarePathwayTemplatePayload = CarePathwayTemplatePayload;
type UpdateCarePathwayTemplatePayload = CarePathwayTemplatePayload & { id: string };

export type CreateCarePathwayTemplateResponse = CarePathwayTemplate;
export type GetCarePathwayTemplateResponse = CarePathwayTemplate;
export type UpdateCarePathwayTemplateResponse = CarePathwayTemplate;

export const carePathwayTemplateApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarePathwayTemplates: builder.query<CarePathwayTemplate[], { accountId: string }>({
      query: ({ accountId }) => ({
        url: `accounts/${accountId}/templates/care-pathways`
      }),
      providesTags: [CPSTagTypes.CarePathwayTemplate]
    }),
    getCarePathwayTemplateById: builder.query<GetCarePathwayTemplateResponse, { accountId: string; id: string }>({
      query: ({ accountId, id }) => ({
        url: `accounts/${accountId}/templates/care-pathways/${id}`
      }),
      providesTags: [CPSTagTypes.CarePathwayTemplate]
    }),
    createCarePathwayTemplate: builder.mutation<CreateCarePathwayTemplateResponse, CreateCarePathwayTemplatePayload>({
      query: ({ accountId, ...payload }) => ({
        url: `accounts/${accountId}/templates/care-pathways`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [CPSTagTypes.CarePathwayTemplate]
    }),
    updateCarePathwayTemplate: builder.mutation<UpdateCarePathwayTemplateResponse, UpdateCarePathwayTemplatePayload>({
      query: ({ accountId, id, ...payload }) => ({
        url: `accounts/${accountId}/templates/care-pathways/${id}`,
        method: 'PUT',
        body: payload
      })
    })
  })
});

export const {
  useGetCarePathwayTemplatesQuery,
  useGetCarePathwayTemplateByIdQuery,
  useCreateCarePathwayTemplateMutation,
  useUpdateCarePathwayTemplateMutation
} = carePathwayTemplateApiSlice;
