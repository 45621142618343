import styles from './ClientInfo.module.scss';
import {
  clientProfilesEncrypted,
  clientRecordsEncryptedInterface,
  CommunicationPreference
} from 'interfaces/Clients/clientsRecord';
import ClientPersonalDetails from '../../components/ClientPersonalDetails/ClientPersonalDetails';
import EditClientModal from 'components/EditClientModal/EditClientModal';
import { editClientForm } from 'components/EditClientModal/EditClientModalInterface';
import AdultInfoBox from './component/AdultInfoBox/AdultInfoBox';
import ChildInfoBox from './component/ChildInfoBox/ChildInfoBox';
import CoupleInfoBox from './component/CoupleInfoBox/CoupleInfoBox';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import AssessmentSummary from './component/AssessmentSummary/AssessmentSummary';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchAllClientProfileEncryptDetails } from 'utils/hooks/GetClient/clientDetails';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import KeyClientContacts from '../../../PatientDetailsReferrers/components/KeyClientContacts/KeyClientContacts';
import classNames from 'classnames';

interface ClientInfoProps {
  clientRecordData: clientRecordsEncryptedInterface;
  onResendInvitation: () => void;
  onCancelInvitation: () => void;
  isInvitationProcessing: boolean;
  refetchClientDetail?: () => void;
  showEditModal: boolean;
  setShowEditModal: (value: boolean) => void;
  setIsOnboardingModalVisible: (value: boolean) => void;
}

const ClientInfo = ({
  clientRecordData,
  onResendInvitation,
  onCancelInvitation,
  isInvitationProcessing,
  refetchClientDetail,
  showEditModal,
  setShowEditModal,
  setIsOnboardingModalVisible
}: ClientInfoProps) => {
  const { accountTimeZone } = useTimeZone();
  const { isClientTimeZoneEnabled } = useGetFeatureToggle();

  const { token } = useGetAccessToken();
  const { clientEncryptDetails, clientEncryptDetailsLoading, fetchClientEncryptDetails } =
    useFetchAllClientProfileEncryptDetails(token, clientRecordData._id);

  const clientProfile = clientRecordData.clientProfiles[0] as clientProfilesEncrypted;

  const massageClientProfile = (clientDetails: clientRecordsEncryptedInterface['clientProfiles']) => {
    const newEditClientField = [] as editClientForm['clientProfiles'];
    for (let obj of clientDetails) {
      newEditClientField.push({
        _id: obj._id,
        firstName: obj.firstName,
        lastName: obj.lastName,
        ...(obj.email && {
          email: obj.email
        }),
        ...(obj.mobileNumber && {
          mobileNumber: obj.mobileNumber
        }),
        ...(typeof obj.isEmailThirdParty === 'boolean' && {
          isEmailThirdParty: obj.isEmailThirdParty
        }),
        ...(typeof obj.isMobileNumberThirdParty === 'boolean' && {
          isMobileNumberThirdParty: obj.isMobileNumberThirdParty
        }),
        ...(obj.dateOfBirth && {
          dateOfBirth: obj.dateOfBirth
        }),
        ...(typeof obj.isPrimaryContact === 'boolean' && {
          isPrimaryContact: obj.isPrimaryContact
        }),
        ...(obj.role && {
          role: obj.role
        }),
        onboarding: {
          isCheckOnBoarding: !!obj.onboardingSettings?.firstAssessmentIds,
          onboardingSurveyIds: obj.onboardingSettings?.firstAssessmentIds,
          onboardingMethod: obj.onboardingSettings?.onboardingMethod as 'sendToClient' | 'facilitated'
        },
        consentForm: {
          isCheckOnConsentForm: false,
          consentFormIds: []
        },
        assessmentForm: {
          isCheckOnAssessmentForm: false,
          assessmentFormIds: []
        },
        ...(obj.role !== 'child' && {
          communicationPreference: obj.communicationPreference
            ? obj.communicationPreference
            : CommunicationPreference.NoPreference
        }),
        ...(obj.profileType && {
          profileType: obj.profileType
        })
      });
    }
    return newEditClientField;
  };

  const editMassageData = {
    ...clientRecordData,
    _id: clientRecordData._id,
    clinicianAuth0Ids: clientRecordData.clinicianAuth0Ids,
    recordStatus: clientRecordData.recordStatus,
    recordType: clientRecordData.recordType,
    clientProfiles: massageClientProfile(clientRecordData.clientProfiles),
    clientCapability: {
      profileType: clientRecordData.clientProfiles[0].profileType
    },
    ...(isClientTimeZoneEnabled && { timeZone: clientRecordData.timeZone || accountTimeZone })
  } as editClientForm;

  const getInfoBox = () => {
    switch (clientRecordData.recordType) {
      case 'adult':
        return <AdultInfoBox clientRecordData={clientRecordData} />;
      case 'child':
        return <ChildInfoBox clientRecordData={clientRecordData} />;
      case 'couple':
        return <CoupleInfoBox clientRecordData={clientRecordData} />;
      case 'youngPerson':
        return <CoupleInfoBox clientRecordData={clientRecordData} />;
      default:
        return <AdultInfoBox clientRecordData={clientRecordData} />;
    }
  };

  return (
    <div className={styles.container} id={'clientInfoId'}>
      <div className={styles.infoWrapper}>
        <ClientPersonalDetails
          clientRecordData={clientRecordData}
          clientProfileData={clientProfile}
          onResendInvitation={onResendInvitation}
          onCancelInvitation={onCancelInvitation}
          isInvitationProcessing={isInvitationProcessing}
        />
        {getInfoBox()}
        <div className={styles.row}>
          <div className={classNames(styles.outstandingFormsWrapper, styles['col-4'])}>
            <AssessmentSummary accountId={clientRecordData.accountId} clientRecordId={clientRecordData._id} />
          </div>

          <div className={styles['col-8']}>
            {clientEncryptDetailsLoading ? (
              <div className={styles.loading}>
                <LoadingDot />
              </div>
            ) : (
              <KeyClientContacts
                keyClientContacts={clientEncryptDetails.keyClientContacts || []}
                clientRecordId={clientRecordData._id}
                fetchClientEncryptDetails={fetchClientEncryptDetails}
              />
            )}
          </div>
        </div>
      </div>
      <EditClientModal
        clientRecordData={editMassageData}
        visible={showEditModal}
        onCancel={() => setShowEditModal(false)}
        onRefreshData={refetchClientDetail}
        setIsOnboardingModalVisible={setIsOnboardingModalVisible}
      />
    </div>
  );
};

export default ClientInfo;
