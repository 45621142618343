import { GeneralPractitionerInterface } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/GPDetails/interface';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';

interface IUpdateGeneralPractitionerRequestPayload {
  accountId: string;
  payload: GeneralPractitionerInterface;
}

export const generalPractitionerApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get General practitioner by id
    getGeneralPractitionerById: builder.query<
      GeneralPractitionerInterface,
      { generalPractitionerId: string; accountId: string }
    >({
      query: ({ accountId, generalPractitionerId }) => ({
        url: `/accounts/${accountId}/general-practitioners/${generalPractitionerId}`
      }),
      providesTags: [CPSTagTypes.GeneralPractitioner]
    }),

    // Get General practitioner list
    getAllGeneralPractitioner: builder.query<GeneralPractitionerInterface[], { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/accounts/${accountId}/general-practitioners`
      }),
      providesTags: [CPSTagTypes.GeneralPractitionerList]
    }),

    // Update General practitioner
    updateGeneralPractitioner: builder.mutation<GeneralPractitionerInterface, IUpdateGeneralPractitionerRequestPayload>(
      {
        query: ({ accountId, payload }) => ({
          url: `/accounts/${accountId}/general-practitioners/${payload._id}`,
          method: 'PUT',
          body: payload
        }),
        invalidatesTags: [CPSTagTypes.GeneralPractitioner]
      }
    )
  })
});

export const {
  useGetGeneralPractitionerByIdQuery,
  useGetAllGeneralPractitionerQuery,
  useUpdateGeneralPractitionerMutation
} = generalPractitionerApiSlice;
