import { useMemo } from 'react';
import styles from './IntroNoteEditor2.module.scss';
import { JODITLicense } from '../v2/TextEditor2/TextEditor2';
import RichEditor from 'components/RichEditor/RichEditor';

export interface IntroNoteEditor2Props {
  value: string;
  height?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  readOnly?: boolean;
  placeholder?: string;
  onChange: (e: any) => void;
}

const DISABLE_PLUGINS = 'about,add-new-line,backspace,bold,tune-block';
const TOOLBAR_BUTTONS = 'bold,italic,underline,strikethrough,brush,ol,ul,align';
const BASIC_INFO = [{ id: 1, value: '{{CLIENT_NAME}}' }];

const IntroNoteEditor2 = ({
  value,
  height,
  minHeight,
  maxHeight,
  readOnly,
  placeholder,
  onChange
}: IntroNoteEditor2Props) => {
  const config = useMemo(
    () => ({
      license: JODITLicense,
      enter: 'div',
      height: height,
      limitChars: 500,
      autofocus: true,
      useSearch: false,
      readonly: readOnly,
      toolbarInline: true,
      allowResizeY: false,
      minHeight: minHeight,
      maxheight: maxHeight,
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      fillEmptyParagraph: true,
      placeholder: placeholder || '',
      buttons: TOOLBAR_BUTTONS,
      disablePlugins: DISABLE_PLUGINS,
      autocomplete: {
        sources: [BASIC_INFO],
        insertValueRenderer: (item: any) => `<span class="mention" contenteditable="false">${item.value}</span>`
      },
      spellcheck: true
    }),
    [placeholder, readOnly, height, minHeight, maxHeight]
  );

  return (
    <div className={styles.container}>
      <RichEditor value={value} config={config} onChange={onChange} />
    </div>
  );
};

export default IntroNoteEditor2;
