import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';

export enum SortType {
  ASC = 1,
  DESC = -1
}

export interface Sorting {
  sortBy: string;
  sortType: SortType;
}

export interface Paging {
  page: number;
  perPage: number;
}

export const PER_PAGE_DEFAULT_10 = 10;
export const PER_PAGE_DEFAULT = 20;

export interface TasksFilters {
  clients: FilterCheckListItem[];
}
