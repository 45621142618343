import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDebounce } from './useDebounce';

interface UseSearchParams {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

export const useSearch = ({ page, setPage }: UseSearchParams) => {
  const [searchActivated, setSearchActivated] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  // Reset the page number to 1 so that search result is correct
  useEffect(() => {
    if (debouncedSearchTerm || searchActivated) {
      setPage(1);
      setSearchActivated(true);
    }
  }, [debouncedSearchTerm, searchActivated, setPage]);

  return { searchTerm, setSearchTerm, debouncedSearchTerm, searching, setSearching, searchActivated };
};
