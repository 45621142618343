import { Button, Dropdown } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import OptionLabel from '../../OptionLabel/OptionLabel';
import Radio from '../../Radio/Radio';
import { ISearchByOption } from '../SearchBarT23';
import styles from './SearchByDropdown.module.scss';

interface SearchByDropdownProps {
  id: string;
  menuList: ISearchByOption[];
  selected: ISearchByOption;
  onChange: (v: string) => void;
}

const SearchByDropdown = ({ menuList, onChange, selected }: SearchByDropdownProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRender = () => (
    <div className={classNames(styles.listWrapper, isEdgeAdminView && 't23-admin-theme')}>
      <Radio
        vertical
        useT23Styles
        name="searchBy"
        className={styles.radioBtnWrapper}
        radioClassName={styles.radioBtn}
        labelClassName={styles.radioLabel}
        options={menuList.map((radioItem) => ({
          value: radioItem._id,
          label: (
            <OptionLabel
              titleClassName={classNames(selected._id === radioItem._id && styles.selectedLabel, styles.label)}
              isSelected={selected._id === radioItem._id}
              title={radioItem.name}
            />
          )
        }))}
        value={selected._id}
        onChange={(e) => {
          onChange(e.target.value);
          setIsDropdownOpen(false);
        }}
      />
    </div>
  );

  return (
    <Dropdown
      overlayClassName={styles.container}
      dropdownRender={dropdownRender}
      onOpenChange={(open) => setIsDropdownOpen(open)}
      open={isDropdownOpen}
      trigger={['click']}
    >
      <Button
        shape="round"
        className={classNames(
          styles.button,
          isEdgeAdminView
            ? classNames(styles.adminButton, isDropdownOpen && styles.adminButtonOpen)
            : classNames(styles.nonAdminButton, isDropdownOpen && styles.nonAdminButtonOpen)
        )}
      >
        {selected.name}
        <div className={styles.arrowWrapper}>
          {isDropdownOpen ? (
            <span className={classNames('material-icons-outlined', styles.expandIcon)}>expand_less</span>
          ) : (
            <span className={classNames('material-icons-outlined', styles.expandIcon)}>expand_more</span>
          )}
        </div>
      </Button>
    </Dropdown>
  );
};

export default SearchByDropdown;
