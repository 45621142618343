import ClinicianSelect, { ClinicianSelectProps } from 'components/v2/ClinicianSelect/ClinicianSelect';
import { AccessRight } from 'interfaces/Clients/clinician';

const MentorSelect = (props: Pick<ClinicianSelectProps, 'onSelect' | 'selectedId' | 'disabled'>) => (
  <ClinicianSelect
    {...props}
    filterRoles={[AccessRight.Mentor]}
    allowEmpty
    removeWarningMessage={'Are you sure you want to disconnect this mentor?'}
  />
);

export default MentorSelect;
