import { useState, useEffect, useRef } from 'react';
import styles from './T23Select.module.scss';
import classNames from 'classnames';

export interface OptionInterface {
  value: string;
  label: string;
}

export interface T23SelectProps {
  options: OptionInterface[];
  selectedOptions: string[];
  className?: string;
  onSelect: (selectedOption: string[]) => void;
}

const T23Select = ({ options, selectedOptions, className, onSelect }: T23SelectProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const controlNode = useRef<HTMLDivElement>(null);
  const optionsNode = useRef<HTMLDivElement>(null);

  const handleClickOutSide = (event: any) => {
    if (controlNode.current?.contains(event.target)) {
      setShowOptions(!showOptions);
    } else if (!optionsNode.current?.contains(event.target) && showOptions) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const onOptionSelect = (selected: string) => {
    if (selectedOptions.includes(selected)) {
      onSelect(selectedOptions.filter((item) => item !== selected));
    } else {
      onSelect([...selectedOptions, selected]);
    }
  };

  const selectedValue = options.filter((item) => selectedOptions.includes(item.value)).map((item) => item.label);

  return (
    <div className={classNames(styles.container, className)}>
      <div ref={controlNode} className={styles.controller}>
        <div className={styles.selectedValue}>{selectedValue.length > 0 ? selectedValue.join(', ') : 'Select...'}</div>
        <div className={styles.controlIcon}>
          <span className={'material-icons-outlined'}>arrow_drop_down</span>
        </div>
      </div>
      {showOptions && (
        <div className={styles.options} ref={optionsNode}>
          {options.length < 1 ? (
            <div className={styles.noOption}>No option</div>
          ) : (
            <>
              {options.map((optionItem, index) => {
                const isSelected = selectedOptions.some((item) => item === optionItem.value);
                const isDisable =
                  selectedOptions.map((item) => item.split('_')[0]).includes(optionItem.value.split('_')[0]) &&
                  !selectedOptions.includes(optionItem.value);

                return (
                  <div
                    key={`optionItem_${index}`}
                    className={classNames(
                      styles.optionItem,
                      isSelected ? styles.selected : '',
                      isDisable ? styles.disabled : ''
                    )}
                    onClick={() => !isDisable && onOptionSelect(optionItem.value)}
                  >
                    <div className={styles.itemName}>{optionItem.label}</div>
                    {isSelected && <span className={classNames(styles.checkIcon, 'material-icons')}>check_circle</span>}
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default T23Select;
