import { useEffect, useState } from 'react';

import classnames from 'classnames';
import { COMMUNICATION_OPTIONS } from 'components/AddPatientModalV2/constants';
import CheckBox from 'components/CheckBox/CheckBox';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { useTranslation } from 'react-i18next';
import { ClientDetails } from '../../../../AddPatientModalV2Interface';
import styles from './ChildGuardianForm.module.scss';
import { ChildGuardianErrorInterface } from './ChildGuardianInterface';
import { validationChildGuardianField } from './validation/ChildGuardianFormValidation';

export const ROLE_TYPE_OPTIONS = [
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
  { label: 'Step Mother', value: 'stepMother' },
  { label: 'Step Father', value: 'stepFather' },
  { label: 'Grandparent', value: 'grandparent' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Sibling', value: 'sibling' },
  { label: 'Teacher', value: 'teacher' },
  { label: 'Case Worker', value: 'caseWorker' },
  { label: 'Other', value: 'other' }
];

interface ChildGuardianFormProps {
  id: string | number;

  checkValidation: boolean;
  clientDetail: ClientDetails;
  disablePrimaryContactBtn: boolean;
  onChangeClientField: any;
  updateDetail: number;

  label?: string;
  shadowBox?: boolean;
}

/**
 * This component is used for both young person and child
 */
const ChildGuardianForm = ({
  id,
  checkValidation,
  clientDetail,
  disablePrimaryContactBtn,
  onChangeClientField,
  updateDetail,
  label,
  shadowBox
}: ChildGuardianFormProps) => {
  const [clientField, setClientField] = useState(clientDetail);
  const [errorMessage, setErrorMessage] = useState({
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    mobileNumber: ''
  } as ChildGuardianErrorInterface);

  useEffect(() => {
    if (checkValidation) {
      validatePart1Field(clientField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  useEffect(() => {
    setClientField(clientDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDetail]);

  const validatePart1Field = (clientVal: ClientDetails) => {
    const validate = validationChildGuardianField({ ...clientVal }, disablePrimaryContactBtn);
    setErrorMessage(validate as ChildGuardianErrorInterface);
    return validate;
  };

  const handleChangeFieldValue = async (key: string, value: string | boolean) => {
    const newClientField = {
      ...clientField,
      [key]: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const handleSelectRole = (value: ClientDetails['role']) => {
    const newClientField = {
      ...clientField,
      role: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validatePart1Field(newClientField);
    }
  };

  const onCommunicationPreferenceChange = (value: ClientDetails['communicationPreference']) => {
    const newClientField = {
      ...clientField,
      communicationPreference: value
    };
    setClientField(newClientField);
    onChangeClientField(newClientField);
  };

  const [t] = useTranslation();

  return (
    <div className={shadowBox ? styles.containerWithBox : styles.container}>
      {shadowBox && <div className={styles.labelContainer}>{label && <div className={styles.label}>{label}</div>}</div>}
      <div
        className={classnames(styles.fieldContainer, checkValidation && errorMessage.firstName && styles.fieldError)}
      >
        <MaterialInput
          id={`clientFirstName${id}`}
          label={'Parent/Guardian First Name*'}
          onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
          value={clientField.firstName}
          required
        />
        {checkValidation && errorMessage.firstName && <div className={styles.fieldError}>{errorMessage.firstName}</div>}
      </div>
      <div className={styles.twoQuestionWrapper}>
        <div
          className={classnames(styles.fieldContainer, checkValidation && errorMessage.lastName && styles.fieldError)}
        >
          <MaterialInput
            id={`clientLastName${id}`}
            label={'Parent/Guardian Last Name*'}
            onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
            value={clientField.lastName}
            required
          />
          {checkValidation && errorMessage.lastName && <div className={styles.fieldError}>{errorMessage.lastName}</div>}
        </div>
        <div className={styles.roleFieldContainer}>
          <MaterialSelect
            id={`relationshipType${id}`}
            label={`${t('form.relationship')}*`}
            isSearchable={false}
            options={ROLE_TYPE_OPTIONS}
            value={clientField.role as string}
            onChange={handleSelectRole}
            isError={checkValidation && !!errorMessage.role}
          />
          {checkValidation && errorMessage.role && <div className={styles.fieldError}>{errorMessage.role}</div>}
        </div>
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && errorMessage.email && styles.fieldError)}>
        <MaterialInput
          id={`clientEmail${id}`}
          label={`Parent/Guardian Email${disablePrimaryContactBtn ? '*' : ''}`}
          onChange={(e) => handleChangeFieldValue('email', e.target.value)}
          value={clientField.email}
          required
        />
        {checkValidation && errorMessage.email && <div className={styles.fieldError}>{errorMessage.email}</div>}
      </div>
      <div className={styles.phoneContainer}>
        <MaterialPhoneInput
          id={`clientMobileNumber${id}`}
          label={`Mobile Number${disablePrimaryContactBtn ? '*' : ''}`}
          onChange={(e) => handleChangeFieldValue('mobileNumber', e || '')}
          value={clientField.mobileNumber || ''}
          autoFormat={false}
          isError={checkValidation && !!errorMessage.mobileNumber}
        />
        {checkValidation && errorMessage.mobileNumber && (
          <div className={styles.fieldError}>{errorMessage.mobileNumber}</div>
        )}
      </div>
      <div className={styles.primaryFieldContainer}>
        {/*  Note for this checkbox: will only be disabled for the first guardian if the main client is young person (recordOnly) or child */}
        <CheckBox
          id={`isPrimaryGuardian${id}`}
          disabled={disablePrimaryContactBtn}
          value={clientDetail.isPrimaryContact || false}
          label={'Contact will receive messages, documents and appointment details for this profile'}
          onChange={(e) => handleChangeFieldValue('isPrimaryContact', e.target.checked)}
        />
      </div>
      <div className={classnames(styles.preferenceContainer, styles.fieldContainer)}>
        <div className={styles.inputWithTooltip}>
          <div className={styles.label}>Communication Preference</div>
          <HelpOutLineWithTooltips
            id={'communicationPreference'}
            desc={'Notifications on Invoice and Report & Letter will only be available via Email.'}
            iconClass={styles.helpIcon}
          />
        </div>
        <MaterialSelect
          id={'clientCommunicationPreference'}
          label={''}
          isSearchable={false}
          options={COMMUNICATION_OPTIONS}
          value={clientDetail.communicationPreference || CommunicationPreference.NoPreference}
          onChange={onCommunicationPreferenceChange}
        />
        {clientDetail.communicationPreference === 'none' && (
          <div className={styles.noneComms}>{t('form.error.client_no_digital_message')}</div>
        )}
      </div>
    </div>
  );
};

export default ChildGuardianForm;
