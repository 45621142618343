import { notification } from 'antd';
import { PartialRefundResponse, usePartialRefundMutation } from 'redux/endpoints/billingServices/invoices';

export const usePaymentFullRefund = () => {
  const [partialRefund] = usePartialRefundMutation();

  const onFullRefund = async ({ accountId, paymentRequestId }: { accountId: string; paymentRequestId: string }) => {
    try {
      notification.warning({
        message: 'Fully refund processing...'
      });

      const result: PartialRefundResponse = await partialRefund({
        accountId,
        paymentRequestId
      }).unwrap();
      notification.destroy();

      if (!result.error) {
        notification.success({
          message: 'Successfully fully refunded'
        });
      } else {
        notification.error({ message: 'Something went wrong while trying to process a full refund.' });
      }
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to process a full refund.' });
    }
  };

  return { onFullRefund };
};
