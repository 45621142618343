import { ClinicalAssessmentResponseList } from 'interfaces/checkInService/clinicalAssessment';
import { Assessment } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsAssessments/PatientDetailsAssessments';
import { CISTagTypes, checkInServiceApiSlice } from 'redux/services/checkInServiceApiSlice';
import queryString from 'query-string';

interface GetClinicalAssessmentParams {
  episodeId?: string;
  showOutsideOfEpisode?: boolean;
}

export const clinicalAssessmentApiSlice = checkInServiceApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClinicalAssessmentResponses: builder.query<
      ClinicalAssessmentResponseList[],
      { recordId: string; params?: GetClinicalAssessmentParams }
    >({
      query: ({ recordId, params }) => {
        const stringifyQuery = params ? queryString.stringify(params) : '';
        return {
          url: `/client-records/${recordId}/clinical-assessment-responses?${stringifyQuery}`
        };
      },
      providesTags: [CISTagTypes.ClinicalAssessmentResponses]
    }),

    getAssessmentById: builder.query<Assessment, { clientRecordId: string; assessmentId: string }>({
      query: ({ clientRecordId, assessmentId }) => ({
        url: `/clinical-assessment-responses/${assessmentId}?clientRecordId=${clientRecordId}`
      }),
      providesTags: [CISTagTypes.GetAssessmentById]
    }),

    getClinicalAssessmentDetailsResponse: builder.query<Assessment[], { clientRecordId: string }>({
      query: ({ clientRecordId }) => {
        return {
          url: `/client-records/${clientRecordId}/clinical-assessment-detailed-responses`
        };
      },
      providesTags: (_result, _error, arg) => [
        { type: CISTagTypes.ClinicalAssessmentDetailsResponses, id: arg.clientRecordId }
      ]
    })
  })
});

export const {
  useGetClinicalAssessmentResponsesQuery,
  useGetAssessmentByIdQuery,
  useGetClinicalAssessmentDetailsResponseQuery
} = clinicalAssessmentApiSlice;
