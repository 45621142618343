import { Skeleton } from 'antd';
import DragAndDropSection from 'pages/Report/ReportDetails/components/ReportBuilder/components/ReportContent/components/DragAndDropSection/DragAndDropSection';
import { ReportTemplate } from 'interfaces/Reports/reportTemplate';
import styles from './TemplateContent.module.scss';
import { ReportDimension } from 'interfaces/Reports/report';

interface TemplateContentProps {
  templateData: ReportTemplate;
  isLoading: boolean;
  validation: boolean;
  onChangeTemplateDimensions: (dimension: ReportDimension) => void;
  onChangeTemplateItems: (items: ReportTemplate['items']) => void;
}

const TemplateContent = ({
  templateData,
  isLoading,
  validation,
  onChangeTemplateDimensions,
  onChangeTemplateItems
}: TemplateContentProps) => {
  return (
    <div className={styles.container}>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <DragAndDropSection
            dndDimension={templateData.template.dimensions}
            items={templateData.items}
            onChangeDndContainerSize={onChangeTemplateDimensions}
            onChangeItems={onChangeTemplateItems}
            gridPositioning
            autoExpandContainerHeight={90}
            templateBuilder
          />
          {validation && <div className={styles.error}>Content cannot be empty</div>}
        </>
      )}
    </div>
  );
};

export default TemplateContent;
