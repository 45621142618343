import { DraggableRubric } from 'react-beautiful-dnd';
import { CLASSIFICATION_OPTIONS, OpenDataCategory } from '../../../../CollectData.interface';
import styles from './DataCategoryVirtual.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface DataCategoryVirtualProps {
  rubric: DraggableRubric;
  openDataCategories: OpenDataCategory[];
}

const DataCategoryVirtual = ({ rubric, openDataCategories }: DataCategoryVirtualProps) => {
  const { isHealthDataEnabled } = useGetFeatureToggle();
  const category = openDataCategories[rubric.source.index];
  return (
    <div className={styles.virtualContainer}>
      <div className={styles.headerPreviewWrapper}>
        <div className={styles.title}>
          <div className={styles.label}>Category Name</div>
          <div>{category.name}</div>
        </div>
        {category.classification && isHealthDataEnabled && (
          <div className={styles.classificationBadge}>
            {
              CLASSIFICATION_OPTIONS.find((classificationObj) => classificationObj.value === category.classification)
                ?.label
            }
          </div>
        )}
      </div>
      <ButtonAlt useDivAsBtn variant={'text'} size={'medium'} icon={'import_export'} fab />
    </div>
  );
};

export default DataCategoryVirtual;
