import classnames from 'classnames';
import moment from 'moment';

import FlexBox from 'components/FlexBox/FlexBox';
import IconButton from 'components/IconButton/IconButton';
import { Appointment } from 'pages/InvoiceGenerator/interface';
import { filterInvoiceAppointmentTags } from 'utils/filterInvoiceAppointmentTags';

import AppointmentTags from '../AppointmentTags/AppointmentTags';
import styles from './AppointmentItem.module.scss';
import { getDeliveryTypeLabel } from 'utils/appointment';

interface AppointmentItemProps {
  appointment: Appointment;
  included?: boolean;
  onAddAppointment: (appointment: Appointment) => void;
  onRemoveAppointment: (appointment: Appointment) => void;
  isAddAppointmentItemDisabled: boolean;
}

const AppointmentItem = ({
  appointment,
  included,
  onAddAppointment,
  onRemoveAppointment,
  isAddAppointmentItemDisabled
}: AppointmentItemProps) => {
  const { date, deliveryType, otherInstructions, startTime, endTime, markedStatus = [], sessionTypeName } = appointment;

  const dateText = moment(date, 'YYYY-MM-DD').format('dddd D MMMM');
  const deliveryTypeText = getDeliveryTypeLabel(deliveryType, otherInstructions);
  const duration = moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'minute');
  const startTimeText = moment(startTime, 'HH:mm').format('h:mmA');
  const tags = filterInvoiceAppointmentTags(markedStatus);

  const handleAddOrRemoveAppointment = () => {
    if (included) {
      onRemoveAppointment(appointment);
    } else {
      onAddAppointment(appointment);
    }
  };

  return (
    <FlexBox
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className={classnames(styles.container, included && styles.included)}
    >
      <FlexBox direction="column" className={styles.appointmentInfo}>
        <div>
          {duration} minute, {sessionTypeName}, {deliveryTypeText} booked for
          <br />
          <strong>
            {startTimeText} on {dateText}
          </strong>
        </div>
        {tags.length > 0 && <AppointmentTags tags={tags} />}
      </FlexBox>
      <IconButton
        className={included ? styles.removeIconButton : undefined}
        iconName={included ? 'remove_circle' : 'add_circle'}
        onClick={handleAddOrRemoveAppointment}
        isDisabled={isAddAppointmentItemDisabled && !included}
      />
    </FlexBox>
  );
};

export default AppointmentItem;
