import { useState } from 'react';
import styles from './ReferrerOrganisationForm.module.scss';

import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import CheckBox from 'components/CheckBox/CheckBox';
import { ReferralOrganisation } from 'interfaces/Referral/Referral';
import ReferrerIndividualForm from '../ReferrerIndividualForm/ReferrerIndividualForm';
import ReferrerSearchField from '../ReferrerSearchField/ReferrerSearchField';
import classNames from 'classnames';
import { ORGANISATION_TYPE_OPTIONS } from 'pages/Referrals/utils';
import {
  ReferralFieldsPayload,
  ReferralValidateField
} from 'pages/Referrals/components/AddReferralModal/AddReferralModalInterfaces';
import { ReferrerType } from 'pages/Referrals/interface';
import { ReferralFormMode } from '../ReferrerForm/ReferrerForm';
// import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

export type OrganisationReferrerPayload = ReferralOrganisation;

interface ReferrerOrganisationFormProps {
  organisationFields: ReferralOrganisation;
  errorMessage: ReferralValidateField['org'];
  individualFields: ReferralFieldsPayload['referrer'];
  individualErrorMessage: ReferralValidateField['referrer'];
  mode: ReferralFormMode;
  onChange: (key: string, val: string, keyType?: string) => void;
  isIndividual: boolean;
  setIsIndividual: (val: boolean) => void;
}

const ReferrerOrganisationForm = ({
  organisationFields,
  errorMessage,
  individualFields,
  individualErrorMessage,
  mode,
  onChange,
  isIndividual,
  setIsIndividual
}: ReferrerOrganisationFormProps) => {
  // const { isAddressBookFeatureToggle } = useGetFeatureToggle(); // REFERRALTODO: enable address book integration
  const isAddressBookFeatureToggle = false;

  const [isCreateOrganisation, setIsCreateOrganisation] = useState<boolean>(
    mode === ReferralFormMode.Edit
      ? !!organisationFields.name || !isAddressBookFeatureToggle
      : !isAddressBookFeatureToggle
  );

  const handleChangeOrganisationField = async (key: string, val: string) => {
    onChange(key, val, ReferrerType.Organisation);
  };

  const fetchContacts = async (search: string) => {
    return [];
  };

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        {!isCreateOrganisation && (
          <ReferrerSearchField
            fetchData={fetchContacts}
            label="Search by organisation name"
            buttonLabel="Create new org"
            icon="maps_home_work"
            onClickAdd={(search) => {
              handleChangeOrganisationField('name', search);
              setIsCreateOrganisation(true);
            }}
          />
        )}

        {isCreateOrganisation && (
          <>
            <div className={styles.rowContainer}>
              <div className={styles.fieldContainer}>
                <MaterialInput
                  id="organisation-name"
                  label="Organisation name"
                  maxLength={100}
                  onChange={(e) => handleChangeOrganisationField('name', e.target.value)}
                  value={organisationFields.name}
                  error={!!errorMessage.name}
                  errorMessage={errorMessage.name}
                  required
                />
              </div>
            </div>

            <div className={styles.rowContainer}>
              <div className={styles.fieldContainer}>
                <MaterialSelect
                  id="organisation-type"
                  label="Type"
                  isSearchable={false}
                  options={ORGANISATION_TYPE_OPTIONS}
                  value={organisationFields.type}
                  onChange={(value) => handleChangeOrganisationField('type', value)}
                  errorMessage={errorMessage.type}
                />
              </div>
              <div className={classNames(styles.fieldContainer, styles.fieldOffset)}>
                <MaterialInput
                  id="organisation-billerCode"
                  label="Biller code"
                  maxLength={20}
                  minLength={5}
                  onChange={(e) => handleChangeOrganisationField('billerCode', e.target.value)}
                  value={organisationFields.billerCode}
                />
              </div>
            </div>

            <div className={styles.rowContainer}>
              <div className={styles.fieldContainer}>
                <MaterialInput
                  id="organisation-email"
                  label="Organisation main email"
                  maxLength={200}
                  onChange={(e) => handleChangeOrganisationField('email', e.target.value)}
                  value={organisationFields.email}
                  icon="email"
                />
              </div>
            </div>

            <div className={styles.rowContainer}>
              <div className={styles.fieldContainer}>
                <MaterialPhoneInput
                  id="organisation-mobile"
                  autoFormat={false}
                  disableCountryCode
                  hideFlag
                  inputName="mobile"
                  label="Organisation contact phone number"
                  onChange={(value = '') => {
                    handleChangeOrganisationField('phone', value);
                  }}
                  placeholder=""
                  value={organisationFields.phone || ''}
                  isError={!!errorMessage.phone}
                  errorMessage={errorMessage.phone}
                  inputClass={styles.transparentInput}
                  labelClass={errorMessage.phone ? styles.errorLabel : undefined}
                  icon="phone"
                />
              </div>
            </div>

            <div className={styles.rowContainer}>
              <div className={styles.fieldContainer}>
                <MaterialPhoneInput
                  id="organisation-faxNumber"
                  autoFormat={false}
                  disableCountryCode
                  hideFlag
                  inputName="fax"
                  label="Organisation contact fax number"
                  onChange={(value = '') => {
                    handleChangeOrganisationField('fax', value);
                  }}
                  placeholder=""
                  isError={false}
                  value={organisationFields.fax || ''}
                  inputClass={styles.transparentInput}
                  icon="fax"
                />
              </div>
            </div>
          </>
        )}
      </div>

      {isCreateOrganisation && (
        <div className={styles.column}>
          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <CheckBox
                id="organisation-isIndividual"
                label="Add specific individual referrer"
                value={isIndividual}
                onChange={(e) => setIsIndividual(e.target.checked)}
                useT23style
              />
            </div>
          </div>

          {isIndividual && (
            <ReferrerIndividualForm
              referrerFields={individualFields}
              errorMessage={individualErrorMessage}
              onChange={onChange}
              mode={mode}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ReferrerOrganisationForm;
