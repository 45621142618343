import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import WriteOffList from './components/WriteOffList/WriteOffList';

const InvoiceWriteOff = () => {
  return (
    <HelmetWrapper title="Tacklit - Write off invoices">
      <ContentLayout>
        <WriteOffList />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InvoiceWriteOff;
