import styles from './OptionLabel.module.scss';
import classnames from 'classnames';

interface OptionLabelProps {
  isSelected?: boolean;
  title: string;
  desc?: string;
  highlightTitle?: boolean;
  disabledTitle?: boolean;
  containerClassName?: string;
  titleClassName?: string;
  descClassName?: string;
}

const OptionLabel = ({
  isSelected,
  title,
  desc,
  highlightTitle,
  disabledTitle,
  containerClassName,
  titleClassName,
  descClassName
}: OptionLabelProps) => {
  const titleStyle = disabledTitle
    ? styles.disabled
    : highlightTitle
    ? isSelected
      ? styles.titleSelected
      : styles.title
    : styles.title;
  return (
    <div className={classnames(styles.container, containerClassName)}>
      <div className={classnames(titleClassName, titleStyle)}>{title}</div>
      {desc && <div className={classnames(descClassName, styles.desc, disabledTitle && styles.disabled)}>{desc}</div>}
    </div>
  );
};

export default OptionLabel;
