import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './CarePathwayAppointment.module.scss';
import { CarePathwayAppointmentItem } from './CarePathwayAppointmentItem';
import { CarePathwayTemplateSchemaType } from '../../pages/CarePathwayTemplate/CarePathwayTemplateSchema';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useCallback } from 'react';
import { useCarePathwayTemplateFormContext } from 'pages/CarePathwayTemplate/context';
import { StrictModeDroppable } from 'components/StrictModeDroppable/StrictModeDroppable';

export const CarePathwayAppointment = () => {
  const { values, setValues } = useCarePathwayTemplateFormContext();

  const handleAdd = useCallback(() => {
    const appointment = {
      appointmentTypeId: ''
    };
    const newAppointments = [...values.appointments];
    newAppointments[newAppointments.length] = appointment;
    setValues({
      appointments: newAppointments
    });
  }, [setValues, values.appointments]);

  const reorder = (list: CarePathwayTemplateSchemaType['appointments'], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <DragDropContext
      onDragEnd={(result, provided) => {
        const { destination, source } = result;
        if (!destination) return;

        setValues({
          appointments: reorder(values.appointments, source.index, destination.index)
        });
      }}
    >
      <div>
        <StrictModeDroppable droppableId="droppable">
          {(provided) => (
            <div id="droppable" className={styles.list} ref={provided.innerRef} {...provided.droppableProps}>
              {values.appointments.map((appointment, index) => (
                <Draggable key={index} index={index} draggableId={`${index}`}>
                  {(provided) => (
                    <>
                      <CarePathwayAppointmentItem
                        index={index}
                        key={index}
                        appointment={appointment}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      />
                    </>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
        <br />
        <ButtonAlt variant="outlined" icon="add_circle_outline" onClick={handleAdd}>
          Item
        </ButtonAlt>
        <br />
        <p className={styles.description}>
          {values.appointments.length} item(s) has added to {!values.name.length ? 'this template' : values.name}
        </p>
      </div>
    </DragDropContext>
  );
};
