import { Skeleton } from 'antd';
import classnames from 'classnames';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import SelectClientModal from 'components/SelectClient/SelectClientList/components/SelectClientModal/SelectClientModal';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getName } from 'utils/general';
import styles from './ClientSelect.module.scss';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

interface ClientSelectProps {
  onSelect: (clientRecords: clientRecordsInterface, b?: boolean) => void;
  selectedClientRecords: clientRecordsInterface;
  confirmChangeMessage?: string;
}

const ClientSelect: React.FC<ClientSelectProps> = ({ onSelect, selectedClientRecords, confirmChangeMessage }) => {
  const [showClientModal, setShowClientModal] = useState(false);
  const [selectedModalClient, setSelectedModalClient] = useState<clientRecordsInterface>();
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [t] = useTranslation();

  const handleSelectUser = (clientRecord: clientRecordsInterface) => {
    setSelectedModalClient(clientRecord);
    if (clientRecord._id !== selectedClientRecords._id && confirmChangeMessage) {
      setShowConfirmationBox(true);
    } else {
      onSelect(clientRecord);
    }
    setShowClientModal(false);
  };

  const handleConfirmChangeClient = () => {
    setShowConfirmationBox(false);
    if (selectedModalClient) {
      onSelect(selectedModalClient);
    }
  };

  const handleCancelChangeClient = () => {
    setShowConfirmationBox(false);
  };

  return !selectedClientRecords?.clientProfiles ? (
    <div className={classnames(styles.loadingBox)}>
      <Skeleton.Input active className={styles.skeletonLoading} />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.selectedBox} onClick={() => setShowClientModal(!showClientModal)}>
        <ClientAvatar
          name={selectedClientRecords?.clientProfiles[0] ? getName(selectedClientRecords?.clientProfiles[0]) : ''}
          clientData={selectedClientRecords?.clientProfiles}
          initialsName={selectedClientRecords?.clientProfiles[0]?.initials || ''}
          avatarUrl={selectedClientRecords?.clientProfiles[0]?.avatar}
          avatarSize={36}
          initialsClassName={styles.initialName}
          nameClassName={styles.clientName}
          horizontal
          displayFirstNameOnly={selectedClientRecords?.recordType === 'couple'}
          displayLimit={selectedClientRecords?.recordType === 'couple' ? 2 : 1}
        />
        <span className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</span>
      </div>
      <SelectClientModal
        title="Attached To"
        visible={showClientModal}
        onCloseModal={() => setShowClientModal(false)}
        onSelectClientRecord={handleSelectUser}
        selectedClientId={selectedClientRecords._id || ''}
        participationType={ParticipantType.OneToOne}
        disableGroupSelection
        hideAddNewClientButton
      />
      <ConfirmationBox
        visible={showConfirmationBox}
        title={confirmChangeMessage || t('form.confirm.change_client')}
        confirmBtnLabel={'Confirm'}
        cancelBtnLabel={'Cancel'}
        onSubmitConfirm={handleConfirmChangeClient}
        onSubmitCancel={handleCancelChangeClient}
      />
    </div>
  );
};

export default ClientSelect;
