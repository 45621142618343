/* eslint-disable complexity */
import { useState, useMemo } from 'react';
import { notification } from 'antd';
import { config } from 'config/config';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { postPracticeClinician, trackNewSignup } from 'utils/http/clinician';
import { resendVerificationEmail } from 'utils/http/verification';
import { useGetAccountDetailsFromToken } from './hooks/useGetAccountDetailsFromToken';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import SignUpProgressBar from '../components/SignUpProgressBar/SignUpProgressBar';
import AboutYou from '../components/AboutYou/AboutYou';
import Security from '../components/Security/Security';
import GetStarted from '../components/GetStarted/GetStarted';

import styles from './PracticeSignUp.module.scss';
import './PracticeSignUp.scss';
import PracticeData from '../components/PracticeData/PracticeData';
import moment from 'moment';

const FORM_STAGES = ['Create account', 'Security', 'Data', 'Get started'];

const initialValues = {
  title: '',
  'title-others': '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  mobileNumber: '',
  formattedMobileNumber: '',
  '2fa': ['', '', '', '', '', ''],
  isEmailCopyRequired: true,
  resendEmail: ''
};

const AboutYouSchema = Yup.object().shape({
  title: Yup.string().required('Please select a title'),
  'title-others': Yup.string().when('title', {
    is: (title: string) => title === 'other',
    then: Yup.string().required('Please tell us how we should address you'),
    otherwise: Yup.string()
  }),
  firstName: Yup.string().required('Please enter your first name'),
  lastName: Yup.string().required('Please enter your last name'),
  email: Yup.string().email('Please provide a valid email').required('Please enter your email')
});

const { mobileNumberMinLength, countryCode } = config;

const SecuritySchema = Yup.object().shape({
  password: Yup.string().required('Please choose a password').min(8, 'Password must be at least 8 characters'),
  mobileNumber: Yup.string()
    .required('Please enter your mobile number for two-factor authentication')
    .min(mobileNumberMinLength, `Mobile number has to be at least ${mobileNumberMinLength} characters`),
  '2fa': Yup.array()
    .of(Yup.string())
    .test({
      name: '2fa',
      message: 'Please enter the 6-digit code sent to your mobile number',
      test: (value) => (value as string[]).filter((value) => !!value).length === 6
    })
});

const useSignUpStage = () => {
  const [stage, setStage] = useState(0);

  const nextStage = () => {
    if (stage < 3) {
      setStage(stage + 1);
    }
  };

  return { stage, nextStage };
};

const PracticeSignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [leadId, setLeadId] = useState('' as any);
  const [hideProgressBar, setHideProgressBar] = useState(false);

  const { practiceSlugUrl = '' } = useParams<{ practiceSlugUrl: string }>();
  const { accountDetails, expiredTime } = useGetAccountDetailsFromToken();
  const { accountId, email: defaultEmail, token } = accountDetails;
  const { stage, nextStage } = useSignUpStage();

  const validationSchema = useMemo(() => {
    switch (stage) {
      case 0:
        return AboutYouSchema;
      case 1:
        return SecuritySchema;
      case 2:
      case 4:
      default:
        return;
    }
  }, [stage]);

  const renderForm = (stage: number) => {
    switch (stage) {
      case 0:
        return <AboutYou disableEmail />;
      case 1:
        return <Security />;
      case 2:
        return <PracticeData />;
      case 3:
        return <GetStarted setHideProgressBar={setHideProgressBar} />;
      default:
        return;
    }
  };

  const handleSubmit = async (values: typeof initialValues, formikBag: FormikHelpers<typeof initialValues>) => {
    formikBag.setSubmitting(false);

    if (stage === 0) {
      setIsLoading(true);

      const { email, firstName, lastName } = values;

      try {
        const title = values.title === 'other' ? values['title-others'] : values.title;

        try {
          const res = await trackNewSignup({
            name: `${title} ${firstName} ${lastName}`,
            email,
            practiceUrl: practiceSlugUrl || '',
            geo: countryCode,
            signUpCompleted: 'no',
            step: 1
          });

          const id = res ? await res.text() : '';
          setLeadId(id);
        } catch (innerError) {
          // Log the error but do not take further action
          console.error('Error tracking on Step 1:', innerError);
        }
      } catch (ex) {
        console.error(ex);
      }

      nextStage();
      setIsLoading(false);
      return;
    }

    if (stage === 1) {
      try {
        await trackNewSignup({
          phone_number: values.mobileNumber,
          lead_id: leadId,
          step: 2
        });
      } catch (innerError) {
        // Log the error but do not take further action
        console.error('Error tracking on Step 2:', innerError);
      }
      nextStage();
    }

    if (stage === 2) {
      setIsLoading(true);

      try {
        const title = values.title === 'other' ? values['title-others'] : values.title;
        await postPracticeClinician(token, accountId, {
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.mobileNumber,
          title,
          isEmailCopyRequired: values.isEmailCopyRequired
        }).catch(async (ex) => {
          console.error(ex);

          if (ex?.statusCode === 409) {
            const errorObject = await ex.json().catch(() => undefined);

            if (errorObject?.emailUsed) {
              throw new Error(
                'It seems that an account has already been created using this email. Please proceed to log-in or contact our administrators if you believe an account has been mistakenly created using your email.'
              );
            }
          }

          throw new Error('Something went wrong while creating your account. Please try again.');
        });

        try {
          await trackNewSignup({
            lead_id: leadId,
            signUpCompleted: 'yes',
            step: 4
          });
        } catch (innerError) {
          // Log the error but do not take further action
          console.error('Error tracking on Step 4:', innerError);
        }

        formikBag.setFieldValue('resendEmail', values.email);

        nextStage();
      } catch (error) {
        notification.error({
          message:
            error instanceof Error
              ? error.message
              : 'Something went wrong while creating your account. Please try again.'
        });
      } finally {
        setIsLoading(false);
      }
    }

    if (stage === 3) {
      try {
        await resendVerificationEmail({
          email: defaultEmail,
          clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''
        });

        notification.success({ message: 'Confirmation email resent!' });
      } catch (e) {
        notification.error({ message: e instanceof Error ? e.message : 'Confirmation email resend failed.' });
      }
    }
  };

  const idExpired = () => {
    return moment(moment.unix(expiredTime)).isBefore(moment());
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      )}
      <div className={styles.container}>
        <fieldset disabled={idExpired()}>
          <Formik
            initialValues={{ ...initialValues, email: defaultEmail || initialValues.email }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {() => (
              <Form>
                {!hideProgressBar && <SignUpProgressBar stage={stage} stages={FORM_STAGES} />}
                <ContentLayout className={styles.signUpContainer}>
                  <div className={styles.content}>{renderForm(stage)}</div>
                </ContentLayout>
              </Form>
            )}
          </Formik>
        </fieldset>
      </div>
      {idExpired() && (
        <div className={styles.expiredAlert}>
          <div className={styles.expiredContainer}>
            <div className={styles.expiredTitle}>Invitation has expired</div>
            <div className={styles.expiredMsg}>
              The link to your signup invitation has expired. <br />
              Please contact your practice to resend the invitation. <br />
              If you need any other assistance please do not hesitate to contact us at &nbsp;
              <a href="mailto:support@tacklit.com">
                <span className={styles.supportEmail}>support@tacklit.com</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PracticeSignUp;
