import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { PER_PAGE_DEFAULT, Paging, SortType } from '../utils';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { SearchByEnum } from 'pages/InvoicePayments/components/InvoicePaymentsFilter/utils';
import { InvoicePayment } from 'pages/InvoicePayments/constants';

export enum InvoicePaymentsTab {
  Collected = 'collected',
  Scheduled = 'scheduled',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Refunded = 'refunded'
}

export interface InvoicePaymentsSorting {
  sortBy: string | undefined;
  sortType: SortType | undefined;
}

export interface DateFilter {
  from: string | null;
  to: string | null;
}

export interface InvoicePaymentsSearch {
  searchValue: string;
  searchBy: SearchByEnum;
}

export interface InvoicePaymentsFilters {
  createdAt: DateFilter;
  clients: FilterCheckListItem[];
  appointmentTypes: FilterCheckListItem[];
}

export interface InvoicePaymentsSliceState {
  activeTab: InvoicePaymentsTab;
  search: InvoicePaymentsSearch;
  filters: InvoicePaymentsFilters;
  sorting: InvoicePaymentsSorting;
  collectedPaging: Paging;
  scheduledPaging: Paging;
  refundedPaging: Paging;
  failedPaging: Paging;
  cancelledPaging: Paging;
  isShowPartialRefundModal: boolean;
  selectedPayment: InvoicePayment | undefined;
  partialRefundPayment: { amount: string };
}

const initialState: InvoicePaymentsSliceState = {
  activeTab: InvoicePaymentsTab.Collected,
  search: {
    searchValue: '',
    searchBy: SearchByEnum.ChargeId
  },
  filters: {
    clients: [],
    appointmentTypes: [],
    createdAt: { from: null, to: null }
  },
  sorting: {
    sortBy: 'received',
    sortType: SortType.DESC
  },
  collectedPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT
  },
  scheduledPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT
  },
  refundedPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT
  },
  failedPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT
  },
  cancelledPaging: {
    page: 1,
    perPage: PER_PAGE_DEFAULT
  },
  isShowPartialRefundModal: false,
  selectedPayment: undefined,
  partialRefundPayment: { amount: '' }
};

export const invoicePaymentsSlice = createSlice({
  name: 'invoicePaymentsSlice',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    setPaging: (state, action) => {
      switch (state.activeTab) {
        case InvoicePaymentsTab.Collected: {
          state.collectedPaging = action.payload;
          break;
        }
        case InvoicePaymentsTab.Scheduled: {
          state.scheduledPaging = action.payload;
          break;
        }
        case InvoicePaymentsTab.Failed: {
          state.failedPaging = action.payload;
          break;
        }
        case InvoicePaymentsTab.Cancelled: {
          state.cancelledPaging = action.payload;
          break;
        }
        case InvoicePaymentsTab.Refunded: {
          state.refundedPaging = action.payload;
          break;
        }
      }
    },

    setCollectedPaging: (state, action) => {
      state.collectedPaging = action.payload;
    },

    setScheduledPaging: (state, action) => {
      state.scheduledPaging = action.payload;
    },

    setRefundedPaging: (state, action) => {
      state.refundedPaging = action.payload;
    },

    setFailedPaging: (state, action) => {
      state.failedPaging = action.payload;
    },

    setCancelledPaging: (state, action) => {
      state.cancelledPaging = action.payload;
    },

    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },

    resetPaging: (state) => {
      state.collectedPaging = initialState.collectedPaging;
      state.scheduledPaging = initialState.scheduledPaging;
      state.refundedPaging = initialState.refundedPaging;
      state.failedPaging = initialState.failedPaging;
      state.cancelledPaging = initialState.cancelledPaging;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    resetSorting: (state) => {
      state.sorting = initialState.sorting;
    },

    resetSearch: (state) => {
      state.search = initialState.search;
    },

    resetAll: (state) => {
      state.search = initialState.search;
      state.collectedPaging = initialState.collectedPaging;
      state.scheduledPaging = initialState.scheduledPaging;
      state.refundedPaging = initialState.refundedPaging;
      state.failedPaging = initialState.failedPaging;
      state.cancelledPaging = initialState.cancelledPaging;
      state.sorting = initialState.sorting;
      state.filters = initialState.filters;
    },

    setIsShowPartialRefundModal: (state, action) => {
      state.isShowPartialRefundModal = action.payload;
    },

    setSelectedPayment: (state, action) => {
      state.selectedPayment = action.payload;
    },

    setPartialRefundPayment: (state, action) => {
      state.partialRefundPayment = {
        ...state.partialRefundPayment,
        ...action.payload
      };
    }
  }
});

export const selectFilters = (state: RootState) => state.invoicePaymentsSlice.filters;
export const selectSearch = (state: RootState) => state.invoicePaymentsSlice.search;
export const selectSorting = (state: RootState) => state.invoicePaymentsSlice.sorting;
export const selectCollectedPaging = (state: RootState) => state.invoicePaymentsSlice.collectedPaging;
export const selectScheduledPaging = (state: RootState) => state.invoicePaymentsSlice.scheduledPaging;
export const selectRefundedPaging = (state: RootState) => state.invoicePaymentsSlice.refundedPaging;
export const selectFailedPaging = (state: RootState) => state.invoicePaymentsSlice.failedPaging;
export const selectCancelledPaging = (state: RootState) => state.invoicePaymentsSlice.cancelledPaging;
export const selectActiveTab = (state: RootState) => state.invoicePaymentsSlice.activeTab;
export const selectSelectedPayment = (state: RootState) => state.invoicePaymentsSlice.selectedPayment;
export const selectIsShowPartialRefundModal = (state: RootState) => state.invoicePaymentsSlice.isShowPartialRefundModal;
export const selectPartialRefundPayment = (state: RootState) => state.invoicePaymentsSlice.partialRefundPayment;

export const {
  setSearch,
  setFilters,
  setSorting,
  setPaging,
  setActiveTab,
  resetSearch,
  resetPaging,
  resetAll,
  resetFilters,
  resetSorting,
  setSelectedPayment,
  setIsShowPartialRefundModal,
  setPartialRefundPayment
} = invoicePaymentsSlice.actions;

export default invoicePaymentsSlice.reducer;
