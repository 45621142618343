import { notification } from 'antd';
import { ClinicianListInterface } from 'components/v2/ClinicianSelect/ClinicianSelect';
import { ClientRecordListResponse } from 'interfaces/Clients/clientRecordNew';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { CreatedAppointmentType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { SSTagTypes, scheduleServicesApiSlice } from 'redux/services/scheduleServicesApiSlice';
import { useGetAccessToken } from 'utils/hooks/token';
import { postAppointments } from 'utils/http/appointment';
import { postGroupAppointments } from 'utils/http/ScheduleService/Groups/groups';
import AppointmentForm from './components/AppointmentForm/AppointmentForm';
import { initialValues as AppointmentFormValue } from './components/AppointmentForm/constants';
import BespokeForm from './components/BespokeForm/BespokeForm';
import { initialValues as ScheduleFormValue } from './components/ScheduleForm/constants';
import ScheduleForm from './components/ScheduleForm/ScheduleForm';
import styles from './EventForm.module.scss';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';
import { defaultTimezone as systemTimezone } from 'utils/hooks/GetTimezones/getTimezones';

type EventFormProps = {
  step: number;
  date?: Date;
  defaultClient?: clientRecordsInterface | ClientRecordListResponse['clientRecords'][number];
  defaultGroupId?: string;
  defaultTimeZone?: string;
  setStep: (step: number) => void;
  onClose: () => void;
  onCreateEditSuccess?: () => void;
};

enum SubmitStep {
  Initial = 1,
  SelectTime = 2,
  Finish = 3
}

enum EventType {
  Appointment = 'appointment',
  Bespoke = 'bespoke'
}

type AppointmentValue = typeof AppointmentFormValue;
type ScheduleValue = typeof ScheduleFormValue;
type PayloadValue = AppointmentValue & ScheduleValue;

const EventForm = ({
  step,
  date,
  defaultClient,
  defaultGroupId,
  defaultTimeZone,
  setStep,
  onClose,
  onCreateEditSuccess
}: EventFormProps) => {
  const dispatch = useAppDispatch();
  const { token } = useGetAccessToken();
  const { accountSettings } = useGetAccountSettings();
  const [eventType, setEventType] = useState<string>(EventType.Appointment);
  const [selectedClinician, setSelectedClinician] = useState<Partial<ClinicianListInterface> | undefined>({
    _id: ''
  });
  const [selectedClientRecord, setSelectedClientRecord] = useState<
    clientRecordsInterface | ClientRecordListResponse['clientRecords'][number] | undefined
  >(defaultClient);
  const [appointmentType, setAppointmentType] = useState<CreatedAppointmentType>();
  const [participationType, setParticipationType] = useState<ParticipantType>(
    defaultGroupId ? ParticipantType.Group : ParticipantType.OneToOne
  );
  const [values, setValues] = useState<any>();
  const [processing, setProcessing] = useState<'' | 'active' | 'finished'>('');
  const [selectedGroup, setSelectedGroup] = useState<GroupsFromAPI>({} as GroupsFromAPI);

  useEffect(() => {
    setStep(SubmitStep.Initial);
    if (date) {
      setEventType(EventType.Bespoke);
    }
  }, [date, setStep]);

  const handleChangeClinician = (clinicianValue?: Partial<ClinicianListInterface>) => {
    setSelectedClinician(clinicianValue);
    if (clinicianValue?._id !== selectedClinician?._id) {
      setSelectedClientRecord(undefined);
    }
    if (clinicianValue?.accessRight === 'mentor') {
      setParticipationType(ParticipantType.OneToOne);
    }
  };

  const onSubmitOneToOne = async (newValues: PayloadValue) => {
    setProcessing('active');
    const { eventOwner } = values;
    const payload = {
      eventOwner: eventOwner || selectedClinician?._id,
      clientRecordId: selectedClientRecord?._id,
      deliveryOption: newValues.deliveryType,
      recurringAppointment: {
        frequency: newValues.isRecurring ? newValues.frequency : ScheduleFormValue.frequency,
        amount: newValues.isRecurring ? newValues.occurrences : ScheduleFormValue.occurrences
      },
      slot: {
        date: newValues.date,
        endDate: newValues.endDate,
        startTime: newValues.startTime,
        endTime: newValues.endTime,
        startDateTime: newValues.startDateTime,
        endDateTime: newValues.endDateTime,
        gap: appointmentType?.gap,
        rate: appointmentType?.rate?.amount,
        sessionTypeId: appointmentType?._id,
        name: appointmentType?.name,
        deliveryOptions: [],
        faceToFaceLocation: newValues.faceToFaceLocation,
        videoCallInstructions: newValues.videoCallInstructions,
        phoneCallInstructions: newValues.phoneCallInstructions,
        phoneCallDialClientInstructions: newValues.phoneCallDialClientInstructions,
        otherInstructions: newValues.otherInstructions
      },
      timeZone: selectedClinician?.workTimeZone || defaultTimeZone,
      customised: newValues.isCustomising
    };

    if (newValues.roomInfo) {
      Object.assign(payload, {
        room: JSON.parse(newValues.roomInfo)
      });
    }

    const response = await postAppointments(payload, token);
    if (response.statusCode === 201 && onCreateEditSuccess) {
      notification.success({
        message: 'One to one appointment created.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      onClose();
      onCreateEditSuccess();
      // Clear Next Appointment cached data after add new appointment success
      dispatch(
        scheduleServicesApiSlice.util.invalidateTags([
          { type: SSTagTypes.NextAppointmentByCurrent, id: selectedClientRecord?._id }
        ])
      );
    } else if (response.statusCode === 409) {
      notification.error({ message: (await response.json()).message ?? 'Something went wrong.' });
    }
  };

  const onSubmitGroup = async (newValues: PayloadValue) => {
    setProcessing('active');
    const { eventOwner } = values;
    const groupPayload = {
      eventOwner: eventOwner || selectedClinician?._id,
      deliveryOption: newValues.deliveryType,
      recurringAppointment: {
        frequency: newValues.frequency,
        amount: newValues.occurrences
      },
      slot: {
        date: newValues.date,
        endDate: newValues.endDate,
        startTime: newValues.startTime,
        endTime: newValues.endTime,
        startDateTime: newValues.startDateTime,
        endDateTime: newValues.endDateTime,
        gap: appointmentType?.gap,
        rate: appointmentType?.rate?.amount,
        sessionTypeId: appointmentType?._id,
        name: appointmentType?.name,
        deliveryOptions: [],
        faceToFaceLocation: newValues.faceToFaceLocation,
        videoCallInstructions: newValues.videoCallInstructions,
        phoneCallInstructions: newValues.phoneCallInstructions,
        phoneCallDialClientInstructions: newValues.phoneCallDialClientInstructions,
        otherInstructions: newValues.otherInstructions
      },
      timeZone: accountSettings?.timezone || systemTimezone,
      customised: newValues.isCustomising,
      ...(newValues.roomInfo && {
        room: JSON.parse(newValues.roomInfo)
      })
    };

    const response = await postGroupAppointments(selectedGroup._id, groupPayload, token);
    if (response.statusCode === 204 && onCreateEditSuccess) {
      notification.success({
        message: 'Group appointment created.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      onClose();
      onCreateEditSuccess();
    } else if (response.statusCode === 409) {
      notification.error({ message: (await response.json()).message ?? 'Something went wrong.' });
    }
  };

  const onSubmitAppointment = (nextStep: number) => async (newValues: PayloadValue) => {
    const checkingData = participationType === ParticipantType.Group ? selectedGroup : selectedClientRecord;
    if (nextStep === SubmitStep.Finish && checkingData) {
      try {
        if (participationType === ParticipantType.Group) {
          await onSubmitGroup(newValues);
        } else {
          await onSubmitOneToOne(newValues);
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Something went wrong.'
        });
      } finally {
        setProcessing('');
      }
    } else {
      setValues({
        ...values,
        ...newValues
      });
      setStep(2);
    }
  };

  return (
    <>
      {eventType === EventType.Bespoke ? (
        <BespokeForm
          date={date}
          eventType={eventType}
          setEventType={setEventType}
          onClose={onClose}
          onCreateEditSuccess={onCreateEditSuccess}
          defaultTimeZone={defaultTimeZone}
        />
      ) : (
        <>
          <div className={step === SubmitStep.Initial ? styles.visible : styles.hidden}>
            <AppointmentForm
              eventType={eventType}
              setEventType={setEventType}
              clinician={selectedClinician}
              setClinician={handleChangeClinician}
              defaultGroupId={defaultGroupId}
              setSelectedClientRecord={setSelectedClientRecord}
              appointmentType={appointmentType}
              setAppointmentType={setAppointmentType}
              onSubmit={onSubmitAppointment(SubmitStep.SelectTime)}
              selectedClientRecord={selectedClientRecord}
              participationType={participationType}
              setParticipationType={setParticipationType}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
            />
          </div>
          <div className={step === SubmitStep.SelectTime ? styles.visible : styles.hidden}>
            <ScheduleForm
              clinician={selectedClinician}
              processing={processing}
              appointmentType={appointmentType}
              onSubmit={onSubmitAppointment(SubmitStep.Finish)}
              selectedClientRecord={selectedClientRecord}
              selectedParticipationType={participationType}
              selectedGroup={selectedGroup}
              defaultTimeZone={defaultTimeZone}
            />
          </div>
        </>
      )}
    </>
  );
};

export default EventForm;
