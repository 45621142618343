import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import styles from './ReportHeader.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface ReportHeaderProps {
  onClick?: Function;
}
const ReportHeader = ({ onClick = () => {} }: ReportHeaderProps) => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{'Reports'}</div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <ButtonAlt
          variant={'outlined'}
          icon={'add'}
          onClick={() => {
            localStorage.setItem('reportPrevPath', '');
            navigate(`${FORMS.NEW_REPORT_TEMPLATE}?backToReport=true`);
          }}
        >
          Add New Template
        </ButtonAlt>
        <ButtonAlt id={'createNewReportId'} onClick={() => onClick()} icon={'add_circle_outline'}>
          Create New Report
        </ButtonAlt>
      </div>
    </div>
  );
};

export default ReportHeader;
