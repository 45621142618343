import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { ReportListInterface } from '../../interfaces/Reports/report';
import {
  getReport,
  getReportByAdmin,
  getReportList,
  getReportListByAdmin
} from '../http/DocumentService/Reports/reports';
import { useGetAccountPackageView } from './GetAccountInfo/accountPackageView';
import { ReportTemplate } from 'interfaces/Reports/reportTemplate';
import { defaultReportTemplate } from '../../pages/Report/ReportTemplateEditor/constants';
import { getReportTemplate } from '../http/DocumentService/Reports/reportsTemplate';

export const useFetchReportList = (token: string) => {
  const [reportListData, setReportListData] = useState<ReportListInterface[]>([]);
  const [isReportListDataLoading, setIsReportListDataLoading] = useState(true);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

  const fetchReportList = async () => {
    setIsReportListDataLoading(true);

    try {
      const getReportsResponse =
        isEdgeAdminView || isEdgeReceptionist ? await getReportListByAdmin(token) : await getReportList(token);

      const reportListData = await getReportsResponse.json();

      setReportListData(reportListData);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get this report list data' });
    }

    setIsReportListDataLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchReportList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { reportListData, isReportListDataLoading, fetchReportList };
};

export const useFetchReport = (token: string, reportId: string) => {
  const [reportDetailsData, setReportDetailsData] = useState([]);
  const [isReportDetailsDataLoading, setIsReportDetailsDataLoading] = useState(true);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

  const fetchReportDetails = async () => {
    setIsReportDetailsDataLoading(true);

    try {
      const getReportResponse =
        isEdgeAdminView || isEdgeReceptionist
          ? await getReportByAdmin(token, reportId)
          : await getReport(token, reportId);

      const reportData = await getReportResponse.json();

      setReportDetailsData(reportData);
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get this report details data' });
    }

    setIsReportDetailsDataLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchReportDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { reportDetailsData, isReportDetailsDataLoading, fetchReportDetails };
};

export const useFetchReportTemplate = (token: string, templateId: string) => {
  const [templateData, setTemplateData] = useState<ReportTemplate>(defaultReportTemplate);
  const [isTemplateDataLoading, setIsTemplateDataLoading] = useState(true);

  const fetchTemplateData = useCallback(
    async (token: string) => {
      setIsTemplateDataLoading(true);
      if (templateId === 'newTemplate') {
        const newTemplateData = {
          ...defaultReportTemplate,
          isEditing: false,
          updatedAt: new Date()
        };
        setTemplateData(newTemplateData);
      } else {
        try {
          const getReportTemplateResponses = await (await getReportTemplate(token, templateId)).json();
          setTemplateData(getReportTemplateResponses);
        } catch (ex) {
          console.error(ex);
          notification.error({ message: 'Something went wrong while trying to get this report template' });
        }
      }
      setIsTemplateDataLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (token) {
      fetchTemplateData(token);
    }
  }, [token, fetchTemplateData]);

  return { templateData, isTemplateDataLoading, refetchTemplateData: fetchTemplateData };
};
