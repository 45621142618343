import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';
import { ClientStatus } from 'interfaces/Clients/clientsRecord';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const postClientsRecord = (token: string, body: Record<string, any>) => {
  return httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(`/clinicians/me/client-records`, body);
};

export const postRecheckClientProfileMedicareEligibility = (
  token: string,
  clientRecordId: string,
  clientProfileId: string
) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/client-profiles/${clientProfileId}/recheck-medicare-eligibility`
  );

export const putCheckAvailabilityEmail = (token: string, body: Record<string, any>) => {
  return httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records:checkEmailAvailability`,
    body
  );
};

export const putCheckAvailabilityCaseId = (token: string, body: Record<string, any>) => {
  return httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records:checkCaseIdAvailability`,
    body
  );
};

export const putClientsRecord = (token: string, clientRecordId: string, body: Record<string, any>) => {
  return httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/client-profiles`,
    body
  );
};

export const patchClientRecordsRecordStatus = (
  token: string,
  clientRecordId: string,
  body: { recordStatus: ClientStatus['status'] }
) => {
  return httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}:changeProfileStatus`,
    body
  ) as Promise<BentResponse>;
};

export const putClientProfileMedicare = (
  token: string,
  clientRecordId: string,
  clientProfileId: string,
  body: Record<string, any>
) =>
  httpClient('PUT', url!, [200, 400], { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/client-profiles/${clientProfileId}/medicare`,
    body
  ) as Promise<BentResponse>;

export const putClientProfileMedicareSummary = (
  token: string,
  clientRecordId: string,
  clientProfileId: string,
  body: Record<string, any>
) =>
  httpClient('PUT', url!, [204, 400], { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/client-profiles/${clientProfileId}/medicare/summary`,
    body
  ) as Promise<BentResponse>;

export const getClientsRecord = (token: string, status?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records${status ?? ''}`
  ) as Promise<BentResponse>;

export const getClientsRecordByAccountId = (token: string, accountId: string, query?: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/client-records${query ?? ''}`
  ) as Promise<BentResponse>;

export const getClientRecordByRecordId = (token: string, clientRecordId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}`
  ) as Promise<BentResponse>;

export const getClientRecordByAccountIdNRecordId = (token: string, clientRecordId: string, accountId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/client-records/${clientRecordId}`
  ) as Promise<BentResponse>;

export const getClientEncryptedDataByRecordProfileId = (token: string, recordId: string, profileId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${recordId}/client-profiles/${profileId}/encrypted`
  ) as Promise<BentResponse>;

// GET API
export const getAllClientEncryptedDataByClientRecordId = (token: string, recordId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${recordId}:getDecrypted`
  ) as Promise<BentResponse>;

export const putResendInvitation = (token: string, clientRecordId: string) =>
  httpClient('PATCH', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/clinicians/me/client-records/${clientRecordId}:sendSignupInvitation`) as Promise<BentResponse>;

export const putCancelInvitation = (token: string, clientRecordId: string) =>
  httpClient('PATCH', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}:cancelSignupInvitation`
  ) as Promise<BentResponse>;

// PUT API
export const putClientRecordTag = (token: string, clientRecordId: string, tagId: string) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/tags/${tagId}`
  ) as Promise<BentResponse>;

// DELETE API
export const deleteClientRecordTag = (token: string, clientRecordId: string, tagId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/tags/${tagId}`
  ) as Promise<BentResponse>;

export const changeGeneralPractitioner = (token: string, clientRecordId: string, payload: Record<string, any>) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}:changeGeneralPractitioner`,
    payload
  ) as Promise<BentResponse>;

export const postKeyClientContact = (token: string, clientRecordId: string, body: Record<string, any>) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/key-client-contacts`,
    body
  ) as Promise<BentResponse>;

export const putKeyClientContact = (token: string, clientRecordId: string, keyClientContactId: string) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/key-client-contacts/${keyClientContactId}`
  ) as Promise<BentResponse>;

export const deleteKeyClientContact = (token: string, clientRecordId: string, keyClientContactId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/key-client-contacts/${keyClientContactId}`
  ) as Promise<BentResponse>;

export const unassignedGeneralPractitioner = (token: string, clientRecordId: string) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}:unassignGeneralPractitioner`
  ) as Promise<BentResponse>;

export const putClientReferral = (token: string, clientRecordId: string, payload: Record<string, any>) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/referral`,
    payload
  ) as Promise<BentResponse>;

export const getSystemIntegrations = (token: string, asAdmin: boolean) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/system-integrations?asAdmin=${asAdmin}`
  ) as Promise<BentResponse>;

export const getSystemIntegration = (token: string, integrationId: string, asAdmin: boolean) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/system-integrations/${integrationId}?asAdmin=${asAdmin}`
  ) as Promise<BentResponse>;

export const postAppointmentStatus = (token: string, clientRecordId: string, body: Record<string, any>) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}/appointment-status-overview-configuration`,
    body
  );

export const putOnboardTriageClient = (token: string, clientRecordId: string, body: Record<string, any>) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinicians/me/client-records/${clientRecordId}:onboardTriage`,
    body
  ) as Promise<BentResponse>;
