import styles from './ReportContentAsPDF.module.scss';
import { Report } from 'interfaces/Reports/report';
import { combineName } from 'utils/general';
import { ClientRecordType, ProfileRole } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import { PracticeInterface } from 'interfaces/Profile/Profile';
import { useTranslation } from 'react-i18next';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import DndPreviewSection from '../ReportBuilderPreview/components/ReportDraftContent/components/DndPreviewSection/DndPreviewSection';
import { forwardRef, ForwardedRef } from 'react';
import { Letter } from 'interfaces/Letters/letter';

interface IReportContentAsPDFProps {
  type: 'letter' | 'report';
  reportDetails?: Report;
  letter?: Letter;
  practice?: PracticeInterface;
}

const ReportContentAsPDF = forwardRef(
  ({ type, reportDetails, letter, practice }: IReportContentAsPDFProps, ref: ForwardedRef<null>) => {
    const [t] = useTranslation();

    const data = type === 'report' ? reportDetails : letter;
    const name = type === 'report' ? reportDetails?.reportName : letter?.letterName;

    const primaryClientProfileName =
      data &&
      [ClientRecordType.Child, ClientRecordType.YoungPerson].includes(data.clientRecord.recordType) &&
      type === 'report'
        ? reportDetails?.clientRecord.clientProfiles.filter(
            ({ role }) => role && [ProfileRole.Child, ProfileRole.YoungPerson].includes(role)
          )
        : data?.clientRecord.clientProfiles;

    const clientPrimaryName = primaryClientProfileName
      ? combineName(primaryClientProfileName, data?.clientRecord.recordType === 'couple')
      : '-';

    return (
      <div className={styles.parentWrapper}>
        <div className={styles.container} ref={ref}>
          <div className={styles.contentWrapper}>
            {/* Report Header */}
            <div className={styles.reportHeader}>
              <div className={styles.practiceInfoWrapper}>
                <div className={styles.practiceCard}>
                  {practice && (
                    <>
                      {practice.logo && (
                        <div className={styles.practiceImgWrapper}>
                          <img src={practice.logo} alt={'practice logo'} className={styles.practiceImg} />
                        </div>
                      )}
                      <div className={styles.contactDetailInfoPreview}>
                        <span className={styles.name}>{practice.name}</span>
                        <div className={styles.infoPreviewWrapper}>
                          {data?.contactDetails.practice.address.isVisible && (
                            <div className={styles.detailInfo}>{data?.contactDetails.practice.address.value}</div>
                          )}
                          {data?.contactDetails.practice.mobileNumber.isVisible && (
                            <div className={styles.detailInfo}>{data?.contactDetails.practice.mobileNumber.value}</div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.patientInfoWrapper}>
                <div className={styles.infoWrap}>
                  <span className={styles.infoTitle}>{t('form.client_name')}:</span> {clientPrimaryName}
                </div>
                <div className={styles.infoWrap}>
                  <span className={styles.infoTitle}>Report Name:</span> {name || '-'}
                </div>
                <div className={styles.infoWrap}>
                  <span className={styles.infoTitle}>Date of report:</span>{' '}
                  {moment(data?.issueDate, 'YYYY-MM-DD').format(MOMENTJS_DATE_FORMAT)}
                </div>
              </div>
            </div>

            {/* Report content */}
            <div className={styles.reportContent}>
              {type === 'report' && reportDetails && (
                <DndPreviewSection dndDimension={reportDetails.template.dimensions} reportItems={reportDetails.items} />
              )}
              {type === 'letter' && letter && (
                <div className={styles.letterContent} dangerouslySetInnerHTML={{ __html: letter.body }} />
              )}

              <div className={styles.reportSignature}>
                <div className={styles.signLabel}>{data?.clinicianSignatureExtraDetails.valediction}</div>
                <div className={styles.signature}>
                  {data?.clinicianSignature.type === 'typeVer' ? (
                    data?.clinicianSignature.textVal
                  ) : (
                    <img className={styles.signatureImg} alt={'signature'} src={data?.clinicianSignature.drawVal} />
                  )}
                </div>
                <div className={styles.extraDetailsWrapper}>
                  {data?.clinicianSignatureExtraDetails.name && (
                    <div className={styles.extraDetails}>{data.clinicianSignatureExtraDetails.name}</div>
                  )}
                  {data?.clinicianSignatureExtraDetails.mobileNumber && (
                    <div className={styles.extraDetails}>{data.clinicianSignatureExtraDetails.mobileNumber}</div>
                  )}
                  {data?.clinicianSignatureExtraDetails.email && (
                    <div className={styles.extraDetails}>{data.clinicianSignatureExtraDetails.email}</div>
                  )}
                  {data?.clinicianSignatureExtraDetails.other && (
                    <div className={styles.extraDetails}>{data.clinicianSignatureExtraDetails.other}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ReportContentAsPDF;
