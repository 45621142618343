import { DatePicker, DatePickerProps, Skeleton } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import classNames from 'classnames';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import FilterRadioDropdown, { FilterRadioItem } from 'components/T23/FilterRadioDropdown/FilterRadioDropdown';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { ClaimCodeFilters } from 'interfaces/invoices/pendingClaimInvoices';
import { ClaimTypeFilters, SubmittedStatusFilters, SuccessStatusFilters } from 'interfaces/invoices/submittedClaim';
import { debounce } from 'lodash';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import { useCallback, useMemo, useState } from 'react';
import { useGetMinifiedClientRecordQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetMinifiedGroupListQuery } from 'redux/endpoints/clinicianProfileServices/group';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  resetFilters,
  resetRejectedPaging,
  resetSubmittedPaging,
  resetSuccessPaging,
  resetWriteOffPaging,
  selectActiveTab,
  selectFilters,
  setFilters,
  SubmittedClaimTabs
} from 'redux/features/submittedClaims/submittedClaimSlice';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import customStyles from './DateRangePickerCustom.module.scss';
import styles from './SubmittedClaimFilter.module.scss';
import { useGetPractitionerListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import SearchBarT23 from 'components/SearchBarT23/SearchBarT23';
import moment from 'moment';

const PER_PAGE = 50;

interface SubmittedClaimFilterProps {
  totalItems: number;
  isLoading: boolean;
}

const SubmittedClaimFilter = ({ totalItems, isLoading }: SubmittedClaimFilterProps) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeUser, isEdgeUserView } = useGetAccountPackageView();
  const dispatch = useAppDispatch();

  const filters = useAppSelector(selectFilters);
  const activeTab = useAppSelector(selectActiveTab);

  const [clientListCurrentPage, setClientListCurrentPage] = useState<number>(1);
  const [clientSearchText, setClientSearchText] = useState<string>('');
  const [practitionerCurrentPage, setPractitionerCurrentPage] = useState<number>(1);
  const [practitionerSearchText, setPractitionerSearchText] = useState('');

  const onResetPaging = useCallback(() => {
    dispatch(resetSubmittedPaging());
    dispatch(resetSuccessPaging());
    dispatch(resetRejectedPaging());
    dispatch(resetWriteOffPaging());
  }, [dispatch]);

  const onClearFilter = () => {
    onResetPaging();
    dispatch(resetFilters());
  };

  const onChangeClientFilter = (clients: FilterCheckListItem[]) => {
    onResetPaging();
    dispatch(setFilters({ ...filters, clients }));
  };

  const onChangePractitionerFilter = (practitioners: FilterCheckListItem[]) => {
    onResetPaging();
    dispatch(setFilters({ ...filters, practitioners }));
  };

  const onChangeGroupFilter = (groups: FilterCheckListItem[]) => {
    onResetPaging();
    dispatch(setFilters({ ...filters, groups }));
  };

  const onChangeClaimTypeFilter = (claimType: FilterRadioItem | undefined) => {
    onResetPaging();
    dispatch(setFilters({ ...filters, claimType }));
  };

  const onChangeClaimCodeFilter = (code: FilterRadioItem | undefined) => {
    onResetPaging();
    dispatch(setFilters({ ...filters, code }));
  };

  const onChangeStatusFilter = (status: FilterRadioItem | undefined) => {
    if (activeTab === SubmittedClaimTabs.Submitted) {
      dispatch(resetSubmittedPaging());
      dispatch(setFilters({ ...filters, submittedStatus: status }));
    } else if (activeTab === SubmittedClaimTabs.Success) {
      dispatch(resetSuccessPaging());
      dispatch(setFilters({ ...filters, successStatus: status }));
    }
  };

  const onChangeSubmittedDateFilter = (dateString: [string, string] | string) => {
    onResetPaging();
    dispatch(
      setFilters({
        ...filters,
        ...(filters.claimedAfter !== dateString[0] && {
          claimedAfter: dateString[0]
        }),
        ...(filters.claimedBefore !== dateString[1] && {
          claimedBefore: dateString[1]
        })
      })
    );
  };

  const onChangeClaimIdReferenceSearch = useCallback(
    (claimId: string) => {
      onResetPaging();
      dispatch(setFilters({ ...filters, claimId }));
    },
    [dispatch, filters, onResetPaging]
  );

  const debounceSetClientSearchText = useMemo(
    () =>
      debounce((value) => {
        setClientSearchText(value);
        if (value) {
          setClientListCurrentPage(1);
        }
      }, 1000),
    []
  );

  const debouncedSetPractitionersSearchText = useMemo(
    () =>
      debounce((value) => {
        setPractitionerSearchText(value);
        setPractitionerCurrentPage(1);
      }, 1000),
    []
  );

  const debouncedChangeClaimIdReferenceSearch = useMemo(
    () =>
      debounce((value) => {
        onChangeClaimIdReferenceSearch(value);
      }, 1000),
    [onChangeClaimIdReferenceSearch]
  );

  const {
    data: clientListData,
    isLoading: isClientListDataLoading,
    isFetching: isClientListDataFetching
  } = useGetMinifiedClientRecordQuery({
    accountId,
    params: {
      page: clientListCurrentPage,
      perPage: PER_PAGE,
      recordStatus: 'active',
      ...(clientSearchText && {
        searchValue: clientSearchText,
        searchBy: SearchFilterEnum.FirstOrLastName
      })
    }
  });

  const {
    data: practitionerData,
    isLoading: isPractitionerDataLoading,
    isFetching: isPractitionerDataFetching
  } = useGetPractitionerListQuery({
    accountId,
    params: { page: practitionerCurrentPage, perPage: PER_PAGE, status: 'active', searchValue: practitionerSearchText }
  });

  const { data: groupData } = useGetMinifiedGroupListQuery({ accountId, isEdgeUserView });

  const clientListFilter: FilterCheckListItem[] = clientListData
    ? clientListData.clientRecords.map((item) => ({
        name: `${item.clientProfiles[0].firstName} ${item.clientProfiles[0].lastName}`,
        _id: item._id
      }))
    : [];
  const practitionerList: FilterCheckListItem[] = practitionerData
    ? practitionerData.clinicians.map((item) => ({ name: item.name, _id: item._id }))
    : [];
  const groupList: FilterCheckListItem[] = groupData
    ? groupData.groups.map((item) => ({ name: item.name, _id: item._id }))
    : [];

  const totalClientListPage = clientListData?.paging ? Math.ceil(clientListData.paging.totalItems / PER_PAGE) : 1;
  const totalPractitionerPage = practitionerData?.paging ? Math.ceil(practitionerData.paging.totalItems / PER_PAGE) : 1;

  const {
    clients,
    practitioners,
    groups,
    claimType,
    code,
    submittedStatus,
    successStatus,
    claimedAfter,
    claimedBefore,
    claimId
  } = filters;
  const showClearFilter =
    clients.length > 0 ||
    practitioners.length > 0 ||
    groups.length > 0 ||
    claimType ||
    code ||
    (activeTab === SubmittedClaimTabs.Submitted && submittedStatus) ||
    (activeTab === SubmittedClaimTabs.Success && successStatus) ||
    claimedAfter ||
    claimedBefore ||
    claimId;

  const { RangePicker } = DatePicker;

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <div className={styles.numberOfMatchedClaims}>
          {isLoading ? (
            <div className={styles.loadingProfile}>
              <Skeleton.Input className={styles.loading} active />
            </div>
          ) : (
            <div>
              {showClearFilter ? 'FILTER matches' : 'Showing'} <span className={styles.highLight}>{totalItems}</span>{' '}
              {`${activeTab === SubmittedClaimTabs.WriteOff ? 'written off' : activeTab} claims`}
            </div>
          )}
        </div>
        <ButtonAlt
          error
          disabled={!showClearFilter}
          size={'small'}
          variant={'text'}
          className={styles.clearFilters}
          onClick={onClearFilter}
        >
          Clear filters
        </ButtonAlt>
      </div>

      {/* Filer buttons */}
      <div className={styles.buttonsContainer}>
        {/* Client */}
        <FilterDropdown
          id={'clients'}
          icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
          menuItems={clientListFilter}
          onChangeItem={onToggleFilter(clientListFilter, clients, onChangeClientFilter)}
          searchable
          selectedFilterList={clients}
          showSearchIcon
          loading={isClientListDataLoading}
          hasMoreData={totalClientListPage > clientListCurrentPage || isClientListDataFetching}
          isFetchingMore={isClientListDataFetching}
          loadMore={() => {
            if (!isClientListDataFetching && clientListCurrentPage <= totalClientListPage) {
              setClientListCurrentPage(clientListCurrentPage + 1);
            }
          }}
          enableSelectedBackground
          onClearFilter={() => {
            dispatch(setFilters({ ...filters, clients: [] }));
          }}
          setSearchText={debounceSetClientSearchText}
        >
          Client
        </FilterDropdown>

        {isEdgeUser && (
          <FilterDropdown
            id={'practitioner'}
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            menuItems={practitionerList}
            onChangeItem={onToggleFilter(practitionerList, practitioners, onChangePractitionerFilter)}
            searchable
            selectedFilterList={practitioners}
            showSearchIcon
            loading={isPractitionerDataLoading}
            hasMoreData={totalPractitionerPage > practitionerCurrentPage || isPractitionerDataFetching}
            isFetchingMore={isPractitionerDataFetching}
            loadMore={() => {
              if (!isPractitionerDataFetching && practitionerCurrentPage <= totalPractitionerPage) {
                setPractitionerCurrentPage(practitionerCurrentPage + 1);
              }
            }}
            setSearchText={debouncedSetPractitionersSearchText}
          >
            Practitioner
          </FilterDropdown>
        )}

        {isEdgeUser && (
          <FilterDropdown
            id={'group'}
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            menuItems={groupList}
            onChangeItem={onToggleFilter(groupList, groups, onChangeGroupFilter)}
            searchable
            selectedFilterList={groups}
            showSearchIcon
          >
            Groups
          </FilterDropdown>
        )}

        {/* Claim Type */}
        <FilterRadioDropdown
          id="claimType"
          menuItems={ClaimTypeFilters}
          onChangeItem={onChangeClaimTypeFilter}
          selectedItem={claimType}
        >
          Payer
        </FilterRadioDropdown>

        {/* Claim code */}
        <FilterRadioDropdown
          id="claimCode"
          menuItems={ClaimCodeFilters}
          onChangeItem={onChangeClaimCodeFilter}
          selectedItem={code}
        >
          Code
        </FilterRadioDropdown>

        {/* Claim status */}
        {[SubmittedClaimTabs.Submitted, SubmittedClaimTabs.Success].includes(activeTab) && (
          <FilterRadioDropdown
            id="status"
            menuItems={activeTab === SubmittedClaimTabs.Submitted ? SubmittedStatusFilters : SuccessStatusFilters}
            onChangeItem={onChangeStatusFilter}
            selectedItem={activeTab === SubmittedClaimTabs.Submitted ? submittedStatus : successStatus}
          >
            Status
          </FilterRadioDropdown>
        )}

        {/* Submitted date */}
        <div
          className={classNames(
            customStyles.dateRangeContainer,
            isEdgeAdminView ? customStyles.submittedDateContainerAdmin : customStyles.submittedDateContainer
          )}
        >
          <RangePicker
            placeholder={['From', 'To']}
            value={[
              claimedAfter ? moment(claimedAfter, 'YYYY-MM-DD') : null,
              claimedBefore ? moment(claimedBefore, 'YYYY-MM-DD') : null
            ]}
            dropdownClassName={classNames(
              customStyles.dateRangeDropdown,
              isEdgeAdminView ? customStyles.dropdownClassNameAdmin : customStyles.dropdownClassName
            )}
            onChange={(
              value: DatePickerProps['value'] | RangePickerProps['value'],
              dateString: [string, string] | string
            ) => {
              onChangeSubmittedDateFilter(dateString);
            }}
            className={claimedBefore && claimedAfter && 'hasValue'}
          />
        </div>

        <div className={styles.searchBar}>
          <SearchBarT23
            placeholder={'Search Claim ID or Reference'}
            value={claimId}
            onSearch={debouncedChangeClaimIdReferenceSearch}
            hideLabel
            searchOnChange
            withSearchButton
          />
        </div>
      </div>
    </div>
  );
};

export default SubmittedClaimFilter;
