import React, { useEffect, useState } from 'react';
import styles from './ClientListColumnsDropdown.module.scss';
import { Select } from 'antd';
import { ClientListColumnsDropdownOption } from '../../constant';
import { ColumnConfigType, ColumnSettingsEnum } from 'utils/providers/ClientListColumnsProvider/constants';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface ClientListColumnsDropdownProps {
  index: number;
  column: ColumnConfigType;
  initialOptions: ClientListColumnsDropdownOption[];
}

const ClientListColumnsDropdown = ({ index, column, initialOptions }: ClientListColumnsDropdownProps) => {
  const formik = useFormikContext<{ columns: ColumnConfigType[] }>();
  const [options, setOptions] = useState<ClientListColumnsDropdownOption[]>(initialOptions);
  const { isEdgeAdminView } = useGetAccountPackageView();
  const value = column.id !== ColumnSettingsEnum.EMPTY ? column.id : undefined;
  // for animating the placeholder
  const [isPlaceholderAtTop, setIsPlaceholderAtTop] = useState(!!value);
  // for switching placeholder's color
  const [isFocus, setIsFocus] = useState(false);

  // for handling error
  const isError = !!(column.active && formik.touched.columns?.[index] && formik.errors.columns?.[index]);

  const updateOptions = () => {
    const updatedColumns = formik.values.columns.map((col) => col.id);
    const newOptions = options.map((option) => ({
      ...option,
      ...(option.value && {
        disabled: updatedColumns.includes(option.value)
      })
    }));
    setOptions(newOptions);
  };

  useEffect(() => {
    updateOptions();
    // eslint-disable-next-line
  }, [formik.values.columns]);

  useEffect(() => {
    setIsPlaceholderAtTop(!!value);
  }, [value]);

  const handleChange = (e: ColumnSettingsEnum) => {
    formik.setFieldValue(`columns.${index}.id`, e);
  };

  // is the column selected is for the current dropdown
  const isCurrentSelected = (col: string | undefined) =>
    col && column.id !== ColumnSettingsEnum.EMPTY && col === column.id;

  const handleOnBlur = () => {
    if (value) {
      setIsPlaceholderAtTop(true);
    } else {
      setIsPlaceholderAtTop(false);
    }
    setIsFocus(false);
  };

  const handleFocus = () => {
    setIsPlaceholderAtTop(true);
    if (!isError) {
      setIsFocus(true);
    }
  };

  return (
    <div
      className={classNames(
        styles.dropdownWrapper,
        isError && styles.errorContainer,
        !column.active && styles.disabledContainer,
        isFocus && styles.isFocus,
        isEdgeAdminView ? styles.admin : styles.nonAdmin
      )}
    >
      <p
        className={classNames(
          styles.placeholder,
          isPlaceholderAtTop && styles.placeholderFocus,
          isEdgeAdminView && styles.admin
        )}
      >
        Select data field
      </p>
      <Select
        className={styles.container}
        bordered={false}
        onChange={handleChange}
        onBlur={handleOnBlur}
        onFocus={handleFocus}
        onSelect={() => setIsFocus(false)}
        value={value}
        disabled={!column.active}
        suffixIcon={<i className={`material-icons-outlined`}>expand_more</i>}
        popupClassName={isEdgeAdminView ? styles.adminScrollbar : styles.scrollbar}
      >
        {options.map((option, idx) => (
          <Select.Option key={`option__${idx}`} disabled={!!(option.disabled || option.section)} value={option.value}>
            <div
              className={classNames(
                isEdgeAdminView && styles.admin,
                option.section
                  ? styles.labelSection
                  : isCurrentSelected(option.value)
                  ? styles.labelSelected
                  : option.disabled
                  ? styles.labelOptionDisabled
                  : styles.labelOption,
                idx !== 0 && option.section && styles.marginGroup
              )}
            >
              {!isCurrentSelected(option.value) && option.value && option.disabled && (
                <i className={`material-icons-outlined ${styles.checkIcon}`}>done</i>
              )}
              {option.section || option.label}
            </div>
          </Select.Option>
        ))}
      </Select>
      {isError && <p className={styles.error}>Please select data field</p>}
    </div>
  );
};

export default ClientListColumnsDropdown;
