import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import styles from './LinkClientOrGroup.module.scss';
import {
  AppointmentClientOrGroupType,
  AppointmentHumanFactorStep,
  selectAppointmentData,
  setCurrentStep,
  setSelectedClient,
  setSelectedClientType,
  setSelectedGroup,
  startInlineCalendarValidation
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClientRecordMinifiedForInlineAppointmentQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetGroupMinifiedForInlineAppointmentQuery } from 'redux/endpoints/clinicianProfileServices/group';
import { GroupStatus } from 'pages/Groups/Interfaces/Groups';
import classnames from 'classnames';

const clientOrGroupOptions = [
  { label: 'Client', value: AppointmentClientOrGroupType.Client },
  { label: 'Group', value: AppointmentClientOrGroupType.Group }
];

const PAGINATION_PER_PAGE = 50;

interface LinkClientOrGroupProps {
  containerClassName?: string;
  separatedLine?: ReactNode;
  activeClientName?: boolean;
  showSelectedItemOnFirstOption?: boolean;
}

const LinkClientOrGroup = ({
  containerClassName,
  separatedLine,
  activeClientName,
  showSelectedItemOnFirstOption
}: LinkClientOrGroupProps) => {
  const dispatch = useAppDispatch();
  const { selectedClient, selectedClientType, selectedGroup, selectedPractitioner } =
    useAppSelector(selectAppointmentData);
  const inlineCalendarValidation = useAppSelector(startInlineCalendarValidation);

  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeUserView, isEdgeReceptionist, isNormalUserView } = useGetAccountPackageView();

  const [clientListCurrentPage, setClientListCurrentPage] = useState(1);
  const [groupListCurrentPage, setGroupListCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setClientListCurrentPage(1);
    setGroupListCurrentPage(1);
  }, [selectedPractitioner]);

  const {
    data: clientMinifiedListData,
    isLoading: isClientMinifiedListDataLoading,
    isFetching: isClientMinifiedListDataFetching
  } = useGetClientRecordMinifiedForInlineAppointmentQuery(
    {
      accountId,
      params: {
        ...(selectedPractitioner?._id && { clinicianId: selectedPractitioner._id }),
        recordStatus: isNormalUserView ? 'active' : 'active,waitlist',
        ...(searchText && {
          searchValue: searchText,
          searchBy: SearchFilterEnum.FirstOrLastName
        }),
        page: clientListCurrentPage,
        perPage: PAGINATION_PER_PAGE
      }
    },
    {
      skip:
        ((isEdgeAdminView || isEdgeReceptionist) && selectedPractitioner === undefined) ||
        selectedClientType === AppointmentClientOrGroupType.Group
    }
  );

  const {
    data: groupMinifiedListData,
    isLoading: isGroupMinifiedListDataLoading,
    isFetching: isGroupMinifiedListDataFetching
  } = useGetGroupMinifiedForInlineAppointmentQuery(
    {
      accountId,
      isEdgeUserView,
      ...(selectedPractitioner?._id && { clinicianId: selectedPractitioner._id }),
      status: GroupStatus.Active,
      ...(searchText && { name: searchText }),
      page: groupListCurrentPage,
      perPage: PAGINATION_PER_PAGE
    },
    {
      skip:
        ((isEdgeAdminView || isEdgeReceptionist) && selectedPractitioner === undefined) ||
        selectedClientType === AppointmentClientOrGroupType.Client
    }
  );

  const clientOptions: IOptionItem[] = useMemo(() => {
    return clientMinifiedListData
      ? clientMinifiedListData.clientRecords.map((item) => ({
          value: item._id,
          label: `${item.clientProfiles[0].firstName} ${item.clientProfiles[0].lastName}`
        }))
      : [];
  }, [clientMinifiedListData]);

  const totalClientListPage = clientMinifiedListData?.paging
    ? Math.ceil(clientMinifiedListData.paging.totalItems / PAGINATION_PER_PAGE)
    : 1;

  const debounceSetSearchText = useMemo(
    () =>
      debounce((value) => {
        setSearchText(value);
        setClientListCurrentPage(1);
      }, 1000),
    []
  );

  const handleSelectClientType = (value: string) => {
    if (selectedClientType !== value) {
      dispatch(setSelectedClientType(value));
      setSearchText('');
      dispatch(setSelectedClient(undefined));
      dispatch(setSelectedGroup(undefined));
    }
  };

  const handleSelectClient = (id: string) => {
    const selectedClient = clientMinifiedListData?.clientRecords.find((item) => item._id === id);
    dispatch(setSelectedClient(selectedClient));
    activeClientName && dispatch(setCurrentStep(AppointmentHumanFactorStep.AppointmentType));
  };

  const loadMoreClientRecord = useCallback(() => {
    const page = clientMinifiedListData?.paging.page || 1;
    if (!isClientMinifiedListDataFetching && page < totalClientListPage) {
      setClientListCurrentPage(page + 1);
    }
  }, [totalClientListPage, isClientMinifiedListDataFetching, clientMinifiedListData]);

  const groupOptions: IOptionItem[] = useMemo(() => {
    return groupMinifiedListData
      ? groupMinifiedListData.groups.map((item) => ({
          value: item._id,
          label: item.name
        }))
      : [];
  }, [groupMinifiedListData]);

  const totalGroupListPage = groupMinifiedListData?.paging
    ? Math.ceil(groupMinifiedListData.paging.totalItems / PAGINATION_PER_PAGE)
    : 1;

  const handleSelectGroup = (id: string) => {
    const selectedGroup = groupMinifiedListData?.groups.find((item) => item._id === id);
    dispatch(setSelectedGroup(selectedGroup));
  };

  const loadMoreGroupRecord = useCallback(() => {
    const page = groupMinifiedListData?.paging.page || 1;
    if (!isGroupMinifiedListDataFetching && page < totalGroupListPage) {
      setGroupListCurrentPage(page + 1);
    }
  }, [totalGroupListPage, isGroupMinifiedListDataFetching, groupMinifiedListData]);

  return (
    <div className={classnames(styles.container, containerClassName)}>
      <div className={styles.item}>
        <DropdownSearchable
          selected={selectedClientType}
          options={clientOrGroupOptions}
          onSelect={handleSelectClientType}
        />
      </div>
      {separatedLine ? separatedLine : <div className={styles.divider} />}
      <div className={styles.item}>
        {selectedClientType === AppointmentClientOrGroupType.Client ? (
          <DropdownSearchable
            placeholder="Client Name"
            isActive={activeClientName}
            searchable
            searchText={searchText}
            isSplittedList
            selected={selectedClient?._id}
            options={
              showSelectedItemOnFirstOption && selectedClient
                ? [
                    {
                      value: selectedClient._id,
                      label: `${selectedClient.clientProfiles[0].firstName} ${selectedClient.clientProfiles[0].lastName}`
                    },
                    ...clientOptions.filter((clientOptionObj) => clientOptionObj.value !== selectedClient._id)
                  ]
                : clientOptions || []
            }
            disabled={selectedPractitioner === undefined}
            isLoading={
              isClientMinifiedListDataLoading ||
              (isClientMinifiedListDataFetching && clientListCurrentPage === 1 && !searchText)
            }
            onSelect={handleSelectClient}
            setSearchText={debounceSetSearchText}
            hasMoreData={totalClientListPage > clientListCurrentPage || isClientMinifiedListDataLoading}
            isFetchingMore={isClientMinifiedListDataFetching}
            loadMore={loadMoreClientRecord}
            error={inlineCalendarValidation && !selectedClient?._id ? 'Please select client' : ''}
            hideErrorDesc
          />
        ) : (
          <DropdownSearchable
            placeholder="Group Name"
            searchable
            searchText={searchText}
            isSplittedList
            selected={selectedGroup?._id}
            options={groupOptions || []}
            disabled={selectedPractitioner === undefined}
            isLoading={
              isGroupMinifiedListDataLoading ||
              (isGroupMinifiedListDataFetching && groupListCurrentPage === 1 && !searchText)
            }
            onSelect={handleSelectGroup}
            setSearchText={debounceSetSearchText}
            hasMoreData={totalGroupListPage > groupListCurrentPage || isGroupMinifiedListDataLoading}
            isFetchingMore={isGroupMinifiedListDataFetching}
            loadMore={loadMoreGroupRecord}
            error={inlineCalendarValidation && !selectedGroup?._id ? 'Please select group' : ''}
            hideErrorDesc
          />
        )}
      </div>
    </div>
  );
};

export default LinkClientOrGroup;
