import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './Footer.module.scss';
import {
  usePublishCarePathwayMutation,
  useUpdateCarePathwayMutation
} from 'redux/endpoints/clinicianProfileServices/carePathway';
import { massageCarePathwayPayload } from 'redux/carePlan/useCarePathwaySave';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useState } from 'react';
import { Modal, notification } from 'antd';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { clientRecordsApiSlice } from 'redux/endpoints/clinicianProfileServices/client';
import { CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { selectCarePlanEditorSlice } from 'redux/carePlan/carePlanEditorSlice';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

interface FooterProps {
  onCloseModal: () => void;
}

const Footer = ({ onCloseModal }: FooterProps) => {
  const navigate = useNavigate();
  const { CARECOORDINATION } = useRoutesGenerator();
  const { accountId } = useGetAccountId();
  const dispatch = useAppDispatch();
  const carePlanData = useAppSelector(selectCarePlanEditorSlice);
  const [updateCarePathway] = useUpdateCarePathwayMutation();
  const [publishCarePathway] = usePublishCarePathwayMutation();
  const [saveBtnStatus, setSaveBtnStatus] = useState<ButtonStatusType>('');
  const [doubleConfirmModal, setDoubleConfirmModal] = useState(false);

  const handleDraftPlan = async () => {
    const massagePutPayload = massageCarePathwayPayload(carePlanData);
    try {
      setSaveBtnStatus('active');
      const res: any = await updateCarePathway({
        accountId,
        carePathwayId: carePlanData?._id || '',
        payload: massagePutPayload
      });
      if (res?.error && res?.error?.originalStatus !== 200) {
        console.error('Error publish care pathway:', res.error.data.message);
        throw new Error(res.error.data.message);
      }
      setSaveBtnStatus('finished');
      notification.success({
        message: 'Plan is successfully updated.',
        duration: 10,
        closeIcon: <span className="success">OK</span>,
        placement: 'topRight'
      });
      setTimeout(() => {
        setSaveBtnStatus('');
      }, 1000);
    } catch (error: any) {
      console.error('Error updating care pathway:', error);
      notification.error({
        message: (
          <div>
            Something went wrong while trying to updating your care pathway. <br />
            {error?.message}
          </div>
        )
      });
    } finally {
      setSaveBtnStatus('');
    }
  };

  const handlePublishPlan = async () => {
    setDoubleConfirmModal(false);
    const massagePutPayload = massageCarePathwayPayload(carePlanData);
    try {
      setSaveBtnStatus('active');
      const updateRes: any = await updateCarePathway({
        accountId,
        carePathwayId: carePlanData?._id || '',
        payload: massagePutPayload
      });
      if (updateRes?.error && updateRes?.error?.originalStatus !== 200) {
        console.error('Error publish care pathway:', updateRes.error.data.message);
        throw new Error(updateRes.error.data.message);
      }
      const res: any = await publishCarePathway({
        accountId,
        carePathwayId: carePlanData?._id || ''
      });
      if (res?.error && res.error?.originalStatus !== 200) {
        console.error('Error publish care pathway:', res.error.data.message);
        throw new Error(res.error.data.message);
      }
      setSaveBtnStatus('finished');
      notification.success({
        message: 'Plan is successfully published.',
        duration: 10,
        closeIcon: <span className="success">OK</span>,
        placement: 'topRight'
      });
      setTimeout(() => {
        setSaveBtnStatus('');
        dispatch(clientRecordsApiSlice.util.invalidateTags([CPSTagTypes.CarePathway]));
        dispatch(clientRecordsApiSlice.util.invalidateTags([CPSTagTypes.CarePathwayCount]));
        navigate(`${CARECOORDINATION.CAREPATHWAYS}/active`);
        onCloseModal();
      }, 1000);
    } catch (error: any) {
      console.error('Error publish care pathway:', error);
      notification.error({
        message: (
          <div>
            Something went wrong while trying to publish your care pathway. <br /> {error?.message}
          </div>
        )
      });
    } finally {
      setSaveBtnStatus('');
    }
  };

  const validationOfSave = carePlanData._id && carePlanData.clientRecord?._id && carePlanData.leadClinician?._id;

  return (
    <div className={styles.container}>
      {carePlanData._id && (
        <>
          <ButtonAlt
            icon="save"
            variant="outlined"
            status={saveBtnStatus}
            disabled={!validationOfSave}
            onClick={handleDraftPlan}
          >
            Save in DRAFT
          </ButtonAlt>
          <ButtonAlt
            icon="publish"
            status={saveBtnStatus}
            disabled={!validationOfSave}
            onClick={() => {
              if (carePlanData.name === '') {
                notification.error({
                  message: 'Plan title cannot be empty',
                  duration: 2
                });
                return;
              }
              if (carePlanData.appointments.length <= 0) {
                notification.error({
                  message: 'Required at least one appointment to be add into calendar',
                  duration: 2
                });
                return;
              }
              if (
                carePlanData.appointments.some(
                  (appointmentObj) =>
                    appointmentObj.appointmentType?.roomSettings?.required && !appointmentObj?.room?.roomId
                )
              ) {
                notification.error({
                  message: 'Some appointment are required to select room',
                  duration: 2
                });
                return;
              }
              setDoubleConfirmModal(true);
            }}
          >
            Publish Plan (confirm appointments)
          </ButtonAlt>
        </>
      )}
      <Modal
        title={
          <div className={styles.confirmationContainer}>
            <div className={styles.messageContainer}>
              <i className={`material-symbols-outlined ${styles.messageIcon}`}>error</i>
              <div>
                <div>Are you sure you want to Publish this?</div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <ButtonAlt onClick={() => setDoubleConfirmModal(false)} size={'small'} variant={'outlined'}>
                Cancel
              </ButtonAlt>
              <ButtonAlt onClick={handlePublishPlan} size={'small'}>
                OK
              </ButtonAlt>
            </div>
          </div>
        }
        open={doubleConfirmModal}
        footer={null}
        className={styles.confirmationModal}
        bodyStyle={{ display: 'none' }}
        closable={false}
      />
    </div>
  );
};

export default Footer;
