import { notification } from 'antd';
import ModalV2 from 'components/ModalV2/ModalV2';
import Radio from 'components/Radio/Radio';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { EPISODE_STATUS_LABELS, EpisodeInterface, EpisodeStatus } from 'interfaces/Episodes/episodes';
import { FC, useEffect, useState } from 'react';
import { useChangeEpisodeStatusMutation } from 'redux/endpoints/clinicianProfileServices/episode';
import { useSelectChangeEpisodeStatus } from '../../hooks/useSelectChangeStatusOption';
import {
  changeEpisodeStatusOption,
  ChangeEpisodeStatusState,
  CreateEpisodeProps,
  DEFAULT_EPISODE_ORDER,
  defaultEpisode,
  generateStatusList
} from '../../interface/constants';
import styles from './ChangeEpisodeStatusModal.module.scss';
import formStyles from '../EpisodeModal/EpisodeModal.module.scss';
import { Tooltip } from 'react-tooltip';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { useGenerateAvailableDate } from '../../hooks/useGenerateAvailableDate';
import moment from 'moment';
import { EpisodeError, EpisodeFieldNames, UpdateEpisodeField, UpdateEpisodeStatusError } from '../../interface';
import EpisodeStatusBadge from '../EpisodeStatusBadge/EpisodeStatusBadge';
import AppointmentDatePicker from 'pages/Calendar/components/CalendarWithHighlightsT23/components/CalendarView/components/InlineBookingModal/components/AppointmentDatePicker/AppointmentDatePicker';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';
import { isFormValid, validateForm, validateUpdateStatusForm } from '../../utils/validateErrors';
import { CreateNewEPForm } from './CreateNewEPForm/CreateNewEPForm';
import { generateEpisodeId } from '../../utils/generateId';

interface ChangeEpisodeStatusModalProps {
  visible: boolean;
  episode: EpisodeInterface;
  selectedStatus?: EpisodeStatus;
  clientRecord: clientRecordsInterface;
  onClose: () => void;
  currentActiveEpisode?: EpisodeInterface;
}

export const ChangeEpisodeStatusModal: FC<ChangeEpisodeStatusModalProps> = ({
  visible,
  onClose,
  episode,
  selectedStatus,
  clientRecord,
  currentActiveEpisode
}) => {
  const [changeEpisodeStatus] = useChangeEpisodeStatusMutation();

  const [selectedEPStatus, setSelectedEPStatus] = useState<ChangeEpisodeStatusState>(
    ChangeEpisodeStatusState.activeClient
  );

  const [newEpisodeFields, setNewEpisodeFields] = useState<CreateEpisodeProps>({
    ...defaultEpisode,
    status: EpisodeStatus.Active,
    startDate: moment().format(MOMENTJS_FORMAT_DATE)
  });

  const [newEPFormErrors, setNewEPFormErrors] = useState<Omit<EpisodeError, 'status'>>({
    type: '',
    startDate: '',
    endDate: '',
    currentEpisodeStatus: '',
    'reason.medicalReason': '',
    currentEpisodeEndDate: ''
  });

  const [currentEPData, setCurrentEPData] = useState<UpdateEpisodeField>({
    currentEpisodeStatus: selectedStatus
  });

  const [currentEPErrors, setCurrentEPErrors] = useState<UpdateEpisodeStatusError>({
    currentEpisodeStatus: '',
    currentEpisodeEndDate: ''
  });

  const [checkError, setCheckError] = useState(false);

  const { activeEpisodeId, changeOtherEpisode, draftAndPlannedEpisodeOptions } = useSelectChangeEpisodeStatus({
    episode,
    clientRecordId: clientRecord._id
  });

  const [processing, setProcessing] = useState<ButtonStatusType>('');

  useEffect(() => {
    setCurrentEPData({
      currentEpisodeStatus: selectedStatus
    });
  }, [selectedStatus]);

  const { generateEndDateRange: currentEndDateRange } = useGenerateAvailableDate(
    clientRecord,
    moment(episode.startDate).format(MOMENTJS_FORMAT_DATE)
  );

  const generateUpdateEPEndDate = [
    ChangeEpisodeStatusState.activeClient,
    ChangeEpisodeStatusState.closeClient
  ].includes(selectedEPStatus)
    ? moment().format(MOMENTJS_FORMAT_DATE)
    : moment().subtract(1, 'day').format(MOMENTJS_FORMAT_DATE);

  const handleUpdateCurrentEP = (fieldName: keyof EpisodeFieldNames, value: any) => {
    setCurrentEPData({ ...currentEPData, [fieldName]: value as EpisodeStatus });
    setCurrentEPErrors({ ...currentEPErrors, [fieldName]: '' });
  };

  const handleSave = async () => {
    setProcessing('active');
    setCheckError(true);
    try {
      if (!selectedStatus) {
        return;
      }

      if (selectedEPStatus === ChangeEpisodeStatusState.createEpisode) {
        const validationNewEPForm = validateForm(newEpisodeFields);
        if (validationNewEPForm && !isFormValid(validationNewEPForm)) {
          setNewEPFormErrors(validationNewEPForm);
          return;
        }
      }

      if (selectedEPStatus === ChangeEpisodeStatusState.activeEpisode && !activeEpisodeId) {
        return;
      }

      const validationUpdateStatusForm = validateUpdateStatusForm(currentEPData);
      const checkUpdateStatusFormValidation =
        validationUpdateStatusForm &&
        Object.keys(validationUpdateStatusForm).filter(
          (item) => validationUpdateStatusForm[item as keyof UpdateEpisodeStatusError] && item
        ).length <= 0;

      if (validationUpdateStatusForm && !checkUpdateStatusFormValidation) {
        setCurrentEPErrors(validationUpdateStatusForm);
        return;
      }

      const payload = {
        status: currentEPData.currentEpisodeStatus,
        state: selectedEPStatus,
        endDate: moment(currentEPData.currentEpisodeEndDate).format(MOMENTJS_FORMAT_DATE),
        ...(selectedEPStatus === ChangeEpisodeStatusState.activeEpisode && {
          activeEpisodeId
        }),
        ...(selectedEPStatus === ChangeEpisodeStatusState.createEpisode && {
          episode: {
            ...newEpisodeFields,
            startDate: moment(newEpisodeFields.startDate).format(MOMENTJS_FORMAT_DATE),
            episodeId: generateEpisodeId(
              clientRecord.episodeSummary ? clientRecord.episodeSummary.order + 1 : DEFAULT_EPISODE_ORDER
            )
          }
        })
      };

      await changeEpisodeStatus({ clientRecordId: episode.clientRecordId, payload, episodeId: episode._id }).unwrap();

      setProcessing('finished');
      notification.success({
        message: 'Episode updated.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      onClose();
    } catch (error) {
      notification.error({
        message: 'Something went wrong while changing status',
        duration: 2
      });
      setCheckError(false);
    } finally {
      setProcessing('');
    }
  };

  const isEPisToday =
    !!currentActiveEpisode?.startDate &&
    moment(currentActiveEpisode.startDate).format(MOMENTJS_FORMAT_DATE) === moment().format(MOMENTJS_FORMAT_DATE);

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={visible}
      title={`You are changing the status of Episode #${episode.order}`}
      onModalClose={onClose}
      titleIcon="report"
      iconClassNames={styles.icon}
    >
      <div className={styles.optionContainer}>
        <div className={styles.header}>Do you want to</div>
        <div className={styles.option}>
          <Radio
            labelClassName={styles.label}
            value={selectedEPStatus}
            onChange={(e) => setSelectedEPStatus(e.target.value as ChangeEpisodeStatusState)}
            name={'changeStatusType'}
            vertical
            options={[
              {
                label: changeEpisodeStatusOption[ChangeEpisodeStatusState.activeClient],
                value: ChangeEpisodeStatusState.activeClient
              }
            ]}
          />
          <Radio
            labelClassName={styles.label}
            value={selectedEPStatus}
            onChange={(e) => setSelectedEPStatus(e.target.value as ChangeEpisodeStatusState)}
            name={'changeStatusType'}
            options={[
              {
                label: changeEpisodeStatusOption[ChangeEpisodeStatusState.closeClient],
                value: ChangeEpisodeStatusState.closeClient
              }
            ]}
          />
          <div
            className={styles.radioWrapper}
            data-tooltip-id={isEPisToday ? 'tooltip-no-newEP1' : 'tooltip-no-option'}
          >
            <Radio
              labelClassName={styles.label}
              value={selectedEPStatus}
              onChange={(e) => setSelectedEPStatus(e.target.value as ChangeEpisodeStatusState)}
              name={'changeStatusType'}
              options={[
                {
                  label: changeEpisodeStatusOption[ChangeEpisodeStatusState.activeEpisode],
                  value: ChangeEpisodeStatusState.activeEpisode,
                  disabled: draftAndPlannedEpisodeOptions.length <= 0 || isEPisToday
                }
              ]}
            />
            {draftAndPlannedEpisodeOptions.length <= 0 && (
              <Tooltip id={'tooltip-no-option'} className={styles.tooltip}>
                There is no other PLANNED and DRAFT care episode available.
              </Tooltip>
            )}
            {isEPisToday && (
              <Tooltip id={'tooltip-no-newEP1'} className={styles.tooltip}>
                There is already an episode covering this date, episodes can not overlap.
              </Tooltip>
            )}
          </div>
          <div className={styles.radioWrapper} data-tooltip-id={'tooltip-no-newEP'}>
            <Radio
              labelClassName={styles.label}
              value={selectedEPStatus}
              onChange={(e) => setSelectedEPStatus(e.target.value as ChangeEpisodeStatusState)}
              name={'changeStatusType'}
              options={[
                {
                  label: changeEpisodeStatusOption[ChangeEpisodeStatusState.createEpisode],
                  value: ChangeEpisodeStatusState.createEpisode,
                  disabled: isEPisToday
                }
              ]}
            />
            {selectedEPStatus === ChangeEpisodeStatusState.createEpisode && (
              <CreateNewEPForm
                episodeFields={newEpisodeFields}
                onChangeNewFormField={setNewEpisodeFields}
                errors={newEPFormErrors}
                setErrors={setNewEPFormErrors}
                clientRecord={clientRecord}
              />
            )}
            {isEPisToday && (
              <Tooltip id={`tooltip-no-newEP`} className={styles.tooltip}>
                There is already an episode covering this date, episodes can not overlap.
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className={styles.updateCurrentStatus}>
        <div className={`material-icons-outlined ${styles.icon}`}>info</div>
        <div className={styles.updateContent}>
          <div className={styles.updateDesc}>
            {[ChangeEpisodeStatusState.activeClient, ChangeEpisodeStatusState.closeClient].includes(selectedEPStatus)
              ? `Please update the status accordingly:`
              : `A single client profile can only support one ACTIVE or ON HOLD care episode. You currently have an ${EPISODE_STATUS_LABELS[
                  episode.status
                ].toUpperCase()} episode, please update the status accordingly:`}
          </div>
          <div className={styles.updateActionWrapper}>
            <div className={styles.updateCurrentDescWrapper}>
              <div className={styles.updateActionTitle}>
                Episode #{episode.order} | {episode.creatorName} started on{' '}
                {moment(episode.startDate).format('DD MMM YYYY')}
              </div>
              <div className={styles.updateActionType}>Episode Type: {episode.type}</div>
            </div>
            <div className={styles.updateActionContent}>
              <div className={styles.updateActionDropdownWrapper}>
                <div className={styles.updateActionStatus}>UPDATE EPISODE #{episode.order} STATUS</div>
                <div className={formStyles.container}>
                  <div className={formStyles.dropdownWrapper}>
                    <DropdownSearchable
                      error={currentEPErrors.currentEpisodeStatus}
                      options={generateStatusList([
                        EpisodeStatus.Finished,
                        EpisodeStatus.Cancelled,
                        EpisodeStatus.Error
                      ])}
                      hideActiveLine
                      selected={currentEPData?.currentEpisodeStatus}
                      onSelect={(value) => handleUpdateCurrentEP('currentEpisodeStatus', value)}
                      placeholder={'Change status to'}
                      hideErrorDesc
                      DisplayLabelChildren={(displayProps) => <EpisodeStatusBadge status={displayProps.props.value} />}
                      DropdownItemChildren={(itemProps) => <EpisodeStatusBadge status={itemProps.props.value} />}
                    />
                  </div>
                  <div className={formStyles.dropdownWrapper}>
                    <div className={formStyles.endDateWrapper}>
                      <div className={`material-icons-outlined ${formStyles.endDateIcon}`}>info</div>
                      <AppointmentDatePicker
                        error={currentEPErrors.currentEpisodeEndDate}
                        placeholder="End date"
                        selectedDate={
                          currentEPData?.currentEpisodeEndDate
                            ? new Date(currentEPData.currentEpisodeEndDate)
                            : undefined
                        }
                        onSelect={(value) => handleUpdateCurrentEP('currentEpisodeEndDate', value)}
                        hideErrorDesc
                        disabled={!currentEPData?.currentEpisodeStatus}
                        min={currentEndDateRange()[0].start}
                        max={generateUpdateEPEndDate}
                        valid={currentEndDateRange()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {selectedEPStatus === ChangeEpisodeStatusState.activeEpisode && (
                <div className={styles.changeEPActionDropdownWrapper}>
                  <div className={styles.updateActionStatus}>SET active CARE EPISODE</div>
                  {changeOtherEpisode(checkError)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonAlt onClick={handleSave} status={processing}>
          Save Changes
        </ButtonAlt>
      </div>
    </ModalV2>
  );
};
