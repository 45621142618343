import styles from './DropdownButton.module.scss';
import MenuItems from './MenuItems';

export interface MenuItemProps {
  title: string;
  submenu?: MenuItemProps[];
  onClick?: () => void;
}

interface MenuProps {
  list: MenuItemProps[];
}

const DropdownButton = ({ list }: MenuProps) => {
  return (
    <ul className={styles.dropdownContainer}>
      {list.map((menu: MenuItemProps, index: number) => {
        return <MenuItems items={menu} key={index} depthLevel={0} />;
      })}
    </ul>
  );
};

export default DropdownButton;
