import CheckBox from 'components/CheckBox/CheckBox';
import SearchBar from 'components/SearchBar/SearchBar';
import { useState } from 'react';

import { MBS_CODE_GROUP, MBS_CODE_INDIVIDUAL } from '../../../../../mbsItems';
import styles from './MbsCodeSelection.module.scss';

interface MbsCodeSelectionProps {
  type: 'individual' | 'group';
  selectedCodes: string[];
  setSelectedCodes: (values: string[]) => void;
}

const MbsCodeSelection = ({ type, selectedCodes, setSelectedCodes }: MbsCodeSelectionProps) => {
  const MBS_CODE_LIST = type === 'individual' ? MBS_CODE_INDIVIDUAL : MBS_CODE_GROUP;
  const MBS_CODES = MBS_CODE_LIST.sort((prev, curr) => Number(prev.mbsCode) - Number(curr.mbsCode)).map(
    ({ mbsCode }) => ({
      id: mbsCode,
      label: mbsCode
    })
  );
  const [codeList, setCodeList] = useState(MBS_CODES);

  const onChange = (checked: boolean, id: string) => {
    if (checked) {
      setSelectedCodes([...selectedCodes, id]);
    } else {
      const newSelectedCodes = selectedCodes.filter((code) => code !== id);
      setSelectedCodes(newSelectedCodes);
    }
  };

  const onFilter = (value: string) => {
    if (value === '') {
      setCodeList(MBS_CODES);
    } else {
      setCodeList(MBS_CODES.filter(({ label, id }) => selectedCodes.includes(id) || label.includes(value)));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <div className={styles.title}>{type === 'individual' ? 'INDIVIDUAL' : 'GROUP'}</div>
          <div className={selectedCodes.length > 0 ? styles.hiddenError : styles.error}>
            Must select at least one MBS code
          </div>
        </div>
        <SearchBar
          containerClassName={styles.searchBar}
          placeholder={'Search'}
          onSearch={onFilter}
          withSearchButton
          searchOnChange
        />
      </div>
      <div className={styles.selectionContainer}>
        {codeList.map(({ label, id }, index) => (
          <div className={styles.codeContainer}>
            <CheckBox
              key={index}
              id={`${type}-${index.toString()}`}
              label={label}
              value={selectedCodes.includes(id)}
              onChange={(e) => onChange(e.target.checked, id)}
              disabled={!selectedCodes.includes(id) && selectedCodes.length > 4}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MbsCodeSelection;
