import moment from 'moment';

import FlexBox from 'components/FlexBox/FlexBox';
import { Invoice } from 'pages/Invoices/interface';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';

import InvoiceField from '../InvoiceField/InvoiceField';
import styles from '../../InvoiceViewWithTemplate.module.scss';
import { config } from 'config/config';

const InvoiceInfoSection = ({ invoice }: { invoice: Invoice }) => {
  const { title, invoiceId, issueDate, dueDate, recipients, description, template } = invoice;

  const invoiceInfo = [
    {
      label: 'Invoice ID',
      value: invoiceId
    },
    {
      label: 'Date of issue',
      value: moment(issueDate).format(MOMENTJS_DATE_FORMAT)
    },
    {
      label: 'Due date',
      value: moment(dueDate).format(MOMENTJS_DATE_FORMAT)
    }
  ];

  const recipientsInfo = recipients?.map((item) => [
    {
      label: 'Addressed To',
      show: true,
      value: item.name
    },
    {
      label: 'Address',
      show: template?.recipientAddress,
      value: item.address
    },
    {
      label: 'Email',
      show: template?.recipientEmail,
      value: item.email
    },
    {
      label: 'Phone',
      show: template?.recipientMobileNumber,
      value: item.mobileNumber
    },
    {
      label: 'Reference',
      show: template?.recipientReference,
      value: item.reference
    },
    {
      label: config.practiceSettings.registrationNumberLabel,
      show: template?.recipientRegistrationNumber,
      value: item.registrationNumber
    }
  ]);

  return (
    <FlexBox direction="column" spacing={20} className={styles.padding20}>
      <span className={styles.invoiceHeading}>{title}</span>
      <FlexBox direction="row" spacing={20}>
        <FlexBox direction="column" className={styles.width350} spacing={4}>
          {invoiceInfo.map((item, index) => (
            <InvoiceField key={index} label={item.label} value={item.value} />
          ))}
        </FlexBox>
        {recipientsInfo && (
          <FlexBox direction="column" className={styles.width350} spacing={4}>
            {recipientsInfo.map((recipient) =>
              recipient
                .filter(({ show }) => show)
                .map((item, index) => <InvoiceField key={index} label={item.label} value={item.value} />)
            )}
          </FlexBox>
        )}
      </FlexBox>
      {description && <div className={styles.textAreaContent} dangerouslySetInnerHTML={{ __html: description }} />}
    </FlexBox>
  );
};

export default InvoiceInfoSection;
