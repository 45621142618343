import classNames from 'classnames';
import styles from './ErrorBanner.module.scss';
import { ReactNode } from 'react';

interface ErrorBannerProps {
  className?: string;
  errorMessage: ReactNode;
  icon?: string;
  iconColor?: string;
}

const ErrorBanner = ({ className, errorMessage, icon, iconColor }: ErrorBannerProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <i className="material-icons-outlined" style={{ color: iconColor || '' }}>
        {icon || 'error_outline'}
      </i>
      {errorMessage}
    </div>
  );
};

export default ErrorBanner;
