import styles from './RightSidebar.module.scss';
import classNames from 'classnames';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ReactNode, useRef } from 'react';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

const RightSidebar = ({ title, setHide, children }: { title: string; setHide: () => void; children: ReactNode }) => {
  let controlNode = useRef<HTMLDivElement>(null);

  const clickOutsideHandler = (event: Event | undefined) => {
    if (controlNode.current && !controlNode.current.contains((event?.target as Node) || null)) {
      setHide();
    }
    return true;
  };

  useOnClickOutside(controlNode, clickOutsideHandler);

  return (
    <div ref={controlNode} className={classNames(styles.container, styles.open)}>
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
        <ButtonAlt className={styles.closeBtn} icon="close" variant="text" onClick={setHide} />
      </div>

      {children}
    </div>
  );
};

export default RightSidebar;
