import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import styles from './FilterWrapper.module.scss';
import { useGetMinifiedClientRecordQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useCallback, useMemo, useState } from 'react';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { FilterCheckListChangeValue, FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectAdditionalClinicians,
  selectCarePlanEditorSlice,
  selectClient,
  selectLeadClinician,
  selectTemplate,
  setAdditionalClinicians,
  setAppointmentTypeItems,
  setCarePlanData,
  setClient,
  setLeadClinician,
  setPackage,
  setTemplate
} from 'redux/carePlan/carePlanEditorSlice';
import { debounce } from 'lodash';
import { useGetPractitionerListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { massageCarePathwayPayload } from 'redux/carePlan/useCarePathwaySave';
import {
  useCreateCarePathwayMutation,
  useUpdateCarePathwayItemsMutation,
  useUpdateCarePathwayMutation
} from 'redux/endpoints/clinicianProfileServices/carePathway';
import { Modal, notification } from 'antd';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useGetCarePathwayTemplatesQuery } from 'redux/endpoints/clinicianProfileServices/carePathwayTemplate';
import { CarePathway } from 'interfaces/CarePathway/CarePathway';
import { useGetPackageListQuery } from 'redux/endpoints/scheduleServices/package';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { funderType, PackageFunderQuotaStatus } from 'interfaces/Packages/packages';

const PER_PAGE = 30;

const FilterWrapper = () => {
  const dispatch = useAppDispatch();
  const clientRecord = useAppSelector(selectClient);
  const leadClinician = useAppSelector(selectLeadClinician);
  const additionalClinicians = useAppSelector(selectAdditionalClinicians);
  const selectedTemplate = useAppSelector(selectTemplate);

  const carePlanData = useAppSelector(selectCarePlanEditorSlice);
  const { isPackageEnabled } = useGetFeatureToggle();
  const { accountId } = useGetAccountId();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalBtnStatus, setConfirmModalBtnStatus] = useState<ButtonStatusType>('');

  const [clientListCurrentPage, setClientListCurrentPage] = useState<number>(1);
  const [clientSearchText, setClientSearchText] = useState<string>('');

  const [staffOwnerCurrentPage, setStaffOwnerCurrentPage] = useState<number>(1);
  const [staffOwnerSearchText, setStaffOwnerSearchText] = useState('');

  const [additionalStaffsCurrentPage, setAdditionalStaffsCurrentPage] = useState<number>(1);
  const [additionalStaffsSearchText, setAdditionalStaffsSearchText] = useState('');

  const [createPathwayStatus, setCreatePathwayStatus] = useState<ButtonStatusType>('');

  const [packageListCurrentPage, setPackageListCurrentPage] = useState<number>(1);

  const [selectedTemplateMenu, setSelectedTemplateMenu] = useState<FilterCheckListChangeValue>();

  const onChangeClientFilter = ({ item }: FilterCheckListChangeValue) => {
    dispatch(setClient(item));
  };

  const {
    data: clientListData,
    isLoading: isClientListDataLoading,
    isFetching: isClientListDataFetching
  } = useGetMinifiedClientRecordQuery(
    {
      accountId,
      params: {
        page: clientListCurrentPage,
        perPage: PER_PAGE,
        recordStatus: 'active',
        ...(clientSearchText && {
          searchValue: clientSearchText,
          searchBy: SearchFilterEnum.FirstOrLastName
        })
      }
    },
    {}
  );

  const {
    data: staffData,
    isLoading: isStaffDataLoading,
    isFetching: isStaffDataFetching
  } = useGetPractitionerListQuery({
    accountId,
    id: 'staffOwner',
    params: {
      page: staffOwnerCurrentPage,
      perPage: PER_PAGE,
      status: 'active',
      searchValue: staffOwnerSearchText,
      accessRights: [AccessRight.Admin, AccessRight.User]
    }
  });

  const {
    data: additionalStaffData,
    isLoading: isAdditionalStaffDataLoading,
    isFetching: isAdditionalStaffDataFetching
  } = useGetPractitionerListQuery({
    accountId,
    id: 'clinicians',
    params: {
      page: additionalStaffsCurrentPage,
      perPage: PER_PAGE,
      status: 'active',
      searchValue: additionalStaffsSearchText,
      accessRights: [AccessRight.Admin, AccessRight.User]
    }
  });

  const {
    data: templateListData,
    isLoading: isTemplateListLoading,
    isFetching: isTemplateListFetching
  } = useGetCarePathwayTemplatesQuery({
    accountId
  });

  const packageListPerPage = 50;

  const {
    data: packageListData,
    isLoading: packageListLoading,
    isFetching: packageListFetching
  } = useGetPackageListQuery(
    {
      accountId: accountId,
      params: {
        page: packageListCurrentPage,
        perPage: packageListPerPage,
        expandAppointmentTypes: '1'
      },
      infiniteLoad: true
    },
    { skip: !accountId }
  );

  const totalPackageListPage = packageListData?.paging
    ? Math.ceil(packageListData.paging.totalItems / packageListPerPage)
    : 1;

  const loadMorePackageItem = useCallback(() => {
    const page = packageListData?.paging.page || 1;
    if (!packageListFetching && page < totalPackageListPage) {
      setPackageListCurrentPage(page + 1);
    }
  }, [totalPackageListPage, packageListFetching, packageListData]);

  const [updateCarePathwayItem] = useUpdateCarePathwayItemsMutation();
  const [updateCarePathway] = useUpdateCarePathwayMutation();

  const staffOwnerList: FilterCheckListItem[] = useMemo(() => (staffData ? staffData.clinicians : []), [staffData]);

  const onChangeLeadClinicianFilter = ({ item }: FilterCheckListChangeValue) => {
    const lookIfClinicianHaveAppointment = carePlanData.appointments.some(
      (appointmentObj) => appointmentObj?.clinicianId === leadClinician?._id
    );
    if (lookIfClinicianHaveAppointment) {
      notification.error({
        message: 'Prior to modifying staff owner, kindly cancel the associated appointment.',
        duration: 2
      });
    } else {
      // remove from additional staff if selected
      if (additionalClinicians.find((value) => item?._id === value._id)) {
        const getClinicianInfo = staffOwnerList.find((clinicianObj) => clinicianObj._id === item?._id);
        dispatch(setLeadClinician(getClinicianInfo));
        dispatch(setAdditionalClinicians(additionalClinicians.filter((value) => item?._id !== value._id)));
      } else {
        dispatch(setLeadClinician(item));
      }
    }
  };

  const onChangeAdditionalStaffsFilter = (
    newAdditionalClinicians: FilterCheckListItem[],
    selectedClinicianId?: string
  ) => {
    const checkIfHaveAppointment = carePlanData.appointments.some(
      (appointmentObj) => appointmentObj.clinicianId === selectedClinicianId
    );
    if (checkIfHaveAppointment) {
      notification.error({
        message: 'Prior to modifying clinician, kindly cancel the associated appointment.',
        duration: 2
      });
    } else {
      dispatch(setAdditionalClinicians(newAdditionalClinicians));
    }
  };

  const onChangeTemplateFilter = async ({ item, subMenuItem }: FilterCheckListChangeValue) => {
    const checkIfHaveAppointment = carePlanData.appointments.length > 0;
    if (checkIfHaveAppointment) {
      notification.error({
        message: 'Prior to modifying template, kindly cancel the associated appointment.',
        duration: 2
      });
    } else {
      setSelectedTemplateMenu({ item, subMenuItem });
      if (!carePlanData._id) {
        const findAppointmentInfoFromTemplate = templateListData?.find((templateObj) => templateObj._id === item?._id);
        const findPackageData = packageListData?.packages.find((packageObj) => packageObj._id === item?._id);

        dispatch(
          setTemplate({
            ...item,
            ...(subMenuItem && {
              selectedSubMenu: subMenuItem
            })
          })
        );

        if (item?.itemFlag === 'packageItem') {
          const getFunderData = findPackageData?.funders.find((funderObj) => funderObj.funderId === subMenuItem?._id);
          dispatch(
            setPackage({
              id: item._id,
              funder: {
                type: getFunderData?.type,
                funderId: getFunderData?.funderId,
                name: getFunderData?.name
              }
            })
          );
        } else {
          dispatch(setPackage({}));
        }

        const filterAppointmentType =
          item?.itemFlag === 'packageItem'
            ? findPackageData?.appointmentTypes?.map((apptTypeObj) => ({
                appointmentType: apptTypeObj,
                appointmentTypeId: apptTypeObj._id || ''
              }))
            : findAppointmentInfoFromTemplate?.items;

        dispatch(setAppointmentTypeItems(filterAppointmentType || []));
      } else {
        setConfirmModal(true);
      }
    }
  };

  const handleConfirmChangingTemplate = async () => {
    setConfirmModalBtnStatus('active');
    const item = selectedTemplateMenu?.item;
    const subMenuItem = selectedTemplateMenu?.subMenuItem;
    const findAppointmentInfoFromTemplate = templateListData?.find((templateObj) => templateObj._id === item?._id);
    const findPackageData = packageListData?.packages.find((packageObj) => packageObj._id === item?._id);
    const getFunderData = findPackageData?.funders.find((funderObj) => funderObj.funderId === subMenuItem?._id);

    const templateData: FilterCheckListItem = {
      ...item,
      _id: item?._id || '',
      name: item?.name || '',
      ...(subMenuItem && {
        selectedSubMenu: subMenuItem
      })
    };

    const packageData: CarePathway['package'] =
      item?.itemFlag === 'packageItem'
        ? {
            id: item?._id || '',
            funder: {
              type: getFunderData?.type || funderType.Default,
              funderId: getFunderData?.funderId || '',
              name: getFunderData?.name || ''
            }
          }
        : undefined;

    dispatch(setTemplate(templateData));
    dispatch(setPackage(packageData || {}));

    const filterAppointmentType =
      item?.itemFlag === 'packageItem'
        ? findPackageData?.appointmentTypes?.map((apptTypeObj) => ({
            appointmentType: apptTypeObj,
            appointmentTypeId: apptTypeObj._id || ''
          }))
        : findAppointmentInfoFromTemplate?.items;

    dispatch(setAppointmentTypeItems(filterAppointmentType || []));

    const massageItem =
      filterAppointmentType?.map((itemObj) => ({
        appointmentTypeId: itemObj.appointmentTypeId || ''
      })) || [];

    const { package: currentPackageData, ...restCarePlanData } = carePlanData;
    const updatePayload = {
      ...restCarePlanData,
      template: templateData,
      ...(packageData && { package: packageData })
    };

    try {
      const massagePutPayload = massageCarePathwayPayload(updatePayload, true);
      await updateCarePathway({
        accountId,
        carePathwayId: carePlanData?._id || '',
        payload: massagePutPayload
      });

      const result = await updateCarePathwayItem({
        accountId,
        carePathwayId: carePlanData?._id || '',
        payload: massageItem
      });

      if ('data' in result) {
        dispatch(setAppointmentTypeItems(result.data || []));
      }

      setConfirmModalBtnStatus('finished');
    } catch (error) {
      console.error('Error changing care pathway template:', error);
      setConfirmModalBtnStatus('');
    } finally {
      setConfirmModal(false);
      setConfirmModalBtnStatus('');
    }
  };

  const handleOnClearTemplate = useCallback(async () => {
    dispatch(setPackage({}));
    dispatch(setTemplate(undefined));
    try {
      const massagePutPayload = massageCarePathwayPayload(
        {
          ...carePlanData,
          package: undefined
        },
        true
      );
      await updateCarePathway({
        accountId,
        carePathwayId: carePlanData?._id || '',
        payload: massagePutPayload
      });

      await updateCarePathwayItem({
        accountId,
        carePathwayId: carePlanData?._id || '',
        payload: []
      });

      dispatch(setAppointmentTypeItems([]));

      setConfirmModalBtnStatus('finished');
    } catch (error) {
      console.error('Error when clear care pathway template:', error);
      setConfirmModalBtnStatus('');
    } finally {
      setConfirmModal(false);
      setConfirmModalBtnStatus('');
    }
  }, [carePlanData, accountId, updateCarePathway, updateCarePathwayItem, dispatch]);

  const clientListFilter: FilterCheckListItem[] = useMemo(
    () =>
      clientListData
        ? clientListData.clientRecords.map((item) => ({
            name: `${item.clientProfiles[0].firstName} ${item.clientProfiles[0].lastName}`,
            _id: item._id
          }))
        : [],
    [clientListData]
  );

  const additionalStaffsList: FilterCheckListItem[] = useMemo(
    () =>
      additionalStaffData
        ? additionalStaffData.clinicians.filter((item) => {
            return leadClinician?._id !== item._id;
          })
        : [],
    [additionalStaffData, leadClinician]
  );

  const templateList: FilterCheckListItem[] = useMemo(
    () =>
      templateListData
        ? templateListData
            .filter((templateObj) => templateObj.status === 'published')
            .map((item) => ({
              name: item.name,
              _id: item._id
            }))
        : [],
    [templateListData]
  );

  const packageList: FilterCheckListItem[] = useMemo(
    () =>
      packageListData
        ? packageListData.packages.map((item) => ({
            name: item.name,
            _id: item._id || '',
            itemFlag: 'packageItem',
            subMenu: item.funders.map((funderItem) => ({
              name: funderItem.name,
              _id: funderItem.funderId || '',
              disabled: funderItem.quotaStatus !== PackageFunderQuotaStatus.Available
            }))
          }))
        : [],
    [packageListData]
  );

  const templateListWithPackage: FilterCheckListItem[] = useMemo(
    () => [
      {
        section: 'CARE PATHWAY',
        _id: '',
        name: ''
      },
      ...templateList,
      {
        section: 'PACKAGE',
        _id: '',
        name: ''
      },
      ...packageList
    ],
    [templateList, packageList]
  );

  const totalClientListPage = useMemo(
    () => (clientListData?.paging ? Math.ceil(clientListData.paging.totalItems / PER_PAGE) : 1),
    [clientListData]
  );
  const totalStaffPage = useMemo(
    () => (staffData?.paging ? Math.ceil(staffData.paging.totalItems / PER_PAGE) : 1),
    [staffData]
  );

  const debounceSetClientSearchText = useMemo(
    () =>
      debounce((value) => {
        setClientSearchText(value);
        setClientListCurrentPage(1);
      }, 1000),
    []
  );
  const debounceSetStaffOwnerSearchText = useMemo(
    () =>
      debounce((value) => {
        setStaffOwnerSearchText(value);
        setStaffOwnerCurrentPage(1);
      }, 1000),
    []
  );
  const debounceSetAdditionalStaffsSearchText = useMemo(
    () =>
      debounce((value) => {
        setAdditionalStaffsSearchText(value);
        setAdditionalStaffsCurrentPage(1);
      }, 1000),
    []
  );

  const [createCarePathway] = useCreateCarePathwayMutation();

  const handleBuildingPlan = async () => {
    try {
      setCreatePathwayStatus('active');
      const massagePostPayload = massageCarePathwayPayload(carePlanData);
      const response = await createCarePathway({ accountId, payload: massagePostPayload });

      if ('data' in response) {
        const responseData: CarePathway = response.data;
        dispatch(setCarePlanData(responseData));
        setCreatePathwayStatus('finished');
        setTimeout(() => {
          setCreatePathwayStatus('');
          setClientSearchText('');
          setStaffOwnerSearchText('');
          setAdditionalStaffsSearchText('');
        }, 1000);
      }
    } catch (error: any) {
      console.error('Error create care plan:', error.message);
      setCreatePathwayStatus('');
      notification.error({
        message: 'Care Pathway failed to create.',
        duration: 2
      });
    } finally {
      setCreatePathwayStatus('');
    }
  };

  const resetClient = useCallback(() => {
    if (carePlanData.appointments.length === 0) {
      dispatch(setClient(undefined));
    } else {
      notification.error({
        message: 'Prior to modifying client, kindly cancel all the appointment.',
        duration: 2
      });
    }
  }, [dispatch, carePlanData.appointments]);

  const clearLeadClinicianSelect = () => {
    const lookIfClinicianHaveAppointment = carePlanData.appointments.some(
      (appointmentObj) => appointmentObj?.clinicianId === leadClinician?._id
    );
    if (lookIfClinicianHaveAppointment) {
      notification.error({
        message: 'Prior to modifying staff owner, kindly cancel the associated appointment.',
        duration: 2
      });
    } else {
      dispatch(setLeadClinician(undefined));
    }
  };

  const resetAdditionalClinician = useCallback(() => {
    const lookIfAdditionalClinicianHaveAppointment = carePlanData.appointments.some((appointmentObj) =>
      additionalClinicians.some((clinicianObj) => clinicianObj._id === appointmentObj?.clinicianId)
    );
    if (lookIfAdditionalClinicianHaveAppointment) {
      notification.error({
        message: 'Prior to modifying clinician list, kindly cancel the associated appointment.',
        duration: 2
      });
    } else {
      dispatch(setAdditionalClinicians([]));
    }
  }, [dispatch, carePlanData.appointments, additionalClinicians]);

  const selectedTemplateWithInfo = useMemo(() => {
    const templateInfo = templateListWithPackage.find((tempObj) => tempObj._id === selectedTemplate?._id);
    return {
      ...templateInfo,
      _id: templateInfo?._id || '',
      name: templateInfo?.name || '',
      selectedSubMenu: {
        _id: selectedTemplate?.selectedSubMenu?._id || '',
        name: selectedTemplate?.selectedSubMenu?.name || ''
      }
    };
  }, [templateListWithPackage, selectedTemplate]);

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.label}>SETUP</div>
      <div className={styles.filterGroup}>
        <FilterDropdown
          id="client"
          icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
          menuItems={clientListFilter}
          onChangeItem={onChangeClientFilter}
          selectedFilterList={clientRecord ? [clientRecord] : []}
          loading={isClientListDataLoading}
          hasMoreData={totalClientListPage > clientListCurrentPage || isClientListDataFetching}
          isFetchingMore={isClientListDataFetching}
          loadMore={() => {
            if (!isClientListDataFetching && clientListCurrentPage <= totalClientListPage) {
              setClientListCurrentPage(clientListCurrentPage + 1);
            }
          }}
          setSearchText={debounceSetClientSearchText}
          onClearFilter={resetClient}
          multiple={false}
          baseDropdownClass=""
          showSearchIcon
          searchable
          enableSelectedBackground
        >
          Client
        </FilterDropdown>
        <FilterDropdown
          id="staffOwner"
          icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
          menuItems={staffOwnerList}
          onChangeItem={onChangeLeadClinicianFilter}
          selectedFilterList={leadClinician ? [leadClinician] : []}
          loading={isStaffDataLoading}
          hasMoreData={totalStaffPage > staffOwnerCurrentPage || isStaffDataFetching}
          isFetchingMore={isStaffDataFetching}
          loadMore={() => {
            if (!isStaffDataFetching && staffOwnerCurrentPage <= totalStaffPage) {
              setStaffOwnerCurrentPage(staffOwnerCurrentPage + 1);
            }
          }}
          onClearFilter={clearLeadClinicianSelect}
          setSearchText={debounceSetStaffOwnerSearchText}
          multiple={false}
          baseDropdownClass=""
          showSearchIcon
          searchable
          enableSelectedBackground
        >
          Staff Owner
        </FilterDropdown>
        <FilterDropdown
          id="additionalStaff"
          icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
          menuItems={additionalStaffsList}
          onChangeItem={onToggleFilter(additionalStaffsList, additionalClinicians, onChangeAdditionalStaffsFilter)}
          selectedFilterList={additionalClinicians}
          loading={isAdditionalStaffDataLoading}
          hasMoreData={totalStaffPage > additionalStaffsCurrentPage || isAdditionalStaffDataFetching}
          isFetchingMore={isStaffDataFetching}
          loadMore={() => {
            if (!isStaffDataFetching && additionalStaffsCurrentPage <= totalStaffPage) {
              setAdditionalStaffsCurrentPage(additionalStaffsCurrentPage + 1);
            }
          }}
          onClearFilter={resetAdditionalClinician}
          setSearchText={debounceSetAdditionalStaffsSearchText}
          baseDropdownClass=""
          showSearchIcon
          searchable
          enableSelectedBackground
        >
          Additional Staff
        </FilterDropdown>
        <FilterDropdown
          id="template"
          icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
          menuItems={isPackageEnabled ? templateListWithPackage : templateList}
          onChangeItem={onChangeTemplateFilter}
          selectedFilterList={selectedTemplate ? [selectedTemplateWithInfo] : []}
          loading={isTemplateListLoading}
          loadMore={isPackageEnabled ? loadMorePackageItem : undefined}
          hasMoreData={
            isPackageEnabled
              ? (packageListData?.packages.length || 0) < (packageListData?.paging.totalItems || 0) ||
                packageListFetching ||
                packageListLoading
              : false
          }
          isFetchingMore={isTemplateListFetching}
          onClearFilter={handleOnClearTemplate}
          multiple={false}
          baseDropdownClass=""
          showSearchIcon
          searchable
          enableSelectedBackground
        >
          Template
        </FilterDropdown>
        {!carePlanData._id && (
          <div className={styles.buttonWrapper}>
            <ButtonAlt
              size={'medium'}
              className={styles.button}
              onClick={handleBuildingPlan}
              status={createPathwayStatus}
              disabled={!clientRecord?._id || !leadClinician?._id}
            >
              Start building plan
            </ButtonAlt>
          </div>
        )}
        <Modal
          title={
            <div className={styles.confirmationContainer}>
              <div className={styles.messageContainer}>
                <i className={`material-symbols-outlined ${styles.messageIcon}`}>error</i>
                <div>
                  <div>Are you sure you wish to modify/apply this template?</div>
                  <div>Modifying the template will reset all your current appointment types in the list.</div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <ButtonAlt onClick={() => setConfirmModal(false)} size={'small'} variant={'outlined'}>
                  Cancel
                </ButtonAlt>
                <ButtonAlt status={confirmModalBtnStatus} onClick={handleConfirmChangingTemplate} size={'small'}>
                  OK
                </ButtonAlt>
              </div>
            </div>
          }
          open={confirmModal}
          footer={null}
          className={styles.confirmationModal}
          bodyStyle={{ display: 'none' }}
          closable={false}
        />
      </div>
    </div>
  );
};

export default FilterWrapper;
