import { useEffect, useState } from 'react';

import Button from 'components/v2/Button/Button';

import styles from './PreviewShareReportBox.module.scss';
import ExpandableContent from 'components/ExpandableContent/ExpandableContent';
import ShareSideBox from '../components/ShareSideBox/ShareSideBox';
import moment from 'moment';
import * as yup from 'yup';
import Badge from 'components/Badge/Badge';
import classnames from 'classnames';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import { notification } from 'antd';
import { Report, ReportAccessor } from 'interfaces/Reports/report';
import { putRevokeAccessor, putUnpublish } from 'utils/http/DocumentService/Reports/reports';
import { useNavigate } from 'react-router-dom';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

const emailSchema = yup.array().of(yup.string().email());

interface PreviewShareReportBoxProps {
  data: Report;
  token: string;
  onSendReport: any;
  sendInvoiceButtonStatus: '' | 'active' | 'finished';
}

const sortRecipient = (list: ReportAccessor[]) => list.sort((_, b) => (b.isClient ? 1 : -1));

const PreviewShareReportBox = ({ data, token, onSendReport, sendInvoiceButtonStatus }: PreviewShareReportBoxProps) => {
  const navigate = useNavigate();
  const { REPORTS } = useRoutesGenerator();
  const { auth0ClinicianId } = useGetClinicianId();
  const [isShowSendReportContent, setShowSendReportContent] = useState(false);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [errors, setErrors] = useState<{ customSendInvoiceTime?: string; emails?: string; sendTo?: string }>({});
  const [shareReportDetail, setShareReportDetail] = useState({
    note: '',
    sendTimeInfo: {
      sendInvoiceType: 'now',
      customSendTime: ''
    },
    sendToInfo: {
      isClientCheck: true,
      isOtherCheck: false,
      otherEmail: ''
    }
  });
  const [backEditModeConfirmBox, setBackEditModeConfirmBox] = useState({
    id: '',
    visible: false
  });
  const [recipientList, setRecipientList] = useState<ReportAccessor[]>([]);

  useEffect(() => {
    if (data.shareDetails.accessors?.length) {
      setRecipientList(sortRecipient(data.shareDetails.accessors));
    }
  }, [data.shareDetails]);

  const onChangeReportDetails = (val: object) => {
    const massageData = {
      ...shareReportDetail,
      ...val
    };
    setShareReportDetail(massageData);
  };

  const onHandleShowSendReport = () => {
    setShowSendReportContent(!isShowSendReportContent);
  };

  const onHandleSendReport = () => {
    const newErrors: typeof errors = {};

    if (shareReportDetail.sendTimeInfo.sendInvoiceType === 'custom' && !shareReportDetail.sendTimeInfo.customSendTime) {
      newErrors.customSendInvoiceTime = 'Please select a date and time to send this invoice';
    }

    if (!shareReportDetail.sendToInfo.isClientCheck && !shareReportDetail.sendToInfo.isOtherCheck) {
      newErrors.sendTo = 'Please select a recipient';
    } else if (shareReportDetail.sendToInfo.isOtherCheck) {
      const emailsArray = shareReportDetail.sendToInfo.otherEmail.split(';').filter((email: string) => !!email);

      if (emailsArray.length === 0) {
        newErrors.emails = 'Please enter a list of semi-colon-separated emails';
      } else {
        try {
          emailSchema.validateSync(emailsArray);
        } catch (ex) {
          newErrors.emails = 'Please enter emails in the correct format';
        }
      }
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    } else {
      const shareDetails: { sendAt: Date; sendClient: boolean; sendTo?: string[]; note?: string } = {
        sendAt: new Date(),
        sendClient: shareReportDetail.sendToInfo.isClientCheck,
        note: shareReportDetail.note
      };

      if (shareReportDetail.sendTimeInfo.sendInvoiceType === 'now') {
        shareDetails.sendAt = moment().toDate();
      } else {
        shareDetails.sendAt = moment(shareReportDetail.sendTimeInfo.customSendTime, 'YYYY-MM-DD HH:mm').toDate();
      }

      if (shareReportDetail.sendToInfo.isOtherCheck) {
        shareDetails.sendTo = shareReportDetail.sendToInfo.otherEmail.split(';').filter((email: string) => !!email);
      }

      onSendReport(shareDetails);
      setTimeout(() => {
        setShowSendReportContent(false);
        setShareReportDetail({
          note: '',
          sendTimeInfo: {
            sendInvoiceType: 'now',
            customSendTime: ''
          },
          sendToInfo: {
            isClientCheck: true,
            isOtherCheck: false,
            otherEmail: ''
          }
        });
      }, 1000);
    }
  };

  const onConfirmEditMode = async () => {
    try {
      await putUnpublish(token, data._id);
      setBackEditModeConfirmBox({ id: '', visible: false });
      navigate(`${REPORTS.BASE}/${data._id}/edit`);
    } catch (ex) {
      notification.error({
        message: 'Something went wrong while trying unpublish your report. Please try again.'
      });
    }
  };

  const onCancelEditMode = () => {
    setBackEditModeConfirmBox({ id: '', visible: false });
  };

  const onHandleRevokeBtn = async (id?: string, name?: string) => {
    if (id) {
      try {
        await putRevokeAccessor(token, data._id, id);
        notification.success({
          message: `${name} has been revoke`,
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });
        const newRecipientList = recipientList.filter((x: any) => x._id !== id);
        setRecipientList(newRecipientList);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to revoke accessor.' });
      }
    }
  };

  const isPrimaryData = data.clientRecord?.clientProfiles[0];
  const isClientShared = recipientList.filter((obj) => obj.isClient).length > 0;

  const checkIsAllowEdit = isEdgeAdminView || isEdgeReceptionist ? data.isForPractice : true;

  return (
    <div className={styles.container}>
      {data.authorDetail && (isEdgeAdminView || isEdgeReceptionist) && (
        <div className={styles.authorWrapper}>
          <div className={styles.title}>Authored by</div>
          <div className={styles.profileWrapper}>
            <img src={data.authorDetail?.avatar} className={styles.imgProfile} alt={'profile'} />
            <div className={styles.authorName}>{data.authorDetail?.name}</div>
          </div>
        </div>
      )}
      <div className={styles.saveNShareContainer}>
        <div className={styles.title}>
          Status
          <HelpOutLineWithTooltips
            id={'reportStatusHeading'}
            desc={
              'This details whether a report is Published or in Draft. And if Published whether it has been shared and with who.  You can revoke access or add new recipients of this report here.'
            }
          />
        </div>
        <div className={styles.statusWrapper}>
          <Badge label={data.status} className={styles.publishedBadge} />
          {data.clinician._id === auth0ClinicianId && checkIsAllowEdit && (
            <div
              className={styles.editBtn}
              onClick={() => setBackEditModeConfirmBox({ id: 'report Id', visible: true })}
            >
              <i className={`material-icons-outlined ${styles.icon}`}>create</i>
              <span className={styles.label}>Back to editing mode</span>
            </div>
          )}
        </div>
        <div className={styles.shareWrapper}>
          <div className={styles.badgeWrapper}>
            {recipientList && recipientList?.length > 0 ? (
              <Badge label={'Shared'} className={styles.shareBadge} />
            ) : (
              <div>Not Shared</div>
            )}
          </div>
          <div className={styles.recipientListWrapper}>
            {recipientList && recipientList?.length > 0 ? (
              <>
                {recipientList?.map((item, index) => (
                  <div className={styles.recipientList} key={index}>
                    {item.isClient ? (
                      <div className={classnames(styles.recipientItem, styles.clientItem)}>
                        <div className={styles.client}>
                          <div className={styles.clientAvatar}>
                            <ClientProfileAvatar
                              avatarUrl={isPrimaryData?.avatar}
                              initialsName={isPrimaryData?.initials}
                            />
                          </div>
                          <div className={styles.recipientEmail}>{isPrimaryData?.name}</div>
                        </div>
                        <div
                          className={styles.revokeBtn}
                          onClick={() => onHandleRevokeBtn(item._id, isPrimaryData?.name)}
                        >
                          Revoke access
                        </div>
                      </div>
                    ) : (
                      <div className={styles.recipientItem}>
                        <div className={styles.recipientEmail}>{item.email}</div>
                        <div className={styles.revokeBtn} onClick={() => onHandleRevokeBtn(item._id, item.email)}>
                          Revoke access
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {!isShowSendReportContent && (
          <div className={styles.shareLink} onClick={() => onHandleShowSendReport()}>
            <i className={`material-icons ${styles.plusIcon}`}>add_circle_outline</i>Share with{' '}
            {recipientList && recipientList?.length > 0 ? 'another' : ''} recipient
          </div>
        )}
      </div>
      <ExpandableContent expanded={isShowSendReportContent}>
        <ShareSideBox
          isClientShared={isClientShared}
          allowCommunicationWithEmail={data.clientRecord.allowCommunicationWithEmail}
          data={shareReportDetail}
          onChangeReportDetails={onChangeReportDetails}
          errorsData={errors}
        />
        <div className={styles.buttonWrapper}>
          <Button className={styles.button} status={sendInvoiceButtonStatus} type="button" onClick={onHandleSendReport}>
            Share Report
          </Button>
        </div>
      </ExpandableContent>
      <ConfirmationBox
        visible={backEditModeConfirmBox.visible}
        title={`
          <div>
            Are you sure you want to edit a published report?
          <br>
            If you go back to edit mode we will revoke access to viewing the document to anyone it is shared with. You can then re-share when you have made changes
          </div>
        `}
        confirmBtnLabel={'Confirm'}
        cancelBtnLabel={'Cancel'}
        onSubmitConfirm={() => onConfirmEditMode()}
        onSubmitCancel={() => onCancelEditMode()}
      />
    </div>
  );
};

export default PreviewShareReportBox;
