import { MouseEvent, useState } from 'react';
import styles from './TagsSection.module.scss';

interface TagsSectionProps {
  tags: string[];
  details?: string;
}

const TagsSection = ({ tags, details }: TagsSectionProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const totalTags = tags.length;

  const handleShowMore = (event: MouseEvent, showValue: boolean) => {
    event.stopPropagation();
    setShowAll(showValue);
  };

  return (
    <div className={styles.container}>
      {totalTags > 3 && !showAll ? (
        <>
          {tags.slice(0, 3).map((tag, index) => (
            <div key={index} className={styles.pillContainer}>
              {tag?.trim().length > 0 && <div className={styles.groupPill}>{tag}</div>}
              {details && <div className={styles.referralDetails}>{details}</div>}
            </div>
          ))}
          <button onClick={(event) => handleShowMore(event, true)}>{`+ ${totalTags - 3} more`}</button>
        </>
      ) : (
        <>
          {totalTags > 0
            ? tags.map((tag, index) => (
                <div key={index} className={styles.pillContainer}>
                  {tag?.trim().length > 0 && <div className={styles.groupPill}>{tag}</div>}
                  {details && <div className={styles.referralDetails}>{details}</div>}
                </div>
              ))
            : totalTags === 0 && !details && '-'}
          {totalTags > 3 && <button onClick={(event) => handleShowMore(event, false)}>Show less</button>}
        </>
      )}
    </div>
  );
};

export default TagsSection;
