import LoadingDot from 'components/LoadingDot/LoadingDot';
import { Formik } from 'formik';
import { AppointmentType, AssignmentMode, ParticipantType, RateType } from 'interfaces/Schedule/AppointmentType';
import { useFetchPaymentMethods } from 'pages/Invoices/Invoices';
import { memo, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './AppointmentTypeForm.module.scss';
import AppointmentDescription from './components/AppointmentDescription/AppointmentDescription';
import AppointmentTypeName from './components/AppointmentTypeName/AppointmentTypeName';
import Assignments from './components/Assignments/Assignments';
import BookingRules from './components/BookingRules/BookingRules';
import Duration from './components/Duration/Duration';
import PaymentRequired from './components/PaymentRequired/PaymentRequired';
import Rate from './components/Rate/Rate';
import { appointmentTypeSchema, initialValues, initialValuesUpdate } from './constants';
import AppointmentParticipantType from './components/AppointmentParticipantType/AppointmentParticipantType';
import { useTranslation } from 'react-i18next';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import SubmitFooter from '../SubmitFooter/SubmitFooter';

interface AppointmentTypeFormProps {
  data?: AppointmentType;
  onChangeParticipantType: (participantType: ParticipantType) => void;
  onSubmit: (values: Partial<AppointmentType>) => void;
}

const AppointmentTypeForm = ({ data, onChangeParticipantType, onSubmit }: AppointmentTypeFormProps) => {
  const { isEdgeUser, isEdgeAdminView } = useGetAccountPackageView();
  const initialUpdate = data && initialValuesUpdate(data);
  const [t] = useTranslation();
  const { isAppointmentRateReadDenied } = useGetPermissionToggle();
  const { paymentMethods, isPaymentMethodsLoading } = useFetchPaymentMethods();

  const [isActive, setIsActive] = useState(false);
  const [openToBookStatus, setOpenToBookStatus] = useState<'' | 'active' | 'finished'>('');
  const [draftStatus, setDraftStatus] = useState<'' | 'active' | 'finished'>('');

  const handleSubmit = (values: typeof initialValues) => {
    const isActivity = values.participantType === ParticipantType.Activity;

    const payload = {
      ...values,
      rate: {
        amount: Number(values.rate),
        editable: isActivity ? values.rateEditable : false,
        hidePublicRate: values.hidePublicRate,
        rateType: (values.participantType === ParticipantType.Group && values.rateType) || RateType.PerSession
      },
      duration: {
        minutes: values.duration === 'custom' ? Number(values.customDuration) : Number(values.duration),
        editable: isActivity ? values.durationEditable : true
      },
      ...(values.assignmentMode === AssignmentMode.Single &&
        values.singlePractitioner && {
          assignedClinicians: [values.singlePractitioner]
        }),
      isActive
    };

    delete payload.singlePractitioner;
    onSubmit(payload);
  };

  return (
    <Formik
      initialValues={initialUpdate || initialValues}
      validationSchema={appointmentTypeSchema(t)}
      onSubmit={handleSubmit}
    >
      {({ isValid, submitForm, values }) => (
        <div className={styles.container}>
          <div className={styles.infoRow}>
            {isPaymentMethodsLoading ? (
              <LoadingDot />
            ) : (
              <>
                <div className={styles.name}>
                  <AppointmentTypeName />
                </div>
                <AppointmentDescription />
                {isEdgeUser && (
                  <AppointmentParticipantType onChangeParticipantType={onChangeParticipantType} disabled={!!data} />
                )}
                {values.participantType === ParticipantType.Group && (
                  <div className={styles.subInfoGroup}>
                    <Duration />
                    {!isAppointmentRateReadDenied && <Rate vertical showRateTypeSelect />}
                  </div>
                )}
                {values.participantType === ParticipantType.OneToOne && (
                  <>
                    <div className={styles.subInfoOneToOne}>
                      <Duration />
                      {!isAppointmentRateReadDenied && <Rate />}
                    </div>
                    {isEdgeAdminView && <Assignments includeAutoAssignOption />}
                    <BookingRules />
                    {paymentMethods.some(
                      ({ paymentType, stripeStatus }) => paymentType === 'stripe' && stripeStatus === 'completed'
                    ) && <PaymentRequired />}
                  </>
                )}
                {values.participantType === ParticipantType.Activity && (
                  <>
                    <div className={styles.subInfoActivity}>
                      <Duration
                        inputContainerClass={styles.inputContainerClass}
                        includeFullDayOption
                        showEditableCheckbox
                      />
                      {!isAppointmentRateReadDenied && (
                        <Rate inputContainerClass={styles.inputContainerClass} showEditableCheckbox />
                      )}
                    </div>
                    {isEdgeAdminView && <Assignments />}
                  </>
                )}
                {values.participantType === ParticipantType.Activity ? (
                  <SubmitFooter
                    draftStatus={draftStatus}
                    setDraftStatus={setDraftStatus}
                    openToBookStatus={openToBookStatus}
                    setOpenToBookStatus={setOpenToBookStatus}
                    isDeliveryOptionsEmpty={false}
                    isValid={isValid}
                    setIsActive={setIsActive}
                    submitForm={submitForm}
                  />
                ) : (
                  <div className={styles.footer}>
                    <ButtonAlt onClick={submitForm}>Next to set up schedule</ButtonAlt>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};

export default memo(AppointmentTypeForm);
