import { useEffect, useState } from 'react';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import { clientRecordsInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';

import ClinicianSelect from 'components/v2/ClinicianSelect/ClinicianSelect';
import { patchReassignClient } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';

import styles from './PatientAssignment.module.scss';
import { notification, Modal } from 'antd';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import MentorSelect from '../MentorSelect/MentorSelect';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { clientRecordsApiSlice } from 'redux/endpoints/clinicianProfileServices/client';
import { CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { useDispatch } from 'react-redux';

interface PatientAssignmentProps {
  recordId: string;
  clientRecordData: clientRecordsInterface;
  isClientRecordLoading: boolean;
}

const PatientAssignment = ({ isClientRecordLoading, clientRecordData, recordId }: PatientAssignmentProps) => {
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const [selectedCId, setSelectedCId] = useState(clientRecordData.clinicianAuth0Ids[0] || '');
  const [mentorId, setMentorId] = useState((clientRecordData.mentorIds && clientRecordData.mentorIds[0]) || '');
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');
  const { isMentorFeatureToggle } = useGetFeatureToggle();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isClientRecordLoading) {
      setSelectedCId(clientRecordData.clinicianAuth0Ids[0] || '');
      setMentorId((clientRecordData.mentorIds && clientRecordData.mentorIds[0]) || '');
    }
  }, [clientRecordData.clinicianAuth0Ids, isClientRecordLoading, clientRecordData.mentorIds]);

  const handleSelectPractitioner = (value: any) => {
    setSelectedCId(value._id);
  };

  const handleWarningModal = () => {
    if (clientRecordData.recordStatus === RecordStatus.Closed) {
      return;
    }
    Modal.confirm({
      title: 'Confirm save message',
      content: t('form.confirm.save_client_assignment'),

      onOk: async () => {
        setSaveStatus('active');
        try {
          const payload = {
            clinicianAuth0Ids: selectedCId ? [selectedCId] : [],
            mentorIds: mentorId ? [mentorId] : []
          };
          await patchReassignClient(token, accountId, recordId, payload);
          setSaveStatus('finished');

          notification.success({
            message: t('form.success.save_client_assignment'),
            duration: 3,
            closeIcon: <span className="success">OK</span>
          });
          setTimeout(() => {
            setSaveStatus('');
            dispatch(clientRecordsApiSlice.util.invalidateTags([CPSTagTypes.ClientEncrypted]));
          }, 500);
        } catch (ex) {
          console.error(ex);
          notification.error({
            message: t('form.error.save_client_assignment'),
            duration: 3,
            closeIcon: <span className="success">OK</span>
          });
          setSaveStatus('');
        }
      }
    });
  };

  return (
    <div className={styles.boxContainer}>
      {isClientRecordLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.title}>{t('client_assignment.client')}</div>
          <div className={styles.desc}>{t('client_assignment.client.description')} </div>
          <div className={styles.listWrapper}>
            <ClinicianSelect
              onSelect={handleSelectPractitioner}
              includePractice
              selectedId={selectedCId}
              disabled={clientRecordData.recordStatus === 'closed'}
              filterRoles={[AccessRight.User, AccessRight.Admin]}
            />
          </div>
          {isMentorFeatureToggle && (
            <>
              <div className={styles.title}>{t('client_assignment.mentor')}</div>
              <div className={styles.desc}>{t('client_assignment.mentor.description')}</div>
              <div className={styles.listWrapper}>
                <MentorSelect
                  onSelect={(value) => {
                    setMentorId(value?._id || '');
                  }}
                  selectedId={mentorId}
                  disabled={clientRecordData.recordStatus === 'closed'}
                />
              </div>
            </>
          )}

          <div className={styles.buttonWrapper}>
            <ButtonAlt
              type={'submit'}
              status={saveStatus}
              onClick={handleWarningModal}
              disabled={clientRecordData.recordStatus === RecordStatus.Closed}
            >
              Save Assignment Changes
            </ButtonAlt>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientAssignment;
