import styles from './ReferrerRelativeForm.module.scss';

import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import { ROLE_TYPE_OPTIONS } from 'components/AddPatientModalV2/constants';
import {
  ReferralFieldsPayload,
  ReferralValidateField
} from 'pages/Referrals/components/AddReferralModal/AddReferralModalInterfaces';

interface ReferrerRelativeFormProps {
  referrerFields: ReferralFieldsPayload['referrer'];
  errorMessage: ReferralValidateField['referrer'];
  onChange: (key: string, val: string) => void;
}

const ReferrerRelativeForm = ({
  referrerFields = { firstName: '', lastName: '' },
  errorMessage,
  onChange: handleChangeFieldValue
}: ReferrerRelativeFormProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.rowContainer}>
        <div className={styles.fieldContainer}>
          <MaterialInput
            id="self-firstName"
            label="Referrer first name"
            maxLength={20}
            onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
            value={referrerFields.firstName}
            error={!!errorMessage.firstName}
            errorMessage={errorMessage.firstName}
            required
          />
        </div>
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.fieldContainer}>
          <MaterialInput
            id="self-lastName"
            label="Referrer last name"
            maxLength={20}
            onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
            value={referrerFields.lastName}
            error={!!errorMessage.lastName}
            errorMessage={errorMessage.lastName}
            required
          />
        </div>
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.fieldContainer}>
          <MaterialSelect
            id="self-relationship"
            label="Relationship"
            isSearchable={false}
            options={ROLE_TYPE_OPTIONS}
            value={referrerFields.relationship}
            onChange={(value) => handleChangeFieldValue('relationship', value)}
            errorMessage={errorMessage.relationship}
          />
        </div>
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.fieldContainer}>
          <MaterialInput
            id="self-email"
            label="Referrer email"
            maxLength={200}
            onChange={(e) => handleChangeFieldValue('email', e.target.value)}
            value={referrerFields.email}
            required
            icon="email"
          />
        </div>
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.fieldContainer}>
          <MaterialPhoneInput
            id="self-mobile"
            autoFormat={false}
            disableCountryCode
            hideFlag
            inputName="mobile"
            label="Referrer phone"
            onChange={(value = '') => {
              handleChangeFieldValue('mobile', value);
            }}
            placeholder=""
            isError={false}
            value={referrerFields.mobile || ''}
            inputClass={styles.transparentInput}
            icon="phone"
          />
        </div>
      </div>
    </div>
  );
};

export default ReferrerRelativeForm;
