import { useMemo } from 'react';
import { newClientForm } from '../AddPatientModalV2Interface';
import { defaultAdultDetails } from './defaultAdultDetails';
import { defaultChildDetails } from './defaultChildDetails';
import { defaultCoupleDetails } from './defaultCoupleDetails';
import { defaultYoungDetails } from './defaultYoungDetails';

const initValueGenerator = (defaultDetails: newClientForm, selectedClinicianId?: string): newClientForm => ({
  ...defaultDetails,
  clinicianAuth0Ids: selectedClinicianId ? [selectedClinicianId] : []
});

export const useGetInitValueByType = (selectedClinicianId?: string, initialPrefill?: Partial<newClientForm>) => {
  const AdultInit = useMemo(
    () => initValueGenerator({ ...defaultAdultDetails, ...initialPrefill }, selectedClinicianId),
    [selectedClinicianId, initialPrefill]
  );
  const YoungInit = useMemo(
    () => initValueGenerator({ ...defaultYoungDetails, ...initialPrefill }, selectedClinicianId),
    [selectedClinicianId, initialPrefill]
  );
  const ChildInit = useMemo(
    () => initValueGenerator({ ...defaultChildDetails, ...initialPrefill }, selectedClinicianId),
    [selectedClinicianId, initialPrefill]
  );
  const CoupleInit = useMemo(
    () => initValueGenerator({ ...defaultCoupleDetails, ...initialPrefill }, selectedClinicianId),
    [selectedClinicianId, initialPrefill]
  );

  return {
    AdultInit,
    YoungInit,
    ChildInit,
    CoupleInit
  };
};
