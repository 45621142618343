import { ChangeEvent, useMemo, useState } from 'react';
import classnames from 'classnames';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import {
  Allocation,
  AllocationCategory,
  AllocationType,
  PractitionersDetailsInterface
} from 'interfaces/Practitioners/practitionersListing';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { useGetAccessToken } from 'utils/hooks/token';
import { updateClinicianProfile } from 'utils/http/ClinicianProfileService/Profile/profile';
import { notification } from 'antd';
import styles from './ProfileWorkingSchedule.module.scss';
import { Formik } from 'formik';
import { profileWorkingScheduleSchema, ProfileInterfaceWS } from './constants';
import TimeAvailable from 'pages/Calendar/components/CalendarSettings/components/RoomManagement/components/RoomForm/components/TimeAvailable/TimeAvailable';
import { TimeSlot } from 'interfaces/Schedule/Room';
import moment from 'moment';
import Select from 'components/Select/CommonSelect/Select';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { defaultTimezone, getTimezoneLabel } from 'utils/hooks/GetTimezones/getTimezones';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import Radio from 'components/Radio/Radio';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import TitleInput from 'components/v2/TitleInput/TitleInput';

const TIME_FORMAT_WITHOUT_ASIDE = 'HH:mm',
  TIME_FORMAT_WITH_ASIDE = 'hh:mm a';

export const TIMEZONE_OPTIONS = [
  { value: 'Australia/Brisbane', label: getTimezoneLabel('Australia/Brisbane') },
  { value: 'Australia/Melbourne', label: getTimezoneLabel('Australia/Melbourne') },
  { value: 'Australia/Sydney', label: getTimezoneLabel('Australia/Sydney') },
  { value: 'Australia/Adelaide', label: getTimezoneLabel('Australia/Adelaide') },
  { value: 'Australia/Darwin', label: getTimezoneLabel('Australia/Darwin') },
  { value: 'Australia/Perth', label: getTimezoneLabel('Australia/Perth') },
  { value: 'Pacific/Norfolk', label: getTimezoneLabel('Pacific/Norfolk') },
  { value: 'Indian/Christmas', label: getTimezoneLabel('Indian/Christmas') },
  { value: 'Indian/Cocos', label: getTimezoneLabel('Indian/Cocos') }
];

export const ALLOCATION_CATEGORY_MAP_LABEL = {
  bulkBill: 'Bulk Bill',
  rebate: 'Rebate',
  ndis: 'NDIS',
  workCover: 'Work Cover',
  outOfPocket: 'Self Funded'
};

const ALLOCATION_CATEGORIES = [
  { label: ALLOCATION_CATEGORY_MAP_LABEL.bulkBill, value: AllocationCategory.BulkBill },
  { label: ALLOCATION_CATEGORY_MAP_LABEL.rebate, value: AllocationCategory.Rebate },
  { label: ALLOCATION_CATEGORY_MAP_LABEL.ndis, value: AllocationCategory.NDIS },
  { label: ALLOCATION_CATEGORY_MAP_LABEL.workCover, value: AllocationCategory.WorkCover },
  { label: ALLOCATION_CATEGORY_MAP_LABEL.outOfPocket, value: AllocationCategory.OutOfPocket }
];

const ALLOCATION_TYPES = [
  { label: '% of working hours', value: AllocationType.Percentage },
  { label: 'Sessions', value: AllocationType.Fixed }
];

interface ProfileWorkingScheduleProps {
  profile: ProfileInterface;
  onUpdateProfile: (newProfile: ProfileInterface) => void;
  fetchProfile?: () => void;
}

interface CaseLoadError {
  availableSlot: boolean;
  allocations: { category: string; type: string; value: string }[];
}

enum AppointmentLimit {
  category = 'category',
  none = 'none'
}

const defaultAllocationError = { category: '', type: '', value: '' };

const ProfileWorkingSchedule = ({ profile, onUpdateProfile, fetchProfile }: ProfileWorkingScheduleProps) => {
  const { token } = useGetAccessToken();
  const [caseLoadValue, setCaseLoadValue] = useState<PractitionersDetailsInterface['caseLoad']>(profile.caseLoad);
  const [workTimeZone, setWorkTimeZone] = useState<PractitionersDetailsInterface['workTimeZone']>(profile.workTimeZone);
  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [caseLoadError, setCaseLoadError] = useState<CaseLoadError>({
    availableSlot: false,
    allocations: (profile.caseLoad.allocations || []).map(() => defaultAllocationError)
  });
  const [workTimeZoneError, setWorkTimeZoneError] = useState(false);
  const [limitAppointmentType, setLimitAppointmentType] = useState(
    profile.caseLoad.allocations?.length ? AppointmentLimit.category : AppointmentLimit.none
  );
  const { isMultiTimeZoneEnabled, isSomeoneHealthFeatureToggle } = useGetFeatureToggle();

  const { isEdgeAdminView, isEdgeReceptionistView, isEdgeAdminOrReceptionistView } = useGetAccountPackageView();

  const isAvailableSlotEditable = useMemo(
    () => (isSomeoneHealthFeatureToggle ? isEdgeAdminOrReceptionistView : true),
    [isSomeoneHealthFeatureToggle, isEdgeAdminOrReceptionistView]
  );

  const canEditAllocation = useMemo(
    () => isSomeoneHealthFeatureToggle && isEdgeAdminView,
    [isSomeoneHealthFeatureToggle, isEdgeAdminView]
  );

  const DEFAULT_TIME_SLOT = { startTime: '08:00 AM', endTime: '06:00 PM' };

  const formValue: ProfileInterfaceWS = {
    ...profile,
    availability: {
      monday: {
        isAvailable: profile.workingSchedule.monday?.isActive,
        timeSlots: profile.workingSchedule.monday?.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.monday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      tuesday: {
        isAvailable: profile.workingSchedule.tuesday?.isActive,
        timeSlots: profile.workingSchedule.tuesday?.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.tuesday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      wednesday: {
        isAvailable: profile.workingSchedule.wednesday?.isActive,
        timeSlots: profile.workingSchedule.wednesday?.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.wednesday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      thursday: {
        isAvailable: profile.workingSchedule.thursday?.isActive,
        timeSlots: profile.workingSchedule.thursday?.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.thursday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      friday: {
        isAvailable: profile.workingSchedule.friday?.isActive,
        timeSlots: profile.workingSchedule.friday?.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.friday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      saturday: {
        isAvailable: profile.workingSchedule.saturday?.isActive,
        timeSlots: profile.workingSchedule.saturday?.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.saturday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      sunday: {
        isAvailable: profile.workingSchedule.sunday?.isActive,
        timeSlots: profile.workingSchedule.sunday?.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.sunday.timeSlots
          : [DEFAULT_TIME_SLOT]
      }
    }
  };

  const handleChangeWorkTimeZone = (option: { value: ProfileInterface['workTimeZone']; label: string }) => {
    setWorkTimeZone(option.value);
    setWorkTimeZoneError(false);
  };

  const handleChangeAvailableSlot = (event: ChangeEvent<HTMLInputElement>) => {
    const regexNumberOnly = /^[0-9\b]+$/;
    if (event.target.value === '' || regexNumberOnly.test(event.target.value)) {
      setCaseLoadError({ ...caseLoadError, availableSlot: false });
      setCaseLoadValue({ ...caseLoadValue, availableSlot: parseInt(event.target.value, 10) });
    }
  };

  const handleChangeAllocation = (value: string, field: string, index: number) => {
    const newAllocations = [...(caseLoadValue.allocations || [])] as Allocation[];
    if (field === 'value') {
      const regexNumberOnly = /^[0-9\b]+$/;
      if (value === '' || regexNumberOnly.test(value)) {
        newAllocations[index].value = value;
        setCaseLoadValue({ ...caseLoadValue, allocations: newAllocations });
      }
    } else if (field === 'category') {
      newAllocations[index].category = value as AllocationCategory;
      setCaseLoadValue({ ...caseLoadValue, allocations: newAllocations });
    } else if (field === 'type') {
      newAllocations[index].type = value as AllocationType;
      setCaseLoadValue({ ...caseLoadValue, allocations: newAllocations });
    }
  };

  const validateAllocation = () => {
    let hasError = false;

    if (!canEditAllocation || !caseLoadValue.allocations?.length) {
      return { isAllocationError: false, allocationError: caseLoadError.allocations };
    }

    const newError = caseLoadError.allocations.map((allocation, index) => {
      const currentAllocation = caseLoadValue.allocations?.[index];

      if (!currentAllocation) {
        hasError = true;
        return { ...allocation, value: 'Error' };
      }

      const isEmpty = !currentAllocation.value;
      const isInvalidPercentage =
        currentAllocation.type === AllocationType.Percentage ? +currentAllocation.value > 100 : false;

      if (isEmpty || isInvalidPercentage) {
        hasError = true;
      }

      return {
        ...allocation,
        value: isEmpty ? 'Invalid value' : isInvalidPercentage ? 'Invalid percentage' : ''
      };
    });

    return { isAllocationError: hasError, allocationError: newError };
  };

  const validateField = () => {
    const isAvailableSlotError = isAvailableSlotEditable && caseLoadValue.availableSlot < 0;
    const workTimeZoneMissing = isMultiTimeZoneEnabled && !workTimeZone;

    if (isAvailableSlotError) {
      setCaseLoadError({ ...caseLoadError, availableSlot: true });
    }

    const { isAllocationError, allocationError } = validateAllocation();
    setCaseLoadError({ ...caseLoadError, allocations: allocationError });

    if (workTimeZoneMissing) {
      setWorkTimeZoneError(true);
    }

    if (caseLoadError.availableSlot || workTimeZoneMissing || isAllocationError) {
      setPageSubmitStatus('');
      return false;
    }

    return true;
  };

  const reFormatTimes = (timeSlots: TimeSlot[]) => {
    if (timeSlots && timeSlots.length > 0) {
      return timeSlots.map((timeSlot) => {
        return {
          startTime: moment(timeSlot.startTime, TIME_FORMAT_WITH_ASIDE).format(TIME_FORMAT_WITHOUT_ASIDE),
          endTime: moment(timeSlot.endTime, TIME_FORMAT_WITH_ASIDE).format(TIME_FORMAT_WITHOUT_ASIDE)
        };
      });
    }
    return [] as TimeSlot[];
  };

  const handleSubmit = async (value: ProfileInterfaceWS) => {
    setPageSubmitStatus('active');

    if (!validateField()) {
      return;
    }

    try {
      const reqBody = {
        ...profile,
        ...((isAvailableSlotEditable || canEditAllocation) && {
          caseLoad: {
            ...profile.caseLoad,
            ...(isAvailableSlotEditable && { availableSlot: caseLoadValue.availableSlot }),
            ...(canEditAllocation && {
              allocations: limitAppointmentType === AppointmentLimit.category ? caseLoadValue.allocations : undefined
            })
          }
        }),
        workTimeZone,
        workingSchedule: {
          monday: {
            isActive: value.availability.monday.isAvailable,
            timeSlots: reFormatTimes(value.availability.monday.timeSlots || [])
          },
          tuesday: {
            isActive: value.availability.tuesday.isAvailable,
            timeSlots: reFormatTimes(value.availability.tuesday.timeSlots || [])
          },
          wednesday: {
            isActive: value.availability.wednesday.isAvailable,
            timeSlots: reFormatTimes(value.availability.wednesday.timeSlots || [])
          },
          thursday: {
            isActive: value.availability.thursday.isAvailable,
            timeSlots: reFormatTimes(value.availability.thursday.timeSlots || [])
          },
          friday: {
            isActive: value.availability.friday.isAvailable,
            timeSlots: reFormatTimes(value.availability.friday.timeSlots || [])
          },
          saturday: {
            isActive: value.availability.saturday.isAvailable,
            timeSlots: reFormatTimes(value.availability.saturday.timeSlots || [])
          },
          sunday: {
            isActive: value.availability.sunday.isAvailable,
            timeSlots: reFormatTimes(value.availability.sunday.timeSlots || [])
          }
        }
      };
      await updateClinicianProfile(token, reqBody, isEdgeAdminView || isEdgeReceptionistView ? reqBody._id : undefined);
      fetchProfile && fetchProfile();
      !isEdgeAdminView && onUpdateProfile(reqBody as ProfileInterface);
      setPageSubmitStatus('finished');
      notification.success({
        message: 'Working Schedule updated',
        closeIcon: <span className="success">OK</span>
      });
      setTimeout(() => setPageSubmitStatus(''), 2000);
    } catch (ex) {
      console.error(ex);
      setPageSubmitStatus('');
      notification.error({ message: 'Something went wrong while trying to update your working schedule info' });
    }
  };

  return (
    <Formik initialValues={formValue} validationSchema={profileWorkingScheduleSchema} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.headerTitle}>Working Schedule</div>
            <div className={styles.headerDesc}>
              Manage your target active caseload{isMultiTimeZoneEnabled && ', working timezone,'} and set up your
              regular schedule
            </div>
          </div>
          <div className={styles.content}>
            {isMultiTimeZoneEnabled && (
              <div className={styles.fieldBoxWrapper}>
                <div className={styles.labelWrapper}>
                  <div className={styles.title}>Working Timezone</div>
                </div>
                <div className={classnames(styles.fieldContainer, workTimeZoneError && styles.fieldError)}>
                  <div className={styles.fieldLabelWrapper}>
                    <div className={styles.fieldLabel}>Your operating timezone*</div>
                    <HelpOutLineWithTooltips
                      id={'workTimeZone'}
                      desc={'Your working timezone for booking availability'}
                    />
                  </div>
                  <Select
                    className={styles.workTimeZoneSelect}
                    value={workTimeZone}
                    onChange={(value) => (value ? handleChangeWorkTimeZone(value) : undefined)}
                    options={TIMEZONE_OPTIONS}
                    noCreateNewOption
                    styles={{
                      container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
                      valueContainer: (base: any) => ({
                        ...base,
                        minHeight: '30px',
                        padding: '0 8px 0 0'
                      })
                    }}
                  />
                  {workTimeZoneError && <div className={styles.fieldError}>Work timezone field cannot be blank</div>}
                </div>
              </div>
            )}
            <div className={styles.fieldBoxWrapper}>
              <div className={styles.labelWrapper}>
                <div className={styles.title}>Caseload</div>
              </div>
              <div className={classnames(styles.fieldContainer, caseLoadError.availableSlot && styles.fieldError)}>
                <div className={styles.fieldLabelWrapper}>
                  <div className={styles.fieldLabel}>Your target active caseload*</div>
                  <HelpOutLineWithTooltips id={'caseLoad'} desc={'Availability for target active caseload'} />
                </div>
                <input
                  type={'text'}
                  maxLength={4}
                  className={styles.caseLoadField}
                  value={caseLoadValue.availableSlot || 0}
                  onChange={handleChangeAvailableSlot}
                  disabled={!isAvailableSlotEditable}
                />
                {caseLoadError.availableSlot && <div className={styles.fieldError}>Caseload field cannot be blank</div>}
              </div>
            </div>
            <div className={styles.fieldBoxWrapper}>
              <div className={styles.labelWrapper}>
                <div>
                  <div className={styles.title}>Working Schedule</div>
                  <div className={styles.desc}>
                    Set up your regular schedule{' '}
                    <strong>({profile.workTimeZone || profile.accountSettings?.timezone || defaultTimezone})</strong>{' '}
                    timezone.
                  </div>
                </div>
              </div>
              <TimeAvailable />
            </div>
            {isSomeoneHealthFeatureToggle && (
              <div className={styles.fieldBoxWrapper}>
                <div className={styles.labelWrapper}>
                  <div className={styles.title}>Control Appointment Availability</div>
                  <HelpOutLineWithTooltips
                    id={'controlAppointmentAvailbility'}
                    desc={
                      'Clinician availability is managed by specifying either a % of their available working time in a week, or a number of services offered in a week, per appointment category. This enables the ability to manage what appointments are available to book in any given week.'
                    }
                  />
                </div>
                <div className={styles.labelWrapper}>
                  <div className={styles.desc}>
                    Set specific limits to your weekly schedule that apply to your clients self booking
                  </div>
                </div>

                <Radio
                  options={[
                    { label: 'No limits based on appointment category', value: AppointmentLimit.none },
                    { label: 'Set limits for weekly appointments by category', value: AppointmentLimit.category }
                  ]}
                  value={limitAppointmentType}
                  vertical
                  disabled={!canEditAllocation}
                  onChange={(event) => {
                    const limitType = event.target.value as AppointmentLimit;
                    if (!caseLoadValue.allocations?.length && limitType === AppointmentLimit.category) {
                      caseLoadValue.allocations = [
                        { category: AllocationCategory.BulkBill, type: AllocationType.Percentage, value: '' }
                      ];
                      caseLoadError.allocations = [defaultAllocationError];
                    }
                    setLimitAppointmentType(limitType);
                  }}
                />

                {limitAppointmentType === AppointmentLimit.category && (
                  <div className={styles.fieldContainer}>
                    <div className={styles.allocationFields}>
                      {caseLoadValue.allocations?.map((allocation, index) => (
                        <div className={styles.item} key={`${allocation.category}-${allocation.type}-${index}`}>
                          <div className={styles.category}>
                            <DropdownSearchable
                              error={caseLoadError.allocations[index].category}
                              hideErrorDesc
                              placeholder="Set appointment category"
                              options={ALLOCATION_CATEGORIES}
                              selected={allocation.category}
                              disabled={!canEditAllocation}
                              onSelect={(value) => handleChangeAllocation(value, 'category', index)}
                            />
                          </div>

                          <TitleInput
                            error={caseLoadError.allocations[index].value}
                            inputProps={{
                              placeholder: 'Value',
                              value: allocation.value,
                              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                                handleChangeAllocation(e.target.value, 'value', index),
                              maxLength: 5,
                              disabled: !canEditAllocation
                            }}
                            inputContainerClassName={styles.allocationValueInput}
                            postFix={
                              <div className={styles.postAllocationField}>
                                <i
                                  className={`material-icons-outlined ${styles.icon}`}
                                  onClick={() =>
                                    handleChangeAllocation((+allocation.value + 1).toString(), 'value', index)
                                  }
                                >
                                  keyboard_arrow_up
                                </i>
                                <i
                                  className={`material-icons-outlined ${styles.icon}`}
                                  onClick={() =>
                                    handleChangeAllocation((+allocation.value - 1).toString(), 'value', index)
                                  }
                                >
                                  keyboard_arrow_down
                                </i>
                              </div>
                            }
                          />

                          <DropdownSearchable
                            error={caseLoadError.allocations[index].type}
                            hideErrorDesc
                            placeholder="Type"
                            options={ALLOCATION_TYPES}
                            selected={allocation.type}
                            onSelect={(value) => handleChangeAllocation(value, 'type', index)}
                            disabled={!canEditAllocation}
                            labelClassName={styles.typeLabel}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className={styles.submitButtonContainer}>
              <ButtonAlt id={'saveWorkingSchedule'} onClick={submitForm} status={pageSubmitStatus}>
                Save
              </ButtonAlt>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ProfileWorkingSchedule;
