import { ChangeEvent, KeyboardEvent, useState } from 'react';
import classnames from 'classnames';

import { MedicareFormData } from '../../initValue';
import { MedicareFormErrors } from '../../validation';

import { convertDateFormat, convertExpiryDateFormat } from 'utils/dateChecker';

import MaterialInput from 'components/MaterialInput/MaterialInput';

import styles from './MedicareDetailsForm.module.scss';

interface MedicareDetailsFormProps {
  errors: MedicareFormErrors;
  medicareFormData: MedicareFormData;
  showErrors: boolean;
  onChange: (formData: MedicareFormData) => void;
}

const MedicareDetailsForm = ({ errors, medicareFormData, showErrors, onChange }: MedicareDetailsFormProps) => {
  const [mandatoryOrNot, setMandatoryOrNot] = useState<'' | ' *'>(medicareFormData.parentIrn ? ' *' : '');
  const handleDateOfBirthKeyDown = (event: KeyboardEvent<HTMLInputElement>, isParent?: boolean) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      const newValue = medicareFormData.dateOfBirth.slice(0, medicareFormData.dateOfBirth.length - 1);
      const parentNewValue = medicareFormData.parentDateOfBirth?.slice(
        0,
        medicareFormData.parentDateOfBirth.length - 1
      );

      onChange({
        ...medicareFormData,
        ...(isParent ? { parentDateOfBirth: parentNewValue } : { dateOfBirth: newValue })
      });
    }
  };

  const handleExpiryDateKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      const newValue = medicareFormData.expiryDate.slice(0, medicareFormData.expiryDate.length - 1);
      onChange({
        ...medicareFormData,
        expiryDate: newValue
      });
    }
  };

  const handleChangeFieldValue = async (key: string, e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (key === 'dateOfBirth' || key === 'parentDateOfBirth') {
      value = convertDateFormat(e.target.value);
    }
    if (key === 'expiryDate') {
      value = convertExpiryDateFormat(e.target.value);
    }
    if (key === 'parentIrn') {
      setMandatoryOrNot(value ? ' *' : '');
    }
    const newClientField = {
      ...medicareFormData,
      [key]: value
    };

    onChange(newClientField);
  };

  const generateParentForm = () => {
    return (
      <>
        <div className={styles.title}>PARENT MEDICARE PROFILE</div>
        <div className={styles.message}>
          <i className={`material-icons-outlined ${styles.icon}`} data-tooltip-id="send-invite">
            info
          </i>
          As this child is under 15 years old to process a claim you must provide the relevant Medicare card detail for
          their parent or guardian from the same Medicare card.
        </div>
        <div className={classnames(styles.field, showErrors && errors.parentFirstName && styles.error)}>
          <MaterialInput
            id="parentFirstName"
            label={`Parent First Name ${mandatoryOrNot}`}
            value={medicareFormData.parentFirstName}
            onChange={(e) => handleChangeFieldValue('parentFirstName', e)}
            required
          />
          {showErrors && errors.parentFirstName && <div className={styles.error}>{errors.parentFirstName}</div>}
        </div>
        <div className={classnames(styles.field, showErrors && errors.parentLastName && styles.error)}>
          <MaterialInput
            id="parentLastName"
            label={`Parent Last Name ${mandatoryOrNot}`}
            value={medicareFormData.parentLastName}
            onChange={(e) => handleChangeFieldValue('parentLastName', e)}
            required
          />
          {showErrors && errors.parentLastName && <div className={styles.error}>{errors.parentLastName}</div>}
        </div>
        <div className={classnames(styles.field, showErrors && errors.parentDateOfBirth && styles.error)}>
          <MaterialInput
            id="parentDateOfBirth"
            label={`Parent Date of Birth${mandatoryOrNot}`}
            value={medicareFormData.parentDateOfBirth}
            onKeyDown={(e) => handleDateOfBirthKeyDown(e, true)}
            onChange={(e) => handleChangeFieldValue('parentDateOfBirth', e)}
            type={'tel'}
            maxLength={10}
            required
          />
          {showErrors && errors.parentDateOfBirth && <div className={styles.error}>{errors.parentDateOfBirth}</div>}
        </div>
        <div className={styles.medicareContainer}>
          <div className={styles.field}>
            <MaterialInput
              id="medicareNumber"
              label="Medicare Number"
              value={medicareFormData.number}
              onChange={(e) => handleChangeFieldValue('number', e)}
              required
              disabled
              showDisabledLock
              disabledTooltips={'Medicare card number must be the same as that of the child the claim relates to'}
            />
          </div>
          <div className={classnames(styles.field, showErrors && errors.parentIrn && styles.error)}>
            <MaterialInput
              id="parentIrn"
              label="IRN"
              value={medicareFormData.parentIrn}
              onChange={(e) => handleChangeFieldValue('parentIrn', e)}
              required
            />
            {showErrors && errors.parentIrn && <div className={styles.error}>{errors.parentIrn}</div>}
          </div>
        </div>
        <div className={styles.field}>
          <MaterialInput
            id="expiryDate"
            label="Expiry Date"
            value={medicareFormData.expiryDate}
            onKeyDown={handleExpiryDateKeyDown}
            onChange={(e) => handleChangeFieldValue('expiryDate', e)}
            required
            disabled
            showDisabledLock
            disabledTooltips={'Medicare card number must be the same as that of the child the claim relates to'}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles.title}>{medicareFormData.shouldHaveParentMedicare && 'CHILD '}MEDICARE PROFILE</div>
      <div className={classnames(styles.field, showErrors && errors.firstName && styles.error)}>
        <MaterialInput
          id="clientFirstName"
          label="Client First Name *"
          value={medicareFormData.firstName}
          onChange={(e) => handleChangeFieldValue('firstName', e)}
          required
        />
        {showErrors && errors.firstName && <div className={styles.error}>{errors.firstName}</div>}
      </div>
      <div className={classnames(styles.field, showErrors && errors.lastName && styles.error)}>
        <MaterialInput
          id="clientLastName"
          label="Client Last Name *"
          value={medicareFormData.lastName}
          onChange={(e) => handleChangeFieldValue('lastName', e)}
          required
        />
        {showErrors && errors.lastName && <div className={styles.error}>{errors.lastName}</div>}
      </div>
      <div className={classnames(styles.field, showErrors && errors.dateOfBirth && styles.error)}>
        <MaterialInput
          id="clientDateOfBirth"
          label="Client Date of Birth *"
          value={medicareFormData.dateOfBirth}
          onKeyDown={handleDateOfBirthKeyDown}
          onChange={(e) => handleChangeFieldValue('dateOfBirth', e)}
          type={'tel'}
          maxLength={10}
          required
        />
        {showErrors && errors.dateOfBirth && <div className={styles.error}>{errors.dateOfBirth}</div>}
      </div>
      <div className={styles.medicareContainer}>
        <div className={classnames(styles.field, showErrors && errors.number && styles.error)}>
          <MaterialInput
            id="medicareNumber"
            label="Medicare Number"
            value={medicareFormData.number}
            onChange={(e) => handleChangeFieldValue('number', e)}
            required
          />
          {showErrors && errors.number && <div className={styles.error}>{errors.number}</div>}
        </div>
        <div className={classnames(styles.field, showErrors && errors.irn && styles.error)}>
          <MaterialInput
            id="irn"
            label="IRN"
            value={medicareFormData.irn}
            onChange={(e) => handleChangeFieldValue('irn', e)}
            required
          />
          {showErrors && errors.irn && <div className={styles.error}>{errors.irn}</div>}
        </div>
      </div>
      <div className={classnames(styles.field, showErrors && errors.dva && styles.error)}>
        <MaterialInput
          id="dva"
          label="DVA Number"
          value={medicareFormData.dva}
          onChange={(e) => handleChangeFieldValue('dva', e)}
          required
        />
        {showErrors && errors.dva && <div className={styles.error}>{errors.dva}</div>}
      </div>
      <div className={classnames(styles.field, showErrors && errors.expiryDate && styles.error)}>
        <MaterialInput
          id="expiryDate"
          label="Expiry Date"
          value={medicareFormData.expiryDate}
          onKeyDown={handleExpiryDateKeyDown}
          onChange={(e) => handleChangeFieldValue('expiryDate', e)}
          required
        />
        {showErrors && errors.expiryDate && <div className={styles.error}>{errors.expiryDate}</div>}
      </div>
      {medicareFormData.shouldHaveParentMedicare && generateParentForm()}
    </>
  );
};

export default MedicareDetailsForm;
