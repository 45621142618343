import { ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCurrency } from 'utils/hooks/useCurrency';
import { convertToUnitAmount } from 'utils/convertToUnitAmount';

const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!;

export const StripeElements = ({
  amount,
  stripeAccountId,
  children
}: {
  amount: number;
  stripeAccountId: string;
  children: ReactNode;
}) => {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, { stripeAccount: stripeAccountId });
  const { CURRENCY_TYPE } = useCurrency();

  return (
    <>
      {stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{
            paymentMethodCreation: 'manual',
            mode: 'payment',
            amount: convertToUnitAmount(amount),
            payment_method_types: ['card'],
            currency: CURRENCY_TYPE,
            appearance: {
              theme: 'stripe',
              variables: {
                colorText: '#414449',
                colorTextSecondary: '#414449', // right icons
                colorTextPlaceholder: '#94969D',
                colorDanger: '#d54141',
                borderRadius: '8px',
                fontLineHeight: '22px',
                fontSizeSm: '14px',
                fontWeightNormal: '600'
              },
              rules: {
                '.Label': {
                  color: '#94969D'
                },
                '.Input': {
                  marginBottom: '16px'
                }
              }
            }
          }}
        >
          {children}
        </Elements>
      )}
    </>
  );
};
