import moment from 'moment';
import { MOMENTJS_FORMAT_DATE } from './appointment';

export const MOMENTJS_TIME_FORMAT = 'HH:mm';
export const MOMENTJS_TIME_FORMAT_DAY_PARTS = 'hh:mmA';
export const MOMENTJS_DATE_FORMAT = 'DD/MM/YYYY';
export const MOMENTJS_SHORT_YEAR_FORMAT = 'DD/MM/YY';
export const MOMENTJS_YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';
export const MOMENTJS_DAY_DATE_MONTH_YEAR_FORMAT = 'dddd, Do MMMM YYYY';
export const MOMENTJS_DATE_MONTH_YEAR_FORMAT = 'DD MMM YYYY';
export const MOMENTJS_MONTH_YEAR_FORMAT = 'MMMM YYYY';

export const checkDateValue = (str: string, max: number) => {
  if (str.charAt(0) !== '0' || str === '00') {
    let num = parseInt(str, 10);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str = num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1 ? '0' + num : num.toString();
  }
  return str;
};

export const convertDateFormat = (dateValue: string) => {
  let collect = dateValue.split('/').map(function (v: any) {
    return v.replace(/\D/g, '');
  });
  if (collect[0]) {
    collect[0] = checkDateValue(collect[0], 31);
  }
  if (collect[1]) {
    collect[1] = checkDateValue(collect[1], 12);
  }
  let output = collect.map(function (v: any, i: number) {
    return v.length === 2 && i < 2 ? v + '/' : v;
  });
  return output.join('').substr(0, 14);
};

export const convertExpiryDateFormat = (dateValue: string) => {
  let collect = dateValue.split('/').map(function (v: any) {
    return v.replace(/\D/g, '');
  });
  if (collect[0]) {
    collect[0] = checkDateValue(collect[0], 12);
  }
  let output = collect.map(function (v: any, i: number) {
    return v.length === 2 && i < 1 ? v + '/' : v;
  });
  return output.join('').substr(0, 7);
};

export const formatEndDate = (endDateTime: Date): string => {
  return moment(endDateTime, MOMENTJS_FORMAT_DATE).format(MOMENTJS_FORMAT_DATE);
};
