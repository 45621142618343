import { AddressBookTab } from 'redux/addressBook/addressBookSlice';
import { AddressBookFromAPI, AddressBookType } from './Interfaces/AddressBook';

export const getAddressBookLabelByTab = (tab?: AddressBookTab, isPlural?: boolean) => {
  switch (tab) {
    case AddressBookTab.Individual:
      return isPlural ? 'Individuals' : 'Individual';
    case AddressBookTab.Organisation:
      return isPlural ? 'Organisations' : 'Organisation';
    case AddressBookTab.Referrer:
      return isPlural ? 'Referrers' : 'Referrer';
    default:
      return isPlural ? 'Funders' : 'Funder';
  }
};

export const getAddressBookName = (addressBook: AddressBookFromAPI) => {
  if (addressBook.type === AddressBookType.Individual) {
    return addressBook.contacts[0].firstName + addressBook.contacts[0].lastName
      ? ' ' + addressBook.contacts[0].lastName
      : '';
  } else {
    return addressBook.organisation?.name;
  }
};
