import { GroupListData, GroupStatus, GroupTag, GroupsMinifiedData } from 'pages/Groups/Interfaces/Groups';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import queryString from 'query-string';
import { GroupRecordListRequestPayload } from 'pages/Groups/GroupsList/components/GroupListContent/components/constants';

export const groupApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get group minified
    getMinifiedGroupList: builder.query<
      GroupsMinifiedData,
      {
        accountId: string;
        isEdgeUserView: boolean;
        status?: GroupStatus;
        clinicianId?: string;
        page?: number;
        perPage?: number;
      }
    >({
      query: ({ accountId, isEdgeUserView, status, clinicianId, page, perPage }) => {
        const queryParams = queryString.stringify({
          ...(isEdgeUserView && { asUser: 1 }),
          ...(status && { status }),
          ...(clinicianId && { clinicianId }),
          ...(page && { page }),
          ...(perPage && { perPage })
        });

        return {
          url: `/accounts/${accountId}/groups/minified?${queryParams}`
        };
      }
    }),

    // Get group list
    getAllGroups: builder.query<GroupListData, GroupRecordListRequestPayload>({
      query: ({ accountId, isAdmin, params }) => {
        const stringifiedQueryString = params ? `?${queryString.stringify(params)}` : '';
        return {
          url: isAdmin
            ? `/accounts/${accountId}/groups${stringifiedQueryString}`
            : `/clinicians/me/groups${stringifiedQueryString}`
        };
      },
      providesTags: [CPSTagTypes.GroupList]
    }),

    // Get group tag
    getGroupTags: builder.query<GroupTag[], { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/accounts/${accountId}/group-tags`
      }),
      providesTags: [CPSTagTypes.GroupTagList]
    }),

    // Get group leads
    getGroupLeadList: builder.query<
      GroupListData,
      {
        accountId: string;
        params: { status?: string; asUser?: boolean; name?: string; page?: number; perPage?: number };
      }
    >({
      query: ({ accountId, params }) => ({
        url: `/accounts/${accountId}/groups/leads/minified?${queryString.stringify(params)}`
      })
    }),

    // get minified groups for inline appointment
    getGroupMinifiedForInlineAppointment: builder.query<
      GroupsMinifiedData,
      {
        accountId: string;
        isEdgeUserView: boolean;
        status?: GroupStatus;
        clinicianId?: string;
        name?: string;
        page?: number;
        perPage?: number;
      }
    >({
      query: ({ accountId, isEdgeUserView, status, clinicianId, name, page, perPage }) => {
        const queryParams = queryString.stringify({
          ...(isEdgeUserView && { asUser: 1 }),
          ...(status && { status }),
          ...(clinicianId && { clinicianId }),
          ...(name && { name }),
          ...(page && { page }),
          ...(perPage && { perPage })
        });

        return {
          url: `/accounts/${accountId}/groups/minified?${queryParams}`
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        `${endpointName}_${queryArgs?.clinicianId || ''}_${queryArgs?.name || ''}`,
      merge: (currentCache, newItems, meta) => {
        if (meta.arg.page === 1) {
          return {
            groups: newItems.groups,
            paging: newItems.paging
          };
        }

        return {
          groups: [
            ...currentCache.groups,
            ...newItems.groups.filter((item) => !currentCache.groups.map(({ _id }) => _id).includes(item._id))
          ].sort((a, b) => a.name.localeCompare(b.name)),
          paging: newItems.paging
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => currentArg?.page !== previousArg?.page && currentArg?.page !== 1
    })
  })
});

export const {
  useGetMinifiedGroupListQuery,
  useGetAllGroupsQuery,
  useGetGroupTagsQuery,
  useGetGroupLeadListQuery,
  useGetGroupMinifiedForInlineAppointmentQuery
} = groupApiSlice;
