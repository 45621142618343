import { useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import moment from 'moment';

import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';

import { putClientProfileMedicare } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { getMedicareFormData, MedicareFormData } from './initValue';
import { MedicareFormErrors, validateMedicareForm } from './validation';

import { ButtonStatusType } from 'components/v2/Button/Button';
import ModalHeader from 'components/v2/ModalHeader/ModalHeader';
import MedicareDetailsForm from './components/MedicareDetailsForm/MedicareDetailsForm';

import styles from './EditMedicareDetailsModal.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useAppDispatch } from 'redux/hooks';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { security } from 'utils/security';

const DEFAULT_MEDICARE_ERRORS = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  number: '',
  irn: '',
  dva: '',
  expiryDate: '',
  parentFirstName: '',
  parentLastName: '',
  parentDateOfBirth: '',
  parentIrn: ''
};

interface EditMedicareDetailsModalProps {
  clientRecord: clientRecordsInterface;
  visible: boolean;
  onCancel: () => void;
}

const EditMedicareDetailsModal = ({ clientRecord, visible, onCancel }: EditMedicareDetailsModalProps) => {
  const [medicareFormData, setMedicareFormData] = useState<MedicareFormData>(getMedicareFormData(clientRecord));
  const [errors, setErrors] = useState<MedicareFormErrors>(DEFAULT_MEDICARE_ERRORS);
  const [showErrors, setShowErrors] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState<ButtonStatusType>('');
  const dispatch = useAppDispatch();

  const resetFormData = () => {
    setMedicareFormData(getMedicareFormData(clientRecord));
    setErrors(DEFAULT_MEDICARE_ERRORS);
    setShowErrors(false);
  };

  useEffect(resetFormData, [clientRecord]);

  const handleCancel = () => {
    resetFormData();
    setSaveButtonStatus('');
    onCancel();
  };

  const handleFormChange = (formData: MedicareFormData) => {
    setMedicareFormData(formData);

    const errors = validateMedicareForm(formData);
    setErrors(errors);
  };

  const handleSubmit = async () => {
    setShowErrors(true);
    const errors = validateMedicareForm(medicareFormData);
    setErrors(errors);

    if (!Object.values(errors).some((value) => !!value)) {
      setSaveButtonStatus('active');

      try {
        const token = await security.getAccessTokenSilently();

        const {
          firstName,
          lastName,
          dateOfBirth,
          number,
          irn,
          dva,
          expiryDate,
          parentFirstName,
          parentLastName,
          parentDateOfBirth,
          parentIrn
        } = medicareFormData;

        const payload = {
          firstName,
          lastName,
          dateOfBirth: moment(dateOfBirth, MOMENTJS_DATE_FORMAT).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT),
          number: number !== '' ? Number(number) : undefined,
          irn: irn !== '' ? Number(irn) : undefined,
          dva: dva || undefined,
          expiryDate: expiryDate || undefined,
          ...(medicareFormData.shouldHaveParentMedicare && {
            parent: {
              firstName: parentFirstName,
              lastName: parentLastName,
              ...(parentDateOfBirth && {
                dateOfBirth: moment(parentDateOfBirth, MOMENTJS_DATE_FORMAT).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT)
              }),
              irn: parentIrn !== '' ? Number(parentIrn) : undefined
            }
          })
        };

        const response = await putClientProfileMedicare(
          token,
          clientRecord._id,
          clientRecord.clientProfiles[0]._id,
          payload
        );

        if (response.statusCode === 200) {
          dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.ClientEncrypted]));
          setSaveButtonStatus('finished');
          handleCancel();
          setShowErrors(false);

          notification.success({
            message: 'Client Medicare profile saved.'
          });

          setTimeout(() => {
            setSaveButtonStatus('');
          }, 2000);
        } else if (response.statusCode === 400) {
          const { errors: responseErrors } = (await response.json()) as {
            errors: {
              dateOfBirth?: string;
              numberOrIrn?: string;
              dva?: string;
            };
          };

          setSaveButtonStatus('');
          setErrors({
            ...errors,
            ...(responseErrors.dateOfBirth && { dateOfBirth: responseErrors.dateOfBirth }),
            ...(responseErrors.dva && { dva: responseErrors.dva }),
            ...(responseErrors.numberOrIrn && { number: responseErrors.numberOrIrn, irn: responseErrors.numberOrIrn })
          });

          notification.error({
            message: 'Invalid Medicare details'
          });
        }
      } catch (ex) {
        setSaveButtonStatus('');
        notification.error({ message: "Something went wrong while trying to update this client's Medicare profile" });
      }
    }
  };

  return (
    <Modal
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
      width={700}
      footer={null}
      closable={false}
      open={visible}
      destroyOnClose
    >
      <ModalHeader title="Edit Medicare Profile" onCancel={handleCancel} />
      <div className={styles.formContainer}>
        <MedicareDetailsForm
          errors={errors}
          medicareFormData={medicareFormData}
          showErrors={showErrors}
          onChange={handleFormChange}
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonAlt className={styles.cancelButton} variant={'outlined'} onClick={handleCancel}>
          Cancel
        </ButtonAlt>
        <ButtonAlt status={saveButtonStatus} onClick={handleSubmit}>
          Save Update
        </ButtonAlt>
      </div>
    </Modal>
  );
};

export default EditMedicareDetailsModal;
