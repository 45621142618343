import LoadingDot from 'components/LoadingDot/LoadingDot';
import FormSection from 'components/v2/FormSection/FormSection';
import WeekdayPills from 'components/v2/WeekdayPills/WeekdayPills';
import { useState } from 'react';
import { useFetchRoomList } from 'utils/hooks/GetRoomList/useFetchRoomList';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import CreateRoomModal from './components/CreateRoomModal/CreateRoomModal';
import EditRoomModal from './components/EditRoomModal/EditRoomModal';
import styles from './RoomManagement.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

const RoomManagement = () => {
  const [showCreateRoomModal, setShowCreateRoomModal] = useState(false);
  const [showEditRoomModal, setShowEditRoomModal] = useState(false);
  const [editRoomId, setEditRoomId] = useState<string>();
  const { roomList, isRoomListLoading, reloadRoomList } = useFetchRoomList();
  const { isEdgeAdminView } = useGetAccountPackageView();

  const onCreateFormClose = (reload: boolean) => {
    if (reload) {
      reloadRoomList();
    }
    setShowCreateRoomModal(false);
  };

  const onEditFormClose = (reload: boolean) => {
    if (reload) {
      reloadRoomList();
    }
    setShowEditRoomModal(false);
    setEditRoomId(undefined);
  };

  const selectRoomToEdit = (roomId: string) => {
    setEditRoomId(roomId);
    setShowEditRoomModal(true);
  };
  return (
    <FormSection title="Room Configuration">
      <div className={styles.container}>
        <div className={styles.roomsContainer}>
          {isRoomListLoading ? (
            <div className={styles.loading}>
              <LoadingDot />
            </div>
          ) : (
            <>
              {roomList.map((room, index) => (
                <div key={index} className={styles.roomContainer}>
                  <div className={styles.infoContainer}>
                    <div className={styles.nameAndOccupancyContainer}>
                      <div className={styles.name}>{room.name}</div>
                      <div className={styles.occupancy}>
                        <span className="material-icons">person</span>
                        <span>&nbsp;x&nbsp;{room.maxOccupancy}</span>
                      </div>
                    </div>
                    <div className={styles.weekdayPillsContainer}>
                      <WeekdayPills
                        weekdays={[
                          room.availability.monday.isAvailable,
                          room.availability.tuesday.isAvailable,
                          room.availability.wednesday.isAvailable,
                          room.availability.thursday.isAvailable,
                          room.availability.friday.isAvailable,
                          room.availability.saturday.isAvailable,
                          room.availability.sunday.isAvailable
                        ]}
                      />
                      {isEdgeAdminView && (
                        <ButtonAlt
                          variant={'text'}
                          size={'medium'}
                          icon={'add_circle_outline'}
                          onClick={() => selectRoomToEdit(room._id)}
                        >
                          Edit this room
                        </ButtonAlt>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {isEdgeAdminView && (
                <div className={styles.buttonContainer}>
                  <ButtonAlt
                    variant="outlined"
                    icon="add_circle_outline"
                    onClick={() => setShowCreateRoomModal(true)}
                    className={styles.addButton}
                  >
                    Add New Room
                  </ButtonAlt>
                  <CreateRoomModal visible={showCreateRoomModal} onClose={onCreateFormClose} />
                  {editRoomId && (
                    <EditRoomModal visible={showEditRoomModal} onClose={onEditFormClose} roomId={editRoomId} />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </FormSection>
  );
};

export default RoomManagement;
