import { BentResponse } from 'bent';
import { httpClient } from 'utils/httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

export const downloadCarePathwayTemplateAttachment = (token: string, accountId: string, attachmentId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/care-pathway-templates/${attachmentId}:download`
  ) as Promise<BentResponse>;

export const deleteCarePathwayTemplateAttachment = (token: string, accountId: string, attachmentId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/care-pathway-templates/${attachmentId}`
  ) as Promise<BentResponse>;

export const uploadCarePathwayTemplateAttachment = async (token: string, accountId: string, file: File) => {
  const formData = new FormData();

  formData.append('file', file);

  const request = new XMLHttpRequest();
  request.responseType = 'json';

  return new Promise<any>((resolve, reject) => {
    request.open('POST', `${url}/accounts/${accountId}/care-pathway-templates`, true);
    request.setRequestHeader('Authorization', `Bearer ${token}`);

    request.onload = () => {
      if (request.status === 200) {
        resolve(request.response);
      } else if (request.status === 400) {
        reject('Your file is too big. Please select a file that is smaller than 10MB');
      }
    };

    request.send(formData);
  });
};
