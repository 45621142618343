import styles from './InvoiceCardPayment.module.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { config } from 'config/config';

import { InvoiceCheckoutForm } from 'pages/InvoicesV2/components/InvoiceCardPayment/components/InvoiceCheckoutForm/InvoiceCheckoutForm';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { StripeElements } from 'components/Stripe/StripeElements';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import { convertCurrencyValue, sanitizedCurrencyValue } from 'utils/currencyFormatConverter';
import { useCurrency } from 'utils/hooks/useCurrency';
import InvoiceDetailsForModal from '../InvoiceDetailsForModal/InvoiceDetailsForModal';
import { InvoiceWithType } from '../InvoiceList/constants';

const MINIMUM_STRIPE_FEE = 0.5;

const initialValues = {
  amount: '',
  isSaveCard: false
};

interface InvoiceCardPaymentProps {
  stripeAccountId: string;
  invoice: InvoiceWithType;
  closePayment: () => void;
}

const InvoiceCardPayment = ({ stripeAccountId, invoice, closePayment }: InvoiceCardPaymentProps) => {
  const { currencySymbol } = config;
  const { CURRENCY_DECIMAL } = useCurrency();

  const [step, setStep] = useState<number>(1);

  const { _id: id, owed } = invoice;

  const handleSubmit = () => {
    if (step === 1) {
      setStep(2);
    }
  };

  const onCheckoutComplete = (isSuccess: boolean) => {
    isSuccess && closePayment();
  };

  useEffect(() => {
    setStep(1);
  }, [id]);

  const checkoutFormSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Payment amount must be a valid number')
      .min(
        MINIMUM_STRIPE_FEE,
        `Payment amount must be a minimum of ${currencySymbol}${MINIMUM_STRIPE_FEE.toFixed(CURRENCY_DECIMAL)}`
      )
      .max(owed || Infinity, `Payment amount must be equal or less than ${currencySymbol} owed value.`)
      .required('Please enter payment amount'),
    isSaveCard: Yup.boolean()
  });

  return (
    <div className={styles.container}>
      <InvoiceDetailsForModal invoice={invoice} />

      <Formik initialValues={initialValues} validationSchema={checkoutFormSchema} onSubmit={handleSubmit}>
        {({ values, setFieldValue, errors, submitForm }) => (
          <div className={styles.form}>
            <div className={styles.fieldContainer}>
              <TitleInput
                inputContainerClassName={styles.inputClassName}
                prefix={currencySymbol}
                error={errors.amount}
                inputProps={{
                  maxLength: 18,
                  placeholder: 'Enter amount',
                  value: convertCurrencyValue(values.amount || ''),
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('amount', sanitizedCurrencyValue(e.target.value));
                  },
                  disabled: step !== 1
                }}
              />
              {!errors.amount && (
                <span className={styles.amountNote}>
                  Payment amount must be equal or less than {currencySymbol} owed value.
                </span>
              )}
            </div>

            {step === 1 && (
              <ButtonAlt type="submit" contentClassName={styles.submitBtn} onClick={submitForm} fullWidth>
                Next
              </ButtonAlt>
            )}

            {step === 2 && (
              <StripeElements amount={parseFloat(values.amount)} stripeAccountId={stripeAccountId}>
                <InvoiceCheckoutForm invoiceId={id} onPaymentComplete={onCheckoutComplete} />
              </StripeElements>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default InvoiceCardPayment;
