import classNames from 'classnames';
import styles from './Folder.module.scss';

interface FolderProps {
  disabled?: boolean;
  folderName: string;
  isPrivate?: boolean;
  noteCount: number;
  onClick?: () => void;
  selected?: boolean;
}

const Folder = ({ disabled, folderName, isPrivate, noteCount, onClick, selected }: FolderProps) => {
  return (
    <div
      className={classNames(styles.container, selected && styles.selected, disabled && styles.disabled)}
      onClick={onClick}
    >
      <span className="material-icons-outlined">{isPrivate ? 'lock_person' : 'folder_shared'}</span>
      <div>{folderName}</div>
      <div className={styles.noteCount}>{noteCount}</div>
    </div>
  );
};

export default Folder;
