import { Skeleton } from 'antd';
import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import SearchBar from 'components/v2/SearchBar/SearchBar';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { Fax, FaxTag } from 'interfaces/fax';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { MinifiedClientRecord, useGetMinifiedClientRecordQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useUpdateFaxMutation } from 'redux/endpoints/notificationServices/eFax';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import styles from './TableRowActionDropdown.module.scss';
import { debounce } from 'lodash';

interface TableRowActionDropdownProps {
  fax: Fax;
}

const infinityScrollLoader = (
  <div className={styles.listLoading} key="loading">
    <LoadingCircle />
  </div>
);

const TableRowActionDropdown = ({ fax }: TableRowActionDropdownProps) => {
  const menuNode = useRef<HTMLDivElement>(null);
  const subMenuNode = useRef<HTMLDivElement>(null);

  const { accountId } = useGetAccountId();
  const [updateFaxDetails, { isLoading: updatingFaxDetails }] = useUpdateFaxMutation();

  const [changeLinkedClientExpand, setChangeLinkedClientExpand] = useState(false);
  const [changeTagExpand, setChangeTagExpand] = useState(false);
  const [clientRecordsPage, setClientRecordsPage] = useState(1);
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedClientRecord, setSelectedClientRecord] = useState<MinifiedClientRecord>();
  const [selectedTag, setSelectedTag] = useState<FaxTag>();

  const debouncedSetClientRecordsSearchText = useMemo(
    () =>
      debounce((value) => {
        setClientRecordsPage(1);
        setSearchText(value);
      }, 1000),
    []
  );

  const {
    data: clientRecordsData,
    isFetching: isClientRecordsDataFetching,
    isLoading: isClientRecordsDataLoading
  } = useGetMinifiedClientRecordQuery(
    {
      accountId,
      params: {
        page: clientRecordsPage,
        perPage: 50,
        recordStatus: 'active',
        ...(searchText && {
          searchValue: searchText,
          searchBy: SearchFilterEnum.FirstOrLastName
        })
      }
    },
    {}
  );

  const hasMoreClientRecordsData = clientRecordsData?.paging
    ? clientRecordsData.paging.page * clientRecordsData.paging.perPage < clientRecordsData.paging.totalItems
    : false;

  const loadMoreClientRecords = () => {
    if (!isClientRecordsDataFetching && hasMoreClientRecordsData) {
      setClientRecordsPage(clientRecordsPage + 1);
    }
  };

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target) || subMenuNode.current?.contains(e.target)) {
      return;
    }
    setIsMenuExpanded(false);
    setChangeLinkedClientExpand(false);
    setChangeTagExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleExpandMenu = () => {
    setIsMenuExpanded(!isMenuExpanded);
    if (changeLinkedClientExpand) {
      setChangeLinkedClientExpand(false);
    }
    if (changeTagExpand) {
      setChangeTagExpand(false);
    }
  };

  const handleExpandSubMenu = (state: boolean, dispatcher: Dispatch<SetStateAction<boolean>>) => {
    setChangeLinkedClientExpand(false);
    setChangeTagExpand(false);
    dispatcher(!state);
  };

  const handleClientClick = async (clientRecord: MinifiedClientRecord) => {
    if (!updatingFaxDetails) {
      setSelectedClientRecord(clientRecord);
      await updateFaxDetails({ accountId, faxId: fax._id, payload: { clientRecordId: clientRecord._id } });
      setIsMenuExpanded(false);
      setChangeLinkedClientExpand(false);
      setChangeTagExpand(false);
      setSelectedClientRecord(undefined);
    }
  };

  const handleTagClick = async (tag: FaxTag) => {
    if (!updatingFaxDetails) {
      setSelectedTag(tag);
      await updateFaxDetails({ accountId, faxId: fax._id, payload: { tag } });
      setIsMenuExpanded(false);
      setChangeLinkedClientExpand(false);
      setChangeTagExpand(false);
      setSelectedTag(undefined);
    }
  };

  return (
    <div ref={menuNode} className={styles.container}>
      <ButtonAlt className={styles.button} variant="text" onClick={handleExpandMenu}>
        <i className={`material-icons ${styles.icon}`}>more_vert</i>
      </ButtonAlt>
      <div className={styles.menuWrapper}>
        <div className={isMenuExpanded ? styles.menuShow : styles.menuHide}>
          <div
            className={changeLinkedClientExpand ? styles.itemCardActive : styles.itemCard}
            onClick={() => handleExpandSubMenu(changeLinkedClientExpand, setChangeLinkedClientExpand)}
          >
            <i className={`material-icons-outlined ${styles.itemIcon}`}>person</i>
            <div className={styles.label}>Change Linked Client</div>
            <i className={`material-icons ${styles.subMenuControlIcon}`}>navigate_next</i>
          </div>
          <div
            className={changeTagExpand ? styles.itemCardActive : styles.itemCard}
            onClick={() => handleExpandSubMenu(changeTagExpand, setChangeTagExpand)}
          >
            <i className={`material-icons-outlined ${styles.itemIcon}`}>change_circle</i>
            <div className={styles.label}>Change Tag</div>
            <i className={`material-icons ${styles.subMenuControlIcon}`}>navigate_next</i>
          </div>
        </div>
      </div>
      <div ref={subMenuNode}>
        <div className={styles.subMenuWrapper}>
          <div className={changeLinkedClientExpand ? styles.subMenuShow : styles.subMenuHide}>
            {isClientRecordsDataLoading ? (
              <div className={styles.loading}>
                <Skeleton.Input className={styles.skeleton} active />
              </div>
            ) : (
              <>
                <SearchBar
                  containerClassName={styles.search}
                  placeholder="Search"
                  setSearchValue={(text) => debouncedSetClientRecordsSearchText(text)}
                />
                <InfiniteScroll
                  hasMore={hasMoreClientRecordsData}
                  initialLoad={false}
                  loader={infinityScrollLoader}
                  loadMore={loadMoreClientRecords}
                  pageStart={1}
                  useWindow={false}
                >
                  {clientRecordsData?.clientRecords.map((clientRecord) => (
                    <div
                      className={classNames(
                        selectedClientRecord?._id === clientRecord._id && styles.selected,
                        styles.itemCard,
                        updatingFaxDetails && styles.disabled
                      )}
                      key={clientRecord._id}
                      onClick={() => handleClientClick(clientRecord)}
                    >
                      <div
                        className={styles.label}
                      >{`${clientRecord.clientProfiles[0].firstName} ${clientRecord.clientProfiles[0].lastName}`}</div>
                      {selectedClientRecord?._id === clientRecord._id && <LoadingCircle />}
                    </div>
                  ))}
                </InfiniteScroll>
              </>
            )}
          </div>
          <div className={changeTagExpand ? styles.subMenuShow : styles.subMenuHide}>
            {Object.values(FaxTag).map((tag) => (
              <div
                className={classNames(
                  selectedTag === tag && styles.selected,
                  styles.itemCard,
                  updatingFaxDetails && styles.disabled
                )}
                key={tag}
                onClick={() => handleTagClick(tag)}
              >
                <div className={styles.label}>{tag}</div>
                {selectedTag === tag && <LoadingCircle />}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableRowActionDropdown;
