import { Skeleton } from 'antd';
import classnames from 'classnames';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import { useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useGetAccessToken } from 'utils/hooks/token';

import { useFetchInvoices } from 'utils/hooks/useFetchInvoices';
import { useGetClaims } from '../../hooks/getClaims';
import styles from './ClaimHistory.module.scss';
import columnStyles from './ClaimHistoryColumn.module.scss';
import ClaimItem from './components/ClaimItem/ClaimItem';
import CreateClaimModal from './components/CreateClaimModal/CreateClaimModal';
import { useFetchMedicareLocations } from 'pages/ControlPanel/IntegrationDetails/components/IntegrationDetailsContent/components/IntegrationDetailsContentDisplay/components/Integration/hooks/getMedicareData';

interface ClaimHistoryProps {
  clientRecordId: string;
  clientProfile: clientProfilesInterface;
  parentProfile?: clientProfilesInterface;
  referral: {
    name: string;
    date: string;
    providerNumber: string;
  };
  isMedicareClient: boolean;
  isDvaClient: boolean;
  isClientReferralValid: boolean;
  refetchClaimSummary: () => void;
}

export const ClaimHistory = ({
  clientRecordId,
  clientProfile,
  parentProfile,
  referral,
  isMedicareClient,
  isDvaClient,
  isClientReferralValid,
  refetchClaimSummary
}: ClaimHistoryProps) => {
  const { token } = useGetAccessToken();
  const { claims, isClaimsLoading, refetchClaims } = useGetClaims(token, clientRecordId);
  const { locations, isLocationsLoading } = useFetchMedicareLocations(token, true);

  const fetchInvoicesOptions = useMemo(() => ({ clientRecordId, ignorePagination: true }), [clientRecordId]);
  const { invoices, isInvoicesLoading } = useFetchInvoices(fetchInvoicesOptions);

  const [isCreateClaimModalVisible, setIsCreateClaimModalVisible] = useState(false);

  const handleCloseCreateModal = () => {
    setIsCreateClaimModalVisible(false);
  };

  const locationsFiltered = locations.filter(({ type }) => !isDvaClient || type);

  const allowCreateClaim = (isMedicareClient || isDvaClient) && (!isDvaClient || locationsFiltered.length > 0);

  return (
    <>
      {allowCreateClaim && !isClaimsLoading && !isLocationsLoading && (
        <CreateClaimModal
          parentProfile={parentProfile}
          clientRecordId={clientRecordId}
          clientProfile={clientProfile}
          isMedicareClient={isMedicareClient}
          isDvaClient={isDvaClient}
          invoices={invoices}
          claims={claims}
          referral={referral}
          isInvoicesLoading={isInvoicesLoading}
          visible={isCreateClaimModalVisible}
          locations={locationsFiltered}
          onCancel={handleCloseCreateModal}
          refetchClaims={refetchClaims}
          refetchClaimSummary={refetchClaimSummary}
          isClientReferralValid={isClientReferralValid}
        />
      )}
      <div className={styles.container}>
        <div className={styles.header}>
          Claim History
          {!allowCreateClaim && (
            <Tooltip id="create-claim-button" className={styles.tooltip}>
              {!isMedicareClient && !isDvaClient
                ? 'Invalid Medicare profile'
                : 'A location with a type is required for DVA claiming'}
            </Tooltip>
          )}
          <ButtonAlt
            className={styles.createClaimButton}
            variant={'text'}
            data-tooltip-id="create-claim-button"
            disabled={!allowCreateClaim}
            onClick={() => setIsCreateClaimModalVisible(true)}
          >
            Submit New Claim
          </ButtonAlt>
        </div>
        <div className={classnames(columnStyles.columnWrapper, styles.heading)}>
          <div className={columnStyles.invoiceId}>Invoice ID</div>
          <div className={columnStyles.invoiceDate}>Invoice Date</div>
          <div className={columnStyles.invoiceStatus}>Invoice Status</div>
          <div className={columnStyles.mbsCodeAndDetail}>MBS Code & Details</div>
          <div className={columnStyles.dateSubmitted}>Date submitted</div>
          <div className={columnStyles.claimStatus}>Status</div>
        </div>
        {isClaimsLoading || isInvoicesLoading ? (
          <>
            {[...Array(10)].map((_, i) => (
              <div key={i} className={classnames(styles.loadingWrapper, styles.listItem)}>
                <Skeleton.Input active className={styles.loading} />
              </div>
            ))}
          </>
        ) : claims.length > 0 ? (
          claims.map((claim, index) => (
            <ClaimItem
              claim={claim}
              invoice={invoices.find((invoice) => invoice._id === claim.invoice.id)}
              key={index}
              refetchClaims={refetchClaims}
            />
          ))
        ) : (
          <div className={styles.noItemsContainer}>No claims created yet</div>
        )}
      </div>
    </>
  );
};

export default ClaimHistory;
