import { useEffect, useState } from 'react';

import { Skeleton } from 'antd';
import classNames from 'classnames';
import { ClientProfileEpisodeDropdownItem } from 'components/SideDashboardT23/components/ClientSubTab/ClientProfileSubTab/components/ClientProfileEpisodeDropdownItem/ClientProfileEpisodeDropdownItem';
import DropdownSearchable, { DropdownVariant } from 'components/v2/DropdownSearchable/DropdownSearchable';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { EpisodeStatus } from 'interfaces/Episodes/episodes';
import { useFetchEpisodes } from 'pages/PatientDetails/components/PatientDetailsContent/components/ClientDetailsEoC/hooks/useFetchEpisodes';
import { useGetEpisodeDetailsQuery, useGetEpisodeListQuery } from 'redux/endpoints/clinicianProfileServices/episode';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import styles from './EpisodeTaggingSection.module.scss';

interface EpisodeTaggingSectionProps {
  readOnly: boolean;
  selectedClientRecordId: clientRecordsInterface['_id'];
  selectedEpisode?: string;
  onSelectEpisode: (value: string) => void;
  containerClassName?: string;
  clearCache?: boolean;
  disabled?: boolean;
  showSelected?: boolean;
}

const EpisodeTaggingSection = ({
  readOnly,
  selectedClientRecordId,
  selectedEpisode,
  onSelectEpisode,
  containerClassName,
  clearCache,
  disabled,
  showSelected = true
}: EpisodeTaggingSectionProps) => {
  const { accountTimeZone } = useTimeZone();
  const { episodes, pagination, handleUpdateEpisodes, handleUpdatePaging, fetchMoreEpisodes, hasMore, fetchFromEmpty } =
    useFetchEpisodes();
  const [selectedRecordId, setSelectedRecordId] = useState<string>(selectedClientRecordId);

  const {
    data: episodeListResponse,
    isLoading: episodeListLoading,
    isFetching: episodeListFetching
  } = useGetEpisodeListQuery(
    {
      clientRecordId: selectedClientRecordId,
      timezone: accountTimeZone,
      params: {
        page: pagination.page,
        perPage: 30,
        status: [EpisodeStatus.Finished, EpisodeStatus.Cancelled, EpisodeStatus.Active, EpisodeStatus.OnHold].join(',')
      }
    },
    { skip: !selectedClientRecordId }
  );

  const {
    data: episodeDetailsResponse,
    isLoading: episodeDetailsLoading,
    isFetching: episodeDetailsFetching
  } = useGetEpisodeDetailsQuery(
    {
      clientRecordId: selectedClientRecordId,
      episodeId: selectedEpisode || '',
      timezone: accountTimeZone
    },
    { skip: !selectedClientRecordId || !selectedEpisode }
  );

  useEffect(() => {
    if (episodeListResponse?.episodes && episodeListResponse?.paging) {
      handleUpdateEpisodes(episodeListResponse.episodes, '');
      handleUpdatePaging(episodeListResponse.paging);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeListResponse]);

  useEffect(() => {
    if (clearCache && selectedClientRecordId !== selectedRecordId) {
      fetchFromEmpty();
      setSelectedRecordId(selectedClientRecordId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientRecordId]);

  const handleSelectOption = (value: string) => {
    onSelectEpisode(value);
  };

  const episodesWithCurrentSelect = [
    ...(showSelected && episodeDetailsResponse ? [{ ...episodeDetailsResponse, selected: true }] : []),
    ...episodes
  ];

  return readOnly ? (
    <div className={styles.readOnlyContainer}>
      {episodeDetailsLoading || episodeDetailsFetching ? (
        <div>
          <Skeleton.Input className={styles.epDetailsLoading} />
        </div>
      ) : (
        episodeDetailsResponse && (
          <ClientProfileEpisodeDropdownItem episode={episodeDetailsResponse} supportCurrentLabel={false} />
        )
      )}
    </div>
  ) : episodeListLoading || episodeListFetching ? (
    <div className={styles.episodeLoading}>
      <Skeleton.Input className={styles.epLoading} />
    </div>
  ) : (
    <DropdownSearchable
      controllerClassName={classNames(styles.dropdownContainer, containerClassName)}
      placeholder={!episodes.length ? 'No episode' : 'Select episode'}
      onSelect={handleSelectOption}
      selected={selectedEpisode}
      options={episodesWithCurrentSelect
        .filter((epObj) => epObj?._id)
        .map((episodeObj) => ({
          ...episodeObj,
          value: episodeObj?._id || '',
          label: episodeObj?._id || ''
        }))}
      hasMoreData={hasMore}
      loadMore={fetchMoreEpisodes}
      variant={DropdownVariant.box}
      hideErrorDesc
      hideActiveLine
      firstOptionClassName={styles.selectedLine}
      DropdownItemChildren={(itemProps) => (
        <ClientProfileEpisodeDropdownItem
          episode={itemProps.props}
          selectedClass={itemProps.props.selected}
          value={itemProps.props.value}
          supportCurrentLabel={false}
        />
      )}
      DisplayLabelChildren={(itemProps) => (
        <ClientProfileEpisodeDropdownItem
          episode={itemProps.props}
          supportCurrentLabel={false}
          value={itemProps.props.value}
        />
      )}
      disabled={disabled}
    />
  );
};

export default EpisodeTaggingSection;
