import { EpisodeInterface, EpisodeStatus } from 'interfaces/Episodes/episodes';
import EpisodeStatusBadge from '../components/EpisodeStatusBadge/EpisodeStatusBadge';
import { EpisodeCardAction, nonEditStatus } from '../interface/constants';
import { checkIsShouldShowStatus } from '../utils/checkIsShouldShowStatus';
import moment from 'moment';

interface GetEpisodeStatusActionOptionsPayload {
  currentEpisodeData: EpisodeInterface;
  currentActiveEpisodeData?: EpisodeInterface;
  isShowConfirmArchived: boolean;
  className: string;
  overlapActiveDate: boolean;
}
export const useGetEpisodeStatusActionOptions = ({
  currentEpisodeData,
  currentActiveEpisodeData,
  isShowConfirmArchived,
  className,
  overlapActiveDate
}: GetEpisodeStatusActionOptionsPayload) => {
  const currentEpisodeStatus = currentEpisodeData.status;
  const disabledActiveOrOnHold =
    overlapActiveDate ||
    (currentActiveEpisodeData?.startDate &&
      moment(currentActiveEpisodeData.startDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') &&
      currentEpisodeData._id !== currentActiveEpisodeData?._id) ||
    false;

  const visibleSubMenuItems = [
    { value: EpisodeStatus.Planned, label: <EpisodeStatusBadge status={EpisodeStatus.Planned} /> },
    { value: EpisodeStatus.Draft, label: <EpisodeStatusBadge status={EpisodeStatus.Draft} /> },
    {
      value: EpisodeStatus.Active,
      label: <EpisodeStatusBadge status={EpisodeStatus.Active} isDisable={disabledActiveOrOnHold} />,
      isDisable: disabledActiveOrOnHold,
      tooltip: disabledActiveOrOnHold ? 'There is already an episode covering this date, episodes can not overlap.' : ''
    },
    { value: EpisodeStatus.OnHold, label: <EpisodeStatusBadge status={EpisodeStatus.OnHold} /> },
    { value: EpisodeStatus.Finished, label: <EpisodeStatusBadge status={EpisodeStatus.Finished} /> },
    { value: EpisodeStatus.Cancelled, label: <EpisodeStatusBadge status={EpisodeStatus.Cancelled} /> },
    { value: EpisodeStatus.Error, label: <EpisodeStatusBadge status={EpisodeStatus.Error} /> }
  ].filter(({ value }) => checkIsShouldShowStatus({ currentEpisodeStatus, status: value }));

  const isEditable = !nonEditStatus.includes(currentEpisodeStatus);

  const actionOptions = [];

  if (isEditable) {
    actionOptions.push({ value: EpisodeCardAction.Edit, label: 'Edit Episode', icon: 'edit' });

    if (visibleSubMenuItems.length) {
      actionOptions.push({
        value: EpisodeCardAction.ChangeStatus,
        label: 'Change Status',
        icon: 'task_alt',
        subMenu: visibleSubMenuItems
      });
    }
  } else {
    if (!isShowConfirmArchived) {
      actionOptions.push({ value: EpisodeCardAction.Archived, label: 'Archive Episode', isOpenAfterSelected: true });
    } else {
      actionOptions.push({
        value: EpisodeCardAction.ConfirmArchived,
        label: 'Confirm Archive Episode?',
        className
      });
    }
  }

  return { actionOptions };
};
