import styles from './ClientListHeaderItem.module.scss';
import { SortType } from '../../constants';
import classNames from 'classnames';
import classnames from 'classnames';

interface ClientListHeaderItemProps {
  columnId: string;
  columnName: string;
  sortAble?: boolean;
  sortBy?: string;
  className?: string;
  sortType?: SortType;
  onSort?: (columnId: string) => void;
}

const ClientListHeaderItem = ({
  columnId,
  columnName,
  sortAble,
  sortBy,
  className,
  sortType,
  onSort
}: ClientListHeaderItemProps) => {
  const isSorting = sortBy === columnId;
  return (
    <div className={classnames(styles.container, className)}>
      {sortAble ? (
        <button
          className={classNames(styles.sortButton, isSorting && styles.isSorting)}
          onClick={() => onSort && onSort(columnId)}
        >
          <div className={styles.columnName}>{columnName}</div>
          {isSorting ? (
            sortType && sortType === SortType.DESC ? (
              <i className={classNames(`material-icons`, styles.sortedIcon)}>arrow_drop_down</i>
            ) : (
              <i className={classNames(`material-icons`, styles.sortedIcon)}>arrow_drop_up</i>
            )
          ) : (
            <i className={classNames(`material-icons`, styles.sortIcon)}>unfold_more</i>
          )}
        </button>
      ) : (
        <div className={styles.columnName}>{columnName}</div>
      )}
    </div>
  );
};

export default ClientListHeaderItem;
