import { useContext } from 'react';
import { UserContext } from '../../UserContextProvider';

export const useGetAccountInfo = () => {
  const { clinicianProfile, setProfile: setClinicianProfile } = useContext(UserContext);

  return {
    clinicianProfile,
    setClinicianProfile
  };
};
