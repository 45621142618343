import { AccessRight } from 'interfaces/Clients/clinician';
import { CustomFeature } from 'utils/UserContextProvider';

import { PractitionersDetailsInterface } from '../Practitioners/practitionersListing';
import {
  FitDeliveryList,
  FitLocationList,
  FitPsychologistGenderList
} from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/HelmControl/interfaces/fitFilter';
import {
  AgeRangeList,
  GenderList
} from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/HelmControl/interfaces/mentalHealth';
import { IOptionItem } from '../../components/v2/DropdownSearchable/OptionItem';

export enum ClinicianMedicareRole {
  ClinicalPsychologists = 'clinicalPsychologists',
  RegisteredPsychologists = 'registeredPsychologists',
  OccupationalTherapists = 'occupationalTherapists',
  SocialWorkers = 'socialWorkers',
  GeneralPractitioner = 'generalPractitioner',
  Dietitian = 'dietitian',
  PracticeNurse = 'practiceNurse',
  MentalHealthNurse = 'mentalHealthNurse',
  NursePractitioner = 'nursePractitioner',
  SpeechPathologist = 'speechPathologist',

  // used for Medicare claim items only, not used for actual roles 10/05/2023
  // https://www.servicesaustralia.gov.au/recognition-allied-health-professional-to-provide-medicare-services
  MentalHealthWorker = 'mentalHealthWorker', // includes both psychologists, occupational therapists, social worker
  Psychologist = 'psychologist', // includes both psychologists
  AlliedHealthProfessional = 'alliedHealthProfessional'
}

export enum TacklitResource {
  AppointmentRate = 'appointmentRate',
  Attachment = 'attachment',
  BroadcastSmsOptionToAll = 'broadcastSmsOptionToAll',
  ClientRecord = 'clientRecord',
  GroupAttachment = 'groupAttachment',
  Invoice = 'invoice'
}

interface AnalyticContent {
  title: string;
  embedLink: string;
}

export interface Workspace {
  clinicianId: string;
  accountId: string;
  practice: {
    logo?: string;
    name?: string;
  };
}

export interface RegistrationInterface {
  _id: string;
  registerType: string;
  registerNumber: string;
}

export interface ProfileInterface {
  _id: string;
  accountId: string;
  specialisations: string[];
  consultantLocations: string[];
  title: string;
  titleValue: string;
  name: string;
  practice?: PracticeInterface;
  practices: {
    locations: string[];
    logo: string;
    name: string;
    mobileNumber: string;
  }[];
  accessRight: AccessRight;
  avatar?: string;
  bio?: string;
  partOfPractice?: boolean;
  email: string;
  mobileNumber: string;
  caseLoad: PractitionersDetailsInterface['caseLoad'];
  workingSchedule: PractitionersDetailsInterface['workingSchedule'];
  workTimeZone?: string;
  workingScheduleNew: PractitionersDetailsInterface['workingSchedule'];
  customFeatures?: CustomFeature[];
  accountSettings?: {
    absenceReasons?: string[];
    bazaarUrl?: string;
    bazaarChapterTagIds?: string[];
    customLabels?: Record<string, string>;
    timezone?: string;
    permissions?: {
      [key in AccessRight]?: {
        readDenied?: TacklitResource[];
        writeDenied?: TacklitResource[];
      };
    };
    analytics?: {
      token: string;
      url: string;
    };
    episodeSettings?: {
      typeLabel?: string;
      typeOptions?: IOptionItem[];
      reasonLabel?: string;
      reasonOptions?: IOptionItem[];
      reasonUseLabel?: string;
      reasonUseOptions?: IOptionItem[];
      reasonUseOptionsDisabled?: boolean;
      reasonDetailsLabel?: string;
    };
    referralFormLink?: string;
  };
  profileSettings?: {
    analyticContent?: AnalyticContent[];
    isT23?: boolean;
    enforceMfa?: boolean;
  };
  medicare?: {
    role: ClinicianMedicareRole;
  };
  helmControl?: HelmControlInterface;
  isProfileListed?: boolean;
  gender?: FitPsychologistGenderList;
  slugUrl?: string;
  status: string;
  workspaces?: Workspace[];
  registrations?: RegistrationInterface[];
}

export interface HelmControlInterface {
  headerPhoto?: string;
  additionalPhotos?: string[];
  appointmentPhoto?: string;
  video?: string;
  videoPoster?: string;
  bioPhoto?: string;
  bio?: string;
  bonaFides?: {
    qualifications?: {
      active?: boolean;
      value?: string[];
    };
    languages?: {
      active?: boolean;
      value?: string[];
    };
    style?: {
      active?: boolean;
      value?: string;
    };
    yearsOfExperience?: {
      active?: boolean;
      value?: number;
    };
    medicare?: {
      active?: boolean;
      value?: string;
    };
  };
  quote?: string;
  shortDescription?: string;
  welcomeNote?: string;
  isProfileListed?: boolean;
  shouldUseCaseLoad?: boolean;
  clientAgeRange?: AgeRangeList[];
  clientGender?: GenderList[];
  primarySpecialisations?: string[];
  secondarySpecialisations?: string[];
  deliveryModes?: FitDeliveryList[];
  credentials?: string;
  servicesAvailable?: string[];
  styles?: string[];
  fundingMethods?: string[];
  locations?: FitLocationList[];
}

export type PracticeCountryCode = 'au' | 'uk' | 'nz' | 'sa';

export interface PracticeInterface {
  country?: PracticeCountryCode;
  locations: string[];
  logo: string;
  mobileNumber: string;
  name: string;
  slugUrl: string;
  inboundFaxNumbers: { faxNumber: string; isPrimary?: boolean }[];
  registrationNumber?: string;
  financeEmail?: string;
  formalName?: string;
  faxNumber?: string;
}
