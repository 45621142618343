/**
 * A wrapper around `URLSearchParams` class to serialize an object to a query string.
 *
 * Please note that you'll need to either spread your input if you are using `interface` for your
 * input parameter type or use `type` instead of `interface`.
 *
 * The reason to do this is because `Record<string, unknown>` is a less specific typing and Typescript
 * don't find it safe to assign a more speicifc type to a less specific type.
 *
 * https://stackoverflow.com/questions/60697214/how-to-fix-index-signature-is-missing-in-type-error
 * https://github.com/microsoft/TypeScript/issues/15300
 */
export const parseQueryParams = (queryParams?: Record<string, number | string | boolean | undefined | null>) =>
  (queryParams !== undefined &&
    queryParams !== null &&
    typeof queryParams === 'object' &&
    Object.entries(queryParams)
      .reduce<URLSearchParams>((urlSearchParams, [key, value]) => {
        if (value !== undefined || value !== null) {
          if (typeof value === 'boolean') {
            urlSearchParams.set(key, value ? '1' : '0');
          } else {
            urlSearchParams.set(key, String(value));
          }
        }

        return urlSearchParams;
      }, new URLSearchParams())
      .toString()) ||
  '';
