import { Modal, notification } from 'antd';
import classNames from 'classnames';
import ThreeDotMenu, { ThreeDotMenuHandle } from 'components/T23/ThreeDotMenu/ThreeDotMenu';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { AssignPackagesInterface, funderType, PackagesInterface } from 'interfaces/Packages/packages';
import { REFERRAL_STATUS_LABELS, ReferralEntrySource } from 'interfaces/Referral/Referral';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  packageSchema,
  SelectedPackage
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsProfile/components/PackageAssignment/components/PackageAssignmentModal/PackageAssignmentModal';
import columnStyles from 'pages/Referrals/components/ReferralsHeader/ReferralsColumn.module.scss';
import { ReferralFromAPI, ReferrerType } from 'pages/Referrals/interface';
import ReferralNewClientForm from 'pages/Referrals/ReferralNewClientForm/ReferralNewClientForm';
import { getRoleLabel, isLargeClientMatchCount, isStrongMatch } from 'pages/Referrals/utils';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateClientRecordIdMutation } from 'redux/endpoints/clinicianProfileServices/referral';
import { useAssignPackageMutation } from 'redux/endpoints/scheduleServices/package';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { combineName, getName } from 'utils/general';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import * as Yup from 'yup';
import ActionMenu from './components/ActionMenu/ActionMenu';
import ReferralHistory from './components/ReferralHistory/ReferralHistory';
import ReferralMatchItem from './components/ReferralMatchItem/ReferralMatchItem';
import styles from './ReferralItem.module.scss';

interface ReferralItemProps {
  referral: ReferralFromAPI;
  practiceName: string;
  allowActions?: boolean;
  packageById: Map<string | undefined, PackagesInterface> | undefined;
}

const ReferralItem = ({ referral, practiceName, allowActions, packageById }: ReferralItemProps) => {
  const navigate = useNavigate();

  const { isPackageEnabled, medicareRebateFeatureToggle } = useGetFeatureToggle();
  const { accountId } = useGetAccountId();
  const { timeZoneByView } = useTimeZone();
  const { CLIENTS } = useRoutesGenerator();
  const selectedPackageSchema = Yup.array().of(packageSchema);

  const [isShowHistory, setIsShowHistory] = useState<boolean>(false);
  const [showClientModal, setShowClientModal] = useState<boolean>(false);
  const [showConfirmSync, setShowConfirmSync] = useState<boolean>(false);
  const [isLinkPackageToClient, setIsLinkPackageToClient] = useState<boolean>(false);
  const [selectedClientRecordId, setSelectedClientRecordId] = useState<string>('');

  const [updateClientRecordId] = useUpdateClientRecordIdMutation();
  const [assignPackage] = useAssignPackageMutation();

  const {
    _id,
    referralId,
    clientRecord,
    clientRecordId,
    files = [],
    entrySource,
    referrer,
    subject,
    org,
    createdAt,
    date,
    status,
    changeHistory = [],
    packages = [],
    clientMatch,
    rdf
  } = referral;

  const hasRdf = rdf && rdf.length > 0;

  const activePackages = useMemo(
    () => (packageById ? packages.filter((p) => packageById.get(p.packageId)?.name) : []),
    [packages, packageById]
  );

  const onClickHistory = () => {
    setIsShowHistory(!isShowHistory);
  };

  const getMatchLabel = (matchCount: number) => {
    switch (matchCount) {
      case 0:
        return 'No matches';
      case 1:
        return '1 match';
      default:
        return matchCount + ' matches';
    }
  };

  const hasHistory = changeHistory.length > 0;
  const createdInViewTZ = momentTz(createdAt).tz(timeZoneByView);
  const isAddressBookLinked = false; // REFERRALTODO: enable address book integration
  const isLinked = !!clientRecordId;
  const matchCount = clientMatch?.count || 0;
  const isStrongMatched = clientMatch?.clients.length
    ? isStrongMatch(clientMatch.clients[0].score, medicareRebateFeatureToggle)
    : false;

  const matchMenuRef = useRef<ThreeDotMenuHandle>(null);

  const toggleMatchModal = useCallback((isClose?: boolean) => {
    if (matchMenuRef.current) {
      if (isClose) {
        matchMenuRef.current.closeMenu();
      } else {
        matchMenuRef.current.openMenu();
      }
    }
  }, []);

  const handleClientNameClick = (clientRecordId: string) => {
    navigate(`${CLIENTS.BASE}/${clientRecordId}/profile`);
  };

  const promptToSyncRDF = (clientRecordId: string) => {
    setSelectedClientRecordId(clientRecordId);
    setShowConfirmSync(true);
  };

  const handleLinkClientRecord = async (clientRecordId: string, syncRdf?: boolean, isLinkPackage?: boolean) => {
    notification.warning({
      message: 'Linking client record...'
    });

    try {
      await updateClientRecordId({ accountId, referralId: _id, clientRecordId, syncRdf: syncRdf ? 1 : 0 }).unwrap();

      notification.destroy();
      notification.success({
        message: 'Successfully link client record',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      if (isLinkPackage) {
        await handleLinkPackageToClient(clientRecordId);
      }
    } catch (ex) {
      console.error(ex);

      notification.destroy();
      notification.error({
        message: 'Failed to link client record'
      });
    } finally {
      setSelectedClientRecordId('');
      setShowClientModal(false);
    }
  };

  const validateSelectedPackages = async (selectedPackage: SelectedPackage[]): Promise<boolean> => {
    try {
      await selectedPackageSchema.validate(selectedPackage, { abortEarly: false });
      return true;
    } catch (error) {
      console.error('Validation failed:', error);
      return false;
    }
  };

  const handleLinkPackageToClient = async (clientRecordId: string) => {
    const selectedPackages = activePackages?.map((item) => ({ ...item, referralId: referral._id }));
    if (selectedPackages && packageById) {
      const isValid = await validateSelectedPackages(selectedPackages);

      if (isValid) {
        notification.warning({
          message: 'Assigning packages to client...'
        });
        try {
          const massagePayload: AssignPackagesInterface[] = selectedPackages?.reduce((acc, assignObj) => {
            const currentPackage = packageById.get(assignObj.packageId);
            const currentFunder = currentPackage?.funders.find((funder) => funder.funderId === assignObj.funderId);

            if (currentPackage && currentFunder) {
              const payload = {
                packageId: assignObj.packageId,
                funder: {
                  type: currentFunder.type,
                  ...(currentFunder.type === funderType.AddressBook && {
                    funderId: currentFunder.funderId
                  }),
                  ...(currentFunder.type === funderType.Default && {
                    name: currentFunder.name
                  })
                },
                ...(assignObj.referralId && {
                  referralId: assignObj.referralId
                })
              };

              acc.push(payload);
            }

            return acc;
          }, [] as AssignPackagesInterface[]);

          const assignRes = await assignPackage({
            accountId,
            clientRecordId: clientRecordId,
            payload: massagePayload
          }).unwrap();

          const failedAssignments = assignRes.filter(({ successfullyAssigned }) => !successfullyAssigned);

          notification.destroy();
          notification.success({
            message: 'Successfully assign packages to client',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });

          if (failedAssignments.length > 0) {
            notification.error({
              message: 'Some packages failed to be assigned',
              description: (
                <ol className={styles.failDescWrapper}>
                  {failedAssignments.map((assignObj, idx) => {
                    const currentPackage = packageById.get(assignObj.packageId);
                    const currentFunder = currentPackage?.funders?.find(
                      (funderObj) => funderObj.funderId === assignObj.funder.funderId
                    );
                    return (
                      <li key={idx}>
                        <div className={styles.failTitle}>
                          {currentFunder?.name} - {currentFunder?.name}
                        </div>
                        <div className={styles.failDesc}>Reason: {assignObj.failedToAssignReason}</div>
                      </li>
                    );
                  })}
                </ol>
              )
            });
          } else {
            notification.success({
              message: 'Packages successfully assigned'
            });
          }

          setIsLinkPackageToClient(false);
        } catch (ex) {
          console.error(ex);
          setIsLinkPackageToClient(false);
          notification.error({
            message: 'Something went wrong while assigning to package.'
          });
        }
      } else {
        notification.error({
          message: 'Failed to assign packages to client'
        });
      }
    }
  };

  const getReferrerDetails = useCallback(() => {
    switch (referrer.referrerType) {
      case ReferrerType.Self:
        return 'Self';
      case ReferrerType.FriendFamily:
        return (
          <>
            {getName(referrer)} {referrer.relationship && <div>{getRoleLabel(referrer.relationship)}</div>}
          </>
        );
      case ReferrerType.Organisation:
        return (
          <>
            {org && (
              <div className={styles.organisation}>
                {isAddressBookLinked && (
                  <i className={`material-icons-outlined ${styles.linkReferrerIcon}`}>home_work</i>
                )}
                <span>{org?.name}</span>
              </div>
            )}

            <div className={styles.individual}>
              {isAddressBookLinked && (
                <i className={`material-icons-outlined ${styles.linkReferrerIcon}`}>person_pin_circle</i>
              )}
              <span>{getName({ firstName: referrer.firstName, lastName: referrer.lastName })}</span>
            </div>
          </>
        );
      case ReferrerType.Professional:
        return (
          <>
            <div className={styles.individual}>
              {isAddressBookLinked && (
                <i className={`material-icons-outlined ${styles.linkReferrerIcon}`}>person_pin_circle</i>
              )}
              <span>{getName(referrer)}</span>
            </div>
          </>
        );
      default:
        return null;
    }
  }, [referrer, org, isAddressBookLinked]);

  const getMatchMenu = () => {
    return (
      <ThreeDotMenu
        iconButtonClassName={styles.threeDotMenuIcon}
        size={'medium'}
        paddingSize={'medium'}
        ref={matchMenuRef}
        menuContentClass={styles.matchListContainer}
      >
        <div className={styles.matchListContent}>
          {matchCount > 0 ? (
            <>
              <div className={styles.header}>
                <span className={styles.title}>Referral Matches</span>
                <i
                  className={`material-icons-outlined ${styles.closeIcon}`}
                  onClick={() => {
                    setTimeout(() => {
                      toggleMatchModal(true);
                    }, 10);
                  }}
                >
                  close
                </i>
              </div>

              <div className={styles.matchList}>
                {isLargeClientMatchCount(matchCount) && (
                  <div className={styles.largeMatches}>
                    <i className={`material-icons-outlined ${styles.icon}`}>info</i> Large number of potential matches
                    to this referral. Showing top 10 matches.
                  </div>
                )}

                {clientMatch?.clients.map((client, key) => (
                  <ReferralMatchItem
                    key={key}
                    hasPackage={activePackages.length > 0}
                    onLinkClient={(isLinkPackage) => {
                      setIsLinkPackageToClient(isLinkPackage);
                      hasRdf
                        ? promptToSyncRDF(client.clientRecordId)
                        : handleLinkClientRecord(client.clientRecordId, false, isLinkPackage);
                    }}
                    client={client}
                  />
                ))}
              </div>

              <div className={styles.accessibilityContainer}>
                <div className={styles.linkProfile}>
                  <i className={`material-icons-outlined ${styles.accessibilityIcon}`}>accessibility_new</i>
                  <span>Ignore matches and link to a new profile</span>
                </div>
                <div onClick={() => setShowClientModal(true)} className={styles.createProfile}>
                  Create New Profile
                </div>
              </div>
            </>
          ) : (
            <div className={styles.accessibilityContainer}>
              <div className={styles.linkProfile}>
                <i className={`material-icons-outlined ${styles.accessibilityIcon}`}>accessibility_new</i>
                <span>Link to a new profile</span>
              </div>
              <div onClick={() => setShowClientModal(true)} className={styles.createProfile}>
                Create New Profile
              </div>
            </div>
          )}
        </div>
      </ThreeDotMenu>
    );
  };

  const getPackageFunderPill = (packageId: string, funderId: string) => {
    const currentPackage = packageById?.get(packageId);
    return (
      <>
        {currentPackage && (
          <div className={styles.packageItemDetails}>
            <div className={styles.packageTitle}>{currentPackage.name}</div>
            <div className={styles.funderDetails}>
              {currentPackage.funders.find((funder) => funder.funderId === funderId)?.name}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.itemContainer, isShowHistory && styles.isSelected)}>
        <div className={classNames(columnStyles.referral, styles.referral)}>
          <div className={styles.referralId}>{referralId}</div>
          {(files || []).length > 0 && (
            <div className={styles.attachments}>
              <i className={`material-icons-outlined ${styles.icon}`}>attach_file</i>
              <div onClick={onClickHistory} className={styles.files}>
                {files?.length} {files?.length === 1 ? 'file' : 'files'}
              </div>
            </div>
          )}
        </div>

        <div className={classNames(columnStyles.source, styles.source)}>
          {entrySource && (
            <div className={styles.pill}>
              {ReferralEntrySource.Manual === entrySource ? 'MNL' : entrySource.toUpperCase()}
            </div>
          )}
        </div>

        <div className={classNames(columnStyles.referrer, styles.referrer)}>
          <div className={styles.info}>{getReferrerDetails()}</div>
        </div>

        <div className={classNames(columnStyles.subject, styles.subject)}>
          <div className={styles.subjectName}>{subject && getName(subject)}</div>
          {subject?.dateOfBirth && (
            <div className={styles.subjectDetail}>
              {moment(subject.dateOfBirth, 'YYYY-MM-DD').format(MOMENTJS_DATE_FORMAT)}
            </div>
          )}
        </div>

        <div className={classNames(columnStyles.profile, styles.profile)}>
          <i className={`material-icons-outlined ${styles.linkedIcon} ${isLinked ? styles.active : ''}`}>
            {isLinked ? 'link' : 'link_off'}
          </i>
          <div className={styles.linkInfo}>
            {isLinked ? (
              <span onClick={() => handleClientNameClick(clientRecordId)} className={styles.client}>
                {clientRecord && combineName(clientRecord.clientProfiles)}
              </span>
            ) : (
              <div className={styles.matchInfo}>
                {matchCount > 0 && (
                  <span className={classNames(styles.matchIcon, isStrongMatched ? styles.high : styles.medium)} />
                )}
                <div
                  className={classNames(styles.matchCount, matchCount === 0 && styles.noMatch)}
                  onClick={() => {
                    toggleMatchModal();
                  }}
                >
                  {getMatchLabel(matchCount)}
                  {getMatchMenu()}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={classNames(columnStyles.createdAt, styles.createdAt)}>
          <span>{moment(createdInViewTZ).format('hh:mm')}</span>
          <span>{moment(createdInViewTZ).format('DD MMM YYYY')}</span>
        </div>

        <div className={classNames(columnStyles.date, styles.date)}>
          {moment(date, 'YYYY-MM-DD').format('DD MMM YYYY')}
        </div>

        <div className={classNames(columnStyles.status, styles.status)}>
          <div className={styles.statusBadge} style={{ backgroundColor: REFERRAL_STATUS_LABELS[status].color }}>
            {REFERRAL_STATUS_LABELS[status].label}
          </div>
        </div>

        {isPackageEnabled && (
          <div className={classNames(columnStyles.package, styles.package)}>
            {packageById &&
              activePackages.map((activePackage, idx) => (
                <div key={activePackage.packageId + idx}>
                  {getPackageFunderPill(activePackage.packageId, activePackage.funderId)}
                </div>
              ))}
          </div>
        )}

        <div className={columnStyles.actions}>
          {hasHistory && (
            <ButtonAlt
              variant="text"
              size="medium"
              icon={isShowHistory ? 'expand_less' : 'history'}
              fab
              onClick={onClickHistory}
            />
          )}
          {allowActions && <ActionMenu referral={referral} />}
        </div>
      </div>

      {isShowHistory && (
        <div className={styles.historyWrapper}>
          <ReferralHistory activityFeeds={changeHistory} practiceName={practiceName} files={files} />
        </div>
      )}

      {showClientModal && (
        <ReferralNewClientForm
          referral={referral}
          setCloseModal={setShowClientModal}
          onComplete={(clientRecordId) =>
            hasRdf ? promptToSyncRDF(clientRecordId) : handleLinkClientRecord(clientRecordId, false, false)
          }
        />
      )}

      {selectedClientRecordId && (
        <Modal
          open={showConfirmSync}
          title="Sync Referral Custom Fields"
          onCancel={() => {
            setShowConfirmSync(false);
            setSelectedClientRecordId('');
          }}
          footer={null}
        >
          Do you want to sync the referral custom fields to client data?
          <div className={styles.promptButtons}>
            <ButtonAlt onClick={() => handleLinkClientRecord(selectedClientRecordId, true, isLinkPackageToClient)}>
              Yes
            </ButtonAlt>
            ,
            <ButtonAlt
              variant="text"
              onClick={() => handleLinkClientRecord(selectedClientRecordId, false, isLinkPackageToClient)}
            >
              No
            </ButtonAlt>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ReferralItem;
