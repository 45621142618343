import classNames from 'classnames';
import ButtonAlt from '../ButtonAlt/ButtonAlt';

import styles from './ModalHeader.module.scss';

interface ModalHeaderProps {
  title: string | JSX.Element;
  currentStep?: number;
  totalSteps?: number;
  backLabel?: string;
  onBack?: () => void;
  onCancel: () => void;
  hideStepProcessBar?: boolean;
}

const ModalHeader = ({
  title,
  currentStep,
  totalSteps,
  backLabel,
  onBack,
  onCancel,
  hideStepProcessBar
}: ModalHeaderProps) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>{title}</div>
      {!hideStepProcessBar && (
        <>
          {currentStep && currentStep > 1 && (
            <ButtonAlt variant={'text'} size={'small'} onClick={onBack} icon={'arrow_back_ios'}>
              {backLabel || 'BACK'}
            </ButtonAlt>
          )}
          {totalSteps && currentStep && (
            <div className={styles.processContainer}>
              <div className={styles.processText}>
                STEP {currentStep} OF {totalSteps}
              </div>
              <div className={styles.processBarContainer}>
                {Array(totalSteps)
                  .fill(null)
                  .map((item, index) => (
                    <div
                      key={index}
                      className={classNames(styles.processBar, {
                        [styles.active]: index < currentStep
                      })}
                    />
                  ))}
              </div>
            </div>
          )}
        </>
      )}
      <button onClick={onCancel} className={styles.closeButton}>
        <span className="material-icons-outlined">close</span>
      </button>
    </div>
  );
};

export default ModalHeader;
