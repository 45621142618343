import { Modal, notification } from 'antd';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { Provider } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { updateProvider } from 'utils/http/BillingService/Invoice/claimingcom';

import styles from './EditProviderModal.module.scss';

interface EditProviderModalProps {
  visible: boolean;
  provider: Provider & { locationName: string };
  clinicianId?: string;
  setShowEditProviderModal: (bool: boolean) => void;
  refetchClinicianMedicareProviders: () => void;
}

const EditProviderModal = ({
  visible,
  provider,
  clinicianId,
  setShowEditProviderModal,
  refetchClinicianMedicareProviders
}: EditProviderModalProps) => {
  const { token } = useGetAccessToken();
  const { locationName, locationMinorId, providerNumber, name } = provider;
  const [providerName, setProviderName] = useState(name);
  const [providerNameValidationError, setProviderNameValidationError] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');

  const handleCloseModal = () => {
    setShowEditProviderModal(false);
  };

  const onChangeProviderName = (value: string) => {
    if (providerNameValidationError && value.length > 2 && value.length < 501) {
      setProviderNameValidationError(false);
    }
    setProviderName(value);
  };

  const onSubmit = async () => {
    setSubmitStatus('active');
    if (providerName === '' || providerName.length < 3 || providerName.length > 500) {
      setProviderNameValidationError(true);
      setSubmitStatus('');
      return;
    }
    try {
      const response = await updateProvider(
        token,
        locationMinorId,
        providerNumber,
        {
          name: providerName
        },
        clinicianId
      );

      if (response.statusCode === 400) {
        const error = await response.json();
        notification.error({
          message: error.message,
          duration: 5,
          closeIcon: <span className="success">OK</span>
        });
      } else if (response.statusCode === 204) {
        notification.success({
          message: 'Provider details updated',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        refetchClinicianMedicareProviders();
        handleCloseModal();
        setSubmitStatus('finished');
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to edit provider' });
    }
    setSubmitStatus('');
  };

  return (
    <Modal
      className={styles.modalContainer}
      open={visible}
      closable
      title={<div className={styles.title}>Edit Provider Name</div>}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <div className={styles.inputwithErrorContainer}>
            <MaterialInput
              id={'providerName'}
              label={'Provider Name*'}
              value={providerName}
              onChange={(e) => onChangeProviderName(e.target.value)}
              required
            />
            <div className={providerNameValidationError ? styles.showError : styles.hiddenError}>
              Please enter the provider name (3 - 500 characters)
            </div>
          </div>
          <div className={styles.locationContainer}>
            <MaterialInput
              containerClassName={styles.locationInputContainer}
              id={'locationName'}
              label={'Location Name'}
              value={locationName}
              disabled
              showDisabledLock
              disabledTooltips={'Location name and ID cannot be changed'}
            />
            <div className={styles.locationDisplayContainer}>
              <div className={styles.label}>Location ID:</div>
              <div className={styles.value}>{provider.locationMinorId}</div>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <MaterialInput
              id={'providerNumber'}
              label={'Provider Number'}
              value={providerNumber}
              disabled
              showDisabledLock
              disabledTooltips={'Provider number cannot be changed'}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button className={styles.button} onClick={onSubmit} status={submitStatus}>
            Edit Provider Name
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditProviderModal;
