import { PaymentMethod } from 'pages/Invoices/interface';
import styles from './PaymentDetail.module.scss';
import { PAYMENT_TYPE_LABELS } from 'pages/Invoices/components/PaymentMethods/PaymentMethods';
import PaymentMethodDetail from './components/PaymentMethodDetail';

interface PaymentDetailsProps {
  paymentMethods: PaymentMethod[];
}

const PaymentDetail = ({ paymentMethods }: PaymentDetailsProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Payment Details</div>
      <div className={styles.paymentContent}>
        {paymentMethods.map((paymentMethod, index) => (
          <div className={styles.paymentBlock} key={index}>
            <div className={styles.subtitle}>{PAYMENT_TYPE_LABELS[paymentMethod.paymentType]}</div>
            <PaymentMethodDetail paymentMethod={paymentMethod} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentDetail;
