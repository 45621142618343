import { useState, useEffect, useMemo } from 'react';
import { getClientAppointmentsByDateRange } from 'utils/http/ScheduleService/Appointments/Appointments';
import { notification } from 'antd';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import moment from 'moment';
import { convertTimeToUtcDateObject } from 'utils/helpers/timezone';
import momentTz from 'moment-timezone';

export const useFetchAppointmentsByPatient = ({
  from = '2020-01-01',
  to = moment(new Date()).add(1, 'years').format('YYYY-MM-DD'),
  token,
  clientRecordId,
  showCreatedBy,
  showHistories,
  showDeletedAppointments,
  timeZone
}: {
  from?: string;
  to?: string;
  token: string;
  clientRecordId: string;
  showCreatedBy?: boolean;
  showHistories?: boolean;
  showDeletedAppointments?: boolean;
  timeZone?: string;
}) => {
  const [appointmentsData, setAppointmentsData] = useState<AppointmentSlots[]>([]);
  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(true);

  const tzConvertedAppointmentsData = useMemo(
    () =>
      timeZone
        ? appointmentsData.map((appointment) => {
            if (appointment.startDateTime && appointment.endDateTime) {
              const startDateTime = new Date(appointment.startDateTime!);
              const endDateTime = new Date(appointment.endDateTime!);

              return {
                ...appointment,
                date: momentTz.tz(startDateTime, timeZone).format('YYYY-MM-DD'),
                startTime: momentTz.tz(startDateTime, timeZone).format('HH:mm'),
                endTime: momentTz.tz(endDateTime, timeZone).format('HH:mm')
              };
            }

            return appointment;
          })
        : appointmentsData,
    [appointmentsData, timeZone]
  );

  const fetchAppointments = async () => {
    setIsAppointmentsLoading(true);

    try {
      const getAppointmentResponse = await getClientAppointmentsByDateRange({
        token,
        from,
        to,
        clientRecordId,
        showCreatedBy,
        showHistories,
        showDeletedAppointments
      });

      const appointments = (await getAppointmentResponse.json()) as AppointmentSlots[];

      setAppointmentsData(
        appointments.map((appointment: AppointmentSlots) => {
          if (!appointment.startDateTime || !appointment.endDateTime) {
            const startDateTime =
              appointment.startDateTime ||
              convertTimeToUtcDateObject({
                date: appointment.date || moment().format('YYYY-MM-DD'),
                time: appointment.startTime,
                timeZone
              }).toUTCString();

            const endDateTime =
              appointment.endDateTime ||
              convertTimeToUtcDateObject({
                date: appointment.date || moment().format('YYYY-MM-DD'),
                time: appointment.endTime,
                timeZone
              }).toUTCString();

            return { ...appointment, startDateTime, endDateTime };
          }

          return appointment;
        })
      );
    } catch (ex) {
      notification.error({ message: "Something went wrong while trying to get this patient's appointment" });
    }

    setIsAppointmentsLoading(false);
  };

  useEffect(() => {
    if (token && clientRecordId) {
      fetchAppointments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clientRecordId]);

  return { appointmentsData, tzConvertedAppointmentsData, isAppointmentsLoading, fetchAppointments };
};
