import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';

export const MEDICARE_ROLES_OPTIONS = [
  {
    label: 'Clinical Psychologist',
    value: ClinicianMedicareRole.ClinicalPsychologists
  },
  {
    label: 'Registered Psychologist',
    value: ClinicianMedicareRole.RegisteredPsychologists
  },
  {
    label: 'Occupational Therapist',
    value: ClinicianMedicareRole.OccupationalTherapists
  },
  {
    label: 'Social Worker',
    value: ClinicianMedicareRole.SocialWorkers
  },
  {
    label: 'General Practitioner',
    value: ClinicianMedicareRole.GeneralPractitioner
  },
  {
    label: 'Dietitian',
    value: ClinicianMedicareRole.Dietitian
  },
  {
    label: 'Practice Nurse',
    value: ClinicianMedicareRole.PracticeNurse
  },
  {
    label: 'Mental Health Nurse',
    value: ClinicianMedicareRole.MentalHealthNurse
  },
  {
    label: 'Nurse Practitioner',
    value: ClinicianMedicareRole.NursePractitioner
  },
  {
    label: 'Speech Pathologist',
    value: ClinicianMedicareRole.SpeechPathologist
  }
];
