import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { ROLE_TYPE_OPTIONS } from 'components/AddPatientModalV2/formType/ChildForm/components/ChildGuardianForm/ChildGuardianForm';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import styles from './MemberDetailList.module.scss';

interface MemberDetailListProps {
  client: clientRecordsInterface;
}

const MemberDetailList = ({ client }: MemberDetailListProps) => {
  const findRoleLabel = (roleVal?: string) => {
    const roleLabel = ROLE_TYPE_OPTIONS.find((obj) => obj.value === roleVal);
    return roleLabel ? roleLabel.label : 'Other';
  };

  return (
    <div className={styles.container}>
      {client.clientProfiles.map((clientObj, index) => (
        <div className={styles.detailWrapper} key={index}>
          {(client.recordType !== 'child' || clientObj.role === 'child') && (
            <ClientAvatar
              avatarUrl={clientObj.avatar}
              initialsName={clientObj.initials}
              containerClassName={styles.avatar}
            />
          )}
          <div className={styles.moreThanOne}>
            {client.recordType !== 'child' || clientObj.role === 'child' ? (
              <div className={styles.name}>{clientObj.name}</div>
            ) : (
              <>
                <div className={styles.role}>{findRoleLabel(clientObj.role).toUpperCase()}</div>
                <div className={styles.clientContact}>
                  <div className={styles.mobileAndEmailLabel}>Name</div>
                  <span className={styles.mobileNumberEmail}>{clientObj.name}</span>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MemberDetailList;
