import styles from './PaginationListV2.module.scss';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';

interface PaginationListV2Props {
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
  onPageChange: (page: number) => void;
}

const PaginationListV2 = ({ paging, onPageChange }: PaginationListV2Props) => {
  const onClickPageNumber = (value: any) => {
    onPageChange(value);
  };

  return (
    <ReactPaginate
      previousLabel={
        <span className={styles.label}>
          <i className={classNames(`material-icons`, styles.arrowLeft)}>arrow_back_ios</i>
        </span>
      }
      nextLabel={
        <span className={styles.label}>
          <i className={classNames(`material-icons`, styles.arrowRight)}>arrow_forward_ios</i>
        </span>
      }
      breakLabel={'...'}
      pageCount={Math.ceil(paging.totalItems / paging.perPage) || 1}
      forcePage={paging.page - 1}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={(val) => onClickPageNumber(val.selected + 1)}
      containerClassName={styles.pagination}
      activeClassName={styles.active}
      previousClassName={styles.btn}
      nextClassName={styles.btn}
    />
  );
};

export default PaginationListV2;
