import { notification } from 'antd';
import { debounce } from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { canClientReceiveEmail, canClientReceiveSms } from 'utils/helpers/checkClientCommunicationPreference';
import { clientRecordsInterface } from '../../../interfaces/Clients/clientsRecord';
import {
  getClientsRecord,
  getClientsRecordByAccountId
} from '../../http/ClinicianProfileService/ClientRecords/clientRecords';
import { useGetAccountPackageView } from '../GetAccountInfo/accountPackageView';
import { useGetAccountId } from '../GetAccountInfo/getAccountId';
import { useDebounce } from '../useDebounce';
import { config } from 'config/config';

export const massageClientRecordData = (clientRecordData: clientRecordsInterface[]) =>
  clientRecordData.map((clientRecordObj) => ({
    ...clientRecordObj,
    allowCommunicationWithEmail: clientRecordObj.clientProfiles.some(
      (profileObj) =>
        profileObj.role !== 'child' &&
        profileObj.isPrimaryContact &&
        canClientReceiveEmail({
          communicationPreference: profileObj.communicationPreference
        }) &&
        profileObj.hasEmail
    ),
    allowCommunicationWithSms: clientRecordObj.clientProfiles.some(
      (profileObj) =>
        profileObj.role !== 'child' &&
        profileObj.isPrimaryContact &&
        canClientReceiveSms({
          communicationPreference: profileObj.communicationPreference
        }) &&
        profileObj.hasMobileNumber
    )
  }));

export type LoadMoreClients = () => void;

export const useFetchClientRecordList = ({
  noStatusFilter,
  signUpOnly,
  token,
  usePagination
}: {
  token: string;
  signUpOnly?: boolean;
  noStatusFilter?: boolean;
  usePagination?: boolean;
}) => {
  const [t] = useTranslation();
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionist, isNormalUserView } = useGetAccountPackageView();

  const [clientList, setClientList] = useState<clientRecordsInterface[]>([]);
  const [hasMoreClients, setHasMoreClients] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const [isClientListLoading, setIsClientListLoading] = useState(false);
  const [loadingMoreClients, setLoadingMoreClients] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [page, setPage] = useState(1);
  const [searchActivated, setSearchActivated] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, config.debounceDelay);

  const fetchClients = useCallback(async () => {
    try {
      const recordStatusByType = isNormalUserView ? 'active' : 'active,waitlist';
      const queryParam = {
        ...(!noStatusFilter && { recordStatus: recordStatusByType }),
        ...(debouncedSearchTerm && { q: debouncedSearchTerm }),
        ...(signUpOnly && { hasSignedUp: signUpOnly }),
        // TODO: set using enable pagination as default after all consuming components is ready
        ...(usePagination && { page, perPage: config.perPage }),
        sortByClientName: 1
      };
      const generateQueryParam = queryString.stringify(queryParam);
      const qParam = generateQueryParam ? `?${generateQueryParam}` : '';

      const clientsData =
        (isEdgeAdminView || isEdgeReceptionist) && accountId
          ? await (await getClientsRecordByAccountId(token, accountId, qParam)).json()
          : await (await getClientsRecord(token, qParam)).json();

      setHasMoreClients(!!(clientsData.paging.totalItem - clientsData.paging.page * clientsData.paging.perPage > 0));
      setPage((page) => page + 1);

      return massageClientRecordData(clientsData.clientRecords);
    } catch (ex) {
      notification.error({ message: t('form.error.fetch_client_report_listing') });
    }
  }, [
    accountId,
    debouncedSearchTerm,
    isEdgeAdminView,
    isEdgeReceptionist,
    isNormalUserView,
    noStatusFilter,
    page,
    signUpOnly,
    t,
    token,
    usePagination
  ]);

  const fetchAndSetClients = useCallback(async () => {
    if (token && !isClientListLoading) {
      setIsClientListLoading(true);
      const clientRecords = await fetchClients();
      setClientList(clientRecords ?? []);
      setInitialised(true);
      setIsClientListLoading(false);
    }
  }, [fetchClients, token, isClientListLoading]);

  /** Initial fetch of clients list */
  useEffect(() => {
    if (!initialised) {
      fetchAndSetClients();
    }
  }, [fetchAndSetClients, initialised]);

  const loadMoreClients: LoadMoreClients = async () => {
    if (hasMoreClients && !loadingMoreClients) {
      setLoadingMoreClients(true);
      const clientRecords = await fetchClients();
      if (clientRecords) {
        setClientList((existing) => [...existing, ...clientRecords]);
      }
      setLoadingMoreClients(false);
    }
  };

  const searchClients = useMemo(
    () =>
      debounce(async () => {
        if (token) {
          setLoadingSearch(true);
          const clientRecords = await fetchClients();
          if (clientRecords) {
            setClientList(clientRecords);
          }
          setLoadingSearch(false);
        }
      }, 1000),
    [fetchClients, token]
  );

  // Reset the page number to 1 so that search result is correct
  useEffect(() => {
    if (debouncedSearchTerm || searchActivated) {
      setPage(1);
      setSearchActivated(true);
    }
  }, [searchActivated, debouncedSearchTerm]);

  useEffect(() => {
    if (page === 1 && (debouncedSearchTerm || searchActivated)) {
      searchClients();
    }
  }, [page, searchActivated, searchClients, debouncedSearchTerm]);

  return {
    clientList,
    hasMoreClients,
    isClientListLoading,
    loadingMoreClients,
    loadingSearch,
    loadMoreClients,
    searchTerm,
    setSearchTerm
  };
};
