import { useState } from 'react';
import { Divider } from 'antd';
import classNames from 'classnames';

import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import FlexBox from 'components/FlexBox/FlexBox';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectProcessAppointment, setProcessAppointment } from 'redux/processAppointment/processAppointmentSlice';

import styles from './AppointmentInvoiceDetails.module.scss';

const AppointmentInvoiceDetails = () => {
  const dispatch = useAppDispatch();
  const processAppointment = useAppSelector(selectProcessAppointment);
  const { selectedAppointmentType, selectedInvoiceTemplate, selectedInvoiceStatus } = processAppointment;

  const [isCreateInvoiceClicked, setIsCreateInvoiceClicked] = useState<boolean>(false);

  const handleRemoveInvoiceDetails = () => {
    dispatch(
      setProcessAppointment({
        selectedInvoiceTemplate: undefined,
        selectedInvoiceStatus: undefined
      })
    );
    setIsCreateInvoiceClicked(false);
  };

  return (
    <>
      {isCreateInvoiceClicked ? (
        <FlexBox direction="row" alignItems="center" justifyContent="space-between" className={styles.container}>
          <FlexBox direction="row" alignItems="center" spacing={16}>
            <MaterialSelect
              borderless
              className={styles.minWidth180}
              id="selectedInvoiceTemplate"
              label=""
              isSearchable={false}
              options={[{ label: 'Work in progress', value: '1' }]}
              value={selectedInvoiceTemplate}
              onChange={(value: string) =>
                dispatch(
                  setProcessAppointment({
                    selectedInvoiceTemplate: value
                  })
                )
              }
              placeholder="Select invoice template"
            />
            <Divider type="vertical" className={styles.verticalDivider} />
            <MaterialSelect
              borderless
              className={styles.minWidth160}
              id="selectedInvoiceStatus"
              label=""
              isSearchable={false}
              options={[{ label: 'Work in progress', value: '1' }]}
              value={selectedInvoiceStatus}
              onChange={(value: string) =>
                dispatch(
                  setProcessAppointment({
                    selectedInvoiceStatus: value
                  })
                )
              }
              placeholder="Set invoice status"
            />
          </FlexBox>
          <div className={styles.deleteButtonWrapper} onClick={handleRemoveInvoiceDetails}>
            <i className={'material-icons-outlined'}>delete_outline</i>
          </div>
        </FlexBox>
      ) : (
        <FlexBox direction="row" alignItems="center" spacing={20} className={styles.defaultContainer}>
          <ButtonAlt
            variant="outlined"
            onClick={() => setIsCreateInvoiceClicked(!isCreateInvoiceClicked)}
            icon="add_circle_outline"
            disabled
            // Temporary disabled now
            // disabled={!!selectedAppointmentType?.claimType}
          >
            Create Invoice
          </ButtonAlt>
          {selectedAppointmentType?.claimType ? (
            <div className={styles.billingAutomationSetBadge}>
              Billing Automation Set
              <span className={classNames('material-icons-outlined', styles.icon)}>flash_on</span>
            </div>
          ) : (
            <div className={styles.noBillingAutomationSetBadge}>
              No Billing Automation
              <span className={classNames('material-icons-outlined', styles.icon)}>flash_off</span>
            </div>
          )}
        </FlexBox>
      )}
    </>
  );
};

export default AppointmentInvoiceDetails;
