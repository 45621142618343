import { EpisodeInterface } from 'interfaces/Episodes/episodes';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { defaultClientProfileDropdownOptions, clientProfileDropdownShowAllDataOption } from '../interface/constant';

interface GenerateDropdownOptionsPayload {
  activeEpisode?: EpisodeInterface;
  episodes?: EpisodeInterface[];
  noDefaultValue?: boolean;
}

export const generateClientProfileDropdownOptions = (payload: GenerateDropdownOptionsPayload): IOptionItem[] => {
  const { activeEpisode, episodes, noDefaultValue } = payload;

  let options: IOptionItem[] = [];

  if (activeEpisode?._id) {
    options.push({
      ...activeEpisode,
      label: activeEpisode._id,
      value: activeEpisode._id
    });
  }

  if (episodes && episodes.length) {
    options = options.concat(
      episodes.map((item) => ({
        ...item,
        label: item._id,
        value: item._id
      }))
    );
  }

  if (!noDefaultValue) {
    if (!options.length) {
      options = options.concat(clientProfileDropdownShowAllDataOption);
    } else {
      options = options.concat(defaultClientProfileDropdownOptions);
    }
  }

  return options;
};
