import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import LetterList from './components/LetterList/LetterList';
import styles from './LettersList.module.scss';

const ReportsList = () => {
  return (
    <HelmetWrapper title={'Tacklit - Letters'}>
      <ContentLayout>
        <div className={styles.title}>Letters</div>
        <LetterList />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ReportsList;
