import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { SubTabContext } from 'layouts/AuthLayoutT23/context/SubTabContextProvider';
import { useContext } from 'react';
import ReportCreateNewModal from './components/ReportCreateNewModal/ReportCreateNewModal';
import ReportHeader from './components/ReportHeader/ReportHeader';
import ReportList from './components/ReportList/ReportList';

const ReportsList = () => {
  const createNewReport = () => {
    setShowCreateNewReportModal(true);
  };

  const { showCreateNewReportModal, setShowCreateNewReportModal } = useContext(SubTabContext);

  return (
    <HelmetWrapper title={'Tacklit - Reports'}>
      <ContentLayout>
        <ReportHeader onClick={createNewReport} />
        <ReportList onClickNewReport={createNewReport} />
        <ReportCreateNewModal
          isShowCreateNewModal={showCreateNewReportModal}
          onCloseModal={() => setShowCreateNewReportModal(false)}
        />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ReportsList;
