import styles from './PsychometricDetailHeader.module.scss';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { combineName } from 'utils/general';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface PsychometricDetailHeaderProps {
  goBack: () => void;
  assessmentClientId: string;
  clientProfile: clientProfilesInterface[];
}

const PsychometricDetailHeader = ({ assessmentClientId, clientProfile, goBack }: PsychometricDetailHeaderProps) => {
  return (
    <div className={styles.container}>
      <ButtonAlt
        size={'medium'}
        contentClassName={styles.goBackContent}
        variant={'text'}
        className={styles.goBack}
        onClick={goBack}
        icon={'chevron_left'}
      >
        Back
      </ButtonAlt>
      <div className={styles.info}>
        <ClientAvatar clientData={clientProfile} displayLimit={1} hideName />
        <div className={styles.names}>
          <div className={styles.profileName}>{combineName(clientProfile)}</div>
          <div className={styles.psychometricName}>{assessmentClientId}</div>
        </div>
      </div>
    </div>
  );
};

export default PsychometricDetailHeader;
