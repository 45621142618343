import { ChangeEvent, useEffect } from 'react';
import { useField } from 'formik';

import { Invoice } from 'pages/Invoices/interface';
import { InvoicedItem } from '../../../../interface';

import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';

import styles from './IncludeDiscount.module.scss';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { config } from 'config/config';

const MONEY_SYMBOL = config.currencySymbol;

interface IncludeDiscountProps {
  clientRecord?: clientRecordsInterface;
  draftInvoice?: Partial<Invoice>;
  isDiscounted: boolean;
  onIsDiscountedChange: () => void;
  setIsDiscounted: (isDiscounted: boolean) => void;
}

const calculateDiscount = (discountType: 'percent' | 'amount', discountValue: string, items: InvoicedItem[]) => {
  const discountValueNumber = Number(discountValue);
  const totalCost = items
    .map((item) => Number(item.cost))
    .reduce((finalTotal, currentCost) => finalTotal + currentCost, 0);

  if (isNaN(discountValueNumber) || !totalCost) {
    return;
  } else if (discountType === 'percent') {
    if (discountValueNumber <= 100) {
      return (totalCost * Math.round(discountValueNumber)) / 100;
    } else {
      return totalCost;
    }
  } else if (discountType === 'amount') {
    if (discountValueNumber <= totalCost) {
      return discountValueNumber;
    } else {
      return totalCost;
    }
  }
};

const IncludeDiscount = ({
  clientRecord,
  draftInvoice,
  isDiscounted,
  onIsDiscountedChange,
  setIsDiscounted
}: IncludeDiscountProps) => {
  const [{ value: discountType }, , { setValue: setDiscountTypeValue }] = useField<'percent' | 'amount'>(
    'discount.type'
  );
  const [{ value: discountValue }, , { setValue: setDiscountValueValue }] = useField('discount.value');
  const [{ value: items }] = useField<InvoicedItem[]>('items');

  const total = calculateDiscount(discountType, discountValue, items);

  useEffect(() => {
    if (clientRecord?.invoiceSummary?.settings?.discount && !draftInvoice) {
      const { active, type, value } = clientRecord.invoiceSummary.settings.discount;

      if (active) {
        setDiscountTypeValue(type);
        setDiscountValueValue(String(value));
        setIsDiscounted(true);
      } else {
        setIsDiscounted(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRecord]);

  const handleDiscountTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setDiscountTypeValue('amount');
    } else {
      setDiscountTypeValue('percent');
    }
  };

  const handleDiscountValueBlur = () => {
    const discountValueNumber = Number(discountValue);

    if (isNaN(discountValueNumber)) {
      return;
    } else if (discountType === 'percent') {
      setDiscountValueValue(Math.round(discountValueNumber).toString());
    } else if (discountType === 'amount') {
      setDiscountValueValue(discountValueNumber.toFixed(2));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.discountInputs}>
        <label className={styles.checkboxContainer} htmlFor="checkbox-discount">
          <input
            className={styles.checkbox}
            id="checkbox-discount"
            type="checkbox"
            checked={isDiscounted}
            onChange={onIsDiscountedChange}
          />
          <div className={styles.label}>Include discount</div>
        </label>
        {isDiscounted && (
          <>
            <label className={styles.switchToggle} htmlFor="switch-discount-type">
              <span className={styles.label}>%</span>
              <input
                id="switch-discount-type"
                name="switch-discount-type"
                type="checkbox"
                checked={discountType === 'amount'}
                onChange={handleDiscountTypeChange}
              />
              <span className={styles.indicator} />
              <span className={styles.label}>{MONEY_SYMBOL}</span>
            </label>
            <div className={styles.discountInput}>
              <FormikMaterialInput
                id={'discount.value'}
                name="discount.value"
                label=""
                onBlurCapture={handleDiscountValueBlur}
              />
            </div>
          </>
        )}
      </div>
      {isDiscounted && total !== undefined && !isNaN(total) && (
        <div className={styles.discountValue}>
          - {MONEY_SYMBOL}
          {total.toFixed(2)}
        </div>
      )}
    </div>
  );
};

export default IncludeDiscount;
