import { notification } from 'antd';
import { InvoiceWithType } from 'pages/InvoicesV2/components/InvoiceList/constants';
import { FullRefundResponse, useFullRefundMutation } from 'redux/endpoints/billingServices/invoices';

export const useFullRefund = () => {
  const [postFullRefund] = useFullRefundMutation();

  const onFullRefund = async (invoice: InvoiceWithType, onSuccess?: Function) => {
    try {
      notification.warning({
        message: 'Processing refund...'
      });

      const result: FullRefundResponse = await postFullRefund({
        accountId: invoice.accountId,
        invoiceId: invoice._id
      }).unwrap();
      notification.destroy();

      if (result.success === result.total) {
        notification.success({
          message:
            'Refund processed successfully. Your customer will see the refund as a credit in approximately 5-10 business days depending upon their bank.'
        });
        onSuccess && onSuccess();
      } else if (result.failed === result.total) {
        notification.error({
          message: 'Refund attempt failed. Please try again, if failures persist please contact us to investigate.'
        });
      } else {
        notification.error({
          message: 'Refund attempt failed. Please try again, if failures persist please contact us to investigate.'
        });
        onSuccess && onSuccess();
      }
    } catch (ex) {
      notification.error({
        message: 'Refund attempt failed. Please try again, if failures persist please contact us to investigate.'
      });
    }
  };

  return { onFullRefund };
};
