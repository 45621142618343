import ListHeaderItem from 'components/T23/ListHeaderItem/ListHeaderItem';
import { SubmittedClaimListColumn } from './columns';
import styles from './SubmittedClaimListHeader.module.scss';
import columnStyles from './SubmittedClaimColumn.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectActiveTab,
  selectRejectedWriteOffSorting,
  selectSorting,
  setRejectedWriteOffSorting,
  setSorting,
  SubmittedClaimTabs
} from 'redux/features/submittedClaims/submittedClaimSlice';
import { getColumnNameFromId } from 'components/T23/ListHeaderItem/helper';
import { SortType } from 'redux/features/utils';

const SubmittedClaimListHeader = () => {
  const dispatch = useAppDispatch();
  const sorting = useAppSelector(selectSorting);
  const rejectedWriteOffSorting = useAppSelector(selectRejectedWriteOffSorting);
  const activeTab = useAppSelector(selectActiveTab);

  const { sortBy, sortType } = sorting;
  const { sortBy: rejectedWriteOffSortBy, sortType: rejectedWriteOffSortType } = rejectedWriteOffSorting;

  const onClickSort = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      dispatch(setSorting({ ...sorting, sortType: sortType === SortType.ASC ? SortType.DESC : SortType.ASC }));
    } else {
      dispatch(setSorting({ ...sorting, sortBy: newSortBy, sortType: SortType.DESC }));
    }
  };

  const onClickRejectedWriteOffSort = (newSortBy: string) => {
    if (newSortBy === rejectedWriteOffSortBy) {
      dispatch(
        setRejectedWriteOffSorting({
          ...rejectedWriteOffSorting,
          sortType: rejectedWriteOffSortType === SortType.ASC ? SortType.DESC : SortType.ASC
        })
      );
    } else {
      dispatch(setRejectedWriteOffSorting({ ...rejectedWriteOffSorting, sortBy: newSortBy, sortType: SortType.DESC }));
    }
  };

  const isRejectedWriteOffTab = [SubmittedClaimTabs.Rejected, SubmittedClaimTabs.WriteOff].includes(activeTab);

  return (
    <div className={styles.container}>
      {SubmittedClaimListColumn.map((column, index) => (
        <ListHeaderItem
          key={index}
          columnId={column.id}
          className={columnStyles[column.id]}
          sortBy={isRejectedWriteOffTab ? rejectedWriteOffSortBy : sortBy}
          sortType={isRejectedWriteOffTab ? rejectedWriteOffSortType : sortType}
          columnName={column.label || getColumnNameFromId(column.id)}
          sortAble={isRejectedWriteOffTab && column.id === 'status' ? false : column.sortAble}
          onSort={isRejectedWriteOffTab ? onClickRejectedWriteOffSort : onClickSort}
        />
      ))}
      <div className={columnStyles.actions} />
    </div>
  );
};

export default SubmittedClaimListHeader;
