import { useState } from 'react';
import styles from './ReferrerIndividualForm.module.scss';

import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import { REFERRER_ROLES } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsReferrers/components/ReferralsMVP/components/AddOrEditReferralForm/AddOrEditReferralForm';
import ReferrerSearchField from '../ReferrerSearchField/ReferrerSearchField';
import classNames from 'classnames';
import {
  ReferralFieldsPayload,
  ReferralValidateField
} from 'pages/Referrals/components/AddReferralModal/AddReferralModalInterfaces';
import { ReferralFormMode } from '../ReferrerForm/ReferrerForm';
// import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface ReferrerIndividualFormProps {
  referrerFields: ReferralFieldsPayload['referrer'];
  errorMessage: ReferralValidateField['referrer'];
  showSearch?: boolean;
  mode: ReferralFormMode;
  onChange: (key: string, val: string) => void;
}

const ReferrerIndividualForm = ({
  referrerFields,
  errorMessage,
  showSearch,
  onChange: handleChangeFieldValue,
  mode
}: ReferrerIndividualFormProps) => {
  // const { isAddressBookFeatureToggle } = useGetFeatureToggle(); // REFERRALTODO: enable address book integration
  const isAddressBookFeatureToggle = false;

  const [isCreateIndividual, setIsCreateIndividual] = useState<boolean>(
    mode === ReferralFormMode.Edit
      ? !!referrerFields.firstName || !isAddressBookFeatureToggle
      : !isAddressBookFeatureToggle
  );

  const fetchContacts = async (search: string) => {
    return [];
  };

  return (
    <div className={styles.container}>
      {showSearch && !isCreateIndividual && (
        <ReferrerSearchField
          fetchData={fetchContacts}
          onClickAdd={(search) => {
            handleChangeFieldValue('firstName', search);
            setIsCreateIndividual(true);
          }}
        />
      )}

      {(isCreateIndividual || !showSearch) && (
        <>
          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <MaterialInput
                id="individual-firstName"
                label="Referrer first name"
                maxLength={20}
                onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
                value={referrerFields.firstName}
                error={!!errorMessage.firstName}
                errorMessage={errorMessage.firstName}
                required
              />
            </div>
          </div>

          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <MaterialInput
                id="individual-lastName"
                label="Referrer last name"
                maxLength={20}
                onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
                value={referrerFields.lastName}
                error={!!errorMessage.lastName}
                errorMessage={errorMessage.lastName}
                required
              />
            </div>
          </div>

          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <MaterialSelect
                id="individual-role"
                className={styles.selectOffset}
                label="Role"
                isSearchable
                options={REFERRER_ROLES.map((role) => ({
                  value: role,
                  label: role
                }))}
                value={referrerFields.role}
                onChange={(value) => handleChangeFieldValue('role', value)}
                noCreateNewOption
                error={!!errorMessage.role}
                errorMessage={errorMessage.role}
              />
            </div>

            <div className={classNames(styles.fieldContainer, styles.fieldOffset)}>
              <MaterialInput
                id="individual-providerNumber"
                label="Provider number"
                maxLength={30}
                onChange={(e) => handleChangeFieldValue('providerNumber', e.target.value)}
                value={referrerFields.providerNumber}
              />
            </div>
          </div>

          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <MaterialInput
                id="individual-email"
                label="Referrer email"
                maxLength={200}
                onChange={(e) => handleChangeFieldValue('email', e.target.value)}
                value={referrerFields.email}
                required
                icon="email"
              />
            </div>
          </div>

          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <MaterialPhoneInput
                id="individual-mobile"
                autoFormat={false}
                disableCountryCode
                hideFlag
                inputName="mobile"
                label="Referrer phone number"
                onChange={(value = '') => {
                  handleChangeFieldValue('mobile', value);
                }}
                placeholder=""
                isError={false}
                value={referrerFields.mobile || ''}
                inputClass={styles.transparentInput}
                icon="phone"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReferrerIndividualForm;
