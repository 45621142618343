import styles from './PackageTypeBadge.module.scss';
import { PACKAGES_TYPE_LABELS, PackagesType } from 'interfaces/Packages/packages';

export interface PackageStatusBadgeProps {
  type: PackagesType;
  isDisable?: boolean;
}

const PackageTypeBadge = ({ type, isDisable }: PackageStatusBadgeProps) => (
  <div className={styles[isDisable ? 'disable' : type]}>
    <div>{PACKAGES_TYPE_LABELS[type].toUpperCase()}</div>
  </div>
);

export default PackageTypeBadge;
