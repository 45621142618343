import { documentServicesApiSlice, DSTagTypes } from 'redux/services/documentServicesApiSlice';
import { ConsentFormInterface } from 'interfaces/ConsentForm/ConsentFormInterface';

export const consentFormApiSlice = documentServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConsentDetailsById: builder.query<ConsentFormInterface, { consentFormId: string }>({
      query: ({ consentFormId }) => ({
        url: `/consent-forms/${consentFormId}`
      }),
      providesTags: [DSTagTypes.consentFormDetails]
    })
  })
});

export const { useGetConsentDetailsByIdQuery } = consentFormApiSlice;
