import { Skeleton } from 'antd';
import { useState } from 'react';

import LinkButton from 'components/Button/LinkButton/LinkButton';
import FlexBox from 'components/FlexBox/FlexBox';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { PaymentMethod } from 'pages/Invoices/interface';
import PaymentMethodsModal from 'pages/Invoices/components/PaymentMethods/components/PaymentMethodsModal/PaymentMethodsModal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  RecipientTypes,
  selectInvoiceTemplate,
  setInvoiceTemplate
} from 'redux/invoiceTemplate/invoiceTemplateCreateFormSlice';

import FieldTitle from './components/FieldTitle/FieldTitle';
import SelectPaymentMethods from './components/SelectPaymentMethods/SelectPaymentMethods';
import SelectDefaultSendTo from './components/SelectDefaultSendTo/SelectDefaultSendTo';
import {
  addOrRemoveFromList,
  addOrRemoveFromRecipients,
  addOrRemoveSelectedKeyClientContacts
} from './helpers/helpers';
import styles from './BasicDetailsForm.module.scss';

interface BasicDetailsFormProps {
  availablePaymentMethods: PaymentMethod[];
  isPaymentMethodsLoading: boolean;
  refetchPaymentMethods: () => void;
}

const BasicDetailsForm = ({
  availablePaymentMethods,
  isPaymentMethodsLoading,
  refetchPaymentMethods
}: BasicDetailsFormProps) => {
  const dispatch = useAppDispatch();
  const invoiceTemplate = useAppSelector(selectInvoiceTemplate);
  const { name, description, shortCode, paymentMethods, sendTo } = invoiceTemplate;
  const [showPaymentSettingsModal, setShowPaymentSettingsModal] = useState(false);

  const handlePaymentSettingsModalClose = (paymentMethods?: PaymentMethod[]) => {
    setShowPaymentSettingsModal(false);
    paymentMethods && refetchPaymentMethods();
  };

  const templateDetailsField = [
    {
      name: 'name',
      label: 'Name of the template*',
      value: name
    },
    {
      name: 'description',
      label: 'Short description of invoice template',
      value: description
    },
    {
      name: 'shortCode',
      label: 'Invoice ID short code',
      value: shortCode
    }
  ];

  return (
    <div className={styles.container}>
      <PaymentMethodsModal
        paymentMethods={availablePaymentMethods}
        isPaymentMethodsLoading={isPaymentMethodsLoading}
        visible={showPaymentSettingsModal}
        onClose={handlePaymentSettingsModalClose}
      />
      <FlexBox direction="column" spacing={20}>
        {templateDetailsField.map((item) => (
          <MaterialInput
            key={`templateDetails-${item.name}`}
            id={`${item.name}-input`}
            name={item.name}
            label={item.label}
            value={item.value}
            onChange={(e) =>
              dispatch(
                setInvoiceTemplate({
                  [e.target.name]: e.target.value
                })
              )
            }
            required
          />
        ))}
        {isPaymentMethodsLoading ? (
          <Skeleton active />
        ) : (
          <>
            <FieldTitle
              title="Payment Methods"
              desc={
                <FlexBox direction="row" alignItems="center">
                  To add or update please&nbsp;
                  <LinkButton onClick={() => setShowPaymentSettingsModal(!showPaymentSettingsModal)}>
                    visit the payment settings
                  </LinkButton>
                </FlexBox>
              }
              tooltipId="payment-methods"
              tooltipMessage="Select applied payment methods for this invoice template and access to payment settings."
            />
            {availablePaymentMethods.map((item) => (
              <SelectPaymentMethods
                key={`invoice-template-select-${item._id}`}
                checked={paymentMethods.includes(item._id)}
                item={item}
                onSelectPaymentMethod={() =>
                  dispatch(
                    setInvoiceTemplate({
                      paymentMethods: addOrRemoveFromList({
                        list: paymentMethods,
                        target: item._id
                      })
                    })
                  )
                }
              />
            ))}
          </>
        )}
        <FieldTitle
          title="Default Send To"
          desc={
            <FlexBox direction="row" alignItems="center">
              This setting will be overridden by any contract specific rules on sending
            </FlexBox>
          }
          tooltipId="default-send-to"
          tooltipMessage="Select default invoice receivers of this invoice template."
        />
        {[
          {
            type: RecipientTypes.Client
          },
          {
            type: RecipientTypes.KeyClientContact
          },
          {
            type: RecipientTypes.Funder
          }
        ].map((item) => (
          <SelectDefaultSendTo
            key={`invoice-template-select-sendTo-${item.type}`}
            checked={sendTo.some((x) => x.type === item.type)}
            item={item}
            onSelectRecipient={(selectedType) =>
              dispatch(
                setInvoiceTemplate({
                  sendTo: addOrRemoveFromRecipients({
                    list: sendTo,
                    target: selectedType
                  })
                })
              )
            }
            onSelectKeyClientContact={(selectedTags) =>
              dispatch(
                setInvoiceTemplate({
                  sendTo: addOrRemoveSelectedKeyClientContacts({
                    list: sendTo,
                    selectedTags: selectedTags,
                    selectedType: item.type
                  })
                })
              )
            }
          />
        ))}
      </FlexBox>
    </div>
  );
};

export default BasicDetailsForm;
