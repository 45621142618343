import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import AppointmentTypeSelect from 'components/v2/AppointmentTypeSelect/AppointmentTypeSelect';
import AvailableTimeSelect from 'components/v2/AvailableTimeSelect/AvailableTimeSelect';
import { SelectedAppointmentSlot } from 'components/v2/AvailableTimeSelect/components/DayCard/DayCard';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import CustomiseSchedule from 'components/v2/CustomiseSchedule/CustomiseSchedule';
import FormSection from 'components/v2/FormSection/FormSection';
import { Formik } from 'formik';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { AppointmentType, DeliveryType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { convertTimeToUtcDateObject } from 'utils/helpers/timezone';

import { appointmentSchema } from '../../../EventCreationModal/components/EventForm/components/ScheduleForm/constants';
import styles from './EditEvent.module.scss';
import DeliveryModeSelect from 'components/v2/DeliveryModeSelect/DeliveryModeSelect';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import RoomOption from '../../../EventCreationModal/components/EventForm/components/ScheduleForm/components/RoomOption/RoomOption';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import AppointmentTypeDetails from './components/AppointmentTypeDetails/AppointmentTypeDetails';

interface EditEventProps {
  appointment: AppointmentSlots;
  appointmentType?: AppointmentType;
  timeZone?: string;
  handleUpdateAppointment: (payload: any, setSubmitStatus: Function) => void;
}

const EditEvent = ({ appointment, appointmentType, timeZone, handleUpdateAppointment }: EditEventProps) => {
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [changeNote, setChangeNote] = useState('');
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(appointmentType);
  const [selectedSlot, setSelectedSlot] = useState<SelectedAppointmentSlot>();
  const [isCustomising, setIsCustomising] = useState<boolean>(!!appointment.isActivity);
  const [errorMessage, setErrorMessage] = useState('');
  const { isEdgeUser } = useGetAccountPackageView();
  const isChangingAppointmentType = appointment?.sessionTypeId !== selectedAppointmentType?._id;
  const isChangingDeliveryType =
    isChangingAppointmentType &&
    appointment.deliveryType &&
    !selectedAppointmentType?.deliveryOptions.includes(appointment.deliveryType);

  const { isProcessAppointmentEnabled } = useGetFeatureToggle();

  const onSelectAppointmentType = (newAppointmentType?: AppointmentType) => {
    setSelectedAppointmentType(
      newAppointmentType
        ? {
            ...appointmentType,
            ...newAppointmentType
          }
        : appointmentType
    );
    setSelectedSlot(undefined);
  };

  const handleChangeSlot = (slot?: SelectedAppointmentSlot) => {
    setSelectedSlot(slot);
    setErrorMessage('');
  };

  const handleUpdate = async (values: any) => {
    const newDeliveryType = isChangingDeliveryType ? values.deliveryType : appointment.deliveryType;

    handleUpdateAppointment(
      {
        appointmentTypeId: selectedAppointmentType?._id,
        date: selectedSlot?.date,
        endDate: selectedSlot?.endDate,
        timeZone,
        startTime: moment(selectedSlot?.startTime, 'hh:mmA').format('HH:mm'),
        endTime: moment(selectedSlot?.endTime, 'hh:mmA').format('HH:mm'),
        ...(selectedSlot?.startDateTime &&
          selectedSlot.endDateTime && {
            startDateTime: convertTimeToUtcDateObject({
              date: selectedSlot?.date,
              time: moment(selectedSlot?.startTime, 'hh:mmA').format('HH:mm'),
              timeZone
            }),
            endDateTime: convertTimeToUtcDateObject({
              date: selectedSlot?.date,
              time: moment(selectedSlot?.endTime, 'hh:mmA').format('HH:mm'),
              timeZone
            })
          }),
        note: changeNote,
        customised: isCustomising,
        deliveryType: newDeliveryType,
        ...(isChangingDeliveryType &&
          newDeliveryType === DeliveryType.FaceToFace &&
          values.faceToFaceLocation && { faceToFaceLocation: values.faceToFaceLocation }),
        ...(isChangingDeliveryType &&
          newDeliveryType === DeliveryType.VideoCall &&
          values.videoCallInstructions && { videoCallInstructions: values.videoCallInstructions }),
        ...(isChangingDeliveryType &&
          newDeliveryType === DeliveryType.PhoneCall &&
          values.phoneCallInstructions && { phoneCallInstructions: values.phoneCallInstructions }),
        ...(isChangingDeliveryType &&
          newDeliveryType === DeliveryType.PhoneCallDialClient &&
          values.phoneCallDialClientInstructions && {
            phoneCallDialClientInstructions: values.phoneCallDialClientInstructions
          }),
        ...(isChangingDeliveryType &&
          newDeliveryType === DeliveryType.Other &&
          values.otherInstructions && { otherInstructions: values.otherInstructions }),
        room: (values.roomInfo && JSON.parse(values.roomInfo)) || values.room || appointment.room,
        sessionTypeId: selectedAppointmentType?._id,
        sessionTypeName: selectedAppointmentType?.name
      },
      setSubmitStatus
    );
  };
  return (
    <Formik initialValues={{}} validationSchema={appointmentSchema} onSubmit={handleUpdate}>
      {({ submitForm }) => (
        <>
          <div className={styles.container}>
            {appointment.deliveryType !== DeliveryType.Bespoke ? (
              <>
                {(!isProcessAppointmentEnabled || appointment.groupId) && (
                  <>
                    {appointment.packageId ? (
                      <AppointmentTypeDetails
                        clinicianId={appointment.clinicianId || ''}
                        selectedParticipantType={selectedAppointmentType?.participantType || ParticipantType.OneToOne}
                        selectedAppointmentTypeId={appointment.sessionTypeId || ''}
                      />
                    ) : (
                      <FormSection title="Appointment Type">
                        <AppointmentTypeSelect
                          clinicianId={appointment.clinicianId || ''}
                          onSelect={onSelectAppointmentType}
                          horizontal
                          hideTitle
                          preSelectedAppointmentTypeId={appointment.sessionTypeId}
                          selectedParticipantType={selectedAppointmentType?.participantType || ParticipantType.OneToOne}
                        />
                      </FormSection>
                    )}
                  </>
                )}
                {!_.isEmpty(selectedAppointmentType?._id) && (
                  <div>
                    {isCustomising ? (
                      <CustomiseSchedule
                        timeslot={selectedSlot}
                        bookedAppointment={appointment}
                        onChangeSchedule={setSelectedSlot}
                        setIsCustomising={setIsCustomising}
                        timeZone={timeZone}
                        appointmentType={selectedAppointmentType}
                      />
                    ) : (
                      <AvailableTimeSelect
                        appointmentTypeId={selectedAppointmentType?._id || ''}
                        appointmentTypeName={selectedAppointmentType?.name || ''}
                        selectedSlot={selectedSlot}
                        onSelectSlot={handleChangeSlot}
                        selectedClinicianId={appointment.clinicianId}
                        setIsCustomising={setIsCustomising}
                        selectedRoomId={appointment.room?.roomId}
                        timeZone={timeZone}
                      />
                    )}
                  </div>
                )}

                {!_.isEmpty(selectedAppointmentType?._id) && isChangingDeliveryType && (
                  <>
                    {selectedAppointmentType?.deliveryOptions && (
                      <div className={styles.deliveryModeContainer}>
                        <FormSection title="Delivery Mode">
                          <div className={styles.deliveryModeContainer}>
                            <DeliveryModeSelect appointmentType={selectedAppointmentType} showAdditionalInput />
                          </div>
                        </FormSection>
                      </div>
                    )}
                    {isEdgeUser && selectedAppointmentType?.faceToFaceLocation && (
                      <div className={styles.roomOptionContainer}>
                        <RoomOption appointmentType={selectedAppointmentType} />
                      </div>
                    )}
                  </>
                )}

                <ErrorMessage error={errorMessage} visible={!!errorMessage} />

                {!appointment.isActivity && (
                  <div className={styles.changeNoteContainer}>
                    <div className={styles.label}>Include a change note</div>
                    <textarea className={styles.textarea} onChange={(e) => setChangeNote(e.target.value)} />
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          {selectedAppointmentType?._id && !_.isEmpty(selectedSlot) && (
            <div className={styles.footer}>
              <div>
                <div className={styles.label}>CHANGING TO</div>
                <div className={styles.detailsContainer}>
                  <div className={styles.data}>{selectedAppointmentType?.name}</div>
                  <div className={styles.label}>ON</div>
                  {selectedSlot && (
                    <div className={styles.data}>
                      <div>{moment(`${selectedSlot.date}`, 'YYYY-MM-DD').format('dddd Do MMMM')}</div>
                      <div>
                        {moment(`${selectedSlot.startTime}`, 'HH:mm').format('hh:mm')}
                        {' to '}
                        {moment(`${selectedSlot.endTime}`, 'HH:mm').format('hh:mm a')}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <ButtonAlt status={submitStatus} className={styles.button} onClick={submitForm}>
                Reschedule Appointment
              </ButtonAlt>
            </div>
          )}
        </>
      )}
    </Formik>
  );
};

export default EditEvent;
