import PaginationListV2 from 'components/v2/PaginationListV2/PaginationListV2';
import { getClaimInvoiceDetailsInfo } from 'pages/InvoicePendingClaims/utils';
import CreateClaimModal from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/components/ClaimHistory/components/CreateClaimModal/CreateClaimModal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectBatchOfClaims,
  selectCreateClaimModalVisible,
  selectDataReadyPaging,
  selectDataNotReadyPaging,
  selectSelectedClaimInvoiceId,
  setBatchOfClaims,
  setCreateClaimModalVisible,
  setDataReadyPaging,
  setDataNotReadyPaging,
  PendingClaimTabs,
  selectWriteOffFormValue,
  setWriteOffFormValue,
  setIsShowWriteOffModal,
  setSelectedClaimInvoiceId,
  selectIsShowWriteOffModal,
  selectAbandonedPaging,
  setAbandonedPaging
} from 'redux/features/pendingClaimInvoices/pendingClaimInvoiceSlice';
import PendingClaimInvoiceFilter from '../PendingClaimInvoiceFilter/PendingClaimInvoiceFilter';
import PendingClaimInvoiceItem from '../PendingClaimInvoiceItem/PendingClaimInvoiceItem';
import PendingClaimInvoiceListHeader from '../PendingClaimInvoiceListHeader/PendingClaimInvoiceListHeader';
import styles from './PendingClaimInvoiceList.module.scss';
import { Skeleton, notification } from 'antd';
import { getCreateClaimPayload, getSelectedClaimType } from './utils';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { InvoiceWithProjectedMbsCode } from 'interfaces/invoices/pendingClaimInvoices';
import { createClaim } from 'utils/http/BillingService/Invoice/claimingcom';
import { useGetAccessToken } from 'utils/hooks/token';
import { useState } from 'react';
import Loading from 'components/Loading/Loading';
import { useFetchMedicareLocations } from 'pages/ControlPanel/IntegrationDetails/components/IntegrationDetailsContent/components/IntegrationDetailsContentDisplay/components/Integration/hooks/getMedicareData';
import { putResendInvoice } from 'utils/http/BillingService/Invoice/invoice';
import { BSTagTypes, billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetPendingClaims } from 'redux/features/pendingClaimInvoices/useGetPendingClaims';
import { selectActiveTab } from 'redux/features/pendingClaimInvoices/pendingClaimInvoiceSlice';
import { WriteOffForm } from 'redux/invoices/invoiceSlice';
import WriteOffModal from 'pages/InvoicesV2/components/WriteOffModal/WriteOffModal';
import { usePatchAbandonedPendingClaimMutation } from 'redux/endpoints/billingServices/pendingClaimInvoice';

export interface PendingClaimInvoiceListQueryParams {
  page?: string;
  perPage?: string;
  clinicianAuth0Ids?: string;
  groupIds?: string;
  clients?: string[];
  funder?: string;
  code?: string;
  status?: string;
}

const PendingClaimInvoiceList = () => {
  const { token } = useGetAccessToken();
  const { INVOICES } = useRoutesGenerator();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dataReadyPaging = useAppSelector(selectDataReadyPaging);
  const dataNotReadyPaging = useAppSelector(selectDataNotReadyPaging);
  const abandonedPaging = useAppSelector(selectAbandonedPaging);
  const selectedClaimInvoiceId = useAppSelector(selectSelectedClaimInvoiceId);
  const isCreateClaimModalVisible = useAppSelector(selectCreateClaimModalVisible);
  const batchOfClaims = useAppSelector(selectBatchOfClaims);
  const activeTab = useAppSelector(selectActiveTab);
  const isShowWriteOffModal = useAppSelector(selectIsShowWriteOffModal);
  const writeOffFormValue = useAppSelector(selectWriteOffFormValue);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { locations } = useFetchMedicareLocations(token, true);
  const [patchAbandonedPendingClaim] = usePatchAbandonedPendingClaimMutation();

  const { isLoading, isError, data, refetch } = useGetPendingClaims();

  const selectedClaim = data?.invoices.find((item) => item._id === selectedClaimInvoiceId);

  const {
    clientProfile,
    parentProfile,
    isDvaClient,
    isMedicareClient,
    isGPReferralProviderNumberSet,
    isReferralDateSet
  } = getClaimInvoiceDetailsInfo(selectedClaim?.foundClientRecord, selectedClaim?.foundGeneralPractitioner);

  const locationsFiltered = locations.filter(({ type }) => !isDvaClient || type);

  const onChangePage = (page: number) => {
    if (activeTab === PendingClaimTabs.Abandoned) {
      dispatch(setAbandonedPaging({ ...abandonedPaging, page }));
    } else if (activeTab === PendingClaimTabs.DataReady) {
      dispatch(setDataReadyPaging({ ...dataReadyPaging, page }));
    } else {
      dispatch(setDataNotReadyPaging({ ...dataNotReadyPaging, page }));
    }
    dispatch(setBatchOfClaims([]));
  };

  const onSelectInvoice = (invoiceId: string) => {
    navigate(`${INVOICES.BASE}/${invoiceId}`);
  };

  const refetchListAfterCreateClaim = () => {
    dispatch(billingServicesApiSlice.util.invalidateTags([BSTagTypes.PendingClaims, BSTagTypes.SubmittedClaims]));
    refetch();
  };

  const onSubmitClaim = async (selectedClaim: InvoiceWithProjectedMbsCode) => {
    setIsSubmitting(true);
    const payload = getCreateClaimPayload(selectedClaim);
    try {
      const response = await createClaim(
        token,
        selectedClaim.foundProvider?.locationMinorId || '',
        selectedClaim.foundProvider?.providerNumber || '',
        payload
      );
      setIsSubmitting(false);
      if (response.statusCode === 201) {
        notification.success({
          message: 'Claim created'
        });
        refetchListAfterCreateClaim();
      } else if (response.statusCode === 400 || response.statusCode === 404) {
        const { message } = (await response.json()) as { message: 'string' };
        notification.error({
          message
        });
      } else {
        notification.error({ message: 'Something went wrong while trying to create this claim' });
      }
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to create this claim' });
      setIsSubmitting(false);
      console.error(ex);
    }
  };

  const onResendInvoice = async (invoiceId: string) => {
    if (token) {
      try {
        setIsSubmitting(true);
        await putResendInvoice(token, invoiceId);
        notification.success({
          message: 'Successfully resent this invoice!',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to resend this invoice' });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const onAbandonPendingClaim = async (accountId: string, invoiceId: string) => {
    try {
      setIsSubmitting(true);
      await patchAbandonedPendingClaim({ accountId, invoiceId }).unwrap();
      notification.success({
        message: 'Successfully abandoned invoice!',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to abandoned invoice.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onCheckClaimItems = (isChecked: boolean, invoice: InvoiceWithProjectedMbsCode) => {
    if (isChecked) {
      dispatch(setBatchOfClaims([...batchOfClaims, invoice]));
    } else {
      dispatch(setBatchOfClaims(batchOfClaims.filter((item) => item._id !== invoice._id)));
    }
  };

  const onClickCheckButton = () => {
    if (batchOfClaims.length === invoiceIsClaimReadyData.length) {
      dispatch(setBatchOfClaims([]));
    } else {
      dispatch(setBatchOfClaims(invoiceIsClaimReadyData));
    }
  };

  const onCloseWriteOffModalHandle = () => {
    dispatch(setWriteOffFormValue({ reference: '', date: '' }));
    dispatch(setIsShowWriteOffModal(false));
    dispatch(setSelectedClaimInvoiceId(undefined));
  };

  const onChangeWriteOffFormValue = (value: Partial<WriteOffForm>) => {
    dispatch(setWriteOffFormValue(value));
  };

  const onWriteOffSuccess = () => {
    dispatch(billingServicesApiSlice.util.invalidateTags([BSTagTypes.PendingClaims, BSTagTypes.WriteOffInvoices]));
  };

  const onSubmitBatchPayments = async () => {
    setIsSubmitting(true);

    const invoicesToBeSubmitted = batchOfClaims.map((claim) => {
      const payload = getCreateClaimPayload(claim);
      return createClaim(
        token,
        claim.foundProvider?.locationMinorId || '',
        claim.foundProvider?.providerNumber || '',
        payload
      );
    });

    try {
      await Promise.all(invoicesToBeSubmitted).then((responses) => {
        const countOfFails = responses.filter((res) => res.statusCode !== 201).length;
        if (countOfFails === 0) {
          refetchListAfterCreateClaim();
          notification.success({
            message: `Success, ${responses.length} Claim${
              responses.length > 1 ? 's have' : ' has'
            } been submitted successfully.`
          });
        } else if (countOfFails === responses.length) {
          notification.error({
            message: `Failed, ${responses.length} Claim${
              responses.length > 1 ? 's have' : ' has'
            } been submitted failed.`
          });
        } else {
          refetchListAfterCreateClaim();
          notification.info({
            message: `${responses.length - countOfFails} Claim${
              responses.length - countOfFails > 1 ? 's have' : ' has'
            } been submitted successfully.${
              countOfFails > 0
                ? ` ${countOfFails} Claim${
                    countOfFails > 1 ? 's have' : ' has'
                  } been submitted failed. The tickets not submitted successfully stays in pending claims and the successfully submitted moves to submitted claims.`
                : ''
            }`
          });
        }
      });
      dispatch(setBatchOfClaims([]));
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to submit claims' });
      console.error(ex);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isExistLocationWithType = locations.filter((location) => location.type).length > 0;
  const numberOfBatchClaims = batchOfClaims.length;
  const invoiceIsClaimReadyData = data?.invoices.filter((invoice) => invoice.isClaimReady) || [];

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div className={styles.container}>
        {/* Filters */}
        <div className={styles.filterWrapper}>
          <PendingClaimInvoiceFilter totalItems={!isError ? data?.paging.totalItems || 0 : 0} isLoading={isLoading} />
        </div>
        <div className={styles.listWrapper}>
          <PendingClaimInvoiceListHeader
            isSelectedAll={numberOfBatchClaims === invoiceIsClaimReadyData.length}
            isExistClaimDataReady={invoiceIsClaimReadyData.length > 0 && !isLoading}
            onSelectClick={onClickCheckButton}
          />
          {isLoading ? (
            <div className={styles.loadingWrapper}>
              {[...Array(10)].map((obj, i) => (
                <div key={i} className={styles.loadingItem}>
                  <Skeleton.Input active className={styles.loadingContent} />
                </div>
              ))}
            </div>
          ) : isError || (data?.invoices && data.invoices.length < 1) ? (
            <div>No pending claims</div>
          ) : (
            <div className={styles.listOfInvoices}>
              {data?.invoices.map((invoice, index) => (
                <PendingClaimInvoiceItem
                  key={index}
                  invoice={invoice}
                  isExistLocationWithType={isExistLocationWithType}
                  onSubmitClaim={() => onSubmitClaim(invoice)}
                  onSelect={() => onSelectInvoice(invoice._id)}
                  onResendInvoice={() => onResendInvoice(invoice._id)}
                  isChecked={!!batchOfClaims.find((item) => item._id === invoice._id)}
                  onCheck={(isChecked: boolean) => onCheckClaimItems(isChecked, invoice)}
                  onAbandon={() => onAbandonPendingClaim(invoice.accountId, invoice._id)}
                />
              ))}
            </div>
          )}
        </div>

        {/* Pagination */}
        {!isError && !isLoading && data?.paging && data.paging.totalItems > data.paging.perPage && (
          <div className={styles.paginationWrapper}>
            <PaginationListV2 paging={data.paging} onPageChange={onChangePage} />
          </div>
        )}

        {/* Submit batch button */}
        {numberOfBatchClaims > 0 && (
          <div className={styles.submitBatchClaimsWrapper}>
            <ButtonAlt onClick={onSubmitBatchPayments}>
              {`Submit ${numberOfBatchClaims} Claim${numberOfBatchClaims > 1 ? 's' : ''}`}
            </ButtonAlt>
          </div>
        )}
      </div>

      {/* Create Claim modal */}
      {selectedClaim && clientProfile && (
        <CreateClaimModal
          parentProfile={parentProfile}
          visible={isCreateClaimModalVisible}
          clientRecordId={selectedClaim.foundClientRecord._id || ''}
          clientProfile={clientProfile}
          isMedicareClient={isMedicareClient}
          isDvaClient={isDvaClient}
          referral={{
            name: selectedClaim.foundGeneralPractitioner?.name || '',
            date: selectedClaim.foundClientRecord.referral?.date || '',
            providerNumber: selectedClaim.foundGeneralPractitioner?.medicareProviderNumber || ''
          }}
          onCancel={() => {
            dispatch(setCreateClaimModalVisible(false));
          }}
          preSelectedStep={2}
          hideStepProcessBar
          preSelectedInvoice={selectedClaim}
          preSelectedClaimType={getSelectedClaimType(selectedClaim.funder)}
          locations={locationsFiltered}
          refetchClaims={refetchListAfterCreateClaim}
          isClientReferralValid={isGPReferralProviderNumberSet && isReferralDateSet}
        />
      )}

      {selectedClaimInvoiceId && (
        <WriteOffModal
          invoiceRecordId={selectedClaimInvoiceId}
          showModal={isShowWriteOffModal}
          formValue={writeOffFormValue}
          successMessage="Claim written off. New write off created."
          onCloseModal={onCloseWriteOffModalHandle}
          onChangeValue={onChangeWriteOffFormValue}
          onSubmitSuccess={onWriteOffSuccess}
        />
      )}
    </>
  );
};

export default PendingClaimInvoiceList;
