import { notification } from 'antd';
import { useState } from 'react';
import styles from './SessionIntroModal.module.scss';
import ModalV2 from 'components/ModalV2/ModalV2';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { putGroupSessionDetails } from 'utils/http/ScheduleService/Groups/groups';
import { useGetAccessToken } from 'utils/hooks/token';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface SessionIntroModalProps {
  visible: boolean;
  onCloseModal: () => void;
  fieldValue: string;
  onChangeValue: (value: string) => void;
  groupId: string;
  sessionId: string;
}

const MAX_LIMIT = 1000;

const SessionIntroModal = ({
  visible,
  onCloseModal,
  fieldValue,
  groupId,
  sessionId,
  onChangeValue
}: SessionIntroModalProps) => {
  const { token } = useGetAccessToken();
  const [messageContent, setMessageContent] = useState(fieldValue);
  const [errors, setErrors] = useState<{ message?: boolean }>({});

  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [checkValidation, setCheckValidation] = useState(false);

  const handleSubmit = async () => {
    setPageSubmitStatus('active');
    setCheckValidation(true);

    try {
      const payload = {
        instructions: messageContent
      };
      await putGroupSessionDetails(token, groupId, sessionId, payload);
      setPageSubmitStatus('finished');
      notification.success({
        message: 'Introduction message saved',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setTimeout(() => {
        setPageSubmitStatus('');
        setCheckValidation(false);
        onChangeValue(messageContent);
        onCloseModal();
      }, 2000);
    } catch (ex) {
      console.error(ex);
      setPageSubmitStatus('');
      notification.error({
        message: 'Introduction message fail to save',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const onChangeMessage = (val: string) => {
    setMessageContent(val);
    if (checkValidation) {
      setErrors({
        message: val.length <= 0
      });
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={visible}
      title={'Instructions for this session'}
      onModalClose={onCloseModal}
    >
      <div className={styles.container}>
        <div className={styles.textAreaField}>
          <textarea
            className={styles.textarea}
            rows={4}
            maxLength={MAX_LIMIT}
            value={messageContent}
            onChange={(e) => onChangeMessage(e.target.value)}
          />
          <div className={styles.wordCount}>
            <span className={styles.count}>{MAX_LIMIT - messageContent.length}</span> characters remaining
          </div>
        </div>
        <ErrorMessage visible={!!errors.message} error={'Message content cannot be blank'} />
      </div>
      <div className={styles.submitButtonContainer}>
        <ButtonAlt status={pageSubmitStatus} disabled={pageSubmitStatus !== ''} onClick={handleSubmit}>
          Save
        </ButtonAlt>
      </div>
    </ModalV2>
  );
};

export default SessionIntroModal;
