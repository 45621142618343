export interface IFormatOption {
  value: string;
  label: string;
}

export const BROADCAST_OPTIONS = [
  {
    value: 'sms',
    label: 'SMS'
  }
];

export const BROADCAST_SMS_CHARACTER_LIMIT = 640;

export enum SendToOption {
  AllClient = 'allClient',
  CustomClient = 'customClient',
  CustomGroup = 'customGroup'
}

export enum SendTime {
  Now = 'now',
  CustomDate = 'customDate'
}

export const SEND_TIME_OPTIONS = [
  { value: SendTime.Now, label: 'Now' },
  { value: SendTime.CustomDate, label: 'Custom schedule' }
];
