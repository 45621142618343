import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PracticeInterface } from 'interfaces/Profile/Profile';
import { RootState } from 'redux/store';

export const defaultPracticeData: PracticeInterface = {
  logo: '',
  name: '',
  slugUrl: '',
  mobileNumber: '',
  locations: [''],
  registrationNumber: '',
  financeEmail: '',
  formalName: '',
  inboundFaxNumbers: [],
  faxNumber: ''
};

interface PracticeState {
  data: PracticeInterface;
  isLoading: boolean;
}

const initialState: PracticeState = {
  data: defaultPracticeData,
  isLoading: false
};

const practiceDataSlice = createSlice({
  name: 'practice',
  initialState,
  reducers: {
    setPracticeData: (state, action: PayloadAction<PracticeInterface>) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    setPracticeDataLoading: (state) => {
      state.isLoading = true;
    }
  }
});

export const practiceInfo = (state: RootState) => state.practiceDataSlice;

export const { setPracticeData, setPracticeDataLoading } = practiceDataSlice.actions;

export default practiceDataSlice.reducer;
