import { useContext } from 'react';

import { useGetAccessToken } from 'utils/hooks/token';

import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import AssessmentContent from './components/AssessmentContent/AssessmentContent';
import AssessmentHeader from './components/AssessmentHeader/AssessmentHeader';
import { useFetchFormList } from './hooks/GetFormData';

import styles from './Assessments.module.scss';
import SendAdhocAssessmentModalV2 from './components/SendAdhocAssessmentModalV2/SendAdhocAssessmentModalV2';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import {
  NotificationPreference,
  useFetchNotificationSettings
} from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';
import { SubTabContext } from 'layouts/AuthLayoutT23/context/SubTabContextProvider';

const Assessments = () => {
  const { token } = useGetAccessToken();
  // const [sendAdhocAssessmentVisible, setSendAdhocAssessmentVisible] = useState(false);

  const { formListData, isFormListDataLoading } = useFetchFormList(token);
  const { accountId } = useGetAccountId();
  const { notificationSettings, isNotificationSettingsLoading } = useFetchNotificationSettings(token, accountId);

  const { setSendAdhocAssessmentVisible, sendAdhocAssessmentVisible } = useContext(SubTabContext);

  return (
    <HelmetWrapper title={'Tacklit - Forms'}>
      <ContentLayout>
        {isFormListDataLoading || isNotificationSettingsLoading ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : (
          <div className={styles.container}>
            <AssessmentHeader
              onSendAdhocAssessmentClick={() => setSendAdhocAssessmentVisible(!sendAdhocAssessmentVisible)}
              disableSendAdhocAssessmentButton={[NotificationPreference.None].includes(notificationSettings.forms)}
            />
            <AssessmentContent formListData={formListData} />
            <SendAdhocAssessmentModalV2
              token={token}
              open={sendAdhocAssessmentVisible}
              onCancel={() => setSendAdhocAssessmentVisible(false)}
              notificationSettings={notificationSettings}
            />
          </div>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Assessments;
