import { FaxTag } from 'interfaces/fax';
import { useCallback, useEffect, useState } from 'react';
import { FaxDetails, getFaxDetails } from 'utils/http/NotifService/fax';
import { useGetAccountId } from './GetAccountInfo/getAccountId';
import { useGetAccessToken } from './token';

export interface UpdateFaxDetailsParams {
  clientRecordId?: string;
  faxId: string;
  tag?: FaxTag;
}

export const useFaxDetails = (faxId: string) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();

  const [faxDetails, setFaxDetails] = useState<FaxDetails>();
  const [isFaxDetailsLoading, setIsFaxDetailsLoading] = useState(true);

  const fetchFaxDetails = useCallback(async () => {
    if (token && accountId) {
      setIsFaxDetailsLoading(true);
      const fetchedFaxDetails = await getFaxDetails({ accountId, faxId, token });
      setFaxDetails(fetchedFaxDetails);
      setIsFaxDetailsLoading(false);
    }
  }, [accountId, faxId, token]);

  useEffect(() => {
    fetchFaxDetails();
  }, [fetchFaxDetails]);

  return { faxDetails, isFaxDetailsLoading, fetchFaxDetails };
};
