import { Provider } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { BSTagTypes, billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';

export interface GetClinicianMedicareProvider {
  isAdmin: boolean;
  clinicianId?: string;
  activeOnly?: boolean;
}

export const clinicianMedicareProvider = billingServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyMedicareProvider: builder.query<Provider[], { activeOnly: boolean }>({
      query: ({ activeOnly }) => ({
        url: `/accounts/me/claimingcom/providers?${activeOnly ? 'active=true' : ''}`
      }),
      transformResponse: (response: { providers: Provider[] }) => response.providers,
      providesTags: [BSTagTypes.MyMedicareProvider]
    }),

    getClinicianMedicareProvider: builder.query<Provider[], GetClinicianMedicareProvider>({
      query: ({ isAdmin, clinicianId, activeOnly }) => {
        if (!clinicianId && isAdmin) {
          return { url: `/accounts/me/claimingcom/providers?${activeOnly ? 'active=true' : ''}` };
        }
        return { url: `/clinicians/${clinicianId || 'me'}/claimingcom/providers?${activeOnly ? 'active=true' : ''}` };
      },
      providesTags: [BSTagTypes.ClinicianMedicareProvider],
      transformResponse: (response: { providers: Provider[] }) => response.providers
    })
  })
});

export const { useGetMyMedicareProviderQuery, useGetClinicianMedicareProviderQuery } = clinicianMedicareProvider;
