import { SSTagTypes, scheduleServicesApiSlice } from 'redux/services/scheduleServicesApiSlice';
import { GetInvoicePaymentQueryParams } from '../billingServices/invoicePayments';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import queryString from 'query-string';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

export type ScheduledPayment = AppointmentSlots & {
  clientRecord: Pick<clientRecordsInterface, '_id' | 'clientProfiles' | 'caseId' | 'tacklitId'>;
};

interface GetScheduledPaymentResponseData {
  appointments: ScheduledPayment[];
  paging: { page: number; perPage: number; totalItems: number };
}

export const scheduledPaymentsApiSlice = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getScheduledPayments: builder.query<
      GetScheduledPaymentResponseData,
      { accountId: string; params: GetInvoicePaymentQueryParams }
    >({
      query: ({ accountId, params }) => {
        const stringifiedQuery = params ? queryString.stringify(params) : '';
        return {
          url: `accounts/${accountId}/scheduled-payments?${stringifiedQuery}`
        };
      },
      providesTags: [SSTagTypes.ScheduledPayment]
    })
  })
});

export const { useGetScheduledPaymentsQuery } = scheduledPaymentsApiSlice;
