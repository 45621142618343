export enum LetterTemplateStatus {
  Draft = 'draft',
  Published = 'published'
}

export interface LetterTemplate {
  _id: string;
  accountId: string;
  status: LetterTemplateStatus;
  title: string;
  body: string;
  shareable: boolean;
  createdBy: {
    clinicianAuth0Id: string;
    name: string;
  };
  createdAt: Date;
  updatedAt: Date;
}

export type PutLetterTemplateRequest = Pick<LetterTemplate, 'status' | 'title' | 'body'>;
