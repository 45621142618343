import styles from './AppointmentItem.module.scss';
import columnStyles from 'pages/Tasks/components/TasksHeader/TasksColumn.module.scss';
import momentTz from 'moment-timezone';
import moment from 'moment';
import classNames from 'classnames';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useMemo, useState } from 'react';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { ReviewAppointment } from 'redux/endpoints/scheduleServices/processAppointment';
import { ServiceDeliveredNameMapping } from 'interfaces/Schedule/Appointment';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { combineName } from 'utils/general';
import AppointmentHistory from './ReviewItemHistory/AppointmentHistory';
import ActionMenu from './ActionMenu/ActionMenu';
import { config } from 'config/config';

interface AppointmentItemProps {
  appointment: ReviewAppointment;
  actionMenuAllowed?: boolean;
}

const AppointmentItem = ({ appointment, actionMenuAllowed }: AppointmentItemProps) => {
  const { CLIENTS } = useRoutesGenerator();
  const { timeZoneByView } = useTimeZone();
  const [isShowHistory, setIsShowHistory] = useState<boolean>(false);

  const onClickHistory = () => {
    setIsShowHistory(!isShowHistory);
  };

  const { clientRecord, _id, requestedChanges, serviceDelivered, date, paid, owed } = appointment;
  const hasHistory = appointment.activityFeeds && appointment.activityFeeds.length > 0;
  const createdInViewTZ = requestedChanges ? momentTz(requestedChanges.createdAt).tz(timeZoneByView) : undefined;

  const reasons = useMemo(() => {
    let result: string[] = [];
    if (requestedChanges) {
      const {
        appointmentTypeId,
        funder,
        waiveCancellationFee,
        deliveryType: requestDeliveryType,
        cancellationWithRefund
      } = requestedChanges;
      if (appointmentTypeId) {
        result.push('APPOINTMENT TYPE');
      }
      if (funder) {
        result.push('CODE TYPE');
      }
      if (waiveCancellationFee) {
        result.push('WAIVE CANCELLATION FEE');
      }
      if (requestDeliveryType) {
        result.push('DELIVERY TYPE');
      }
      if (cancellationWithRefund) {
        result.push('CANCELLATION WITH REFUND');
      }
    }
    return result;
  }, [requestedChanges]);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.itemContainer, isShowHistory && styles.isSelected)}>
        {/* Type */}
        <div className={classNames(columnStyles.type, styles.type)}>
          <div className={styles.info}>
            <i className={`material-icons-outlined ${styles.icon}`}>event_note</i>
            <div className={styles.appointment}>
              <a href={`${CLIENTS.BASE}/${clientRecord?._id}/appointments`} className={styles.appointmentId}>
                {_id}
              </a>
              <div className={styles.appointmentDate}>{moment(date, 'YYYY-MM-DD').format('DD MMM YYYY')}</div>
            </div>
          </div>
          {serviceDelivered && (
            <div className={classNames(styles.serviceDeliveredPill, styles[serviceDelivered])}>
              {ServiceDeliveredNameMapping[serviceDelivered]}
            </div>
          )}
        </div>

        {/* Assigned to hard code fow now */}
        <div className={columnStyles.assignedTo}>
          someone.health
          <div className={styles.pill}>Super User</div>
        </div>

        {/* Created */}
        <div className={classNames(columnStyles.created, styles.created)}>
          {createdInViewTZ ? (
            <>
              <i className={`material-icons-outlined ${styles.icon}`}>person_add_alt</i>
              <div className={styles.createTime}>
                <div>{moment(createdInViewTZ).format('hh:mm')}</div>
                <div>{moment(createdInViewTZ).format('DD MMM YYYY')}</div>
              </div>
            </>
          ) : (
            '-'
          )}
        </div>

        {/* Linked To */}
        <div className={columnStyles.linkedTo}>
          <div className={styles.clientName}>{clientRecord && combineName(clientRecord.clientProfiles)}</div>
          {clientRecord?.caseId && <div className={styles.caseId}>{clientRecord.caseId}</div>}
          {clientRecord?.tacklitId && <div className={styles.tacklitId}>{clientRecord.tacklitId}</div>}
        </div>

        {/* Reason */}
        <div className={classNames(columnStyles.reason, styles.reason)}>
          {reasons.length
            ? reasons.map((reason, index) => (
                <div key={index} className={styles.pill}>
                  {reason}
                </div>
              ))
            : '-'}
        </div>

        {/* Invoice */}
        <div className={columnStyles.invoice}>
          <div className={styles.invoiceItem}>
            <div className={styles.label}>Paid:</div>
            <div className={styles.value}>
              {config.currencySymbol}
              {(paid || 0).toFixed(2)}
            </div>
          </div>
          <div className={styles.invoiceItem}>
            <div className={styles.label}>Owed:</div>
            <div className={styles.value}>
              {config.currencySymbol}
              {(owed || 0).toFixed(2)}
            </div>
          </div>
        </div>

        {/* Action */}
        <div className={columnStyles.actions}>
          {hasHistory && (
            <ButtonAlt
              variant="text"
              size="medium"
              icon={isShowHistory ? 'expand_less' : 'history'}
              fab
              onClick={onClickHistory}
            />
          )}
          {actionMenuAllowed && <ActionMenu appointment={appointment} />}
        </div>
      </div>

      {/* History */}
      {isShowHistory && (
        <div className={styles.historyWrapper}>
          <AppointmentHistory
            activityFeeds={appointment.activityFeeds || []}
            clientName={appointment.clientRecord?.clientProfiles[0].name || ''}
          />
        </div>
      )}
    </div>
  );
};

export default AppointmentItem;
