import classnames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import { config } from 'config/config';

import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';

import styles from './IncludeDiscount.module.scss';
import { useFormikContext } from 'formik';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

const { currencySymbol } = config;

const IncludeDiscount = () => {
  const { values, setFieldValue } = useFormikContext<Required<clientRecordsInterface['invoiceSummary']>['settings']>();
  const [t] = useTranslation();

  const handleDiscountTypeChange = (checked: boolean) => {
    if (checked) {
      setFieldValue('discount.type', 'amount');
    } else {
      setFieldValue('discount.type', 'percent');
    }
  };

  const handleDiscountValueBlur = (value: string) => {
    const discountValueNumber = Number(value);

    if (isNaN(discountValueNumber)) {
      return;
    } else if (values.discount.type === 'percent') {
      setFieldValue('discount.value', Number(Math.round(discountValueNumber).toString()));
    } else if (values.discount.type === 'amount') {
      setFieldValue('discount.value', Number(discountValueNumber.toFixed(2)));
    }
  };

  return (
    <>
      <div className={styles.sectionLabel}>
        {t('invoice.set_default_discount')}
        <i className={`material-icons ${styles.icon}`} data-tooltip-id="default-discount">
          help_outline
        </i>
        <Tooltip id="default-discount" className={styles.tooltip}>
          {t('invoice.set_default_discount.description', { currency: currencySymbol })}
        </Tooltip>
      </div>
      <div className={classnames(styles.item, styles.includeDiscount, values.discount.active && styles.active)}>
        <div className={styles.checkboxContainer}>
          <input
            className={styles.checkbox}
            id="checkbox-discount-active"
            type="checkbox"
            checked={values.discount.active}
            onChange={(e) => setFieldValue('discount.active', e.target.checked)}
          />
          <label className={styles.checkboxLabel} htmlFor="checkbox-discount-active">
            Include Discount
          </label>
        </div>
        <div className={styles.discountContainer}>
          <label className={styles.switchToggle} htmlFor="switch-discount-type">
            <span className={styles.switchLabel}>%</span>
            <input
              id="switch-discount-type"
              name="switch-discount-type"
              type="checkbox"
              checked={values.discount.type === 'amount'}
              onChange={(e) => handleDiscountTypeChange(e.target.checked)}
            />
            <span className={styles.indicator} />
            <span className={styles.switchLabel}>{currencySymbol}</span>
          </label>
          <div className={styles.discountInput}>
            <FormikMaterialInput
              id={'discountValue'}
              name="discount.value"
              label=""
              onBlurCapture={(e) => handleDiscountValueBlur(e.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IncludeDiscount;
