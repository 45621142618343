import RedirectTo404 from 'components/RedirectTo404/RedirectTo404';
import Mentor from 'pages/Mentor/Mentor';
import { Route, Routes } from 'react-router-dom';

const MentorRoutes = () => {
  return (
    <Routes>
      <Route path={':tab/*'} element={<Mentor />} />
      <Route path={'*'} element={<RedirectTo404 />} />
    </Routes>
  );
};

export default MentorRoutes;
