import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { MedicareItem, MedicareItemFormat, MedicareItemMode, MedicareItemDuration } from '../interfaces';

export const REGISTERED_PSYCHOLOGIST_ITEMS: MedicareItem[] = [
  {
    mbsCode: '80100',
    description:
      'Initial individual face to face (consultation rooms) session by a registered psychologist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 68.5,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '80105',
    description:
      'Initial individual face to face (call-out) session by a registered psychologist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 93.15,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '80101',
    description:
      'Initial individual telehealth (geographic eligibiility applies) session by a registered psychologist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 62.55,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '91169',
    description:
      'Initial individual telehealth (video call) session by a registered psychologist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 68.5,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '91183',
    description:
      'Initial individual telehealth (phone call) session by a registered psychologist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 68.5,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '80110',
    description:
      'Initial individual face to face (consultation rooms) session by a registered psychologist, lasting at least 50 minutes.',
    benefit: 96.65,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '80115',
    description:
      'Initial individual face to face (call-out) session by a registered psychologist, lasting at least 50 minutes.',
    benefit: 121.35,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '80111',
    description:
      'Initial individual telehealth (geographic eligibiility applies) session by a registered psychologist, lasting at least 50 minutes.',
    benefit: 88.25,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '91170',
    description:
      'Initial individual telehealth (video call) session by a registered psychologist, lasting at least 50 minutes.',
    benefit: 96.65,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '91184',
    description:
      'Initial individual telehealth (phone call) session by a registered psychologist, lasting at least 50 minutes.',
    benefit: 96.65,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '93350',
    description:
      'Additional individual face to face (consultation rooms) session by a registered psychologist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 62.55,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '93351',
    description:
      'Additional individual telehealth (video call) session by a registered psychologist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 62.55,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '93352',
    description:
      'Additional individual telehealth (phone call) session by a registered psychologist, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 62.55,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '93353',
    description:
      'Additional individual face to face (consultation rooms) session by a registered psychologist, lasting at least 50 minutes.',
    benefit: 88.25,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '93354',
    description:
      'Additional individual telehealth (video call) session by a registered psychologist, lasting at least 50 minutes.',
    benefit: 88.25,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '93355',
    description:
      'Additional individual telehealth (phone call) session by a registered psychologist, lasting at least 50 minutes.',
    benefit: 88.25,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '80120',
    description: 'Group face to face session by a registered psychologist, lasting at least 60 minutes.',
    benefit: 24.65,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: '80121',
    description:
      'Group telehealth (geographic eligibility applies) session by a registered psychologist, lasting at least 60 minutes.',
    benefit: 24.65,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.GroupMoreThan60
  }
];
