import { notification } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { getCheckInInsights } from '../http/CheckInService/ClientGraphInsights/checkInInsights';
import { useGetAccountPackageView } from './GetAccountInfo/accountPackageView';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentEpisode, selectIsOutsideAllEpisodes, selectIsShowAllData } from 'redux/episodes/episodeSlice';
import { EpisodeInterface } from 'interfaces/Episodes/episodes';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface ChartData {
  date: string;
  uv: number;
}

const formatGraphData = (data: { date: string; value: number }[]): ChartData[] =>
  data.map(({ date, value }) => ({ date, uv: value }));

export const calculateDateRange = ({ episode, dateRange }: { episode?: EpisodeInterface; dateRange: number }) => {
  const startDateBaseOnDateRange = moment(episode?.endDate).subtract(dateRange, 'days');
  const startDateIfHaveEpisode = moment(episode?.startDate).isAfter(startDateBaseOnDateRange)
    ? moment(episode?.startDate)
    : startDateBaseOnDateRange;
  const startDate = (!episode ? moment().subtract(dateRange, 'days') : startDateIfHaveEpisode).format('YYYY-MM-DD');
  const endDate = (episode ? moment(episode?.endDate) : moment()).format('YYYY-MM-DD');

  return { startDate, endDate };
};

export const useFetchOverallCheckInGraph = ({
  token,
  clientId,
  profileId
}: {
  token: string;
  clientId: string;
  profileId?: string;
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState(30);
  const [overallCheckInGraph, setOverallCheckInGraph] = useState<ChartData[]>([]);
  const [isOverallCheckInGraphLoading, setIsOverallCheckInGraphLoading] = useState(true);
  const currentEpisode = useAppSelector(selectCurrentEpisode);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const { isEoCEnabled } = useGetFeatureToggle();
  const isSupportEpisode = isEoCEnabled && !isShowAllData;
  const episode = isSupportEpisode ? currentEpisode : undefined;

  const fetchOverallCheckInGraph = async (dateRange: number) => {
    setIsOverallCheckInGraphLoading(true);

    try {
      setSelectedDateRange(dateRange);
      const { startDate, endDate } = calculateDateRange({ episode, dateRange });

      const episodeQuery = isOutsideAllEpisodes ? `&showOutsideOfEpisode=${isOutsideAllEpisodes}` : '';
      const queryParam = `${
        profileId ? `clientRecordId=${clientId}&clientProfileId=${profileId}` : `patientId=${clientId}`
      }&startDate=${startDate}&endDate=${endDate}&type=overall${episodeQuery}`;

      const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights(token, queryParam);
      const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

      if (insights) {
        const dateRangeInsights = isOutsideAllEpisodes ? insights.slice(-dateRange) : insights;
        setOverallCheckInGraph(formatGraphData(dateRangeInsights));
      } else {
        throw new Error();
      }
    } catch (ex) {
      notification.error({
        message: "Something went wrong while trying to get this patient's overall check in insights"
      });
    }

    setIsOverallCheckInGraphLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchOverallCheckInGraph(30);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { overallCheckInGraph, isOverallCheckInGraphLoading, selectedDateRange, fetchOverallCheckInGraph };
};

export const useFetchEnergyCheckInGraph = ({
  token,
  clientId,
  profileId
}: {
  token: string;
  clientId: string;
  profileId?: string;
}) => {
  const currentEpisode = useAppSelector(selectCurrentEpisode);
  const [energyCheckInGraph, setEnergyCheckInGraph] = useState<ChartData[]>([]);
  const [isEnergyCheckInGraphLoading, setIsEnergyCheckInGraphLoading] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState(30);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const { isEoCEnabled } = useGetFeatureToggle();
  const isSupportEpisode = isEoCEnabled && !isShowAllData;
  const episode = isSupportEpisode ? currentEpisode : undefined;

  const fetchEnergyCheckInGraph = async (dateRange: number) => {
    setIsEnergyCheckInGraphLoading(true);
    setSelectedDateRange(dateRange);

    try {
      const { startDate, endDate } = calculateDateRange({ episode, dateRange });
      const episodeQuery = isOutsideAllEpisodes ? `&showOutsideOfEpisode=${isOutsideAllEpisodes}` : '';
      const queryParam = `${
        profileId ? `clientRecordId=${clientId}&clientProfileId=${profileId}` : `patientId=${clientId}`
      }&startDate=${startDate}&endDate=${endDate}&type=energy${episodeQuery}`;

      const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights(token, queryParam);
      const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

      if (insights) {
        const dateRangeInsights = isOutsideAllEpisodes ? insights.slice(-dateRange) : insights;
        setEnergyCheckInGraph(formatGraphData(dateRangeInsights));
      } else {
        throw new Error();
      }
    } catch (ex) {
      notification.error({
        message: "Something went wrong while trying to get this patient's energy check in insights"
      });
    }

    setIsEnergyCheckInGraphLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchEnergyCheckInGraph(30);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { energyCheckInGraph, isEnergyCheckInGraphLoading, selectedDateRange, fetchEnergyCheckInGraph };
};

export const useFetchSleepCheckInGraph = ({
  token,
  clientId,
  profileId
}: {
  token: string;
  clientId: string;
  profileId?: string;
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState(30);
  const [sleepCheckInGraph, setSleepCheckInGraph] = useState<ChartData[]>([]);
  const [isSleepCheckInGraphLoading, setIsSleepCheckInGraphLoading] = useState(true);
  const currentEpisode = useAppSelector(selectCurrentEpisode);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const { isEoCEnabled } = useGetFeatureToggle();
  const isSupportEpisode = isEoCEnabled && !isShowAllData;
  const episode = isSupportEpisode ? currentEpisode : undefined;

  const fetchSleepCheckInGraph = async (dateRange: number) => {
    setIsSleepCheckInGraphLoading(true);
    setSelectedDateRange(dateRange);
    try {
      const { startDate, endDate } = calculateDateRange({ episode, dateRange });
      const episodeQuery = isOutsideAllEpisodes ? `&showOutsideOfEpisode=${isOutsideAllEpisodes}` : '';
      const queryParam = `${
        profileId ? `clientRecordId=${clientId}&clientProfileId=${profileId}` : `patientId=${clientId}`
      }&startDate=${startDate}&endDate=${endDate}&type=sleep${episodeQuery}`;

      const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights(token, queryParam);
      const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

      if (insights) {
        const dateRangeInsights = isOutsideAllEpisodes ? insights.slice(-dateRange) : insights;
        setSleepCheckInGraph(formatGraphData(dateRangeInsights));
      } else {
        throw new Error();
      }
    } catch (ex) {
      notification.error({
        message: "Something went wrong while trying to get this patient's sleep check in insights"
      });
    }

    setIsSleepCheckInGraphLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchSleepCheckInGraph(30);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { sleepCheckInGraph, isSleepCheckInGraphLoading, selectedDateRange, fetchSleepCheckInGraph };
};

export const useFetchAppetiteCheckInGraph = ({
  token,
  clientId,
  profileId
}: {
  token: string;
  clientId: string;
  profileId?: string;
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState(30);
  const [appetiteCheckInGraph, setAppetiteCheckInGraph] = useState<ChartData[]>([]);
  const [isAppetiteCheckInGraphLoading, setIsAppetiteCheckInGraphLoading] = useState(true);
  const currentEpisode = useAppSelector(selectCurrentEpisode);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const { isEoCEnabled } = useGetFeatureToggle();
  const isSupportEpisode = isEoCEnabled && !isShowAllData;
  const episode = isSupportEpisode ? currentEpisode : undefined;

  const fetchAppetiteCheckInGraph = async (dateRange: number) => {
    setIsAppetiteCheckInGraphLoading(true);
    setSelectedDateRange(dateRange);

    if (clientId) {
      try {
        const { startDate, endDate } = calculateDateRange({ episode, dateRange });
        const episodeQuery = isOutsideAllEpisodes ? `&showOutsideOfEpisode=${isOutsideAllEpisodes}` : '';
        const queryParam = `${
          profileId ? `clientRecordId=${clientId}&clientProfileId=${profileId}` : `patientId=${clientId}`
        }&startDate=${startDate}&endDate=${endDate}&type=appetite${episodeQuery}`;

        const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights(token, queryParam);
        const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

        if (insights) {
          const dateRangeInsights = isOutsideAllEpisodes ? insights.slice(-dateRange) : insights;
          setAppetiteCheckInGraph(formatGraphData(dateRangeInsights));
        } else {
          throw new Error();
        }
      } catch (ex) {
        notification.error({
          message: "Something went wrong while trying to get this patient's appetite check in insights"
        });
      }
    }

    setIsAppetiteCheckInGraphLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAppetiteCheckInGraph(30);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { appetiteCheckInGraph, isAppetiteCheckInGraphLoading, selectedDateRange, fetchAppetiteCheckInGraph };
};

export const useFetchAllGraph = (token: string, recordId: string, profileId: string) => {
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [overallCheckInGraph, setOverallCheckInGraph] = useState<ChartData[]>([]);
  const [energyCheckInGraph, setEnergyCheckInGraph] = useState<ChartData[]>([]);
  const [appetiteCheckInGraph, setAppetiteCheckInGraph] = useState<ChartData[]>([]);
  const [sleepCheckInGraph, setSleepCheckInGraph] = useState<ChartData[]>([]);
  const [isAllGraphLoading, setAllGraphLoading] = useState(true);
  const currentEpisode = useAppSelector(selectCurrentEpisode);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const { isEoCEnabled } = useGetFeatureToggle();
  const isSupportEpisode = isEoCEnabled && isOutsideAllEpisodes && !isShowAllData;
  const episode = isSupportEpisode ? currentEpisode : undefined;

  const getParam = (startDate: string, endDate: string, type: string, showOutsideOfEpisode?: boolean) => {
    const episodeQuery = showOutsideOfEpisode ? `&showOutsideOfEpisode=${showOutsideOfEpisode}` : '';
    return `clientRecordId=${recordId}&clientProfileId=${profileId}&startDate=${startDate}&endDate=${endDate}&type=${type}${episodeQuery}`;
  };

  const fetchAllGraph = async (dateRange: number) => {
    setAllGraphLoading(true);

    if (profileId && !isEdgeReceptionist) {
      try {
        const { startDate, endDate } = calculateDateRange({ episode, dateRange });
        const getOverallInsights = await getCheckInInsights(
          token,
          getParam(startDate, endDate, 'overall', isOutsideAllEpisodes)
        );
        const getEnergyInsights = await getCheckInInsights(
          token,
          getParam(startDate, endDate, 'energy', isOutsideAllEpisodes)
        );
        const getAppetiteInsights = await getCheckInInsights(
          token,
          getParam(startDate, endDate, 'appetite', isOutsideAllEpisodes)
        );
        const getSleepInsights = await getCheckInInsights(
          token,
          getParam(startDate, endDate, 'sleep', isOutsideAllEpisodes)
        );
        const overallInsights = await getOverallInsights.json();
        const energyInsights = await getEnergyInsights.json();
        const appetiteInsights = await getAppetiteInsights.json();
        const sleepInsights = await getSleepInsights.json();

        if (appetiteInsights) {
          setOverallCheckInGraph(formatGraphData(overallInsights.insights));
          setEnergyCheckInGraph(formatGraphData(energyInsights.insights));
          setAppetiteCheckInGraph(formatGraphData(appetiteInsights.insights));
          setSleepCheckInGraph(formatGraphData(sleepInsights.insights));
        } else {
          throw new Error();
        }
      } catch (ex) {
        notification.error({
          message: 'Something went wrong while trying to get all graph data'
        });
      }
    }

    setAllGraphLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAllGraph(30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    graphData: {
      overall: {
        title: 'Overall, how are you feeling',
        graphData: overallCheckInGraph
      },
      energy: {
        title: 'How are  your energy levels?',
        graphData: energyCheckInGraph
      },
      sleep: {
        title: 'How have you slept the last few nights?',
        graphData: sleepCheckInGraph
      },
      appetite: {
        title: 'How are your appetite and eating habits?',
        graphData: appetiteCheckInGraph
      }
    },
    isAllGraphLoading,
    fetchAllGraph
  };
};
