import { useEffect, useState } from 'react';
import { Menu, Dropdown, Skeleton } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Masonry from 'react-masonry-css';

import { useFetchMicroJournalEntries } from 'utils/hooks/microJournal';
import JournalEntryCard from './components/JournalEntryCard/JournalEntryCard';

import styles from './MicroJournalEntries.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import {
  selectCurrentEpisodeId,
  selectIsFetchingEpisodes,
  selectIsOutsideAllEpisodes,
  selectIsShowAllData
} from 'redux/episodes/episodeSlice';

const DATE_RANGE_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Last 7 days', value: '7' },
  { label: 'Last 14 days', value: '14' },
  { label: 'Last 30 days', value: '30' }
];

interface MicroJournalEntriesProps {
  token: string;
  recordId?: string;
  profileId?: string;
  patientId?: string;
}

const MicroJournalEntries = ({ token, recordId, profileId, patientId }: MicroJournalEntriesProps) => {
  const [selectedDateRange, setSelectedDateRange] = useState('all');
  const clientId = recordId || patientId || '';
  const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const isFetchingEpisodes = useAppSelector(selectIsFetchingEpisodes);
  const { microJournalEntries, isMicroJournalEntriesLoading, fetchMicroJournalEntries } = useFetchMicroJournalEntries({
    token,
    clientId,
    profileId
  });
  const [t] = useTranslation();
  useEffect(() => {
    if (token && !isFetchingEpisodes) {
      fetchMicroJournalEntries(selectedDateRange === 'all' ? undefined : 30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, token, recordId, currentEpisodeId, isOutsideAllEpisodes, isShowAllData, isFetchingEpisodes]);

  const handleDateRangeSelect = (filter: string) => {
    if (filter !== 'all') {
      const dateRangeNumber = Number.parseInt(filter, 10);

      if (!isNaN(dateRangeNumber)) {
        setSelectedDateRange(filter);
        fetchMicroJournalEntries(dateRangeNumber);
      }
    } else {
      setSelectedDateRange(filter);
      fetchMicroJournalEntries();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>Micro Journal Entries</div>
        <div className={styles.headerFilter}>
          <div className={styles.filter}>Filter</div>
          <div>
            <Dropdown
              overlay={
                <Menu onClick={({ key }) => handleDateRangeSelect(String(key))}>
                  {DATE_RANGE_OPTIONS.map(({ label, value }) => (
                    <Menu.Item key={value}>{label}</Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <span className={styles.headerFilterDropdown} onClick={(e) => e.preventDefault()}>
                {DATE_RANGE_OPTIONS.find((option) => option.value === selectedDateRange)?.label} <DownOutlined />
              </span>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {isMicroJournalEntriesLoading ? (
          <Skeleton active />
        ) : (
          <>
            {microJournalEntries.length > 0 ? (
              <Masonry breakpointCols={3} className={styles.masonryContainer}>
                {microJournalEntries.map(({ date, title, response, tags }, index) => (
                  <JournalEntryCard key={index} date={date} title={title} response={response} tags={tags} />
                ))}
              </Masonry>
            ) : (
              <div className={styles.noContent}>
                {selectedDateRange === 'all'
                  ? t('label.client_empty_data')
                  : `There is not data for the ${DATE_RANGE_OPTIONS.find(
                      (option) => option.value === selectedDateRange
                    )?.label.toLowerCase()}`}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MicroJournalEntries;
