import ExpandableContent from 'components/ExpandableContent/ExpandableContent';
import styles from './SubTab.module.scss';
import { SubTabItem } from './SubTabItem';

export interface SubTabOption {
  href?: string;
  label?: string;
  icon?: string;
  value: string;
  number?: number;
  desc?: JSX.Element;
  matchingRecordCount?: number;
  hideMatchingRecordCount?: boolean;
}
interface SubTabProps {
  options?: SubTabOption[];
  activeOption?: string;
  onChange?: Function;
  title?: string;
  icon?: string;
}

const SubTab = ({ options = [], activeOption, onChange, title, icon }: SubTabProps) => {
  return (
    <div className={styles.subTab}>
      <div className={styles.header}>
        {options?.length > 0 && <div className={styles.title}>{title}</div>}
        <div className={styles.iconButton}>{icon && <i className="material-icons-outlined">{icon}</i>}</div>
      </div>
      <ExpandableContent expanded>
        <div className={styles.options}>
          {options?.map((item: SubTabOption, index) => (
            <SubTabItem key={index} option={item} activeOption={activeOption} onChange={onChange} />
          ))}
        </div>
      </ExpandableContent>
    </div>
  );
};

export default SubTab;
