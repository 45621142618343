import { useContext, useEffect, useMemo, useState } from 'react';
import styles from './EditClientModal.module.scss';
import { Modal, notification } from 'antd';
import EditChildAndYoungPersonForm from './formType/EditChildAndYoungPersonForm/EditChildAndYoungPersonForm';
import { editClientForm } from './EditClientModalInterface';
import EditAdultForm from './formType/EditAdultForm/EditAdultForm';
import {
  checkIsDuplicateCaseId,
  validateAdultForm,
  validateChildForm,
  validateCoupleForm,
  validateYoungForm
} from '../AddPatientModalV2/validation/SubmitFormValidation';
import { useGetAccessToken } from 'utils/hooks/token';
import { putUpdateClientData } from './hooks/PutUpdateClient';
import EditCoupleForm from './formType/EditCoupleForm/EditCoupleForm';
import { clientProfilesInterface, RecordStatus } from 'interfaces/Clients/clientsRecord';
import { useTranslation } from 'react-i18next';
import { patchClientRecordsRecordStatus } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { isMatch } from 'lodash';
import { useDispatch } from 'react-redux';
import { clientRecordsApiSlice } from 'redux/endpoints/clinicianProfileServices/client';
import { CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { reformatPhoneNumber } from '../MaterialPhoneInput/MaterialPhoneInput';
import { UserContext } from 'utils/UserContextProvider';

interface EditClientModalProps {
  clientRecordData: editClientForm;
  onCancel: (isUpdated: boolean) => void;
  visible: boolean;
  setIsOnboardingModalVisible: (val: boolean) => void;
  onRefreshData?: () => void;
}

const EditClientModal = ({
  clientRecordData,
  visible,
  onCancel,
  setIsOnboardingModalVisible,
  onRefreshData
}: EditClientModalProps) => {
  const { token } = useGetAccessToken();
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [checkValidation, setCheckValidation] = useState(false);
  const [clientFormData, setClientFormData] = useState(clientRecordData);
  const [showOnboardingCTA, setShowOnboardingCTA] = useState(false);
  const { clinicianProfile } = useContext(UserContext);
  const dispatch = useDispatch();

  const getCountry = useMemo(
    () => clinicianProfile?.practice?.country || process.env.REACT_APP_GAE_REGION || 'au',
    [clinicianProfile?.practice?.country]
  );

  const [t] = useTranslation();

  useEffect(() => {
    setClientFormData(clientRecordData);
  }, [clientRecordData]);

  const generateEmailCollection = () => {
    let emailCollection: string[] = [];
    for (const obj of clientRecordData.clientProfiles) {
      if (obj.email) {
        emailCollection.push(obj.email);
      }
    }
    return emailCollection;
  };
  const emailCollection = generateEmailCollection();
  const initCaseIdValue = clientRecordData.caseId;

  const handleChangeClientDetail = (clientProfilesData: editClientForm['clientProfiles']) => {
    setClientFormData({
      ...clientFormData,
      clientProfiles: clientProfilesData
    });
  };

  const handleChangeCaseId = (value: editClientForm['caseId']) => {
    setClientFormData({
      ...clientFormData,
      caseId: value
    });
  };

  const handleChangeTimeZone = (value: editClientForm['timeZone']) => {
    setClientFormData({
      ...clientFormData,
      timeZone: value
    });
  };

  const handleChangeClientAddress = (value: editClientForm['address']) => {
    setClientFormData({
      ...clientFormData,
      address: value
    });
  };

  const handlePlaceSelected = (value: editClientForm['address']) => {
    setClientFormData((clientFormData) => {
      const { address } = clientFormData;
      return {
        ...clientFormData,
        address: {
          ...address,
          line1: value.line1,
          line2: address.line2 || value.line2,
          state: address.state || value.state,
          suburb: address.suburb || value.suburb,
          postcode: address.postcode || value.postcode,
          country: address.country || value.country
        }
      };
    });
  };

  const handleChangeProfileType = (value: clientProfilesInterface['profileType']) => {
    const newClientProfile = clientFormData.clientProfiles.map((clientProfile) => ({
      ...clientProfile,
      profileType: value
    }));
    setClientFormData({
      ...clientFormData,
      clientProfiles: newClientProfile,
      clientCapability: {
        profileType: value
      }
    });
  };

  const validateSubmitField = async (recordType: editClientForm['recordType'], clientVal: editClientForm) => {
    const isCaseIdDuplicateExist = await checkIsDuplicateCaseId(token, clientVal.caseId, initCaseIdValue);
    if (recordType === 'adult') {
      const { isAdultFormErrorExist } = await validateAdultForm(
        token,
        clientVal.clientProfiles,
        clientVal.clientProfiles[0].profileType,
        emailCollection
      );
      if (isAdultFormErrorExist || isCaseIdDuplicateExist) {
        setSubmitStatus('');
      }
      return !isAdultFormErrorExist && !isCaseIdDuplicateExist;
    } else if (recordType === 'child') {
      const { isChildFormErrorExist } = await validateChildForm(clientVal.clientProfiles);

      if (isChildFormErrorExist || isCaseIdDuplicateExist) {
        setSubmitStatus('');
      }
      return !isChildFormErrorExist && !isCaseIdDuplicateExist;
    } else if (recordType === 'youngPerson') {
      const { isYoungFormErrorExist } = await validateYoungForm(
        token,
        clientVal.clientProfiles,
        clientVal.clientCapability.profileType,
        emailCollection
      );
      if (isYoungFormErrorExist || isCaseIdDuplicateExist) {
        setSubmitStatus('');
      }
      return !isYoungFormErrorExist && !isCaseIdDuplicateExist;
    } else if (recordType === 'couple') {
      const { isClientFormErrorExist, isDuplicateEmail, isDuplicatePhone } = await validateCoupleForm(
        token,
        clientVal.clientProfiles,
        clientRecordData.clientProfiles,
        clientVal.clientProfiles[0].profileType
      );
      if (isClientFormErrorExist || isDuplicateEmail || isDuplicatePhone || isCaseIdDuplicateExist) {
        setSubmitStatus('');
      }
      return !isClientFormErrorExist && !isDuplicateEmail && !isDuplicatePhone && !isCaseIdDuplicateExist;
    }
  };

  const isFormUnchangedExceptStatus = useMemo(
    () => isMatch({ ...clientFormData, recordStatus: undefined }, { ...clientRecordData, recordStatus: undefined }),
    [clientFormData, clientRecordData]
  );

  const clientFormPayload = useMemo(
    () => ({
      ...clientFormData,
      clientProfiles: clientFormData.clientProfiles?.map((clientProfileObj) => ({
        ...clientProfileObj,
        mobileNumber: clientProfileObj.mobileNumber
          ? reformatPhoneNumber(clientProfileObj.mobileNumber, getCountry)
          : ''
      }))
    }),
    [clientFormData, getCountry]
  );

  const handleEditSaveClientData = async (withTriage?: boolean) => {
    setSubmitStatus('active');
    setCheckValidation(true);

    const isStatusChanged = clientRecordData.recordStatus !== clientFormData.recordStatus;
    const closingRecord = isStatusChanged && clientFormData.recordStatus === RecordStatus.Closed;

    if (closingRecord || (await validateSubmitField(clientFormPayload.recordType, clientFormPayload))) {
      try {
        if (closingRecord || (isStatusChanged && isFormUnchangedExceptStatus)) {
          await patchClientRecordsRecordStatus(token, clientRecordData._id, {
            recordStatus: clientFormPayload.recordStatus
          });
        } else {
          await putUpdateClientData(token, clientFormPayload);
        }
        onRefreshData && onRefreshData();
        setTimeout(() => {
          setSubmitStatus('finished');
        }, 500);
        setTimeout(() => {
          notification.success({
            message: t('form.success.update_client'),
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setSubmitStatus('');
          onCancel(false);
          setCheckValidation(false);

          if (!closingRecord && clientFormPayload.recordStatus !== clientRecordData.recordStatus && withTriage) {
            setIsOnboardingModalVisible(true);
          }
        }, 1000);
        dispatch(clientRecordsApiSlice.util.invalidateTags([CPSTagTypes.ClientList]));
      } catch (ex) {
        console.error(ex);
        setSubmitStatus('');
        notification.error({
          message: t('form.error.update_client'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  const handleSubmit = async (options?: { withTriage?: boolean }) => {
    const { withTriage } = options || {};
    const checkCommPreferenceChange = clientRecordData.clientProfiles.filter(
      (oriProfData) =>
        !clientFormPayload.clientProfiles.some(
          (editProfData) =>
            oriProfData._id === editProfData._id &&
            oriProfData.communicationPreference === editProfData.communicationPreference
        )
    );

    if (checkCommPreferenceChange.length > 0) {
      Modal.confirm({
        title: 'Confirm communication update',
        content: t('form.confirm.update_client_communication'),
        cancelText: 'Cancel',
        okText: 'Yes',
        onOk: () => handleEditSaveClientData(withTriage)
      });
    } else {
      await handleEditSaveClientData(withTriage);
    }
  };

  const onStatusProfileChange = (clientStatus: 'active' | 'closed' | 'waitlist') => {
    if (clientStatus !== 'closed' && clientFormData.recordStatus !== clientStatus) {
      setShowOnboardingCTA(true);
    }

    setClientFormData({
      ...clientFormData,
      recordStatus: clientStatus
    });
  };

  const getFormType = () => {
    switch (clientRecordData.recordType) {
      case 'adult':
        return (
          <EditAdultForm
            clientRecordId={clientFormData._id}
            clientDetail={clientFormData.clientProfiles}
            onChangeClientField={handleChangeClientDetail}
            checkValidation={checkValidation}
            submitStatus={submitStatus}
            onClickSubmit={handleSubmit}
            caseIdValue={clientFormData.caseId}
            onChangeCaseId={handleChangeCaseId}
            excludeEmailCheckingGroup={emailCollection}
            excludeCaseIdChecking={initCaseIdValue}
            profileType={clientFormData.clientCapability.profileType}
            onChangeProfileType={handleChangeProfileType}
            recordStatus={clientFormData.recordStatus}
            onStatusProfileChange={onStatusProfileChange}
            onClickSaveClosedClient={handleEditSaveClientData}
            showOnboardingCTA={showOnboardingCTA}
            noUnsavedChanges={isFormUnchangedExceptStatus}
            timeZone={clientFormData.timeZone}
            onChangeTimeZone={handleChangeTimeZone}
            clientAddress={clientFormData.address}
            onChangeClientAddress={handleChangeClientAddress}
            onPlaceSelected={handlePlaceSelected}
          />
        );
      case 'child':
        return (
          <EditChildAndYoungPersonForm
            clientRecordType="child"
            clientDetail={clientFormData.clientProfiles}
            onChangeClientField={handleChangeClientDetail}
            checkValidation={checkValidation}
            submitStatus={submitStatus}
            onClickSubmit={handleSubmit}
            caseIdValue={clientFormData.caseId}
            onChangeCaseId={handleChangeCaseId}
            excludeCaseIdChecking={initCaseIdValue}
            profileType={clientFormData.clientCapability.profileType}
            onChangeProfileType={handleChangeProfileType}
            recordStatus={clientRecordData.recordStatus}
            onStatusProfileChange={onStatusProfileChange}
            onClickSaveClosedClient={handleEditSaveClientData}
            showOnboardingCTA={showOnboardingCTA}
            noUnsavedChanges={isFormUnchangedExceptStatus}
            timeZone={clientFormData.timeZone}
            onChangeTimeZone={handleChangeTimeZone}
            clientAddress={clientFormData.address}
            onChangeClientAddress={handleChangeClientAddress}
            onPlaceSelected={handlePlaceSelected}
          />
        );
      case 'couple':
        return (
          <EditCoupleForm
            clientRecordId={clientFormData._id}
            clientDetail={clientFormData.clientProfiles}
            onChangeClientField={handleChangeClientDetail}
            checkValidation={checkValidation}
            submitStatus={submitStatus}
            onClickSubmit={handleSubmit}
            caseIdValue={clientFormData.caseId}
            onChangeCaseId={handleChangeCaseId}
            profileType={clientFormData.clientCapability.profileType}
            onChangeProfileType={handleChangeProfileType}
            excludeCaseIdChecking={initCaseIdValue}
            recordStatus={clientFormData.recordStatus}
            onStatusProfileChange={onStatusProfileChange}
            onClickSaveClosedClient={handleEditSaveClientData}
            showOnboardingCTA={showOnboardingCTA}
            noUnsavedChanges={isFormUnchangedExceptStatus}
            timeZone={clientFormData.timeZone}
            onChangeTimeZone={handleChangeTimeZone}
            clientAddress={clientFormData.address}
            onChangeClientAddress={handleChangeClientAddress}
            onPlaceSelected={handlePlaceSelected}
          />
        );
      case 'youngPerson':
        return (
          <EditChildAndYoungPersonForm
            clientRecordType="youngPerson"
            clientDetail={clientFormData.clientProfiles}
            onChangeClientField={handleChangeClientDetail}
            checkValidation={checkValidation}
            submitStatus={submitStatus}
            onClickSubmit={handleSubmit}
            caseIdValue={clientFormData.caseId}
            onChangeCaseId={handleChangeCaseId}
            excludeCaseIdChecking={initCaseIdValue}
            excludeEmailCheckingGroup={emailCollection}
            profileType={clientFormData.clientCapability.profileType}
            onChangeProfileType={handleChangeProfileType}
            recordStatus={clientFormData.recordStatus}
            onStatusProfileChange={onStatusProfileChange}
            onClickSaveClosedClient={handleEditSaveClientData}
            showOnboardingCTA={showOnboardingCTA}
            noUnsavedChanges={isFormUnchangedExceptStatus}
            timeZone={clientFormData.timeZone}
            onChangeTimeZone={handleChangeTimeZone}
            clientAddress={clientFormData.address}
            onChangeClientAddress={handleChangeClientAddress}
            onPlaceSelected={handlePlaceSelected}
          />
        );
      default:
        return (
          <EditChildAndYoungPersonForm
            clientRecordType="child"
            clientDetail={clientFormData.clientProfiles}
            onChangeClientField={handleChangeClientDetail}
            checkValidation={checkValidation}
            submitStatus={submitStatus}
            onClickSubmit={handleSubmit}
            caseIdValue={clientFormData.caseId}
            onChangeCaseId={handleChangeCaseId}
            excludeCaseIdChecking={initCaseIdValue}
            profileType={clientFormData.clientProfiles[0].profileType}
            onChangeProfileType={handleChangeProfileType}
            recordStatus={clientFormData.recordStatus}
            onStatusProfileChange={onStatusProfileChange}
            onClickSaveClosedClient={handleEditSaveClientData}
            showOnboardingCTA={showOnboardingCTA}
            noUnsavedChanges={isFormUnchangedExceptStatus}
            timeZone={clientFormData.timeZone}
            onChangeTimeZone={handleChangeTimeZone}
            clientAddress={clientFormData.address}
            onChangeClientAddress={handleChangeClientAddress}
            onPlaceSelected={handlePlaceSelected}
          />
        );
    }
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={<div className={styles.title}>{t('title.edit_client_information')}</div>}
      open={visible}
      onCancel={() => onCancel(false)}
      footer={null}
      destroyOnClose
    >
      <div>
        <div className={styles.formContainer}>{getFormType()}</div>
      </div>
    </Modal>
  );
};

export default EditClientModal;
