import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  SegmentedGroup,
  SegmentedItem
} from '@mobiscroll/react';
import styles from './CalendarHeader.module.scss';

interface CalendarHeaderProps {
  view: 'day' | 'week';
  onViewChange: (view: 'day' | 'week') => void;
}

const CalendarHeader = ({ view, onViewChange }: CalendarHeaderProps) => {
  const onViewChangeHandler = (event: any) => {
    onViewChange(event.target.value === 'week' ? 'week' : 'day');
  };
  return (
    <div className={styles.container} id={'calendarNavId'}>
      <CalendarToday className="cal-header-today" />
      <CalendarPrev className="cal-header-prev" />
      <CalendarNext className="cal-header-next" />
      <CalendarNav className="cal-header-nav" />
      <div className="cal-header-picker">
        <SegmentedGroup value={view} onChange={onViewChangeHandler}>
          <SegmentedItem value="week" className="cal-header-view-mode">
            Week
          </SegmentedItem>
          <SegmentedItem value="day" className="cal-header-view-mode">
            Day
          </SegmentedItem>
        </SegmentedGroup>
      </div>
    </div>
  );
};

export default CalendarHeader;
