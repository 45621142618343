import styles from './AttachmentCard.module.scss';
import path from 'path-browserify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';
import { GroupAttachmentInterface } from '../../interfaces';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import DocumentViewerModal from 'components/DocumentViewerModal/DocumentViewerModal';
import { useState } from 'react';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface AttachmentCardProps {
  baseLink: string;
  isDeleting: boolean;
  ableToDelete: boolean;
  attachment: GroupAttachmentInterface;
  onDeleteAttachmentClick: () => void;
}

const AttachmentCard = ({
  baseLink,
  isDeleting,
  ableToDelete,
  attachment: { _id, caseNote, createdAt, title, thumbnail, groupId },
  onDeleteAttachmentClick
}: AttachmentCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.previewContainer}>
          {thumbnail ? (
            <img src={thumbnail} alt={'thumbnail'} />
          ) : (
            <span className={styles.fileType}>{path.extname(title).toUpperCase()}</span>
          )}
        </div>
        <Button className={styles.titleButton} variant="secondary" onClick={() => setIsModalOpen((bool) => !bool)}>
          {title}
        </Button>
        <div className={styles.date}>Added {moment(createdAt).format(MOMENTJS_DATE_FORMAT)}</div>
        {caseNote?._id && (
          <div className={styles.linked}>
            Linked to{' '}
            <Link className={styles.link} to={baseLink}>
              {caseNote.title}
            </Link>
          </div>
        )}
        {ableToDelete ? (
          <ButtonAlt
            error
            size={'small'}
            className={styles.deleteButton}
            variant="text"
            disabled={isDeleting}
            onClick={onDeleteAttachmentClick}
          >
            DELETE
          </ButtonAlt>
        ) : (
          <i className={`material-icons-outlined ${styles.lockIcon}`}>lock</i>
        )}
      </div>
      <DocumentViewerModal
        title={title}
        groupId={groupId}
        attachmentId={_id}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default AttachmentCard;
