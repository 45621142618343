import BroadcastMessageOneToOne from './components/BroadcastMessageOneToOne/BroadcastMessageOneToOne';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

import styles from './MessagesContent.module.scss';
import BroadcastMessageGroup from './components/BroadcastMessageGroup/BroadcastMessageGroup';

const MessagesContent = () => {
  const { isGroupsFeatureToggle } = useGetFeatureToggle();

  return (
    <div className={styles.container}>
      {isGroupsFeatureToggle ? <BroadcastMessageGroup /> : <BroadcastMessageOneToOne />}
    </div>
  );
};

export default MessagesContent;
