import { PractitionersDetailsInterface, WorkingScheduleInterface } from 'interfaces/Practitioners/practitionersListing';
import momentTz, { Moment } from 'moment-timezone';

export const mapWorkingScheduleToInvalidTimeSlots =
  (accountTimeZone: string) =>
  ({
    _id: clinicianId,
    workingSchedule,
    workTimeZone
  }: Pick<PractitionersDetailsInterface, '_id' | 'workingSchedule' | 'workTimeZone'>) =>
    Object.entries(
      workingSchedule as Record<
        keyof WorkingScheduleInterface,
        WorkingScheduleInterface[keyof WorkingScheduleInterface]
      >
    ).flatMap(([day, { isActive: isDayActive, timeSlots }]) => {
      const slotData = {
        resource: clinicianId,
        start: '',
        end: ''
      };

      let momentifiedTimeSlots: { start: Moment; end: Moment }[] = [];

      const timeZone = workTimeZone || accountTimeZone;

      const dayStartime = momentTz.tz(`${day} 00:00`, 'dddd HH:mm', timeZone);
      const dayEndTime = momentTz(dayStartime).endOf('day');

      // Block whole day if not active
      if (!isDayActive) {
        momentifiedTimeSlots = [
          {
            start: momentTz(dayStartime),
            end: momentTz(dayEndTime)
          }
          // Possibly also extended the end time to the next day
        ];
      } else if (timeSlots) {
        //sort to ensure the sequence
        const sortedTimeSlots = [...(timeSlots || [])].sort((a, b) => {
          return a.startTime.localeCompare(b.startTime);
        });

        // Get non available slots from working schedule, e.g. working schedule is 9am-1pm, 2pm-6pm
        // Will turn into 12am-9am, 1pm-2pm, 6pm-12am
        momentifiedTimeSlots = [
          ...sortedTimeSlots.map((timeSlot, index) => {
            const startTime =
              index === 0
                ? momentTz(dayStartime)
                : momentTz.tz(sortedTimeSlots[index - 1].endTime, 'HH:mm', timeZone).day(day);
            const endTime = momentTz.tz(timeSlot.startTime, 'HH:mm', timeZone).day(day);
            return {
              start: startTime,
              end: endTime
            };
          }),
          {
            start: momentTz.tz(sortedTimeSlots[timeSlots.length - 1].endTime, 'HH:mm', timeZone).day(day),
            end: momentTz(dayEndTime)
          }
        ];
      }

      // Convert from local time to utc, the time might move from 8am - 12pm Sydney to 9pm-1am utc time
      const timeSlotsInUTC = momentifiedTimeSlots.flatMap(({ start, end }) => {
        start.utc();
        end.utc();
        return {
          start: start.format('HH:mm'),
          startDay: start.format('dd'),
          end: end.utc().format('HH:mm'),
          endDay: end.format('dd')
        };
      });

      // Break timeslots like 23:00 - 05:00 into two timeslots
      // which are 23:00 - 24:00 and 00:00 - 05:00 respectively.
      const breakeddownUTCTimeslots = timeSlotsInUTC.flatMap((timeSlot) => {
        if (timeSlot.startDay !== timeSlot.endDay) {
          return [
            {
              ...slotData,
              start: timeSlot.start,
              end: '23:59',
              recurring: {
                repeat: 'weekly',
                weekDays: timeSlot.startDay
              }
            },
            ...((timeSlot.end !== '00:00' && [
              {
                ...slotData,
                start: '00:00',
                end: timeSlot.end,
                recurring: {
                  repeat: 'weekly',
                  weekDays: timeSlot.endDay
                }
              }
            ]) ||
              [])
          ];
        }
        // No need to breakdown if still within the same day
        return [
          {
            ...slotData,
            start: timeSlot.start,
            end: timeSlot.end,
            recurring: {
              repeat: 'weekly',
              weekDays: timeSlot.startDay
            }
          }
        ];
      });
      return breakeddownUTCTimeslots;
    });
