import styles from './PatientDetailsNoteDetailsDisplay.module.scss';
import { CaseNote } from 'interfaces/caseNote';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CaseNoteAttachment from './components/CaseNoteAttachment/CaseNoteAttachment';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useTranslation } from 'react-i18next';

interface PatientDetailsNoteDetailsDisplayProps {
  recordId: string;
  data: CaseNote;
  showSafeguardingAlert?: boolean;
}

const PatientDetailsNoteDetailsDisplay = ({
  recordId,
  data,
  showSafeguardingAlert = true
}: PatientDetailsNoteDetailsDisplayProps) => {
  const { CLIENTS } = useRoutesGenerator();
  const { isHelmFeatureToggle, isCaWFeatureToggle } = useGetFeatureToggle();
  const [t] = useTranslation();

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.label}>Title</div>
          <div className={styles.value}>{data.title}</div>
        </div>
        <div className={styles.safeguardingContainer}>
          {!(isHelmFeatureToggle || isCaWFeatureToggle) && showSafeguardingAlert && (
            <div className={styles.safeguarding}>
              <div className={styles.label}>{t('client_case_notes.safe_guarding_alert')}</div>
              <div className={styles.value}>{data.safeguardingAlert ? 'Yes' : 'No'}</div>
            </div>
          )}
          {data.safeguardingAlert && (
            <div className={styles.safeguardingReason}>
              <div className={styles.label}>{t('client_case_notes.safe_guarding_label')}</div>
              <div className={styles.value}>{data.safeguardingReason}</div>
            </div>
          )}
        </div>

        <div className={styles.fieldContainer}>
          {data.fields.map((fieldObj, index: number) => {
            switch (fieldObj.type) {
              case 'multipleChoice':
                return (
                  fieldObj.value && (
                    <div className={styles.fieldBox} key={index}>
                      <div className={styles.label}>{fieldObj.name}</div>
                      {fieldObj.multiple ? (
                        <div className={styles.value}>{fieldObj.value.map((val: any) => val.label).join(', ')}</div>
                      ) : (
                        <div className={styles.value}>{fieldObj.value.label}</div>
                      )}
                    </div>
                  )
                );
              case 'link':
                return (
                  fieldObj.value && (
                    <div className={styles.fieldBox} key={index}>
                      <div className={styles.label}>{fieldObj.name}</div>
                      {fieldObj.linkType === 'assessment' ? (
                        <Link
                          className={styles.value}
                          to={`${CLIENTS.BASE}/${recordId}/assessments/${fieldObj.value._id}`}
                        >
                          {moment(fieldObj.value.date).format(MOMENTJS_DATE_FORMAT)} {fieldObj.value.shortCode}
                        </Link>
                      ) : (
                        <div className={styles.value}>
                          {moment(fieldObj.value.date).format(MOMENTJS_DATE_FORMAT)} {fieldObj.value.shortCode}
                        </div>
                      )}
                    </div>
                  )
                );
              case 'multipleChoiceCount': {
                const getOnlyWithValue = fieldObj.options.filter((obj: any) => obj.count > 0);
                return (
                  getOnlyWithValue.length > 0 && (
                    <div className={styles.fieldBox} key={index}>
                      <div className={styles.label}>{fieldObj.name}</div>
                      <div className={styles.value}>
                        {getOnlyWithValue.map((val: any) => `${val.count} ${val.label}`).join(' | ')}
                      </div>
                    </div>
                  )
                );
              }
              case 'date':
                return (
                  fieldObj.value && (
                    <div className={styles.fieldBox} key={index}>
                      <div className={styles.label}>{fieldObj.name}</div>
                      <div className={styles.value}>
                        {moment(fieldObj.value, MOMENTJS_DATE_FORMAT).format('MMMM DD YYYY')}
                      </div>
                    </div>
                  )
                );
              case 'text':
              case 'number':
              case 'time':
              default:
                return (
                  fieldObj.value && (
                    <div className={styles.fieldBox} key={index}>
                      <div className={styles.label}>{fieldObj.name}</div>
                      <div className={styles.value}>{fieldObj.value}</div>
                    </div>
                  )
                );
            }
          })}
        </div>
      </div>
      <div className={`ql-snow ${styles.body}`}>
        <div className={`ql-editor ${styles.text}`} dangerouslySetInnerHTML={{ __html: data.body }} />
      </div>
      <CaseNoteAttachment recordId={recordId} caseNoteAttachmentData={data.attachments} />
    </>
  );
};

export default PatientDetailsNoteDetailsDisplay;
