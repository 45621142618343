import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import AppointmentReportList from './components/AppointmentReportList/AppointmentReportList';

const PractitionerReport = () => (
  <HelmetWrapper title="Services Report">
    <AppointmentReportList />
  </HelmetWrapper>
);

export default PractitionerReport;
