import { Modal, notification } from 'antd';
import Radio from 'components/Radio/Radio';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import { MedicareDetails } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { useGetAccessToken } from 'utils/hooks/token';
import { putClientProfileMedicareSummary } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';

import MbsCodeSelection from './components/MbsCodeSelection';
import styles from './CustomiseSummaryModal.module.scss';

interface CustomiseSummaryModalProps {
  visible: boolean;
  clientRecordId: string;
  clientProfileId: string;
  summary: MedicareDetails['summary'];
  selectedYear: string;
  handleUpdateSummarySettings: ({
    year,
    individualCodes,
    groupCodes
  }: {
    year: string;
    individualCodes: string[];
    groupCodes: string[];
  }) => void;
  handleCancel: () => void;
}

const getYearOptions = () => {
  const dateStart = moment('2022-01-01');
  const dateEnd = moment();
  const options: { value: string; label: string }[] = [];

  while (dateEnd > dateStart || dateStart.format('YYYY') === dateEnd.format('YYYY')) {
    const year = dateStart.format('YYYY');
    options.push({ label: year, value: year });
    dateStart.add(1, 'year');
  }
  return options;
};

const sortMbsCodes = (codes: string[]) => codes.sort((prev, curr) => Number(prev) - Number(curr));

const CustomiseSummaryModal = ({
  visible,
  clientRecordId,
  clientProfileId,
  summary,
  selectedYear,
  handleUpdateSummarySettings,
  handleCancel
}: CustomiseSummaryModalProps) => {
  const { token } = useGetAccessToken();
  const [year, setYear] = useState(selectedYear);
  const [selectedIndividualCodes, setSelectedIndividualCodes] = useState<string[]>(summary.mbsCodes.individual);
  const [selectedGroupCodes, setSelectedGroupCodes] = useState<string[]>(summary.mbsCodes.group);
  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');
  const dispatch = useAppDispatch();

  const yearOptions = getYearOptions();

  const handleSubmit = async () => {
    if (selectedIndividualCodes.length === 0 || selectedGroupCodes.length === 0) {
      return;
    }
    setButtonStatus('active');
    try {
      const payload = {
        individual: sortMbsCodes(selectedIndividualCodes),
        group: sortMbsCodes(selectedGroupCodes)
      };
      await putClientProfileMedicareSummary(token, clientRecordId, clientProfileId, payload);

      handleUpdateSummarySettings({ year, individualCodes: selectedIndividualCodes, groupCodes: selectedGroupCodes });
      dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.ClientEncrypted]));
      handleCancel();
      notification.success({
        message: 'Claim summary settings updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setButtonStatus('finished');
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: "Something went wrong while trying to update this client's claim summary settings",
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    setButtonStatus('');
  };

  return (
    <Modal
      width={800}
      title={<div className={styles.title}>Customise Summary</div>}
      footer={null}
      open={visible}
      destroyOnClose
      onCancel={handleCancel}
    >
      <div className={styles.container}>
        <div className={styles.yearSelectionContainer}>
          <div className={styles.label}>SHOW YEAR</div>
          <Radio options={yearOptions} value={year} onChange={(e) => setYear(e.target.value)} />
        </div>
        <div className={styles.mbsContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Set MBS items</div>
            <div className={styles.description}>
              Control the items to show claim counts and run forward eligibility checks for
            </div>
          </div>
          <MbsCodeSelection
            type={'individual'}
            selectedCodes={selectedIndividualCodes}
            setSelectedCodes={setSelectedIndividualCodes}
          />
          <MbsCodeSelection
            type={'group'}
            selectedCodes={selectedGroupCodes}
            setSelectedCodes={setSelectedGroupCodes}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} variant={'secondary'} onClick={handleCancel}>
            Cancel
          </Button>
          <Button className={styles.button} onClick={handleSubmit} status={buttonStatus}>
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomiseSummaryModal;
