import moment from 'moment';

export const initReportDataFunc = ({
  reportName,
  isEdgeAdminView,
  approvalRequired
}: {
  reportName: string;
  isEdgeAdminView: boolean;
  approvalRequired?: boolean;
}) => ({
  issueDate: moment().format('YYYY-MM-DD'),
  clinician: {},
  practice: {},
  clientRecord: {
    clientProfiles: []
  },
  contactDetails: {
    practice: {
      address: {
        value: '',
        isVisible: false
      },
      mobileNumber: {
        value: '',
        isVisible: false
      }
    },
    clinician: {
      email: {
        value: '',
        isVisible: false
      },
      mobileNumber: {
        value: '',
        isVisible: false
      }
    }
  },
  reportName: reportName,
  status: 'draft',
  lastUpdatedTime: new Date(),
  isEditing: false,
  isForPractice: isEdgeAdminView,
  template: {
    dimensions: {
      height: 500,
      width: 840
    }
  },
  shareDetails: {
    accessors: []
  },
  items: [],
  clinicianSignature: {
    type: '',
    textVal: '',
    drawVal: ''
  },
  clinicianSignatureExtraDetails: {
    valediction: 'Kind Regards',
    email: '',
    mobileNumber: '',
    name: '',
    other: ''
  },
  ...(approvalRequired && { approvalRequired })
});
