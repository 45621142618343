import { notification } from 'antd';
import { useEffect, useState } from 'react';
import styles from './CreateGroupModal.module.scss';
import GroupForm from './components/GroupForm/GroupForm';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { CreateGroupRequestPayload, CreateGroupValidateField } from './CreateGroupModalInterfaces';
import { postNewGroup } from 'utils/http/ClinicianProfileService/Groups/Groups';
import { useGetAccessToken } from 'utils/hooks/token';
import { scrollToView } from 'utils/scrollToView';
import { validateGroupId, validationGroupNameForm } from './components/GroupForm/validation/GroupFormValidation';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import ModalV2 from '../../ModalV2/ModalV2';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useAppDispatch } from 'redux/hooks';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';

interface CreateGroupModalProps {
  visible: boolean;
  onReset: () => void;
  onCloseModal: () => void;
}

const CreateGroupModal = ({ visible, onReset, onCloseModal }: CreateGroupModalProps) => {
  const dispatch = useAppDispatch();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { isEdgeReceptionist, isEdgeAdminView } = useGetAccountPackageView();

  const { auth0ClinicianId } = useGetClinicianId();
  const [selectedClinicianId, setSelectedClinicianId] = useState(
    isEdgeReceptionist || isEdgeAdminView ? '' : auth0ClinicianId
  );

  const defaultFormValue: CreateGroupRequestPayload = {
    groupId: '',
    name: '',
    leadClinicianAuth0Id: '',
    description: ''
  };
  const [groupField, setGroupField] = useState(defaultFormValue);
  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [checkValidation, setCheckValidation] = useState(false);

  const [errorMessage, setErrorMessage] = useState<CreateGroupValidateField>({
    name: ''
  });

  useEffect(() => {
    setSelectedClinicianId(isEdgeReceptionist || isEdgeAdminView ? '' : auth0ClinicianId);
  }, [isEdgeAdminView, auth0ClinicianId, isEdgeReceptionist]);

  const handleChangeSelectedClinician = (val: string) => {
    setSelectedClinicianId(val);
  };

  const handleChangeGroupForm = async (val: CreateGroupRequestPayload) => {
    setGroupField(val);

    const validate = validationGroupNameForm(val);
    setErrorMessage(validate as CreateGroupValidateField);
  };

  const checkIsDuplicateGroupId = async (token: string, groupIdValue?: string, excludeGroupIdValue?: string) => {
    const isSameGroupIdValue = excludeGroupIdValue === groupIdValue;
    if (groupIdValue && !isSameGroupIdValue) {
      const pastValidateGroupId = await validateGroupId(token, accountId, groupIdValue);
      return pastValidateGroupId.statusCode !== 200;
    } else {
      return false;
    }
  };

  const validateGroupField = async (groupFieldVal: CreateGroupRequestPayload) => {
    const isGroupIdDuplicateExists = await checkIsDuplicateGroupId(token, groupFieldVal.groupId);
    const groupForm = validationGroupNameForm(groupFieldVal);
    setErrorMessage(groupForm as CreateGroupValidateField);

    const checkFieldHaveError = Object.values(groupForm as CreateGroupRequestPayload).some((value) => value !== '');

    if (checkFieldHaveError || isGroupIdDuplicateExists) {
      setPageSubmitStatus('');
    }

    return !checkFieldHaveError && !isGroupIdDuplicateExists;
  };

  const handleSubmit = async () => {
    setPageSubmitStatus('active');
    setCheckValidation(true);

    if (await validateGroupField(groupField)) {
      try {
        const groupFormData: CreateGroupRequestPayload = {
          groupId: groupField.groupId,
          name: groupField.name,
          leadClinicianAuth0Id: groupField.leadClinicianAuth0Id,
          description: groupField.description
        };
        await postNewGroup(token, accountId, groupFormData);
        setPageSubmitStatus('finished');
        setTimeout(() => {
          scrollToView('modalHeader', true);
          notification.success({
            message: 'New group created',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setPageSubmitStatus('');
          setGroupField(defaultFormValue);
          setCheckValidation(false);
          onReset();
          dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.GroupList]));
        }, 2000);
      } catch (ex) {
        console.error(ex);
        setPageSubmitStatus('');
        notification.error({
          message: 'Failed to create new group',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={visible}
      title={'Create New Group'}
      onModalClose={onCloseModal}
    >
      <div className={styles.container}>
        <GroupForm
          selectedClinicianId={selectedClinicianId}
          onChangeClinician={(selectedId: string) => handleChangeSelectedClinician(selectedId)}
          onChangeGroupField={handleChangeGroupForm}
          checkValidation={checkValidation}
          groupFormField={groupField}
          errorMessage={errorMessage}
          accountId={accountId}
        />
      </div>
      <div className={styles.submitButtonContainer}>
        <ButtonAlt status={pageSubmitStatus} disabled={pageSubmitStatus !== ''} onClick={handleSubmit}>
          Create Now
        </ButtonAlt>
      </div>
    </ModalV2>
  );
};

export default CreateGroupModal;
