import { Skeleton } from 'antd';
import styles from './PractitionersListingHeader.module.scss';
import { PractitionersStatus } from 'interfaces/Practitioners/practitionersListing';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface PractitionersListingHeaderType {
  total: number;
  isLoading: boolean;
  currentTabFilter: PractitionersStatus['status'];
  onAddPractitionerClick: () => void;
}

const PractitionersListingHeader = ({
  total,
  isLoading,
  currentTabFilter,
  onAddPractitionerClick
}: PractitionersListingHeaderType) => {
  const [t] = useTranslation();
  const { isEdgeAdminView } = useGetAccountPackageView();

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.titleWrapper}>
          {isLoading ? (
            <Skeleton.Button active className={styles.loading} />
          ) : (
            <div className={styles.title}>{t('header.practitioner', { count: total, status: currentTabFilter })}</div>
          )}
        </div>
      </div>
      {isEdgeAdminView && (
        <div className={styles.rightSection}>
          <ButtonAlt icon={'add_circle_outline'} onClick={onAddPractitionerClick}>
            {t('button.add_new_practitioner')}
          </ButtonAlt>
        </div>
      )}
    </div>
  );
};

export default PractitionersListingHeader;
