import Button from 'components/Button/Button';
import { Fax } from 'interfaces/fax';
import moment from 'moment';
import path from 'path-browserify';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import styles from './PatientDetailsFaxCard.module.scss';

interface PatientDetailsFaxCardProps {
  onTitleClick: (fax: Fax) => void;
  fax: Fax;
}

const PatientDetailsFaxCard = ({ onTitleClick, fax }: PatientDetailsFaxCardProps) => {
  const title = fax.faxDocument?.path || '';

  return (
    <>
      <div className={styles.container}>
        <div className={styles.previewContainer}>
          <span className={styles.fileType}>{path.extname(title).toUpperCase()}</span>
        </div>
        <Button className={styles.titleButton} variant="secondary" onClick={() => onTitleClick(fax)}>
          {title}
        </Button>
        <div className={styles.date}>Added {moment(fax.createdAt).format(MOMENTJS_DATE_FORMAT)}</div>
      </div>
    </>
  );
};

export default PatientDetailsFaxCard;
