import moment, { Moment } from 'moment';

import DayCard from 'components/DayCard/DayCard';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { AvailabilitiesViewType } from 'components/v2/AvailableTimeSelect/components/AvailabilitiesViewTypeToggle/AvailabilitiesViewTypeToggle';
import {
  AppointmentTypeAvailabilities,
  SelectedAppointmentSlotProps
} from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import styles from './AppointmentSlotSelect.module.scss';
import { getTargetAppointmentSlots } from './helpers/helpers';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';
import { FetchingDates } from 'components/v2/AvailableTimeSelect/AvailableTimeSelect';

interface AppointmentSlotSelectProps {
  availabilitiesViewType: string;
  fetchingDates: FetchingDates;
  firstDay: Moment;
  setFirstDay: (value: Moment) => void;
  setFetchingDates: (value: FetchingDates) => void;
  appointmentTypeAvailabilities?: AppointmentTypeAvailabilities;
  showAppointmentTypeName?: boolean;
  selectedSlot?: SelectedAppointmentSlotProps;
  onSelectSlot?: (slot: SelectedAppointmentSlotProps) => void;
  numberOfDaysShown?: number;
  numberOfTimeSlotsShown?: number;
  isLoading?: boolean;
  dayRange?: number;
}

const AppointmentSlotSelect = ({
  availabilitiesViewType,
  fetchingDates,
  firstDay,
  setFirstDay,
  setFetchingDates,
  appointmentTypeAvailabilities,
  onSelectSlot,
  selectedSlot,
  numberOfDaysShown = 7,
  numberOfTimeSlotsShown,
  isLoading,
  dayRange = 30
}: AppointmentSlotSelectProps) => {
  const isPrevButtonEnabled = firstDay.isAfter(moment());

  const isNavByDay = numberOfDaysShown < 7;

  const prevLabel = `Prev ${isNavByDay ? 'Days' : 'Week'}`;
  const nextLabel = `Next ${isNavByDay ? 'Days' : 'Week'}`;

  const goPrev = () => {
    if (isPrevButtonEnabled) {
      setFirstDay(firstDay.clone().subtract(numberOfDaysShown, 'd'));
    }
  };

  const goNext = () => {
    const newFirstDay = firstDay.clone().add(numberOfDaysShown, 'd');
    setFirstDay(newFirstDay);
    if (newFirstDay.diff(moment(fetchingDates.to), 'd') > -7) {
      const from = moment(fetchingDates.to).add(1, 'd').format(MOMENTJS_FORMAT_DATE);
      setFetchingDates({
        from,
        to: moment(from).add(dayRange, 'd').endOf('isoWeek').format(MOMENTJS_FORMAT_DATE)
      });
    }
  };

  const handleSelectSlot = (slot: SelectedAppointmentSlotProps) => {
    if (onSelectSlot) {
      onSelectSlot(slot);
    }
  };

  const jumpToNextAvailableSlot = () => {
    if (nextAvailableDate) {
      setFirstDay(nextAvailableDate.startOf('isoWeek'));
    }
  };

  const { targetAppointmentSlots, targetAppointmentSlotsHasNoAvailability, nextAvailableDate } =
    getTargetAppointmentSlots({ numberOfDaysShown, currentDate: firstDay, appointmentTypeAvailabilities });

  const shouldShowJumpToNextAvailableSlot = targetAppointmentSlotsHasNoAvailability && !!nextAvailableDate;

  return (
    <div className={styles.container}>
      <div className={styles.daysContainer}>
        {targetAppointmentSlots?.map((slots, index) => {
          return (
            <DayCard
              key={index}
              isAvailabilitiesViewTypeSimple={availabilitiesViewType === AvailabilitiesViewType.Simple}
              selectedAppointmentSlot={selectedSlot}
              onSelectSlot={handleSelectSlot}
              data={slots}
              numberOfTimeSlotsShown={numberOfTimeSlotsShown}
              isLoading={isLoading}
            />
          );
        })}
      </div>
      <div className={styles.navContainer}>
        <ButtonAlt
          size={'small'}
          className={styles.icon}
          variant="text"
          onClick={goPrev}
          disabled={!isPrevButtonEnabled}
        >
          <div className={styles.navWrapper}>
            <i className={`material-icons-outlined ${styles.navIcon}`}>arrow_back_ios_new</i>
            <span className={styles.navLabel}>{prevLabel}</span>
          </div>
        </ButtonAlt>
        {shouldShowJumpToNextAvailableSlot && (
          <ButtonAlt size={'small'} variant="text" onClick={jumpToNextAvailableSlot} icon={'skip_next'} iconPostFix>
            JUMP TO NEXT AVAILABLE
          </ButtonAlt>
        )}
        <ButtonAlt size={'small'} className={styles.icon} variant="text" onClick={goNext} disabled={isLoading}>
          <div className={styles.navWrapper}>
            <span className={styles.navLabel}>{nextLabel}</span>
            <i className={`material-icons-outlined ${styles.navIcon}`}>arrow_forward_ios_new</i>
          </div>
        </ButtonAlt>
      </div>
    </div>
  );
};

export default AppointmentSlotSelect;
