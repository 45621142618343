import { Modal, notification } from 'antd';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import mime from 'mime';
import { useCallback, useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { base64ToBlob } from 'utils/base64ToBlob';
import { useFaxDetails } from 'utils/hooks/fax';
import styles from './FaxDetailsModal.module.scss';

interface FaxDetailsModalProps {
  faxId: string;
  onCloseModal: () => void;
  visible: boolean;
}

const FaxDetailsModal = ({ faxId, onCloseModal, visible }: FaxDetailsModalProps) => {
  const { faxDetails, isFaxDetailsLoading } = useFaxDetails(faxId);
  const [isParsingDocument, setIsParsingDocument] = useState(false);
  const [documentBlob, setDocumentBlob] = useState<Blob | null>();
  const [documentUrl, setDocumentUrl] = useState('');

  const loadDocument = useCallback(async () => {
    if (faxDetails?.file) {
      setIsParsingDocument(true);

      try {
        const blob = base64ToBlob({
          base64Data: faxDetails.file,
          contentType: mime.getType(faxDetails.fileName) || 'application/pdf'
        });
        setDocumentBlob(blob);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          setDocumentUrl(reader.result as string);
        };
        reader.onerror = () => {
          throw new Error('Fail to load the file');
        };
        setIsParsingDocument(false);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while parsing the fax document.' });
      }
    }
  }, [faxDetails]);

  useEffect(() => {
    if (visible && !isParsingDocument) {
      loadDocument();
    }
  }, [isParsingDocument, loadDocument, visible]);

  const handleDownload = () => {
    if (!documentBlob) {
      return;
    }
    const anchor = document.createElement('a');

    anchor.href = URL.createObjectURL(documentBlob);
    anchor.download = 'test.pdf';
    anchor.click();
    anchor.remove();
    notification.success({ message: 'File successfully downloaded.' });
  };

  return (
    <Modal
      footer={null}
      onCancel={onCloseModal}
      open={visible}
      title={
        <div className={styles.modalHeader}>
          <p className={styles.title}>View fax file</p>
          <ButtonAlt disabled={!documentBlob} icon="download" onClick={handleDownload} size="medium" variant="outlined">
            Download Attachment
          </ButtonAlt>
        </div>
      }
      width={800}
    >
      {isFaxDetailsLoading ? (
        <div>Loading</div>
      ) : !faxDetails ? (
        <div>Fax details not available</div>
      ) : documentUrl ? (
        <DocViewer
          config={{ header: { disableHeader: true, disableFileName: true } }}
          documents={[{ uri: documentUrl }]}
          pluginRenderers={DocViewerRenderers}
        />
      ) : (
        <div>Document not available</div>
      )}
    </Modal>
  );
};

export default FaxDetailsModal;
