import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { notification } from 'antd';

import { AppointmentsStatus, InvoiceStatus, InvoiceStatusToBeChange } from 'pages/Invoices/interface';

import { useGetAccessToken } from 'utils/hooks/token';
import { getAppointmentsInvoicedStatusByPatientId } from 'utils/http/appointment';
import { putInvoiceStatus, putResendInvoice } from 'utils/http/BillingService/Invoice/invoice';
import Loading from 'components/Loading/Loading';
import AppointmentStatus, {
  AppointmentStatusFilterHandle,
  InvoiceAppointmentStatusFilterEnum
} from 'pages/Invoices/components/AppointmentStatus/AppointmentStatus';
import InvoiceListing from 'pages/Invoices/components/InvoiceListing/InvoiceListing';
import PaymentStatus from 'pages/Invoices/components/PaymentStatus/PaymentStatus';
import InfiniteScroller from 'react-infinite-scroller';
import styles from './PatientDetailsInvoices.module.scss';
import { StatusFilterType, useFetchInvoices } from 'utils/hooks/useFetchInvoices';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectCurrentEpisodeId, selectIsOutsideAllEpisodes, selectIsShowAllData } from 'redux/episodes/episodeSlice';
import { DataRefreshHandle } from '../../PatientDetailsContent';
import { InvoiceType, useTotalInvoicesAmounts } from 'utils/hooks/useTotalInvoicesAmounts';
import { setIsRefreshDataDisabled } from 'redux/clients/clientDetailsSlice';
import { BSTagTypes, billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';
import { security } from 'utils/security';

const useFetchAppointmentsStatus = (token: string, clientRecordId: string) => {
  const [appointmentsStatus, setAppointmentsStatus] = useState<AppointmentsStatus>();
  const [isAppointmentsStatusLoading, setIsAppointmentsStatusLoading] = useState(true);
  const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);

  const fetchAppointmentsStatus = async () => {
    try {
      const queryParams = {
        ...(!isShowAllData && {
          ...(currentEpisodeId && !isOutsideAllEpisodes && { episodeId: currentEpisodeId }),
          ...(isOutsideAllEpisodes && { showOutsideOfEpisode: isOutsideAllEpisodes })
        })
      };
      setIsAppointmentsStatusLoading(true);

      const callAppointmentsStatus = await getAppointmentsInvoicedStatusByPatientId(token, clientRecordId, queryParams);
      const appointmentsStatus = await callAppointmentsStatus.json();

      if (appointmentsStatus) {
        setAppointmentsStatus(appointmentsStatus);
      }
    } catch (ex) {
      notification.error({ message: "Something went wrong while trying to get your appointments' invoiced status." });
    }

    setIsAppointmentsStatusLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchAppointmentsStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowAllData, currentEpisodeId, isOutsideAllEpisodes, token]);

  return { appointmentsStatus, isAppointmentsStatusLoading, fetchAppointmentsStatus };
};

interface PatientDetailsInvoicesProps {
  recordId: string;
  allowCreateNewInvoice: boolean;
}

const PatientDetailsInvoices = forwardRef<DataRefreshHandle, PatientDetailsInvoicesProps>(
  ({ recordId, allowCreateNewInvoice }, ref) => {
    const { token } = useGetAccessToken();
    const dispatch = useAppDispatch();

    const [invoiceIdProcessing, setInvoiceIdProcessing] = useState<string>('');
    const [invoicesAmountsFilter, setInvoicesAmountsFilter] = useState<InvoiceType>(InvoiceType.All);
    const appointmentStatusRef = useRef<AppointmentStatusFilterHandle>(null);

    const {
      totalInvoicesAmounts,
      loading: isTotalInvoicesAmountsLoading,
      fetchTotalInvoicesAmounts
    } = useTotalInvoicesAmounts(invoicesAmountsFilter, recordId);

    const { appointmentsStatus, isAppointmentsStatusLoading, fetchAppointmentsStatus } = useFetchAppointmentsStatus(
      token,
      recordId
    );

    const fetchInvoicesOptions = useMemo(() => ({ clientRecordId: recordId }), [recordId]);
    const {
      dateSort,
      fetchMoreInvoices,
      hasMoreInvoices,
      invoices,
      isInvoicesLoading,
      participationType,
      statusFilter,
      setDateSort,
      setInvoices,
      setParticipationType,
      setStatusFilter,
      refetch: refetchInvoices
    } = useFetchInvoices(fetchInvoicesOptions);

    useImperativeHandle(ref, () => ({
      onDataRefresh: () => {
        appointmentStatusRef.current?.onFilterChange(InvoiceAppointmentStatusFilterEnum.ALL);
        statusFilter !== StatusFilterType.All ? setStatusFilter(StatusFilterType.All) : refetchInvoices();
        fetchAppointmentsStatus();
        fetchTotalInvoicesAmounts();
      }
    }));

    useEffect(() => {
      dispatch(
        setIsRefreshDataDisabled(isAppointmentsStatusLoading || isInvoicesLoading || isTotalInvoicesAmountsLoading)
      );
    }, [isAppointmentsStatusLoading, isInvoicesLoading, isTotalInvoicesAmountsLoading, dispatch]);

    const onChangeStatus = async (_id: string, status: InvoiceStatusToBeChange) => {
      const token = await security.getAccessTokenSilently();

      if (token) {
        try {
          setInvoiceIdProcessing(_id);
          await putInvoiceStatus(token, _id, status);

          const newInvoices = [...invoices];
          const updatedInvoice = newInvoices.find((invoice) => invoice._id === _id);
          updatedInvoice && (updatedInvoice.status = status as InvoiceStatus);
          setInvoices(newInvoices);

          notification.success({
            message: 'Invoice status updated.',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          dispatch(billingServicesApiSlice.util.invalidateTags([BSTagTypes.Invoices, BSTagTypes.InvoiceSummary]));
          setInvoiceIdProcessing('');
        } catch (ex) {
          setInvoiceIdProcessing('');
          notification.error({
            message: 'Status update failed. Please try again, if failures persist please contact us to investigate.',
            duration: 2
          });
        }
      }
    };

    const onResendInvoice = async (_id: string) => {
      const token = await security.getAccessTokenSilently();

      if (token) {
        try {
          setInvoiceIdProcessing(_id);
          await putResendInvoice(token, _id);

          notification.success({
            message: 'Successfully resent this invoice!',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setInvoiceIdProcessing('');
        } catch (ex) {
          setInvoiceIdProcessing('');
          notification.error({ message: "Something went wrong while trying to update this invoice's status" });
        }
      }
    };

    return (
      <div className={styles.container}>
        <div className={styles.statuses}>
          <div className={styles.appointmentStatus}>
            <AppointmentStatus
              ref={appointmentStatusRef}
              appointmentsStatus={appointmentsStatus}
              isAppointmentsStatusLoading={isAppointmentsStatusLoading}
            />
          </div>
          <div className={styles.paymentStatus}>
            <PaymentStatus
              isLoading={isTotalInvoicesAmountsLoading}
              filter={invoicesAmountsFilter}
              totalInvoicesAmounts={totalInvoicesAmounts}
              enableGroupSelection={false}
              setFilter={setInvoicesAmountsFilter}
            />
          </div>
        </div>
        <div className={styles.listing} id="clientInvoiceListing">
          <InfiniteScroller
            initialLoad={false}
            loadMore={() => fetchMoreInvoices()}
            useWindow
            hasMore={hasMoreInvoices}
            loader={
              <div className={styles.listLoading} key={0}>
                <Loading />
              </div>
            }
          >
            <InvoiceListing
              invoices={invoices}
              allowCreateNewInvoice={allowCreateNewInvoice}
              isInvoicesLoading={isInvoicesLoading}
              onChangeStatus={onChangeStatus}
              onResendInvoice={onResendInvoice}
              invoiceIdProcessing={invoiceIdProcessing}
              enableGroupSelection={false}
              dateSort={dateSort}
              setDateSort={setDateSort}
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
              participationType={participationType}
              setParticipationType={setParticipationType}
            />
          </InfiniteScroller>
        </div>
      </div>
    );
  }
);

export default PatientDetailsInvoices;
