import { useState } from 'react';
import classNames from 'classnames';
import styles from './KeyClientContactList.module.scss';
import columnStyles from '../../KeyClientContactsColumn.module.scss';
import ContactTag from '../ContactTag/ContactTag';
import { KeyClientContact } from 'interfaces/Clients/clientsRecord';
import { Modal, notification } from 'antd';
import { deleteKeyClientContact } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

export interface KeyClientContactListProps {
  token: string;
  clientRecordId: string;
  keyClientContacts: KeyClientContact[];
  enableDelete: boolean;
}

const KeyClientContactList = ({
  keyClientContacts,
  token,
  clientRecordId,
  enableDelete
}: KeyClientContactListProps) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [t] = useTranslation();

  const onDeleteContact = async (value: KeyClientContact) => {
    Modal.confirm({
      title: t('form.confirm.delete_key_client_contact'),
      cancelText: 'Cancel',
      okText: 'Yes',
      onOk: () => handleDeleteTag(value)
    });
  };

  const handleDeleteTag = async (value: KeyClientContact) => {
    if (isDeleting) {
      return;
    }
    setIsDeleting(true);
    try {
      await deleteKeyClientContact(token, clientRecordId, value._id);
      keyClientContacts.splice(
        keyClientContacts.findIndex((o) => o._id === value._id),
        1
      );
      setTimeout(() => {
        notification.success({
          message: t('form.success.delete_key_client_contact'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }, 500);
    } catch (e) {
      notification.error({ message: t('form.error.delete_key_client_contact') });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className={styles.container}>
      {keyClientContacts.map((contact, index) => (
        <div key={index} className={classNames(columnStyles.listHeader, styles.contactItem)}>
          <div className={columnStyles.firstName}>{contact.firstName}</div>
          <div className={columnStyles.lastName}>{contact.lastName}</div>
          <div className={columnStyles.mobileNumber}>{contact.mobileNumber}</div>
          <div className={columnStyles.email}>{contact.email}</div>
          <div className={columnStyles.contactTags}>
            <ContactTag tags={contact.tags || []} />
          </div>
          <div className={columnStyles.actions}>
            {enableDelete && (
              <ButtonAlt
                error
                variant={'text'}
                onClick={() => onDeleteContact(contact)}
                icon={'cancel'}
                disabled={isDeleting}
                size={'medium'}
                fab
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default KeyClientContactList;
