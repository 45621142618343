import SubTab, { SubTabOption } from 'components/v2/SubTab/SubTab';
// import SubTabButton from 'components/v2/SubTab/SubTabButton/SubTabButton';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import styles from '../SubTabLayout.module.scss';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
// import classNames from 'classnames';

const MessagesSubTab = () => {
  const { faxEnable } = useGetFeatureToggle();
  const location = useLocation();
  const { isEdgePlanUser } = useGetAccountPackage();
  const { isEdgeAdminOrReceptionistView } = useGetAccountPackageView();
  const isMessagesPage = isEdgePlanUser
    ? location.pathname.split('/')[2] === 'messages'
    : location.pathname.split('/')[1] === 'messages';
  const getFilterType: string = isMessagesPage
    ? isEdgePlanUser
      ? (location.pathname.split('/')[3] as string) ?? 'broadcastMessage'
      : (location.pathname.split('/')[2] as string) ?? 'broadcastMessage'
    : '';
  const { MESSAGES } = useRoutesGenerator();
  const [filterType, setFilterType] = useState<string>(getFilterType);

  useEffect(() => {
    setFilterType(getFilterType);
  }, [getFilterType]);

  const baseHref = MESSAGES.BASE;

  const isFaxPagesVisible = isEdgeAdminOrReceptionistView && faxEnable;

  /**
   * `Received & Sent Faxes` tab is accessible only to edge admin and receptionist
   * https://tacklit.slack.com/archives/C04LM9BAHK9/p1689821687226459
   * */
  const messageHistoryOptions = [
    ...(isFaxPagesVisible
      ? [{ label: 'Received & Sent Faxes', value: 'faxes', href: `${baseHref}/faxes` } as SubTabOption]
      : [])
  ];

  return (
    <div className={styles.container}>
      {/* <div className={classNames(styles.section)}>
        <SubTabButton
          label="Create New Broadcast"
          icon="add_circle_outline"
          color="white"
          onClick={navigateToMessagesPage}
        />
      </div> */}
      <div className={styles.section}>
        <SubTab
          title="SEND NEW MESSAGE"
          options={[
            { label: 'Broadcast Message', value: 'broadcastMessage', href: `${baseHref}/broadcastMessage` },
            ...(isFaxPagesVisible
              ? [{ label: 'Fax', value: 'send-fax', href: `${baseHref}/send-fax` } as SubTabOption]
              : [])
          ]}
          activeOption={filterType}
        />
      </div>
      {!!messageHistoryOptions.length && (
        <div className={styles.section}>
          <SubTab title="MESSAGE HISTORY" options={messageHistoryOptions} activeOption={filterType} />
        </div>
      )}
      {/* <div className={styles.section}>
        <SubTab
          onChange={() => {}}
          noOptionText="Send broadcast messages to clients with important updates or content you want to share."
          noOptionLinkText="How to use Broadcast"
        />
      </div> */}
    </div>
  );
};

export default MessagesSubTab;
