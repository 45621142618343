import { ChangeEvent, useState } from 'react';

import styles from './SelectKeyContactsBox.module.scss';
import { KeyContact } from 'pages/Groups/Interfaces/Groups';

interface SelectKeyContactsBoxProps {
  keyContacts: KeyContact[];
  onChangeKeyContacts: (selectedKeyContacts: string[]) => void;
}

const SelectKeyContactsBox = ({ keyContacts, onChangeKeyContacts }: SelectKeyContactsBoxProps) => {
  const [selectedKeyContacts, setSelectedKeyContacts] = useState<string[]>([]);

  const handleCheckKeyContact = (e: ChangeEvent<HTMLInputElement>, keyContactId: string) => {
    const selectKeyContacts = [...selectedKeyContacts];

    if (e.target.checked) {
      selectKeyContacts.push(keyContactId);
    } else {
      const index = selectKeyContacts.indexOf(keyContactId, 0);

      if (index > -1) {
        selectKeyContacts.splice(index, 1);
      }
    }
    setSelectedKeyContacts([...selectKeyContacts]);
    onChangeKeyContacts(selectKeyContacts);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Select Key Contact
        <i className={`material-icons icon`}>arrow_drop_down</i>
      </div>
      {keyContacts.map((keyContactObj, index) => (
        <label
          key={index}
          htmlFor={`checkbox${index}`}
          className={!keyContactObj.email ? styles.keyContactDisableItem : styles.keyContactItem}
        >
          <div className={styles.keyContactInfo}>
            <div className={styles.name}>
              {keyContactObj.firstName} {keyContactObj.lastName}
            </div>
            {keyContactObj.email && <div className={styles.email}>{keyContactObj.email}</div>}
            {keyContactObj.tags && keyContactObj.tags.length > 0 && (
              <div className={styles.tags}>
                {keyContactObj.tags.map((tag, index) => (
                  <div key={`contactTag_${index}`} className={styles.tagPill}>
                    {tag}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles.checkboxContainer}>
            <input
              className={styles.checkbox}
              id={`checkbox${index}`}
              type="checkbox"
              disabled={!keyContactObj.email}
              checked={selectedKeyContacts.some((id) => id === keyContactObj._id)}
              onChange={(e) => handleCheckKeyContact(e, keyContactObj._id)}
            />
          </div>
        </label>
      ))}
    </div>
  );
};

export default SelectKeyContactsBox;
