import { Divider } from 'antd';
import classnames from 'classnames';
import { useEffect, useState, useRef } from 'react';

import FlexBox from 'components/FlexBox/FlexBox';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import SearchBar from 'components/v2/SearchBar/SearchBar';
import { IInvoiceTemplate } from 'pages/Invoices/interface';
import styles from './SelectTemplateDropdown.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface SelectTemplateDropdownProps {
  onAddNewTemplate: () => void;
  onChangeTemplate: (invoiceTemplateId: string) => void;
  templateList?: IInvoiceTemplate[];
  selectedTemplate?: string;
}

const SelectTemplateDropdown = ({
  onAddNewTemplate,
  onChangeTemplate,
  templateList,
  selectedTemplate
}: SelectTemplateDropdownProps) => {
  const menuNode = useRef<HTMLDivElement>(null);
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { isInvoiceTemplateEnabled } = useGetFeatureToggle();

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [filteredTemplateList, setFilteredTemplateList] = useState<IInvoiceTemplate[]>([]);

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target)) {
      return;
    }
    setIsMenuExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  useEffect(() => {
    if (searchValue) {
      setFilteredTemplateList(
        templateList?.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase().trim())) || []
      );
    } else {
      setFilteredTemplateList(templateList || []);
    }
  }, [searchValue, templateList]);

  return (
    <div ref={menuNode}>
      <FlexBox direction="row" justifyContent="space-between" alignItems="center" className={styles.wrapper}>
        <span className={styles.templateName}>{selectedTemplate}</span>
        <ButtonAlt
          size={'small'}
          variant={'text'}
          onClick={() => setIsMenuExpanded(!isMenuExpanded)}
          className={styles.linkButton}
        >
          Change template
        </ButtonAlt>
      </FlexBox>
      <div className={styles.menuWrapper}>
        <div className={isMenuExpanded ? styles.menuShow : styles.menuHide}>
          <div className={styles.searchWrapper}>
            <SearchBar
              containerClassName={styles.searchBox}
              clearTextIcon
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </div>
          <Divider style={{ margin: 0 }} />
          <div className={styles.groupLabel}>TEMPLATE LIST</div>
          {filteredTemplateList.map((item) => (
            <div
              className={styles.optionWrapper}
              key={item._id}
              onClick={() => {
                setIsMenuExpanded(false);
                onChangeTemplate(item._id);
              }}
            >
              <span>{item.name}</span>
            </div>
          ))}
          {isInvoiceTemplateEnabled && (isEdgeAdminView || isEdgeReceptionistView) && (
            <div
              className={styles.optionWrapper}
              onClick={() => {
                setIsMenuExpanded(false);
                onAddNewTemplate();
              }}
            >
              <FlexBox direction="row" alignItems="center" spacing={8} className={styles.addButton}>
                <i className={classnames('material-icons')}>add_circle_outline</i>
                <span>New template</span>
              </FlexBox>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectTemplateDropdown;
