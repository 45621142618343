import Badge from 'components/Badge/Badge';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ClinicianAvatar from 'components/ClinicianAvatar/ClinicianAvatar';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import { ReportListInterface } from 'interfaces/Reports/report';
import { startCase } from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { checkIsTodayOrYesterday } from 'utils/timeDifferent';

import styles from './ReportItem.module.scss';
import columnStyles from '../../ReportListColumn.module.scss';
import ActionsMenu from '../ActionsMenu/ActionsMenu';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import classNames from 'classnames';
import { getSharedDetails } from '../../constants';

interface ReportItemProps {
  reportListData: ReportListInterface[];
  onClickSendViaEFax: (value: ReportListInterface) => void;
}

const ReportItem = ({ reportListData, onClickSendViaEFax }: ReportItemProps) => {
  const navigate = useNavigate();
  const { faxEnable } = useGetFeatureToggle();
  const { REPORTS } = useRoutesGenerator();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

  const goToDetail = (id: string) => {
    navigate(`${REPORTS.BASE}/${id}`);
  };

  return (
    <>
      {reportListData.map((item: ReportListInterface, index: number) => {
        const createdTimeCheckToday = checkIsTodayOrYesterday(item.createdAt);
        const updatedTimeCheckToday = checkIsTodayOrYesterday(item.lastUpdatedTime);
        const primaryData = item.clientRecord?.clientProfiles[0];
        const clientProfile = item.clientRecord?.clientProfiles as clientProfilesInterface[];

        return (
          <div className={styles.listingWrapper} key={index} onClick={() => goToDetail(item._id)}>
            <div className={styles.contentWrapper}>
              {(isEdgeAdminView || isEdgeReceptionist) && (
                <div className={columnStyles.authorColumn}>
                  <ClinicianAvatar
                    avatarSize={60}
                    isEllipsisName
                    avatarUrl={item.isForPractice ? item.practice?.logo : item.clinician?.avatar}
                    name={item.isForPractice ? item.practice?.name : item.clinician?.name}
                  />
                </div>
              )}
              <div className={columnStyles.profileColumn}>
                <ClientAvatar
                  clientData={clientProfile}
                  avatarSize={60}
                  isEllipsisName
                  displayFirstNameOnly={item.clientRecord.recordType === 'couple'}
                  displayLimit={item.clientRecord.recordType === 'couple' ? 2 : 1}
                  statusCircle={item.status !== 'published' ? 'inactive' : 'active'}
                />
              </div>
              <div className={classNames(styles.reportNameSection, columnStyles.reportNameColumn)}>
                <div className={styles.link}>{item.reportName}</div>
              </div>
              <div className={classNames(styles.statusSection, columnStyles.statusColumn)}>
                <Badge label={startCase(item.status)} className={styles[item.status]} />
                {item.shareDetails?.accessors && item.shareDetails.accessors.length > 0 && (
                  <Badge label={'Shared'} className={styles.shareBadge} />
                )}
                {item.review?.reviewType && ['changesRequested', 'feedback'].includes(item.review.reviewType) && (
                  <Badge
                    label={startCase(item.review.reviewType)}
                    className={
                      item.review.reviewType === 'feedback' ? styles.feedbackBadge : styles.changesRequestedBadge
                    }
                  />
                )}
                {item && ['draft', 'approved'].includes(item.status) && item.editedByReviewer && (
                  <Badge label={'Reviewers Edits'} className={styles.reviewerEditsBadge} />
                )}
              </div>
              <div className={classNames(styles.createdSection, columnStyles.createdColumn)}>
                {createdTimeCheckToday === 'other' ? (
                  <>
                    <div>{moment(item.createdAt).format('D MMM YYYY')}</div>
                    <div>{moment(item.createdAt).format('hh:mm A')}</div>
                  </>
                ) : (
                  <div>
                    <span className={styles.day}>{createdTimeCheckToday} </span>
                    {moment(item.createdAt).format('hh:mm A')}
                  </div>
                )}
              </div>
              <div className={classNames(styles.lastAccessedSection, columnStyles.lastActivityColumn)}>
                {updatedTimeCheckToday === 'other' ? (
                  <>
                    <div>{moment(item.lastUpdatedTime).format('D MMM YYYY')}</div>
                    <div>{moment(item.lastUpdatedTime).format('hh:mm A')}</div>
                  </>
                ) : (
                  <div>
                    <span className={styles.day}>{updatedTimeCheckToday} </span>
                    {moment(item.lastUpdatedTime).format('hh:mm A')}
                  </div>
                )}
              </div>
              <div className={classNames(styles.shareDetailSection, columnStyles.shareDetailColumn)}>
                {faxEnable
                  ? getSharedDetails(item.shareDetails, primaryData?.name || '')
                      .slice(0, 10)
                      .map((item, index) => (
                        <div className={styles.sharedItem} key={index}>
                          <i className={classNames('material-icons-outlined', styles.icon, item.className)}>
                            {item.icon}
                          </i>
                          {item.label}
                        </div>
                      ))
                  : !!item.shareDetails.accessors?.length && (
                      <div className={styles.shareDetailsEmail}>
                        <i className={`material-icons-outlined ${styles.shareIcon}`}>share</i>
                        <div className={styles.shareNameContainer}>
                          {item.shareDetails.accessors.slice(0, 3).map((obj: any, index: any) => (
                            <div key={index}>
                              {obj.isClient ? (
                                <div className={styles.shareName}>{primaryData?.name}</div>
                              ) : (
                                <div className={styles.shareName}>{obj.email}</div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
              </div>
              <div className={classNames(styles.actionWrapper, columnStyles.actionsColum)}>
                {faxEnable && (
                  <ActionsMenu
                    onClickSendViaEFax={() => onClickSendViaEFax(item as ReportListInterface)}
                    isSendEFaxDisabled={item.status !== 'published'}
                  />
                )}
                <ButtonAlt className={styles.editButton} variant={'outlined'} onClick={() => goToDetail(item._id)}>
                  <i className={`material-icons ${styles.icon}`}>
                    {item.status !== 'published' ? 'edit' : 'arrow_forward'}
                  </i>
                </ButtonAlt>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ReportItem;
