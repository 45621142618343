import { EpisodeDropdownType } from '../components/ClientProfileEpisodeDropdownItem/ClientProfileEpisodeDropdownItem';

export const clientProfileDropdownShowAllDataOption = [
  {
    label: EpisodeDropdownType.SHOW_ALL_DATA,
    value: EpisodeDropdownType.SHOW_ALL_DATA
  }
];

export const defaultClientProfileDropdownOptions = [
  {
    label: EpisodeDropdownType.OUTSIDE_ALL_EPISODES,
    value: EpisodeDropdownType.OUTSIDE_ALL_EPISODES
  },
  {
    label: EpisodeDropdownType.SHOW_ALL_DATA,
    value: EpisodeDropdownType.SHOW_ALL_DATA
  }
];
