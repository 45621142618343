import { useRef } from 'react';

import styles from './SurveyVideoModal.module.scss';
import { Modal } from 'antd';

export interface SurveyVideoModalProps {
  visible: boolean;
  onCancel: () => void;
}

const videoUrl =
  'https://static-web-assets.tacklit.com/Create_survey_explainer_2048e581e5/Create_survey_explainer_2048e581e5.mp4';

const SurveyVideoModal = ({ visible, onCancel }: SurveyVideoModalProps) => {
  const vidRef = useRef<HTMLVideoElement>(null);

  const handleCancelButton = () => {
    vidRef?.current?.pause();
    onCancel();
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={<div className={styles.headerTitle}>Survey Editor Guide</div>}
      open={visible}
      onCancel={() => handleCancelButton()}
      footer={null}
    >
      <div className={styles.container}>
        <video ref={vidRef} autoPlay className={styles.videoPlayer} controls src={videoUrl} />
      </div>
    </Modal>
  );
};

export default SurveyVideoModal;
