import { ConsentFormInterface } from 'interfaces/ConsentForm/ConsentFormInterface';
import { useParams } from 'react-router-dom';
import { useGetConsentDetailsByIdQuery } from 'redux/endpoints/documentServices/consentForm';

const consentDefaultData: ConsentFormInterface = {
  _id: '',
  isPublished: false,
  isDefault: false,
  accountId: '',
  name: '',
  body: '',
  signatureRequired: {
    other: false,
    client: false
  }
};

export const useFetchConsentForm = () => {
  const path = useParams<{ consentId?: string }>();
  const consentId = path.consentId ?? '';
  const isNewConsentForm = consentId?.includes('new');

  const { data: consentFormData, isLoading: isConsentFormDataLoading } = useGetConsentDetailsByIdQuery(
    {
      consentFormId: consentId
    },
    {
      skip: isNewConsentForm
    }
  );

  return {
    consentFormData: isNewConsentForm ? consentDefaultData : consentFormData || consentDefaultData,
    isConsentFormDataLoading
  };
};
