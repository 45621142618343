/* eslint-disable complexity */
import { DatePicker, notification } from 'antd';
import classNames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ExpandableContent from 'components/ExpandableContent/ExpandableContent';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { ButtonStatusType } from 'components/v2/Button/Button';
import Select from 'components/v2/Select/Select';
import { QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';
import { ScaleType } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { VariableData } from '../../ClientData.interface';
import ClientDataHistoryList from '../ClientDataHistory/ClientDataHistoryList';
import styles from './ClientDataListItem.module.scss';
import * as Yup from 'yup';
import moment from 'moment';
import { handleCopyContent } from 'utils/CopyFeature';
import T23Select from 'components/Select/T23Select/T23Select';
import { putClientData } from 'utils/http/CheckInService/OpenDataField/openDataField';
import { isArray } from 'lodash';
import {
  CLIENT_EMAIL_VARIABLE,
  CLIENT_ID_VARIABLE,
  DATE_FORMAT,
  getHistoryValue,
  getVariableOptions,
  INIT_EMOJI_VALUE,
  IS_FIRST_OR_LAST_NAME,
  UNEDITABLE_VARIABLES
} from '../../constants';
import {
  validateCaseId,
  validateEmail,
  validatePhoneNumber
} from 'components/AddPatientModalV2/formType/AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import { useGetAccessToken } from 'utils/hooks/token';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import { useAppDispatch } from 'redux/hooks';

const PrefixIcon: { [key: string]: string } = {
  [QuestionType.freeText]: 'text_format',
  [QuestionType.numeric]: 'numbers',
  [QuestionType.date]: 'date_range',
  [QuestionType.email]: 'email'
};

interface ClientDataListItemProps {
  clientData: VariableData;
  clientRecordId: string;
  clientProfileId: string;
  disableEmailField: boolean;
  shouldValidateEmailField: boolean;
  editingVariable: string;
  couplePartnerPhoneNumber?: string;
  fetchClientData: (token: string) => Promise<void>;
  setEditingVariable: (variableId: string) => void;
}

const ClientDataListItem = ({
  clientData,
  clientRecordId,
  clientProfileId,
  disableEmailField,
  shouldValidateEmailField,
  editingVariable,
  couplePartnerPhoneNumber,
  fetchClientData,
  setEditingVariable
}: ClientDataListItemProps) => {
  const { token } = useGetAccessToken();
  const [expanded, setExpanded] = useState(false);
  const [newValue, setNewValue] = useState<string | string[]>('');
  const [newOtherValue, setNewOtherValue] = useState('');
  const [error, setError] = useState('');
  const [isTouched, setIsTouched] = useState(false);
  const [value, setValue] = useState<string | string[]>('');
  const [otherValue, setOtherValue] = useState<string | string[]>('');
  const [saveBtnStatus, setSaveBtnStatus] = useState<ButtonStatusType>('');
  const disabled = disableEmailField && UNEDITABLE_VARIABLES.includes(clientData.variableId);
  const dispatch = useAppDispatch();

  const validator = useMemo(() => {
    switch (clientData.variableType) {
      case QuestionType.phone:
        return Yup.string()
          .required('Please input the value')
          .matches(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g, 'Invalid phone number');
      case QuestionType.email:
        return Yup.string().email('Invalid email format').required('Please input the value');
      case QuestionType.scale:
        if (clientData.scaleType === ScaleType.EmojiReaction) {
          return Yup.array().required('Please select at least one option').min(1, 'Please select at least one option');
        } else {
          return Yup.string().required('Please select an option');
        }
      case QuestionType.freeText:
      case QuestionType.date:
        return Yup.string().required('Please input the value');
      case QuestionType.multipleChoiceFreeText:
      case QuestionType.multipleChoice:
        if (typeof clientData.maxSelection !== 'undefined' && clientData.maxSelection !== 1) {
          return Yup.array().required('Please select at least one option').min(1, 'Please select at least one option');
        } else {
          return Yup.string().required('Please select an option');
        }
      case QuestionType.numeric:
        return Yup.number().required('Please input the value').typeError('Please input a numeric value');
    }
  }, [clientData.variableType, clientData.maxSelection, clientData.scaleType]);

  const validateField = useCallback(async () => {
    try {
      await validator?.validate(newValue);
      setError('');
      return true;
    } catch (error: any) {
      if (error?.errors?.length > 0) {
        setError(error.errors[0]);
      } else {
        setError('');
      }
      return false;
    }
  }, [validator, newValue]);

  useEffect(() => {
    isTouched && validateField();
  }, [isTouched, validateField]);

  const validateEmailPhoneAndCaseId = async (newValue: string | string[]) => {
    if (
      !newValue ||
      typeof newValue !== 'string' ||
      newValue === value ||
      ![QuestionType.freeText, QuestionType.phone, QuestionType.email].includes(clientData.variableType) ||
      (clientData.variableType === QuestionType.freeText && clientData.variableId !== CLIENT_ID_VARIABLE) ||
      (clientData.variableType === QuestionType.email &&
        (clientData.variableId !== CLIENT_EMAIL_VARIABLE || !shouldValidateEmailField))
    ) {
      return true;
    }

    if (clientData.variableType === QuestionType.phone) {
      if (couplePartnerPhoneNumber && newValue.trim().replaceAll(' ', '') === couplePartnerPhoneNumber) {
        setError('This mobile number is duplicated with another client');
        return false;
      }
      const result = await validatePhoneNumber(newValue);

      if (!result.valid) {
        setError('Invalid Phone Number');
      }

      return result.valid;
    } else if (clientData.variableId === CLIENT_ID_VARIABLE) {
      const response = await validateCaseId(token, newValue || '', clientRecordId);

      if (response.statusCode !== 200) {
        setError('Client Reference is duplicated');
      }

      return response.statusCode === 200;
    } else if (clientData.variableId === CLIENT_EMAIL_VARIABLE) {
      const response = await validateEmail(token, newValue);
      if (response.statusCode !== 200) {
        setError('This email address is already in use');
      }

      return response.statusCode === 200;
    }
  };

  const handleSaveChange = async () => {
    setIsTouched(true);
    setSaveBtnStatus('active');

    if (!(await validateField()) || !(await validateEmailPhoneAndCaseId(newValue))) {
      setSaveBtnStatus('');
      return;
    }

    try {
      const massageReactionValue = { ...INIT_EMOJI_VALUE };

      if (isArray(newValue) && clientData.scaleType === ScaleType.EmojiReaction) {
        newValue.forEach((reaction) => {
          const splitValue = reaction.split('_');
          massageReactionValue[splitValue[0]] = Number.parseInt(splitValue[1], 10);
        });
      }

      const payload = {
        categoryId: clientData.categoryId,
        clientProfileId,
        value:
          newValue.length > 0 && clientData.scaleType === ScaleType.EmojiReaction ? massageReactionValue : newValue,
        ...(newOtherValue && { otherValue: newOtherValue })
      };

      await putClientData(token, clientRecordId, clientData.variableId, payload);
      if (IS_FIRST_OR_LAST_NAME.includes(clientData.variableId)) {
        dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.ClientEncrypted]));
      }
      await fetchClientData(token);

      notification.success({ message: 'Successfully update client data' });
      setSaveBtnStatus('finished');
      setValue(newValue);
      setOtherValue(newOtherValue);
    } catch (ex) {
      setSaveBtnStatus('');
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to update client data' });
    }

    setSaveBtnStatus('');
    setEditingVariable('');
  };

  const variableOptions = useMemo(
    () =>
      getVariableOptions({
        type: clientData.variableType,
        scaleType: clientData.scaleType,
        options: clientData.options
      }),
    [clientData.options, clientData.scaleType, clientData.variableType]
  );

  const prepareNewValue = (
    lastHistoryValue: string | string[],
    decryptedValue: string | undefined,
    maxSelection: number | undefined,
    variableType: QuestionType,
    scaleType: ScaleType | undefined
  ) => {
    const responseAsArray =
      ([QuestionType.multipleChoice, QuestionType.multipleChoiceFreeText].includes(variableType) &&
        maxSelection !== 1) ||
      scaleType === ScaleType.EmojiReaction;

    if (responseAsArray && isArray(lastHistoryValue)) {
      if (lastHistoryValue.length) {
        return variableOptions.filter((item) => lastHistoryValue.includes(item.value)).map(({ label }) => label);
      }
      return maxSelection === 1 ? '' : [];
    }
    return decryptedValue ? decryptedValue : lastHistoryValue ? lastHistoryValue : responseAsArray ? [] : '';
  };

  useEffect(() => {
    // In case Multiple choice - Multiple answer, the option(s) is deleted
    const latestHistory = clientData.histories[0];
    if (!newValue || newValue.length < 1) {
      const preNewValue = prepareNewValue(
        latestHistory?.value,
        latestHistory?.decryptedValue,
        clientData.maxSelection,
        clientData.variableType,
        clientData.scaleType
      );
      setNewValue(preNewValue);
    }

    if (!value || value.length < 1) {
      setValue(latestHistory?.decryptedValue || latestHistory?.value);
    }

    const historyOtherValue = latestHistory?.decryptedOtherValue || latestHistory?.otherValue;
    if (historyOtherValue) {
      if (!newOtherValue) {
        setNewOtherValue(historyOtherValue);
      }

      if (!otherValue) {
        setOtherValue(historyOtherValue);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, variableOptions]);

  const isEditing = editingVariable === `${clientData.categoryId}_${clientData.variableId}`;
  const isEditingOther = !!editingVariable && !isEditing;

  const lastHistoryValue = useMemo(
    () => getHistoryValue(value, clientData.variableType, variableOptions),
    [value, clientData.variableType, variableOptions]
  );

  return (
    <div className={classNames(styles.container, expanded && styles.expanded, isEditing && styles.editing)}>
      <div className={styles.listItem}>
        <div className={styles.variableName}>
          {clientData.variableName}
          {clientData.variableId === 'PI001' && (
            <HelpOutLineWithTooltips
              tooltipsClass={styles.tooltipsClass}
              iconClass={styles.iconClass}
              id={'clientIdTooltip'}
              desc={'This question will not be included in surveys'}
            />
          )}
        </div>
        <div className={styles.value}>
          {isEditing && !disabled ? (
            <div className={styles.valueEdit}>
              <div className={styles.valueInput}>
                <div className={styles.inputBox}>
                  <div className={styles.inputItem}>
                    {PrefixIcon[clientData.variableType] && (
                      <div className={styles.prefixIcon}>
                        <i className={'material-icons-outlined'}>{PrefixIcon[clientData.variableType]}</i>
                      </div>
                    )}
                    <div className={styles.editControl}>
                      {[QuestionType.multipleChoice, QuestionType.multipleChoiceFreeText, QuestionType.scale].includes(
                        clientData.variableType
                      ) ? (
                        clientData.scaleType === ScaleType.EmojiReaction ||
                        ([QuestionType.multipleChoice, QuestionType.multipleChoiceFreeText].includes(
                          clientData.variableType
                        ) &&
                          clientData.maxSelection !== undefined &&
                          clientData.maxSelection !== 1) ? (
                          <T23Select
                            options={variableOptions}
                            selectedOptions={isArray(newValue) ? newValue : newValue ? [newValue] : []}
                            onSelect={
                              clientData.maxSelection === 0 ||
                              clientData.scaleType === ScaleType.EmojiReaction ||
                              (clientData.maxSelection && clientData.maxSelection < newValue.length)
                                ? setNewValue
                                : () => {}
                            }
                          />
                        ) : (
                          <Select
                            filledArrow
                            containerClass={styles.select}
                            controlStyle={{
                              border: 'none !important',
                              boxShadow: 'none',
                              backgroundColor: 'transparent'
                            }}
                            valueStyle={{ padding: '4px 0' }}
                            options={variableOptions}
                            defaultValue={variableOptions.find((i) => i.value === newValue)}
                            value={variableOptions.find((i) => i.value === newValue)}
                            onChange={(e) => {
                              setNewValue(e?.value || '');
                              setIsTouched(true);
                            }}
                          />
                        )
                      ) : clientData.variableType === QuestionType.date ? (
                        <DatePicker
                          clearIcon={null}
                          suffixIcon={null}
                          className={styles.datePicker}
                          value={newValue ? moment(newValue, DATE_FORMAT) : undefined}
                          format={DATE_FORMAT}
                          onChange={(e) => {
                            setNewValue(e ? moment(e).format(DATE_FORMAT) : '');
                            setIsTouched(true);
                          }}
                        />
                      ) : clientData.variableType === QuestionType.phone ? (
                        <MaterialPhoneInput
                          id={clientData.variableId}
                          inputClass={styles.phoneInput}
                          buttonClass={styles.phoneButton}
                          label=""
                          onChange={(e) => {
                            setNewValue(e || '');
                            setIsTouched(true);
                          }}
                          value={newValue.toString()}
                          isError={false}
                        />
                      ) : (
                        <MaterialInput
                          id={clientData.variableId}
                          label=""
                          value={newValue}
                          onChange={(e) => {
                            setNewValue(e.target.value);
                            setIsTouched(true);
                          }}
                          autoFocus
                          className={styles.input}
                          containerClassName={styles.inputContainer}
                        />
                      )}
                    </div>
                  </div>
                  {clientData.variableType === QuestionType.multipleChoiceFreeText && (
                    <div className={styles.inputItem}>
                      <div className={styles.prefixLabel}>NOTE</div>
                      <MaterialInput
                        id={clientData.variableId}
                        label=""
                        value={newOtherValue}
                        onChange={(e) => {
                          setNewOtherValue(e.target.value);
                          setIsTouched(true);
                        }}
                        className={styles.input}
                        containerClassName={styles.inputContainer}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.buttons}>
                  {![QuestionType.multipleChoice, QuestionType.multipleChoiceFreeText, QuestionType.scale].includes(
                    clientData.variableType
                  ) && (
                    <ButtonAlt
                      error
                      className={styles.removeAnswer}
                      size={'medium'}
                      onClick={() => saveBtnStatus !== 'active' && setNewValue('')}
                      variant={'text'}
                      disabled={saveBtnStatus === 'active'}
                    >
                      Remove Answer
                    </ButtonAlt>
                  )}
                  <ButtonAlt
                    size={'medium'}
                    onClick={handleSaveChange}
                    className={styles.saveButton}
                    status={saveBtnStatus}
                  >
                    Save
                  </ButtonAlt>
                  <ButtonAlt
                    size={'medium'}
                    onClick={() => {
                      if (saveBtnStatus === 'active') return;
                      setNewValue(value);
                      setEditingVariable('');
                    }}
                    variant={'text'}
                    className={styles.closeButton}
                    disabled={saveBtnStatus === 'active'}
                  >
                    Cancel
                  </ButtonAlt>
                </div>
              </div>
              <ErrorMessage error={error} visible={isTouched && !!error} />
            </div>
          ) : (
            <div>
              <div className={styles.valueText}>
                {lastHistoryValue}
                {value &&
                  [
                    QuestionType.date,
                    QuestionType.email,
                    QuestionType.freeText,
                    QuestionType.phone,
                    QuestionType.numeric
                  ].includes(clientData.variableType) && (
                    <div className={styles.copyButtonWrapper}>
                      <ButtonAlt
                        fab
                        className={styles.button}
                        contentClassName={styles.actionIcon}
                        size={'small'}
                        variant={'text'}
                        onClick={() => !isArray(value) && handleCopyContent(value)}
                        icon={'copy'}
                      />
                    </div>
                  )}
              </div>
              {otherValue && (
                <div className={styles.valueText}>
                  <span className={styles.otherValueLabel}>NOTE</span>
                  {otherValue}
                </div>
              )}
            </div>
          )}
        </div>
        {!isEditing && (
          <div className={styles.actionButtons}>
            {clientData.histories && clientData.histories.length > 0 && (
              <div className={styles.buttonWrapper}>
                <ButtonAlt
                  fab
                  className={styles.button}
                  contentClassName={styles.actionIcon}
                  size={'small'}
                  disabled={!clientData.histories || clientData.histories.length < 1}
                  variant={'text'}
                  onClick={() => setExpanded(!expanded)}
                  icon={disabled || isEditingOther ? 'keyboard_arrow_up' : 'history'}
                />
              </div>
            )}
            <div className={styles.buttonWrapper}>
              <ButtonAlt
                fab
                className={styles.button}
                contentClassName={styles.actionIcon}
                size={'small'}
                variant={'text'}
                onClick={() => {
                  setEditingVariable(`${clientData.categoryId}_${clientData.variableId}`);
                  setIsTouched(false);
                }}
                disabled={disabled || isEditingOther}
                icon={disabled || isEditingOther ? 'lock' : 'edit'}
              />
            </div>
          </div>
        )}
      </div>
      <ExpandableContent expanded={expanded}>
        <ClientDataHistoryList
          clientDataVariable={clientData}
          clientRecordId={clientRecordId}
          variableOptions={variableOptions}
        />
      </ExpandableContent>
    </div>
  );
};

export default ClientDataListItem;
