import classnames from 'classnames';
import moment from 'moment';

import FlexBox from 'components/FlexBox/FlexBox';
import IconButton from 'components/IconButton/IconButton';
import { Appointment } from 'pages/InvoiceGenerator/interface';
import { filterInvoiceAppointmentTags } from 'utils/filterInvoiceAppointmentTags';

import styles from './GroupAppointmentItem.module.scss';

interface GroupAppointmentItemProps {
  appointment: Appointment;
  included?: boolean;
  onAddAppointment: (appointment: Appointment) => void;
  onRemoveAppointment: (appointment: Appointment) => void;
  isAddAppointmentItemDisabled: boolean;
}

const GroupAppointmentItem = ({
  appointment,
  included,
  onAddAppointment,
  onRemoveAppointment,
  isAddAppointmentItemDisabled
}: GroupAppointmentItemProps) => {
  const dateText = moment(appointment.date, 'YYYY-MM-DD').format('dddd D MMMM, YYYY');
  const startTimeText = moment(appointment.startTime, 'HH:mm').format('h:mm A');
  const isGroupAppointment = !appointment.clientRecord;
  const tags = filterInvoiceAppointmentTags((!isGroupAppointment && appointment.markedStatus) || []);

  const handleAddOrRemoveAppointment = () => {
    if (included) {
      onRemoveAppointment(appointment);
    } else {
      onAddAppointment(appointment);
    }
  };

  return (
    <FlexBox
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={12}
      className={classnames(styles.container, included && styles.included)}
    >
      <FlexBox direction="row" alignItems="center" spacing={12}>
        {isGroupAppointment ? (
          <i className={`material-icons ${styles.groupIcon}`}>groups</i>
        ) : (
          <i className={`material-icons ${styles.groupIcon}`}>person</i>
        )}
        <FlexBox direction="column" className={styles.appointmentInfo}>
          <div>
            <div>
              <span className={styles.title}>{appointment.sessionTypeName}</span> {isGroupAppointment ? 'on' : ''}
            </div>
            {isGroupAppointment ? (
              <strong>
                {dateText} at {startTimeText}
              </strong>
            ) : (
              <div>
                booked for{' '}
                <strong>
                  {appointment.clientRecord?.clientName} on {dateText} at {startTimeText}
                </strong>
              </div>
            )}
          </div>
          {tags.length > 0 && (
            <div className={styles.tags}>
              {tags.map((status, index) => (
                <div className={styles.tag} key={index}>
                  {status}
                </div>
              ))}
            </div>
          )}
        </FlexBox>
      </FlexBox>
      <IconButton
        className={included ? styles.removeIconButton : undefined}
        iconName={included ? 'remove_circle' : 'add_circle'}
        onClick={handleAddOrRemoveAppointment}
        isDisabled={isAddAppointmentItemDisabled && !included}
      />
    </FlexBox>
  );
};

export default GroupAppointmentItem;
