import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { clientRecordsInterface, ClientRecordType } from 'interfaces/Clients/clientsRecord';

export enum clientProfileSubTab {
  profile = 'profile',
  clientData = 'client-data',
  activity = 'activity',
  episodes = 'episodes',
  notes = 'notes',
  attachments = 'attachments',
  survey = 'survey',
  assessments = 'assessments',
  checkIn = 'checkIn',
  report = 'report',
  letters = 'letters',
  appointments = 'appointments',
  invoices = 'invoices',
  assignment = 'assignment',
  referrers = 'referrers',
  medicare = 'medicare',
  healthData = 'health-data'
}

interface tabIdInterface {
  tabId: clientProfileSubTab;
  isDisplay: boolean;
}

export const useGetClientDetailsTabInfo = (clientRecordData?: clientRecordsInterface) => {
  const { isEdgeAdminView, isEdgeReceptionist, isEdgeReceptionistView } = useGetAccountPackageView();
  const { medicareRebateFeatureToggle, isEoCEnabled } = useGetFeatureToggle();
  const { isInvoiceReadDenied, isAttachmentReadDenied } = useGetPermissionToggle();

  const clientDetailsTab: tabIdInterface[] = [
    {
      tabId: clientProfileSubTab.profile,
      isDisplay: true
    },
    {
      tabId: clientProfileSubTab.clientData,
      isDisplay: !isEdgeReceptionist
    },
    {
      tabId: clientProfileSubTab.healthData,
      isDisplay: !isEdgeReceptionist
    },
    {
      tabId: clientProfileSubTab.activity,
      isDisplay: true
    },
    {
      tabId: clientProfileSubTab.episodes,
      isDisplay: isEoCEnabled
    },
    {
      tabId: clientProfileSubTab.notes,
      isDisplay: !isEdgeReceptionist
    },
    {
      tabId: clientProfileSubTab.attachments,
      isDisplay: !isAttachmentReadDenied
    },
    {
      tabId: clientProfileSubTab.survey,
      isDisplay: !isEdgeReceptionist
    },
    {
      tabId: clientProfileSubTab.assessments,
      isDisplay: !isEdgeReceptionist
    },
    {
      tabId: clientProfileSubTab.checkIn,
      isDisplay: !isEdgeReceptionist && (clientRecordData?.isCompleteRecords || false)
    },
    {
      tabId: clientProfileSubTab.report,
      isDisplay: !isEdgeReceptionist
    },
    {
      tabId: clientProfileSubTab.letters,
      isDisplay: !isEdgeReceptionist
    },
    {
      tabId: clientProfileSubTab.appointments,
      isDisplay: true
    },
    {
      tabId: clientProfileSubTab.invoices,
      isDisplay: !isInvoiceReadDenied
    },
    {
      tabId: clientProfileSubTab.assignment,
      isDisplay: isEdgeReceptionistView || isEdgeAdminView
    },
    {
      tabId: clientProfileSubTab.referrers,
      isDisplay: true
    },
    {
      tabId: clientProfileSubTab.medicare,
      isDisplay:
        (medicareRebateFeatureToggle &&
          clientRecordData?.recordType &&
          [ClientRecordType.Adult, ClientRecordType.YoungPerson, ClientRecordType.Child].includes(
            clientRecordData?.recordType
          )) ||
        false
    }
  ];

  const generateTab: Record<clientProfileSubTab, tabIdInterface> = Object.assign(
    {},
    ...clientDetailsTab.map((key: tabIdInterface) => ({
      [key.tabId]: {
        tabId: key.tabId,
        isDisplay: key.isDisplay
      }
    }))
  );

  return generateTab;
};
