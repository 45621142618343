import ModalV2 from 'components/ModalV2/ModalV2';
import { useState } from 'react';
import styles from './PackageModal.module.scss';
import {
  FunderLimitType,
  funderType,
  PackagesBenefit,
  PackagesBenefitLimit,
  PackagesInterface,
  PackagesServiceSequence,
  PackagesStatus
} from 'interfaces/Packages/packages';
import { PackageSetUp } from './components/PackageSetUp/PackageSetUp';
import { PackageAppointment } from './components/PackageAppointment/PackageAppointment';
import { useCreatePackageMutation, useUpdatePackageMutation } from 'redux/endpoints/scheduleServices/package';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { notification } from 'antd';
import { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';

interface PackageModalProps {
  visible: boolean;
  onClose: () => void;
  packagesDetail?: PackagesInterface;
}

export const defaultPackages: PackagesInterface = {
  status: PackagesStatus.Draft,
  name: '',
  type: undefined,
  description: '',
  billingCode: '',
  funders: [],
  serviceAccessOrder: PackagesServiceSequence.Any,
  appointmentTypeIds: [],
  benefitControl: {
    by: PackagesBenefit.Client,
    limitMode: PackagesBenefitLimit.None
  }
};

export const PackageModal = ({ visible, onClose, packagesDetail }: PackageModalProps) => {
  const [packageFields, setPackageFields] = useState<PackagesInterface>(packagesDetail || defaultPackages);
  const [step, setStep] = useState<number>(1);
  const { accountId } = useGetAccountId();
  const [submitStatus, setSubmitStatus] = useState<{ btnType: PackagesStatus | undefined; status: ButtonStatusType }>({
    btnType: undefined,
    status: ''
  });

  const [createPackage] = useCreatePackageMutation();
  const [updatePackage] = useUpdatePackageMutation();

  const handleBackStep = () => {
    setStep(step - 1);
  };

  const onChangePackageData = (value: PackagesInterface) => {
    const massageData = {
      ...value,
      benefitControl: {
        by: value.benefitControl.by,
        limitMode: value.benefitControl.limitMode,
        ...(value.benefitControl.by === PackagesBenefit.Funder && {
          funderLimitType: value.benefitControl.funderLimitType
        }),
        perFunderLimit: value.benefitControl.perFunderLimit || [],
        limit: value.benefitControl.limit
      }
    };
    setPackageFields(massageData);
  };

  const handleSubmitForm = async (status?: PackagesStatus) => {
    if (step === 1) {
      setStep(2);
    }
    if (step === 2) {
      setSubmitStatus({
        btnType: status,
        status: 'active'
      });
      try {
        const massagePayload = {
          name: packageFields.name,
          description: packageFields.description,
          billingCode: packageFields.billingCode,
          appointmentTypeIds: packageFields.appointmentTypeIds,
          serviceAccessOrder: packageFields.serviceAccessOrder,
          type: packageFields.type,
          status: status || PackagesStatus.Draft,
          funders: packageFields.funders.map((funderObj) => ({
            type: funderObj.type,
            ...(funderObj.type === funderType.AddressBook && {
              funderId: funderObj.funderId
            }),
            ...(funderObj.type === funderType.Default && {
              name: funderObj.name
            })
          })),
          ...(packageFields.feeChecked && {
            fee: packageFields.fee
          }),
          benefitControl: {
            by: packageFields.benefitControl.by,
            limitMode: packageFields.benefitControl.limitMode,
            ...(packageFields.benefitControl.by === PackagesBenefit.Funder &&
            packageFields.benefitControl.funderLimitType === FunderLimitType.PerFunder
              ? {
                  perFunderLimit: packageFields.benefitControl.perFunderLimit?.map((funderLimitObj) => ({
                    type: funderLimitObj.type,
                    limit: funderLimitObj.limit,
                    name: funderLimitObj.name,
                    ...(funderLimitObj.type === funderType.AddressBook && {
                      funderId: funderLimitObj.funderId
                    })
                  }))
                }
              : packageFields.benefitControl.limitMode !== PackagesBenefitLimit.None && {
                  limit: packageFields.benefitControl.limit
                })
          }
        };

        if (packagesDetail?._id) {
          await updatePackage({
            accountId,
            packageId: packagesDetail._id,
            payload: massagePayload
          }).unwrap();

          notification.success({
            message: 'Package has been updated',
            duration: 2
          });
        } else {
          await createPackage({
            accountId,
            payload: massagePayload
          }).unwrap();

          notification.success({
            message: 'Package has been created',
            duration: 2
          });
        }

        setSubmitStatus({
          btnType: status,
          status: 'finished'
        });
        setTimeout(() => {
          setSubmitStatus({
            btnType: status,
            status: ''
          });
          onClose();
          setStep(1);
          setPackageFields(defaultPackages);
        }, 1000);
      } catch (ex) {
        console.error(ex);
        setSubmitStatus({
          btnType: status,
          status: ''
        });
        notification.error({
          message: 'Something went wrong while trying to create the package.'
        });
      }
    }
  };

  const generateStepContent = () => {
    const packageName = packageFields.name;
    switch (step) {
      case 1:
        return {
          title: 'Set up Package',
          content: (
            <PackageSetUp
              packageData={packageFields}
              onChangePackageData={onChangePackageData}
              onSubmit={handleSubmitForm}
            />
          )
        };
      case 2:
        return {
          title: <>Set up {<span className={styles.title}>{packageName}</span>} appointments</>,
          content: (
            <PackageAppointment
              packageData={packageFields}
              onChangePackageData={onChangePackageData}
              onSubmit={handleSubmitForm}
              submitStatus={submitStatus}
            />
          )
        };
      default:
        return {
          title: '',
          content: <div />
        };
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={visible}
      title={generateStepContent().title}
      onModalClose={onClose}
      showBackStepBtn={step > 1}
      onClickBackBtn={handleBackStep}
      currentStep={step}
      totalStep={2}
    >
      {generateStepContent().content}
    </ModalV2>
  );
};
