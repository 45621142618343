import RichEditor from 'components/RichEditor/RichEditor';
import { useMemo } from 'react';
import styles from './TextEditor2.module.scss';

export const JODITLicense = process.env.REACT_APP_JODIT_ID || '';

export interface TextEditorProps {
  value: string;
  height?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  readOnly?: boolean;
  className?: string;
  placeholder?: string;
  onChange: (e: any) => void;
  onBlurMethod?: boolean;
}

const DISABLE_PLUGINS = 'about,add-new-line,backspace,bold,tune-block';
const TOOLBAR_BUTTONS = 'bold,italic,underline,strikethrough,brush,ol,ul,outdent,indent,align';

const TextEditor2 = ({
  value,
  height,
  minHeight,
  maxHeight,
  placeholder,
  readOnly,
  onChange,
  onBlurMethod
}: TextEditorProps) => {
  const config = useMemo(
    () => ({
      license: JODITLicense,
      enter: 'div',
      height: height,
      autofocus: true,
      statusbar: false,
      useSearch: false,
      readonly: readOnly,
      toolbarInline: true,
      allowResizeY: false,
      minHeight: minHeight,
      maxHeight: maxHeight,
      toolbarAdaptive: false,
      fillEmptyParagraph: true,
      buttons: TOOLBAR_BUTTONS,
      disablePlugins: DISABLE_PLUGINS,
      placeholder: placeholder || '',
      spellcheck: true
    }),
    [placeholder, readOnly, height, minHeight, maxHeight]
  );

  return (
    <div className={`text-editor ${styles.container}`}>
      <RichEditor value={value} config={config} onChange={onChange} onBlurMethod={onBlurMethod} />
    </div>
  );
};

export default TextEditor2;
