import styles from './AppointmentTypeDetails.module.scss';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useMemo } from 'react';
import { useGetAppointmentTypesForInlineAppointmentQuery } from 'redux/endpoints/scheduleServices/appointmentType';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { config } from 'config/config';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';

interface AppointmentTypeSelectProps {
  clinicianId: string;
  selectedParticipantType: ParticipantType;
  selectedAppointmentTypeId: string;
}

const AppointmentTypeDetails = ({
  clinicianId,
  selectedParticipantType,
  selectedAppointmentTypeId
}: AppointmentTypeSelectProps) => {
  const { isEdgeAdminUser, isNormalUserView, isEdgeReceptionist } = useGetAccountPackageView();
  const { isAppointmentRateReadDenied } = useGetPermissionToggle();

  const {
    data: appointmentTypesData,
    isLoading: isAppointmentTypesDataLoading,
    isFetching: isAppointmentTypesDataFetching
  } = useGetAppointmentTypesForInlineAppointmentQuery({
    isAdmin: isEdgeAdminUser || isEdgeReceptionist || isNormalUserView,
    clinicianId: clinicianId || '',
    participantType: selectedParticipantType
  });

  const appointmentTypeDetails = useMemo(
    () => appointmentTypesData?.data.find((apptType) => apptType._id === selectedAppointmentTypeId),
    [appointmentTypesData, selectedAppointmentTypeId]
  );

  return (
    <div className={styles.container}>
      {isAppointmentTypesDataLoading || isAppointmentTypesDataFetching ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <div className={styles.aTBoxWrapper}>
          <div className={styles.name}>{appointmentTypeDetails?.name}</div>
          <div className={styles.description}>{appointmentTypeDetails?.description}</div>
          <div className={styles.durationAndRateContainer}>
            <div className={styles.iconLabel}>
              <div className={`material-icons-outlined ${styles.icon}`}>schedule</div>
              <div className={styles.label}>
                {appointmentTypeDetails?.duration.minutes === 1440
                  ? 'Full day'
                  : `${appointmentTypeDetails?.duration.minutes} minutes`}
              </div>
            </div>
            {!isAppointmentRateReadDenied && (
              <div className={styles.iconLabel}>
                <i className={`material-icons-outlined ${styles.icon}`}>{config.moneyIcon}</i>
                <div className={styles.label}>{appointmentTypeDetails?.rate.amount}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentTypeDetails;
