import { notification } from 'antd';
import { Letter } from 'interfaces/Letters/letter';
import { useCallback, useEffect, useState } from 'react';
import { getLetter, getLetterByAdmin } from 'utils/http/DocumentService/Letters/letters';
import { useGetAccountPackageView } from '../GetAccountInfo/accountPackageView';

export const useFetchLetter = (token?: string, accountId?: string, clinicianId?: string, letterId?: string) => {
  const [letter, setLetter] = useState<Letter>();
  const [loading, setLoading] = useState(true);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

  const fetchLetter = useCallback(
    async (token?: string, accountId?: string, clinicianId?: string, letterId?: string) => {
      setLoading(true);
      try {
        if (token && accountId && clinicianId && letterId && letterId !== 'new') {
          const response =
            isEdgeAdminView || isEdgeReceptionist
              ? await getLetterByAdmin(token, accountId, letterId)
              : await getLetter(token, accountId, clinicianId, letterId);
          const data = await response.json();
          setLetter(data);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        notification.error({ message: 'Failed to load letter template' });
      }
      if (letterId === 'new') {
        setLetter(undefined);
        setLoading(false);
      }
    },
    [isEdgeAdminView, isEdgeReceptionist]
  );

  useEffect(() => {
    fetchLetter(token, accountId, clinicianId, letterId);
  }, [token, accountId, clinicianId, letterId, fetchLetter]);

  return { letter, isLoadingLetter: loading, fetchLetter };
};
