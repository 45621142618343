import { notification } from 'antd';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ClinicianListInterface } from 'components/v2/ClinicianSelect/ClinicianSelect';
import { Formik } from 'formik';
import { AccessRight } from 'interfaces/Clients/clinician';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { AppointmentSlots, Attendee } from 'interfaces/Schedule/Appointment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { defaultTimezone } from 'utils/hooks/GetTimezones/getTimezones';
import { useGetAccessToken } from 'utils/hooks/token';
import { postBespokeAppointment } from 'utils/http/appointment';
import { formatEndDate } from 'utils/dateChecker';
import { convertTimeToUtcDateObject } from 'utils/helpers/timezone';
import EventOwnerAndFormatField from '../EventOwnerAndFormatField/EventOwnerAndFormatField';
import styles from './BespokeForm.module.scss';
import AppointmentTitle from './components/AppointmentTitle/AppointmentTitle';
import Attendees from './components/Attendees/Attendees';
import Details from './components/Details/Details';
import Location from './components/Location/Location';
import Schedule from './components/Schedule/Schedule';
import { appointmentBeSpokeSchema, initialValues } from './constants';

type BespokeFormProps = {
  isEdit?: boolean;
  appointment?: AppointmentSlots;
  eventType: string;
  date?: Date;
  defaultTimeZone?: string;
  onClose: () => void;
  setEventType: (eventType: string) => void;
  onCreateEditSuccess?: () => void;
  handleUpdateAppointment?: (payload: any, setSubmitStatus: Function) => Promise<void>;
};

const BespokeForm = ({
  isEdit = false,
  appointment,
  eventType,
  date,
  defaultTimeZone: initTimeZone,
  setEventType,
  onClose,
  onCreateEditSuccess,
  handleUpdateAppointment
}: BespokeFormProps) => {
  const { token } = useGetAccessToken();
  const { isEdgeAdminView, isEdgeReceptionistView, isEdgeReceptionist } = useGetAccountPackageView();
  const [clinician, setClinician] = useState<Partial<ClinicianListInterface>>();
  const [createEvent, setCreateEvent] = useState<'' | 'active' | 'finished'>('');
  const [selectedAttendees, setSelectedAttendees] = useState<Attendee[]>([]);
  const [timeZone, setTimeZone] = useState<string>(initTimeZone || defaultTimezone);

  useEffect(() => {
    if (isEdit && appointment) {
      setSelectedAttendees(
        appointment.attendees || [
          {
            ...appointment?.clientRecord?.clientProfiles[0],
            clientRecordId: appointment.clientRecord?._id,
            clientProfileId: appointment?.clientRecord?.clientProfiles[0]._id
          } as Attendee
        ]
      );
    }
  }, [isEdit, appointment]);

  const handleSelectClinician = (clinician?: Partial<PractitionersDetailsInterface>) => {
    setClinician(clinician);
    //Prevent set tz from running without selecting any clinician
    if (isEdgeAdminView || isEdgeReceptionist) {
      setTimeZone(clinician?.workTimeZone || initTimeZone || defaultTimezone);
    }
    if (clinician?.accessRight === AccessRight.Mentor) {
      setSelectedAttendees((selectedAttendees) =>
        selectedAttendees.filter((attendee) => attendee.clinicianId && attendee.clinicianId === clinician._id)
      );
    }
  };

  const handleBeSpokeSubmit = async (value: typeof initialValues) => {
    try {
      setCreateEvent('active');
      const endDateTime = convertTimeToUtcDateObject({
        date: value.date,
        time: moment(value.endTime, 'hh:mm a').format('HH:mm'),
        timeZone
      });

      const payload = {
        recurringAppointment: {
          frequency: value.isRecurring ? value.frequency : initialValues.frequency,
          amount: value.isRecurring ? value.occurrences : initialValues.occurrences
        },
        title: value.title,
        notes: value.notes,
        eventOwner: value.eventOwner,
        date: value.date,
        endDate: formatEndDate(endDateTime),
        startTime: moment(value.startTime, 'hh:mm a').format('HH:mm'),
        endTime: moment(value.endTime, 'hh:mm a').format('HH:mm'),
        startDateTime: convertTimeToUtcDateObject({
          date: value.date,
          time: moment(value.startTime, 'hh:mm a').format('HH:mm'),
          timeZone
        }),
        endDateTime: convertTimeToUtcDateObject({
          date: value.date,
          time: moment(value.endTime, 'hh:mm a').format('HH:mm'),
          timeZone
        }),
        timeZone,
        faceToFaceLocation: value.faceToFaceLocation,
        attendees: selectedAttendees,
        asAdmin: isEdgeAdminView || isEdgeReceptionistView
      };

      if (value.bookRoom && value.roomInfo) {
        Object.assign(payload, {
          room: JSON.parse(value.roomInfo)
        });
      } else {
        Object.assign(payload, {
          room: undefined
        });
      }

      if (isEdit) {
        Object.assign(payload, {
          id: appointment?._id
        });
        await handleUpdateAppointment!(payload, setCreateEvent);
      } else {
        const response = await postBespokeAppointment(payload, token);
        if (response.statusCode === 201 && onCreateEditSuccess) {
          onCreateEditSuccess();

          notification.success({
            message: 'Bespoke appointment created.',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          onClose();
        } else if (response.statusCode === 409) {
          notification.error({ message: (await response.json()).message ?? 'Something went wrong.' });
        }
      }
    } catch (err) {
      notification.error({ message: 'Something went wrong.' });
      console.error(err);
    } finally {
      setCreateEvent('');
    }
  };

  const recurringDayDifference =
    appointment?.recurrings && appointment.recurrings.length > 1
      ? moment(appointment.recurrings[1].date, 'YYYY-MM-DD').diff(
          moment(appointment.recurrings[0].date, 'YYYY-MM-DD'),
          'd'
        )
      : 0;

  const formattedInitialValues =
    isEdit && appointment
      ? {
          eventOwner: appointment.clinicianId || '',
          isRecurring: !!appointment.recurringAppointmentId,
          frequency: recurringDayDifference.toString(),
          occurrences: appointment.recurrings?.length.toString(),
          title: appointment.title,
          notes: appointment.notes || '',
          date: appointment.date || '',
          endDate: appointment.endDate || '',
          timeZone: initTimeZone || '',
          startTime: appointment.startDateTime
            ? moment(appointment.startDateTime).tz(timeZone).format('HH:mm')
            : appointment.startTime,
          endTime: appointment.endDateTime
            ? moment(appointment.endDateTime).tz(timeZone).format('HH:mm')
            : appointment.endTime,
          faceToFaceLocation: appointment.faceToFaceLocation || '',
          bookRoom: !!appointment.room,
          roomInfo: JSON.stringify(appointment.room) || '',
          attendees: []
        }
      : initialValues;

  return (
    <Formik
      initialValues={formattedInitialValues}
      validationSchema={appointmentBeSpokeSchema}
      onSubmit={handleBeSpokeSubmit}
    >
      {({ submitForm }) => {
        return (
          <div className={styles.container}>
            <EventOwnerAndFormatField
              eventType={eventType}
              setClinician={handleSelectClinician}
              setEventType={setEventType}
              selectedClinician={clinician || {}}
              hideAppointmentFormat={isEdit}
            />
            <div className={styles.formContainer}>
              <div className={styles.eventTitleContainer}>
                <AppointmentTitle />
              </div>
              <div>
                <div className={styles.label}>When</div>
                <Schedule isEdit={isEdit} date={date} timeZone={timeZone} />
              </div>
              <div>
                <div className={styles.label}>Where</div>
                <Location bookedAppointmentId={appointment?._id} />
              </div>
              <div>
                <div className={styles.label}>Invite attendees</div>
                <Attendees
                  clinicianId={clinician?.accessRight === AccessRight.Mentor ? clinician._id || '' : ''}
                  selectedAttendees={selectedAttendees}
                  setSelectedAttendees={setSelectedAttendees}
                />
              </div>
              <div>
                <div className={styles.label}>Description and details</div>
                <Details />
              </div>
              <div className={styles.footer}>
                <div>
                  <ButtonAlt status={createEvent} onClick={submitForm}>
                    Save Event
                  </ButtonAlt>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default BespokeForm;
