import { PutLetterRequest } from 'interfaces/Letters/letter';
import styles from './LetterPreview.module.scss';
import ReportContentHeader from 'pages/Report/components/ReportContentComponents/ReportContentHeader/ReportContentHeader';
import SignatureSection from 'pages/Report/components/ReportContentComponents/SignatureSection/SignatureSection';
import { clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

interface LetterPreviewProps {
  data: PutLetterRequest;
  clientRecord?: Pick<clientRecordsInterface, 'recordType'> & {
    clientProfiles: Pick<clientProfilesInterface, 'firstName' | 'lastName' | 'name' | 'role'>[];
  };
}

const LetterPreview = ({ data, clientRecord }: LetterPreviewProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <ReportContentHeader
          type="Letter"
          clinicianDetails={{
            clinician: data.clinician,
            practice: data.practice
          }}
          clinicianContactData={data.contactDetails}
          reportName={data.letterName}
          issueDate={data.issueDate}
          clientRecord={clientRecord}
          isPreview
        />
        <div className={styles.content}>
          <div dangerouslySetInnerHTML={{ __html: data.body }} />
        </div>
        <div>
          <SignatureSection
            previewMode
            clinician={data.clinician}
            signVal={data.clinicianSignature}
            extraDetailsVal={data.clinicianSignatureExtraDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default LetterPreview;
