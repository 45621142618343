import { createSlice } from '@reduxjs/toolkit';
import { Sorting, SortType } from '../utils';
import moment from 'moment';
import { RootState } from 'redux/store';
import { MOMENTJS_MONTH_YEAR_FORMAT } from 'utils/dateChecker';

export interface PractitionerReportFilters {
  dateString: string;
}

export interface PractitionerReportSliceState {
  sorting: Sorting;
  filters: PractitionerReportFilters;
}

const initialState: PractitionerReportSliceState = {
  sorting: {
    sortBy: 'dateOfService',
    sortType: SortType.ASC
  },
  filters: {
    dateString: moment().format(MOMENTJS_MONTH_YEAR_FORMAT)
  }
};

export const practitionerReportSlice = createSlice({
  name: 'practitionerReportSlice',
  initialState,
  reducers: {
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload
      };
    }
  }
});

export const selectSorting = (state: RootState) => state.practitionerReportSlice.sorting;
export const selectFilters = (state: RootState) => state.practitionerReportSlice.filters;

export const { setFilters, setSorting } = practitionerReportSlice.actions;

export default practitionerReportSlice.reducer;
