import { CaseNoteTemplate } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import { useEffect, useRef, useState } from 'react';
import styles from './TemplateDropdown.module.scss';

interface TemplateDropdownProps {
  currentTemplate?: CaseNoteTemplate;
  handleSelectTemplate: (id: string, confirm?: true) => Promise<void>;
  templateList?: CaseNoteTemplate[];
}

const TemplateDropdown = ({ currentTemplate, handleSelectTemplate, templateList }: TemplateDropdownProps) => {
  const node = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const closeMenu = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }

    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeMenu);

    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  });

  const handleClickMenuItem = (templateId: string) => {
    handleSelectTemplate(templateId);
    setShowMenu(false);
  };

  return (
    <div ref={node} className={styles.selectTemplate}>
      <div className={styles.button} onClick={() => setShowMenu(!showMenu)}>
        {currentTemplate?.name || 'Insert Template'}
        <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
      </div>
      <div className={styles.menuWrapper}>
        <div className={showMenu ? styles.menuBoxShow : styles.menuBoxHide}>
          {templateList?.map((template) => (
            <div
              className={template._id === currentTemplate?._id ? styles.activeListBox : styles.listBox}
              key={template._id}
              onClick={() => handleClickMenuItem(template._id!)}
            >
              <div className={styles.listTitle}>{template.name}</div>
              <div className={styles.listDesc}>{template.description}</div>
            </div>
          ))}
          {(!templateList || templateList.length === 0) && (
            <div className={styles.listBoxContext}>
              <div className={styles.listDesc}>There is no assigned template.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateDropdown;
