import styles from './WriteOffModal.module.scss';
import ModalV2 from 'components/ModalV2/ModalV2';
import { InvoiceWithType } from '../InvoiceList/constants';
import InvoiceDetailsForModal from '../InvoiceDetailsForModal/InvoiceDetailsForModal';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { ChangeEvent, useMemo, useState } from 'react';
import { WriteOffForm } from 'redux/invoices/invoiceSlice';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import AppointmentDatePicker from 'pages/Calendar/components/CalendarWithHighlightsT23/components/CalendarView/components/InlineBookingModal/components/AppointmentDatePicker/AppointmentDatePicker';
import classNames from 'classnames';
import { useCurrency } from 'utils/hooks/useCurrency';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_YEAR_MONTH_DAY_FORMAT } from 'utils/dateChecker';
import { useGetInvoiceWithTypeByIdQuery, useWriteOffMutation } from 'redux/endpoints/billingServices/invoices';
import { notification, Skeleton } from 'antd';
import moment from 'moment';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

interface WriteOffModalProps {
  showModal: boolean;
  formValue: WriteOffForm | undefined;
  invoiceRecordId?: string;
  invoice?: InvoiceWithType;
  successMessage?: string;
  onCloseModal: () => void;
  onChangeValue: (value: Partial<WriteOffForm>) => void;
  onSubmitSuccess?: () => void;
}

type FormError = Pick<WriteOffForm, 'date'>;
const FormErrorInitialValue: FormError = {
  date: ''
};

const WriteOffModal = ({
  showModal,
  invoice,
  invoiceRecordId,
  formValue,
  successMessage,
  onCloseModal,
  onChangeValue,
  onSubmitSuccess
}: WriteOffModalProps) => {
  const { accountId } = useGetAccountId();
  const { formatCurrency } = useCurrency();

  const [formError, setFormError] = useState<FormError>(FormErrorInitialValue);
  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');

  const [postWriteOff] = useWriteOffMutation();

  const { data, isLoading, isFetching } = useGetInvoiceWithTypeByIdQuery(
    {
      invoiceId: invoiceRecordId || ''
    },
    { skip: !invoiceRecordId }
  );

  const invoiceData: InvoiceWithType | undefined = useMemo(() => {
    if (invoice) {
      return invoice;
    }
    if (!isLoading && !isFetching && data) {
      return data;
    }
  }, [invoice, isLoading, isFetching, data]);

  const { _id } = invoiceData || {};
  const { reference, date } = formValue || {};

  const handleCloseModal = () => {
    setFormError(FormErrorInitialValue);
    onCloseModal();
  };

  const onChangeReference = (value: string) => {
    onChangeValue({ reference: value });
  };

  const onChangeDate = (value: Date) => {
    onChangeValue({ date: moment(value).format(MOMENTJS_YEAR_MONTH_DAY_FORMAT) });
    setFormError({
      ...formError,
      date: ''
    });
  };

  const handleSubmit = async () => {
    if (date) {
      setSubmitButtonStatus('active');
      try {
        await postWriteOff({
          accountId,
          invoiceId: _id || '',
          body: {
            ...(reference && {
              reference
            }),
            date
          }
        }).unwrap();

        notification.success({
          message: successMessage || 'Invoice written off. New write off created.',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitButtonStatus('finished');
        onSubmitSuccess && onSubmitSuccess();
        handleCloseModal();
      } catch (ex) {
        notification.error({
          message: 'Write off failed.  Please try again, if failures persist please contact us to investigate.'
        });
        setSubmitButtonStatus('');
      }
    } else {
      setFormError({
        date: !date ? 'Required' : ''
      });
    }
  };

  return (
    <ModalV2
      containerClassName={styles.modalContainer}
      isModalOpen={showModal}
      title="Write Off"
      onModalClose={handleCloseModal}
      bodyStyle={{ padding: '0' }}
      headerContainerClassName={styles.headerContainerClassName}
      titleClassName={styles.titleClassName}
    >
      <div className={styles.container}>
        {/* Invoice details */}
        <InvoiceDetailsForModal invoice={invoiceData} isLoading={isLoading || isFetching} />

        <div className={styles.form}>
          <div className={styles.rowContainer}>
            <div className={styles.dateContainer}>
              <div className={styles.dateInputHeader}>Date</div>
              <AppointmentDatePicker
                error={formError.date}
                placeholder="Select date"
                labelFormat={MOMENTJS_DATE_FORMAT}
                selectedDate={date ? new Date(date) : undefined}
                hideErrorDesc
                showBorderBottom
                icon="calendar_today"
                iconClassName={classNames('material-icons-outlined', styles.calendarIcon)}
                controllerClassName={styles.dateController}
                labelClassName={styles.dateLabel}
                borderBottomClassName={formError.date ? styles.calendarBorderBottomError : styles.calendarBorderBottom}
                onSelect={onChangeDate}
              />
            </div>
            <div className={styles.referenceWrapper}>
              <TitleInput
                inputContainer={styles.referenceInput}
                inputProps={{
                  placeholder: 'Enter manual reference',
                  value: reference || '',
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    onChangeReference(e.target.value);
                  }
                }}
                hideErrorDesc
                defaultLine
              />
              <div className={styles.systemReference}>
                {!isLoading && !isFetching && invoiceData ? (
                  <>
                    <span>{`${invoiceData.invoiceId}-W`}</span>
                  </>
                ) : (
                  <Skeleton.Input className={styles.invoiceIdLoading} active />
                )}{' '}
                is system reference
              </div>
            </div>
          </div>

          <div className={styles.info}>
            <i className={'material-icons-outlined'}>info</i>
            <div>
              You are about to write off the entire outstanding balance of&nbsp;
              {!isLoading && !isFetching && invoiceData ? (
                <strong>{formatCurrency(invoiceData.owed || 0)}</strong>
              ) : (
                <Skeleton.Input className={styles.owedLoading} active />
              )}
              &nbsp;from this invoice. If you wish to write off a different amount please process any refunds or credits
              before actioning the write off.
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <ButtonAlt
            status={submitButtonStatus}
            disabled={isLoading || isFetching || !invoiceData}
            loadingWord="Processing..."
            onClick={handleSubmit}
          >
            Write Off
          </ButtonAlt>
        </div>
      </div>
    </ModalV2>
  );
};

export default WriteOffModal;
