import LoadingDot from 'components/LoadingDot/LoadingDot';
import { CaseNote } from 'interfaces/caseNote';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import moment from 'moment';
import SimpleNote from 'pages/PatientDetails/components/SimpleNote/SimpleNote';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';
import { useGetAccessToken } from 'utils/hooks/token';
import MentorHeader from '../../../MentorHeader/MentorHeader';
import Bazaar from './components/Bazaar/Bazaar';
import CaptureSessionFeedback from './components/CaptureSessionFeedback/CaptureSessionFeedback';
import CaseNoteDetail from './components/CaseNoteDetail/CaseNoteDetail';
import MemberAppointmentDetail from './components/MemberAppointmentDetail/MemberAppointmentDetail';
import MemberDetailList from './components/MemberDetailList/MemberDetailList';
import QuickNoteDetail from './components/QuickNoteDetail/QuickNoteDetail';
import { useFetchNotesByClientId } from './hooks/getClientNotes';
import { useFetchClientDetailByClientId } from './hooks/getClientDetailByClientId';
import { useFetchNextAppointmentsByClientId } from './hooks/getNextAppointments';
import { QuickNote } from './interfaces';
import styles from './MemberDetail.module.scss';

interface MemberDetailProps {
  profile: ProfileInterface;
}

const MemberDetail = ({ profile }: MemberDetailProps) => {
  const { accountSettings } = useGetAccountSettings();
  const { token } = useGetAccessToken();
  const navigate = useNavigate();
  const path = useParams<{ clientId: string }>();
  const clientRecordId = path.clientId ?? '';
  const [refreshList, setRefreshList] = useState(0);

  const { clientDetails, isClientDetailLoading } = useFetchClientDetailByClientId(token, clientRecordId);
  const { nextAppointments, isNextAppointmentsLoading } = useFetchNextAppointmentsByClientId(token, clientRecordId);
  const { memberNotes, isMemberNotesLoading } = useFetchNotesByClientId(token, clientRecordId, refreshList);
  const [t] = useTranslation();

  const handleBackBtn = () => {
    navigate(-1);
  };

  const hasBazaar = !!(
    clientDetails?.clientProfiles.length === 1 &&
    accountSettings &&
    accountSettings.bazaarUrl &&
    accountSettings.bazaarChapterTagIds
  );

  return isMemberNotesLoading || isNextAppointmentsLoading || isClientDetailLoading || !clientDetails ? (
    <div className={styles.loadingWrapper}>
      <LoadingDot />
    </div>
  ) : (
    <>
      <div className={styles.container}>
        <MentorHeader profile={profile} horizontal>
          <div className={styles.header}>
            <div className={styles.backBtn} onClick={() => handleBackBtn()}>
              <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
              <div className={styles.goBackText}>BACK</div>
            </div>
          </div>
        </MentorHeader>
        <div className={styles.content}>
          <div className={styles.clientInformation}>
            <MemberDetailList client={clientDetails} />
            <MemberAppointmentDetail nextAppointments={nextAppointments} />
            {hasBazaar && <Bazaar />}
          </div>
          <div className={styles.sessionFeedback}>
            <CaptureSessionFeedback
              profile={profile}
              recordId={clientRecordId}
              onCreated={() => setRefreshList(refreshList + 1)}
              hasBazaar={hasBazaar}
            />
          </div>
        </div>
        {memberNotes.length > 0 && (
          <>
            <div className={styles.divider} />
            <div className={styles.clientJourney}>
              {t('label.client_journey')}
              <div className={styles.noteList}>
                {memberNotes.map((note, index) => (
                  <div key={index}>
                    <div className={styles.title}>
                      <div className={styles.noteIcon}>
                        <i className={`material-icons-outlined`}>speaker_notes</i>
                      </div>
                      {note.isCaseNote
                        ? note.note.title
                        : `${moment(note.note.updatedAt).format('MMMM D, YYYY')} - YOUR NOTE titled ${note.note.title}`}
                    </div>
                    {note.isCaseNote ? (
                      <CaseNoteDetail caseNote={note.note as CaseNote} />
                    ) : (
                      <QuickNoteDetail quickNote={note.note as QuickNote} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <SimpleNote recordId={clientRecordId} onCreated={() => setRefreshList(refreshList + 1)} />
    </>
  );
};
export default MemberDetail;
