import classNames from 'classnames';
import { AppointmentType, DayTimeSelection, DeliveryType, ScheduleOption } from 'interfaces/Schedule/AppointmentType';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import styles from './AppointmentTypeOption.module.scss';
import { config } from 'config/config';

interface AppointmentTypeOptionProps extends AppointmentType {
  _id: string;
  selected: boolean;
  onClick: (id: string) => void;
}

const AppointmentTypeOption = ({
  _id,
  name,
  description,
  duration,
  rate,
  dayTimeSelections,
  deliveryOptions,
  selected,
  onClick
}: AppointmentTypeOptionProps) => {
  const { isAppointmentRateReadDenied } = useGetPermissionToggle();

  const generateDayTimeSelections = (dayTimeSelections: DayTimeSelection) => {
    const availabilities =
      dayTimeSelections.kind === ScheduleOption.AutoFit ? dayTimeSelections.autoFit : dayTimeSelections.fixedTime;
    return (
      <>
        <div className={availabilities?.Monday?.isActive ? styles.active : ''}>M</div>
        <div className={availabilities?.Tuesday?.isActive ? styles.active : ''}>T</div>
        <div className={availabilities?.Wednesday?.isActive ? styles.active : ''}>W</div>
        <div className={availabilities?.Thursday?.isActive ? styles.active : ''}>T</div>
        <div className={availabilities?.Friday?.isActive ? styles.active : ''}>F</div>
        <div className={availabilities?.Saturday?.isActive ? styles.active : ''}>S</div>
        <div className={availabilities?.Sunday?.isActive ? styles.active : ''}>S</div>
      </>
    );
  };

  return (
    <button
      className={classNames(styles.container, {
        [styles.selected]: selected
      })}
      onClick={() => onClick(_id)}
    >
      <div className={styles.name}>{name}</div>
      {description && <div className={styles.description}>{description}</div>}
      <div className={styles.durationAndRateContainer}>
        <div className={styles.iconLabel}>
          <span className="material-icons-outlined">schedule</span>
          <div className={styles.label}>{duration.minutes === 1440 ? 'Full day' : `${duration.minutes} minutes`}</div>
        </div>
        {!isAppointmentRateReadDenied && (
          <div className={styles.iconLabel}>
            <i className="material-icons-outlined">{config.moneyIcon}</i>
            <div className={styles.label}>{rate.amount}</div>
          </div>
        )}
      </div>
      {dayTimeSelections && (
        <div>
          <div className={styles.label}>AVAILABLE</div>
          <div className={styles.weekdaysContainer}>{generateDayTimeSelections(dayTimeSelections)}</div>
        </div>
      )}
      {deliveryOptions.length > 0 && (
        <div>
          <div className={styles.label}>VIA</div>
          <div className={styles.deliveryTypeContainer}>
            <div className={deliveryOptions.includes(DeliveryType.FaceToFace) ? styles.active : ''}>
              <span className="material-icons">supervisor_account</span>
            </div>
            <div
              className={
                [DeliveryType.PhoneCall, DeliveryType.PhoneCallDialClient].some((phoneCallDeliveryType) =>
                  deliveryOptions.includes(phoneCallDeliveryType)
                )
                  ? styles.active
                  : ''
              }
            >
              <span className="material-icons">call</span>
            </div>
            <div className={deliveryOptions.includes(DeliveryType.VideoCall) ? styles.active : ''}>
              <span className="material-icons-outlined">videocam</span>
            </div>
            <div className={deliveryOptions.includes(DeliveryType.Other) ? styles.active : ''}>
              <span className="material-icons-outlined">connect_without_contact</span>
            </div>
          </div>
        </div>
      )}
      {selected && (
        <div className={styles.selectedBadge}>
          <span className="material-icons">check_circle</span>
        </div>
      )}
    </button>
  );
};

export default AppointmentTypeOption;
