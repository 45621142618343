import { Skeleton } from 'antd';
import classNames from 'classnames';
import { AddressBookRole } from 'pages/AddressBook/Interfaces/AddressBook';
import { getAddressBookName } from 'pages/AddressBook/helpers';
import { getRecipientValuesFromPayer } from 'pages/InvoiceGeneratorWithTemplate/helpers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetAddressBooksQuery } from 'redux/endpoints/clinicianProfileServices/addressBook';
import { useGetAssignmentPackageQuery } from 'redux/endpoints/scheduleServices/package';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectInvoiceForm,
  selectSelectedClient,
  selectSelectedFunder,
  selectSelectedPackage,
  setInvoiceForm,
  setSelectedFunder,
  setSelectedPackage
} from 'redux/invoices/createInvoiceWithTemplateSlice';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import styles from './PayerSelection.module.scss';

interface PayerOption {
  funderId: string;
  name: string;
  assigneeId?: string;
}

const PayerSelection = ({ clientRecordId }: { clientRecordId: string }) => {
  const { accountId } = useGetAccountId();
  const { isAddressBookFeatureToggle } = useGetFeatureToggle();
  const dispatch = useAppDispatch();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isContactsExpanded, setIsContactsExpanded] = useState<boolean>(false);

  const menuNode = useRef<HTMLDivElement>(null);
  const contactListNode = useRef<HTMLDivElement>(null);

  const selectedFunder = useAppSelector(selectSelectedFunder);
  const selectedPackage = useAppSelector(selectSelectedPackage);
  const selectedClient = useAppSelector(selectSelectedClient);
  const invoiceForm = useAppSelector(selectInvoiceForm);

  const {
    data: assignmentPackageList,
    isLoading: isAssignmentPackageListLoading,
    isFetching: isAssignmentPackageListFetching
  } = useGetAssignmentPackageQuery(
    {
      accountId: accountId,
      clientRecordId
    },
    { skip: !clientRecordId }
  );

  const {
    data: contactData,
    isLoading: isContactDataLoading,
    isFetching: isContactDataFetching
  } = useGetAddressBooksQuery(
    {
      accountId,
      params: {
        roles: AddressBookRole.Funder,
        page: 1,
        perPage: 50
      }
    },
    { skip: !accountId || !isAddressBookFeatureToggle }
  );

  // Pre-bind payer for existed invoice
  useEffect(() => {
    if (
      invoiceForm.invoiceId &&
      !isAssignmentPackageListLoading &&
      !isAssignmentPackageListFetching &&
      assignmentPackageList &&
      invoiceForm.addressBook?._id
    ) {
      const foundPackage = assignmentPackageList.find(
        (item) => item.assignee.funder.funderId === invoiceForm.addressBook?._id
      );
      if (foundPackage) {
        dispatch(setSelectedPackage(foundPackage));
      }
    }
  }, [
    invoiceForm.invoiceId,
    isAssignmentPackageListLoading,
    isAssignmentPackageListFetching,
    assignmentPackageList,
    invoiceForm.addressBook,
    dispatch
  ]);

  const assignmentPackageOptions: PayerOption[] = useMemo(() => {
    if (!isAssignmentPackageListLoading && !isAssignmentPackageListFetching && assignmentPackageList) {
      return assignmentPackageList.map((item) => ({
        funderId: item.assignee.funder.funderId || '',
        name: `${item.name} - ${item.assignee.funder.name}`,
        assigneeId: item.assignee._id
      }));
    }
    return [];
  }, [isAssignmentPackageListLoading, isAssignmentPackageListFetching, assignmentPackageList]);

  const contactOptions: PayerOption[] = useMemo(() => {
    if (!isContactDataLoading && !isContactDataFetching && contactData?.addressBooks) {
      return contactData.addressBooks.map((item) => ({
        funderId: item._id,
        name: getAddressBookName(item)
      }));
    }
    return [];
  }, [isContactDataLoading, isContactDataFetching, contactData]);

  const closeAllMenu = () => {
    setIsExpanded(false);
    setIsContactsExpanded(false);
  };

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target) || contactListNode.current?.contains(event.target)) {
      return;
    }
    closeAllMenu();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const onSelectFunder = (funderId: string) => {
    dispatch(setSelectedPackage(undefined));
    closeAllMenu();
    const selectedFunderData = contactData?.addressBooks.find((item) => item._id === funderId);
    if (selectedFunderData) {
      dispatch(setSelectedFunder(selectedFunderData));

      const { line1, line2, suburb, state, postcode, country } = selectedFunderData.address || {};
      const addressStr = `${line1 ? `${line1} ` : ''}${line2 ? `${line2} ` : ''}${suburb ? `${suburb} ` : ''}${
        postcode ? `${postcode} ` : ''
      }${state ? `${state} ` : ''}${country ? `${country} ` : ''}`;
      const funderKeyContact = selectedFunderData.contacts.find((contact) => contact.isPrimary);
      dispatch(
        setInvoiceForm({
          recipients: [
            {
              name: getAddressBookName(selectedFunderData),
              address: addressStr,
              email: funderKeyContact?.email || '',
              mobileNumber: funderKeyContact?.mobileNumber || selectedFunderData?.organisation?.phone || '',
              reference: '',
              registrationNumber: selectedFunderData.code || ''
            }
          ]
        })
      );
    }
  };

  const onSelectPackage = (funderId: string, assigneeId?: string) => {
    dispatch(setSelectedFunder(undefined));
    closeAllMenu();
    const selectedPackageData = assignmentPackageList?.find((item) =>
      funderId
        ? item.assignee.funder.funderId === funderId && item.assignee._id === assigneeId
        : item.assignee._id === assigneeId
    );
    if (selectedPackageData) {
      dispatch(setSelectedPackage(selectedPackageData));
    }
    const recipientData = getRecipientValuesFromPayer({ selectedPackageData, contactData, selectedClient });
    dispatch(
      setInvoiceForm({
        recipients: recipientData
      })
    );
  };

  return (
    <div ref={menuNode} className={styles.container}>
      <div className={styles.controller}>
        <div>
          {selectedPackage
            ? `${selectedPackage.name} - ${selectedPackage.assignee.funder.name}`
            : selectedFunder
            ? getAddressBookName(selectedFunder)
            : 'Select Payer'}
        </div>
        <button
          className={classNames(styles.customButton, styles.changePayerButton)}
          onClick={() => {
            setIsContactsExpanded(false);
            setIsExpanded(!isExpanded);
          }}
        >
          Change Payer
        </button>
      </div>

      <div className={styles.payerListWrapper}>
        <div className={isExpanded ? styles.payerListShow : styles.payerList}>
          <div className={styles.label}>Packages</div>
          {isAssignmentPackageListLoading || isAssignmentPackageListFetching ? (
            [...Array(3)].map((_obj, i) => (
              <div key={i} className={styles.loadingBox}>
                <Skeleton.Input active className={styles.loading} />
              </div>
            ))
          ) : assignmentPackageOptions.length > 0 ? (
            <div className={styles.packages}>
              {assignmentPackageOptions.map((item, index) => (
                <button
                  className={classNames(styles.customButton, styles.payerItem)}
                  key={index}
                  onClick={() => onSelectPackage(item.funderId, item.assigneeId)}
                >
                  {item.name}
                </button>
              ))}
            </div>
          ) : (
            <div className={styles.noPackageFound}>No package found</div>
          )}
          <div ref={contactListNode} className={classNames(styles.contactContainer)}>
            <button
              disabled={!contactOptions.length}
              className={classNames(
                styles.customButton,
                styles.payerItem,
                styles.contactListControl,
                !contactOptions.length && styles.disabled
              )}
              onClick={() => {
                setIsContactsExpanded(!isContactsExpanded);
              }}
            >
              Contacts
              <i className={`material-icons`}>navigate_next</i>
            </button>
            <div className={styles.contactListWrapper}>
              <div className={isContactsExpanded ? styles.contactListShow : styles.contactList}>
                {isContactDataLoading || isContactDataFetching
                  ? [...Array(3)].map((_obj, i) => (
                      <div key={i} className={styles.loadingBox}>
                        <Skeleton.Input active className={styles.loading} />
                      </div>
                    ))
                  : contactOptions.map((item, index) => (
                      <button
                        className={classNames(styles.customButton, styles.payerItem)}
                        key={index}
                        onClick={() => onSelectFunder(item.funderId)}
                      >
                        {item.name}
                      </button>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayerSelection;
