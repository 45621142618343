import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getClaimSummary } from 'utils/http/BillingService/Invoice/claimingcom';

export interface ClaimCount {
  code: string;
  count: number;
}

export const useGetClaimSummary = ({
  token,
  clientRecordId,
  isClientMedicareSet,
  query
}: {
  token: string;
  clientRecordId: string;
  isClientMedicareSet: boolean;
  query: {
    year: string;
    individualCodes: string[];
    groupCodes: string[];
  };
}) => {
  const [individualClaimCounts, setIndividualClaimCounts] = useState<ClaimCount[]>(
    query.individualCodes.map((code) => ({ code, count: 0 }))
  );
  const [groupClaimCounts, setGroupClaimCounts] = useState<ClaimCount[]>(
    query.groupCodes.map((code) => ({ code, count: 0 }))
  );
  const [isClaimSummaryLoading, setIsClaimSummaryLoading] = useState(false);

  const fetchClaimSummary = useCallback(
    async (
      token: string,
      clientRecordId: string,
      query: {
        year: string;
        individualCodes: string[];
        groupCodes: string[];
      }
    ) => {
      setIsClaimSummaryLoading(true);

      try {
        const { individualCodes, groupCodes, year } = query;
        const codes = individualCodes.concat(groupCodes);
        const queryString = `codes=${codes.join(',')}&year=${year}`;
        const getClaimsResponse = await getClaimSummary(token, clientRecordId, queryString);
        const { summary } = (await getClaimsResponse.json()) as { summary: ClaimCount[] };

        const populatedIndividualCounts = individualCodes.map((code) => ({
          code,
          count: summary.find((item) => item.code === code)?.count ?? 0
        }));
        const populatedGroupCounts = groupCodes.map((code) => ({
          code,
          count: summary.find((item) => item.code === code)?.count ?? 0
        }));

        setIndividualClaimCounts(populatedIndividualCounts);
        setGroupClaimCounts(populatedGroupCounts);
      } catch (ex) {
        console.error(ex);
        notification.error({
          message: "Something went wrong while trying to fetch your client's claims summary"
        });
      }
      setIsClaimSummaryLoading(false);
    },
    []
  );

  const refetchClaimSummary = () => {
    if (token && isClientMedicareSet) {
      fetchClaimSummary(token, clientRecordId, query);
    }
  };

  useEffect(() => {
    if (token && isClientMedicareSet) {
      fetchClaimSummary(token, clientRecordId, query);
    }
  }, [token, isClientMedicareSet, query, clientRecordId, fetchClaimSummary]);

  return { individualClaimCounts, groupClaimCounts, isClaimSummaryLoading, refetchClaimSummary };
};
