import { useCallback, useEffect, useState } from 'react';
import { getOpenDataCategories } from 'utils/http/CheckInService/OpenDataField/openDataField';
import { ClassificationTypes, OpenDataCategory } from '../CollectData.interface';
import { v4 as uuid } from 'uuid';

export const useFetchOpenDataCategories = (token: string, accountId: string, classification?: ClassificationTypes) => {
  const [isOpenDataCategoriesLoading, setIsOpenDataCategoriesLoading] = useState(false);
  const [openDataCategories, setOpenDataCategories] = useState<OpenDataCategory[]>([]);
  const fetchOpenDataCategories = useCallback(async () => {
    if (token && accountId) {
      setIsOpenDataCategoriesLoading(true);
      try {
        const res = await (await getOpenDataCategories(token, accountId, classification)).json();
        setOpenDataCategories(
          res.map((item: OpenDataCategory) => ({
            ...item,
            questions: item.questions?.map((i) => ({ ...i, id: uuid() })) || []
          }))
        );
        setIsOpenDataCategoriesLoading(false);
      } catch (er) {
        console.error(er);
        setIsOpenDataCategoriesLoading(false);
      }
    }
  }, [token, accountId, classification]);

  useEffect(() => {
    fetchOpenDataCategories();
  }, [fetchOpenDataCategories]);

  return { openDataCategories, isOpenDataCategoriesLoading, fetchOpenDataCategories, setOpenDataCategories };
};
