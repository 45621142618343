import { useEffect, useRef, useState } from 'react';
import styles from './InvoicePaymentsActionMenu.module.scss';
import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';
import classNames from 'classnames';
import { useCurrency } from 'utils/hooks/useCurrency';
import { InvoicePayment } from 'pages/InvoicePayments/constants';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { InvoicePaymentsTab, selectActiveTab } from 'redux/features/invoicePayments/invoicePaymentsSlice';
import { useAppSelector } from 'redux/hooks';

interface InvoicePaymentsActionMenuProps {
  payment?: InvoicePayment;
  onViewClient: () => void;
  onPartialRefund?: () => void;
  onFullRefund?: () => void;
}

const InvoicePaymentsActionMenu = ({
  payment,
  onViewClient,
  onPartialRefund,
  onFullRefund
}: InvoicePaymentsActionMenuProps) => {
  const { formatCurrency } = useCurrency();
  const activeTab = useAppSelector(selectActiveTab);

  const menuNode = useRef<HTMLDivElement>(null);
  const refundSubMenu = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [refundExpand, setRefundExpand] = useState(false);

  const { payments } = payment || {};

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target) || refundSubMenu.current?.contains(event.target)) {
      return;
    }
    handleCloseMenu();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const handleExpandMenu = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCloseMenu = () => {
    setRefundExpand(false);
    setIsExpanded(false);
  };

  const handleExpandSubMenu = () => {
    return setRefundExpand(!refundExpand);
  };

  const handlePartialRefund = () => {
    handleCloseMenu();
    onPartialRefund && onPartialRefund();
  };

  const handleFullRefund = () => {
    handleCloseMenu();
    onFullRefund && onFullRefund();
  };

  const handleViewClient = () => {
    handleCloseMenu();
    onViewClient();
  };

  return (
    <div ref={menuNode} className={styles.container}>
      <ButtonAlt size="medium" variant="text" fab icon="more_vert" onClick={handleExpandMenu} />
      <div className={styles.menuWrapper}>
        <div className={isExpanded ? styles.menuShow : styles.menuHide}>
          {/* View Invoice */}
          <div className={styles.menuItem}>
            <ThreeDotMenuItem label="View Client" postIcon="open_in_new" onClick={handleViewClient} />
          </div>

          {activeTab === InvoicePaymentsTab.Collected &&
            payments &&
            (payments.paid === 0 ? (
              <ThreeDotMenuItem
                className={styles.refundedLabel}
                label={`${formatCurrency(payments.refunded)} Refunded`}
                onClick={() => {}}
              />
            ) : (
              <div className={styles.menuItem}>
                <ThreeDotMenuItem
                  labelClassName={styles.menuItemLabel}
                  label="Refund"
                  postIcon="navigate_next"
                  onClick={handleExpandSubMenu}
                />
                {/* Refund sub menu */}
                <div
                  ref={refundSubMenu}
                  className={classNames(styles.wideSubMenuShow, refundExpand ? styles.subMenuShow : styles.subMenuHide)}
                >
                  <ThreeDotMenuItem
                    label="Full Refund:"
                    labelClassName={styles.menuItemLabel}
                    postComponent={<span className={styles.postLabel}>{formatCurrency(payments.paid)}</span>}
                    onClick={handleFullRefund}
                  />
                  <ThreeDotMenuItem
                    label="Partial Refund"
                    labelClassName={styles.menuItemLabel}
                    onClick={handlePartialRefund}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default InvoicePaymentsActionMenu;
