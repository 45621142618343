import { useState } from 'react';
import { Modal } from 'antd';
import { toPng } from 'html-to-image';

import { MicroJournalEntry } from 'utils/hooks/microJournal';

import JournalDetailList from './components/JournalDetailList/JournalDetailList';

import styles from './AddJournalDetailModal.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface AddJournalDetailModalProps {
  recordId: string;
  profileId: string;
  visible: boolean;
  onCancel: (newJournalDetailImages?: string) => void;
}

const AddJournalDetailModal = ({ recordId, profileId, visible, onCancel }: AddJournalDetailModalProps) => {
  const [selectedJournalDetails, setSelectedJournalDetails] = useState<MicroJournalEntry[]>([]);

  const [addButtonStatus, setAddButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const handleSelectJournalDetail = (journalDetail: MicroJournalEntry) => {
    if (selectedJournalDetails.map((journalDetail) => journalDetail._id).includes(journalDetail._id)) {
      setSelectedJournalDetails(
        selectedJournalDetails.filter((selectedJournalDetail) => selectedJournalDetail._id !== journalDetail._id)
      );
    } else {
      setSelectedJournalDetails([...selectedJournalDetails, journalDetail]);
    }
  };

  const handleAddJournalDetailClick = async () => {
    setAddButtonStatus('active');

    let newJournalDetailImages = '<div>';

    for (let i = 0; i < selectedJournalDetails.length; i++) {
      const element = document.getElementById(`add-journal-detail-card-${selectedJournalDetails[i]._id}`);

      if (element) {
        try {
          const dataUrl = await toPng(element);

          newJournalDetailImages += `<img src="${dataUrl}" width="200"><br><br>`;
        } catch (ex) {
          console.error(ex);
        }
      }
    }

    newJournalDetailImages += '</div>';

    setAddButtonStatus('finished');
    onCancel(newJournalDetailImages);

    setTimeout(() => {
      setAddButtonStatus('');
      setSelectedJournalDetails([]);
    }, 500);
  };

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      width={768}
      footer={null}
      open={visible}
      onCancel={() => onCancel()}
      destroyOnClose
    >
      <div className={styles.header}>Add Journal Detail</div>
      <div className={styles.content}>
        <div className={styles.description}>Select the journal details to embed this note</div>
        <JournalDetailList
          recordId={recordId}
          profileId={profileId}
          selectedJournalDetails={selectedJournalDetails}
          onSelectJournalDetail={handleSelectJournalDetail}
        />
        <div className={styles.buttonContainer}>
          <ButtonAlt status={addButtonStatus} onClick={handleAddJournalDetailClick}>
            Add Journal Details to this note
          </ButtonAlt>
        </div>
      </div>
    </Modal>
  );
};

export default AddJournalDetailModal;
