import styles from './SessionAvailableDate.module.scss';
import { DayTimeSelection, ScheduleOption } from 'interfaces/Schedule/AppointmentType';
import WeekdayPills from 'components/v2/WeekdayPills/WeekdayPills';
import { useMemo } from 'react';

type SessionAvailableDateProps = {
  dayTimeSelections: DayTimeSelection;
};

const SessionAvailableDate = ({ dayTimeSelections }: SessionAvailableDateProps) => {
  const weekDayAvailability = useMemo((): [boolean, boolean, boolean, boolean, boolean, boolean, boolean] => {
    const { kind, autoFit, fixedTime } = dayTimeSelections;
    const selection = kind === ScheduleOption.AutoFit ? autoFit : fixedTime;
    return [
      !!selection?.Monday?.isActive,
      !!selection?.Tuesday?.isActive,
      !!selection?.Wednesday?.isActive,
      !!selection?.Thursday?.isActive,
      !!selection?.Friday?.isActive,
      !!selection?.Saturday?.isActive,
      !!selection?.Sunday?.isActive
    ];
  }, [dayTimeSelections]);

  return (
    <div className={styles.container}>
      <div className={styles.label}>AVAILABLE</div>
      <WeekdayPills weekdays={weekDayAvailability} />
    </div>
  );
};

export default SessionAvailableDate;
