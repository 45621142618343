import { useContext } from 'react';
import { UserContext } from 'utils/UserContextProvider';
import { defaultTimezone } from 'utils/hooks/GetTimezones/getTimezones';
import { useGetAccountPackageView } from './GetAccountInfo/accountPackageView';
import { useGetAccountSettings } from './GetAccountSettings/getAccountSettings';

export const useTimeZone = () => {
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { clinicianProfile } = useContext(UserContext);
  const { accountSettings } = useGetAccountSettings();

  const timeZoneByView =
    isEdgeAdminView || isEdgeReceptionistView
      ? accountSettings?.timezone || defaultTimezone
      : clinicianProfile?.workTimeZone || accountSettings?.timezone || defaultTimezone;

  return {
    accountTimeZone: accountSettings?.timezone || defaultTimezone,
    clinicianWorkTimeZone: clinicianProfile?.workTimeZone || defaultTimezone,
    timeZoneByView: timeZoneByView || defaultTimezone
  };
};
