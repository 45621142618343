import { billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';
import queryString from 'query-string';
import { Location } from 'pages/ControlPanel/Interfaces/Claimingcom';

interface GetLocationsQueryParams {
  active?: boolean;
}

export const locationApiSlice = billingServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query<{ locations: Location[] }, GetLocationsQueryParams>({
      query: (params) => {
        const stringifiedQuery = params ? queryString.stringify(params) : '';
        return {
          url: `accounts/me/claimingcom/locations?${stringifiedQuery}`
        };
      }
    })
  })
});

export const { useGetLocationsQuery } = locationApiSlice;
