import { ProfileInterface } from 'interfaces/Profile/Profile';
import moment from 'moment';
import * as Yup from 'yup';
import { WorkingScheduleInterface } from './interfaces';

export const TIME_FORMAT_WITHOUT_ASIDE = 'HH:mm';
export const TIME_FORMAT_WITH_ASIDE = 'hh:mm a';

const TIME_FORMAT = 'HH:mm A';
const DEFAULT_TIME_SLOT = { startTime: '08:00 AM', endTime: '06:00 PM' };

export const initFormValue = (profile: ProfileInterface) => {
  return {
    ...profile,
    availability: {
      monday: {
        isAvailable: profile.workingSchedule.monday.isActive,
        timeSlots: profile.workingSchedule.monday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.monday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      tuesday: {
        isAvailable: profile.workingSchedule.tuesday.isActive,
        timeSlots: profile.workingSchedule.tuesday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.tuesday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      wednesday: {
        isAvailable: profile.workingSchedule.wednesday.isActive,
        timeSlots: profile.workingSchedule.wednesday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.wednesday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      thursday: {
        isAvailable: profile.workingSchedule.thursday.isActive,
        timeSlots: profile.workingSchedule.thursday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.thursday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      friday: {
        isAvailable: profile.workingSchedule.friday.isActive,
        timeSlots: profile.workingSchedule.friday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.friday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      saturday: {
        isAvailable: profile.workingSchedule.saturday.isActive,
        timeSlots: profile.workingSchedule.saturday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.saturday.timeSlots
          : [DEFAULT_TIME_SLOT]
      },
      sunday: {
        isAvailable: profile.workingSchedule.sunday.isActive,
        timeSlots: profile.workingSchedule.sunday.timeSlots?.[0]?.startTime
          ? profile.workingSchedule.sunday.timeSlots
          : [DEFAULT_TIME_SLOT]
      }
    }
  } as WorkingScheduleInterface;
};

const timeSlotSchema = Yup.object({
  startTime: Yup.string().required(),
  endTime: Yup.string().required()
}).test('validTimeSequence', 'End time must later then start time', (value) => {
  return moment(value?.startTime, TIME_FORMAT).diff(moment(value?.endTime, TIME_FORMAT)) < 0;
});

const availableTimeSchema = Yup.object({
  isAvailable: Yup.boolean(),
  timeSlots: Yup.array(timeSlotSchema)
    .when('isAvailable', {
      is: true,
      then: Yup.array(timeSlotSchema)
        .min(1, 'Please select at least one timeslot')
        .required('Please select at least one timeslot'),
      otherwise: Yup.array(timeSlotSchema)
    })
    .test('noOverlapTime', 'Time slots overlapped', (value) => {
      let result = [];
      if (value) {
        for (let a = 0; a < value.length; a++) {
          const startTimeA = moment(value[a]?.startTime, TIME_FORMAT);
          const endTimeA = moment(value[a]?.endTime, TIME_FORMAT);
          result[a] = true;
          for (let b = 0; b < value.length; b++) {
            const startTimeB = moment(value[b]?.startTime, TIME_FORMAT);
            const endTimeB = moment(value[b]?.endTime, TIME_FORMAT);
            if (a !== b) {
              //if not same index
              if (
                (startTimeA.diff(startTimeB) > 0 && startTimeA.diff(endTimeB) < 0) ||
                (endTimeA.diff(startTimeB) > 0 && endTimeA.diff(endTimeB) < 0) ||
                startTimeA.isSame(startTimeB) ||
                endTimeA.isSame(endTimeB)
              ) {
                return false;
              }
            }
          }
        }
      }
      return true;
    })
});

export const workingScheduleSchema = Yup.object({
  availability: Yup.object({
    monday: availableTimeSchema,
    tuesday: availableTimeSchema,
    wednesday: availableTimeSchema,
    thursday: availableTimeSchema,
    friday: availableTimeSchema,
    saturday: availableTimeSchema,
    sunday: availableTimeSchema
  })
});
