import CheckBox from 'components/CheckBox/CheckBox';
import { PaymentMethod } from 'pages/Invoices/interface';
import { PAYMENT_TYPE_LABELS } from 'pages/Invoices/components/PaymentMethods/PaymentMethods';
import styles from './SelectPaymentMethods.module.scss';

interface SelectPaymentMethodsProps {
  checked: boolean;
  item: PaymentMethod;
  onSelectPaymentMethod: (id: string) => void;
}

const SelectPaymentMethods = ({
  checked,
  item: { paymentType, _id },
  onSelectPaymentMethod
}: SelectPaymentMethodsProps) => {
  return (
    <CheckBox
      id={`${paymentType}_${_id}`}
      className={styles.mediumCheckBoxLabel}
      inputClassName={styles.checkBoxInput}
      value={checked}
      label={PAYMENT_TYPE_LABELS[paymentType]}
      onChange={(e) => onSelectPaymentMethod(e.target.id)}
    />
  );
};

export default SelectPaymentMethods;
