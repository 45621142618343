import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { GroupMemberList, GroupMemberListFilterParameter } from 'pages/Groups/Interfaces/Groups';
import { getGroupMembersByGroupId } from 'utils/http/ClinicianProfileService/Groups/GroupMembers';
import queryString from 'query-string';

export const useFetchGroupMemberList = (
  token: string,
  groupId: string,
  filterValue: GroupMemberListFilterParameter,
  refreshList?: number,
  selectedStages?: string[]
) => {
  const [groupMembers, setGroupMembers] = useState<GroupMemberList>({
    clientRecords: [],
    count: {
      active: 0,
      completed: 0,
      archived: 0,
      all: 0
    },
    paging: {
      page: 1,
      perPage: 20,
      totalItem: 0
    }
  });
  const [isGroupMembersLoading, setIsGroupMembersLoading] = useState(true);

  const fetchGroupMemberList = useCallback(
    async (token: string, filterValue: GroupMemberListFilterParameter) => {
      try {
        setIsGroupMembersLoading(true);
        const massageParam = {
          ...filterValue,
          ...(filterValue?.status === 'all' && {
            status: undefined
          }),
          ...(selectedStages?.length && {
            stageIds: selectedStages.join(',')
          })
        };
        const qParam = queryString.stringify(massageParam || {});

        const getGroupMemberData = await getGroupMembersByGroupId(token, groupId, qParam);
        const groupMemberListData: GroupMemberList = await getGroupMemberData.json();
        const sumCount = Object.values(groupMemberListData.count).reduce((a: number, b: number) => a + b) ?? 0;

        const massageRes = {
          ...groupMemberListData,
          count: {
            ...groupMemberListData.count,
            all: sumCount
          }
        };

        setGroupMembers(massageRes);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get your groups member list' });
      }
      setIsGroupMembersLoading(false);
    },
    [groupId, selectedStages]
  );

  useEffect(() => {
    if (token) {
      fetchGroupMemberList(token, filterValue);
    }
  }, [fetchGroupMemberList, token, filterValue, refreshList]);

  return { groupMembers, isGroupMembersLoading, fetchGroupMemberList };
};
