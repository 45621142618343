import classNames from 'classnames';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styles from './FolderDropdown.module.scss';

const privateFolderLabel = 'Private Folder';
const sharedFolderLabel = 'Shared Folder';

interface FolderDropdownProps {
  isPrivateInput: boolean;
  onChange: (val: boolean) => void;
  setIsPrivateInput: Dispatch<SetStateAction<boolean>>;
}

const FolderDropdown = ({ isPrivateInput, onChange, setIsPrivateInput }: FolderDropdownProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (ref.current?.contains(e.target as Node)) {
      return;
    }

    setIsVisible(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleClickOption = (value: boolean) => {
    setIsPrivateInput(value);
    onChange(value);
    setIsVisible(false);
  };

  return (
    <div className={styles.container} ref={ref}>
      <button className={styles.button} onClick={() => setIsVisible(!isVisible)}>
        {isPrivateInput ? privateFolderLabel : sharedFolderLabel}
        <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
      </button>
      <div className={classNames(styles.menu, !isVisible && styles.hidden)}>
        <div
          className={classNames(styles.option, isPrivateInput && styles.selected)}
          onClick={() => handleClickOption(true)}
        >
          {privateFolderLabel}
        </div>
        <div
          className={classNames(styles.option, !isPrivateInput && styles.selected)}
          onClick={() => handleClickOption(false)}
        >
          {sharedFolderLabel}
        </div>
      </div>
    </div>
  );
};

export default FolderDropdown;
