import { notification, Skeleton } from 'antd';
import classnames from 'classnames';
import moment from 'moment';
import { Dispatch, SetStateAction, useRef } from 'react';
import { useParams } from 'react-router-dom';

import SearchBarT23 from 'components/SearchBarT23/SearchBarT23';
import ThreeDotMenu, { ThreeDotMenuHandle } from 'components/T23/ThreeDotMenu/ThreeDotMenu';
import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { CaseNote } from 'interfaces/caseNote';
import { useTogglePrivacyMutation } from 'redux/endpoints/documentServices/caseNote';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import styles from './PatientDetailsNotesListing.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface PatientDetailsNotesListingProps {
  addNoteButtonStatus: ButtonStatusType;
  handleRedirect: (caseNoteId: string) => void;
  isAddNoteButtonDisabled: boolean;
  isAddNoteButtonVisible: boolean;
  isDeleting: boolean;
  isEditing: boolean;
  isPrivateFolder: boolean;
  noteId?: string;
  notes: CaseNote[];
  onClickAddNote: () => void;
  onClickDeleteNote: (arg?: { noteId?: string; noteTitle?: string }) => void;
  onClickEditNote: (noteId: string) => void;
  recordId: string;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  listingLoading?: boolean;
}

const PatientDetailsNotesListing = ({
  addNoteButtonStatus,
  handleRedirect,
  isAddNoteButtonDisabled,
  isAddNoteButtonVisible,
  isDeleting,
  isEditing,
  isPrivateFolder,
  noteId,
  notes,
  onClickAddNote,
  onClickDeleteNote,
  onClickEditNote,
  recordId,
  searchValue,
  setSearchValue,
  listingLoading
}: PatientDetailsNotesListingProps) => {
  const menuRef = useRef<ThreeDotMenuHandle>(null);

  const path = useParams() as { assessmentOrReportId: string };
  const { accountId } = useGetAccountId();
  const { auth0ClinicianId: loggedInUserId } = useGetClinicianId();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { isHidePrivateCaseNotes } = useGetFeatureToggle();

  const [togglePrivacy, { isLoading: isTogglingPrivacy, error: togglePrivacyError }] = useTogglePrivacyMutation();

  const togglePrivacyClickHandlerGenerator = (caseNoteId: string) => async () => {
    await togglePrivacy({ accountId, caseNoteId, clientRecordId: recordId });

    if (togglePrivacyError) {
      console.error('Failed to move case note:', togglePrivacyError);
      notification.error({ message: 'Failed to move case note.' });
      return;
    }

    menuRef.current?.closeMenu();
    notification.success({ message: 'Case note moved successfully.' });
  };

  const noteClickHandlerGenerator = (newSelectedNoteId: string) => () => {
    const currentNoteId = path.assessmentOrReportId;

    if (!isEditing && newSelectedNoteId !== currentNoteId) {
      handleRedirect(newSelectedNoteId);
    }
  };

  const handleEditClick = (noteId: string) => {
    onClickEditNote(noteId);
    menuRef.current?.closeMenu();
  };

  const handleDeleteClick = ({ noteId, noteTitle }: { noteId?: string; noteTitle?: string }) => {
    onClickDeleteNote({ noteId, noteTitle });
    menuRef.current?.closeMenu();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {isAddNoteButtonVisible && (
          <ButtonAlt
            className={styles.addNoteButton}
            disabled={isAddNoteButtonDisabled}
            icon="add_circle_outline"
            onClick={onClickAddNote}
            status={addNoteButtonStatus}
          >
            NEW NOTE
          </ButtonAlt>
        )}
        {isEditing && (
          <div className={styles.warningMessage}>Save or close the current note editing to create a new one.</div>
        )}
        <SearchBarT23
          containerClassName={styles.search}
          hideLabel
          onSearch={setSearchValue}
          placeholder="Search notes"
          value={searchValue}
        />
      </div>
      <div className={styles.body}>
        {listingLoading ? (
          <div className={styles.listingLoadingWrapper}>
            {[...Array(3)].map((obj, i) => (
              <div key={i} className={styles.loadingItem}>
                <Skeleton.Input active className={styles.loadingContent1} />
                <Skeleton.Input active className={styles.loadingContent2} />
                <Skeleton.Input active className={styles.loadingContent3} />
              </div>
            ))}
          </div>
        ) : !listingLoading && notes.length > 0 ? (
          notes.map(
            ({
              _id,
              attachments,
              clinicianId: caseNoteClinicianId,
              clinicianName,
              safeguardingAlert,
              title,
              updatedAt
            }) => {
              const isAuthor = loggedInUserId === caseNoteClinicianId;
              const isLocked = isPrivateFolder && !isAuthor;
              const isSelected = _id === noteId;
              const lockedStyles = isLocked ? styles.locked : null;

              return (
                <div
                  className={classnames(
                    styles.noteItem,
                    isSelected && styles.selected,
                    isEditing && styles.disabled,
                    !safeguardingAlert && styles.alignIconCenter
                  )}
                  key={_id}
                  onClick={noteClickHandlerGenerator(_id)}
                >
                  <div className={styles.mainSection}>
                    <div className={classnames(styles.noteTitle, lockedStyles)}>{title || 'New Note'}</div>
                    <div className={styles.noteContent}>
                      <div className={classnames(styles.noteDate, lockedStyles)}>
                        {moment(updatedAt).format('DD/MM/YYYY hh:mm A')}
                      </div>
                      {attachments.length > 0 && (
                        <div className={styles.noteAttachmentsCount}>
                          <i className={`material-icons-outlined ${styles.icon}`}>attach_file</i>
                          {attachments.length}
                        </div>
                      )}
                    </div>
                    {clinicianName && (
                      <div className={classnames(styles.authorName, lockedStyles)}>{clinicianName}</div>
                    )}
                  </div>
                  <div className={classnames(styles.iconContainer, isAuthor && styles.adjustAlignment)}>
                    {isAuthor ? (
                      <ThreeDotMenu isMenuDisabled={isEditing || isTogglingPrivacy} ref={menuRef}>
                        {!isEdgeAdminView && !isHidePrivateCaseNotes && (
                          <ThreeDotMenuItem
                            isLoading={isTogglingPrivacy}
                            label={isPrivateFolder ? 'Move to shared folder' : 'Move to private folder'}
                            onClick={togglePrivacyClickHandlerGenerator(_id)}
                          />
                        )}
                        <ThreeDotMenuItem label="Edit" onClick={() => handleEditClick(_id)} />
                        <ThreeDotMenuItem
                          isLoading={isDeleting}
                          label="Delete"
                          onClick={() => handleDeleteClick({ noteId: _id, noteTitle: title })}
                        />
                      </ThreeDotMenu>
                    ) : (
                      isLocked && (
                        <div className={classnames('material-icons-outlined', styles.lockIcon, lockedStyles)}>lock</div>
                      )
                    )}
                    {safeguardingAlert && (
                      <div className={styles.safeguardingAlert}>
                        <i className={classnames('material-icons', styles.safeguardingAlertIcon, lockedStyles)}>
                          report_problem
                        </i>
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          )
        ) : (
          searchValue !== '' && <div className={styles.noValue}>No case note match {searchValue}</div>
        )}
      </div>
    </div>
  );
};

export default PatientDetailsNotesListing;
