import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import appRoute from 'components/AppRoute/AppRoute';

import Auth0ProviderWithHistory from 'components/Auth/Auth0ProviderWithHistory';

import NoAuthLayout from 'layouts/NoAuthLayout/NoAuthLayout';
import ContentDeliveryLayout from 'layouts/ContentDeliveryLayout/ContentDeliveryLayout';

import Forbidden403 from 'pages/403/Forbidden403';
import NotFound404 from 'pages/404/NotFound404';
import PracticeSignUp from 'pages/SignUp/PracticeSignUp/PracticeSignUp';
import ClinicianSignUp from 'pages/SignUp/ClinicianSignUp/ClinicianSignUp';
import Login from 'pages/Login/Login';
import Unverified from 'pages/Unverified/Unverified';
import ContentDeliveryIntroduction from 'pages/ContentDelivery/ContentDeliveryIntroduction/ContentDeliveryIntroduction';
import ContentDeliveryPage from 'pages/ContentDelivery/ContentDeliveryPage/ContentDeliveryPage';
import AuthLayoutT23 from 'layouts/AuthLayoutT23/AuthLayoutT23';
import SubTabContextProvider from 'layouts/AuthLayoutT23/context/SubTabContextProvider';
import AuthRoutesT23 from 'Routes/AuthRoutesT23';
import SetPassword from 'pages/SetPassword/SetPassword';
import SetPasswordLayout from 'layouts/SetPasswordLayout/SetPasswordLayout';
import Logout from 'pages/Logout/Logout';
import ProCarePendingSetup from 'ProCare/ProCarePendingSetup/ProCarePendingSetup';
import ProCareAdSignUpCompletion from 'ProCare/ProCareAdSignUpCompletion/ProCareAdSignUpCompletion';

const App = () => (
  <Router>
    <Auth0ProviderWithHistory>
      <SubTabContextProvider>
        <Routes>
          {appRoute({ path: '/403', component: Forbidden403, layout: NoAuthLayout })}
          {appRoute({ path: '/404', component: NotFound404, layout: NoAuthLayout })}
          {appRoute({ path: '/signup', component: ClinicianSignUp, layout: NoAuthLayout })}
          {appRoute({ path: '/:practiceSlugUrl/signup', component: PracticeSignUp, layout: NoAuthLayout })}
          {appRoute({ path: '/', component: Login, layout: NoAuthLayout })}
          {appRoute({ path: '/login', component: Login, layout: NoAuthLayout })}
          {appRoute({ path: '/logout', component: Logout, layout: NoAuthLayout })}
          {appRoute({ path: '/unverified', component: Unverified, layout: NoAuthLayout })}
          {appRoute({ path: '/signup/claim', component: ProCareAdSignUpCompletion, layout: NoAuthLayout })}
          {appRoute({ path: '/pending-setup', component: ProCarePendingSetup, layout: NoAuthLayout })}
          {appRoute({
            path: '/set-password',
            component: SetPassword,
            layout: SetPasswordLayout
          })}
          {appRoute({
            path: `/contentDelivery/:contentDeliveryId`,
            component: ContentDeliveryIntroduction,
            layout: ContentDeliveryLayout
          })}
          {appRoute({
            path: `/contentDelivery/:contentDeliveryId/chapters/:chapterNumber/pages/:pageNumber`,
            component: ContentDeliveryPage,
            layout: ContentDeliveryLayout
          })}
          <Route element={<AuthLayoutT23 />}>
            <Route path="*" element={<AuthRoutesT23 />} />
          </Route>
        </Routes>
      </SubTabContextProvider>
    </Auth0ProviderWithHistory>
  </Router>
);

export default App;
