import { Modal, Skeleton, notification } from 'antd';
import OptionLabel from 'components/OptionLabel/OptionLabel';
import Radio from 'components/Radio/Radio';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { TabIds } from 'pages/Client/Client';
import { getClientRecordRequestPayload } from 'pages/Client/components/ClientListContent/components/ClientList/constants';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { selectSetting } from 'redux/clients/clientListColumnsSettingsSlice';
import { selectFilters, selectPaging, selectSearch, selectSorting } from 'redux/clients/clientListSlice';
import { useGetClientRecordsListQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useAppSelector } from 'redux/hooks';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

import { useGetClinicianId } from '../../utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccessToken } from '../../utils/hooks/token';
import { scrollToView } from '../../utils/scrollToView';
import styles from './AddPatientModalV2.module.scss';
import { newClientForm } from './AddPatientModalV2Interface';
import { CapabilityFormV2 } from './components/CapabilityForm/CapabilityFormV2';
import ModalHeader from './components/ModalHeader/ModalHeader';
import OnboardingTriageStep from './components/OnboardingTriageStep/OnboardingTriageStep';
import ProfileTypeForm from './components/ProfileTypeForm/ProfileTypeForm';
import AdultForm from './formType/AdultForm/AdultForm';
import ChildForm from './formType/ChildForm/ChildForm';
import CoupleForm from './formType/CoupleForm/CoupleForm';
import { YoungForm } from './formType/YoungForm/YoungForm';
import { postNewClientData } from './hooks/PostNewClient';
import { usePrefillNewClientForm } from './hooks/PrefillValue';
import { useGetInitValueByType } from './InitValue/initValue';
import {
  checkIsDuplicateCaseId,
  validateAdultForm,
  validateChildForm,
  validateCoupleForm,
  validateYoungForm
} from './validation/SubmitFormValidation';
import { TIMEZONE_OPTIONS } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/ProfileWorkingSchedule/ProfileWorkingSchedule';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface AddPatientModalV2Props {
  onCancel?: (isUpdated: boolean) => void;
  onResetForm?: () => void;
  onComplete?: (clientRecord: clientRecordsInterface) => void;
  visible: boolean;
  information?: ReactNode;
  initialPrefill?: Partial<newClientForm>;
  getClientPrefill?: (recordType: newClientForm['recordType']) => void;
}

const AddPatientModalV2 = ({
  onResetForm = () => {},
  visible,
  onCancel,
  onComplete,
  information,
  initialPrefill,
  getClientPrefill,
  ...props
}: AddPatientModalV2Props) => {
  const { token } = useGetAccessToken();
  const { isEdgeReceptionist, isEdgeAdminView, isNormalUserView, isEdgeUserView } = useGetAccountPackageView();
  const { auth0ClinicianId } = useGetClinicianId();
  const [selectedClinicianId, setSelectedClinicianId] = useState(
    isEdgeReceptionist || isEdgeAdminView ? '' : auth0ClinicianId
  );
  const { accountTimeZone } = useTimeZone();
  const { isClientTimeZoneEnabled } = useGetFeatureToggle();

  const [timeZone, setTimeZone] = useState<string>(accountTimeZone);

  const [t] = useTranslation();

  const { AdultInit, YoungInit, ChildInit, CoupleInit } = useGetInitValueByType(selectedClinicianId, initialPrefill);

  const { accountId } = useGetAccountId();
  const { tabId } = useParams<{ [key in keyof TabIds]: string }>();

  const columnSetting = useAppSelector(selectSetting);
  const search = useAppSelector(selectSearch);
  const paging = useAppSelector(selectPaging);
  const sorting = useAppSelector(selectSorting);
  const filters = useAppSelector(selectFilters);

  const { refetch } = useGetClientRecordsListQuery(
    {
      accountId,
      payload: {
        ...getClientRecordRequestPayload({ tabId, paging, sorting, filters, isEdgeUserView, columnSetting, search })
      }
    },
    { skip: !columnSetting }
  );

  useEffect(() => {
    setSelectedClinicianId(isEdgeReceptionist || isEdgeAdminView ? '' : auth0ClinicianId);
  }, [isEdgeAdminView, auth0ClinicianId, isEdgeReceptionist]);

  const {
    clientFormData,
    setClientFormData,
    onBoardingList,
    defaultOnBoardingId,
    consentList,
    defaultConsentId,
    prefillLoading,
    fetchPrefillClientData,
    assessmentList,
    defaultAssessmentId
  } = usePrefillNewClientForm(token, AdultInit);

  const [page1SubmitStatus, setPage1SubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [page2SubmitStatus, setPage2SubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const [pageStep, setPageStep] = useState(1);
  const [formCompleted, setFormCompleted] = useState(false);

  const [isSubmitPage1, setIsSubmitPage1] = useState(false);

  const [clientRecords, setClientRecords] = useState({} as clientRecordsInterface);

  const handleBackStepBtn = () => {
    setPageStep(1);
  };

  const handleChangeProfileType = async (value: newClientForm['recordType']) => {
    const prefill = getClientPrefill?.(value) || undefined;

    let initData;
    switch (value) {
      case 'adult':
        initData = AdultInit;
        break;

      case 'youngPerson':
        initData = YoungInit;
        break;

      case 'child':
        initData = ChildInit;
        break;

      default:
        initData = CoupleInit;
        break;
    }
    await fetchPrefillClientData(initData, prefill);
  };

  const handleChangeReferral = (value: string[]) => {
    setClientFormData({
      ...clientFormData,
      referralSourceTags: value
    });
  };

  const handleChangeReferralDetail = (value: string) => {
    setClientFormData({
      ...clientFormData,
      referralDetail: value
    });
  };

  const handleChangeCaseId = (value: newClientForm['caseId']) => {
    setClientFormData({
      ...clientFormData,
      caseId: value
    });
  };

  const handleChangeClientDetail = (val: newClientForm['clientProfiles']) => {
    setClientFormData({
      ...clientFormData,
      clientProfiles: val
    });
  };

  const handleClientRecordStatus = (val: newClientForm['registeredAs']) => {
    const profileType = val === 'waitlist' || clientFormData.recordType === 'child' ? 'recordOnly' : 'full';
    const clientProfiles = clientFormData.clientProfiles.map((client, index) => {
      return {
        ...client,
        onboarding: {
          ...client.onboarding,
          onboardingMethod: profileType !== 'full' ? 'facilitated' : client.onboarding.onboardingMethod
        },
        // for young person profile, `isPrimaryContact` is depending on `profileType`, if `full`, then true
        ...(clientFormData.recordType === 'youngPerson' && index === 0 && { isPrimaryContact: profileType === 'full' })
      };
    });

    setClientFormData({
      ...clientFormData,
      registeredAs: val,
      clientCapability: {
        profileType
      },
      clientProfiles: clientProfiles
    });
  };

  const handleChangeCapability = (val: newClientForm['clientCapability']) => {
    const clientProfiles = clientFormData.clientProfiles.map((client, idx) => {
      return {
        ...client,
        onboarding: {
          ...client.onboarding,
          onboardingMethod: val.profileType !== 'full' ? 'facilitated' : client.onboarding.onboardingMethod
        },
        // for young person profile, `isPrimaryContact` is depends on `profileType`, if `full`, then true
        ...(clientFormData.recordType === 'youngPerson' &&
          idx === 0 && {
            isPrimaryContact: val.profileType === 'full'
          })
      };
    });
    setClientFormData({
      ...clientFormData,
      clientCapability: val,
      clientProfiles: clientProfiles
    });
  };

  const handleChangeSelectedClinician = (val: string) => {
    setSelectedClinicianId(val);
    setClientFormData({
      ...clientFormData,
      clinicianAuth0Ids: val ? [val] : []
    });
  };

  const validatePart1Field = async (recordType: newClientForm['recordType'], clientVal: newClientForm) => {
    const isCaseIdDuplicateExist = await checkIsDuplicateCaseId(token, clientVal.caseId);
    if (recordType === 'adult') {
      const { isAdultFormErrorExist } = await validateAdultForm(
        token,
        clientVal.clientProfiles,
        clientVal.clientCapability.profileType
      );
      if (isAdultFormErrorExist || isCaseIdDuplicateExist) {
        setPage1SubmitStatus('');
      }
      return !isAdultFormErrorExist && !isCaseIdDuplicateExist;
    } else if (recordType === 'child') {
      const { isChildFormErrorExist } = await validateChildForm(clientVal.clientProfiles);

      if (isChildFormErrorExist || isCaseIdDuplicateExist || clientVal.clientCapability.profileType !== 'recordOnly') {
        setPage1SubmitStatus('');
      }
      return (
        !isChildFormErrorExist && !isCaseIdDuplicateExist && clientVal.clientCapability.profileType === 'recordOnly'
      );
    } else if (recordType === 'couple') {
      const { isClientFormErrorExist, isDuplicateEmail, isDuplicatePhone } = await validateCoupleForm(
        token,
        clientVal.clientProfiles
      );
      if (isClientFormErrorExist || isDuplicateEmail || isDuplicatePhone || isCaseIdDuplicateExist) {
        setPage1SubmitStatus('');
      }
      return !isClientFormErrorExist && !isDuplicateEmail && !isDuplicatePhone && !isCaseIdDuplicateExist;
    } else if (recordType === 'youngPerson') {
      const { isYoungFormErrorExist } = await validateYoungForm(
        token,
        clientVal.clientProfiles,
        clientVal.clientCapability.profileType
      );
      if (isYoungFormErrorExist || isCaseIdDuplicateExist) {
        setPage1SubmitStatus('');
      }
      return !isYoungFormErrorExist && !isCaseIdDuplicateExist;
    }
  };

  const handleSubmitPart1 = async () => {
    setPage1SubmitStatus('active');
    setIsSubmitPage1(true);
    if (await validatePart1Field(clientFormData.recordType, clientFormData)) {
      setTimeout(() => {
        setPageStep(2);
        scrollToView('modalHeader', true);
        setPage1SubmitStatus('');
      }, 500);
    }
  };

  const handleSubmitPart2 = async () => {
    setPage2SubmitStatus('active');
    try {
      const result = await postNewClientData({
        token,
        clientVal: clientFormData,
        ...(isClientTimeZoneEnabled && { timeZone }),
        selectedClinicianId
      });
      setClientRecords(result.createdClientRecord);
      setTimeout(() => {
        setPage2SubmitStatus('finished');
      }, 500);
      setTimeout(() => {
        setFormCompleted(true);
        scrollToView('modalHeader', true);
        notification.success({
          message: t('form.success.create_client'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setPage2SubmitStatus('');
        onComplete?.(result.createdClientRecord);
      }, 1000);
    } catch (ex) {
      console.error(ex);
      setPage2SubmitStatus('');
      notification.error({
        message: t('form.error.create_client'),
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleResetForm = async () => {
    setPage1SubmitStatus('');
    setPage2SubmitStatus('');
    setPageStep(1);
    setFormCompleted(false);
    setIsSubmitPage1(false);
    setClientRecords({} as clientRecordsInterface);
    await fetchPrefillClientData(AdultInit);
    onResetForm();
  };

  const handleChangeTimeZone = (value: string) => {
    setTimeZone(value);
  };

  const handleChangeClientAddress = (value: newClientForm['address']) => {
    setClientFormData({
      ...clientFormData,
      address: value
    });
  };

  const handlePlaceSelected = (value: newClientForm['address']) => {
    setClientFormData((clientFormData) => {
      const { address } = clientFormData;
      return {
        ...clientFormData,
        address: {
          ...address,
          line1: value.line1,
          line2: address.line2 || value.line2,
          suburb: address.suburb || value.suburb,
          state: address.state || value.state,
          postcode: address.postcode || value.postcode,
          country: address.country || value.country
        }
      };
    });
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={
        <ModalHeader
          clientDetails={clientFormData}
          selectedClinicianId={selectedClinicianId || ''}
          onChangeClinician={(selectedId) => handleChangeSelectedClinician(selectedId)}
          totalStep={2}
          isCompleted={formCompleted}
          currentStep={pageStep}
          onClickBackBtn={handleBackStepBtn}
        />
      }
      open={visible}
      {...props}
      onCancel={() => {
        onCancel && onCancel(formCompleted);
        if (formCompleted) {
          refetch();
          handleResetForm();
        }
      }}
      footer={null}
    >
      <div className={styles.container}>
        {information}
        {pageStep === 1 && !formCompleted && (
          <>
            <div className={styles.radioBtnContainer}>
              <Radio
                name={'new_client_status'}
                options={[
                  {
                    value: 'active',
                    label: (
                      <OptionLabel
                        isSelected={clientFormData.registeredAs === 'active'}
                        title={t('client.creation.record_status.active')}
                        highlightTitle
                      />
                    )
                  }
                ]}
                value={clientFormData.registeredAs}
                labelClassName={styles.label}
                onChange={(e) => handleClientRecordStatus(e.target.value as newClientForm['registeredAs'])}
              />
              {!isNormalUserView && (
                <Radio
                  name={'new_client_status'}
                  options={[
                    {
                      value: 'waitlist',
                      label: (
                        <OptionLabel
                          isSelected={clientFormData.registeredAs === 'waitlist'}
                          title={t('client.creation.record_status.waitlist')}
                          highlightTitle
                        />
                      )
                    }
                  ]}
                  value={clientFormData.registeredAs}
                  labelClassName={styles.label}
                  onChange={(e) => handleClientRecordStatus(e.target.value as newClientForm['registeredAs'])}
                />
              )}
            </div>
            <div>
              {isClientTimeZoneEnabled && (
                <MaterialSelect
                  className={styles.clientTimeZoneSelect}
                  id={'timeZone'}
                  label={'Client Time Zone'}
                  isSearchable={false}
                  options={TIMEZONE_OPTIONS}
                  value={timeZone}
                  onChange={(value: string) => handleChangeTimeZone(value)}
                />
              )}
              <div className={styles.twoQuestionWrapper}>
                <div className={styles.profileTypeContainer}>
                  <ProfileTypeForm
                    profileTypeData={clientFormData.recordType}
                    onChangeProfileType={handleChangeProfileType}
                  />
                </div>
                {/** Currently applied to YPP only. */}
                {clientFormData.recordType === 'youngPerson' && (
                  <CapabilityFormV2
                    clientRegisteredAs={clientFormData.registeredAs}
                    capabilityData={clientFormData.clientCapability}
                    onChangeClientCapability={handleChangeCapability}
                    helpText={
                      "This provides the option for a Young Person to create an account and manage their key documents and activities. If this is set as 'No', then notifications will be sent to primary contact."
                    }
                  />
                )}
              </div>
              <div className={prefillLoading ? styles.contentLoadingContainer : styles.contentContainer}>
                {prefillLoading ? (
                  <div className={styles.loadingContainer}>
                    <Skeleton active />
                    <Skeleton active />
                  </div>
                ) : (
                  <>
                    {clientFormData.recordType === 'adult' && (
                      <AdultForm
                        referralSourceTags={clientFormData.referralSourceTags}
                        referralDetail={clientFormData.referralDetail}
                        clientDetail={clientFormData.clientProfiles}
                        clientCapability={clientFormData.clientCapability}
                        clientRegisteredAs={clientFormData.registeredAs}
                        onChangeClientField={handleChangeClientDetail}
                        onChangeClientCapability={handleChangeCapability}
                        onChangeReferral={handleChangeReferral}
                        onChangeReferralDetail={handleChangeReferralDetail}
                        caseIdValue={clientFormData.caseId}
                        onChangeCaseId={handleChangeCaseId}
                        checkValidation={isSubmitPage1}
                        clientAddress={clientFormData.address}
                        onChangeClientAddress={handleChangeClientAddress}
                        onPlaceSelected={handlePlaceSelected}
                      />
                    )}
                    {clientFormData.recordType === 'youngPerson' && (
                      <YoungForm
                        clientFormData={clientFormData}
                        onChangeClientField={handleChangeClientDetail}
                        onChangeClientCapability={handleChangeCapability}
                        onChangeReferral={handleChangeReferral}
                        onChangeReferralDetail={handleChangeReferralDetail}
                        onChangeCaseId={handleChangeCaseId}
                        isOnboardingListExist={onBoardingList.length > 0}
                        isContentListExist={consentList.length > 0}
                        defaultOnBoardingId={defaultOnBoardingId}
                        defaultConsentId={defaultConsentId}
                        defaultAssessmentId={defaultAssessmentId}
                        checkValidation={isSubmitPage1}
                        clientAddress={clientFormData.address}
                        onChangeClientAddress={handleChangeClientAddress}
                        onPlaceSelected={handlePlaceSelected}
                      />
                    )}
                    {clientFormData.recordType === 'child' && (
                      <ChildForm
                        referralSourceTags={clientFormData.referralSourceTags}
                        referralDetail={clientFormData.referralDetail}
                        clientDetail={clientFormData.clientProfiles}
                        clientCapability={clientFormData.clientCapability}
                        onChangeClientField={handleChangeClientDetail}
                        onChangeClientCapability={handleChangeCapability}
                        onChangeReferral={handleChangeReferral}
                        onChangeReferralDetail={handleChangeReferralDetail}
                        caseIdValue={clientFormData.caseId}
                        onChangeCaseId={handleChangeCaseId}
                        isOnboardingListExist={onBoardingList.length > 0}
                        isContentListExist={consentList.length > 0}
                        defaultOnBoardingId={defaultOnBoardingId}
                        defaultConsentId={defaultConsentId}
                        defaultAssessmentId={defaultAssessmentId}
                        checkValidation={isSubmitPage1}
                        clientAddress={clientFormData.address}
                        onChangeClientAddress={handleChangeClientAddress}
                        onPlaceSelected={handlePlaceSelected}
                      />
                    )}
                    {clientFormData.recordType === 'couple' && (
                      <CoupleForm
                        referralSourceTags={clientFormData.referralSourceTags}
                        referralDetail={clientFormData.referralDetail}
                        clientDetail={clientFormData.clientProfiles}
                        clientCapability={clientFormData.clientCapability}
                        clientRegisteredAs={clientFormData.registeredAs}
                        onChangeReferral={handleChangeReferral}
                        onChangeReferralDetail={handleChangeReferralDetail}
                        onChangeClientField={handleChangeClientDetail}
                        onChangeClientCapability={handleChangeCapability}
                        caseIdValue={clientFormData.caseId}
                        onChangeCaseId={handleChangeCaseId}
                        checkValidation={isSubmitPage1}
                        clientAddress={clientFormData.address}
                        onChangeClientAddress={handleChangeClientAddress}
                        onPlaceSelected={handlePlaceSelected}
                      />
                    )}
                    <div className={styles.submitButtonContainer}>
                      <ButtonAlt
                        id="nextStepBtnId"
                        disabled={page1SubmitStatus !== ''}
                        status={page1SubmitStatus}
                        onClick={handleSubmitPart1}
                      >
                        Next
                      </ButtonAlt>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {pageStep === 2 && (
          <OnboardingTriageStep
            formCompleted={formCompleted}
            clientFormData={clientFormData}
            onboardingList={onBoardingList}
            consentList={consentList}
            assessmentList={assessmentList}
            clientRecords={clientRecords}
            setClientFormData={setClientFormData}
            handleSubmit={handleSubmitPart2}
            handleResetForm={handleResetForm}
            submitStatus={page2SubmitStatus}
          />
        )}
      </div>
    </Modal>
  );
};

export default AddPatientModalV2;
