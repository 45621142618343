import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import styles from './SearchBarT23.module.scss';
import SearchByDropdown from './SearchByDropdown/SearchByDropdown';

export interface ISearchByOption {
  name: string;
  _id: string;
}

interface SearchBarT23Props {
  placeholder?: string;
  value?: string;
  onSearch: (searchValue: string) => void;
  withSearchButton?: boolean;
  label?: string;
  containerClassName?: string;
  searchOnChange?: boolean;
  hideLabel?: boolean;
  labelClassName?: string;
  // if this prop is passed, meaning this component will include dropdown
  withSearchBy?: {
    onChange: (value: string) => void;
    searchByOptions: ISearchByOption[];
    selectedSearchByOption: ISearchByOption;
  };
}

const SearchBarT23 = ({
  placeholder,
  value,
  onSearch,
  withSearchButton,
  label,
  containerClassName,
  searchOnChange,
  hideLabel,
  labelClassName,
  withSearchBy
}: SearchBarT23Props) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [searchValue, setSearchValue] = useState(value || '');
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setSearchValue(value || '');
  }, [value]);

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && (searchValue.length === 0 || searchValue.length > 1)) {
      onSearch(searchValue);
    }
  };

  const handleOnChange = (val: string) => {
    setSearchValue(val);
    if (searchOnChange) {
      onSearch(val);
    }
  };

  const handleOnSearch = () => {
    if (searchValue.length === 0 || searchValue.length > 1) {
      onSearch(searchValue);
    }
  };

  return (
    <div className={styles.wrapper}>
      {!hideLabel && (
        <div className={classNames(styles.searchLabel, labelClassName)}>
          {withSearchButton && (
            <div className={styles.searchButton} onClick={handleOnSearch}>
              <div
                className={classNames(
                  styles.iconWrapper,
                  focused && (isEdgeAdminView ? styles.adminIconWrapperFocused : styles.iconWrapperFocused)
                )}
              >
                <span
                  className={classNames(
                    'material-icons-outlined',
                    styles.searchIcon,
                    focused && (isEdgeAdminView ? styles.adminSearchIconFocused : styles.searchIconFocused)
                  )}
                >
                  search
                </span>
              </div>
            </div>
          )}
          {label || 'SEARCH BY:'}
        </div>
      )}
      <div className={classNames(styles.container, containerClassName)}>
        <div className={styles.searchBarContainer}>
          {withSearchBy && (
            <div className={styles.filterDropdown}>
              <SearchByDropdown
                id="searchFilter"
                menuList={withSearchBy.searchByOptions}
                selected={withSearchBy.selectedSearchByOption}
                onChange={(value) => withSearchBy.onChange(value)}
              />
            </div>
          )}
          <input
            className={classNames(
              withSearchButton
                ? isEdgeAdminView
                  ? styles.adminSearchInputWithBtn
                  : styles.searchInputWithBtn
                : styles.searchInput,
              !withSearchBy && styles.searchInputWithoutFilter
            )}
            value={searchValue}
            placeholder={placeholder}
            onChange={(e) => handleOnChange(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setFocused(false);
              setSearchValue(value || ''); // Rest search value if user don't hit enter
            }}
          />
          {searchValue.length ? (
            <span
              className={classNames('material-icons-outlined', styles.closeIcon)}
              onClick={() => {
                setSearchValue('');
                onSearch('');
              }}
            >
              close
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SearchBarT23;
