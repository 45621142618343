import { useEffect, useState } from 'react';
import { GroupDetailsTab, GroupPsychometricMenu } from 'pages/Groups/Interfaces/Groups';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchGroupAssessmentClients } from '../../hooks/getGroupAssessmentClients';
import PsychometricHeader from './components/PsychometricHeader/PsychometricHeader';
import PsychometricListContent from './components/PsychometricListContent/PsychometricListContent';
import styles from './PsychometricList.module.scss';
import LoadingDot from 'components/LoadingDot/LoadingDot';

import NoPsychometrics from './nopsychometric.png';
import Button from 'components/v2/Button/Button';
import SendAssessmentModal from '../../../SendAssessmentModal/SendAssessmentModal';
import Select from 'components/v2/Select/Select';
import { useFetchPsychometricList } from 'pages/Groups/GroupDetails/hooks/getPsychometricMenuList';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';

interface PsychometricListProps {
  psychometricId?: string;
  selectedClinicalAssessmentId?: string;
  psychometricMenuList: GroupPsychometricMenu[];
  onCreateSuccess: () => void;
}

interface SelectedPsychometricProps {
  value: string;
  label: string;
}

const PsychometricList = ({
  psychometricId,
  selectedClinicalAssessmentId,
  psychometricMenuList,
  onCreateSuccess
}: PsychometricListProps) => {
  const navigate = useNavigate();
  const { token } = useGetAccessToken();
  const { CARECOORDINATION } = useRoutesGenerator();
  const { isEdgePlanUser } = useGetAccountPackage();
  const { groupId = '' } = useParams<{ groupId: string }>();
  const location = useLocation();
  const [visibleSendAssessment, setVisibleSendAssessment] = useState<boolean>(false);
  const { psychometricList, isPsychometricListLoading } = useFetchPsychometricList(token, groupId);
  const { groupAssessmentClients, isGroupAssessmentClientsLoading } = useFetchGroupAssessmentClients(
    token,
    groupId,
    psychometricId || '',
    selectedClinicalAssessmentId || ''
  );
  const [selectedPsychometricId, setSelectedPsychometricId] = useState<SelectedPsychometricProps>();
  const noPsychometrics = !isPsychometricListLoading && psychometricList?.length === 0;
  const noPsychometricId = !isPsychometricListLoading && psychometricList.length !== 0 ? psychometricList[0]._id : '';
  const getPsychometricId: string = isEdgePlanUser
    ? location.pathname.split('/')[5] ?? noPsychometricId
    : location.pathname.split('/')[4] ?? noPsychometricId;

  useEffect(() => {
    if (!psychometricId && psychometricMenuList && psychometricMenuList.length > 0) {
      const isSentPsychometric = psychometricMenuList.filter((obj) => obj.isSent);
      if (isSentPsychometric.length > 0) {
        navigate(
          `${CARECOORDINATION.GROUPS}/${groupId}/${GroupDetailsTab.Psychometrics}/${isSentPsychometric[0]._id}` +
            `/clinicalAssessmentId/${isSentPsychometric[0].clinicalAssessmentId}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psychometricMenuList]);

  useEffect(() => {
    const psychometric = psychometricList.find((i) => i._id === getPsychometricId);
    if (psychometric) {
      setSelectedPsychometricId({ value: psychometric._id, label: psychometric.clinicalAssessmentId });
    }
  }, [psychometricList, getPsychometricId, setSelectedPsychometricId]);

  const handleCancel = () => {
    setVisibleSendAssessment(false);
  };

  return (
    <div className={styles.columnContainer}>
      {!noPsychometrics && (
        <div className={styles.headerContainer}>
          <Select
            filledArrow
            label="PSYCHOMETRICS"
            options={psychometricList?.map((i) => ({ value: i._id, label: i.clinicalAssessmentId }))}
            onChange={(val: any) => {
              if (val?.value !== selectedPsychometricId?.value) {
                setSelectedPsychometricId(val);
                navigate(
                  `${CARECOORDINATION.GROUPS}/${groupId}/${GroupDetailsTab.Psychometrics}/${val?.value}/clinicalAssessmentId/${val?.label}`
                );
              }
            }}
            isSearchable={false}
            //@ts-ignore
            value={
              psychometricList
                ? selectedPsychometricId
                  ? {
                      value: psychometricList.find((i) => i._id === selectedPsychometricId.value)!._id,
                      label: selectedPsychometricId.label
                    }
                  : psychometricList[0]
                  ? { value: psychometricList[0]._id, label: psychometricList[0].clinicalAssessmentId }
                  : undefined
                : undefined
            }
            isLoading={isPsychometricListLoading}
            containerClass={styles.selectPsychometricId}
            controlStyle={{ boxShadow: 'none' }}
          />
        </div>
      )}
      <div className={styles.container}>
        {groupAssessmentClients.length === 0 && isGroupAssessmentClientsLoading ? (
          <div className={styles.loadingContainer}>
            <LoadingDot />
          </div>
        ) : psychometricMenuList.length > 0 && groupAssessmentClients.length > 0 ? (
          <>
            <PsychometricHeader
              selectedClinicalAssessmentId={selectedClinicalAssessmentId}
              groupId={groupId}
              psychometricId={psychometricId}
              psychometricMenuList={psychometricMenuList}
            />
            <PsychometricListContent
              psychometricList={groupAssessmentClients}
              isPsychometricListLoading={isGroupAssessmentClientsLoading}
            />
          </>
        ) : psychometricId ? (
          'No clients were in the group when this psychometric assessment was sent, or all clients who have been sent this psychometric assessment have been removed from the group.'
        ) : (
          <div className={styles.noPsychometrics}>
            <img className={styles.image} src={NoPsychometrics} alt="No psychometric" />
            <div className={styles.prompt}>
              <div className={styles.text}>No psychometrics have been sent for this group.</div>
              <Button className={styles.button} onClick={() => setVisibleSendAssessment(true)}>
                <i className={`material-icons-outlined ${styles.icon}`}>send</i>Send assessment
              </Button>
              <SendAssessmentModal
                groupId={groupId}
                showModal={visibleSendAssessment}
                handleCancel={handleCancel}
                onCreateSuccess={onCreateSuccess}
                setShowModal={(value) => setVisibleSendAssessment(value)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PsychometricList;
