import { Groups } from 'pages/Groups/Interfaces/Groups';
import styles from './ActiveGroupListCard.module.scss';
import ActiveGroupListingItem from './components/ActiveGroupListingItem';
import ListHeaderItem from 'components/T23/ListHeaderItem/ListHeaderItem';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { GroupListSorting } from 'redux/groups/groupSlice';

interface ActiveGroupListCardProps {
  groupListing: Groups[];
  searchValue: string;
  sorting: GroupListSorting;
  onSortGroupListByName: () => void;
  onSortGroupListByClientSince: () => void;
  onSortGroupListByGroupMember: () => void;
}

const ActiveGroupListCard = ({
  groupListing,
  searchValue,
  sorting,
  onSortGroupListByName,
  onSortGroupListByClientSince,
  onSortGroupListByGroupMember
}: ActiveGroupListCardProps) => {
  const { isGroupsTagsToggle } = useGetFeatureToggle();
  const { sortBy, sortType } = sorting;

  return groupListing.length > 0 ? (
    <>
      <div className={styles.filterContainer}>
        <ListHeaderItem
          columnId="groupName"
          className={styles.groupNameSection}
          sortBy={sortBy}
          sortType={sortType}
          columnName="Group Name"
          sortAble
          onSort={onSortGroupListByName}
        />

        <ListHeaderItem
          columnId="clientSince"
          className={styles.createdSection}
          sortBy={sortBy}
          sortType={sortType}
          columnName="Created"
          sortAble
          onSort={onSortGroupListByClientSince}
        />

        {isGroupsTagsToggle && (
          <ListHeaderItem columnId="groupTag" className={styles.groupTagSection} columnName="Group Tag" />
        )}

        <ListHeaderItem
          columnId="groupMember"
          className={styles.groupMemberSection}
          sortBy={sortBy}
          sortType={sortType}
          columnName="Group Members"
          sortAble
          onSort={onSortGroupListByGroupMember}
        />

        <ListHeaderItem columnId="groupLead" className={styles.groupLeadSection} columnName="Group Lead" />
        <ListHeaderItem columnId="groupDesc" className={styles.descriptionSection} columnName="Description" />

        <div className={styles.buttonSection} />
      </div>
      {groupListing.map((groupListingObj, index) => (
        <ActiveGroupListingItem key={index} groupListingItem={groupListingObj} searchValue={searchValue} />
      ))}
    </>
  ) : (
    <div>There is no data for the group</div>
  );
};

export default ActiveGroupListCard;
