import sanitizeHtml from 'sanitize-html';

export const sanitizeHTMLString = (
  html: string,
  disallowedTagsMode: sanitizeHtml.DisallowedTagsModes = 'discard'
): string =>
  sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'pre', 'strike']),
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
      span: ['contenteditable'],
      '*': ['style', 'class']
    },
    allowedSchemesByTag: {
      img: ['data']
    },
    parseStyleAttributes: false,
    disallowedTagsMode
  });
