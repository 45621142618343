import { useCurrency } from 'utils/hooks/useCurrency';
import styles from './AppointmentReportHeader.module.scss';
import columnStyles from './AppointmentReportHeaderColumn.module.scss';
import { getAppointmentReportTableColumns } from './column';
import ListHeaderItem from 'components/T23/ListHeaderItem/ListHeaderItem';
import { getColumnNameFromId } from 'components/T23/ListHeaderItem/helper';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SortType } from 'redux/features/utils';
import { selectSorting, setSorting } from 'redux/features/practitionerReport/practitionerReportSlice';
import moment from 'moment';
import { defaultTimezone } from 'utils/hooks/GetTimezones/getTimezones';

const AppointmentReportHeader = () => {
  const dispatch = useAppDispatch();
  const { CURRENCY_SYMBOL } = useCurrency();

  const sorting = useAppSelector(selectSorting);

  const { sortBy, sortType } = sorting;

  const onClickSort = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      dispatch(setSorting({ ...sorting, sortType: sortType === SortType.ASC ? SortType.DESC : SortType.ASC }));
    } else {
      dispatch(setSorting({ ...sorting, sortBy: newSortBy, sortType: SortType.DESC }));
    }
  };
  const abbreviatedTimezone = moment().tz(defaultTimezone).format('z');

  return (
    <div className={styles.container}>
      {getAppointmentReportTableColumns(CURRENCY_SYMBOL, abbreviatedTimezone).map((column, index) => (
        <ListHeaderItem
          key={index}
          columnId={column.id}
          className={columnStyles[column.id]}
          sortBy={sortBy}
          sortType={sortType}
          columnName={column.label || getColumnNameFromId(column.id)}
          sortAble={column.sortAble}
          onSort={onClickSort}
        />
      ))}
    </div>
  );
};

export default AppointmentReportHeader;
