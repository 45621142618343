import classNames from 'classnames';

import styles from './ContentLayout.module.scss';

interface ContentLayoutProps {
  children?: any;
  className?: string;
  withMaxWidth?: boolean;
}

const ContentLayout = ({ children, className, withMaxWidth }: ContentLayoutProps) => {
  return <div className={classNames(styles.container, className, withMaxWidth && styles.withMaxWidth)}>{children}</div>;
};

export default ContentLayout;
