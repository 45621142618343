import useGetRecentAppointments from 'pages/Report/hooks/useGetRecentAppointments';
import styles from './RecentAppointmentLists.module.scss';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import WidgetItemWrapper from '../WidgetItemWrapper/WidgetItemWrapper';
import { ReportWidgetType } from 'interfaces/Reports/report';
import { getDeliveryTypeLabel } from 'utils/appointment';

interface Props {
  clientRecordId: string;
}
export const RecentAppointmentList = ({ clientRecordId }: Props) => {
  const { isLoading, recentAppointmentLists } = useGetRecentAppointments(clientRecordId, 10);

  return (
    <>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>
          <i className={`material-icons ${styles.titleIcon}`}>calendar_month</i>
          Recent Appointments
        </div>
      </div>
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <LoadingDot />
        </div>
      )}
      <div className={styles.appointmentLists}>
        {recentAppointmentLists.map((appointment) => (
          <div className={styles.dragContainer} key={appointment._id}>
            <WidgetItemWrapper
              key={appointment._id}
              data={{
                title: `Date: ${appointment.date} | Time: ${appointment.startTime} - ${
                  appointment.endTime
                } | Appointment Type: ${appointment.sessionTypeName} | Delivery Type: ${getDeliveryTypeLabel(
                  appointment.deliveryType,
                  appointment.otherInstructions
                )}`,
                width: 600
              }}
              widgetType={ReportWidgetType.BodyTextWidget}
            >
              <div className={styles.inputBodyWidget}>
                <div className={styles.appointmentWrapper}>
                  <h3>{appointment.sessionTypeName}</h3>
                  <div>Date: {appointment.date}</div>
                  <div>
                    Time: {appointment.startTime} - {appointment.endTime}
                  </div>
                </div>
              </div>
            </WidgetItemWrapper>
          </div>
        ))}
      </div>
    </>
  );
};
