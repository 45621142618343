import styles from './CoupleInfoBox.module.scss';
import { clientProfilesEncrypted, clientRecordsEncryptedInterface } from 'interfaces/Clients/clientsRecord';
import ClientContactDetails from '../../../ClientContactDetails/ClientContactDetails';
import { getShortOrdinalNumber } from 'utils/general';

interface CoupleInfoBoxProps {
  clientRecordData: clientRecordsEncryptedInterface;
}

const CoupleInfoBox = ({ clientRecordData }: CoupleInfoBoxProps) => {
  const clientProfiles = clientRecordData.clientProfiles as clientProfilesEncrypted[];

  return (
    <div className={styles.coupleInfoContainer}>
      <div className={styles.contactDetailsWrapper}>
        {clientProfiles.map((item, index) => (
          <ClientContactDetails
            key={`detail_${item._id}`}
            clientProfileData={item}
            title={`${getShortOrdinalNumber(index + 1)} Contact`}
            clientAddress={index === 0 ? clientRecordData.address : undefined}
            caseId={clientRecordData.caseId}
          />
        ))}
      </div>
    </div>
  );
};

export default CoupleInfoBox;
