import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import AssessmentsDetailsContent from './components/AssessmentsDetailsContent/AssessmentsDetailsContent';
import NotFound404 from 'pages/404/NotFound404';
import { useMCIDetails } from 'utils/hooks/useMCIDetails';

import styles from './MicroCheckInDetails.module.scss';

const MicroCheckInDetails = () => {
  const {
    mciDetails,
    mciDetailsInitializing,
    mciDetailsRefetching,
    questionTemplates,
    questionTemplatesInitializing,
    refetchMciDetails
  } = useMCIDetails();
  const initializingPage = mciDetailsInitializing || questionTemplatesInitializing;

  return (
    <HelmetWrapper title={'Tacklit - Micro Check In Assessments'}>
      <ContentLayout>
        {initializingPage ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : mciDetails ? (
          <div className={styles.container}>
            <AssessmentsDetailsContent
              data={mciDetails}
              isEdit={mciDetails?.isExisting || false}
              qTemplateData={questionTemplates}
              refetchMciDetails={refetchMciDetails}
              mciDetailsRefetching={mciDetailsRefetching}
            />
          </div>
        ) : (
          <NotFound404 />
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default MicroCheckInDetails;
