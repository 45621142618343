import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import {
  NotificationSettingsInterface,
  NotificationPreference
} from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

export enum CommunicationTransport {
  SMS = 'sms',
  Email = 'email',
  Both = 'both',
  None = 'none'
}

interface GetCommunication {
  transport: CommunicationTransport;
  notificationSettings?: NotificationSettingsInterface;
  category: keyof NotificationSettingsInterface;
}

// use to get communication preference query for filtering the client records
export const getCommunicationPreferencesFilterQueryArray = ({
  transport,
  notificationSettings,
  category
}: GetCommunication): CommunicationPreference[] => {
  const allowed = {
    sms: [CommunicationTransport.SMS, CommunicationTransport.Both].includes(transport),
    email: [CommunicationTransport.Email, CommunicationTransport.Both].includes(transport)
  };

  if (notificationSettings) {
    switch (notificationSettings[category]) {
      case NotificationPreference.None:
        allowed.sms = false;
        allowed.email = false;
        break;
      case NotificationPreference.SMS:
        allowed.email = false;
        break;
      case NotificationPreference.Email:
        allowed.sms = false;
        break;
      default:
        break;
    }
  }

  let communicationPreferencesQuery: CommunicationPreference[] = [];
  if (allowed.sms && allowed.email) {
    communicationPreferencesQuery = [
      CommunicationPreference.SMS,
      CommunicationPreference.Email,
      CommunicationPreference.NoPreference
    ];
  } else if (!allowed.sms && !allowed.email) {
    communicationPreferencesQuery = [CommunicationPreference.None];
  } else if (allowed.email) {
    communicationPreferencesQuery = [CommunicationPreference.Email, CommunicationPreference.NoPreference];
  } else if (allowed.sms) {
    communicationPreferencesQuery = [CommunicationPreference.SMS, CommunicationPreference.NoPreference];
  }
  return communicationPreferencesQuery;
};
