import classnames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import IconButton from 'components/IconButton/IconButton';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { CancelAppointmentModal } from '../CancelAppointmentModal/CancelAppointmentModal';
import { useCancelAppointment } from '../../hooks/useCancelAppointment';
import { useProcessAppointment } from '../../hooks/useProcessAppointment';
import styles from './AppointmentActionsDropdown.module.scss';
import { useAppDispatch } from 'redux/hooks';
import { setIsCancel } from 'redux/processAppointment/processAppointmentSlice';

interface AppointmentActionsDropdownProps {
  handleReschedule?: () => void;
  appointment: AppointmentSlots;
  token: string;
  onFinishMarkStatus: () => void;
  handleShowProcessAppointment: () => void;
  isFromCalendarView: boolean;
}

const AppointmentActionsDropdown: FC<AppointmentActionsDropdownProps> = ({
  handleReschedule,
  appointment,
  token,
  onFinishMarkStatus,
  handleShowProcessAppointment,
  isFromCalendarView
}) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const dispatch = useAppDispatch();
  const menuNode = useRef<HTMLDivElement>(null);
  const modalNode = useRef<HTMLDivElement>(null);

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const { date, startTime, endTime, recurringAppointmentId, isActivity } = appointment;

  const {
    isCancelling,
    isConfirmingCancel,
    reason,
    isErrorVisible,
    isRecurring,
    cancelRecurringStatus,
    cancelButtonLabel,
    handleChangeReason,
    handleCancel,
    handleCloseModal,
    cancelAppointment,
    handleAbortCancelRecurringAppointments,
    resetCancelValues
  } = useCancelAppointment({
    appointment,
    onFinishMarkStatus,
    token,
    recurringAppointmentId
  });

  const { isProcessVisible } = useProcessAppointment({ appointment });

  const handleToggleMenu = () => {
    if (isMenuExpanded) {
      resetCancelValues();
    }
    setIsMenuExpanded(!isMenuExpanded);
  };

  const handleClickOutSide = (event: any) => {
    if (isCancelling || menuNode.current?.contains(event.target) || modalNode.current?.contains(event.target)) {
      return;
    }
    setIsMenuExpanded(false);
    resetCancelValues();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => document.removeEventListener('mousedown', handleClickOutSide);
  });

  const onCancelAppointmentHandle = () => {
    if (!isActivity) {
      dispatch(setIsCancel(true));
      setIsMenuExpanded(!isMenuExpanded);
      handleShowProcessAppointment();
    } else {
      handleCancel();
    }
  };

  return (
    <div ref={menuNode} className={styles.container}>
      {isFromCalendarView ? (
        <ButtonAlt
          variant="outlined"
          iconPostFix
          onClick={handleToggleMenu}
          icon="expand_circle_down"
          size="medium"
          fullWidth
          disabled={isCancelling}
        >
          Appointment Actions
        </ButtonAlt>
      ) : (
        <IconButton active={isMenuExpanded} iconName="event_note" onClick={handleToggleMenu} />
      )}
      <div className={styles.menuWrapper}>
        <div className={isMenuExpanded ? styles.menuShow : styles.menuHide}>
          {isProcessVisible && (
            <div className={classnames(isCancelling && styles.notAllowed)}>
              <div
                className={classnames(
                  isEdgeAdminView ? styles.adminTheme : styles.userTheme,
                  isCancelling && styles.noEvent
                )}
                onClick={() => {
                  setIsMenuExpanded(!isMenuExpanded);
                  handleShowProcessAppointment();
                }}
              >
                Process
              </div>
            </div>
          )}
          {handleReschedule && (
            <div className={classnames(isCancelling && styles.notAllowed)}>
              <div
                className={classnames(
                  isEdgeAdminView ? styles.adminTheme : styles.userTheme,
                  isCancelling && styles.noEvent
                )}
                onClick={() => {
                  setIsMenuExpanded(!isMenuExpanded);
                  handleReschedule();
                }}
              >
                Reschedule
              </div>
            </div>
          )}
          <div className={classnames(isCancelling && styles.notAllowed)}>
            <div
              className={classnames(
                styles.cancelContainer,
                isEdgeAdminView ? styles.adminTheme : styles.userTheme,
                isConfirmingCancel && styles.confirming,
                isCancelling && styles.noEvent,
                styles.cursorDefault
              )}
            >
              {!isActivity && (
                <>
                  <textarea
                    className={classnames(styles.hidden, isConfirmingCancel && styles.cancelTextarea)}
                    placeholder="Cancellation message for client..."
                    value={reason}
                    onChange={handleChangeReason}
                  />
                  <ErrorMessage
                    className={styles.errorLabel}
                    error="Please insert reason to cancel"
                    visible={isErrorVisible}
                  />
                </>
              )}
              <div className={styles.cancelLabelWrapper} onClick={onCancelAppointmentHandle}>
                <div className={styles.cancelLabel}>{cancelButtonLabel}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isRecurring && (
        <CancelAppointmentModal
          modalNode={modalNode}
          date={date}
          endTime={endTime}
          startTime={startTime}
          isOpen={isRecurring}
          onClose={handleCloseModal}
          handleCancelAppointment={cancelAppointment}
          handleAbortCancelRecurringAppointments={handleAbortCancelRecurringAppointments}
          cancelRecurringStatus={cancelRecurringStatus}
        />
      )}
    </div>
  );
};

export default AppointmentActionsDropdown;
