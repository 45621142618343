import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import PageHeader from 'components/PageHeader/PageHeader';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './CarePathwayTemplate.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'components/Card/Card';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import FlexBox from 'components/FlexBox/FlexBox';
import Label from 'pages/Report/components/Label/Label';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import Radio, { RadioOption } from 'components/Radio/Radio';
import { Divider, Skeleton, notification } from 'antd';
import WidgetTextEditor2 from 'components/WidgetTextEditor2/WidgetTextEditor2';
import { CarePathwayGoal } from 'components/CarePathwayGoal/CarePathwayGoal';
import { CarePathwayTemplateAttachment } from 'components/CarePathwayTemplateAttachmentInput/CarePathwayTemplateAttachment';
import { CarePathwayAppointment } from 'components/CarePathwayAppointment/CarePathwayAppointment';
import { CarePathwayTemplateSchema, CarePathwayTemplateSchemaType } from './CarePathwayTemplateSchema';
import { Form } from 'components/Form';
import {
  CarePathwayTemplatePayload,
  CreateCarePathwayTemplateResponse,
  useCreateCarePathwayTemplateMutation,
  useGetCarePathwayTemplateByIdQuery,
  useUpdateCarePathwayTemplateMutation
} from 'redux/endpoints/clinicianProfileServices/carePathwayTemplate';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useMemo } from 'react';

export const CarePathwayTemplate = () => {
  const { FORMS } = useRoutesGenerator();
  const navigate = useNavigate();
  const { carePathwayTemplateId = '' } = useParams<{ carePathwayTemplateId?: string }>();
  const { accountId } = useGetAccountId();
  const isUpdating = !!carePathwayTemplateId;

  const { data, isFetching } = useGetCarePathwayTemplateByIdQuery(
    {
      accountId: `${accountId}` ?? '',
      id: carePathwayTemplateId
    },
    {
      skip: !isUpdating
    }
  );
  const initialValues = useMemo(() => {
    return {
      name: data?.name ?? '',
      description: data?.description ?? '',
      shortCode: data?.shortCode ?? '',
      status: data?.status ?? 'draft',
      eligibility: data?.eligibility ?? 'individual',
      sequence: data?.sequence ?? 'any',
      summary: data?.summary ?? '',
      purpose: {
        category: data?.purpose.category ?? '1',
        objective: data?.purpose.objective ?? '1',
        description: data?.purpose.description ?? ''
      },
      appointments: data?.items ?? [],
      attachments: data?.attachments ?? []
    };
  }, [data]);
  const [createCarePathwayTemplate] = useCreateCarePathwayTemplateMutation();
  const [updateCarePathwayTemplate] = useUpdateCarePathwayTemplateMutation();

  const templateDetailsField: {
    name: 'name' | 'description' | 'shortCode';
    label: string;
  }[] = [
    {
      name: 'name',
      label: 'Name of the template*'
    },
    {
      name: 'description',
      label: 'Short description of care pathway template'
    },
    {
      name: 'shortCode',
      label: 'Care Pathway ID short code'
    }
  ];

  const eligibilityOptions: RadioOption[] = [
    { value: 'individual', label: 'Individual client' },
    { value: 'group', label: 'Group', disabled: true }
  ];

  const sequenceOptions: RadioOption[] = [
    { value: 'any', label: 'Any Order' },
    { value: 'defined', label: 'Defined Sequence', disabled: true }
  ];

  const handleSubmit = async (values: CarePathwayTemplateSchemaType, context?: { isDraft: boolean }) => {
    const { appointments, ...others } = values;
    const body: CarePathwayTemplatePayload = {
      ...others,
      items: [...appointments],
      accountId: `${accountId}` ?? '',
      status: context?.isDraft ? 'draft' : 'published'
    };

    if (isUpdating) {
      const response = await updateCarePathwayTemplate({
        ...body,
        id: carePathwayTemplateId
      });
      if (Object.hasOwn(response, 'data')) {
        notification.success({
          message: 'Care pathway template updated!',
          duration: 2
        });
      } else {
        notification.error({
          message: 'Something went wrong while trying to update this care pathway template',
          duration: 2
        });
      }
    } else {
      const response = await createCarePathwayTemplate(body);
      if (Object.hasOwn(response, 'data')) {
        notification.success({
          message: 'Care pathway template created!',
          duration: 2
        });
        navigate(
          `${FORMS.CARE_PATHWAY_TEMPLATE}/${(response as { data: CreateCarePathwayTemplateResponse })?.data?._id}`
        );
      } else {
        notification.error({
          message: 'Something went wrong while trying to create this care pathway template',
          duration: 2
        });
      }
    }
  };

  return (
    <HelmetWrapper title={'Care Pathway Template'}>
      <ContentLayout className={styles.container}>
        {isFetching ? (
          <Card className={styles.cardContainer}>
            <Skeleton active />
          </Card>
        ) : (
          <Form<typeof CarePathwayTemplateSchema, { isDraft: boolean }>
            validationSchema={CarePathwayTemplateSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, setValues, submitForm, isSubmitting, isValid, isWaitingFormValidation }) => {
              const DRAFT = {
                id: 'draft',
                label: 'Draft',
                isActive: values.status === 'draft'
              };
              const PUBLISHED = {
                id: 'published',
                label: 'Published',
                isActive: values.status === 'published'
              };
              const toggleList = [DRAFT, PUBLISHED];

              const hasImcompleteItems =
                !values.appointments.length ||
                values.appointments.some((item) => {
                  return !item.appointmentTypeId;
                });

              const disableSave = isSubmitting || isWaitingFormValidation || !isValid || hasImcompleteItems;
              const disableSaveAsDraft = isSubmitting || isWaitingFormValidation || !isValid;

              const handleSave = () => {
                submitForm();
              };

              const handleSaveAsDraft = () => {
                submitForm({
                  isDraft: true
                });
              };

              return (
                <>
                  <PageHeader title="Care Pathway Template">
                    <div className={styles.pageHeaderRight}>
                      <ButtonAlt disabled={disableSaveAsDraft} onClick={handleSaveAsDraft} variant="outlined">
                        Save As Draft
                      </ButtonAlt>
                      <ButtonAlt disabled={disableSave} onClick={handleSave}>
                        Save & Publish
                      </ButtonAlt>
                    </div>
                  </PageHeader>
                  <ButtonAlt
                    variant={'text'}
                    size={'medium'}
                    onClick={() => navigate(FORMS.BASE)}
                    icon={'arrow_back_ios'}
                    className={styles.backBtn}
                  >
                    To Template List
                  </ButtonAlt>
                  <Card className={styles.cardContainer}>
                    <FlexBox className={styles.leftContainer} direction="column" spacing={20}>
                      {templateDetailsField.map((item) => (
                        <MaterialInput
                          key={`templateDetails-${item.name}`}
                          id={`${item.name}-input`}
                          name={item.name}
                          label={item.label}
                          value={values[item.name]}
                          required
                          onChange={(e) => setValues({ [item.name]: e.target.value })}
                        />
                      ))}
                      <div className={styles.statusContainer}>
                        <Label label="TEMPLATE STATUS" />
                        <ToggleSwitchV2
                          id="status"
                          toggleList={toggleList}
                          onChangeStatus={(val) => {
                            setValues({ status: val.id });
                          }}
                        />
                      </div>
                      <div className={styles.radioContainer}>
                        <div className={styles.title}>Eligibility</div>
                        <Radio
                          name="eligibility"
                          options={eligibilityOptions}
                          vertical
                          value={values.eligibility}
                          onChange={(event) => {
                            setValues({ eligibility: event.target.value });
                          }}
                        />
                      </div>
                      <div className={styles.radioContainer}>
                        <div className={styles.title}>Type</div>
                        <Radio
                          name="sequence"
                          options={sequenceOptions}
                          vertical
                          value={values.sequence}
                          onChange={(event) => {
                            setValues({ sequence: event.target.value });
                          }}
                        />
                      </div>
                    </FlexBox>
                    <Divider type="vertical" className={styles.divider} />
                    <div className={styles.rightContainer}>
                      <div>
                        <Label label="PATHWAY SUMMARY" />
                        <WidgetTextEditor2
                          height={315}
                          minHeight={315}
                          basicMention
                          enableMention
                          hasContainerBorder
                          value={values.summary ?? ''}
                          className={styles.welcomeNoteEditor}
                          onChange={(body) => {
                            setValues({ summary: body.trim() });
                          }}
                        />
                      </div>
                      <CarePathwayGoal />
                      <CarePathwayTemplateAttachment />
                      <Divider />
                      <CarePathwayAppointment />
                    </div>
                  </Card>
                </>
              );
            }}
          </Form>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};
