import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import Button from 'components/v2/Button/Button';
import { ClaimStatus } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';
import { useMemo } from 'react';

import styles from './ClaimStatus.module.scss';

interface ClaimStatusProps {
  _id: string;
  status: ClaimStatus;
  iconOnly?: boolean;
  handleOpenStatementModal?: () => void;
}

const ClaimStatusComponent = ({ _id, status, iconOnly, handleOpenStatementModal }: ClaimStatusProps) => {
  const { icon, iconClass, text, buttonText, tooltip } = useMemo(() => {
    switch (status) {
      case ClaimStatus.MedicareAssessed:
      case ClaimStatus.WithMedicare:
        return {
          icon: 'check_circle',
          iconClass: styles.success,
          text: 'Claim success',
          buttonText: 'View'
        };
      case ClaimStatus.Complete:
        return {
          icon: 'check_circle',
          iconClass: styles.success,
          text: 'Claim completed',
          buttonText: 'View'
        };
      case ClaimStatus.MedicarePendable:
        return {
          icon: 'hourglass_top',
          iconClass: styles.pendable,
          text: 'Claim pendable',
          buttonText: 'View',
          tooltip: 'Possible issues detected in claim, click View to confirm submission'
        };
      case ClaimStatus.MedicarePended:
        return {
          icon: 'hourglass_top',
          iconClass: styles.pended,
          text: 'Claim pended',
          buttonText: 'View',
          tooltip: 'Confirmed Pendable claim, to be reviewed by Medicare manually'
        };
      case ClaimStatus.Expired:
        return {
          icon: 'warning',
          iconClass: styles.expired,
          text: 'Claim expired',
          buttonText: 'View',
          tooltip: 'This Pendable claim has expired. Please resubmit'
        };
      case ClaimStatus.MedicareRejected:
      case ClaimStatus.Rejected:
        return {
          icon: 'cancel',
          iconClass: styles.rejected,
          text: 'Claim rejected',
          buttonText: 'Review'
        };
      case ClaimStatus.Processed:
        return {
          icon: 'done',
          iconClass: styles.pended,
          text: 'Claim processed',
          buttonText: 'Processed'
        };

      case ClaimStatus.WriteOff:
        return {
          icon: 'money_off',
          iconClass: styles.writeOff,
          text: 'Claim written off',
          buttonText: 'View'
        };

      default:
        console.error(`Failed to get claim status icon for status [${status}]`);
        return {};
    }
  }, [status]);

  return (
    <>
      <i className={`material-icons ${styles.icon} ${iconClass}`}>{icon}</i>
      {!iconOnly && (
        <>
          <div className={styles.text}>
            <div>{text}</div>
            <Button className={styles.viewButton} variant="link" onClick={handleOpenStatementModal}>
              {buttonText}
            </Button>
          </div>
          {tooltip && <HelpOutLineWithTooltips id={`${_id}-status-tooltip`} desc={tooltip} />}
        </>
      )}
    </>
  );
};

export default ClaimStatusComponent;
