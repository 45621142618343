import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { MedicareItem, MedicareItemFormat, MedicareItemMode, MedicareItemDuration } from '../interfaces';

export const SOCIAL_WORKER_ITEMS: MedicareItem[] = [
  {
    mbsCode: '80150',
    description:
      'Initial individual face to face (consultation rooms) session by a social worker, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '80155',
    description:
      'Initial individual face to face (call-out) session by a social worker, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 85,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '80151',
    description:
      'Initial individual telehealth (geographic eligibiility applies) session by a social worker, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 58,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '91175',
    description:
      'Initial individual telehealth (video call) session by a social worker, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '91187',
    description:
      'Initial individual telehealth (phone call) session by a social worker, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '80160',
    description:
      'Initial individual face to face (consultation rooms) session by a social worker, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '80165',
    description: 'Initial individual face to face (call-out) session by a social worker, lasting at least 50 minutes.',
    benefit: 109.75,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '80161',
    description:
      'Initial individual telehealth (geographic eligibiility applies) session by a social worker, lasting at least 50 minutes.',
    benefit: 81.9,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '91176',
    description: 'Initial individual telehealth (video call) session by a social worker, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '91188',
    description: 'Initial individual telehealth (phone call) session by a social worker, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '93362',
    description:
      'Additional individual face to face (consultation rooms) session by a social worker, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 55.1,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '93363',
    description:
      'Additional individual telehealth (video call) session by a social worker, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 55.1,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '93364',
    description:
      'Additional individual telehealth (phone call) session by a social worker, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 55.1,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: '93365',
    description:
      'Additional individual face to face (consultation rooms) session by a social worker, lasting at least 50 minutes.',
    benefit: 77.8,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '93366',
    description:
      'Additional individual telehealth (video call) session by a social worker, lasting at least 50 minutes.',
    benefit: 77.8,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '93367',
    description:
      'Additional individual telehealth (phone call) session by a social worker, lasting at least 50 minutes.',
    benefit: 77.8,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: '80170',
    description: 'Group face to face session by a social worker, lasting at least 60 minutes.',
    benefit: 21.6,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: '80171',
    description:
      'Group telehealth (geographic eligibility applies) session by a social worker, lasting at least 60 minutes.',
    benefit: 21.6,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: '82376',
    description:
      'Eating disorder psychological treatment service provided to an eligible patient in consulting rooms by an eligible social worker, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '82378',
    description:
      'Eating disorder psychological treatment service provided to an eligible patient at a place other than consulting rooms by an eligible social worker, lasting more than 20 minutes but less than 50 minutes.',
    benefit: 85,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '82379',
    description:
      'Eating disorder psychological treatment service provided to an eligible patient in consulting rooms by an eligible social worker, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '82381',
    description:
      'Eating disorder psychological treatment service provided to an eligible patient at a place other than consulting rooms by an eligible social worker, lasting at least 50 minutes.',
    benefit: 109.75,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '82382',
    description:
      'Eating disorder psychological treatment service provided to a person in person as part of a group of 6 to 10 patients (but not as an admitted patient of a hospital) by an eligible social worker, lasting at least 60 minutes.',
    benefit: 21.6,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: '82383',
    description:
      'Eating disorder psychological treatment service provided to a person by video conference as part of a group of 6 to 10 patients (but not as an admitted patient of a hospital) by an eligible social worker, lasting at least 60 minutes.',
    benefit: 21.6,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: '81005',
    description:
      'Provision of a non-directive pregnancy support counselling service, lasting at least 30 minutes, to a person who is currently pregnant or who has been pregnant in the preceding 12 months, by an eligible social worker, where the patient is referred to the social worker by a medical practitioner.',
    benefit: 70.85,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneMoreThan30
  },
  {
    mbsCode: '93100',
    description:
      'Eating disorder psychological treatment service provided by telehealth attendance to an eligible patient by an eligible social worker, lasting at least 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '93103',
    description:
      'Eating disorder psychological treatment service provided by telehealth attendance to an eligible patient by an eligible social worker, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '93134',
    description:
      'Eating disorder psychological treatment service provided by phone attendance to an eligible patient by an eligible social worker, lasting at least 20 minutes but less than 50 minutes.',
    benefit: 60.35,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: '93137',
    description:
      'Eating disorder psychological treatment service provided by phone attendance to an eligible patient by an eligible social worker, lasting at least 50 minutes.',
    benefit: 85.2,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: '93026',
    description:
      'Provision of a non-directive pregnancy support counselling service as a telehealth attendance, lasting at least 30 minutes, to a person who is currently pregnant or who has been pregnant in the preceding 12 months, by an eligible social worker, where the patient is referred to the social worker by a medical practitioner.',
    benefit: 70.85,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan30
  },
  {
    mbsCode: '93029',
    description:
      'Provision of a non-directive pregnancy support counselling service as a phone attendance, lasting at least 30 minutes, to a person who is currently pregnant or who has been pregnant in the preceding 12 months, by an eligible social worker, where the patient is referred to the social worker by a medical practitioner.',
    benefit: 70.85,
    role: ClinicianMedicareRole.SocialWorkers,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan30
  }
];
