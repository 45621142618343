import { AppointmentClientOrGroupType } from 'redux/features/appointmentCreation/appointmentCreationSlice';

interface oneOnOneAppointmentValidationInterface {
  selectPractitioner?: boolean;
  selectClient?: boolean;
  selectAppointmentType?: boolean;
  selectDeliveryMode?: boolean;
  selectTimeSlot?: boolean;
}

export const oneOnOneAppointmentValidation = ({
  selectPractitioner,
  selectClient,
  selectAppointmentType,
  selectDeliveryMode,
  selectTimeSlot
}: oneOnOneAppointmentValidationInterface) => {
  const ERROR_MESSAGES = {
    practitioner: 'Please select practitioner',
    client: 'Please select client',
    appointmentType: 'Please select appointment type',
    deliveryMode: 'Please select delivery mode',
    timeSlot: 'Please select time slot'
  };

  const errorConditions = [
    { condition: selectPractitioner === false, message: ERROR_MESSAGES.practitioner },
    { condition: selectClient === false, message: ERROR_MESSAGES.client },
    { condition: selectAppointmentType === false, message: ERROR_MESSAGES.appointmentType },
    { condition: selectDeliveryMode === false, message: ERROR_MESSAGES.deliveryMode },
    { condition: selectTimeSlot === false, message: ERROR_MESSAGES.timeSlot }
  ];

  for (const { condition, message } of errorConditions) {
    if (condition) {
      return { errorMessage: message, isErrorExist: true };
    }
  }

  return { errorMessage: '', isErrorExist: false };
};

interface activityAppointmentValidationInterface {
  selectPractitioner?: boolean;
  selectTargetedClient?: boolean;
  selectAppointmentType?: boolean;
  selectedClientType?: AppointmentClientOrGroupType;
  selectTimeSlot?: boolean;
}

export const activityAppointmentValidation = ({
  selectPractitioner,
  selectTargetedClient,
  selectAppointmentType,
  selectedClientType,
  selectTimeSlot
}: activityAppointmentValidationInterface) => {
  const ERROR_MESSAGES = {
    practitioner: 'Please select practitioner',
    client: 'Please select client',
    group: 'Please select group',
    appointmentType: 'Please select activity type',
    timeSlot: 'Please select time slot'
  };

  const errorConditions = [
    { condition: selectPractitioner === false, message: ERROR_MESSAGES.practitioner },
    {
      condition: selectTargetedClient === false,
      message: selectedClientType === AppointmentClientOrGroupType.Client ? ERROR_MESSAGES.client : ERROR_MESSAGES.group
    },
    { condition: selectAppointmentType === false, message: ERROR_MESSAGES.appointmentType },
    { condition: selectTimeSlot === false, message: ERROR_MESSAGES.timeSlot }
  ];

  for (const { condition, message } of errorConditions) {
    if (condition) {
      return { errorMessage: message, isErrorExist: true };
    }
  }

  return { errorMessage: '', isErrorExist: false };
};
