import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SubTabOption } from './SubTab';
import styles from './SubTab.module.scss';

interface SubTabItemProps {
  option: SubTabOption;
  activeOption?: string;
  onChange?: Function;
}

export const SubTabItem: FC<SubTabItemProps> = ({ option, activeOption, onChange }) => {
  const subTabNumber = () => {
    const { number, matchingRecordCount, hideMatchingRecordCount } = option;
    if (number === undefined || number < 0) {
      return null;
    }

    if (matchingRecordCount === undefined || hideMatchingRecordCount) {
      return <div className={styles.number}>{number}</div>;
    }

    return (
      <div className={styles.number}>
        {matchingRecordCount}/{number}
      </div>
    );
  };

  const subTabContent = () => (
    <div
      className={classNames(styles.subTabItem, activeOption === option.value && styles.active)}
      onClick={() => {
        if (activeOption !== option.value) {
          onChange?.(option.value);
        }
      }}
    >
      <div className={classNames(styles.leftContent, option.number && styles.leftContentWithNumber)}>
        <div className={styles.title}>
          {option.icon && (
            <div className={styles.subTabIcon}>
              <i className={'material-icons-outlined'}>{option.icon}</i>
            </div>
          )}
          <div className={styles.ellipsis}>{option.label}</div>
        </div>
        {activeOption === option.value && option.desc && <div className={styles.desc}>{option.desc}</div>}
      </div>
      <div className={styles.rightText}>
        {subTabNumber()}
        <i className={classNames(styles.navigateIcon, 'material-icons-outlined')}>navigate_next</i>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      {option?.href ? <Link to={option.href}>{subTabContent()}</Link> : subTabContent()}
    </div>
  );
};
