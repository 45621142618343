import { createSlice } from '@reduxjs/toolkit';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { RootState } from 'redux/store';

export enum SortType {
  ASC = 1,
  DESC = -1
}

export interface Sorting {
  sortBy: string;
  sortType: SortType;
}

export interface InvoiceReviewFilters {
  clients: FilterCheckListItem[];
  claimTypes: FilterCheckListItem[];
  statuses: FilterCheckListItem[];
}

export interface Paging {
  page: number;
  perPage: number;
}

interface InvoiceReviewSliceState {
  filters: InvoiceReviewFilters;
  sorting: Sorting;
  paging: Paging;
}

const initialState: InvoiceReviewSliceState = {
  filters: {
    clients: [],
    claimTypes: [],
    statuses: []
  },
  sorting: {
    sortBy: 'appointmentDate',
    sortType: SortType.DESC
  },
  paging: {
    page: 1,
    perPage: 10
  }
};

export const invoiceReviewSlice = createSlice({
  name: 'invoiceReviewSlice',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    },

    setPaging: (state, action) => {
      state.paging = action.payload;
    }
  }
});

export const selectFilters = (state: RootState) => state.invoiceReviewSlice.filters;
export const selectSorting = (state: RootState) => state.invoiceReviewSlice.sorting;
export const selectPaging = (state: RootState) => state.invoiceReviewSlice.paging;

export const { setFilters, resetFilters, setSorting, setPaging, resetPaging } = invoiceReviewSlice.actions;

export default invoiceReviewSlice.reducer;
