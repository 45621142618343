import { useEffect, useRef, useState } from 'react';
import styles from './PackageAssignmentActionMenu.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useUnassignPackageMutation } from 'redux/endpoints/scheduleServices/package';
import { Modal, notification } from 'antd';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface PackageAssignmentActionMenuProps {
  clientRecordId: string;
  packageId: string;
  assigneeId: string;
}

const PackageAssignmentActionMenu = ({ clientRecordId, packageId, assigneeId }: PackageAssignmentActionMenuProps) => {
  const { accountId } = useGetAccountId();
  const menuNode = useRef<HTMLDivElement>(null);
  const subMenuNode = useRef<HTMLDivElement>(null);
  const [menuExpand, setMenuExpand] = useState(false);
  const { isEdgeAdminView } = useGetAccountPackageView();

  const [unassignPackage] = useUnassignPackageMutation();

  const handleExpandMenu = () => {
    setMenuExpand(!menuExpand);
  };

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target) || subMenuNode.current?.contains(e.target)) {
      return;
    }
    setMenuExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleUnassignedPackage = async () => {
    handleHideAllMenu();

    Modal.confirm({
      title:
        'Please note that unassigning from this package is not reversible. Any services associated with this package will not be cancelled.',
      onOk: async () => {
        try {
          await unassignPackage({
            accountId,
            clientRecordId: clientRecordId,
            packageId: packageId,
            assigneeId: assigneeId
          }).unwrap();
        } catch (ex) {
          console.error(ex);
          notification.error({
            message: 'Something went wrong while unassigning the package.'
          });
        }
      },
      okButtonProps: {
        style: {
          backgroundColor: isEdgeAdminView ? '#988337' : '#3f52ff',
          borderColor: isEdgeAdminView ? '#988337' : '#3f52ff',
          color: '#fff',
          borderRadius: '4px'
        }
      },
      cancelButtonProps: {
        style: {
          backgroundColor: '#fff',
          borderColor: isEdgeAdminView ? '#988337' : '#3f52ff',
          color: isEdgeAdminView ? '#988337' : '#3f52ff',
          borderRadius: '4px'
        }
      }
    });
  };

  const handleHideAllMenu = () => {
    setMenuExpand(false);
  };

  return (
    <>
      <div ref={menuNode} className={styles.container}>
        <div className={styles.buttonWrapper}>
          <ButtonAlt variant={'text'} size={'medium'} onClick={handleExpandMenu} icon={'more_vert'} fab />
        </div>
        <div className={styles.menuWrapper}>
          <div className={menuExpand ? styles.menuShow : styles.menuHide}>
            {/*<div className={styles.itemCard} onClick={handlePausePackage}>*/}
            {/*  <div className={styles.label}>Pause Package</div>*/}
            {/*</div>*/}
            <div className={styles.itemCard} onClick={handleUnassignedPackage}>
              <div className={styles.label}>Unassign Package</div>
            </div>
            {/*<div className={styles.itemCard} onClick={handleBookAppointment}>*/}
            {/*  <div className={styles.label}>Book Appointment</div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageAssignmentActionMenu;
