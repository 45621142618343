import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { ClientListColumnsSettings, defaultColumnSettings } from 'utils/providers/ClientListColumnsProvider/constants';
import { getPermissionFromPath } from 'utils/hooks/GetAccountInfo/accountPackageView';

const { currentView } = getPermissionFromPath();

const initialState: { setting: ClientListColumnsSettings } = {
  setting: defaultColumnSettings[currentView]
};

export const clientListColumnsSettingsSlice = createSlice({
  name: 'clientListColumnsSettingsSlice',
  initialState,
  reducers: {
    setSetting: (state, action) => {
      state.setting = action.payload;
    }
  }
});

export const selectSetting = (state: RootState) => state.clientListColumnsSettingsSlice.setting;

export const { setSetting } = clientListColumnsSettingsSlice.actions;

export default clientListColumnsSettingsSlice.reducer;
