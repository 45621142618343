import { OptionInterface } from 'components/Select/T23Select/T23Select';

export enum S1hSpecialisations {
  Addiction = 'addiction',
  AdjustmentDisorder = 'adjustmentDisorder',
  AlcoholAndOtherDrugs = 'alcoholAndOtherDrugs',
  AngerManagement = 'angerManagement',
  Anxiety = 'anxiety',
  AttentionDeficitHyperactivityDisorder = 'attentionDeficitHyperactivityDisorder',
  Autism = 'autism',
  BipolarAffectiveDisorder = 'bipolarAffectiveDisorder',
  BodyDysmorphia = 'bodyDysmorphia',
  BorderlinePersonalityDisorder = 'borderlinePersonalityDisorder',
  Burnout = 'burnout',
  Career = 'career',
  ChronicPain = 'chronicPain',
  Communication = 'communication',
  Confidence = 'confidence',
  DepressionAndMood = 'depressionAndMood',
  DissociativeDisorders = 'dissociativeDisorders',
  DomesticViolence = 'domesticViolence',
  Dyslexia = 'dyslexia',
  EatingDisorders = 'eatingDisorders',
  Family = 'family',
  FertilityAPerinatal = 'fertilityAPerinatal',
  FinancialStress = 'financialStress',
  GamblingAddiction = 'gamblingAddiction',
  GenderAndSexualIdentity = 'genderAndSexualIdentity',
  GriefAndBereavement = 'griefAndBereavement',
  HealthAndLifestyle = 'healthAndLifestyle',
  InfantSleep = 'infantSleep',
  LifeTransition = 'lifeTransition',
  Loneliness = 'loneliness',
  LowMood = 'lowMood',
  MeaningValue = 'meaningValue',
  Narcissism = 'narcissism',
  NewParent = 'newParent',
  Ocd = 'ocd',
  PainManagement = 'painManagement',
  PanicAttacks = 'panicAttacks',
  ParentTherapy = 'parentTherapy',
  Perfectionism = 'perfectionism',
  Performance = 'performance',
  PerinatalAndPostnatalDepressionAndAnxiety = 'perinatalAndPostnatalDepressionAndAnxiety',
  PersonalityDisorder = 'personalityDisorder',
  Phobias = 'phobias',
  PornographySexualDifficulties = 'pornographySexualDifficulties',
  Ptsd = 'ptsd',
  RelationshipCounselling = 'relationshipCounselling',
  RiskyBehaviours = 'riskyBehaviours',
  SelfDevelopment = 'selfDevelopment',
  SelfEsteem = 'selfEsteem',
  SexualAbuse = 'sexualAbuse',
  SexualHealth = 'sexualHealth',
  SleepInsomnia = 'sleepInsomnia',
  SmokingCessation = 'smokingCessation',
  SocialAnxiety = 'socialAnxiety',
  StressManagement = 'stressManagement',
  Tinnitus = 'tinnitus',
  TraumaAndPtsd = 'traumaAndPtsd',
  Violence = 'violence',
  WeightManagement = 'weightManagement',
  WorkplaceIncidentTherapy = 'workplaceIncidentTherapy',
  WorkplaceRelations = 'workplaceRelations',
  WorkStress = 'workStress'
}

/**
 * Temporary usage
 * Currently we stored `secondarySpecialisations` as label
 * Later need to migrate the label to key
 */
export const S1hSpecialisationsKeyLabelMapping = {
  [S1hSpecialisations.Addiction]: 'Addiction',
  [S1hSpecialisations.AdjustmentDisorder]: 'Adjustment disorder',
  [S1hSpecialisations.AlcoholAndOtherDrugs]: 'Alcohol and Other Drugs (AOD)',
  [S1hSpecialisations.AngerManagement]: 'Anger Management',
  [S1hSpecialisations.Anxiety]: 'Anxiety',
  [S1hSpecialisations.AttentionDeficitHyperactivityDisorder]: 'Attention Deficit Hyperactivity Disorder (ADHD)',
  [S1hSpecialisations.Autism]: 'Autism',
  [S1hSpecialisations.BipolarAffectiveDisorder]: 'Bipolar affective disorder',
  [S1hSpecialisations.BodyDysmorphia]: 'Body Dysmorphia',
  [S1hSpecialisations.BorderlinePersonalityDisorder]: 'Borderline Personality Disorder',
  [S1hSpecialisations.Burnout]: 'Burnout',
  [S1hSpecialisations.Career]: 'Career',
  [S1hSpecialisations.ChronicPain]: 'Chronic Pain',
  [S1hSpecialisations.Communication]: 'Communication',
  [S1hSpecialisations.Confidence]: 'Confidence',
  [S1hSpecialisations.DepressionAndMood]: 'Depression & Mood',
  [S1hSpecialisations.DissociativeDisorders]: 'Dissociative disorders',
  [S1hSpecialisations.DomesticViolence]: 'Domestic Violence',
  [S1hSpecialisations.Dyslexia]: 'Dyslexia',
  [S1hSpecialisations.EatingDisorders]: 'Eating Disorders',
  [S1hSpecialisations.Family]: 'Family',
  [S1hSpecialisations.FertilityAPerinatal]: 'Fertility & Perinatal',
  [S1hSpecialisations.FinancialStress]: 'Financial stress',
  [S1hSpecialisations.GamblingAddiction]: 'Gambling Addiction',
  [S1hSpecialisations.GenderAndSexualIdentity]: 'Gender & Sexual Identity',
  [S1hSpecialisations.GriefAndBereavement]: 'Grief & Bereavement',
  [S1hSpecialisations.HealthAndLifestyle]: 'Health & Lifestyle',
  [S1hSpecialisations.InfantSleep]: 'Infant Sleep',
  [S1hSpecialisations.LifeTransition]: 'Life Transition',
  [S1hSpecialisations.Loneliness]: 'Loneliness',
  [S1hSpecialisations.LowMood]: 'Low mood',
  [S1hSpecialisations.MeaningValue]: 'Meaning & value',
  [S1hSpecialisations.Narcissism]: 'Narcissism',
  [S1hSpecialisations.NewParent]: 'New Parent',
  [S1hSpecialisations.Ocd]: 'OCD',
  [S1hSpecialisations.PainManagement]: 'Pain Management',
  [S1hSpecialisations.PanicAttacks]: 'Panic attacks',
  [S1hSpecialisations.ParentTherapy]: 'Parent therapy',
  [S1hSpecialisations.Perfectionism]: 'Perfectionism',
  [S1hSpecialisations.Performance]: 'Performance',
  [S1hSpecialisations.PerinatalAndPostnatalDepressionAndAnxiety]: 'Perinatal and postnatal depression and anxiety',
  [S1hSpecialisations.PersonalityDisorder]: 'Personality disorder',
  [S1hSpecialisations.Phobias]: 'Phobias',
  [S1hSpecialisations.PornographySexualDifficulties]: 'Pornography/ sexual difficulties',
  [S1hSpecialisations.Ptsd]: 'PTSD',
  [S1hSpecialisations.RelationshipCounselling]: 'Relationship Counselling',
  [S1hSpecialisations.RiskyBehaviours]: 'Risky behaviours',
  [S1hSpecialisations.SelfDevelopment]: 'Self development',
  [S1hSpecialisations.SelfEsteem]: 'Self esteem',
  [S1hSpecialisations.SexualAbuse]: 'Sexual Abuse',
  [S1hSpecialisations.SexualHealth]: 'Sexual health',
  [S1hSpecialisations.SleepInsomnia]: 'Sleep/ Insomnia',
  [S1hSpecialisations.SmokingCessation]: 'Smoking cessation',
  [S1hSpecialisations.SocialAnxiety]: 'Social anxiety',
  [S1hSpecialisations.StressManagement]: 'Stress Management',
  [S1hSpecialisations.Tinnitus]: 'Tinitus',
  [S1hSpecialisations.TraumaAndPtsd]: 'Trauma & PTSD',
  [S1hSpecialisations.Violence]: 'Violence',
  [S1hSpecialisations.WeightManagement]: 'Weight Management',
  [S1hSpecialisations.WorkplaceIncidentTherapy]: 'Workplace incident therapy',
  [S1hSpecialisations.WorkplaceRelations]: 'Workplace relations',
  [S1hSpecialisations.WorkStress]: 'Work stress'
};

export const S1hSpecialisationOptionList: OptionInterface[] = Object.values(S1hSpecialisations).map((item) => ({
  value: item,
  label: S1hSpecialisationsKeyLabelMapping[item]
}));

export const SpecialisationOptionList: OptionInterface[] = Object.values(S1hSpecialisations)
  .filter((item) => item !== S1hSpecialisations.Addiction)
  .map((item) => ({
    value: item,
    label: S1hSpecialisationsKeyLabelMapping[item]
  }));

// Uses overlapping values from S1hSpecialisations, if removed from above update below
export enum SelectSpecialisations {
  Abuse = 'abuse',
  AcquiredBrainInjury = 'acquiredBrainInjury',
  AdjustmentDisorder = 'adjustmentDisorder',
  AngerManagement = 'angerManagement',
  Anxiety = 'anxiety',
  Assertiveness = 'assertiveness',
  AttachmentDisorder = 'attachmentDisorder',
  AttentionDeficitHyperactivityDisorder = 'attentionDeficitHyperactivityDisorder',
  AutismSpectrumDisorder = 'autism',
  Bereavement = 'bereavement',
  BipolarDisorder = 'bipolarAffectiveDisorder',
  BodyImage = 'bodyImage',
  Burnout = 'burnout',
  ChallengingBehaviours = 'challengingBehaviours',
  ChronicFatigueSyndrome = 'chronicFatigueSyndrome',
  ChronicPain = 'chronicPain',
  CopingWithCancer = 'copingWithCancer',
  Dementia = 'dementia',
  Depression = 'depression',
  DissociativeDisorders = 'dissociativeDisorders',
  DomesticViolence = 'domesticViolence',
  EatingDisorders = 'eatingDisorders',
  Family = 'family',
  GriefAndLoss = 'griefAndLoss',
  LowMood = 'lowMood',
  LowSelfEsteem = 'lowSelfEsteem',
  MaternalMentalHealth = 'maternalMentalHealth',
  Menopause = 'menopause',
  NeurodegenerativeDisease = 'neurodegenerativeDisease',
  ObsessiveCompulsiveDisorder = 'ocd',
  PanicAttacks = 'panicAttacks',
  Perfectionism = 'perfectionism',
  PersonalityDisorder = 'personalityDisorder',
  Phobias = 'phobias',
  PostTraumaticStressDisorder = 'ptsd',
  RelationshipProblems = 'relationshipProblems',
  SexualDifficulties = 'sexualDifficulties',
  SelfHarm = 'selfHarm',
  SleepProblems = 'sleepProblems',
  Stress = 'stress',
  TraumaticExperiences = 'traumaticExperiences',
  WeightManagement = 'weightManagement',
  Worry = 'worry'
}

export const SelectSpecialisationsKeyLabelMapping = {
  [SelectSpecialisations.Abuse]: 'Abuse',
  [SelectSpecialisations.AcquiredBrainInjury]: 'Acquired Brain Injury (ABI)',
  [SelectSpecialisations.AdjustmentDisorder]: 'Adjustment Disorder',
  [SelectSpecialisations.AngerManagement]: 'Anger Management',
  [SelectSpecialisations.Anxiety]: 'Anxiety',
  [SelectSpecialisations.Assertiveness]: 'Assertiveness',
  [SelectSpecialisations.AttachmentDisorder]: 'Attachment Disorder',
  [SelectSpecialisations.AttentionDeficitHyperactivityDisorder]: 'Attention Deficit Hyperactivity Disorder (ADHD)',
  [SelectSpecialisations.AutismSpectrumDisorder]: 'Autism Spectrum Disorder (ASD)',
  [SelectSpecialisations.Bereavement]: 'Bereavement',
  [SelectSpecialisations.BipolarDisorder]: 'Bi Polar Disorder',
  [SelectSpecialisations.BodyImage]: 'Body Image',
  [SelectSpecialisations.Burnout]: 'Burn Out',
  [SelectSpecialisations.ChallengingBehaviours]: 'Challenging or Risky Behaviours',
  [SelectSpecialisations.ChronicFatigueSyndrome]: 'Chronic Fatigue Syndrome',
  [SelectSpecialisations.ChronicPain]: 'Chronic Pain',
  [SelectSpecialisations.CopingWithCancer]: 'Coping With Cancer',
  [SelectSpecialisations.Dementia]: 'Dementia',
  [SelectSpecialisations.Depression]: 'Depression',
  [SelectSpecialisations.DissociativeDisorders]: 'Dissociation',
  [SelectSpecialisations.DomesticViolence]: 'Domestic Volence',
  [SelectSpecialisations.EatingDisorders]: 'Eating Issues',
  [SelectSpecialisations.GriefAndLoss]: 'Grief And Loss',
  [SelectSpecialisations.Family]: 'Family',
  [SelectSpecialisations.LowMood]: 'Low mood',
  [SelectSpecialisations.LowSelfEsteem]: 'Low Self Esteem',
  [SelectSpecialisations.MaternalMentalHealth]: 'Maternal Mental Health',
  [SelectSpecialisations.Menopause]: 'Menopause',
  [SelectSpecialisations.NeurodegenerativeDisease]: 'Neurodegenrative Disease',
  [SelectSpecialisations.ObsessiveCompulsiveDisorder]: 'Obsessive Compulsive Disorder (OCD)',
  [SelectSpecialisations.PanicAttacks]: 'Panic Attacks',
  [SelectSpecialisations.PersonalityDisorder]: 'Personality Disorders',
  [SelectSpecialisations.Perfectionism]: 'Perfectionism',
  [SelectSpecialisations.Phobias]: 'Phobias',
  [SelectSpecialisations.PostTraumaticStressDisorder]: 'Post Traumatic Stress Disorder (PTSD)',
  [SelectSpecialisations.RelationshipProblems]: 'Relationship Problems',
  [SelectSpecialisations.SexualDifficulties]: 'Sexual Difficulties',
  [SelectSpecialisations.SelfHarm]: 'Self Harm',
  [SelectSpecialisations.SleepProblems]: 'Sleep Problems',
  [SelectSpecialisations.Stress]: 'Stress',
  [SelectSpecialisations.TraumaticExperiences]: 'Traumatic Experiences',
  [SelectSpecialisations.WeightManagement]: 'Weight Management',
  [SelectSpecialisations.Worry]: 'Worry'
};

export const SelectSpecialisationOptionList: OptionInterface[] = Object.values(SelectSpecialisations).map((item) => ({
  value: item,
  label: SelectSpecialisationsKeyLabelMapping[item]
}));
