export const COMMUNICATION_OPTIONS = [
  { label: 'No Preference', value: 'noPreference' },
  { label: 'Only send SMS, no E-mail', value: 'sms' },
  { label: 'Only send E-mail, no SMS', value: 'email' },
  { label: 'Don’t send either SMS or E-mail', value: 'none' }
];

export const REFERRAL_SOURCE_TAGS = [
  'GP',
  'Website',
  'Employer',
  'School',
  'Inbound call',
  'Social media',
  'Community',
  'Client',
  'Psychiatrist/Psychologist',
  'Insurance company',
  'Health Insurer',
  'A web search',
  'Friend / Family',
  'Social Media',
  'Community group or charity',
  'Other Psychologist / Psychiatrist',
  'Other healthcare service',
  'Other'
];

export const PROFILE_TYPE_OPTIONS = [
  { label: 'Adult', value: 'adult' },
  { label: 'Young Person', value: 'youngPerson' },
  { label: 'Child', value: 'child' },
  { label: 'Couple', value: 'couple' }
];

export const CLIENT_ACCOUNT = [
  { label: 'Yes, send invite', value: 'full' },
  { label: 'No', value: 'recordOnly' }
];

export const maxGuardianField = 5;

export const ROLE_TYPE_OPTIONS = [
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
  { label: 'Step Mother', value: 'stepMother' },
  { label: 'Step Father', value: 'stepFather' },
  { label: 'Grandparent', value: 'grandparent' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Sibling', value: 'sibling' },
  { label: 'Teacher', value: 'teacher' },
  { label: 'Case Worker', value: 'caseWorker' },
  { label: 'Other', value: 'other' }
];
