import { useEffect } from 'react';
import { Skeleton } from 'antd';

import { ProfileRole, ClientRecordType, CommunicationPreference } from 'interfaces/Clients/clientsRecord';

import { handleCopyContent } from 'utils/CopyFeature';
import { getName } from 'utils/general';
import { useFetchAllClientProfileEncryptDetails } from 'utils/hooks/GetClient/clientDetails';
import { useGetAccessToken } from 'utils/hooks/token';

import styles from './ContactDetails.module.scss';

interface ContactDetailsProps {
  clientRecordId: string;
  visible: boolean;
}

const communicationPreferenceText = {
  [CommunicationPreference.NoPreference]: 'No preference set',
  [CommunicationPreference.Email]: 'Email only',
  [CommunicationPreference.SMS]: 'SMS only',
  [CommunicationPreference.None]: 'No communication'
};

const ContactDetails = ({ clientRecordId, visible }: ContactDetailsProps) => {
  const { token } = useGetAccessToken();

  const { clientEncryptDetails, clientEncryptDetailsLoading, fetchClientEncryptDetails } =
    useFetchAllClientProfileEncryptDetails(token, clientRecordId, false);

  useEffect(() => {
    if (!clientEncryptDetails._id && visible && token) {
      fetchClientEncryptDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, visible]);

  const clientProfiles =
    clientEncryptDetails.recordType === ClientRecordType.Child
      ? clientEncryptDetails.clientProfiles.filter((profile) => profile.role !== ProfileRole.Child)
      : clientEncryptDetails.clientProfiles;

  return clientEncryptDetailsLoading || !clientEncryptDetails._id ? (
    <div className={styles.loading}>
      <Skeleton.Input className={styles.skeleton} active />
      <Skeleton.Input className={styles.skeleton} active />
    </div>
  ) : (
    <div className={styles.container}>
      {clientProfiles.map(({ firstName, lastName, name, email, mobileNumber, communicationPreference }, index) => (
        <div key={index}>
          <div className={styles.name}>{getName({ firstName, lastName, name })}</div>
          {mobileNumber && (
            <div className={styles.mobileNumber} onClick={() => handleCopyContent(mobileNumber)}>
              <i className={`material-icons ${styles.iconLeft}`}>call</i>
              <div className={styles.value}>{mobileNumber}</div>
              <i className={`material-icons-outlined ${styles.iconRight}`}>content_copy</i>
            </div>
          )}
          {email && (
            <div className={styles.email} onClick={() => handleCopyContent(email)}>
              <i className={`material-icons-outlined ${styles.iconLeft}`}>email</i>
              <div className={styles.value}>{email}</div>
              <i className={`material-icons-outlined ${styles.iconRight}`}>content_copy</i>
            </div>
          )}
          {!mobileNumber && !email && <div className={styles.noContactDetails}>No contact details</div>}
          {communicationPreference && (
            <>
              <div className={styles.communicationPreferenceLabel}>Communication Preference</div>
              <div className={styles.communicationPreference}>
                {communicationPreferenceText[communicationPreference]}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ContactDetails;
