import { Route, Routes, Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import styles from './Mentor.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import MentorAboutYou from './components/MentorAboutYou/MentorAboutYou';
import MentorWorkingSchedule from './components/MentorWorkingSchedule/MentorWorkingSchedule';
import MemberDetail from './components/Members/components/MemberDetail/MemberDetail';
import { useFetchProfile } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import MentorAppointmentList from './components/Members/components/MentorAppointmentList/MentorAppointmentList';

interface subMenuProps {
  pathName: string;
  name: string;
  desc: string;
  iconName: string;
  active: boolean;
  pathLink: string;
}
const subMenu = [
  {
    pathName: 'clients',
    name: 'Your members',
    desc: 'Members assigned to you',
    iconName: 'group'
  },
  {
    pathName: 'about-you',
    name: 'About you',
    desc: 'Settings, security & contact info',
    iconName: 'manage_accounts'
  },
  {
    pathName: 'schedule',
    name: 'Working Schedule',
    desc: 'Days and times, timeoff',
    iconName: 'edit_calendar'
  }
] as subMenuProps[];

const renderSubMenuItem = (index: number, { name, desc, iconName, active, pathLink }: subMenuProps) => {
  return (
    <Link to={pathLink} key={index} className={styles.subMenuWrapper}>
      <div
        className={classNames(styles.submenu, {
          [styles.active]: active
        })}
      >
        <div className={styles.iconContainer}>
          <span className="material-icons-outlined">{iconName}</span>
        </div>
        <div>
          <div className={styles.name}>{name}</div>
          <div className={styles.subname}>{desc}</div>
        </div>
      </div>
    </Link>
  );
};

const Mentor = () => {
  const { MENTOR } = useRoutesGenerator();
  const { tab = '' } = useParams<{ tab: string }>();
  const { profile, isProfileLoading, setProfile } = useFetchProfile();

  const renderContent = () => {
    switch (tab) {
      case 'clients':
        return (
          <Routes>
            <Route index element={<MentorAppointmentList profile={profile} />} />
            <Route path={`/:clientId`} element={<MemberDetail profile={profile} />} />
          </Routes>
        );
      case 'schedule':
        return <MentorWorkingSchedule profile={profile} onUpdateProfile={setProfile} />;
      case 'about-you':
        return <MentorAboutYou profile={profile} onUpdateProfile={setProfile} />;
    }
  };

  return (
    <HelmetWrapper title="Tacklit - Mentor">
      <div className={styles.container}>
        {isProfileLoading ? (
          <div className={styles.loadingWrapper}>
            <LoadingDot />
          </div>
        ) : (
          <ContentLayout className={styles.layout}>
            <div className={styles.submenuContainer}>
              {subMenu
                .filter((obj) => obj.pathName)
                .map((menu, index) =>
                  renderSubMenuItem(index, {
                    ...menu,
                    active: tab === menu.pathName,
                    pathLink: `${MENTOR.BASE}/${menu.pathName}`
                  })
                )}
            </div>
            <div className={styles.content}>{renderContent()}</div>
          </ContentLayout>
        )}
      </div>
    </HelmetWrapper>
  );
};
export default Mentor;
