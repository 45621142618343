export const EMOJI_MAP = {
  happy: '😄',
  grateful: '🤗',
  loved: '🥰',
  respected: '🙇',
  thoughtful: '🤔',
  hopeful: '🤞',
  nervous: '😧',
  anxious: '😰',
  worried: '😟',
  angry: '😠',
  tired: '😴',
  frustrated: '😤',
  bored: '🥱',
  lonely: '😶',
  mischievous: '😜',
  strong: '💪',
  flat: '😒',
  upset: '😢',
  unwell: '🤒',
  frowning: '☹️',
  slightly_frowning: '🙁',
  neutral: '😐',
  slightly_smile: '🙂',
  smiley: '😃'
} as Record<string, string>;
