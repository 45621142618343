import { useEffect, useMemo, useState } from 'react';
import { Popconfirm } from 'antd';
import CapabilityForm from '../../components/CapabilityForm/CapabilityForm';
import { ClientDetails, newClientForm } from '../../AddPatientModalV2Interface';
import MaterialInput from '../../../MaterialInput/MaterialInput';
import ChildOrYoungClientForm from './components/ChildClientForm/ChildClientForm';
import ChildGuardianForm from './components/ChildGuardianForm/ChildGuardianForm';
import { v4 as uuid } from 'uuid';

import styles from './ChildForm.module.scss';
import classnames from 'classnames';
import { useGetAccessToken } from 'utils/hooks/token';
import { debounce } from 'lodash';
import { validateCaseId } from '../AdultForm/components/AdultClientForm/validation/AdultClientFormValidation';
import { maxGuardianField, REFERRAL_SOURCE_TAGS } from 'components/AddPatientModalV2/constants';
import SearchableSelect, { OptionInterface } from 'components/Select/SearchableSelect/SearchableSelect';
import { useTranslation } from 'react-i18next';
import { ClientAddress } from 'interfaces/Clients/clientsRecord';

interface ChildFormProps {
  clientDetail: newClientForm['clientProfiles'];
  clientCapability: newClientForm['clientCapability'];
  referralSourceTags: newClientForm['referralSourceTags'];
  referralDetail: newClientForm['referralDetail'];
  caseIdValue: newClientForm['caseId'];
  onChangeCaseId: (value: newClientForm['caseId']) => void;
  onChangeClientField: any;
  onChangeClientCapability: any;
  onChangeReferral: (value: string[]) => void;
  onChangeReferralDetail: (value: string) => void;
  checkValidation: boolean;
  clientRecordId?: string;
  isOnboardingListExist: boolean;
  isContentListExist: boolean;
  defaultOnBoardingId: string;
  defaultConsentId: string;
  defaultAssessmentId: string;
  clientAddress: ClientAddress;
  onChangeClientAddress: (address: ClientAddress) => void;
  onPlaceSelected: (address: ClientAddress) => void;
}

const ChildForm = ({
  clientDetail,
  clientCapability,
  referralSourceTags,
  referralDetail,
  caseIdValue,
  onChangeCaseId,
  onChangeClientField,
  onChangeClientCapability,
  onChangeReferral,
  onChangeReferralDetail,
  checkValidation,
  clientRecordId,
  isOnboardingListExist,
  isContentListExist,
  defaultOnBoardingId,
  defaultConsentId,
  defaultAssessmentId,
  clientAddress,
  onChangeClientAddress,
  onPlaceSelected
}: ChildFormProps) => {
  const { token } = useGetAccessToken();
  const [duplicateCaseId, setDuplicateCaseId] = useState(false);
  const [isCheckingCaseIdDuplicate, setIsCheckingCaseIdDuplicate] = useState(false);

  const selected = REFERRAL_SOURCE_TAGS.filter((option) => referralSourceTags?.includes(option)).map((item) => ({
    _id: item,
    label: item
  }));
  const [selectedReferralSourceTags, setSelectedReferralSourceTags] = useState<OptionInterface[]>(selected || []);

  useEffect(() => {
    if (checkValidation && caseIdValue && caseIdValue.length > 0) {
      validateDuplicateCaseId(caseIdValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const handleChangeClientField = (val: ClientDetails) => {
    const newClientField = [];
    for (let obj of clientDetail) {
      newClientField.push({
        ...obj,
        firstName: obj._id === val._id ? val.firstName : obj.firstName,
        lastName: obj._id === val._id ? val.lastName : obj.lastName,
        isPrimaryContact: obj._id === val._id ? val.isPrimaryContact : obj.isPrimaryContact,
        ...(obj.role === 'child' && {
          dateOfBirth: obj._id === val._id ? val.dateOfBirth : obj.dateOfBirth
        }),
        ...(obj.role !== 'child' && {
          role: obj._id === val._id ? val.role : obj.role,
          email: obj._id === val._id ? val.email : obj.email,
          mobileNumber: obj._id === val._id ? val.mobileNumber : obj.mobileNumber
        }),
        ...(obj.role !== 'child' && {
          communicationPreference: obj._id === val._id ? val.communicationPreference : obj.communicationPreference
        })
      });
    }
    onChangeClientField(newClientField);
  };

  const debouncedCheckCaseIdDuplicate = useMemo(
    () =>
      debounce(async (value) => {
        const resDupCaseId = await validateCaseId(token, value, clientRecordId);
        setDuplicateCaseId(resDupCaseId.statusCode !== 200);
        setIsCheckingCaseIdDuplicate(false);
      }, 1000),
    [token, clientRecordId]
  );

  const validateDuplicateCaseId = async (caseIdValue: string) => {
    if (token) {
      setIsCheckingCaseIdDuplicate(true);
      await debouncedCheckCaseIdDuplicate(caseIdValue);
    }
  };

  const handleCaseIdChange = async (caseIdValue: string) => {
    onChangeCaseId(caseIdValue);
    if (checkValidation && caseIdValue.length > 0) {
      await validateDuplicateCaseId(caseIdValue);
    } else {
      setDuplicateCaseId(false);
    }
  };

  const handleAddNewGuardian = () => {
    if (clientDetail.length <= maxGuardianField) {
      const newClientProfile = [
        ...clientDetail,
        {
          _id: uuid(),
          firstName: '',
          lastName: '',
          email: '',
          mobileNumber: '',
          onboardingSurvey: '',
          consentFormId: '',
          isPrimaryContact: false,
          role: '',
          onboarding: {
            onboardingSurveyIds: [defaultOnBoardingId] ?? [],
            isCheckOnBoarding: isOnboardingListExist ?? true,
            onboardingMethod: 'facilitated'
          },
          consentForm: {
            isCheckOnConsentForm: isContentListExist ?? true,
            consentFormIds: [defaultConsentId] ?? []
          },
          assessmentForm: {
            isCheckOnAssessmentForm: true,
            assessmentFormIds: [defaultAssessmentId] ?? []
          },
          communicationPreference: 'noPreference'
        }
      ];
      onChangeClientField(newClientProfile);
    }
  };

  const handleRemoveGuardian = (removeId: string) => {
    if (clientDetail.length >= 2) {
      const removeByIdList = clientDetail.filter((q) => q._id !== removeId);
      const childProfile = removeByIdList.filter((cp) => cp.role === 'child');
      const guardianProfile = removeByIdList.filter((cp) => cp.role !== 'child');

      const newGuardianField = [];
      for (let obj of guardianProfile) {
        newGuardianField.push({
          ...obj,
          isPrimaryContact: guardianProfile.length < 2 ? true : obj.isPrimaryContact
        });
      }
      const massageField = [...childProfile, ...newGuardianField];
      onChangeClientField(massageField);
    }
  };

  const onSelectReferralSourceTags = (selectedOption: OptionInterface) => {
    let newReferralSourceTags = [];
    if (selectedReferralSourceTags.some((item) => item._id === selectedOption._id)) {
      newReferralSourceTags = selectedReferralSourceTags.filter((item) => item._id !== selectedOption._id);
    } else {
      newReferralSourceTags = [...selectedReferralSourceTags, selectedOption];
    }
    setSelectedReferralSourceTags(newReferralSourceTags);
    onChangeReferral(newReferralSourceTags.map((item) => item._id));
  };

  const totalGuardian = clientDetail.filter((obj) => obj.role !== 'child').length;
  const [t] = useTranslation();

  return (
    <>
      {/* Selected Referral Source */}
      {selectedReferralSourceTags.length > 0 && (
        <div className={styles.selectedReferralTypessContainer}>
          {selectedReferralSourceTags.map((referralType, index) => (
            <div key={`referralSource${index}`} className={styles.pill}>
              {referralType.label}
            </div>
          ))}
        </div>
      )}
      {/* Referral Source dropdown */}
      <div className={styles.dropdownContainer}>
        <SearchableSelect
          className={styles.rsDropdown}
          label={'Select Referral Source'}
          options={REFERRAL_SOURCE_TAGS.map((item) => ({ _id: item, label: item }))}
          searchBoxPlaceholder={'Select Source'}
          isMultipleChoice
          selectedOptionsProps={selectedReferralSourceTags}
          onSelecteOptionProps={onSelectReferralSourceTags}
          loadingEnable
        />
      </div>
      <div className={styles.fieldContainer}>
        <MaterialInput
          id={`referralDetail`}
          label={'Referral Detail'}
          value={referralDetail}
          onChange={(event) => {
            onChangeReferralDetail(event.target.value);
          }}
          required
        />
      </div>
      <div className={classnames(styles.fieldContainer, checkValidation && duplicateCaseId && styles.fieldError)}>
        <MaterialInput
          id={`clientCaseId`}
          label={t('form.client_reference')}
          onChange={(e) => handleCaseIdChange(e.target.value)}
          isLoading={isCheckingCaseIdDuplicate}
          value={caseIdValue}
          maxLength={20}
          required
        />
        {checkValidation && duplicateCaseId && (
          <div className={styles.fieldError}>{t('form.error.duplicate_client_reference')}</div>
        )}
      </div>
      <ChildOrYoungClientForm
        id={'childField'}
        recordType={'child'}
        profileType={clientCapability.profileType}
        clientDetail={clientDetail.find((obj) => obj.role === 'child') || clientDetail[0]}
        onChangeClientField={handleChangeClientField}
        checkValidation={checkValidation}
        clientAddress={clientAddress}
        onChangeClientAddress={onChangeClientAddress}
        onPlaceSelected={onPlaceSelected}
      />
      {clientDetail
        .filter((obj) => obj.role !== 'child')
        .map((obj, index) => (
          <div className={styles.guardianContainer} key={index}>
            <div className={styles.guardianHeader}>
              <div className={styles.guardianTitle}>PARENT OR GUARDIAN {totalGuardian > 1 && index + 1}</div>
              {index > 0 && (
                <Popconfirm
                  title="Are you sure to remove this Parent or Guardian?"
                  onConfirm={() => handleRemoveGuardian(obj._id)}
                  okText="Yes"
                  cancelText="No"
                  placement={'bottomRight'}
                >
                  <div className={`material-icons-outlined ${styles.removeBtn}`}>close</div>
                </Popconfirm>
              )}
            </div>
            <ChildGuardianForm
              id={`Guardian${index}`}
              key={index}
              clientDetail={obj}
              onChangeClientField={handleChangeClientField}
              checkValidation={checkValidation}
              disablePrimaryContactBtn={index === 0}
              updateDetail={clientDetail.length}
            />
          </div>
        ))}
      {clientDetail.length <= maxGuardianField && (
        <div className={styles.addGuardianBtn} onClick={() => handleAddNewGuardian()}>
          <i className={`material-icons-outlined ${styles.icon}`}>add</i>
          Add another parent or guardian
        </div>
      )}
      <CapabilityForm
        capabilityData={clientCapability}
        onChangeCapabilityField={onChangeClientCapability}
        recordOnly
        recordDesc={'Child profiles have additional protection'}
      />
    </>
  );
};

export default ChildForm;
