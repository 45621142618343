import { Fax, FaxTag } from 'interfaces/fax';
import queryString from 'query-string';
import { notificationServicesApiSlice } from 'redux/services/notificationServicesApiSlice';

export interface ISendEFaxRequestPayload {
  accountId: string;
  reportId: string;
  payload: {
    sendAt: string;
    sendFrom: string;
    sendTo: {
      faxNumber: string;
      recipientName?: string;
    }[];
    printPayload?: Node;
  };
}

export interface GetFaxesResponse {
  faxes: Fax[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export interface GetFaxesRequestPayload {
  accountId: string;
  params: {
    clientRecordIds?: string[];
    includeFileMetadata?: boolean;
    page: number;
    perPage: number;
    sortByCreatedAt?: 1 | -1;
    statuses?: string[];
    tags?: string[];
  };
}

export interface UpdateFaxRequestPayload {
  accountId: string;
  faxId: string;
  payload: { tag?: FaxTag; clientRecordId?: string };
}

export const eFaxApiSlice = notificationServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendEFax: builder.mutation({
      query: ({ accountId, reportId, payload }: ISendEFaxRequestPayload) => ({
        url: `/accounts/${accountId}/reports/${reportId}/faxes`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['Fax']
    }),
    updateFax: builder.mutation({
      query: ({ accountId, faxId, payload }: UpdateFaxRequestPayload) => ({
        url: `/accounts/${accountId}/faxes/${faxId}`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: (_, __, arg) => [{ type: 'Fax', id: arg.faxId }]
    }),
    getFaxes: builder.query<GetFaxesResponse, GetFaxesRequestPayload>({
      query: ({ accountId, params }) => {
        const stringifiedQueryString = params ? `?${queryString.stringify(params)}` : '';
        return { url: `/accounts/${accountId}/faxes${stringifiedQueryString}` };
      },
      providesTags: (result) =>
        result?.faxes.length
          ? [...(result?.faxes.map(({ _id }) => ({ type: 'Fax' as const, id: _id })) || []), 'Fax']
          : ['Fax']
    })
  })
});

export const { useSendEFaxMutation, useUpdateFaxMutation, useGetFaxesQuery } = eFaxApiSlice;
