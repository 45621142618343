import ContentLayout from 'components/ContentLayoutT23/ContentLayoutT23';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import InvoicePaymentsList from './components/InvoicePaymentsList/InvoicePaymentsList';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  InvoicePaymentsTab,
  selectActiveTab,
  selectIsShowPartialRefundModal,
  selectPartialRefundPayment,
  selectSelectedPayment,
  setActiveTab,
  setIsShowPartialRefundModal,
  setPartialRefundPayment,
  setSelectedPayment
} from 'redux/features/invoicePayments/invoicePaymentsSlice';
import PartialRefundModal from './components/PartialRefundModal/PartialRefundModal';
import { useEffect } from 'react';

interface InvoicePaymentsProps {
  tab: InvoicePaymentsTab;
}

const InvoicePayments = ({ tab }: InvoicePaymentsProps) => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector(selectActiveTab);

  useEffect(() => {
    if (tab !== activeTab) {
      dispatch(setActiveTab(tab));
    }
  }, [tab, activeTab, dispatch]);

  const isShowPartialRefundModal = useAppSelector(selectIsShowPartialRefundModal);
  const selectedPayment = useAppSelector(selectSelectedPayment);
  const partialRefundPayment = useAppSelector(selectPartialRefundPayment);

  const onCloseModalHandle = () => {
    dispatch(setIsShowPartialRefundModal(false));
    dispatch(setSelectedPayment(undefined));
    dispatch(setPartialRefundPayment({ amount: '' }));
  };

  const onChangeRefundValue = (value: { amount: string }) => {
    dispatch(setPartialRefundPayment(value));
  };

  return (
    <HelmetWrapper title="Tacklit - Payments">
      <ContentLayout>
        <InvoicePaymentsList />

        {selectedPayment && (
          <PartialRefundModal
            showModal={isShowPartialRefundModal}
            payment={selectedPayment}
            partialRefund={partialRefundPayment}
            onCloseModal={onCloseModalHandle}
            onChangeValue={onChangeRefundValue}
          />
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InvoicePayments;
