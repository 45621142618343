import { CaseNoteField } from 'interfaces/caseNote';
import { QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';
import { CaseNoteFormatSettingsFields } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import {
  OpenDataCategory,
  OpenDataFieldVariable,
  ScaleType
} from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';
import { v4 as uuidv4 } from 'uuid';
import { getVariableOptions } from '../../PatientDetailsClientData/constants';

const mutateCaseNoteFieldWithCdfVariable = ({
  field,
  variable,
  scaleTypes
}: {
  field: CaseNoteField;
  variable: Partial<OpenDataFieldVariable>;
  scaleTypes: OpenDataFieldVariable[];
}) => {
  field.variableId = variable.variableId;
  field.name = variable.variableName || '';

  switch (variable.type) {
    case QuestionType.freeText:
      field.type = 'text';
      break;
    case QuestionType.numeric:
      field.type = 'number';
      break;
    case QuestionType.date:
      field.type = 'date';
      break;
    case QuestionType.phone:
      field.type = 'phone';
      break;
    case QuestionType.email:
      field.type = 'email';
      break;
    case QuestionType.scale:
      field.type = 'multipleChoice';
      field.multiple = variable.scaleType === ScaleType.EmojiReaction;
      field.options = getVariableOptions({
        type: variable.type,
        scaleType: variable.scaleType,
        options: scaleTypes.find((scale) => scale.scaleType === variable.scaleType)?.options
      });
      break;
    case QuestionType.multipleChoiceFreeText:
    case QuestionType.multipleChoice:
      field.type = 'multipleChoice';
      field.multiple = typeof variable.maxSelection !== 'undefined' && variable.maxSelection !== 1;
      field.options = getVariableOptions({
        type: variable.type,
        scaleType: variable.scaleType,
        options: variable.options
      });
      break;

    default:
      field.type = 'text';
  }
};

export const formatCaseNoteTemplateFields = ({
  fields,
  categories,
  scaleTypes
}: {
  fields: CaseNoteFormatSettingsFields[];
  categories: OpenDataCategory[];
  scaleTypes: OpenDataFieldVariable[];
}): CaseNoteField[] =>
  fields
    .map(
      ({
        name,
        type,
        linkType,
        choiceType,
        commonChoices,
        customChoices,
        customCommonChoices,
        active,
        mandatory,
        multiple,
        categoryId,
        variableId
      }) => {
        if (!active) {
          return undefined;
        }

        const field: CaseNoteField = {
          _id: uuidv4(),
          name,
          type: type as CaseNoteField['type'],
          mandatory,
          options: [],
          value: undefined
        };

        if (type === 'link') {
          field.linkType = linkType;
        } else if (type === 'multipleChoice' || type === 'multipleChoiceCount') {
          let options: CaseNoteField['options'] = [];

          if (choiceType === 'common') {
            options = commonChoices?.concat(customCommonChoices || []) || [];
          } else {
            options = customChoices || [];
          }

          field.multiple = multiple;
          field.options = options;
        } else if (type === 'cdf') {
          const foundVariable = categories
            .find((category) => category._id === categoryId)
            ?.questions.find((question) => question.variableId === variableId);

          if (foundVariable) {
            field.categoryId = categoryId;

            mutateCaseNoteFieldWithCdfVariable({
              field,
              variable: foundVariable,
              scaleTypes
            });
          } else {
            // default back to freetext
            field.type = 'text';
          }
        }

        return field;
      }
    )
    .filter((field): field is CaseNoteField => !!field);
