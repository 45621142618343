import { useMemo, useRef, useState } from 'react';
import { notification, Modal } from 'antd';

import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  deactivatePractitioner,
  assignRole,
  patchRequestResetClinicianPassword,
  patchClinicianPassword,
  activatePractitioner
} from 'utils/http/ClinicianProfileService/Accounts/accounts';
import _ from 'lodash';
import styles from './PractitionerAction.module.scss';
import { getUserRoles } from 'interfaces/Practitioners/practitionersListing';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import ErrorBanner from 'components/ErrorBanner/ErrorBanner';
import classNames from 'classnames';
import { handleCopyContent } from 'utils/CopyFeature';
import DropdownButton from 'components/DropdownButton/DropdownButton';

interface PractitionerActionProps {
  id: string;
  practitionerRole: string;
  practitionerName: string;
  practitionerEmail: string;
  isActive: boolean;
  fetchProfile?: () => void;
  allowResetPassword: boolean;
}

enum UpdatePasswordStatus {
  Generating = 'generating',
  Success = 'success',
  Error = 'error'
}

const PractitionerAction = ({
  id,
  practitionerRole,
  practitionerName,
  practitionerEmail,
  fetchProfile,
  allowResetPassword,
  isActive
}: PractitionerActionProps) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { isMentorFeatureToggle } = useGetFeatureToggle();
  const [t] = useTranslation();

  const USER_ROLES = useMemo(() => getUserRoles(t), [t]);

  const node = useRef<HTMLDivElement>(null);
  const [isSendingRequestResetPassword, setIsSendingRequestResetPassword] = useState(false);
  const [showUpdatePasswordMenu, setShowUpdatePasswordMenu] = useState(false);
  const [updatePasswordStatus, setUpdatePasswordStatus] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const actionItems = [
    {
      title: 'Actions...',
      submenu: [
        {
          title: 'Update Permission',
          submenu: Object.keys(USER_ROLES)
            .filter((roleKey) => roleKey !== practitionerRole && (roleKey !== 'mentor' || isMentorFeatureToggle))
            .map((role) => ({
              title: t('label.change_to_role', { role: USER_ROLES[role] }),
              onClick: () => handleConfirmChangeRoleModal(role)
            }))
        },
        ...(allowResetPassword
          ? [
              {
                title: 'Reset Password',
                submenu: [
                  {
                    title: 'Send a reset email',
                    onClick: () => handleRequestResetPasswordEmail()
                  },
                  {
                    title: 'Generate new password',
                    onClick: () => handleUpdatePasswordModal()
                  }
                ]
              }
            ]
          : []),
        {
          title: t('label.deactivate_practitioner'),
          onClick: () => handleConfirmDeactivateModal()
        }
      ]
    }
  ];

  const handleConfirmDeactivateModal = () => {
    Modal.confirm({
      title: 'Confirm message',
      content: 'Are you sure that you want to deactivate?',
      onOk: async () => {
        try {
          await deactivatePractitioner(token, accountId, id);
          notification.success({ message: t('form.success.deactivate_practitioner') });
          fetchProfile?.();
        } catch (ex) {
          console.error(ex);
          notification.error({ message: t('form.error.deactivate_practitioner') });
        }
      }
    });
  };

  const handleConfirmActivateModal = () => {
    Modal.confirm({
      title: 'Confirm message',
      content: `Are you sure that you want to activate ${practitionerName}?`,
      onOk: async () => {
        try {
          await activatePractitioner(token, accountId, id);
          notification.success({ message: `${practitionerName} successfully activated` });
          fetchProfile?.();
        } catch (ex) {
          console.error(ex);
          notification.error({ message: `Something went wrong when trying to activate ${practitionerName}` });
        }
      }
    });
  };

  const handleUpdatePasswordModal = (isSkipConfirm = false) => {
    const generateNewPassword = async () => {
      try {
        setUpdatePasswordStatus(UpdatePasswordStatus.Generating);
        setShowUpdatePasswordMenu(true);

        const response = await patchClinicianPassword(token, accountId, id);

        if (response.statusCode === 200) {
          const { password } = await response.json();

          setNewPassword(password);
          setUpdatePasswordStatus(UpdatePasswordStatus.Success);
        } else {
          setUpdatePasswordStatus(UpdatePasswordStatus.Error);
        }
      } catch (ex) {
        console.error(ex);
        setUpdatePasswordStatus(UpdatePasswordStatus.Error);
      }
    };

    if (isSkipConfirm) {
      generateNewPassword();
      return;
    }
    Modal.confirm({
      title: 'Confirm message',
      content: 'Are you sure that you want to generate new password?',
      onOk: () => {
        generateNewPassword();
      }
    });
  };

  const onCloseResetPasswordMenu = () => {
    setNewPassword('');
    setShowUpdatePasswordMenu(false);
  };

  const getUpdatePasswordStatusDesc = () => {
    switch (updatePasswordStatus) {
      case UpdatePasswordStatus.Generating:
        return <>A new password is being generated...</>;
      case UpdatePasswordStatus.Success:
        return (
          <>
            You successfully generated a new password. <i className="material-icons">check_circle</i>
          </>
        );
      case UpdatePasswordStatus.Error:
        return <>Password generation failed.</>;
      default:
        return <></>;
    }
  };

  const handleRequestResetPasswordEmail = async () => {
    if (isSendingRequestResetPassword) return;

    Modal.confirm({
      title: 'Confirm message',
      content: 'Are you sure that you want to request a password reset email?',
      onOk: async () => {
        try {
          setIsSendingRequestResetPassword(true);

          const response = await patchRequestResetClinicianPassword(token, accountId, id);

          setIsSendingRequestResetPassword(false);

          if (response.statusCode === 204) {
            notification.success({ message: `Password reset email successfully sent out to ${practitionerEmail}` });
          } else if (response.statusCode === 403) {
            notification.error({ message: 'This user is not active or no longer exists.' });
          }
        } catch (ex) {
          console.error(ex);
          notification.error({ message: 'Something went wrong while trying to request a password reset email.' });
        }
      }
    });
  };

  const handleConfirmChangeRoleModal = (newRole: string) => {
    const getRoleTranslate = USER_ROLES[newRole].toLowerCase();
    Modal.confirm({
      title: 'Confirm message',
      content: `Are you sure that you want to change ${practitionerName} to ${getRoleTranslate} role?`,
      onOk: async () => {
        onHandleChangeRole(newRole);
      }
    });
  };

  const onHandleChangeRole = async (newRole: string) => {
    try {
      await assignRole(token, accountId, id, newRole);
      notification.success({ message: t('form.success.assign_role_practitioner') });
      fetchProfile?.();
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: t('form.error.assign_role_practitioner', { role: _.startCase(newRole) })
      });
    }
  };

  return (
    <div className={styles.container} ref={node}>
      {isActive ? (
        <DropdownButton list={actionItems} />
      ) : (
        <ButtonAlt
          size={'medium'}
          variant={'outlined'}
          onClick={(e) => {
            e.stopPropagation();
            handleConfirmActivateModal();
          }}
        >
          Reactivate Practitioner Account
        </ButtonAlt>
      )}
      <Modal
        className={styles.modalContainer}
        open={showUpdatePasswordMenu}
        closeIcon={
          <i
            onClick={() => onCloseResetPasswordMenu()}
            className={classNames('material-icons-outlined', styles.closeIcon)}
          >
            close
          </i>
        }
        footer={false}
        closable={updatePasswordStatus === UpdatePasswordStatus.Error}
      >
        <div className={styles.content}>
          <div className={styles.status}>{getUpdatePasswordStatusDesc()}</div>

          {updatePasswordStatus !== UpdatePasswordStatus.Error && (
            <div className={styles.password}>
              {newPassword ? (
                <>
                  {newPassword}
                  <div className={styles.copy} onClick={() => handleCopyContent(newPassword)}>
                    Copy<i className="material-icons">content_copy</i>
                  </div>
                </>
              ) : (
                '...'
              )}
            </div>
          )}

          <div className={styles.statusDesc}>
            {updatePasswordStatus === UpdatePasswordStatus.Error ? (
              <>
                <ErrorBanner
                  icon="warning"
                  iconColor="#d54141"
                  errorMessage={<div>Something went wrong resetting this password.</div>}
                />
                <div className={styles.title}>Please try again.</div>
                <div>
                  <ButtonAlt
                    variant="outlined"
                    type={'button'}
                    icon="cached"
                    iconPostFix
                    onClick={() => handleUpdatePasswordModal(true)}
                  >
                    Retry
                  </ButtonAlt>
                  <br />
                  If you continue to encounter a problem
                  <a href="mailto:support@tacklit.com" className={styles.link}>
                    please contact us so we can look into the issue
                  </a>
                  .
                </div>
              </>
            ) : (
              <>
                <div className={styles.title}>Please treat this information sensitively.</div>
                <div>
                  Upon login this team member will need to set a new secure password.
                  <br />
                  Previous passwords will not longer be valid.
                </div>
              </>
            )}
          </div>

          {updatePasswordStatus !== UpdatePasswordStatus.Error && (
            <div className={styles.submitButtonContainer}>
              <ButtonAlt variant="outlined" type={'button'} onClick={() => onCloseResetPasswordMenu()}>
                Close
              </ButtonAlt>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PractitionerAction;
