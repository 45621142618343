import { ChangeEvent, ReactNode } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './CheckBox.module.scss';
import classnames from 'classnames';

interface AddClientCheckBoxProps {
  id: string;
  value: boolean;
  className?: string;
  inputClassName?: string;
  label?: any;
  labelClassName?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  disabledClassName?: string;
  useT23style?: boolean;
  labelTooltip?: ReactNode;
}

const CheckBox = ({
  id,
  value,
  className,
  inputClassName,
  onChange,
  label,
  labelClassName,
  disabled,
  disabledClassName,
  useT23style,
  labelTooltip
}: AddClientCheckBoxProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();

  return (
    <label className={classnames(disabled ? styles.disabledContainer : styles.container, className)} htmlFor={id}>
      <input
        disabled={disabled}
        className={classnames(
          styles.checkbox,
          disabled && disabledClassName,
          inputClassName,
          useT23style && isEdgeAdminView && styles.admin
        )}
        id={id}
        type={'checkbox'}
        checked={value}
        onChange={onChange}
      />
      {label && (
        <div className={classnames(styles.checkBoxLabel, labelClassName)}>
          {label} {labelTooltip}
        </div>
      )}
    </label>
  );
};

export default CheckBox;
