import { Dropdown, Menu, Skeleton } from 'antd';
import ParticipationToggle from 'components/SelectClientOrGroup/components/ParticipationHeader/components/ParticipationToggle/ParticipationToggle';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { SortOrder } from 'interfaces';
import { Invoice, OnChangeInvoiceStatus } from '../../interface';
import ClientInvoiceItem from './components/ClientInvoiceItem/ClientInvoiceItem';
import GroupInvoiceItem from './components/GroupInvoiceItem/GroupInvoiceItem';
import InvoiceModal from './components/InvoiceModal/InvoiceModal';
import styles from './InvoiceListing.module.scss';
import NoInvoices from 'assets/images/NoInvoices.png';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { StatusFilterType } from 'utils/hooks/useFetchInvoices';

const STATUS_FILTER_OPTIONS = [
  { label: 'All', value: StatusFilterType.All },
  { label: 'Draft', value: StatusFilterType.Draft },
  { label: 'Issued', value: StatusFilterType.Issued },
  { label: 'Overdue', value: StatusFilterType.Overdue },
  { label: 'Marked Paid', value: StatusFilterType.MarkedPaid },
  { label: 'Confirm Paid', value: StatusFilterType.ConfirmPaid },
  { label: 'Closed', value: StatusFilterType.Closed }
];

interface InvoiceListingProps {
  invoices: Invoice[];
  isInvoicesLoading: boolean;
  allowCreateNewInvoice: boolean;
  onChangeStatus: OnChangeInvoiceStatus;
  onResendInvoice: (_id: string) => void;
  invoiceIdProcessing: string;
  enableGroupSelection?: boolean;
  dateSort: SortOrder;
  setDateSort: Dispatch<SetStateAction<SortOrder>>;
  statusFilter: StatusFilterType;
  setStatusFilter: Dispatch<SetStateAction<StatusFilterType>>;
  participationType: ParticipantType;
  setParticipationType: Dispatch<SetStateAction<ParticipantType>>;
}

const InvoiceListing = ({
  invoices,
  isInvoicesLoading,
  allowCreateNewInvoice,
  onChangeStatus,
  onResendInvoice,
  invoiceIdProcessing,
  enableGroupSelection,
  dateSort,
  setDateSort,
  statusFilter,
  setStatusFilter,
  participationType,
  setParticipationType
}: InvoiceListingProps) => {
  const { INVOICES } = useRoutesGenerator();
  const { invoiceId = '' } = useParams<{ invoiceId?: string }>();

  const [selectedInvoiceId, setSelectedInvoiceId] = useState('');
  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);

  const handleDateSortClick = () => {
    if (dateSort === SortOrder.Ascending) {
      setDateSort(SortOrder.Descending);
    } else {
      setDateSort(SortOrder.Ascending);
    }
  };

  const handleInvoiceItemClick = (invoiceId: string) => () => {
    setSelectedInvoiceId(invoiceId);
    setIsInvoiceModalVisible(true);
  };

  const handleInvoiceModalClose = () => {
    setSelectedInvoiceId('');
    setIsInvoiceModalVisible(false);
  };

  useEffect(() => {
    if (invoiceId) {
      setSelectedInvoiceId(invoiceId);
      setIsInvoiceModalVisible(true);
    }
  }, [invoiceId]);
  return (
    <>
      <InvoiceModal
        invoice={invoices.find((invoice) => invoice._id === selectedInvoiceId)}
        visible={isInvoiceModalVisible}
        onChangeStatus={onChangeStatus}
        onClose={handleInvoiceModalClose}
        onResendInvoice={onResendInvoice}
        isInvoiceActionProcessing={selectedInvoiceId === invoiceIdProcessing}
      />
      <div className={styles.container}>
        {isInvoicesLoading ? (
          <Skeleton active />
        ) : invoices.length === 0 && statusFilter === StatusFilterType.All ? (
          <div className={styles.noInvoices}>
            <img className={styles.image} src={NoInvoices} alt="No Invoices" />
            <div className={styles.prompt}>
              <div className={styles.text}>No invoices created</div>
              {allowCreateNewInvoice && (
                <Link className={styles.link} to={INVOICES.NEW}>
                  <i className={`material-icons-outlined ${styles.icon}`}>add_circle_outline</i>
                  Create New Invoice
                </Link>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className={styles.filter}>
              <div className={styles.filterLabel}>Filter:</div>
              <Dropdown
                overlay={
                  <Menu onClick={(e) => setStatusFilter(e.key as StatusFilterType)}>
                    {STATUS_FILTER_OPTIONS.map((option) => (
                      <Menu.Item key={option.value}>{option.label}</Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={['click']}
              >
                <div className={styles.dropdownText}>
                  {STATUS_FILTER_OPTIONS.find((option) => option.value === statusFilter)?.label}
                  <i className={`material-icons-outlined ${styles.icon}`}>arrow_drop_down</i>
                </div>
              </Dropdown>
              {enableGroupSelection && (
                <div className={styles.toggleWrapper}>
                  <ParticipationToggle
                    selectedParticipantType={participationType}
                    onChangeParticipation={(val) => {
                      setParticipationType(val);
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles.listHeader}>
              {participationType === ParticipantType.Group ? (
                <div className={styles.nameLabel}>Name</div>
              ) : (
                <div className={styles.profile} />
              )}
              <span className={styles.invoiceId}>Invoice ID</span>
              <span className={styles.issueDate}>
                <ButtonAlt
                  contentClassName={styles.buttonContent}
                  variant="text"
                  size="small"
                  onClick={handleDateSortClick}
                  icon={dateSort === SortOrder.Ascending ? 'arrow_drop_up' : 'arrow_drop_down'}
                  iconPostFix
                >
                  Dated
                </ButtonAlt>
              </span>
              <span className={styles.description}>Description</span>
              <span className={styles.amount}>Amount</span>
              <span className={styles.status}>Status</span>
              <span className={styles.action}>Action</span>
            </div>
            {participationType === ParticipantType.Group ? (
              invoices?.length === 0 ? (
                <div className={styles.noInvoicesFilter}>
                  <div className={styles.text}>
                    No group invoices{' '}
                    {statusFilter !== StatusFilterType.All &&
                      STATUS_FILTER_OPTIONS.find((option) => option.value === statusFilter)?.label.toLowerCase()}
                  </div>
                </div>
              ) : (
                invoices?.map((invoice, index) => (
                  <GroupInvoiceItem
                    key={index}
                    invoice={invoice}
                    onChangeStatus={onChangeStatus}
                    onInvoiceClick={handleInvoiceItemClick(invoice._id)}
                    onResendInvoice={onResendInvoice}
                    isInvoiceActionProcessing={invoice._id === invoiceIdProcessing}
                  />
                ))
              )
            ) : invoices?.length === 0 ? (
              <div className={styles.noInvoicesFilter}>
                <div className={styles.text}>
                  No invoices{' '}
                  {statusFilter !== StatusFilterType.All &&
                    STATUS_FILTER_OPTIONS.find((option) => option.value === statusFilter)?.label.toLowerCase()}{' '}
                  filter
                </div>
              </div>
            ) : (
              invoices.map(
                (invoice, index) =>
                  invoice.clientRecord && (
                    <ClientInvoiceItem
                      key={index}
                      invoice={invoice}
                      onChangeStatus={onChangeStatus}
                      onResendInvoice={onResendInvoice}
                      isInvoiceActionProcessing={invoice._id === invoiceIdProcessing}
                    />
                  )
              )
            )}
          </>
        )}
      </div>
    </>
  );
};

export default InvoiceListing;
