// client name obj stored separately from columns
export interface ClientNameConfigType {
  avatar: boolean;
  refId: boolean;
}

export enum ColumnSettingsEnum {
  CLIENT_SINCE = 'clientSince',
  PROFILE_TYPE = 'profileType',
  PRACTITIONER = 'practitioner',
  GROUP = 'group',
  LAST_ACTIVITY = 'lastActivity',
  /**
   * Appointment info
   */
  NEXT_APPOINTMENT = 'nextAppointment',
  APPOINTMENT_ATTENDED = 'appointmentAttended',
  FUTURE_APPOINTMENTS = 'futureAppointments',
  /**
   * Forms data
   */
  LAST_CONSENT_SIGNED = 'lastConsentSigned',
  LAST_PSYCHOMETRIC_COMPLETED = 'lastPsychometricCompleted',
  LAST_SURVEY_COMPLETED = 'lastSurveyCompleted',
  /**
   * Referral details
   */
  REFERRAL_DATE = 'referralDate',
  REFERRAL_SOURCE_AND_DETAIL = 'referralSourceAndDetail',
  REFERRAL_STATUSES = 'referralStatuses',
  /**
   * Empty
   */
  EMPTY = '-'
}

export const CLIENT_LIST_SORT_ABLE_COLUMNS = [ColumnSettingsEnum.CLIENT_SINCE];

export const CLIENT_LIST_APPOINTMENT_STATISTICS_COLUMNS = [
  ColumnSettingsEnum.APPOINTMENT_ATTENDED,
  ColumnSettingsEnum.FUTURE_APPOINTMENTS
];

export const CLIENT_LIST_FORMS_COLUMNS = [
  ColumnSettingsEnum.LAST_CONSENT_SIGNED,
  ColumnSettingsEnum.LAST_PSYCHOMETRIC_COMPLETED,
  ColumnSettingsEnum.LAST_SURVEY_COMPLETED
];

export const CLIENT_LIST_FILTER_ABLE_COLUMNS = [
  ColumnSettingsEnum.PRACTITIONER,
  ColumnSettingsEnum.PROFILE_TYPE,
  ColumnSettingsEnum.GROUP,
  ColumnSettingsEnum.REFERRAL_SOURCE_AND_DETAIL,
  ColumnSettingsEnum.REFERRAL_STATUSES,
  ...CLIENT_LIST_FORMS_COLUMNS
];

export enum UserViewEnum {
  EDGE_ADMIN_VIEW = 'edgeAdminView',
  EDGE_USER_VIEW = 'edgeUserView',
  EDGE_RECEPTIONIST_VIEW = 'edgeReceptionistView',
  PIONEER_VIEW = 'pioneerView'
}

/**
 * client list columns default
 */
export interface ColumnConfigType {
  id: ColumnSettingsEnum;
  active: boolean;
}
const DEFAULT_EDGE_USER_COLUMNS: ColumnConfigType[] = [
  ColumnSettingsEnum.CLIENT_SINCE,
  ColumnSettingsEnum.PROFILE_TYPE,
  ColumnSettingsEnum.GROUP,
  ColumnSettingsEnum.LAST_ACTIVITY,
  ColumnSettingsEnum.APPOINTMENT_ATTENDED,
  ColumnSettingsEnum.NEXT_APPOINTMENT,
  ColumnSettingsEnum.EMPTY
].map((column) => ({
  id: column,
  active: column !== ColumnSettingsEnum.EMPTY // non-empty columns will set active = true
}));

const DEFAULT_EDGE_ADMIN_RECEPTIONIST_COLUMNS: ColumnConfigType[] = [
  ColumnSettingsEnum.PRACTITIONER,
  ColumnSettingsEnum.CLIENT_SINCE,
  ColumnSettingsEnum.PROFILE_TYPE,
  ColumnSettingsEnum.GROUP,
  ColumnSettingsEnum.LAST_ACTIVITY,
  ColumnSettingsEnum.APPOINTMENT_ATTENDED,
  ColumnSettingsEnum.NEXT_APPOINTMENT
].map((column) => ({
  id: column,
  active: column !== ColumnSettingsEnum.EMPTY // non-empty columns will set active = true
}));

const DEFAULT_PIONEER_COLUMNS: ColumnConfigType[] = [
  ColumnSettingsEnum.CLIENT_SINCE,
  ColumnSettingsEnum.PROFILE_TYPE,
  ColumnSettingsEnum.LAST_ACTIVITY,
  ColumnSettingsEnum.APPOINTMENT_ATTENDED,
  ColumnSettingsEnum.NEXT_APPOINTMENT,
  ColumnSettingsEnum.EMPTY,
  ColumnSettingsEnum.EMPTY
].map((column) => ({
  id: column,
  active: column !== ColumnSettingsEnum.EMPTY // non-empty columns will set active = true
}));

export interface ClientListColumnsSettings {
  id: string;
  view: UserViewEnum;
  clientName: ClientNameConfigType;
  columns: ColumnConfigType[];
}

const DEFAULT_EDGE_ADMIN_COLUMNS_SETTINGS: ClientListColumnsSettings = {
  id: '',
  view: UserViewEnum.EDGE_ADMIN_VIEW,
  clientName: {
    avatar: true,
    refId: true
  },
  columns: DEFAULT_EDGE_ADMIN_RECEPTIONIST_COLUMNS
};

const DEFAULT_EDGE_RECEPTIONIST_COLUMNS_SETTINGS: ClientListColumnsSettings = {
  id: '',
  view: UserViewEnum.EDGE_RECEPTIONIST_VIEW,
  clientName: {
    avatar: true,
    refId: true
  },
  columns: DEFAULT_EDGE_ADMIN_RECEPTIONIST_COLUMNS
};

const DEFAULT_EDGE_USER_COLUMNS_SETTINGS: ClientListColumnsSettings = {
  id: '',
  view: UserViewEnum.EDGE_USER_VIEW,
  clientName: {
    avatar: true,
    refId: true
  },
  columns: DEFAULT_EDGE_USER_COLUMNS
};

const DEFAULT_PIONEER_COLUMNS_SETTINGS: ClientListColumnsSettings = {
  id: '',
  view: UserViewEnum.PIONEER_VIEW,
  clientName: {
    avatar: true,
    refId: true
  },
  columns: DEFAULT_PIONEER_COLUMNS
};

/**
 * Import this object to get the default data
 */
export const defaultColumnSettings = {
  [UserViewEnum.EDGE_ADMIN_VIEW]: DEFAULT_EDGE_ADMIN_COLUMNS_SETTINGS,
  [UserViewEnum.EDGE_RECEPTIONIST_VIEW]: DEFAULT_EDGE_RECEPTIONIST_COLUMNS_SETTINGS,
  [UserViewEnum.EDGE_USER_VIEW]: DEFAULT_EDGE_USER_COLUMNS_SETTINGS,
  [UserViewEnum.PIONEER_VIEW]: DEFAULT_PIONEER_COLUMNS_SETTINGS
};
