import styles from './GroupPickAssessment.module.scss';
import classnames from 'classnames';
import { ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { Row, Col, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import ClientOrGroupPickAssessment from 'components/ClientOrGroupPickAssessment/ClientOrGroupPickAssessment';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface GroupPickAssessmentProps {
  enableChangeClientOrGroup: boolean;
  selectedGroup?: GroupsFromAPI;
  selectedAssessments: ClinicalAssessmentStruct[];
  onNextClick: () => void;
  onAddSelectedAssessment: (item: ClinicalAssessmentStruct) => void;
  onRemoveSelectedAssessment: (item: ClinicalAssessmentStruct) => void;
  onBackClick: () => void;
}

const GroupPickAssessment = ({
  enableChangeClientOrGroup,
  selectedGroup,
  selectedAssessments,
  onBackClick,
  onNextClick,
  onAddSelectedAssessment,
  onRemoveSelectedAssessment,
  className
}: GroupPickAssessmentProps & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={classnames(styles.container, className)}>
      {enableChangeClientOrGroup && (
        <Button type="text" className={styles.backBtn} onClick={() => onBackClick()}>
          <Row gutter={8} align="middle">
            <Col className={styles.backBtnIcon}>
              <LeftOutlined />
            </Col>
            <Col>change group</Col>
          </Row>
        </Button>
      )}
      <div className={styles.pickAssessment}>
        <ClientOrGroupPickAssessment
          selectedGroup={selectedGroup}
          participationType={ParticipantType.Group}
          selectedAssessments={selectedAssessments}
          onAddSelectedAssessment={onAddSelectedAssessment}
          onRemoveSelectedAssessment={onRemoveSelectedAssessment}
        />
      </div>
      <div className={styles.submitBtn}>
        <ButtonAlt disabled={selectedAssessments.length < 1} onClick={onNextClick}>
          Next
        </ButtonAlt>
      </div>
    </div>
  );
};

export default GroupPickAssessment;
