import { useState } from 'react';

import CheckBox from 'components/CheckBox/CheckBox';
import FlexBox from 'components/FlexBox/FlexBox';
import Select from 'components/Select/CommonSelect/Select';
import { CONTACT_TAG_OPTIONS } from 'pages/Groups/GroupDetails/components/GroupContent/components/KeyContacts/constants';
import { Recipient, RecipientTypes } from 'redux/invoiceTemplate/invoiceTemplateCreateFormSlice';
import styles from './SelectDefaultSendTo.module.scss';
import { SendTypeLabels } from 'pages/InvoiceTemplate/helpers/constants';

interface SelectDefaultSendToProps {
  checked: boolean;
  item: Recipient;
  onSelectRecipient: (type: { type: string }) => void;
  onSelectKeyClientContact: (selectedTags: string[]) => void;
}

const SelectDefaultSendTo = ({
  checked,
  item,
  onSelectRecipient,
  onSelectKeyClientContact
}: SelectDefaultSendToProps) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { type } = item;

  return (
    <FlexBox direction="column" spacing={8}>
      <CheckBox
        id={type}
        className={styles.mediumCheckBoxLabel}
        inputClassName={styles.checkBoxInput}
        value={checked}
        label={SendTypeLabels[type]}
        onChange={(e) =>
          onSelectRecipient({
            type: e.target.id
          })
        }
      />
      {type === RecipientTypes.KeyClientContact && checked ? (
        <div className={styles.contactTags}>
          <Select
            className={styles.tagSelect}
            isMulti
            placeholder="Select relationship"
            name="tags"
            labelClass={styles.labelClass}
            options={CONTACT_TAG_OPTIONS.map((item) => ({ value: item, label: item }))}
            isClearable={false}
            menuPlacement={'auto'}
            onChange={(e) => {
              const tags = e as unknown as { label: string; value: string }[];
              const selectedTags = tags ? tags.map((tag) => tag.value) : [];
              if (selectedTags.length > 5) {
                setShowErrorMessage(true);
                return;
              }
              setShowErrorMessage(false);
              return onSelectKeyClientContact(selectedTags);
            }}
            smallCaretDown
            styles={{
              control: (controlBase: any) => ({
                ...controlBase,
                minHeight: '40px',
                border: 'none',
                borderBottom: `1px solid ${showErrorMessage ? '#ff1900' : '#b5b8bd'}`,
                borderRadius: 0,
                boxShadow: 'none',
                cursor: 'pointer'
              }),
              valueContainer: (base: any) => ({
                ...base,
                minHeight: '40px',
                padding: '0 8px 0 0'
              }),
              placeholder: (base) => ({ ...base, color: styles.newGreyColor, fontSize: '14px', lineHeight: '21px' }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: styles.newBlueColor,
                borderRadius: 25,
                color: styles.newGreyColor,
                padding: 4,
                fontSize: 12
              }),
              multiValueLabel: (base) => ({ ...base, color: styles.newGreyColor, fontSize: 12 })
            }}
            noCreateNewOption
            errorMessage={showErrorMessage ? 'Tag options must less than 5' : undefined}
          />
        </div>
      ) : null}
    </FlexBox>
  );
};

export default SelectDefaultSendTo;
