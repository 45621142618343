import { createSlice } from '@reduxjs/toolkit';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { RootState } from 'redux/store';

export interface PackageListPaging {
  page: number;
  perPage: number;
}

export interface PackageListFilters {
  selectedType: FilterCheckListItem[];
  selectedStatus: FilterCheckListItem[];
  selectedFunder: FilterCheckListItem[];
}

interface PackageListState {
  search: string;
  paging: PackageListPaging;
  filters: PackageListFilters;
}

export const initialState: PackageListState = {
  search: '',
  paging: {
    page: 1,
    perPage: 10
  },
  filters: {
    selectedType: [],
    selectedStatus: [],
    selectedFunder: []
  }
};

export const packageListSlice = createSlice({
  name: 'packageListSlice',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },

    setPaging: (state, action) => {
      state.paging = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    resetSearch: (state) => {
      state.search = initialState.search;
    },

    resetAll: (state) => {
      state.search = initialState.search;
      state.paging = initialState.paging;
      state.filters = initialState.filters;
    },

    resetPagingAndSorting: (state) => {
      state.paging = initialState.paging;
    }
  }
});

export const selectSearch = (state: RootState) => state.packageListSlice.search;
export const selectPaging = (state: RootState) => state.packageListSlice.paging;
export const selectFilters = (state: RootState) => state.packageListSlice.filters;

export const { setSearch, setPaging, setFilters, resetPaging, resetFilters, resetAll, resetPagingAndSorting } =
  packageListSlice.actions;

export default packageListSlice.reducer;
