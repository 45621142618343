import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { EpisodeInterface } from 'interfaces/Episodes/episodes';
import { DEFAULT_EPISODE_ORDER } from '../interface/constants';

export const getEpisodeOrder = (
  mode: 'create' | 'edit',
  clientRecord: clientRecordsInterface,
  episode?: EpisodeInterface
): number => {
  let order = DEFAULT_EPISODE_ORDER;

  switch (mode) {
    case 'create': {
      if (clientRecord?.episodeSummary) {
        order = clientRecord.episodeSummary.order + 1;
      }
      break;
    }
    case 'edit': {
      if (episode) {
        order = episode.order;
      }
      break;
    }
  }

  return order;
};
