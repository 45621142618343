import { useCallback, useEffect, useState } from 'react';
import { useGetAccessToken } from './token';
import { useGetAccountId } from './GetAccountInfo/getAccountId';
import { getInvoicesAmounts } from 'utils/http/BillingService/Invoice/invoice';
import { notification } from 'antd';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentEpisodeId, selectIsOutsideAllEpisodes, selectIsShowAllData } from 'redux/episodes/episodeSlice';

export interface TotalInvoicesAmounts {
  closed: number;
  confirmPaid: number;
  issued: number;
  markedPaid: number;
  overdue: number;
  writeOff: number;
  voided: number;
}

export enum InvoiceType {
  All = '',
  Individual = 'individual',
  Group = 'group'
}

export const useTotalInvoicesAmounts = (filter: InvoiceType, clientRecordId?: string) => {
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);

  const [loading, setLoading] = useState(true);
  const [totalInvoicesAmounts, setTotalInvoicesAmounts] = useState<TotalInvoicesAmounts>({
    closed: 0,
    confirmPaid: 0,
    issued: 0,
    markedPaid: 0,
    overdue: 0,
    writeOff: 0,
    voided: 0
  });

  const fetchTotalInvoicesAmounts = useCallback(async () => {
    setLoading(true);
    const queryParams =
      filter || clientRecordId
        ? {
            ...(filter && { type: filter }),
            ...(clientRecordId && { clientRecordId }),
            ...(!isShowAllData && {
              ...(currentEpisodeId && !isOutsideAllEpisodes && { episodeId: currentEpisodeId }),
              ...(isOutsideAllEpisodes && { showOutsideOfEpisode: isOutsideAllEpisodes })
            })
          }
        : undefined;
    try {
      const response = await getInvoicesAmounts({ token, accountId, queryParams });
      setTotalInvoicesAmounts(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error({ message: 'Something went wrong while trying to fetch the total invoice amounts.' });
    }
  }, [accountId, clientRecordId, currentEpisodeId, filter, isOutsideAllEpisodes, isShowAllData, token]);

  useEffect(() => {
    if (token && accountId) {
      fetchTotalInvoicesAmounts();
    }
  }, [token, accountId, fetchTotalInvoicesAmounts]);

  return { totalInvoicesAmounts, loading, fetchTotalInvoicesAmounts };
};
