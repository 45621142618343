import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './WidgetsListTemplate.module.scss';

interface WidgetsTemplateProps {
  title?: string;
  onClickBackBtn: () => void;
  children?: any;
}

const WidgetsListTemplate = ({ title, onClickBackBtn, children }: WidgetsTemplateProps) => {
  return (
    <div className={styles.container}>
      <ButtonAlt variant={'text'} size={'medium'} onClick={onClickBackBtn} icon={'arrow_back_ios'}>
        Back to all elements
      </ButtonAlt>
      {title && <div className={styles.insightsTitle}>{title}</div>}
      <div className={styles.insightsMenuContent}>{children}</div>
    </div>
  );
};

export default WidgetsListTemplate;
