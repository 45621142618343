import classnames from 'classnames';
import moment from 'moment';
import { Invoice } from 'pages/Invoices/interface';
import { useState } from 'react';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';

import { Claim, ClaimType } from '../../../../hooks/getClaims';
import columnStyles from '../../ClaimHistoryColumn.module.scss';
import styles from './ClaimItem.module.scss';
import ClaimStatementModal from './components/ClaimStatementModal/ClaimStatementModal';
import ClaimStatus from './components/ClaimStatus/ClaimStatus';
import { STATUS_LABELS } from 'pages/Invoices/components/InvoiceListing/components/ClientInvoiceItem/ClientInvoiceItem';

const CLAIM_TYPE_DETAILS = {
  [ClaimType.BulkBill]: 'Claim',
  [ClaimType.Medicare]: 'Rebate',
  [ClaimType.DVA]: 'Claim'
};

interface ClaimItemProps {
  claim: Claim;
  invoice?: Invoice;
  refetchClaims: () => void;
}

const ClaimItem = ({ claim, invoice, refetchClaims }: ClaimItemProps) => {
  const [isClaimStatementModalVisible, setIsClaimStatementModalVisible] = useState(false);

  const handleCloseStatementModal = async () => {
    setIsClaimStatementModalVisible(false);
  };

  const handleOpenStatementModal = async () => {
    setIsClaimStatementModalVisible(true);
  };

  const { _id, type, mbsCode, benefit, status, createdAt } = claim;
  const formattedInvoiceDate = moment(invoice?.issueDate, 'YYYY-MM-DD').format(MOMENTJS_DATE_FORMAT);
  const formattedDateSubmitted = moment(createdAt).format(MOMENTJS_DATE_FORMAT);

  return (
    <>
      <div className={classnames(columnStyles.columnWrapper, styles.listItem)}>
        <div className={classnames(columnStyles.invoiceId, styles.invoiceId)}>{invoice?.invoiceId}</div>
        <div className={classnames(columnStyles.invoiceDate, styles.invoiceDate)}>{formattedInvoiceDate}</div>
        <div className={classnames(columnStyles.invoiceStatus, styles.invoiceStatus)}>
          <div className={styles[invoice?.status ?? '']}>{STATUS_LABELS[invoice?.status ?? 'issued']}</div>
        </div>
        <div className={classnames(columnStyles.mbsCodeAndDetail, styles.mbsCodeAndDetail)}>
          <div className={styles.code}>{mbsCode}</div>
          <div className={styles.rebate}>
            {CLAIM_TYPE_DETAILS[type || ClaimType.Medicare]} of ${benefit.toFixed(2)}
          </div>
        </div>
        <div className={classnames(columnStyles.dateSubmitted, styles.dateSubmitted)}>{formattedDateSubmitted}</div>
        <div className={columnStyles.claimStatus}>
          <ClaimStatus _id={_id} status={status} handleOpenStatementModal={handleOpenStatementModal} />
        </div>
      </div>
      <ClaimStatementModal
        visible={isClaimStatementModalVisible}
        claim={claim}
        invoice={invoice}
        handleCancel={handleCloseStatementModal}
        refetchClaims={refetchClaims}
      />
    </>
  );
};

export default ClaimItem;
