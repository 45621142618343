import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useAppDispatch } from 'redux/hooks';
import { useEffect, useState } from 'react';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import {
  appointmentApiSlice,
  useGetAppointmentsByClinicianIdsQuery
} from 'redux/endpoints/scheduleServices/appointment';
import { BATCH_CHUNK_SIZE } from './useAppointmentEvents';

const REFETCH_EVERY_MINUTES = 5;
/**
 * Custom hook for fetching appointments based on clinician and room IDs.
 *
 * This hook decides whether to batch requests based on the number of IDs. If batching is needed,
 * it fetches appointments in batches. If not, it fetches them directly. It returns the appointment data,
 * and loading and fetching states.
 */

interface appointmentsEventByBatchInterface {
  from: string;
  to: string;
  clinicianIds: string[];
  roomIds: string[];
  clinicianIdBatches: string[][];
  roomIdBatches: string[][];
}

export const useAppointmentsEventByBatch = ({
  from,
  to,
  clinicianIds,
  roomIds,
  clinicianIdBatches,
  roomIdBatches
}: appointmentsEventByBatchInterface) => {
  const { accountId } = useGetAccountId();
  const { isEdgeUserView } = useGetAccountPackageView();
  const { accountTimeZone } = useTimeZone();
  const dispatch = useAppDispatch();

  const totalClinicianAndRoomIds = clinicianIds.length + roomIds.length;
  const shouldBatchFetch = totalClinicianAndRoomIds >= BATCH_CHUNK_SIZE;

  const [data, setData] = useState<AppointmentSlots[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (shouldBatchFetch) {
      setIsLoading(true);
      const fetchClinicianRequests = clinicianIdBatches.map((clinicianObj) =>
        dispatch(
          appointmentApiSlice.endpoints.getAppointmentsByClinicianIds.initiate(
            {
              accountId,
              asUser: isEdgeUserView,
              params: {
                from,
                to,
                clinicianIds: clinicianObj.join(',')
              },
              timeZone: accountTimeZone
            },
            { subscriptionOptions: { pollingInterval: 1000 * 60 * REFETCH_EVERY_MINUTES } }
          )
        ).unwrap()
      );

      const fetchRoomRequests = roomIdBatches.map((roomObj) =>
        dispatch(
          appointmentApiSlice.endpoints.getAppointmentsByClinicianIds.initiate(
            {
              accountId,
              asUser: isEdgeUserView,
              params: {
                from,
                to,
                roomIds: roomObj.join(',')
              },
              timeZone: accountTimeZone
            },
            { subscriptionOptions: { pollingInterval: 1000 * 60 * REFETCH_EVERY_MINUTES } }
          )
        ).unwrap()
      );

      Promise.all([...fetchClinicianRequests, ...fetchRoomRequests])
        .then((results) => {
          const allResults = results.flat();
          setData(allResults);
        })
        .catch((error) => {
          console.error('Error fetching appointments:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    dispatch,
    clinicianIdBatches,
    roomIdBatches,
    from,
    to,
    accountId,
    isEdgeUserView,
    accountTimeZone,
    shouldBatchFetch
  ]);

  const {
    data: notBatchAppointmentData,
    isLoading: notBatchAppointmentLoading,
    isFetching: notBatchAppointmentFetching
  } = useGetAppointmentsByClinicianIdsQuery(
    {
      accountId,
      asUser: isEdgeUserView,
      params: {
        from,
        to,
        clinicianIds: clinicianIds.join(','),
        roomIds: roomIds.join(',')
      },
      timeZone: accountTimeZone
    },
    {
      skip: clinicianIds.length <= 0 || shouldBatchFetch,
      pollingInterval: 1000 * 60 * REFETCH_EVERY_MINUTES
    }
  );

  return {
    data: shouldBatchFetch ? data : notBatchAppointmentData,
    isLoading: shouldBatchFetch ? isLoading : notBatchAppointmentLoading,
    isFetching: shouldBatchFetch ? isLoading : notBatchAppointmentFetching
  };
};
