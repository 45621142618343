import { useCallback, useEffect, useState } from 'react';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { getCommonVariables } from 'utils/http/CheckInService/OpenDataField/openDataField';
import { OpenDataFieldVariable } from '../CollectData.interface';

const MEDICARE_QUESTION_IDS = ['ID2', 'ID3'];

export const useFetchCommonODFVariable = () => {
  const { medicareRebateFeatureToggle } = useGetFeatureToggle();

  const [isCommonVariablesLoading, setIsCommonVariablesLoading] = useState(false);
  const [commonVariables, setCommonVariables] = useState<OpenDataFieldVariable[]>([]);
  const [scaleTypes, setScaleTypes] = useState<OpenDataFieldVariable[]>([]);

  const fetchCommonVariables = useCallback(async () => {
    try {
      setIsCommonVariablesLoading(true);

      const res = (await (await getCommonVariables()).json()) as {
        commonVariables: OpenDataFieldVariable[];
        scaleTypes: OpenDataFieldVariable[];
      };

      if (!medicareRebateFeatureToggle) {
        res.commonVariables = res.commonVariables.filter(
          (item: OpenDataFieldVariable) => !MEDICARE_QUESTION_IDS.includes(item.variableId)
        );
      }

      setCommonVariables(res.commonVariables);
      setScaleTypes(res.scaleTypes);
      setIsCommonVariablesLoading(false);
    } catch (er) {
      console.error(er);
      setIsCommonVariablesLoading(false);
    }
  }, [medicareRebateFeatureToggle]);

  useEffect(() => {
    fetchCommonVariables();
  }, [fetchCommonVariables]);

  return { commonVariables, scaleTypes, isCommonVariablesLoading, fetchCommonVariables };
};
