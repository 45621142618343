import { useState } from 'react';
import styles from './FormatSettingsFieldEditor.module.scss';
import * as yup from 'yup';
import { CaseNoteTemplate } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import { Formik } from 'formik';
import Button from 'components/Button/Button';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Select from 'components/Select/CommonSelect/Select';
import classnames from 'classnames';
import Radio from 'components/Radio/Radio';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { Modal } from 'antd';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

const FIELD_TYPE_OPTIONS = [
  { label: 'Date', value: 'date' },
  { label: 'Time', value: 'time' },
  { label: 'Multiple Choice', value: 'multipleChoice' },
  { label: 'Number', value: 'number' },
  { label: 'Text', value: 'text' }
];

const MULTIPLE_CHOICE_COUNT_FIELD_TYPE = { label: 'Multiple Choice with Count', value: 'multipleChoiceCount' };

const fieldSchema = yup.object().shape({
  name: yup.string().required("Please enter this field's name")
});

interface FormatSettingsFieldsEditorProps {
  selectedField: CaseNoteTemplate['fields'][number];
  onEditFinish: (updatedField?: CaseNoteTemplate['fields'][number]) => void;
}

const FormatSettingsFieldEditor = ({ selectedField, onEditFinish }: FormatSettingsFieldsEditorProps) => {
  const [isAddCommonOptionsInputVisible, setIsAddCommonOptionsInputVisible] = useState(
    !!selectedField.customCommonChoices?.length
  );
  const [selectedCustomChoiceIndex, setSelectedCustomChoiceIndex] = useState<number | undefined>(undefined);

  const handleCommonChoiceTypeClick = (setFieldValue: (field: string, value: any) => void) => () => {
    setFieldValue('choiceType', 'common');

    setSelectedCustomChoiceIndex(undefined);

    const customCommonChoicesValue = (
      document.getElementById('additional-custom-multiple-choice-options') as HTMLInputElement
    )?.value;

    setIsAddCommonOptionsInputVisible(!!customCommonChoicesValue);
  };

  const handleCustomChoiceTypeClick = (setFieldValue: (field: string, value: any) => void) => () => {
    setFieldValue('choiceType', 'custom');

    setIsAddCommonOptionsInputVisible(false);
  };

  const getCustomChoiceObject = (index: number) => {
    const label =
      (document.getElementById(`custom-multiple-choice-section-name-${index}`) as HTMLInputElement)?.value || '';

    const options = (document.getElementById(`custom-multiple-choice-options-${index}`) as HTMLInputElement)?.value;

    return {
      label,
      options: options
        ?.split(';')
        .filter((value) => !!value)
        .map((value, index) => ({ label: value, value: `Custom.${value}-${index}` }))
    };
  };

  const handleAddCustomChoice =
    (field: CaseNoteTemplate['fields'][number], setFieldValue: (field: string, value: any) => void) => () => {
      const customChoices = [...(field.customChoices || [])];

      if (selectedCustomChoiceIndex !== undefined && customChoices[selectedCustomChoiceIndex]) {
        customChoices[selectedCustomChoiceIndex] = getCustomChoiceObject(selectedCustomChoiceIndex);
      }

      setFieldValue('customChoices', [...customChoices, { label: 'New Section', options: [] }]);

      setSelectedCustomChoiceIndex(field.customChoices?.length || 0);
    };

  const handleCustomChoiceSectionSave = (setFieldValue: (field: string, value: any) => void, index: number) => () => {
    setFieldValue(`customChoices.${index}`, getCustomChoiceObject(index));

    setSelectedCustomChoiceIndex(undefined);
  };

  const handleCustomChoiceClick = (index: number, setFieldValue: (field: string, value: any) => void) => {
    if (index === selectedCustomChoiceIndex) {
      handleCustomChoiceSectionSave(setFieldValue, index)();
    } else {
      selectedCustomChoiceIndex && handleCustomChoiceSectionSave(setFieldValue, selectedCustomChoiceIndex)();

      setSelectedCustomChoiceIndex(index);
    }
  };

  const handleSubmit = (values: CaseNoteTemplate['fields'][number]) => {
    const { type, multiple, choiceType, commonChoices, customChoices, customCommonChoices, ...rest } = values;

    const payload: Partial<CaseNoteTemplate['fields'][number]> = {
      type
    };

    if (type === 'multipleChoice' || type === 'multipleChoiceCount') {
      payload.multiple = multiple;
      payload.choiceType = choiceType;
      payload.commonChoices = commonChoices;

      if (choiceType === 'common') {
        const value = (document.getElementById('additional-custom-multiple-choice-options') as HTMLInputElement)?.value;

        const customCommonChoices =
          type === 'multipleChoice'
            ? [
                {
                  label: 'Custom',
                  options: value
                    ?.split(';')
                    .filter((value) => !!value)
                    .map((value, index) => ({ label: value, value: `Custom.${value}-${index}` }))
                }
              ]
            : value
                ?.split(';')
                .filter((value) => !!value)
                .map((value, index) => ({ label: value, value: `Custom.${value}-${index}` }));

        payload.customCommonChoices = customCommonChoices;
      } else if (type === 'multipleChoice') {
        payload.customChoices = customChoices
          ?.map((choice, index) => {
            if (index !== selectedCustomChoiceIndex) {
              return choice;
            } else {
              const label = (
                document.getElementById(`custom-multiple-choice-section-name-${index}`) as HTMLInputElement
              )?.value;

              if (!label) {
                return undefined;
              }

              const options = (document.getElementById(`custom-multiple-choice-options-${index}`) as HTMLInputElement)
                ?.value;

              return {
                label,
                options: options
                  ?.split(';')
                  .filter((value) => !!value)
                  .map((value, index) => ({ label: value, value: `Custom.${value}-${index}` }))
              };
            }
          })
          .filter((choice): choice is typeof customChoices[number] => !!choice);
      } else if (type === 'multipleChoiceCount') {
        const options = (document.getElementById('custom-multiple-choice-options') as HTMLInputElement)?.value;

        payload.customChoices = options
          ?.split(';')
          .filter((value) => !!value)
          .map((value, index) => ({ label: value, value: `Custom.${value}-${index}`, count: 0 }));
      }
    }

    onEditFinish({ ...rest, ...payload } as CaseNoteTemplate['fields'][number]);
  };

  return (
    <Modal bodyStyle={{ padding: 0 }} width={768} footer={null} open closable={false}>
      <Formik initialValues={selectedField} validationSchema={fieldSchema} onSubmit={handleSubmit} enableReinitialize>
        {({ errors, touched, values, setFieldValue, submitForm }) => (
          <div className={styles.container}>
            <ButtonAlt
              variant={'text'}
              size={'small'}
              error
              className={styles.discardButton}
              onClick={() => onEditFinish()}
            >
              DISCARD CHANGES
            </ButtonAlt>
            <div className={styles.titleText}>Edit Field</div>
            <div className={styles.body}>
              <div className={styles.header}>
                <i className={`material-icons-outlined ${styles.icon}`}>edit</i>
                <div>
                  <input
                    className={styles.titleInput}
                    placeholder="Field name"
                    value={values.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                  />
                  <ErrorMessage error={errors.name} visible={!!touched.name} />
                </div>
              </div>
              <div className={styles.content}>
                <div className={styles.leftCol}>
                  <Select
                    labelClass={styles.selectLabel}
                    label="Selection format"
                    options={
                      values.type === 'multipleChoiceCount' ? [MULTIPLE_CHOICE_COUNT_FIELD_TYPE] : FIELD_TYPE_OPTIONS
                    }
                    styles={{ valueContainer: (base) => ({ ...base, paddingLeft: 0 }) }}
                    value={values.type}
                    onChange={(e: any) => setFieldValue('type', e?.value)}
                  />
                  {(values.type === 'multipleChoice' || values.type === 'multipleChoiceCount') && (
                    <div className={styles.switchContainer}>
                      <div className={styles.label}>Accept more than one answer?</div>
                      <label className={styles.switchToggle} htmlFor="switch-multiple-answers">
                        <span className={classnames(styles.switchLabel, !values.multiple && styles.active)}>No</span>
                        <input
                          id="switch-multiple-answers"
                          name="switch-multiple-answers"
                          type="checkbox"
                          checked={values.multiple}
                          onChange={(e) => setFieldValue('multiple', e.target.checked)}
                        />
                        <span className={styles.indicator} />
                        <span className={classnames(styles.switchLabel, values.multiple && styles.active)}>Yes</span>
                      </label>
                    </div>
                  )}
                </div>
                <div className={styles.middleCol}>
                  {(values.type === 'multipleChoice' || values.type === 'multipleChoiceCount') && (
                    <>
                      <div className={styles.label}>Field Settings</div>
                      {values.commonChoices && (
                        <div className={styles.commonOptionsContainer}>
                          <Radio
                            name="choiceType"
                            options={[{ label: 'Common', value: 'common' }]}
                            value={values.choiceType}
                            onClick={handleCommonChoiceTypeClick(setFieldValue)}
                          />
                          <div
                            className={classnames(
                              styles.inputContainer,
                              values.choiceType === 'common' && styles.visible
                            )}
                          >
                            <div className={styles.commonOptions}>
                              {values.commonChoices
                                .flatMap((choice) => choice.options?.map((option) => option.label) || choice.label)
                                .filter((label) => !!label)
                                .join(';')}
                            </div>
                            <div
                              className={classnames(
                                styles.addOptionsContainer,
                                isAddCommonOptionsInputVisible && styles.visible
                              )}
                            >
                              <MaterialInput
                                className={styles.additionalCommonOptionsInput}
                                id="additional-custom-multiple-choice-options"
                                label="Custom Options"
                                defaultValue={values.customCommonChoices
                                  ?.flatMap((choice) => choice.options?.map((option) => option.label) || choice.label)
                                  .filter((label) => !!label)
                                  .join(';')}
                                required
                              />
                              <div className={styles.tip}>Separate options with semi-colon ;</div>
                            </div>
                            {!isAddCommonOptionsInputVisible && (
                              <Button
                                className={styles.addMoreButton}
                                variant="secondary"
                                onClick={() => setIsAddCommonOptionsInputVisible(true)}
                              >
                                <i className={`material-icons-outlined ${styles.icon}`}>add</i>
                                add options
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                      <div className={styles.customOptionsContainer}>
                        <Radio
                          name="choiceType"
                          options={[{ label: 'Custom', value: 'custom' }]}
                          value={values.choiceType}
                          onClick={handleCustomChoiceTypeClick(setFieldValue)}
                        />
                        <div
                          className={classnames(
                            styles.inputContainer,
                            values.choiceType === 'custom' && styles.visible
                          )}
                        >
                          {values.type === 'multipleChoice' ? (
                            <>
                              {values.customChoices?.map((choice, index) => (
                                <div key={index}>
                                  <Button
                                    className={styles.pill}
                                    onClick={() => handleCustomChoiceClick(index, setFieldValue)}
                                  >
                                    {choice.label} | {choice.options?.length || 0} answers
                                  </Button>
                                  <div
                                    className={classnames(
                                      styles.customChoiceInputs,
                                      index === selectedCustomChoiceIndex && styles.visible
                                    )}
                                  >
                                    <MaterialInput
                                      className={styles.input}
                                      id={`custom-multiple-choice-section-name-${index}`}
                                      label="Group title"
                                      defaultValue={choice.label}
                                      required
                                    />
                                    <MaterialInput
                                      className={styles.input}
                                      id={`custom-multiple-choice-options-${index}`}
                                      label="Answer options"
                                      defaultValue={choice.options?.map((option) => option.label).join(';')}
                                      required
                                    />
                                    <div className={styles.tip}>Separate options with semi-colon ;</div>
                                  </div>
                                </div>
                              ))}
                              <Button
                                className={styles.addMoreButton}
                                variant="secondary"
                                onClick={handleAddCustomChoice(values, setFieldValue)}
                              >
                                <i className={`material-icons-outlined ${styles.icon}`}>add</i>
                                add options
                              </Button>
                            </>
                          ) : (
                            <>
                              <MaterialInput
                                className={styles.customOptionsInput}
                                id="custom-multiple-choice-options"
                                label="Custom Options"
                                defaultValue={values.customChoices
                                  ?.flatMap((choice) => choice.options?.map((option) => option.label) || choice.label)
                                  .filter((label) => !!label)
                                  .join(';')}
                                autoFocus
                                required
                              />
                              <div className={styles.tip}>Separate options with semi-colon ;</div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.rightCol}>
                  <ButtonAlt size={'medium'} className={styles.saveButton} onClick={() => submitForm()}>
                    SAVE CHANGES
                  </ButtonAlt>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default FormatSettingsFieldEditor;
