import { newClientForm } from 'components/AddPatientModalV2/AddPatientModalV2Interface';
import { CLIENT_ACCOUNT } from 'components/AddPatientModalV2/constants';
import { ClientCapabilityProps } from 'components/AddPatientModalV2/interfaces/clientCapability.interface';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { useState } from 'react';
import styles from './CapabilityFormV2.module.scss';

export const CapabilityFormV2 = ({
  clientRegisteredAs,
  capabilityData,
  onChangeClientCapability,
  helpText
}: ClientCapabilityProps) => {
  const [clientCapabilityDetails, setClientCapabilityDetails] = useState(capabilityData);

  const handleClientCapability = (value: newClientForm['clientCapability']['profileType']) => {
    const details = {
      ...clientCapabilityDetails,
      profileType: value
    };
    setClientCapabilityDetails(details);
    onChangeClientCapability(details);
  };

  return (
    <>
      <div className={styles.clientAccountContainer}>
        <div className={styles.selectContainer}>
          <MaterialSelect
            id={'clientAccount'}
            label={'Client Account?'}
            isSearchable={false}
            options={CLIENT_ACCOUNT}
            value={clientRegisteredAs === 'waitlist' ? 'recordOnly' : capabilityData.profileType}
            isDisabled={clientRegisteredAs === 'waitlist'}
            onChange={handleClientCapability}
          />
        </div>
        {helpText && (
          <div className={styles.help}>
            <HelpOutLineWithTooltips id={`client-account-help`} desc={helpText} iconClass={styles.helpIcon} />
          </div>
        )}
      </div>
    </>
  );
};
