import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useMemo, useState } from 'react';
import { useGetPractitionerListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import styles from './AppointmentTypeFilters.module.scss';
import {
  formMenuFilterByStatus,
  getFormMenuFilterByDeliveryMode,
  getFormMenuFilterByParticipantType,
  onToggleFilter
} from './constants';
import { debounce } from 'lodash';
import {
  resetFilters,
  resetPaging,
  selectFilters,
  selectSearch,
  setFilters,
  setSearch
} from 'redux/appointmentTypes/appointmentTypesSlice';
import SearchBarT23 from 'components/SearchBarT23/SearchBarT23';
import FilterRadioDropdown, { FilterRadioItem } from 'components/T23/FilterRadioDropdown/FilterRadioDropdown';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

const PER_PAGE = 50;

const AppointmentTypeFilters = () => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { isTwilioTelehealthEnabled } = useGetFeatureToggle();
  const dispatch = useAppDispatch();

  const search = useAppSelector(selectSearch);
  const filters = useAppSelector(selectFilters);

  const [practitionerCurrentPage, setPractitionerCurrentPage] = useState<number>(1);
  const [practitionerSearchText, setPractitionerSearchText] = useState('');

  const deliveryModeFilters = getFormMenuFilterByDeliveryMode(isTwilioTelehealthEnabled);

  const {
    data: practitionerData,
    isLoading: isPractitionerDataLoading,
    isFetching: isPractitionerDataFetching
  } = useGetPractitionerListQuery({
    accountId,
    params: {
      page: practitionerCurrentPage,
      perPage: PER_PAGE,
      status: 'active',
      searchValue: practitionerSearchText
    }
  });

  const practitionerList: FilterCheckListItem[] = practitionerData
    ? practitionerData.clinicians.map((item) => ({ name: item.name, _id: item._id }))
    : [];

  const onChangePractitionerFilter = (selectedPractitioners: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedPractitioners }));
  };

  const onChangeDeliveryModeFilter = (selectedDeliveryMode: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedDeliveryMode }));
  };

  const onChangeStatusFilter = (selectedStatus: FilterRadioItem | undefined) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedStatus }));
  };

  const onChangeTypeFilter = (selectedType: FilterRadioItem | undefined) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, selectedType }));
  };

  const onClearFilter = () => {
    dispatch(resetFilters());
  };

  const handleSearch = (searchValue: string) => {
    dispatch(resetPaging());
    dispatch(setSearch({ ...search, searchValue }));
  };

  const totalPractitionerPage = practitionerData?.paging ? Math.ceil(practitionerData.paging.totalItems / PER_PAGE) : 1;
  const { selectedPractitioners, selectedDeliveryMode, selectedStatus, selectedType } = filters;
  const { searchValue } = search;
  const showClearFilter = Object.keys(filters).some(
    (key) =>
      key !== 'recordStatus' &&
      key !== 'selectedFormsTimeBound' &&
      (selectedPractitioners.length > 0 || selectedDeliveryMode.length > 0 || selectedStatus || selectedType)
  );

  const debouncedSetPractitionersSearchText = useMemo(
    () =>
      debounce((value) => {
        setPractitionerSearchText(value);
        setPractitionerCurrentPage(1);
      }, 1000),
    []
  );

  return (
    <div className={styles.container}>
      <div className={styles.filterSection}>
        <div className={styles.label}>
          <ButtonAlt
            error
            disabled={!showClearFilter}
            size={'small'}
            variant={'text'}
            className={styles.clearFilters}
            onClick={onClearFilter}
          >
            Clear filters
          </ButtonAlt>
        </div>
        <div className={styles.buttonsContainer}>
          {isEdgeAdminView && (
            <FilterDropdown
              id={'practitioner'}
              icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
              menuItems={practitionerList}
              onChangeItem={onToggleFilter(practitionerList, selectedPractitioners, onChangePractitionerFilter)}
              searchable
              selectedFilterList={selectedPractitioners}
              showSearchIcon
              showToggleAllButtons
              loading={isPractitionerDataLoading}
              hasMoreData={totalPractitionerPage > practitionerCurrentPage || isPractitionerDataFetching}
              isFetchingMore={isPractitionerDataFetching}
              loadMore={() => {
                if (!isPractitionerDataFetching && practitionerCurrentPage <= totalPractitionerPage) {
                  setPractitionerCurrentPage(practitionerCurrentPage + 1);
                }
              }}
              setSearchText={debouncedSetPractitionersSearchText}
            >
              Practitioner
            </FilterDropdown>
          )}
          <FilterDropdown
            id={'deliveryMode'}
            icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
            menuItems={deliveryModeFilters}
            onChangeItem={onToggleFilter(deliveryModeFilters, selectedDeliveryMode, onChangeDeliveryModeFilter)}
            selectedFilterList={selectedDeliveryMode}
          >
            Delivery Mode
          </FilterDropdown>
          <FilterRadioDropdown
            id="status"
            menuItems={formMenuFilterByStatus}
            onChangeItem={onChangeStatusFilter}
            selectedItem={selectedStatus}
          >
            Status
          </FilterRadioDropdown>

          <FilterRadioDropdown
            id={'type'}
            menuItems={getFormMenuFilterByParticipantType}
            onChangeItem={onChangeTypeFilter}
            selectedItem={selectedType}
          >
            Type
          </FilterRadioDropdown>
        </div>
      </div>
      <div className={styles.searchBarWrapper}>
        <SearchBarT23
          placeholder={'Search by name or ID'}
          value={searchValue}
          withSearchButton
          onSearch={handleSearch}
        />
      </div>
    </div>
  );
};

export default AppointmentTypeFilters;
