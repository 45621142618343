import { ClinicianDetails } from 'interfaces/Letters/letter';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { Signature } from 'interfaces/documentService/Signature';

export const getClinicianDetails = (clinicianProfile?: ProfileInterface): ClinicianDetails | undefined => {
  if (clinicianProfile) {
    const { _id, avatar, name, practice, title, email, mobileNumber } = clinicianProfile;
    const firstPractice = practice ? practice : undefined;

    return {
      clinician: {
        _id,
        avatar,
        email,
        mobileNumber,
        name: `${title || ''}${name ? ` ${name}` : ''}`
      },
      practice: firstPractice
        ? {
            address: firstPractice?.locations?.[0],
            logo: firstPractice?.logo,
            mobileNumber: firstPractice?.mobileNumber,
            name: firstPractice?.name
          }
        : undefined
    };
  }
};

export const mapSignature = (signature?: Signature) =>
  signature?.signature
    ? {
        type: signature.signature.type,
        textVal: signature.signature.type === 'typeVer' ? signature.signature.value : '',
        drawVal: signature.signature.type === 'drawVer' ? signature.signature.value : ''
      }
    : undefined;

export const formatContactDetails = (clinicianDetails?: ClinicianDetails) => ({
  practice: {
    address: {
      value: clinicianDetails?.practice?.address || '',
      isVisible: !!clinicianDetails?.practice?.address || false
    },
    mobileNumber: {
      value: clinicianDetails?.practice?.mobileNumber || '',
      isVisible: !!clinicianDetails?.practice?.mobileNumber || false
    }
  },
  clinician: {
    mobileNumber: {
      value: clinicianDetails?.clinician?.mobileNumber || '',
      isVisible: !!clinicianDetails?.clinician?.mobileNumber || false
    },
    email: {
      value: clinicianDetails?.clinician?.email || '',
      isVisible: !!clinicianDetails?.clinician?.email || false
    }
  }
});
