import { ProfileInterface } from 'interfaces/Profile/Profile';
import { capitalize } from 'lodash';

const formatTerminology = (labels: Record<string, string>) => {
  const { client, clients, group, groups, practitioner, practitioners } = labels;

  const terminology = {
    client,
    clients,
    group,
    groups,
    practitioner,
    practitioners,
    'client.capitalize': capitalize(client),
    'clients.capitalize': capitalize(clients),
    'group.capitalize': capitalize(group),
    'groups.capitalize': capitalize(groups),
    'practitioner.capitalize': capitalize(practitioner),
    'practitioners.capitalize': capitalize(practitioners)
  };

  return terminology;
};

export const getTerminology = (accountSettings?: ProfileInterface['accountSettings']) => {
  if (accountSettings && accountSettings.customLabels) {
    const { customLabels } = accountSettings;

    return formatTerminology(customLabels);
  }

  return formatTerminology({
    client: 'client',
    clients: 'clients',
    group: 'group',
    groups: 'groups',
    practitioner: 'practitioner',
    practitioners: 'practitioners'
  });
};
