import styles from './ClientSelected.module.scss';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { PatientAssessment } from 'pages/Assessments/components/SendAdhocAssessmentModal/interfaces';
import { canClientReceiveSms, canClientReceiveEmail } from 'utils/helpers/checkClientCommunicationPreference';
import classnames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { getName } from 'utils/general';
import { Dropdown, Menu } from 'antd';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { useTranslation } from 'react-i18next';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchNotificationSettings } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { ProfileRole } from 'interfaces/Clients/clientsRecord';

interface ClientSelectedProps {
  patientAssessment: PatientAssessment;
  participationType?: ParticipantType;
  isShowCheckBox?: boolean;
  proxyProfiles?: PatientAssessment[];
  selectedProxyProfileId?: string;
  onSelectClientProfile?: (isSelected: boolean, clientId: string) => void;
  handleProxyProfileChange?: (value: string) => void;
}

const ClientSelected = ({
  patientAssessment,
  isShowCheckBox,
  proxyProfiles,
  selectedProxyProfileId,
  onSelectClientProfile,
  handleProxyProfileChange
}: ClientSelectedProps) => {
  const { client, isSelected } = patientAssessment;
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();

  const { notificationSettings, isNotificationSettingsLoading } = useFetchNotificationSettings(token, accountId);

  const notificationConfig = {
    notificationSettings,
    notificationSettingsCategory: 'forms',
    communicationPreference: client.communicationPreference
  };

  const isCommsAllowed =
    !!(client.role === ProfileRole.YoungPerson) || // young record ===> send notification to guardians
    !!(client.role === ProfileRole.Child && proxyProfiles?.length) ||
    (canClientReceiveSms(notificationConfig) && client.hasMobileNumber) ||
    (canClientReceiveEmail(notificationConfig) && client.hasEmail);
  const [t] = useTranslation();

  const handleProxyProfileClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  if (isNotificationSettingsLoading) {
    return (
      <div className={styles.loading}>
        <LoadingCircle />
      </div>
    );
  }

  return (
    <div
      className={classnames(styles.profileWrapper, !isCommsAllowed && styles.disableProfileWrapper)}
      onClick={
        isCommsAllowed ? () => onSelectClientProfile && onSelectClientProfile(!isSelected, client._id) : undefined
      }
    >
      {isShowCheckBox && (
        <CheckBox
          id={`${client._id}_profile`}
          value={isSelected}
          onChange={(e) => onSelectClientProfile && onSelectClientProfile(e.target.checked, client._id)}
          disabled={!isCommsAllowed}
        />
      )}
      <div className={styles.profile}>
        <ClientProfileAvatar
          avatarUrl={client.avatar}
          initialsName={client.initials}
          initialClassName={styles.initialAvatar}
        />
      </div>
      <div className={styles.description}>
        <div className={styles.name}>{patientAssessment.client && getName(patientAssessment.client)}</div>
        {isCommsAllowed && patientAssessment?.client.role === 'child' && proxyProfiles && (
          <div onClick={(e) => handleProxyProfileClick(e)} className={styles.note}>
            (
            <Dropdown
              className={styles.proxyProfilesDropdown}
              overlay={
                <Menu
                  selectedKeys={proxyProfiles.map(({ client }) => client.name)}
                  onClick={({ key }) => handleProxyProfileChange && handleProxyProfileChange(key as string)}
                >
                  {proxyProfiles.map(({ client }) => (
                    <Menu.Item key={client._id}>
                      <div>{client.name}</div>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <div className={styles.headerFilterDropdown} onClick={(e) => e.preventDefault()}>
                {proxyProfiles.find(({ client }) => client._id === selectedProxyProfileId)?.client.name}{' '}
                <DownOutlined />
              </div>
            </Dropdown>
            will receive the request)
          </div>
        )}
      </div>
      {!isCommsAllowed && <HelpOutLineWithTooltips id={`noAllowAdd`} desc={t('form.disallowed_sms.description')} />}
    </div>
  );
};

export default ClientSelected;
