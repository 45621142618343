import MaterialInput from 'components/MaterialInput/MaterialInput';
import { useField } from 'formik';

import styles from './MedicareDetails.module.scss';

const MedicareDetails = () => {
  const [claimantNameField] = useField<string>('medicare.claimant.name');
  const [claimantDateOfBirthField] = useField<string>('medicare.claimant.dateOfBirth');
  const [claimantMedicareNumberField] = useField<string>('medicare.claimant.medicareNumber');
  const [claimantIrnField] = useField<string>('medicare.claimant.irn');
  const [claimantDvaField] = useField<string>('medicare.claimant.dva');
  const [claimantExpiryDateField] = useField<string>('medicare.claimant.expiryDate');
  const [claimantPhoneNumberField] = useField<string>('medicare.claimant.phoneNumber');
  const [claimantAddressField] = useField<string>('medicare.claimant.address');

  const [referralNameField] = useField<string>('medicare.referral.name');
  const [referralDateField] = useField<string>('medicare.referral.date');
  const [referralProviderNumberField] = useField<string>('medicare.referral.providerNumber');

  const [serviceProviderNameField] = useField<string>('medicare.serviceProvider.name');
  const [serviceProviderNumberField] = useField<string>('medicare.serviceProvider.providerNumber');

  const [claimDateField] = useField<string>('medicare.claim.dateOfService');
  const [claimMbsCodeField] = useField<string>('medicare.claim.mbsCode');

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.title}>Medicare Claimant Details</div>
        <div className={styles.fieldsContainer}>
          <div className={styles.row}>
            <div className={styles.field}>
              <div className={styles.label}>Name:</div>
              <MaterialInput id={'name'} className={styles.input} label={undefined} {...claimantNameField} />
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Date of Birth:</div>
              <MaterialInput
                id={'dob'}
                className={styles.input}
                type={'tel'}
                pattern={'[0-9\\/]*'}
                maxLength={10}
                label={undefined}
                {...claimantDateOfBirthField}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.field}>
              <div className={styles.label}>Medicare Card No.:</div>
              <MaterialInput
                id={'medicareCardNo'}
                className={styles.input}
                label={undefined}
                {...claimantMedicareNumberField}
              />
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Phone Number:</div>
              <MaterialInput
                id={'phoneNumber'}
                className={styles.input}
                label={undefined}
                {...claimantPhoneNumberField}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.field}>
              <div className={styles.label}>IRN:</div>
              <MaterialInput id={'irn'} className={styles.input} label={undefined} {...claimantIrnField} />
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Address:</div>
              <MaterialInput id={'address'} className={styles.input} label={undefined} {...claimantAddressField} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.field}>
              <div className={styles.label}>DVA No.:</div>
              <MaterialInput id={'dvaNo'} className={styles.input} label={undefined} {...claimantDvaField} />
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Expiry Date:</div>
              <MaterialInput
                id={'expiryDate'}
                className={styles.input}
                type={'tel'}
                pattern={'[0-9\\/]*'}
                maxLength={7}
                label={undefined}
                {...claimantExpiryDateField}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Referral Details</div>
        <div className={styles.fieldsContainer}>
          <div className={styles.row}>
            <div className={styles.field}>
              <div className={styles.label}>Referral Name:</div>
              <MaterialInput id={'referralName'} className={styles.input} label={undefined} {...referralNameField} />
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Date of Referral:</div>
              <MaterialInput id={'dateOfReferral'} className={styles.input} label={undefined} {...referralDateField} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.field}>
              <div className={styles.label}>Provider Number:</div>
              <MaterialInput
                id={'referralProviderName'}
                className={styles.input}
                label={undefined}
                {...referralProviderNumberField}
              />
            </div>
            <div className={styles.field} />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Service Provider Details</div>
        <div className={styles.fieldsContainer}>
          <div className={styles.row}>
            <div className={styles.field}>
              <div className={styles.label}>Provider Name:</div>
              <MaterialInput
                id={'serviceProviderName'}
                className={styles.input}
                label={undefined}
                {...serviceProviderNameField}
              />
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Provider Number:</div>
              <MaterialInput
                id={'serviceProviderNumber'}
                className={styles.input}
                label={undefined}
                {...serviceProviderNumberField}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>MBS Claim Details</div>
        <div className={styles.fieldsContainer}>
          <div className={styles.row}>
            <div className={styles.field}>
              <div className={styles.label}>Date of Service:</div>
              <MaterialInput id={'dateOfService'} className={styles.input} label={undefined} {...claimDateField} />
            </div>
            <div className={styles.field}>
              <div className={styles.label}>MBS Code:</div>
              <MaterialInput id={'mbsCode'} className={styles.input} label={undefined} {...claimMbsCodeField} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicareDetails;
