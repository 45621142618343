import { FormListingInterface } from 'interfaces/Form/FormInterface';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import { NEW_ASSESSMENT_TEMPORARY_ID } from '../../../../../AssessmentDetails/OnBoardingDetails/OnBoardingDetails';
import styles from './AssessmentColumnBase.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface AssessmentColumnProps {
  name: string;
  columnType: string;
  data: FormListingInterface[];
  showCreateCard?: boolean;
  children?: any;
  viewOnly?: boolean;
  id: string;
}

const AssessmentColumnBase = ({
  name,
  columnType,
  data,
  showCreateCard,
  children,
  viewOnly,
  id
}: AssessmentColumnProps) => {
  const navigate = useNavigate();
  const { FORMS } = useRoutesGenerator();
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { isCarePlanEnabled, isInvoiceTemplateEnabled } = useGetFeatureToggle();
  const [t] = useTranslation();

  const node = useRef<HTMLDivElement>(null);
  const [showCreateMenu, setShowCreateMenu] = useState(false);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const setOpen = (val: boolean) => {
    setShowCreateMenu(val);
  };

  const onSelectNewFa = () => {
    navigate(`${FORMS.BASE}/survey/${NEW_ASSESSMENT_TEMPORARY_ID}`);
  };

  const onSelectNewCa = () => {
    navigate(`${FORMS.BASE}/microCheckIn/newMCI`);
  };

  const onHandleNewConsentForm = () => {
    navigate(`${FORMS.BASE}/consent/new`);
  };

  const onHandleNewCaseNote = () => {
    navigate(`${FORMS.BASE}/caseNotes/new`);
  };

  const onHandleNewCarePathway = () => {
    navigate(`${FORMS.BASE}/carePathway/new`);
  };

  const onHandleNewLetter = () => {
    navigate(FORMS.NEW_LETTER_TEMPLATE);
  };

  const onHandleNewReport = () => {
    navigate(FORMS.NEW_REPORT_TEMPLATE);
  };

  const onSelectInvoiceTemplate = () => {
    navigate(`${FORMS.NEW_INVOICE_TEMPLATE_EDITOR}`);
  };

  const showOAc = isEdgeAdminView || isNormalUserView;
  const showMCIc = isEdgeUserView || isNormalUserView;
  const showConsent = isEdgeAdminView || isNormalUserView;
  const showCNT = isEdgeAdminView || isNormalUserView;
  const showInvoiceTemplate = isInvoiceTemplateEnabled && (isEdgeAdminView || isEdgeReceptionistView);
  const showCarePathway = isCarePlanEnabled && (isEdgeAdminView || isNormalUserView);

  return (
    <div className={styles.container} id={id}>
      <div className={styles.header}>
        <div className={styles.title}>
          {name}
          {viewOnly && <i className={`material-icons ${styles.editOffIcon}`}>edit_off</i>}
        </div>
        <div>
          {data.length || 0} {columnType}
        </div>
      </div>
      <div className={styles.content}>
        {children}
        {showCreateCard && (
          <div className={styles.cNewCardContainer}>
            <div ref={node}>
              <ButtonAlt
                onClick={() => setOpen(!showCreateMenu)}
                id={'createNewTemplateIdColumn'}
                variant={'outlined'}
                icon={'add_circle_outline'}
              >
                Create new template
              </ButtonAlt>
              <div className={styles.menuWrapper}>
                <div className={showCreateMenu ? styles.menuBoxShow : styles.menuBoxHide}>
                  {showOAc && (
                    <div className={styles.listBox} onClick={onSelectNewFa}>
                      <div className={styles.listTitle}>{t('assessment_table.survey')}</div>
                      <div className={styles.listDesc}>{t('assessment_table.survey.description')}</div>
                    </div>
                  )}
                  {showMCIc && (
                    <div className={styles.listBox} onClick={onSelectNewCa}>
                      <div className={styles.listTitle}>{t('assessment_table.micro_checkin')}</div>
                      <div className={styles.listDesc}>{t('assessment_table.micro_checkin.description')}</div>
                    </div>
                  )}
                  {showConsent && (
                    <div className={styles.listBox} onClick={onHandleNewConsentForm}>
                      <div className={styles.listTitle}>{t('assessment_table.consent_form')}</div>
                      <div className={styles.listDesc}>{t('assessment_table.consent_form.description')}</div>
                    </div>
                  )}
                  {showCNT && (
                    <div className={styles.listBox} onClick={onHandleNewCaseNote}>
                      <div className={styles.listTitle}>Case Note</div>
                      <div className={styles.listDesc}>
                        Capture client notes for initial assessments, follow-up sessions, different conditions or
                        different types of clients; to keep track of client progress.
                      </div>
                    </div>
                  )}
                  {showCarePathway && (
                    <div className={styles.listBox} onClick={onHandleNewCarePathway}>
                      <div className={styles.listTitle}>Care Pathway</div>
                      <div className={styles.listDesc}>
                        Capture client notes for initial assessments, follow-up sessions, different conditions or
                        different types of clients; to keep track of client progress.
                      </div>
                    </div>
                  )}
                  <div className={styles.listBox} onClick={onHandleNewLetter}>
                    <div className={styles.listTitle}>Letter</div>
                    <div className={styles.listDesc}>
                      Best for simpler, text oriented documents. Word processor style controls.
                    </div>
                  </div>
                  <div className={styles.listBox} onClick={onHandleNewReport}>
                    <div className={styles.listTitle}>Report</div>
                    <div className={styles.listDesc}>
                      Use our drag-and-drop canvas for complex document layouts. Reports are great to utilise
                      incorporated graphs and measures, and require approval flows / collaboration.
                    </div>
                  </div>
                  {showInvoiceTemplate && (
                    <div className={styles.listBox} onClick={onSelectInvoiceTemplate}>
                      <div className={styles.listTitle}>Invoice Template</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentColumnBase;
