import classnames from 'classnames';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import moment from 'moment';
import { Appointment } from 'pages/InvoiceGenerator/interface';
import { filterInvoiceAppointmentTags } from 'utils/filterInvoiceAppointmentTags';
import styles from './AppointmentItem.module.scss';
import { getDeliveryTypeLabel } from 'utils/appointment';

interface AppointmentItemProps {
  appointment: Appointment;
  included?: boolean;
  onAddAppointment: (appointment: Appointment) => void;
  onRemoveAppointment: (appointment: Appointment) => void;
}

const AppointmentItem = ({ appointment, included, onAddAppointment, onRemoveAppointment }: AppointmentItemProps) => {
  const { date, deliveryType, otherInstructions, startTime, endTime, markedStatus = [], sessionTypeName } = appointment;

  const dateText = moment(date, 'YYYY-MM-DD').format('dddd D MMMM');
  const deliveryTypeText = getDeliveryTypeLabel(deliveryType, otherInstructions);
  const duration = moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'minute');
  const startTimeText = moment(startTime, 'HH:mm').format('h:mmA');
  const tags = filterInvoiceAppointmentTags(markedStatus);

  const handleAddOrRemoveAppointment = () => {
    if (included) {
      onRemoveAppointment(appointment);
    } else {
      onAddAppointment(appointment);
    }
  };

  return (
    <div className={classnames(styles.container, included && styles.included)}>
      <div className={styles.appointmentInfo}>
        <div>
          {duration} minute{sessionTypeName && `, ${sessionTypeName}`}, {deliveryTypeText} booked for
          <br />
          <strong>
            {startTimeText} on {dateText}
          </strong>
        </div>
        {tags.length > 0 && (
          <div className={styles.tags}>
            {tags.map((status, index) => (
              <div className={styles.tag} key={index}>
                {status}
              </div>
            ))}
          </div>
        )}
      </div>
      <ButtonAlt
        variant={'text'}
        size={'medium'}
        className={styles.button}
        type="button"
        onClick={handleAddOrRemoveAppointment}
        icon={included ? 'remove_circle' : 'add_circle'}
        fab
      />
    </div>
  );
};

export default AppointmentItem;
