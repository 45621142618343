import { ClientRecordType } from 'interfaces/Clients/clientsRecord';
import { ConsentFormInterface } from 'interfaces/ConsentForm/ConsentFormInterface';
import { useEffect, useState } from 'react';
import { getClinicalAssessmentTemplate } from 'store/CheckIn/GetClinicalAssessmentTemplate/action';
import { getConsentFormListing } from 'utils/http/DocumentService/ConsentForm/consentForm';

import { getFirstAssessments } from '../../../store/CheckIn/GetFirstAssessment/action';
import { CheckBoxListing, newClientForm } from '../AddPatientModalV2Interface';

const getSurveyList = async (token: string) => {
  let onBoardingOptionList: CheckBoxListing[] = [];
  let consentOptionList: CheckBoxListing[] = [];
  let clinicalAssessmentList: CheckBoxListing[] = [];

  const GetOBAssessments = await getFirstAssessments(token);
  onBoardingOptionList = GetOBAssessments.filter((oBObj) => oBObj.isPublished).map(({ id, name, isDefault }) => ({
    label: name,
    value: id,
    isDefault
  }));

  const GetConsentFormList = (await (await getConsentFormListing(token)).json()) as ConsentFormInterface[];
  consentOptionList = GetConsentFormList.filter((consentObj) => consentObj.isPublished).map(
    ({ _id, name, isDefault }) => ({
      label: name,
      value: _id,
      isDefault
    })
  );

  const getClinicalAssessmentList = await getClinicalAssessmentTemplate(token);
  clinicalAssessmentList = getClinicalAssessmentList.map(({ id, title }) => ({
    label: title!,
    value: id,
    isDefault: false
  }));

  return { onBoardingOptionList, consentOptionList, clinicalAssessmentList };
};

export const usePrefillNewClientForm = (token: string, clientDetails: newClientForm) => {
  const [clientFormData, setClientFormData] = useState(clientDetails);
  const [prefillLoading, setPrefillLoading] = useState(true);
  const [onBoardingList, setOnBoardingList] = useState<CheckBoxListing[]>([]);
  const [consentList, setConsentList] = useState<CheckBoxListing[]>([]);
  const [fetchSurveyList, setFetchSurveyList] = useState(true);
  const [defaultOnBoardingId, setDefaultOnBoardingId] = useState('');
  const [defaultConsentId, setDefaultConsentId] = useState('');
  const [defaultAssessmentId, setDefaultAssessmentId] = useState('');
  const [assessmentList, setAssessmentList] = useState<CheckBoxListing[]>([]);

  const fetchPrefillClientData = async (formData: newClientForm, prefill?: Partial<newClientForm>) => {
    setPrefillLoading(true);
    let onBoardingSurveyList = onBoardingList;
    let consentSurveyList = consentList;
    let assessmentSurveyList = assessmentList;
    if (fetchSurveyList) {
      const { onBoardingOptionList, consentOptionList, clinicalAssessmentList } = await getSurveyList(token);
      setOnBoardingList(onBoardingOptionList);
      setConsentList(consentOptionList);
      setAssessmentList(clinicalAssessmentList);
      onBoardingSurveyList = onBoardingOptionList;
      consentSurveyList = consentOptionList;
      assessmentSurveyList = clinicalAssessmentList;
      setFetchSurveyList(false);
    }
    const defaultOBa = onBoardingSurveyList.find((oBa) => oBa.isDefault)?.value ?? '';
    setDefaultOnBoardingId(defaultOBa);
    const defaultConsentForm = consentSurveyList.find((consentObj) => consentObj.isDefault)?.value ?? '';
    setDefaultConsentId(defaultConsentForm);
    const defaultAssessmentId = assessmentSurveyList.find((assessmentObj) => assessmentObj.isDefault)?.value ?? '';
    setDefaultAssessmentId(defaultAssessmentId);

    const { clientProfiles: prefillClientProfiles, ...restPrefill } = prefill || {};
    formData.clientProfiles = prefillClientProfiles ? [...prefillClientProfiles] : formData.clientProfiles;

    if (onBoardingSurveyList.length === 0) {
      formData.clientProfiles = formData.clientProfiles.map((client) => {
        return {
          ...client,
          onboarding: {
            ...client.onboarding,
            isCheckOnBoarding: false
          }
        };
      });
    }

    const profileType =
      clientFormData.registeredAs === 'waitlist' ? 'recordOnly' : clientFormData.clientCapability.profileType;

    const newClientDetails = formData.clientProfiles.map((obj, idx) => ({
      ...obj,
      onboarding: {
        ...obj.onboarding,
        onboardingSurveyIds: []
      },
      consentForm: {
        ...obj.consentForm,
        consentFormIds: [],
        isCheckOnConsentForm: consentSurveyList.length > 0
      },
      assessmentForm: {
        assessmentFormIds: [],
        isCheckOnAssessmentForm: assessmentSurveyList.length > 0
      },
      ...(idx === 0 &&
        formData.recordType === ClientRecordType.YoungPerson && {
          isPrimaryContact: profileType === 'full'
        })
    }));
    setClientFormData({
      ...formData,
      ...restPrefill,
      registeredAs: clientFormData.registeredAs,
      clientProfiles: newClientDetails,
      clientCapability: {
        profileType: formData.recordType !== ClientRecordType.Child ? profileType : 'recordOnly'
      }
    });
    setPrefillLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchPrefillClientData(clientDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clientDetails]);

  return {
    clientFormData,
    setClientFormData,
    prefillLoading,
    onBoardingList,
    defaultOnBoardingId,
    consentList,
    defaultConsentId,
    fetchPrefillClientData,
    assessmentList,
    defaultAssessmentId
  };
};
