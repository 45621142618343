import { Skeleton } from 'antd';
import { useState } from 'react';

import CheckBoxWithDesc from 'components/CheckBoxWithDesc/CheckBoxWithDesc';
import FlexBox from 'components/FlexBox/FlexBox';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { PaymentMethod } from 'pages/Invoices/interface';
import { getPaymentMethodDescription } from 'pages/Invoices/components/PaymentMethods/PaymentMethods';
import PaymentMethodsModal from 'pages/Invoices/components/PaymentMethods/components/PaymentMethodsModal/PaymentMethodsModal';
import { selectInvoiceForm } from 'redux/invoices/createInvoiceWithTemplateSlice';
import { useAppSelector } from 'redux/hooks';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './IncludePaymentMethods.module.scss';

const TYPE_LABELS = {
  bankTransfer: 'Bank Transfer',
  stripe: 'Online Payment',
  paypal: 'Paypal',
  cash: 'Cash',
  other: 'Other'
};

interface IncludePaymentMethodsProps {
  paymentMethods: PaymentMethod[];
  isPaymentMethodsLoading: boolean;
  refetchPaymentMethods: () => void;
  onAddPaymentMethod: (paymentMethod: PaymentMethod) => void;
  onRemovePaymentMethod: (paymentMethod: PaymentMethod) => void;
}

const IncludePaymentMethods = ({
  onAddPaymentMethod,
  onRemovePaymentMethod,
  paymentMethods,
  isPaymentMethodsLoading,
  refetchPaymentMethods
}: IncludePaymentMethodsProps) => {
  const invoiceForm = useAppSelector(selectInvoiceForm);
  const { isEdgeReceptionist, isEdgeAdminView, isNormalUserView } = useGetAccountPackageView();
  const isEditingAllowed = isEdgeAdminView || isEdgeReceptionist || isNormalUserView;

  const [showPaymentSettingsModal, setShowPaymentSettingsModal] = useState(false);

  const handlePaymentSettingsModalClose = (paymentMethods?: PaymentMethod[]) => {
    setShowPaymentSettingsModal(false);
    paymentMethods && refetchPaymentMethods();
  };

  return (
    <FlexBox direction="column" spacing={16} className={styles.wrapper}>
      <FlexBox direction="row" alignItems="center" spacing={12}>
        <i className={`material-icons-outlined ${styles.icon}`}>account_balance</i>
        <span className={styles.text}>Payment methods</span>
      </FlexBox>
      {isPaymentMethodsLoading ? (
        <Skeleton className={styles.wrapper} active />
      ) : (
        <>
          <FlexBox direction="column" spacing={16}>
            {paymentMethods.map((paymentMethod) => {
              const { _id, accountName, email, instructions, paymentType, stripeConnectedBank, stripeStatus } =
                paymentMethod;

              const description = getPaymentMethodDescription({
                accountName,
                email,
                instructions,
                paymentType,
                stripeConnectedBank
              });

              const isDisabled = paymentType === 'stripe' && stripeStatus !== 'completed';

              return (
                <CheckBoxWithDesc
                  key={`select-payment-method-${_id}`}
                  id={`select-payment-method-${_id}`}
                  label={TYPE_LABELS[paymentType]}
                  checked={invoiceForm.paymentMethods.filter((item) => item._id === _id).length > 0}
                  disabled={isDisabled}
                  description={description}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onAddPaymentMethod(paymentMethod);
                    } else {
                      onRemovePaymentMethod(paymentMethod);
                    }
                  }}
                />
              );
            })}
            <div>
              <ButtonAlt
                variant="outlined"
                type="button"
                onClick={() => setShowPaymentSettingsModal(!showPaymentSettingsModal)}
                disabled={!isEditingAllowed}
                icon={'settings'}
                fullWidth
              >
                Add / change payment details
              </ButtonAlt>
            </div>
          </FlexBox>
          <PaymentMethodsModal
            paymentMethods={paymentMethods}
            isPaymentMethodsLoading={isPaymentMethodsLoading}
            visible={showPaymentSettingsModal}
            onClose={handlePaymentSettingsModalClose}
          />
        </>
      )}
    </FlexBox>
  );
};

export default IncludePaymentMethods;
