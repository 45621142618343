import { useState } from 'react';
import { Skeleton } from 'antd';
import { Tooltip } from 'react-tooltip';

import { RefetchMciDetails } from 'utils/hooks/useMCIDetails';
import ClientAssignList from './components/ClientAssignList/ClientAssignList';

import styles from './ClientsList.module.scss';
import { MicroCheckInAssessmentStruct } from 'pages/AssessmentDetails/MicroCheckInDetails/Interface';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { getName } from 'utils/general';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface ClientsListProps {
  fetchedAssignedClients: MicroCheckInAssessmentStruct['clients'];
  isEditMode: boolean;
  mciDetailsRefetching: boolean;
  refetchMciDetails: RefetchMciDetails;
}

const ClientsList = ({
  fetchedAssignedClients,
  isEditMode,
  mciDetailsRefetching,
  refetchMciDetails
}: ClientsListProps) => {
  const [showClientAssignmentModal, setShowClientAssignmentModal] = useState(false);
  const openClientAssignmentModal = () => setShowClientAssignmentModal(true);
  const closeClientAssignmentModal = () => setShowClientAssignmentModal(false);
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      {!isEditMode && (
        <>
          <div className={styles.inactive} data-tooltip-id="inactive" />
          <Tooltip id="inactive" className={styles.tooltip}>
            {t('client_program.tooltip')}
          </Tooltip>
        </>
      )}
      <div className={styles.clientTitle}>{t('client_program.title')}</div>
      <div className={styles.clientDesc}>
        <div>{t('client_program.description', { count: fetchedAssignedClients.length })}</div>
        <ButtonAlt
          size="small"
          variant="text"
          disabled={!isEditMode || mciDetailsRefetching}
          onClick={openClientAssignmentModal}
        >
          {t('button.add_or_change_client')}
        </ButtonAlt>
      </div>
      {mciDetailsRefetching ? (
        <Skeleton className={styles.container} />
      ) : (
        <>
          <div>
            {fetchedAssignedClients.map((clientProfileObj, index) => (
              <div className={styles.clientCard} key={index}>
                <ClientAvatar
                  name={getName(clientProfileObj)}
                  avatarUrl={clientProfileObj.avatar}
                  initialsName={clientProfileObj.initials}
                  avatarSize={40}
                  horizontal
                />
              </div>
            ))}
          </div>
          <ClientAssignList
            isEditMode={isEditMode}
            isShowModal={showClientAssignmentModal}
            closeModal={closeClientAssignmentModal}
            refetchMciDetails={refetchMciDetails}
          />
        </>
      )}
    </div>
  );
};

export default ClientsList;
