export interface EpisodeReason {
  medicalReason?: string;
  reasonUse?: string;
  details?: string;
}

export interface EpisodeInterface {
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
  episodeId: string;
  order: number;
  status: EpisodeStatus;
  type: string;
  reason: EpisodeReason;
  startDate: Date | string;
  endDate?: Date | string;
  permissions?: EpisodePermission[];
  createdBy: string;
  creatorName: string;
  accountId: string;
  clientRecordId: string;
  currentEpisodeStatus?: EpisodeStatus;
  currentEpisodeEndDate?: Date | string;
}

export interface EpisodePermission {
  clinicianId: string;
  edit: boolean;
  avatar: string;
  firstName: string;
  lastName: string;
}

export enum EpisodeStatus {
  Draft = 'draft',
  Planned = 'planned',
  Active = 'active',
  OnHold = 'onHold',
  Finished = 'finished',
  Cancelled = 'cancelled',
  Archived = 'archived',
  Error = 'error'
}

export enum ReasonUse {
  ChiefComplaint = 'Chief Complaint',
  HealthConcern = 'Health Concern',
  AdmittingDiagnosis = 'Admitting Diagnosis',
  ReasonForVisit = 'Reason for Visit',
  HealthMaintenance = 'Health Maintenance (including screening)'
}

export const EPISODE_STATUS_LABELS: Record<EpisodeStatus, string> = {
  draft: 'Draft',
  planned: 'Planned',
  active: 'Active',
  onHold: 'On Hold',
  finished: 'Finished',
  cancelled: 'Cancelled',
  archived: 'Archived',
  error: 'Error'
};
