import classNames from 'classnames';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import { useField } from 'formik';
import { Props } from 'react-select';
import styles from './RuleField.module.scss';

interface RuleFieldProps {
  name: string;
  desc: string;
  subDesc?: string;
  help?: string;
  optionLabel: string;
  options: { label: string; value: string | number }[];
}

const RuleField = ({ name, desc, subDesc, help, optionLabel, options }: RuleFieldProps) => {
  const [, { value: active }, { setValue: setActive }] = useField(`${name}.active`);

  const propStyles: Props['styles'] = {
    container: (base) => ({ ...base, margin: 0 }),
    control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: `1px solid ${styles.greyColor}`,
      borderRadius: 0,
      boxShadow: 'none',
      minHeight: 0,
      minWidth: '150px'
    }),
    valueContainer: (base) => ({ ...base, padding: 0 }),
    dropdownIndicator: (base) => ({ ...base, padding: 0 })
  };
  return (
    <div
      className={classNames(styles.container, {
        [styles.active]: active
      })}
    >
      <label className={styles.label}>
        <input className={styles.checkbox} type="checkbox" checked={active} onChange={() => setActive(!active)} />
        <div className={styles.descGroup}>
          <div className={styles.descContainer}>
            <span className={styles.desc}>{desc}</span>
            {help && <HelpOutLineWithTooltips id={`${name}-help`} desc={help} iconClass={styles.helpIcon} />}
          </div>
          {subDesc && <span className={styles.subDesc}>{subDesc}</span>}
        </div>
      </label>
      {active && (
        <FormikSelect
          className={styles.select}
          name={`${name}.value`}
          label={optionLabel}
          options={options}
          styles={propStyles}
        />
      )}
    </div>
  );
};

export default RuleField;
