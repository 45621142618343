import MaterialInput from 'components/MaterialInput/MaterialInput';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { ButtonStatusType } from 'components/v2/Button/Button';
import { Popconfirm } from 'antd';
import styles from './DataCategoryHeader.module.scss';
import classNames from 'classnames';
import { CLASSIFICATION_OPTIONS, ClassificationTypes, OpenDataCategory } from '../../../../CollectData.interface';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import Select from 'components/v2/Select/Select';
import { Props } from 'react-select';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';

interface DataCategoryHeaderProps {
  editing: boolean;
  value: OpenDataCategory;
  showError: boolean;
  editingCategory: string;
  deleteStatus: ButtonStatusType;
  category?: OpenDataCategory;
  isAddNew?: boolean;
  canReorder?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  handleDelete: () => void;
  handleDiscardChange: () => void;
  setEditingCategory: (id: string) => void;
  setValue: (data: OpenDataCategory) => void;
}

const DataCategoryHeader = ({
  category,
  editing,
  value,
  showError,
  isAddNew,
  editingCategory,
  canReorder,
  dragHandleProps,
  deleteStatus,
  setValue,
  setEditingCategory,
  handleDelete,
  handleDiscardChange
}: DataCategoryHeaderProps) => {
  const { isHealthDataEnabled } = useGetFeatureToggle();
  const propStyles: Props['styles'] = {
    control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom:
        showError && !value.classification ? `1px solid ${styles.redColor}` : `1px solid ${styles.greyColor}`,
      borderRadius: 0,
      boxShadow: 'none',
      height: '29px',
      minHeight: '29px',
      zIndex: 99
    })
  };
  return (
    <div className={styles.header}>
      {!editing ? (
        <div className={styles.headerPreviewWrapper}>
          <div className={styles.title}>
            <div className={styles.label}>Category Name</div>
            <div>{value.name}</div>
          </div>
          {value.classification && isHealthDataEnabled && (
            <div className={styles.classificationBadge}>
              {
                CLASSIFICATION_OPTIONS.find((classificationObj) => classificationObj.value === value.classification)
                  ?.label
              }
            </div>
          )}
        </div>
      ) : (
        <div className={styles.leftActionWrapper}>
          <div className={classNames(styles.name, showError && !value.name && styles.fieldError)}>
            <MaterialInput
              id={'categoryName'}
              label="Category Name"
              value={value?.name || ''}
              onChange={(v) => setValue({ ...value, name: v.target.value })}
              className={styles.nameInput}
              autoFocus={isAddNew}
            />
            <ErrorMessage
              visible={showError && !value.name}
              error="Please enter category's name"
              className={styles.nameInputError}
            />
          </div>
          {isHealthDataEnabled && (
            <div className={classNames(styles.classification, showError && !value.classification && styles.fieldError)}>
              <div className={styles.label}>Classification</div>
              <Select
                options={CLASSIFICATION_OPTIONS}
                value={CLASSIFICATION_OPTIONS.find(
                  (classificationObj) => classificationObj.value === value.classification
                )}
                onChange={(v) => setValue({ ...value, classification: v?.value as ClassificationTypes })}
                isSearchable={false}
                valueStyle={{ padding: '4px 0' }}
                styles={propStyles}
              />
              <ErrorMessage
                visible={showError && !value.classification}
                error="Please chooose classification type"
                className={styles.nameInputError}
              />
            </div>
          )}
        </div>
      )}
      <div className={styles.action}>
        {!editing ? (
          <>
            <ButtonAlt
              size={'medium'}
              variant="text"
              icon={value.defaultCategoryId ? 'lock' : 'edit'}
              disabled={!!value.defaultCategoryId || editingCategory !== ''}
              onClick={() => setEditingCategory(category?._id || '')}
            >
              {value.defaultCategoryId ? `Basic ${value.name.split(' ')[0]} Data` : 'Edit'}
            </ButtonAlt>
            <div {...dragHandleProps}>
              <ButtonAlt
                useDivAsBtn
                size={'medium'}
                variant="text"
                icon={'import_export'}
                disabled={!canReorder || editingCategory !== ''}
                fab
              />
            </div>
          </>
        ) : (
          <>
            <Popconfirm
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No"
              title="Do you want to delete this category?"
            >
              <ButtonAlt error variant="text" size={'medium'} status={deleteStatus} icon="delete">
                Delete Category
              </ButtonAlt>
            </Popconfirm>
            <ButtonAlt size={'medium'} variant={'outlined'} onClick={handleDiscardChange}>
              Discard Changes
            </ButtonAlt>
          </>
        )}
      </div>
    </div>
  );
};

export default DataCategoryHeader;
