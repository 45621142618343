import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';
import queryString from 'query-string';
import { ClassificationTypes } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';

const CHECK_IN_SERVICE_URL = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const getCommonVariables = () =>
  httpClient('GET', CHECK_IN_SERVICE_URL!, 200)(`/open-data/common-variables-scale-types`) as Promise<BentResponse>;

export const getOpenDataCategories = (token: string, accountId: string, classification?: ClassificationTypes) =>
  httpClient('GET', CHECK_IN_SERVICE_URL!, 200, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/open-data/categories${classification ? `?classification=${classification}` : ''}`
  ) as Promise<BentResponse>;

export const postOpenDataCategory = (token: string, accountId: string, body: Record<string, any>) =>
  httpClient('POST', CHECK_IN_SERVICE_URL!, 201, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/open-data/categories`,
    body
  ) as Promise<BentResponse>;

export const putOpenDataCategory = (
  token: string,
  accountId: string,
  openDataCategoryId: string,
  body: Record<string, any>
) =>
  httpClient('PUT', CHECK_IN_SERVICE_URL!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/open-data/categories/${openDataCategoryId}`,
    body
  ) as Promise<BentResponse>;

export const deleteOpenDataCategory = (token: string, accountId: string, openDataCategoryId: string) =>
  httpClient('DELETE', CHECK_IN_SERVICE_URL!, 204, { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/open-data/categories/${openDataCategoryId}`
  ) as Promise<BentResponse>;

export const reorderCategories = (token: string, accountId: string, body: { categoriesOrder: string[] }) =>
  httpClient('PUT', CHECK_IN_SERVICE_URL!, 204, {
    Authorization: `Bearer ${token}`
  })(`/accounts/${accountId}/open-data/reorder-categories`, body) as Promise<BentResponse>;

export const getClientData = (
  token: string,
  clientRecordId: string,
  query?: { classification?: string; episodeId?: string; showOutsideOfEpisode?: boolean }
) => {
  const qParam = query ? `?${queryString.stringify(query)}` : '';
  return httpClient('GET', CHECK_IN_SERVICE_URL!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-data${qParam}`
  ) as Promise<BentResponse>;
};

export const getSimpleClientData = (token: string, clientRecordId: string) =>
  httpClient('GET', CHECK_IN_SERVICE_URL!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-data/minified`
  ) as Promise<BentResponse>;

export const putClientData = (
  token: string,
  clientRecordId: string,
  variableId: string,
  body: { clientProfileId?: string; value: string | string[] | unknown; categoryId: string }
) =>
  httpClient('PUT', CHECK_IN_SERVICE_URL!, 204, { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-data/${variableId}`,
    body
  ) as Promise<BentResponse>;
