import classNames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import Select from 'components/v2/Select/Select';
import { useState } from 'react';
import { OpenDataFieldVariable } from '../../CollectData.interface';
import styles from './VariableForm.module.scss';
import { QuestionType } from 'interfaces/checkInService/AssessmentInterfaces';
import MultipleChoiceOptions from './components/MultipleChoiceOptions/MultipleChoiceOptions';
import ScaleTypes from './components/ScaleTypes/ScaleTypes';
import { QuestionTypeDisplay } from '../OpenDataVariable/OpenDataVariable';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { v4 } from 'uuid';

const formats = [
  {
    label: 'Multiple Choice',
    value: QuestionType.multipleChoice
  },
  {
    label: 'Multiple Choice Free Text',
    value: QuestionType.multipleChoiceFreeText
  },
  {
    label: 'Scale',
    value: QuestionType.scale
  },
  {
    label: 'Text',
    value: QuestionType.freeText
  },
  {
    label: 'Number',
    value: QuestionType.numeric
  },
  {
    label: 'Date',
    value: QuestionType.date
  },
  {
    label: 'Email Address',
    value: QuestionType.email
  },
  {
    label: 'Phone Number',
    value: QuestionType.phone
  }
];

const DEFAULT_COMMON_VARIABLE = { variableName: '', isCommonVariable: true, stem: '' };

const VariableForm = ({
  initValue,
  onSubmit,
  onCancel,
  commonVariables,
  scaleTypes,
  isCommonVariablesLoading
}: {
  initValue?: Partial<OpenDataFieldVariable>;
  onSubmit: (value: Partial<OpenDataFieldVariable>) => void;
  onCancel: () => void;
  commonVariables: OpenDataFieldVariable[];
  scaleTypes: OpenDataFieldVariable[];
  isCommonVariablesLoading: boolean;
}) => {
  const [value, setValue] = useState<Partial<OpenDataFieldVariable>>(initValue || DEFAULT_COMMON_VARIABLE);
  const [showError, setShowError] = useState(false);

  const handleSubmit = () => {
    setShowError(true);
    if (value.isCommonVariable) {
      if (!value.variableId || !value.stem) {
        return;
      }
    } else {
      if (!value.variableName || !value.stem || !value.type) {
        return;
      } else if (value.type === 'multipleChoice' && !((value.options?.length || 0) > 0)) {
        return;
      }
    }

    const finalValue: Partial<OpenDataFieldVariable> = {
      ...value,
      id: value.id || v4(),
      ...(value.type === 'multipleChoice' && {
        options: value.options,
        maxSelection: value.maxSelection === undefined ? 1 : value.maxSelection
      }),
      isCommonVariable: value.isCommonVariable || false,
      variableName: value.variableName?.trim()
    };

    onSubmit(finalValue);
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {!initValue && (
          <ToggleSwitch
            buttonWidth={320}
            id={'isCommonVariable'}
            checkLabel="Common Variable"
            labelClassName={styles.toggleSwitch}
            unCheckLabel="Create Custom"
            isCheckedProps={value.isCommonVariable || false}
            onChangeProps={(e) =>
              setValue({
                ...value,
                isCommonVariable: e.target.checked,
                variableName: '',
                stem: '',
                type: undefined,
                variableId: undefined
              })
            }
          />
        )}
      </div>
      {value.isCommonVariable ? (
        <>
          <div
            className={classNames(styles.row, value.isCommonVariable ? styles.commonVariable : styles.customVariable)}
          >
            <div className={styles.question}>
              <div className={styles.label}>Select common variable</div>
              <Select
                options={commonVariables.map((i) => ({ label: i.variableName, value: i.variableId }))}
                value={
                  commonVariables
                    .filter((i) => i.variableId === value.variableId)
                    .map((i) => ({ label: i.variableName, value: i.variableId }))[0]
                }
                onChange={(v) =>
                  v &&
                  setValue({ ...DEFAULT_COMMON_VARIABLE, ...commonVariables.find((i) => i.variableId === v.value) })
                }
                isSearchable={false}
                isLoading={isCommonVariablesLoading}
                valueStyle={{ padding: '4px 0' }}
              />
              <ErrorMessage visible={showError && !value.variableId} error={'Please select common field'} />
            </div>
            <div className={styles.variableId}>
              <div className={styles.label}>Variable ID</div>
              {value.variableId}
            </div>
            <div className={styles.format}>
              <div className={styles.label}>Answer format</div>
              {value.type ? QuestionTypeDisplay[value.type] : ''}
            </div>
          </div>
          <div
            className={classNames(styles.row, value.isCommonVariable ? styles.commonVariable : styles.customVariable)}
          >
            <div className={styles.stem}>
              <MaterialInput
                id={'question'}
                label="Formatted as Question"
                value={value.stem || ''}
                onChange={(v) => setValue({ ...value, stem: v.target.value })}
                className={styles.input}
              />
              <ErrorMessage
                visible={showError && !value.stem}
                error={'Please fill in your question for this data field'}
              />
            </div>
          </div>
          {value.type === 'scale' && (
            <ScaleTypes variable={value} scaleQuestions={scaleTypes} allowEdit={false} setVariable={setValue} />
          )}
        </>
      ) : (
        <>
          <div
            className={classNames(styles.row, value.isCommonVariable ? styles.commonVariable : styles.customVariable)}
          >
            <div className={styles.variableName}>
              <MaterialInput
                id={'variableName'}
                label="Variable name"
                value={value.variableName || ''}
                onChange={(v) => setValue({ ...value, variableName: v.target.value })}
                className={styles.input}
              />
              <ErrorMessage visible={showError && !value.variableName} error={'Please enter variable name'} />
            </div>
            <div className={styles.answerFormat}>
              <div className={styles.label}>Answer format</div>
              {initValue ? (
                <div>{formats.find((i) => i.value === value.type)?.label}</div>
              ) : (
                <Select
                  options={formats}
                  value={formats.find((i) => i.value === value.type)}
                  onChange={(v) =>
                    v && setValue({ variableName: value.variableName, stem: value.stem, type: v.value as QuestionType })
                  }
                  isSearchable={false}
                />
              )}
              <ErrorMessage visible={showError && !value.type} error={'Please select format for this data field'} />
            </div>
          </div>
          <div
            className={classNames(styles.row, value.isCommonVariable ? styles.commonVariable : styles.customVariable)}
          >
            <div className={styles.stem}>
              <MaterialInput
                id={'formattedQuestion'}
                label="Formatted as Question"
                value={value.stem || ''}
                onChange={(v) => setValue({ ...value, stem: v.target.value })}
                className={styles.input}
              />
              <ErrorMessage
                visible={showError && !value.stem}
                error={'Please fill in your question for this data field'}
              />
            </div>
          </div>
          {value.type && [QuestionType.multipleChoice, QuestionType.multipleChoiceFreeText].includes(value.type) && (
            <MultipleChoiceOptions
              allowEditMoreThanOne={!initValue}
              variable={value}
              setVariable={setValue}
              showError={showError}
            />
          )}
          {value.type === 'scale' && (
            <ScaleTypes variable={value} scaleQuestions={scaleTypes} allowEdit={!initValue} setVariable={setValue} />
          )}
        </>
      )}
      <div className={styles.buttons}>
        <ButtonAlt
          onClick={() => {
            handleSubmit();
          }}
        >
          {initValue ? 'Save question' : 'Add this question'}
        </ButtonAlt>
        <ButtonAlt onClick={() => onCancel()} variant="outlined">
          Cancel
        </ButtonAlt>
      </div>
    </div>
  );
};

export default VariableForm;
