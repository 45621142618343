import { AvailabilityTimeSlotsByDate } from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import moment from 'moment';
import momentTz from 'moment-timezone';

export const convertTimeToUtcDateObject = ({
  date,
  time,
  timeZone
}: {
  date: string;
  time: string;
  timeZone?: string;
}) => {
  return timeZone
    ? momentTz.tz(`${date} ${time}`, `YYYY-MM-DD HH:mm`, timeZone).utc().toDate()
    : moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').utc().toDate();
};

export const convertUtcTimeToClinicianTime = ({
  startDateTime,
  endDateTime,
  timezone
}: {
  startDateTime: Date;
  endDateTime: Date;
  timezone: string;
}) => ({
  date: momentTz.tz(startDateTime, timezone).format('YYYY-MM-DD'),
  startTime: momentTz.tz(startDateTime, timezone).format('HH:mm'),
  endTime: momentTz.tz(endDateTime, timezone).format('HH:mm'),
  startDateTime: moment(startDateTime).toDate(),
  endDateTime: moment(endDateTime).toDate()
});

export const convertAppointmentTimeBetweenTimezones = ({
  date,
  startTime,
  endTime,
  originalTimezone,
  targetTimezone
}: {
  date: string;
  startTime: string;
  endTime: string;
  originalTimezone: string;
  targetTimezone: string;
}) => {
  const startDateTime = momentTz.tz(`${date} ${startTime}`, `YYYY-MM-DD HH:mm`, originalTimezone).tz(targetTimezone);
  const endDateTime = momentTz.tz(`${date} ${endTime}`, `YYYY-MM-DD HH:mm`, originalTimezone).tz(targetTimezone);
  return {
    startDateTime,
    endDateTime
  };
};

export const mapUtcToClinicianTimeInTimeSlots = (timeSlots: AvailabilityTimeSlotsByDate, timezone: string) =>
  Object.entries(timeSlots).reduce((onlyAvailableTimeSlots, [date, dateTimeSlots]) => {
    if (dateTimeSlots.isAvailable) {
      const timeSlots = dateTimeSlots.timeSlots;
      if (timeSlots.length > 0) {
        onlyAvailableTimeSlots[date] = {
          ...dateTimeSlots,
          timeSlots: timeSlots.map((slot) => ({
            ...slot,
            ...(slot.endDateTime &&
              slot.startDateTime &&
              convertUtcTimeToClinicianTime({
                endDateTime: slot.endDateTime,
                startDateTime: slot.startDateTime,
                timezone
              }))
          }))
        };
      }
    } else {
      onlyAvailableTimeSlots[date] = {
        ...dateTimeSlots,
        noAvailabilityReason: dateTimeSlots.noAvailabilityReason
      };
    }

    return onlyAvailableTimeSlots;
  }, {} as AvailabilityTimeSlotsByDate);
