import { Skeleton } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FlexBox from 'components/FlexBox/FlexBox';
import FilterSelect from 'components/Select/FilterSelect/FilterSelect';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { Appointment } from 'pages/InvoiceGenerator/interface';

import styles from './IncludeAppointments.module.scss';
import AppointmentItem from '../AppointmentItem/AppointmentItem';
import GroupAppointmentItem from '../GroupAppointmentItem/GroupAppointmentItem';

interface IncludeAppointmentsProps {
  isAppointmentsLoading: boolean;
  appointments: Appointment[];
  selectedAppointmentIds: string[];
  participationType: ParticipantType;
  isClientSelected: boolean;
  isGroupSelected: boolean;
  onAddAppointment: (appointment: Appointment) => void;
  onRemoveAppointment: (appointment: Appointment) => void;
  isAddAppointmentItemDisabled: boolean;
}

const IncludeAppointments = ({
  isAppointmentsLoading,
  appointments,
  selectedAppointmentIds,
  isClientSelected,
  isGroupSelected,
  participationType,
  onAddAppointment,
  onRemoveAppointment,
  isAddAppointmentItemDisabled
}: IncludeAppointmentsProps) => {
  const [t] = useTranslation();
  const recipientLabel =
    participationType === ParticipantType.Group ? t('label.group.capitalize') : t('label.client.capitalize');

  const [filter, setFilter] = useState('Unattached');

  const { addedAppointments, unaddedAppointments } = useMemo(() => {
    if (!appointments) {
      return { addedAppointments: [], unaddedAppointments: [] };
    }

    const addedAppointments = appointments.filter((appointment) => selectedAppointmentIds.includes(appointment._id));
    let unaddedAppointments = appointments.filter((appointment) => !selectedAppointmentIds.includes(appointment._id));

    if (filter === 'Unattached') {
      unaddedAppointments = unaddedAppointments.filter(
        (appointment) => (!appointment.invoices || appointment.invoices.length === 0) && appointment.type !== 'reserved'
      );
    }

    return { addedAppointments, unaddedAppointments };
  }, [appointments, filter, selectedAppointmentIds]);

  const handleFilterClick = (value: string) => {
    setFilter(value);
  };

  const filterOptions = ['Unattached', 'All'].map((option) => ({
    label: option,
    value: option
  }));

  return (
    <FlexBox direction="column" spacing={16}>
      <FlexBox direction="row" alignItems="center" spacing={12} className={styles.wrapper}>
        <i className={`material-icons-outlined ${styles.icon}`}>event</i>
        <span className={styles.text}>Include appointments</span>
      </FlexBox>
      {isAppointmentsLoading ? (
        <Skeleton active className={styles.wrapper} />
      ) : (
        <>
          {addedAppointments && addedAppointments.length > 0 && (
            <FlexBox direction="column" className={styles.appointmentsWrapper}>
              {addedAppointments.map((appointment, index) => {
                return participationType === ParticipantType.Group && isGroupSelected ? (
                  <GroupAppointmentItem
                    key={index}
                    appointment={appointment}
                    onAddAppointment={onAddAppointment}
                    onRemoveAppointment={onRemoveAppointment}
                    included
                    isAddAppointmentItemDisabled={isAddAppointmentItemDisabled}
                  />
                ) : (
                  <AppointmentItem
                    key={index}
                    appointment={appointment}
                    onAddAppointment={onAddAppointment}
                    onRemoveAppointment={onRemoveAppointment}
                    included
                    isAddAppointmentItemDisabled={isAddAppointmentItemDisabled}
                  />
                );
              })}
            </FlexBox>
          )}
          <FlexBox direction="row" className={styles.wrapper}>
            <FilterSelect options={filterOptions} value={filter} onSelected={(value) => handleFilterClick(value)} />
          </FlexBox>
          {!isClientSelected && !isGroupSelected ? (
            <div className={styles.notSelected}>{recipientLabel} not selected</div>
          ) : (
            <FlexBox direction="column" spacing={16} className={styles.unaddedAppointmentsWrapper}>
              {unaddedAppointments.map((appointment, index) => {
                return participationType === ParticipantType.Group && isGroupSelected ? (
                  <GroupAppointmentItem
                    key={index}
                    appointment={appointment}
                    onAddAppointment={onAddAppointment}
                    onRemoveAppointment={onRemoveAppointment}
                    isAddAppointmentItemDisabled={isAddAppointmentItemDisabled}
                  />
                ) : (
                  <AppointmentItem
                    key={index}
                    appointment={appointment}
                    onAddAppointment={onAddAppointment}
                    onRemoveAppointment={onRemoveAppointment}
                    isAddAppointmentItemDisabled={isAddAppointmentItemDisabled}
                  />
                );
              })}
            </FlexBox>
          )}
        </>
      )}
    </FlexBox>
  );
};

export default IncludeAppointments;
