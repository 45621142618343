/* eslint-disable complexity */
import { useGetAccessToken } from 'utils/hooks/token';
import { clientRecordsInterface, ClientRecordType, RecordStatus } from 'interfaces/Clients/clientsRecord';

import PatientDetailsInsights from './components/PatientDetailsInsights/PatientDetailsInsights';
import PatientDetailsInvoices from './components/PatientDetailsInvoices/PatientDetailsInvoices';
import PatientDetailsReports from './components/PatientDetailsReports/PatientDetailsReports';
import PatientDetailsAppointments from './components/PatientDetailsAppointments/PatientDetailsAppointments';
import PatientDetailsNotes from './components/PatientDetailsNotes/PatientDetailsNotes';
import PatientDetailsAttachments from './components/PatientDetailsAttachments/PatientDetailsAttachments';
import PatientAssignment from './components/PatientAssignment/PatientAssignment';
import PatientDetailsActivity from './components/PatientDetailsActivity/PatientDetailsActivity';

import PatientDetailsProfile from './components/PatientDetailsProfile/PatientDetailsProfile';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import styles from './PatientDetailsContent.module.scss';

import PatientDetailsSurveys from './components/PatientDetailsSurveys/PatientDetailsSurveys';
import PatientDetailsReferrers from './components/PatientDetailsReferrers/PatientDetailsReferrers';
import PatientDetailsMedicare from './components/PatientDetailsMedicare/PatientDetailsMedicare';
import AssessmentResult from './components/AssessmentResult/AssessmentResult';
import PatientDetailsClientData from './components/PatientDetailsClientData/PatientDetailsClientData';
import { useGetClinicianProfileQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import PatientDetailsLetters from './components/PatientDetailsLetters/PatientDetailsLetters';
import ClientDetailsEoC from './components/ClientDetailsEoC/ClientDetailsEoC';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { useGetClientDetailsTabInfo } from '../../../../components/SideDashboardT23/components/ClientSubTab/ClientProfileSubTab/constants';
import { ClassificationTypes } from '../../../ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';
import { Ref } from 'react';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import PatientDetailsInvoicesV2 from './components/PatientDetailsInvoicesV2/PatientDetailsInvoicesV2';
import { NotificationSettingsInterface } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';

export interface DataRefreshHandle {
  onDataRefresh: () => void;
}

interface PatientDetailsContentProps {
  recordId: string;
  profileId: string;
  isClientLoading: boolean;
  clientRecordData: clientRecordsInterface;
  currentTab: string;
  assessmentOrReportId?: string;
  refreshActivity?: number;
  allowCreateNewInvoice: boolean;
  notificationSettings: NotificationSettingsInterface;
  dataRefreshRef?: Ref<DataRefreshHandle>;
  setIsOnboardingModalVisible: (value: boolean) => void;
}

export interface Patient {
  _id: string;
  name: string;
  avatar: string;
  isReferredByGP: boolean;
  status: string;
  signupDate: string;
}

const PatientDetailsContent = ({
  recordId,
  profileId,
  currentTab,
  assessmentOrReportId,
  refreshActivity,
  clientRecordData,
  isClientLoading,
  allowCreateNewInvoice,
  notificationSettings,
  dataRefreshRef,
  setIsOnboardingModalVisible
}: PatientDetailsContentProps) => {
  const { token } = useGetAccessToken();
  const { isInvoiceListV2Enabled } = useGetFeatureToggle();

  const { isEdgeAdminView, isEdgeReceptionistView, isEdgeReceptionist } = useGetAccountPackageView();

  const { data: profileData, isLoading: isProfileLoading } = useGetClinicianProfileQuery();

  const {
    profile,
    'client-data': clientData,
    'health-data': healthData,
    activity,
    episodes,
    notes,
    attachments,
    survey,
    assessments,
    checkIn,
    report,
    letters,
    appointments,
    invoices,
    assignment,
    referrers,
    medicare
  } = useGetClientDetailsTabInfo(clientRecordData);

  const getContentComponents = () => {
    switch (currentTab) {
      case activity.tabId:
        return (
          activity.isDisplay && (
            <PatientDetailsActivity
              clientRecordData={clientRecordData}
              isClientRecordLoading={isClientLoading}
              profile={profileData ?? ({} as ProfileInterface)}
              isProfileLoading={isProfileLoading}
              refreshActivity={refreshActivity}
            />
          )
        );
      case notes.tabId:
        return (
          notes.isDisplay && (
            <PatientDetailsNotes
              recordId={recordId}
              profileId={profileId}
              currentTab={currentTab}
              noteId={assessmentOrReportId}
              clientRecordData={clientRecordData}
              token={token}
            />
          )
        );
      case assessments.tabId:
        return (
          assessments.isDisplay && (
            <AssessmentResult
              isEdgeReceptionist={isEdgeReceptionist}
              recordId={recordId}
              assessmentId={assessmentOrReportId}
              clientRecord={clientRecordData}
              notificationSettings={notificationSettings}
              ref={dataRefreshRef}
            />
          )
        );
      case checkIn.tabId:
        return (
          checkIn.isDisplay && (
            <PatientDetailsInsights
              clientRecordData={clientRecordData}
              profileId={assessmentOrReportId || profileId}
              currentTab={currentTab}
              token={token}
            />
          )
        );
      case report.tabId:
        return (
          report.isDisplay && (
            <PatientDetailsReports
              recordId={recordId}
              currentTab={currentTab}
              reportId={assessmentOrReportId}
              clientRecordData={clientRecordData}
            />
          )
        );
      case letters.tabId:
        return (
          letters.isDisplay && (
            <PatientDetailsLetters
              recordId={recordId}
              currentTab={currentTab}
              letterId={assessmentOrReportId}
              clientRecordData={clientRecordData}
            />
          )
        );
      case survey.tabId:
        return (
          survey.isDisplay && (
            <PatientDetailsSurveys
              isEdgeReceptionist={isEdgeReceptionist}
              recordId={recordId}
              currentTab={currentTab}
              surveyId={assessmentOrReportId}
              clientRecordData={clientRecordData}
              ref={dataRefreshRef}
            />
          )
        );
      case appointments.tabId:
        return (
          appointments.isDisplay && (
            <PatientDetailsAppointments
              recordId={recordId}
              profile={profileData ?? ({} as ProfileInterface)}
              clientRecordData={clientRecordData}
              isProfileLoading={isProfileLoading}
              ref={dataRefreshRef}
            />
          )
        );
      case invoices.tabId:
        return (
          invoices.isDisplay &&
          (isInvoiceListV2Enabled ? (
            <PatientDetailsInvoicesV2 recordId={recordId} clientRecord={clientRecordData} ref={dataRefreshRef} />
          ) : (
            <PatientDetailsInvoices
              allowCreateNewInvoice={allowCreateNewInvoice && clientRecordData.recordStatus !== RecordStatus.Closed}
              recordId={recordId}
              ref={dataRefreshRef}
            />
          ))
        );
      case attachments.tabId:
        return (
          attachments.isDisplay && (
            <PatientDetailsAttachments
              recordId={recordId}
              token={token}
              isReadOnly={clientRecordData.recordStatus === RecordStatus.Closed}
            />
          )
        );
      case profile.tabId:
        return (
          profile.isDisplay && (
            <PatientDetailsProfile
              clientRecordData={clientRecordData}
              isClientRecordLoading={isClientLoading}
              recordId={recordId}
              notificationSettings={notificationSettings}
              setIsOnboardingModalVisible={setIsOnboardingModalVisible}
            />
          )
        );
      case assignment.tabId:
        return (
          assignment.isDisplay &&
          (isEdgeAdminView || isEdgeReceptionistView) && (
            <PatientAssignment
              clientRecordData={clientRecordData}
              isClientRecordLoading={isClientLoading}
              recordId={recordId}
            />
          )
        );
      case referrers.tabId:
        return referrers.isDisplay && <PatientDetailsReferrers recordId={recordId} />;
      case medicare.tabId:
        return (
          medicare.isDisplay &&
          (clientRecordData.recordType === ClientRecordType.Adult ||
            clientRecordData.recordType === ClientRecordType.YoungPerson ||
            clientRecordData.recordType === ClientRecordType.Child) && (
            <PatientDetailsMedicare clientRecordData={clientRecordData} />
          )
        );
      case clientData.tabId:
        return (
          clientData.isDisplay && (
            <PatientDetailsClientData
              clientRecord={clientRecordData}
              classification={ClassificationTypes.ProfileData}
            />
          )
        );
      case healthData.tabId:
        return (
          clientData.isDisplay && (
            <PatientDetailsClientData clientRecord={clientRecordData} classification={ClassificationTypes.HealthData} />
          )
        );
      case episodes.tabId:
        return episodes.isDisplay && <ClientDetailsEoC clientRecord={clientRecordData} />;
    }
  };

  return <div className={styles.container}>{getContentComponents()}</div>;
};

export default PatientDetailsContent;
