import { AppointmentType } from 'interfaces/Schedule/AppointmentType';
import { AppointmentStatusOption } from 'interfaces/Schedule/Appointment';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { CreatePackageFunderInterface } from '../Packages/packages';

export enum CarePathwayCardAction {
  ChangeStatus = 'changeStatus',
  Edit = 'edit',
  Delete = 'delete'
}

export enum CarePathwayStatus {
  Active = 'active',
  Closed = 'closed',
  Completed = 'completed',
  Draft = 'draft'
}

export const CARE_PATHWAY_STATUS_LABELS: Record<CarePathwayStatus, string> = {
  draft: 'Draft',
  active: 'Active',
  closed: 'Closed',
  completed: 'Completed'
};

export enum carePathwayItemType {
  Appointment = 'appointment'
}

export interface CarePlanAppointmentType {
  appointmentType: AppointmentType;
  appointmentTypeId?: string;
  _id?: string;
  active?: boolean;
  preferredDeliveryType?: string;
  isProcessing?: boolean;
  isDropped?: boolean;
}

export interface carePlanAppointments {
  startDateTime: Date | string;
  endDateTime: Date | string;
  clinicianId: string;
  carePathwayItemId?: string;
  deliveryType?: string;
  roomId?: string;
  clinician?: {
    name: string;
    _id: string;
  };
  markedStatus?: AppointmentStatusOption[];
  _id?: string;
}

export interface CarePlanAppointments extends carePlanAppointments, MbscCalendarEvent {}

export interface CarePathwayPackage {
  id: string;
  name?: string;
  funder: CreatePackageFunderInterface;
}

export interface CarePathway {
  _id?: string;
  accountId?: string;
  name: string;
  description: string;
  note: string;
  isNoteVisibleToClient: boolean;
  shortCode?: string;
  status: CarePathwayStatus;
  clientRecord?: {
    _id: string;
    name: string;
    clientProfiles?: {
      _id: string;
      name: string;
      firstName: string;
      lastName: string;
    }[];
  };
  leadClinician?: FilterCheckListItem;
  clinicians: FilterCheckListItem[];
  template?: FilterCheckListItem;
  items: CarePlanAppointmentType[];
  appointments: CarePlanAppointments[];
  createdAt?: string;
  updatedAt?: string;
  package?: CarePathwayPackage;
}

export interface CarePathwayAppointmentItem
  extends Omit<CarePlanAppointmentType, 'appointmentType' | 'active' | 'appointmentId'> {
  appointmentTypeId: string;
  appointmentType?: AppointmentType;
}

export interface CreateCarePathway
  extends Omit<CarePathway, 'clientRecord' | 'leadClinician' | 'clinicians' | 'appointments' | 'items'> {
  clientRecordId: string;
  leadClinicianAuth0Id: string;
  clinicianAuth0Ids: string[];
  appointments: carePlanAppointments[];
  items?: CarePathwayAppointmentItem[];
}

export interface CarePlanAppointmentTypeList extends CarePlanAppointmentType {
  appointment: CarePlanAppointments;
}

export interface CarePathwayList extends Omit<CarePathway, 'items' | 'appointments'> {
  items: CarePlanAppointmentTypeList[];
}

export type GetCarePathwayResponse = {
  carePathways: CarePathwayList[];
  paging: {
    totalItems: number;
    page: number;
    perPage: number;
  };
};

export type GetCarePathwayCountResponse = {
  active: number;
  closed: number;
  completed: number;
  draft: number;
};
