const UKTerms = () => {
  return (
    <div>
      <p>&nbsp;</p>
      <p>
        <strong>Tacklit Practitioner Terms Of Use - UK</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>These terms and conditions (&ldquo;</span>
        <strong>Terms</strong>
        <span style={{ fontWeight: 400 }}>&rdquo;) govern all Practitioners (defined as &ldquo;</span>
        <strong>Practitioner</strong>
        <span style={{ fontWeight: 400 }}>&rdquo;, &ldquo;</span>
        <strong>you</strong>
        <span style={{ fontWeight: 400 }}>&rdquo; and &ldquo;</span>
        <strong>your</strong>
        <span style={{ fontWeight: 400 }}>&rdquo;) that:</span>
      </p>
      <ul>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>have signed up and registered an account with Tacklit (the </span>
          <strong>Account</strong>
          <span style={{ fontWeight: 400 }}>);</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>have accessed the Practitioner Portal via </span>
          <a href="http://www.tacklit.com">
            <span style={{ fontWeight: 400 }}>www.tacklit.com</span>
          </a>
          <span style={{ fontWeight: 400 }}> (the </span>
          <strong>Site</strong>
          <span style={{ fontWeight: 400 }}>);</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            have utilised any features of the Site or Tacklit&rsquo;s Products offered through the Site or other
            customer touchpoints (the{' '}
          </span>
          <strong>Service</strong>
          <span style={{ fontWeight: 400 }}>); and</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>have utilised any features of any Tacklit APIs (the </span>
          <strong>APIs</strong>
          <span style={{ fontWeight: 400 }}>) directly or via an authorised third party provider (</span>
          <strong>Partner</strong>
          <span style={{ fontWeight: 400 }}>).</span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Your access to and use of the information, materials and services provided on this Site is conditional upon
          your acceptance and compliance with the Terms.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>Any current or potential clients (the </span>
        <strong>&lsquo;Client&rsquo;</strong>
        <span style={{ fontWeight: 400 }}>, </span>
        <strong>&lsquo;clients&rsquo;</strong>
        <span style={{ fontWeight: 400 }}>
          ) you invite to, or accept to, enter a relationship with through the Service will need to agree, and adhere,
          to a separate user agreement (the{' '}
        </span>
        <strong>Client End User Agreement</strong>
        <span style={{ fontWeight: 400 }}>).</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Depending on what level of the Service you choose, there may be other terms that govern your relationship with
          Tacklit in conjunction with these Terms (defined as{' '}
        </span>
        <strong>Product Terms</strong>
        <span style={{ fontWeight: 400 }}>).</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          The Site is owned and operated by Tacklit UK Ltd (Company Number 12700473) defined as (&ldquo;
        </span>
        <strong>Tacklit</strong>
        <span style={{ fontWeight: 400 }}>&rdquo;, &ldquo;</span>
        <strong>we</strong>
        <span style={{ fontWeight: 400 }}>&rdquo;, &ldquo;</span>
        <strong>us</strong>
        <span style={{ fontWeight: 400 }}>&rdquo; and &ldquo;</span>
        <strong>our</strong>
        <span style={{ fontWeight: 400 }}>&rdquo;).</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Your continued use of this Site will be deemed as acceptance of these Terms by you.
        </span>
      </p>
      <h4>&nbsp;</h4>
      <h4>
        <strong>Variation of Terms</strong>
      </h4>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit may vary these Terms at any time. If Tacklit varies these Terms, it will provide notice by
            publishing the varied Terms on the Site.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You accept that by doing this, Tacklit has provided you with sufficient notice of the variation to its
            Terms. You will be notified of any material changes to the Terms which may be reasonably detrimental to you,
            5 days prior to them taking effect.
          </span>
        </li>
      </ol>
      <h4>
        <strong>Levels of Service</strong>
      </h4>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            The Practitioner has the right to choose, from a predefined selection as determined by Tacklit, the elements
            of functionality and support they wish to receive from Tacklit (the{' '}
          </span>
          <strong>Level of Service</strong>
          <span style={{ fontWeight: 400 }}>
            ).&nbsp; This determines, but is not limited to, access to product features, client volumes limits, and
            customer support access that are governed under the relevant Product Terms.&nbsp;&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit reserves the right to change the configuration of, and/or any price associated with access to, these
            Levels of Service in their sole discretion at any time{' '}
          </span>
          <span style={{ fontWeight: 400 }}>upon 30 days' notice from us</span>
          <span style={{ fontWeight: 400 }}>
            .&nbsp; Any such change may replace any previous subscription plan at a time determined by Tacklit.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            The Practitioner has the right to request to change or cancel their Level of Service at any time.&nbsp; Any
            such change shall be accommodated at the next subscription renewal date, or earlier where agreed by Tacklit.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Downgrading your Level of Service may cause the loss of access to content, data, features or records
            provided by the Service. You warrant that Tacklit is not liable or responsible for any and all consequences
            of any such loss of access.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You have the right to cancel your Account and stop your Level of Service at any time. Your access to the
            Site will remain unaltered until the end of your current subscription cycle.&nbsp; You are not entitled to
            any refund for cancellations part way through a subscription cycle. If you choose to terminate or suspend
            your subscription Tacklit will retain any account data for 90 days.&nbsp; If, after that time, you have not
            restored your subscription or exported your information it will be permanently removed.&nbsp; Tacklit is not
            responsible for ensuring you export your data, and will not be held{' '}
          </span>
          <span style={{ fontWeight: 400 }}>liable or responsible for any and all consequences</span>
          <span style={{ fontWeight: 400 }}> from such removal of data.</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You are solely responsible for which Clients you interact with via our Site, and how those interactions take
            place.&nbsp; This is including but not limited to what features, functionality, surveys, check-ins, user
            content, data or other information you use or share, and at what frequency, with them via our Site. You,
            with the consent of your Client, are responsible for determining the length of any engagement via the Site
            and the suitable start or end date of any relationship
          </span>
        </li>
      </ol>
      <h4>
        <strong>Payment</strong>
      </h4>
      <p>&nbsp;</p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            A valid credit card or debit card is required for subscription and incidentals payments. Your card will be
            charged in line with your Levels of Service subscription plan on either a monthly or annual basis.&nbsp; If
            you are eligible for an introductory trial offer you will not be charged for that portion of the duration of
            your subscription plan rounded to the nearest whole calendar day.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            All outstanding amounts owing to Tacklit must be paid within 14 days of the date of invoice.&nbsp; This
            includes, but is not limited to, all subscription fees, implementation fees, support fees that are
            applicable to your account based on your Level of Service.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            If you do not pay your subscription fees on time Tacklit may disable your account without notice and refuse
            to supply further services to you.&nbsp; We may attempt to charge the credit or debit card on file multiple
            times in case of payment failure, and will use reasonable efforts to contact you to inform you of missing
            payment, via{' '}
          </span>
          <span style={{ fontWeight: 400 }}>notices within the Service itself or by email correspondence.</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You are obliged to pay for the services that Tacklit agrees to provide you with regardless of whether you
            utilise or fully utilise those services. If you do not provide Tacklit with the necessary materials or
            information for Tacklit to deliver these services to you, you are still liable to Tacklit for full payment.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Credit card security is implemented using https (256-bit encryption) on all transactions. Credit card
            details are not stored within our application. Your credit card details are stored at a trusted and secure
            payment gateway used by Tacklit for payment processing.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            &nbsp;If you opt to change your Level of Service, you will be pro-rata credited for the remainder of your
            current plan. You will then be charged at the rate of the new plan until your billing date. You will have
            the changed plan limits applied immediately. If this adjustment causes your account to be credited, this
            will be applied to your subscription and used for future payments.&nbsp; We do not offer refunds for any
            differences in balance due to Level of Service changes.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            All fees stated are exclusive of taxes, levies or duties imposed by the tax authorities. If your company is
            liable to pay GST, you will be responsible for paying GST on top of any subscription fees.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            &nbsp;All monthly and annual subscription fees must be paid in advance of delivery of the Service.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            &nbsp;The Practitioner accepts and agrees to the automatic renewal of their subscription, authorizing for
            that intent that the appropriate amounts be charged through the payment information previously made
            available. The renewal of a subscription will follow the same Level of Service and price point as the prior
            period, with the explicit exemption of a change due to the expiry of any special offers or discounts that
            were limited, unless otherwise changed in line with these Terms.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            &nbsp;All fees related to the Service, including but not limited to the monthly subscription plan fees, are
            subject to change upon 30 days' notice from us. Such notice may be provided at any time by updates to the
            Site, notices within the Service itself, or by email correspondence.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            If you believe there has been any error in the charges relating to the Service we provide you, please
            contact us immediately. If there are charges made in error, we will credit your account or credit card
            account for the appropriate amount.
          </span>
        </li>
      </ol>
      <h4>&nbsp;</h4>
      <h4>&nbsp;</h4>
      <h4>
        <strong>Intellectual Property Rights</strong>
      </h4>
      <p>
        <span style={{ fontWeight: 400 }}>
          Tacklit retains all intellectual property rights subsisting in any of the goods and services provided to you
          by Tacklit.&nbsp; Except to the extent that applicable laws prevent us from restraining you from doing so, you
          agree that you will not copy, reproduce, alter, modify, or create derivative works from the Service.
        </span>
      </p>
      <p>&nbsp;</p>
      <h4>
        <strong>Limitation of Liability and Disclaimer</strong>
      </h4>
      <ol start={11}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Tacklit provides no warranty to you that services generally available through the Site will be uninterrupted
            or error free. Except where we are unable to exclude our liability by legislation, we, our officers,
            employees, agents and contractors will not be liable in any way to you or anyone else for any loss or
            damage, however it arises (whether in contract, tort including negligence, or otherwise) out of or in
            connection with your access and use of the Site.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={12}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            In the case of goods or services supplied or offered via the Site, liability for breach of an implied term
            which cannot be excluded by legislation, is limited at our option to either, the supply of the goods or
            services (or the equivalent goods or services) again or the payment of the cost of having the goods or
            services supplied again.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={13}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Our limitation of liability under these Terms applies to direct, indirect, consequential, special, punitive
            or other damages that you or others may suffer, as well as damages for loss of profit, business interruption
            or the loss of data or information, even if we are informed of their possibility.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={14}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Tacklit accepts no responsibility or liability for any errors in data or content that is created, edited or
            viewed by you, or associated end users invited or accepted by you, in using the Site.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={15}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Tacklit cannot and does not guarantee or warrant to you that files available for downloading through the
            Site or delivered via electronic mail through the Site, or features and products available through the Site,
            will be free of infection or viruses, worms, Trojan horses or other code that manifest contaminating or
            destructive properties. You are responsible for implementing sufficient procedures and checkpoints to
            satisfy your particular requirements for accuracy of data input and output, and for maintaining a means
            external to the Site for the reconstruction of any lost data.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={16}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Tacklit acts as a medium through which individuals connect with their mental health practitioners. Tacklit
            is not a medical device, we are a platform to make it easier for you and your clients to share relevant
            information as you see fit.&nbsp; Tacklit does not diagnose, nor is it responsible for diagnosing, clients
            or potential clients who may connect with you via our Service. Tacklit is not responsible in any way for
            determining, administering, verifying or altering any course of action or treatment in relation to care or
            support you provide as part of utilising our Service.&nbsp; You are solely responsible for any and all the
            outcomes or consequences resulting from any course of treatment, or interaction, facilitated, either wholly
            or in part, via our Service.
          </span>
        </li>
      </ol>
      <h4>&nbsp;</h4>
      <h4>
        <strong>Changes to the Site</strong>
      </h4>
      <ol start={18}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Tacklit reserves the right at all times without the need to have to provide any notice to you, to alter the
            functionality and/or appearance of its products and services available from Tacklit or the Site
            itself.&nbsp;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h4>
        <strong>Your Obligations</strong>
      </h4>
      <ol start={19}>
        <li>
          <span style={{ fontWeight: 400 }}> You warrant and agree that:</span>
        </li>
      </ol>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            you have the legal capacity and power to agree to be bound by these Terms and perform the obligations under
            them;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            you are a genuine healthcare practitioner who is using the Service solely for the purpose of helping manage
            and improve the effectiveness of the services and support you offer your clients.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            all files you upload as part of your use of the service with be free of infection or viruses;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>You will not use the Site for any illegal or illicit purpose;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You will not use the Site or any features of the Site or products offered on the Site to upload, download,
            transact, store or make available data that is unlawful, harassing threatening, harmful, tortious,
            defamatory, libellous, abusive violent, obscene, invasive of another&rsquo;s privacy, racially or ethnically
            offensive or otherwise in our opinion objectionable or damaging to Tacklit, the Site users or persons
            generally;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Outside of the functionality offered through using the Site you may not modify, copy, reproduce, republish,
            upload, post, transmit or distribute in any way any material from this Site including code and software;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            If you utilise any of Tacklit&rsquo;s APIs, either directly or via Partner;
          </span>
        </li>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              that Partner&rsquo;s access to the Site or a Tacklit API has been notified to, and approved by, Tacklit;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              Tacklit will continue to allow access to that Partner until such time as you provide us with a written
              request to revoke this access;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>that Partner is authorised to act on your behalf;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              any system utilised by you or that Partner will comply with the data security obligations as defined by
              Tacklit;
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>
              a breach of these Terms by that Partner will be deemed to be a breach of the relevant Terms by you and
              Tacklit will have the right to take action against you on account of that breach (regardless of whether or
              not you had specific knowledge of the relevant breach).
            </span>
          </li>
        </ol>
      </ol>
      <ol start={20}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            You may not assign or transfer any rights and obligations pursuant to these Terms to any other person or
            entity without Tacklit&rsquo;s prior written approval (which will not be unreasonably withheld). If you are
            a company, any change in your effective control shall be deemed an assignment for the purpose of this
            clause.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={21}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            You indemnify and will keep indemnified Tacklit, its officers, employees and agents against all claims,
            actions, suits, liabilities, actual or contingent costs, damages and expenses incurred by Tacklit in
            connection with:
          </span>
        </li>
      </ol>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>any breach of these Terms or the Product Terms by you;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            any negligent act or omission by you or parties associated with your account;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            any negative changes or outcomes with your clients or your relationship with your clients.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>The misuse of any aspect of the Site or any related Service; or</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            an actual breach by you of any law, legislation, regulations, by-laws, ordinances or codes of conduct which
            occurs as a consequence of your advertisement appearing on the Site.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={22}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            You agree at all times to deal with any information or products provided by Tacklit or accessed from the
            Site in a manner which abides by all applicable laws of the UK, or of any other relevant jurisdiction
            (including, without limitation, privacy and copyright laws).
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={23}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            You agree that neither the Site or any aspect of any Level of Service you enter into with Tacklit represents
            any form of crisis channel, comprehensive monitoring or alerting service in relation to the wellbeing or
            immediate risk response for of any of your Clients, and you warrant the services provided will not be used
            by you in any manner that would rely on the Service to support you that endeavour.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h4>
        <strong>Collection and use of practitioner and client data</strong>
      </h4>
      <ol start={30}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Any &ldquo;personal information&rdquo; (within the meaning of the Data Protection Act 2018) of any client
            that you obtain, or add through your own notes, through your use of the Site or any features of the Site or
            products offered on the Site must only be used by you in relation to your genuine requirements in delivery
            of effective healthcare for a Client and closely related activities.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={31}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Whereby any Client accepts an invitation to, or requests to, work with you through the Site they must agree
            to the terms outlined in the Clients End User Agreement and our Privacy Policy.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={32}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            All data volunteered by Clients via the Service is explicitly consented by the Client and governed via the
            Client End User Agreement.&nbsp; This consent can be withdrawn at any time in the sole discretion of the
            Client, which would then stop the sharing of any further information. The Client has the right to request
            the removal of any such historically shared verbatim data in accordance with the Client End User Agreement
            which the Practitioner will be bound to comply with as part of our Service provision.&nbsp;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={31}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Tacklit takes its obligations under the Data Protection Act 2018 (The Data Protection Act 2018 is the
            UK&rsquo;s implementation of the General Data Protection Regulation (GDPR)) extremely seriously. If Tacklit
            believes that you have misused client data for any reason, we reserve the right to:
          </span>
        </li>
      </ol>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            immediately suspend or terminate your account, and/or suspend or terminate the account of any party that has
            received client personal information from you in breach of these Terms;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            report any potential contraventions of the Data Protection Act 2018 by you to the relevant authorities,
            including the Office of the UK Information Commissioner; and/or
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            take legal action against you seeking any number of remedies provided by law, including the award of
            monetary damages.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={32}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Tacklit does not verify the accuracy or completeness of any and all information, claims or counterclaims
            shared by any party via the Site.&nbsp; We present data shared from Clients as-is, without moderation,
            editing, retraction or form of adjustment.&nbsp;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={33}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            User Content.&nbsp; All content you or your clients upload, create, submit, distribute, and/or post to the
            Service, including but not limited to, document layouts, source code, pictures, video and other images,
            audio materials, graphics, document or data files, information relating to natural and other persons,
            messages, e-mail and other communications, files, texts, personalization settings and other information
            and/or content, which is or may be provided to Tacklit and/or placed on the user&rsquo;s Tacklit profile
            page or inputted and/or uploaded by you via the Service (&ldquo;
          </span>
          <strong>User Content</strong>
          <span style={{ fontWeight: 400 }}>
            &rdquo;) whether publicly posted or privately transmitted, is the sole responsibility of the person who
            originated such User Content.&nbsp; Tacklit has no responsibility and/or liability for the deletion and/or
            accuracy of any User Content; the failure to store, transmit and/or receive transmission of User Content;
            and/or the security, privacy, storage and/or transmission of other communications originating with and/or
            involving use of the Service. You alone are responsible for User Content, and once published, it cannot
            always be withdrawn.&nbsp; You represent that you own, and/or have the necessary permissions to use and
            authorize use of User Content as described in the Terms.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h4>
        <strong>Access</strong>
      </h4>
      <ol start={49}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Your access to the Practitioner Portal (and the functionality contained therein) will be via a secure login
            username and password (the{' '}
          </span>
          <strong>Login</strong> <strong>Credentials</strong>
          <span style={{ fontWeight: 400 }}>
            ) created or amended by you in line with Tacklit&rsquo;s Security Policy.&nbsp;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={50}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Depending on your level of Service you may be entitled to add additional users to access all or parts of
            your account through the Site.&nbsp; When these rights are granted the Practitioner is responsible for:
          </span>
        </li>
      </ol>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            providing Tacklit with the identity and contact details of individuals authorised to access the Practitioner
            Portal on your behalf (
          </span>
          <strong>authorised users</strong>
          <span style={{ fontWeight: 400 }}>);</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>setting authority limits for all authorised users;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            supervising the use of the Practitioner Portal by the authorised users, and ensuring that such use is
            adheres to these Terms; and
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            ensuring that any authorised users Login Credentials are kept secure and confidential.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            removing authorised users access when they no longer need to use the Service in accordance with their
            relationship with you.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={50}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            You are responsible for the use of the Login Credentials to enable users to gain access to the Practitioner
            Portal, whether the use is by authorised users or any other person. Any act or omission by an authorised
            user in respect of the use of the Login Credentials and/or the use of the Practitioner Portal that breaches
            these Terms will be deemed a breach of these Terms by you.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={51}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Tacklit reserves the right to enforce the use of Two-factor Authentication (
          </span>
          <strong>2FA</strong>
          <span style={{ fontWeight: 400 }}>
            ) by Practitioners for access to either parts or all of the Site in its sole discretion in line with its
            Security Policy.&nbsp; Where 2FA is enabled the Practitioner warrants that they will comply with this
            security measure to access the relevant information and not attempt to subvert, remove or circumvent this
            protection.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={51}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            If you utilise the Add Client function of the Practitioner Portal then before utilising that function you
            must ensure that you have:
          </span>
        </li>
      </ol>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            expressly obtained the consent of every individual whose Personal Information, (as that term is defined
            within the Data Protection Act 2018), you upload onto the Practitioner Portal, to such uploading of their
            Personal Information and the storage of such information by Tacklit;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            fully disclosed to such individuals the purpose for which their Personal Information has been collected and
            is stored on our Site; and
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            otherwise fully complied with your obligations under the Data Protection Act 2018 in respect of the
            collection and storage of such information.
          </span>
        </li>
      </ol>
      <h4>&nbsp;</h4>
      <h4>
        <strong>Maintenance</strong>
      </h4>
      <ol start={54}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Tacklit will use reasonable endeavours to ensure that the Practitioner Portal is available for access by
            authorised users at all times. Notwithstanding this, Tacklit and its third party service providers may be
            required to undertake maintenance and upkeep of the Site from time to time. Tacklit will endeavour to limit
            any &lsquo;downtime&rsquo; to periods outside of standard business hours.&nbsp;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h4>&nbsp;</h4>
      <h4>&nbsp;</h4>
      <h4>&nbsp;</h4>
      <h4>
        <strong>Licence to use data</strong>
      </h4>
      <ol start={58}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Except for information that is &lsquo;personal information&rsquo; (as that term is defined in the Data
            Protection Act 2018), all data stored on the Site on behalf of the Practitioner (
          </span>
          <strong>Practitioner Data</strong>
          <span style={{ fontWeight: 400 }}>
            ), such as calendar availability and appointment types, client summary reports, analysed clinical
            assessments, client notes, customised onboarding question sets, is owned by the Practitioner, and not
            Tacklit. For the avoidance of doubt, the use of personal information of clients by Tacklit and/or the
            Practitioner is governed by the Data Protection Act 2018 and the terms of the relevant privacy policy of
            Tacklit and/or the Practitioner.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={59}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            The Practitioner hereby grants to Tacklit and Tacklit&rsquo;s related parties a perpetual, non-exclusive,
            irrevocable licence to use any Practitioner Data, to:
          </span>
        </li>
      </ol>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>manage internal reporting requirements;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>collate statistical information about use of the Site</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>analyse user behaviour on the Site;</span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            obtain and analyse high level trends and prepare reports relating thereto; and
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>generally improve the user experience.</span>
        </li>
      </ol>
      <ol start={60}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Tacklit may use any data relating to the Practitioner&rsquo;s use of, or engagement with the Site (
          </span>
          <strong>Ancillary Data</strong>
          <span style={{ fontWeight: 400 }}>
            ) for any purpose it sees fit, unless such Ancillary Data would reasonably be considered confidential in
            nature. Tacklit may disclose Ancillary Data to its related parties.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h4>
        <strong>Hardware &amp; software</strong>
      </h4>
      <ol start={61}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            It is the Practitioner&rsquo;s responsibility to ensure that it has the necessary computer hardware and
            software systems in place to access and utilise the Site.&nbsp; Tacklit will reasonably ensure that the
            Services are reliably accessible and usable on common devices / browsers.&nbsp;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h4>&nbsp;</h4>
      <p>&nbsp;</p>
      <h4>
        <strong>General Terms</strong>
      </h4>
      <ol start={62}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Tacklit reserves the right, in our absolute discretion, to terminate your agreement for utilising any part
            of the Service or the Site in any way which is in breach of any of these Terms or the Product Terms.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={63}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            Termination of our agreement with you as a result of you breaching any one or more of these Terms, will not
            end provisions of these Terms that are capable of surviving termination.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={64}>
        <li>
          <span style={{ fontWeight: 400 }}>
            {' '}
            These Terms are governed by the laws of the UK. Practitioners irrevocably and unconditionally submit to the
            exclusive jurisdiction of the Courts of England, and waive any objection to legal action being brought in
            those Courts on the grounds of venue or inconvenient forum.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h4>
        <strong>Product Terms</strong>
      </h4>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: 400 }}>
          These terms are applicable to any practitioner who uses specific aspects or features of our service. By using
          any of these features you agree to abide by these conditions in conjunction with all the other provisions in
          the Terms.
        </span>
      </p>
      <p>&nbsp;</p>
      <h5>
        <strong>Clinical assessments</strong>
      </h5>
      <p>&nbsp;</p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit provides a library of clinical assessments for Practitioners to administer via our Site. Access to,
            and use of, these assessments are subject to the Level of Service you have requested from Tacklit.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You are responsible for inviting a Client who is working with you via the Site, to undertake an
            assessment.&nbsp; You are solely responsible for selecting which assessment you wish to use with which
            Client.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Upon completion of any assessment the results will be visible to you via the Site, these results will be
            presented to you verbatim as answered by the Client and also with any scoring interpretation in line with
            the rules associated with that particular assessment.&nbsp; Any scores or interpretations are not made
            available directly to Clients, you are responsible for sharing that information in a manner that is suitable
            based on your sole Clinical judgement.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Assessments are not editable or adaptable outside of any specifications within their commonly used
            framework.&nbsp; Tacklit provides them &lsquo;as-is&rsquo; for you to use based solely on your clinical
            judgement.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            These clinical assessments, and any accompanying Intellectual Property (the{' '}
          </span>
          <strong>IP</strong>
          <span style={{ fontWeight: 400 }}>
            ), are not owned by Tacklit.&nbsp; And using them via our Site does not give you any claim to any aspect of
            any such IP.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You warrant that you are using any such clinical assessment solely in line with the purpose for which they
            are intended, in support of your work with your Clients.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit will endeavour to provide you access to relevant clinical assessments, based on the prevailing
            scientific evidence for what can be effective in helping you assess presenting symptoms and their severity,
            but makes no guarantee to make available any specific assessment instrument.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit offers you the use of these assessments in line with any licencing or rights associated with each
            assessment.&nbsp; You warrant that you will adhere to any and all restrictions imposed as part of any such
            licencing, and will not attempt to subvert, remove or circumvent these limits.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit reserves the right to add, remove or change the offered selection of assessments without prior
            notice at any time.&nbsp;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h5>
        <strong>Micro journaling</strong>
      </h5>
      <p>&nbsp;</p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit provides you with the capability to offer your Clients the ability to keep a micro journal of their
            thoughts, emotions and lived experiences via the Site.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You are solely responsible for deciding which Clients you wish to invite to keep a journal, and any one you
            invite which journal check-in program you&rsquo;d like them to follow.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            If a Client accepts the invitation to keep a journal via the Site, they are then asked to explicitly choose
            whether they want to share their journal entries with you directly or keep them private for their own self
            reflection.&nbsp; If they consent to sharing this information, it will be visible to you securely via the
            specific Client profile via the site.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            The data provided by the Client via the Micro Journaling feature is owned by the Client.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            A Client has the right to revoke any consent to sharing journaling data at any point, they do not need
            permission from either you or us to make that change.&nbsp; They can make this change via the Client Profile
            on the Site. And any access to any historical verbatim entries previously may also be withdrawn based on the
            sole discretion of the Client.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit captures and shares all information entered into Micro Journaling &lsquo;as-is&rsquo; without any
            editing, curation, moderation, or interpretation as they are User Content. To that end Tacklit takes no
            responsibility for the accuracy, truthfulness, completeness or appropriateness of any such content.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            This is not a crisis service.&nbsp; It is a journaling tool for Clients to log information.&nbsp; It is not
            a messaging service, nor is it monitored for content or intent at any time by Tacklit.&nbsp; By using this
            product You and your Clients (via the Client End User Agreement) are expressly consenting to not using it
            for the purpose of sharing any information that may be considered as needing a response, time-sensitive,
            urgent or critical in any way.&nbsp;&nbsp;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h5>
        <strong>Digital Agreements</strong>
      </h5>
      <p>&nbsp;</p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit provides you with the capability to create and administer digital consent forms solely for the
            purpose of entering into a healthcare practitioner-client relationship that may be required as part of your
            engagement with your Clients.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            These digital agreements are created, edited and amended solely by you as they are User Content.&nbsp;
            Tacklit does not review, moderate or edit any aspect of any User Content and is in no way responsible for
            any consent you deem necessary or required.&nbsp; Nor does Tacklit endorse, or verify the legality,
            legitimacy or suitability for any and all clauses and terms that require the consent you request from your
            Clients.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Clients are able to signal their agreement with your terms by completing an &lsquo;e-Signature&rsquo;.&nbsp;
            This signature is stored, with other relevant metadata including the time, date and confirmed profile
            authentication (based on being successfully logged in) as a way of confirmation of agreement.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            In your sole discretion, you may configure the relevant Tacklit features to align to terms you set out in
            any agreement you enter into with your client.&nbsp; For example, for avoidance of doubt, if you don&rsquo;t
            allow cancellations of appointments within 48 hours of session start time as referenced in your Cancellation
            terms; you may then set up the calendar to prevent any change requests being submitted after this time.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit does not enforce, underwrite or in any way become an accountable party for any aspect of any
            agreement you create or enter into with your clients.&nbsp; Any breach of any terms you enter into with your
            clients is a matter for you to pursue directly with them.&nbsp;&nbsp;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h5>
        <strong>Calendar &amp; Scheduling</strong>
      </h5>
      <p>&nbsp;</p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit enables you to set up and manage your appointments and availability&nbsp; within the
            Site.&nbsp;&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You have the sole responsibility of setting up any and all appointments you offer, setting the rules to
            apply around if and how to accept bookings, change bookings or cancellations of a booking.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You may configure your appointments and availability within the parameters required by Tacklit, including
            duration, start times, mandatory gaps between appointments, lead time etc.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Your calendar will be configured to a specific timezone of your choosing.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You may open appointments to be bookable by new or existing clients in line with rules that you set within
            the parameters required by Tacklit.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You are fully responsible for setting your initial, and managing ongoing, availability at all times.&nbsp;
            Tacklit endeavours to avoid any clashes of appointments but does not guarantee that this will never occur in
            all scenarios.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Instant Book appointments are open to Clients and are confirmed instantly.&nbsp; Requests to Book need
            confirmation from you before they become confirmed, we will hold any appointment time that has been
            requested from the view of other clients until you have made an &lsquo;approve&rsquo; or
            &lsquo;decline&rsquo; decision.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Where you apply account level rules to your account they will override any conflicting parameters on any
            individual appointment types.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit endeavour to maintain any connections with third party calendars that are made available to you, and
            that you enable through the Site.&nbsp; We do not guarantee uptime or performance of these connections, nor
            that all information will be transmitted between these services accurately and reliably given the changing
            nature of such external services.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h5>
        <strong>Custom Surveys</strong>
      </h5>
      <p>&nbsp;</p>
      <ol>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit provides you with the capability to create and administer Custom Surveys via the Site.&nbsp; We
            provide this capability to enable you to capture relevant and timely information to aid your clinical
            decision making, strengthen the therapeutic alliance with your Client and support your practice.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You are solely responsible for deciding which Clients you wish to invite to a Custom Survey, and any one you
            invite which Survey you&rsquo;d like them to undertake and at what point in your relationship.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            If a Client accepts the invitation to complete a Survey via the Site, they must consent to sharing this
            information, it will be visible to you securely via the specific Client profile via the site.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            You warrant that any and all information you request to gather from Custom Surveys is genuine and
            justifiably in the best interest of helping the individual Client in their recovery and relationship with
            you.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            The data provided by the Client via the Custom Survey feature is owned by the Client.&nbsp;
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            A Client has the right to revoke any consent to sharing survey data at any point, they do not need
            permission from either you or us to make that change.&nbsp; They can make this change via the Client Profile
            on the Site. And any access to any historical verbatim entries previously may also be withdrawn based on the
            sole discretion of the Client.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            Tacklit captures and shares all information entered into Custom Surveys &lsquo;as-is&rsquo; without any
            editing, curation, moderation, or interpretation as they are User Content. To that end Tacklit takes no
            responsibility for the accuracy, truthfulness, completeness or appropriateness of any such content.
          </span>
        </li>
        <li style={{ fontWeight: 400 }}>
          <span style={{ fontWeight: 400 }}>
            This is not a crisis service.&nbsp; It is a survey tool for you to request information from Clients.&nbsp;
            It is not a messaging service, nor is it monitored for content or intent at any time by Tacklit.&nbsp; By
            using this product You and your Clients (via the Client End User Agreement) are expressly consenting to not
            using it for the purpose of sharing any information that may be considered as needing a response,
            time-sensitive, urgent or critical in any way.&nbsp;
          </span>
        </li>
      </ol>
    </div>
  );
};

export default UKTerms;
