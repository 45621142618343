import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import {
  FunderNameMapping,
  InvoiceWithProjectedMbsCode,
  PendingClaimsFunder
} from 'interfaces/invoices/pendingClaimInvoices';
import { useEffect, useRef, useState } from 'react';
import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';
import styles from './PendingClaimInvoiceItemMenu.module.scss';
import moment from 'moment';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface PendingClaimInvoiceItemMenuProps {
  invoice: InvoiceWithProjectedMbsCode;
  ableToCreate: boolean;
  onSubmit: () => void;
  onViewClientProfile: () => void;
  onResend: () => void;
  onEdit: () => void;
  onWriteOff: () => void;
  onAbandon: () => void;
}

const PendingClaimInvoiceItemMenu = ({
  invoice,
  ableToCreate,
  onSubmit,
  onResend,
  onViewClientProfile,
  onEdit,
  onWriteOff,
  onAbandon
}: PendingClaimInvoiceItemMenuProps) => {
  const { isInvoiceListV2Enabled } = useGetFeatureToggle();
  const { isEdgeAdminView } = useGetAccountPackageView();

  const [isExpanded, setIsExpanded] = useState(false);
  const [createClaimExpand, setCreateClaimExpand] = useState(false);

  const menuNode = useRef<HTMLDivElement>(null);
  const subMenuNode = useRef<HTMLDivElement>(null);

  const onCreateClaim = () => {
    setCreateClaimExpand(false);
    setIsExpanded(false);
    onSubmit();
  };

  const onEditClaim = () => {
    setCreateClaimExpand(false);
    setIsExpanded(false);
    onEdit();
  };

  const onResendInvoice = () => {
    createClaimExpand && setCreateClaimExpand(false);
    isExpanded && setIsExpanded(false);
    onResend();
  };

  const handleWriteOff = () => {
    createClaimExpand && setCreateClaimExpand(false);
    isExpanded && setIsExpanded(false);
    onWriteOff();
  };

  const handleAbandon = () => {
    createClaimExpand && setCreateClaimExpand(false);
    isExpanded && setIsExpanded(false);
    onAbandon();
  };

  const handleExpandMenu = () => {
    setIsExpanded(!isExpanded);
    if (createClaimExpand) {
      setCreateClaimExpand(false);
    }
  };

  const handleExpandSubMenu = (item: string) => {
    if (item === 'createClaim') {
      if (createClaimExpand) {
        setCreateClaimExpand(false);
      }
      return setCreateClaimExpand(!createClaimExpand);
    }
  };

  const handleClick = (e: any) => {
    if (menuNode.current?.contains(e.target) || subMenuNode.current?.contains(e.target)) {
      return;
    }
    setIsExpanded(false);
    setCreateClaimExpand(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const { funder, mbsCode, isClaimReady, foundAppointment, foundClinician, foundProvider, claimAbandoned } = invoice;
  return (
    <>
      <div ref={menuNode} className={styles.container}>
        <ButtonAlt className={styles.button} variant={'text'} onClick={handleExpandMenu}>
          <i className={`material-icons ${styles.icon}`}>more_vert</i>
        </ButtonAlt>
        <div className={styles.menuWrapper}>
          <div className={isExpanded ? styles.menuShow : styles.menuHide}>
            {/* Create Claim */}
            <ThreeDotMenuItem
              isActive={createClaimExpand}
              disabled={!ableToCreate}
              label={'Create Claim'}
              onClick={() => {
                handleExpandSubMenu('createClaim');
              }}
              preIcon={'credit_card'}
              postIcon={'navigate_next'}
            />
            {/* Resend Invoice to Client */}
            <ThreeDotMenuItem label={'Resend Invoice to Client'} onClick={onResendInvoice} preIcon={'send'} />
            {/* View Client Profile */}
            <ThreeDotMenuItem
              label={'View Client Profile'}
              onClick={onViewClientProfile}
              preIcon={'person'}
              postIcon={'open_in_new'}
            />
            {/* Delete Invoice */}
            {/* <ThreeDotMenuItem
              className={styles.deleteInvoice}
              disabled={isSubmitting}
              label={'Delete Invoice'}
              onClick={onDelete}
              preIcon={'delete'}
            /> */}

            {/* Write Off */}
            {isInvoiceListV2Enabled && isEdgeAdminView && (
              <ThreeDotMenuItem
                label="Write Off"
                onClick={handleWriteOff}
                preIcon="money_off"
                disabled={!funder || ![PendingClaimsFunder.BULK_BILL, PendingClaimsFunder.DVA].includes(funder)}
              />
            )}

            {/* Abandon */}
            {isEdgeAdminView && !claimAbandoned && (
              <ThreeDotMenuItem label="Abandon" onClick={handleAbandon} preIcon="credit_card_off" />
            )}
          </div>
        </div>
      </div>
      <div ref={subMenuNode}>
        <div className={styles.subMenuWrapper}>
          <div className={createClaimExpand ? styles.subMenuShow : styles.subMenuHide}>
            <div className={styles.createClaimContainer}>
              <div className={styles.createClaimItem}>
                <div className={styles.label}>Type:</div>
                <div className={styles.value}>{funder && FunderNameMapping[funder]}</div>
              </div>
              <div className={styles.createClaimItem}>
                <div className={styles.label}>MBS Code:</div>
                <div className={styles.value}>{mbsCode}</div>
              </div>
              <div className={styles.createClaimItem}>
                <div className={styles.value}>
                  {foundClinician?.name} {foundProvider?.providerNumber}
                </div>
              </div>
              <div className={`${styles.createClaimItem} ${styles.column}`}>
                <div className={styles.label}>Delivery Date:</div>
                <div className={styles.value}>
                  {moment(`${foundAppointment.date} ${foundAppointment.startTime}`, 'YYYY-MM-DD HH:mm').format(
                    'HH:mm [on] DD/MM/YYYY'
                  )}
                </div>
              </div>
              <div className={styles.createClaimItem}>
                <ButtonAlt disabled={!isClaimReady} onClick={onCreateClaim} size={'small'}>
                  Submit
                </ButtonAlt>
                <ButtonAlt onClick={onEditClaim} size={'small'} variant={'text'} icon={'mode_edit'}>
                  Edit
                </ButtonAlt>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingClaimInvoiceItemMenu;
