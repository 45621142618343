import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { selectFilters, selectPaging, selectSearch, selectSorting } from './referralSlice';
import { useAppSelector } from 'redux/hooks';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { getReferralsRequestPayload, REFERRAL_PER_PAGE } from 'pages/Referrals/utils';
import { useGetReferralListQuery } from 'redux/endpoints/clinicianProfileServices/referral';

export const useGetReferralList = () => {
  const { accountId } = useGetAccountId();
  const filters = useAppSelector(selectFilters);
  const paging = useAppSelector(selectPaging);
  const search = useAppSelector(selectSearch);
  const sorting = useAppSelector(selectSorting);

  const { isReferralV2ListEnabled } = useGetFeatureToggle();

  const { data, isLoading, isFetching, isError, refetch } = useGetReferralListQuery(
    {
      accountId,
      params: getReferralsRequestPayload(search, paging, sorting, filters)
    },
    { skip: !accountId || !isReferralV2ListEnabled }
  );

  return {
    isLoading,
    isFetching,
    isError,
    referrals: data?.referrals || [],
    paging: data?.paging || { totalItems: 0, page: 1, perPage: REFERRAL_PER_PAGE },
    counts: data?.count || {},
    totalItems: data?.paging.totalItems || 0,
    refetch
  };
};
