import ListHeaderItem, { SortType } from 'components/T23/ListHeaderItem/ListHeaderItem';
import { PendingClaimInvoiceListColumn } from './columns';
import styles from './PendingClaimInvoiceListHeader.module.scss';
import columnStyles from './PendingClaimInvoiceColumn.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectSorting, setSorting } from 'redux/features/pendingClaimInvoices/pendingClaimInvoiceSlice';
import { getColumnNameFromId } from 'components/T23/ListHeaderItem/helper';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface PendingClaimInvoiceListHeaderProps {
  isSelectedAll?: boolean;
  isExistClaimDataReady?: boolean;
  onSelectClick?: () => void;
}

const PendingClaimInvoiceListHeader = ({
  isSelectedAll,
  isExistClaimDataReady,
  onSelectClick
}: PendingClaimInvoiceListHeaderProps) => {
  const dispatch = useAppDispatch();
  const sorting = useAppSelector(selectSorting);

  const { sortBy, sortType } = sorting;

  const onClickSort = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      dispatch(setSorting({ ...sorting, sortType: sortType === SortType.ASC ? SortType.DESC : SortType.ASC }));
    } else {
      dispatch(setSorting({ ...sorting, sortBy: newSortBy, sortType: SortType.DESC }));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonSelectWrapper}>
        {isExistClaimDataReady && (
          <ButtonAlt variant="text" size="small" className={styles.selectButton} onClick={onSelectClick}>
            {isSelectedAll ? 'Deselect All' : 'Select All'}
          </ButtonAlt>
        )}
      </div>
      {PendingClaimInvoiceListColumn.map((column, index) => (
        <ListHeaderItem
          key={index}
          columnId={column.id}
          className={columnStyles[column.id]}
          sortBy={sortBy}
          sortType={sortType}
          columnName={column.label || getColumnNameFromId(column.id)}
          sortAble={column.sortAble}
          onSort={onClickSort}
        />
      ))}
      <div className={columnStyles.actions} />
    </div>
  );
};

export default PendingClaimInvoiceListHeader;
