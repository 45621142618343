import styles from './MemberAppointmentDetail.module.scss';
import moment from 'moment';
import { NextAppointmentInterface } from '../../../../constants';
import AppointmentDetail from './AppointmentDetail/AppointmentDetail';

interface MemberAppointmentDetailProps {
  nextAppointments: NextAppointmentInterface[];
}

const MemberAppointmentDetail = ({ nextAppointments }: MemberAppointmentDetailProps) => {
  const filteredAppointments = nextAppointments.length
    ? nextAppointments.filter((obj) => obj.date === nextAppointments[0].date)
    : [];

  const isTodayDate = filteredAppointments.length > 0 && moment(filteredAppointments[0].date).isSame(moment(), 'day');

  return filteredAppointments.length ? (
    <div className={styles.container}>
      <div className={styles.label}>
        {`${isTodayDate ? `TODAY'S SESSION` : 'NEXT SESSION'}${filteredAppointments.length > 1 ? 'S' : ''}`}
      </div>
      <div className={styles.date}>{moment(filteredAppointments[0].date).format('dddd, MMMM D')}</div>
      {filteredAppointments.map((appointment, index) => (
        <AppointmentDetail key={index} appointment={appointment} />
      ))}
    </div>
  ) : (
    <div className={styles.noScheduled}>No session scheduled</div>
  );
};

export default MemberAppointmentDetail;
