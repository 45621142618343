import { notification, Skeleton } from 'antd';

import styles from './CheckInProgram.module.scss';

import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { useFetchAssignedMCI, useFetchMCIList } from './hooks/GetCheckInInsights';
import { putAssignedMCI } from 'utils/http/CheckInService/MicroCheckIn/mci';
import { useGetAccessToken } from 'utils/hooks/token';

interface CheckInProgramProps {
  clientRecordId: string;
  clientProfileId: string;
  disabled: boolean;
}

const CheckInProgram = ({ clientRecordId, clientProfileId, disabled }: CheckInProgramProps) => {
  const { token } = useGetAccessToken();
  const { MCIs, isMCIsLoading } = useFetchMCIList(token);
  const { setAssignedMCI, assignedMCI, isAssignedMCILoading } = useFetchAssignedMCI(
    token,
    clientRecordId,
    clientProfileId
  );

  const handleMCISelect = async (microCheckInId: string) => {
    setAssignedMCI(microCheckInId);
    const body = {
      microCheckInId,
      clientProfileId,
      clientRecordId
    };
    await putAssignedMCI(token, body);
    notification.success({ message: 'Check-in program updated.' });
  };

  return (
    <div className={styles.container}>
      {isMCIsLoading || isAssignedMCILoading ? (
        <Skeleton.Button active className={styles.loading} />
      ) : (
        <MaterialSelect
          label={'Name of check-in program:'}
          isSearchable={false}
          options={MCIs}
          value={assignedMCI || ''}
          onChange={handleMCISelect}
          boldValue
          isDisabled={disabled}
        />
      )}
    </div>
  );
};

export default CheckInProgram;
