import classnames from 'classnames';
import styles from './ConsentFormList.module.scss';
import columnStyles from './ConsentFormListColumn.module.scss';
import SortBtn, { SortType } from 'components/TableList/SortBtn/SortBtn';
import { ConsentFormListInterface } from 'pages/Groups/Interfaces/Groups';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { combineName } from 'utils/general';
import { Skeleton } from 'antd';
import PerRecordDropdown from 'components/TableList/PerRecordDropdown/PerRecordDropdown';
import PaginationDesc from 'components/TableList/PaginationDesc/PaginationDesc';
import moment from 'moment';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface ConsentFormListProps {
  consentFormList: ConsentFormListInterface;
  isConsentFormListLoading: boolean;
  selectedPerPage: number;
  sortType: SortType;
  sortColumn: string;
  selectedPage: number;
  onChangeSortType: (val: SortType, sortColumn?: string) => void;
  onChangePerPage: (val: number) => void;
  onChangePage: (val: number) => void;
}

const ConsentFormList = ({
  consentFormList,
  isConsentFormListLoading,
  selectedPerPage,
  sortType,
  sortColumn,
  selectedPage,
  onChangeSortType,
  onChangePerPage,
  onChangePage
}: ConsentFormListProps) => {
  const TOTAL_ITEM = consentFormList.paging.totalItem || 0;

  const handleRecordPerPageValue = (perPageVal: number) => {
    onChangePerPage(perPageVal);
  };

  const handlePageChange = (pageVal: number) => {
    onChangePage(pageVal);
  };

  const handleRemindAction = (consentId: string) => {
    //TODO:
    console.log('handleRemindAction');
  };

  const handleDetailAction = (consentId: string) => {
    //TODO:
    console.log('handleDetailAction');
  };

  return (
    <div className={styles.container}>
      <div className={classnames(columnStyles.columnWrapper, styles.header)}>
        <div className={columnStyles.avatar}>AVATAR</div>
        <div className={classnames(columnStyles.fullName, sortColumn === 'fullName' ? columnStyles.active : '')}>
          FULL NAME
          <SortBtn
            columnName={'fullName'}
            selectedColumn={sortColumn}
            selectedSortName={sortType}
            onChangeSort={onChangeSortType}
            disabled={isConsentFormListLoading}
          />
        </div>
        <div className={classnames(columnStyles.formSent, sortColumn === 'formSent' ? columnStyles.active : '')}>
          FORM SENT
          <SortBtn
            columnName={'formSent'}
            selectedColumn={sortColumn}
            selectedSortName={sortType}
            onChangeSort={onChangeSortType}
            disabled={isConsentFormListLoading}
          />
        </div>
        <div className={classnames(columnStyles.formSigned, sortColumn === 'formSigned' ? columnStyles.active : '')}>
          FORM SIGNED
          <SortBtn
            columnName={'formSigned'}
            selectedColumn={sortColumn}
            selectedSortName={sortType}
            onChangeSort={onChangeSortType}
            disabled={isConsentFormListLoading}
          />
        </div>
        <div className={classnames(columnStyles.dateSigned, sortColumn === 'dateSigned' ? columnStyles.active : '')}>
          DATE SIGNED
          <SortBtn
            columnName={'dateSigned'}
            selectedColumn={sortColumn}
            selectedSortName={sortType}
            onChangeSort={onChangeSortType}
            disabled={isConsentFormListLoading}
          />
        </div>
        <div className={columnStyles.remind}>REMIND</div>
        <div className={columnStyles.view}>VIEW</div>
      </div>
      {isConsentFormListLoading ? (
        <>
          {[...Array(10)].map((obj, i) => (
            <div key={i} className={classnames(styles.loadingWrapper, styles.listItem)}>
              <Skeleton.Input active className={styles.loading} />
            </div>
          ))}
        </>
      ) : consentFormList.consentFormList?.length > 0 ? (
        <>
          {consentFormList.consentFormList?.map((consentForm, index) => (
            <div key={index} className={classnames(columnStyles.columnWrapper, styles.listItem)}>
              <div className={columnStyles.avatar}>
                <ClientAvatar
                  clientData={consentForm.clientProfiles}
                  displayLimit={consentForm.recordType === 'couple' ? 2 : 1}
                  hideName
                />
              </div>
              <div className={classnames(columnStyles.fullName, styles.name)}>
                {combineName(consentForm.clientProfiles)}
              </div>
              <div className={classnames(columnStyles.formSent, styles.isSentText)}>
                {consentForm.isFormSent ? 'Yes' : 'No'}
              </div>
              <div className={classnames(columnStyles.formSigned, styles.isFormSignedText)}>
                {consentForm.isFormSigned ? 'Yes' : 'No'}
              </div>
              <div className={classnames(columnStyles.dateSigned, styles.dateSignedText)}>
                {consentForm.dateSigned ? moment().format('DD MMMM YYYY') : '-'}
              </div>
              <div className={columnStyles.remind}>
                <ButtonAlt
                  size={'medium'}
                  fab
                  variant={'text'}
                  onClick={() => handleRemindAction(consentForm._id)}
                  icon={'add_alert'}
                />
              </div>
              <div className={columnStyles.view}>
                <ButtonAlt
                  size={'medium'}
                  fab
                  variant={'outlined'}
                  onClick={() => handleDetailAction(consentForm._id)}
                  icon={'arrow_forward'}
                />
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className={styles.noConsentFoundContainer}>No Consent form found</div>
      )}
      <div className={styles.paginationWrapper}>
        <div className={styles.recordPerPageWrapper}>
          <PerRecordDropdown
            totalItem={TOTAL_ITEM}
            selectedPerPage={selectedPerPage}
            selectDropdownValue={handleRecordPerPageValue}
            disabled={isConsentFormListLoading}
          />
          <div className={styles.label}>records per page</div>
        </div>
        <PaginationDesc
          currentPage={selectedPage}
          totalItem={TOTAL_ITEM}
          perPage={selectedPerPage}
          onPageChange={handlePageChange}
          isLoading={isConsentFormListLoading}
          label={'members'}
        />
      </div>
    </div>
  );
};

export default ConsentFormList;
