import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getClientNextAppointments } from 'utils/http/appointment';
import { NextAppointmentInterface } from '../../../constants';

export const useFetchNextAppointmentsByClientId = (token: string, clientId: string) => {
  const [nextAppointments, setNextAppointments] = useState<NextAppointmentInterface[]>([]);
  const [isNextAppointmentsLoading, setIsNextAppointmentsLoading] = useState(true);
  const [t] = useTranslation();

  const fetchClientDetail = async (token: string) => {
    try {
      const response = await getClientNextAppointments(token, clientId);
      const appointments = await response.json();
      setNextAppointments(appointments);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: t('form.error.failed_to_retrieve_this_client_detail')
      });
    }

    setIsNextAppointmentsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchClientDetail(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clientId]);

  return { nextAppointments, isNextAppointmentsLoading };
};
