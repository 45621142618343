import { cloneDeep } from 'lodash';
import moment from 'moment';
import { packageSchema } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsProfile/components/PackageAssignment/components/PackageAssignmentModal/PackageAssignmentModal';
import { ReferrerType } from 'pages/Referrals/interface';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import * as yup from 'yup';
import { ReferralFieldsPayload } from '../../AddReferralModal/AddReferralModalInterfaces';
import { referralFormValidationInitialMessage } from '../ReferralForm';

type ValidationContext = {
  referrerType: ReferrerType;
  isIndividual: boolean;
};

const referralFormSchema = yup.object().shape({
  date: yup.string().test('Referral Date', 'Please enter a valid date', (value) => {
    if (value && value.length > 0) {
      const date = moment(value, MOMENTJS_DATE_FORMAT, true);
      return date.isValid();
    } else {
      return false;
    }
  }),
  detail: yup.string().required('Please enter referral detail/number'),
  expiryDate: yup.string().test('Expiry Date', 'Please enter a valid date', (value) => {
    if (value && value.length > 0) {
      const date = moment(value, MOMENTJS_DATE_FORMAT, true);
      return date.isValid();
    } else {
      return true;
    }
  }),
  status: yup.string().required('Please select status'),
  subject: yup.object().shape({
    firstName: yup.string().required('Please enter contact first name'),
    lastName: yup.string().required('Please enter contact last name'),
    dateOfBirth: yup.string().test('Subject Date of Birth', 'Please enter a valid date', (value) => {
      if (value && value.length > 0) {
        const date = moment(value, MOMENTJS_DATE_FORMAT, true);
        return date.isValid();
      } else {
        return true;
      }
    })
  }),
  packages: yup
    .array()
    .nullable()
    .of(packageSchema)
    .test('unique-combination', 'Package and Funder combination is duplicated', (packages) => {
      if (!packages) return true; // Allow null
      const combinations = packages.map((pkg) => `${pkg.packageId}-${pkg.funderId}`);
      const uniqueCombinations = new Set(combinations);
      return combinations.length === uniqueCombinations.size;
    }),
  referrer: yup.object().when('$data', (data: ValidationContext, schema: any) => {
    if (data.referrerType === ReferrerType.FriendFamily) {
      return yup.object().shape({
        firstName: yup.string().required('Please enter contact first name'),
        lastName: yup.string().required('Please enter contact last name'),
        relationship: yup.string().required('Please select relationship'),
        email: yup.string().email('Please enter a valid email'),
        mobile: yup.string()
      });
    } else if (
      data.referrerType === ReferrerType.Professional ||
      (data.referrerType === ReferrerType.Organisation && data.isIndividual)
    ) {
      return yup.object().shape({
        firstName: yup.string().required('Please enter contact first name'),
        lastName: yup.string().required('Please enter contact last name'),
        role: yup.string().required('Please select role')
      });
    } else {
      return schema; // Return the original schema if referralType is unknown or undefined
    }
  }),
  org: yup.object().when('$data', (data: ValidationContext, schema: any) => {
    if (data.referrerType === ReferrerType.Organisation) {
      return yup.object().shape({
        name: yup.string().required('Please enter organisation name'),
        type: yup.string().required('Please select organisation type'),
        phone: yup.string().required('Please enter phone number')
      });
    } else {
      return schema; // Return the original schema if referralType is unknown or undefined
    }
  })
});

interface GetArrayIndexResult {
  isArray: boolean;
  variableName: string | null;
  propertyName: string | null;
  index: number | null;
}

const getArrayInfoWithProperty = (str: string): GetArrayIndexResult => {
  const regex = /^([a-zA-Z_$][a-zA-Z_$0-9]*)\[(\d+)\]\.([a-zA-Z_$][a-zA-Z_$0-9]*)$/; // 'packages[1].funderId'
  const match = str.match(regex);

  if (match) {
    return {
      isArray: true,
      variableName: match[1], // 'packages' part
      propertyName: match[3], // 'funderId' part
      index: parseInt(match[2], 10) // '1' part, converted to a number
    };
  }

  return {
    isArray: false,
    variableName: null,
    propertyName: null,
    index: null
  };
};

export const validateReferralForm = (
  referralFields: ReferralFieldsPayload,
  referrerType: ReferrerType,
  isIndividual: boolean
) => {
  let emptyArrayObjects;
  if (referralFields.packages && referralFields.packages.length > 0) {
    emptyArrayObjects = Array.from({ length: referralFields.packages.length }, () => ({
      funderId: '',
      packageId: ''
    }));
  }
  const validationErrors = cloneDeep({ ...referralFormValidationInitialMessage, packages: emptyArrayObjects });
  try {
    referralFormSchema.validateSync(referralFields, {
      abortEarly: false,
      context: { data: { referrerType, isIndividual } }
    });
    return { isError: false, messages: validationErrors };
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: yup.ValidationError) => {
        const path = error.path;
        if (path && typeof path === 'string') {
          const pathParts = path.split('.');
          let currentLevel = validationErrors as any;

          const { isArray, variableName, propertyName, index } = getArrayInfoWithProperty(path);
          if (isArray && propertyName && variableName && (index || index === 0)) {
            if (currentLevel[variableName][index]) {
              currentLevel[variableName][index][propertyName] = error.message;
            }
            return;
          }

          pathParts.forEach((part, index) => {
            if (index === pathParts.length - 1) {
              currentLevel[part] = error.message;
            } else {
              if (!currentLevel[part]) {
                currentLevel[part] = {};
              }
              currentLevel = currentLevel[part];
            }
          });
        }
      });
      return { isError: true, messages: validationErrors };
    } else {
      return { isError: true, messages: validationErrors };
    }
  }
};
