import { BentResponse } from 'bent';

import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_BILLING_SERVICE_URL;

export const postConnectStripe = (token: string, payload?: { feeMethod: string }) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/stripe/connect`,
    payload
  ) as Promise<BentResponse>;
