import styles from './ControlPanelHeader.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { PackageModal } from 'pages/Packages/components/PackageModal/PackageModal';
import { useState } from 'react';

const ControlPanelHeader = ({ tab, practitionerName }: { tab?: string; practitionerName?: string }) => {
  const [isPackageModalVisible, setIsPackageModalVisible] = useState(false);

  const generatePageTitle = () => {
    switch (tab) {
      case 'packages':
        return 'Packages';

      case 'services-report':
        return `${practitionerName ? `${practitionerName} ` : ''}Services Report`;

      default:
        return 'Control Panel';
    }
  };

  const generatedHeaderContent = () => {
    switch (tab) {
      case 'packages':
        return (
          <div>
            <ButtonAlt icon={'add_circle_outline'} onClick={() => setIsPackageModalVisible(!isPackageModalVisible)}>
              New Package
            </ButtonAlt>
            <PackageModal visible={isPackageModalVisible} onClose={() => setIsPackageModalVisible(false)} />
          </div>
        );

      default:
        return <div />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{generatePageTitle()}</div>
      <div>{generatedHeaderContent()}</div>
    </div>
  );
};

export default ControlPanelHeader;
