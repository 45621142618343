import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import classnames from 'classnames';
import Loading from 'components/Loading/Loading';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { UserContextProvider } from 'utils/UserContextProvider';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import NoAuthLayout from '../NoAuthLayout/NoAuthLayout';
import { ModalVisibilityContext } from 'components/SideDashboardT23/ModalVisibilityContext';
import styles from './AuthLayoutT23.module.scss';
import SideDashboardT23 from 'components/SideDashboardT23/SideDashboardT23';
import HeaderT23 from 'components/layout/HeaderT23/HeaderT23';
import { SubTabContext } from './context/SubTabContextProvider';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import AddPatientModalV2 from 'components/AddPatientModalV2/AddPatientModalV2';
import CreateGroupModal from 'components/Groups/CreateGroupModal/CreateGroupModal';
import AddPractitionerModal from 'components/AddPractitionerModal/AddPractitionerModal';
import CreateAddressBookModal from 'components/AddressBook/CreateAddressBookModal/CreateAddressBookModal';
import AddReferralModal from 'pages/Referrals/components/AddReferralModal/AddReferralModal';
import { useDispatch } from 'react-redux';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import CarePathwayModal from '../../pages/Groups/CarePathwayModal/CarePathwayModal';
import { useFetchPracticeData, useFetchProfile } from '../../utils/hooks/GetProfileDetails/getProfileInfo';
import { handleCountryRedirection } from 'utils/handleCountryRedirection';

const AuthLayoutT23 = () => {
  const { user } = useAuth0();

  const { isEdgePlanUser, isMentorUser } = useGetAccountPackage();
  const { profile, isProfileLoading } = useFetchProfile();
  useFetchPracticeData();

  const {
    isEdgeUser,
    isEdgeAdminView,
    isEdgeUserView,
    isEdgeReceptionist,
    isEdgeUserMentorView,
    isEdgeAdminUser,
    isNormalUserView
  } = useGetAccountPackageView();
  const { isEnableStonlyGuide } = useGetFeatureToggle();
  const { showWorkspace, setShowWorkspace, showSubTab, setShowSubTab } = useContext(SubTabContext);
  const navigate = useNavigate();
  const { CALENDAR } = useRoutesGenerator();

  useEffect(() => {
    if (user?.['https://tacklit.com/roles'].includes('patient') && process.env.REACT_APP_CLIENT_DOMAIN) {
      window.location.href = process.env.REACT_APP_CLIENT_DOMAIN;
    } else if (!user?.email_verified) {
      navigate('/unverified');
    } else if (!isProfileLoading) {
      handleCountryRedirection(profile.practice?.country);

      const beaconEle = document.getElementById('beacon-container');

      if (window.location.href.includes(CALENDAR.BASE)) {
        beaconEle?.remove();
      } else if (!beaconEle) {
        const beaconV2Script = document.createElement('script');
        const beaconInitScript = document.createElement('script');

        beaconV2Script.type = 'text/javascript';
        beaconV2Script.innerHTML = `!(function (e, t, n) {
          function a() {
            var e = t.getElementsByTagName('script')[0],
              n = t.createElement('script');
            (n.type = 'text/javascript'),
              (n.async = !0),
              (n.src = 'https://beacon-v2.helpscout.net'),
              e.parentNode.insertBefore(n, e);
          }
          if (
            ((e.Beacon = n = function (t, n, a) {
              e.Beacon.readyQueue.push({ method: t, options: n, data: a });
            }),
            (n.readyQueue = []),
            'complete' === t.readyState)
          )
            return a();
            e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
          })(window, document, window.Beacon || function () {});`;

        beaconInitScript.type = 'text/javascript';
        beaconInitScript.innerHTML = `window.Beacon('init', '04a84e47-a8e3-4e59-9ced-5b8dffa47780');`;

        document.body.appendChild(beaconV2Script);
        document.body.appendChild(beaconInitScript);
      }
    }
  }, [user, navigate, profile, isProfileLoading, CALENDAR.BASE]);

  useEffect(() => {
    if (isEnableStonlyGuide) {
      const stonlyScript = document.createElement('script');

      stonlyScript.type = 'text/javascript';
      stonlyScript.innerHTML = `var STONLY_WID = 'd877dd68-99ae-11ec-9fb8-0ae9fa2a18a2';
        !(function (s, t, o, n, l, y, w, g) {
            s.StonlyWidget ||
               (((w = s.StonlyWidget = function () {
                  w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
               }).scriptPath = n),
               (w.queue = []),
               ((y = t.createElement(o)).async = !0),
               (g = new XMLHttpRequest()).open('GET', n + 'version?v=' + Date.now(), !0),
               (g.onreadystatechange = function () {
                  4 === g.readyState &&
                    ((y.src = n + 'stonly-widget.js?v=' + (200 === g.status ? g.responseText : Date.now())),
                    (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l));
                }),
              g.send());
          })(window, document, 'script', 'https://stonly.com/js/widget/v2/')`;

      document.body.appendChild(stonlyScript);
    }
  }, [isEnableStonlyGuide]);

  const backgroundColor = isEdgeAdminView ? styles.edgeSUBackground : styles.defaultBackground;

  const [addClientModalVisible, setAddClientModalVisible] = useState(false);
  const [addGroupModalVisible, setAddGroupModalVisible] = useState(false);
  const [addPractitionerModalVisible, setAddPractitionerModalVisible] = useState(false);
  const [addAddressBookModalVisible, setAddAddressBookModalVisible] = useState(false);
  const [addNewPathwayModalVisible, setAddNewPathwayModalVisible] = useState(false);
  const [addReferralModalVisible, setAddReferralModalVisible] = useState(false);
  const dispatch = useDispatch();

  const onResetAddGroupModal = () => {
    setAddGroupModalVisible(false);
  };

  return (
    <UserContextProvider>
      <div
        className={classnames(
          isEdgeUserMentorView ? styles.mentorContainer : styles.container,
          showSubTab && styles.showSubTab,
          showWorkspace && styles.showWorkspaceSidebar,
          isEdgeAdminView && 't23-admin-theme'
        )}
      >
        <div className={styles.header}>
          <HeaderT23
            className={classnames(isMentorUser && styles.fullHeader)}
            showSubTab={showSubTab}
            showWorkspace={showWorkspace}
          />
        </div>
        <ModalVisibilityContext.Provider
          value={{
            addClientModalVisible,
            setAddClientModalVisible,
            addGroupModalVisible,
            setAddGroupModalVisible,
            addPractitionerModalVisible,
            setAddPractitionerModalVisible,
            addAddressBookModalVisible,
            setAddAddressBookModalVisible,
            addNewPathwayModalVisible,
            setAddNewPathwayModalVisible,
            addReferralModalVisible,
            setAddReferralModalVisible
          }}
        >
          {!isEdgeUserMentorView && (
            <aside
              className={classnames(
                styles.sideDashboardWrapper,
                backgroundColor,
                showWorkspace && styles.workspaceActive
              )}
            >
              <SideDashboardT23
                isEdgeUser={isEdgeUser}
                isEdgeReceptionist={isEdgeReceptionist}
                isEdgeAdminView={isEdgeAdminView}
                isEdgeUserView={isEdgeUserView}
                isEdgePlanUser={isEdgePlanUser}
                isNormalUserView={isNormalUserView}
                showWorkspace={showWorkspace}
                setShowWorkspace={setShowWorkspace}
                showSubTab={showSubTab}
                setShowSubTab={setShowSubTab}
                isEdgeAdminUser={isEdgeAdminUser}
              />
            </aside>
          )}
          <main className={styles.contentWrapper}>
            <Outlet />
            {!isMentorUser && (
              <AddPatientModalV2
                visible={addClientModalVisible}
                onCancel={(isUpdated: boolean) => {
                  if (isUpdated) {
                    dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.ClientList]));
                  }

                  setAddClientModalVisible(false);
                }}
              />
            )}
            {!isMentorUser && !isNormalUserView && (
              <CreateGroupModal
                visible={addGroupModalVisible}
                onReset={onResetAddGroupModal}
                onCloseModal={() => setAddGroupModalVisible(false)}
              />
            )}
            {!isMentorUser && isEdgeAdminView && (
              <AddPractitionerModal
                visible={addPractitionerModalVisible}
                onCancel={() => setAddPractitionerModalVisible(false)}
              />
            )}

            {isEdgeAdminView && (
              <CreateAddressBookModal
                visible={addAddressBookModalVisible}
                onCloseModal={() => setAddAddressBookModalVisible(false)}
              />
            )}

            {isEdgeAdminView && (
              <CarePathwayModal
                visible={addNewPathwayModalVisible}
                onCloseModal={() => setAddNewPathwayModalVisible(false)}
              />
            )}

            {(isEdgeAdminView || isEdgeReceptionist) && (
              <AddReferralModal
                visible={addReferralModalVisible}
                onCloseModal={() => setAddReferralModalVisible(false)}
              />
            )}
          </main>
        </ModalVisibilityContext.Provider>
        {/*<div className={classnames(styles.rightSideNav, backgroundColor)}>*/}
        {/*  <RightSideNavT23*/}
        {/*    isEdgeAdminView={isEdgeAdminView}*/}
        {/*    isMobile={screenWidth < parseFloat(styles.t23LayoutChangeMediumWidth)}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.bottomMiniView}>*/}
        {/*  <BottomMiniViewT23 />*/}
        {/*</div>*/}
      </div>
    </UserContextProvider>
  );
};

export default withAuthenticationRequired(AuthLayoutT23, {
  onRedirecting: () => (
    <NoAuthLayout>
      <Loading />
    </NoAuthLayout>
  )
});
