import { Question } from 'interfaces/checkInService/AssessmentInterfaces';

import styles from './Numeric.module.scss';

interface NumericProps {
  question: Question;
}

const Numeric = ({ question }: NumericProps) => {
  return (
    <div className={styles.container}>
      <div className="question">{question.stem}</div>
      {(typeof question.response?.value === 'string' || typeof question.response?.value === 'number') && (
        <div className={styles.answer}>{question.response?.value}</div>
      )}
    </div>
  );
};

export default Numeric;
