export enum SearchByEnum {
  ChargeId = 'chargeId',
  AppointmentId = 'appointmentId'
}

export const SearchByItems = [
  { name: 'Charge ID', _id: SearchByEnum.ChargeId },
  { name: 'Appointment ID', _id: SearchByEnum.AppointmentId }
];

export const ScheduledPaymentSearchByItems = [
  { name: 'Intent ID', _id: SearchByEnum.ChargeId },
  { name: 'Appointment ID', _id: SearchByEnum.AppointmentId }
];
