import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { EpisodeInterface, EpisodeStatus, EPISODE_STATUS_LABELS, ReasonUse } from 'interfaces/Episodes/episodes';
import formatSettings from 'pages/AssessmentDetails/CaseNoteTemplate/formatSettings.json';
import { CaseNoteFormatSettingsFields } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';

export enum EpisodeCardAction {
  ChangeStatus = 'changeStatus',
  Edit = 'edit',
  Archived = 'archived',
  ConfirmArchived = 'confirmArchived'
}

export enum ChangeEpisodeStatusState {
  activeClient = 'activeClient',
  closeClient = 'closeClient',
  activeEpisode = 'activeEpisode',
  createEpisode = 'createEpisode'
}

export const nonEditStatus = [
  EpisodeStatus.Archived,
  EpisodeStatus.Cancelled,
  EpisodeStatus.Error,
  EpisodeStatus.Finished
];

export const changeEpisodeStatusOption: Record<ChangeEpisodeStatusState, string> = {
  [ChangeEpisodeStatusState.activeClient]: 'Have no active episode, remain client STATUS as ACTIVE',
  [ChangeEpisodeStatusState.closeClient]: 'Have no active episode, change client STATUS to CLOSED',
  [ChangeEpisodeStatusState.activeEpisode]: 'Designate another episode as ACTIVE',
  [ChangeEpisodeStatusState.createEpisode]: 'Create a new ACTIVE care episode'
};

export const generateStatusList = (status: EpisodeStatus[]) =>
  status.map((statusObj) => ({
    label: EPISODE_STATUS_LABELS[statusObj].toUpperCase(),
    value: statusObj
  }));

export const editEpisodeList = (epStatus: EpisodeStatus) => {
  switch (epStatus) {
    case EpisodeStatus.Draft:
      return generateStatusList([EpisodeStatus.Draft, EpisodeStatus.Planned, EpisodeStatus.Active]);
    case EpisodeStatus.Planned:
      return generateStatusList([EpisodeStatus.Draft, EpisodeStatus.Planned, EpisodeStatus.Active]);
    case EpisodeStatus.Active:
      return generateStatusList([
        EpisodeStatus.Active,
        EpisodeStatus.OnHold,
        EpisodeStatus.Finished,
        EpisodeStatus.Cancelled,
        EpisodeStatus.Error
      ]);
    case EpisodeStatus.OnHold:
      return generateStatusList([
        EpisodeStatus.Active,
        EpisodeStatus.OnHold,
        EpisodeStatus.Finished,
        EpisodeStatus.Cancelled,
        EpisodeStatus.Error
      ]);
    case EpisodeStatus.Finished:
      return generateStatusList([EpisodeStatus.Finished]);
    case EpisodeStatus.Cancelled:
      return generateStatusList([EpisodeStatus.Cancelled]);
    case EpisodeStatus.Archived:
      return generateStatusList([EpisodeStatus.Archived]);
    case EpisodeStatus.Error:
      return generateStatusList([EpisodeStatus.Error]);
    default:
      return generateStatusList([
        EpisodeStatus.Draft,
        EpisodeStatus.Planned,
        EpisodeStatus.Active,
        EpisodeStatus.OnHold,
        EpisodeStatus.Finished,
        EpisodeStatus.Cancelled,
        EpisodeStatus.Error
      ]);
  }
};

export const createEpisodeStatus = generateStatusList([
  EpisodeStatus.Draft,
  EpisodeStatus.Planned,
  EpisodeStatus.Active,
  EpisodeStatus.Finished,
  EpisodeStatus.Cancelled
]);

export const episodeTypes: IOptionItem[] = [
  {
    label: 'Acute care',
    value: 'acuteCare',
    subMenu: [
      {
        label: 'Emergency response',
        value: 'Emergency response'
      },
      {
        label: 'Transfer',
        value: 'Transfer'
      }
    ]
  },
  {
    label: 'In patient admission',
    value: 'In patient admission',
    subMenu: [
      {
        label: 'Planned',
        value: 'Planned'
      },
      {
        label: 'Unplanned',
        value: 'Unplanned'
      }
    ]
  },
  {
    label: 'Out patient',
    value: 'Out patient',
    subMenu: [
      {
        label: 'Ongoing treatment',
        value: 'Ongoing treatment'
      },
      {
        label: 'Post In-Patient support / referral',
        value: 'Post In-Patient support / referral'
      },
      {
        label: 'Community Program',
        value: 'Community Program'
      }
    ]
  },
  {
    label: 'Request for assessment / diagnosis',
    value: 'Request for assessment / diagnosis',
    subMenu: [
      {
        label: 'Psychiatric assessment',
        value: 'Psychiatric assessment'
      },
      {
        label: 'ADHD assessment',
        value: 'ADHD assessment'
      },
      {
        label: 'Sleep assessment',
        value: 'Sleep assessment'
      },
      {
        label: 'Personality disorder assessment',
        value: 'Personality disorder assessment'
      },
      {
        label: 'Other assessment',
        value: 'Other assessment'
      }
    ]
  },
  {
    label: 'Primary care',
    value: 'Primary care',
    subMenu: [
      {
        label: 'Allied Health',
        value: 'Allied Health'
      },
      {
        label: 'General Practice',
        value: 'General Practice'
      }
    ]
  },
  {
    label: 'Secondary care',
    value: 'Secondary care',
    subMenu: [
      {
        label: 'GP Referral - first presentation',
        value: 'GP Referral - first presentation'
      },
      {
        label: 'GP Referral - continuation',
        value: 'GP Referral - continuation'
      },
      {
        label: 'Community Referral',
        value: 'Community Referral'
      },
      {
        label: 'Self Referral',
        value: 'Self Referral'
      },
      {
        label: 'Case Manager Referral',
        value: 'Case Manager Referral'
      }
    ]
  },
  {
    label: 'Drug and alcohol rehabilitation',
    value: 'Drug and alcohol rehabilitation'
  },
  {
    label: 'Palliative',
    value: 'Palliative',
    subMenu: [
      {
        label: 'Program',
        value: 'Program'
      },
      {
        label: 'Clinical',
        value: 'Clinical'
      }
    ]
  },
  {
    label: 'Non-Acute Care',
    value: 'Non-Acute Care',
    subMenu: [
      {
        label: 'Respite',
        value: 'Respite'
      },
      {
        label: 'Nursing Home',
        value: 'Nursing Home'
      }
    ]
  }
];

const presentingIssuesCommonOptions: CaseNoteFormatSettingsFields['commonChoices'] = formatSettings.fields.find(
  (fieldObj) => fieldObj.key === 'presentingIssues'
)?.commonChoices;
export const medicalReasons: IOptionItem[] = presentingIssuesCommonOptions?.[0].options || [];

export const reasonUses: IOptionItem[] = [
  {
    label: ReasonUse.ChiefComplaint,
    value: ReasonUse.ChiefComplaint
  },
  {
    label: ReasonUse.HealthConcern,
    value: ReasonUse.HealthConcern
  },
  {
    label: ReasonUse.AdmittingDiagnosis,
    value: ReasonUse.AdmittingDiagnosis
  },
  {
    label: ReasonUse.ReasonForVisit,
    value: ReasonUse.ReasonForVisit
  },
  {
    label: ReasonUse.HealthMaintenance,
    value: ReasonUse.HealthMaintenance
  }
];

export interface CreateEpisodeProps extends Pick<EpisodeInterface, 'type' | 'reason'> {
  startDate?: Date | string;
  endDate?: Date | string;
  episodeId?: string;
  status: EpisodeStatus;
  permissions?: string[];
  currentEpisodeStatus?: EpisodeStatus;
  currentEpisodeEndDate?: Date | string;
}

export const DEFAULT_EPISODE_ORDER = 1;

export const defaultEpisode: CreateEpisodeProps = {
  episodeId: undefined,
  status: EpisodeStatus.Draft,
  type: '',
  reason: {
    medicalReason: undefined,
    details: '',
    reasonUse: undefined
  },
  startDate: undefined,
  endDate: undefined,
  permissions: undefined,
  currentEpisodeStatus: undefined,
  currentEpisodeEndDate: undefined
};

export const EPISODE_PER_PAGE = 30;
