import styles from './OnboardingContentView.module.scss';
import AssessmentsHeader from '../../../components/AssessmentsHeader/AssessmentsHeader';
import { FirstAssessmentStruct, QuestionSetStruct } from 'interfaces/firstAssessment';
import QuestionSectionView from './components/QuestionSectionView/QuestionSectionView';
import { useEffect, useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useFetchOpenDataCategories } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/hooks/useFetchOpenDataCategories';

interface OnboardingContentViewProps {
  data: FirstAssessmentStruct;
}

const OnboardingContentView = ({ data }: OnboardingContentViewProps) => {
  const [assessment, setAssessment] = useState(data);
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { openDataCategories, isOpenDataCategoriesLoading } = useFetchOpenDataCategories(token, accountId);

  useEffect(() => {
    if (data && !isOpenDataCategoriesLoading) {
      if (data.questionSets.some(({ categoryId }) => categoryId)) {
        const combineAssessment: FirstAssessmentStruct = {
          ...data,
          questionSets: [
            ...data.questionSets.map((set: QuestionSetStruct) => ({
              ...set,
              questions: set.categoryId
                ? openDataCategories
                    .find((item) => item._id === set.categoryId)
                    ?.questions.map((i) => ({
                      id: i.id,
                      isChecked: true,
                      stem: i.stem
                    })) || []
                : set.questions
            }))
          ]
        };
        setAssessment(combineAssessment);
      } else {
        setAssessment(data);
      }
    }
  }, [data, openDataCategories, isOpenDataCategoriesLoading]);

  return (
    <div>
      <AssessmentsHeader title={'Survey'} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.leftWrapper}>
            <div className={styles.titleBox}>
              <div className={styles.title}>Name of survey</div>
              <div className={styles.value}>{assessment.name}</div>
            </div>
            <div className={styles.diffNameBox}>
              {!!assessment.diffName && (
                <>
                  <div className={styles.title}>Survey's name for clients</div>
                  <div className={styles.value}>{assessment.diffName}</div>
                </>
              )}
            </div>
            {!!assessment.description && (
              <div className={styles.descBox}>
                <div className={styles.title}>Short description of this assessment</div>
                <div className={styles.value}>{assessment.description}</div>
              </div>
            )}
            <div className={styles.title}>Tags</div>
            <div className={styles.tagBox}>
              {assessment.tags?.map((obj) => (
                <div className={styles.tagItem}>{obj}</div>
              ))}
            </div>
            {!!assessment.note && (
              <div className={styles.noteBox}>
                <div className={styles.title}>Intro note for recipient</div>
                <div
                  className={`ql-editor ${styles.text}`}
                  dangerouslySetInnerHTML={{ __html: assessment.note || '' }}
                />
              </div>
            )}
          </div>
          <div className={styles.rightWrapper}>
            <QuestionSectionView
              questionList={assessment.questionSets}
              isOpenDataCategoriesLoading={isOpenDataCategoriesLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingContentView;
