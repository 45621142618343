import { notification } from 'antd';
import classNames from 'classnames';
import mime from 'mime';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './ReferralDocumentForm.module.scss';

import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { referralFilesInterface } from 'interfaces/Clients/clientsRecord';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { useGetAccessToken } from 'utils/hooks/token';
import { getDecryptedDocument } from 'utils/http/ClinicianProfileService/DecryptedDocument/download';
import { ReferralFieldsPayload } from '../../../AddReferralModal/AddReferralModalInterfaces';

interface ReferralDocumentFormProps {
  referral: ReferralFieldsPayload;
  onChange: (files: File[]) => void;
}

interface FileWithId {
  id: string;
  file: File;
}

export interface ReferralDocumentFormRef {
  resetFiles: () => Promise<void>;
}

export const FILE_COUNT_LIMIT = 5;

const ReferralDocumentForm = forwardRef<ReferralDocumentFormRef, ReferralDocumentFormProps>(
  ({ referral, onChange }, ref) => {
    const { token } = useGetAccessToken();
    const referralFiles = referral?.files ?? [];

    const [selectedFiles, setSelectedFiles] = useState<FileWithId[]>([]);

    const handleDownloadAttachment = async (attachment: referralFilesInterface) => {
      try {
        const callGetDecryptedDocument = await getDecryptedDocument(token, attachment.bucketName, attachment.fileName);

        const decryptedDocument = await callGetDecryptedDocument.arrayBuffer();

        const documentBlob = new Blob([decryptedDocument], {
          type: mime.getType(attachment.fileName) || 'application/octet-stream'
        });

        window.open(URL.createObjectURL(documentBlob), '_blank');
      } catch (ex) {
        console.error(ex);

        notification.error({ message: 'Something went wrong while trying to download this document.' });
      }
    };

    const onAddRefAttachment = () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = true;
      input.onchange = async (event) => {
        const files = (event.target as HTMLInputElement).files;
        if (!files || files.length === 0) {
          return;
        }

        const existingFileCount = selectedFiles.length;
        if (existingFileCount + files.length > FILE_COUNT_LIMIT) {
          notification.error({
            message: `You can only upload up to ${FILE_COUNT_LIMIT} files. You have already selected ${existingFileCount} files.`
          });
          return;
        }

        const filesWithId = Array.from(files).map((file) => ({
          file: file,
          id: uuidv4()
        }));

        setSelectedFiles([...selectedFiles, ...filesWithId]);
        onChange([...selectedFiles.map((file) => file.file), ...Array.from(files)]);

        input.value = '';
      };
      input.click();
    };

    const onRemoveRefAttachment = (id: string) => {
      const newFiles = selectedFiles.filter((file) => file.id !== id);
      setSelectedFiles(newFiles);
      onChange(newFiles.map((file) => file.file));
    };

    useImperativeHandle(ref, () => ({
      resetFiles: async () => setSelectedFiles([])
    }));

    return (
      <div className={styles.container}>
        <div className={styles.referralContent}>
          {referralFiles.map((fileObj, index) => {
            const formattedFileName = fileObj.fileName.substring(fileObj.fileName.indexOf('-') + 1);
            const formattedCreatedTime = moment(fileObj.createdAt).format(MOMENTJS_DATE_FORMAT);

            return (
              <div key={index} className={styles.refDocRecord}>
                <div className={styles.refDocName}>
                  <i className={classNames('material-icons', styles.listIcon)}>done</i>
                  {formattedFileName}
                </div>

                <div className={styles.refDocAddedDetails}>
                  <div>
                    Added <b>{formattedCreatedTime}</b>
                    {fileObj.uploaderName ? ` by ${fileObj.uploaderName}` : ' by the referee'}
                  </div>
                </div>

                <div className={styles.viewAction}>
                  <ButtonAlt
                    contentClassName={styles.buttonContent}
                    variant="text"
                    size="medium"
                    onClick={() => handleDownloadAttachment(fileObj)}
                    icon="attach_file"
                  >
                    View
                  </ButtonAlt>
                </div>
              </div>
            );
          })}

          {selectedFiles.length > 0 && (
            <div>
              {selectedFiles.map(({ file, id }, index) => {
                return (
                  <div key={index} className={styles.refDocRecord}>
                    <div className={styles.refDocName}>
                      <i className={classNames('material-icons', styles.listIcon)}>done</i>
                      {file.name}
                    </div>

                    <div className={styles.refDocAddedDetails}>
                      <div>To be added</div>
                    </div>

                    <div className={styles.viewAction}>
                      <ButtonAlt
                        contentClassName={styles.buttonContent}
                        variant="text"
                        size="medium"
                        onClick={() => onRemoveRefAttachment(id)}
                        icon="delete"
                        fab
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <ButtonAlt variant="outlined" onClick={onAddRefAttachment} icon="upload">
          Upload New Document
        </ButtonAlt>
      </div>
    );
  }
);

export default ReferralDocumentForm;
