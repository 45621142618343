import styles from './ClientPickAssessment.module.scss';
import { ClinicalAssessmentStruct } from 'interfaces/firstAssessment';
import { PatientAssessment } from '../../SendAdhocAssessmentModal/interfaces';

import classnames from 'classnames';
import ClientOrGroupPickAssessment from 'components/ClientOrGroupPickAssessment/ClientOrGroupPickAssessment';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useTranslation } from 'react-i18next';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface ClientPickAssessmentProps {
  patientAssessments: PatientAssessment[];
  proxyProfiles?: PatientAssessment[];
  selectedProxyProfileId?: string;
  noReselectClient?: boolean;
  onBackClick: () => void;
  onNextClick: () => void;
  onAddSelectedAssessment: (item: ClinicalAssessmentStruct, clientId?: string) => void;
  onRemoveSelectedAssessment: (item: ClinicalAssessmentStruct, clientId?: string) => void;
  onSelectClientProfile: (isSelected: boolean, clientId: string) => void;
  handleProxyProfileChange: (value: string) => void;
}

const ClientPickAssessment = ({
  patientAssessments,
  noReselectClient,
  proxyProfiles,
  selectedProxyProfileId,
  className,
  handleProxyProfileChange,
  onBackClick,
  onNextClick,
  onAddSelectedAssessment,
  onRemoveSelectedAssessment,
  onSelectClientProfile
}: ClientPickAssessmentProps & React.HTMLAttributes<HTMLDivElement>) => {
  const [t] = useTranslation();

  const isDisable =
    patientAssessments.filter((patient) => patient.isSelected).length < 1 ||
    !patientAssessments.some((patient) => {
      return patient.selectedAssessments.length > 0;
    }) ||
    patientAssessments.some((patient) => patient.client.role === 'child' && !selectedProxyProfileId);

  return (
    <div className={classnames(styles.container, className)}>
      {!noReselectClient && (
        <ButtonAlt
          variant="text"
          size="medium"
          className={styles.backBtn}
          icon={'arrow_back_ios_new'}
          onClick={() => onBackClick()}
        >
          {t('label.change_client')}
        </ButtonAlt>
      )}
      <div className={styles.pickAssessmentWrapper}>
        {patientAssessments.map((patientAssessment, index) => (
          <div key={patientAssessment.client._id} className={styles.clientItem}>
            <ClientOrGroupPickAssessment
              clientId={patientAssessment.client._id}
              selectedProxyProfileId={selectedProxyProfileId}
              onSelectClientProfile={onSelectClientProfile}
              isShowCheckBox={patientAssessments.length > 1}
              patientAssessment={patientAssessment}
              participationType={ParticipantType.OneToOne}
              selectedAssessments={patientAssessment.selectedAssessments}
              onAddSelectedAssessment={onAddSelectedAssessment}
              onRemoveSelectedAssessment={onRemoveSelectedAssessment}
              proxyProfiles={proxyProfiles}
              handleProxyProfileChange={handleProxyProfileChange}
            />
          </div>
        ))}
      </div>
      <div className={styles.submitBtn}>
        <ButtonAlt disabled={isDisable} onClick={onNextClick}>
          Next
        </ButtonAlt>
      </div>
    </div>
  );
};

export default ClientPickAssessment;
