import { useState } from 'react';

import styles from './ReportHeaderEdit.module.scss';
import Modal from 'components/Modal/Modal';
import { notification } from 'antd';
import { config } from 'config/config';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { Formik } from 'formik';
import FormikPlaceMaterialInput from 'components/FormikPlaceMaterialInput/FormikPlaceMaterialInput';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import * as yup from 'yup';
import { Tooltip } from 'react-tooltip';
import { Report, ReportContactDetails } from 'interfaces/Reports/report';
import { useGetAccessToken } from 'utils/hooks/token';
import { putReportContactDetails } from 'utils/http/DocumentService/Reports/reports';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ClinicianDetails } from 'interfaces/Letters/letter';

const { mobileNumberMinLength } = config;

const BasicDetailHeaderSchema = yup.object().shape({
  practice: yup.object().shape({
    mobileNumber: yup.object().shape({
      value: yup
        .string()
        .min(mobileNumberMinLength, `Mobile number has to be at least ${mobileNumberMinLength} characters`)
        .nullable()
    })
  })
});

interface BasicDetailHeadingEditProps {
  visible: boolean;
  onCloseModal: () => void;
  clinicianDetails: ClinicianDetails;
  clinicianContactDetails: ReportContactDetails;
  onChangeContactDetails: any;
}

const ReportHeaderEdit = ({
  visible,
  onCloseModal,
  clinicianDetails,
  clinicianContactDetails,
  onChangeContactDetails
}: BasicDetailHeadingEditProps) => {
  const { token } = useGetAccessToken();
  const [saveStatus, setSaveStatus] = useState<'' | 'active' | 'finished'>('');

  const handleSubmit = async (values: Report['contactDetails']) => {
    setSaveStatus('active');
    try {
      await putReportContactDetails(token, { ...values });

      setSaveStatus('finished');
      notification.success({
        message: 'Report contact detail updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>,
        onClose: () => setSaveStatus('')
      });
      setTimeout(() => {
        onCloseModal();
      }, 1000);
    } catch (ex) {
      setSaveStatus('');
      notification.error({
        message: 'Something went wrong while trying to update your Report contact details',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    onChangeContactDetails(values);
  };

  const onSubmit = (e: any, submitForm: () => void) => {
    e.preventDefault();
    submitForm();
  };

  return (
    <Modal isModalOpen={visible}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            Report & Letter header contact details
            <i className={`material-icons ${styles.tooltipIcon}`} data-tooltip-id="reportHeaderSetting">
              help_outline
            </i>
            <Tooltip id="reportHeaderSetting" className={styles.tooltip}>
              Select and edit what contact details show in your report. This will be saved for any future reports as
              well. To update your practice logo please go to your profile page and update them there.
            </Tooltip>
          </div>
          {
            /** Prevent dialog from closing when saving. */
            saveStatus === '' && (
              <i onClick={onCloseModal} className={`material-icons ${styles.icon}`}>
                close
              </i>
            )
          }
        </div>
        <Formik
          initialValues={clinicianContactDetails}
          onSubmit={handleSubmit}
          validationSchema={BasicDetailHeaderSchema}
          enableReinitialize
        >
          {({ errors, values, setFieldValue, submitForm }) => {
            return (
              <>
                <div className={styles.content}>
                  {clinicianDetails.practice && (
                    <div className={styles.profileInfo}>
                      {clinicianDetails.practice?.logo && (
                        <img className={styles.image} src={clinicianDetails.practice.logo} alt="Practice logo" />
                      )}
                      <div className={styles.detailsWrapper}>
                        <span className={styles.detailsTitle}>{clinicianDetails.practice?.name}</span>
                        <div className={styles.detailInfoWrapper}>
                          <div className={styles.inputWrapper}>
                            <label className={styles.checkboxContainer} htmlFor={'practiceAddress'}>
                              <input
                                className={styles.checkbox}
                                id="practiceAddress"
                                type="checkbox"
                                checked={values.practice.address.isVisible}
                                onChange={(e) => setFieldValue('practice.address.isVisible', e.target.checked)}
                              />
                            </label>
                            <FormikPlaceMaterialInput
                              name={'practice.address.value'}
                              placeholder={'Practice Address'}
                              label={'Practice Address'}
                              className={styles.detailInput}
                            />
                          </div>
                          <div className={styles.inputWrapper}>
                            <label className={styles.checkboxContainer} htmlFor={'practiceMobile'}>
                              <input
                                className={styles.checkbox}
                                id="practiceMobile"
                                type="checkbox"
                                checked={values.practice.mobileNumber.isVisible}
                                onChange={(e) => setFieldValue('practice.mobileNumber.isVisible', e.target.checked)}
                              />
                            </label>
                            <div className={styles.questionWrapper}>
                              <MaterialInput
                                id={'practiceMobileNumber'}
                                label={'Practice Mobile number'}
                                placeholder={'Practice Mobile number'}
                                value={values.practice.mobileNumber.value}
                                className={styles.detailInput}
                                onChange={(e) => setFieldValue('practice.mobileNumber.value', e.target.value)}
                              />
                              <ErrorMessage
                                error={errors.practice?.mobileNumber?.value}
                                visible={!!errors.practice?.mobileNumber?.value}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.buttonWrapper}>
                  <ButtonAlt status={saveStatus} onClick={(e) => onSubmit(e, submitForm)}>
                    Save
                  </ButtonAlt>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ReportHeaderEdit;
