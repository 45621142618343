import { useCurrency } from 'utils/hooks/useCurrency';
import styles from './PaymentDetailsForModal.module.scss';
import { combineName } from 'utils/general';
import { InvoicePayment } from 'pages/InvoicePayments/constants';
import momentTz from 'moment-timezone';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import classNames from 'classnames';

interface PaymentDetailsForModalProps {
  payment: InvoicePayment;
}

const PaymentDetailsForModal = ({ payment }: PaymentDetailsForModalProps) => {
  const { CURRENCY_SYMBOL, formatCurrency } = useCurrency();
  const { timeZoneByView } = useTimeZone();

  const { appointment, payments, amount } = payment;
  const { clientRecord, sessionTypeName, startDateTime, endDateTime, invoices } = appointment || {};
  const { clientProfiles, caseId, tacklitId } = clientRecord || {};

  const serviceDateFormatted = momentTz(startDateTime).tz(timeZoneByView).format('DD MMM YYYY');
  const serviceTimeFormatted = `${momentTz(startDateTime).tz(timeZoneByView).format('hh:mm')} - ${momentTz(endDateTime)
    .tz(timeZoneByView)
    .format('hh:mmA')}`;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.service}>SERVICE</div>
        <div className={styles.serviceDate}>SERVICE DATE</div>
        <div className={styles.client}>CLIENT</div>
        <div className={styles.invoiceId}>INVOICE ID</div>
        <div className={styles.amount}>{`${CURRENCY_SYMBOL} AMOUNT`}</div>
        <div className={styles.eligibleForRefund}>{`${CURRENCY_SYMBOL} ELIGIBLE FOR REFUND`}</div>
      </div>
      <div className={styles.details}>
        <div className={styles.service}>{sessionTypeName}</div>
        <div className={styles.serviceDate}>
          <div>{serviceDateFormatted}</div>
          <div>{serviceTimeFormatted}</div>
        </div>
        <div className={styles.client}>
          {clientProfiles && <div className={styles.clientName}>{combineName(clientProfiles)}</div>}
          {caseId && <div className={styles.caseId}>{caseId}</div>}
          {tacklitId && <div className={styles.tacklitId}>{tacklitId}</div>}
        </div>

        <div className={styles.invoiceId}>
          {invoices && invoices.length > 0 ? (
            <div className={styles.invoiceIds}>
              {invoices.map(({ _id, invoiceId }) => (
                <div key={_id}>{invoiceId}</div>
              ))}
            </div>
          ) : (
            '-'
          )}
        </div>
        <div className={classNames(styles.amount, styles.amountValue)}>{formatCurrency(amount)}</div>
        <div className={classNames(styles.eligibleForRefund, styles.amountValue)}>{formatCurrency(payments.paid)}</div>
      </div>
    </div>
  );
};

export default PaymentDetailsForModal;
