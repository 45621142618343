import {
  AppointmentTypeAvailabilities,
  AvailabilityTimeSlotsByDate
} from 'interfaces/Schedule/AppointmentTypeAvailabilities';
import moment, { Moment } from 'moment';
import { MOMENTJS_FORMAT_DATE } from 'utils/appointment';

export const getNextAvailableDate = ({
  currentDate,
  appointmentTypeAvailabilities
}: {
  currentDate: Moment;
  appointmentTypeAvailabilities?: AppointmentTypeAvailabilities;
}) => {
  const currentDateString = currentDate.clone().day(7).format(MOMENTJS_FORMAT_DATE);
  const nextAvailableDate =
    appointmentTypeAvailabilities &&
    Object.entries(appointmentTypeAvailabilities.timeSlots).find(
      ([date, timeSlots]) =>
        date >= currentDateString && timeSlots.isAvailable && timeSlots.timeSlots.some(({ isAvailable }) => isAvailable)
    );

  return nextAvailableDate ? moment(nextAvailableDate[0], MOMENTJS_FORMAT_DATE) : undefined;
};

export const getAllDatesBasedOnNumberOfDaysShown = (numberOfDaysShown: number, currentDate: Moment) => {
  const dates = [];
  for (let i = 0; i < numberOfDaysShown; i++) {
    dates.push(currentDate.clone().add(i, 'day').format(MOMENTJS_FORMAT_DATE));
  }

  return dates;
};

export const getTargetAppointmentSlots = ({
  numberOfDaysShown,
  currentDate,
  appointmentTypeAvailabilities
}: {
  numberOfDaysShown: number;
  currentDate: Moment;
  appointmentTypeAvailabilities?: AppointmentTypeAvailabilities;
}) => {
  const availableDates = getAllDatesBasedOnNumberOfDaysShown(numberOfDaysShown, currentDate);

  const targetAppointmentSlots: ({ date: string } | ({ date: string } & AvailabilityTimeSlotsByDate[string]))[] =
    availableDates.map((date) => ({
      ...appointmentTypeAvailabilities?.timeSlots[date],
      date
    }));

  const targetAppointmentSlotsHasNoAvailability = !targetAppointmentSlots.some(
    (appointmentSlots) =>
      'isAvailable' in appointmentSlots &&
      appointmentSlots.isAvailable &&
      appointmentSlots.timeSlots?.some(({ isAvailable }) => isAvailable)
  );

  return {
    targetAppointmentSlots,
    targetAppointmentSlotsHasNoAvailability,
    nextAvailableDate: getNextAvailableDate({
      currentDate,
      appointmentTypeAvailabilities
    })
  };
};
