import { CONTACT_TAG_OPTIONS } from 'pages/Groups/GroupDetails/components/GroupContent/components/KeyContacts/constants';
import { ItemColumns, ItemOverview } from 'redux/invoiceTemplate/invoiceTemplateCreateFormSlice';
import { config } from 'config/config';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

const IS_DEVELOPMENT = isDevelopmentENV();

export const DUE_DATE_OPTIONS = [
  { label: 'Immediate (Today)', value: '0' },
  { label: 'Tomorrow', value: '1' },
  { label: '2 Days', value: '2' },
  { label: '3 Days', value: '3' },
  { label: '7 Days', value: '7' },
  { label: '14 Days', value: '14' },
  { label: '28 Days', value: '28' }
];

export const RECIPIENT_OPTIONS = [
  {
    value: 'client',
    label: 'Client'
  },
  {
    value: 'keyClientContact',
    label: 'Key client contact',
    subMenu: CONTACT_TAG_OPTIONS.map((item) => ({ value: item, label: item }))
  },
  {
    value: 'funder',
    label: `Funder (${IS_DEVELOPMENT ? 'Package/Contacts' : 'primary contact'})`
  }
];

export const INCLUDE_ITEM_MENU_OPTIONS = [
  { label: 'Appointment name', value: ItemOverview.Name, isDisabled: false, group: 'itemOverviews' },
  { label: 'Appointment ID', value: ItemOverview.Id, isDisabled: false, group: 'itemOverviews' },
  { label: 'Appointment date', value: ItemOverview.Date, isDisabled: false, group: 'itemOverviews' },
  {
    label: 'Appointment duration',
    value: ItemOverview.Duration,
    isDisabled: false,
    group: 'itemOverviews'
  },
  { label: 'Appointment tag', value: ItemOverview.Status, isDisabled: false, group: 'itemOverviews' },
  {
    label: 'Service delivered by',
    value: ItemOverview.Owner,
    isDisabled: false,
    group: 'itemOverviews'
  }
];

export const INCLUDE_COLUMN_MENU_OPTIONS = [
  { label: 'Quantity', value: ItemColumns.Quantity, isDisabled: true, group: 'itemColumns' },
  { label: 'Unit price', value: ItemColumns.UnitPrice, isDisabled: true, group: 'itemColumns' },
  {
    label: `${config.taxRateLabel} rate`,
    value: ItemColumns.TaxRate,
    isDisabled: true,
    group: 'itemColumns'
  },
  { label: 'Total', value: ItemColumns.Total, isDisabled: true, group: 'itemColumns' }
];

export const GROUPED_OPTIONS = [
  {
    label: 'ITEM INCLUDE',
    options: INCLUDE_ITEM_MENU_OPTIONS
  },
  {
    label: 'COLUMN INCLUDE',
    options: INCLUDE_COLUMN_MENU_OPTIONS
  }
];

export const SendTypeLabels: { [key: string]: string } = {
  client: 'Client',
  keyClientContact: 'Key client contact',
  funder: `Funder (${IS_DEVELOPMENT ? 'Package/Contacts' : 'primary contact'})`
};
