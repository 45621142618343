import { notification } from 'antd';
import { Claim } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';
import { useCallback, useState } from 'react';
import { PractitionersListMinified } from 'redux/endpoints/clinicianProfileServices/practitioner';
import { getClaimHistory } from 'utils/http/BillingService/Invoice/claimingcom';

export interface ClaimHistoryItem extends Claim {
  createdByClinician?: PractitionersListMinified;
}

export const useFetchClaimHistory = (token: string, claimId: string) => {
  const [claimHistory, setClaimHistory] = useState<ClaimHistoryItem[]>();
  const [isClaimHistoryLoading, setIsClaimHistoryLoading] = useState(true);

  const fetchClaimHistory = useCallback(
    async (token: string) => {
      try {
        const getClaimHistoryResponse = await getClaimHistory(token, claimId);
        const claimHistory = (await getClaimHistoryResponse.json()) as ClaimHistoryItem[];

        setClaimHistory(claimHistory);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: "Something went wrong while trying to get this claim's history" });
      }

      setIsClaimHistoryLoading(false);
    },
    [claimId]
  );

  const refetchClaimHistory = useCallback(() => {
    fetchClaimHistory(token);
  }, [fetchClaimHistory, token]);

  return { claimHistory, isClaimHistoryLoading, refetchClaimHistory };
};
