import styles from './MBSCodeOptionItem.module.scss';

interface MBSCodeOptionItemProps {
  label: string;
  description?: string;
}

const MBSCodeOptionItem = ({ label, description }: MBSCodeOptionItemProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.mbsCode}>{label}</div>
      {description && <div className={styles.mbsCodeDetails}>{description}</div>}
    </div>
  );
};

export default MBSCodeOptionItem;
