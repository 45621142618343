import { Pagination } from 'interfaces';
import { EpisodeInterface } from 'interfaces/Episodes/episodes';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { Paging } from 'redux/endpoints/clinicianProfileServices/episode';
import { EPISODE_PER_PAGE } from 'pages/PatientDetails/components/PatientDetailsContent/components/ClientDetailsEoC/interface/constants';

export const useFetchEpisodes = (activeEpisodeId?: string) => {
  const [episodes, setEpisodes] = useState<EpisodeInterface[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    perPage: EPISODE_PER_PAGE,
    totalItem: 0,
    totalPage: 0
  });

  const handleUpdateEpisodes = (newEpisodes: EpisodeInterface[], activeEpisodeId: string): void => {
    if (!newEpisodes.length) {
      return;
    }

    setEpisodes((prev) =>
      [
        ...prev.filter((item) => !newEpisodes.map(({ _id }) => _id).includes(item._id) && item._id !== activeEpisodeId),
        ...newEpisodes
      ].sort((a, b) => b.order - a.order)
    );
  };

  const handleUpdatePaging = (paging: Paging): void => {
    setHasMore(paging.totalItems / paging.perPage > pagination.page);
  };

  const fetchMoreEpisodes = _.debounce(() => {
    setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
  }, 1000);

  const draftAndPlannedEpisodeOptions = useMemo(() => {
    return episodes
      .filter((item) => activeEpisodeId && item._id !== activeEpisodeId)
      .map((item) => ({
        ...item,
        value: item._id,
        label: `Episode ${item.order}`
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodes]);

  const fetchFromEmpty = () => {
    setEpisodes([]);
    setPagination({
      page: 1,
      perPage: EPISODE_PER_PAGE,
      totalItem: 0,
      totalPage: 0
    });
  };

  return {
    pagination,
    fetchMoreEpisodes,
    episodes,
    draftAndPlannedEpisodeOptions,
    handleUpdateEpisodes,
    handleUpdatePaging,
    hasMore,
    fetchFromEmpty
  };
};
