import classNames from 'classnames';
import { DatePicker, DatePickerProps, Skeleton } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment, { Moment } from 'moment';
import styles from './InvoiceListFilter.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import { useGetMinifiedClientRecordQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useCallback, useMemo, useState } from 'react';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import {
  DEFAULT_SORTING,
  resetFilters,
  resetPaging,
  resetSearch,
  selectActiveTab,
  selectFilters,
  selectSearch,
  selectSorting,
  setFilters,
  setSearch,
  setSorting
} from 'redux/invoices/invoiceSlice';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import { debounce } from 'lodash';
import { StatusFilterType } from 'utils/hooks/useFetchInvoices';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetPractitionerListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import customStyles from 'pages/InvoiceSubmittedClaims/components/SubmittedClaimFilter/DateRangePickerCustom.module.scss';
import { CLAIM_TYPE_OPTIONS, InvoiceTab } from 'pages/InvoicesV2/constants';
import { useCurrency } from 'utils/hooks/useCurrency';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import ParticipationToggle from 'components/SelectClientOrGroup/components/ParticipationHeader/components/ParticipationToggle/ParticipationToggle';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import SearchBar from 'components/SearchBar/SearchBar';
import { useGetInvoicesList } from 'redux/invoices/useGetInvoices';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useGetMinifiedGroupListQuery } from 'redux/endpoints/clinicianProfileServices/group';

const PER_PAGE = 50;
const DATE_RANGE_LIMIT = 30;

export const STATUS_OPTIONS = [
  { name: 'Draft', _id: StatusFilterType.Draft },
  { name: 'Issued', _id: StatusFilterType.Issued },
  { name: 'Overdue', _id: StatusFilterType.Overdue },
  { name: 'Marked Paid', _id: StatusFilterType.MarkedPaid },
  { name: 'Confirm Paid', _id: StatusFilterType.ConfirmPaid },
  { name: 'Fully Refunded', _id: StatusFilterType.FullyRefunded },
  { name: 'Closed', _id: StatusFilterType.Closed },
  { name: 'Voided', _id: StatusFilterType.Voided },
  { name: 'Write Off', _id: StatusFilterType.WriteOff },
  { name: 'Pending Claim', _id: StatusFilterType.PendingClaim },
  { name: 'Claim Complete', _id: StatusFilterType.ClaimComplete },
  { name: 'Claim Success', _id: StatusFilterType.ClaimSuccess },
  { name: 'Claim Rejected', _id: StatusFilterType.ClaimRejected }
];

export enum InvoicePaymentStatus {
  OwedMoreThanZero = 'owedMoreThanZero',
  ZeroOwed = 'zeroOwed',
  PaidMoreThanZero = 'paidMoreThanZero',
  ZeroPaid = 'zeroPaid',
  FailedPayments = 'failedPayments',
  CreditNote = 'creditNote',
  WriteOff = 'writeOff'
}

const getPaymentStatusOptions = (currencySymbol: string) => [
  {
    name: `Owed is > ${currencySymbol}0`,
    _id: InvoicePaymentStatus.OwedMoreThanZero
  },
  {
    name: `Owed is = ${currencySymbol}0`,
    _id: InvoicePaymentStatus.ZeroOwed
  },
  {
    name: `Paid is > ${currencySymbol}0`,
    _id: InvoicePaymentStatus.PaidMoreThanZero
  },
  {
    name: `Paid is = ${currencySymbol}0`,
    _id: InvoicePaymentStatus.ZeroPaid
  },
  {
    name: 'Payment failed',
    _id: InvoicePaymentStatus.FailedPayments
  },
  {
    name: 'Payment credited',
    _id: InvoicePaymentStatus.CreditNote
  },
  {
    name: 'Payment write off',
    _id: InvoicePaymentStatus.WriteOff
  }
];

interface InvoiceListFilterProps {
  showInvoiceType?: boolean;
  skipFetchInvoices?: boolean;
}

type RangeValue = [Moment | null, Moment | null] | null;
const { RangePicker } = DatePicker;

const InvoiceListFilter = ({ showInvoiceType, skipFetchInvoices }: InvoiceListFilterProps) => {
  const dispatch = useAppDispatch();
  const { CURRENCY_SYMBOL } = useCurrency();
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeUserView } = useGetAccountPackageView();
  const { isGroupsFeatureToggle } = useGetFeatureToggle();

  const filters = useAppSelector(selectFilters);
  const sorting = useAppSelector(selectSorting);
  const activeTab = useAppSelector(selectActiveTab);
  const search = useAppSelector(selectSearch);

  const { searchValue } = search;
  const { clients, invoiceTypes, statuses, paymentStatuses, practitioners, createdAt, participantType, groups } =
    filters;

  const [clientListCurrentPage, setClientListCurrentPage] = useState<number>(1);
  const [clientSearchText, setClientSearchText] = useState<string>('');

  const [practitionerCurrentPage, setPractitionerCurrentPage] = useState<number>(1);
  const [practitionerSearchText, setPractitionerSearchText] = useState<string>('');

  const [dates, setDates] = useState<RangeValue>(null);

  const { isInvoicesLoading, invoiceCounts, totalItems: matchedCount } = useGetInvoicesList(skipFetchInvoices);

  const {
    data: clientListData,
    isLoading: isClientListDataLoading,
    isFetching: isClientListDataFetching
  } = useGetMinifiedClientRecordQuery(
    {
      accountId,
      params: {
        page: clientListCurrentPage,
        perPage: PER_PAGE,
        recordStatus: 'active,waitlist',
        ...(clientSearchText && {
          searchValue: clientSearchText,
          searchBy: SearchFilterEnum.FirstOrLastName
        })
      }
    },
    {}
  );

  const {
    data: groupListData,
    isLoading: isGroupListDataLoading,
    isFetching: isGroupListDataFetching
  } = useGetMinifiedGroupListQuery({ accountId, isEdgeUserView }, { skip: participantType !== ParticipantType.Group });

  const clientListFilter: FilterCheckListItem[] = clientListData
    ? clientListData.clientRecords.map((item) => ({
        name: `${item.clientProfiles[0].firstName} ${item.clientProfiles[0].lastName}`,
        _id: item._id
      }))
    : [];

  const {
    data: practitionerData,
    isLoading: isPractitionerDataLoading,
    isFetching: isPractitionerDataFetching
  } = useGetPractitionerListQuery({
    accountId,
    params: { page: practitionerCurrentPage, perPage: PER_PAGE, status: 'active', searchValue: practitionerSearchText }
  });

  const practitionerList: FilterCheckListItem[] = practitionerData
    ? practitionerData.clinicians.map((item) => ({ name: item.name, _id: item._id }))
    : [];

  const groupListFilter: FilterCheckListItem[] = groupListData ? groupListData.groups : [];

  const onChangeClientFilter = (clients: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, clients }));
  };

  const onChangeGroupFilter = (groups: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, groups }));
  };

  const onChangePractitionerFilter = (practitioners: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, practitioners }));
  };

  const onChangeInvoiceTypesFilter = (invoiceTypes: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, invoiceTypes }));
  };

  const onChangeStatusesFilter = (statuses: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, statuses }));
  };

  const onChangePaymentStatusesFilter = (paymentStatuses: FilterCheckListItem[]) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, paymentStatuses }));
  };

  const onChangeSubmittedDateFilter = (dateString: [string, string] | string) => {
    const isClear = !dateString[0] && !dateString[1];
    dispatch(resetPaging());
    dispatch(
      setFilters({
        ...filters,
        ...(filters.createdAt && {
          createdAt: {
            ...(dateString[0] && {
              from: dateString[0]
            }),
            ...(dateString[1] && {
              to: dateString[1]
            })
          }
        }),
        ...(isClear && {
          paymentStatuses: []
        })
      })
    );
    if (isClear && ['paid', 'owed'].includes(sorting.sortBy)) {
      dispatch(setSorting(DEFAULT_SORTING));
    }
  };

  const disabledDate = useCallback(
    (current: Moment) => {
      const tomorrowDate = moment().add(1, 'day').startOf('day');
      let tooLate = false;
      let tooEarly = false;

      if (dates) {
        tooLate = !!(dates[0] && current.diff(dates[0], 'days') > DATE_RANGE_LIMIT);
        tooEarly = !!(dates[1] && dates[1].diff(current, 'days') > DATE_RANGE_LIMIT);
      }

      return current.startOf('day') >= tomorrowDate || tooEarly || tooLate;
    },
    [dates]
  );

  const onChangeParticipantType = (participantType: ParticipantType) => {
    dispatch(
      setFilters({
        ...filters,
        participantType
      })
    );
  };

  const onSearchHandle = (val: string) => {
    dispatch(resetPaging());
    dispatch(setSearch({ ...search, searchValue: val }));
  };

  const onClearFilter = () => {
    dispatch(resetPaging());
    dispatch(resetSearch());
    dispatch(resetFilters(filters));
    setDates(null);
  };

  const debounceSetClientSearchText = useMemo(
    () =>
      debounce((value) => {
        setClientSearchText(value);
        setClientListCurrentPage(1);
      }, 1000),
    []
  );

  const debouncedSetPractitionersSearchText = useMemo(
    () =>
      debounce((value) => {
        setPractitionerSearchText(value);
        setPractitionerCurrentPage(1);
      }, 1000),
    []
  );

  const showClearFilter = useMemo(
    () =>
      search.searchValue ||
      (filters.participantType === ParticipantType.OneToOne &&
        activeTab !== InvoiceTab.ClientProfile &&
        filters.clients.length > 0) ||
      (filters.participantType === ParticipantType.Group && filters.groups.length > 0) ||
      filters.invoiceTypes.length > 0 ||
      filters.statuses.length > 0 ||
      filters.paymentStatuses.length > 0 ||
      filters.practitioners.length > 0 ||
      (activeTab !== InvoiceTab.Recent && (filters.createdAt.from || filters.createdAt.to)),
    [filters, activeTab, search.searchValue]
  );

  const PaymentStatusOptions = useMemo(() => getPaymentStatusOptions(CURRENCY_SYMBOL), [CURRENCY_SYMBOL]);

  const totalClientListPage = clientListData?.paging ? Math.ceil(clientListData.paging.totalItems / PER_PAGE) : 1;
  const totalPractitionerPage = practitionerData?.paging ? Math.ceil(practitionerData.paging.totalItems / PER_PAGE) : 1;
  const isFilteredByDateRange = !!(createdAt.from && createdAt.to);

  return (
    <div className={styles.container}>
      {/* Filter buttons */}

      <div className={styles.rowContainer}>
        <div className={styles.filterInformation}>
          <div className={styles.numberOfMatchedInvoices}>
            {!isInvoicesLoading ? (
              <div>
                {showClearFilter ? 'FILTER matched' : 'Showing'}{' '}
                <span className={styles.highLight}>
                  {matchedCount}
                  {showClearFilter
                    ? ` / ${activeTab === InvoiceTab.Recent ? invoiceCounts.recent : invoiceCounts.total}`
                    : ''}
                </span>{' '}
                invoices
              </div>
            ) : (
              <div className={styles.loadingInvoice}>
                <Skeleton.Input className={styles.loading} active />
              </div>
            )}
          </div>
          {showClearFilter && (
            <ButtonAlt error size={'small'} variant={'text'} className={styles.clearFilters} onClick={onClearFilter}>
              Clear filters
            </ButtonAlt>
          )}
        </div>
        {/* Participant Type */}
        {isGroupsFeatureToggle && activeTab !== InvoiceTab.ClientProfile && (
          <ParticipationToggle
            selectedParticipantType={participantType}
            onChangeParticipation={(val) => {
              onChangeParticipantType(val);
            }}
          />
        )}
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.filterDropdownsContainer}>
          {/* Date Range */}
          {activeTab !== InvoiceTab.Recent && (
            <div
              className={classNames(
                customStyles.dateRangeContainer,
                isEdgeAdminView ? customStyles.submittedDateContainerAdmin : customStyles.submittedDateContainer
              )}
            >
              <RangePicker
                placeholder={['From', 'To']}
                value={[
                  createdAt.from ? moment(createdAt.from, 'YYYY-MM-DD') : null,
                  createdAt.to ? moment(createdAt.to, 'YYYY-MM-DD') : null
                ]}
                dropdownClassName={classNames(
                  customStyles.dateRangeDropdown,
                  isEdgeAdminView ? customStyles.dropdownClassNameAdmin : customStyles.dropdownClassName
                )}
                onChange={(
                  _value: DatePickerProps['value'] | RangePickerProps['value'],
                  dateString: [string, string] | string
                ) => {
                  onChangeSubmittedDateFilter(dateString);
                }}
                className={createdAt.from || createdAt.to ? 'hasValue' : ''}
                disabledDate={disabledDate}
                onCalendarChange={setDates}
              />
            </div>
          )}

          {/* Client | Group filter*/}
          {activeTab !== InvoiceTab.ClientProfile && (
            <>
              {participantType === ParticipantType.OneToOne ? (
                <FilterDropdown
                  id="clients"
                  icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
                  menuItems={clientListFilter}
                  onChangeItem={onToggleFilter(clientListFilter, clients, onChangeClientFilter)}
                  searchable
                  selectedFilterList={clients}
                  showSearchIcon
                  loading={isClientListDataLoading}
                  hasMoreData={totalClientListPage > clientListCurrentPage || isClientListDataFetching}
                  isFetchingMore={isClientListDataFetching}
                  loadMore={() => {
                    if (!isClientListDataFetching && clientListCurrentPage <= totalClientListPage) {
                      setClientListCurrentPage(clientListCurrentPage + 1);
                    }
                  }}
                  enableSelectedBackground
                  onClearFilter={() => {
                    dispatch(resetPaging());
                    dispatch(setFilters({ ...filters, clients: [] }));
                  }}
                  setSearchText={debounceSetClientSearchText}
                >
                  Client
                </FilterDropdown>
              ) : (
                <FilterDropdown
                  id="groups"
                  searchable
                  icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
                  menuItems={groupListFilter}
                  onChangeItem={onToggleFilter(groupListFilter, groups, onChangeGroupFilter)}
                  selectedFilterList={groups}
                  loading={isGroupListDataLoading || isGroupListDataFetching}
                  enableSelectedBackground
                  onClearFilter={() => {
                    dispatch(resetPaging());
                    dispatch(setFilters({ ...filters, groups: [] }));
                  }}
                >
                  Group
                </FilterDropdown>
              )}
            </>
          )}

          {/* Practitioner */}
          {isEdgeAdminView && (
            <FilterDropdown
              id={'practitioner'}
              icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
              menuItems={practitionerList}
              onChangeItem={onToggleFilter(practitionerList, practitioners, onChangePractitionerFilter)}
              searchable
              selectedFilterList={practitioners}
              showSearchIcon
              showToggleAllButtons
              loading={isPractitionerDataLoading}
              hasMoreData={totalPractitionerPage > practitionerCurrentPage || isPractitionerDataFetching}
              isFetchingMore={isPractitionerDataFetching}
              loadMore={() => {
                if (!isPractitionerDataFetching && practitionerCurrentPage <= totalPractitionerPage) {
                  setPractitionerCurrentPage(practitionerCurrentPage + 1);
                }
              }}
              onClearFilter={() => {
                dispatch(resetPaging());
                dispatch(setFilters({ ...filters, practitioners: [] }));
              }}
              enableSelectedBackground
              setSearchText={debouncedSetPractitionersSearchText}
            >
              Practitioner
            </FilterDropdown>
          )}

          {/* Invoice Type */}
          {showInvoiceType && (
            <FilterDropdown
              id="invoiceTypes"
              menuItems={CLAIM_TYPE_OPTIONS}
              onChangeItem={onToggleFilter(CLAIM_TYPE_OPTIONS, invoiceTypes, onChangeInvoiceTypesFilter)}
              selectedFilterList={invoiceTypes}
              enableSelectedBackground
              onClearFilter={() => {
                dispatch(resetPaging());
                dispatch(setFilters({ ...filters, invoiceTypes: [] }));
              }}
            >
              Payer
            </FilterDropdown>
          )}

          {/* Status */}
          <FilterDropdown
            id="statuses"
            menuItems={STATUS_OPTIONS}
            onChangeItem={onToggleFilter(STATUS_OPTIONS, statuses, onChangeStatusesFilter)}
            selectedFilterList={statuses}
            enableSelectedBackground
            onClearFilter={() => {
              dispatch(resetPaging());
              dispatch(setFilters({ ...filters, statuses: [] }));
            }}
          >
            Status
          </FilterDropdown>

          {/* Payment Status */}
          <FilterDropdown
            id="paymentStatus"
            menuItems={PaymentStatusOptions}
            onChangeItem={onToggleFilter(PaymentStatusOptions, paymentStatuses, onChangePaymentStatusesFilter)}
            selectedFilterList={paymentStatuses}
            enableSelectedBackground
            isInactive={!isFilteredByDateRange}
            tooltipContent="Please select a date range before filtering by paid / owed amount."
            onClearFilter={() => {
              dispatch(resetPaging());
              dispatch(setFilters({ ...filters, paymentStatuses: [] }));
            }}
          >
            {CURRENCY_SYMBOL} Paid / Owed
          </FilterDropdown>
        </div>
        <SearchBar
          placeholder={'Search by Invoice ID'}
          value={searchValue}
          withSearchButton
          onSearch={onSearchHandle}
          containerClassName={styles.searchContainer}
        />
      </div>
    </div>
  );
};

export default InvoiceListFilter;
