import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import { ReferralSubject } from 'interfaces/Referral/Referral';
import { convertDateFormat, MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { ReferralFieldsPayload, ReferralValidateField } from '../../../AddReferralModal/AddReferralModalInterfaces';
import styles from './ReferralSubjectForm.module.scss';

interface ReferralSubjectFormProps {
  subjectFields: ReferralFieldsPayload['subject'];
  errorMessage: ReferralValidateField['subject'];
  onChange: (key: string, val: string) => void;
}

export interface AddReferralSubjectPayload extends ReferralSubject {}

const ReferralSubjectForm = ({
  subjectFields = { firstName: '', lastName: '' },
  errorMessage: subjectErrorMessage,
  onChange: handleChangeFieldValue
}: ReferralSubjectFormProps) => {
  const { medicareRebateFeatureToggle, isRegionUK, isRegionAU } = useGetFeatureToggle();

  const handleKeyDownDateChange = (event: React.KeyboardEvent<HTMLInputElement>, dateValue: string) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      if (dateValue) {
        const newValue = dateValue.slice(0, dateValue.length - 1);
        handleChangeFieldValue('dateOfBirth', newValue);
      }
    }
  };

  const handleDateChange = (dateValue: string) => {
    const newValue = convertDateFormat(dateValue);
    handleChangeFieldValue('dateOfBirth', newValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.rowContainer}>
        <div className={styles.fieldContainer}>
          <MaterialInput
            id="subject-firstName"
            label="First name"
            maxLength={20}
            onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
            value={subjectFields.firstName}
            error={!!subjectErrorMessage.firstName}
            errorMessage={subjectErrorMessage.firstName}
            required
          />
        </div>
      </div>

      <div className={styles.rowContainer}>
        <div className={styles.fieldContainer}>
          <MaterialInput
            id="subject-lastName"
            label="Last name"
            maxLength={20}
            onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
            value={subjectFields.lastName}
            error={!!subjectErrorMessage.lastName}
            errorMessage={subjectErrorMessage.lastName}
            required
          />
        </div>
      </div>

      <div className={styles.rowContainer}>
        <div className={classNames(styles.fieldContainer, styles.dateContainer)}>
          <div className={styles.dateInputLabel}>DOB</div>
          <MaterialInput
            id="subject-dob"
            placeholder={MOMENTJS_DATE_FORMAT}
            label={''}
            onKeyDown={(e) => handleKeyDownDateChange(e, subjectFields.dateOfBirth || '')}
            onChange={(e) => handleDateChange(e.target.value)}
            value={subjectFields.dateOfBirth}
            type={'tel'}
            pattern={'[0-9\\/]*'}
            maxLength={10}
            autoComplete={'off'}
            errorMessage={subjectErrorMessage.dateOfBirth}
          />
        </div>
      </div>

      {((isRegionAU && medicareRebateFeatureToggle) || isRegionUK) && (
        <div className={styles.rowContainer}>
          <div className={styles.fieldContainer}>
            <MaterialInput
              id="subject-providerNumber"
              label={isRegionUK ? 'NHS number' : 'Medicare number'}
              maxLength={30}
              onChange={(e) => handleChangeFieldValue('providerNumber', e.target.value)}
              value={subjectFields.providerNumber}
            />
          </div>
        </div>
      )}

      <div className={styles.rowContainer}>
        <div className={styles.fieldContainer}>
          <MaterialInput
            id="subject-email"
            label="Subject contact email"
            maxLength={200}
            onChange={(e) => handleChangeFieldValue('email', e.target.value)}
            value={subjectFields.email}
            required
            icon="email"
          />
        </div>
      </div>

      <div className={styles.rowContainer}>
        <div className={styles.fieldContainer}>
          <MaterialPhoneInput
            id="subject-mobile"
            autoFormat={false}
            disableCountryCode
            hideFlag
            inputName="mobile"
            label="Subject contact phone"
            onChange={(value = '') => {
              handleChangeFieldValue('mobile', value);
            }}
            placeholder=""
            isError={false}
            value={subjectFields.mobile || ''}
            inputClass={styles.transparentInput}
            icon="phone"
          />
        </div>
      </div>
    </div>
  );
};

export default ReferralSubjectForm;
