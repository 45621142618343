import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { PractitionersListing } from 'interfaces/Practitioners/practitionersListing';
import { getPractitionerListing } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useTranslation } from 'react-i18next';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useFetchProfile } from 'utils/hooks/GetProfileDetails/getProfileInfo';
import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { ClaimFormData } from '../initValue';

export const useFetchPractitionerOptions = (
  token: string,
  setClaimForm: (claimForm: SetStateAction<ClaimFormData>) => void
) => {
  const { accountId } = useGetAccountId();
  const { profile, isProfileLoading } = useFetchProfile();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [t] = useTranslation();

  const [practitionersOptions, setPractitionersOptions] = useState<
    { label: string; value: string; role: ClinicianMedicareRole }[]
  >([]);
  const [isPractitionersOptionsLoading, setIsPractitionersOptionsLoading] = useState(true);

  const fetchPractitionersList = useCallback(
    async (token: string) => {
      try {
        if (isEdgeAdminView || isEdgeReceptionist) {
          const practitionersData = await getPractitionerListing(token, accountId);
          const practitionerListData = (await practitionersData.json()) as PractitionersListing;

          setPractitionersOptions(
            practitionerListData.practitionerList
              .filter(
                ({ accessRight, medicare, status }) =>
                  status === 'active' && medicare?.role && accessRight !== 'receptionist' && accessRight !== 'mentor'
              )
              .map(({ _id, name, medicare }) => ({ label: name, value: _id, role: medicare!.role }))
          );
        } else {
          setClaimForm((claimForm) => ({ ...claimForm, clinicianId: profile._id }));
          setPractitionersOptions([
            {
              label: profile.name,
              value: profile._id,
              role: profile.medicare?.role || ClinicianMedicareRole.ClinicalPsychologists
            }
          ]);
        }
      } catch (ex) {
        notification.error({ message: t('form.error.failed_to_retrieve_practitioner_record') });
      }

      setIsPractitionersOptionsLoading(false);
    },
    [accountId, profile, t, isEdgeAdminView, isEdgeReceptionist, setClaimForm]
  );

  useEffect(() => {
    if (!isProfileLoading && token) {
      fetchPractitionersList(token);
    }
  }, [token, fetchPractitionersList, isProfileLoading]);

  return { practitionersOptions, isPractitionersOptionsLoading, fetchPractitionersList, setPractitionersOptions };
};
