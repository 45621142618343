import { notification } from 'antd';
import { Appointment } from 'pages/InvoiceGenerator/interface';
import { useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { getRecentAppointmentsByClientRecordId } from 'utils/http/ScheduleService/Appointments/Appointments';
import { security } from 'utils/security';

const useGetRecentAppointments = (clientRecordId: string, limit?: number) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const [isLoading, setIsLoading] = useState(true);
  const [recentAppointmentLists, setRecentAppointmentLists] = useState<Appointment[]>([]);

  const getRecentAppointments = async () => {
    setIsLoading(true);
    try {
      const token = await security.getAccessTokenSilently();
      const data = (await (
        await getRecentAppointmentsByClientRecordId({ token, clientRecordId, asAdmin: isEdgeAdminView, limit })
      ).json()) as Appointment[];
      setRecentAppointmentLists(data);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: "There's an error when trying to get client's recent appointments",
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getRecentAppointments();
    // eslint-disable-next-line
  }, [clientRecordId]);

  return {
    isLoading,
    recentAppointmentLists
  };
};

export default useGetRecentAppointments;
