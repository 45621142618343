import { useState } from 'react';
import { notification } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { MedicareDetails } from 'interfaces/Clients/clientsRecord';

import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { postRecheckClientProfileMedicareEligibility } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import VerificationStatus from './components/VerificationStatus/VerificationStatus';

import styles from './MedicareCheck.module.scss';
import { useAppDispatch } from 'redux/hooks';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import { security } from 'utils/security';

interface MedicareCheckProps {
  clientRecordId: string;
  clientProfileId: string;
  status: MedicareDetails['status'];
  parentStatus?: MedicareDetails['status'];
  updatedAt?: string;
  isGeneralPractitionerLoading: boolean;
  isGPReferralProviderNumberSet: boolean;
  isReferralDateSet: boolean;
  fetchGeneralPractitioner: () => Promise<void>;
}

const MedicareCheck = ({
  clientRecordId,
  clientProfileId,
  status,
  parentStatus,
  updatedAt,
  isGeneralPractitionerLoading,
  isGPReferralProviderNumberSet,
  isReferralDateSet,
  fetchGeneralPractitioner
}: MedicareCheckProps) => {
  const { CLIENTS } = useRoutesGenerator();
  const dispatch = useAppDispatch();

  const [recheckButtonStatus, setRecheckButtonStatus] = useState<ButtonStatusType>('');

  const isLoading = recheckButtonStatus === 'active';

  const handleRecheckEligibility = async () => {
    setRecheckButtonStatus('active');

    try {
      const token = await security.getAccessTokenSilently();

      await postRecheckClientProfileMedicareEligibility(token, clientRecordId, clientProfileId);
      dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.ClientEncrypted]));
      await fetchGeneralPractitioner();

      setRecheckButtonStatus('finished');

      setTimeout(() => {
        setRecheckButtonStatus('');
      }, 2000);
    } catch (ex) {
      console.error(ex);
      setRecheckButtonStatus('');
      notification.error({
        message: "Something went wrong while checking your client's Medicare eligibility"
      });
    }
  };

  return (
    <>
      <div className={styles.title}>MEDICARE CHECK</div>
      <div className={styles.medicareVerified}>
        <VerificationStatus status={status} isLoading={isLoading} medicareOwner={parentStatus ? 'child' : undefined} />
        {parentStatus && <VerificationStatus status={parentStatus} medicareOwner={'parent'} isLoading={isLoading} />}
        <div className={styles.item}>
          {isGeneralPractitionerLoading || isLoading ? (
            <>
              <div className={styles.loading}>
                <LoadingCircle />
              </div>
              Checking referral details...
            </>
          ) : (
            <>
              {isGPReferralProviderNumberSet && isReferralDateSet ? (
                <>
                  <i className={`material-icons ${styles.icon}`}>check_circle</i>
                  <span>
                    <Link
                      className={styles.referralLink}
                      to={`${CLIENTS.BASE}/${clientRecordId}/referrers`}
                      target="_blank"
                    >
                      Referral details
                    </Link>{' '}
                    set
                  </span>
                </>
              ) : (
                <>
                  <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
                  <span>
                    Missing{' '}
                    <Link
                      className={styles.referralLink}
                      to={`${CLIENTS.BASE}/${clientRecordId}/referrers`}
                      target="_blank"
                    >
                      {!isGPReferralProviderNumberSet && !isReferralDateSet
                        ? "referral date and referring GP's provider number"
                        : !isGPReferralProviderNumberSet
                        ? "referring GP's provider number"
                        : 'referral date'}
                    </Link>
                  </span>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.recheckEligibility}>
        <Button
          className={styles.recheckButton}
          activeClassName={styles.active}
          variant="secondary"
          status={recheckButtonStatus}
          onClick={handleRecheckEligibility}
        >
          <i className={`material-icons ${styles.icon}`}>published_with_changes</i>
          Re-check eligibility
        </Button>
        <div className={styles.lastChecked}>Last checked {moment(updatedAt).format('HH:mm DD/MM/YYYY')}</div>
      </div>
    </>
  );
};

export default MedicareCheck;
