import { Skeleton } from 'antd';
import Folder from '../Folder/Folder';
import styles from './FolderList.module.scss';

export interface FolderMetadata {
  _id: string;
  clinicianName?: string;
  folderName: string;
  isPrivate: boolean;
  isSelected?: boolean;
  noteCount: number;
  sortOrder: number;
}

interface FolderListProps {
  disabled?: boolean;
  folders: FolderMetadata[];
  loading?: boolean;
  onClick?: (folderId: string) => void;
}

const FolderList = ({ disabled, folders, loading, onClick }: FolderListProps) => {
  return (
    <div className={styles.container}>
      {folders.map(({ _id, folderName, isPrivate, noteCount, isSelected: selected }) =>
        loading ? (
          <Skeleton.Button key={_id} active className={styles.loadingSkeleton} />
        ) : (
          <Folder
            disabled={disabled}
            folderName={folderName}
            isPrivate={isPrivate}
            key={_id}
            noteCount={noteCount}
            onClick={() => onClick?.(_id)}
            selected={selected}
          />
        )
      )}
    </div>
  );
};

export default FolderList;
