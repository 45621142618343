import { useEffect, useState } from 'react';
import styles from './PackageAssignment.module.scss';
import classnames from 'classnames';
import columnStyles from './PackageAssignmentColumn.module.scss';
import moment from 'moment';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import PackageAssignmentModal from './components/PackageAssignmentModal/PackageAssignmentModal';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import NO_RESULT_IMG from 'assets/images/noResultFunder.png';
import PackageUtilisationActionMenu from './components/PackageUtilisationActionMenu/PackageUtilisationActionMenu';
import PackageAssignmentActionMenu from './components/PackageAssignmentActionMenu/PackageAssignmentActionMenu';
import { useGetAssignmentPackageQuery } from 'redux/endpoints/scheduleServices/package';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import FunderBadge from 'pages/Packages/components/FunderBadge/FunderBadge';
import { Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { selectAssignmentLoading, setAssignmentUILoading } from 'redux/packages/packageAssigmentListSlice';
import { MOMENTJS_DATE_FORMAT, MOMENTJS_SHORT_YEAR_FORMAT } from 'utils/dateChecker';
import EpisodeStatusBadge from '../../../ClientDetailsEoC/components/EpisodeStatusBadge/EpisodeStatusBadge';
import { PackagesBenefit, PackagesBenefitLimit } from 'interfaces/Packages/packages';
import { Tooltip } from 'react-tooltip';

interface PackageAssignmentProps {
  clientRecordId: string;
}

const PackageAssignment = ({ clientRecordId }: PackageAssignmentProps) => {
  const { accountId } = useGetAccountId();
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const [isShowPackageModal, setIsShowPackageModal] = useState<boolean>(false);

  const {
    data: assignmentPackageList,
    isLoading: isAssignmentPackageListLoading,
    isFetching: isAssignmentPackageListFetching
  } = useGetAssignmentPackageQuery(
    {
      accountId: accountId,
      clientRecordId: clientRecordId,
      params: {
        sortByCreatedAt: '-1'
      }
    },
    { skip: !clientRecordId }
  );

  const dispatch = useDispatch();
  const assignmentLoading = useAppSelector(selectAssignmentLoading);

  // Effect to handle the stop loading action
  useEffect(() => {
    if (assignmentLoading && !isAssignmentPackageListLoading && !isAssignmentPackageListFetching) {
      dispatch(setAssignmentUILoading(false));
    }
  }, [assignmentLoading, isAssignmentPackageListLoading, isAssignmentPackageListFetching, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.title}>Packages</div>
        {(isEdgeAdminView || isEdgeReceptionistView) && (
          <div>
            <ButtonAlt
              variant={'outlined'}
              size={'medium'}
              onClick={() => {
                setIsShowPackageModal(true);
              }}
              disabled={false}
              icon={'add'}
            >
              Attach new package
            </ButtonAlt>
          </div>
        )}
      </div>
      {!assignmentLoading && (isAssignmentPackageListLoading || isAssignmentPackageListFetching) ? (
        <div className={styles.content}>
          <div className={styles.list}>
            {[...Array(5)].map((_, i) => (
              <div key={i} className={classnames(columnStyles.columnWrapper, styles.listItem)}>
                <div className={columnStyles.name}>
                  <Skeleton.Input active className={styles.nameLoading} />
                </div>
                <div className={columnStyles.tag}>
                  <Skeleton.Input active className={styles.funderLoading} />
                </div>
                <div className={columnStyles.joinedDate}>
                  <Skeleton.Input active className={styles.joinedDateLoading} />
                </div>
                <div className={columnStyles.utilisation}>
                  <Skeleton.Input active className={styles.utilisationLoading} />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.content}>
          {assignmentPackageList && assignmentPackageList.length > 0 && (
            <div className={classnames(columnStyles.columnWrapper, styles.header)}>
              <div className={columnStyles.name}>Package Name</div>
              <div className={columnStyles.tag}>Funder</div>
              <div className={columnStyles.joinedDate}>Date added</div>
              <div className={columnStyles.utilisation}>Utilisation</div>
              <div className={columnStyles.episode}>Episode link</div>
              <div className={columnStyles.action} />
            </div>
          )}
          <div className={styles.list}>
            {assignmentPackageList && assignmentPackageList.length > 0 ? (
              assignmentPackageList.map((assignmentObj, index) => {
                const startDate = moment(assignmentObj.episode?.startDate).format(MOMENTJS_SHORT_YEAR_FORMAT);
                const episodeEndDate = assignmentObj.episode?.endDate
                  ? moment(assignmentObj.episode?.endDate).format(MOMENTJS_DATE_FORMAT)
                  : 'Ongoing';
                return (
                  <div key={index} className={classnames(columnStyles.columnWrapper, styles.listItem)}>
                    <div className={classnames(columnStyles.name, styles.name)}>{assignmentObj.name || '-'}</div>
                    <div className={classnames(columnStyles.tag, styles.tag)}>
                      <FunderBadge funder={assignmentObj.assignee.funder.name} />
                    </div>
                    <div className={classnames(columnStyles.joinedDate, styles.joinedDate)}>
                      <div className={styles.date}>
                        {moment(assignmentObj.assignee.createdAt).format('DD MMM YYYY')}
                      </div>
                      <div className={styles.joinedSince}>{`(${moment().diff(
                        moment(assignmentObj.assignee.createdAt),
                        'days'
                      )} days)`}</div>
                    </div>
                    <div className={classnames(columnStyles.utilisation, styles.utilisation)}>
                      <div className={styles.utilisationInfo}>
                        {assignmentObj.appointmentTypes.filter((utilObj) => utilObj.isUtilised).length}/
                        {assignmentObj.benefitControl.limitMode !== PackagesBenefitLimit.None ? (
                          assignmentObj.benefitControl.by === PackagesBenefit.Client ? (
                            assignmentObj.benefitControl.limit
                          ) : (
                            assignmentObj.appointmentTypes.length
                          )
                        ) : (
                          <>
                            <i
                              data-tooltip-id={`infinity-${assignmentObj._id}`}
                              className={`material-symbols-outlined ${styles.infinityIcon}`}
                            >
                              all_inclusive
                            </i>
                            <Tooltip id={`infinity-${assignmentObj._id}`} className={styles.tooltip}>
                              No limit on benefits
                            </Tooltip>
                          </>
                        )}
                      </div>
                      <div className={styles.editBtnWrapper}>
                        <PackageUtilisationActionMenu
                          clientRecordId={clientRecordId}
                          packageId={assignmentObj._id || ''}
                          assigneeId={assignmentObj.assignee._id}
                          appointmentList={assignmentObj.appointmentTypes}
                        />
                      </div>
                    </div>
                    <div className={columnStyles.episode}>
                      {assignmentObj.episode && (
                        <div className={styles.episodeWrapper}>
                          <div className={styles.episodeInfo}>
                            <div>Episode (#{assignmentObj.episode.order})</div>
                            <div>
                              {startDate} - {episodeEndDate}
                            </div>
                          </div>
                          <div>
                            <EpisodeStatusBadge className={styles.episodeBadge} status={assignmentObj.episode.status} />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={columnStyles.action}>
                      {(isEdgeAdminView || isEdgeReceptionistView) && (
                        <PackageAssignmentActionMenu
                          clientRecordId={clientRecordId}
                          packageId={assignmentObj._id || ''}
                          assigneeId={assignmentObj.assignee._id}
                        />
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={styles.noResultWrapper}>
                <img src={NO_RESULT_IMG} alt={'no result'} className={styles.noResultImg} />
                <div className={styles.grayOutLabel}>No package assigned</div>
              </div>
            )}
          </div>
        </div>
      )}
      <PackageAssignmentModal
        key={`${clientRecordId}-assignedPackageModal`}
        isVisible={isShowPackageModal}
        clientRecordId={clientRecordId}
        onClose={() => setIsShowPackageModal(false)}
      />
    </div>
  );
};

export default PackageAssignment;
