import ReadMore from 'components/ReadMore/ReadMore';
import { QuickNote } from '../../interfaces';

import styles from './QuickNoteDetail.module.scss';

interface QuickNoteDetailProps {
  quickNote: QuickNote;
}

const QuickNoteDetail = ({ quickNote }: QuickNoteDetailProps) => {
  return (
    <div className={styles.container}>
      <ReadMore bodyClass={styles.bodyClass} text={quickNote.body} maxHeight={140} />
    </div>
  );
};

export default QuickNoteDetail;
