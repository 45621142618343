import { Modal, notification } from 'antd';
import classnames from 'classnames';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import MaterialDropdown from 'components/MaterialFieldComponent/MaterialDropdown/MaterialDropdown';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { ButtonStatusType } from 'components/v2/Button/Button';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { Location, LocationType } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useState } from 'react';
import { validateMedicareProviderNumber } from 'utils/helpers/validateMedicareProviderNumber';
import { useGetAccessToken } from 'utils/hooks/token';
import { updateLocation } from 'utils/http/BillingService/Invoice/claimingcom';

import styles from './EditLocationModal.module.scss';

const locationTypeOptions = [
  { label: 'Community Health Centre', value: LocationType.CommunityHealthCentre },
  { label: 'Home Visit', value: LocationType.HomeVisit },
  { label: 'Hospital', value: LocationType.Hospital },
  { label: 'Residential Care Facility', value: LocationType.ResidentialCareFacility },
  { label: 'Rooms', value: LocationType.Rooms }
];

interface EditLocationModalProps {
  visible: boolean;
  location: Location;
  setShowEditLocationModal: (bool: boolean) => void;
  refetchMedicareLocations: () => void;
}

const EditLocationModal = ({
  visible,
  location,
  setShowEditLocationModal,
  refetchMedicareLocations
}: EditLocationModalProps) => {
  const { token } = useGetAccessToken();
  const [locationName, setLocationName] = useState(location.name);
  const [address, setAddress] = useState(location.address ?? '');
  const [type, setType] = useState(location.type || undefined);
  const [providerNumber, setProviderNumber] = useState(location.providerNumber || '');
  const [locationNameValidationError, setLocationNameValidationError] = useState(false);
  const [providerNumberValidationError, setProviderNumberValidationError] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<ButtonStatusType>('');
  const providerNumberError = validateMedicareProviderNumber(providerNumber);

  const handleCloseModal = () => {
    setShowEditLocationModal(false);
  };

  const onChangeLocationName = (value: string) => {
    if (locationNameValidationError && value.length > 2 && value.length < 501) {
      setLocationNameValidationError(false);
    }
    setLocationName(value);
  };

  const handleChangeProviderNumber = (value: string) => {
    if (providerNumberValidationError) {
      setProviderNumberValidationError(false);
    }
    setProviderNumber(value.toUpperCase());
  };

  const onSubmit = async () => {
    setSubmitStatus('active');

    const locationNameError = locationName === '' || locationName.length < 3 || locationName.length > 500;
    const providerNumberRequiredError = type === LocationType.Hospital && !!providerNumberError;
    if (locationNameError || providerNumberRequiredError) {
      setLocationNameValidationError(locationNameError);
      setProviderNumberValidationError(providerNumberRequiredError);
      setSubmitStatus('');
      return;
    }

    try {
      await updateLocation(token, location.minorId, {
        name: locationName,
        address,
        type,
        ...(type === LocationType.Hospital && { providerNumber })
      });

      notification.success({
        message: 'Location updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      refetchMedicareLocations();
      handleCloseModal();
      setSubmitStatus('finished');
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to edit location' });
    }
    setSubmitStatus('');
  };

  return (
    <Modal
      className={styles.modalContainer}
      open={visible}
      closable
      title={<div className={styles.title}>Edit Location Details</div>}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <div className={styles.halfWidth}>
            <MaterialInput
              id={'locationName'}
              label={'Location Name*'}
              value={locationName}
              onChange={(e) => onChangeLocationName(e.target.value)}
              required
            />
            {locationNameValidationError && (
              <div className={styles.error}>Please enter the location name (3 - 500 characters)</div>
            )}
          </div>
          <MaterialInput
            id={'locationAddress'}
            label={'Address'}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          <div className={styles.typeContainer}>
            <div className={styles.halfWidth}>
              <MaterialDropdown
                labelClassName={styles.dropdownLabel}
                id={`type-${location.minorId}`}
                label={'Location Type'}
                labelHelpWithTooltips="Required for DVA claims"
                dropDownList={locationTypeOptions}
                selectedValue={type}
                isClearable
                onChangeValue={(value) => setType((value as typeof type) || undefined)}
              />
            </div>
            {type === LocationType.Hospital && (
              <div className={styles.halfWidth}>
                <MaterialInput
                  id={`providerNumber-${location.minorId}`}
                  label={
                    <>
                      Provider Number{type === LocationType.Hospital && '*'}
                      <HelpOutLineWithTooltips
                        id={`providerNumber-${location.minorId}`}
                        desc={
                          'Commonwealth Hospital Facility Provider Number. Required only if the location\'s type is "Hospital".'
                        }
                      />
                    </>
                  }
                  value={providerNumber}
                  onChange={(e) => handleChangeProviderNumber(e.target.value)}
                  required
                />
                {providerNumberValidationError && (
                  <div className={classnames(styles.error, styles.providerNumberError)}>{providerNumberError}</div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <ButtonAlt className={styles.button} onClick={onSubmit} status={submitStatus}>
            Save Location
          </ButtonAlt>
        </div>
      </div>
    </Modal>
  );
};

export default EditLocationModal;
