import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { DaySelection, DAY_SELECTION } from '../../../constants';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import moment, { Moment } from 'moment';
import {
  getAppointmentsCount,
  getClientAppointmentsByDateRange
} from 'utils/http/ScheduleService/Appointments/Appointments';
import { getProfileTags } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { ProfileTagsInterface } from 'interfaces/Clients/profileTags';

const FORMAT_DATE = 'YYYY-MM-DD';

export const useFetchMemberListByFilter = (
  token: string,
  accountId: string,
  filter: { filterStatus: string; selectedDay: DaySelection }
) => {
  const [listOfMembers, setListOfMembers] = useState<AppointmentSlots[]>([]);
  const [isListOfMembersLoading, setIsListOfMembersLoading] = useState(true);

  const fetchMemberList = useCallback(
    async (token: string, filter: { filterStatus: string; selectedDay: DaySelection }) => {
      try {
        setIsListOfMembersLoading(true);
        let from: Moment | null = null;
        let to: Moment | null = null;

        if (filter.filterStatus === 'past') {
          to = moment().subtract(1, 'day');
        } else {
          switch (filter.selectedDay.key) {
            case 'today':
              from = moment();
              to = moment();
              break;
            case 'tomorrow':
              from = moment().add(1, 'day');
              to = moment().add(1, 'day');
              break;
            case 'thisWeek':
              from = moment();
              to = moment().day(6);
              break;
            case 'nextWeek':
              from = moment().day(7);
              to = moment().day(13);
              break;
            case 'allUpcoming':
              from = moment();
              break;
          }
        }
        const callAppointments = (await (
          await getClientAppointmentsByDateRange({
            token,
            from: from?.format(FORMAT_DATE),
            to: to?.format(FORMAT_DATE)
          })
        ).json()) as AppointmentSlots[];
        const sortedAppointments =
          filter.filterStatus === 'past'
            ? callAppointments.sort(
                (a, b) => moment(b.date).diff(moment(a.date)) || (a.startTime > b.startTime ? 1 : -1)
              )
            : callAppointments.sort(
                (a, b) => moment(a.date).diff(moment(b.date)) || (a.startTime > b.startTime ? 1 : -1)
              );

        //Convert tag id to tag name
        if (sortedAppointments.some(({ clientRecord }) => clientRecord?.tags && clientRecord.tags.length > 0)) {
          const listOfTags = (await (await getProfileTags(token, accountId)).json()) as ProfileTagsInterface[];
          const appointmentsWithTags = sortedAppointments.map((obj) => ({
            ...obj,
            clientRecord: {
              ...obj.clientRecord,
              tags: obj.clientRecord?.tags
                ? obj.clientRecord.tags.map((tag) => {
                    return listOfTags.find(({ _id }) => _id === tag)?.label;
                  })
                : []
            }
          })) as AppointmentSlots[];
          setListOfMembers(appointmentsWithTags);
          setIsListOfMembersLoading(false);
          return;
        }
        setListOfMembers(callAppointments);
        setIsListOfMembersLoading(false);
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get your member list' });
      }

      setIsListOfMembersLoading(false);
    },
    [accountId]
  );

  useEffect(() => {
    if (token) {
      fetchMemberList(token, filter);
    }
  }, [token, filter, fetchMemberList]);
  return { listOfMembers, isListOfMembersLoading, fetchMemberList };
};

export const useFetchCountEachTimeSelections = (token: string) => {
  const [timeSelections, setTimeSelections] = useState<DaySelection[]>([]);
  const [isTimeSelectionsLoading, setIsTimeSelectionsLoading] = useState(true);

  const fetchCountEachTimeSelections = async (token: string) => {
    try {
      const today = moment().format(FORMAT_DATE);
      const callAppointmentCount = await (await getAppointmentsCount(token, today)).json();
      const timeSelectionData = DAY_SELECTION.map((obj) => ({
        ...obj,
        count: callAppointmentCount[obj.key] ?? 0
      }));
      setTimeSelections(timeSelectionData);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get your member list.' });
    }

    setIsTimeSelectionsLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchCountEachTimeSelections(token);
    }
  }, [token]);
  return { timeSelections, isTimeSelectionsLoading };
};
