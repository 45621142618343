import { KeyboardEvent, useState } from 'react';

import Button from 'components/Button/Button';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';

import styles from './TaxRate.module.scss';

interface TaxRateProps {
  defaultTaxRate: string;
  onSetTaxRate: (taxRate: string) => void;
}

const TaxRate = ({ defaultTaxRate, onSetTaxRate }: TaxRateProps) => {
  const [error, setError] = useState(' ');
  const [taxRate, setTaxRate] = useState(defaultTaxRate);

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.keyCode === 13) {
      handleSetTaxRate();
    }
  };

  const handleSetTaxRate = () => {
    if (isNaN(Number(taxRate))) {
      setError('Numbers only!');
    } else {
      setError(' ');

      const taxRateNumber = Number(taxRate);

      let newTaxRate = '';

      if (taxRateNumber < 0) {
        newTaxRate = '0.0';
      } else if (taxRateNumber > 100) {
        newTaxRate = '100.0';
      } else {
        newTaxRate = taxRateNumber.toFixed(1);
      }

      setTaxRate(newTaxRate);
      onSetTaxRate(newTaxRate);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <div className={styles.inputWrapper}>
            <MaterialInput
              id={'taxRate'}
              className={styles.input}
              label="Tax rate"
              value={taxRate}
              onChange={(e) => setTaxRate(e.target.value)}
              onKeyUp={handleKeyUp}
            />
            <ErrorMessage className={styles.error} error={error} visible />
          </div>
          <Button className={styles.button} type="button" onClick={handleSetTaxRate}>
            Set
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TaxRate;
