import { Route, Routes } from 'react-router-dom';
import { GroupDetailsTab } from '../Interfaces/Groups';
import GroupDetails from './GroupDetails';

const GroupDetailRoutes = () => {
  const generateGroupTabs = () => {
    const tabKey = Object.keys(GroupDetailsTab) as (keyof typeof GroupDetailsTab)[];
    let tabList: string[] = [];
    for (const key of tabKey) {
      const tabValue: string = GroupDetailsTab[key];
      tabList.push(tabValue);
    }
    return tabList;
  };

  return (
    <Routes>
      {generateGroupTabs().map((tab, index) => (
        <Route key={index} path={`${tab}/*`} element={<GroupDetails />} />
      ))}
      <Route path={`${GroupDetailsTab.Survey}/:contentId/*`} element={<GroupDetails />} />
      <Route path={`${GroupDetailsTab.Consent}/:contentId/*`} element={<GroupDetails />} />{' '}
      {/* TODO: Ignored for now */}
      <Route path={`${GroupDetailsTab.CaseNotes}/:contentId/*`} element={<GroupDetails />} />
      <Route
        path={`${GroupDetailsTab.Psychometrics}/:contentId/clinicalAssessmentId/:childContentId/*`}
        element={<GroupDetails />}
      />
    </Routes>
  );
};

export default GroupDetailRoutes;
