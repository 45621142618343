import { useField } from 'formik';

import Switch from 'components/Switch/Switch';

import SubmitButton from '../SubmitButton/SubmitButton';
import TermsAndConditions from './components/TermsAndConditions';

import styles from './PracticeData.module.scss';

const PracticeData = () => {
  const [{ value: isEmailCopyRequired, onChange: onIsEmailCopyRequiredChange }] = useField<boolean>(
    'isEmailCopyRequired'
  );

  return (
    <>
      <span className="title">We treat Data Security and Privacy Compliance as first class concerns</span>
      <span className="heading">
        We know how critical it is for you to have confidence and peace of mind that all your important information is
        handled correctly.
        <br />
        <br />
        We use leading security technology, practices and procedures in how we collect, process and manage all data on
        our platform.
      </span>
      <div className="divider" />
      <div className={styles.plainEnglish}>
        <span className={`sectionHeader ${styles.fullVersion}`}>Edge Platform Terms of Use</span>
      </div>
      <div className="divider" />
      <TermsAndConditions />
      <div className={styles.emailCopy}>
        Email me a copy of the policy{' '}
        <Switch
          className={styles.switch}
          checked={isEmailCopyRequired}
          onChange={onIsEmailCopyRequiredChange}
          name="isEmailCopyRequired"
        />
      </div>
      <SubmitButton variant="primary" type="submit">
        Accept terms
      </SubmitButton>
    </>
  );
};

export default PracticeData;
