import { Skeleton, notification } from 'antd';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useFetchPaymentMethods } from 'pages/Invoices/Invoices';
import { Invoice, InvoiceStatus } from 'pages/Invoices/interface';
import { useGetInvoiceTemplatesListQuery } from 'redux/endpoints/billingServices/invoiceTemplates';
import {
  useCreateInvoiceMutation,
  useGetInvoiceByIdQuery,
  useUpdateInvoiceMutation
} from 'redux/endpoints/billingServices/invoices';
import {
  setInvoiceForm,
  setParticipantType,
  setSelectedClient,
  setSelectedGroup,
  selectInvoiceForm,
  selectParticipantType,
  resetAll as resetInvoice,
  selectSelectedFunder,
  selectSelectedPackage
} from 'redux/invoices/createInvoiceWithTemplateSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { useGetInvoiceSettingsQuery } from 'redux/endpoints/billingServices/invoiceSetting';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

import styles from './InvoiceGeneratorWithTemplate.module.scss';
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import { UserContext } from 'utils/UserContextProvider';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { useGetClientEncryptedDataByClientRecordIdQuery } from 'redux/endpoints/clinicianProfileServices/client';
import {
  getInitialServiceDeliveryData,
  getInvoiceId,
  getInvoiceReqPayload,
  getRecipientsValue,
  initNewInvoiceWithTemplateValues
} from './helpers';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { useCreateTemplateContent } from 'utils/helpers/useCreateTemplateContent';
import HeadingAssessment from 'components/HeadingTemplate/HeadingTemplateList/components/HeadingAssessment/HeadingAssessment';
import { useGetGeneralPractitionerByIdQuery } from 'redux/endpoints/clinicianProfileServices/generalPractitioner';

const IS_DEVELOPMENT = isDevelopmentENV();

const InvoiceGeneratorWithTemplate = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { INVOICES } = useRoutesGenerator();
  const { accountId } = useGetAccountId();
  const { invoiceId = '' } = useParams<{ invoiceId?: string }>();
  const location = useLocation() as { [key: string]: any; state: { invoice?: Partial<Invoice> } | null };
  const invoiceTemplateId = location.state?.invoice?.template?._id;
  const { auth0ClinicianId } = useGetClinicianId();
  const { clinicianProfile } = useContext(UserContext);

  const invoiceForm = useAppSelector(selectInvoiceForm);
  const participationType = useAppSelector(selectParticipantType);
  const selectedFunder = useAppSelector(selectSelectedFunder);
  const selectedPackage = useAppSelector(selectSelectedPackage);

  const [createInvoice, { isLoading: isCreating }] = useCreateInvoiceMutation();
  const [updateInvoice, { isLoading: isUpdating }] = useUpdateInvoiceMutation();

  const selectedClientRecordId =
    invoiceForm && invoiceForm.clientRecord && invoiceForm.clientRecord._id ? invoiceForm.clientRecord?._id : null;

  const { paymentMethods, isPaymentMethodsLoading, refetchPaymentMethods } = useFetchPaymentMethods();
  const {
    data: invoiceSettings,
    isLoading: isInvoiceSettingLoading,
    isFetching: isInvoiceSettingFetching
  } = useGetInvoiceSettingsQuery({
    clinicianId: auth0ClinicianId
  });
  const isInvoiceSettingsLoading = isInvoiceSettingLoading || isInvoiceSettingFetching;

  const { data: invoiceTemplateList, isLoading: isInvoiceTemplateListLoading } = useGetInvoiceTemplatesListQuery({
    accountId
  });

  const {
    data: invoice,
    isLoading: isInvoiceDataLoading,
    isFetching: isInvoiceDataFetching
  } = useGetInvoiceByIdQuery(
    {
      invoiceId: invoiceId
    },
    { skip: !invoiceId }
  );

  const {
    data: clientEncryptDetails,
    isLoading: isClientEncryptDetailsLoading,
    isFetching: isClientEncryptDetailsFetching
  } = useGetClientEncryptedDataByClientRecordIdQuery(
    {
      clientRecordId: selectedClientRecordId || ''
    },
    { skip: !selectedClientRecordId }
  );

  const {
    data: generalPractitioner,
    isLoading: isGeneralPractitionerLoading,
    isFetching: isGeneralPractitionerFetching
  } = useGetGeneralPractitionerByIdQuery(
    { generalPractitionerId: clientEncryptDetails?.referral?.generalPractitionerId || '', accountId },
    { skip: !clientEncryptDetails?.referral?.generalPractitionerId || !accountId }
  );

  const { renderer, assessmentList, isRendererBinding } = useCreateTemplateContent({
    clientEncryptDetails,
    shouldNotRun: !IS_DEVELOPMENT
  });

  // Pre-populate data for new invoice
  useEffect(() => {
    if (
      !invoiceId &&
      !isInvoiceTemplateListLoading &&
      invoiceTemplateId &&
      invoiceTemplateList &&
      !isInvoiceSettingsLoading &&
      invoiceSettings &&
      clinicianProfile &&
      paymentMethods
    ) {
      const targetInvoiceTemplate = invoiceTemplateList?.invoiceTemplates.find(
        (item) => item._id === invoiceTemplateId
      );
      const targetPaymentMethods = paymentMethods.filter(({ _id }) =>
        targetInvoiceTemplate?.paymentMethods.includes(_id)
      );

      const initialValues = initNewInvoiceWithTemplateValues({
        practice: clinicianProfile.practice,
        invoiceTemplate: targetInvoiceTemplate,
        invoiceTemplateId,
        paymentMethods: targetPaymentMethods,
        invoiceSettings
      });

      dispatch(
        setInvoiceForm({
          ...initialValues,
          note: targetInvoiceTemplate?.invoiceNotes,
          footer: targetInvoiceTemplate?.invoiceFooter
            ? IS_DEVELOPMENT && renderer
              ? renderer(targetInvoiceTemplate.invoiceFooter)
              : targetInvoiceTemplate?.invoiceFooter
            : '',
          description: targetInvoiceTemplate?.invoiceNotes
            ? IS_DEVELOPMENT && renderer
              ? renderer(targetInvoiceTemplate.invoiceNotes)
              : targetInvoiceTemplate?.invoiceNotes
            : ''
        })
      );
    }
  }, [
    clinicianProfile,
    invoiceId,
    invoiceSettings,
    invoiceTemplateId,
    invoiceTemplateList,
    isInvoiceSettingsLoading,
    isInvoiceTemplateListLoading,
    paymentMethods,
    dispatch,
    renderer
  ]);

  // Pre-populate data for existing invoice
  useEffect(() => {
    if (
      invoiceId &&
      !isInvoiceDataLoading &&
      !isInvoiceDataFetching &&
      invoice &&
      !isInvoiceSettingsLoading &&
      invoiceSettings
    ) {
      invoice.group && dispatch(setSelectedGroup(invoice.group));
      dispatch(setParticipantType(invoice.group ? ParticipantType.Group : ParticipantType.OneToOne));
      dispatch(
        setInvoiceForm({
          ...invoice,
          dueDate: moment(invoice.dueDate).format(MOMENTJS_DATE_FORMAT),
          issueDate: moment(invoice.issueDate).format(MOMENTJS_DATE_FORMAT),
          taxRate: invoiceSettings.taxRate
        })
      );
    }
  }, [
    dispatch,
    invoice,
    invoiceId,
    invoiceSettings,
    isInvoiceDataLoading,
    isInvoiceDataFetching,
    isInvoiceSettingsLoading
  ]);

  // Bind the data based on clientRecord
  useEffect(() => {
    if (
      !isClientEncryptDetailsLoading &&
      !isClientEncryptDetailsFetching &&
      !isGeneralPractitionerLoading &&
      !isGeneralPractitionerFetching
    ) {
      dispatch(setSelectedClient(clientEncryptDetails));
      const invoiceTemplate =
        invoice?.template || invoiceTemplateList?.invoiceTemplates.find((item) => item._id === invoiceTemplateId);
      const recipientsData =
        !invoiceId && clientEncryptDetails && invoiceTemplate
          ? getRecipientsValue({ invoiceTemplate: invoiceTemplate, clientEncryptDetails })
          : null;
      const medicareData =
        !invoiceId && clientEncryptDetails && generalPractitioner
          ? getInitialServiceDeliveryData(clientEncryptDetails, generalPractitioner)
          : null;

      dispatch(
        setInvoiceForm({
          ...(!invoiceId && {
            invoiceId: `${invoiceTemplate?.shortCode ? `${invoiceTemplate.shortCode}-` : ''}${getInvoiceId({
              clientEncryptDetails,
              invoiceSettings
            })}`
          }),
          ...(recipientsData && { recipients: recipientsData }),
          ...(medicareData && { medicare: medicareData })
        })
      );
    }
  }, [
    invoiceId,
    invoice,
    isInvoiceDataLoading,
    isInvoiceDataFetching,
    isClientEncryptDetailsLoading,
    isClientEncryptDetailsFetching,
    clientEncryptDetails,
    invoiceSettings,
    generalPractitioner,
    isGeneralPractitionerLoading,
    isGeneralPractitionerFetching,
    invoiceTemplateId,
    invoiceTemplateList,
    dispatch
  ]);

  const invoiceTemplate = invoiceTemplateList?.invoiceTemplates.find((item) => item._id === invoiceTemplateId);

  const validateForm = () => {
    const { group, dueDate, paymentMethods, items } = invoiceForm;

    if (!selectedClientRecordId && !group?._id) {
      return notification.error({
        message: t('form.error.invoice_missing_client_or_group', {
          participant: participationType === ParticipantType.Group ? t('label.group') : t('label.client')
        }),
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }

    if (!dueDate) {
      return notification.error({
        message: 'Please select a due date before creating this invoice',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }

    if (paymentMethods.length < 1) {
      return notification.error({
        message: 'Please select a payment method before creating this invoice',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }

    if (items.length < 1) {
      return notification.error({
        message: 'Please select an appointment item before creating this invoice',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }

    return true;
  };

  const handleSave = async (invoiceStatus: InvoiceStatus) => {
    if (!validateForm()) {
      return;
    }
    try {
      const funderId = selectedPackage?.assignee.funder.funderId || selectedFunder?._id || '';

      if (invoiceId) {
        await updateInvoice({
          invoiceId,
          payload: getInvoiceReqPayload(invoiceStatus, invoiceForm, funderId)
        }).unwrap();
        notification.success({
          message: 'Invoice updated!',
          duration: 2
        });
      } else {
        await createInvoice({ payload: getInvoiceReqPayload(invoiceStatus, invoiceForm, funderId) }).unwrap();
        notification.success({
          message: 'Invoice created!',
          duration: 2
        });
        dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.ClientEncrypted]));
      }
      dispatch(resetInvoice());
      navigate(INVOICES.BASE);
    } catch (ex) {
      notification.error({
        message: `Something went wrong while trying to ${invoiceId ? 'update' : 'create'} this invoice`,
        duration: 2
      });
    }
  };

  return (
    <HelmetWrapper title="Invoice Generator With Template">
      <ContentLayout className={styles.container}>
        {isInvoiceTemplateListLoading ||
        isInvoiceSettingsLoading ||
        isInvoiceDataLoading ||
        !invoiceTemplateList ||
        !invoiceTemplate ||
        !clinicianProfile ? (
          <Skeleton active />
        ) : (
          <>
            {IS_DEVELOPMENT && <HeadingAssessment isLoading={isRendererBinding} assessmentData={assessmentList} />}
            <LeftSection
              clinicianName={`${clinicianProfile.title}  ${clinicianProfile.name}`}
              isPaymentMethodsLoading={isPaymentMethodsLoading}
              paymentMethods={paymentMethods}
              refetchPaymentMethods={refetchPaymentMethods}
              invoiceTemplateList={invoiceTemplateList.invoiceTemplates}
              invoiceTemplate={invoiceTemplate}
            />
            <RightSection
              isClientDetailsLoading={isClientEncryptDetailsLoading || isClientEncryptDetailsFetching}
              isRendererBinding={isRendererBinding}
              clientEncryptDetails={clientEncryptDetails}
              invoiceTemplate={invoiceTemplate}
              onSave={handleSave}
              isSubmitting={isCreating || isUpdating}
            />
          </>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InvoiceGeneratorWithTemplate;
