import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { InvoiceWithProjectedMbsCode, PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';
import { getClaimInvoiceDetailsInfo } from 'pages/InvoicePendingClaims/utils';
import { ClaimType } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';
import { MBS_CODE_ITEMS } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/mbsItems';

export const getSelectedClaimType = (selectedFunder?: PendingClaimsFunder) => {
  switch (selectedFunder) {
    case PendingClaimsFunder.REBATE:
      return ClaimType.Medicare;
    case PendingClaimsFunder.BULK_BILL:
      return ClaimType.BulkBill;
    case PendingClaimsFunder.DVA:
      return ClaimType.DVA;
    default:
      return undefined;
  }
};

export const getCreateClaimPayload = (claim: InvoiceWithProjectedMbsCode) => {
  const { clientProfile } = getClaimInvoiceDetailsInfo(claim.foundClientRecord);

  const clinicianRoleMbsItems =
    MBS_CODE_ITEMS[claim.foundClinician?.medicare?.role || ClinicianMedicareRole.ClinicalPsychologists];

  const mbsItem = clinicianRoleMbsItems.find(({ mbsCode }) => mbsCode === claim.mbsCode) || clinicianRoleMbsItems[0];

  return {
    type: getSelectedClaimType(claim.funder),
    invoiceId: claim._id,
    clientRecordId: claim.foundClientRecord._id,
    clientProfileId: clientProfile?._id || '',
    mbsCode: claim.mbsCode,
    benefit: Math.min(claim.invoiceAmount, mbsItem.benefit),
    service: {
      duration: mbsItem.duration,
      format: mbsItem.format,
      mode: mbsItem.mode
    }
  };
};
