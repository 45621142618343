import { useMemo } from 'react';
import classnames from 'classnames';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import FlexBox from 'components/FlexBox/FlexBox';
import { PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';
import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { MBS_CODE_ITEMS } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/mbsItems';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectFormError,
  selectIsPrepareClaimOpen,
  selectProcessAppointment,
  setFormError,
  setIsPrepareClaimOpen,
  setProcessAppointment
} from 'redux/processAppointment/processAppointmentSlice';
import styles from './AppointmentClaimDetails.module.scss';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import MBSCodeOptionItem from '../MBSCodeOptionItem/MBSCodeOptionItem';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { ClaimType } from 'interfaces/Schedule/AppointmentType';
import { ServiceDelivered } from 'interfaces/Schedule/Appointment';
import CheckBox from 'components/CheckBox/CheckBox';
import { config } from 'config/config';

const CODE_TYPE_BULK_BILL: IOptionItem = { label: 'Medicare - BulkBill', value: PendingClaimsFunder.BULK_BILL };
const CODE_TYPE_REBATE: IOptionItem = { label: 'Medicare - Rebate', value: PendingClaimsFunder.REBATE };
const CODE_TYPE_DVA: IOptionItem = { label: 'DVA', value: PendingClaimsFunder.DVA };

const CodeTypeNote = (
  <div className={styles.codeTypeNote}>
    <i className={`material-icons-outlined ${styles.icon}`}>info</i>
    This appointment only supports limited code types. To use another code type please change the appointment type
    above.
  </div>
);

const AppointmentClaimDetails = () => {
  const MONEY_SYMBOL = config.currencySymbol;
  const dispatch = useAppDispatch();
  const isPrepareClaimOpen = useAppSelector(selectIsPrepareClaimOpen);
  const processAppointment = useAppSelector(selectProcessAppointment);
  const formError = useAppSelector(selectFormError);
  const {
    selectedServiceDelivered,
    selectedAppointmentType,
    selectedPractitioner,
    selectedClaimType,
    selectedMbsCode,
    useServiceFee
  } = processAppointment;

  const mbsCodeOptions = useMemo(() => {
    return MBS_CODE_ITEMS[selectedPractitioner?.medicare?.role || ClinicianMedicareRole.ClinicalPsychologists].map(
      ({ mbsCode, benefit, description }) => ({
        label: mbsCode,
        value: mbsCode,
        benefit,
        description
      })
    );
  }, [selectedPractitioner]);

  const codeTypeOptions = useMemo(() => {
    switch (selectedAppointmentType?.claimType) {
      case ClaimType.BULK_BILL:
        return [CODE_TYPE_BULK_BILL, CODE_TYPE_DVA];
      case ClaimType.REBATE:
        return [CODE_TYPE_REBATE, CODE_TYPE_DVA];
      default:
        return [CODE_TYPE_BULK_BILL, CODE_TYPE_REBATE, CODE_TYPE_DVA];
    }
  }, [selectedAppointmentType]);

  const handleRemoveClaimDetails = () => {
    dispatch(
      setProcessAppointment({
        selectedClaimType: undefined,
        selectedMbsCode: undefined,
        useServiceFee: false
      })
    );
    dispatch(
      setFormError({
        selectedClaimType: undefined,
        selectedMbsCode: undefined
      })
    );
    dispatch(setIsPrepareClaimOpen(false));
  };

  const getMessage = (claimType: string) => {
    switch (claimType) {
      case PendingClaimsFunder.BULK_BILL:
        return 'This will create a pending Bulk Bill claim. To be processed client medicare and referral data must be accurate and allow this service.';
      case PendingClaimsFunder.REBATE:
        return 'This will create a pending Rebate claim. To be processed the appointment fee must be paid in full and relevant client medicare and referral data must be accurate and allow this service.';
      case PendingClaimsFunder.DVA:
        return 'This will create a pending DVA claim.';
      default:
        return null;
    }
  };

  const onSelectClaimType = (value: string) => {
    dispatch(
      setProcessAppointment({
        selectedClaimType: value
      })
    );

    // Reset error
    if (formError.selectedClaimType) {
      dispatch(
        setFormError({
          selectedClaimType: false
        })
      );
    }
  };

  const onSelectMBSCode = (value: string) => {
    dispatch(
      setProcessAppointment({
        selectedMbsCode: value
      })
    );
    // Reset error
    if (formError.selectedMbsCode) {
      dispatch(
        setFormError({
          selectedMbsCode: false
        })
      );
    }
  };

  const onCheckServiceFee = (value: boolean) => {
    dispatch(
      setProcessAppointment({
        useServiceFee: value
      })
    );
  };

  const showCodeClaimTypeNote =
    selectedAppointmentType?.claimType &&
    [ClaimType.BULK_BILL, ClaimType.REBATE].includes(selectedAppointmentType.claimType);

  const disabledPrepareClaim =
    !selectedAppointmentType?.claimType ||
    ![ClaimType.BULK_BILL, ClaimType.REBATE, ClaimType.DVA].includes(selectedAppointmentType.claimType) ||
    selectedServiceDelivered !== ServiceDelivered.Attended;

  return (
    <>
      {!disabledPrepareClaim && isPrepareClaimOpen ? (
        <>
          <FlexBox
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className={
              formError.selectedClaimType || formError.selectedMbsCode ? styles.errorContainer : styles.container
            }
          >
            <FlexBox direction="row" alignItems="center" spacing={16}>
              <div className={styles.dropdownWrapper}>
                <DropdownSearchable
                  className={classnames(formError.selectedClaimType && styles.errorInput)}
                  placeholder="Code Type"
                  selected={selectedClaimType}
                  options={codeTypeOptions}
                  onSelect={onSelectClaimType}
                  note={showCodeClaimTypeNote ? CodeTypeNote : undefined}
                  optionWrapperClassName={styles.codeTypeOptionWrapper}
                />
              </div>
              <hr className={styles.divider} />
              <div className={styles.dropdownWrapper}>
                <DropdownSearchable
                  placeholder="Code"
                  controllerClassName={classnames(styles.maxWidth100, styles.controllerClassName)}
                  dropdownIconClassName={styles.dropdownIconClassName}
                  optionWrapperClassName={styles.minWidth500}
                  className={classnames(formError.selectedMbsCode && styles.errorInput)}
                  options={mbsCodeOptions}
                  selected={selectedMbsCode}
                  onSelect={onSelectMBSCode}
                  DropdownItemChildren={(item) => (
                    <MBSCodeOptionItem label={item.props.value} description={item.props.description} />
                  )}
                  DisplayLabelChildren={(item) => <MBSCodeOptionItem label={item.props.value} />}
                  itemClassName={styles.codeItemClassName}
                />
              </div>
            </FlexBox>
            {selectedMbsCode && (
              <div className={styles.dropdownWrapper}>
                <CheckBox
                  className={styles.checkBox}
                  id={'mbsCheckbox'}
                  value={useServiceFee}
                  label={`Use the Medicare fee schedule price of ${MONEY_SYMBOL}${(
                    mbsCodeOptions.find(({ value }) => value === selectedMbsCode)?.benefit || 0
                  ).toFixed(2)}`}
                  onChange={(e) => onCheckServiceFee(e.target.checked)}
                />
              </div>
            )}

            <div className={styles.deleteButtonWrapper} onClick={handleRemoveClaimDetails}>
              <i className={'material-icons-outlined'}>delete_outline</i>
            </div>
          </FlexBox>
          {selectedClaimType && <div className={styles.messageWrapper}>{getMessage(selectedClaimType)}</div>}
        </>
      ) : (
        <ButtonAlt
          disabled={disabledPrepareClaim}
          variant="outlined"
          onClick={() => dispatch(setIsPrepareClaimOpen(!isPrepareClaimOpen))}
          icon="add_circle_outline"
        >
          Prepare Claim
        </ButtonAlt>
      )}
    </>
  );
};

export default AppointmentClaimDetails;
