import classNames from 'classnames';
import styles from './PillButtonGroup.module.scss';

interface PillButtonGroupProps {
  options: {
    value: string;
    label: string;
  }[];
  value: string;
  onChange: (value: string) => void;
  compact?: boolean;
  variant?: 'default' | 'theme';
}

const PillButtonGroup = ({ options, value, onChange, compact = false, variant = 'default' }: PillButtonGroupProps) => {
  const handleClick = (option: string) => () => {
    onChange(option);
  };

  return (
    <div className={styles.container}>
      {options.map((option, index) => (
        <button
          key={index}
          className={classNames(
            styles.button,
            value === option.value && styles.active,
            compact && styles.compact,
            styles[variant]
          )}
          onClick={handleClick(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default PillButtonGroup;
