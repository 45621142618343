import classNames from 'classnames';
import AddressForm from 'components/AddPatientModalV2/components/AddressForm/AddressForm';
import { EditAddressBookRequestPayload } from 'components/AddressBook/EditAddressBookModal/EditAddressBookModalInterfaces';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import Radio from 'components/Radio/Radio';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import AddressBookContactTable from 'pages/AddressBook/AddressBookList/components/AddressBookListContent/components/AddressBookTable/components/AddressBookContactTable/AddressBookContactTable';
import {
  ADDRESS_BOOK_CATEGORY_OPTIONS,
  ADDRESS_BOOK_ROLE_OPTIONS,
  ADDRESS_BOOK_TYPE_OPTIONS,
  AddressBookType
} from 'pages/AddressBook/Interfaces/AddressBook';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import {
  CreateAddressBookRequestPayload,
  CreateAddressBookValidateField
} from '../../CreateAddressBookModalInterfaces';
import AddressBookContactForm, {
  AddressBookContactFormHandler,
  CreateAddressBookContactRequestPayload
} from '../AddressBookContactForm/AddressBookContactForm';
import styles from './AddressBookForm.module.scss';

export interface AddressBookFormHandler {
  submitContact: () => Promise<CreateAddressBookContactRequestPayload[] | undefined>;
}

export type CreateOrUpdateAddressBookPayload = CreateAddressBookRequestPayload | EditAddressBookRequestPayload;

interface AddressBookFormProps {
  mode: 'add' | 'edit';
  addressBookFormField: CreateOrUpdateAddressBookPayload;
  onChangeField: (fields: CreateOrUpdateAddressBookPayload) => void;
  errorMessage: CreateAddressBookValidateField;
  submitStatus: string;
  contactField: CreateAddressBookContactRequestPayload;
  setContactField: (fields?: CreateAddressBookContactRequestPayload) => void;
}

const AddressBookForm = forwardRef<AddressBookFormHandler, AddressBookFormProps>(
  ({ mode, addressBookFormField, onChangeField, errorMessage, submitStatus, contactField, setContactField }, ref) => {
    const contactFormRef = useRef<AddressBookContactFormHandler>(null);

    const handleChangeFieldValue = async (key: string, val: string) => {
      const newAddressBookField = {
        ...addressBookFormField,
        [key]: val
      };
      onChangeField(newAddressBookField);
    };

    const handleChangeOrganisationFieldValue = async (key: string, val: string) => {
      const newAddressBookField = {
        ...addressBookFormField,
        organisation: {
          name: '',
          ...addressBookFormField.organisation,
          [key]: val
        }
      };
      onChangeField(newAddressBookField);
    };

    const handlePlaceSelected = (value: CreateOrUpdateAddressBookPayload['address']) => {
      const formValue = addressBookFormField.address;
      const addressMassage = {
        ...addressBookFormField.address,
        line1: value?.line1,
        line2: formValue?.line2 || value?.line2,
        state: formValue?.state || value?.state,
        suburb: formValue?.suburb || value?.suburb,
        postcode: formValue?.postcode || value?.postcode,
        country: formValue?.country || value?.country
      };
      const newAddressBookField = {
        ...addressBookFormField,
        address: addressMassage
      };
      onChangeField(newAddressBookField);
    };

    const onChangeAddress = (value: CreateOrUpdateAddressBookPayload['address']) => {
      const newAddressBookField = {
        ...addressBookFormField,
        address: value
      };
      onChangeField(newAddressBookField);
    };

    const handleDeleteContact = (deleteContactIndex: number) => {
      let newContacts = addressBookFormField.contacts.filter((_, index) => index !== deleteContactIndex);

      if (addressBookFormField.contacts[deleteContactIndex].isPrimary && newContacts.length) {
        // if deleting primary contact, set the first remaining as primary
        const updatedPrimaryContact = { ...newContacts[0], isPrimary: true, updatedAt: new Date() };
        newContacts = [updatedPrimaryContact, ...newContacts.slice(1)];
      }

      const newAddressBookField = {
        ...addressBookFormField,
        contacts: newContacts
      };

      onChangeField(newAddressBookField);
    };

    const handleChangePrimaryContact = (updateIndex: number) => {
      const newAddressBookField = {
        ...addressBookFormField,
        contacts: addressBookFormField.contacts.map((contact, index) => ({
          ...contact,
          isPrimary: index === updateIndex,
          updatedAt: new Date()
        }))
      };

      onChangeField(newAddressBookField);
    };

    const handleContactFormSubmit = async (
      contact: CreateAddressBookContactRequestPayload = contactField,
      type: AddressBookType = addressBookFormField.type,
      isReset?: boolean
    ) => {
      const isOrganisation = type === AddressBookType.Organisation;
      const isValid = await validateContactForm(isOrganisation);

      if (isValid) {
        if (addressBookFormField.contacts?.length < 1) {
          contact.isPrimary = true;
        }

        const newAddressBookField = {
          ...addressBookFormField,
          contacts: !isOrganisation ? [contact] : [...addressBookFormField.contacts, contact]
        };

        onChangeField(newAddressBookField);
        if (isReset) {
          setContactField();
        }
        return newAddressBookField.contacts;
      }
    };

    const handleTypeChange = async (type: AddressBookType) => {
      const { contacts } = addressBookFormField;

      if (type === AddressBookType.Individual) {
        if (contacts.length > 0) {
          const primary = contacts.find((contact) => contact.isPrimary);
          if (primary) {
            await handleContactFormSubmit(primary, type, false);
            setContactField(primary);
          }
        }
      } else {
        if (contacts.length > 0) {
          setContactField();
        }
      }
      handleChangeFieldValue('type', type);
    };

    const validateContactForm = async (isReset?: boolean) => {
      if (contactFormRef.current) {
        return await contactFormRef.current.validate(isReset);
      }
    };

    useImperativeHandle(ref, () => ({
      submitContact: async () => {
        return await handleContactFormSubmit();
      }
    }));

    return (
      <div>
        <div className={styles.header}>SET UP</div>
        <div className={styles.rowContainer}>
          <div className={classNames(styles.fieldContainer, styles.roleField)}>
            <ToggleSwitchV2
              id={`role-${mode}`}
              toggleList={ADDRESS_BOOK_ROLE_OPTIONS.map((opt) => ({
                id: opt._id,
                label: opt.name,
                isActive: addressBookFormField.role === opt._id
              }))}
              className={styles.toggleContainer}
              wrapperClassName={styles.toggleWrapper}
              activeLabelClassName={styles.activeLabel}
              indicatorClassName={styles.indicator}
              onChangeStatus={(val) => {
                handleChangeFieldValue('role', val.id);
              }}
              size="medium"
            />
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.radioBtnContainer}>
              <Radio
                name={`type-${mode}`}
                options={ADDRESS_BOOK_TYPE_OPTIONS.map((obj) => ({
                  value: obj._id,
                  label: obj.name
                }))}
                value={addressBookFormField.type}
                labelClassName={styles.label}
                onChange={(e) => {
                  handleTypeChange(e.target.value as AddressBookType);
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.rowContainer}>
          {addressBookFormField.type === AddressBookType.Organisation && (
            <div className={styles.fieldContainer}>
              <MaterialInput
                id={`name-${mode}-${addressBookFormField.organisation?.name}`}
                label={`Organisation Name`}
                maxLength={20}
                onChange={(e) => handleChangeOrganisationFieldValue('name', e.target.value)}
                value={addressBookFormField.organisation?.name}
                error={!!errorMessage.organisation.name}
                required
              />
              {errorMessage.organisation.name && (
                <div className={styles.fieldError}>{errorMessage.organisation.name}</div>
              )}
            </div>
          )}
          <div className={classNames(styles.fieldContainer, styles.dropdownField, styles.typeField)}>
            <MaterialSelect
              id={`category-${mode}-${addressBookFormField.category}`}
              label={'Category'}
              isSearchable={false}
              options={ADDRESS_BOOK_CATEGORY_OPTIONS.map((opt) => ({ label: opt.name, value: opt._id }))}
              value={addressBookFormField.category}
              onChange={(value) => handleChangeFieldValue('category', value)}
              error={!!errorMessage.category}
            />
            {errorMessage.category && <div className={styles.fieldError}>{errorMessage.category}</div>}
          </div>

          <div className={classNames(styles.fieldContainer, styles.codeField)}>
            <MaterialInput
              id={`code-${mode}-${addressBookFormField.code}`}
              label={`Code`}
              maxLength={20}
              minLength={5}
              onChange={(e) => handleChangeFieldValue('code', e.target.value)}
              value={addressBookFormField.code}
            />
          </div>
        </div>

        {addressBookFormField.type === AddressBookType.Organisation && (
          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <MaterialInput
                id={`description-${mode}-${addressBookFormField.organisation?.description}`}
                label={`Short description`}
                maxLength={200}
                onChange={(e) => handleChangeOrganisationFieldValue('description', e.target.value)}
                value={addressBookFormField.organisation?.description}
              />
            </div>
          </div>
        )}

        {addressBookFormField.type === AddressBookType.Individual && (
          <div className={styles.rowContainer}>
            <div className={styles.fieldContainer}>
              <AddressBookContactForm
                ref={contactFormRef}
                contactField={contactField}
                onChangeField={setContactField}
              />
            </div>
          </div>
        )}

        <div className={styles.fieldContainer}>
          <AddressForm
            clientAddress={addressBookFormField.address || {}}
            onChangeClientAddress={onChangeAddress}
            onPlaceSelected={handlePlaceSelected}
          />
        </div>

        {addressBookFormField.type === AddressBookType.Organisation && (
          <>
            <div className={styles.rowContainer}>
              <div className={styles.fieldContainer}>
                <MaterialPhoneInput
                  id={`phone-${mode}`}
                  autoFormat={false}
                  disableCountryCode
                  disabled={submitStatus === 'active'}
                  hideFlag
                  inputName="mobileNumber"
                  label="Phone"
                  onChange={(value = '') => {
                    handleChangeOrganisationFieldValue('phone', value);
                  }}
                  placeholder=""
                  isError={!!errorMessage.organisation.phone}
                  value={addressBookFormField.organisation?.phone || ''}
                />
                {errorMessage.organisation.phone && (
                  <div className={styles.fieldError}>{errorMessage.organisation.phone}</div>
                )}
              </div>

              <div className={styles.fieldContainer}>
                <MaterialPhoneInput
                  id={`faxNumber-${mode}`}
                  autoFormat={false}
                  disableCountryCode
                  disabled={submitStatus === 'active'}
                  hideFlag
                  inputName="faxNumber"
                  label="Fax"
                  onChange={(value = '') => {
                    handleChangeOrganisationFieldValue('faxNumber', value);
                  }}
                  placeholder=""
                  isError={!!errorMessage?.organisation.faxNumber}
                  value={addressBookFormField.organisation?.faxNumber || ''}
                />
                {errorMessage.organisation.faxNumber && (
                  <div className={styles.fieldError}>{errorMessage.organisation.faxNumber}</div>
                )}
              </div>
            </div>
            <div className={classNames(styles.fieldContainer, styles.contactSection)}>
              <div className={styles.header}>ADD INDIVIDUALS</div>
              {addressBookFormField.contacts?.length > 0 && (
                <AddressBookContactTable
                  type={addressBookFormField.type}
                  contacts={addressBookFormField.contacts}
                  onChangePrimaryContact={handleChangePrimaryContact}
                  onDeleteContact={handleDeleteContact}
                  isEditMode
                />
              )}
              <AddressBookContactForm
                ref={contactFormRef}
                contactField={contactField}
                onChangeField={setContactField}
              />

              <ButtonAlt
                onClick={(e) => {
                  e.preventDefault();
                  handleContactFormSubmit(undefined, undefined, true);
                }}
              >
                Add Individual
              </ButtonAlt>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default AddressBookForm;
