import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';

import styles from './Analytics.module.scss';
import { useGetAccountSettings } from '../../utils/hooks/GetAccountSettings/getAccountSettings';

const Analytics = () => {
  const { accountSettings } = useGetAccountSettings();
  return (
    <HelmetWrapper title="Tacklit - Analytics">
      <ContentLayout className={styles.container}>
        <iframe
          title={'Helm Content'}
          width={'100%'}
          height={'100%'}
          src={accountSettings?.analytics?.url}
          frameBorder={0}
          allowFullScreen
        />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Analytics;
