import { RadarChart, Radar, PolarRadiusAxis } from 'recharts';
import { Assessment } from '../../../PatientDetailsAssessments/PatientDetailsAssessments';
import styles from './RecoverySTAR.module.scss';

interface RecoverySTARProps {
  assessment: Assessment;
}

const recoverySTARScale = [
  { value: [1, 2], label: 'Stuck' },
  { value: [3, 4], label: 'Accepting help' },
  { value: [5, 6], label: 'Believing and trying' },
  { value: [7, 8], label: 'Learning' },
  { value: [9, 10], label: 'Self-reliance' }
];

const RecoverSTAR = ({ assessment }: RecoverySTARProps) => {
  const starData = assessment?.sections[0].questions.slice(0, -1).map((question) => ({
    scale: question.stem,
    value: Number(String(question.response?.value).split('-')[0])
  }));

  const starThoughts = assessment?.sections[0].questions.slice(-1)[0].response?.value;

  return (
    <div className={styles.star}>
      <div className={styles.starData}>
        <RadarChart
          width={700}
          height={600}
          cx="50%"
          cy="50%"
          innerRadius={'8%'}
          outerRadius={'60%'}
          data={starData}
          className={styles.starChart}
        >
          <Radar
            dataKey="value"
            stroke="#3F52FF"
            strokeWidth={'4px'}
            fill={'transparent'}
            dot={({ cx, cy, ...rest }) => {
              return (
                <g>
                  <circle cx={cx} cy={cy} r="8" fill="#3F52FF" />
                  <text x={cx} y={cy} textAnchor="middle" stroke="#ffffff" strokeWidth="1.2px" dy=".3em" fontSize="9px">
                    {/* @ts-ignore */}
                    {rest.payload.value}
                  </text>
                </g>
              );
            }}
          />
          <PolarRadiusAxis domain={[1, 10]} axisLine={false} tick={false} />
        </RadarChart>
      </div>
      <div className={styles.starInfo}>
        <div className={styles.starThoughts}>
          <div className={styles.starThoughtsTitle}>Your thoughts</div>
          <div className={styles.starThoughtsText}>{starThoughts}</div>
        </div>
        <div className={styles.starScale}>
          {recoverySTARScale.map((scale) => (
            <div className={styles.starScaleTextItem} key={scale.label}>
              <div className={styles.starScaleTextItemValue}>{scale.value.join('-')}</div>
              <div className={styles.starScaleTextItemLabel}>{scale.label}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.starCopyright}>
        © Triangle Consulting Social Enterprise. Used under licence. See{' '}
        <a href="https://www.outcomesstar.org.uk" target="_blank" rel="noreferrer">
          www.outcomesstar.org.uk
        </a>
      </div>
    </div>
  );
};

export default RecoverSTAR;
