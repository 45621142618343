import i18n from 'i18n';
import * as yup from 'yup';
import { ClientDetails } from '../../../../../AddPatientModalV2Interface';
import { ChildGuardianErrorInterface } from '../ChildGuardianInterface';

const childGuardianFormSchema = () =>
  yup.object().shape(
    {
      _areContactDetailsMandatory: yup.boolean().default(false),
      firstName: yup.string().required('Please enter first name'),
      lastName: yup.string().required('Please enter last name'),
      role: yup.string().required(i18n.t('validation.client_role.require')),
      isPrimaryContact: yup.boolean(),
      email: yup
        .string()
        .email('Please enter a valid email address')
        .when('_areContactDetailsMandatory', {
          is: true,
          then: (schema) => schema.required('Please enter a valid email address')
        }),
      mobileNumber: yup
        .string()
        .when('_areContactDetailsMandatory', {
          is: true,
          then: (schema) => schema.required('Please enter a valid mobile number')
        })
        .when('mobileNumber', (mobileNumber, schema) => {
          if (mobileNumber && mobileNumber.length > 0) {
            return schema.min(12, 'Mobile number must be 10 digits or longer');
          }

          return schema;
        })
    },
    [
      // Escape hatch for cyclical reference
      ['mobileNumber', 'mobileNumber']
    ]
  );

export const validationChildGuardianField = (value: ClientDetails, areContactDetailsMandatory: boolean = false) => {
  const validationErrors: ChildGuardianErrorInterface = {
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    mobileNumber: ''
  };

  try {
    childGuardianFormSchema().validateSync(
      { ...value, _areContactDetailsMandatory: areContactDetailsMandatory },
      { abortEarly: false }
    );

    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error) => {
        if (error.path) {
          validationErrors[error.path as keyof ChildGuardianErrorInterface] = error.message;
        }
      });

      return validationErrors;
    }
  }
};
