import * as yup from 'yup';
import { ChildAndYoungClientErrorInterface } from '../ChildClientInterface';
import moment from 'moment';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import i18n from 'i18n';

const childAndYoungPersonFormSchema = () =>
  yup.object().shape({
    firstName: yup.string().required(i18n.t('validation.client_firstname.require')),
    lastName: yup.string().required(i18n.t('validation.client_lastname.require')),
    dateOfBirth: yup.string().test('Date of Birth', i18n.t('validation.dob.invalid'), (value) => {
      if (value && value?.length > 0) {
        return moment(value, MOMENTJS_DATE_FORMAT).format(MOMENTJS_DATE_FORMAT) === value;
      } else {
        return true;
      }
    }),
    email: yup.string().email(i18n.t('validation.client_email.invalid')),
    mobileNumber: yup
      .string()
      .test('is-empty-or-valid', i18n.t('validation.client_phonenumber.min', { count: 10 }), (value) => {
        if (value && value?.length === 0) {
          return value?.length >= 10;
        } else {
          return true;
        }
      })
  });

export const validationChildAndYoungPersonField = (clientVal: ChildAndYoungClientErrorInterface) => {
  const validationErrors: ChildAndYoungClientErrorInterface = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    mobileNumber: ''
  };

  try {
    childAndYoungPersonFormSchema().validateSync(clientVal, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof ChildAndYoungClientErrorInterface] = error.message;
        }
      });
      return validationErrors;
    }
  }
};
