import moment from 'moment';
import {
  selectAppointmentData,
  setSelectedDate,
  setSelectedStatus,
  startInlineCalendarValidation
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getTimezoneLabel } from 'utils/hooks/GetTimezones/getTimezones';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import AppointmentDatePicker from '../../../AppointmentDatePicker/AppointmentDatePicker';
import AppointmentTimePicker from '../../../AppointmentTimePicker/AppointmentTimePicker';
import styles from './AppointmentDateAndTime.module.scss';
import { convertAppointmentTimeBetweenTimezones } from 'utils/helpers/timezone';
import classNames from 'classnames';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { AppointmentStatusType } from 'interfaces/Schedule/Appointment';
import { Datepicker } from '@mobiscroll/react';
import { useMemo } from 'react';

const appointmentStatusOptions = [
  { label: 'Busy', value: AppointmentStatusType.Busy },
  { label: 'Free', value: AppointmentStatusType.Free }
];

interface AppointmentDateAndTimeProps {
  onInlineRef?: (ref: Datepicker | null) => void;
}

const AppointmentDateAndTime = ({ onInlineRef }: AppointmentDateAndTimeProps) => {
  const dispatch = useAppDispatch();
  const { timeZoneByView } = useTimeZone();
  const { selectedDate, selectedPractitioner, selectedTime, selectedAppointmentType, selectedStatus, isActivity } =
    useAppSelector(selectAppointmentData);
  const inlineCalendarValidation = useAppSelector(startInlineCalendarValidation);

  const onSelectDate = (value: Date) => {
    dispatch(setSelectedDate(moment(value).format('YYYY-MM-DD')));
  };

  const { startDateTime: selectedPractitionerStartDateTime, endDateTime: selectedPractitionerEndDateTime } =
    selectedPractitioner?.workTimeZone && selectedPractitioner.workTimeZone !== timeZoneByView
      ? convertAppointmentTimeBetweenTimezones({
          date: selectedDate,
          startTime: selectedTime.startTime,
          endTime: selectedTime.endTime,
          originalTimezone: timeZoneByView,
          targetTimezone: selectedPractitioner?.workTimeZone || ''
        })
      : { startDateTime: undefined, endDateTime: undefined };

  const handleSelectActivityStatus = (value: string) => {
    dispatch(setSelectedStatus(value));
  };

  const isSlotNotSelected = useMemo(
    () => !selectedDate || !selectedTime.startTime || !selectedTime.endTime,
    [selectedDate, selectedTime]
  );

  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.dateTimeWrapper,
          inlineCalendarValidation && isSlotNotSelected && styles.slotBoxError
        )}
      >
        <div className={styles.dateTimeItem}>
          <AppointmentDatePicker
            selectedDate={selectedDate ? moment(selectedDate).toDate() : undefined}
            placeholder={'Select Date'}
            onSelect={onSelectDate}
            labelFormat={'dddd, Do MMMM'}
            onInlineRef={onInlineRef}
          />
        </div>
        <div className={styles.dateTimeItem}>
          <AppointmentTimePicker
            step={15}
            minTime={15}
            duration={selectedAppointmentType ? selectedAppointmentType.duration.minutes : 50}
          />
        </div>
        {isActivity && (
          <div className={styles.dateTimeItem}>
            Diary is
            <DropdownSearchable
              selected={selectedStatus}
              options={appointmentStatusOptions}
              onSelect={handleSelectActivityStatus}
            />
          </div>
        )}
      </div>
      <div
        className={classNames(
          styles.timezoneWrapper,
          inlineCalendarValidation && isSlotNotSelected && styles.timezoneSlotBoxError
        )}
      >
        <i className={classNames('material-icons', styles.icon)}>schedule</i>
        <div className={styles.timezone}>
          <div className={styles.yourTimezone}>
            The timezone the booking will be made in:
            <strong> {getTimezoneLabel(timeZoneByView)}</strong>
          </div>
          {/* There is a case that clinician doesn't have workTimeZone */}
          {selectedPractitionerStartDateTime && selectedPractitionerEndDateTime && (
            <div className={styles.staffTimezone}>
              For <strong>{selectedPractitioner?.name}</strong> the time will be{' '}
              <strong>
                {selectedPractitionerStartDateTime.isSame(selectedPractitionerEndDateTime, 'date')
                  ? `${selectedPractitionerStartDateTime.format(
                      'dddd, Do MMMM HH:mm'
                    )} - ${selectedPractitionerEndDateTime.format('HH:mm')}`
                  : `${selectedPractitionerStartDateTime.format('dddd, Do MMMM HH:mm')} -
                  ${selectedPractitionerEndDateTime.format('dddd, Do MMMM HH:mm')}`}
              </strong>
            </div>
          )}
        </div>
      </div>
      {inlineCalendarValidation && isSlotNotSelected && (
        <div className={styles.errorMessage}>Please select timeslot</div>
      )}
    </div>
  );
};

export default AppointmentDateAndTime;
