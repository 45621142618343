import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  SegmentedGroup,
  SegmentedItem
} from '@mobiscroll/react';
import classNames from 'classnames';
import styles from './CalendarHeader.module.scss';
import './MobiscrollCustom.scss';
import TimeZoneLabel from 'components/TimeZoneLabel/TimeZoneLabel';
import CheckBox from 'components/CheckBox/CheckBox';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

type CalendarHeaderProps = {
  view: string;
  onViewChange: Function;
  is2xZoom: boolean;
  setIs2xZoom: (v: boolean) => void;
  showZoomButton: boolean;
  isShowWorkingSchedule: boolean;
  enableWorkingScheduleView: boolean;
  setIsShowWorkingSchedule: (v: boolean) => void;
  isShowCancelledAppointment: boolean;
  setIsShowCancelledAppointment: (v: boolean) => void;
};

const CalendarHeader = ({
  view,
  onViewChange,
  is2xZoom,
  setIs2xZoom,
  isShowWorkingSchedule,
  setIsShowWorkingSchedule,
  enableWorkingScheduleView,
  isShowCancelledAppointment,
  setIsShowCancelledAppointment
}: CalendarHeaderProps) => {
  const { isEdgeUser } = useGetAccountPackageView();

  const onChange = (event: any) => {
    onViewChange(event.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.nav} id={'calendarNavId'}>
        <CalendarNav className="cal-header-nav" />
        <div className="cal-header-picker">
          <SegmentedGroup value={view} onChange={onChange} className={'i0000000i'}>
            <SegmentedItem value="week" className="cal-header-view-mode">
              Week
            </SegmentedItem>
            <SegmentedItem value="day" className="cal-header-view-mode">
              Day
            </SegmentedItem>
          </SegmentedGroup>
        </div>
        <CalendarPrev className="cal-header-prev" />
        <CalendarToday className="cal-header-today" />
        <CalendarNext className="cal-header-next" />
      </div>
      <div className={styles.zooms}>
        <label className={classNames(styles.switchToggle, is2xZoom && styles.isChecked)} htmlFor="switch-view-type">
          <span className={styles.label}>
            <i className="material-icons-outlined">zoom_in</i> 1x
          </span>
          <input
            id="switch-view-type"
            name="switch-view-type"
            type="checkbox"
            checked={is2xZoom}
            onChange={(e) => setIs2xZoom(e.target.checked)}
          />
          <span className={styles.indicator} />
          <span className={styles.labelChecked}>
            <i className="material-icons-outlined">zoom_in</i>2x
          </span>
        </label>
      </div>
      {isEdgeUser && (
        <div className={styles.toggleViewsContainer}>
          <CheckBox
            id="toggle-show-ws"
            className={styles.checkbox}
            value={isShowWorkingSchedule}
            disabled={!enableWorkingScheduleView}
            onChange={(ev) => {
              setIsShowWorkingSchedule(ev.target.checked);
            }}
          />
          <label htmlFor="toggle-show-ws" className={styles.toogleViewLabel}>
            Show Working Schedule
          </label>
          <CheckBox
            id="toggle-show-cancelled"
            className={styles.checkbox}
            value={isShowCancelledAppointment}
            onChange={(ev) => {
              setIsShowCancelledAppointment(ev.target.checked);
            }}
          />
          <label htmlFor="toggle-show-cancelled" className={styles.toogleViewLabel}>
            Show Cancelled
          </label>
        </div>
      )}
      <div className={styles.spacer} />
      <TimeZoneLabel />
    </div>
  );
};

export default CalendarHeader;
