import { useField } from 'formik';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import MaterialInput, { MaterialInputProps } from './MaterialInput';

interface FormikMaterialInputProps extends Omit<MaterialInputProps, 'hasError'> {
  inputClass?: string;
  name: string;
}

const FormikMaterialInput = ({ className, inputClass, name, id, ...props }: FormikMaterialInputProps) => {
  const [field, { error, touched }] = useField(name);

  return (
    <div className={className}>
      <MaterialInput {...props} {...field} className={inputClass} id={id} />
      <ErrorMessage error={error} visible={touched} />
    </div>
  );
};

export default FormikMaterialInput;
