import styles from './PackageBenefitControl.module.scss';
import {
  FunderLimitType,
  PackagesBenefit,
  PackagesBenefitLimit,
  PackagesInterface
} from 'interfaces/Packages/packages';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import { convertCurrencyValue, convertOnlyNumberValue, sanitizedCurrencyValue } from 'utils/currencyFormatConverter';
import { ChangeEvent, useCallback, useMemo } from 'react';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import FunderBadge from '../../../FunderBadge/FunderBadge';
import { config } from 'config/config';

interface PackageBenefitControlProps {
  packageData: PackagesInterface;
  onChangePackageData: (value: PackagesInterface) => void;
  checkValidation: boolean;
}

export const PackageBenefitControl = ({
  packageData,
  onChangePackageData,
  checkValidation
}: PackageBenefitControlProps) => {
  const { currencySymbol } = config;
  const handleBudget = (value: string, id?: string) => {
    onChangePackageData({
      ...packageData,
      benefitControl: {
        ...packageData.benefitControl,
        limitMode: packageData.benefitControl?.limitMode,
        ...(packageData.benefitControl.by === PackagesBenefit.Funder &&
        packageData.benefitControl.funderLimitType === FunderLimitType.PerFunder
          ? {
              perFunderLimit: packageData.benefitControl?.perFunderLimit?.map((limitObj) => ({
                ...limitObj,
                limit:
                  limitObj.funderId === id
                    ? packageData.benefitControl.limitMode === PackagesBenefitLimit.DollarSpent
                      ? value
                      : parseInt(value, 10) || 0
                    : limitObj.limit
              }))
            }
          : {
              limit:
                packageData.benefitControl.limitMode === PackagesBenefitLimit.DollarSpent
                  ? value
                  : parseInt(value, 10) || 0
            })
      }
    });
  };

  const FUNDER_LIMIT_OPTION = useMemo(
    () => [
      {
        id: FunderLimitType.Uniform,
        label: 'Uniform',
        isActive: packageData.benefitControl.funderLimitType === FunderLimitType.Uniform
      },
      {
        id: FunderLimitType.PerFunder,
        label: 'Per Funder',
        isActive: packageData.benefitControl.funderLimitType === FunderLimitType.PerFunder
      }
    ],
    [packageData.benefitControl.funderLimitType]
  );

  const handleChangeInfo = useCallback(
    (value: FunderLimitType) => {
      onChangePackageData({
        ...packageData,
        benefitControl: {
          ...packageData.benefitControl,
          funderLimitType: value,
          ...(packageData.benefitControl?.perFunderLimit &&
          packageData.benefitControl?.perFunderLimit?.length > 0 &&
          packageData.funders.length > 0 &&
          packageData.benefitControl.by === PackagesBenefit.Funder &&
          value === FunderLimitType.PerFunder
            ? {
                perFunderLimit: packageData.funders.map((funderObj) => ({
                  ...funderObj,
                  funderId: funderObj.funderId,
                  limit:
                    packageData.benefitControl?.perFunderLimit?.find(
                      (limitObj) => limitObj.funderId === funderObj.funderId
                    )?.limit || 0
                }))
              }
            : packageData.benefitControl?.limit && {
                limit: packageData.benefitControl?.limit || 0
              })
        }
      });
    },
    [onChangePackageData, packageData]
  );

  const getLimitTypeLabel = useMemo(
    () => (benefitLimitType?: PackagesBenefitLimit, funderLimitType?: FunderLimitType) => {
      switch (benefitLimitType) {
        case PackagesBenefitLimit.TotalServices:
          if (packageData.benefitControl.by === PackagesBenefit.Client) {
            return 'Max number of services';
          } else {
            return funderLimitType === FunderLimitType.Uniform
              ? 'Total number of service per funder'
              : 'Total number of service';
          }
        case PackagesBenefitLimit.NumberOfClients:
          return funderLimitType === FunderLimitType.Uniform
            ? 'Total number of clients per funder'
            : 'Total number of clients';
        case PackagesBenefitLimit.DollarSpent:
          return funderLimitType === FunderLimitType.Uniform ? 'Total budget per funder' : 'Total budget';
        default:
          return '';
      }
    },
    [packageData.benefitControl.by]
  );

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <div className={styles.label}>Set {packageData.benefitControl.by} limits</div>
          <HelpOutLineWithTooltips id={'limit'} desc={'Please choose type of limit you would like to apply'} />
          {packageData.benefitControl.by === PackagesBenefit.Funder && (
            <div className={styles.toggleContainer}>
              <ToggleSwitchV2
                id={'funderLimitOption'}
                toggleList={FUNDER_LIMIT_OPTION}
                onChangeStatus={(value) => handleChangeInfo(value.id as FunderLimitType)}
                isLoading={false}
                className={styles.toggleClass}
                indicatorClassName={styles.indicatorClass}
                activeLabelClassName={styles.activeClass}
                size={'small'}
              />
            </div>
          )}
        </div>
        {packageData.benefitControl.by === PackagesBenefit.Funder &&
        packageData.benefitControl.funderLimitType === FunderLimitType.PerFunder ? (
          packageData.benefitControl?.perFunderLimit?.map((limitObj, index) => (
            <div key={index} className={styles.content}>
              {packageData.benefitControl?.limitMode === PackagesBenefitLimit.DollarSpent ? (
                <div className={styles.budgetWrapper}>
                  {packageData.benefitControl.by === PackagesBenefit.Funder && limitObj.name && (
                    <div className={styles.badgeWrapper}>
                      <FunderBadge funder={limitObj.name} />
                    </div>
                  )}
                  <div className={styles.label}>
                    {getLimitTypeLabel(
                      packageData.benefitControl?.limitMode,
                      packageData.benefitControl.funderLimitType
                    )}
                  </div>
                  <TitleInput
                    prefix={currencySymbol}
                    inputContainer={styles.packageFeeInput}
                    defaultLine
                    inputProps={{
                      maxLength: 18,
                      placeholder: '',
                      value: convertCurrencyValue(limitObj.limit.toString() || ''),
                      onChange: (e: ChangeEvent<HTMLInputElement>) =>
                        handleBudget(sanitizedCurrencyValue(e.target.value), limitObj?.funderId || '')
                    }}
                    error={
                      checkValidation && (limitObj.limit === '' || limitObj.limit?.toString() === '0')
                        ? 'Please enter a valid budget amount'
                        : ''
                    }
                  />
                </div>
              ) : (
                <div className={styles.budgetWrapper}>
                  {packageData.benefitControl.by === PackagesBenefit.Funder && limitObj.name && (
                    <div className={styles.badgeWrapper}>
                      <FunderBadge funder={limitObj.name} />
                    </div>
                  )}
                  <div className={styles.label}>
                    {getLimitTypeLabel(
                      packageData.benefitControl?.limitMode,
                      packageData.benefitControl.funderLimitType
                    )}
                  </div>
                  <TitleInput
                    icon={'tag'}
                    inputContainer={styles.packageFeeInput}
                    defaultLine
                    inputProps={{
                      maxLength: 18,
                      placeholder: '',
                      value: convertOnlyNumberValue(limitObj.limit.toString() || ''),
                      onChange: (e: ChangeEvent<HTMLInputElement>) =>
                        handleBudget(e.target.value, limitObj?.funderId || '')
                    }}
                    error={
                      checkValidation && (limitObj.limit === '' || limitObj.limit?.toString() === '0')
                        ? 'Please enter a valid limit amount'
                        : ''
                    }
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <div className={styles.content}>
            <div className={styles.budgetWrapper}>
              <div className={styles.budgetLabel}>{getLimitTypeLabel(packageData.benefitControl?.limitMode)}</div>
              <TitleInput
                icon={packageData.benefitControl?.limitMode !== PackagesBenefitLimit.DollarSpent ? 'tag' : undefined}
                prefix={
                  packageData.benefitControl?.limitMode === PackagesBenefitLimit.DollarSpent
                    ? currencySymbol
                    : undefined
                }
                inputContainer={styles.packageFeeInput}
                defaultLine
                inputProps={{
                  maxLength: 18,
                  placeholder: '',
                  value: convertOnlyNumberValue(packageData.benefitControl.limit?.toString() || ''),
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    handleBudget(
                      packageData.benefitControl?.limitMode === PackagesBenefitLimit.DollarSpent
                        ? sanitizedCurrencyValue(e.target.value)
                        : e.target.value
                    )
                }}
                error={
                  checkValidation &&
                  (packageData.benefitControl.limit === '' || packageData.benefitControl.limit?.toString() === '0')
                    ? 'Please enter a valid budget amount'
                    : ''
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
