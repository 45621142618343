import classnames from 'classnames';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import { AccessRight } from 'interfaces/Clients/clinician';
import { ChangeEvent, forwardRef, ReactElement, useImperativeHandle, useState } from 'react';
import OptionLabel from '../../../OptionLabel/OptionLabel';
import Radio from '../../../Radio/Radio';
import { PractitionerProfiles } from '../../AddPractitionerModalInterface';
import { PRACTITIONER_DEFAULT_ROLES, PRACTITIONER_ROLES } from './constants';
import styles from './PractitionerForm.module.scss';
import { useGetFeatureToggle } from '../../../../utils/featureToggle/featureToggle';
import { useTranslation } from 'react-i18next';

interface PractitionerFormProps {
  practitionerDetails: PractitionerProfiles['emails'];
  onChangeEmailField: (emails: string[]) => void;
  onChangeEmailValue: (emailVal: string) => void;
  onChangeRole: (role: AccessRight) => void;
}

export type PractitionerFormHandle = {
  validateEmail: (email: string) => boolean;
};

const PractitionerForm = forwardRef<PractitionerFormHandle, PractitionerFormProps>(
  ({ practitionerDetails, onChangeEmailField, onChangeEmailValue, onChangeRole }, ref) => {
    const [emailItems, setEmailItems] = useState(practitionerDetails);
    const [emailFieldValue, setEmailFieldValue] = useState('');
    const [error, setError] = useState('');
    const [selectedRole, setSelectedRole] = useState(PRACTITIONER_ROLES.admin);
    const { isMentorFeatureToggle } = useGetFeatureToggle();

    useImperativeHandle(ref, () => ({
      validateEmail: (email) => validateAndAddEmail(email)
    }));

    const validateAndAddEmail = (email: string) => {
      const value = email.trim();
      const valid = isValid(value);
      if (value && valid) {
        const newEmailList = [...emailItems, value];
        setEmailItems(newEmailList);
        onChangeEmailField(newEmailList);
        setEmailFieldValue('');
        onChangeEmailValue('');
        setError('');
      }
      return valid;
    };

    const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
      if (['Enter', 'Tab', ',', ';', ' '].includes(evt.key)) {
        evt.preventDefault();
        validateAndAddEmail(emailFieldValue);
      }
    };

    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
      setEmailFieldValue(evt.target.value);
      onChangeEmailValue(evt.target.value);
      setError('');
    };

    const handleDelete = (item: any) => {
      const newEmailList = emailItems.filter((i) => i !== item);
      setEmailItems(newEmailList);
      onChangeEmailField(newEmailList);
    };

    const handlePaste = (evt: any) => {
      evt.preventDefault();

      const paste = evt.clipboardData.getData('text');
      const emails = paste.match(/[\w\d._\-#+]+@[\w\d._\-#]+[.][\w\d._\-#]+/g);

      if (emails) {
        const toBeAdded = emails.filter((email: string) => !isInList(email));
        const newEmailList = [...emailItems, ...toBeAdded];

        setEmailItems(newEmailList);
        onChangeEmailField(newEmailList);
      }
    };

    const isInList = (email: string) => {
      return emailItems.includes(email);
    };

    const isEmail = (email: string) => {
      return /^([\w\d._\-#+])+@([\w\d._\-#]+[.][\w\d._\-#]+)+$/.test(email);
    };

    const isValid = (email: string) => {
      if (isInList(email)) {
        setError(`${email} has already been added.`);
        return false;
      } else if (!isEmail(email)) {
        setError(`${email} is not a valid email address.`);
        return false;
      }

      return true;
    };

    const handleRoleChange = (value: AccessRight) => {
      const selected = (Object.keys(PRACTITIONER_ROLES) as Array<keyof typeof PRACTITIONER_ROLES>).find(
        (key) => PRACTITIONER_ROLES[key].value === value
      );
      setSelectedRole(PRACTITIONER_ROLES[selected || PRACTITIONER_DEFAULT_ROLES]);
      onChangeRole(value);
    };

    const [t] = useTranslation();

    const getRoleOptions = () =>
      (Object.keys(PRACTITIONER_ROLES) as Array<keyof typeof PRACTITIONER_ROLES>).reduce((allowedRoleOptions, key) => {
        if (isMentorFeatureToggle || key !== AccessRight.Mentor) {
          allowedRoleOptions.push({
            value: PRACTITIONER_ROLES[key].value,
            label: (
              <OptionLabel
                isSelected={PRACTITIONER_ROLES[key].value === selectedRole.value}
                title={t(PRACTITIONER_ROLES[key].label)}
                desc={t(PRACTITIONER_ROLES[key].description)}
                highlightTitle
              />
            )
          });
        }

        return allowedRoleOptions;
      }, [] as { value: string; label: ReactElement }[]);

    return (
      <div className={styles.container}>
        <div className={styles.roleContainer}>
          <div className={styles.practionerRoleTitle}>
            Select Role
            <HelpOutLineWithTooltips
              id={'practionerRole'}
              desc={
                'Set the access control for each member of your team. You can change the role of any member in your team later in their profile.'
              }
            />
          </div>
          <Radio
            vertical
            name={'practionerRoleOptions'}
            options={getRoleOptions()}
            value={selectedRole.value}
            onChange={(e) => handleRoleChange(e.target.value as AccessRight)}
          />
        </div>

        <div className={styles.label}>Email Address*</div>
        <div className={classnames(styles.fieldContainer, error && styles.fieldError)}>
          <input
            className={styles.emailField}
            value={emailFieldValue}
            placeholder={'Type or paste email addresses and press `Enter`...'}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onPaste={handlePaste}
          />
          {error && <p className={styles.fieldError}>{error}</p>}
        </div>
        {emailItems.length > 0 && (
          <div className={styles.ecWrapper}>
            <div className={styles.ecTitle}>{emailItems.length} email added</div>
            <div className={styles.ecList}>
              {emailItems.map((item) => (
                <div className={styles.emailItem} key={item}>
                  {`${t(selectedRole.label)} - ${item}`}
                  <button type={'button'} className={styles.deleteBtn} onClick={() => handleDelete(item)}>
                    <span className="material-icons-outlined">close</span>
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default PractitionerForm;
