import { Modal } from 'antd';
import styles from './FullScreenModal.module.scss';
import IconButton from 'components/IconButton/IconButton';
import logo from 'assets/images/tacklit-logo-blue.svg';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import classNames from 'classnames';

interface FullScreenModalProps {
  open: boolean;
  title: string;
  subtitle?: string;
  onClose: () => void;
  children: React.ReactNode;
}

const FullScreenModal = ({ open, title, subtitle, onClose, children }: FullScreenModalProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  return (
    <Modal
      open={open}
      closable={false}
      width="100%"
      style={{
        top: 0,
        margin: 0,
        paddingBottom: 0,
        maxWidth: '100vw'
      }}
      wrapClassName={classNames(styles.modalWrap, isEdgeAdminView && 't23-admin-theme')}
      bodyStyle={{
        padding: '0'
      }}
      onCancel={onClose}
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            <IconButton iconName="close" onClick={onClose} />
            <div className={styles.titleWrapper}>
              <div className={styles.title}>{title}</div>
              {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            </div>
          </div>
          <div>
            <div className={styles.logoWrapper}>
              <img src={logo} alt={'logo'} />
            </div>
            <IconButton iconName="logout" onClick={onClose} />
          </div>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </Modal>
  );
};

export default FullScreenModal;
