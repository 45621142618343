import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectSorting, setIsSettingModalOpen, setSorting } from 'redux/clients/clientListSlice';
import { setSetting } from 'redux/clients/clientListColumnsSettingsSlice';
import ClientListHeaderItem from '../ClientListHeaderItem/ClientListHeaderItem';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { Tooltip } from 'antd';
import { SortType } from '../../constants';
import { CLIENT_LIST_SORT_ABLE_COLUMNS } from 'utils/providers/ClientListColumnsProvider/constants';
import { selectSetting } from 'redux/clients/clientListColumnsSettingsSlice';
import { useContext, useEffect } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { UserContext } from 'utils/UserContextProvider';
import { getColumnNameFromId, initColumnSettings } from './helper';
import styles from './ClientListHeader.module.scss';
import columnStyles from '../../ClientListColumn.module.scss';
import classnames from 'classnames';
import { CLIENT_LIST_SORT_INFO } from '../../../../../../Client';

const ClientListHeader = () => {
  const { currentView } = useGetAccountPackageView();
  const dispatch = useAppDispatch();
  const sorting = useAppSelector(selectSorting);
  const columnSetting = useAppSelector(selectSetting);
  const { clinicianProfile } = useContext(UserContext);

  const { sortBy, sortType } = sorting;

  useEffect(() => {
    if (
      Object.keys(columnSetting).length < 1 ||
      columnSetting.id !== clinicianProfile?._id ||
      columnSetting.view !== currentView
    ) {
      const initialSetting = initColumnSettings(clinicianProfile?._id || '', currentView);
      dispatch(setSetting(initialSetting));
    }
  }, [clinicianProfile?._id, currentView, columnSetting, dispatch]);

  const onClickSort = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      const newSort = { ...sorting, sortType: sortType === SortType.ASC ? SortType.DESC : SortType.ASC };
      localStorage.setItem(CLIENT_LIST_SORT_INFO, JSON.stringify(newSort));
      dispatch(setSorting(newSort));
    } else {
      const newSort = { ...sorting, sortBy: newSortBy, sortType: SortType.DESC };
      localStorage.setItem(CLIENT_LIST_SORT_INFO, JSON.stringify(newSort));
      dispatch(setSorting(newSort));
    }
  };

  return (
    <div className={styles.container}>
      {Object.keys(columnSetting).length > 1 && (
        <>
          <ClientListHeaderItem
            columnId={'clientName'}
            className={columnStyles.client}
            sortBy={sortBy}
            sortType={sortType}
            columnName={'Client'}
            sortAble
            onSort={onClickSort}
          />
          {columnSetting.columns
            .filter((column) => column.active)
            .map((column, index) => (
              <ClientListHeaderItem
                key={index}
                columnId={column.id}
                className={columnStyles[column.id]}
                sortBy={sortBy}
                sortType={sortType}
                columnName={getColumnNameFromId(column.id)}
                sortAble={CLIENT_LIST_SORT_ABLE_COLUMNS.includes(column.id)}
                onSort={onClickSort}
              />
            ))}
          <div className={classnames(styles.action, columnStyles.action)}>
            <Tooltip
              align={{ offset: [-20] }}
              title={'Column Settings'}
              overlayClassName={styles.settingButtonTooltip}
              showArrow={false}
              placement={'bottom'}
            >
              <div>
                <ButtonAlt
                  variant={'text'}
                  icon={'settings_suggest'}
                  className={styles.settingButton}
                  onClick={() => dispatch(setIsSettingModalOpen(true))}
                />
              </div>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};

export default ClientListHeader;
