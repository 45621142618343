import { createSlice } from '@reduxjs/toolkit';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { FormStatusDaysBefore, SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { ClientStatus, SortType } from 'pages/Client/components/ClientListContent/components/ClientList/constants';
import { RootState } from 'redux/store';
import { CLIENT_LIST_SORT_INFO } from 'pages/Client/Client';

export interface ClientListSorting {
  sortBy: string;
  sortType: SortType;
}

export interface ClientListPaging {
  page: number;
  perPage: number;
}

export interface ClientListFilters {
  recordStatus: ClientStatus;
  selectedPractitioners: FilterCheckListItem[];
  selectedGroups: FilterCheckListItem[];
  selectedReferralSources: FilterCheckListItem[];
  selectedReferralStatuses: FilterCheckListItem[];
  selectedProfileTypes: FilterCheckListItem[];
  selectedFormsStatus: FilterCheckListItem[];
  selectedFormsTimeBound: FormStatusDaysBefore;
}

export interface ClientListSearch {
  searchValue: string;
  searchBy: SearchFilterEnum;
}

interface ClientListState {
  isSettingModalOpen: boolean;
  search: ClientListSearch;
  sorting: ClientListSorting;
  paging: ClientListPaging;
  filters: ClientListFilters;
}

const getPrevClientListSort = localStorage.getItem(CLIENT_LIST_SORT_INFO);
const clientListSort: ClientListSorting = getPrevClientListSort ? JSON.parse(getPrevClientListSort) : {};

export const initialState: ClientListState = {
  isSettingModalOpen: false,
  search: {
    searchValue: '',
    searchBy: SearchFilterEnum.FirstOrLastName
  },
  sorting: {
    sortBy: clientListSort?.sortBy || 'clientSince',
    sortType: clientListSort?.sortType || SortType.DESC
  },
  paging: {
    page: 1,
    perPage: 10
  },
  filters: {
    recordStatus: ClientStatus.ACTIVE,
    selectedPractitioners: [],
    selectedGroups: [],
    selectedReferralSources: [],
    selectedReferralStatuses: [],
    selectedProfileTypes: [],
    selectedFormsStatus: [],
    selectedFormsTimeBound: FormStatusDaysBefore.All
  }
};

export const clientListSlice = createSlice({
  name: 'clientListSlice',
  initialState,
  reducers: {
    setIsSettingModalOpen: (state, action) => {
      state.isSettingModalOpen = action.payload;
    },

    setSearch: (state, action) => {
      state.search = action.payload;
    },

    setSorting: (state, action) => {
      state.sorting = action.payload;
    },

    setPaging: (state, action) => {
      state.paging = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetPaging: (state) => {
      state.paging = initialState.paging;
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },

    resetSorting: (state) => {
      state.sorting = initialState.sorting;
    },

    resetSearch: (state) => {
      state.search = initialState.search;
    },

    resetAll: (state) => {
      state.search = initialState.search;
      state.paging = initialState.paging;
      state.sorting = initialState.sorting;
      state.filters = initialState.filters;
    },

    resetPagingAndSorting: (state) => {
      state.paging = initialState.paging;
      state.sorting = initialState.sorting;
    }
  }
});

export const selectSearch = (state: RootState) => state.clientListSlice.search;
export const selectSorting = (state: RootState) => state.clientListSlice.sorting;
export const selectPaging = (state: RootState) => state.clientListSlice.paging;
export const selectFilters = (state: RootState) => state.clientListSlice.filters;
export const selectIsSettingModalOpen = (state: RootState) => state.clientListSlice.isSettingModalOpen;

export const {
  setSearch,
  setSorting,
  setPaging,
  setFilters,
  resetPaging,
  resetFilters,
  resetSearch,
  resetSorting,
  resetAll,
  resetPagingAndSorting,
  setIsSettingModalOpen
} = clientListSlice.actions;

export default clientListSlice.reducer;
