import classNames from 'classnames';
import { ThreeDotMenuHandle } from 'components/T23/ThreeDotMenu/ThreeDotMenu';
import { AddressBook, AddressBookFromAPI } from 'pages/AddressBook/Interfaces/AddressBook';
import { RefObject, useRef, useState } from 'react';
import { renderCellValue } from '../../../constants';
import columnStyles from '../../AddressBookTable.module.scss';
import AddressBookContactTable from '../AddressBookContactTable/AddressBookContactTable';
import styles from './AddressBookItem.module.scss';

interface AddressBookItemProps {
  addressBook: AddressBookFromAPI;
  columns: {
    label: string;
    field: string;
    className: string;
    cell?: (
      prop: AddressBookFromAPI,
      options: { expand: boolean; setExpand: (value: boolean) => void; menuRef: RefObject<ThreeDotMenuHandle> }
    ) => JSX.Element | string;
  }[];
  onAddContact?: (value: AddressBookFromAPI) => void;
}

const AddressBookItem = ({ addressBook, columns, onAddContact }: AddressBookItemProps) => {
  const menuRef = useRef<ThreeDotMenuHandle>(null);
  const [expand, setExpand] = useState(false);

  return (
    <div className={classNames(styles.container, expand && styles.expandingRow)}>
      <div className={styles.rowContainer}>
        {columns.map((row, index) => (
          <div key={index} className={classNames(styles.row, columnStyles[row.className])}>
            {row.cell
              ? row.cell(addressBook, { expand, setExpand, menuRef })
              : renderCellValue(addressBook[row.field as keyof AddressBook])}
          </div>
        ))}
      </div>
      {expand && (
        <div className={styles.contactRowContainer}>
          <AddressBookContactTable
            type={addressBook.type}
            contacts={addressBook.contacts}
            onAddContact={() => onAddContact && onAddContact(addressBook)}
          />
        </div>
      )}
    </div>
  );
};

export default AddressBookItem;
