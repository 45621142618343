import i18n from 'i18n';
import moment from 'moment';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import * as yup from 'yup';

import { MedicareFormData } from './initValue';

export interface MedicareFormErrors {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  number: string;
  irn: string;
  dva: string;
  expiryDate: string;
  parentFirstName: string;
  parentLastName: string;
  parentDateOfBirth: string;
  parentIrn: string;
}

const InitialMedicareFormErrors: MedicareFormErrors = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  number: '',
  irn: '',
  dva: '',
  expiryDate: '',
  parentFirstName: '',
  parentLastName: '',
  parentDateOfBirth: '',
  parentIrn: ''
};

const isValidDob = (dob: string) => {
  return moment(dob, MOMENTJS_DATE_FORMAT).format(MOMENTJS_DATE_FORMAT) === dob;
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Please enter the client's first name"),
  lastName: yup.string().required("Please enter the client's last name"),
  dateOfBirth: yup
    .string()
    .required("Please enter the client's date of birth")
    .test('validateDoB', i18n.t('validation.dob.invalid'), (value) => {
      if (value && value?.length > 0) {
        return isValidDob(value);
      } else {
        return true;
      }
    }),
  number: yup
    .string()
    .test(
      'validateMedicareNumberMinLength',
      "The client's Medicare number must be at least 10 digits long",
      (value) => !value || value.length >= 10
    )
    .test('validateMedicareNumber', 'Please enter numbers only', (value) => !isNaN(Number(value)))
    .nullable(),
  irn: yup.string().when('number', {
    is: (number: string) => number,
    then: yup
      .string()
      .required("Please enter the client's Individual Reference Number(IRN)")
      .test('validateIRN', 'Please enter numbers only', (value) => !isNaN(Number(value))),
    otherwise: yup.string().nullable()
  }),
  dva: yup.string().nullable(),
  parentFirstName: yup.string().when('parentIrn', {
    is: (parentIrn: string) => parentIrn,
    then: yup.string().required("Please enter the parent's first name"),
    otherwise: yup.string().nullable()
  }),
  parentLastName: yup.string().when('parentIrn', {
    is: (parentIrn: string) => parentIrn,
    then: yup.string().required("Please enter the parent's last name"),
    otherwise: yup.string().nullable()
  }),
  parentDateOfBirth: yup.string().when('parentIrn', {
    is: (parentIrn: string) => parentIrn,
    then: yup
      .string()
      .required("Please enter the parent's date of birth")
      .test('validateParentDoB', i18n.t('validation.dob.invalid'), (value) => {
        if (value && value.length > 0) {
          return isValidDob(value);
        } else {
          return true;
        }
      }),
    otherwise: yup.string().nullable()
  })
});

export const validateMedicareForm = (formData: MedicareFormData) => {
  const errors = { ...InitialMedicareFormErrors };
  try {
    validationSchema.validateSync(formData, { abortEarly: false });

    // Additional validation, can not add in to Yup validation - will cause an error 'Cyclic dependency'
    if ((formData.irn || formData.parentIrn) && !formData.number) {
      errors.number = "Please enter the client's Medicare number";
    }
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(errors).includes(error.path)) {
          errors[error.path as keyof MedicareFormErrors] = error.message;
        }
      });
    }
  }

  return errors;
};
