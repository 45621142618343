import { notification } from 'antd';
import classnames from 'classnames';
import queryString from 'query-string';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import FlexBox from 'components/FlexBox/FlexBox';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { AppointmentClaimType, AppointmentSlots, ServiceDelivered } from 'interfaces/Schedule/Appointment';
import { ClaimType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { AppointmentType } from 'interfaces/Schedule/AppointmentType';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectProcessAppointment,
  setProcessAppointment,
  setFormError,
  setIsPrepareClaimOpen,
  selectIsPrepareClaimOpen,
  selectFormError,
  selectIsCancel
} from 'redux/processAppointment/processAppointmentSlice';
import { useGetAppointmentTypesForInlineAppointmentQuery } from 'redux/endpoints/scheduleServices/appointmentType';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import AppointmentBasicDetails from './components/AppointmentBasicDetails/AppointmentBasicDetails';
import AppointmentMoreDetails from './components/AppointmentMoreDetails/AppointmentMoreDetails';
import AppointmentClaimDetails from './components/AppointmentClaimDetails/AppointmentClaimDetails';
import AppointmentInvoiceDetails from './components/AppointmentInvoiceDetails/AppointmentInvoiceDetails';
import styles from './ProcessAppointment.module.scss';
import {
  useProcessAppointmentMutation,
  useReviewAppointmentMutation
} from 'redux/endpoints/scheduleServices/processAppointment';
import { mbsItemLookup } from 'pages/InvoiceTemplate/helpers/helpers';
import { useGetClinicianProfileByIdQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import moment from 'moment';
import { TIME_FORMAT } from '../../../FinderWizardModal/constants';
import { useGetClinicianMedicareProviderQuery } from 'redux/endpoints/billingServices/clinicianMedicareProvider';
import { CancelAppointmentModal } from '../EventDetails/components/CancelAppointmentModal/CancelAppointmentModal';
import { MBS_CODE_FULL_LIST } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/mbsItems';
import { practiceInfo } from 'redux/slice/practiceDataSlice';

interface ProcessAppointmentProps {
  appointment: AppointmentSlots;
  appointmentType?: AppointmentType;
  onClose?: () => void;
  profile?: ProfileInterface;
  onProcessAppointmentComplete: (isCancel: boolean) => void;
  mode?: 'process' | 'review';
}

const ProcessAppointment = ({
  appointment,
  appointmentType,
  onClose,
  profile,
  onProcessAppointmentComplete,
  mode = 'process'
}: ProcessAppointmentProps) => {
  const dispatch = useAppDispatch();
  const { isEdgeAdminUser, isEdgeAdminView, isNormalUserView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { data: practiceData } = useAppSelector(practiceInfo);

  const { search } = useLocation();
  const { serviceDelivered }: { serviceDelivered?: string } = queryString.parse(search);
  const modalNode = useRef<HTMLDivElement>(null);

  const [isCancelRecurring, setIsCancelRecurring] = useState(false);
  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');

  const isPrepareClaimOpen = useAppSelector(selectIsPrepareClaimOpen);
  const processAppointment = useAppSelector(selectProcessAppointment);
  const formError = useAppSelector(selectFormError);
  const isCancel = useAppSelector(selectIsCancel);
  const {
    selectedClient,
    selectedAppointmentType,
    selectedDeliveryMode,
    selectedServiceDelivered,
    selectedPractitioner,
    selectedProviderNumber,
    selectedClaimType,
    selectedMbsCode,
    waiveCancellationFee,
    reviewNote,
    cancellationNote,
    useServiceFee
  } = processAppointment;

  const [processAppointmentAction] = useProcessAppointmentMutation();
  const [reviewAppointmentAction] = useReviewAppointmentMutation();

  const {
    data: providers,
    isLoading: isProvidersLoading,
    isFetching: isProvidersFetching
  } = useGetClinicianMedicareProviderQuery({
    isAdmin: isEdgeAdminView || isEdgeReceptionistView,
    clinicianId: appointment.clinicianId,
    activeOnly: true
  });

  const {
    data: appointmentTypesData,
    isLoading: isAppointmentTypesDataLoading,
    isFetching: isAppointmentTypesDataFetching
  } = useGetAppointmentTypesForInlineAppointmentQuery({
    isAdmin: isEdgeAdminUser || isEdgeReceptionistView || isNormalUserView,
    clinicianId: appointment.clinicianId || '',
    participantType: ParticipantType.OneToOne // 1:1 for now, extend for Group later
  });

  const {
    data: appointmentClinicianProfile,
    isLoading: isAppointmentClinicianProfileLoading,
    isFetching: isAppointmentClinicianProfileFetching
  } = useGetClinicianProfileByIdQuery(
    { clinicianId: appointment.clinicianId || '' },
    { skip: !appointment.clinicianId }
  );

  const foundMbsItem = useMemo(() => {
    return !isAppointmentClinicianProfileLoading &&
      !isAppointmentClinicianProfileFetching &&
      appointment.deliveryType &&
      appointment.startTime &&
      appointment.endTime
      ? mbsItemLookup({
          deliveryType: appointment.deliveryType,
          duration: moment(appointment.endTime, TIME_FORMAT).diff(
            moment(appointment.startTime, TIME_FORMAT),
            'minutes'
          ),
          clinicianRole: appointmentClinicianProfile?.medicare?.role
        })
      : undefined;
  }, [
    appointmentClinicianProfile,
    isAppointmentClinicianProfileFetching,
    isAppointmentClinicianProfileLoading,
    appointment
  ]);

  const isFutureAppointment = useMemo(
    () => moment(appointment.startDateTime).isAfter(moment()),
    [appointment.startDateTime]
  );

  // Auto fill MBS code
  useEffect(() => {
    dispatch(
      setProcessAppointment({
        selectedMbsCode: foundMbsItem?.mbsCode || '',
        useServiceFee: !!foundMbsItem?.mbsCode && appointment?.claimType === AppointmentClaimType.BulkBill
      })
    );
  }, [foundMbsItem, dispatch, appointment?.claimType]);

  useEffect(() => {
    if (appointment) {
      const defaultProvider = providers?.length
        ? providers.find((option) => option.default)?.providerNumber || providers[0].providerNumber
        : undefined;

      const { deliveryType, funder, waiveCancellationFee, reviewNote, mbsCode, providerId, invoiceAmount } =
        appointment.requestedChanges || {};
      dispatch(
        setProcessAppointment({
          selectedClient: appointment.clientRecord?.clientProfiles?.find((target) => target.isPrimaryContact),
          selectedAppointmentType: appointmentType, // Already handle from parent component
          selectedDeliveryMode: deliveryType || appointment.deliveryType,
          selectedServiceDelivered:
            serviceDelivered ||
            appointment.serviceDelivered ||
            (isCancel ? ServiceDelivered.CancelShortNotice : ServiceDelivered.Attended),
          selectedPractitioner: profile || { _id: '', ...practiceData },
          selectedProviderNumber: providerId || defaultProvider,
          selectedClaimType:
            funder ||
            (appointmentType?.claimType && [ClaimType.BULK_BILL, ClaimType.REBATE].includes(appointmentType.claimType)
              ? appointment.claimType
              : undefined),
          ...(waiveCancellationFee && {
            waiveCancellationFee
          }),
          ...(mbsCode && {
            selectedMbsCode: mbsCode
          }),
          ...(invoiceAmount !== undefined && { useServiceFee: true }),
          reviewNote,
          ...(appointment.requestedChanges?.cancellationNote && {
            cancellationNote: appointment.requestedChanges?.cancellationNote
          })
        })
      );

      if (appointmentType?.claimType && [ClaimType.BULK_BILL, ClaimType.REBATE].includes(appointmentType.claimType)) {
        !isCancel && dispatch(setIsPrepareClaimOpen(true));
      }
    }
  }, [appointment, appointmentType, dispatch, practiceData, profile, providers, serviceDelivered, isCancel]);

  const needReviewNote = useMemo(() => {
    return (
      mode === 'process' &&
      (selectedAppointmentType?._id !== appointmentType?._id ||
        selectedDeliveryMode !== appointment.deliveryType ||
        (selectedServiceDelivered === ServiceDelivered.Attended &&
          selectedClaimType !== appointment.claimType &&
          appointment.claimType &&
          [AppointmentClaimType.BulkBill, AppointmentClaimType.Rebate].includes(appointment.claimType)) ||
        waiveCancellationFee)
    );
  }, [
    appointment.claimType,
    appointment.deliveryType,
    appointmentType?._id,
    selectedAppointmentType?._id,
    selectedClaimType,
    selectedDeliveryMode,
    selectedServiceDelivered,
    waiveCancellationFee,
    mode
  ]);

  const isSpecialAppointment = useMemo(() => {
    return (
      !appointment.claimType ||
      ![AppointmentClaimType.BulkBill, AppointmentClaimType.Rebate, AppointmentClaimType.OutOfPocket].includes(
        appointment.claimType
      )
    );
  }, [appointment.claimType]);

  const validateForm = async () => {
    const { selectedClaimType, selectedMbsCode } = processAppointment;

    if (
      !selectedClient ||
      !selectedAppointmentType ||
      !selectedDeliveryMode ||
      !selectedServiceDelivered ||
      !selectedPractitioner ||
      (isPrepareClaimOpen && (!selectedClaimType || !selectedMbsCode)) ||
      (isCancel && isFutureAppointment && !cancellationNote)
    ) {
      return false;
    }
    return !(needReviewNote && mode === 'process' && !reviewNote);
  };

  const cancellationWithRefund = useMemo(
    () =>
      selectedServiceDelivered &&
      [
        ServiceDelivered.ClinicianDidNotAttend,
        ServiceDelivered.CancelWithNotice,
        ServiceDelivered.ClinicianCancelWithNotice,
        ServiceDelivered.ClinicianCancelShortNotice
      ].includes(selectedServiceDelivered) &&
      appointment.paymentRequestIds &&
      appointment.paymentRequestIds.length > 0,
    [selectedServiceDelivered, appointment.paymentRequestIds]
  );

  const handleFormError = () => {
    dispatch(
      setFormError({
        selectedClient: !selectedClient,
        selectedAppointmentType: !selectedAppointmentType,
        selectedDeliveryMode: !selectedDeliveryMode,
        selectedServiceDelivered: !selectedServiceDelivered,
        selectedPractitioner: !selectedPractitioner,
        selectedClaimType: isPrepareClaimOpen && !selectedClaimType,
        selectedMbsCode: isPrepareClaimOpen && !selectedMbsCode,
        reviewNote: needReviewNote && mode === 'process' && !reviewNote,
        cancellationNote: isCancel && !cancellationNote
      })
    );
  };

  const handleProcessAppointment = async (isCancelRecurringAppointments?: boolean) => {
    try {
      const payload = {
        serviceDelivered: selectedServiceDelivered,
        appointmentTypeId: selectedAppointmentType?._id,
        deliveryType: selectedDeliveryMode,
        asAdmin: isEdgeAdminView,
        providerId: selectedProviderNumber,
        funder: selectedClaimType,
        mbsCode: selectedMbsCode,
        waiveCancellationFee,
        ...(isCancelRecurringAppointments && {
          includeRecurringAppointments: true
        }),
        ...(cancellationNote && {
          cancellationNote
        }),
        ...(useServiceFee &&
          selectedMbsCode && {
            invoiceAmount: MBS_CODE_FULL_LIST.find(({ mbsCode }) => mbsCode === selectedMbsCode)?.benefit
          })
      };

      try {
        setSubmitButtonStatus('active');
        await processAppointmentAction({ appointmentId: appointment?._id || '', payload }).unwrap();
        notification.success({
          message: 'Appointment processed successfully',
          duration: 2
        });
        setSubmitButtonStatus('finished');
        setTimeout(() => {
          setSubmitButtonStatus('');
          onProcessAppointmentComplete(
            !!selectedServiceDelivered &&
              [
                ServiceDelivered.ClinicianDidNotAttend,
                ServiceDelivered.CancelWithNotice,
                ServiceDelivered.CancelShortNotice
              ].includes(selectedServiceDelivered)
          );
        }, 2000);
      } catch (ex) {
        notification.error({
          message: 'Something went wrong while trying to process appointment',
          duration: 2
        });
        setSubmitButtonStatus('');
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const handleSubmitForReview = async () => {
    try {
      const payload = {
        serviceDelivered: selectedServiceDelivered,
        appointmentTypeId: selectedAppointmentType?._id,
        deliveryType: selectedDeliveryMode,
        asAdmin: isEdgeAdminView,
        providerId: selectedProviderNumber,
        funder: selectedClaimType,
        mbsCode: selectedMbsCode,
        waiveCancellationFee,
        reviewNote,
        cancellationNote,
        cancellationWithRefund,
        ...(useServiceFee &&
          selectedMbsCode && {
            invoiceAmount: MBS_CODE_FULL_LIST.find(({ mbsCode }) => mbsCode === selectedMbsCode)?.benefit
          })
      };

      try {
        setSubmitButtonStatus('active');
        await reviewAppointmentAction({ appointmentId: appointment?._id || '', payload }).unwrap();
        notification.success({
          message: 'Appointment submitted for review successfully',
          duration: 2
        });
        setSubmitButtonStatus('finished');
        setTimeout(() => {
          setSubmitButtonStatus('');
          onProcessAppointmentComplete(false);
        }, 2000);
      } catch (ex) {
        notification.error({
          message: 'Something went wrong while trying to submit for review',
          duration: 2
        });
        setSubmitButtonStatus('');
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const handleSetReviewNote = (value: string) => {
    dispatch(setProcessAppointment({ reviewNote: value }));

    // Reset error
    if (formError.reviewNote) {
      dispatch(
        setFormError({
          reviewNote: false
        })
      );
    }
  };

  const handleSetCancellationNote = (value: string) => {
    dispatch(setProcessAppointment({ cancellationNote: value }));

    // Reset error
    if (formError.cancellationNote) {
      dispatch(
        setFormError({
          cancellationNote: false
        })
      );
    }
  };

  const handleCancelAppointment = (includeRecurringAppointments: boolean) => {
    setIsCancelRecurring(false);
    handleProcessAppointment(includeRecurringAppointments);
  };

  const handleConfirmButtonClick = async () => {
    const isValid = await validateForm();
    if (isValid) {
      if (
        needReviewNote ||
        cancellationWithRefund ||
        (isSpecialAppointment && selectedServiceDelivered !== ServiceDelivered.CancelWithNotice)
      ) {
        handleSubmitForReview();
      } else if (isCancel && appointment.recurringAppointmentId) {
        setIsCancelRecurring(true);
      } else {
        handleProcessAppointment();
      }
    } else {
      handleFormError();
    }
  };

  if (isProvidersLoading || isProvidersFetching || isAppointmentTypesDataLoading || isAppointmentTypesDataFetching) {
    return (
      <div className={classnames(styles.container, styles.loading)}>
        <LoadingCircle />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <FlexBox direction="column" justifyContent="space-between" spacing={16}>
        <FlexBox direction="column" spacing={16}>
          <FlexBox direction="row" justifyContent="space-between">
            <FlexBox direction="column" spacing={4}>
              <span className={styles.title}>{isCancel ? 'CANCEL APPOINTMENT' : 'PROCESS APPOINTMENT'}</span>
              <span className={styles.subtitle}>ApptID: {appointment._id}</span>
            </FlexBox>
            <button onClick={onClose} className={styles.closeButton}>
              <span className="material-icons-outlined">close</span>
            </button>
          </FlexBox>
          <AppointmentBasicDetails
            appointmentTypes={appointmentTypesData?.data || []}
            medicareRole={appointmentClinicianProfile?.medicare?.role}
          />
          <AppointmentMoreDetails providers={providers || []} />
        </FlexBox>

        {/* Cancellation message */}
        {isCancel && isFutureAppointment && (
          <FlexBox direction="row" alignItems="flex-end" justifyContent="flex-end" spacing={8}>
            <textarea
              value={cancellationNote}
              className={formError.cancellationNote ? styles.cancellationNoteError : styles.cancellationNote}
              onChange={(e) => handleSetCancellationNote(e.target.value)}
              placeholder="Cancellation message for client..."
            />
          </FlexBox>
        )}

        <AppointmentInvoiceDetails />
        <AppointmentClaimDetails />
        {needReviewNote &&
          selectedAppointmentType?.claimType &&
          selectedClaimType &&
          selectedClaimType !== selectedAppointmentType.claimType.toString() && (
            <div className={styles.changeClaimTypeInfo}>
              <i className="material-icons-outlined">info</i>
              You’re suggesting a code type that is different from the appointment information. This will flag for the
              admin team to review before the claim is submitted.
            </div>
          )}
        <FlexBox direction="row" alignItems="flex-end" justifyContent="flex-end" spacing={8}>
          {needReviewNote ? (
            <textarea
              value={reviewNote}
              className={formError.reviewNote ? styles.reviewNoteError : styles.reviewNote}
              onChange={(e) => handleSetReviewNote(e.target.value)}
              placeholder="Note for review"
            />
          ) : (
            <span className={styles.warning}>After processing this appointment data not be amended</span>
          )}
          <ButtonAlt
            loadingWord="Processing..."
            completedWord="Done"
            status={submitButtonStatus}
            onClick={handleConfirmButtonClick}
          >
            Confirm
          </ButtonAlt>
        </FlexBox>
      </FlexBox>
      {isCancelRecurring && (
        <CancelAppointmentModal
          modalNode={modalNode}
          date={appointment.date}
          endTime={appointment.endTime}
          startTime={appointment.startTime}
          isOpen={isCancelRecurring}
          onClose={() => setIsCancelRecurring(false)}
          handleCancelAppointment={handleCancelAppointment}
          handleAbortCancelRecurringAppointments={() => setIsCancelRecurring(false)}
        />
      )}
    </div>
  );
};

export default ProcessAppointment;
