import { useState } from 'react';
import styles from './MemberFilter.module.scss';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { DaySelection, FilterStatus, MentorAppointmentFilter } from 'pages/Mentor/components/Members/constants';
import MentorTimeSelection from '../../../MemberFilter/components/MentorTimeSelection';

interface MemberFilterProps {
  filterStatus: FilterStatus;
  selectedDay: DaySelection;
  onChangeFilter: (value: MentorAppointmentFilter) => void;
  dayList: DaySelection[];
  isLoading: boolean;
  totalAppointment: number;
}

const MemberFilter = ({
  filterStatus,
  selectedDay,
  onChangeFilter,
  dayList,
  isLoading,
  totalAppointment
}: MemberFilterProps) => {
  const [filterStatusState, setFilterStatusState] = useState<FilterStatus>(filterStatus);
  const [selectedTimeState, setSelectedTimeState] = useState(selectedDay);

  const onChangeFilterStatus = () => {
    const newFilterStatus = filterStatus === FilterStatus.UPCOMING ? FilterStatus.PAST : FilterStatus.UPCOMING;
    setFilterStatusState(newFilterStatus);
    onChangeFilter({ filterStatus: newFilterStatus, selectedDay: selectedDay });
  };

  const onChangeDaySelection = (newSelectedDay: DaySelection) => {
    onChangeFilter({ filterStatus: filterStatusState, selectedDay: newSelectedDay });
    setSelectedTimeState(newSelectedDay);
  };

  return (
    <div className={styles.container}>
      <div className={styles.switchWrapper}>
        <ToggleSwitch
          id={'memberStatus'}
          checkLabel={'UPCOMING'}
          unCheckLabel={'PAST'}
          buttonWidth={253}
          isCheckedProps={filterStatusState === 'upcoming'}
          onChangeProps={onChangeFilterStatus}
          disabled={isLoading}
        />
      </div>
      <MentorTimeSelection
        filterStatusState={filterStatusState}
        selectedTimeState={selectedTimeState}
        dayList={dayList}
        onChangeTimeSelection={onChangeDaySelection}
        isLoading={isLoading}
        totalAppointment={totalAppointment}
      />
    </div>
  );
};

export default MemberFilter;
