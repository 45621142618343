import FormikInput from 'components/Input/FormikInput';
import FormikRadio from 'components/Radio/FormikRadio';
import SubmitButton from '../SubmitButton/SubmitButton';

import styles from './AboutYou.module.scss';
import { useField } from 'formik';

export const salutationOptions = [
  {
    label: 'Dr',
    value: 'Dr'
  },
  {
    label: 'Mr',
    value: 'Mr'
  },
  {
    label: 'Mrs',
    value: 'Mrs'
  },
  {
    label: 'Miss',
    value: 'Miss'
  },
  {
    label: 'Ms',
    value: 'Ms'
  }
];

interface AboutYouProps {
  disableEmail?: boolean;
}

const AboutYou = ({ disableEmail }: AboutYouProps) => {
  const [{ value: email }] = useField('email');

  return (
    <>
      <span className={styles.title}>Welcome to Tacklit</span>
      <span className={styles.heading}>It will take you a few minutes to create your secure account</span>
      <div className="divider" />
      <span className="sectionHeader">Tell us more about you</span>
      <FormikRadio
        variant="button"
        className={styles.title}
        name="title"
        options={salutationOptions}
        hasOthers
        noSpacing
      />
      <FormikInput className="fullWidth" label="First Name" name="firstName" required />
      <FormikInput className="fullWidth" label="Last Name" name="lastName" required />
      {disableEmail ? (
        <>
          <div className={styles.disabledEmailLabel}>Email</div>
          <div className={styles.disabledEmailValue}>{email}</div>
        </>
      ) : (
        <FormikInput className="fullWidth" label="Email" name="email" noSpacing required />
      )}
      <span className="description">This will be your username to login</span>
      <SubmitButton variant="primary" type="submit">
        Set up account details
      </SubmitButton>
    </>
  );
};

export default AboutYou;
