import styles from './NoCaseNoteDetail.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface NoCaseNoteDetailProps {
  message: string;
  addNoteButtonStatus: '' | 'active' | 'finished';
  isAddNoteButtonDisabled: boolean;
  onClickNewNote: () => void;
}

const NoCaseNoteDetail = ({
  message,
  addNoteButtonStatus,
  isAddNoteButtonDisabled,
  onClickNewNote
}: NoCaseNoteDetailProps) => {
  return (
    <>
      <div className={styles.text}>{message}</div>
      <ButtonAlt
        className={styles.button}
        status={addNoteButtonStatus}
        disabled={isAddNoteButtonDisabled}
        onClick={onClickNewNote}
        icon={'add_circle_outline'}
      >
        Add new note
      </ButtonAlt>
    </>
  );
};

export default NoCaseNoteDetail;
