import { CreateAddressBookContactValidateField } from 'components/AddressBook/CreateAddressBookModal/CreateAddressBookModalInterfaces';
import * as yup from 'yup';
import { CreateAddressBookContactRequestPayload } from '../AddressBookContactForm';

const contactFormSchema = yup.object().shape({
  firstName: yup.string().required('Please enter contact first name')
});

export const validateContactForm = (contact: CreateAddressBookContactRequestPayload) => {
  const validationErrors: CreateAddressBookContactValidateField = {
    firstName: ''
  };
  try {
    contactFormSchema.validateSync(contact, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof CreateAddressBookContactValidateField] = error.message;
        }
      });
      return validationErrors;
    }
  }
};
