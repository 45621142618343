import styles from './ClosedGroupListCard.module.scss';
import columnStyles from './ClosedGroupColumn.module.scss';
import { Groups } from 'pages/Groups/Interfaces/Groups';
import ClosedGroupItem from './components/ClosedGroupListItem';
import ListHeaderItem from 'components/T23/ListHeaderItem/ListHeaderItem';
import { GroupListSorting } from 'redux/groups/groupSlice';

interface ClosedGroupListCardProps {
  groupListing: Groups[];
  searchValue: string;
  sorting: GroupListSorting;
  onSortGroupListByName: () => void;
  onSortGroupListByClientSince: () => void;
}

const ClosedGroupListCard = ({
  groupListing,
  searchValue,
  sorting,
  onSortGroupListByName,
  onSortGroupListByClientSince
}: ClosedGroupListCardProps) => {
  const { sortBy, sortType } = sorting;

  return groupListing.length > 0 ? (
    <>
      <div className={styles.filterContainer}>
        <ListHeaderItem
          columnId="groupName"
          className={columnStyles.GroupNameSection}
          sortBy={sortBy}
          sortType={sortType}
          columnName="Group Name"
          sortAble
          onSort={onSortGroupListByName}
        />
        <ListHeaderItem
          columnId="clientSince"
          className={columnStyles.GroupSinceSection}
          sortBy={sortBy}
          sortType={sortType}
          columnName="Group Since"
          sortAble
          onSort={onSortGroupListByClientSince}
        />
        <ListHeaderItem columnId="groupClosed" className={columnStyles.GroupClosedSection} columnName="Group Closed" />
        <ListHeaderItem columnId="groupLead" className={columnStyles.GroupLeadSection} columnName="Group Lead" />
      </div>
      {groupListing.map((groupListObj, index) => (
        <ClosedGroupItem groupListingItem={groupListObj} key={index} searchValue={searchValue} />
      ))}
    </>
  ) : (
    <div>There is no data for the group</div>
  );
};

export default ClosedGroupListCard;
