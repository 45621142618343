import { useState } from 'react';
import { Divider, Input, Modal, notification } from 'antd';
import moment from 'moment';

import { SendSurveyForm, SendSurveyModalProps, SurveyLink } from './interfaces';

import { combineName, getName } from 'utils/general';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  postCreateSurveyLinks,
  postSendAdHocSurvey,
  postSendAdHocSurveyAsPractice
} from 'utils/http/CheckInService/Assessment/firstAssessment';
import { usePrefillProfileSurveyForm } from './hooks/PrefillValue';

import CheckBox from 'components/CheckBox/CheckBox';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import InviteTimeForm from 'components/InviteTimeForm/InviteTimeForm';
import { inviteTimeInterface } from 'components/InviteTimeForm/InviteTimeInterface';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import OptionLabel from 'components/OptionLabel/OptionLabel';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import ProfileSelect from './components/ProfileSelect/ProfileSelect';

import styles from './SendSurveyModal.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import StepProgressBar from 'components/ModalV2/components/StepProgressBar/StepProgressBar';
import { ProfileRole } from 'interfaces/Clients/clientsRecord';
import SurveyListSelection from './components/SurveySelect/SurveyListSelection';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

const formatSendTime = (sendTimeValue: string, customTime?: string) => {
  const nowTime = moment().format('HH:mm');

  let sendAtDateTime: Date;

  if (sendTimeValue === '9') {
    if (nowTime > '09:00') {
      sendAtDateTime = moment().add('1', 'day').hour(9).minute(0).second(0).toDate();
    } else {
      sendAtDateTime = moment().hour(9).minute(0).second(0).toDate();
    }
  } else if (sendTimeValue === '13') {
    if (nowTime > '13:00') {
      sendAtDateTime = moment().add('1', 'day').hour(13).minute(0).second(0).toDate();
    } else {
      sendAtDateTime = moment().hour(13).minute(0).second(0).toDate();
    }
  } else if (sendTimeValue === 'custom') {
    sendAtDateTime = moment(customTime).toDate() || nowTime;
  } else {
    sendAtDateTime = moment().toDate();
  }

  return sendAtDateTime;
};

const putNewSendSurveyForm = async (
  token: string,
  sendSurveyForm: SendSurveyForm,
  clientRecordId: string,
  sendAsAdmin: boolean
) => {
  const clientProfiles = sendSurveyForm.profileList
    .filter((profile) => profile.isChecked)
    .map(({ profileId, surveys, receiver }) => ({
      _id: profileId,
      surveyIds: surveys.map(({ value }) => value),
      proxyClientProfileId: receiver?._id
    }));

  const payload = {
    clientProfiles,
    sendAt: formatSendTime(sendSurveyForm.inviteTime.sendAt, sendSurveyForm.inviteTime.customSendTime),
    note: sendSurveyForm.note
  };

  if (sendAsAdmin) {
    await postSendAdHocSurveyAsPractice(token, clientRecordId, payload);
  } else {
    await postSendAdHocSurvey(token, clientRecordId, payload);
  }
};

const postGetSurveyLink = async (token: string, sendSurveyForm: SendSurveyForm, clientRecordId: string) => {
  const clientProfiles = sendSurveyForm.profileList
    .filter((profile) => profile.isChecked)
    .map(({ profileId, surveys, receiver }) => ({
      _id: profileId,
      surveyIds: surveys.map(({ value }) => value),
      proxyClientProfileId: receiver?._id
    }));

  const payload = {
    clientProfiles,
    sendAt: formatSendTime(sendSurveyForm.inviteTime.sendAt, sendSurveyForm.inviteTime.customSendTime),
    note: sendSurveyForm.note
  };
  return await postCreateSurveyLinks(token, clientRecordId, payload);
};

const SendSurveyModal = ({
  onCancel,
  visible,
  clientProfileList,
  clientRecordId,
  notificationSettings
}: SendSurveyModalProps) => {
  const { token } = useGetAccessToken();
  const [submitStatus, setSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [step, setStep] = useState<1 | 2>(1);
  const [isReceiverValid, setIsReceiverValid] = useState<boolean>(true);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const isHaveChildProfile = clientProfileList.find((profile) => profile.role === ProfileRole.Child);
  const [isSendSurvey, setIsSendSurvey] = useState(true);
  const [isGetLinkSuccessful, setIsGetLinkSuccessful] = useState(false);
  const [surveyLinks, setSurveyLinks] = useState<SurveyLink[]>([]);

  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();

  const { sendSurveyForm, setSendSurveyForm, prefillLoading, surveyList, profileOption, fetchPrefillProfilesData } =
    usePrefillProfileSurveyForm(token, clientProfileList, notificationSettings);

  const isYoungRecord = clientProfileList.some(
    (profile) => profile.role === ProfileRole.YoungPerson && profile.profileType === 'recordOnly'
  );

  const [t] = useTranslation();

  // Hi future maintainer, this only happens if child has more than one contact and a selected profile cannot be contacted
  const onlyGenerateLink = sendSurveyForm.profileList?.some(
    (profile) => profile.isChecked && profile.isCommunicationsNotAllowed
  );

  const handleSelectSurvey = (index: number, val: string) => {
    if (sendSurveyForm.profileList[index].surveys.length >= 3) {
      return;
    }

    const foundSurvey = surveyList.find(({ value }) => value === val);

    if (foundSurvey && !sendSurveyForm.profileList[index].surveys.find(({ value }) => value === val)) {
      sendSurveyForm.profileList[index].surveys.push(foundSurvey);
    }

    setSendSurveyForm({ ...sendSurveyForm, profileList: [...sendSurveyForm.profileList] });
  };

  const handleRemoveSurvey = (index: number, val: string) => {
    sendSurveyForm.profileList[index].surveys = sendSurveyForm.profileList[index].surveys.filter(
      ({ value }) => value !== val
    );

    setSendSurveyForm({ ...sendSurveyForm, profileList: [...sendSurveyForm.profileList] });
  };

  const handleSelectProfile = (index: number, val: string) => {
    if (sendSurveyForm.profileList[index].role === 'child') {
      sendSurveyForm.profileList[index].receiver = {
        _id: val,
        name: profileOption.find((profile) => profile.value === val)?.label || '',
        isValid: !!val
      };
    }
    setSendSurveyForm({ ...sendSurveyForm, profileList: [...sendSurveyForm.profileList] });
  };

  const handleProfileChange = (index: number, val: boolean) => {
    sendSurveyForm.profileList[index].isChecked = val;
    setSendSurveyForm({ ...sendSurveyForm, profileList: [...sendSurveyForm.profileList] });
  };

  const handleChangeInviteTime = (val: inviteTimeInterface) => {
    sendSurveyForm.inviteTime = val;
    setSendSurveyForm({ ...sendSurveyForm });
  };

  const onChangeNote = (val: string) => {
    sendSurveyForm.note = val;
    setSendSurveyForm({ ...sendSurveyForm });
  };

  const isStep1Valid = (): boolean => {
    const checkProfileSelect = !(
      sendSurveyForm.profileList.find((profile) => profile.role === 'child' && !profile.receiver?._id) &&
      sendSurveyForm.profileList.some((profile) => profile.role !== 'child' && !profile.isCommunicationsNotAllowed)
    );
    setIsReceiverValid(checkProfileSelect);

    const checkProfileList = sendSurveyForm.profileList.map((profile) => ({
      ...profile,
      isValid: !(!profile.surveys.length && profile.isChecked)
    }));
    setSendSurveyForm({ ...sendSurveyForm, profileList: checkProfileList });
    return !checkProfileList.find((profile) => !profile.isValid) && checkProfileSelect;
  };

  const isStep2Valid = (): boolean => {
    const inviteTimeCheck = sendSurveyForm.inviteTime.sendAt !== 'custom' || !!sendSurveyForm.inviteTime.customSendTime;
    return inviteTimeCheck;
  };

  const handleMoveToStep2 = () => {
    if (!isStep1Valid()) return;
    setStep(2);
  };

  const handleSubmitSurvey = async () => {
    setIsSubmit(true);
    if (!(isStep2Valid() && isStep1Valid())) return;
    setSubmitStatus('active');

    try {
      await putNewSendSurveyForm(token, sendSurveyForm, clientRecordId, isEdgeAdminView || isEdgeReceptionistView);

      setTimeout(() => {
        setSubmitStatus('finished');
      }, 500);
      setTimeout(async () => {
        notification.success({
          message: 'Successfully sent survey form',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
        setSubmitStatus('');
        if (onCancel) {
          onCancel();
        }
        await fetchPrefillProfilesData(clientProfileList);
        setStep(1);
        setIsReceiverValid(true);
        setIsSubmit(false);
      }, 1000);
    } catch (e) {
      console.error(e);
      setSubmitStatus('');
      notification.error({
        message: 'Something went wrong while trying to send your survey form. Please try again.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const handleGetLink = async () => {
    setIsSubmit(true);
    if (!isStep1Valid()) return;
    setSubmitStatus('active');
    try {
      const getResponse = await postGetSurveyLink(token, sendSurveyForm, clientRecordId);
      const surveyLinksData = (await getResponse.json()) as SurveyLink[];
      if (surveyLinksData && surveyLinksData.length > 0) {
        setTimeout(() => {
          setSubmitStatus('finished');
        }, 500);
        setTimeout(async () => {
          notification.success({
            message: 'Successfully get link to the survey',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setIsGetLinkSuccessful(true);
          // Map reponse to get client's name
          const surveyLinkList = surveyLinksData.map((item) => {
            item.name = sendSurveyForm.profileList.find((profile) => profile.profileId === item.clientProfileId)?.name;
            return item;
          });
          setSurveyLinks(surveyLinkList);
        }, 1000);
      } else {
        notification.error({
          message: 'Something went wrong while trying to get link to the survey. Please try again.',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    } catch (e) {
      notification.error({
        message: 'Something went wrong while trying to get link to the survey. Please try again.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    } finally {
      setSubmitStatus('');
    }
  };

  const handleCopyContent = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      notification.success({
        message: 'Copied!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    } catch (err) {
      notification.error({
        message: 'Failed to copy!',
        duration: 1,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  const openLinkInNewTab = (value: string) => {
    window.open(value, '_blank');
  };

  const generateAnotherSurvey = () => {
    setStep(1);
    setIsSendSurvey(true);
    setIsGetLinkSuccessful(false);
    setSubmitStatus('');
    fetchPrefillProfilesData(clientProfileList);
  };

  const renderGetLinkSuccessful = () => {
    return (
      <div className={styles.isGetLinkSuccessful}>
        <p>
          <Trans i18nKey="client_survey.one_time_link_generated" />
        </p>

        {surveyLinks.map((item) => (
          <div key={item.clientProfileId} className={styles.linkToSurvey}>
            <div className={styles.name}>{item.name}</div>
            <div className={styles.linkContainer}>
              <div className={styles.value}>{item.url}</div>
              <div className={styles.copyBtn} onClick={() => handleCopyContent(item.url)}>
                <i className={`material-icons ${styles.icon}`}>content_copy</i>
              </div>
              <div className={styles.openLinkInNewTab} onClick={() => openLinkInNewTab(item.url)}>
                <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
                Open link in new tab
              </div>
            </div>
          </div>
        ))}
        <div className={styles.newSurveyBtn} onClick={() => generateAnotherSurvey()}>
          <i className={`material-icons`}>add_circle_outline</i>
          <div className={styles.newSurveyLabel}>Generate another survey</div>
        </div>
      </div>
    );
  };

  const renderStep1 = () => {
    return (
      <div className={styles.container}>
        <div className={styles.secondHeaderTitle}>{t('label.select_client')}</div>
        {sendSurveyForm.profileList.map((profile, index) => (
          <div key={`profile-${index}`} className={styles.itemWrapper}>
            <div className={styles.profileBox}>
              <CheckBox
                className={styles.checkBox}
                labelClassName={styles.checkBoxLabel}
                id={`checkBox-${index}`}
                value={profile.isChecked}
                label={
                  <>
                    <ClientProfileAvatar
                      avatarClassName={styles.avatar}
                      initialClassName={styles.avatar}
                      avatarUrl={profile.avatar}
                      initialsName={profile.initials}
                    />
                    <div className={styles.clientLabel}>
                      <OptionLabel
                        containerClassName={styles.nameContainer}
                        titleClassName={styles.clientName}
                        title={getName(profile)}
                        descClassName={styles.note}
                        desc={
                          !onlyGenerateLink &&
                          !profile.isCommunicationsNotAllowed &&
                          profile.role === 'child' &&
                          profile.isChecked
                            ? profile.receiver && profile.receiver.name
                              ? `(${profile.receiver.name} will receive the request)`
                              : 'No contact to receive the request'
                            : ''
                        }
                      />
                    </div>
                  </>
                }
                onChange={(e) => handleProfileChange(index, e.target.checked)}
              />
            </div>
            {profile.isChecked && (
              <div className={styles.selectContainer}>
                <Divider style={{ margin: 0 }} />
                {!onlyGenerateLink && !profile.isCommunicationsNotAllowed && profile.role === 'child' && (
                  <div className={styles.profileSelect}>
                    <ProfileSelect
                      key={`profile${index}`}
                      id={`profile${index}`}
                      value={profile.receiver?._id || ''}
                      checkValidation={!isReceiverValid}
                      profileList={profileOption}
                      isListLoading={prefillLoading}
                      onChangeConsentValue={(val: string) => handleSelectProfile(index, val)}
                    />
                  </div>
                )}
                <SurveyListSelection
                  key={`survey${index}`}
                  id={`survey${index}`}
                  value={profile.surveys}
                  checkValidation={!profile.isValid}
                  surveyList={surveyList}
                  isListLoading={prefillLoading}
                  onSelectSurvey={(val: string) => handleSelectSurvey(index, val)}
                  onRemoveSurvey={(val: string) => handleRemoveSurvey(index, val)}
                />
              </div>
            )}
          </div>
        ))}
        <div className={styles.submitButtonContainer}>
          <ButtonAlt
            status={submitStatus}
            onClick={handleMoveToStep2}
            disabled={!sendSurveyForm.profileList.find((profile) => profile.isChecked)}
          >
            Next
          </ButtonAlt>
        </div>
      </div>
    );
  };

  const goBackStep1 = () => {
    if (submitStatus === 'active') {
      return;
    }
    setStep(1);
  };

  const renderStep2 = () => {
    return (
      <>
        <ButtonAlt
          className={styles.backBtn}
          variant={'text'}
          size={'medium'}
          disabled={submitStatus === 'active'}
          onClick={goBackStep1}
          icon={'arrow_back_ios'}
        >
          Back
        </ButtonAlt>
        <div className={styles.step2Container}>
          <div className={styles.profileWrapper}>
            {sendSurveyForm.profileList
              .filter((profile) => profile.isChecked)
              .map((profile) => (
                <div className={styles.profileSurvey}>
                  <div className={styles.profile}>
                    <ClientProfileAvatar
                      avatarClassName={styles.avatar}
                      initialClassName={styles.avatar}
                      avatarUrl={profile.avatar}
                      initialsName={profile.initials}
                    />
                    <div className={styles.name}>{getName(profile)}</div>
                  </div>
                  <div className={styles.surveyNames}>
                    {profile.surveys.map(({ label }) => (
                      <span>{label}</span>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div className={styles.howToComplete}>
            <span className={styles.title}>
              {onlyGenerateLink
                ? `Generate link for ${combineName(
                    sendSurveyForm.profileList.filter((item) => item.isChecked),
                    true
                  )}`
                : `How should ${combineName(
                    sendSurveyForm.profileList.filter((item) => item.isChecked),
                    true
                  )} complete the survey`}
            </span>
            <ToggleSwitch
              disabled={submitStatus === 'active' || onlyGenerateLink}
              key={'howCompleteSurvey'}
              id={'howCompleteSurvey'}
              checkLabel={'SEND SURVEY'}
              unCheckLabel={'GET LINK'}
              buttonWidth={304}
              isCheckedProps={isSendSurvey && !onlyGenerateLink}
              onChangeProps={() => {
                setIsSendSurvey(!isSendSurvey);
              }}
            />
          </div>

          {isSendSurvey && !onlyGenerateLink ? (
            <>
              <InviteTimeForm
                disabled={submitStatus === 'active'}
                title={''}
                tooltipsMessage={''}
                inviteTimeForm={sendSurveyForm.inviteTime}
                checkValidation={isSubmit}
                onChangeInviteField={handleChangeInviteTime}
              />
              <div className={styles.addNoteTitle}>
                Include a personalised note for context
                <HelpOutLineWithTooltips
                  id={'addNoteTitle'}
                  desc={
                    'This will be included in the SMS or email. You can personalise the message and provide context for the request to complete the survey'
                  }
                />
              </div>

              <div className={styles.addNoteEditor}>
                <Input.TextArea
                  disabled={submitStatus === 'active'}
                  id={'surveyNote'}
                  rows={5}
                  value={sendSurveyForm.note}
                  onChange={(e) => onChangeNote(e.target.value)}
                />
              </div>
              <div className={styles.submitButtonContainer}>
                <ButtonAlt
                  status={submitStatus}
                  onClick={handleSubmitSurvey}
                  disabled={!sendSurveyForm.profileList.find((profile) => profile.isChecked)}
                >
                  Send survey form
                </ButtonAlt>
              </div>
            </>
          ) : (
            <>
              <div className={styles.getLinkTitle}>
                This will create a one time use link. Best for in person, or facilitated, completion
              </div>
              <div className={styles.submitButtonContainer}>
                <ButtonAlt
                  status={submitStatus}
                  onClick={handleGetLink}
                  disabled={!sendSurveyForm.profileList.find((profile) => profile.isChecked)}
                >
                  Generate Link for responses
                </ButtonAlt>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={
        <div className={styles.modalHeader}>
          <div className={styles.title}>
            {!isSendSurvey && isGetLinkSuccessful ? `Here is the link to the survey` : `Generate survey`}
            {isHaveChildProfile ? (
              <HelpOutLineWithTooltips
                id={'sendSurveyHeadingInfo'}
                desc={`The request for a child to complete a survey will be sent to the child's primary contact.`}
              />
            ) : (
              isYoungRecord && (
                <HelpOutLineWithTooltips
                  id={'sendSurveyHeadingInfo'}
                  desc={`The request for a Young Person to complete a survey will be sent to the their primary contact if no contact detail is provided.`}
                />
              )
            )}
          </div>
          <StepProgressBar currentStep={step} totalStep={2} />
        </div>
      }
      open={visible}
      footer={null}
      onCancel={onCancel}
    >
      {prefillLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : surveyList.length === 0 ? (
        <div className={styles.container}>
          <div className={styles.noListLabel}>No surveys available.</div>
        </div>
      ) : (
        <>
          {step === 1 && renderStep1()}
          {step === 2 && (isGetLinkSuccessful ? renderGetLinkSuccessful() : renderStep2())}
        </>
      )}
    </Modal>
  );
};

export default SendSurveyModal;
