import { useState } from 'react';
import { Modal, notification, Skeleton } from 'antd';
import { config } from 'config/config';
import { Formik } from 'formik';
import * as yup from 'yup';

import { InvoiceSettings, PaymentMethod } from '../../../../interface';
import { useUpdateInvoiceSettingsMutation } from 'redux/endpoints/billingServices/invoiceSetting';
import Button from 'components/Button/Button';
import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import PaymentMethodItem from './components/PaymentMethodItem/PaymentMethodItem';

import styles from './InvoiceSettingsModal.module.scss';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

type InvoiceSettingsFields = Required<Omit<InvoiceSettings, 'medicare' | 'integration'>>;

export const DEFAULT_INVOICE_SETTINGS: InvoiceSettingsFields = {
  dueDate: 0,
  taxRate: 0,
  note: '',
  paymentMethods: [],
  contactDetails: {
    practice: {
      address: {
        value: '',
        isVisible: false
      },
      mobileNumber: {
        value: '',
        isVisible: false
      }
    },
    clinician: {
      mobileNumber: {
        value: '',
        isVisible: false
      },
      email: {
        value: '',
        isVisible: false
      }
    }
  }
};

const DUE_DATE_OPTIONS = [
  { label: 'Immediate (Today)', value: 0 },
  { label: 'Tomorrow', value: 1 },
  { label: '2 Days', value: 2 },
  { label: '3 Days', value: 3 },
  { label: '7 Days', value: 7 },
  { label: '14 Days', value: 14 },
  { label: '28 Days', value: 28 }
];

const { mobileNumberMinLength, taxLabel } = config;

const invoiceSettingsSchema = yup.object().shape({
  dueDate: yup.number().typeError('Please select an option'),
  taxRate: yup
    .number()
    .typeError('Please enter numbers only')
    .min(0, 'Please enter a positive value')
    .max(100, 'Please enter a value less than or equal to 100'),
  note: yup.string(),
  paymentMethods: yup.array().of(yup.string()).min(1, 'Please select at least one payment method').ensure(),
  contactDetails: yup.object().shape({
    practice: yup.object().shape({
      mobileNumber: yup.object().shape({
        value: yup
          .string()
          .min(mobileNumberMinLength, `Mobile number has to be at least ${mobileNumberMinLength} characters`)
          .nullable()
      })
    }),
    clinician: yup.object().shape({
      mobileNumber: yup.object().shape({
        value: yup
          .string()
          .min(mobileNumberMinLength, `Mobile number has to be at least ${mobileNumberMinLength} characters`)
          .nullable()
      }),
      email: yup.object().shape({
        value: yup.string().email('Please enter a valid email address').nullable()
      })
    })
  })
});

interface InvoiceSettingsModalProps {
  invoiceSettings?: InvoiceSettings;
  paymentMethods: PaymentMethod[];
  isInvoiceSettingsLoading: boolean;
  isPaymentMethodsLoading: boolean;
  visible: boolean;
  onAddPaymentMethodClick: () => void;
  onClose: () => void;
}

const InvoiceSettingsModal = ({
  invoiceSettings,
  paymentMethods,
  isInvoiceSettingsLoading,
  isPaymentMethodsLoading,
  visible,
  onAddPaymentMethodClick,
  onClose
}: InvoiceSettingsModalProps) => {
  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [updateInvoiceSettings] = useUpdateInvoiceSettingsMutation();

  const handleSubmit = async (values: InvoiceSettingsFields) => {
    setSaveButtonStatus('active');

    try {
      const formattedInvoiceSettings = { ...values, taxRate: Number(values.taxRate) };

      await updateInvoiceSettings({ payload: { invoiceSettings: formattedInvoiceSettings } }).unwrap();

      setSaveButtonStatus('finished');
      notification.success({
        message: 'Invoice settings updated',
        duration: 2,
        closeIcon: <span className="success">OK</span>,
        onClose: () => setSaveButtonStatus('')
      });

      onClose();
    } catch (ex) {
      setSaveButtonStatus('');
      notification.error({
        message: 'Something went wrong while trying to update your invoice settings',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
  };

  return (
    <Modal
      bodyStyle={{ padding: 0 }}
      footer={null}
      width={768}
      open={visible}
      onCancel={() => onClose()}
      destroyOnClose
    >
      <div className={styles.title}>Invoice Settings</div>
      <div className={styles.content}>
        <div className={styles.heading}>
          Set up your defaults to apply to all invoices.
          <br />
          You can always change them in each individual invoice.
        </div>
        <Formik
          initialValues={{
            ...DEFAULT_INVOICE_SETTINGS,
            ...(invoiceSettings?.dueDate && { dueDate: invoiceSettings.dueDate }),
            ...(invoiceSettings?.taxRate !== undefined && { taxRate: invoiceSettings.taxRate }),
            ...(invoiceSettings?.note && { note: invoiceSettings.note }),
            ...(invoiceSettings?.paymentMethods && { paymentMethods: invoiceSettings.paymentMethods }),
            ...(invoiceSettings?.contactDetails && { contactDetails: invoiceSettings.contactDetails })
          }}
          validationSchema={invoiceSettingsSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, touched, values, setFieldValue, submitForm }) => {
            return (
              <>
                {isInvoiceSettingsLoading ? (
                  <Skeleton active />
                ) : (
                  <>
                    <div className={styles.selectLabel}>
                      Due Date
                      <HelpOutLineWithTooltips
                        id={'due-date'}
                        desc={
                          'Your default invoice payment due date is calculated based on days from when the invoice is issued e.g. invoice date + 7 days. You can change this on each individual invoice if you should need to.'
                        }
                      />
                    </div>
                    <FormikSelect
                      className={styles.paymentType}
                      name="dueDate"
                      options={DUE_DATE_OPTIONS}
                      styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
                    />
                    <div className={styles.label}>
                      {taxLabel} Rate %
                      <HelpOutLineWithTooltips
                        id={'tax-rate'}
                        desc={
                          'You can set your default tax rate to cover any Sales Tax or VAT you are required to collect. This % is added on top of your base fee in each invoice. You can amend both the base fee and tax rate in each individual invoice. Leaving blank will default tax to zero.'
                        }
                      />
                    </div>
                    <FormikMaterialInput id={'taxRate'} className={styles.accountName} name="taxRate" label="" />
                    <div className={styles.paymentMethodsContainer}>
                      <div className={styles.subtitle}>Payment method options to include</div>
                      <div className={styles.description}>You can edit this in each invoice you create</div>
                      {isPaymentMethodsLoading ? (
                        <Skeleton className={styles.loading} active />
                      ) : (
                        <>
                          {paymentMethods.length === 0 ? (
                            <div className={styles.noPaymentMethods}>
                              <span className={styles.text}>You currently have no payment methods.</span>
                              <Button className={styles.button} variant="primary" onClick={onAddPaymentMethodClick}>
                                <i className={`material-icons ${styles.icon}`}>settings</i>
                                Add a payment method
                              </Button>
                            </div>
                          ) : (
                            paymentMethods.map((paymentMethod, index) => (
                              <PaymentMethodItem
                                key={index}
                                checked={values.paymentMethods.includes(paymentMethod._id)}
                                index={index}
                                paymentMethod={paymentMethod}
                                onAddPaymentMethod={() =>
                                  setFieldValue('paymentMethods', [...values.paymentMethods, paymentMethod._id])
                                }
                                onRemovePaymentMethod={() =>
                                  setFieldValue(
                                    'paymentMethods',
                                    values.paymentMethods.filter(
                                      (paymentMethodId) => paymentMethodId !== paymentMethod._id
                                    )
                                  )
                                }
                              />
                            ))
                          )}
                        </>
                      )}
                      <ErrorMessage
                        error={
                          Array.isArray(errors.paymentMethods)
                            ? errors.paymentMethods.find((error) => !!error)
                            : errors.paymentMethods
                        }
                        visible={!!touched.paymentMethods}
                      />
                    </div>
                    <div className={styles.subtitle}>Note to include when sharing invoice</div>
                    <div className={styles.description}>You can edit this in each invoice you create</div>
                    <div className={styles.noteContainer}>
                      <textarea
                        className={styles.note}
                        maxLength={160}
                        rows={3}
                        value={values.note}
                        onChange={(e) => setFieldValue('note', e.target.value)}
                      />
                      <span className={styles.characterCount}>
                        {values.note.length} / {160} characters
                      </span>
                    </div>
                  </>
                )}
                <div className={styles.buttonRow}>
                  <ButtonAlt type="submit" status={saveButtonStatus} onClick={submitForm}>
                    Save
                  </ButtonAlt>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default InvoiceSettingsModal;
