import { Modal } from 'antd';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { AttachedClientRecordStatus, Groups, GroupStatus } from 'pages/Groups/Interfaces/Groups';
import { useState } from 'react';
import styles from './groupStatusButtons.module.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface GroupStatusButtonsProps {
  groupData: Groups;
  onChange: (value: boolean, clientStatus?: AttachedClientRecordStatus) => void;
}

const GroupStatusButtons = ({ groupData, onChange }: GroupStatusButtonsProps) => {
  const [status, setStatus] = useState<boolean>(groupData.status === GroupStatus.Active);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const handleConfirm = (clientStatus: AttachedClientRecordStatus) => {
    setIsConfirm(false);
    setStatus(!status);
    onChange(!status, clientStatus);
  };

  const handleConfirmClick = () => {
    Modal.confirm({
      closable: true,
      title: `Are you sure you want to ${status ? 'close' : 'reactivate'} this group?`,
      cancelText: 'Cancel',
      okText: 'Yes',
      onOk: () => {
        setStatus(!status);
        onChange(!status);
      }
    });
  };

  return (
    <div className={styles.statusContainer}>
      <div className={styles.toggle}>
        <ToggleSwitch
          id={'GroupActiveStatus'}
          checkLabel={'Active'}
          unCheckLabel={'Closed'}
          isCheckedProps={status}
          onChangeProps={() => (groupData.clientCount > 0 && status ? setIsConfirm(true) : handleConfirmClick())}
          toggleClassName={styles.toggleSwitch}
          disabled={false}
        />
      </div>
      <Modal
        closable={false}
        className={styles.modalContainer}
        open={isConfirm}
        onCancel={() => setIsConfirm(false)}
        confirmLoading
        footer={
          <div className={styles.confirmModalFooter}>
            <ButtonAlt
              className={styles.footerBtn}
              size={'medium'}
              variant={'outlined'}
              onClick={() => setIsConfirm(false)}
            >
              Cancel
            </ButtonAlt>
            <ButtonAlt
              className={styles.footerBtn}
              size={'medium'}
              onClick={() => handleConfirm(AttachedClientRecordStatus.Archived)}
            >
              Archive All
            </ButtonAlt>
            <ButtonAlt
              className={styles.footerBtn}
              size={'medium'}
              onClick={() => handleConfirm(AttachedClientRecordStatus.Completed)}
            >
              Complete All
            </ButtonAlt>
          </div>
        }
      >
        {groupData.clientCount > 0 && status && (
          <>
            <div className={styles.confirmModal}>
              <ExclamationCircleOutlined className={styles.warningIcon} />
              <div>
                <p>Are you sure you want to close this group?</p>
                <p>If yes, please choose to archive all or complete all.</p>
              </div>
            </div>
            <label className={styles.subTitle}>
              If you wish to mix status please do one by one before confirm close group.
            </label>
          </>
        )}
      </Modal>
    </div>
  );
};

export default GroupStatusButtons;
