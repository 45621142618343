import TimePicker from 'components/v2/TimePicker/TimePicker';
import { useField } from 'formik';
import { TimeSlotType } from '../../../../constants';
import styles from './TimeSelection.module.scss';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

interface TimeSelectionProps {
  day: string;
  index?: number;
  showAddButton?: boolean;
  onSplit?: () => void;
  onRemove?: () => void;
}

const TimeSelection = ({ day, index = -1, onSplit, onRemove }: TimeSelectionProps) => {
  const [, { value, touched, error }, { setValue, setTouched }] = useField(`availability.${day}.timeSlots[${index}]`);

  const onChangeTime = (index: number, type: keyof TimeSlotType) => (newTime: string) => {
    setValue({
      ...value,
      [type]: newTime
    });
    setTouched(true);
  };

  return (
    <div className={styles.detailsContainer}>
      <div className={styles.selectionsContainer}>
        <div className={styles.timeContainer}>
          <div className={styles.startTimeContainer}>
            <TimePicker time={value.startTime} error={false} onChange={onChangeTime(index, 'startTime')} />
          </div>
          <div className={styles.endTimeContainer}>
            <TimePicker time={value.endTime} error={false} onChange={onChangeTime(index, 'endTime')} />
          </div>
        </div>
        <div className={styles.actionContainer}>
          {index === 0 ? (
            <ButtonAlt variant="text" size="medium" onClick={onSplit}>
              Split
            </ButtonAlt>
          ) : (
            <ButtonAlt error variant="text" size="medium" onClick={onRemove}>
              Remove
            </ButtonAlt>
          )}
        </div>
      </div>
      {touched && error && (
        <div className={styles.errorContainer}>
          <ErrorMessage visible={touched && error.length > 1} error={error} />
        </div>
      )}
    </div>
  );
};

export default TimeSelection;
