import { Tooltip, TooltipProps } from 'antd';
import classNames from 'classnames';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import style from './MenuTooltip.module.scss';

interface MenuTooltipProps {
  pillShape?: boolean;
}

const MenuTooltip = ({ children, placement = 'right', pillShape, ...props }: TooltipProps & MenuTooltipProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const overlayClassName = classNames(isEdgeAdminView ? style.admin : style.user, pillShape && style.pillShape);

  return (
    <Tooltip {...props} overlayClassName={overlayClassName} placement={placement} showArrow={false}>
      {children}
    </Tooltip>
  );
};

export default MenuTooltip;
