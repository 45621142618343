import { useEffect, useRef, useState } from 'react';

import styles from './QuestionCategoryMenu.module.scss';

export interface QuestionListWithCheckBoxProps {
  value: string;
  data: any;
  id: string;
  onSelectQuestionSet: any;
  selectedData: any;
  onAddTopic?: any;
}

const QuestionCategoryMenu = ({
  value,
  data,
  id,
  onSelectQuestionSet,
  onAddTopic,
  selectedData
}: QuestionListWithCheckBoxProps) => {
  const node = useRef<HTMLDivElement>(null);
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [templateList, setTemplateList] = useState(data);
  const [customInput, setCustomInput] = useState('');
  const [customAddBtn, setCustomAddBtn] = useState(false);
  const [questionTopicName, setQuestionTopicName] = useState(value);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    openList(false);
  };

  useEffect(() => {
    const filterSelectedItem = selectedData.map((itemY: any) => {
      return itemY.sectionName;
    });
    const filteredTemplateList = data.filter((itemX: any) => !filterSelectedItem.includes(itemX.sectionName));

    setTemplateList(filteredTemplateList);
    setQuestionTopicName(value);
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedData]);

  const openList = (val: boolean) => {
    setShowCategoryList(val);
  };

  const selectQuestionSet = (id: string, qName: string) => {
    setShowCategoryList(false);
    setQuestionTopicName(qName);
    onSelectQuestionSet(id, qName);
  };

  const onChangeCustomInput = (value: string) => {
    setCustomAddBtn(value.length > 2);
    setCustomInput(value);
  };

  const addNewTopic = () => {
    onAddTopic(customInput);
    setCustomInput('');
  };

  return (
    <div ref={node} className={styles.materialSelect}>
      <div className={styles.label}>Topic</div>
      <div className={styles.dropdownWrapper}>
        <div>
          <div className={styles.valueWrapper} onClick={() => openList(!showCategoryList)}>
            {questionTopicName.length === 0 ? (
              <div>Please select topic</div>
            ) : (
              <div className={styles.valueBox}>{questionTopicName}</div>
            )}
            <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
          </div>
          <div className={showCategoryList ? styles.listWrapperShow : styles.listWrapperHide}>
            {templateList.map((obj: any, index: number) => {
              return (
                questionTopicName !== obj.sectionName && (
                  <div className={styles.listBox} key={index} onClick={() => selectQuestionSet(id, obj.sectionName)}>
                    {obj.sectionName}
                  </div>
                )
              );
            })}
            <div className={styles.customListBox}>
              <input
                value={customInput}
                className={styles.searchInput}
                placeholder={'Add custom topic'}
                onChange={(e) => onChangeCustomInput(e.target.value)}
              />
              {customAddBtn && (
                <div className={styles.addButton} onClick={addNewTopic}>
                  + Add
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionCategoryMenu;
