import { Skeleton } from 'antd';
import styles from './TasksFilters.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import { useGetMinifiedClientRecordQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useMemo, useState } from 'react';
import { SearchFilterEnum } from 'interfaces/Clients/clientRecordNew';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';
import { onToggleFilter } from 'pages/Client/components/ClientListFilters/constants';
import { debounce } from 'lodash';

const PER_PAGE = 50;

interface TasksFiltersProps {
  isLoading: boolean;
  totalItems: number;
  filterText: string;
  selectedClients: FilterCheckListItem[];
  onChangeClientFilter: (clients: FilterCheckListItem[]) => void;
  onResetFilters: () => void;
}

const TasksFilters = ({
  isLoading,
  totalItems,
  selectedClients,
  filterText,
  onChangeClientFilter,
  onResetFilters
}: TasksFiltersProps) => {
  const { accountId } = useGetAccountId();

  const [clientListCurrentPage, setClientListCurrentPage] = useState<number>(1);
  const [clientSearchText, setClientSearchText] = useState<string>('');

  const {
    data: clientListData,
    isLoading: isClientListDataLoading,
    isFetching: isClientListDataFetching
  } = useGetMinifiedClientRecordQuery(
    {
      accountId,
      params: {
        page: clientListCurrentPage,
        perPage: PER_PAGE,
        recordStatus: 'active,waitlist',
        ...(clientSearchText && {
          searchValue: clientSearchText,
          searchBy: SearchFilterEnum.FirstOrLastName
        })
      }
    },
    {}
  );

  const clientListFilter: FilterCheckListItem[] = clientListData
    ? clientListData.clientRecords.map((item) => ({
        name: `${item.clientProfiles[0].firstName} ${item.clientProfiles[0].lastName}`,
        _id: item._id
      }))
    : [];

  const debounceSetClientSearchText = useMemo(
    () =>
      debounce((value) => {
        setClientSearchText(value);
        setClientListCurrentPage(1);
      }, 1000),
    []
  );

  const showClearFilter = selectedClients.length;
  const totalClientListPage = clientListData?.paging ? Math.ceil(clientListData.paging.totalItems / PER_PAGE) : 1;

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <div className={styles.numberOfMatchedTasks}>
          {isLoading ? (
            <div className={styles.loadingProfile}>
              <Skeleton.Input className={styles.loading} active />
            </div>
          ) : (
            <div>
              {showClearFilter ? 'FILTER matches' : 'Showing'} <span className={styles.highLight}>{totalItems}</span>{' '}
              {filterText}
            </div>
          )}
        </div>
        <ButtonAlt
          error
          disabled={!showClearFilter}
          size="small"
          variant="text"
          className={styles.clearFilters}
          onClick={onResetFilters}
        >
          Clear filters
        </ButtonAlt>
      </div>

      {/* Filer buttons */}
      <div className={styles.buttonsContainer}>
        {/* Client */}
        <FilterDropdown
          id="clients"
          icon={<i className={`material-icons ${styles.searchIcon}`}>search</i>}
          menuItems={clientListFilter}
          onChangeItem={onToggleFilter(clientListFilter, selectedClients, onChangeClientFilter)}
          searchable
          selectedFilterList={selectedClients}
          showSearchIcon
          loading={isClientListDataLoading}
          hasMoreData={totalClientListPage > clientListCurrentPage || isClientListDataFetching}
          isFetchingMore={isClientListDataFetching}
          loadMore={() => {
            if (!isClientListDataFetching && clientListCurrentPage <= totalClientListPage) {
              setClientListCurrentPage(clientListCurrentPage + 1);
            }
          }}
          enableSelectedBackground
          onClearFilter={() => {
            onChangeClientFilter([]);
          }}
          setSearchText={debounceSetClientSearchText}
        >
          Client
        </FilterDropdown>
      </div>
    </div>
  );
};

export default TasksFilters;
