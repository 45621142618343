import { createRef, useCallback, useState, memo } from 'react';

import styles from './SelectClientOrGroup.module.scss';
import ParticipationHeader from './components/ParticipationHeader/ParticipationHeader';
import { ParticipantType } from '../../interfaces/Schedule/AppointmentType';
import GroupList from './components/GroupList/GroupList';
import ClientRecordList from './components/ClientRecordList/ClientRecordList';
import { GroupsFromAPI, GroupStatus } from '../../pages/Groups/Interfaces/Groups';
import { clientRecordsInterface } from '../../interfaces/Clients/clientsRecord';
import { ClinicianListInterface } from '../v2/ClinicianSelect/ClinicianSelect';
import { useFetchGroupsAppointmentList } from './components/GroupList/hooks/getGroupMemberList';
import { useGetAccessToken } from '../../utils/hooks/token';
import { useFetchClientRecordByClinician } from './hooks/GetClientList';
import { SortType } from 'components/TableList/SortBtn/SortBtn';
import _ from 'lodash';
import InfiniteScroller from 'react-infinite-scroller';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import SearchBar from 'components/v2/SearchBar/SearchBar';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import classnames from 'classnames';
import { scrollToView } from '../../utils/scrollToView';

interface SelectClientOrGroupProps {
  selectedClinician?: Partial<ClinicianListInterface>;
  defaultRecordId?: string;
  defaultGroupId?: string;
  defaultParticipationType: ParticipantType;
  onChangeSelectedParticipationType?: (val: ParticipantType) => void;
  isListLoading?: (isLoadingVal: boolean) => void;
  handleSelectGroup?: (val: GroupsFromAPI, preventCloseModal?: boolean) => void;
  handleSelectClientRecord?: (val?: clientRecordsInterface) => void;
  enableGroupSelection?: boolean;
  showAllStatusClient?: boolean;
  hideAddNewClientButton?: boolean;
  scrollContainerClassName?: string;
  disabled?: boolean;
  showOnlyParticipantType?: {
    oneToOne: boolean;
    group: boolean;
  };
  hideAvatar?: boolean;
}

const PER_PAGE = 20;

const SelectClientOrGroup = memo(
  ({
    selectedClinician,
    defaultRecordId,
    defaultGroupId,
    defaultParticipationType,
    onChangeSelectedParticipationType,
    isListLoading,
    handleSelectGroup,
    handleSelectClientRecord,
    enableGroupSelection,
    showAllStatusClient,
    hideAddNewClientButton,
    scrollContainerClassName,
    disabled,
    showOnlyParticipantType,
    hideAvatar
  }: SelectClientOrGroupProps) => {
    const { token } = useGetAccessToken();

    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSortAsc, setIsSortAsc] = useState(true);
    const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

    const checkAndResetSelectedClient = (clients: clientRecordsInterface[]) => {
      if (!clients.some(({ _id }) => _id === defaultRecordId)) {
        handleSelectClientRecord?.(undefined);
      }
    };

    const {
      bookableClients,
      isBookableClientsLoading,
      hasMoreData,
      fetchMoreClientRecords,
      sortClientsByName,
      refetchClientRecords
    } = useFetchClientRecordByClinician({
      clinicianId: selectedClinician?._id,
      showAllStatusClient,
      perPage: PER_PAGE,
      sortByClientName: isSortAsc ? SortType.asc : SortType.desc,
      checkClientExist: checkAndResetSelectedClient,
      searchValue: searchValue
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSetSearchValue = useCallback(
      _.debounce((v) => {
        if (isEdgeAdminView || isEdgeReceptionist) {
          refetchClientRecords({ search: v });
        }
        setLoading(false);
      }, 1500),
      [selectedClinician]
    );

    const { groupList, isGroupListLoading, sortByGroupName } = useFetchGroupsAppointmentList(
      token,
      selectedClinician?._id,
      GroupStatus.Active,
      enableGroupSelection
    );

    const infiniteScrollContainerRef = createRef<HTMLDivElement>();

    return (
      <div className={styles.container}>
        <ParticipationHeader
          selectedParticipantType={defaultParticipationType}
          handleChangeParticipation={(val) => {
            onChangeSelectedParticipationType && onChangeSelectedParticipationType(val);
          }}
          selectedSort={isSortAsc}
          onChangeSort={(val: boolean) => {
            scrollToView('scrollTop', true, false, 'auto');
            sortByGroupName(val);
            setIsSortAsc(val);
            sortClientsByName(val);
          }}
          onSuccessAddNewClient={() => {
            refetchClientRecords({});
          }}
          enableGroupSelection={enableGroupSelection}
          hideAddNewClientButton={hideAddNewClientButton}
          showOnlyParticipantType={showOnlyParticipantType}
        />
        {defaultParticipationType === ParticipantType.Group && enableGroupSelection ? (
          <GroupList
            groupList={groupList.groupList}
            onSelectGroups={(val, preventCloseModal) => {
              handleSelectGroup && handleSelectGroup(val, preventCloseModal);
            }}
            selectedGroupId={defaultGroupId}
            onGroupListLoading={isListLoading}
            isLoading={isGroupListLoading}
          />
        ) : (
          <>
            <SearchBar
              setSearchValue={(v) => {
                scrollToView('scrollTop', true, false, 'auto');
                setLoading(true);
                setSearchValue(v);
                debounceSetSearchValue(v);
              }}
            />
            <div
              className={classnames(styles.scrollContainer, scrollContainerClassName)}
              ref={infiniteScrollContainerRef}
            >
              <div id={'scrollTop'} />
              <InfiniteScroller
                getScrollParent={() => infiniteScrollContainerRef.current}
                initialLoad={false}
                loader={
                  <div className={styles.listLoading} key={0}>
                    <LoadingCircle />
                  </div>
                }
                loadMore={() => fetchMoreClientRecords(searchValue)}
                useWindow={false}
                hasMore={hasMoreData}
              >
                <ClientRecordList
                  clientRecords={bookableClients}
                  selectedClientRecordId={defaultRecordId}
                  handleSelectClientRecord={(val) => {
                    handleSelectClientRecord && handleSelectClientRecord(val!);
                  }}
                  onClientListLoading={isListLoading}
                  isLoading={loading || isBookableClientsLoading}
                  searchValue={searchValue}
                  className={styles.clientList}
                  disabled={disabled}
                  hideAvatar={hideAvatar}
                />
              </InfiniteScroller>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default SelectClientOrGroup;
