import styles from './UnassignedMemberList.module.scss';
import columnStyles from './UnassignedMemberListColumn.module.scss';
import classnames from 'classnames';
import SortBtn, { SortType } from 'components/TableList/SortBtn/SortBtn';
import PerRecordDropdown from 'components/TableList/PerRecordDropdown/PerRecordDropdown';
import PaginationDesc from 'components/TableList/PaginationDesc/PaginationDesc';
import { UnassignedGroupMemberList } from 'pages/Groups/Interfaces/Groups';
import { combineName } from 'utils/general';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import ProfileTypeBadge from 'components/ProfileTypeBadge/ProfileTypeBadge';
import { Skeleton } from 'antd';
import CheckBox from 'components/CheckBox/CheckBox';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

interface UnassignedMemberListProps {
  groupMembers: UnassignedGroupMemberList;
  isGroupMembersLoading: boolean;
  selectedPerPage: number;
  selectedPage: number;
  selectedSortName: SortType;
  selectedMemberList: string[];
  onChangeNameSort: (val: SortType) => void;
  onChangePerPage: (val: number) => void;
  onChangePage: (val: number) => void;
  onSelectMember: (clientRecordId: string, value: boolean) => void;
}

const UnassignedMemberList = ({
  groupMembers,
  isGroupMembersLoading,
  selectedPerPage,
  selectedPage,
  selectedSortName,
  selectedMemberList,
  onChangeNameSort,
  onChangePerPage,
  onChangePage,
  onSelectMember
}: UnassignedMemberListProps) => {
  const { isEdgeAdminView } = useGetAccountPackageView();
  const TOTAL_ITEM = groupMembers.paging.totalItem || 0;

  const handleRecordPerPageValue = (perPageVal: number) => {
    onChangePerPage(perPageVal);
  };

  const handlePageChange = (pageVal: number) => {
    onChangePage(pageVal);
  };

  return (
    <div className={classnames(styles.container, isEdgeAdminView ? columnStyles.adminTheme : columnStyles.userTheme)}>
      <div className={classnames(columnStyles.columnWrapper, styles.header)}>
        <div className={columnStyles.checkBox} />
        <div className={columnStyles.avatar}>AVATAR</div>
        <div className={classnames(columnStyles.fullName, columnStyles.sortActive)}>
          FULL NAME
          <SortBtn
            selectedSortName={selectedSortName}
            onChangeSort={onChangeNameSort}
            disabled={isGroupMembersLoading}
          />
        </div>
        <div className={columnStyles.profileType}>PROFILE TYPE</div>
        <div className={columnStyles.mobile}>MOBILE</div>
        <div className={columnStyles.email}>EMAIL</div>
      </div>
      {isGroupMembersLoading ? (
        <>
          {[...Array(10)].map((obj, i) => (
            <div key={i} className={classnames(styles.loadingWrapper, styles.listItem)}>
              <Skeleton.Input active className={styles.loading} />
            </div>
          ))}
        </>
      ) : groupMembers.clientRecords.length > 0 ? (
        <>
          {groupMembers.clientRecords.map((clientRecordObj, index) => {
            const isChildProfile = clientRecordObj.recordType === 'child';
            const clientProfile = isChildProfile
              ? clientRecordObj.clientProfiles.filter((cpObj) => cpObj.isPrimaryContact)
              : clientRecordObj.clientProfiles;
            const isMobileNumberExist = clientRecordObj.clientProfiles.some((el) => el.mobileNumber);
            const isEmailNumberExist = clientRecordObj.clientProfiles.some((el) => el.email);

            return (
              <div key={index} className={classnames(columnStyles.columnWrapper, styles.listItem)}>
                <div className={columnStyles.checkBox}>
                  <CheckBox
                    id={`selected${index}`}
                    value={selectedMemberList.filter((selectedId) => selectedId === clientRecordObj._id).length > 0}
                    onChange={(e) => onSelectMember(clientRecordObj._id, e.target.checked)}
                  />
                </div>
                <div className={columnStyles.avatar}>
                  <ClientAvatar
                    clientData={clientRecordObj.clientProfiles}
                    displayLimit={clientRecordObj.recordType === 'couple' ? 2 : 1}
                    hideName
                  />
                </div>
                <div className={classnames(columnStyles.fullName, styles.name)}>
                  {combineName(clientRecordObj.clientProfiles)}
                </div>
                <div className={columnStyles.profileType}>
                  <ProfileTypeBadge
                    clientProfilesData={clientRecordObj.clientProfiles}
                    recordType={clientRecordObj.recordType}
                    isShowInvitationAction={false}
                    isInvitationProcessing={false}
                    canBeFullProfile={false}
                  />
                </div>
                <div className={columnStyles.mobile}>
                  {isMobileNumberExist ? (
                    <div className={styles.multipleValue}>
                      {clientProfile.map((cpObj, index) => (
                        <div key={index}>{cpObj.mobileNumber}</div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.noValue}>-</div>
                  )}
                </div>
                <div className={columnStyles.email}>
                  {isEmailNumberExist ? (
                    <div className={styles.multipleValue}>
                      {clientProfile.map((cpObj, index) => (
                        <div key={index}>{cpObj.email}</div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.noValue}>-</div>
                  )}
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className={styles.noMemberFoundContainer}>No members found</div>
      )}
      <div className={styles.paginationWrapper}>
        <div className={styles.recordPerPageWrapper}>
          <PerRecordDropdown
            totalItem={TOTAL_ITEM}
            selectedPerPage={selectedPerPage}
            selectDropdownValue={handleRecordPerPageValue}
            disabled={isGroupMembersLoading}
          />
          <div className={styles.label}>records per page</div>
        </div>
        <PaginationDesc
          currentPage={selectedPage}
          totalItem={TOTAL_ITEM}
          perPage={selectedPerPage}
          onPageChange={handlePageChange}
          isLoading={isGroupMembersLoading}
          label={'members'}
        />
      </div>
    </div>
  );
};

export default UnassignedMemberList;
