import classNames from 'classnames';
import { useField } from 'formik';

import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import MaterialInput from 'components/MaterialInput/MaterialInput';
import Checkbox from 'components/v2/Checkbox/Checkbox';
import RoomSelect from 'components/v2/RoomSelect/RoomSelect';

import styles from './Location.module.scss';

interface LocationProps {
  bookedAppointmentId?: string;
}

const Location = ({ bookedAppointmentId }: LocationProps) => {
  const { isNormalUserView } = useGetAccountPackageView();
  const [locationProps, , locationHelper] = useField('faceToFaceLocation');
  const [bookRoom, , bookRoomHelper] = useField('bookRoom');

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.iconContainer}>
          <span className="material-icons-outlined">edit_location</span>
        </div>
        <div className={styles.locationDetails}>
          <div className={classNames(styles.locationField)}>
            <MaterialInput
              id={`location`}
              label={'Add location or dial in details'}
              onChange={(e) => locationHelper.setValue(e.target.value)}
              value={locationProps.value}
              required
            />
          </div>
          {!isNormalUserView && (
            <div className={classNames(styles.roomContainer)}>
              <Checkbox
                label="Add a room?"
                subLabel="Reserve an available room for this appointment"
                checked={bookRoom.value}
                onChange={() => bookRoomHelper.setValue(!bookRoom.value)}
              />
              {bookRoom.value && <RoomSelect bookedAppointmentId={bookedAppointmentId} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Location;
