import {
  ADDRESS_BOOK_ROLE_OPTIONS,
  ADDRESS_BOOK_TYPE_OPTIONS,
  AddressBookRole,
  AddressBookType
} from 'pages/AddressBook/Interfaces/AddressBook';
import { ReactNode } from 'react';
import {
  AddressBookListFilters,
  AddressBookListPaging,
  AddressBookListSearch,
  AddressBookListSorting
} from 'redux/addressBook/addressBookSlice';

export enum ColumnSettingsEnum {
  AddressBookId = 'addressBookId',
  Name = 'name',
  Category = 'category'
}

export interface SortBy {
  addressBookId?: number;
  name?: number;
  category?: number;
}

export enum AddressBookSortField {
  AddressBookIdAsc = 'addressBookId',
  AddressBookIdDesc = '-addressBookId',
  NameAsc = 'name',
  NameDesc = '-name',
  CategoryAsc = 'category',
  CategoryDesc = '-category'
}

interface GetAddressBookRecordListingQuery {
  page: number;
  perPage: number;

  searchName?: string;
  sort?: AddressBookSortField;

  categories?: string;
  types?: string;
  roles?: string;
}

export interface AddressBookListRequestPayload {
  accountId: string;
  params?: GetAddressBookRecordListingQuery;
}

export enum SortType {
  ASC = 1,
  DESC = -1
}

interface GetPayloadParam {
  paging: AddressBookListPaging;
  sorting: AddressBookListSorting;
  filters: AddressBookListFilters;
  search: AddressBookListSearch;
}

export const ADDRESS_BOOK_LIST_SORT_INFO = 'address-book-list-sort';

const getSortByQuery = (sortBy: AddressBookListSorting['sortBy'], sortType: SortType) => {
  const isASC = sortType === SortType.ASC;
  switch (sortBy) {
    case ColumnSettingsEnum.AddressBookId:
      return isASC ? AddressBookSortField.AddressBookIdAsc : AddressBookSortField.AddressBookIdDesc;
    case ColumnSettingsEnum.Name:
      return isASC ? AddressBookSortField.NameAsc : AddressBookSortField.NameDesc;
    case ColumnSettingsEnum.Category:
      return isASC ? AddressBookSortField.CategoryAsc : AddressBookSortField.CategoryDesc;
    default:
      return '';
  }
};

export const getAddressBookRecordRequestPayload = (params: GetPayloadParam): GetAddressBookRecordListingQuery => {
  const { paging, sorting, filters, search } = params;
  const { page, perPage } = paging;
  const { sortBy, sortType } = sorting;
  const { searchValue } = search;
  const { selectedCategories, selectedTypes, selectedRoles } = filters;
  const sortByKey = sortBy && getSortByQuery(sortBy, sortType);

  return {
    page,
    perPage,
    ...(sortByKey && {
      sort: sortByKey
    }),

    ...(selectedCategories.length > 0 && {
      categories: selectedCategories.map(({ _id }) => _id).join(',')
    }),

    ...(selectedTypes.length > 0 && {
      types: selectedTypes.map(({ _id }) => _id).join(',')
    }),

    ...(selectedRoles.length > 0 && {
      roles: selectedRoles.map(({ _id }) => _id).join(',')
    }),

    ...(search.searchValue && {
      searchName: searchValue
    })
  };
};

export const defaultAddressBookFilters: Record<string, Partial<AddressBookListFilters>> = {
  funder: {
    selectedRoles: ADDRESS_BOOK_ROLE_OPTIONS.filter((opt) => opt._id === AddressBookRole.Funder),
    selectedTypes: []
  },
  referrer: {
    selectedRoles: ADDRESS_BOOK_ROLE_OPTIONS.filter((opt) => opt._id === AddressBookRole.Referrer),
    selectedTypes: []
  },
  organisation: {
    selectedRoles: [],
    selectedTypes: ADDRESS_BOOK_TYPE_OPTIONS.filter((opt) => opt._id === AddressBookType.Organisation)
  },
  individual: {
    selectedRoles: [],
    selectedTypes: ADDRESS_BOOK_TYPE_OPTIONS.filter((opt) => opt._id === AddressBookType.Individual)
  }
};

export const renderCellValue = (value: unknown): ReactNode => {
  if (typeof value === 'string' || typeof value === 'boolean') {
    return value.toString() || '-';
  } else if (value instanceof Date) {
    return value.toISOString();
  } else {
    return null;
  }
};

export const AddressBookFormValidationInitialMessage = {
  type: '',
  role: '',
  category: '',
  organisation: {
    name: '',
    phone: '',
    faxNumber: ''
  }
};
