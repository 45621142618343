import styles from './EpisodeStatusBadge.module.scss';
import { EPISODE_STATUS_LABELS, EpisodeStatus } from 'interfaces/Episodes/episodes';
import classNames from 'classnames';

export interface EpisodeStatusBadgeProps {
  status: EpisodeStatus;
  isDisable?: boolean;
  className?: string;
}

const EpisodeStatusBadge = ({ status, isDisable, className }: EpisodeStatusBadgeProps) => (
  <div className={classNames(styles[isDisable ? 'disable' : status], className)}>
    <div>{EPISODE_STATUS_LABELS[status]?.toUpperCase()}</div>
  </div>
);

export default EpisodeStatusBadge;
