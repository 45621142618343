import {
  PendingClaimInvoiceListRequestPayload,
  PendingClaimInvoiceListResponse
} from 'interfaces/invoices/pendingClaimInvoices';
import { BSTagTypes, billingServicesApiSlice } from 'redux/services/billingServicesApiSlice';
import queryString from 'query-string';
import { Claim } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';

export type CreateClaimPayload = Pick<Claim, 'type' | 'benefit' | 'mbsCode' | 'service'> & {
  invoiceId: string;
  clientRecordId: string;
  clientProfileId: string;
};

export const pendingClaimInvoiceApiSlice = billingServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createClaim: builder.mutation({
      query: ({
        minorId,
        providerId,
        payload
      }: {
        minorId: string;
        providerId: string;
        payload: CreateClaimPayload;
      }) => ({
        url: `/clinicians/me/claimingcom/locations/${minorId}/providers/${providerId}/claims`,
        method: 'POST',
        body: payload
      }),
      transformErrorResponse: (response: { status: number; data: unknown }, _meta, _arg) => {
        const errorData = response.data as { message: string };
        if (response.status === 400 || response.status === 404) {
          return { message: errorData.message };
        } else if (response.status !== 201) {
          return { message: 'Something went wrong while trying to create claim' };
        }
      },
      invalidatesTags: (_result, error, _arg) =>
        error
          ? []
          : [
              BSTagTypes.Invoices,
              BSTagTypes.InvoiceSummary,
              BSTagTypes.InvoicePayments,
              BSTagTypes.PendingClaims,
              BSTagTypes.SubmittedClaims
            ]
    }),

    getPendingClaimInvoiceList: builder.query<PendingClaimInvoiceListResponse, PendingClaimInvoiceListRequestPayload>({
      query: ({ accountId, params }) => ({
        url: `/accounts/${accountId}/invoices:pendingClaims?${queryString.stringify(params)}`
      }),
      providesTags: [BSTagTypes.PendingClaims]
    }),

    patchAbandonedPendingClaim: builder.mutation({
      query: ({ accountId, invoiceId }: { accountId: string; invoiceId: string }) => ({
        url: `/accounts/${accountId}/invoices/${invoiceId}/abandoned-claim`,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, error, arg) => {
        return error ? [] : [{ type: BSTagTypes.PendingClaims }];
      }
    })
  })
});

export const { useGetPendingClaimInvoiceListQuery, useCreateClaimMutation, usePatchAbandonedPendingClaimMutation } =
  pendingClaimInvoiceApiSlice;
