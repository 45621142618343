export const generateEpisodeId = (episodeOrder: number): string => {
  let id = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const RANDOM_CHARACTERS_LENGTH = 5;

  for (let i = 0; i < RANDOM_CHARACTERS_LENGTH; i++) {
    id += characters[Math.floor(Math.random() * characters.length)];
  }

  return `${episodeOrder}-${id}`;
};
