import styles from './MentorHeader.module.scss';
import { ProfileInterface } from 'interfaces/Profile/Profile';

interface MentorHeaderProps {
  profile: ProfileInterface;
  children?: any;
  horizontal?: boolean;
}

const MentorHeader = ({ profile, children, horizontal }: MentorHeaderProps) => (
  <div className={horizontal ? styles.sideBySideContainer : styles.container}>
    <div className={styles.logoWrapper}>
      <img className={styles.logo} src={profile.practice?.logo} alt={"Mentor's logo"} />
    </div>
    {children}
  </div>
);

export default MentorHeader;
