import { Dropdown, Menu, Skeleton } from 'antd';
import LETTERBUILDERIMG from 'assets/images/reportBuilderImg.png';
import PaginationListV2 from 'components/v2/PaginationListV2/PaginationListV2';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  resetPaging,
  selectFilters,
  selectPaging,
  selectSearch,
  setFilters,
  setPaging,
  setSearch
} from 'redux/letters/letterSlice';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';

import LetterItem from './components/LetterItem/LetterItem';
import styles from './LetterList.module.scss';
import columnStyles from './LetterListColumn.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetClientEncryptedDataByClientRecordIdQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useState } from 'react';
import { Letter } from 'interfaces/Letters/letter';
import { EDGE_USER_LETTER_FILTERS, LETTER_FILTERS } from './constants';
import SearchBarT23 from 'components/SearchBarT23/SearchBarT23';
import {
  GetLetterResponse,
  useGetLetterDetailsQuery,
  useGetLettersQuery
} from 'redux/endpoints/documentServices/letter';
import ShareReportModal from 'pages/Report/components/ShareReportModal/ShareReportModal';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useNavigate } from 'react-router-dom';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';

const LetterList = () => {
  const { accountId } = useGetAccountId();
  const { auth0ClinicianId } = useGetClinicianId();
  const { faxEnable } = useGetFeatureToggle();
  const dispatch = useAppDispatch();
  const { isEdgeAdminView, isEdgeReceptionist, isEdgeUser } = useGetAccountPackageView();
  const [t] = useTranslation();
  const paging = useAppSelector(selectPaging);
  const filters = useAppSelector(selectFilters);
  const search = useAppSelector(selectSearch);

  const navigate = useNavigate();
  const { LETTERS } = useRoutesGenerator();

  const [selectedLetter, setSelectedLetter] = useState<
    { letterId: string; letterName: string; clientRecordId: string } | undefined
  >(undefined);
  const [isShareLetterModalOpen, setIsShareLetterModalOpen] = useState<boolean>(false);

  const {
    data,
    isLoading: letterDataLoading,
    isFetching
  } = useGetLettersQuery({
    accountId,
    clinicianId: auth0ClinicianId,
    payload: {
      ...paging,
      ...(filters.status !== 'allStatus' && { status: filters.status }),
      ...(search && { search })
    },
    isAdmin: isEdgeAdminView || isEdgeReceptionist
  });

  const letterData = data as GetLetterResponse;

  const {
    data: clientEncryptDetails,
    isLoading: isClientEncryptDetailsLoading,
    isFetching: isClientEncryptDetailsFetching
  } = useGetClientEncryptedDataByClientRecordIdQuery(
    { clientRecordId: selectedLetter?.clientRecordId || '' },
    { skip: !selectedLetter || !selectedLetter.clientRecordId }
  );

  const {
    data: letterDetails,
    isLoading: isLetterDetailsLoading,
    isFetching: isLetterDetailsFetching
  } = useGetLetterDetailsQuery(
    {
      accountId,
      clinicianId: auth0ClinicianId,
      isAdmin: isEdgeAdminView || isEdgeReceptionist,
      letterId: selectedLetter?.letterId || ''
    },
    { skip: !selectedLetter?.letterId }
  );

  const onClickSendViaEFax = (letter: Letter) => {
    setSelectedLetter({
      letterId: letter._id,
      letterName: letter.letterName,
      clientRecordId: letter.clientRecord._id
    });
    setIsShareLetterModalOpen(true);
  };

  const showPagination =
    !isFetching &&
    !letterDataLoading &&
    letterData &&
    letterData.paging &&
    letterData.paging.totalItems > letterData.paging.perPage;
  const filterType = isEdgeUser ? EDGE_USER_LETTER_FILTERS : LETTER_FILTERS;

  const onChangeStatusFilter = (status: string) => {
    dispatch(resetPaging());
    dispatch(setFilters({ ...filters, status }));
  };

  const onChangePage = (page: number) => {
    dispatch(setPaging({ ...paging, page }));
  };

  const handleSearch = (letterName: string) => {
    dispatch(resetPaging());
    dispatch(setSearch(letterName));
  };

  return (
    <>
      <div className={styles.letterListHeader}>
        <div className={styles.filterWrapper} id={'filterLetterId'}>
          <div className={styles.filterWrapperTitle}>Filter:</div>
          <Dropdown
            overlay={
              <Menu onClick={({ key }) => onChangeStatusFilter(String(key))}>
                {filterType.map(({ label, value }) => (
                  <Menu.Item key={value}>{label}</Menu.Item>
                ))}
              </Menu>
            }
            trigger={['click']}
          >
            <div className={styles.filterWrapperFilter} onClick={(e) => e.preventDefault()}>
              {filterType.find((option) => option.value === filters.status)?.label}
              <i className={`material-icons ${styles.dropDownIcon}`}>keyboard_arrow_down</i>
            </div>
          </Dropdown>
        </div>
        <div className={styles.searchBarWrapper}>
          <SearchBarT23 placeholder="Letter name" value={search} withSearchButton onSearch={handleSearch} />
        </div>
      </div>
      {letterDataLoading || isFetching ? (
        <div className={styles.loadingWrapper}>
          {[...Array(10)].map((obj, i) => (
            <div key={i} className={styles.loadingItem}>
              <Skeleton.Input active className={styles.loadingContent} />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.container}>
          {!letterData?.data?.length ? (
            <div className={styles.noDataWrapper} id={'noLetterListingId'}>
              <img className={styles.image} alt={'no letter img'} src={LETTERBUILDERIMG} />
              <div className={styles.infoWrapper}>
                <div className={styles.text}>{t('label.no_letter_for_any_client')}</div>
                <ButtonAlt
                  size={'medium'}
                  variant="outlined"
                  onClick={() => navigate(LETTERS.NEW)}
                  icon="add_circle_outline"
                >
                  Create New Letter
                </ButtonAlt>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.cardContainer} id={'letterListingId'}>
                {letterData?.data.length ? (
                  <>
                    <div className={styles.filterListWrap}>
                      <div className={styles.contentWrapper}>
                        {(isEdgeAdminView || isEdgeReceptionist) && (
                          <div className={columnStyles.authorColumn}>
                            <div className={styles.label}>Created as</div>
                          </div>
                        )}
                        <div className={columnStyles.profileColumn}>
                          <div className={styles.label}>{t('label.client.capitalize')}</div>
                        </div>
                        <div className={columnStyles.letterNameColumn}>
                          <div className={styles.label}>Name</div>
                        </div>
                        <div className={columnStyles.statusColumn}>
                          <div className={styles.label}>Status</div>
                        </div>
                        <div className={columnStyles.createdColumn}>
                          <div className={styles.label}>Created At</div>
                        </div>
                        <div className={columnStyles.lastActivityColumn}>
                          <div className={styles.label}>Last Updated</div>
                        </div>
                        <div className={columnStyles.shareDetailColumn}>
                          <div className={styles.label}>Share</div>
                        </div>
                        <div className={columnStyles.actionsColum} />
                      </div>
                    </div>
                    <LetterItem letterListData={letterData.data} onClickSendViaEFax={onClickSendViaEFax} />
                  </>
                ) : (
                  <div className={styles.noData}>There is currently no letters and letters data</div>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {/* Pagination */}
      {showPagination && (
        <div className={styles.paginationWrapper}>
          <PaginationListV2 paging={letterData.paging} onPageChange={onChangePage} />
        </div>
      )}

      {faxEnable && selectedLetter && (
        <ShareReportModal
          type="letter"
          reportId={selectedLetter.letterId}
          reportName={selectedLetter.letterName}
          letterData={letterDetails}
          generalPractitionerId={clientEncryptDetails?.referral?.generalPractitionerId}
          isModalOpen={isShareLetterModalOpen}
          closeModal={() => setIsShareLetterModalOpen(false)}
          isLoading={
            isClientEncryptDetailsLoading ||
            isClientEncryptDetailsFetching ||
            isLetterDetailsLoading ||
            isLetterDetailsFetching
          }
        />
      )}
    </>
  );
};

export default LetterList;
